import { Injectable } from '@angular/core';

import { sms_pb, sms_ng_grpc_pb } from 'libs/generated-grpc-web';
import { ISmsRecipientDetails } from 'libs/shared';
import { MingaSmsDetailsMapper } from 'libs/shared-grpc';

@Injectable({ providedIn: 'root' })
export class SmsAdmin {
  constructor(private smsAdminProto: sms_ng_grpc_pb.SmsAdmin) {}

  async getDetails(mingaHash: string): Promise<ISmsRecipientDetails> {
    const request = new sms_pb.SmsAdminGetDetailsRequest();
    request.setMingaHash(mingaHash);
    const details = await this.smsAdminProto.getDetails(request);
    return MingaSmsDetailsMapper.toISmsRecipientDetails(details);
  }

  async setMaxSendCount(
    mingaHash: string,
    newMaxSendCount: number,
  ): Promise<ISmsRecipientDetails> {
    const request = new sms_pb.SmsAdminSetMaxSendCountRequest();
    request.setMingaHash(mingaHash);
    request.setNewMaxSendCount(newMaxSendCount);
    const details = await this.smsAdminProto.setMaxSendCount(request);
    return MingaSmsDetailsMapper.toISmsRecipientDetails(details);
  }

  async addMessageBlock(
    mingaHash: string,
    blockSize: number,
  ): Promise<ISmsRecipientDetails> {
    const request = new sms_pb.SmsAdminAddMessageBlockRequest();
    request.setMingaHash(mingaHash);
    request.setBlockSize(blockSize);
    const details = await this.smsAdminProto.addMessageBlock(request);
    return MingaSmsDetailsMapper.toISmsRecipientDetails(details);
  }
}
