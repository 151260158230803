import { Overlay } from '@angular/cdk/overlay';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { BehaviorSubject, combineLatest, ReplaySubject, Subject } from 'rxjs';
import { distinctUntilChanged, map, take, takeUntil } from 'rxjs/operators';

import { BottomSheetService } from '@shared/components/bottom-sheet';
import { MediaService } from '@shared/services/media';

import { FiltersFormSheetComponent } from './components/filters-form-sheet/filters-form-sheet.component';
import { FILTERS_FORM_FIELD_TYPE, FiltersFormMessage } from './constants';
import {
  FiltersFormData,
  FiltersFormSheetData,
  FiltersFormSheetResponseData,
} from './types';
import { FilterFormOverlay } from './utils';

@Component({
  selector: 'mg-filters-form',
  templateUrl: './filters-form.component.html',
  styleUrls: ['./filters-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FiltersFormComponent implements OnInit, OnDestroy {
  // Children

  @ViewChild('triggerButton') triggerButton: ElementRef;

  // Constants

  public readonly MESSAGE = FiltersFormMessage;
  public readonly FIELD_TYPE = FILTERS_FORM_FIELD_TYPE;

  // Cleanup

  private readonly _destroyedSubject = new ReplaySubject<void>(1);

  // Events

  private readonly _resetSubject = new Subject<void>();
  private readonly _finishedSettingDataSubject = new Subject<void>();

  // Form

  public readonly form = new UntypedFormGroup({});
  public defaultFormValueState: { [key: string]: any } = {};
  private _formFieldsSet = new Set<string>();

  // State

  private _data: FiltersFormData = {};

  // Inputs

  @Input() set data(data: FiltersFormData) {
    // unsubscribe to avoid valueChanges on every control update
    this._formValueChangeSubscription.unsubscribe();
    this.defaultFormValueState = {};

    this._data = data;
    const newFormFieldsSet = new Set<string>();

    // also do count here to avoid an extra iteration
    let count = 0;
    for (const field in data) {
      if (data.hasOwnProperty(field)) {
        newFormFieldsSet.add(field);
        let fieldObject = data[field];
        if (typeof fieldObject === 'function') {
          fieldObject = fieldObject(this.form.value, false);
        }
        const formValue = fieldObject.value ?? fieldObject.default;
        if (fieldObject.default) this.defaultFormValueState[field] = formValue;
        if (this._checkCountFilterValue(formValue, field)) count++;
        if (this.form.get(field)) {
          this.form.get(field).setValue(formValue, { emitEvent: false });
        } else {
          this.form.addControl(field, this._fb.control(formValue));
        }
        this._formFieldsSet.delete(field);
      }
    }

    // remove old form controls to avoid count issues
    for (const field of this._formFieldsSet) {
      if (this.form.contains(field)) this.form.removeControl(field);
    }

    this._formFieldsSet.clear();
    this._formFieldsSet = newFormFieldsSet;
    this._appliedFiltersCountSubject.next(count);
    this._formValueChangeSubscription = this._fetchFormSubscription();
    this._cdr.markForCheck();
    this._finishedSettingDataSubject.next();
  }
  @Input() title = 'Filters';
  @Input() appearance: 'primary' | 'secondary' = 'primary';
  @Input() showChips = true;
  @Input() enableQueryParams: boolean;
  @Input() responsive: boolean;
  @Input() loading: boolean;

  // Outputs

  @Output() state = new EventEmitter<any>();
  @Output() resetState = new EventEmitter<void>();

  // Subscriptions

  private readonly _resetSubscription = this._resetSubject
    .pipe(takeUntil(this._destroyedSubject))
    .subscribe(() => this._handleResetSubscription());

  private _formValueChangeSubscription = this._fetchFormSubscription();
  private _appliedFiltersCountSubject = new BehaviorSubject<number>(0);
  public appliedFiltersCount$ = this._appliedFiltersCountSubject.asObservable();

  /** Show chips on desktop and if counts are greater than 0 */
  public readonly shouldShowChips$ = combineLatest([
    this.media.isDesktopView$,
    this.appliedFiltersCount$,
  ]).pipe(
    takeUntil(this._destroyedSubject),
    map(([isDesktop, count]) => isDesktop && count > 0),
  );

  // Overlay

  public readonly overlay = new FilterFormOverlay(
    this._overlay,
    this._viewContainerRef,
  );

  // Getters

  public get dataState() {
    return this._data;
  }

  constructor(
    public media: MediaService,
    private _overlay: Overlay,
    private _viewContainerRef: ViewContainerRef,
    private _bottomSheet: BottomSheetService<
      FiltersFormSheetData,
      FiltersFormSheetResponseData
    >,
    private _fb: UntypedFormBuilder,
    private _cdr: ChangeDetectorRef,
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    if (this.enableQueryParams) this._handleQueryParams();
  }

  ngOnDestroy(): void {
    this._destroyedSubject.next();
    this._destroyedSubject.complete();
    this._resetSubject.complete();
    this._resetSubscription.unsubscribe();
    this._finishedSettingDataSubject.complete();
    this._formValueChangeSubscription.unsubscribe();
    this._formFieldsSet.clear();
  }

  protected readonly chipSortOrder = () => 0;

  public async open(options?: { focusField?: string }) {
    const fields = Array.from(this._formFieldsSet);
    if (this.media.isMobileView) this._openBottomSheet(fields);
    else this._openPopover(fields, options);
  }

  // Allow others to be able to reset the form.
  public reset() {
    this._resetSubject.next();
  }

  private _openBottomSheet(fields: string[]) {
    return this._bottomSheet
      .open(FiltersFormSheetComponent, {
        data: {
          title: this.title,
          data: this._data,
          fields,
          formGroup: this.form,
          resetSubject: this._resetSubject,
        },
      })
      .afterDismissed();
  }

  private _openPopover(fields: string[], options?: { focusField?: string }) {
    this.overlay.open({
      triggerElement: this.triggerButton,
      fields,
      data: this._data,
      formGroup: this.form,
      resetSubject: this._resetSubject,
      focusFieldOnInit: options?.focusField,
    });
  }

  private _calculateAppliedFiltersCount(state: Record<string, any>) {
    let count = 0;
    for (const key in state) {
      if (this._checkCountFilterValue(state[key], key)) count++;
    }
    this._appliedFiltersCountSubject.next(count);
  }

  private _checkCountFilterValue(value: any, key: string) {
    let field = this._data[key];
    if (typeof field === 'function') field = field(this.form.value, true);
    // don't include toggle group filters in the count
    if (field.type === 'toggle-group') return false;
    if (!value) return false;
    if (Array.isArray(value)) return value.length > 0;
    return true;
  }

  private _handleResetSubscription() {
    this.form.reset(this.defaultFormValueState);
    this.resetState.emit();
  }

  private _handleFormValueChangeSubscription(v: Record<string, any>) {
    this._calculateAppliedFiltersCount(v);
    this.state.emit(v);
  }

  private _fetchFormSubscription() {
    return this.form.valueChanges
      .pipe(takeUntil(this._destroyedSubject), distinctUntilChanged())
      .subscribe(v => this._handleFormValueChangeSubscription(v));
  }

  private _handleQueryParams() {
    const filtersQueryParam =
      this._activatedRoute.snapshot.queryParams?.filters;
    if (filtersQueryParam) {
      this._finishedSettingDataSubject.pipe(take(1)).subscribe(() => {
        this.form.patchValue(JSON.parse(filtersQueryParam));
      });
    }

    // @TODO: implement this correctly, so it updates but let's save
    // for future work

    // this.form.valueChanges
    //   .pipe(takeUntil(this._destroyedSubject))
    //   .subscribe(v => {
    //     const queryParams: Params = { filters: JSON.stringify(v) };
    //     this._router.navigate([], {
    //       relativeTo: this._activatedRoute,
    //       queryParams,
    //       queryParamsHandling: 'merge',
    //     });
    //   });
  }
}
