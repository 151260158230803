import { z } from 'zod';

import {
  PeopleUserListPageState,
  PeopleUserListPageStateFilters,
} from '../types/people-userlists.types';

export enum PeopleUserListsMessages {
  TITLE = 'User Lists',
  TITLE_TOOLTIP = 'User lists allow you to manage frequently accessed groups of users that can be used as a filter in various sections of the app',
  FILTER_LABEL_TITLE = 'Search by title or list id',
  FILTER_LABEL_LIST_MANAGER = 'Search by list manager',

  BUTTON_LABEL_CREATE = 'Create list',
  BUTTON_LABEL_EXPORT = 'Export list',
  BUTTON_LABEL_IMPORT = 'Import file',

  BUTTON_LABEL_ALL_LISTS = 'All lists',
  BUTTON_LABEL_MY_LISTS = 'My lists',

  BUTTON_LABEL_ENABLE_HOMEROOM = 'Use as homerooms',
  BUTTON_LABEL_DISABLE_HOMEROOM = 'Remove from homerooms',

  COL_TITLE = 'Title',
  COL_LIST_ID = 'List ID',
  COL_PERIOD_ID = 'Period ID',
  COL_TERM_ID = 'Term ID',
  COL_CREATED_BY = 'Owner',
  COL_LAST_UPDATED = 'Last Updated',
  COL_PUBLIC = 'Public',
  COL_MANAGE_MEMBERS = 'Members',
  COL_ACTIVE = 'Active',
  COL_EDIT = 'Edit',

  LABEL_PRIVACY = 'Privacy',

  EMPTY_STATE_TITLE = 'No user list found',
  EMPTY_STATE_BODY = 'User lists will be visible here',
  EMPTY_STATE_FILTERS_BODY = 'No user lists match the current filters',

  BUTTON_LABEL_ADD = 'Add',

  LIST_ID_TOOLTIP = "This List ID links Minga's user list to class lists within your SIS, enabling automatic updates during SIS syncs",

  SET_HOMEROOM_SUCCESS_SNACKBAR = 'User lists successfully set as homerooms',
  REMOVE_HOMEROOM_SUCCESS_SNACKBAR = 'User lists successfully removed from homerooms',
}

export enum PeopleUserListFilterField {
  TERM_IDS = 'term_ids',
  PERIOD_IDS = 'period_ids',
}

export const PEOPLE_USER_LIST_PAGE_DEFAULT_STATE: PeopleUserListPageState = {
  lists: new Map(),
  activeListId: null,
  totalLists: null,
  loading: {
    allLists: false,
    activeList: false,
    delete: false,
    create: false,
    update: false,
    setHomeroom: false,
  },
};

export const queryParamsToFilters = (
  params: UserListFilterQuery,
  isUserListAdmin: boolean,
): PeopleUserListPageStateFilters => ({
  title: params.title || '',
  listManagerHashes: params.manager_hashes || [],
  showMine: params.my_lists ?? !isUserListAdmin,
  termSourceIds: params.term_ids,
  periodSourceIds: params.period_ids,
});

export const userListFilterQuerySchema = z.object({
  title: z.string().catch(''),
  manager_hashes: z.array(z.string()).catch([]),
  my_lists: z.boolean().optional(),
  term_ids: z.array(z.string()).catch([]),
  period_ids: z.array(z.string()).catch([]),
});

export type UserListFilterQuery = z.infer<typeof userListFilterQuerySchema>;
