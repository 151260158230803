import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  SimpleChanges,
  ViewChild,
} from '@angular/core';

import { Odometer } from '@app/src/app/util/odometer';

export interface IMgOdometerElementProperties {
  value: number;
  format: string;
}

@Component({
  selector: 'mg-odometer',
  templateUrl: './MgOdometer.element.html',
  styleUrls: ['./MgOdometer.element.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MgOdometerElement {
  @Input()
  value: number = 0;

  @Input()
  format: string = '';

  @ViewChild('odometerEl', { static: true })
  odometerEl?: ElementRef;

  private _odometer: any;

  constructor() {}

  ngAfterViewInit() {
    if (this.odometerEl && this.odometerEl.nativeElement) {
      this._odometer = new Odometer({
        el: this.odometerEl.nativeElement,
        value: 0,
        format: this.format,
        theme: 'minimal',
      });

      requestAnimationFrame(() => {
        this._valueChange();
      });
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.value) {
      this._valueChange();
    }
  }

  _valueChange() {
    if (this._odometer) {
      this._odometer.update(this.value);
    }
  }
}
