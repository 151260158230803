<div class="container">
  <mg-tools-split-card
    class="tw-block tw-mb-3"
    leftSubtitle="Registered Periods"
    rightSubtitle="Unregistered Periods"
    [leftTitle]="(registeredPeriodCount$ | async)?.toString()"
    [rightTitle]="(unregisteredPeriodCount$ | async)?.toString()">
  </mg-tools-split-card>

  <div class="filters tw-mb-6">
    <mat-button-toggle-group
      class="filter-toggle-group always-full-width"
      [multiple]="false"
      [value]="periodsFilter$ | async"
      (change)="filterSchedules($event.value)">
      <mat-button-toggle
        class="toggle-button"
        [value]="'all'">
        <mg-text variant="body-sm-secondary"> All periods </mg-text>
      </mat-button-toggle>
      <mat-button-toggle
        class="toggle-button"
        [value]="'registered'">
        <mg-text variant="body-sm-secondary"> Registered </mg-text>
      </mat-button-toggle>
      <mat-button-toggle
        class="toggle-button"
        [value]="'unregistered'">
        <mg-text variant="body-sm-secondary"> Unregistered </mg-text>
      </mat-button-toggle>
    </mat-button-toggle-group>
  </div>

  <div *ngIf="schedule$ | async">
    <mg-ftm-activites-student-scheduler
      [schedule]="schedule$ | async"
      (activityRegistered)="activityRegistered($event)"
      [personHash]="personHash"></mg-ftm-activites-student-scheduler>
  </div>
</div>
