// GENERATED CODE -- DO NOT EDIT!

import {grpc} from '@improbable-eng/grpc-web';

import * as content_post_pb from '../content/post_pb';
import * as content_common_pb from '../content/common_pb';
import * as common_delta_pb from '../common/delta_pb';
import * as designed_svg_designed_svg_pb from '../designed_svg/designed_svg_pb';

export class Post {
  static readonly serviceName = "minga.content.Post";

  static readonly New = {
    methodName: "New",
    service: Post,
    requestStream: false,
    responseStream: false,
    requestType: content_post_pb.PostInfo,
    responseType: content_common_pb.ContentSaveResult,
  };

  static readonly Update = {
    methodName: "Update",
    service: Post,
    requestStream: false,
    responseStream: false,
    requestType: content_post_pb.DeltaPostInfo,
    responseType: content_common_pb.ContentSaveResult,
  };

  static readonly GetInfo = {
    methodName: "GetInfo",
    service: Post,
    requestStream: false,
    responseStream: false,
    requestType: content_common_pb.ContentInfoQuery,
    responseType: content_post_pb.PostInfo,
  };

}

export class PostClient {
  new() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  update() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  getInfo() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
}
