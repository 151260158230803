import { Injectable } from '@angular/core';

import * as day from 'dayjs';
import { CheckinReasonType, ICheckinReason } from 'libs/domain';
import {
  FlexTimeActivityInstance,
  FlexTimeActivityInstancePayload,
} from 'libs/domain';
import { flex_time_ng_grpc_pb, flex_time_pb } from 'libs/generated-grpc-web';
import { FlexTimeActivityInstanceMapper } from 'libs/shared-grpc';

import { MgFormatTimeRangePipe } from '@app/src/app/pipes';
import { dateTimeObjectToDateTimeMessage } from '@app/src/app/util/date';

import { FtmActivitySelectorModalComponent } from '@modules/flex-time-manager/components/ftm-shared/components/ftm-activity-selector-modal/ftm-activity-selector-modal';
import {
  ActivitySelectorModalData,
  ActivitySelectorModalResponse,
} from '@modules/flex-time-manager/components/ftm-shared/ftm-shared.constants';

import { SelectGroupOption } from '@shared/components/form/components/form-grouped-select/form-grouped-select.types';
import {
  ModalOverlayService,
  ModalOverlayServiceCloseEventType,
} from '@shared/components/modal-overlay';
import { mapFlexActivityToOption } from '@shared/components/user-list-filter/utils/user-list.utils';
import { getAppColor } from '@shared/constants';
import { ErrorHandlerService } from '@shared/services/error-handler';

import { CacheService } from '../cache/cache.service';
import { CacheKey } from '../cache/cache.types';

@Injectable()
export class FlexTimeActivityInstanceService {
  private _cachedTodaysFlexActivites = this._cacheService.create<
    FlexTimeActivityInstance[]
  >(
    CacheKey.USER_LIST_TODAYS_FLEX_ACTIVITIES,
    async (filters: { startDate: day.Dayjs; endDate: day.Dayjs }) => {
      const activities = await this.fetchAll(filters);
      return activities.sort((a, b) => {
        return a.flexTimePeriod?.startTime < b.flexTimePeriod?.startTime
          ? -1
          : 1;
      });
    },
    {
      ttl: 60,
    },
  );
  /** Service Constructor */
  constructor(
    private _flexTimeManager: flex_time_ng_grpc_pb.FlexTimeManager,
    private _errorHandler: ErrorHandlerService,
    private _modalService: ModalOverlayService<
      ActivitySelectorModalResponse,
      ActivitySelectorModalData
    >,
    private _cacheService: CacheService,
  ) {}

  public async fetchAll({
    startDate,
    endDate,
    periodId,
    personHashes,
    activityTemplateId,
  }: {
    startDate?: day.Dayjs;
    endDate?: day.Dayjs;
    periodId?: number;
    personHashes?: string[];
    activityTemplateId?: number;
  }): Promise<FlexTimeActivityInstance[]> {
    try {
      const request = new flex_time_pb.ListFlexTimeActivityInstancesRequest();

      if (startDate) {
        const startDateTime = dateTimeObjectToDateTimeMessage(
          startDate.startOf('day').toDate(),
        );
        request.setStartDate(startDateTime);
      }

      if (endDate) {
        const endDateTime = dateTimeObjectToDateTimeMessage(
          endDate.startOf('day').toDate(),
        );
        request.setEndDate(endDateTime);
      }

      if (periodId) request.setPeriodId(periodId);

      if (personHashes?.length) request.setPersonHashesList(personHashes);

      if (activityTemplateId) request.setActivityTemplateId(activityTemplateId);

      const response =
        await this._flexTimeManager.listFlexTimeActivityInstances(request);
      return response
        .getFlexTimeActivityInstancesList()
        .map(FlexTimeActivityInstanceMapper.fromProto);
    } catch (error) {
      throw this._errorHandler.gateWayError(
        'failed to fetch time activities instances',
        error,
        true,
      );
    }
  }

  public async create(
    activityInstance: FlexTimeActivityInstancePayload,
  ): Promise<FlexTimeActivityInstance> {
    try {
      const request = new flex_time_pb.UpsertFlexTimeActivityInstanceRequest();
      const instance = new flex_time_pb.FlexTimeActivityInstance();
      instance.setActivityId(activityInstance.flexTimeActivityId);
      instance.setPeriodId(activityInstance.flexTimePeriodId);
      request.setFlexTimeActivityInstancesList([instance]);
      const response =
        await this._flexTimeManager.upsertFlexTimeActivityInstance(request);
      return FlexTimeActivityInstanceMapper.fromProto(
        response.getFlexTimeActivityInstancesList()[0],
      );
    } catch (error) {
      throw this._errorHandler.gateWayError(
        'Failed to create FlexTime activity instance',
        error,
        true,
      );
    }
  }

  public async update(): Promise<FlexTimeActivityInstance> {
    try {
      return null;
    } catch (error) {
      throw this._errorHandler.gateWayError(
        'Failed to delete FlexTime activity instance',
        error,
        true,
      );
    }
  }

  public async delete(id: number): Promise<boolean> {
    try {
      const request = new flex_time_pb.DeleteFlexTimeActivityInstanceRequest();
      request.setId(id);
      await this._flexTimeManager.deleteFlexTimeActivityInstance(request);
      return true;
    } catch (error) {
      throw this._errorHandler.gateWayError(
        'Failed to delete FlexTime activity instance',
        error,
        true,
      );
    }
  }

  public async openActivitySelector(
    modalData: ActivitySelectorModalData,
  ): Promise<FlexTimeActivityInstance> {
    return new Promise((resolve, reject) => {
      const modalRef = this._modalService.open(
        FtmActivitySelectorModalComponent,
        {
          data: modalData || {},
        },
      );

      modalRef.afterClosed.subscribe(async response => {
        const { type, data } = response;

        if (
          type === ModalOverlayServiceCloseEventType.CREATE &&
          data.activityInstance
        ) {
          resolve(data.activityInstance);
        }
      });
    });
  }

  public mapFlexActivityInstanceToReason(
    activity: FlexTimeActivityInstance,
  ): ICheckinReason {
    return {
      id: activity.checkinReasonId,
      name: activity?.flexTimeActivity?.name || '',
      icon: 'FlexTime-o',
      mingaId: activity.mingaId,
      color: getAppColor('flextime'),
      active: false,
      checkinReasonType: CheckinReasonType.FLEXTIME,
      pointReward: 0,
      showAbsentees: false,
      selfCheckIn: false,
    };
  }

  public getTotalCounts(instances: FlexTimeActivityInstance[]): {
    totalRegistered: number;
    totalSeats: number;
    totalAbsentees: number;
  } {
    const [totalRegistered, totalSeats, totalAbsentees] = (
      instances || []
    ).reduce(
      (totals, curr) => {
        const [registered, seats, absentees] = totals;
        return [
          registered + curr.registered,
          seats + curr.spaces,
          absentees + curr.absentees,
        ];
      },
      [0, 0, 0],
    );

    return {
      totalRegistered,
      totalSeats,
      totalAbsentees,
    };
  }

  public async getFlexActivitesListByUser(filters: {
    startDate: day.Dayjs;
    endDate: day.Dayjs;
    userHash: string;
  }): Promise<SelectGroupOption<number>[]> {
    const activities = await this._getFlexActivities(filters);
    const timeRangePipe = new MgFormatTimeRangePipe();

    return activities
      .filter(
        activity =>
          activity.flexTimeActivity.createdByPerson.hash === filters.userHash,
      )
      .map(a => mapFlexActivityToOption(a, timeRangePipe.transform));
  }

  public async getFlexActivitesList(filters: {
    startDate: day.Dayjs;
    endDate: day.Dayjs;
  }): Promise<SelectGroupOption<number>[]> {
    const activities = await this._getFlexActivities(filters);
    const timeRangePipe = new MgFormatTimeRangePipe();

    return activities.map(a =>
      mapFlexActivityToOption(a, timeRangePipe.transform),
    );
  }

  private async _getFlexActivities(filters: {
    startDate: day.Dayjs;
    endDate: day.Dayjs;
  }) {
    try {
      return await this._cachedTodaysFlexActivites.get(filters).toPromise();
    } catch (e) {
      this._errorHandler.gateWayError('Failed to fetch activities', e, true);
    }
  }
}
