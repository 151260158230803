// source: gateway/connect.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

import * as jspb from 'google-protobuf';
import * as common_legacy_pb from '../common/legacy_pb';
export namespace AppConfigRequest {
  export interface AsObject {
    version: string;
  }
}
export class AppConfigRequest extends jspb.Message {
  static readonly displayName = "AppConfigRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): AppConfigRequest.AsObject {
    return AppConfigRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!AppConfigRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: AppConfigRequest) {
    var f: any;
    var obj: any = {
      version: jspb.Message.getFieldWithDefault(msg, 1, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!AppConfigRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new AppConfigRequest;
    return AppConfigRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!AppConfigRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!AppConfigRequest}
   */
  static deserializeBinaryFromReader(msg: AppConfigRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setVersion(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!AppConfigRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: AppConfigRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getVersion();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    AppConfigRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string version = 1;
   * @return {string}
   */
  getVersion(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setVersion(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


}
export namespace AppConfigResponse {
  export interface AsObject {
    status?: common_legacy_pb.StatusCode;
    configVersion: string;
    configJsonString: string;
    downloadUrl: string;
    externalUrl: string;
    assetUrlPrefix: string;
    minimumVersion: string;
    apiUrl: string;
    countryAppConfigList: AppConfigResponse.CountryAppConfig.AsObject[];
  }
}
export class AppConfigResponse extends jspb.Message {
  static readonly displayName = "AppConfigResponse";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [9];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, AppConfigResponse.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): AppConfigResponse.AsObject {
    return AppConfigResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!AppConfigResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: AppConfigResponse) {
    var f: any;
    var obj: any = {
      status: jspb.Message.getFieldWithDefault(msg, 1, 0),
      configVersion: jspb.Message.getFieldWithDefault(msg, 2, ""),
      configJsonstring: jspb.Message.getFieldWithDefault(msg, 3, ""),
      downloadUrl: jspb.Message.getFieldWithDefault(msg, 4, ""),
      externalUrl: jspb.Message.getFieldWithDefault(msg, 5, ""),
      assetUrlPrefix: jspb.Message.getFieldWithDefault(msg, 6, ""),
      minimumVersion: jspb.Message.getFieldWithDefault(msg, 7, ""),
      apiUrl: jspb.Message.getFieldWithDefault(msg, 8, ""),
      countryAppConfigList: jspb.Message.toObjectList(msg.getCountryAppConfigList(),
      AppConfigResponse.CountryAppConfig.toObject, includeInstance)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!AppConfigResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new AppConfigResponse;
    return AppConfigResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!AppConfigResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!AppConfigResponse}
   */
  static deserializeBinaryFromReader(msg: AppConfigResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {!StatusCode} */ (reader.readEnum());
        msg.setStatus(value1);
        break;
      case 2:
        var value2 = /** @type {string} */ (reader.readString());
        msg.setConfigVersion(value2);
        break;
      case 3:
        var value3 = /** @type {string} */ (reader.readString());
        msg.setConfigJsonstring(value3);
        break;
      case 4:
        var value4 = /** @type {string} */ (reader.readString());
        msg.setDownloadUrl(value4);
        break;
      case 5:
        var value5 = /** @type {string} */ (reader.readString());
        msg.setExternalUrl(value5);
        break;
      case 6:
        var value6 = /** @type {string} */ (reader.readString());
        msg.setAssetUrlPrefix(value6);
        break;
      case 7:
        var value7 = /** @type {string} */ (reader.readString());
        msg.setMinimumVersion(value7);
        break;
      case 8:
        var value8 = /** @type {string} */ (reader.readString());
        msg.setApiUrl(value8);
        break;
      case 9:
        var value9 = new AppConfigResponse.CountryAppConfig;
        reader.readMessage(value9,AppConfigResponse.CountryAppConfig.deserializeBinaryFromReader);
        msg.addCountryAppConfig(value9);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!AppConfigResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: AppConfigResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getStatus();
    if (f !== 0.0) {
      writer.writeEnum(
        1,
        f
      );
    }
    f = message.getConfigVersion();
    if (f.length > 0) {
      writer.writeString(
        2,
        f
      );
    }
    f = message.getConfigJsonstring();
    if (f.length > 0) {
      writer.writeString(
        3,
        f
      );
    }
    f = message.getDownloadUrl();
    if (f.length > 0) {
      writer.writeString(
        4,
        f
      );
    }
    f = message.getExternalUrl();
    if (f.length > 0) {
      writer.writeString(
        5,
        f
      );
    }
    f = message.getAssetUrlPrefix();
    if (f.length > 0) {
      writer.writeString(
        6,
        f
      );
    }
    f = message.getMinimumVersion();
    if (f.length > 0) {
      writer.writeString(
        7,
        f
      );
    }
    f = message.getApiUrl();
    if (f.length > 0) {
      writer.writeString(
        8,
        f
      );
    }
    f = message.getCountryAppConfigList();
    if (f.length > 0) {
      writer.writeRepeatedMessage(
        9,
        f,
        AppConfigResponse.CountryAppConfig.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    AppConfigResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional StatusCode status = 1;
   * @return {!StatusCode}
   */
  getStatus(): common_legacy_pb.StatusCode {
    return /** @type {!StatusCode} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
  };


  /** @param {!StatusCode} value */
  setStatus(value: common_legacy_pb.StatusCode) {
    jspb.Message.setProto3EnumField(this, 1, value);
  }


  /**
   * optional string config_version = 2;
   * @return {string}
   */
  getConfigVersion(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
  };


  /** @param {string} value */
  setConfigVersion(value: string) {
    jspb.Message.setProto3StringField(this, 2, value);
  }


  /**
   * optional string config_jsonString = 3;
   * @return {string}
   */
  getConfigJsonstring(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
  };


  /** @param {string} value */
  setConfigJsonstring(value: string) {
    jspb.Message.setProto3StringField(this, 3, value);
  }


  /**
   * optional string download_url = 4;
   * @return {string}
   */
  getDownloadUrl(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
  };


  /** @param {string} value */
  setDownloadUrl(value: string) {
    jspb.Message.setProto3StringField(this, 4, value);
  }


  /**
   * optional string external_url = 5;
   * @return {string}
   */
  getExternalUrl(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
  };


  /** @param {string} value */
  setExternalUrl(value: string) {
    jspb.Message.setProto3StringField(this, 5, value);
  }


  /**
   * optional string asset_url_prefix = 6;
   * @return {string}
   */
  getAssetUrlPrefix(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
  };


  /** @param {string} value */
  setAssetUrlPrefix(value: string) {
    jspb.Message.setProto3StringField(this, 6, value);
  }


  /**
   * optional string minimum_version = 7;
   * @return {string}
   */
  getMinimumVersion(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
  };


  /** @param {string} value */
  setMinimumVersion(value: string) {
    jspb.Message.setProto3StringField(this, 7, value);
  }


  /**
   * optional string api_url = 8;
   * @return {string}
   */
  getApiUrl(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
  };


  /** @param {string} value */
  setApiUrl(value: string) {
    jspb.Message.setProto3StringField(this, 8, value);
  }


  /**
   * repeated CountryAppConfig country_app_config = 9;
   * @return {!Array<!AppConfigResponse.CountryAppConfig>}
   */
  getCountryAppConfigList(): AppConfigResponse.CountryAppConfig[] {
    return /** @type{!Array<!AppConfigResponse.CountryAppConfig>} */ (
      jspb.Message.getRepeatedWrapperField(this, AppConfigResponse.CountryAppConfig, 9));
  }


  /** @param {!Array<!AppConfigResponse.CountryAppConfig>} value */
  setCountryAppConfigList(value?: AppConfigResponse.CountryAppConfig[]) {
    jspb.Message.setRepeatedWrapperField(this, 9, value);
  }


  /**
   * @param {!AppConfigResponse.CountryAppConfig=} opt_value
   * @param {number=} opt_index
   * @return {!AppConfigResponse.CountryAppConfig}
   */
  addCountryAppConfig(opt_value?: AppConfigResponse.CountryAppConfig, opt_index?: number): AppConfigResponse.CountryAppConfig {
    return jspb.Message.addToRepeatedWrapperField(this, 9, opt_value, AppConfigResponse.CountryAppConfig, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearCountryAppConfigList() {
    this.setCountryAppConfigList([]);
  }


}
export namespace AppConfigResponse {
export namespace CountryAppConfig {
  export interface AsObject {
    country: string;
    apiUrl: string;
    assetUrlPrefix: string;
  }
}
export class CountryAppConfig extends jspb.Message {
  static readonly displayName = "AppConfigResponse.CountryAppConfig";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): CountryAppConfig.AsObject {
    return CountryAppConfig.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!CountryAppConfig} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: CountryAppConfig) {
    var f: any;
    var obj: any = {
      country: jspb.Message.getFieldWithDefault(msg, 1, ""),
      apiUrl: jspb.Message.getFieldWithDefault(msg, 2, ""),
      assetUrlPrefix: jspb.Message.getFieldWithDefault(msg, 3, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!CountryAppConfig}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new CountryAppConfig;
    return CountryAppConfig.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!AppConfigResponse.CountryAppConfig} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!AppConfigResponse.CountryAppConfig}
   */
  static deserializeBinaryFromReader(msg: CountryAppConfig, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setCountry(value1);
        break;
      case 2:
        var value2 = /** @type {string} */ (reader.readString());
        msg.setApiUrl(value2);
        break;
      case 3:
        var value3 = /** @type {string} */ (reader.readString());
        msg.setAssetUrlPrefix(value3);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!AppConfigResponse.CountryAppConfig} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: AppConfigResponse.CountryAppConfig, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getCountry();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
    f = message.getApiUrl();
    if (f.length > 0) {
      writer.writeString(
        2,
        f
      );
    }
    f = message.getAssetUrlPrefix();
    if (f.length > 0) {
      writer.writeString(
        3,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    AppConfigResponse.CountryAppConfig.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string country = 1;
   * @return {string}
   */
  getCountry(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setCountry(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


  /**
   * optional string api_url = 2;
   * @return {string}
   */
  getApiUrl(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
  };


  /** @param {string} value */
  setApiUrl(value: string) {
    jspb.Message.setProto3StringField(this, 2, value);
  }


  /**
   * optional string asset_url_prefix = 3;
   * @return {string}
   */
  getAssetUrlPrefix(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
  };


  /** @param {string} value */
  setAssetUrlPrefix(value: string) {
    jspb.Message.setProto3StringField(this, 3, value);
  }


}
} // namespace AppConfigResponse
export namespace LocaleSyncRequest {
  export interface AsObject {
    localeLanguage: string;
  }
}
export class LocaleSyncRequest extends jspb.Message {
  static readonly displayName = "LocaleSyncRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): LocaleSyncRequest.AsObject {
    return LocaleSyncRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!LocaleSyncRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: LocaleSyncRequest) {
    var f: any;
    var obj: any = {
      localeLanguage: jspb.Message.getFieldWithDefault(msg, 1, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!LocaleSyncRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new LocaleSyncRequest;
    return LocaleSyncRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!LocaleSyncRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!LocaleSyncRequest}
   */
  static deserializeBinaryFromReader(msg: LocaleSyncRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setLocaleLanguage(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!LocaleSyncRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: LocaleSyncRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getLocaleLanguage();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    LocaleSyncRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string locale_language = 1;
   * @return {string}
   */
  getLocaleLanguage(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setLocaleLanguage(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


}
export namespace LocaleSyncResponse {
  export interface AsObject {
    status?: common_legacy_pb.StatusCode;
    localeVersion: string;
    localeLanguage: string;
    localeJsonString: string;
  }
}
export class LocaleSyncResponse extends jspb.Message {
  static readonly displayName = "LocaleSyncResponse";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): LocaleSyncResponse.AsObject {
    return LocaleSyncResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!LocaleSyncResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: LocaleSyncResponse) {
    var f: any;
    var obj: any = {
      status: jspb.Message.getFieldWithDefault(msg, 1, 0),
      localeVersion: jspb.Message.getFieldWithDefault(msg, 2, ""),
      localeLanguage: jspb.Message.getFieldWithDefault(msg, 3, ""),
      localeJsonstring: jspb.Message.getFieldWithDefault(msg, 4, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!LocaleSyncResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new LocaleSyncResponse;
    return LocaleSyncResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!LocaleSyncResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!LocaleSyncResponse}
   */
  static deserializeBinaryFromReader(msg: LocaleSyncResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {!StatusCode} */ (reader.readEnum());
        msg.setStatus(value1);
        break;
      case 2:
        var value2 = /** @type {string} */ (reader.readString());
        msg.setLocaleVersion(value2);
        break;
      case 3:
        var value3 = /** @type {string} */ (reader.readString());
        msg.setLocaleLanguage(value3);
        break;
      case 4:
        var value4 = /** @type {string} */ (reader.readString());
        msg.setLocaleJsonstring(value4);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!LocaleSyncResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: LocaleSyncResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getStatus();
    if (f !== 0.0) {
      writer.writeEnum(
        1,
        f
      );
    }
    f = message.getLocaleVersion();
    if (f.length > 0) {
      writer.writeString(
        2,
        f
      );
    }
    f = message.getLocaleLanguage();
    if (f.length > 0) {
      writer.writeString(
        3,
        f
      );
    }
    f = message.getLocaleJsonstring();
    if (f.length > 0) {
      writer.writeString(
        4,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    LocaleSyncResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional StatusCode status = 1;
   * @return {!StatusCode}
   */
  getStatus(): common_legacy_pb.StatusCode {
    return /** @type {!StatusCode} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
  };


  /** @param {!StatusCode} value */
  setStatus(value: common_legacy_pb.StatusCode) {
    jspb.Message.setProto3EnumField(this, 1, value);
  }


  /**
   * optional string locale_version = 2;
   * @return {string}
   */
  getLocaleVersion(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
  };


  /** @param {string} value */
  setLocaleVersion(value: string) {
    jspb.Message.setProto3StringField(this, 2, value);
  }


  /**
   * optional string locale_language = 3;
   * @return {string}
   */
  getLocaleLanguage(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
  };


  /** @param {string} value */
  setLocaleLanguage(value: string) {
    jspb.Message.setProto3StringField(this, 3, value);
  }


  /**
   * optional string locale_jsonString = 4;
   * @return {string}
   */
  getLocaleJsonstring(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
  };


  /** @param {string} value */
  setLocaleJsonstring(value: string) {
    jspb.Message.setProto3StringField(this, 4, value);
  }


}
export namespace ErrorReportRequest {
  export interface AsObject {
    errorPriority?: errorPriority;
    errorMessage: string;
    errorContext: string;
  }
}
export class ErrorReportRequest extends jspb.Message {
  static readonly displayName = "ErrorReportRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): ErrorReportRequest.AsObject {
    return ErrorReportRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!ErrorReportRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: ErrorReportRequest) {
    var f: any;
    var obj: any = {
      errorPriority: jspb.Message.getFieldWithDefault(msg, 1, 0),
      errorMessage: jspb.Message.getFieldWithDefault(msg, 2, ""),
      errorContext: jspb.Message.getFieldWithDefault(msg, 3, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!ErrorReportRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new ErrorReportRequest;
    return ErrorReportRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!ErrorReportRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!ErrorReportRequest}
   */
  static deserializeBinaryFromReader(msg: ErrorReportRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {!errorPriority} */ (reader.readEnum());
        msg.setErrorPriority(value1);
        break;
      case 2:
        var value2 = /** @type {string} */ (reader.readString());
        msg.setErrorMessage(value2);
        break;
      case 3:
        var value3 = /** @type {string} */ (reader.readString());
        msg.setErrorContext(value3);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!ErrorReportRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: ErrorReportRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getErrorPriority();
    if (f !== 0.0) {
      writer.writeEnum(
        1,
        f
      );
    }
    f = message.getErrorMessage();
    if (f.length > 0) {
      writer.writeString(
        2,
        f
      );
    }
    f = message.getErrorContext();
    if (f.length > 0) {
      writer.writeString(
        3,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    ErrorReportRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional errorPriority error_priority = 1;
   * @return {!errorPriority}
   */
  getErrorPriority(): errorPriority {
    return /** @type {!errorPriority} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
  };


  /** @param {!errorPriority} value */
  setErrorPriority(value: errorPriority) {
    jspb.Message.setProto3EnumField(this, 1, value);
  }


  /**
   * optional string error_message = 2;
   * @return {string}
   */
  getErrorMessage(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
  };


  /** @param {string} value */
  setErrorMessage(value: string) {
    jspb.Message.setProto3StringField(this, 2, value);
  }


  /**
   * optional string error_context = 3;
   * @return {string}
   */
  getErrorContext(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
  };


  /** @param {string} value */
  setErrorContext(value: string) {
    jspb.Message.setProto3StringField(this, 3, value);
  }


}
export namespace ErrorReportResponse {
  export interface AsObject {
    status?: common_legacy_pb.StatusCode;
    errorHandlerOverride: string;
  }
}
export class ErrorReportResponse extends jspb.Message {
  static readonly displayName = "ErrorReportResponse";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): ErrorReportResponse.AsObject {
    return ErrorReportResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!ErrorReportResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: ErrorReportResponse) {
    var f: any;
    var obj: any = {
      status: jspb.Message.getFieldWithDefault(msg, 1, 0),
      errorHandlerOverride: jspb.Message.getFieldWithDefault(msg, 2, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!ErrorReportResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new ErrorReportResponse;
    return ErrorReportResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!ErrorReportResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!ErrorReportResponse}
   */
  static deserializeBinaryFromReader(msg: ErrorReportResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {!StatusCode} */ (reader.readEnum());
        msg.setStatus(value1);
        break;
      case 2:
        var value2 = /** @type {string} */ (reader.readString());
        msg.setErrorHandlerOverride(value2);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!ErrorReportResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: ErrorReportResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getStatus();
    if (f !== 0.0) {
      writer.writeEnum(
        1,
        f
      );
    }
    f = message.getErrorHandlerOverride();
    if (f.length > 0) {
      writer.writeString(
        2,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    ErrorReportResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional StatusCode status = 1;
   * @return {!StatusCode}
   */
  getStatus(): common_legacy_pb.StatusCode {
    return /** @type {!StatusCode} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
  };


  /** @param {!StatusCode} value */
  setStatus(value: common_legacy_pb.StatusCode) {
    jspb.Message.setProto3EnumField(this, 1, value);
  }


  /**
   * optional string error_handler_override = 2;
   * @return {string}
   */
  getErrorHandlerOverride(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
  };


  /** @param {string} value */
  setErrorHandlerOverride(value: string) {
    jspb.Message.setProto3StringField(this, 2, value);
  }


}
export enum errorPriority {
  LOGONLY = 0,
  WARN = 1,
  ALERT = 2,
}
