import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';

import { MgButtonModule } from '@app/src/app/button';
import { MgPipesModule } from '@app/src/app/pipes';

import { GroupJoinButtonModule } from '../GroupJoinButton';
import { MgGroupPreviewComponent } from './MgGroupPreview.component';

@NgModule({
  imports: [
    // Minga dependencies
    GroupJoinButtonModule,
    MgButtonModule,
    MgPipesModule,

    // External dependencies
    CommonModule,
    FlexLayoutModule,
    MatIconModule,
  ],
  declarations: [MgGroupPreviewComponent],
  exports: [MgGroupPreviewComponent],
})
export class MgGroupPreviewModule {}
