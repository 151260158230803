// source: points_internal/points_internal.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

import * as jspb from 'google-protobuf';
export namespace PointsSummaryItem {
  export interface AsObject {
    totalPoints: number;
    issuedTo: number;
  }
}
export class PointsSummaryItem extends jspb.Message {
  static readonly displayName = "PointsSummaryItem";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): PointsSummaryItem.AsObject {
    return PointsSummaryItem.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!PointsSummaryItem} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: PointsSummaryItem) {
    var f: any;
    var obj: any = {
      totalPoints: jspb.Message.getFieldWithDefault(msg, 1, 0),
      issuedTo: jspb.Message.getFieldWithDefault(msg, 2, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!PointsSummaryItem}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new PointsSummaryItem;
    return PointsSummaryItem.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!PointsSummaryItem} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!PointsSummaryItem}
   */
  static deserializeBinaryFromReader(msg: PointsSummaryItem, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {number} */ (reader.readInt32());
        msg.setTotalPoints(value1);
        break;
      case 2:
        var value2 = /** @type {number} */ (reader.readInt32());
        msg.setIssuedTo(value2);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!PointsSummaryItem} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: PointsSummaryItem, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getTotalPoints();
    if (f !== 0) {
      writer.writeInt32(
        1,
        f
      );
    }
    f = message.getIssuedTo();
    if (f !== 0) {
      writer.writeInt32(
        2,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    PointsSummaryItem.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional int32 total_points = 1;
   * @return {number}
   */
  getTotalPoints(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
  };


  /** @param {number} value */
  setTotalPoints(value: number) {
    jspb.Message.setProto3IntField(this, 1, value);
  }


  /**
   * optional int32 issued_to = 2;
   * @return {number}
   */
  getIssuedTo(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
  };


  /** @param {number} value */
  setIssuedTo(value: number) {
    jspb.Message.setProto3IntField(this, 2, value);
  }


}
export namespace GetPointsSummaryReportRequest {
  export interface AsObject {
    mingaId: number;
    startDate: string;
    endDate: string;
    limit: number;
    offset: number;
    issuedByList: number[];
    issuedToList: number[];
    gradeList: string[];
    teamsList: number[];
  }
}
export class GetPointsSummaryReportRequest extends jspb.Message {
  static readonly displayName = "GetPointsSummaryReportRequest";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [6,7,8,9];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, GetPointsSummaryReportRequest.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): GetPointsSummaryReportRequest.AsObject {
    return GetPointsSummaryReportRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!GetPointsSummaryReportRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: GetPointsSummaryReportRequest) {
    var f: any;
    var obj: any = {
      mingaId: jspb.Message.getFieldWithDefault(msg, 1, 0),
      startDate: jspb.Message.getFieldWithDefault(msg, 2, ""),
      endDate: jspb.Message.getFieldWithDefault(msg, 3, ""),
      limit: jspb.Message.getFieldWithDefault(msg, 4, 0),
      offset: jspb.Message.getFieldWithDefault(msg, 5, 0),
      issuedByList: (f = jspb.Message.getRepeatedField(msg, 6)) == null ? undefined : f,
      issuedToList: (f = jspb.Message.getRepeatedField(msg, 7)) == null ? undefined : f,
      gradeList: (f = jspb.Message.getRepeatedField(msg, 8)) == null ? undefined : f,
      teamsList: (f = jspb.Message.getRepeatedField(msg, 9)) == null ? undefined : f
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!GetPointsSummaryReportRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new GetPointsSummaryReportRequest;
    return GetPointsSummaryReportRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!GetPointsSummaryReportRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!GetPointsSummaryReportRequest}
   */
  static deserializeBinaryFromReader(msg: GetPointsSummaryReportRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {number} */ (reader.readInt32());
        msg.setMingaId(value1);
        break;
      case 2:
        var value2 = /** @type {string} */ (reader.readString());
        msg.setStartDate(value2);
        break;
      case 3:
        var value3 = /** @type {string} */ (reader.readString());
        msg.setEndDate(value3);
        break;
      case 4:
        var value4 = /** @type {number} */ (reader.readInt32());
        msg.setLimit(value4);
        break;
      case 5:
        var value5 = /** @type {number} */ (reader.readInt32());
        msg.setOffset(value5);
        break;
      case 6:
        var value6 = /** @type {!Array<number>} */ (reader.readPackedInt32());
        msg.setIssuedByList(value6);
        break;
      case 7:
        var value7 = /** @type {!Array<number>} */ (reader.readPackedInt32());
        msg.setIssuedToList(value7);
        break;
      case 8:
        var value8 = /** @type {string} */ (reader.readString());
        msg.addGrade(value8);
        break;
      case 9:
        var value9 = /** @type {!Array<number>} */ (reader.readPackedInt32());
        msg.setTeamsList(value9);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!GetPointsSummaryReportRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: GetPointsSummaryReportRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getMingaId();
    if (f !== 0) {
      writer.writeInt32(
        1,
        f
      );
    }
    f = message.getStartDate();
    if (f.length > 0) {
      writer.writeString(
        2,
        f
      );
    }
    f = message.getEndDate();
    if (f.length > 0) {
      writer.writeString(
        3,
        f
      );
    }
    f = message.getLimit();
    if (f !== 0) {
      writer.writeInt32(
        4,
        f
      );
    }
    f = message.getOffset();
    if (f !== 0) {
      writer.writeInt32(
        5,
        f
      );
    }
    f = message.getIssuedByList();
    if (f.length > 0) {
      writer.writePackedInt32(
        6,
        f
      );
    }
    f = message.getIssuedToList();
    if (f.length > 0) {
      writer.writePackedInt32(
        7,
        f
      );
    }
    f = message.getGradeList();
    if (f.length > 0) {
      writer.writeRepeatedString(
        8,
        f
      );
    }
    f = message.getTeamsList();
    if (f.length > 0) {
      writer.writePackedInt32(
        9,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    GetPointsSummaryReportRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional int32 minga_id = 1;
   * @return {number}
   */
  getMingaId(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
  };


  /** @param {number} value */
  setMingaId(value: number) {
    jspb.Message.setProto3IntField(this, 1, value);
  }


  /**
   * optional string start_date = 2;
   * @return {string}
   */
  getStartDate(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
  };


  /** @param {string} value */
  setStartDate(value: string) {
    jspb.Message.setProto3StringField(this, 2, value);
  }


  /**
   * optional string end_date = 3;
   * @return {string}
   */
  getEndDate(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
  };


  /** @param {string} value */
  setEndDate(value: string) {
    jspb.Message.setProto3StringField(this, 3, value);
  }


  /**
   * optional int32 limit = 4;
   * @return {number}
   */
  getLimit(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
  };


  /** @param {number} value */
  setLimit(value: number) {
    jspb.Message.setProto3IntField(this, 4, value);
  }


  /**
   * optional int32 offset = 5;
   * @return {number}
   */
  getOffset(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
  };


  /** @param {number} value */
  setOffset(value: number) {
    jspb.Message.setProto3IntField(this, 5, value);
  }


  /**
   * repeated int32 issued_by = 6;
   * @return {!Array<number>}
   */
  getIssuedByList(): number[] {
    return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 6));
  };


  /** @param {!Array<number>} value */
  setIssuedByList(value: number[]) {
    jspb.Message.setField(this, 6, value || []);
  }


  /**
   * @param {number} value
   * @param {number=} opt_index
   */
  addIssuedBy(value: number, opt_index?: number): void {
    jspb.Message.addToRepeatedField(this, 6, value, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearIssuedByList() {
    this.setIssuedByList([]);
  }


  /**
   * repeated int32 issued_to = 7;
   * @return {!Array<number>}
   */
  getIssuedToList(): number[] {
    return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 7));
  };


  /** @param {!Array<number>} value */
  setIssuedToList(value: number[]) {
    jspb.Message.setField(this, 7, value || []);
  }


  /**
   * @param {number} value
   * @param {number=} opt_index
   */
  addIssuedTo(value: number, opt_index?: number): void {
    jspb.Message.addToRepeatedField(this, 7, value, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearIssuedToList() {
    this.setIssuedToList([]);
  }


  /**
   * repeated string grade = 8;
   * @return {!Array<string>}
   */
  getGradeList(): string[] {
    return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 8));
  };


  /** @param {!Array<string>} value */
  setGradeList(value: string[]) {
    jspb.Message.setField(this, 8, value || []);
  }


  /**
   * @param {string} value
   * @param {number=} opt_index
   */
  addGrade(value: string, opt_index?: number): void {
    jspb.Message.addToRepeatedField(this, 8, value, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearGradeList() {
    this.setGradeList([]);
  }


  /**
   * repeated int32 teams = 9;
   * @return {!Array<number>}
   */
  getTeamsList(): number[] {
    return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 9));
  };


  /** @param {!Array<number>} value */
  setTeamsList(value: number[]) {
    jspb.Message.setField(this, 9, value || []);
  }


  /**
   * @param {number} value
   * @param {number=} opt_index
   */
  addTeams(value: number, opt_index?: number): void {
    jspb.Message.addToRepeatedField(this, 9, value, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearTeamsList() {
    this.setTeamsList([]);
  }


}
export namespace GetPointsSummaryReportResponse {
  export interface AsObject {
    itemsList: PointsSummaryItem.AsObject[];
  }
}
export class GetPointsSummaryReportResponse extends jspb.Message {
  static readonly displayName = "GetPointsSummaryReportResponse";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [1];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, GetPointsSummaryReportResponse.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): GetPointsSummaryReportResponse.AsObject {
    return GetPointsSummaryReportResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!GetPointsSummaryReportResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: GetPointsSummaryReportResponse) {
    var f: any;
    var obj: any = {
      itemsList: jspb.Message.toObjectList(msg.getItemsList(),
      PointsSummaryItem.toObject, includeInstance)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!GetPointsSummaryReportResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new GetPointsSummaryReportResponse;
    return GetPointsSummaryReportResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!GetPointsSummaryReportResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!GetPointsSummaryReportResponse}
   */
  static deserializeBinaryFromReader(msg: GetPointsSummaryReportResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new PointsSummaryItem;
        reader.readMessage(value1,PointsSummaryItem.deserializeBinaryFromReader);
        msg.addItems(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!GetPointsSummaryReportResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: GetPointsSummaryReportResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getItemsList();
    if (f.length > 0) {
      writer.writeRepeatedMessage(
        1,
        f,
        PointsSummaryItem.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    GetPointsSummaryReportResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * repeated PointsSummaryItem items = 1;
   * @return {!Array<!PointsSummaryItem>}
   */
  getItemsList(): PointsSummaryItem[] {
    return /** @type{!Array<!PointsSummaryItem>} */ (
      jspb.Message.getRepeatedWrapperField(this, PointsSummaryItem, 1));
  }


  /** @param {!Array<!PointsSummaryItem>} value */
  setItemsList(value?: PointsSummaryItem[]) {
    jspb.Message.setRepeatedWrapperField(this, 1, value);
  }


  /**
   * @param {!PointsSummaryItem=} opt_value
   * @param {number=} opt_index
   * @return {!PointsSummaryItem}
   */
  addItems(opt_value?: PointsSummaryItem, opt_index?: number): PointsSummaryItem {
    return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, PointsSummaryItem, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearItemsList() {
    this.setItemsList([]);
  }


}
export namespace GetTeamPointsSummaryReportRequest {
  export interface AsObject {
    mingaId: number;
    startDate: string;
    endDate: string;
    limit: number;
    offset: number;
    issuedByList: number[];
    teamsList: number[];
  }
}
export class GetTeamPointsSummaryReportRequest extends jspb.Message {
  static readonly displayName = "GetTeamPointsSummaryReportRequest";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [6,7];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, GetTeamPointsSummaryReportRequest.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): GetTeamPointsSummaryReportRequest.AsObject {
    return GetTeamPointsSummaryReportRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!GetTeamPointsSummaryReportRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: GetTeamPointsSummaryReportRequest) {
    var f: any;
    var obj: any = {
      mingaId: jspb.Message.getFieldWithDefault(msg, 1, 0),
      startDate: jspb.Message.getFieldWithDefault(msg, 2, ""),
      endDate: jspb.Message.getFieldWithDefault(msg, 3, ""),
      limit: jspb.Message.getFieldWithDefault(msg, 4, 0),
      offset: jspb.Message.getFieldWithDefault(msg, 5, 0),
      issuedByList: (f = jspb.Message.getRepeatedField(msg, 6)) == null ? undefined : f,
      teamsList: (f = jspb.Message.getRepeatedField(msg, 7)) == null ? undefined : f
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!GetTeamPointsSummaryReportRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new GetTeamPointsSummaryReportRequest;
    return GetTeamPointsSummaryReportRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!GetTeamPointsSummaryReportRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!GetTeamPointsSummaryReportRequest}
   */
  static deserializeBinaryFromReader(msg: GetTeamPointsSummaryReportRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {number} */ (reader.readInt32());
        msg.setMingaId(value1);
        break;
      case 2:
        var value2 = /** @type {string} */ (reader.readString());
        msg.setStartDate(value2);
        break;
      case 3:
        var value3 = /** @type {string} */ (reader.readString());
        msg.setEndDate(value3);
        break;
      case 4:
        var value4 = /** @type {number} */ (reader.readInt32());
        msg.setLimit(value4);
        break;
      case 5:
        var value5 = /** @type {number} */ (reader.readInt32());
        msg.setOffset(value5);
        break;
      case 6:
        var value6 = /** @type {!Array<number>} */ (reader.readPackedInt32());
        msg.setIssuedByList(value6);
        break;
      case 7:
        var value7 = /** @type {!Array<number>} */ (reader.readPackedInt32());
        msg.setTeamsList(value7);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!GetTeamPointsSummaryReportRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: GetTeamPointsSummaryReportRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getMingaId();
    if (f !== 0) {
      writer.writeInt32(
        1,
        f
      );
    }
    f = message.getStartDate();
    if (f.length > 0) {
      writer.writeString(
        2,
        f
      );
    }
    f = message.getEndDate();
    if (f.length > 0) {
      writer.writeString(
        3,
        f
      );
    }
    f = message.getLimit();
    if (f !== 0) {
      writer.writeInt32(
        4,
        f
      );
    }
    f = message.getOffset();
    if (f !== 0) {
      writer.writeInt32(
        5,
        f
      );
    }
    f = message.getIssuedByList();
    if (f.length > 0) {
      writer.writePackedInt32(
        6,
        f
      );
    }
    f = message.getTeamsList();
    if (f.length > 0) {
      writer.writePackedInt32(
        7,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    GetTeamPointsSummaryReportRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional int32 minga_id = 1;
   * @return {number}
   */
  getMingaId(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
  };


  /** @param {number} value */
  setMingaId(value: number) {
    jspb.Message.setProto3IntField(this, 1, value);
  }


  /**
   * optional string start_date = 2;
   * @return {string}
   */
  getStartDate(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
  };


  /** @param {string} value */
  setStartDate(value: string) {
    jspb.Message.setProto3StringField(this, 2, value);
  }


  /**
   * optional string end_date = 3;
   * @return {string}
   */
  getEndDate(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
  };


  /** @param {string} value */
  setEndDate(value: string) {
    jspb.Message.setProto3StringField(this, 3, value);
  }


  /**
   * optional int32 limit = 4;
   * @return {number}
   */
  getLimit(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
  };


  /** @param {number} value */
  setLimit(value: number) {
    jspb.Message.setProto3IntField(this, 4, value);
  }


  /**
   * optional int32 offset = 5;
   * @return {number}
   */
  getOffset(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
  };


  /** @param {number} value */
  setOffset(value: number) {
    jspb.Message.setProto3IntField(this, 5, value);
  }


  /**
   * repeated int32 issued_by = 6;
   * @return {!Array<number>}
   */
  getIssuedByList(): number[] {
    return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 6));
  };


  /** @param {!Array<number>} value */
  setIssuedByList(value: number[]) {
    jspb.Message.setField(this, 6, value || []);
  }


  /**
   * @param {number} value
   * @param {number=} opt_index
   */
  addIssuedBy(value: number, opt_index?: number): void {
    jspb.Message.addToRepeatedField(this, 6, value, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearIssuedByList() {
    this.setIssuedByList([]);
  }


  /**
   * repeated int32 teams = 7;
   * @return {!Array<number>}
   */
  getTeamsList(): number[] {
    return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 7));
  };


  /** @param {!Array<number>} value */
  setTeamsList(value: number[]) {
    jspb.Message.setField(this, 7, value || []);
  }


  /**
   * @param {number} value
   * @param {number=} opt_index
   */
  addTeams(value: number, opt_index?: number): void {
    jspb.Message.addToRepeatedField(this, 7, value, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearTeamsList() {
    this.setTeamsList([]);
  }


}
export namespace GetTeamPointsSummaryReportResponse {
  export interface AsObject {
    itemsList: PointsSummaryItem.AsObject[];
  }
}
export class GetTeamPointsSummaryReportResponse extends jspb.Message {
  static readonly displayName = "GetTeamPointsSummaryReportResponse";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [1];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, GetTeamPointsSummaryReportResponse.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): GetTeamPointsSummaryReportResponse.AsObject {
    return GetTeamPointsSummaryReportResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!GetTeamPointsSummaryReportResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: GetTeamPointsSummaryReportResponse) {
    var f: any;
    var obj: any = {
      itemsList: jspb.Message.toObjectList(msg.getItemsList(),
      PointsSummaryItem.toObject, includeInstance)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!GetTeamPointsSummaryReportResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new GetTeamPointsSummaryReportResponse;
    return GetTeamPointsSummaryReportResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!GetTeamPointsSummaryReportResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!GetTeamPointsSummaryReportResponse}
   */
  static deserializeBinaryFromReader(msg: GetTeamPointsSummaryReportResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new PointsSummaryItem;
        reader.readMessage(value1,PointsSummaryItem.deserializeBinaryFromReader);
        msg.addItems(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!GetTeamPointsSummaryReportResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: GetTeamPointsSummaryReportResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getItemsList();
    if (f.length > 0) {
      writer.writeRepeatedMessage(
        1,
        f,
        PointsSummaryItem.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    GetTeamPointsSummaryReportResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * repeated PointsSummaryItem items = 1;
   * @return {!Array<!PointsSummaryItem>}
   */
  getItemsList(): PointsSummaryItem[] {
    return /** @type{!Array<!PointsSummaryItem>} */ (
      jspb.Message.getRepeatedWrapperField(this, PointsSummaryItem, 1));
  }


  /** @param {!Array<!PointsSummaryItem>} value */
  setItemsList(value?: PointsSummaryItem[]) {
    jspb.Message.setRepeatedWrapperField(this, 1, value);
  }


  /**
   * @param {!PointsSummaryItem=} opt_value
   * @param {number=} opt_index
   * @return {!PointsSummaryItem}
   */
  addItems(opt_value?: PointsSummaryItem, opt_index?: number): PointsSummaryItem {
    return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, PointsSummaryItem, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearItemsList() {
    this.setItemsList([]);
  }


}
