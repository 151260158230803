import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import {
  FlexStudentsStats,
  FtmReportFilterType,
  ReportTypes,
} from 'libs/domain';
import { ColumnInfo, FlexTimeReportColumns } from 'libs/shared';

import { ReportDatasourceService } from '@app/src/app/components/manager-report/services/report-datasource.service';

import { FlexTimeManagerRoutes } from '@modules/flex-time-manager/constants';

import { SystemAlertModalService } from '@shared/components/system-alert-modal';
import { SystemAlertSnackBarService } from '@shared/components/system-alert-snackbar';

import { FtmReportsService } from './ftm-reports.service';

@Injectable()
export class FtmReportsStudentsDatasourceService extends ReportDatasourceService<FlexStudentsStats> {
  historyInfo = {
    filter: FtmReportFilterType.REGISTRANT,
    url: `${FlexTimeManagerRoutes.ROOT}/${FlexTimeManagerRoutes.REPORTS}/${ReportTypes.FLEX_PERIOD_REGISTERED}`,
  };

  constructor(
    _router: Router,
    private _reportService: FtmReportsService,
    _alertModal: SystemAlertModalService,
    _snackBar: SystemAlertSnackBarService,
  ) {
    super(
      _router,
      _reportService,
      FtmReportsStudentsDatasourceService.name,
      _alertModal,
      _snackBar,
    );
    const linkCols: ColumnInfo[] = [
      {
        header: '',
        key: this.TEMPLATE_KEYS.HISTORY,
        columnClasses: ['w-16'],
      },
    ];
    super.setDisplayColumns(FlexTimeReportColumns.STUDENTS, linkCols);
  }

  async fetch(
    offset: number,
    limit: number,
  ): Promise<{ items: FlexStudentsStats[]; pageToken: number }> {
    return await this._reportService.getAStudentsReport(
      offset,
      limit,
      this._currentSort,
    );
  }
}
