import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { MediaService } from '@shared/services/media';

import {
  FORM_FIELDS,
  LOCKED_STATES,
  MyClassMessages,
} from '../../constants/tt-my-class.constants';
import { AssignmentType, FormState } from '../../types/tt-my-class.types';

@Component({
  selector: 'mg-tt-assignment-footer',
  templateUrl: './tt-assignment-footer.component.html',
  styleUrls: ['./tt-assignment-footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TtAssignmentFooterComponent implements OnInit, OnDestroy {
  @Input() form: UntypedFormGroup;
  @Input() formErrors: string[] = [];
  @Input() formState: FormState;
  @Input() assignSuccess$: Observable<void>;
  @Output() assign = new EventEmitter<void>();

  private _destroyedSubject = new ReplaySubject<void>(1);

  private _remountSubject = new BehaviorSubject<boolean>(false);
  public remount$ = this._remountSubject.asObservable();

  public LOCKED_STATES: FormState[] = LOCKED_STATES;
  public FORM_FIELDS = FORM_FIELDS;
  public ASSIGNMENT_TYPE = AssignmentType;
  public MESSAGES = MyClassMessages;

  constructor(
    public mediaService: MediaService,
    private _cdr: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this._onSelectedActionChange();
    this._subscribeToAssignmentSuccess();
  }

  ngOnDestroy(): void {
    this._destroyedSubject.next();
    this._destroyedSubject.complete();
  }

  private _subscribeToAssignmentSuccess() {
    this.assignSuccess$
      .pipe(takeUntil(this._destroyedSubject))
      .subscribe(val => {
        // this forces a unmount/remount of the sub fields to reset them
        // using formControl.reset() doesn't work because there's some logic we need to
        // set based on type and time etc..
        this._remountSubject.next(true);
        setTimeout(() => {
          this._remountSubject.next(false);
        }, 1);
      });
  }

  private _onSelectedActionChange() {
    this.form
      .get(FORM_FIELDS.SELECTED_ACTION)
      .valueChanges.pipe(takeUntil(this._destroyedSubject))
      .subscribe(() => {
        // we need to trigger change detection to force a re-render of the mg-override-layout-footer
        this._cdr.detectChanges();
      });
  }
}
