// GENERATED CODE -- DO NOT EDIT!

import {grpc} from '@improbable-eng/grpc-web';

import * as date_range_date_range_preset_pb from '../date_range/date_range_preset_pb';
import * as common_date_pb from '../common/date_pb';

export class DateRangePresets {
  static readonly serviceName = "date_range_preset.v1.DateRangePresets";

  static readonly GetDateRangePreset = {
    methodName: "GetDateRangePreset",
    service: DateRangePresets,
    requestStream: false,
    responseStream: false,
    requestType: date_range_date_range_preset_pb.GetDateRangePresetRequest,
    responseType: date_range_date_range_preset_pb.GetDateRangePresetResponse,
  };

  static readonly GetDateRangePresets = {
    methodName: "GetDateRangePresets",
    service: DateRangePresets,
    requestStream: false,
    responseStream: false,
    requestType: date_range_date_range_preset_pb.GetDateRangePresetsRequest,
    responseType: date_range_date_range_preset_pb.GetDateRangePresetsResponse,
  };

  static readonly CreateDateRangePreset = {
    methodName: "CreateDateRangePreset",
    service: DateRangePresets,
    requestStream: false,
    responseStream: false,
    requestType: date_range_date_range_preset_pb.CreateDateRangePresetRequest,
    responseType: date_range_date_range_preset_pb.CreateDateRangePresetResponse,
  };

  static readonly UpdateDateRangePreset = {
    methodName: "UpdateDateRangePreset",
    service: DateRangePresets,
    requestStream: false,
    responseStream: false,
    requestType: date_range_date_range_preset_pb.UpdateDateRangePresetRequest,
    responseType: date_range_date_range_preset_pb.UpdateDateRangePresetResponse,
  };

  static readonly DeleteDateRangePreset = {
    methodName: "DeleteDateRangePreset",
    service: DateRangePresets,
    requestStream: false,
    responseStream: false,
    requestType: date_range_date_range_preset_pb.DeleteDateRangePresetRequest,
    responseType: date_range_date_range_preset_pb.DeleteDateRangePresetResponse,
  };

  static readonly ToggleDateRangePresetActive = {
    methodName: "ToggleDateRangePresetActive",
    service: DateRangePresets,
    requestStream: false,
    responseStream: false,
    requestType: date_range_date_range_preset_pb.ToggleDateRangePresetActiveRequest,
    responseType: date_range_date_range_preset_pb.ToggleDateRangePresetActiveResponse,
  };

}

export class DateRangePresetsClient {
  getDateRangePreset() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  getDateRangePresets() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  createDateRangePreset() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  updateDateRangePreset() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  deleteDateRangePreset() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  toggleDateRangePresetActive() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
}
