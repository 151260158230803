// Linear Interpolation
export function lerp(start: number, end: number, alpha: number) {
  return start * (1 - alpha) + end * alpha
}

/**
 * Rectangle with the origin at the top left
 */
export interface IPositionalRect {
  top: number;
  left: number;
  width: number;
  height: number;
}

export function isPointWithinRect(x: number, y: number, rect: IPositionalRect) {
  return rect.left <= x && x <= rect.left + rect.width && rect.top <= y &&
    y <= rect.top + rect.height;
}

/**
 * Distance between two points
 * @param x1 point 1 x coordinate
 * @param y1 point 1 y coordinate
 * @param x2 point 2 x coordinate
 * @param y2 point 2 y coordinate
 */
export function pointDist(x1: number, y1: number, x2: number, y2: number) {
  const a = x1 - x2;
  const b = y1 - y2;
  return Math.sqrt((a * a) + (b * b));
}
