// source: gateway/content_views.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

import * as jspb from 'google-protobuf';
import * as common_legacy_pb from '../common/legacy_pb';
import * as common_date_pb from '../common/date_pb';
import * as gateway_comment_pb from '../gateway/comment_pb';
import * as gateway_person_view_pb from '../gateway/person_view_pb';
import * as gateway_mention_pb from '../gateway/mention_pb';
import * as gateway_banner_pb from '../gateway/banner_pb';
import * as image_image_pb from '../image/image_pb';
import * as content_common_pb from '../content/common_pb';
import * as designed_svg_designed_svg_pb from '../designed_svg/designed_svg_pb';
import * as content_poll_answer_pb from '../content/poll/answer_pb';
export namespace FeedComment {
  export interface AsObject {
    displayName: string;
    body: string;
    timestamp: number;
    personView?: gateway_person_view_pb.PersonViewMinimal.AsObject;
    commentContextHash: string;
    likeCount: number;
    bodyMentionRangeList: gateway_mention_pb.MentionRange.AsObject[];
    hasLiked: boolean;
  }
}
export class FeedComment extends jspb.Message {
  static readonly displayName = "FeedComment";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [7];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, FeedComment.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): FeedComment.AsObject {
    return FeedComment.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!FeedComment} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: FeedComment) {
    var f: any;
    var obj: any = {
      displayName: jspb.Message.getFieldWithDefault(msg, 1, ""),
      body: jspb.Message.getFieldWithDefault(msg, 2, ""),
      timestamp: jspb.Message.getFieldWithDefault(msg, 3, 0),
      personView: (f = msg.getPersonView()) && gateway_person_view_pb.PersonViewMinimal.toObject(includeInstance, f),
      commentContextHash: jspb.Message.getFieldWithDefault(msg, 5, ""),
      likeCount: jspb.Message.getFieldWithDefault(msg, 6, 0),
      bodyMentionRangeList: jspb.Message.toObjectList(msg.getBodyMentionRangeList(),
      gateway_mention_pb.MentionRange.toObject, includeInstance),
      hasLiked: jspb.Message.getBooleanFieldWithDefault(msg, 8, false)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!FeedComment}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new FeedComment;
    return FeedComment.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!FeedComment} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!FeedComment}
   */
  static deserializeBinaryFromReader(msg: FeedComment, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setDisplayName(value1);
        break;
      case 2:
        var value2 = /** @type {string} */ (reader.readString());
        msg.setBody(value2);
        break;
      case 3:
        var value3 = /** @type {number} */ (reader.readInt64());
        msg.setTimestamp(value3);
        break;
      case 4:
        var value4 = new gateway_person_view_pb.PersonViewMinimal;
        reader.readMessage(value4,gateway_person_view_pb.PersonViewMinimal.deserializeBinaryFromReader);
        msg.setPersonView(value4);
        break;
      case 5:
        var value5 = /** @type {string} */ (reader.readString());
        msg.setCommentContextHash(value5);
        break;
      case 6:
        var value6 = /** @type {number} */ (reader.readInt32());
        msg.setLikeCount(value6);
        break;
      case 7:
        var value7 = new gateway_mention_pb.MentionRange;
        reader.readMessage(value7,gateway_mention_pb.MentionRange.deserializeBinaryFromReader);
        msg.addBodyMentionRange(value7);
        break;
      case 8:
        var value8 = /** @type {boolean} */ (reader.readBool());
        msg.setHasLiked(value8);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!FeedComment} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: FeedComment, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getDisplayName();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
    f = message.getBody();
    if (f.length > 0) {
      writer.writeString(
        2,
        f
      );
    }
    f = message.getTimestamp();
    if (f !== 0) {
      writer.writeInt64(
        3,
        f
      );
    }
    f = message.getPersonView();
    if (f != null) {
      writer.writeMessage(
        4,
        f,
        gateway_person_view_pb.PersonViewMinimal.serializeBinaryToWriter
      );
    }
    f = message.getCommentContextHash();
    if (f.length > 0) {
      writer.writeString(
        5,
        f
      );
    }
    f = message.getLikeCount();
    if (f !== 0) {
      writer.writeInt32(
        6,
        f
      );
    }
    f = message.getBodyMentionRangeList();
    if (f.length > 0) {
      writer.writeRepeatedMessage(
        7,
        f,
        gateway_mention_pb.MentionRange.serializeBinaryToWriter
      );
    }
    f = message.getHasLiked();
    if (f) {
      writer.writeBool(
        8,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    FeedComment.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string display_name = 1;
   * @return {string}
   */
  getDisplayName(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setDisplayName(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


  /**
   * optional string body = 2;
   * @return {string}
   */
  getBody(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
  };


  /** @param {string} value */
  setBody(value: string) {
    jspb.Message.setProto3StringField(this, 2, value);
  }


  /**
   * optional int64 timestamp = 3;
   * @return {number}
   */
  getTimestamp(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
  };


  /** @param {number} value */
  setTimestamp(value: number) {
    jspb.Message.setProto3IntField(this, 3, value);
  }


  /**
   * optional PersonViewMinimal person_view = 4;
   * @return {?PersonViewMinimal}
   */
  getPersonView(): gateway_person_view_pb.PersonViewMinimal {
    return /** @type{?PersonViewMinimal} */ (
      jspb.Message.getWrapperField(this, gateway_person_view_pb.PersonViewMinimal, 4));
  }


  /** @param {?PersonViewMinimal|undefined} value */
  setPersonView(value?: gateway_person_view_pb.PersonViewMinimal) {
    jspb.Message.setWrapperField(this, 4, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearPersonView() {
    this.setPersonView(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasPersonView(): boolean {
    return jspb.Message.getField(this, 4) != null;
  }


  /**
   * optional string comment_context_hash = 5;
   * @return {string}
   */
  getCommentContextHash(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
  };


  /** @param {string} value */
  setCommentContextHash(value: string) {
    jspb.Message.setProto3StringField(this, 5, value);
  }


  /**
   * optional int32 like_count = 6;
   * @return {number}
   */
  getLikeCount(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
  };


  /** @param {number} value */
  setLikeCount(value: number) {
    jspb.Message.setProto3IntField(this, 6, value);
  }


  /**
   * repeated MentionRange body_mention_range = 7;
   * @return {!Array<!MentionRange>}
   */
  getBodyMentionRangeList(): gateway_mention_pb.MentionRange[] {
    return /** @type{!Array<!MentionRange>} */ (
      jspb.Message.getRepeatedWrapperField(this, gateway_mention_pb.MentionRange, 7));
  }


  /** @param {!Array<!MentionRange>} value */
  setBodyMentionRangeList(value?: gateway_mention_pb.MentionRange[]) {
    jspb.Message.setRepeatedWrapperField(this, 7, value);
  }


  /**
   * @param {!MentionRange=} opt_value
   * @param {number=} opt_index
   * @return {!MentionRange}
   */
  addBodyMentionRange(opt_value?: gateway_mention_pb.MentionRange, opt_index?: number): gateway_mention_pb.MentionRange {
    return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, gateway_mention_pb.MentionRange, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearBodyMentionRangeList() {
    this.setBodyMentionRangeList([]);
  }


  /**
   * optional bool has_liked = 8;
   * @return {boolean}
   */
  getHasLiked(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
  };


  /** @param {boolean} value */
  setHasLiked(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 8, value);
  }


}
export namespace LongCardView {
  export interface AsObject {
    title: string;
    fullBody: string;
    authorDisplayName: string;
    authorAvatarUrl: string;
    timestamp: number;
    imageList: image_image_pb.ImageInfo.AsObject[];
    videoUrl: string;
    likeCount: number;
    commentCount: number;
    totalCommentCount: number;
    commentList: gateway_comment_pb.CommentContent.AsObject[];
    commentListPage: number;
    authorIconUrl: string;
    authorIconColor: string;
    authorRoleName: string;
    authorPersonView?: gateway_person_view_pb.PersonViewMinimal.AsObject;
    mediaWidth: number;
    mediaHeight: number;
    lightContent: boolean;
    gradientOverlap: boolean;
    backgroundColor: string;
    gradientSize: number;
    disableActions: boolean;
    cloudflareVideoUrl: string;
    cloudflareUid: string;
    cloudflareReady: boolean;
    designedSvg?: designed_svg_designed_svg_pb.DesignedSvg.AsObject;
    logoAnchor?: content_common_pb.LogoAnchor;
    customLogoImage?: image_image_pb.ImageInfo.AsObject;
    allowedCommentingRoleTypesList: string[];
    ownerGroupHash: string;
    hasLiked: boolean;
  }
}
export class LongCardView extends jspb.Message {
  static readonly displayName = "LongCardView";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [6,10,37];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, LongCardView.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): LongCardView.AsObject {
    return LongCardView.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!LongCardView} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: LongCardView) {
    var f: any;
    var obj: any = {
      title: jspb.Message.getFieldWithDefault(msg, 1, ""),
      fullBody: jspb.Message.getFieldWithDefault(msg, 2, ""),
      authorDisplayName: jspb.Message.getFieldWithDefault(msg, 3, ""),
      authorAvatarUrl: jspb.Message.getFieldWithDefault(msg, 4, ""),
      timestamp: jspb.Message.getFieldWithDefault(msg, 5, 0),
      imageList: jspb.Message.toObjectList(msg.getImageList(),
      image_image_pb.ImageInfo.toObject, includeInstance),
      videoUrl: jspb.Message.getFieldWithDefault(msg, 7, ""),
      likeCount: jspb.Message.getFieldWithDefault(msg, 8, 0),
      commentCount: jspb.Message.getFieldWithDefault(msg, 9, 0),
      totalCommentCount: jspb.Message.getFieldWithDefault(msg, 18, 0),
      commentList: jspb.Message.toObjectList(msg.getCommentList(),
      gateway_comment_pb.CommentContent.toObject, includeInstance),
      commentListPage: jspb.Message.getFieldWithDefault(msg, 11, 0),
      authorIconUrl: jspb.Message.getFieldWithDefault(msg, 12, ""),
      authorIconColor: jspb.Message.getFieldWithDefault(msg, 13, ""),
      authorRoleName: jspb.Message.getFieldWithDefault(msg, 14, ""),
      authorPersonView: (f = msg.getAuthorPersonView()) && gateway_person_view_pb.PersonViewMinimal.toObject(includeInstance, f),
      mediaWidth: jspb.Message.getFieldWithDefault(msg, 16, 0),
      mediaHeight: jspb.Message.getFieldWithDefault(msg, 17, 0),
      lightContent: jspb.Message.getBooleanFieldWithDefault(msg, 19, false),
      gradientOverlap: jspb.Message.getBooleanFieldWithDefault(msg, 20, false),
      backgroundColor: jspb.Message.getFieldWithDefault(msg, 21, ""),
      gradientSize: jspb.Message.getFloatingPointFieldWithDefault(msg, 23, 0.0),
      disableActions: jspb.Message.getBooleanFieldWithDefault(msg, 22, false),
      cloudflareVideoUrl: jspb.Message.getFieldWithDefault(msg, 32, ""),
      cloudflareUid: jspb.Message.getFieldWithDefault(msg, 33, ""),
      cloudflareReady: jspb.Message.getBooleanFieldWithDefault(msg, 34, false),
      designedSvg: (f = msg.getDesignedSvg()) && designed_svg_designed_svg_pb.DesignedSvg.toObject(includeInstance, f),
      logoAnchor: jspb.Message.getFieldWithDefault(msg, 36, 0),
      customLogoImage: (f = msg.getCustomLogoImage()) && image_image_pb.ImageInfo.toObject(includeInstance, f),
      allowedCommentingRoleTypesList: (f = jspb.Message.getRepeatedField(msg, 37)) == null ? undefined : f,
      ownerGroupHash: jspb.Message.getFieldWithDefault(msg, 38, ""),
      hasLiked: jspb.Message.getBooleanFieldWithDefault(msg, 40, false)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!LongCardView}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new LongCardView;
    return LongCardView.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!LongCardView} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!LongCardView}
   */
  static deserializeBinaryFromReader(msg: LongCardView, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setTitle(value1);
        break;
      case 2:
        var value2 = /** @type {string} */ (reader.readString());
        msg.setFullBody(value2);
        break;
      case 3:
        var value3 = /** @type {string} */ (reader.readString());
        msg.setAuthorDisplayName(value3);
        break;
      case 4:
        var value4 = /** @type {string} */ (reader.readString());
        msg.setAuthorAvatarUrl(value4);
        break;
      case 5:
        var value5 = /** @type {number} */ (reader.readInt64());
        msg.setTimestamp(value5);
        break;
      case 6:
        var value6 = new image_image_pb.ImageInfo;
        reader.readMessage(value6,image_image_pb.ImageInfo.deserializeBinaryFromReader);
        msg.addImage(value6);
        break;
      case 7:
        var value7 = /** @type {string} */ (reader.readString());
        msg.setVideoUrl(value7);
        break;
      case 8:
        var value8 = /** @type {number} */ (reader.readInt32());
        msg.setLikeCount(value8);
        break;
      case 9:
        var value9 = /** @type {number} */ (reader.readInt32());
        msg.setCommentCount(value9);
        break;
      case 18:
        var value18 = /** @type {number} */ (reader.readInt32());
        msg.setTotalCommentCount(value18);
        break;
      case 10:
        var value10 = new gateway_comment_pb.CommentContent;
        reader.readMessage(value10,gateway_comment_pb.CommentContent.deserializeBinaryFromReader);
        msg.addComment(value10);
        break;
      case 11:
        var value11 = /** @type {number} */ (reader.readInt32());
        msg.setCommentListPage(value11);
        break;
      case 12:
        var value12 = /** @type {string} */ (reader.readString());
        msg.setAuthorIconUrl(value12);
        break;
      case 13:
        var value13 = /** @type {string} */ (reader.readString());
        msg.setAuthorIconColor(value13);
        break;
      case 14:
        var value14 = /** @type {string} */ (reader.readString());
        msg.setAuthorRoleName(value14);
        break;
      case 15:
        var value15 = new gateway_person_view_pb.PersonViewMinimal;
        reader.readMessage(value15,gateway_person_view_pb.PersonViewMinimal.deserializeBinaryFromReader);
        msg.setAuthorPersonView(value15);
        break;
      case 16:
        var value16 = /** @type {number} */ (reader.readInt32());
        msg.setMediaWidth(value16);
        break;
      case 17:
        var value17 = /** @type {number} */ (reader.readInt32());
        msg.setMediaHeight(value17);
        break;
      case 19:
        var value19 = /** @type {boolean} */ (reader.readBool());
        msg.setLightContent(value19);
        break;
      case 20:
        var value20 = /** @type {boolean} */ (reader.readBool());
        msg.setGradientOverlap(value20);
        break;
      case 21:
        var value21 = /** @type {string} */ (reader.readString());
        msg.setBackgroundColor(value21);
        break;
      case 23:
        var value23 = /** @type {number} */ (reader.readFloat());
        msg.setGradientSize(value23);
        break;
      case 22:
        var value22 = /** @type {boolean} */ (reader.readBool());
        msg.setDisableActions(value22);
        break;
      case 32:
        var value32 = /** @type {string} */ (reader.readString());
        msg.setCloudflareVideoUrl(value32);
        break;
      case 33:
        var value33 = /** @type {string} */ (reader.readString());
        msg.setCloudflareUid(value33);
        break;
      case 34:
        var value34 = /** @type {boolean} */ (reader.readBool());
        msg.setCloudflareReady(value34);
        break;
      case 35:
        var value35 = new designed_svg_designed_svg_pb.DesignedSvg;
        reader.readMessage(value35,designed_svg_designed_svg_pb.DesignedSvg.deserializeBinaryFromReader);
        msg.setDesignedSvg(value35);
        break;
      case 36:
        var value36 = /** @type {!LogoAnchor} */ (reader.readEnum());
        msg.setLogoAnchor(value36);
        break;
      case 39:
        var value39 = new image_image_pb.ImageInfo;
        reader.readMessage(value39,image_image_pb.ImageInfo.deserializeBinaryFromReader);
        msg.setCustomLogoImage(value39);
        break;
      case 37:
        var value37 = /** @type {string} */ (reader.readString());
        msg.addAllowedCommentingRoleTypes(value37);
        break;
      case 38:
        var value38 = /** @type {string} */ (reader.readString());
        msg.setOwnerGroupHash(value38);
        break;
      case 40:
        var value40 = /** @type {boolean} */ (reader.readBool());
        msg.setHasLiked(value40);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!LongCardView} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: LongCardView, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getTitle();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
    f = message.getFullBody();
    if (f.length > 0) {
      writer.writeString(
        2,
        f
      );
    }
    f = message.getAuthorDisplayName();
    if (f.length > 0) {
      writer.writeString(
        3,
        f
      );
    }
    f = message.getAuthorAvatarUrl();
    if (f.length > 0) {
      writer.writeString(
        4,
        f
      );
    }
    f = message.getTimestamp();
    if (f !== 0) {
      writer.writeInt64(
        5,
        f
      );
    }
    f = message.getImageList();
    if (f.length > 0) {
      writer.writeRepeatedMessage(
        6,
        f,
        image_image_pb.ImageInfo.serializeBinaryToWriter
      );
    }
    f = message.getVideoUrl();
    if (f.length > 0) {
      writer.writeString(
        7,
        f
      );
    }
    f = message.getLikeCount();
    if (f !== 0) {
      writer.writeInt32(
        8,
        f
      );
    }
    f = message.getCommentCount();
    if (f !== 0) {
      writer.writeInt32(
        9,
        f
      );
    }
    f = message.getTotalCommentCount();
    if (f !== 0) {
      writer.writeInt32(
        18,
        f
      );
    }
    f = message.getCommentList();
    if (f.length > 0) {
      writer.writeRepeatedMessage(
        10,
        f,
        gateway_comment_pb.CommentContent.serializeBinaryToWriter
      );
    }
    f = message.getCommentListPage();
    if (f !== 0) {
      writer.writeInt32(
        11,
        f
      );
    }
    f = message.getAuthorIconUrl();
    if (f.length > 0) {
      writer.writeString(
        12,
        f
      );
    }
    f = message.getAuthorIconColor();
    if (f.length > 0) {
      writer.writeString(
        13,
        f
      );
    }
    f = message.getAuthorRoleName();
    if (f.length > 0) {
      writer.writeString(
        14,
        f
      );
    }
    f = message.getAuthorPersonView();
    if (f != null) {
      writer.writeMessage(
        15,
        f,
        gateway_person_view_pb.PersonViewMinimal.serializeBinaryToWriter
      );
    }
    f = message.getMediaWidth();
    if (f !== 0) {
      writer.writeInt32(
        16,
        f
      );
    }
    f = message.getMediaHeight();
    if (f !== 0) {
      writer.writeInt32(
        17,
        f
      );
    }
    f = message.getLightContent();
    if (f) {
      writer.writeBool(
        19,
        f
      );
    }
    f = message.getGradientOverlap();
    if (f) {
      writer.writeBool(
        20,
        f
      );
    }
    f = message.getBackgroundColor();
    if (f.length > 0) {
      writer.writeString(
        21,
        f
      );
    }
    f = message.getGradientSize();
    if (f !== 0.0) {
      writer.writeFloat(
        23,
        f
      );
    }
    f = message.getDisableActions();
    if (f) {
      writer.writeBool(
        22,
        f
      );
    }
    f = message.getCloudflareVideoUrl();
    if (f.length > 0) {
      writer.writeString(
        32,
        f
      );
    }
    f = message.getCloudflareUid();
    if (f.length > 0) {
      writer.writeString(
        33,
        f
      );
    }
    f = message.getCloudflareReady();
    if (f) {
      writer.writeBool(
        34,
        f
      );
    }
    f = message.getDesignedSvg();
    if (f != null) {
      writer.writeMessage(
        35,
        f,
        designed_svg_designed_svg_pb.DesignedSvg.serializeBinaryToWriter
      );
    }
    f = message.getLogoAnchor();
    if (f !== 0.0) {
      writer.writeEnum(
        36,
        f
      );
    }
    f = message.getCustomLogoImage();
    if (f != null) {
      writer.writeMessage(
        39,
        f,
        image_image_pb.ImageInfo.serializeBinaryToWriter
      );
    }
    f = message.getAllowedCommentingRoleTypesList();
    if (f.length > 0) {
      writer.writeRepeatedString(
        37,
        f
      );
    }
    f = message.getOwnerGroupHash();
    if (f.length > 0) {
      writer.writeString(
        38,
        f
      );
    }
    f = message.getHasLiked();
    if (f) {
      writer.writeBool(
        40,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    LongCardView.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string title = 1;
   * @return {string}
   */
  getTitle(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setTitle(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


  /**
   * optional string full_body = 2;
   * @return {string}
   */
  getFullBody(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
  };


  /** @param {string} value */
  setFullBody(value: string) {
    jspb.Message.setProto3StringField(this, 2, value);
  }


  /**
   * optional string author_display_name = 3;
   * @return {string}
   */
  getAuthorDisplayName(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
  };


  /** @param {string} value */
  setAuthorDisplayName(value: string) {
    jspb.Message.setProto3StringField(this, 3, value);
  }


  /**
   * optional string author_avatar_url = 4;
   * @return {string}
   */
  getAuthorAvatarUrl(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
  };


  /** @param {string} value */
  setAuthorAvatarUrl(value: string) {
    jspb.Message.setProto3StringField(this, 4, value);
  }


  /**
   * optional int64 timestamp = 5;
   * @return {number}
   */
  getTimestamp(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
  };


  /** @param {number} value */
  setTimestamp(value: number) {
    jspb.Message.setProto3IntField(this, 5, value);
  }


  /**
   * repeated ImageInfo image = 6;
   * @return {!Array<!ImageInfo>}
   */
  getImageList(): image_image_pb.ImageInfo[] {
    return /** @type{!Array<!ImageInfo>} */ (
      jspb.Message.getRepeatedWrapperField(this, image_image_pb.ImageInfo, 6));
  }


  /** @param {!Array<!ImageInfo>} value */
  setImageList(value?: image_image_pb.ImageInfo[]) {
    jspb.Message.setRepeatedWrapperField(this, 6, value);
  }


  /**
   * @param {!ImageInfo=} opt_value
   * @param {number=} opt_index
   * @return {!ImageInfo}
   */
  addImage(opt_value?: image_image_pb.ImageInfo, opt_index?: number): image_image_pb.ImageInfo {
    return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, image_image_pb.ImageInfo, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearImageList() {
    this.setImageList([]);
  }


  /**
   * optional string video_url = 7;
   * @return {string}
   */
  getVideoUrl(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
  };


  /** @param {string} value */
  setVideoUrl(value: string) {
    jspb.Message.setProto3StringField(this, 7, value);
  }


  /**
   * optional int32 like_count = 8;
   * @return {number}
   */
  getLikeCount(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
  };


  /** @param {number} value */
  setLikeCount(value: number) {
    jspb.Message.setProto3IntField(this, 8, value);
  }


  /**
   * optional int32 comment_count = 9;
   * @return {number}
   */
  getCommentCount(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
  };


  /** @param {number} value */
  setCommentCount(value: number) {
    jspb.Message.setProto3IntField(this, 9, value);
  }


  /**
   * optional int32 total_comment_count = 18;
   * @return {number}
   */
  getTotalCommentCount(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 18, 0));
  };


  /** @param {number} value */
  setTotalCommentCount(value: number) {
    jspb.Message.setProto3IntField(this, 18, value);
  }


  /**
   * repeated CommentContent comment = 10;
   * @return {!Array<!CommentContent>}
   */
  getCommentList(): gateway_comment_pb.CommentContent[] {
    return /** @type{!Array<!CommentContent>} */ (
      jspb.Message.getRepeatedWrapperField(this, gateway_comment_pb.CommentContent, 10));
  }


  /** @param {!Array<!CommentContent>} value */
  setCommentList(value?: gateway_comment_pb.CommentContent[]) {
    jspb.Message.setRepeatedWrapperField(this, 10, value);
  }


  /**
   * @param {!CommentContent=} opt_value
   * @param {number=} opt_index
   * @return {!CommentContent}
   */
  addComment(opt_value?: gateway_comment_pb.CommentContent, opt_index?: number): gateway_comment_pb.CommentContent {
    return jspb.Message.addToRepeatedWrapperField(this, 10, opt_value, gateway_comment_pb.CommentContent, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearCommentList() {
    this.setCommentList([]);
  }


  /**
   * optional int32 comment_list_page = 11;
   * @return {number}
   */
  getCommentListPage(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
  };


  /** @param {number} value */
  setCommentListPage(value: number) {
    jspb.Message.setProto3IntField(this, 11, value);
  }


  /**
   * optional string author_icon_url = 12;
   * @return {string}
   */
  getAuthorIconUrl(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
  };


  /** @param {string} value */
  setAuthorIconUrl(value: string) {
    jspb.Message.setProto3StringField(this, 12, value);
  }


  /**
   * optional string author_icon_color = 13;
   * @return {string}
   */
  getAuthorIconColor(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
  };


  /** @param {string} value */
  setAuthorIconColor(value: string) {
    jspb.Message.setProto3StringField(this, 13, value);
  }


  /**
   * optional string author_role_name = 14;
   * @return {string}
   */
  getAuthorRoleName(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
  };


  /** @param {string} value */
  setAuthorRoleName(value: string) {
    jspb.Message.setProto3StringField(this, 14, value);
  }


  /**
   * optional PersonViewMinimal author_person_view = 15;
   * @return {?PersonViewMinimal}
   */
  getAuthorPersonView(): gateway_person_view_pb.PersonViewMinimal {
    return /** @type{?PersonViewMinimal} */ (
      jspb.Message.getWrapperField(this, gateway_person_view_pb.PersonViewMinimal, 15));
  }


  /** @param {?PersonViewMinimal|undefined} value */
  setAuthorPersonView(value?: gateway_person_view_pb.PersonViewMinimal) {
    jspb.Message.setWrapperField(this, 15, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearAuthorPersonView() {
    this.setAuthorPersonView(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasAuthorPersonView(): boolean {
    return jspb.Message.getField(this, 15) != null;
  }


  /**
   * optional int32 media_width = 16;
   * @return {number}
   */
  getMediaWidth(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
  };


  /** @param {number} value */
  setMediaWidth(value: number) {
    jspb.Message.setProto3IntField(this, 16, value);
  }


  /**
   * optional int32 media_height = 17;
   * @return {number}
   */
  getMediaHeight(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 17, 0));
  };


  /** @param {number} value */
  setMediaHeight(value: number) {
    jspb.Message.setProto3IntField(this, 17, value);
  }


  /**
   * optional bool light_content = 19;
   * @return {boolean}
   */
  getLightContent(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 19, false));
  };


  /** @param {boolean} value */
  setLightContent(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 19, value);
  }


  /**
   * optional bool gradient_overlap = 20;
   * @return {boolean}
   */
  getGradientOverlap(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 20, false));
  };


  /** @param {boolean} value */
  setGradientOverlap(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 20, value);
  }


  /**
   * optional string background_color = 21;
   * @return {string}
   */
  getBackgroundColor(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 21, ""));
  };


  /** @param {string} value */
  setBackgroundColor(value: string) {
    jspb.Message.setProto3StringField(this, 21, value);
  }


  /**
   * optional float gradient_size = 23;
   * @return {number}
   */
  getGradientSize(): number {
    return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 23, 0.0));
  };


  /** @param {number} value */
  setGradientSize(value: number) {
    jspb.Message.setProto3FloatField(this, 23, value);
  }


  /**
   * optional bool disable_actions = 22;
   * @return {boolean}
   */
  getDisableActions(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 22, false));
  };


  /** @param {boolean} value */
  setDisableActions(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 22, value);
  }


  /**
   * optional string cloudflare_video_url = 32;
   * @return {string}
   */
  getCloudflareVideoUrl(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 32, ""));
  };


  /** @param {string} value */
  setCloudflareVideoUrl(value: string) {
    jspb.Message.setProto3StringField(this, 32, value);
  }


  /**
   * optional string cloudflare_uid = 33;
   * @return {string}
   */
  getCloudflareUid(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 33, ""));
  };


  /** @param {string} value */
  setCloudflareUid(value: string) {
    jspb.Message.setProto3StringField(this, 33, value);
  }


  /**
   * optional bool cloudflare_ready = 34;
   * @return {boolean}
   */
  getCloudflareReady(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 34, false));
  };


  /** @param {boolean} value */
  setCloudflareReady(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 34, value);
  }


  /**
   * optional minga.DesignedSvg designed_svg = 35;
   * @return {?DesignedSvg}
   */
  getDesignedSvg(): designed_svg_designed_svg_pb.DesignedSvg {
    return /** @type{?DesignedSvg} */ (
      jspb.Message.getWrapperField(this, designed_svg_designed_svg_pb.DesignedSvg, 35));
  }


  /** @param {?DesignedSvg|undefined} value */
  setDesignedSvg(value?: designed_svg_designed_svg_pb.DesignedSvg) {
    jspb.Message.setWrapperField(this, 35, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearDesignedSvg() {
    this.setDesignedSvg(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasDesignedSvg(): boolean {
    return jspb.Message.getField(this, 35) != null;
  }


  /**
   * optional minga.content.LogoAnchor logo_anchor = 36;
   * @return {!LogoAnchor}
   */
  getLogoAnchor(): content_common_pb.LogoAnchor {
    return /** @type {!LogoAnchor} */ (jspb.Message.getFieldWithDefault(this, 36, 0));
  };


  /** @param {!LogoAnchor} value */
  setLogoAnchor(value: content_common_pb.LogoAnchor) {
    jspb.Message.setProto3EnumField(this, 36, value);
  }


  /**
   * optional ImageInfo custom_logo_image = 39;
   * @return {?ImageInfo}
   */
  getCustomLogoImage(): image_image_pb.ImageInfo {
    return /** @type{?ImageInfo} */ (
      jspb.Message.getWrapperField(this, image_image_pb.ImageInfo, 39));
  }


  /** @param {?ImageInfo|undefined} value */
  setCustomLogoImage(value?: image_image_pb.ImageInfo) {
    jspb.Message.setWrapperField(this, 39, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearCustomLogoImage() {
    this.setCustomLogoImage(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasCustomLogoImage(): boolean {
    return jspb.Message.getField(this, 39) != null;
  }


  /**
   * repeated string allowed_commenting_role_types = 37;
   * @return {!Array<string>}
   */
  getAllowedCommentingRoleTypesList(): string[] {
    return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 37));
  };


  /** @param {!Array<string>} value */
  setAllowedCommentingRoleTypesList(value: string[]) {
    jspb.Message.setField(this, 37, value || []);
  }


  /**
   * @param {string} value
   * @param {number=} opt_index
   */
  addAllowedCommentingRoleTypes(value: string, opt_index?: number): void {
    jspb.Message.addToRepeatedField(this, 37, value, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearAllowedCommentingRoleTypesList() {
    this.setAllowedCommentingRoleTypesList([]);
  }


  /**
   * optional string owner_group_hash = 38;
   * @return {string}
   */
  getOwnerGroupHash(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 38, ""));
  };


  /** @param {string} value */
  setOwnerGroupHash(value: string) {
    jspb.Message.setProto3StringField(this, 38, value);
  }


  /**
   * optional bool has_liked = 40;
   * @return {boolean}
   */
  getHasLiked(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 40, false));
  };


  /** @param {boolean} value */
  setHasLiked(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 40, value);
  }


}
export namespace CommonShortCardFields {
  export interface AsObject {
    pinned: boolean;
    unpublishTimestamp: number;
    hasLiked: boolean;
  }
}
export class CommonShortCardFields extends jspb.Message {
  static readonly displayName = "CommonShortCardFields";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): CommonShortCardFields.AsObject {
    return CommonShortCardFields.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!CommonShortCardFields} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: CommonShortCardFields) {
    var f: any;
    var obj: any = {
      pinned: jspb.Message.getBooleanFieldWithDefault(msg, 100, false),
      unpublishTimestamp: jspb.Message.getFieldWithDefault(msg, 101, 0),
      hasLiked: jspb.Message.getBooleanFieldWithDefault(msg, 102, false)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!CommonShortCardFields}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new CommonShortCardFields;
    return CommonShortCardFields.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!CommonShortCardFields} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!CommonShortCardFields}
   */
  static deserializeBinaryFromReader(msg: CommonShortCardFields, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 100:
        var value100 = /** @type {boolean} */ (reader.readBool());
        msg.setPinned(value100);
        break;
      case 101:
        var value101 = /** @type {number} */ (reader.readUint64());
        msg.setUnpublishTimestamp(value101);
        break;
      case 102:
        var value102 = /** @type {boolean} */ (reader.readBool());
        msg.setHasLiked(value102);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!CommonShortCardFields} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: CommonShortCardFields, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getPinned();
    if (f) {
      writer.writeBool(
        100,
        f
      );
    }
    f = message.getUnpublishTimestamp();
    if (f !== 0) {
      writer.writeUint64(
        101,
        f
      );
    }
    f = message.getHasLiked();
    if (f) {
      writer.writeBool(
        102,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    CommonShortCardFields.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional bool pinned = 100;
   * @return {boolean}
   */
  getPinned(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 100, false));
  };


  /** @param {boolean} value */
  setPinned(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 100, value);
  }


  /**
   * optional uint64 unpublish_timestamp = 101;
   * @return {number}
   */
  getUnpublishTimestamp(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 101, 0));
  };


  /** @param {number} value */
  setUnpublishTimestamp(value: number) {
    jspb.Message.setProto3IntField(this, 101, value);
  }


  /**
   * optional bool has_liked = 102;
   * @return {boolean}
   */
  getHasLiked(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 102, false));
  };


  /** @param {boolean} value */
  setHasLiked(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 102, value);
  }


}
export namespace ShortCardView {
  export interface AsObject {
    title: string;
    body: string;
    authorDisplayName: string;
    authorAvatarUrl: string;
    timestamp: number;
    imageUrlList: string[];
    videoUrl: string;
    likeCount: number;
    commentCount: number;
    totalCommentCount: number;
    imageList: image_image_pb.ImageInfo.AsObject[];
    commentList: FeedComment.AsObject[];
    authorIconUrl: string;
    authorIconColor: string;
    authorRoleName: string;
    authorPersonView?: gateway_person_view_pb.PersonViewMinimal.AsObject;
    mediaWidth: number;
    mediaHeight: number;
    featuredImage?: image_image_pb.ImageInfo.AsObject;
    imageCount: number;
    contentHash: string;
    lightContent: boolean;
    gradientOverlap: boolean;
    backgroundColor: string;
    gradientSize: number;
    ownerGroupHash: string;
    cloudflareVideoUrl: string;
    cloudflareAnimatedImage: string;
    cloudflareUid: string;
    cloudflareReady: boolean;
    designedSvg?: designed_svg_designed_svg_pb.DesignedSvg.AsObject;
    logoAnchor?: content_common_pb.LogoAnchor;
    customLogoImage?: image_image_pb.ImageInfo.AsObject;
    allowedCommentingRoleTypesList: string[];
    pinned: boolean;
    unpublishTimestamp: number;
    hasLiked: boolean;
  }
}
export class ShortCardView extends jspb.Message {
  static readonly displayName = "ShortCardView";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [6,10,11,37];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, ShortCardView.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): ShortCardView.AsObject {
    return ShortCardView.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!ShortCardView} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: ShortCardView) {
    var f: any;
    var obj: any = {
      title: jspb.Message.getFieldWithDefault(msg, 1, ""),
      body: jspb.Message.getFieldWithDefault(msg, 2, ""),
      authorDisplayName: jspb.Message.getFieldWithDefault(msg, 3, ""),
      authorAvatarUrl: jspb.Message.getFieldWithDefault(msg, 4, ""),
      timestamp: jspb.Message.getFieldWithDefault(msg, 5, 0),
      imageUrlList: (f = jspb.Message.getRepeatedField(msg, 6)) == null ? undefined : f,
      videoUrl: jspb.Message.getFieldWithDefault(msg, 7, ""),
      likeCount: jspb.Message.getFieldWithDefault(msg, 8, 0),
      commentCount: jspb.Message.getFieldWithDefault(msg, 9, 0),
      totalCommentCount: jspb.Message.getFieldWithDefault(msg, 21, 0),
      imageList: jspb.Message.toObjectList(msg.getImageList(),
      image_image_pb.ImageInfo.toObject, includeInstance),
      commentList: jspb.Message.toObjectList(msg.getCommentList(),
      FeedComment.toObject, includeInstance),
      authorIconUrl: jspb.Message.getFieldWithDefault(msg, 12, ""),
      authorIconColor: jspb.Message.getFieldWithDefault(msg, 13, ""),
      authorRoleName: jspb.Message.getFieldWithDefault(msg, 14, ""),
      authorPersonView: (f = msg.getAuthorPersonView()) && gateway_person_view_pb.PersonViewMinimal.toObject(includeInstance, f),
      mediaWidth: jspb.Message.getFieldWithDefault(msg, 16, 0),
      mediaHeight: jspb.Message.getFieldWithDefault(msg, 17, 0),
      featuredImage: (f = msg.getFeaturedImage()) && image_image_pb.ImageInfo.toObject(includeInstance, f),
      imageCount: jspb.Message.getFieldWithDefault(msg, 19, 0),
      contentHash: jspb.Message.getFieldWithDefault(msg, 20, ""),
      lightContent: jspb.Message.getBooleanFieldWithDefault(msg, 22, false),
      gradientOverlap: jspb.Message.getBooleanFieldWithDefault(msg, 23, false),
      backgroundColor: jspb.Message.getFieldWithDefault(msg, 24, ""),
      gradientSize: jspb.Message.getFloatingPointFieldWithDefault(msg, 25, 0.0),
      ownerGroupHash: jspb.Message.getFieldWithDefault(msg, 30, ""),
      cloudflareVideoUrl: jspb.Message.getFieldWithDefault(msg, 31, ""),
      cloudflareAnimatedImage: jspb.Message.getFieldWithDefault(msg, 32, ""),
      cloudflareUid: jspb.Message.getFieldWithDefault(msg, 33, ""),
      cloudflareReady: jspb.Message.getBooleanFieldWithDefault(msg, 34, false),
      designedSvg: (f = msg.getDesignedSvg()) && designed_svg_designed_svg_pb.DesignedSvg.toObject(includeInstance, f),
      logoAnchor: jspb.Message.getFieldWithDefault(msg, 36, 0),
      customLogoImage: (f = msg.getCustomLogoImage()) && image_image_pb.ImageInfo.toObject(includeInstance, f),
      allowedCommentingRoleTypesList: (f = jspb.Message.getRepeatedField(msg, 37)) == null ? undefined : f,
      pinned: jspb.Message.getBooleanFieldWithDefault(msg, 100, false),
      unpublishTimestamp: jspb.Message.getFieldWithDefault(msg, 101, 0),
      hasLiked: jspb.Message.getBooleanFieldWithDefault(msg, 102, false)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!ShortCardView}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new ShortCardView;
    return ShortCardView.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!ShortCardView} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!ShortCardView}
   */
  static deserializeBinaryFromReader(msg: ShortCardView, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setTitle(value1);
        break;
      case 2:
        var value2 = /** @type {string} */ (reader.readString());
        msg.setBody(value2);
        break;
      case 3:
        var value3 = /** @type {string} */ (reader.readString());
        msg.setAuthorDisplayName(value3);
        break;
      case 4:
        var value4 = /** @type {string} */ (reader.readString());
        msg.setAuthorAvatarUrl(value4);
        break;
      case 5:
        var value5 = /** @type {number} */ (reader.readInt64());
        msg.setTimestamp(value5);
        break;
      case 6:
        var value6 = /** @type {string} */ (reader.readString());
        msg.addImageUrl(value6);
        break;
      case 7:
        var value7 = /** @type {string} */ (reader.readString());
        msg.setVideoUrl(value7);
        break;
      case 8:
        var value8 = /** @type {number} */ (reader.readInt32());
        msg.setLikeCount(value8);
        break;
      case 9:
        var value9 = /** @type {number} */ (reader.readInt32());
        msg.setCommentCount(value9);
        break;
      case 21:
        var value21 = /** @type {number} */ (reader.readInt32());
        msg.setTotalCommentCount(value21);
        break;
      case 10:
        var value10 = new image_image_pb.ImageInfo;
        reader.readMessage(value10,image_image_pb.ImageInfo.deserializeBinaryFromReader);
        msg.addImage(value10);
        break;
      case 11:
        var value11 = new FeedComment;
        reader.readMessage(value11,FeedComment.deserializeBinaryFromReader);
        msg.addComment(value11);
        break;
      case 12:
        var value12 = /** @type {string} */ (reader.readString());
        msg.setAuthorIconUrl(value12);
        break;
      case 13:
        var value13 = /** @type {string} */ (reader.readString());
        msg.setAuthorIconColor(value13);
        break;
      case 14:
        var value14 = /** @type {string} */ (reader.readString());
        msg.setAuthorRoleName(value14);
        break;
      case 15:
        var value15 = new gateway_person_view_pb.PersonViewMinimal;
        reader.readMessage(value15,gateway_person_view_pb.PersonViewMinimal.deserializeBinaryFromReader);
        msg.setAuthorPersonView(value15);
        break;
      case 16:
        var value16 = /** @type {number} */ (reader.readInt32());
        msg.setMediaWidth(value16);
        break;
      case 17:
        var value17 = /** @type {number} */ (reader.readInt32());
        msg.setMediaHeight(value17);
        break;
      case 18:
        var value18 = new image_image_pb.ImageInfo;
        reader.readMessage(value18,image_image_pb.ImageInfo.deserializeBinaryFromReader);
        msg.setFeaturedImage(value18);
        break;
      case 19:
        var value19 = /** @type {number} */ (reader.readInt32());
        msg.setImageCount(value19);
        break;
      case 20:
        var value20 = /** @type {string} */ (reader.readString());
        msg.setContentHash(value20);
        break;
      case 22:
        var value22 = /** @type {boolean} */ (reader.readBool());
        msg.setLightContent(value22);
        break;
      case 23:
        var value23 = /** @type {boolean} */ (reader.readBool());
        msg.setGradientOverlap(value23);
        break;
      case 24:
        var value24 = /** @type {string} */ (reader.readString());
        msg.setBackgroundColor(value24);
        break;
      case 25:
        var value25 = /** @type {number} */ (reader.readFloat());
        msg.setGradientSize(value25);
        break;
      case 30:
        var value30 = /** @type {string} */ (reader.readString());
        msg.setOwnerGroupHash(value30);
        break;
      case 31:
        var value31 = /** @type {string} */ (reader.readString());
        msg.setCloudflareVideoUrl(value31);
        break;
      case 32:
        var value32 = /** @type {string} */ (reader.readString());
        msg.setCloudflareAnimatedImage(value32);
        break;
      case 33:
        var value33 = /** @type {string} */ (reader.readString());
        msg.setCloudflareUid(value33);
        break;
      case 34:
        var value34 = /** @type {boolean} */ (reader.readBool());
        msg.setCloudflareReady(value34);
        break;
      case 35:
        var value35 = new designed_svg_designed_svg_pb.DesignedSvg;
        reader.readMessage(value35,designed_svg_designed_svg_pb.DesignedSvg.deserializeBinaryFromReader);
        msg.setDesignedSvg(value35);
        break;
      case 36:
        var value36 = /** @type {!LogoAnchor} */ (reader.readEnum());
        msg.setLogoAnchor(value36);
        break;
      case 38:
        var value38 = new image_image_pb.ImageInfo;
        reader.readMessage(value38,image_image_pb.ImageInfo.deserializeBinaryFromReader);
        msg.setCustomLogoImage(value38);
        break;
      case 37:
        var value37 = /** @type {string} */ (reader.readString());
        msg.addAllowedCommentingRoleTypes(value37);
        break;
      case 100:
        var value100 = /** @type {boolean} */ (reader.readBool());
        msg.setPinned(value100);
        break;
      case 101:
        var value101 = /** @type {number} */ (reader.readUint64());
        msg.setUnpublishTimestamp(value101);
        break;
      case 102:
        var value102 = /** @type {boolean} */ (reader.readBool());
        msg.setHasLiked(value102);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!ShortCardView} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: ShortCardView, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getTitle();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
    f = message.getBody();
    if (f.length > 0) {
      writer.writeString(
        2,
        f
      );
    }
    f = message.getAuthorDisplayName();
    if (f.length > 0) {
      writer.writeString(
        3,
        f
      );
    }
    f = message.getAuthorAvatarUrl();
    if (f.length > 0) {
      writer.writeString(
        4,
        f
      );
    }
    f = message.getTimestamp();
    if (f !== 0) {
      writer.writeInt64(
        5,
        f
      );
    }
    f = message.getImageUrlList();
    if (f.length > 0) {
      writer.writeRepeatedString(
        6,
        f
      );
    }
    f = message.getVideoUrl();
    if (f.length > 0) {
      writer.writeString(
        7,
        f
      );
    }
    f = message.getLikeCount();
    if (f !== 0) {
      writer.writeInt32(
        8,
        f
      );
    }
    f = message.getCommentCount();
    if (f !== 0) {
      writer.writeInt32(
        9,
        f
      );
    }
    f = message.getTotalCommentCount();
    if (f !== 0) {
      writer.writeInt32(
        21,
        f
      );
    }
    f = message.getImageList();
    if (f.length > 0) {
      writer.writeRepeatedMessage(
        10,
        f,
        image_image_pb.ImageInfo.serializeBinaryToWriter
      );
    }
    f = message.getCommentList();
    if (f.length > 0) {
      writer.writeRepeatedMessage(
        11,
        f,
        FeedComment.serializeBinaryToWriter
      );
    }
    f = message.getAuthorIconUrl();
    if (f.length > 0) {
      writer.writeString(
        12,
        f
      );
    }
    f = message.getAuthorIconColor();
    if (f.length > 0) {
      writer.writeString(
        13,
        f
      );
    }
    f = message.getAuthorRoleName();
    if (f.length > 0) {
      writer.writeString(
        14,
        f
      );
    }
    f = message.getAuthorPersonView();
    if (f != null) {
      writer.writeMessage(
        15,
        f,
        gateway_person_view_pb.PersonViewMinimal.serializeBinaryToWriter
      );
    }
    f = message.getMediaWidth();
    if (f !== 0) {
      writer.writeInt32(
        16,
        f
      );
    }
    f = message.getMediaHeight();
    if (f !== 0) {
      writer.writeInt32(
        17,
        f
      );
    }
    f = message.getFeaturedImage();
    if (f != null) {
      writer.writeMessage(
        18,
        f,
        image_image_pb.ImageInfo.serializeBinaryToWriter
      );
    }
    f = message.getImageCount();
    if (f !== 0) {
      writer.writeInt32(
        19,
        f
      );
    }
    f = message.getContentHash();
    if (f.length > 0) {
      writer.writeString(
        20,
        f
      );
    }
    f = message.getLightContent();
    if (f) {
      writer.writeBool(
        22,
        f
      );
    }
    f = message.getGradientOverlap();
    if (f) {
      writer.writeBool(
        23,
        f
      );
    }
    f = message.getBackgroundColor();
    if (f.length > 0) {
      writer.writeString(
        24,
        f
      );
    }
    f = message.getGradientSize();
    if (f !== 0.0) {
      writer.writeFloat(
        25,
        f
      );
    }
    f = message.getOwnerGroupHash();
    if (f.length > 0) {
      writer.writeString(
        30,
        f
      );
    }
    f = message.getCloudflareVideoUrl();
    if (f.length > 0) {
      writer.writeString(
        31,
        f
      );
    }
    f = message.getCloudflareAnimatedImage();
    if (f.length > 0) {
      writer.writeString(
        32,
        f
      );
    }
    f = message.getCloudflareUid();
    if (f.length > 0) {
      writer.writeString(
        33,
        f
      );
    }
    f = message.getCloudflareReady();
    if (f) {
      writer.writeBool(
        34,
        f
      );
    }
    f = message.getDesignedSvg();
    if (f != null) {
      writer.writeMessage(
        35,
        f,
        designed_svg_designed_svg_pb.DesignedSvg.serializeBinaryToWriter
      );
    }
    f = message.getLogoAnchor();
    if (f !== 0.0) {
      writer.writeEnum(
        36,
        f
      );
    }
    f = message.getCustomLogoImage();
    if (f != null) {
      writer.writeMessage(
        38,
        f,
        image_image_pb.ImageInfo.serializeBinaryToWriter
      );
    }
    f = message.getAllowedCommentingRoleTypesList();
    if (f.length > 0) {
      writer.writeRepeatedString(
        37,
        f
      );
    }
    f = message.getPinned();
    if (f) {
      writer.writeBool(
        100,
        f
      );
    }
    f = message.getUnpublishTimestamp();
    if (f !== 0) {
      writer.writeUint64(
        101,
        f
      );
    }
    f = message.getHasLiked();
    if (f) {
      writer.writeBool(
        102,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    ShortCardView.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string title = 1;
   * @return {string}
   */
  getTitle(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setTitle(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


  /**
   * optional string body = 2;
   * @return {string}
   */
  getBody(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
  };


  /** @param {string} value */
  setBody(value: string) {
    jspb.Message.setProto3StringField(this, 2, value);
  }


  /**
   * optional string author_display_name = 3;
   * @return {string}
   */
  getAuthorDisplayName(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
  };


  /** @param {string} value */
  setAuthorDisplayName(value: string) {
    jspb.Message.setProto3StringField(this, 3, value);
  }


  /**
   * optional string author_avatar_url = 4;
   * @return {string}
   */
  getAuthorAvatarUrl(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
  };


  /** @param {string} value */
  setAuthorAvatarUrl(value: string) {
    jspb.Message.setProto3StringField(this, 4, value);
  }


  /**
   * optional int64 timestamp = 5;
   * @return {number}
   */
  getTimestamp(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
  };


  /** @param {number} value */
  setTimestamp(value: number) {
    jspb.Message.setProto3IntField(this, 5, value);
  }


  /**
   * repeated string image_url = 6;
   * @return {!Array<string>}
   */
  getImageUrlList(): string[] {
    return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 6));
  };


  /** @param {!Array<string>} value */
  setImageUrlList(value: string[]) {
    jspb.Message.setField(this, 6, value || []);
  }


  /**
   * @param {string} value
   * @param {number=} opt_index
   */
  addImageUrl(value: string, opt_index?: number): void {
    jspb.Message.addToRepeatedField(this, 6, value, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearImageUrlList() {
    this.setImageUrlList([]);
  }


  /**
   * optional string video_url = 7;
   * @return {string}
   */
  getVideoUrl(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
  };


  /** @param {string} value */
  setVideoUrl(value: string) {
    jspb.Message.setProto3StringField(this, 7, value);
  }


  /**
   * optional int32 like_count = 8;
   * @return {number}
   */
  getLikeCount(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
  };


  /** @param {number} value */
  setLikeCount(value: number) {
    jspb.Message.setProto3IntField(this, 8, value);
  }


  /**
   * optional int32 comment_count = 9;
   * @return {number}
   */
  getCommentCount(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
  };


  /** @param {number} value */
  setCommentCount(value: number) {
    jspb.Message.setProto3IntField(this, 9, value);
  }


  /**
   * optional int32 total_comment_count = 21;
   * @return {number}
   */
  getTotalCommentCount(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 21, 0));
  };


  /** @param {number} value */
  setTotalCommentCount(value: number) {
    jspb.Message.setProto3IntField(this, 21, value);
  }


  /**
   * repeated ImageInfo image = 10;
   * @return {!Array<!ImageInfo>}
   */
  getImageList(): image_image_pb.ImageInfo[] {
    return /** @type{!Array<!ImageInfo>} */ (
      jspb.Message.getRepeatedWrapperField(this, image_image_pb.ImageInfo, 10));
  }


  /** @param {!Array<!ImageInfo>} value */
  setImageList(value?: image_image_pb.ImageInfo[]) {
    jspb.Message.setRepeatedWrapperField(this, 10, value);
  }


  /**
   * @param {!ImageInfo=} opt_value
   * @param {number=} opt_index
   * @return {!ImageInfo}
   */
  addImage(opt_value?: image_image_pb.ImageInfo, opt_index?: number): image_image_pb.ImageInfo {
    return jspb.Message.addToRepeatedWrapperField(this, 10, opt_value, image_image_pb.ImageInfo, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearImageList() {
    this.setImageList([]);
  }


  /**
   * repeated FeedComment comment = 11;
   * @return {!Array<!FeedComment>}
   */
  getCommentList(): FeedComment[] {
    return /** @type{!Array<!FeedComment>} */ (
      jspb.Message.getRepeatedWrapperField(this, FeedComment, 11));
  }


  /** @param {!Array<!FeedComment>} value */
  setCommentList(value?: FeedComment[]) {
    jspb.Message.setRepeatedWrapperField(this, 11, value);
  }


  /**
   * @param {!FeedComment=} opt_value
   * @param {number=} opt_index
   * @return {!FeedComment}
   */
  addComment(opt_value?: FeedComment, opt_index?: number): FeedComment {
    return jspb.Message.addToRepeatedWrapperField(this, 11, opt_value, FeedComment, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearCommentList() {
    this.setCommentList([]);
  }


  /**
   * optional string author_icon_url = 12;
   * @return {string}
   */
  getAuthorIconUrl(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
  };


  /** @param {string} value */
  setAuthorIconUrl(value: string) {
    jspb.Message.setProto3StringField(this, 12, value);
  }


  /**
   * optional string author_icon_color = 13;
   * @return {string}
   */
  getAuthorIconColor(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
  };


  /** @param {string} value */
  setAuthorIconColor(value: string) {
    jspb.Message.setProto3StringField(this, 13, value);
  }


  /**
   * optional string author_role_name = 14;
   * @return {string}
   */
  getAuthorRoleName(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
  };


  /** @param {string} value */
  setAuthorRoleName(value: string) {
    jspb.Message.setProto3StringField(this, 14, value);
  }


  /**
   * optional PersonViewMinimal author_person_view = 15;
   * @return {?PersonViewMinimal}
   */
  getAuthorPersonView(): gateway_person_view_pb.PersonViewMinimal {
    return /** @type{?PersonViewMinimal} */ (
      jspb.Message.getWrapperField(this, gateway_person_view_pb.PersonViewMinimal, 15));
  }


  /** @param {?PersonViewMinimal|undefined} value */
  setAuthorPersonView(value?: gateway_person_view_pb.PersonViewMinimal) {
    jspb.Message.setWrapperField(this, 15, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearAuthorPersonView() {
    this.setAuthorPersonView(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasAuthorPersonView(): boolean {
    return jspb.Message.getField(this, 15) != null;
  }


  /**
   * optional int32 media_width = 16;
   * @return {number}
   */
  getMediaWidth(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
  };


  /** @param {number} value */
  setMediaWidth(value: number) {
    jspb.Message.setProto3IntField(this, 16, value);
  }


  /**
   * optional int32 media_height = 17;
   * @return {number}
   */
  getMediaHeight(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 17, 0));
  };


  /** @param {number} value */
  setMediaHeight(value: number) {
    jspb.Message.setProto3IntField(this, 17, value);
  }


  /**
   * optional ImageInfo featured_image = 18;
   * @return {?ImageInfo}
   */
  getFeaturedImage(): image_image_pb.ImageInfo {
    return /** @type{?ImageInfo} */ (
      jspb.Message.getWrapperField(this, image_image_pb.ImageInfo, 18));
  }


  /** @param {?ImageInfo|undefined} value */
  setFeaturedImage(value?: image_image_pb.ImageInfo) {
    jspb.Message.setWrapperField(this, 18, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearFeaturedImage() {
    this.setFeaturedImage(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasFeaturedImage(): boolean {
    return jspb.Message.getField(this, 18) != null;
  }


  /**
   * optional int32 image_count = 19;
   * @return {number}
   */
  getImageCount(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 19, 0));
  };


  /** @param {number} value */
  setImageCount(value: number) {
    jspb.Message.setProto3IntField(this, 19, value);
  }


  /**
   * optional string content_hash = 20;
   * @return {string}
   */
  getContentHash(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
  };


  /** @param {string} value */
  setContentHash(value: string) {
    jspb.Message.setProto3StringField(this, 20, value);
  }


  /**
   * optional bool light_content = 22;
   * @return {boolean}
   */
  getLightContent(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 22, false));
  };


  /** @param {boolean} value */
  setLightContent(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 22, value);
  }


  /**
   * optional bool gradient_overlap = 23;
   * @return {boolean}
   */
  getGradientOverlap(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 23, false));
  };


  /** @param {boolean} value */
  setGradientOverlap(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 23, value);
  }


  /**
   * optional string background_color = 24;
   * @return {string}
   */
  getBackgroundColor(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 24, ""));
  };


  /** @param {string} value */
  setBackgroundColor(value: string) {
    jspb.Message.setProto3StringField(this, 24, value);
  }


  /**
   * optional float gradient_size = 25;
   * @return {number}
   */
  getGradientSize(): number {
    return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 25, 0.0));
  };


  /** @param {number} value */
  setGradientSize(value: number) {
    jspb.Message.setProto3FloatField(this, 25, value);
  }


  /**
   * optional string owner_group_hash = 30;
   * @return {string}
   */
  getOwnerGroupHash(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 30, ""));
  };


  /** @param {string} value */
  setOwnerGroupHash(value: string) {
    jspb.Message.setProto3StringField(this, 30, value);
  }


  /**
   * optional string cloudflare_video_url = 31;
   * @return {string}
   */
  getCloudflareVideoUrl(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 31, ""));
  };


  /** @param {string} value */
  setCloudflareVideoUrl(value: string) {
    jspb.Message.setProto3StringField(this, 31, value);
  }


  /**
   * optional string cloudflare_animated_image = 32;
   * @return {string}
   */
  getCloudflareAnimatedImage(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 32, ""));
  };


  /** @param {string} value */
  setCloudflareAnimatedImage(value: string) {
    jspb.Message.setProto3StringField(this, 32, value);
  }


  /**
   * optional string cloudflare_uid = 33;
   * @return {string}
   */
  getCloudflareUid(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 33, ""));
  };


  /** @param {string} value */
  setCloudflareUid(value: string) {
    jspb.Message.setProto3StringField(this, 33, value);
  }


  /**
   * optional bool cloudflare_ready = 34;
   * @return {boolean}
   */
  getCloudflareReady(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 34, false));
  };


  /** @param {boolean} value */
  setCloudflareReady(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 34, value);
  }


  /**
   * optional minga.DesignedSvg designed_svg = 35;
   * @return {?DesignedSvg}
   */
  getDesignedSvg(): designed_svg_designed_svg_pb.DesignedSvg {
    return /** @type{?DesignedSvg} */ (
      jspb.Message.getWrapperField(this, designed_svg_designed_svg_pb.DesignedSvg, 35));
  }


  /** @param {?DesignedSvg|undefined} value */
  setDesignedSvg(value?: designed_svg_designed_svg_pb.DesignedSvg) {
    jspb.Message.setWrapperField(this, 35, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearDesignedSvg() {
    this.setDesignedSvg(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasDesignedSvg(): boolean {
    return jspb.Message.getField(this, 35) != null;
  }


  /**
   * optional minga.content.LogoAnchor logo_anchor = 36;
   * @return {!LogoAnchor}
   */
  getLogoAnchor(): content_common_pb.LogoAnchor {
    return /** @type {!LogoAnchor} */ (jspb.Message.getFieldWithDefault(this, 36, 0));
  };


  /** @param {!LogoAnchor} value */
  setLogoAnchor(value: content_common_pb.LogoAnchor) {
    jspb.Message.setProto3EnumField(this, 36, value);
  }


  /**
   * optional ImageInfo custom_logo_image = 38;
   * @return {?ImageInfo}
   */
  getCustomLogoImage(): image_image_pb.ImageInfo {
    return /** @type{?ImageInfo} */ (
      jspb.Message.getWrapperField(this, image_image_pb.ImageInfo, 38));
  }


  /** @param {?ImageInfo|undefined} value */
  setCustomLogoImage(value?: image_image_pb.ImageInfo) {
    jspb.Message.setWrapperField(this, 38, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearCustomLogoImage() {
    this.setCustomLogoImage(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasCustomLogoImage(): boolean {
    return jspb.Message.getField(this, 38) != null;
  }


  /**
   * repeated string allowed_commenting_role_types = 37;
   * @return {!Array<string>}
   */
  getAllowedCommentingRoleTypesList(): string[] {
    return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 37));
  };


  /** @param {!Array<string>} value */
  setAllowedCommentingRoleTypesList(value: string[]) {
    jspb.Message.setField(this, 37, value || []);
  }


  /**
   * @param {string} value
   * @param {number=} opt_index
   */
  addAllowedCommentingRoleTypes(value: string, opt_index?: number): void {
    jspb.Message.addToRepeatedField(this, 37, value, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearAllowedCommentingRoleTypesList() {
    this.setAllowedCommentingRoleTypesList([]);
  }


  /**
   * optional bool pinned = 100;
   * @return {boolean}
   */
  getPinned(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 100, false));
  };


  /** @param {boolean} value */
  setPinned(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 100, value);
  }


  /**
   * optional uint64 unpublish_timestamp = 101;
   * @return {number}
   */
  getUnpublishTimestamp(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 101, 0));
  };


  /** @param {number} value */
  setUnpublishTimestamp(value: number) {
    jspb.Message.setProto3IntField(this, 101, value);
  }


  /**
   * optional bool has_liked = 102;
   * @return {boolean}
   */
  getHasLiked(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 102, false));
  };


  /** @param {boolean} value */
  setHasLiked(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 102, value);
  }


}
export namespace LongEventCardView {
  export interface AsObject {
    title: string;
    fullBody: string;
    timestamp: number;
    image?: image_image_pb.ImageInfo.AsObject;
    likeCount: number;
    commentCount: number;
    totalCommentCount: number;
    commentList: gateway_comment_pb.CommentContent.AsObject[];
    commentListPage: number;
    authorPersonView?: gateway_person_view_pb.PersonViewMinimal.AsObject;
    startTimestamp: number;
    endTimestamp: number;
    allowGoing: boolean;
    hostedBy: string;
    location: string;
    goingCount: number;
    hasCode: boolean;
    lightContent: boolean;
    gradientOverlap: boolean;
    backgroundColor: string;
    gradientSize: number;
    banner?: gateway_banner_pb.Banner.AsObject;
    allDay: boolean;
    allowedCommentingRoleTypesList: string[];
    ownerGroupHash: string;
    cancelled: boolean;
    eventManagersList: string[];
    checkinCount: number;
    checkoutCount: number;
    ticketUrl: string;
    category: string;
    points: number;
    hasTickets: boolean;
    ticketCount: number;
    ticketInfo: string;
    ticketPrice: number;
    eventStatus: string;
    allowSelfCheckIn: boolean;
    hasLiked: boolean;
    eventReasonId: number;
  }
}
export class LongEventCardView extends jspb.Message {
  static readonly displayName = "LongEventCardView";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [8,24,27];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, LongEventCardView.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): LongEventCardView.AsObject {
    return LongEventCardView.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!LongEventCardView} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: LongEventCardView) {
    var f: any;
    var obj: any = {
      title: jspb.Message.getFieldWithDefault(msg, 1, ""),
      fullBody: jspb.Message.getFieldWithDefault(msg, 2, ""),
      timestamp: jspb.Message.getFieldWithDefault(msg, 3, 0),
      image: (f = msg.getImage()) && image_image_pb.ImageInfo.toObject(includeInstance, f),
      likeCount: jspb.Message.getFieldWithDefault(msg, 5, 0),
      commentCount: jspb.Message.getFieldWithDefault(msg, 6, 0),
      totalCommentCount: jspb.Message.getFieldWithDefault(msg, 7, 0),
      commentList: jspb.Message.toObjectList(msg.getCommentList(),
      gateway_comment_pb.CommentContent.toObject, includeInstance),
      commentListPage: jspb.Message.getFieldWithDefault(msg, 9, 0),
      authorPersonView: (f = msg.getAuthorPersonView()) && gateway_person_view_pb.PersonViewMinimal.toObject(includeInstance, f),
      startTimestamp: jspb.Message.getFieldWithDefault(msg, 11, 0),
      endTimestamp: jspb.Message.getFieldWithDefault(msg, 12, 0),
      allowGoing: jspb.Message.getBooleanFieldWithDefault(msg, 13, false),
      hostedBy: jspb.Message.getFieldWithDefault(msg, 14, ""),
      location: jspb.Message.getFieldWithDefault(msg, 15, ""),
      goingCount: jspb.Message.getFieldWithDefault(msg, 16, 0),
      hasCode: jspb.Message.getBooleanFieldWithDefault(msg, 17, false),
      lightContent: jspb.Message.getBooleanFieldWithDefault(msg, 18, false),
      gradientOverlap: jspb.Message.getBooleanFieldWithDefault(msg, 19, false),
      backgroundColor: jspb.Message.getFieldWithDefault(msg, 20, ""),
      gradientSize: jspb.Message.getFloatingPointFieldWithDefault(msg, 23, 0.0),
      banner: (f = msg.getBanner()) && gateway_banner_pb.Banner.toObject(includeInstance, f),
      allDay: jspb.Message.getBooleanFieldWithDefault(msg, 22, false),
      allowedCommentingRoleTypesList: (f = jspb.Message.getRepeatedField(msg, 24)) == null ? undefined : f,
      ownerGroupHash: jspb.Message.getFieldWithDefault(msg, 25, ""),
      cancelled: jspb.Message.getBooleanFieldWithDefault(msg, 26, false),
      eventManagersList: (f = jspb.Message.getRepeatedField(msg, 27)) == null ? undefined : f,
      checkinCount: jspb.Message.getFieldWithDefault(msg, 28, 0),
      checkoutCount: jspb.Message.getFieldWithDefault(msg, 29, 0),
      ticketUrl: jspb.Message.getFieldWithDefault(msg, 30, ""),
      category: jspb.Message.getFieldWithDefault(msg, 31, ""),
      points: jspb.Message.getFieldWithDefault(msg, 32, 0),
      hasTickets: jspb.Message.getBooleanFieldWithDefault(msg, 33, false),
      ticketCount: jspb.Message.getFieldWithDefault(msg, 34, 0),
      ticketInfo: jspb.Message.getFieldWithDefault(msg, 35, ""),
      ticketPrice: jspb.Message.getFloatingPointFieldWithDefault(msg, 36, 0.0),
      eventStatus: jspb.Message.getFieldWithDefault(msg, 37, ""),
      allowSelfCheckIn: jspb.Message.getBooleanFieldWithDefault(msg, 38, false),
      hasLiked: jspb.Message.getBooleanFieldWithDefault(msg, 102, false),
      eventReasonId: jspb.Message.getFieldWithDefault(msg, 103, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!LongEventCardView}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new LongEventCardView;
    return LongEventCardView.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!LongEventCardView} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!LongEventCardView}
   */
  static deserializeBinaryFromReader(msg: LongEventCardView, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setTitle(value1);
        break;
      case 2:
        var value2 = /** @type {string} */ (reader.readString());
        msg.setFullBody(value2);
        break;
      case 3:
        var value3 = /** @type {number} */ (reader.readInt64());
        msg.setTimestamp(value3);
        break;
      case 4:
        var value4 = new image_image_pb.ImageInfo;
        reader.readMessage(value4,image_image_pb.ImageInfo.deserializeBinaryFromReader);
        msg.setImage(value4);
        break;
      case 5:
        var value5 = /** @type {number} */ (reader.readInt32());
        msg.setLikeCount(value5);
        break;
      case 6:
        var value6 = /** @type {number} */ (reader.readInt32());
        msg.setCommentCount(value6);
        break;
      case 7:
        var value7 = /** @type {number} */ (reader.readInt32());
        msg.setTotalCommentCount(value7);
        break;
      case 8:
        var value8 = new gateway_comment_pb.CommentContent;
        reader.readMessage(value8,gateway_comment_pb.CommentContent.deserializeBinaryFromReader);
        msg.addComment(value8);
        break;
      case 9:
        var value9 = /** @type {number} */ (reader.readInt32());
        msg.setCommentListPage(value9);
        break;
      case 10:
        var value10 = new gateway_person_view_pb.PersonViewMinimal;
        reader.readMessage(value10,gateway_person_view_pb.PersonViewMinimal.deserializeBinaryFromReader);
        msg.setAuthorPersonView(value10);
        break;
      case 11:
        var value11 = /** @type {number} */ (reader.readInt64());
        msg.setStartTimestamp(value11);
        break;
      case 12:
        var value12 = /** @type {number} */ (reader.readInt64());
        msg.setEndTimestamp(value12);
        break;
      case 13:
        var value13 = /** @type {boolean} */ (reader.readBool());
        msg.setAllowGoing(value13);
        break;
      case 14:
        var value14 = /** @type {string} */ (reader.readString());
        msg.setHostedBy(value14);
        break;
      case 15:
        var value15 = /** @type {string} */ (reader.readString());
        msg.setLocation(value15);
        break;
      case 16:
        var value16 = /** @type {number} */ (reader.readInt32());
        msg.setGoingCount(value16);
        break;
      case 17:
        var value17 = /** @type {boolean} */ (reader.readBool());
        msg.setHasCode(value17);
        break;
      case 18:
        var value18 = /** @type {boolean} */ (reader.readBool());
        msg.setLightContent(value18);
        break;
      case 19:
        var value19 = /** @type {boolean} */ (reader.readBool());
        msg.setGradientOverlap(value19);
        break;
      case 20:
        var value20 = /** @type {string} */ (reader.readString());
        msg.setBackgroundColor(value20);
        break;
      case 23:
        var value23 = /** @type {number} */ (reader.readFloat());
        msg.setGradientSize(value23);
        break;
      case 21:
        var value21 = new gateway_banner_pb.Banner;
        reader.readMessage(value21,gateway_banner_pb.Banner.deserializeBinaryFromReader);
        msg.setBanner(value21);
        break;
      case 22:
        var value22 = /** @type {boolean} */ (reader.readBool());
        msg.setAllDay(value22);
        break;
      case 24:
        var value24 = /** @type {string} */ (reader.readString());
        msg.addAllowedCommentingRoleTypes(value24);
        break;
      case 25:
        var value25 = /** @type {string} */ (reader.readString());
        msg.setOwnerGroupHash(value25);
        break;
      case 26:
        var value26 = /** @type {boolean} */ (reader.readBool());
        msg.setCancelled(value26);
        break;
      case 27:
        var value27 = /** @type {string} */ (reader.readString());
        msg.addEventManagers(value27);
        break;
      case 28:
        var value28 = /** @type {number} */ (reader.readInt32());
        msg.setCheckinCount(value28);
        break;
      case 29:
        var value29 = /** @type {number} */ (reader.readInt32());
        msg.setCheckoutCount(value29);
        break;
      case 30:
        var value30 = /** @type {string} */ (reader.readString());
        msg.setTicketUrl(value30);
        break;
      case 31:
        var value31 = /** @type {string} */ (reader.readString());
        msg.setCategory(value31);
        break;
      case 32:
        var value32 = /** @type {number} */ (reader.readInt32());
        msg.setPoints(value32);
        break;
      case 33:
        var value33 = /** @type {boolean} */ (reader.readBool());
        msg.setHasTickets(value33);
        break;
      case 34:
        var value34 = /** @type {number} */ (reader.readInt32());
        msg.setTicketCount(value34);
        break;
      case 35:
        var value35 = /** @type {string} */ (reader.readString());
        msg.setTicketInfo(value35);
        break;
      case 36:
        var value36 = /** @type {number} */ (reader.readFloat());
        msg.setTicketPrice(value36);
        break;
      case 37:
        var value37 = /** @type {string} */ (reader.readString());
        msg.setEventStatus(value37);
        break;
      case 38:
        var value38 = /** @type {boolean} */ (reader.readBool());
        msg.setAllowSelfCheckIn(value38);
        break;
      case 102:
        var value102 = /** @type {boolean} */ (reader.readBool());
        msg.setHasLiked(value102);
        break;
      case 103:
        var value103 = /** @type {number} */ (reader.readInt32());
        msg.setEventReasonId(value103);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!LongEventCardView} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: LongEventCardView, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getTitle();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
    f = message.getFullBody();
    if (f.length > 0) {
      writer.writeString(
        2,
        f
      );
    }
    f = message.getTimestamp();
    if (f !== 0) {
      writer.writeInt64(
        3,
        f
      );
    }
    f = message.getImage();
    if (f != null) {
      writer.writeMessage(
        4,
        f,
        image_image_pb.ImageInfo.serializeBinaryToWriter
      );
    }
    f = message.getLikeCount();
    if (f !== 0) {
      writer.writeInt32(
        5,
        f
      );
    }
    f = message.getCommentCount();
    if (f !== 0) {
      writer.writeInt32(
        6,
        f
      );
    }
    f = message.getTotalCommentCount();
    if (f !== 0) {
      writer.writeInt32(
        7,
        f
      );
    }
    f = message.getCommentList();
    if (f.length > 0) {
      writer.writeRepeatedMessage(
        8,
        f,
        gateway_comment_pb.CommentContent.serializeBinaryToWriter
      );
    }
    f = message.getCommentListPage();
    if (f !== 0) {
      writer.writeInt32(
        9,
        f
      );
    }
    f = message.getAuthorPersonView();
    if (f != null) {
      writer.writeMessage(
        10,
        f,
        gateway_person_view_pb.PersonViewMinimal.serializeBinaryToWriter
      );
    }
    f = message.getStartTimestamp();
    if (f !== 0) {
      writer.writeInt64(
        11,
        f
      );
    }
    f = message.getEndTimestamp();
    if (f !== 0) {
      writer.writeInt64(
        12,
        f
      );
    }
    f = message.getAllowGoing();
    if (f) {
      writer.writeBool(
        13,
        f
      );
    }
    f = message.getHostedBy();
    if (f.length > 0) {
      writer.writeString(
        14,
        f
      );
    }
    f = message.getLocation();
    if (f.length > 0) {
      writer.writeString(
        15,
        f
      );
    }
    f = message.getGoingCount();
    if (f !== 0) {
      writer.writeInt32(
        16,
        f
      );
    }
    f = message.getHasCode();
    if (f) {
      writer.writeBool(
        17,
        f
      );
    }
    f = message.getLightContent();
    if (f) {
      writer.writeBool(
        18,
        f
      );
    }
    f = message.getGradientOverlap();
    if (f) {
      writer.writeBool(
        19,
        f
      );
    }
    f = message.getBackgroundColor();
    if (f.length > 0) {
      writer.writeString(
        20,
        f
      );
    }
    f = message.getGradientSize();
    if (f !== 0.0) {
      writer.writeFloat(
        23,
        f
      );
    }
    f = message.getBanner();
    if (f != null) {
      writer.writeMessage(
        21,
        f,
        gateway_banner_pb.Banner.serializeBinaryToWriter
      );
    }
    f = message.getAllDay();
    if (f) {
      writer.writeBool(
        22,
        f
      );
    }
    f = message.getAllowedCommentingRoleTypesList();
    if (f.length > 0) {
      writer.writeRepeatedString(
        24,
        f
      );
    }
    f = message.getOwnerGroupHash();
    if (f.length > 0) {
      writer.writeString(
        25,
        f
      );
    }
    f = message.getCancelled();
    if (f) {
      writer.writeBool(
        26,
        f
      );
    }
    f = message.getEventManagersList();
    if (f.length > 0) {
      writer.writeRepeatedString(
        27,
        f
      );
    }
    f = message.getCheckinCount();
    if (f !== 0) {
      writer.writeInt32(
        28,
        f
      );
    }
    f = message.getCheckoutCount();
    if (f !== 0) {
      writer.writeInt32(
        29,
        f
      );
    }
    f = message.getTicketUrl();
    if (f.length > 0) {
      writer.writeString(
        30,
        f
      );
    }
    f = message.getCategory();
    if (f.length > 0) {
      writer.writeString(
        31,
        f
      );
    }
    f = message.getPoints();
    if (f !== 0) {
      writer.writeInt32(
        32,
        f
      );
    }
    f = message.getHasTickets();
    if (f) {
      writer.writeBool(
        33,
        f
      );
    }
    f = message.getTicketCount();
    if (f !== 0) {
      writer.writeInt32(
        34,
        f
      );
    }
    f = message.getTicketInfo();
    if (f.length > 0) {
      writer.writeString(
        35,
        f
      );
    }
    f = message.getTicketPrice();
    if (f !== 0.0) {
      writer.writeFloat(
        36,
        f
      );
    }
    f = message.getEventStatus();
    if (f.length > 0) {
      writer.writeString(
        37,
        f
      );
    }
    f = message.getAllowSelfCheckIn();
    if (f) {
      writer.writeBool(
        38,
        f
      );
    }
    f = message.getHasLiked();
    if (f) {
      writer.writeBool(
        102,
        f
      );
    }
    f = message.getEventReasonId();
    if (f !== 0) {
      writer.writeInt32(
        103,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    LongEventCardView.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string title = 1;
   * @return {string}
   */
  getTitle(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setTitle(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


  /**
   * optional string full_body = 2;
   * @return {string}
   */
  getFullBody(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
  };


  /** @param {string} value */
  setFullBody(value: string) {
    jspb.Message.setProto3StringField(this, 2, value);
  }


  /**
   * optional int64 timestamp = 3;
   * @return {number}
   */
  getTimestamp(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
  };


  /** @param {number} value */
  setTimestamp(value: number) {
    jspb.Message.setProto3IntField(this, 3, value);
  }


  /**
   * optional ImageInfo image = 4;
   * @return {?ImageInfo}
   */
  getImage(): image_image_pb.ImageInfo {
    return /** @type{?ImageInfo} */ (
      jspb.Message.getWrapperField(this, image_image_pb.ImageInfo, 4));
  }


  /** @param {?ImageInfo|undefined} value */
  setImage(value?: image_image_pb.ImageInfo) {
    jspb.Message.setWrapperField(this, 4, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearImage() {
    this.setImage(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasImage(): boolean {
    return jspb.Message.getField(this, 4) != null;
  }


  /**
   * optional int32 like_count = 5;
   * @return {number}
   */
  getLikeCount(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
  };


  /** @param {number} value */
  setLikeCount(value: number) {
    jspb.Message.setProto3IntField(this, 5, value);
  }


  /**
   * optional int32 comment_count = 6;
   * @return {number}
   */
  getCommentCount(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
  };


  /** @param {number} value */
  setCommentCount(value: number) {
    jspb.Message.setProto3IntField(this, 6, value);
  }


  /**
   * optional int32 total_comment_count = 7;
   * @return {number}
   */
  getTotalCommentCount(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
  };


  /** @param {number} value */
  setTotalCommentCount(value: number) {
    jspb.Message.setProto3IntField(this, 7, value);
  }


  /**
   * repeated CommentContent comment = 8;
   * @return {!Array<!CommentContent>}
   */
  getCommentList(): gateway_comment_pb.CommentContent[] {
    return /** @type{!Array<!CommentContent>} */ (
      jspb.Message.getRepeatedWrapperField(this, gateway_comment_pb.CommentContent, 8));
  }


  /** @param {!Array<!CommentContent>} value */
  setCommentList(value?: gateway_comment_pb.CommentContent[]) {
    jspb.Message.setRepeatedWrapperField(this, 8, value);
  }


  /**
   * @param {!CommentContent=} opt_value
   * @param {number=} opt_index
   * @return {!CommentContent}
   */
  addComment(opt_value?: gateway_comment_pb.CommentContent, opt_index?: number): gateway_comment_pb.CommentContent {
    return jspb.Message.addToRepeatedWrapperField(this, 8, opt_value, gateway_comment_pb.CommentContent, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearCommentList() {
    this.setCommentList([]);
  }


  /**
   * optional int32 comment_list_page = 9;
   * @return {number}
   */
  getCommentListPage(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
  };


  /** @param {number} value */
  setCommentListPage(value: number) {
    jspb.Message.setProto3IntField(this, 9, value);
  }


  /**
   * optional PersonViewMinimal author_person_view = 10;
   * @return {?PersonViewMinimal}
   */
  getAuthorPersonView(): gateway_person_view_pb.PersonViewMinimal {
    return /** @type{?PersonViewMinimal} */ (
      jspb.Message.getWrapperField(this, gateway_person_view_pb.PersonViewMinimal, 10));
  }


  /** @param {?PersonViewMinimal|undefined} value */
  setAuthorPersonView(value?: gateway_person_view_pb.PersonViewMinimal) {
    jspb.Message.setWrapperField(this, 10, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearAuthorPersonView() {
    this.setAuthorPersonView(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasAuthorPersonView(): boolean {
    return jspb.Message.getField(this, 10) != null;
  }


  /**
   * optional int64 start_timestamp = 11;
   * @return {number}
   */
  getStartTimestamp(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
  };


  /** @param {number} value */
  setStartTimestamp(value: number) {
    jspb.Message.setProto3IntField(this, 11, value);
  }


  /**
   * optional int64 end_timestamp = 12;
   * @return {number}
   */
  getEndTimestamp(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
  };


  /** @param {number} value */
  setEndTimestamp(value: number) {
    jspb.Message.setProto3IntField(this, 12, value);
  }


  /**
   * optional bool allow_going = 13;
   * @return {boolean}
   */
  getAllowGoing(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 13, false));
  };


  /** @param {boolean} value */
  setAllowGoing(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 13, value);
  }


  /**
   * optional string hosted_by = 14;
   * @return {string}
   */
  getHostedBy(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
  };


  /** @param {string} value */
  setHostedBy(value: string) {
    jspb.Message.setProto3StringField(this, 14, value);
  }


  /**
   * optional string location = 15;
   * @return {string}
   */
  getLocation(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
  };


  /** @param {string} value */
  setLocation(value: string) {
    jspb.Message.setProto3StringField(this, 15, value);
  }


  /**
   * optional int32 going_count = 16;
   * @return {number}
   */
  getGoingCount(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
  };


  /** @param {number} value */
  setGoingCount(value: number) {
    jspb.Message.setProto3IntField(this, 16, value);
  }


  /**
   * optional bool has_code = 17;
   * @return {boolean}
   */
  getHasCode(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 17, false));
  };


  /** @param {boolean} value */
  setHasCode(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 17, value);
  }


  /**
   * optional bool light_content = 18;
   * @return {boolean}
   */
  getLightContent(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 18, false));
  };


  /** @param {boolean} value */
  setLightContent(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 18, value);
  }


  /**
   * optional bool gradient_overlap = 19;
   * @return {boolean}
   */
  getGradientOverlap(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 19, false));
  };


  /** @param {boolean} value */
  setGradientOverlap(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 19, value);
  }


  /**
   * optional string background_color = 20;
   * @return {string}
   */
  getBackgroundColor(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
  };


  /** @param {string} value */
  setBackgroundColor(value: string) {
    jspb.Message.setProto3StringField(this, 20, value);
  }


  /**
   * optional float gradient_size = 23;
   * @return {number}
   */
  getGradientSize(): number {
    return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 23, 0.0));
  };


  /** @param {number} value */
  setGradientSize(value: number) {
    jspb.Message.setProto3FloatField(this, 23, value);
  }


  /**
   * optional minga.banner.Banner banner = 21;
   * @return {?Banner}
   */
  getBanner(): gateway_banner_pb.Banner {
    return /** @type{?Banner} */ (
      jspb.Message.getWrapperField(this, gateway_banner_pb.Banner, 21));
  }


  /** @param {?Banner|undefined} value */
  setBanner(value?: gateway_banner_pb.Banner) {
    jspb.Message.setWrapperField(this, 21, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearBanner() {
    this.setBanner(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasBanner(): boolean {
    return jspb.Message.getField(this, 21) != null;
  }


  /**
   * optional bool all_day = 22;
   * @return {boolean}
   */
  getAllDay(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 22, false));
  };


  /** @param {boolean} value */
  setAllDay(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 22, value);
  }


  /**
   * repeated string allowed_commenting_role_types = 24;
   * @return {!Array<string>}
   */
  getAllowedCommentingRoleTypesList(): string[] {
    return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 24));
  };


  /** @param {!Array<string>} value */
  setAllowedCommentingRoleTypesList(value: string[]) {
    jspb.Message.setField(this, 24, value || []);
  }


  /**
   * @param {string} value
   * @param {number=} opt_index
   */
  addAllowedCommentingRoleTypes(value: string, opt_index?: number): void {
    jspb.Message.addToRepeatedField(this, 24, value, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearAllowedCommentingRoleTypesList() {
    this.setAllowedCommentingRoleTypesList([]);
  }


  /**
   * optional string owner_group_hash = 25;
   * @return {string}
   */
  getOwnerGroupHash(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 25, ""));
  };


  /** @param {string} value */
  setOwnerGroupHash(value: string) {
    jspb.Message.setProto3StringField(this, 25, value);
  }


  /**
   * optional bool cancelled = 26;
   * @return {boolean}
   */
  getCancelled(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 26, false));
  };


  /** @param {boolean} value */
  setCancelled(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 26, value);
  }


  /**
   * repeated string event_managers = 27;
   * @return {!Array<string>}
   */
  getEventManagersList(): string[] {
    return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 27));
  };


  /** @param {!Array<string>} value */
  setEventManagersList(value: string[]) {
    jspb.Message.setField(this, 27, value || []);
  }


  /**
   * @param {string} value
   * @param {number=} opt_index
   */
  addEventManagers(value: string, opt_index?: number): void {
    jspb.Message.addToRepeatedField(this, 27, value, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearEventManagersList() {
    this.setEventManagersList([]);
  }


  /**
   * optional int32 checkin_count = 28;
   * @return {number}
   */
  getCheckinCount(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 28, 0));
  };


  /** @param {number} value */
  setCheckinCount(value: number) {
    jspb.Message.setProto3IntField(this, 28, value);
  }


  /**
   * optional int32 checkout_count = 29;
   * @return {number}
   */
  getCheckoutCount(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 29, 0));
  };


  /** @param {number} value */
  setCheckoutCount(value: number) {
    jspb.Message.setProto3IntField(this, 29, value);
  }


  /**
   * optional string ticket_url = 30;
   * @return {string}
   */
  getTicketUrl(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 30, ""));
  };


  /** @param {string} value */
  setTicketUrl(value: string) {
    jspb.Message.setProto3StringField(this, 30, value);
  }


  /**
   * optional string category = 31;
   * @return {string}
   */
  getCategory(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 31, ""));
  };


  /** @param {string} value */
  setCategory(value: string) {
    jspb.Message.setProto3StringField(this, 31, value);
  }


  /**
   * optional int32 points = 32;
   * @return {number}
   */
  getPoints(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 32, 0));
  };


  /** @param {number} value */
  setPoints(value: number) {
    jspb.Message.setProto3IntField(this, 32, value);
  }


  /**
   * optional bool has_tickets = 33;
   * @return {boolean}
   */
  getHasTickets(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 33, false));
  };


  /** @param {boolean} value */
  setHasTickets(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 33, value);
  }


  /**
   * optional int32 ticket_count = 34;
   * @return {number}
   */
  getTicketCount(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 34, 0));
  };


  /** @param {number} value */
  setTicketCount(value: number) {
    jspb.Message.setProto3IntField(this, 34, value);
  }


  /**
   * optional string ticket_info = 35;
   * @return {string}
   */
  getTicketInfo(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 35, ""));
  };


  /** @param {string} value */
  setTicketInfo(value: string) {
    jspb.Message.setProto3StringField(this, 35, value);
  }


  /**
   * optional float ticket_price = 36;
   * @return {number}
   */
  getTicketPrice(): number {
    return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 36, 0.0));
  };


  /** @param {number} value */
  setTicketPrice(value: number) {
    jspb.Message.setProto3FloatField(this, 36, value);
  }


  /**
   * optional string event_status = 37;
   * @return {string}
   */
  getEventStatus(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 37, ""));
  };


  /** @param {string} value */
  setEventStatus(value: string) {
    jspb.Message.setProto3StringField(this, 37, value);
  }


  /**
   * optional bool allow_self_check_in = 38;
   * @return {boolean}
   */
  getAllowSelfCheckIn(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 38, false));
  };


  /** @param {boolean} value */
  setAllowSelfCheckIn(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 38, value);
  }


  /**
   * optional bool has_liked = 102;
   * @return {boolean}
   */
  getHasLiked(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 102, false));
  };


  /** @param {boolean} value */
  setHasLiked(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 102, value);
  }


  /**
   * optional int32 event_reason_id = 103;
   * @return {number}
   */
  getEventReasonId(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 103, 0));
  };


  /** @param {number} value */
  setEventReasonId(value: number) {
    jspb.Message.setProto3IntField(this, 103, value);
  }


}
export namespace ShortEventCardView {
  export interface AsObject {
    title: string;
    body: string;
    timestamp: number;
    likeCount: number;
    commentCount: number;
    totalCommentCount: number;
    commentList: FeedComment.AsObject[];
    authorPersonView?: gateway_person_view_pb.PersonViewMinimal.AsObject;
    featuredImage?: image_image_pb.ImageInfo.AsObject;
    contentHash: string;
    startTimestamp: number;
    endTimestamp: number;
    allowGoing: boolean;
    hostedBy: string;
    location: string;
    goingCount: number;
    hasCode: boolean;
    lightContent: boolean;
    gradientOverlap: boolean;
    backgroundColor: string;
    gradientSize: number;
    banner?: gateway_banner_pb.Banner.AsObject;
    allDay: boolean;
    ownerGroupHash: string;
    allowedCommentingRoleTypesList: string[];
    cancelled: boolean;
    eventManagersList: string[];
    category: string;
    points: number;
    hasTickets: boolean;
    ticketPrice: number;
    eventStatus: string;
    pinned: boolean;
    unpublishTimestamp: number;
    hasLiked: boolean;
    eventReasonId: number;
    allowSelfCheckIn: boolean;
    contextHash: string;
  }
}
export class ShortEventCardView extends jspb.Message {
  static readonly displayName = "ShortEventCardView";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [7,31,33];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, ShortEventCardView.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): ShortEventCardView.AsObject {
    return ShortEventCardView.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!ShortEventCardView} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: ShortEventCardView) {
    var f: any;
    var obj: any = {
      title: jspb.Message.getFieldWithDefault(msg, 1, ""),
      body: jspb.Message.getFieldWithDefault(msg, 2, ""),
      timestamp: jspb.Message.getFieldWithDefault(msg, 3, 0),
      likeCount: jspb.Message.getFieldWithDefault(msg, 4, 0),
      commentCount: jspb.Message.getFieldWithDefault(msg, 5, 0),
      totalCommentCount: jspb.Message.getFieldWithDefault(msg, 6, 0),
      commentList: jspb.Message.toObjectList(msg.getCommentList(),
      FeedComment.toObject, includeInstance),
      authorPersonView: (f = msg.getAuthorPersonView()) && gateway_person_view_pb.PersonViewMinimal.toObject(includeInstance, f),
      featuredImage: (f = msg.getFeaturedImage()) && image_image_pb.ImageInfo.toObject(includeInstance, f),
      contentHash: jspb.Message.getFieldWithDefault(msg, 10, ""),
      startTimestamp: jspb.Message.getFieldWithDefault(msg, 11, 0),
      endTimestamp: jspb.Message.getFieldWithDefault(msg, 12, 0),
      allowGoing: jspb.Message.getBooleanFieldWithDefault(msg, 13, false),
      hostedBy: jspb.Message.getFieldWithDefault(msg, 14, ""),
      location: jspb.Message.getFieldWithDefault(msg, 15, ""),
      goingCount: jspb.Message.getFieldWithDefault(msg, 16, 0),
      hasCode: jspb.Message.getBooleanFieldWithDefault(msg, 17, false),
      lightContent: jspb.Message.getBooleanFieldWithDefault(msg, 18, false),
      gradientOverlap: jspb.Message.getBooleanFieldWithDefault(msg, 19, false),
      backgroundColor: jspb.Message.getFieldWithDefault(msg, 20, ""),
      gradientSize: jspb.Message.getFloatingPointFieldWithDefault(msg, 23, 0.0),
      banner: (f = msg.getBanner()) && gateway_banner_pb.Banner.toObject(includeInstance, f),
      allDay: jspb.Message.getBooleanFieldWithDefault(msg, 22, false),
      ownerGroupHash: jspb.Message.getFieldWithDefault(msg, 30, ""),
      allowedCommentingRoleTypesList: (f = jspb.Message.getRepeatedField(msg, 31)) == null ? undefined : f,
      cancelled: jspb.Message.getBooleanFieldWithDefault(msg, 32, false),
      eventManagersList: (f = jspb.Message.getRepeatedField(msg, 33)) == null ? undefined : f,
      category: jspb.Message.getFieldWithDefault(msg, 34, ""),
      points: jspb.Message.getFieldWithDefault(msg, 35, 0),
      hasTickets: jspb.Message.getBooleanFieldWithDefault(msg, 36, false),
      ticketPrice: jspb.Message.getFloatingPointFieldWithDefault(msg, 37, 0.0),
      eventStatus: jspb.Message.getFieldWithDefault(msg, 38, ""),
      pinned: jspb.Message.getBooleanFieldWithDefault(msg, 100, false),
      unpublishTimestamp: jspb.Message.getFieldWithDefault(msg, 101, 0),
      hasLiked: jspb.Message.getBooleanFieldWithDefault(msg, 102, false),
      eventReasonId: jspb.Message.getFieldWithDefault(msg, 103, 0),
      allowSelfCheckIn: jspb.Message.getBooleanFieldWithDefault(msg, 104, false),
      contextHash: jspb.Message.getFieldWithDefault(msg, 105, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!ShortEventCardView}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new ShortEventCardView;
    return ShortEventCardView.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!ShortEventCardView} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!ShortEventCardView}
   */
  static deserializeBinaryFromReader(msg: ShortEventCardView, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setTitle(value1);
        break;
      case 2:
        var value2 = /** @type {string} */ (reader.readString());
        msg.setBody(value2);
        break;
      case 3:
        var value3 = /** @type {number} */ (reader.readInt64());
        msg.setTimestamp(value3);
        break;
      case 4:
        var value4 = /** @type {number} */ (reader.readInt32());
        msg.setLikeCount(value4);
        break;
      case 5:
        var value5 = /** @type {number} */ (reader.readInt32());
        msg.setCommentCount(value5);
        break;
      case 6:
        var value6 = /** @type {number} */ (reader.readInt32());
        msg.setTotalCommentCount(value6);
        break;
      case 7:
        var value7 = new FeedComment;
        reader.readMessage(value7,FeedComment.deserializeBinaryFromReader);
        msg.addComment(value7);
        break;
      case 8:
        var value8 = new gateway_person_view_pb.PersonViewMinimal;
        reader.readMessage(value8,gateway_person_view_pb.PersonViewMinimal.deserializeBinaryFromReader);
        msg.setAuthorPersonView(value8);
        break;
      case 9:
        var value9 = new image_image_pb.ImageInfo;
        reader.readMessage(value9,image_image_pb.ImageInfo.deserializeBinaryFromReader);
        msg.setFeaturedImage(value9);
        break;
      case 10:
        var value10 = /** @type {string} */ (reader.readString());
        msg.setContentHash(value10);
        break;
      case 11:
        var value11 = /** @type {number} */ (reader.readInt64());
        msg.setStartTimestamp(value11);
        break;
      case 12:
        var value12 = /** @type {number} */ (reader.readInt64());
        msg.setEndTimestamp(value12);
        break;
      case 13:
        var value13 = /** @type {boolean} */ (reader.readBool());
        msg.setAllowGoing(value13);
        break;
      case 14:
        var value14 = /** @type {string} */ (reader.readString());
        msg.setHostedBy(value14);
        break;
      case 15:
        var value15 = /** @type {string} */ (reader.readString());
        msg.setLocation(value15);
        break;
      case 16:
        var value16 = /** @type {number} */ (reader.readInt32());
        msg.setGoingCount(value16);
        break;
      case 17:
        var value17 = /** @type {boolean} */ (reader.readBool());
        msg.setHasCode(value17);
        break;
      case 18:
        var value18 = /** @type {boolean} */ (reader.readBool());
        msg.setLightContent(value18);
        break;
      case 19:
        var value19 = /** @type {boolean} */ (reader.readBool());
        msg.setGradientOverlap(value19);
        break;
      case 20:
        var value20 = /** @type {string} */ (reader.readString());
        msg.setBackgroundColor(value20);
        break;
      case 23:
        var value23 = /** @type {number} */ (reader.readFloat());
        msg.setGradientSize(value23);
        break;
      case 21:
        var value21 = new gateway_banner_pb.Banner;
        reader.readMessage(value21,gateway_banner_pb.Banner.deserializeBinaryFromReader);
        msg.setBanner(value21);
        break;
      case 22:
        var value22 = /** @type {boolean} */ (reader.readBool());
        msg.setAllDay(value22);
        break;
      case 30:
        var value30 = /** @type {string} */ (reader.readString());
        msg.setOwnerGroupHash(value30);
        break;
      case 31:
        var value31 = /** @type {string} */ (reader.readString());
        msg.addAllowedCommentingRoleTypes(value31);
        break;
      case 32:
        var value32 = /** @type {boolean} */ (reader.readBool());
        msg.setCancelled(value32);
        break;
      case 33:
        var value33 = /** @type {string} */ (reader.readString());
        msg.addEventManagers(value33);
        break;
      case 34:
        var value34 = /** @type {string} */ (reader.readString());
        msg.setCategory(value34);
        break;
      case 35:
        var value35 = /** @type {number} */ (reader.readInt32());
        msg.setPoints(value35);
        break;
      case 36:
        var value36 = /** @type {boolean} */ (reader.readBool());
        msg.setHasTickets(value36);
        break;
      case 37:
        var value37 = /** @type {number} */ (reader.readFloat());
        msg.setTicketPrice(value37);
        break;
      case 38:
        var value38 = /** @type {string} */ (reader.readString());
        msg.setEventStatus(value38);
        break;
      case 100:
        var value100 = /** @type {boolean} */ (reader.readBool());
        msg.setPinned(value100);
        break;
      case 101:
        var value101 = /** @type {number} */ (reader.readUint64());
        msg.setUnpublishTimestamp(value101);
        break;
      case 102:
        var value102 = /** @type {boolean} */ (reader.readBool());
        msg.setHasLiked(value102);
        break;
      case 103:
        var value103 = /** @type {number} */ (reader.readInt32());
        msg.setEventReasonId(value103);
        break;
      case 104:
        var value104 = /** @type {boolean} */ (reader.readBool());
        msg.setAllowSelfCheckIn(value104);
        break;
      case 105:
        var value105 = /** @type {string} */ (reader.readString());
        msg.setContextHash(value105);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!ShortEventCardView} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: ShortEventCardView, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getTitle();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
    f = message.getBody();
    if (f.length > 0) {
      writer.writeString(
        2,
        f
      );
    }
    f = message.getTimestamp();
    if (f !== 0) {
      writer.writeInt64(
        3,
        f
      );
    }
    f = message.getLikeCount();
    if (f !== 0) {
      writer.writeInt32(
        4,
        f
      );
    }
    f = message.getCommentCount();
    if (f !== 0) {
      writer.writeInt32(
        5,
        f
      );
    }
    f = message.getTotalCommentCount();
    if (f !== 0) {
      writer.writeInt32(
        6,
        f
      );
    }
    f = message.getCommentList();
    if (f.length > 0) {
      writer.writeRepeatedMessage(
        7,
        f,
        FeedComment.serializeBinaryToWriter
      );
    }
    f = message.getAuthorPersonView();
    if (f != null) {
      writer.writeMessage(
        8,
        f,
        gateway_person_view_pb.PersonViewMinimal.serializeBinaryToWriter
      );
    }
    f = message.getFeaturedImage();
    if (f != null) {
      writer.writeMessage(
        9,
        f,
        image_image_pb.ImageInfo.serializeBinaryToWriter
      );
    }
    f = message.getContentHash();
    if (f.length > 0) {
      writer.writeString(
        10,
        f
      );
    }
    f = message.getStartTimestamp();
    if (f !== 0) {
      writer.writeInt64(
        11,
        f
      );
    }
    f = message.getEndTimestamp();
    if (f !== 0) {
      writer.writeInt64(
        12,
        f
      );
    }
    f = message.getAllowGoing();
    if (f) {
      writer.writeBool(
        13,
        f
      );
    }
    f = message.getHostedBy();
    if (f.length > 0) {
      writer.writeString(
        14,
        f
      );
    }
    f = message.getLocation();
    if (f.length > 0) {
      writer.writeString(
        15,
        f
      );
    }
    f = message.getGoingCount();
    if (f !== 0) {
      writer.writeInt32(
        16,
        f
      );
    }
    f = message.getHasCode();
    if (f) {
      writer.writeBool(
        17,
        f
      );
    }
    f = message.getLightContent();
    if (f) {
      writer.writeBool(
        18,
        f
      );
    }
    f = message.getGradientOverlap();
    if (f) {
      writer.writeBool(
        19,
        f
      );
    }
    f = message.getBackgroundColor();
    if (f.length > 0) {
      writer.writeString(
        20,
        f
      );
    }
    f = message.getGradientSize();
    if (f !== 0.0) {
      writer.writeFloat(
        23,
        f
      );
    }
    f = message.getBanner();
    if (f != null) {
      writer.writeMessage(
        21,
        f,
        gateway_banner_pb.Banner.serializeBinaryToWriter
      );
    }
    f = message.getAllDay();
    if (f) {
      writer.writeBool(
        22,
        f
      );
    }
    f = message.getOwnerGroupHash();
    if (f.length > 0) {
      writer.writeString(
        30,
        f
      );
    }
    f = message.getAllowedCommentingRoleTypesList();
    if (f.length > 0) {
      writer.writeRepeatedString(
        31,
        f
      );
    }
    f = message.getCancelled();
    if (f) {
      writer.writeBool(
        32,
        f
      );
    }
    f = message.getEventManagersList();
    if (f.length > 0) {
      writer.writeRepeatedString(
        33,
        f
      );
    }
    f = message.getCategory();
    if (f.length > 0) {
      writer.writeString(
        34,
        f
      );
    }
    f = message.getPoints();
    if (f !== 0) {
      writer.writeInt32(
        35,
        f
      );
    }
    f = message.getHasTickets();
    if (f) {
      writer.writeBool(
        36,
        f
      );
    }
    f = message.getTicketPrice();
    if (f !== 0.0) {
      writer.writeFloat(
        37,
        f
      );
    }
    f = message.getEventStatus();
    if (f.length > 0) {
      writer.writeString(
        38,
        f
      );
    }
    f = message.getPinned();
    if (f) {
      writer.writeBool(
        100,
        f
      );
    }
    f = message.getUnpublishTimestamp();
    if (f !== 0) {
      writer.writeUint64(
        101,
        f
      );
    }
    f = message.getHasLiked();
    if (f) {
      writer.writeBool(
        102,
        f
      );
    }
    f = message.getEventReasonId();
    if (f !== 0) {
      writer.writeInt32(
        103,
        f
      );
    }
    f = message.getAllowSelfCheckIn();
    if (f) {
      writer.writeBool(
        104,
        f
      );
    }
    f = message.getContextHash();
    if (f.length > 0) {
      writer.writeString(
        105,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    ShortEventCardView.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string title = 1;
   * @return {string}
   */
  getTitle(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setTitle(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


  /**
   * optional string body = 2;
   * @return {string}
   */
  getBody(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
  };


  /** @param {string} value */
  setBody(value: string) {
    jspb.Message.setProto3StringField(this, 2, value);
  }


  /**
   * optional int64 timestamp = 3;
   * @return {number}
   */
  getTimestamp(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
  };


  /** @param {number} value */
  setTimestamp(value: number) {
    jspb.Message.setProto3IntField(this, 3, value);
  }


  /**
   * optional int32 like_count = 4;
   * @return {number}
   */
  getLikeCount(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
  };


  /** @param {number} value */
  setLikeCount(value: number) {
    jspb.Message.setProto3IntField(this, 4, value);
  }


  /**
   * optional int32 comment_count = 5;
   * @return {number}
   */
  getCommentCount(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
  };


  /** @param {number} value */
  setCommentCount(value: number) {
    jspb.Message.setProto3IntField(this, 5, value);
  }


  /**
   * optional int32 total_comment_count = 6;
   * @return {number}
   */
  getTotalCommentCount(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
  };


  /** @param {number} value */
  setTotalCommentCount(value: number) {
    jspb.Message.setProto3IntField(this, 6, value);
  }


  /**
   * repeated FeedComment comment = 7;
   * @return {!Array<!FeedComment>}
   */
  getCommentList(): FeedComment[] {
    return /** @type{!Array<!FeedComment>} */ (
      jspb.Message.getRepeatedWrapperField(this, FeedComment, 7));
  }


  /** @param {!Array<!FeedComment>} value */
  setCommentList(value?: FeedComment[]) {
    jspb.Message.setRepeatedWrapperField(this, 7, value);
  }


  /**
   * @param {!FeedComment=} opt_value
   * @param {number=} opt_index
   * @return {!FeedComment}
   */
  addComment(opt_value?: FeedComment, opt_index?: number): FeedComment {
    return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, FeedComment, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearCommentList() {
    this.setCommentList([]);
  }


  /**
   * optional PersonViewMinimal author_person_view = 8;
   * @return {?PersonViewMinimal}
   */
  getAuthorPersonView(): gateway_person_view_pb.PersonViewMinimal {
    return /** @type{?PersonViewMinimal} */ (
      jspb.Message.getWrapperField(this, gateway_person_view_pb.PersonViewMinimal, 8));
  }


  /** @param {?PersonViewMinimal|undefined} value */
  setAuthorPersonView(value?: gateway_person_view_pb.PersonViewMinimal) {
    jspb.Message.setWrapperField(this, 8, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearAuthorPersonView() {
    this.setAuthorPersonView(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasAuthorPersonView(): boolean {
    return jspb.Message.getField(this, 8) != null;
  }


  /**
   * optional ImageInfo featured_image = 9;
   * @return {?ImageInfo}
   */
  getFeaturedImage(): image_image_pb.ImageInfo {
    return /** @type{?ImageInfo} */ (
      jspb.Message.getWrapperField(this, image_image_pb.ImageInfo, 9));
  }


  /** @param {?ImageInfo|undefined} value */
  setFeaturedImage(value?: image_image_pb.ImageInfo) {
    jspb.Message.setWrapperField(this, 9, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearFeaturedImage() {
    this.setFeaturedImage(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasFeaturedImage(): boolean {
    return jspb.Message.getField(this, 9) != null;
  }


  /**
   * optional string content_hash = 10;
   * @return {string}
   */
  getContentHash(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
  };


  /** @param {string} value */
  setContentHash(value: string) {
    jspb.Message.setProto3StringField(this, 10, value);
  }


  /**
   * optional int64 start_timestamp = 11;
   * @return {number}
   */
  getStartTimestamp(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
  };


  /** @param {number} value */
  setStartTimestamp(value: number) {
    jspb.Message.setProto3IntField(this, 11, value);
  }


  /**
   * optional int64 end_timestamp = 12;
   * @return {number}
   */
  getEndTimestamp(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
  };


  /** @param {number} value */
  setEndTimestamp(value: number) {
    jspb.Message.setProto3IntField(this, 12, value);
  }


  /**
   * optional bool allow_going = 13;
   * @return {boolean}
   */
  getAllowGoing(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 13, false));
  };


  /** @param {boolean} value */
  setAllowGoing(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 13, value);
  }


  /**
   * optional string hosted_by = 14;
   * @return {string}
   */
  getHostedBy(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
  };


  /** @param {string} value */
  setHostedBy(value: string) {
    jspb.Message.setProto3StringField(this, 14, value);
  }


  /**
   * optional string location = 15;
   * @return {string}
   */
  getLocation(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
  };


  /** @param {string} value */
  setLocation(value: string) {
    jspb.Message.setProto3StringField(this, 15, value);
  }


  /**
   * optional int32 going_count = 16;
   * @return {number}
   */
  getGoingCount(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
  };


  /** @param {number} value */
  setGoingCount(value: number) {
    jspb.Message.setProto3IntField(this, 16, value);
  }


  /**
   * optional bool has_code = 17;
   * @return {boolean}
   */
  getHasCode(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 17, false));
  };


  /** @param {boolean} value */
  setHasCode(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 17, value);
  }


  /**
   * optional bool light_content = 18;
   * @return {boolean}
   */
  getLightContent(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 18, false));
  };


  /** @param {boolean} value */
  setLightContent(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 18, value);
  }


  /**
   * optional bool gradient_overlap = 19;
   * @return {boolean}
   */
  getGradientOverlap(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 19, false));
  };


  /** @param {boolean} value */
  setGradientOverlap(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 19, value);
  }


  /**
   * optional string background_color = 20;
   * @return {string}
   */
  getBackgroundColor(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
  };


  /** @param {string} value */
  setBackgroundColor(value: string) {
    jspb.Message.setProto3StringField(this, 20, value);
  }


  /**
   * optional float gradient_size = 23;
   * @return {number}
   */
  getGradientSize(): number {
    return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 23, 0.0));
  };


  /** @param {number} value */
  setGradientSize(value: number) {
    jspb.Message.setProto3FloatField(this, 23, value);
  }


  /**
   * optional minga.banner.Banner banner = 21;
   * @return {?Banner}
   */
  getBanner(): gateway_banner_pb.Banner {
    return /** @type{?Banner} */ (
      jspb.Message.getWrapperField(this, gateway_banner_pb.Banner, 21));
  }


  /** @param {?Banner|undefined} value */
  setBanner(value?: gateway_banner_pb.Banner) {
    jspb.Message.setWrapperField(this, 21, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearBanner() {
    this.setBanner(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasBanner(): boolean {
    return jspb.Message.getField(this, 21) != null;
  }


  /**
   * optional bool all_day = 22;
   * @return {boolean}
   */
  getAllDay(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 22, false));
  };


  /** @param {boolean} value */
  setAllDay(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 22, value);
  }


  /**
   * optional string owner_group_hash = 30;
   * @return {string}
   */
  getOwnerGroupHash(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 30, ""));
  };


  /** @param {string} value */
  setOwnerGroupHash(value: string) {
    jspb.Message.setProto3StringField(this, 30, value);
  }


  /**
   * repeated string allowed_commenting_role_types = 31;
   * @return {!Array<string>}
   */
  getAllowedCommentingRoleTypesList(): string[] {
    return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 31));
  };


  /** @param {!Array<string>} value */
  setAllowedCommentingRoleTypesList(value: string[]) {
    jspb.Message.setField(this, 31, value || []);
  }


  /**
   * @param {string} value
   * @param {number=} opt_index
   */
  addAllowedCommentingRoleTypes(value: string, opt_index?: number): void {
    jspb.Message.addToRepeatedField(this, 31, value, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearAllowedCommentingRoleTypesList() {
    this.setAllowedCommentingRoleTypesList([]);
  }


  /**
   * optional bool cancelled = 32;
   * @return {boolean}
   */
  getCancelled(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 32, false));
  };


  /** @param {boolean} value */
  setCancelled(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 32, value);
  }


  /**
   * repeated string event_managers = 33;
   * @return {!Array<string>}
   */
  getEventManagersList(): string[] {
    return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 33));
  };


  /** @param {!Array<string>} value */
  setEventManagersList(value: string[]) {
    jspb.Message.setField(this, 33, value || []);
  }


  /**
   * @param {string} value
   * @param {number=} opt_index
   */
  addEventManagers(value: string, opt_index?: number): void {
    jspb.Message.addToRepeatedField(this, 33, value, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearEventManagersList() {
    this.setEventManagersList([]);
  }


  /**
   * optional string category = 34;
   * @return {string}
   */
  getCategory(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 34, ""));
  };


  /** @param {string} value */
  setCategory(value: string) {
    jspb.Message.setProto3StringField(this, 34, value);
  }


  /**
   * optional int32 points = 35;
   * @return {number}
   */
  getPoints(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 35, 0));
  };


  /** @param {number} value */
  setPoints(value: number) {
    jspb.Message.setProto3IntField(this, 35, value);
  }


  /**
   * optional bool has_tickets = 36;
   * @return {boolean}
   */
  getHasTickets(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 36, false));
  };


  /** @param {boolean} value */
  setHasTickets(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 36, value);
  }


  /**
   * optional float ticket_price = 37;
   * @return {number}
   */
  getTicketPrice(): number {
    return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 37, 0.0));
  };


  /** @param {number} value */
  setTicketPrice(value: number) {
    jspb.Message.setProto3FloatField(this, 37, value);
  }


  /**
   * optional string event_status = 38;
   * @return {string}
   */
  getEventStatus(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 38, ""));
  };


  /** @param {string} value */
  setEventStatus(value: string) {
    jspb.Message.setProto3StringField(this, 38, value);
  }


  /**
   * optional bool pinned = 100;
   * @return {boolean}
   */
  getPinned(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 100, false));
  };


  /** @param {boolean} value */
  setPinned(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 100, value);
  }


  /**
   * optional uint64 unpublish_timestamp = 101;
   * @return {number}
   */
  getUnpublishTimestamp(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 101, 0));
  };


  /** @param {number} value */
  setUnpublishTimestamp(value: number) {
    jspb.Message.setProto3IntField(this, 101, value);
  }


  /**
   * optional bool has_liked = 102;
   * @return {boolean}
   */
  getHasLiked(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 102, false));
  };


  /** @param {boolean} value */
  setHasLiked(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 102, value);
  }


  /**
   * optional int32 event_reason_id = 103;
   * @return {number}
   */
  getEventReasonId(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 103, 0));
  };


  /** @param {number} value */
  setEventReasonId(value: number) {
    jspb.Message.setProto3IntField(this, 103, value);
  }


  /**
   * optional bool allow_self_check_in = 104;
   * @return {boolean}
   */
  getAllowSelfCheckIn(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 104, false));
  };


  /** @param {boolean} value */
  setAllowSelfCheckIn(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 104, value);
  }


  /**
   * optional string context_hash = 105;
   * @return {string}
   */
  getContextHash(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 105, ""));
  };


  /** @param {string} value */
  setContextHash(value: string) {
    jspb.Message.setProto3StringField(this, 105, value);
  }


}
export namespace PollChoice {
  export interface AsObject {
    text: string;
    icon: string;
    count: number;
    choiceId: number;
  }
}
export class PollChoice extends jspb.Message {
  static readonly displayName = "PollChoice";
  /**
   * Oneof group definitions for this message. Each group defines the field
   * numbers belonging to that group. When of these fields' value is set, all
   * other fields in the group are cleared. During deserialization, if multiple
   * fields are encountered for a group, only the last value seen will be kept.
   * @private {!Array<!Array<number>>}
   * @const
   */
  static readonly oneofGroups_ = [[1,2]];


  /**
   * @return {PollChoice.ChoiceCase}
   */
  getChoiceCase() {
    return /** @type {PollChoice.ChoiceCase} */(jspb.Message.computeOneofCase(this, PollChoice.oneofGroups_[0]));
  }

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, PollChoice.oneofGroups_);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): PollChoice.AsObject {
    return PollChoice.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!PollChoice} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: PollChoice) {
    var f: any;
    var obj: any = {
      text: jspb.Message.getFieldWithDefault(msg, 1, ""),
      icon: jspb.Message.getFieldWithDefault(msg, 2, ""),
      count: jspb.Message.getFieldWithDefault(msg, 3, 0),
      choiceId: jspb.Message.getFieldWithDefault(msg, 4, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!PollChoice}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new PollChoice;
    return PollChoice.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!PollChoice} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!PollChoice}
   */
  static deserializeBinaryFromReader(msg: PollChoice, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setText(value1);
        break;
      case 2:
        var value2 = /** @type {string} */ (reader.readString());
        msg.setIcon(value2);
        break;
      case 3:
        var value3 = /** @type {number} */ (reader.readInt32());
        msg.setCount(value3);
        break;
      case 4:
        var value4 = /** @type {number} */ (reader.readUint32());
        msg.setChoiceId(value4);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!PollChoice} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: PollChoice, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = /** @type {string} */ (jspb.Message.getField(message, 1));
    if (f != null) {
      writer.writeString(
        1,
        f
      );
    }
    f = /** @type {string} */ (jspb.Message.getField(message, 2));
    if (f != null) {
      writer.writeString(
        2,
        f
      );
    }
    f = message.getCount();
    if (f !== 0) {
      writer.writeInt32(
        3,
        f
      );
    }
    f = message.getChoiceId();
    if (f !== 0) {
      writer.writeUint32(
        4,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    PollChoice.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string text = 1;
   * @return {string}
   */
  getText(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setText(value: string) {
    jspb.Message.setOneofField(this, 1, PollChoice.oneofGroups_[0], value);
  }


  /**
   * Clears the field making it undefined.
   */
  clearText() {
    jspb.Message.setOneofField(this, 1, PollChoice.oneofGroups_[0], undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasText(): boolean {
    return jspb.Message.getField(this, 1) != null;
  }


  /**
   * optional string icon = 2;
   * @return {string}
   */
  getIcon(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
  };


  /** @param {string} value */
  setIcon(value: string) {
    jspb.Message.setOneofField(this, 2, PollChoice.oneofGroups_[0], value);
  }


  /**
   * Clears the field making it undefined.
   */
  clearIcon() {
    jspb.Message.setOneofField(this, 2, PollChoice.oneofGroups_[0], undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasIcon(): boolean {
    return jspb.Message.getField(this, 2) != null;
  }


  /**
   * optional int32 count = 3;
   * @return {number}
   */
  getCount(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
  };


  /** @param {number} value */
  setCount(value: number) {
    jspb.Message.setProto3IntField(this, 3, value);
  }


  /**
   * optional uint32 choice_id = 4;
   * @return {number}
   */
  getChoiceId(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
  };


  /** @param {number} value */
  setChoiceId(value: number) {
    jspb.Message.setProto3IntField(this, 4, value);
  }


}
export namespace PollChoice {
export enum ChoiceCase {
  CHOICE_NOT_SET = 0,
  TEXT = 1,
  ICON = 2,
}
} // namespace PollChoice
export namespace ShortFileCardView {
  export interface AsObject {
    image?: image_image_pb.ImageInfo.AsObject;
    filePath: string;
    name: string;
    fileExtension: string;
    url: string;
    timestamp: number;
    mimeType: string;
    contentHash: string;
    authorPersonView?: gateway_person_view_pb.PersonViewMinimal.AsObject;
    ownerGroupHash: string;
    pinned: boolean;
    unpublishTimestamp: number;
    hasLiked: boolean;
  }
}
export class ShortFileCardView extends jspb.Message {
  static readonly displayName = "ShortFileCardView";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): ShortFileCardView.AsObject {
    return ShortFileCardView.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!ShortFileCardView} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: ShortFileCardView) {
    var f: any;
    var obj: any = {
      image: (f = msg.getImage()) && image_image_pb.ImageInfo.toObject(includeInstance, f),
      filePath: jspb.Message.getFieldWithDefault(msg, 2, ""),
      name: jspb.Message.getFieldWithDefault(msg, 3, ""),
      fileExtension: jspb.Message.getFieldWithDefault(msg, 4, ""),
      url: jspb.Message.getFieldWithDefault(msg, 5, ""),
      timestamp: jspb.Message.getFieldWithDefault(msg, 6, 0),
      mimeType: jspb.Message.getFieldWithDefault(msg, 7, ""),
      contentHash: jspb.Message.getFieldWithDefault(msg, 8, ""),
      authorPersonView: (f = msg.getAuthorPersonView()) && gateway_person_view_pb.PersonViewMinimal.toObject(includeInstance, f),
      ownerGroupHash: jspb.Message.getFieldWithDefault(msg, 10, ""),
      pinned: jspb.Message.getBooleanFieldWithDefault(msg, 100, false),
      unpublishTimestamp: jspb.Message.getFieldWithDefault(msg, 101, 0),
      hasLiked: jspb.Message.getBooleanFieldWithDefault(msg, 102, false)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!ShortFileCardView}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new ShortFileCardView;
    return ShortFileCardView.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!ShortFileCardView} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!ShortFileCardView}
   */
  static deserializeBinaryFromReader(msg: ShortFileCardView, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new image_image_pb.ImageInfo;
        reader.readMessage(value1,image_image_pb.ImageInfo.deserializeBinaryFromReader);
        msg.setImage(value1);
        break;
      case 2:
        var value2 = /** @type {string} */ (reader.readString());
        msg.setFilePath(value2);
        break;
      case 3:
        var value3 = /** @type {string} */ (reader.readString());
        msg.setName(value3);
        break;
      case 4:
        var value4 = /** @type {string} */ (reader.readString());
        msg.setFileExtension(value4);
        break;
      case 5:
        var value5 = /** @type {string} */ (reader.readString());
        msg.setUrl(value5);
        break;
      case 6:
        var value6 = /** @type {number} */ (reader.readInt64());
        msg.setTimestamp(value6);
        break;
      case 7:
        var value7 = /** @type {string} */ (reader.readString());
        msg.setMimeType(value7);
        break;
      case 8:
        var value8 = /** @type {string} */ (reader.readString());
        msg.setContentHash(value8);
        break;
      case 9:
        var value9 = new gateway_person_view_pb.PersonViewMinimal;
        reader.readMessage(value9,gateway_person_view_pb.PersonViewMinimal.deserializeBinaryFromReader);
        msg.setAuthorPersonView(value9);
        break;
      case 10:
        var value10 = /** @type {string} */ (reader.readString());
        msg.setOwnerGroupHash(value10);
        break;
      case 100:
        var value100 = /** @type {boolean} */ (reader.readBool());
        msg.setPinned(value100);
        break;
      case 101:
        var value101 = /** @type {number} */ (reader.readUint64());
        msg.setUnpublishTimestamp(value101);
        break;
      case 102:
        var value102 = /** @type {boolean} */ (reader.readBool());
        msg.setHasLiked(value102);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!ShortFileCardView} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: ShortFileCardView, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getImage();
    if (f != null) {
      writer.writeMessage(
        1,
        f,
        image_image_pb.ImageInfo.serializeBinaryToWriter
      );
    }
    f = message.getFilePath();
    if (f.length > 0) {
      writer.writeString(
        2,
        f
      );
    }
    f = message.getName();
    if (f.length > 0) {
      writer.writeString(
        3,
        f
      );
    }
    f = message.getFileExtension();
    if (f.length > 0) {
      writer.writeString(
        4,
        f
      );
    }
    f = message.getUrl();
    if (f.length > 0) {
      writer.writeString(
        5,
        f
      );
    }
    f = message.getTimestamp();
    if (f !== 0) {
      writer.writeInt64(
        6,
        f
      );
    }
    f = message.getMimeType();
    if (f.length > 0) {
      writer.writeString(
        7,
        f
      );
    }
    f = message.getContentHash();
    if (f.length > 0) {
      writer.writeString(
        8,
        f
      );
    }
    f = message.getAuthorPersonView();
    if (f != null) {
      writer.writeMessage(
        9,
        f,
        gateway_person_view_pb.PersonViewMinimal.serializeBinaryToWriter
      );
    }
    f = message.getOwnerGroupHash();
    if (f.length > 0) {
      writer.writeString(
        10,
        f
      );
    }
    f = message.getPinned();
    if (f) {
      writer.writeBool(
        100,
        f
      );
    }
    f = message.getUnpublishTimestamp();
    if (f !== 0) {
      writer.writeUint64(
        101,
        f
      );
    }
    f = message.getHasLiked();
    if (f) {
      writer.writeBool(
        102,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    ShortFileCardView.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional ImageInfo image = 1;
   * @return {?ImageInfo}
   */
  getImage(): image_image_pb.ImageInfo {
    return /** @type{?ImageInfo} */ (
      jspb.Message.getWrapperField(this, image_image_pb.ImageInfo, 1));
  }


  /** @param {?ImageInfo|undefined} value */
  setImage(value?: image_image_pb.ImageInfo) {
    jspb.Message.setWrapperField(this, 1, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearImage() {
    this.setImage(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasImage(): boolean {
    return jspb.Message.getField(this, 1) != null;
  }


  /**
   * optional string file_path = 2;
   * @return {string}
   */
  getFilePath(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
  };


  /** @param {string} value */
  setFilePath(value: string) {
    jspb.Message.setProto3StringField(this, 2, value);
  }


  /**
   * optional string name = 3;
   * @return {string}
   */
  getName(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
  };


  /** @param {string} value */
  setName(value: string) {
    jspb.Message.setProto3StringField(this, 3, value);
  }


  /**
   * optional string file_extension = 4;
   * @return {string}
   */
  getFileExtension(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
  };


  /** @param {string} value */
  setFileExtension(value: string) {
    jspb.Message.setProto3StringField(this, 4, value);
  }


  /**
   * optional string url = 5;
   * @return {string}
   */
  getUrl(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
  };


  /** @param {string} value */
  setUrl(value: string) {
    jspb.Message.setProto3StringField(this, 5, value);
  }


  /**
   * optional int64 timestamp = 6;
   * @return {number}
   */
  getTimestamp(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
  };


  /** @param {number} value */
  setTimestamp(value: number) {
    jspb.Message.setProto3IntField(this, 6, value);
  }


  /**
   * optional string mime_type = 7;
   * @return {string}
   */
  getMimeType(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
  };


  /** @param {string} value */
  setMimeType(value: string) {
    jspb.Message.setProto3StringField(this, 7, value);
  }


  /**
   * optional string content_hash = 8;
   * @return {string}
   */
  getContentHash(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
  };


  /** @param {string} value */
  setContentHash(value: string) {
    jspb.Message.setProto3StringField(this, 8, value);
  }


  /**
   * optional PersonViewMinimal author_person_view = 9;
   * @return {?PersonViewMinimal}
   */
  getAuthorPersonView(): gateway_person_view_pb.PersonViewMinimal {
    return /** @type{?PersonViewMinimal} */ (
      jspb.Message.getWrapperField(this, gateway_person_view_pb.PersonViewMinimal, 9));
  }


  /** @param {?PersonViewMinimal|undefined} value */
  setAuthorPersonView(value?: gateway_person_view_pb.PersonViewMinimal) {
    jspb.Message.setWrapperField(this, 9, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearAuthorPersonView() {
    this.setAuthorPersonView(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasAuthorPersonView(): boolean {
    return jspb.Message.getField(this, 9) != null;
  }


  /**
   * optional string owner_group_hash = 10;
   * @return {string}
   */
  getOwnerGroupHash(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
  };


  /** @param {string} value */
  setOwnerGroupHash(value: string) {
    jspb.Message.setProto3StringField(this, 10, value);
  }


  /**
   * optional bool pinned = 100;
   * @return {boolean}
   */
  getPinned(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 100, false));
  };


  /** @param {boolean} value */
  setPinned(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 100, value);
  }


  /**
   * optional uint64 unpublish_timestamp = 101;
   * @return {number}
   */
  getUnpublishTimestamp(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 101, 0));
  };


  /** @param {number} value */
  setUnpublishTimestamp(value: number) {
    jspb.Message.setProto3IntField(this, 101, value);
  }


  /**
   * optional bool has_liked = 102;
   * @return {boolean}
   */
  getHasLiked(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 102, false));
  };


  /** @param {boolean} value */
  setHasLiked(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 102, value);
  }


}
export namespace ShortPollCardView {
  export interface AsObject {
    contentHash: string;
    timestamp: number;
    closeTimestamp: number;
    showResults: boolean;
    choiceList: PollChoice.AsObject[];
    totalChoiceCount: number;
    question: string;
    pollBgImage: string;
    authorPersonView?: gateway_person_view_pb.PersonViewMinimal.AsObject;
    pollLayout?: PollLayout;
    pollResults?: content_poll_answer_pb.PollResults.AsObject;
    pollClosed: boolean;
    points: number;
    hasResponded: number;
    ownerGroupHash: string;
    pinned: boolean;
    unpublishTimestamp: number;
    hasLiked: boolean;
  }
}
export class ShortPollCardView extends jspb.Message {
  static readonly displayName = "ShortPollCardView";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [5];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, ShortPollCardView.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): ShortPollCardView.AsObject {
    return ShortPollCardView.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!ShortPollCardView} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: ShortPollCardView) {
    var f: any;
    var obj: any = {
      contentHash: jspb.Message.getFieldWithDefault(msg, 1, ""),
      timestamp: jspb.Message.getFieldWithDefault(msg, 2, 0),
      closeTimestamp: jspb.Message.getFieldWithDefault(msg, 3, 0),
      showResults: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
      choiceList: jspb.Message.toObjectList(msg.getChoiceList(),
      PollChoice.toObject, includeInstance),
      totalChoiceCount: jspb.Message.getFieldWithDefault(msg, 6, 0),
      question: jspb.Message.getFieldWithDefault(msg, 7, ""),
      pollBgImage: jspb.Message.getFieldWithDefault(msg, 8, ""),
      authorPersonView: (f = msg.getAuthorPersonView()) && gateway_person_view_pb.PersonViewMinimal.toObject(includeInstance, f),
      pollLayout: jspb.Message.getFieldWithDefault(msg, 10, 0),
      pollResults: (f = msg.getPollResults()) && content_poll_answer_pb.PollResults.toObject(includeInstance, f),
      pollClosed: jspb.Message.getBooleanFieldWithDefault(msg, 13, false),
      points: jspb.Message.getFieldWithDefault(msg, 14, 0),
      hasResponded: jspb.Message.getFieldWithDefault(msg, 15, 0),
      ownerGroupHash: jspb.Message.getFieldWithDefault(msg, 30, ""),
      pinned: jspb.Message.getBooleanFieldWithDefault(msg, 100, false),
      unpublishTimestamp: jspb.Message.getFieldWithDefault(msg, 101, 0),
      hasLiked: jspb.Message.getBooleanFieldWithDefault(msg, 102, false)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!ShortPollCardView}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new ShortPollCardView;
    return ShortPollCardView.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!ShortPollCardView} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!ShortPollCardView}
   */
  static deserializeBinaryFromReader(msg: ShortPollCardView, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setContentHash(value1);
        break;
      case 2:
        var value2 = /** @type {number} */ (reader.readUint64());
        msg.setTimestamp(value2);
        break;
      case 3:
        var value3 = /** @type {number} */ (reader.readUint64());
        msg.setCloseTimestamp(value3);
        break;
      case 4:
        var value4 = /** @type {boolean} */ (reader.readBool());
        msg.setShowResults(value4);
        break;
      case 5:
        var value5 = new PollChoice;
        reader.readMessage(value5,PollChoice.deserializeBinaryFromReader);
        msg.addChoice(value5);
        break;
      case 6:
        var value6 = /** @type {number} */ (reader.readUint32());
        msg.setTotalChoiceCount(value6);
        break;
      case 7:
        var value7 = /** @type {string} */ (reader.readString());
        msg.setQuestion(value7);
        break;
      case 8:
        var value8 = /** @type {string} */ (reader.readString());
        msg.setPollBgImage(value8);
        break;
      case 9:
        var value9 = new gateway_person_view_pb.PersonViewMinimal;
        reader.readMessage(value9,gateway_person_view_pb.PersonViewMinimal.deserializeBinaryFromReader);
        msg.setAuthorPersonView(value9);
        break;
      case 10:
        var value10 = /** @type {!PollLayout} */ (reader.readEnum());
        msg.setPollLayout(value10);
        break;
      case 11:
        var value11 = new content_poll_answer_pb.PollResults;
        reader.readMessage(value11,content_poll_answer_pb.PollResults.deserializeBinaryFromReader);
        msg.setPollResults(value11);
        break;
      case 13:
        var value13 = /** @type {boolean} */ (reader.readBool());
        msg.setPollClosed(value13);
        break;
      case 14:
        var value14 = /** @type {number} */ (reader.readInt32());
        msg.setPoints(value14);
        break;
      case 15:
        var value15 = /** @type {number} */ (reader.readInt32());
        msg.setHasResponded(value15);
        break;
      case 30:
        var value30 = /** @type {string} */ (reader.readString());
        msg.setOwnerGroupHash(value30);
        break;
      case 100:
        var value100 = /** @type {boolean} */ (reader.readBool());
        msg.setPinned(value100);
        break;
      case 101:
        var value101 = /** @type {number} */ (reader.readUint64());
        msg.setUnpublishTimestamp(value101);
        break;
      case 102:
        var value102 = /** @type {boolean} */ (reader.readBool());
        msg.setHasLiked(value102);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!ShortPollCardView} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: ShortPollCardView, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getContentHash();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
    f = message.getTimestamp();
    if (f !== 0) {
      writer.writeUint64(
        2,
        f
      );
    }
    f = message.getCloseTimestamp();
    if (f !== 0) {
      writer.writeUint64(
        3,
        f
      );
    }
    f = message.getShowResults();
    if (f) {
      writer.writeBool(
        4,
        f
      );
    }
    f = message.getChoiceList();
    if (f.length > 0) {
      writer.writeRepeatedMessage(
        5,
        f,
        PollChoice.serializeBinaryToWriter
      );
    }
    f = message.getTotalChoiceCount();
    if (f !== 0) {
      writer.writeUint32(
        6,
        f
      );
    }
    f = message.getQuestion();
    if (f.length > 0) {
      writer.writeString(
        7,
        f
      );
    }
    f = message.getPollBgImage();
    if (f.length > 0) {
      writer.writeString(
        8,
        f
      );
    }
    f = message.getAuthorPersonView();
    if (f != null) {
      writer.writeMessage(
        9,
        f,
        gateway_person_view_pb.PersonViewMinimal.serializeBinaryToWriter
      );
    }
    f = message.getPollLayout();
    if (f !== 0.0) {
      writer.writeEnum(
        10,
        f
      );
    }
    f = message.getPollResults();
    if (f != null) {
      writer.writeMessage(
        11,
        f,
        content_poll_answer_pb.PollResults.serializeBinaryToWriter
      );
    }
    f = message.getPollClosed();
    if (f) {
      writer.writeBool(
        13,
        f
      );
    }
    f = message.getPoints();
    if (f !== 0) {
      writer.writeInt32(
        14,
        f
      );
    }
    f = message.getHasResponded();
    if (f !== 0) {
      writer.writeInt32(
        15,
        f
      );
    }
    f = message.getOwnerGroupHash();
    if (f.length > 0) {
      writer.writeString(
        30,
        f
      );
    }
    f = message.getPinned();
    if (f) {
      writer.writeBool(
        100,
        f
      );
    }
    f = message.getUnpublishTimestamp();
    if (f !== 0) {
      writer.writeUint64(
        101,
        f
      );
    }
    f = message.getHasLiked();
    if (f) {
      writer.writeBool(
        102,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    ShortPollCardView.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string content_hash = 1;
   * @return {string}
   */
  getContentHash(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setContentHash(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


  /**
   * optional uint64 timestamp = 2;
   * @return {number}
   */
  getTimestamp(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
  };


  /** @param {number} value */
  setTimestamp(value: number) {
    jspb.Message.setProto3IntField(this, 2, value);
  }


  /**
   * optional uint64 close_timestamp = 3;
   * @return {number}
   */
  getCloseTimestamp(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
  };


  /** @param {number} value */
  setCloseTimestamp(value: number) {
    jspb.Message.setProto3IntField(this, 3, value);
  }


  /**
   * optional bool show_results = 4;
   * @return {boolean}
   */
  getShowResults(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
  };


  /** @param {boolean} value */
  setShowResults(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 4, value);
  }


  /**
   * repeated PollChoice choice = 5;
   * @return {!Array<!PollChoice>}
   */
  getChoiceList(): PollChoice[] {
    return /** @type{!Array<!PollChoice>} */ (
      jspb.Message.getRepeatedWrapperField(this, PollChoice, 5));
  }


  /** @param {!Array<!PollChoice>} value */
  setChoiceList(value?: PollChoice[]) {
    jspb.Message.setRepeatedWrapperField(this, 5, value);
  }


  /**
   * @param {!PollChoice=} opt_value
   * @param {number=} opt_index
   * @return {!PollChoice}
   */
  addChoice(opt_value?: PollChoice, opt_index?: number): PollChoice {
    return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, PollChoice, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearChoiceList() {
    this.setChoiceList([]);
  }


  /**
   * optional uint32 total_choice_count = 6;
   * @return {number}
   */
  getTotalChoiceCount(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
  };


  /** @param {number} value */
  setTotalChoiceCount(value: number) {
    jspb.Message.setProto3IntField(this, 6, value);
  }


  /**
   * optional string question = 7;
   * @return {string}
   */
  getQuestion(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
  };


  /** @param {string} value */
  setQuestion(value: string) {
    jspb.Message.setProto3StringField(this, 7, value);
  }


  /**
   * optional string poll_bg_image = 8;
   * @return {string}
   */
  getPollBgImage(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
  };


  /** @param {string} value */
  setPollBgImage(value: string) {
    jspb.Message.setProto3StringField(this, 8, value);
  }


  /**
   * optional PersonViewMinimal author_person_view = 9;
   * @return {?PersonViewMinimal}
   */
  getAuthorPersonView(): gateway_person_view_pb.PersonViewMinimal {
    return /** @type{?PersonViewMinimal} */ (
      jspb.Message.getWrapperField(this, gateway_person_view_pb.PersonViewMinimal, 9));
  }


  /** @param {?PersonViewMinimal|undefined} value */
  setAuthorPersonView(value?: gateway_person_view_pb.PersonViewMinimal) {
    jspb.Message.setWrapperField(this, 9, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearAuthorPersonView() {
    this.setAuthorPersonView(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasAuthorPersonView(): boolean {
    return jspb.Message.getField(this, 9) != null;
  }


  /**
   * optional PollLayout poll_layout = 10;
   * @return {!PollLayout}
   */
  getPollLayout(): PollLayout {
    return /** @type {!PollLayout} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
  };


  /** @param {!PollLayout} value */
  setPollLayout(value: PollLayout) {
    jspb.Message.setProto3EnumField(this, 10, value);
  }


  /**
   * optional minga.content.PollResults poll_results = 11;
   * @return {?PollResults}
   */
  getPollResults(): content_poll_answer_pb.PollResults {
    return /** @type{?PollResults} */ (
      jspb.Message.getWrapperField(this, content_poll_answer_pb.PollResults, 11));
  }


  /** @param {?PollResults|undefined} value */
  setPollResults(value?: content_poll_answer_pb.PollResults) {
    jspb.Message.setWrapperField(this, 11, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearPollResults() {
    this.setPollResults(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasPollResults(): boolean {
    return jspb.Message.getField(this, 11) != null;
  }


  /**
   * optional bool poll_closed = 13;
   * @return {boolean}
   */
  getPollClosed(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 13, false));
  };


  /** @param {boolean} value */
  setPollClosed(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 13, value);
  }


  /**
   * optional int32 points = 14;
   * @return {number}
   */
  getPoints(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
  };


  /** @param {number} value */
  setPoints(value: number) {
    jspb.Message.setProto3IntField(this, 14, value);
  }


  /**
   * optional int32 has_responded = 15;
   * @return {number}
   */
  getHasResponded(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
  };


  /** @param {number} value */
  setHasResponded(value: number) {
    jspb.Message.setProto3IntField(this, 15, value);
  }


  /**
   * optional string owner_group_hash = 30;
   * @return {string}
   */
  getOwnerGroupHash(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 30, ""));
  };


  /** @param {string} value */
  setOwnerGroupHash(value: string) {
    jspb.Message.setProto3StringField(this, 30, value);
  }


  /**
   * optional bool pinned = 100;
   * @return {boolean}
   */
  getPinned(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 100, false));
  };


  /** @param {boolean} value */
  setPinned(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 100, value);
  }


  /**
   * optional uint64 unpublish_timestamp = 101;
   * @return {number}
   */
  getUnpublishTimestamp(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 101, 0));
  };


  /** @param {number} value */
  setUnpublishTimestamp(value: number) {
    jspb.Message.setProto3IntField(this, 101, value);
  }


  /**
   * optional bool has_liked = 102;
   * @return {boolean}
   */
  getHasLiked(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 102, false));
  };


  /** @param {boolean} value */
  setHasLiked(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 102, value);
  }


}
export namespace LongPollCardView {
  export interface AsObject {
    contentHash: string;
    timestamp: number;
    closeTimestamp: number;
    showResults: boolean;
    choiceList: PollChoice.AsObject[];
    totalChoiceCount: number;
    question: string;
    pollBgImage: string;
    authorPersonView?: gateway_person_view_pb.PersonViewMinimal.AsObject;
    pollLayout?: PollLayout;
    pollResults?: content_poll_answer_pb.PollResults.AsObject;
    pollClosed: boolean;
    ownerGroupHash: string;
    points: number;
    hasResponded: number;
    hasLiked: boolean;
  }
}
export class LongPollCardView extends jspb.Message {
  static readonly displayName = "LongPollCardView";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [5];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, LongPollCardView.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): LongPollCardView.AsObject {
    return LongPollCardView.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!LongPollCardView} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: LongPollCardView) {
    var f: any;
    var obj: any = {
      contentHash: jspb.Message.getFieldWithDefault(msg, 1, ""),
      timestamp: jspb.Message.getFieldWithDefault(msg, 2, 0),
      closeTimestamp: jspb.Message.getFieldWithDefault(msg, 3, 0),
      showResults: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
      choiceList: jspb.Message.toObjectList(msg.getChoiceList(),
      PollChoice.toObject, includeInstance),
      totalChoiceCount: jspb.Message.getFieldWithDefault(msg, 6, 0),
      question: jspb.Message.getFieldWithDefault(msg, 7, ""),
      pollBgImage: jspb.Message.getFieldWithDefault(msg, 8, ""),
      authorPersonView: (f = msg.getAuthorPersonView()) && gateway_person_view_pb.PersonViewMinimal.toObject(includeInstance, f),
      pollLayout: jspb.Message.getFieldWithDefault(msg, 10, 0),
      pollResults: (f = msg.getPollResults()) && content_poll_answer_pb.PollResults.toObject(includeInstance, f),
      pollClosed: jspb.Message.getBooleanFieldWithDefault(msg, 13, false),
      ownerGroupHash: jspb.Message.getFieldWithDefault(msg, 14, ""),
      points: jspb.Message.getFieldWithDefault(msg, 15, 0),
      hasResponded: jspb.Message.getFieldWithDefault(msg, 16, 0),
      hasLiked: jspb.Message.getBooleanFieldWithDefault(msg, 102, false)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!LongPollCardView}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new LongPollCardView;
    return LongPollCardView.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!LongPollCardView} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!LongPollCardView}
   */
  static deserializeBinaryFromReader(msg: LongPollCardView, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setContentHash(value1);
        break;
      case 2:
        var value2 = /** @type {number} */ (reader.readUint64());
        msg.setTimestamp(value2);
        break;
      case 3:
        var value3 = /** @type {number} */ (reader.readUint64());
        msg.setCloseTimestamp(value3);
        break;
      case 4:
        var value4 = /** @type {boolean} */ (reader.readBool());
        msg.setShowResults(value4);
        break;
      case 5:
        var value5 = new PollChoice;
        reader.readMessage(value5,PollChoice.deserializeBinaryFromReader);
        msg.addChoice(value5);
        break;
      case 6:
        var value6 = /** @type {number} */ (reader.readUint32());
        msg.setTotalChoiceCount(value6);
        break;
      case 7:
        var value7 = /** @type {string} */ (reader.readString());
        msg.setQuestion(value7);
        break;
      case 8:
        var value8 = /** @type {string} */ (reader.readString());
        msg.setPollBgImage(value8);
        break;
      case 9:
        var value9 = new gateway_person_view_pb.PersonViewMinimal;
        reader.readMessage(value9,gateway_person_view_pb.PersonViewMinimal.deserializeBinaryFromReader);
        msg.setAuthorPersonView(value9);
        break;
      case 10:
        var value10 = /** @type {!PollLayout} */ (reader.readEnum());
        msg.setPollLayout(value10);
        break;
      case 11:
        var value11 = new content_poll_answer_pb.PollResults;
        reader.readMessage(value11,content_poll_answer_pb.PollResults.deserializeBinaryFromReader);
        msg.setPollResults(value11);
        break;
      case 13:
        var value13 = /** @type {boolean} */ (reader.readBool());
        msg.setPollClosed(value13);
        break;
      case 14:
        var value14 = /** @type {string} */ (reader.readString());
        msg.setOwnerGroupHash(value14);
        break;
      case 15:
        var value15 = /** @type {number} */ (reader.readInt32());
        msg.setPoints(value15);
        break;
      case 16:
        var value16 = /** @type {number} */ (reader.readInt32());
        msg.setHasResponded(value16);
        break;
      case 102:
        var value102 = /** @type {boolean} */ (reader.readBool());
        msg.setHasLiked(value102);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!LongPollCardView} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: LongPollCardView, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getContentHash();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
    f = message.getTimestamp();
    if (f !== 0) {
      writer.writeUint64(
        2,
        f
      );
    }
    f = message.getCloseTimestamp();
    if (f !== 0) {
      writer.writeUint64(
        3,
        f
      );
    }
    f = message.getShowResults();
    if (f) {
      writer.writeBool(
        4,
        f
      );
    }
    f = message.getChoiceList();
    if (f.length > 0) {
      writer.writeRepeatedMessage(
        5,
        f,
        PollChoice.serializeBinaryToWriter
      );
    }
    f = message.getTotalChoiceCount();
    if (f !== 0) {
      writer.writeUint32(
        6,
        f
      );
    }
    f = message.getQuestion();
    if (f.length > 0) {
      writer.writeString(
        7,
        f
      );
    }
    f = message.getPollBgImage();
    if (f.length > 0) {
      writer.writeString(
        8,
        f
      );
    }
    f = message.getAuthorPersonView();
    if (f != null) {
      writer.writeMessage(
        9,
        f,
        gateway_person_view_pb.PersonViewMinimal.serializeBinaryToWriter
      );
    }
    f = message.getPollLayout();
    if (f !== 0.0) {
      writer.writeEnum(
        10,
        f
      );
    }
    f = message.getPollResults();
    if (f != null) {
      writer.writeMessage(
        11,
        f,
        content_poll_answer_pb.PollResults.serializeBinaryToWriter
      );
    }
    f = message.getPollClosed();
    if (f) {
      writer.writeBool(
        13,
        f
      );
    }
    f = message.getOwnerGroupHash();
    if (f.length > 0) {
      writer.writeString(
        14,
        f
      );
    }
    f = message.getPoints();
    if (f !== 0) {
      writer.writeInt32(
        15,
        f
      );
    }
    f = message.getHasResponded();
    if (f !== 0) {
      writer.writeInt32(
        16,
        f
      );
    }
    f = message.getHasLiked();
    if (f) {
      writer.writeBool(
        102,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    LongPollCardView.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string content_hash = 1;
   * @return {string}
   */
  getContentHash(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setContentHash(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


  /**
   * optional uint64 timestamp = 2;
   * @return {number}
   */
  getTimestamp(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
  };


  /** @param {number} value */
  setTimestamp(value: number) {
    jspb.Message.setProto3IntField(this, 2, value);
  }


  /**
   * optional uint64 close_timestamp = 3;
   * @return {number}
   */
  getCloseTimestamp(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
  };


  /** @param {number} value */
  setCloseTimestamp(value: number) {
    jspb.Message.setProto3IntField(this, 3, value);
  }


  /**
   * optional bool show_results = 4;
   * @return {boolean}
   */
  getShowResults(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
  };


  /** @param {boolean} value */
  setShowResults(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 4, value);
  }


  /**
   * repeated PollChoice choice = 5;
   * @return {!Array<!PollChoice>}
   */
  getChoiceList(): PollChoice[] {
    return /** @type{!Array<!PollChoice>} */ (
      jspb.Message.getRepeatedWrapperField(this, PollChoice, 5));
  }


  /** @param {!Array<!PollChoice>} value */
  setChoiceList(value?: PollChoice[]) {
    jspb.Message.setRepeatedWrapperField(this, 5, value);
  }


  /**
   * @param {!PollChoice=} opt_value
   * @param {number=} opt_index
   * @return {!PollChoice}
   */
  addChoice(opt_value?: PollChoice, opt_index?: number): PollChoice {
    return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, PollChoice, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearChoiceList() {
    this.setChoiceList([]);
  }


  /**
   * optional uint32 total_choice_count = 6;
   * @return {number}
   */
  getTotalChoiceCount(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
  };


  /** @param {number} value */
  setTotalChoiceCount(value: number) {
    jspb.Message.setProto3IntField(this, 6, value);
  }


  /**
   * optional string question = 7;
   * @return {string}
   */
  getQuestion(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
  };


  /** @param {string} value */
  setQuestion(value: string) {
    jspb.Message.setProto3StringField(this, 7, value);
  }


  /**
   * optional string poll_bg_image = 8;
   * @return {string}
   */
  getPollBgImage(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
  };


  /** @param {string} value */
  setPollBgImage(value: string) {
    jspb.Message.setProto3StringField(this, 8, value);
  }


  /**
   * optional PersonViewMinimal author_person_view = 9;
   * @return {?PersonViewMinimal}
   */
  getAuthorPersonView(): gateway_person_view_pb.PersonViewMinimal {
    return /** @type{?PersonViewMinimal} */ (
      jspb.Message.getWrapperField(this, gateway_person_view_pb.PersonViewMinimal, 9));
  }


  /** @param {?PersonViewMinimal|undefined} value */
  setAuthorPersonView(value?: gateway_person_view_pb.PersonViewMinimal) {
    jspb.Message.setWrapperField(this, 9, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearAuthorPersonView() {
    this.setAuthorPersonView(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasAuthorPersonView(): boolean {
    return jspb.Message.getField(this, 9) != null;
  }


  /**
   * optional PollLayout poll_layout = 10;
   * @return {!PollLayout}
   */
  getPollLayout(): PollLayout {
    return /** @type {!PollLayout} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
  };


  /** @param {!PollLayout} value */
  setPollLayout(value: PollLayout) {
    jspb.Message.setProto3EnumField(this, 10, value);
  }


  /**
   * optional minga.content.PollResults poll_results = 11;
   * @return {?PollResults}
   */
  getPollResults(): content_poll_answer_pb.PollResults {
    return /** @type{?PollResults} */ (
      jspb.Message.getWrapperField(this, content_poll_answer_pb.PollResults, 11));
  }


  /** @param {?PollResults|undefined} value */
  setPollResults(value?: content_poll_answer_pb.PollResults) {
    jspb.Message.setWrapperField(this, 11, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearPollResults() {
    this.setPollResults(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasPollResults(): boolean {
    return jspb.Message.getField(this, 11) != null;
  }


  /**
   * optional bool poll_closed = 13;
   * @return {boolean}
   */
  getPollClosed(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 13, false));
  };


  /** @param {boolean} value */
  setPollClosed(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 13, value);
  }


  /**
   * optional string owner_group_hash = 14;
   * @return {string}
   */
  getOwnerGroupHash(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
  };


  /** @param {string} value */
  setOwnerGroupHash(value: string) {
    jspb.Message.setProto3StringField(this, 14, value);
  }


  /**
   * optional int32 points = 15;
   * @return {number}
   */
  getPoints(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
  };


  /** @param {number} value */
  setPoints(value: number) {
    jspb.Message.setProto3IntField(this, 15, value);
  }


  /**
   * optional int32 has_responded = 16;
   * @return {number}
   */
  getHasResponded(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
  };


  /** @param {number} value */
  setHasResponded(value: number) {
    jspb.Message.setProto3IntField(this, 16, value);
  }


  /**
   * optional bool has_liked = 102;
   * @return {boolean}
   */
  getHasLiked(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 102, false));
  };


  /** @param {boolean} value */
  setHasLiked(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 102, value);
  }


}
export namespace LongAnnouncementCardView {
  export interface AsObject {
    title: string;
    body: string;
    timestamp: number;
    contentHash: string;
    lightContent: boolean;
    gradientOverlap: boolean;
    backgroundColor: string;
    gradientSize: number;
    authorPersonView?: gateway_person_view_pb.PersonViewMinimal.AsObject;
    ownerGroupHash: string;
  }
}
export class LongAnnouncementCardView extends jspb.Message {
  static readonly displayName = "LongAnnouncementCardView";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): LongAnnouncementCardView.AsObject {
    return LongAnnouncementCardView.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!LongAnnouncementCardView} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: LongAnnouncementCardView) {
    var f: any;
    var obj: any = {
      title: jspb.Message.getFieldWithDefault(msg, 1, ""),
      body: jspb.Message.getFieldWithDefault(msg, 2, ""),
      timestamp: jspb.Message.getFieldWithDefault(msg, 3, 0),
      contentHash: jspb.Message.getFieldWithDefault(msg, 4, ""),
      lightContent: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
      gradientOverlap: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
      backgroundColor: jspb.Message.getFieldWithDefault(msg, 7, ""),
      gradientSize: jspb.Message.getFloatingPointFieldWithDefault(msg, 9, 0.0),
      authorPersonView: (f = msg.getAuthorPersonView()) && gateway_person_view_pb.PersonViewMinimal.toObject(includeInstance, f),
      ownerGroupHash: jspb.Message.getFieldWithDefault(msg, 14, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!LongAnnouncementCardView}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new LongAnnouncementCardView;
    return LongAnnouncementCardView.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!LongAnnouncementCardView} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!LongAnnouncementCardView}
   */
  static deserializeBinaryFromReader(msg: LongAnnouncementCardView, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setTitle(value1);
        break;
      case 2:
        var value2 = /** @type {string} */ (reader.readString());
        msg.setBody(value2);
        break;
      case 3:
        var value3 = /** @type {number} */ (reader.readInt64());
        msg.setTimestamp(value3);
        break;
      case 4:
        var value4 = /** @type {string} */ (reader.readString());
        msg.setContentHash(value4);
        break;
      case 5:
        var value5 = /** @type {boolean} */ (reader.readBool());
        msg.setLightContent(value5);
        break;
      case 6:
        var value6 = /** @type {boolean} */ (reader.readBool());
        msg.setGradientOverlap(value6);
        break;
      case 7:
        var value7 = /** @type {string} */ (reader.readString());
        msg.setBackgroundColor(value7);
        break;
      case 9:
        var value9 = /** @type {number} */ (reader.readFloat());
        msg.setGradientSize(value9);
        break;
      case 8:
        var value8 = new gateway_person_view_pb.PersonViewMinimal;
        reader.readMessage(value8,gateway_person_view_pb.PersonViewMinimal.deserializeBinaryFromReader);
        msg.setAuthorPersonView(value8);
        break;
      case 14:
        var value14 = /** @type {string} */ (reader.readString());
        msg.setOwnerGroupHash(value14);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!LongAnnouncementCardView} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: LongAnnouncementCardView, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getTitle();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
    f = message.getBody();
    if (f.length > 0) {
      writer.writeString(
        2,
        f
      );
    }
    f = message.getTimestamp();
    if (f !== 0) {
      writer.writeInt64(
        3,
        f
      );
    }
    f = message.getContentHash();
    if (f.length > 0) {
      writer.writeString(
        4,
        f
      );
    }
    f = message.getLightContent();
    if (f) {
      writer.writeBool(
        5,
        f
      );
    }
    f = message.getGradientOverlap();
    if (f) {
      writer.writeBool(
        6,
        f
      );
    }
    f = message.getBackgroundColor();
    if (f.length > 0) {
      writer.writeString(
        7,
        f
      );
    }
    f = message.getGradientSize();
    if (f !== 0.0) {
      writer.writeFloat(
        9,
        f
      );
    }
    f = message.getAuthorPersonView();
    if (f != null) {
      writer.writeMessage(
        8,
        f,
        gateway_person_view_pb.PersonViewMinimal.serializeBinaryToWriter
      );
    }
    f = message.getOwnerGroupHash();
    if (f.length > 0) {
      writer.writeString(
        14,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    LongAnnouncementCardView.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string title = 1;
   * @return {string}
   */
  getTitle(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setTitle(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


  /**
   * optional string body = 2;
   * @return {string}
   */
  getBody(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
  };


  /** @param {string} value */
  setBody(value: string) {
    jspb.Message.setProto3StringField(this, 2, value);
  }


  /**
   * optional int64 timestamp = 3;
   * @return {number}
   */
  getTimestamp(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
  };


  /** @param {number} value */
  setTimestamp(value: number) {
    jspb.Message.setProto3IntField(this, 3, value);
  }


  /**
   * optional string content_hash = 4;
   * @return {string}
   */
  getContentHash(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
  };


  /** @param {string} value */
  setContentHash(value: string) {
    jspb.Message.setProto3StringField(this, 4, value);
  }


  /**
   * optional bool light_content = 5;
   * @return {boolean}
   */
  getLightContent(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
  };


  /** @param {boolean} value */
  setLightContent(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 5, value);
  }


  /**
   * optional bool gradient_overlap = 6;
   * @return {boolean}
   */
  getGradientOverlap(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
  };


  /** @param {boolean} value */
  setGradientOverlap(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 6, value);
  }


  /**
   * optional string background_color = 7;
   * @return {string}
   */
  getBackgroundColor(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
  };


  /** @param {string} value */
  setBackgroundColor(value: string) {
    jspb.Message.setProto3StringField(this, 7, value);
  }


  /**
   * optional float gradient_size = 9;
   * @return {number}
   */
  getGradientSize(): number {
    return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 9, 0.0));
  };


  /** @param {number} value */
  setGradientSize(value: number) {
    jspb.Message.setProto3FloatField(this, 9, value);
  }


  /**
   * optional PersonViewMinimal author_person_view = 8;
   * @return {?PersonViewMinimal}
   */
  getAuthorPersonView(): gateway_person_view_pb.PersonViewMinimal {
    return /** @type{?PersonViewMinimal} */ (
      jspb.Message.getWrapperField(this, gateway_person_view_pb.PersonViewMinimal, 8));
  }


  /** @param {?PersonViewMinimal|undefined} value */
  setAuthorPersonView(value?: gateway_person_view_pb.PersonViewMinimal) {
    jspb.Message.setWrapperField(this, 8, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearAuthorPersonView() {
    this.setAuthorPersonView(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasAuthorPersonView(): boolean {
    return jspb.Message.getField(this, 8) != null;
  }


  /**
   * optional string owner_group_hash = 14;
   * @return {string}
   */
  getOwnerGroupHash(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
  };


  /** @param {string} value */
  setOwnerGroupHash(value: string) {
    jspb.Message.setProto3StringField(this, 14, value);
  }


}
export namespace ShortAnnouncementCardView {
  export interface AsObject {
    title: string;
    body: string;
    timestamp: number;
    contentHash: string;
    lightContent: boolean;
    gradientOverlap: boolean;
    backgroundColor: string;
    gradientSize: number;
    authorPersonView?: gateway_person_view_pb.PersonViewMinimal.AsObject;
    ownerGroupHash: string;
    pinned: boolean;
    unpublishTimestamp: number;
  }
}
export class ShortAnnouncementCardView extends jspb.Message {
  static readonly displayName = "ShortAnnouncementCardView";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): ShortAnnouncementCardView.AsObject {
    return ShortAnnouncementCardView.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!ShortAnnouncementCardView} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: ShortAnnouncementCardView) {
    var f: any;
    var obj: any = {
      title: jspb.Message.getFieldWithDefault(msg, 1, ""),
      body: jspb.Message.getFieldWithDefault(msg, 2, ""),
      timestamp: jspb.Message.getFieldWithDefault(msg, 3, 0),
      contentHash: jspb.Message.getFieldWithDefault(msg, 4, ""),
      lightContent: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
      gradientOverlap: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
      backgroundColor: jspb.Message.getFieldWithDefault(msg, 7, ""),
      gradientSize: jspb.Message.getFloatingPointFieldWithDefault(msg, 9, 0.0),
      authorPersonView: (f = msg.getAuthorPersonView()) && gateway_person_view_pb.PersonViewMinimal.toObject(includeInstance, f),
      ownerGroupHash: jspb.Message.getFieldWithDefault(msg, 30, ""),
      pinned: jspb.Message.getBooleanFieldWithDefault(msg, 100, false),
      unpublishTimestamp: jspb.Message.getFieldWithDefault(msg, 101, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!ShortAnnouncementCardView}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new ShortAnnouncementCardView;
    return ShortAnnouncementCardView.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!ShortAnnouncementCardView} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!ShortAnnouncementCardView}
   */
  static deserializeBinaryFromReader(msg: ShortAnnouncementCardView, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setTitle(value1);
        break;
      case 2:
        var value2 = /** @type {string} */ (reader.readString());
        msg.setBody(value2);
        break;
      case 3:
        var value3 = /** @type {number} */ (reader.readInt64());
        msg.setTimestamp(value3);
        break;
      case 4:
        var value4 = /** @type {string} */ (reader.readString());
        msg.setContentHash(value4);
        break;
      case 5:
        var value5 = /** @type {boolean} */ (reader.readBool());
        msg.setLightContent(value5);
        break;
      case 6:
        var value6 = /** @type {boolean} */ (reader.readBool());
        msg.setGradientOverlap(value6);
        break;
      case 7:
        var value7 = /** @type {string} */ (reader.readString());
        msg.setBackgroundColor(value7);
        break;
      case 9:
        var value9 = /** @type {number} */ (reader.readFloat());
        msg.setGradientSize(value9);
        break;
      case 8:
        var value8 = new gateway_person_view_pb.PersonViewMinimal;
        reader.readMessage(value8,gateway_person_view_pb.PersonViewMinimal.deserializeBinaryFromReader);
        msg.setAuthorPersonView(value8);
        break;
      case 30:
        var value30 = /** @type {string} */ (reader.readString());
        msg.setOwnerGroupHash(value30);
        break;
      case 100:
        var value100 = /** @type {boolean} */ (reader.readBool());
        msg.setPinned(value100);
        break;
      case 101:
        var value101 = /** @type {number} */ (reader.readUint64());
        msg.setUnpublishTimestamp(value101);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!ShortAnnouncementCardView} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: ShortAnnouncementCardView, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getTitle();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
    f = message.getBody();
    if (f.length > 0) {
      writer.writeString(
        2,
        f
      );
    }
    f = message.getTimestamp();
    if (f !== 0) {
      writer.writeInt64(
        3,
        f
      );
    }
    f = message.getContentHash();
    if (f.length > 0) {
      writer.writeString(
        4,
        f
      );
    }
    f = message.getLightContent();
    if (f) {
      writer.writeBool(
        5,
        f
      );
    }
    f = message.getGradientOverlap();
    if (f) {
      writer.writeBool(
        6,
        f
      );
    }
    f = message.getBackgroundColor();
    if (f.length > 0) {
      writer.writeString(
        7,
        f
      );
    }
    f = message.getGradientSize();
    if (f !== 0.0) {
      writer.writeFloat(
        9,
        f
      );
    }
    f = message.getAuthorPersonView();
    if (f != null) {
      writer.writeMessage(
        8,
        f,
        gateway_person_view_pb.PersonViewMinimal.serializeBinaryToWriter
      );
    }
    f = message.getOwnerGroupHash();
    if (f.length > 0) {
      writer.writeString(
        30,
        f
      );
    }
    f = message.getPinned();
    if (f) {
      writer.writeBool(
        100,
        f
      );
    }
    f = message.getUnpublishTimestamp();
    if (f !== 0) {
      writer.writeUint64(
        101,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    ShortAnnouncementCardView.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string title = 1;
   * @return {string}
   */
  getTitle(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setTitle(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


  /**
   * optional string body = 2;
   * @return {string}
   */
  getBody(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
  };


  /** @param {string} value */
  setBody(value: string) {
    jspb.Message.setProto3StringField(this, 2, value);
  }


  /**
   * optional int64 timestamp = 3;
   * @return {number}
   */
  getTimestamp(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
  };


  /** @param {number} value */
  setTimestamp(value: number) {
    jspb.Message.setProto3IntField(this, 3, value);
  }


  /**
   * optional string content_hash = 4;
   * @return {string}
   */
  getContentHash(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
  };


  /** @param {string} value */
  setContentHash(value: string) {
    jspb.Message.setProto3StringField(this, 4, value);
  }


  /**
   * optional bool light_content = 5;
   * @return {boolean}
   */
  getLightContent(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
  };


  /** @param {boolean} value */
  setLightContent(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 5, value);
  }


  /**
   * optional bool gradient_overlap = 6;
   * @return {boolean}
   */
  getGradientOverlap(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
  };


  /** @param {boolean} value */
  setGradientOverlap(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 6, value);
  }


  /**
   * optional string background_color = 7;
   * @return {string}
   */
  getBackgroundColor(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
  };


  /** @param {string} value */
  setBackgroundColor(value: string) {
    jspb.Message.setProto3StringField(this, 7, value);
  }


  /**
   * optional float gradient_size = 9;
   * @return {number}
   */
  getGradientSize(): number {
    return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 9, 0.0));
  };


  /** @param {number} value */
  setGradientSize(value: number) {
    jspb.Message.setProto3FloatField(this, 9, value);
  }


  /**
   * optional PersonViewMinimal author_person_view = 8;
   * @return {?PersonViewMinimal}
   */
  getAuthorPersonView(): gateway_person_view_pb.PersonViewMinimal {
    return /** @type{?PersonViewMinimal} */ (
      jspb.Message.getWrapperField(this, gateway_person_view_pb.PersonViewMinimal, 8));
  }


  /** @param {?PersonViewMinimal|undefined} value */
  setAuthorPersonView(value?: gateway_person_view_pb.PersonViewMinimal) {
    jspb.Message.setWrapperField(this, 8, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearAuthorPersonView() {
    this.setAuthorPersonView(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasAuthorPersonView(): boolean {
    return jspb.Message.getField(this, 8) != null;
  }


  /**
   * optional string owner_group_hash = 30;
   * @return {string}
   */
  getOwnerGroupHash(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 30, ""));
  };


  /** @param {string} value */
  setOwnerGroupHash(value: string) {
    jspb.Message.setProto3StringField(this, 30, value);
  }


  /**
   * optional bool pinned = 100;
   * @return {boolean}
   */
  getPinned(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 100, false));
  };


  /** @param {boolean} value */
  setPinned(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 100, value);
  }


  /**
   * optional uint64 unpublish_timestamp = 101;
   * @return {number}
   */
  getUnpublishTimestamp(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 101, 0));
  };


  /** @param {number} value */
  setUnpublishTimestamp(value: number) {
    jspb.Message.setProto3IntField(this, 101, value);
  }


}
export namespace ShortInspirationCardView {
  export interface AsObject {
    contentHash: string;
    timestamp: number;
    featuredImage?: image_image_pb.ImageInfo.AsObject;
    lightContent: boolean;
    gradientOverlap: boolean;
    backgroundColor: string;
    gradientSize: number;
    likeCount: number;
    commentCount: number;
    totalCommentCount: number;
    commentList: FeedComment.AsObject[];
    authorPersonView?: gateway_person_view_pb.PersonViewMinimal.AsObject;
    ownerGroupHash: string;
    allowedCommentingRoleTypesList: string[];
    pinned: boolean;
    unpublishTimestamp: number;
    hasLiked: boolean;
  }
}
export class ShortInspirationCardView extends jspb.Message {
  static readonly displayName = "ShortInspirationCardView";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [10,31];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, ShortInspirationCardView.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): ShortInspirationCardView.AsObject {
    return ShortInspirationCardView.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!ShortInspirationCardView} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: ShortInspirationCardView) {
    var f: any;
    var obj: any = {
      contentHash: jspb.Message.getFieldWithDefault(msg, 1, ""),
      timestamp: jspb.Message.getFieldWithDefault(msg, 2, 0),
      featuredImage: (f = msg.getFeaturedImage()) && image_image_pb.ImageInfo.toObject(includeInstance, f),
      lightContent: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
      gradientOverlap: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
      backgroundColor: jspb.Message.getFieldWithDefault(msg, 6, ""),
      gradientSize: jspb.Message.getFloatingPointFieldWithDefault(msg, 12, 0.0),
      likeCount: jspb.Message.getFieldWithDefault(msg, 7, 0),
      commentCount: jspb.Message.getFieldWithDefault(msg, 8, 0),
      totalCommentCount: jspb.Message.getFieldWithDefault(msg, 9, 0),
      commentList: jspb.Message.toObjectList(msg.getCommentList(),
      FeedComment.toObject, includeInstance),
      authorPersonView: (f = msg.getAuthorPersonView()) && gateway_person_view_pb.PersonViewMinimal.toObject(includeInstance, f),
      ownerGroupHash: jspb.Message.getFieldWithDefault(msg, 30, ""),
      allowedCommentingRoleTypesList: (f = jspb.Message.getRepeatedField(msg, 31)) == null ? undefined : f,
      pinned: jspb.Message.getBooleanFieldWithDefault(msg, 100, false),
      unpublishTimestamp: jspb.Message.getFieldWithDefault(msg, 101, 0),
      hasLiked: jspb.Message.getBooleanFieldWithDefault(msg, 102, false)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!ShortInspirationCardView}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new ShortInspirationCardView;
    return ShortInspirationCardView.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!ShortInspirationCardView} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!ShortInspirationCardView}
   */
  static deserializeBinaryFromReader(msg: ShortInspirationCardView, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setContentHash(value1);
        break;
      case 2:
        var value2 = /** @type {number} */ (reader.readInt64());
        msg.setTimestamp(value2);
        break;
      case 3:
        var value3 = new image_image_pb.ImageInfo;
        reader.readMessage(value3,image_image_pb.ImageInfo.deserializeBinaryFromReader);
        msg.setFeaturedImage(value3);
        break;
      case 4:
        var value4 = /** @type {boolean} */ (reader.readBool());
        msg.setLightContent(value4);
        break;
      case 5:
        var value5 = /** @type {boolean} */ (reader.readBool());
        msg.setGradientOverlap(value5);
        break;
      case 6:
        var value6 = /** @type {string} */ (reader.readString());
        msg.setBackgroundColor(value6);
        break;
      case 12:
        var value12 = /** @type {number} */ (reader.readFloat());
        msg.setGradientSize(value12);
        break;
      case 7:
        var value7 = /** @type {number} */ (reader.readInt32());
        msg.setLikeCount(value7);
        break;
      case 8:
        var value8 = /** @type {number} */ (reader.readInt32());
        msg.setCommentCount(value8);
        break;
      case 9:
        var value9 = /** @type {number} */ (reader.readInt32());
        msg.setTotalCommentCount(value9);
        break;
      case 10:
        var value10 = new FeedComment;
        reader.readMessage(value10,FeedComment.deserializeBinaryFromReader);
        msg.addComment(value10);
        break;
      case 11:
        var value11 = new gateway_person_view_pb.PersonViewMinimal;
        reader.readMessage(value11,gateway_person_view_pb.PersonViewMinimal.deserializeBinaryFromReader);
        msg.setAuthorPersonView(value11);
        break;
      case 30:
        var value30 = /** @type {string} */ (reader.readString());
        msg.setOwnerGroupHash(value30);
        break;
      case 31:
        var value31 = /** @type {string} */ (reader.readString());
        msg.addAllowedCommentingRoleTypes(value31);
        break;
      case 100:
        var value100 = /** @type {boolean} */ (reader.readBool());
        msg.setPinned(value100);
        break;
      case 101:
        var value101 = /** @type {number} */ (reader.readUint64());
        msg.setUnpublishTimestamp(value101);
        break;
      case 102:
        var value102 = /** @type {boolean} */ (reader.readBool());
        msg.setHasLiked(value102);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!ShortInspirationCardView} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: ShortInspirationCardView, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getContentHash();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
    f = message.getTimestamp();
    if (f !== 0) {
      writer.writeInt64(
        2,
        f
      );
    }
    f = message.getFeaturedImage();
    if (f != null) {
      writer.writeMessage(
        3,
        f,
        image_image_pb.ImageInfo.serializeBinaryToWriter
      );
    }
    f = message.getLightContent();
    if (f) {
      writer.writeBool(
        4,
        f
      );
    }
    f = message.getGradientOverlap();
    if (f) {
      writer.writeBool(
        5,
        f
      );
    }
    f = message.getBackgroundColor();
    if (f.length > 0) {
      writer.writeString(
        6,
        f
      );
    }
    f = message.getGradientSize();
    if (f !== 0.0) {
      writer.writeFloat(
        12,
        f
      );
    }
    f = message.getLikeCount();
    if (f !== 0) {
      writer.writeInt32(
        7,
        f
      );
    }
    f = message.getCommentCount();
    if (f !== 0) {
      writer.writeInt32(
        8,
        f
      );
    }
    f = message.getTotalCommentCount();
    if (f !== 0) {
      writer.writeInt32(
        9,
        f
      );
    }
    f = message.getCommentList();
    if (f.length > 0) {
      writer.writeRepeatedMessage(
        10,
        f,
        FeedComment.serializeBinaryToWriter
      );
    }
    f = message.getAuthorPersonView();
    if (f != null) {
      writer.writeMessage(
        11,
        f,
        gateway_person_view_pb.PersonViewMinimal.serializeBinaryToWriter
      );
    }
    f = message.getOwnerGroupHash();
    if (f.length > 0) {
      writer.writeString(
        30,
        f
      );
    }
    f = message.getAllowedCommentingRoleTypesList();
    if (f.length > 0) {
      writer.writeRepeatedString(
        31,
        f
      );
    }
    f = message.getPinned();
    if (f) {
      writer.writeBool(
        100,
        f
      );
    }
    f = message.getUnpublishTimestamp();
    if (f !== 0) {
      writer.writeUint64(
        101,
        f
      );
    }
    f = message.getHasLiked();
    if (f) {
      writer.writeBool(
        102,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    ShortInspirationCardView.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string content_hash = 1;
   * @return {string}
   */
  getContentHash(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setContentHash(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


  /**
   * optional int64 timestamp = 2;
   * @return {number}
   */
  getTimestamp(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
  };


  /** @param {number} value */
  setTimestamp(value: number) {
    jspb.Message.setProto3IntField(this, 2, value);
  }


  /**
   * optional ImageInfo featured_image = 3;
   * @return {?ImageInfo}
   */
  getFeaturedImage(): image_image_pb.ImageInfo {
    return /** @type{?ImageInfo} */ (
      jspb.Message.getWrapperField(this, image_image_pb.ImageInfo, 3));
  }


  /** @param {?ImageInfo|undefined} value */
  setFeaturedImage(value?: image_image_pb.ImageInfo) {
    jspb.Message.setWrapperField(this, 3, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearFeaturedImage() {
    this.setFeaturedImage(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasFeaturedImage(): boolean {
    return jspb.Message.getField(this, 3) != null;
  }


  /**
   * optional bool light_content = 4;
   * @return {boolean}
   */
  getLightContent(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
  };


  /** @param {boolean} value */
  setLightContent(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 4, value);
  }


  /**
   * optional bool gradient_overlap = 5;
   * @return {boolean}
   */
  getGradientOverlap(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
  };


  /** @param {boolean} value */
  setGradientOverlap(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 5, value);
  }


  /**
   * optional string background_color = 6;
   * @return {string}
   */
  getBackgroundColor(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
  };


  /** @param {string} value */
  setBackgroundColor(value: string) {
    jspb.Message.setProto3StringField(this, 6, value);
  }


  /**
   * optional float gradient_size = 12;
   * @return {number}
   */
  getGradientSize(): number {
    return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 12, 0.0));
  };


  /** @param {number} value */
  setGradientSize(value: number) {
    jspb.Message.setProto3FloatField(this, 12, value);
  }


  /**
   * optional int32 like_count = 7;
   * @return {number}
   */
  getLikeCount(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
  };


  /** @param {number} value */
  setLikeCount(value: number) {
    jspb.Message.setProto3IntField(this, 7, value);
  }


  /**
   * optional int32 comment_count = 8;
   * @return {number}
   */
  getCommentCount(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
  };


  /** @param {number} value */
  setCommentCount(value: number) {
    jspb.Message.setProto3IntField(this, 8, value);
  }


  /**
   * optional int32 total_comment_count = 9;
   * @return {number}
   */
  getTotalCommentCount(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
  };


  /** @param {number} value */
  setTotalCommentCount(value: number) {
    jspb.Message.setProto3IntField(this, 9, value);
  }


  /**
   * repeated FeedComment comment = 10;
   * @return {!Array<!FeedComment>}
   */
  getCommentList(): FeedComment[] {
    return /** @type{!Array<!FeedComment>} */ (
      jspb.Message.getRepeatedWrapperField(this, FeedComment, 10));
  }


  /** @param {!Array<!FeedComment>} value */
  setCommentList(value?: FeedComment[]) {
    jspb.Message.setRepeatedWrapperField(this, 10, value);
  }


  /**
   * @param {!FeedComment=} opt_value
   * @param {number=} opt_index
   * @return {!FeedComment}
   */
  addComment(opt_value?: FeedComment, opt_index?: number): FeedComment {
    return jspb.Message.addToRepeatedWrapperField(this, 10, opt_value, FeedComment, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearCommentList() {
    this.setCommentList([]);
  }


  /**
   * optional PersonViewMinimal author_person_view = 11;
   * @return {?PersonViewMinimal}
   */
  getAuthorPersonView(): gateway_person_view_pb.PersonViewMinimal {
    return /** @type{?PersonViewMinimal} */ (
      jspb.Message.getWrapperField(this, gateway_person_view_pb.PersonViewMinimal, 11));
  }


  /** @param {?PersonViewMinimal|undefined} value */
  setAuthorPersonView(value?: gateway_person_view_pb.PersonViewMinimal) {
    jspb.Message.setWrapperField(this, 11, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearAuthorPersonView() {
    this.setAuthorPersonView(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasAuthorPersonView(): boolean {
    return jspb.Message.getField(this, 11) != null;
  }


  /**
   * optional string owner_group_hash = 30;
   * @return {string}
   */
  getOwnerGroupHash(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 30, ""));
  };


  /** @param {string} value */
  setOwnerGroupHash(value: string) {
    jspb.Message.setProto3StringField(this, 30, value);
  }


  /**
   * repeated string allowed_commenting_role_types = 31;
   * @return {!Array<string>}
   */
  getAllowedCommentingRoleTypesList(): string[] {
    return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 31));
  };


  /** @param {!Array<string>} value */
  setAllowedCommentingRoleTypesList(value: string[]) {
    jspb.Message.setField(this, 31, value || []);
  }


  /**
   * @param {string} value
   * @param {number=} opt_index
   */
  addAllowedCommentingRoleTypes(value: string, opt_index?: number): void {
    jspb.Message.addToRepeatedField(this, 31, value, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearAllowedCommentingRoleTypesList() {
    this.setAllowedCommentingRoleTypesList([]);
  }


  /**
   * optional bool pinned = 100;
   * @return {boolean}
   */
  getPinned(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 100, false));
  };


  /** @param {boolean} value */
  setPinned(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 100, value);
  }


  /**
   * optional uint64 unpublish_timestamp = 101;
   * @return {number}
   */
  getUnpublishTimestamp(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 101, 0));
  };


  /** @param {number} value */
  setUnpublishTimestamp(value: number) {
    jspb.Message.setProto3IntField(this, 101, value);
  }


  /**
   * optional bool has_liked = 102;
   * @return {boolean}
   */
  getHasLiked(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 102, false));
  };


  /** @param {boolean} value */
  setHasLiked(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 102, value);
  }


}
export namespace ShortBirthdayCardView {
  export interface AsObject {
    contentHash: string;
    timestamp: number;
    featuredImage?: image_image_pb.ImageInfo.AsObject;
    lightContent: boolean;
    gradientOverlap: boolean;
    backgroundColor: string;
    gradientSize: number;
    likeCount: number;
    commentCount: number;
    totalCommentCount: number;
    commentList: FeedComment.AsObject[];
    birthdate?: common_date_pb.Date.AsObject;
    birthdayPersonList: gateway_person_view_pb.PersonViewMinimal.AsObject[];
    authorPersonView?: gateway_person_view_pb.PersonViewMinimal.AsObject;
    allowedCommentingRoleTypesList: string[];
    pinned: boolean;
    unpublishTimestamp: number;
    hasLiked: boolean;
  }
}
export class ShortBirthdayCardView extends jspb.Message {
  static readonly displayName = "ShortBirthdayCardView";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [10,12,15];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, ShortBirthdayCardView.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): ShortBirthdayCardView.AsObject {
    return ShortBirthdayCardView.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!ShortBirthdayCardView} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: ShortBirthdayCardView) {
    var f: any;
    var obj: any = {
      contentHash: jspb.Message.getFieldWithDefault(msg, 1, ""),
      timestamp: jspb.Message.getFieldWithDefault(msg, 2, 0),
      featuredImage: (f = msg.getFeaturedImage()) && image_image_pb.ImageInfo.toObject(includeInstance, f),
      lightContent: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
      gradientOverlap: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
      backgroundColor: jspb.Message.getFieldWithDefault(msg, 6, ""),
      gradientSize: jspb.Message.getFloatingPointFieldWithDefault(msg, 14, 0.0),
      likeCount: jspb.Message.getFieldWithDefault(msg, 7, 0),
      commentCount: jspb.Message.getFieldWithDefault(msg, 8, 0),
      totalCommentCount: jspb.Message.getFieldWithDefault(msg, 9, 0),
      commentList: jspb.Message.toObjectList(msg.getCommentList(),
      FeedComment.toObject, includeInstance),
      birthdate: (f = msg.getBirthdate()) && common_date_pb.Date.toObject(includeInstance, f),
      birthdayPersonList: jspb.Message.toObjectList(msg.getBirthdayPersonList(),
      gateway_person_view_pb.PersonViewMinimal.toObject, includeInstance),
      authorPersonView: (f = msg.getAuthorPersonView()) && gateway_person_view_pb.PersonViewMinimal.toObject(includeInstance, f),
      allowedCommentingRoleTypesList: (f = jspb.Message.getRepeatedField(msg, 15)) == null ? undefined : f,
      pinned: jspb.Message.getBooleanFieldWithDefault(msg, 100, false),
      unpublishTimestamp: jspb.Message.getFieldWithDefault(msg, 101, 0),
      hasLiked: jspb.Message.getBooleanFieldWithDefault(msg, 102, false)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!ShortBirthdayCardView}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new ShortBirthdayCardView;
    return ShortBirthdayCardView.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!ShortBirthdayCardView} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!ShortBirthdayCardView}
   */
  static deserializeBinaryFromReader(msg: ShortBirthdayCardView, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setContentHash(value1);
        break;
      case 2:
        var value2 = /** @type {number} */ (reader.readInt64());
        msg.setTimestamp(value2);
        break;
      case 3:
        var value3 = new image_image_pb.ImageInfo;
        reader.readMessage(value3,image_image_pb.ImageInfo.deserializeBinaryFromReader);
        msg.setFeaturedImage(value3);
        break;
      case 4:
        var value4 = /** @type {boolean} */ (reader.readBool());
        msg.setLightContent(value4);
        break;
      case 5:
        var value5 = /** @type {boolean} */ (reader.readBool());
        msg.setGradientOverlap(value5);
        break;
      case 6:
        var value6 = /** @type {string} */ (reader.readString());
        msg.setBackgroundColor(value6);
        break;
      case 14:
        var value14 = /** @type {number} */ (reader.readFloat());
        msg.setGradientSize(value14);
        break;
      case 7:
        var value7 = /** @type {number} */ (reader.readInt32());
        msg.setLikeCount(value7);
        break;
      case 8:
        var value8 = /** @type {number} */ (reader.readInt32());
        msg.setCommentCount(value8);
        break;
      case 9:
        var value9 = /** @type {number} */ (reader.readInt32());
        msg.setTotalCommentCount(value9);
        break;
      case 10:
        var value10 = new FeedComment;
        reader.readMessage(value10,FeedComment.deserializeBinaryFromReader);
        msg.addComment(value10);
        break;
      case 11:
        var value11 = new common_date_pb.Date;
        reader.readMessage(value11,common_date_pb.Date.deserializeBinaryFromReader);
        msg.setBirthdate(value11);
        break;
      case 12:
        var value12 = new gateway_person_view_pb.PersonViewMinimal;
        reader.readMessage(value12,gateway_person_view_pb.PersonViewMinimal.deserializeBinaryFromReader);
        msg.addBirthdayPerson(value12);
        break;
      case 13:
        var value13 = new gateway_person_view_pb.PersonViewMinimal;
        reader.readMessage(value13,gateway_person_view_pb.PersonViewMinimal.deserializeBinaryFromReader);
        msg.setAuthorPersonView(value13);
        break;
      case 15:
        var value15 = /** @type {string} */ (reader.readString());
        msg.addAllowedCommentingRoleTypes(value15);
        break;
      case 100:
        var value100 = /** @type {boolean} */ (reader.readBool());
        msg.setPinned(value100);
        break;
      case 101:
        var value101 = /** @type {number} */ (reader.readUint64());
        msg.setUnpublishTimestamp(value101);
        break;
      case 102:
        var value102 = /** @type {boolean} */ (reader.readBool());
        msg.setHasLiked(value102);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!ShortBirthdayCardView} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: ShortBirthdayCardView, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getContentHash();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
    f = message.getTimestamp();
    if (f !== 0) {
      writer.writeInt64(
        2,
        f
      );
    }
    f = message.getFeaturedImage();
    if (f != null) {
      writer.writeMessage(
        3,
        f,
        image_image_pb.ImageInfo.serializeBinaryToWriter
      );
    }
    f = message.getLightContent();
    if (f) {
      writer.writeBool(
        4,
        f
      );
    }
    f = message.getGradientOverlap();
    if (f) {
      writer.writeBool(
        5,
        f
      );
    }
    f = message.getBackgroundColor();
    if (f.length > 0) {
      writer.writeString(
        6,
        f
      );
    }
    f = message.getGradientSize();
    if (f !== 0.0) {
      writer.writeFloat(
        14,
        f
      );
    }
    f = message.getLikeCount();
    if (f !== 0) {
      writer.writeInt32(
        7,
        f
      );
    }
    f = message.getCommentCount();
    if (f !== 0) {
      writer.writeInt32(
        8,
        f
      );
    }
    f = message.getTotalCommentCount();
    if (f !== 0) {
      writer.writeInt32(
        9,
        f
      );
    }
    f = message.getCommentList();
    if (f.length > 0) {
      writer.writeRepeatedMessage(
        10,
        f,
        FeedComment.serializeBinaryToWriter
      );
    }
    f = message.getBirthdate();
    if (f != null) {
      writer.writeMessage(
        11,
        f,
        common_date_pb.Date.serializeBinaryToWriter
      );
    }
    f = message.getBirthdayPersonList();
    if (f.length > 0) {
      writer.writeRepeatedMessage(
        12,
        f,
        gateway_person_view_pb.PersonViewMinimal.serializeBinaryToWriter
      );
    }
    f = message.getAuthorPersonView();
    if (f != null) {
      writer.writeMessage(
        13,
        f,
        gateway_person_view_pb.PersonViewMinimal.serializeBinaryToWriter
      );
    }
    f = message.getAllowedCommentingRoleTypesList();
    if (f.length > 0) {
      writer.writeRepeatedString(
        15,
        f
      );
    }
    f = message.getPinned();
    if (f) {
      writer.writeBool(
        100,
        f
      );
    }
    f = message.getUnpublishTimestamp();
    if (f !== 0) {
      writer.writeUint64(
        101,
        f
      );
    }
    f = message.getHasLiked();
    if (f) {
      writer.writeBool(
        102,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    ShortBirthdayCardView.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string content_hash = 1;
   * @return {string}
   */
  getContentHash(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setContentHash(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


  /**
   * optional int64 timestamp = 2;
   * @return {number}
   */
  getTimestamp(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
  };


  /** @param {number} value */
  setTimestamp(value: number) {
    jspb.Message.setProto3IntField(this, 2, value);
  }


  /**
   * optional ImageInfo featured_image = 3;
   * @return {?ImageInfo}
   */
  getFeaturedImage(): image_image_pb.ImageInfo {
    return /** @type{?ImageInfo} */ (
      jspb.Message.getWrapperField(this, image_image_pb.ImageInfo, 3));
  }


  /** @param {?ImageInfo|undefined} value */
  setFeaturedImage(value?: image_image_pb.ImageInfo) {
    jspb.Message.setWrapperField(this, 3, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearFeaturedImage() {
    this.setFeaturedImage(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasFeaturedImage(): boolean {
    return jspb.Message.getField(this, 3) != null;
  }


  /**
   * optional bool light_content = 4;
   * @return {boolean}
   */
  getLightContent(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
  };


  /** @param {boolean} value */
  setLightContent(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 4, value);
  }


  /**
   * optional bool gradient_overlap = 5;
   * @return {boolean}
   */
  getGradientOverlap(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
  };


  /** @param {boolean} value */
  setGradientOverlap(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 5, value);
  }


  /**
   * optional string background_color = 6;
   * @return {string}
   */
  getBackgroundColor(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
  };


  /** @param {string} value */
  setBackgroundColor(value: string) {
    jspb.Message.setProto3StringField(this, 6, value);
  }


  /**
   * optional float gradient_size = 14;
   * @return {number}
   */
  getGradientSize(): number {
    return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 14, 0.0));
  };


  /** @param {number} value */
  setGradientSize(value: number) {
    jspb.Message.setProto3FloatField(this, 14, value);
  }


  /**
   * optional int32 like_count = 7;
   * @return {number}
   */
  getLikeCount(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
  };


  /** @param {number} value */
  setLikeCount(value: number) {
    jspb.Message.setProto3IntField(this, 7, value);
  }


  /**
   * optional int32 comment_count = 8;
   * @return {number}
   */
  getCommentCount(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
  };


  /** @param {number} value */
  setCommentCount(value: number) {
    jspb.Message.setProto3IntField(this, 8, value);
  }


  /**
   * optional int32 total_comment_count = 9;
   * @return {number}
   */
  getTotalCommentCount(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
  };


  /** @param {number} value */
  setTotalCommentCount(value: number) {
    jspb.Message.setProto3IntField(this, 9, value);
  }


  /**
   * repeated FeedComment comment = 10;
   * @return {!Array<!FeedComment>}
   */
  getCommentList(): FeedComment[] {
    return /** @type{!Array<!FeedComment>} */ (
      jspb.Message.getRepeatedWrapperField(this, FeedComment, 10));
  }


  /** @param {!Array<!FeedComment>} value */
  setCommentList(value?: FeedComment[]) {
    jspb.Message.setRepeatedWrapperField(this, 10, value);
  }


  /**
   * @param {!FeedComment=} opt_value
   * @param {number=} opt_index
   * @return {!FeedComment}
   */
  addComment(opt_value?: FeedComment, opt_index?: number): FeedComment {
    return jspb.Message.addToRepeatedWrapperField(this, 10, opt_value, FeedComment, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearCommentList() {
    this.setCommentList([]);
  }


  /**
   * optional minga.common.Date birthdate = 11;
   * @return {?Date}
   */
  getBirthdate(): common_date_pb.Date {
    return /** @type{?Date} */ (
      jspb.Message.getWrapperField(this, common_date_pb.Date, 11));
  }


  /** @param {?Date|undefined} value */
  setBirthdate(value?: common_date_pb.Date) {
    jspb.Message.setWrapperField(this, 11, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearBirthdate() {
    this.setBirthdate(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasBirthdate(): boolean {
    return jspb.Message.getField(this, 11) != null;
  }


  /**
   * repeated PersonViewMinimal birthday_person = 12;
   * @return {!Array<!PersonViewMinimal>}
   */
  getBirthdayPersonList(): gateway_person_view_pb.PersonViewMinimal[] {
    return /** @type{!Array<!PersonViewMinimal>} */ (
      jspb.Message.getRepeatedWrapperField(this, gateway_person_view_pb.PersonViewMinimal, 12));
  }


  /** @param {!Array<!PersonViewMinimal>} value */
  setBirthdayPersonList(value?: gateway_person_view_pb.PersonViewMinimal[]) {
    jspb.Message.setRepeatedWrapperField(this, 12, value);
  }


  /**
   * @param {!PersonViewMinimal=} opt_value
   * @param {number=} opt_index
   * @return {!PersonViewMinimal}
   */
  addBirthdayPerson(opt_value?: gateway_person_view_pb.PersonViewMinimal, opt_index?: number): gateway_person_view_pb.PersonViewMinimal {
    return jspb.Message.addToRepeatedWrapperField(this, 12, opt_value, gateway_person_view_pb.PersonViewMinimal, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearBirthdayPersonList() {
    this.setBirthdayPersonList([]);
  }


  /**
   * optional PersonViewMinimal author_person_view = 13;
   * @return {?PersonViewMinimal}
   */
  getAuthorPersonView(): gateway_person_view_pb.PersonViewMinimal {
    return /** @type{?PersonViewMinimal} */ (
      jspb.Message.getWrapperField(this, gateway_person_view_pb.PersonViewMinimal, 13));
  }


  /** @param {?PersonViewMinimal|undefined} value */
  setAuthorPersonView(value?: gateway_person_view_pb.PersonViewMinimal) {
    jspb.Message.setWrapperField(this, 13, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearAuthorPersonView() {
    this.setAuthorPersonView(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasAuthorPersonView(): boolean {
    return jspb.Message.getField(this, 13) != null;
  }


  /**
   * repeated string allowed_commenting_role_types = 15;
   * @return {!Array<string>}
   */
  getAllowedCommentingRoleTypesList(): string[] {
    return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 15));
  };


  /** @param {!Array<string>} value */
  setAllowedCommentingRoleTypesList(value: string[]) {
    jspb.Message.setField(this, 15, value || []);
  }


  /**
   * @param {string} value
   * @param {number=} opt_index
   */
  addAllowedCommentingRoleTypes(value: string, opt_index?: number): void {
    jspb.Message.addToRepeatedField(this, 15, value, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearAllowedCommentingRoleTypesList() {
    this.setAllowedCommentingRoleTypesList([]);
  }


  /**
   * optional bool pinned = 100;
   * @return {boolean}
   */
  getPinned(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 100, false));
  };


  /** @param {boolean} value */
  setPinned(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 100, value);
  }


  /**
   * optional uint64 unpublish_timestamp = 101;
   * @return {number}
   */
  getUnpublishTimestamp(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 101, 0));
  };


  /** @param {number} value */
  setUnpublishTimestamp(value: number) {
    jspb.Message.setProto3IntField(this, 101, value);
  }


  /**
   * optional bool has_liked = 102;
   * @return {boolean}
   */
  getHasLiked(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 102, false));
  };


  /** @param {boolean} value */
  setHasLiked(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 102, value);
  }


}
export namespace LongInspirationCardView {
  export interface AsObject {
    title: string;
    timestamp: number;
    image?: image_image_pb.ImageInfo.AsObject;
    likeCount: number;
    commentCount: number;
    totalCommentCount: number;
    commentList: gateway_comment_pb.CommentContent.AsObject[];
    commentListPage: number;
    authorPersonView?: gateway_person_view_pb.PersonViewMinimal.AsObject;
    lightContent: boolean;
    gradientOverlap: boolean;
    backgroundColor: string;
    gradientSize: number;
    allowedCommentingRoleTypesList: string[];
    hasLiked: boolean;
  }
}
export class LongInspirationCardView extends jspb.Message {
  static readonly displayName = "LongInspirationCardView";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [7,14];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, LongInspirationCardView.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): LongInspirationCardView.AsObject {
    return LongInspirationCardView.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!LongInspirationCardView} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: LongInspirationCardView) {
    var f: any;
    var obj: any = {
      title: jspb.Message.getFieldWithDefault(msg, 1, ""),
      timestamp: jspb.Message.getFieldWithDefault(msg, 2, 0),
      image: (f = msg.getImage()) && image_image_pb.ImageInfo.toObject(includeInstance, f),
      likeCount: jspb.Message.getFieldWithDefault(msg, 4, 0),
      commentCount: jspb.Message.getFieldWithDefault(msg, 5, 0),
      totalCommentCount: jspb.Message.getFieldWithDefault(msg, 6, 0),
      commentList: jspb.Message.toObjectList(msg.getCommentList(),
      gateway_comment_pb.CommentContent.toObject, includeInstance),
      commentListPage: jspb.Message.getFieldWithDefault(msg, 8, 0),
      authorPersonView: (f = msg.getAuthorPersonView()) && gateway_person_view_pb.PersonViewMinimal.toObject(includeInstance, f),
      lightContent: jspb.Message.getBooleanFieldWithDefault(msg, 10, false),
      gradientOverlap: jspb.Message.getBooleanFieldWithDefault(msg, 11, false),
      backgroundColor: jspb.Message.getFieldWithDefault(msg, 12, ""),
      gradientSize: jspb.Message.getFloatingPointFieldWithDefault(msg, 13, 0.0),
      allowedCommentingRoleTypesList: (f = jspb.Message.getRepeatedField(msg, 14)) == null ? undefined : f,
      hasLiked: jspb.Message.getBooleanFieldWithDefault(msg, 102, false)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!LongInspirationCardView}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new LongInspirationCardView;
    return LongInspirationCardView.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!LongInspirationCardView} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!LongInspirationCardView}
   */
  static deserializeBinaryFromReader(msg: LongInspirationCardView, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setTitle(value1);
        break;
      case 2:
        var value2 = /** @type {number} */ (reader.readInt64());
        msg.setTimestamp(value2);
        break;
      case 3:
        var value3 = new image_image_pb.ImageInfo;
        reader.readMessage(value3,image_image_pb.ImageInfo.deserializeBinaryFromReader);
        msg.setImage(value3);
        break;
      case 4:
        var value4 = /** @type {number} */ (reader.readInt32());
        msg.setLikeCount(value4);
        break;
      case 5:
        var value5 = /** @type {number} */ (reader.readInt32());
        msg.setCommentCount(value5);
        break;
      case 6:
        var value6 = /** @type {number} */ (reader.readInt32());
        msg.setTotalCommentCount(value6);
        break;
      case 7:
        var value7 = new gateway_comment_pb.CommentContent;
        reader.readMessage(value7,gateway_comment_pb.CommentContent.deserializeBinaryFromReader);
        msg.addComment(value7);
        break;
      case 8:
        var value8 = /** @type {number} */ (reader.readInt32());
        msg.setCommentListPage(value8);
        break;
      case 9:
        var value9 = new gateway_person_view_pb.PersonViewMinimal;
        reader.readMessage(value9,gateway_person_view_pb.PersonViewMinimal.deserializeBinaryFromReader);
        msg.setAuthorPersonView(value9);
        break;
      case 10:
        var value10 = /** @type {boolean} */ (reader.readBool());
        msg.setLightContent(value10);
        break;
      case 11:
        var value11 = /** @type {boolean} */ (reader.readBool());
        msg.setGradientOverlap(value11);
        break;
      case 12:
        var value12 = /** @type {string} */ (reader.readString());
        msg.setBackgroundColor(value12);
        break;
      case 13:
        var value13 = /** @type {number} */ (reader.readFloat());
        msg.setGradientSize(value13);
        break;
      case 14:
        var value14 = /** @type {string} */ (reader.readString());
        msg.addAllowedCommentingRoleTypes(value14);
        break;
      case 102:
        var value102 = /** @type {boolean} */ (reader.readBool());
        msg.setHasLiked(value102);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!LongInspirationCardView} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: LongInspirationCardView, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getTitle();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
    f = message.getTimestamp();
    if (f !== 0) {
      writer.writeInt64(
        2,
        f
      );
    }
    f = message.getImage();
    if (f != null) {
      writer.writeMessage(
        3,
        f,
        image_image_pb.ImageInfo.serializeBinaryToWriter
      );
    }
    f = message.getLikeCount();
    if (f !== 0) {
      writer.writeInt32(
        4,
        f
      );
    }
    f = message.getCommentCount();
    if (f !== 0) {
      writer.writeInt32(
        5,
        f
      );
    }
    f = message.getTotalCommentCount();
    if (f !== 0) {
      writer.writeInt32(
        6,
        f
      );
    }
    f = message.getCommentList();
    if (f.length > 0) {
      writer.writeRepeatedMessage(
        7,
        f,
        gateway_comment_pb.CommentContent.serializeBinaryToWriter
      );
    }
    f = message.getCommentListPage();
    if (f !== 0) {
      writer.writeInt32(
        8,
        f
      );
    }
    f = message.getAuthorPersonView();
    if (f != null) {
      writer.writeMessage(
        9,
        f,
        gateway_person_view_pb.PersonViewMinimal.serializeBinaryToWriter
      );
    }
    f = message.getLightContent();
    if (f) {
      writer.writeBool(
        10,
        f
      );
    }
    f = message.getGradientOverlap();
    if (f) {
      writer.writeBool(
        11,
        f
      );
    }
    f = message.getBackgroundColor();
    if (f.length > 0) {
      writer.writeString(
        12,
        f
      );
    }
    f = message.getGradientSize();
    if (f !== 0.0) {
      writer.writeFloat(
        13,
        f
      );
    }
    f = message.getAllowedCommentingRoleTypesList();
    if (f.length > 0) {
      writer.writeRepeatedString(
        14,
        f
      );
    }
    f = message.getHasLiked();
    if (f) {
      writer.writeBool(
        102,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    LongInspirationCardView.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string title = 1;
   * @return {string}
   */
  getTitle(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setTitle(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


  /**
   * optional int64 timestamp = 2;
   * @return {number}
   */
  getTimestamp(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
  };


  /** @param {number} value */
  setTimestamp(value: number) {
    jspb.Message.setProto3IntField(this, 2, value);
  }


  /**
   * optional ImageInfo image = 3;
   * @return {?ImageInfo}
   */
  getImage(): image_image_pb.ImageInfo {
    return /** @type{?ImageInfo} */ (
      jspb.Message.getWrapperField(this, image_image_pb.ImageInfo, 3));
  }


  /** @param {?ImageInfo|undefined} value */
  setImage(value?: image_image_pb.ImageInfo) {
    jspb.Message.setWrapperField(this, 3, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearImage() {
    this.setImage(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasImage(): boolean {
    return jspb.Message.getField(this, 3) != null;
  }


  /**
   * optional int32 like_count = 4;
   * @return {number}
   */
  getLikeCount(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
  };


  /** @param {number} value */
  setLikeCount(value: number) {
    jspb.Message.setProto3IntField(this, 4, value);
  }


  /**
   * optional int32 comment_count = 5;
   * @return {number}
   */
  getCommentCount(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
  };


  /** @param {number} value */
  setCommentCount(value: number) {
    jspb.Message.setProto3IntField(this, 5, value);
  }


  /**
   * optional int32 total_comment_count = 6;
   * @return {number}
   */
  getTotalCommentCount(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
  };


  /** @param {number} value */
  setTotalCommentCount(value: number) {
    jspb.Message.setProto3IntField(this, 6, value);
  }


  /**
   * repeated CommentContent comment = 7;
   * @return {!Array<!CommentContent>}
   */
  getCommentList(): gateway_comment_pb.CommentContent[] {
    return /** @type{!Array<!CommentContent>} */ (
      jspb.Message.getRepeatedWrapperField(this, gateway_comment_pb.CommentContent, 7));
  }


  /** @param {!Array<!CommentContent>} value */
  setCommentList(value?: gateway_comment_pb.CommentContent[]) {
    jspb.Message.setRepeatedWrapperField(this, 7, value);
  }


  /**
   * @param {!CommentContent=} opt_value
   * @param {number=} opt_index
   * @return {!CommentContent}
   */
  addComment(opt_value?: gateway_comment_pb.CommentContent, opt_index?: number): gateway_comment_pb.CommentContent {
    return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, gateway_comment_pb.CommentContent, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearCommentList() {
    this.setCommentList([]);
  }


  /**
   * optional int32 comment_list_page = 8;
   * @return {number}
   */
  getCommentListPage(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
  };


  /** @param {number} value */
  setCommentListPage(value: number) {
    jspb.Message.setProto3IntField(this, 8, value);
  }


  /**
   * optional PersonViewMinimal author_person_view = 9;
   * @return {?PersonViewMinimal}
   */
  getAuthorPersonView(): gateway_person_view_pb.PersonViewMinimal {
    return /** @type{?PersonViewMinimal} */ (
      jspb.Message.getWrapperField(this, gateway_person_view_pb.PersonViewMinimal, 9));
  }


  /** @param {?PersonViewMinimal|undefined} value */
  setAuthorPersonView(value?: gateway_person_view_pb.PersonViewMinimal) {
    jspb.Message.setWrapperField(this, 9, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearAuthorPersonView() {
    this.setAuthorPersonView(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasAuthorPersonView(): boolean {
    return jspb.Message.getField(this, 9) != null;
  }


  /**
   * optional bool light_content = 10;
   * @return {boolean}
   */
  getLightContent(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
  };


  /** @param {boolean} value */
  setLightContent(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 10, value);
  }


  /**
   * optional bool gradient_overlap = 11;
   * @return {boolean}
   */
  getGradientOverlap(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
  };


  /** @param {boolean} value */
  setGradientOverlap(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 11, value);
  }


  /**
   * optional string background_color = 12;
   * @return {string}
   */
  getBackgroundColor(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
  };


  /** @param {string} value */
  setBackgroundColor(value: string) {
    jspb.Message.setProto3StringField(this, 12, value);
  }


  /**
   * optional float gradient_size = 13;
   * @return {number}
   */
  getGradientSize(): number {
    return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 13, 0.0));
  };


  /** @param {number} value */
  setGradientSize(value: number) {
    jspb.Message.setProto3FloatField(this, 13, value);
  }


  /**
   * repeated string allowed_commenting_role_types = 14;
   * @return {!Array<string>}
   */
  getAllowedCommentingRoleTypesList(): string[] {
    return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 14));
  };


  /** @param {!Array<string>} value */
  setAllowedCommentingRoleTypesList(value: string[]) {
    jspb.Message.setField(this, 14, value || []);
  }


  /**
   * @param {string} value
   * @param {number=} opt_index
   */
  addAllowedCommentingRoleTypes(value: string, opt_index?: number): void {
    jspb.Message.addToRepeatedField(this, 14, value, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearAllowedCommentingRoleTypesList() {
    this.setAllowedCommentingRoleTypesList([]);
  }


  /**
   * optional bool has_liked = 102;
   * @return {boolean}
   */
  getHasLiked(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 102, false));
  };


  /** @param {boolean} value */
  setHasLiked(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 102, value);
  }


}
export namespace LongBirthdayCardView {
  export interface AsObject {
    title: string;
    timestamp: number;
    image?: image_image_pb.ImageInfo.AsObject;
    likeCount: number;
    commentCount: number;
    totalCommentCount: number;
    commentList: gateway_comment_pb.CommentContent.AsObject[];
    commentListPage: number;
    lightContent: boolean;
    gradientOverlap: boolean;
    backgroundColor: string;
    gradientSize: number;
    birthdate?: common_date_pb.Date.AsObject;
    birthdayPersonList: gateway_person_view_pb.PersonViewMinimal.AsObject[];
    authorPersonView?: gateway_person_view_pb.PersonViewMinimal.AsObject;
    allowedCommentingRoleTypesList: string[];
    hasLiked: boolean;
  }
}
export class LongBirthdayCardView extends jspb.Message {
  static readonly displayName = "LongBirthdayCardView";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [7,13,16];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, LongBirthdayCardView.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): LongBirthdayCardView.AsObject {
    return LongBirthdayCardView.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!LongBirthdayCardView} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: LongBirthdayCardView) {
    var f: any;
    var obj: any = {
      title: jspb.Message.getFieldWithDefault(msg, 1, ""),
      timestamp: jspb.Message.getFieldWithDefault(msg, 2, 0),
      image: (f = msg.getImage()) && image_image_pb.ImageInfo.toObject(includeInstance, f),
      likeCount: jspb.Message.getFieldWithDefault(msg, 4, 0),
      commentCount: jspb.Message.getFieldWithDefault(msg, 5, 0),
      totalCommentCount: jspb.Message.getFieldWithDefault(msg, 6, 0),
      commentList: jspb.Message.toObjectList(msg.getCommentList(),
      gateway_comment_pb.CommentContent.toObject, includeInstance),
      commentListPage: jspb.Message.getFieldWithDefault(msg, 8, 0),
      lightContent: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
      gradientOverlap: jspb.Message.getBooleanFieldWithDefault(msg, 10, false),
      backgroundColor: jspb.Message.getFieldWithDefault(msg, 11, ""),
      gradientSize: jspb.Message.getFloatingPointFieldWithDefault(msg, 15, 0.0),
      birthdate: (f = msg.getBirthdate()) && common_date_pb.Date.toObject(includeInstance, f),
      birthdayPersonList: jspb.Message.toObjectList(msg.getBirthdayPersonList(),
      gateway_person_view_pb.PersonViewMinimal.toObject, includeInstance),
      authorPersonView: (f = msg.getAuthorPersonView()) && gateway_person_view_pb.PersonViewMinimal.toObject(includeInstance, f),
      allowedCommentingRoleTypesList: (f = jspb.Message.getRepeatedField(msg, 16)) == null ? undefined : f,
      hasLiked: jspb.Message.getBooleanFieldWithDefault(msg, 102, false)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!LongBirthdayCardView}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new LongBirthdayCardView;
    return LongBirthdayCardView.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!LongBirthdayCardView} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!LongBirthdayCardView}
   */
  static deserializeBinaryFromReader(msg: LongBirthdayCardView, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setTitle(value1);
        break;
      case 2:
        var value2 = /** @type {number} */ (reader.readInt64());
        msg.setTimestamp(value2);
        break;
      case 3:
        var value3 = new image_image_pb.ImageInfo;
        reader.readMessage(value3,image_image_pb.ImageInfo.deserializeBinaryFromReader);
        msg.setImage(value3);
        break;
      case 4:
        var value4 = /** @type {number} */ (reader.readInt32());
        msg.setLikeCount(value4);
        break;
      case 5:
        var value5 = /** @type {number} */ (reader.readInt32());
        msg.setCommentCount(value5);
        break;
      case 6:
        var value6 = /** @type {number} */ (reader.readInt32());
        msg.setTotalCommentCount(value6);
        break;
      case 7:
        var value7 = new gateway_comment_pb.CommentContent;
        reader.readMessage(value7,gateway_comment_pb.CommentContent.deserializeBinaryFromReader);
        msg.addComment(value7);
        break;
      case 8:
        var value8 = /** @type {number} */ (reader.readInt32());
        msg.setCommentListPage(value8);
        break;
      case 9:
        var value9 = /** @type {boolean} */ (reader.readBool());
        msg.setLightContent(value9);
        break;
      case 10:
        var value10 = /** @type {boolean} */ (reader.readBool());
        msg.setGradientOverlap(value10);
        break;
      case 11:
        var value11 = /** @type {string} */ (reader.readString());
        msg.setBackgroundColor(value11);
        break;
      case 15:
        var value15 = /** @type {number} */ (reader.readFloat());
        msg.setGradientSize(value15);
        break;
      case 12:
        var value12 = new common_date_pb.Date;
        reader.readMessage(value12,common_date_pb.Date.deserializeBinaryFromReader);
        msg.setBirthdate(value12);
        break;
      case 13:
        var value13 = new gateway_person_view_pb.PersonViewMinimal;
        reader.readMessage(value13,gateway_person_view_pb.PersonViewMinimal.deserializeBinaryFromReader);
        msg.addBirthdayPerson(value13);
        break;
      case 14:
        var value14 = new gateway_person_view_pb.PersonViewMinimal;
        reader.readMessage(value14,gateway_person_view_pb.PersonViewMinimal.deserializeBinaryFromReader);
        msg.setAuthorPersonView(value14);
        break;
      case 16:
        var value16 = /** @type {string} */ (reader.readString());
        msg.addAllowedCommentingRoleTypes(value16);
        break;
      case 102:
        var value102 = /** @type {boolean} */ (reader.readBool());
        msg.setHasLiked(value102);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!LongBirthdayCardView} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: LongBirthdayCardView, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getTitle();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
    f = message.getTimestamp();
    if (f !== 0) {
      writer.writeInt64(
        2,
        f
      );
    }
    f = message.getImage();
    if (f != null) {
      writer.writeMessage(
        3,
        f,
        image_image_pb.ImageInfo.serializeBinaryToWriter
      );
    }
    f = message.getLikeCount();
    if (f !== 0) {
      writer.writeInt32(
        4,
        f
      );
    }
    f = message.getCommentCount();
    if (f !== 0) {
      writer.writeInt32(
        5,
        f
      );
    }
    f = message.getTotalCommentCount();
    if (f !== 0) {
      writer.writeInt32(
        6,
        f
      );
    }
    f = message.getCommentList();
    if (f.length > 0) {
      writer.writeRepeatedMessage(
        7,
        f,
        gateway_comment_pb.CommentContent.serializeBinaryToWriter
      );
    }
    f = message.getCommentListPage();
    if (f !== 0) {
      writer.writeInt32(
        8,
        f
      );
    }
    f = message.getLightContent();
    if (f) {
      writer.writeBool(
        9,
        f
      );
    }
    f = message.getGradientOverlap();
    if (f) {
      writer.writeBool(
        10,
        f
      );
    }
    f = message.getBackgroundColor();
    if (f.length > 0) {
      writer.writeString(
        11,
        f
      );
    }
    f = message.getGradientSize();
    if (f !== 0.0) {
      writer.writeFloat(
        15,
        f
      );
    }
    f = message.getBirthdate();
    if (f != null) {
      writer.writeMessage(
        12,
        f,
        common_date_pb.Date.serializeBinaryToWriter
      );
    }
    f = message.getBirthdayPersonList();
    if (f.length > 0) {
      writer.writeRepeatedMessage(
        13,
        f,
        gateway_person_view_pb.PersonViewMinimal.serializeBinaryToWriter
      );
    }
    f = message.getAuthorPersonView();
    if (f != null) {
      writer.writeMessage(
        14,
        f,
        gateway_person_view_pb.PersonViewMinimal.serializeBinaryToWriter
      );
    }
    f = message.getAllowedCommentingRoleTypesList();
    if (f.length > 0) {
      writer.writeRepeatedString(
        16,
        f
      );
    }
    f = message.getHasLiked();
    if (f) {
      writer.writeBool(
        102,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    LongBirthdayCardView.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string title = 1;
   * @return {string}
   */
  getTitle(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setTitle(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


  /**
   * optional int64 timestamp = 2;
   * @return {number}
   */
  getTimestamp(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
  };


  /** @param {number} value */
  setTimestamp(value: number) {
    jspb.Message.setProto3IntField(this, 2, value);
  }


  /**
   * optional ImageInfo image = 3;
   * @return {?ImageInfo}
   */
  getImage(): image_image_pb.ImageInfo {
    return /** @type{?ImageInfo} */ (
      jspb.Message.getWrapperField(this, image_image_pb.ImageInfo, 3));
  }


  /** @param {?ImageInfo|undefined} value */
  setImage(value?: image_image_pb.ImageInfo) {
    jspb.Message.setWrapperField(this, 3, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearImage() {
    this.setImage(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasImage(): boolean {
    return jspb.Message.getField(this, 3) != null;
  }


  /**
   * optional int32 like_count = 4;
   * @return {number}
   */
  getLikeCount(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
  };


  /** @param {number} value */
  setLikeCount(value: number) {
    jspb.Message.setProto3IntField(this, 4, value);
  }


  /**
   * optional int32 comment_count = 5;
   * @return {number}
   */
  getCommentCount(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
  };


  /** @param {number} value */
  setCommentCount(value: number) {
    jspb.Message.setProto3IntField(this, 5, value);
  }


  /**
   * optional int32 total_comment_count = 6;
   * @return {number}
   */
  getTotalCommentCount(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
  };


  /** @param {number} value */
  setTotalCommentCount(value: number) {
    jspb.Message.setProto3IntField(this, 6, value);
  }


  /**
   * repeated CommentContent comment = 7;
   * @return {!Array<!CommentContent>}
   */
  getCommentList(): gateway_comment_pb.CommentContent[] {
    return /** @type{!Array<!CommentContent>} */ (
      jspb.Message.getRepeatedWrapperField(this, gateway_comment_pb.CommentContent, 7));
  }


  /** @param {!Array<!CommentContent>} value */
  setCommentList(value?: gateway_comment_pb.CommentContent[]) {
    jspb.Message.setRepeatedWrapperField(this, 7, value);
  }


  /**
   * @param {!CommentContent=} opt_value
   * @param {number=} opt_index
   * @return {!CommentContent}
   */
  addComment(opt_value?: gateway_comment_pb.CommentContent, opt_index?: number): gateway_comment_pb.CommentContent {
    return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, gateway_comment_pb.CommentContent, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearCommentList() {
    this.setCommentList([]);
  }


  /**
   * optional int32 comment_list_page = 8;
   * @return {number}
   */
  getCommentListPage(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
  };


  /** @param {number} value */
  setCommentListPage(value: number) {
    jspb.Message.setProto3IntField(this, 8, value);
  }


  /**
   * optional bool light_content = 9;
   * @return {boolean}
   */
  getLightContent(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
  };


  /** @param {boolean} value */
  setLightContent(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 9, value);
  }


  /**
   * optional bool gradient_overlap = 10;
   * @return {boolean}
   */
  getGradientOverlap(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
  };


  /** @param {boolean} value */
  setGradientOverlap(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 10, value);
  }


  /**
   * optional string background_color = 11;
   * @return {string}
   */
  getBackgroundColor(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
  };


  /** @param {string} value */
  setBackgroundColor(value: string) {
    jspb.Message.setProto3StringField(this, 11, value);
  }


  /**
   * optional float gradient_size = 15;
   * @return {number}
   */
  getGradientSize(): number {
    return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 15, 0.0));
  };


  /** @param {number} value */
  setGradientSize(value: number) {
    jspb.Message.setProto3FloatField(this, 15, value);
  }


  /**
   * optional minga.common.Date birthdate = 12;
   * @return {?Date}
   */
  getBirthdate(): common_date_pb.Date {
    return /** @type{?Date} */ (
      jspb.Message.getWrapperField(this, common_date_pb.Date, 12));
  }


  /** @param {?Date|undefined} value */
  setBirthdate(value?: common_date_pb.Date) {
    jspb.Message.setWrapperField(this, 12, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearBirthdate() {
    this.setBirthdate(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasBirthdate(): boolean {
    return jspb.Message.getField(this, 12) != null;
  }


  /**
   * repeated PersonViewMinimal birthday_person = 13;
   * @return {!Array<!PersonViewMinimal>}
   */
  getBirthdayPersonList(): gateway_person_view_pb.PersonViewMinimal[] {
    return /** @type{!Array<!PersonViewMinimal>} */ (
      jspb.Message.getRepeatedWrapperField(this, gateway_person_view_pb.PersonViewMinimal, 13));
  }


  /** @param {!Array<!PersonViewMinimal>} value */
  setBirthdayPersonList(value?: gateway_person_view_pb.PersonViewMinimal[]) {
    jspb.Message.setRepeatedWrapperField(this, 13, value);
  }


  /**
   * @param {!PersonViewMinimal=} opt_value
   * @param {number=} opt_index
   * @return {!PersonViewMinimal}
   */
  addBirthdayPerson(opt_value?: gateway_person_view_pb.PersonViewMinimal, opt_index?: number): gateway_person_view_pb.PersonViewMinimal {
    return jspb.Message.addToRepeatedWrapperField(this, 13, opt_value, gateway_person_view_pb.PersonViewMinimal, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearBirthdayPersonList() {
    this.setBirthdayPersonList([]);
  }


  /**
   * optional PersonViewMinimal author_person_view = 14;
   * @return {?PersonViewMinimal}
   */
  getAuthorPersonView(): gateway_person_view_pb.PersonViewMinimal {
    return /** @type{?PersonViewMinimal} */ (
      jspb.Message.getWrapperField(this, gateway_person_view_pb.PersonViewMinimal, 14));
  }


  /** @param {?PersonViewMinimal|undefined} value */
  setAuthorPersonView(value?: gateway_person_view_pb.PersonViewMinimal) {
    jspb.Message.setWrapperField(this, 14, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearAuthorPersonView() {
    this.setAuthorPersonView(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasAuthorPersonView(): boolean {
    return jspb.Message.getField(this, 14) != null;
  }


  /**
   * repeated string allowed_commenting_role_types = 16;
   * @return {!Array<string>}
   */
  getAllowedCommentingRoleTypesList(): string[] {
    return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 16));
  };


  /** @param {!Array<string>} value */
  setAllowedCommentingRoleTypesList(value: string[]) {
    jspb.Message.setField(this, 16, value || []);
  }


  /**
   * @param {string} value
   * @param {number=} opt_index
   */
  addAllowedCommentingRoleTypes(value: string, opt_index?: number): void {
    jspb.Message.addToRepeatedField(this, 16, value, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearAllowedCommentingRoleTypesList() {
    this.setAllowedCommentingRoleTypesList([]);
  }


  /**
   * optional bool has_liked = 102;
   * @return {boolean}
   */
  getHasLiked(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 102, false));
  };


  /** @param {boolean} value */
  setHasLiked(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 102, value);
  }


}
export namespace ChallengeResponseSummaryItem {
  export interface AsObject {
    body: string;
    contentContextHash: string;
    authorPersonView?: gateway_person_view_pb.PersonViewMinimal.AsObject;
    featuredImage?: image_image_pb.ImageInfo.AsObject;
  }
}
export class ChallengeResponseSummaryItem extends jspb.Message {
  static readonly displayName = "ChallengeResponseSummaryItem";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): ChallengeResponseSummaryItem.AsObject {
    return ChallengeResponseSummaryItem.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!ChallengeResponseSummaryItem} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: ChallengeResponseSummaryItem) {
    var f: any;
    var obj: any = {
      body: jspb.Message.getFieldWithDefault(msg, 1, ""),
      contentContextHash: jspb.Message.getFieldWithDefault(msg, 2, ""),
      authorPersonView: (f = msg.getAuthorPersonView()) && gateway_person_view_pb.PersonViewMinimal.toObject(includeInstance, f),
      featuredImage: (f = msg.getFeaturedImage()) && image_image_pb.ImageInfo.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!ChallengeResponseSummaryItem}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new ChallengeResponseSummaryItem;
    return ChallengeResponseSummaryItem.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!ChallengeResponseSummaryItem} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!ChallengeResponseSummaryItem}
   */
  static deserializeBinaryFromReader(msg: ChallengeResponseSummaryItem, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setBody(value1);
        break;
      case 2:
        var value2 = /** @type {string} */ (reader.readString());
        msg.setContentContextHash(value2);
        break;
      case 3:
        var value3 = new gateway_person_view_pb.PersonViewMinimal;
        reader.readMessage(value3,gateway_person_view_pb.PersonViewMinimal.deserializeBinaryFromReader);
        msg.setAuthorPersonView(value3);
        break;
      case 4:
        var value4 = new image_image_pb.ImageInfo;
        reader.readMessage(value4,image_image_pb.ImageInfo.deserializeBinaryFromReader);
        msg.setFeaturedImage(value4);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!ChallengeResponseSummaryItem} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: ChallengeResponseSummaryItem, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getBody();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
    f = message.getContentContextHash();
    if (f.length > 0) {
      writer.writeString(
        2,
        f
      );
    }
    f = message.getAuthorPersonView();
    if (f != null) {
      writer.writeMessage(
        3,
        f,
        gateway_person_view_pb.PersonViewMinimal.serializeBinaryToWriter
      );
    }
    f = message.getFeaturedImage();
    if (f != null) {
      writer.writeMessage(
        4,
        f,
        image_image_pb.ImageInfo.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    ChallengeResponseSummaryItem.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string body = 1;
   * @return {string}
   */
  getBody(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setBody(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


  /**
   * optional string content_context_hash = 2;
   * @return {string}
   */
  getContentContextHash(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
  };


  /** @param {string} value */
  setContentContextHash(value: string) {
    jspb.Message.setProto3StringField(this, 2, value);
  }


  /**
   * optional PersonViewMinimal author_person_view = 3;
   * @return {?PersonViewMinimal}
   */
  getAuthorPersonView(): gateway_person_view_pb.PersonViewMinimal {
    return /** @type{?PersonViewMinimal} */ (
      jspb.Message.getWrapperField(this, gateway_person_view_pb.PersonViewMinimal, 3));
  }


  /** @param {?PersonViewMinimal|undefined} value */
  setAuthorPersonView(value?: gateway_person_view_pb.PersonViewMinimal) {
    jspb.Message.setWrapperField(this, 3, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearAuthorPersonView() {
    this.setAuthorPersonView(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasAuthorPersonView(): boolean {
    return jspb.Message.getField(this, 3) != null;
  }


  /**
   * optional ImageInfo featured_image = 4;
   * @return {?ImageInfo}
   */
  getFeaturedImage(): image_image_pb.ImageInfo {
    return /** @type{?ImageInfo} */ (
      jspb.Message.getWrapperField(this, image_image_pb.ImageInfo, 4));
  }


  /** @param {?ImageInfo|undefined} value */
  setFeaturedImage(value?: image_image_pb.ImageInfo) {
    jspb.Message.setWrapperField(this, 4, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearFeaturedImage() {
    this.setFeaturedImage(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasFeaturedImage(): boolean {
    return jspb.Message.getField(this, 4) != null;
  }


}
export namespace ShortChallengeCardView {
  export interface AsObject {
    contentHash: string;
    featuredImage?: image_image_pb.ImageInfo.AsObject;
    dueDate?: common_date_pb.Date.AsObject;
    title: string;
    body: string;
    responsesSummaryList: ChallengeResponseSummaryItem.AsObject[];
    hasResponded: boolean;
    challengeType: string;
    allowedCommentingRoleTypesList: string[];
    ownerGroupHash: string;
    cloudflareVideoUrl: string;
    cloudflareUid: string;
    cloudflareReady: boolean;
    privacy: string;
    commentsAllowed: boolean;
    likeCount: number;
    commentCount: number;
    totalCommentCount: number;
    commentList: FeedComment.AsObject[];
    authorPersonView?: gateway_person_view_pb.PersonViewMinimal.AsObject;
    respondedPeopleCount: number;
    color: string;
    points: number;
    allowedRolesList: string[];
    invitePeopleCount: number;
    inviteResponseCount: number;
    requiresApproval: boolean;
    timestamp: number;
    pinned: boolean;
    unpublishTimestamp: number;
    hasLiked: boolean;
  }
}
export class ShortChallengeCardView extends jspb.Message {
  static readonly displayName = "ShortChallengeCardView";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [6,9,19,24];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, ShortChallengeCardView.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): ShortChallengeCardView.AsObject {
    return ShortChallengeCardView.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!ShortChallengeCardView} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: ShortChallengeCardView) {
    var f: any;
    var obj: any = {
      contentHash: jspb.Message.getFieldWithDefault(msg, 1, ""),
      featuredImage: (f = msg.getFeaturedImage()) && image_image_pb.ImageInfo.toObject(includeInstance, f),
      dueDate: (f = msg.getDueDate()) && common_date_pb.Date.toObject(includeInstance, f),
      title: jspb.Message.getFieldWithDefault(msg, 4, ""),
      body: jspb.Message.getFieldWithDefault(msg, 5, ""),
      responsesSummaryList: jspb.Message.toObjectList(msg.getResponsesSummaryList(),
      ChallengeResponseSummaryItem.toObject, includeInstance),
      hasResponded: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
      challengeType: jspb.Message.getFieldWithDefault(msg, 8, ""),
      allowedCommentingRoleTypesList: (f = jspb.Message.getRepeatedField(msg, 9)) == null ? undefined : f,
      ownerGroupHash: jspb.Message.getFieldWithDefault(msg, 10, ""),
      cloudflareVideoUrl: jspb.Message.getFieldWithDefault(msg, 11, ""),
      cloudflareUid: jspb.Message.getFieldWithDefault(msg, 12, ""),
      cloudflareReady: jspb.Message.getBooleanFieldWithDefault(msg, 13, false),
      privacy: jspb.Message.getFieldWithDefault(msg, 14, ""),
      commentsAllowed: jspb.Message.getBooleanFieldWithDefault(msg, 15, false),
      likeCount: jspb.Message.getFieldWithDefault(msg, 16, 0),
      commentCount: jspb.Message.getFieldWithDefault(msg, 17, 0),
      totalCommentCount: jspb.Message.getFieldWithDefault(msg, 18, 0),
      commentList: jspb.Message.toObjectList(msg.getCommentList(),
      FeedComment.toObject, includeInstance),
      authorPersonView: (f = msg.getAuthorPersonView()) && gateway_person_view_pb.PersonViewMinimal.toObject(includeInstance, f),
      respondedPeopleCount: jspb.Message.getFieldWithDefault(msg, 21, 0),
      color: jspb.Message.getFieldWithDefault(msg, 22, ""),
      points: jspb.Message.getFieldWithDefault(msg, 23, 0),
      allowedRolesList: (f = jspb.Message.getRepeatedField(msg, 24)) == null ? undefined : f,
      invitePeopleCount: jspb.Message.getFieldWithDefault(msg, 25, 0),
      inviteResponseCount: jspb.Message.getFieldWithDefault(msg, 26, 0),
      requiresApproval: jspb.Message.getBooleanFieldWithDefault(msg, 27, false),
      timestamp: jspb.Message.getFieldWithDefault(msg, 28, 0),
      pinned: jspb.Message.getBooleanFieldWithDefault(msg, 100, false),
      unpublishTimestamp: jspb.Message.getFieldWithDefault(msg, 101, 0),
      hasLiked: jspb.Message.getBooleanFieldWithDefault(msg, 102, false)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!ShortChallengeCardView}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new ShortChallengeCardView;
    return ShortChallengeCardView.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!ShortChallengeCardView} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!ShortChallengeCardView}
   */
  static deserializeBinaryFromReader(msg: ShortChallengeCardView, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setContentHash(value1);
        break;
      case 2:
        var value2 = new image_image_pb.ImageInfo;
        reader.readMessage(value2,image_image_pb.ImageInfo.deserializeBinaryFromReader);
        msg.setFeaturedImage(value2);
        break;
      case 3:
        var value3 = new common_date_pb.Date;
        reader.readMessage(value3,common_date_pb.Date.deserializeBinaryFromReader);
        msg.setDueDate(value3);
        break;
      case 4:
        var value4 = /** @type {string} */ (reader.readString());
        msg.setTitle(value4);
        break;
      case 5:
        var value5 = /** @type {string} */ (reader.readString());
        msg.setBody(value5);
        break;
      case 6:
        var value6 = new ChallengeResponseSummaryItem;
        reader.readMessage(value6,ChallengeResponseSummaryItem.deserializeBinaryFromReader);
        msg.addResponsesSummary(value6);
        break;
      case 7:
        var value7 = /** @type {boolean} */ (reader.readBool());
        msg.setHasResponded(value7);
        break;
      case 8:
        var value8 = /** @type {string} */ (reader.readString());
        msg.setChallengeType(value8);
        break;
      case 9:
        var value9 = /** @type {string} */ (reader.readString());
        msg.addAllowedCommentingRoleTypes(value9);
        break;
      case 10:
        var value10 = /** @type {string} */ (reader.readString());
        msg.setOwnerGroupHash(value10);
        break;
      case 11:
        var value11 = /** @type {string} */ (reader.readString());
        msg.setCloudflareVideoUrl(value11);
        break;
      case 12:
        var value12 = /** @type {string} */ (reader.readString());
        msg.setCloudflareUid(value12);
        break;
      case 13:
        var value13 = /** @type {boolean} */ (reader.readBool());
        msg.setCloudflareReady(value13);
        break;
      case 14:
        var value14 = /** @type {string} */ (reader.readString());
        msg.setPrivacy(value14);
        break;
      case 15:
        var value15 = /** @type {boolean} */ (reader.readBool());
        msg.setCommentsAllowed(value15);
        break;
      case 16:
        var value16 = /** @type {number} */ (reader.readInt32());
        msg.setLikeCount(value16);
        break;
      case 17:
        var value17 = /** @type {number} */ (reader.readInt32());
        msg.setCommentCount(value17);
        break;
      case 18:
        var value18 = /** @type {number} */ (reader.readInt32());
        msg.setTotalCommentCount(value18);
        break;
      case 19:
        var value19 = new FeedComment;
        reader.readMessage(value19,FeedComment.deserializeBinaryFromReader);
        msg.addComment(value19);
        break;
      case 20:
        var value20 = new gateway_person_view_pb.PersonViewMinimal;
        reader.readMessage(value20,gateway_person_view_pb.PersonViewMinimal.deserializeBinaryFromReader);
        msg.setAuthorPersonView(value20);
        break;
      case 21:
        var value21 = /** @type {number} */ (reader.readInt32());
        msg.setRespondedPeopleCount(value21);
        break;
      case 22:
        var value22 = /** @type {string} */ (reader.readString());
        msg.setColor(value22);
        break;
      case 23:
        var value23 = /** @type {number} */ (reader.readInt32());
        msg.setPoints(value23);
        break;
      case 24:
        var value24 = /** @type {string} */ (reader.readString());
        msg.addAllowedRoles(value24);
        break;
      case 25:
        var value25 = /** @type {number} */ (reader.readInt32());
        msg.setInvitePeopleCount(value25);
        break;
      case 26:
        var value26 = /** @type {number} */ (reader.readInt32());
        msg.setInviteResponseCount(value26);
        break;
      case 27:
        var value27 = /** @type {boolean} */ (reader.readBool());
        msg.setRequiresApproval(value27);
        break;
      case 28:
        var value28 = /** @type {number} */ (reader.readUint64());
        msg.setTimestamp(value28);
        break;
      case 100:
        var value100 = /** @type {boolean} */ (reader.readBool());
        msg.setPinned(value100);
        break;
      case 101:
        var value101 = /** @type {number} */ (reader.readUint64());
        msg.setUnpublishTimestamp(value101);
        break;
      case 102:
        var value102 = /** @type {boolean} */ (reader.readBool());
        msg.setHasLiked(value102);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!ShortChallengeCardView} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: ShortChallengeCardView, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getContentHash();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
    f = message.getFeaturedImage();
    if (f != null) {
      writer.writeMessage(
        2,
        f,
        image_image_pb.ImageInfo.serializeBinaryToWriter
      );
    }
    f = message.getDueDate();
    if (f != null) {
      writer.writeMessage(
        3,
        f,
        common_date_pb.Date.serializeBinaryToWriter
      );
    }
    f = message.getTitle();
    if (f.length > 0) {
      writer.writeString(
        4,
        f
      );
    }
    f = message.getBody();
    if (f.length > 0) {
      writer.writeString(
        5,
        f
      );
    }
    f = message.getResponsesSummaryList();
    if (f.length > 0) {
      writer.writeRepeatedMessage(
        6,
        f,
        ChallengeResponseSummaryItem.serializeBinaryToWriter
      );
    }
    f = message.getHasResponded();
    if (f) {
      writer.writeBool(
        7,
        f
      );
    }
    f = message.getChallengeType();
    if (f.length > 0) {
      writer.writeString(
        8,
        f
      );
    }
    f = message.getAllowedCommentingRoleTypesList();
    if (f.length > 0) {
      writer.writeRepeatedString(
        9,
        f
      );
    }
    f = message.getOwnerGroupHash();
    if (f.length > 0) {
      writer.writeString(
        10,
        f
      );
    }
    f = message.getCloudflareVideoUrl();
    if (f.length > 0) {
      writer.writeString(
        11,
        f
      );
    }
    f = message.getCloudflareUid();
    if (f.length > 0) {
      writer.writeString(
        12,
        f
      );
    }
    f = message.getCloudflareReady();
    if (f) {
      writer.writeBool(
        13,
        f
      );
    }
    f = message.getPrivacy();
    if (f.length > 0) {
      writer.writeString(
        14,
        f
      );
    }
    f = message.getCommentsAllowed();
    if (f) {
      writer.writeBool(
        15,
        f
      );
    }
    f = message.getLikeCount();
    if (f !== 0) {
      writer.writeInt32(
        16,
        f
      );
    }
    f = message.getCommentCount();
    if (f !== 0) {
      writer.writeInt32(
        17,
        f
      );
    }
    f = message.getTotalCommentCount();
    if (f !== 0) {
      writer.writeInt32(
        18,
        f
      );
    }
    f = message.getCommentList();
    if (f.length > 0) {
      writer.writeRepeatedMessage(
        19,
        f,
        FeedComment.serializeBinaryToWriter
      );
    }
    f = message.getAuthorPersonView();
    if (f != null) {
      writer.writeMessage(
        20,
        f,
        gateway_person_view_pb.PersonViewMinimal.serializeBinaryToWriter
      );
    }
    f = message.getRespondedPeopleCount();
    if (f !== 0) {
      writer.writeInt32(
        21,
        f
      );
    }
    f = message.getColor();
    if (f.length > 0) {
      writer.writeString(
        22,
        f
      );
    }
    f = message.getPoints();
    if (f !== 0) {
      writer.writeInt32(
        23,
        f
      );
    }
    f = message.getAllowedRolesList();
    if (f.length > 0) {
      writer.writeRepeatedString(
        24,
        f
      );
    }
    f = message.getInvitePeopleCount();
    if (f !== 0) {
      writer.writeInt32(
        25,
        f
      );
    }
    f = message.getInviteResponseCount();
    if (f !== 0) {
      writer.writeInt32(
        26,
        f
      );
    }
    f = message.getRequiresApproval();
    if (f) {
      writer.writeBool(
        27,
        f
      );
    }
    f = message.getTimestamp();
    if (f !== 0) {
      writer.writeUint64(
        28,
        f
      );
    }
    f = message.getPinned();
    if (f) {
      writer.writeBool(
        100,
        f
      );
    }
    f = message.getUnpublishTimestamp();
    if (f !== 0) {
      writer.writeUint64(
        101,
        f
      );
    }
    f = message.getHasLiked();
    if (f) {
      writer.writeBool(
        102,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    ShortChallengeCardView.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string content_hash = 1;
   * @return {string}
   */
  getContentHash(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setContentHash(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


  /**
   * optional ImageInfo featured_image = 2;
   * @return {?ImageInfo}
   */
  getFeaturedImage(): image_image_pb.ImageInfo {
    return /** @type{?ImageInfo} */ (
      jspb.Message.getWrapperField(this, image_image_pb.ImageInfo, 2));
  }


  /** @param {?ImageInfo|undefined} value */
  setFeaturedImage(value?: image_image_pb.ImageInfo) {
    jspb.Message.setWrapperField(this, 2, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearFeaturedImage() {
    this.setFeaturedImage(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasFeaturedImage(): boolean {
    return jspb.Message.getField(this, 2) != null;
  }


  /**
   * optional minga.common.Date due_date = 3;
   * @return {?Date}
   */
  getDueDate(): common_date_pb.Date {
    return /** @type{?Date} */ (
      jspb.Message.getWrapperField(this, common_date_pb.Date, 3));
  }


  /** @param {?Date|undefined} value */
  setDueDate(value?: common_date_pb.Date) {
    jspb.Message.setWrapperField(this, 3, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearDueDate() {
    this.setDueDate(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasDueDate(): boolean {
    return jspb.Message.getField(this, 3) != null;
  }


  /**
   * optional string title = 4;
   * @return {string}
   */
  getTitle(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
  };


  /** @param {string} value */
  setTitle(value: string) {
    jspb.Message.setProto3StringField(this, 4, value);
  }


  /**
   * optional string body = 5;
   * @return {string}
   */
  getBody(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
  };


  /** @param {string} value */
  setBody(value: string) {
    jspb.Message.setProto3StringField(this, 5, value);
  }


  /**
   * repeated ChallengeResponseSummaryItem responses_summary = 6;
   * @return {!Array<!ChallengeResponseSummaryItem>}
   */
  getResponsesSummaryList(): ChallengeResponseSummaryItem[] {
    return /** @type{!Array<!ChallengeResponseSummaryItem>} */ (
      jspb.Message.getRepeatedWrapperField(this, ChallengeResponseSummaryItem, 6));
  }


  /** @param {!Array<!ChallengeResponseSummaryItem>} value */
  setResponsesSummaryList(value?: ChallengeResponseSummaryItem[]) {
    jspb.Message.setRepeatedWrapperField(this, 6, value);
  }


  /**
   * @param {!ChallengeResponseSummaryItem=} opt_value
   * @param {number=} opt_index
   * @return {!ChallengeResponseSummaryItem}
   */
  addResponsesSummary(opt_value?: ChallengeResponseSummaryItem, opt_index?: number): ChallengeResponseSummaryItem {
    return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, ChallengeResponseSummaryItem, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearResponsesSummaryList() {
    this.setResponsesSummaryList([]);
  }


  /**
   * optional bool has_responded = 7;
   * @return {boolean}
   */
  getHasResponded(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
  };


  /** @param {boolean} value */
  setHasResponded(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 7, value);
  }


  /**
   * optional string challenge_type = 8;
   * @return {string}
   */
  getChallengeType(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
  };


  /** @param {string} value */
  setChallengeType(value: string) {
    jspb.Message.setProto3StringField(this, 8, value);
  }


  /**
   * repeated string allowed_commenting_role_types = 9;
   * @return {!Array<string>}
   */
  getAllowedCommentingRoleTypesList(): string[] {
    return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 9));
  };


  /** @param {!Array<string>} value */
  setAllowedCommentingRoleTypesList(value: string[]) {
    jspb.Message.setField(this, 9, value || []);
  }


  /**
   * @param {string} value
   * @param {number=} opt_index
   */
  addAllowedCommentingRoleTypes(value: string, opt_index?: number): void {
    jspb.Message.addToRepeatedField(this, 9, value, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearAllowedCommentingRoleTypesList() {
    this.setAllowedCommentingRoleTypesList([]);
  }


  /**
   * optional string owner_group_hash = 10;
   * @return {string}
   */
  getOwnerGroupHash(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
  };


  /** @param {string} value */
  setOwnerGroupHash(value: string) {
    jspb.Message.setProto3StringField(this, 10, value);
  }


  /**
   * optional string cloudflare_video_url = 11;
   * @return {string}
   */
  getCloudflareVideoUrl(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
  };


  /** @param {string} value */
  setCloudflareVideoUrl(value: string) {
    jspb.Message.setProto3StringField(this, 11, value);
  }


  /**
   * optional string cloudflare_uid = 12;
   * @return {string}
   */
  getCloudflareUid(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
  };


  /** @param {string} value */
  setCloudflareUid(value: string) {
    jspb.Message.setProto3StringField(this, 12, value);
  }


  /**
   * optional bool cloudflare_ready = 13;
   * @return {boolean}
   */
  getCloudflareReady(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 13, false));
  };


  /** @param {boolean} value */
  setCloudflareReady(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 13, value);
  }


  /**
   * optional string privacy = 14;
   * @return {string}
   */
  getPrivacy(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
  };


  /** @param {string} value */
  setPrivacy(value: string) {
    jspb.Message.setProto3StringField(this, 14, value);
  }


  /**
   * optional bool comments_allowed = 15;
   * @return {boolean}
   */
  getCommentsAllowed(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 15, false));
  };


  /** @param {boolean} value */
  setCommentsAllowed(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 15, value);
  }


  /**
   * optional int32 like_count = 16;
   * @return {number}
   */
  getLikeCount(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
  };


  /** @param {number} value */
  setLikeCount(value: number) {
    jspb.Message.setProto3IntField(this, 16, value);
  }


  /**
   * optional int32 comment_count = 17;
   * @return {number}
   */
  getCommentCount(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 17, 0));
  };


  /** @param {number} value */
  setCommentCount(value: number) {
    jspb.Message.setProto3IntField(this, 17, value);
  }


  /**
   * optional int32 total_comment_count = 18;
   * @return {number}
   */
  getTotalCommentCount(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 18, 0));
  };


  /** @param {number} value */
  setTotalCommentCount(value: number) {
    jspb.Message.setProto3IntField(this, 18, value);
  }


  /**
   * repeated FeedComment comment = 19;
   * @return {!Array<!FeedComment>}
   */
  getCommentList(): FeedComment[] {
    return /** @type{!Array<!FeedComment>} */ (
      jspb.Message.getRepeatedWrapperField(this, FeedComment, 19));
  }


  /** @param {!Array<!FeedComment>} value */
  setCommentList(value?: FeedComment[]) {
    jspb.Message.setRepeatedWrapperField(this, 19, value);
  }


  /**
   * @param {!FeedComment=} opt_value
   * @param {number=} opt_index
   * @return {!FeedComment}
   */
  addComment(opt_value?: FeedComment, opt_index?: number): FeedComment {
    return jspb.Message.addToRepeatedWrapperField(this, 19, opt_value, FeedComment, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearCommentList() {
    this.setCommentList([]);
  }


  /**
   * optional PersonViewMinimal author_person_view = 20;
   * @return {?PersonViewMinimal}
   */
  getAuthorPersonView(): gateway_person_view_pb.PersonViewMinimal {
    return /** @type{?PersonViewMinimal} */ (
      jspb.Message.getWrapperField(this, gateway_person_view_pb.PersonViewMinimal, 20));
  }


  /** @param {?PersonViewMinimal|undefined} value */
  setAuthorPersonView(value?: gateway_person_view_pb.PersonViewMinimal) {
    jspb.Message.setWrapperField(this, 20, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearAuthorPersonView() {
    this.setAuthorPersonView(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasAuthorPersonView(): boolean {
    return jspb.Message.getField(this, 20) != null;
  }


  /**
   * optional int32 responded_people_count = 21;
   * @return {number}
   */
  getRespondedPeopleCount(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 21, 0));
  };


  /** @param {number} value */
  setRespondedPeopleCount(value: number) {
    jspb.Message.setProto3IntField(this, 21, value);
  }


  /**
   * optional string color = 22;
   * @return {string}
   */
  getColor(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 22, ""));
  };


  /** @param {string} value */
  setColor(value: string) {
    jspb.Message.setProto3StringField(this, 22, value);
  }


  /**
   * optional int32 points = 23;
   * @return {number}
   */
  getPoints(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 23, 0));
  };


  /** @param {number} value */
  setPoints(value: number) {
    jspb.Message.setProto3IntField(this, 23, value);
  }


  /**
   * repeated string allowed_roles = 24;
   * @return {!Array<string>}
   */
  getAllowedRolesList(): string[] {
    return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 24));
  };


  /** @param {!Array<string>} value */
  setAllowedRolesList(value: string[]) {
    jspb.Message.setField(this, 24, value || []);
  }


  /**
   * @param {string} value
   * @param {number=} opt_index
   */
  addAllowedRoles(value: string, opt_index?: number): void {
    jspb.Message.addToRepeatedField(this, 24, value, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearAllowedRolesList() {
    this.setAllowedRolesList([]);
  }


  /**
   * optional int32 invite_people_count = 25;
   * @return {number}
   */
  getInvitePeopleCount(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 25, 0));
  };


  /** @param {number} value */
  setInvitePeopleCount(value: number) {
    jspb.Message.setProto3IntField(this, 25, value);
  }


  /**
   * optional int32 invite_response_count = 26;
   * @return {number}
   */
  getInviteResponseCount(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 26, 0));
  };


  /** @param {number} value */
  setInviteResponseCount(value: number) {
    jspb.Message.setProto3IntField(this, 26, value);
  }


  /**
   * optional bool requires_approval = 27;
   * @return {boolean}
   */
  getRequiresApproval(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 27, false));
  };


  /** @param {boolean} value */
  setRequiresApproval(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 27, value);
  }


  /**
   * optional uint64 timestamp = 28;
   * @return {number}
   */
  getTimestamp(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 28, 0));
  };


  /** @param {number} value */
  setTimestamp(value: number) {
    jspb.Message.setProto3IntField(this, 28, value);
  }


  /**
   * optional bool pinned = 100;
   * @return {boolean}
   */
  getPinned(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 100, false));
  };


  /** @param {boolean} value */
  setPinned(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 100, value);
  }


  /**
   * optional uint64 unpublish_timestamp = 101;
   * @return {number}
   */
  getUnpublishTimestamp(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 101, 0));
  };


  /** @param {number} value */
  setUnpublishTimestamp(value: number) {
    jspb.Message.setProto3IntField(this, 101, value);
  }


  /**
   * optional bool has_liked = 102;
   * @return {boolean}
   */
  getHasLiked(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 102, false));
  };


  /** @param {boolean} value */
  setHasLiked(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 102, value);
  }


}
export namespace ShortSmsMessageCardView {
  export interface AsObject {
    contentHash: string;
    message: string;
    timestamp: number;
    authorPersonView?: gateway_person_view_pb.PersonViewMinimal.AsObject;
    ownerGroupHash: string;
    pinned: boolean;
    unpublishTimestamp: number;
  }
}
export class ShortSmsMessageCardView extends jspb.Message {
  static readonly displayName = "ShortSmsMessageCardView";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): ShortSmsMessageCardView.AsObject {
    return ShortSmsMessageCardView.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!ShortSmsMessageCardView} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: ShortSmsMessageCardView) {
    var f: any;
    var obj: any = {
      contentHash: jspb.Message.getFieldWithDefault(msg, 1, ""),
      message: jspb.Message.getFieldWithDefault(msg, 2, ""),
      timestamp: jspb.Message.getFieldWithDefault(msg, 3, 0),
      authorPersonView: (f = msg.getAuthorPersonView()) && gateway_person_view_pb.PersonViewMinimal.toObject(includeInstance, f),
      ownerGroupHash: jspb.Message.getFieldWithDefault(msg, 5, ""),
      pinned: jspb.Message.getBooleanFieldWithDefault(msg, 100, false),
      unpublishTimestamp: jspb.Message.getFieldWithDefault(msg, 101, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!ShortSmsMessageCardView}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new ShortSmsMessageCardView;
    return ShortSmsMessageCardView.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!ShortSmsMessageCardView} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!ShortSmsMessageCardView}
   */
  static deserializeBinaryFromReader(msg: ShortSmsMessageCardView, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setContentHash(value1);
        break;
      case 2:
        var value2 = /** @type {string} */ (reader.readString());
        msg.setMessage(value2);
        break;
      case 3:
        var value3 = /** @type {number} */ (reader.readInt64());
        msg.setTimestamp(value3);
        break;
      case 4:
        var value4 = new gateway_person_view_pb.PersonViewMinimal;
        reader.readMessage(value4,gateway_person_view_pb.PersonViewMinimal.deserializeBinaryFromReader);
        msg.setAuthorPersonView(value4);
        break;
      case 5:
        var value5 = /** @type {string} */ (reader.readString());
        msg.setOwnerGroupHash(value5);
        break;
      case 100:
        var value100 = /** @type {boolean} */ (reader.readBool());
        msg.setPinned(value100);
        break;
      case 101:
        var value101 = /** @type {number} */ (reader.readUint64());
        msg.setUnpublishTimestamp(value101);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!ShortSmsMessageCardView} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: ShortSmsMessageCardView, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getContentHash();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
    f = message.getMessage();
    if (f.length > 0) {
      writer.writeString(
        2,
        f
      );
    }
    f = message.getTimestamp();
    if (f !== 0) {
      writer.writeInt64(
        3,
        f
      );
    }
    f = message.getAuthorPersonView();
    if (f != null) {
      writer.writeMessage(
        4,
        f,
        gateway_person_view_pb.PersonViewMinimal.serializeBinaryToWriter
      );
    }
    f = message.getOwnerGroupHash();
    if (f.length > 0) {
      writer.writeString(
        5,
        f
      );
    }
    f = message.getPinned();
    if (f) {
      writer.writeBool(
        100,
        f
      );
    }
    f = message.getUnpublishTimestamp();
    if (f !== 0) {
      writer.writeUint64(
        101,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    ShortSmsMessageCardView.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string content_hash = 1;
   * @return {string}
   */
  getContentHash(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setContentHash(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


  /**
   * optional string message = 2;
   * @return {string}
   */
  getMessage(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
  };


  /** @param {string} value */
  setMessage(value: string) {
    jspb.Message.setProto3StringField(this, 2, value);
  }


  /**
   * optional int64 timestamp = 3;
   * @return {number}
   */
  getTimestamp(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
  };


  /** @param {number} value */
  setTimestamp(value: number) {
    jspb.Message.setProto3IntField(this, 3, value);
  }


  /**
   * optional PersonViewMinimal author_person_view = 4;
   * @return {?PersonViewMinimal}
   */
  getAuthorPersonView(): gateway_person_view_pb.PersonViewMinimal {
    return /** @type{?PersonViewMinimal} */ (
      jspb.Message.getWrapperField(this, gateway_person_view_pb.PersonViewMinimal, 4));
  }


  /** @param {?PersonViewMinimal|undefined} value */
  setAuthorPersonView(value?: gateway_person_view_pb.PersonViewMinimal) {
    jspb.Message.setWrapperField(this, 4, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearAuthorPersonView() {
    this.setAuthorPersonView(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasAuthorPersonView(): boolean {
    return jspb.Message.getField(this, 4) != null;
  }


  /**
   * optional string owner_group_hash = 5;
   * @return {string}
   */
  getOwnerGroupHash(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
  };


  /** @param {string} value */
  setOwnerGroupHash(value: string) {
    jspb.Message.setProto3StringField(this, 5, value);
  }


  /**
   * optional bool pinned = 100;
   * @return {boolean}
   */
  getPinned(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 100, false));
  };


  /** @param {boolean} value */
  setPinned(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 100, value);
  }


  /**
   * optional uint64 unpublish_timestamp = 101;
   * @return {number}
   */
  getUnpublishTimestamp(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 101, 0));
  };


  /** @param {number} value */
  setUnpublishTimestamp(value: number) {
    jspb.Message.setProto3IntField(this, 101, value);
  }


}
export namespace LongChallengeCardView {
  export interface AsObject {
    contentHash: string;
    featuredImage?: image_image_pb.ImageInfo.AsObject;
    dueDate?: common_date_pb.Date.AsObject;
    title: string;
    body: string;
    responsesSummaryList: ChallengeResponseSummaryItem.AsObject[];
    hasResponded: boolean;
    challengeType: string;
    allowedCommentingRoleTypesList: string[];
    ownerGroupHash: string;
    likeCount: number;
    commentCount: number;
    totalCommentCount: number;
    commentList: gateway_comment_pb.CommentContent.AsObject[];
    authorPersonView?: gateway_person_view_pb.PersonViewMinimal.AsObject;
    cloudflareVideoUrl: string;
    cloudflareUid: string;
    cloudflareReady: boolean;
    privacy: string;
    commentsAllowed: boolean;
    respondedPeopleCount: number;
    color: string;
    points: number;
    badgeImage?: image_image_pb.ImageInfo.AsObject;
    allowedRolesList: string[];
    requiresApproval: boolean;
    timestamp: number;
    pinned: boolean;
    unpublishTimestamp: number;
    hasLiked: boolean;
  }
}
export class LongChallengeCardView extends jspb.Message {
  static readonly displayName = "LongChallengeCardView";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [6,9,14,25];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, LongChallengeCardView.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): LongChallengeCardView.AsObject {
    return LongChallengeCardView.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!LongChallengeCardView} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: LongChallengeCardView) {
    var f: any;
    var obj: any = {
      contentHash: jspb.Message.getFieldWithDefault(msg, 1, ""),
      featuredImage: (f = msg.getFeaturedImage()) && image_image_pb.ImageInfo.toObject(includeInstance, f),
      dueDate: (f = msg.getDueDate()) && common_date_pb.Date.toObject(includeInstance, f),
      title: jspb.Message.getFieldWithDefault(msg, 4, ""),
      body: jspb.Message.getFieldWithDefault(msg, 5, ""),
      responsesSummaryList: jspb.Message.toObjectList(msg.getResponsesSummaryList(),
      ChallengeResponseSummaryItem.toObject, includeInstance),
      hasResponded: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
      challengeType: jspb.Message.getFieldWithDefault(msg, 8, ""),
      allowedCommentingRoleTypesList: (f = jspb.Message.getRepeatedField(msg, 9)) == null ? undefined : f,
      ownerGroupHash: jspb.Message.getFieldWithDefault(msg, 10, ""),
      likeCount: jspb.Message.getFieldWithDefault(msg, 11, 0),
      commentCount: jspb.Message.getFieldWithDefault(msg, 12, 0),
      totalCommentCount: jspb.Message.getFieldWithDefault(msg, 13, 0),
      commentList: jspb.Message.toObjectList(msg.getCommentList(),
      gateway_comment_pb.CommentContent.toObject, includeInstance),
      authorPersonView: (f = msg.getAuthorPersonView()) && gateway_person_view_pb.PersonViewMinimal.toObject(includeInstance, f),
      cloudflareVideoUrl: jspb.Message.getFieldWithDefault(msg, 16, ""),
      cloudflareUid: jspb.Message.getFieldWithDefault(msg, 17, ""),
      cloudflareReady: jspb.Message.getBooleanFieldWithDefault(msg, 18, false),
      privacy: jspb.Message.getFieldWithDefault(msg, 19, ""),
      commentsAllowed: jspb.Message.getBooleanFieldWithDefault(msg, 20, false),
      respondedPeopleCount: jspb.Message.getFieldWithDefault(msg, 21, 0),
      color: jspb.Message.getFieldWithDefault(msg, 22, ""),
      points: jspb.Message.getFieldWithDefault(msg, 23, 0),
      badgeImage: (f = msg.getBadgeImage()) && image_image_pb.ImageInfo.toObject(includeInstance, f),
      allowedRolesList: (f = jspb.Message.getRepeatedField(msg, 25)) == null ? undefined : f,
      requiresApproval: jspb.Message.getBooleanFieldWithDefault(msg, 26, false),
      timestamp: jspb.Message.getFieldWithDefault(msg, 28, 0),
      pinned: jspb.Message.getBooleanFieldWithDefault(msg, 100, false),
      unpublishTimestamp: jspb.Message.getFieldWithDefault(msg, 101, 0),
      hasLiked: jspb.Message.getBooleanFieldWithDefault(msg, 102, false)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!LongChallengeCardView}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new LongChallengeCardView;
    return LongChallengeCardView.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!LongChallengeCardView} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!LongChallengeCardView}
   */
  static deserializeBinaryFromReader(msg: LongChallengeCardView, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setContentHash(value1);
        break;
      case 2:
        var value2 = new image_image_pb.ImageInfo;
        reader.readMessage(value2,image_image_pb.ImageInfo.deserializeBinaryFromReader);
        msg.setFeaturedImage(value2);
        break;
      case 3:
        var value3 = new common_date_pb.Date;
        reader.readMessage(value3,common_date_pb.Date.deserializeBinaryFromReader);
        msg.setDueDate(value3);
        break;
      case 4:
        var value4 = /** @type {string} */ (reader.readString());
        msg.setTitle(value4);
        break;
      case 5:
        var value5 = /** @type {string} */ (reader.readString());
        msg.setBody(value5);
        break;
      case 6:
        var value6 = new ChallengeResponseSummaryItem;
        reader.readMessage(value6,ChallengeResponseSummaryItem.deserializeBinaryFromReader);
        msg.addResponsesSummary(value6);
        break;
      case 7:
        var value7 = /** @type {boolean} */ (reader.readBool());
        msg.setHasResponded(value7);
        break;
      case 8:
        var value8 = /** @type {string} */ (reader.readString());
        msg.setChallengeType(value8);
        break;
      case 9:
        var value9 = /** @type {string} */ (reader.readString());
        msg.addAllowedCommentingRoleTypes(value9);
        break;
      case 10:
        var value10 = /** @type {string} */ (reader.readString());
        msg.setOwnerGroupHash(value10);
        break;
      case 11:
        var value11 = /** @type {number} */ (reader.readInt32());
        msg.setLikeCount(value11);
        break;
      case 12:
        var value12 = /** @type {number} */ (reader.readInt32());
        msg.setCommentCount(value12);
        break;
      case 13:
        var value13 = /** @type {number} */ (reader.readInt32());
        msg.setTotalCommentCount(value13);
        break;
      case 14:
        var value14 = new gateway_comment_pb.CommentContent;
        reader.readMessage(value14,gateway_comment_pb.CommentContent.deserializeBinaryFromReader);
        msg.addComment(value14);
        break;
      case 15:
        var value15 = new gateway_person_view_pb.PersonViewMinimal;
        reader.readMessage(value15,gateway_person_view_pb.PersonViewMinimal.deserializeBinaryFromReader);
        msg.setAuthorPersonView(value15);
        break;
      case 16:
        var value16 = /** @type {string} */ (reader.readString());
        msg.setCloudflareVideoUrl(value16);
        break;
      case 17:
        var value17 = /** @type {string} */ (reader.readString());
        msg.setCloudflareUid(value17);
        break;
      case 18:
        var value18 = /** @type {boolean} */ (reader.readBool());
        msg.setCloudflareReady(value18);
        break;
      case 19:
        var value19 = /** @type {string} */ (reader.readString());
        msg.setPrivacy(value19);
        break;
      case 20:
        var value20 = /** @type {boolean} */ (reader.readBool());
        msg.setCommentsAllowed(value20);
        break;
      case 21:
        var value21 = /** @type {number} */ (reader.readInt32());
        msg.setRespondedPeopleCount(value21);
        break;
      case 22:
        var value22 = /** @type {string} */ (reader.readString());
        msg.setColor(value22);
        break;
      case 23:
        var value23 = /** @type {number} */ (reader.readInt32());
        msg.setPoints(value23);
        break;
      case 24:
        var value24 = new image_image_pb.ImageInfo;
        reader.readMessage(value24,image_image_pb.ImageInfo.deserializeBinaryFromReader);
        msg.setBadgeImage(value24);
        break;
      case 25:
        var value25 = /** @type {string} */ (reader.readString());
        msg.addAllowedRoles(value25);
        break;
      case 26:
        var value26 = /** @type {boolean} */ (reader.readBool());
        msg.setRequiresApproval(value26);
        break;
      case 28:
        var value28 = /** @type {number} */ (reader.readUint64());
        msg.setTimestamp(value28);
        break;
      case 100:
        var value100 = /** @type {boolean} */ (reader.readBool());
        msg.setPinned(value100);
        break;
      case 101:
        var value101 = /** @type {number} */ (reader.readUint64());
        msg.setUnpublishTimestamp(value101);
        break;
      case 102:
        var value102 = /** @type {boolean} */ (reader.readBool());
        msg.setHasLiked(value102);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!LongChallengeCardView} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: LongChallengeCardView, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getContentHash();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
    f = message.getFeaturedImage();
    if (f != null) {
      writer.writeMessage(
        2,
        f,
        image_image_pb.ImageInfo.serializeBinaryToWriter
      );
    }
    f = message.getDueDate();
    if (f != null) {
      writer.writeMessage(
        3,
        f,
        common_date_pb.Date.serializeBinaryToWriter
      );
    }
    f = message.getTitle();
    if (f.length > 0) {
      writer.writeString(
        4,
        f
      );
    }
    f = message.getBody();
    if (f.length > 0) {
      writer.writeString(
        5,
        f
      );
    }
    f = message.getResponsesSummaryList();
    if (f.length > 0) {
      writer.writeRepeatedMessage(
        6,
        f,
        ChallengeResponseSummaryItem.serializeBinaryToWriter
      );
    }
    f = message.getHasResponded();
    if (f) {
      writer.writeBool(
        7,
        f
      );
    }
    f = message.getChallengeType();
    if (f.length > 0) {
      writer.writeString(
        8,
        f
      );
    }
    f = message.getAllowedCommentingRoleTypesList();
    if (f.length > 0) {
      writer.writeRepeatedString(
        9,
        f
      );
    }
    f = message.getOwnerGroupHash();
    if (f.length > 0) {
      writer.writeString(
        10,
        f
      );
    }
    f = message.getLikeCount();
    if (f !== 0) {
      writer.writeInt32(
        11,
        f
      );
    }
    f = message.getCommentCount();
    if (f !== 0) {
      writer.writeInt32(
        12,
        f
      );
    }
    f = message.getTotalCommentCount();
    if (f !== 0) {
      writer.writeInt32(
        13,
        f
      );
    }
    f = message.getCommentList();
    if (f.length > 0) {
      writer.writeRepeatedMessage(
        14,
        f,
        gateway_comment_pb.CommentContent.serializeBinaryToWriter
      );
    }
    f = message.getAuthorPersonView();
    if (f != null) {
      writer.writeMessage(
        15,
        f,
        gateway_person_view_pb.PersonViewMinimal.serializeBinaryToWriter
      );
    }
    f = message.getCloudflareVideoUrl();
    if (f.length > 0) {
      writer.writeString(
        16,
        f
      );
    }
    f = message.getCloudflareUid();
    if (f.length > 0) {
      writer.writeString(
        17,
        f
      );
    }
    f = message.getCloudflareReady();
    if (f) {
      writer.writeBool(
        18,
        f
      );
    }
    f = message.getPrivacy();
    if (f.length > 0) {
      writer.writeString(
        19,
        f
      );
    }
    f = message.getCommentsAllowed();
    if (f) {
      writer.writeBool(
        20,
        f
      );
    }
    f = message.getRespondedPeopleCount();
    if (f !== 0) {
      writer.writeInt32(
        21,
        f
      );
    }
    f = message.getColor();
    if (f.length > 0) {
      writer.writeString(
        22,
        f
      );
    }
    f = message.getPoints();
    if (f !== 0) {
      writer.writeInt32(
        23,
        f
      );
    }
    f = message.getBadgeImage();
    if (f != null) {
      writer.writeMessage(
        24,
        f,
        image_image_pb.ImageInfo.serializeBinaryToWriter
      );
    }
    f = message.getAllowedRolesList();
    if (f.length > 0) {
      writer.writeRepeatedString(
        25,
        f
      );
    }
    f = message.getRequiresApproval();
    if (f) {
      writer.writeBool(
        26,
        f
      );
    }
    f = message.getTimestamp();
    if (f !== 0) {
      writer.writeUint64(
        28,
        f
      );
    }
    f = message.getPinned();
    if (f) {
      writer.writeBool(
        100,
        f
      );
    }
    f = message.getUnpublishTimestamp();
    if (f !== 0) {
      writer.writeUint64(
        101,
        f
      );
    }
    f = message.getHasLiked();
    if (f) {
      writer.writeBool(
        102,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    LongChallengeCardView.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string content_hash = 1;
   * @return {string}
   */
  getContentHash(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setContentHash(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


  /**
   * optional ImageInfo featured_image = 2;
   * @return {?ImageInfo}
   */
  getFeaturedImage(): image_image_pb.ImageInfo {
    return /** @type{?ImageInfo} */ (
      jspb.Message.getWrapperField(this, image_image_pb.ImageInfo, 2));
  }


  /** @param {?ImageInfo|undefined} value */
  setFeaturedImage(value?: image_image_pb.ImageInfo) {
    jspb.Message.setWrapperField(this, 2, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearFeaturedImage() {
    this.setFeaturedImage(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasFeaturedImage(): boolean {
    return jspb.Message.getField(this, 2) != null;
  }


  /**
   * optional minga.common.Date due_date = 3;
   * @return {?Date}
   */
  getDueDate(): common_date_pb.Date {
    return /** @type{?Date} */ (
      jspb.Message.getWrapperField(this, common_date_pb.Date, 3));
  }


  /** @param {?Date|undefined} value */
  setDueDate(value?: common_date_pb.Date) {
    jspb.Message.setWrapperField(this, 3, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearDueDate() {
    this.setDueDate(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasDueDate(): boolean {
    return jspb.Message.getField(this, 3) != null;
  }


  /**
   * optional string title = 4;
   * @return {string}
   */
  getTitle(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
  };


  /** @param {string} value */
  setTitle(value: string) {
    jspb.Message.setProto3StringField(this, 4, value);
  }


  /**
   * optional string body = 5;
   * @return {string}
   */
  getBody(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
  };


  /** @param {string} value */
  setBody(value: string) {
    jspb.Message.setProto3StringField(this, 5, value);
  }


  /**
   * repeated ChallengeResponseSummaryItem responses_summary = 6;
   * @return {!Array<!ChallengeResponseSummaryItem>}
   */
  getResponsesSummaryList(): ChallengeResponseSummaryItem[] {
    return /** @type{!Array<!ChallengeResponseSummaryItem>} */ (
      jspb.Message.getRepeatedWrapperField(this, ChallengeResponseSummaryItem, 6));
  }


  /** @param {!Array<!ChallengeResponseSummaryItem>} value */
  setResponsesSummaryList(value?: ChallengeResponseSummaryItem[]) {
    jspb.Message.setRepeatedWrapperField(this, 6, value);
  }


  /**
   * @param {!ChallengeResponseSummaryItem=} opt_value
   * @param {number=} opt_index
   * @return {!ChallengeResponseSummaryItem}
   */
  addResponsesSummary(opt_value?: ChallengeResponseSummaryItem, opt_index?: number): ChallengeResponseSummaryItem {
    return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, ChallengeResponseSummaryItem, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearResponsesSummaryList() {
    this.setResponsesSummaryList([]);
  }


  /**
   * optional bool has_responded = 7;
   * @return {boolean}
   */
  getHasResponded(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
  };


  /** @param {boolean} value */
  setHasResponded(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 7, value);
  }


  /**
   * optional string challenge_type = 8;
   * @return {string}
   */
  getChallengeType(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
  };


  /** @param {string} value */
  setChallengeType(value: string) {
    jspb.Message.setProto3StringField(this, 8, value);
  }


  /**
   * repeated string allowed_commenting_role_types = 9;
   * @return {!Array<string>}
   */
  getAllowedCommentingRoleTypesList(): string[] {
    return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 9));
  };


  /** @param {!Array<string>} value */
  setAllowedCommentingRoleTypesList(value: string[]) {
    jspb.Message.setField(this, 9, value || []);
  }


  /**
   * @param {string} value
   * @param {number=} opt_index
   */
  addAllowedCommentingRoleTypes(value: string, opt_index?: number): void {
    jspb.Message.addToRepeatedField(this, 9, value, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearAllowedCommentingRoleTypesList() {
    this.setAllowedCommentingRoleTypesList([]);
  }


  /**
   * optional string owner_group_hash = 10;
   * @return {string}
   */
  getOwnerGroupHash(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
  };


  /** @param {string} value */
  setOwnerGroupHash(value: string) {
    jspb.Message.setProto3StringField(this, 10, value);
  }


  /**
   * optional int32 like_count = 11;
   * @return {number}
   */
  getLikeCount(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
  };


  /** @param {number} value */
  setLikeCount(value: number) {
    jspb.Message.setProto3IntField(this, 11, value);
  }


  /**
   * optional int32 comment_count = 12;
   * @return {number}
   */
  getCommentCount(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
  };


  /** @param {number} value */
  setCommentCount(value: number) {
    jspb.Message.setProto3IntField(this, 12, value);
  }


  /**
   * optional int32 total_comment_count = 13;
   * @return {number}
   */
  getTotalCommentCount(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
  };


  /** @param {number} value */
  setTotalCommentCount(value: number) {
    jspb.Message.setProto3IntField(this, 13, value);
  }


  /**
   * repeated CommentContent comment = 14;
   * @return {!Array<!CommentContent>}
   */
  getCommentList(): gateway_comment_pb.CommentContent[] {
    return /** @type{!Array<!CommentContent>} */ (
      jspb.Message.getRepeatedWrapperField(this, gateway_comment_pb.CommentContent, 14));
  }


  /** @param {!Array<!CommentContent>} value */
  setCommentList(value?: gateway_comment_pb.CommentContent[]) {
    jspb.Message.setRepeatedWrapperField(this, 14, value);
  }


  /**
   * @param {!CommentContent=} opt_value
   * @param {number=} opt_index
   * @return {!CommentContent}
   */
  addComment(opt_value?: gateway_comment_pb.CommentContent, opt_index?: number): gateway_comment_pb.CommentContent {
    return jspb.Message.addToRepeatedWrapperField(this, 14, opt_value, gateway_comment_pb.CommentContent, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearCommentList() {
    this.setCommentList([]);
  }


  /**
   * optional PersonViewMinimal author_person_view = 15;
   * @return {?PersonViewMinimal}
   */
  getAuthorPersonView(): gateway_person_view_pb.PersonViewMinimal {
    return /** @type{?PersonViewMinimal} */ (
      jspb.Message.getWrapperField(this, gateway_person_view_pb.PersonViewMinimal, 15));
  }


  /** @param {?PersonViewMinimal|undefined} value */
  setAuthorPersonView(value?: gateway_person_view_pb.PersonViewMinimal) {
    jspb.Message.setWrapperField(this, 15, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearAuthorPersonView() {
    this.setAuthorPersonView(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasAuthorPersonView(): boolean {
    return jspb.Message.getField(this, 15) != null;
  }


  /**
   * optional string cloudflare_video_url = 16;
   * @return {string}
   */
  getCloudflareVideoUrl(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
  };


  /** @param {string} value */
  setCloudflareVideoUrl(value: string) {
    jspb.Message.setProto3StringField(this, 16, value);
  }


  /**
   * optional string cloudflare_uid = 17;
   * @return {string}
   */
  getCloudflareUid(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
  };


  /** @param {string} value */
  setCloudflareUid(value: string) {
    jspb.Message.setProto3StringField(this, 17, value);
  }


  /**
   * optional bool cloudflare_ready = 18;
   * @return {boolean}
   */
  getCloudflareReady(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 18, false));
  };


  /** @param {boolean} value */
  setCloudflareReady(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 18, value);
  }


  /**
   * optional string privacy = 19;
   * @return {string}
   */
  getPrivacy(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
  };


  /** @param {string} value */
  setPrivacy(value: string) {
    jspb.Message.setProto3StringField(this, 19, value);
  }


  /**
   * optional bool comments_allowed = 20;
   * @return {boolean}
   */
  getCommentsAllowed(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 20, false));
  };


  /** @param {boolean} value */
  setCommentsAllowed(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 20, value);
  }


  /**
   * optional int32 responded_people_count = 21;
   * @return {number}
   */
  getRespondedPeopleCount(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 21, 0));
  };


  /** @param {number} value */
  setRespondedPeopleCount(value: number) {
    jspb.Message.setProto3IntField(this, 21, value);
  }


  /**
   * optional string color = 22;
   * @return {string}
   */
  getColor(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 22, ""));
  };


  /** @param {string} value */
  setColor(value: string) {
    jspb.Message.setProto3StringField(this, 22, value);
  }


  /**
   * optional int32 points = 23;
   * @return {number}
   */
  getPoints(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 23, 0));
  };


  /** @param {number} value */
  setPoints(value: number) {
    jspb.Message.setProto3IntField(this, 23, value);
  }


  /**
   * optional ImageInfo badge_image = 24;
   * @return {?ImageInfo}
   */
  getBadgeImage(): image_image_pb.ImageInfo {
    return /** @type{?ImageInfo} */ (
      jspb.Message.getWrapperField(this, image_image_pb.ImageInfo, 24));
  }


  /** @param {?ImageInfo|undefined} value */
  setBadgeImage(value?: image_image_pb.ImageInfo) {
    jspb.Message.setWrapperField(this, 24, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearBadgeImage() {
    this.setBadgeImage(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasBadgeImage(): boolean {
    return jspb.Message.getField(this, 24) != null;
  }


  /**
   * repeated string allowed_roles = 25;
   * @return {!Array<string>}
   */
  getAllowedRolesList(): string[] {
    return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 25));
  };


  /** @param {!Array<string>} value */
  setAllowedRolesList(value: string[]) {
    jspb.Message.setField(this, 25, value || []);
  }


  /**
   * @param {string} value
   * @param {number=} opt_index
   */
  addAllowedRoles(value: string, opt_index?: number): void {
    jspb.Message.addToRepeatedField(this, 25, value, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearAllowedRolesList() {
    this.setAllowedRolesList([]);
  }


  /**
   * optional bool requires_approval = 26;
   * @return {boolean}
   */
  getRequiresApproval(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 26, false));
  };


  /** @param {boolean} value */
  setRequiresApproval(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 26, value);
  }


  /**
   * optional uint64 timestamp = 28;
   * @return {number}
   */
  getTimestamp(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 28, 0));
  };


  /** @param {number} value */
  setTimestamp(value: number) {
    jspb.Message.setProto3IntField(this, 28, value);
  }


  /**
   * optional bool pinned = 100;
   * @return {boolean}
   */
  getPinned(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 100, false));
  };


  /** @param {boolean} value */
  setPinned(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 100, value);
  }


  /**
   * optional uint64 unpublish_timestamp = 101;
   * @return {number}
   */
  getUnpublishTimestamp(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 101, 0));
  };


  /** @param {number} value */
  setUnpublishTimestamp(value: number) {
    jspb.Message.setProto3IntField(this, 101, value);
  }


  /**
   * optional bool has_liked = 102;
   * @return {boolean}
   */
  getHasLiked(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 102, false));
  };


  /** @param {boolean} value */
  setHasLiked(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 102, value);
  }


}
export namespace LongSmsMessageCardView {
  export interface AsObject {
    contentHash: string;
    message: string;
    timestamp: number;
    authorPersonView?: gateway_person_view_pb.PersonViewMinimal.AsObject;
    ownerGroupHash: string;
    pinned: boolean;
    unpublishTimestamp: number;
  }
}
export class LongSmsMessageCardView extends jspb.Message {
  static readonly displayName = "LongSmsMessageCardView";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): LongSmsMessageCardView.AsObject {
    return LongSmsMessageCardView.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!LongSmsMessageCardView} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: LongSmsMessageCardView) {
    var f: any;
    var obj: any = {
      contentHash: jspb.Message.getFieldWithDefault(msg, 1, ""),
      message: jspb.Message.getFieldWithDefault(msg, 2, ""),
      timestamp: jspb.Message.getFieldWithDefault(msg, 3, 0),
      authorPersonView: (f = msg.getAuthorPersonView()) && gateway_person_view_pb.PersonViewMinimal.toObject(includeInstance, f),
      ownerGroupHash: jspb.Message.getFieldWithDefault(msg, 5, ""),
      pinned: jspb.Message.getBooleanFieldWithDefault(msg, 100, false),
      unpublishTimestamp: jspb.Message.getFieldWithDefault(msg, 101, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!LongSmsMessageCardView}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new LongSmsMessageCardView;
    return LongSmsMessageCardView.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!LongSmsMessageCardView} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!LongSmsMessageCardView}
   */
  static deserializeBinaryFromReader(msg: LongSmsMessageCardView, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setContentHash(value1);
        break;
      case 2:
        var value2 = /** @type {string} */ (reader.readString());
        msg.setMessage(value2);
        break;
      case 3:
        var value3 = /** @type {number} */ (reader.readInt64());
        msg.setTimestamp(value3);
        break;
      case 4:
        var value4 = new gateway_person_view_pb.PersonViewMinimal;
        reader.readMessage(value4,gateway_person_view_pb.PersonViewMinimal.deserializeBinaryFromReader);
        msg.setAuthorPersonView(value4);
        break;
      case 5:
        var value5 = /** @type {string} */ (reader.readString());
        msg.setOwnerGroupHash(value5);
        break;
      case 100:
        var value100 = /** @type {boolean} */ (reader.readBool());
        msg.setPinned(value100);
        break;
      case 101:
        var value101 = /** @type {number} */ (reader.readUint64());
        msg.setUnpublishTimestamp(value101);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!LongSmsMessageCardView} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: LongSmsMessageCardView, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getContentHash();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
    f = message.getMessage();
    if (f.length > 0) {
      writer.writeString(
        2,
        f
      );
    }
    f = message.getTimestamp();
    if (f !== 0) {
      writer.writeInt64(
        3,
        f
      );
    }
    f = message.getAuthorPersonView();
    if (f != null) {
      writer.writeMessage(
        4,
        f,
        gateway_person_view_pb.PersonViewMinimal.serializeBinaryToWriter
      );
    }
    f = message.getOwnerGroupHash();
    if (f.length > 0) {
      writer.writeString(
        5,
        f
      );
    }
    f = message.getPinned();
    if (f) {
      writer.writeBool(
        100,
        f
      );
    }
    f = message.getUnpublishTimestamp();
    if (f !== 0) {
      writer.writeUint64(
        101,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    LongSmsMessageCardView.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string content_hash = 1;
   * @return {string}
   */
  getContentHash(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setContentHash(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


  /**
   * optional string message = 2;
   * @return {string}
   */
  getMessage(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
  };


  /** @param {string} value */
  setMessage(value: string) {
    jspb.Message.setProto3StringField(this, 2, value);
  }


  /**
   * optional int64 timestamp = 3;
   * @return {number}
   */
  getTimestamp(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
  };


  /** @param {number} value */
  setTimestamp(value: number) {
    jspb.Message.setProto3IntField(this, 3, value);
  }


  /**
   * optional PersonViewMinimal author_person_view = 4;
   * @return {?PersonViewMinimal}
   */
  getAuthorPersonView(): gateway_person_view_pb.PersonViewMinimal {
    return /** @type{?PersonViewMinimal} */ (
      jspb.Message.getWrapperField(this, gateway_person_view_pb.PersonViewMinimal, 4));
  }


  /** @param {?PersonViewMinimal|undefined} value */
  setAuthorPersonView(value?: gateway_person_view_pb.PersonViewMinimal) {
    jspb.Message.setWrapperField(this, 4, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearAuthorPersonView() {
    this.setAuthorPersonView(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasAuthorPersonView(): boolean {
    return jspb.Message.getField(this, 4) != null;
  }


  /**
   * optional string owner_group_hash = 5;
   * @return {string}
   */
  getOwnerGroupHash(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
  };


  /** @param {string} value */
  setOwnerGroupHash(value: string) {
    jspb.Message.setProto3StringField(this, 5, value);
  }


  /**
   * optional bool pinned = 100;
   * @return {boolean}
   */
  getPinned(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 100, false));
  };


  /** @param {boolean} value */
  setPinned(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 100, value);
  }


  /**
   * optional uint64 unpublish_timestamp = 101;
   * @return {number}
   */
  getUnpublishTimestamp(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 101, 0));
  };


  /** @param {number} value */
  setUnpublishTimestamp(value: number) {
    jspb.Message.setProto3IntField(this, 101, value);
  }


}
export namespace ContentView {
  export interface AsObject {
    contentContextHash?: common_legacy_pb.UniqueHash.AsObject;
    contentHash?: common_legacy_pb.UniqueHash.AsObject;
    commentContextHash: string;
    longCard?: LongCardView.AsObject;
    longEventCard?: LongEventCardView.AsObject;
    longInspirationCard?: LongInspirationCardView.AsObject;
    longBirthdayCard?: LongBirthdayCardView.AsObject;
    longAnnouncementCard?: LongAnnouncementCardView.AsObject;
    longPollCard?: LongPollCardView.AsObject;
    longChallengeCard?: LongChallengeCardView.AsObject;
    longSmsMessageCard?: LongSmsMessageCardView.AsObject;
  }
}
export class ContentView extends jspb.Message {
  static readonly displayName = "ContentView";
  /**
   * Oneof group definitions for this message. Each group defines the field
   * numbers belonging to that group. When of these fields' value is set, all
   * other fields in the group are cleared. During deserialization, if multiple
   * fields are encountered for a group, only the last value seen will be kept.
   * @private {!Array<!Array<number>>}
   * @const
   */
  static readonly oneofGroups_ = [[3,4,5,7,8,9,10,11]];


  /**
   * @return {ContentView.ViewCase}
   */
  getViewCase() {
    return /** @type {ContentView.ViewCase} */(jspb.Message.computeOneofCase(this, ContentView.oneofGroups_[0]));
  }

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, ContentView.oneofGroups_);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): ContentView.AsObject {
    return ContentView.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!ContentView} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: ContentView) {
    var f: any;
    var obj: any = {
      contentContextHash: (f = msg.getContentContextHash()) && common_legacy_pb.UniqueHash.toObject(includeInstance, f),
      contentHash: (f = msg.getContentHash()) && common_legacy_pb.UniqueHash.toObject(includeInstance, f),
      commentContextHash: jspb.Message.getFieldWithDefault(msg, 6, ""),
      longCard: (f = msg.getLongCard()) && LongCardView.toObject(includeInstance, f),
      longEventCard: (f = msg.getLongEventCard()) && LongEventCardView.toObject(includeInstance, f),
      longInspirationCard: (f = msg.getLongInspirationCard()) && LongInspirationCardView.toObject(includeInstance, f),
      longBirthdayCard: (f = msg.getLongBirthdayCard()) && LongBirthdayCardView.toObject(includeInstance, f),
      longAnnouncementCard: (f = msg.getLongAnnouncementCard()) && LongAnnouncementCardView.toObject(includeInstance, f),
      longPollCard: (f = msg.getLongPollCard()) && LongPollCardView.toObject(includeInstance, f),
      longChallengeCard: (f = msg.getLongChallengeCard()) && LongChallengeCardView.toObject(includeInstance, f),
      longSmsMessageCard: (f = msg.getLongSmsMessageCard()) && LongSmsMessageCardView.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!ContentView}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new ContentView;
    return ContentView.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!ContentView} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!ContentView}
   */
  static deserializeBinaryFromReader(msg: ContentView, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new common_legacy_pb.UniqueHash;
        reader.readMessage(value1,common_legacy_pb.UniqueHash.deserializeBinaryFromReader);
        msg.setContentContextHash(value1);
        break;
      case 2:
        var value2 = new common_legacy_pb.UniqueHash;
        reader.readMessage(value2,common_legacy_pb.UniqueHash.deserializeBinaryFromReader);
        msg.setContentHash(value2);
        break;
      case 6:
        var value6 = /** @type {string} */ (reader.readString());
        msg.setCommentContextHash(value6);
        break;
      case 3:
        var value3 = new LongCardView;
        reader.readMessage(value3,LongCardView.deserializeBinaryFromReader);
        msg.setLongCard(value3);
        break;
      case 4:
        var value4 = new LongEventCardView;
        reader.readMessage(value4,LongEventCardView.deserializeBinaryFromReader);
        msg.setLongEventCard(value4);
        break;
      case 5:
        var value5 = new LongInspirationCardView;
        reader.readMessage(value5,LongInspirationCardView.deserializeBinaryFromReader);
        msg.setLongInspirationCard(value5);
        break;
      case 7:
        var value7 = new LongBirthdayCardView;
        reader.readMessage(value7,LongBirthdayCardView.deserializeBinaryFromReader);
        msg.setLongBirthdayCard(value7);
        break;
      case 8:
        var value8 = new LongAnnouncementCardView;
        reader.readMessage(value8,LongAnnouncementCardView.deserializeBinaryFromReader);
        msg.setLongAnnouncementCard(value8);
        break;
      case 9:
        var value9 = new LongPollCardView;
        reader.readMessage(value9,LongPollCardView.deserializeBinaryFromReader);
        msg.setLongPollCard(value9);
        break;
      case 10:
        var value10 = new LongChallengeCardView;
        reader.readMessage(value10,LongChallengeCardView.deserializeBinaryFromReader);
        msg.setLongChallengeCard(value10);
        break;
      case 11:
        var value11 = new LongSmsMessageCardView;
        reader.readMessage(value11,LongSmsMessageCardView.deserializeBinaryFromReader);
        msg.setLongSmsMessageCard(value11);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!ContentView} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: ContentView, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getContentContextHash();
    if (f != null) {
      writer.writeMessage(
        1,
        f,
        common_legacy_pb.UniqueHash.serializeBinaryToWriter
      );
    }
    f = message.getContentHash();
    if (f != null) {
      writer.writeMessage(
        2,
        f,
        common_legacy_pb.UniqueHash.serializeBinaryToWriter
      );
    }
    f = message.getCommentContextHash();
    if (f.length > 0) {
      writer.writeString(
        6,
        f
      );
    }
    f = message.getLongCard();
    if (f != null) {
      writer.writeMessage(
        3,
        f,
        LongCardView.serializeBinaryToWriter
      );
    }
    f = message.getLongEventCard();
    if (f != null) {
      writer.writeMessage(
        4,
        f,
        LongEventCardView.serializeBinaryToWriter
      );
    }
    f = message.getLongInspirationCard();
    if (f != null) {
      writer.writeMessage(
        5,
        f,
        LongInspirationCardView.serializeBinaryToWriter
      );
    }
    f = message.getLongBirthdayCard();
    if (f != null) {
      writer.writeMessage(
        7,
        f,
        LongBirthdayCardView.serializeBinaryToWriter
      );
    }
    f = message.getLongAnnouncementCard();
    if (f != null) {
      writer.writeMessage(
        8,
        f,
        LongAnnouncementCardView.serializeBinaryToWriter
      );
    }
    f = message.getLongPollCard();
    if (f != null) {
      writer.writeMessage(
        9,
        f,
        LongPollCardView.serializeBinaryToWriter
      );
    }
    f = message.getLongChallengeCard();
    if (f != null) {
      writer.writeMessage(
        10,
        f,
        LongChallengeCardView.serializeBinaryToWriter
      );
    }
    f = message.getLongSmsMessageCard();
    if (f != null) {
      writer.writeMessage(
        11,
        f,
        LongSmsMessageCardView.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    ContentView.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional UniqueHash content_context_hash = 1;
   * @return {?UniqueHash}
   */
  getContentContextHash(): common_legacy_pb.UniqueHash {
    return /** @type{?UniqueHash} */ (
      jspb.Message.getWrapperField(this, common_legacy_pb.UniqueHash, 1));
  }


  /** @param {?UniqueHash|undefined} value */
  setContentContextHash(value?: common_legacy_pb.UniqueHash) {
    jspb.Message.setWrapperField(this, 1, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearContentContextHash() {
    this.setContentContextHash(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasContentContextHash(): boolean {
    return jspb.Message.getField(this, 1) != null;
  }


  /**
   * optional UniqueHash content_hash = 2;
   * @return {?UniqueHash}
   */
  getContentHash(): common_legacy_pb.UniqueHash {
    return /** @type{?UniqueHash} */ (
      jspb.Message.getWrapperField(this, common_legacy_pb.UniqueHash, 2));
  }


  /** @param {?UniqueHash|undefined} value */
  setContentHash(value?: common_legacy_pb.UniqueHash) {
    jspb.Message.setWrapperField(this, 2, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearContentHash() {
    this.setContentHash(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasContentHash(): boolean {
    return jspb.Message.getField(this, 2) != null;
  }


  /**
   * optional string comment_context_hash = 6;
   * @return {string}
   */
  getCommentContextHash(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
  };


  /** @param {string} value */
  setCommentContextHash(value: string) {
    jspb.Message.setProto3StringField(this, 6, value);
  }


  /**
   * optional LongCardView long_card = 3;
   * @return {?LongCardView}
   */
  getLongCard(): LongCardView {
    return /** @type{?LongCardView} */ (
      jspb.Message.getWrapperField(this, LongCardView, 3));
  }


  /** @param {?LongCardView|undefined} value */
  setLongCard(value?: LongCardView) {
    jspb.Message.setOneofWrapperField(this, 3, ContentView.oneofGroups_[0], value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearLongCard() {
    this.setLongCard(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasLongCard(): boolean {
    return jspb.Message.getField(this, 3) != null;
  }


  /**
   * optional LongEventCardView long_event_card = 4;
   * @return {?LongEventCardView}
   */
  getLongEventCard(): LongEventCardView {
    return /** @type{?LongEventCardView} */ (
      jspb.Message.getWrapperField(this, LongEventCardView, 4));
  }


  /** @param {?LongEventCardView|undefined} value */
  setLongEventCard(value?: LongEventCardView) {
    jspb.Message.setOneofWrapperField(this, 4, ContentView.oneofGroups_[0], value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearLongEventCard() {
    this.setLongEventCard(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasLongEventCard(): boolean {
    return jspb.Message.getField(this, 4) != null;
  }


  /**
   * optional LongInspirationCardView long_inspiration_card = 5;
   * @return {?LongInspirationCardView}
   */
  getLongInspirationCard(): LongInspirationCardView {
    return /** @type{?LongInspirationCardView} */ (
      jspb.Message.getWrapperField(this, LongInspirationCardView, 5));
  }


  /** @param {?LongInspirationCardView|undefined} value */
  setLongInspirationCard(value?: LongInspirationCardView) {
    jspb.Message.setOneofWrapperField(this, 5, ContentView.oneofGroups_[0], value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearLongInspirationCard() {
    this.setLongInspirationCard(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasLongInspirationCard(): boolean {
    return jspb.Message.getField(this, 5) != null;
  }


  /**
   * optional LongBirthdayCardView long_birthday_card = 7;
   * @return {?LongBirthdayCardView}
   */
  getLongBirthdayCard(): LongBirthdayCardView {
    return /** @type{?LongBirthdayCardView} */ (
      jspb.Message.getWrapperField(this, LongBirthdayCardView, 7));
  }


  /** @param {?LongBirthdayCardView|undefined} value */
  setLongBirthdayCard(value?: LongBirthdayCardView) {
    jspb.Message.setOneofWrapperField(this, 7, ContentView.oneofGroups_[0], value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearLongBirthdayCard() {
    this.setLongBirthdayCard(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasLongBirthdayCard(): boolean {
    return jspb.Message.getField(this, 7) != null;
  }


  /**
   * optional LongAnnouncementCardView long_announcement_card = 8;
   * @return {?LongAnnouncementCardView}
   */
  getLongAnnouncementCard(): LongAnnouncementCardView {
    return /** @type{?LongAnnouncementCardView} */ (
      jspb.Message.getWrapperField(this, LongAnnouncementCardView, 8));
  }


  /** @param {?LongAnnouncementCardView|undefined} value */
  setLongAnnouncementCard(value?: LongAnnouncementCardView) {
    jspb.Message.setOneofWrapperField(this, 8, ContentView.oneofGroups_[0], value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearLongAnnouncementCard() {
    this.setLongAnnouncementCard(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasLongAnnouncementCard(): boolean {
    return jspb.Message.getField(this, 8) != null;
  }


  /**
   * optional LongPollCardView long_poll_card = 9;
   * @return {?LongPollCardView}
   */
  getLongPollCard(): LongPollCardView {
    return /** @type{?LongPollCardView} */ (
      jspb.Message.getWrapperField(this, LongPollCardView, 9));
  }


  /** @param {?LongPollCardView|undefined} value */
  setLongPollCard(value?: LongPollCardView) {
    jspb.Message.setOneofWrapperField(this, 9, ContentView.oneofGroups_[0], value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearLongPollCard() {
    this.setLongPollCard(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasLongPollCard(): boolean {
    return jspb.Message.getField(this, 9) != null;
  }


  /**
   * optional LongChallengeCardView long_challenge_card = 10;
   * @return {?LongChallengeCardView}
   */
  getLongChallengeCard(): LongChallengeCardView {
    return /** @type{?LongChallengeCardView} */ (
      jspb.Message.getWrapperField(this, LongChallengeCardView, 10));
  }


  /** @param {?LongChallengeCardView|undefined} value */
  setLongChallengeCard(value?: LongChallengeCardView) {
    jspb.Message.setOneofWrapperField(this, 10, ContentView.oneofGroups_[0], value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearLongChallengeCard() {
    this.setLongChallengeCard(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasLongChallengeCard(): boolean {
    return jspb.Message.getField(this, 10) != null;
  }


  /**
   * optional LongSmsMessageCardView long_sms_message_card = 11;
   * @return {?LongSmsMessageCardView}
   */
  getLongSmsMessageCard(): LongSmsMessageCardView {
    return /** @type{?LongSmsMessageCardView} */ (
      jspb.Message.getWrapperField(this, LongSmsMessageCardView, 11));
  }


  /** @param {?LongSmsMessageCardView|undefined} value */
  setLongSmsMessageCard(value?: LongSmsMessageCardView) {
    jspb.Message.setOneofWrapperField(this, 11, ContentView.oneofGroups_[0], value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearLongSmsMessageCard() {
    this.setLongSmsMessageCard(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasLongSmsMessageCard(): boolean {
    return jspb.Message.getField(this, 11) != null;
  }


}
export namespace ContentView {
export enum ViewCase {
  VIEW_NOT_SET = 0,
  LONG_CARD = 3,
  LONG_EVENT_CARD = 4,
  LONG_INSPIRATION_CARD = 5,
  LONG_BIRTHDAY_CARD = 7,
  LONG_ANNOUNCEMENT_CARD = 8,
  LONG_POLL_CARD = 9,
  LONG_CHALLENGE_CARD = 10,
  LONG_SMS_MESSAGE_CARD = 11,
}
} // namespace ContentView
export namespace LoadContentRequest {
  export interface AsObject {
    contentContextHashList: common_legacy_pb.UniqueHash.AsObject[];
    contentHashList: common_legacy_pb.UniqueHash.AsObject[];
    programContentHash?: common_legacy_pb.UniqueHash.AsObject;
  }
}
export class LoadContentRequest extends jspb.Message {
  static readonly displayName = "LoadContentRequest";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [1,2];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, LoadContentRequest.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): LoadContentRequest.AsObject {
    return LoadContentRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!LoadContentRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: LoadContentRequest) {
    var f: any;
    var obj: any = {
      contentContextHashList: jspb.Message.toObjectList(msg.getContentContextHashList(),
      common_legacy_pb.UniqueHash.toObject, includeInstance),
      contentHashList: jspb.Message.toObjectList(msg.getContentHashList(),
      common_legacy_pb.UniqueHash.toObject, includeInstance),
      programContentHash: (f = msg.getProgramContentHash()) && common_legacy_pb.UniqueHash.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!LoadContentRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new LoadContentRequest;
    return LoadContentRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!LoadContentRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!LoadContentRequest}
   */
  static deserializeBinaryFromReader(msg: LoadContentRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new common_legacy_pb.UniqueHash;
        reader.readMessage(value1,common_legacy_pb.UniqueHash.deserializeBinaryFromReader);
        msg.addContentContextHash(value1);
        break;
      case 2:
        var value2 = new common_legacy_pb.UniqueHash;
        reader.readMessage(value2,common_legacy_pb.UniqueHash.deserializeBinaryFromReader);
        msg.addContentHash(value2);
        break;
      case 3:
        var value3 = new common_legacy_pb.UniqueHash;
        reader.readMessage(value3,common_legacy_pb.UniqueHash.deserializeBinaryFromReader);
        msg.setProgramContentHash(value3);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!LoadContentRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: LoadContentRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getContentContextHashList();
    if (f.length > 0) {
      writer.writeRepeatedMessage(
        1,
        f,
        common_legacy_pb.UniqueHash.serializeBinaryToWriter
      );
    }
    f = message.getContentHashList();
    if (f.length > 0) {
      writer.writeRepeatedMessage(
        2,
        f,
        common_legacy_pb.UniqueHash.serializeBinaryToWriter
      );
    }
    f = message.getProgramContentHash();
    if (f != null) {
      writer.writeMessage(
        3,
        f,
        common_legacy_pb.UniqueHash.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    LoadContentRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * repeated UniqueHash content_context_hash = 1;
   * @return {!Array<!UniqueHash>}
   */
  getContentContextHashList(): common_legacy_pb.UniqueHash[] {
    return /** @type{!Array<!UniqueHash>} */ (
      jspb.Message.getRepeatedWrapperField(this, common_legacy_pb.UniqueHash, 1));
  }


  /** @param {!Array<!UniqueHash>} value */
  setContentContextHashList(value?: common_legacy_pb.UniqueHash[]) {
    jspb.Message.setRepeatedWrapperField(this, 1, value);
  }


  /**
   * @param {!UniqueHash=} opt_value
   * @param {number=} opt_index
   * @return {!UniqueHash}
   */
  addContentContextHash(opt_value?: common_legacy_pb.UniqueHash, opt_index?: number): common_legacy_pb.UniqueHash {
    return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, common_legacy_pb.UniqueHash, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearContentContextHashList() {
    this.setContentContextHashList([]);
  }


  /**
   * repeated UniqueHash content_hash = 2;
   * @return {!Array<!UniqueHash>}
   */
  getContentHashList(): common_legacy_pb.UniqueHash[] {
    return /** @type{!Array<!UniqueHash>} */ (
      jspb.Message.getRepeatedWrapperField(this, common_legacy_pb.UniqueHash, 2));
  }


  /** @param {!Array<!UniqueHash>} value */
  setContentHashList(value?: common_legacy_pb.UniqueHash[]) {
    jspb.Message.setRepeatedWrapperField(this, 2, value);
  }


  /**
   * @param {!UniqueHash=} opt_value
   * @param {number=} opt_index
   * @return {!UniqueHash}
   */
  addContentHash(opt_value?: common_legacy_pb.UniqueHash, opt_index?: number): common_legacy_pb.UniqueHash {
    return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, common_legacy_pb.UniqueHash, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearContentHashList() {
    this.setContentHashList([]);
  }


  /**
   * optional UniqueHash program_content_hash = 3;
   * @return {?UniqueHash}
   */
  getProgramContentHash(): common_legacy_pb.UniqueHash {
    return /** @type{?UniqueHash} */ (
      jspb.Message.getWrapperField(this, common_legacy_pb.UniqueHash, 3));
  }


  /** @param {?UniqueHash|undefined} value */
  setProgramContentHash(value?: common_legacy_pb.UniqueHash) {
    jspb.Message.setWrapperField(this, 3, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearProgramContentHash() {
    this.setProgramContentHash(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasProgramContentHash(): boolean {
    return jspb.Message.getField(this, 3) != null;
  }


}
export namespace LoadContentResponse {
  export interface AsObject {
    status?: common_legacy_pb.StatusCode;
    reasonList: common_legacy_pb.StatusReason.AsObject[];
    contentList: ContentView.AsObject[];
  }
}
export class LoadContentResponse extends jspb.Message {
  static readonly displayName = "LoadContentResponse";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [2,3];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, LoadContentResponse.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): LoadContentResponse.AsObject {
    return LoadContentResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!LoadContentResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: LoadContentResponse) {
    var f: any;
    var obj: any = {
      status: jspb.Message.getFieldWithDefault(msg, 1, 0),
      reasonList: jspb.Message.toObjectList(msg.getReasonList(),
      common_legacy_pb.StatusReason.toObject, includeInstance),
      contentList: jspb.Message.toObjectList(msg.getContentList(),
      ContentView.toObject, includeInstance)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!LoadContentResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new LoadContentResponse;
    return LoadContentResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!LoadContentResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!LoadContentResponse}
   */
  static deserializeBinaryFromReader(msg: LoadContentResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {!StatusCode} */ (reader.readEnum());
        msg.setStatus(value1);
        break;
      case 2:
        var value2 = new common_legacy_pb.StatusReason;
        reader.readMessage(value2,common_legacy_pb.StatusReason.deserializeBinaryFromReader);
        msg.addReason(value2);
        break;
      case 3:
        var value3 = new ContentView;
        reader.readMessage(value3,ContentView.deserializeBinaryFromReader);
        msg.addContent(value3);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!LoadContentResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: LoadContentResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getStatus();
    if (f !== 0.0) {
      writer.writeEnum(
        1,
        f
      );
    }
    f = message.getReasonList();
    if (f.length > 0) {
      writer.writeRepeatedMessage(
        2,
        f,
        common_legacy_pb.StatusReason.serializeBinaryToWriter
      );
    }
    f = message.getContentList();
    if (f.length > 0) {
      writer.writeRepeatedMessage(
        3,
        f,
        ContentView.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    LoadContentResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional StatusCode status = 1;
   * @return {!StatusCode}
   */
  getStatus(): common_legacy_pb.StatusCode {
    return /** @type {!StatusCode} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
  };


  /** @param {!StatusCode} value */
  setStatus(value: common_legacy_pb.StatusCode) {
    jspb.Message.setProto3EnumField(this, 1, value);
  }


  /**
   * repeated StatusReason reason = 2;
   * @return {!Array<!StatusReason>}
   */
  getReasonList(): common_legacy_pb.StatusReason[] {
    return /** @type{!Array<!StatusReason>} */ (
      jspb.Message.getRepeatedWrapperField(this, common_legacy_pb.StatusReason, 2));
  }


  /** @param {!Array<!StatusReason>} value */
  setReasonList(value?: common_legacy_pb.StatusReason[]) {
    jspb.Message.setRepeatedWrapperField(this, 2, value);
  }


  /**
   * @param {!StatusReason=} opt_value
   * @param {number=} opt_index
   * @return {!StatusReason}
   */
  addReason(opt_value?: common_legacy_pb.StatusReason, opt_index?: number): common_legacy_pb.StatusReason {
    return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, common_legacy_pb.StatusReason, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearReasonList() {
    this.setReasonList([]);
  }


  /**
   * repeated ContentView content = 3;
   * @return {!Array<!ContentView>}
   */
  getContentList(): ContentView[] {
    return /** @type{!Array<!ContentView>} */ (
      jspb.Message.getRepeatedWrapperField(this, ContentView, 3));
  }


  /** @param {!Array<!ContentView>} value */
  setContentList(value?: ContentView[]) {
    jspb.Message.setRepeatedWrapperField(this, 3, value);
  }


  /**
   * @param {!ContentView=} opt_value
   * @param {number=} opt_index
   * @return {!ContentView}
   */
  addContent(opt_value?: ContentView, opt_index?: number): ContentView {
    return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, ContentView, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearContentList() {
    this.setContentList([]);
  }


}
export enum PollLayout {
  PL_LIST = 0,
  PL_GRID = 1,
}
