// GENERATED CODE -- DO NOT EDIT!

import {grpc} from '@improbable-eng/grpc-web';

import * as stats_report_stats_report_pb from '../stats_report/stats_report_pb';
import * as hall_pass_hall_pass_pb from '../hall_pass/hall_pass_pb';
import * as hall_pass_stats_hall_pass_stats_pb from '../hall_pass_stats/hall_pass_stats_pb';
import * as checkin_checkin_pb from '../checkin/checkin_pb';
import * as pbis_pbis_pb from '../pbis/pbis_pb';
import * as pbis_stats_pbis_stats_pb from '../pbis_stats/pbis_stats_pb';
import * as points_points_pb from '../points/points_pb';
import * as flex_time_stats_flex_time_stats_pb from '../flex_time_stats/flex_time_stats_pb';
import * as common_date_pb from '../common/date_pb';
import * as content_event_pb from '../content/event_pb';

export class StatsReportManager {
  static readonly serviceName = "minga.stats_report.StatsReportManager";

  static readonly GetReport = {
    methodName: "GetReport",
    service: StatsReportManager,
    requestStream: false,
    responseStream: false,
    requestType: stats_report_stats_report_pb.GetOrExportReportRequest,
    responseType: stats_report_stats_report_pb.GetReportResponse,
  };

  static readonly ExportReport = {
    methodName: "ExportReport",
    service: StatsReportManager,
    requestStream: false,
    responseStream: true,
    requestType: stats_report_stats_report_pb.GetOrExportReportRequest,
    responseType: stats_report_stats_report_pb.ExportReportChunk,
  };

  static readonly ListScheduledReports = {
    methodName: "ListScheduledReports",
    service: StatsReportManager,
    requestStream: false,
    responseStream: false,
    requestType: stats_report_stats_report_pb.ListScheduledReportsRequest,
    responseType: stats_report_stats_report_pb.ListScheduledReportsResponse,
  };

  static readonly GetScheduledReport = {
    methodName: "GetScheduledReport",
    service: StatsReportManager,
    requestStream: false,
    responseStream: false,
    requestType: stats_report_stats_report_pb.GetScheduledReportRequest,
    responseType: stats_report_stats_report_pb.GetScheduledReportResponse,
  };

  static readonly UpsertScheduledReport = {
    methodName: "UpsertScheduledReport",
    service: StatsReportManager,
    requestStream: false,
    responseStream: false,
    requestType: stats_report_stats_report_pb.UpsertScheduledReportRequest,
    responseType: stats_report_stats_report_pb.UpsertScheduledReportResponse,
  };

  static readonly DeleteScheduledReport = {
    methodName: "DeleteScheduledReport",
    service: StatsReportManager,
    requestStream: false,
    responseStream: false,
    requestType: stats_report_stats_report_pb.DeleteScheduledReportRequest,
    responseType: stats_report_stats_report_pb.DeleteScheduledReportResponse,
  };

  static readonly ActivateScheduledReport = {
    methodName: "ActivateScheduledReport",
    service: StatsReportManager,
    requestStream: false,
    responseStream: false,
    requestType: stats_report_stats_report_pb.ActivateScheduledReportRequest,
    responseType: stats_report_stats_report_pb.ActivateScheduledReportResponse,
  };

  static readonly ListPastReports = {
    methodName: "ListPastReports",
    service: StatsReportManager,
    requestStream: false,
    responseStream: false,
    requestType: stats_report_stats_report_pb.ListPastReportsRequest,
    responseType: stats_report_stats_report_pb.ListPastReportsResponse,
  };

  static readonly DownloadPastReport = {
    methodName: "DownloadPastReport",
    service: StatsReportManager,
    requestStream: false,
    responseStream: false,
    requestType: stats_report_stats_report_pb.DownloadPastReportRequest,
    responseType: stats_report_stats_report_pb.DownloadPastReportResponse,
  };

  static readonly GetPastReport = {
    methodName: "GetPastReport",
    service: StatsReportManager,
    requestStream: false,
    responseStream: false,
    requestType: stats_report_stats_report_pb.GetPastReportRequest,
    responseType: stats_report_stats_report_pb.GetPastReportResponse,
  };

}

export class StatsReportManagerClient {
  getReport() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  exportReport() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  listScheduledReports() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  getScheduledReport() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  upsertScheduledReport() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  deleteScheduledReport() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  activateScheduledReport() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  listPastReports() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  downloadPastReport() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  getPastReport() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
}
