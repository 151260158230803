import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { CountryModule } from '@app/src/app/components/Country';
import { CountrySelectorModule } from '@app/src/app/components/CountrySelector';
import { PinModule } from '@app/src/app/components/Input/Pin';
import { MgMessageBoxModule } from '@app/src/app/components/MessageBox';
import { SsoUiModule } from '@app/src/app/components/SsoUi';
import { MgAvatarModule } from '@app/src/app/elements/MgAvatar';
import { MgPersonMinimalModule } from '@app/src/app/elements/MgPersonMinimal';
import { SilhLandingLayoutModule } from '@app/src/app/layout/SilhLandingLayout';

import { KioskUiModule } from '@modules/kiosk/kiosk-ui/kiosk-ui.module';

import { CollapsibleModule } from '@shared/components/collapsible';
import { KioskNonAuthedGuard } from '@shared/guards';
import { CommonFormModules, CommonUIModules } from '@shared/modules';

import { LandingCountryComponent } from './components/landing-country/landing-country.component';
import { LandingCreateComponent } from './components/landing-create/landing-create.component';
import { LandingEmailComponent } from './components/landing-email/landing-email.component';
import { LandingForgotComponent } from './components/landing-forgot/landing-forgot.component';
import { LandingJoiningComponent } from './components/landing-joining/landing-joining.component';
import { LandingKioskLoginComponent } from './components/landing-kiosk-login/landing-kiosk-login.component';
import { LandingPasswordComponent } from './components/landing-password/landing-password.component';
import { LandingPinComponent } from './components/landing-pin/landing-pin.component';
import { LandingResetComponent } from './components/landing-reset/landing-reset.component';
import { LandingHasEmail, LandingHasEmailOrPin, LandingHasPin } from './guards';
import { LandingComponent } from './landing.component';
import { LANDING_ROUTES } from './landing.routes';

@NgModule({
  imports: [
    // Routing
    RouterModule.forChild(LANDING_ROUTES),

    // Minga dependencies
    CommonUIModules,
    CommonFormModules,
    MgAvatarModule,
    MgPersonMinimalModule,
    CountryModule,
    SsoUiModule,
    MgMessageBoxModule,
    CountrySelectorModule,
    PinModule,
    SilhLandingLayoutModule,
    KioskUiModule,
    CollapsibleModule,

    // External dependencies
    CommonModule,
    RouterModule,
  ],
  declarations: [
    // Container
    LandingComponent,

    // Subpages
    LandingCountryComponent,
    LandingCreateComponent,
    LandingEmailComponent,
    LandingForgotComponent,
    LandingJoiningComponent,
    LandingPasswordComponent,
    LandingPinComponent,
    LandingResetComponent,
    LandingKioskLoginComponent,
  ],
  exports: [RouterModule],
  providers: [
    LandingHasEmail,
    LandingHasPin,
    LandingHasEmailOrPin,
    KioskNonAuthedGuard,
  ],
})
export class LandingModule {}
