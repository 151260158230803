import {
  EditableConsequence,
  ExistingConsequenceType,
  IConsequence,
  OverdueConsequenceActionType,
  UpdateConsequences,
} from 'libs/domain';
import { PersonViewMinimal, pbis_pb } from 'libs/generated-grpc-ts';

import {
  asObjectToMessage,
  dateObjectToDateTimeMessage,
  dateTimeMessageToDateObject,
  dateTimeMessageToDayjsObject,
  dayJsObjectToDateTimeMsg,
} from '../';
import { ConsequenceTypeMapper } from './consequence-type.mappers.proto';

export namespace ConsequenceMapper {
  export const toProto = (consequence: IConsequence): pbis_pb.Consequence => {
    const msg = new pbis_pb.Consequence();
    msg.setId(consequence.id);
    msg.setTypeId(consequence.typeId);
    msg.setIssuedAt(dateObjectToDateTimeMessage(consequence.issuedAt));
    if (consequence.issuedBy) msg.setIssuedBy(consequence.issuedBy);
    msg.setIssuedTo(consequence.issuedTo);

    if (consequence.type) {
      msg.setType(
        ConsequenceTypeMapper.toProto(
          consequence.type as ExistingConsequenceType,
        ),
      );
    }

    if (consequence.status) {
      msg.setStatus(consequence.status);
    }

    if (consequence.completedAt) {
      msg.setCompletedAt(dateObjectToDateTimeMessage(consequence.completedAt));
    }

    if (consequence.completeBy) {
      msg.setCompleteBy(dateObjectToDateTimeMessage(consequence.completeBy));
    }

    if (consequence.issuedByPerson) {
      msg.setIssuedByPerson(
        asObjectToMessage(consequence.issuedByPerson, new PersonViewMinimal()),
      );
    }

    if (consequence.issuedToPerson) {
      msg.setIssuedToPerson(
        asObjectToMessage(consequence.issuedToPerson, new PersonViewMinimal()),
      );
    }

    if (consequence.note) msg.setNotes(consequence.note);

    msg.setComplete(consequence.complete || false);

    if (consequence.imported) msg.setImported(consequence.imported);
    if (consequence.automationName)
      msg.setAutomationName(consequence.automationName);

    if (consequence.sourceId) msg.setSourceId(consequence.sourceId);

    if (consequence.handledByOverdueAction)
      msg.setOverdueActionType(consequence.handledByOverdueAction);

    if (consequence.actionName) msg.setActionName(consequence.actionName);

    if (consequence.completedBy) msg.setCompletedBy(consequence.completedBy);

    if (consequence.completedByPerson) {
      msg.setCompletedByPerson(
        asObjectToMessage(
          consequence.completedByPerson,
          new PersonViewMinimal(),
        ),
      );
    }

    if (consequence.sourceOverdueActionType)
      msg.setSourceOverdueActionType(consequence.sourceOverdueActionType);
    return msg;
  };

  export const toProtoEditable = (
    cons: EditableConsequence,
  ): pbis_pb.EditableConsequence => {
    const msg = toProto(cons);
    const editable = new pbis_pb.EditableConsequence();
    editable.setConsequence(msg);
    if (cons.manuallyUpdatedAt)
      editable.setManuallyUpdatedAt(
        dateObjectToDateTimeMessage(cons.manuallyUpdatedAt),
      );
    return editable;
  };

  export const fromProto = (consequence: pbis_pb.Consequence): IConsequence => {
    const isOverdueActionType = Object.values(
      OverdueConsequenceActionType,
    ).includes(consequence.getOverdueActionType() as any);
    const overdueActionType = isOverdueActionType
      ? (consequence.getOverdueActionType() as OverdueConsequenceActionType)
      : undefined;

    return {
      id: consequence.getId(),
      typeId: consequence.getTypeId(),
      type: consequence.getType()
        ? ConsequenceTypeMapper.fromProto(consequence.getType())
        : undefined,
      issuedAt: dateTimeMessageToDateObject(consequence.getIssuedAt()),
      issuedTo: consequence.getIssuedTo(),
      recipientId: consequence.getIssuedTo(),
      issuedBy: consequence.getIssuedBy(),
      completeBy: consequence.getCompleteBy()
        ? dateTimeMessageToDateObject(consequence.getCompleteBy())
        : undefined,
      completedAt: consequence.getCompletedAt()
        ? dateTimeMessageToDateObject(consequence.getCompletedAt())
        : undefined,
      status: consequence.getStatus(),
      issuedByPerson: consequence.getIssuedByPerson()?.toObject(),
      issuedToPerson: consequence.getIssuedToPerson()?.toObject(),
      complete: consequence.getComplete(),
      imported: consequence.getImported(),
      automationName: consequence.getAutomationName(),
      note: consequence.getNotes(),
      sourceId: consequence.getSourceId(),
      handledByOverdueAction: overdueActionType,
      actionName: consequence.getActionName(),
      completedBy: consequence.getCompletedBy(),
      completedByPerson: consequence.getCompletedByPerson()?.toObject(),
      sourceOverdueActionType: consequence.getSourceOverdueActionType(),
    };
  };

  export const fromProtoEditable = (
    msg: pbis_pb.EditableConsequence,
  ): EditableConsequence => {
    const cons = fromProto(msg.getConsequence());
    const manuallyUpdatedAt = msg.getManuallyUpdatedAt()
      ? dateTimeMessageToDateObject(msg.getManuallyUpdatedAt())
      : undefined;
    return { ...cons, manuallyUpdatedAt };
  };
}

export namespace UpdateConsequenceMapper {
  export const toProto = (
    consequence: UpdateConsequences,
  ): pbis_pb.UpdateConsequences => {
    const msg = new pbis_pb.UpdateConsequences();

    msg.setIssuedToList(consequence.issuedTo);

    if (consequence.id) msg.setId(consequence.id);
    if (consequence.type) msg.setType(consequence.type);
    if (consequence.notes) msg.setNotes(consequence.notes);
    if (consequence.status) msg.setStatus(consequence.status);

    if (consequence.completeBy) {
      msg.setCompleteBy(dayJsObjectToDateTimeMsg(consequence.completeBy));
    }

    if (consequence.completedAt) {
      msg.setCompletedAt(dayJsObjectToDateTimeMsg(consequence.completedAt));
    }

    return msg;
  };

  export const fromProto = (
    msg: pbis_pb.UpdateConsequences,
    tz?: string,
  ): UpdateConsequences => {
    const consequence: UpdateConsequences = {
      id: msg.getId(),
      type: msg.getType(),
      status: msg.getStatus(),
      issuedTo: msg.getIssuedToList(),
      issuedBy: msg.getIssuedBy(),
      notes: msg.getNotes(),
    };

    if (msg.getCompleteBy()) {
      consequence.completeBy = dateTimeMessageToDayjsObject(
        msg.getCompleteBy(),
        tz,
      );
    }

    if (msg.getCompletedAt()) {
      consequence.completedAt = dateTimeMessageToDayjsObject(
        msg.getCompletedAt(),
        tz,
      );
    }

    return consequence;
  };
}
