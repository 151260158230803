import { Injectable, isDevMode } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

import { MingaRoleType } from 'libs/domain';
import { badgeRoleNameToMingaRoleType } from 'libs/shared';
import { Userpilot } from 'userpilot';

import { AppConfigService } from '@app/src/app/minimal/services/AppConfig';
import { AuthInfoService } from '@app/src/app/minimal/services/AuthInfo';
import { MingaStoreFacadeService } from '@app/src/app/store/Minga/services';

import { SystemAlertSnackBarService } from '@shared/components/system-alert-snackbar';
import { UserPilotMingaInfoOptions } from '@shared/types/UserPilotMingaInfo';

export enum UserpilotEventType {
  VISITED_SIS = 'visited sis page',
}

@Injectable({
  providedIn: 'root',
})
export class UserpilotService {
  // TODO: This should be passed in as a dependency. This is a side-effect we can't control.
  private _isDisabled = isDevMode();

  constructor(
    private _router: Router,
    private _systemAlertSnackBar: SystemAlertSnackBarService,
    private _authInfoService: AuthInfoService,
    private _mingaStore: MingaStoreFacadeService,
    private _appConfig: AppConfigService,
  ) {
    // reload userpilot
    this._router.events.subscribe(event => {
      try {
        if (event instanceof NavigationEnd) {
          if (!this._isDisabled) {
            const origin = this._appConfig.getAppOrigin();
            Userpilot.reload(origin + event.url);
          }
        }
      } catch (err) {
        console.error(err);
      }
    });
  }

  // TODO: Pull user pilot key from environment variable. Security risk here.
  public initialize() {
    if (this._isDisabled) return;
    Userpilot.initialize('NX-124c0295');
  }

  public async identify(options?: UserPilotMingaInfoOptions) {
    if (this._isDisabled) return;

    const person = this._authInfoService.authPerson;
    const roleType = badgeRoleNameToMingaRoleType(person.badgeRoleName);
    const mingaInfo = await this._mingaStore.getMingaAsPromise();
    const sisSettings = mingaInfo.sisSettings;
    const environmentName = await this._appConfig.getEnvironment();

    /**
     * We don't want to use UserPilot to identify or show any popups if a user or minga is
     * related to E2E testing on the staging environment. This is a temporary fix while we
     * are running these tests on staging.
     */
    const isStaging = environmentName === 'stage';
    const isTestMinga = mingaInfo.name.startsWith('E2E-');
    const isTestUser =
      person.email.includes('test+') && person.email.includes('@minga.io');
    const isTestingAccount = isStaging && (isTestUser || isTestMinga);

    // This is a temporary fix while we are running E2E tests on staging.
    if (isTestingAccount) {
      console.log('E2E testing account detected, skipping Userpilot identify.');
      return;
    }

    let userId = person.hash;
    const identityInfo: any = {
      role: person.badgeRoleName,
      company: {
        id: options?.hash || mingaInfo.hash,
        name: options?.name || mingaInfo.name,
        hubspotId: mingaInfo.hubspotId || '',
        districtId: mingaInfo.districtId || '',
        // @TODO: remove sis_type because it's redundant
        sisType: options?.sisType || mingaInfo.sisType,
        ...(options?.featureToggle || mingaInfo.featureToggle),
      },
      login_count: person.loginCount,
      created_at: person.dateCreated,
    };

    if (
      roleType != MingaRoleType.STUDENT &&
      roleType != MingaRoleType.STUDENT_LEADER &&
      roleType != MingaRoleType.PARENT
    ) {
      identityInfo.first_name = person.firstName;
      identityInfo.last_name = person.lastName;
      identityInfo.email = person.email;
      if (roleType === MingaRoleType.OWNER) {
        identityInfo.company.sisUpdateExistingUsers =
          options?.sisUsersExisting || sisSettings?.updateExisting;
        identityInfo.company.sisArchiveMissingUsers =
          options?.sisUsersMissing || sisSettings?.archiveMissing;
      }
    } else {
      // create a separate user for each role per minga
      const prefix = mingaInfo.hash.substring(0, 7);
      userId = prefix + '-' + identityInfo.role;
    }

    try {
      Userpilot.identify(userId, identityInfo);
    } catch (e) {
      console.error('Problem identifying user for Userpilot');
      this._systemAlertSnackBar.open({
        type: 'error',
        message: `Userpilot failed to initialize: ${e}`,
      });
    }
  }

  public openResourceCenter() {
    Userpilot.trigger('resource_center:qDxBnNAgOc');
  }

  public track(event: UserpilotEventType) {}
}
