// source: content/video_upload/video_upload.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

import * as jspb from 'google-protobuf';
import * as common_delta_pb from '../../common/delta_pb';
export namespace VideoUploadInfo {
  export interface AsObject {
    videoThumbnail: string;
    cloudflareUid: string;
    cloudflareSignedToken: string;
  }
}
export class VideoUploadInfo extends jspb.Message {
  static readonly displayName = "VideoUploadInfo";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): VideoUploadInfo.AsObject {
    return VideoUploadInfo.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!VideoUploadInfo} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: VideoUploadInfo) {
    var f: any;
    var obj: any = {
      videoThumbnail: jspb.Message.getFieldWithDefault(msg, 1, ""),
      cloudflareUid: jspb.Message.getFieldWithDefault(msg, 2, ""),
      cloudflareSignedToken: jspb.Message.getFieldWithDefault(msg, 3, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!VideoUploadInfo}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new VideoUploadInfo;
    return VideoUploadInfo.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!VideoUploadInfo} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!VideoUploadInfo}
   */
  static deserializeBinaryFromReader(msg: VideoUploadInfo, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setVideoThumbnail(value1);
        break;
      case 2:
        var value2 = /** @type {string} */ (reader.readString());
        msg.setCloudflareUid(value2);
        break;
      case 3:
        var value3 = /** @type {string} */ (reader.readString());
        msg.setCloudflareSignedToken(value3);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!VideoUploadInfo} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: VideoUploadInfo, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getVideoThumbnail();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
    f = message.getCloudflareUid();
    if (f.length > 0) {
      writer.writeString(
        2,
        f
      );
    }
    f = message.getCloudflareSignedToken();
    if (f.length > 0) {
      writer.writeString(
        3,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    VideoUploadInfo.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string video_thumbnail = 1;
   * @return {string}
   */
  getVideoThumbnail(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setVideoThumbnail(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


  /**
   * optional string cloudflare_uid = 2;
   * @return {string}
   */
  getCloudflareUid(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
  };


  /** @param {string} value */
  setCloudflareUid(value: string) {
    jspb.Message.setProto3StringField(this, 2, value);
  }


  /**
   * optional string cloudflare_signed_token = 3;
   * @return {string}
   */
  getCloudflareSignedToken(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
  };


  /** @param {string} value */
  setCloudflareSignedToken(value: string) {
    jspb.Message.setProto3StringField(this, 3, value);
  }


}
export namespace DeltaVideoUploadInfo {
  export interface AsObject {
    videoThumbnail?: common_delta_pb.StringDelta.AsObject;
    cloudflareUid?: common_delta_pb.StringDelta.AsObject;
  }
}
export class DeltaVideoUploadInfo extends jspb.Message {
  static readonly displayName = "DeltaVideoUploadInfo";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): DeltaVideoUploadInfo.AsObject {
    return DeltaVideoUploadInfo.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!DeltaVideoUploadInfo} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: DeltaVideoUploadInfo) {
    var f: any;
    var obj: any = {
      videoThumbnail: (f = msg.getVideoThumbnail()) && common_delta_pb.StringDelta.toObject(includeInstance, f),
      cloudflareUid: (f = msg.getCloudflareUid()) && common_delta_pb.StringDelta.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!DeltaVideoUploadInfo}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new DeltaVideoUploadInfo;
    return DeltaVideoUploadInfo.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!DeltaVideoUploadInfo} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!DeltaVideoUploadInfo}
   */
  static deserializeBinaryFromReader(msg: DeltaVideoUploadInfo, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new common_delta_pb.StringDelta;
        reader.readMessage(value1,common_delta_pb.StringDelta.deserializeBinaryFromReader);
        msg.setVideoThumbnail(value1);
        break;
      case 2:
        var value2 = new common_delta_pb.StringDelta;
        reader.readMessage(value2,common_delta_pb.StringDelta.deserializeBinaryFromReader);
        msg.setCloudflareUid(value2);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!DeltaVideoUploadInfo} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: DeltaVideoUploadInfo, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getVideoThumbnail();
    if (f != null) {
      writer.writeMessage(
        1,
        f,
        common_delta_pb.StringDelta.serializeBinaryToWriter
      );
    }
    f = message.getCloudflareUid();
    if (f != null) {
      writer.writeMessage(
        2,
        f,
        common_delta_pb.StringDelta.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    DeltaVideoUploadInfo.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional minga.common.StringDelta video_thumbnail = 1;
   * @return {?StringDelta}
   */
  getVideoThumbnail(): common_delta_pb.StringDelta {
    return /** @type{?StringDelta} */ (
      jspb.Message.getWrapperField(this, common_delta_pb.StringDelta, 1));
  }


  /** @param {?StringDelta|undefined} value */
  setVideoThumbnail(value?: common_delta_pb.StringDelta) {
    jspb.Message.setWrapperField(this, 1, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearVideoThumbnail() {
    this.setVideoThumbnail(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasVideoThumbnail(): boolean {
    return jspb.Message.getField(this, 1) != null;
  }


  /**
   * optional minga.common.StringDelta cloudflare_uid = 2;
   * @return {?StringDelta}
   */
  getCloudflareUid(): common_delta_pb.StringDelta {
    return /** @type{?StringDelta} */ (
      jspb.Message.getWrapperField(this, common_delta_pb.StringDelta, 2));
  }


  /** @param {?StringDelta|undefined} value */
  setCloudflareUid(value?: common_delta_pb.StringDelta) {
    jspb.Message.setWrapperField(this, 2, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearCloudflareUid() {
    this.setCloudflareUid(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasCloudflareUid(): boolean {
    return jspb.Message.getField(this, 2) != null;
  }


}
