import { FlexStudentsStats } from 'libs/domain';
import { flex_time_stats_pb as flex_stats_pb } from 'libs/generated-grpc-ts';

import { IReportPersonMapper } from '../person';

export const toProto = (item: FlexStudentsStats) => {
  const msg = new flex_stats_pb.FlexStudentsStats();
  const student = IReportPersonMapper.toProto(item.person);
  msg.setStudent(student);
  msg.setAssigned(item.assigned);
  msg.setRegistered(item.registered);
  msg.setCheckIns(item.checkIns);
  msg.setAttendance(item.attendance);
  msg.setTotalTime(item.totalTime);
  return msg;
};

export const fromProto = (
  msg: flex_stats_pb.FlexStudentsStats,
): FlexStudentsStats => {
  const student = msg.getStudent();
  const person = IReportPersonMapper.fromProto(student);
  return {
    person,
    assigned: msg.getAssigned(),
    registered: msg.getRegistered(),
    checkIns: msg.getCheckIns(),
    attendance: msg.getAttendance(),
    totalTime: msg.getTotalTime(),
  };
};
