import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

import { TranslateModule } from '@ngx-translate/core';

import { MgButtonModule } from '@app/src/app/button';
import { MgSuccessCheckmarkModule } from '@app/src/app/components/SuccessCheckmark';
import { MgFormFieldModule } from '@app/src/app/form-field';
import { PermissionsModule } from '@app/src/app/permissions';

import { OverlayModule } from '@shared/components/modal-overlay';
import { CommonUIModules } from '@shared/modules';

import { BroadcastNotificationDialog } from './BroadcastNotification.component';

@NgModule({
  imports: [
    // Minga dependencies
    CommonUIModules,
    MgSuccessCheckmarkModule,
    MgButtonModule,
    MgFormFieldModule,
    MatInputModule,
    PermissionsModule,
    OverlayModule,

    // External dependencies
    CommonModule,
    FormsModule,
    FlexLayoutModule,
    MatFormFieldModule,
    MatDialogModule,
    MatSlideToggleModule,
    TranslateModule,
  ],
  declarations: [BroadcastNotificationDialog],
  exports: [BroadcastNotificationDialog],
})
export class BroadcastNotificationModule {}
