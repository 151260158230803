import { IMingaSetting } from 'libs/domain';
import { IMingaFeatureToggle } from 'libs/domain';
import { gateway } from 'libs/generated-grpc-ts';

export const toProto = (
  toggle: IMingaFeatureToggle,
): gateway.minga_pb.MingaFeatureToggle => {
  const msg = new gateway.minga_pb.MingaFeatureToggle();

  if (toggle.trackingEnabled) {
    msg.setTrackingEnabled(toggle.trackingEnabled);
  }
  if (toggle.checkinEnabled) {
    msg.setCheckinEnabled(toggle.checkinEnabled);
  }
  if (toggle.hallPassEnabled) {
    msg.setHallPassEnabled(toggle.hallPassEnabled);
  }
  if (toggle.studentIdEnabled) {
    msg.setStudentIdEnabled(toggle.studentIdEnabled);
  }
  if (toggle.communityEnabled) {
    msg.setCommunityEnabled(toggle.communityEnabled);
  }
  if (toggle.photoGalleryEnabled) {
    msg.setPhotoGalleryEnabled(toggle.photoGalleryEnabled);
  }
  if (toggle.directMessagingEnabled) {
    msg.setDirectMessagingEnabled(toggle.directMessagingEnabled);
  }
  if (toggle.flexTimeEnabled) {
    msg.setFlexTimeEnabled(toggle.flexTimeEnabled);
  }
  return msg;
};

export const fromProto = (
  proto: gateway.minga_pb.MingaFeatureToggle,
): IMingaFeatureToggle => {
  return {
    trackingEnabled: proto.getTrackingEnabled(),
    checkinEnabled: proto.getCheckinEnabled(),
    hallPassEnabled: proto.getHallPassEnabled(),
    studentIdEnabled: proto.getStudentIdEnabled(),
    communityEnabled: proto.getCommunityEnabled(),
    photoGalleryEnabled: proto.getPhotoGalleryEnabled(),
    directMessagingEnabled: proto.getDirectMessagingEnabled(),
    flexTimeEnabled: proto.getFlexTimeEnabled(),
  };
};

/**
 * To Settings
 *
 * Only needed for backwards compatibility with old minga clients
 */
export const toSettings = (toggle: IMingaFeatureToggle): IMingaSetting[] => {
  return [
    {
      name: 'feature_flags.tracking_expansion_pack',
      value: toggle?.trackingEnabled?.toString(),
    },
    {
      name: 'feature_flags.hall_pass_expansion_pack',
      value: toggle?.hallPassEnabled?.toString(),
    },
    {
      name: 'feature_flags.enable_student_id',
      value: toggle?.studentIdEnabled?.toString(),
    },
    {
      name: 'feature_flags.minga_community',
      value: toggle?.communityEnabled?.toString(),
    },
    {
      name: 'feature_flags.enable_photo_gallery',
      value: toggle?.photoGalleryEnabled?.toString(),
    },
    {
      name: 'feature_flags.direct_messaging',
      value: toggle?.directMessagingEnabled?.toString(),
    },
    {
      name: 'feature_flags.flex_time_expansion_pack',
      value: toggle?.flexTimeEnabled?.toString(),
    },
  ];
};
