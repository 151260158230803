import { Injectable, OnDestroy } from '@angular/core';

import { IHallPass } from 'libs/domain';
import { ReplaySubject } from 'rxjs';

import { RootService } from '@app/src/app/minimal/services/RootService';

import { SystemAlertModalService } from '@shared/components/system-alert-modal';
import { SystemAlertSnackBarService } from '@shared/components/system-alert-snackbar';
import { ReportActionService } from '@shared/services/reports';

import { HpmReportsHistoryMessages } from '../components/hpm-reports-history/hpm-reports-history.constants';
import { HpmReportsService } from '../services';

@Injectable()
export class HpmReportsHistoryActionService
  extends ReportActionService<IHallPass>
  implements OnDestroy
{
  private _destroyed = new ReplaySubject<void>(1);

  constructor(
    public snackbar: SystemAlertSnackBarService,
    public alertModal: SystemAlertModalService,
    private _rootService: RootService,
    private _hpmReportsService: HpmReportsService,
  ) {
    super(snackbar, alertModal);
  }

  ngOnDestroy(): void {
    this._destroyed.next();
    this._destroyed.complete();
  }

  /** Abstract methods */
  public setTotalSelectable(total: number): void {
    this.totalSelectable = total;
  }

  /** Action methods */
  public async archiveHallpass(): Promise<boolean> {
    if (!this.isSelectionValid()) return;
    try {
      const confirmed = await this.openAlertModal(
        HpmReportsHistoryMessages.ARCHIVE_HEADING,
        '',
        HpmReportsHistoryMessages.ARCHIVE_LABEL,
      );

      if (confirmed) {
        const data = this.getSelection() as IHallPass[];
        await this._hpmReportsService.archiveHallPass(data);
      }
      this._rootService.addLoadingPromise(this._clearSelectionAndRefetch());
      return true;
    } catch (e) {
      this.openSnackBar(`Unable to archive consequence(s): ${e}`, 'error');
      return false;
    }
  }

  public getSelectionMessage(): string {
    if (this.isEmpty) return '';

    const selection = this.getSelection();
    const message = `${selection.length} selected`;
    return message;
  }

  private async _clearSelectionAndRefetch() {
    this._hpmReportsService.applyFilter();
    this.clearSelection();
  }
}
