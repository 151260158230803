import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { INotificationType } from 'libs/domain';
import { PointHistoryType } from 'libs/domain';
import { gateway } from 'libs/generated-grpc-web';
import { $enum } from 'ts-enum-util';

@Component({
  selector: 'mg-points-notification',
  templateUrl: './MgPointsNotification.element.html',
  styleUrls: ['./MgPointsNotification.element.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MgPointsNotificationElement {
  @Input()
  notification?: gateway.notification_pb.Notification.AsObject;

  get pointHistoryType(): PointHistoryType {
    if (this.notification && this.notification.notificationType) {
      const notificationType = $enum(INotificationType).asValueOrDefault(
        this.notification.notificationType,
        INotificationType.POINTS_CUSTOM,
      );

      switch (notificationType) {
        case INotificationType.POINTS_EVENT_CHECKIN:
          return PointHistoryType.EVENT_CHECKIN;
        case INotificationType.POINTS_IMAGE_CHALLENGE:
          return PointHistoryType.IMAGE_CHALLENGE_RESPONSE;
        case INotificationType.POINTS_TEXT_CHALLENGE:
          return PointHistoryType.TEXT_CHALLENGE_RESPONSE;
        case INotificationType.POINTS_POLL:
          return PointHistoryType.POLL_RESPONSE;
        default:
          break;
      }
    }
    return PointHistoryType.CUSTOM;
  }
}
