// source: assetservice/assetservice.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

import * as jspb from 'google-protobuf';
export namespace AssetSize {
  export interface AsObject {
    path: string;
    width: number;
    height: number;
  }
}
export class AssetSize extends jspb.Message {
  static readonly displayName = "AssetSize";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): AssetSize.AsObject {
    return AssetSize.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!AssetSize} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: AssetSize) {
    var f: any;
    var obj: any = {
      path: jspb.Message.getFieldWithDefault(msg, 1, ""),
      width: jspb.Message.getFieldWithDefault(msg, 2, 0),
      height: jspb.Message.getFieldWithDefault(msg, 3, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!AssetSize}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new AssetSize;
    return AssetSize.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!AssetSize} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!AssetSize}
   */
  static deserializeBinaryFromReader(msg: AssetSize, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setPath(value1);
        break;
      case 2:
        var value2 = /** @type {number} */ (reader.readInt32());
        msg.setWidth(value2);
        break;
      case 3:
        var value3 = /** @type {number} */ (reader.readInt32());
        msg.setHeight(value3);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!AssetSize} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: AssetSize, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getPath();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
    f = message.getWidth();
    if (f !== 0) {
      writer.writeInt32(
        2,
        f
      );
    }
    f = message.getHeight();
    if (f !== 0) {
      writer.writeInt32(
        3,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    AssetSize.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string path = 1;
   * @return {string}
   */
  getPath(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setPath(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


  /**
   * optional int32 width = 2;
   * @return {number}
   */
  getWidth(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
  };


  /** @param {number} value */
  setWidth(value: number) {
    jspb.Message.setProto3IntField(this, 2, value);
  }


  /**
   * optional int32 height = 3;
   * @return {number}
   */
  getHeight(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
  };


  /** @param {number} value */
  setHeight(value: number) {
    jspb.Message.setProto3IntField(this, 3, value);
  }


}
export namespace AssetChunk {
  export interface AsObject {
    uploadId: number;
    byteOffset: number;
    sourcePart?: (string|Uint8Array);
    assetPath: string;
  }
}
export class AssetChunk extends jspb.Message {
  static readonly displayName = "AssetChunk";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): AssetChunk.AsObject {
    return AssetChunk.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!AssetChunk} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: AssetChunk) {
    var f: any;
    var obj: any = {
      uploadId: jspb.Message.getFieldWithDefault(msg, 1, 0),
      byteOffset: jspb.Message.getFieldWithDefault(msg, 2, 0),
      sourcePart: msg.getSourcePart_asB64(),
      assetPath: jspb.Message.getFieldWithDefault(msg, 4, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!AssetChunk}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new AssetChunk;
    return AssetChunk.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!AssetChunk} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!AssetChunk}
   */
  static deserializeBinaryFromReader(msg: AssetChunk, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {number} */ (reader.readInt32());
        msg.setUploadId(value1);
        break;
      case 2:
        var value2 = /** @type {number} */ (reader.readInt32());
        msg.setByteOffset(value2);
        break;
      case 3:
        var value3 = /** @type {!Uint8Array} */ (reader.readBytes());
        msg.setSourcePart(value3);
        break;
      case 4:
        var value4 = /** @type {string} */ (reader.readString());
        msg.setAssetPath(value4);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!AssetChunk} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: AssetChunk, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getUploadId();
    if (f !== 0) {
      writer.writeInt32(
        1,
        f
      );
    }
    f = message.getByteOffset();
    if (f !== 0) {
      writer.writeInt32(
        2,
        f
      );
    }
    f = message.getSourcePart_asU8();
    if (f.length > 0) {
      writer.writeBytes(
        3,
        f
      );
    }
    f = message.getAssetPath();
    if (f.length > 0) {
      writer.writeString(
        4,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    AssetChunk.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional int32 upload_id = 1;
   * @return {number}
   */
  getUploadId(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
  };


  /** @param {number} value */
  setUploadId(value: number) {
    jspb.Message.setProto3IntField(this, 1, value);
  }


  /**
   * optional int32 byte_offset = 2;
   * @return {number}
   */
  getByteOffset(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
  };


  /** @param {number} value */
  setByteOffset(value: number) {
    jspb.Message.setProto3IntField(this, 2, value);
  }


  /**
   * optional bytes source_part = 3;
   * @return {!(string|Uint8Array)}
   */
  getSourcePart(): (string|Uint8Array) {
    return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
  };


  /**
   * optional bytes source_part = 3;
   * This is a type-conversion wrapper around `getSourcePart()`
   * @return {string}
   */
  getSourcePart_asB64() {
    return /** @type {string} */ (jspb.Message.bytesAsB64(
        this.getSourcePart()));
  }


  /**
   * optional bytes source_part = 3;
   * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getSourcePart()`
   * @return {!Uint8Array}
   */
  getSourcePart_asU8() {
    return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
        this.getSourcePart()));
  }


  /** @param {!(string|Uint8Array)} value */
  setSourcePart(value: (string|Uint8Array)) {
    jspb.Message.setProto3BytesField(this, 3, value);
  }


  /**
   * optional string asset_path = 4;
   * @return {string}
   */
  getAssetPath(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
  };


  /** @param {string} value */
  setAssetPath(value: string) {
    jspb.Message.setProto3StringField(this, 4, value);
  }


}
export namespace AssetChunkConfirmation {
  export interface AsObject {
    responseCode?: AssetChunkConfirmation.ChunkResponseCode;
    uploadId: number;
    byteOffset: number;
    assetResponseCode?: ResponseCode;
    assetPath: string;
  }
}
export class AssetChunkConfirmation extends jspb.Message {
  static readonly displayName = "AssetChunkConfirmation";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): AssetChunkConfirmation.AsObject {
    return AssetChunkConfirmation.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!AssetChunkConfirmation} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: AssetChunkConfirmation) {
    var f: any;
    var obj: any = {
      responseCode: jspb.Message.getFieldWithDefault(msg, 1, 0),
      uploadId: jspb.Message.getFieldWithDefault(msg, 2, 0),
      byteOffset: jspb.Message.getFieldWithDefault(msg, 3, 0),
      assetResponseCode: jspb.Message.getFieldWithDefault(msg, 4, 0),
      assetPath: jspb.Message.getFieldWithDefault(msg, 5, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!AssetChunkConfirmation}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new AssetChunkConfirmation;
    return AssetChunkConfirmation.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!AssetChunkConfirmation} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!AssetChunkConfirmation}
   */
  static deserializeBinaryFromReader(msg: AssetChunkConfirmation, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {!AssetChunkConfirmation.ChunkResponseCode} */ (reader.readEnum());
        msg.setResponseCode(value1);
        break;
      case 2:
        var value2 = /** @type {number} */ (reader.readInt32());
        msg.setUploadId(value2);
        break;
      case 3:
        var value3 = /** @type {number} */ (reader.readInt32());
        msg.setByteOffset(value3);
        break;
      case 4:
        var value4 = /** @type {!ResponseCode} */ (reader.readEnum());
        msg.setAssetResponseCode(value4);
        break;
      case 5:
        var value5 = /** @type {string} */ (reader.readString());
        msg.setAssetPath(value5);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!AssetChunkConfirmation} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: AssetChunkConfirmation, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getResponseCode();
    if (f !== 0.0) {
      writer.writeEnum(
        1,
        f
      );
    }
    f = message.getUploadId();
    if (f !== 0) {
      writer.writeInt32(
        2,
        f
      );
    }
    f = message.getByteOffset();
    if (f !== 0) {
      writer.writeInt32(
        3,
        f
      );
    }
    f = message.getAssetResponseCode();
    if (f !== 0.0) {
      writer.writeEnum(
        4,
        f
      );
    }
    f = message.getAssetPath();
    if (f.length > 0) {
      writer.writeString(
        5,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    AssetChunkConfirmation.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional ChunkResponseCode response_code = 1;
   * @return {!AssetChunkConfirmation.ChunkResponseCode}
   */
  getResponseCode(): AssetChunkConfirmation.ChunkResponseCode {
    return /** @type {!AssetChunkConfirmation.ChunkResponseCode} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
  };


  /** @param {!AssetChunkConfirmation.ChunkResponseCode} value */
  setResponseCode(value: AssetChunkConfirmation.ChunkResponseCode) {
    jspb.Message.setProto3EnumField(this, 1, value);
  }


  /**
   * optional int32 upload_id = 2;
   * @return {number}
   */
  getUploadId(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
  };


  /** @param {number} value */
  setUploadId(value: number) {
    jspb.Message.setProto3IntField(this, 2, value);
  }


  /**
   * optional int32 byte_offset = 3;
   * @return {number}
   */
  getByteOffset(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
  };


  /** @param {number} value */
  setByteOffset(value: number) {
    jspb.Message.setProto3IntField(this, 3, value);
  }


  /**
   * optional ResponseCode asset_response_code = 4;
   * @return {!ResponseCode}
   */
  getAssetResponseCode(): ResponseCode {
    return /** @type {!ResponseCode} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
  };


  /** @param {!ResponseCode} value */
  setAssetResponseCode(value: ResponseCode) {
    jspb.Message.setProto3EnumField(this, 4, value);
  }


  /**
   * optional string asset_path = 5;
   * @return {string}
   */
  getAssetPath(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
  };


  /** @param {string} value */
  setAssetPath(value: string) {
    jspb.Message.setProto3StringField(this, 5, value);
  }


}
export namespace AssetChunkConfirmation {
export enum ChunkResponseCode {
  OK = 0,
  RETRY = 1,
  COMPLETE = 2,
  ERROR = 3,
}
} // namespace AssetChunkConfirmation
export namespace AssetOwner {
  export interface AsObject {
    userId: number;
    username: string;
  }
}
export class AssetOwner extends jspb.Message {
  static readonly displayName = "AssetOwner";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): AssetOwner.AsObject {
    return AssetOwner.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!AssetOwner} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: AssetOwner) {
    var f: any;
    var obj: any = {
      userId: jspb.Message.getFieldWithDefault(msg, 1, 0),
      username: jspb.Message.getFieldWithDefault(msg, 2, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!AssetOwner}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new AssetOwner;
    return AssetOwner.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!AssetOwner} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!AssetOwner}
   */
  static deserializeBinaryFromReader(msg: AssetOwner, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {number} */ (reader.readInt32());
        msg.setUserId(value1);
        break;
      case 2:
        var value2 = /** @type {string} */ (reader.readString());
        msg.setUsername(value2);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!AssetOwner} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: AssetOwner, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getUserId();
    if (f !== 0) {
      writer.writeInt32(
        1,
        f
      );
    }
    f = message.getUsername();
    if (f.length > 0) {
      writer.writeString(
        2,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    AssetOwner.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional int32 user_id = 1;
   * @return {number}
   */
  getUserId(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
  };


  /** @param {number} value */
  setUserId(value: number) {
    jspb.Message.setProto3IntField(this, 1, value);
  }


  /**
   * optional string username = 2;
   * @return {string}
   */
  getUsername(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
  };


  /** @param {string} value */
  setUsername(value: string) {
    jspb.Message.setProto3StringField(this, 2, value);
  }


}
export namespace NewAssetRequest {
  export interface AsObject {
    size: number;
    filename: string;
    owner?: AssetOwner.AsObject;
    binaryHash: string;
    forecastType?: NewAssetRequest.ForecastType;
    fileType: string;
    inputAccept: string;
  }
}
export class NewAssetRequest extends jspb.Message {
  static readonly displayName = "NewAssetRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): NewAssetRequest.AsObject {
    return NewAssetRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!NewAssetRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: NewAssetRequest) {
    var f: any;
    var obj: any = {
      size: jspb.Message.getFieldWithDefault(msg, 1, 0),
      filename: jspb.Message.getFieldWithDefault(msg, 2, ""),
      owner: (f = msg.getOwner()) && AssetOwner.toObject(includeInstance, f),
      binaryHash: jspb.Message.getFieldWithDefault(msg, 4, ""),
      forecastType: jspb.Message.getFieldWithDefault(msg, 5, 0),
      fileType: jspb.Message.getFieldWithDefault(msg, 6, ""),
      inputAccept: jspb.Message.getFieldWithDefault(msg, 7, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!NewAssetRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new NewAssetRequest;
    return NewAssetRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!NewAssetRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!NewAssetRequest}
   */
  static deserializeBinaryFromReader(msg: NewAssetRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {number} */ (reader.readInt64());
        msg.setSize(value1);
        break;
      case 2:
        var value2 = /** @type {string} */ (reader.readString());
        msg.setFilename(value2);
        break;
      case 3:
        var value3 = new AssetOwner;
        reader.readMessage(value3,AssetOwner.deserializeBinaryFromReader);
        msg.setOwner(value3);
        break;
      case 4:
        var value4 = /** @type {string} */ (reader.readString());
        msg.setBinaryHash(value4);
        break;
      case 5:
        var value5 = /** @type {!NewAssetRequest.ForecastType} */ (reader.readEnum());
        msg.setForecastType(value5);
        break;
      case 6:
        var value6 = /** @type {string} */ (reader.readString());
        msg.setFileType(value6);
        break;
      case 7:
        var value7 = /** @type {string} */ (reader.readString());
        msg.setInputAccept(value7);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!NewAssetRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: NewAssetRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getSize();
    if (f !== 0) {
      writer.writeInt64(
        1,
        f
      );
    }
    f = message.getFilename();
    if (f.length > 0) {
      writer.writeString(
        2,
        f
      );
    }
    f = message.getOwner();
    if (f != null) {
      writer.writeMessage(
        3,
        f,
        AssetOwner.serializeBinaryToWriter
      );
    }
    f = message.getBinaryHash();
    if (f.length > 0) {
      writer.writeString(
        4,
        f
      );
    }
    f = message.getForecastType();
    if (f !== 0.0) {
      writer.writeEnum(
        5,
        f
      );
    }
    f = message.getFileType();
    if (f.length > 0) {
      writer.writeString(
        6,
        f
      );
    }
    f = message.getInputAccept();
    if (f.length > 0) {
      writer.writeString(
        7,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    NewAssetRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional int64 size = 1;
   * @return {number}
   */
  getSize(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
  };


  /** @param {number} value */
  setSize(value: number) {
    jspb.Message.setProto3IntField(this, 1, value);
  }


  /**
   * optional string filename = 2;
   * @return {string}
   */
  getFilename(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
  };


  /** @param {string} value */
  setFilename(value: string) {
    jspb.Message.setProto3StringField(this, 2, value);
  }


  /**
   * optional AssetOwner owner = 3;
   * @return {?AssetOwner}
   */
  getOwner(): AssetOwner {
    return /** @type{?AssetOwner} */ (
      jspb.Message.getWrapperField(this, AssetOwner, 3));
  }


  /** @param {?AssetOwner|undefined} value */
  setOwner(value?: AssetOwner) {
    jspb.Message.setWrapperField(this, 3, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearOwner() {
    this.setOwner(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasOwner(): boolean {
    return jspb.Message.getField(this, 3) != null;
  }


  /**
   * optional string binary_hash = 4;
   * @return {string}
   */
  getBinaryHash(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
  };


  /** @param {string} value */
  setBinaryHash(value: string) {
    jspb.Message.setProto3StringField(this, 4, value);
  }


  /**
   * optional ForecastType forecast_type = 5;
   * @return {!NewAssetRequest.ForecastType}
   */
  getForecastType(): NewAssetRequest.ForecastType {
    return /** @type {!NewAssetRequest.ForecastType} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
  };


  /** @param {!NewAssetRequest.ForecastType} value */
  setForecastType(value: NewAssetRequest.ForecastType) {
    jspb.Message.setProto3EnumField(this, 5, value);
  }


  /**
   * optional string file_type = 6;
   * @return {string}
   */
  getFileType(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
  };


  /** @param {string} value */
  setFileType(value: string) {
    jspb.Message.setProto3StringField(this, 6, value);
  }


  /**
   * optional string input_accept = 7;
   * @return {string}
   */
  getInputAccept(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
  };


  /** @param {string} value */
  setInputAccept(value: string) {
    jspb.Message.setProto3StringField(this, 7, value);
  }


}
export namespace NewAssetRequest {
export enum ForecastType {
  IMAGE = 0,
  VIDEO = 1,
  DOC = 2,
}
} // namespace NewAssetRequest
export namespace NewAssetResponse {
  export interface AsObject {
    responseCode?: ResponseCode;
    assetId: string;
    uploadId: number;
  }
}
export class NewAssetResponse extends jspb.Message {
  static readonly displayName = "NewAssetResponse";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): NewAssetResponse.AsObject {
    return NewAssetResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!NewAssetResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: NewAssetResponse) {
    var f: any;
    var obj: any = {
      responseCode: jspb.Message.getFieldWithDefault(msg, 1, 0),
      assetId: jspb.Message.getFieldWithDefault(msg, 2, ""),
      uploadId: jspb.Message.getFieldWithDefault(msg, 3, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!NewAssetResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new NewAssetResponse;
    return NewAssetResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!NewAssetResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!NewAssetResponse}
   */
  static deserializeBinaryFromReader(msg: NewAssetResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {!ResponseCode} */ (reader.readEnum());
        msg.setResponseCode(value1);
        break;
      case 2:
        var value2 = /** @type {string} */ (reader.readString());
        msg.setAssetId(value2);
        break;
      case 3:
        var value3 = /** @type {number} */ (reader.readInt32());
        msg.setUploadId(value3);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!NewAssetResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: NewAssetResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getResponseCode();
    if (f !== 0.0) {
      writer.writeEnum(
        1,
        f
      );
    }
    f = message.getAssetId();
    if (f.length > 0) {
      writer.writeString(
        2,
        f
      );
    }
    f = message.getUploadId();
    if (f !== 0) {
      writer.writeInt32(
        3,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    NewAssetResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional ResponseCode response_code = 1;
   * @return {!ResponseCode}
   */
  getResponseCode(): ResponseCode {
    return /** @type {!ResponseCode} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
  };


  /** @param {!ResponseCode} value */
  setResponseCode(value: ResponseCode) {
    jspb.Message.setProto3EnumField(this, 1, value);
  }


  /**
   * optional string asset_id = 2;
   * @return {string}
   */
  getAssetId(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
  };


  /** @param {string} value */
  setAssetId(value: string) {
    jspb.Message.setProto3StringField(this, 2, value);
  }


  /**
   * optional int32 upload_id = 3;
   * @return {number}
   */
  getUploadId(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
  };


  /** @param {number} value */
  setUploadId(value: number) {
    jspb.Message.setProto3IntField(this, 3, value);
  }


}
export namespace GetAssetRequest {
  export interface AsObject {
    assetId: string;
  }
}
export class GetAssetRequest extends jspb.Message {
  static readonly displayName = "GetAssetRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): GetAssetRequest.AsObject {
    return GetAssetRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!GetAssetRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: GetAssetRequest) {
    var f: any;
    var obj: any = {
      assetId: jspb.Message.getFieldWithDefault(msg, 1, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!GetAssetRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new GetAssetRequest;
    return GetAssetRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!GetAssetRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!GetAssetRequest}
   */
  static deserializeBinaryFromReader(msg: GetAssetRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setAssetId(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!GetAssetRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: GetAssetRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getAssetId();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    GetAssetRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string asset_id = 1;
   * @return {string}
   */
  getAssetId(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setAssetId(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


}
export namespace GetAssetResponse {
  export interface AsObject {
    responseCode?: ResponseCode;
    assetSizeMap: [string, AssetSize.AsObject][];
  }
}
export class GetAssetResponse extends jspb.Message {
  static readonly displayName = "GetAssetResponse";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): GetAssetResponse.AsObject {
    return GetAssetResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!GetAssetResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: GetAssetResponse) {
    var f: any;
    var obj: any = {
      responseCode: jspb.Message.getFieldWithDefault(msg, 1, 0),
      assetSizeMap: (f = msg.getAssetSizeMap()) ? f.toObject(includeInstance, AssetSize.toObject) : []
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!GetAssetResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new GetAssetResponse;
    return GetAssetResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!GetAssetResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!GetAssetResponse}
   */
  static deserializeBinaryFromReader(msg: GetAssetResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {!ResponseCode} */ (reader.readEnum());
        msg.setResponseCode(value1);
        break;
      case 2:
        var value2 = msg.getAssetSizeMap();
        reader.readMessage(value2, function(message, reader) {
          jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, AssetSize.deserializeBinaryFromReader, "");
           });
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!GetAssetResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: GetAssetResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getResponseCode();
    if (f !== 0.0) {
      writer.writeEnum(
        1,
        f
      );
    }
    f = message.getAssetSizeMap(true);
    if (f && f.getLength() > 0) {
      f.serializeBinary(2, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, AssetSize.serializeBinaryToWriter);
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    GetAssetResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional ResponseCode response_code = 1;
   * @return {!ResponseCode}
   */
  getResponseCode(): ResponseCode {
    return /** @type {!ResponseCode} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
  };


  /** @param {!ResponseCode} value */
  setResponseCode(value: ResponseCode) {
    jspb.Message.setProto3EnumField(this, 1, value);
  }


  /**
   * map<string, AssetSize> asset_size = 2;
   * @param {boolean=} opt_noLazyCreate Do not create the map if
   * empty, instead returning `undefined`
   * @return {!jspb.Map<string,!AssetSize>}
   */
  getAssetSizeMap(): jspb.Map<string,AssetSize>;
  getAssetSizeMap(noLazyCreate: true): jspb.Map<string,AssetSize>|undefined;
  getAssetSizeMap(noLazyCreate: false): jspb.Map<string,AssetSize>;
  getAssetSizeMap(opt_noLazyCreate: boolean = false): jspb.Map<string,AssetSize> {
    return /** @type {!jspb.Map<string,!AssetSize>} */ (
        jspb.Message.getMapField(this, 2, opt_noLazyCreate,
        AssetSize));
  }


  /**
   * Clears values from the map. The map will be non-null.
   */
  clearAssetSizeMap() {
    this.getAssetSizeMap().clear();
  }


}
export namespace DeleteAssetRequest {
  export interface AsObject {
    assetId: number;
  }
}
export class DeleteAssetRequest extends jspb.Message {
  static readonly displayName = "DeleteAssetRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): DeleteAssetRequest.AsObject {
    return DeleteAssetRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!DeleteAssetRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: DeleteAssetRequest) {
    var f: any;
    var obj: any = {
      assetId: jspb.Message.getFieldWithDefault(msg, 1, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!DeleteAssetRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new DeleteAssetRequest;
    return DeleteAssetRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!DeleteAssetRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!DeleteAssetRequest}
   */
  static deserializeBinaryFromReader(msg: DeleteAssetRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {number} */ (reader.readInt32());
        msg.setAssetId(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!DeleteAssetRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: DeleteAssetRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getAssetId();
    if (f !== 0) {
      writer.writeInt32(
        1,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    DeleteAssetRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional int32 asset_id = 1;
   * @return {number}
   */
  getAssetId(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
  };


  /** @param {number} value */
  setAssetId(value: number) {
    jspb.Message.setProto3IntField(this, 1, value);
  }


}
export namespace DeleteAssetResponse {
  export interface AsObject {
    responseCode?: ResponseCode;
  }
}
export class DeleteAssetResponse extends jspb.Message {
  static readonly displayName = "DeleteAssetResponse";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): DeleteAssetResponse.AsObject {
    return DeleteAssetResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!DeleteAssetResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: DeleteAssetResponse) {
    var f: any;
    var obj: any = {
      responseCode: jspb.Message.getFieldWithDefault(msg, 1, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!DeleteAssetResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new DeleteAssetResponse;
    return DeleteAssetResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!DeleteAssetResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!DeleteAssetResponse}
   */
  static deserializeBinaryFromReader(msg: DeleteAssetResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {!ResponseCode} */ (reader.readEnum());
        msg.setResponseCode(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!DeleteAssetResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: DeleteAssetResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getResponseCode();
    if (f !== 0.0) {
      writer.writeEnum(
        1,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    DeleteAssetResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional ResponseCode response_code = 1;
   * @return {!ResponseCode}
   */
  getResponseCode(): ResponseCode {
    return /** @type {!ResponseCode} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
  };


  /** @param {!ResponseCode} value */
  setResponseCode(value: ResponseCode) {
    jspb.Message.setProto3EnumField(this, 1, value);
  }


}
export namespace ReplaceAssetRequest {
  export interface AsObject {
    assetId: number;
    newAsset?: NewAssetRequest.AsObject;
  }
}
export class ReplaceAssetRequest extends jspb.Message {
  static readonly displayName = "ReplaceAssetRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): ReplaceAssetRequest.AsObject {
    return ReplaceAssetRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!ReplaceAssetRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: ReplaceAssetRequest) {
    var f: any;
    var obj: any = {
      assetId: jspb.Message.getFieldWithDefault(msg, 1, 0),
      newAsset: (f = msg.getNewAsset()) && NewAssetRequest.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!ReplaceAssetRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new ReplaceAssetRequest;
    return ReplaceAssetRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!ReplaceAssetRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!ReplaceAssetRequest}
   */
  static deserializeBinaryFromReader(msg: ReplaceAssetRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {number} */ (reader.readInt32());
        msg.setAssetId(value1);
        break;
      case 2:
        var value2 = new NewAssetRequest;
        reader.readMessage(value2,NewAssetRequest.deserializeBinaryFromReader);
        msg.setNewAsset(value2);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!ReplaceAssetRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: ReplaceAssetRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getAssetId();
    if (f !== 0) {
      writer.writeInt32(
        1,
        f
      );
    }
    f = message.getNewAsset();
    if (f != null) {
      writer.writeMessage(
        2,
        f,
        NewAssetRequest.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    ReplaceAssetRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional int32 asset_id = 1;
   * @return {number}
   */
  getAssetId(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
  };


  /** @param {number} value */
  setAssetId(value: number) {
    jspb.Message.setProto3IntField(this, 1, value);
  }


  /**
   * optional NewAssetRequest new_asset = 2;
   * @return {?NewAssetRequest}
   */
  getNewAsset(): NewAssetRequest {
    return /** @type{?NewAssetRequest} */ (
      jspb.Message.getWrapperField(this, NewAssetRequest, 2));
  }


  /** @param {?NewAssetRequest|undefined} value */
  setNewAsset(value?: NewAssetRequest) {
    jspb.Message.setWrapperField(this, 2, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearNewAsset() {
    this.setNewAsset(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasNewAsset(): boolean {
    return jspb.Message.getField(this, 2) != null;
  }


}
export namespace ProcessAssetRequest {
  export interface AsObject {
    assetId: string;
    name: string;
    quality: number;
    width: number;
    height: number;
    aspectRatio: number;
  }
}
export class ProcessAssetRequest extends jspb.Message {
  static readonly displayName = "ProcessAssetRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): ProcessAssetRequest.AsObject {
    return ProcessAssetRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!ProcessAssetRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: ProcessAssetRequest) {
    var f: any;
    var obj: any = {
      assetId: jspb.Message.getFieldWithDefault(msg, 1, ""),
      name: jspb.Message.getFieldWithDefault(msg, 2, ""),
      quality: jspb.Message.getFieldWithDefault(msg, 3, 0),
      width: jspb.Message.getFieldWithDefault(msg, 4, 0),
      height: jspb.Message.getFieldWithDefault(msg, 5, 0),
      aspectRatio: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!ProcessAssetRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new ProcessAssetRequest;
    return ProcessAssetRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!ProcessAssetRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!ProcessAssetRequest}
   */
  static deserializeBinaryFromReader(msg: ProcessAssetRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setAssetId(value1);
        break;
      case 2:
        var value2 = /** @type {string} */ (reader.readString());
        msg.setName(value2);
        break;
      case 3:
        var value3 = /** @type {number} */ (reader.readUint32());
        msg.setQuality(value3);
        break;
      case 4:
        var value4 = /** @type {number} */ (reader.readUint32());
        msg.setWidth(value4);
        break;
      case 5:
        var value5 = /** @type {number} */ (reader.readUint32());
        msg.setHeight(value5);
        break;
      case 6:
        var value6 = /** @type {number} */ (reader.readFloat());
        msg.setAspectRatio(value6);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!ProcessAssetRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: ProcessAssetRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getAssetId();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
    f = message.getName();
    if (f.length > 0) {
      writer.writeString(
        2,
        f
      );
    }
    f = message.getQuality();
    if (f !== 0) {
      writer.writeUint32(
        3,
        f
      );
    }
    f = message.getWidth();
    if (f !== 0) {
      writer.writeUint32(
        4,
        f
      );
    }
    f = message.getHeight();
    if (f !== 0) {
      writer.writeUint32(
        5,
        f
      );
    }
    f = message.getAspectRatio();
    if (f !== 0.0) {
      writer.writeFloat(
        6,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    ProcessAssetRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string asset_id = 1;
   * @return {string}
   */
  getAssetId(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setAssetId(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


  /**
   * optional string name = 2;
   * @return {string}
   */
  getName(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
  };


  /** @param {string} value */
  setName(value: string) {
    jspb.Message.setProto3StringField(this, 2, value);
  }


  /**
   * optional uint32 quality = 3;
   * @return {number}
   */
  getQuality(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
  };


  /** @param {number} value */
  setQuality(value: number) {
    jspb.Message.setProto3IntField(this, 3, value);
  }


  /**
   * optional uint32 width = 4;
   * @return {number}
   */
  getWidth(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
  };


  /** @param {number} value */
  setWidth(value: number) {
    jspb.Message.setProto3IntField(this, 4, value);
  }


  /**
   * optional uint32 height = 5;
   * @return {number}
   */
  getHeight(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
  };


  /** @param {number} value */
  setHeight(value: number) {
    jspb.Message.setProto3IntField(this, 5, value);
  }


  /**
   * optional float aspect_ratio = 6;
   * @return {number}
   */
  getAspectRatio(): number {
    return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
  };


  /** @param {number} value */
  setAspectRatio(value: number) {
    jspb.Message.setProto3FloatField(this, 6, value);
  }


}
export namespace ProcessAssetResponse {
  export interface AsObject {
    processId: number;
  }
}
export class ProcessAssetResponse extends jspb.Message {
  static readonly displayName = "ProcessAssetResponse";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): ProcessAssetResponse.AsObject {
    return ProcessAssetResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!ProcessAssetResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: ProcessAssetResponse) {
    var f: any;
    var obj: any = {
      processId: jspb.Message.getFieldWithDefault(msg, 1, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!ProcessAssetResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new ProcessAssetResponse;
    return ProcessAssetResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!ProcessAssetResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!ProcessAssetResponse}
   */
  static deserializeBinaryFromReader(msg: ProcessAssetResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {number} */ (reader.readInt32());
        msg.setProcessId(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!ProcessAssetResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: ProcessAssetResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getProcessId();
    if (f !== 0) {
      writer.writeInt32(
        1,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    ProcessAssetResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional int32 process_id = 1;
   * @return {number}
   */
  getProcessId(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
  };


  /** @param {number} value */
  setProcessId(value: number) {
    jspb.Message.setProto3IntField(this, 1, value);
  }


}
export namespace SetAssetRequest {
  export interface AsObject {
    existingAssetId: string;
    url: string;
    processName: string;
  }
}
export class SetAssetRequest extends jspb.Message {
  static readonly displayName = "SetAssetRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): SetAssetRequest.AsObject {
    return SetAssetRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!SetAssetRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: SetAssetRequest) {
    var f: any;
    var obj: any = {
      existingAssetId: jspb.Message.getFieldWithDefault(msg, 1, ""),
      url: jspb.Message.getFieldWithDefault(msg, 2, ""),
      processName: jspb.Message.getFieldWithDefault(msg, 3, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!SetAssetRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new SetAssetRequest;
    return SetAssetRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!SetAssetRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!SetAssetRequest}
   */
  static deserializeBinaryFromReader(msg: SetAssetRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setExistingAssetId(value1);
        break;
      case 2:
        var value2 = /** @type {string} */ (reader.readString());
        msg.setUrl(value2);
        break;
      case 3:
        var value3 = /** @type {string} */ (reader.readString());
        msg.setProcessName(value3);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!SetAssetRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: SetAssetRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getExistingAssetId();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
    f = message.getUrl();
    if (f.length > 0) {
      writer.writeString(
        2,
        f
      );
    }
    f = message.getProcessName();
    if (f.length > 0) {
      writer.writeString(
        3,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    SetAssetRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string existing_asset_id = 1;
   * @return {string}
   */
  getExistingAssetId(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setExistingAssetId(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


  /**
   * optional string url = 2;
   * @return {string}
   */
  getUrl(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
  };


  /** @param {string} value */
  setUrl(value: string) {
    jspb.Message.setProto3StringField(this, 2, value);
  }


  /**
   * optional string process_name = 3;
   * @return {string}
   */
  getProcessName(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
  };


  /** @param {string} value */
  setProcessName(value: string) {
    jspb.Message.setProto3StringField(this, 3, value);
  }


}
export namespace SetAssetResponse {
  export interface AsObject {
    responseCode?: ResponseCode;
    assetId: string;
  }
}
export class SetAssetResponse extends jspb.Message {
  static readonly displayName = "SetAssetResponse";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): SetAssetResponse.AsObject {
    return SetAssetResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!SetAssetResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: SetAssetResponse) {
    var f: any;
    var obj: any = {
      responseCode: jspb.Message.getFieldWithDefault(msg, 1, 0),
      assetId: jspb.Message.getFieldWithDefault(msg, 2, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!SetAssetResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new SetAssetResponse;
    return SetAssetResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!SetAssetResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!SetAssetResponse}
   */
  static deserializeBinaryFromReader(msg: SetAssetResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {!ResponseCode} */ (reader.readEnum());
        msg.setResponseCode(value1);
        break;
      case 2:
        var value2 = /** @type {string} */ (reader.readString());
        msg.setAssetId(value2);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!SetAssetResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: SetAssetResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getResponseCode();
    if (f !== 0.0) {
      writer.writeEnum(
        1,
        f
      );
    }
    f = message.getAssetId();
    if (f.length > 0) {
      writer.writeString(
        2,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    SetAssetResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional ResponseCode response_code = 1;
   * @return {!ResponseCode}
   */
  getResponseCode(): ResponseCode {
    return /** @type {!ResponseCode} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
  };


  /** @param {!ResponseCode} value */
  setResponseCode(value: ResponseCode) {
    jspb.Message.setProto3EnumField(this, 1, value);
  }


  /**
   * optional string asset_id = 2;
   * @return {string}
   */
  getAssetId(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
  };


  /** @param {string} value */
  setAssetId(value: string) {
    jspb.Message.setProto3StringField(this, 2, value);
  }


}
export namespace ProcessAssetProgressRequest {
  export interface AsObject {
    processId: number;
  }
}
export class ProcessAssetProgressRequest extends jspb.Message {
  static readonly displayName = "ProcessAssetProgressRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): ProcessAssetProgressRequest.AsObject {
    return ProcessAssetProgressRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!ProcessAssetProgressRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: ProcessAssetProgressRequest) {
    var f: any;
    var obj: any = {
      processId: jspb.Message.getFieldWithDefault(msg, 1, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!ProcessAssetProgressRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new ProcessAssetProgressRequest;
    return ProcessAssetProgressRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!ProcessAssetProgressRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!ProcessAssetProgressRequest}
   */
  static deserializeBinaryFromReader(msg: ProcessAssetProgressRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {number} */ (reader.readInt32());
        msg.setProcessId(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!ProcessAssetProgressRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: ProcessAssetProgressRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getProcessId();
    if (f !== 0) {
      writer.writeInt32(
        1,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    ProcessAssetProgressRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional int32 process_id = 1;
   * @return {number}
   */
  getProcessId(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
  };


  /** @param {number} value */
  setProcessId(value: number) {
    jspb.Message.setProto3IntField(this, 1, value);
  }


}
export namespace ProcessAssetProgress {
  export interface AsObject {
    progress: number;
  }
}
export class ProcessAssetProgress extends jspb.Message {
  static readonly displayName = "ProcessAssetProgress";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): ProcessAssetProgress.AsObject {
    return ProcessAssetProgress.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!ProcessAssetProgress} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: ProcessAssetProgress) {
    var f: any;
    var obj: any = {
      progress: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!ProcessAssetProgress}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new ProcessAssetProgress;
    return ProcessAssetProgress.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!ProcessAssetProgress} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!ProcessAssetProgress}
   */
  static deserializeBinaryFromReader(msg: ProcessAssetProgress, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {number} */ (reader.readFloat());
        msg.setProgress(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!ProcessAssetProgress} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: ProcessAssetProgress, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getProgress();
    if (f !== 0.0) {
      writer.writeFloat(
        1,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    ProcessAssetProgress.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional float progress = 1;
   * @return {number}
   */
  getProgress(): number {
    return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
  };


  /** @param {number} value */
  setProgress(value: number) {
    jspb.Message.setProto3FloatField(this, 1, value);
  }


}
export enum ResponseCode {
  ASSET_SUCCESS = 0,
  ASSET_BUSY = 1,
  ASSET_FATAL = 2,
  ASSET_INVALID_TYPE = 3,
  ASSET_INVALID_SIZE = 4,
}
