import { INotificationType } from '../';

export enum RealtimeAlertType {
  DEFAULT,
  ERROR,
  WARNING,
  SUCCESS,
}

// namespace these by domain
export enum RealtimeEventType {
  HALL_PASS_GRANTED = 'HALL_PASS_GRANTED',
  HALL_PASS_NOTIFY = 'HALL_PASS_NOTIFY',
  HALL_PASS_APPROVAL_REQUEST = 'HALL_PASS_APPROVAL_REQUEST', // teacher approved flow
  HALL_PASS_APPROVAL_CANCEL = 'HALL_PASS_APPROVAL_CANCEL', // teacher approved flow
  HALL_PASS_APPROVED = 'HALL_PASS_APPROVED', // teacher approved flow
  HALL_PASS_DENIED = 'HALL_PASS_DENIED', // teacher approved flow
  HALL_PASS_ENDED = 'HALL_PASS_ENDED', // teacher approved flow
  /**
   * Generic event for when a hall pass becomes active
   */
  HALL_PASS_ACTIVE = 'HALL_PASS_ACTIVE',

  /**
   * Will show a generic system alert notification (supports basic SystemAlertModalData types)
   */
  GENERIC_NOTIFICATION = 'GENERIC_NOTIFICATION',
}

export type RealtimeDocument<T extends RealtimeEventType> = {
  createdAt: Date;
  handledAt: Date | null;
  type: T;
  mingaHash: string;
  payload: RealtimePayloadMap[T];
};

type User = {
  hash: string;
  name: string;
};

export type RealtimeHallPassMeta = {
  id: number;
  passName: string;
  issuedTo: User;
  issuedBy: User;
  approvedBy?: User;
  requestedBy?: User;
  endedBy?: User;
};

export type RealtimePayloadMap = {
  [RealtimeEventType.HALL_PASS_GRANTED]: {
    notification: {
      notificationType: INotificationType;
      title: string;
      body: string;
      metadata: {
        hallPass: RealtimeHallPassMeta;
      };
    };
  };
  [RealtimeEventType.HALL_PASS_NOTIFY]: {
    notification: {
      notificationType: INotificationType;
      title: string;
      body: string;
      metadata: {
        hallPass: RealtimeHallPassMeta;
      };
    };
  };
  [RealtimeEventType.HALL_PASS_APPROVAL_REQUEST]: {
    notification: {
      notificationType: INotificationType;
      title: string;
      body: string;
      metadata: {
        hallPass: {
          endDateIsoString: string;
          // for legacy clients only
          secondsToExpire: number;
        } & RealtimeHallPassMeta;
      };
    };
  };
  [RealtimeEventType.HALL_PASS_APPROVAL_CANCEL]: {
    notification: {
      notificationType: INotificationType;
      title: string;
      body: string;
      metadata: {
        hallPass: RealtimeHallPassMeta;
      };
    };
  };
  [RealtimeEventType.HALL_PASS_APPROVED]: {
    notification: {
      notificationType: INotificationType;
      title?: string;
      body?: string;
      metadata: {
        hallPass: RealtimeHallPassMeta;
      };
    };
  };
  [RealtimeEventType.HALL_PASS_DENIED]: {
    notification: {
      notificationType: INotificationType;
      title?: string;
      body?: string;
      metadata: {
        hallPass: RealtimeHallPassMeta;
      };
    };
  };
  [RealtimeEventType.HALL_PASS_ENDED]: {
    notification: {
      notificationType: INotificationType;
      title: string;
      body: string;
      metadata: {
        hallPass: RealtimeHallPassMeta;
      };
    };
  };
  [RealtimeEventType.HALL_PASS_ACTIVE]: {
    hallPass: RealtimeHallPassMeta;
  };
  [RealtimeEventType.GENERIC_NOTIFICATION]: {
    systemAlertData: {
      heading?: string;
      modalType?: RealtimeAlertType;
      message?: string;
      subMessage?: string;
      detailedMessage?: [string, string][];
      confirmActionBtn?: string;
      closeBtn?: string;
      disableClose?: boolean;
      icon?: string;
      iconColor?: string;
    };
  };
};
