import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
} from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';

import { IMembershipList, MembershipListType } from 'libs/domain';
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

import { ListMembershipService } from '@app/src/app/services/ListMembership';

import { ConfirmationDialogComponent } from '@shared/components/confirmation-dialog';
import {
  MODAL_OVERLAY_DATA,
  ModalOverlayPrimaryHeaderBackground,
  ModalOverlayRef,
  ModalOverlayService,
  ModalOverlayServiceCloseEventType,
} from '@shared/components/modal-overlay';
import { SystemAlertSnackBarService } from '@shared/components/system-alert-snackbar';

import { MltEditMessage } from '../../constants';
import {
  MltEditData,
  MltEditResponse,
  MltImageEditData,
  MltImageEditResponse,
} from '../../types';
import { MltImageEditComponent } from '../mlt-image-edit/mlt-image-edit.component';

@Component({
  selector: 'mg-mlt-edit',
  templateUrl: './mlt-edit.component.html',
  styleUrls: ['./mlt-edit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MltEditComponent implements OnDestroy {
  /** Constants */
  public readonly MSG = MltEditMessage;
  public readonly MODAL_CONFIG = {
    headerBg: ModalOverlayPrimaryHeaderBackground.PEOPLE_BLUE,
  };

  /** Misc Subjects */
  private readonly _destroyedSubj = new ReplaySubject<void>(1);

  /** Form Controls */
  public readonly form = this._fb.group([]);

  /** Is Loading */
  private readonly _isLoadingSubj = new BehaviorSubject<boolean>(false);
  public readonly isLoading$ = this._isLoadingSubj.asObservable();

  /** List */
  private readonly _listSubj = new BehaviorSubject<IMembershipList>(undefined);
  public readonly list$ = this._listSubj.asObservable();
  public _assetPath = this.data?.assetPath || undefined;

  get assetPath() {
    return this.assetPath;
  }

  /** Image / asset */
  public readonly imagePath$ = this._listSubj.pipe(
    takeUntil(this._destroyedSubj),
    map(list => {
      return list?.assetPath || list?.bannerImage || this._assetPath || '';
    }),
  );

  /** Can delete list */
  public readonly canDeleteList$ = this.list$.pipe(
    takeUntil(this._destroyedSubj),
    map(({ listType }) => listType !== MembershipListType.NO_ACCESS),
  );

  /** Computed Getters */
  get canSubmit(): boolean {
    return this.form.dirty && this.form.valid;
  }
  get isNewList(): boolean {
    return this.data?.id ? false : !this.data?.contextHash;
  }

  /** Component Constructor */
  constructor(
    @Inject(MODAL_OVERLAY_DATA)
    public data: MltEditData,
    private _modalOverlay: ModalOverlayRef<MltEditResponse, MltEditData>,
    private _dialog: MatDialog,
    private _systemSnack: SystemAlertSnackBarService,
    private _fb: UntypedFormBuilder,
    private _listService: ListMembershipService,
    private _imageEditModal: ModalOverlayService<
      MltImageEditResponse,
      MltImageEditData
    >,
    private _cdr: ChangeDetectorRef,
  ) {
    this._initForm();
    this._fetchList();
  }

  ngOnDestroy(): void {
    this._destroyedSubj.next();
    this._destroyedSubj.complete();
    this._listSubj.complete();
    this._isLoadingSubj.complete();
  }

  public async submit() {
    if (!this.canSubmit) return;
    const payload = this._getPayload();
    try {
      const updatedList = await this._listService.updateMembershipList(payload);
      this._modalOverlay.close(ModalOverlayServiceCloseEventType.SUBMIT, {
        updatedList,
      });
    } catch (error) {
      this._systemSnack.open({
        message: 'Something went wrong trying to update this list.',
        type: 'error',
      });
    }
  }

  public async delete() {
    const confirmationDialog = this._dialog.open(ConfirmationDialogComponent, {
      data: {
        text: {
          description: 'Are you sure you want to delete this list?',
          deleteBtn: 'Delete',
        },
      },
    });
    confirmationDialog.afterClosed().subscribe(async response => {
      if (!response || !response.confirmed || response.cancelled) return;
      if (this.isNewList) {
        this._modalOverlay.close(ModalOverlayServiceCloseEventType.CLOSE);
      } else {
        await this._deleteList();
        this._modalOverlay.close(ModalOverlayServiceCloseEventType.DELETE, {
          deletedListId: this.data.id,
        });
      }
    });
  }

  public async changeAsset(assetPath: any) {
    const list = this._listSubj.getValue();
    list.assetPath = assetPath;
    list.bannerHash = '';
    this._listSubj.next({ ...list });
    this.form.markAsDirty();
  }

  public async editImage() {
    const list = this._listSubj.getValue();
    const imagePreset =
      list.listType === MembershipListType.STICKER ? 'sticker' : undefined;
    const editImageModal = this._imageEditModal.open(MltImageEditComponent, {
      data: {
        assetPath: list.assetPath,
        bannerHash: list.bannerHash,
        imagePreset,
      },
    });
    const result = await editImageModal.afterClosed.toPromise();
    if (!result) return;
    const { type, data } = result;
    if (type === ModalOverlayServiceCloseEventType.SUBMIT) {
      if (data.assetPath) {
        this._assetPath = data.assetPath;
        list.assetPath = data.assetPath;
        list.bannerHash = '';
        this._cdr.markForCheck();
        this.form.markAsDirty();
      } else if (data.bannerHash) {
        list.bannerHash = data.bannerHash;
        list.assetPath = '';
        if (data.bannerImage) list.bannerImage = data.bannerImage;
        this.form.markAsDirty();
      }
      this._listSubj.next({ ...list });
    }
  }

  private _initForm() {
    const { changeColor, changeName, changePriority } = this.data.config;
    if (changeColor) this.form.addControl('color', this._fb.control(''));
    if (changeName)
      this.form.addControl(
        'name',
        this._fb.control('', [
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(25),
        ]),
      );
    if (changePriority)
      this.form.addControl('priority', this._fb.control(null));
  }

  private async _fetchList(): Promise<void> {
    this._isLoadingSubj.next(true);
    try {
      let result: IMembershipList;
      if (this.data.id)
        result = await this._listService.getMembershipList(this.data.id);
      else if (this.data.contextHash)
        result = await this._listService.getMembershipListByContextHash(
          this.data.contextHash,
        );
      if (result.assetPath) this._assetPath = result.assetPath;
      this._listSubj.next(result);
      this._updateFormFromList(result);
    } catch (error) {
      this._systemSnack.open({
        message: 'Something went wrong trying to fetch this list.',
        type: 'error',
      });
      console.log(error);
    }
    this._isLoadingSubj.next(false);
  }

  private async _deleteList() {
    try {
      const { id } = this._listSubj.getValue();
      await this._listService.deleteMembershipList(id);
    } catch (error) {
      this._systemSnack.open({
        message: 'Something went wrong trying to delete this list.',
        type: 'error',
      });
    }
  }

  private _updateFormFromList(list: IMembershipList) {
    if (!list) return;
    const { name, color, priority } = list;
    if (this.form.contains('name')) {
      const nameField = this.form.get('name');
      const { listType } = this._listSubj.getValue();
      nameField.reset(name);
      if (listType === MembershipListType.NO_ACCESS) nameField.disable();
    }
    if (this.form.contains('color')) this.form.get('color').reset(color);
    if (this.form.contains('priority'))
      this.form.get('priority').reset(priority);
  }

  private _getPayload(): IMembershipList {
    const list = this._listSubj.getValue();
    const payload: IMembershipList = { ...list };
    for (const field in this.form.controls) {
      if (this.form.controls.hasOwnProperty(field)) {
        payload[field] = this.form.controls[field].value;
      }
    }
    return payload;
  }
}
