// GENERATED CODE -- DO NOT EDIT!

import {grpc} from '@improbable-eng/grpc-web';

import * as gateway_content_views_pb from '../gateway/content_views_pb';
import * as common_legacy_pb from '../common/legacy_pb';
import * as common_date_pb from '../common/date_pb';
import * as gateway_comment_pb from '../gateway/comment_pb';
import * as gateway_person_view_pb from '../gateway/person_view_pb';
import * as gateway_mention_pb from '../gateway/mention_pb';
import * as gateway_banner_pb from '../gateway/banner_pb';
import * as image_image_pb from '../image/image_pb';
import * as content_common_pb from '../content/common_pb';
import * as designed_svg_designed_svg_pb from '../designed_svg/designed_svg_pb';
import * as content_poll_answer_pb from '../content/poll/answer_pb';

export class ContentViews {
  static readonly serviceName = "ContentViews";

  static readonly loadContent = {
    methodName: "loadContent",
    service: ContentViews,
    requestStream: false,
    responseStream: false,
    requestType: gateway_content_views_pb.LoadContentRequest,
    responseType: gateway_content_views_pb.LoadContentResponse,
  };

}

export class ContentViewsClient {
  loadContent() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
}
