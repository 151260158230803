import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { RouterModule } from '@angular/router';

import { MgButtonModule } from '@app/src/app/button';
import { MgImageModule } from '@app/src/app/components/MgImage';
import { SwipeContainerModule } from '@app/src/app/components/SwipeContainer';
import { MgDirectivesModule } from '@app/src/app/directives';
import { MgIconModule } from '@app/src/app/icon';
import { PermissionsModule } from '@app/src/app/permissions';
import { MgPipesModule } from '@app/src/app/pipes';

import { LightboxModule } from '..';
import { GalleryLightboxComponent } from './GalleryLightbox.component';

@NgModule({
  imports: [
    // Minga dependencies
    LightboxModule,
    SwipeContainerModule,
    MgPipesModule,
    MgImageModule,
    MgIconModule,
    MgDirectivesModule,
    MgButtonModule,
    PermissionsModule,

    // External dependencies
    CommonModule,
    FlexLayoutModule,
    RouterModule,
  ],
  declarations: [GalleryLightboxComponent],
  exports: [GalleryLightboxComponent],
})
export class GalleryLightboxModule {}
