// source: class_types/class_types.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

import * as jspb from 'google-protobuf';
import * as google_protobuf_wrappers_pb from '../google/protobuf/wrappers_pb';
import * as image_image_pb from '../image/image_pb';
export namespace HallPassTypeCount {
  export interface AsObject {
    id: number;
    name: string;
    asset?: image_image_pb.ImageInfo.AsObject;
    bannerHash: string;
    color: string;
    active: boolean;
    priority: number;
    defaultHallPassTime: number;
    requireTeacherApproval: boolean;
    count: number;
    manuallyEndPass?: google_protobuf_wrappers_pb.BoolValue.AsObject;
  }
}
export class HallPassTypeCount extends jspb.Message {
  static readonly displayName = "HallPassTypeCount";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): HallPassTypeCount.AsObject {
    return HallPassTypeCount.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!HallPassTypeCount} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: HallPassTypeCount) {
    var f: any;
    var obj: any = {
      id: jspb.Message.getFieldWithDefault(msg, 1, 0),
      name: jspb.Message.getFieldWithDefault(msg, 2, ""),
      asset: (f = msg.getAsset()) && image_image_pb.ImageInfo.toObject(includeInstance, f),
      bannerHash: jspb.Message.getFieldWithDefault(msg, 4, ""),
      color: jspb.Message.getFieldWithDefault(msg, 5, ""),
      active: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
      priority: jspb.Message.getFieldWithDefault(msg, 7, 0),
      defaultHallPassTime: jspb.Message.getFieldWithDefault(msg, 8, 0),
      requireTeacherApproval: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
      count: jspb.Message.getFieldWithDefault(msg, 10, 0),
      manuallyEndPass: (f = msg.getManuallyEndPass()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!HallPassTypeCount}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new HallPassTypeCount;
    return HallPassTypeCount.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!HallPassTypeCount} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!HallPassTypeCount}
   */
  static deserializeBinaryFromReader(msg: HallPassTypeCount, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {number} */ (reader.readInt32());
        msg.setId(value1);
        break;
      case 2:
        var value2 = /** @type {string} */ (reader.readString());
        msg.setName(value2);
        break;
      case 3:
        var value3 = new image_image_pb.ImageInfo;
        reader.readMessage(value3,image_image_pb.ImageInfo.deserializeBinaryFromReader);
        msg.setAsset(value3);
        break;
      case 4:
        var value4 = /** @type {string} */ (reader.readString());
        msg.setBannerHash(value4);
        break;
      case 5:
        var value5 = /** @type {string} */ (reader.readString());
        msg.setColor(value5);
        break;
      case 6:
        var value6 = /** @type {boolean} */ (reader.readBool());
        msg.setActive(value6);
        break;
      case 7:
        var value7 = /** @type {number} */ (reader.readInt32());
        msg.setPriority(value7);
        break;
      case 8:
        var value8 = /** @type {number} */ (reader.readInt32());
        msg.setDefaultHallPassTime(value8);
        break;
      case 9:
        var value9 = /** @type {boolean} */ (reader.readBool());
        msg.setRequireTeacherApproval(value9);
        break;
      case 10:
        var value10 = /** @type {number} */ (reader.readInt32());
        msg.setCount(value10);
        break;
      case 11:
        var value11 = new google_protobuf_wrappers_pb.BoolValue;
        reader.readMessage(value11,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
        msg.setManuallyEndPass(value11);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!HallPassTypeCount} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: HallPassTypeCount, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getId();
    if (f !== 0) {
      writer.writeInt32(
        1,
        f
      );
    }
    f = message.getName();
    if (f.length > 0) {
      writer.writeString(
        2,
        f
      );
    }
    f = message.getAsset();
    if (f != null) {
      writer.writeMessage(
        3,
        f,
        image_image_pb.ImageInfo.serializeBinaryToWriter
      );
    }
    f = message.getBannerHash();
    if (f.length > 0) {
      writer.writeString(
        4,
        f
      );
    }
    f = message.getColor();
    if (f.length > 0) {
      writer.writeString(
        5,
        f
      );
    }
    f = message.getActive();
    if (f) {
      writer.writeBool(
        6,
        f
      );
    }
    f = message.getPriority();
    if (f !== 0) {
      writer.writeInt32(
        7,
        f
      );
    }
    f = message.getDefaultHallPassTime();
    if (f !== 0) {
      writer.writeInt32(
        8,
        f
      );
    }
    f = message.getRequireTeacherApproval();
    if (f) {
      writer.writeBool(
        9,
        f
      );
    }
    f = message.getCount();
    if (f !== 0) {
      writer.writeInt32(
        10,
        f
      );
    }
    f = message.getManuallyEndPass();
    if (f != null) {
      writer.writeMessage(
        11,
        f,
        google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    HallPassTypeCount.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional int32 id = 1;
   * @return {number}
   */
  getId(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
  };


  /** @param {number} value */
  setId(value: number) {
    jspb.Message.setProto3IntField(this, 1, value);
  }


  /**
   * optional string name = 2;
   * @return {string}
   */
  getName(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
  };


  /** @param {string} value */
  setName(value: string) {
    jspb.Message.setProto3StringField(this, 2, value);
  }


  /**
   * optional ImageInfo asset = 3;
   * @return {?ImageInfo}
   */
  getAsset(): image_image_pb.ImageInfo {
    return /** @type{?ImageInfo} */ (
      jspb.Message.getWrapperField(this, image_image_pb.ImageInfo, 3));
  }


  /** @param {?ImageInfo|undefined} value */
  setAsset(value?: image_image_pb.ImageInfo) {
    jspb.Message.setWrapperField(this, 3, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearAsset() {
    this.setAsset(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasAsset(): boolean {
    return jspb.Message.getField(this, 3) != null;
  }


  /**
   * optional string banner_hash = 4;
   * @return {string}
   */
  getBannerHash(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
  };


  /** @param {string} value */
  setBannerHash(value: string) {
    jspb.Message.setProto3StringField(this, 4, value);
  }


  /**
   * optional string color = 5;
   * @return {string}
   */
  getColor(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
  };


  /** @param {string} value */
  setColor(value: string) {
    jspb.Message.setProto3StringField(this, 5, value);
  }


  /**
   * optional bool active = 6;
   * @return {boolean}
   */
  getActive(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
  };


  /** @param {boolean} value */
  setActive(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 6, value);
  }


  /**
   * optional int32 priority = 7;
   * @return {number}
   */
  getPriority(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
  };


  /** @param {number} value */
  setPriority(value: number) {
    jspb.Message.setProto3IntField(this, 7, value);
  }


  /**
   * optional int32 default_hall_pass_time = 8;
   * @return {number}
   */
  getDefaultHallPassTime(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
  };


  /** @param {number} value */
  setDefaultHallPassTime(value: number) {
    jspb.Message.setProto3IntField(this, 8, value);
  }


  /**
   * optional bool require_teacher_approval = 9;
   * @return {boolean}
   */
  getRequireTeacherApproval(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
  };


  /** @param {boolean} value */
  setRequireTeacherApproval(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 9, value);
  }


  /**
   * optional int32 count = 10;
   * @return {number}
   */
  getCount(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
  };


  /** @param {number} value */
  setCount(value: number) {
    jspb.Message.setProto3IntField(this, 10, value);
  }


  /**
   * optional google.protobuf.BoolValue manually_end_pass = 11;
   * @return {?BoolValue}
   */
  getManuallyEndPass(): google_protobuf_wrappers_pb.BoolValue {
    return /** @type{?BoolValue} */ (
      jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 11));
  }


  /** @param {?BoolValue|undefined} value */
  setManuallyEndPass(value?: google_protobuf_wrappers_pb.BoolValue) {
    jspb.Message.setWrapperField(this, 11, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearManuallyEndPass() {
    this.setManuallyEndPass(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasManuallyEndPass(): boolean {
    return jspb.Message.getField(this, 11) != null;
  }


}
export namespace ConsequenceTypeCount {
  export interface AsObject {
    id: number;
    name: string;
    description: string;
    categoryId: number;
    addNotes: boolean;
    active: boolean;
    iconType: string;
    iconColor: string;
    enableDueDate: boolean;
    count: number;
  }
}
export class ConsequenceTypeCount extends jspb.Message {
  static readonly displayName = "ConsequenceTypeCount";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): ConsequenceTypeCount.AsObject {
    return ConsequenceTypeCount.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!ConsequenceTypeCount} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: ConsequenceTypeCount) {
    var f: any;
    var obj: any = {
      id: jspb.Message.getFieldWithDefault(msg, 1, 0),
      name: jspb.Message.getFieldWithDefault(msg, 2, ""),
      description: jspb.Message.getFieldWithDefault(msg, 3, ""),
      categoryId: jspb.Message.getFieldWithDefault(msg, 4, 0),
      addNotes: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
      active: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
      iconType: jspb.Message.getFieldWithDefault(msg, 7, ""),
      iconColor: jspb.Message.getFieldWithDefault(msg, 8, ""),
      enableDueDate: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
      count: jspb.Message.getFieldWithDefault(msg, 10, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!ConsequenceTypeCount}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new ConsequenceTypeCount;
    return ConsequenceTypeCount.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!ConsequenceTypeCount} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!ConsequenceTypeCount}
   */
  static deserializeBinaryFromReader(msg: ConsequenceTypeCount, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {number} */ (reader.readInt32());
        msg.setId(value1);
        break;
      case 2:
        var value2 = /** @type {string} */ (reader.readString());
        msg.setName(value2);
        break;
      case 3:
        var value3 = /** @type {string} */ (reader.readString());
        msg.setDescription(value3);
        break;
      case 4:
        var value4 = /** @type {number} */ (reader.readInt32());
        msg.setCategoryId(value4);
        break;
      case 5:
        var value5 = /** @type {boolean} */ (reader.readBool());
        msg.setAddNotes(value5);
        break;
      case 6:
        var value6 = /** @type {boolean} */ (reader.readBool());
        msg.setActive(value6);
        break;
      case 7:
        var value7 = /** @type {string} */ (reader.readString());
        msg.setIconType(value7);
        break;
      case 8:
        var value8 = /** @type {string} */ (reader.readString());
        msg.setIconColor(value8);
        break;
      case 9:
        var value9 = /** @type {boolean} */ (reader.readBool());
        msg.setEnableDueDate(value9);
        break;
      case 10:
        var value10 = /** @type {number} */ (reader.readInt32());
        msg.setCount(value10);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!ConsequenceTypeCount} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: ConsequenceTypeCount, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getId();
    if (f !== 0) {
      writer.writeInt32(
        1,
        f
      );
    }
    f = message.getName();
    if (f.length > 0) {
      writer.writeString(
        2,
        f
      );
    }
    f = message.getDescription();
    if (f.length > 0) {
      writer.writeString(
        3,
        f
      );
    }
    f = message.getCategoryId();
    if (f !== 0) {
      writer.writeInt32(
        4,
        f
      );
    }
    f = message.getAddNotes();
    if (f) {
      writer.writeBool(
        5,
        f
      );
    }
    f = message.getActive();
    if (f) {
      writer.writeBool(
        6,
        f
      );
    }
    f = message.getIconType();
    if (f.length > 0) {
      writer.writeString(
        7,
        f
      );
    }
    f = message.getIconColor();
    if (f.length > 0) {
      writer.writeString(
        8,
        f
      );
    }
    f = message.getEnableDueDate();
    if (f) {
      writer.writeBool(
        9,
        f
      );
    }
    f = message.getCount();
    if (f !== 0) {
      writer.writeInt32(
        10,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    ConsequenceTypeCount.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional int32 id = 1;
   * @return {number}
   */
  getId(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
  };


  /** @param {number} value */
  setId(value: number) {
    jspb.Message.setProto3IntField(this, 1, value);
  }


  /**
   * optional string name = 2;
   * @return {string}
   */
  getName(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
  };


  /** @param {string} value */
  setName(value: string) {
    jspb.Message.setProto3StringField(this, 2, value);
  }


  /**
   * optional string description = 3;
   * @return {string}
   */
  getDescription(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
  };


  /** @param {string} value */
  setDescription(value: string) {
    jspb.Message.setProto3StringField(this, 3, value);
  }


  /**
   * optional int32 category_id = 4;
   * @return {number}
   */
  getCategoryId(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
  };


  /** @param {number} value */
  setCategoryId(value: number) {
    jspb.Message.setProto3IntField(this, 4, value);
  }


  /**
   * optional bool add_notes = 5;
   * @return {boolean}
   */
  getAddNotes(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
  };


  /** @param {boolean} value */
  setAddNotes(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 5, value);
  }


  /**
   * optional bool active = 6;
   * @return {boolean}
   */
  getActive(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
  };


  /** @param {boolean} value */
  setActive(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 6, value);
  }


  /**
   * optional string icon_type = 7;
   * @return {string}
   */
  getIconType(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
  };


  /** @param {string} value */
  setIconType(value: string) {
    jspb.Message.setProto3StringField(this, 7, value);
  }


  /**
   * optional string icon_color = 8;
   * @return {string}
   */
  getIconColor(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
  };


  /** @param {string} value */
  setIconColor(value: string) {
    jspb.Message.setProto3StringField(this, 8, value);
  }


  /**
   * optional bool enable_due_date = 9;
   * @return {boolean}
   */
  getEnableDueDate(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
  };


  /** @param {boolean} value */
  setEnableDueDate(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 9, value);
  }


  /**
   * optional int32 count = 10;
   * @return {number}
   */
  getCount(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
  };


  /** @param {number} value */
  setCount(value: number) {
    jspb.Message.setProto3IntField(this, 10, value);
  }


}
export namespace PbisTypeCount {
  export interface AsObject {
    id: number;
    name: string;
    categoryId: number;
    active: boolean;
    iconType: string;
    iconColor: string;
    count: number;
  }
}
export class PbisTypeCount extends jspb.Message {
  static readonly displayName = "PbisTypeCount";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): PbisTypeCount.AsObject {
    return PbisTypeCount.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!PbisTypeCount} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: PbisTypeCount) {
    var f: any;
    var obj: any = {
      id: jspb.Message.getFieldWithDefault(msg, 1, 0),
      name: jspb.Message.getFieldWithDefault(msg, 2, ""),
      categoryId: jspb.Message.getFieldWithDefault(msg, 3, 0),
      active: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
      iconType: jspb.Message.getFieldWithDefault(msg, 5, ""),
      iconColor: jspb.Message.getFieldWithDefault(msg, 6, ""),
      count: jspb.Message.getFieldWithDefault(msg, 7, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!PbisTypeCount}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new PbisTypeCount;
    return PbisTypeCount.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!PbisTypeCount} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!PbisTypeCount}
   */
  static deserializeBinaryFromReader(msg: PbisTypeCount, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {number} */ (reader.readInt32());
        msg.setId(value1);
        break;
      case 2:
        var value2 = /** @type {string} */ (reader.readString());
        msg.setName(value2);
        break;
      case 3:
        var value3 = /** @type {number} */ (reader.readInt32());
        msg.setCategoryId(value3);
        break;
      case 4:
        var value4 = /** @type {boolean} */ (reader.readBool());
        msg.setActive(value4);
        break;
      case 5:
        var value5 = /** @type {string} */ (reader.readString());
        msg.setIconType(value5);
        break;
      case 6:
        var value6 = /** @type {string} */ (reader.readString());
        msg.setIconColor(value6);
        break;
      case 7:
        var value7 = /** @type {number} */ (reader.readInt32());
        msg.setCount(value7);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!PbisTypeCount} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: PbisTypeCount, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getId();
    if (f !== 0) {
      writer.writeInt32(
        1,
        f
      );
    }
    f = message.getName();
    if (f.length > 0) {
      writer.writeString(
        2,
        f
      );
    }
    f = message.getCategoryId();
    if (f !== 0) {
      writer.writeInt32(
        3,
        f
      );
    }
    f = message.getActive();
    if (f) {
      writer.writeBool(
        4,
        f
      );
    }
    f = message.getIconType();
    if (f.length > 0) {
      writer.writeString(
        5,
        f
      );
    }
    f = message.getIconColor();
    if (f.length > 0) {
      writer.writeString(
        6,
        f
      );
    }
    f = message.getCount();
    if (f !== 0) {
      writer.writeInt32(
        7,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    PbisTypeCount.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional int32 id = 1;
   * @return {number}
   */
  getId(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
  };


  /** @param {number} value */
  setId(value: number) {
    jspb.Message.setProto3IntField(this, 1, value);
  }


  /**
   * optional string name = 2;
   * @return {string}
   */
  getName(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
  };


  /** @param {string} value */
  setName(value: string) {
    jspb.Message.setProto3StringField(this, 2, value);
  }


  /**
   * optional int32 category_id = 3;
   * @return {number}
   */
  getCategoryId(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
  };


  /** @param {number} value */
  setCategoryId(value: number) {
    jspb.Message.setProto3IntField(this, 3, value);
  }


  /**
   * optional bool active = 4;
   * @return {boolean}
   */
  getActive(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
  };


  /** @param {boolean} value */
  setActive(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 4, value);
  }


  /**
   * optional string icon_type = 5;
   * @return {string}
   */
  getIconType(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
  };


  /** @param {string} value */
  setIconType(value: string) {
    jspb.Message.setProto3StringField(this, 5, value);
  }


  /**
   * optional string icon_color = 6;
   * @return {string}
   */
  getIconColor(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
  };


  /** @param {string} value */
  setIconColor(value: string) {
    jspb.Message.setProto3StringField(this, 6, value);
  }


  /**
   * optional int32 count = 7;
   * @return {number}
   */
  getCount(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
  };


  /** @param {number} value */
  setCount(value: number) {
    jspb.Message.setProto3IntField(this, 7, value);
  }


}
export namespace GetClassTypesRequest {
  export interface AsObject {
    listId: number;
  }
}
export class GetClassTypesRequest extends jspb.Message {
  static readonly displayName = "GetClassTypesRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): GetClassTypesRequest.AsObject {
    return GetClassTypesRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!GetClassTypesRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: GetClassTypesRequest) {
    var f: any;
    var obj: any = {
      listId: jspb.Message.getFieldWithDefault(msg, 1, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!GetClassTypesRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new GetClassTypesRequest;
    return GetClassTypesRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!GetClassTypesRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!GetClassTypesRequest}
   */
  static deserializeBinaryFromReader(msg: GetClassTypesRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {number} */ (reader.readInt32());
        msg.setListId(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!GetClassTypesRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: GetClassTypesRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getListId();
    if (f !== 0) {
      writer.writeInt32(
        1,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    GetClassTypesRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional int32 list_id = 1;
   * @return {number}
   */
  getListId(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
  };


  /** @param {number} value */
  setListId(value: number) {
    jspb.Message.setProto3IntField(this, 1, value);
  }


}
export namespace GetClassTypesResponse {
  export interface AsObject {
    hallpassTypeCountsList: HallPassTypeCount.AsObject[];
    consequenceTypeCountsList: ConsequenceTypeCount.AsObject[];
    pbisTypeCountsList: PbisTypeCount.AsObject[];
  }
}
export class GetClassTypesResponse extends jspb.Message {
  static readonly displayName = "GetClassTypesResponse";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [1,2,3];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, GetClassTypesResponse.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): GetClassTypesResponse.AsObject {
    return GetClassTypesResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!GetClassTypesResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: GetClassTypesResponse) {
    var f: any;
    var obj: any = {
      hallpassTypeCountsList: jspb.Message.toObjectList(msg.getHallpassTypeCountsList(),
      HallPassTypeCount.toObject, includeInstance),
      consequenceTypeCountsList: jspb.Message.toObjectList(msg.getConsequenceTypeCountsList(),
      ConsequenceTypeCount.toObject, includeInstance),
      pbisTypeCountsList: jspb.Message.toObjectList(msg.getPbisTypeCountsList(),
      PbisTypeCount.toObject, includeInstance)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!GetClassTypesResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new GetClassTypesResponse;
    return GetClassTypesResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!GetClassTypesResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!GetClassTypesResponse}
   */
  static deserializeBinaryFromReader(msg: GetClassTypesResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new HallPassTypeCount;
        reader.readMessage(value1,HallPassTypeCount.deserializeBinaryFromReader);
        msg.addHallpassTypeCounts(value1);
        break;
      case 2:
        var value2 = new ConsequenceTypeCount;
        reader.readMessage(value2,ConsequenceTypeCount.deserializeBinaryFromReader);
        msg.addConsequenceTypeCounts(value2);
        break;
      case 3:
        var value3 = new PbisTypeCount;
        reader.readMessage(value3,PbisTypeCount.deserializeBinaryFromReader);
        msg.addPbisTypeCounts(value3);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!GetClassTypesResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: GetClassTypesResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getHallpassTypeCountsList();
    if (f.length > 0) {
      writer.writeRepeatedMessage(
        1,
        f,
        HallPassTypeCount.serializeBinaryToWriter
      );
    }
    f = message.getConsequenceTypeCountsList();
    if (f.length > 0) {
      writer.writeRepeatedMessage(
        2,
        f,
        ConsequenceTypeCount.serializeBinaryToWriter
      );
    }
    f = message.getPbisTypeCountsList();
    if (f.length > 0) {
      writer.writeRepeatedMessage(
        3,
        f,
        PbisTypeCount.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    GetClassTypesResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * repeated HallPassTypeCount hallpass_type_counts = 1;
   * @return {!Array<!HallPassTypeCount>}
   */
  getHallpassTypeCountsList(): HallPassTypeCount[] {
    return /** @type{!Array<!HallPassTypeCount>} */ (
      jspb.Message.getRepeatedWrapperField(this, HallPassTypeCount, 1));
  }


  /** @param {!Array<!HallPassTypeCount>} value */
  setHallpassTypeCountsList(value?: HallPassTypeCount[]) {
    jspb.Message.setRepeatedWrapperField(this, 1, value);
  }


  /**
   * @param {!HallPassTypeCount=} opt_value
   * @param {number=} opt_index
   * @return {!HallPassTypeCount}
   */
  addHallpassTypeCounts(opt_value?: HallPassTypeCount, opt_index?: number): HallPassTypeCount {
    return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, HallPassTypeCount, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearHallpassTypeCountsList() {
    this.setHallpassTypeCountsList([]);
  }


  /**
   * repeated ConsequenceTypeCount consequence_type_counts = 2;
   * @return {!Array<!ConsequenceTypeCount>}
   */
  getConsequenceTypeCountsList(): ConsequenceTypeCount[] {
    return /** @type{!Array<!ConsequenceTypeCount>} */ (
      jspb.Message.getRepeatedWrapperField(this, ConsequenceTypeCount, 2));
  }


  /** @param {!Array<!ConsequenceTypeCount>} value */
  setConsequenceTypeCountsList(value?: ConsequenceTypeCount[]) {
    jspb.Message.setRepeatedWrapperField(this, 2, value);
  }


  /**
   * @param {!ConsequenceTypeCount=} opt_value
   * @param {number=} opt_index
   * @return {!ConsequenceTypeCount}
   */
  addConsequenceTypeCounts(opt_value?: ConsequenceTypeCount, opt_index?: number): ConsequenceTypeCount {
    return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, ConsequenceTypeCount, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearConsequenceTypeCountsList() {
    this.setConsequenceTypeCountsList([]);
  }


  /**
   * repeated PbisTypeCount pbis_type_counts = 3;
   * @return {!Array<!PbisTypeCount>}
   */
  getPbisTypeCountsList(): PbisTypeCount[] {
    return /** @type{!Array<!PbisTypeCount>} */ (
      jspb.Message.getRepeatedWrapperField(this, PbisTypeCount, 3));
  }


  /** @param {!Array<!PbisTypeCount>} value */
  setPbisTypeCountsList(value?: PbisTypeCount[]) {
    jspb.Message.setRepeatedWrapperField(this, 3, value);
  }


  /**
   * @param {!PbisTypeCount=} opt_value
   * @param {number=} opt_index
   * @return {!PbisTypeCount}
   */
  addPbisTypeCounts(opt_value?: PbisTypeCount, opt_index?: number): PbisTypeCount {
    return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, PbisTypeCount, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearPbisTypeCountsList() {
    this.setPbisTypeCountsList([]);
  }


}
export namespace TypeCounts {
  export interface AsObject {
    id: number;
    count: number;
  }
}
export class TypeCounts extends jspb.Message {
  static readonly displayName = "TypeCounts";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): TypeCounts.AsObject {
    return TypeCounts.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!TypeCounts} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: TypeCounts) {
    var f: any;
    var obj: any = {
      id: jspb.Message.getFieldWithDefault(msg, 1, 0),
      count: jspb.Message.getFieldWithDefault(msg, 2, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!TypeCounts}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new TypeCounts;
    return TypeCounts.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!TypeCounts} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!TypeCounts}
   */
  static deserializeBinaryFromReader(msg: TypeCounts, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {number} */ (reader.readInt32());
        msg.setId(value1);
        break;
      case 2:
        var value2 = /** @type {number} */ (reader.readInt32());
        msg.setCount(value2);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!TypeCounts} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: TypeCounts, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getId();
    if (f !== 0) {
      writer.writeInt32(
        1,
        f
      );
    }
    f = message.getCount();
    if (f !== 0) {
      writer.writeInt32(
        2,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    TypeCounts.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional int32 id = 1;
   * @return {number}
   */
  getId(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
  };


  /** @param {number} value */
  setId(value: number) {
    jspb.Message.setProto3IntField(this, 1, value);
  }


  /**
   * optional int32 count = 2;
   * @return {number}
   */
  getCount(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
  };


  /** @param {number} value */
  setCount(value: number) {
    jspb.Message.setProto3IntField(this, 2, value);
  }


}
export namespace GetListTypeCountsRequest {
  export interface AsObject {
    listId: number;
  }
}
export class GetListTypeCountsRequest extends jspb.Message {
  static readonly displayName = "GetListTypeCountsRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): GetListTypeCountsRequest.AsObject {
    return GetListTypeCountsRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!GetListTypeCountsRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: GetListTypeCountsRequest) {
    var f: any;
    var obj: any = {
      listId: jspb.Message.getFieldWithDefault(msg, 1, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!GetListTypeCountsRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new GetListTypeCountsRequest;
    return GetListTypeCountsRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!GetListTypeCountsRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!GetListTypeCountsRequest}
   */
  static deserializeBinaryFromReader(msg: GetListTypeCountsRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {number} */ (reader.readInt32());
        msg.setListId(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!GetListTypeCountsRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: GetListTypeCountsRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getListId();
    if (f !== 0) {
      writer.writeInt32(
        1,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    GetListTypeCountsRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional int32 list_id = 1;
   * @return {number}
   */
  getListId(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
  };


  /** @param {number} value */
  setListId(value: number) {
    jspb.Message.setProto3IntField(this, 1, value);
  }


}
export namespace GetListTypeCountsResponse {
  export interface AsObject {
    hallpassCountsList: TypeCounts.AsObject[];
    consequenceCountsList: TypeCounts.AsObject[];
    pbisCountsList: TypeCounts.AsObject[];
  }
}
export class GetListTypeCountsResponse extends jspb.Message {
  static readonly displayName = "GetListTypeCountsResponse";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [1,2,3];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, GetListTypeCountsResponse.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): GetListTypeCountsResponse.AsObject {
    return GetListTypeCountsResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!GetListTypeCountsResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: GetListTypeCountsResponse) {
    var f: any;
    var obj: any = {
      hallpassCountsList: jspb.Message.toObjectList(msg.getHallpassCountsList(),
      TypeCounts.toObject, includeInstance),
      consequenceCountsList: jspb.Message.toObjectList(msg.getConsequenceCountsList(),
      TypeCounts.toObject, includeInstance),
      pbisCountsList: jspb.Message.toObjectList(msg.getPbisCountsList(),
      TypeCounts.toObject, includeInstance)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!GetListTypeCountsResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new GetListTypeCountsResponse;
    return GetListTypeCountsResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!GetListTypeCountsResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!GetListTypeCountsResponse}
   */
  static deserializeBinaryFromReader(msg: GetListTypeCountsResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new TypeCounts;
        reader.readMessage(value1,TypeCounts.deserializeBinaryFromReader);
        msg.addHallpassCounts(value1);
        break;
      case 2:
        var value2 = new TypeCounts;
        reader.readMessage(value2,TypeCounts.deserializeBinaryFromReader);
        msg.addConsequenceCounts(value2);
        break;
      case 3:
        var value3 = new TypeCounts;
        reader.readMessage(value3,TypeCounts.deserializeBinaryFromReader);
        msg.addPbisCounts(value3);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!GetListTypeCountsResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: GetListTypeCountsResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getHallpassCountsList();
    if (f.length > 0) {
      writer.writeRepeatedMessage(
        1,
        f,
        TypeCounts.serializeBinaryToWriter
      );
    }
    f = message.getConsequenceCountsList();
    if (f.length > 0) {
      writer.writeRepeatedMessage(
        2,
        f,
        TypeCounts.serializeBinaryToWriter
      );
    }
    f = message.getPbisCountsList();
    if (f.length > 0) {
      writer.writeRepeatedMessage(
        3,
        f,
        TypeCounts.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    GetListTypeCountsResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * repeated TypeCounts hallpass_counts = 1;
   * @return {!Array<!TypeCounts>}
   */
  getHallpassCountsList(): TypeCounts[] {
    return /** @type{!Array<!TypeCounts>} */ (
      jspb.Message.getRepeatedWrapperField(this, TypeCounts, 1));
  }


  /** @param {!Array<!TypeCounts>} value */
  setHallpassCountsList(value?: TypeCounts[]) {
    jspb.Message.setRepeatedWrapperField(this, 1, value);
  }


  /**
   * @param {!TypeCounts=} opt_value
   * @param {number=} opt_index
   * @return {!TypeCounts}
   */
  addHallpassCounts(opt_value?: TypeCounts, opt_index?: number): TypeCounts {
    return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, TypeCounts, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearHallpassCountsList() {
    this.setHallpassCountsList([]);
  }


  /**
   * repeated TypeCounts consequence_counts = 2;
   * @return {!Array<!TypeCounts>}
   */
  getConsequenceCountsList(): TypeCounts[] {
    return /** @type{!Array<!TypeCounts>} */ (
      jspb.Message.getRepeatedWrapperField(this, TypeCounts, 2));
  }


  /** @param {!Array<!TypeCounts>} value */
  setConsequenceCountsList(value?: TypeCounts[]) {
    jspb.Message.setRepeatedWrapperField(this, 2, value);
  }


  /**
   * @param {!TypeCounts=} opt_value
   * @param {number=} opt_index
   * @return {!TypeCounts}
   */
  addConsequenceCounts(opt_value?: TypeCounts, opt_index?: number): TypeCounts {
    return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, TypeCounts, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearConsequenceCountsList() {
    this.setConsequenceCountsList([]);
  }


  /**
   * repeated TypeCounts pbis_counts = 3;
   * @return {!Array<!TypeCounts>}
   */
  getPbisCountsList(): TypeCounts[] {
    return /** @type{!Array<!TypeCounts>} */ (
      jspb.Message.getRepeatedWrapperField(this, TypeCounts, 3));
  }


  /** @param {!Array<!TypeCounts>} value */
  setPbisCountsList(value?: TypeCounts[]) {
    jspb.Message.setRepeatedWrapperField(this, 3, value);
  }


  /**
   * @param {!TypeCounts=} opt_value
   * @param {number=} opt_index
   * @return {!TypeCounts}
   */
  addPbisCounts(opt_value?: TypeCounts, opt_index?: number): TypeCounts {
    return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, TypeCounts, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearPbisCountsList() {
    this.setPbisCountsList([]);
  }


}
