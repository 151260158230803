// source: badge/badge.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

import * as jspb from 'google-protobuf';
import * as common_date_pb from '../common/date_pb';
import * as image_image_pb from '../image/image_pb';
export namespace Badge {
  export interface AsObject {
    id: number;
    name: string;
    description: string;
    asset?: image_image_pb.ImageInfo.AsObject;
    banner?: image_image_pb.ImageInfo.AsObject;
    date?: common_date_pb.DateTime.AsObject;
    contentContextHashList: string[];
  }
}
export class Badge extends jspb.Message {
  static readonly displayName = "Badge";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [20];

  /**
   * Oneof group definitions for this message. Each group defines the field
   * numbers belonging to that group. When of these fields' value is set, all
   * other fields in the group are cleared. During deserialization, if multiple
   * fields are encountered for a group, only the last value seen will be kept.
   * @private {!Array<!Array<number>>}
   * @const
   */
  static readonly oneofGroups_ = [[4,5]];


  /**
   * @return {Badge.ImageCase}
   */
  getImageCase() {
    return /** @type {Badge.ImageCase} */(jspb.Message.computeOneofCase(this, Badge.oneofGroups_[0]));
  }

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, Badge.repeatedFields_, Badge.oneofGroups_);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): Badge.AsObject {
    return Badge.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!Badge} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: Badge) {
    var f: any;
    var obj: any = {
      id: jspb.Message.getFieldWithDefault(msg, 1, 0),
      name: jspb.Message.getFieldWithDefault(msg, 2, ""),
      description: jspb.Message.getFieldWithDefault(msg, 3, ""),
      asset: (f = msg.getAsset()) && image_image_pb.ImageInfo.toObject(includeInstance, f),
      banner: (f = msg.getBanner()) && image_image_pb.ImageInfo.toObject(includeInstance, f),
      date: (f = msg.getDate()) && common_date_pb.DateTime.toObject(includeInstance, f),
      contentContextHashList: (f = jspb.Message.getRepeatedField(msg, 20)) == null ? undefined : f
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!Badge}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new Badge;
    return Badge.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!Badge} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!Badge}
   */
  static deserializeBinaryFromReader(msg: Badge, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {number} */ (reader.readInt32());
        msg.setId(value1);
        break;
      case 2:
        var value2 = /** @type {string} */ (reader.readString());
        msg.setName(value2);
        break;
      case 3:
        var value3 = /** @type {string} */ (reader.readString());
        msg.setDescription(value3);
        break;
      case 4:
        var value4 = new image_image_pb.ImageInfo;
        reader.readMessage(value4,image_image_pb.ImageInfo.deserializeBinaryFromReader);
        msg.setAsset(value4);
        break;
      case 5:
        var value5 = new image_image_pb.ImageInfo;
        reader.readMessage(value5,image_image_pb.ImageInfo.deserializeBinaryFromReader);
        msg.setBanner(value5);
        break;
      case 6:
        var value6 = new common_date_pb.DateTime;
        reader.readMessage(value6,common_date_pb.DateTime.deserializeBinaryFromReader);
        msg.setDate(value6);
        break;
      case 20:
        var value20 = /** @type {string} */ (reader.readString());
        msg.addContentContextHash(value20);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!Badge} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: Badge, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getId();
    if (f !== 0) {
      writer.writeInt32(
        1,
        f
      );
    }
    f = message.getName();
    if (f.length > 0) {
      writer.writeString(
        2,
        f
      );
    }
    f = message.getDescription();
    if (f.length > 0) {
      writer.writeString(
        3,
        f
      );
    }
    f = message.getAsset();
    if (f != null) {
      writer.writeMessage(
        4,
        f,
        image_image_pb.ImageInfo.serializeBinaryToWriter
      );
    }
    f = message.getBanner();
    if (f != null) {
      writer.writeMessage(
        5,
        f,
        image_image_pb.ImageInfo.serializeBinaryToWriter
      );
    }
    f = message.getDate();
    if (f != null) {
      writer.writeMessage(
        6,
        f,
        common_date_pb.DateTime.serializeBinaryToWriter
      );
    }
    f = message.getContentContextHashList();
    if (f.length > 0) {
      writer.writeRepeatedString(
        20,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    Badge.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional int32 id = 1;
   * @return {number}
   */
  getId(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
  };


  /** @param {number} value */
  setId(value: number) {
    jspb.Message.setProto3IntField(this, 1, value);
  }


  /**
   * optional string name = 2;
   * @return {string}
   */
  getName(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
  };


  /** @param {string} value */
  setName(value: string) {
    jspb.Message.setProto3StringField(this, 2, value);
  }


  /**
   * optional string description = 3;
   * @return {string}
   */
  getDescription(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
  };


  /** @param {string} value */
  setDescription(value: string) {
    jspb.Message.setProto3StringField(this, 3, value);
  }


  /**
   * optional ImageInfo asset = 4;
   * @return {?ImageInfo}
   */
  getAsset(): image_image_pb.ImageInfo {
    return /** @type{?ImageInfo} */ (
      jspb.Message.getWrapperField(this, image_image_pb.ImageInfo, 4));
  }


  /** @param {?ImageInfo|undefined} value */
  setAsset(value?: image_image_pb.ImageInfo) {
    jspb.Message.setOneofWrapperField(this, 4, Badge.oneofGroups_[0], value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearAsset() {
    this.setAsset(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasAsset(): boolean {
    return jspb.Message.getField(this, 4) != null;
  }


  /**
   * optional ImageInfo banner = 5;
   * @return {?ImageInfo}
   */
  getBanner(): image_image_pb.ImageInfo {
    return /** @type{?ImageInfo} */ (
      jspb.Message.getWrapperField(this, image_image_pb.ImageInfo, 5));
  }


  /** @param {?ImageInfo|undefined} value */
  setBanner(value?: image_image_pb.ImageInfo) {
    jspb.Message.setOneofWrapperField(this, 5, Badge.oneofGroups_[0], value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearBanner() {
    this.setBanner(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasBanner(): boolean {
    return jspb.Message.getField(this, 5) != null;
  }


  /**
   * optional minga.common.DateTime date = 6;
   * @return {?DateTime}
   */
  getDate(): common_date_pb.DateTime {
    return /** @type{?DateTime} */ (
      jspb.Message.getWrapperField(this, common_date_pb.DateTime, 6));
  }


  /** @param {?DateTime|undefined} value */
  setDate(value?: common_date_pb.DateTime) {
    jspb.Message.setWrapperField(this, 6, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearDate() {
    this.setDate(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasDate(): boolean {
    return jspb.Message.getField(this, 6) != null;
  }


  /**
   * repeated string content_context_hash = 20;
   * @return {!Array<string>}
   */
  getContentContextHashList(): string[] {
    return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 20));
  };


  /** @param {!Array<string>} value */
  setContentContextHashList(value: string[]) {
    jspb.Message.setField(this, 20, value || []);
  }


  /**
   * @param {string} value
   * @param {number=} opt_index
   */
  addContentContextHash(value: string, opt_index?: number): void {
    jspb.Message.addToRepeatedField(this, 20, value, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearContentContextHashList() {
    this.setContentContextHashList([]);
  }


}
export namespace Badge {
export enum ImageCase {
  IMAGE_NOT_SET = 0,
  ASSET = 4,
  BANNER = 5,
}
} // namespace Badge
export namespace GetPersonBadgesRequest {
  export interface AsObject {
    personHash: string;
  }
}
export class GetPersonBadgesRequest extends jspb.Message {
  static readonly displayName = "GetPersonBadgesRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): GetPersonBadgesRequest.AsObject {
    return GetPersonBadgesRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!GetPersonBadgesRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: GetPersonBadgesRequest) {
    var f: any;
    var obj: any = {
      personHash: jspb.Message.getFieldWithDefault(msg, 1, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!GetPersonBadgesRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new GetPersonBadgesRequest;
    return GetPersonBadgesRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!GetPersonBadgesRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!GetPersonBadgesRequest}
   */
  static deserializeBinaryFromReader(msg: GetPersonBadgesRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setPersonHash(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!GetPersonBadgesRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: GetPersonBadgesRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getPersonHash();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    GetPersonBadgesRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string person_hash = 1;
   * @return {string}
   */
  getPersonHash(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setPersonHash(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


}
export namespace GetPersonBadgesResponse {
  export interface AsObject {
    badgeList: Badge.AsObject[];
  }
}
export class GetPersonBadgesResponse extends jspb.Message {
  static readonly displayName = "GetPersonBadgesResponse";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [1];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, GetPersonBadgesResponse.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): GetPersonBadgesResponse.AsObject {
    return GetPersonBadgesResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!GetPersonBadgesResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: GetPersonBadgesResponse) {
    var f: any;
    var obj: any = {
      badgeList: jspb.Message.toObjectList(msg.getBadgeList(),
      Badge.toObject, includeInstance)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!GetPersonBadgesResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new GetPersonBadgesResponse;
    return GetPersonBadgesResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!GetPersonBadgesResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!GetPersonBadgesResponse}
   */
  static deserializeBinaryFromReader(msg: GetPersonBadgesResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new Badge;
        reader.readMessage(value1,Badge.deserializeBinaryFromReader);
        msg.addBadge(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!GetPersonBadgesResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: GetPersonBadgesResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getBadgeList();
    if (f.length > 0) {
      writer.writeRepeatedMessage(
        1,
        f,
        Badge.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    GetPersonBadgesResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * repeated Badge badge = 1;
   * @return {!Array<!Badge>}
   */
  getBadgeList(): Badge[] {
    return /** @type{!Array<!Badge>} */ (
      jspb.Message.getRepeatedWrapperField(this, Badge, 1));
  }


  /** @param {!Array<!Badge>} value */
  setBadgeList(value?: Badge[]) {
    jspb.Message.setRepeatedWrapperField(this, 1, value);
  }


  /**
   * @param {!Badge=} opt_value
   * @param {number=} opt_index
   * @return {!Badge}
   */
  addBadge(opt_value?: Badge, opt_index?: number): Badge {
    return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, Badge, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearBadgeList() {
    this.setBadgeList([]);
  }


}
