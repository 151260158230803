import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnDestroy,
} from '@angular/core';
import {
  UntypedFormArray,
  UntypedFormBuilder,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';

import { PointReward } from 'libs/domain';
import { BehaviorSubject, ReplaySubject, combineLatest } from 'rxjs';
import { map, shareReplay, takeUntil, tap } from 'rxjs/operators';

import { ConfirmationDialogComponent } from '@shared/components/confirmation-dialog';
import {
  MODAL_OVERLAY_DATA,
  ModalOverlayPrimaryHeaderBackground,
  ModalOverlayRef,
  ModalOverlayServiceCloseEventType,
} from '@shared/components/modal-overlay';
import { PointsService } from '@shared/services/points';

import {
  REDEEM_POINTS_MODAL_DISPLAYED_COLUMNS,
  REDEEM_POINTS_MODAL_FORMGROUP,
  RedeemPointsModalFormField,
  RedeemPointsModalMessages,
} from './constants';
import { RedeemPointsModalService } from './services';
import { RedeemPointsModalData, RedeemPointsModalResponse } from './types';

@Component({
  selector: 'mg-redeem-points-modal',
  templateUrl: './redeem-points-modal.component.html',
  styleUrls: ['./redeem-points-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RedeemPointsModalComponent implements OnDestroy {
  /** Constants */
  public readonly MESSAGES = RedeemPointsModalMessages;
  public readonly MODAL_CONFIG = {
    headerBg: ModalOverlayPrimaryHeaderBackground.GREEN,
  };
  public readonly DISPLAYED_COLUMNS = REDEEM_POINTS_MODAL_DISPLAYED_COLUMNS;
  public readonly FORM_FIELD = RedeemPointsModalFormField;

  /** General Observables */
  private _destroyed = new ReplaySubject<void>(1);
  public readonly isLoading$ = this._redeemPointsService.isLoading$.pipe(
    takeUntil(this._destroyed),
  );

  private _isSubmitting = false;

  /** Form Controls */
  public readonly form = this._fb.group({
    ...REDEEM_POINTS_MODAL_FORMGROUP,
    [RedeemPointsModalFormField.QUANTITY]: this._fb.array(
      [],
      [Validators.required],
    ),
  });

  /** Student Details */
  public readonly student$ = this._redeemPointsService.student$.pipe(
    takeUntil(this._destroyed),
    tap(() => {
      this.form.controls[this.FORM_FIELD.ADD_REWARD].enable();
      this.rewardItemsForm.enable();
    }),
  );

  /** Selected Reward Items */
  private readonly _selectedRewardItemIds = new BehaviorSubject<number[]>([]);
  public readonly selectedRewardItemsIds$ = this._selectedRewardItemIds
    .asObservable()
    .pipe(shareReplay());
  public readonly selectedRewardItems$ = combineLatest([
    this._redeemPointsService.rewardTypes$,
    this.selectedRewardItemsIds$,
  ]).pipe(
    takeUntil(this._destroyed),
    map(([all, selected]) => {
      return selected.reduce((mapped, id) => {
        const reward = all.find(rewardItem => rewardItem.id === id);
        if (reward) mapped.push(reward);
        return mapped;
      }, []);
    }),
  );

  /** Reward Types Options */
  public readonly rewardOptions$ = combineLatest([
    this._redeemPointsService.rewardTypes$,
    this.selectedRewardItemsIds$,
  ]).pipe(
    takeUntil(this._destroyed),
    map(([all, selected]) => {
      return all.reduce((result, { id, name }) => {
        if (!selected.includes(id)) {
          result.push({
            label: name,
            value: id,
          });
        }
        return result;
      }, []);
    }),
  );

  /** Total Cost */
  private _totalCost: number;
  public readonly totalCost$ = combineLatest([
    this.selectedRewardItems$,
    this.rewardItemsForm.valueChanges,
  ]).pipe(
    takeUntil(this._destroyed),
    map(([items, quantities]) => {
      return items.reduce((total, { pointCost }, index) => {
        total += pointCost * quantities[index];
        return total;
      }, 0);
    }),
    tap(val => {
      this._totalCost = val;
    }),
  );

  /** Adequate Points */
  public hasAdequatePoints$ = combineLatest([
    this.totalCost$,
    this.student$,
  ]).pipe(
    takeUntil(this._destroyed),
    map(([totalCost, student]) => student?.points >= totalCost),
  );

  /** Computed Getters */
  get rewardItemsForm() {
    return this.form.get(this.FORM_FIELD.QUANTITY) as UntypedFormArray;
  }

  get totalQuantities() {
    return this.rewardItemsForm.controls.reduce((total, control) => {
      total += +control.value;
      return total;
    }, 0);
  }

  get canSubmit(): boolean {
    return (
      this.form.dirty &&
      this.form.valid &&
      this.totalQuantities > 0 &&
      !this._isSubmitting
    );
  }

  get showSearch(): boolean {
    return this.dialogData?.rewardItemIds?.length === 0;
  }

  /** Component Constructor */
  constructor(
    @Inject(MODAL_OVERLAY_DATA)
    public dialogData: RedeemPointsModalData,
    private _router: Router,
    private _modalOverlay: ModalOverlayRef<
      RedeemPointsModalResponse,
      RedeemPointsModalData
    >,
    private _dialog: MatDialog,
    private _fb: UntypedFormBuilder,
    private _redeemPointsService: RedeemPointsModalService,
    private _points: PointsService,
  ) {
    this._redeemPointsService.fetchInitialData(dialogData.person);
    this.dialogData.rewardItemIds?.forEach(itemId => {
      if (!itemId) return;
      this.form.get(this.FORM_FIELD.ADD_REWARD).reset(itemId);
      this.addRewardItem(itemId, true);
    });
    this.form.markAsDirty();
  }

  ngOnDestroy(): void {
    this._destroyed.next();
    this._destroyed.complete();
  }

  public async submit(): Promise<void> {
    if (!this.canSubmit) return;
    this._isSubmitting = true;

    try {
      const person = this._redeemPointsService.getStudentDetails();
      const points = this._totalCost;
      const quantities = this.rewardItemsForm.getRawValue();
      const ids = this._selectedRewardItemIds.getValue();
      const duplicatedIds = ids.reduce(
        (acc, id, idx) => [
          ...acc,
          // replicate point reward id base on the
          // quantity of that item, eg. [1, 1, 2, 2, 2]
          ...Array(+quantities[idx]).fill(id),
        ],
        [] as number[],
      );
      await this._points.redeemPoints(person.personHash, points, duplicatedIds);
      this._modalOverlay.close(ModalOverlayServiceCloseEventType.SUBMIT, {
        pointsRedeemed: points,
        personDisplayName: person.displayName,
      });
    } finally {
      this._isSubmitting = false;
    }
  }

  public async cancel(): Promise<void> {
    const confirmationDialog = this._dialog.open(ConfirmationDialogComponent, {
      data: {
        text: {
          description: `Are you sure you want to cancel?`,
          deleteBtn: 'Yes',
        },
      },
    });
    confirmationDialog.afterClosed().subscribe(async res => {
      if (!res) return;
      if (res.confirmed) {
        this._modalOverlay.close(ModalOverlayServiceCloseEventType.CLOSE);
      }
    });
  }

  public deleteRewardItem({ id }: PointReward, index: number) {
    this._selectedRewardItemIds.next(
      this._selectedRewardItemIds
        .getValue()
        .filter(selectedId => selectedId !== id),
    );
    this.rewardItemsForm.removeAt(index);
  }

  public addRewardItem(item: number, clearSelect?: boolean) {
    if (!item) return;
    this._selectedRewardItemIds.next([
      ...this._selectedRewardItemIds.getValue(),
      item,
    ]);
    this.rewardItemsForm.push(
      this._fb.control(1, [
        Validators.required,
        Validators.min(1),
        Validators.max(100),
      ]),
    );
    if (clearSelect) {
      const selectField = this.form.get(this.FORM_FIELD.ADD_REWARD);
      selectField.setValue(undefined);
    }
  }
}
