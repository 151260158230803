import { Injectable } from '@angular/core';

import { FlexTimeActivityInstance } from 'libs/domain';
import { Registration, RegistrationTypes } from 'libs/domain';
import { RestrictionErrorMinimal } from 'libs/domain';
import {
  registration_ng_grpc_pb,
  flex_time_activity_ng_grpc_pb,
  flex_time_ng_grpc_pb,
  registration_pb as reg_pb,
  flex_time_activity_pb as ft_activity_pb,
} from 'libs/generated-grpc-web';
import { RegistrationMapper } from 'libs/shared-grpc';
import { RestrictionErrorMapper } from 'libs/shared-grpc';

import { AuthInfoService } from '@app/src/app/minimal/services/AuthInfo';

import { ErrorHandlerService } from '../error-handler';

@Injectable({
  providedIn: 'root',
})
export class FlexTimeRegistrationService {
  /** Service Constructor */
  constructor(
    private _registrationManager: registration_ng_grpc_pb.RegistrationManager,
    private _flexTimeManager: flex_time_ng_grpc_pb.FlexTimeManager,
    private _ftActivity: flex_time_activity_ng_grpc_pb.FlexTimeActivity,
    private _errorHandler: ErrorHandlerService,
    private _authInfoService: AuthInfoService,
  ) {}

  public async selfRegister(
    activityInstanceId: number,
  ): Promise<FlexTimeActivityInstance> {
    try {
      const request = new reg_pb.RegisterRequest();
      request.setType(RegistrationTypes.FLEX_TIME);
      request.setActivityInstanceId(activityInstanceId);
      request.setPersonHash(this._authInfoService.authPersonHash);
      const response = await this._registrationManager.register(request);
      if (response.getError()) {
        throw new Error(response.getError());
      }
      return RegistrationMapper.fromProto(response.getRegistration())
        .registration;
    } catch (error) {
      throw this._errorHandler.gateWayError(
        `failed to register for activity with instance id ${activityInstanceId}}`,
        error,
        true,
      );
    }
  }

  public async assign(
    activityInstanceId: number,
    personHashes: string[],
    canUnregister: boolean,
    bypassRestrictions = false,
  ): Promise<{
    assignments: Registration[];
    errors: RestrictionErrorMinimal[];
  }> {
    try {
      const request = new reg_pb.AssignRequest();
      request.setType(RegistrationTypes.FLEX_TIME);
      request.setActivityInstanceId(activityInstanceId);
      request.setPersonHashList(personHashes);
      request.setCanUnregister(canUnregister);
      request.setBypassRestrictions(bypassRestrictions);
      const response = await this._registrationManager.assign(request);

      const assignments = response
        .getRegistrationList()
        .map(RegistrationMapper.fromProto);
      const errors = response
        .getErrorList()
        .map(RestrictionErrorMapper.fromProto);

      return { assignments, errors };
    } catch (error) {
      throw this._errorHandler.gateWayError(
        `failed to assign for activity with instance id ${activityInstanceId}`,
        error,
        true,
      );
    }
  }

  public async registerToHomeroom({
    periodId,
    hashes,
  }: {
    periodId: number;
    hashes: string[];
  }): Promise<{ registeredCount: number; errors: RestrictionErrorMinimal[] }> {
    try {
      const request = new reg_pb.RegisterToHomeroomRequest();
      request.setType(RegistrationTypes.FLEX_TIME);
      request.setPersonHashList(hashes);
      request.setPeriodId(periodId);
      const response = await this._registrationManager.registerToHomeroom(
        request,
      );

      const registeredCount = response.getRegisteredCount();
      const errors = response
        .getErrorList()
        .map(RestrictionErrorMapper.fromProto);

      return { registeredCount, errors };
    } catch (error) {
      throw this._errorHandler.gateWayError(
        `failed to auto register students to period: ${periodId}`,
        error,
        true,
      );
    }
  }

  public async delete(
    activityInstanceId: number,
    personHashes: string[],
  ): Promise<void> {
    try {
      const request = new reg_pb.DeleteRegistrationRequest();
      request.setId(activityInstanceId);
      request.setType(RegistrationTypes.FLEX_TIME);
      request.setPersonHashList(personHashes);
      await this._registrationManager.deleteRegistration(request);
    } catch (error) {
      throw this._errorHandler.gateWayError(
        `failed to delete registration with id: ${activityInstanceId}`,
        error,
        true,
      );
    }
  }

  public async validate(
    periodId: number,
    hashes: string[],
    activityInstanceId: number,
  ) {
    try {
      const request = new ft_activity_pb.ValidateAssignmentRequest();
      request.setPeriodId(periodId);
      request.setPersonHashesList(hashes);
      request.setActivityId(activityInstanceId);
      const response = await this._ftActivity.validateAssignment(request);
      const existingRegistrations =
        response.getExistingRegistrationList().map(o => o?.toObject()) || [];
      const success = response.getSuccessList();
      const restrictionsErrors =
        response
          .getRestrictionErrorList()
          .map(RestrictionErrorMapper.fromProto) || [];
      return { existingRegistrations, success, restrictionsErrors };
    } catch (error) {
      throw this._errorHandler.gateWayError(
        'failed to validate flex time period registration',
        error,
        true,
      );
    }
  }

  public async fetchRegisteredPeople(
    activityInstanceId: number,
    checkinReasonId?: number,
  ): Promise<any[]> {
    const request = new reg_pb.GetRegisteredPeopleRequest();
    request.setId(activityInstanceId);
    request.setType(RegistrationTypes.FLEX_TIME);
    request.setReason(checkinReasonId || 0);

    const response = await this._registrationManager.getRegisteredPeople(
      request,
    );

    const people = response.getPersonsList();
    const checkins = response.getCheckedInHashesList();

    return people.map(item => {
      const person = item.getPerson();
      const isAssigned = item.getCannotUnregister();
      const isCheckedIn = checkins.includes(person.getPersonHash());

      let status: string;
      if (isCheckedIn) {
        status = 'Checked In';
      } else {
        status = isAssigned ? 'Assigned' : 'Registered';
      }

      return {
        displayName: person.getDisplayName(),
        firstName: person.getFirstName(),
        lastName: person.getLastName(),
        profileImageUrl: person.getProfileImageUrl(),
        email: person.getEmail(),
        grade: person.getGrade(),
        personHash: person.getPersonHash(),
        studentId: person.getStudentId(),
        filename: person.getFilename(),
        status,
      };
    });
  }
}
