// source: gateway/minga.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

import * as jspb from 'google-protobuf';
import * as common_delta_pb from '../common/delta_pb';
import * as common_legacy_pb from '../common/legacy_pb';
import * as common_stream_pb from '../common/stream_pb';
import * as image_image_pb from '../image/image_pb';
import * as gateway_file_pb from '../gateway/file_pb';
import * as common_date_pb from '../common/date_pb';
import * as google_protobuf_wrappers_pb from '../google/protobuf/wrappers_pb';
import * as common_graph_pb from '../common/graph_pb';
export namespace Minga {
  export interface AsObject {
    name: string;
    hash?: common_legacy_pb.UniqueHash.AsObject;
    placeId: string;
    baseContentGroup?: common_legacy_pb.UniqueHash.AsObject;
    imageUrl: string;
    phoneNumber: string;
    websiteUrl: string;
    email: string;
    linksList: MingaLink.AsObject[];
    paused: boolean;
    mingaType: string;
    mingaSize: number;
    mingaStatus: string;
    schoolType: string;
    startDate?: common_date_pb.DateTime.AsObject;
    endDate?: common_date_pb.DateTime.AsObject;
    adminFullName: string;
    adminEmail: string;
    adminPhone: string;
    mingaSubscriptionId: number;
    partner?: MingaPartner.AsObject;
    district?: MingaPartner.AsObject;
    featureToggle?: MingaFeatureToggle.AsObject;
    adminFirstName: string;
    adminLastName: string;
    adminId: string;
    hubSpotId: string;
    mingaSupportTier: string;
  }
}
export class Minga extends jspb.Message {
  static readonly displayName = "Minga";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [10];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, Minga.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): Minga.AsObject {
    return Minga.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!Minga} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: Minga) {
    var f: any;
    var obj: any = {
      name: jspb.Message.getFieldWithDefault(msg, 1, ""),
      hash: (f = msg.getHash()) && common_legacy_pb.UniqueHash.toObject(includeInstance, f),
      placeId: jspb.Message.getFieldWithDefault(msg, 4, ""),
      baseContentGroup: (f = msg.getBaseContentGroup()) && common_legacy_pb.UniqueHash.toObject(includeInstance, f),
      imageUrl: jspb.Message.getFieldWithDefault(msg, 6, ""),
      phoneNumber: jspb.Message.getFieldWithDefault(msg, 7, ""),
      websiteUrl: jspb.Message.getFieldWithDefault(msg, 8, ""),
      email: jspb.Message.getFieldWithDefault(msg, 9, ""),
      linksList: jspb.Message.toObjectList(msg.getLinksList(),
      MingaLink.toObject, includeInstance),
      paused: jspb.Message.getBooleanFieldWithDefault(msg, 11, false),
      mingaType: jspb.Message.getFieldWithDefault(msg, 12, ""),
      mingaSize: jspb.Message.getFieldWithDefault(msg, 13, 0),
      mingaStatus: jspb.Message.getFieldWithDefault(msg, 14, ""),
      schoolType: jspb.Message.getFieldWithDefault(msg, 15, ""),
      startDate: (f = msg.getStartDate()) && common_date_pb.DateTime.toObject(includeInstance, f),
      endDate: (f = msg.getEndDate()) && common_date_pb.DateTime.toObject(includeInstance, f),
      adminFullName: jspb.Message.getFieldWithDefault(msg, 18, ""),
      adminEmail: jspb.Message.getFieldWithDefault(msg, 19, ""),
      adminPhone: jspb.Message.getFieldWithDefault(msg, 20, ""),
      mingaSubscriptionId: jspb.Message.getFieldWithDefault(msg, 21, 0),
      partner: (f = msg.getPartner()) && MingaPartner.toObject(includeInstance, f),
      district: (f = msg.getDistrict()) && MingaPartner.toObject(includeInstance, f),
      featureToggle: (f = msg.getFeatureToggle()) && MingaFeatureToggle.toObject(includeInstance, f),
      adminFirstName: jspb.Message.getFieldWithDefault(msg, 25, ""),
      adminLastName: jspb.Message.getFieldWithDefault(msg, 26, ""),
      adminId: jspb.Message.getFieldWithDefault(msg, 27, ""),
      hubSpotId: jspb.Message.getFieldWithDefault(msg, 28, ""),
      mingaSupportTier: jspb.Message.getFieldWithDefault(msg, 29, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!Minga}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new Minga;
    return Minga.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!Minga} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!Minga}
   */
  static deserializeBinaryFromReader(msg: Minga, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setName(value1);
        break;
      case 3:
        var value3 = new common_legacy_pb.UniqueHash;
        reader.readMessage(value3,common_legacy_pb.UniqueHash.deserializeBinaryFromReader);
        msg.setHash(value3);
        break;
      case 4:
        var value4 = /** @type {string} */ (reader.readString());
        msg.setPlaceId(value4);
        break;
      case 5:
        var value5 = new common_legacy_pb.UniqueHash;
        reader.readMessage(value5,common_legacy_pb.UniqueHash.deserializeBinaryFromReader);
        msg.setBaseContentGroup(value5);
        break;
      case 6:
        var value6 = /** @type {string} */ (reader.readString());
        msg.setImageUrl(value6);
        break;
      case 7:
        var value7 = /** @type {string} */ (reader.readString());
        msg.setPhoneNumber(value7);
        break;
      case 8:
        var value8 = /** @type {string} */ (reader.readString());
        msg.setWebsiteUrl(value8);
        break;
      case 9:
        var value9 = /** @type {string} */ (reader.readString());
        msg.setEmail(value9);
        break;
      case 10:
        var value10 = new MingaLink;
        reader.readMessage(value10,MingaLink.deserializeBinaryFromReader);
        msg.addLinks(value10);
        break;
      case 11:
        var value11 = /** @type {boolean} */ (reader.readBool());
        msg.setPaused(value11);
        break;
      case 12:
        var value12 = /** @type {string} */ (reader.readString());
        msg.setMingaType(value12);
        break;
      case 13:
        var value13 = /** @type {number} */ (reader.readInt32());
        msg.setMingaSize(value13);
        break;
      case 14:
        var value14 = /** @type {string} */ (reader.readString());
        msg.setMingaStatus(value14);
        break;
      case 15:
        var value15 = /** @type {string} */ (reader.readString());
        msg.setSchoolType(value15);
        break;
      case 16:
        var value16 = new common_date_pb.DateTime;
        reader.readMessage(value16,common_date_pb.DateTime.deserializeBinaryFromReader);
        msg.setStartDate(value16);
        break;
      case 17:
        var value17 = new common_date_pb.DateTime;
        reader.readMessage(value17,common_date_pb.DateTime.deserializeBinaryFromReader);
        msg.setEndDate(value17);
        break;
      case 18:
        var value18 = /** @type {string} */ (reader.readString());
        msg.setAdminFullName(value18);
        break;
      case 19:
        var value19 = /** @type {string} */ (reader.readString());
        msg.setAdminEmail(value19);
        break;
      case 20:
        var value20 = /** @type {string} */ (reader.readString());
        msg.setAdminPhone(value20);
        break;
      case 21:
        var value21 = /** @type {number} */ (reader.readInt32());
        msg.setMingaSubscriptionId(value21);
        break;
      case 22:
        var value22 = new MingaPartner;
        reader.readMessage(value22,MingaPartner.deserializeBinaryFromReader);
        msg.setPartner(value22);
        break;
      case 23:
        var value23 = new MingaPartner;
        reader.readMessage(value23,MingaPartner.deserializeBinaryFromReader);
        msg.setDistrict(value23);
        break;
      case 24:
        var value24 = new MingaFeatureToggle;
        reader.readMessage(value24,MingaFeatureToggle.deserializeBinaryFromReader);
        msg.setFeatureToggle(value24);
        break;
      case 25:
        var value25 = /** @type {string} */ (reader.readString());
        msg.setAdminFirstName(value25);
        break;
      case 26:
        var value26 = /** @type {string} */ (reader.readString());
        msg.setAdminLastName(value26);
        break;
      case 27:
        var value27 = /** @type {string} */ (reader.readString());
        msg.setAdminId(value27);
        break;
      case 28:
        var value28 = /** @type {string} */ (reader.readString());
        msg.setHubSpotId(value28);
        break;
      case 29:
        var value29 = /** @type {string} */ (reader.readString());
        msg.setMingaSupportTier(value29);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!Minga} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: Minga, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getName();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
    f = message.getHash();
    if (f != null) {
      writer.writeMessage(
        3,
        f,
        common_legacy_pb.UniqueHash.serializeBinaryToWriter
      );
    }
    f = message.getPlaceId();
    if (f.length > 0) {
      writer.writeString(
        4,
        f
      );
    }
    f = message.getBaseContentGroup();
    if (f != null) {
      writer.writeMessage(
        5,
        f,
        common_legacy_pb.UniqueHash.serializeBinaryToWriter
      );
    }
    f = message.getImageUrl();
    if (f.length > 0) {
      writer.writeString(
        6,
        f
      );
    }
    f = message.getPhoneNumber();
    if (f.length > 0) {
      writer.writeString(
        7,
        f
      );
    }
    f = message.getWebsiteUrl();
    if (f.length > 0) {
      writer.writeString(
        8,
        f
      );
    }
    f = message.getEmail();
    if (f.length > 0) {
      writer.writeString(
        9,
        f
      );
    }
    f = message.getLinksList();
    if (f.length > 0) {
      writer.writeRepeatedMessage(
        10,
        f,
        MingaLink.serializeBinaryToWriter
      );
    }
    f = message.getPaused();
    if (f) {
      writer.writeBool(
        11,
        f
      );
    }
    f = message.getMingaType();
    if (f.length > 0) {
      writer.writeString(
        12,
        f
      );
    }
    f = message.getMingaSize();
    if (f !== 0) {
      writer.writeInt32(
        13,
        f
      );
    }
    f = message.getMingaStatus();
    if (f.length > 0) {
      writer.writeString(
        14,
        f
      );
    }
    f = message.getSchoolType();
    if (f.length > 0) {
      writer.writeString(
        15,
        f
      );
    }
    f = message.getStartDate();
    if (f != null) {
      writer.writeMessage(
        16,
        f,
        common_date_pb.DateTime.serializeBinaryToWriter
      );
    }
    f = message.getEndDate();
    if (f != null) {
      writer.writeMessage(
        17,
        f,
        common_date_pb.DateTime.serializeBinaryToWriter
      );
    }
    f = message.getAdminFullName();
    if (f.length > 0) {
      writer.writeString(
        18,
        f
      );
    }
    f = message.getAdminEmail();
    if (f.length > 0) {
      writer.writeString(
        19,
        f
      );
    }
    f = message.getAdminPhone();
    if (f.length > 0) {
      writer.writeString(
        20,
        f
      );
    }
    f = message.getMingaSubscriptionId();
    if (f !== 0) {
      writer.writeInt32(
        21,
        f
      );
    }
    f = message.getPartner();
    if (f != null) {
      writer.writeMessage(
        22,
        f,
        MingaPartner.serializeBinaryToWriter
      );
    }
    f = message.getDistrict();
    if (f != null) {
      writer.writeMessage(
        23,
        f,
        MingaPartner.serializeBinaryToWriter
      );
    }
    f = message.getFeatureToggle();
    if (f != null) {
      writer.writeMessage(
        24,
        f,
        MingaFeatureToggle.serializeBinaryToWriter
      );
    }
    f = message.getAdminFirstName();
    if (f.length > 0) {
      writer.writeString(
        25,
        f
      );
    }
    f = message.getAdminLastName();
    if (f.length > 0) {
      writer.writeString(
        26,
        f
      );
    }
    f = message.getAdminId();
    if (f.length > 0) {
      writer.writeString(
        27,
        f
      );
    }
    f = message.getHubSpotId();
    if (f.length > 0) {
      writer.writeString(
        28,
        f
      );
    }
    f = message.getMingaSupportTier();
    if (f.length > 0) {
      writer.writeString(
        29,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    Minga.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string name = 1;
   * @return {string}
   */
  getName(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setName(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


  /**
   * optional UniqueHash hash = 3;
   * @return {?UniqueHash}
   */
  getHash(): common_legacy_pb.UniqueHash {
    return /** @type{?UniqueHash} */ (
      jspb.Message.getWrapperField(this, common_legacy_pb.UniqueHash, 3));
  }


  /** @param {?UniqueHash|undefined} value */
  setHash(value?: common_legacy_pb.UniqueHash) {
    jspb.Message.setWrapperField(this, 3, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearHash() {
    this.setHash(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasHash(): boolean {
    return jspb.Message.getField(this, 3) != null;
  }


  /**
   * optional string place_id = 4;
   * @return {string}
   */
  getPlaceId(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
  };


  /** @param {string} value */
  setPlaceId(value: string) {
    jspb.Message.setProto3StringField(this, 4, value);
  }


  /**
   * optional UniqueHash base_content_group = 5;
   * @return {?UniqueHash}
   */
  getBaseContentGroup(): common_legacy_pb.UniqueHash {
    return /** @type{?UniqueHash} */ (
      jspb.Message.getWrapperField(this, common_legacy_pb.UniqueHash, 5));
  }


  /** @param {?UniqueHash|undefined} value */
  setBaseContentGroup(value?: common_legacy_pb.UniqueHash) {
    jspb.Message.setWrapperField(this, 5, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearBaseContentGroup() {
    this.setBaseContentGroup(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasBaseContentGroup(): boolean {
    return jspb.Message.getField(this, 5) != null;
  }


  /**
   * optional string image_url = 6;
   * @return {string}
   */
  getImageUrl(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
  };


  /** @param {string} value */
  setImageUrl(value: string) {
    jspb.Message.setProto3StringField(this, 6, value);
  }


  /**
   * optional string phone_number = 7;
   * @return {string}
   */
  getPhoneNumber(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
  };


  /** @param {string} value */
  setPhoneNumber(value: string) {
    jspb.Message.setProto3StringField(this, 7, value);
  }


  /**
   * optional string website_url = 8;
   * @return {string}
   */
  getWebsiteUrl(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
  };


  /** @param {string} value */
  setWebsiteUrl(value: string) {
    jspb.Message.setProto3StringField(this, 8, value);
  }


  /**
   * optional string email = 9;
   * @return {string}
   */
  getEmail(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
  };


  /** @param {string} value */
  setEmail(value: string) {
    jspb.Message.setProto3StringField(this, 9, value);
  }


  /**
   * repeated MingaLink links = 10;
   * @return {!Array<!MingaLink>}
   */
  getLinksList(): MingaLink[] {
    return /** @type{!Array<!MingaLink>} */ (
      jspb.Message.getRepeatedWrapperField(this, MingaLink, 10));
  }


  /** @param {!Array<!MingaLink>} value */
  setLinksList(value?: MingaLink[]) {
    jspb.Message.setRepeatedWrapperField(this, 10, value);
  }


  /**
   * @param {!MingaLink=} opt_value
   * @param {number=} opt_index
   * @return {!MingaLink}
   */
  addLinks(opt_value?: MingaLink, opt_index?: number): MingaLink {
    return jspb.Message.addToRepeatedWrapperField(this, 10, opt_value, MingaLink, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearLinksList() {
    this.setLinksList([]);
  }


  /**
   * optional bool paused = 11;
   * @return {boolean}
   */
  getPaused(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
  };


  /** @param {boolean} value */
  setPaused(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 11, value);
  }


  /**
   * optional string minga_type = 12;
   * @return {string}
   */
  getMingaType(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
  };


  /** @param {string} value */
  setMingaType(value: string) {
    jspb.Message.setProto3StringField(this, 12, value);
  }


  /**
   * optional int32 minga_size = 13;
   * @return {number}
   */
  getMingaSize(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
  };


  /** @param {number} value */
  setMingaSize(value: number) {
    jspb.Message.setProto3IntField(this, 13, value);
  }


  /**
   * optional string minga_status = 14;
   * @return {string}
   */
  getMingaStatus(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
  };


  /** @param {string} value */
  setMingaStatus(value: string) {
    jspb.Message.setProto3StringField(this, 14, value);
  }


  /**
   * optional string school_type = 15;
   * @return {string}
   */
  getSchoolType(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
  };


  /** @param {string} value */
  setSchoolType(value: string) {
    jspb.Message.setProto3StringField(this, 15, value);
  }


  /**
   * optional minga.common.DateTime start_date = 16;
   * @return {?DateTime}
   */
  getStartDate(): common_date_pb.DateTime {
    return /** @type{?DateTime} */ (
      jspb.Message.getWrapperField(this, common_date_pb.DateTime, 16));
  }


  /** @param {?DateTime|undefined} value */
  setStartDate(value?: common_date_pb.DateTime) {
    jspb.Message.setWrapperField(this, 16, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearStartDate() {
    this.setStartDate(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasStartDate(): boolean {
    return jspb.Message.getField(this, 16) != null;
  }


  /**
   * optional minga.common.DateTime end_date = 17;
   * @return {?DateTime}
   */
  getEndDate(): common_date_pb.DateTime {
    return /** @type{?DateTime} */ (
      jspb.Message.getWrapperField(this, common_date_pb.DateTime, 17));
  }


  /** @param {?DateTime|undefined} value */
  setEndDate(value?: common_date_pb.DateTime) {
    jspb.Message.setWrapperField(this, 17, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearEndDate() {
    this.setEndDate(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasEndDate(): boolean {
    return jspb.Message.getField(this, 17) != null;
  }


  /**
   * optional string admin_full_name = 18;
   * @return {string}
   */
  getAdminFullName(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
  };


  /** @param {string} value */
  setAdminFullName(value: string) {
    jspb.Message.setProto3StringField(this, 18, value);
  }


  /**
   * optional string admin_email = 19;
   * @return {string}
   */
  getAdminEmail(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
  };


  /** @param {string} value */
  setAdminEmail(value: string) {
    jspb.Message.setProto3StringField(this, 19, value);
  }


  /**
   * optional string admin_phone = 20;
   * @return {string}
   */
  getAdminPhone(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
  };


  /** @param {string} value */
  setAdminPhone(value: string) {
    jspb.Message.setProto3StringField(this, 20, value);
  }


  /**
   * optional int32 minga_subscription_id = 21;
   * @return {number}
   */
  getMingaSubscriptionId(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 21, 0));
  };


  /** @param {number} value */
  setMingaSubscriptionId(value: number) {
    jspb.Message.setProto3IntField(this, 21, value);
  }


  /**
   * optional MingaPartner partner = 22;
   * @return {?MingaPartner}
   */
  getPartner(): MingaPartner {
    return /** @type{?MingaPartner} */ (
      jspb.Message.getWrapperField(this, MingaPartner, 22));
  }


  /** @param {?MingaPartner|undefined} value */
  setPartner(value?: MingaPartner) {
    jspb.Message.setWrapperField(this, 22, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearPartner() {
    this.setPartner(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasPartner(): boolean {
    return jspb.Message.getField(this, 22) != null;
  }


  /**
   * optional MingaPartner district = 23;
   * @return {?MingaPartner}
   */
  getDistrict(): MingaPartner {
    return /** @type{?MingaPartner} */ (
      jspb.Message.getWrapperField(this, MingaPartner, 23));
  }


  /** @param {?MingaPartner|undefined} value */
  setDistrict(value?: MingaPartner) {
    jspb.Message.setWrapperField(this, 23, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearDistrict() {
    this.setDistrict(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasDistrict(): boolean {
    return jspb.Message.getField(this, 23) != null;
  }


  /**
   * optional MingaFeatureToggle feature_toggle = 24;
   * @return {?MingaFeatureToggle}
   */
  getFeatureToggle(): MingaFeatureToggle {
    return /** @type{?MingaFeatureToggle} */ (
      jspb.Message.getWrapperField(this, MingaFeatureToggle, 24));
  }


  /** @param {?MingaFeatureToggle|undefined} value */
  setFeatureToggle(value?: MingaFeatureToggle) {
    jspb.Message.setWrapperField(this, 24, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearFeatureToggle() {
    this.setFeatureToggle(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasFeatureToggle(): boolean {
    return jspb.Message.getField(this, 24) != null;
  }


  /**
   * optional string admin_first_name = 25;
   * @return {string}
   */
  getAdminFirstName(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 25, ""));
  };


  /** @param {string} value */
  setAdminFirstName(value: string) {
    jspb.Message.setProto3StringField(this, 25, value);
  }


  /**
   * optional string admin_last_name = 26;
   * @return {string}
   */
  getAdminLastName(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 26, ""));
  };


  /** @param {string} value */
  setAdminLastName(value: string) {
    jspb.Message.setProto3StringField(this, 26, value);
  }


  /**
   * optional string admin_id = 27;
   * @return {string}
   */
  getAdminId(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 27, ""));
  };


  /** @param {string} value */
  setAdminId(value: string) {
    jspb.Message.setProto3StringField(this, 27, value);
  }


  /**
   * optional string hub_spot_id = 28;
   * @return {string}
   */
  getHubSpotId(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 28, ""));
  };


  /** @param {string} value */
  setHubSpotId(value: string) {
    jspb.Message.setProto3StringField(this, 28, value);
  }


  /**
   * optional string minga_support_tier = 29;
   * @return {string}
   */
  getMingaSupportTier(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 29, ""));
  };


  /** @param {string} value */
  setMingaSupportTier(value: string) {
    jspb.Message.setProto3StringField(this, 29, value);
  }


}
export namespace MingaLink {
  export interface AsObject {
    id: number;
    title: string;
    url: string;
    icon: string;
  }
}
export class MingaLink extends jspb.Message {
  static readonly displayName = "MingaLink";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): MingaLink.AsObject {
    return MingaLink.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!MingaLink} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: MingaLink) {
    var f: any;
    var obj: any = {
      id: jspb.Message.getFieldWithDefault(msg, 1, 0),
      title: jspb.Message.getFieldWithDefault(msg, 2, ""),
      url: jspb.Message.getFieldWithDefault(msg, 3, ""),
      icon: jspb.Message.getFieldWithDefault(msg, 4, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!MingaLink}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new MingaLink;
    return MingaLink.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!MingaLink} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!MingaLink}
   */
  static deserializeBinaryFromReader(msg: MingaLink, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {number} */ (reader.readInt32());
        msg.setId(value1);
        break;
      case 2:
        var value2 = /** @type {string} */ (reader.readString());
        msg.setTitle(value2);
        break;
      case 3:
        var value3 = /** @type {string} */ (reader.readString());
        msg.setUrl(value3);
        break;
      case 4:
        var value4 = /** @type {string} */ (reader.readString());
        msg.setIcon(value4);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!MingaLink} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: MingaLink, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getId();
    if (f !== 0) {
      writer.writeInt32(
        1,
        f
      );
    }
    f = message.getTitle();
    if (f.length > 0) {
      writer.writeString(
        2,
        f
      );
    }
    f = message.getUrl();
    if (f.length > 0) {
      writer.writeString(
        3,
        f
      );
    }
    f = message.getIcon();
    if (f.length > 0) {
      writer.writeString(
        4,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    MingaLink.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional int32 id = 1;
   * @return {number}
   */
  getId(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
  };


  /** @param {number} value */
  setId(value: number) {
    jspb.Message.setProto3IntField(this, 1, value);
  }


  /**
   * optional string title = 2;
   * @return {string}
   */
  getTitle(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
  };


  /** @param {string} value */
  setTitle(value: string) {
    jspb.Message.setProto3StringField(this, 2, value);
  }


  /**
   * optional string url = 3;
   * @return {string}
   */
  getUrl(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
  };


  /** @param {string} value */
  setUrl(value: string) {
    jspb.Message.setProto3StringField(this, 3, value);
  }


  /**
   * optional string icon = 4;
   * @return {string}
   */
  getIcon(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
  };


  /** @param {string} value */
  setIcon(value: string) {
    jspb.Message.setProto3StringField(this, 4, value);
  }


}
export namespace MingaInfoMinimal {
  export interface AsObject {
    id: number;
    mingaName: string;
    mingaHash: string;
    settingsList: MingaSetting.AsObject[];
    mingaLogo: string;
    mingaType: string;
    paused: boolean;
    featureToggle?: MingaFeatureToggle.AsObject;
    sisType: string;
    sisSettings?: sisFields.AsObject;
    hubspotId: string;
    districtId: number;
    mingaSupportTier: string;
  }
}
export class MingaInfoMinimal extends jspb.Message {
  static readonly displayName = "MingaInfoMinimal";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [4];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, MingaInfoMinimal.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): MingaInfoMinimal.AsObject {
    return MingaInfoMinimal.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!MingaInfoMinimal} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: MingaInfoMinimal) {
    var f: any;
    var obj: any = {
      id: jspb.Message.getFieldWithDefault(msg, 1, 0),
      mingaName: jspb.Message.getFieldWithDefault(msg, 2, ""),
      mingaHash: jspb.Message.getFieldWithDefault(msg, 3, ""),
      settingsList: jspb.Message.toObjectList(msg.getSettingsList(),
      MingaSetting.toObject, includeInstance),
      mingaLogo: jspb.Message.getFieldWithDefault(msg, 5, ""),
      mingaType: jspb.Message.getFieldWithDefault(msg, 6, ""),
      paused: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
      featureToggle: (f = msg.getFeatureToggle()) && MingaFeatureToggle.toObject(includeInstance, f),
      sisType: jspb.Message.getFieldWithDefault(msg, 9, ""),
      sisSettings: (f = msg.getSisSettings()) && sisFields.toObject(includeInstance, f),
      hubspotId: jspb.Message.getFieldWithDefault(msg, 11, ""),
      districtId: jspb.Message.getFieldWithDefault(msg, 12, 0),
      mingaSupportTier: jspb.Message.getFieldWithDefault(msg, 13, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!MingaInfoMinimal}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new MingaInfoMinimal;
    return MingaInfoMinimal.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!MingaInfoMinimal} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!MingaInfoMinimal}
   */
  static deserializeBinaryFromReader(msg: MingaInfoMinimal, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {number} */ (reader.readInt32());
        msg.setId(value1);
        break;
      case 2:
        var value2 = /** @type {string} */ (reader.readString());
        msg.setMingaName(value2);
        break;
      case 3:
        var value3 = /** @type {string} */ (reader.readString());
        msg.setMingaHash(value3);
        break;
      case 4:
        var value4 = new MingaSetting;
        reader.readMessage(value4,MingaSetting.deserializeBinaryFromReader);
        msg.addSettings(value4);
        break;
      case 5:
        var value5 = /** @type {string} */ (reader.readString());
        msg.setMingaLogo(value5);
        break;
      case 6:
        var value6 = /** @type {string} */ (reader.readString());
        msg.setMingaType(value6);
        break;
      case 7:
        var value7 = /** @type {boolean} */ (reader.readBool());
        msg.setPaused(value7);
        break;
      case 8:
        var value8 = new MingaFeatureToggle;
        reader.readMessage(value8,MingaFeatureToggle.deserializeBinaryFromReader);
        msg.setFeatureToggle(value8);
        break;
      case 9:
        var value9 = /** @type {string} */ (reader.readString());
        msg.setSisType(value9);
        break;
      case 10:
        var value10 = new sisFields;
        reader.readMessage(value10,sisFields.deserializeBinaryFromReader);
        msg.setSisSettings(value10);
        break;
      case 11:
        var value11 = /** @type {string} */ (reader.readString());
        msg.setHubspotId(value11);
        break;
      case 12:
        var value12 = /** @type {number} */ (reader.readInt32());
        msg.setDistrictId(value12);
        break;
      case 13:
        var value13 = /** @type {string} */ (reader.readString());
        msg.setMingaSupportTier(value13);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!MingaInfoMinimal} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: MingaInfoMinimal, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getId();
    if (f !== 0) {
      writer.writeInt32(
        1,
        f
      );
    }
    f = message.getMingaName();
    if (f.length > 0) {
      writer.writeString(
        2,
        f
      );
    }
    f = message.getMingaHash();
    if (f.length > 0) {
      writer.writeString(
        3,
        f
      );
    }
    f = message.getSettingsList();
    if (f.length > 0) {
      writer.writeRepeatedMessage(
        4,
        f,
        MingaSetting.serializeBinaryToWriter
      );
    }
    f = message.getMingaLogo();
    if (f.length > 0) {
      writer.writeString(
        5,
        f
      );
    }
    f = message.getMingaType();
    if (f.length > 0) {
      writer.writeString(
        6,
        f
      );
    }
    f = message.getPaused();
    if (f) {
      writer.writeBool(
        7,
        f
      );
    }
    f = message.getFeatureToggle();
    if (f != null) {
      writer.writeMessage(
        8,
        f,
        MingaFeatureToggle.serializeBinaryToWriter
      );
    }
    f = message.getSisType();
    if (f.length > 0) {
      writer.writeString(
        9,
        f
      );
    }
    f = message.getSisSettings();
    if (f != null) {
      writer.writeMessage(
        10,
        f,
        sisFields.serializeBinaryToWriter
      );
    }
    f = message.getHubspotId();
    if (f.length > 0) {
      writer.writeString(
        11,
        f
      );
    }
    f = message.getDistrictId();
    if (f !== 0) {
      writer.writeInt32(
        12,
        f
      );
    }
    f = message.getMingaSupportTier();
    if (f.length > 0) {
      writer.writeString(
        13,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    MingaInfoMinimal.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional int32 id = 1;
   * @return {number}
   */
  getId(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
  };


  /** @param {number} value */
  setId(value: number) {
    jspb.Message.setProto3IntField(this, 1, value);
  }


  /**
   * optional string minga_name = 2;
   * @return {string}
   */
  getMingaName(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
  };


  /** @param {string} value */
  setMingaName(value: string) {
    jspb.Message.setProto3StringField(this, 2, value);
  }


  /**
   * optional string minga_hash = 3;
   * @return {string}
   */
  getMingaHash(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
  };


  /** @param {string} value */
  setMingaHash(value: string) {
    jspb.Message.setProto3StringField(this, 3, value);
  }


  /**
   * repeated MingaSetting settings = 4;
   * @return {!Array<!MingaSetting>}
   */
  getSettingsList(): MingaSetting[] {
    return /** @type{!Array<!MingaSetting>} */ (
      jspb.Message.getRepeatedWrapperField(this, MingaSetting, 4));
  }


  /** @param {!Array<!MingaSetting>} value */
  setSettingsList(value?: MingaSetting[]) {
    jspb.Message.setRepeatedWrapperField(this, 4, value);
  }


  /**
   * @param {!MingaSetting=} opt_value
   * @param {number=} opt_index
   * @return {!MingaSetting}
   */
  addSettings(opt_value?: MingaSetting, opt_index?: number): MingaSetting {
    return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, MingaSetting, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearSettingsList() {
    this.setSettingsList([]);
  }


  /**
   * optional string minga_logo = 5;
   * @return {string}
   */
  getMingaLogo(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
  };


  /** @param {string} value */
  setMingaLogo(value: string) {
    jspb.Message.setProto3StringField(this, 5, value);
  }


  /**
   * optional string minga_type = 6;
   * @return {string}
   */
  getMingaType(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
  };


  /** @param {string} value */
  setMingaType(value: string) {
    jspb.Message.setProto3StringField(this, 6, value);
  }


  /**
   * optional bool paused = 7;
   * @return {boolean}
   */
  getPaused(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
  };


  /** @param {boolean} value */
  setPaused(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 7, value);
  }


  /**
   * optional MingaFeatureToggle feature_toggle = 8;
   * @return {?MingaFeatureToggle}
   */
  getFeatureToggle(): MingaFeatureToggle {
    return /** @type{?MingaFeatureToggle} */ (
      jspb.Message.getWrapperField(this, MingaFeatureToggle, 8));
  }


  /** @param {?MingaFeatureToggle|undefined} value */
  setFeatureToggle(value?: MingaFeatureToggle) {
    jspb.Message.setWrapperField(this, 8, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearFeatureToggle() {
    this.setFeatureToggle(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasFeatureToggle(): boolean {
    return jspb.Message.getField(this, 8) != null;
  }


  /**
   * optional string sis_type = 9;
   * @return {string}
   */
  getSisType(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
  };


  /** @param {string} value */
  setSisType(value: string) {
    jspb.Message.setProto3StringField(this, 9, value);
  }


  /**
   * optional sisFields sis_settings = 10;
   * @return {?sisFields}
   */
  getSisSettings(): sisFields {
    return /** @type{?sisFields} */ (
      jspb.Message.getWrapperField(this, sisFields, 10));
  }


  /** @param {?sisFields|undefined} value */
  setSisSettings(value?: sisFields) {
    jspb.Message.setWrapperField(this, 10, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearSisSettings() {
    this.setSisSettings(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasSisSettings(): boolean {
    return jspb.Message.getField(this, 10) != null;
  }


  /**
   * optional string hubspot_id = 11;
   * @return {string}
   */
  getHubspotId(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
  };


  /** @param {string} value */
  setHubspotId(value: string) {
    jspb.Message.setProto3StringField(this, 11, value);
  }


  /**
   * optional int32 district_id = 12;
   * @return {number}
   */
  getDistrictId(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
  };


  /** @param {number} value */
  setDistrictId(value: number) {
    jspb.Message.setProto3IntField(this, 12, value);
  }


  /**
   * optional string minga_support_tier = 13;
   * @return {string}
   */
  getMingaSupportTier(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
  };


  /** @param {string} value */
  setMingaSupportTier(value: string) {
    jspb.Message.setProto3StringField(this, 13, value);
  }


}
export namespace MingaPartner {
  export interface AsObject {
    id: number;
    name: string;
  }
}
export class MingaPartner extends jspb.Message {
  static readonly displayName = "MingaPartner";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): MingaPartner.AsObject {
    return MingaPartner.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!MingaPartner} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: MingaPartner) {
    var f: any;
    var obj: any = {
      id: jspb.Message.getFieldWithDefault(msg, 1, 0),
      name: jspb.Message.getFieldWithDefault(msg, 2, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!MingaPartner}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new MingaPartner;
    return MingaPartner.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!MingaPartner} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!MingaPartner}
   */
  static deserializeBinaryFromReader(msg: MingaPartner, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {number} */ (reader.readInt32());
        msg.setId(value1);
        break;
      case 2:
        var value2 = /** @type {string} */ (reader.readString());
        msg.setName(value2);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!MingaPartner} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: MingaPartner, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getId();
    if (f !== 0) {
      writer.writeInt32(
        1,
        f
      );
    }
    f = message.getName();
    if (f.length > 0) {
      writer.writeString(
        2,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    MingaPartner.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional int32 id = 1;
   * @return {number}
   */
  getId(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
  };


  /** @param {number} value */
  setId(value: number) {
    jspb.Message.setProto3IntField(this, 1, value);
  }


  /**
   * optional string name = 2;
   * @return {string}
   */
  getName(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
  };


  /** @param {string} value */
  setName(value: string) {
    jspb.Message.setProto3StringField(this, 2, value);
  }


}
export namespace MingaDelta {
  export interface AsObject {
    name?: common_delta_pb.StringDelta.AsObject;
    creator?: common_delta_pb.StringDelta.AsObject;
    hash: string;
    placeId?: common_delta_pb.StringDelta.AsObject;
    imageUrl?: common_delta_pb.StringDelta.AsObject;
    phoneNumber?: common_delta_pb.StringDelta.AsObject;
    websiteUrl?: common_delta_pb.StringDelta.AsObject;
    email?: common_delta_pb.StringDelta.AsObject;
    mingaType?: common_delta_pb.StringDelta.AsObject;
    mingaSize?: common_delta_pb.Int32Delta.AsObject;
    mingaStatus?: common_delta_pb.StringDelta.AsObject;
    schoolType?: common_delta_pb.StringDelta.AsObject;
    startDate?: common_delta_pb.DateDelta.AsObject;
    endDate?: common_delta_pb.DateDelta.AsObject;
    adminFullName?: common_delta_pb.StringDelta.AsObject;
    adminEmail?: common_delta_pb.StringDelta.AsObject;
    adminPhone?: common_delta_pb.StringDelta.AsObject;
    mingaSubscriptionId?: common_delta_pb.Int32Delta.AsObject;
    mingaPartnerId?: common_delta_pb.Int32Delta.AsObject;
    mingaDistrictId?: common_delta_pb.Int32Delta.AsObject;
    featureToggles?: MingaFeatureToggle.AsObject;
    adminFirstName?: common_delta_pb.StringDelta.AsObject;
    adminLastName?: common_delta_pb.StringDelta.AsObject;
    hubSpotId?: common_delta_pb.StringDelta.AsObject;
    mingaSupportTier?: common_delta_pb.StringDelta.AsObject;
  }
}
export class MingaDelta extends jspb.Message {
  static readonly displayName = "MingaDelta";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): MingaDelta.AsObject {
    return MingaDelta.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!MingaDelta} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: MingaDelta) {
    var f: any;
    var obj: any = {
      name: (f = msg.getName()) && common_delta_pb.StringDelta.toObject(includeInstance, f),
      creator: (f = msg.getCreator()) && common_delta_pb.StringDelta.toObject(includeInstance, f),
      hash: jspb.Message.getFieldWithDefault(msg, 3, ""),
      placeId: (f = msg.getPlaceId()) && common_delta_pb.StringDelta.toObject(includeInstance, f),
      imageUrl: (f = msg.getImageUrl()) && common_delta_pb.StringDelta.toObject(includeInstance, f),
      phoneNumber: (f = msg.getPhoneNumber()) && common_delta_pb.StringDelta.toObject(includeInstance, f),
      websiteUrl: (f = msg.getWebsiteUrl()) && common_delta_pb.StringDelta.toObject(includeInstance, f),
      email: (f = msg.getEmail()) && common_delta_pb.StringDelta.toObject(includeInstance, f),
      mingaType: (f = msg.getMingaType()) && common_delta_pb.StringDelta.toObject(includeInstance, f),
      mingaSize: (f = msg.getMingaSize()) && common_delta_pb.Int32Delta.toObject(includeInstance, f),
      mingaStatus: (f = msg.getMingaStatus()) && common_delta_pb.StringDelta.toObject(includeInstance, f),
      schoolType: (f = msg.getSchoolType()) && common_delta_pb.StringDelta.toObject(includeInstance, f),
      startDate: (f = msg.getStartDate()) && common_delta_pb.DateDelta.toObject(includeInstance, f),
      endDate: (f = msg.getEndDate()) && common_delta_pb.DateDelta.toObject(includeInstance, f),
      adminFullName: (f = msg.getAdminFullName()) && common_delta_pb.StringDelta.toObject(includeInstance, f),
      adminEmail: (f = msg.getAdminEmail()) && common_delta_pb.StringDelta.toObject(includeInstance, f),
      adminPhone: (f = msg.getAdminPhone()) && common_delta_pb.StringDelta.toObject(includeInstance, f),
      mingaSubscriptionId: (f = msg.getMingaSubscriptionId()) && common_delta_pb.Int32Delta.toObject(includeInstance, f),
      mingaPartnerId: (f = msg.getMingaPartnerId()) && common_delta_pb.Int32Delta.toObject(includeInstance, f),
      mingaDistrictId: (f = msg.getMingaDistrictId()) && common_delta_pb.Int32Delta.toObject(includeInstance, f),
      featureToggles: (f = msg.getFeatureToggles()) && MingaFeatureToggle.toObject(includeInstance, f),
      adminFirstName: (f = msg.getAdminFirstName()) && common_delta_pb.StringDelta.toObject(includeInstance, f),
      adminLastName: (f = msg.getAdminLastName()) && common_delta_pb.StringDelta.toObject(includeInstance, f),
      hubSpotId: (f = msg.getHubSpotId()) && common_delta_pb.StringDelta.toObject(includeInstance, f),
      mingaSupportTier: (f = msg.getMingaSupportTier()) && common_delta_pb.StringDelta.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!MingaDelta}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new MingaDelta;
    return MingaDelta.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!MingaDelta} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!MingaDelta}
   */
  static deserializeBinaryFromReader(msg: MingaDelta, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new common_delta_pb.StringDelta;
        reader.readMessage(value1,common_delta_pb.StringDelta.deserializeBinaryFromReader);
        msg.setName(value1);
        break;
      case 2:
        var value2 = new common_delta_pb.StringDelta;
        reader.readMessage(value2,common_delta_pb.StringDelta.deserializeBinaryFromReader);
        msg.setCreator(value2);
        break;
      case 3:
        var value3 = /** @type {string} */ (reader.readString());
        msg.setHash(value3);
        break;
      case 4:
        var value4 = new common_delta_pb.StringDelta;
        reader.readMessage(value4,common_delta_pb.StringDelta.deserializeBinaryFromReader);
        msg.setPlaceId(value4);
        break;
      case 5:
        var value5 = new common_delta_pb.StringDelta;
        reader.readMessage(value5,common_delta_pb.StringDelta.deserializeBinaryFromReader);
        msg.setImageUrl(value5);
        break;
      case 6:
        var value6 = new common_delta_pb.StringDelta;
        reader.readMessage(value6,common_delta_pb.StringDelta.deserializeBinaryFromReader);
        msg.setPhoneNumber(value6);
        break;
      case 7:
        var value7 = new common_delta_pb.StringDelta;
        reader.readMessage(value7,common_delta_pb.StringDelta.deserializeBinaryFromReader);
        msg.setWebsiteUrl(value7);
        break;
      case 8:
        var value8 = new common_delta_pb.StringDelta;
        reader.readMessage(value8,common_delta_pb.StringDelta.deserializeBinaryFromReader);
        msg.setEmail(value8);
        break;
      case 9:
        var value9 = new common_delta_pb.StringDelta;
        reader.readMessage(value9,common_delta_pb.StringDelta.deserializeBinaryFromReader);
        msg.setMingaType(value9);
        break;
      case 10:
        var value10 = new common_delta_pb.Int32Delta;
        reader.readMessage(value10,common_delta_pb.Int32Delta.deserializeBinaryFromReader);
        msg.setMingaSize(value10);
        break;
      case 11:
        var value11 = new common_delta_pb.StringDelta;
        reader.readMessage(value11,common_delta_pb.StringDelta.deserializeBinaryFromReader);
        msg.setMingaStatus(value11);
        break;
      case 12:
        var value12 = new common_delta_pb.StringDelta;
        reader.readMessage(value12,common_delta_pb.StringDelta.deserializeBinaryFromReader);
        msg.setSchoolType(value12);
        break;
      case 13:
        var value13 = new common_delta_pb.DateDelta;
        reader.readMessage(value13,common_delta_pb.DateDelta.deserializeBinaryFromReader);
        msg.setStartDate(value13);
        break;
      case 14:
        var value14 = new common_delta_pb.DateDelta;
        reader.readMessage(value14,common_delta_pb.DateDelta.deserializeBinaryFromReader);
        msg.setEndDate(value14);
        break;
      case 15:
        var value15 = new common_delta_pb.StringDelta;
        reader.readMessage(value15,common_delta_pb.StringDelta.deserializeBinaryFromReader);
        msg.setAdminFullName(value15);
        break;
      case 16:
        var value16 = new common_delta_pb.StringDelta;
        reader.readMessage(value16,common_delta_pb.StringDelta.deserializeBinaryFromReader);
        msg.setAdminEmail(value16);
        break;
      case 17:
        var value17 = new common_delta_pb.StringDelta;
        reader.readMessage(value17,common_delta_pb.StringDelta.deserializeBinaryFromReader);
        msg.setAdminPhone(value17);
        break;
      case 18:
        var value18 = new common_delta_pb.Int32Delta;
        reader.readMessage(value18,common_delta_pb.Int32Delta.deserializeBinaryFromReader);
        msg.setMingaSubscriptionId(value18);
        break;
      case 19:
        var value19 = new common_delta_pb.Int32Delta;
        reader.readMessage(value19,common_delta_pb.Int32Delta.deserializeBinaryFromReader);
        msg.setMingaPartnerId(value19);
        break;
      case 20:
        var value20 = new common_delta_pb.Int32Delta;
        reader.readMessage(value20,common_delta_pb.Int32Delta.deserializeBinaryFromReader);
        msg.setMingaDistrictId(value20);
        break;
      case 21:
        var value21 = new MingaFeatureToggle;
        reader.readMessage(value21,MingaFeatureToggle.deserializeBinaryFromReader);
        msg.setFeatureToggles(value21);
        break;
      case 22:
        var value22 = new common_delta_pb.StringDelta;
        reader.readMessage(value22,common_delta_pb.StringDelta.deserializeBinaryFromReader);
        msg.setAdminFirstName(value22);
        break;
      case 23:
        var value23 = new common_delta_pb.StringDelta;
        reader.readMessage(value23,common_delta_pb.StringDelta.deserializeBinaryFromReader);
        msg.setAdminLastName(value23);
        break;
      case 24:
        var value24 = new common_delta_pb.StringDelta;
        reader.readMessage(value24,common_delta_pb.StringDelta.deserializeBinaryFromReader);
        msg.setHubSpotId(value24);
        break;
      case 25:
        var value25 = new common_delta_pb.StringDelta;
        reader.readMessage(value25,common_delta_pb.StringDelta.deserializeBinaryFromReader);
        msg.setMingaSupportTier(value25);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!MingaDelta} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: MingaDelta, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getName();
    if (f != null) {
      writer.writeMessage(
        1,
        f,
        common_delta_pb.StringDelta.serializeBinaryToWriter
      );
    }
    f = message.getCreator();
    if (f != null) {
      writer.writeMessage(
        2,
        f,
        common_delta_pb.StringDelta.serializeBinaryToWriter
      );
    }
    f = message.getHash();
    if (f.length > 0) {
      writer.writeString(
        3,
        f
      );
    }
    f = message.getPlaceId();
    if (f != null) {
      writer.writeMessage(
        4,
        f,
        common_delta_pb.StringDelta.serializeBinaryToWriter
      );
    }
    f = message.getImageUrl();
    if (f != null) {
      writer.writeMessage(
        5,
        f,
        common_delta_pb.StringDelta.serializeBinaryToWriter
      );
    }
    f = message.getPhoneNumber();
    if (f != null) {
      writer.writeMessage(
        6,
        f,
        common_delta_pb.StringDelta.serializeBinaryToWriter
      );
    }
    f = message.getWebsiteUrl();
    if (f != null) {
      writer.writeMessage(
        7,
        f,
        common_delta_pb.StringDelta.serializeBinaryToWriter
      );
    }
    f = message.getEmail();
    if (f != null) {
      writer.writeMessage(
        8,
        f,
        common_delta_pb.StringDelta.serializeBinaryToWriter
      );
    }
    f = message.getMingaType();
    if (f != null) {
      writer.writeMessage(
        9,
        f,
        common_delta_pb.StringDelta.serializeBinaryToWriter
      );
    }
    f = message.getMingaSize();
    if (f != null) {
      writer.writeMessage(
        10,
        f,
        common_delta_pb.Int32Delta.serializeBinaryToWriter
      );
    }
    f = message.getMingaStatus();
    if (f != null) {
      writer.writeMessage(
        11,
        f,
        common_delta_pb.StringDelta.serializeBinaryToWriter
      );
    }
    f = message.getSchoolType();
    if (f != null) {
      writer.writeMessage(
        12,
        f,
        common_delta_pb.StringDelta.serializeBinaryToWriter
      );
    }
    f = message.getStartDate();
    if (f != null) {
      writer.writeMessage(
        13,
        f,
        common_delta_pb.DateDelta.serializeBinaryToWriter
      );
    }
    f = message.getEndDate();
    if (f != null) {
      writer.writeMessage(
        14,
        f,
        common_delta_pb.DateDelta.serializeBinaryToWriter
      );
    }
    f = message.getAdminFullName();
    if (f != null) {
      writer.writeMessage(
        15,
        f,
        common_delta_pb.StringDelta.serializeBinaryToWriter
      );
    }
    f = message.getAdminEmail();
    if (f != null) {
      writer.writeMessage(
        16,
        f,
        common_delta_pb.StringDelta.serializeBinaryToWriter
      );
    }
    f = message.getAdminPhone();
    if (f != null) {
      writer.writeMessage(
        17,
        f,
        common_delta_pb.StringDelta.serializeBinaryToWriter
      );
    }
    f = message.getMingaSubscriptionId();
    if (f != null) {
      writer.writeMessage(
        18,
        f,
        common_delta_pb.Int32Delta.serializeBinaryToWriter
      );
    }
    f = message.getMingaPartnerId();
    if (f != null) {
      writer.writeMessage(
        19,
        f,
        common_delta_pb.Int32Delta.serializeBinaryToWriter
      );
    }
    f = message.getMingaDistrictId();
    if (f != null) {
      writer.writeMessage(
        20,
        f,
        common_delta_pb.Int32Delta.serializeBinaryToWriter
      );
    }
    f = message.getFeatureToggles();
    if (f != null) {
      writer.writeMessage(
        21,
        f,
        MingaFeatureToggle.serializeBinaryToWriter
      );
    }
    f = message.getAdminFirstName();
    if (f != null) {
      writer.writeMessage(
        22,
        f,
        common_delta_pb.StringDelta.serializeBinaryToWriter
      );
    }
    f = message.getAdminLastName();
    if (f != null) {
      writer.writeMessage(
        23,
        f,
        common_delta_pb.StringDelta.serializeBinaryToWriter
      );
    }
    f = message.getHubSpotId();
    if (f != null) {
      writer.writeMessage(
        24,
        f,
        common_delta_pb.StringDelta.serializeBinaryToWriter
      );
    }
    f = message.getMingaSupportTier();
    if (f != null) {
      writer.writeMessage(
        25,
        f,
        common_delta_pb.StringDelta.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    MingaDelta.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional minga.common.StringDelta name = 1;
   * @return {?StringDelta}
   */
  getName(): common_delta_pb.StringDelta {
    return /** @type{?StringDelta} */ (
      jspb.Message.getWrapperField(this, common_delta_pb.StringDelta, 1));
  }


  /** @param {?StringDelta|undefined} value */
  setName(value?: common_delta_pb.StringDelta) {
    jspb.Message.setWrapperField(this, 1, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearName() {
    this.setName(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasName(): boolean {
    return jspb.Message.getField(this, 1) != null;
  }


  /**
   * optional minga.common.StringDelta creator = 2;
   * @return {?StringDelta}
   */
  getCreator(): common_delta_pb.StringDelta {
    return /** @type{?StringDelta} */ (
      jspb.Message.getWrapperField(this, common_delta_pb.StringDelta, 2));
  }


  /** @param {?StringDelta|undefined} value */
  setCreator(value?: common_delta_pb.StringDelta) {
    jspb.Message.setWrapperField(this, 2, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearCreator() {
    this.setCreator(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasCreator(): boolean {
    return jspb.Message.getField(this, 2) != null;
  }


  /**
   * optional string hash = 3;
   * @return {string}
   */
  getHash(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
  };


  /** @param {string} value */
  setHash(value: string) {
    jspb.Message.setProto3StringField(this, 3, value);
  }


  /**
   * optional minga.common.StringDelta place_id = 4;
   * @return {?StringDelta}
   */
  getPlaceId(): common_delta_pb.StringDelta {
    return /** @type{?StringDelta} */ (
      jspb.Message.getWrapperField(this, common_delta_pb.StringDelta, 4));
  }


  /** @param {?StringDelta|undefined} value */
  setPlaceId(value?: common_delta_pb.StringDelta) {
    jspb.Message.setWrapperField(this, 4, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearPlaceId() {
    this.setPlaceId(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasPlaceId(): boolean {
    return jspb.Message.getField(this, 4) != null;
  }


  /**
   * optional minga.common.StringDelta image_url = 5;
   * @return {?StringDelta}
   */
  getImageUrl(): common_delta_pb.StringDelta {
    return /** @type{?StringDelta} */ (
      jspb.Message.getWrapperField(this, common_delta_pb.StringDelta, 5));
  }


  /** @param {?StringDelta|undefined} value */
  setImageUrl(value?: common_delta_pb.StringDelta) {
    jspb.Message.setWrapperField(this, 5, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearImageUrl() {
    this.setImageUrl(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasImageUrl(): boolean {
    return jspb.Message.getField(this, 5) != null;
  }


  /**
   * optional minga.common.StringDelta phone_number = 6;
   * @return {?StringDelta}
   */
  getPhoneNumber(): common_delta_pb.StringDelta {
    return /** @type{?StringDelta} */ (
      jspb.Message.getWrapperField(this, common_delta_pb.StringDelta, 6));
  }


  /** @param {?StringDelta|undefined} value */
  setPhoneNumber(value?: common_delta_pb.StringDelta) {
    jspb.Message.setWrapperField(this, 6, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearPhoneNumber() {
    this.setPhoneNumber(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasPhoneNumber(): boolean {
    return jspb.Message.getField(this, 6) != null;
  }


  /**
   * optional minga.common.StringDelta website_url = 7;
   * @return {?StringDelta}
   */
  getWebsiteUrl(): common_delta_pb.StringDelta {
    return /** @type{?StringDelta} */ (
      jspb.Message.getWrapperField(this, common_delta_pb.StringDelta, 7));
  }


  /** @param {?StringDelta|undefined} value */
  setWebsiteUrl(value?: common_delta_pb.StringDelta) {
    jspb.Message.setWrapperField(this, 7, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearWebsiteUrl() {
    this.setWebsiteUrl(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasWebsiteUrl(): boolean {
    return jspb.Message.getField(this, 7) != null;
  }


  /**
   * optional minga.common.StringDelta email = 8;
   * @return {?StringDelta}
   */
  getEmail(): common_delta_pb.StringDelta {
    return /** @type{?StringDelta} */ (
      jspb.Message.getWrapperField(this, common_delta_pb.StringDelta, 8));
  }


  /** @param {?StringDelta|undefined} value */
  setEmail(value?: common_delta_pb.StringDelta) {
    jspb.Message.setWrapperField(this, 8, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearEmail() {
    this.setEmail(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasEmail(): boolean {
    return jspb.Message.getField(this, 8) != null;
  }


  /**
   * optional minga.common.StringDelta minga_type = 9;
   * @return {?StringDelta}
   */
  getMingaType(): common_delta_pb.StringDelta {
    return /** @type{?StringDelta} */ (
      jspb.Message.getWrapperField(this, common_delta_pb.StringDelta, 9));
  }


  /** @param {?StringDelta|undefined} value */
  setMingaType(value?: common_delta_pb.StringDelta) {
    jspb.Message.setWrapperField(this, 9, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearMingaType() {
    this.setMingaType(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasMingaType(): boolean {
    return jspb.Message.getField(this, 9) != null;
  }


  /**
   * optional minga.common.Int32Delta minga_size = 10;
   * @return {?Int32Delta}
   */
  getMingaSize(): common_delta_pb.Int32Delta {
    return /** @type{?Int32Delta} */ (
      jspb.Message.getWrapperField(this, common_delta_pb.Int32Delta, 10));
  }


  /** @param {?Int32Delta|undefined} value */
  setMingaSize(value?: common_delta_pb.Int32Delta) {
    jspb.Message.setWrapperField(this, 10, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearMingaSize() {
    this.setMingaSize(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasMingaSize(): boolean {
    return jspb.Message.getField(this, 10) != null;
  }


  /**
   * optional minga.common.StringDelta minga_status = 11;
   * @return {?StringDelta}
   */
  getMingaStatus(): common_delta_pb.StringDelta {
    return /** @type{?StringDelta} */ (
      jspb.Message.getWrapperField(this, common_delta_pb.StringDelta, 11));
  }


  /** @param {?StringDelta|undefined} value */
  setMingaStatus(value?: common_delta_pb.StringDelta) {
    jspb.Message.setWrapperField(this, 11, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearMingaStatus() {
    this.setMingaStatus(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasMingaStatus(): boolean {
    return jspb.Message.getField(this, 11) != null;
  }


  /**
   * optional minga.common.StringDelta school_type = 12;
   * @return {?StringDelta}
   */
  getSchoolType(): common_delta_pb.StringDelta {
    return /** @type{?StringDelta} */ (
      jspb.Message.getWrapperField(this, common_delta_pb.StringDelta, 12));
  }


  /** @param {?StringDelta|undefined} value */
  setSchoolType(value?: common_delta_pb.StringDelta) {
    jspb.Message.setWrapperField(this, 12, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearSchoolType() {
    this.setSchoolType(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasSchoolType(): boolean {
    return jspb.Message.getField(this, 12) != null;
  }


  /**
   * optional minga.common.DateDelta start_date = 13;
   * @return {?DateDelta}
   */
  getStartDate(): common_delta_pb.DateDelta {
    return /** @type{?DateDelta} */ (
      jspb.Message.getWrapperField(this, common_delta_pb.DateDelta, 13));
  }


  /** @param {?DateDelta|undefined} value */
  setStartDate(value?: common_delta_pb.DateDelta) {
    jspb.Message.setWrapperField(this, 13, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearStartDate() {
    this.setStartDate(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasStartDate(): boolean {
    return jspb.Message.getField(this, 13) != null;
  }


  /**
   * optional minga.common.DateDelta end_date = 14;
   * @return {?DateDelta}
   */
  getEndDate(): common_delta_pb.DateDelta {
    return /** @type{?DateDelta} */ (
      jspb.Message.getWrapperField(this, common_delta_pb.DateDelta, 14));
  }


  /** @param {?DateDelta|undefined} value */
  setEndDate(value?: common_delta_pb.DateDelta) {
    jspb.Message.setWrapperField(this, 14, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearEndDate() {
    this.setEndDate(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasEndDate(): boolean {
    return jspb.Message.getField(this, 14) != null;
  }


  /**
   * optional minga.common.StringDelta admin_full_name = 15;
   * @return {?StringDelta}
   */
  getAdminFullName(): common_delta_pb.StringDelta {
    return /** @type{?StringDelta} */ (
      jspb.Message.getWrapperField(this, common_delta_pb.StringDelta, 15));
  }


  /** @param {?StringDelta|undefined} value */
  setAdminFullName(value?: common_delta_pb.StringDelta) {
    jspb.Message.setWrapperField(this, 15, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearAdminFullName() {
    this.setAdminFullName(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasAdminFullName(): boolean {
    return jspb.Message.getField(this, 15) != null;
  }


  /**
   * optional minga.common.StringDelta admin_email = 16;
   * @return {?StringDelta}
   */
  getAdminEmail(): common_delta_pb.StringDelta {
    return /** @type{?StringDelta} */ (
      jspb.Message.getWrapperField(this, common_delta_pb.StringDelta, 16));
  }


  /** @param {?StringDelta|undefined} value */
  setAdminEmail(value?: common_delta_pb.StringDelta) {
    jspb.Message.setWrapperField(this, 16, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearAdminEmail() {
    this.setAdminEmail(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasAdminEmail(): boolean {
    return jspb.Message.getField(this, 16) != null;
  }


  /**
   * optional minga.common.StringDelta admin_phone = 17;
   * @return {?StringDelta}
   */
  getAdminPhone(): common_delta_pb.StringDelta {
    return /** @type{?StringDelta} */ (
      jspb.Message.getWrapperField(this, common_delta_pb.StringDelta, 17));
  }


  /** @param {?StringDelta|undefined} value */
  setAdminPhone(value?: common_delta_pb.StringDelta) {
    jspb.Message.setWrapperField(this, 17, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearAdminPhone() {
    this.setAdminPhone(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasAdminPhone(): boolean {
    return jspb.Message.getField(this, 17) != null;
  }


  /**
   * optional minga.common.Int32Delta minga_subscription_id = 18;
   * @return {?Int32Delta}
   */
  getMingaSubscriptionId(): common_delta_pb.Int32Delta {
    return /** @type{?Int32Delta} */ (
      jspb.Message.getWrapperField(this, common_delta_pb.Int32Delta, 18));
  }


  /** @param {?Int32Delta|undefined} value */
  setMingaSubscriptionId(value?: common_delta_pb.Int32Delta) {
    jspb.Message.setWrapperField(this, 18, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearMingaSubscriptionId() {
    this.setMingaSubscriptionId(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasMingaSubscriptionId(): boolean {
    return jspb.Message.getField(this, 18) != null;
  }


  /**
   * optional minga.common.Int32Delta minga_partner_id = 19;
   * @return {?Int32Delta}
   */
  getMingaPartnerId(): common_delta_pb.Int32Delta {
    return /** @type{?Int32Delta} */ (
      jspb.Message.getWrapperField(this, common_delta_pb.Int32Delta, 19));
  }


  /** @param {?Int32Delta|undefined} value */
  setMingaPartnerId(value?: common_delta_pb.Int32Delta) {
    jspb.Message.setWrapperField(this, 19, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearMingaPartnerId() {
    this.setMingaPartnerId(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasMingaPartnerId(): boolean {
    return jspb.Message.getField(this, 19) != null;
  }


  /**
   * optional minga.common.Int32Delta minga_district_id = 20;
   * @return {?Int32Delta}
   */
  getMingaDistrictId(): common_delta_pb.Int32Delta {
    return /** @type{?Int32Delta} */ (
      jspb.Message.getWrapperField(this, common_delta_pb.Int32Delta, 20));
  }


  /** @param {?Int32Delta|undefined} value */
  setMingaDistrictId(value?: common_delta_pb.Int32Delta) {
    jspb.Message.setWrapperField(this, 20, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearMingaDistrictId() {
    this.setMingaDistrictId(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasMingaDistrictId(): boolean {
    return jspb.Message.getField(this, 20) != null;
  }


  /**
   * optional MingaFeatureToggle feature_toggles = 21;
   * @return {?MingaFeatureToggle}
   */
  getFeatureToggles(): MingaFeatureToggle {
    return /** @type{?MingaFeatureToggle} */ (
      jspb.Message.getWrapperField(this, MingaFeatureToggle, 21));
  }


  /** @param {?MingaFeatureToggle|undefined} value */
  setFeatureToggles(value?: MingaFeatureToggle) {
    jspb.Message.setWrapperField(this, 21, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearFeatureToggles() {
    this.setFeatureToggles(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasFeatureToggles(): boolean {
    return jspb.Message.getField(this, 21) != null;
  }


  /**
   * optional minga.common.StringDelta admin_first_name = 22;
   * @return {?StringDelta}
   */
  getAdminFirstName(): common_delta_pb.StringDelta {
    return /** @type{?StringDelta} */ (
      jspb.Message.getWrapperField(this, common_delta_pb.StringDelta, 22));
  }


  /** @param {?StringDelta|undefined} value */
  setAdminFirstName(value?: common_delta_pb.StringDelta) {
    jspb.Message.setWrapperField(this, 22, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearAdminFirstName() {
    this.setAdminFirstName(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasAdminFirstName(): boolean {
    return jspb.Message.getField(this, 22) != null;
  }


  /**
   * optional minga.common.StringDelta admin_last_name = 23;
   * @return {?StringDelta}
   */
  getAdminLastName(): common_delta_pb.StringDelta {
    return /** @type{?StringDelta} */ (
      jspb.Message.getWrapperField(this, common_delta_pb.StringDelta, 23));
  }


  /** @param {?StringDelta|undefined} value */
  setAdminLastName(value?: common_delta_pb.StringDelta) {
    jspb.Message.setWrapperField(this, 23, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearAdminLastName() {
    this.setAdminLastName(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasAdminLastName(): boolean {
    return jspb.Message.getField(this, 23) != null;
  }


  /**
   * optional minga.common.StringDelta hub_spot_id = 24;
   * @return {?StringDelta}
   */
  getHubSpotId(): common_delta_pb.StringDelta {
    return /** @type{?StringDelta} */ (
      jspb.Message.getWrapperField(this, common_delta_pb.StringDelta, 24));
  }


  /** @param {?StringDelta|undefined} value */
  setHubSpotId(value?: common_delta_pb.StringDelta) {
    jspb.Message.setWrapperField(this, 24, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearHubSpotId() {
    this.setHubSpotId(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasHubSpotId(): boolean {
    return jspb.Message.getField(this, 24) != null;
  }


  /**
   * optional minga.common.StringDelta minga_support_tier = 25;
   * @return {?StringDelta}
   */
  getMingaSupportTier(): common_delta_pb.StringDelta {
    return /** @type{?StringDelta} */ (
      jspb.Message.getWrapperField(this, common_delta_pb.StringDelta, 25));
  }


  /** @param {?StringDelta|undefined} value */
  setMingaSupportTier(value?: common_delta_pb.StringDelta) {
    jspb.Message.setWrapperField(this, 25, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearMingaSupportTier() {
    this.setMingaSupportTier(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasMingaSupportTier(): boolean {
    return jspb.Message.getField(this, 25) != null;
  }


}
export namespace CreateMingasRequest {
  export interface AsObject {
    mingaList: Minga.AsObject[];
  }
}
export class CreateMingasRequest extends jspb.Message {
  static readonly displayName = "CreateMingasRequest";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [1];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, CreateMingasRequest.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): CreateMingasRequest.AsObject {
    return CreateMingasRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!CreateMingasRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: CreateMingasRequest) {
    var f: any;
    var obj: any = {
      mingaList: jspb.Message.toObjectList(msg.getMingaList(),
      Minga.toObject, includeInstance)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!CreateMingasRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new CreateMingasRequest;
    return CreateMingasRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!CreateMingasRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!CreateMingasRequest}
   */
  static deserializeBinaryFromReader(msg: CreateMingasRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new Minga;
        reader.readMessage(value1,Minga.deserializeBinaryFromReader);
        msg.addMinga(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!CreateMingasRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: CreateMingasRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getMingaList();
    if (f.length > 0) {
      writer.writeRepeatedMessage(
        1,
        f,
        Minga.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    CreateMingasRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * repeated Minga minga = 1;
   * @return {!Array<!Minga>}
   */
  getMingaList(): Minga[] {
    return /** @type{!Array<!Minga>} */ (
      jspb.Message.getRepeatedWrapperField(this, Minga, 1));
  }


  /** @param {!Array<!Minga>} value */
  setMingaList(value?: Minga[]) {
    jspb.Message.setRepeatedWrapperField(this, 1, value);
  }


  /**
   * @param {!Minga=} opt_value
   * @param {number=} opt_index
   * @return {!Minga}
   */
  addMinga(opt_value?: Minga, opt_index?: number): Minga {
    return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, Minga, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearMingaList() {
    this.setMingaList([]);
  }


}
export namespace CreateMingasResponse {
  export interface AsObject {
    status?: common_legacy_pb.StatusCode;
    reasonList: common_legacy_pb.StatusReason.AsObject[];
    mingaList: Minga.AsObject[];
  }
}
export class CreateMingasResponse extends jspb.Message {
  static readonly displayName = "CreateMingasResponse";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [2,3];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, CreateMingasResponse.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): CreateMingasResponse.AsObject {
    return CreateMingasResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!CreateMingasResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: CreateMingasResponse) {
    var f: any;
    var obj: any = {
      status: jspb.Message.getFieldWithDefault(msg, 1, 0),
      reasonList: jspb.Message.toObjectList(msg.getReasonList(),
      common_legacy_pb.StatusReason.toObject, includeInstance),
      mingaList: jspb.Message.toObjectList(msg.getMingaList(),
      Minga.toObject, includeInstance)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!CreateMingasResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new CreateMingasResponse;
    return CreateMingasResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!CreateMingasResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!CreateMingasResponse}
   */
  static deserializeBinaryFromReader(msg: CreateMingasResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {!StatusCode} */ (reader.readEnum());
        msg.setStatus(value1);
        break;
      case 2:
        var value2 = new common_legacy_pb.StatusReason;
        reader.readMessage(value2,common_legacy_pb.StatusReason.deserializeBinaryFromReader);
        msg.addReason(value2);
        break;
      case 3:
        var value3 = new Minga;
        reader.readMessage(value3,Minga.deserializeBinaryFromReader);
        msg.addMinga(value3);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!CreateMingasResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: CreateMingasResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getStatus();
    if (f !== 0.0) {
      writer.writeEnum(
        1,
        f
      );
    }
    f = message.getReasonList();
    if (f.length > 0) {
      writer.writeRepeatedMessage(
        2,
        f,
        common_legacy_pb.StatusReason.serializeBinaryToWriter
      );
    }
    f = message.getMingaList();
    if (f.length > 0) {
      writer.writeRepeatedMessage(
        3,
        f,
        Minga.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    CreateMingasResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional StatusCode status = 1;
   * @return {!StatusCode}
   */
  getStatus(): common_legacy_pb.StatusCode {
    return /** @type {!StatusCode} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
  };


  /** @param {!StatusCode} value */
  setStatus(value: common_legacy_pb.StatusCode) {
    jspb.Message.setProto3EnumField(this, 1, value);
  }


  /**
   * repeated StatusReason reason = 2;
   * @return {!Array<!StatusReason>}
   */
  getReasonList(): common_legacy_pb.StatusReason[] {
    return /** @type{!Array<!StatusReason>} */ (
      jspb.Message.getRepeatedWrapperField(this, common_legacy_pb.StatusReason, 2));
  }


  /** @param {!Array<!StatusReason>} value */
  setReasonList(value?: common_legacy_pb.StatusReason[]) {
    jspb.Message.setRepeatedWrapperField(this, 2, value);
  }


  /**
   * @param {!StatusReason=} opt_value
   * @param {number=} opt_index
   * @return {!StatusReason}
   */
  addReason(opt_value?: common_legacy_pb.StatusReason, opt_index?: number): common_legacy_pb.StatusReason {
    return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, common_legacy_pb.StatusReason, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearReasonList() {
    this.setReasonList([]);
  }


  /**
   * repeated Minga minga = 3;
   * @return {!Array<!Minga>}
   */
  getMingaList(): Minga[] {
    return /** @type{!Array<!Minga>} */ (
      jspb.Message.getRepeatedWrapperField(this, Minga, 3));
  }


  /** @param {!Array<!Minga>} value */
  setMingaList(value?: Minga[]) {
    jspb.Message.setRepeatedWrapperField(this, 3, value);
  }


  /**
   * @param {!Minga=} opt_value
   * @param {number=} opt_index
   * @return {!Minga}
   */
  addMinga(opt_value?: Minga, opt_index?: number): Minga {
    return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, Minga, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearMingaList() {
    this.setMingaList([]);
  }


}
export namespace ReadMingasRequest {
  export interface AsObject {
    hashList: string[];
  }
}
export class ReadMingasRequest extends jspb.Message {
  static readonly displayName = "ReadMingasRequest";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [1];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, ReadMingasRequest.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): ReadMingasRequest.AsObject {
    return ReadMingasRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!ReadMingasRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: ReadMingasRequest) {
    var f: any;
    var obj: any = {
      hashList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!ReadMingasRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new ReadMingasRequest;
    return ReadMingasRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!ReadMingasRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!ReadMingasRequest}
   */
  static deserializeBinaryFromReader(msg: ReadMingasRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.addHash(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!ReadMingasRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: ReadMingasRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getHashList();
    if (f.length > 0) {
      writer.writeRepeatedString(
        1,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    ReadMingasRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * repeated string hash = 1;
   * @return {!Array<string>}
   */
  getHashList(): string[] {
    return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
  };


  /** @param {!Array<string>} value */
  setHashList(value: string[]) {
    jspb.Message.setField(this, 1, value || []);
  }


  /**
   * @param {string} value
   * @param {number=} opt_index
   */
  addHash(value: string, opt_index?: number): void {
    jspb.Message.addToRepeatedField(this, 1, value, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearHashList() {
    this.setHashList([]);
  }


}
export namespace ReadMingasResponse {
  export interface AsObject {
    status?: common_legacy_pb.StatusCode;
    reasonList: common_legacy_pb.StatusReason.AsObject[];
    mingaList: Minga.AsObject[];
  }
}
export class ReadMingasResponse extends jspb.Message {
  static readonly displayName = "ReadMingasResponse";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [2,3];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, ReadMingasResponse.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): ReadMingasResponse.AsObject {
    return ReadMingasResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!ReadMingasResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: ReadMingasResponse) {
    var f: any;
    var obj: any = {
      status: jspb.Message.getFieldWithDefault(msg, 1, 0),
      reasonList: jspb.Message.toObjectList(msg.getReasonList(),
      common_legacy_pb.StatusReason.toObject, includeInstance),
      mingaList: jspb.Message.toObjectList(msg.getMingaList(),
      Minga.toObject, includeInstance)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!ReadMingasResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new ReadMingasResponse;
    return ReadMingasResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!ReadMingasResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!ReadMingasResponse}
   */
  static deserializeBinaryFromReader(msg: ReadMingasResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {!StatusCode} */ (reader.readEnum());
        msg.setStatus(value1);
        break;
      case 2:
        var value2 = new common_legacy_pb.StatusReason;
        reader.readMessage(value2,common_legacy_pb.StatusReason.deserializeBinaryFromReader);
        msg.addReason(value2);
        break;
      case 3:
        var value3 = new Minga;
        reader.readMessage(value3,Minga.deserializeBinaryFromReader);
        msg.addMinga(value3);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!ReadMingasResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: ReadMingasResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getStatus();
    if (f !== 0.0) {
      writer.writeEnum(
        1,
        f
      );
    }
    f = message.getReasonList();
    if (f.length > 0) {
      writer.writeRepeatedMessage(
        2,
        f,
        common_legacy_pb.StatusReason.serializeBinaryToWriter
      );
    }
    f = message.getMingaList();
    if (f.length > 0) {
      writer.writeRepeatedMessage(
        3,
        f,
        Minga.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    ReadMingasResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional StatusCode status = 1;
   * @return {!StatusCode}
   */
  getStatus(): common_legacy_pb.StatusCode {
    return /** @type {!StatusCode} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
  };


  /** @param {!StatusCode} value */
  setStatus(value: common_legacy_pb.StatusCode) {
    jspb.Message.setProto3EnumField(this, 1, value);
  }


  /**
   * repeated StatusReason reason = 2;
   * @return {!Array<!StatusReason>}
   */
  getReasonList(): common_legacy_pb.StatusReason[] {
    return /** @type{!Array<!StatusReason>} */ (
      jspb.Message.getRepeatedWrapperField(this, common_legacy_pb.StatusReason, 2));
  }


  /** @param {!Array<!StatusReason>} value */
  setReasonList(value?: common_legacy_pb.StatusReason[]) {
    jspb.Message.setRepeatedWrapperField(this, 2, value);
  }


  /**
   * @param {!StatusReason=} opt_value
   * @param {number=} opt_index
   * @return {!StatusReason}
   */
  addReason(opt_value?: common_legacy_pb.StatusReason, opt_index?: number): common_legacy_pb.StatusReason {
    return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, common_legacy_pb.StatusReason, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearReasonList() {
    this.setReasonList([]);
  }


  /**
   * repeated Minga minga = 3;
   * @return {!Array<!Minga>}
   */
  getMingaList(): Minga[] {
    return /** @type{!Array<!Minga>} */ (
      jspb.Message.getRepeatedWrapperField(this, Minga, 3));
  }


  /** @param {!Array<!Minga>} value */
  setMingaList(value?: Minga[]) {
    jspb.Message.setRepeatedWrapperField(this, 3, value);
  }


  /**
   * @param {!Minga=} opt_value
   * @param {number=} opt_index
   * @return {!Minga}
   */
  addMinga(opt_value?: Minga, opt_index?: number): Minga {
    return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, Minga, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearMingaList() {
    this.setMingaList([]);
  }


}
export namespace UpdateMingasRequest {
  export interface AsObject {
    mingaList: MingaDelta.AsObject[];
  }
}
export class UpdateMingasRequest extends jspb.Message {
  static readonly displayName = "UpdateMingasRequest";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [1];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, UpdateMingasRequest.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): UpdateMingasRequest.AsObject {
    return UpdateMingasRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!UpdateMingasRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: UpdateMingasRequest) {
    var f: any;
    var obj: any = {
      mingaList: jspb.Message.toObjectList(msg.getMingaList(),
      MingaDelta.toObject, includeInstance)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!UpdateMingasRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new UpdateMingasRequest;
    return UpdateMingasRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!UpdateMingasRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!UpdateMingasRequest}
   */
  static deserializeBinaryFromReader(msg: UpdateMingasRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new MingaDelta;
        reader.readMessage(value1,MingaDelta.deserializeBinaryFromReader);
        msg.addMinga(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!UpdateMingasRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: UpdateMingasRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getMingaList();
    if (f.length > 0) {
      writer.writeRepeatedMessage(
        1,
        f,
        MingaDelta.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    UpdateMingasRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * repeated MingaDelta minga = 1;
   * @return {!Array<!MingaDelta>}
   */
  getMingaList(): MingaDelta[] {
    return /** @type{!Array<!MingaDelta>} */ (
      jspb.Message.getRepeatedWrapperField(this, MingaDelta, 1));
  }


  /** @param {!Array<!MingaDelta>} value */
  setMingaList(value?: MingaDelta[]) {
    jspb.Message.setRepeatedWrapperField(this, 1, value);
  }


  /**
   * @param {!MingaDelta=} opt_value
   * @param {number=} opt_index
   * @return {!MingaDelta}
   */
  addMinga(opt_value?: MingaDelta, opt_index?: number): MingaDelta {
    return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, MingaDelta, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearMingaList() {
    this.setMingaList([]);
  }


}
export namespace UpdateMingasResponse {
  export interface AsObject {
    status?: common_legacy_pb.StatusCode;
    reasonList: common_legacy_pb.StatusReason.AsObject[];
    mingaList: Minga.AsObject[];
  }
}
export class UpdateMingasResponse extends jspb.Message {
  static readonly displayName = "UpdateMingasResponse";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [2,3];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, UpdateMingasResponse.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): UpdateMingasResponse.AsObject {
    return UpdateMingasResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!UpdateMingasResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: UpdateMingasResponse) {
    var f: any;
    var obj: any = {
      status: jspb.Message.getFieldWithDefault(msg, 1, 0),
      reasonList: jspb.Message.toObjectList(msg.getReasonList(),
      common_legacy_pb.StatusReason.toObject, includeInstance),
      mingaList: jspb.Message.toObjectList(msg.getMingaList(),
      Minga.toObject, includeInstance)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!UpdateMingasResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new UpdateMingasResponse;
    return UpdateMingasResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!UpdateMingasResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!UpdateMingasResponse}
   */
  static deserializeBinaryFromReader(msg: UpdateMingasResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {!StatusCode} */ (reader.readEnum());
        msg.setStatus(value1);
        break;
      case 2:
        var value2 = new common_legacy_pb.StatusReason;
        reader.readMessage(value2,common_legacy_pb.StatusReason.deserializeBinaryFromReader);
        msg.addReason(value2);
        break;
      case 3:
        var value3 = new Minga;
        reader.readMessage(value3,Minga.deserializeBinaryFromReader);
        msg.addMinga(value3);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!UpdateMingasResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: UpdateMingasResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getStatus();
    if (f !== 0.0) {
      writer.writeEnum(
        1,
        f
      );
    }
    f = message.getReasonList();
    if (f.length > 0) {
      writer.writeRepeatedMessage(
        2,
        f,
        common_legacy_pb.StatusReason.serializeBinaryToWriter
      );
    }
    f = message.getMingaList();
    if (f.length > 0) {
      writer.writeRepeatedMessage(
        3,
        f,
        Minga.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    UpdateMingasResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional StatusCode status = 1;
   * @return {!StatusCode}
   */
  getStatus(): common_legacy_pb.StatusCode {
    return /** @type {!StatusCode} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
  };


  /** @param {!StatusCode} value */
  setStatus(value: common_legacy_pb.StatusCode) {
    jspb.Message.setProto3EnumField(this, 1, value);
  }


  /**
   * repeated StatusReason reason = 2;
   * @return {!Array<!StatusReason>}
   */
  getReasonList(): common_legacy_pb.StatusReason[] {
    return /** @type{!Array<!StatusReason>} */ (
      jspb.Message.getRepeatedWrapperField(this, common_legacy_pb.StatusReason, 2));
  }


  /** @param {!Array<!StatusReason>} value */
  setReasonList(value?: common_legacy_pb.StatusReason[]) {
    jspb.Message.setRepeatedWrapperField(this, 2, value);
  }


  /**
   * @param {!StatusReason=} opt_value
   * @param {number=} opt_index
   * @return {!StatusReason}
   */
  addReason(opt_value?: common_legacy_pb.StatusReason, opt_index?: number): common_legacy_pb.StatusReason {
    return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, common_legacy_pb.StatusReason, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearReasonList() {
    this.setReasonList([]);
  }


  /**
   * repeated Minga minga = 3;
   * @return {!Array<!Minga>}
   */
  getMingaList(): Minga[] {
    return /** @type{!Array<!Minga>} */ (
      jspb.Message.getRepeatedWrapperField(this, Minga, 3));
  }


  /** @param {!Array<!Minga>} value */
  setMingaList(value?: Minga[]) {
    jspb.Message.setRepeatedWrapperField(this, 3, value);
  }


  /**
   * @param {!Minga=} opt_value
   * @param {number=} opt_index
   * @return {!Minga}
   */
  addMinga(opt_value?: Minga, opt_index?: number): Minga {
    return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, Minga, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearMingaList() {
    this.setMingaList([]);
  }


}
export namespace DeleteMingasRequest {
  export interface AsObject {
    hashList: string[];
  }
}
export class DeleteMingasRequest extends jspb.Message {
  static readonly displayName = "DeleteMingasRequest";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [1];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, DeleteMingasRequest.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): DeleteMingasRequest.AsObject {
    return DeleteMingasRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!DeleteMingasRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: DeleteMingasRequest) {
    var f: any;
    var obj: any = {
      hashList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!DeleteMingasRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new DeleteMingasRequest;
    return DeleteMingasRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!DeleteMingasRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!DeleteMingasRequest}
   */
  static deserializeBinaryFromReader(msg: DeleteMingasRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.addHash(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!DeleteMingasRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: DeleteMingasRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getHashList();
    if (f.length > 0) {
      writer.writeRepeatedString(
        1,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    DeleteMingasRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * repeated string hash = 1;
   * @return {!Array<string>}
   */
  getHashList(): string[] {
    return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
  };


  /** @param {!Array<string>} value */
  setHashList(value: string[]) {
    jspb.Message.setField(this, 1, value || []);
  }


  /**
   * @param {string} value
   * @param {number=} opt_index
   */
  addHash(value: string, opt_index?: number): void {
    jspb.Message.addToRepeatedField(this, 1, value, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearHashList() {
    this.setHashList([]);
  }


}
export namespace DeleteMingasResponse {
  export interface AsObject {
    status?: common_legacy_pb.StatusCode;
    reasonList: common_legacy_pb.StatusReason.AsObject[];
  }
}
export class DeleteMingasResponse extends jspb.Message {
  static readonly displayName = "DeleteMingasResponse";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [2];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, DeleteMingasResponse.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): DeleteMingasResponse.AsObject {
    return DeleteMingasResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!DeleteMingasResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: DeleteMingasResponse) {
    var f: any;
    var obj: any = {
      status: jspb.Message.getFieldWithDefault(msg, 1, 0),
      reasonList: jspb.Message.toObjectList(msg.getReasonList(),
      common_legacy_pb.StatusReason.toObject, includeInstance)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!DeleteMingasResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new DeleteMingasResponse;
    return DeleteMingasResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!DeleteMingasResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!DeleteMingasResponse}
   */
  static deserializeBinaryFromReader(msg: DeleteMingasResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {!StatusCode} */ (reader.readEnum());
        msg.setStatus(value1);
        break;
      case 2:
        var value2 = new common_legacy_pb.StatusReason;
        reader.readMessage(value2,common_legacy_pb.StatusReason.deserializeBinaryFromReader);
        msg.addReason(value2);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!DeleteMingasResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: DeleteMingasResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getStatus();
    if (f !== 0.0) {
      writer.writeEnum(
        1,
        f
      );
    }
    f = message.getReasonList();
    if (f.length > 0) {
      writer.writeRepeatedMessage(
        2,
        f,
        common_legacy_pb.StatusReason.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    DeleteMingasResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional StatusCode status = 1;
   * @return {!StatusCode}
   */
  getStatus(): common_legacy_pb.StatusCode {
    return /** @type {!StatusCode} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
  };


  /** @param {!StatusCode} value */
  setStatus(value: common_legacy_pb.StatusCode) {
    jspb.Message.setProto3EnumField(this, 1, value);
  }


  /**
   * repeated StatusReason reason = 2;
   * @return {!Array<!StatusReason>}
   */
  getReasonList(): common_legacy_pb.StatusReason[] {
    return /** @type{!Array<!StatusReason>} */ (
      jspb.Message.getRepeatedWrapperField(this, common_legacy_pb.StatusReason, 2));
  }


  /** @param {!Array<!StatusReason>} value */
  setReasonList(value?: common_legacy_pb.StatusReason[]) {
    jspb.Message.setRepeatedWrapperField(this, 2, value);
  }


  /**
   * @param {!StatusReason=} opt_value
   * @param {number=} opt_index
   * @return {!StatusReason}
   */
  addReason(opt_value?: common_legacy_pb.StatusReason, opt_index?: number): common_legacy_pb.StatusReason {
    return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, common_legacy_pb.StatusReason, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearReasonList() {
    this.setReasonList([]);
  }


}
export namespace ReadUserMingaRequest {
  export interface AsObject {
  }
}
export class ReadUserMingaRequest extends jspb.Message {
  static readonly displayName = "ReadUserMingaRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): ReadUserMingaRequest.AsObject {
    return ReadUserMingaRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!ReadUserMingaRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: ReadUserMingaRequest) {
    var f: any;
    var obj: any = {

    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!ReadUserMingaRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new ReadUserMingaRequest;
    return ReadUserMingaRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!ReadUserMingaRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!ReadUserMingaRequest}
   */
  static deserializeBinaryFromReader(msg: ReadUserMingaRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!ReadUserMingaRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: ReadUserMingaRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    ReadUserMingaRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
}
export namespace ReadUserMingaResponse {
  export interface AsObject {
    status?: common_legacy_pb.StatusCode;
    reason?: common_legacy_pb.StatusReason.AsObject;
    minga?: Minga.AsObject;
    memberCount: number;
    mingaAddress: string;
  }
}
export class ReadUserMingaResponse extends jspb.Message {
  static readonly displayName = "ReadUserMingaResponse";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): ReadUserMingaResponse.AsObject {
    return ReadUserMingaResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!ReadUserMingaResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: ReadUserMingaResponse) {
    var f: any;
    var obj: any = {
      status: jspb.Message.getFieldWithDefault(msg, 1, 0),
      reason: (f = msg.getReason()) && common_legacy_pb.StatusReason.toObject(includeInstance, f),
      minga: (f = msg.getMinga()) && Minga.toObject(includeInstance, f),
      memberCount: jspb.Message.getFieldWithDefault(msg, 4, 0),
      mingaAddress: jspb.Message.getFieldWithDefault(msg, 5, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!ReadUserMingaResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new ReadUserMingaResponse;
    return ReadUserMingaResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!ReadUserMingaResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!ReadUserMingaResponse}
   */
  static deserializeBinaryFromReader(msg: ReadUserMingaResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {!StatusCode} */ (reader.readEnum());
        msg.setStatus(value1);
        break;
      case 2:
        var value2 = new common_legacy_pb.StatusReason;
        reader.readMessage(value2,common_legacy_pb.StatusReason.deserializeBinaryFromReader);
        msg.setReason(value2);
        break;
      case 3:
        var value3 = new Minga;
        reader.readMessage(value3,Minga.deserializeBinaryFromReader);
        msg.setMinga(value3);
        break;
      case 4:
        var value4 = /** @type {number} */ (reader.readInt32());
        msg.setMemberCount(value4);
        break;
      case 5:
        var value5 = /** @type {string} */ (reader.readString());
        msg.setMingaAddress(value5);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!ReadUserMingaResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: ReadUserMingaResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getStatus();
    if (f !== 0.0) {
      writer.writeEnum(
        1,
        f
      );
    }
    f = message.getReason();
    if (f != null) {
      writer.writeMessage(
        2,
        f,
        common_legacy_pb.StatusReason.serializeBinaryToWriter
      );
    }
    f = message.getMinga();
    if (f != null) {
      writer.writeMessage(
        3,
        f,
        Minga.serializeBinaryToWriter
      );
    }
    f = message.getMemberCount();
    if (f !== 0) {
      writer.writeInt32(
        4,
        f
      );
    }
    f = message.getMingaAddress();
    if (f.length > 0) {
      writer.writeString(
        5,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    ReadUserMingaResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional StatusCode status = 1;
   * @return {!StatusCode}
   */
  getStatus(): common_legacy_pb.StatusCode {
    return /** @type {!StatusCode} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
  };


  /** @param {!StatusCode} value */
  setStatus(value: common_legacy_pb.StatusCode) {
    jspb.Message.setProto3EnumField(this, 1, value);
  }


  /**
   * optional StatusReason reason = 2;
   * @return {?StatusReason}
   */
  getReason(): common_legacy_pb.StatusReason {
    return /** @type{?StatusReason} */ (
      jspb.Message.getWrapperField(this, common_legacy_pb.StatusReason, 2));
  }


  /** @param {?StatusReason|undefined} value */
  setReason(value?: common_legacy_pb.StatusReason) {
    jspb.Message.setWrapperField(this, 2, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearReason() {
    this.setReason(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasReason(): boolean {
    return jspb.Message.getField(this, 2) != null;
  }


  /**
   * optional Minga minga = 3;
   * @return {?Minga}
   */
  getMinga(): Minga {
    return /** @type{?Minga} */ (
      jspb.Message.getWrapperField(this, Minga, 3));
  }


  /** @param {?Minga|undefined} value */
  setMinga(value?: Minga) {
    jspb.Message.setWrapperField(this, 3, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearMinga() {
    this.setMinga(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasMinga(): boolean {
    return jspb.Message.getField(this, 3) != null;
  }


  /**
   * optional int32 member_count = 4;
   * @return {number}
   */
  getMemberCount(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
  };


  /** @param {number} value */
  setMemberCount(value: number) {
    jspb.Message.setProto3IntField(this, 4, value);
  }


  /**
   * optional string minga_address = 5;
   * @return {string}
   */
  getMingaAddress(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
  };


  /** @param {string} value */
  setMingaAddress(value: string) {
    jspb.Message.setProto3StringField(this, 5, value);
  }


}
export namespace UpdateMingaProfileRequest {
  export interface AsObject {
    minga?: MingaDelta.AsObject;
  }
}
export class UpdateMingaProfileRequest extends jspb.Message {
  static readonly displayName = "UpdateMingaProfileRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): UpdateMingaProfileRequest.AsObject {
    return UpdateMingaProfileRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!UpdateMingaProfileRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: UpdateMingaProfileRequest) {
    var f: any;
    var obj: any = {
      minga: (f = msg.getMinga()) && MingaDelta.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!UpdateMingaProfileRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new UpdateMingaProfileRequest;
    return UpdateMingaProfileRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!UpdateMingaProfileRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!UpdateMingaProfileRequest}
   */
  static deserializeBinaryFromReader(msg: UpdateMingaProfileRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new MingaDelta;
        reader.readMessage(value1,MingaDelta.deserializeBinaryFromReader);
        msg.setMinga(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!UpdateMingaProfileRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: UpdateMingaProfileRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getMinga();
    if (f != null) {
      writer.writeMessage(
        1,
        f,
        MingaDelta.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    UpdateMingaProfileRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional MingaDelta minga = 1;
   * @return {?MingaDelta}
   */
  getMinga(): MingaDelta {
    return /** @type{?MingaDelta} */ (
      jspb.Message.getWrapperField(this, MingaDelta, 1));
  }


  /** @param {?MingaDelta|undefined} value */
  setMinga(value?: MingaDelta) {
    jspb.Message.setWrapperField(this, 1, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearMinga() {
    this.setMinga(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasMinga(): boolean {
    return jspb.Message.getField(this, 1) != null;
  }


}
export namespace UpdateMingaProfileResponse {
  export interface AsObject {
    status?: common_legacy_pb.StatusCode;
    reason?: common_legacy_pb.StatusReason.AsObject;
    minga?: Minga.AsObject;
  }
}
export class UpdateMingaProfileResponse extends jspb.Message {
  static readonly displayName = "UpdateMingaProfileResponse";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): UpdateMingaProfileResponse.AsObject {
    return UpdateMingaProfileResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!UpdateMingaProfileResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: UpdateMingaProfileResponse) {
    var f: any;
    var obj: any = {
      status: jspb.Message.getFieldWithDefault(msg, 1, 0),
      reason: (f = msg.getReason()) && common_legacy_pb.StatusReason.toObject(includeInstance, f),
      minga: (f = msg.getMinga()) && Minga.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!UpdateMingaProfileResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new UpdateMingaProfileResponse;
    return UpdateMingaProfileResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!UpdateMingaProfileResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!UpdateMingaProfileResponse}
   */
  static deserializeBinaryFromReader(msg: UpdateMingaProfileResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {!StatusCode} */ (reader.readEnum());
        msg.setStatus(value1);
        break;
      case 2:
        var value2 = new common_legacy_pb.StatusReason;
        reader.readMessage(value2,common_legacy_pb.StatusReason.deserializeBinaryFromReader);
        msg.setReason(value2);
        break;
      case 3:
        var value3 = new Minga;
        reader.readMessage(value3,Minga.deserializeBinaryFromReader);
        msg.setMinga(value3);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!UpdateMingaProfileResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: UpdateMingaProfileResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getStatus();
    if (f !== 0.0) {
      writer.writeEnum(
        1,
        f
      );
    }
    f = message.getReason();
    if (f != null) {
      writer.writeMessage(
        2,
        f,
        common_legacy_pb.StatusReason.serializeBinaryToWriter
      );
    }
    f = message.getMinga();
    if (f != null) {
      writer.writeMessage(
        3,
        f,
        Minga.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    UpdateMingaProfileResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional StatusCode status = 1;
   * @return {!StatusCode}
   */
  getStatus(): common_legacy_pb.StatusCode {
    return /** @type {!StatusCode} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
  };


  /** @param {!StatusCode} value */
  setStatus(value: common_legacy_pb.StatusCode) {
    jspb.Message.setProto3EnumField(this, 1, value);
  }


  /**
   * optional StatusReason reason = 2;
   * @return {?StatusReason}
   */
  getReason(): common_legacy_pb.StatusReason {
    return /** @type{?StatusReason} */ (
      jspb.Message.getWrapperField(this, common_legacy_pb.StatusReason, 2));
  }


  /** @param {?StatusReason|undefined} value */
  setReason(value?: common_legacy_pb.StatusReason) {
    jspb.Message.setWrapperField(this, 2, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearReason() {
    this.setReason(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasReason(): boolean {
    return jspb.Message.getField(this, 2) != null;
  }


  /**
   * optional Minga minga = 3;
   * @return {?Minga}
   */
  getMinga(): Minga {
    return /** @type{?Minga} */ (
      jspb.Message.getWrapperField(this, Minga, 3));
  }


  /** @param {?Minga|undefined} value */
  setMinga(value?: Minga) {
    jspb.Message.setWrapperField(this, 3, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearMinga() {
    this.setMinga(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasMinga(): boolean {
    return jspb.Message.getField(this, 3) != null;
  }


}
export namespace MingaFilesResponse {
  export interface AsObject {
    fileList: gateway_file_pb.FileDetails.AsObject[];
  }
}
export class MingaFilesResponse extends jspb.Message {
  static readonly displayName = "MingaFilesResponse";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [1];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, MingaFilesResponse.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): MingaFilesResponse.AsObject {
    return MingaFilesResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!MingaFilesResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: MingaFilesResponse) {
    var f: any;
    var obj: any = {
      fileList: jspb.Message.toObjectList(msg.getFileList(),
      gateway_file_pb.FileDetails.toObject, includeInstance)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!MingaFilesResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new MingaFilesResponse;
    return MingaFilesResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!MingaFilesResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!MingaFilesResponse}
   */
  static deserializeBinaryFromReader(msg: MingaFilesResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new gateway_file_pb.FileDetails;
        reader.readMessage(value1,gateway_file_pb.FileDetails.deserializeBinaryFromReader);
        msg.addFile(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!MingaFilesResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: MingaFilesResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getFileList();
    if (f.length > 0) {
      writer.writeRepeatedMessage(
        1,
        f,
        gateway_file_pb.FileDetails.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    MingaFilesResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * repeated FileDetails file = 1;
   * @return {!Array<!FileDetails>}
   */
  getFileList(): gateway_file_pb.FileDetails[] {
    return /** @type{!Array<!FileDetails>} */ (
      jspb.Message.getRepeatedWrapperField(this, gateway_file_pb.FileDetails, 1));
  }


  /** @param {!Array<!FileDetails>} value */
  setFileList(value?: gateway_file_pb.FileDetails[]) {
    jspb.Message.setRepeatedWrapperField(this, 1, value);
  }


  /**
   * @param {!FileDetails=} opt_value
   * @param {number=} opt_index
   * @return {!FileDetails}
   */
  addFile(opt_value?: gateway_file_pb.FileDetails, opt_index?: number): gateway_file_pb.FileDetails {
    return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, gateway_file_pb.FileDetails, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearFileList() {
    this.setFileList([]);
  }


}
export namespace MingaFilesRequest {
  export interface AsObject {
  }
}
export class MingaFilesRequest extends jspb.Message {
  static readonly displayName = "MingaFilesRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): MingaFilesRequest.AsObject {
    return MingaFilesRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!MingaFilesRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: MingaFilesRequest) {
    var f: any;
    var obj: any = {

    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!MingaFilesRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new MingaFilesRequest;
    return MingaFilesRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!MingaFilesRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!MingaFilesRequest}
   */
  static deserializeBinaryFromReader(msg: MingaFilesRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!MingaFilesRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: MingaFilesRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    MingaFilesRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
}
export namespace SearchForMingaRequest {
  export interface AsObject {
    searchText: string;
  }
}
export class SearchForMingaRequest extends jspb.Message {
  static readonly displayName = "SearchForMingaRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): SearchForMingaRequest.AsObject {
    return SearchForMingaRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!SearchForMingaRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: SearchForMingaRequest) {
    var f: any;
    var obj: any = {
      searchText: jspb.Message.getFieldWithDefault(msg, 1, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!SearchForMingaRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new SearchForMingaRequest;
    return SearchForMingaRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!SearchForMingaRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!SearchForMingaRequest}
   */
  static deserializeBinaryFromReader(msg: SearchForMingaRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setSearchText(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!SearchForMingaRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: SearchForMingaRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getSearchText();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    SearchForMingaRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string search_text = 1;
   * @return {string}
   */
  getSearchText(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setSearchText(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


}
export namespace SearchForMingaResponse {
  export interface AsObject {
    mingasList: MingaDashboardInfo2.AsObject[];
  }
}
export class SearchForMingaResponse extends jspb.Message {
  static readonly displayName = "SearchForMingaResponse";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [1];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, SearchForMingaResponse.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): SearchForMingaResponse.AsObject {
    return SearchForMingaResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!SearchForMingaResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: SearchForMingaResponse) {
    var f: any;
    var obj: any = {
      mingasList: jspb.Message.toObjectList(msg.getMingasList(),
      MingaDashboardInfo2.toObject, includeInstance)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!SearchForMingaResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new SearchForMingaResponse;
    return SearchForMingaResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!SearchForMingaResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!SearchForMingaResponse}
   */
  static deserializeBinaryFromReader(msg: SearchForMingaResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new MingaDashboardInfo2;
        reader.readMessage(value1,MingaDashboardInfo2.deserializeBinaryFromReader);
        msg.addMingas(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!SearchForMingaResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: SearchForMingaResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getMingasList();
    if (f.length > 0) {
      writer.writeRepeatedMessage(
        1,
        f,
        MingaDashboardInfo2.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    SearchForMingaResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * repeated MingaDashboardInfo2 mingas = 1;
   * @return {!Array<!MingaDashboardInfo2>}
   */
  getMingasList(): MingaDashboardInfo2[] {
    return /** @type{!Array<!MingaDashboardInfo2>} */ (
      jspb.Message.getRepeatedWrapperField(this, MingaDashboardInfo2, 1));
  }


  /** @param {!Array<!MingaDashboardInfo2>} value */
  setMingasList(value?: MingaDashboardInfo2[]) {
    jspb.Message.setRepeatedWrapperField(this, 1, value);
  }


  /**
   * @param {!MingaDashboardInfo2=} opt_value
   * @param {number=} opt_index
   * @return {!MingaDashboardInfo2}
   */
  addMingas(opt_value?: MingaDashboardInfo2, opt_index?: number): MingaDashboardInfo2 {
    return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, MingaDashboardInfo2, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearMingasList() {
    this.setMingasList([]);
  }


}
export namespace UpdateMingaImageRequest {
  export interface AsObject {
    asset: string;
    mingaHash: string;
  }
}
export class UpdateMingaImageRequest extends jspb.Message {
  static readonly displayName = "UpdateMingaImageRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): UpdateMingaImageRequest.AsObject {
    return UpdateMingaImageRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!UpdateMingaImageRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: UpdateMingaImageRequest) {
    var f: any;
    var obj: any = {
      asset: jspb.Message.getFieldWithDefault(msg, 1, ""),
      mingaHash: jspb.Message.getFieldWithDefault(msg, 2, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!UpdateMingaImageRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new UpdateMingaImageRequest;
    return UpdateMingaImageRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!UpdateMingaImageRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!UpdateMingaImageRequest}
   */
  static deserializeBinaryFromReader(msg: UpdateMingaImageRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setAsset(value1);
        break;
      case 2:
        var value2 = /** @type {string} */ (reader.readString());
        msg.setMingaHash(value2);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!UpdateMingaImageRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: UpdateMingaImageRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getAsset();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
    f = message.getMingaHash();
    if (f.length > 0) {
      writer.writeString(
        2,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    UpdateMingaImageRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string asset = 1;
   * @return {string}
   */
  getAsset(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setAsset(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


  /**
   * optional string minga_hash = 2;
   * @return {string}
   */
  getMingaHash(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
  };


  /** @param {string} value */
  setMingaHash(value: string) {
    jspb.Message.setProto3StringField(this, 2, value);
  }


}
export namespace UpdateMingaImageResponse {
  export interface AsObject {
    status?: common_legacy_pb.StatusCode;
    mingaImageInfo?: image_image_pb.ImageInfo.AsObject;
  }
}
export class UpdateMingaImageResponse extends jspb.Message {
  static readonly displayName = "UpdateMingaImageResponse";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): UpdateMingaImageResponse.AsObject {
    return UpdateMingaImageResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!UpdateMingaImageResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: UpdateMingaImageResponse) {
    var f: any;
    var obj: any = {
      status: jspb.Message.getFieldWithDefault(msg, 1, 0),
      mingaImageInfo: (f = msg.getMingaImageInfo()) && image_image_pb.ImageInfo.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!UpdateMingaImageResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new UpdateMingaImageResponse;
    return UpdateMingaImageResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!UpdateMingaImageResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!UpdateMingaImageResponse}
   */
  static deserializeBinaryFromReader(msg: UpdateMingaImageResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {!StatusCode} */ (reader.readEnum());
        msg.setStatus(value1);
        break;
      case 2:
        var value2 = new image_image_pb.ImageInfo;
        reader.readMessage(value2,image_image_pb.ImageInfo.deserializeBinaryFromReader);
        msg.setMingaImageInfo(value2);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!UpdateMingaImageResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: UpdateMingaImageResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getStatus();
    if (f !== 0.0) {
      writer.writeEnum(
        1,
        f
      );
    }
    f = message.getMingaImageInfo();
    if (f != null) {
      writer.writeMessage(
        2,
        f,
        image_image_pb.ImageInfo.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    UpdateMingaImageResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional StatusCode status = 1;
   * @return {!StatusCode}
   */
  getStatus(): common_legacy_pb.StatusCode {
    return /** @type {!StatusCode} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
  };


  /** @param {!StatusCode} value */
  setStatus(value: common_legacy_pb.StatusCode) {
    jspb.Message.setProto3EnumField(this, 1, value);
  }


  /**
   * optional ImageInfo minga_image_info = 2;
   * @return {?ImageInfo}
   */
  getMingaImageInfo(): image_image_pb.ImageInfo {
    return /** @type{?ImageInfo} */ (
      jspb.Message.getWrapperField(this, image_image_pb.ImageInfo, 2));
  }


  /** @param {?ImageInfo|undefined} value */
  setMingaImageInfo(value?: image_image_pb.ImageInfo) {
    jspb.Message.setWrapperField(this, 2, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearMingaImageInfo() {
    this.setMingaImageInfo(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasMingaImageInfo(): boolean {
    return jspb.Message.getField(this, 2) != null;
  }


}
export namespace AddMingaLinkRequest {
  export interface AsObject {
    link?: MingaLink.AsObject;
  }
}
export class AddMingaLinkRequest extends jspb.Message {
  static readonly displayName = "AddMingaLinkRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): AddMingaLinkRequest.AsObject {
    return AddMingaLinkRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!AddMingaLinkRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: AddMingaLinkRequest) {
    var f: any;
    var obj: any = {
      link: (f = msg.getLink()) && MingaLink.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!AddMingaLinkRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new AddMingaLinkRequest;
    return AddMingaLinkRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!AddMingaLinkRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!AddMingaLinkRequest}
   */
  static deserializeBinaryFromReader(msg: AddMingaLinkRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new MingaLink;
        reader.readMessage(value1,MingaLink.deserializeBinaryFromReader);
        msg.setLink(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!AddMingaLinkRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: AddMingaLinkRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getLink();
    if (f != null) {
      writer.writeMessage(
        1,
        f,
        MingaLink.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    AddMingaLinkRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional MingaLink link = 1;
   * @return {?MingaLink}
   */
  getLink(): MingaLink {
    return /** @type{?MingaLink} */ (
      jspb.Message.getWrapperField(this, MingaLink, 1));
  }


  /** @param {?MingaLink|undefined} value */
  setLink(value?: MingaLink) {
    jspb.Message.setWrapperField(this, 1, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearLink() {
    this.setLink(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasLink(): boolean {
    return jspb.Message.getField(this, 1) != null;
  }


}
export namespace AddMingaLinkResponse {
  export interface AsObject {
    status?: common_legacy_pb.StatusCode;
    reason?: common_legacy_pb.StatusReason.AsObject;
    link?: MingaLink.AsObject;
  }
}
export class AddMingaLinkResponse extends jspb.Message {
  static readonly displayName = "AddMingaLinkResponse";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): AddMingaLinkResponse.AsObject {
    return AddMingaLinkResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!AddMingaLinkResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: AddMingaLinkResponse) {
    var f: any;
    var obj: any = {
      status: jspb.Message.getFieldWithDefault(msg, 1, 0),
      reason: (f = msg.getReason()) && common_legacy_pb.StatusReason.toObject(includeInstance, f),
      link: (f = msg.getLink()) && MingaLink.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!AddMingaLinkResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new AddMingaLinkResponse;
    return AddMingaLinkResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!AddMingaLinkResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!AddMingaLinkResponse}
   */
  static deserializeBinaryFromReader(msg: AddMingaLinkResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {!StatusCode} */ (reader.readEnum());
        msg.setStatus(value1);
        break;
      case 2:
        var value2 = new common_legacy_pb.StatusReason;
        reader.readMessage(value2,common_legacy_pb.StatusReason.deserializeBinaryFromReader);
        msg.setReason(value2);
        break;
      case 3:
        var value3 = new MingaLink;
        reader.readMessage(value3,MingaLink.deserializeBinaryFromReader);
        msg.setLink(value3);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!AddMingaLinkResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: AddMingaLinkResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getStatus();
    if (f !== 0.0) {
      writer.writeEnum(
        1,
        f
      );
    }
    f = message.getReason();
    if (f != null) {
      writer.writeMessage(
        2,
        f,
        common_legacy_pb.StatusReason.serializeBinaryToWriter
      );
    }
    f = message.getLink();
    if (f != null) {
      writer.writeMessage(
        3,
        f,
        MingaLink.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    AddMingaLinkResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional StatusCode status = 1;
   * @return {!StatusCode}
   */
  getStatus(): common_legacy_pb.StatusCode {
    return /** @type {!StatusCode} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
  };


  /** @param {!StatusCode} value */
  setStatus(value: common_legacy_pb.StatusCode) {
    jspb.Message.setProto3EnumField(this, 1, value);
  }


  /**
   * optional StatusReason reason = 2;
   * @return {?StatusReason}
   */
  getReason(): common_legacy_pb.StatusReason {
    return /** @type{?StatusReason} */ (
      jspb.Message.getWrapperField(this, common_legacy_pb.StatusReason, 2));
  }


  /** @param {?StatusReason|undefined} value */
  setReason(value?: common_legacy_pb.StatusReason) {
    jspb.Message.setWrapperField(this, 2, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearReason() {
    this.setReason(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasReason(): boolean {
    return jspb.Message.getField(this, 2) != null;
  }


  /**
   * optional MingaLink link = 3;
   * @return {?MingaLink}
   */
  getLink(): MingaLink {
    return /** @type{?MingaLink} */ (
      jspb.Message.getWrapperField(this, MingaLink, 3));
  }


  /** @param {?MingaLink|undefined} value */
  setLink(value?: MingaLink) {
    jspb.Message.setWrapperField(this, 3, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearLink() {
    this.setLink(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasLink(): boolean {
    return jspb.Message.getField(this, 3) != null;
  }


}
export namespace UpdateMingaLinkRequest {
  export interface AsObject {
    link?: MingaLink.AsObject;
  }
}
export class UpdateMingaLinkRequest extends jspb.Message {
  static readonly displayName = "UpdateMingaLinkRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): UpdateMingaLinkRequest.AsObject {
    return UpdateMingaLinkRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!UpdateMingaLinkRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: UpdateMingaLinkRequest) {
    var f: any;
    var obj: any = {
      link: (f = msg.getLink()) && MingaLink.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!UpdateMingaLinkRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new UpdateMingaLinkRequest;
    return UpdateMingaLinkRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!UpdateMingaLinkRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!UpdateMingaLinkRequest}
   */
  static deserializeBinaryFromReader(msg: UpdateMingaLinkRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new MingaLink;
        reader.readMessage(value1,MingaLink.deserializeBinaryFromReader);
        msg.setLink(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!UpdateMingaLinkRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: UpdateMingaLinkRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getLink();
    if (f != null) {
      writer.writeMessage(
        1,
        f,
        MingaLink.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    UpdateMingaLinkRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional MingaLink link = 1;
   * @return {?MingaLink}
   */
  getLink(): MingaLink {
    return /** @type{?MingaLink} */ (
      jspb.Message.getWrapperField(this, MingaLink, 1));
  }


  /** @param {?MingaLink|undefined} value */
  setLink(value?: MingaLink) {
    jspb.Message.setWrapperField(this, 1, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearLink() {
    this.setLink(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasLink(): boolean {
    return jspb.Message.getField(this, 1) != null;
  }


}
export namespace UpdateMingaLinkResponse {
  export interface AsObject {
    status?: common_legacy_pb.StatusCode;
    reason?: common_legacy_pb.StatusReason.AsObject;
    link?: MingaLink.AsObject;
  }
}
export class UpdateMingaLinkResponse extends jspb.Message {
  static readonly displayName = "UpdateMingaLinkResponse";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): UpdateMingaLinkResponse.AsObject {
    return UpdateMingaLinkResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!UpdateMingaLinkResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: UpdateMingaLinkResponse) {
    var f: any;
    var obj: any = {
      status: jspb.Message.getFieldWithDefault(msg, 1, 0),
      reason: (f = msg.getReason()) && common_legacy_pb.StatusReason.toObject(includeInstance, f),
      link: (f = msg.getLink()) && MingaLink.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!UpdateMingaLinkResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new UpdateMingaLinkResponse;
    return UpdateMingaLinkResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!UpdateMingaLinkResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!UpdateMingaLinkResponse}
   */
  static deserializeBinaryFromReader(msg: UpdateMingaLinkResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {!StatusCode} */ (reader.readEnum());
        msg.setStatus(value1);
        break;
      case 2:
        var value2 = new common_legacy_pb.StatusReason;
        reader.readMessage(value2,common_legacy_pb.StatusReason.deserializeBinaryFromReader);
        msg.setReason(value2);
        break;
      case 3:
        var value3 = new MingaLink;
        reader.readMessage(value3,MingaLink.deserializeBinaryFromReader);
        msg.setLink(value3);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!UpdateMingaLinkResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: UpdateMingaLinkResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getStatus();
    if (f !== 0.0) {
      writer.writeEnum(
        1,
        f
      );
    }
    f = message.getReason();
    if (f != null) {
      writer.writeMessage(
        2,
        f,
        common_legacy_pb.StatusReason.serializeBinaryToWriter
      );
    }
    f = message.getLink();
    if (f != null) {
      writer.writeMessage(
        3,
        f,
        MingaLink.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    UpdateMingaLinkResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional StatusCode status = 1;
   * @return {!StatusCode}
   */
  getStatus(): common_legacy_pb.StatusCode {
    return /** @type {!StatusCode} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
  };


  /** @param {!StatusCode} value */
  setStatus(value: common_legacy_pb.StatusCode) {
    jspb.Message.setProto3EnumField(this, 1, value);
  }


  /**
   * optional StatusReason reason = 2;
   * @return {?StatusReason}
   */
  getReason(): common_legacy_pb.StatusReason {
    return /** @type{?StatusReason} */ (
      jspb.Message.getWrapperField(this, common_legacy_pb.StatusReason, 2));
  }


  /** @param {?StatusReason|undefined} value */
  setReason(value?: common_legacy_pb.StatusReason) {
    jspb.Message.setWrapperField(this, 2, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearReason() {
    this.setReason(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasReason(): boolean {
    return jspb.Message.getField(this, 2) != null;
  }


  /**
   * optional MingaLink link = 3;
   * @return {?MingaLink}
   */
  getLink(): MingaLink {
    return /** @type{?MingaLink} */ (
      jspb.Message.getWrapperField(this, MingaLink, 3));
  }


  /** @param {?MingaLink|undefined} value */
  setLink(value?: MingaLink) {
    jspb.Message.setWrapperField(this, 3, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearLink() {
    this.setLink(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasLink(): boolean {
    return jspb.Message.getField(this, 3) != null;
  }


}
export namespace UpdateMingaStudentIdImageRequest {
  export interface AsObject {
    assetPath: string;
  }
}
export class UpdateMingaStudentIdImageRequest extends jspb.Message {
  static readonly displayName = "UpdateMingaStudentIdImageRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): UpdateMingaStudentIdImageRequest.AsObject {
    return UpdateMingaStudentIdImageRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!UpdateMingaStudentIdImageRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: UpdateMingaStudentIdImageRequest) {
    var f: any;
    var obj: any = {
      assetPath: jspb.Message.getFieldWithDefault(msg, 1, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!UpdateMingaStudentIdImageRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new UpdateMingaStudentIdImageRequest;
    return UpdateMingaStudentIdImageRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!UpdateMingaStudentIdImageRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!UpdateMingaStudentIdImageRequest}
   */
  static deserializeBinaryFromReader(msg: UpdateMingaStudentIdImageRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setAssetPath(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!UpdateMingaStudentIdImageRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: UpdateMingaStudentIdImageRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getAssetPath();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    UpdateMingaStudentIdImageRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string asset_path = 1;
   * @return {string}
   */
  getAssetPath(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setAssetPath(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


}
export namespace UpdateMingaStudentIdImageResponse {
  export interface AsObject {
    studentIdImage?: image_image_pb.ImageInfo.AsObject;
  }
}
export class UpdateMingaStudentIdImageResponse extends jspb.Message {
  static readonly displayName = "UpdateMingaStudentIdImageResponse";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): UpdateMingaStudentIdImageResponse.AsObject {
    return UpdateMingaStudentIdImageResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!UpdateMingaStudentIdImageResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: UpdateMingaStudentIdImageResponse) {
    var f: any;
    var obj: any = {
      studentIdImage: (f = msg.getStudentIdImage()) && image_image_pb.ImageInfo.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!UpdateMingaStudentIdImageResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new UpdateMingaStudentIdImageResponse;
    return UpdateMingaStudentIdImageResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!UpdateMingaStudentIdImageResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!UpdateMingaStudentIdImageResponse}
   */
  static deserializeBinaryFromReader(msg: UpdateMingaStudentIdImageResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new image_image_pb.ImageInfo;
        reader.readMessage(value1,image_image_pb.ImageInfo.deserializeBinaryFromReader);
        msg.setStudentIdImage(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!UpdateMingaStudentIdImageResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: UpdateMingaStudentIdImageResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getStudentIdImage();
    if (f != null) {
      writer.writeMessage(
        1,
        f,
        image_image_pb.ImageInfo.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    UpdateMingaStudentIdImageResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional ImageInfo student_id_image = 1;
   * @return {?ImageInfo}
   */
  getStudentIdImage(): image_image_pb.ImageInfo {
    return /** @type{?ImageInfo} */ (
      jspb.Message.getWrapperField(this, image_image_pb.ImageInfo, 1));
  }


  /** @param {?ImageInfo|undefined} value */
  setStudentIdImage(value?: image_image_pb.ImageInfo) {
    jspb.Message.setWrapperField(this, 1, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearStudentIdImage() {
    this.setStudentIdImage(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasStudentIdImage(): boolean {
    return jspb.Message.getField(this, 1) != null;
  }


}
export namespace RemoveMingaStudentIdImageRequest {
  export interface AsObject {
  }
}
export class RemoveMingaStudentIdImageRequest extends jspb.Message {
  static readonly displayName = "RemoveMingaStudentIdImageRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): RemoveMingaStudentIdImageRequest.AsObject {
    return RemoveMingaStudentIdImageRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!RemoveMingaStudentIdImageRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: RemoveMingaStudentIdImageRequest) {
    var f: any;
    var obj: any = {

    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!RemoveMingaStudentIdImageRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new RemoveMingaStudentIdImageRequest;
    return RemoveMingaStudentIdImageRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!RemoveMingaStudentIdImageRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!RemoveMingaStudentIdImageRequest}
   */
  static deserializeBinaryFromReader(msg: RemoveMingaStudentIdImageRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!RemoveMingaStudentIdImageRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: RemoveMingaStudentIdImageRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    RemoveMingaStudentIdImageRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
}
export namespace RemoveMingaStudentIdImageResponse {
  export interface AsObject {
  }
}
export class RemoveMingaStudentIdImageResponse extends jspb.Message {
  static readonly displayName = "RemoveMingaStudentIdImageResponse";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): RemoveMingaStudentIdImageResponse.AsObject {
    return RemoveMingaStudentIdImageResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!RemoveMingaStudentIdImageResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: RemoveMingaStudentIdImageResponse) {
    var f: any;
    var obj: any = {

    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!RemoveMingaStudentIdImageResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new RemoveMingaStudentIdImageResponse;
    return RemoveMingaStudentIdImageResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!RemoveMingaStudentIdImageResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!RemoveMingaStudentIdImageResponse}
   */
  static deserializeBinaryFromReader(msg: RemoveMingaStudentIdImageResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!RemoveMingaStudentIdImageResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: RemoveMingaStudentIdImageResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    RemoveMingaStudentIdImageResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
}
export namespace RemoveMingaLinkRequest {
  export interface AsObject {
    id: number;
  }
}
export class RemoveMingaLinkRequest extends jspb.Message {
  static readonly displayName = "RemoveMingaLinkRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): RemoveMingaLinkRequest.AsObject {
    return RemoveMingaLinkRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!RemoveMingaLinkRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: RemoveMingaLinkRequest) {
    var f: any;
    var obj: any = {
      id: jspb.Message.getFieldWithDefault(msg, 1, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!RemoveMingaLinkRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new RemoveMingaLinkRequest;
    return RemoveMingaLinkRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!RemoveMingaLinkRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!RemoveMingaLinkRequest}
   */
  static deserializeBinaryFromReader(msg: RemoveMingaLinkRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {number} */ (reader.readInt32());
        msg.setId(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!RemoveMingaLinkRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: RemoveMingaLinkRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getId();
    if (f !== 0) {
      writer.writeInt32(
        1,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    RemoveMingaLinkRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional int32 id = 1;
   * @return {number}
   */
  getId(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
  };


  /** @param {number} value */
  setId(value: number) {
    jspb.Message.setProto3IntField(this, 1, value);
  }


}
export namespace RemoveMingaLinkResponse {
  export interface AsObject {
    status?: common_legacy_pb.StatusCode;
    reason?: common_legacy_pb.StatusReason.AsObject;
  }
}
export class RemoveMingaLinkResponse extends jspb.Message {
  static readonly displayName = "RemoveMingaLinkResponse";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): RemoveMingaLinkResponse.AsObject {
    return RemoveMingaLinkResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!RemoveMingaLinkResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: RemoveMingaLinkResponse) {
    var f: any;
    var obj: any = {
      status: jspb.Message.getFieldWithDefault(msg, 1, 0),
      reason: (f = msg.getReason()) && common_legacy_pb.StatusReason.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!RemoveMingaLinkResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new RemoveMingaLinkResponse;
    return RemoveMingaLinkResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!RemoveMingaLinkResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!RemoveMingaLinkResponse}
   */
  static deserializeBinaryFromReader(msg: RemoveMingaLinkResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {!StatusCode} */ (reader.readEnum());
        msg.setStatus(value1);
        break;
      case 2:
        var value2 = new common_legacy_pb.StatusReason;
        reader.readMessage(value2,common_legacy_pb.StatusReason.deserializeBinaryFromReader);
        msg.setReason(value2);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!RemoveMingaLinkResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: RemoveMingaLinkResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getStatus();
    if (f !== 0.0) {
      writer.writeEnum(
        1,
        f
      );
    }
    f = message.getReason();
    if (f != null) {
      writer.writeMessage(
        2,
        f,
        common_legacy_pb.StatusReason.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    RemoveMingaLinkResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional StatusCode status = 1;
   * @return {!StatusCode}
   */
  getStatus(): common_legacy_pb.StatusCode {
    return /** @type {!StatusCode} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
  };


  /** @param {!StatusCode} value */
  setStatus(value: common_legacy_pb.StatusCode) {
    jspb.Message.setProto3EnumField(this, 1, value);
  }


  /**
   * optional StatusReason reason = 2;
   * @return {?StatusReason}
   */
  getReason(): common_legacy_pb.StatusReason {
    return /** @type{?StatusReason} */ (
      jspb.Message.getWrapperField(this, common_legacy_pb.StatusReason, 2));
  }


  /** @param {?StatusReason|undefined} value */
  setReason(value?: common_legacy_pb.StatusReason) {
    jspb.Message.setWrapperField(this, 2, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearReason() {
    this.setReason(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasReason(): boolean {
    return jspb.Message.getField(this, 2) != null;
  }


}
export namespace UpdateMingaSettingRequest {
  export interface AsObject {
    mingaHash: string;
    settingList: MingaSetting.AsObject[];
  }
}
export class UpdateMingaSettingRequest extends jspb.Message {
  static readonly displayName = "UpdateMingaSettingRequest";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [2];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, UpdateMingaSettingRequest.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): UpdateMingaSettingRequest.AsObject {
    return UpdateMingaSettingRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!UpdateMingaSettingRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: UpdateMingaSettingRequest) {
    var f: any;
    var obj: any = {
      mingaHash: jspb.Message.getFieldWithDefault(msg, 1, ""),
      settingList: jspb.Message.toObjectList(msg.getSettingList(),
      MingaSetting.toObject, includeInstance)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!UpdateMingaSettingRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new UpdateMingaSettingRequest;
    return UpdateMingaSettingRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!UpdateMingaSettingRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!UpdateMingaSettingRequest}
   */
  static deserializeBinaryFromReader(msg: UpdateMingaSettingRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setMingaHash(value1);
        break;
      case 2:
        var value2 = new MingaSetting;
        reader.readMessage(value2,MingaSetting.deserializeBinaryFromReader);
        msg.addSetting(value2);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!UpdateMingaSettingRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: UpdateMingaSettingRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getMingaHash();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
    f = message.getSettingList();
    if (f.length > 0) {
      writer.writeRepeatedMessage(
        2,
        f,
        MingaSetting.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    UpdateMingaSettingRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string minga_hash = 1;
   * @return {string}
   */
  getMingaHash(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setMingaHash(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


  /**
   * repeated MingaSetting setting = 2;
   * @return {!Array<!MingaSetting>}
   */
  getSettingList(): MingaSetting[] {
    return /** @type{!Array<!MingaSetting>} */ (
      jspb.Message.getRepeatedWrapperField(this, MingaSetting, 2));
  }


  /** @param {!Array<!MingaSetting>} value */
  setSettingList(value?: MingaSetting[]) {
    jspb.Message.setRepeatedWrapperField(this, 2, value);
  }


  /**
   * @param {!MingaSetting=} opt_value
   * @param {number=} opt_index
   * @return {!MingaSetting}
   */
  addSetting(opt_value?: MingaSetting, opt_index?: number): MingaSetting {
    return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, MingaSetting, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearSettingList() {
    this.setSettingList([]);
  }


}
export namespace UpdateMingaSettingResponse {
  export interface AsObject {
    settingList: MingaSetting.AsObject[];
  }
}
export class UpdateMingaSettingResponse extends jspb.Message {
  static readonly displayName = "UpdateMingaSettingResponse";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [1];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, UpdateMingaSettingResponse.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): UpdateMingaSettingResponse.AsObject {
    return UpdateMingaSettingResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!UpdateMingaSettingResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: UpdateMingaSettingResponse) {
    var f: any;
    var obj: any = {
      settingList: jspb.Message.toObjectList(msg.getSettingList(),
      MingaSetting.toObject, includeInstance)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!UpdateMingaSettingResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new UpdateMingaSettingResponse;
    return UpdateMingaSettingResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!UpdateMingaSettingResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!UpdateMingaSettingResponse}
   */
  static deserializeBinaryFromReader(msg: UpdateMingaSettingResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new MingaSetting;
        reader.readMessage(value1,MingaSetting.deserializeBinaryFromReader);
        msg.addSetting(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!UpdateMingaSettingResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: UpdateMingaSettingResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getSettingList();
    if (f.length > 0) {
      writer.writeRepeatedMessage(
        1,
        f,
        MingaSetting.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    UpdateMingaSettingResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * repeated MingaSetting setting = 1;
   * @return {!Array<!MingaSetting>}
   */
  getSettingList(): MingaSetting[] {
    return /** @type{!Array<!MingaSetting>} */ (
      jspb.Message.getRepeatedWrapperField(this, MingaSetting, 1));
  }


  /** @param {!Array<!MingaSetting>} value */
  setSettingList(value?: MingaSetting[]) {
    jspb.Message.setRepeatedWrapperField(this, 1, value);
  }


  /**
   * @param {!MingaSetting=} opt_value
   * @param {number=} opt_index
   * @return {!MingaSetting}
   */
  addSetting(opt_value?: MingaSetting, opt_index?: number): MingaSetting {
    return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, MingaSetting, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearSettingList() {
    this.setSettingList([]);
  }


}
export namespace MingaSetting {
  export interface AsObject {
    keyName: string;
    value: string;
  }
}
export class MingaSetting extends jspb.Message {
  static readonly displayName = "MingaSetting";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): MingaSetting.AsObject {
    return MingaSetting.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!MingaSetting} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: MingaSetting) {
    var f: any;
    var obj: any = {
      keyName: jspb.Message.getFieldWithDefault(msg, 1, ""),
      value: jspb.Message.getFieldWithDefault(msg, 2, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!MingaSetting}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new MingaSetting;
    return MingaSetting.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!MingaSetting} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!MingaSetting}
   */
  static deserializeBinaryFromReader(msg: MingaSetting, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setKeyName(value1);
        break;
      case 2:
        var value2 = /** @type {string} */ (reader.readString());
        msg.setValue(value2);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!MingaSetting} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: MingaSetting, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getKeyName();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
    f = message.getValue();
    if (f.length > 0) {
      writer.writeString(
        2,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    MingaSetting.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string key_name = 1;
   * @return {string}
   */
  getKeyName(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setKeyName(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


  /**
   * optional string value = 2;
   * @return {string}
   */
  getValue(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
  };


  /** @param {string} value */
  setValue(value: string) {
    jspb.Message.setProto3StringField(this, 2, value);
  }


}
export namespace GetMingaSettingsRequest {
  export interface AsObject {
    mingaHash: string;
  }
}
export class GetMingaSettingsRequest extends jspb.Message {
  static readonly displayName = "GetMingaSettingsRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): GetMingaSettingsRequest.AsObject {
    return GetMingaSettingsRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!GetMingaSettingsRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: GetMingaSettingsRequest) {
    var f: any;
    var obj: any = {
      mingaHash: jspb.Message.getFieldWithDefault(msg, 1, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!GetMingaSettingsRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new GetMingaSettingsRequest;
    return GetMingaSettingsRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!GetMingaSettingsRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!GetMingaSettingsRequest}
   */
  static deserializeBinaryFromReader(msg: GetMingaSettingsRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setMingaHash(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!GetMingaSettingsRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: GetMingaSettingsRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getMingaHash();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    GetMingaSettingsRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string minga_hash = 1;
   * @return {string}
   */
  getMingaHash(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setMingaHash(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


}
export namespace GetMingaSettingsResponse {
  export interface AsObject {
    settingsList: MingaSetting.AsObject[];
    featureToggle?: MingaFeatureToggle.AsObject;
  }
}
export class GetMingaSettingsResponse extends jspb.Message {
  static readonly displayName = "GetMingaSettingsResponse";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [1];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, GetMingaSettingsResponse.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): GetMingaSettingsResponse.AsObject {
    return GetMingaSettingsResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!GetMingaSettingsResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: GetMingaSettingsResponse) {
    var f: any;
    var obj: any = {
      settingsList: jspb.Message.toObjectList(msg.getSettingsList(),
      MingaSetting.toObject, includeInstance),
      featureToggle: (f = msg.getFeatureToggle()) && MingaFeatureToggle.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!GetMingaSettingsResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new GetMingaSettingsResponse;
    return GetMingaSettingsResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!GetMingaSettingsResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!GetMingaSettingsResponse}
   */
  static deserializeBinaryFromReader(msg: GetMingaSettingsResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new MingaSetting;
        reader.readMessage(value1,MingaSetting.deserializeBinaryFromReader);
        msg.addSettings(value1);
        break;
      case 2:
        var value2 = new MingaFeatureToggle;
        reader.readMessage(value2,MingaFeatureToggle.deserializeBinaryFromReader);
        msg.setFeatureToggle(value2);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!GetMingaSettingsResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: GetMingaSettingsResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getSettingsList();
    if (f.length > 0) {
      writer.writeRepeatedMessage(
        1,
        f,
        MingaSetting.serializeBinaryToWriter
      );
    }
    f = message.getFeatureToggle();
    if (f != null) {
      writer.writeMessage(
        2,
        f,
        MingaFeatureToggle.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    GetMingaSettingsResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * repeated MingaSetting settings = 1;
   * @return {!Array<!MingaSetting>}
   */
  getSettingsList(): MingaSetting[] {
    return /** @type{!Array<!MingaSetting>} */ (
      jspb.Message.getRepeatedWrapperField(this, MingaSetting, 1));
  }


  /** @param {!Array<!MingaSetting>} value */
  setSettingsList(value?: MingaSetting[]) {
    jspb.Message.setRepeatedWrapperField(this, 1, value);
  }


  /**
   * @param {!MingaSetting=} opt_value
   * @param {number=} opt_index
   * @return {!MingaSetting}
   */
  addSettings(opt_value?: MingaSetting, opt_index?: number): MingaSetting {
    return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, MingaSetting, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearSettingsList() {
    this.setSettingsList([]);
  }


  /**
   * optional MingaFeatureToggle feature_toggle = 2;
   * @return {?MingaFeatureToggle}
   */
  getFeatureToggle(): MingaFeatureToggle {
    return /** @type{?MingaFeatureToggle} */ (
      jspb.Message.getWrapperField(this, MingaFeatureToggle, 2));
  }


  /** @param {?MingaFeatureToggle|undefined} value */
  setFeatureToggle(value?: MingaFeatureToggle) {
    jspb.Message.setWrapperField(this, 2, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearFeatureToggle() {
    this.setFeatureToggle(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasFeatureToggle(): boolean {
    return jspb.Message.getField(this, 2) != null;
  }


}
export namespace MingaFeatureToggle {
  export interface AsObject {
    trackingEnabled: boolean;
    hallPassEnabled: boolean;
    studentIdEnabled: boolean;
    communityEnabled: boolean;
    photoGalleryEnabled: boolean;
    directMessagingEnabled: boolean;
    checkinEnabled: boolean;
    flexTimeEnabled: boolean;
  }
}
export class MingaFeatureToggle extends jspb.Message {
  static readonly displayName = "MingaFeatureToggle";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): MingaFeatureToggle.AsObject {
    return MingaFeatureToggle.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!MingaFeatureToggle} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: MingaFeatureToggle) {
    var f: any;
    var obj: any = {
      trackingEnabled: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
      hallPassEnabled: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
      studentIdEnabled: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
      communityEnabled: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
      photoGalleryEnabled: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
      directMessagingEnabled: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
      checkinEnabled: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
      flexTimeEnabled: jspb.Message.getBooleanFieldWithDefault(msg, 9, false)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!MingaFeatureToggle}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new MingaFeatureToggle;
    return MingaFeatureToggle.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!MingaFeatureToggle} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!MingaFeatureToggle}
   */
  static deserializeBinaryFromReader(msg: MingaFeatureToggle, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 2:
        var value2 = /** @type {boolean} */ (reader.readBool());
        msg.setTrackingEnabled(value2);
        break;
      case 3:
        var value3 = /** @type {boolean} */ (reader.readBool());
        msg.setHallPassEnabled(value3);
        break;
      case 4:
        var value4 = /** @type {boolean} */ (reader.readBool());
        msg.setStudentIdEnabled(value4);
        break;
      case 5:
        var value5 = /** @type {boolean} */ (reader.readBool());
        msg.setCommunityEnabled(value5);
        break;
      case 6:
        var value6 = /** @type {boolean} */ (reader.readBool());
        msg.setPhotoGalleryEnabled(value6);
        break;
      case 7:
        var value7 = /** @type {boolean} */ (reader.readBool());
        msg.setDirectMessagingEnabled(value7);
        break;
      case 8:
        var value8 = /** @type {boolean} */ (reader.readBool());
        msg.setCheckinEnabled(value8);
        break;
      case 9:
        var value9 = /** @type {boolean} */ (reader.readBool());
        msg.setFlexTimeEnabled(value9);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!MingaFeatureToggle} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: MingaFeatureToggle, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getTrackingEnabled();
    if (f) {
      writer.writeBool(
        2,
        f
      );
    }
    f = message.getHallPassEnabled();
    if (f) {
      writer.writeBool(
        3,
        f
      );
    }
    f = message.getStudentIdEnabled();
    if (f) {
      writer.writeBool(
        4,
        f
      );
    }
    f = message.getCommunityEnabled();
    if (f) {
      writer.writeBool(
        5,
        f
      );
    }
    f = message.getPhotoGalleryEnabled();
    if (f) {
      writer.writeBool(
        6,
        f
      );
    }
    f = message.getDirectMessagingEnabled();
    if (f) {
      writer.writeBool(
        7,
        f
      );
    }
    f = message.getCheckinEnabled();
    if (f) {
      writer.writeBool(
        8,
        f
      );
    }
    f = message.getFlexTimeEnabled();
    if (f) {
      writer.writeBool(
        9,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    MingaFeatureToggle.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional bool tracking_enabled = 2;
   * @return {boolean}
   */
  getTrackingEnabled(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
  };


  /** @param {boolean} value */
  setTrackingEnabled(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 2, value);
  }


  /**
   * optional bool hall_pass_enabled = 3;
   * @return {boolean}
   */
  getHallPassEnabled(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
  };


  /** @param {boolean} value */
  setHallPassEnabled(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 3, value);
  }


  /**
   * optional bool student_id_enabled = 4;
   * @return {boolean}
   */
  getStudentIdEnabled(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
  };


  /** @param {boolean} value */
  setStudentIdEnabled(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 4, value);
  }


  /**
   * optional bool community_enabled = 5;
   * @return {boolean}
   */
  getCommunityEnabled(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
  };


  /** @param {boolean} value */
  setCommunityEnabled(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 5, value);
  }


  /**
   * optional bool photo_gallery_enabled = 6;
   * @return {boolean}
   */
  getPhotoGalleryEnabled(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
  };


  /** @param {boolean} value */
  setPhotoGalleryEnabled(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 6, value);
  }


  /**
   * optional bool direct_messaging_enabled = 7;
   * @return {boolean}
   */
  getDirectMessagingEnabled(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
  };


  /** @param {boolean} value */
  setDirectMessagingEnabled(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 7, value);
  }


  /**
   * optional bool checkin_enabled = 8;
   * @return {boolean}
   */
  getCheckinEnabled(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
  };


  /** @param {boolean} value */
  setCheckinEnabled(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 8, value);
  }


  /**
   * optional bool flex_time_enabled = 9;
   * @return {boolean}
   */
  getFlexTimeEnabled(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
  };


  /** @param {boolean} value */
  setFlexTimeEnabled(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 9, value);
  }


}
export namespace GetMingaFeatureTogglesRequest {
  export interface AsObject {
    mingaHash: string;
  }
}
export class GetMingaFeatureTogglesRequest extends jspb.Message {
  static readonly displayName = "GetMingaFeatureTogglesRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): GetMingaFeatureTogglesRequest.AsObject {
    return GetMingaFeatureTogglesRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!GetMingaFeatureTogglesRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: GetMingaFeatureTogglesRequest) {
    var f: any;
    var obj: any = {
      mingaHash: jspb.Message.getFieldWithDefault(msg, 1, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!GetMingaFeatureTogglesRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new GetMingaFeatureTogglesRequest;
    return GetMingaFeatureTogglesRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!GetMingaFeatureTogglesRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!GetMingaFeatureTogglesRequest}
   */
  static deserializeBinaryFromReader(msg: GetMingaFeatureTogglesRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setMingaHash(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!GetMingaFeatureTogglesRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: GetMingaFeatureTogglesRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getMingaHash();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    GetMingaFeatureTogglesRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string minga_hash = 1;
   * @return {string}
   */
  getMingaHash(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setMingaHash(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


}
export namespace GetMingaFeatureTogglesResponse {
  export interface AsObject {
    featureToggle?: MingaFeatureToggle.AsObject;
  }
}
export class GetMingaFeatureTogglesResponse extends jspb.Message {
  static readonly displayName = "GetMingaFeatureTogglesResponse";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): GetMingaFeatureTogglesResponse.AsObject {
    return GetMingaFeatureTogglesResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!GetMingaFeatureTogglesResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: GetMingaFeatureTogglesResponse) {
    var f: any;
    var obj: any = {
      featureToggle: (f = msg.getFeatureToggle()) && MingaFeatureToggle.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!GetMingaFeatureTogglesResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new GetMingaFeatureTogglesResponse;
    return GetMingaFeatureTogglesResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!GetMingaFeatureTogglesResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!GetMingaFeatureTogglesResponse}
   */
  static deserializeBinaryFromReader(msg: GetMingaFeatureTogglesResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new MingaFeatureToggle;
        reader.readMessage(value1,MingaFeatureToggle.deserializeBinaryFromReader);
        msg.setFeatureToggle(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!GetMingaFeatureTogglesResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: GetMingaFeatureTogglesResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getFeatureToggle();
    if (f != null) {
      writer.writeMessage(
        1,
        f,
        MingaFeatureToggle.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    GetMingaFeatureTogglesResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional MingaFeatureToggle feature_toggle = 1;
   * @return {?MingaFeatureToggle}
   */
  getFeatureToggle(): MingaFeatureToggle {
    return /** @type{?MingaFeatureToggle} */ (
      jspb.Message.getWrapperField(this, MingaFeatureToggle, 1));
  }


  /** @param {?MingaFeatureToggle|undefined} value */
  setFeatureToggle(value?: MingaFeatureToggle) {
    jspb.Message.setWrapperField(this, 1, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearFeatureToggle() {
    this.setFeatureToggle(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasFeatureToggle(): boolean {
    return jspb.Message.getField(this, 1) != null;
  }


}
export namespace UpdateMingaFeatureTogglesRequest {
  export interface AsObject {
    mingaHash: string;
    featureToggle?: MingaFeatureToggle.AsObject;
  }
}
export class UpdateMingaFeatureTogglesRequest extends jspb.Message {
  static readonly displayName = "UpdateMingaFeatureTogglesRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): UpdateMingaFeatureTogglesRequest.AsObject {
    return UpdateMingaFeatureTogglesRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!UpdateMingaFeatureTogglesRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: UpdateMingaFeatureTogglesRequest) {
    var f: any;
    var obj: any = {
      mingaHash: jspb.Message.getFieldWithDefault(msg, 1, ""),
      featureToggle: (f = msg.getFeatureToggle()) && MingaFeatureToggle.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!UpdateMingaFeatureTogglesRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new UpdateMingaFeatureTogglesRequest;
    return UpdateMingaFeatureTogglesRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!UpdateMingaFeatureTogglesRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!UpdateMingaFeatureTogglesRequest}
   */
  static deserializeBinaryFromReader(msg: UpdateMingaFeatureTogglesRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setMingaHash(value1);
        break;
      case 2:
        var value2 = new MingaFeatureToggle;
        reader.readMessage(value2,MingaFeatureToggle.deserializeBinaryFromReader);
        msg.setFeatureToggle(value2);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!UpdateMingaFeatureTogglesRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: UpdateMingaFeatureTogglesRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getMingaHash();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
    f = message.getFeatureToggle();
    if (f != null) {
      writer.writeMessage(
        2,
        f,
        MingaFeatureToggle.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    UpdateMingaFeatureTogglesRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string minga_hash = 1;
   * @return {string}
   */
  getMingaHash(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setMingaHash(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


  /**
   * optional MingaFeatureToggle feature_toggle = 2;
   * @return {?MingaFeatureToggle}
   */
  getFeatureToggle(): MingaFeatureToggle {
    return /** @type{?MingaFeatureToggle} */ (
      jspb.Message.getWrapperField(this, MingaFeatureToggle, 2));
  }


  /** @param {?MingaFeatureToggle|undefined} value */
  setFeatureToggle(value?: MingaFeatureToggle) {
    jspb.Message.setWrapperField(this, 2, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearFeatureToggle() {
    this.setFeatureToggle(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasFeatureToggle(): boolean {
    return jspb.Message.getField(this, 2) != null;
  }


}
export namespace UpdateMingaFeatureTogglesResponse {
  export interface AsObject {
    featureToggle?: MingaFeatureToggle.AsObject;
  }
}
export class UpdateMingaFeatureTogglesResponse extends jspb.Message {
  static readonly displayName = "UpdateMingaFeatureTogglesResponse";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): UpdateMingaFeatureTogglesResponse.AsObject {
    return UpdateMingaFeatureTogglesResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!UpdateMingaFeatureTogglesResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: UpdateMingaFeatureTogglesResponse) {
    var f: any;
    var obj: any = {
      featureToggle: (f = msg.getFeatureToggle()) && MingaFeatureToggle.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!UpdateMingaFeatureTogglesResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new UpdateMingaFeatureTogglesResponse;
    return UpdateMingaFeatureTogglesResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!UpdateMingaFeatureTogglesResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!UpdateMingaFeatureTogglesResponse}
   */
  static deserializeBinaryFromReader(msg: UpdateMingaFeatureTogglesResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new MingaFeatureToggle;
        reader.readMessage(value1,MingaFeatureToggle.deserializeBinaryFromReader);
        msg.setFeatureToggle(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!UpdateMingaFeatureTogglesResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: UpdateMingaFeatureTogglesResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getFeatureToggle();
    if (f != null) {
      writer.writeMessage(
        1,
        f,
        MingaFeatureToggle.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    UpdateMingaFeatureTogglesResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional MingaFeatureToggle feature_toggle = 1;
   * @return {?MingaFeatureToggle}
   */
  getFeatureToggle(): MingaFeatureToggle {
    return /** @type{?MingaFeatureToggle} */ (
      jspb.Message.getWrapperField(this, MingaFeatureToggle, 1));
  }


  /** @param {?MingaFeatureToggle|undefined} value */
  setFeatureToggle(value?: MingaFeatureToggle) {
    jspb.Message.setWrapperField(this, 1, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearFeatureToggle() {
    this.setFeatureToggle(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasFeatureToggle(): boolean {
    return jspb.Message.getField(this, 1) != null;
  }


}
export namespace UpdateUserMingaFeatureTogglesRequest {
  export interface AsObject {
    featureToggle?: MingaFeatureToggle.AsObject;
  }
}
export class UpdateUserMingaFeatureTogglesRequest extends jspb.Message {
  static readonly displayName = "UpdateUserMingaFeatureTogglesRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): UpdateUserMingaFeatureTogglesRequest.AsObject {
    return UpdateUserMingaFeatureTogglesRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!UpdateUserMingaFeatureTogglesRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: UpdateUserMingaFeatureTogglesRequest) {
    var f: any;
    var obj: any = {
      featureToggle: (f = msg.getFeatureToggle()) && MingaFeatureToggle.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!UpdateUserMingaFeatureTogglesRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new UpdateUserMingaFeatureTogglesRequest;
    return UpdateUserMingaFeatureTogglesRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!UpdateUserMingaFeatureTogglesRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!UpdateUserMingaFeatureTogglesRequest}
   */
  static deserializeBinaryFromReader(msg: UpdateUserMingaFeatureTogglesRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new MingaFeatureToggle;
        reader.readMessage(value1,MingaFeatureToggle.deserializeBinaryFromReader);
        msg.setFeatureToggle(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!UpdateUserMingaFeatureTogglesRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: UpdateUserMingaFeatureTogglesRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getFeatureToggle();
    if (f != null) {
      writer.writeMessage(
        1,
        f,
        MingaFeatureToggle.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    UpdateUserMingaFeatureTogglesRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional MingaFeatureToggle feature_toggle = 1;
   * @return {?MingaFeatureToggle}
   */
  getFeatureToggle(): MingaFeatureToggle {
    return /** @type{?MingaFeatureToggle} */ (
      jspb.Message.getWrapperField(this, MingaFeatureToggle, 1));
  }


  /** @param {?MingaFeatureToggle|undefined} value */
  setFeatureToggle(value?: MingaFeatureToggle) {
    jspb.Message.setWrapperField(this, 1, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearFeatureToggle() {
    this.setFeatureToggle(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasFeatureToggle(): boolean {
    return jspb.Message.getField(this, 1) != null;
  }


}
export namespace UpdateUserMingaFeatureTogglesResponse {
  export interface AsObject {
    featureToggle?: MingaFeatureToggle.AsObject;
  }
}
export class UpdateUserMingaFeatureTogglesResponse extends jspb.Message {
  static readonly displayName = "UpdateUserMingaFeatureTogglesResponse";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): UpdateUserMingaFeatureTogglesResponse.AsObject {
    return UpdateUserMingaFeatureTogglesResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!UpdateUserMingaFeatureTogglesResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: UpdateUserMingaFeatureTogglesResponse) {
    var f: any;
    var obj: any = {
      featureToggle: (f = msg.getFeatureToggle()) && MingaFeatureToggle.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!UpdateUserMingaFeatureTogglesResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new UpdateUserMingaFeatureTogglesResponse;
    return UpdateUserMingaFeatureTogglesResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!UpdateUserMingaFeatureTogglesResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!UpdateUserMingaFeatureTogglesResponse}
   */
  static deserializeBinaryFromReader(msg: UpdateUserMingaFeatureTogglesResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new MingaFeatureToggle;
        reader.readMessage(value1,MingaFeatureToggle.deserializeBinaryFromReader);
        msg.setFeatureToggle(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!UpdateUserMingaFeatureTogglesResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: UpdateUserMingaFeatureTogglesResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getFeatureToggle();
    if (f != null) {
      writer.writeMessage(
        1,
        f,
        MingaFeatureToggle.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    UpdateUserMingaFeatureTogglesResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional MingaFeatureToggle feature_toggle = 1;
   * @return {?MingaFeatureToggle}
   */
  getFeatureToggle(): MingaFeatureToggle {
    return /** @type{?MingaFeatureToggle} */ (
      jspb.Message.getWrapperField(this, MingaFeatureToggle, 1));
  }


  /** @param {?MingaFeatureToggle|undefined} value */
  setFeatureToggle(value?: MingaFeatureToggle) {
    jspb.Message.setWrapperField(this, 1, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearFeatureToggle() {
    this.setFeatureToggle(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasFeatureToggle(): boolean {
    return jspb.Message.getField(this, 1) != null;
  }


}
export namespace MingaDashboardInfo {
  export interface AsObject {
    hash: string;
    name: string;
    address: string;
    imageUrl: string;
    memberCount: number;
    dailyActivePercent: number;
    weeklyActivePercent: number;
    dailyActivePercentDiff: number;
    weeklyActivePercentDiff: number;
    dailyActiveCount: number;
    weeklyActiveCount: number;
    paused: boolean;
    mingaType: string;
    mingaStatus: string;
    mingaSize: number;
    billableCount: number;
    totalCount: number;
    joinCode: string;
    subscriptionPlanId: string;
    subscriptionName: string;
    subscriptionEndDate?: common_date_pb.Date.AsObject;
    subscriptionFee: string;
    subscriptionPortalUrl: string;
    customJoinCode: boolean;
    defaultRoleType: string;
    createdAt?: common_date_pb.Date.AsObject;
    studentIdImage?: image_image_pb.ImageInfo.AsObject;
    subscriptionStartDate?: common_date_pb.Date.AsObject;
  }
}
export class MingaDashboardInfo extends jspb.Message {
  static readonly displayName = "MingaDashboardInfo";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): MingaDashboardInfo.AsObject {
    return MingaDashboardInfo.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!MingaDashboardInfo} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: MingaDashboardInfo) {
    var f: any;
    var obj: any = {
      hash: jspb.Message.getFieldWithDefault(msg, 1, ""),
      name: jspb.Message.getFieldWithDefault(msg, 2, ""),
      address: jspb.Message.getFieldWithDefault(msg, 3, ""),
      imageUrl: jspb.Message.getFieldWithDefault(msg, 4, ""),
      memberCount: jspb.Message.getFieldWithDefault(msg, 5, 0),
      dailyActivePercent: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
      weeklyActivePercent: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
      dailyActivePercentDiff: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0),
      weeklyActivePercentDiff: jspb.Message.getFloatingPointFieldWithDefault(msg, 9, 0.0),
      dailyActiveCount: jspb.Message.getFieldWithDefault(msg, 10, 0),
      weeklyActiveCount: jspb.Message.getFieldWithDefault(msg, 11, 0),
      paused: jspb.Message.getBooleanFieldWithDefault(msg, 12, false),
      mingaType: jspb.Message.getFieldWithDefault(msg, 13, ""),
      mingaStatus: jspb.Message.getFieldWithDefault(msg, 14, ""),
      mingaSize: jspb.Message.getFieldWithDefault(msg, 15, 0),
      billableCount: jspb.Message.getFieldWithDefault(msg, 16, 0),
      totalCount: jspb.Message.getFieldWithDefault(msg, 17, 0),
      joinCode: jspb.Message.getFieldWithDefault(msg, 18, ""),
      subscriptionPlanId: jspb.Message.getFieldWithDefault(msg, 19, ""),
      subscriptionName: jspb.Message.getFieldWithDefault(msg, 20, ""),
      subscriptionEndDate: (f = msg.getSubscriptionEndDate()) && common_date_pb.Date.toObject(includeInstance, f),
      subscriptionFee: jspb.Message.getFieldWithDefault(msg, 22, ""),
      subscriptionPortalUrl: jspb.Message.getFieldWithDefault(msg, 23, ""),
      customJoinCode: jspb.Message.getBooleanFieldWithDefault(msg, 24, false),
      defaultRoleType: jspb.Message.getFieldWithDefault(msg, 25, ""),
      createdAt: (f = msg.getCreatedAt()) && common_date_pb.Date.toObject(includeInstance, f),
      studentIdImage: (f = msg.getStudentIdImage()) && image_image_pb.ImageInfo.toObject(includeInstance, f),
      subscriptionStartDate: (f = msg.getSubscriptionStartDate()) && common_date_pb.Date.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!MingaDashboardInfo}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new MingaDashboardInfo;
    return MingaDashboardInfo.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!MingaDashboardInfo} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!MingaDashboardInfo}
   */
  static deserializeBinaryFromReader(msg: MingaDashboardInfo, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setHash(value1);
        break;
      case 2:
        var value2 = /** @type {string} */ (reader.readString());
        msg.setName(value2);
        break;
      case 3:
        var value3 = /** @type {string} */ (reader.readString());
        msg.setAddress(value3);
        break;
      case 4:
        var value4 = /** @type {string} */ (reader.readString());
        msg.setImageUrl(value4);
        break;
      case 5:
        var value5 = /** @type {number} */ (reader.readInt32());
        msg.setMemberCount(value5);
        break;
      case 6:
        var value6 = /** @type {number} */ (reader.readFloat());
        msg.setDailyActivePercent(value6);
        break;
      case 7:
        var value7 = /** @type {number} */ (reader.readFloat());
        msg.setWeeklyActivePercent(value7);
        break;
      case 8:
        var value8 = /** @type {number} */ (reader.readFloat());
        msg.setDailyActivePercentDiff(value8);
        break;
      case 9:
        var value9 = /** @type {number} */ (reader.readFloat());
        msg.setWeeklyActivePercentDiff(value9);
        break;
      case 10:
        var value10 = /** @type {number} */ (reader.readInt32());
        msg.setDailyActiveCount(value10);
        break;
      case 11:
        var value11 = /** @type {number} */ (reader.readInt32());
        msg.setWeeklyActiveCount(value11);
        break;
      case 12:
        var value12 = /** @type {boolean} */ (reader.readBool());
        msg.setPaused(value12);
        break;
      case 13:
        var value13 = /** @type {string} */ (reader.readString());
        msg.setMingaType(value13);
        break;
      case 14:
        var value14 = /** @type {string} */ (reader.readString());
        msg.setMingaStatus(value14);
        break;
      case 15:
        var value15 = /** @type {number} */ (reader.readInt32());
        msg.setMingaSize(value15);
        break;
      case 16:
        var value16 = /** @type {number} */ (reader.readInt32());
        msg.setBillableCount(value16);
        break;
      case 17:
        var value17 = /** @type {number} */ (reader.readInt32());
        msg.setTotalCount(value17);
        break;
      case 18:
        var value18 = /** @type {string} */ (reader.readString());
        msg.setJoinCode(value18);
        break;
      case 19:
        var value19 = /** @type {string} */ (reader.readString());
        msg.setSubscriptionPlanId(value19);
        break;
      case 20:
        var value20 = /** @type {string} */ (reader.readString());
        msg.setSubscriptionName(value20);
        break;
      case 21:
        var value21 = new common_date_pb.Date;
        reader.readMessage(value21,common_date_pb.Date.deserializeBinaryFromReader);
        msg.setSubscriptionEndDate(value21);
        break;
      case 22:
        var value22 = /** @type {string} */ (reader.readString());
        msg.setSubscriptionFee(value22);
        break;
      case 23:
        var value23 = /** @type {string} */ (reader.readString());
        msg.setSubscriptionPortalUrl(value23);
        break;
      case 24:
        var value24 = /** @type {boolean} */ (reader.readBool());
        msg.setCustomJoinCode(value24);
        break;
      case 25:
        var value25 = /** @type {string} */ (reader.readString());
        msg.setDefaultRoleType(value25);
        break;
      case 26:
        var value26 = new common_date_pb.Date;
        reader.readMessage(value26,common_date_pb.Date.deserializeBinaryFromReader);
        msg.setCreatedAt(value26);
        break;
      case 27:
        var value27 = new image_image_pb.ImageInfo;
        reader.readMessage(value27,image_image_pb.ImageInfo.deserializeBinaryFromReader);
        msg.setStudentIdImage(value27);
        break;
      case 28:
        var value28 = new common_date_pb.Date;
        reader.readMessage(value28,common_date_pb.Date.deserializeBinaryFromReader);
        msg.setSubscriptionStartDate(value28);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!MingaDashboardInfo} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: MingaDashboardInfo, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getHash();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
    f = message.getName();
    if (f.length > 0) {
      writer.writeString(
        2,
        f
      );
    }
    f = message.getAddress();
    if (f.length > 0) {
      writer.writeString(
        3,
        f
      );
    }
    f = message.getImageUrl();
    if (f.length > 0) {
      writer.writeString(
        4,
        f
      );
    }
    f = message.getMemberCount();
    if (f !== 0) {
      writer.writeInt32(
        5,
        f
      );
    }
    f = message.getDailyActivePercent();
    if (f !== 0.0) {
      writer.writeFloat(
        6,
        f
      );
    }
    f = message.getWeeklyActivePercent();
    if (f !== 0.0) {
      writer.writeFloat(
        7,
        f
      );
    }
    f = message.getDailyActivePercentDiff();
    if (f !== 0.0) {
      writer.writeFloat(
        8,
        f
      );
    }
    f = message.getWeeklyActivePercentDiff();
    if (f !== 0.0) {
      writer.writeFloat(
        9,
        f
      );
    }
    f = message.getDailyActiveCount();
    if (f !== 0) {
      writer.writeInt32(
        10,
        f
      );
    }
    f = message.getWeeklyActiveCount();
    if (f !== 0) {
      writer.writeInt32(
        11,
        f
      );
    }
    f = message.getPaused();
    if (f) {
      writer.writeBool(
        12,
        f
      );
    }
    f = message.getMingaType();
    if (f.length > 0) {
      writer.writeString(
        13,
        f
      );
    }
    f = message.getMingaStatus();
    if (f.length > 0) {
      writer.writeString(
        14,
        f
      );
    }
    f = message.getMingaSize();
    if (f !== 0) {
      writer.writeInt32(
        15,
        f
      );
    }
    f = message.getBillableCount();
    if (f !== 0) {
      writer.writeInt32(
        16,
        f
      );
    }
    f = message.getTotalCount();
    if (f !== 0) {
      writer.writeInt32(
        17,
        f
      );
    }
    f = message.getJoinCode();
    if (f.length > 0) {
      writer.writeString(
        18,
        f
      );
    }
    f = message.getSubscriptionPlanId();
    if (f.length > 0) {
      writer.writeString(
        19,
        f
      );
    }
    f = message.getSubscriptionName();
    if (f.length > 0) {
      writer.writeString(
        20,
        f
      );
    }
    f = message.getSubscriptionEndDate();
    if (f != null) {
      writer.writeMessage(
        21,
        f,
        common_date_pb.Date.serializeBinaryToWriter
      );
    }
    f = message.getSubscriptionFee();
    if (f.length > 0) {
      writer.writeString(
        22,
        f
      );
    }
    f = message.getSubscriptionPortalUrl();
    if (f.length > 0) {
      writer.writeString(
        23,
        f
      );
    }
    f = message.getCustomJoinCode();
    if (f) {
      writer.writeBool(
        24,
        f
      );
    }
    f = message.getDefaultRoleType();
    if (f.length > 0) {
      writer.writeString(
        25,
        f
      );
    }
    f = message.getCreatedAt();
    if (f != null) {
      writer.writeMessage(
        26,
        f,
        common_date_pb.Date.serializeBinaryToWriter
      );
    }
    f = message.getStudentIdImage();
    if (f != null) {
      writer.writeMessage(
        27,
        f,
        image_image_pb.ImageInfo.serializeBinaryToWriter
      );
    }
    f = message.getSubscriptionStartDate();
    if (f != null) {
      writer.writeMessage(
        28,
        f,
        common_date_pb.Date.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    MingaDashboardInfo.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string hash = 1;
   * @return {string}
   */
  getHash(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setHash(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


  /**
   * optional string name = 2;
   * @return {string}
   */
  getName(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
  };


  /** @param {string} value */
  setName(value: string) {
    jspb.Message.setProto3StringField(this, 2, value);
  }


  /**
   * optional string address = 3;
   * @return {string}
   */
  getAddress(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
  };


  /** @param {string} value */
  setAddress(value: string) {
    jspb.Message.setProto3StringField(this, 3, value);
  }


  /**
   * optional string image_url = 4;
   * @return {string}
   */
  getImageUrl(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
  };


  /** @param {string} value */
  setImageUrl(value: string) {
    jspb.Message.setProto3StringField(this, 4, value);
  }


  /**
   * optional int32 member_count = 5;
   * @return {number}
   */
  getMemberCount(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
  };


  /** @param {number} value */
  setMemberCount(value: number) {
    jspb.Message.setProto3IntField(this, 5, value);
  }


  /**
   * optional float daily_active_percent = 6;
   * @return {number}
   */
  getDailyActivePercent(): number {
    return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
  };


  /** @param {number} value */
  setDailyActivePercent(value: number) {
    jspb.Message.setProto3FloatField(this, 6, value);
  }


  /**
   * optional float weekly_active_percent = 7;
   * @return {number}
   */
  getWeeklyActivePercent(): number {
    return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
  };


  /** @param {number} value */
  setWeeklyActivePercent(value: number) {
    jspb.Message.setProto3FloatField(this, 7, value);
  }


  /**
   * optional float daily_active_percent_diff = 8;
   * @return {number}
   */
  getDailyActivePercentDiff(): number {
    return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
  };


  /** @param {number} value */
  setDailyActivePercentDiff(value: number) {
    jspb.Message.setProto3FloatField(this, 8, value);
  }


  /**
   * optional float weekly_active_percent_diff = 9;
   * @return {number}
   */
  getWeeklyActivePercentDiff(): number {
    return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 9, 0.0));
  };


  /** @param {number} value */
  setWeeklyActivePercentDiff(value: number) {
    jspb.Message.setProto3FloatField(this, 9, value);
  }


  /**
   * optional int32 daily_active_count = 10;
   * @return {number}
   */
  getDailyActiveCount(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
  };


  /** @param {number} value */
  setDailyActiveCount(value: number) {
    jspb.Message.setProto3IntField(this, 10, value);
  }


  /**
   * optional int32 weekly_active_count = 11;
   * @return {number}
   */
  getWeeklyActiveCount(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
  };


  /** @param {number} value */
  setWeeklyActiveCount(value: number) {
    jspb.Message.setProto3IntField(this, 11, value);
  }


  /**
   * optional bool paused = 12;
   * @return {boolean}
   */
  getPaused(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 12, false));
  };


  /** @param {boolean} value */
  setPaused(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 12, value);
  }


  /**
   * optional string minga_type = 13;
   * @return {string}
   */
  getMingaType(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
  };


  /** @param {string} value */
  setMingaType(value: string) {
    jspb.Message.setProto3StringField(this, 13, value);
  }


  /**
   * optional string minga_status = 14;
   * @return {string}
   */
  getMingaStatus(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
  };


  /** @param {string} value */
  setMingaStatus(value: string) {
    jspb.Message.setProto3StringField(this, 14, value);
  }


  /**
   * optional int32 minga_size = 15;
   * @return {number}
   */
  getMingaSize(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
  };


  /** @param {number} value */
  setMingaSize(value: number) {
    jspb.Message.setProto3IntField(this, 15, value);
  }


  /**
   * optional int32 billable_count = 16;
   * @return {number}
   */
  getBillableCount(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
  };


  /** @param {number} value */
  setBillableCount(value: number) {
    jspb.Message.setProto3IntField(this, 16, value);
  }


  /**
   * optional int32 total_count = 17;
   * @return {number}
   */
  getTotalCount(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 17, 0));
  };


  /** @param {number} value */
  setTotalCount(value: number) {
    jspb.Message.setProto3IntField(this, 17, value);
  }


  /**
   * optional string join_code = 18;
   * @return {string}
   */
  getJoinCode(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
  };


  /** @param {string} value */
  setJoinCode(value: string) {
    jspb.Message.setProto3StringField(this, 18, value);
  }


  /**
   * optional string subscription_plan_id = 19;
   * @return {string}
   */
  getSubscriptionPlanId(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
  };


  /** @param {string} value */
  setSubscriptionPlanId(value: string) {
    jspb.Message.setProto3StringField(this, 19, value);
  }


  /**
   * optional string subscription_name = 20;
   * @return {string}
   */
  getSubscriptionName(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
  };


  /** @param {string} value */
  setSubscriptionName(value: string) {
    jspb.Message.setProto3StringField(this, 20, value);
  }


  /**
   * optional minga.common.Date subscription_end_date = 21;
   * @return {?Date}
   */
  getSubscriptionEndDate(): common_date_pb.Date {
    return /** @type{?Date} */ (
      jspb.Message.getWrapperField(this, common_date_pb.Date, 21));
  }


  /** @param {?Date|undefined} value */
  setSubscriptionEndDate(value?: common_date_pb.Date) {
    jspb.Message.setWrapperField(this, 21, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearSubscriptionEndDate() {
    this.setSubscriptionEndDate(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasSubscriptionEndDate(): boolean {
    return jspb.Message.getField(this, 21) != null;
  }


  /**
   * optional string subscription_fee = 22;
   * @return {string}
   */
  getSubscriptionFee(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 22, ""));
  };


  /** @param {string} value */
  setSubscriptionFee(value: string) {
    jspb.Message.setProto3StringField(this, 22, value);
  }


  /**
   * optional string subscription_portal_url = 23;
   * @return {string}
   */
  getSubscriptionPortalUrl(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 23, ""));
  };


  /** @param {string} value */
  setSubscriptionPortalUrl(value: string) {
    jspb.Message.setProto3StringField(this, 23, value);
  }


  /**
   * optional bool custom_join_code = 24;
   * @return {boolean}
   */
  getCustomJoinCode(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 24, false));
  };


  /** @param {boolean} value */
  setCustomJoinCode(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 24, value);
  }


  /**
   * optional string default_role_type = 25;
   * @return {string}
   */
  getDefaultRoleType(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 25, ""));
  };


  /** @param {string} value */
  setDefaultRoleType(value: string) {
    jspb.Message.setProto3StringField(this, 25, value);
  }


  /**
   * optional minga.common.Date created_at = 26;
   * @return {?Date}
   */
  getCreatedAt(): common_date_pb.Date {
    return /** @type{?Date} */ (
      jspb.Message.getWrapperField(this, common_date_pb.Date, 26));
  }


  /** @param {?Date|undefined} value */
  setCreatedAt(value?: common_date_pb.Date) {
    jspb.Message.setWrapperField(this, 26, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearCreatedAt() {
    this.setCreatedAt(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasCreatedAt(): boolean {
    return jspb.Message.getField(this, 26) != null;
  }


  /**
   * optional ImageInfo student_id_image = 27;
   * @return {?ImageInfo}
   */
  getStudentIdImage(): image_image_pb.ImageInfo {
    return /** @type{?ImageInfo} */ (
      jspb.Message.getWrapperField(this, image_image_pb.ImageInfo, 27));
  }


  /** @param {?ImageInfo|undefined} value */
  setStudentIdImage(value?: image_image_pb.ImageInfo) {
    jspb.Message.setWrapperField(this, 27, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearStudentIdImage() {
    this.setStudentIdImage(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasStudentIdImage(): boolean {
    return jspb.Message.getField(this, 27) != null;
  }


  /**
   * optional minga.common.Date subscription_start_date = 28;
   * @return {?Date}
   */
  getSubscriptionStartDate(): common_date_pb.Date {
    return /** @type{?Date} */ (
      jspb.Message.getWrapperField(this, common_date_pb.Date, 28));
  }


  /** @param {?Date|undefined} value */
  setSubscriptionStartDate(value?: common_date_pb.Date) {
    jspb.Message.setWrapperField(this, 28, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearSubscriptionStartDate() {
    this.setSubscriptionStartDate(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasSubscriptionStartDate(): boolean {
    return jspb.Message.getField(this, 28) != null;
  }


}
export namespace MingaDashboardInfo2 {
  export interface AsObject {
    hash: string;
    name: string;
    address: string;
    city: string;
    state: string;
    partner: string;
    district: string;
    imageUrl: string;
    memberCount: number;
    type?: MingaType;
    status?: MingaStatus;
    paused: boolean;
    size: number;
    registeredCount: number;
    billableCount: number;
    totalCount: number;
    subscription?: MingaSubscription2.AsObject;
    createdAt?: common_date_pb.Date.AsObject;
    stickerCount: number;
    groupCount: number;
    checkinCount: number;
    dailyActiveCount: number;
    weeklyActiveCount: number;
    monthlyActiveCount: number;
    hallPassCount: number;
    postCount: number;
    behaviorCount: number;
    featureToggle?: MingaFeatureToggle.AsObject;
    registeredStudents: number;
    unregisteredStudents: number;
    sisType: string;
    hubSpotId: string;
    rostering: string;
    subscriptionEndDate?: common_date_pb.Date.AsObject;
    healthScoreStatsMap: [string, number][];
    smsCount: number;
    sisActive: boolean;
    healthScoreStatsEntriesList: MingaHealthScoreMapEntry.AsObject[];
    subscriptionStartDate?: common_date_pb.Date.AsObject;
    sisListCount: number;
    sisListActive: boolean;
    districtId: string;
    smsRemaining?: google_protobuf_wrappers_pb.Int32Value.AsObject;
    mingaSupportTier: string;
    bellScheduleEnabled: boolean;
    bellScheduleStaffAndStudentsEnabled: boolean;
  }
}
export class MingaDashboardInfo2 extends jspb.Message {
  static readonly displayName = "MingaDashboardInfo2";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [38];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, MingaDashboardInfo2.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): MingaDashboardInfo2.AsObject {
    return MingaDashboardInfo2.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!MingaDashboardInfo2} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: MingaDashboardInfo2) {
    var f: any;
    var obj: any = {
      hash: jspb.Message.getFieldWithDefault(msg, 1, ""),
      name: jspb.Message.getFieldWithDefault(msg, 2, ""),
      address: jspb.Message.getFieldWithDefault(msg, 3, ""),
      city: jspb.Message.getFieldWithDefault(msg, 4, ""),
      state: jspb.Message.getFieldWithDefault(msg, 5, ""),
      partner: jspb.Message.getFieldWithDefault(msg, 6, ""),
      district: jspb.Message.getFieldWithDefault(msg, 7, ""),
      imageUrl: jspb.Message.getFieldWithDefault(msg, 8, ""),
      memberCount: jspb.Message.getFieldWithDefault(msg, 9, 0),
      type: jspb.Message.getFieldWithDefault(msg, 10, 0),
      status: jspb.Message.getFieldWithDefault(msg, 11, 0),
      paused: jspb.Message.getBooleanFieldWithDefault(msg, 12, false),
      size: jspb.Message.getFieldWithDefault(msg, 13, 0),
      registeredCount: jspb.Message.getFieldWithDefault(msg, 14, 0),
      billableCount: jspb.Message.getFieldWithDefault(msg, 15, 0),
      totalCount: jspb.Message.getFieldWithDefault(msg, 16, 0),
      subscription: (f = msg.getSubscription()) && MingaSubscription2.toObject(includeInstance, f),
      createdAt: (f = msg.getCreatedAt()) && common_date_pb.Date.toObject(includeInstance, f),
      stickerCount: jspb.Message.getFieldWithDefault(msg, 19, 0),
      groupCount: jspb.Message.getFieldWithDefault(msg, 20, 0),
      checkinCount: jspb.Message.getFieldWithDefault(msg, 26, 0),
      dailyActiveCount: jspb.Message.getFieldWithDefault(msg, 21, 0),
      weeklyActiveCount: jspb.Message.getFieldWithDefault(msg, 22, 0),
      monthlyActiveCount: jspb.Message.getFieldWithDefault(msg, 23, 0),
      hallPassCount: jspb.Message.getFieldWithDefault(msg, 24, 0),
      postCount: jspb.Message.getFieldWithDefault(msg, 25, 0),
      behaviorCount: jspb.Message.getFieldWithDefault(msg, 27, 0),
      featureToggle: (f = msg.getFeatureToggle()) && MingaFeatureToggle.toObject(includeInstance, f),
      registeredStudents: jspb.Message.getFieldWithDefault(msg, 29, 0),
      unregisteredStudents: jspb.Message.getFieldWithDefault(msg, 30, 0),
      sisType: jspb.Message.getFieldWithDefault(msg, 31, ""),
      hubSpotId: jspb.Message.getFieldWithDefault(msg, 32, ""),
      rostering: jspb.Message.getFieldWithDefault(msg, 33, ""),
      subscriptionEndDate: (f = msg.getSubscriptionEndDate()) && common_date_pb.Date.toObject(includeInstance, f),
      healthScoreStatsMap: (f = msg.getHealthScoreStatsMap()) ? f.toObject(includeInstance, undefined) : [],
      smsCount: jspb.Message.getFieldWithDefault(msg, 36, 0),
      sisActive: jspb.Message.getBooleanFieldWithDefault(msg, 37, false),
      healthScoreStatsEntriesList: jspb.Message.toObjectList(msg.getHealthScoreStatsEntriesList(),
      MingaHealthScoreMapEntry.toObject, includeInstance),
      subscriptionStartDate: (f = msg.getSubscriptionStartDate()) && common_date_pb.Date.toObject(includeInstance, f),
      sisListCount: jspb.Message.getFieldWithDefault(msg, 41, 0),
      sisListActive: jspb.Message.getBooleanFieldWithDefault(msg, 42, false),
      districtId: jspb.Message.getFieldWithDefault(msg, 43, ""),
      smsRemaining: (f = msg.getSmsRemaining()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
      mingaSupportTier: jspb.Message.getFieldWithDefault(msg, 45, ""),
      bellScheduleEnabled: jspb.Message.getBooleanFieldWithDefault(msg, 46, false),
      bellScheduleStaffAndStudentsEnabled: jspb.Message.getBooleanFieldWithDefault(msg, 47, false)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!MingaDashboardInfo2}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new MingaDashboardInfo2;
    return MingaDashboardInfo2.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!MingaDashboardInfo2} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!MingaDashboardInfo2}
   */
  static deserializeBinaryFromReader(msg: MingaDashboardInfo2, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setHash(value1);
        break;
      case 2:
        var value2 = /** @type {string} */ (reader.readString());
        msg.setName(value2);
        break;
      case 3:
        var value3 = /** @type {string} */ (reader.readString());
        msg.setAddress(value3);
        break;
      case 4:
        var value4 = /** @type {string} */ (reader.readString());
        msg.setCity(value4);
        break;
      case 5:
        var value5 = /** @type {string} */ (reader.readString());
        msg.setState(value5);
        break;
      case 6:
        var value6 = /** @type {string} */ (reader.readString());
        msg.setPartner(value6);
        break;
      case 7:
        var value7 = /** @type {string} */ (reader.readString());
        msg.setDistrict(value7);
        break;
      case 8:
        var value8 = /** @type {string} */ (reader.readString());
        msg.setImageUrl(value8);
        break;
      case 9:
        var value9 = /** @type {number} */ (reader.readInt32());
        msg.setMemberCount(value9);
        break;
      case 10:
        var value10 = /** @type {!MingaType} */ (reader.readEnum());
        msg.setType(value10);
        break;
      case 11:
        var value11 = /** @type {!MingaStatus} */ (reader.readEnum());
        msg.setStatus(value11);
        break;
      case 12:
        var value12 = /** @type {boolean} */ (reader.readBool());
        msg.setPaused(value12);
        break;
      case 13:
        var value13 = /** @type {number} */ (reader.readInt32());
        msg.setSize(value13);
        break;
      case 14:
        var value14 = /** @type {number} */ (reader.readInt32());
        msg.setRegisteredCount(value14);
        break;
      case 15:
        var value15 = /** @type {number} */ (reader.readInt32());
        msg.setBillableCount(value15);
        break;
      case 16:
        var value16 = /** @type {number} */ (reader.readInt32());
        msg.setTotalCount(value16);
        break;
      case 17:
        var value17 = new MingaSubscription2;
        reader.readMessage(value17,MingaSubscription2.deserializeBinaryFromReader);
        msg.setSubscription(value17);
        break;
      case 18:
        var value18 = new common_date_pb.Date;
        reader.readMessage(value18,common_date_pb.Date.deserializeBinaryFromReader);
        msg.setCreatedAt(value18);
        break;
      case 19:
        var value19 = /** @type {number} */ (reader.readInt32());
        msg.setStickerCount(value19);
        break;
      case 20:
        var value20 = /** @type {number} */ (reader.readInt32());
        msg.setGroupCount(value20);
        break;
      case 26:
        var value26 = /** @type {number} */ (reader.readInt32());
        msg.setCheckinCount(value26);
        break;
      case 21:
        var value21 = /** @type {number} */ (reader.readInt32());
        msg.setDailyActiveCount(value21);
        break;
      case 22:
        var value22 = /** @type {number} */ (reader.readInt32());
        msg.setWeeklyActiveCount(value22);
        break;
      case 23:
        var value23 = /** @type {number} */ (reader.readInt32());
        msg.setMonthlyActiveCount(value23);
        break;
      case 24:
        var value24 = /** @type {number} */ (reader.readInt32());
        msg.setHallPassCount(value24);
        break;
      case 25:
        var value25 = /** @type {number} */ (reader.readInt32());
        msg.setPostCount(value25);
        break;
      case 27:
        var value27 = /** @type {number} */ (reader.readInt32());
        msg.setBehaviorCount(value27);
        break;
      case 28:
        var value28 = new MingaFeatureToggle;
        reader.readMessage(value28,MingaFeatureToggle.deserializeBinaryFromReader);
        msg.setFeatureToggle(value28);
        break;
      case 29:
        var value29 = /** @type {number} */ (reader.readInt32());
        msg.setRegisteredStudents(value29);
        break;
      case 30:
        var value30 = /** @type {number} */ (reader.readInt32());
        msg.setUnregisteredStudents(value30);
        break;
      case 31:
        var value31 = /** @type {string} */ (reader.readString());
        msg.setSisType(value31);
        break;
      case 32:
        var value32 = /** @type {string} */ (reader.readString());
        msg.setHubSpotId(value32);
        break;
      case 33:
        var value33 = /** @type {string} */ (reader.readString());
        msg.setRostering(value33);
        break;
      case 34:
        var value34 = new common_date_pb.Date;
        reader.readMessage(value34,common_date_pb.Date.deserializeBinaryFromReader);
        msg.setSubscriptionEndDate(value34);
        break;
      case 35:
        var value35 = msg.getHealthScoreStatsMap();
        reader.readMessage(value35, function(message, reader) {
          jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readFloat, null, "");
           });
        break;
      case 36:
        var value36 = /** @type {number} */ (reader.readInt32());
        msg.setSmsCount(value36);
        break;
      case 37:
        var value37 = /** @type {boolean} */ (reader.readBool());
        msg.setSisActive(value37);
        break;
      case 38:
        var value38 = new MingaHealthScoreMapEntry;
        reader.readMessage(value38,MingaHealthScoreMapEntry.deserializeBinaryFromReader);
        msg.addHealthScoreStatsEntries(value38);
        break;
      case 39:
        var value39 = new common_date_pb.Date;
        reader.readMessage(value39,common_date_pb.Date.deserializeBinaryFromReader);
        msg.setSubscriptionStartDate(value39);
        break;
      case 41:
        var value41 = /** @type {number} */ (reader.readInt32());
        msg.setSisListCount(value41);
        break;
      case 42:
        var value42 = /** @type {boolean} */ (reader.readBool());
        msg.setSisListActive(value42);
        break;
      case 43:
        var value43 = /** @type {string} */ (reader.readString());
        msg.setDistrictId(value43);
        break;
      case 44:
        var value44 = new google_protobuf_wrappers_pb.Int32Value;
        reader.readMessage(value44,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
        msg.setSmsRemaining(value44);
        break;
      case 45:
        var value45 = /** @type {string} */ (reader.readString());
        msg.setMingaSupportTier(value45);
        break;
      case 46:
        var value46 = /** @type {boolean} */ (reader.readBool());
        msg.setBellScheduleEnabled(value46);
        break;
      case 47:
        var value47 = /** @type {boolean} */ (reader.readBool());
        msg.setBellScheduleStaffAndStudentsEnabled(value47);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!MingaDashboardInfo2} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: MingaDashboardInfo2, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getHash();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
    f = message.getName();
    if (f.length > 0) {
      writer.writeString(
        2,
        f
      );
    }
    f = message.getAddress();
    if (f.length > 0) {
      writer.writeString(
        3,
        f
      );
    }
    f = message.getCity();
    if (f.length > 0) {
      writer.writeString(
        4,
        f
      );
    }
    f = message.getState();
    if (f.length > 0) {
      writer.writeString(
        5,
        f
      );
    }
    f = message.getPartner();
    if (f.length > 0) {
      writer.writeString(
        6,
        f
      );
    }
    f = message.getDistrict();
    if (f.length > 0) {
      writer.writeString(
        7,
        f
      );
    }
    f = message.getImageUrl();
    if (f.length > 0) {
      writer.writeString(
        8,
        f
      );
    }
    f = message.getMemberCount();
    if (f !== 0) {
      writer.writeInt32(
        9,
        f
      );
    }
    f = message.getType();
    if (f !== 0.0) {
      writer.writeEnum(
        10,
        f
      );
    }
    f = message.getStatus();
    if (f !== 0.0) {
      writer.writeEnum(
        11,
        f
      );
    }
    f = message.getPaused();
    if (f) {
      writer.writeBool(
        12,
        f
      );
    }
    f = message.getSize();
    if (f !== 0) {
      writer.writeInt32(
        13,
        f
      );
    }
    f = message.getRegisteredCount();
    if (f !== 0) {
      writer.writeInt32(
        14,
        f
      );
    }
    f = message.getBillableCount();
    if (f !== 0) {
      writer.writeInt32(
        15,
        f
      );
    }
    f = message.getTotalCount();
    if (f !== 0) {
      writer.writeInt32(
        16,
        f
      );
    }
    f = message.getSubscription();
    if (f != null) {
      writer.writeMessage(
        17,
        f,
        MingaSubscription2.serializeBinaryToWriter
      );
    }
    f = message.getCreatedAt();
    if (f != null) {
      writer.writeMessage(
        18,
        f,
        common_date_pb.Date.serializeBinaryToWriter
      );
    }
    f = message.getStickerCount();
    if (f !== 0) {
      writer.writeInt32(
        19,
        f
      );
    }
    f = message.getGroupCount();
    if (f !== 0) {
      writer.writeInt32(
        20,
        f
      );
    }
    f = message.getCheckinCount();
    if (f !== 0) {
      writer.writeInt32(
        26,
        f
      );
    }
    f = message.getDailyActiveCount();
    if (f !== 0) {
      writer.writeInt32(
        21,
        f
      );
    }
    f = message.getWeeklyActiveCount();
    if (f !== 0) {
      writer.writeInt32(
        22,
        f
      );
    }
    f = message.getMonthlyActiveCount();
    if (f !== 0) {
      writer.writeInt32(
        23,
        f
      );
    }
    f = message.getHallPassCount();
    if (f !== 0) {
      writer.writeInt32(
        24,
        f
      );
    }
    f = message.getPostCount();
    if (f !== 0) {
      writer.writeInt32(
        25,
        f
      );
    }
    f = message.getBehaviorCount();
    if (f !== 0) {
      writer.writeInt32(
        27,
        f
      );
    }
    f = message.getFeatureToggle();
    if (f != null) {
      writer.writeMessage(
        28,
        f,
        MingaFeatureToggle.serializeBinaryToWriter
      );
    }
    f = message.getRegisteredStudents();
    if (f !== 0) {
      writer.writeInt32(
        29,
        f
      );
    }
    f = message.getUnregisteredStudents();
    if (f !== 0) {
      writer.writeInt32(
        30,
        f
      );
    }
    f = message.getSisType();
    if (f.length > 0) {
      writer.writeString(
        31,
        f
      );
    }
    f = message.getHubSpotId();
    if (f.length > 0) {
      writer.writeString(
        32,
        f
      );
    }
    f = message.getRostering();
    if (f.length > 0) {
      writer.writeString(
        33,
        f
      );
    }
    f = message.getSubscriptionEndDate();
    if (f != null) {
      writer.writeMessage(
        34,
        f,
        common_date_pb.Date.serializeBinaryToWriter
      );
    }
    f = message.getHealthScoreStatsMap(true);
    if (f && f.getLength() > 0) {
      f.serializeBinary(35, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeFloat);
    }
    f = message.getSmsCount();
    if (f !== 0) {
      writer.writeInt32(
        36,
        f
      );
    }
    f = message.getSisActive();
    if (f) {
      writer.writeBool(
        37,
        f
      );
    }
    f = message.getHealthScoreStatsEntriesList();
    if (f.length > 0) {
      writer.writeRepeatedMessage(
        38,
        f,
        MingaHealthScoreMapEntry.serializeBinaryToWriter
      );
    }
    f = message.getSubscriptionStartDate();
    if (f != null) {
      writer.writeMessage(
        39,
        f,
        common_date_pb.Date.serializeBinaryToWriter
      );
    }
    f = message.getSisListCount();
    if (f !== 0) {
      writer.writeInt32(
        41,
        f
      );
    }
    f = message.getSisListActive();
    if (f) {
      writer.writeBool(
        42,
        f
      );
    }
    f = message.getDistrictId();
    if (f.length > 0) {
      writer.writeString(
        43,
        f
      );
    }
    f = message.getSmsRemaining();
    if (f != null) {
      writer.writeMessage(
        44,
        f,
        google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
      );
    }
    f = message.getMingaSupportTier();
    if (f.length > 0) {
      writer.writeString(
        45,
        f
      );
    }
    f = message.getBellScheduleEnabled();
    if (f) {
      writer.writeBool(
        46,
        f
      );
    }
    f = message.getBellScheduleStaffAndStudentsEnabled();
    if (f) {
      writer.writeBool(
        47,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    MingaDashboardInfo2.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string hash = 1;
   * @return {string}
   */
  getHash(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setHash(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


  /**
   * optional string name = 2;
   * @return {string}
   */
  getName(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
  };


  /** @param {string} value */
  setName(value: string) {
    jspb.Message.setProto3StringField(this, 2, value);
  }


  /**
   * optional string address = 3;
   * @return {string}
   */
  getAddress(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
  };


  /** @param {string} value */
  setAddress(value: string) {
    jspb.Message.setProto3StringField(this, 3, value);
  }


  /**
   * optional string city = 4;
   * @return {string}
   */
  getCity(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
  };


  /** @param {string} value */
  setCity(value: string) {
    jspb.Message.setProto3StringField(this, 4, value);
  }


  /**
   * optional string state = 5;
   * @return {string}
   */
  getState(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
  };


  /** @param {string} value */
  setState(value: string) {
    jspb.Message.setProto3StringField(this, 5, value);
  }


  /**
   * optional string partner = 6;
   * @return {string}
   */
  getPartner(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
  };


  /** @param {string} value */
  setPartner(value: string) {
    jspb.Message.setProto3StringField(this, 6, value);
  }


  /**
   * optional string district = 7;
   * @return {string}
   */
  getDistrict(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
  };


  /** @param {string} value */
  setDistrict(value: string) {
    jspb.Message.setProto3StringField(this, 7, value);
  }


  /**
   * optional string image_url = 8;
   * @return {string}
   */
  getImageUrl(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
  };


  /** @param {string} value */
  setImageUrl(value: string) {
    jspb.Message.setProto3StringField(this, 8, value);
  }


  /**
   * optional int32 member_count = 9;
   * @return {number}
   */
  getMemberCount(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
  };


  /** @param {number} value */
  setMemberCount(value: number) {
    jspb.Message.setProto3IntField(this, 9, value);
  }


  /**
   * optional MingaType type = 10;
   * @return {!MingaType}
   */
  getType(): MingaType {
    return /** @type {!MingaType} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
  };


  /** @param {!MingaType} value */
  setType(value: MingaType) {
    jspb.Message.setProto3EnumField(this, 10, value);
  }


  /**
   * optional MingaStatus status = 11;
   * @return {!MingaStatus}
   */
  getStatus(): MingaStatus {
    return /** @type {!MingaStatus} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
  };


  /** @param {!MingaStatus} value */
  setStatus(value: MingaStatus) {
    jspb.Message.setProto3EnumField(this, 11, value);
  }


  /**
   * optional bool paused = 12;
   * @return {boolean}
   */
  getPaused(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 12, false));
  };


  /** @param {boolean} value */
  setPaused(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 12, value);
  }


  /**
   * optional int32 size = 13;
   * @return {number}
   */
  getSize(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
  };


  /** @param {number} value */
  setSize(value: number) {
    jspb.Message.setProto3IntField(this, 13, value);
  }


  /**
   * optional int32 registered_count = 14;
   * @return {number}
   */
  getRegisteredCount(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
  };


  /** @param {number} value */
  setRegisteredCount(value: number) {
    jspb.Message.setProto3IntField(this, 14, value);
  }


  /**
   * optional int32 billable_count = 15;
   * @return {number}
   */
  getBillableCount(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
  };


  /** @param {number} value */
  setBillableCount(value: number) {
    jspb.Message.setProto3IntField(this, 15, value);
  }


  /**
   * optional int32 total_count = 16;
   * @return {number}
   */
  getTotalCount(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
  };


  /** @param {number} value */
  setTotalCount(value: number) {
    jspb.Message.setProto3IntField(this, 16, value);
  }


  /**
   * optional MingaSubscription2 subscription = 17;
   * @return {?MingaSubscription2}
   */
  getSubscription(): MingaSubscription2 {
    return /** @type{?MingaSubscription2} */ (
      jspb.Message.getWrapperField(this, MingaSubscription2, 17));
  }


  /** @param {?MingaSubscription2|undefined} value */
  setSubscription(value?: MingaSubscription2) {
    jspb.Message.setWrapperField(this, 17, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearSubscription() {
    this.setSubscription(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasSubscription(): boolean {
    return jspb.Message.getField(this, 17) != null;
  }


  /**
   * optional minga.common.Date created_at = 18;
   * @return {?Date}
   */
  getCreatedAt(): common_date_pb.Date {
    return /** @type{?Date} */ (
      jspb.Message.getWrapperField(this, common_date_pb.Date, 18));
  }


  /** @param {?Date|undefined} value */
  setCreatedAt(value?: common_date_pb.Date) {
    jspb.Message.setWrapperField(this, 18, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearCreatedAt() {
    this.setCreatedAt(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasCreatedAt(): boolean {
    return jspb.Message.getField(this, 18) != null;
  }


  /**
   * optional int32 sticker_count = 19;
   * @return {number}
   */
  getStickerCount(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 19, 0));
  };


  /** @param {number} value */
  setStickerCount(value: number) {
    jspb.Message.setProto3IntField(this, 19, value);
  }


  /**
   * optional int32 group_count = 20;
   * @return {number}
   */
  getGroupCount(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 20, 0));
  };


  /** @param {number} value */
  setGroupCount(value: number) {
    jspb.Message.setProto3IntField(this, 20, value);
  }


  /**
   * optional int32 checkin_count = 26;
   * @return {number}
   */
  getCheckinCount(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 26, 0));
  };


  /** @param {number} value */
  setCheckinCount(value: number) {
    jspb.Message.setProto3IntField(this, 26, value);
  }


  /**
   * optional int32 daily_active_count = 21;
   * @return {number}
   */
  getDailyActiveCount(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 21, 0));
  };


  /** @param {number} value */
  setDailyActiveCount(value: number) {
    jspb.Message.setProto3IntField(this, 21, value);
  }


  /**
   * optional int32 weekly_active_count = 22;
   * @return {number}
   */
  getWeeklyActiveCount(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 22, 0));
  };


  /** @param {number} value */
  setWeeklyActiveCount(value: number) {
    jspb.Message.setProto3IntField(this, 22, value);
  }


  /**
   * optional int32 monthly_active_count = 23;
   * @return {number}
   */
  getMonthlyActiveCount(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 23, 0));
  };


  /** @param {number} value */
  setMonthlyActiveCount(value: number) {
    jspb.Message.setProto3IntField(this, 23, value);
  }


  /**
   * optional int32 hall_pass_count = 24;
   * @return {number}
   */
  getHallPassCount(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 24, 0));
  };


  /** @param {number} value */
  setHallPassCount(value: number) {
    jspb.Message.setProto3IntField(this, 24, value);
  }


  /**
   * optional int32 post_count = 25;
   * @return {number}
   */
  getPostCount(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 25, 0));
  };


  /** @param {number} value */
  setPostCount(value: number) {
    jspb.Message.setProto3IntField(this, 25, value);
  }


  /**
   * optional int32 behavior_count = 27;
   * @return {number}
   */
  getBehaviorCount(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 27, 0));
  };


  /** @param {number} value */
  setBehaviorCount(value: number) {
    jspb.Message.setProto3IntField(this, 27, value);
  }


  /**
   * optional MingaFeatureToggle feature_toggle = 28;
   * @return {?MingaFeatureToggle}
   */
  getFeatureToggle(): MingaFeatureToggle {
    return /** @type{?MingaFeatureToggle} */ (
      jspb.Message.getWrapperField(this, MingaFeatureToggle, 28));
  }


  /** @param {?MingaFeatureToggle|undefined} value */
  setFeatureToggle(value?: MingaFeatureToggle) {
    jspb.Message.setWrapperField(this, 28, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearFeatureToggle() {
    this.setFeatureToggle(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasFeatureToggle(): boolean {
    return jspb.Message.getField(this, 28) != null;
  }


  /**
   * optional int32 registered_students = 29;
   * @return {number}
   */
  getRegisteredStudents(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 29, 0));
  };


  /** @param {number} value */
  setRegisteredStudents(value: number) {
    jspb.Message.setProto3IntField(this, 29, value);
  }


  /**
   * optional int32 unregistered_students = 30;
   * @return {number}
   */
  getUnregisteredStudents(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 30, 0));
  };


  /** @param {number} value */
  setUnregisteredStudents(value: number) {
    jspb.Message.setProto3IntField(this, 30, value);
  }


  /**
   * optional string sis_type = 31;
   * @return {string}
   */
  getSisType(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 31, ""));
  };


  /** @param {string} value */
  setSisType(value: string) {
    jspb.Message.setProto3StringField(this, 31, value);
  }


  /**
   * optional string hub_spot_id = 32;
   * @return {string}
   */
  getHubSpotId(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 32, ""));
  };


  /** @param {string} value */
  setHubSpotId(value: string) {
    jspb.Message.setProto3StringField(this, 32, value);
  }


  /**
   * optional string rostering = 33;
   * @return {string}
   */
  getRostering(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 33, ""));
  };


  /** @param {string} value */
  setRostering(value: string) {
    jspb.Message.setProto3StringField(this, 33, value);
  }


  /**
   * optional minga.common.Date subscription_end_date = 34;
   * @return {?Date}
   */
  getSubscriptionEndDate(): common_date_pb.Date {
    return /** @type{?Date} */ (
      jspb.Message.getWrapperField(this, common_date_pb.Date, 34));
  }


  /** @param {?Date|undefined} value */
  setSubscriptionEndDate(value?: common_date_pb.Date) {
    jspb.Message.setWrapperField(this, 34, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearSubscriptionEndDate() {
    this.setSubscriptionEndDate(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasSubscriptionEndDate(): boolean {
    return jspb.Message.getField(this, 34) != null;
  }


  /**
   * map<string, float> health_score_stats = 35;
   * @param {boolean=} opt_noLazyCreate Do not create the map if
   * empty, instead returning `undefined`
   * @return {!jspb.Map<string,number>}
   */
  getHealthScoreStatsMap(): jspb.Map<string,number>;
  getHealthScoreStatsMap(noLazyCreate: true): jspb.Map<string,number>|undefined;
  getHealthScoreStatsMap(noLazyCreate: false): jspb.Map<string,number>;
  getHealthScoreStatsMap(opt_noLazyCreate: boolean = false): jspb.Map<string,number> {
    return /** @type {!jspb.Map<string,number>} */ (
        jspb.Message.getMapField(this, 35, opt_noLazyCreate,
        null));
  }


  /**
   * Clears values from the map. The map will be non-null.
   */
  clearHealthScoreStatsMap() {
    this.getHealthScoreStatsMap().clear();
  }


  /**
   * optional int32 sms_count = 36;
   * @return {number}
   */
  getSmsCount(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 36, 0));
  };


  /** @param {number} value */
  setSmsCount(value: number) {
    jspb.Message.setProto3IntField(this, 36, value);
  }


  /**
   * optional bool sis_active = 37;
   * @return {boolean}
   */
  getSisActive(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 37, false));
  };


  /** @param {boolean} value */
  setSisActive(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 37, value);
  }


  /**
   * repeated MingaHealthScoreMapEntry health_score_stats_entries = 38;
   * @return {!Array<!MingaHealthScoreMapEntry>}
   */
  getHealthScoreStatsEntriesList(): MingaHealthScoreMapEntry[] {
    return /** @type{!Array<!MingaHealthScoreMapEntry>} */ (
      jspb.Message.getRepeatedWrapperField(this, MingaHealthScoreMapEntry, 38));
  }


  /** @param {!Array<!MingaHealthScoreMapEntry>} value */
  setHealthScoreStatsEntriesList(value?: MingaHealthScoreMapEntry[]) {
    jspb.Message.setRepeatedWrapperField(this, 38, value);
  }


  /**
   * @param {!MingaHealthScoreMapEntry=} opt_value
   * @param {number=} opt_index
   * @return {!MingaHealthScoreMapEntry}
   */
  addHealthScoreStatsEntries(opt_value?: MingaHealthScoreMapEntry, opt_index?: number): MingaHealthScoreMapEntry {
    return jspb.Message.addToRepeatedWrapperField(this, 38, opt_value, MingaHealthScoreMapEntry, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearHealthScoreStatsEntriesList() {
    this.setHealthScoreStatsEntriesList([]);
  }


  /**
   * optional minga.common.Date subscription_start_date = 39;
   * @return {?Date}
   */
  getSubscriptionStartDate(): common_date_pb.Date {
    return /** @type{?Date} */ (
      jspb.Message.getWrapperField(this, common_date_pb.Date, 39));
  }


  /** @param {?Date|undefined} value */
  setSubscriptionStartDate(value?: common_date_pb.Date) {
    jspb.Message.setWrapperField(this, 39, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearSubscriptionStartDate() {
    this.setSubscriptionStartDate(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasSubscriptionStartDate(): boolean {
    return jspb.Message.getField(this, 39) != null;
  }


  /**
   * optional int32 sis_list_count = 41;
   * @return {number}
   */
  getSisListCount(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 41, 0));
  };


  /** @param {number} value */
  setSisListCount(value: number) {
    jspb.Message.setProto3IntField(this, 41, value);
  }


  /**
   * optional bool sis_list_active = 42;
   * @return {boolean}
   */
  getSisListActive(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 42, false));
  };


  /** @param {boolean} value */
  setSisListActive(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 42, value);
  }


  /**
   * optional string district_id = 43;
   * @return {string}
   */
  getDistrictId(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 43, ""));
  };


  /** @param {string} value */
  setDistrictId(value: string) {
    jspb.Message.setProto3StringField(this, 43, value);
  }


  /**
   * optional google.protobuf.Int32Value sms_remaining = 44;
   * @return {?Int32Value}
   */
  getSmsRemaining(): google_protobuf_wrappers_pb.Int32Value {
    return /** @type{?Int32Value} */ (
      jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 44));
  }


  /** @param {?Int32Value|undefined} value */
  setSmsRemaining(value?: google_protobuf_wrappers_pb.Int32Value) {
    jspb.Message.setWrapperField(this, 44, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearSmsRemaining() {
    this.setSmsRemaining(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasSmsRemaining(): boolean {
    return jspb.Message.getField(this, 44) != null;
  }


  /**
   * optional string minga_support_tier = 45;
   * @return {string}
   */
  getMingaSupportTier(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 45, ""));
  };


  /** @param {string} value */
  setMingaSupportTier(value: string) {
    jspb.Message.setProto3StringField(this, 45, value);
  }


  /**
   * optional bool bell_schedule_enabled = 46;
   * @return {boolean}
   */
  getBellScheduleEnabled(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 46, false));
  };


  /** @param {boolean} value */
  setBellScheduleEnabled(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 46, value);
  }


  /**
   * optional bool bell_schedule_staff_and_students_enabled = 47;
   * @return {boolean}
   */
  getBellScheduleStaffAndStudentsEnabled(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 47, false));
  };


  /** @param {boolean} value */
  setBellScheduleStaffAndStudentsEnabled(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 47, value);
  }


}
export namespace MingaHealthScoreMapEntry {
  export interface AsObject {
    key: string;
    intValue: number;
    scoreValue?: MingaHealthScore.AsObject;
  }
}
export class MingaHealthScoreMapEntry extends jspb.Message {
  static readonly displayName = "MingaHealthScoreMapEntry";
  /**
   * Oneof group definitions for this message. Each group defines the field
   * numbers belonging to that group. When of these fields' value is set, all
   * other fields in the group are cleared. During deserialization, if multiple
   * fields are encountered for a group, only the last value seen will be kept.
   * @private {!Array<!Array<number>>}
   * @const
   */
  static readonly oneofGroups_ = [[2,3]];


  /**
   * @return {MingaHealthScoreMapEntry.ValueCase}
   */
  getValueCase() {
    return /** @type {MingaHealthScoreMapEntry.ValueCase} */(jspb.Message.computeOneofCase(this, MingaHealthScoreMapEntry.oneofGroups_[0]));
  }

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, MingaHealthScoreMapEntry.oneofGroups_);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): MingaHealthScoreMapEntry.AsObject {
    return MingaHealthScoreMapEntry.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!MingaHealthScoreMapEntry} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: MingaHealthScoreMapEntry) {
    var f: any;
    var obj: any = {
      key: jspb.Message.getFieldWithDefault(msg, 1, ""),
      intValue: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
      scoreValue: (f = msg.getScoreValue()) && MingaHealthScore.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!MingaHealthScoreMapEntry}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new MingaHealthScoreMapEntry;
    return MingaHealthScoreMapEntry.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!MingaHealthScoreMapEntry} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!MingaHealthScoreMapEntry}
   */
  static deserializeBinaryFromReader(msg: MingaHealthScoreMapEntry, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setKey(value1);
        break;
      case 2:
        var value2 = /** @type {number} */ (reader.readDouble());
        msg.setIntValue(value2);
        break;
      case 3:
        var value3 = new MingaHealthScore;
        reader.readMessage(value3,MingaHealthScore.deserializeBinaryFromReader);
        msg.setScoreValue(value3);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!MingaHealthScoreMapEntry} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: MingaHealthScoreMapEntry, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getKey();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
    f = /** @type {number} */ (jspb.Message.getField(message, 2));
    if (f != null) {
      writer.writeDouble(
        2,
        f
      );
    }
    f = message.getScoreValue();
    if (f != null) {
      writer.writeMessage(
        3,
        f,
        MingaHealthScore.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    MingaHealthScoreMapEntry.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string key = 1;
   * @return {string}
   */
  getKey(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setKey(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


  /**
   * optional double int_value = 2;
   * @return {number}
   */
  getIntValue(): number {
    return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
  };


  /** @param {number} value */
  setIntValue(value: number) {
    jspb.Message.setOneofField(this, 2, MingaHealthScoreMapEntry.oneofGroups_[0], value);
  }


  /**
   * Clears the field making it undefined.
   */
  clearIntValue() {
    jspb.Message.setOneofField(this, 2, MingaHealthScoreMapEntry.oneofGroups_[0], undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasIntValue(): boolean {
    return jspb.Message.getField(this, 2) != null;
  }


  /**
   * optional MingaHealthScore score_value = 3;
   * @return {?MingaHealthScore}
   */
  getScoreValue(): MingaHealthScore {
    return /** @type{?MingaHealthScore} */ (
      jspb.Message.getWrapperField(this, MingaHealthScore, 3));
  }


  /** @param {?MingaHealthScore|undefined} value */
  setScoreValue(value?: MingaHealthScore) {
    jspb.Message.setOneofWrapperField(this, 3, MingaHealthScoreMapEntry.oneofGroups_[0], value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearScoreValue() {
    this.setScoreValue(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasScoreValue(): boolean {
    return jspb.Message.getField(this, 3) != null;
  }


}
export namespace MingaHealthScoreMapEntry {
export enum ValueCase {
  VALUE_NOT_SET = 0,
  INT_VALUE = 2,
  SCORE_VALUE = 3,
}
} // namespace MingaHealthScoreMapEntry
export namespace MingaHealthScore {
  export interface AsObject {
    score: number;
    quality: string;
  }
}
export class MingaHealthScore extends jspb.Message {
  static readonly displayName = "MingaHealthScore";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): MingaHealthScore.AsObject {
    return MingaHealthScore.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!MingaHealthScore} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: MingaHealthScore) {
    var f: any;
    var obj: any = {
      score: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
      quality: jspb.Message.getFieldWithDefault(msg, 2, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!MingaHealthScore}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new MingaHealthScore;
    return MingaHealthScore.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!MingaHealthScore} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!MingaHealthScore}
   */
  static deserializeBinaryFromReader(msg: MingaHealthScore, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {number} */ (reader.readDouble());
        msg.setScore(value1);
        break;
      case 2:
        var value2 = /** @type {string} */ (reader.readString());
        msg.setQuality(value2);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!MingaHealthScore} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: MingaHealthScore, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getScore();
    if (f !== 0.0) {
      writer.writeDouble(
        1,
        f
      );
    }
    f = message.getQuality();
    if (f.length > 0) {
      writer.writeString(
        2,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    MingaHealthScore.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional double score = 1;
   * @return {number}
   */
  getScore(): number {
    return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
  };


  /** @param {number} value */
  setScore(value: number) {
    jspb.Message.setProto3FloatField(this, 1, value);
  }


  /**
   * optional string quality = 2;
   * @return {string}
   */
  getQuality(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
  };


  /** @param {string} value */
  setQuality(value: string) {
    jspb.Message.setProto3StringField(this, 2, value);
  }


}
export namespace MingaSubscription2 {
  export interface AsObject {
    planId: string;
    name: string;
    groupSize: number;
    adminUserSize: number;
    premiumStudioTemplates: boolean;
    premiumPrograms: boolean;
    pricedPerDefaultRole: boolean;
    plan?: MingaSubscriptionPlan;
  }
}
export class MingaSubscription2 extends jspb.Message {
  static readonly displayName = "MingaSubscription2";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): MingaSubscription2.AsObject {
    return MingaSubscription2.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!MingaSubscription2} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: MingaSubscription2) {
    var f: any;
    var obj: any = {
      planId: jspb.Message.getFieldWithDefault(msg, 1, ""),
      name: jspb.Message.getFieldWithDefault(msg, 2, ""),
      groupSize: jspb.Message.getFieldWithDefault(msg, 5, 0),
      adminUserSize: jspb.Message.getFieldWithDefault(msg, 6, 0),
      premiumStudioTemplates: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
      premiumPrograms: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
      pricedPerDefaultRole: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
      plan: jspb.Message.getFieldWithDefault(msg, 10, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!MingaSubscription2}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new MingaSubscription2;
    return MingaSubscription2.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!MingaSubscription2} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!MingaSubscription2}
   */
  static deserializeBinaryFromReader(msg: MingaSubscription2, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setPlanId(value1);
        break;
      case 2:
        var value2 = /** @type {string} */ (reader.readString());
        msg.setName(value2);
        break;
      case 5:
        var value5 = /** @type {number} */ (reader.readInt32());
        msg.setGroupSize(value5);
        break;
      case 6:
        var value6 = /** @type {number} */ (reader.readInt32());
        msg.setAdminUserSize(value6);
        break;
      case 7:
        var value7 = /** @type {boolean} */ (reader.readBool());
        msg.setPremiumStudioTemplates(value7);
        break;
      case 8:
        var value8 = /** @type {boolean} */ (reader.readBool());
        msg.setPremiumPrograms(value8);
        break;
      case 9:
        var value9 = /** @type {boolean} */ (reader.readBool());
        msg.setPricedPerDefaultRole(value9);
        break;
      case 10:
        var value10 = /** @type {!MingaSubscriptionPlan} */ (reader.readEnum());
        msg.setPlan(value10);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!MingaSubscription2} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: MingaSubscription2, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getPlanId();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
    f = message.getName();
    if (f.length > 0) {
      writer.writeString(
        2,
        f
      );
    }
    f = message.getGroupSize();
    if (f !== 0) {
      writer.writeInt32(
        5,
        f
      );
    }
    f = message.getAdminUserSize();
    if (f !== 0) {
      writer.writeInt32(
        6,
        f
      );
    }
    f = message.getPremiumStudioTemplates();
    if (f) {
      writer.writeBool(
        7,
        f
      );
    }
    f = message.getPremiumPrograms();
    if (f) {
      writer.writeBool(
        8,
        f
      );
    }
    f = message.getPricedPerDefaultRole();
    if (f) {
      writer.writeBool(
        9,
        f
      );
    }
    f = message.getPlan();
    if (f !== 0.0) {
      writer.writeEnum(
        10,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    MingaSubscription2.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string plan_id = 1;
   * @return {string}
   */
  getPlanId(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setPlanId(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


  /**
   * optional string name = 2;
   * @return {string}
   */
  getName(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
  };


  /** @param {string} value */
  setName(value: string) {
    jspb.Message.setProto3StringField(this, 2, value);
  }


  /**
   * optional int32 group_size = 5;
   * @return {number}
   */
  getGroupSize(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
  };


  /** @param {number} value */
  setGroupSize(value: number) {
    jspb.Message.setProto3IntField(this, 5, value);
  }


  /**
   * optional int32 admin_user_size = 6;
   * @return {number}
   */
  getAdminUserSize(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
  };


  /** @param {number} value */
  setAdminUserSize(value: number) {
    jspb.Message.setProto3IntField(this, 6, value);
  }


  /**
   * optional bool premium_studio_templates = 7;
   * @return {boolean}
   */
  getPremiumStudioTemplates(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
  };


  /** @param {boolean} value */
  setPremiumStudioTemplates(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 7, value);
  }


  /**
   * optional bool premium_programs = 8;
   * @return {boolean}
   */
  getPremiumPrograms(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
  };


  /** @param {boolean} value */
  setPremiumPrograms(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 8, value);
  }


  /**
   * optional bool priced_per_default_role = 9;
   * @return {boolean}
   */
  getPricedPerDefaultRole(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
  };


  /** @param {boolean} value */
  setPricedPerDefaultRole(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 9, value);
  }


  /**
   * optional MingaSubscriptionPlan plan = 10;
   * @return {!MingaSubscriptionPlan}
   */
  getPlan(): MingaSubscriptionPlan {
    return /** @type {!MingaSubscriptionPlan} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
  };


  /** @param {!MingaSubscriptionPlan} value */
  setPlan(value: MingaSubscriptionPlan) {
    jspb.Message.setProto3EnumField(this, 10, value);
  }


}
export namespace MingaSubscription {
  export interface AsObject {
    planId: string;
    name: string;
    mingaType: string;
    mingaSizeDeprecated: number;
    planUrl: string;
    monthlyFee: number;
    id: number;
    annualFee: string;
    mingaSize?: google_protobuf_wrappers_pb.Int32Value.AsObject;
    groupSize?: google_protobuf_wrappers_pb.Int32Value.AsObject;
    adminUserSize?: google_protobuf_wrappers_pb.Int32Value.AsObject;
    premiumStudioTemplates: boolean;
    premiumPrograms: boolean;
    pricedPerDefaultRole: boolean;
    annualSmsMessageQuota: number;
  }
}
export class MingaSubscription extends jspb.Message {
  static readonly displayName = "MingaSubscription";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): MingaSubscription.AsObject {
    return MingaSubscription.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!MingaSubscription} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: MingaSubscription) {
    var f: any;
    var obj: any = {
      planId: jspb.Message.getFieldWithDefault(msg, 1, ""),
      name: jspb.Message.getFieldWithDefault(msg, 2, ""),
      mingaType: jspb.Message.getFieldWithDefault(msg, 3, ""),
      mingaSizeDeprecated: jspb.Message.getFieldWithDefault(msg, 4, 0),
      planUrl: jspb.Message.getFieldWithDefault(msg, 5, ""),
      monthlyFee: jspb.Message.getFieldWithDefault(msg, 6, 0),
      id: jspb.Message.getFieldWithDefault(msg, 7, 0),
      annualFee: jspb.Message.getFieldWithDefault(msg, 8, ""),
      mingaSize: (f = msg.getMingaSize()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
      groupSize: (f = msg.getGroupSize()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
      adminUserSize: (f = msg.getAdminUserSize()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
      premiumStudioTemplates: jspb.Message.getBooleanFieldWithDefault(msg, 13, false),
      premiumPrograms: jspb.Message.getBooleanFieldWithDefault(msg, 14, false),
      pricedPerDefaultRole: jspb.Message.getBooleanFieldWithDefault(msg, 15, false),
      annualSmsMessageQuota: jspb.Message.getFieldWithDefault(msg, 16, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!MingaSubscription}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new MingaSubscription;
    return MingaSubscription.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!MingaSubscription} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!MingaSubscription}
   */
  static deserializeBinaryFromReader(msg: MingaSubscription, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setPlanId(value1);
        break;
      case 2:
        var value2 = /** @type {string} */ (reader.readString());
        msg.setName(value2);
        break;
      case 3:
        var value3 = /** @type {string} */ (reader.readString());
        msg.setMingaType(value3);
        break;
      case 4:
        var value4 = /** @type {number} */ (reader.readInt32());
        msg.setMingaSizeDeprecated(value4);
        break;
      case 5:
        var value5 = /** @type {string} */ (reader.readString());
        msg.setPlanUrl(value5);
        break;
      case 6:
        var value6 = /** @type {number} */ (reader.readInt32());
        msg.setMonthlyFee(value6);
        break;
      case 7:
        var value7 = /** @type {number} */ (reader.readInt32());
        msg.setId(value7);
        break;
      case 8:
        var value8 = /** @type {string} */ (reader.readString());
        msg.setAnnualFee(value8);
        break;
      case 9:
        var value9 = new google_protobuf_wrappers_pb.Int32Value;
        reader.readMessage(value9,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
        msg.setMingaSize(value9);
        break;
      case 11:
        var value11 = new google_protobuf_wrappers_pb.Int32Value;
        reader.readMessage(value11,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
        msg.setGroupSize(value11);
        break;
      case 12:
        var value12 = new google_protobuf_wrappers_pb.Int32Value;
        reader.readMessage(value12,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
        msg.setAdminUserSize(value12);
        break;
      case 13:
        var value13 = /** @type {boolean} */ (reader.readBool());
        msg.setPremiumStudioTemplates(value13);
        break;
      case 14:
        var value14 = /** @type {boolean} */ (reader.readBool());
        msg.setPremiumPrograms(value14);
        break;
      case 15:
        var value15 = /** @type {boolean} */ (reader.readBool());
        msg.setPricedPerDefaultRole(value15);
        break;
      case 16:
        var value16 = /** @type {number} */ (reader.readInt32());
        msg.setAnnualSmsMessageQuota(value16);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!MingaSubscription} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: MingaSubscription, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getPlanId();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
    f = message.getName();
    if (f.length > 0) {
      writer.writeString(
        2,
        f
      );
    }
    f = message.getMingaType();
    if (f.length > 0) {
      writer.writeString(
        3,
        f
      );
    }
    f = message.getMingaSizeDeprecated();
    if (f !== 0) {
      writer.writeInt32(
        4,
        f
      );
    }
    f = message.getPlanUrl();
    if (f.length > 0) {
      writer.writeString(
        5,
        f
      );
    }
    f = message.getMonthlyFee();
    if (f !== 0) {
      writer.writeInt32(
        6,
        f
      );
    }
    f = message.getId();
    if (f !== 0) {
      writer.writeInt32(
        7,
        f
      );
    }
    f = message.getAnnualFee();
    if (f.length > 0) {
      writer.writeString(
        8,
        f
      );
    }
    f = message.getMingaSize();
    if (f != null) {
      writer.writeMessage(
        9,
        f,
        google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
      );
    }
    f = message.getGroupSize();
    if (f != null) {
      writer.writeMessage(
        11,
        f,
        google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
      );
    }
    f = message.getAdminUserSize();
    if (f != null) {
      writer.writeMessage(
        12,
        f,
        google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
      );
    }
    f = message.getPremiumStudioTemplates();
    if (f) {
      writer.writeBool(
        13,
        f
      );
    }
    f = message.getPremiumPrograms();
    if (f) {
      writer.writeBool(
        14,
        f
      );
    }
    f = message.getPricedPerDefaultRole();
    if (f) {
      writer.writeBool(
        15,
        f
      );
    }
    f = message.getAnnualSmsMessageQuota();
    if (f !== 0) {
      writer.writeInt32(
        16,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    MingaSubscription.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string plan_id = 1;
   * @return {string}
   */
  getPlanId(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setPlanId(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


  /**
   * optional string name = 2;
   * @return {string}
   */
  getName(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
  };


  /** @param {string} value */
  setName(value: string) {
    jspb.Message.setProto3StringField(this, 2, value);
  }


  /**
   * optional string minga_type = 3;
   * @return {string}
   */
  getMingaType(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
  };


  /** @param {string} value */
  setMingaType(value: string) {
    jspb.Message.setProto3StringField(this, 3, value);
  }


  /**
   * optional int32 minga_size_deprecated = 4;
   * @return {number}
   */
  getMingaSizeDeprecated(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
  };


  /** @param {number} value */
  setMingaSizeDeprecated(value: number) {
    jspb.Message.setProto3IntField(this, 4, value);
  }


  /**
   * optional string plan_url = 5;
   * @return {string}
   */
  getPlanUrl(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
  };


  /** @param {string} value */
  setPlanUrl(value: string) {
    jspb.Message.setProto3StringField(this, 5, value);
  }


  /**
   * optional int32 monthly_fee = 6;
   * @return {number}
   */
  getMonthlyFee(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
  };


  /** @param {number} value */
  setMonthlyFee(value: number) {
    jspb.Message.setProto3IntField(this, 6, value);
  }


  /**
   * optional int32 id = 7;
   * @return {number}
   */
  getId(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
  };


  /** @param {number} value */
  setId(value: number) {
    jspb.Message.setProto3IntField(this, 7, value);
  }


  /**
   * optional string annual_fee = 8;
   * @return {string}
   */
  getAnnualFee(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
  };


  /** @param {string} value */
  setAnnualFee(value: string) {
    jspb.Message.setProto3StringField(this, 8, value);
  }


  /**
   * optional google.protobuf.Int32Value minga_size = 9;
   * @return {?Int32Value}
   */
  getMingaSize(): google_protobuf_wrappers_pb.Int32Value {
    return /** @type{?Int32Value} */ (
      jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 9));
  }


  /** @param {?Int32Value|undefined} value */
  setMingaSize(value?: google_protobuf_wrappers_pb.Int32Value) {
    jspb.Message.setWrapperField(this, 9, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearMingaSize() {
    this.setMingaSize(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasMingaSize(): boolean {
    return jspb.Message.getField(this, 9) != null;
  }


  /**
   * optional google.protobuf.Int32Value group_size = 11;
   * @return {?Int32Value}
   */
  getGroupSize(): google_protobuf_wrappers_pb.Int32Value {
    return /** @type{?Int32Value} */ (
      jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 11));
  }


  /** @param {?Int32Value|undefined} value */
  setGroupSize(value?: google_protobuf_wrappers_pb.Int32Value) {
    jspb.Message.setWrapperField(this, 11, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearGroupSize() {
    this.setGroupSize(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasGroupSize(): boolean {
    return jspb.Message.getField(this, 11) != null;
  }


  /**
   * optional google.protobuf.Int32Value admin_user_size = 12;
   * @return {?Int32Value}
   */
  getAdminUserSize(): google_protobuf_wrappers_pb.Int32Value {
    return /** @type{?Int32Value} */ (
      jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 12));
  }


  /** @param {?Int32Value|undefined} value */
  setAdminUserSize(value?: google_protobuf_wrappers_pb.Int32Value) {
    jspb.Message.setWrapperField(this, 12, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearAdminUserSize() {
    this.setAdminUserSize(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasAdminUserSize(): boolean {
    return jspb.Message.getField(this, 12) != null;
  }


  /**
   * optional bool premium_studio_templates = 13;
   * @return {boolean}
   */
  getPremiumStudioTemplates(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 13, false));
  };


  /** @param {boolean} value */
  setPremiumStudioTemplates(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 13, value);
  }


  /**
   * optional bool premium_programs = 14;
   * @return {boolean}
   */
  getPremiumPrograms(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 14, false));
  };


  /** @param {boolean} value */
  setPremiumPrograms(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 14, value);
  }


  /**
   * optional bool priced_per_default_role = 15;
   * @return {boolean}
   */
  getPricedPerDefaultRole(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 15, false));
  };


  /** @param {boolean} value */
  setPricedPerDefaultRole(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 15, value);
  }


  /**
   * optional int32 annual_sms_message_quota = 16;
   * @return {number}
   */
  getAnnualSmsMessageQuota(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
  };


  /** @param {number} value */
  setAnnualSmsMessageQuota(value: number) {
    jspb.Message.setProto3IntField(this, 16, value);
  }


}
export namespace MingaDistrict {
  export interface AsObject {
    id: number;
    name: string;
    hubspotId: string;
    createdAt?: common_date_pb.DateTime.AsObject;
  }
}
export class MingaDistrict extends jspb.Message {
  static readonly displayName = "MingaDistrict";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): MingaDistrict.AsObject {
    return MingaDistrict.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!MingaDistrict} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: MingaDistrict) {
    var f: any;
    var obj: any = {
      id: jspb.Message.getFieldWithDefault(msg, 1, 0),
      name: jspb.Message.getFieldWithDefault(msg, 2, ""),
      hubspotId: jspb.Message.getFieldWithDefault(msg, 3, ""),
      createdAt: (f = msg.getCreatedAt()) && common_date_pb.DateTime.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!MingaDistrict}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new MingaDistrict;
    return MingaDistrict.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!MingaDistrict} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!MingaDistrict}
   */
  static deserializeBinaryFromReader(msg: MingaDistrict, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {number} */ (reader.readInt32());
        msg.setId(value1);
        break;
      case 2:
        var value2 = /** @type {string} */ (reader.readString());
        msg.setName(value2);
        break;
      case 3:
        var value3 = /** @type {string} */ (reader.readString());
        msg.setHubspotId(value3);
        break;
      case 4:
        var value4 = new common_date_pb.DateTime;
        reader.readMessage(value4,common_date_pb.DateTime.deserializeBinaryFromReader);
        msg.setCreatedAt(value4);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!MingaDistrict} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: MingaDistrict, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getId();
    if (f !== 0) {
      writer.writeInt32(
        1,
        f
      );
    }
    f = message.getName();
    if (f.length > 0) {
      writer.writeString(
        2,
        f
      );
    }
    f = message.getHubspotId();
    if (f.length > 0) {
      writer.writeString(
        3,
        f
      );
    }
    f = message.getCreatedAt();
    if (f != null) {
      writer.writeMessage(
        4,
        f,
        common_date_pb.DateTime.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    MingaDistrict.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional int32 id = 1;
   * @return {number}
   */
  getId(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
  };


  /** @param {number} value */
  setId(value: number) {
    jspb.Message.setProto3IntField(this, 1, value);
  }


  /**
   * optional string name = 2;
   * @return {string}
   */
  getName(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
  };


  /** @param {string} value */
  setName(value: string) {
    jspb.Message.setProto3StringField(this, 2, value);
  }


  /**
   * optional string hubspot_id = 3;
   * @return {string}
   */
  getHubspotId(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
  };


  /** @param {string} value */
  setHubspotId(value: string) {
    jspb.Message.setProto3StringField(this, 3, value);
  }


  /**
   * optional minga.common.DateTime created_at = 4;
   * @return {?DateTime}
   */
  getCreatedAt(): common_date_pb.DateTime {
    return /** @type{?DateTime} */ (
      jspb.Message.getWrapperField(this, common_date_pb.DateTime, 4));
  }


  /** @param {?DateTime|undefined} value */
  setCreatedAt(value?: common_date_pb.DateTime) {
    jspb.Message.setWrapperField(this, 4, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearCreatedAt() {
    this.setCreatedAt(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasCreatedAt(): boolean {
    return jspb.Message.getField(this, 4) != null;
  }


}
export namespace GetMingaDistrictRequest {
  export interface AsObject {
    id: number;
  }
}
export class GetMingaDistrictRequest extends jspb.Message {
  static readonly displayName = "GetMingaDistrictRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): GetMingaDistrictRequest.AsObject {
    return GetMingaDistrictRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!GetMingaDistrictRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: GetMingaDistrictRequest) {
    var f: any;
    var obj: any = {
      id: jspb.Message.getFieldWithDefault(msg, 1, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!GetMingaDistrictRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new GetMingaDistrictRequest;
    return GetMingaDistrictRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!GetMingaDistrictRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!GetMingaDistrictRequest}
   */
  static deserializeBinaryFromReader(msg: GetMingaDistrictRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {number} */ (reader.readInt32());
        msg.setId(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!GetMingaDistrictRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: GetMingaDistrictRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getId();
    if (f !== 0) {
      writer.writeInt32(
        1,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    GetMingaDistrictRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional int32 id = 1;
   * @return {number}
   */
  getId(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
  };


  /** @param {number} value */
  setId(value: number) {
    jspb.Message.setProto3IntField(this, 1, value);
  }


}
export namespace GetMingaDistrictResponse {
  export interface AsObject {
    district?: MingaDistrict.AsObject;
  }
}
export class GetMingaDistrictResponse extends jspb.Message {
  static readonly displayName = "GetMingaDistrictResponse";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): GetMingaDistrictResponse.AsObject {
    return GetMingaDistrictResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!GetMingaDistrictResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: GetMingaDistrictResponse) {
    var f: any;
    var obj: any = {
      district: (f = msg.getDistrict()) && MingaDistrict.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!GetMingaDistrictResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new GetMingaDistrictResponse;
    return GetMingaDistrictResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!GetMingaDistrictResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!GetMingaDistrictResponse}
   */
  static deserializeBinaryFromReader(msg: GetMingaDistrictResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new MingaDistrict;
        reader.readMessage(value1,MingaDistrict.deserializeBinaryFromReader);
        msg.setDistrict(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!GetMingaDistrictResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: GetMingaDistrictResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getDistrict();
    if (f != null) {
      writer.writeMessage(
        1,
        f,
        MingaDistrict.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    GetMingaDistrictResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional MingaDistrict district = 1;
   * @return {?MingaDistrict}
   */
  getDistrict(): MingaDistrict {
    return /** @type{?MingaDistrict} */ (
      jspb.Message.getWrapperField(this, MingaDistrict, 1));
  }


  /** @param {?MingaDistrict|undefined} value */
  setDistrict(value?: MingaDistrict) {
    jspb.Message.setWrapperField(this, 1, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearDistrict() {
    this.setDistrict(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasDistrict(): boolean {
    return jspb.Message.getField(this, 1) != null;
  }


}
export namespace ExportDistrictsRequest {
  export interface AsObject {
  }
}
export class ExportDistrictsRequest extends jspb.Message {
  static readonly displayName = "ExportDistrictsRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): ExportDistrictsRequest.AsObject {
    return ExportDistrictsRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!ExportDistrictsRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: ExportDistrictsRequest) {
    var f: any;
    var obj: any = {

    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!ExportDistrictsRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new ExportDistrictsRequest;
    return ExportDistrictsRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!ExportDistrictsRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!ExportDistrictsRequest}
   */
  static deserializeBinaryFromReader(msg: ExportDistrictsRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!ExportDistrictsRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: ExportDistrictsRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    ExportDistrictsRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
}
export namespace ExportDistrictsChunk {
  export interface AsObject {
    chunk?: (string|Uint8Array);
    filename: string;
  }
}
export class ExportDistrictsChunk extends jspb.Message {
  static readonly displayName = "ExportDistrictsChunk";
  /**
   * Oneof group definitions for this message. Each group defines the field
   * numbers belonging to that group. When of these fields' value is set, all
   * other fields in the group are cleared. During deserialization, if multiple
   * fields are encountered for a group, only the last value seen will be kept.
   * @private {!Array<!Array<number>>}
   * @const
   */
  static readonly oneofGroups_ = [[1,2]];


  /**
   * @return {ExportDistrictsChunk.InfoCase}
   */
  getInfoCase() {
    return /** @type {ExportDistrictsChunk.InfoCase} */(jspb.Message.computeOneofCase(this, ExportDistrictsChunk.oneofGroups_[0]));
  }

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, ExportDistrictsChunk.oneofGroups_);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): ExportDistrictsChunk.AsObject {
    return ExportDistrictsChunk.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!ExportDistrictsChunk} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: ExportDistrictsChunk) {
    var f: any;
    var obj: any = {
      chunk: msg.getChunk_asB64(),
      filename: jspb.Message.getFieldWithDefault(msg, 2, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!ExportDistrictsChunk}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new ExportDistrictsChunk;
    return ExportDistrictsChunk.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!ExportDistrictsChunk} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!ExportDistrictsChunk}
   */
  static deserializeBinaryFromReader(msg: ExportDistrictsChunk, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {!Uint8Array} */ (reader.readBytes());
        msg.setChunk(value1);
        break;
      case 2:
        var value2 = /** @type {string} */ (reader.readString());
        msg.setFilename(value2);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!ExportDistrictsChunk} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: ExportDistrictsChunk, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 1));
    if (f != null) {
      writer.writeBytes(
        1,
        f
      );
    }
    f = /** @type {string} */ (jspb.Message.getField(message, 2));
    if (f != null) {
      writer.writeString(
        2,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    ExportDistrictsChunk.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional bytes chunk = 1;
   * @return {!(string|Uint8Array)}
   */
  getChunk(): (string|Uint8Array) {
    return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /**
   * optional bytes chunk = 1;
   * This is a type-conversion wrapper around `getChunk()`
   * @return {string}
   */
  getChunk_asB64() {
    return /** @type {string} */ (jspb.Message.bytesAsB64(
        this.getChunk()));
  }


  /**
   * optional bytes chunk = 1;
   * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getChunk()`
   * @return {!Uint8Array}
   */
  getChunk_asU8() {
    return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
        this.getChunk()));
  }


  /** @param {!(string|Uint8Array)} value */
  setChunk(value: (string|Uint8Array)) {
    jspb.Message.setOneofField(this, 1, ExportDistrictsChunk.oneofGroups_[0], value);
  }


  /**
   * Clears the field making it undefined.
   */
  clearChunk() {
    jspb.Message.setOneofField(this, 1, ExportDistrictsChunk.oneofGroups_[0], undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasChunk(): boolean {
    return jspb.Message.getField(this, 1) != null;
  }


  /**
   * optional string filename = 2;
   * @return {string}
   */
  getFilename(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
  };


  /** @param {string} value */
  setFilename(value: string) {
    jspb.Message.setOneofField(this, 2, ExportDistrictsChunk.oneofGroups_[0], value);
  }


  /**
   * Clears the field making it undefined.
   */
  clearFilename() {
    jspb.Message.setOneofField(this, 2, ExportDistrictsChunk.oneofGroups_[0], undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasFilename(): boolean {
    return jspb.Message.getField(this, 2) != null;
  }


}
export namespace ExportDistrictsChunk {
export enum InfoCase {
  INFO_NOT_SET = 0,
  CHUNK = 1,
  FILENAME = 2,
}
} // namespace ExportDistrictsChunk
export namespace DeleteMingaDistrictRequest {
  export interface AsObject {
    id: number;
  }
}
export class DeleteMingaDistrictRequest extends jspb.Message {
  static readonly displayName = "DeleteMingaDistrictRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): DeleteMingaDistrictRequest.AsObject {
    return DeleteMingaDistrictRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!DeleteMingaDistrictRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: DeleteMingaDistrictRequest) {
    var f: any;
    var obj: any = {
      id: jspb.Message.getFieldWithDefault(msg, 1, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!DeleteMingaDistrictRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new DeleteMingaDistrictRequest;
    return DeleteMingaDistrictRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!DeleteMingaDistrictRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!DeleteMingaDistrictRequest}
   */
  static deserializeBinaryFromReader(msg: DeleteMingaDistrictRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {number} */ (reader.readInt32());
        msg.setId(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!DeleteMingaDistrictRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: DeleteMingaDistrictRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getId();
    if (f !== 0) {
      writer.writeInt32(
        1,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    DeleteMingaDistrictRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional int32 id = 1;
   * @return {number}
   */
  getId(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
  };


  /** @param {number} value */
  setId(value: number) {
    jspb.Message.setProto3IntField(this, 1, value);
  }


}
export namespace DeleteMingaDistrictResponse {
  export interface AsObject {
    district?: MingaDistrict.AsObject;
  }
}
export class DeleteMingaDistrictResponse extends jspb.Message {
  static readonly displayName = "DeleteMingaDistrictResponse";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): DeleteMingaDistrictResponse.AsObject {
    return DeleteMingaDistrictResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!DeleteMingaDistrictResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: DeleteMingaDistrictResponse) {
    var f: any;
    var obj: any = {
      district: (f = msg.getDistrict()) && MingaDistrict.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!DeleteMingaDistrictResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new DeleteMingaDistrictResponse;
    return DeleteMingaDistrictResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!DeleteMingaDistrictResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!DeleteMingaDistrictResponse}
   */
  static deserializeBinaryFromReader(msg: DeleteMingaDistrictResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new MingaDistrict;
        reader.readMessage(value1,MingaDistrict.deserializeBinaryFromReader);
        msg.setDistrict(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!DeleteMingaDistrictResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: DeleteMingaDistrictResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getDistrict();
    if (f != null) {
      writer.writeMessage(
        1,
        f,
        MingaDistrict.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    DeleteMingaDistrictResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional MingaDistrict district = 1;
   * @return {?MingaDistrict}
   */
  getDistrict(): MingaDistrict {
    return /** @type{?MingaDistrict} */ (
      jspb.Message.getWrapperField(this, MingaDistrict, 1));
  }


  /** @param {?MingaDistrict|undefined} value */
  setDistrict(value?: MingaDistrict) {
    jspb.Message.setWrapperField(this, 1, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearDistrict() {
    this.setDistrict(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasDistrict(): boolean {
    return jspb.Message.getField(this, 1) != null;
  }


}
export namespace GetMingaDistrictsRequest {
  export interface AsObject {
    limit: number;
    offset: number;
  }
}
export class GetMingaDistrictsRequest extends jspb.Message {
  static readonly displayName = "GetMingaDistrictsRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): GetMingaDistrictsRequest.AsObject {
    return GetMingaDistrictsRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!GetMingaDistrictsRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: GetMingaDistrictsRequest) {
    var f: any;
    var obj: any = {
      limit: jspb.Message.getFieldWithDefault(msg, 1, 0),
      offset: jspb.Message.getFieldWithDefault(msg, 2, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!GetMingaDistrictsRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new GetMingaDistrictsRequest;
    return GetMingaDistrictsRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!GetMingaDistrictsRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!GetMingaDistrictsRequest}
   */
  static deserializeBinaryFromReader(msg: GetMingaDistrictsRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {number} */ (reader.readInt32());
        msg.setLimit(value1);
        break;
      case 2:
        var value2 = /** @type {number} */ (reader.readInt32());
        msg.setOffset(value2);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!GetMingaDistrictsRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: GetMingaDistrictsRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getLimit();
    if (f !== 0) {
      writer.writeInt32(
        1,
        f
      );
    }
    f = message.getOffset();
    if (f !== 0) {
      writer.writeInt32(
        2,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    GetMingaDistrictsRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional int32 limit = 1;
   * @return {number}
   */
  getLimit(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
  };


  /** @param {number} value */
  setLimit(value: number) {
    jspb.Message.setProto3IntField(this, 1, value);
  }


  /**
   * optional int32 offset = 2;
   * @return {number}
   */
  getOffset(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
  };


  /** @param {number} value */
  setOffset(value: number) {
    jspb.Message.setProto3IntField(this, 2, value);
  }


}
export namespace GetMingaDistrictsResponse {
  export interface AsObject {
    districtsList: MingaDistrict.AsObject[];
  }
}
export class GetMingaDistrictsResponse extends jspb.Message {
  static readonly displayName = "GetMingaDistrictsResponse";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [1];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, GetMingaDistrictsResponse.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): GetMingaDistrictsResponse.AsObject {
    return GetMingaDistrictsResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!GetMingaDistrictsResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: GetMingaDistrictsResponse) {
    var f: any;
    var obj: any = {
      districtsList: jspb.Message.toObjectList(msg.getDistrictsList(),
      MingaDistrict.toObject, includeInstance)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!GetMingaDistrictsResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new GetMingaDistrictsResponse;
    return GetMingaDistrictsResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!GetMingaDistrictsResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!GetMingaDistrictsResponse}
   */
  static deserializeBinaryFromReader(msg: GetMingaDistrictsResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new MingaDistrict;
        reader.readMessage(value1,MingaDistrict.deserializeBinaryFromReader);
        msg.addDistricts(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!GetMingaDistrictsResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: GetMingaDistrictsResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getDistrictsList();
    if (f.length > 0) {
      writer.writeRepeatedMessage(
        1,
        f,
        MingaDistrict.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    GetMingaDistrictsResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * repeated MingaDistrict districts = 1;
   * @return {!Array<!MingaDistrict>}
   */
  getDistrictsList(): MingaDistrict[] {
    return /** @type{!Array<!MingaDistrict>} */ (
      jspb.Message.getRepeatedWrapperField(this, MingaDistrict, 1));
  }


  /** @param {!Array<!MingaDistrict>} value */
  setDistrictsList(value?: MingaDistrict[]) {
    jspb.Message.setRepeatedWrapperField(this, 1, value);
  }


  /**
   * @param {!MingaDistrict=} opt_value
   * @param {number=} opt_index
   * @return {!MingaDistrict}
   */
  addDistricts(opt_value?: MingaDistrict, opt_index?: number): MingaDistrict {
    return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, MingaDistrict, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearDistrictsList() {
    this.setDistrictsList([]);
  }


}
export namespace UpsertMingaDistrictRequest {
  export interface AsObject {
    district?: MingaDistrict.AsObject;
  }
}
export class UpsertMingaDistrictRequest extends jspb.Message {
  static readonly displayName = "UpsertMingaDistrictRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): UpsertMingaDistrictRequest.AsObject {
    return UpsertMingaDistrictRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!UpsertMingaDistrictRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: UpsertMingaDistrictRequest) {
    var f: any;
    var obj: any = {
      district: (f = msg.getDistrict()) && MingaDistrict.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!UpsertMingaDistrictRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new UpsertMingaDistrictRequest;
    return UpsertMingaDistrictRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!UpsertMingaDistrictRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!UpsertMingaDistrictRequest}
   */
  static deserializeBinaryFromReader(msg: UpsertMingaDistrictRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new MingaDistrict;
        reader.readMessage(value1,MingaDistrict.deserializeBinaryFromReader);
        msg.setDistrict(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!UpsertMingaDistrictRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: UpsertMingaDistrictRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getDistrict();
    if (f != null) {
      writer.writeMessage(
        1,
        f,
        MingaDistrict.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    UpsertMingaDistrictRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional MingaDistrict district = 1;
   * @return {?MingaDistrict}
   */
  getDistrict(): MingaDistrict {
    return /** @type{?MingaDistrict} */ (
      jspb.Message.getWrapperField(this, MingaDistrict, 1));
  }


  /** @param {?MingaDistrict|undefined} value */
  setDistrict(value?: MingaDistrict) {
    jspb.Message.setWrapperField(this, 1, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearDistrict() {
    this.setDistrict(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasDistrict(): boolean {
    return jspb.Message.getField(this, 1) != null;
  }


}
export namespace UpsertMingaDistrictResponse {
  export interface AsObject {
    district?: MingaDistrict.AsObject;
  }
}
export class UpsertMingaDistrictResponse extends jspb.Message {
  static readonly displayName = "UpsertMingaDistrictResponse";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): UpsertMingaDistrictResponse.AsObject {
    return UpsertMingaDistrictResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!UpsertMingaDistrictResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: UpsertMingaDistrictResponse) {
    var f: any;
    var obj: any = {
      district: (f = msg.getDistrict()) && MingaDistrict.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!UpsertMingaDistrictResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new UpsertMingaDistrictResponse;
    return UpsertMingaDistrictResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!UpsertMingaDistrictResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!UpsertMingaDistrictResponse}
   */
  static deserializeBinaryFromReader(msg: UpsertMingaDistrictResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new MingaDistrict;
        reader.readMessage(value1,MingaDistrict.deserializeBinaryFromReader);
        msg.setDistrict(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!UpsertMingaDistrictResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: UpsertMingaDistrictResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getDistrict();
    if (f != null) {
      writer.writeMessage(
        1,
        f,
        MingaDistrict.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    UpsertMingaDistrictResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional MingaDistrict district = 1;
   * @return {?MingaDistrict}
   */
  getDistrict(): MingaDistrict {
    return /** @type{?MingaDistrict} */ (
      jspb.Message.getWrapperField(this, MingaDistrict, 1));
  }


  /** @param {?MingaDistrict|undefined} value */
  setDistrict(value?: MingaDistrict) {
    jspb.Message.setWrapperField(this, 1, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearDistrict() {
    this.setDistrict(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasDistrict(): boolean {
    return jspb.Message.getField(this, 1) != null;
  }


}
export namespace PauseMingaRequest {
  export interface AsObject {
    mingaHash: string;
    paused: boolean;
  }
}
export class PauseMingaRequest extends jspb.Message {
  static readonly displayName = "PauseMingaRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): PauseMingaRequest.AsObject {
    return PauseMingaRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!PauseMingaRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: PauseMingaRequest) {
    var f: any;
    var obj: any = {
      mingaHash: jspb.Message.getFieldWithDefault(msg, 1, ""),
      paused: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!PauseMingaRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new PauseMingaRequest;
    return PauseMingaRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!PauseMingaRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!PauseMingaRequest}
   */
  static deserializeBinaryFromReader(msg: PauseMingaRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setMingaHash(value1);
        break;
      case 2:
        var value2 = /** @type {boolean} */ (reader.readBool());
        msg.setPaused(value2);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!PauseMingaRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: PauseMingaRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getMingaHash();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
    f = message.getPaused();
    if (f) {
      writer.writeBool(
        2,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    PauseMingaRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string minga_hash = 1;
   * @return {string}
   */
  getMingaHash(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setMingaHash(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


  /**
   * optional bool paused = 2;
   * @return {boolean}
   */
  getPaused(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
  };


  /** @param {boolean} value */
  setPaused(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 2, value);
  }


}
export namespace PauseMingaResponse {
  export interface AsObject {
  }
}
export class PauseMingaResponse extends jspb.Message {
  static readonly displayName = "PauseMingaResponse";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): PauseMingaResponse.AsObject {
    return PauseMingaResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!PauseMingaResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: PauseMingaResponse) {
    var f: any;
    var obj: any = {

    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!PauseMingaResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new PauseMingaResponse;
    return PauseMingaResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!PauseMingaResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!PauseMingaResponse}
   */
  static deserializeBinaryFromReader(msg: PauseMingaResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!PauseMingaResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: PauseMingaResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    PauseMingaResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
}
export namespace GetPublicMingaCampInfoRequest {
  export interface AsObject {
    mingaHash: string;
  }
}
export class GetPublicMingaCampInfoRequest extends jspb.Message {
  static readonly displayName = "GetPublicMingaCampInfoRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): GetPublicMingaCampInfoRequest.AsObject {
    return GetPublicMingaCampInfoRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!GetPublicMingaCampInfoRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: GetPublicMingaCampInfoRequest) {
    var f: any;
    var obj: any = {
      mingaHash: jspb.Message.getFieldWithDefault(msg, 1, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!GetPublicMingaCampInfoRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new GetPublicMingaCampInfoRequest;
    return GetPublicMingaCampInfoRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!GetPublicMingaCampInfoRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!GetPublicMingaCampInfoRequest}
   */
  static deserializeBinaryFromReader(msg: GetPublicMingaCampInfoRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setMingaHash(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!GetPublicMingaCampInfoRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: GetPublicMingaCampInfoRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getMingaHash();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    GetPublicMingaCampInfoRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string minga_hash = 1;
   * @return {string}
   */
  getMingaHash(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setMingaHash(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


}
export namespace GetPublicMingaCampInfoResponse {
  export interface AsObject {
    bannerImage?: image_image_pb.ImageInfo.AsObject;
    description: string;
  }
}
export class GetPublicMingaCampInfoResponse extends jspb.Message {
  static readonly displayName = "GetPublicMingaCampInfoResponse";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): GetPublicMingaCampInfoResponse.AsObject {
    return GetPublicMingaCampInfoResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!GetPublicMingaCampInfoResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: GetPublicMingaCampInfoResponse) {
    var f: any;
    var obj: any = {
      bannerImage: (f = msg.getBannerImage()) && image_image_pb.ImageInfo.toObject(includeInstance, f),
      description: jspb.Message.getFieldWithDefault(msg, 2, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!GetPublicMingaCampInfoResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new GetPublicMingaCampInfoResponse;
    return GetPublicMingaCampInfoResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!GetPublicMingaCampInfoResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!GetPublicMingaCampInfoResponse}
   */
  static deserializeBinaryFromReader(msg: GetPublicMingaCampInfoResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new image_image_pb.ImageInfo;
        reader.readMessage(value1,image_image_pb.ImageInfo.deserializeBinaryFromReader);
        msg.setBannerImage(value1);
        break;
      case 2:
        var value2 = /** @type {string} */ (reader.readString());
        msg.setDescription(value2);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!GetPublicMingaCampInfoResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: GetPublicMingaCampInfoResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getBannerImage();
    if (f != null) {
      writer.writeMessage(
        1,
        f,
        image_image_pb.ImageInfo.serializeBinaryToWriter
      );
    }
    f = message.getDescription();
    if (f.length > 0) {
      writer.writeString(
        2,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    GetPublicMingaCampInfoResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional ImageInfo banner_image = 1;
   * @return {?ImageInfo}
   */
  getBannerImage(): image_image_pb.ImageInfo {
    return /** @type{?ImageInfo} */ (
      jspb.Message.getWrapperField(this, image_image_pb.ImageInfo, 1));
  }


  /** @param {?ImageInfo|undefined} value */
  setBannerImage(value?: image_image_pb.ImageInfo) {
    jspb.Message.setWrapperField(this, 1, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearBannerImage() {
    this.setBannerImage(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasBannerImage(): boolean {
    return jspb.Message.getField(this, 1) != null;
  }


  /**
   * optional string description = 2;
   * @return {string}
   */
  getDescription(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
  };


  /** @param {string} value */
  setDescription(value: string) {
    jspb.Message.setProto3StringField(this, 2, value);
  }


}
export namespace CreateFilesRequest {
  export interface AsObject {
    fileList: gateway_file_pb.FileInfo.AsObject[];
  }
}
export class CreateFilesRequest extends jspb.Message {
  static readonly displayName = "CreateFilesRequest";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [3];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, CreateFilesRequest.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): CreateFilesRequest.AsObject {
    return CreateFilesRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!CreateFilesRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: CreateFilesRequest) {
    var f: any;
    var obj: any = {
      fileList: jspb.Message.toObjectList(msg.getFileList(),
      gateway_file_pb.FileInfo.toObject, includeInstance)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!CreateFilesRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new CreateFilesRequest;
    return CreateFilesRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!CreateFilesRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!CreateFilesRequest}
   */
  static deserializeBinaryFromReader(msg: CreateFilesRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 3:
        var value3 = new gateway_file_pb.FileInfo;
        reader.readMessage(value3,gateway_file_pb.FileInfo.deserializeBinaryFromReader);
        msg.addFile(value3);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!CreateFilesRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: CreateFilesRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getFileList();
    if (f.length > 0) {
      writer.writeRepeatedMessage(
        3,
        f,
        gateway_file_pb.FileInfo.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    CreateFilesRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * repeated FileInfo file = 3;
   * @return {!Array<!FileInfo>}
   */
  getFileList(): gateway_file_pb.FileInfo[] {
    return /** @type{!Array<!FileInfo>} */ (
      jspb.Message.getRepeatedWrapperField(this, gateway_file_pb.FileInfo, 3));
  }


  /** @param {!Array<!FileInfo>} value */
  setFileList(value?: gateway_file_pb.FileInfo[]) {
    jspb.Message.setRepeatedWrapperField(this, 3, value);
  }


  /**
   * @param {!FileInfo=} opt_value
   * @param {number=} opt_index
   * @return {!FileInfo}
   */
  addFile(opt_value?: gateway_file_pb.FileInfo, opt_index?: number): gateway_file_pb.FileInfo {
    return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, gateway_file_pb.FileInfo, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearFileList() {
    this.setFileList([]);
  }


}
export namespace RemoveFileRequest {
  export interface AsObject {
    fileHash: string;
  }
}
export class RemoveFileRequest extends jspb.Message {
  static readonly displayName = "RemoveFileRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): RemoveFileRequest.AsObject {
    return RemoveFileRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!RemoveFileRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: RemoveFileRequest) {
    var f: any;
    var obj: any = {
      fileHash: jspb.Message.getFieldWithDefault(msg, 1, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!RemoveFileRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new RemoveFileRequest;
    return RemoveFileRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!RemoveFileRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!RemoveFileRequest}
   */
  static deserializeBinaryFromReader(msg: RemoveFileRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setFileHash(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!RemoveFileRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: RemoveFileRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getFileHash();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    RemoveFileRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string file_hash = 1;
   * @return {string}
   */
  getFileHash(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setFileHash(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


}
export namespace MingaDashboardRequest {
  export interface AsObject {
    filterMingaTypeList: string[];
    filterMingaStatusList: string[];
    filterMingaPaused?: MingaDashboardRequest.BooleanFilter;
    sortByDailyActiveCount?: MingaDashboardRequest.SortOrder;
    sortByWeeklyActiveCount?: MingaDashboardRequest.SortOrder;
    searchText: string;
    filterMingaSubscriptionsList: string[];
  }
}
export class MingaDashboardRequest extends jspb.Message {
  static readonly displayName = "MingaDashboardRequest";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [1,2,7];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, MingaDashboardRequest.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): MingaDashboardRequest.AsObject {
    return MingaDashboardRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!MingaDashboardRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: MingaDashboardRequest) {
    var f: any;
    var obj: any = {
      filterMingaTypeList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
      filterMingaStatusList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
      filterMingaPaused: jspb.Message.getFieldWithDefault(msg, 3, 0),
      sortByDailyActiveCount: jspb.Message.getFieldWithDefault(msg, 4, 0),
      sortByWeeklyActiveCount: jspb.Message.getFieldWithDefault(msg, 5, 0),
      searchText: jspb.Message.getFieldWithDefault(msg, 6, ""),
      filterMingaSubscriptionsList: (f = jspb.Message.getRepeatedField(msg, 7)) == null ? undefined : f
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!MingaDashboardRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new MingaDashboardRequest;
    return MingaDashboardRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!MingaDashboardRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!MingaDashboardRequest}
   */
  static deserializeBinaryFromReader(msg: MingaDashboardRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.addFilterMingaType(value1);
        break;
      case 2:
        var value2 = /** @type {string} */ (reader.readString());
        msg.addFilterMingaStatus(value2);
        break;
      case 3:
        var value3 = /** @type {!MingaDashboardRequest.BooleanFilter} */ (reader.readEnum());
        msg.setFilterMingaPaused(value3);
        break;
      case 4:
        var value4 = /** @type {!MingaDashboardRequest.SortOrder} */ (reader.readEnum());
        msg.setSortByDailyActiveCount(value4);
        break;
      case 5:
        var value5 = /** @type {!MingaDashboardRequest.SortOrder} */ (reader.readEnum());
        msg.setSortByWeeklyActiveCount(value5);
        break;
      case 6:
        var value6 = /** @type {string} */ (reader.readString());
        msg.setSearchText(value6);
        break;
      case 7:
        var value7 = /** @type {string} */ (reader.readString());
        msg.addFilterMingaSubscriptions(value7);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!MingaDashboardRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: MingaDashboardRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getFilterMingaTypeList();
    if (f.length > 0) {
      writer.writeRepeatedString(
        1,
        f
      );
    }
    f = message.getFilterMingaStatusList();
    if (f.length > 0) {
      writer.writeRepeatedString(
        2,
        f
      );
    }
    f = message.getFilterMingaPaused();
    if (f !== 0.0) {
      writer.writeEnum(
        3,
        f
      );
    }
    f = message.getSortByDailyActiveCount();
    if (f !== 0.0) {
      writer.writeEnum(
        4,
        f
      );
    }
    f = message.getSortByWeeklyActiveCount();
    if (f !== 0.0) {
      writer.writeEnum(
        5,
        f
      );
    }
    f = message.getSearchText();
    if (f.length > 0) {
      writer.writeString(
        6,
        f
      );
    }
    f = message.getFilterMingaSubscriptionsList();
    if (f.length > 0) {
      writer.writeRepeatedString(
        7,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    MingaDashboardRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * repeated string filter_minga_type = 1;
   * @return {!Array<string>}
   */
  getFilterMingaTypeList(): string[] {
    return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
  };


  /** @param {!Array<string>} value */
  setFilterMingaTypeList(value: string[]) {
    jspb.Message.setField(this, 1, value || []);
  }


  /**
   * @param {string} value
   * @param {number=} opt_index
   */
  addFilterMingaType(value: string, opt_index?: number): void {
    jspb.Message.addToRepeatedField(this, 1, value, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearFilterMingaTypeList() {
    this.setFilterMingaTypeList([]);
  }


  /**
   * repeated string filter_minga_status = 2;
   * @return {!Array<string>}
   */
  getFilterMingaStatusList(): string[] {
    return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
  };


  /** @param {!Array<string>} value */
  setFilterMingaStatusList(value: string[]) {
    jspb.Message.setField(this, 2, value || []);
  }


  /**
   * @param {string} value
   * @param {number=} opt_index
   */
  addFilterMingaStatus(value: string, opt_index?: number): void {
    jspb.Message.addToRepeatedField(this, 2, value, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearFilterMingaStatusList() {
    this.setFilterMingaStatusList([]);
  }


  /**
   * optional BooleanFilter filter_minga_paused = 3;
   * @return {!MingaDashboardRequest.BooleanFilter}
   */
  getFilterMingaPaused(): MingaDashboardRequest.BooleanFilter {
    return /** @type {!MingaDashboardRequest.BooleanFilter} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
  };


  /** @param {!MingaDashboardRequest.BooleanFilter} value */
  setFilterMingaPaused(value: MingaDashboardRequest.BooleanFilter) {
    jspb.Message.setProto3EnumField(this, 3, value);
  }


  /**
   * optional SortOrder sort_by_daily_active_count = 4;
   * @return {!MingaDashboardRequest.SortOrder}
   */
  getSortByDailyActiveCount(): MingaDashboardRequest.SortOrder {
    return /** @type {!MingaDashboardRequest.SortOrder} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
  };


  /** @param {!MingaDashboardRequest.SortOrder} value */
  setSortByDailyActiveCount(value: MingaDashboardRequest.SortOrder) {
    jspb.Message.setProto3EnumField(this, 4, value);
  }


  /**
   * optional SortOrder sort_by_weekly_active_count = 5;
   * @return {!MingaDashboardRequest.SortOrder}
   */
  getSortByWeeklyActiveCount(): MingaDashboardRequest.SortOrder {
    return /** @type {!MingaDashboardRequest.SortOrder} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
  };


  /** @param {!MingaDashboardRequest.SortOrder} value */
  setSortByWeeklyActiveCount(value: MingaDashboardRequest.SortOrder) {
    jspb.Message.setProto3EnumField(this, 5, value);
  }


  /**
   * optional string search_text = 6;
   * @return {string}
   */
  getSearchText(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
  };


  /** @param {string} value */
  setSearchText(value: string) {
    jspb.Message.setProto3StringField(this, 6, value);
  }


  /**
   * repeated string filter_minga_subscriptions = 7;
   * @return {!Array<string>}
   */
  getFilterMingaSubscriptionsList(): string[] {
    return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 7));
  };


  /** @param {!Array<string>} value */
  setFilterMingaSubscriptionsList(value: string[]) {
    jspb.Message.setField(this, 7, value || []);
  }


  /**
   * @param {string} value
   * @param {number=} opt_index
   */
  addFilterMingaSubscriptions(value: string, opt_index?: number): void {
    jspb.Message.addToRepeatedField(this, 7, value, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearFilterMingaSubscriptionsList() {
    this.setFilterMingaSubscriptionsList([]);
  }


}
export namespace MingaDashboardRequest {
export enum BooleanFilter {
  UNSET = 0,
  ON = 1,
  OFF = 2,
}
} // namespace MingaDashboardRequest
export namespace MingaDashboardRequest {
export enum SortOrder {
  NONE = 0,
  ASC = 1,
  DESC = 2,
}
} // namespace MingaDashboardRequest
export namespace MingaDashboardResponse {
  export interface AsObject {
    mingaList: MingaDashboardInfo.AsObject[];
  }
}
export class MingaDashboardResponse extends jspb.Message {
  static readonly displayName = "MingaDashboardResponse";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [1];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, MingaDashboardResponse.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): MingaDashboardResponse.AsObject {
    return MingaDashboardResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!MingaDashboardResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: MingaDashboardResponse) {
    var f: any;
    var obj: any = {
      mingaList: jspb.Message.toObjectList(msg.getMingaList(),
      MingaDashboardInfo.toObject, includeInstance)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!MingaDashboardResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new MingaDashboardResponse;
    return MingaDashboardResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!MingaDashboardResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!MingaDashboardResponse}
   */
  static deserializeBinaryFromReader(msg: MingaDashboardResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new MingaDashboardInfo;
        reader.readMessage(value1,MingaDashboardInfo.deserializeBinaryFromReader);
        msg.addMinga(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!MingaDashboardResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: MingaDashboardResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getMingaList();
    if (f.length > 0) {
      writer.writeRepeatedMessage(
        1,
        f,
        MingaDashboardInfo.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    MingaDashboardResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * repeated MingaDashboardInfo minga = 1;
   * @return {!Array<!MingaDashboardInfo>}
   */
  getMingaList(): MingaDashboardInfo[] {
    return /** @type{!Array<!MingaDashboardInfo>} */ (
      jspb.Message.getRepeatedWrapperField(this, MingaDashboardInfo, 1));
  }


  /** @param {!Array<!MingaDashboardInfo>} value */
  setMingaList(value?: MingaDashboardInfo[]) {
    jspb.Message.setRepeatedWrapperField(this, 1, value);
  }


  /**
   * @param {!MingaDashboardInfo=} opt_value
   * @param {number=} opt_index
   * @return {!MingaDashboardInfo}
   */
  addMinga(opt_value?: MingaDashboardInfo, opt_index?: number): MingaDashboardInfo {
    return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, MingaDashboardInfo, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearMingaList() {
    this.setMingaList([]);
  }


}
export namespace MingaDashboardRequest2 {
  export interface AsObject {
    name: string;
    district: string;
    partner: string;
    state: string;
    statusList: MingaStatus[];
    subscriptionList: MingaSubscriptionPlan[];
  }
}
export class MingaDashboardRequest2 extends jspb.Message {
  static readonly displayName = "MingaDashboardRequest2";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [5,6];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, MingaDashboardRequest2.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): MingaDashboardRequest2.AsObject {
    return MingaDashboardRequest2.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!MingaDashboardRequest2} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: MingaDashboardRequest2) {
    var f: any;
    var obj: any = {
      name: jspb.Message.getFieldWithDefault(msg, 1, ""),
      district: jspb.Message.getFieldWithDefault(msg, 2, ""),
      partner: jspb.Message.getFieldWithDefault(msg, 3, ""),
      state: jspb.Message.getFieldWithDefault(msg, 4, ""),
      statusList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f,
      subscriptionList: (f = jspb.Message.getRepeatedField(msg, 6)) == null ? undefined : f
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!MingaDashboardRequest2}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new MingaDashboardRequest2;
    return MingaDashboardRequest2.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!MingaDashboardRequest2} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!MingaDashboardRequest2}
   */
  static deserializeBinaryFromReader(msg: MingaDashboardRequest2, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setName(value1);
        break;
      case 2:
        var value2 = /** @type {string} */ (reader.readString());
        msg.setDistrict(value2);
        break;
      case 3:
        var value3 = /** @type {string} */ (reader.readString());
        msg.setPartner(value3);
        break;
      case 4:
        var value4 = /** @type {string} */ (reader.readString());
        msg.setState(value4);
        break;
      case 5:
        var value5 = /** @type {!Array<!MingaStatus>} */ (reader.readPackedEnum());
        msg.setStatusList(value5);
        break;
      case 6:
        var value6 = /** @type {!Array<!MingaSubscriptionPlan>} */ (reader.readPackedEnum());
        msg.setSubscriptionList(value6);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!MingaDashboardRequest2} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: MingaDashboardRequest2, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getName();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
    f = message.getDistrict();
    if (f.length > 0) {
      writer.writeString(
        2,
        f
      );
    }
    f = message.getPartner();
    if (f.length > 0) {
      writer.writeString(
        3,
        f
      );
    }
    f = message.getState();
    if (f.length > 0) {
      writer.writeString(
        4,
        f
      );
    }
    f = message.getStatusList();
    if (f.length > 0) {
      writer.writePackedEnum(
        5,
        f
      );
    }
    f = message.getSubscriptionList();
    if (f.length > 0) {
      writer.writePackedEnum(
        6,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    MingaDashboardRequest2.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string name = 1;
   * @return {string}
   */
  getName(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setName(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


  /**
   * optional string district = 2;
   * @return {string}
   */
  getDistrict(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
  };


  /** @param {string} value */
  setDistrict(value: string) {
    jspb.Message.setProto3StringField(this, 2, value);
  }


  /**
   * optional string partner = 3;
   * @return {string}
   */
  getPartner(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
  };


  /** @param {string} value */
  setPartner(value: string) {
    jspb.Message.setProto3StringField(this, 3, value);
  }


  /**
   * optional string state = 4;
   * @return {string}
   */
  getState(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
  };


  /** @param {string} value */
  setState(value: string) {
    jspb.Message.setProto3StringField(this, 4, value);
  }


  /**
   * repeated MingaStatus status = 5;
   * @return {!Array<!MingaStatus>}
   */
  getStatusList(): MingaStatus[] {
    return /** @type {!Array<!MingaStatus>} */ (jspb.Message.getRepeatedField(this, 5));
  };


  /** @param {!Array<!MingaStatus>} value */
  setStatusList(value: MingaStatus[]) {
    jspb.Message.setField(this, 5, value || []);
  }


  /**
   * @param {!MingaStatus} value
   * @param {number=} opt_index
   */
  addStatus(value: MingaStatus, opt_index?: number): void {
    jspb.Message.addToRepeatedField(this, 5, value, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearStatusList() {
    this.setStatusList([]);
  }


  /**
   * repeated MingaSubscriptionPlan subscription = 6;
   * @return {!Array<!MingaSubscriptionPlan>}
   */
  getSubscriptionList(): MingaSubscriptionPlan[] {
    return /** @type {!Array<!MingaSubscriptionPlan>} */ (jspb.Message.getRepeatedField(this, 6));
  };


  /** @param {!Array<!MingaSubscriptionPlan>} value */
  setSubscriptionList(value: MingaSubscriptionPlan[]) {
    jspb.Message.setField(this, 6, value || []);
  }


  /**
   * @param {!MingaSubscriptionPlan} value
   * @param {number=} opt_index
   */
  addSubscription(value: MingaSubscriptionPlan, opt_index?: number): void {
    jspb.Message.addToRepeatedField(this, 6, value, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearSubscriptionList() {
    this.setSubscriptionList([]);
  }


}
export namespace MingaDashboardResponse2 {
  export interface AsObject {
    mingaList: MingaDashboardInfo2.AsObject[];
  }
}
export class MingaDashboardResponse2 extends jspb.Message {
  static readonly displayName = "MingaDashboardResponse2";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [1];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, MingaDashboardResponse2.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): MingaDashboardResponse2.AsObject {
    return MingaDashboardResponse2.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!MingaDashboardResponse2} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: MingaDashboardResponse2) {
    var f: any;
    var obj: any = {
      mingaList: jspb.Message.toObjectList(msg.getMingaList(),
      MingaDashboardInfo2.toObject, includeInstance)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!MingaDashboardResponse2}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new MingaDashboardResponse2;
    return MingaDashboardResponse2.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!MingaDashboardResponse2} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!MingaDashboardResponse2}
   */
  static deserializeBinaryFromReader(msg: MingaDashboardResponse2, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new MingaDashboardInfo2;
        reader.readMessage(value1,MingaDashboardInfo2.deserializeBinaryFromReader);
        msg.addMinga(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!MingaDashboardResponse2} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: MingaDashboardResponse2, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getMingaList();
    if (f.length > 0) {
      writer.writeRepeatedMessage(
        1,
        f,
        MingaDashboardInfo2.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    MingaDashboardResponse2.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * repeated MingaDashboardInfo2 minga = 1;
   * @return {!Array<!MingaDashboardInfo2>}
   */
  getMingaList(): MingaDashboardInfo2[] {
    return /** @type{!Array<!MingaDashboardInfo2>} */ (
      jspb.Message.getRepeatedWrapperField(this, MingaDashboardInfo2, 1));
  }


  /** @param {!Array<!MingaDashboardInfo2>} value */
  setMingaList(value?: MingaDashboardInfo2[]) {
    jspb.Message.setRepeatedWrapperField(this, 1, value);
  }


  /**
   * @param {!MingaDashboardInfo2=} opt_value
   * @param {number=} opt_index
   * @return {!MingaDashboardInfo2}
   */
  addMinga(opt_value?: MingaDashboardInfo2, opt_index?: number): MingaDashboardInfo2 {
    return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, MingaDashboardInfo2, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearMingaList() {
    this.setMingaList([]);
  }


}
export namespace GetMingaReportRequest {
  export interface AsObject {
    groupBy?: common_graph_pb.GraphGroupBy;
    mingaHash: string;
    district: string;
    partner: string;
    state: string;
    statusList: string[];
    subscriptionList: string[];
    type?: MingaReportType;
    startDate?: common_date_pb.Date.AsObject;
    endDate?: common_date_pb.Date.AsObject;
    modulesList: string[];
  }
}
export class GetMingaReportRequest extends jspb.Message {
  static readonly displayName = "GetMingaReportRequest";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [6,7,11];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, GetMingaReportRequest.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): GetMingaReportRequest.AsObject {
    return GetMingaReportRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!GetMingaReportRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: GetMingaReportRequest) {
    var f: any;
    var obj: any = {
      groupBy: jspb.Message.getFieldWithDefault(msg, 1, 0),
      mingaHash: jspb.Message.getFieldWithDefault(msg, 2, ""),
      district: jspb.Message.getFieldWithDefault(msg, 3, ""),
      partner: jspb.Message.getFieldWithDefault(msg, 4, ""),
      state: jspb.Message.getFieldWithDefault(msg, 5, ""),
      statusList: (f = jspb.Message.getRepeatedField(msg, 6)) == null ? undefined : f,
      subscriptionList: (f = jspb.Message.getRepeatedField(msg, 7)) == null ? undefined : f,
      type: jspb.Message.getFieldWithDefault(msg, 8, 0),
      startDate: (f = msg.getStartDate()) && common_date_pb.Date.toObject(includeInstance, f),
      endDate: (f = msg.getEndDate()) && common_date_pb.Date.toObject(includeInstance, f),
      modulesList: (f = jspb.Message.getRepeatedField(msg, 11)) == null ? undefined : f
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!GetMingaReportRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new GetMingaReportRequest;
    return GetMingaReportRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!GetMingaReportRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!GetMingaReportRequest}
   */
  static deserializeBinaryFromReader(msg: GetMingaReportRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {!GraphGroupBy} */ (reader.readEnum());
        msg.setGroupBy(value1);
        break;
      case 2:
        var value2 = /** @type {string} */ (reader.readString());
        msg.setMingaHash(value2);
        break;
      case 3:
        var value3 = /** @type {string} */ (reader.readString());
        msg.setDistrict(value3);
        break;
      case 4:
        var value4 = /** @type {string} */ (reader.readString());
        msg.setPartner(value4);
        break;
      case 5:
        var value5 = /** @type {string} */ (reader.readString());
        msg.setState(value5);
        break;
      case 6:
        var value6 = /** @type {string} */ (reader.readString());
        msg.addStatus(value6);
        break;
      case 7:
        var value7 = /** @type {string} */ (reader.readString());
        msg.addSubscription(value7);
        break;
      case 8:
        var value8 = /** @type {!MingaReportType} */ (reader.readEnum());
        msg.setType(value8);
        break;
      case 9:
        var value9 = new common_date_pb.Date;
        reader.readMessage(value9,common_date_pb.Date.deserializeBinaryFromReader);
        msg.setStartDate(value9);
        break;
      case 10:
        var value10 = new common_date_pb.Date;
        reader.readMessage(value10,common_date_pb.Date.deserializeBinaryFromReader);
        msg.setEndDate(value10);
        break;
      case 11:
        var value11 = /** @type {string} */ (reader.readString());
        msg.addModules(value11);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!GetMingaReportRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: GetMingaReportRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getGroupBy();
    if (f !== 0.0) {
      writer.writeEnum(
        1,
        f
      );
    }
    f = message.getMingaHash();
    if (f.length > 0) {
      writer.writeString(
        2,
        f
      );
    }
    f = message.getDistrict();
    if (f.length > 0) {
      writer.writeString(
        3,
        f
      );
    }
    f = message.getPartner();
    if (f.length > 0) {
      writer.writeString(
        4,
        f
      );
    }
    f = message.getState();
    if (f.length > 0) {
      writer.writeString(
        5,
        f
      );
    }
    f = message.getStatusList();
    if (f.length > 0) {
      writer.writeRepeatedString(
        6,
        f
      );
    }
    f = message.getSubscriptionList();
    if (f.length > 0) {
      writer.writeRepeatedString(
        7,
        f
      );
    }
    f = message.getType();
    if (f !== 0.0) {
      writer.writeEnum(
        8,
        f
      );
    }
    f = message.getStartDate();
    if (f != null) {
      writer.writeMessage(
        9,
        f,
        common_date_pb.Date.serializeBinaryToWriter
      );
    }
    f = message.getEndDate();
    if (f != null) {
      writer.writeMessage(
        10,
        f,
        common_date_pb.Date.serializeBinaryToWriter
      );
    }
    f = message.getModulesList();
    if (f.length > 0) {
      writer.writeRepeatedString(
        11,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    GetMingaReportRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional minga.common.GraphGroupBy group_by = 1;
   * @return {!GraphGroupBy}
   */
  getGroupBy(): common_graph_pb.GraphGroupBy {
    return /** @type {!GraphGroupBy} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
  };


  /** @param {!GraphGroupBy} value */
  setGroupBy(value: common_graph_pb.GraphGroupBy) {
    jspb.Message.setProto3EnumField(this, 1, value);
  }


  /**
   * optional string minga_hash = 2;
   * @return {string}
   */
  getMingaHash(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
  };


  /** @param {string} value */
  setMingaHash(value: string) {
    jspb.Message.setProto3StringField(this, 2, value);
  }


  /**
   * optional string district = 3;
   * @return {string}
   */
  getDistrict(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
  };


  /** @param {string} value */
  setDistrict(value: string) {
    jspb.Message.setProto3StringField(this, 3, value);
  }


  /**
   * optional string partner = 4;
   * @return {string}
   */
  getPartner(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
  };


  /** @param {string} value */
  setPartner(value: string) {
    jspb.Message.setProto3StringField(this, 4, value);
  }


  /**
   * optional string state = 5;
   * @return {string}
   */
  getState(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
  };


  /** @param {string} value */
  setState(value: string) {
    jspb.Message.setProto3StringField(this, 5, value);
  }


  /**
   * repeated string status = 6;
   * @return {!Array<string>}
   */
  getStatusList(): string[] {
    return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 6));
  };


  /** @param {!Array<string>} value */
  setStatusList(value: string[]) {
    jspb.Message.setField(this, 6, value || []);
  }


  /**
   * @param {string} value
   * @param {number=} opt_index
   */
  addStatus(value: string, opt_index?: number): void {
    jspb.Message.addToRepeatedField(this, 6, value, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearStatusList() {
    this.setStatusList([]);
  }


  /**
   * repeated string subscription = 7;
   * @return {!Array<string>}
   */
  getSubscriptionList(): string[] {
    return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 7));
  };


  /** @param {!Array<string>} value */
  setSubscriptionList(value: string[]) {
    jspb.Message.setField(this, 7, value || []);
  }


  /**
   * @param {string} value
   * @param {number=} opt_index
   */
  addSubscription(value: string, opt_index?: number): void {
    jspb.Message.addToRepeatedField(this, 7, value, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearSubscriptionList() {
    this.setSubscriptionList([]);
  }


  /**
   * optional MingaReportType type = 8;
   * @return {!MingaReportType}
   */
  getType(): MingaReportType {
    return /** @type {!MingaReportType} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
  };


  /** @param {!MingaReportType} value */
  setType(value: MingaReportType) {
    jspb.Message.setProto3EnumField(this, 8, value);
  }


  /**
   * optional minga.common.Date start_date = 9;
   * @return {?Date}
   */
  getStartDate(): common_date_pb.Date {
    return /** @type{?Date} */ (
      jspb.Message.getWrapperField(this, common_date_pb.Date, 9));
  }


  /** @param {?Date|undefined} value */
  setStartDate(value?: common_date_pb.Date) {
    jspb.Message.setWrapperField(this, 9, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearStartDate() {
    this.setStartDate(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasStartDate(): boolean {
    return jspb.Message.getField(this, 9) != null;
  }


  /**
   * optional minga.common.Date end_date = 10;
   * @return {?Date}
   */
  getEndDate(): common_date_pb.Date {
    return /** @type{?Date} */ (
      jspb.Message.getWrapperField(this, common_date_pb.Date, 10));
  }


  /** @param {?Date|undefined} value */
  setEndDate(value?: common_date_pb.Date) {
    jspb.Message.setWrapperField(this, 10, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearEndDate() {
    this.setEndDate(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasEndDate(): boolean {
    return jspb.Message.getField(this, 10) != null;
  }


  /**
   * repeated string modules = 11;
   * @return {!Array<string>}
   */
  getModulesList(): string[] {
    return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 11));
  };


  /** @param {!Array<string>} value */
  setModulesList(value: string[]) {
    jspb.Message.setField(this, 11, value || []);
  }


  /**
   * @param {string} value
   * @param {number=} opt_index
   */
  addModules(value: string, opt_index?: number): void {
    jspb.Message.addToRepeatedField(this, 11, value, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearModulesList() {
    this.setModulesList([]);
  }


}
export namespace GetMingaReportResponse {
  export interface AsObject {
    dataList: common_graph_pb.GraphData.AsObject[];
  }
}
export class GetMingaReportResponse extends jspb.Message {
  static readonly displayName = "GetMingaReportResponse";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [1];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, GetMingaReportResponse.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): GetMingaReportResponse.AsObject {
    return GetMingaReportResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!GetMingaReportResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: GetMingaReportResponse) {
    var f: any;
    var obj: any = {
      dataList: jspb.Message.toObjectList(msg.getDataList(),
      common_graph_pb.GraphData.toObject, includeInstance)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!GetMingaReportResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new GetMingaReportResponse;
    return GetMingaReportResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!GetMingaReportResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!GetMingaReportResponse}
   */
  static deserializeBinaryFromReader(msg: GetMingaReportResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new common_graph_pb.GraphData;
        reader.readMessage(value1,common_graph_pb.GraphData.deserializeBinaryFromReader);
        msg.addData(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!GetMingaReportResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: GetMingaReportResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getDataList();
    if (f.length > 0) {
      writer.writeRepeatedMessage(
        1,
        f,
        common_graph_pb.GraphData.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    GetMingaReportResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * repeated minga.common.GraphData data = 1;
   * @return {!Array<!GraphData>}
   */
  getDataList(): common_graph_pb.GraphData[] {
    return /** @type{!Array<!GraphData>} */ (
      jspb.Message.getRepeatedWrapperField(this, common_graph_pb.GraphData, 1));
  }


  /** @param {!Array<!GraphData>} value */
  setDataList(value?: common_graph_pb.GraphData[]) {
    jspb.Message.setRepeatedWrapperField(this, 1, value);
  }


  /**
   * @param {!GraphData=} opt_value
   * @param {number=} opt_index
   * @return {!GraphData}
   */
  addData(opt_value?: common_graph_pb.GraphData, opt_index?: number): common_graph_pb.GraphData {
    return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, common_graph_pb.GraphData, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearDataList() {
    this.setDataList([]);
  }


}
export namespace GetMingaReportSummaryRequest {
  export interface AsObject {
    mingaHash: string;
    district: string;
    partner: string;
    state: string;
    statusList: string[];
    subscriptionList: string[];
    startDate?: common_date_pb.Date.AsObject;
    endDate?: common_date_pb.Date.AsObject;
    modulesList: string[];
  }
}
export class GetMingaReportSummaryRequest extends jspb.Message {
  static readonly displayName = "GetMingaReportSummaryRequest";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [5,6,9];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, GetMingaReportSummaryRequest.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): GetMingaReportSummaryRequest.AsObject {
    return GetMingaReportSummaryRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!GetMingaReportSummaryRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: GetMingaReportSummaryRequest) {
    var f: any;
    var obj: any = {
      mingaHash: jspb.Message.getFieldWithDefault(msg, 1, ""),
      district: jspb.Message.getFieldWithDefault(msg, 2, ""),
      partner: jspb.Message.getFieldWithDefault(msg, 3, ""),
      state: jspb.Message.getFieldWithDefault(msg, 4, ""),
      statusList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f,
      subscriptionList: (f = jspb.Message.getRepeatedField(msg, 6)) == null ? undefined : f,
      startDate: (f = msg.getStartDate()) && common_date_pb.Date.toObject(includeInstance, f),
      endDate: (f = msg.getEndDate()) && common_date_pb.Date.toObject(includeInstance, f),
      modulesList: (f = jspb.Message.getRepeatedField(msg, 9)) == null ? undefined : f
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!GetMingaReportSummaryRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new GetMingaReportSummaryRequest;
    return GetMingaReportSummaryRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!GetMingaReportSummaryRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!GetMingaReportSummaryRequest}
   */
  static deserializeBinaryFromReader(msg: GetMingaReportSummaryRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setMingaHash(value1);
        break;
      case 2:
        var value2 = /** @type {string} */ (reader.readString());
        msg.setDistrict(value2);
        break;
      case 3:
        var value3 = /** @type {string} */ (reader.readString());
        msg.setPartner(value3);
        break;
      case 4:
        var value4 = /** @type {string} */ (reader.readString());
        msg.setState(value4);
        break;
      case 5:
        var value5 = /** @type {string} */ (reader.readString());
        msg.addStatus(value5);
        break;
      case 6:
        var value6 = /** @type {string} */ (reader.readString());
        msg.addSubscription(value6);
        break;
      case 7:
        var value7 = new common_date_pb.Date;
        reader.readMessage(value7,common_date_pb.Date.deserializeBinaryFromReader);
        msg.setStartDate(value7);
        break;
      case 8:
        var value8 = new common_date_pb.Date;
        reader.readMessage(value8,common_date_pb.Date.deserializeBinaryFromReader);
        msg.setEndDate(value8);
        break;
      case 9:
        var value9 = /** @type {string} */ (reader.readString());
        msg.addModules(value9);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!GetMingaReportSummaryRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: GetMingaReportSummaryRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getMingaHash();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
    f = message.getDistrict();
    if (f.length > 0) {
      writer.writeString(
        2,
        f
      );
    }
    f = message.getPartner();
    if (f.length > 0) {
      writer.writeString(
        3,
        f
      );
    }
    f = message.getState();
    if (f.length > 0) {
      writer.writeString(
        4,
        f
      );
    }
    f = message.getStatusList();
    if (f.length > 0) {
      writer.writeRepeatedString(
        5,
        f
      );
    }
    f = message.getSubscriptionList();
    if (f.length > 0) {
      writer.writeRepeatedString(
        6,
        f
      );
    }
    f = message.getStartDate();
    if (f != null) {
      writer.writeMessage(
        7,
        f,
        common_date_pb.Date.serializeBinaryToWriter
      );
    }
    f = message.getEndDate();
    if (f != null) {
      writer.writeMessage(
        8,
        f,
        common_date_pb.Date.serializeBinaryToWriter
      );
    }
    f = message.getModulesList();
    if (f.length > 0) {
      writer.writeRepeatedString(
        9,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    GetMingaReportSummaryRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string minga_hash = 1;
   * @return {string}
   */
  getMingaHash(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setMingaHash(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


  /**
   * optional string district = 2;
   * @return {string}
   */
  getDistrict(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
  };


  /** @param {string} value */
  setDistrict(value: string) {
    jspb.Message.setProto3StringField(this, 2, value);
  }


  /**
   * optional string partner = 3;
   * @return {string}
   */
  getPartner(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
  };


  /** @param {string} value */
  setPartner(value: string) {
    jspb.Message.setProto3StringField(this, 3, value);
  }


  /**
   * optional string state = 4;
   * @return {string}
   */
  getState(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
  };


  /** @param {string} value */
  setState(value: string) {
    jspb.Message.setProto3StringField(this, 4, value);
  }


  /**
   * repeated string status = 5;
   * @return {!Array<string>}
   */
  getStatusList(): string[] {
    return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 5));
  };


  /** @param {!Array<string>} value */
  setStatusList(value: string[]) {
    jspb.Message.setField(this, 5, value || []);
  }


  /**
   * @param {string} value
   * @param {number=} opt_index
   */
  addStatus(value: string, opt_index?: number): void {
    jspb.Message.addToRepeatedField(this, 5, value, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearStatusList() {
    this.setStatusList([]);
  }


  /**
   * repeated string subscription = 6;
   * @return {!Array<string>}
   */
  getSubscriptionList(): string[] {
    return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 6));
  };


  /** @param {!Array<string>} value */
  setSubscriptionList(value: string[]) {
    jspb.Message.setField(this, 6, value || []);
  }


  /**
   * @param {string} value
   * @param {number=} opt_index
   */
  addSubscription(value: string, opt_index?: number): void {
    jspb.Message.addToRepeatedField(this, 6, value, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearSubscriptionList() {
    this.setSubscriptionList([]);
  }


  /**
   * optional minga.common.Date start_date = 7;
   * @return {?Date}
   */
  getStartDate(): common_date_pb.Date {
    return /** @type{?Date} */ (
      jspb.Message.getWrapperField(this, common_date_pb.Date, 7));
  }


  /** @param {?Date|undefined} value */
  setStartDate(value?: common_date_pb.Date) {
    jspb.Message.setWrapperField(this, 7, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearStartDate() {
    this.setStartDate(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasStartDate(): boolean {
    return jspb.Message.getField(this, 7) != null;
  }


  /**
   * optional minga.common.Date end_date = 8;
   * @return {?Date}
   */
  getEndDate(): common_date_pb.Date {
    return /** @type{?Date} */ (
      jspb.Message.getWrapperField(this, common_date_pb.Date, 8));
  }


  /** @param {?Date|undefined} value */
  setEndDate(value?: common_date_pb.Date) {
    jspb.Message.setWrapperField(this, 8, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearEndDate() {
    this.setEndDate(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasEndDate(): boolean {
    return jspb.Message.getField(this, 8) != null;
  }


  /**
   * repeated string modules = 9;
   * @return {!Array<string>}
   */
  getModulesList(): string[] {
    return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 9));
  };


  /** @param {!Array<string>} value */
  setModulesList(value: string[]) {
    jspb.Message.setField(this, 9, value || []);
  }


  /**
   * @param {string} value
   * @param {number=} opt_index
   */
  addModules(value: string, opt_index?: number): void {
    jspb.Message.addToRepeatedField(this, 9, value, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearModulesList() {
    this.setModulesList([]);
  }


}
export namespace GetMingaReportSummaryResponse {
  export interface AsObject {
    activeUserCount: number;
    activeStudentCount: number;
    hallPassCount: number;
    postCount: number;
    behaviorCount: number;
    checkinCount: number;
  }
}
export class GetMingaReportSummaryResponse extends jspb.Message {
  static readonly displayName = "GetMingaReportSummaryResponse";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): GetMingaReportSummaryResponse.AsObject {
    return GetMingaReportSummaryResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!GetMingaReportSummaryResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: GetMingaReportSummaryResponse) {
    var f: any;
    var obj: any = {
      activeUserCount: jspb.Message.getFieldWithDefault(msg, 1, 0),
      activeStudentCount: jspb.Message.getFieldWithDefault(msg, 2, 0),
      hallPassCount: jspb.Message.getFieldWithDefault(msg, 3, 0),
      postCount: jspb.Message.getFieldWithDefault(msg, 4, 0),
      behaviorCount: jspb.Message.getFieldWithDefault(msg, 5, 0),
      checkinCount: jspb.Message.getFieldWithDefault(msg, 6, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!GetMingaReportSummaryResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new GetMingaReportSummaryResponse;
    return GetMingaReportSummaryResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!GetMingaReportSummaryResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!GetMingaReportSummaryResponse}
   */
  static deserializeBinaryFromReader(msg: GetMingaReportSummaryResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {number} */ (reader.readInt32());
        msg.setActiveUserCount(value1);
        break;
      case 2:
        var value2 = /** @type {number} */ (reader.readInt32());
        msg.setActiveStudentCount(value2);
        break;
      case 3:
        var value3 = /** @type {number} */ (reader.readInt32());
        msg.setHallPassCount(value3);
        break;
      case 4:
        var value4 = /** @type {number} */ (reader.readInt32());
        msg.setPostCount(value4);
        break;
      case 5:
        var value5 = /** @type {number} */ (reader.readInt32());
        msg.setBehaviorCount(value5);
        break;
      case 6:
        var value6 = /** @type {number} */ (reader.readInt32());
        msg.setCheckinCount(value6);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!GetMingaReportSummaryResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: GetMingaReportSummaryResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getActiveUserCount();
    if (f !== 0) {
      writer.writeInt32(
        1,
        f
      );
    }
    f = message.getActiveStudentCount();
    if (f !== 0) {
      writer.writeInt32(
        2,
        f
      );
    }
    f = message.getHallPassCount();
    if (f !== 0) {
      writer.writeInt32(
        3,
        f
      );
    }
    f = message.getPostCount();
    if (f !== 0) {
      writer.writeInt32(
        4,
        f
      );
    }
    f = message.getBehaviorCount();
    if (f !== 0) {
      writer.writeInt32(
        5,
        f
      );
    }
    f = message.getCheckinCount();
    if (f !== 0) {
      writer.writeInt32(
        6,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    GetMingaReportSummaryResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional int32 active_user_count = 1;
   * @return {number}
   */
  getActiveUserCount(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
  };


  /** @param {number} value */
  setActiveUserCount(value: number) {
    jspb.Message.setProto3IntField(this, 1, value);
  }


  /**
   * optional int32 active_student_count = 2;
   * @return {number}
   */
  getActiveStudentCount(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
  };


  /** @param {number} value */
  setActiveStudentCount(value: number) {
    jspb.Message.setProto3IntField(this, 2, value);
  }


  /**
   * optional int32 hall_pass_count = 3;
   * @return {number}
   */
  getHallPassCount(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
  };


  /** @param {number} value */
  setHallPassCount(value: number) {
    jspb.Message.setProto3IntField(this, 3, value);
  }


  /**
   * optional int32 post_count = 4;
   * @return {number}
   */
  getPostCount(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
  };


  /** @param {number} value */
  setPostCount(value: number) {
    jspb.Message.setProto3IntField(this, 4, value);
  }


  /**
   * optional int32 behavior_count = 5;
   * @return {number}
   */
  getBehaviorCount(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
  };


  /** @param {number} value */
  setBehaviorCount(value: number) {
    jspb.Message.setProto3IntField(this, 5, value);
  }


  /**
   * optional int32 checkin_count = 6;
   * @return {number}
   */
  getCheckinCount(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
  };


  /** @param {number} value */
  setCheckinCount(value: number) {
    jspb.Message.setProto3IntField(this, 6, value);
  }


}
export namespace UserMingaAccountInfoRequest {
  export interface AsObject {
  }
}
export class UserMingaAccountInfoRequest extends jspb.Message {
  static readonly displayName = "UserMingaAccountInfoRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): UserMingaAccountInfoRequest.AsObject {
    return UserMingaAccountInfoRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!UserMingaAccountInfoRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: UserMingaAccountInfoRequest) {
    var f: any;
    var obj: any = {

    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!UserMingaAccountInfoRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new UserMingaAccountInfoRequest;
    return UserMingaAccountInfoRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!UserMingaAccountInfoRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!UserMingaAccountInfoRequest}
   */
  static deserializeBinaryFromReader(msg: UserMingaAccountInfoRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!UserMingaAccountInfoRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: UserMingaAccountInfoRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    UserMingaAccountInfoRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
}
export namespace UserMingaAccountInfoResponse {
  export interface AsObject {
    minga?: MingaDashboardInfo.AsObject;
    settingList: MingaSetting.AsObject[];
    featureToggle?: MingaFeatureToggle.AsObject;
  }
}
export class UserMingaAccountInfoResponse extends jspb.Message {
  static readonly displayName = "UserMingaAccountInfoResponse";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [2];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, UserMingaAccountInfoResponse.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): UserMingaAccountInfoResponse.AsObject {
    return UserMingaAccountInfoResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!UserMingaAccountInfoResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: UserMingaAccountInfoResponse) {
    var f: any;
    var obj: any = {
      minga: (f = msg.getMinga()) && MingaDashboardInfo.toObject(includeInstance, f),
      settingList: jspb.Message.toObjectList(msg.getSettingList(),
      MingaSetting.toObject, includeInstance),
      featureToggle: (f = msg.getFeatureToggle()) && MingaFeatureToggle.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!UserMingaAccountInfoResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new UserMingaAccountInfoResponse;
    return UserMingaAccountInfoResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!UserMingaAccountInfoResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!UserMingaAccountInfoResponse}
   */
  static deserializeBinaryFromReader(msg: UserMingaAccountInfoResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new MingaDashboardInfo;
        reader.readMessage(value1,MingaDashboardInfo.deserializeBinaryFromReader);
        msg.setMinga(value1);
        break;
      case 2:
        var value2 = new MingaSetting;
        reader.readMessage(value2,MingaSetting.deserializeBinaryFromReader);
        msg.addSetting(value2);
        break;
      case 3:
        var value3 = new MingaFeatureToggle;
        reader.readMessage(value3,MingaFeatureToggle.deserializeBinaryFromReader);
        msg.setFeatureToggle(value3);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!UserMingaAccountInfoResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: UserMingaAccountInfoResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getMinga();
    if (f != null) {
      writer.writeMessage(
        1,
        f,
        MingaDashboardInfo.serializeBinaryToWriter
      );
    }
    f = message.getSettingList();
    if (f.length > 0) {
      writer.writeRepeatedMessage(
        2,
        f,
        MingaSetting.serializeBinaryToWriter
      );
    }
    f = message.getFeatureToggle();
    if (f != null) {
      writer.writeMessage(
        3,
        f,
        MingaFeatureToggle.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    UserMingaAccountInfoResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional MingaDashboardInfo minga = 1;
   * @return {?MingaDashboardInfo}
   */
  getMinga(): MingaDashboardInfo {
    return /** @type{?MingaDashboardInfo} */ (
      jspb.Message.getWrapperField(this, MingaDashboardInfo, 1));
  }


  /** @param {?MingaDashboardInfo|undefined} value */
  setMinga(value?: MingaDashboardInfo) {
    jspb.Message.setWrapperField(this, 1, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearMinga() {
    this.setMinga(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasMinga(): boolean {
    return jspb.Message.getField(this, 1) != null;
  }


  /**
   * repeated MingaSetting setting = 2;
   * @return {!Array<!MingaSetting>}
   */
  getSettingList(): MingaSetting[] {
    return /** @type{!Array<!MingaSetting>} */ (
      jspb.Message.getRepeatedWrapperField(this, MingaSetting, 2));
  }


  /** @param {!Array<!MingaSetting>} value */
  setSettingList(value?: MingaSetting[]) {
    jspb.Message.setRepeatedWrapperField(this, 2, value);
  }


  /**
   * @param {!MingaSetting=} opt_value
   * @param {number=} opt_index
   * @return {!MingaSetting}
   */
  addSetting(opt_value?: MingaSetting, opt_index?: number): MingaSetting {
    return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, MingaSetting, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearSettingList() {
    this.setSettingList([]);
  }


  /**
   * optional MingaFeatureToggle feature_toggle = 3;
   * @return {?MingaFeatureToggle}
   */
  getFeatureToggle(): MingaFeatureToggle {
    return /** @type{?MingaFeatureToggle} */ (
      jspb.Message.getWrapperField(this, MingaFeatureToggle, 3));
  }


  /** @param {?MingaFeatureToggle|undefined} value */
  setFeatureToggle(value?: MingaFeatureToggle) {
    jspb.Message.setWrapperField(this, 3, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearFeatureToggle() {
    this.setFeatureToggle(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasFeatureToggle(): boolean {
    return jspb.Message.getField(this, 3) != null;
  }


}
export namespace UpdateUserMingaSettingsRequest {
  export interface AsObject {
    settingList: MingaSetting.AsObject[];
  }
}
export class UpdateUserMingaSettingsRequest extends jspb.Message {
  static readonly displayName = "UpdateUserMingaSettingsRequest";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [1];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, UpdateUserMingaSettingsRequest.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): UpdateUserMingaSettingsRequest.AsObject {
    return UpdateUserMingaSettingsRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!UpdateUserMingaSettingsRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: UpdateUserMingaSettingsRequest) {
    var f: any;
    var obj: any = {
      settingList: jspb.Message.toObjectList(msg.getSettingList(),
      MingaSetting.toObject, includeInstance)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!UpdateUserMingaSettingsRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new UpdateUserMingaSettingsRequest;
    return UpdateUserMingaSettingsRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!UpdateUserMingaSettingsRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!UpdateUserMingaSettingsRequest}
   */
  static deserializeBinaryFromReader(msg: UpdateUserMingaSettingsRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new MingaSetting;
        reader.readMessage(value1,MingaSetting.deserializeBinaryFromReader);
        msg.addSetting(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!UpdateUserMingaSettingsRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: UpdateUserMingaSettingsRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getSettingList();
    if (f.length > 0) {
      writer.writeRepeatedMessage(
        1,
        f,
        MingaSetting.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    UpdateUserMingaSettingsRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * repeated MingaSetting setting = 1;
   * @return {!Array<!MingaSetting>}
   */
  getSettingList(): MingaSetting[] {
    return /** @type{!Array<!MingaSetting>} */ (
      jspb.Message.getRepeatedWrapperField(this, MingaSetting, 1));
  }


  /** @param {!Array<!MingaSetting>} value */
  setSettingList(value?: MingaSetting[]) {
    jspb.Message.setRepeatedWrapperField(this, 1, value);
  }


  /**
   * @param {!MingaSetting=} opt_value
   * @param {number=} opt_index
   * @return {!MingaSetting}
   */
  addSetting(opt_value?: MingaSetting, opt_index?: number): MingaSetting {
    return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, MingaSetting, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearSettingList() {
    this.setSettingList([]);
  }


}
export namespace UpdateUserMingaSettingsResponse {
  export interface AsObject {
    settingList: MingaSetting.AsObject[];
  }
}
export class UpdateUserMingaSettingsResponse extends jspb.Message {
  static readonly displayName = "UpdateUserMingaSettingsResponse";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [1];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, UpdateUserMingaSettingsResponse.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): UpdateUserMingaSettingsResponse.AsObject {
    return UpdateUserMingaSettingsResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!UpdateUserMingaSettingsResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: UpdateUserMingaSettingsResponse) {
    var f: any;
    var obj: any = {
      settingList: jspb.Message.toObjectList(msg.getSettingList(),
      MingaSetting.toObject, includeInstance)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!UpdateUserMingaSettingsResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new UpdateUserMingaSettingsResponse;
    return UpdateUserMingaSettingsResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!UpdateUserMingaSettingsResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!UpdateUserMingaSettingsResponse}
   */
  static deserializeBinaryFromReader(msg: UpdateUserMingaSettingsResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new MingaSetting;
        reader.readMessage(value1,MingaSetting.deserializeBinaryFromReader);
        msg.addSetting(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!UpdateUserMingaSettingsResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: UpdateUserMingaSettingsResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getSettingList();
    if (f.length > 0) {
      writer.writeRepeatedMessage(
        1,
        f,
        MingaSetting.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    UpdateUserMingaSettingsResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * repeated MingaSetting setting = 1;
   * @return {!Array<!MingaSetting>}
   */
  getSettingList(): MingaSetting[] {
    return /** @type{!Array<!MingaSetting>} */ (
      jspb.Message.getRepeatedWrapperField(this, MingaSetting, 1));
  }


  /** @param {!Array<!MingaSetting>} value */
  setSettingList(value?: MingaSetting[]) {
    jspb.Message.setRepeatedWrapperField(this, 1, value);
  }


  /**
   * @param {!MingaSetting=} opt_value
   * @param {number=} opt_index
   * @return {!MingaSetting}
   */
  addSetting(opt_value?: MingaSetting, opt_index?: number): MingaSetting {
    return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, MingaSetting, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearSettingList() {
    this.setSettingList([]);
  }


}
export namespace CreateUserMingaJoinCodeRequest {
  export interface AsObject {
  }
}
export class CreateUserMingaJoinCodeRequest extends jspb.Message {
  static readonly displayName = "CreateUserMingaJoinCodeRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): CreateUserMingaJoinCodeRequest.AsObject {
    return CreateUserMingaJoinCodeRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!CreateUserMingaJoinCodeRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: CreateUserMingaJoinCodeRequest) {
    var f: any;
    var obj: any = {

    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!CreateUserMingaJoinCodeRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new CreateUserMingaJoinCodeRequest;
    return CreateUserMingaJoinCodeRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!CreateUserMingaJoinCodeRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!CreateUserMingaJoinCodeRequest}
   */
  static deserializeBinaryFromReader(msg: CreateUserMingaJoinCodeRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!CreateUserMingaJoinCodeRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: CreateUserMingaJoinCodeRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    CreateUserMingaJoinCodeRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
}
export namespace CreateUserMingaJoinCodeResponse {
  export interface AsObject {
    mingaJoinCode: string;
  }
}
export class CreateUserMingaJoinCodeResponse extends jspb.Message {
  static readonly displayName = "CreateUserMingaJoinCodeResponse";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): CreateUserMingaJoinCodeResponse.AsObject {
    return CreateUserMingaJoinCodeResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!CreateUserMingaJoinCodeResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: CreateUserMingaJoinCodeResponse) {
    var f: any;
    var obj: any = {
      mingaJoinCode: jspb.Message.getFieldWithDefault(msg, 1, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!CreateUserMingaJoinCodeResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new CreateUserMingaJoinCodeResponse;
    return CreateUserMingaJoinCodeResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!CreateUserMingaJoinCodeResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!CreateUserMingaJoinCodeResponse}
   */
  static deserializeBinaryFromReader(msg: CreateUserMingaJoinCodeResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setMingaJoinCode(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!CreateUserMingaJoinCodeResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: CreateUserMingaJoinCodeResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getMingaJoinCode();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    CreateUserMingaJoinCodeResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string minga_join_code = 1;
   * @return {string}
   */
  getMingaJoinCode(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setMingaJoinCode(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


}
export namespace ReadUserMingaJoinCodeRequest {
  export interface AsObject {
  }
}
export class ReadUserMingaJoinCodeRequest extends jspb.Message {
  static readonly displayName = "ReadUserMingaJoinCodeRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): ReadUserMingaJoinCodeRequest.AsObject {
    return ReadUserMingaJoinCodeRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!ReadUserMingaJoinCodeRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: ReadUserMingaJoinCodeRequest) {
    var f: any;
    var obj: any = {

    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!ReadUserMingaJoinCodeRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new ReadUserMingaJoinCodeRequest;
    return ReadUserMingaJoinCodeRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!ReadUserMingaJoinCodeRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!ReadUserMingaJoinCodeRequest}
   */
  static deserializeBinaryFromReader(msg: ReadUserMingaJoinCodeRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!ReadUserMingaJoinCodeRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: ReadUserMingaJoinCodeRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    ReadUserMingaJoinCodeRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
}
export namespace ReadUserMingaJoinCodeResponse {
  export interface AsObject {
    mingaJoinCode: string;
  }
}
export class ReadUserMingaJoinCodeResponse extends jspb.Message {
  static readonly displayName = "ReadUserMingaJoinCodeResponse";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): ReadUserMingaJoinCodeResponse.AsObject {
    return ReadUserMingaJoinCodeResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!ReadUserMingaJoinCodeResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: ReadUserMingaJoinCodeResponse) {
    var f: any;
    var obj: any = {
      mingaJoinCode: jspb.Message.getFieldWithDefault(msg, 1, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!ReadUserMingaJoinCodeResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new ReadUserMingaJoinCodeResponse;
    return ReadUserMingaJoinCodeResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!ReadUserMingaJoinCodeResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!ReadUserMingaJoinCodeResponse}
   */
  static deserializeBinaryFromReader(msg: ReadUserMingaJoinCodeResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setMingaJoinCode(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!ReadUserMingaJoinCodeResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: ReadUserMingaJoinCodeResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getMingaJoinCode();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    ReadUserMingaJoinCodeResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string minga_join_code = 1;
   * @return {string}
   */
  getMingaJoinCode(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setMingaJoinCode(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


}
export namespace ReadUserMingaGradesRequest {
  export interface AsObject {
  }
}
export class ReadUserMingaGradesRequest extends jspb.Message {
  static readonly displayName = "ReadUserMingaGradesRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): ReadUserMingaGradesRequest.AsObject {
    return ReadUserMingaGradesRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!ReadUserMingaGradesRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: ReadUserMingaGradesRequest) {
    var f: any;
    var obj: any = {

    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!ReadUserMingaGradesRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new ReadUserMingaGradesRequest;
    return ReadUserMingaGradesRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!ReadUserMingaGradesRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!ReadUserMingaGradesRequest}
   */
  static deserializeBinaryFromReader(msg: ReadUserMingaGradesRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!ReadUserMingaGradesRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: ReadUserMingaGradesRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    ReadUserMingaGradesRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
}
export namespace ReadUserMingaGradesResponse {
  export interface AsObject {
    gradeOptionsList: string[];
  }
}
export class ReadUserMingaGradesResponse extends jspb.Message {
  static readonly displayName = "ReadUserMingaGradesResponse";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [1];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, ReadUserMingaGradesResponse.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): ReadUserMingaGradesResponse.AsObject {
    return ReadUserMingaGradesResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!ReadUserMingaGradesResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: ReadUserMingaGradesResponse) {
    var f: any;
    var obj: any = {
      gradeOptionsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!ReadUserMingaGradesResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new ReadUserMingaGradesResponse;
    return ReadUserMingaGradesResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!ReadUserMingaGradesResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!ReadUserMingaGradesResponse}
   */
  static deserializeBinaryFromReader(msg: ReadUserMingaGradesResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.addGradeOptions(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!ReadUserMingaGradesResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: ReadUserMingaGradesResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getGradeOptionsList();
    if (f.length > 0) {
      writer.writeRepeatedString(
        1,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    ReadUserMingaGradesResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * repeated string grade_options = 1;
   * @return {!Array<string>}
   */
  getGradeOptionsList(): string[] {
    return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
  };


  /** @param {!Array<string>} value */
  setGradeOptionsList(value: string[]) {
    jspb.Message.setField(this, 1, value || []);
  }


  /**
   * @param {string} value
   * @param {number=} opt_index
   */
  addGradeOptions(value: string, opt_index?: number): void {
    jspb.Message.addToRepeatedField(this, 1, value, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearGradeOptionsList() {
    this.setGradeOptionsList([]);
  }


}
export namespace ChangeCustomJoinCodeRequest {
  export interface AsObject {
    mingaJoinCode: string;
  }
}
export class ChangeCustomJoinCodeRequest extends jspb.Message {
  static readonly displayName = "ChangeCustomJoinCodeRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): ChangeCustomJoinCodeRequest.AsObject {
    return ChangeCustomJoinCodeRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!ChangeCustomJoinCodeRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: ChangeCustomJoinCodeRequest) {
    var f: any;
    var obj: any = {
      mingaJoinCode: jspb.Message.getFieldWithDefault(msg, 1, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!ChangeCustomJoinCodeRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new ChangeCustomJoinCodeRequest;
    return ChangeCustomJoinCodeRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!ChangeCustomJoinCodeRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!ChangeCustomJoinCodeRequest}
   */
  static deserializeBinaryFromReader(msg: ChangeCustomJoinCodeRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setMingaJoinCode(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!ChangeCustomJoinCodeRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: ChangeCustomJoinCodeRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getMingaJoinCode();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    ChangeCustomJoinCodeRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string minga_join_code = 1;
   * @return {string}
   */
  getMingaJoinCode(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setMingaJoinCode(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


}
export namespace ChangeCustomJoinCodeResponse {
  export interface AsObject {
    mingaJoinCode: string;
  }
}
export class ChangeCustomJoinCodeResponse extends jspb.Message {
  static readonly displayName = "ChangeCustomJoinCodeResponse";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): ChangeCustomJoinCodeResponse.AsObject {
    return ChangeCustomJoinCodeResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!ChangeCustomJoinCodeResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: ChangeCustomJoinCodeResponse) {
    var f: any;
    var obj: any = {
      mingaJoinCode: jspb.Message.getFieldWithDefault(msg, 1, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!ChangeCustomJoinCodeResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new ChangeCustomJoinCodeResponse;
    return ChangeCustomJoinCodeResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!ChangeCustomJoinCodeResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!ChangeCustomJoinCodeResponse}
   */
  static deserializeBinaryFromReader(msg: ChangeCustomJoinCodeResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setMingaJoinCode(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!ChangeCustomJoinCodeResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: ChangeCustomJoinCodeResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getMingaJoinCode();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    ChangeCustomJoinCodeResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string minga_join_code = 1;
   * @return {string}
   */
  getMingaJoinCode(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setMingaJoinCode(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


}
export namespace ReadMingaSubscriptionsRequest {
  export interface AsObject {
    filterMingaType: string;
  }
}
export class ReadMingaSubscriptionsRequest extends jspb.Message {
  static readonly displayName = "ReadMingaSubscriptionsRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): ReadMingaSubscriptionsRequest.AsObject {
    return ReadMingaSubscriptionsRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!ReadMingaSubscriptionsRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: ReadMingaSubscriptionsRequest) {
    var f: any;
    var obj: any = {
      filterMingaType: jspb.Message.getFieldWithDefault(msg, 1, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!ReadMingaSubscriptionsRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new ReadMingaSubscriptionsRequest;
    return ReadMingaSubscriptionsRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!ReadMingaSubscriptionsRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!ReadMingaSubscriptionsRequest}
   */
  static deserializeBinaryFromReader(msg: ReadMingaSubscriptionsRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setFilterMingaType(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!ReadMingaSubscriptionsRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: ReadMingaSubscriptionsRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getFilterMingaType();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    ReadMingaSubscriptionsRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string filter_minga_type = 1;
   * @return {string}
   */
  getFilterMingaType(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setFilterMingaType(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


}
export namespace ReadMingaSubscriptionsResponse {
  export interface AsObject {
    subscriptionList: MingaSubscription.AsObject[];
    displaySubscriptionsList: MingaSubscription.AsObject[];
  }
}
export class ReadMingaSubscriptionsResponse extends jspb.Message {
  static readonly displayName = "ReadMingaSubscriptionsResponse";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [1,2];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, ReadMingaSubscriptionsResponse.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): ReadMingaSubscriptionsResponse.AsObject {
    return ReadMingaSubscriptionsResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!ReadMingaSubscriptionsResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: ReadMingaSubscriptionsResponse) {
    var f: any;
    var obj: any = {
      subscriptionList: jspb.Message.toObjectList(msg.getSubscriptionList(),
      MingaSubscription.toObject, includeInstance),
      displaySubscriptionsList: jspb.Message.toObjectList(msg.getDisplaySubscriptionsList(),
      MingaSubscription.toObject, includeInstance)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!ReadMingaSubscriptionsResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new ReadMingaSubscriptionsResponse;
    return ReadMingaSubscriptionsResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!ReadMingaSubscriptionsResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!ReadMingaSubscriptionsResponse}
   */
  static deserializeBinaryFromReader(msg: ReadMingaSubscriptionsResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new MingaSubscription;
        reader.readMessage(value1,MingaSubscription.deserializeBinaryFromReader);
        msg.addSubscription(value1);
        break;
      case 2:
        var value2 = new MingaSubscription;
        reader.readMessage(value2,MingaSubscription.deserializeBinaryFromReader);
        msg.addDisplaySubscriptions(value2);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!ReadMingaSubscriptionsResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: ReadMingaSubscriptionsResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getSubscriptionList();
    if (f.length > 0) {
      writer.writeRepeatedMessage(
        1,
        f,
        MingaSubscription.serializeBinaryToWriter
      );
    }
    f = message.getDisplaySubscriptionsList();
    if (f.length > 0) {
      writer.writeRepeatedMessage(
        2,
        f,
        MingaSubscription.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    ReadMingaSubscriptionsResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * repeated MingaSubscription subscription = 1;
   * @return {!Array<!MingaSubscription>}
   */
  getSubscriptionList(): MingaSubscription[] {
    return /** @type{!Array<!MingaSubscription>} */ (
      jspb.Message.getRepeatedWrapperField(this, MingaSubscription, 1));
  }


  /** @param {!Array<!MingaSubscription>} value */
  setSubscriptionList(value?: MingaSubscription[]) {
    jspb.Message.setRepeatedWrapperField(this, 1, value);
  }


  /**
   * @param {!MingaSubscription=} opt_value
   * @param {number=} opt_index
   * @return {!MingaSubscription}
   */
  addSubscription(opt_value?: MingaSubscription, opt_index?: number): MingaSubscription {
    return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, MingaSubscription, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearSubscriptionList() {
    this.setSubscriptionList([]);
  }


  /**
   * repeated MingaSubscription display_subscriptions = 2;
   * @return {!Array<!MingaSubscription>}
   */
  getDisplaySubscriptionsList(): MingaSubscription[] {
    return /** @type{!Array<!MingaSubscription>} */ (
      jspb.Message.getRepeatedWrapperField(this, MingaSubscription, 2));
  }


  /** @param {!Array<!MingaSubscription>} value */
  setDisplaySubscriptionsList(value?: MingaSubscription[]) {
    jspb.Message.setRepeatedWrapperField(this, 2, value);
  }


  /**
   * @param {!MingaSubscription=} opt_value
   * @param {number=} opt_index
   * @return {!MingaSubscription}
   */
  addDisplaySubscriptions(opt_value?: MingaSubscription, opt_index?: number): MingaSubscription {
    return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, MingaSubscription, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearDisplaySubscriptionsList() {
    this.setDisplaySubscriptionsList([]);
  }


}
export namespace GetActiveMingaSubscriptionRequest {
  export interface AsObject {
  }
}
export class GetActiveMingaSubscriptionRequest extends jspb.Message {
  static readonly displayName = "GetActiveMingaSubscriptionRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): GetActiveMingaSubscriptionRequest.AsObject {
    return GetActiveMingaSubscriptionRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!GetActiveMingaSubscriptionRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: GetActiveMingaSubscriptionRequest) {
    var f: any;
    var obj: any = {

    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!GetActiveMingaSubscriptionRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new GetActiveMingaSubscriptionRequest;
    return GetActiveMingaSubscriptionRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!GetActiveMingaSubscriptionRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!GetActiveMingaSubscriptionRequest}
   */
  static deserializeBinaryFromReader(msg: GetActiveMingaSubscriptionRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!GetActiveMingaSubscriptionRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: GetActiveMingaSubscriptionRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    GetActiveMingaSubscriptionRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
}
export namespace GetActiveMingaSubscriptionResponse {
  export interface AsObject {
    subscription?: MingaSubscription.AsObject;
  }
}
export class GetActiveMingaSubscriptionResponse extends jspb.Message {
  static readonly displayName = "GetActiveMingaSubscriptionResponse";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): GetActiveMingaSubscriptionResponse.AsObject {
    return GetActiveMingaSubscriptionResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!GetActiveMingaSubscriptionResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: GetActiveMingaSubscriptionResponse) {
    var f: any;
    var obj: any = {
      subscription: (f = msg.getSubscription()) && MingaSubscription.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!GetActiveMingaSubscriptionResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new GetActiveMingaSubscriptionResponse;
    return GetActiveMingaSubscriptionResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!GetActiveMingaSubscriptionResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!GetActiveMingaSubscriptionResponse}
   */
  static deserializeBinaryFromReader(msg: GetActiveMingaSubscriptionResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new MingaSubscription;
        reader.readMessage(value1,MingaSubscription.deserializeBinaryFromReader);
        msg.setSubscription(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!GetActiveMingaSubscriptionResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: GetActiveMingaSubscriptionResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getSubscription();
    if (f != null) {
      writer.writeMessage(
        1,
        f,
        MingaSubscription.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    GetActiveMingaSubscriptionResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional MingaSubscription subscription = 1;
   * @return {?MingaSubscription}
   */
  getSubscription(): MingaSubscription {
    return /** @type{?MingaSubscription} */ (
      jspb.Message.getWrapperField(this, MingaSubscription, 1));
  }


  /** @param {?MingaSubscription|undefined} value */
  setSubscription(value?: MingaSubscription) {
    jspb.Message.setWrapperField(this, 1, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearSubscription() {
    this.setSubscription(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasSubscription(): boolean {
    return jspb.Message.getField(this, 1) != null;
  }


}
export namespace sisFields {
  export interface AsObject {
    url: string;
    clientId: string;
    clientSecret: string;
    schoolId: string;
    updateExisting: boolean;
    archiveMissing: boolean;
    active: boolean;
    authUrl: string;
    studentUrl: string;
    teacherUrl: string;
    lastModified: boolean;
    rosteringMethod: string;
    studentRostering: boolean;
    staffRostering: boolean;
    sisType: string;
    accessToken: string;
    applicationId: string;
    parentRostering: boolean;
    notificationEmailsList: string[];
    idNumberFieldName: string;
    teacherRostering: boolean;
    adminRostering: boolean;
    updateExistingStudents: boolean;
    updateExistingStaff: boolean;
    updateExistingTeachers: boolean;
    updateExistingAdmins: boolean;
    archiveMissingStudents: boolean;
    archiveMissingStaff: boolean;
    archiveMissingTeachers: boolean;
    archiveMissingAdmins: boolean;
    classListRostering: boolean;
    termSourcedIdsList: string[];
    termSourcedIdsNull: boolean;
    gradesList: string[];
    gradesNull: boolean;
  }
}
export class sisFields extends jspb.Message {
  static readonly displayName = "sisFields";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [19,32,34];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, sisFields.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): sisFields.AsObject {
    return sisFields.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!sisFields} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: sisFields) {
    var f: any;
    var obj: any = {
      url: jspb.Message.getFieldWithDefault(msg, 1, ""),
      clientId: jspb.Message.getFieldWithDefault(msg, 2, ""),
      clientSecret: jspb.Message.getFieldWithDefault(msg, 3, ""),
      schoolId: jspb.Message.getFieldWithDefault(msg, 4, ""),
      updateExisting: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
      archiveMissing: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
      active: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
      authUrl: jspb.Message.getFieldWithDefault(msg, 8, ""),
      studentUrl: jspb.Message.getFieldWithDefault(msg, 9, ""),
      teacherUrl: jspb.Message.getFieldWithDefault(msg, 10, ""),
      lastModified: jspb.Message.getBooleanFieldWithDefault(msg, 11, false),
      rosteringMethod: jspb.Message.getFieldWithDefault(msg, 12, ""),
      studentRostering: jspb.Message.getBooleanFieldWithDefault(msg, 13, false),
      staffRostering: jspb.Message.getBooleanFieldWithDefault(msg, 14, false),
      sisType: jspb.Message.getFieldWithDefault(msg, 15, ""),
      accessToken: jspb.Message.getFieldWithDefault(msg, 16, ""),
      applicationId: jspb.Message.getFieldWithDefault(msg, 17, ""),
      parentRostering: jspb.Message.getBooleanFieldWithDefault(msg, 18, false),
      notificationEmailsList: (f = jspb.Message.getRepeatedField(msg, 19)) == null ? undefined : f,
      idNumberFieldName: jspb.Message.getFieldWithDefault(msg, 20, ""),
      teacherRostering: jspb.Message.getBooleanFieldWithDefault(msg, 21, false),
      adminRostering: jspb.Message.getBooleanFieldWithDefault(msg, 22, false),
      updateExistingStudents: jspb.Message.getBooleanFieldWithDefault(msg, 23, false),
      updateExistingStaff: jspb.Message.getBooleanFieldWithDefault(msg, 24, false),
      updateExistingTeachers: jspb.Message.getBooleanFieldWithDefault(msg, 25, false),
      updateExistingAdmins: jspb.Message.getBooleanFieldWithDefault(msg, 26, false),
      archiveMissingStudents: jspb.Message.getBooleanFieldWithDefault(msg, 27, false),
      archiveMissingStaff: jspb.Message.getBooleanFieldWithDefault(msg, 28, false),
      archiveMissingTeachers: jspb.Message.getBooleanFieldWithDefault(msg, 29, false),
      archiveMissingAdmins: jspb.Message.getBooleanFieldWithDefault(msg, 30, false),
      classListRostering: jspb.Message.getBooleanFieldWithDefault(msg, 31, false),
      termSourcedIdsList: (f = jspb.Message.getRepeatedField(msg, 32)) == null ? undefined : f,
      termSourcedIdsNull: jspb.Message.getBooleanFieldWithDefault(msg, 33, false),
      gradesList: (f = jspb.Message.getRepeatedField(msg, 34)) == null ? undefined : f,
      gradesNull: jspb.Message.getBooleanFieldWithDefault(msg, 35, false)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!sisFields}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new sisFields;
    return sisFields.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!sisFields} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!sisFields}
   */
  static deserializeBinaryFromReader(msg: sisFields, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setUrl(value1);
        break;
      case 2:
        var value2 = /** @type {string} */ (reader.readString());
        msg.setClientId(value2);
        break;
      case 3:
        var value3 = /** @type {string} */ (reader.readString());
        msg.setClientSecret(value3);
        break;
      case 4:
        var value4 = /** @type {string} */ (reader.readString());
        msg.setSchoolId(value4);
        break;
      case 5:
        var value5 = /** @type {boolean} */ (reader.readBool());
        msg.setUpdateExisting(value5);
        break;
      case 6:
        var value6 = /** @type {boolean} */ (reader.readBool());
        msg.setArchiveMissing(value6);
        break;
      case 7:
        var value7 = /** @type {boolean} */ (reader.readBool());
        msg.setActive(value7);
        break;
      case 8:
        var value8 = /** @type {string} */ (reader.readString());
        msg.setAuthUrl(value8);
        break;
      case 9:
        var value9 = /** @type {string} */ (reader.readString());
        msg.setStudentUrl(value9);
        break;
      case 10:
        var value10 = /** @type {string} */ (reader.readString());
        msg.setTeacherUrl(value10);
        break;
      case 11:
        var value11 = /** @type {boolean} */ (reader.readBool());
        msg.setLastModified(value11);
        break;
      case 12:
        var value12 = /** @type {string} */ (reader.readString());
        msg.setRosteringMethod(value12);
        break;
      case 13:
        var value13 = /** @type {boolean} */ (reader.readBool());
        msg.setStudentRostering(value13);
        break;
      case 14:
        var value14 = /** @type {boolean} */ (reader.readBool());
        msg.setStaffRostering(value14);
        break;
      case 15:
        var value15 = /** @type {string} */ (reader.readString());
        msg.setSisType(value15);
        break;
      case 16:
        var value16 = /** @type {string} */ (reader.readString());
        msg.setAccessToken(value16);
        break;
      case 17:
        var value17 = /** @type {string} */ (reader.readString());
        msg.setApplicationId(value17);
        break;
      case 18:
        var value18 = /** @type {boolean} */ (reader.readBool());
        msg.setParentRostering(value18);
        break;
      case 19:
        var value19 = /** @type {string} */ (reader.readString());
        msg.addNotificationEmails(value19);
        break;
      case 20:
        var value20 = /** @type {string} */ (reader.readString());
        msg.setIdNumberFieldName(value20);
        break;
      case 21:
        var value21 = /** @type {boolean} */ (reader.readBool());
        msg.setTeacherRostering(value21);
        break;
      case 22:
        var value22 = /** @type {boolean} */ (reader.readBool());
        msg.setAdminRostering(value22);
        break;
      case 23:
        var value23 = /** @type {boolean} */ (reader.readBool());
        msg.setUpdateExistingStudents(value23);
        break;
      case 24:
        var value24 = /** @type {boolean} */ (reader.readBool());
        msg.setUpdateExistingStaff(value24);
        break;
      case 25:
        var value25 = /** @type {boolean} */ (reader.readBool());
        msg.setUpdateExistingTeachers(value25);
        break;
      case 26:
        var value26 = /** @type {boolean} */ (reader.readBool());
        msg.setUpdateExistingAdmins(value26);
        break;
      case 27:
        var value27 = /** @type {boolean} */ (reader.readBool());
        msg.setArchiveMissingStudents(value27);
        break;
      case 28:
        var value28 = /** @type {boolean} */ (reader.readBool());
        msg.setArchiveMissingStaff(value28);
        break;
      case 29:
        var value29 = /** @type {boolean} */ (reader.readBool());
        msg.setArchiveMissingTeachers(value29);
        break;
      case 30:
        var value30 = /** @type {boolean} */ (reader.readBool());
        msg.setArchiveMissingAdmins(value30);
        break;
      case 31:
        var value31 = /** @type {boolean} */ (reader.readBool());
        msg.setClassListRostering(value31);
        break;
      case 32:
        var value32 = /** @type {string} */ (reader.readString());
        msg.addTermSourcedIds(value32);
        break;
      case 33:
        var value33 = /** @type {boolean} */ (reader.readBool());
        msg.setTermSourcedIdsNull(value33);
        break;
      case 34:
        var value34 = /** @type {string} */ (reader.readString());
        msg.addGrades(value34);
        break;
      case 35:
        var value35 = /** @type {boolean} */ (reader.readBool());
        msg.setGradesNull(value35);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!sisFields} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: sisFields, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getUrl();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
    f = message.getClientId();
    if (f.length > 0) {
      writer.writeString(
        2,
        f
      );
    }
    f = message.getClientSecret();
    if (f.length > 0) {
      writer.writeString(
        3,
        f
      );
    }
    f = message.getSchoolId();
    if (f.length > 0) {
      writer.writeString(
        4,
        f
      );
    }
    f = message.getUpdateExisting();
    if (f) {
      writer.writeBool(
        5,
        f
      );
    }
    f = message.getArchiveMissing();
    if (f) {
      writer.writeBool(
        6,
        f
      );
    }
    f = message.getActive();
    if (f) {
      writer.writeBool(
        7,
        f
      );
    }
    f = message.getAuthUrl();
    if (f.length > 0) {
      writer.writeString(
        8,
        f
      );
    }
    f = message.getStudentUrl();
    if (f.length > 0) {
      writer.writeString(
        9,
        f
      );
    }
    f = message.getTeacherUrl();
    if (f.length > 0) {
      writer.writeString(
        10,
        f
      );
    }
    f = message.getLastModified();
    if (f) {
      writer.writeBool(
        11,
        f
      );
    }
    f = message.getRosteringMethod();
    if (f.length > 0) {
      writer.writeString(
        12,
        f
      );
    }
    f = message.getStudentRostering();
    if (f) {
      writer.writeBool(
        13,
        f
      );
    }
    f = message.getStaffRostering();
    if (f) {
      writer.writeBool(
        14,
        f
      );
    }
    f = message.getSisType();
    if (f.length > 0) {
      writer.writeString(
        15,
        f
      );
    }
    f = message.getAccessToken();
    if (f.length > 0) {
      writer.writeString(
        16,
        f
      );
    }
    f = message.getApplicationId();
    if (f.length > 0) {
      writer.writeString(
        17,
        f
      );
    }
    f = message.getParentRostering();
    if (f) {
      writer.writeBool(
        18,
        f
      );
    }
    f = message.getNotificationEmailsList();
    if (f.length > 0) {
      writer.writeRepeatedString(
        19,
        f
      );
    }
    f = message.getIdNumberFieldName();
    if (f.length > 0) {
      writer.writeString(
        20,
        f
      );
    }
    f = message.getTeacherRostering();
    if (f) {
      writer.writeBool(
        21,
        f
      );
    }
    f = message.getAdminRostering();
    if (f) {
      writer.writeBool(
        22,
        f
      );
    }
    f = message.getUpdateExistingStudents();
    if (f) {
      writer.writeBool(
        23,
        f
      );
    }
    f = message.getUpdateExistingStaff();
    if (f) {
      writer.writeBool(
        24,
        f
      );
    }
    f = message.getUpdateExistingTeachers();
    if (f) {
      writer.writeBool(
        25,
        f
      );
    }
    f = message.getUpdateExistingAdmins();
    if (f) {
      writer.writeBool(
        26,
        f
      );
    }
    f = message.getArchiveMissingStudents();
    if (f) {
      writer.writeBool(
        27,
        f
      );
    }
    f = message.getArchiveMissingStaff();
    if (f) {
      writer.writeBool(
        28,
        f
      );
    }
    f = message.getArchiveMissingTeachers();
    if (f) {
      writer.writeBool(
        29,
        f
      );
    }
    f = message.getArchiveMissingAdmins();
    if (f) {
      writer.writeBool(
        30,
        f
      );
    }
    f = message.getClassListRostering();
    if (f) {
      writer.writeBool(
        31,
        f
      );
    }
    f = message.getTermSourcedIdsList();
    if (f.length > 0) {
      writer.writeRepeatedString(
        32,
        f
      );
    }
    f = message.getTermSourcedIdsNull();
    if (f) {
      writer.writeBool(
        33,
        f
      );
    }
    f = message.getGradesList();
    if (f.length > 0) {
      writer.writeRepeatedString(
        34,
        f
      );
    }
    f = message.getGradesNull();
    if (f) {
      writer.writeBool(
        35,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    sisFields.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string url = 1;
   * @return {string}
   */
  getUrl(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setUrl(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


  /**
   * optional string client_id = 2;
   * @return {string}
   */
  getClientId(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
  };


  /** @param {string} value */
  setClientId(value: string) {
    jspb.Message.setProto3StringField(this, 2, value);
  }


  /**
   * optional string client_secret = 3;
   * @return {string}
   */
  getClientSecret(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
  };


  /** @param {string} value */
  setClientSecret(value: string) {
    jspb.Message.setProto3StringField(this, 3, value);
  }


  /**
   * optional string school_id = 4;
   * @return {string}
   */
  getSchoolId(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
  };


  /** @param {string} value */
  setSchoolId(value: string) {
    jspb.Message.setProto3StringField(this, 4, value);
  }


  /**
   * optional bool update_existing = 5;
   * @return {boolean}
   */
  getUpdateExisting(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
  };


  /** @param {boolean} value */
  setUpdateExisting(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 5, value);
  }


  /**
   * optional bool archive_missing = 6;
   * @return {boolean}
   */
  getArchiveMissing(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
  };


  /** @param {boolean} value */
  setArchiveMissing(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 6, value);
  }


  /**
   * optional bool active = 7;
   * @return {boolean}
   */
  getActive(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
  };


  /** @param {boolean} value */
  setActive(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 7, value);
  }


  /**
   * optional string auth_url = 8;
   * @return {string}
   */
  getAuthUrl(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
  };


  /** @param {string} value */
  setAuthUrl(value: string) {
    jspb.Message.setProto3StringField(this, 8, value);
  }


  /**
   * optional string student_url = 9;
   * @return {string}
   */
  getStudentUrl(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
  };


  /** @param {string} value */
  setStudentUrl(value: string) {
    jspb.Message.setProto3StringField(this, 9, value);
  }


  /**
   * optional string teacher_url = 10;
   * @return {string}
   */
  getTeacherUrl(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
  };


  /** @param {string} value */
  setTeacherUrl(value: string) {
    jspb.Message.setProto3StringField(this, 10, value);
  }


  /**
   * optional bool last_modified = 11;
   * @return {boolean}
   */
  getLastModified(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
  };


  /** @param {boolean} value */
  setLastModified(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 11, value);
  }


  /**
   * optional string rostering_method = 12;
   * @return {string}
   */
  getRosteringMethod(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
  };


  /** @param {string} value */
  setRosteringMethod(value: string) {
    jspb.Message.setProto3StringField(this, 12, value);
  }


  /**
   * optional bool student_rostering = 13;
   * @return {boolean}
   */
  getStudentRostering(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 13, false));
  };


  /** @param {boolean} value */
  setStudentRostering(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 13, value);
  }


  /**
   * optional bool staff_rostering = 14;
   * @return {boolean}
   */
  getStaffRostering(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 14, false));
  };


  /** @param {boolean} value */
  setStaffRostering(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 14, value);
  }


  /**
   * optional string sis_type = 15;
   * @return {string}
   */
  getSisType(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
  };


  /** @param {string} value */
  setSisType(value: string) {
    jspb.Message.setProto3StringField(this, 15, value);
  }


  /**
   * optional string access_token = 16;
   * @return {string}
   */
  getAccessToken(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
  };


  /** @param {string} value */
  setAccessToken(value: string) {
    jspb.Message.setProto3StringField(this, 16, value);
  }


  /**
   * optional string application_id = 17;
   * @return {string}
   */
  getApplicationId(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
  };


  /** @param {string} value */
  setApplicationId(value: string) {
    jspb.Message.setProto3StringField(this, 17, value);
  }


  /**
   * optional bool parent_rostering = 18;
   * @return {boolean}
   */
  getParentRostering(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 18, false));
  };


  /** @param {boolean} value */
  setParentRostering(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 18, value);
  }


  /**
   * repeated string notification_emails = 19;
   * @return {!Array<string>}
   */
  getNotificationEmailsList(): string[] {
    return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 19));
  };


  /** @param {!Array<string>} value */
  setNotificationEmailsList(value: string[]) {
    jspb.Message.setField(this, 19, value || []);
  }


  /**
   * @param {string} value
   * @param {number=} opt_index
   */
  addNotificationEmails(value: string, opt_index?: number): void {
    jspb.Message.addToRepeatedField(this, 19, value, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearNotificationEmailsList() {
    this.setNotificationEmailsList([]);
  }


  /**
   * optional string id_number_field_name = 20;
   * @return {string}
   */
  getIdNumberFieldName(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
  };


  /** @param {string} value */
  setIdNumberFieldName(value: string) {
    jspb.Message.setProto3StringField(this, 20, value);
  }


  /**
   * optional bool teacher_rostering = 21;
   * @return {boolean}
   */
  getTeacherRostering(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 21, false));
  };


  /** @param {boolean} value */
  setTeacherRostering(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 21, value);
  }


  /**
   * optional bool admin_rostering = 22;
   * @return {boolean}
   */
  getAdminRostering(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 22, false));
  };


  /** @param {boolean} value */
  setAdminRostering(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 22, value);
  }


  /**
   * optional bool update_existing_students = 23;
   * @return {boolean}
   */
  getUpdateExistingStudents(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 23, false));
  };


  /** @param {boolean} value */
  setUpdateExistingStudents(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 23, value);
  }


  /**
   * optional bool update_existing_staff = 24;
   * @return {boolean}
   */
  getUpdateExistingStaff(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 24, false));
  };


  /** @param {boolean} value */
  setUpdateExistingStaff(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 24, value);
  }


  /**
   * optional bool update_existing_teachers = 25;
   * @return {boolean}
   */
  getUpdateExistingTeachers(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 25, false));
  };


  /** @param {boolean} value */
  setUpdateExistingTeachers(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 25, value);
  }


  /**
   * optional bool update_existing_admins = 26;
   * @return {boolean}
   */
  getUpdateExistingAdmins(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 26, false));
  };


  /** @param {boolean} value */
  setUpdateExistingAdmins(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 26, value);
  }


  /**
   * optional bool archive_missing_students = 27;
   * @return {boolean}
   */
  getArchiveMissingStudents(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 27, false));
  };


  /** @param {boolean} value */
  setArchiveMissingStudents(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 27, value);
  }


  /**
   * optional bool archive_missing_staff = 28;
   * @return {boolean}
   */
  getArchiveMissingStaff(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 28, false));
  };


  /** @param {boolean} value */
  setArchiveMissingStaff(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 28, value);
  }


  /**
   * optional bool archive_missing_teachers = 29;
   * @return {boolean}
   */
  getArchiveMissingTeachers(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 29, false));
  };


  /** @param {boolean} value */
  setArchiveMissingTeachers(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 29, value);
  }


  /**
   * optional bool archive_missing_admins = 30;
   * @return {boolean}
   */
  getArchiveMissingAdmins(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 30, false));
  };


  /** @param {boolean} value */
  setArchiveMissingAdmins(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 30, value);
  }


  /**
   * optional bool class_list_rostering = 31;
   * @return {boolean}
   */
  getClassListRostering(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 31, false));
  };


  /** @param {boolean} value */
  setClassListRostering(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 31, value);
  }


  /**
   * repeated string term_sourced_ids = 32;
   * @return {!Array<string>}
   */
  getTermSourcedIdsList(): string[] {
    return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 32));
  };


  /** @param {!Array<string>} value */
  setTermSourcedIdsList(value: string[]) {
    jspb.Message.setField(this, 32, value || []);
  }


  /**
   * @param {string} value
   * @param {number=} opt_index
   */
  addTermSourcedIds(value: string, opt_index?: number): void {
    jspb.Message.addToRepeatedField(this, 32, value, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearTermSourcedIdsList() {
    this.setTermSourcedIdsList([]);
  }


  /**
   * optional bool term_sourced_ids_null = 33;
   * @return {boolean}
   */
  getTermSourcedIdsNull(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 33, false));
  };


  /** @param {boolean} value */
  setTermSourcedIdsNull(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 33, value);
  }


  /**
   * repeated string grades = 34;
   * @return {!Array<string>}
   */
  getGradesList(): string[] {
    return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 34));
  };


  /** @param {!Array<string>} value */
  setGradesList(value: string[]) {
    jspb.Message.setField(this, 34, value || []);
  }


  /**
   * @param {string} value
   * @param {number=} opt_index
   */
  addGrades(value: string, opt_index?: number): void {
    jspb.Message.addToRepeatedField(this, 34, value, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearGradesList() {
    this.setGradesList([]);
  }


  /**
   * optional bool grades_null = 35;
   * @return {boolean}
   */
  getGradesNull(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 35, false));
  };


  /** @param {boolean} value */
  setGradesNull(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 35, value);
  }


}
export namespace sisSyncLogTotal {
  export interface AsObject {
    totalUpdated: number;
    totalCreated: number;
    totalIgnored: number;
    totalArchived: number;
    totalErrors: number;
    totalRestored: number;
  }
}
export class sisSyncLogTotal extends jspb.Message {
  static readonly displayName = "sisSyncLogTotal";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): sisSyncLogTotal.AsObject {
    return sisSyncLogTotal.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!sisSyncLogTotal} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: sisSyncLogTotal) {
    var f: any;
    var obj: any = {
      totalUpdated: jspb.Message.getFieldWithDefault(msg, 1, 0),
      totalCreated: jspb.Message.getFieldWithDefault(msg, 2, 0),
      totalIgnored: jspb.Message.getFieldWithDefault(msg, 3, 0),
      totalArchived: jspb.Message.getFieldWithDefault(msg, 4, 0),
      totalErrors: jspb.Message.getFieldWithDefault(msg, 5, 0),
      totalRestored: jspb.Message.getFieldWithDefault(msg, 6, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!sisSyncLogTotal}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new sisSyncLogTotal;
    return sisSyncLogTotal.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!sisSyncLogTotal} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!sisSyncLogTotal}
   */
  static deserializeBinaryFromReader(msg: sisSyncLogTotal, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {number} */ (reader.readInt32());
        msg.setTotalUpdated(value1);
        break;
      case 2:
        var value2 = /** @type {number} */ (reader.readInt32());
        msg.setTotalCreated(value2);
        break;
      case 3:
        var value3 = /** @type {number} */ (reader.readInt32());
        msg.setTotalIgnored(value3);
        break;
      case 4:
        var value4 = /** @type {number} */ (reader.readInt32());
        msg.setTotalArchived(value4);
        break;
      case 5:
        var value5 = /** @type {number} */ (reader.readInt32());
        msg.setTotalErrors(value5);
        break;
      case 6:
        var value6 = /** @type {number} */ (reader.readInt32());
        msg.setTotalRestored(value6);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!sisSyncLogTotal} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: sisSyncLogTotal, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getTotalUpdated();
    if (f !== 0) {
      writer.writeInt32(
        1,
        f
      );
    }
    f = message.getTotalCreated();
    if (f !== 0) {
      writer.writeInt32(
        2,
        f
      );
    }
    f = message.getTotalIgnored();
    if (f !== 0) {
      writer.writeInt32(
        3,
        f
      );
    }
    f = message.getTotalArchived();
    if (f !== 0) {
      writer.writeInt32(
        4,
        f
      );
    }
    f = message.getTotalErrors();
    if (f !== 0) {
      writer.writeInt32(
        5,
        f
      );
    }
    f = message.getTotalRestored();
    if (f !== 0) {
      writer.writeInt32(
        6,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    sisSyncLogTotal.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional int32 total_updated = 1;
   * @return {number}
   */
  getTotalUpdated(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
  };


  /** @param {number} value */
  setTotalUpdated(value: number) {
    jspb.Message.setProto3IntField(this, 1, value);
  }


  /**
   * optional int32 total_created = 2;
   * @return {number}
   */
  getTotalCreated(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
  };


  /** @param {number} value */
  setTotalCreated(value: number) {
    jspb.Message.setProto3IntField(this, 2, value);
  }


  /**
   * optional int32 total_ignored = 3;
   * @return {number}
   */
  getTotalIgnored(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
  };


  /** @param {number} value */
  setTotalIgnored(value: number) {
    jspb.Message.setProto3IntField(this, 3, value);
  }


  /**
   * optional int32 total_archived = 4;
   * @return {number}
   */
  getTotalArchived(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
  };


  /** @param {number} value */
  setTotalArchived(value: number) {
    jspb.Message.setProto3IntField(this, 4, value);
  }


  /**
   * optional int32 total_errors = 5;
   * @return {number}
   */
  getTotalErrors(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
  };


  /** @param {number} value */
  setTotalErrors(value: number) {
    jspb.Message.setProto3IntField(this, 5, value);
  }


  /**
   * optional int32 total_restored = 6;
   * @return {number}
   */
  getTotalRestored(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
  };


  /** @param {number} value */
  setTotalRestored(value: number) {
    jspb.Message.setProto3IntField(this, 6, value);
  }


}
export namespace sisUnifiedSyncLog {
  export interface AsObject {
    summary?: sisSyncLog.AsObject;
    studentTotals?: sisSyncLogTotal.AsObject;
    teacherTotals?: sisSyncLogTotal.AsObject;
    staffTotals?: sisSyncLogTotal.AsObject;
    termTotals?: sisSyncLogTotal.AsObject;
    sectionTotals?: sisSyncLogTotal.AsObject;
    termFileLink: string;
    sectionFileLink: string;
    studentFileLink: string;
    teacherFileLink: string;
    staffFileLink: string;
  }
}
export class sisUnifiedSyncLog extends jspb.Message {
  static readonly displayName = "sisUnifiedSyncLog";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): sisUnifiedSyncLog.AsObject {
    return sisUnifiedSyncLog.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!sisUnifiedSyncLog} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: sisUnifiedSyncLog) {
    var f: any;
    var obj: any = {
      summary: (f = msg.getSummary()) && sisSyncLog.toObject(includeInstance, f),
      studentTotals: (f = msg.getStudentTotals()) && sisSyncLogTotal.toObject(includeInstance, f),
      teacherTotals: (f = msg.getTeacherTotals()) && sisSyncLogTotal.toObject(includeInstance, f),
      staffTotals: (f = msg.getStaffTotals()) && sisSyncLogTotal.toObject(includeInstance, f),
      termTotals: (f = msg.getTermTotals()) && sisSyncLogTotal.toObject(includeInstance, f),
      sectionTotals: (f = msg.getSectionTotals()) && sisSyncLogTotal.toObject(includeInstance, f),
      termFileLink: jspb.Message.getFieldWithDefault(msg, 7, ""),
      sectionFileLink: jspb.Message.getFieldWithDefault(msg, 8, ""),
      studentFileLink: jspb.Message.getFieldWithDefault(msg, 9, ""),
      teacherFileLink: jspb.Message.getFieldWithDefault(msg, 10, ""),
      staffFileLink: jspb.Message.getFieldWithDefault(msg, 11, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!sisUnifiedSyncLog}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new sisUnifiedSyncLog;
    return sisUnifiedSyncLog.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!sisUnifiedSyncLog} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!sisUnifiedSyncLog}
   */
  static deserializeBinaryFromReader(msg: sisUnifiedSyncLog, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new sisSyncLog;
        reader.readMessage(value1,sisSyncLog.deserializeBinaryFromReader);
        msg.setSummary(value1);
        break;
      case 2:
        var value2 = new sisSyncLogTotal;
        reader.readMessage(value2,sisSyncLogTotal.deserializeBinaryFromReader);
        msg.setStudentTotals(value2);
        break;
      case 3:
        var value3 = new sisSyncLogTotal;
        reader.readMessage(value3,sisSyncLogTotal.deserializeBinaryFromReader);
        msg.setTeacherTotals(value3);
        break;
      case 4:
        var value4 = new sisSyncLogTotal;
        reader.readMessage(value4,sisSyncLogTotal.deserializeBinaryFromReader);
        msg.setStaffTotals(value4);
        break;
      case 5:
        var value5 = new sisSyncLogTotal;
        reader.readMessage(value5,sisSyncLogTotal.deserializeBinaryFromReader);
        msg.setTermTotals(value5);
        break;
      case 6:
        var value6 = new sisSyncLogTotal;
        reader.readMessage(value6,sisSyncLogTotal.deserializeBinaryFromReader);
        msg.setSectionTotals(value6);
        break;
      case 7:
        var value7 = /** @type {string} */ (reader.readString());
        msg.setTermFileLink(value7);
        break;
      case 8:
        var value8 = /** @type {string} */ (reader.readString());
        msg.setSectionFileLink(value8);
        break;
      case 9:
        var value9 = /** @type {string} */ (reader.readString());
        msg.setStudentFileLink(value9);
        break;
      case 10:
        var value10 = /** @type {string} */ (reader.readString());
        msg.setTeacherFileLink(value10);
        break;
      case 11:
        var value11 = /** @type {string} */ (reader.readString());
        msg.setStaffFileLink(value11);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!sisUnifiedSyncLog} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: sisUnifiedSyncLog, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getSummary();
    if (f != null) {
      writer.writeMessage(
        1,
        f,
        sisSyncLog.serializeBinaryToWriter
      );
    }
    f = message.getStudentTotals();
    if (f != null) {
      writer.writeMessage(
        2,
        f,
        sisSyncLogTotal.serializeBinaryToWriter
      );
    }
    f = message.getTeacherTotals();
    if (f != null) {
      writer.writeMessage(
        3,
        f,
        sisSyncLogTotal.serializeBinaryToWriter
      );
    }
    f = message.getStaffTotals();
    if (f != null) {
      writer.writeMessage(
        4,
        f,
        sisSyncLogTotal.serializeBinaryToWriter
      );
    }
    f = message.getTermTotals();
    if (f != null) {
      writer.writeMessage(
        5,
        f,
        sisSyncLogTotal.serializeBinaryToWriter
      );
    }
    f = message.getSectionTotals();
    if (f != null) {
      writer.writeMessage(
        6,
        f,
        sisSyncLogTotal.serializeBinaryToWriter
      );
    }
    f = message.getTermFileLink();
    if (f.length > 0) {
      writer.writeString(
        7,
        f
      );
    }
    f = message.getSectionFileLink();
    if (f.length > 0) {
      writer.writeString(
        8,
        f
      );
    }
    f = message.getStudentFileLink();
    if (f.length > 0) {
      writer.writeString(
        9,
        f
      );
    }
    f = message.getTeacherFileLink();
    if (f.length > 0) {
      writer.writeString(
        10,
        f
      );
    }
    f = message.getStaffFileLink();
    if (f.length > 0) {
      writer.writeString(
        11,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    sisUnifiedSyncLog.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional sisSyncLog summary = 1;
   * @return {?sisSyncLog}
   */
  getSummary(): sisSyncLog {
    return /** @type{?sisSyncLog} */ (
      jspb.Message.getWrapperField(this, sisSyncLog, 1));
  }


  /** @param {?sisSyncLog|undefined} value */
  setSummary(value?: sisSyncLog) {
    jspb.Message.setWrapperField(this, 1, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearSummary() {
    this.setSummary(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasSummary(): boolean {
    return jspb.Message.getField(this, 1) != null;
  }


  /**
   * optional sisSyncLogTotal student_totals = 2;
   * @return {?sisSyncLogTotal}
   */
  getStudentTotals(): sisSyncLogTotal {
    return /** @type{?sisSyncLogTotal} */ (
      jspb.Message.getWrapperField(this, sisSyncLogTotal, 2));
  }


  /** @param {?sisSyncLogTotal|undefined} value */
  setStudentTotals(value?: sisSyncLogTotal) {
    jspb.Message.setWrapperField(this, 2, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearStudentTotals() {
    this.setStudentTotals(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasStudentTotals(): boolean {
    return jspb.Message.getField(this, 2) != null;
  }


  /**
   * optional sisSyncLogTotal teacher_totals = 3;
   * @return {?sisSyncLogTotal}
   */
  getTeacherTotals(): sisSyncLogTotal {
    return /** @type{?sisSyncLogTotal} */ (
      jspb.Message.getWrapperField(this, sisSyncLogTotal, 3));
  }


  /** @param {?sisSyncLogTotal|undefined} value */
  setTeacherTotals(value?: sisSyncLogTotal) {
    jspb.Message.setWrapperField(this, 3, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearTeacherTotals() {
    this.setTeacherTotals(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasTeacherTotals(): boolean {
    return jspb.Message.getField(this, 3) != null;
  }


  /**
   * optional sisSyncLogTotal staff_totals = 4;
   * @return {?sisSyncLogTotal}
   */
  getStaffTotals(): sisSyncLogTotal {
    return /** @type{?sisSyncLogTotal} */ (
      jspb.Message.getWrapperField(this, sisSyncLogTotal, 4));
  }


  /** @param {?sisSyncLogTotal|undefined} value */
  setStaffTotals(value?: sisSyncLogTotal) {
    jspb.Message.setWrapperField(this, 4, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearStaffTotals() {
    this.setStaffTotals(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasStaffTotals(): boolean {
    return jspb.Message.getField(this, 4) != null;
  }


  /**
   * optional sisSyncLogTotal term_totals = 5;
   * @return {?sisSyncLogTotal}
   */
  getTermTotals(): sisSyncLogTotal {
    return /** @type{?sisSyncLogTotal} */ (
      jspb.Message.getWrapperField(this, sisSyncLogTotal, 5));
  }


  /** @param {?sisSyncLogTotal|undefined} value */
  setTermTotals(value?: sisSyncLogTotal) {
    jspb.Message.setWrapperField(this, 5, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearTermTotals() {
    this.setTermTotals(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasTermTotals(): boolean {
    return jspb.Message.getField(this, 5) != null;
  }


  /**
   * optional sisSyncLogTotal section_totals = 6;
   * @return {?sisSyncLogTotal}
   */
  getSectionTotals(): sisSyncLogTotal {
    return /** @type{?sisSyncLogTotal} */ (
      jspb.Message.getWrapperField(this, sisSyncLogTotal, 6));
  }


  /** @param {?sisSyncLogTotal|undefined} value */
  setSectionTotals(value?: sisSyncLogTotal) {
    jspb.Message.setWrapperField(this, 6, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearSectionTotals() {
    this.setSectionTotals(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasSectionTotals(): boolean {
    return jspb.Message.getField(this, 6) != null;
  }


  /**
   * optional string term_file_link = 7;
   * @return {string}
   */
  getTermFileLink(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
  };


  /** @param {string} value */
  setTermFileLink(value: string) {
    jspb.Message.setProto3StringField(this, 7, value);
  }


  /**
   * optional string section_file_link = 8;
   * @return {string}
   */
  getSectionFileLink(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
  };


  /** @param {string} value */
  setSectionFileLink(value: string) {
    jspb.Message.setProto3StringField(this, 8, value);
  }


  /**
   * optional string student_file_link = 9;
   * @return {string}
   */
  getStudentFileLink(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
  };


  /** @param {string} value */
  setStudentFileLink(value: string) {
    jspb.Message.setProto3StringField(this, 9, value);
  }


  /**
   * optional string teacher_file_link = 10;
   * @return {string}
   */
  getTeacherFileLink(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
  };


  /** @param {string} value */
  setTeacherFileLink(value: string) {
    jspb.Message.setProto3StringField(this, 10, value);
  }


  /**
   * optional string staff_file_link = 11;
   * @return {string}
   */
  getStaffFileLink(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
  };


  /** @param {string} value */
  setStaffFileLink(value: string) {
    jspb.Message.setProto3StringField(this, 11, value);
  }


}
export namespace sisSyncLog {
  export interface AsObject {
    numUpdated: number;
    numCreated: number;
    numIgnored: number;
    fileLink: string;
    updatedAt?: common_date_pb.DateTime.AsObject;
    numArchived: number;
    numErrors: number;
    type: string;
    numRestored: number;
  }
}
export class sisSyncLog extends jspb.Message {
  static readonly displayName = "sisSyncLog";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): sisSyncLog.AsObject {
    return sisSyncLog.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!sisSyncLog} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: sisSyncLog) {
    var f: any;
    var obj: any = {
      numupdated: jspb.Message.getFieldWithDefault(msg, 1, 0),
      numcreated: jspb.Message.getFieldWithDefault(msg, 2, 0),
      numignored: jspb.Message.getFieldWithDefault(msg, 3, 0),
      filelink: jspb.Message.getFieldWithDefault(msg, 4, ""),
      updatedat: (f = msg.getUpdatedat()) && common_date_pb.DateTime.toObject(includeInstance, f),
      numarchived: jspb.Message.getFieldWithDefault(msg, 6, 0),
      numerrors: jspb.Message.getFieldWithDefault(msg, 7, 0),
      type: jspb.Message.getFieldWithDefault(msg, 8, ""),
      numRestored: jspb.Message.getFieldWithDefault(msg, 9, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!sisSyncLog}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new sisSyncLog;
    return sisSyncLog.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!sisSyncLog} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!sisSyncLog}
   */
  static deserializeBinaryFromReader(msg: sisSyncLog, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {number} */ (reader.readInt32());
        msg.setNumupdated(value1);
        break;
      case 2:
        var value2 = /** @type {number} */ (reader.readInt32());
        msg.setNumcreated(value2);
        break;
      case 3:
        var value3 = /** @type {number} */ (reader.readInt32());
        msg.setNumignored(value3);
        break;
      case 4:
        var value4 = /** @type {string} */ (reader.readString());
        msg.setFilelink(value4);
        break;
      case 5:
        var value5 = new common_date_pb.DateTime;
        reader.readMessage(value5,common_date_pb.DateTime.deserializeBinaryFromReader);
        msg.setUpdatedat(value5);
        break;
      case 6:
        var value6 = /** @type {number} */ (reader.readInt32());
        msg.setNumarchived(value6);
        break;
      case 7:
        var value7 = /** @type {number} */ (reader.readInt32());
        msg.setNumerrors(value7);
        break;
      case 8:
        var value8 = /** @type {string} */ (reader.readString());
        msg.setType(value8);
        break;
      case 9:
        var value9 = /** @type {number} */ (reader.readInt32());
        msg.setNumRestored(value9);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!sisSyncLog} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: sisSyncLog, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getNumupdated();
    if (f !== 0) {
      writer.writeInt32(
        1,
        f
      );
    }
    f = message.getNumcreated();
    if (f !== 0) {
      writer.writeInt32(
        2,
        f
      );
    }
    f = message.getNumignored();
    if (f !== 0) {
      writer.writeInt32(
        3,
        f
      );
    }
    f = message.getFilelink();
    if (f.length > 0) {
      writer.writeString(
        4,
        f
      );
    }
    f = message.getUpdatedat();
    if (f != null) {
      writer.writeMessage(
        5,
        f,
        common_date_pb.DateTime.serializeBinaryToWriter
      );
    }
    f = message.getNumarchived();
    if (f !== 0) {
      writer.writeInt32(
        6,
        f
      );
    }
    f = message.getNumerrors();
    if (f !== 0) {
      writer.writeInt32(
        7,
        f
      );
    }
    f = message.getType();
    if (f.length > 0) {
      writer.writeString(
        8,
        f
      );
    }
    f = message.getNumRestored();
    if (f !== 0) {
      writer.writeInt32(
        9,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    sisSyncLog.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional int32 numUpdated = 1;
   * @return {number}
   */
  getNumupdated(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
  };


  /** @param {number} value */
  setNumupdated(value: number) {
    jspb.Message.setProto3IntField(this, 1, value);
  }


  /**
   * optional int32 numCreated = 2;
   * @return {number}
   */
  getNumcreated(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
  };


  /** @param {number} value */
  setNumcreated(value: number) {
    jspb.Message.setProto3IntField(this, 2, value);
  }


  /**
   * optional int32 numIgnored = 3;
   * @return {number}
   */
  getNumignored(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
  };


  /** @param {number} value */
  setNumignored(value: number) {
    jspb.Message.setProto3IntField(this, 3, value);
  }


  /**
   * optional string fileLink = 4;
   * @return {string}
   */
  getFilelink(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
  };


  /** @param {string} value */
  setFilelink(value: string) {
    jspb.Message.setProto3StringField(this, 4, value);
  }


  /**
   * optional minga.common.DateTime updatedAt = 5;
   * @return {?DateTime}
   */
  getUpdatedat(): common_date_pb.DateTime {
    return /** @type{?DateTime} */ (
      jspb.Message.getWrapperField(this, common_date_pb.DateTime, 5));
  }


  /** @param {?DateTime|undefined} value */
  setUpdatedat(value?: common_date_pb.DateTime) {
    jspb.Message.setWrapperField(this, 5, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearUpdatedat() {
    this.setUpdatedat(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasUpdatedat(): boolean {
    return jspb.Message.getField(this, 5) != null;
  }


  /**
   * optional int32 numArchived = 6;
   * @return {number}
   */
  getNumarchived(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
  };


  /** @param {number} value */
  setNumarchived(value: number) {
    jspb.Message.setProto3IntField(this, 6, value);
  }


  /**
   * optional int32 numErrors = 7;
   * @return {number}
   */
  getNumerrors(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
  };


  /** @param {number} value */
  setNumerrors(value: number) {
    jspb.Message.setProto3IntField(this, 7, value);
  }


  /**
   * optional string type = 8;
   * @return {string}
   */
  getType(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
  };


  /** @param {string} value */
  setType(value: string) {
    jspb.Message.setProto3StringField(this, 8, value);
  }


  /**
   * optional int32 num_restored = 9;
   * @return {number}
   */
  getNumRestored(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
  };


  /** @param {number} value */
  setNumRestored(value: number) {
    jspb.Message.setProto3IntField(this, 9, value);
  }


}
export namespace UpdateSISRequest {
  export interface AsObject {
    fields?: sisFields.AsObject;
  }
}
export class UpdateSISRequest extends jspb.Message {
  static readonly displayName = "UpdateSISRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): UpdateSISRequest.AsObject {
    return UpdateSISRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!UpdateSISRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: UpdateSISRequest) {
    var f: any;
    var obj: any = {
      fields: (f = msg.getFields()) && sisFields.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!UpdateSISRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new UpdateSISRequest;
    return UpdateSISRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!UpdateSISRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!UpdateSISRequest}
   */
  static deserializeBinaryFromReader(msg: UpdateSISRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new sisFields;
        reader.readMessage(value1,sisFields.deserializeBinaryFromReader);
        msg.setFields(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!UpdateSISRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: UpdateSISRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getFields();
    if (f != null) {
      writer.writeMessage(
        1,
        f,
        sisFields.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    UpdateSISRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional sisFields fields = 1;
   * @return {?sisFields}
   */
  getFields(): sisFields {
    return /** @type{?sisFields} */ (
      jspb.Message.getWrapperField(this, sisFields, 1));
  }


  /** @param {?sisFields|undefined} value */
  setFields(value?: sisFields) {
    jspb.Message.setWrapperField(this, 1, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearFields() {
    this.setFields(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasFields(): boolean {
    return jspb.Message.getField(this, 1) != null;
  }


}
export namespace UpdateSISResponse {
  export interface AsObject {
  }
}
export class UpdateSISResponse extends jspb.Message {
  static readonly displayName = "UpdateSISResponse";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): UpdateSISResponse.AsObject {
    return UpdateSISResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!UpdateSISResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: UpdateSISResponse) {
    var f: any;
    var obj: any = {

    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!UpdateSISResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new UpdateSISResponse;
    return UpdateSISResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!UpdateSISResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!UpdateSISResponse}
   */
  static deserializeBinaryFromReader(msg: UpdateSISResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!UpdateSISResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: UpdateSISResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    UpdateSISResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
}
export namespace GetSISRequest {
  export interface AsObject {
  }
}
export class GetSISRequest extends jspb.Message {
  static readonly displayName = "GetSISRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): GetSISRequest.AsObject {
    return GetSISRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!GetSISRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: GetSISRequest) {
    var f: any;
    var obj: any = {

    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!GetSISRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new GetSISRequest;
    return GetSISRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!GetSISRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!GetSISRequest}
   */
  static deserializeBinaryFromReader(msg: GetSISRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!GetSISRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: GetSISRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    GetSISRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
}
export namespace GetSISResponse {
  export interface AsObject {
    fieldsList: sisFields.AsObject[];
  }
}
export class GetSISResponse extends jspb.Message {
  static readonly displayName = "GetSISResponse";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [1];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, GetSISResponse.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): GetSISResponse.AsObject {
    return GetSISResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!GetSISResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: GetSISResponse) {
    var f: any;
    var obj: any = {
      fieldsList: jspb.Message.toObjectList(msg.getFieldsList(),
      sisFields.toObject, includeInstance)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!GetSISResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new GetSISResponse;
    return GetSISResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!GetSISResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!GetSISResponse}
   */
  static deserializeBinaryFromReader(msg: GetSISResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new sisFields;
        reader.readMessage(value1,sisFields.deserializeBinaryFromReader);
        msg.addFields(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!GetSISResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: GetSISResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getFieldsList();
    if (f.length > 0) {
      writer.writeRepeatedMessage(
        1,
        f,
        sisFields.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    GetSISResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * repeated sisFields fields = 1;
   * @return {!Array<!sisFields>}
   */
  getFieldsList(): sisFields[] {
    return /** @type{!Array<!sisFields>} */ (
      jspb.Message.getRepeatedWrapperField(this, sisFields, 1));
  }


  /** @param {!Array<!sisFields>} value */
  setFieldsList(value?: sisFields[]) {
    jspb.Message.setRepeatedWrapperField(this, 1, value);
  }


  /**
   * @param {!sisFields=} opt_value
   * @param {number=} opt_index
   * @return {!sisFields}
   */
  addFields(opt_value?: sisFields, opt_index?: number): sisFields {
    return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, sisFields, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearFieldsList() {
    this.setFieldsList([]);
  }


}
export namespace GetSISErrorDetailsRequest {
  export interface AsObject {
  }
}
export class GetSISErrorDetailsRequest extends jspb.Message {
  static readonly displayName = "GetSISErrorDetailsRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): GetSISErrorDetailsRequest.AsObject {
    return GetSISErrorDetailsRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!GetSISErrorDetailsRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: GetSISErrorDetailsRequest) {
    var f: any;
    var obj: any = {

    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!GetSISErrorDetailsRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new GetSISErrorDetailsRequest;
    return GetSISErrorDetailsRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!GetSISErrorDetailsRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!GetSISErrorDetailsRequest}
   */
  static deserializeBinaryFromReader(msg: GetSISErrorDetailsRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!GetSISErrorDetailsRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: GetSISErrorDetailsRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    GetSISErrorDetailsRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
}
export namespace GetSISErrorDetailsResponse {
  export interface AsObject {
    error: string;
  }
}
export class GetSISErrorDetailsResponse extends jspb.Message {
  static readonly displayName = "GetSISErrorDetailsResponse";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): GetSISErrorDetailsResponse.AsObject {
    return GetSISErrorDetailsResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!GetSISErrorDetailsResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: GetSISErrorDetailsResponse) {
    var f: any;
    var obj: any = {
      error: jspb.Message.getFieldWithDefault(msg, 1, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!GetSISErrorDetailsResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new GetSISErrorDetailsResponse;
    return GetSISErrorDetailsResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!GetSISErrorDetailsResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!GetSISErrorDetailsResponse}
   */
  static deserializeBinaryFromReader(msg: GetSISErrorDetailsResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setError(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!GetSISErrorDetailsResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: GetSISErrorDetailsResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getError();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    GetSISErrorDetailsResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string error = 1;
   * @return {string}
   */
  getError(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setError(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


}
export namespace DeleteSISRequest {
  export interface AsObject {
    rosteringMethod: string;
  }
}
export class DeleteSISRequest extends jspb.Message {
  static readonly displayName = "DeleteSISRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): DeleteSISRequest.AsObject {
    return DeleteSISRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!DeleteSISRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: DeleteSISRequest) {
    var f: any;
    var obj: any = {
      rosteringMethod: jspb.Message.getFieldWithDefault(msg, 1, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!DeleteSISRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new DeleteSISRequest;
    return DeleteSISRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!DeleteSISRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!DeleteSISRequest}
   */
  static deserializeBinaryFromReader(msg: DeleteSISRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setRosteringMethod(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!DeleteSISRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: DeleteSISRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getRosteringMethod();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    DeleteSISRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string rostering_method = 1;
   * @return {string}
   */
  getRosteringMethod(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setRosteringMethod(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


}
export namespace DeleteSISResponse {
  export interface AsObject {
  }
}
export class DeleteSISResponse extends jspb.Message {
  static readonly displayName = "DeleteSISResponse";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): DeleteSISResponse.AsObject {
    return DeleteSISResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!DeleteSISResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: DeleteSISResponse) {
    var f: any;
    var obj: any = {

    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!DeleteSISResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new DeleteSISResponse;
    return DeleteSISResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!DeleteSISResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!DeleteSISResponse}
   */
  static deserializeBinaryFromReader(msg: DeleteSISResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!DeleteSISResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: DeleteSISResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    DeleteSISResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
}
export namespace ActivateRosteringMethodRequest {
  export interface AsObject {
    rosteringMethod: string;
  }
}
export class ActivateRosteringMethodRequest extends jspb.Message {
  static readonly displayName = "ActivateRosteringMethodRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): ActivateRosteringMethodRequest.AsObject {
    return ActivateRosteringMethodRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!ActivateRosteringMethodRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: ActivateRosteringMethodRequest) {
    var f: any;
    var obj: any = {
      rosteringMethod: jspb.Message.getFieldWithDefault(msg, 1, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!ActivateRosteringMethodRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new ActivateRosteringMethodRequest;
    return ActivateRosteringMethodRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!ActivateRosteringMethodRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!ActivateRosteringMethodRequest}
   */
  static deserializeBinaryFromReader(msg: ActivateRosteringMethodRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setRosteringMethod(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!ActivateRosteringMethodRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: ActivateRosteringMethodRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getRosteringMethod();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    ActivateRosteringMethodRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string rostering_method = 1;
   * @return {string}
   */
  getRosteringMethod(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setRosteringMethod(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


}
export namespace ActivateRosteringMethodResponse {
  export interface AsObject {
  }
}
export class ActivateRosteringMethodResponse extends jspb.Message {
  static readonly displayName = "ActivateRosteringMethodResponse";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): ActivateRosteringMethodResponse.AsObject {
    return ActivateRosteringMethodResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!ActivateRosteringMethodResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: ActivateRosteringMethodResponse) {
    var f: any;
    var obj: any = {

    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!ActivateRosteringMethodResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new ActivateRosteringMethodResponse;
    return ActivateRosteringMethodResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!ActivateRosteringMethodResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!ActivateRosteringMethodResponse}
   */
  static deserializeBinaryFromReader(msg: ActivateRosteringMethodResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!ActivateRosteringMethodResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: ActivateRosteringMethodResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    ActivateRosteringMethodResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
}
export namespace DeactivateAllRosteringMethodsRequest {
  export interface AsObject {
  }
}
export class DeactivateAllRosteringMethodsRequest extends jspb.Message {
  static readonly displayName = "DeactivateAllRosteringMethodsRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): DeactivateAllRosteringMethodsRequest.AsObject {
    return DeactivateAllRosteringMethodsRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!DeactivateAllRosteringMethodsRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: DeactivateAllRosteringMethodsRequest) {
    var f: any;
    var obj: any = {

    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!DeactivateAllRosteringMethodsRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new DeactivateAllRosteringMethodsRequest;
    return DeactivateAllRosteringMethodsRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!DeactivateAllRosteringMethodsRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!DeactivateAllRosteringMethodsRequest}
   */
  static deserializeBinaryFromReader(msg: DeactivateAllRosteringMethodsRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!DeactivateAllRosteringMethodsRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: DeactivateAllRosteringMethodsRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    DeactivateAllRosteringMethodsRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
}
export namespace DeactivateAllRosteringMethodsResponse {
  export interface AsObject {
  }
}
export class DeactivateAllRosteringMethodsResponse extends jspb.Message {
  static readonly displayName = "DeactivateAllRosteringMethodsResponse";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): DeactivateAllRosteringMethodsResponse.AsObject {
    return DeactivateAllRosteringMethodsResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!DeactivateAllRosteringMethodsResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: DeactivateAllRosteringMethodsResponse) {
    var f: any;
    var obj: any = {

    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!DeactivateAllRosteringMethodsResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new DeactivateAllRosteringMethodsResponse;
    return DeactivateAllRosteringMethodsResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!DeactivateAllRosteringMethodsResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!DeactivateAllRosteringMethodsResponse}
   */
  static deserializeBinaryFromReader(msg: DeactivateAllRosteringMethodsResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!DeactivateAllRosteringMethodsResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: DeactivateAllRosteringMethodsResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    DeactivateAllRosteringMethodsResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
}
export namespace SchoolInfo {
  export interface AsObject {
    id: string;
    name: string;
  }
}
export class SchoolInfo extends jspb.Message {
  static readonly displayName = "SchoolInfo";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): SchoolInfo.AsObject {
    return SchoolInfo.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!SchoolInfo} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: SchoolInfo) {
    var f: any;
    var obj: any = {
      id: jspb.Message.getFieldWithDefault(msg, 1, ""),
      name: jspb.Message.getFieldWithDefault(msg, 2, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!SchoolInfo}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new SchoolInfo;
    return SchoolInfo.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!SchoolInfo} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!SchoolInfo}
   */
  static deserializeBinaryFromReader(msg: SchoolInfo, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setId(value1);
        break;
      case 2:
        var value2 = /** @type {string} */ (reader.readString());
        msg.setName(value2);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!SchoolInfo} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: SchoolInfo, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getId();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
    f = message.getName();
    if (f.length > 0) {
      writer.writeString(
        2,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    SchoolInfo.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string id = 1;
   * @return {string}
   */
  getId(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setId(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


  /**
   * optional string name = 2;
   * @return {string}
   */
  getName(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
  };


  /** @param {string} value */
  setName(value: string) {
    jspb.Message.setProto3StringField(this, 2, value);
  }


}
export namespace TestSISRequest {
  export interface AsObject {
    testURL: string;
  }
}
export class TestSISRequest extends jspb.Message {
  static readonly displayName = "TestSISRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): TestSISRequest.AsObject {
    return TestSISRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!TestSISRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: TestSISRequest) {
    var f: any;
    var obj: any = {
      testurl: jspb.Message.getFieldWithDefault(msg, 1, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!TestSISRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new TestSISRequest;
    return TestSISRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!TestSISRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!TestSISRequest}
   */
  static deserializeBinaryFromReader(msg: TestSISRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setTesturl(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!TestSISRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: TestSISRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getTesturl();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    TestSISRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string testURL = 1;
   * @return {string}
   */
  getTesturl(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setTesturl(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


}
export namespace TestSISResponse {
  export interface AsObject {
    error: string;
    schoolInfosList: SchoolInfo.AsObject[];
    rawResponse: string;
  }
}
export class TestSISResponse extends jspb.Message {
  static readonly displayName = "TestSISResponse";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [2];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, TestSISResponse.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): TestSISResponse.AsObject {
    return TestSISResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!TestSISResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: TestSISResponse) {
    var f: any;
    var obj: any = {
      error: jspb.Message.getFieldWithDefault(msg, 1, ""),
      schoolInfosList: jspb.Message.toObjectList(msg.getSchoolInfosList(),
      SchoolInfo.toObject, includeInstance),
      rawresponse: jspb.Message.getFieldWithDefault(msg, 3, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!TestSISResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new TestSISResponse;
    return TestSISResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!TestSISResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!TestSISResponse}
   */
  static deserializeBinaryFromReader(msg: TestSISResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setError(value1);
        break;
      case 2:
        var value2 = new SchoolInfo;
        reader.readMessage(value2,SchoolInfo.deserializeBinaryFromReader);
        msg.addSchoolInfos(value2);
        break;
      case 3:
        var value3 = /** @type {string} */ (reader.readString());
        msg.setRawresponse(value3);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!TestSISResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: TestSISResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getError();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
    f = message.getSchoolInfosList();
    if (f.length > 0) {
      writer.writeRepeatedMessage(
        2,
        f,
        SchoolInfo.serializeBinaryToWriter
      );
    }
    f = message.getRawresponse();
    if (f.length > 0) {
      writer.writeString(
        3,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    TestSISResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string error = 1;
   * @return {string}
   */
  getError(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setError(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


  /**
   * repeated SchoolInfo school_infos = 2;
   * @return {!Array<!SchoolInfo>}
   */
  getSchoolInfosList(): SchoolInfo[] {
    return /** @type{!Array<!SchoolInfo>} */ (
      jspb.Message.getRepeatedWrapperField(this, SchoolInfo, 2));
  }


  /** @param {!Array<!SchoolInfo>} value */
  setSchoolInfosList(value?: SchoolInfo[]) {
    jspb.Message.setRepeatedWrapperField(this, 2, value);
  }


  /**
   * @param {!SchoolInfo=} opt_value
   * @param {number=} opt_index
   * @return {!SchoolInfo}
   */
  addSchoolInfos(opt_value?: SchoolInfo, opt_index?: number): SchoolInfo {
    return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, SchoolInfo, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearSchoolInfosList() {
    this.setSchoolInfosList([]);
  }


  /**
   * optional string rawResponse = 3;
   * @return {string}
   */
  getRawresponse(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
  };


  /** @param {string} value */
  setRawresponse(value: string) {
    jspb.Message.setProto3StringField(this, 3, value);
  }


}
export namespace GetSISSyncRequest {
  export interface AsObject {
  }
}
export class GetSISSyncRequest extends jspb.Message {
  static readonly displayName = "GetSISSyncRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): GetSISSyncRequest.AsObject {
    return GetSISSyncRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!GetSISSyncRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: GetSISSyncRequest) {
    var f: any;
    var obj: any = {

    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!GetSISSyncRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new GetSISSyncRequest;
    return GetSISSyncRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!GetSISSyncRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!GetSISSyncRequest}
   */
  static deserializeBinaryFromReader(msg: GetSISSyncRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!GetSISSyncRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: GetSISSyncRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    GetSISSyncRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
}
export namespace GetSISSyncResponse {
  export interface AsObject {
    logsList: sisSyncLog.AsObject[];
  }
}
export class GetSISSyncResponse extends jspb.Message {
  static readonly displayName = "GetSISSyncResponse";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [1];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, GetSISSyncResponse.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): GetSISSyncResponse.AsObject {
    return GetSISSyncResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!GetSISSyncResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: GetSISSyncResponse) {
    var f: any;
    var obj: any = {
      logsList: jspb.Message.toObjectList(msg.getLogsList(),
      sisSyncLog.toObject, includeInstance)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!GetSISSyncResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new GetSISSyncResponse;
    return GetSISSyncResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!GetSISSyncResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!GetSISSyncResponse}
   */
  static deserializeBinaryFromReader(msg: GetSISSyncResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new sisSyncLog;
        reader.readMessage(value1,sisSyncLog.deserializeBinaryFromReader);
        msg.addLogs(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!GetSISSyncResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: GetSISSyncResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getLogsList();
    if (f.length > 0) {
      writer.writeRepeatedMessage(
        1,
        f,
        sisSyncLog.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    GetSISSyncResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * repeated sisSyncLog logs = 1;
   * @return {!Array<!sisSyncLog>}
   */
  getLogsList(): sisSyncLog[] {
    return /** @type{!Array<!sisSyncLog>} */ (
      jspb.Message.getRepeatedWrapperField(this, sisSyncLog, 1));
  }


  /** @param {!Array<!sisSyncLog>} value */
  setLogsList(value?: sisSyncLog[]) {
    jspb.Message.setRepeatedWrapperField(this, 1, value);
  }


  /**
   * @param {!sisSyncLog=} opt_value
   * @param {number=} opt_index
   * @return {!sisSyncLog}
   */
  addLogs(opt_value?: sisSyncLog, opt_index?: number): sisSyncLog {
    return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, sisSyncLog, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearLogsList() {
    this.setLogsList([]);
  }


}
export namespace TriggerSISSyncRequest {
  export interface AsObject {
    massDelete: boolean;
  }
}
export class TriggerSISSyncRequest extends jspb.Message {
  static readonly displayName = "TriggerSISSyncRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): TriggerSISSyncRequest.AsObject {
    return TriggerSISSyncRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!TriggerSISSyncRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: TriggerSISSyncRequest) {
    var f: any;
    var obj: any = {
      massDelete: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!TriggerSISSyncRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new TriggerSISSyncRequest;
    return TriggerSISSyncRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!TriggerSISSyncRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!TriggerSISSyncRequest}
   */
  static deserializeBinaryFromReader(msg: TriggerSISSyncRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {boolean} */ (reader.readBool());
        msg.setMassDelete(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!TriggerSISSyncRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: TriggerSISSyncRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getMassDelete();
    if (f) {
      writer.writeBool(
        1,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    TriggerSISSyncRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional bool mass_delete = 1;
   * @return {boolean}
   */
  getMassDelete(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
  };


  /** @param {boolean} value */
  setMassDelete(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 1, value);
  }


}
export namespace TriggerSISSyncResponse {
  export interface AsObject {
  }
}
export class TriggerSISSyncResponse extends jspb.Message {
  static readonly displayName = "TriggerSISSyncResponse";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): TriggerSISSyncResponse.AsObject {
    return TriggerSISSyncResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!TriggerSISSyncResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: TriggerSISSyncResponse) {
    var f: any;
    var obj: any = {

    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!TriggerSISSyncResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new TriggerSISSyncResponse;
    return TriggerSISSyncResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!TriggerSISSyncResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!TriggerSISSyncResponse}
   */
  static deserializeBinaryFromReader(msg: TriggerSISSyncResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!TriggerSISSyncResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: TriggerSISSyncResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    TriggerSISSyncResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
}
export namespace GetSISUnifiedSyncRequest {
  export interface AsObject {
  }
}
export class GetSISUnifiedSyncRequest extends jspb.Message {
  static readonly displayName = "GetSISUnifiedSyncRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): GetSISUnifiedSyncRequest.AsObject {
    return GetSISUnifiedSyncRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!GetSISUnifiedSyncRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: GetSISUnifiedSyncRequest) {
    var f: any;
    var obj: any = {

    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!GetSISUnifiedSyncRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new GetSISUnifiedSyncRequest;
    return GetSISUnifiedSyncRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!GetSISUnifiedSyncRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!GetSISUnifiedSyncRequest}
   */
  static deserializeBinaryFromReader(msg: GetSISUnifiedSyncRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!GetSISUnifiedSyncRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: GetSISUnifiedSyncRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    GetSISUnifiedSyncRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
}
export namespace GetSISUnifiedSyncResponse {
  export interface AsObject {
    logsList: sisUnifiedSyncLog.AsObject[];
  }
}
export class GetSISUnifiedSyncResponse extends jspb.Message {
  static readonly displayName = "GetSISUnifiedSyncResponse";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [1];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, GetSISUnifiedSyncResponse.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): GetSISUnifiedSyncResponse.AsObject {
    return GetSISUnifiedSyncResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!GetSISUnifiedSyncResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: GetSISUnifiedSyncResponse) {
    var f: any;
    var obj: any = {
      logsList: jspb.Message.toObjectList(msg.getLogsList(),
      sisUnifiedSyncLog.toObject, includeInstance)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!GetSISUnifiedSyncResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new GetSISUnifiedSyncResponse;
    return GetSISUnifiedSyncResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!GetSISUnifiedSyncResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!GetSISUnifiedSyncResponse}
   */
  static deserializeBinaryFromReader(msg: GetSISUnifiedSyncResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new sisUnifiedSyncLog;
        reader.readMessage(value1,sisUnifiedSyncLog.deserializeBinaryFromReader);
        msg.addLogs(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!GetSISUnifiedSyncResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: GetSISUnifiedSyncResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getLogsList();
    if (f.length > 0) {
      writer.writeRepeatedMessage(
        1,
        f,
        sisUnifiedSyncLog.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    GetSISUnifiedSyncResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * repeated sisUnifiedSyncLog logs = 1;
   * @return {!Array<!sisUnifiedSyncLog>}
   */
  getLogsList(): sisUnifiedSyncLog[] {
    return /** @type{!Array<!sisUnifiedSyncLog>} */ (
      jspb.Message.getRepeatedWrapperField(this, sisUnifiedSyncLog, 1));
  }


  /** @param {!Array<!sisUnifiedSyncLog>} value */
  setLogsList(value?: sisUnifiedSyncLog[]) {
    jspb.Message.setRepeatedWrapperField(this, 1, value);
  }


  /**
   * @param {!sisUnifiedSyncLog=} opt_value
   * @param {number=} opt_index
   * @return {!sisUnifiedSyncLog}
   */
  addLogs(opt_value?: sisUnifiedSyncLog, opt_index?: number): sisUnifiedSyncLog {
    return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, sisUnifiedSyncLog, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearLogsList() {
    this.setLogsList([]);
  }


}
export namespace ResetGroupsRequest {
  export interface AsObject {
  }
}
export class ResetGroupsRequest extends jspb.Message {
  static readonly displayName = "ResetGroupsRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): ResetGroupsRequest.AsObject {
    return ResetGroupsRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!ResetGroupsRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: ResetGroupsRequest) {
    var f: any;
    var obj: any = {

    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!ResetGroupsRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new ResetGroupsRequest;
    return ResetGroupsRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!ResetGroupsRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!ResetGroupsRequest}
   */
  static deserializeBinaryFromReader(msg: ResetGroupsRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!ResetGroupsRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: ResetGroupsRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    ResetGroupsRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
}
export namespace ResetGroupsResponse {
  export interface AsObject {
  }
}
export class ResetGroupsResponse extends jspb.Message {
  static readonly displayName = "ResetGroupsResponse";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): ResetGroupsResponse.AsObject {
    return ResetGroupsResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!ResetGroupsResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: ResetGroupsResponse) {
    var f: any;
    var obj: any = {

    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!ResetGroupsResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new ResetGroupsResponse;
    return ResetGroupsResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!ResetGroupsResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!ResetGroupsResponse}
   */
  static deserializeBinaryFromReader(msg: ResetGroupsResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!ResetGroupsResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: ResetGroupsResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    ResetGroupsResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
}
export namespace ResetPointsRequest {
  export interface AsObject {
  }
}
export class ResetPointsRequest extends jspb.Message {
  static readonly displayName = "ResetPointsRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): ResetPointsRequest.AsObject {
    return ResetPointsRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!ResetPointsRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: ResetPointsRequest) {
    var f: any;
    var obj: any = {

    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!ResetPointsRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new ResetPointsRequest;
    return ResetPointsRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!ResetPointsRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!ResetPointsRequest}
   */
  static deserializeBinaryFromReader(msg: ResetPointsRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!ResetPointsRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: ResetPointsRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    ResetPointsRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
}
export namespace ResetPointsResponse {
  export interface AsObject {
  }
}
export class ResetPointsResponse extends jspb.Message {
  static readonly displayName = "ResetPointsResponse";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): ResetPointsResponse.AsObject {
    return ResetPointsResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!ResetPointsResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: ResetPointsResponse) {
    var f: any;
    var obj: any = {

    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!ResetPointsResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new ResetPointsResponse;
    return ResetPointsResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!ResetPointsResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!ResetPointsResponse}
   */
  static deserializeBinaryFromReader(msg: ResetPointsResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!ResetPointsResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: ResetPointsResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    ResetPointsResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
}
export namespace ResetAutomationCountersRequest {
  export interface AsObject {
  }
}
export class ResetAutomationCountersRequest extends jspb.Message {
  static readonly displayName = "ResetAutomationCountersRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): ResetAutomationCountersRequest.AsObject {
    return ResetAutomationCountersRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!ResetAutomationCountersRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: ResetAutomationCountersRequest) {
    var f: any;
    var obj: any = {

    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!ResetAutomationCountersRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new ResetAutomationCountersRequest;
    return ResetAutomationCountersRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!ResetAutomationCountersRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!ResetAutomationCountersRequest}
   */
  static deserializeBinaryFromReader(msg: ResetAutomationCountersRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!ResetAutomationCountersRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: ResetAutomationCountersRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    ResetAutomationCountersRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
}
export namespace ResetAutomationCountersResponse {
  export interface AsObject {
  }
}
export class ResetAutomationCountersResponse extends jspb.Message {
  static readonly displayName = "ResetAutomationCountersResponse";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): ResetAutomationCountersResponse.AsObject {
    return ResetAutomationCountersResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!ResetAutomationCountersResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: ResetAutomationCountersResponse) {
    var f: any;
    var obj: any = {

    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!ResetAutomationCountersResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new ResetAutomationCountersResponse;
    return ResetAutomationCountersResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!ResetAutomationCountersResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!ResetAutomationCountersResponse}
   */
  static deserializeBinaryFromReader(msg: ResetAutomationCountersResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!ResetAutomationCountersResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: ResetAutomationCountersResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    ResetAutomationCountersResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
}
export namespace ResetGroupsMembersRequest {
  export interface AsObject {
  }
}
export class ResetGroupsMembersRequest extends jspb.Message {
  static readonly displayName = "ResetGroupsMembersRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): ResetGroupsMembersRequest.AsObject {
    return ResetGroupsMembersRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!ResetGroupsMembersRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: ResetGroupsMembersRequest) {
    var f: any;
    var obj: any = {

    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!ResetGroupsMembersRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new ResetGroupsMembersRequest;
    return ResetGroupsMembersRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!ResetGroupsMembersRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!ResetGroupsMembersRequest}
   */
  static deserializeBinaryFromReader(msg: ResetGroupsMembersRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!ResetGroupsMembersRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: ResetGroupsMembersRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    ResetGroupsMembersRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
}
export namespace ResetGroupsMembersResponse {
  export interface AsObject {
  }
}
export class ResetGroupsMembersResponse extends jspb.Message {
  static readonly displayName = "ResetGroupsMembersResponse";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): ResetGroupsMembersResponse.AsObject {
    return ResetGroupsMembersResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!ResetGroupsMembersResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: ResetGroupsMembersResponse) {
    var f: any;
    var obj: any = {

    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!ResetGroupsMembersResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new ResetGroupsMembersResponse;
    return ResetGroupsMembersResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!ResetGroupsMembersResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!ResetGroupsMembersResponse}
   */
  static deserializeBinaryFromReader(msg: ResetGroupsMembersResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!ResetGroupsMembersResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: ResetGroupsMembersResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    ResetGroupsMembersResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
}
export namespace ResetUserListsRequest {
  export interface AsObject {
  }
}
export class ResetUserListsRequest extends jspb.Message {
  static readonly displayName = "ResetUserListsRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): ResetUserListsRequest.AsObject {
    return ResetUserListsRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!ResetUserListsRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: ResetUserListsRequest) {
    var f: any;
    var obj: any = {

    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!ResetUserListsRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new ResetUserListsRequest;
    return ResetUserListsRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!ResetUserListsRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!ResetUserListsRequest}
   */
  static deserializeBinaryFromReader(msg: ResetUserListsRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!ResetUserListsRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: ResetUserListsRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    ResetUserListsRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
}
export namespace ResetUserListsResponse {
  export interface AsObject {
  }
}
export class ResetUserListsResponse extends jspb.Message {
  static readonly displayName = "ResetUserListsResponse";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): ResetUserListsResponse.AsObject {
    return ResetUserListsResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!ResetUserListsResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: ResetUserListsResponse) {
    var f: any;
    var obj: any = {

    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!ResetUserListsResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new ResetUserListsResponse;
    return ResetUserListsResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!ResetUserListsResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!ResetUserListsResponse}
   */
  static deserializeBinaryFromReader(msg: ResetUserListsResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!ResetUserListsResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: ResetUserListsResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    ResetUserListsResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
}
export namespace GetMingaPartnersRequest {
  export interface AsObject {
  }
}
export class GetMingaPartnersRequest extends jspb.Message {
  static readonly displayName = "GetMingaPartnersRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): GetMingaPartnersRequest.AsObject {
    return GetMingaPartnersRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!GetMingaPartnersRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: GetMingaPartnersRequest) {
    var f: any;
    var obj: any = {

    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!GetMingaPartnersRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new GetMingaPartnersRequest;
    return GetMingaPartnersRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!GetMingaPartnersRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!GetMingaPartnersRequest}
   */
  static deserializeBinaryFromReader(msg: GetMingaPartnersRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!GetMingaPartnersRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: GetMingaPartnersRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    GetMingaPartnersRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
}
export namespace GetMingaPartnersResponse {
  export interface AsObject {
    partnersList: MingaPartner.AsObject[];
  }
}
export class GetMingaPartnersResponse extends jspb.Message {
  static readonly displayName = "GetMingaPartnersResponse";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [1];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, GetMingaPartnersResponse.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): GetMingaPartnersResponse.AsObject {
    return GetMingaPartnersResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!GetMingaPartnersResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: GetMingaPartnersResponse) {
    var f: any;
    var obj: any = {
      partnersList: jspb.Message.toObjectList(msg.getPartnersList(),
      MingaPartner.toObject, includeInstance)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!GetMingaPartnersResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new GetMingaPartnersResponse;
    return GetMingaPartnersResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!GetMingaPartnersResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!GetMingaPartnersResponse}
   */
  static deserializeBinaryFromReader(msg: GetMingaPartnersResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new MingaPartner;
        reader.readMessage(value1,MingaPartner.deserializeBinaryFromReader);
        msg.addPartners(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!GetMingaPartnersResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: GetMingaPartnersResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getPartnersList();
    if (f.length > 0) {
      writer.writeRepeatedMessage(
        1,
        f,
        MingaPartner.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    GetMingaPartnersResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * repeated MingaPartner partners = 1;
   * @return {!Array<!MingaPartner>}
   */
  getPartnersList(): MingaPartner[] {
    return /** @type{!Array<!MingaPartner>} */ (
      jspb.Message.getRepeatedWrapperField(this, MingaPartner, 1));
  }


  /** @param {!Array<!MingaPartner>} value */
  setPartnersList(value?: MingaPartner[]) {
    jspb.Message.setRepeatedWrapperField(this, 1, value);
  }


  /**
   * @param {!MingaPartner=} opt_value
   * @param {number=} opt_index
   * @return {!MingaPartner}
   */
  addPartners(opt_value?: MingaPartner, opt_index?: number): MingaPartner {
    return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, MingaPartner, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearPartnersList() {
    this.setPartnersList([]);
  }


}
export namespace UpsertMingaPartnerRequest {
  export interface AsObject {
    partner?: MingaPartner.AsObject;
  }
}
export class UpsertMingaPartnerRequest extends jspb.Message {
  static readonly displayName = "UpsertMingaPartnerRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): UpsertMingaPartnerRequest.AsObject {
    return UpsertMingaPartnerRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!UpsertMingaPartnerRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: UpsertMingaPartnerRequest) {
    var f: any;
    var obj: any = {
      partner: (f = msg.getPartner()) && MingaPartner.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!UpsertMingaPartnerRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new UpsertMingaPartnerRequest;
    return UpsertMingaPartnerRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!UpsertMingaPartnerRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!UpsertMingaPartnerRequest}
   */
  static deserializeBinaryFromReader(msg: UpsertMingaPartnerRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new MingaPartner;
        reader.readMessage(value1,MingaPartner.deserializeBinaryFromReader);
        msg.setPartner(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!UpsertMingaPartnerRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: UpsertMingaPartnerRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getPartner();
    if (f != null) {
      writer.writeMessage(
        1,
        f,
        MingaPartner.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    UpsertMingaPartnerRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional MingaPartner partner = 1;
   * @return {?MingaPartner}
   */
  getPartner(): MingaPartner {
    return /** @type{?MingaPartner} */ (
      jspb.Message.getWrapperField(this, MingaPartner, 1));
  }


  /** @param {?MingaPartner|undefined} value */
  setPartner(value?: MingaPartner) {
    jspb.Message.setWrapperField(this, 1, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearPartner() {
    this.setPartner(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasPartner(): boolean {
    return jspb.Message.getField(this, 1) != null;
  }


}
export namespace UpsertMingaPartnerResponse {
  export interface AsObject {
    partner?: MingaPartner.AsObject;
  }
}
export class UpsertMingaPartnerResponse extends jspb.Message {
  static readonly displayName = "UpsertMingaPartnerResponse";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): UpsertMingaPartnerResponse.AsObject {
    return UpsertMingaPartnerResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!UpsertMingaPartnerResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: UpsertMingaPartnerResponse) {
    var f: any;
    var obj: any = {
      partner: (f = msg.getPartner()) && MingaPartner.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!UpsertMingaPartnerResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new UpsertMingaPartnerResponse;
    return UpsertMingaPartnerResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!UpsertMingaPartnerResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!UpsertMingaPartnerResponse}
   */
  static deserializeBinaryFromReader(msg: UpsertMingaPartnerResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new MingaPartner;
        reader.readMessage(value1,MingaPartner.deserializeBinaryFromReader);
        msg.setPartner(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!UpsertMingaPartnerResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: UpsertMingaPartnerResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getPartner();
    if (f != null) {
      writer.writeMessage(
        1,
        f,
        MingaPartner.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    UpsertMingaPartnerResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional MingaPartner partner = 1;
   * @return {?MingaPartner}
   */
  getPartner(): MingaPartner {
    return /** @type{?MingaPartner} */ (
      jspb.Message.getWrapperField(this, MingaPartner, 1));
  }


  /** @param {?MingaPartner|undefined} value */
  setPartner(value?: MingaPartner) {
    jspb.Message.setWrapperField(this, 1, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearPartner() {
    this.setPartner(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasPartner(): boolean {
    return jspb.Message.getField(this, 1) != null;
  }


}
export namespace GenerateMingaSFTPGoUserRequest {
  export interface AsObject {
  }
}
export class GenerateMingaSFTPGoUserRequest extends jspb.Message {
  static readonly displayName = "GenerateMingaSFTPGoUserRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): GenerateMingaSFTPGoUserRequest.AsObject {
    return GenerateMingaSFTPGoUserRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!GenerateMingaSFTPGoUserRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: GenerateMingaSFTPGoUserRequest) {
    var f: any;
    var obj: any = {

    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!GenerateMingaSFTPGoUserRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new GenerateMingaSFTPGoUserRequest;
    return GenerateMingaSFTPGoUserRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!GenerateMingaSFTPGoUserRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!GenerateMingaSFTPGoUserRequest}
   */
  static deserializeBinaryFromReader(msg: GenerateMingaSFTPGoUserRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!GenerateMingaSFTPGoUserRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: GenerateMingaSFTPGoUserRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    GenerateMingaSFTPGoUserRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
}
export namespace GenerateMingaSFTPGoUserResponse {
  export interface AsObject {
    username: string;
    password: string;
    host: string;
  }
}
export class GenerateMingaSFTPGoUserResponse extends jspb.Message {
  static readonly displayName = "GenerateMingaSFTPGoUserResponse";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): GenerateMingaSFTPGoUserResponse.AsObject {
    return GenerateMingaSFTPGoUserResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!GenerateMingaSFTPGoUserResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: GenerateMingaSFTPGoUserResponse) {
    var f: any;
    var obj: any = {
      username: jspb.Message.getFieldWithDefault(msg, 1, ""),
      password: jspb.Message.getFieldWithDefault(msg, 2, ""),
      host: jspb.Message.getFieldWithDefault(msg, 3, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!GenerateMingaSFTPGoUserResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new GenerateMingaSFTPGoUserResponse;
    return GenerateMingaSFTPGoUserResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!GenerateMingaSFTPGoUserResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!GenerateMingaSFTPGoUserResponse}
   */
  static deserializeBinaryFromReader(msg: GenerateMingaSFTPGoUserResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setUsername(value1);
        break;
      case 2:
        var value2 = /** @type {string} */ (reader.readString());
        msg.setPassword(value2);
        break;
      case 3:
        var value3 = /** @type {string} */ (reader.readString());
        msg.setHost(value3);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!GenerateMingaSFTPGoUserResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: GenerateMingaSFTPGoUserResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getUsername();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
    f = message.getPassword();
    if (f.length > 0) {
      writer.writeString(
        2,
        f
      );
    }
    f = message.getHost();
    if (f.length > 0) {
      writer.writeString(
        3,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    GenerateMingaSFTPGoUserResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string username = 1;
   * @return {string}
   */
  getUsername(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setUsername(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


  /**
   * optional string password = 2;
   * @return {string}
   */
  getPassword(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
  };


  /** @param {string} value */
  setPassword(value: string) {
    jspb.Message.setProto3StringField(this, 2, value);
  }


  /**
   * optional string host = 3;
   * @return {string}
   */
  getHost(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
  };


  /** @param {string} value */
  setHost(value: string) {
    jspb.Message.setProto3StringField(this, 3, value);
  }


}
export namespace GetMingaStatsForPersonRequest {
  export interface AsObject {
    startDate?: common_date_pb.Date.AsObject;
    endDate?: common_date_pb.Date.AsObject;
  }
}
export class GetMingaStatsForPersonRequest extends jspb.Message {
  static readonly displayName = "GetMingaStatsForPersonRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): GetMingaStatsForPersonRequest.AsObject {
    return GetMingaStatsForPersonRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!GetMingaStatsForPersonRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: GetMingaStatsForPersonRequest) {
    var f: any;
    var obj: any = {
      startDate: (f = msg.getStartDate()) && common_date_pb.Date.toObject(includeInstance, f),
      endDate: (f = msg.getEndDate()) && common_date_pb.Date.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!GetMingaStatsForPersonRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new GetMingaStatsForPersonRequest;
    return GetMingaStatsForPersonRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!GetMingaStatsForPersonRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!GetMingaStatsForPersonRequest}
   */
  static deserializeBinaryFromReader(msg: GetMingaStatsForPersonRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 2:
        var value2 = new common_date_pb.Date;
        reader.readMessage(value2,common_date_pb.Date.deserializeBinaryFromReader);
        msg.setStartDate(value2);
        break;
      case 3:
        var value3 = new common_date_pb.Date;
        reader.readMessage(value3,common_date_pb.Date.deserializeBinaryFromReader);
        msg.setEndDate(value3);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!GetMingaStatsForPersonRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: GetMingaStatsForPersonRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getStartDate();
    if (f != null) {
      writer.writeMessage(
        2,
        f,
        common_date_pb.Date.serializeBinaryToWriter
      );
    }
    f = message.getEndDate();
    if (f != null) {
      writer.writeMessage(
        3,
        f,
        common_date_pb.Date.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    GetMingaStatsForPersonRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional minga.common.Date start_date = 2;
   * @return {?Date}
   */
  getStartDate(): common_date_pb.Date {
    return /** @type{?Date} */ (
      jspb.Message.getWrapperField(this, common_date_pb.Date, 2));
  }


  /** @param {?Date|undefined} value */
  setStartDate(value?: common_date_pb.Date) {
    jspb.Message.setWrapperField(this, 2, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearStartDate() {
    this.setStartDate(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasStartDate(): boolean {
    return jspb.Message.getField(this, 2) != null;
  }


  /**
   * optional minga.common.Date end_date = 3;
   * @return {?Date}
   */
  getEndDate(): common_date_pb.Date {
    return /** @type{?Date} */ (
      jspb.Message.getWrapperField(this, common_date_pb.Date, 3));
  }


  /** @param {?Date|undefined} value */
  setEndDate(value?: common_date_pb.Date) {
    jspb.Message.setWrapperField(this, 3, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearEndDate() {
    this.setEndDate(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasEndDate(): boolean {
    return jspb.Message.getField(this, 3) != null;
  }


}
export namespace GetMingaStatsForPersonResponse {
  export interface AsObject {
    activeUserCount: number;
    checkinCount: number;
    hallPassCount: number;
    pointCount: number;
    behaviorCount: number;
  }
}
export class GetMingaStatsForPersonResponse extends jspb.Message {
  static readonly displayName = "GetMingaStatsForPersonResponse";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): GetMingaStatsForPersonResponse.AsObject {
    return GetMingaStatsForPersonResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!GetMingaStatsForPersonResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: GetMingaStatsForPersonResponse) {
    var f: any;
    var obj: any = {
      activeUserCount: jspb.Message.getFieldWithDefault(msg, 1, 0),
      checkinCount: jspb.Message.getFieldWithDefault(msg, 2, 0),
      hallPassCount: jspb.Message.getFieldWithDefault(msg, 3, 0),
      pointCount: jspb.Message.getFieldWithDefault(msg, 4, 0),
      behaviorCount: jspb.Message.getFieldWithDefault(msg, 5, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!GetMingaStatsForPersonResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new GetMingaStatsForPersonResponse;
    return GetMingaStatsForPersonResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!GetMingaStatsForPersonResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!GetMingaStatsForPersonResponse}
   */
  static deserializeBinaryFromReader(msg: GetMingaStatsForPersonResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {number} */ (reader.readInt32());
        msg.setActiveUserCount(value1);
        break;
      case 2:
        var value2 = /** @type {number} */ (reader.readInt32());
        msg.setCheckinCount(value2);
        break;
      case 3:
        var value3 = /** @type {number} */ (reader.readInt32());
        msg.setHallPassCount(value3);
        break;
      case 4:
        var value4 = /** @type {number} */ (reader.readInt32());
        msg.setPointCount(value4);
        break;
      case 5:
        var value5 = /** @type {number} */ (reader.readInt32());
        msg.setBehaviorCount(value5);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!GetMingaStatsForPersonResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: GetMingaStatsForPersonResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getActiveUserCount();
    if (f !== 0) {
      writer.writeInt32(
        1,
        f
      );
    }
    f = message.getCheckinCount();
    if (f !== 0) {
      writer.writeInt32(
        2,
        f
      );
    }
    f = message.getHallPassCount();
    if (f !== 0) {
      writer.writeInt32(
        3,
        f
      );
    }
    f = message.getPointCount();
    if (f !== 0) {
      writer.writeInt32(
        4,
        f
      );
    }
    f = message.getBehaviorCount();
    if (f !== 0) {
      writer.writeInt32(
        5,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    GetMingaStatsForPersonResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional int32 active_user_count = 1;
   * @return {number}
   */
  getActiveUserCount(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
  };


  /** @param {number} value */
  setActiveUserCount(value: number) {
    jspb.Message.setProto3IntField(this, 1, value);
  }


  /**
   * optional int32 checkin_count = 2;
   * @return {number}
   */
  getCheckinCount(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
  };


  /** @param {number} value */
  setCheckinCount(value: number) {
    jspb.Message.setProto3IntField(this, 2, value);
  }


  /**
   * optional int32 hall_pass_count = 3;
   * @return {number}
   */
  getHallPassCount(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
  };


  /** @param {number} value */
  setHallPassCount(value: number) {
    jspb.Message.setProto3IntField(this, 3, value);
  }


  /**
   * optional int32 point_count = 4;
   * @return {number}
   */
  getPointCount(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
  };


  /** @param {number} value */
  setPointCount(value: number) {
    jspb.Message.setProto3IntField(this, 4, value);
  }


  /**
   * optional int32 behavior_count = 5;
   * @return {number}
   */
  getBehaviorCount(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
  };


  /** @param {number} value */
  setBehaviorCount(value: number) {
    jspb.Message.setProto3IntField(this, 5, value);
  }


}
export namespace GetStatsForStudentRequest {
  export interface AsObject {
    studentHash: string;
  }
}
export class GetStatsForStudentRequest extends jspb.Message {
  static readonly displayName = "GetStatsForStudentRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): GetStatsForStudentRequest.AsObject {
    return GetStatsForStudentRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!GetStatsForStudentRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: GetStatsForStudentRequest) {
    var f: any;
    var obj: any = {
      studentHash: jspb.Message.getFieldWithDefault(msg, 1, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!GetStatsForStudentRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new GetStatsForStudentRequest;
    return GetStatsForStudentRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!GetStatsForStudentRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!GetStatsForStudentRequest}
   */
  static deserializeBinaryFromReader(msg: GetStatsForStudentRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setStudentHash(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!GetStatsForStudentRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: GetStatsForStudentRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getStudentHash();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    GetStatsForStudentRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string student_hash = 1;
   * @return {string}
   */
  getStudentHash(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setStudentHash(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


}
export namespace GetStatsForStudentResponse {
  export interface AsObject {
    redeemablePointCount: number;
    overdueConsequenceCount: number;
    activeConsequenceCount: number;
    unregisteredPeriodCount: number;
  }
}
export class GetStatsForStudentResponse extends jspb.Message {
  static readonly displayName = "GetStatsForStudentResponse";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): GetStatsForStudentResponse.AsObject {
    return GetStatsForStudentResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!GetStatsForStudentResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: GetStatsForStudentResponse) {
    var f: any;
    var obj: any = {
      redeemablePointCount: jspb.Message.getFieldWithDefault(msg, 1, 0),
      overdueConsequenceCount: jspb.Message.getFieldWithDefault(msg, 2, 0),
      activeConsequenceCount: jspb.Message.getFieldWithDefault(msg, 3, 0),
      unregisteredPeriodCount: jspb.Message.getFieldWithDefault(msg, 4, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!GetStatsForStudentResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new GetStatsForStudentResponse;
    return GetStatsForStudentResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!GetStatsForStudentResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!GetStatsForStudentResponse}
   */
  static deserializeBinaryFromReader(msg: GetStatsForStudentResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {number} */ (reader.readInt32());
        msg.setRedeemablePointCount(value1);
        break;
      case 2:
        var value2 = /** @type {number} */ (reader.readInt32());
        msg.setOverdueConsequenceCount(value2);
        break;
      case 3:
        var value3 = /** @type {number} */ (reader.readInt32());
        msg.setActiveConsequenceCount(value3);
        break;
      case 4:
        var value4 = /** @type {number} */ (reader.readInt32());
        msg.setUnregisteredPeriodCount(value4);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!GetStatsForStudentResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: GetStatsForStudentResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getRedeemablePointCount();
    if (f !== 0) {
      writer.writeInt32(
        1,
        f
      );
    }
    f = message.getOverdueConsequenceCount();
    if (f !== 0) {
      writer.writeInt32(
        2,
        f
      );
    }
    f = message.getActiveConsequenceCount();
    if (f !== 0) {
      writer.writeInt32(
        3,
        f
      );
    }
    f = message.getUnregisteredPeriodCount();
    if (f !== 0) {
      writer.writeInt32(
        4,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    GetStatsForStudentResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional int32 redeemable_point_count = 1;
   * @return {number}
   */
  getRedeemablePointCount(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
  };


  /** @param {number} value */
  setRedeemablePointCount(value: number) {
    jspb.Message.setProto3IntField(this, 1, value);
  }


  /**
   * optional int32 overdue_consequence_count = 2;
   * @return {number}
   */
  getOverdueConsequenceCount(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
  };


  /** @param {number} value */
  setOverdueConsequenceCount(value: number) {
    jspb.Message.setProto3IntField(this, 2, value);
  }


  /**
   * optional int32 active_consequence_count = 3;
   * @return {number}
   */
  getActiveConsequenceCount(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
  };


  /** @param {number} value */
  setActiveConsequenceCount(value: number) {
    jspb.Message.setProto3IntField(this, 3, value);
  }


  /**
   * optional int32 unregistered_period_count = 4;
   * @return {number}
   */
  getUnregisteredPeriodCount(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
  };


  /** @param {number} value */
  setUnregisteredPeriodCount(value: number) {
    jspb.Message.setProto3IntField(this, 4, value);
  }


}
export enum MingaType {
  MINGA = 0,
  CONFERENCE = 1,
}
export enum MingaStatus {
  DEMO = 0,
  EVAL = 1,
  TESTING = 2,
  LIVE = 3,
  EXPIRED = 4,
  EVAL_OVER = 5,
  PENDING_DELETION = 6,
  TRIAL = 7,
}
export enum MingaSubscriptionPlan {
  MINGA_FREE = 0,
  MINGA_100 = 1,
  MINGA_500 = 2,
  MINGA_1500 = 3,
  MINGA_3500 = 4,
  MINGA_5000 = 5,
  MINGA_2500 = 6,
  MINGA_1000 = 7,
}
export enum MingaReportType {
  POST = 0,
  ACTIVE_USER = 1,
  BEHAVIOR = 2,
  HALL_PASS = 3,
  ACTIVE_STUDENT = 4,
  CHECKIN = 5,
}
