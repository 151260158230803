// source: content/event.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

import * as jspb from 'google-protobuf';
import * as content_common_pb from '../content/common_pb';
import * as common_delta_pb from '../common/delta_pb';
import * as common_date_pb from '../common/date_pb';
import * as gateway_person_view_pb from '../gateway/person_view_pb';
import * as restriction_restriction_pb from '../restriction/restriction_pb';
export namespace AdditionalDate {
  export interface AsObject {
    contentId: number;
    eventStart?: common_date_pb.DateTime.AsObject;
    eventEnd?: common_date_pb.DateTime.AsObject;
    allDayEvent: boolean;
  }
}
export class AdditionalDate extends jspb.Message {
  static readonly displayName = "AdditionalDate";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): AdditionalDate.AsObject {
    return AdditionalDate.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!AdditionalDate} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: AdditionalDate) {
    var f: any;
    var obj: any = {
      contentId: jspb.Message.getFieldWithDefault(msg, 1, 0),
      eventStart: (f = msg.getEventStart()) && common_date_pb.DateTime.toObject(includeInstance, f),
      eventEnd: (f = msg.getEventEnd()) && common_date_pb.DateTime.toObject(includeInstance, f),
      allDayEvent: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!AdditionalDate}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new AdditionalDate;
    return AdditionalDate.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!AdditionalDate} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!AdditionalDate}
   */
  static deserializeBinaryFromReader(msg: AdditionalDate, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {number} */ (reader.readInt32());
        msg.setContentId(value1);
        break;
      case 2:
        var value2 = new common_date_pb.DateTime;
        reader.readMessage(value2,common_date_pb.DateTime.deserializeBinaryFromReader);
        msg.setEventStart(value2);
        break;
      case 3:
        var value3 = new common_date_pb.DateTime;
        reader.readMessage(value3,common_date_pb.DateTime.deserializeBinaryFromReader);
        msg.setEventEnd(value3);
        break;
      case 4:
        var value4 = /** @type {boolean} */ (reader.readBool());
        msg.setAllDayEvent(value4);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!AdditionalDate} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: AdditionalDate, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getContentId();
    if (f !== 0) {
      writer.writeInt32(
        1,
        f
      );
    }
    f = message.getEventStart();
    if (f != null) {
      writer.writeMessage(
        2,
        f,
        common_date_pb.DateTime.serializeBinaryToWriter
      );
    }
    f = message.getEventEnd();
    if (f != null) {
      writer.writeMessage(
        3,
        f,
        common_date_pb.DateTime.serializeBinaryToWriter
      );
    }
    f = message.getAllDayEvent();
    if (f) {
      writer.writeBool(
        4,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    AdditionalDate.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional int32 content_id = 1;
   * @return {number}
   */
  getContentId(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
  };


  /** @param {number} value */
  setContentId(value: number) {
    jspb.Message.setProto3IntField(this, 1, value);
  }


  /**
   * optional minga.common.DateTime event_start = 2;
   * @return {?DateTime}
   */
  getEventStart(): common_date_pb.DateTime {
    return /** @type{?DateTime} */ (
      jspb.Message.getWrapperField(this, common_date_pb.DateTime, 2));
  }


  /** @param {?DateTime|undefined} value */
  setEventStart(value?: common_date_pb.DateTime) {
    jspb.Message.setWrapperField(this, 2, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearEventStart() {
    this.setEventStart(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasEventStart(): boolean {
    return jspb.Message.getField(this, 2) != null;
  }


  /**
   * optional minga.common.DateTime event_end = 3;
   * @return {?DateTime}
   */
  getEventEnd(): common_date_pb.DateTime {
    return /** @type{?DateTime} */ (
      jspb.Message.getWrapperField(this, common_date_pb.DateTime, 3));
  }


  /** @param {?DateTime|undefined} value */
  setEventEnd(value?: common_date_pb.DateTime) {
    jspb.Message.setWrapperField(this, 3, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearEventEnd() {
    this.setEventEnd(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasEventEnd(): boolean {
    return jspb.Message.getField(this, 3) != null;
  }


  /**
   * optional bool all_day_event = 4;
   * @return {boolean}
   */
  getAllDayEvent(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
  };


  /** @param {boolean} value */
  setAllDayEvent(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 4, value);
  }


}
export namespace EventInfo {
  export interface AsObject {
    title: string;
    description: string;
    eventHost: string;
    eventLocation: string;
    allDayEvent: boolean;
    eventStart?: common_date_pb.DateTime.AsObject;
    eventEnd?: common_date_pb.DateTime.AsObject;
    allowGoing: boolean;
    eventBannerAsset: string;
    eventBannerHash: string;
    eventCode: string;
    cancelled: boolean;
    points: number;
    ticketUrl: string;
    eventCategory: string;
    additionalDatesList: AdditionalDate.AsObject[];
    recurringEventId: number;
    hasTickets: boolean;
    ticketInfo: string;
    ticketPrice: number;
    singleCheckIn: boolean;
    eventReasonId: number;
    allowSelfCheckIn: boolean;
  }
}
export class EventInfo extends jspb.Message {
  static readonly displayName = "EventInfo";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [16];

  /**
   * Oneof group definitions for this message. Each group defines the field
   * numbers belonging to that group. When of these fields' value is set, all
   * other fields in the group are cleared. During deserialization, if multiple
   * fields are encountered for a group, only the last value seen will be kept.
   * @private {!Array<!Array<number>>}
   * @const
   */
  static readonly oneofGroups_ = [[9,11]];


  /**
   * @return {EventInfo.EventBannerCase}
   */
  getEventBannerCase() {
    return /** @type {EventInfo.EventBannerCase} */(jspb.Message.computeOneofCase(this, EventInfo.oneofGroups_[0]));
  }

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, EventInfo.repeatedFields_, EventInfo.oneofGroups_);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): EventInfo.AsObject {
    return EventInfo.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!EventInfo} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: EventInfo) {
    var f: any;
    var obj: any = {
      title: jspb.Message.getFieldWithDefault(msg, 1, ""),
      description: jspb.Message.getFieldWithDefault(msg, 2, ""),
      eventHost: jspb.Message.getFieldWithDefault(msg, 3, ""),
      eventLocation: jspb.Message.getFieldWithDefault(msg, 4, ""),
      allDayEvent: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
      eventStart: (f = msg.getEventStart()) && common_date_pb.DateTime.toObject(includeInstance, f),
      eventEnd: (f = msg.getEventEnd()) && common_date_pb.DateTime.toObject(includeInstance, f),
      allowGoing: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
      eventBannerAsset: jspb.Message.getFieldWithDefault(msg, 9, ""),
      eventBannerHash: jspb.Message.getFieldWithDefault(msg, 11, ""),
      eventCode: jspb.Message.getFieldWithDefault(msg, 10, ""),
      cancelled: jspb.Message.getBooleanFieldWithDefault(msg, 12, false),
      points: jspb.Message.getFieldWithDefault(msg, 13, 0),
      ticketUrl: jspb.Message.getFieldWithDefault(msg, 14, ""),
      eventCategory: jspb.Message.getFieldWithDefault(msg, 15, ""),
      additionalDatesList: jspb.Message.toObjectList(msg.getAdditionalDatesList(),
      AdditionalDate.toObject, includeInstance),
      recurringeventid: jspb.Message.getFieldWithDefault(msg, 17, 0),
      hasTickets: jspb.Message.getBooleanFieldWithDefault(msg, 18, false),
      ticketInfo: jspb.Message.getFieldWithDefault(msg, 19, ""),
      ticketPrice: jspb.Message.getFloatingPointFieldWithDefault(msg, 20, 0.0),
      singleCheckIn: jspb.Message.getBooleanFieldWithDefault(msg, 21, false),
      eventReasonId: jspb.Message.getFieldWithDefault(msg, 22, 0),
      allowSelfCheckIn: jspb.Message.getBooleanFieldWithDefault(msg, 23, false)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!EventInfo}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new EventInfo;
    return EventInfo.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!EventInfo} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!EventInfo}
   */
  static deserializeBinaryFromReader(msg: EventInfo, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setTitle(value1);
        break;
      case 2:
        var value2 = /** @type {string} */ (reader.readString());
        msg.setDescription(value2);
        break;
      case 3:
        var value3 = /** @type {string} */ (reader.readString());
        msg.setEventHost(value3);
        break;
      case 4:
        var value4 = /** @type {string} */ (reader.readString());
        msg.setEventLocation(value4);
        break;
      case 5:
        var value5 = /** @type {boolean} */ (reader.readBool());
        msg.setAllDayEvent(value5);
        break;
      case 6:
        var value6 = new common_date_pb.DateTime;
        reader.readMessage(value6,common_date_pb.DateTime.deserializeBinaryFromReader);
        msg.setEventStart(value6);
        break;
      case 7:
        var value7 = new common_date_pb.DateTime;
        reader.readMessage(value7,common_date_pb.DateTime.deserializeBinaryFromReader);
        msg.setEventEnd(value7);
        break;
      case 8:
        var value8 = /** @type {boolean} */ (reader.readBool());
        msg.setAllowGoing(value8);
        break;
      case 9:
        var value9 = /** @type {string} */ (reader.readString());
        msg.setEventBannerAsset(value9);
        break;
      case 11:
        var value11 = /** @type {string} */ (reader.readString());
        msg.setEventBannerHash(value11);
        break;
      case 10:
        var value10 = /** @type {string} */ (reader.readString());
        msg.setEventCode(value10);
        break;
      case 12:
        var value12 = /** @type {boolean} */ (reader.readBool());
        msg.setCancelled(value12);
        break;
      case 13:
        var value13 = /** @type {number} */ (reader.readInt32());
        msg.setPoints(value13);
        break;
      case 14:
        var value14 = /** @type {string} */ (reader.readString());
        msg.setTicketUrl(value14);
        break;
      case 15:
        var value15 = /** @type {string} */ (reader.readString());
        msg.setEventCategory(value15);
        break;
      case 16:
        var value16 = new AdditionalDate;
        reader.readMessage(value16,AdditionalDate.deserializeBinaryFromReader);
        msg.addAdditionalDates(value16);
        break;
      case 17:
        var value17 = /** @type {number} */ (reader.readInt32());
        msg.setRecurringeventid(value17);
        break;
      case 18:
        var value18 = /** @type {boolean} */ (reader.readBool());
        msg.setHasTickets(value18);
        break;
      case 19:
        var value19 = /** @type {string} */ (reader.readString());
        msg.setTicketInfo(value19);
        break;
      case 20:
        var value20 = /** @type {number} */ (reader.readFloat());
        msg.setTicketPrice(value20);
        break;
      case 21:
        var value21 = /** @type {boolean} */ (reader.readBool());
        msg.setSingleCheckIn(value21);
        break;
      case 22:
        var value22 = /** @type {number} */ (reader.readInt32());
        msg.setEventReasonId(value22);
        break;
      case 23:
        var value23 = /** @type {boolean} */ (reader.readBool());
        msg.setAllowSelfCheckIn(value23);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!EventInfo} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: EventInfo, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getTitle();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
    f = message.getDescription();
    if (f.length > 0) {
      writer.writeString(
        2,
        f
      );
    }
    f = message.getEventHost();
    if (f.length > 0) {
      writer.writeString(
        3,
        f
      );
    }
    f = message.getEventLocation();
    if (f.length > 0) {
      writer.writeString(
        4,
        f
      );
    }
    f = message.getAllDayEvent();
    if (f) {
      writer.writeBool(
        5,
        f
      );
    }
    f = message.getEventStart();
    if (f != null) {
      writer.writeMessage(
        6,
        f,
        common_date_pb.DateTime.serializeBinaryToWriter
      );
    }
    f = message.getEventEnd();
    if (f != null) {
      writer.writeMessage(
        7,
        f,
        common_date_pb.DateTime.serializeBinaryToWriter
      );
    }
    f = message.getAllowGoing();
    if (f) {
      writer.writeBool(
        8,
        f
      );
    }
    f = /** @type {string} */ (jspb.Message.getField(message, 9));
    if (f != null) {
      writer.writeString(
        9,
        f
      );
    }
    f = /** @type {string} */ (jspb.Message.getField(message, 11));
    if (f != null) {
      writer.writeString(
        11,
        f
      );
    }
    f = message.getEventCode();
    if (f.length > 0) {
      writer.writeString(
        10,
        f
      );
    }
    f = message.getCancelled();
    if (f) {
      writer.writeBool(
        12,
        f
      );
    }
    f = message.getPoints();
    if (f !== 0) {
      writer.writeInt32(
        13,
        f
      );
    }
    f = message.getTicketUrl();
    if (f.length > 0) {
      writer.writeString(
        14,
        f
      );
    }
    f = message.getEventCategory();
    if (f.length > 0) {
      writer.writeString(
        15,
        f
      );
    }
    f = message.getAdditionalDatesList();
    if (f.length > 0) {
      writer.writeRepeatedMessage(
        16,
        f,
        AdditionalDate.serializeBinaryToWriter
      );
    }
    f = message.getRecurringeventid();
    if (f !== 0) {
      writer.writeInt32(
        17,
        f
      );
    }
    f = message.getHasTickets();
    if (f) {
      writer.writeBool(
        18,
        f
      );
    }
    f = message.getTicketInfo();
    if (f.length > 0) {
      writer.writeString(
        19,
        f
      );
    }
    f = message.getTicketPrice();
    if (f !== 0.0) {
      writer.writeFloat(
        20,
        f
      );
    }
    f = message.getSingleCheckIn();
    if (f) {
      writer.writeBool(
        21,
        f
      );
    }
    f = message.getEventReasonId();
    if (f !== 0) {
      writer.writeInt32(
        22,
        f
      );
    }
    f = message.getAllowSelfCheckIn();
    if (f) {
      writer.writeBool(
        23,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    EventInfo.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string title = 1;
   * @return {string}
   */
  getTitle(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setTitle(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


  /**
   * optional string description = 2;
   * @return {string}
   */
  getDescription(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
  };


  /** @param {string} value */
  setDescription(value: string) {
    jspb.Message.setProto3StringField(this, 2, value);
  }


  /**
   * optional string event_host = 3;
   * @return {string}
   */
  getEventHost(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
  };


  /** @param {string} value */
  setEventHost(value: string) {
    jspb.Message.setProto3StringField(this, 3, value);
  }


  /**
   * optional string event_location = 4;
   * @return {string}
   */
  getEventLocation(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
  };


  /** @param {string} value */
  setEventLocation(value: string) {
    jspb.Message.setProto3StringField(this, 4, value);
  }


  /**
   * optional bool all_day_event = 5;
   * @return {boolean}
   */
  getAllDayEvent(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
  };


  /** @param {boolean} value */
  setAllDayEvent(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 5, value);
  }


  /**
   * optional minga.common.DateTime event_start = 6;
   * @return {?DateTime}
   */
  getEventStart(): common_date_pb.DateTime {
    return /** @type{?DateTime} */ (
      jspb.Message.getWrapperField(this, common_date_pb.DateTime, 6));
  }


  /** @param {?DateTime|undefined} value */
  setEventStart(value?: common_date_pb.DateTime) {
    jspb.Message.setWrapperField(this, 6, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearEventStart() {
    this.setEventStart(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasEventStart(): boolean {
    return jspb.Message.getField(this, 6) != null;
  }


  /**
   * optional minga.common.DateTime event_end = 7;
   * @return {?DateTime}
   */
  getEventEnd(): common_date_pb.DateTime {
    return /** @type{?DateTime} */ (
      jspb.Message.getWrapperField(this, common_date_pb.DateTime, 7));
  }


  /** @param {?DateTime|undefined} value */
  setEventEnd(value?: common_date_pb.DateTime) {
    jspb.Message.setWrapperField(this, 7, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearEventEnd() {
    this.setEventEnd(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasEventEnd(): boolean {
    return jspb.Message.getField(this, 7) != null;
  }


  /**
   * optional bool allow_going = 8;
   * @return {boolean}
   */
  getAllowGoing(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
  };


  /** @param {boolean} value */
  setAllowGoing(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 8, value);
  }


  /**
   * optional string event_banner_asset = 9;
   * @return {string}
   */
  getEventBannerAsset(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
  };


  /** @param {string} value */
  setEventBannerAsset(value: string) {
    jspb.Message.setOneofField(this, 9, EventInfo.oneofGroups_[0], value);
  }


  /**
   * Clears the field making it undefined.
   */
  clearEventBannerAsset() {
    jspb.Message.setOneofField(this, 9, EventInfo.oneofGroups_[0], undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasEventBannerAsset(): boolean {
    return jspb.Message.getField(this, 9) != null;
  }


  /**
   * optional string event_banner_hash = 11;
   * @return {string}
   */
  getEventBannerHash(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
  };


  /** @param {string} value */
  setEventBannerHash(value: string) {
    jspb.Message.setOneofField(this, 11, EventInfo.oneofGroups_[0], value);
  }


  /**
   * Clears the field making it undefined.
   */
  clearEventBannerHash() {
    jspb.Message.setOneofField(this, 11, EventInfo.oneofGroups_[0], undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasEventBannerHash(): boolean {
    return jspb.Message.getField(this, 11) != null;
  }


  /**
   * optional string event_code = 10;
   * @return {string}
   */
  getEventCode(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
  };


  /** @param {string} value */
  setEventCode(value: string) {
    jspb.Message.setProto3StringField(this, 10, value);
  }


  /**
   * optional bool cancelled = 12;
   * @return {boolean}
   */
  getCancelled(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 12, false));
  };


  /** @param {boolean} value */
  setCancelled(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 12, value);
  }


  /**
   * optional int32 points = 13;
   * @return {number}
   */
  getPoints(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
  };


  /** @param {number} value */
  setPoints(value: number) {
    jspb.Message.setProto3IntField(this, 13, value);
  }


  /**
   * optional string ticket_url = 14;
   * @return {string}
   */
  getTicketUrl(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
  };


  /** @param {string} value */
  setTicketUrl(value: string) {
    jspb.Message.setProto3StringField(this, 14, value);
  }


  /**
   * optional string event_category = 15;
   * @return {string}
   */
  getEventCategory(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
  };


  /** @param {string} value */
  setEventCategory(value: string) {
    jspb.Message.setProto3StringField(this, 15, value);
  }


  /**
   * repeated AdditionalDate additional_dates = 16;
   * @return {!Array<!AdditionalDate>}
   */
  getAdditionalDatesList(): AdditionalDate[] {
    return /** @type{!Array<!AdditionalDate>} */ (
      jspb.Message.getRepeatedWrapperField(this, AdditionalDate, 16));
  }


  /** @param {!Array<!AdditionalDate>} value */
  setAdditionalDatesList(value?: AdditionalDate[]) {
    jspb.Message.setRepeatedWrapperField(this, 16, value);
  }


  /**
   * @param {!AdditionalDate=} opt_value
   * @param {number=} opt_index
   * @return {!AdditionalDate}
   */
  addAdditionalDates(opt_value?: AdditionalDate, opt_index?: number): AdditionalDate {
    return jspb.Message.addToRepeatedWrapperField(this, 16, opt_value, AdditionalDate, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearAdditionalDatesList() {
    this.setAdditionalDatesList([]);
  }


  /**
   * optional int32 recurringEventId = 17;
   * @return {number}
   */
  getRecurringeventid(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 17, 0));
  };


  /** @param {number} value */
  setRecurringeventid(value: number) {
    jspb.Message.setProto3IntField(this, 17, value);
  }


  /**
   * optional bool has_tickets = 18;
   * @return {boolean}
   */
  getHasTickets(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 18, false));
  };


  /** @param {boolean} value */
  setHasTickets(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 18, value);
  }


  /**
   * optional string ticket_info = 19;
   * @return {string}
   */
  getTicketInfo(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
  };


  /** @param {string} value */
  setTicketInfo(value: string) {
    jspb.Message.setProto3StringField(this, 19, value);
  }


  /**
   * optional float ticket_price = 20;
   * @return {number}
   */
  getTicketPrice(): number {
    return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 20, 0.0));
  };


  /** @param {number} value */
  setTicketPrice(value: number) {
    jspb.Message.setProto3FloatField(this, 20, value);
  }


  /**
   * optional bool single_check_in = 21;
   * @return {boolean}
   */
  getSingleCheckIn(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 21, false));
  };


  /** @param {boolean} value */
  setSingleCheckIn(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 21, value);
  }


  /**
   * optional int32 event_reason_id = 22;
   * @return {number}
   */
  getEventReasonId(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 22, 0));
  };


  /** @param {number} value */
  setEventReasonId(value: number) {
    jspb.Message.setProto3IntField(this, 22, value);
  }


  /**
   * optional bool allow_self_check_in = 23;
   * @return {boolean}
   */
  getAllowSelfCheckIn(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 23, false));
  };


  /** @param {boolean} value */
  setAllowSelfCheckIn(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 23, value);
  }


}
export namespace EventInfo {
export enum EventBannerCase {
  EVENT_BANNER_NOT_SET = 0,
  EVENT_BANNER_ASSET = 9,
  EVENT_BANNER_HASH = 11,
}
} // namespace EventInfo
export namespace DeltaEventInfo {
  export interface AsObject {
    contentHash: string;
    title?: common_delta_pb.StringDelta.AsObject;
    description?: common_delta_pb.StringDelta.AsObject;
    eventHost?: common_delta_pb.StringDelta.AsObject;
    eventLocation?: common_delta_pb.StringDelta.AsObject;
    eventStart?: common_delta_pb.DateTimeDelta.AsObject;
    eventEnd?: common_delta_pb.DateTimeDelta.AsObject;
    allowGoing?: common_delta_pb.BoolDelta.AsObject;
    eventBannerAsset?: common_delta_pb.StringDelta.AsObject;
    allDayEvent?: common_delta_pb.BoolDelta.AsObject;
    eventCode?: common_delta_pb.StringDelta.AsObject;
    eventBannerHash?: common_delta_pb.StringDelta.AsObject;
    points?: common_delta_pb.Int32Delta.AsObject;
    ticketUrl?: common_delta_pb.StringDelta.AsObject;
    eventCategory?: common_delta_pb.StringDelta.AsObject;
    additionalDatesList: AdditionalDate.AsObject[];
    recurringEventId: number;
    hasTickets?: common_delta_pb.BoolDelta.AsObject;
    ticketInfo?: common_delta_pb.StringDelta.AsObject;
    ticketPrice?: common_delta_pb.FloatDelta.AsObject;
    singleCheckIn?: common_delta_pb.BoolDelta.AsObject;
    allowSelfCheckIn?: common_delta_pb.BoolDelta.AsObject;
  }
}
export class DeltaEventInfo extends jspb.Message {
  static readonly displayName = "DeltaEventInfo";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [16];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, DeltaEventInfo.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): DeltaEventInfo.AsObject {
    return DeltaEventInfo.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!DeltaEventInfo} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: DeltaEventInfo) {
    var f: any;
    var obj: any = {
      contentHash: jspb.Message.getFieldWithDefault(msg, 1, ""),
      title: (f = msg.getTitle()) && common_delta_pb.StringDelta.toObject(includeInstance, f),
      description: (f = msg.getDescription()) && common_delta_pb.StringDelta.toObject(includeInstance, f),
      eventHost: (f = msg.getEventHost()) && common_delta_pb.StringDelta.toObject(includeInstance, f),
      eventLocation: (f = msg.getEventLocation()) && common_delta_pb.StringDelta.toObject(includeInstance, f),
      eventStart: (f = msg.getEventStart()) && common_delta_pb.DateTimeDelta.toObject(includeInstance, f),
      eventEnd: (f = msg.getEventEnd()) && common_delta_pb.DateTimeDelta.toObject(includeInstance, f),
      allowGoing: (f = msg.getAllowGoing()) && common_delta_pb.BoolDelta.toObject(includeInstance, f),
      eventBannerAsset: (f = msg.getEventBannerAsset()) && common_delta_pb.StringDelta.toObject(includeInstance, f),
      allDayEvent: (f = msg.getAllDayEvent()) && common_delta_pb.BoolDelta.toObject(includeInstance, f),
      eventCode: (f = msg.getEventCode()) && common_delta_pb.StringDelta.toObject(includeInstance, f),
      eventBannerHash: (f = msg.getEventBannerHash()) && common_delta_pb.StringDelta.toObject(includeInstance, f),
      points: (f = msg.getPoints()) && common_delta_pb.Int32Delta.toObject(includeInstance, f),
      ticketUrl: (f = msg.getTicketUrl()) && common_delta_pb.StringDelta.toObject(includeInstance, f),
      eventCategory: (f = msg.getEventCategory()) && common_delta_pb.StringDelta.toObject(includeInstance, f),
      additionalDatesList: jspb.Message.toObjectList(msg.getAdditionalDatesList(),
      AdditionalDate.toObject, includeInstance),
      recurringeventid: jspb.Message.getFieldWithDefault(msg, 17, 0),
      hasTickets: (f = msg.getHasTickets()) && common_delta_pb.BoolDelta.toObject(includeInstance, f),
      ticketInfo: (f = msg.getTicketInfo()) && common_delta_pb.StringDelta.toObject(includeInstance, f),
      ticketPrice: (f = msg.getTicketPrice()) && common_delta_pb.FloatDelta.toObject(includeInstance, f),
      singleCheckIn: (f = msg.getSingleCheckIn()) && common_delta_pb.BoolDelta.toObject(includeInstance, f),
      allowSelfCheckIn: (f = msg.getAllowSelfCheckIn()) && common_delta_pb.BoolDelta.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!DeltaEventInfo}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new DeltaEventInfo;
    return DeltaEventInfo.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!DeltaEventInfo} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!DeltaEventInfo}
   */
  static deserializeBinaryFromReader(msg: DeltaEventInfo, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setContentHash(value1);
        break;
      case 2:
        var value2 = new common_delta_pb.StringDelta;
        reader.readMessage(value2,common_delta_pb.StringDelta.deserializeBinaryFromReader);
        msg.setTitle(value2);
        break;
      case 3:
        var value3 = new common_delta_pb.StringDelta;
        reader.readMessage(value3,common_delta_pb.StringDelta.deserializeBinaryFromReader);
        msg.setDescription(value3);
        break;
      case 4:
        var value4 = new common_delta_pb.StringDelta;
        reader.readMessage(value4,common_delta_pb.StringDelta.deserializeBinaryFromReader);
        msg.setEventHost(value4);
        break;
      case 5:
        var value5 = new common_delta_pb.StringDelta;
        reader.readMessage(value5,common_delta_pb.StringDelta.deserializeBinaryFromReader);
        msg.setEventLocation(value5);
        break;
      case 6:
        var value6 = new common_delta_pb.DateTimeDelta;
        reader.readMessage(value6,common_delta_pb.DateTimeDelta.deserializeBinaryFromReader);
        msg.setEventStart(value6);
        break;
      case 7:
        var value7 = new common_delta_pb.DateTimeDelta;
        reader.readMessage(value7,common_delta_pb.DateTimeDelta.deserializeBinaryFromReader);
        msg.setEventEnd(value7);
        break;
      case 8:
        var value8 = new common_delta_pb.BoolDelta;
        reader.readMessage(value8,common_delta_pb.BoolDelta.deserializeBinaryFromReader);
        msg.setAllowGoing(value8);
        break;
      case 9:
        var value9 = new common_delta_pb.StringDelta;
        reader.readMessage(value9,common_delta_pb.StringDelta.deserializeBinaryFromReader);
        msg.setEventBannerAsset(value9);
        break;
      case 10:
        var value10 = new common_delta_pb.BoolDelta;
        reader.readMessage(value10,common_delta_pb.BoolDelta.deserializeBinaryFromReader);
        msg.setAllDayEvent(value10);
        break;
      case 11:
        var value11 = new common_delta_pb.StringDelta;
        reader.readMessage(value11,common_delta_pb.StringDelta.deserializeBinaryFromReader);
        msg.setEventCode(value11);
        break;
      case 12:
        var value12 = new common_delta_pb.StringDelta;
        reader.readMessage(value12,common_delta_pb.StringDelta.deserializeBinaryFromReader);
        msg.setEventBannerHash(value12);
        break;
      case 13:
        var value13 = new common_delta_pb.Int32Delta;
        reader.readMessage(value13,common_delta_pb.Int32Delta.deserializeBinaryFromReader);
        msg.setPoints(value13);
        break;
      case 14:
        var value14 = new common_delta_pb.StringDelta;
        reader.readMessage(value14,common_delta_pb.StringDelta.deserializeBinaryFromReader);
        msg.setTicketUrl(value14);
        break;
      case 15:
        var value15 = new common_delta_pb.StringDelta;
        reader.readMessage(value15,common_delta_pb.StringDelta.deserializeBinaryFromReader);
        msg.setEventCategory(value15);
        break;
      case 16:
        var value16 = new AdditionalDate;
        reader.readMessage(value16,AdditionalDate.deserializeBinaryFromReader);
        msg.addAdditionalDates(value16);
        break;
      case 17:
        var value17 = /** @type {number} */ (reader.readInt32());
        msg.setRecurringeventid(value17);
        break;
      case 18:
        var value18 = new common_delta_pb.BoolDelta;
        reader.readMessage(value18,common_delta_pb.BoolDelta.deserializeBinaryFromReader);
        msg.setHasTickets(value18);
        break;
      case 19:
        var value19 = new common_delta_pb.StringDelta;
        reader.readMessage(value19,common_delta_pb.StringDelta.deserializeBinaryFromReader);
        msg.setTicketInfo(value19);
        break;
      case 20:
        var value20 = new common_delta_pb.FloatDelta;
        reader.readMessage(value20,common_delta_pb.FloatDelta.deserializeBinaryFromReader);
        msg.setTicketPrice(value20);
        break;
      case 21:
        var value21 = new common_delta_pb.BoolDelta;
        reader.readMessage(value21,common_delta_pb.BoolDelta.deserializeBinaryFromReader);
        msg.setSingleCheckIn(value21);
        break;
      case 22:
        var value22 = new common_delta_pb.BoolDelta;
        reader.readMessage(value22,common_delta_pb.BoolDelta.deserializeBinaryFromReader);
        msg.setAllowSelfCheckIn(value22);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!DeltaEventInfo} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: DeltaEventInfo, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getContentHash();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
    f = message.getTitle();
    if (f != null) {
      writer.writeMessage(
        2,
        f,
        common_delta_pb.StringDelta.serializeBinaryToWriter
      );
    }
    f = message.getDescription();
    if (f != null) {
      writer.writeMessage(
        3,
        f,
        common_delta_pb.StringDelta.serializeBinaryToWriter
      );
    }
    f = message.getEventHost();
    if (f != null) {
      writer.writeMessage(
        4,
        f,
        common_delta_pb.StringDelta.serializeBinaryToWriter
      );
    }
    f = message.getEventLocation();
    if (f != null) {
      writer.writeMessage(
        5,
        f,
        common_delta_pb.StringDelta.serializeBinaryToWriter
      );
    }
    f = message.getEventStart();
    if (f != null) {
      writer.writeMessage(
        6,
        f,
        common_delta_pb.DateTimeDelta.serializeBinaryToWriter
      );
    }
    f = message.getEventEnd();
    if (f != null) {
      writer.writeMessage(
        7,
        f,
        common_delta_pb.DateTimeDelta.serializeBinaryToWriter
      );
    }
    f = message.getAllowGoing();
    if (f != null) {
      writer.writeMessage(
        8,
        f,
        common_delta_pb.BoolDelta.serializeBinaryToWriter
      );
    }
    f = message.getEventBannerAsset();
    if (f != null) {
      writer.writeMessage(
        9,
        f,
        common_delta_pb.StringDelta.serializeBinaryToWriter
      );
    }
    f = message.getAllDayEvent();
    if (f != null) {
      writer.writeMessage(
        10,
        f,
        common_delta_pb.BoolDelta.serializeBinaryToWriter
      );
    }
    f = message.getEventCode();
    if (f != null) {
      writer.writeMessage(
        11,
        f,
        common_delta_pb.StringDelta.serializeBinaryToWriter
      );
    }
    f = message.getEventBannerHash();
    if (f != null) {
      writer.writeMessage(
        12,
        f,
        common_delta_pb.StringDelta.serializeBinaryToWriter
      );
    }
    f = message.getPoints();
    if (f != null) {
      writer.writeMessage(
        13,
        f,
        common_delta_pb.Int32Delta.serializeBinaryToWriter
      );
    }
    f = message.getTicketUrl();
    if (f != null) {
      writer.writeMessage(
        14,
        f,
        common_delta_pb.StringDelta.serializeBinaryToWriter
      );
    }
    f = message.getEventCategory();
    if (f != null) {
      writer.writeMessage(
        15,
        f,
        common_delta_pb.StringDelta.serializeBinaryToWriter
      );
    }
    f = message.getAdditionalDatesList();
    if (f.length > 0) {
      writer.writeRepeatedMessage(
        16,
        f,
        AdditionalDate.serializeBinaryToWriter
      );
    }
    f = message.getRecurringeventid();
    if (f !== 0) {
      writer.writeInt32(
        17,
        f
      );
    }
    f = message.getHasTickets();
    if (f != null) {
      writer.writeMessage(
        18,
        f,
        common_delta_pb.BoolDelta.serializeBinaryToWriter
      );
    }
    f = message.getTicketInfo();
    if (f != null) {
      writer.writeMessage(
        19,
        f,
        common_delta_pb.StringDelta.serializeBinaryToWriter
      );
    }
    f = message.getTicketPrice();
    if (f != null) {
      writer.writeMessage(
        20,
        f,
        common_delta_pb.FloatDelta.serializeBinaryToWriter
      );
    }
    f = message.getSingleCheckIn();
    if (f != null) {
      writer.writeMessage(
        21,
        f,
        common_delta_pb.BoolDelta.serializeBinaryToWriter
      );
    }
    f = message.getAllowSelfCheckIn();
    if (f != null) {
      writer.writeMessage(
        22,
        f,
        common_delta_pb.BoolDelta.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    DeltaEventInfo.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string content_hash = 1;
   * @return {string}
   */
  getContentHash(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setContentHash(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


  /**
   * optional minga.common.StringDelta title = 2;
   * @return {?StringDelta}
   */
  getTitle(): common_delta_pb.StringDelta {
    return /** @type{?StringDelta} */ (
      jspb.Message.getWrapperField(this, common_delta_pb.StringDelta, 2));
  }


  /** @param {?StringDelta|undefined} value */
  setTitle(value?: common_delta_pb.StringDelta) {
    jspb.Message.setWrapperField(this, 2, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearTitle() {
    this.setTitle(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasTitle(): boolean {
    return jspb.Message.getField(this, 2) != null;
  }


  /**
   * optional minga.common.StringDelta description = 3;
   * @return {?StringDelta}
   */
  getDescription(): common_delta_pb.StringDelta {
    return /** @type{?StringDelta} */ (
      jspb.Message.getWrapperField(this, common_delta_pb.StringDelta, 3));
  }


  /** @param {?StringDelta|undefined} value */
  setDescription(value?: common_delta_pb.StringDelta) {
    jspb.Message.setWrapperField(this, 3, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearDescription() {
    this.setDescription(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasDescription(): boolean {
    return jspb.Message.getField(this, 3) != null;
  }


  /**
   * optional minga.common.StringDelta event_host = 4;
   * @return {?StringDelta}
   */
  getEventHost(): common_delta_pb.StringDelta {
    return /** @type{?StringDelta} */ (
      jspb.Message.getWrapperField(this, common_delta_pb.StringDelta, 4));
  }


  /** @param {?StringDelta|undefined} value */
  setEventHost(value?: common_delta_pb.StringDelta) {
    jspb.Message.setWrapperField(this, 4, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearEventHost() {
    this.setEventHost(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasEventHost(): boolean {
    return jspb.Message.getField(this, 4) != null;
  }


  /**
   * optional minga.common.StringDelta event_location = 5;
   * @return {?StringDelta}
   */
  getEventLocation(): common_delta_pb.StringDelta {
    return /** @type{?StringDelta} */ (
      jspb.Message.getWrapperField(this, common_delta_pb.StringDelta, 5));
  }


  /** @param {?StringDelta|undefined} value */
  setEventLocation(value?: common_delta_pb.StringDelta) {
    jspb.Message.setWrapperField(this, 5, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearEventLocation() {
    this.setEventLocation(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasEventLocation(): boolean {
    return jspb.Message.getField(this, 5) != null;
  }


  /**
   * optional minga.common.DateTimeDelta event_start = 6;
   * @return {?DateTimeDelta}
   */
  getEventStart(): common_delta_pb.DateTimeDelta {
    return /** @type{?DateTimeDelta} */ (
      jspb.Message.getWrapperField(this, common_delta_pb.DateTimeDelta, 6));
  }


  /** @param {?DateTimeDelta|undefined} value */
  setEventStart(value?: common_delta_pb.DateTimeDelta) {
    jspb.Message.setWrapperField(this, 6, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearEventStart() {
    this.setEventStart(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasEventStart(): boolean {
    return jspb.Message.getField(this, 6) != null;
  }


  /**
   * optional minga.common.DateTimeDelta event_end = 7;
   * @return {?DateTimeDelta}
   */
  getEventEnd(): common_delta_pb.DateTimeDelta {
    return /** @type{?DateTimeDelta} */ (
      jspb.Message.getWrapperField(this, common_delta_pb.DateTimeDelta, 7));
  }


  /** @param {?DateTimeDelta|undefined} value */
  setEventEnd(value?: common_delta_pb.DateTimeDelta) {
    jspb.Message.setWrapperField(this, 7, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearEventEnd() {
    this.setEventEnd(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasEventEnd(): boolean {
    return jspb.Message.getField(this, 7) != null;
  }


  /**
   * optional minga.common.BoolDelta allow_going = 8;
   * @return {?BoolDelta}
   */
  getAllowGoing(): common_delta_pb.BoolDelta {
    return /** @type{?BoolDelta} */ (
      jspb.Message.getWrapperField(this, common_delta_pb.BoolDelta, 8));
  }


  /** @param {?BoolDelta|undefined} value */
  setAllowGoing(value?: common_delta_pb.BoolDelta) {
    jspb.Message.setWrapperField(this, 8, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearAllowGoing() {
    this.setAllowGoing(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasAllowGoing(): boolean {
    return jspb.Message.getField(this, 8) != null;
  }


  /**
   * optional minga.common.StringDelta event_banner_asset = 9;
   * @return {?StringDelta}
   */
  getEventBannerAsset(): common_delta_pb.StringDelta {
    return /** @type{?StringDelta} */ (
      jspb.Message.getWrapperField(this, common_delta_pb.StringDelta, 9));
  }


  /** @param {?StringDelta|undefined} value */
  setEventBannerAsset(value?: common_delta_pb.StringDelta) {
    jspb.Message.setWrapperField(this, 9, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearEventBannerAsset() {
    this.setEventBannerAsset(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasEventBannerAsset(): boolean {
    return jspb.Message.getField(this, 9) != null;
  }


  /**
   * optional minga.common.BoolDelta all_day_event = 10;
   * @return {?BoolDelta}
   */
  getAllDayEvent(): common_delta_pb.BoolDelta {
    return /** @type{?BoolDelta} */ (
      jspb.Message.getWrapperField(this, common_delta_pb.BoolDelta, 10));
  }


  /** @param {?BoolDelta|undefined} value */
  setAllDayEvent(value?: common_delta_pb.BoolDelta) {
    jspb.Message.setWrapperField(this, 10, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearAllDayEvent() {
    this.setAllDayEvent(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasAllDayEvent(): boolean {
    return jspb.Message.getField(this, 10) != null;
  }


  /**
   * optional minga.common.StringDelta event_code = 11;
   * @return {?StringDelta}
   */
  getEventCode(): common_delta_pb.StringDelta {
    return /** @type{?StringDelta} */ (
      jspb.Message.getWrapperField(this, common_delta_pb.StringDelta, 11));
  }


  /** @param {?StringDelta|undefined} value */
  setEventCode(value?: common_delta_pb.StringDelta) {
    jspb.Message.setWrapperField(this, 11, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearEventCode() {
    this.setEventCode(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasEventCode(): boolean {
    return jspb.Message.getField(this, 11) != null;
  }


  /**
   * optional minga.common.StringDelta event_banner_hash = 12;
   * @return {?StringDelta}
   */
  getEventBannerHash(): common_delta_pb.StringDelta {
    return /** @type{?StringDelta} */ (
      jspb.Message.getWrapperField(this, common_delta_pb.StringDelta, 12));
  }


  /** @param {?StringDelta|undefined} value */
  setEventBannerHash(value?: common_delta_pb.StringDelta) {
    jspb.Message.setWrapperField(this, 12, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearEventBannerHash() {
    this.setEventBannerHash(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasEventBannerHash(): boolean {
    return jspb.Message.getField(this, 12) != null;
  }


  /**
   * optional minga.common.Int32Delta points = 13;
   * @return {?Int32Delta}
   */
  getPoints(): common_delta_pb.Int32Delta {
    return /** @type{?Int32Delta} */ (
      jspb.Message.getWrapperField(this, common_delta_pb.Int32Delta, 13));
  }


  /** @param {?Int32Delta|undefined} value */
  setPoints(value?: common_delta_pb.Int32Delta) {
    jspb.Message.setWrapperField(this, 13, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearPoints() {
    this.setPoints(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasPoints(): boolean {
    return jspb.Message.getField(this, 13) != null;
  }


  /**
   * optional minga.common.StringDelta ticket_url = 14;
   * @return {?StringDelta}
   */
  getTicketUrl(): common_delta_pb.StringDelta {
    return /** @type{?StringDelta} */ (
      jspb.Message.getWrapperField(this, common_delta_pb.StringDelta, 14));
  }


  /** @param {?StringDelta|undefined} value */
  setTicketUrl(value?: common_delta_pb.StringDelta) {
    jspb.Message.setWrapperField(this, 14, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearTicketUrl() {
    this.setTicketUrl(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasTicketUrl(): boolean {
    return jspb.Message.getField(this, 14) != null;
  }


  /**
   * optional minga.common.StringDelta event_category = 15;
   * @return {?StringDelta}
   */
  getEventCategory(): common_delta_pb.StringDelta {
    return /** @type{?StringDelta} */ (
      jspb.Message.getWrapperField(this, common_delta_pb.StringDelta, 15));
  }


  /** @param {?StringDelta|undefined} value */
  setEventCategory(value?: common_delta_pb.StringDelta) {
    jspb.Message.setWrapperField(this, 15, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearEventCategory() {
    this.setEventCategory(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasEventCategory(): boolean {
    return jspb.Message.getField(this, 15) != null;
  }


  /**
   * repeated AdditionalDate additional_dates = 16;
   * @return {!Array<!AdditionalDate>}
   */
  getAdditionalDatesList(): AdditionalDate[] {
    return /** @type{!Array<!AdditionalDate>} */ (
      jspb.Message.getRepeatedWrapperField(this, AdditionalDate, 16));
  }


  /** @param {!Array<!AdditionalDate>} value */
  setAdditionalDatesList(value?: AdditionalDate[]) {
    jspb.Message.setRepeatedWrapperField(this, 16, value);
  }


  /**
   * @param {!AdditionalDate=} opt_value
   * @param {number=} opt_index
   * @return {!AdditionalDate}
   */
  addAdditionalDates(opt_value?: AdditionalDate, opt_index?: number): AdditionalDate {
    return jspb.Message.addToRepeatedWrapperField(this, 16, opt_value, AdditionalDate, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearAdditionalDatesList() {
    this.setAdditionalDatesList([]);
  }


  /**
   * optional int32 recurringEventId = 17;
   * @return {number}
   */
  getRecurringeventid(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 17, 0));
  };


  /** @param {number} value */
  setRecurringeventid(value: number) {
    jspb.Message.setProto3IntField(this, 17, value);
  }


  /**
   * optional minga.common.BoolDelta has_tickets = 18;
   * @return {?BoolDelta}
   */
  getHasTickets(): common_delta_pb.BoolDelta {
    return /** @type{?BoolDelta} */ (
      jspb.Message.getWrapperField(this, common_delta_pb.BoolDelta, 18));
  }


  /** @param {?BoolDelta|undefined} value */
  setHasTickets(value?: common_delta_pb.BoolDelta) {
    jspb.Message.setWrapperField(this, 18, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearHasTickets() {
    this.setHasTickets(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasHasTickets(): boolean {
    return jspb.Message.getField(this, 18) != null;
  }


  /**
   * optional minga.common.StringDelta ticket_info = 19;
   * @return {?StringDelta}
   */
  getTicketInfo(): common_delta_pb.StringDelta {
    return /** @type{?StringDelta} */ (
      jspb.Message.getWrapperField(this, common_delta_pb.StringDelta, 19));
  }


  /** @param {?StringDelta|undefined} value */
  setTicketInfo(value?: common_delta_pb.StringDelta) {
    jspb.Message.setWrapperField(this, 19, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearTicketInfo() {
    this.setTicketInfo(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasTicketInfo(): boolean {
    return jspb.Message.getField(this, 19) != null;
  }


  /**
   * optional minga.common.FloatDelta ticket_price = 20;
   * @return {?FloatDelta}
   */
  getTicketPrice(): common_delta_pb.FloatDelta {
    return /** @type{?FloatDelta} */ (
      jspb.Message.getWrapperField(this, common_delta_pb.FloatDelta, 20));
  }


  /** @param {?FloatDelta|undefined} value */
  setTicketPrice(value?: common_delta_pb.FloatDelta) {
    jspb.Message.setWrapperField(this, 20, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearTicketPrice() {
    this.setTicketPrice(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasTicketPrice(): boolean {
    return jspb.Message.getField(this, 20) != null;
  }


  /**
   * optional minga.common.BoolDelta single_check_in = 21;
   * @return {?BoolDelta}
   */
  getSingleCheckIn(): common_delta_pb.BoolDelta {
    return /** @type{?BoolDelta} */ (
      jspb.Message.getWrapperField(this, common_delta_pb.BoolDelta, 21));
  }


  /** @param {?BoolDelta|undefined} value */
  setSingleCheckIn(value?: common_delta_pb.BoolDelta) {
    jspb.Message.setWrapperField(this, 21, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearSingleCheckIn() {
    this.setSingleCheckIn(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasSingleCheckIn(): boolean {
    return jspb.Message.getField(this, 21) != null;
  }


  /**
   * optional minga.common.BoolDelta allow_self_check_in = 22;
   * @return {?BoolDelta}
   */
  getAllowSelfCheckIn(): common_delta_pb.BoolDelta {
    return /** @type{?BoolDelta} */ (
      jspb.Message.getWrapperField(this, common_delta_pb.BoolDelta, 22));
  }


  /** @param {?BoolDelta|undefined} value */
  setAllowSelfCheckIn(value?: common_delta_pb.BoolDelta) {
    jspb.Message.setWrapperField(this, 22, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearAllowSelfCheckIn() {
    this.setAllowSelfCheckIn(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasAllowSelfCheckIn(): boolean {
    return jspb.Message.getField(this, 22) != null;
  }


}
export namespace EventReportFilter {
  export interface AsObject {
    contextHash: string;
    personStatus: string;
    limit: number;
    offset: number;
  }
}
export class EventReportFilter extends jspb.Message {
  static readonly displayName = "EventReportFilter";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): EventReportFilter.AsObject {
    return EventReportFilter.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!EventReportFilter} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: EventReportFilter) {
    var f: any;
    var obj: any = {
      contextHash: jspb.Message.getFieldWithDefault(msg, 1, ""),
      personStatus: jspb.Message.getFieldWithDefault(msg, 2, ""),
      limit: jspb.Message.getFieldWithDefault(msg, 3, 0),
      offset: jspb.Message.getFieldWithDefault(msg, 4, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!EventReportFilter}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new EventReportFilter;
    return EventReportFilter.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!EventReportFilter} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!EventReportFilter}
   */
  static deserializeBinaryFromReader(msg: EventReportFilter, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setContextHash(value1);
        break;
      case 2:
        var value2 = /** @type {string} */ (reader.readString());
        msg.setPersonStatus(value2);
        break;
      case 3:
        var value3 = /** @type {number} */ (reader.readInt32());
        msg.setLimit(value3);
        break;
      case 4:
        var value4 = /** @type {number} */ (reader.readInt32());
        msg.setOffset(value4);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!EventReportFilter} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: EventReportFilter, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getContextHash();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
    f = message.getPersonStatus();
    if (f.length > 0) {
      writer.writeString(
        2,
        f
      );
    }
    f = message.getLimit();
    if (f !== 0) {
      writer.writeInt32(
        3,
        f
      );
    }
    f = message.getOffset();
    if (f !== 0) {
      writer.writeInt32(
        4,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    EventReportFilter.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string context_hash = 1;
   * @return {string}
   */
  getContextHash(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setContextHash(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


  /**
   * optional string person_status = 2;
   * @return {string}
   */
  getPersonStatus(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
  };


  /** @param {string} value */
  setPersonStatus(value: string) {
    jspb.Message.setProto3StringField(this, 2, value);
  }


  /**
   * optional int32 limit = 3;
   * @return {number}
   */
  getLimit(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
  };


  /** @param {number} value */
  setLimit(value: number) {
    jspb.Message.setProto3IntField(this, 3, value);
  }


  /**
   * optional int32 offset = 4;
   * @return {number}
   */
  getOffset(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
  };


  /** @param {number} value */
  setOffset(value: number) {
    jspb.Message.setProto3IntField(this, 4, value);
  }


}
export namespace EventPerson {
  export interface AsObject {
    name: string;
    tickets: number;
    checkinTime?: common_date_pb.DateTime.AsObject;
    checkoutTime?: common_date_pb.DateTime.AsObject;
    studentId: string;
  }
}
export class EventPerson extends jspb.Message {
  static readonly displayName = "EventPerson";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): EventPerson.AsObject {
    return EventPerson.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!EventPerson} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: EventPerson) {
    var f: any;
    var obj: any = {
      name: jspb.Message.getFieldWithDefault(msg, 1, ""),
      tickets: jspb.Message.getFieldWithDefault(msg, 2, 0),
      checkinTime: (f = msg.getCheckinTime()) && common_date_pb.DateTime.toObject(includeInstance, f),
      checkoutTime: (f = msg.getCheckoutTime()) && common_date_pb.DateTime.toObject(includeInstance, f),
      studentId: jspb.Message.getFieldWithDefault(msg, 5, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!EventPerson}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new EventPerson;
    return EventPerson.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!EventPerson} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!EventPerson}
   */
  static deserializeBinaryFromReader(msg: EventPerson, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setName(value1);
        break;
      case 2:
        var value2 = /** @type {number} */ (reader.readInt32());
        msg.setTickets(value2);
        break;
      case 3:
        var value3 = new common_date_pb.DateTime;
        reader.readMessage(value3,common_date_pb.DateTime.deserializeBinaryFromReader);
        msg.setCheckinTime(value3);
        break;
      case 4:
        var value4 = new common_date_pb.DateTime;
        reader.readMessage(value4,common_date_pb.DateTime.deserializeBinaryFromReader);
        msg.setCheckoutTime(value4);
        break;
      case 5:
        var value5 = /** @type {string} */ (reader.readString());
        msg.setStudentId(value5);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!EventPerson} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: EventPerson, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getName();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
    f = message.getTickets();
    if (f !== 0) {
      writer.writeInt32(
        2,
        f
      );
    }
    f = message.getCheckinTime();
    if (f != null) {
      writer.writeMessage(
        3,
        f,
        common_date_pb.DateTime.serializeBinaryToWriter
      );
    }
    f = message.getCheckoutTime();
    if (f != null) {
      writer.writeMessage(
        4,
        f,
        common_date_pb.DateTime.serializeBinaryToWriter
      );
    }
    f = message.getStudentId();
    if (f.length > 0) {
      writer.writeString(
        5,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    EventPerson.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string name = 1;
   * @return {string}
   */
  getName(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setName(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


  /**
   * optional int32 tickets = 2;
   * @return {number}
   */
  getTickets(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
  };


  /** @param {number} value */
  setTickets(value: number) {
    jspb.Message.setProto3IntField(this, 2, value);
  }


  /**
   * optional minga.common.DateTime checkin_time = 3;
   * @return {?DateTime}
   */
  getCheckinTime(): common_date_pb.DateTime {
    return /** @type{?DateTime} */ (
      jspb.Message.getWrapperField(this, common_date_pb.DateTime, 3));
  }


  /** @param {?DateTime|undefined} value */
  setCheckinTime(value?: common_date_pb.DateTime) {
    jspb.Message.setWrapperField(this, 3, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearCheckinTime() {
    this.setCheckinTime(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasCheckinTime(): boolean {
    return jspb.Message.getField(this, 3) != null;
  }


  /**
   * optional minga.common.DateTime checkout_time = 4;
   * @return {?DateTime}
   */
  getCheckoutTime(): common_date_pb.DateTime {
    return /** @type{?DateTime} */ (
      jspb.Message.getWrapperField(this, common_date_pb.DateTime, 4));
  }


  /** @param {?DateTime|undefined} value */
  setCheckoutTime(value?: common_date_pb.DateTime) {
    jspb.Message.setWrapperField(this, 4, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearCheckoutTime() {
    this.setCheckoutTime(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasCheckoutTime(): boolean {
    return jspb.Message.getField(this, 4) != null;
  }


  /**
   * optional string student_id = 5;
   * @return {string}
   */
  getStudentId(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
  };


  /** @param {string} value */
  setStudentId(value: string) {
    jspb.Message.setProto3StringField(this, 5, value);
  }


}
export namespace CancelEventRequest {
  export interface AsObject {
    contentHash: string;
  }
}
export class CancelEventRequest extends jspb.Message {
  static readonly displayName = "CancelEventRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): CancelEventRequest.AsObject {
    return CancelEventRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!CancelEventRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: CancelEventRequest) {
    var f: any;
    var obj: any = {
      contentHash: jspb.Message.getFieldWithDefault(msg, 1, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!CancelEventRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new CancelEventRequest;
    return CancelEventRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!CancelEventRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!CancelEventRequest}
   */
  static deserializeBinaryFromReader(msg: CancelEventRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setContentHash(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!CancelEventRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: CancelEventRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getContentHash();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    CancelEventRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string content_hash = 1;
   * @return {string}
   */
  getContentHash(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setContentHash(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


}
export namespace CancelEventResponse {
  export interface AsObject {
  }
}
export class CancelEventResponse extends jspb.Message {
  static readonly displayName = "CancelEventResponse";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): CancelEventResponse.AsObject {
    return CancelEventResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!CancelEventResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: CancelEventResponse) {
    var f: any;
    var obj: any = {

    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!CancelEventResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new CancelEventResponse;
    return CancelEventResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!CancelEventResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!CancelEventResponse}
   */
  static deserializeBinaryFromReader(msg: CancelEventResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!CancelEventResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: CancelEventResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    CancelEventResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
}
export namespace CheckInUsersRequest {
  export interface AsObject {
    contextHash: string;
    personHashList: string[];
    guestIdList: number[];
    bypassValidation: boolean;
  }
}
export class CheckInUsersRequest extends jspb.Message {
  static readonly displayName = "CheckInUsersRequest";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [2,3];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, CheckInUsersRequest.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): CheckInUsersRequest.AsObject {
    return CheckInUsersRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!CheckInUsersRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: CheckInUsersRequest) {
    var f: any;
    var obj: any = {
      contextHash: jspb.Message.getFieldWithDefault(msg, 1, ""),
      personHashList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
      guestIdList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
      bypassValidation: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!CheckInUsersRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new CheckInUsersRequest;
    return CheckInUsersRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!CheckInUsersRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!CheckInUsersRequest}
   */
  static deserializeBinaryFromReader(msg: CheckInUsersRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setContextHash(value1);
        break;
      case 2:
        var value2 = /** @type {string} */ (reader.readString());
        msg.addPersonHash(value2);
        break;
      case 3:
        var value3 = /** @type {!Array<number>} */ (reader.readPackedInt32());
        msg.setGuestIdList(value3);
        break;
      case 4:
        var value4 = /** @type {boolean} */ (reader.readBool());
        msg.setBypassValidation(value4);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!CheckInUsersRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: CheckInUsersRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getContextHash();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
    f = message.getPersonHashList();
    if (f.length > 0) {
      writer.writeRepeatedString(
        2,
        f
      );
    }
    f = message.getGuestIdList();
    if (f.length > 0) {
      writer.writePackedInt32(
        3,
        f
      );
    }
    f = message.getBypassValidation();
    if (f) {
      writer.writeBool(
        4,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    CheckInUsersRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string context_hash = 1;
   * @return {string}
   */
  getContextHash(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setContextHash(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


  /**
   * repeated string person_hash = 2;
   * @return {!Array<string>}
   */
  getPersonHashList(): string[] {
    return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
  };


  /** @param {!Array<string>} value */
  setPersonHashList(value: string[]) {
    jspb.Message.setField(this, 2, value || []);
  }


  /**
   * @param {string} value
   * @param {number=} opt_index
   */
  addPersonHash(value: string, opt_index?: number): void {
    jspb.Message.addToRepeatedField(this, 2, value, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearPersonHashList() {
    this.setPersonHashList([]);
  }


  /**
   * repeated int32 guest_id = 3;
   * @return {!Array<number>}
   */
  getGuestIdList(): number[] {
    return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 3));
  };


  /** @param {!Array<number>} value */
  setGuestIdList(value: number[]) {
    jspb.Message.setField(this, 3, value || []);
  }


  /**
   * @param {number} value
   * @param {number=} opt_index
   */
  addGuestId(value: number, opt_index?: number): void {
    jspb.Message.addToRepeatedField(this, 3, value, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearGuestIdList() {
    this.setGuestIdList([]);
  }


  /**
   * optional bool bypass_validation = 4;
   * @return {boolean}
   */
  getBypassValidation(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
  };


  /** @param {boolean} value */
  setBypassValidation(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 4, value);
  }


}
export namespace EventValidityError {
  export interface AsObject {
    errorCode: number;
    personHash: string;
    name: string;
  }
}
export class EventValidityError extends jspb.Message {
  static readonly displayName = "EventValidityError";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): EventValidityError.AsObject {
    return EventValidityError.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!EventValidityError} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: EventValidityError) {
    var f: any;
    var obj: any = {
      errorCode: jspb.Message.getFieldWithDefault(msg, 1, 0),
      personHash: jspb.Message.getFieldWithDefault(msg, 2, ""),
      name: jspb.Message.getFieldWithDefault(msg, 3, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!EventValidityError}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new EventValidityError;
    return EventValidityError.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!EventValidityError} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!EventValidityError}
   */
  static deserializeBinaryFromReader(msg: EventValidityError, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {number} */ (reader.readInt32());
        msg.setErrorCode(value1);
        break;
      case 2:
        var value2 = /** @type {string} */ (reader.readString());
        msg.setPersonHash(value2);
        break;
      case 3:
        var value3 = /** @type {string} */ (reader.readString());
        msg.setName(value3);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!EventValidityError} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: EventValidityError, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getErrorCode();
    if (f !== 0) {
      writer.writeInt32(
        1,
        f
      );
    }
    f = message.getPersonHash();
    if (f.length > 0) {
      writer.writeString(
        2,
        f
      );
    }
    f = message.getName();
    if (f.length > 0) {
      writer.writeString(
        3,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    EventValidityError.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional int32 error_code = 1;
   * @return {number}
   */
  getErrorCode(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
  };


  /** @param {number} value */
  setErrorCode(value: number) {
    jspb.Message.setProto3IntField(this, 1, value);
  }


  /**
   * optional string person_hash = 2;
   * @return {string}
   */
  getPersonHash(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
  };


  /** @param {string} value */
  setPersonHash(value: string) {
    jspb.Message.setProto3StringField(this, 2, value);
  }


  /**
   * optional string name = 3;
   * @return {string}
   */
  getName(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
  };


  /** @param {string} value */
  setName(value: string) {
    jspb.Message.setProto3StringField(this, 3, value);
  }


}
export namespace CheckInUsersResponse {
  export interface AsObject {
    errorsList: EventValidityError.AsObject[];
    restrictionErrorsList: restriction_restriction_pb.RestrictionError.AsObject[];
  }
}
export class CheckInUsersResponse extends jspb.Message {
  static readonly displayName = "CheckInUsersResponse";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [1,2];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, CheckInUsersResponse.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): CheckInUsersResponse.AsObject {
    return CheckInUsersResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!CheckInUsersResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: CheckInUsersResponse) {
    var f: any;
    var obj: any = {
      errorsList: jspb.Message.toObjectList(msg.getErrorsList(),
      EventValidityError.toObject, includeInstance),
      restrictionErrorsList: jspb.Message.toObjectList(msg.getRestrictionErrorsList(),
      restriction_restriction_pb.RestrictionError.toObject, includeInstance)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!CheckInUsersResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new CheckInUsersResponse;
    return CheckInUsersResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!CheckInUsersResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!CheckInUsersResponse}
   */
  static deserializeBinaryFromReader(msg: CheckInUsersResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new EventValidityError;
        reader.readMessage(value1,EventValidityError.deserializeBinaryFromReader);
        msg.addErrors(value1);
        break;
      case 2:
        var value2 = new restriction_restriction_pb.RestrictionError;
        reader.readMessage(value2,restriction_restriction_pb.RestrictionError.deserializeBinaryFromReader);
        msg.addRestrictionErrors(value2);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!CheckInUsersResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: CheckInUsersResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getErrorsList();
    if (f.length > 0) {
      writer.writeRepeatedMessage(
        1,
        f,
        EventValidityError.serializeBinaryToWriter
      );
    }
    f = message.getRestrictionErrorsList();
    if (f.length > 0) {
      writer.writeRepeatedMessage(
        2,
        f,
        restriction_restriction_pb.RestrictionError.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    CheckInUsersResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * repeated EventValidityError errors = 1;
   * @return {!Array<!EventValidityError>}
   */
  getErrorsList(): EventValidityError[] {
    return /** @type{!Array<!EventValidityError>} */ (
      jspb.Message.getRepeatedWrapperField(this, EventValidityError, 1));
  }


  /** @param {!Array<!EventValidityError>} value */
  setErrorsList(value?: EventValidityError[]) {
    jspb.Message.setRepeatedWrapperField(this, 1, value);
  }


  /**
   * @param {!EventValidityError=} opt_value
   * @param {number=} opt_index
   * @return {!EventValidityError}
   */
  addErrors(opt_value?: EventValidityError, opt_index?: number): EventValidityError {
    return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, EventValidityError, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearErrorsList() {
    this.setErrorsList([]);
  }


  /**
   * repeated restriction.RestrictionError restriction_errors = 2;
   * @return {!Array<!RestrictionError>}
   */
  getRestrictionErrorsList(): restriction_restriction_pb.RestrictionError[] {
    return /** @type{!Array<!RestrictionError>} */ (
      jspb.Message.getRepeatedWrapperField(this, restriction_restriction_pb.RestrictionError, 2));
  }


  /** @param {!Array<!RestrictionError>} value */
  setRestrictionErrorsList(value?: restriction_restriction_pb.RestrictionError[]) {
    jspb.Message.setRepeatedWrapperField(this, 2, value);
  }


  /**
   * @param {!RestrictionError=} opt_value
   * @param {number=} opt_index
   * @return {!RestrictionError}
   */
  addRestrictionErrors(opt_value?: restriction_restriction_pb.RestrictionError, opt_index?: number): restriction_restriction_pb.RestrictionError {
    return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, restriction_restriction_pb.RestrictionError, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearRestrictionErrorsList() {
    this.setRestrictionErrorsList([]);
  }


}
export namespace CheckOutUsersRequest {
  export interface AsObject {
    contextHash: string;
    personHashList: string[];
    guestIdList: number[];
  }
}
export class CheckOutUsersRequest extends jspb.Message {
  static readonly displayName = "CheckOutUsersRequest";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [2,3];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, CheckOutUsersRequest.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): CheckOutUsersRequest.AsObject {
    return CheckOutUsersRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!CheckOutUsersRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: CheckOutUsersRequest) {
    var f: any;
    var obj: any = {
      contextHash: jspb.Message.getFieldWithDefault(msg, 1, ""),
      personHashList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
      guestIdList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!CheckOutUsersRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new CheckOutUsersRequest;
    return CheckOutUsersRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!CheckOutUsersRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!CheckOutUsersRequest}
   */
  static deserializeBinaryFromReader(msg: CheckOutUsersRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setContextHash(value1);
        break;
      case 2:
        var value2 = /** @type {string} */ (reader.readString());
        msg.addPersonHash(value2);
        break;
      case 3:
        var value3 = /** @type {!Array<number>} */ (reader.readPackedInt32());
        msg.setGuestIdList(value3);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!CheckOutUsersRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: CheckOutUsersRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getContextHash();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
    f = message.getPersonHashList();
    if (f.length > 0) {
      writer.writeRepeatedString(
        2,
        f
      );
    }
    f = message.getGuestIdList();
    if (f.length > 0) {
      writer.writePackedInt32(
        3,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    CheckOutUsersRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string context_hash = 1;
   * @return {string}
   */
  getContextHash(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setContextHash(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


  /**
   * repeated string person_hash = 2;
   * @return {!Array<string>}
   */
  getPersonHashList(): string[] {
    return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
  };


  /** @param {!Array<string>} value */
  setPersonHashList(value: string[]) {
    jspb.Message.setField(this, 2, value || []);
  }


  /**
   * @param {string} value
   * @param {number=} opt_index
   */
  addPersonHash(value: string, opt_index?: number): void {
    jspb.Message.addToRepeatedField(this, 2, value, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearPersonHashList() {
    this.setPersonHashList([]);
  }


  /**
   * repeated int32 guest_id = 3;
   * @return {!Array<number>}
   */
  getGuestIdList(): number[] {
    return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 3));
  };


  /** @param {!Array<number>} value */
  setGuestIdList(value: number[]) {
    jspb.Message.setField(this, 3, value || []);
  }


  /**
   * @param {number} value
   * @param {number=} opt_index
   */
  addGuestId(value: number, opt_index?: number): void {
    jspb.Message.addToRepeatedField(this, 3, value, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearGuestIdList() {
    this.setGuestIdList([]);
  }


}
export namespace CheckOutUsersResponse {
  export interface AsObject {
  }
}
export class CheckOutUsersResponse extends jspb.Message {
  static readonly displayName = "CheckOutUsersResponse";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): CheckOutUsersResponse.AsObject {
    return CheckOutUsersResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!CheckOutUsersResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: CheckOutUsersResponse) {
    var f: any;
    var obj: any = {

    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!CheckOutUsersResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new CheckOutUsersResponse;
    return CheckOutUsersResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!CheckOutUsersResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!CheckOutUsersResponse}
   */
  static deserializeBinaryFromReader(msg: CheckOutUsersResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!CheckOutUsersResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: CheckOutUsersResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    CheckOutUsersResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
}
export namespace AddEventManagerRequest {
  export interface AsObject {
    contextHash: string;
    personHashList: string[];
  }
}
export class AddEventManagerRequest extends jspb.Message {
  static readonly displayName = "AddEventManagerRequest";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [2];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, AddEventManagerRequest.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): AddEventManagerRequest.AsObject {
    return AddEventManagerRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!AddEventManagerRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: AddEventManagerRequest) {
    var f: any;
    var obj: any = {
      contextHash: jspb.Message.getFieldWithDefault(msg, 1, ""),
      personHashList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!AddEventManagerRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new AddEventManagerRequest;
    return AddEventManagerRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!AddEventManagerRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!AddEventManagerRequest}
   */
  static deserializeBinaryFromReader(msg: AddEventManagerRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setContextHash(value1);
        break;
      case 2:
        var value2 = /** @type {string} */ (reader.readString());
        msg.addPersonHash(value2);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!AddEventManagerRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: AddEventManagerRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getContextHash();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
    f = message.getPersonHashList();
    if (f.length > 0) {
      writer.writeRepeatedString(
        2,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    AddEventManagerRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string context_hash = 1;
   * @return {string}
   */
  getContextHash(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setContextHash(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


  /**
   * repeated string person_hash = 2;
   * @return {!Array<string>}
   */
  getPersonHashList(): string[] {
    return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
  };


  /** @param {!Array<string>} value */
  setPersonHashList(value: string[]) {
    jspb.Message.setField(this, 2, value || []);
  }


  /**
   * @param {string} value
   * @param {number=} opt_index
   */
  addPersonHash(value: string, opt_index?: number): void {
    jspb.Message.addToRepeatedField(this, 2, value, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearPersonHashList() {
    this.setPersonHashList([]);
  }


}
export namespace AddEventManagerResponse {
  export interface AsObject {
  }
}
export class AddEventManagerResponse extends jspb.Message {
  static readonly displayName = "AddEventManagerResponse";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): AddEventManagerResponse.AsObject {
    return AddEventManagerResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!AddEventManagerResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: AddEventManagerResponse) {
    var f: any;
    var obj: any = {

    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!AddEventManagerResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new AddEventManagerResponse;
    return AddEventManagerResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!AddEventManagerResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!AddEventManagerResponse}
   */
  static deserializeBinaryFromReader(msg: AddEventManagerResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!AddEventManagerResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: AddEventManagerResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    AddEventManagerResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
}
export namespace RemoveEventManagerRequest {
  export interface AsObject {
    contextHash: string;
    personHashList: string[];
  }
}
export class RemoveEventManagerRequest extends jspb.Message {
  static readonly displayName = "RemoveEventManagerRequest";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [2];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, RemoveEventManagerRequest.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): RemoveEventManagerRequest.AsObject {
    return RemoveEventManagerRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!RemoveEventManagerRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: RemoveEventManagerRequest) {
    var f: any;
    var obj: any = {
      contextHash: jspb.Message.getFieldWithDefault(msg, 1, ""),
      personHashList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!RemoveEventManagerRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new RemoveEventManagerRequest;
    return RemoveEventManagerRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!RemoveEventManagerRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!RemoveEventManagerRequest}
   */
  static deserializeBinaryFromReader(msg: RemoveEventManagerRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setContextHash(value1);
        break;
      case 2:
        var value2 = /** @type {string} */ (reader.readString());
        msg.addPersonHash(value2);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!RemoveEventManagerRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: RemoveEventManagerRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getContextHash();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
    f = message.getPersonHashList();
    if (f.length > 0) {
      writer.writeRepeatedString(
        2,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    RemoveEventManagerRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string context_hash = 1;
   * @return {string}
   */
  getContextHash(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setContextHash(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


  /**
   * repeated string person_hash = 2;
   * @return {!Array<string>}
   */
  getPersonHashList(): string[] {
    return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
  };


  /** @param {!Array<string>} value */
  setPersonHashList(value: string[]) {
    jspb.Message.setField(this, 2, value || []);
  }


  /**
   * @param {string} value
   * @param {number=} opt_index
   */
  addPersonHash(value: string, opt_index?: number): void {
    jspb.Message.addToRepeatedField(this, 2, value, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearPersonHashList() {
    this.setPersonHashList([]);
  }


}
export namespace RemoveEventManagerResponse {
  export interface AsObject {
  }
}
export class RemoveEventManagerResponse extends jspb.Message {
  static readonly displayName = "RemoveEventManagerResponse";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): RemoveEventManagerResponse.AsObject {
    return RemoveEventManagerResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!RemoveEventManagerResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: RemoveEventManagerResponse) {
    var f: any;
    var obj: any = {

    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!RemoveEventManagerResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new RemoveEventManagerResponse;
    return RemoveEventManagerResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!RemoveEventManagerResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!RemoveEventManagerResponse}
   */
  static deserializeBinaryFromReader(msg: RemoveEventManagerResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!RemoveEventManagerResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: RemoveEventManagerResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    RemoveEventManagerResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
}
export namespace GetInvitedPeopleRequest {
  export interface AsObject {
    contextHash: string;
  }
}
export class GetInvitedPeopleRequest extends jspb.Message {
  static readonly displayName = "GetInvitedPeopleRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): GetInvitedPeopleRequest.AsObject {
    return GetInvitedPeopleRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!GetInvitedPeopleRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: GetInvitedPeopleRequest) {
    var f: any;
    var obj: any = {
      contextHash: jspb.Message.getFieldWithDefault(msg, 1, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!GetInvitedPeopleRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new GetInvitedPeopleRequest;
    return GetInvitedPeopleRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!GetInvitedPeopleRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!GetInvitedPeopleRequest}
   */
  static deserializeBinaryFromReader(msg: GetInvitedPeopleRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setContextHash(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!GetInvitedPeopleRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: GetInvitedPeopleRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getContextHash();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    GetInvitedPeopleRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string context_hash = 1;
   * @return {string}
   */
  getContextHash(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setContextHash(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


}
export namespace GetInvitedPeopleResponse {
  export interface AsObject {
    peopleList: gateway_person_view_pb.PersonViewEvent.AsObject[];
  }
}
export class GetInvitedPeopleResponse extends jspb.Message {
  static readonly displayName = "GetInvitedPeopleResponse";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [1];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, GetInvitedPeopleResponse.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): GetInvitedPeopleResponse.AsObject {
    return GetInvitedPeopleResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!GetInvitedPeopleResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: GetInvitedPeopleResponse) {
    var f: any;
    var obj: any = {
      peopleList: jspb.Message.toObjectList(msg.getPeopleList(),
      gateway_person_view_pb.PersonViewEvent.toObject, includeInstance)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!GetInvitedPeopleResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new GetInvitedPeopleResponse;
    return GetInvitedPeopleResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!GetInvitedPeopleResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!GetInvitedPeopleResponse}
   */
  static deserializeBinaryFromReader(msg: GetInvitedPeopleResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new gateway_person_view_pb.PersonViewEvent;
        reader.readMessage(value1,gateway_person_view_pb.PersonViewEvent.deserializeBinaryFromReader);
        msg.addPeople(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!GetInvitedPeopleResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: GetInvitedPeopleResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getPeopleList();
    if (f.length > 0) {
      writer.writeRepeatedMessage(
        1,
        f,
        gateway_person_view_pb.PersonViewEvent.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    GetInvitedPeopleResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * repeated PersonViewEvent people = 1;
   * @return {!Array<!PersonViewEvent>}
   */
  getPeopleList(): gateway_person_view_pb.PersonViewEvent[] {
    return /** @type{!Array<!PersonViewEvent>} */ (
      jspb.Message.getRepeatedWrapperField(this, gateway_person_view_pb.PersonViewEvent, 1));
  }


  /** @param {!Array<!PersonViewEvent>} value */
  setPeopleList(value?: gateway_person_view_pb.PersonViewEvent[]) {
    jspb.Message.setRepeatedWrapperField(this, 1, value);
  }


  /**
   * @param {!PersonViewEvent=} opt_value
   * @param {number=} opt_index
   * @return {!PersonViewEvent}
   */
  addPeople(opt_value?: gateway_person_view_pb.PersonViewEvent, opt_index?: number): gateway_person_view_pb.PersonViewEvent {
    return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, gateway_person_view_pb.PersonViewEvent, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearPeopleList() {
    this.setPeopleList([]);
  }


}
export namespace InvitePeopleRequest {
  export interface AsObject {
    contextHash: string;
    personHashList: string[];
  }
}
export class InvitePeopleRequest extends jspb.Message {
  static readonly displayName = "InvitePeopleRequest";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [2];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, InvitePeopleRequest.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): InvitePeopleRequest.AsObject {
    return InvitePeopleRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!InvitePeopleRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: InvitePeopleRequest) {
    var f: any;
    var obj: any = {
      contextHash: jspb.Message.getFieldWithDefault(msg, 1, ""),
      personHashList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!InvitePeopleRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new InvitePeopleRequest;
    return InvitePeopleRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!InvitePeopleRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!InvitePeopleRequest}
   */
  static deserializeBinaryFromReader(msg: InvitePeopleRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setContextHash(value1);
        break;
      case 2:
        var value2 = /** @type {string} */ (reader.readString());
        msg.addPersonHash(value2);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!InvitePeopleRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: InvitePeopleRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getContextHash();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
    f = message.getPersonHashList();
    if (f.length > 0) {
      writer.writeRepeatedString(
        2,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    InvitePeopleRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string context_hash = 1;
   * @return {string}
   */
  getContextHash(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setContextHash(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


  /**
   * repeated string person_hash = 2;
   * @return {!Array<string>}
   */
  getPersonHashList(): string[] {
    return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
  };


  /** @param {!Array<string>} value */
  setPersonHashList(value: string[]) {
    jspb.Message.setField(this, 2, value || []);
  }


  /**
   * @param {string} value
   * @param {number=} opt_index
   */
  addPersonHash(value: string, opt_index?: number): void {
    jspb.Message.addToRepeatedField(this, 2, value, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearPersonHashList() {
    this.setPersonHashList([]);
  }


}
export namespace InvitePeopleResponse {
  export interface AsObject {
  }
}
export class InvitePeopleResponse extends jspb.Message {
  static readonly displayName = "InvitePeopleResponse";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): InvitePeopleResponse.AsObject {
    return InvitePeopleResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!InvitePeopleResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: InvitePeopleResponse) {
    var f: any;
    var obj: any = {

    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!InvitePeopleResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new InvitePeopleResponse;
    return InvitePeopleResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!InvitePeopleResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!InvitePeopleResponse}
   */
  static deserializeBinaryFromReader(msg: InvitePeopleResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!InvitePeopleResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: InvitePeopleResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    InvitePeopleResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
}
export namespace GetEventTicketsByStudentIdRequest {
  export interface AsObject {
    contextHash: string;
    studentIdList: string[];
    personHashList: string[];
  }
}
export class GetEventTicketsByStudentIdRequest extends jspb.Message {
  static readonly displayName = "GetEventTicketsByStudentIdRequest";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [2,3];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, GetEventTicketsByStudentIdRequest.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): GetEventTicketsByStudentIdRequest.AsObject {
    return GetEventTicketsByStudentIdRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!GetEventTicketsByStudentIdRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: GetEventTicketsByStudentIdRequest) {
    var f: any;
    var obj: any = {
      contextHash: jspb.Message.getFieldWithDefault(msg, 1, ""),
      studentIdList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
      personHashList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!GetEventTicketsByStudentIdRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new GetEventTicketsByStudentIdRequest;
    return GetEventTicketsByStudentIdRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!GetEventTicketsByStudentIdRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!GetEventTicketsByStudentIdRequest}
   */
  static deserializeBinaryFromReader(msg: GetEventTicketsByStudentIdRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setContextHash(value1);
        break;
      case 2:
        var value2 = /** @type {string} */ (reader.readString());
        msg.addStudentId(value2);
        break;
      case 3:
        var value3 = /** @type {string} */ (reader.readString());
        msg.addPersonHash(value3);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!GetEventTicketsByStudentIdRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: GetEventTicketsByStudentIdRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getContextHash();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
    f = message.getStudentIdList();
    if (f.length > 0) {
      writer.writeRepeatedString(
        2,
        f
      );
    }
    f = message.getPersonHashList();
    if (f.length > 0) {
      writer.writeRepeatedString(
        3,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    GetEventTicketsByStudentIdRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string context_hash = 1;
   * @return {string}
   */
  getContextHash(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setContextHash(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


  /**
   * repeated string student_id = 2;
   * @return {!Array<string>}
   */
  getStudentIdList(): string[] {
    return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
  };


  /** @param {!Array<string>} value */
  setStudentIdList(value: string[]) {
    jspb.Message.setField(this, 2, value || []);
  }


  /**
   * @param {string} value
   * @param {number=} opt_index
   */
  addStudentId(value: string, opt_index?: number): void {
    jspb.Message.addToRepeatedField(this, 2, value, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearStudentIdList() {
    this.setStudentIdList([]);
  }


  /**
   * repeated string person_hash = 3;
   * @return {!Array<string>}
   */
  getPersonHashList(): string[] {
    return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
  };


  /** @param {!Array<string>} value */
  setPersonHashList(value: string[]) {
    jspb.Message.setField(this, 3, value || []);
  }


  /**
   * @param {string} value
   * @param {number=} opt_index
   */
  addPersonHash(value: string, opt_index?: number): void {
    jspb.Message.addToRepeatedField(this, 3, value, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearPersonHashList() {
    this.setPersonHashList([]);
  }


}
export namespace GetEventTicketsByStudentIdResponse {
  export interface AsObject {
    requiresTicket: boolean;
    personList: EventTicketPerson.AsObject[];
    singleCheckin: boolean;
  }
}
export class GetEventTicketsByStudentIdResponse extends jspb.Message {
  static readonly displayName = "GetEventTicketsByStudentIdResponse";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [2];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, GetEventTicketsByStudentIdResponse.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): GetEventTicketsByStudentIdResponse.AsObject {
    return GetEventTicketsByStudentIdResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!GetEventTicketsByStudentIdResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: GetEventTicketsByStudentIdResponse) {
    var f: any;
    var obj: any = {
      requiresTicket: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
      personList: jspb.Message.toObjectList(msg.getPersonList(),
      EventTicketPerson.toObject, includeInstance),
      singleCheckin: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!GetEventTicketsByStudentIdResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new GetEventTicketsByStudentIdResponse;
    return GetEventTicketsByStudentIdResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!GetEventTicketsByStudentIdResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!GetEventTicketsByStudentIdResponse}
   */
  static deserializeBinaryFromReader(msg: GetEventTicketsByStudentIdResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {boolean} */ (reader.readBool());
        msg.setRequiresTicket(value1);
        break;
      case 2:
        var value2 = new EventTicketPerson;
        reader.readMessage(value2,EventTicketPerson.deserializeBinaryFromReader);
        msg.addPerson(value2);
        break;
      case 3:
        var value3 = /** @type {boolean} */ (reader.readBool());
        msg.setSingleCheckin(value3);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!GetEventTicketsByStudentIdResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: GetEventTicketsByStudentIdResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getRequiresTicket();
    if (f) {
      writer.writeBool(
        1,
        f
      );
    }
    f = message.getPersonList();
    if (f.length > 0) {
      writer.writeRepeatedMessage(
        2,
        f,
        EventTicketPerson.serializeBinaryToWriter
      );
    }
    f = message.getSingleCheckin();
    if (f) {
      writer.writeBool(
        3,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    GetEventTicketsByStudentIdResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional bool requires_ticket = 1;
   * @return {boolean}
   */
  getRequiresTicket(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
  };


  /** @param {boolean} value */
  setRequiresTicket(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 1, value);
  }


  /**
   * repeated EventTicketPerson person = 2;
   * @return {!Array<!EventTicketPerson>}
   */
  getPersonList(): EventTicketPerson[] {
    return /** @type{!Array<!EventTicketPerson>} */ (
      jspb.Message.getRepeatedWrapperField(this, EventTicketPerson, 2));
  }


  /** @param {!Array<!EventTicketPerson>} value */
  setPersonList(value?: EventTicketPerson[]) {
    jspb.Message.setRepeatedWrapperField(this, 2, value);
  }


  /**
   * @param {!EventTicketPerson=} opt_value
   * @param {number=} opt_index
   * @return {!EventTicketPerson}
   */
  addPerson(opt_value?: EventTicketPerson, opt_index?: number): EventTicketPerson {
    return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, EventTicketPerson, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearPersonList() {
    this.setPersonList([]);
  }


  /**
   * optional bool single_checkin = 3;
   * @return {boolean}
   */
  getSingleCheckin(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
  };


  /** @param {boolean} value */
  setSingleCheckin(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 3, value);
  }


}
export namespace EventTicketPerson {
  export interface AsObject {
    ticket?: EventTicket.AsObject;
    displayName: string;
    personHash: string;
    checkedIn?: common_date_pb.DateTime.AsObject;
    checkedOut?: common_date_pb.DateTime.AsObject;
  }
}
export class EventTicketPerson extends jspb.Message {
  static readonly displayName = "EventTicketPerson";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): EventTicketPerson.AsObject {
    return EventTicketPerson.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!EventTicketPerson} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: EventTicketPerson) {
    var f: any;
    var obj: any = {
      ticket: (f = msg.getTicket()) && EventTicket.toObject(includeInstance, f),
      displayName: jspb.Message.getFieldWithDefault(msg, 2, ""),
      personHash: jspb.Message.getFieldWithDefault(msg, 3, ""),
      checkedIn: (f = msg.getCheckedIn()) && common_date_pb.DateTime.toObject(includeInstance, f),
      checkedOut: (f = msg.getCheckedOut()) && common_date_pb.DateTime.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!EventTicketPerson}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new EventTicketPerson;
    return EventTicketPerson.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!EventTicketPerson} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!EventTicketPerson}
   */
  static deserializeBinaryFromReader(msg: EventTicketPerson, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new EventTicket;
        reader.readMessage(value1,EventTicket.deserializeBinaryFromReader);
        msg.setTicket(value1);
        break;
      case 2:
        var value2 = /** @type {string} */ (reader.readString());
        msg.setDisplayName(value2);
        break;
      case 3:
        var value3 = /** @type {string} */ (reader.readString());
        msg.setPersonHash(value3);
        break;
      case 4:
        var value4 = new common_date_pb.DateTime;
        reader.readMessage(value4,common_date_pb.DateTime.deserializeBinaryFromReader);
        msg.setCheckedIn(value4);
        break;
      case 5:
        var value5 = new common_date_pb.DateTime;
        reader.readMessage(value5,common_date_pb.DateTime.deserializeBinaryFromReader);
        msg.setCheckedOut(value5);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!EventTicketPerson} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: EventTicketPerson, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getTicket();
    if (f != null) {
      writer.writeMessage(
        1,
        f,
        EventTicket.serializeBinaryToWriter
      );
    }
    f = message.getDisplayName();
    if (f.length > 0) {
      writer.writeString(
        2,
        f
      );
    }
    f = message.getPersonHash();
    if (f.length > 0) {
      writer.writeString(
        3,
        f
      );
    }
    f = message.getCheckedIn();
    if (f != null) {
      writer.writeMessage(
        4,
        f,
        common_date_pb.DateTime.serializeBinaryToWriter
      );
    }
    f = message.getCheckedOut();
    if (f != null) {
      writer.writeMessage(
        5,
        f,
        common_date_pb.DateTime.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    EventTicketPerson.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional EventTicket ticket = 1;
   * @return {?EventTicket}
   */
  getTicket(): EventTicket {
    return /** @type{?EventTicket} */ (
      jspb.Message.getWrapperField(this, EventTicket, 1));
  }


  /** @param {?EventTicket|undefined} value */
  setTicket(value?: EventTicket) {
    jspb.Message.setWrapperField(this, 1, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearTicket() {
    this.setTicket(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasTicket(): boolean {
    return jspb.Message.getField(this, 1) != null;
  }


  /**
   * optional string display_name = 2;
   * @return {string}
   */
  getDisplayName(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
  };


  /** @param {string} value */
  setDisplayName(value: string) {
    jspb.Message.setProto3StringField(this, 2, value);
  }


  /**
   * optional string person_hash = 3;
   * @return {string}
   */
  getPersonHash(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
  };


  /** @param {string} value */
  setPersonHash(value: string) {
    jspb.Message.setProto3StringField(this, 3, value);
  }


  /**
   * optional minga.common.DateTime checked_in = 4;
   * @return {?DateTime}
   */
  getCheckedIn(): common_date_pb.DateTime {
    return /** @type{?DateTime} */ (
      jspb.Message.getWrapperField(this, common_date_pb.DateTime, 4));
  }


  /** @param {?DateTime|undefined} value */
  setCheckedIn(value?: common_date_pb.DateTime) {
    jspb.Message.setWrapperField(this, 4, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearCheckedIn() {
    this.setCheckedIn(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasCheckedIn(): boolean {
    return jspb.Message.getField(this, 4) != null;
  }


  /**
   * optional minga.common.DateTime checked_out = 5;
   * @return {?DateTime}
   */
  getCheckedOut(): common_date_pb.DateTime {
    return /** @type{?DateTime} */ (
      jspb.Message.getWrapperField(this, common_date_pb.DateTime, 5));
  }


  /** @param {?DateTime|undefined} value */
  setCheckedOut(value?: common_date_pb.DateTime) {
    jspb.Message.setWrapperField(this, 5, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearCheckedOut() {
    this.setCheckedOut(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasCheckedOut(): boolean {
    return jspb.Message.getField(this, 5) != null;
  }


}
export namespace EventTicket {
  export interface AsObject {
    personHash: string;
    studentNumber: string;
    firstName: string;
    lastName: string;
    email: string;
    guestsList: EventGuest.AsObject[];
  }
}
export class EventTicket extends jspb.Message {
  static readonly displayName = "EventTicket";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [7];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, EventTicket.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): EventTicket.AsObject {
    return EventTicket.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!EventTicket} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: EventTicket) {
    var f: any;
    var obj: any = {
      personHash: jspb.Message.getFieldWithDefault(msg, 1, ""),
      studentNumber: jspb.Message.getFieldWithDefault(msg, 2, ""),
      firstName: jspb.Message.getFieldWithDefault(msg, 3, ""),
      lastName: jspb.Message.getFieldWithDefault(msg, 4, ""),
      email: jspb.Message.getFieldWithDefault(msg, 5, ""),
      guestsList: jspb.Message.toObjectList(msg.getGuestsList(),
      EventGuest.toObject, includeInstance)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!EventTicket}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new EventTicket;
    return EventTicket.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!EventTicket} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!EventTicket}
   */
  static deserializeBinaryFromReader(msg: EventTicket, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setPersonHash(value1);
        break;
      case 2:
        var value2 = /** @type {string} */ (reader.readString());
        msg.setStudentNumber(value2);
        break;
      case 3:
        var value3 = /** @type {string} */ (reader.readString());
        msg.setFirstName(value3);
        break;
      case 4:
        var value4 = /** @type {string} */ (reader.readString());
        msg.setLastName(value4);
        break;
      case 5:
        var value5 = /** @type {string} */ (reader.readString());
        msg.setEmail(value5);
        break;
      case 7:
        var value7 = new EventGuest;
        reader.readMessage(value7,EventGuest.deserializeBinaryFromReader);
        msg.addGuests(value7);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!EventTicket} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: EventTicket, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getPersonHash();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
    f = message.getStudentNumber();
    if (f.length > 0) {
      writer.writeString(
        2,
        f
      );
    }
    f = message.getFirstName();
    if (f.length > 0) {
      writer.writeString(
        3,
        f
      );
    }
    f = message.getLastName();
    if (f.length > 0) {
      writer.writeString(
        4,
        f
      );
    }
    f = message.getEmail();
    if (f.length > 0) {
      writer.writeString(
        5,
        f
      );
    }
    f = message.getGuestsList();
    if (f.length > 0) {
      writer.writeRepeatedMessage(
        7,
        f,
        EventGuest.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    EventTicket.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string person_hash = 1;
   * @return {string}
   */
  getPersonHash(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setPersonHash(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


  /**
   * optional string student_number = 2;
   * @return {string}
   */
  getStudentNumber(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
  };


  /** @param {string} value */
  setStudentNumber(value: string) {
    jspb.Message.setProto3StringField(this, 2, value);
  }


  /**
   * optional string first_name = 3;
   * @return {string}
   */
  getFirstName(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
  };


  /** @param {string} value */
  setFirstName(value: string) {
    jspb.Message.setProto3StringField(this, 3, value);
  }


  /**
   * optional string last_name = 4;
   * @return {string}
   */
  getLastName(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
  };


  /** @param {string} value */
  setLastName(value: string) {
    jspb.Message.setProto3StringField(this, 4, value);
  }


  /**
   * optional string email = 5;
   * @return {string}
   */
  getEmail(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
  };


  /** @param {string} value */
  setEmail(value: string) {
    jspb.Message.setProto3StringField(this, 5, value);
  }


  /**
   * repeated EventGuest guests = 7;
   * @return {!Array<!EventGuest>}
   */
  getGuestsList(): EventGuest[] {
    return /** @type{!Array<!EventGuest>} */ (
      jspb.Message.getRepeatedWrapperField(this, EventGuest, 7));
  }


  /** @param {!Array<!EventGuest>} value */
  setGuestsList(value?: EventGuest[]) {
    jspb.Message.setRepeatedWrapperField(this, 7, value);
  }


  /**
   * @param {!EventGuest=} opt_value
   * @param {number=} opt_index
   * @return {!EventGuest}
   */
  addGuests(opt_value?: EventGuest, opt_index?: number): EventGuest {
    return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, EventGuest, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearGuestsList() {
    this.setGuestsList([]);
  }


}
export namespace EventGuest {
  export interface AsObject {
    guestName: string;
    checkedIn?: common_date_pb.DateTime.AsObject;
    checkedOut?: common_date_pb.DateTime.AsObject;
    guestId: number;
  }
}
export class EventGuest extends jspb.Message {
  static readonly displayName = "EventGuest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): EventGuest.AsObject {
    return EventGuest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!EventGuest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: EventGuest) {
    var f: any;
    var obj: any = {
      guestName: jspb.Message.getFieldWithDefault(msg, 1, ""),
      checkedIn: (f = msg.getCheckedIn()) && common_date_pb.DateTime.toObject(includeInstance, f),
      checkedOut: (f = msg.getCheckedOut()) && common_date_pb.DateTime.toObject(includeInstance, f),
      guestId: jspb.Message.getFieldWithDefault(msg, 4, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!EventGuest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new EventGuest;
    return EventGuest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!EventGuest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!EventGuest}
   */
  static deserializeBinaryFromReader(msg: EventGuest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setGuestName(value1);
        break;
      case 2:
        var value2 = new common_date_pb.DateTime;
        reader.readMessage(value2,common_date_pb.DateTime.deserializeBinaryFromReader);
        msg.setCheckedIn(value2);
        break;
      case 3:
        var value3 = new common_date_pb.DateTime;
        reader.readMessage(value3,common_date_pb.DateTime.deserializeBinaryFromReader);
        msg.setCheckedOut(value3);
        break;
      case 4:
        var value4 = /** @type {number} */ (reader.readInt32());
        msg.setGuestId(value4);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!EventGuest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: EventGuest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getGuestName();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
    f = message.getCheckedIn();
    if (f != null) {
      writer.writeMessage(
        2,
        f,
        common_date_pb.DateTime.serializeBinaryToWriter
      );
    }
    f = message.getCheckedOut();
    if (f != null) {
      writer.writeMessage(
        3,
        f,
        common_date_pb.DateTime.serializeBinaryToWriter
      );
    }
    f = message.getGuestId();
    if (f !== 0) {
      writer.writeInt32(
        4,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    EventGuest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string guest_name = 1;
   * @return {string}
   */
  getGuestName(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setGuestName(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


  /**
   * optional minga.common.DateTime checked_in = 2;
   * @return {?DateTime}
   */
  getCheckedIn(): common_date_pb.DateTime {
    return /** @type{?DateTime} */ (
      jspb.Message.getWrapperField(this, common_date_pb.DateTime, 2));
  }


  /** @param {?DateTime|undefined} value */
  setCheckedIn(value?: common_date_pb.DateTime) {
    jspb.Message.setWrapperField(this, 2, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearCheckedIn() {
    this.setCheckedIn(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasCheckedIn(): boolean {
    return jspb.Message.getField(this, 2) != null;
  }


  /**
   * optional minga.common.DateTime checked_out = 3;
   * @return {?DateTime}
   */
  getCheckedOut(): common_date_pb.DateTime {
    return /** @type{?DateTime} */ (
      jspb.Message.getWrapperField(this, common_date_pb.DateTime, 3));
  }


  /** @param {?DateTime|undefined} value */
  setCheckedOut(value?: common_date_pb.DateTime) {
    jspb.Message.setWrapperField(this, 3, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearCheckedOut() {
    this.setCheckedOut(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasCheckedOut(): boolean {
    return jspb.Message.getField(this, 3) != null;
  }


  /**
   * optional int32 guest_id = 4;
   * @return {number}
   */
  getGuestId(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
  };


  /** @param {number} value */
  setGuestId(value: number) {
    jspb.Message.setProto3IntField(this, 4, value);
  }


}
export namespace AddEventTicketsRequest {
  export interface AsObject {
    contextHash: string;
    ticketList: EventTicket.AsObject[];
  }
}
export class AddEventTicketsRequest extends jspb.Message {
  static readonly displayName = "AddEventTicketsRequest";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [2];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, AddEventTicketsRequest.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): AddEventTicketsRequest.AsObject {
    return AddEventTicketsRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!AddEventTicketsRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: AddEventTicketsRequest) {
    var f: any;
    var obj: any = {
      contextHash: jspb.Message.getFieldWithDefault(msg, 1, ""),
      ticketList: jspb.Message.toObjectList(msg.getTicketList(),
      EventTicket.toObject, includeInstance)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!AddEventTicketsRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new AddEventTicketsRequest;
    return AddEventTicketsRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!AddEventTicketsRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!AddEventTicketsRequest}
   */
  static deserializeBinaryFromReader(msg: AddEventTicketsRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setContextHash(value1);
        break;
      case 2:
        var value2 = new EventTicket;
        reader.readMessage(value2,EventTicket.deserializeBinaryFromReader);
        msg.addTicket(value2);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!AddEventTicketsRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: AddEventTicketsRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getContextHash();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
    f = message.getTicketList();
    if (f.length > 0) {
      writer.writeRepeatedMessage(
        2,
        f,
        EventTicket.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    AddEventTicketsRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string context_hash = 1;
   * @return {string}
   */
  getContextHash(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setContextHash(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


  /**
   * repeated EventTicket ticket = 2;
   * @return {!Array<!EventTicket>}
   */
  getTicketList(): EventTicket[] {
    return /** @type{!Array<!EventTicket>} */ (
      jspb.Message.getRepeatedWrapperField(this, EventTicket, 2));
  }


  /** @param {!Array<!EventTicket>} value */
  setTicketList(value?: EventTicket[]) {
    jspb.Message.setRepeatedWrapperField(this, 2, value);
  }


  /**
   * @param {!EventTicket=} opt_value
   * @param {number=} opt_index
   * @return {!EventTicket}
   */
  addTicket(opt_value?: EventTicket, opt_index?: number): EventTicket {
    return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, EventTicket, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearTicketList() {
    this.setTicketList([]);
  }


}
export namespace AddEventTicketsResponse {
  export interface AsObject {
    errorsList: AddEventTicketError.AsObject[];
    successMsg: string;
  }
}
export class AddEventTicketsResponse extends jspb.Message {
  static readonly displayName = "AddEventTicketsResponse";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [1];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, AddEventTicketsResponse.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): AddEventTicketsResponse.AsObject {
    return AddEventTicketsResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!AddEventTicketsResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: AddEventTicketsResponse) {
    var f: any;
    var obj: any = {
      errorsList: jspb.Message.toObjectList(msg.getErrorsList(),
      AddEventTicketError.toObject, includeInstance),
      successMsg: jspb.Message.getFieldWithDefault(msg, 2, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!AddEventTicketsResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new AddEventTicketsResponse;
    return AddEventTicketsResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!AddEventTicketsResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!AddEventTicketsResponse}
   */
  static deserializeBinaryFromReader(msg: AddEventTicketsResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new AddEventTicketError;
        reader.readMessage(value1,AddEventTicketError.deserializeBinaryFromReader);
        msg.addErrors(value1);
        break;
      case 2:
        var value2 = /** @type {string} */ (reader.readString());
        msg.setSuccessMsg(value2);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!AddEventTicketsResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: AddEventTicketsResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getErrorsList();
    if (f.length > 0) {
      writer.writeRepeatedMessage(
        1,
        f,
        AddEventTicketError.serializeBinaryToWriter
      );
    }
    f = message.getSuccessMsg();
    if (f.length > 0) {
      writer.writeString(
        2,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    AddEventTicketsResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * repeated AddEventTicketError errors = 1;
   * @return {!Array<!AddEventTicketError>}
   */
  getErrorsList(): AddEventTicketError[] {
    return /** @type{!Array<!AddEventTicketError>} */ (
      jspb.Message.getRepeatedWrapperField(this, AddEventTicketError, 1));
  }


  /** @param {!Array<!AddEventTicketError>} value */
  setErrorsList(value?: AddEventTicketError[]) {
    jspb.Message.setRepeatedWrapperField(this, 1, value);
  }


  /**
   * @param {!AddEventTicketError=} opt_value
   * @param {number=} opt_index
   * @return {!AddEventTicketError}
   */
  addErrors(opt_value?: AddEventTicketError, opt_index?: number): AddEventTicketError {
    return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, AddEventTicketError, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearErrorsList() {
    this.setErrorsList([]);
  }


  /**
   * optional string success_msg = 2;
   * @return {string}
   */
  getSuccessMsg(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
  };


  /** @param {string} value */
  setSuccessMsg(value: string) {
    jspb.Message.setProto3StringField(this, 2, value);
  }


}
export namespace AddEventTicketError {
  export interface AsObject {
    errorMsg: string;
    rowNumber: number;
  }
}
export class AddEventTicketError extends jspb.Message {
  static readonly displayName = "AddEventTicketError";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): AddEventTicketError.AsObject {
    return AddEventTicketError.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!AddEventTicketError} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: AddEventTicketError) {
    var f: any;
    var obj: any = {
      errorMsg: jspb.Message.getFieldWithDefault(msg, 1, ""),
      rowNumber: jspb.Message.getFieldWithDefault(msg, 2, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!AddEventTicketError}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new AddEventTicketError;
    return AddEventTicketError.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!AddEventTicketError} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!AddEventTicketError}
   */
  static deserializeBinaryFromReader(msg: AddEventTicketError, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setErrorMsg(value1);
        break;
      case 2:
        var value2 = /** @type {number} */ (reader.readInt32());
        msg.setRowNumber(value2);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!AddEventTicketError} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: AddEventTicketError, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getErrorMsg();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
    f = message.getRowNumber();
    if (f !== 0) {
      writer.writeInt32(
        2,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    AddEventTicketError.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string error_msg = 1;
   * @return {string}
   */
  getErrorMsg(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setErrorMsg(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


  /**
   * optional int32 row_number = 2;
   * @return {number}
   */
  getRowNumber(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
  };


  /** @param {number} value */
  setRowNumber(value: number) {
    jspb.Message.setProto3IntField(this, 2, value);
  }


}
export namespace EventTicketsPeopleRequest {
  export interface AsObject {
    contextHash: string;
  }
}
export class EventTicketsPeopleRequest extends jspb.Message {
  static readonly displayName = "EventTicketsPeopleRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): EventTicketsPeopleRequest.AsObject {
    return EventTicketsPeopleRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!EventTicketsPeopleRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: EventTicketsPeopleRequest) {
    var f: any;
    var obj: any = {
      contextHash: jspb.Message.getFieldWithDefault(msg, 1, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!EventTicketsPeopleRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new EventTicketsPeopleRequest;
    return EventTicketsPeopleRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!EventTicketsPeopleRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!EventTicketsPeopleRequest}
   */
  static deserializeBinaryFromReader(msg: EventTicketsPeopleRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setContextHash(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!EventTicketsPeopleRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: EventTicketsPeopleRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getContextHash();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    EventTicketsPeopleRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string context_hash = 1;
   * @return {string}
   */
  getContextHash(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setContextHash(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


}
export namespace EventTicketsPeopleResponse {
  export interface AsObject {
    personList: gateway_person_view_pb.PersonViewEvent.AsObject[];
  }
}
export class EventTicketsPeopleResponse extends jspb.Message {
  static readonly displayName = "EventTicketsPeopleResponse";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [1];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, EventTicketsPeopleResponse.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): EventTicketsPeopleResponse.AsObject {
    return EventTicketsPeopleResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!EventTicketsPeopleResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: EventTicketsPeopleResponse) {
    var f: any;
    var obj: any = {
      personList: jspb.Message.toObjectList(msg.getPersonList(),
      gateway_person_view_pb.PersonViewEvent.toObject, includeInstance)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!EventTicketsPeopleResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new EventTicketsPeopleResponse;
    return EventTicketsPeopleResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!EventTicketsPeopleResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!EventTicketsPeopleResponse}
   */
  static deserializeBinaryFromReader(msg: EventTicketsPeopleResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new gateway_person_view_pb.PersonViewEvent;
        reader.readMessage(value1,gateway_person_view_pb.PersonViewEvent.deserializeBinaryFromReader);
        msg.addPerson(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!EventTicketsPeopleResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: EventTicketsPeopleResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getPersonList();
    if (f.length > 0) {
      writer.writeRepeatedMessage(
        1,
        f,
        gateway_person_view_pb.PersonViewEvent.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    EventTicketsPeopleResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * repeated PersonViewEvent person = 1;
   * @return {!Array<!PersonViewEvent>}
   */
  getPersonList(): gateway_person_view_pb.PersonViewEvent[] {
    return /** @type{!Array<!PersonViewEvent>} */ (
      jspb.Message.getRepeatedWrapperField(this, gateway_person_view_pb.PersonViewEvent, 1));
  }


  /** @param {!Array<!PersonViewEvent>} value */
  setPersonList(value?: gateway_person_view_pb.PersonViewEvent[]) {
    jspb.Message.setRepeatedWrapperField(this, 1, value);
  }


  /**
   * @param {!PersonViewEvent=} opt_value
   * @param {number=} opt_index
   * @return {!PersonViewEvent}
   */
  addPerson(opt_value?: gateway_person_view_pb.PersonViewEvent, opt_index?: number): gateway_person_view_pb.PersonViewEvent {
    return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, gateway_person_view_pb.PersonViewEvent, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearPersonList() {
    this.setPersonList([]);
  }


}
export namespace ExportEventTicketsRequest {
  export interface AsObject {
    contextHash: string;
  }
}
export class ExportEventTicketsRequest extends jspb.Message {
  static readonly displayName = "ExportEventTicketsRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): ExportEventTicketsRequest.AsObject {
    return ExportEventTicketsRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!ExportEventTicketsRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: ExportEventTicketsRequest) {
    var f: any;
    var obj: any = {
      contextHash: jspb.Message.getFieldWithDefault(msg, 1, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!ExportEventTicketsRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new ExportEventTicketsRequest;
    return ExportEventTicketsRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!ExportEventTicketsRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!ExportEventTicketsRequest}
   */
  static deserializeBinaryFromReader(msg: ExportEventTicketsRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setContextHash(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!ExportEventTicketsRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: ExportEventTicketsRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getContextHash();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    ExportEventTicketsRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string context_hash = 1;
   * @return {string}
   */
  getContextHash(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setContextHash(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


}
export namespace ExportEventTicketsChunk {
  export interface AsObject {
    chunk?: (string|Uint8Array);
    filename: string;
  }
}
export class ExportEventTicketsChunk extends jspb.Message {
  static readonly displayName = "ExportEventTicketsChunk";
  /**
   * Oneof group definitions for this message. Each group defines the field
   * numbers belonging to that group. When of these fields' value is set, all
   * other fields in the group are cleared. During deserialization, if multiple
   * fields are encountered for a group, only the last value seen will be kept.
   * @private {!Array<!Array<number>>}
   * @const
   */
  static readonly oneofGroups_ = [[1,2]];


  /**
   * @return {ExportEventTicketsChunk.InfoCase}
   */
  getInfoCase() {
    return /** @type {ExportEventTicketsChunk.InfoCase} */(jspb.Message.computeOneofCase(this, ExportEventTicketsChunk.oneofGroups_[0]));
  }

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, ExportEventTicketsChunk.oneofGroups_);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): ExportEventTicketsChunk.AsObject {
    return ExportEventTicketsChunk.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!ExportEventTicketsChunk} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: ExportEventTicketsChunk) {
    var f: any;
    var obj: any = {
      chunk: msg.getChunk_asB64(),
      filename: jspb.Message.getFieldWithDefault(msg, 2, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!ExportEventTicketsChunk}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new ExportEventTicketsChunk;
    return ExportEventTicketsChunk.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!ExportEventTicketsChunk} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!ExportEventTicketsChunk}
   */
  static deserializeBinaryFromReader(msg: ExportEventTicketsChunk, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {!Uint8Array} */ (reader.readBytes());
        msg.setChunk(value1);
        break;
      case 2:
        var value2 = /** @type {string} */ (reader.readString());
        msg.setFilename(value2);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!ExportEventTicketsChunk} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: ExportEventTicketsChunk, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 1));
    if (f != null) {
      writer.writeBytes(
        1,
        f
      );
    }
    f = /** @type {string} */ (jspb.Message.getField(message, 2));
    if (f != null) {
      writer.writeString(
        2,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    ExportEventTicketsChunk.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional bytes chunk = 1;
   * @return {!(string|Uint8Array)}
   */
  getChunk(): (string|Uint8Array) {
    return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /**
   * optional bytes chunk = 1;
   * This is a type-conversion wrapper around `getChunk()`
   * @return {string}
   */
  getChunk_asB64() {
    return /** @type {string} */ (jspb.Message.bytesAsB64(
        this.getChunk()));
  }


  /**
   * optional bytes chunk = 1;
   * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getChunk()`
   * @return {!Uint8Array}
   */
  getChunk_asU8() {
    return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
        this.getChunk()));
  }


  /** @param {!(string|Uint8Array)} value */
  setChunk(value: (string|Uint8Array)) {
    jspb.Message.setOneofField(this, 1, ExportEventTicketsChunk.oneofGroups_[0], value);
  }


  /**
   * Clears the field making it undefined.
   */
  clearChunk() {
    jspb.Message.setOneofField(this, 1, ExportEventTicketsChunk.oneofGroups_[0], undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasChunk(): boolean {
    return jspb.Message.getField(this, 1) != null;
  }


  /**
   * optional string filename = 2;
   * @return {string}
   */
  getFilename(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
  };


  /** @param {string} value */
  setFilename(value: string) {
    jspb.Message.setOneofField(this, 2, ExportEventTicketsChunk.oneofGroups_[0], value);
  }


  /**
   * Clears the field making it undefined.
   */
  clearFilename() {
    jspb.Message.setOneofField(this, 2, ExportEventTicketsChunk.oneofGroups_[0], undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasFilename(): boolean {
    return jspb.Message.getField(this, 2) != null;
  }


}
export namespace ExportEventTicketsChunk {
export enum InfoCase {
  INFO_NOT_SET = 0,
  CHUNK = 1,
  FILENAME = 2,
}
} // namespace ExportEventTicketsChunk
export namespace RemovePeopleFromEventRequest {
  export interface AsObject {
    contextHash: string;
    personHashList: string[];
  }
}
export class RemovePeopleFromEventRequest extends jspb.Message {
  static readonly displayName = "RemovePeopleFromEventRequest";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [2];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, RemovePeopleFromEventRequest.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): RemovePeopleFromEventRequest.AsObject {
    return RemovePeopleFromEventRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!RemovePeopleFromEventRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: RemovePeopleFromEventRequest) {
    var f: any;
    var obj: any = {
      contextHash: jspb.Message.getFieldWithDefault(msg, 1, ""),
      personHashList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!RemovePeopleFromEventRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new RemovePeopleFromEventRequest;
    return RemovePeopleFromEventRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!RemovePeopleFromEventRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!RemovePeopleFromEventRequest}
   */
  static deserializeBinaryFromReader(msg: RemovePeopleFromEventRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setContextHash(value1);
        break;
      case 2:
        var value2 = /** @type {string} */ (reader.readString());
        msg.addPersonHash(value2);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!RemovePeopleFromEventRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: RemovePeopleFromEventRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getContextHash();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
    f = message.getPersonHashList();
    if (f.length > 0) {
      writer.writeRepeatedString(
        2,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    RemovePeopleFromEventRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string context_hash = 1;
   * @return {string}
   */
  getContextHash(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setContextHash(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


  /**
   * repeated string person_hash = 2;
   * @return {!Array<string>}
   */
  getPersonHashList(): string[] {
    return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
  };


  /** @param {!Array<string>} value */
  setPersonHashList(value: string[]) {
    jspb.Message.setField(this, 2, value || []);
  }


  /**
   * @param {string} value
   * @param {number=} opt_index
   */
  addPersonHash(value: string, opt_index?: number): void {
    jspb.Message.addToRepeatedField(this, 2, value, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearPersonHashList() {
    this.setPersonHashList([]);
  }


}
export namespace RemovePeopleFromEventResponse {
  export interface AsObject {
  }
}
export class RemovePeopleFromEventResponse extends jspb.Message {
  static readonly displayName = "RemovePeopleFromEventResponse";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): RemovePeopleFromEventResponse.AsObject {
    return RemovePeopleFromEventResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!RemovePeopleFromEventResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: RemovePeopleFromEventResponse) {
    var f: any;
    var obj: any = {

    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!RemovePeopleFromEventResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new RemovePeopleFromEventResponse;
    return RemovePeopleFromEventResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!RemovePeopleFromEventResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!RemovePeopleFromEventResponse}
   */
  static deserializeBinaryFromReader(msg: RemovePeopleFromEventResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!RemovePeopleFromEventResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: RemovePeopleFromEventResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    RemovePeopleFromEventResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
}
export namespace GetEventManagementInfoRequest {
  export interface AsObject {
    contextHash: string;
  }
}
export class GetEventManagementInfoRequest extends jspb.Message {
  static readonly displayName = "GetEventManagementInfoRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): GetEventManagementInfoRequest.AsObject {
    return GetEventManagementInfoRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!GetEventManagementInfoRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: GetEventManagementInfoRequest) {
    var f: any;
    var obj: any = {
      contextHash: jspb.Message.getFieldWithDefault(msg, 1, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!GetEventManagementInfoRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new GetEventManagementInfoRequest;
    return GetEventManagementInfoRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!GetEventManagementInfoRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!GetEventManagementInfoRequest}
   */
  static deserializeBinaryFromReader(msg: GetEventManagementInfoRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setContextHash(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!GetEventManagementInfoRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: GetEventManagementInfoRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getContextHash();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    GetEventManagementInfoRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string context_hash = 1;
   * @return {string}
   */
  getContextHash(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setContextHash(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


}
export namespace GetEventManagementInfoResponse {
  export interface AsObject {
    inviteCount: number;
    goingCount: number;
    ticketCount: number;
    checkinCount: number;
    checkoutCount: number;
    managerCount: number;
    responsesCount: number;
    notCheckedInCount: number;
  }
}
export class GetEventManagementInfoResponse extends jspb.Message {
  static readonly displayName = "GetEventManagementInfoResponse";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): GetEventManagementInfoResponse.AsObject {
    return GetEventManagementInfoResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!GetEventManagementInfoResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: GetEventManagementInfoResponse) {
    var f: any;
    var obj: any = {
      inviteCount: jspb.Message.getFieldWithDefault(msg, 1, 0),
      goingCount: jspb.Message.getFieldWithDefault(msg, 2, 0),
      ticketCount: jspb.Message.getFieldWithDefault(msg, 3, 0),
      checkinCount: jspb.Message.getFieldWithDefault(msg, 4, 0),
      checkoutCount: jspb.Message.getFieldWithDefault(msg, 5, 0),
      managerCount: jspb.Message.getFieldWithDefault(msg, 6, 0),
      responsesCount: jspb.Message.getFieldWithDefault(msg, 7, 0),
      notCheckedInCount: jspb.Message.getFieldWithDefault(msg, 8, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!GetEventManagementInfoResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new GetEventManagementInfoResponse;
    return GetEventManagementInfoResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!GetEventManagementInfoResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!GetEventManagementInfoResponse}
   */
  static deserializeBinaryFromReader(msg: GetEventManagementInfoResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {number} */ (reader.readInt32());
        msg.setInviteCount(value1);
        break;
      case 2:
        var value2 = /** @type {number} */ (reader.readInt32());
        msg.setGoingCount(value2);
        break;
      case 3:
        var value3 = /** @type {number} */ (reader.readInt32());
        msg.setTicketCount(value3);
        break;
      case 4:
        var value4 = /** @type {number} */ (reader.readInt32());
        msg.setCheckinCount(value4);
        break;
      case 5:
        var value5 = /** @type {number} */ (reader.readInt32());
        msg.setCheckoutCount(value5);
        break;
      case 6:
        var value6 = /** @type {number} */ (reader.readInt32());
        msg.setManagerCount(value6);
        break;
      case 7:
        var value7 = /** @type {number} */ (reader.readInt32());
        msg.setResponsesCount(value7);
        break;
      case 8:
        var value8 = /** @type {number} */ (reader.readInt32());
        msg.setNotCheckedInCount(value8);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!GetEventManagementInfoResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: GetEventManagementInfoResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getInviteCount();
    if (f !== 0) {
      writer.writeInt32(
        1,
        f
      );
    }
    f = message.getGoingCount();
    if (f !== 0) {
      writer.writeInt32(
        2,
        f
      );
    }
    f = message.getTicketCount();
    if (f !== 0) {
      writer.writeInt32(
        3,
        f
      );
    }
    f = message.getCheckinCount();
    if (f !== 0) {
      writer.writeInt32(
        4,
        f
      );
    }
    f = message.getCheckoutCount();
    if (f !== 0) {
      writer.writeInt32(
        5,
        f
      );
    }
    f = message.getManagerCount();
    if (f !== 0) {
      writer.writeInt32(
        6,
        f
      );
    }
    f = message.getResponsesCount();
    if (f !== 0) {
      writer.writeInt32(
        7,
        f
      );
    }
    f = message.getNotCheckedInCount();
    if (f !== 0) {
      writer.writeInt32(
        8,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    GetEventManagementInfoResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional int32 invite_count = 1;
   * @return {number}
   */
  getInviteCount(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
  };


  /** @param {number} value */
  setInviteCount(value: number) {
    jspb.Message.setProto3IntField(this, 1, value);
  }


  /**
   * optional int32 going_count = 2;
   * @return {number}
   */
  getGoingCount(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
  };


  /** @param {number} value */
  setGoingCount(value: number) {
    jspb.Message.setProto3IntField(this, 2, value);
  }


  /**
   * optional int32 ticket_count = 3;
   * @return {number}
   */
  getTicketCount(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
  };


  /** @param {number} value */
  setTicketCount(value: number) {
    jspb.Message.setProto3IntField(this, 3, value);
  }


  /**
   * optional int32 checkin_count = 4;
   * @return {number}
   */
  getCheckinCount(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
  };


  /** @param {number} value */
  setCheckinCount(value: number) {
    jspb.Message.setProto3IntField(this, 4, value);
  }


  /**
   * optional int32 checkout_count = 5;
   * @return {number}
   */
  getCheckoutCount(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
  };


  /** @param {number} value */
  setCheckoutCount(value: number) {
    jspb.Message.setProto3IntField(this, 5, value);
  }


  /**
   * optional int32 manager_count = 6;
   * @return {number}
   */
  getManagerCount(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
  };


  /** @param {number} value */
  setManagerCount(value: number) {
    jspb.Message.setProto3IntField(this, 6, value);
  }


  /**
   * optional int32 responses_count = 7;
   * @return {number}
   */
  getResponsesCount(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
  };


  /** @param {number} value */
  setResponsesCount(value: number) {
    jspb.Message.setProto3IntField(this, 7, value);
  }


  /**
   * optional int32 not_checked_in_count = 8;
   * @return {number}
   */
  getNotCheckedInCount(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
  };


  /** @param {number} value */
  setNotCheckedInCount(value: number) {
    jspb.Message.setProto3IntField(this, 8, value);
  }


}
export namespace GetEventGoingPeopleRequest {
  export interface AsObject {
    contextHash: string;
    checkinStatus: string;
    count: number;
    offset: number;
  }
}
export class GetEventGoingPeopleRequest extends jspb.Message {
  static readonly displayName = "GetEventGoingPeopleRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): GetEventGoingPeopleRequest.AsObject {
    return GetEventGoingPeopleRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!GetEventGoingPeopleRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: GetEventGoingPeopleRequest) {
    var f: any;
    var obj: any = {
      contextHash: jspb.Message.getFieldWithDefault(msg, 1, ""),
      checkinStatus: jspb.Message.getFieldWithDefault(msg, 2, ""),
      count: jspb.Message.getFieldWithDefault(msg, 3, 0),
      offset: jspb.Message.getFieldWithDefault(msg, 4, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!GetEventGoingPeopleRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new GetEventGoingPeopleRequest;
    return GetEventGoingPeopleRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!GetEventGoingPeopleRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!GetEventGoingPeopleRequest}
   */
  static deserializeBinaryFromReader(msg: GetEventGoingPeopleRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setContextHash(value1);
        break;
      case 2:
        var value2 = /** @type {string} */ (reader.readString());
        msg.setCheckinStatus(value2);
        break;
      case 3:
        var value3 = /** @type {number} */ (reader.readInt32());
        msg.setCount(value3);
        break;
      case 4:
        var value4 = /** @type {number} */ (reader.readInt32());
        msg.setOffset(value4);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!GetEventGoingPeopleRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: GetEventGoingPeopleRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getContextHash();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
    f = message.getCheckinStatus();
    if (f.length > 0) {
      writer.writeString(
        2,
        f
      );
    }
    f = message.getCount();
    if (f !== 0) {
      writer.writeInt32(
        3,
        f
      );
    }
    f = message.getOffset();
    if (f !== 0) {
      writer.writeInt32(
        4,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    GetEventGoingPeopleRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string context_hash = 1;
   * @return {string}
   */
  getContextHash(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setContextHash(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


  /**
   * optional string checkin_status = 2;
   * @return {string}
   */
  getCheckinStatus(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
  };


  /** @param {string} value */
  setCheckinStatus(value: string) {
    jspb.Message.setProto3StringField(this, 2, value);
  }


  /**
   * optional int32 count = 3;
   * @return {number}
   */
  getCount(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
  };


  /** @param {number} value */
  setCount(value: number) {
    jspb.Message.setProto3IntField(this, 3, value);
  }


  /**
   * optional int32 offset = 4;
   * @return {number}
   */
  getOffset(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
  };


  /** @param {number} value */
  setOffset(value: number) {
    jspb.Message.setProto3IntField(this, 4, value);
  }


}
export namespace GetEventGoingPeopleResponse {
  export interface AsObject {
    personList: gateway_person_view_pb.PersonViewEvent.AsObject[];
  }
}
export class GetEventGoingPeopleResponse extends jspb.Message {
  static readonly displayName = "GetEventGoingPeopleResponse";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [1];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, GetEventGoingPeopleResponse.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): GetEventGoingPeopleResponse.AsObject {
    return GetEventGoingPeopleResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!GetEventGoingPeopleResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: GetEventGoingPeopleResponse) {
    var f: any;
    var obj: any = {
      personList: jspb.Message.toObjectList(msg.getPersonList(),
      gateway_person_view_pb.PersonViewEvent.toObject, includeInstance)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!GetEventGoingPeopleResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new GetEventGoingPeopleResponse;
    return GetEventGoingPeopleResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!GetEventGoingPeopleResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!GetEventGoingPeopleResponse}
   */
  static deserializeBinaryFromReader(msg: GetEventGoingPeopleResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new gateway_person_view_pb.PersonViewEvent;
        reader.readMessage(value1,gateway_person_view_pb.PersonViewEvent.deserializeBinaryFromReader);
        msg.addPerson(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!GetEventGoingPeopleResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: GetEventGoingPeopleResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getPersonList();
    if (f.length > 0) {
      writer.writeRepeatedMessage(
        1,
        f,
        gateway_person_view_pb.PersonViewEvent.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    GetEventGoingPeopleResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * repeated PersonViewEvent person = 1;
   * @return {!Array<!PersonViewEvent>}
   */
  getPersonList(): gateway_person_view_pb.PersonViewEvent[] {
    return /** @type{!Array<!PersonViewEvent>} */ (
      jspb.Message.getRepeatedWrapperField(this, gateway_person_view_pb.PersonViewEvent, 1));
  }


  /** @param {!Array<!PersonViewEvent>} value */
  setPersonList(value?: gateway_person_view_pb.PersonViewEvent[]) {
    jspb.Message.setRepeatedWrapperField(this, 1, value);
  }


  /**
   * @param {!PersonViewEvent=} opt_value
   * @param {number=} opt_index
   * @return {!PersonViewEvent}
   */
  addPerson(opt_value?: gateway_person_view_pb.PersonViewEvent, opt_index?: number): gateway_person_view_pb.PersonViewEvent {
    return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, gateway_person_view_pb.PersonViewEvent, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearPersonList() {
    this.setPersonList([]);
  }


}
