import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatRippleModule } from '@angular/material/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { RouterModule } from '@angular/router';

import { MgButtonModule } from '@app/src/app/button';
import { MgEmptyStateModule } from '@app/src/app/components/EmptyState';
import { MgImageModule } from '@app/src/app/components/MgImage';
import { MgFormFieldModule } from '@app/src/app/form-field';
import { MgIconModule } from '@app/src/app/icon';
import { InputsModule } from '@app/src/app/inputs';
import { MgPipesModule } from '@app/src/app/pipes';
import { MgSpinnerModule } from '@app/src/app/spinner';

import { AutomationCounterResetModule } from '@shared/components/automation-counter-reset/automation-counter-reset.module';
import { GenericButtonModule } from '@shared/components/button';
import { FormModule } from '@shared/components/form';
import { FormRestrictionModule } from '@shared/components/form-restriction-input/form-restriction-input.module';
import { GenericModule } from '@shared/components/generic';
import { ManagerOverlayModule } from '@shared/components/manager-overlay';
import { MembershipListTableModule } from '@shared/components/membership-list-table';
import { OverlayModule } from '@shared/components/modal-overlay';
import { SystemAlertModule } from '@shared/components/system-alert';
import { TextModule } from '@shared/components/text';
import { TooltipModule } from '@shared/components/tooltip';
import { ScrollIntoViewModule } from '@shared/directives/scroll-into-view/scroll-into-view.module';
import {
  AutomationActionsService,
  AutomationGroupService,
} from '@shared/services/automation';

import { BmTypesComponent } from './bm-types.component';
import { BmTypesAutomationResetComponent } from './components/bm-types-automation-reset/bm-types-automation-reset.component';
import { BmTypesAutomationStepEditComponent } from './components/bm-types-automation-step-edit/bm-types-automation-step-edit.component';
import { BmTypesAutomationStepComponent } from './components/bm-types-automation-step/bm-types-automation-step.component';
import { BmTypesAutomationsEditGroupComponent } from './components/bm-types-automations-edit-group/bm-types-automations-edit-group.component';
import { BmTypesAutomationsComponent } from './components/bm-types-automations/bm-types-automations.component';
import { BmTypesBehaviorEditComponent } from './components/bm-types-behavior-edit/bm-types-behavior-edit.component';
import { BmTypesBehaviorsComponent } from './components/bm-types-behaviors/bm-types-behaviors.component';
import { BmTypesConsequenceEditComponent } from './components/bm-types-consequence-edit/bm-types-consequence-edit.component';
import { BmTypesConsequencesComponent } from './components/bm-types-consequences/bm-types-consequences.component';
import { BmTypesListComponent } from './components/bm-types-list/bm-types-list.component';
import { GetAutomationGroupTriggersPipe } from './pipes/getAutomationGroupTriggers.pipe';
import { GetConsequenceTypeFromIdPipe } from './pipes/getConsequenceTypeFromId.pipe';
import { GetDueDateActiveFromIdPipe } from './pipes/getDueDateActiveFromId.pipe';
import {
  BmTypesAutomationService,
  BmTypesBehaviorService,
  BmTypesConsequenceService,
} from './services';

@NgModule({
  imports: [
    // Minga dependencies
    MgSpinnerModule,
    MgButtonModule,
    MgIconModule,
    MgEmptyStateModule,
    GenericButtonModule,
    ManagerOverlayModule,
    OverlayModule,
    FormModule,
    TooltipModule,
    MgImageModule,
    MgPipesModule,
    MgFormFieldModule,
    OverlayModule,
    FormModule,
    MembershipListTableModule,
    InputsModule,
    TextModule,
    GenericModule,
    MatExpansionModule,
    MatRippleModule,
    SystemAlertModule,
    RouterModule,
    FormRestrictionModule,
    ScrollIntoViewModule,
    AutomationCounterResetModule,

    // External dependencies
    CommonModule,
    FlexLayoutModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatTableModule,
    MatSortModule,
    MatButtonToggleModule,
    ReactiveFormsModule,
    MatSlideToggleModule,
    MatMenuModule,
    MatRadioModule,
  ],
  declarations: [
    BmTypesComponent,
    BmTypesBehaviorsComponent,
    BmTypesConsequencesComponent,
    BmTypesAutomationsComponent,
    BmTypesAutomationResetComponent,
    BmTypesListComponent,
    BmTypesAutomationsEditGroupComponent,
    BmTypesAutomationStepComponent,
    BmTypesAutomationStepEditComponent,
    BmTypesBehaviorEditComponent,
    BmTypesConsequenceEditComponent,
    // Pipes
    GetAutomationGroupTriggersPipe,
    GetConsequenceTypeFromIdPipe,
    GetDueDateActiveFromIdPipe,
  ],
  exports: [BmTypesComponent],
  providers: [
    BmTypesBehaviorService,
    BmTypesConsequenceService,
    BmTypesAutomationService,
    AutomationActionsService,
    AutomationGroupService,
  ],
})
export class BmTypesModule {}
