import { MingaPermission, MingaRoleType } from 'libs/domain';
import { DisplayNameFormat } from 'libs/util';

import { RoleCategory, UserRole } from './UserRole';

export class ParentRole extends UserRole {
  readonly roleType = MingaRoleType.PARENT;
  readonly name = 'Parent';
  readonly displayNameFormat = DisplayNameFormat.FIRST_LAST;
  readonly iconUrl = 'assets/roles/parent.svg';
  readonly iconColor = '#C55683';
  readonly capabilitiesDescription = `Parents will be allowed to share posts, pictures, and videos`;
  readonly feedPermittable = true;
  readonly galleryPermittable = true;
  readonly groupFeedPermittable = true;
  readonly programManagePermittable = false;
  readonly videoUploadPermittable = true;
  readonly groupCreatePermittable = true;
  readonly admin = false;
  readonly commentPermittable = true;
  readonly requiredFeatures = [];
  readonly sendToMailChimp = false;
  readonly IDRole = RoleCategory.PARENT;

  readonly permissions = [
    MingaPermission.ASSET_UPLOAD,
    MingaPermission.ACCOUNT_PROFILE_CHANGE,
    MingaPermission.CONTENT_COMMENTS_VIEW,
    MingaPermission.CONTENT_LIKES_LIKE,
    MingaPermission.CONTENT_GROUP_ENABLED,
    MingaPermission.GROUP_CONTENT_POST_CREATE,
    MingaPermission.GROUP_CONTENT_VIDEO_CREATE,
    MingaPermission.CONTENT_POST_CREATE,
    MingaPermission.CONTENT_VIDEO_CREATE,
    MingaPermission.CONTENT_CREATED_MINGA_DESIGNER_CONTENT,
    MingaPermission.IS_PARENT,
    MingaPermission.CONTENT_COMMENT_CREATE,
  ];
  readonly overridablePermissions = [];
}
