import { IDefaultPbisType } from 'libs/domain';

export const DefaultPbisTypes: IDefaultPbisType[] = [
  {
    name: 'Kindness',
    categoryId: 0,
    points: 1,
    active: true,
  },
  {
    name: 'Effort',
    categoryId: 0,
    points: 1,
    active: true,
  },
  {
    name: 'Helpful',
    categoryId: 0,
    points: 1,
    active: true,
  },
  {
    name: 'Above & Beyond',
    categoryId: 0,
    points: 1,
    active: true,
  },
  {
    name: 'Excellence',
    categoryId: 0,
    points: 1,
    active: true,
  },
  {
    name: 'Tardy',
    categoryId: 1,
    points: 0,
    active: true,
  },
  {
    name: 'Language',
    categoryId: 1,
    points: 0,
    active: true,
  },
  {
    name: 'Violence',
    categoryId: 1,
    points: 0,
    active: true,
  },
  {
    name: 'Substance',
    categoryId: 1,
    points: 0,
    active: true,
  },
  {
    name: 'Cell Phone',
    categoryId: 1,
    points: 0,
    active: true,
  },
  {
    name: 'No Hall Pass',
    categoryId: 1,
    points: 0,
    active: true,
  },
];

export enum DefaultPbisTypesEnum {
  KINDNESS = 'Kindness',
  EFFORT = 'Effort',
  HELPFUL = 'Helpful',
  ABOVE_AND_BEYOND = 'Above & Beyond',
  EXCELLENCE = 'Excellence',
  TARDY = 'Tardy',
  LANGUAGE = 'Language',
  VIOLENCE = 'Violence',
  SUBSTANCE = 'Substance',
  CELL_PHONE = 'Cell Phone',
  NO_HALL_PASS = 'No Hall Pass',
}

export enum DefaultBehaviorEmailTemplate {
  SUBJECT = 'NO REPLY: ${firstName} ${lastName} has been assigned a behavior by ${mingaName}',
  BODY = 'Hello, your child ${firstName} ${lastName} has been assigned ${typeName} at ${mingaName}',
}

export enum DefaultConsequenceEmailTemplate {
  SUBJECT = 'NO REPLY: ${firstName} ${lastName} has been assigned a guidance consequence by ${mingaName}',
  BODY = 'Hello, your child ${firstName} ${lastName} has been assigned ${typeName} at ${mingaName}',
}

export const DEFAULT_BEHAVIOR_SMS_TEMPLATE =
  'Hello, your child ${firstName} ${lastName} has been assigned ${typeName} at ${mingaName}.';

export const DEFAULT_CONSEQUENCE_SMS_TEMPLATE =
  'Hello, your child ${firstName} ${lastName} has been assigned ${typeName} at ${mingaName}.';
