import { ChangeDetectionStrategy, Component } from '@angular/core';

import { FtmReportsStudentsDatasourceService } from '../../services';

@Component({
  selector: 'mg-ftm-reports-students',
  templateUrl: './ftm-reports-students.component.html',
  styleUrls: ['./ftm-reports-students.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [FtmReportsStudentsDatasourceService],
})
export class FtmReportsStudentsComponent {
  constructor(public ds: FtmReportsStudentsDatasourceService) {}
}
