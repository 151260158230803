import { date_pb } from 'libs/generated-grpc-web';

export function dateObjectToDateTimeMessage(dateObj: Date): date_pb.DateTime {
  const msg = new date_pb.DateTime();
  const seconds =
    dateObj.getHours() * 3600 +
    dateObj.getMinutes() * 60 +
    dateObj.getSeconds();

  msg.setYear(dateObj.getFullYear());
  msg.setDay(dateObj.getDate());
  msg.setMonth(dateObj.getMonth() + 1);
  msg.setSeconds(seconds + 1);

  return msg;
}

export function dateTimeObjectToDateTimeMessage(
  dateObj: Date,
): date_pb.DateTime {
  const dateTime = new date_pb.DateTime();
  const seconds =
    dateObj.getHours() * 3600 +
    dateObj.getMinutes() * 60 +
    dateObj.getSeconds();

  dateTime.setYear(dateObj.getFullYear());
  dateTime.setDay(dateObj.getDate());
  dateTime.setMonth(dateObj.getMonth() + 1);
  dateTime.setSeconds(seconds + 1);

  return dateTime;
}

export function dateObjectToDateMessage(dateObj: Date): date_pb.Date {
  const msg = new date_pb.Date();

  msg.setYear(dateObj.getFullYear());
  msg.setDay(dateObj.getDate());
  msg.setMonth(dateObj.getMonth() + 1);

  return msg;
}

export function dateObjectToDateMessageUTC(dateObj: Date): date_pb.Date {
  const msg = new date_pb.Date();

  msg.setYear(dateObj.getUTCFullYear());
  msg.setDay(dateObj.getUTCDate());
  msg.setMonth(dateObj.getUTCMonth() + 1);

  return msg;
}

export function dateTimeMessageToDateObject(dateTime: date_pb.DateTime) {
  const date = new Date(
    dateTime.getYear(),
    dateTime.getMonth() - 1,
    dateTime.getDay(),
  );

  const seconds = dateTime.getSeconds();

  return new Date(date.getTime() + (seconds + 1) * 1000);
}

export function dateMessageToDateObject(dateMessage: date_pb.Date) {
  const date = new Date(
    dateMessage.getYear(),
    dateMessage.getMonth() - 1,
    dateMessage.getDay(),
  );

  return date;
}

export function dateMessageObjectToDateObject(
  dateMessageObj: date_pb.Date.AsObject,
) {
  const date = new Date(
    dateMessageObj.year,
    dateMessageObj.month - 1,
    dateMessageObj.day,
    23,
    59,
  );

  return date;
}
