import { Injectable, NgZone } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { grpc } from '@improbable-eng/grpc-web';

import {
  CreateAccountRequest,
  CreateAccountResponse,
  DeviceLoginRequest,
  EmailRequest,
  EmailResponse,
  ForgotKioskPinRequest,
  ForgotKioskPinResponse,
  ForgotPasswordRequest,
  ForgotPasswordResponse,
  KioskLoginRequest,
  KioskPinRequest,
  KioskPinResponse,
  LoginRequest,
  LoginResponse,
  LoginValidationRequest,
  LoginValidationResponse,
  LogoutRequest,
  LogoutResponse,
  OnStartupRequest,
  OnStartupResponse,
  PinRequest,
  PinResponse,
  ResetPasswordRequest,
  ResetPasswordResponse,
  RetrievePinRequest,
  RetrievePinResponse,
  ValidateKioskPinRequest,
  ValidateKioskPinResponse,
} from '../gateway/login_pb';

import {
  LoginManager as __LoginManager,
} from '../gateway/login_pb_service';

/**
  * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
  LoginManager service (all rpc calls available for login)
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *  */
@Injectable({providedIn: 'root'})
export class LoginManager {

  constructor(private _ngZone: NgZone) {
  }

  login(request: LoginRequest): Promise<LoginResponse>;
  login(request: LoginRequest, metadata: grpc.Metadata): Promise<LoginResponse>;
  login(request: LoginRequest, callback: (err: any|null, response: LoginResponse, metadata: grpc.Metadata) => void): void;
  login(request: LoginRequest, metadata: grpc.Metadata, callback: (err: any|null, response: LoginResponse, metadata: grpc.Metadata) => void): void;

  login(request: LoginRequest, arg1?: grpc.Metadata|((err: any|null, response: LoginResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: LoginResponse, metadata: grpc.Metadata) => void): Promise<LoginResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<LoginResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__LoginManager.Login, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  kioskLogin(request: KioskLoginRequest): Promise<LoginResponse>;
  kioskLogin(request: KioskLoginRequest, metadata: grpc.Metadata): Promise<LoginResponse>;
  kioskLogin(request: KioskLoginRequest, callback: (err: any|null, response: LoginResponse, metadata: grpc.Metadata) => void): void;
  kioskLogin(request: KioskLoginRequest, metadata: grpc.Metadata, callback: (err: any|null, response: LoginResponse, metadata: grpc.Metadata) => void): void;

  kioskLogin(request: KioskLoginRequest, arg1?: grpc.Metadata|((err: any|null, response: LoginResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: LoginResponse, metadata: grpc.Metadata) => void): Promise<LoginResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<LoginResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__LoginManager.KioskLogin, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  deviceLogin(request: DeviceLoginRequest): Promise<LoginResponse>;
  deviceLogin(request: DeviceLoginRequest, metadata: grpc.Metadata): Promise<LoginResponse>;
  deviceLogin(request: DeviceLoginRequest, callback: (err: any|null, response: LoginResponse, metadata: grpc.Metadata) => void): void;
  deviceLogin(request: DeviceLoginRequest, metadata: grpc.Metadata, callback: (err: any|null, response: LoginResponse, metadata: grpc.Metadata) => void): void;

  deviceLogin(request: DeviceLoginRequest, arg1?: grpc.Metadata|((err: any|null, response: LoginResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: LoginResponse, metadata: grpc.Metadata) => void): Promise<LoginResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<LoginResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__LoginManager.DeviceLogin, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  validate(request: LoginValidationRequest): Promise<LoginValidationResponse>;
  validate(request: LoginValidationRequest, metadata: grpc.Metadata): Promise<LoginValidationResponse>;
  validate(request: LoginValidationRequest, callback: (err: any|null, response: LoginValidationResponse, metadata: grpc.Metadata) => void): void;
  validate(request: LoginValidationRequest, metadata: grpc.Metadata, callback: (err: any|null, response: LoginValidationResponse, metadata: grpc.Metadata) => void): void;

  validate(request: LoginValidationRequest, arg1?: grpc.Metadata|((err: any|null, response: LoginValidationResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: LoginValidationResponse, metadata: grpc.Metadata) => void): Promise<LoginValidationResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<LoginValidationResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__LoginManager.Validate, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  email(request: EmailRequest): Promise<EmailResponse>;
  email(request: EmailRequest, metadata: grpc.Metadata): Promise<EmailResponse>;
  email(request: EmailRequest, callback: (err: any|null, response: EmailResponse, metadata: grpc.Metadata) => void): void;
  email(request: EmailRequest, metadata: grpc.Metadata, callback: (err: any|null, response: EmailResponse, metadata: grpc.Metadata) => void): void;

  email(request: EmailRequest, arg1?: grpc.Metadata|((err: any|null, response: EmailResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: EmailResponse, metadata: grpc.Metadata) => void): Promise<EmailResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<EmailResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__LoginManager.Email, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  pin(request: PinRequest): Promise<PinResponse>;
  pin(request: PinRequest, metadata: grpc.Metadata): Promise<PinResponse>;
  pin(request: PinRequest, callback: (err: any|null, response: PinResponse, metadata: grpc.Metadata) => void): void;
  pin(request: PinRequest, metadata: grpc.Metadata, callback: (err: any|null, response: PinResponse, metadata: grpc.Metadata) => void): void;

  pin(request: PinRequest, arg1?: grpc.Metadata|((err: any|null, response: PinResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: PinResponse, metadata: grpc.Metadata) => void): Promise<PinResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<PinResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__LoginManager.Pin, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  createAccount(request: CreateAccountRequest): Promise<CreateAccountResponse>;
  createAccount(request: CreateAccountRequest, metadata: grpc.Metadata): Promise<CreateAccountResponse>;
  createAccount(request: CreateAccountRequest, callback: (err: any|null, response: CreateAccountResponse, metadata: grpc.Metadata) => void): void;
  createAccount(request: CreateAccountRequest, metadata: grpc.Metadata, callback: (err: any|null, response: CreateAccountResponse, metadata: grpc.Metadata) => void): void;

  createAccount(request: CreateAccountRequest, arg1?: grpc.Metadata|((err: any|null, response: CreateAccountResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: CreateAccountResponse, metadata: grpc.Metadata) => void): Promise<CreateAccountResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<CreateAccountResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__LoginManager.CreateAccount, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  logout(request: LogoutRequest): Promise<LogoutResponse>;
  logout(request: LogoutRequest, metadata: grpc.Metadata): Promise<LogoutResponse>;
  logout(request: LogoutRequest, callback: (err: any|null, response: LogoutResponse, metadata: grpc.Metadata) => void): void;
  logout(request: LogoutRequest, metadata: grpc.Metadata, callback: (err: any|null, response: LogoutResponse, metadata: grpc.Metadata) => void): void;

  logout(request: LogoutRequest, arg1?: grpc.Metadata|((err: any|null, response: LogoutResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: LogoutResponse, metadata: grpc.Metadata) => void): Promise<LogoutResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<LogoutResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__LoginManager.Logout, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  forgotPassword(request: ForgotPasswordRequest): Promise<ForgotPasswordResponse>;
  forgotPassword(request: ForgotPasswordRequest, metadata: grpc.Metadata): Promise<ForgotPasswordResponse>;
  forgotPassword(request: ForgotPasswordRequest, callback: (err: any|null, response: ForgotPasswordResponse, metadata: grpc.Metadata) => void): void;
  forgotPassword(request: ForgotPasswordRequest, metadata: grpc.Metadata, callback: (err: any|null, response: ForgotPasswordResponse, metadata: grpc.Metadata) => void): void;

  forgotPassword(request: ForgotPasswordRequest, arg1?: grpc.Metadata|((err: any|null, response: ForgotPasswordResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: ForgotPasswordResponse, metadata: grpc.Metadata) => void): Promise<ForgotPasswordResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<ForgotPasswordResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__LoginManager.ForgotPassword, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  resetPassword(request: ResetPasswordRequest): Promise<ResetPasswordResponse>;
  resetPassword(request: ResetPasswordRequest, metadata: grpc.Metadata): Promise<ResetPasswordResponse>;
  resetPassword(request: ResetPasswordRequest, callback: (err: any|null, response: ResetPasswordResponse, metadata: grpc.Metadata) => void): void;
  resetPassword(request: ResetPasswordRequest, metadata: grpc.Metadata, callback: (err: any|null, response: ResetPasswordResponse, metadata: grpc.Metadata) => void): void;

  resetPassword(request: ResetPasswordRequest, arg1?: grpc.Metadata|((err: any|null, response: ResetPasswordResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: ResetPasswordResponse, metadata: grpc.Metadata) => void): Promise<ResetPasswordResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<ResetPasswordResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__LoginManager.ResetPassword, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  /**
    Retrieves a pin for an account that matches the requested details. If the
 account doesn't already exist a new one will be created.
  */
  retrievePin(request: RetrievePinRequest): Promise<RetrievePinResponse>;
  /**
    Retrieves a pin for an account that matches the requested details. If the
 account doesn't already exist a new one will be created.
  */
  retrievePin(request: RetrievePinRequest, metadata: grpc.Metadata): Promise<RetrievePinResponse>;
  /**
    Retrieves a pin for an account that matches the requested details. If the
 account doesn't already exist a new one will be created.
  */
  retrievePin(request: RetrievePinRequest, callback: (err: any|null, response: RetrievePinResponse, metadata: grpc.Metadata) => void): void;
  /**
    Retrieves a pin for an account that matches the requested details. If the
 account doesn't already exist a new one will be created.
  */
  retrievePin(request: RetrievePinRequest, metadata: grpc.Metadata, callback: (err: any|null, response: RetrievePinResponse, metadata: grpc.Metadata) => void): void;

  retrievePin(request: RetrievePinRequest, arg1?: grpc.Metadata|((err: any|null, response: RetrievePinResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: RetrievePinResponse, metadata: grpc.Metadata) => void): Promise<RetrievePinResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<RetrievePinResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__LoginManager.RetrievePin, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  getKioskPin(request: KioskPinRequest): Promise<KioskPinResponse>;
  getKioskPin(request: KioskPinRequest, metadata: grpc.Metadata): Promise<KioskPinResponse>;
  getKioskPin(request: KioskPinRequest, callback: (err: any|null, response: KioskPinResponse, metadata: grpc.Metadata) => void): void;
  getKioskPin(request: KioskPinRequest, metadata: grpc.Metadata, callback: (err: any|null, response: KioskPinResponse, metadata: grpc.Metadata) => void): void;

  getKioskPin(request: KioskPinRequest, arg1?: grpc.Metadata|((err: any|null, response: KioskPinResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: KioskPinResponse, metadata: grpc.Metadata) => void): Promise<KioskPinResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<KioskPinResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__LoginManager.GetKioskPin, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  resetKioskPin(request: KioskPinRequest): Promise<KioskPinResponse>;
  resetKioskPin(request: KioskPinRequest, metadata: grpc.Metadata): Promise<KioskPinResponse>;
  resetKioskPin(request: KioskPinRequest, callback: (err: any|null, response: KioskPinResponse, metadata: grpc.Metadata) => void): void;
  resetKioskPin(request: KioskPinRequest, metadata: grpc.Metadata, callback: (err: any|null, response: KioskPinResponse, metadata: grpc.Metadata) => void): void;

  resetKioskPin(request: KioskPinRequest, arg1?: grpc.Metadata|((err: any|null, response: KioskPinResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: KioskPinResponse, metadata: grpc.Metadata) => void): Promise<KioskPinResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<KioskPinResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__LoginManager.ResetKioskPin, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  validateKioskPin(request: ValidateKioskPinRequest): Promise<ValidateKioskPinResponse>;
  validateKioskPin(request: ValidateKioskPinRequest, metadata: grpc.Metadata): Promise<ValidateKioskPinResponse>;
  validateKioskPin(request: ValidateKioskPinRequest, callback: (err: any|null, response: ValidateKioskPinResponse, metadata: grpc.Metadata) => void): void;
  validateKioskPin(request: ValidateKioskPinRequest, metadata: grpc.Metadata, callback: (err: any|null, response: ValidateKioskPinResponse, metadata: grpc.Metadata) => void): void;

  validateKioskPin(request: ValidateKioskPinRequest, arg1?: grpc.Metadata|((err: any|null, response: ValidateKioskPinResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: ValidateKioskPinResponse, metadata: grpc.Metadata) => void): Promise<ValidateKioskPinResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<ValidateKioskPinResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__LoginManager.ValidateKioskPin, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  forgotKioskPin(request: ForgotKioskPinRequest): Promise<ForgotKioskPinResponse>;
  forgotKioskPin(request: ForgotKioskPinRequest, metadata: grpc.Metadata): Promise<ForgotKioskPinResponse>;
  forgotKioskPin(request: ForgotKioskPinRequest, callback: (err: any|null, response: ForgotKioskPinResponse, metadata: grpc.Metadata) => void): void;
  forgotKioskPin(request: ForgotKioskPinRequest, metadata: grpc.Metadata, callback: (err: any|null, response: ForgotKioskPinResponse, metadata: grpc.Metadata) => void): void;

  forgotKioskPin(request: ForgotKioskPinRequest, arg1?: grpc.Metadata|((err: any|null, response: ForgotKioskPinResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: ForgotKioskPinResponse, metadata: grpc.Metadata) => void): Promise<ForgotKioskPinResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<ForgotKioskPinResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__LoginManager.ForgotKioskPin, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  onStartup(request: OnStartupRequest): Promise<OnStartupResponse>;
  onStartup(request: OnStartupRequest, metadata: grpc.Metadata): Promise<OnStartupResponse>;
  onStartup(request: OnStartupRequest, callback: (err: any|null, response: OnStartupResponse, metadata: grpc.Metadata) => void): void;
  onStartup(request: OnStartupRequest, metadata: grpc.Metadata, callback: (err: any|null, response: OnStartupResponse, metadata: grpc.Metadata) => void): void;

  onStartup(request: OnStartupRequest, arg1?: grpc.Metadata|((err: any|null, response: OnStartupResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: OnStartupResponse, metadata: grpc.Metadata) => void): Promise<OnStartupResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<OnStartupResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__LoginManager.OnStartup, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

}

