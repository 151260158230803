import { Injectable } from '@angular/core';

import * as day from 'dayjs';
import { gateway } from 'libs/generated-grpc-web';

import { dateTimeObjectToDateTimeMessage } from '@app/src/app/util/date';

import { ErrorHandlerService } from '@shared/services/error-handler';

import { CacheService } from '../cache/cache.service';
import { CacheKey } from '../cache/cache.types';
import { createCacheCollection } from '../cache/cache.utils';
import { UserStats, MingaStats } from './stats.types';

@Injectable({ providedIn: 'root' })
export class StatsService {
  private _cachedUserStats = createCacheCollection(key =>
    this._cacheService.create<UserStats>(
      `${CacheKey.STATS_USER}:${key}`,
      async data => {
        return await this._getStatsForUser(data.personHash);
      },
      {
        ttl: 60,
      },
    ),
  );
  private _cachedMingaStats = createCacheCollection(key =>
    this._cacheService.create<MingaStats>(
      `${CacheKey.STATS_MINGA}:${key}`,
      async data => {
        return await this._getMingaStats(data);
      },
      {
        // these stats get aggregated daily, so we can cache for a while
        ttl: 60 * 8, // 8hrs
      },
    ),
  );

  /** Service Constructor */
  constructor(
    private _errorHandler: ErrorHandlerService,
    private _mingaManager: gateway.minga_ng_grpc_pb.MingaManager,
    private _cacheService: CacheService,
  ) {}

  /**
   * Stats for a specific user
   */
  public async getStatsForUser(
    personHash: string,
    opts?: {
      revalidate?: boolean;
    },
  ): Promise<UserStats> {
    const cacheItem = this._cachedUserStats.get(personHash);

    return await cacheItem
      .get(
        {
          personHash,
        },
        opts,
      )
      .toPromise();
  }

  /**
   * Stats around the minga (shown to manager+ roles)
   */
  public async getMingaStats(
    data: {
      startDate: day.Dayjs;
      endDate: day.Dayjs;
    },
    cacheOpts?: {
      revalidate?: boolean;
    },
  ): Promise<MingaStats> {
    const key = this._mingaStatsCacheKey(data.startDate, data.endDate);
    const cacheItem = this._cachedMingaStats.get(key);
    return await cacheItem.get(data, cacheOpts).toPromise();
  }

  private async _getStatsForUser(personHash: string): Promise<UserStats> {
    try {
      const request = new gateway.minga_pb.GetStatsForStudentRequest();
      request.setStudentHash(personHash);
      const stats = await this._mingaManager.getStatsForStudent(request);
      return {
        redeemablePointCount: stats.getRedeemablePointCount(),
        overdueConsequenceCount: stats.getOverdueConsequenceCount(),
        activeConsequenceCount: stats.getActiveConsequenceCount(),
        unregisteredPeriodCount: stats.getUnregisteredPeriodCount(),
      };
    } catch (error) {
      throw this._errorHandler.gateWayError(
        'Failed to user stats',
        error,
        false,
      );
    }
  }

  /**
   * General stats for the minga (separate from minga stats in super admin)
   */
  private async _getMingaStats(opts: {
    startDate: day.Dayjs;
    endDate: day.Dayjs;
  }): Promise<MingaStats> {
    try {
      const request = new gateway.minga_pb.GetMingaStatsForPersonRequest();
      request.setStartDate(
        dateTimeObjectToDateTimeMessage(opts.startDate.toDate()),
      );
      request.setEndDate(
        dateTimeObjectToDateTimeMessage(opts.endDate.toDate()),
      );
      const stats = await this._mingaManager.getMingaStatsForPerson(request);
      return {
        activeUserCount: stats.getActiveUserCount(),
        checkinCount: stats.getCheckinCount(),
        hallPassCount: stats.getHallPassCount(),
        pointCount: stats.getPointCount(),
        behaviorCount: stats.getBehaviorCount(),
      };
    } catch (error) {
      throw this._errorHandler.gateWayError(
        'Failed to user stats',
        error,
        true,
      );
    }
  }

  private _mingaStatsCacheKey(
    startDate: day.Dayjs,
    endDate: day.Dayjs,
  ): string {
    return `${startDate.format('YYYY-MM-DD')}:${endDate.format('YYYY-MM-DD')}`;
  }
}
