import {
  AutomationAutoResetCustom,
  AutomationAutoResetFrequency,
  AutomationAutoResetMonthly,
  AutomationAutoResetValue,
  AutomationPayload,
  AutomationTrigger,
  IActionThresholdAutomation,
} from 'libs/domain';
import { automation_pb } from 'libs/generated-grpc-ts';
import { $enum } from 'ts-enum-util';

export const toProto = (
  automation: AutomationPayload,
): automation_pb.Automation => {
  const message = new automation_pb.Automation();
  if (automation.id) message.setId(automation.id);
  if (automation.mingaId) message.setMingaId(automation.mingaId);
  if (automation.name) message.setName(automation.name);
  if (automation.description) message.setDescription(automation.description);

  if (typeof automation.active !== 'undefined')
    message.setActive(automation.active);

  if (automation.resetCounter) {
    message.setResetCounter(automation.resetCounter);
  }

  if (automation.note) {
    message.setNote(automation.note);
  }

  if (automation.triggers) {
    const triggersMsg = new automation_pb.AutomationTriggers();
    automation.triggers.pbisTriggers?.forEach(trigger => {
      const msg = new automation_pb.AutomationTrigger();
      msg.setId(trigger.id);
      msg.setIconType(trigger.iconType || '');
      msg.setIconColor(trigger.iconColor || '');
      msg.setName(trigger.name || '');

      triggersMsg.addBehaviors(msg);
    });
    automation.triggers.checkinReasonTriggers?.forEach(trigger => {
      const msg = new automation_pb.AutomationTrigger();
      msg.setId(trigger.id);
      msg.setIconType(trigger.iconType || '');
      msg.setIconColor(trigger.iconColor || '');
      msg.setName(trigger.name || '');

      triggersMsg.addCheckinReason(msg);
    });
    message.setTriggers(triggersMsg);
  }

  if (automation.autoReset?.length)
    for (const autoReset of automation.autoReset) {
      const autoResetMsg = new automation_pb.AutomationAutoReset();
      autoResetMsg.setFrequency(autoReset.frequency);
      if (autoReset.frequency === AutomationAutoResetFrequency.CUSTOM) {
        autoResetMsg.setDate(autoReset.date);
        autoResetMsg.setTime(autoReset.time);
      }
      message.addAutoReset(autoResetMsg);
    }
  return message;
};

export const fromProto = (
  message: automation_pb.Automation,
): IActionThresholdAutomation => {
  let pbisTriggers: AutomationTrigger[] = [];
  let checkinReasonTriggers: AutomationTrigger[] = [];
  const triggerMsg = message.getTriggers();
  if (triggerMsg) {
    pbisTriggers = triggerMsg.getBehaviorsList().map(behavior => {
      return { id: behavior.getId() };
    });
    checkinReasonTriggers = triggerMsg.getCheckinReasonList().map(reason => {
      return { id: reason.getId() };
    });
  }

  const autoResetMsgList = message.getAutoResetList();
  const autoResetList: AutomationAutoResetValue[] = [];
  if (autoResetMsgList?.length)
    for (const autoResetMsg of autoResetMsgList) {
      const frequency = $enum(AutomationAutoResetFrequency).asValueOrThrow(
        autoResetMsg.getFrequency(),
      );
      const autoReset = {
        frequency,
      };
      const isCustom = (obj: any): obj is AutomationAutoResetCustom =>
        obj.frequency === AutomationAutoResetFrequency.CUSTOM;
      const validFrequency = (obj: any): obj is AutomationAutoResetMonthly =>
        obj.frequency === AutomationAutoResetFrequency.MONTHLY ||
        obj.frequency === AutomationAutoResetFrequency.DAILY ||
        obj.frequency === AutomationAutoResetFrequency.WEEKLY ||
        obj.frequency === AutomationAutoResetFrequency.CUSTOM;

      if (isCustom(autoReset)) {
        autoReset.date = autoResetMsg.getDate();
        autoReset.time = autoResetMsg.getTime();
      } else if (!validFrequency(autoReset))
        throw new Error('invalid frequency');
      autoResetList.push(autoReset);
    }

  return {
    id: message.getId(),
    mingaId: message.getMingaId(),
    name: message.getName(),
    description: message.getDescription(),
    note: message.getNote(),
    active: message.getActive(),
    resetCounter: message.getResetCounter(),
    triggers: {
      pbisTriggers,
      checkinReasonTriggers,
    },
    autoReset: autoResetList,
  };
};
