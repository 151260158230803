// source: messaging/messaging.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

import * as jspb from 'google-protobuf';
import * as common_stream_pb from '../common/stream_pb';
import * as image_image_pb from '../image/image_pb';
export namespace ConversationPreview {
  export interface AsObject {
    conversationId: number;
    lastMessageTimestamp: number;
    lastMessageBody: string;
    participantPeopleList: string[];
    readStatus: boolean;
  }
}
export class ConversationPreview extends jspb.Message {
  static readonly displayName = "ConversationPreview";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [4];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, ConversationPreview.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): ConversationPreview.AsObject {
    return ConversationPreview.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!ConversationPreview} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: ConversationPreview) {
    var f: any;
    var obj: any = {
      conversationId: jspb.Message.getFieldWithDefault(msg, 1, 0),
      lastMessageTimestamp: jspb.Message.getFieldWithDefault(msg, 2, 0),
      lastMessageBody: jspb.Message.getFieldWithDefault(msg, 3, ""),
      participantPeopleList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
      readStatus: jspb.Message.getBooleanFieldWithDefault(msg, 5, false)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!ConversationPreview}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new ConversationPreview;
    return ConversationPreview.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!ConversationPreview} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!ConversationPreview}
   */
  static deserializeBinaryFromReader(msg: ConversationPreview, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {number} */ (reader.readUint32());
        msg.setConversationId(value1);
        break;
      case 2:
        var value2 = /** @type {number} */ (reader.readUint64());
        msg.setLastMessageTimestamp(value2);
        break;
      case 3:
        var value3 = /** @type {string} */ (reader.readString());
        msg.setLastMessageBody(value3);
        break;
      case 4:
        var value4 = /** @type {string} */ (reader.readString());
        msg.addParticipantPeople(value4);
        break;
      case 5:
        var value5 = /** @type {boolean} */ (reader.readBool());
        msg.setReadStatus(value5);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!ConversationPreview} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: ConversationPreview, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getConversationId();
    if (f !== 0) {
      writer.writeUint32(
        1,
        f
      );
    }
    f = message.getLastMessageTimestamp();
    if (f !== 0) {
      writer.writeUint64(
        2,
        f
      );
    }
    f = message.getLastMessageBody();
    if (f.length > 0) {
      writer.writeString(
        3,
        f
      );
    }
    f = message.getParticipantPeopleList();
    if (f.length > 0) {
      writer.writeRepeatedString(
        4,
        f
      );
    }
    f = message.getReadStatus();
    if (f) {
      writer.writeBool(
        5,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    ConversationPreview.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional uint32 conversation_id = 1;
   * @return {number}
   */
  getConversationId(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
  };


  /** @param {number} value */
  setConversationId(value: number) {
    jspb.Message.setProto3IntField(this, 1, value);
  }


  /**
   * optional uint64 last_message_timestamp = 2;
   * @return {number}
   */
  getLastMessageTimestamp(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
  };


  /** @param {number} value */
  setLastMessageTimestamp(value: number) {
    jspb.Message.setProto3IntField(this, 2, value);
  }


  /**
   * optional string last_message_body = 3;
   * @return {string}
   */
  getLastMessageBody(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
  };


  /** @param {string} value */
  setLastMessageBody(value: string) {
    jspb.Message.setProto3StringField(this, 3, value);
  }


  /**
   * repeated string participant_people = 4;
   * @return {!Array<string>}
   */
  getParticipantPeopleList(): string[] {
    return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 4));
  };


  /** @param {!Array<string>} value */
  setParticipantPeopleList(value: string[]) {
    jspb.Message.setField(this, 4, value || []);
  }


  /**
   * @param {string} value
   * @param {number=} opt_index
   */
  addParticipantPeople(value: string, opt_index?: number): void {
    jspb.Message.addToRepeatedField(this, 4, value, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearParticipantPeopleList() {
    this.setParticipantPeopleList([]);
  }


  /**
   * optional bool read_status = 5;
   * @return {boolean}
   */
  getReadStatus(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
  };


  /** @param {boolean} value */
  setReadStatus(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 5, value);
  }


}
export namespace MessagingStreamConversationsResponse {
  export interface AsObject {
    itemMetadata?: common_stream_pb.StreamItemMetadata.AsObject;
    item?: ConversationPreview.AsObject;
  }
}
export class MessagingStreamConversationsResponse extends jspb.Message {
  static readonly displayName = "MessagingStreamConversationsResponse";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): MessagingStreamConversationsResponse.AsObject {
    return MessagingStreamConversationsResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!MessagingStreamConversationsResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: MessagingStreamConversationsResponse) {
    var f: any;
    var obj: any = {
      itemMetadata: (f = msg.getItemMetadata()) && common_stream_pb.StreamItemMetadata.toObject(includeInstance, f),
      item: (f = msg.getItem()) && ConversationPreview.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!MessagingStreamConversationsResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new MessagingStreamConversationsResponse;
    return MessagingStreamConversationsResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!MessagingStreamConversationsResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!MessagingStreamConversationsResponse}
   */
  static deserializeBinaryFromReader(msg: MessagingStreamConversationsResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new common_stream_pb.StreamItemMetadata;
        reader.readMessage(value1,common_stream_pb.StreamItemMetadata.deserializeBinaryFromReader);
        msg.setItemMetadata(value1);
        break;
      case 2:
        var value2 = new ConversationPreview;
        reader.readMessage(value2,ConversationPreview.deserializeBinaryFromReader);
        msg.setItem(value2);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!MessagingStreamConversationsResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: MessagingStreamConversationsResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getItemMetadata();
    if (f != null) {
      writer.writeMessage(
        1,
        f,
        common_stream_pb.StreamItemMetadata.serializeBinaryToWriter
      );
    }
    f = message.getItem();
    if (f != null) {
      writer.writeMessage(
        2,
        f,
        ConversationPreview.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    MessagingStreamConversationsResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional StreamItemMetadata item_metadata = 1;
   * @return {?StreamItemMetadata}
   */
  getItemMetadata(): common_stream_pb.StreamItemMetadata {
    return /** @type{?StreamItemMetadata} */ (
      jspb.Message.getWrapperField(this, common_stream_pb.StreamItemMetadata, 1));
  }


  /** @param {?StreamItemMetadata|undefined} value */
  setItemMetadata(value?: common_stream_pb.StreamItemMetadata) {
    jspb.Message.setWrapperField(this, 1, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearItemMetadata() {
    this.setItemMetadata(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasItemMetadata(): boolean {
    return jspb.Message.getField(this, 1) != null;
  }


  /**
   * optional ConversationPreview item = 2;
   * @return {?ConversationPreview}
   */
  getItem(): ConversationPreview {
    return /** @type{?ConversationPreview} */ (
      jspb.Message.getWrapperField(this, ConversationPreview, 2));
  }


  /** @param {?ConversationPreview|undefined} value */
  setItem(value?: ConversationPreview) {
    jspb.Message.setWrapperField(this, 2, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearItem() {
    this.setItem(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasItem(): boolean {
    return jspb.Message.getField(this, 2) != null;
  }


}
export namespace Message {
  export interface AsObject {
    id: number;
    timestamp: number;
    body: string;
    authorPerson: string;
    statusMap: [string, Message.MessageStatus.AsObject][];
    attachmentList: Message.MessageAttachment.AsObject[];
  }
}
export class Message extends jspb.Message {
  static readonly displayName = "Message";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [6];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, Message.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): Message.AsObject {
    return Message.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!Message} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: Message) {
    var f: any;
    var obj: any = {
      id: jspb.Message.getFieldWithDefault(msg, 1, 0),
      timestamp: jspb.Message.getFieldWithDefault(msg, 2, 0),
      body: jspb.Message.getFieldWithDefault(msg, 3, ""),
      authorPerson: jspb.Message.getFieldWithDefault(msg, 4, ""),
      statusMap: (f = msg.getStatusMap()) ? f.toObject(includeInstance, Message.MessageStatus.toObject) : [],
      attachmentList: jspb.Message.toObjectList(msg.getAttachmentList(),
      Message.MessageAttachment.toObject, includeInstance)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!Message}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new Message;
    return Message.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!Message} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!Message}
   */
  static deserializeBinaryFromReader(msg: Message, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {number} */ (reader.readUint64());
        msg.setId(value1);
        break;
      case 2:
        var value2 = /** @type {number} */ (reader.readUint64());
        msg.setTimestamp(value2);
        break;
      case 3:
        var value3 = /** @type {string} */ (reader.readString());
        msg.setBody(value3);
        break;
      case 4:
        var value4 = /** @type {string} */ (reader.readString());
        msg.setAuthorPerson(value4);
        break;
      case 5:
        var value5 = msg.getStatusMap();
        reader.readMessage(value5, function(message, reader) {
          jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, Message.MessageStatus.deserializeBinaryFromReader, "");
           });
        break;
      case 6:
        var value6 = new Message.MessageAttachment;
        reader.readMessage(value6,Message.MessageAttachment.deserializeBinaryFromReader);
        msg.addAttachment(value6);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!Message} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: Message, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getId();
    if (f !== 0) {
      writer.writeUint64(
        1,
        f
      );
    }
    f = message.getTimestamp();
    if (f !== 0) {
      writer.writeUint64(
        2,
        f
      );
    }
    f = message.getBody();
    if (f.length > 0) {
      writer.writeString(
        3,
        f
      );
    }
    f = message.getAuthorPerson();
    if (f.length > 0) {
      writer.writeString(
        4,
        f
      );
    }
    f = message.getStatusMap(true);
    if (f && f.getLength() > 0) {
      f.serializeBinary(5, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, Message.MessageStatus.serializeBinaryToWriter);
    }
    f = message.getAttachmentList();
    if (f.length > 0) {
      writer.writeRepeatedMessage(
        6,
        f,
        Message.MessageAttachment.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    Message.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional uint64 id = 1;
   * @return {number}
   */
  getId(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
  };


  /** @param {number} value */
  setId(value: number) {
    jspb.Message.setProto3IntField(this, 1, value);
  }


  /**
   * optional uint64 timestamp = 2;
   * @return {number}
   */
  getTimestamp(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
  };


  /** @param {number} value */
  setTimestamp(value: number) {
    jspb.Message.setProto3IntField(this, 2, value);
  }


  /**
   * optional string body = 3;
   * @return {string}
   */
  getBody(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
  };


  /** @param {string} value */
  setBody(value: string) {
    jspb.Message.setProto3StringField(this, 3, value);
  }


  /**
   * optional string author_person = 4;
   * @return {string}
   */
  getAuthorPerson(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
  };


  /** @param {string} value */
  setAuthorPerson(value: string) {
    jspb.Message.setProto3StringField(this, 4, value);
  }


  /**
   * map<string, MessageStatus> status = 5;
   * @param {boolean=} opt_noLazyCreate Do not create the map if
   * empty, instead returning `undefined`
   * @return {!jspb.Map<string,!Message.MessageStatus>}
   */
  getStatusMap(): jspb.Map<string,Message.MessageStatus>;
  getStatusMap(noLazyCreate: true): jspb.Map<string,Message.MessageStatus>|undefined;
  getStatusMap(noLazyCreate: false): jspb.Map<string,Message.MessageStatus>;
  getStatusMap(opt_noLazyCreate: boolean = false): jspb.Map<string,Message.MessageStatus> {
    return /** @type {!jspb.Map<string,!Message.MessageStatus>} */ (
        jspb.Message.getMapField(this, 5, opt_noLazyCreate,
        Message.MessageStatus));
  }


  /**
   * Clears values from the map. The map will be non-null.
   */
  clearStatusMap() {
    this.getStatusMap().clear();
  }


  /**
   * repeated MessageAttachment attachment = 6;
   * @return {!Array<!Message.MessageAttachment>}
   */
  getAttachmentList(): Message.MessageAttachment[] {
    return /** @type{!Array<!Message.MessageAttachment>} */ (
      jspb.Message.getRepeatedWrapperField(this, Message.MessageAttachment, 6));
  }


  /** @param {!Array<!Message.MessageAttachment>} value */
  setAttachmentList(value?: Message.MessageAttachment[]) {
    jspb.Message.setRepeatedWrapperField(this, 6, value);
  }


  /**
   * @param {!Message.MessageAttachment=} opt_value
   * @param {number=} opt_index
   * @return {!Message.MessageAttachment}
   */
  addAttachment(opt_value?: Message.MessageAttachment, opt_index?: number): Message.MessageAttachment {
    return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, Message.MessageAttachment, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearAttachmentList() {
    this.setAttachmentList([]);
  }


}
export namespace Message {
export namespace MessageStatus {
  export interface AsObject {
    readAtTimestamp: number;
  }
}
export class MessageStatus extends jspb.Message {
  static readonly displayName = "Message.MessageStatus";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): MessageStatus.AsObject {
    return MessageStatus.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!MessageStatus} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: MessageStatus) {
    var f: any;
    var obj: any = {
      readAtTimestamp: jspb.Message.getFieldWithDefault(msg, 1, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!MessageStatus}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new MessageStatus;
    return MessageStatus.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!Message.MessageStatus} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!Message.MessageStatus}
   */
  static deserializeBinaryFromReader(msg: MessageStatus, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {number} */ (reader.readUint64());
        msg.setReadAtTimestamp(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!Message.MessageStatus} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: Message.MessageStatus, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getReadAtTimestamp();
    if (f !== 0) {
      writer.writeUint64(
        1,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    Message.MessageStatus.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional uint64 read_at_timestamp = 1;
   * @return {number}
   */
  getReadAtTimestamp(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
  };


  /** @param {number} value */
  setReadAtTimestamp(value: number) {
    jspb.Message.setProto3IntField(this, 1, value);
  }


}
} // namespace Message
export namespace Message {
export namespace MessageAttachment {
  export interface AsObject {
    image?: image_image_pb.ImageInfo.AsObject;
  }
}
export class MessageAttachment extends jspb.Message {
  static readonly displayName = "Message.MessageAttachment";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): MessageAttachment.AsObject {
    return MessageAttachment.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!MessageAttachment} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: MessageAttachment) {
    var f: any;
    var obj: any = {
      image: (f = msg.getImage()) && image_image_pb.ImageInfo.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!MessageAttachment}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new MessageAttachment;
    return MessageAttachment.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!Message.MessageAttachment} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!Message.MessageAttachment}
   */
  static deserializeBinaryFromReader(msg: MessageAttachment, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new image_image_pb.ImageInfo;
        reader.readMessage(value1,image_image_pb.ImageInfo.deserializeBinaryFromReader);
        msg.setImage(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!Message.MessageAttachment} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: Message.MessageAttachment, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getImage();
    if (f != null) {
      writer.writeMessage(
        1,
        f,
        image_image_pb.ImageInfo.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    Message.MessageAttachment.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional ImageInfo image = 1;
   * @return {?ImageInfo}
   */
  getImage(): image_image_pb.ImageInfo {
    return /** @type{?ImageInfo} */ (
      jspb.Message.getWrapperField(this, image_image_pb.ImageInfo, 1));
  }


  /** @param {?ImageInfo|undefined} value */
  setImage(value?: image_image_pb.ImageInfo) {
    jspb.Message.setWrapperField(this, 1, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearImage() {
    this.setImage(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasImage(): boolean {
    return jspb.Message.getField(this, 1) != null;
  }


}
} // namespace Message
export namespace MessagingStartConversationRequest {
  export interface AsObject {
    participantList: string[];
  }
}
export class MessagingStartConversationRequest extends jspb.Message {
  static readonly displayName = "MessagingStartConversationRequest";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [1];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, MessagingStartConversationRequest.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): MessagingStartConversationRequest.AsObject {
    return MessagingStartConversationRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!MessagingStartConversationRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: MessagingStartConversationRequest) {
    var f: any;
    var obj: any = {
      participantList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!MessagingStartConversationRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new MessagingStartConversationRequest;
    return MessagingStartConversationRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!MessagingStartConversationRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!MessagingStartConversationRequest}
   */
  static deserializeBinaryFromReader(msg: MessagingStartConversationRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.addParticipant(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!MessagingStartConversationRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: MessagingStartConversationRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getParticipantList();
    if (f.length > 0) {
      writer.writeRepeatedString(
        1,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    MessagingStartConversationRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * repeated string participant = 1;
   * @return {!Array<string>}
   */
  getParticipantList(): string[] {
    return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
  };


  /** @param {!Array<string>} value */
  setParticipantList(value: string[]) {
    jspb.Message.setField(this, 1, value || []);
  }


  /**
   * @param {string} value
   * @param {number=} opt_index
   */
  addParticipant(value: string, opt_index?: number): void {
    jspb.Message.addToRepeatedField(this, 1, value, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearParticipantList() {
    this.setParticipantList([]);
  }


}
export namespace MessagingStartConversationResponse {
  export interface AsObject {
    newConversationId: number;
  }
}
export class MessagingStartConversationResponse extends jspb.Message {
  static readonly displayName = "MessagingStartConversationResponse";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): MessagingStartConversationResponse.AsObject {
    return MessagingStartConversationResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!MessagingStartConversationResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: MessagingStartConversationResponse) {
    var f: any;
    var obj: any = {
      newConversationId: jspb.Message.getFieldWithDefault(msg, 1, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!MessagingStartConversationResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new MessagingStartConversationResponse;
    return MessagingStartConversationResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!MessagingStartConversationResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!MessagingStartConversationResponse}
   */
  static deserializeBinaryFromReader(msg: MessagingStartConversationResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {number} */ (reader.readUint32());
        msg.setNewConversationId(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!MessagingStartConversationResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: MessagingStartConversationResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getNewConversationId();
    if (f !== 0) {
      writer.writeUint32(
        1,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    MessagingStartConversationResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional uint32 new_conversation_id = 1;
   * @return {number}
   */
  getNewConversationId(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
  };


  /** @param {number} value */
  setNewConversationId(value: number) {
    jspb.Message.setProto3IntField(this, 1, value);
  }


}
export namespace MessagingSendMessageRequest {
  export interface AsObject {
    conversationId: number;
    messageBody: string;
    assetPathList: string[];
  }
}
export class MessagingSendMessageRequest extends jspb.Message {
  static readonly displayName = "MessagingSendMessageRequest";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [3];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, MessagingSendMessageRequest.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): MessagingSendMessageRequest.AsObject {
    return MessagingSendMessageRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!MessagingSendMessageRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: MessagingSendMessageRequest) {
    var f: any;
    var obj: any = {
      conversationId: jspb.Message.getFieldWithDefault(msg, 1, 0),
      messageBody: jspb.Message.getFieldWithDefault(msg, 2, ""),
      assetPathList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!MessagingSendMessageRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new MessagingSendMessageRequest;
    return MessagingSendMessageRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!MessagingSendMessageRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!MessagingSendMessageRequest}
   */
  static deserializeBinaryFromReader(msg: MessagingSendMessageRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {number} */ (reader.readUint32());
        msg.setConversationId(value1);
        break;
      case 2:
        var value2 = /** @type {string} */ (reader.readString());
        msg.setMessageBody(value2);
        break;
      case 3:
        var value3 = /** @type {string} */ (reader.readString());
        msg.addAssetPath(value3);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!MessagingSendMessageRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: MessagingSendMessageRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getConversationId();
    if (f !== 0) {
      writer.writeUint32(
        1,
        f
      );
    }
    f = message.getMessageBody();
    if (f.length > 0) {
      writer.writeString(
        2,
        f
      );
    }
    f = message.getAssetPathList();
    if (f.length > 0) {
      writer.writeRepeatedString(
        3,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    MessagingSendMessageRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional uint32 conversation_id = 1;
   * @return {number}
   */
  getConversationId(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
  };


  /** @param {number} value */
  setConversationId(value: number) {
    jspb.Message.setProto3IntField(this, 1, value);
  }


  /**
   * optional string message_body = 2;
   * @return {string}
   */
  getMessageBody(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
  };


  /** @param {string} value */
  setMessageBody(value: string) {
    jspb.Message.setProto3StringField(this, 2, value);
  }


  /**
   * repeated string asset_path = 3;
   * @return {!Array<string>}
   */
  getAssetPathList(): string[] {
    return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
  };


  /** @param {!Array<string>} value */
  setAssetPathList(value: string[]) {
    jspb.Message.setField(this, 3, value || []);
  }


  /**
   * @param {string} value
   * @param {number=} opt_index
   */
  addAssetPath(value: string, opt_index?: number): void {
    jspb.Message.addToRepeatedField(this, 3, value, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearAssetPathList() {
    this.setAssetPathList([]);
  }


}
export namespace MessagingSendMessageResponse {
  export interface AsObject {
    newMessageId: number;
    attachmentList: Message.MessageAttachment.AsObject[];
  }
}
export class MessagingSendMessageResponse extends jspb.Message {
  static readonly displayName = "MessagingSendMessageResponse";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [2];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, MessagingSendMessageResponse.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): MessagingSendMessageResponse.AsObject {
    return MessagingSendMessageResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!MessagingSendMessageResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: MessagingSendMessageResponse) {
    var f: any;
    var obj: any = {
      newMessageId: jspb.Message.getFieldWithDefault(msg, 1, 0),
      attachmentList: jspb.Message.toObjectList(msg.getAttachmentList(),
      Message.MessageAttachment.toObject, includeInstance)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!MessagingSendMessageResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new MessagingSendMessageResponse;
    return MessagingSendMessageResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!MessagingSendMessageResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!MessagingSendMessageResponse}
   */
  static deserializeBinaryFromReader(msg: MessagingSendMessageResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {number} */ (reader.readUint64());
        msg.setNewMessageId(value1);
        break;
      case 2:
        var value2 = new Message.MessageAttachment;
        reader.readMessage(value2,Message.MessageAttachment.deserializeBinaryFromReader);
        msg.addAttachment(value2);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!MessagingSendMessageResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: MessagingSendMessageResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getNewMessageId();
    if (f !== 0) {
      writer.writeUint64(
        1,
        f
      );
    }
    f = message.getAttachmentList();
    if (f.length > 0) {
      writer.writeRepeatedMessage(
        2,
        f,
        Message.MessageAttachment.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    MessagingSendMessageResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional uint64 new_message_id = 1;
   * @return {number}
   */
  getNewMessageId(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
  };


  /** @param {number} value */
  setNewMessageId(value: number) {
    jspb.Message.setProto3IntField(this, 1, value);
  }


  /**
   * repeated Message.MessageAttachment attachment = 2;
   * @return {!Array<!Message.MessageAttachment>}
   */
  getAttachmentList(): Message.MessageAttachment[] {
    return /** @type{!Array<!Message.MessageAttachment>} */ (
      jspb.Message.getRepeatedWrapperField(this, Message.MessageAttachment, 2));
  }


  /** @param {!Array<!Message.MessageAttachment>} value */
  setAttachmentList(value?: Message.MessageAttachment[]) {
    jspb.Message.setRepeatedWrapperField(this, 2, value);
  }


  /**
   * @param {!Message.MessageAttachment=} opt_value
   * @param {number=} opt_index
   * @return {!Message.MessageAttachment}
   */
  addAttachment(opt_value?: Message.MessageAttachment, opt_index?: number): Message.MessageAttachment {
    return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, Message.MessageAttachment, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearAttachmentList() {
    this.setAttachmentList([]);
  }


}
export namespace MessagingMarkAsReadRequest {
  export interface AsObject {
    messageIdList: number[];
    conversationIdList: number[];
  }
}
export class MessagingMarkAsReadRequest extends jspb.Message {
  static readonly displayName = "MessagingMarkAsReadRequest";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [1,2];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, MessagingMarkAsReadRequest.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): MessagingMarkAsReadRequest.AsObject {
    return MessagingMarkAsReadRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!MessagingMarkAsReadRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: MessagingMarkAsReadRequest) {
    var f: any;
    var obj: any = {
      messageIdList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
      conversationIdList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!MessagingMarkAsReadRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new MessagingMarkAsReadRequest;
    return MessagingMarkAsReadRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!MessagingMarkAsReadRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!MessagingMarkAsReadRequest}
   */
  static deserializeBinaryFromReader(msg: MessagingMarkAsReadRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {!Array<number>} */ (reader.readPackedUint64());
        msg.setMessageIdList(value1);
        break;
      case 2:
        var value2 = /** @type {!Array<number>} */ (reader.readPackedUint32());
        msg.setConversationIdList(value2);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!MessagingMarkAsReadRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: MessagingMarkAsReadRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getMessageIdList();
    if (f.length > 0) {
      writer.writePackedUint64(
        1,
        f
      );
    }
    f = message.getConversationIdList();
    if (f.length > 0) {
      writer.writePackedUint32(
        2,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    MessagingMarkAsReadRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * repeated uint64 message_id = 1;
   * @return {!Array<number>}
   */
  getMessageIdList(): number[] {
    return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 1));
  };


  /** @param {!Array<number>} value */
  setMessageIdList(value: number[]) {
    jspb.Message.setField(this, 1, value || []);
  }


  /**
   * @param {number} value
   * @param {number=} opt_index
   */
  addMessageId(value: number, opt_index?: number): void {
    jspb.Message.addToRepeatedField(this, 1, value, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearMessageIdList() {
    this.setMessageIdList([]);
  }


  /**
   * repeated uint32 conversation_id = 2;
   * @return {!Array<number>}
   */
  getConversationIdList(): number[] {
    return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 2));
  };


  /** @param {!Array<number>} value */
  setConversationIdList(value: number[]) {
    jspb.Message.setField(this, 2, value || []);
  }


  /**
   * @param {number} value
   * @param {number=} opt_index
   */
  addConversationId(value: number, opt_index?: number): void {
    jspb.Message.addToRepeatedField(this, 2, value, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearConversationIdList() {
    this.setConversationIdList([]);
  }


}
export namespace MessagingMarkAsReadResponse {
  export interface AsObject {
    conversation?: ConversationPreview.AsObject;
  }
}
export class MessagingMarkAsReadResponse extends jspb.Message {
  static readonly displayName = "MessagingMarkAsReadResponse";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): MessagingMarkAsReadResponse.AsObject {
    return MessagingMarkAsReadResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!MessagingMarkAsReadResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: MessagingMarkAsReadResponse) {
    var f: any;
    var obj: any = {
      conversation: (f = msg.getConversation()) && ConversationPreview.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!MessagingMarkAsReadResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new MessagingMarkAsReadResponse;
    return MessagingMarkAsReadResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!MessagingMarkAsReadResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!MessagingMarkAsReadResponse}
   */
  static deserializeBinaryFromReader(msg: MessagingMarkAsReadResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new ConversationPreview;
        reader.readMessage(value1,ConversationPreview.deserializeBinaryFromReader);
        msg.setConversation(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!MessagingMarkAsReadResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: MessagingMarkAsReadResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getConversation();
    if (f != null) {
      writer.writeMessage(
        1,
        f,
        ConversationPreview.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    MessagingMarkAsReadResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional ConversationPreview conversation = 1;
   * @return {?ConversationPreview}
   */
  getConversation(): ConversationPreview {
    return /** @type{?ConversationPreview} */ (
      jspb.Message.getWrapperField(this, ConversationPreview, 1));
  }


  /** @param {?ConversationPreview|undefined} value */
  setConversation(value?: ConversationPreview) {
    jspb.Message.setWrapperField(this, 1, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearConversation() {
    this.setConversation(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasConversation(): boolean {
    return jspb.Message.getField(this, 1) != null;
  }


}
export namespace MessagingGetConversationRequest {
  export interface AsObject {
    conversationId: number;
  }
}
export class MessagingGetConversationRequest extends jspb.Message {
  static readonly displayName = "MessagingGetConversationRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): MessagingGetConversationRequest.AsObject {
    return MessagingGetConversationRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!MessagingGetConversationRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: MessagingGetConversationRequest) {
    var f: any;
    var obj: any = {
      conversationId: jspb.Message.getFieldWithDefault(msg, 1, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!MessagingGetConversationRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new MessagingGetConversationRequest;
    return MessagingGetConversationRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!MessagingGetConversationRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!MessagingGetConversationRequest}
   */
  static deserializeBinaryFromReader(msg: MessagingGetConversationRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {number} */ (reader.readUint32());
        msg.setConversationId(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!MessagingGetConversationRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: MessagingGetConversationRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getConversationId();
    if (f !== 0) {
      writer.writeUint32(
        1,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    MessagingGetConversationRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional uint32 conversation_id = 1;
   * @return {number}
   */
  getConversationId(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
  };


  /** @param {number} value */
  setConversationId(value: number) {
    jspb.Message.setProto3IntField(this, 1, value);
  }


}
export namespace MessagingGetConversationResponse {
  export interface AsObject {
    conversation?: ConversationPreview.AsObject;
  }
}
export class MessagingGetConversationResponse extends jspb.Message {
  static readonly displayName = "MessagingGetConversationResponse";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): MessagingGetConversationResponse.AsObject {
    return MessagingGetConversationResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!MessagingGetConversationResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: MessagingGetConversationResponse) {
    var f: any;
    var obj: any = {
      conversation: (f = msg.getConversation()) && ConversationPreview.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!MessagingGetConversationResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new MessagingGetConversationResponse;
    return MessagingGetConversationResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!MessagingGetConversationResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!MessagingGetConversationResponse}
   */
  static deserializeBinaryFromReader(msg: MessagingGetConversationResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new ConversationPreview;
        reader.readMessage(value1,ConversationPreview.deserializeBinaryFromReader);
        msg.setConversation(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!MessagingGetConversationResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: MessagingGetConversationResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getConversation();
    if (f != null) {
      writer.writeMessage(
        1,
        f,
        ConversationPreview.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    MessagingGetConversationResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional ConversationPreview conversation = 1;
   * @return {?ConversationPreview}
   */
  getConversation(): ConversationPreview {
    return /** @type{?ConversationPreview} */ (
      jspb.Message.getWrapperField(this, ConversationPreview, 1));
  }


  /** @param {?ConversationPreview|undefined} value */
  setConversation(value?: ConversationPreview) {
    jspb.Message.setWrapperField(this, 1, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearConversation() {
    this.setConversation(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasConversation(): boolean {
    return jspb.Message.getField(this, 1) != null;
  }


}
export namespace MessagingGetMessagesRequest {
  export interface AsObject {
    conversationId: number;
  }
}
export class MessagingGetMessagesRequest extends jspb.Message {
  static readonly displayName = "MessagingGetMessagesRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): MessagingGetMessagesRequest.AsObject {
    return MessagingGetMessagesRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!MessagingGetMessagesRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: MessagingGetMessagesRequest) {
    var f: any;
    var obj: any = {
      conversationId: jspb.Message.getFieldWithDefault(msg, 1, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!MessagingGetMessagesRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new MessagingGetMessagesRequest;
    return MessagingGetMessagesRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!MessagingGetMessagesRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!MessagingGetMessagesRequest}
   */
  static deserializeBinaryFromReader(msg: MessagingGetMessagesRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {number} */ (reader.readUint32());
        msg.setConversationId(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!MessagingGetMessagesRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: MessagingGetMessagesRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getConversationId();
    if (f !== 0) {
      writer.writeUint32(
        1,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    MessagingGetMessagesRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional uint32 conversation_id = 1;
   * @return {number}
   */
  getConversationId(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
  };


  /** @param {number} value */
  setConversationId(value: number) {
    jspb.Message.setProto3IntField(this, 1, value);
  }


}
export namespace MessagingGetMessagesResponse {
  export interface AsObject {
    message?: Message.AsObject;
  }
}
export class MessagingGetMessagesResponse extends jspb.Message {
  static readonly displayName = "MessagingGetMessagesResponse";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): MessagingGetMessagesResponse.AsObject {
    return MessagingGetMessagesResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!MessagingGetMessagesResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: MessagingGetMessagesResponse) {
    var f: any;
    var obj: any = {
      message: (f = msg.getMessage()) && Message.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!MessagingGetMessagesResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new MessagingGetMessagesResponse;
    return MessagingGetMessagesResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!MessagingGetMessagesResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!MessagingGetMessagesResponse}
   */
  static deserializeBinaryFromReader(msg: MessagingGetMessagesResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new Message;
        reader.readMessage(value1,Message.deserializeBinaryFromReader);
        msg.setMessage(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!MessagingGetMessagesResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: MessagingGetMessagesResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getMessage();
    if (f != null) {
      writer.writeMessage(
        1,
        f,
        Message.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    MessagingGetMessagesResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional Message message = 1;
   * @return {?Message}
   */
  getMessage(): Message {
    return /** @type{?Message} */ (
      jspb.Message.getWrapperField(this, Message, 1));
  }


  /** @param {?Message|undefined} value */
  setMessage(value?: Message) {
    jspb.Message.setWrapperField(this, 1, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearMessage() {
    this.setMessage(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasMessage(): boolean {
    return jspb.Message.getField(this, 1) != null;
  }


}
export namespace MessagingGetConversationsRequest {
  export interface AsObject {
  }
}
export class MessagingGetConversationsRequest extends jspb.Message {
  static readonly displayName = "MessagingGetConversationsRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): MessagingGetConversationsRequest.AsObject {
    return MessagingGetConversationsRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!MessagingGetConversationsRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: MessagingGetConversationsRequest) {
    var f: any;
    var obj: any = {

    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!MessagingGetConversationsRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new MessagingGetConversationsRequest;
    return MessagingGetConversationsRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!MessagingGetConversationsRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!MessagingGetConversationsRequest}
   */
  static deserializeBinaryFromReader(msg: MessagingGetConversationsRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!MessagingGetConversationsRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: MessagingGetConversationsRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    MessagingGetConversationsRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
}
export namespace MessagingGetConversationsResponse {
  export interface AsObject {
    conversation?: ConversationPreview.AsObject;
  }
}
export class MessagingGetConversationsResponse extends jspb.Message {
  static readonly displayName = "MessagingGetConversationsResponse";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): MessagingGetConversationsResponse.AsObject {
    return MessagingGetConversationsResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!MessagingGetConversationsResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: MessagingGetConversationsResponse) {
    var f: any;
    var obj: any = {
      conversation: (f = msg.getConversation()) && ConversationPreview.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!MessagingGetConversationsResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new MessagingGetConversationsResponse;
    return MessagingGetConversationsResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!MessagingGetConversationsResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!MessagingGetConversationsResponse}
   */
  static deserializeBinaryFromReader(msg: MessagingGetConversationsResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new ConversationPreview;
        reader.readMessage(value1,ConversationPreview.deserializeBinaryFromReader);
        msg.setConversation(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!MessagingGetConversationsResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: MessagingGetConversationsResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getConversation();
    if (f != null) {
      writer.writeMessage(
        1,
        f,
        ConversationPreview.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    MessagingGetConversationsResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional ConversationPreview conversation = 1;
   * @return {?ConversationPreview}
   */
  getConversation(): ConversationPreview {
    return /** @type{?ConversationPreview} */ (
      jspb.Message.getWrapperField(this, ConversationPreview, 1));
  }


  /** @param {?ConversationPreview|undefined} value */
  setConversation(value?: ConversationPreview) {
    jspb.Message.setWrapperField(this, 1, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearConversation() {
    this.setConversation(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasConversation(): boolean {
    return jspb.Message.getField(this, 1) != null;
  }


}
export namespace MessagingCheckUnreadRequest {
  export interface AsObject {
  }
}
export class MessagingCheckUnreadRequest extends jspb.Message {
  static readonly displayName = "MessagingCheckUnreadRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): MessagingCheckUnreadRequest.AsObject {
    return MessagingCheckUnreadRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!MessagingCheckUnreadRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: MessagingCheckUnreadRequest) {
    var f: any;
    var obj: any = {

    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!MessagingCheckUnreadRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new MessagingCheckUnreadRequest;
    return MessagingCheckUnreadRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!MessagingCheckUnreadRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!MessagingCheckUnreadRequest}
   */
  static deserializeBinaryFromReader(msg: MessagingCheckUnreadRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!MessagingCheckUnreadRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: MessagingCheckUnreadRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    MessagingCheckUnreadRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
}
export namespace MessagingCheckUnreadResponse {
  export interface AsObject {
    unread: boolean;
  }
}
export class MessagingCheckUnreadResponse extends jspb.Message {
  static readonly displayName = "MessagingCheckUnreadResponse";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): MessagingCheckUnreadResponse.AsObject {
    return MessagingCheckUnreadResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!MessagingCheckUnreadResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: MessagingCheckUnreadResponse) {
    var f: any;
    var obj: any = {
      unread: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!MessagingCheckUnreadResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new MessagingCheckUnreadResponse;
    return MessagingCheckUnreadResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!MessagingCheckUnreadResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!MessagingCheckUnreadResponse}
   */
  static deserializeBinaryFromReader(msg: MessagingCheckUnreadResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {boolean} */ (reader.readBool());
        msg.setUnread(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!MessagingCheckUnreadResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: MessagingCheckUnreadResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getUnread();
    if (f) {
      writer.writeBool(
        1,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    MessagingCheckUnreadResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional bool unread = 1;
   * @return {boolean}
   */
  getUnread(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
  };


  /** @param {boolean} value */
  setUnread(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 1, value);
  }


}
