// source: gateway/comment.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

import * as jspb from 'google-protobuf';
import * as common_legacy_pb from '../common/legacy_pb';
import * as gateway_person_view_pb from '../gateway/person_view_pb';
import * as gateway_mention_pb from '../gateway/mention_pb';
import * as gateway_moderation_pb from '../gateway/moderation_pb';
export namespace CommentContent {
  export interface AsObject {
    commentContextHash: string;
    authorDisplayName: string;
    authorAvatarUrl: string;
    timestamp: number;
    text: string;
    authorPersonView?: gateway_person_view_pb.PersonViewMinimal.AsObject;
    likeCount: number;
    textMentionRangeList: gateway_mention_pb.MentionRange.AsObject[];
    commentList: CommentContent.AsObject[];
    commentCount: number;
    commentListPage: number;
    deleted: boolean;
    hasLiked: boolean;
  }
}
export class CommentContent extends jspb.Message {
  static readonly displayName = "CommentContent";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [8,9];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, CommentContent.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): CommentContent.AsObject {
    return CommentContent.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!CommentContent} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: CommentContent) {
    var f: any;
    var obj: any = {
      commentContextHash: jspb.Message.getFieldWithDefault(msg, 1, ""),
      authorDisplayName: jspb.Message.getFieldWithDefault(msg, 2, ""),
      authorAvatarUrl: jspb.Message.getFieldWithDefault(msg, 3, ""),
      timestamp: jspb.Message.getFieldWithDefault(msg, 4, 0),
      text: jspb.Message.getFieldWithDefault(msg, 5, ""),
      authorPersonView: (f = msg.getAuthorPersonView()) && gateway_person_view_pb.PersonViewMinimal.toObject(includeInstance, f),
      likeCount: jspb.Message.getFieldWithDefault(msg, 7, 0),
      textMentionRangeList: jspb.Message.toObjectList(msg.getTextMentionRangeList(),
      gateway_mention_pb.MentionRange.toObject, includeInstance),
      commentList: jspb.Message.toObjectList(msg.getCommentList(),
      CommentContent.toObject, includeInstance),
      commentCount: jspb.Message.getFieldWithDefault(msg, 10, 0),
      commentListPage: jspb.Message.getFieldWithDefault(msg, 11, 0),
      deleted: jspb.Message.getBooleanFieldWithDefault(msg, 12, false),
      hasLiked: jspb.Message.getBooleanFieldWithDefault(msg, 13, false)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!CommentContent}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new CommentContent;
    return CommentContent.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!CommentContent} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!CommentContent}
   */
  static deserializeBinaryFromReader(msg: CommentContent, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setCommentContextHash(value1);
        break;
      case 2:
        var value2 = /** @type {string} */ (reader.readString());
        msg.setAuthorDisplayName(value2);
        break;
      case 3:
        var value3 = /** @type {string} */ (reader.readString());
        msg.setAuthorAvatarUrl(value3);
        break;
      case 4:
        var value4 = /** @type {number} */ (reader.readInt64());
        msg.setTimestamp(value4);
        break;
      case 5:
        var value5 = /** @type {string} */ (reader.readString());
        msg.setText(value5);
        break;
      case 6:
        var value6 = new gateway_person_view_pb.PersonViewMinimal;
        reader.readMessage(value6,gateway_person_view_pb.PersonViewMinimal.deserializeBinaryFromReader);
        msg.setAuthorPersonView(value6);
        break;
      case 7:
        var value7 = /** @type {number} */ (reader.readInt32());
        msg.setLikeCount(value7);
        break;
      case 8:
        var value8 = new gateway_mention_pb.MentionRange;
        reader.readMessage(value8,gateway_mention_pb.MentionRange.deserializeBinaryFromReader);
        msg.addTextMentionRange(value8);
        break;
      case 9:
        var value9 = new CommentContent;
        reader.readMessage(value9,CommentContent.deserializeBinaryFromReader);
        msg.addComment(value9);
        break;
      case 10:
        var value10 = /** @type {number} */ (reader.readInt32());
        msg.setCommentCount(value10);
        break;
      case 11:
        var value11 = /** @type {number} */ (reader.readInt32());
        msg.setCommentListPage(value11);
        break;
      case 12:
        var value12 = /** @type {boolean} */ (reader.readBool());
        msg.setDeleted(value12);
        break;
      case 13:
        var value13 = /** @type {boolean} */ (reader.readBool());
        msg.setHasLiked(value13);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!CommentContent} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: CommentContent, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getCommentContextHash();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
    f = message.getAuthorDisplayName();
    if (f.length > 0) {
      writer.writeString(
        2,
        f
      );
    }
    f = message.getAuthorAvatarUrl();
    if (f.length > 0) {
      writer.writeString(
        3,
        f
      );
    }
    f = message.getTimestamp();
    if (f !== 0) {
      writer.writeInt64(
        4,
        f
      );
    }
    f = message.getText();
    if (f.length > 0) {
      writer.writeString(
        5,
        f
      );
    }
    f = message.getAuthorPersonView();
    if (f != null) {
      writer.writeMessage(
        6,
        f,
        gateway_person_view_pb.PersonViewMinimal.serializeBinaryToWriter
      );
    }
    f = message.getLikeCount();
    if (f !== 0) {
      writer.writeInt32(
        7,
        f
      );
    }
    f = message.getTextMentionRangeList();
    if (f.length > 0) {
      writer.writeRepeatedMessage(
        8,
        f,
        gateway_mention_pb.MentionRange.serializeBinaryToWriter
      );
    }
    f = message.getCommentList();
    if (f.length > 0) {
      writer.writeRepeatedMessage(
        9,
        f,
        CommentContent.serializeBinaryToWriter
      );
    }
    f = message.getCommentCount();
    if (f !== 0) {
      writer.writeInt32(
        10,
        f
      );
    }
    f = message.getCommentListPage();
    if (f !== 0) {
      writer.writeInt32(
        11,
        f
      );
    }
    f = message.getDeleted();
    if (f) {
      writer.writeBool(
        12,
        f
      );
    }
    f = message.getHasLiked();
    if (f) {
      writer.writeBool(
        13,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    CommentContent.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string comment_context_hash = 1;
   * @return {string}
   */
  getCommentContextHash(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setCommentContextHash(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


  /**
   * optional string author_display_name = 2;
   * @return {string}
   */
  getAuthorDisplayName(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
  };


  /** @param {string} value */
  setAuthorDisplayName(value: string) {
    jspb.Message.setProto3StringField(this, 2, value);
  }


  /**
   * optional string author_avatar_url = 3;
   * @return {string}
   */
  getAuthorAvatarUrl(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
  };


  /** @param {string} value */
  setAuthorAvatarUrl(value: string) {
    jspb.Message.setProto3StringField(this, 3, value);
  }


  /**
   * optional int64 timestamp = 4;
   * @return {number}
   */
  getTimestamp(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
  };


  /** @param {number} value */
  setTimestamp(value: number) {
    jspb.Message.setProto3IntField(this, 4, value);
  }


  /**
   * optional string text = 5;
   * @return {string}
   */
  getText(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
  };


  /** @param {string} value */
  setText(value: string) {
    jspb.Message.setProto3StringField(this, 5, value);
  }


  /**
   * optional PersonViewMinimal author_person_view = 6;
   * @return {?PersonViewMinimal}
   */
  getAuthorPersonView(): gateway_person_view_pb.PersonViewMinimal {
    return /** @type{?PersonViewMinimal} */ (
      jspb.Message.getWrapperField(this, gateway_person_view_pb.PersonViewMinimal, 6));
  }


  /** @param {?PersonViewMinimal|undefined} value */
  setAuthorPersonView(value?: gateway_person_view_pb.PersonViewMinimal) {
    jspb.Message.setWrapperField(this, 6, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearAuthorPersonView() {
    this.setAuthorPersonView(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasAuthorPersonView(): boolean {
    return jspb.Message.getField(this, 6) != null;
  }


  /**
   * optional int32 like_count = 7;
   * @return {number}
   */
  getLikeCount(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
  };


  /** @param {number} value */
  setLikeCount(value: number) {
    jspb.Message.setProto3IntField(this, 7, value);
  }


  /**
   * repeated MentionRange text_mention_range = 8;
   * @return {!Array<!MentionRange>}
   */
  getTextMentionRangeList(): gateway_mention_pb.MentionRange[] {
    return /** @type{!Array<!MentionRange>} */ (
      jspb.Message.getRepeatedWrapperField(this, gateway_mention_pb.MentionRange, 8));
  }


  /** @param {!Array<!MentionRange>} value */
  setTextMentionRangeList(value?: gateway_mention_pb.MentionRange[]) {
    jspb.Message.setRepeatedWrapperField(this, 8, value);
  }


  /**
   * @param {!MentionRange=} opt_value
   * @param {number=} opt_index
   * @return {!MentionRange}
   */
  addTextMentionRange(opt_value?: gateway_mention_pb.MentionRange, opt_index?: number): gateway_mention_pb.MentionRange {
    return jspb.Message.addToRepeatedWrapperField(this, 8, opt_value, gateway_mention_pb.MentionRange, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearTextMentionRangeList() {
    this.setTextMentionRangeList([]);
  }


  /**
   * repeated CommentContent comment = 9;
   * @return {!Array<!CommentContent>}
   */
  getCommentList(): CommentContent[] {
    return /** @type{!Array<!CommentContent>} */ (
      jspb.Message.getRepeatedWrapperField(this, CommentContent, 9));
  }


  /** @param {!Array<!CommentContent>} value */
  setCommentList(value?: CommentContent[]) {
    jspb.Message.setRepeatedWrapperField(this, 9, value);
  }


  /**
   * @param {!CommentContent=} opt_value
   * @param {number=} opt_index
   * @return {!CommentContent}
   */
  addComment(opt_value?: CommentContent, opt_index?: number): CommentContent {
    return jspb.Message.addToRepeatedWrapperField(this, 9, opt_value, CommentContent, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearCommentList() {
    this.setCommentList([]);
  }


  /**
   * optional int32 comment_count = 10;
   * @return {number}
   */
  getCommentCount(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
  };


  /** @param {number} value */
  setCommentCount(value: number) {
    jspb.Message.setProto3IntField(this, 10, value);
  }


  /**
   * optional int32 comment_list_page = 11;
   * @return {number}
   */
  getCommentListPage(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
  };


  /** @param {number} value */
  setCommentListPage(value: number) {
    jspb.Message.setProto3IntField(this, 11, value);
  }


  /**
   * optional bool deleted = 12;
   * @return {boolean}
   */
  getDeleted(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 12, false));
  };


  /** @param {boolean} value */
  setDeleted(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 12, value);
  }


  /**
   * optional bool has_liked = 13;
   * @return {boolean}
   */
  getHasLiked(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 13, false));
  };


  /** @param {boolean} value */
  setHasLiked(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 13, value);
  }


}
export namespace AddCommentRequest {
  export interface AsObject {
    contextHash: string;
    text: string;
    textMentionRangeList: gateway_mention_pb.MentionRange.AsObject[];
  }
}
export class AddCommentRequest extends jspb.Message {
  static readonly displayName = "AddCommentRequest";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [3];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, AddCommentRequest.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): AddCommentRequest.AsObject {
    return AddCommentRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!AddCommentRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: AddCommentRequest) {
    var f: any;
    var obj: any = {
      contextHash: jspb.Message.getFieldWithDefault(msg, 1, ""),
      text: jspb.Message.getFieldWithDefault(msg, 2, ""),
      textMentionRangeList: jspb.Message.toObjectList(msg.getTextMentionRangeList(),
      gateway_mention_pb.MentionRange.toObject, includeInstance)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!AddCommentRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new AddCommentRequest;
    return AddCommentRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!AddCommentRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!AddCommentRequest}
   */
  static deserializeBinaryFromReader(msg: AddCommentRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setContextHash(value1);
        break;
      case 2:
        var value2 = /** @type {string} */ (reader.readString());
        msg.setText(value2);
        break;
      case 3:
        var value3 = new gateway_mention_pb.MentionRange;
        reader.readMessage(value3,gateway_mention_pb.MentionRange.deserializeBinaryFromReader);
        msg.addTextMentionRange(value3);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!AddCommentRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: AddCommentRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getContextHash();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
    f = message.getText();
    if (f.length > 0) {
      writer.writeString(
        2,
        f
      );
    }
    f = message.getTextMentionRangeList();
    if (f.length > 0) {
      writer.writeRepeatedMessage(
        3,
        f,
        gateway_mention_pb.MentionRange.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    AddCommentRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string context_hash = 1;
   * @return {string}
   */
  getContextHash(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setContextHash(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


  /**
   * optional string text = 2;
   * @return {string}
   */
  getText(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
  };


  /** @param {string} value */
  setText(value: string) {
    jspb.Message.setProto3StringField(this, 2, value);
  }


  /**
   * repeated MentionRange text_mention_range = 3;
   * @return {!Array<!MentionRange>}
   */
  getTextMentionRangeList(): gateway_mention_pb.MentionRange[] {
    return /** @type{!Array<!MentionRange>} */ (
      jspb.Message.getRepeatedWrapperField(this, gateway_mention_pb.MentionRange, 3));
  }


  /** @param {!Array<!MentionRange>} value */
  setTextMentionRangeList(value?: gateway_mention_pb.MentionRange[]) {
    jspb.Message.setRepeatedWrapperField(this, 3, value);
  }


  /**
   * @param {!MentionRange=} opt_value
   * @param {number=} opt_index
   * @return {!MentionRange}
   */
  addTextMentionRange(opt_value?: gateway_mention_pb.MentionRange, opt_index?: number): gateway_mention_pb.MentionRange {
    return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, gateway_mention_pb.MentionRange, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearTextMentionRangeList() {
    this.setTextMentionRangeList([]);
  }


}
export namespace AddCommentResponse {
  export interface AsObject {
    status?: common_legacy_pb.StatusCode;
    reason?: common_legacy_pb.StatusReason.AsObject;
    newCommentContextHash: string;
    newCommentText: string;
    newCommentPersonView?: gateway_person_view_pb.PersonViewMinimal.AsObject;
    moderationResult?: gateway_moderation_pb.ModerationResult.AsObject;
  }
}
export class AddCommentResponse extends jspb.Message {
  static readonly displayName = "AddCommentResponse";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): AddCommentResponse.AsObject {
    return AddCommentResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!AddCommentResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: AddCommentResponse) {
    var f: any;
    var obj: any = {
      status: jspb.Message.getFieldWithDefault(msg, 1, 0),
      reason: (f = msg.getReason()) && common_legacy_pb.StatusReason.toObject(includeInstance, f),
      newCommentContextHash: jspb.Message.getFieldWithDefault(msg, 3, ""),
      newCommentText: jspb.Message.getFieldWithDefault(msg, 4, ""),
      newCommentPersonView: (f = msg.getNewCommentPersonView()) && gateway_person_view_pb.PersonViewMinimal.toObject(includeInstance, f),
      moderationResult: (f = msg.getModerationResult()) && gateway_moderation_pb.ModerationResult.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!AddCommentResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new AddCommentResponse;
    return AddCommentResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!AddCommentResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!AddCommentResponse}
   */
  static deserializeBinaryFromReader(msg: AddCommentResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {!StatusCode} */ (reader.readEnum());
        msg.setStatus(value1);
        break;
      case 2:
        var value2 = new common_legacy_pb.StatusReason;
        reader.readMessage(value2,common_legacy_pb.StatusReason.deserializeBinaryFromReader);
        msg.setReason(value2);
        break;
      case 3:
        var value3 = /** @type {string} */ (reader.readString());
        msg.setNewCommentContextHash(value3);
        break;
      case 4:
        var value4 = /** @type {string} */ (reader.readString());
        msg.setNewCommentText(value4);
        break;
      case 5:
        var value5 = new gateway_person_view_pb.PersonViewMinimal;
        reader.readMessage(value5,gateway_person_view_pb.PersonViewMinimal.deserializeBinaryFromReader);
        msg.setNewCommentPersonView(value5);
        break;
      case 6:
        var value6 = new gateway_moderation_pb.ModerationResult;
        reader.readMessage(value6,gateway_moderation_pb.ModerationResult.deserializeBinaryFromReader);
        msg.setModerationResult(value6);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!AddCommentResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: AddCommentResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getStatus();
    if (f !== 0.0) {
      writer.writeEnum(
        1,
        f
      );
    }
    f = message.getReason();
    if (f != null) {
      writer.writeMessage(
        2,
        f,
        common_legacy_pb.StatusReason.serializeBinaryToWriter
      );
    }
    f = message.getNewCommentContextHash();
    if (f.length > 0) {
      writer.writeString(
        3,
        f
      );
    }
    f = message.getNewCommentText();
    if (f.length > 0) {
      writer.writeString(
        4,
        f
      );
    }
    f = message.getNewCommentPersonView();
    if (f != null) {
      writer.writeMessage(
        5,
        f,
        gateway_person_view_pb.PersonViewMinimal.serializeBinaryToWriter
      );
    }
    f = message.getModerationResult();
    if (f != null) {
      writer.writeMessage(
        6,
        f,
        gateway_moderation_pb.ModerationResult.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    AddCommentResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional StatusCode status = 1;
   * @return {!StatusCode}
   */
  getStatus(): common_legacy_pb.StatusCode {
    return /** @type {!StatusCode} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
  };


  /** @param {!StatusCode} value */
  setStatus(value: common_legacy_pb.StatusCode) {
    jspb.Message.setProto3EnumField(this, 1, value);
  }


  /**
   * optional StatusReason reason = 2;
   * @return {?StatusReason}
   */
  getReason(): common_legacy_pb.StatusReason {
    return /** @type{?StatusReason} */ (
      jspb.Message.getWrapperField(this, common_legacy_pb.StatusReason, 2));
  }


  /** @param {?StatusReason|undefined} value */
  setReason(value?: common_legacy_pb.StatusReason) {
    jspb.Message.setWrapperField(this, 2, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearReason() {
    this.setReason(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasReason(): boolean {
    return jspb.Message.getField(this, 2) != null;
  }


  /**
   * optional string new_comment_context_hash = 3;
   * @return {string}
   */
  getNewCommentContextHash(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
  };


  /** @param {string} value */
  setNewCommentContextHash(value: string) {
    jspb.Message.setProto3StringField(this, 3, value);
  }


  /**
   * optional string new_comment_text = 4;
   * @return {string}
   */
  getNewCommentText(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
  };


  /** @param {string} value */
  setNewCommentText(value: string) {
    jspb.Message.setProto3StringField(this, 4, value);
  }


  /**
   * optional PersonViewMinimal new_comment_person_view = 5;
   * @return {?PersonViewMinimal}
   */
  getNewCommentPersonView(): gateway_person_view_pb.PersonViewMinimal {
    return /** @type{?PersonViewMinimal} */ (
      jspb.Message.getWrapperField(this, gateway_person_view_pb.PersonViewMinimal, 5));
  }


  /** @param {?PersonViewMinimal|undefined} value */
  setNewCommentPersonView(value?: gateway_person_view_pb.PersonViewMinimal) {
    jspb.Message.setWrapperField(this, 5, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearNewCommentPersonView() {
    this.setNewCommentPersonView(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasNewCommentPersonView(): boolean {
    return jspb.Message.getField(this, 5) != null;
  }


  /**
   * optional minga.ModerationResult moderation_result = 6;
   * @return {?ModerationResult}
   */
  getModerationResult(): gateway_moderation_pb.ModerationResult {
    return /** @type{?ModerationResult} */ (
      jspb.Message.getWrapperField(this, gateway_moderation_pb.ModerationResult, 6));
  }


  /** @param {?ModerationResult|undefined} value */
  setModerationResult(value?: gateway_moderation_pb.ModerationResult) {
    jspb.Message.setWrapperField(this, 6, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearModerationResult() {
    this.setModerationResult(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasModerationResult(): boolean {
    return jspb.Message.getField(this, 6) != null;
  }


}
export namespace FetchCommentsRequest {
  export interface AsObject {
    contextHash: string;
    page: number;
  }
}
export class FetchCommentsRequest extends jspb.Message {
  static readonly displayName = "FetchCommentsRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): FetchCommentsRequest.AsObject {
    return FetchCommentsRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!FetchCommentsRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: FetchCommentsRequest) {
    var f: any;
    var obj: any = {
      contextHash: jspb.Message.getFieldWithDefault(msg, 1, ""),
      page: jspb.Message.getFieldWithDefault(msg, 2, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!FetchCommentsRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new FetchCommentsRequest;
    return FetchCommentsRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!FetchCommentsRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!FetchCommentsRequest}
   */
  static deserializeBinaryFromReader(msg: FetchCommentsRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setContextHash(value1);
        break;
      case 2:
        var value2 = /** @type {number} */ (reader.readInt32());
        msg.setPage(value2);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!FetchCommentsRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: FetchCommentsRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getContextHash();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
    f = message.getPage();
    if (f !== 0) {
      writer.writeInt32(
        2,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    FetchCommentsRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string context_hash = 1;
   * @return {string}
   */
  getContextHash(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setContextHash(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


  /**
   * optional int32 page = 2;
   * @return {number}
   */
  getPage(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
  };


  /** @param {number} value */
  setPage(value: number) {
    jspb.Message.setProto3IntField(this, 2, value);
  }


}
export namespace FetchCommentsResponse {
  export interface AsObject {
    status?: common_legacy_pb.StatusCode;
    reason?: common_legacy_pb.StatusReason.AsObject;
    commentList: CommentContent.AsObject[];
  }
}
export class FetchCommentsResponse extends jspb.Message {
  static readonly displayName = "FetchCommentsResponse";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [3];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, FetchCommentsResponse.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): FetchCommentsResponse.AsObject {
    return FetchCommentsResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!FetchCommentsResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: FetchCommentsResponse) {
    var f: any;
    var obj: any = {
      status: jspb.Message.getFieldWithDefault(msg, 1, 0),
      reason: (f = msg.getReason()) && common_legacy_pb.StatusReason.toObject(includeInstance, f),
      commentList: jspb.Message.toObjectList(msg.getCommentList(),
      CommentContent.toObject, includeInstance)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!FetchCommentsResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new FetchCommentsResponse;
    return FetchCommentsResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!FetchCommentsResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!FetchCommentsResponse}
   */
  static deserializeBinaryFromReader(msg: FetchCommentsResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {!StatusCode} */ (reader.readEnum());
        msg.setStatus(value1);
        break;
      case 2:
        var value2 = new common_legacy_pb.StatusReason;
        reader.readMessage(value2,common_legacy_pb.StatusReason.deserializeBinaryFromReader);
        msg.setReason(value2);
        break;
      case 3:
        var value3 = new CommentContent;
        reader.readMessage(value3,CommentContent.deserializeBinaryFromReader);
        msg.addComment(value3);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!FetchCommentsResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: FetchCommentsResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getStatus();
    if (f !== 0.0) {
      writer.writeEnum(
        1,
        f
      );
    }
    f = message.getReason();
    if (f != null) {
      writer.writeMessage(
        2,
        f,
        common_legacy_pb.StatusReason.serializeBinaryToWriter
      );
    }
    f = message.getCommentList();
    if (f.length > 0) {
      writer.writeRepeatedMessage(
        3,
        f,
        CommentContent.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    FetchCommentsResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional StatusCode status = 1;
   * @return {!StatusCode}
   */
  getStatus(): common_legacy_pb.StatusCode {
    return /** @type {!StatusCode} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
  };


  /** @param {!StatusCode} value */
  setStatus(value: common_legacy_pb.StatusCode) {
    jspb.Message.setProto3EnumField(this, 1, value);
  }


  /**
   * optional StatusReason reason = 2;
   * @return {?StatusReason}
   */
  getReason(): common_legacy_pb.StatusReason {
    return /** @type{?StatusReason} */ (
      jspb.Message.getWrapperField(this, common_legacy_pb.StatusReason, 2));
  }


  /** @param {?StatusReason|undefined} value */
  setReason(value?: common_legacy_pb.StatusReason) {
    jspb.Message.setWrapperField(this, 2, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearReason() {
    this.setReason(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasReason(): boolean {
    return jspb.Message.getField(this, 2) != null;
  }


  /**
   * repeated CommentContent comment = 3;
   * @return {!Array<!CommentContent>}
   */
  getCommentList(): CommentContent[] {
    return /** @type{!Array<!CommentContent>} */ (
      jspb.Message.getRepeatedWrapperField(this, CommentContent, 3));
  }


  /** @param {!Array<!CommentContent>} value */
  setCommentList(value?: CommentContent[]) {
    jspb.Message.setRepeatedWrapperField(this, 3, value);
  }


  /**
   * @param {!CommentContent=} opt_value
   * @param {number=} opt_index
   * @return {!CommentContent}
   */
  addComment(opt_value?: CommentContent, opt_index?: number): CommentContent {
    return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, CommentContent, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearCommentList() {
    this.setCommentList([]);
  }


}
