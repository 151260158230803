import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';

import { ClippedHtmlModule } from '@app/src/app/components/ClippedHtml';
import { MgContentLinkModule } from '@app/src/app/content-link';
import { MgEllipsisModule } from '@app/src/app/elements/MgEllipsis';
import { MgShortCardModule } from '@app/src/app/elements/MgShortCard';
import { MgeIconModule } from '@app/src/app/elements/MgeIcon';
import { UgcLinksHighlightPipeModule } from '@app/src/app/ugc-links/pipes/UgcLinksHighlight';

import { MgAnnouncementShortCardElement } from './MgAnnouncementShortCard.element';

@NgModule({
  imports: [
    // Minga dependencies
    MgShortCardModule,
    MgEllipsisModule,
    MgeIconModule,
    MgContentLinkModule,
    ClippedHtmlModule,
    UgcLinksHighlightPipeModule,

    // External dependencies
    CommonModule,
    FlexLayoutModule,
  ],
  declarations: [MgAnnouncementShortCardElement],
  exports: [MgAnnouncementShortCardElement],
})
export class MgAnnouncementShortCardModule {}
