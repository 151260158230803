import { day } from 'libs/day';
import { HallPassStatusEnum, HallPassStudentCreatedFilter } from 'libs/domain';
import { HpmReportsFilters } from 'libs/domain';
import { hall_pass_stats_pb } from 'libs/generated-grpc-ts';
import { $enum } from 'ts-enum-util';

import { dateObjectToDateTimeMessage, dateTimeMessageToDayjsObject } from '../';

export const toProto = (
  filter: HpmReportsFilters,
  limit?: number,
  offset?: number,
): hall_pass_stats_pb.HallPassReportFilters => {
  const filterProto = new hall_pass_stats_pb.HallPassReportFilters();

  filterProto.setStudentCreated(filter.createdBy);
  filterProto.setIssuedByList(filter.issuedBy);
  filterProto.setIssuedToList(filter.issuedTo);
  filterProto.setPassTypeList(filter.hallpassType);
  filterProto.setUserListList(filter.userList);
  if (filter.startDate)
    filterProto.setStartDate(
      dateObjectToDateTimeMessage(filter.startDate.toDate()),
    );
  if (filter.endDate)
    filterProto.setEndDate(
      dateObjectToDateTimeMessage(filter.endDate.toDate()),
    );
  if (filter.status?.length) filterProto.setStatusList(filter.status);
  if (filter.deniedBy?.length) filterProto.setDeniedByList(filter.deniedBy);
  if (filter.deniedByBlackout?.length)
    filterProto.setDeniedByBlackoutList(filter.deniedByBlackout);
  if (filter.deniedByNoParty?.length)
    filterProto.setDeniedByNoPartyList(filter.deniedByNoParty);

  if (limit) filterProto.setLimit(limit);
  if (offset) filterProto.setOffset(offset);
  return filterProto;
};

export const fromProto = (
  proto: hall_pass_stats_pb.HallPassReportFilters,
): HpmReportsFilters => {
  const startDateProto = proto.getStartDate();
  const endDateProto = proto.getEndDate();
  let startDate: day.Dayjs | undefined;
  let endDate: day.Dayjs | undefined;
  if (startDateProto) startDate = dateTimeMessageToDayjsObject(startDateProto);
  if (endDateProto) endDate = dateTimeMessageToDayjsObject(endDateProto);

  const statusList = proto.getStatusList();
  const status: HallPassStatusEnum[] = [];
  if (statusList?.length) {
    statusList.forEach(statusProto => {
      status.push($enum(HallPassStatusEnum).asValueOrThrow(statusProto));
    });
  }
  return {
    startDate,
    endDate,
    createdBy: $enum(HallPassStudentCreatedFilter).asValueOrThrow(
      proto.getStudentCreated(),
    ),
    issuedBy: proto.getIssuedByList(),
    issuedTo: proto.getIssuedToList(),
    hallpassType: proto.getPassTypeList(),
    limit: proto.getLimit(),
    offset: proto.getOffset(),
    userList: proto.getUserListList(),
    status,
    deniedBy: proto.getDeniedByList(),
    deniedByBlackout: proto.getDeniedByBlackoutList(),
    deniedByNoParty: proto.getDeniedByNoPartyList(),
  };
};
