import { Injectable, NgZone } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { grpc } from '@improbable-eng/grpc-web';

import {
  MingaAddContent,
  MingaAddProgram,
  MingaContentResult,
  MingaMoveContent,
  MingaProgramResult,
  MingaRemoveContent,
  MingaRemoveProgram,
  MingaSetCommentingRolesRequest,
  MingaSetCommentingRolesResult,
  MingaUnpinContent,
  MingaUnpinContentResult,
} from '../content/minga_pb';

import {
  MingaContent as __MingaContent,
} from '../content/minga_pb_service';

/**
  *
 Service for managing the current mingas content.
  */
@Injectable({providedIn: 'root'})
export class MingaContent {

  constructor(private _ngZone: NgZone) {
  }

  /**
    Add program to current minga
  */
  addProgram(request: MingaAddProgram): Promise<MingaProgramResult>;
  /**
    Add program to current minga
  */
  addProgram(request: MingaAddProgram, metadata: grpc.Metadata): Promise<MingaProgramResult>;
  /**
    Add program to current minga
  */
  addProgram(request: MingaAddProgram, callback: (err: any|null, response: MingaProgramResult, metadata: grpc.Metadata) => void): void;
  /**
    Add program to current minga
  */
  addProgram(request: MingaAddProgram, metadata: grpc.Metadata, callback: (err: any|null, response: MingaProgramResult, metadata: grpc.Metadata) => void): void;

  addProgram(request: MingaAddProgram, arg1?: grpc.Metadata|((err: any|null, response: MingaProgramResult, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: MingaProgramResult, metadata: grpc.Metadata) => void): Promise<MingaProgramResult>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<MingaProgramResult>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__MingaContent.AddProgram, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  /**
    Remove program from current minga
  */
  removeProgram(request: MingaRemoveProgram): Promise<MingaProgramResult>;
  /**
    Remove program from current minga
  */
  removeProgram(request: MingaRemoveProgram, metadata: grpc.Metadata): Promise<MingaProgramResult>;
  /**
    Remove program from current minga
  */
  removeProgram(request: MingaRemoveProgram, callback: (err: any|null, response: MingaProgramResult, metadata: grpc.Metadata) => void): void;
  /**
    Remove program from current minga
  */
  removeProgram(request: MingaRemoveProgram, metadata: grpc.Metadata, callback: (err: any|null, response: MingaProgramResult, metadata: grpc.Metadata) => void): void;

  removeProgram(request: MingaRemoveProgram, arg1?: grpc.Metadata|((err: any|null, response: MingaProgramResult, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: MingaProgramResult, metadata: grpc.Metadata) => void): Promise<MingaProgramResult>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<MingaProgramResult>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__MingaContent.RemoveProgram, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  /**
    Add content to current minga
  */
  addContent(request: MingaAddContent): Promise<MingaContentResult>;
  /**
    Add content to current minga
  */
  addContent(request: MingaAddContent, metadata: grpc.Metadata): Promise<MingaContentResult>;
  /**
    Add content to current minga
  */
  addContent(request: MingaAddContent, callback: (err: any|null, response: MingaContentResult, metadata: grpc.Metadata) => void): void;
  /**
    Add content to current minga
  */
  addContent(request: MingaAddContent, metadata: grpc.Metadata, callback: (err: any|null, response: MingaContentResult, metadata: grpc.Metadata) => void): void;

  addContent(request: MingaAddContent, arg1?: grpc.Metadata|((err: any|null, response: MingaContentResult, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: MingaContentResult, metadata: grpc.Metadata) => void): Promise<MingaContentResult>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<MingaContentResult>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__MingaContent.AddContent, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  removeContent(request: MingaRemoveContent): Promise<MingaContentResult>;
  removeContent(request: MingaRemoveContent, metadata: grpc.Metadata): Promise<MingaContentResult>;
  removeContent(request: MingaRemoveContent, callback: (err: any|null, response: MingaContentResult, metadata: grpc.Metadata) => void): void;
  removeContent(request: MingaRemoveContent, metadata: grpc.Metadata, callback: (err: any|null, response: MingaContentResult, metadata: grpc.Metadata) => void): void;

  removeContent(request: MingaRemoveContent, arg1?: grpc.Metadata|((err: any|null, response: MingaContentResult, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: MingaContentResult, metadata: grpc.Metadata) => void): Promise<MingaContentResult>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<MingaContentResult>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__MingaContent.RemoveContent, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  /**
    Move content by adjusting its publish date(s) or pin date
  */
  moveContent(request: MingaMoveContent): Promise<MingaContentResult>;
  /**
    Move content by adjusting its publish date(s) or pin date
  */
  moveContent(request: MingaMoveContent, metadata: grpc.Metadata): Promise<MingaContentResult>;
  /**
    Move content by adjusting its publish date(s) or pin date
  */
  moveContent(request: MingaMoveContent, callback: (err: any|null, response: MingaContentResult, metadata: grpc.Metadata) => void): void;
  /**
    Move content by adjusting its publish date(s) or pin date
  */
  moveContent(request: MingaMoveContent, metadata: grpc.Metadata, callback: (err: any|null, response: MingaContentResult, metadata: grpc.Metadata) => void): void;

  moveContent(request: MingaMoveContent, arg1?: grpc.Metadata|((err: any|null, response: MingaContentResult, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: MingaContentResult, metadata: grpc.Metadata) => void): Promise<MingaContentResult>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<MingaContentResult>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__MingaContent.MoveContent, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  unpinContent(request: MingaUnpinContent): Promise<MingaUnpinContentResult>;
  unpinContent(request: MingaUnpinContent, metadata: grpc.Metadata): Promise<MingaUnpinContentResult>;
  unpinContent(request: MingaUnpinContent, callback: (err: any|null, response: MingaUnpinContentResult, metadata: grpc.Metadata) => void): void;
  unpinContent(request: MingaUnpinContent, metadata: grpc.Metadata, callback: (err: any|null, response: MingaUnpinContentResult, metadata: grpc.Metadata) => void): void;

  unpinContent(request: MingaUnpinContent, arg1?: grpc.Metadata|((err: any|null, response: MingaUnpinContentResult, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: MingaUnpinContentResult, metadata: grpc.Metadata) => void): Promise<MingaUnpinContentResult>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<MingaUnpinContentResult>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__MingaContent.UnpinContent, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  setCommentingRoles(request: MingaSetCommentingRolesRequest): Promise<MingaSetCommentingRolesResult>;
  setCommentingRoles(request: MingaSetCommentingRolesRequest, metadata: grpc.Metadata): Promise<MingaSetCommentingRolesResult>;
  setCommentingRoles(request: MingaSetCommentingRolesRequest, callback: (err: any|null, response: MingaSetCommentingRolesResult, metadata: grpc.Metadata) => void): void;
  setCommentingRoles(request: MingaSetCommentingRolesRequest, metadata: grpc.Metadata, callback: (err: any|null, response: MingaSetCommentingRolesResult, metadata: grpc.Metadata) => void): void;

  setCommentingRoles(request: MingaSetCommentingRolesRequest, arg1?: grpc.Metadata|((err: any|null, response: MingaSetCommentingRolesResult, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: MingaSetCommentingRolesResult, metadata: grpc.Metadata) => void): Promise<MingaSetCommentingRolesResult>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<MingaSetCommentingRolesResult>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__MingaContent.SetCommentingRoles, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

}

