import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import dayjs, * as day from 'dayjs';
import { FlexTimeActivityInstance, MinimalFlexTimePeriod } from 'libs/domain';
import { MingaPermission } from 'libs/domain';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';

import { RootService } from '@app/src/app/minimal/services/RootService';
import { PermissionsService } from '@app/src/app/permissions';

import {
  FlexTimePeriodWithRegistration,
  Schedule,
} from '@modules/tools/student-tools';
import { StudentToolsFlexTimeService } from '@modules/tools/student-tools/services';

import {
  SystemAlertCloseEvents,
  SystemAlertModalService,
  SystemAlertModalType,
} from '@shared/components/system-alert-modal';
import {
  FlexTimeActivityInstanceService,
  FlexTimePermissionsService,
} from '@shared/services/flex-time';
import { FlexTimeRegistrationValidationService } from '@shared/services/flex-time/flex-time-registration-validation.service';
import { MediaService } from '@shared/services/media';

import {
  ActivityCardFields,
  FtmSharedMessages,
} from '../../ftm-shared.constants';

interface DailySchedule {
  date: string;
  periods: FlexTimePeriodWithRegistration[];
}

@Component({
  selector: 'mg-ftm-activites-student-scheduler',
  templateUrl: './ftm-activites-student-scheduler.component.html',
  styleUrls: ['./ftm-activites-student-scheduler.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FtmActivitesStudentSchedulerComponent implements OnInit {
  @ViewChild('detailedActivityInformationTemplate')
  detailedActivityInformationTemplate: TemplateRef<any>;

  // Inputs

  @Input() set schedule(value: Schedule) {
    this._dailySchedulesSubject.next(
      this._groupByDate(value.flexTimePeriodWithRegistration),
    );
  }
  @Input() personHash: string;

  // Outputs
  @Output() activityRegistered = new EventEmitter<FlexTimeActivityInstance>();
  public MESSAGES = FtmSharedMessages;
  public CARD_FIELDS = [
    ActivityCardFields.NAME,
    ActivityCardFields.DESCRIPTION,
    ActivityCardFields.LOCATION,
    ActivityCardFields.TEACHER,
  ];

  private _periodRegistrationInProgess = new BehaviorSubject<number>(null);
  public periodRegistrationInProgess$ =
    this._periodRegistrationInProgess.asObservable();
  public isProfileView$ = this._route.paramMap.pipe(
    map(params => params.has('hash')),
  );

  private _dailySchedulesSubject = new BehaviorSubject<DailySchedule[]>([]);
  public readonly dailySchedules$ = this._dailySchedulesSubject.asObservable();

  // Component Constructor

  constructor(
    public media: MediaService,
    private _route: ActivatedRoute,
    private _systemAlertModal: SystemAlertModalService,
    private _stFlexTimeService: StudentToolsFlexTimeService,
    private _flexActivityInstanceService: FlexTimeActivityInstanceService,
    private _flexTimePerms: FlexTimePermissionsService,
    private _flexRegService: FlexTimeRegistrationValidationService,
    private _permissions: PermissionsService,
    private _root: RootService,
  ) {}

  // Lifecycle Hooks

  ngOnInit(): void {
    if (this.schedule && this.schedule.flexTimePeriodWithRegistration) {
      this._dailySchedulesSubject.next(
        this._groupByDate(this.schedule.flexTimePeriodWithRegistration),
      );
    }
  }

  // Public Methods

  public async openActivities(
    periodId: number,
    currentActivity?: FlexTimeActivityInstance,
  ) {
    const currentActivityId = currentActivity?.id || null;

    try {
      const activityInstance =
        await this._flexActivityInstanceService.openActivitySelector({
          periodId,
          currentActivityId,
          selectorType: 'register',
        });

      this._periodRegistrationInProgess.next(periodId);
      if (!this._flexTimePerms.isFlexTimeAdmin()) {
        // emit here bc _flexRegService has its own confirm modal
        await this._root.addLoadingPromise(
          this._stFlexTimeService.registerForActivity(activityInstance.id),
        );
        this.activityRegistered.emit(activityInstance);
      } else {
        await this._flexRegService.register({
          activityId: activityInstance.id,
          periodId,
          bypassRestrictions: false,
          hashes: [this.personHash],
        });
      }
    } catch (e) {
      const error = e as Error;
      const systemAlertModal = await this._systemAlertModal.open({
        modalType: SystemAlertModalType.ERROR,
        heading: 'Cannot register for activity',
        message: error.message,
        closeBtn: 'Close',
      });
      await systemAlertModal.afterClosed().toPromise();
    } finally {
      this._periodRegistrationInProgess.next(null);
    }
  }

  public async confirmChange(
    periodId: number,
    currentActivity: FlexTimeActivityInstance,
  ) {
    const systemAlertModal = await this._systemAlertModal.open({
      modalType: SystemAlertModalType.DEFAULT,
      heading: FtmSharedMessages.CHANGE_CONFIRM_TITLE,
      message: FtmSharedMessages.CHANGE_CONFIRM_DESC,
      confirmActionBtn: FtmSharedMessages.CHANGE_CONFIRM_BTN,
      closeBtn: FtmSharedMessages.CHANGE_CLOSE_BTN,
    });

    systemAlertModal.afterClosed().subscribe(response => {
      if (response?.type === SystemAlertCloseEvents.CONFIRM) {
        this.openActivities(periodId, currentActivity);
      }
    });
  }

  public isPeriodOver(data: MinimalFlexTimePeriod): boolean {
    const date = day(data.date).format('MMM DD YYYY');
    const parsed = `${date} ${data.endTime}`;

    const periodDate = day(parsed, 'MMM DD YYYY HH:mm:ss');

    return periodDate.isBefore(day(), 'minute');
  }

  public async openActivityModal(period: FlexTimePeriodWithRegistration) {
    const activity = period.myRegistration.registration.flexTimeActivity;
    const canRegister = this._canRegister(period);

    const systemAlertModal = await this._systemAlertModal.open({
      icon: 'mg-flextime-menu-o',
      iconColor: 'success',
      heading: activity.name,
      message: activity.description,
      confirmActionBtn: canRegister && 'Change',
      closeBtn: canRegister ? 'Cancel' : 'Close',
      templateRef: this.detailedActivityInformationTemplate,
      templateContext: {
        period,
      },
    });

    const result = await systemAlertModal.afterClosed().toPromise();
    if (result?.type === SystemAlertCloseEvents.CONFIRM) {
      this.openActivities(period.id);
    }
  }

  // Private Methods

  private _canRegister(periodInformation: FlexTimePeriodWithRegistration) {
    if (this.isPeriodOver(periodInformation)) return false;

    const flexTimeManagePermission = this._permissions.hasPermission(
      MingaPermission.FLEX_TIME_MANAGE,
    );
    const flexTimeViewMyActivityPermission = this._permissions.hasPermission(
      MingaPermission.FLEX_TIME_VIEW_MY_ACTIVITY,
    );

    return (
      flexTimeManagePermission ||
      (!flexTimeViewMyActivityPermission &&
        periodInformation.enableStudentSelfManagement &&
        periodInformation.myRegistration &&
        !periodInformation.myRegistration.cannotUnregister &&
        this._route.paramMap.pipe(map(params => params.has('hash'))))
    );
  }

  private _groupByDate(periods: any[]): { date: string; periods: any[] }[] {
    const grouped = periods.reduce((acc, period) => {
      const date = dayjs(period.date).format('dddd, MMM DD');
      if (!acc[date]) {
        acc[date] = [];
      }
      acc[date].push(period);
      return acc;
    }, {});

    return Object.keys(grouped).map(date => ({
      date,
      periods: grouped[date],
    }));
  }
}
