import { Injectable, NgZone } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { grpc } from '@improbable-eng/grpc-web';

import {
  AddDefaultPbisTypesRequest,
  AddDefaultPbisTypesResponse,
  CompleteConsequenceRequest,
  CompleteConsequenceResponse,
  CreateBehaviorRequest,
  CreateBehaviorResponse,
  CreateConsequenceRequest,
  CreateConsequenceResponse,
  DeleteBehaviorRequest,
  DeleteBehaviorResponse,
  DeleteConsequenceRequest,
  DeleteConsequenceTypeRequest,
  DeleteConsequenceTypeResponse,
  DeleteTypeReponse,
  DeleteTypeRequest,
  GetBehaviorRequest,
  GetBehaviorResponse,
  GetConsequenceTypesRequest,
  GetConsequenceTypesResponse,
  GetStudentToolsBehaviorsRequest,
  GetStudentToolsBehaviorsResponse,
  GetTypeRequest,
  GetTypeResponse,
  GetTypesRequest,
  GetTypesResponse,
  ImportBehaviorsRequest,
  ImportBehaviorsResponse,
  UpdateBehaviorRequest,
  UpdateBehaviorResponse,
  UpdateConsequenceRequest,
  UpdateConsequenceResponse,
  UpdateConsequenceTypeRequest,
  UpdateConsequenceTypeResponse,
  UpdateTypeReponse,
  UpdateTypeRequest,
} from '../pbis/pbis_pb';

import {
  PbisManager as __PbisManager,
} from '../pbis/pbis_pb_service';

@Injectable({providedIn: 'root'})
export class PbisManager {

  constructor(private _ngZone: NgZone) {
  }

  getType(request: GetTypeRequest): Promise<GetTypeResponse>;
  getType(request: GetTypeRequest, metadata: grpc.Metadata): Promise<GetTypeResponse>;
  getType(request: GetTypeRequest, callback: (err: any|null, response: GetTypeResponse, metadata: grpc.Metadata) => void): void;
  getType(request: GetTypeRequest, metadata: grpc.Metadata, callback: (err: any|null, response: GetTypeResponse, metadata: grpc.Metadata) => void): void;

  getType(request: GetTypeRequest, arg1?: grpc.Metadata|((err: any|null, response: GetTypeResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: GetTypeResponse, metadata: grpc.Metadata) => void): Promise<GetTypeResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<GetTypeResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__PbisManager.GetType, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  getTypes(request: GetTypesRequest): Promise<GetTypesResponse>;
  getTypes(request: GetTypesRequest, metadata: grpc.Metadata): Promise<GetTypesResponse>;
  getTypes(request: GetTypesRequest, callback: (err: any|null, response: GetTypesResponse, metadata: grpc.Metadata) => void): void;
  getTypes(request: GetTypesRequest, metadata: grpc.Metadata, callback: (err: any|null, response: GetTypesResponse, metadata: grpc.Metadata) => void): void;

  getTypes(request: GetTypesRequest, arg1?: grpc.Metadata|((err: any|null, response: GetTypesResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: GetTypesResponse, metadata: grpc.Metadata) => void): Promise<GetTypesResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<GetTypesResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__PbisManager.GetTypes, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  updateType(request: UpdateTypeRequest): Promise<UpdateTypeReponse>;
  updateType(request: UpdateTypeRequest, metadata: grpc.Metadata): Promise<UpdateTypeReponse>;
  updateType(request: UpdateTypeRequest, callback: (err: any|null, response: UpdateTypeReponse, metadata: grpc.Metadata) => void): void;
  updateType(request: UpdateTypeRequest, metadata: grpc.Metadata, callback: (err: any|null, response: UpdateTypeReponse, metadata: grpc.Metadata) => void): void;

  updateType(request: UpdateTypeRequest, arg1?: grpc.Metadata|((err: any|null, response: UpdateTypeReponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: UpdateTypeReponse, metadata: grpc.Metadata) => void): Promise<UpdateTypeReponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<UpdateTypeReponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__PbisManager.UpdateType, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  deleteType(request: DeleteTypeRequest): Promise<DeleteTypeReponse>;
  deleteType(request: DeleteTypeRequest, metadata: grpc.Metadata): Promise<DeleteTypeReponse>;
  deleteType(request: DeleteTypeRequest, callback: (err: any|null, response: DeleteTypeReponse, metadata: grpc.Metadata) => void): void;
  deleteType(request: DeleteTypeRequest, metadata: grpc.Metadata, callback: (err: any|null, response: DeleteTypeReponse, metadata: grpc.Metadata) => void): void;

  deleteType(request: DeleteTypeRequest, arg1?: grpc.Metadata|((err: any|null, response: DeleteTypeReponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: DeleteTypeReponse, metadata: grpc.Metadata) => void): Promise<DeleteTypeReponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<DeleteTypeReponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__PbisManager.DeleteType, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  addDefaultPbisTypes(request: AddDefaultPbisTypesRequest): Promise<AddDefaultPbisTypesResponse>;
  addDefaultPbisTypes(request: AddDefaultPbisTypesRequest, metadata: grpc.Metadata): Promise<AddDefaultPbisTypesResponse>;
  addDefaultPbisTypes(request: AddDefaultPbisTypesRequest, callback: (err: any|null, response: AddDefaultPbisTypesResponse, metadata: grpc.Metadata) => void): void;
  addDefaultPbisTypes(request: AddDefaultPbisTypesRequest, metadata: grpc.Metadata, callback: (err: any|null, response: AddDefaultPbisTypesResponse, metadata: grpc.Metadata) => void): void;

  addDefaultPbisTypes(request: AddDefaultPbisTypesRequest, arg1?: grpc.Metadata|((err: any|null, response: AddDefaultPbisTypesResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: AddDefaultPbisTypesResponse, metadata: grpc.Metadata) => void): Promise<AddDefaultPbisTypesResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<AddDefaultPbisTypesResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__PbisManager.AddDefaultPbisTypes, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  createBehavior(request: CreateBehaviorRequest): Promise<CreateBehaviorResponse>;
  createBehavior(request: CreateBehaviorRequest, metadata: grpc.Metadata): Promise<CreateBehaviorResponse>;
  createBehavior(request: CreateBehaviorRequest, callback: (err: any|null, response: CreateBehaviorResponse, metadata: grpc.Metadata) => void): void;
  createBehavior(request: CreateBehaviorRequest, metadata: grpc.Metadata, callback: (err: any|null, response: CreateBehaviorResponse, metadata: grpc.Metadata) => void): void;

  createBehavior(request: CreateBehaviorRequest, arg1?: grpc.Metadata|((err: any|null, response: CreateBehaviorResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: CreateBehaviorResponse, metadata: grpc.Metadata) => void): Promise<CreateBehaviorResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<CreateBehaviorResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__PbisManager.CreateBehavior, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  updateConsequenceType(request: UpdateConsequenceTypeRequest): Promise<UpdateConsequenceTypeResponse>;
  updateConsequenceType(request: UpdateConsequenceTypeRequest, metadata: grpc.Metadata): Promise<UpdateConsequenceTypeResponse>;
  updateConsequenceType(request: UpdateConsequenceTypeRequest, callback: (err: any|null, response: UpdateConsequenceTypeResponse, metadata: grpc.Metadata) => void): void;
  updateConsequenceType(request: UpdateConsequenceTypeRequest, metadata: grpc.Metadata, callback: (err: any|null, response: UpdateConsequenceTypeResponse, metadata: grpc.Metadata) => void): void;

  updateConsequenceType(request: UpdateConsequenceTypeRequest, arg1?: grpc.Metadata|((err: any|null, response: UpdateConsequenceTypeResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: UpdateConsequenceTypeResponse, metadata: grpc.Metadata) => void): Promise<UpdateConsequenceTypeResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<UpdateConsequenceTypeResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__PbisManager.UpdateConsequenceType, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  deleteConsequenceType(request: DeleteConsequenceTypeRequest): Promise<DeleteConsequenceTypeResponse>;
  deleteConsequenceType(request: DeleteConsequenceTypeRequest, metadata: grpc.Metadata): Promise<DeleteConsequenceTypeResponse>;
  deleteConsequenceType(request: DeleteConsequenceTypeRequest, callback: (err: any|null, response: DeleteConsequenceTypeResponse, metadata: grpc.Metadata) => void): void;
  deleteConsequenceType(request: DeleteConsequenceTypeRequest, metadata: grpc.Metadata, callback: (err: any|null, response: DeleteConsequenceTypeResponse, metadata: grpc.Metadata) => void): void;

  deleteConsequenceType(request: DeleteConsequenceTypeRequest, arg1?: grpc.Metadata|((err: any|null, response: DeleteConsequenceTypeResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: DeleteConsequenceTypeResponse, metadata: grpc.Metadata) => void): Promise<DeleteConsequenceTypeResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<DeleteConsequenceTypeResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__PbisManager.DeleteConsequenceType, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  getConsequenceTypes(request: GetConsequenceTypesRequest): Promise<GetConsequenceTypesResponse>;
  getConsequenceTypes(request: GetConsequenceTypesRequest, metadata: grpc.Metadata): Promise<GetConsequenceTypesResponse>;
  getConsequenceTypes(request: GetConsequenceTypesRequest, callback: (err: any|null, response: GetConsequenceTypesResponse, metadata: grpc.Metadata) => void): void;
  getConsequenceTypes(request: GetConsequenceTypesRequest, metadata: grpc.Metadata, callback: (err: any|null, response: GetConsequenceTypesResponse, metadata: grpc.Metadata) => void): void;

  getConsequenceTypes(request: GetConsequenceTypesRequest, arg1?: grpc.Metadata|((err: any|null, response: GetConsequenceTypesResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: GetConsequenceTypesResponse, metadata: grpc.Metadata) => void): Promise<GetConsequenceTypesResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<GetConsequenceTypesResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__PbisManager.GetConsequenceTypes, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  createConsequences(request: CreateConsequenceRequest): Promise<CreateConsequenceResponse>;
  createConsequences(request: CreateConsequenceRequest, metadata: grpc.Metadata): Promise<CreateConsequenceResponse>;
  createConsequences(request: CreateConsequenceRequest, callback: (err: any|null, response: CreateConsequenceResponse, metadata: grpc.Metadata) => void): void;
  createConsequences(request: CreateConsequenceRequest, metadata: grpc.Metadata, callback: (err: any|null, response: CreateConsequenceResponse, metadata: grpc.Metadata) => void): void;

  createConsequences(request: CreateConsequenceRequest, arg1?: grpc.Metadata|((err: any|null, response: CreateConsequenceResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: CreateConsequenceResponse, metadata: grpc.Metadata) => void): Promise<CreateConsequenceResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<CreateConsequenceResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__PbisManager.CreateConsequences, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  getBehavior(request: GetBehaviorRequest): Promise<GetBehaviorResponse>;
  getBehavior(request: GetBehaviorRequest, metadata: grpc.Metadata): Promise<GetBehaviorResponse>;
  getBehavior(request: GetBehaviorRequest, callback: (err: any|null, response: GetBehaviorResponse, metadata: grpc.Metadata) => void): void;
  getBehavior(request: GetBehaviorRequest, metadata: grpc.Metadata, callback: (err: any|null, response: GetBehaviorResponse, metadata: grpc.Metadata) => void): void;

  getBehavior(request: GetBehaviorRequest, arg1?: grpc.Metadata|((err: any|null, response: GetBehaviorResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: GetBehaviorResponse, metadata: grpc.Metadata) => void): Promise<GetBehaviorResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<GetBehaviorResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__PbisManager.GetBehavior, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  deleteBehavior(request: DeleteBehaviorRequest): Promise<DeleteBehaviorResponse>;
  deleteBehavior(request: DeleteBehaviorRequest, metadata: grpc.Metadata): Promise<DeleteBehaviorResponse>;
  deleteBehavior(request: DeleteBehaviorRequest, callback: (err: any|null, response: DeleteBehaviorResponse, metadata: grpc.Metadata) => void): void;
  deleteBehavior(request: DeleteBehaviorRequest, metadata: grpc.Metadata, callback: (err: any|null, response: DeleteBehaviorResponse, metadata: grpc.Metadata) => void): void;

  deleteBehavior(request: DeleteBehaviorRequest, arg1?: grpc.Metadata|((err: any|null, response: DeleteBehaviorResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: DeleteBehaviorResponse, metadata: grpc.Metadata) => void): Promise<DeleteBehaviorResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<DeleteBehaviorResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__PbisManager.DeleteBehavior, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  deleteConsequence(request: DeleteConsequenceRequest): Promise<DeleteBehaviorResponse>;
  deleteConsequence(request: DeleteConsequenceRequest, metadata: grpc.Metadata): Promise<DeleteBehaviorResponse>;
  deleteConsequence(request: DeleteConsequenceRequest, callback: (err: any|null, response: DeleteBehaviorResponse, metadata: grpc.Metadata) => void): void;
  deleteConsequence(request: DeleteConsequenceRequest, metadata: grpc.Metadata, callback: (err: any|null, response: DeleteBehaviorResponse, metadata: grpc.Metadata) => void): void;

  deleteConsequence(request: DeleteConsequenceRequest, arg1?: grpc.Metadata|((err: any|null, response: DeleteBehaviorResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: DeleteBehaviorResponse, metadata: grpc.Metadata) => void): Promise<DeleteBehaviorResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<DeleteBehaviorResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__PbisManager.DeleteConsequence, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  completeConsequence(request: CompleteConsequenceRequest): Promise<CompleteConsequenceResponse>;
  completeConsequence(request: CompleteConsequenceRequest, metadata: grpc.Metadata): Promise<CompleteConsequenceResponse>;
  completeConsequence(request: CompleteConsequenceRequest, callback: (err: any|null, response: CompleteConsequenceResponse, metadata: grpc.Metadata) => void): void;
  completeConsequence(request: CompleteConsequenceRequest, metadata: grpc.Metadata, callback: (err: any|null, response: CompleteConsequenceResponse, metadata: grpc.Metadata) => void): void;

  completeConsequence(request: CompleteConsequenceRequest, arg1?: grpc.Metadata|((err: any|null, response: CompleteConsequenceResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: CompleteConsequenceResponse, metadata: grpc.Metadata) => void): Promise<CompleteConsequenceResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<CompleteConsequenceResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__PbisManager.CompleteConsequence, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  getStudentToolsBehaviors(request: GetStudentToolsBehaviorsRequest): Promise<GetStudentToolsBehaviorsResponse>;
  getStudentToolsBehaviors(request: GetStudentToolsBehaviorsRequest, metadata: grpc.Metadata): Promise<GetStudentToolsBehaviorsResponse>;
  getStudentToolsBehaviors(request: GetStudentToolsBehaviorsRequest, callback: (err: any|null, response: GetStudentToolsBehaviorsResponse, metadata: grpc.Metadata) => void): void;
  getStudentToolsBehaviors(request: GetStudentToolsBehaviorsRequest, metadata: grpc.Metadata, callback: (err: any|null, response: GetStudentToolsBehaviorsResponse, metadata: grpc.Metadata) => void): void;

  getStudentToolsBehaviors(request: GetStudentToolsBehaviorsRequest, arg1?: grpc.Metadata|((err: any|null, response: GetStudentToolsBehaviorsResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: GetStudentToolsBehaviorsResponse, metadata: grpc.Metadata) => void): Promise<GetStudentToolsBehaviorsResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<GetStudentToolsBehaviorsResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__PbisManager.GetStudentToolsBehaviors, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  importBehaviors(request: ImportBehaviorsRequest): {cancel():void;close():void}&Observable<ImportBehaviorsResponse>;
  importBehaviors(request: ImportBehaviorsRequest, metadata: grpc.Metadata): {cancel():void;close():void}&Observable<ImportBehaviorsResponse>;
  importBehaviors(request: ImportBehaviorsRequest, onMessage: (message?: ImportBehaviorsResponse) => void,onError?: (err: any) => void,onEnd?: (code: number, msg: string|undefined, metadata: grpc.Metadata) => void): void;
  importBehaviors(request: ImportBehaviorsRequest, metadata: grpc.Metadata, onMessage: (message?: ImportBehaviorsResponse) => void,onError?: (err: any) => void,onEnd?: (code: number, msg: string|undefined, metadata: grpc.Metadata) => void): void;

  importBehaviors(request: ImportBehaviorsRequest, arg1?: grpc.Metadata|((message?: ImportBehaviorsResponse) => void), arg2?: ((message?: ImportBehaviorsResponse) => void)|((err: any) => void), arg3?: ((err: any) => void)|((code: number, msg: string|undefined, metadata: grpc.Metadata) => void), arg4?: (code: number, msg: string|undefined, metadata: grpc.Metadata) => void): {cancel():void;close():void}&Observable<ImportBehaviorsResponse>|void {
    let ret: any;
    let metadata: any;

    let onMessage: any;
    let onError: any;
    let onEnd: any;
    if(typeof arg1 === 'function') {
      onMessage = arg1;
      onError = arg2;
      onEnd = arg3;
    } else if(typeof arg2 === 'function') {
      metadata = arg1;
      onMessage = arg2;
      onError = arg3;
      onEnd = arg4;
    } else {
      metadata = arg1;
    }

    if(!onMessage) {
      let subject = new Subject<ImportBehaviorsResponse>();
      ret = subject.asObservable();

      onMessage = (response: any) => {
        subject.next(response);
      };

      onError = (err: any) => {
        subject.error(err);
      };

      onEnd = (code: any, msg: any, metadata: any) => {
        subject.complete();
      };

    } else {
      if(!onError) {
        onError = (err: any) => console.error(err);
      }

      if(!onEnd) {
        onEnd = (code: any, msg: any, metadata: any) => {};
      }
    }

    let req = grpc.invoke(__PbisManager.ImportBehaviors, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onMessage: response => this._ngZone.run(() => {
        onMessage(response);
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code == grpc.Code.OK) {
          onEnd(code, msg, metadata);
        } else {
          let err = new Error(code + ' ' + (msg||''));
          (<any>err).code = code;
          onError(err);
        }
      })
    });

    (<any>ret).close = () => {
      console.warn('[Angular Grpc] .close() is deprecated use .cancel() instead');
      return req.close();
    };
    (<any>ret).cancel = () => req.close();
    return ret;
  }

  updateBehavior(request: UpdateBehaviorRequest): Promise<UpdateBehaviorResponse>;
  updateBehavior(request: UpdateBehaviorRequest, metadata: grpc.Metadata): Promise<UpdateBehaviorResponse>;
  updateBehavior(request: UpdateBehaviorRequest, callback: (err: any|null, response: UpdateBehaviorResponse, metadata: grpc.Metadata) => void): void;
  updateBehavior(request: UpdateBehaviorRequest, metadata: grpc.Metadata, callback: (err: any|null, response: UpdateBehaviorResponse, metadata: grpc.Metadata) => void): void;

  updateBehavior(request: UpdateBehaviorRequest, arg1?: grpc.Metadata|((err: any|null, response: UpdateBehaviorResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: UpdateBehaviorResponse, metadata: grpc.Metadata) => void): Promise<UpdateBehaviorResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<UpdateBehaviorResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__PbisManager.UpdateBehavior, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  updateConsequence(request: UpdateConsequenceRequest): Promise<UpdateConsequenceResponse>;
  updateConsequence(request: UpdateConsequenceRequest, metadata: grpc.Metadata): Promise<UpdateConsequenceResponse>;
  updateConsequence(request: UpdateConsequenceRequest, callback: (err: any|null, response: UpdateConsequenceResponse, metadata: grpc.Metadata) => void): void;
  updateConsequence(request: UpdateConsequenceRequest, metadata: grpc.Metadata, callback: (err: any|null, response: UpdateConsequenceResponse, metadata: grpc.Metadata) => void): void;

  updateConsequence(request: UpdateConsequenceRequest, arg1?: grpc.Metadata|((err: any|null, response: UpdateConsequenceResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: UpdateConsequenceResponse, metadata: grpc.Metadata) => void): Promise<UpdateConsequenceResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<UpdateConsequenceResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__PbisManager.UpdateConsequence, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

}

