import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

import { IBellSchedule } from 'libs/domain';

import { TERM_FORM_FIELDS } from '../constants/mm-bs-terms.constants';

export const getOverlappingDays = (
  schedules: IBellSchedule[],
  currentFormDays?: string[],
): string[] => {
  const days = new Set<string>();
  const overlappingDays = new Set<string>();
  const daysArrays = schedules.map(s => s.days);

  if (currentFormDays) {
    daysArrays.push(currentFormDays);
  }

  for (const daysArray of daysArrays) {
    for (const day of daysArray) {
      if (days.has(day)) {
        overlappingDays.add(day);
      }
      days.add(day);
    }
  }

  return [...overlappingDays];
};

export const noOverlappingDaysValidator = (
  schedules: IBellSchedule[],
): ValidatorFn => {
  return (formGroup: AbstractControl): ValidationErrors | null => {
    const scheduleIds = formGroup.get(
      TERM_FORM_FIELDS.BELL_SCHEDULE_IDS,
    )?.value;

    if (!scheduleIds?.length) return null;

    const filteredSchedules = schedules.filter(s => scheduleIds.includes(s.id));

    const overlappingDays = getOverlappingDays(filteredSchedules);

    if (overlappingDays.length === 0) return null;

    return {
      overlappingDays,
    };
  };
};
