import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';

import { TranslateModule } from '@ngx-translate/core';

import { FileInputModule } from '@app/src/app/components/Input/FileInput';
import { FileInputUploadModule } from '@app/src/app/components/Input/FileInputUpload';
import { FileUploadModule } from '@app/src/app/components/Input/FileUpload';
import { MgTextareaModule } from '@app/src/app/components/Textarea';
import { MgFormFieldModule } from '@app/src/app/form-field';
import { MgPasswordModule } from '@app/src/app/password';

import { FormModule } from '@shared/components/form';

@NgModule({
  exports: [
    // Minga dependencies
    MgFormFieldModule,
    FileInputModule,
    FileUploadModule,
    FileInputUploadModule,
    MgTextareaModule,
    MgPasswordModule,
    FormModule,

    // External dependencies
    FormsModule,
    MatInputModule,
    MatFormFieldModule,
    MatCheckboxModule,
    ReactiveFormsModule,
    TranslateModule,
    MatSelectModule,
    MatButtonToggleModule,
    MatRadioModule,
  ],
})
export class CommonFormModules {}
