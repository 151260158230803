import { EventPerson } from 'libs/domain';

import { ColumnInfo } from '../types';
import { studentId } from './columns.constants';
import { TimeColumnKeys } from './common.constants';

const eventPerson: ColumnInfo = {
  key: 'eventPersonName',
  header: 'Name',
  width: 'large',
  type: 'text',
  accessorFn: (row: EventPerson) => row.name,
};

const checkinTime: ColumnInfo = {
  key: TimeColumnKeys.CHECKIN_TIME,
  header: 'Check in',
  width: 'small',
  type: 'date',
};

const checkoutTime: ColumnInfo = {
  key: TimeColumnKeys.CHECKOUT_TIME,
  header: 'Check out',
  width: 'small',
  type: 'date',
};

export const EVENT: ColumnInfo[] = [
  eventPerson,
  { ...studentId, accessorFn: undefined },
  checkinTime,
  checkoutTime,
];

export const EVENT_TICKETED: ColumnInfo[] = [
  eventPerson,
  { ...studentId, accessorFn: undefined },
  { key: 'tickets', header: 'Tickets', width: 'small', type: 'number' },
  checkinTime,
  checkoutTime,
];

export const NOT_GOING: ColumnInfo[] = [
  eventPerson,
  { ...studentId, accessorFn: undefined },
];
