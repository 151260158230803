<div class="card-container">
  <div
    class="profile-header subcard"
    *ngIf="person$ | async">
    <mg-text
      color="surface"
      variant="header-md-secondary">
      {{ FIELDS.TITLE }}
    </mg-text>

    <div class="profile-header-pic-container">
      <div
        *ngIf="person?.filename && !overwriteURL"
        class="profile-picture"
        [mgStudentPicture]="person.filename"></div>
      <mg-avatar
        *ngIf="!person.filename || overwriteURL"
        class="profile-picture"
        [src]="overwriteURL ? overwriteURL : person.avatarUrl"></mg-avatar>

      <mg-btn
        *hasPermission="'ID_CHANGE_OWN_PHOTO'"
        class="profile-header-change-photo"
        color="primary"
        variant="text"
        (pressed)="changePhoto()">
        {{ FIELDS.CHANGE_PHOTO }}
      </mg-btn>
    </div>
  </div>

  <div
    class="change-password subcard"
    *ngIf="person$ | async">
    <mg-text
      color="surface"
      variant="header-md-secondary">
      {{ FIELDS.CHANGE_PASSWORD }}
    </mg-text>

    <form [formGroup]="passwordForm">
      <fieldset>
        <mg-form-text-input
          name="currentPassword"
          inputType="password"
          [label]="FIELDS.CURRENT_PASSWORD_LABEL"
          [control]="passwordForm.get('currentPassword')"></mg-form-text-input>
      </fieldset>

      <div class="password-requirements">
        <mg-text
          color="surface"
          variant="header-sm">
          {{ PASSWORD_REQUIREMENTS.HEADER }}
        </mg-text>

        <ul>
          <li *ngFor="let requirement of PASSWORD_REQUIREMENTS.REQUIREMENTS">
            <mg-text variant="body-sm">{{ requirement }}</mg-text>
          </li>
        </ul>
      </div>

      <fieldset>
        <mg-form-text-input
          name="newPassword"
          inputType="password"
          [label]="FIELDS.NEW_PASSWORD_LABEL"
          [control]="passwordForm.get('newPassword')"></mg-form-text-input>
        <mg-form-text-input
          name="confirmPassword"
          inputType="password"
          [label]="FIELDS.CONFIRM_PASSWORD_LABEL"
          [control]="passwordForm.get('confirmPassword')"></mg-form-text-input>
      </fieldset>

      <div class="button-container">
        <mg-btn
          variant="filled"
          size="small"
          [disabled]="passwordForm.invalid"
          (pressed)="updatePassword()">
          {{ FIELDS.UPDATE_PASSWORD }}
        </mg-btn>
      </div>
    </form>
  </div>

  <div
    *ngIf="canUserDeleteAccount$ | async"
    class="delete-account subcard">
    <div class="tw-flex tw-justify-between">
      <mg-text
        color="surface"
        variant="body-sm"
        element="p">
        {{ FIELDS.DELETE_ACCOUNT }}
      </mg-text>

      <mg-btn
        variant="destructive"
        (pressed)="launchArchiveAccountConfDialog()"
        >{{ FIELDS.DELETE_BUTTON }}</mg-btn
      >
    </div>
  </div>
</div>
