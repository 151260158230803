import { IMembershipList } from 'libs/domain';
import { IPbisType } from 'libs/domain';
import { Restriction } from 'libs/domain';
import { pbis_pb } from 'libs/generated-grpc-ts';

import { asObjectToMessage } from '../';
import { MembershipListMapper } from '../membership_list';
import { RestrictionMapper } from '../restriction';

export const toProto = (item: IPbisType): pbis_pb.PbisType => {
  const msg = new pbis_pb.PbisType();
  if (item.id) msg.setId(item.id);
  if (item.name) msg.setName(item.name);
  if (typeof item.categoryId === 'number') msg.setCategoryId(item.categoryId);
  if (item.points) msg.setPoints(item.points);
  if (item.active) msg.setActive(item.active);
  if (item.createdBy) msg.setCreatedBy(item.createdBy);
  if (item.mingaId) msg.setMingaId(item.mingaId);
  if (typeof item.sendParentEmail == 'boolean')
    msg.setSendParentEmail(item.sendParentEmail);
  if (typeof item.sendParentSms == 'boolean')
    msg.setSendParentSms(item.sendParentSms);
  if (typeof item.sendAdminEmail == 'boolean')
    msg.setSendAdminEmail(item.sendAdminEmail);
  if (item.adminEmails) msg.setAdminEmailList(item.adminEmails);
  // if (item.automationId) msg.setAutomationId(item.automationId);
  if (item.dailyBehaviorCount)
    msg.setDailyBehaviorCount(item.dailyBehaviorCount);
  if (item.hallPassTypeId) msg.setHallPassTypeId(item.hallPassTypeId);
  if (item.canTrackMembershipListId)
    msg.setMembershipListId(item.canTrackMembershipListId);
  if (item.canTrackMembershipList) {
    const membershipListMsg = MembershipListMapper.toProto(
      item.canTrackMembershipList,
    );
    msg.setMembershipList(membershipListMsg);
  }
  if (item.emailTemplate)
    msg.setEmailTemplate(
      asObjectToMessage(item.emailTemplate, new pbis_pb.EmailTemplate()),
    );
  if (item.smsTemplate)
    msg.setSmsTemplate(
      asObjectToMessage(item.smsTemplate, new pbis_pb.SmsTemplate()),
    );
  if (item.iconType) msg.setIconType(item.iconType);
  if (item.iconColor) msg.setIconColor(item.iconColor);
  if (item.restriction) {
    const restrictionMsg = RestrictionMapper.toProto(item.restriction);
    msg.setRestriction(restrictionMsg);
  }

  return msg;
};

export const fromProto = (msg: pbis_pb.PbisType): IPbisType => {
  let membershipList: IMembershipList | undefined;
  if (msg.getMembershipList()) {
    membershipList = MembershipListMapper.fromProto(msg.getMembershipList());
  }
  let restriction: Restriction | undefined;
  if (msg.getRestriction()) {
    restriction = RestrictionMapper.fromProto(msg.getRestriction());
  }

  return {
    id: msg.getId(),
    name: msg.getName(),
    categoryId: msg.getCategoryId(),
    points: msg.getPoints(),
    active: msg.getActive(),
    createdBy: msg.getCreatedBy(),
    mingaId: msg.getMingaId(),
    sendParentEmail: msg.getSendParentEmail(),
    sendParentSms: msg.getSendParentSms(),
    sendAdminEmail: msg.getSendAdminEmail(),
    adminEmails: msg.getAdminEmailList(),
    dailyBehaviorCount: msg.getDailyBehaviorCount(),
    hallPassTypeId: msg.getHallPassTypeId(),
    canTrackMembershipListId: msg.getMembershipListId(),
    canTrackMembershipList: membershipList,
    emailTemplate: msg.getEmailTemplate()?.toObject(),
    smsTemplate: msg.getSmsTemplate()?.toObject(),
    iconType: msg.getIconType(),
    iconColor: msg.getIconColor(),
    restriction,
  };
};
