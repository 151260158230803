import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { RouterModule } from '@angular/router';

import { TranslateModule } from '@ngx-translate/core';

import { MgEmptyStateModule } from '@app/src/app/components/EmptyState';
import { MgStreamScrollerModule } from '@app/src/app/components/MgStreamScroller';
import { MgContentLinkModule } from '@app/src/app/content-link';
import { MgPollMiniCardModule } from '@app/src/app/elements/MgMiniCard/MgPollMiniCard';
import { MgIconModule } from '@app/src/app/icon';
import { PermissionsModule } from '@app/src/app/permissions';
import { MgPipesModule } from '@app/src/app/pipes';

import { LayoutLegacyContentModule } from '@modules/layout/components/layout-legacy-content/layout-legacy-content.module';

import { HomeActionButtonModule } from '../home-action-button/home-action-button.module';
import { HomePollsComponent } from './home-polls.component';

@NgModule({
  imports: [
    // Minga dependencies
    MgStreamScrollerModule,
    MgPollMiniCardModule,
    MgIconModule,
    MgPipesModule,
    MgContentLinkModule,
    PermissionsModule,
    MgEmptyStateModule,
    LayoutLegacyContentModule,
    HomeActionButtonModule,

    // External dependencies
    CommonModule,
    RouterModule,
    FlexLayoutModule,
    TranslateModule,
  ],
  declarations: [HomePollsComponent],
  exports: [HomePollsComponent],
})
export class HomePollsModule {}
