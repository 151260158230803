import { PortalModule } from '@angular/cdk/portal';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatChipsModule } from '@angular/material/chips';
import { MatRippleModule } from '@angular/material/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';

import { MgButtonModule } from '@app/src/app/button';
import { MgEmptyStateModule } from '@app/src/app/components/EmptyState';
import { MgImageModule } from '@app/src/app/components/MgImage';
import { MgSuccessCheckmarkModule } from '@app/src/app/components/SuccessCheckmark';
import { MgIconModule } from '@app/src/app/icon';
import { PermissionsModule } from '@app/src/app/permissions';
import { MgPipesModule } from '@app/src/app/pipes';
import { MgSpinnerModule } from '@app/src/app/spinner';

import { BottomSheetModule } from '@shared/components/bottom-sheet';
import {
  GenericButtonComponent,
  GenericCardComponent,
  GenericChipComponent,
  GenericMenuItemComponent,
  GenericModule,
  GenericTagComponent,
} from '@shared/components/generic';
import { OverlayModule } from '@shared/components/modal-overlay';
import { PlaceholderModule } from '@shared/components/placeholder';
import { PortalLayoutModule } from '@shared/components/portal-layout';
import { SystemAlertModule } from '@shared/components/system-alert';
import { MobileCardModule } from '@shared/components/tables/mobile-card/mobile-card.module';
import { TextModule } from '@shared/components/text';
import { TooltipModule } from '@shared/components/tooltip';

@NgModule({
  imports: [
    // Minga dependencies
    GenericModule,
  ],
  exports: [
    // Minga dependencies
    PermissionsModule,
    TextModule,
    MgIconModule,
    MgSpinnerModule,
    MgEmptyStateModule,
    GenericButtonComponent,
    GenericCardComponent,
    GenericChipComponent,
    GenericTagComponent,
    GenericMenuItemComponent,
    MgButtonModule,
    MgSuccessCheckmarkModule,
    PortalLayoutModule,
    SystemAlertModule,
    OverlayModule,
    TooltipModule,
    MgImageModule,
    MgPipesModule,
    MobileCardModule,
    BottomSheetModule,
    PlaceholderModule,

    // Angular dependencies
    CommonModule,
    FlexLayoutModule,
    RouterModule,

    // Material UI dependencies
    MatRippleModule,
    MatProgressBarModule,
    MatTableModule,
    MatSortModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatTooltipModule,
    MatExpansionModule,
    MatChipsModule,
    MatSlideToggleModule,
    MatButtonToggleModule,
    PortalModule,
    ScrollingModule,
  ],
})
export class CommonUIModules {}
