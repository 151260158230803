import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { MgGroupListModule } from '@app/src/app/groups/containers/MgGroupList';
import { MgSpinnerModule } from '@app/src/app/spinner';

import { ParentGroupPickerComponent } from './ParentGroupPicker.component';

@NgModule({
  imports: [
    // Minga dependencies
    MgGroupListModule,
    MgSpinnerModule,

    // External dependencies
    CommonModule,
  ],
  declarations: [ParentGroupPickerComponent],
  exports: [ParentGroupPickerComponent],
})
export class ParentGroupPickerModule {}
