import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  UntypedFormBuilder,
  Validators,
} from '@angular/forms';

import {
  AutomationAutoResetCustom,
  AutomationAutoResetFrequency,
  AutomationAutoResetValue,
  AutomationPayload,
  AutomationTrigger,
  IActionThresholdAutomation,
} from 'libs/domain';
import { MingaRoleType } from 'libs/domain';
import { ReplaySubject, combineLatest } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

import { RootService } from '@app/src/app/minimal/services/RootService';
import { PermissionsService } from '@app/src/app/permissions';
import { scrollIntoView } from '@app/src/app/util/scroll-into-view';

import { BehaviorManagerService } from '@modules/behavior-manager/services';
import { PeopleSelectorService } from '@modules/people-selector';

import { CrudFormBase } from '@shared/components/crud-form-base/crud-form-base.abstract';
import {
  MODAL_OVERLAY_DATA,
  ModalOverlayPrimaryHeaderBackground,
  ModalOverlayRef,
  ModalOverlayService,
  ModalOverlayServiceCloseEventType,
} from '@shared/components/modal-overlay';
import {
  SystemAlertCloseEvents,
  SystemAlertModalService,
  SystemAlertModalType,
} from '@shared/components/system-alert-modal';
import { SystemAlertSnackBarService } from '@shared/components/system-alert-snackbar';
import { AutomationGroupService } from '@shared/services/automation';

import {
  BmTypesAutomationGroupEditModalData,
  BmTypesAutomationGroupEditModalResponse,
} from '../../types';
import {
  BmTypesAutomationResetComponent,
  BmTypesAutomationResetModalData,
} from '../bm-types-automation-reset/bm-types-automation-reset.component';
import {
  EDIT_AUTOMATIONS_FORMGROUP,
  EditAutomationFormFields,
  EditAutomationGroupMessages,
  FREQUENCY_OPTIONS,
} from './bm-types-automations-edit-group.constants';

enum ActionKeysToCheck {
  TRIGGERS = 'triggers',
  AUTO_RESET = 'autoReset',
}

@Component({
  selector: 'mg-bm-types-automations-edit-group',
  templateUrl: './bm-types-automations-edit-group.component.html',
  styleUrls: ['./bm-types-automations-edit-group.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BmTypesAutomationsEditGroupComponent
  extends CrudFormBase<IActionThresholdAutomation>
  implements OnDestroy, OnInit
{
  /** Children */
  @ViewChild('formElement', { static: false })
  formElement?: ElementRef<HTMLElement>;

  /** Constants */
  public MESSAGES = EditAutomationGroupMessages;
  public FORM_FIELDS = EditAutomationFormFields;
  public FREQUENCY_OPTIONS = FREQUENCY_OPTIONS;
  public RESET_FREQUENCY = AutomationAutoResetFrequency;
  public readonly MODAL_CONFIG = {
    headerBg: ModalOverlayPrimaryHeaderBackground.GREEN,
  };

  /** General subjects */
  private _destroyed = new ReplaySubject<void>(1);

  /** Form */
  public readonly form = this._fb.group(EDIT_AUTOMATIONS_FORMGROUP);

  /** Can manually reset counters */
  public readonly canResetCounters$ = combineLatest([
    this.isNew$,
    this._permissions.mingaRoleType$,
  ]).pipe(
    takeUntil(this._destroyed),
    map(([isNew, role]) =>
      !isNew &&
      [
        MingaRoleType.OWNER,
        MingaRoleType.MANAGER,
        MingaRoleType.SUPERADMIN,
      ].includes(role as MingaRoleType)
        ? true
        : false,
    ),
  );

  /** Behaviors Options */
  public readonly behaviorsOptions$ = this._bmService
    .getBehaviorTypes(false)
    .pipe(
      map(behaviors => {
        return behaviors
          .map(behavior => ({
            label: behavior.name,
            value: behavior.id,
            disabled: !behavior.active,
          }))
          .sort((a, b) => {
            return +a.disabled - +b.disabled;
          });
      }),
    );

  public readonly disabledBehaviorsWarning$ = combineLatest([
    this.behaviorsOptions$,
    this.form.get(EditAutomationFormFields.BEHAVIOR).valueChanges,
  ]).pipe(
    map(([options, value]) => {
      return (value || []).some(
        id => options.find(option => id === option.value)?.disabled,
      );
    }),
  );

  /** Component Constructor */
  constructor(
    @Inject(MODAL_OVERLAY_DATA)
    public dialogData: BmTypesAutomationGroupEditModalData,
    public rootService: RootService,
    private _modalRef: ModalOverlayRef<
      BmTypesAutomationGroupEditModalResponse,
      BmTypesAutomationGroupEditModalData
    >,
    private _peopleSelector: PeopleSelectorService,
    private _fb: UntypedFormBuilder,
    private _permissions: PermissionsService,
    private _bmService: BehaviorManagerService,
    private _automationGroup: AutomationGroupService,
    private _systemAlertModal: SystemAlertModalService,
    private _systemAlert: SystemAlertSnackBarService,
    private _cdr: ChangeDetectorRef,
    private _modalOverlay: ModalOverlayService<
      any,
      BmTypesAutomationResetModalData
    >,
  ) {
    super({
      id: dialogData?.id,
      get: id => {
        return this._automationGroup.fetch(id);
      },
      create: data => {
        return this._automationGroup.create(data);
      },
      update: data => {
        return this._automationGroup.update(data as IActionThresholdAutomation);
      },
      delete: async data => {
        await this._automationGroup.delete(data.id);
        return data;
      },
      onSetForm: data => {
        for (const key in data) {
          if (data.hasOwnProperty(key)) {
            if (key === ActionKeysToCheck.AUTO_RESET) {
              const autoReset = data[key] as AutomationAutoResetValue[];
              this._handleSetAutoResetFields(autoReset);
              continue;
            }
            const control = this.form.controls[key];
            if (!control) continue;
            let value = data[key];
            if (key === ActionKeysToCheck.TRIGGERS) {
              value = value?.pbisTriggers?.map(trigger => trigger.id) || [];
            }
            control.setValue(value);
            this._markControlsPristineUntouched([control]);
          }
        }
        this._cdr.markForCheck();
        this.form.markAsPristine();
        this.form.markAsUntouched();
      },
      onValidate: _data => {
        Object.values(this.form.controls).forEach(control => {
          control.markAsTouched();
          control.updateValueAndValidity();
        });

        return this.form.valid;
      },
      onSuccess: (type, data) => {
        if (type === 'delete') {
          this._modalRef.close(ModalOverlayServiceCloseEventType.DELETE, {
            deleted: data,
          });
        }

        if (type === 'create') {
          this._modalRef.close(ModalOverlayServiceCloseEventType.CREATE, {
            created: data,
          });
        }

        if (type === 'update') {
          this._modalRef.close(ModalOverlayServiceCloseEventType.SUBMIT, {
            updated: data,
          });
        }
      },
      onSubmit: data => {
        const behaviorControl = this.form.get(
          EditAutomationFormFields.BEHAVIOR,
        );
        let triggers: AutomationTrigger[] = [];
        const value: number[] = behaviorControl.value; // an array of pbis type ids.
        if (value.length) {
          triggers = value.map(id => ({ id }));
        }
        const autoReset: AutomationAutoResetValue[] = [];
        if (this.form.get(EditAutomationFormFields.ENABLE_AUTO_RESET).value) {
          const frequency = this.form.get(
            EditAutomationFormFields.AUTO_RESET_FREQUENCY,
          ).value;
          const time = this.form.get(
            EditAutomationFormFields.AUTO_RESET_TIME,
          ).value;
          switch (frequency) {
            case AutomationAutoResetFrequency.MONTHLY:
            case AutomationAutoResetFrequency.DAILY:
            case AutomationAutoResetFrequency.WEEKLY: {
              autoReset.push({
                frequency,
              });
              break;
            }
            case AutomationAutoResetFrequency.CUSTOM: {
              const dates = this.form.get(
                EditAutomationFormFields.AUTOR_RESET_CUSTOM_DATES,
              ).value;
              for (const date of dates)
                autoReset.push({
                  frequency,
                  date,
                  time,
                });
              break;
            }
            default: {
              console.warn('unhandled frequency change');
            }
          }
        }
        const mergedData: AutomationPayload = {
          id: data.id,
          ...this.form.value,
          autoReset,
          triggers: {
            pbisTriggers: triggers,
          },
          active: data.active ?? true,
        };
        return mergedData;
      },
      onShowLoader: promise => rootService.addLoadingPromise(promise),
      onCancel: async () => {
        if (this.form.pristine) {
          this._modalRef.close(ModalOverlayServiceCloseEventType.CLOSE);
          return;
        }

        const modalRef = await this._systemAlertModal.open({
          modalType: SystemAlertModalType.WARNING,
          heading: EditAutomationGroupMessages.DELETE_CONFIRM_DISCARD_TITLE,
          message: EditAutomationGroupMessages.DELETE_CONFIRM_DISCARD_DESC,
          closeBtn: EditAutomationGroupMessages.DELETE_CONFIRM_CANCEL_BTN,
          confirmActionBtn:
            EditAutomationGroupMessages.DELETE_CONFIRM_DISCARD_BTN,
        });

        const response = await modalRef.afterClosed().toPromise();

        if (response?.type === SystemAlertCloseEvents.CONFIRM) {
          this._modalRef.close(ModalOverlayServiceCloseEventType.CLOSE);
        }
      },
      onDelete: async () => {
        const modalRef = await this._systemAlertModal.open({
          modalType: SystemAlertModalType.WARNING,
          heading: EditAutomationGroupMessages.DELETE_CONFIRM_TITLE,
          closeBtn: EditAutomationGroupMessages.DELETE_CONFIRM_CANCEL_BTN,
          confirmActionBtn:
            EditAutomationGroupMessages.DELETE_CONFIRM_DELETE_BTN,
        });

        const response = await modalRef.afterClosed().toPromise();

        return response?.type === SystemAlertCloseEvents.CONFIRM;
      },
      onFormStateChange: state => {
        if (state === 'invalid') {
          // scroll to top of form
          scrollIntoView(this.formElement.nativeElement, {
            align: { top: 0 },
          });
        }
      },
    });
  }

  ngOnInit() {
    this.init();

    this.form
      .get(this.FORM_FIELDS.AUTO_RESET_FREQUENCY)
      .valueChanges.pipe(takeUntil(this._destroyed))
      .subscribe(value => {
        this._onAutoResetFrequencyChange(value);
      });

    this.form
      .get(this.FORM_FIELDS.ENABLE_AUTO_RESET)
      .valueChanges.pipe(takeUntil(this._destroyed))
      .subscribe(value => {
        this._onEnableAutoReset(value);
      });
  }

  ngOnDestroy(): void {
    this._destroyed.next();
    this._destroyed.complete();
  }

  public async openResetModal(all?: boolean): Promise<any> {
    const automationGroup = this.getData();
    let selection;
    if (!all) {
      selection = await this._peopleSelector.openStandAlone(
        automationGroup?.name,
        {
          submitLabel: 'Next',
        },
      );
    }
    const modalRef = this._modalOverlay.open<BmTypesAutomationResetModalData>(
      BmTypesAutomationResetComponent,
      {
        data: {
          automationGroupName: automationGroup?.name,
          type: all ? 'all' : 'selection',
          automationId: this.dialogData.id,
          selection: selection ?? [],
        },
        disposeOnNavigation: false,
      },
    );
    const result = await modalRef.afterClosed.toPromise();
    if (result.type === 'submit') {
      this._systemAlert.success('Student counter(s) updated');
    }
  }

  private _onAutoResetFrequencyChange(
    value: AutomationAutoResetFrequency,
  ): void {
    if (!this.form.get(this.FORM_FIELDS.ENABLE_AUTO_RESET).value) return;
    const autoFrequencyCustom = this.form.get(
      this.FORM_FIELDS.AUTOR_RESET_CUSTOM_DATES,
    );
    const autoResetTime = this.form.get(this.FORM_FIELDS.AUTO_RESET_TIME);

    switch (value) {
      case AutomationAutoResetFrequency.DAILY:
      case AutomationAutoResetFrequency.MONTHLY: {
        autoFrequencyCustom.clearValidators();
        autoResetTime.clearValidators();
        break;
      }
      case AutomationAutoResetFrequency.WEEKLY: {
        autoFrequencyCustom.clearValidators();
        autoResetTime.clearValidators();
        break;
      }
      case AutomationAutoResetFrequency.CUSTOM: {
        autoFrequencyCustom.setValidators([Validators.required]);
        autoResetTime.setValidators([Validators.required]);
        break;
      }
      default: {
        console.warn('unhandled frequency change');
      }
    }

    autoResetTime.updateValueAndValidity();
    autoFrequencyCustom.updateValueAndValidity();
  }

  private _onEnableAutoReset(isEnabled: boolean): void {
    const autoFrequency = this.form.get(this.FORM_FIELDS.AUTO_RESET_FREQUENCY);
    const autoResetCustomDates = this.form.get(
      this.FORM_FIELDS.AUTOR_RESET_CUSTOM_DATES,
    );

    if (isEnabled) {
      autoFrequency.setValidators([Validators.required]);
    } else {
      autoFrequency.clearValidators();
      autoResetCustomDates.clearValidators();
    }

    autoFrequency.updateValueAndValidity();
    autoResetCustomDates.updateValueAndValidity();
  }

  private _handleSetAutoResetFields(
    autoReset: AutomationAutoResetValue[],
  ): void {
    if (autoReset.length) {
      const reset = autoReset[0];
      const enableResetControl = this.form.get(
        EditAutomationFormFields.ENABLE_AUTO_RESET,
      );
      const frequencyControl = this.form.get(
        EditAutomationFormFields.AUTO_RESET_FREQUENCY,
      );
      const autoResetTime = this.form.get(
        EditAutomationFormFields.AUTO_RESET_TIME,
      );
      const autoResetCustomDatesControl = this.form.get(
        EditAutomationFormFields.AUTOR_RESET_CUSTOM_DATES,
      );
      enableResetControl?.setValue(true);
      frequencyControl?.setValue(reset.frequency);
      switch (reset.frequency) {
        case AutomationAutoResetFrequency.MONTHLY:
        case AutomationAutoResetFrequency.DAILY:
        case AutomationAutoResetFrequency.WEEKLY: {
          break;
        }
        case AutomationAutoResetFrequency.CUSTOM: {
          const dates: string[] = [];
          // just to make typescript happy
          const isCustom = (x: any): x is AutomationAutoResetCustom => true;
          for (const ar of autoReset) {
            if (!isCustom(ar)) continue;
            dates.push(ar.date);
          }
          autoResetCustomDatesControl?.setValue(dates);
          autoResetTime?.setValue(reset.time);
          break;
        }
        default: {
          console.warn('unhandled frequency change');
        }
      }
      this._markControlsPristineUntouched([
        enableResetControl,
        frequencyControl,
        autoResetTime,
        autoResetCustomDatesControl,
      ]);
    }
  }

  private _markControlsPristineUntouched(controls: AbstractControl[]): void {
    for (const c of controls) {
      c.markAsPristine();
      c.markAsUntouched();
    }
  }
}
