import { Injectable } from '@angular/core';

import { DeviceAttribute } from './attributes/attribute.interface';
import { HomeButtonAttribute } from './attributes/homebutton-attribute';
import {
  Attribute,
  AppRuntime,
  Platform,
  DeviceInfo,
  Window,
} from './runtime.interface';

@Injectable({ providedIn: 'root' })

/**
 * The default window parameter is a browser implementation, it needs to be
 * replaced during testing because it is not available in the node test
 * environment.
 */
export class AppRuntimeDefault implements AppRuntime {
  /**
   * For convenience, so we don't have to constantly drill two levels deep
   * (this._window.navigator.userAgent) when accessing the user agent.
   */
  private _userAgent: string;

  /**
   * The native device ready promise is used by callers to check if
   * it's safe to move forward with execution. If a browser app, then
   * this will probably never resolve.
   *
   * Copied directly from device-ready.utils.ts
   */
  private _nativeDeviceReady = new Promise(resolve =>
    this._window.document?.addEventListener('deviceready', resolve, false),
  );

  /**
   * Device attributes we currently support. Each attribute includes
   * rules for checking if the device has the specified attribute.
   *
   * TODO: consider moving this to another file, and make it a dependency, so
   * we aren't modifying this file every time we add a new attribute.
   */
  private _attributeLookup: Record<Attribute, DeviceAttribute> = {
    homebutton: new HomeButtonAttribute(),
  };

  // TODO: Add optional logger here for debugging, but not needed in tests.
  constructor(private _window: Window) {
    this._userAgent = this._window.navigator.userAgent;
  }

  isNativeApp(platform?: Platform): boolean {
    const isCordova = isCordovaUserAgent(this._userAgent);
    const isPlatform = this._isPlatform(platform);

    /**
     * This additional check could be removed. They way the user agent
     * is appended to in cordova/config.xml solves this, but in cases where
     * we still want to match on the user agent of ios, this fixes that.
     */
    const isPlatformMatch =
      platform === 'ios'
        ? isPlatform || /ios/i.test(this._userAgent)
        : isPlatform;

    return isCordova && isPlatformMatch;
  }

  /** If no platform is defined, then always return true. */
  private _isPlatform(platform?: Platform): boolean {
    if (platform === 'ios') {
      return isIosUserAgent(this._userAgent);
    } else if (platform === 'android') {
      return isAndroidUserAgent(this._userAgent);
    } else {
      return true;
    }
  }

  isBrowserApp(platform?: Platform): boolean {
    const isBrowser = !this.isNativeApp(platform);
    const isPlatform = this._isPlatform(platform);
    return isBrowser && isPlatform;
  }

  async hasAttribute(attribute: Attribute): Promise<boolean> {
    const deviceInfo = await this.getDeviceInfo();
    const model: string | undefined =
      'model' in deviceInfo ? deviceInfo.model : undefined;
    return this._attributeLookup[attribute].isAvailableOn(model);
  }

  async getDeviceInfo(): Promise<DeviceInfo> {
    if (this.isNativeApp()) {
      try {
        await this._nativeDeviceReady;
        const device = this._window.device ?? {};
        return {
          ...device,
          userAgent: this._userAgent,
        };
      } catch (error) {
        // TODO: Update to use Logger, don't want to see this in the test console.
        console.error('Could not get native device info:', error);
        return {
          userAgent: this._userAgent,
        };
      }
    } else {
      return {
        userAgent: this._userAgent,
      };
    }
  }
}

/**
 * Do not use these functions directly. They are only exported for testing
 * large quantities of user agents. Use the AppRuntime to handle all of your
 * runtime app/device queries.
 *
 * These regexes were directly copied from app/src/config.ts; those regexes
 * could be replaced with these functions.
 */
export const isCordovaUserAgent = (agent: string): boolean => {
  // This regex was modified slightly from the original in app/src/config.ts;
  // brackets were removed.
  const regex = /io\.minga\.app/i;
  return regex.test(agent);
};

export const isAndroidUserAgent = (agent: string): boolean => {
  const regex = /android/i;
  return regex.test(agent);
};

export const isIosUserAgent = (agent: string): boolean => {
  // This regex was modified slightly from the original in app/src/config.ts;
  // ignore case was added.
  const regex = /iPad|iPhone|iPod/i;
  return regex.test(agent);
};
