import { Pipe, PipeTransform } from '@angular/core';

import { formatTime } from 'libs/util';

@Pipe({ name: 'formatTimeRange' })
export class MgFormatTimeRangePipe implements PipeTransform {
  transform(item: { startTime: string; endTime: string }): string {
    const { startTime, endTime } = item;

    const formattedStart = formatTime(startTime);
    const formattedEnd = ` - ${formatTime(endTime)}`;

    return `${formattedStart}${formattedEnd}`;
  }
}
