import { IMingaDistrict } from 'libs/domain';
import { gateway } from 'libs/generated-grpc-ts';

import { dateObjectToDateTimeMessage, dateTimeMessageToDateObject } from '../';

export const toProto = (
  district: IMingaDistrict,
): gateway.minga_pb.MingaDistrict => {
  const message = new gateway.minga_pb.MingaDistrict();
  if (district.id) message.setId(district.id);
  if (district.name) message.setName(district.name);
  if (district.hubspotId) message.setHubspotId(district.hubspotId);
  if (district.createdAt)
    message.setCreatedAt(dateObjectToDateTimeMessage(district.createdAt));

  return message;
};

export const fromProto = (
  message: gateway.minga_pb.MingaDistrict,
): IMingaDistrict => {
  return {
    id: message.getId(),
    name: message.getName(),
    hubspotId: message.getHubspotId(),
    createdAt: message.getCreatedAt()
      ? dateTimeMessageToDateObject(message.getCreatedAt())
      : undefined,
  };
};
