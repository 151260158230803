import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { MgIconModule } from '@app/src/app/icon';

import { ManagerOverlayModule } from '@shared/components/manager-overlay';

import { PointsManagerComponent } from './points-manager.component';
import { PointsManagerService } from './services';

@NgModule({
  imports: [
    // Minga dependencies
    MgIconModule,
    ManagerOverlayModule,

    // External dependencies
    CommonModule,
    RouterModule,
  ],
  declarations: [PointsManagerComponent],
  exports: [PointsManagerComponent],
  providers: [PointsManagerService],
})
export class PointsManagerModule {}
