import { IBannerCategory } from 'libs/domain';
import { gateway } from 'libs/generated-grpc-ts';

export namespace IBannerCategoryMapper {
  export function toBannerLibraryStats(
    category: IBannerCategory,
  ): gateway.banner_pb.BannerLibrarayStats.CategoryCount {
    const countMsg = new gateway.banner_pb.BannerLibrarayStats.CategoryCount();
    const catEnum = bannerCategorySqlEnumToMsgEnum(category.name);
    countMsg.setCategory(catEnum);
    countMsg.setCategoryCount(category.count);

    return countMsg;
  }

  export function bannerCategoryMsgEnumToSqlEnum(
    catEnum: gateway.banner_pb.Banner.Category,
  ): string {
    switch (catEnum) {
      case gateway.banner_pb.Banner.Category.ART:
        return 'ART';
      case gateway.banner_pb.Banner.Category.CAUSES:
        return 'CAUSES';
      case gateway.banner_pb.Banner.Category.FOOD:
        return 'FOOD';
      case gateway.banner_pb.Banner.Category.GENERAL:
        return 'GENERAL';
      case gateway.banner_pb.Banner.Category.SCHOOL_EVENTS:
        return 'SCHOOL_EVENTS';
      case gateway.banner_pb.Banner.Category.SEASONAL:
        return 'SEASONAL';
      case gateway.banner_pb.Banner.Category.SPORTS:
        return 'SPORTS';
      case gateway.banner_pb.Banner.Category.GROUP:
        return 'GROUP';
    }
    return 'GENERAL';
  }

  export function bannerCategorySqlEnumToMsgEnum(
    catEnum: string,
  ): gateway.banner_pb.Banner.Category {
    switch (catEnum) {
      case 'ART':
        return gateway.banner_pb.Banner.Category.ART;
      case 'CAUSES':
        return gateway.banner_pb.Banner.Category.CAUSES;
      case 'FOOD':
        return gateway.banner_pb.Banner.Category.FOOD;
      case 'GENERAL':
        return gateway.banner_pb.Banner.Category.GENERAL;
      case 'SCHOOL_EVENTS':
        return gateway.banner_pb.Banner.Category.SCHOOL_EVENTS;
      case 'SEASONAL':
        return gateway.banner_pb.Banner.Category.SEASONAL;
      case 'SPORTS':
        return gateway.banner_pb.Banner.Category.SPORTS;
      case 'GROUP':
        return gateway.banner_pb.Banner.Category.GROUP;
    }

    return gateway.banner_pb.Banner.Category.GENERAL;
  }
}
