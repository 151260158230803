import * as day from 'dayjs';

import { HallPassStatusEnum, IHallPassErrorType } from '../hallPass';
import { ReportFilterType } from './ReportFilterType';

export enum HpmReportsFilter {
  ISSUED_TO = 'issuedTo',
  HALLPASS_TYPE = 'hallpassType',
  CREATED_BY = 'createdBy',
  START_DATE = 'startDate',
  END_DATE = 'endDate',
  GROUP_BY = 'groupBy',
  SORT_BY = 'sortBy',
  ISSUED_BY = 'issuedBy',
  USER_LIST = 'userList',
  STATUS = 'status',
  DENIED_BY = 'deniedBy',
  DENIED_BY_BLACKOUT = 'deniedByBlackout',
  DENIED_BY_NO_PARTY = 'deniedByNoParty',
}

export enum HallPassStudentCreatedFilter {
  ALL = 'ALL',
  STUDENT_CREATED = 'STUDENT_CREATED',
  NON_STUDENT_CREATED = 'NON_STUDENT_CREATED',
}

export const HPM_REPORT_FILTER_DENIED_BY_USER = -1;

export type HpmReportsFilters = ReportFilterType & {
  [HpmReportsFilter.ISSUED_BY]: string[];
  [HpmReportsFilter.ISSUED_TO]: string[];
  [HpmReportsFilter.HALLPASS_TYPE]: number[];
  [HpmReportsFilter.CREATED_BY]: HallPassStudentCreatedFilter;
  [HpmReportsFilter.START_DATE]?: day.Dayjs;
  [HpmReportsFilter.END_DATE]?: day.Dayjs;
  [HpmReportsFilter.USER_LIST]: number[];
  [HpmReportsFilter.STATUS]: HallPassStatusEnum[];
  [HpmReportsFilter.DENIED_BY]: number[];
  [HpmReportsFilter.DENIED_BY_BLACKOUT]: number[];
  [HpmReportsFilter.DENIED_BY_NO_PARTY]: number[];
};
