import { Injectable } from '@angular/core';

import * as localforage from 'localforage';
import { Update } from '@ngrx/entity';
import { select, Store } from '@ngrx/store';
import { IMingaSetting, MingaMinimalModel } from 'libs/domain';
import { IMingaFeatureToggle } from 'libs/domain';
import { IMingaSubscription } from 'libs/domain';
import { mingaSettingTypes } from 'libs/util';
import { Observable } from 'rxjs';
import { filter, map, take, tap } from 'rxjs/operators';

import { MingaManagerService } from '@app/src/app/services/MingaManager';
import {
  fetchAllMingas,
  setCurrentMinga,
  setMingaCollection,
  updateSettings,
} from '@app/src/app/store/Minga/actions';
import {
  getActiveMingaSubscription,
  getAllMingas,
  getCurrentMinga,
  getMingaByHash,
  getSettingValue,
  getModuleEnabled,
} from '@app/src/app/store/Minga/selectors';

@Injectable({ providedIn: 'root' })
export class MingaStoreFacadeService {
  private mingaInfo$: Observable<MingaMinimalModel> = this.store.pipe(
    select(getCurrentMinga),
  );

  /**
   * The active minga subscription or null. Technically the store can have a
   * null state for active minga subscriptions, but this is rare and should only
   * be happening between the fetch.
   *
   * @NOTE you should prefer use of `activeMingaSubscription$` over this
   */
  readonly activeMingaSubscriptionOrNull$: Observable<IMingaSubscription | null> =
    this.store.pipe(select(getActiveMingaSubscription));

  /**
   * The active minga subscription
   */
  readonly activeMingaSubscription$ = this.activeMingaSubscriptionOrNull$.pipe(
    filter(activeSubscription => !!activeSubscription),
    // The map is just for the typings
    map(activeSubscription => activeSubscription!),
  );

  constructor(
    private store: Store<any>,
    private _mingaManager: MingaManagerService,
  ) {}

  /**
   * Function to call on initial startup.
   */
  public async startup() {
    await this._loadCachedMingaSettings();
  }

  /**
   * Set the store's minga info.
   *
   * @param mingaInfo
   */
  public async setMingaSettings(mingaMinimal: MingaMinimalModel) {
    this.store.dispatch(setMingaCollection({ payload: [mingaMinimal] }));
    this.store.dispatch(setCurrentMinga({ payload: mingaMinimal }));
    this._storeCachedMingaSettings(mingaMinimal);
  }

  /**
   * Store the minga info in local storage.
   *
   * @param mingaMinimal
   */
  private async _storeCachedMingaSettings(mingaMinimal: MingaMinimalModel) {
    if (mingaMinimal) {
      await localforage
        .setItem('cachedMinga', mingaMinimal)
        .catch(console.error);
    }
  }

  /**
   * clear stored info, called in rootEffects on logout.
   */
  public async clearCachedMingaSettings() {
    await localforage.setItem('cachedMinga', null).catch(console.error);
  }

  /**
   * Load minga info from local storage.
   */
  private async _loadCachedMingaSettings() {
    await localforage
      .getItem('cachedMinga')
      .then((mingaMinimal: MingaMinimalModel) => {
        if (mingaMinimal) {
          this.store.dispatch(setMingaCollection({ payload: [mingaMinimal] }));
          this.store.dispatch(setCurrentMinga({ payload: mingaMinimal }));
        }
      })
      .catch(console.error);
  }

  public async getMingaAsPromise(): Promise<MingaMinimalModel> {
    return this.mingaInfo$
      .pipe(
        filter(minga => minga != null),
        take(1),
      )
      .toPromise();
  }
  public getMingaAsObservable(): Observable<MingaMinimalModel> {
    return this.mingaInfo$;
  }

  /**
   * Convenient function for district minga feature check.
   */
  public async hasDistrictFeatureEnabled(): Promise<boolean> {
    return this.observeDistrictFeatureEnabled().pipe(take(1)).toPromise();
  }

  /** Conventient function to observe the district minga feature */
  public observeDistrictFeatureEnabled(): Observable<boolean> {
    return this.store.select(
      getSettingValue(mingaSettingTypes.FEATURE_DISTRICT_ENABLED),
    );
  }

  public fetchAllMingas() {
    this.store.dispatch(fetchAllMingas());
  }

  public getMingaByHash(hash: string) {
    const minga = this.store.select<MingaMinimalModel>(getMingaByHash, hash);
    return minga;
  }

  public getAllMingas(): Observable<MingaMinimalModel[]> {
    return this.store.select<MingaMinimalModel[]>(getAllMingas);
  }

  /**
   * Update the logo for the current minga, in store.
   *
   * @param mingaLogo
   */
  public async setMingaImageUrl(mingaLogo: string) {
    const storedMinga = await this.getMingaAsPromise();
    storedMinga.logo = mingaLogo;
    this.setMingaSettings(storedMinga);
  }
}
