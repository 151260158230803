import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule } from '@angular/forms';
import { MatChipsModule } from '@angular/material/chips';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';

import { TranslateModule } from '@ngx-translate/core';

import { MgButtonModule } from '@app/src/app/button';
import { MgAvatarModule } from '@app/src/app/elements/MgAvatar';
import { MgFormFieldModule } from '@app/src/app/form-field';
import { MgIconModule } from '@app/src/app/icon';
import { MgOverlayToolsModule } from '@app/src/app/overlay/MgOverlayTools.module';
import { MgSpinnerModule } from '@app/src/app/spinner';

import { DmConversationCreateComponent } from './dm-conversation-create.component';

@NgModule({
  imports: [
    // Minga dependencies
    MgIconModule,
    MgSpinnerModule,
    MgButtonModule,
    MgAvatarModule,
    MgFormFieldModule,
    MgOverlayToolsModule,

    // External dependencies
    CommonModule,
    FormsModule,
    MatIconModule,
    MatInputModule,
    MatFormFieldModule,
    MatChipsModule,
    FlexLayoutModule,
    TranslateModule,
  ],
  declarations: [DmConversationCreateComponent],
  exports: [],
})
export class DirectMessageConversationCreateModule {}
