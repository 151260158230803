// GENERATED CODE -- DO NOT EDIT!

import {grpc} from '@improbable-eng/grpc-web';

import * as permission_permission_pb from '../permission/permission_pb';

export class PermissionOverrideAdmin {
  static readonly serviceName = "minga.permission.PermissionOverrideAdmin";

  static readonly SetPermissionOverride = {
    methodName: "SetPermissionOverride",
    service: PermissionOverrideAdmin,
    requestStream: false,
    responseStream: false,
    requestType: permission_permission_pb.SetPermissionOverrideRequest,
    responseType: permission_permission_pb.SetPermissionOverrideResponse,
  };

  static readonly UnsetPermissionOverride = {
    methodName: "UnsetPermissionOverride",
    service: PermissionOverrideAdmin,
    requestStream: false,
    responseStream: false,
    requestType: permission_permission_pb.UnsetPermissionOverrideRequest,
    responseType: permission_permission_pb.UnsetPermissionOverrideResponse,
  };

  static readonly GetPermissionOverrides = {
    methodName: "GetPermissionOverrides",
    service: PermissionOverrideAdmin,
    requestStream: false,
    responseStream: false,
    requestType: permission_permission_pb.GetPermissionOverridesRequest,
    responseType: permission_permission_pb.GetPermissionOverridesResponse,
  };

}

export class PermissionOverrideAdminClient {
  setPermissionOverride() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  unsetPermissionOverride() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  getPermissionOverrides() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
}
