import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { BehaviorSubject } from 'rxjs';

import { MingaSettingsService } from '@app/src/app/store/Minga/services';

import { SystemAlertSnackBarService } from '@shared/components/system-alert-snackbar';

import { StMessages } from '../../constants';
import { StudentToolsService } from '../../services/student-tools';

@Component({
  selector: 'mg-st-behaviours',
  templateUrl: './st-behaviours.component.html',
  styleUrls: ['./st-behaviours.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StBehavioursComponent implements OnInit {
  private _loading = new BehaviorSubject(true);
  public loading$ = this._loading.asObservable();
  public activeBehaviours$ = this._studentToolsService.activeBehaviors$;
  public historicBehaviours$ = this._studentToolsService.historicBehaviors$;
  public MESSAGES = StMessages;
  public isPbisEnabled$ = this._settingService.isPbisModuleEnabled();

  constructor(
    private _route: ActivatedRoute,
    private _studentToolsService: StudentToolsService,
    private _systemAlertSnackBar: SystemAlertSnackBarService,
    private _settingService: MingaSettingsService,
  ) {}

  ngOnInit(): void {
    this._getStudentBehaviours();
  }

  private async _getStudentBehaviours() {
    const personHash = this._route.snapshot.paramMap.get('hash');

    try {
      await this._studentToolsService.getStudentToolsBehaviors(personHash);
    } catch (e) {
      this._systemAlertSnackBar.error(this.MESSAGES.ERROR_LOADING_BEHAVIORS);
    } finally {
      this._loading.next(false);
    }
  }
}
