import { Injectable } from '@angular/core';

import { AdminPersonView } from 'libs/generated-grpc-web';
import {
  people_collection_ng_grpc_pb,
  people_collection_pb,
  order_pb as order_by_pb,
} from 'libs/generated-grpc-web';

import { downloadjs } from '@app/src/app/util/downloadjs';

import { PsData, PsFilterState, PsSearchType, PsUploadRow } from '../../types';

@Injectable()
export class PsCollectionSearchImplService {
  /** Service Constructor */
  constructor(
    private _peopleCollectionService: people_collection_ng_grpc_pb.PeopleCollection,
  ) {}

  public async collectionSearch(
    type: PsSearchType,
    filters: PsFilterState,
    onlyVisibleRoles = false,
  ) {
    if (type === 'text') return this.fetchPeople(filters, onlyVisibleRoles);
    else return this.fetchPeopleByFile(filters);
  }

  public async fetchPeople(
    filter: PsFilterState,
    onlyVisibleRoles: boolean,
  ): Promise<PsData[]> {
    const { data } = await this._fetchPeople(
      filter,
      false,
      false,
      undefined,
      onlyVisibleRoles,
    );
    return data.map(this._mapPersonToPsData);
  }

  public async fetchPeopleAsStreamItem(
    filter: PsFilterState,
    orderBy?: order_by_pb.OrderBy.AsObject,
  ) {
    const { data, totalSize } = await this._fetchPeople(
      filter,
      true,
      true,
      orderBy,
    );
    const adaptedPeople = data.map(this._mapPersonToPsData);
    return { data: adaptedPeople, totalSize };
  }

  public async exportPeople(
    filter: PsFilterState,
    orderBy?: order_by_pb.OrderBy.AsObject,
  ) {
    const request = this._mapFilterToRequest(
      filter,
      true,
      true,
      orderBy,
      false,
    );
    const obs = this._peopleCollectionService.exportPeopleForSelector(request);
    let filename = '';
    let fileData = new Uint8Array(0);
    await new Promise((resolve, reject): any => {
      obs.subscribe(
        chunk => {
          if (chunk.hasChunk()) {
            const chunkData = chunk.getChunk_asU8();
            const newFileData = new Uint8Array(
              fileData.length + chunkData.length,
            );
            newFileData.set(fileData);
            newFileData.set(chunkData, fileData.length);
            fileData = newFileData;
          } else if (chunk.hasFilename()) {
            filename = chunk.getFilename();
          }
        },
        reject,
        resolve as any,
      );
    });

    // @TODO: @types/downloadjs doesn't agree with the typings here
    downloadjs(fileData as any, filename);
  }

  public async fetchPeopleByFile(filter: PsFilterState): Promise<PsData[]> {
    const request = new people_collection_pb.GetPeopleFromFileRequest();
    const { fileUploadedPeople } = filter;
    this._setBasicParams(request, filter);
    this._setFileParams(request, fileUploadedPeople);
    const result = await this._peopleCollectionService.getPeopleFromFile(
      request,
    );
    return result.getPeopleList().map(this._mapPersonToPsData);
  }

  private async _fetchPeople(
    filter: PsFilterState,
    roleType = false,
    bypassFilterCheck = false,
    orderBy?: order_by_pb.OrderBy.AsObject,
    onlyVisibleRoles = false,
  ) {
    const request = this._mapFilterToRequest(
      filter,
      roleType,
      bypassFilterCheck,
      orderBy,
      onlyVisibleRoles,
    );
    const response = await this._peopleCollectionService.getPeopleForSelector(
      request,
    );

    return {
      data: response.getPeopleList(),
      totalSize: response.getTotalSize(),
    };
  }

  private _mapFilterToRequest(
    filter: PsFilterState,
    roleType = false,
    bypassFilterCheck = false,
    orderBy?: order_by_pb.OrderBy.AsObject,
    onlyVisibleRoles = true,
  ) {
    const request = new people_collection_pb.GetPeopleForSelectorRequest();

    request.setRoleType(roleType);
    request.setBypassFilterCheck(bypassFilterCheck);
    request.setLimit(filter.limit);
    request.setOffset(filter.offset);
    request.setOnlyVisibleRoles(onlyVisibleRoles);

    if (orderBy) {
      const orderByMessage = new order_by_pb.OrderBy();
      orderByMessage.setField(orderBy.field);
      orderByMessage.setDirection(orderBy.direction);
      request.setOrderBy(orderByMessage);
    }

    if (typeof filter.registered === 'boolean') {
      if (filter.registered) request.setRegisteredOnly(filter.registered);
      else request.setUnregisteredOnly(!filter.registered);
    }
    const { keywords } = filter;
    request.setQuery(keywords);
    this._setBasicParams(request, filter);
    return request;
  }

  private _mapPersonToPsData(person: AdminPersonView): PsData {
    return {
      personHash: person.getPersonHash(),
      displayName: `${person.getFirstName()} ${person.getLastName()}`,
      firstName: person.getFirstName(),
      lastName: person.getLastName(),
      profilePicture: person.getProfileImageUrl(),
      badge: person.getBadgeIconUrl(),
      email: person.getEmailAddress(),
      studentId: person.getStudentId(),
      grade: person.getGrade(),
      role: person.getBadgeRoleName(),
    };
  }

  private _setBasicParams(
    request:
      | people_collection_pb.GetPeopleForSelectorRequest
      | people_collection_pb.GetPeopleFromFileRequest,
    filter: PsFilterState,
  ) {
    if (filter.grades?.length) request.setGradesList(filter.grades);
    if (filter.roles?.length) request.setRolesList(filter.roles);
    if (filter.groups?.length) request.setGroupsList(filter.groups);
    if (filter.schools?.length) request.setGroupsList(filter.schools);
    if (filter.lists?.length) request.setUserListsList(filter.lists);
  }

  private _setFileParams(
    request: people_collection_pb.GetPeopleFromFileRequest,
    people: PsUploadRow[],
  ) {
    people.forEach(person => {
      const searchFields = new people_collection_pb.PersonSearchFields();
      const {
        email = null,
        firstName = null,
        lastName = null,
        name = null,
        studentId = null,
      } = person;
      if (studentId) searchFields.setStudentId(studentId);
      if (email) searchFields.setEmail(email);
      // split name into first and last if necessary
      if (name && name.includes(' ')) {
        const splitName = name.split(' ');
        searchFields.setFirstName(splitName[0]);
        searchFields.setLastName(splitName[splitName.length - 1]);
      } else if (name) {
        searchFields.setFirstName(name);
      } else {
        if (firstName) searchFields.setFirstName(firstName);
        if (lastName) searchFields.setLastName(lastName);
      }
      request.addPerson(searchFields);
    });
  }
}
