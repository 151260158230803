import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { MgButtonModule } from '@app/src/app/button';
import { MgImageModule } from '@app/src/app/components/MgImage';
import { MgIconModule } from '@app/src/app/icon';
import { MgPipesModule } from '@app/src/app/pipes';

import { SuggestedContentComponent } from './SuggestedContent.component';

@NgModule({
  imports: [
    // Minga dependencies
    MgImageModule,
    MgPipesModule,
    MgButtonModule,
    MgIconModule,

    // External dependencies
    CommonModule,
    RouterModule,
  ],
  declarations: [SuggestedContentComponent],
  exports: [SuggestedContentComponent],
})
export class SuggestedContentModule {}
