import { Injectable, NgZone } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { grpc } from '@improbable-eng/grpc-web';

import {
  ArchiveFlexTimeActivityRequest,
  ArchiveFlexTimeActivityResponse,
  ArchiveFlexTimeRegistrationRequest,
  ArchiveFlexTimeRegistrationResponse,
  DeleteFlexTimeActivityInstanceRequest,
  DeleteFlexTimeActivityInstanceResponse,
  DeleteFlexTimeActivityRequest,
  DeleteFlexTimeActivityResponse,
  DeleteFlexTimePeriodRequest,
  DeleteFlexTimePeriodResponse,
  FlexTimeActivity,
  FlexTimePeriod,
  GetFlexTimeActivityRequest,
  GetFlexTimeActivityTypesRequest,
  GetFlexTimeActivityTypesResponse,
  GetFlexTimePeriodRequest,
  ListFlexTimeActivitiesRequest,
  ListFlexTimeActivitiesResponse,
  ListFlexTimeActivityInstancesRequest,
  ListFlexTimeActivityInstancesResponse,
  ListFlexTimePeriodsRequest,
  ListFlexTimePeriodsResponse,
  ListMinimalFlexTimePeriodsRequest,
  ListMinimalFlexTimePeriodsResponse,
  UpsertFlexTimeActivityInstanceRequest,
  UpsertFlexTimeActivityInstanceResponse,
  UpsertFlexTimeActivityRequest,
  UpsertFlexTimePeriodRequest,
  ValidateAssignRequest,
  ValidateAssignResponse,
} from '../flex_time/flex_time_pb';

import {
  FlexTimeManager as __FlexTimeManager,
} from '../flex_time/flex_time_pb_service';

@Injectable({providedIn: 'root'})
export class FlexTimeManager {

  constructor(private _ngZone: NgZone) {
  }

  listFlexTimePeriods(request: ListFlexTimePeriodsRequest): Promise<ListFlexTimePeriodsResponse>;
  listFlexTimePeriods(request: ListFlexTimePeriodsRequest, metadata: grpc.Metadata): Promise<ListFlexTimePeriodsResponse>;
  listFlexTimePeriods(request: ListFlexTimePeriodsRequest, callback: (err: any|null, response: ListFlexTimePeriodsResponse, metadata: grpc.Metadata) => void): void;
  listFlexTimePeriods(request: ListFlexTimePeriodsRequest, metadata: grpc.Metadata, callback: (err: any|null, response: ListFlexTimePeriodsResponse, metadata: grpc.Metadata) => void): void;

  listFlexTimePeriods(request: ListFlexTimePeriodsRequest, arg1?: grpc.Metadata|((err: any|null, response: ListFlexTimePeriodsResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: ListFlexTimePeriodsResponse, metadata: grpc.Metadata) => void): Promise<ListFlexTimePeriodsResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<ListFlexTimePeriodsResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__FlexTimeManager.ListFlexTimePeriods, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  listMinimalFlexTimePeriods(request: ListMinimalFlexTimePeriodsRequest): Promise<ListMinimalFlexTimePeriodsResponse>;
  listMinimalFlexTimePeriods(request: ListMinimalFlexTimePeriodsRequest, metadata: grpc.Metadata): Promise<ListMinimalFlexTimePeriodsResponse>;
  listMinimalFlexTimePeriods(request: ListMinimalFlexTimePeriodsRequest, callback: (err: any|null, response: ListMinimalFlexTimePeriodsResponse, metadata: grpc.Metadata) => void): void;
  listMinimalFlexTimePeriods(request: ListMinimalFlexTimePeriodsRequest, metadata: grpc.Metadata, callback: (err: any|null, response: ListMinimalFlexTimePeriodsResponse, metadata: grpc.Metadata) => void): void;

  listMinimalFlexTimePeriods(request: ListMinimalFlexTimePeriodsRequest, arg1?: grpc.Metadata|((err: any|null, response: ListMinimalFlexTimePeriodsResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: ListMinimalFlexTimePeriodsResponse, metadata: grpc.Metadata) => void): Promise<ListMinimalFlexTimePeriodsResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<ListMinimalFlexTimePeriodsResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__FlexTimeManager.ListMinimalFlexTimePeriods, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  upsertFlexTimePeriod(request: UpsertFlexTimePeriodRequest): Promise<FlexTimePeriod>;
  upsertFlexTimePeriod(request: UpsertFlexTimePeriodRequest, metadata: grpc.Metadata): Promise<FlexTimePeriod>;
  upsertFlexTimePeriod(request: UpsertFlexTimePeriodRequest, callback: (err: any|null, response: FlexTimePeriod, metadata: grpc.Metadata) => void): void;
  upsertFlexTimePeriod(request: UpsertFlexTimePeriodRequest, metadata: grpc.Metadata, callback: (err: any|null, response: FlexTimePeriod, metadata: grpc.Metadata) => void): void;

  upsertFlexTimePeriod(request: UpsertFlexTimePeriodRequest, arg1?: grpc.Metadata|((err: any|null, response: FlexTimePeriod, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: FlexTimePeriod, metadata: grpc.Metadata) => void): Promise<FlexTimePeriod>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<FlexTimePeriod>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__FlexTimeManager.UpsertFlexTimePeriod, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  cloneFlexTimePeriod(request: UpsertFlexTimePeriodRequest): Promise<FlexTimePeriod>;
  cloneFlexTimePeriod(request: UpsertFlexTimePeriodRequest, metadata: grpc.Metadata): Promise<FlexTimePeriod>;
  cloneFlexTimePeriod(request: UpsertFlexTimePeriodRequest, callback: (err: any|null, response: FlexTimePeriod, metadata: grpc.Metadata) => void): void;
  cloneFlexTimePeriod(request: UpsertFlexTimePeriodRequest, metadata: grpc.Metadata, callback: (err: any|null, response: FlexTimePeriod, metadata: grpc.Metadata) => void): void;

  cloneFlexTimePeriod(request: UpsertFlexTimePeriodRequest, arg1?: grpc.Metadata|((err: any|null, response: FlexTimePeriod, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: FlexTimePeriod, metadata: grpc.Metadata) => void): Promise<FlexTimePeriod>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<FlexTimePeriod>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__FlexTimeManager.CloneFlexTimePeriod, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  getFlexTimePeriod(request: GetFlexTimePeriodRequest): Promise<FlexTimePeriod>;
  getFlexTimePeriod(request: GetFlexTimePeriodRequest, metadata: grpc.Metadata): Promise<FlexTimePeriod>;
  getFlexTimePeriod(request: GetFlexTimePeriodRequest, callback: (err: any|null, response: FlexTimePeriod, metadata: grpc.Metadata) => void): void;
  getFlexTimePeriod(request: GetFlexTimePeriodRequest, metadata: grpc.Metadata, callback: (err: any|null, response: FlexTimePeriod, metadata: grpc.Metadata) => void): void;

  getFlexTimePeriod(request: GetFlexTimePeriodRequest, arg1?: grpc.Metadata|((err: any|null, response: FlexTimePeriod, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: FlexTimePeriod, metadata: grpc.Metadata) => void): Promise<FlexTimePeriod>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<FlexTimePeriod>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__FlexTimeManager.GetFlexTimePeriod, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  deleteFlexTimePeriod(request: DeleteFlexTimePeriodRequest): Promise<DeleteFlexTimePeriodResponse>;
  deleteFlexTimePeriod(request: DeleteFlexTimePeriodRequest, metadata: grpc.Metadata): Promise<DeleteFlexTimePeriodResponse>;
  deleteFlexTimePeriod(request: DeleteFlexTimePeriodRequest, callback: (err: any|null, response: DeleteFlexTimePeriodResponse, metadata: grpc.Metadata) => void): void;
  deleteFlexTimePeriod(request: DeleteFlexTimePeriodRequest, metadata: grpc.Metadata, callback: (err: any|null, response: DeleteFlexTimePeriodResponse, metadata: grpc.Metadata) => void): void;

  deleteFlexTimePeriod(request: DeleteFlexTimePeriodRequest, arg1?: grpc.Metadata|((err: any|null, response: DeleteFlexTimePeriodResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: DeleteFlexTimePeriodResponse, metadata: grpc.Metadata) => void): Promise<DeleteFlexTimePeriodResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<DeleteFlexTimePeriodResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__FlexTimeManager.DeleteFlexTimePeriod, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  listFlexTimeActivities(request: ListFlexTimeActivitiesRequest): Promise<ListFlexTimeActivitiesResponse>;
  listFlexTimeActivities(request: ListFlexTimeActivitiesRequest, metadata: grpc.Metadata): Promise<ListFlexTimeActivitiesResponse>;
  listFlexTimeActivities(request: ListFlexTimeActivitiesRequest, callback: (err: any|null, response: ListFlexTimeActivitiesResponse, metadata: grpc.Metadata) => void): void;
  listFlexTimeActivities(request: ListFlexTimeActivitiesRequest, metadata: grpc.Metadata, callback: (err: any|null, response: ListFlexTimeActivitiesResponse, metadata: grpc.Metadata) => void): void;

  listFlexTimeActivities(request: ListFlexTimeActivitiesRequest, arg1?: grpc.Metadata|((err: any|null, response: ListFlexTimeActivitiesResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: ListFlexTimeActivitiesResponse, metadata: grpc.Metadata) => void): Promise<ListFlexTimeActivitiesResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<ListFlexTimeActivitiesResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__FlexTimeManager.ListFlexTimeActivities, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  getFlexTimeActivity(request: GetFlexTimeActivityRequest): Promise<FlexTimeActivity>;
  getFlexTimeActivity(request: GetFlexTimeActivityRequest, metadata: grpc.Metadata): Promise<FlexTimeActivity>;
  getFlexTimeActivity(request: GetFlexTimeActivityRequest, callback: (err: any|null, response: FlexTimeActivity, metadata: grpc.Metadata) => void): void;
  getFlexTimeActivity(request: GetFlexTimeActivityRequest, metadata: grpc.Metadata, callback: (err: any|null, response: FlexTimeActivity, metadata: grpc.Metadata) => void): void;

  getFlexTimeActivity(request: GetFlexTimeActivityRequest, arg1?: grpc.Metadata|((err: any|null, response: FlexTimeActivity, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: FlexTimeActivity, metadata: grpc.Metadata) => void): Promise<FlexTimeActivity>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<FlexTimeActivity>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__FlexTimeManager.GetFlexTimeActivity, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  upsertFlexTimeActivity(request: UpsertFlexTimeActivityRequest): Promise<FlexTimeActivity>;
  upsertFlexTimeActivity(request: UpsertFlexTimeActivityRequest, metadata: grpc.Metadata): Promise<FlexTimeActivity>;
  upsertFlexTimeActivity(request: UpsertFlexTimeActivityRequest, callback: (err: any|null, response: FlexTimeActivity, metadata: grpc.Metadata) => void): void;
  upsertFlexTimeActivity(request: UpsertFlexTimeActivityRequest, metadata: grpc.Metadata, callback: (err: any|null, response: FlexTimeActivity, metadata: grpc.Metadata) => void): void;

  upsertFlexTimeActivity(request: UpsertFlexTimeActivityRequest, arg1?: grpc.Metadata|((err: any|null, response: FlexTimeActivity, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: FlexTimeActivity, metadata: grpc.Metadata) => void): Promise<FlexTimeActivity>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<FlexTimeActivity>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__FlexTimeManager.UpsertFlexTimeActivity, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  deleteFlexTimeActivity(request: DeleteFlexTimeActivityRequest): Promise<DeleteFlexTimeActivityResponse>;
  deleteFlexTimeActivity(request: DeleteFlexTimeActivityRequest, metadata: grpc.Metadata): Promise<DeleteFlexTimeActivityResponse>;
  deleteFlexTimeActivity(request: DeleteFlexTimeActivityRequest, callback: (err: any|null, response: DeleteFlexTimeActivityResponse, metadata: grpc.Metadata) => void): void;
  deleteFlexTimeActivity(request: DeleteFlexTimeActivityRequest, metadata: grpc.Metadata, callback: (err: any|null, response: DeleteFlexTimeActivityResponse, metadata: grpc.Metadata) => void): void;

  deleteFlexTimeActivity(request: DeleteFlexTimeActivityRequest, arg1?: grpc.Metadata|((err: any|null, response: DeleteFlexTimeActivityResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: DeleteFlexTimeActivityResponse, metadata: grpc.Metadata) => void): Promise<DeleteFlexTimeActivityResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<DeleteFlexTimeActivityResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__FlexTimeManager.DeleteFlexTimeActivity, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  archiveFlexTimeActivity(request: ArchiveFlexTimeActivityRequest): Promise<ArchiveFlexTimeActivityResponse>;
  archiveFlexTimeActivity(request: ArchiveFlexTimeActivityRequest, metadata: grpc.Metadata): Promise<ArchiveFlexTimeActivityResponse>;
  archiveFlexTimeActivity(request: ArchiveFlexTimeActivityRequest, callback: (err: any|null, response: ArchiveFlexTimeActivityResponse, metadata: grpc.Metadata) => void): void;
  archiveFlexTimeActivity(request: ArchiveFlexTimeActivityRequest, metadata: grpc.Metadata, callback: (err: any|null, response: ArchiveFlexTimeActivityResponse, metadata: grpc.Metadata) => void): void;

  archiveFlexTimeActivity(request: ArchiveFlexTimeActivityRequest, arg1?: grpc.Metadata|((err: any|null, response: ArchiveFlexTimeActivityResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: ArchiveFlexTimeActivityResponse, metadata: grpc.Metadata) => void): Promise<ArchiveFlexTimeActivityResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<ArchiveFlexTimeActivityResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__FlexTimeManager.ArchiveFlexTimeActivity, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  listFlexTimeActivityInstances(request: ListFlexTimeActivityInstancesRequest): Promise<ListFlexTimeActivityInstancesResponse>;
  listFlexTimeActivityInstances(request: ListFlexTimeActivityInstancesRequest, metadata: grpc.Metadata): Promise<ListFlexTimeActivityInstancesResponse>;
  listFlexTimeActivityInstances(request: ListFlexTimeActivityInstancesRequest, callback: (err: any|null, response: ListFlexTimeActivityInstancesResponse, metadata: grpc.Metadata) => void): void;
  listFlexTimeActivityInstances(request: ListFlexTimeActivityInstancesRequest, metadata: grpc.Metadata, callback: (err: any|null, response: ListFlexTimeActivityInstancesResponse, metadata: grpc.Metadata) => void): void;

  listFlexTimeActivityInstances(request: ListFlexTimeActivityInstancesRequest, arg1?: grpc.Metadata|((err: any|null, response: ListFlexTimeActivityInstancesResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: ListFlexTimeActivityInstancesResponse, metadata: grpc.Metadata) => void): Promise<ListFlexTimeActivityInstancesResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<ListFlexTimeActivityInstancesResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__FlexTimeManager.ListFlexTimeActivityInstances, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  upsertFlexTimeActivityInstance(request: UpsertFlexTimeActivityInstanceRequest): Promise<UpsertFlexTimeActivityInstanceResponse>;
  upsertFlexTimeActivityInstance(request: UpsertFlexTimeActivityInstanceRequest, metadata: grpc.Metadata): Promise<UpsertFlexTimeActivityInstanceResponse>;
  upsertFlexTimeActivityInstance(request: UpsertFlexTimeActivityInstanceRequest, callback: (err: any|null, response: UpsertFlexTimeActivityInstanceResponse, metadata: grpc.Metadata) => void): void;
  upsertFlexTimeActivityInstance(request: UpsertFlexTimeActivityInstanceRequest, metadata: grpc.Metadata, callback: (err: any|null, response: UpsertFlexTimeActivityInstanceResponse, metadata: grpc.Metadata) => void): void;

  upsertFlexTimeActivityInstance(request: UpsertFlexTimeActivityInstanceRequest, arg1?: grpc.Metadata|((err: any|null, response: UpsertFlexTimeActivityInstanceResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: UpsertFlexTimeActivityInstanceResponse, metadata: grpc.Metadata) => void): Promise<UpsertFlexTimeActivityInstanceResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<UpsertFlexTimeActivityInstanceResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__FlexTimeManager.UpsertFlexTimeActivityInstance, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  deleteFlexTimeActivityInstance(request: DeleteFlexTimeActivityInstanceRequest): Promise<DeleteFlexTimeActivityInstanceResponse>;
  deleteFlexTimeActivityInstance(request: DeleteFlexTimeActivityInstanceRequest, metadata: grpc.Metadata): Promise<DeleteFlexTimeActivityInstanceResponse>;
  deleteFlexTimeActivityInstance(request: DeleteFlexTimeActivityInstanceRequest, callback: (err: any|null, response: DeleteFlexTimeActivityInstanceResponse, metadata: grpc.Metadata) => void): void;
  deleteFlexTimeActivityInstance(request: DeleteFlexTimeActivityInstanceRequest, metadata: grpc.Metadata, callback: (err: any|null, response: DeleteFlexTimeActivityInstanceResponse, metadata: grpc.Metadata) => void): void;

  deleteFlexTimeActivityInstance(request: DeleteFlexTimeActivityInstanceRequest, arg1?: grpc.Metadata|((err: any|null, response: DeleteFlexTimeActivityInstanceResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: DeleteFlexTimeActivityInstanceResponse, metadata: grpc.Metadata) => void): Promise<DeleteFlexTimeActivityInstanceResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<DeleteFlexTimeActivityInstanceResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__FlexTimeManager.DeleteFlexTimeActivityInstance, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  getFlexTimeActivityTypes(request: GetFlexTimeActivityTypesRequest): Promise<GetFlexTimeActivityTypesResponse>;
  getFlexTimeActivityTypes(request: GetFlexTimeActivityTypesRequest, metadata: grpc.Metadata): Promise<GetFlexTimeActivityTypesResponse>;
  getFlexTimeActivityTypes(request: GetFlexTimeActivityTypesRequest, callback: (err: any|null, response: GetFlexTimeActivityTypesResponse, metadata: grpc.Metadata) => void): void;
  getFlexTimeActivityTypes(request: GetFlexTimeActivityTypesRequest, metadata: grpc.Metadata, callback: (err: any|null, response: GetFlexTimeActivityTypesResponse, metadata: grpc.Metadata) => void): void;

  getFlexTimeActivityTypes(request: GetFlexTimeActivityTypesRequest, arg1?: grpc.Metadata|((err: any|null, response: GetFlexTimeActivityTypesResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: GetFlexTimeActivityTypesResponse, metadata: grpc.Metadata) => void): Promise<GetFlexTimeActivityTypesResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<GetFlexTimeActivityTypesResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__FlexTimeManager.GetFlexTimeActivityTypes, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  validateAssign(request: ValidateAssignRequest): Promise<ValidateAssignResponse>;
  validateAssign(request: ValidateAssignRequest, metadata: grpc.Metadata): Promise<ValidateAssignResponse>;
  validateAssign(request: ValidateAssignRequest, callback: (err: any|null, response: ValidateAssignResponse, metadata: grpc.Metadata) => void): void;
  validateAssign(request: ValidateAssignRequest, metadata: grpc.Metadata, callback: (err: any|null, response: ValidateAssignResponse, metadata: grpc.Metadata) => void): void;

  validateAssign(request: ValidateAssignRequest, arg1?: grpc.Metadata|((err: any|null, response: ValidateAssignResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: ValidateAssignResponse, metadata: grpc.Metadata) => void): Promise<ValidateAssignResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<ValidateAssignResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__FlexTimeManager.ValidateAssign, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  archiveFlexTimeRegistration(request: ArchiveFlexTimeRegistrationRequest): Promise<ArchiveFlexTimeRegistrationResponse>;
  archiveFlexTimeRegistration(request: ArchiveFlexTimeRegistrationRequest, metadata: grpc.Metadata): Promise<ArchiveFlexTimeRegistrationResponse>;
  archiveFlexTimeRegistration(request: ArchiveFlexTimeRegistrationRequest, callback: (err: any|null, response: ArchiveFlexTimeRegistrationResponse, metadata: grpc.Metadata) => void): void;
  archiveFlexTimeRegistration(request: ArchiveFlexTimeRegistrationRequest, metadata: grpc.Metadata, callback: (err: any|null, response: ArchiveFlexTimeRegistrationResponse, metadata: grpc.Metadata) => void): void;

  archiveFlexTimeRegistration(request: ArchiveFlexTimeRegistrationRequest, arg1?: grpc.Metadata|((err: any|null, response: ArchiveFlexTimeRegistrationResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: ArchiveFlexTimeRegistrationResponse, metadata: grpc.Metadata) => void): Promise<ArchiveFlexTimeRegistrationResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<ArchiveFlexTimeRegistrationResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__FlexTimeManager.ArchiveFlexTimeRegistration, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

}

