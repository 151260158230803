import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';

import { MgButtonModule } from '@app/src/app/button';
import { PeopleSearchInputModule } from '@app/src/app/components/people-search-input';
import { MgFormFieldModule } from '@app/src/app/form-field';
import { MgIconModule } from '@app/src/app/icon';
import { ScrollTargetDirectiveModule } from '@app/src/app/misc/ScrollTarget/directive';
import { MgSpinnerModule } from '@app/src/app/spinner';

import { FormModule } from '@shared/components/form';

import { ManagerReportHeaderComponent } from './manager-report-header.component';

@NgModule({
  imports: [
    // Minga dependencies
    MgButtonModule,
    MgIconModule,
    MgFormFieldModule,
    MgSpinnerModule,
    ScrollTargetDirectiveModule,
    PeopleSearchInputModule,
    FormModule,

    // External dependencies
    CommonModule,
    FlexLayoutModule,
    FormsModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatSelectModule,
    MatDatepickerModule,
    ReactiveFormsModule,
  ],
  declarations: [ManagerReportHeaderComponent],
  exports: [ManagerReportHeaderComponent],
})
export class ManagerReportHeaderModule {}
