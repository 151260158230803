import { IPbisClassTypeCount } from 'libs/domain';
import { class_types_pb } from 'libs/generated-grpc-ts';

export const toProto = (
  typeCount: IPbisClassTypeCount,
): class_types_pb.PbisTypeCount => {
  const msg = new class_types_pb.PbisTypeCount();

  if (typeCount.id) msg.setId(typeCount.id);
  if (typeCount.name) msg.setName(typeCount.name);
  if (typeCount.categoryId) msg.setCategoryId(typeCount.categoryId);
  if (typeCount.iconType) msg.setIconType(typeCount.iconType);
  if (typeCount.iconColor) msg.setIconColor(typeCount.iconColor);
  if (typeCount.active) msg.setActive(typeCount.active);

  if (typeCount.count) msg.setCount(typeCount.count);

  return msg;
};

export const fromProto = (
  msg: class_types_pb.PbisTypeCount,
): IPbisClassTypeCount => {
  const typeCounts: IPbisClassTypeCount = {
    count: msg.getCount(),
    id: msg.getId(),
    name: msg.getName(),
    categoryId: msg.getCategoryId(),
    iconColor: msg.getIconColor(),
    iconType: msg.getIconType(),
    active: msg.getActive(),
  };
  return typeCounts;
};
