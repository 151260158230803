import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { take } from 'rxjs/operators';

import { BarcodeScanner } from '@app/src/app/barcodeScanner';
import { RootService } from '@app/src/app/minimal/services/RootService';
import { ChallengeInvitesService } from '@app/src/app/modules/challenges/services';

import { PsData } from '@modules/people-selector/types';

import { SystemAlertSnackBarService } from '@shared/components/system-alert-snackbar';

import { PeopleSelectorService } from '../people-selector.service';
import { PeopleSelectorFormService } from '../ps-form.service';
import { PsCollectionSearchImplService } from '../search-impl/ps-collection-search.impl.service';

@Injectable()
export class PsChallengeService extends PeopleSelectorFormService<'Challenge'> {
  /** Service Constructor */
  constructor(
    public router: Router,
    public snackbar: SystemAlertSnackBarService,
    public peopleSelector: PeopleSelectorService,
    public barCodeScanner: BarcodeScanner,
    private _psCollectionSearch: PsCollectionSearchImplService,
    private _challengeInvitesService: ChallengeInvitesService,
    private _rootService: RootService,
  ) {
    super(
      {
        name: 'Challenge',
        pageDefinitions: {
          invite: {
            submitFn: async => this.submitInvite(),
            searchFn: async =>
              this._psCollectionSearch.collectionSearch('text', undefined),
          },
          invited: {
            submitFn: async => this.submitInvite(),
            searchFn: async => this.fetchInvites(),
          },
        },
      },
      router,
      snackbar,
      barCodeScanner,
      peopleSelector,
    );

    this.setConfig({
      name: 'Challenge',
      pageDefinitions: {
        invite: {
          submitFn: async () => this.submitInvite(),
          searchFn: async (type, filters) =>
            this._psCollectionSearch.collectionSearch(type, filters),
        },
        invited: {
          submitFn: async () => {
            return;
          },
          searchFn: async () => this.fetchInvites(),
        },
      },
    });
  }

  public async submitInvite(): Promise<void> {
    const personHashes = await this.selection.selectedHashes$
      .pipe(take(1))
      .toPromise();
    const { contextHash } = this.data;
    try {
      await this._rootService.addLoadingPromise(
        this._createChallengeInvite(contextHash, personHashes),
      );
      this.snackbar.success('Invitation(s) successfully sent!');
    } catch {
      this.snackbar.error(
        'Something went wrong when inviting, please try again later',
      );
    }
  }

  public async fetchInvites(): Promise<PsData[]> {
    const { contextHash } = this.data;
    return await this._fetchChallengeInvites(contextHash);
  }

  private async _createChallengeInvite(
    challengeHash: string,
    personHashes: string[],
  ) {
    await this._challengeInvitesService.invitePeopleToChallenge(
      challengeHash,
      personHashes,
    );
  }

  private async _fetchChallengeInvites(
    challengeHash: string,
  ): Promise<PsData[]> {
    const invited = await this._challengeInvitesService.fetchChallengeInvites(
      challengeHash,
    );

    return invited.map(invite => {
      const person = invite.authorPersonView.personInfo;
      return {
        personHash: person.personHash,
        displayName: person.displayName,
        badge: person.badgeRoleName,
        studentId: person.studentId,
        email: invite.authorPersonView.personEmail,
      };
    });
  }
}
