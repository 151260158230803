// GENERATED CODE -- DO NOT EDIT!

import {grpc} from '@improbable-eng/grpc-web';

import * as content_challenge_challenge_response_pb from '../../content/challenge/challenge_response_pb';
import * as content_common_pb from '../../content/common_pb';
import * as content_video_upload_video_upload_pb from '../../content/video_upload/video_upload_pb';
import * as gateway_person_view_pb from '../../gateway/person_view_pb';
import * as gateway_comment_pb from '../../gateway/comment_pb';
import * as common_date_pb from '../../common/date_pb';
import * as common_stream_pb from '../../common/stream_pb';
import * as image_image_pb from '../../image/image_pb';

export class ChallengeResponse {
  static readonly serviceName = "minga.content.ChallengeResponse";

  static readonly New = {
    methodName: "New",
    service: ChallengeResponse,
    requestStream: false,
    responseStream: false,
    requestType: content_challenge_challenge_response_pb.ChallengeResponseInfo,
    responseType: content_common_pb.ContentSaveResult,
  };

  static readonly GetInfo = {
    methodName: "GetInfo",
    service: ChallengeResponse,
    requestStream: false,
    responseStream: false,
    requestType: content_common_pb.ContentInfoQuery,
    responseType: content_challenge_challenge_response_pb.ChallengeResponseInfo,
  };

  static readonly StreamResponsesAdmin = {
    methodName: "StreamResponsesAdmin",
    service: ChallengeResponse,
    requestStream: false,
    responseStream: true,
    requestType: common_stream_pb.StreamID,
    responseType: content_challenge_challenge_response_pb.StreamChallengeResponsesAdminResponse,
  };

  static readonly StreamResponsesAdminControl = {
    methodName: "StreamResponsesAdminControl",
    service: ChallengeResponse,
    requestStream: false,
    responseStream: false,
    requestType: common_stream_pb.StreamControl,
    responseType: common_stream_pb.StreamControlResponse,
  };

  static readonly StreamResponses = {
    methodName: "StreamResponses",
    service: ChallengeResponse,
    requestStream: false,
    responseStream: true,
    requestType: common_stream_pb.StreamID,
    responseType: content_challenge_challenge_response_pb.StreamChallengeResponseCardResponse,
  };

  static readonly StreamResponsesControl = {
    methodName: "StreamResponsesControl",
    service: ChallengeResponse,
    requestStream: false,
    responseStream: false,
    requestType: common_stream_pb.StreamControl,
    responseType: common_stream_pb.StreamControlResponse,
  };

  static readonly ApproveOrDeclineResponse = {
    methodName: "ApproveOrDeclineResponse",
    service: ChallengeResponse,
    requestStream: false,
    responseStream: false,
    requestType: content_challenge_challenge_response_pb.ApproveOrDeclineResponseRequest,
    responseType: content_challenge_challenge_response_pb.ApproveOrDeclineResponseResponse,
  };

}

export class ChallengeResponseClient {
  new() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  getInfo() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  streamResponsesAdmin() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  streamResponsesAdminControl() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  streamResponses() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  streamResponsesControl() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  approveOrDeclineResponse() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
}
