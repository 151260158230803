import { Component, HostBinding, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { PermissionsService } from '@app/src/app/permissions';

@Component({
  selector: 'mg-dialog-welcome',
  templateUrl: './WelcomeDialog.component.html',
  styleUrls: ['./WelcomeDialog.component.scss'],
})
export class WelcomeDialog {
  @HostBinding('class.ios-browser')
  readonly iosBrowser = window.MINGA_DEVICE_IOS && window.MINGA_APP_BROWSER;
  mingaName: string = '';
  enableGroups: boolean = false;

  constructor(
    private dialogRef: MatDialogRef<WelcomeDialog>,
    private permissions: PermissionsService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.mingaName = data?.mingaName || '';
    this.enableGroups = 'enableGroups' in data ? !!data.enableGroups : false;
  }

  isUserRole(roleType: string) {
    return this.permissions.hasRoleType(roleType);
  }

  onKeyDown(e: KeyboardEvent) {
    if (e.key === 'Enter') {
      this.close(true);
    }
  }

  cancel() {
    this.dialogRef.close(null);
  }

  close(save: boolean = true) {
    // Delay on purpose to feel like you hit the correct option
    setTimeout(() => {
      this.dialogRef.close(save);
    }, 300);
  }
}
