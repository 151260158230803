import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { closeCurrentOverlay } from '@app/src/app/util/overlay';

@Injectable({ providedIn: 'root' })
export class ConversationNavigator {
  constructor(private router: Router) {}

  /**
   * Navigate to conversation page by it's ID
   */
  navigateByConversationId(conversationId: number, verbose: boolean = false) {
    const conversationPath = verbose ? 'verbose~c' : 'c';
    return this.router.navigate([
      '',
      { outlets: { o: ['messaging', conversationPath, conversationId] } },
    ]);
  }

  /**
   * Navigate to conversation people overlay by it's ID
   */
  navigatePeopleByConversationId(conversationId: number) {
    return this.router.navigate([
      '',
      {
        outlets: {
          o: ['messaging', 'c~people', conversationId],
        },
      },
    ]);
  }

  /**
   * Navigate to create conversation
   */
  navigateCreateConversation() {
    return this.router.navigate([
      '',
      { outlets: { o: ['messaging', 'create'] } },
    ]);
  }

  /**
   * Navigate to conversation home
   */
  navigateHome() {
    return this.router.navigate(['messaging', { outlets: { o: null } }]);
  }

  /**
   * Exit out of direct messaging
   */
  navigateExit() {
    return closeCurrentOverlay(this.router);
  }
}
