import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';

import { MgFormFieldModule } from '@app/src/app/form-field';

import { CalendarScheduleOptionsComponent } from './CalendarScheduleOptions.component';

@NgModule({
  imports: [
    // Minga dependencies
    MgFormFieldModule,

    // External dependencies
    CommonModule,
    FormsModule,
    MatFormFieldModule,
    MatCheckboxModule,
    MatSelectModule,

    MatButtonToggleModule,
  ],
  declarations: [CalendarScheduleOptionsComponent],
  exports: [CalendarScheduleOptionsComponent],
})
export class CalendarScheduleOptionsModule {}
