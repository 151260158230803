import { IAssetProcessedPaths } from 'libs/domain';
import { IMessage, IMessageAttachment, IMessageStatus } from 'libs/domain';
import { image_pb, messaging_pb } from 'libs/generated-grpc-ts';

export namespace MessageMapper {
  export function fromProtoMessageAttachmentToIMessageAttachment(
    attachments: messaging_pb.Message.MessageAttachment[],
  ): IMessageAttachment[] {
    return attachments.map(attachment => {
      const msgAttachmentList: IMessageAttachment = {};

      const image = attachment.getImage();
      if (image) {
        const imageInfo = image.toObject();

        const paths: IAssetProcessedPaths = {};
        imageInfo.sizeMap.forEach(entry => {
          paths[entry[0]] = entry[1];
        });

        msgAttachmentList.image = paths;
      }

      return msgAttachmentList;
    });
  }

  export function fromIMessageAttachmentToProtoMessageAttachment(
    attachmentList: IMessageAttachment[],
  ): messaging_pb.Message.MessageAttachment[] {
    return attachmentList.map(attachment => {
      const attachMsg = new messaging_pb.Message.MessageAttachment();
      if (attachment.image) {
        const imageInfo = new image_pb.ImageInfo();
        const sizeMap = imageInfo.getSizeMap();

        for (let sizeKey in attachment.image) {
          const imgSize = new image_pb.ImageSize();
          const sizePath = attachment.image[sizeKey];
          imgSize.setPath(sizePath.path);
          imgSize.setWidth(sizePath.width);
          imgSize.setHeight(sizePath.height);

          sizeMap.set(sizeKey, imgSize);
        }

        attachMsg.setImage(imageInfo);
      }

      return attachMsg;
    });
  }

  export function fromIMessage(message: IMessage): messaging_pb.Message {
    const msg = new messaging_pb.Message();

    msg.setId(message.id);
    msg.setBody(message.body);
    msg.setTimestamp(message.createdDate.getTime());
    msg.setAuthorPerson(message.authorPersonHash);

    const attachmentList = fromIMessageAttachmentToProtoMessageAttachment(
      message.attachmentList,
    );
    msg.setAttachmentList(attachmentList);

    const statusMap = msg.getStatusMap();

    for (const [hash, status] of message.statusMap.entries()) {
      const statusMsg = new messaging_pb.Message.MessageStatus();
      if (status.readAt) {
        statusMsg.setReadAtTimestamp(status.readAt.getTime());
      }
      statusMap.set(hash, statusMsg);
    }

    return msg;
  }

  export function toIMessage(message: messaging_pb.Message): IMessage {
    const msgStatusMap = message.getStatusMap();
    const statusMap = new Map<string, IMessageStatus>();

    msgStatusMap.forEach((statusMsg, hash) => {
      const timestamp = statusMsg.getReadAtTimestamp();

      statusMap.set(hash, {
        readAt: timestamp ? new Date(timestamp) : null,
      });
    });

    const attachmentList = fromProtoMessageAttachmentToIMessageAttachment(
      message.getAttachmentList(),
    );

    return {
      id: message.getId(),
      body: message.getBody(),
      authorPersonHash: message.getAuthorPerson(),
      createdDate: new Date(message.getTimestamp()),
      statusMap,
      attachmentList,
    };
  }
}
