import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';

import { TranslateModule } from '@ngx-translate/core';
import { NgScrollbarModule } from 'ngx-scrollbar';

import { MgButtonModule } from '@app/src/app/button';
import { MgEmptyStateModule } from '@app/src/app/components/EmptyState';
import { AddressModule } from '@app/src/app/components/Input/Address';
import { MgEmailModule } from '@app/src/app/components/Input/Email';
import { FileInputModule } from '@app/src/app/components/Input/FileInput';
import { FileUploadModule } from '@app/src/app/components/Input/FileUpload';
import { MingaNameModule } from '@app/src/app/components/Input/MingaName';
import { MgImageModule } from '@app/src/app/components/MgImage';
import { MgDateTimePickerModule } from '@app/src/app/date-time-picker';
import { SaveCancelDialogModule } from '@app/src/app/dialog/SaveCancel';
import { MgAvatarModule } from '@app/src/app/elements/MgAvatar';
import { MgFormFieldModule } from '@app/src/app/form-field';
import { MgIconModule } from '@app/src/app/icon';
import { MgOverlayToolsModule } from '@app/src/app/overlay/tools';
import { PermissionsModule } from '@app/src/app/permissions';
import { MgPipesModule } from '@app/src/app/pipes';
import { DestroyMingaDialogModule } from '@app/src/app/routes/sadmin/minga/DestroyMingaDialog';
import { MgSpinnerModule } from '@app/src/app/spinner';

import { BarChartModule } from '@shared/components/bar-chart';
import { CollapsibleModule } from '@shared/components/collapsible';
import { CountTileModule } from '@shared/components/count-tile';
import { FormModule } from '@shared/components/form';
import { GenericModule } from '@shared/components/generic';
import { ManagerOverlayModule } from '@shared/components/manager-overlay';
import { OverlayModule } from '@shared/components/modal-overlay';
import { ArrayPipesModule } from '@shared/pipes';

import { MmReportsComponent } from './mm-reports.component';

@NgModule({
  imports: [
    // Minga dependencies
    ManagerOverlayModule,
    CollapsibleModule,
    MgEmptyStateModule,
    MgSpinnerModule,
    FormModule,
    MgIconModule,
    OverlayModule,
    PermissionsModule,
    MgPipesModule,
    MgImageModule,
    MgButtonModule,
    MgAvatarModule,
    MgDateTimePickerModule,
    MgEmailModule,
    AddressModule,
    MingaNameModule,
    FileUploadModule,
    FileInputModule,
    MgFormFieldModule,
    DestroyMingaDialogModule,
    SaveCancelDialogModule,
    MgOverlayToolsModule,
    CountTileModule,
    ArrayPipesModule,
    BarChartModule,
    GenericModule,

    // External dependencies
    CommonModule,
    MatTableModule,
    MatSortModule,
    FlexLayoutModule,
    MatPaginatorModule,
    NgScrollbarModule,
    MatButtonModule,
    FormsModule,
    MatInputModule,
    MatSelectModule,
    MatCheckboxModule,
    MatButtonToggleModule,
    MatMenuModule,
    ReactiveFormsModule,
    MatExpansionModule,
    TranslateModule,
    MatFormFieldModule,
    MatIconModule,
  ],
  providers: [],
  declarations: [MmReportsComponent],
  exports: [MmReportsComponent],
})
export class MmReportsModule {}
