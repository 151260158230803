// GENERATED CODE -- DO NOT EDIT!

import {grpc} from '@improbable-eng/grpc-web';

import * as messaging_settings_messaging_settings_pb from '../messaging_settings/messaging_settings_pb';

export class MessagingSettings {
  static readonly serviceName = "minga.MessagingSettings";

  static readonly SetRoleMessaging = {
    methodName: "SetRoleMessaging",
    service: MessagingSettings,
    requestStream: false,
    responseStream: false,
    requestType: messaging_settings_messaging_settings_pb.MessagingSettingsSetRoleMessagingRequest,
    responseType: messaging_settings_messaging_settings_pb.MessagingSettingsSetRoleMessagingResponse,
  };

  static readonly GetRoleMessaging = {
    methodName: "GetRoleMessaging",
    service: MessagingSettings,
    requestStream: false,
    responseStream: false,
    requestType: messaging_settings_messaging_settings_pb.MessagingSettingsGetRoleMessagingRequest,
    responseType: messaging_settings_messaging_settings_pb.MessagingSettingsGetRoleMessagingResponse,
  };

}

export class MessagingSettingsClient {
  setRoleMessaging() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  getRoleMessaging() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
}
