// source: checkin/checkin.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

import * as jspb from 'google-protobuf';
import * as common_date_pb from '../common/date_pb';
import * as membership_list_membership_list_pb from '../membership_list/membership_list_pb';
import * as restriction_restriction_pb from '../restriction/restriction_pb';
import * as student_schedule_student_schedule_pb from '../student_schedule/student_schedule_pb';
export namespace CheckinReason {
  export interface AsObject {
    id: number;
    name: string;
    mingaId: number;
    createdBy: number;
    active: boolean;
    type?: CheckinType;
    pointReward: number;
    stickerIdsList: number[];
    icon: string;
    showAbsentees: boolean;
    numberOfAbsentees: number;
    totalCheckinCount: number;
    rolesList: string[];
    gradesList: string[];
    mingaGroupHashesList: string[];
    blockNoAccess: boolean;
    restrictedCheckinReasonId: number;
    priority: number;
    hallPassId: number;
    behaviorId: number;
    color: string;
    selfCheckIn: boolean;
    consequenceId: number;
    allowCheckout: boolean;
    checkinReasonType: string;
    membershipList?: membership_list_membership_list_pb.MembershipList.AsObject;
    restrictionId: number;
    allowMultipleCheckins: boolean;
    userListsList: number[];
    enableStudentPhoto: boolean;
    availableInKiosk: boolean;
    autoEndHallPass: boolean;
    restriction?: restriction_restriction_pb.Restriction.AsObject;
  }
}
export class CheckinReason extends jspb.Message {
  static readonly displayName = "CheckinReason";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [8,13,14,15,29];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, CheckinReason.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): CheckinReason.AsObject {
    return CheckinReason.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!CheckinReason} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: CheckinReason) {
    var f: any;
    var obj: any = {
      id: jspb.Message.getFieldWithDefault(msg, 1, 0),
      name: jspb.Message.getFieldWithDefault(msg, 2, ""),
      mingaId: jspb.Message.getFieldWithDefault(msg, 3, 0),
      createdBy: jspb.Message.getFieldWithDefault(msg, 4, 0),
      active: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
      type: jspb.Message.getFieldWithDefault(msg, 6, 0),
      pointReward: jspb.Message.getFieldWithDefault(msg, 7, 0),
      stickerIdsList: (f = jspb.Message.getRepeatedField(msg, 8)) == null ? undefined : f,
      icon: jspb.Message.getFieldWithDefault(msg, 9, ""),
      showAbsentees: jspb.Message.getBooleanFieldWithDefault(msg, 10, false),
      numberOfAbsentees: jspb.Message.getFieldWithDefault(msg, 11, 0),
      totalCheckinCount: jspb.Message.getFieldWithDefault(msg, 12, 0),
      rolesList: (f = jspb.Message.getRepeatedField(msg, 13)) == null ? undefined : f,
      gradesList: (f = jspb.Message.getRepeatedField(msg, 14)) == null ? undefined : f,
      mingagrouphashesList: (f = jspb.Message.getRepeatedField(msg, 15)) == null ? undefined : f,
      blockNoAccess: jspb.Message.getBooleanFieldWithDefault(msg, 16, false),
      restrictedCheckinReasonId: jspb.Message.getFieldWithDefault(msg, 17, 0),
      priority: jspb.Message.getFieldWithDefault(msg, 18, 0),
      hallPassId: jspb.Message.getFieldWithDefault(msg, 19, 0),
      behaviorId: jspb.Message.getFieldWithDefault(msg, 20, 0),
      color: jspb.Message.getFieldWithDefault(msg, 21, ""),
      selfCheckIn: jspb.Message.getBooleanFieldWithDefault(msg, 22, false),
      consequenceId: jspb.Message.getFieldWithDefault(msg, 23, 0),
      allowCheckout: jspb.Message.getBooleanFieldWithDefault(msg, 24, false),
      checkinReasonType: jspb.Message.getFieldWithDefault(msg, 25, ""),
      membershipList: (f = msg.getMembershipList()) && membership_list_membership_list_pb.MembershipList.toObject(includeInstance, f),
      restrictionId: jspb.Message.getFieldWithDefault(msg, 27, 0),
      allowMultipleCheckins: jspb.Message.getBooleanFieldWithDefault(msg, 28, false),
      userListsList: (f = jspb.Message.getRepeatedField(msg, 29)) == null ? undefined : f,
      enableStudentPhoto: jspb.Message.getBooleanFieldWithDefault(msg, 30, false),
      availableInKiosk: jspb.Message.getBooleanFieldWithDefault(msg, 31, false),
      autoEndHallPass: jspb.Message.getBooleanFieldWithDefault(msg, 32, false),
      restriction: (f = msg.getRestriction()) && restriction_restriction_pb.Restriction.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!CheckinReason}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new CheckinReason;
    return CheckinReason.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!CheckinReason} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!CheckinReason}
   */
  static deserializeBinaryFromReader(msg: CheckinReason, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {number} */ (reader.readInt32());
        msg.setId(value1);
        break;
      case 2:
        var value2 = /** @type {string} */ (reader.readString());
        msg.setName(value2);
        break;
      case 3:
        var value3 = /** @type {number} */ (reader.readInt32());
        msg.setMingaId(value3);
        break;
      case 4:
        var value4 = /** @type {number} */ (reader.readInt32());
        msg.setCreatedBy(value4);
        break;
      case 5:
        var value5 = /** @type {boolean} */ (reader.readBool());
        msg.setActive(value5);
        break;
      case 6:
        var value6 = /** @type {!CheckinType} */ (reader.readEnum());
        msg.setType(value6);
        break;
      case 7:
        var value7 = /** @type {number} */ (reader.readInt32());
        msg.setPointReward(value7);
        break;
      case 8:
        var value8 = /** @type {!Array<number>} */ (reader.readPackedInt32());
        msg.setStickerIdsList(value8);
        break;
      case 9:
        var value9 = /** @type {string} */ (reader.readString());
        msg.setIcon(value9);
        break;
      case 10:
        var value10 = /** @type {boolean} */ (reader.readBool());
        msg.setShowAbsentees(value10);
        break;
      case 11:
        var value11 = /** @type {number} */ (reader.readInt32());
        msg.setNumberOfAbsentees(value11);
        break;
      case 12:
        var value12 = /** @type {number} */ (reader.readInt32());
        msg.setTotalCheckinCount(value12);
        break;
      case 13:
        var value13 = /** @type {string} */ (reader.readString());
        msg.addRoles(value13);
        break;
      case 14:
        var value14 = /** @type {string} */ (reader.readString());
        msg.addGrades(value14);
        break;
      case 15:
        var value15 = /** @type {string} */ (reader.readString());
        msg.addMingagrouphashes(value15);
        break;
      case 16:
        var value16 = /** @type {boolean} */ (reader.readBool());
        msg.setBlockNoAccess(value16);
        break;
      case 17:
        var value17 = /** @type {number} */ (reader.readInt32());
        msg.setRestrictedCheckinReasonId(value17);
        break;
      case 18:
        var value18 = /** @type {number} */ (reader.readInt32());
        msg.setPriority(value18);
        break;
      case 19:
        var value19 = /** @type {number} */ (reader.readInt32());
        msg.setHallPassId(value19);
        break;
      case 20:
        var value20 = /** @type {number} */ (reader.readInt32());
        msg.setBehaviorId(value20);
        break;
      case 21:
        var value21 = /** @type {string} */ (reader.readString());
        msg.setColor(value21);
        break;
      case 22:
        var value22 = /** @type {boolean} */ (reader.readBool());
        msg.setSelfCheckIn(value22);
        break;
      case 23:
        var value23 = /** @type {number} */ (reader.readInt32());
        msg.setConsequenceId(value23);
        break;
      case 24:
        var value24 = /** @type {boolean} */ (reader.readBool());
        msg.setAllowCheckout(value24);
        break;
      case 25:
        var value25 = /** @type {string} */ (reader.readString());
        msg.setCheckinReasonType(value25);
        break;
      case 26:
        var value26 = new membership_list_membership_list_pb.MembershipList;
        reader.readMessage(value26,membership_list_membership_list_pb.MembershipList.deserializeBinaryFromReader);
        msg.setMembershipList(value26);
        break;
      case 27:
        var value27 = /** @type {number} */ (reader.readInt32());
        msg.setRestrictionId(value27);
        break;
      case 28:
        var value28 = /** @type {boolean} */ (reader.readBool());
        msg.setAllowMultipleCheckins(value28);
        break;
      case 29:
        var value29 = /** @type {!Array<number>} */ (reader.readPackedInt32());
        msg.setUserListsList(value29);
        break;
      case 30:
        var value30 = /** @type {boolean} */ (reader.readBool());
        msg.setEnableStudentPhoto(value30);
        break;
      case 31:
        var value31 = /** @type {boolean} */ (reader.readBool());
        msg.setAvailableInKiosk(value31);
        break;
      case 32:
        var value32 = /** @type {boolean} */ (reader.readBool());
        msg.setAutoEndHallPass(value32);
        break;
      case 33:
        var value33 = new restriction_restriction_pb.Restriction;
        reader.readMessage(value33,restriction_restriction_pb.Restriction.deserializeBinaryFromReader);
        msg.setRestriction(value33);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!CheckinReason} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: CheckinReason, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getId();
    if (f !== 0) {
      writer.writeInt32(
        1,
        f
      );
    }
    f = message.getName();
    if (f.length > 0) {
      writer.writeString(
        2,
        f
      );
    }
    f = message.getMingaId();
    if (f !== 0) {
      writer.writeInt32(
        3,
        f
      );
    }
    f = message.getCreatedBy();
    if (f !== 0) {
      writer.writeInt32(
        4,
        f
      );
    }
    f = message.getActive();
    if (f) {
      writer.writeBool(
        5,
        f
      );
    }
    f = message.getType();
    if (f !== 0.0) {
      writer.writeEnum(
        6,
        f
      );
    }
    f = message.getPointReward();
    if (f !== 0) {
      writer.writeInt32(
        7,
        f
      );
    }
    f = message.getStickerIdsList();
    if (f.length > 0) {
      writer.writePackedInt32(
        8,
        f
      );
    }
    f = message.getIcon();
    if (f.length > 0) {
      writer.writeString(
        9,
        f
      );
    }
    f = message.getShowAbsentees();
    if (f) {
      writer.writeBool(
        10,
        f
      );
    }
    f = message.getNumberOfAbsentees();
    if (f !== 0) {
      writer.writeInt32(
        11,
        f
      );
    }
    f = message.getTotalCheckinCount();
    if (f !== 0) {
      writer.writeInt32(
        12,
        f
      );
    }
    f = message.getRolesList();
    if (f.length > 0) {
      writer.writeRepeatedString(
        13,
        f
      );
    }
    f = message.getGradesList();
    if (f.length > 0) {
      writer.writeRepeatedString(
        14,
        f
      );
    }
    f = message.getMingagrouphashesList();
    if (f.length > 0) {
      writer.writeRepeatedString(
        15,
        f
      );
    }
    f = message.getBlockNoAccess();
    if (f) {
      writer.writeBool(
        16,
        f
      );
    }
    f = message.getRestrictedCheckinReasonId();
    if (f !== 0) {
      writer.writeInt32(
        17,
        f
      );
    }
    f = message.getPriority();
    if (f !== 0) {
      writer.writeInt32(
        18,
        f
      );
    }
    f = message.getHallPassId();
    if (f !== 0) {
      writer.writeInt32(
        19,
        f
      );
    }
    f = message.getBehaviorId();
    if (f !== 0) {
      writer.writeInt32(
        20,
        f
      );
    }
    f = message.getColor();
    if (f.length > 0) {
      writer.writeString(
        21,
        f
      );
    }
    f = message.getSelfCheckIn();
    if (f) {
      writer.writeBool(
        22,
        f
      );
    }
    f = message.getConsequenceId();
    if (f !== 0) {
      writer.writeInt32(
        23,
        f
      );
    }
    f = message.getAllowCheckout();
    if (f) {
      writer.writeBool(
        24,
        f
      );
    }
    f = message.getCheckinReasonType();
    if (f.length > 0) {
      writer.writeString(
        25,
        f
      );
    }
    f = message.getMembershipList();
    if (f != null) {
      writer.writeMessage(
        26,
        f,
        membership_list_membership_list_pb.MembershipList.serializeBinaryToWriter
      );
    }
    f = message.getRestrictionId();
    if (f !== 0) {
      writer.writeInt32(
        27,
        f
      );
    }
    f = message.getAllowMultipleCheckins();
    if (f) {
      writer.writeBool(
        28,
        f
      );
    }
    f = message.getUserListsList();
    if (f.length > 0) {
      writer.writePackedInt32(
        29,
        f
      );
    }
    f = message.getEnableStudentPhoto();
    if (f) {
      writer.writeBool(
        30,
        f
      );
    }
    f = message.getAvailableInKiosk();
    if (f) {
      writer.writeBool(
        31,
        f
      );
    }
    f = message.getAutoEndHallPass();
    if (f) {
      writer.writeBool(
        32,
        f
      );
    }
    f = message.getRestriction();
    if (f != null) {
      writer.writeMessage(
        33,
        f,
        restriction_restriction_pb.Restriction.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    CheckinReason.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional int32 id = 1;
   * @return {number}
   */
  getId(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
  };


  /** @param {number} value */
  setId(value: number) {
    jspb.Message.setProto3IntField(this, 1, value);
  }


  /**
   * optional string name = 2;
   * @return {string}
   */
  getName(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
  };


  /** @param {string} value */
  setName(value: string) {
    jspb.Message.setProto3StringField(this, 2, value);
  }


  /**
   * optional int32 minga_id = 3;
   * @return {number}
   */
  getMingaId(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
  };


  /** @param {number} value */
  setMingaId(value: number) {
    jspb.Message.setProto3IntField(this, 3, value);
  }


  /**
   * optional int32 created_by = 4;
   * @return {number}
   */
  getCreatedBy(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
  };


  /** @param {number} value */
  setCreatedBy(value: number) {
    jspb.Message.setProto3IntField(this, 4, value);
  }


  /**
   * optional bool active = 5;
   * @return {boolean}
   */
  getActive(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
  };


  /** @param {boolean} value */
  setActive(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 5, value);
  }


  /**
   * optional CheckinType type = 6;
   * @return {!CheckinType}
   */
  getType(): CheckinType {
    return /** @type {!CheckinType} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
  };


  /** @param {!CheckinType} value */
  setType(value: CheckinType) {
    jspb.Message.setProto3EnumField(this, 6, value);
  }


  /**
   * optional int32 point_reward = 7;
   * @return {number}
   */
  getPointReward(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
  };


  /** @param {number} value */
  setPointReward(value: number) {
    jspb.Message.setProto3IntField(this, 7, value);
  }


  /**
   * repeated int32 sticker_ids = 8;
   * @return {!Array<number>}
   */
  getStickerIdsList(): number[] {
    return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 8));
  };


  /** @param {!Array<number>} value */
  setStickerIdsList(value: number[]) {
    jspb.Message.setField(this, 8, value || []);
  }


  /**
   * @param {number} value
   * @param {number=} opt_index
   */
  addStickerIds(value: number, opt_index?: number): void {
    jspb.Message.addToRepeatedField(this, 8, value, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearStickerIdsList() {
    this.setStickerIdsList([]);
  }


  /**
   * optional string icon = 9;
   * @return {string}
   */
  getIcon(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
  };


  /** @param {string} value */
  setIcon(value: string) {
    jspb.Message.setProto3StringField(this, 9, value);
  }


  /**
   * optional bool show_absentees = 10;
   * @return {boolean}
   */
  getShowAbsentees(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
  };


  /** @param {boolean} value */
  setShowAbsentees(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 10, value);
  }


  /**
   * optional int32 number_of_absentees = 11;
   * @return {number}
   */
  getNumberOfAbsentees(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
  };


  /** @param {number} value */
  setNumberOfAbsentees(value: number) {
    jspb.Message.setProto3IntField(this, 11, value);
  }


  /**
   * optional int32 total_checkin_count = 12;
   * @return {number}
   */
  getTotalCheckinCount(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
  };


  /** @param {number} value */
  setTotalCheckinCount(value: number) {
    jspb.Message.setProto3IntField(this, 12, value);
  }


  /**
   * repeated string roles = 13;
   * @return {!Array<string>}
   */
  getRolesList(): string[] {
    return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 13));
  };


  /** @param {!Array<string>} value */
  setRolesList(value: string[]) {
    jspb.Message.setField(this, 13, value || []);
  }


  /**
   * @param {string} value
   * @param {number=} opt_index
   */
  addRoles(value: string, opt_index?: number): void {
    jspb.Message.addToRepeatedField(this, 13, value, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearRolesList() {
    this.setRolesList([]);
  }


  /**
   * repeated string grades = 14;
   * @return {!Array<string>}
   */
  getGradesList(): string[] {
    return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 14));
  };


  /** @param {!Array<string>} value */
  setGradesList(value: string[]) {
    jspb.Message.setField(this, 14, value || []);
  }


  /**
   * @param {string} value
   * @param {number=} opt_index
   */
  addGrades(value: string, opt_index?: number): void {
    jspb.Message.addToRepeatedField(this, 14, value, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearGradesList() {
    this.setGradesList([]);
  }


  /**
   * repeated string mingaGroupHashes = 15;
   * @return {!Array<string>}
   */
  getMingagrouphashesList(): string[] {
    return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 15));
  };


  /** @param {!Array<string>} value */
  setMingagrouphashesList(value: string[]) {
    jspb.Message.setField(this, 15, value || []);
  }


  /**
   * @param {string} value
   * @param {number=} opt_index
   */
  addMingagrouphashes(value: string, opt_index?: number): void {
    jspb.Message.addToRepeatedField(this, 15, value, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearMingagrouphashesList() {
    this.setMingagrouphashesList([]);
  }


  /**
   * optional bool block_no_access = 16;
   * @return {boolean}
   */
  getBlockNoAccess(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 16, false));
  };


  /** @param {boolean} value */
  setBlockNoAccess(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 16, value);
  }


  /**
   * optional int32 restricted_checkin_reason_id = 17;
   * @return {number}
   */
  getRestrictedCheckinReasonId(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 17, 0));
  };


  /** @param {number} value */
  setRestrictedCheckinReasonId(value: number) {
    jspb.Message.setProto3IntField(this, 17, value);
  }


  /**
   * optional int32 priority = 18;
   * @return {number}
   */
  getPriority(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 18, 0));
  };


  /** @param {number} value */
  setPriority(value: number) {
    jspb.Message.setProto3IntField(this, 18, value);
  }


  /**
   * optional int32 hall_pass_id = 19;
   * @return {number}
   */
  getHallPassId(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 19, 0));
  };


  /** @param {number} value */
  setHallPassId(value: number) {
    jspb.Message.setProto3IntField(this, 19, value);
  }


  /**
   * optional int32 behavior_id = 20;
   * @return {number}
   */
  getBehaviorId(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 20, 0));
  };


  /** @param {number} value */
  setBehaviorId(value: number) {
    jspb.Message.setProto3IntField(this, 20, value);
  }


  /**
   * optional string color = 21;
   * @return {string}
   */
  getColor(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 21, ""));
  };


  /** @param {string} value */
  setColor(value: string) {
    jspb.Message.setProto3StringField(this, 21, value);
  }


  /**
   * optional bool self_check_in = 22;
   * @return {boolean}
   */
  getSelfCheckIn(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 22, false));
  };


  /** @param {boolean} value */
  setSelfCheckIn(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 22, value);
  }


  /**
   * optional int32 consequence_id = 23;
   * @return {number}
   */
  getConsequenceId(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 23, 0));
  };


  /** @param {number} value */
  setConsequenceId(value: number) {
    jspb.Message.setProto3IntField(this, 23, value);
  }


  /**
   * optional bool allow_checkout = 24;
   * @return {boolean}
   */
  getAllowCheckout(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 24, false));
  };


  /** @param {boolean} value */
  setAllowCheckout(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 24, value);
  }


  /**
   * optional string checkin_reason_type = 25;
   * @return {string}
   */
  getCheckinReasonType(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 25, ""));
  };


  /** @param {string} value */
  setCheckinReasonType(value: string) {
    jspb.Message.setProto3StringField(this, 25, value);
  }


  /**
   * optional membership_list.MembershipList membership_list = 26;
   * @return {?MembershipList}
   */
  getMembershipList(): membership_list_membership_list_pb.MembershipList {
    return /** @type{?MembershipList} */ (
      jspb.Message.getWrapperField(this, membership_list_membership_list_pb.MembershipList, 26));
  }


  /** @param {?MembershipList|undefined} value */
  setMembershipList(value?: membership_list_membership_list_pb.MembershipList) {
    jspb.Message.setWrapperField(this, 26, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearMembershipList() {
    this.setMembershipList(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasMembershipList(): boolean {
    return jspb.Message.getField(this, 26) != null;
  }


  /**
   * optional int32 restriction_id = 27;
   * @return {number}
   */
  getRestrictionId(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 27, 0));
  };


  /** @param {number} value */
  setRestrictionId(value: number) {
    jspb.Message.setProto3IntField(this, 27, value);
  }


  /**
   * optional bool allow_multiple_checkins = 28;
   * @return {boolean}
   */
  getAllowMultipleCheckins(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 28, false));
  };


  /** @param {boolean} value */
  setAllowMultipleCheckins(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 28, value);
  }


  /**
   * repeated int32 user_lists = 29;
   * @return {!Array<number>}
   */
  getUserListsList(): number[] {
    return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 29));
  };


  /** @param {!Array<number>} value */
  setUserListsList(value: number[]) {
    jspb.Message.setField(this, 29, value || []);
  }


  /**
   * @param {number} value
   * @param {number=} opt_index
   */
  addUserLists(value: number, opt_index?: number): void {
    jspb.Message.addToRepeatedField(this, 29, value, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearUserListsList() {
    this.setUserListsList([]);
  }


  /**
   * optional bool enable_student_photo = 30;
   * @return {boolean}
   */
  getEnableStudentPhoto(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 30, false));
  };


  /** @param {boolean} value */
  setEnableStudentPhoto(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 30, value);
  }


  /**
   * optional bool available_in_kiosk = 31;
   * @return {boolean}
   */
  getAvailableInKiosk(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 31, false));
  };


  /** @param {boolean} value */
  setAvailableInKiosk(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 31, value);
  }


  /**
   * optional bool auto_end_hall_pass = 32;
   * @return {boolean}
   */
  getAutoEndHallPass(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 32, false));
  };


  /** @param {boolean} value */
  setAutoEndHallPass(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 32, value);
  }


  /**
   * optional restriction.Restriction restriction = 33;
   * @return {?Restriction}
   */
  getRestriction(): restriction_restriction_pb.Restriction {
    return /** @type{?Restriction} */ (
      jspb.Message.getWrapperField(this, restriction_restriction_pb.Restriction, 33));
  }


  /** @param {?Restriction|undefined} value */
  setRestriction(value?: restriction_restriction_pb.Restriction) {
    jspb.Message.setWrapperField(this, 33, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearRestriction() {
    this.setRestriction(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasRestriction(): boolean {
    return jspb.Message.getField(this, 33) != null;
  }


}
export namespace CheckinPersonDetail {
  export interface AsObject {
    name: string;
    hash: string;
  }
}
export class CheckinPersonDetail extends jspb.Message {
  static readonly displayName = "CheckinPersonDetail";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): CheckinPersonDetail.AsObject {
    return CheckinPersonDetail.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!CheckinPersonDetail} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: CheckinPersonDetail) {
    var f: any;
    var obj: any = {
      name: jspb.Message.getFieldWithDefault(msg, 1, ""),
      hash: jspb.Message.getFieldWithDefault(msg, 2, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!CheckinPersonDetail}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new CheckinPersonDetail;
    return CheckinPersonDetail.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!CheckinPersonDetail} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!CheckinPersonDetail}
   */
  static deserializeBinaryFromReader(msg: CheckinPersonDetail, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setName(value1);
        break;
      case 2:
        var value2 = /** @type {string} */ (reader.readString());
        msg.setHash(value2);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!CheckinPersonDetail} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: CheckinPersonDetail, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getName();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
    f = message.getHash();
    if (f.length > 0) {
      writer.writeString(
        2,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    CheckinPersonDetail.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string name = 1;
   * @return {string}
   */
  getName(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setName(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


  /**
   * optional string hash = 2;
   * @return {string}
   */
  getHash(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
  };


  /** @param {string} value */
  setHash(value: string) {
    jspb.Message.setProto3StringField(this, 2, value);
  }


}
export namespace Checkin {
  export interface AsObject {
    id: number;
    reason?: CheckinReason.AsObject;
    person?: CheckinPerson.AsObject;
    checkinBy?: CheckinPerson.AsObject;
    checkinTime?: common_date_pb.DateTime.AsObject;
    checkoutBy?: CheckinPerson.AsObject;
    checkoutTime?: common_date_pb.DateTime.AsObject;
    reasonName: string;
    reasonId: number;
    note: string;
    isKiosk: boolean;
    currentClass?: student_schedule_student_schedule_pb.StudentSection.AsObject;
    currentClassesList: student_schedule_student_schedule_pb.StudentSection.AsObject[];
  }
}
export class Checkin extends jspb.Message {
  static readonly displayName = "Checkin";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [14];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, Checkin.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): Checkin.AsObject {
    return Checkin.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!Checkin} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: Checkin) {
    var f: any;
    var obj: any = {
      id: jspb.Message.getFieldWithDefault(msg, 1, 0),
      reason: (f = msg.getReason()) && CheckinReason.toObject(includeInstance, f),
      person: (f = msg.getPerson()) && CheckinPerson.toObject(includeInstance, f),
      checkinby: (f = msg.getCheckinby()) && CheckinPerson.toObject(includeInstance, f),
      checkinTime: (f = msg.getCheckinTime()) && common_date_pb.DateTime.toObject(includeInstance, f),
      checkoutBy: (f = msg.getCheckoutBy()) && CheckinPerson.toObject(includeInstance, f),
      checkoutTime: (f = msg.getCheckoutTime()) && common_date_pb.DateTime.toObject(includeInstance, f),
      reasonName: jspb.Message.getFieldWithDefault(msg, 8, ""),
      reasonId: jspb.Message.getFieldWithDefault(msg, 9, 0),
      note: jspb.Message.getFieldWithDefault(msg, 10, ""),
      isKiosk: jspb.Message.getBooleanFieldWithDefault(msg, 11, false),
      currentClass: (f = msg.getCurrentClass()) && student_schedule_student_schedule_pb.StudentSection.toObject(includeInstance, f),
      currentClassesList: jspb.Message.toObjectList(msg.getCurrentClassesList(),
      student_schedule_student_schedule_pb.StudentSection.toObject, includeInstance)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!Checkin}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new Checkin;
    return Checkin.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!Checkin} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!Checkin}
   */
  static deserializeBinaryFromReader(msg: Checkin, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {number} */ (reader.readInt32());
        msg.setId(value1);
        break;
      case 2:
        var value2 = new CheckinReason;
        reader.readMessage(value2,CheckinReason.deserializeBinaryFromReader);
        msg.setReason(value2);
        break;
      case 3:
        var value3 = new CheckinPerson;
        reader.readMessage(value3,CheckinPerson.deserializeBinaryFromReader);
        msg.setPerson(value3);
        break;
      case 4:
        var value4 = new CheckinPerson;
        reader.readMessage(value4,CheckinPerson.deserializeBinaryFromReader);
        msg.setCheckinby(value4);
        break;
      case 5:
        var value5 = new common_date_pb.DateTime;
        reader.readMessage(value5,common_date_pb.DateTime.deserializeBinaryFromReader);
        msg.setCheckinTime(value5);
        break;
      case 6:
        var value6 = new CheckinPerson;
        reader.readMessage(value6,CheckinPerson.deserializeBinaryFromReader);
        msg.setCheckoutBy(value6);
        break;
      case 7:
        var value7 = new common_date_pb.DateTime;
        reader.readMessage(value7,common_date_pb.DateTime.deserializeBinaryFromReader);
        msg.setCheckoutTime(value7);
        break;
      case 8:
        var value8 = /** @type {string} */ (reader.readString());
        msg.setReasonName(value8);
        break;
      case 9:
        var value9 = /** @type {number} */ (reader.readInt32());
        msg.setReasonId(value9);
        break;
      case 10:
        var value10 = /** @type {string} */ (reader.readString());
        msg.setNote(value10);
        break;
      case 11:
        var value11 = /** @type {boolean} */ (reader.readBool());
        msg.setIsKiosk(value11);
        break;
      case 13:
        var value13 = new student_schedule_student_schedule_pb.StudentSection;
        reader.readMessage(value13,student_schedule_student_schedule_pb.StudentSection.deserializeBinaryFromReader);
        msg.setCurrentClass(value13);
        break;
      case 14:
        var value14 = new student_schedule_student_schedule_pb.StudentSection;
        reader.readMessage(value14,student_schedule_student_schedule_pb.StudentSection.deserializeBinaryFromReader);
        msg.addCurrentClasses(value14);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!Checkin} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: Checkin, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getId();
    if (f !== 0) {
      writer.writeInt32(
        1,
        f
      );
    }
    f = message.getReason();
    if (f != null) {
      writer.writeMessage(
        2,
        f,
        CheckinReason.serializeBinaryToWriter
      );
    }
    f = message.getPerson();
    if (f != null) {
      writer.writeMessage(
        3,
        f,
        CheckinPerson.serializeBinaryToWriter
      );
    }
    f = message.getCheckinby();
    if (f != null) {
      writer.writeMessage(
        4,
        f,
        CheckinPerson.serializeBinaryToWriter
      );
    }
    f = message.getCheckinTime();
    if (f != null) {
      writer.writeMessage(
        5,
        f,
        common_date_pb.DateTime.serializeBinaryToWriter
      );
    }
    f = message.getCheckoutBy();
    if (f != null) {
      writer.writeMessage(
        6,
        f,
        CheckinPerson.serializeBinaryToWriter
      );
    }
    f = message.getCheckoutTime();
    if (f != null) {
      writer.writeMessage(
        7,
        f,
        common_date_pb.DateTime.serializeBinaryToWriter
      );
    }
    f = message.getReasonName();
    if (f.length > 0) {
      writer.writeString(
        8,
        f
      );
    }
    f = message.getReasonId();
    if (f !== 0) {
      writer.writeInt32(
        9,
        f
      );
    }
    f = message.getNote();
    if (f.length > 0) {
      writer.writeString(
        10,
        f
      );
    }
    f = message.getIsKiosk();
    if (f) {
      writer.writeBool(
        11,
        f
      );
    }
    f = message.getCurrentClass();
    if (f != null) {
      writer.writeMessage(
        13,
        f,
        student_schedule_student_schedule_pb.StudentSection.serializeBinaryToWriter
      );
    }
    f = message.getCurrentClassesList();
    if (f.length > 0) {
      writer.writeRepeatedMessage(
        14,
        f,
        student_schedule_student_schedule_pb.StudentSection.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    Checkin.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional int32 id = 1;
   * @return {number}
   */
  getId(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
  };


  /** @param {number} value */
  setId(value: number) {
    jspb.Message.setProto3IntField(this, 1, value);
  }


  /**
   * optional CheckinReason reason = 2;
   * @return {?CheckinReason}
   */
  getReason(): CheckinReason {
    return /** @type{?CheckinReason} */ (
      jspb.Message.getWrapperField(this, CheckinReason, 2));
  }


  /** @param {?CheckinReason|undefined} value */
  setReason(value?: CheckinReason) {
    jspb.Message.setWrapperField(this, 2, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearReason() {
    this.setReason(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasReason(): boolean {
    return jspb.Message.getField(this, 2) != null;
  }


  /**
   * optional CheckinPerson person = 3;
   * @return {?CheckinPerson}
   */
  getPerson(): CheckinPerson {
    return /** @type{?CheckinPerson} */ (
      jspb.Message.getWrapperField(this, CheckinPerson, 3));
  }


  /** @param {?CheckinPerson|undefined} value */
  setPerson(value?: CheckinPerson) {
    jspb.Message.setWrapperField(this, 3, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearPerson() {
    this.setPerson(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasPerson(): boolean {
    return jspb.Message.getField(this, 3) != null;
  }


  /**
   * optional CheckinPerson checkinBy = 4;
   * @return {?CheckinPerson}
   */
  getCheckinby(): CheckinPerson {
    return /** @type{?CheckinPerson} */ (
      jspb.Message.getWrapperField(this, CheckinPerson, 4));
  }


  /** @param {?CheckinPerson|undefined} value */
  setCheckinby(value?: CheckinPerson) {
    jspb.Message.setWrapperField(this, 4, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearCheckinby() {
    this.setCheckinby(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasCheckinby(): boolean {
    return jspb.Message.getField(this, 4) != null;
  }


  /**
   * optional minga.common.DateTime checkin_time = 5;
   * @return {?DateTime}
   */
  getCheckinTime(): common_date_pb.DateTime {
    return /** @type{?DateTime} */ (
      jspb.Message.getWrapperField(this, common_date_pb.DateTime, 5));
  }


  /** @param {?DateTime|undefined} value */
  setCheckinTime(value?: common_date_pb.DateTime) {
    jspb.Message.setWrapperField(this, 5, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearCheckinTime() {
    this.setCheckinTime(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasCheckinTime(): boolean {
    return jspb.Message.getField(this, 5) != null;
  }


  /**
   * optional CheckinPerson checkout_by = 6;
   * @return {?CheckinPerson}
   */
  getCheckoutBy(): CheckinPerson {
    return /** @type{?CheckinPerson} */ (
      jspb.Message.getWrapperField(this, CheckinPerson, 6));
  }


  /** @param {?CheckinPerson|undefined} value */
  setCheckoutBy(value?: CheckinPerson) {
    jspb.Message.setWrapperField(this, 6, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearCheckoutBy() {
    this.setCheckoutBy(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasCheckoutBy(): boolean {
    return jspb.Message.getField(this, 6) != null;
  }


  /**
   * optional minga.common.DateTime checkout_time = 7;
   * @return {?DateTime}
   */
  getCheckoutTime(): common_date_pb.DateTime {
    return /** @type{?DateTime} */ (
      jspb.Message.getWrapperField(this, common_date_pb.DateTime, 7));
  }


  /** @param {?DateTime|undefined} value */
  setCheckoutTime(value?: common_date_pb.DateTime) {
    jspb.Message.setWrapperField(this, 7, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearCheckoutTime() {
    this.setCheckoutTime(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasCheckoutTime(): boolean {
    return jspb.Message.getField(this, 7) != null;
  }


  /**
   * optional string reason_name = 8;
   * @return {string}
   */
  getReasonName(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
  };


  /** @param {string} value */
  setReasonName(value: string) {
    jspb.Message.setProto3StringField(this, 8, value);
  }


  /**
   * optional int32 reason_id = 9;
   * @return {number}
   */
  getReasonId(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
  };


  /** @param {number} value */
  setReasonId(value: number) {
    jspb.Message.setProto3IntField(this, 9, value);
  }


  /**
   * optional string note = 10;
   * @return {string}
   */
  getNote(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
  };


  /** @param {string} value */
  setNote(value: string) {
    jspb.Message.setProto3StringField(this, 10, value);
  }


  /**
   * optional bool is_kiosk = 11;
   * @return {boolean}
   */
  getIsKiosk(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
  };


  /** @param {boolean} value */
  setIsKiosk(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 11, value);
  }


  /**
   * optional minga.student_schedule.StudentSection current_class = 13;
   * @return {?StudentSection}
   */
  getCurrentClass(): student_schedule_student_schedule_pb.StudentSection {
    return /** @type{?StudentSection} */ (
      jspb.Message.getWrapperField(this, student_schedule_student_schedule_pb.StudentSection, 13));
  }


  /** @param {?StudentSection|undefined} value */
  setCurrentClass(value?: student_schedule_student_schedule_pb.StudentSection) {
    jspb.Message.setWrapperField(this, 13, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearCurrentClass() {
    this.setCurrentClass(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasCurrentClass(): boolean {
    return jspb.Message.getField(this, 13) != null;
  }


  /**
   * repeated minga.student_schedule.StudentSection current_classes = 14;
   * @return {!Array<!StudentSection>}
   */
  getCurrentClassesList(): student_schedule_student_schedule_pb.StudentSection[] {
    return /** @type{!Array<!StudentSection>} */ (
      jspb.Message.getRepeatedWrapperField(this, student_schedule_student_schedule_pb.StudentSection, 14));
  }


  /** @param {!Array<!StudentSection>} value */
  setCurrentClassesList(value?: student_schedule_student_schedule_pb.StudentSection[]) {
    jspb.Message.setRepeatedWrapperField(this, 14, value);
  }


  /**
   * @param {!StudentSection=} opt_value
   * @param {number=} opt_index
   * @return {!StudentSection}
   */
  addCurrentClasses(opt_value?: student_schedule_student_schedule_pb.StudentSection, opt_index?: number): student_schedule_student_schedule_pb.StudentSection {
    return jspb.Message.addToRepeatedWrapperField(this, 14, opt_value, student_schedule_student_schedule_pb.StudentSection, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearCurrentClassesList() {
    this.setCurrentClassesList([]);
  }


}
export namespace EditableCheckin {
  export interface AsObject {
    checkin?: Checkin.AsObject;
    manuallyUpdatedAt?: common_date_pb.DateTime.AsObject;
  }
}
export class EditableCheckin extends jspb.Message {
  static readonly displayName = "EditableCheckin";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): EditableCheckin.AsObject {
    return EditableCheckin.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!EditableCheckin} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: EditableCheckin) {
    var f: any;
    var obj: any = {
      checkin: (f = msg.getCheckin()) && Checkin.toObject(includeInstance, f),
      manuallyUpdatedAt: (f = msg.getManuallyUpdatedAt()) && common_date_pb.DateTime.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!EditableCheckin}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new EditableCheckin;
    return EditableCheckin.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!EditableCheckin} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!EditableCheckin}
   */
  static deserializeBinaryFromReader(msg: EditableCheckin, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new Checkin;
        reader.readMessage(value1,Checkin.deserializeBinaryFromReader);
        msg.setCheckin(value1);
        break;
      case 2:
        var value2 = new common_date_pb.DateTime;
        reader.readMessage(value2,common_date_pb.DateTime.deserializeBinaryFromReader);
        msg.setManuallyUpdatedAt(value2);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!EditableCheckin} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: EditableCheckin, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getCheckin();
    if (f != null) {
      writer.writeMessage(
        1,
        f,
        Checkin.serializeBinaryToWriter
      );
    }
    f = message.getManuallyUpdatedAt();
    if (f != null) {
      writer.writeMessage(
        2,
        f,
        common_date_pb.DateTime.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    EditableCheckin.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional Checkin checkin = 1;
   * @return {?Checkin}
   */
  getCheckin(): Checkin {
    return /** @type{?Checkin} */ (
      jspb.Message.getWrapperField(this, Checkin, 1));
  }


  /** @param {?Checkin|undefined} value */
  setCheckin(value?: Checkin) {
    jspb.Message.setWrapperField(this, 1, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearCheckin() {
    this.setCheckin(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasCheckin(): boolean {
    return jspb.Message.getField(this, 1) != null;
  }


  /**
   * optional minga.common.DateTime manually_updated_at = 2;
   * @return {?DateTime}
   */
  getManuallyUpdatedAt(): common_date_pb.DateTime {
    return /** @type{?DateTime} */ (
      jspb.Message.getWrapperField(this, common_date_pb.DateTime, 2));
  }


  /** @param {?DateTime|undefined} value */
  setManuallyUpdatedAt(value?: common_date_pb.DateTime) {
    jspb.Message.setWrapperField(this, 2, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearManuallyUpdatedAt() {
    this.setManuallyUpdatedAt(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasManuallyUpdatedAt(): boolean {
    return jspb.Message.getField(this, 2) != null;
  }


}
export namespace CheckinRequest {
  export interface AsObject {
    peopleHashesList: string[];
    checkinReasonId: number;
    bypassMultiCheck: boolean;
    guestList: number[];
    note: string;
    isKiosk: boolean;
  }
}
export class CheckinRequest extends jspb.Message {
  static readonly displayName = "CheckinRequest";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [1,4];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, CheckinRequest.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): CheckinRequest.AsObject {
    return CheckinRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!CheckinRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: CheckinRequest) {
    var f: any;
    var obj: any = {
      peopleHashesList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
      checkinReasonId: jspb.Message.getFieldWithDefault(msg, 2, 0),
      bypassMultiCheck: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
      guestList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
      note: jspb.Message.getFieldWithDefault(msg, 5, ""),
      isKiosk: jspb.Message.getBooleanFieldWithDefault(msg, 6, false)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!CheckinRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new CheckinRequest;
    return CheckinRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!CheckinRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!CheckinRequest}
   */
  static deserializeBinaryFromReader(msg: CheckinRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.addPeopleHashes(value1);
        break;
      case 2:
        var value2 = /** @type {number} */ (reader.readInt32());
        msg.setCheckinReasonId(value2);
        break;
      case 3:
        var value3 = /** @type {boolean} */ (reader.readBool());
        msg.setBypassMultiCheck(value3);
        break;
      case 4:
        var value4 = /** @type {!Array<number>} */ (reader.readPackedInt32());
        msg.setGuestList(value4);
        break;
      case 5:
        var value5 = /** @type {string} */ (reader.readString());
        msg.setNote(value5);
        break;
      case 6:
        var value6 = /** @type {boolean} */ (reader.readBool());
        msg.setIsKiosk(value6);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!CheckinRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: CheckinRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getPeopleHashesList();
    if (f.length > 0) {
      writer.writeRepeatedString(
        1,
        f
      );
    }
    f = message.getCheckinReasonId();
    if (f !== 0) {
      writer.writeInt32(
        2,
        f
      );
    }
    f = message.getBypassMultiCheck();
    if (f) {
      writer.writeBool(
        3,
        f
      );
    }
    f = message.getGuestList();
    if (f.length > 0) {
      writer.writePackedInt32(
        4,
        f
      );
    }
    f = message.getNote();
    if (f.length > 0) {
      writer.writeString(
        5,
        f
      );
    }
    f = message.getIsKiosk();
    if (f) {
      writer.writeBool(
        6,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    CheckinRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * repeated string people_hashes = 1;
   * @return {!Array<string>}
   */
  getPeopleHashesList(): string[] {
    return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
  };


  /** @param {!Array<string>} value */
  setPeopleHashesList(value: string[]) {
    jspb.Message.setField(this, 1, value || []);
  }


  /**
   * @param {string} value
   * @param {number=} opt_index
   */
  addPeopleHashes(value: string, opt_index?: number): void {
    jspb.Message.addToRepeatedField(this, 1, value, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearPeopleHashesList() {
    this.setPeopleHashesList([]);
  }


  /**
   * optional int32 checkin_reason_id = 2;
   * @return {number}
   */
  getCheckinReasonId(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
  };


  /** @param {number} value */
  setCheckinReasonId(value: number) {
    jspb.Message.setProto3IntField(this, 2, value);
  }


  /**
   * optional bool bypass_multi_check = 3;
   * @return {boolean}
   */
  getBypassMultiCheck(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
  };


  /** @param {boolean} value */
  setBypassMultiCheck(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 3, value);
  }


  /**
   * repeated int32 guest = 4;
   * @return {!Array<number>}
   */
  getGuestList(): number[] {
    return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 4));
  };


  /** @param {!Array<number>} value */
  setGuestList(value: number[]) {
    jspb.Message.setField(this, 4, value || []);
  }


  /**
   * @param {number} value
   * @param {number=} opt_index
   */
  addGuest(value: number, opt_index?: number): void {
    jspb.Message.addToRepeatedField(this, 4, value, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearGuestList() {
    this.setGuestList([]);
  }


  /**
   * optional string note = 5;
   * @return {string}
   */
  getNote(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
  };


  /** @param {string} value */
  setNote(value: string) {
    jspb.Message.setProto3StringField(this, 5, value);
  }


  /**
   * optional bool is_kiosk = 6;
   * @return {boolean}
   */
  getIsKiosk(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
  };


  /** @param {boolean} value */
  setIsKiosk(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 6, value);
  }


}
export namespace CheckinResponse {
  export interface AsObject {
    successPeopleHashesList: string[];
    errors: string;
    errorList: restriction_restriction_pb.RestrictionError.AsObject[];
  }
}
export class CheckinResponse extends jspb.Message {
  static readonly displayName = "CheckinResponse";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [1,3];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, CheckinResponse.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): CheckinResponse.AsObject {
    return CheckinResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!CheckinResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: CheckinResponse) {
    var f: any;
    var obj: any = {
      successPeopleHashesList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
      errors: jspb.Message.getFieldWithDefault(msg, 2, ""),
      errorList: jspb.Message.toObjectList(msg.getErrorList(),
      restriction_restriction_pb.RestrictionError.toObject, includeInstance)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!CheckinResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new CheckinResponse;
    return CheckinResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!CheckinResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!CheckinResponse}
   */
  static deserializeBinaryFromReader(msg: CheckinResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.addSuccessPeopleHashes(value1);
        break;
      case 2:
        var value2 = /** @type {string} */ (reader.readString());
        msg.setErrors(value2);
        break;
      case 3:
        var value3 = new restriction_restriction_pb.RestrictionError;
        reader.readMessage(value3,restriction_restriction_pb.RestrictionError.deserializeBinaryFromReader);
        msg.addError(value3);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!CheckinResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: CheckinResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getSuccessPeopleHashesList();
    if (f.length > 0) {
      writer.writeRepeatedString(
        1,
        f
      );
    }
    f = message.getErrors();
    if (f.length > 0) {
      writer.writeString(
        2,
        f
      );
    }
    f = message.getErrorList();
    if (f.length > 0) {
      writer.writeRepeatedMessage(
        3,
        f,
        restriction_restriction_pb.RestrictionError.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    CheckinResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * repeated string success_people_hashes = 1;
   * @return {!Array<string>}
   */
  getSuccessPeopleHashesList(): string[] {
    return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
  };


  /** @param {!Array<string>} value */
  setSuccessPeopleHashesList(value: string[]) {
    jspb.Message.setField(this, 1, value || []);
  }


  /**
   * @param {string} value
   * @param {number=} opt_index
   */
  addSuccessPeopleHashes(value: string, opt_index?: number): void {
    jspb.Message.addToRepeatedField(this, 1, value, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearSuccessPeopleHashesList() {
    this.setSuccessPeopleHashesList([]);
  }


  /**
   * optional string errors = 2;
   * @return {string}
   */
  getErrors(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
  };


  /** @param {string} value */
  setErrors(value: string) {
    jspb.Message.setProto3StringField(this, 2, value);
  }


  /**
   * repeated restriction.RestrictionError error = 3;
   * @return {!Array<!RestrictionError>}
   */
  getErrorList(): restriction_restriction_pb.RestrictionError[] {
    return /** @type{!Array<!RestrictionError>} */ (
      jspb.Message.getRepeatedWrapperField(this, restriction_restriction_pb.RestrictionError, 3));
  }


  /** @param {!Array<!RestrictionError>} value */
  setErrorList(value?: restriction_restriction_pb.RestrictionError[]) {
    jspb.Message.setRepeatedWrapperField(this, 3, value);
  }


  /**
   * @param {!RestrictionError=} opt_value
   * @param {number=} opt_index
   * @return {!RestrictionError}
   */
  addError(opt_value?: restriction_restriction_pb.RestrictionError, opt_index?: number): restriction_restriction_pb.RestrictionError {
    return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, restriction_restriction_pb.RestrictionError, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearErrorList() {
    this.setErrorList([]);
  }


}
export namespace CheckoutRequest {
  export interface AsObject {
    peopleHashesList: string[];
    checkinReasonId: number;
    guestList: number[];
    note: string;
  }
}
export class CheckoutRequest extends jspb.Message {
  static readonly displayName = "CheckoutRequest";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [1,3];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, CheckoutRequest.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): CheckoutRequest.AsObject {
    return CheckoutRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!CheckoutRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: CheckoutRequest) {
    var f: any;
    var obj: any = {
      peopleHashesList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
      checkinReasonId: jspb.Message.getFieldWithDefault(msg, 2, 0),
      guestList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
      note: jspb.Message.getFieldWithDefault(msg, 4, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!CheckoutRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new CheckoutRequest;
    return CheckoutRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!CheckoutRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!CheckoutRequest}
   */
  static deserializeBinaryFromReader(msg: CheckoutRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.addPeopleHashes(value1);
        break;
      case 2:
        var value2 = /** @type {number} */ (reader.readInt32());
        msg.setCheckinReasonId(value2);
        break;
      case 3:
        var value3 = /** @type {!Array<number>} */ (reader.readPackedInt32());
        msg.setGuestList(value3);
        break;
      case 4:
        var value4 = /** @type {string} */ (reader.readString());
        msg.setNote(value4);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!CheckoutRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: CheckoutRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getPeopleHashesList();
    if (f.length > 0) {
      writer.writeRepeatedString(
        1,
        f
      );
    }
    f = message.getCheckinReasonId();
    if (f !== 0) {
      writer.writeInt32(
        2,
        f
      );
    }
    f = message.getGuestList();
    if (f.length > 0) {
      writer.writePackedInt32(
        3,
        f
      );
    }
    f = message.getNote();
    if (f.length > 0) {
      writer.writeString(
        4,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    CheckoutRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * repeated string people_hashes = 1;
   * @return {!Array<string>}
   */
  getPeopleHashesList(): string[] {
    return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
  };


  /** @param {!Array<string>} value */
  setPeopleHashesList(value: string[]) {
    jspb.Message.setField(this, 1, value || []);
  }


  /**
   * @param {string} value
   * @param {number=} opt_index
   */
  addPeopleHashes(value: string, opt_index?: number): void {
    jspb.Message.addToRepeatedField(this, 1, value, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearPeopleHashesList() {
    this.setPeopleHashesList([]);
  }


  /**
   * optional int32 checkin_reason_id = 2;
   * @return {number}
   */
  getCheckinReasonId(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
  };


  /** @param {number} value */
  setCheckinReasonId(value: number) {
    jspb.Message.setProto3IntField(this, 2, value);
  }


  /**
   * repeated int32 guest = 3;
   * @return {!Array<number>}
   */
  getGuestList(): number[] {
    return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 3));
  };


  /** @param {!Array<number>} value */
  setGuestList(value: number[]) {
    jspb.Message.setField(this, 3, value || []);
  }


  /**
   * @param {number} value
   * @param {number=} opt_index
   */
  addGuest(value: number, opt_index?: number): void {
    jspb.Message.addToRepeatedField(this, 3, value, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearGuestList() {
    this.setGuestList([]);
  }


  /**
   * optional string note = 4;
   * @return {string}
   */
  getNote(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
  };


  /** @param {string} value */
  setNote(value: string) {
    jspb.Message.setProto3StringField(this, 4, value);
  }


}
export namespace CheckoutResponse {
  export interface AsObject {
    successPeopleHashesList: string[];
    errors: string;
    errorList: restriction_restriction_pb.RestrictionError.AsObject[];
  }
}
export class CheckoutResponse extends jspb.Message {
  static readonly displayName = "CheckoutResponse";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [1,3];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, CheckoutResponse.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): CheckoutResponse.AsObject {
    return CheckoutResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!CheckoutResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: CheckoutResponse) {
    var f: any;
    var obj: any = {
      successPeopleHashesList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
      errors: jspb.Message.getFieldWithDefault(msg, 2, ""),
      errorList: jspb.Message.toObjectList(msg.getErrorList(),
      restriction_restriction_pb.RestrictionError.toObject, includeInstance)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!CheckoutResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new CheckoutResponse;
    return CheckoutResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!CheckoutResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!CheckoutResponse}
   */
  static deserializeBinaryFromReader(msg: CheckoutResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.addSuccessPeopleHashes(value1);
        break;
      case 2:
        var value2 = /** @type {string} */ (reader.readString());
        msg.setErrors(value2);
        break;
      case 3:
        var value3 = new restriction_restriction_pb.RestrictionError;
        reader.readMessage(value3,restriction_restriction_pb.RestrictionError.deserializeBinaryFromReader);
        msg.addError(value3);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!CheckoutResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: CheckoutResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getSuccessPeopleHashesList();
    if (f.length > 0) {
      writer.writeRepeatedString(
        1,
        f
      );
    }
    f = message.getErrors();
    if (f.length > 0) {
      writer.writeString(
        2,
        f
      );
    }
    f = message.getErrorList();
    if (f.length > 0) {
      writer.writeRepeatedMessage(
        3,
        f,
        restriction_restriction_pb.RestrictionError.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    CheckoutResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * repeated string success_people_hashes = 1;
   * @return {!Array<string>}
   */
  getSuccessPeopleHashesList(): string[] {
    return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
  };


  /** @param {!Array<string>} value */
  setSuccessPeopleHashesList(value: string[]) {
    jspb.Message.setField(this, 1, value || []);
  }


  /**
   * @param {string} value
   * @param {number=} opt_index
   */
  addSuccessPeopleHashes(value: string, opt_index?: number): void {
    jspb.Message.addToRepeatedField(this, 1, value, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearSuccessPeopleHashesList() {
    this.setSuccessPeopleHashesList([]);
  }


  /**
   * optional string errors = 2;
   * @return {string}
   */
  getErrors(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
  };


  /** @param {string} value */
  setErrors(value: string) {
    jspb.Message.setProto3StringField(this, 2, value);
  }


  /**
   * repeated restriction.RestrictionError error = 3;
   * @return {!Array<!RestrictionError>}
   */
  getErrorList(): restriction_restriction_pb.RestrictionError[] {
    return /** @type{!Array<!RestrictionError>} */ (
      jspb.Message.getRepeatedWrapperField(this, restriction_restriction_pb.RestrictionError, 3));
  }


  /** @param {!Array<!RestrictionError>} value */
  setErrorList(value?: restriction_restriction_pb.RestrictionError[]) {
    jspb.Message.setRepeatedWrapperField(this, 3, value);
  }


  /**
   * @param {!RestrictionError=} opt_value
   * @param {number=} opt_index
   * @return {!RestrictionError}
   */
  addError(opt_value?: restriction_restriction_pb.RestrictionError, opt_index?: number): restriction_restriction_pb.RestrictionError {
    return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, restriction_restriction_pb.RestrictionError, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearErrorList() {
    this.setErrorList([]);
  }


}
export namespace CheckInReportFilters {
  export interface AsObject {
    issuedToList: string[];
    checkinReasonIdsList: number[];
    startDate?: common_date_pb.DateTime.AsObject;
    endDate?: common_date_pb.DateTime.AsObject;
    gradeList: string[];
    offset: number;
    limit: number;
    rolesList: string[];
    issuedByList: string[];
    bypassLenCheck: boolean;
    userListList: number[];
  }
}
export class CheckInReportFilters extends jspb.Message {
  static readonly displayName = "CheckInReportFilters";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [1,2,5,8,9,11];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, CheckInReportFilters.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): CheckInReportFilters.AsObject {
    return CheckInReportFilters.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!CheckInReportFilters} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: CheckInReportFilters) {
    var f: any;
    var obj: any = {
      issuedToList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
      checkinReasonIdsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
      startDate: (f = msg.getStartDate()) && common_date_pb.DateTime.toObject(includeInstance, f),
      endDate: (f = msg.getEndDate()) && common_date_pb.DateTime.toObject(includeInstance, f),
      gradeList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f,
      offset: jspb.Message.getFieldWithDefault(msg, 6, 0),
      limit: jspb.Message.getFieldWithDefault(msg, 7, 0),
      rolesList: (f = jspb.Message.getRepeatedField(msg, 8)) == null ? undefined : f,
      issuedByList: (f = jspb.Message.getRepeatedField(msg, 9)) == null ? undefined : f,
      bypassLenCheck: jspb.Message.getBooleanFieldWithDefault(msg, 10, false),
      userListList: (f = jspb.Message.getRepeatedField(msg, 11)) == null ? undefined : f
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!CheckInReportFilters}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new CheckInReportFilters;
    return CheckInReportFilters.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!CheckInReportFilters} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!CheckInReportFilters}
   */
  static deserializeBinaryFromReader(msg: CheckInReportFilters, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.addIssuedTo(value1);
        break;
      case 2:
        var value2 = /** @type {!Array<number>} */ (reader.readPackedInt32());
        msg.setCheckinReasonIdsList(value2);
        break;
      case 3:
        var value3 = new common_date_pb.DateTime;
        reader.readMessage(value3,common_date_pb.DateTime.deserializeBinaryFromReader);
        msg.setStartDate(value3);
        break;
      case 4:
        var value4 = new common_date_pb.DateTime;
        reader.readMessage(value4,common_date_pb.DateTime.deserializeBinaryFromReader);
        msg.setEndDate(value4);
        break;
      case 5:
        var value5 = /** @type {string} */ (reader.readString());
        msg.addGrade(value5);
        break;
      case 6:
        var value6 = /** @type {number} */ (reader.readInt32());
        msg.setOffset(value6);
        break;
      case 7:
        var value7 = /** @type {number} */ (reader.readInt32());
        msg.setLimit(value7);
        break;
      case 8:
        var value8 = /** @type {string} */ (reader.readString());
        msg.addRoles(value8);
        break;
      case 9:
        var value9 = /** @type {string} */ (reader.readString());
        msg.addIssuedBy(value9);
        break;
      case 10:
        var value10 = /** @type {boolean} */ (reader.readBool());
        msg.setBypassLenCheck(value10);
        break;
      case 11:
        var value11 = /** @type {!Array<number>} */ (reader.readPackedInt32());
        msg.setUserListList(value11);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!CheckInReportFilters} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: CheckInReportFilters, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getIssuedToList();
    if (f.length > 0) {
      writer.writeRepeatedString(
        1,
        f
      );
    }
    f = message.getCheckinReasonIdsList();
    if (f.length > 0) {
      writer.writePackedInt32(
        2,
        f
      );
    }
    f = message.getStartDate();
    if (f != null) {
      writer.writeMessage(
        3,
        f,
        common_date_pb.DateTime.serializeBinaryToWriter
      );
    }
    f = message.getEndDate();
    if (f != null) {
      writer.writeMessage(
        4,
        f,
        common_date_pb.DateTime.serializeBinaryToWriter
      );
    }
    f = message.getGradeList();
    if (f.length > 0) {
      writer.writeRepeatedString(
        5,
        f
      );
    }
    f = message.getOffset();
    if (f !== 0) {
      writer.writeInt32(
        6,
        f
      );
    }
    f = message.getLimit();
    if (f !== 0) {
      writer.writeInt32(
        7,
        f
      );
    }
    f = message.getRolesList();
    if (f.length > 0) {
      writer.writeRepeatedString(
        8,
        f
      );
    }
    f = message.getIssuedByList();
    if (f.length > 0) {
      writer.writeRepeatedString(
        9,
        f
      );
    }
    f = message.getBypassLenCheck();
    if (f) {
      writer.writeBool(
        10,
        f
      );
    }
    f = message.getUserListList();
    if (f.length > 0) {
      writer.writePackedInt32(
        11,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    CheckInReportFilters.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * repeated string issued_to = 1;
   * @return {!Array<string>}
   */
  getIssuedToList(): string[] {
    return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
  };


  /** @param {!Array<string>} value */
  setIssuedToList(value: string[]) {
    jspb.Message.setField(this, 1, value || []);
  }


  /**
   * @param {string} value
   * @param {number=} opt_index
   */
  addIssuedTo(value: string, opt_index?: number): void {
    jspb.Message.addToRepeatedField(this, 1, value, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearIssuedToList() {
    this.setIssuedToList([]);
  }


  /**
   * repeated int32 checkin_reason_ids = 2;
   * @return {!Array<number>}
   */
  getCheckinReasonIdsList(): number[] {
    return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 2));
  };


  /** @param {!Array<number>} value */
  setCheckinReasonIdsList(value: number[]) {
    jspb.Message.setField(this, 2, value || []);
  }


  /**
   * @param {number} value
   * @param {number=} opt_index
   */
  addCheckinReasonIds(value: number, opt_index?: number): void {
    jspb.Message.addToRepeatedField(this, 2, value, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearCheckinReasonIdsList() {
    this.setCheckinReasonIdsList([]);
  }


  /**
   * optional minga.common.DateTime start_date = 3;
   * @return {?DateTime}
   */
  getStartDate(): common_date_pb.DateTime {
    return /** @type{?DateTime} */ (
      jspb.Message.getWrapperField(this, common_date_pb.DateTime, 3));
  }


  /** @param {?DateTime|undefined} value */
  setStartDate(value?: common_date_pb.DateTime) {
    jspb.Message.setWrapperField(this, 3, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearStartDate() {
    this.setStartDate(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasStartDate(): boolean {
    return jspb.Message.getField(this, 3) != null;
  }


  /**
   * optional minga.common.DateTime end_date = 4;
   * @return {?DateTime}
   */
  getEndDate(): common_date_pb.DateTime {
    return /** @type{?DateTime} */ (
      jspb.Message.getWrapperField(this, common_date_pb.DateTime, 4));
  }


  /** @param {?DateTime|undefined} value */
  setEndDate(value?: common_date_pb.DateTime) {
    jspb.Message.setWrapperField(this, 4, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearEndDate() {
    this.setEndDate(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasEndDate(): boolean {
    return jspb.Message.getField(this, 4) != null;
  }


  /**
   * repeated string grade = 5;
   * @return {!Array<string>}
   */
  getGradeList(): string[] {
    return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 5));
  };


  /** @param {!Array<string>} value */
  setGradeList(value: string[]) {
    jspb.Message.setField(this, 5, value || []);
  }


  /**
   * @param {string} value
   * @param {number=} opt_index
   */
  addGrade(value: string, opt_index?: number): void {
    jspb.Message.addToRepeatedField(this, 5, value, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearGradeList() {
    this.setGradeList([]);
  }


  /**
   * optional int32 offset = 6;
   * @return {number}
   */
  getOffset(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
  };


  /** @param {number} value */
  setOffset(value: number) {
    jspb.Message.setProto3IntField(this, 6, value);
  }


  /**
   * optional int32 limit = 7;
   * @return {number}
   */
  getLimit(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
  };


  /** @param {number} value */
  setLimit(value: number) {
    jspb.Message.setProto3IntField(this, 7, value);
  }


  /**
   * repeated string roles = 8;
   * @return {!Array<string>}
   */
  getRolesList(): string[] {
    return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 8));
  };


  /** @param {!Array<string>} value */
  setRolesList(value: string[]) {
    jspb.Message.setField(this, 8, value || []);
  }


  /**
   * @param {string} value
   * @param {number=} opt_index
   */
  addRoles(value: string, opt_index?: number): void {
    jspb.Message.addToRepeatedField(this, 8, value, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearRolesList() {
    this.setRolesList([]);
  }


  /**
   * repeated string issued_by = 9;
   * @return {!Array<string>}
   */
  getIssuedByList(): string[] {
    return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 9));
  };


  /** @param {!Array<string>} value */
  setIssuedByList(value: string[]) {
    jspb.Message.setField(this, 9, value || []);
  }


  /**
   * @param {string} value
   * @param {number=} opt_index
   */
  addIssuedBy(value: string, opt_index?: number): void {
    jspb.Message.addToRepeatedField(this, 9, value, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearIssuedByList() {
    this.setIssuedByList([]);
  }


  /**
   * optional bool bypass_len_check = 10;
   * @return {boolean}
   */
  getBypassLenCheck(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
  };


  /** @param {boolean} value */
  setBypassLenCheck(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 10, value);
  }


  /**
   * repeated int32 user_list = 11;
   * @return {!Array<number>}
   */
  getUserListList(): number[] {
    return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 11));
  };


  /** @param {!Array<number>} value */
  setUserListList(value: number[]) {
    jspb.Message.setField(this, 11, value || []);
  }


  /**
   * @param {number} value
   * @param {number=} opt_index
   */
  addUserList(value: number, opt_index?: number): void {
    jspb.Message.addToRepeatedField(this, 11, value, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearUserListList() {
    this.setUserListList([]);
  }


}
export namespace GetMingaCheckinReasonRequest {
  export interface AsObject {
    id: number;
  }
}
export class GetMingaCheckinReasonRequest extends jspb.Message {
  static readonly displayName = "GetMingaCheckinReasonRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): GetMingaCheckinReasonRequest.AsObject {
    return GetMingaCheckinReasonRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!GetMingaCheckinReasonRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: GetMingaCheckinReasonRequest) {
    var f: any;
    var obj: any = {
      id: jspb.Message.getFieldWithDefault(msg, 1, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!GetMingaCheckinReasonRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new GetMingaCheckinReasonRequest;
    return GetMingaCheckinReasonRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!GetMingaCheckinReasonRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!GetMingaCheckinReasonRequest}
   */
  static deserializeBinaryFromReader(msg: GetMingaCheckinReasonRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {number} */ (reader.readInt32());
        msg.setId(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!GetMingaCheckinReasonRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: GetMingaCheckinReasonRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getId();
    if (f !== 0) {
      writer.writeInt32(
        1,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    GetMingaCheckinReasonRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional int32 id = 1;
   * @return {number}
   */
  getId(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
  };


  /** @param {number} value */
  setId(value: number) {
    jspb.Message.setProto3IntField(this, 1, value);
  }


}
export namespace GetMingaCheckinReasonResponse {
  export interface AsObject {
    checkinReason?: CheckinReason.AsObject;
  }
}
export class GetMingaCheckinReasonResponse extends jspb.Message {
  static readonly displayName = "GetMingaCheckinReasonResponse";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): GetMingaCheckinReasonResponse.AsObject {
    return GetMingaCheckinReasonResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!GetMingaCheckinReasonResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: GetMingaCheckinReasonResponse) {
    var f: any;
    var obj: any = {
      checkinReason: (f = msg.getCheckinReason()) && CheckinReason.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!GetMingaCheckinReasonResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new GetMingaCheckinReasonResponse;
    return GetMingaCheckinReasonResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!GetMingaCheckinReasonResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!GetMingaCheckinReasonResponse}
   */
  static deserializeBinaryFromReader(msg: GetMingaCheckinReasonResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new CheckinReason;
        reader.readMessage(value1,CheckinReason.deserializeBinaryFromReader);
        msg.setCheckinReason(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!GetMingaCheckinReasonResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: GetMingaCheckinReasonResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getCheckinReason();
    if (f != null) {
      writer.writeMessage(
        1,
        f,
        CheckinReason.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    GetMingaCheckinReasonResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional CheckinReason checkin_reason = 1;
   * @return {?CheckinReason}
   */
  getCheckinReason(): CheckinReason {
    return /** @type{?CheckinReason} */ (
      jspb.Message.getWrapperField(this, CheckinReason, 1));
  }


  /** @param {?CheckinReason|undefined} value */
  setCheckinReason(value?: CheckinReason) {
    jspb.Message.setWrapperField(this, 1, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearCheckinReason() {
    this.setCheckinReason(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasCheckinReason(): boolean {
    return jspb.Message.getField(this, 1) != null;
  }


}
export namespace GetMingaCheckinReasonsRequest {
  export interface AsObject {
    getActiveOnly: boolean;
    offset: number;
    limit: number;
    includeCounts: boolean;
    kioskOnly: boolean;
  }
}
export class GetMingaCheckinReasonsRequest extends jspb.Message {
  static readonly displayName = "GetMingaCheckinReasonsRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): GetMingaCheckinReasonsRequest.AsObject {
    return GetMingaCheckinReasonsRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!GetMingaCheckinReasonsRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: GetMingaCheckinReasonsRequest) {
    var f: any;
    var obj: any = {
      getActiveOnly: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
      offset: jspb.Message.getFieldWithDefault(msg, 2, 0),
      limit: jspb.Message.getFieldWithDefault(msg, 3, 0),
      includeCounts: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
      kioskOnly: jspb.Message.getBooleanFieldWithDefault(msg, 5, false)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!GetMingaCheckinReasonsRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new GetMingaCheckinReasonsRequest;
    return GetMingaCheckinReasonsRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!GetMingaCheckinReasonsRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!GetMingaCheckinReasonsRequest}
   */
  static deserializeBinaryFromReader(msg: GetMingaCheckinReasonsRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {boolean} */ (reader.readBool());
        msg.setGetActiveOnly(value1);
        break;
      case 2:
        var value2 = /** @type {number} */ (reader.readInt32());
        msg.setOffset(value2);
        break;
      case 3:
        var value3 = /** @type {number} */ (reader.readInt32());
        msg.setLimit(value3);
        break;
      case 4:
        var value4 = /** @type {boolean} */ (reader.readBool());
        msg.setIncludeCounts(value4);
        break;
      case 5:
        var value5 = /** @type {boolean} */ (reader.readBool());
        msg.setKioskOnly(value5);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!GetMingaCheckinReasonsRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: GetMingaCheckinReasonsRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getGetActiveOnly();
    if (f) {
      writer.writeBool(
        1,
        f
      );
    }
    f = message.getOffset();
    if (f !== 0) {
      writer.writeInt32(
        2,
        f
      );
    }
    f = message.getLimit();
    if (f !== 0) {
      writer.writeInt32(
        3,
        f
      );
    }
    f = message.getIncludeCounts();
    if (f) {
      writer.writeBool(
        4,
        f
      );
    }
    f = message.getKioskOnly();
    if (f) {
      writer.writeBool(
        5,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    GetMingaCheckinReasonsRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional bool get_active_only = 1;
   * @return {boolean}
   */
  getGetActiveOnly(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
  };


  /** @param {boolean} value */
  setGetActiveOnly(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 1, value);
  }


  /**
   * optional int32 offset = 2;
   * @return {number}
   */
  getOffset(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
  };


  /** @param {number} value */
  setOffset(value: number) {
    jspb.Message.setProto3IntField(this, 2, value);
  }


  /**
   * optional int32 limit = 3;
   * @return {number}
   */
  getLimit(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
  };


  /** @param {number} value */
  setLimit(value: number) {
    jspb.Message.setProto3IntField(this, 3, value);
  }


  /**
   * optional bool include_counts = 4;
   * @return {boolean}
   */
  getIncludeCounts(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
  };


  /** @param {boolean} value */
  setIncludeCounts(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 4, value);
  }


  /**
   * optional bool kiosk_only = 5;
   * @return {boolean}
   */
  getKioskOnly(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
  };


  /** @param {boolean} value */
  setKioskOnly(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 5, value);
  }


}
export namespace GetMingaCheckinReasonsResponse {
  export interface AsObject {
    checkinReasonsList: CheckinReason.AsObject[];
  }
}
export class GetMingaCheckinReasonsResponse extends jspb.Message {
  static readonly displayName = "GetMingaCheckinReasonsResponse";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [1];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, GetMingaCheckinReasonsResponse.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): GetMingaCheckinReasonsResponse.AsObject {
    return GetMingaCheckinReasonsResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!GetMingaCheckinReasonsResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: GetMingaCheckinReasonsResponse) {
    var f: any;
    var obj: any = {
      checkinReasonsList: jspb.Message.toObjectList(msg.getCheckinReasonsList(),
      CheckinReason.toObject, includeInstance)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!GetMingaCheckinReasonsResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new GetMingaCheckinReasonsResponse;
    return GetMingaCheckinReasonsResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!GetMingaCheckinReasonsResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!GetMingaCheckinReasonsResponse}
   */
  static deserializeBinaryFromReader(msg: GetMingaCheckinReasonsResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new CheckinReason;
        reader.readMessage(value1,CheckinReason.deserializeBinaryFromReader);
        msg.addCheckinReasons(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!GetMingaCheckinReasonsResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: GetMingaCheckinReasonsResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getCheckinReasonsList();
    if (f.length > 0) {
      writer.writeRepeatedMessage(
        1,
        f,
        CheckinReason.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    GetMingaCheckinReasonsResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * repeated CheckinReason checkin_reasons = 1;
   * @return {!Array<!CheckinReason>}
   */
  getCheckinReasonsList(): CheckinReason[] {
    return /** @type{!Array<!CheckinReason>} */ (
      jspb.Message.getRepeatedWrapperField(this, CheckinReason, 1));
  }


  /** @param {!Array<!CheckinReason>} value */
  setCheckinReasonsList(value?: CheckinReason[]) {
    jspb.Message.setRepeatedWrapperField(this, 1, value);
  }


  /**
   * @param {!CheckinReason=} opt_value
   * @param {number=} opt_index
   * @return {!CheckinReason}
   */
  addCheckinReasons(opt_value?: CheckinReason, opt_index?: number): CheckinReason {
    return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, CheckinReason, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearCheckinReasonsList() {
    this.setCheckinReasonsList([]);
  }


}
export namespace CheckinPerson {
  export interface AsObject {
    id: number;
    displayName: string;
    grade: string;
    studentId: string;
    hash: string;
    badgeIconUrl: string;
    badgeIconColor: string;
    badgeRoleName: string;
    firstName: string;
    lastName: string;
  }
}
export class CheckinPerson extends jspb.Message {
  static readonly displayName = "CheckinPerson";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): CheckinPerson.AsObject {
    return CheckinPerson.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!CheckinPerson} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: CheckinPerson) {
    var f: any;
    var obj: any = {
      id: jspb.Message.getFieldWithDefault(msg, 1, 0),
      displayName: jspb.Message.getFieldWithDefault(msg, 2, ""),
      grade: jspb.Message.getFieldWithDefault(msg, 3, ""),
      studentId: jspb.Message.getFieldWithDefault(msg, 4, ""),
      hash: jspb.Message.getFieldWithDefault(msg, 5, ""),
      badgeIconUrl: jspb.Message.getFieldWithDefault(msg, 6, ""),
      badgeIconColor: jspb.Message.getFieldWithDefault(msg, 7, ""),
      badgeRoleName: jspb.Message.getFieldWithDefault(msg, 8, ""),
      firstName: jspb.Message.getFieldWithDefault(msg, 9, ""),
      lastName: jspb.Message.getFieldWithDefault(msg, 10, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!CheckinPerson}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new CheckinPerson;
    return CheckinPerson.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!CheckinPerson} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!CheckinPerson}
   */
  static deserializeBinaryFromReader(msg: CheckinPerson, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {number} */ (reader.readInt32());
        msg.setId(value1);
        break;
      case 2:
        var value2 = /** @type {string} */ (reader.readString());
        msg.setDisplayName(value2);
        break;
      case 3:
        var value3 = /** @type {string} */ (reader.readString());
        msg.setGrade(value3);
        break;
      case 4:
        var value4 = /** @type {string} */ (reader.readString());
        msg.setStudentId(value4);
        break;
      case 5:
        var value5 = /** @type {string} */ (reader.readString());
        msg.setHash(value5);
        break;
      case 6:
        var value6 = /** @type {string} */ (reader.readString());
        msg.setBadgeIconUrl(value6);
        break;
      case 7:
        var value7 = /** @type {string} */ (reader.readString());
        msg.setBadgeIconColor(value7);
        break;
      case 8:
        var value8 = /** @type {string} */ (reader.readString());
        msg.setBadgeRoleName(value8);
        break;
      case 9:
        var value9 = /** @type {string} */ (reader.readString());
        msg.setFirstName(value9);
        break;
      case 10:
        var value10 = /** @type {string} */ (reader.readString());
        msg.setLastName(value10);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!CheckinPerson} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: CheckinPerson, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getId();
    if (f !== 0) {
      writer.writeInt32(
        1,
        f
      );
    }
    f = message.getDisplayName();
    if (f.length > 0) {
      writer.writeString(
        2,
        f
      );
    }
    f = message.getGrade();
    if (f.length > 0) {
      writer.writeString(
        3,
        f
      );
    }
    f = message.getStudentId();
    if (f.length > 0) {
      writer.writeString(
        4,
        f
      );
    }
    f = message.getHash();
    if (f.length > 0) {
      writer.writeString(
        5,
        f
      );
    }
    f = message.getBadgeIconUrl();
    if (f.length > 0) {
      writer.writeString(
        6,
        f
      );
    }
    f = message.getBadgeIconColor();
    if (f.length > 0) {
      writer.writeString(
        7,
        f
      );
    }
    f = message.getBadgeRoleName();
    if (f.length > 0) {
      writer.writeString(
        8,
        f
      );
    }
    f = message.getFirstName();
    if (f.length > 0) {
      writer.writeString(
        9,
        f
      );
    }
    f = message.getLastName();
    if (f.length > 0) {
      writer.writeString(
        10,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    CheckinPerson.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional int32 id = 1;
   * @return {number}
   */
  getId(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
  };


  /** @param {number} value */
  setId(value: number) {
    jspb.Message.setProto3IntField(this, 1, value);
  }


  /**
   * optional string display_name = 2;
   * @return {string}
   */
  getDisplayName(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
  };


  /** @param {string} value */
  setDisplayName(value: string) {
    jspb.Message.setProto3StringField(this, 2, value);
  }


  /**
   * optional string grade = 3;
   * @return {string}
   */
  getGrade(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
  };


  /** @param {string} value */
  setGrade(value: string) {
    jspb.Message.setProto3StringField(this, 3, value);
  }


  /**
   * optional string student_id = 4;
   * @return {string}
   */
  getStudentId(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
  };


  /** @param {string} value */
  setStudentId(value: string) {
    jspb.Message.setProto3StringField(this, 4, value);
  }


  /**
   * optional string hash = 5;
   * @return {string}
   */
  getHash(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
  };


  /** @param {string} value */
  setHash(value: string) {
    jspb.Message.setProto3StringField(this, 5, value);
  }


  /**
   * optional string badge_icon_url = 6;
   * @return {string}
   */
  getBadgeIconUrl(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
  };


  /** @param {string} value */
  setBadgeIconUrl(value: string) {
    jspb.Message.setProto3StringField(this, 6, value);
  }


  /**
   * optional string badge_icon_color = 7;
   * @return {string}
   */
  getBadgeIconColor(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
  };


  /** @param {string} value */
  setBadgeIconColor(value: string) {
    jspb.Message.setProto3StringField(this, 7, value);
  }


  /**
   * optional string badge_role_name = 8;
   * @return {string}
   */
  getBadgeRoleName(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
  };


  /** @param {string} value */
  setBadgeRoleName(value: string) {
    jspb.Message.setProto3StringField(this, 8, value);
  }


  /**
   * optional string first_name = 9;
   * @return {string}
   */
  getFirstName(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
  };


  /** @param {string} value */
  setFirstName(value: string) {
    jspb.Message.setProto3StringField(this, 9, value);
  }


  /**
   * optional string last_name = 10;
   * @return {string}
   */
  getLastName(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
  };


  /** @param {string} value */
  setLastName(value: string) {
    jspb.Message.setProto3StringField(this, 10, value);
  }


}
export namespace ToggleCheckinReasonActiveRequest {
  export interface AsObject {
    checkinReasonId: number;
  }
}
export class ToggleCheckinReasonActiveRequest extends jspb.Message {
  static readonly displayName = "ToggleCheckinReasonActiveRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): ToggleCheckinReasonActiveRequest.AsObject {
    return ToggleCheckinReasonActiveRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!ToggleCheckinReasonActiveRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: ToggleCheckinReasonActiveRequest) {
    var f: any;
    var obj: any = {
      checkinReasonId: jspb.Message.getFieldWithDefault(msg, 1, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!ToggleCheckinReasonActiveRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new ToggleCheckinReasonActiveRequest;
    return ToggleCheckinReasonActiveRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!ToggleCheckinReasonActiveRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!ToggleCheckinReasonActiveRequest}
   */
  static deserializeBinaryFromReader(msg: ToggleCheckinReasonActiveRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {number} */ (reader.readInt32());
        msg.setCheckinReasonId(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!ToggleCheckinReasonActiveRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: ToggleCheckinReasonActiveRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getCheckinReasonId();
    if (f !== 0) {
      writer.writeInt32(
        1,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    ToggleCheckinReasonActiveRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional int32 checkin_reason_id = 1;
   * @return {number}
   */
  getCheckinReasonId(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
  };


  /** @param {number} value */
  setCheckinReasonId(value: number) {
    jspb.Message.setProto3IntField(this, 1, value);
  }


}
export namespace ToggleCheckinReasonActiveResponse {
  export interface AsObject {
  }
}
export class ToggleCheckinReasonActiveResponse extends jspb.Message {
  static readonly displayName = "ToggleCheckinReasonActiveResponse";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): ToggleCheckinReasonActiveResponse.AsObject {
    return ToggleCheckinReasonActiveResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!ToggleCheckinReasonActiveResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: ToggleCheckinReasonActiveResponse) {
    var f: any;
    var obj: any = {

    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!ToggleCheckinReasonActiveResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new ToggleCheckinReasonActiveResponse;
    return ToggleCheckinReasonActiveResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!ToggleCheckinReasonActiveResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!ToggleCheckinReasonActiveResponse}
   */
  static deserializeBinaryFromReader(msg: ToggleCheckinReasonActiveResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!ToggleCheckinReasonActiveResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: ToggleCheckinReasonActiveResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    ToggleCheckinReasonActiveResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
}
export namespace UpsertCheckinReasonRequest {
  export interface AsObject {
    checkinReason?: CheckinReason.AsObject;
    bypassRestrictions: boolean;
  }
}
export class UpsertCheckinReasonRequest extends jspb.Message {
  static readonly displayName = "UpsertCheckinReasonRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): UpsertCheckinReasonRequest.AsObject {
    return UpsertCheckinReasonRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!UpsertCheckinReasonRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: UpsertCheckinReasonRequest) {
    var f: any;
    var obj: any = {
      checkinReason: (f = msg.getCheckinReason()) && CheckinReason.toObject(includeInstance, f),
      bypassRestrictions: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!UpsertCheckinReasonRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new UpsertCheckinReasonRequest;
    return UpsertCheckinReasonRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!UpsertCheckinReasonRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!UpsertCheckinReasonRequest}
   */
  static deserializeBinaryFromReader(msg: UpsertCheckinReasonRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new CheckinReason;
        reader.readMessage(value1,CheckinReason.deserializeBinaryFromReader);
        msg.setCheckinReason(value1);
        break;
      case 2:
        var value2 = /** @type {boolean} */ (reader.readBool());
        msg.setBypassRestrictions(value2);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!UpsertCheckinReasonRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: UpsertCheckinReasonRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getCheckinReason();
    if (f != null) {
      writer.writeMessage(
        1,
        f,
        CheckinReason.serializeBinaryToWriter
      );
    }
    f = message.getBypassRestrictions();
    if (f) {
      writer.writeBool(
        2,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    UpsertCheckinReasonRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional CheckinReason checkin_reason = 1;
   * @return {?CheckinReason}
   */
  getCheckinReason(): CheckinReason {
    return /** @type{?CheckinReason} */ (
      jspb.Message.getWrapperField(this, CheckinReason, 1));
  }


  /** @param {?CheckinReason|undefined} value */
  setCheckinReason(value?: CheckinReason) {
    jspb.Message.setWrapperField(this, 1, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearCheckinReason() {
    this.setCheckinReason(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasCheckinReason(): boolean {
    return jspb.Message.getField(this, 1) != null;
  }


  /**
   * optional bool bypass_restrictions = 2;
   * @return {boolean}
   */
  getBypassRestrictions(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
  };


  /** @param {boolean} value */
  setBypassRestrictions(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 2, value);
  }


}
export namespace UpsertCheckinReasonResponse {
  export interface AsObject {
    checkinReason?: CheckinReason.AsObject;
  }
}
export class UpsertCheckinReasonResponse extends jspb.Message {
  static readonly displayName = "UpsertCheckinReasonResponse";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): UpsertCheckinReasonResponse.AsObject {
    return UpsertCheckinReasonResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!UpsertCheckinReasonResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: UpsertCheckinReasonResponse) {
    var f: any;
    var obj: any = {
      checkinReason: (f = msg.getCheckinReason()) && CheckinReason.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!UpsertCheckinReasonResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new UpsertCheckinReasonResponse;
    return UpsertCheckinReasonResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!UpsertCheckinReasonResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!UpsertCheckinReasonResponse}
   */
  static deserializeBinaryFromReader(msg: UpsertCheckinReasonResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new CheckinReason;
        reader.readMessage(value1,CheckinReason.deserializeBinaryFromReader);
        msg.setCheckinReason(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!UpsertCheckinReasonResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: UpsertCheckinReasonResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getCheckinReason();
    if (f != null) {
      writer.writeMessage(
        1,
        f,
        CheckinReason.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    UpsertCheckinReasonResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional CheckinReason checkin_reason = 1;
   * @return {?CheckinReason}
   */
  getCheckinReason(): CheckinReason {
    return /** @type{?CheckinReason} */ (
      jspb.Message.getWrapperField(this, CheckinReason, 1));
  }


  /** @param {?CheckinReason|undefined} value */
  setCheckinReason(value?: CheckinReason) {
    jspb.Message.setWrapperField(this, 1, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearCheckinReason() {
    this.setCheckinReason(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasCheckinReason(): boolean {
    return jspb.Message.getField(this, 1) != null;
  }


}
export namespace DeleteCheckinReasonRequest {
  export interface AsObject {
    checkinReasonId: number;
  }
}
export class DeleteCheckinReasonRequest extends jspb.Message {
  static readonly displayName = "DeleteCheckinReasonRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): DeleteCheckinReasonRequest.AsObject {
    return DeleteCheckinReasonRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!DeleteCheckinReasonRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: DeleteCheckinReasonRequest) {
    var f: any;
    var obj: any = {
      checkinReasonId: jspb.Message.getFieldWithDefault(msg, 1, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!DeleteCheckinReasonRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new DeleteCheckinReasonRequest;
    return DeleteCheckinReasonRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!DeleteCheckinReasonRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!DeleteCheckinReasonRequest}
   */
  static deserializeBinaryFromReader(msg: DeleteCheckinReasonRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {number} */ (reader.readInt32());
        msg.setCheckinReasonId(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!DeleteCheckinReasonRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: DeleteCheckinReasonRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getCheckinReasonId();
    if (f !== 0) {
      writer.writeInt32(
        1,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    DeleteCheckinReasonRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional int32 checkin_reason_id = 1;
   * @return {number}
   */
  getCheckinReasonId(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
  };


  /** @param {number} value */
  setCheckinReasonId(value: number) {
    jspb.Message.setProto3IntField(this, 1, value);
  }


}
export namespace DeleteCheckinReasonResponse {
  export interface AsObject {
  }
}
export class DeleteCheckinReasonResponse extends jspb.Message {
  static readonly displayName = "DeleteCheckinReasonResponse";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): DeleteCheckinReasonResponse.AsObject {
    return DeleteCheckinReasonResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!DeleteCheckinReasonResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: DeleteCheckinReasonResponse) {
    var f: any;
    var obj: any = {

    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!DeleteCheckinReasonResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new DeleteCheckinReasonResponse;
    return DeleteCheckinReasonResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!DeleteCheckinReasonResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!DeleteCheckinReasonResponse}
   */
  static deserializeBinaryFromReader(msg: DeleteCheckinReasonResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!DeleteCheckinReasonResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: DeleteCheckinReasonResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    DeleteCheckinReasonResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
}
export namespace AddDefaultCheckinReasonsRequest {
  export interface AsObject {
    mingaHash: string;
  }
}
export class AddDefaultCheckinReasonsRequest extends jspb.Message {
  static readonly displayName = "AddDefaultCheckinReasonsRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): AddDefaultCheckinReasonsRequest.AsObject {
    return AddDefaultCheckinReasonsRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!AddDefaultCheckinReasonsRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: AddDefaultCheckinReasonsRequest) {
    var f: any;
    var obj: any = {
      mingaHash: jspb.Message.getFieldWithDefault(msg, 1, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!AddDefaultCheckinReasonsRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new AddDefaultCheckinReasonsRequest;
    return AddDefaultCheckinReasonsRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!AddDefaultCheckinReasonsRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!AddDefaultCheckinReasonsRequest}
   */
  static deserializeBinaryFromReader(msg: AddDefaultCheckinReasonsRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setMingaHash(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!AddDefaultCheckinReasonsRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: AddDefaultCheckinReasonsRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getMingaHash();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    AddDefaultCheckinReasonsRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string minga_hash = 1;
   * @return {string}
   */
  getMingaHash(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setMingaHash(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


}
export namespace AddDefaultCheckinReasonsResponse {
  export interface AsObject {
    errorMessage: string;
  }
}
export class AddDefaultCheckinReasonsResponse extends jspb.Message {
  static readonly displayName = "AddDefaultCheckinReasonsResponse";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): AddDefaultCheckinReasonsResponse.AsObject {
    return AddDefaultCheckinReasonsResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!AddDefaultCheckinReasonsResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: AddDefaultCheckinReasonsResponse) {
    var f: any;
    var obj: any = {
      errorMessage: jspb.Message.getFieldWithDefault(msg, 1, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!AddDefaultCheckinReasonsResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new AddDefaultCheckinReasonsResponse;
    return AddDefaultCheckinReasonsResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!AddDefaultCheckinReasonsResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!AddDefaultCheckinReasonsResponse}
   */
  static deserializeBinaryFromReader(msg: AddDefaultCheckinReasonsResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setErrorMessage(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!AddDefaultCheckinReasonsResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: AddDefaultCheckinReasonsResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getErrorMessage();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    AddDefaultCheckinReasonsResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string error_message = 1;
   * @return {string}
   */
  getErrorMessage(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setErrorMessage(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


}
export namespace SelfCheckInRequest {
  export interface AsObject {
    checkinReasonId: number;
    code: string;
    bypassCode: boolean;
    note: string;
    isKiosk: boolean;
  }
}
export class SelfCheckInRequest extends jspb.Message {
  static readonly displayName = "SelfCheckInRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): SelfCheckInRequest.AsObject {
    return SelfCheckInRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!SelfCheckInRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: SelfCheckInRequest) {
    var f: any;
    var obj: any = {
      checkinReasonId: jspb.Message.getFieldWithDefault(msg, 1, 0),
      code: jspb.Message.getFieldWithDefault(msg, 2, ""),
      bypassCode: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
      note: jspb.Message.getFieldWithDefault(msg, 4, ""),
      isKiosk: jspb.Message.getBooleanFieldWithDefault(msg, 5, false)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!SelfCheckInRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new SelfCheckInRequest;
    return SelfCheckInRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!SelfCheckInRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!SelfCheckInRequest}
   */
  static deserializeBinaryFromReader(msg: SelfCheckInRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {number} */ (reader.readInt32());
        msg.setCheckinReasonId(value1);
        break;
      case 2:
        var value2 = /** @type {string} */ (reader.readString());
        msg.setCode(value2);
        break;
      case 3:
        var value3 = /** @type {boolean} */ (reader.readBool());
        msg.setBypassCode(value3);
        break;
      case 4:
        var value4 = /** @type {string} */ (reader.readString());
        msg.setNote(value4);
        break;
      case 5:
        var value5 = /** @type {boolean} */ (reader.readBool());
        msg.setIsKiosk(value5);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!SelfCheckInRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: SelfCheckInRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getCheckinReasonId();
    if (f !== 0) {
      writer.writeInt32(
        1,
        f
      );
    }
    f = message.getCode();
    if (f.length > 0) {
      writer.writeString(
        2,
        f
      );
    }
    f = message.getBypassCode();
    if (f) {
      writer.writeBool(
        3,
        f
      );
    }
    f = message.getNote();
    if (f.length > 0) {
      writer.writeString(
        4,
        f
      );
    }
    f = message.getIsKiosk();
    if (f) {
      writer.writeBool(
        5,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    SelfCheckInRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional int32 checkin_reason_id = 1;
   * @return {number}
   */
  getCheckinReasonId(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
  };


  /** @param {number} value */
  setCheckinReasonId(value: number) {
    jspb.Message.setProto3IntField(this, 1, value);
  }


  /**
   * optional string code = 2;
   * @return {string}
   */
  getCode(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
  };


  /** @param {string} value */
  setCode(value: string) {
    jspb.Message.setProto3StringField(this, 2, value);
  }


  /**
   * optional bool bypass_code = 3;
   * @return {boolean}
   */
  getBypassCode(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
  };


  /** @param {boolean} value */
  setBypassCode(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 3, value);
  }


  /**
   * optional string note = 4;
   * @return {string}
   */
  getNote(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
  };


  /** @param {string} value */
  setNote(value: string) {
    jspb.Message.setProto3StringField(this, 4, value);
  }


  /**
   * optional bool is_kiosk = 5;
   * @return {boolean}
   */
  getIsKiosk(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
  };


  /** @param {boolean} value */
  setIsKiosk(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 5, value);
  }


}
export namespace SelfCheckInResponse {
  export interface AsObject {
    errorMessage: string;
  }
}
export class SelfCheckInResponse extends jspb.Message {
  static readonly displayName = "SelfCheckInResponse";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): SelfCheckInResponse.AsObject {
    return SelfCheckInResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!SelfCheckInResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: SelfCheckInResponse) {
    var f: any;
    var obj: any = {
      errorMessage: jspb.Message.getFieldWithDefault(msg, 1, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!SelfCheckInResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new SelfCheckInResponse;
    return SelfCheckInResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!SelfCheckInResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!SelfCheckInResponse}
   */
  static deserializeBinaryFromReader(msg: SelfCheckInResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setErrorMessage(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!SelfCheckInResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: SelfCheckInResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getErrorMessage();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    SelfCheckInResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string error_message = 1;
   * @return {string}
   */
  getErrorMessage(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setErrorMessage(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


}
export namespace SelfCheckOutRequest {
  export interface AsObject {
    checkinReasonId: number;
    note: string;
  }
}
export class SelfCheckOutRequest extends jspb.Message {
  static readonly displayName = "SelfCheckOutRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): SelfCheckOutRequest.AsObject {
    return SelfCheckOutRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!SelfCheckOutRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: SelfCheckOutRequest) {
    var f: any;
    var obj: any = {
      checkinReasonId: jspb.Message.getFieldWithDefault(msg, 1, 0),
      note: jspb.Message.getFieldWithDefault(msg, 2, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!SelfCheckOutRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new SelfCheckOutRequest;
    return SelfCheckOutRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!SelfCheckOutRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!SelfCheckOutRequest}
   */
  static deserializeBinaryFromReader(msg: SelfCheckOutRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {number} */ (reader.readInt32());
        msg.setCheckinReasonId(value1);
        break;
      case 2:
        var value2 = /** @type {string} */ (reader.readString());
        msg.setNote(value2);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!SelfCheckOutRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: SelfCheckOutRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getCheckinReasonId();
    if (f !== 0) {
      writer.writeInt32(
        1,
        f
      );
    }
    f = message.getNote();
    if (f.length > 0) {
      writer.writeString(
        2,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    SelfCheckOutRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional int32 checkin_reason_id = 1;
   * @return {number}
   */
  getCheckinReasonId(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
  };


  /** @param {number} value */
  setCheckinReasonId(value: number) {
    jspb.Message.setProto3IntField(this, 1, value);
  }


  /**
   * optional string note = 2;
   * @return {string}
   */
  getNote(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
  };


  /** @param {string} value */
  setNote(value: string) {
    jspb.Message.setProto3StringField(this, 2, value);
  }


}
export namespace SelfCheckOutResponse {
  export interface AsObject {
    errorMessage: string;
  }
}
export class SelfCheckOutResponse extends jspb.Message {
  static readonly displayName = "SelfCheckOutResponse";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): SelfCheckOutResponse.AsObject {
    return SelfCheckOutResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!SelfCheckOutResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: SelfCheckOutResponse) {
    var f: any;
    var obj: any = {
      errorMessage: jspb.Message.getFieldWithDefault(msg, 1, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!SelfCheckOutResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new SelfCheckOutResponse;
    return SelfCheckOutResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!SelfCheckOutResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!SelfCheckOutResponse}
   */
  static deserializeBinaryFromReader(msg: SelfCheckOutResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setErrorMessage(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!SelfCheckOutResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: SelfCheckOutResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getErrorMessage();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    SelfCheckOutResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string error_message = 1;
   * @return {string}
   */
  getErrorMessage(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setErrorMessage(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


}
export namespace ArchiveCheckinRequest {
  export interface AsObject {
    idsList: number[];
  }
}
export class ArchiveCheckinRequest extends jspb.Message {
  static readonly displayName = "ArchiveCheckinRequest";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [1];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, ArchiveCheckinRequest.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): ArchiveCheckinRequest.AsObject {
    return ArchiveCheckinRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!ArchiveCheckinRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: ArchiveCheckinRequest) {
    var f: any;
    var obj: any = {
      idsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!ArchiveCheckinRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new ArchiveCheckinRequest;
    return ArchiveCheckinRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!ArchiveCheckinRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!ArchiveCheckinRequest}
   */
  static deserializeBinaryFromReader(msg: ArchiveCheckinRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {!Array<number>} */ (reader.readPackedInt32());
        msg.setIdsList(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!ArchiveCheckinRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: ArchiveCheckinRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getIdsList();
    if (f.length > 0) {
      writer.writePackedInt32(
        1,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    ArchiveCheckinRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * repeated int32 ids = 1;
   * @return {!Array<number>}
   */
  getIdsList(): number[] {
    return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 1));
  };


  /** @param {!Array<number>} value */
  setIdsList(value: number[]) {
    jspb.Message.setField(this, 1, value || []);
  }


  /**
   * @param {number} value
   * @param {number=} opt_index
   */
  addIds(value: number, opt_index?: number): void {
    jspb.Message.addToRepeatedField(this, 1, value, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearIdsList() {
    this.setIdsList([]);
  }


}
export namespace ArchiveCheckinResponse {
  export interface AsObject {
  }
}
export class ArchiveCheckinResponse extends jspb.Message {
  static readonly displayName = "ArchiveCheckinResponse";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): ArchiveCheckinResponse.AsObject {
    return ArchiveCheckinResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!ArchiveCheckinResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: ArchiveCheckinResponse) {
    var f: any;
    var obj: any = {

    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!ArchiveCheckinResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new ArchiveCheckinResponse;
    return ArchiveCheckinResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!ArchiveCheckinResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!ArchiveCheckinResponse}
   */
  static deserializeBinaryFromReader(msg: ArchiveCheckinResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!ArchiveCheckinResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: ArchiveCheckinResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    ArchiveCheckinResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
}
export namespace UpdateCheckinRequest {
  export interface AsObject {
    checkin?: EditableCheckin.AsObject;
  }
}
export class UpdateCheckinRequest extends jspb.Message {
  static readonly displayName = "UpdateCheckinRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): UpdateCheckinRequest.AsObject {
    return UpdateCheckinRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!UpdateCheckinRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: UpdateCheckinRequest) {
    var f: any;
    var obj: any = {
      checkin: (f = msg.getCheckin()) && EditableCheckin.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!UpdateCheckinRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new UpdateCheckinRequest;
    return UpdateCheckinRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!UpdateCheckinRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!UpdateCheckinRequest}
   */
  static deserializeBinaryFromReader(msg: UpdateCheckinRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new EditableCheckin;
        reader.readMessage(value1,EditableCheckin.deserializeBinaryFromReader);
        msg.setCheckin(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!UpdateCheckinRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: UpdateCheckinRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getCheckin();
    if (f != null) {
      writer.writeMessage(
        1,
        f,
        EditableCheckin.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    UpdateCheckinRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional EditableCheckin checkin = 1;
   * @return {?EditableCheckin}
   */
  getCheckin(): EditableCheckin {
    return /** @type{?EditableCheckin} */ (
      jspb.Message.getWrapperField(this, EditableCheckin, 1));
  }


  /** @param {?EditableCheckin|undefined} value */
  setCheckin(value?: EditableCheckin) {
    jspb.Message.setWrapperField(this, 1, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearCheckin() {
    this.setCheckin(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasCheckin(): boolean {
    return jspb.Message.getField(this, 1) != null;
  }


}
export namespace UpdateCheckinResponse {
  export interface AsObject {
    checkin?: EditableCheckin.AsObject;
  }
}
export class UpdateCheckinResponse extends jspb.Message {
  static readonly displayName = "UpdateCheckinResponse";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): UpdateCheckinResponse.AsObject {
    return UpdateCheckinResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!UpdateCheckinResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: UpdateCheckinResponse) {
    var f: any;
    var obj: any = {
      checkin: (f = msg.getCheckin()) && EditableCheckin.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!UpdateCheckinResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new UpdateCheckinResponse;
    return UpdateCheckinResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!UpdateCheckinResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!UpdateCheckinResponse}
   */
  static deserializeBinaryFromReader(msg: UpdateCheckinResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 2:
        var value2 = new EditableCheckin;
        reader.readMessage(value2,EditableCheckin.deserializeBinaryFromReader);
        msg.setCheckin(value2);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!UpdateCheckinResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: UpdateCheckinResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getCheckin();
    if (f != null) {
      writer.writeMessage(
        2,
        f,
        EditableCheckin.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    UpdateCheckinResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional EditableCheckin checkin = 2;
   * @return {?EditableCheckin}
   */
  getCheckin(): EditableCheckin {
    return /** @type{?EditableCheckin} */ (
      jspb.Message.getWrapperField(this, EditableCheckin, 2));
  }


  /** @param {?EditableCheckin|undefined} value */
  setCheckin(value?: EditableCheckin) {
    jspb.Message.setWrapperField(this, 2, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearCheckin() {
    this.setCheckin(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasCheckin(): boolean {
    return jspb.Message.getField(this, 2) != null;
  }


}
export enum CheckinType {
  CHECKIN = 0,
  CHECKOUT = 1,
}
