import { Injectable, isDevMode } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

import { firebase } from '@app/src/firebase';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { MingaMinimalModel } from 'libs/domain';
import { MingaRoleType } from 'libs/domain';
import { gateway } from 'libs/generated-grpc-web';
import { badgeRoleNameToMingaRoleType } from 'libs/shared';
import { mingaSettingTypes } from 'libs/util';
import { distinctUntilChanged } from 'rxjs/operators';

import { AuthInfoService } from '@app/src/app/minimal/services/AuthInfo';
import { MingaStoreFacadeService } from '@app/src/app/store/Minga/services';
import { isAnalyticsEnabled } from '@app/src/app/util/analytics';

import { AppConfigService } from '../AppConfig';

// @HACK: should already have been declared in @types/google.analytics
// gets added by firebase analytics.
declare let gtag: Function;

// === We need to be mindful with what user properties we add, we can only ever have 25 ===
// === reference: https://firebase.google.com/docs/analytics/user-properties?platform=web ===
// === UserProperty names can be up to 24 characters long, may only contain alphanumeric characters and underscores ("_") ===
// === UserProperty values can be up to 36 characters long. The "firebase_", "google_" and "ga_" prefixes are reserved and should not be used ===
// === reference: https://developers.google.com/android/reference/com/google/firebase/analytics/FirebaseAnalytics.UserProperty#:~:text=UserProperty%20names%20can%20be%20up,up%20to%2036%20characters%20long
export interface AnalyticsUserProperties {
  role?: string;
  userHash?: string;
  minga?: string;
  mingaType?: string;
  // flag used for beta cohort (feature rollout)
  mingaIsBeta?: boolean;
  // flag used for customer council cohort (feature rollout)
  mingaIsCustomerCouncil?: boolean;
}

export interface UserJoinEventProperties {
  type: 'join_code' | 'pin';
  success: boolean;
}

export interface ContentCreatedEventProperties {
  type?: string;
  success?: boolean;
  error?: string;
}

export interface CommentCreatedEventProperties {
  success: boolean;
  error?: string;
}

export interface ContentBlockedEventProperties {
  type?: string;
}

export interface LongCardOpenedEventProperties {
  type?: string;
}

export interface SubscriptionCreatedEventProperties {
  mingaType: string;
  planId: string;
}

export interface HashtagClickedEventProperties {
  type: 'person' | 'group' | 'event';
}

@Injectable({ providedIn: 'root' })
export class AnalyticsService {
  private _isDevMode = isDevMode();

  gaScript?: HTMLScriptElement;
  userHash = '';

  constructor(
    private _serverAnalytics: gateway.analytics_ng_grpc_pb.AnalyticsClient,
    private _authInfoService: AuthInfoService,
    private _mingaStore: MingaStoreFacadeService,
    private _gtmService: GoogleTagManagerService,
    private _appConfig: AppConfigService,
  ) {
    // update role in analytics when it gets set.
    this._authInfoService.authPerson$
      .pipe(distinctUntilChanged())
      .subscribe(authPerson => {
        if (authPerson) {
          const roleType = badgeRoleNameToMingaRoleType(
            authPerson?.badgeRoleName,
          );
          const userProperties: any = {
            userHash: authPerson.hash,
          };

          // we dont want to override the users current role when they are in kiosk mode
          if (roleType !== MingaRoleType.KIOSK) {
            userProperties.role = authPerson.badgeRoleName;
          }

          this.setUserProperties(userProperties);
          this.setUserId(authPerson.hash);
        }
      });
  }
  public trackRouter(router: Router) {
    // from:
    // https://stackoverflow.com/questions/45758852/angular-4-using-google-analytics
    router.events.subscribe(event => {
      try {
        if (event instanceof NavigationEnd) {
          // use a timeout to only send the info after the page title has been
          // set by other components.
          setTimeout(
            () => this.setPage(event.urlAfterRedirects, event.url),
            1000,
          );
        }
      } catch (err) {
        console.error(err);
        // this.errorReporter.sendErrorReport(
        //   "client Analytics.service contructor()",
        //   'router.events.subscribe error: ' + e.message,
        //   errorPriority.LOGONLY
        // );
      }
    });
  }

  /**
   * Send a virtual pageview to the website tracker to trigger the completion
   * of a minga created goal in analytics.
   *
   * @param event
   */
  public sendMingaCreatedEvent() {}

  public sendUserJoinEvent(info: UserJoinEventProperties) {
    this.logEvent('user_join', info);
  }

  public sendContentCreatedEvent(info: ContentCreatedEventProperties) {
    this.logEvent('content_created', info);
    this.sendUserEngaged();
  }

  public sendCommentCreatedEvent(info: CommentCreatedEventProperties) {
    this.logEvent('comment_created', info);
    this.sendUserEngaged();
  }

  public sendContentBlockedEvent(info: ContentBlockedEventProperties) {
    this.logEvent('content_blocked', info);
  }

  public sendOverrideBlockEvent() {
    this.logEvent('content_block_overridden', {});
  }

  public sendSubscriptionCreatedEvent(
    info: SubscriptionCreatedEventProperties,
  ) {
    this.logEvent('subscription_created', info);
  }

  public sendLongCardOpenedEvent(info: LongCardOpenedEventProperties) {
    this.logEvent('longcard_opened', info);
  }

  public sendPollChoiceSelectedEvent() {
    this.sendUserEngaged();
    this.logEvent('pollchoice_selected', {});
  }

  public sendAppRatingViewed() {
    this.logEvent('rated_app', {});
  }

  /**
   * This is for a category of actions we are using to measure
   * 'engagement'.
   */
  public sendUserEngaged() {
    this.logEvent('user_engaged', {});
  }

  public sendLikeTriggered() {
    this.logEvent('likeTriggered', {});
    this.sendUserEngaged();
  }

  public sendEventGoingTriggered(checkIn: boolean = false) {
    this.logEvent('eventGoingTriggered', { checkIn });
    this.sendUserEngaged();
  }

  public sendNotificationOpened(type: number) {
    this.logEvent('notificationOpened', { type });
  }

  public sendProfileUpdated() {
    this.logEvent('profileUpdated', {});
    this.sendUserEngaged();
  }

  public sendDirectMessageSent() {
    this.logEvent('directMessageSent', {});
  }

  public sendGalleryHashTaggedClicked(info: HashtagClickedEventProperties) {
    this.logEvent('galleryHashtagClicked', { type: info.type });
  }

  public sendViewGalleryPost() {
    this.logEvent('viewGalleryPost', {});
  }

  public sendViewEventGallery() {
    this.logEvent('viewEventGallery', {});
  }

  public sendViewGroupGallery() {
    this.logEvent('viewGroupGallery', {});
  }

  public sendSuggestedLinkClickedEvent(url: string) {
    this.logEvent('suggestedContentClicked', { url });
  }

  public async sendLoginToWebsiteTracker() {
    const enabled = await isAnalyticsEnabled();
    if (enabled) {
      firebase.analytics().setAnalyticsCollectionEnabled(true);
      const analyticsId = this._appConfig.getValue('websiteAnalyticsId');
      gtag('event', 'app_login', {
        send_to: analyticsId,
      });
    }
  }

  public async sendManagerReport(params: {
    managerType:
      | 'pbis'
      | 'checkin'
      | 'flexTime'
      | 'hallpass'
      | 'minga'
      | 'points';
    reportType?: any;
  }) {
    this.logEvent('runManagerReport', params);
  }

  public setPage(pageName: string, url: string) {
    if (!window.MINGA_APP_BROWSER) {
      window.FirebasePlugin.setScreenName(pageName);
    } else {
      firebase.analytics().setCurrentScreen(pageName);
      // only do this on web, because on hybrid firebase automatically does it.
      this.logEvent('screen_view', { screen_name: pageName });
    }

    // google tag manager
    if (!this._isDevMode) {
      const gtmTag = {
        event: 'page',
        pageName: url,
      };
      this._gtmService.pushTag(gtmTag);
    }
  }

  // Set userId for the current (default) GA tracker
  public setUserId(userId: string) {
    this.userHash = userId;
    if (!window.MINGA_APP_BROWSER && window.FirebasePlugin) {
      window.FirebasePlugin.setUserId(userId);
    } else {
      firebase.analytics().setUserId(userId);
    }
  }

  public setUserProperties(properties: AnalyticsUserProperties) {
    if (!properties) return;

    if (!window.MINGA_APP_BROWSER && window.FirebasePlugin) {
      //@NOTE: FirebasePlugin does not have setUserProperties, so adding
      // properties individually
      // (https://github.com/dpa99c/cordova-plugin-firebasex#readme)
      for (const key in properties) {
        if (properties.hasOwnProperty(key)) {
          window.FirebasePlugin.setUserProperty(key, properties[key]);
        }
      }
    } else {
      firebase.analytics().setUserProperties(properties);
    }
  }

  public sendException(
    description: string,
    priority: number = gateway.connect_pb.errorPriority.WARN,
  ) {
    this.logEvent('exception', {
      description,
      fatal: priority === gateway.connect_pb.errorPriority.ALERT,
    });
  }

  public logEvent(name: string, parameters: any) {
    if (!window.MINGA_APP_BROWSER && window.FirebasePlugin) {
      window.FirebasePlugin.logEvent(name, parameters);
    } else {
      firebase.analytics().logEvent(name, parameters);
    }
  }

  public emitEvent(
    eventCategory: string,
    eventAction: string,
    eventLabel: string | null = null,
    eventValue: number | null = null,
  ) {
    this.logEvent(eventCategory, {
      eventLabel,
      eventAction,
    });
  }

  async getClientId(): Promise<string> {
    return this.userHash;
  }

  _initServerGA(clientId: any = false) {
    const init = async (clientId: any) => {
      const req: gateway.analytics_pb.AnalyticsClientRequest =
        new gateway.analytics_pb.AnalyticsClientRequest();
      const res: gateway.analytics_pb.AnalyticsClientResponse =
        new gateway.analytics_pb.AnalyticsClientResponse();

      req.setClientId(clientId);
      await this._serverAnalytics.setAnalyticsClient(req);
    };
    if (!clientId) {
      this.getClientId().then(client => {
        init(client);
      });
    } else {
      init(clientId);
    }
  }

  async init(router: Router) {
    this.trackRouter(router);
  }

  async sendAnalyticsEvent(
    eventCategory: string,
    eventAction: string,
    eventLabel: string = '',
    eventValue: number = 0,
  ) {
    const req: gateway.analytics_pb.EmitEventRequest =
      new gateway.analytics_pb.EmitEventRequest();
    const res: gateway.analytics_pb.EmitEventResponse =
      new gateway.analytics_pb.EmitEventResponse();

    req.setEventCategory(eventCategory);
    req.setEventAction(eventAction);
    req.setEventLabel(eventLabel);
    req.setEventValue(eventValue);

    return await this._serverAnalytics.serverEmitEvent(req);
  }

  public setMingaUserProperties(mingaMinimal: MingaMinimalModel) {
    const mingaIsBeta = mingaMinimal.settings.find(
      s => s.name === mingaSettingTypes.FEATURE_ROLLOUT_ENABLE_BETA,
    );
    const mingaIsCustomerCouncil = mingaMinimal.settings.find(
      s => s.name === mingaSettingTypes.FEATURE_ROLLOUT_ENABLE_CUSTOMER_COUNCIL,
    );

    const properties = {
      mingaType: mingaMinimal.mingaType,
      minga: mingaMinimal.hash,
      mingaIsBeta: mingaIsBeta?.value ?? false,
      mingaIsCustomerCouncil: mingaIsCustomerCouncil?.value ?? false,
      mingaHubspotId: mingaMinimal.hubspotId || '',
      mingaDistrictId: mingaMinimal.districtId || '',
    };
    this.setUserProperties(properties);
  }
}
