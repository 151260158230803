import * as $ngrx_entity_entity from '@ngrx/entity';
//TS2742
import * as $ngrx_entity_src_models from '@ngrx/entity/src/models';
import * as $ngrx_store_store from '@ngrx/store';
import { createEntityAdapter, EntityState } from '@ngrx/entity';
//TS2742
import { createSelector } from '@ngrx/store';
//TS2742
import {
  IConversation,
  IConversationWithReadStatus,
  IMessage,
} from 'libs/domain';

export const messagingStoreKeyname = 'messaging';

export interface MessageState extends IMessage {
  /**
   * The send state of a message to indiciate if a message is currently waiting
   * for confirmation or failed to send. If `sendState` is unset the message is
   * confirmed.
   */
  sendState?: 'pending' | 'failed_to_send';
}

export interface ConversationState extends IConversationWithReadStatus {
  /** Flag to indicate conversation info is currently being fetched */
  fetching: boolean;
  /** Messages belonging to this conversation */
  messages: EntityState<MessageState> & {
    /** Flag to indicate messages are currently being fetched from server */
    fetching: boolean;
  };
}

export interface MessagingState {
  conversations: EntityState<ConversationState> & {
    /** Flag to indiciate conversations are currently being fetched */
    fetching: boolean;

    /**
     * Flag to indiciate that this conversations list is incomplete. This would
     * usually happen when a single conversation was set but not the whole list.
     */
    partial: boolean;
  };
}

export namespace MessagingState {
  export const conversationEntities = createEntityAdapter<ConversationState>({
    sortComparer: (a, b) => {
      const bDate = b.lastMessageDate;
      const aDate = a.lastMessageDate;
      if (aDate && bDate && aDate > bDate) return -1;
      else if (aDate && bDate && aDate < bDate) return 1;
      else if (aDate && !bDate) return -1;
      else if (bDate && !aDate) return 1;
      else return 0;
    },
  });
  export const conversationSelectors = conversationEntities.getSelectors();

  export const messageEntities = createEntityAdapter<MessageState>({
    sortComparer: (a, b) => {
      if (a.createdDate > b.createdDate) return -1;
      else if (a.createdDate < b.createdDate) return 1;
      else return 0;
    },
  });
  export const messageSelectors = messageEntities.getSelectors();
  export const messagesInitialState = messageEntities.getInitialState({
    fetching: false,
  });

  export const initialState: MessagingState = {
    conversations: conversationEntities.getInitialState({
      fetching: false,
      partial: false,
    }),
  };

  export const selectConversationsState = (state: MessagingState) =>
    state.conversations;

  export const selectConversations = createSelector(
    (state: MessagingState) => state.conversations,
    conversationSelectors.selectEntities,
  );

  export const selectConversationsList = createSelector(
    (state: MessagingState) => state.conversations,
    conversationSelectors.selectAll,
  );

  export const selectConversation = (conversationId: number) =>
    createSelector(
      selectConversations,
      conversations => conversations[conversationId],
    );

  /**
   * Ensure messaging state has conversation state with id `conversationId`.
   * If conversation state doesn't exist it will be created.
   */
  export const ensureConversation = (
    conversationId: number,
    state: MessagingState,
  ) => {
    let conversations = selectConversationsState(state);
    let conv = MessagingState.selectConversation(conversationId)(state);
    if (!conv) {
      conv = {
        fetching: false,
        id: conversationId,
        lastMessageDate: null,
        lastMessageBody: '',
        participants: [],
        readStatus: false,
        messages: messageEntities.getInitialState({
          fetching: false,
        }),
      };
      conversations = conversationEntities.addOne(conv, conversations);
    }
    return conversations;
  };

  export const selectMessagesFetching = (conversationId: number) =>
    createSelector(selectConversation(conversationId), conversation =>
      conversation ? conversation.messages.fetching : false,
    );

  export const selectMessages = (conversationId: number) =>
    createSelector(selectConversation(conversationId), conversation =>
      conversation
        ? messageSelectors.selectAll(conversation.messages)
        : undefined,
    );
}
