import { RosteringMethod } from 'libs/domain';

import { FormSelectOption } from '@shared/components/form';

export enum PeopleRosteringMessages {
  HEADER_TITLE = 'Rostering',
  PAGE_SUBTITLE = 'Settings',
  METHOD_SELECT_TITLE = 'Rostering Method',
  STATE_SELECT_MSG = 'Enable Automatic Rostering',
  ROSTERING_DISABLE_MSG = 'Are you sure you want to disable the current rostering method?',
  ONE_ROSTER_CHANGE_TITLE = 'OneRoster History',
  SFTP_CHANGE_TITLE = 'SFTP History',
  NON_ACCOUNT_MANAGE_MSG = 'You must be an owner in order to manage rostering',
  ERROR_MSG = 'An error has occured during your SIS roster sync and requires an action',
  VIEW_ERROR = 'View error',
}

export const PEOPLE_ROSTERING_OPTIONS: FormSelectOption<RosteringMethod>[] = [
  { label: 'OneRoster API', value: RosteringMethod.ONE_ROSTER },
  { label: 'SFTP', value: RosteringMethod.SFTP },
  //{ label: 'SIS API', value: RosteringMethod.SIS_API },
];
