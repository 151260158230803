// GENERATED CODE -- DO NOT EDIT!

import {grpc} from '@improbable-eng/grpc-web';

import * as minga_stats_internal_minga_stats_internal_pb from '../minga_stats_internal/minga_stats_internal_pb';
import * as common_date_pb from '../common/date_pb';

export class MingaStatsInternalService {
  static readonly serviceName = "minga.minga_stats_internal.MingaStatsInternalService";

  static readonly InternalGetMingaStats = {
    methodName: "InternalGetMingaStats",
    service: MingaStatsInternalService,
    requestStream: false,
    responseStream: false,
    requestType: minga_stats_internal_minga_stats_internal_pb.InternalGetMingaStatsRequest,
    responseType: minga_stats_internal_minga_stats_internal_pb.InternalGetMingaStatsResponse,
  };

  static readonly InternalGetMingaStatsAggregate = {
    methodName: "InternalGetMingaStatsAggregate",
    service: MingaStatsInternalService,
    requestStream: false,
    responseStream: false,
    requestType: minga_stats_internal_minga_stats_internal_pb.InternalGetMingaStatsAggregateRequest,
    responseType: minga_stats_internal_minga_stats_internal_pb.InternalGetMingaStatsAggregateResponse,
  };

  static readonly InternalGetMingaStatsAggregateCount = {
    methodName: "InternalGetMingaStatsAggregateCount",
    service: MingaStatsInternalService,
    requestStream: false,
    responseStream: false,
    requestType: minga_stats_internal_minga_stats_internal_pb.InternalGetMingaStatsAggregateCountRequest,
    responseType: minga_stats_internal_minga_stats_internal_pb.InternalGetMingaStatsAggregateCountResponse,
  };

}

export class MingaStatsInternalServiceClient {
  internalGetMingaStats() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  internalGetMingaStatsAggregate() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  internalGetMingaStatsAggregateCount() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
}
