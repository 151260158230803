import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SwipeStackModule } from '@app/src/app/components/SwipeStack';
import { MgIconModule } from '@app/src/app/icon';
import { MgSpinnerModule } from '@app/src/app/spinner';

import { StreamSwipeStackComponent } from './StreamSwipeStack.component';
import { StreamSwipeStackHeadDirective } from './StreamSwipeStackHead.directive';
import { StreamSwipeStackItemDirective } from './StreamSwipeStackItem.directive';
import { StreamSwipeStackLoadingDirective } from './StreamSwipeStackLoading.directive';
import { StreamSwipeStackTailDirective } from './StreamSwipeStackTail.directive';

@NgModule({
  imports: [
    // Minga dependencies
    MgSpinnerModule,
    SwipeStackModule,
    MgIconModule,

    // External dependencies
    CommonModule,
  ],
  declarations: [
    StreamSwipeStackComponent,
    StreamSwipeStackItemDirective,
    StreamSwipeStackHeadDirective,
    StreamSwipeStackTailDirective,
    StreamSwipeStackLoadingDirective,
  ],
  exports: [
    StreamSwipeStackComponent,
    StreamSwipeStackItemDirective,
    StreamSwipeStackHeadDirective,
    StreamSwipeStackTailDirective,
    StreamSwipeStackLoadingDirective,
  ],
  providers: [],
})
export class StreamSwipeStackModule {}
