import { day } from 'libs/day';
import { BmReportsFilters } from 'libs/domain';
import { wrappers_pb } from 'libs/generated-grpc-ts';
import { pbis_stats_pb } from 'libs/generated-grpc-ts';

import { dateObjectToDateTimeMessage, dateTimeMessageToDayjsObject } from '../';

export const toProto = (
  filters: BmReportsFilters,
  limit?: number,
  offset?: number,
): pbis_stats_pb.PbisReportFilters => {
  const filterProto = new pbis_stats_pb.PbisReportFilters();
  if (filters.startDate)
    filterProto.setStartDate(
      dateObjectToDateTimeMessage(filters.startDate.toDate()),
    );
  if (filters.endDate)
    filterProto.setEndDate(
      dateObjectToDateTimeMessage(filters.endDate.toDate()),
    );
  filterProto.setGradeList(filters.grades);
  if (filters.types) filterProto.setBehaviorTypeList(filters.types);
  if (filters.consTypes) filterProto.setConsequenceTypeList(filters.consTypes);
  if (filters.issuedBy) filterProto.setIssuedByList(filters.issuedBy);
  if (filters.issuedTo) filterProto.setIssuedToList(filters.issuedTo);
  if (filters.consCatTypes)
    filterProto.setConsequenceCatTypeList(filters.consCatTypes);
  if (filters.consStatus)
    filterProto.setConsequenceStatusList(filters.consStatus);
  if (filters.automationTypes)
    filterProto.setAutomationTypeList(filters.automationTypes);
  if (filters.consID) filterProto.setConsequenceId(filters.consID);
  if (filters.autoGroup) filterProto.setAutomationGroupId(filters.autoGroup);
  if (typeof filters.consequenceCategory === 'number') {
    const wrapper = new wrappers_pb.Int32Value();
    wrapper.setValue(filters.consequenceCategory);
    filterProto.setConsequenceCategory(wrapper);
  }
  if (offset) filterProto.setOffset(offset);
  if (limit) filterProto.setLimit(limit);
  filterProto.setUserListList(filters.userList);

  return filterProto;
};

export const fromProto = (
  proto: pbis_stats_pb.PbisReportFilters,
): BmReportsFilters & { offset?: number; limit?: number } => {
  const startDateProto = proto.getStartDate();
  const endDateProto = proto.getEndDate();
  let startDate: day.Dayjs | undefined;
  let endDate: day.Dayjs | undefined;
  if (startDateProto) startDate = dateTimeMessageToDayjsObject(startDateProto);
  if (endDateProto) endDate = dateTimeMessageToDayjsObject(endDateProto);
  return {
    startDate,
    endDate,
    grades: proto.getGradeList(),
    types: proto.getBehaviorTypeList(),
    consTypes: proto.getConsequenceTypeList(),
    issuedBy: proto.getIssuedByList(),
    issuedTo: proto.getIssuedToList(),
    consCatTypes: proto.getConsequenceCatTypeList(),
    consStatus: proto.getConsequenceStatusList(),
    offset: proto.getOffset(),
    limit: proto.getLimit(),
    automationTypes: proto.getAutomationTypeList(),
    consID: proto.getConsequenceId(),
    autoGroup: proto.getAutomationGroupId(),
    consequenceCategory:
      typeof proto.getConsequenceCategory() === 'number'
        ? proto.getConsequenceCategory().getValue()
        : undefined,
    userList: proto.getUserListList(),
  };
};
