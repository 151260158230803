import { Injectable } from '@angular/core';

import * as Sentry from '@sentry/angular';
import * as SemVer from 'semver';
import { environment } from '@app/src/environment';
import { distinctUntilChanged } from 'rxjs/operators';

import { AuthInfoService } from '@app/src/app/minimal/services/AuthInfo';
import { MingaStoreFacadeService } from '@app/src/app/store/Minga/services';

@Injectable({ providedIn: 'root' })
export class SentryService {
  constructor(
    private authInfoService: AuthInfoService,
    private mingaStore: MingaStoreFacadeService,
  ) {}

  public startup() {
    const stage = this.getStage();
    const version = this.getVersion();
    Sentry.configureScope(scope =>
      scope.addEventProcessor(event => ({
        ...event,
        environment: stage,
        release: version,
      })),
    );

    // update person in sentry when user is set.
    this.authInfoService.authPerson$
      .pipe(distinctUntilChanged())
      .subscribe(authPerson => {
        if (authPerson) {
          this.setUser(authPerson.hash);
        }
      });
    this.mingaStore
      .getMingaAsObservable()
      .pipe(distinctUntilChanged())
      .subscribe(minga => {
        if (minga) {
          this.setMinga(minga.hash);
        }
      });
  }

  private getStage(): string {
    const appVersion = this.getVersion();
    if (!SemVer.valid(appVersion)) {
      return 'dev';
    }
    // we know this should be being run on stage if it's a release candidate
    if (SemVer.prerelease(appVersion)) {
      return 'stage';
    } else {
      return 'production';
    }
  }

  private getVersion(): string {
    const configVersion = environment.version;
    // if the config has a version, use that
    if (configVersion) {
      return configVersion;
    } else {
      return '';
    }
  }

  public setUser(personHash: string) {
    Sentry.setUser({ id: personHash });
  }

  public clearUser() {
    Sentry.setUser(null);
  }

  public captureMessageAsError(msg: string, context?: any) {
    const err = new Error(msg);
    Sentry.captureException(err, { contexts: context });
  }

  public setMinga(hash: string) {
    //@TODO
  }
}
