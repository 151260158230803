import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';

import { TranslateModule } from '@ngx-translate/core';

import { MgTextareaModule } from '@app/src/app/components/Textarea';
import { MgMentionModule } from '@app/src/app/elements/MgMention';
import { MgFormFieldModule } from '@app/src/app/form-field';
import { MgIconModule } from '@app/src/app/icon';
import { MentionsModule } from '@app/src/app/mentions';

import { MgQuillEditorModule } from '../QuillEditor';
import { BodyInputComponent } from './BodyInput.component';

@NgModule({
  imports: [
    // Minga dependencies
    MgFormFieldModule,
    MgQuillEditorModule,
    MgTextareaModule,
    MgIconModule,
    MentionsModule,
    MgMentionModule,

    // External dependencies
    CommonModule,
    FormsModule,
    MatFormFieldModule,
    TranslateModule,
    FlexLayoutModule,
  ],
  declarations: [BodyInputComponent],
  exports: [BodyInputComponent],
})
export class BodyInputModule {}
