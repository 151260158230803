import {
  ExistingFlexTimePeriodRegistration,
  FlexCheckInStatuses,
  FlexRegistrationInfo,
  FlexRegistrationType,
} from 'libs/domain';
import {
  flex_time_pb,
  flex_time_stats_pb as flex_stats_pb,
} from 'libs/generated-grpc-ts';
import { $enum } from 'ts-enum-util';

import { dateTimeMessageToDayjsObject, dayJsObjectToDateTimeMsg } from '../';
import { IReportPersonMapper } from '../person';

export const toProto = (
  info: FlexRegistrationInfo,
): flex_stats_pb.FlexRegistrationInfo => {
  const proto = new flex_stats_pb.FlexRegistrationInfo();
  proto.setId(info.id);
  proto.setActivityId(info.activityId);
  proto.setActivityInstanceId(info.activityInstanceId);
  proto.setActivityName(info.activityName);
  if (info.checkinDate) {
    proto.setCheckInDate(dayJsObjectToDateTimeMsg(info.checkinDate));
  }
  if (info.registeredDate) {
    proto.setRegisteredDate(dayJsObjectToDateTimeMsg(info.registeredDate));
  }
  if (info.student) {
    proto.setStudent(IReportPersonMapper.toProto(info.student));
  }
  if (info.teacher) {
    proto.setTeacherId(info.teacher.id);
    proto.setTeacherName(info.teacher.firstName);
    proto.setTeacherLastName(info.teacher.lastName);
  }
  if (info.checkinStatus) proto.setCheckinStatus(info.checkinStatus);
  if (info.period) proto.setPeriodName(info.period);
  if (info.periodDate) proto.setPeriodDate(info.periodDate);
  if (info.periodStartTime) proto.setPeriodStartTime(info.periodStartTime);
  if (info.periodEndTime) proto.setPeriodEndTime(info.periodEndTime);
  if (info.type) proto.setType(info.type);
  proto.setRegistererFirstName(info.registerer.firstName);
  proto.setRegistererLastName(info.registerer.lastName);
  if (info.activityLocation) proto.setActivityLocation(info.activityLocation);
  return proto;
};

export const fromProto = (
  proto: flex_stats_pb.FlexRegistrationInfo,
): FlexRegistrationInfo => {
  const checkinDate = proto.getCheckInDate()
    ? dateTimeMessageToDayjsObject(proto.getCheckInDate())
    : undefined;
  const registeredDate = proto.getRegisteredDate()
    ? dateTimeMessageToDayjsObject(proto.getRegisteredDate())
    : undefined;
  return {
    id: proto.getId(),
    activityId: proto.getActivityId(),
    activityInstanceId: proto.getActivityInstanceId(),
    activityName: proto.getActivityName(),
    student: IReportPersonMapper.fromProto(proto.getStudent()),
    checkinDate,
    registeredDate,
    checkinStatus: $enum(FlexCheckInStatuses).asValueOrThrow(
      proto.getCheckinStatus(),
    ),
    teacher: {
      id: proto.getTeacherId(),
      firstName: proto.getTeacherName(),
      lastName: proto.getTeacherLastName(),
    },
    period: proto.getPeriodName(),
    periodDate: proto.getPeriodDate(),
    periodStartTime: proto.getPeriodStartTime(),
    periodEndTime: proto.getPeriodEndTime(),
    type: $enum(FlexRegistrationType).asValueOrThrow(proto.getType()),
    registerer: {
      firstName: proto.getRegistererFirstName(),
      lastName: proto.getRegistererLastName(),
    },
    activityLocation: proto.getActivityLocation(),
  };
};

export const toExistingRegistrationProto = (
  existing: ExistingFlexTimePeriodRegistration,
): flex_time_pb.ExistingRegistration => {
  const message = new flex_time_pb.ExistingRegistration();
  if (existing.activityName) message.setActivityName(existing.activityName);
  if (existing.activityInstanceId) {
    message.setActivityInstanceId(existing.activityInstanceId);
  }

  if (existing.person) {
    const person = new flex_time_pb.Person();
    person.setHash(existing.person.hash);
    person.setName(existing.person.name);
    message.setPerson(person);
  }

  if (existing.cannotUnregister) {
    message.setCannotUnregister(existing.cannotUnregister);
  }

  if (existing.registeredBy) {
    const person = new flex_time_pb.Person();
    person.setHash(existing.registeredBy.hash);
    person.setName(existing.registeredBy.name);
    message.setRegisteredBy(person);
  }

  if (existing.activityLocation) {
    message.setActivityLocation(existing.activityLocation);
  }

  if (existing.activityTeacherName) {
    message.setActivityTeacherName(existing.activityTeacherName);
  }

  return message;
};

export const fromExistingRegistrationProto = (
  message: flex_time_pb.ExistingRegistration,
): ExistingFlexTimePeriodRegistration => {
  let person = {
    hash: '',
    name: '',
  };

  if (message.getPerson()) {
    person = message.getPerson().toObject();
  }

  return {
    activityName: message.getActivityName() ?? undefined,
    activityInstanceId: message.getActivityInstanceId() ?? undefined,
    activityTeacherName: message.getActivityTeacherName() ?? undefined,
    person,
    activityLocation: message.getActivityLocation() ?? undefined,
    cannotUnregister: message.getCannotUnregister(),
    registeredBy: message.getRegisteredBy().toObject() ?? {
      hash: '',
      name: '',
    },
  };
};
