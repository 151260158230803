import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatTable, MatTableDataSource } from '@angular/material/table';

import { PastReport, PastReportStatus } from 'libs/domain';
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { PaginatorComponent } from '@shared/components/paginator';
import { MediaService } from '@shared/services/media';
import { ScheduledReportsHistoryService } from '@shared/services/scheduled-reports/scheduled-reports-history.service';

import {
  HistoryTableMessages,
  ScheduledHistoryTableColumn,
} from '../../constants';
import { ScheduledReportsHistoryStore } from '../../store/scheduled-reports-history.store';

@Component({
  selector: 'mg-mm-sr-history-table',
  templateUrl: './mm-sr-history-table.component.html',
  styleUrls: ['./mm-sr-history-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MmSrHistoryTableComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  @ViewChild(MatTable) table: MatTable<any>;
  @ViewChild(PaginatorComponent)
  paginator: PaginatorComponent;
  private _destroyed$ = new ReplaySubject<void>(1);

  public isLoading$ = this._historyStore.isLoading$;
  public readonly dataSource = new MatTableDataSource<PastReport>([]);
  private _downloadsInFlight = new BehaviorSubject<Set<number>>(new Set());
  public downloadsInFlight$ = this._downloadsInFlight.asObservable();

  // even some errors we might be fine to still download a report
  public readonly DOWNLOADABLE_STATUSES = [
    PastReportStatus.Success,
    PastReportStatus.EmailError, // report was still generated
    PastReportStatus.SftpError, // report was still generated
  ];

  public displayColumns: string[] = [
    ScheduledHistoryTableColumn.STATUS,
    ScheduledHistoryTableColumn.NAME,
    ScheduledHistoryTableColumn.DATE,
    ScheduledHistoryTableColumn.TIME,
    ScheduledHistoryTableColumn.DOWNLOAD,
  ];
  public readonly COLUMN_DEF = ScheduledHistoryTableColumn;
  public readonly MESSAGES = HistoryTableMessages;
  public readonly PAST_REPORT_STATUS = PastReportStatus;
  constructor(
    public media: MediaService,
    private _historyStore: ScheduledReportsHistoryStore,
    private _cdr: ChangeDetectorRef,
    private _scheduledReportsHistoryService: ScheduledReportsHistoryService,
  ) {}

  ngOnInit(): void {
    this._historyStore.getHistoryReports();

    this._historyStore.pastReports$
      .pipe(takeUntil(this._destroyed$))
      .subscribe(pastReports => {
        this.dataSource.data = pastReports;
        this._cdr.detectChanges();
      });
  }

  ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator.matPaginator;
  }

  public trackById(index: number, data: PastReport) {
    if (!data) return index;
    return data.id;
  }

  public async download(id: number) {
    const inFlight = this._downloadsInFlight.value;
    inFlight.add(id);
    try {
      await this._scheduledReportsHistoryService.download(id);
    } finally {
      inFlight.delete(id);
    }

    this._downloadsInFlight.next(inFlight);
  }
}
