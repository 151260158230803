import {
  Component,
  ContentChild,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  AsyncValidatorFn,
  ControlValueAccessor,
  UntypedFormControl,
  UntypedFormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
  ValidatorFn,
  Validators,
} from '@angular/forms';

import '@app/src/globals';
import { gateway } from 'libs/generated-grpc-web';
import { timer } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { MgValidators } from '@app/src/app/input/validators';
import { AppConfigService } from '@app/src/app/minimal/services/AppConfig';
import { InstantErrorStateMatcher } from '@app/src/app/util/form';
import { uniqueEmailRace } from '@app/src/app/util/grpc-multi-invoke';

import { SystemAlertSnackBarService } from '@shared/components/system-alert-snackbar';

export const MG_EMAIL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => MgEmailComponent),
  multi: true,
};

const MG_EMAIL_VALIDATOR: any = {
  provide: NG_VALIDATORS,
  useExisting: forwardRef(() => MgEmailComponent),
  multi: true,
};

/**
 * Slot component for replacing `<mg-email>`'s `<mat-hint>`
 */
@Component({
  selector: 'mg-email-hint',
  template: '<ng-content></ng-content>',
})
export class MgEmailSlot_Hint {}

@Component({
  selector: 'mg-email',
  providers: [MG_EMAIL_VALUE_ACCESSOR, MG_EMAIL_VALIDATOR],
  templateUrl: './Email.component.html',
  styleUrls: ['./Email.component.scss'],
})
export class MgEmailComponent
  implements ControlValueAccessor, Validator, OnChanges, OnInit
{
  _formGroup: UntypedFormGroup = new UntypedFormGroup({});
  innerValue: string = '';
  enableReadOnlyState: boolean =
    !window.MINGA_DEVICE_ANDROID && !window.MINGA_DEVICE_IOS;

  @ContentChild(MgEmailSlot_Hint, { static: false })
  hintSlot?: MgEmailSlot_Hint;

  // shows/hides asterisk on input as validation required is always on for
  // mg-email
  @Input()
  required: boolean = false;

  @Input()
  unique: boolean | string = false;

  @Input()
  nohint: boolean = false;

  @Input()
  noerror: boolean = false;

  @Input()
  novalidate: boolean = false;

  @Input()
  instantErrors: boolean = false;

  @Input()
  float: boolean = true;

  @Input()
  mgNoHintMargin: boolean = false;

  @Input()
  editMode: boolean = false;

  @Input('validatePatternOnly')
  validatePatternOnly: boolean = false;

  @Input('name')
  inputName: string = 'emailComponent';

  @Input('editing')
  set outsideEditing(value: boolean) {
    this.editing = value;
  }

  @Input('readOnly')
  readonly: boolean = false;

  get outsideEditing() {
    return this.editing;
  }

  @Output('save')
  onSaveCallback: EventEmitter<any> = new EventEmitter();
  @Output('focus')
  onFocusCallback: EventEmitter<any> = new EventEmitter();

  inputControl: UntypedFormControl = new UntypedFormControl();

  @ViewChild('inputElement', { static: true })
  inputElement: ElementRef;

  onChange;
  onTouched;
  translateParams = { value: 'email address' };

  @Input()
  outerFormControl?: UntypedFormControl;
  // outerControl: FormControl;
  outerErrors: ValidationErrors | null = null;

  editing: boolean = false;

  matcher: InstantErrorStateMatcher | null = null;
  private _uniqueValidator: AsyncValidatorFn;
  private _validators: ValidatorFn[] = [];

  constructor(
    private _systemAlertSnackBar: SystemAlertSnackBarService,
    private appConfig: AppConfigService,
  ) {
    this.outerFormControl = new UntypedFormControl();
    this._setupUniqueValidator();
  }

  get floatingValue() {
    return this.float ? 'auto' : 'never';
  }

  get saveDisabled() {
    const invalidBool = this.statusInvalid || this.inputControlErrorKey;
    return typeof this.unique == 'string'
      ? this.unique == this.innerValue || invalidBool
      : invalidBool;
  }

  get hasOuterFormControlError() {
    if (!this.outerFormControl) return false;

    if (!!this.outerFormControl.errors && !!this.inputControl.errors == false) {
      return false;
    } else if (!this.outerFormControl.errors) {
      return true;
    }
  }

  onKeyDown(e: KeyboardEvent) {
    if (this.editMode && e.key === 'Enter') {
      e.preventDefault();
      e.stopImmediatePropagation();
      e.stopPropagation();

      this.onSave();
    }
  }

  initInstantErrors() {
    if (this.instantErrors) {
      this.matcher = new InstantErrorStateMatcher();
    } else {
      this.matcher = null;
    }
  }

  // @TODO: Remove the unique validator within here and just use
  // `./MgUniqueEmail.directive.ts`
  private _setupUniqueValidator() {
    this._uniqueValidator = (c: AbstractControl) => {
      const value = c.value;

      if (typeof this.unique == 'string') {
        if (this.unique == value) {
          return Promise.resolve(null);
        }
      }

      const responsHandler = (res: gateway.people_pb.EmailUniqueResponse) => {
        let isUnique = res.getUnique();
        if (!isUnique) {
          return {
            emailTaken: true,
          };
        } else {
          return null;
        }
      };

      return timer(1000).pipe(
        switchMap(() => {
          return this.appConfig
            .getAllApiUrls()
            .then(apiUrls => uniqueEmailRace(value, apiUrls))
            .then(({ response }) => responsHandler(response));
        }),
      );
    };
  }

  wantsUnique() {
    return this.unique || typeof this.unique == 'string';
  }

  // Pass through our internal
  validate(control: AbstractControl): ValidationErrors | null {
    return this.inputControl.errors;
  }

  private _getAsyncValidators(unique = this.wantsUnique()): AsyncValidatorFn[] {
    let validators: AsyncValidatorFn[] = [];

    if (unique) {
      validators.push(this._uniqueValidator);
    }

    return validators;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.unique && this._formGroup) {
      let { currentValue } = changes.unique.currentValue;
      this._initValidators();
    }

    if (changes.instantErrors) {
      this.initInstantErrors();
    }
  }

  private _initValidators() {
    let validators = this._getAsyncValidators();

    this.inputControl.setAsyncValidators(validators);
  }

  ngOnInit() {
    this.inputControl = new UntypedFormControl();

    if (this.validatePatternOnly) {
      this._validators.push(MgValidators.Email);
    } else if (!this.novalidate) {
      this._validators.push(Validators.maxLength(50));
      this._validators.push(MgValidators.Email);

      // as you have to be able to validate to check required
      if (this.required) {
        this._validators.push(Validators.required);
      }
    }

    if (this._validators.length) {
      this.inputControl.setValidators(this._validators);
    }

    const asyncValidators = this._getAsyncValidators();
    if (asyncValidators.length) {
      this.inputControl.setAsyncValidators(asyncValidators);
    }

    this._formGroup = new UntypedFormGroup({ inputControl: this.inputControl });

    this._initOuterControl();
    this.inputControl.setValue(this.innerValue);

    this.initInstantErrors();

    // @HACK: shouldn't have to do this...
    setTimeout(() => {
      this.inputControl.statusChanges.subscribe((status: string) => {
        // Only update the outer control when or value is valid. This is
        // important because we use the outter validator to actually push the
        // changes.
        if (status == 'VALID') {
          this.onChange(this.innerValue);
          this.onTouched();
        }
      });
    });
  }

  private _initOuterControl() {
    if (this.outerFormControl) {
      // Check if the the outer formcontrol has errors and save them if we do
      this.outerFormControl.statusChanges.subscribe(val => {
        if (this.outerFormControl && this.hasOuterFormControlError) {
          this.outerErrors = this.outerFormControl.errors;
        } else if (!this.hasOuterFormControlError && this.outerErrors) {
          // clear the errors if this status update has none
          this.outerErrors = null;
        }
      });
    }
  }

  get inputControlErrorKey() {
    let innerErrors = this.inputControl.errors
      ? Object.keys(this.inputControl.errors)[0]
      : '';

    let outerErrors =
      this.outerFormControl && this.outerFormControl.errors
        ? Object.keys(this.outerFormControl.errors)[0]
        : '';

    return innerErrors ? innerErrors : outerErrors;
  }

  get statusDone() {
    return (
      this.inputControl.dirty &&
      this.inputControl.valid &&
      (!this.outerFormControl || this.outerFormControl.valid)
    );
  }

  get statusInvalid() {
    return (
      (this.inputControl.dirty && this.inputControl.invalid) ||
      !this.outerFormControl ||
      (this.outerFormControl.dirty && this.outerFormControl.invalid)
    );
  }

  get statusTouched() {
    return (
      (this.inputControl.invalid &&
        (this.inputControl.dirty || this.inputControl.touched)) ||
      (this.outerFormControl &&
        this.outerFormControl.invalid &&
        (this.outerFormControl.dirty || this.outerFormControl.touched))
    );
  }

  get statusPending() {
    return (
      this.inputControl.pending ||
      (this.outerFormControl && this.outerFormControl.pending)
    );
  }

  writeValue(value: string): void {
    if (typeof value == 'string') {
      this.innerValue = value;
      this.inputControl.setValue(value);
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  change(value: string) {
    // update internal value
    this.innerValue = value;
    if (typeof this.onChange == 'function') {
      this.onChange(this.innerValue);
    }
  }

  initEdit() {
    if (this.unique && typeof this.unique == 'string') {
      this.writeValue(this.unique);
    }

    this._initValidators();

    this.inputControl.markAsPristine();
    this.inputElement.nativeElement.focus();
  }

  toggleEdit(toggleState = !this.editing, event = null) {
    if (event) {
      event.preventDefault();
      event.stopImmediatePropagation();
      event.stopPropagation();
    }

    this.editing = toggleState;

    if (this.editing) {
      this.initEdit();
    } else {
      if (this.unique && typeof this.unique == 'string') {
        this.writeValue(this.unique);
      }

      this.inputControl.markAsPristine();
    }
  }

  onFocus(event) {
    if (this.validatePatternOnly) {
      this.inputControl.setValidators(this._validators);
    }
    if (!!event && !this.editing) {
      this.toggleEdit();
      this.onFocusCallback.emit();
    }
  }

  onBlur(event) {
    if (this.validatePatternOnly) {
      // if not required, and they left it empty, reset
      if (!this.innerValue) {
        this.inputControl.setErrors(null);
        this.inputControl.clearValidators();
        this.inputControl.markAsPristine();

        if (this.outerFormControl) {
          this.outerFormControl.markAsPristine();
        }
      }
    }
  }

  onSave() {
    if (this.saveDisabled) return;
    if (this.inputControlErrorKey) {
      this._systemAlertSnackBar.warning(
        `your email has errors, please fix and try again.`,
      );
      return;
    }
    this.onSaveCallback.emit(this.innerValue);
  }
}
