import { ListAutomationActionPayload } from 'libs/domain';
import { automation_actions_pb } from 'libs/generated-grpc-ts';
import { wrappers_pb } from 'libs/generated-grpc-ts';

export const toProto = async (
  action: Partial<ListAutomationActionPayload>,
): Promise<automation_actions_pb.AutomationAction> => {
  const message = new automation_actions_pb.AutomationAction();

  // number
  if (action?.id != null) message.setId(action.id);
  if (action?.automationId != null)
    message.setAutomationId(action.automationId);
  if (action?.threshold) message.setThreshold(action.threshold);

  // String
  if (action?.description) message.setDescription(action.description);
  if (action?.note) message.setNote(action.note);
  if (action?.name) message.setName(action.name);
  if (action.consequenceIconType)
    message.setConsequenceIconType(action.consequenceIconType);
  if (action.consequenceIconColor)
    message.setConsequenceIconColor(action.consequenceIconColor);
  if (action.consequenceName)
    message.setConsequenceName(action.consequenceName);
  if (action.consequenceCategoryId)
    message.setConsequenceCategoryId(action.consequenceCategoryId);

  // boolean
  if (action?.repeatStep != null) message.setRepeatStep(action?.repeatStep);
  if (action?.enableNotes != null) message.setEnableNotes(action.enableNotes);

  // nullable number
  if (action?.completeByDays != null) {
    const wrapper = new wrappers_pb.Int32Value();
    wrapper.setValue(action.completeByDays);
    message.setCompleteByDays(wrapper);
  }
  if (action?.additionalBehaviors != null) {
    const wrapper = new wrappers_pb.Int32Value();
    wrapper.setValue(action.additionalBehaviors);
    message.setAdditionalBehaviors(wrapper);
  }
  if (action?.repeatEvery != null) {
    const wrapper = new wrappers_pb.Int32Value();
    wrapper.setValue(action.repeatEvery);
    message.setRepeatEvery(wrapper);
  }
  if (action?.consequenceId != null) {
    const wrapper = new wrappers_pb.Int32Value();
    wrapper.setValue(action.consequenceId);
    message.setConsequenceId(wrapper);
  }
  if (action?.hallpassTypeId != null) {
    const wrapper = new wrappers_pb.Int32Value();
    wrapper.setValue(action.hallpassTypeId);
    message.setHallpassTypeId(wrapper);
  }
  if (action?.pbisTypeId != null) {
    const wrapper = new wrappers_pb.Int32Value();
    wrapper.setValue(action.pbisTypeId);
    message.setPbisTypeId(wrapper);
  }
  if (action?.consequenceToCompleteId != null) {
    const wrapper = new wrappers_pb.Int32Value();
    wrapper.setValue(action.consequenceToCompleteId);
    message.setConsequenceToCompleteId(wrapper);
  }

  return message;
};

export const fromProto = async (
  message: automation_actions_pb.AutomationAction,
): Promise<ListAutomationActionPayload> => {
  return {
    // number
    id: message.getId(),
    automationId: message.getAutomationId(),
    threshold: message.getThreshold(),

    // string
    name: message.getName(),
    description: message.getDescription(),
    note: message.getNote(),
    consequenceIconColor: message.getConsequenceIconColor(),
    consequenceIconType: message.getConsequenceIconType(),
    consequenceName: message.getConsequenceName(),
    consequenceCategoryId: message.getConsequenceCategoryId(),

    // boolean
    enableNotes: message.getEnableNotes(),
    repeatStep: message.getRepeatStep(),

    // nullable number
    completeByDays: message.hasCompleteByDays()
      ? message.getCompleteByDays().getValue()
      : null,
    additionalBehaviors: message.hasAdditionalBehaviors()
      ? message.getAdditionalBehaviors().getValue()
      : null,
    repeatEvery: message.hasRepeatEvery()
      ? message.getRepeatEvery().getValue()
      : null,
    consequenceId: message.hasConsequenceId()
      ? message.getConsequenceId().getValue()
      : null,
    hallpassTypeId: message.hasHallpassTypeId()
      ? message.getHallpassTypeId().getValue()
      : null,
    pbisTypeId: message.hasPbisTypeId()
      ? message.getPbisTypeId().getValue()
      : null,
    consequenceToCompleteId: message.hasConsequenceToCompleteId()
      ? message.getConsequenceToCompleteId().getValue()
      : null,
  };
};
