import * as day from 'dayjs';
import { ReportSortBy } from 'libs/util';

import { IReportPerson } from '../person';

export enum FlexCheckInStatuses {
  IN = 'Checked in',
  ABSENT = 'Absent',
}

export enum FlexRegistrationType {
  REGISTERED = 'Registered',
  ASSIGNED = 'Assigned',
}

export type FlexRegistrationInfo = {
  id: number;
  student: IReportPerson;
  registerer: {
    firstName: string;
    lastName: string;
  };
  registeredDate?: day.Dayjs;
  checkinDate?: day.Dayjs;
  checkinStatus?: FlexCheckInStatuses;
  activityName: string;
  activityId: number;
  activityInstanceId: number;
  teacher: {
    id: number;
    firstName: string;
    lastName: string;
  };
  activityLocation?: string;
  type?: FlexRegistrationType;
  period?: string;
  periodDate?: string;
  periodStartTime?: string;
  periodEndTime?: string;
};

export type FlexUnregisteredInfo = {
  studentId?: string;
  grade?: string;
  role: string;
  badgeIconUrl: string;
  hash: string;
  firstName: string;
  lastName: string;
  email?: string;
};

export type FlexReportFilters = {
  startDate: Date;
  endDate: Date;
  registrants?: number[];
  registerers?: number[];
  activityIds?: number[];
  periodIds?: number[];
  checkinStatus?: FlexCheckInStatuses;
  limit?: number;
  offset?: number;
  sort?: ReportSortBy;
  userList?: number[];
  type?: FlexRegistrationType;
};

export type IFlexRegistrationOptions = FlexReportFilters;

export type IFlexUnregisteredOptions = Omit<
  FlexReportFilters,
  'checkinStatus' | 'startDate' | 'endDate' | 'activityIds'
> & { periodIds: number[] };

interface FlexPersonType {
  firstName: string;
  lastName: string;
  email?: string;
}

export interface FlexReportRegistrationTableData {
  student: FlexPersonType;
  studentId?: string;
  grade?: string;
  role?: string;
  type?: string;
  date?: Date;
  checkinTime?: Date | day.Dayjs;
  period?: string;
  periodDate?: string;
  periodStartTime?: string;
  periodEndTime?: string;
  activity?: string;
  teacher?: FlexPersonType;
  status?: string;
  badgeIconUrl?: string;
}

export type FlexReportUnregisteredTableData = FlexUnregisteredInfo & {
  complete?: boolean; // never set, just used for assign column
};

/** @deprecated only used for ftm stats impl for back compat. date sept 13 23 */
export enum FtmReportPages {
  HISTORY = 'HISTORY',
  UNREGISTERED = 'unregistered',
}

export interface FlexActivityStats {
  activityId: number;
  name: string;
  assigned: number;
  registered: number;
  checkIns: number;
  /** hh:mm.ss */
  totalTime: string;
}

export interface FlexStudentsStats {
  person: IReportPerson;
  assigned: number;
  registered: number;
  checkIns: number;
  attendance: number;
  /** hh:mm */
  totalTime: string;
}

export interface FlexStaffStats {
  person: IReportPerson;
  activities: number;
  /** hh:mm */
  totalTime: string;
  assigned: number;
  registered: number;
  checkIns: number;
}
