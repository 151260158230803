import * as day from 'dayjs';

import { FlexCheckInStatuses } from '../flexTime/FlexTimeStats.domain';

export enum FtmReportFilterType {
  REGISTRANT = 'registrant',
  REGISTERER = 'registerer',
  ACTIVITIES = 'activities',
  PERIODS = 'periods',
  CHECKIN_STATUS = 'checkinStatus',
  START_DATE = 'startDate',
  END_DATE = 'endDate',
  USER_LIST = 'userList',
}

export interface FtmReportFilters {
  [FtmReportFilterType.REGISTRANT]: string[];
  [FtmReportFilterType.REGISTERER]?: string[];
  [FtmReportFilterType.ACTIVITIES]: number[];
  [FtmReportFilterType.PERIODS]: number[];
  [FtmReportFilterType.CHECKIN_STATUS]?: FlexCheckInStatuses;
  [FtmReportFilterType.START_DATE]?: day.Dayjs;
  [FtmReportFilterType.END_DATE]?: day.Dayjs;
  [FtmReportFilterType.USER_LIST]: number[];
}
