// GENERATED CODE -- DO NOT EDIT!

import {grpc} from '@improbable-eng/grpc-web';

import * as gateway_content_report_pb from '../gateway/content_report_pb';
import * as common_legacy_pb from '../common/legacy_pb';
import * as common_stream_pb from '../common/stream_pb';
import * as gateway_moderation_pb from '../gateway/moderation_pb';

export class ReportContent {
  static readonly serviceName = "minga.ReportContent";

  static readonly report = {
    methodName: "report",
    service: ReportContent,
    requestStream: false,
    responseStream: false,
    requestType: gateway_content_report_pb.ReportContentRequest,
    responseType: gateway_content_report_pb.ReportContentResponse,
  };

  static readonly resolve = {
    methodName: "resolve",
    service: ReportContent,
    requestStream: false,
    responseStream: false,
    requestType: gateway_content_report_pb.UnReportContentRequest,
    responseType: gateway_content_report_pb.ReportContentResponse,
  };

  static readonly retrieveReportedContexts = {
    methodName: "retrieveReportedContexts",
    service: ReportContent,
    requestStream: false,
    responseStream: false,
    requestType: gateway_content_report_pb.ReportedContextsRequest,
    responseType: gateway_content_report_pb.ReportedContextsResponse,
  };

  static readonly retrieveReportedUgc = {
    methodName: "retrieveReportedUgc",
    service: ReportContent,
    requestStream: false,
    responseStream: false,
    requestType: gateway_content_report_pb.ReportedUgcRequest,
    responseType: gateway_content_report_pb.ReportedUgcResponse,
  };

  static readonly override = {
    methodName: "override",
    service: ReportContent,
    requestStream: false,
    responseStream: false,
    requestType: gateway_content_report_pb.OverrideModerationRequest,
    responseType: gateway_content_report_pb.OverrideModerationResponse,
  };

  static readonly retrieveStats = {
    methodName: "retrieveStats",
    service: ReportContent,
    requestStream: false,
    responseStream: false,
    requestType: gateway_content_report_pb.ReportStatsRequest,
    responseType: gateway_content_report_pb.ReportStatsResponse,
  };

}

export class ReportStreamManager {
  static readonly serviceName = "minga.ReportStreamManager";

  static readonly StreamReports = {
    methodName: "StreamReports",
    service: ReportStreamManager,
    requestStream: false,
    responseStream: true,
    requestType: common_stream_pb.StreamID,
    responseType: gateway_content_report_pb.StreamModerationItem,
  };

  static readonly StreamReportsControl = {
    methodName: "StreamReportsControl",
    service: ReportStreamManager,
    requestStream: false,
    responseStream: false,
    requestType: common_stream_pb.StreamControl,
    responseType: common_stream_pb.StreamControlResponse,
  };

}

export class ReportContentClient {
  report() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  resolve() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  retrieveReportedContexts() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  retrieveReportedUgc() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  override() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  retrieveStats() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
}

export class ReportStreamManagerClient {
  streamReports() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  streamReportsControl() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
}
