import { Registration, RegistrationTypes } from 'libs/domain';
import { registration_pb as reg_pb } from 'libs/generated-grpc-ts';

import { FlexTimeActivityInstanceMapper } from '../flex_time';

export const toProto = (registration: Registration): reg_pb.Registration => {
  const message = new reg_pb.Registration();
  if (registration.id) message.setId(registration.id);
  if (registration.type === RegistrationTypes.FLEX_TIME) {
    message.setFlex(
      FlexTimeActivityInstanceMapper.toProto(registration.registration),
    );
  }
  if (registration?.personHash) message.setPersonHash(registration.personHash);
  if (registration.cannotUnregister)
    message.setCannotUnregister(registration.cannotUnregister);
  return message;
};

export const fromProto = (message: reg_pb.Registration): Registration => {
  let registration;
  let type;
  if (message.getFlex()) {
    registration = FlexTimeActivityInstanceMapper.fromProto(message.getFlex());
    type = RegistrationTypes.FLEX_TIME;
  }
  return {
    personHash: message.getPersonHash(),
    id: message.getId() ?? undefined,
    registration,
    type,
    cannotUnregister: message.getCannotUnregister() ?? undefined,
  };
};
