import { ScheduledReportUnmapped } from './ScheduledReports.domain';

export enum PastReportStatus {
  'Success',
  'Error',
  'ReportGenerationError',
  'EmailError',
  'SftpError',
}

export interface PastReport {
  id: number;
  name: string;
  /**
   * @todo would be nice to use a template literal type for these
   * 2 properties whenever we upgrade to TS 4.1+
   */
  date: Date;
  status: PastReportStatus;
  scheduledReportId: number;
}

export type PastReportWithScheduledReport = PastReport & {
  scheduledReport: ScheduledReportUnmapped;
};
