import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';

import { MgButtonModule } from '@app/src/app/button';
import { MgEmptyStateModule } from '@app/src/app/components/EmptyState';
import { MgIconModule } from '@app/src/app/icon';
import { MgSpinnerModule } from '@app/src/app/spinner';

import { GenericButtonModule } from '@shared/components/button';

import { ManagerReportDataTableComponent } from './manager-report-data-table.component';

@NgModule({
  imports: [
    // Minga dependencies
    MgButtonModule,
    MgSpinnerModule,
    MgIconModule,
    MgEmptyStateModule,
    GenericButtonModule,

    // External dependencies
    CommonModule,
    FlexLayoutModule,
    MatTableModule,
    MatTooltipModule,
    MatSortModule,
  ],
  declarations: [ManagerReportDataTableComponent],
  exports: [ManagerReportDataTableComponent],
})
export class ManagerReportDataTableModule {}
