import { Injectable } from '@angular/core';

import { ComponentStore, tapResponse } from '@ngrx/component-store';
import { EntityState, createEntityAdapter } from '@ngrx/entity';
import { PastReport } from 'libs/domain';
import { Observable, from } from 'rxjs';
import { exhaustMap, tap } from 'rxjs/operators';

import { ScheduledReportsHistoryService } from '@shared/services/scheduled-reports/scheduled-reports-history.service';

export interface ScheduledReportsHistoryState extends EntityState<PastReport> {
  isLoading: boolean;
  error: string | null;
}

const initialState = {
  isLoading: false,
  error: null,
};

// use entity adapter for managing crud state
const adapter = createEntityAdapter<PastReport>({
  selectId: (pastReport: PastReport) => pastReport.id,
});

const { selectAll } = adapter.getSelectors();

@Injectable()
export class ScheduledReportsHistoryStore extends ComponentStore<ScheduledReportsHistoryState> {
  // reducers
  private _setIsLoading = this.updater(state => ({
    ...state,
    isLoading: true,
  }));
  private _setError = this.updater((state, error: string) => ({
    ...state,
    isLoading: false,
    error,
  }));
  private _setPastReports = this.updater((state, pastReports: PastReport[]) =>
    adapter.setAll(pastReports, {
      ...state,
      isLoading: false,
      error: null,
    }),
  );

  // selectors
  public isLoading$: Observable<boolean> = this.select(
    state => state.isLoading,
  );
  public error$ = this.select(state => state.error);
  public pastReports$ = this.select(state => selectAll(state));

  // effects
  public getHistoryReports = this.effect(trigger$ => {
    return trigger$.pipe(
      tap(() => {
        this._setIsLoading();
      }),
      exhaustMap(() => {
        return from(this._scheduledReportsHistoryService.getAll()).pipe(
          tapResponse(
            pastReports => this._setPastReports(pastReports),
            (error: any) => this._setError(error.message),
          ),
        );
      }),
    );
  });

  constructor(
    private _scheduledReportsHistoryService: ScheduledReportsHistoryService,
  ) {
    super(adapter.getInitialState(initialState));
  }
}
