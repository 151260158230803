// source: content/challenge/challenge_response.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

import * as jspb from 'google-protobuf';
import * as content_common_pb from '../../content/common_pb';
import * as content_video_upload_video_upload_pb from '../../content/video_upload/video_upload_pb';
import * as gateway_person_view_pb from '../../gateway/person_view_pb';
import * as gateway_comment_pb from '../../gateway/comment_pb';
import * as common_date_pb from '../../common/date_pb';
import * as common_stream_pb from '../../common/stream_pb';
import * as image_image_pb from '../../image/image_pb';
export namespace ChallengeResponseInfo {
  export interface AsObject {
    contentHash: string;
    contextHash: string;
    body: string;
    ownerContextHash: string;
    backgroundBannerHash: string;
    backgroundColor: string;
    type: string;
    privacy: string;
    video?: content_video_upload_video_upload_pb.VideoUploadInfo.AsObject;
    assetPath: string;
    approved: string;
  }
}
export class ChallengeResponseInfo extends jspb.Message {
  static readonly displayName = "ChallengeResponseInfo";
  /**
   * Oneof group definitions for this message. Each group defines the field
   * numbers belonging to that group. When of these fields' value is set, all
   * other fields in the group are cleared. During deserialization, if multiple
   * fields are encountered for a group, only the last value seen will be kept.
   * @private {!Array<!Array<number>>}
   * @const
   */
  static readonly oneofGroups_ = [[5,6]];


  /**
   * @return {ChallengeResponseInfo.BackgroundCase}
   */
  getBackgroundCase() {
    return /** @type {ChallengeResponseInfo.BackgroundCase} */(jspb.Message.computeOneofCase(this, ChallengeResponseInfo.oneofGroups_[0]));
  }

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, ChallengeResponseInfo.oneofGroups_);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): ChallengeResponseInfo.AsObject {
    return ChallengeResponseInfo.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!ChallengeResponseInfo} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: ChallengeResponseInfo) {
    var f: any;
    var obj: any = {
      contentHash: jspb.Message.getFieldWithDefault(msg, 1, ""),
      contextHash: jspb.Message.getFieldWithDefault(msg, 2, ""),
      body: jspb.Message.getFieldWithDefault(msg, 3, ""),
      ownerContextHash: jspb.Message.getFieldWithDefault(msg, 4, ""),
      backgroundBannerHash: jspb.Message.getFieldWithDefault(msg, 5, ""),
      backgroundColor: jspb.Message.getFieldWithDefault(msg, 6, ""),
      type: jspb.Message.getFieldWithDefault(msg, 7, ""),
      privacy: jspb.Message.getFieldWithDefault(msg, 8, ""),
      video: (f = msg.getVideo()) && content_video_upload_video_upload_pb.VideoUploadInfo.toObject(includeInstance, f),
      assetPath: jspb.Message.getFieldWithDefault(msg, 10, ""),
      approved: jspb.Message.getFieldWithDefault(msg, 11, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!ChallengeResponseInfo}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new ChallengeResponseInfo;
    return ChallengeResponseInfo.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!ChallengeResponseInfo} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!ChallengeResponseInfo}
   */
  static deserializeBinaryFromReader(msg: ChallengeResponseInfo, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setContentHash(value1);
        break;
      case 2:
        var value2 = /** @type {string} */ (reader.readString());
        msg.setContextHash(value2);
        break;
      case 3:
        var value3 = /** @type {string} */ (reader.readString());
        msg.setBody(value3);
        break;
      case 4:
        var value4 = /** @type {string} */ (reader.readString());
        msg.setOwnerContextHash(value4);
        break;
      case 5:
        var value5 = /** @type {string} */ (reader.readString());
        msg.setBackgroundBannerHash(value5);
        break;
      case 6:
        var value6 = /** @type {string} */ (reader.readString());
        msg.setBackgroundColor(value6);
        break;
      case 7:
        var value7 = /** @type {string} */ (reader.readString());
        msg.setType(value7);
        break;
      case 8:
        var value8 = /** @type {string} */ (reader.readString());
        msg.setPrivacy(value8);
        break;
      case 9:
        var value9 = new content_video_upload_video_upload_pb.VideoUploadInfo;
        reader.readMessage(value9,content_video_upload_video_upload_pb.VideoUploadInfo.deserializeBinaryFromReader);
        msg.setVideo(value9);
        break;
      case 10:
        var value10 = /** @type {string} */ (reader.readString());
        msg.setAssetPath(value10);
        break;
      case 11:
        var value11 = /** @type {string} */ (reader.readString());
        msg.setApproved(value11);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!ChallengeResponseInfo} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: ChallengeResponseInfo, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getContentHash();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
    f = message.getContextHash();
    if (f.length > 0) {
      writer.writeString(
        2,
        f
      );
    }
    f = message.getBody();
    if (f.length > 0) {
      writer.writeString(
        3,
        f
      );
    }
    f = message.getOwnerContextHash();
    if (f.length > 0) {
      writer.writeString(
        4,
        f
      );
    }
    f = /** @type {string} */ (jspb.Message.getField(message, 5));
    if (f != null) {
      writer.writeString(
        5,
        f
      );
    }
    f = /** @type {string} */ (jspb.Message.getField(message, 6));
    if (f != null) {
      writer.writeString(
        6,
        f
      );
    }
    f = message.getType();
    if (f.length > 0) {
      writer.writeString(
        7,
        f
      );
    }
    f = message.getPrivacy();
    if (f.length > 0) {
      writer.writeString(
        8,
        f
      );
    }
    f = message.getVideo();
    if (f != null) {
      writer.writeMessage(
        9,
        f,
        content_video_upload_video_upload_pb.VideoUploadInfo.serializeBinaryToWriter
      );
    }
    f = message.getAssetPath();
    if (f.length > 0) {
      writer.writeString(
        10,
        f
      );
    }
    f = message.getApproved();
    if (f.length > 0) {
      writer.writeString(
        11,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    ChallengeResponseInfo.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string content_hash = 1;
   * @return {string}
   */
  getContentHash(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setContentHash(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


  /**
   * optional string context_hash = 2;
   * @return {string}
   */
  getContextHash(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
  };


  /** @param {string} value */
  setContextHash(value: string) {
    jspb.Message.setProto3StringField(this, 2, value);
  }


  /**
   * optional string body = 3;
   * @return {string}
   */
  getBody(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
  };


  /** @param {string} value */
  setBody(value: string) {
    jspb.Message.setProto3StringField(this, 3, value);
  }


  /**
   * optional string owner_context_hash = 4;
   * @return {string}
   */
  getOwnerContextHash(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
  };


  /** @param {string} value */
  setOwnerContextHash(value: string) {
    jspb.Message.setProto3StringField(this, 4, value);
  }


  /**
   * optional string background_banner_hash = 5;
   * @return {string}
   */
  getBackgroundBannerHash(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
  };


  /** @param {string} value */
  setBackgroundBannerHash(value: string) {
    jspb.Message.setOneofField(this, 5, ChallengeResponseInfo.oneofGroups_[0], value);
  }


  /**
   * Clears the field making it undefined.
   */
  clearBackgroundBannerHash() {
    jspb.Message.setOneofField(this, 5, ChallengeResponseInfo.oneofGroups_[0], undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasBackgroundBannerHash(): boolean {
    return jspb.Message.getField(this, 5) != null;
  }


  /**
   * optional string background_color = 6;
   * @return {string}
   */
  getBackgroundColor(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
  };


  /** @param {string} value */
  setBackgroundColor(value: string) {
    jspb.Message.setOneofField(this, 6, ChallengeResponseInfo.oneofGroups_[0], value);
  }


  /**
   * Clears the field making it undefined.
   */
  clearBackgroundColor() {
    jspb.Message.setOneofField(this, 6, ChallengeResponseInfo.oneofGroups_[0], undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasBackgroundColor(): boolean {
    return jspb.Message.getField(this, 6) != null;
  }


  /**
   * optional string type = 7;
   * @return {string}
   */
  getType(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
  };


  /** @param {string} value */
  setType(value: string) {
    jspb.Message.setProto3StringField(this, 7, value);
  }


  /**
   * optional string privacy = 8;
   * @return {string}
   */
  getPrivacy(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
  };


  /** @param {string} value */
  setPrivacy(value: string) {
    jspb.Message.setProto3StringField(this, 8, value);
  }


  /**
   * optional VideoUploadInfo video = 9;
   * @return {?VideoUploadInfo}
   */
  getVideo(): content_video_upload_video_upload_pb.VideoUploadInfo {
    return /** @type{?VideoUploadInfo} */ (
      jspb.Message.getWrapperField(this, content_video_upload_video_upload_pb.VideoUploadInfo, 9));
  }


  /** @param {?VideoUploadInfo|undefined} value */
  setVideo(value?: content_video_upload_video_upload_pb.VideoUploadInfo) {
    jspb.Message.setWrapperField(this, 9, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearVideo() {
    this.setVideo(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasVideo(): boolean {
    return jspb.Message.getField(this, 9) != null;
  }


  /**
   * optional string asset_path = 10;
   * @return {string}
   */
  getAssetPath(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
  };


  /** @param {string} value */
  setAssetPath(value: string) {
    jspb.Message.setProto3StringField(this, 10, value);
  }


  /**
   * optional string approved = 11;
   * @return {string}
   */
  getApproved(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
  };


  /** @param {string} value */
  setApproved(value: string) {
    jspb.Message.setProto3StringField(this, 11, value);
  }


}
export namespace ChallengeResponseInfo {
export enum BackgroundCase {
  BACKGROUND_NOT_SET = 0,
  BACKGROUND_BANNER_HASH = 5,
  BACKGROUND_COLOR = 6,
}
} // namespace ChallengeResponseInfo
export namespace ChallengeResponseCard {
  export interface AsObject {
    contentHash: string;
    contextHash: string;
    body: string;
    ownerContextHash: string;
    backgroundBannerHash: string;
    backgroundColor: string;
    type: string;
    privacy: string;
    video?: content_video_upload_video_upload_pb.VideoUploadInfo.AsObject;
    featuredImage?: image_image_pb.ImageInfo.AsObject;
    authorPersonView?: gateway_person_view_pb.PersonViewMinimal.AsObject;
    likeCount: number;
    commentCount: number;
    commentList: gateway_comment_pb.CommentContent.AsObject[];
    publishDate?: common_date_pb.DateTime.AsObject;
    commentListPage: number;
    totalCommentCount: number;
    backgroundImage?: image_image_pb.ImageInfo.AsObject;
    color: string;
    approved: string;
  }
}
export class ChallengeResponseCard extends jspb.Message {
  static readonly displayName = "ChallengeResponseCard";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [14];

  /**
   * Oneof group definitions for this message. Each group defines the field
   * numbers belonging to that group. When of these fields' value is set, all
   * other fields in the group are cleared. During deserialization, if multiple
   * fields are encountered for a group, only the last value seen will be kept.
   * @private {!Array<!Array<number>>}
   * @const
   */
  static readonly oneofGroups_ = [[5,6]];


  /**
   * @return {ChallengeResponseCard.BackgroundCase}
   */
  getBackgroundCase() {
    return /** @type {ChallengeResponseCard.BackgroundCase} */(jspb.Message.computeOneofCase(this, ChallengeResponseCard.oneofGroups_[0]));
  }

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, ChallengeResponseCard.repeatedFields_, ChallengeResponseCard.oneofGroups_);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): ChallengeResponseCard.AsObject {
    return ChallengeResponseCard.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!ChallengeResponseCard} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: ChallengeResponseCard) {
    var f: any;
    var obj: any = {
      contentHash: jspb.Message.getFieldWithDefault(msg, 1, ""),
      contextHash: jspb.Message.getFieldWithDefault(msg, 2, ""),
      body: jspb.Message.getFieldWithDefault(msg, 3, ""),
      ownerContextHash: jspb.Message.getFieldWithDefault(msg, 4, ""),
      backgroundBannerHash: jspb.Message.getFieldWithDefault(msg, 5, ""),
      backgroundColor: jspb.Message.getFieldWithDefault(msg, 6, ""),
      type: jspb.Message.getFieldWithDefault(msg, 7, ""),
      privacy: jspb.Message.getFieldWithDefault(msg, 8, ""),
      video: (f = msg.getVideo()) && content_video_upload_video_upload_pb.VideoUploadInfo.toObject(includeInstance, f),
      featuredImage: (f = msg.getFeaturedImage()) && image_image_pb.ImageInfo.toObject(includeInstance, f),
      authorPersonView: (f = msg.getAuthorPersonView()) && gateway_person_view_pb.PersonViewMinimal.toObject(includeInstance, f),
      likeCount: jspb.Message.getFieldWithDefault(msg, 12, 0),
      commentCount: jspb.Message.getFieldWithDefault(msg, 13, 0),
      commentList: jspb.Message.toObjectList(msg.getCommentList(),
      gateway_comment_pb.CommentContent.toObject, includeInstance),
      publishDate: (f = msg.getPublishDate()) && common_date_pb.DateTime.toObject(includeInstance, f),
      commentListPage: jspb.Message.getFieldWithDefault(msg, 16, 0),
      totalCommentCount: jspb.Message.getFieldWithDefault(msg, 17, 0),
      backgroundImage: (f = msg.getBackgroundImage()) && image_image_pb.ImageInfo.toObject(includeInstance, f),
      color: jspb.Message.getFieldWithDefault(msg, 19, ""),
      approved: jspb.Message.getFieldWithDefault(msg, 20, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!ChallengeResponseCard}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new ChallengeResponseCard;
    return ChallengeResponseCard.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!ChallengeResponseCard} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!ChallengeResponseCard}
   */
  static deserializeBinaryFromReader(msg: ChallengeResponseCard, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setContentHash(value1);
        break;
      case 2:
        var value2 = /** @type {string} */ (reader.readString());
        msg.setContextHash(value2);
        break;
      case 3:
        var value3 = /** @type {string} */ (reader.readString());
        msg.setBody(value3);
        break;
      case 4:
        var value4 = /** @type {string} */ (reader.readString());
        msg.setOwnerContextHash(value4);
        break;
      case 5:
        var value5 = /** @type {string} */ (reader.readString());
        msg.setBackgroundBannerHash(value5);
        break;
      case 6:
        var value6 = /** @type {string} */ (reader.readString());
        msg.setBackgroundColor(value6);
        break;
      case 7:
        var value7 = /** @type {string} */ (reader.readString());
        msg.setType(value7);
        break;
      case 8:
        var value8 = /** @type {string} */ (reader.readString());
        msg.setPrivacy(value8);
        break;
      case 9:
        var value9 = new content_video_upload_video_upload_pb.VideoUploadInfo;
        reader.readMessage(value9,content_video_upload_video_upload_pb.VideoUploadInfo.deserializeBinaryFromReader);
        msg.setVideo(value9);
        break;
      case 10:
        var value10 = new image_image_pb.ImageInfo;
        reader.readMessage(value10,image_image_pb.ImageInfo.deserializeBinaryFromReader);
        msg.setFeaturedImage(value10);
        break;
      case 11:
        var value11 = new gateway_person_view_pb.PersonViewMinimal;
        reader.readMessage(value11,gateway_person_view_pb.PersonViewMinimal.deserializeBinaryFromReader);
        msg.setAuthorPersonView(value11);
        break;
      case 12:
        var value12 = /** @type {number} */ (reader.readInt32());
        msg.setLikeCount(value12);
        break;
      case 13:
        var value13 = /** @type {number} */ (reader.readInt32());
        msg.setCommentCount(value13);
        break;
      case 14:
        var value14 = new gateway_comment_pb.CommentContent;
        reader.readMessage(value14,gateway_comment_pb.CommentContent.deserializeBinaryFromReader);
        msg.addComment(value14);
        break;
      case 15:
        var value15 = new common_date_pb.DateTime;
        reader.readMessage(value15,common_date_pb.DateTime.deserializeBinaryFromReader);
        msg.setPublishDate(value15);
        break;
      case 16:
        var value16 = /** @type {number} */ (reader.readInt32());
        msg.setCommentListPage(value16);
        break;
      case 17:
        var value17 = /** @type {number} */ (reader.readInt32());
        msg.setTotalCommentCount(value17);
        break;
      case 18:
        var value18 = new image_image_pb.ImageInfo;
        reader.readMessage(value18,image_image_pb.ImageInfo.deserializeBinaryFromReader);
        msg.setBackgroundImage(value18);
        break;
      case 19:
        var value19 = /** @type {string} */ (reader.readString());
        msg.setColor(value19);
        break;
      case 20:
        var value20 = /** @type {string} */ (reader.readString());
        msg.setApproved(value20);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!ChallengeResponseCard} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: ChallengeResponseCard, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getContentHash();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
    f = message.getContextHash();
    if (f.length > 0) {
      writer.writeString(
        2,
        f
      );
    }
    f = message.getBody();
    if (f.length > 0) {
      writer.writeString(
        3,
        f
      );
    }
    f = message.getOwnerContextHash();
    if (f.length > 0) {
      writer.writeString(
        4,
        f
      );
    }
    f = /** @type {string} */ (jspb.Message.getField(message, 5));
    if (f != null) {
      writer.writeString(
        5,
        f
      );
    }
    f = /** @type {string} */ (jspb.Message.getField(message, 6));
    if (f != null) {
      writer.writeString(
        6,
        f
      );
    }
    f = message.getType();
    if (f.length > 0) {
      writer.writeString(
        7,
        f
      );
    }
    f = message.getPrivacy();
    if (f.length > 0) {
      writer.writeString(
        8,
        f
      );
    }
    f = message.getVideo();
    if (f != null) {
      writer.writeMessage(
        9,
        f,
        content_video_upload_video_upload_pb.VideoUploadInfo.serializeBinaryToWriter
      );
    }
    f = message.getFeaturedImage();
    if (f != null) {
      writer.writeMessage(
        10,
        f,
        image_image_pb.ImageInfo.serializeBinaryToWriter
      );
    }
    f = message.getAuthorPersonView();
    if (f != null) {
      writer.writeMessage(
        11,
        f,
        gateway_person_view_pb.PersonViewMinimal.serializeBinaryToWriter
      );
    }
    f = message.getLikeCount();
    if (f !== 0) {
      writer.writeInt32(
        12,
        f
      );
    }
    f = message.getCommentCount();
    if (f !== 0) {
      writer.writeInt32(
        13,
        f
      );
    }
    f = message.getCommentList();
    if (f.length > 0) {
      writer.writeRepeatedMessage(
        14,
        f,
        gateway_comment_pb.CommentContent.serializeBinaryToWriter
      );
    }
    f = message.getPublishDate();
    if (f != null) {
      writer.writeMessage(
        15,
        f,
        common_date_pb.DateTime.serializeBinaryToWriter
      );
    }
    f = message.getCommentListPage();
    if (f !== 0) {
      writer.writeInt32(
        16,
        f
      );
    }
    f = message.getTotalCommentCount();
    if (f !== 0) {
      writer.writeInt32(
        17,
        f
      );
    }
    f = message.getBackgroundImage();
    if (f != null) {
      writer.writeMessage(
        18,
        f,
        image_image_pb.ImageInfo.serializeBinaryToWriter
      );
    }
    f = message.getColor();
    if (f.length > 0) {
      writer.writeString(
        19,
        f
      );
    }
    f = message.getApproved();
    if (f.length > 0) {
      writer.writeString(
        20,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    ChallengeResponseCard.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string content_hash = 1;
   * @return {string}
   */
  getContentHash(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setContentHash(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


  /**
   * optional string context_hash = 2;
   * @return {string}
   */
  getContextHash(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
  };


  /** @param {string} value */
  setContextHash(value: string) {
    jspb.Message.setProto3StringField(this, 2, value);
  }


  /**
   * optional string body = 3;
   * @return {string}
   */
  getBody(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
  };


  /** @param {string} value */
  setBody(value: string) {
    jspb.Message.setProto3StringField(this, 3, value);
  }


  /**
   * optional string owner_context_hash = 4;
   * @return {string}
   */
  getOwnerContextHash(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
  };


  /** @param {string} value */
  setOwnerContextHash(value: string) {
    jspb.Message.setProto3StringField(this, 4, value);
  }


  /**
   * optional string background_banner_hash = 5;
   * @return {string}
   */
  getBackgroundBannerHash(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
  };


  /** @param {string} value */
  setBackgroundBannerHash(value: string) {
    jspb.Message.setOneofField(this, 5, ChallengeResponseCard.oneofGroups_[0], value);
  }


  /**
   * Clears the field making it undefined.
   */
  clearBackgroundBannerHash() {
    jspb.Message.setOneofField(this, 5, ChallengeResponseCard.oneofGroups_[0], undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasBackgroundBannerHash(): boolean {
    return jspb.Message.getField(this, 5) != null;
  }


  /**
   * optional string background_color = 6;
   * @return {string}
   */
  getBackgroundColor(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
  };


  /** @param {string} value */
  setBackgroundColor(value: string) {
    jspb.Message.setOneofField(this, 6, ChallengeResponseCard.oneofGroups_[0], value);
  }


  /**
   * Clears the field making it undefined.
   */
  clearBackgroundColor() {
    jspb.Message.setOneofField(this, 6, ChallengeResponseCard.oneofGroups_[0], undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasBackgroundColor(): boolean {
    return jspb.Message.getField(this, 6) != null;
  }


  /**
   * optional string type = 7;
   * @return {string}
   */
  getType(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
  };


  /** @param {string} value */
  setType(value: string) {
    jspb.Message.setProto3StringField(this, 7, value);
  }


  /**
   * optional string privacy = 8;
   * @return {string}
   */
  getPrivacy(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
  };


  /** @param {string} value */
  setPrivacy(value: string) {
    jspb.Message.setProto3StringField(this, 8, value);
  }


  /**
   * optional VideoUploadInfo video = 9;
   * @return {?VideoUploadInfo}
   */
  getVideo(): content_video_upload_video_upload_pb.VideoUploadInfo {
    return /** @type{?VideoUploadInfo} */ (
      jspb.Message.getWrapperField(this, content_video_upload_video_upload_pb.VideoUploadInfo, 9));
  }


  /** @param {?VideoUploadInfo|undefined} value */
  setVideo(value?: content_video_upload_video_upload_pb.VideoUploadInfo) {
    jspb.Message.setWrapperField(this, 9, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearVideo() {
    this.setVideo(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasVideo(): boolean {
    return jspb.Message.getField(this, 9) != null;
  }


  /**
   * optional ImageInfo featured_image = 10;
   * @return {?ImageInfo}
   */
  getFeaturedImage(): image_image_pb.ImageInfo {
    return /** @type{?ImageInfo} */ (
      jspb.Message.getWrapperField(this, image_image_pb.ImageInfo, 10));
  }


  /** @param {?ImageInfo|undefined} value */
  setFeaturedImage(value?: image_image_pb.ImageInfo) {
    jspb.Message.setWrapperField(this, 10, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearFeaturedImage() {
    this.setFeaturedImage(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasFeaturedImage(): boolean {
    return jspb.Message.getField(this, 10) != null;
  }


  /**
   * optional PersonViewMinimal author_person_view = 11;
   * @return {?PersonViewMinimal}
   */
  getAuthorPersonView(): gateway_person_view_pb.PersonViewMinimal {
    return /** @type{?PersonViewMinimal} */ (
      jspb.Message.getWrapperField(this, gateway_person_view_pb.PersonViewMinimal, 11));
  }


  /** @param {?PersonViewMinimal|undefined} value */
  setAuthorPersonView(value?: gateway_person_view_pb.PersonViewMinimal) {
    jspb.Message.setWrapperField(this, 11, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearAuthorPersonView() {
    this.setAuthorPersonView(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasAuthorPersonView(): boolean {
    return jspb.Message.getField(this, 11) != null;
  }


  /**
   * optional int32 like_count = 12;
   * @return {number}
   */
  getLikeCount(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
  };


  /** @param {number} value */
  setLikeCount(value: number) {
    jspb.Message.setProto3IntField(this, 12, value);
  }


  /**
   * optional int32 comment_count = 13;
   * @return {number}
   */
  getCommentCount(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
  };


  /** @param {number} value */
  setCommentCount(value: number) {
    jspb.Message.setProto3IntField(this, 13, value);
  }


  /**
   * repeated CommentContent comment = 14;
   * @return {!Array<!CommentContent>}
   */
  getCommentList(): gateway_comment_pb.CommentContent[] {
    return /** @type{!Array<!CommentContent>} */ (
      jspb.Message.getRepeatedWrapperField(this, gateway_comment_pb.CommentContent, 14));
  }


  /** @param {!Array<!CommentContent>} value */
  setCommentList(value?: gateway_comment_pb.CommentContent[]) {
    jspb.Message.setRepeatedWrapperField(this, 14, value);
  }


  /**
   * @param {!CommentContent=} opt_value
   * @param {number=} opt_index
   * @return {!CommentContent}
   */
  addComment(opt_value?: gateway_comment_pb.CommentContent, opt_index?: number): gateway_comment_pb.CommentContent {
    return jspb.Message.addToRepeatedWrapperField(this, 14, opt_value, gateway_comment_pb.CommentContent, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearCommentList() {
    this.setCommentList([]);
  }


  /**
   * optional minga.common.DateTime publish_date = 15;
   * @return {?DateTime}
   */
  getPublishDate(): common_date_pb.DateTime {
    return /** @type{?DateTime} */ (
      jspb.Message.getWrapperField(this, common_date_pb.DateTime, 15));
  }


  /** @param {?DateTime|undefined} value */
  setPublishDate(value?: common_date_pb.DateTime) {
    jspb.Message.setWrapperField(this, 15, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearPublishDate() {
    this.setPublishDate(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasPublishDate(): boolean {
    return jspb.Message.getField(this, 15) != null;
  }


  /**
   * optional int32 comment_list_page = 16;
   * @return {number}
   */
  getCommentListPage(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
  };


  /** @param {number} value */
  setCommentListPage(value: number) {
    jspb.Message.setProto3IntField(this, 16, value);
  }


  /**
   * optional int32 total_comment_count = 17;
   * @return {number}
   */
  getTotalCommentCount(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 17, 0));
  };


  /** @param {number} value */
  setTotalCommentCount(value: number) {
    jspb.Message.setProto3IntField(this, 17, value);
  }


  /**
   * optional ImageInfo background_image = 18;
   * @return {?ImageInfo}
   */
  getBackgroundImage(): image_image_pb.ImageInfo {
    return /** @type{?ImageInfo} */ (
      jspb.Message.getWrapperField(this, image_image_pb.ImageInfo, 18));
  }


  /** @param {?ImageInfo|undefined} value */
  setBackgroundImage(value?: image_image_pb.ImageInfo) {
    jspb.Message.setWrapperField(this, 18, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearBackgroundImage() {
    this.setBackgroundImage(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasBackgroundImage(): boolean {
    return jspb.Message.getField(this, 18) != null;
  }


  /**
   * optional string color = 19;
   * @return {string}
   */
  getColor(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
  };


  /** @param {string} value */
  setColor(value: string) {
    jspb.Message.setProto3StringField(this, 19, value);
  }


  /**
   * optional string approved = 20;
   * @return {string}
   */
  getApproved(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
  };


  /** @param {string} value */
  setApproved(value: string) {
    jspb.Message.setProto3StringField(this, 20, value);
  }


}
export namespace ChallengeResponseCard {
export enum BackgroundCase {
  BACKGROUND_NOT_SET = 0,
  BACKGROUND_BANNER_HASH = 5,
  BACKGROUND_COLOR = 6,
}
} // namespace ChallengeResponseCard
export namespace ChallengeResponseListItem {
  export interface AsObject {
    contentHash: string;
    contextHash: string;
    body: string;
    ownerContextHash: string;
    grade: string;
    publishDate?: common_date_pb.DateTime.AsObject;
    authorPersonView?: gateway_person_view_pb.PersonViewMinimal.AsObject;
    likeCount: number;
    commentCount: number;
    approved: string;
    featuredImage?: image_image_pb.ImageInfo.AsObject;
  }
}
export class ChallengeResponseListItem extends jspb.Message {
  static readonly displayName = "ChallengeResponseListItem";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): ChallengeResponseListItem.AsObject {
    return ChallengeResponseListItem.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!ChallengeResponseListItem} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: ChallengeResponseListItem) {
    var f: any;
    var obj: any = {
      contentHash: jspb.Message.getFieldWithDefault(msg, 1, ""),
      contextHash: jspb.Message.getFieldWithDefault(msg, 2, ""),
      body: jspb.Message.getFieldWithDefault(msg, 3, ""),
      ownerContextHash: jspb.Message.getFieldWithDefault(msg, 4, ""),
      grade: jspb.Message.getFieldWithDefault(msg, 5, ""),
      publishDate: (f = msg.getPublishDate()) && common_date_pb.DateTime.toObject(includeInstance, f),
      authorPersonView: (f = msg.getAuthorPersonView()) && gateway_person_view_pb.PersonViewMinimal.toObject(includeInstance, f),
      likeCount: jspb.Message.getFieldWithDefault(msg, 8, 0),
      commentCount: jspb.Message.getFieldWithDefault(msg, 9, 0),
      approved: jspb.Message.getFieldWithDefault(msg, 10, ""),
      featuredImage: (f = msg.getFeaturedImage()) && image_image_pb.ImageInfo.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!ChallengeResponseListItem}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new ChallengeResponseListItem;
    return ChallengeResponseListItem.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!ChallengeResponseListItem} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!ChallengeResponseListItem}
   */
  static deserializeBinaryFromReader(msg: ChallengeResponseListItem, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setContentHash(value1);
        break;
      case 2:
        var value2 = /** @type {string} */ (reader.readString());
        msg.setContextHash(value2);
        break;
      case 3:
        var value3 = /** @type {string} */ (reader.readString());
        msg.setBody(value3);
        break;
      case 4:
        var value4 = /** @type {string} */ (reader.readString());
        msg.setOwnerContextHash(value4);
        break;
      case 5:
        var value5 = /** @type {string} */ (reader.readString());
        msg.setGrade(value5);
        break;
      case 6:
        var value6 = new common_date_pb.DateTime;
        reader.readMessage(value6,common_date_pb.DateTime.deserializeBinaryFromReader);
        msg.setPublishDate(value6);
        break;
      case 7:
        var value7 = new gateway_person_view_pb.PersonViewMinimal;
        reader.readMessage(value7,gateway_person_view_pb.PersonViewMinimal.deserializeBinaryFromReader);
        msg.setAuthorPersonView(value7);
        break;
      case 8:
        var value8 = /** @type {number} */ (reader.readInt32());
        msg.setLikeCount(value8);
        break;
      case 9:
        var value9 = /** @type {number} */ (reader.readInt32());
        msg.setCommentCount(value9);
        break;
      case 10:
        var value10 = /** @type {string} */ (reader.readString());
        msg.setApproved(value10);
        break;
      case 11:
        var value11 = new image_image_pb.ImageInfo;
        reader.readMessage(value11,image_image_pb.ImageInfo.deserializeBinaryFromReader);
        msg.setFeaturedImage(value11);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!ChallengeResponseListItem} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: ChallengeResponseListItem, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getContentHash();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
    f = message.getContextHash();
    if (f.length > 0) {
      writer.writeString(
        2,
        f
      );
    }
    f = message.getBody();
    if (f.length > 0) {
      writer.writeString(
        3,
        f
      );
    }
    f = message.getOwnerContextHash();
    if (f.length > 0) {
      writer.writeString(
        4,
        f
      );
    }
    f = message.getGrade();
    if (f.length > 0) {
      writer.writeString(
        5,
        f
      );
    }
    f = message.getPublishDate();
    if (f != null) {
      writer.writeMessage(
        6,
        f,
        common_date_pb.DateTime.serializeBinaryToWriter
      );
    }
    f = message.getAuthorPersonView();
    if (f != null) {
      writer.writeMessage(
        7,
        f,
        gateway_person_view_pb.PersonViewMinimal.serializeBinaryToWriter
      );
    }
    f = message.getLikeCount();
    if (f !== 0) {
      writer.writeInt32(
        8,
        f
      );
    }
    f = message.getCommentCount();
    if (f !== 0) {
      writer.writeInt32(
        9,
        f
      );
    }
    f = message.getApproved();
    if (f.length > 0) {
      writer.writeString(
        10,
        f
      );
    }
    f = message.getFeaturedImage();
    if (f != null) {
      writer.writeMessage(
        11,
        f,
        image_image_pb.ImageInfo.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    ChallengeResponseListItem.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string content_hash = 1;
   * @return {string}
   */
  getContentHash(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setContentHash(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


  /**
   * optional string context_hash = 2;
   * @return {string}
   */
  getContextHash(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
  };


  /** @param {string} value */
  setContextHash(value: string) {
    jspb.Message.setProto3StringField(this, 2, value);
  }


  /**
   * optional string body = 3;
   * @return {string}
   */
  getBody(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
  };


  /** @param {string} value */
  setBody(value: string) {
    jspb.Message.setProto3StringField(this, 3, value);
  }


  /**
   * optional string owner_context_hash = 4;
   * @return {string}
   */
  getOwnerContextHash(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
  };


  /** @param {string} value */
  setOwnerContextHash(value: string) {
    jspb.Message.setProto3StringField(this, 4, value);
  }


  /**
   * optional string grade = 5;
   * @return {string}
   */
  getGrade(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
  };


  /** @param {string} value */
  setGrade(value: string) {
    jspb.Message.setProto3StringField(this, 5, value);
  }


  /**
   * optional minga.common.DateTime publish_date = 6;
   * @return {?DateTime}
   */
  getPublishDate(): common_date_pb.DateTime {
    return /** @type{?DateTime} */ (
      jspb.Message.getWrapperField(this, common_date_pb.DateTime, 6));
  }


  /** @param {?DateTime|undefined} value */
  setPublishDate(value?: common_date_pb.DateTime) {
    jspb.Message.setWrapperField(this, 6, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearPublishDate() {
    this.setPublishDate(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasPublishDate(): boolean {
    return jspb.Message.getField(this, 6) != null;
  }


  /**
   * optional PersonViewMinimal author_person_view = 7;
   * @return {?PersonViewMinimal}
   */
  getAuthorPersonView(): gateway_person_view_pb.PersonViewMinimal {
    return /** @type{?PersonViewMinimal} */ (
      jspb.Message.getWrapperField(this, gateway_person_view_pb.PersonViewMinimal, 7));
  }


  /** @param {?PersonViewMinimal|undefined} value */
  setAuthorPersonView(value?: gateway_person_view_pb.PersonViewMinimal) {
    jspb.Message.setWrapperField(this, 7, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearAuthorPersonView() {
    this.setAuthorPersonView(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasAuthorPersonView(): boolean {
    return jspb.Message.getField(this, 7) != null;
  }


  /**
   * optional int32 like_count = 8;
   * @return {number}
   */
  getLikeCount(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
  };


  /** @param {number} value */
  setLikeCount(value: number) {
    jspb.Message.setProto3IntField(this, 8, value);
  }


  /**
   * optional int32 comment_count = 9;
   * @return {number}
   */
  getCommentCount(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
  };


  /** @param {number} value */
  setCommentCount(value: number) {
    jspb.Message.setProto3IntField(this, 9, value);
  }


  /**
   * optional string approved = 10;
   * @return {string}
   */
  getApproved(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
  };


  /** @param {string} value */
  setApproved(value: string) {
    jspb.Message.setProto3StringField(this, 10, value);
  }


  /**
   * optional ImageInfo featured_image = 11;
   * @return {?ImageInfo}
   */
  getFeaturedImage(): image_image_pb.ImageInfo {
    return /** @type{?ImageInfo} */ (
      jspb.Message.getWrapperField(this, image_image_pb.ImageInfo, 11));
  }


  /** @param {?ImageInfo|undefined} value */
  setFeaturedImage(value?: image_image_pb.ImageInfo) {
    jspb.Message.setWrapperField(this, 11, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearFeaturedImage() {
    this.setFeaturedImage(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasFeaturedImage(): boolean {
    return jspb.Message.getField(this, 11) != null;
  }


}
export namespace StreamChallengeResponsesAdminResponse {
  export interface AsObject {
    itemMetadata?: common_stream_pb.StreamItemMetadata.AsObject;
    item?: ChallengeResponseListItem.AsObject;
  }
}
export class StreamChallengeResponsesAdminResponse extends jspb.Message {
  static readonly displayName = "StreamChallengeResponsesAdminResponse";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): StreamChallengeResponsesAdminResponse.AsObject {
    return StreamChallengeResponsesAdminResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!StreamChallengeResponsesAdminResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: StreamChallengeResponsesAdminResponse) {
    var f: any;
    var obj: any = {
      itemMetadata: (f = msg.getItemMetadata()) && common_stream_pb.StreamItemMetadata.toObject(includeInstance, f),
      item: (f = msg.getItem()) && ChallengeResponseListItem.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!StreamChallengeResponsesAdminResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new StreamChallengeResponsesAdminResponse;
    return StreamChallengeResponsesAdminResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!StreamChallengeResponsesAdminResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!StreamChallengeResponsesAdminResponse}
   */
  static deserializeBinaryFromReader(msg: StreamChallengeResponsesAdminResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new common_stream_pb.StreamItemMetadata;
        reader.readMessage(value1,common_stream_pb.StreamItemMetadata.deserializeBinaryFromReader);
        msg.setItemMetadata(value1);
        break;
      case 2:
        var value2 = new ChallengeResponseListItem;
        reader.readMessage(value2,ChallengeResponseListItem.deserializeBinaryFromReader);
        msg.setItem(value2);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!StreamChallengeResponsesAdminResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: StreamChallengeResponsesAdminResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getItemMetadata();
    if (f != null) {
      writer.writeMessage(
        1,
        f,
        common_stream_pb.StreamItemMetadata.serializeBinaryToWriter
      );
    }
    f = message.getItem();
    if (f != null) {
      writer.writeMessage(
        2,
        f,
        ChallengeResponseListItem.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    StreamChallengeResponsesAdminResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional StreamItemMetadata item_metadata = 1;
   * @return {?StreamItemMetadata}
   */
  getItemMetadata(): common_stream_pb.StreamItemMetadata {
    return /** @type{?StreamItemMetadata} */ (
      jspb.Message.getWrapperField(this, common_stream_pb.StreamItemMetadata, 1));
  }


  /** @param {?StreamItemMetadata|undefined} value */
  setItemMetadata(value?: common_stream_pb.StreamItemMetadata) {
    jspb.Message.setWrapperField(this, 1, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearItemMetadata() {
    this.setItemMetadata(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasItemMetadata(): boolean {
    return jspb.Message.getField(this, 1) != null;
  }


  /**
   * optional ChallengeResponseListItem item = 2;
   * @return {?ChallengeResponseListItem}
   */
  getItem(): ChallengeResponseListItem {
    return /** @type{?ChallengeResponseListItem} */ (
      jspb.Message.getWrapperField(this, ChallengeResponseListItem, 2));
  }


  /** @param {?ChallengeResponseListItem|undefined} value */
  setItem(value?: ChallengeResponseListItem) {
    jspb.Message.setWrapperField(this, 2, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearItem() {
    this.setItem(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasItem(): boolean {
    return jspb.Message.getField(this, 2) != null;
  }


}
export namespace StreamChallengeResponseCardResponse {
  export interface AsObject {
    itemMetadata?: common_stream_pb.StreamItemMetadata.AsObject;
    item?: ChallengeResponseCard.AsObject;
  }
}
export class StreamChallengeResponseCardResponse extends jspb.Message {
  static readonly displayName = "StreamChallengeResponseCardResponse";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): StreamChallengeResponseCardResponse.AsObject {
    return StreamChallengeResponseCardResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!StreamChallengeResponseCardResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: StreamChallengeResponseCardResponse) {
    var f: any;
    var obj: any = {
      itemMetadata: (f = msg.getItemMetadata()) && common_stream_pb.StreamItemMetadata.toObject(includeInstance, f),
      item: (f = msg.getItem()) && ChallengeResponseCard.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!StreamChallengeResponseCardResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new StreamChallengeResponseCardResponse;
    return StreamChallengeResponseCardResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!StreamChallengeResponseCardResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!StreamChallengeResponseCardResponse}
   */
  static deserializeBinaryFromReader(msg: StreamChallengeResponseCardResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new common_stream_pb.StreamItemMetadata;
        reader.readMessage(value1,common_stream_pb.StreamItemMetadata.deserializeBinaryFromReader);
        msg.setItemMetadata(value1);
        break;
      case 2:
        var value2 = new ChallengeResponseCard;
        reader.readMessage(value2,ChallengeResponseCard.deserializeBinaryFromReader);
        msg.setItem(value2);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!StreamChallengeResponseCardResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: StreamChallengeResponseCardResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getItemMetadata();
    if (f != null) {
      writer.writeMessage(
        1,
        f,
        common_stream_pb.StreamItemMetadata.serializeBinaryToWriter
      );
    }
    f = message.getItem();
    if (f != null) {
      writer.writeMessage(
        2,
        f,
        ChallengeResponseCard.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    StreamChallengeResponseCardResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional StreamItemMetadata item_metadata = 1;
   * @return {?StreamItemMetadata}
   */
  getItemMetadata(): common_stream_pb.StreamItemMetadata {
    return /** @type{?StreamItemMetadata} */ (
      jspb.Message.getWrapperField(this, common_stream_pb.StreamItemMetadata, 1));
  }


  /** @param {?StreamItemMetadata|undefined} value */
  setItemMetadata(value?: common_stream_pb.StreamItemMetadata) {
    jspb.Message.setWrapperField(this, 1, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearItemMetadata() {
    this.setItemMetadata(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasItemMetadata(): boolean {
    return jspb.Message.getField(this, 1) != null;
  }


  /**
   * optional ChallengeResponseCard item = 2;
   * @return {?ChallengeResponseCard}
   */
  getItem(): ChallengeResponseCard {
    return /** @type{?ChallengeResponseCard} */ (
      jspb.Message.getWrapperField(this, ChallengeResponseCard, 2));
  }


  /** @param {?ChallengeResponseCard|undefined} value */
  setItem(value?: ChallengeResponseCard) {
    jspb.Message.setWrapperField(this, 2, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearItem() {
    this.setItem(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasItem(): boolean {
    return jspb.Message.getField(this, 2) != null;
  }


}
export namespace ApproveOrDeclineResponseRequest {
  export interface AsObject {
    contextHash: string;
    approved: string;
    reasonDeclined: string;
  }
}
export class ApproveOrDeclineResponseRequest extends jspb.Message {
  static readonly displayName = "ApproveOrDeclineResponseRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): ApproveOrDeclineResponseRequest.AsObject {
    return ApproveOrDeclineResponseRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!ApproveOrDeclineResponseRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: ApproveOrDeclineResponseRequest) {
    var f: any;
    var obj: any = {
      contextHash: jspb.Message.getFieldWithDefault(msg, 1, ""),
      approved: jspb.Message.getFieldWithDefault(msg, 2, ""),
      reasonDeclined: jspb.Message.getFieldWithDefault(msg, 3, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!ApproveOrDeclineResponseRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new ApproveOrDeclineResponseRequest;
    return ApproveOrDeclineResponseRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!ApproveOrDeclineResponseRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!ApproveOrDeclineResponseRequest}
   */
  static deserializeBinaryFromReader(msg: ApproveOrDeclineResponseRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setContextHash(value1);
        break;
      case 2:
        var value2 = /** @type {string} */ (reader.readString());
        msg.setApproved(value2);
        break;
      case 3:
        var value3 = /** @type {string} */ (reader.readString());
        msg.setReasonDeclined(value3);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!ApproveOrDeclineResponseRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: ApproveOrDeclineResponseRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getContextHash();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
    f = message.getApproved();
    if (f.length > 0) {
      writer.writeString(
        2,
        f
      );
    }
    f = message.getReasonDeclined();
    if (f.length > 0) {
      writer.writeString(
        3,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    ApproveOrDeclineResponseRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string context_hash = 1;
   * @return {string}
   */
  getContextHash(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setContextHash(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


  /**
   * optional string approved = 2;
   * @return {string}
   */
  getApproved(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
  };


  /** @param {string} value */
  setApproved(value: string) {
    jspb.Message.setProto3StringField(this, 2, value);
  }


  /**
   * optional string reason_declined = 3;
   * @return {string}
   */
  getReasonDeclined(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
  };


  /** @param {string} value */
  setReasonDeclined(value: string) {
    jspb.Message.setProto3StringField(this, 3, value);
  }


}
export namespace ApproveOrDeclineResponseResponse {
  export interface AsObject {
  }
}
export class ApproveOrDeclineResponseResponse extends jspb.Message {
  static readonly displayName = "ApproveOrDeclineResponseResponse";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): ApproveOrDeclineResponseResponse.AsObject {
    return ApproveOrDeclineResponseResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!ApproveOrDeclineResponseResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: ApproveOrDeclineResponseResponse) {
    var f: any;
    var obj: any = {

    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!ApproveOrDeclineResponseResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new ApproveOrDeclineResponseResponse;
    return ApproveOrDeclineResponseResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!ApproveOrDeclineResponseResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!ApproveOrDeclineResponseResponse}
   */
  static deserializeBinaryFromReader(msg: ApproveOrDeclineResponseResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!ApproveOrDeclineResponseResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: ApproveOrDeclineResponseResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    ApproveOrDeclineResponseResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
}
