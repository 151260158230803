// source: flex_time_stats/flex_time_stats.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

import * as jspb from 'google-protobuf';
import * as common_date_pb from '../common/date_pb';
import * as report_person_report_person_pb from '../report_person/report_person_pb';
export namespace FlexReportFilters {
  export interface AsObject {
    activityIdList: number[];
    periodIdList: number[];
    registrantHashList: string[];
    checkinStatus: string;
    startDate?: common_date_pb.DateTime.AsObject;
    endDate?: common_date_pb.DateTime.AsObject;
    limit: number;
    offset: number;
    userListList: number[];
    registererHashList: string[];
  }
}
export class FlexReportFilters extends jspb.Message {
  static readonly displayName = "FlexReportFilters";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [1,2,3,9,10];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, FlexReportFilters.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): FlexReportFilters.AsObject {
    return FlexReportFilters.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!FlexReportFilters} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: FlexReportFilters) {
    var f: any;
    var obj: any = {
      activityIdList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
      periodIdList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
      registrantHashList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
      checkinStatus: jspb.Message.getFieldWithDefault(msg, 4, ""),
      startDate: (f = msg.getStartDate()) && common_date_pb.DateTime.toObject(includeInstance, f),
      endDate: (f = msg.getEndDate()) && common_date_pb.DateTime.toObject(includeInstance, f),
      limit: jspb.Message.getFieldWithDefault(msg, 7, 0),
      offset: jspb.Message.getFieldWithDefault(msg, 8, 0),
      userListList: (f = jspb.Message.getRepeatedField(msg, 9)) == null ? undefined : f,
      registererHashList: (f = jspb.Message.getRepeatedField(msg, 10)) == null ? undefined : f
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!FlexReportFilters}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new FlexReportFilters;
    return FlexReportFilters.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!FlexReportFilters} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!FlexReportFilters}
   */
  static deserializeBinaryFromReader(msg: FlexReportFilters, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {!Array<number>} */ (reader.readPackedInt32());
        msg.setActivityIdList(value1);
        break;
      case 2:
        var value2 = /** @type {!Array<number>} */ (reader.readPackedInt32());
        msg.setPeriodIdList(value2);
        break;
      case 3:
        var value3 = /** @type {string} */ (reader.readString());
        msg.addRegistrantHash(value3);
        break;
      case 4:
        var value4 = /** @type {string} */ (reader.readString());
        msg.setCheckinStatus(value4);
        break;
      case 5:
        var value5 = new common_date_pb.DateTime;
        reader.readMessage(value5,common_date_pb.DateTime.deserializeBinaryFromReader);
        msg.setStartDate(value5);
        break;
      case 6:
        var value6 = new common_date_pb.DateTime;
        reader.readMessage(value6,common_date_pb.DateTime.deserializeBinaryFromReader);
        msg.setEndDate(value6);
        break;
      case 7:
        var value7 = /** @type {number} */ (reader.readInt32());
        msg.setLimit(value7);
        break;
      case 8:
        var value8 = /** @type {number} */ (reader.readInt32());
        msg.setOffset(value8);
        break;
      case 9:
        var value9 = /** @type {!Array<number>} */ (reader.readPackedInt32());
        msg.setUserListList(value9);
        break;
      case 10:
        var value10 = /** @type {string} */ (reader.readString());
        msg.addRegistererHash(value10);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!FlexReportFilters} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: FlexReportFilters, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getActivityIdList();
    if (f.length > 0) {
      writer.writePackedInt32(
        1,
        f
      );
    }
    f = message.getPeriodIdList();
    if (f.length > 0) {
      writer.writePackedInt32(
        2,
        f
      );
    }
    f = message.getRegistrantHashList();
    if (f.length > 0) {
      writer.writeRepeatedString(
        3,
        f
      );
    }
    f = message.getCheckinStatus();
    if (f.length > 0) {
      writer.writeString(
        4,
        f
      );
    }
    f = message.getStartDate();
    if (f != null) {
      writer.writeMessage(
        5,
        f,
        common_date_pb.DateTime.serializeBinaryToWriter
      );
    }
    f = message.getEndDate();
    if (f != null) {
      writer.writeMessage(
        6,
        f,
        common_date_pb.DateTime.serializeBinaryToWriter
      );
    }
    f = message.getLimit();
    if (f !== 0) {
      writer.writeInt32(
        7,
        f
      );
    }
    f = message.getOffset();
    if (f !== 0) {
      writer.writeInt32(
        8,
        f
      );
    }
    f = message.getUserListList();
    if (f.length > 0) {
      writer.writePackedInt32(
        9,
        f
      );
    }
    f = message.getRegistererHashList();
    if (f.length > 0) {
      writer.writeRepeatedString(
        10,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    FlexReportFilters.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * repeated int32 activity_id = 1;
   * @return {!Array<number>}
   */
  getActivityIdList(): number[] {
    return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 1));
  };


  /** @param {!Array<number>} value */
  setActivityIdList(value: number[]) {
    jspb.Message.setField(this, 1, value || []);
  }


  /**
   * @param {number} value
   * @param {number=} opt_index
   */
  addActivityId(value: number, opt_index?: number): void {
    jspb.Message.addToRepeatedField(this, 1, value, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearActivityIdList() {
    this.setActivityIdList([]);
  }


  /**
   * repeated int32 period_id = 2;
   * @return {!Array<number>}
   */
  getPeriodIdList(): number[] {
    return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 2));
  };


  /** @param {!Array<number>} value */
  setPeriodIdList(value: number[]) {
    jspb.Message.setField(this, 2, value || []);
  }


  /**
   * @param {number} value
   * @param {number=} opt_index
   */
  addPeriodId(value: number, opt_index?: number): void {
    jspb.Message.addToRepeatedField(this, 2, value, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearPeriodIdList() {
    this.setPeriodIdList([]);
  }


  /**
   * repeated string registrant_hash = 3;
   * @return {!Array<string>}
   */
  getRegistrantHashList(): string[] {
    return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
  };


  /** @param {!Array<string>} value */
  setRegistrantHashList(value: string[]) {
    jspb.Message.setField(this, 3, value || []);
  }


  /**
   * @param {string} value
   * @param {number=} opt_index
   */
  addRegistrantHash(value: string, opt_index?: number): void {
    jspb.Message.addToRepeatedField(this, 3, value, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearRegistrantHashList() {
    this.setRegistrantHashList([]);
  }


  /**
   * optional string checkin_status = 4;
   * @return {string}
   */
  getCheckinStatus(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
  };


  /** @param {string} value */
  setCheckinStatus(value: string) {
    jspb.Message.setProto3StringField(this, 4, value);
  }


  /**
   * optional minga.common.DateTime start_date = 5;
   * @return {?DateTime}
   */
  getStartDate(): common_date_pb.DateTime {
    return /** @type{?DateTime} */ (
      jspb.Message.getWrapperField(this, common_date_pb.DateTime, 5));
  }


  /** @param {?DateTime|undefined} value */
  setStartDate(value?: common_date_pb.DateTime) {
    jspb.Message.setWrapperField(this, 5, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearStartDate() {
    this.setStartDate(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasStartDate(): boolean {
    return jspb.Message.getField(this, 5) != null;
  }


  /**
   * optional minga.common.DateTime end_date = 6;
   * @return {?DateTime}
   */
  getEndDate(): common_date_pb.DateTime {
    return /** @type{?DateTime} */ (
      jspb.Message.getWrapperField(this, common_date_pb.DateTime, 6));
  }


  /** @param {?DateTime|undefined} value */
  setEndDate(value?: common_date_pb.DateTime) {
    jspb.Message.setWrapperField(this, 6, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearEndDate() {
    this.setEndDate(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasEndDate(): boolean {
    return jspb.Message.getField(this, 6) != null;
  }


  /**
   * optional int32 limit = 7;
   * @return {number}
   */
  getLimit(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
  };


  /** @param {number} value */
  setLimit(value: number) {
    jspb.Message.setProto3IntField(this, 7, value);
  }


  /**
   * optional int32 offset = 8;
   * @return {number}
   */
  getOffset(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
  };


  /** @param {number} value */
  setOffset(value: number) {
    jspb.Message.setProto3IntField(this, 8, value);
  }


  /**
   * repeated int32 user_list = 9;
   * @return {!Array<number>}
   */
  getUserListList(): number[] {
    return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 9));
  };


  /** @param {!Array<number>} value */
  setUserListList(value: number[]) {
    jspb.Message.setField(this, 9, value || []);
  }


  /**
   * @param {number} value
   * @param {number=} opt_index
   */
  addUserList(value: number, opt_index?: number): void {
    jspb.Message.addToRepeatedField(this, 9, value, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearUserListList() {
    this.setUserListList([]);
  }


  /**
   * repeated string registerer_hash = 10;
   * @return {!Array<string>}
   */
  getRegistererHashList(): string[] {
    return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 10));
  };


  /** @param {!Array<string>} value */
  setRegistererHashList(value: string[]) {
    jspb.Message.setField(this, 10, value || []);
  }


  /**
   * @param {string} value
   * @param {number=} opt_index
   */
  addRegistererHash(value: string, opt_index?: number): void {
    jspb.Message.addToRepeatedField(this, 10, value, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearRegistererHashList() {
    this.setRegistererHashList([]);
  }


}
export namespace FlexRegistrationInfo {
  export interface AsObject {
    registrantName: string;
    studentIdNum: string;
    registeredDate?: common_date_pb.DateTime.AsObject;
    checkInDate?: common_date_pb.DateTime.AsObject;
    checkinStatus: string;
    activityName: string;
    activityId: number;
    activityInstanceId: number;
    teacherName: string;
    registrantLastName: string;
    teacherLastName: string;
    grade: string;
    periodName: string;
    periodDate: string;
    periodStartTime: string;
    type: string;
    role: string;
    badgeIconUrl: string;
    periodEndTime: string;
    student?: report_person_report_person_pb.ReportPerson.AsObject;
    id: number;
    registererFirstName: string;
    registererLastName: string;
    activityLocation: string;
    teacherId: number;
  }
}
export class FlexRegistrationInfo extends jspb.Message {
  static readonly displayName = "FlexRegistrationInfo";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): FlexRegistrationInfo.AsObject {
    return FlexRegistrationInfo.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!FlexRegistrationInfo} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: FlexRegistrationInfo) {
    var f: any;
    var obj: any = {
      registrantName: jspb.Message.getFieldWithDefault(msg, 1, ""),
      studentIdNum: jspb.Message.getFieldWithDefault(msg, 2, ""),
      registeredDate: (f = msg.getRegisteredDate()) && common_date_pb.DateTime.toObject(includeInstance, f),
      checkInDate: (f = msg.getCheckInDate()) && common_date_pb.DateTime.toObject(includeInstance, f),
      checkinStatus: jspb.Message.getFieldWithDefault(msg, 5, ""),
      activityName: jspb.Message.getFieldWithDefault(msg, 6, ""),
      activityId: jspb.Message.getFieldWithDefault(msg, 7, 0),
      activityInstanceId: jspb.Message.getFieldWithDefault(msg, 8, 0),
      teacherName: jspb.Message.getFieldWithDefault(msg, 9, ""),
      registrantLastName: jspb.Message.getFieldWithDefault(msg, 10, ""),
      teacherLastName: jspb.Message.getFieldWithDefault(msg, 11, ""),
      grade: jspb.Message.getFieldWithDefault(msg, 12, ""),
      periodName: jspb.Message.getFieldWithDefault(msg, 13, ""),
      periodDate: jspb.Message.getFieldWithDefault(msg, 14, ""),
      periodStartTime: jspb.Message.getFieldWithDefault(msg, 15, ""),
      type: jspb.Message.getFieldWithDefault(msg, 16, ""),
      role: jspb.Message.getFieldWithDefault(msg, 17, ""),
      badgeIconUrl: jspb.Message.getFieldWithDefault(msg, 18, ""),
      periodEndTime: jspb.Message.getFieldWithDefault(msg, 19, ""),
      student: (f = msg.getStudent()) && report_person_report_person_pb.ReportPerson.toObject(includeInstance, f),
      id: jspb.Message.getFieldWithDefault(msg, 21, 0),
      registererFirstName: jspb.Message.getFieldWithDefault(msg, 22, ""),
      registererLastName: jspb.Message.getFieldWithDefault(msg, 23, ""),
      activityLocation: jspb.Message.getFieldWithDefault(msg, 24, ""),
      teacherId: jspb.Message.getFieldWithDefault(msg, 25, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!FlexRegistrationInfo}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new FlexRegistrationInfo;
    return FlexRegistrationInfo.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!FlexRegistrationInfo} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!FlexRegistrationInfo}
   */
  static deserializeBinaryFromReader(msg: FlexRegistrationInfo, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setRegistrantName(value1);
        break;
      case 2:
        var value2 = /** @type {string} */ (reader.readString());
        msg.setStudentIdNum(value2);
        break;
      case 3:
        var value3 = new common_date_pb.DateTime;
        reader.readMessage(value3,common_date_pb.DateTime.deserializeBinaryFromReader);
        msg.setRegisteredDate(value3);
        break;
      case 4:
        var value4 = new common_date_pb.DateTime;
        reader.readMessage(value4,common_date_pb.DateTime.deserializeBinaryFromReader);
        msg.setCheckInDate(value4);
        break;
      case 5:
        var value5 = /** @type {string} */ (reader.readString());
        msg.setCheckinStatus(value5);
        break;
      case 6:
        var value6 = /** @type {string} */ (reader.readString());
        msg.setActivityName(value6);
        break;
      case 7:
        var value7 = /** @type {number} */ (reader.readInt32());
        msg.setActivityId(value7);
        break;
      case 8:
        var value8 = /** @type {number} */ (reader.readInt32());
        msg.setActivityInstanceId(value8);
        break;
      case 9:
        var value9 = /** @type {string} */ (reader.readString());
        msg.setTeacherName(value9);
        break;
      case 10:
        var value10 = /** @type {string} */ (reader.readString());
        msg.setRegistrantLastName(value10);
        break;
      case 11:
        var value11 = /** @type {string} */ (reader.readString());
        msg.setTeacherLastName(value11);
        break;
      case 12:
        var value12 = /** @type {string} */ (reader.readString());
        msg.setGrade(value12);
        break;
      case 13:
        var value13 = /** @type {string} */ (reader.readString());
        msg.setPeriodName(value13);
        break;
      case 14:
        var value14 = /** @type {string} */ (reader.readString());
        msg.setPeriodDate(value14);
        break;
      case 15:
        var value15 = /** @type {string} */ (reader.readString());
        msg.setPeriodStartTime(value15);
        break;
      case 16:
        var value16 = /** @type {string} */ (reader.readString());
        msg.setType(value16);
        break;
      case 17:
        var value17 = /** @type {string} */ (reader.readString());
        msg.setRole(value17);
        break;
      case 18:
        var value18 = /** @type {string} */ (reader.readString());
        msg.setBadgeIconUrl(value18);
        break;
      case 19:
        var value19 = /** @type {string} */ (reader.readString());
        msg.setPeriodEndTime(value19);
        break;
      case 20:
        var value20 = new report_person_report_person_pb.ReportPerson;
        reader.readMessage(value20,report_person_report_person_pb.ReportPerson.deserializeBinaryFromReader);
        msg.setStudent(value20);
        break;
      case 21:
        var value21 = /** @type {number} */ (reader.readInt32());
        msg.setId(value21);
        break;
      case 22:
        var value22 = /** @type {string} */ (reader.readString());
        msg.setRegistererFirstName(value22);
        break;
      case 23:
        var value23 = /** @type {string} */ (reader.readString());
        msg.setRegistererLastName(value23);
        break;
      case 24:
        var value24 = /** @type {string} */ (reader.readString());
        msg.setActivityLocation(value24);
        break;
      case 25:
        var value25 = /** @type {number} */ (reader.readInt32());
        msg.setTeacherId(value25);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!FlexRegistrationInfo} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: FlexRegistrationInfo, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getRegistrantName();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
    f = message.getStudentIdNum();
    if (f.length > 0) {
      writer.writeString(
        2,
        f
      );
    }
    f = message.getRegisteredDate();
    if (f != null) {
      writer.writeMessage(
        3,
        f,
        common_date_pb.DateTime.serializeBinaryToWriter
      );
    }
    f = message.getCheckInDate();
    if (f != null) {
      writer.writeMessage(
        4,
        f,
        common_date_pb.DateTime.serializeBinaryToWriter
      );
    }
    f = message.getCheckinStatus();
    if (f.length > 0) {
      writer.writeString(
        5,
        f
      );
    }
    f = message.getActivityName();
    if (f.length > 0) {
      writer.writeString(
        6,
        f
      );
    }
    f = message.getActivityId();
    if (f !== 0) {
      writer.writeInt32(
        7,
        f
      );
    }
    f = message.getActivityInstanceId();
    if (f !== 0) {
      writer.writeInt32(
        8,
        f
      );
    }
    f = message.getTeacherName();
    if (f.length > 0) {
      writer.writeString(
        9,
        f
      );
    }
    f = message.getRegistrantLastName();
    if (f.length > 0) {
      writer.writeString(
        10,
        f
      );
    }
    f = message.getTeacherLastName();
    if (f.length > 0) {
      writer.writeString(
        11,
        f
      );
    }
    f = message.getGrade();
    if (f.length > 0) {
      writer.writeString(
        12,
        f
      );
    }
    f = message.getPeriodName();
    if (f.length > 0) {
      writer.writeString(
        13,
        f
      );
    }
    f = message.getPeriodDate();
    if (f.length > 0) {
      writer.writeString(
        14,
        f
      );
    }
    f = message.getPeriodStartTime();
    if (f.length > 0) {
      writer.writeString(
        15,
        f
      );
    }
    f = message.getType();
    if (f.length > 0) {
      writer.writeString(
        16,
        f
      );
    }
    f = message.getRole();
    if (f.length > 0) {
      writer.writeString(
        17,
        f
      );
    }
    f = message.getBadgeIconUrl();
    if (f.length > 0) {
      writer.writeString(
        18,
        f
      );
    }
    f = message.getPeriodEndTime();
    if (f.length > 0) {
      writer.writeString(
        19,
        f
      );
    }
    f = message.getStudent();
    if (f != null) {
      writer.writeMessage(
        20,
        f,
        report_person_report_person_pb.ReportPerson.serializeBinaryToWriter
      );
    }
    f = message.getId();
    if (f !== 0) {
      writer.writeInt32(
        21,
        f
      );
    }
    f = message.getRegistererFirstName();
    if (f.length > 0) {
      writer.writeString(
        22,
        f
      );
    }
    f = message.getRegistererLastName();
    if (f.length > 0) {
      writer.writeString(
        23,
        f
      );
    }
    f = message.getActivityLocation();
    if (f.length > 0) {
      writer.writeString(
        24,
        f
      );
    }
    f = message.getTeacherId();
    if (f !== 0) {
      writer.writeInt32(
        25,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    FlexRegistrationInfo.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string registrant_name = 1;
   * @return {string}
   */
  getRegistrantName(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setRegistrantName(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


  /**
   * optional string student_id_num = 2;
   * @return {string}
   */
  getStudentIdNum(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
  };


  /** @param {string} value */
  setStudentIdNum(value: string) {
    jspb.Message.setProto3StringField(this, 2, value);
  }


  /**
   * optional minga.common.DateTime registered_date = 3;
   * @return {?DateTime}
   */
  getRegisteredDate(): common_date_pb.DateTime {
    return /** @type{?DateTime} */ (
      jspb.Message.getWrapperField(this, common_date_pb.DateTime, 3));
  }


  /** @param {?DateTime|undefined} value */
  setRegisteredDate(value?: common_date_pb.DateTime) {
    jspb.Message.setWrapperField(this, 3, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearRegisteredDate() {
    this.setRegisteredDate(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasRegisteredDate(): boolean {
    return jspb.Message.getField(this, 3) != null;
  }


  /**
   * optional minga.common.DateTime check_in_date = 4;
   * @return {?DateTime}
   */
  getCheckInDate(): common_date_pb.DateTime {
    return /** @type{?DateTime} */ (
      jspb.Message.getWrapperField(this, common_date_pb.DateTime, 4));
  }


  /** @param {?DateTime|undefined} value */
  setCheckInDate(value?: common_date_pb.DateTime) {
    jspb.Message.setWrapperField(this, 4, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearCheckInDate() {
    this.setCheckInDate(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasCheckInDate(): boolean {
    return jspb.Message.getField(this, 4) != null;
  }


  /**
   * optional string checkin_status = 5;
   * @return {string}
   */
  getCheckinStatus(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
  };


  /** @param {string} value */
  setCheckinStatus(value: string) {
    jspb.Message.setProto3StringField(this, 5, value);
  }


  /**
   * optional string activity_name = 6;
   * @return {string}
   */
  getActivityName(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
  };


  /** @param {string} value */
  setActivityName(value: string) {
    jspb.Message.setProto3StringField(this, 6, value);
  }


  /**
   * optional int32 activity_id = 7;
   * @return {number}
   */
  getActivityId(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
  };


  /** @param {number} value */
  setActivityId(value: number) {
    jspb.Message.setProto3IntField(this, 7, value);
  }


  /**
   * optional int32 activity_instance_id = 8;
   * @return {number}
   */
  getActivityInstanceId(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
  };


  /** @param {number} value */
  setActivityInstanceId(value: number) {
    jspb.Message.setProto3IntField(this, 8, value);
  }


  /**
   * optional string teacher_name = 9;
   * @return {string}
   */
  getTeacherName(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
  };


  /** @param {string} value */
  setTeacherName(value: string) {
    jspb.Message.setProto3StringField(this, 9, value);
  }


  /**
   * optional string registrant_last_name = 10;
   * @return {string}
   */
  getRegistrantLastName(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
  };


  /** @param {string} value */
  setRegistrantLastName(value: string) {
    jspb.Message.setProto3StringField(this, 10, value);
  }


  /**
   * optional string teacher_last_name = 11;
   * @return {string}
   */
  getTeacherLastName(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
  };


  /** @param {string} value */
  setTeacherLastName(value: string) {
    jspb.Message.setProto3StringField(this, 11, value);
  }


  /**
   * optional string grade = 12;
   * @return {string}
   */
  getGrade(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
  };


  /** @param {string} value */
  setGrade(value: string) {
    jspb.Message.setProto3StringField(this, 12, value);
  }


  /**
   * optional string period_name = 13;
   * @return {string}
   */
  getPeriodName(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
  };


  /** @param {string} value */
  setPeriodName(value: string) {
    jspb.Message.setProto3StringField(this, 13, value);
  }


  /**
   * optional string period_date = 14;
   * @return {string}
   */
  getPeriodDate(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
  };


  /** @param {string} value */
  setPeriodDate(value: string) {
    jspb.Message.setProto3StringField(this, 14, value);
  }


  /**
   * optional string period_start_time = 15;
   * @return {string}
   */
  getPeriodStartTime(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
  };


  /** @param {string} value */
  setPeriodStartTime(value: string) {
    jspb.Message.setProto3StringField(this, 15, value);
  }


  /**
   * optional string type = 16;
   * @return {string}
   */
  getType(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
  };


  /** @param {string} value */
  setType(value: string) {
    jspb.Message.setProto3StringField(this, 16, value);
  }


  /**
   * optional string role = 17;
   * @return {string}
   */
  getRole(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
  };


  /** @param {string} value */
  setRole(value: string) {
    jspb.Message.setProto3StringField(this, 17, value);
  }


  /**
   * optional string badge_icon_url = 18;
   * @return {string}
   */
  getBadgeIconUrl(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
  };


  /** @param {string} value */
  setBadgeIconUrl(value: string) {
    jspb.Message.setProto3StringField(this, 18, value);
  }


  /**
   * optional string period_end_time = 19;
   * @return {string}
   */
  getPeriodEndTime(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
  };


  /** @param {string} value */
  setPeriodEndTime(value: string) {
    jspb.Message.setProto3StringField(this, 19, value);
  }


  /**
   * optional minga.report_person.ReportPerson student = 20;
   * @return {?ReportPerson}
   */
  getStudent(): report_person_report_person_pb.ReportPerson {
    return /** @type{?ReportPerson} */ (
      jspb.Message.getWrapperField(this, report_person_report_person_pb.ReportPerson, 20));
  }


  /** @param {?ReportPerson|undefined} value */
  setStudent(value?: report_person_report_person_pb.ReportPerson) {
    jspb.Message.setWrapperField(this, 20, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearStudent() {
    this.setStudent(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasStudent(): boolean {
    return jspb.Message.getField(this, 20) != null;
  }


  /**
   * optional int32 id = 21;
   * @return {number}
   */
  getId(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 21, 0));
  };


  /** @param {number} value */
  setId(value: number) {
    jspb.Message.setProto3IntField(this, 21, value);
  }


  /**
   * optional string registerer_first_name = 22;
   * @return {string}
   */
  getRegistererFirstName(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 22, ""));
  };


  /** @param {string} value */
  setRegistererFirstName(value: string) {
    jspb.Message.setProto3StringField(this, 22, value);
  }


  /**
   * optional string registerer_last_name = 23;
   * @return {string}
   */
  getRegistererLastName(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 23, ""));
  };


  /** @param {string} value */
  setRegistererLastName(value: string) {
    jspb.Message.setProto3StringField(this, 23, value);
  }


  /**
   * optional string activity_location = 24;
   * @return {string}
   */
  getActivityLocation(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 24, ""));
  };


  /** @param {string} value */
  setActivityLocation(value: string) {
    jspb.Message.setProto3StringField(this, 24, value);
  }


  /**
   * optional int32 teacher_id = 25;
   * @return {number}
   */
  getTeacherId(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 25, 0));
  };


  /** @param {number} value */
  setTeacherId(value: number) {
    jspb.Message.setProto3IntField(this, 25, value);
  }


}
export namespace FlexUnregisteredInfo {
  export interface AsObject {
    firstName: string;
    lastName: string;
    studentIdNum: string;
    role: string;
    iconUrl: string;
    grade: string;
    hash: string;
    name: string;
  }
}
export class FlexUnregisteredInfo extends jspb.Message {
  static readonly displayName = "FlexUnregisteredInfo";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): FlexUnregisteredInfo.AsObject {
    return FlexUnregisteredInfo.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!FlexUnregisteredInfo} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: FlexUnregisteredInfo) {
    var f: any;
    var obj: any = {
      firstName: jspb.Message.getFieldWithDefault(msg, 1, ""),
      lastName: jspb.Message.getFieldWithDefault(msg, 2, ""),
      studentIdNum: jspb.Message.getFieldWithDefault(msg, 3, ""),
      role: jspb.Message.getFieldWithDefault(msg, 4, ""),
      iconUrl: jspb.Message.getFieldWithDefault(msg, 5, ""),
      grade: jspb.Message.getFieldWithDefault(msg, 6, ""),
      hash: jspb.Message.getFieldWithDefault(msg, 7, ""),
      name: jspb.Message.getFieldWithDefault(msg, 8, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!FlexUnregisteredInfo}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new FlexUnregisteredInfo;
    return FlexUnregisteredInfo.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!FlexUnregisteredInfo} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!FlexUnregisteredInfo}
   */
  static deserializeBinaryFromReader(msg: FlexUnregisteredInfo, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setFirstName(value1);
        break;
      case 2:
        var value2 = /** @type {string} */ (reader.readString());
        msg.setLastName(value2);
        break;
      case 3:
        var value3 = /** @type {string} */ (reader.readString());
        msg.setStudentIdNum(value3);
        break;
      case 4:
        var value4 = /** @type {string} */ (reader.readString());
        msg.setRole(value4);
        break;
      case 5:
        var value5 = /** @type {string} */ (reader.readString());
        msg.setIconUrl(value5);
        break;
      case 6:
        var value6 = /** @type {string} */ (reader.readString());
        msg.setGrade(value6);
        break;
      case 7:
        var value7 = /** @type {string} */ (reader.readString());
        msg.setHash(value7);
        break;
      case 8:
        var value8 = /** @type {string} */ (reader.readString());
        msg.setName(value8);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!FlexUnregisteredInfo} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: FlexUnregisteredInfo, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getFirstName();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
    f = message.getLastName();
    if (f.length > 0) {
      writer.writeString(
        2,
        f
      );
    }
    f = message.getStudentIdNum();
    if (f.length > 0) {
      writer.writeString(
        3,
        f
      );
    }
    f = message.getRole();
    if (f.length > 0) {
      writer.writeString(
        4,
        f
      );
    }
    f = message.getIconUrl();
    if (f.length > 0) {
      writer.writeString(
        5,
        f
      );
    }
    f = message.getGrade();
    if (f.length > 0) {
      writer.writeString(
        6,
        f
      );
    }
    f = message.getHash();
    if (f.length > 0) {
      writer.writeString(
        7,
        f
      );
    }
    f = message.getName();
    if (f.length > 0) {
      writer.writeString(
        8,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    FlexUnregisteredInfo.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string first_name = 1;
   * @return {string}
   */
  getFirstName(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setFirstName(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


  /**
   * optional string last_name = 2;
   * @return {string}
   */
  getLastName(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
  };


  /** @param {string} value */
  setLastName(value: string) {
    jspb.Message.setProto3StringField(this, 2, value);
  }


  /**
   * optional string student_id_num = 3;
   * @return {string}
   */
  getStudentIdNum(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
  };


  /** @param {string} value */
  setStudentIdNum(value: string) {
    jspb.Message.setProto3StringField(this, 3, value);
  }


  /**
   * optional string role = 4;
   * @return {string}
   */
  getRole(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
  };


  /** @param {string} value */
  setRole(value: string) {
    jspb.Message.setProto3StringField(this, 4, value);
  }


  /**
   * optional string icon_url = 5;
   * @return {string}
   */
  getIconUrl(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
  };


  /** @param {string} value */
  setIconUrl(value: string) {
    jspb.Message.setProto3StringField(this, 5, value);
  }


  /**
   * optional string grade = 6;
   * @return {string}
   */
  getGrade(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
  };


  /** @param {string} value */
  setGrade(value: string) {
    jspb.Message.setProto3StringField(this, 6, value);
  }


  /**
   * optional string hash = 7;
   * @return {string}
   */
  getHash(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
  };


  /** @param {string} value */
  setHash(value: string) {
    jspb.Message.setProto3StringField(this, 7, value);
  }


  /**
   * optional string name = 8;
   * @return {string}
   */
  getName(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
  };


  /** @param {string} value */
  setName(value: string) {
    jspb.Message.setProto3StringField(this, 8, value);
  }


}
export namespace GetFlexRegistrationReportRequest {
  export interface AsObject {
    filters?: FlexReportFilters.AsObject;
  }
}
export class GetFlexRegistrationReportRequest extends jspb.Message {
  static readonly displayName = "GetFlexRegistrationReportRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): GetFlexRegistrationReportRequest.AsObject {
    return GetFlexRegistrationReportRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!GetFlexRegistrationReportRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: GetFlexRegistrationReportRequest) {
    var f: any;
    var obj: any = {
      filters: (f = msg.getFilters()) && FlexReportFilters.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!GetFlexRegistrationReportRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new GetFlexRegistrationReportRequest;
    return GetFlexRegistrationReportRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!GetFlexRegistrationReportRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!GetFlexRegistrationReportRequest}
   */
  static deserializeBinaryFromReader(msg: GetFlexRegistrationReportRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new FlexReportFilters;
        reader.readMessage(value1,FlexReportFilters.deserializeBinaryFromReader);
        msg.setFilters(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!GetFlexRegistrationReportRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: GetFlexRegistrationReportRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getFilters();
    if (f != null) {
      writer.writeMessage(
        1,
        f,
        FlexReportFilters.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    GetFlexRegistrationReportRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional FlexReportFilters filters = 1;
   * @return {?FlexReportFilters}
   */
  getFilters(): FlexReportFilters {
    return /** @type{?FlexReportFilters} */ (
      jspb.Message.getWrapperField(this, FlexReportFilters, 1));
  }


  /** @param {?FlexReportFilters|undefined} value */
  setFilters(value?: FlexReportFilters) {
    jspb.Message.setWrapperField(this, 1, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearFilters() {
    this.setFilters(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasFilters(): boolean {
    return jspb.Message.getField(this, 1) != null;
  }


}
export namespace GetFlexRegistrationReportResponse {
  export interface AsObject {
    registrationList: FlexRegistrationInfo.AsObject[];
  }
}
export class GetFlexRegistrationReportResponse extends jspb.Message {
  static readonly displayName = "GetFlexRegistrationReportResponse";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [1];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, GetFlexRegistrationReportResponse.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): GetFlexRegistrationReportResponse.AsObject {
    return GetFlexRegistrationReportResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!GetFlexRegistrationReportResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: GetFlexRegistrationReportResponse) {
    var f: any;
    var obj: any = {
      registrationList: jspb.Message.toObjectList(msg.getRegistrationList(),
      FlexRegistrationInfo.toObject, includeInstance)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!GetFlexRegistrationReportResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new GetFlexRegistrationReportResponse;
    return GetFlexRegistrationReportResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!GetFlexRegistrationReportResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!GetFlexRegistrationReportResponse}
   */
  static deserializeBinaryFromReader(msg: GetFlexRegistrationReportResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new FlexRegistrationInfo;
        reader.readMessage(value1,FlexRegistrationInfo.deserializeBinaryFromReader);
        msg.addRegistration(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!GetFlexRegistrationReportResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: GetFlexRegistrationReportResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getRegistrationList();
    if (f.length > 0) {
      writer.writeRepeatedMessage(
        1,
        f,
        FlexRegistrationInfo.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    GetFlexRegistrationReportResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * repeated FlexRegistrationInfo registration = 1;
   * @return {!Array<!FlexRegistrationInfo>}
   */
  getRegistrationList(): FlexRegistrationInfo[] {
    return /** @type{!Array<!FlexRegistrationInfo>} */ (
      jspb.Message.getRepeatedWrapperField(this, FlexRegistrationInfo, 1));
  }


  /** @param {!Array<!FlexRegistrationInfo>} value */
  setRegistrationList(value?: FlexRegistrationInfo[]) {
    jspb.Message.setRepeatedWrapperField(this, 1, value);
  }


  /**
   * @param {!FlexRegistrationInfo=} opt_value
   * @param {number=} opt_index
   * @return {!FlexRegistrationInfo}
   */
  addRegistration(opt_value?: FlexRegistrationInfo, opt_index?: number): FlexRegistrationInfo {
    return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, FlexRegistrationInfo, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearRegistrationList() {
    this.setRegistrationList([]);
  }


}
export namespace GetPeriodUnregisteredReportRequest {
  export interface AsObject {
    filters?: FlexReportFilters.AsObject;
  }
}
export class GetPeriodUnregisteredReportRequest extends jspb.Message {
  static readonly displayName = "GetPeriodUnregisteredReportRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): GetPeriodUnregisteredReportRequest.AsObject {
    return GetPeriodUnregisteredReportRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!GetPeriodUnregisteredReportRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: GetPeriodUnregisteredReportRequest) {
    var f: any;
    var obj: any = {
      filters: (f = msg.getFilters()) && FlexReportFilters.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!GetPeriodUnregisteredReportRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new GetPeriodUnregisteredReportRequest;
    return GetPeriodUnregisteredReportRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!GetPeriodUnregisteredReportRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!GetPeriodUnregisteredReportRequest}
   */
  static deserializeBinaryFromReader(msg: GetPeriodUnregisteredReportRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new FlexReportFilters;
        reader.readMessage(value1,FlexReportFilters.deserializeBinaryFromReader);
        msg.setFilters(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!GetPeriodUnregisteredReportRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: GetPeriodUnregisteredReportRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getFilters();
    if (f != null) {
      writer.writeMessage(
        1,
        f,
        FlexReportFilters.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    GetPeriodUnregisteredReportRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional FlexReportFilters filters = 1;
   * @return {?FlexReportFilters}
   */
  getFilters(): FlexReportFilters {
    return /** @type{?FlexReportFilters} */ (
      jspb.Message.getWrapperField(this, FlexReportFilters, 1));
  }


  /** @param {?FlexReportFilters|undefined} value */
  setFilters(value?: FlexReportFilters) {
    jspb.Message.setWrapperField(this, 1, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearFilters() {
    this.setFilters(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasFilters(): boolean {
    return jspb.Message.getField(this, 1) != null;
  }


}
export namespace GetPeriodUnregisteredReportResponse {
  export interface AsObject {
    peopleList: FlexUnregisteredInfo.AsObject[];
  }
}
export class GetPeriodUnregisteredReportResponse extends jspb.Message {
  static readonly displayName = "GetPeriodUnregisteredReportResponse";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [1];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, GetPeriodUnregisteredReportResponse.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): GetPeriodUnregisteredReportResponse.AsObject {
    return GetPeriodUnregisteredReportResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!GetPeriodUnregisteredReportResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: GetPeriodUnregisteredReportResponse) {
    var f: any;
    var obj: any = {
      peopleList: jspb.Message.toObjectList(msg.getPeopleList(),
      FlexUnregisteredInfo.toObject, includeInstance)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!GetPeriodUnregisteredReportResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new GetPeriodUnregisteredReportResponse;
    return GetPeriodUnregisteredReportResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!GetPeriodUnregisteredReportResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!GetPeriodUnregisteredReportResponse}
   */
  static deserializeBinaryFromReader(msg: GetPeriodUnregisteredReportResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new FlexUnregisteredInfo;
        reader.readMessage(value1,FlexUnregisteredInfo.deserializeBinaryFromReader);
        msg.addPeople(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!GetPeriodUnregisteredReportResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: GetPeriodUnregisteredReportResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getPeopleList();
    if (f.length > 0) {
      writer.writeRepeatedMessage(
        1,
        f,
        FlexUnregisteredInfo.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    GetPeriodUnregisteredReportResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * repeated FlexUnregisteredInfo people = 1;
   * @return {!Array<!FlexUnregisteredInfo>}
   */
  getPeopleList(): FlexUnregisteredInfo[] {
    return /** @type{!Array<!FlexUnregisteredInfo>} */ (
      jspb.Message.getRepeatedWrapperField(this, FlexUnregisteredInfo, 1));
  }


  /** @param {!Array<!FlexUnregisteredInfo>} value */
  setPeopleList(value?: FlexUnregisteredInfo[]) {
    jspb.Message.setRepeatedWrapperField(this, 1, value);
  }


  /**
   * @param {!FlexUnregisteredInfo=} opt_value
   * @param {number=} opt_index
   * @return {!FlexUnregisteredInfo}
   */
  addPeople(opt_value?: FlexUnregisteredInfo, opt_index?: number): FlexUnregisteredInfo {
    return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, FlexUnregisteredInfo, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearPeopleList() {
    this.setPeopleList([]);
  }


}
export namespace ExportFlexReportRequest {
  export interface AsObject {
    filters?: FlexReportFilters.AsObject;
    type: string;
  }
}
export class ExportFlexReportRequest extends jspb.Message {
  static readonly displayName = "ExportFlexReportRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): ExportFlexReportRequest.AsObject {
    return ExportFlexReportRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!ExportFlexReportRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: ExportFlexReportRequest) {
    var f: any;
    var obj: any = {
      filters: (f = msg.getFilters()) && FlexReportFilters.toObject(includeInstance, f),
      type: jspb.Message.getFieldWithDefault(msg, 2, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!ExportFlexReportRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new ExportFlexReportRequest;
    return ExportFlexReportRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!ExportFlexReportRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!ExportFlexReportRequest}
   */
  static deserializeBinaryFromReader(msg: ExportFlexReportRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new FlexReportFilters;
        reader.readMessage(value1,FlexReportFilters.deserializeBinaryFromReader);
        msg.setFilters(value1);
        break;
      case 2:
        var value2 = /** @type {string} */ (reader.readString());
        msg.setType(value2);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!ExportFlexReportRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: ExportFlexReportRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getFilters();
    if (f != null) {
      writer.writeMessage(
        1,
        f,
        FlexReportFilters.serializeBinaryToWriter
      );
    }
    f = message.getType();
    if (f.length > 0) {
      writer.writeString(
        2,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    ExportFlexReportRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional FlexReportFilters filters = 1;
   * @return {?FlexReportFilters}
   */
  getFilters(): FlexReportFilters {
    return /** @type{?FlexReportFilters} */ (
      jspb.Message.getWrapperField(this, FlexReportFilters, 1));
  }


  /** @param {?FlexReportFilters|undefined} value */
  setFilters(value?: FlexReportFilters) {
    jspb.Message.setWrapperField(this, 1, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearFilters() {
    this.setFilters(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasFilters(): boolean {
    return jspb.Message.getField(this, 1) != null;
  }


  /**
   * optional string type = 2;
   * @return {string}
   */
  getType(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
  };


  /** @param {string} value */
  setType(value: string) {
    jspb.Message.setProto3StringField(this, 2, value);
  }


}
export namespace ExportFlexReportChunk {
  export interface AsObject {
    chunk?: (string|Uint8Array);
    filename: string;
  }
}
export class ExportFlexReportChunk extends jspb.Message {
  static readonly displayName = "ExportFlexReportChunk";
  /**
   * Oneof group definitions for this message. Each group defines the field
   * numbers belonging to that group. When of these fields' value is set, all
   * other fields in the group are cleared. During deserialization, if multiple
   * fields are encountered for a group, only the last value seen will be kept.
   * @private {!Array<!Array<number>>}
   * @const
   */
  static readonly oneofGroups_ = [[1,2]];


  /**
   * @return {ExportFlexReportChunk.InfoCase}
   */
  getInfoCase() {
    return /** @type {ExportFlexReportChunk.InfoCase} */(jspb.Message.computeOneofCase(this, ExportFlexReportChunk.oneofGroups_[0]));
  }

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, ExportFlexReportChunk.oneofGroups_);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): ExportFlexReportChunk.AsObject {
    return ExportFlexReportChunk.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!ExportFlexReportChunk} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: ExportFlexReportChunk) {
    var f: any;
    var obj: any = {
      chunk: msg.getChunk_asB64(),
      filename: jspb.Message.getFieldWithDefault(msg, 2, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!ExportFlexReportChunk}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new ExportFlexReportChunk;
    return ExportFlexReportChunk.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!ExportFlexReportChunk} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!ExportFlexReportChunk}
   */
  static deserializeBinaryFromReader(msg: ExportFlexReportChunk, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {!Uint8Array} */ (reader.readBytes());
        msg.setChunk(value1);
        break;
      case 2:
        var value2 = /** @type {string} */ (reader.readString());
        msg.setFilename(value2);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!ExportFlexReportChunk} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: ExportFlexReportChunk, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 1));
    if (f != null) {
      writer.writeBytes(
        1,
        f
      );
    }
    f = /** @type {string} */ (jspb.Message.getField(message, 2));
    if (f != null) {
      writer.writeString(
        2,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    ExportFlexReportChunk.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional bytes chunk = 1;
   * @return {!(string|Uint8Array)}
   */
  getChunk(): (string|Uint8Array) {
    return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /**
   * optional bytes chunk = 1;
   * This is a type-conversion wrapper around `getChunk()`
   * @return {string}
   */
  getChunk_asB64() {
    return /** @type {string} */ (jspb.Message.bytesAsB64(
        this.getChunk()));
  }


  /**
   * optional bytes chunk = 1;
   * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getChunk()`
   * @return {!Uint8Array}
   */
  getChunk_asU8() {
    return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
        this.getChunk()));
  }


  /** @param {!(string|Uint8Array)} value */
  setChunk(value: (string|Uint8Array)) {
    jspb.Message.setOneofField(this, 1, ExportFlexReportChunk.oneofGroups_[0], value);
  }


  /**
   * Clears the field making it undefined.
   */
  clearChunk() {
    jspb.Message.setOneofField(this, 1, ExportFlexReportChunk.oneofGroups_[0], undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasChunk(): boolean {
    return jspb.Message.getField(this, 1) != null;
  }


  /**
   * optional string filename = 2;
   * @return {string}
   */
  getFilename(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
  };


  /** @param {string} value */
  setFilename(value: string) {
    jspb.Message.setOneofField(this, 2, ExportFlexReportChunk.oneofGroups_[0], value);
  }


  /**
   * Clears the field making it undefined.
   */
  clearFilename() {
    jspb.Message.setOneofField(this, 2, ExportFlexReportChunk.oneofGroups_[0], undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasFilename(): boolean {
    return jspb.Message.getField(this, 2) != null;
  }


}
export namespace ExportFlexReportChunk {
export enum InfoCase {
  INFO_NOT_SET = 0,
  CHUNK = 1,
  FILENAME = 2,
}
} // namespace ExportFlexReportChunk
export namespace FlexActivityStats {
  export interface AsObject {
    name: string;
    assigned: number;
    registered: number;
    checkIns: number;
    totalTime: string;
    activityId: number;
  }
}
export class FlexActivityStats extends jspb.Message {
  static readonly displayName = "FlexActivityStats";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): FlexActivityStats.AsObject {
    return FlexActivityStats.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!FlexActivityStats} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: FlexActivityStats) {
    var f: any;
    var obj: any = {
      name: jspb.Message.getFieldWithDefault(msg, 1, ""),
      assigned: jspb.Message.getFieldWithDefault(msg, 2, 0),
      registered: jspb.Message.getFieldWithDefault(msg, 3, 0),
      checkIns: jspb.Message.getFieldWithDefault(msg, 4, 0),
      totalTime: jspb.Message.getFieldWithDefault(msg, 5, ""),
      activityId: jspb.Message.getFieldWithDefault(msg, 6, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!FlexActivityStats}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new FlexActivityStats;
    return FlexActivityStats.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!FlexActivityStats} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!FlexActivityStats}
   */
  static deserializeBinaryFromReader(msg: FlexActivityStats, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setName(value1);
        break;
      case 2:
        var value2 = /** @type {number} */ (reader.readInt32());
        msg.setAssigned(value2);
        break;
      case 3:
        var value3 = /** @type {number} */ (reader.readInt32());
        msg.setRegistered(value3);
        break;
      case 4:
        var value4 = /** @type {number} */ (reader.readInt32());
        msg.setCheckIns(value4);
        break;
      case 5:
        var value5 = /** @type {string} */ (reader.readString());
        msg.setTotalTime(value5);
        break;
      case 6:
        var value6 = /** @type {number} */ (reader.readInt32());
        msg.setActivityId(value6);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!FlexActivityStats} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: FlexActivityStats, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getName();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
    f = message.getAssigned();
    if (f !== 0) {
      writer.writeInt32(
        2,
        f
      );
    }
    f = message.getRegistered();
    if (f !== 0) {
      writer.writeInt32(
        3,
        f
      );
    }
    f = message.getCheckIns();
    if (f !== 0) {
      writer.writeInt32(
        4,
        f
      );
    }
    f = message.getTotalTime();
    if (f.length > 0) {
      writer.writeString(
        5,
        f
      );
    }
    f = message.getActivityId();
    if (f !== 0) {
      writer.writeInt32(
        6,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    FlexActivityStats.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string name = 1;
   * @return {string}
   */
  getName(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setName(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


  /**
   * optional int32 assigned = 2;
   * @return {number}
   */
  getAssigned(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
  };


  /** @param {number} value */
  setAssigned(value: number) {
    jspb.Message.setProto3IntField(this, 2, value);
  }


  /**
   * optional int32 registered = 3;
   * @return {number}
   */
  getRegistered(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
  };


  /** @param {number} value */
  setRegistered(value: number) {
    jspb.Message.setProto3IntField(this, 3, value);
  }


  /**
   * optional int32 check_ins = 4;
   * @return {number}
   */
  getCheckIns(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
  };


  /** @param {number} value */
  setCheckIns(value: number) {
    jspb.Message.setProto3IntField(this, 4, value);
  }


  /**
   * optional string total_time = 5;
   * @return {string}
   */
  getTotalTime(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
  };


  /** @param {string} value */
  setTotalTime(value: string) {
    jspb.Message.setProto3StringField(this, 5, value);
  }


  /**
   * optional int32 activity_id = 6;
   * @return {number}
   */
  getActivityId(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
  };


  /** @param {number} value */
  setActivityId(value: number) {
    jspb.Message.setProto3IntField(this, 6, value);
  }


}
export namespace FlexStaffStats {
  export interface AsObject {
    staff?: report_person_report_person_pb.ReportPerson.AsObject;
    activities: number;
    totalTime: string;
    assigned: number;
    registered: number;
    checkIns: number;
  }
}
export class FlexStaffStats extends jspb.Message {
  static readonly displayName = "FlexStaffStats";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): FlexStaffStats.AsObject {
    return FlexStaffStats.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!FlexStaffStats} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: FlexStaffStats) {
    var f: any;
    var obj: any = {
      staff: (f = msg.getStaff()) && report_person_report_person_pb.ReportPerson.toObject(includeInstance, f),
      activities: jspb.Message.getFieldWithDefault(msg, 2, 0),
      totalTime: jspb.Message.getFieldWithDefault(msg, 3, ""),
      assigned: jspb.Message.getFieldWithDefault(msg, 4, 0),
      registered: jspb.Message.getFieldWithDefault(msg, 5, 0),
      checkIns: jspb.Message.getFieldWithDefault(msg, 6, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!FlexStaffStats}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new FlexStaffStats;
    return FlexStaffStats.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!FlexStaffStats} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!FlexStaffStats}
   */
  static deserializeBinaryFromReader(msg: FlexStaffStats, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new report_person_report_person_pb.ReportPerson;
        reader.readMessage(value1,report_person_report_person_pb.ReportPerson.deserializeBinaryFromReader);
        msg.setStaff(value1);
        break;
      case 2:
        var value2 = /** @type {number} */ (reader.readInt32());
        msg.setActivities(value2);
        break;
      case 3:
        var value3 = /** @type {string} */ (reader.readString());
        msg.setTotalTime(value3);
        break;
      case 4:
        var value4 = /** @type {number} */ (reader.readInt32());
        msg.setAssigned(value4);
        break;
      case 5:
        var value5 = /** @type {number} */ (reader.readInt32());
        msg.setRegistered(value5);
        break;
      case 6:
        var value6 = /** @type {number} */ (reader.readInt32());
        msg.setCheckIns(value6);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!FlexStaffStats} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: FlexStaffStats, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getStaff();
    if (f != null) {
      writer.writeMessage(
        1,
        f,
        report_person_report_person_pb.ReportPerson.serializeBinaryToWriter
      );
    }
    f = message.getActivities();
    if (f !== 0) {
      writer.writeInt32(
        2,
        f
      );
    }
    f = message.getTotalTime();
    if (f.length > 0) {
      writer.writeString(
        3,
        f
      );
    }
    f = message.getAssigned();
    if (f !== 0) {
      writer.writeInt32(
        4,
        f
      );
    }
    f = message.getRegistered();
    if (f !== 0) {
      writer.writeInt32(
        5,
        f
      );
    }
    f = message.getCheckIns();
    if (f !== 0) {
      writer.writeInt32(
        6,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    FlexStaffStats.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional minga.report_person.ReportPerson staff = 1;
   * @return {?ReportPerson}
   */
  getStaff(): report_person_report_person_pb.ReportPerson {
    return /** @type{?ReportPerson} */ (
      jspb.Message.getWrapperField(this, report_person_report_person_pb.ReportPerson, 1));
  }


  /** @param {?ReportPerson|undefined} value */
  setStaff(value?: report_person_report_person_pb.ReportPerson) {
    jspb.Message.setWrapperField(this, 1, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearStaff() {
    this.setStaff(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasStaff(): boolean {
    return jspb.Message.getField(this, 1) != null;
  }


  /**
   * optional int32 activities = 2;
   * @return {number}
   */
  getActivities(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
  };


  /** @param {number} value */
  setActivities(value: number) {
    jspb.Message.setProto3IntField(this, 2, value);
  }


  /**
   * optional string total_time = 3;
   * @return {string}
   */
  getTotalTime(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
  };


  /** @param {string} value */
  setTotalTime(value: string) {
    jspb.Message.setProto3StringField(this, 3, value);
  }


  /**
   * optional int32 assigned = 4;
   * @return {number}
   */
  getAssigned(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
  };


  /** @param {number} value */
  setAssigned(value: number) {
    jspb.Message.setProto3IntField(this, 4, value);
  }


  /**
   * optional int32 registered = 5;
   * @return {number}
   */
  getRegistered(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
  };


  /** @param {number} value */
  setRegistered(value: number) {
    jspb.Message.setProto3IntField(this, 5, value);
  }


  /**
   * optional int32 check_ins = 6;
   * @return {number}
   */
  getCheckIns(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
  };


  /** @param {number} value */
  setCheckIns(value: number) {
    jspb.Message.setProto3IntField(this, 6, value);
  }


}
export namespace FlexStudentsStats {
  export interface AsObject {
    student?: report_person_report_person_pb.ReportPerson.AsObject;
    assigned: number;
    registered: number;
    checkIns: number;
    attendance: number;
    totalTime: string;
  }
}
export class FlexStudentsStats extends jspb.Message {
  static readonly displayName = "FlexStudentsStats";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): FlexStudentsStats.AsObject {
    return FlexStudentsStats.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!FlexStudentsStats} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: FlexStudentsStats) {
    var f: any;
    var obj: any = {
      student: (f = msg.getStudent()) && report_person_report_person_pb.ReportPerson.toObject(includeInstance, f),
      assigned: jspb.Message.getFieldWithDefault(msg, 2, 0),
      registered: jspb.Message.getFieldWithDefault(msg, 3, 0),
      checkIns: jspb.Message.getFieldWithDefault(msg, 4, 0),
      attendance: jspb.Message.getFieldWithDefault(msg, 5, 0),
      totalTime: jspb.Message.getFieldWithDefault(msg, 6, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!FlexStudentsStats}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new FlexStudentsStats;
    return FlexStudentsStats.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!FlexStudentsStats} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!FlexStudentsStats}
   */
  static deserializeBinaryFromReader(msg: FlexStudentsStats, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new report_person_report_person_pb.ReportPerson;
        reader.readMessage(value1,report_person_report_person_pb.ReportPerson.deserializeBinaryFromReader);
        msg.setStudent(value1);
        break;
      case 2:
        var value2 = /** @type {number} */ (reader.readInt32());
        msg.setAssigned(value2);
        break;
      case 3:
        var value3 = /** @type {number} */ (reader.readInt32());
        msg.setRegistered(value3);
        break;
      case 4:
        var value4 = /** @type {number} */ (reader.readInt32());
        msg.setCheckIns(value4);
        break;
      case 5:
        var value5 = /** @type {number} */ (reader.readInt32());
        msg.setAttendance(value5);
        break;
      case 6:
        var value6 = /** @type {string} */ (reader.readString());
        msg.setTotalTime(value6);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!FlexStudentsStats} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: FlexStudentsStats, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getStudent();
    if (f != null) {
      writer.writeMessage(
        1,
        f,
        report_person_report_person_pb.ReportPerson.serializeBinaryToWriter
      );
    }
    f = message.getAssigned();
    if (f !== 0) {
      writer.writeInt32(
        2,
        f
      );
    }
    f = message.getRegistered();
    if (f !== 0) {
      writer.writeInt32(
        3,
        f
      );
    }
    f = message.getCheckIns();
    if (f !== 0) {
      writer.writeInt32(
        4,
        f
      );
    }
    f = message.getAttendance();
    if (f !== 0) {
      writer.writeInt32(
        5,
        f
      );
    }
    f = message.getTotalTime();
    if (f.length > 0) {
      writer.writeString(
        6,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    FlexStudentsStats.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional minga.report_person.ReportPerson student = 1;
   * @return {?ReportPerson}
   */
  getStudent(): report_person_report_person_pb.ReportPerson {
    return /** @type{?ReportPerson} */ (
      jspb.Message.getWrapperField(this, report_person_report_person_pb.ReportPerson, 1));
  }


  /** @param {?ReportPerson|undefined} value */
  setStudent(value?: report_person_report_person_pb.ReportPerson) {
    jspb.Message.setWrapperField(this, 1, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearStudent() {
    this.setStudent(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasStudent(): boolean {
    return jspb.Message.getField(this, 1) != null;
  }


  /**
   * optional int32 assigned = 2;
   * @return {number}
   */
  getAssigned(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
  };


  /** @param {number} value */
  setAssigned(value: number) {
    jspb.Message.setProto3IntField(this, 2, value);
  }


  /**
   * optional int32 registered = 3;
   * @return {number}
   */
  getRegistered(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
  };


  /** @param {number} value */
  setRegistered(value: number) {
    jspb.Message.setProto3IntField(this, 3, value);
  }


  /**
   * optional int32 check_ins = 4;
   * @return {number}
   */
  getCheckIns(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
  };


  /** @param {number} value */
  setCheckIns(value: number) {
    jspb.Message.setProto3IntField(this, 4, value);
  }


  /**
   * optional int32 attendance = 5;
   * @return {number}
   */
  getAttendance(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
  };


  /** @param {number} value */
  setAttendance(value: number) {
    jspb.Message.setProto3IntField(this, 5, value);
  }


  /**
   * optional string total_time = 6;
   * @return {string}
   */
  getTotalTime(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
  };


  /** @param {string} value */
  setTotalTime(value: string) {
    jspb.Message.setProto3StringField(this, 6, value);
  }


}
