import {$enum} from 'ts-enum-util';

export enum ExitReason {
  TOO_EXPENSIVE = 'TOO_EXPENSIVE',
  DOES_NOT_FULFILL_NEED = 'DOES_NOT_FULFILL_NEED',
  HAD_APP_ISSUES = 'HAD_APP_ISSUES',
}

export namespace ExitReason {
  export function isValid(value: any): value is ExitReason {
    // According to the docs this works with string enums
    return $enum(<any>ExitReason).isValue(value);
  }
}
