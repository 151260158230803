// GENERATED CODE -- DO NOT EDIT!

import {grpc} from '@improbable-eng/grpc-web';

import * as gateway_feed_pb from '../gateway/feed_pb';
import * as common_legacy_pb from '../common/legacy_pb';
import * as common_stream_pb from '../common/stream_pb';
import * as gateway_content_views_pb from '../gateway/content_views_pb';
import * as gateway_person_view_pb from '../gateway/person_view_pb';

export class ContentFeed {
  static readonly serviceName = "ContentFeed";

  static readonly addTemporaryContent = {
    methodName: "addTemporaryContent",
    service: ContentFeed,
    requestStream: false,
    responseStream: false,
    requestType: gateway_feed_pb.ContentFeedItemView,
    responseType: gateway_feed_pb.ContentFeedItemView,
  };

  static readonly getTopOfFeed = {
    methodName: "getTopOfFeed",
    service: ContentFeed,
    requestStream: false,
    responseStream: false,
    requestType: gateway_feed_pb.TopOfFeedRequest,
    responseType: gateway_feed_pb.TopOfFeedResponse,
  };

  static readonly AddTopOfFeedText = {
    methodName: "AddTopOfFeedText",
    service: ContentFeed,
    requestStream: false,
    responseStream: true,
    requestType: gateway_feed_pb.AddTopOfFeedTextRequest,
    responseType: gateway_feed_pb.AddTopOfFeedTextResponse,
  };

  static readonly getContentFeed = {
    methodName: "getContentFeed",
    service: ContentFeed,
    requestStream: false,
    responseStream: false,
    requestType: gateway_feed_pb.GetContentFeedRequest,
    responseType: gateway_feed_pb.GetContentFeedResponse,
  };

  static readonly streamContentFeed = {
    methodName: "streamContentFeed",
    service: ContentFeed,
    requestStream: false,
    responseStream: true,
    requestType: common_stream_pb.StreamID,
    responseType: gateway_feed_pb.StreamContentFeedResponse,
  };

  static readonly streamContentFeedControl = {
    methodName: "streamContentFeedControl",
    service: ContentFeed,
    requestStream: false,
    responseStream: false,
    requestType: common_stream_pb.StreamControl,
    responseType: common_stream_pb.StreamControlResponse,
  };

}

export class ContentFeedClient {
  addTemporaryContent() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  getTopOfFeed() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  addTopOfFeedText() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  getContentFeed() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  streamContentFeed() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  streamContentFeedControl() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
}
