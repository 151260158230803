import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { image_pb } from 'libs/generated-grpc-web';

@Component({
  selector: 'mg-ftm-activity-img',
  templateUrl: './ftm-activity-img.component.html',
  styleUrls: ['./ftm-activity-img.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FtmActivityImgComponent {
  public resolvedImage: image_pb.ImageInfo | string;
  @Input() set bannerImage(image: image_pb.ImageInfo) {
    if (image) {
      this.resolvedImage = image;
    }
  }
  @Input() set imagePath(imgPath: string) {
    if (imgPath) {
      this.resolvedImage = imgPath;
    }
  }
  @Input() aspectRatio = 1;
}
