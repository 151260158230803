import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  Input,
} from '@angular/core';

import { inOutAnination } from '@app/src/app/util/animations';

@Component({
  selector: 'mg-message-box-content',
  template: '<ng-content></ng-content>',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MgMessageBox_Content {}

@Component({
  selector: 'mg-message-box',
  templateUrl: './MessageBox.component.html',
  styleUrls: ['./MessageBox.component.scss'],
  animations: [inOutAnination],
})
export class MessageBoxComponent {
  @ContentChild(MgMessageBox_Content, { static: false })
  contentSlot?: MgMessageBox_Content;
}
