import { Injectable, NgZone } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { grpc } from '@improbable-eng/grpc-web';

import {
  CreateTermRequest,
  CreateTermResponse,
  DeleteTermRequest,
  DeleteTermResponse,
  GetSisTermsRequest,
  GetSisTermsResponse,
  GetTermRequest,
  GetTermResponse,
  GetTermsRequest,
  GetTermsResponse,
  UpdateTermRequest,
  UpdateTermResponse,
} from '../term/term_pb';

import {
  Terms as __Terms,
} from '../term/term_pb_service';

@Injectable({providedIn: 'root'})
export class Terms {

  constructor(private _ngZone: NgZone) {
  }

  getTerm(request: GetTermRequest): Promise<GetTermResponse>;
  getTerm(request: GetTermRequest, metadata: grpc.Metadata): Promise<GetTermResponse>;
  getTerm(request: GetTermRequest, callback: (err: any|null, response: GetTermResponse, metadata: grpc.Metadata) => void): void;
  getTerm(request: GetTermRequest, metadata: grpc.Metadata, callback: (err: any|null, response: GetTermResponse, metadata: grpc.Metadata) => void): void;

  getTerm(request: GetTermRequest, arg1?: grpc.Metadata|((err: any|null, response: GetTermResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: GetTermResponse, metadata: grpc.Metadata) => void): Promise<GetTermResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<GetTermResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__Terms.GetTerm, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  getTerms(request: GetTermsRequest): Promise<GetTermsResponse>;
  getTerms(request: GetTermsRequest, metadata: grpc.Metadata): Promise<GetTermsResponse>;
  getTerms(request: GetTermsRequest, callback: (err: any|null, response: GetTermsResponse, metadata: grpc.Metadata) => void): void;
  getTerms(request: GetTermsRequest, metadata: grpc.Metadata, callback: (err: any|null, response: GetTermsResponse, metadata: grpc.Metadata) => void): void;

  getTerms(request: GetTermsRequest, arg1?: grpc.Metadata|((err: any|null, response: GetTermsResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: GetTermsResponse, metadata: grpc.Metadata) => void): Promise<GetTermsResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<GetTermsResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__Terms.GetTerms, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  getTermsFromSis(request: GetSisTermsRequest): Promise<GetSisTermsResponse>;
  getTermsFromSis(request: GetSisTermsRequest, metadata: grpc.Metadata): Promise<GetSisTermsResponse>;
  getTermsFromSis(request: GetSisTermsRequest, callback: (err: any|null, response: GetSisTermsResponse, metadata: grpc.Metadata) => void): void;
  getTermsFromSis(request: GetSisTermsRequest, metadata: grpc.Metadata, callback: (err: any|null, response: GetSisTermsResponse, metadata: grpc.Metadata) => void): void;

  getTermsFromSis(request: GetSisTermsRequest, arg1?: grpc.Metadata|((err: any|null, response: GetSisTermsResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: GetSisTermsResponse, metadata: grpc.Metadata) => void): Promise<GetSisTermsResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<GetSisTermsResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__Terms.GetTermsFromSis, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  createTerm(request: CreateTermRequest): Promise<CreateTermResponse>;
  createTerm(request: CreateTermRequest, metadata: grpc.Metadata): Promise<CreateTermResponse>;
  createTerm(request: CreateTermRequest, callback: (err: any|null, response: CreateTermResponse, metadata: grpc.Metadata) => void): void;
  createTerm(request: CreateTermRequest, metadata: grpc.Metadata, callback: (err: any|null, response: CreateTermResponse, metadata: grpc.Metadata) => void): void;

  createTerm(request: CreateTermRequest, arg1?: grpc.Metadata|((err: any|null, response: CreateTermResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: CreateTermResponse, metadata: grpc.Metadata) => void): Promise<CreateTermResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<CreateTermResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__Terms.CreateTerm, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  updateTerm(request: UpdateTermRequest): Promise<UpdateTermResponse>;
  updateTerm(request: UpdateTermRequest, metadata: grpc.Metadata): Promise<UpdateTermResponse>;
  updateTerm(request: UpdateTermRequest, callback: (err: any|null, response: UpdateTermResponse, metadata: grpc.Metadata) => void): void;
  updateTerm(request: UpdateTermRequest, metadata: grpc.Metadata, callback: (err: any|null, response: UpdateTermResponse, metadata: grpc.Metadata) => void): void;

  updateTerm(request: UpdateTermRequest, arg1?: grpc.Metadata|((err: any|null, response: UpdateTermResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: UpdateTermResponse, metadata: grpc.Metadata) => void): Promise<UpdateTermResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<UpdateTermResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__Terms.UpdateTerm, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  deleteTerm(request: DeleteTermRequest): Promise<DeleteTermResponse>;
  deleteTerm(request: DeleteTermRequest, metadata: grpc.Metadata): Promise<DeleteTermResponse>;
  deleteTerm(request: DeleteTermRequest, callback: (err: any|null, response: DeleteTermResponse, metadata: grpc.Metadata) => void): void;
  deleteTerm(request: DeleteTermRequest, metadata: grpc.Metadata, callback: (err: any|null, response: DeleteTermResponse, metadata: grpc.Metadata) => void): void;

  deleteTerm(request: DeleteTermRequest, arg1?: grpc.Metadata|((err: any|null, response: DeleteTermResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: DeleteTermResponse, metadata: grpc.Metadata) => void): Promise<DeleteTermResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<DeleteTermResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__Terms.DeleteTerm, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

}

