import { DefaultHallPassTypeIcons } from 'libs/domain';

export const HP_TYPE_ICON_CONFIG: Record<DefaultHallPassTypeIcons, string> = {
  [DefaultHallPassTypeIcons.ATHLETICS]:
    '/assets/hall-pass/type-icons/athletics.svg',
  [DefaultHallPassTypeIcons.FOUNTAIN]:
    '/assets/hall-pass/type-icons/fountain.svg',
  [DefaultHallPassTypeIcons.HALL]: '/assets/hall-pass/type-icons/hall.svg',
  [DefaultHallPassTypeIcons.HEALTH]: '/assets/hall-pass/type-icons/health.svg',
  [DefaultHallPassTypeIcons.LIBRARY]:
    '/assets/hall-pass/type-icons/library.svg',
  [DefaultHallPassTypeIcons.MEDIA]: '/assets/hall-pass/type-icons/media.svg',
  [DefaultHallPassTypeIcons.OFFICE]: '/assets/hall-pass/type-icons/office.svg',
  [DefaultHallPassTypeIcons.RESTROOM]:
    '/assets/hall-pass/type-icons/restroom.svg',
  [DefaultHallPassTypeIcons.TARDY]: '/assets/hall-pass/type-icons/tardy.svg',
  [DefaultHallPassTypeIcons.TRANSFER]:
    '/assets/hall-pass/type-icons/transfer.svg',
};
