import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { MgEmptyStateModule } from '@app/src/app/components/EmptyState';
import { MgStreamScrollerModule } from '@app/src/app/components/MgStreamScroller';
import { MgPagedScrollerModule } from '@app/src/app/components/mg-paged-scroller';
import { MgContentLinkModule } from '@app/src/app/content-link';
import { MgContentFeedItemModule } from '@app/src/app/elements/MgContentFeedItem';
import { MgDateSeparatorModule } from '@app/src/app/elements/MgDateSeparator';
import { MgEventShortCardModule } from '@app/src/app/elements/MgShortCard/MgEventShortCard';
import { MgSpinnerModule } from '@app/src/app/spinner';

import { EventScheduleComponent } from './EventSchedule.component';

@NgModule({
  imports: [
    // Minga dependencies
    MgSpinnerModule,
    MgEventShortCardModule,
    MgContentLinkModule,
    MgDateSeparatorModule,
    MgStreamScrollerModule,
    MgContentFeedItemModule,
    MgPagedScrollerModule,
    MgEmptyStateModule,

    // External dependencies
    CommonModule,
    RouterModule,
  ],
  declarations: [EventScheduleComponent],
  exports: [EventScheduleComponent],
})
export class EventScheduleModule {}
