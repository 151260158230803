import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { MgChallengeInviteNotificationModule } from '@app/src/app/elements/MgNotification/MgChallengeInviteNotification';

import { MgBadgeNotificationModule } from './MgBadgeNotification';
import { MgBasicNotificationModule } from './MgBasicNotification';
import { MgChallengeResponseNotificationModule } from './MgChallengeResponseNotification';
import { MgEventInviteNotificationModule } from './MgEventInviteNotification';
import { MgEventInviteNotificationElement } from './MgEventInviteNotification/MgEventInviteNotification.element';
import { MgEventNotificationModule } from './MgEventNotification';
import { MgNotificationElement } from './MgNotification.element';
import { MgPersonNotificationModule } from './MgPersonNotification';
import { MgPersonTitleNotificationModule } from './MgPersonTitleNotification';
import { MgPointsNotificationModule } from './MgPointsNotification';

export { MgEventInviteNotificationElement, MgNotificationElement };

@NgModule({
  imports: [
    // Minga dependencies
    MgBasicNotificationModule,
    MgEventNotificationModule,
    MgPersonNotificationModule,
    MgPersonTitleNotificationModule,
    MgPointsNotificationModule,
    MgEventInviteNotificationModule,
    MgChallengeInviteNotificationModule,
    MgBadgeNotificationModule,
    MgChallengeResponseNotificationModule,

    // External dependencies
    CommonModule,
  ],
  declarations: [MgNotificationElement],
  exports: [
    MgNotificationElement,
    MgBasicNotificationModule,
    MgEventNotificationModule,
    MgPersonNotificationModule,
    MgPersonTitleNotificationModule,
    MgChallengeInviteNotificationModule,
    MgEventInviteNotificationModule,
  ],
})
export class MgNotificationModule {}
