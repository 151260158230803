// GENERATED CODE -- DO NOT EDIT!

import {grpc} from '@improbable-eng/grpc-web';

import * as hall_pass_stats_internal_hall_pass_stats_internal_pb from '../hall_pass_stats_internal/hall_pass_stats_internal_pb';

export class HallPassStatsInternalService {
  static readonly serviceName = "minga.hall_pass_stats_internal.HallPassStatsInternalService";

  static readonly GetHallPassSummaryReportByType = {
    methodName: "GetHallPassSummaryReportByType",
    service: HallPassStatsInternalService,
    requestStream: false,
    responseStream: false,
    requestType: hall_pass_stats_internal_hall_pass_stats_internal_pb.InternalGetHallPassSummaryReportByTypeRequest,
    responseType: hall_pass_stats_internal_hall_pass_stats_internal_pb.InternalGetHallPassSummaryReportByTypeResponse,
  };

  static readonly GetHallPassTypeReport = {
    methodName: "GetHallPassTypeReport",
    service: HallPassStatsInternalService,
    requestStream: false,
    responseStream: false,
    requestType: hall_pass_stats_internal_hall_pass_stats_internal_pb.InternalGetHallPassTypeReportRequest,
    responseType: hall_pass_stats_internal_hall_pass_stats_internal_pb.InternalGetHallPassTypeReportResponse,
  };

  static readonly GetHallPassTopUsers = {
    methodName: "GetHallPassTopUsers",
    service: HallPassStatsInternalService,
    requestStream: false,
    responseStream: false,
    requestType: hall_pass_stats_internal_hall_pass_stats_internal_pb.InternalGetHallPassTopUsersRequest,
    responseType: hall_pass_stats_internal_hall_pass_stats_internal_pb.InternalGetHallPassTopUsersResponse,
  };

  static readonly GetHallPassTopCreators = {
    methodName: "GetHallPassTopCreators",
    service: HallPassStatsInternalService,
    requestStream: false,
    responseStream: false,
    requestType: hall_pass_stats_internal_hall_pass_stats_internal_pb.InternalGetHallPassTopCreatorsRequest,
    responseType: hall_pass_stats_internal_hall_pass_stats_internal_pb.InternalGetHallPassTopCreatorsResponse,
  };

  static readonly GetHallPassExpiredTimeReport = {
    methodName: "GetHallPassExpiredTimeReport",
    service: HallPassStatsInternalService,
    requestStream: false,
    responseStream: false,
    requestType: hall_pass_stats_internal_hall_pass_stats_internal_pb.InternalGetHallPassExpiredTimeReportRequest,
    responseType: hall_pass_stats_internal_hall_pass_stats_internal_pb.InternalGetHallPassExpiredTimeReportResponse,
  };

}

export class HallPassStatsInternalServiceClient {
  getHallPassSummaryReportByType() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  getHallPassTypeReport() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  getHallPassTopUsers() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  getHallPassTopCreators() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  getHallPassExpiredTimeReport() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
}
