import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { IHallPassSummaryResult } from 'libs/domain';
import { HpmReportsFilter } from 'libs/domain';
import { ReportTypes } from 'libs/domain';
import { TemplateColumnKeys, HallPassReportColumns } from 'libs/shared';

import { ReportDatasourceService } from '@app/src/app/components/manager-report/services/report-datasource.service';

import { HpmReportsService } from '../services';

@Injectable()
export class HpmReportsTypesService extends ReportDatasourceService<IHallPassSummaryResult> {
  historyInfo = {
    filter: HpmReportsFilter.HALLPASS_TYPE,
    url: '/hall-pass/reports/' + ReportTypes.HALL_PASS_HISTORY,
  };
  summaryInfo = {
    filter: HpmReportsFilter.HALLPASS_TYPE,
    url: '/hall-pass/reports/' + ReportTypes.HALL_PASS_SUMMARY,
  };

  /** Service Constructor */
  constructor(private _hpmReports: HpmReportsService, _router: Router) {
    super(_router, _hpmReports, HpmReportsTypesService.name);
    const displayCol = [
      {
        header: '',
        key: TemplateColumnKeys.SUMMARY,
        columnClasses: ['w-16'],
      },
      {
        header: '',
        key: TemplateColumnKeys.HISTORY,
        columnClasses: ['w-16'],
      },
    ];
    super.setDisplayColumns(HallPassReportColumns.TYPES, displayCol);
  }

  async fetch(offset: number, limit: number) {
    return await this._hpmReports.getTypes(offset, limit, this._currentSort);
  }
}
