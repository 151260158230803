<div class="sheet-grouped-select">
  <div class="search">
    <mg-form-text-input
      placeholder="Search"
      iconRight="mg-search"
      [isClearable]="clearable"
      [control]="searchControl">
    </mg-form-text-input>
  </div>

  <div class="body">
    <ng-container
      *ngIf="(activeCategory$ | async)?.value"
      [ngTemplateOutlet]="backTpl"
      [ngTemplateOutletContext]="{ label: (activeCategory$ | async)?.label }">
    </ng-container>

    <ng-container *ngIf="(lazyLoading$ | async) === false; else loading">
      <ng-container *ngIf="(options$ | async)?.length > 0; else noResults">
        <cdk-virtual-scroll-viewport
          class="option-viewport"
          itemSize="50">
          <div *cdkVirtualFor="let option of options$ | async">
            <ng-container
              [ngTemplateOutlet]="
                (selectType$ | async) === SELECT_TYPE.OPTION
                  ? itemOption
                  : categoryOption
              "
              [ngTemplateOutletContext]="{ option: option }">
            </ng-container>
          </div>
        </cdk-virtual-scroll-viewport>
      </ng-container>
    </ng-container>
  </div>
</div>

<ng-template
  #itemOption
  let-option="option">
  <div class="option-item">
    <button
      class="btn-reset btn btn-item"
      [attr.data-analytics]="
        'grouped-select-option-' + option?.category?.label + '-' + option?.value
          | slugify
      "
      [attr.data-test]="
        'grouped-select-option-' + option?.category?.label + '-' + option?.value
          | slugify
      "
      fxLayout="row"
      fxLayoutAlign="start center"
      fxLayoutGap="8px"
      matRipple
      [matRippleColor]="'rgba(0, 0, 0, 0.02)'"
      (click)="optionClicked(option)">
      <!-- Multiple selection -->
      <mg-form-checkbox
        *ngIf="multiple; else radioTemplate"
        [checked]="selected.has(option.value)"
        [disabled]="option?.disabled">
        <mg-text
          variant="body-md"
          [numberOfLines]="2">
          {{ option?.optionLabel || option?.label }}
        </mg-text>
      </mg-form-checkbox>
      <!-- Single selection -->
      <ng-template #radioTemplate>
        <mat-radio-button
          [value]="option.value"
          [checked]="selected.has(option.value)"
          [disabled]="option?.disabled">
          <mg-text
            variant="body-md"
            [numberOfLines]="2">
            {{ option?.optionLabel || option?.label }}
          </mg-text>
        </mat-radio-button>
      </ng-template>
    </button>
  </div>
</ng-template>

<ng-template
  #categoryOption
  let-option="option">
  <div class="option-category">
    <button
      class="btn-reset btn btn-category"
      matRipple
      [matRippleColor]="'rgba(0, 0, 0, 0.02)'"
      [matRippleDisabled]="option?.disabled"
      [attr.data-analytics]="
        'grouped-select-category-' + option?.label | slugify
      "
      [attr.data-test]="'grouped-select-category-' + option?.label | slugify"
      (click)="setCategory(option)"
      type="button">
      <div
        fxLayout="row"
        fxLayoutAlign="space-between center">
        <div
          fxLayout="row"
          fxLayoutAlign="start center"
          fxLayoutGap="8px">
          <mg-icon
            *ngIf="option?.icon"
            [iconName]="option?.icon"
            [icon-namespace]="option?.iconNamespace"
            [style.color]="option?.iconColor"></mg-icon>
          <mg-text
            variant="body-md"
            [numberOfLines]="2">
            {{ option?.label }}
          </mg-text>
        </div>
        <mg-icon iconName="mg-chevron-right"></mg-icon>
      </div>
    </button>
  </div>
</ng-template>

<ng-template #noResults>
  <div
    fxLayout="column"
    fxLayoutAlign="center center"
    fxLayoutGap="8px"
    class="no-results">
    <mg-text
      color="surface-alt"
      variant="body-md"
      [numberOfLines]="1">
      No results found</mg-text
    >
    <mg-btn
      *ngIf="searchControl.value"
      variant="text"
      (pressed)="resetSearch()">
      Reset Search
    </mg-btn>
  </div>
</ng-template>

<ng-template #loading>
  <div
    *ngIf="lazyLoading$ | async; else empty"
    class="loading">
    <mg-spinner
      variant="rainbow"
      [diameter]="20"
      [thickness]="2">
    </mg-spinner>
  </div>
</ng-template>

<ng-template
  #backTpl
  let-label="label">
  <button
    class="btn-category-back btn-reset"
    (click)="back()"
    matRipple
    [matRippleColor]="'rgba(0, 0, 0, 0.02)'"
    [matRippleDisabled]="option?.disabled"
    type="button">
    <div
      fxLayout="row"
      fxLayoutAlign="start center">
      <mg-icon iconName="mg-chevron-left"></mg-icon>
      <mg-text
        variant="body-sm-secondary"
        color="surface-alt">
        {{ label }}
      </mg-text>
    </div>
  </button>
</ng-template>
