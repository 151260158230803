// GENERATED CODE -- DO NOT EDIT!

import {grpc} from '@improbable-eng/grpc-web';

import * as gateway_feed_post_pb from '../gateway/feed_post_pb';
import * as common_legacy_pb from '../common/legacy_pb';
import * as common_delta_pb from '../common/delta_pb';
import * as gateway_mention_pb from '../gateway/mention_pb';
import * as gateway_moderation_pb from '../gateway/moderation_pb';

export class FeedPostManager {
  static readonly serviceName = "FeedPostManager";

  static readonly create = {
    methodName: "create",
    service: FeedPostManager,
    requestStream: false,
    responseStream: false,
    requestType: gateway_feed_post_pb.CreateRequest,
    responseType: gateway_feed_post_pb.CreateResponse,
  };

  static readonly update = {
    methodName: "update",
    service: FeedPostManager,
    requestStream: false,
    responseStream: false,
    requestType: gateway_feed_post_pb.UpdateRequest,
    responseType: gateway_feed_post_pb.UpdateResponse,
  };

  static readonly delete = {
    methodName: "delete",
    service: FeedPostManager,
    requestStream: false,
    responseStream: false,
    requestType: gateway_feed_post_pb.DeleteRequest,
    responseType: gateway_feed_post_pb.DeleteResponse,
  };

}

export class FeedPostManagerClient {
  create() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  update() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  delete() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
}
