import {
  BannerType,
  IBannerDetails,
  IBannerHeadlineOptions,
} from 'libs/domain';
import { gateway, image_pb } from 'libs/generated-grpc-ts';
import { $enum } from 'ts-enum-util';

import { IBannerCategoryMapper, IBannerHeadlineOptionsMessageMapper } from '.';

export namespace IBannerDetailsMessageMapper {
  export function toBannerLibraryMessage(
    bannerDetails: IBannerDetails,
  ): gateway.banner_pb.Banner {
    const banner = new gateway.banner_pb.Banner();
    if (bannerDetails.hash) {
      banner.setBannerHash(bannerDetails.hash);
    }
    banner.setArchived(bannerDetails.archived);
    if (bannerDetails.headlineOptions) {
      banner.setHeadlineOptions(
        IBannerHeadlineOptionsMessageMapper.toBannerLibraryMessage(
          bannerDetails.headlineOptions,
        ),
      );
    }
    if (bannerDetails.keywords) {
      banner.setKeywordList(bannerDetails.keywords);
    }
    if (bannerDetails.categories) {
      const catMsgs: gateway.banner_pb.Banner.Category[] = [];
      for (let category of bannerDetails.categories) {
        catMsgs.push(
          IBannerCategoryMapper.bannerCategorySqlEnumToMsgEnum(category),
        );
      }

      banner.setCategoryList(catMsgs);
    }

    if (bannerDetails.asset) {
      const imageInfo = new image_pb.ImageInfo();
      const sizeMap = imageInfo.getSizeMap();
      const paths = bannerDetails.asset.sizeMap;
      for (let sizeKey in paths) {
        const imgSize = new image_pb.ImageSize();
        const sizePath = paths[sizeKey];
        imgSize.setPath(sizePath.path);
        imgSize.setWidth(sizePath.width);
        imgSize.setHeight(sizePath.height);

        sizeMap.set(sizeKey, imgSize);
      }
      banner.setImage(imageInfo);
    }

    if (bannerDetails.type) {
      banner.setType(bannerDetails.type);
    }

    return banner;
  }

  export function fromBannerLibraryAdd(
    bannerMsg: gateway.banner_pb.BannerLibraryAdd,
  ): IBannerDetails {
    const categoryList = bannerMsg.getCategoryList().map(category => {
      return IBannerCategoryMapper.bannerCategoryMsgEnumToSqlEnum(category);
    });
    const keywordList = bannerMsg.getKeywordList();
    const asset = bannerMsg.getAsset();
    let headlineOptions: IBannerHeadlineOptions | null = null;
    if (bannerMsg.getHeadlineOptions()) {
      headlineOptions =
        IBannerHeadlineOptionsMessageMapper.fromBannerLibraryMessage(
          bannerMsg.getHeadlineOptions(),
        );
    }

    let filename = asset.replace('raw/', '');
    const type = bannerMsg.getType();
    const bannerType = $enum(BannerType).asValueOrDefault(
      type,
      BannerType.EVENT,
    );
    const reference = bannerMsg.getReference();

    return {
      keywords: keywordList,
      categories: categoryList,
      headlineOptions: headlineOptions,
      archived: false,
      type: bannerType,
      asset: {
        filename: filename,
        sizeMap: {
          raw: {
            path: asset,
            width: 100, // temp place holders
            height: 100,
          },
        },
      },
      reference,
    };
  }

  export function fromBannerLibraryUpdate(
    bannerMsg: gateway.banner_pb.BannerLibraryUpdate,
    previousBanner: IBannerDetails,
  ): IBannerDetails {
    const categoryList = bannerMsg.getCategoryList().map(category => {
      return IBannerCategoryMapper.bannerCategoryMsgEnumToSqlEnum(category);
    });

    previousBanner.categories = categoryList;

    const keywordList = bannerMsg.getKeywordList();
    previousBanner.keywords = keywordList;

    const type = bannerMsg.getType();
    const bannerType = $enum(BannerType).asValueOrDefault(
      type,
      BannerType.EVENT,
    );
    previousBanner.type = bannerType;

    const asset = bannerMsg.getNewAsset().getValue();
    if (asset != previousBanner.asset?.filename) {
      let filename = asset.replace('raw/', '');
      previousBanner.asset = {
        filename: filename,
        sizeMap: {
          raw: {
            path: asset,
            width: 100, // temp place holders
            height: 100,
          },
        },
      };
    }
    const headlineOptions = bannerMsg.getHeadlineOptions();
    if (headlineOptions) {
      previousBanner.headlineOptions =
        IBannerHeadlineOptionsMessageMapper.fromBannerLibraryMessage(
          headlineOptions,
        );
    }

    return previousBanner;
  }
}
