import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
} from '@angular/core';

import { Store } from '@ngrx/store';
import { gateway } from 'libs/generated-grpc-web';
import { Observable, ReplaySubject } from 'rxjs';
import { distinctUntilChanged, map, takeUntil } from 'rxjs/operators';

import { IEmptyStateIcon } from '@app/src/app/components/EmptyState';
import { AuthInfoService } from '@app/src/app/minimal/services/AuthInfo';
import { ContentEvents } from '@app/src/app/minimal/services/ContentEvents';
import { PermissionsService } from '@app/src/app/permissions';
import { ContentFeedService } from '@app/src/app/services/ContentFeed';
import { MingaSettingsService } from '@app/src/app/store/Minga/services';
import { ShowStudentIdAction } from '@app/src/app/store/root/rootActions';

import { ViewIdService } from '@shared/components/view-id/services/view-id.service';

import { HomeFeedMessage } from './constants';
import { HomeFeedDataSource } from './services';

@Component({
  selector: 'mg-home-feed',
  templateUrl: './home-feed.component.html',
  styleUrls: ['./home-feed.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [HomeFeedDataSource],
})
export class HomeFeedComponent implements AfterViewInit, OnDestroy {
  // Constants
  public readonly MSG = HomeFeedMessage;

  // Clean up
  private readonly _destroyedSubject = new ReplaySubject<void>(1);

  // State
  searchText = '';
  filter: any = {
    deletedOnly: false,
  };
  communityEnabled = false;
  idEnabled = false;
  hallpassEnabled = false;
  mingaRoleType$: Observable<string>;

  ContentFeed = gateway.feed_ng_grpc_pb.ContentFeed;
  hasToken$: Observable<boolean>;

  emptyStateIcon: IEmptyStateIcon = {
    icon: 'post',
    text: 'Add',
  };

  readonly isCommunityEnabled$: Observable<boolean>;
  readonly isStudentIdEnabled$: Observable<boolean>;
  readonly isHallpassEnabled$: Observable<boolean>;

  constructor(
    public viewId: ViewIdService,
    public contentFeed: gateway.feed_ng_grpc_pb.ContentFeed,
    public ds: HomeFeedDataSource,
    private _contentFeedService: ContentFeedService,
    private _store: Store<any>,
    private _permissions: PermissionsService,
    private _contentEvents: ContentEvents,
    private _authInfoService: AuthInfoService,
    private _settingService: MingaSettingsService,
  ) {
    this.ds = ds;
    this.isCommunityEnabled$ = this._settingService.isCommunityModuleEnabled();
    this.isCommunityEnabled$
      .pipe(distinctUntilChanged(), takeUntil(this._destroyedSubject))
      .subscribe(value => {
        this.communityEnabled = value;
      });
    this.isStudentIdEnabled$ = this._settingService.isIdModuleEnabled();
    this.isStudentIdEnabled$
      .pipe(distinctUntilChanged(), takeUntil(this._destroyedSubject))
      .subscribe(value => {
        this.idEnabled = value;
      });
    this.isHallpassEnabled$ = this._settingService.isHallPassModuleEnabled();
    this.isHallpassEnabled$
      .pipe(distinctUntilChanged(), takeUntil(this._destroyedSubject))
      .subscribe(value => {
        this.hallpassEnabled = value;
      });
    this.mingaRoleType$ = this._permissions.mingaRoleType$;
    this._contentEvents.onContentDeleted
      .pipe(takeUntil(this._destroyedSubject))
      .subscribe(() => {
        this.ds.restartStream();
      });
    this._contentEvents.onContentFeedsUpdate
      .pipe(takeUntil(this._destroyedSubject))
      .subscribe(() => {
        this.ds.restartStream();
      });
    // wait until we know we have a token before trying to load the feed.
    this.hasToken$ = this._authInfoService.authInfo$.pipe(map(info => !!info));
  }

  ngAfterViewInit() {
    // show student id when feed has finished being routed to
    this._store.dispatch(new ShowStudentIdAction());
  }

  ngOnDestroy(): void {
    this._destroyedSubject.next();
    this._destroyedSubject.complete();
  }

  feedItemClick(e: any) {
    this._contentFeedService.feedItemClick(e);
  }
}
