import { Component, OnInit } from '@angular/core';

import * as day from 'dayjs';
import { MingaSupportTier } from 'libs/util';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { first } from 'rxjs/operators';

import { AppConfigService } from '@app/src/app/minimal/services/AppConfig';
import { AuthInfoService } from '@app/src/app/minimal/services/AuthInfo';
import { MingaManagerService } from '@app/src/app/services/MingaManager';
import { RevHeroService } from '@app/src/app/services/RevHero';
import { SmsMinga } from '@app/src/app/services/SmsMinga';
import { SystemAlertSnackBarService } from '@app/src/app/shared/components/system-alert-snackbar';
import {
  MingaSettingsService,
  MingaStoreFacadeService,
} from '@app/src/app/store/Minga/services';

import { ModalOverlayService } from '@shared/components/modal-overlay';

import { MmSubscriptionFeatureModalComponent } from './components/mm-subscription-feature-modal/mm-subscription-feature-modal.component';
import { MmSubscriptionUpgradeModalComponent } from './components/mm-subscription-upgrade-modal/mm-subscription-upgrade-modal.component';
import {
  BEHAVIOR_CONFIG,
  CHECKIN_CONFIG,
  FLEXTIME_CONFIG,
  HALLPASS_CONFIG,
  MingaManagerSubscriptionMessage,
  SUPPORT_TIER_MESSAGES,
  SubscriptionInfoType,
  subscriptionInfoConfig,
} from './constants';
import { moduleInfoConfig } from './constants/module-config.constants';
import { PromotionalModalConfig, SubscriptionInfo } from './types';

@Component({
  selector: 'mg-mm-subscription',
  templateUrl: './mm-subscription.component.html',
  styleUrls: ['./mm-subscription.component.scss'],
})
export class MmSubscriptionComponent implements OnInit {
  // Constants
  public readonly MESSAGES = MingaManagerSubscriptionMessage;
  public readonly SUPPORT_TIER_MESSAGES = SUPPORT_TIER_MESSAGES;
  public readonly SUB_INFO_TYPE = SubscriptionInfoType;
  public readonly MINGA_SUPPORT_TIER = MingaSupportTier;
  private readonly _REV_HERO_SCRIPT_URL =
    'https://assets.revenuehero.io/scheduler.min.js';
  private readonly _REV_HERO_ROUTER_ID =
    this._appConfig.getValue('revHeroRouterId');
  private readonly _HUBSPOT_FORM_ID =
    'hsForm_1959317e-9d5f-4c08-8886-c6c3b667dc7e';

  // Subjects and Observables
  private _subInfoSubject = new BehaviorSubject<SubscriptionInfo[] | []>([]);
  public readonly subInfo$ = this._subInfoSubject.asObservable();
  public readonly moduleInfo$ = of(moduleInfoConfig);
  public hasDisabledModule$: Observable<boolean>;
  public readonly minga$ = this._mingaStore.getMingaAsObservable();

  constructor(
    public mingaSettings: MingaSettingsService,
    private _mingaService: MingaManagerService,
    private _smsService: SmsMinga,
    private _modalOverlay: ModalOverlayService,
    private _mingaStore: MingaStoreFacadeService,
    private _revHeroService: RevHeroService,
    private _authInfo: AuthInfoService,
    private _snackBar: SystemAlertSnackBarService,
    private _appConfig: AppConfigService,
  ) {}

  async ngOnInit() {
    this._setupSubscriptionConfig();

    this.moduleInfo$.subscribe(async modules => {
      const moduleDisabledStates = await Promise.all(
        modules.map(async module => {
          const isEnabled = await this.mingaSettings.getModuleEnabled(
            module.moduleToggleKey,
          );
          return isEnabled === false;
        }),
      );
      this.hasDisabledModule$ = of(moduleDisabledStates.some(state => state));
    });
  }

  public openUpgradeRequestModal() {
    this._revHeroService
      .loadScript(this._REV_HERO_SCRIPT_URL)
      .pipe(first())
      .subscribe(() => {
        const person = this._authInfo.authPerson;
        const firstName = person.firstName;
        const lastName = person.lastName;
        const email = person.email;

        // @ts-ignore - RevenueHero is loaded dynamically from the above service call
        const hero = new RevenueHero({
          routerId: this._REV_HERO_ROUTER_ID,
          showLoader: true,
          requireConfirmation: true,
        });
        hero.schedule(this._HUBSPOT_FORM_ID);

        hero
          .submit({
            firstname: firstName,
            lastname: lastName,
            email: email,
          })
          .then(data => {
            hero.dialog.open(data);
          })
          .catch(error => {
            this._snackBar.error('An error occurred. Please try again later.');
            console.log(error);
          });
      });
  }

  public isSMSCountNearMax(info: SubscriptionInfo): boolean {
    if (!info.value || !info.maxValue) return false;
    const count = parseInt(info.value, 10);
    const max = parseInt(info.maxValue, 10);
    return count / max >= 0.8;
  }

  private async _setupSubscriptionConfig() {
    const subConfig = [...subscriptionInfoConfig];
    const mingaSubscription = (
      await this._mingaService.getUserMingaAccountInfo()
    ).dashboardInfo;
    const smsSubscription = await this._smsService.getDetails();

    const startDateObject = mingaSubscription.subscriptionStartDate;
    const startDate = startDateObject
      ? day()
          .year(startDateObject.year)
          .month(startDateObject.month - 1)
          .date(startDateObject.day)
          .format('MMM D, YYYY')
      : 'N/A';
    const endDateObject = mingaSubscription.subscriptionEndDate;
    const endDate = endDateObject
      ? day()
          .year(endDateObject.year)
          .month(endDateObject.month - 1)
          .date(endDateObject.day)
          .format('MMM D, YYYY')
      : 'N/A';

    subConfig.forEach(config => {
      switch (config.type) {
        case SubscriptionInfoType.STUDENT_TIER:
          config.value = mingaSubscription.billableCount.toString();
          config.maxValue = mingaSubscription.mingaSize.toString();
          break;
        case SubscriptionInfoType.START_DATE:
          config.value = startDate;
          break;
        case SubscriptionInfoType.END_DATE:
          config.value = endDate;
          break;
        case SubscriptionInfoType.SMS_MESSAGES:
          config.value = smsSubscription.currentSendCount.toString();
          config.maxValue = smsSubscription.maxSendCount.toString();
          break;
        default:
          throw new Error('Invalid subscription detail type');
      }
    });

    this._subInfoSubject.next(subConfig);
  }

  public openHallPassModal(): void {
    this._modalOverlay.open<PromotionalModalConfig>(
      MmSubscriptionFeatureModalComponent,
      {
        data: HALLPASS_CONFIG,
      },
    );
  }

  public openBehaviorModal(): void {
    this._modalOverlay.open<PromotionalModalConfig>(
      MmSubscriptionFeatureModalComponent,
      {
        data: BEHAVIOR_CONFIG,
      },
    );
  }

  public openCheckInModal(): void {
    this._modalOverlay.open<PromotionalModalConfig>(
      MmSubscriptionFeatureModalComponent,
      {
        data: CHECKIN_CONFIG,
      },
    );
  }

  public openFlexTimeModal(): void {
    this._modalOverlay.open<PromotionalModalConfig>(
      MmSubscriptionFeatureModalComponent,
      {
        data: FLEXTIME_CONFIG,
      },
    );
  }
}
