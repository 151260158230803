import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';

import { INotificationType } from 'libs/domain';
import { gateway } from 'libs/generated-grpc-web';

import { GroupRoutes } from '@app/src/app/groups/groups.types';
import { GroupsFacadeService } from '@app/src/app/groups/services';
import { MessageDialogComponent } from '@app/src/app/minimal/components/MessageDialog';
import { BadgeNotificationHandlerService } from '@app/src/app/notifications/badge';
import { PointsNotificationHandlerService } from '@app/src/app/notifications/points';
import { ContentState } from '@app/src/app/services/ContentState';
import { ReportService } from '@app/src/app/services/Report';

import { HomeRoute } from '@modules/home/constants';

import { ViewIdService } from '@shared/components/view-id/services/view-id.service';

import { BehaviorsNotificationHandlerService } from '../behaviors';
import { FlexTimeNotificationHandlerService } from '../flextime/FlexTimeNotificationHandler.service';
import { HallPassNotificationHandlerService } from '../hallpasses/HallPassNotificationHandler.service';
import { RosteringNotificationHandlerService } from '../rostering';

@Injectable({ providedIn: 'root' })
export class NotificationClickHandler {
  constructor(
    private _router: Router,
    private _dialog: MatDialog,
    private _reportService: ReportService,
    private _groupsFacade: GroupsFacadeService,
    private _contentState: ContentState,
    private _pointsNotificationHandler: PointsNotificationHandlerService,
    private _badgeNotificationHandler: BadgeNotificationHandlerService,
    private _behaviorNotificationHandler: BehaviorsNotificationHandlerService,
    private _hallPassNotificationHandler: HallPassNotificationHandlerService,
    private _flexTimeNotificationHandler: FlexTimeNotificationHandlerService,
    private _rosteringNotificationHandler: RosteringNotificationHandlerService,
    private _viewId: ViewIdService,
  ) {}

  clickNotification(
    notification: gateway.notification_pb.Notification.AsObject,
  ) {
    const notificationType = notification.notificationType;
    if (
      this._pointsNotificationHandler.isPointsNotification(notificationType)
    ) {
      return this._pointsNotificationHandler.handleDisplayPointNotification(
        notification,
      );
    } else if (
      this._badgeNotificationHandler.isBadgeNotification(notificationType)
    ) {
      return this._badgeNotificationHandler.handleDisplayBadgeNotification(
        notification,
      );
    } else if (
      this._hallPassNotificationHandler.isHallPassNotification(notificationType)
    ) {
      return this._hallPassNotificationHandler.handleNotification(notification);
    } else if (
      this._flexTimeNotificationHandler.isFlexTimeNotification(notificationType)
    ) {
      return this._flexTimeNotificationHandler.handleFlexTimeNotification(
        notification,
      );
    } else if (
      this._rosteringNotificationHandler.isRosteringNotification(
        notificationType,
      )
    ) {
      return this._rosteringNotificationHandler.handleNotification();
    }
    if (notification.actionAlertContent) {
      return this._clickAlertContent(notification);
    }
    if (notification.actionOpenContent) {
      return this._clickOpenContent(notification.actionOpenContent);
    }
    if (notification.actionGalleryPhoto) {
      return this._clickGalleryPhoto(notification.actionGalleryPhoto);
    }
    if (notification.actionReportedContent) {
      return this._clickReportedContent(notification.actionReportedContent);
    }
    if (notification.actionGroupContent) {
      return this._clickGroupContent(notification.actionGroupContent);
    }
    if (notification.actionOpenChallengeResponse) {
      return this._clickChallengeResponse(
        notification,
        notification.actionOpenChallengeResponse,
      );
    }
    if (notification.actionOpenStudentId) {
      return this._clickStudentId();
    }
    if (notification.actionOpenBehavior) {
      return this._clickBehavior(notification);
    }
    return this._clickNotificationDefault();
  }

  private _clickNotificationDefault() {
    this._router.navigate([
      '/notifications',
      { outlets: { o: null, search: null } },
    ]);
  }

  private _clickAlertContent(
    notification: gateway.notification_pb.Notification.AsObject,
  ) {
    const dialogOptions = {
      data: {
        title: notification.title,
        text: notification.body,
        translate: false,
        'mg-icon': 'broadcast',
        iconColor: 'white',
        iconBackgound: '#de1717',
      },
      panelClass: 'mg-overflow-dialog',
    };

    this._dialog.open(MessageDialogComponent, dialogOptions);
  }

  private _clickOpenContent(
    action: gateway.notification_pb.Notification.ActionOpenContent.AsObject,
  ) {
    const contentContextHash = action.contentContextHash;
    this._router.navigate([
      '',
      { outlets: { o: ['view', contentContextHash] } },
    ]);
  }

  private _clickGalleryPhoto(
    action: gateway.notification_pb.Notification.ActionGalleryPhoto.AsObject,
  ) {
    const galleryPhotoHash = action.galleryPhotoHash;
    // go to gallery page to view photo
    this._router.navigate([HomeRoute.GALLERY]);
  }

  private _clickReportedContent(
    action: gateway.notification_pb.Notification.ActionReportedContent.AsObject,
  ) {
    const contextHash = action.contentContextHash;
    const galleryPhotoUuid = action.galleryPhotoUuid;
    if (contextHash || galleryPhotoUuid) {
      // mark this context hash as reported, so that the resolve option
      // appears in the context menu for this content.
      this._reportService.setReportedUgc({ contextHash, galleryPhotoUuid });

      if (contextHash) {
        this._router.navigate(['', { outlets: { o: ['view', contextHash] } }]);
      } else if (galleryPhotoUuid) {
        this._router.navigate([
          '',
          { outlets: { o: ['gallery', galleryPhotoUuid] } },
        ]);
      }
    }
  }

  private async _clickGroupContent(
    action: gateway.notification_pb.Notification.ActionGroupContent.AsObject,
  ) {
    const groupHash = action.groupHash;
    switch (action.action) {
      case gateway.notification_pb.Notification.ActionGroupContent.GroupAction
        .GROUP_MEMBERLIST:
        this._groupsFacade.dispatchLoadGroupDetails(groupHash);
        this._groupsFacade.setCurrentGroupHash(groupHash);
        this._router.navigate(
          [
            '/',
            GroupRoutes.ROOT,
            GroupRoutes.VIEW,
            groupHash,
            GroupRoutes.GROUP_FEED,
          ],
          {
            state: { viewMembers: true },
          },
        );
        break;
      case gateway.notification_pb.Notification.ActionGroupContent.GroupAction
        .GROUP_CONTENT:
      default:
        // go to the group page
        this._router.navigate([
          '/',
          GroupRoutes.ROOT,
          GroupRoutes.VIEW,
          groupHash,
          GroupRoutes.GROUP_FEED,
        ]);
        break;
    }
  }

  private _clickChallengeResponse(
    notification: gateway.notification_pb.Notification.AsObject,
    action: gateway.notification_pb.Notification.ActionOpenChallengeResponse.AsObject,
  ) {
    const challengeHash = action.challengeContextHash;
    const responseHash = action.responseContextHash;
    const isResponseDenied =
      notification.notificationType ===
      INotificationType.CHALLENGE_RESPONSE_DENIED;

    // a declined challenge response notification shouldn't open an overlay
    if (isResponseDenied) {
      return;
    }

    if (challengeHash) {
      if (responseHash) {
        this._contentState.setStateValue(
          challengeHash,
          'responseContextHash',
          responseHash,
        );
      }

      this._router.navigate(['', { outlets: { o: ['view', challengeHash] } }]);
    }
  }

  private _clickStudentId() {
    this._router.navigate([
      '',
      { outlets: { o: [this._viewId.getIdOverlayRoute()] } },
    ]);
  }

  private _clickBehavior(
    notification: gateway.notification_pb.Notification.AsObject,
  ) {
    this._behaviorNotificationHandler.handleBehaviorNotification(notification);
  }
}
