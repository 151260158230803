import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Resolve,
  RouterStateSnapshot,
} from '@angular/router';

import { of } from 'rxjs';

import { EventsFacadeService } from '@app/src/app/events/services';
import { GroupsFacadeService } from '@app/src/app/groups/services';

import { IGalleryResolvedData } from '../types';
import { EventGalleryService } from './event-gallery.service';
import { GroupGalleryService } from './group-gallery.service';
import { MingaGalleryService } from './minga-gallery.service';

@Injectable()
export class GalleryDataResolver implements Resolve<any> {
  constructor(
    public mingaService: MingaGalleryService,
    public eventService: EventGalleryService,
    public groupService: GroupGalleryService,
    public eventsFacade: EventsFacadeService,
    public groupsFacade: GroupsFacadeService,
  ) {}

  async resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Promise<IGalleryResolvedData> {
    const eventContextHash = route.params.eventContextHash || '';
    const groupHash = route.params.groupHash || '';
    const parentHashes = await this.groupsFacade.getParentGroupHashes();

    if (eventContextHash) {
      await this.eventService.setEventFromContextHash(eventContextHash);
      const eventContent$ =
        this.eventsFacade.getEventByContextHash(eventContextHash);

      return {
        eventContent$,
        group$: of(null),
        galleryService: this.eventService,
      };
    } else if (groupHash) {
      await this.groupService.setGroupFromHash(groupHash);
      const group$ = this.groupsFacade.getGroup(groupHash);
      return {
        group$,
        galleryService: this.groupService,
        eventContent$: of(undefined),
      };
    } else if (parentHashes && parentHashes.length > 0) {
      await this.groupService.setGroupFromHash(parentHashes[0]);
      const group$ = this.groupsFacade.getGroup(parentHashes[0]);
      return {
        group$,
        galleryService: this.groupService,
        eventContent$: of(undefined),
      };
    } else {
      return {
        galleryService: this.mingaService,
        group$: of(null),
        eventContent$: of(undefined),
      };
    }
  }
}
