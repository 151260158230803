// GENERATED CODE -- DO NOT EDIT!

import {grpc} from '@improbable-eng/grpc-web';

import * as messaging_messaging_pb from '../messaging/messaging_pb';
import * as common_stream_pb from '../common/stream_pb';
import * as image_image_pb from '../image/image_pb';

export class Messaging {
  static readonly serviceName = "minga.Messaging";

  static readonly StartConversation = {
    methodName: "StartConversation",
    service: Messaging,
    requestStream: false,
    responseStream: false,
    requestType: messaging_messaging_pb.MessagingStartConversationRequest,
    responseType: messaging_messaging_pb.MessagingStartConversationResponse,
  };

  static readonly SendMessage = {
    methodName: "SendMessage",
    service: Messaging,
    requestStream: false,
    responseStream: false,
    requestType: messaging_messaging_pb.MessagingSendMessageRequest,
    responseType: messaging_messaging_pb.MessagingSendMessageResponse,
  };

  static readonly MarkAsRead = {
    methodName: "MarkAsRead",
    service: Messaging,
    requestStream: false,
    responseStream: false,
    requestType: messaging_messaging_pb.MessagingMarkAsReadRequest,
    responseType: messaging_messaging_pb.MessagingMarkAsReadResponse,
  };

  static readonly GetConversation = {
    methodName: "GetConversation",
    service: Messaging,
    requestStream: false,
    responseStream: false,
    requestType: messaging_messaging_pb.MessagingGetConversationRequest,
    responseType: messaging_messaging_pb.MessagingGetConversationResponse,
  };

  static readonly GetConversations = {
    methodName: "GetConversations",
    service: Messaging,
    requestStream: false,
    responseStream: true,
    requestType: messaging_messaging_pb.MessagingGetConversationsRequest,
    responseType: messaging_messaging_pb.MessagingGetConversationsResponse,
  };

  static readonly GetAllConversations = {
    methodName: "GetAllConversations",
    service: Messaging,
    requestStream: false,
    responseStream: true,
    requestType: messaging_messaging_pb.MessagingGetConversationRequest,
    responseType: messaging_messaging_pb.MessagingGetConversationsResponse,
  };

  static readonly GetMessages = {
    methodName: "GetMessages",
    service: Messaging,
    requestStream: false,
    responseStream: true,
    requestType: messaging_messaging_pb.MessagingGetMessagesRequest,
    responseType: messaging_messaging_pb.MessagingGetMessagesResponse,
  };

  static readonly CheckForAnyUnread = {
    methodName: "CheckForAnyUnread",
    service: Messaging,
    requestStream: false,
    responseStream: false,
    requestType: messaging_messaging_pb.MessagingCheckUnreadRequest,
    responseType: messaging_messaging_pb.MessagingCheckUnreadResponse,
  };

  static readonly StreamConversations = {
    methodName: "StreamConversations",
    service: Messaging,
    requestStream: false,
    responseStream: true,
    requestType: common_stream_pb.StreamID,
    responseType: messaging_messaging_pb.MessagingStreamConversationsResponse,
  };

  static readonly StreamConversationsControl = {
    methodName: "StreamConversationsControl",
    service: Messaging,
    requestStream: false,
    responseStream: false,
    requestType: common_stream_pb.StreamControl,
    responseType: common_stream_pb.StreamControlResponse,
  };

}

export class MessagingClient {
  startConversation() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  sendMessage() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  markAsRead() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  getConversation() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  getConversations() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  getAllConversations() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  getMessages() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  checkForAnyUnread() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  streamConversations() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  streamConversationsControl() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
}
