// source: gateway/file.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

import * as jspb from 'google-protobuf';
import * as image_image_pb from '../image/image_pb';
import * as gateway_person_view_pb from '../gateway/person_view_pb';
export namespace FileInfo {
  export interface AsObject {
    assetPath: string;
    url: string;
    name: string;
  }
}
export class FileInfo extends jspb.Message {
  static readonly displayName = "FileInfo";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): FileInfo.AsObject {
    return FileInfo.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!FileInfo} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: FileInfo) {
    var f: any;
    var obj: any = {
      assetPath: jspb.Message.getFieldWithDefault(msg, 1, ""),
      url: jspb.Message.getFieldWithDefault(msg, 2, ""),
      name: jspb.Message.getFieldWithDefault(msg, 3, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!FileInfo}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new FileInfo;
    return FileInfo.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!FileInfo} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!FileInfo}
   */
  static deserializeBinaryFromReader(msg: FileInfo, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setAssetPath(value1);
        break;
      case 2:
        var value2 = /** @type {string} */ (reader.readString());
        msg.setUrl(value2);
        break;
      case 3:
        var value3 = /** @type {string} */ (reader.readString());
        msg.setName(value3);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!FileInfo} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: FileInfo, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getAssetPath();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
    f = message.getUrl();
    if (f.length > 0) {
      writer.writeString(
        2,
        f
      );
    }
    f = message.getName();
    if (f.length > 0) {
      writer.writeString(
        3,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    FileInfo.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string asset_path = 1;
   * @return {string}
   */
  getAssetPath(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setAssetPath(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


  /**
   * optional string url = 2;
   * @return {string}
   */
  getUrl(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
  };


  /** @param {string} value */
  setUrl(value: string) {
    jspb.Message.setProto3StringField(this, 2, value);
  }


  /**
   * optional string name = 3;
   * @return {string}
   */
  getName(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
  };


  /** @param {string} value */
  setName(value: string) {
    jspb.Message.setProto3StringField(this, 3, value);
  }


}
export namespace FileDetails {
  export interface AsObject {
    image?: image_image_pb.ImageInfo.AsObject;
    filePath: string;
    name: string;
    fileExtension: string;
    url: string;
    hash: string;
    timestamp: number;
    mimeType: string;
    authorPersonView?: gateway_person_view_pb.PersonViewMinimal.AsObject;
  }
}
export class FileDetails extends jspb.Message {
  static readonly displayName = "FileDetails";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): FileDetails.AsObject {
    return FileDetails.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!FileDetails} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: FileDetails) {
    var f: any;
    var obj: any = {
      image: (f = msg.getImage()) && image_image_pb.ImageInfo.toObject(includeInstance, f),
      filePath: jspb.Message.getFieldWithDefault(msg, 2, ""),
      name: jspb.Message.getFieldWithDefault(msg, 3, ""),
      fileExtension: jspb.Message.getFieldWithDefault(msg, 4, ""),
      url: jspb.Message.getFieldWithDefault(msg, 5, ""),
      hash: jspb.Message.getFieldWithDefault(msg, 6, ""),
      timestamp: jspb.Message.getFieldWithDefault(msg, 7, 0),
      mimeType: jspb.Message.getFieldWithDefault(msg, 8, ""),
      authorPersonView: (f = msg.getAuthorPersonView()) && gateway_person_view_pb.PersonViewMinimal.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!FileDetails}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new FileDetails;
    return FileDetails.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!FileDetails} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!FileDetails}
   */
  static deserializeBinaryFromReader(msg: FileDetails, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new image_image_pb.ImageInfo;
        reader.readMessage(value1,image_image_pb.ImageInfo.deserializeBinaryFromReader);
        msg.setImage(value1);
        break;
      case 2:
        var value2 = /** @type {string} */ (reader.readString());
        msg.setFilePath(value2);
        break;
      case 3:
        var value3 = /** @type {string} */ (reader.readString());
        msg.setName(value3);
        break;
      case 4:
        var value4 = /** @type {string} */ (reader.readString());
        msg.setFileExtension(value4);
        break;
      case 5:
        var value5 = /** @type {string} */ (reader.readString());
        msg.setUrl(value5);
        break;
      case 6:
        var value6 = /** @type {string} */ (reader.readString());
        msg.setHash(value6);
        break;
      case 7:
        var value7 = /** @type {number} */ (reader.readInt64());
        msg.setTimestamp(value7);
        break;
      case 8:
        var value8 = /** @type {string} */ (reader.readString());
        msg.setMimeType(value8);
        break;
      case 9:
        var value9 = new gateway_person_view_pb.PersonViewMinimal;
        reader.readMessage(value9,gateway_person_view_pb.PersonViewMinimal.deserializeBinaryFromReader);
        msg.setAuthorPersonView(value9);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!FileDetails} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: FileDetails, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getImage();
    if (f != null) {
      writer.writeMessage(
        1,
        f,
        image_image_pb.ImageInfo.serializeBinaryToWriter
      );
    }
    f = message.getFilePath();
    if (f.length > 0) {
      writer.writeString(
        2,
        f
      );
    }
    f = message.getName();
    if (f.length > 0) {
      writer.writeString(
        3,
        f
      );
    }
    f = message.getFileExtension();
    if (f.length > 0) {
      writer.writeString(
        4,
        f
      );
    }
    f = message.getUrl();
    if (f.length > 0) {
      writer.writeString(
        5,
        f
      );
    }
    f = message.getHash();
    if (f.length > 0) {
      writer.writeString(
        6,
        f
      );
    }
    f = message.getTimestamp();
    if (f !== 0) {
      writer.writeInt64(
        7,
        f
      );
    }
    f = message.getMimeType();
    if (f.length > 0) {
      writer.writeString(
        8,
        f
      );
    }
    f = message.getAuthorPersonView();
    if (f != null) {
      writer.writeMessage(
        9,
        f,
        gateway_person_view_pb.PersonViewMinimal.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    FileDetails.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional ImageInfo image = 1;
   * @return {?ImageInfo}
   */
  getImage(): image_image_pb.ImageInfo {
    return /** @type{?ImageInfo} */ (
      jspb.Message.getWrapperField(this, image_image_pb.ImageInfo, 1));
  }


  /** @param {?ImageInfo|undefined} value */
  setImage(value?: image_image_pb.ImageInfo) {
    jspb.Message.setWrapperField(this, 1, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearImage() {
    this.setImage(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasImage(): boolean {
    return jspb.Message.getField(this, 1) != null;
  }


  /**
   * optional string file_path = 2;
   * @return {string}
   */
  getFilePath(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
  };


  /** @param {string} value */
  setFilePath(value: string) {
    jspb.Message.setProto3StringField(this, 2, value);
  }


  /**
   * optional string name = 3;
   * @return {string}
   */
  getName(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
  };


  /** @param {string} value */
  setName(value: string) {
    jspb.Message.setProto3StringField(this, 3, value);
  }


  /**
   * optional string file_extension = 4;
   * @return {string}
   */
  getFileExtension(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
  };


  /** @param {string} value */
  setFileExtension(value: string) {
    jspb.Message.setProto3StringField(this, 4, value);
  }


  /**
   * optional string url = 5;
   * @return {string}
   */
  getUrl(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
  };


  /** @param {string} value */
  setUrl(value: string) {
    jspb.Message.setProto3StringField(this, 5, value);
  }


  /**
   * optional string hash = 6;
   * @return {string}
   */
  getHash(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
  };


  /** @param {string} value */
  setHash(value: string) {
    jspb.Message.setProto3StringField(this, 6, value);
  }


  /**
   * optional int64 timestamp = 7;
   * @return {number}
   */
  getTimestamp(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
  };


  /** @param {number} value */
  setTimestamp(value: number) {
    jspb.Message.setProto3IntField(this, 7, value);
  }


  /**
   * optional string mime_type = 8;
   * @return {string}
   */
  getMimeType(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
  };


  /** @param {string} value */
  setMimeType(value: string) {
    jspb.Message.setProto3StringField(this, 8, value);
  }


  /**
   * optional PersonViewMinimal author_person_view = 9;
   * @return {?PersonViewMinimal}
   */
  getAuthorPersonView(): gateway_person_view_pb.PersonViewMinimal {
    return /** @type{?PersonViewMinimal} */ (
      jspb.Message.getWrapperField(this, gateway_person_view_pb.PersonViewMinimal, 9));
  }


  /** @param {?PersonViewMinimal|undefined} value */
  setAuthorPersonView(value?: gateway_person_view_pb.PersonViewMinimal) {
    jspb.Message.setWrapperField(this, 9, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearAuthorPersonView() {
    this.setAuthorPersonView(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasAuthorPersonView(): boolean {
    return jspb.Message.getField(this, 9) != null;
  }


}
