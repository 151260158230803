import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  ContentChildren,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { MatTable } from '@angular/material/table';

import { ColumnInfo, DateColumnKeys, TimeColumnKeys } from 'libs/shared';
import {
  BehaviorSubject,
  Observable,
  ReplaySubject,
  combineLatest,
} from 'rxjs';
import { map, take, takeUntil, tap } from 'rxjs/operators';
import { $enum } from 'ts-enum-util';

import {
  ColumnUpdate,
  ReportDatasourceService,
} from '@app/src/app/components/manager-report/services/report-datasource.service';

import { MediaService } from '@shared/services/media';
import { ReportActionService } from '@shared/services/reports';

import { PaginatorComponent } from '../paginator';
import { ReportTableColumnTemplateComponent } from './components/columns/rt-column-template.component';
import { ReportTableMessage } from './constants/report-table.constants';
import {
  ReportTableFilterInfo,
  ReportTableSelectableData,
  StatusReportManager,
} from './types/report-table.types';

/** The master toggle can be in one of three states. */
type ItemsSelectedState = 'none' | 'some' | 'all';

@Component({
  selector: 'mg-report-table',
  templateUrl: './report-table.component.html',
  styleUrls: ['./report-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReportTableComponent implements OnInit, AfterViewInit, OnDestroy {
  /** Children */
  @ContentChildren(ReportTableColumnTemplateComponent)
  insertedCols: QueryList<ReportTableColumnTemplateComponent>;
  @ViewChild(MatTable, { static: true }) table: MatTable<any>;
  @ViewChild(PaginatorComponent) paginator: PaginatorComponent;
  @ContentChild('actionBar', { read: TemplateRef })
  actionBarTemplate: TemplateRef<any>;

  /** Constants */
  public readonly MSG = ReportTableMessage;

  /** General subjects */
  private readonly _destroyedSubject = new ReplaySubject<void>(1);

  /** Columns meta data */
  private readonly _columnsMetaDataSubject = new BehaviorSubject<ColumnInfo[]>(
    [],
  );
  public readonly columnsMetaData$ =
    this._columnsMetaDataSubject.asObservable();

  /** Displayed columns */
  private readonly _displayedColumnsSubject = new BehaviorSubject<string[]>([]);
  public readonly displayedColumns$ =
    this._displayedColumnsSubject.asObservable();

  /** Active Sortable Column */
  private _activeSortColumnSubj = new BehaviorSubject<string>(null);
  public readonly activeSortColumn$ = this._activeSortColumnSubj.asObservable();

  /** Table data */
  public data$: Observable<any[]>;
  public hasData$: Observable<boolean>;

  /** Selection */
  public showActionBar$: Observable<boolean>;
  public itemsSelected$: Observable<ItemsSelectedState>;

  /** Table wrapper height */
  private readonly _wrapperMaxHeightSubject = new BehaviorSubject<any>(null);
  public readonly wrapperMaxHeight$ =
    this._wrapperMaxHeightSubject.asObservable();

  /** Inputs */
  @Input() noResultsMessage: string = ReportTableMessage.NO_RESULTS_SUBTITLE;
  @Input() selectable: boolean;
  @Input() dataSourceService: ReportDatasourceService<any>;
  @Input() filterInfo?: ReportTableFilterInfo;
  @Input() actionService: ReportActionService<any>;
  @Input() selectionCount: number;
  @Input() selectionMessage: string;
  @Input() showPaginator = true;
  @Input() pageSizeOptions: number[] = [100, 150, 200];
  @Input() statusReference: StatusReportManager;
  @Input() spaceAfterContent: boolean;
  @Input() disableMaxHeight: boolean;
  @Input() sortable = true;
  @Input() visible = true;

  /** Outputs */
  @Output() cellClick = new EventEmitter<ReportTableSelectableData>();

  /** Date and Time Columns */
  public dateColumns = $enum(DateColumnKeys);
  public timeColumns = $enum(TimeColumnKeys);

  /** Component Constructor */
  constructor(private _media: MediaService, private _elRef: ElementRef) {}

  ngOnInit(): void {
    this._connectData();
    this._initActionService();
  }

  ngAfterViewInit(): void {
    this.dataSourceService.setPaginator(this.paginator);
    this.dataSourceService.columnsChanged$
      .pipe(takeUntil(this._destroyedSubject))
      .subscribe(changed => {
        if (changed?.table || changed?.keys) this._handleColumns(changed);
      });
    this._addTableColumnDefs();
    this._setUpSelectionObservables();

    this._media.breakpoint$
      .pipe(takeUntil(this._destroyedSubject))
      .subscribe(bp => {
        // the setTimeout is necessary for the height calculations to be correct
        setTimeout(() => {
          if (!this.disableMaxHeight && !['xsmall', 'small'].includes(bp)) {
            // @TODO this needs to be refactored. If the table is low on the page we get a negative value
            this._wrapperMaxHeightSubject.next({
              maxHeight: `calc(100vh - ${
                this._elRef.nativeElement.offsetTop + 151
              }px)`,
            });
          } else {
            this._wrapperMaxHeightSubject.next({ maxHeight: 'none' });
          }
        }, 50);
      });

    this.data$
      .pipe(takeUntil(this._destroyedSubject))
      .subscribe(_ => this.table.renderRows());
  }

  ngOnDestroy(): void {
    this._destroyedSubject.next();
    this._destroyedSubject.complete();
    this._columnsMetaDataSubject.complete();
    this._displayedColumnsSubject.complete();
    this._wrapperMaxHeightSubject.complete();
  }

  public selectAll() {
    this.dataSourceService.items$
      .pipe(takeUntil(this._destroyedSubject), take(1))
      .subscribe(items => this.actionService.selectAll(items));
  }

  public masterToggle() {
    this.dataSourceService.items$
      .pipe(takeUntil(this._destroyedSubject), take(1))
      .subscribe(items => this.actionService.masterToggle(items));
  }

  public selectActiveColumn(column) {
    if (column.sort) {
      this._activeSortColumnSubj.next(column.key);
    }
  }

  public cellClickEvent(key: string, value: unknown) {
    if (typeof value === 'string' || typeof value === 'number') {
      this.cellClick.emit({ key, value });
    }
  }

  public clearSelection() {
    this.actionService.clearSelection();
  }

  private _connectData() {
    if (!this.dataSourceService) return;
    this.data$ = this.dataSourceService.items$.pipe(
      takeUntil(this._destroyedSubject),
      map(items => {
        const filteredItems = this.filterInfo
          ? items.filter(item => this.filterInfo.filterFn(item))
          : items;

        if (!this.showPaginator && this.actionService) {
          this.actionService.setTotalSelectable(filteredItems.length);
        }
        return filteredItems;
      }),
    );
    this.hasData$ = combineLatest([
      this.dataSourceService.tableLoading$,
      this.data$,
    ]).pipe(
      takeUntil(this._destroyedSubject),
      map(([loading, items]) => !loading && items.length > 0),
    );
  }

  private _setUpSelectionObservables() {
    if (!this.actionService) return;

    const toSelection = ([selectionCount, items]) => {
      if (selectionCount === 0) {
        return 'none';
      } else if (selectionCount === items.length) {
        return 'all';
      } else {
        return 'some';
      }
    };

    this.itemsSelected$ = combineLatest([
      this.actionService.selectionCount$,
      this.dataSourceService.items$,
    ]).pipe(takeUntil(this._destroyedSubject), map(toSelection));
  }

  private _initActionService() {
    if (!this.actionService) return;
    this.showActionBar$ = this.actionService.selectionCount$.pipe(
      takeUntil(this._destroyedSubject),
      map(count => count > 0),
      tap(isActive => {
        this._wrapperMaxHeightSubject.next({
          maxHeight: `calc(100vh - ${
            this._elRef.nativeElement.offsetTop + (isActive ? 202 : 151)
          }px)`,
        });
      }),
    );
  }

  private _addTableColumnDefs() {
    if (!this.dataSourceService) return;
    this._handleColumns({ table: true, keys: true });
    this.insertedCols.forEach(col => {
      if (col.columnDef && col.columnDef.name) {
        this.table.addColumnDef(col.columnDef);
      }
    });
  }

  private _handleColumns(update: ColumnUpdate) {
    if (update.table) {
      this._columnsMetaDataSubject.next(
        this.dataSourceService.getDisplayColumns(),
      );
    }
    if (update.keys) {
      const keys = this.dataSourceService.getDisplayKeys();
      if (this.selectable) keys.unshift('report-table-select-col');
      this._displayedColumnsSubject.next(keys);
    }
  }
}
