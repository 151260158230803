import { PbisCategoryData, PbisNameCategory } from 'libs/domain';

export enum PbisCategory {
  PRAISE = 0,
  GUIDANCE = 1,
}

export const SUCCESS_DIALOG_CONFIG: Record<
  PbisNameCategory,
  {
    message: string;
    imagePath?: string;
    iconName?: string;
  }
> = {
  [PbisNameCategory.PRAISE]: {
    message: 'Success! Praise Assigned',
    imagePath: '/assets/teacher-tools/praise-icon.svg',
  },
  [PbisNameCategory.GUIDANCE]: {
    message: 'Success! Guidance Assigned',
    imagePath: '/assets/teacher-tools/behavior-icon.svg',
  },
  [PbisNameCategory.CONSEQUENCE]: {
    message: 'Success! Consequence Assigned',
    imagePath: '/assets/teacher-tools/consequence-icon.svg',
  },
};

export const PbisCategories: PbisCategoryData[] = [
  {
    id: PbisCategory.PRAISE,
    name: 'Praise',
    color: '#91BCAD',
  },
  {
    id: PbisCategory.GUIDANCE,
    name: 'Guidance',
    color: '#DB6578',
  },
];

export const PbisCategoriesObj = {
  [PbisCategory.PRAISE]: {
    name: 'Praise',
    color: '#91BCAD',
  },
  [PbisCategory.GUIDANCE]: {
    name: 'Guidance',
    color: '#DB6578',
  },
};
