import { day } from 'libs/day';
import { TermOptions } from 'libs/domain';
import { IBellSchedule } from 'libs/domain';
import { Period as IPeriod } from 'libs/domain';
import { bell_schedule_pb as proto, period_pb } from 'libs/generated-grpc-ts';

import { dateTimeMessageToDayjsObject, dayJsObjectToDateTimeMsg } from '../';

export const toProto = (bellSchedule: IBellSchedule): proto.IBellSchedule => {
  const msg = new proto.IBellSchedule();
  msg.setId(bellSchedule.id);
  msg.setName(bellSchedule.name);
  if (bellSchedule.days) msg.setDaysList(bellSchedule.days);
  if (bellSchedule.overrideDates?.size) {
    const overrideDates: proto.OverrideDates[] = [];
    bellSchedule.overrideDates.forEach((dates, termId) => {
      const overrideDate = new proto.OverrideDates();
      overrideDate.setTermId(termId);
      overrideDate.setDatesList(dates.map(d => dayJsObjectToDateTimeMsg(d)));
      overrideDates.push(overrideDate);
    });
    msg.setOverrideDatesList(overrideDates);
  }
  if (bellSchedule.periods) {
    const periodMsgs: period_pb.Period[] = [];
    bellSchedule.periods.forEach(period => {
      const periodMsg = new period_pb.Period();
      if (period.id) periodMsg.setId(period.id);
      periodMsg.setShortCode(period.shortCode);
      periodMsg.setPeriodName(period.periodName);
      periodMsg.setStartTime(period.startTime);
      periodMsg.setEndTime(period.endTime);

      periodMsgs.push(periodMsg);
    });

    msg.setPeriodsList(periodMsgs);
  }
  if (bellSchedule.terms?.length) {
    const termMsgs: proto.Term[] = [];
    const termIds: number[] = [];
    bellSchedule.terms.forEach(term => {
      const termMsg = new proto.Term();
      termMsg.setId(term.id);
      termMsg.setTitle(term.title);
      termMsgs.push(termMsg);
      termIds.push(term.id);
    });
    msg.setTermsList(termMsgs);
    msg.setTermIdsList(termIds);
  }

  return msg;
};

export const fromProto = (msg: proto.IBellSchedule): IBellSchedule => {
  const bellSchedule: IBellSchedule = {
    id: msg.getId(),
    name: msg.getName(),
    periods: [],
    days: [],
    terms: [],
    termIds: msg.getTermIdsList(),
  };
  if (msg.getPeriodsList()) {
    const periodList: IPeriod[] = [];
    if (msg.getPeriodsList().length > 0) {
      for (const period of msg.getPeriodsList()) {
        const periodD: IPeriod = {
          id: period.getId(),
          shortCode: period.getShortCode(),
          periodName: period.getPeriodName(),
          startTime: period.getStartTime(),
          endTime: period.getEndTime(),
        };
        periodList.push(periodD);
      }
    }

    bellSchedule.periods = periodList;
  }
  if (msg.getDaysList()) bellSchedule.days = msg.getDaysList();

  if (msg.getTermsList()) {
    const termList: TermOptions[] = [];
    if (msg.getTermsList().length > 0) {
      for (const term of msg.getTermsList()) {
        const termD: TermOptions = {
          id: term.getId(),
          title: term.getTitle(),
        };
        termList.push(termD);
      }
    }
    bellSchedule.terms = termList;
  }

  if (msg.getOverrideDatesList()?.length) {
    const overrideDates = new Map<number, day.Dayjs[]>();
    msg.getOverrideDatesList().forEach(dates => {
      overrideDates.set(
        dates.getTermId(),
        dates.getDatesList().map(d => dateTimeMessageToDayjsObject(d)),
      );
    });
    bellSchedule.overrideDates = overrideDates;
  }

  return bellSchedule;
};
