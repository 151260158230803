import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';

import { map } from 'rxjs/operators';

import { AppConfigService } from '@app/src/app/minimal/services/AppConfig';
import { PermissionsService } from '@app/src/app/permissions';
import { MingaSettingsService } from '@app/src/app/store/Minga/services';

import { NavigationPermissionService } from '@shared/services/navigation/navigation-permission.service';
import { AccessRestrictionsValidator } from '@shared/utils/access-restrictions-validator';

@Injectable()
export class DeviceGuard implements CanActivate {
  constructor(
    private _router: Router,
    private _settings: MingaSettingsService,
    private _permissions: PermissionsService,
    private _navPermissions: NavigationPermissionService,
    private _appConfig: AppConfigService,
  ) {}

  canActivate(route: ActivatedRouteSnapshot, _state: RouterStateSnapshot) {
    if (!route.data?.accessRestrictions?.device) return true;
    const validator = new AccessRestrictionsValidator(
      this._permissions,
      this._settings,
      this._navPermissions,
      this._appConfig,
    );
    return validator
      .checkDeviceRestrictions(route.data.accessRestrictions.device)
      .pipe(map(isAllowed => isAllowed || this._router.createUrlTree([`/`])));
  }
}
