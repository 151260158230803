import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';

import * as _ from 'lodash';
import { gateway } from 'libs/generated-grpc-web';
import { Subscription } from 'rxjs';

import {
  FileListComponent,
  FileListStyleEnum,
} from '@app/src/app/components/FileList';
import {
  DeleteConfirmationDialogComponent,
  IDeleteConfirmationOptions,
} from '@app/src/app/dialog';
import { FileDownloaderService, FileUploadManager } from '@app/src/app/file';
import { IFileDownloadStatus } from '@app/src/app/file/public_interfaces';
import {
  ContentEvents,
  IFileUpdateEvent,
} from '@app/src/app/minimal/services/ContentEvents';
import { IMingaFile } from '@app/src/app/models/MingaFiles';
import { MingaManagerService } from '@app/src/app/services/MingaManager';

import { SystemAlertSnackBarService } from '@shared/components/system-alert-snackbar';

import { FileInputDisplay } from '../Input/FileInput';

export interface IFileListDeleteEventInfo {
  file: gateway.file_pb.FileDetails.AsObject;
  index: number;
}

@Component({
  selector: 'mg-file-list-container',
  templateUrl: './FileListContainer.component.html',
  styleUrls: ['./FileListContainer.component.scss'],
})
export class FileListContainerComponent implements OnDestroy {
  fileDownloadSubscription: Subscription = new Subscription();
  downloadingFile: boolean = false;
  loadingFiles: boolean = true;
  _files: IMingaFile[] = [];

  hasCreatePermission: boolean = false;
  hasDeletePermission: boolean = false;
  cancelText: string = 'Cancel';
  fileUpdateSubscription: Subscription;

  @Input()
  enableFileManagement: boolean = false;

  @Input()
  enableCreateURL: boolean = false;

  @Output()
  fileDelete = new EventEmitter<IFileListDeleteEventInfo>();

  @Input()
  groupHash?: string;

  @Input()
  listStyle: FileListStyleEnum = FileListStyleEnum.LIST;

  @Input()
  set files(newFiles: IMingaFile[]) {
    this._files = newFiles;
    this.loadingFiles = false;
    this.cdr.markForCheck();
  }

  get files() {
    return this._files;
  }

  @ViewChild(FileListComponent)
  fileListComponent: FileListComponent;

  constructor(
    private mingaManager: MingaManagerService,
    private _systemAlertSnackBar: SystemAlertSnackBarService,
    private fileDownloader: FileDownloaderService,
    private fileUploadManager: FileUploadManager,
    private router: Router,
    private dialog: MatDialog,
    private cdr: ChangeDetectorRef,
    private contentEvents: ContentEvents,
  ) {
    this.fileDownloadSubscription =
      this.fileDownloader.onDownloadStatusUpdate.subscribe(ev => {
        this.onFileDownloadUpdate(ev);
      });

    this.fileUpdateSubscription = this.contentEvents.onFileUpdate.subscribe(
      ev => {
        this.onFileUpdate(ev);
      },
    );
  }

  ngOnDestroy() {
    this.fileDownloadSubscription.unsubscribe();
    this.fileUpdateSubscription.unsubscribe();
  }

  onFileChange(event: File[]) {
    if (this.groupHash) {
      const pendingFileKey = `groupFiles_${this.groupHash}`;
      this.fileUploadManager.addPendingFiles(pendingFileKey, event);
      this.router.navigate([
        '',
        { outlets: { o: ['groups', 'file~upload', this.groupHash] } },
      ]);
    } else {
      const pendingFileKey = `mingaFiles`;
      this.fileUploadManager.addPendingFiles(pendingFileKey, event);
      this.router.navigate(['', { outlets: { o: ['files~upload'] } }]);
    }
  }

  async onUploadUrl() {
    if (this.groupHash) {
      await this.router.navigate([
        '',
        { outlets: { o: ['groups', 'url~upload', this.groupHash] } },
      ]);
    } else {
      throw new Error(`Minga url upload unimplemented`);
    }
  }

  async onFileUpdate(ev: IFileUpdateEvent) {
    // update if it's the right group or when is minga files and no group hash
    if (
      (this.groupHash && this.groupHash == ev.groupHash) ||
      (!this.groupHash && ev.mingaFileUpdate)
    ) {
      // update files with more recent data
      this._files = ev.files;
      this.cdr.detectChanges();
    }
  }

  async onFileDownload(file: gateway.file_pb.FileDetails.AsObject) {
    this.fileDownloader.downloadFromFileDetails(file);
  }

  onFileDownloadUpdate(ev: IFileDownloadStatus) {
    for (let groupFile of this._files) {
      if (groupFile.status && groupFile.status.fileKey == ev.fileKey) {
        groupFile.status = ev;

        if (ev.error) {
          this.downloadingFile = false;
        } else if (ev.progress && ev.progress < 100 && !ev.complete) {
          this.downloadingFile = true;
        } else {
          this.downloadingFile = false;
        }

        // update reference for detecting changes
        this._files = _.clone(this._files);

        // notify that there's a change
        this.cdr.detectChanges();
        break;
      }
    }
  }

  onFileOpen(index: number) {
    const mingaFile = this._files[index];
    const fileStatus = mingaFile.status;
    if (fileStatus && fileStatus.fileKey) {
      this.fileDownloader.openDownload(fileStatus.fileKey);
    }
  }

  onFileIndexDownloadAbort(index: number) {
    this.fileDownloader.abortDownloads();

    // update file status
    if (this._files[index] && this._files[index].status) {
      this._files[index].status.canceled = true;
      this._files[index].status.progress = null;
      this._files[index].status.complete = false;
      this._files[index].status.error = false;
    }

    // reset state
    this.downloadingFile = false;

    // update reference for detecting changes
    this._files = _.clone(this._files);

    // notify that there's a change
    this.cdr.detectChanges();
  }

  private async _removeFile(
    file: gateway.file_pb.FileDetails.AsObject,
    index: number,
  ) {
    // assume the file is going to delete successfully
    this._files.splice(index, 1);

    await this.mingaManager
      .removeFile(file.hash)
      .then(responseFiles => {
        this._files = responseFiles;
      })
      .catch(err => {
        console.error(`onFileIndexDelete() removeGroupFile error: `, err);

        // restore file that wasn't deleted
        this._files.push({ file });

        this._systemAlertSnackBar.error(
          `uh oh, an error occurred, please try again later`,
        );
      });
  }

  async onFileIndexDelete(index: number) {
    const file = this._files[index].file;
    const options: IDeleteConfirmationOptions = {
      showSubTitle: false,
      titleLocaleValue: `file "${file.name}"`,
    };

    let dialog = this.dialog.open(DeleteConfirmationDialogComponent, {
      data: options,
    });
    dialog.afterClosed().subscribe(async result => {
      if (result === true) {
        this.fileDelete.emit({ file, index });
        // assume the file is going to delete successfully
        this._files.splice(index, 1);
        // this._removeFile(file, index);
      }
    });
  }
}
