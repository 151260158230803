import { NgModule } from '@angular/core';

import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { AuthStoreEffects } from './effects';
import { reducer } from './reducer';
import { authStoreKeyname } from './state';

@NgModule({
  imports: [
    StoreModule.forFeature({
      name: authStoreKeyname,
      reducer,
    }),
    EffectsModule.forFeature([AuthStoreEffects]),
  ],
  providers: [AuthStoreEffects],
})
export class AuthStoreModule {}
