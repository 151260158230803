import {
  Directive,
  EventEmitter,
  NgZone,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  ActivatedRoute,
  ChildrenOutletContexts,
  OutletContext,
  Router,
  RouterOutlet,
} from '@angular/router';

import { Subscription } from 'rxjs';

import {
  IOverlayConfig,
  OverlayStepInfo,
  parseOverlayStepperResult,
} from '@app/src/app/misc/overlay';

import { MgOverlayComponent } from './MgOverlay.component';

@Directive({
  selector: 'mg-overlay > router-outlet[mgOverlayRouterOutlet]',
  exportAs: 'mgOverlayRouterOutlet',
})
export class MgOverlayRouterOutletDirective implements OnInit, OnDestroy {
  private _routerOutletSubscription?: Subscription;
  private _activatedChildOutletSubscription?: Subscription;
  private _currentOverlayConfig: IOverlayConfig = {};
  private _activeOverlayComponents: any[] = [];
  private _overlayPrevious?: OverlayStepInfo;
  private _overlayNext?: OverlayStepInfo;

  @Output()
  overlayConfigUpdate: EventEmitter<IOverlayConfig> = new EventEmitter();

  /**
   * The current overlay config
   */
  get currentOverlayConfig(): IOverlayConfig {
    // Copy so it cannot be erroneously modified
    return Object.assign({}, this._currentOverlayConfig);
  }

  get overlayNext() {
    return this._overlayNext;
  }

  get overlayPrevious() {
    return this._overlayPrevious;
  }

  get isActivated(): boolean {
    return this.routerOutlet.isActivated;
  }

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private mgOverlay: MgOverlayComponent,
    private ngZone: NgZone,
    private routerOutlet: RouterOutlet,
  ) {}

  ngOnInit() {
    this._routerOutletSubscription = new Subscription();

    const activeSub = this.routerOutlet.activateEvents.subscribe((ev: any) =>
      this._onRouterOutletActivate(ev),
    );
    const deactiveSub = this.routerOutlet.deactivateEvents.subscribe(
      (ev: any) => this._onRouterOutletDeactivate(ev),
    );

    this._routerOutletSubscription.add(activeSub);
    this._routerOutletSubscription.add(deactiveSub);
  }

  reset() {
    this._currentOverlayConfig = {};
    this._activeOverlayComponents = [];
    delete this._overlayPrevious;
    delete this._overlayNext;
  }

  private _onRouterOutletActivate(ev: any) {
    this.reset();

    const outletName = this.routerOutlet.activatedRoute.outlet;
    const rootContexts: ChildrenOutletContexts = (<any>this.router)
      .rootContexts;
    const outletContext = rootContexts.getContext(outletName);

    if (!outletContext) {
      console.warn(
        `Could not find router outlet with name ${outletName} in the root context. The mgOverlayRouterOutlet directive must be on a root router outlet. Nested router outlets are not supported.`,
      );
      return;
    }

    if (this._activatedChildOutletSubscription) {
      this._activatedChildOutletSubscription.unsubscribe();
      delete this._activatedChildOutletSubscription;
    }

    this._setupOutletContext(outletContext);
  }

  private _onRouterOutletDeactivate(ev: any) {}

  private _setupOutletContext(outletContext: OutletContext) {
    if (!this._activatedChildOutletSubscription) {
      this._activatedChildOutletSubscription = new Subscription();
    }

    if (outletContext.outlet) {
      const outlet = outletContext.outlet;

      if (outlet.isActivated) {
        this._setupActiveOverlayRouteComponent(outlet.component);
      }

      const sub = outlet.activateEvents.subscribe(() => {
        this._setupActiveOverlayRouteComponent(outlet.component);
      });

      this._activatedChildOutletSubscription.add(sub);
    }

    const childPrimary = outletContext.children.getContext('primary');
    if (childPrimary) {
      this._setupOutletContext(childPrimary);
    }
  }

  private _setupActiveOverlayRouteComponent(component: any) {
    this._activeOverlayComponents.push(component);

    const isActiveComponent = () => {
      return this._activeOverlayComponents.includes(component);
    };

    const configHandler = (config: IOverlayConfig) => {
      if (!isActiveComponent()) return;

      // @TODO: Why did we need to run this with NgZone?
      this.ngZone.run(() => this.updateOverlayConfig(config));
    };

    const prevDisableHandler = (disabled: boolean) => {
      if (!isActiveComponent()) return;

      if (this._overlayPrevious) {
        this._overlayPrevious.disabled = !!disabled;
      }
    };

    const nextDisableHandler = (disabled: boolean) => {
      if (!isActiveComponent()) return;

      if (this._overlayNext) {
        this._overlayNext.disabled = !!disabled;
      }
    };

    const stepHandler = (val: string | boolean | OverlayStepInfo) => {
      if (!isActiveComponent()) return;

      // clang-format off
      console.warn(
        '[OVERLAY] Deprecated notice: `registerOnOverlayPreviousUpdate` and `registerOnOverlayNextUpdate` are deprecated. Please use `registerOnOverlayNavRefresh` instead.',
      );
      // clang-format on
      this.updateOverlayNav();
    };

    if (typeof component.registerOnOverlayConfig === 'function') {
      component.registerOnOverlayConfig(configHandler);
    }
    if (typeof component.registerOnOverlayPreviousUpdate === 'function') {
      component.registerOnOverlayPreviousUpdate(stepHandler);
    }
    if (typeof component.registerOnOverlayNextUpdate === 'function') {
      component.registerOnOverlayNextUpdate(stepHandler);
    }

    this.updateOverlayNav();
  }

  private _teardownActiveOverlayRouteComponent(component: any) {}

  updateOverlayNav() {
    for (const overlayComp of this._activeOverlayComponents) {
      if (typeof overlayComp.hasOverlayPrevious === 'function') {
        const overlayPrevious = overlayComp.hasOverlayPrevious();
        this._overlayPrevious = parseOverlayStepperResult(overlayPrevious, {
          defaultText: 'Close',
          defaultIcon: 'close',
        });
      }

      if (typeof overlayComp.hasOverlayNext === 'function') {
        const overlayNext = overlayComp.hasOverlayNext();
        this._overlayNext = parseOverlayStepperResult(overlayNext, {
          defaultText: 'Next',
          defaultIcon: '',
        });
      }
    }

    if (!this._overlayPrevious) {
      this._overlayPrevious = parseOverlayStepperResult(true, {
        defaultText: 'Close',
        defaultIcon: 'close',
      });
    }
  }

  async defaultOverlayClose() {
    const outletName = this.routerOutlet.activatedRoute.outlet;
    await this.router.navigate(['', { outlets: { [outletName]: null } }]);
  }

  async onOverlayClose() {
    try {
      for (const overlayComp of this._activeOverlayComponents) {
        if (typeof overlayComp.onOverlayClose === 'function') {
          const result = await overlayComp.onOverlayClose();

          // If the onOverlayClose returns a truthy value it should be
          // considered handled. Default behaviour should not happen.
          if (result) {
            return;
          }
        }
      }
    } catch (err) {
      console.error(err);
    }

    await this.defaultOverlayClose();
  }

  async onOverlayNext(ev: any) {
    if (!this._overlayNext) return;
    if (this._overlayNext.disabled) return;

    for (const overlayComp of this._activeOverlayComponents) {
      if (typeof overlayComp.onOverlayNext === 'function') {
        const result = await overlayComp.onOverlayNext();
        this.updateOverlayNav();
      }
    }
  }

  async onOverlayPrevious(ev: any) {
    if (!this._overlayPrevious) return;
    if (this._overlayPrevious.disabled) return;

    let previousHandled = false;

    for (const overlayComp of this._activeOverlayComponents) {
      if (typeof overlayComp.onOverlayPrevious === 'function') {
        const result = await overlayComp.onOverlayPrevious();
        this.updateOverlayNav();
        previousHandled = true;
      }
    }

    if (!previousHandled) {
      await this.onOverlayClose();
    }
  }

  updateOverlayConfig(config: IOverlayConfig) {
    this._currentOverlayConfig = Object.assign({}, config);
    this.overlayConfigUpdate.emit(this.currentOverlayConfig);
  }

  ngOnDestroy() {
    if (this._routerOutletSubscription) {
      this._routerOutletSubscription.unsubscribe();
      delete this._routerOutletSubscription;
    }

    if (this._activatedChildOutletSubscription) {
      this._activatedChildOutletSubscription.unsubscribe();
      delete this._activatedChildOutletSubscription;
    }
  }
}
