import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  NgZone,
  Output,
} from '@angular/core';
import {
  MatCalendarBody,
  MatDatepickerModule,
} from '@angular/material/datepicker';

import { ICalendarSelectDay } from '@app/src/app/minimal/services/EventContent';

@Component({
  moduleId: MatDatepickerModule.name,
  selector: '[mg-calendar-body]',
  templateUrl: './CustomCalendarBody.component.html',
  styleUrls: ['./CustomCalendarBody.component.scss'],
  host: { class: 'mat-calendar-body', role: 'grid', 'aria-readonly': 'true' },
  exportAs: 'matCalendarBody',
})
export class CustomCalendarBody
  extends MatCalendarBody
  implements AfterViewInit
{
  @Input()
  multiSelectDays: ICalendarSelectDay[] = [];

  @Output()
  onLastRowClick: EventEmitter<any> = new EventEmitter();

  @Input()
  filteringDays: ICalendarSelectDay[] = [];

  @Input()
  pastMonth: boolean = false;

  calculatedPadding: number;

  @HostListener('window:resize', ['$event'])
  onResize(e) {
    this.calcPadding();
  }

  constructor(private elementRef_: ElementRef, _ngZone: NgZone) {
    super(elementRef_, _ngZone);
    this.calcPadding();
  }

  ngAfterViewInit() {
    this.calcPadding();
  }

  calcPadding() {
    const rect = this.elementRef_.nativeElement.getBoundingClientRect();
    let elementWidth = rect.width + 16; // 16 for parent element's padding
    const windowWidth = window.innerWidth;

    // adjust width if way smaller than the window
    if (windowWidth / 3 > elementWidth) {
      elementWidth = windowWidth / 2;
    }

    let multiplier = 23;
    if (elementWidth < 500) {
      if (elementWidth > 400) {
        multiplier = 25;
      } else if (elementWidth > 375) {
        multiplier = 27;
      } else if (elementWidth > 350) {
        multiplier = 30;
      } else if (elementWidth > 325) {
        multiplier = 33;
      } else if (elementWidth > 300) {
        multiplier = 35;
      } else {
        multiplier = 40;
      }
    }

    this.calculatedPadding = (multiplier * this.cellAspectRatio) / this.numCols;
  }

  checkIfHasActive(rowIndex: number) {
    if (!this.todayValue) return false;
    const currentRow = this.rows[rowIndex];

    const firstDay = currentRow[0];
    const lastDay = currentRow[currentRow.length - 1];

    return (
      firstDay.value <= this.todayValue && lastDay.value >= this.todayValue
    );
  }

  checkIfSelected(itemValue: number) {
    if (this.multiSelectDays) {
      for (let day of this.multiSelectDays) {
        if (day && day.date && itemValue === day.date.getDate()) {
          return true;
        }
      }
    }
    // return itemValue == this.selectedValue;
  }

  checkIfFiltering(itemValue: number) {
    if (this.filteringDays) {
      for (let day of this.filteringDays) {
        if (day && day.date && itemValue === day.date.getDate()) {
          return true;
        }
      }
    }
  }

  getCellClass(itemValue: number) {
    if (this.multiSelectDays) {
      let classString = '';
      for (let day of this.multiSelectDays) {
        if (day && day.date && itemValue === day.date.getDate()) {
          classString = 'selected ';
          if (day.going) {
            classString += 'going ';
          }
          if (day.past) {
            classString += 'past';
          }
          if (day.checkedIn) {
            classString += ' checked-in';
          }
          break;
        }
      }
      if (classString === '') {
        classString += this.getMultiDayEventCellClass(itemValue);
      }

      return classString;
    }

    if (itemValue < this.todayValue) {
      return 'past';
    }
    if (this.pastMonth) {
      return 'past';
    }
    return '';
  }

  /* Highlights multievent days that fall inbetween event start and endDates */
  private getMultiDayEventCellClass(itemValue: number): string {
    for (let day of this.multiSelectDays) {
      if (
        day.endDate &&
        itemValue > day.date.getDate() &&
        itemValue <= day.endDate.getDate()
      ) {
        return 'selected';
      }
    }

    return '';
  }

  /** The number of blank cells to put at the beginning for the first row. */
  get _lastRowOffset(): number {
    return this.rows &&
      this.rows.length &&
      this.rows[this.rows.length - 1].length
      ? this.numCols - this.rows[this.rows.length - 1].length
      : 0;
  }

  _onLastRowClick(e) {
    this.onLastRowClick.emit(e);
  }
}
