// GENERATED CODE -- DO NOT EDIT!

import {grpc} from '@improbable-eng/grpc-web';

import * as gateway_survey_pb from '../gateway/survey_pb';

export class Survey {
  static readonly serviceName = "minga.Survey";

  static readonly EvalCancelExitSurvey = {
    methodName: "EvalCancelExitSurvey",
    service: Survey,
    requestStream: false,
    responseStream: false,
    requestType: gateway_survey_pb.ExitSurveyRequest,
    responseType: gateway_survey_pb.ExitSurveyResponse,
  };

}

export class SurveyClient {
  evalCancelExitSurvey() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
}
