import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';

import { BehaviorSubject, ReplaySubject } from 'rxjs';

import {
  ModalOverlayPrimaryHeaderBackground,
  ModalOverlayRef,
  ModalOverlayServiceCloseEventType,
} from '@shared/components/modal-overlay';

import { BmReportsAutoCounterResetMessages } from './bm-reports-auto-counter-reset.constants';
import { BmReportsAutoCounterResetResponse } from './bm-reports-auto-counter-reset.types';

@Component({
  selector: 'mg-bm-reports-auto-counter-reset',
  templateUrl: './bm-reports-auto-counter-reset.component.html',
  styleUrls: ['./bm-reports-auto-counter-reset.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BmReportsAutoCounterResetComponent implements OnDestroy {
  /** General subjects */
  private readonly _destroyedSubject = new ReplaySubject<void>(1);
  private _isLoading = new BehaviorSubject<boolean>(false);
  public readonly isLoading$ = this._isLoading.asObservable();

  /** Constants */
  public readonly MESSAGES = BmReportsAutoCounterResetMessages;
  public readonly modalBG = ModalOverlayPrimaryHeaderBackground.GREEN;

  /** Form Controls */
  public readonly countFC = new UntypedFormControl(0, [
    Validators.min(0),
    Validators.required,
  ]);

  constructor(
    private _modalRef: ModalOverlayRef<
      BmReportsAutoCounterResetResponse,
      undefined
    >,
  ) {}

  ngOnDestroy(): void {
    this._destroyedSubject.next();
    this._destroyedSubject.complete();
  }

  public close() {
    this._modalRef.close(ModalOverlayServiceCloseEventType.CLOSE);
  }

  public submit() {
    this._modalRef.close(ModalOverlayServiceCloseEventType.SUBMIT, {
      counterValue: this.countFC.value,
    });
  }
}
