<button
  type="button"
  class="card"
  [ngClass]="classes"
  [attr.aria-label]="student.firstName + ' ' + student.lastName"
  [attr.data-analytics]="'tools-myclass-click-student-card'"
  [attr.data-test]="'tools-myclass-click-student-card'"
  (click)="toggle()">
  <div class="card-wrap">
    <div
      *ngIf="hidePicture === false"
      class="card-avatar">
      <div
        *ngIf="student.filename"
        class="profile-picture"
        [mgStudentPicture]="student.filename"></div>
      <mg-avatar
        *ngIf="!student.filename && student.profileImageUrl"
        [src]="student.profileImageUrl">
      </mg-avatar>
      <div
        *ngIf="!student.filename && !student.profileImageUrl"
        class="profile-picture"
        [style.background-image]="
          'url(/assets/student-id/placeholder-profile-pic.png)'
        "></div>
    </div>
    <div class="card-name">
      <mg-text
        class="last-name"
        [matTooltip]="student.firstName + ' ' + student.lastName"
        matTooltipClass="mg-tooltip"
        [matTooltipShowDelay]="500"
        [numberOfLines]="1"
        [variant]="firstNameFirst ? 'body-sm' : 'header-sm'">
        {{ student.lastName }}
      </mg-text>
      <mg-text
        class="first-name"
        [numberOfLines]="1"
        [variant]="firstNameFirst ? 'header-sm' : 'body-sm'">
        {{ student.firstName }}
      </mg-text>
    </div>
    <div class="card-checkbox">
      <mg-form-checkbox [checked]="selected"></mg-form-checkbox>
    </div>

    <!-- Card Action Bar -->
    <ng-container *ngIf="mediaService.breakpoint$ | async as breakpoint">
      <div
        *ngIf="(mediaService.isMobileView$ | async) === false"
        class="card-actions"
        (click)="actionsBarClicked($event, breakpoint)">
        <!-- Praise Pill -->
        <ng-container
          *ngIf="
            (pastPraises$ | async)?.totalCount > 0 ||
            (pastPraiseConsequences$ | async)?.totalCount > 0
          "
          [ngTemplateOutlet]="praisePillTemplate">
        </ng-container>

        <!-- Guidance Pill -->
        <ng-container
          *ngIf="
            (pastGuidances$ | async)?.totalCount > 0 ||
            (pastConsequences$ | async)?.totalCount > 0
          "
          [ngTemplateOutlet]="guidancePillTemplate">
        </ng-container>
        <!-- Hall Pass Pill -->
        <ng-container
          *ngIf="
            hallPass &&
            PASS_STATUS_TO_TYPE[hallPass.status.state] &&
            hallPass.status.state === HallPassStatus.ENDED
          "
          [ngTemplateOutlet]="hallPassPillTemplate">
        </ng-container>

        <!-- Profile Button -->
        <mg-btn
          *ngIf="canViewProfile; else idTemplate"
          class="card-student-id"
          size="small"
          variant="icon-secondary"
          icon="mg-role"
          iconSet="minga"
          id="tools-myclass-click-view-profile"
          [attr.aria-label]="'Nagivate to student id'"
          (click)="$event.stopPropagation()"
          (pressed)="navigateToProfile(student.personHash)">
        </mg-btn>
        <ng-template #idTemplate>
          <mg-btn
            [attr.aria-label]="'Nagivate to student id'"
            class="card-student-id"
            size="small"
            variant="icon-secondary"
            icon="mg-id-menu-o"
            iconSet="minga"
            (click)="$event.stopPropagation()"
            (pressed)="navigateToId(student.personHash)">
          </mg-btn>
        </ng-template>
      </div>
    </ng-container>
  </div>

  <!-- Bottom Bar -->
  <div
    *ngIf="
      (mediaService.isMobileView$ | async) ||
      (hallPass &&
        PASS_STATUS_TO_TYPE[hallPass.status.state] &&
        (hallPass.status.state === HallPassStatus.ACTIVE ||
          hallPass.status.state === HallPassStatus.OVERDUE ||
          hallPass.status.state === HallPassStatus.PENDING_APPROVAL))
    "
    class="bottom-bar tw-flex tw-items-center tw-gap-1">
    <!-- Hall Pass Pill -->
    <ng-container
      *ngIf="hallPass && PASS_STATUS_TO_TYPE[hallPass.status.state]"
      [ngTemplateOutlet]="hallPassPillTemplate">
    </ng-container>

    <!-- Praise Pill -->
    <ng-container
      *ngIf="
        (mediaService.isMobileView$ | async) &&
        ((pastPraises$ | async)?.totalCount > 0 ||
          (pastPraiseConsequences$ | async)?.totalCount > 0) &&
        hallPass?.status.state !== HallPassStatus.PENDING_APPROVAL
      "
      [ngTemplateOutlet]="praisePillTemplate">
    </ng-container>

    <!-- Guidance Pill -->
    <ng-container
      *ngIf="
        (mediaService.isMobileView$ | async) &&
        ((pastGuidances$ | async)?.totalCount > 0 ||
          (pastConsequences$ | async)?.totalCount > 0) &&
        hallPass?.status.state !== HallPassStatus.PENDING_APPROVAL
      "
      [ngTemplateOutlet]="guidancePillTemplate">
    </ng-container>

    <!-- Mobile Profile Button -->
    <mg-btn
      *ngIf="
        (mediaService.isMobileView$ | async) && canViewProfile;
        else idTemplate
      "
      class="bottom-bar-student-id"
      size="small"
      variant="icon-secondary"
      icon="mg-role"
      iconSet="minga"
      id="tools-myclass-click-view-profile"
      [attr.aria-label]="'Nagivate to student profile'"
      (click)="$event.stopPropagation()"
      (pressed)="navigateToProfile(student.personHash)">
    </mg-btn>
    <ng-template #idTemplate>
      <mg-btn
        *ngIf="mediaService.isMobileView$ | async"
        class="bottom-bar-student-id"
        size="small"
        variant="icon-secondary"
        icon="mg-id-menu-o"
        iconSet="minga"
        [attr.aria-label]="'Nagivate to student id'"
        (click)="$event.stopPropagation()"
        (pressed)="navigateToId(student.personHash)">
      </mg-btn>
    </ng-template>
  </div>
</button>

<!-- Hall Pass Pill Template -->
<ng-template #hallPassPillTemplate>
  <ng-container
    *ngIf="
      hallPass
        | countdown
          : (recentlyEndedSetting$ | async)
          : (manuallyEndedPassesSetting$ | async)
          : myClassHallPassService.timeTicker$
          : hallPass.type?.manuallyEndPass
        | async as countdown;
      else loaderTemplate
    ">
    <mg-tt-pill
      [attr.aria-label]="'Active Hall Passes'"
      [type]="
        selected && hallPass.status.state === HallPassStatus.ACTIVE
          ? 'selected'
          : PASS_STATUS_TO_TYPE[hallPass.status.state]
      "
      icon="mg-away"
      [text]="
        hallPass.status.state === HallPassStatus.ACTIVE ||
        hallPass.status.state === HallPassStatus.OVERDUE
          ? '' + countdown
          : ''
      "
      [id]="'tools-myclass-click-pill-hp'"
      [size]="(mediaService.isMobileView$ | async) ? 'medium' : 'small'"
      (click)="$event.stopPropagation()"
      [matMenuTriggerFor]="hallpass">
    </mg-tt-pill>

    <ng-container
      *ngIf="
        hallPass
          | hpmCanApprove: authHash:('HALL_PASS_TYPE_MANAGE' | hasPermission)
      ">
      <div
        class="tt-pill"
        [attr.data-analytics]="'tools-myclass-click-pill-deny'"
        [attr.data-test]="'tools-myclass-click-pill-deny'"
        (click)="
          $event.stopPropagation();
          onHallPassActionClicked('deny', { skipConfirmation: true })
        ">
        <mg-icon
          class="deny"
          iconName="mg-cross">
        </mg-icon>
      </div>
      <div
        class="tt-pill"
        [attr.data-analytics]="'tools-myclass-click-pill-approve'"
        [attr.data-test]="'tools-myclass-click-pill-approve'"
        (click)="
          $event.stopPropagation();
          onHallPassActionClicked('approve', { skipConfirmation: true })
        ">
        <mg-icon
          class="approve"
          iconName="mg-checkmark">
        </mg-icon>
      </div>
    </ng-container>
    <mg-btn
      *ngIf="
        HallPassStatus.ACTIVE === hallPass.status.state ||
        HallPassStatus.OVERDUE === hallPass.status.state
      "
      class="tw-flex"
      variant="text"
      size="xsmall"
      id="tools-myclass-click-pill-end"
      (click)="$event.stopPropagation()"
      (pressed)="onHallPassActionClicked('end')">
      End
    </mg-btn>
    <mat-menu
      #hallpass="matMenu"
      yPosition="below"
      xPosition="after"
      [hasBackdrop]="true">
      <ng-template matMenuContent>
        <div
          class="popup-wrap large"
          fxLayout="column"
          fxLayoutGap="4px">
          <mg-text
            variant="label-md-secondary"
            element="p"
            >{{ hallPass.status.state }} Hall Pass</mg-text
          >
          <mg-text
            variant="label-md"
            element="p">
            {{ hallPass.type?.name }}
          </mg-text>

          <ng-container
            *ngIf="
              hallPass.status.state === HallPassStatus.ENDED;
              else timerTemplate
            ">
            <mg-text
              variant="label-md"
              element="p">
              {{ hallPass.status.end | date: 'hh:mm a' }}
            </mg-text>
          </ng-container>
          <ng-template #timerTemplate>
            <ng-container *ngIf="countdown; else loaderTemplate">
              <div
                fxLayout="row"
                fxLayoutAlign="start center"
                fxLayoutGap="2px">
                <mg-icon
                  *ngIf="
                    showIcon() &&
                    hallPass.status.state === HallPassStatus.ACTIVE
                  "
                  class="auto-end-icon"
                  iconName="mg-auto-end">
                </mg-icon>
                <mg-text
                  *ngIf="countdown !== 'DISABLED'"
                  variant="label-md"
                  element="p">
                  {{ countdown }}
                </mg-text>
              </div>
            </ng-container>
          </ng-template>

          <div class="tw-flex tw-justify-center tw-items-center">
            <mg-btn
              *ngIf="
                HallPassStatus.ACTIVE === hallPass.status.state ||
                HallPassStatus.OVERDUE === hallPass.status.state
              "
              variant="text"
              id="tools-myclass-click-end"
              (pressed)="onHallPassActionClicked('end')">
              End
            </mg-btn>
            <ng-container
              *ngIf="
                hallPass
                  | hpmCanApprove
                    : authHash
                    : ('HALL_PASS_TYPE_MANAGE' | hasPermission)
              ">
              <mg-btn
                variant="text"
                id="tools-myclass-click-deny"
                (pressed)="onHallPassActionClicked('deny')">
                Deny
              </mg-btn>
              <mg-btn
                variant="text"
                id="tools-myclass-click-approve"
                (pressed)="onHallPassActionClicked('approve')">
                Approve
              </mg-btn>
            </ng-container>
          </div>
        </div>
      </ng-template>
    </mat-menu>
  </ng-container>
</ng-template>

<!-- Praise Pill Template -->
<ng-template #praisePillTemplate>
  <mg-tt-pill
    [attr.aria-label]="'Active Praises'"
    type="success"
    [text]="
      (pastPraises$ | async)?.totalCount +
      (pastPraiseConsequences$ | async)?.totalCount
    "
    [size]="(mediaService.isMobileView$ | async) ? 'medium' : 'small'"
    [matMenuTriggerFor]="pastPraises"
    (click)="$event.stopPropagation()">
  </mg-tt-pill>
  <mat-menu
    #pastPraises="matMenu"
    yPosition="below"
    xPosition="after"
    [hasBackdrop]="true">
    <ng-template matMenuContent>
      <div
        class="popup-wrap"
        fxLayout="column"
        fxLayoutGap="4px">
        <div *ngIf="(pastPraises$ | async)?.totalCount > 0">
          <mg-text
            variant="label-md-secondary"
            element="p"
            >Praises</mg-text
          >
          <mg-text
            *ngFor="let praise of (pastPraises$ | async)?.items"
            variant="label-md"
            element="p">
            {{ praise.count }} {{ praise.label }}
          </mg-text>
        </div>
        <div *ngIf="(pastPraiseConsequences$ | async)?.totalCount > 0">
          <mg-text
            variant="label-md-secondary"
            element="p"
            >Consequences</mg-text
          >
          <mg-text
            *ngFor="let consequence of (pastPraiseConsequences$ | async)?.items"
            variant="label-md"
            element="p">
            {{ consequence.count }} {{ consequence.label }}
          </mg-text>
        </div>
      </div>
    </ng-template>
  </mat-menu>
</ng-template>

<!-- Guidance Pill Template -->
<ng-template #guidancePillTemplate>
  <mg-tt-pill
    [attr.aria-label]="'Active Guidances'"
    type="error"
    [text]="
      (pastGuidances$ | async)?.totalCount +
      (pastConsequences$ | async)?.totalCount
    "
    [id]="'tools-myclass-click-pill-guid'"
    [size]="(mediaService.isMobileView$ | async) ? 'medium' : 'small'"
    (click)="$event.stopPropagation()"
    [matMenuTriggerFor]="pastGuidances">
  </mg-tt-pill>
  <mat-menu
    #pastGuidances="matMenu"
    yPosition="below"
    xPosition="after"
    [hasBackdrop]="true">
    <ng-template matMenuContent>
      <div
        class="popup-wrap"
        fxLayout="column"
        fxLayoutGap="4px">
        <div *ngIf="(pastGuidances$ | async)?.totalCount > 0">
          <mg-text
            variant="label-md-secondary"
            element="p"
            >Guidances</mg-text
          >
          <mg-text
            *ngFor="
              let guidance of (pastGuidances$ | async)?.items;
              let i = index
            "
            variant="label-md"
            element="p">
            {{ guidance.count }} {{ guidance.label }}
          </mg-text>
        </div>
        <div *ngIf="(pastConsequences$ | async)?.totalCount > 0">
          <mg-text
            variant="label-md-secondary"
            element="p"
            >Consequences</mg-text
          >
          <mg-text
            *ngFor="
              let consequence of (pastConsequences$ | async)?.items;
              let i = index
            "
            variant="label-md"
            element="p">
            {{ consequence.count }} {{ consequence.label }}
          </mg-text>
        </div>
      </div>
    </ng-template>
  </mat-menu>
</ng-template>

<!-- Profile Icon Template -->

<!-- Loader Template -->
<ng-template #loaderTemplate>
  <div class="inline-spinner">
    <mg-spinner
      [diameter]="16"
      [thickness]="2">
    </mg-spinner>
  </div>
</ng-template>
