export enum ChallengeType {
  IMAGE = 'IMAGE',
  VIDEO = 'VIDEO',
  TEXT = 'TEXT',
}

export enum ChallengePrivacy {
  PUBLIC = 'PUBLIC',
  SEMIPRIVATE = 'SEMIPRIVATE',
  PRIVATE = 'PRIVATE',
}

export enum ChallengeColor {
  RED = 'RED',
  ORANGE = 'ORANGE',
  YELLOW = 'YELLOW',
  GREEN = 'GREEN',
  BLUE = 'BLUE',
  PURPLE = 'PURPLE'
}

export enum ChallengeApprovalState {
  PENDING = 'PENDING',
  DENIED = 'DENIED',
  APPROVED = 'APPROVED'
}
