import { PastReport, PastReportStatus } from 'libs/domain';
import { stats_report_pb as proto } from 'libs/generated-grpc-ts';
import { $enum } from 'ts-enum-util';

import { dateObjectToDateTimeMessage, dateTimeMessageToDateObject } from '../';

export const toProto = (report: PastReport): proto.PastReport => {
  const msg = new proto.PastReport();
  if (report.id) msg.setId(report.id);
  if (report.name) msg.setName(report.name);
  if (report.status)
    msg.setStatus($enum(PastReportStatus).asValueOrThrow(report.status));
  if (report.date) msg.setDate(dateObjectToDateTimeMessage(report.date));
  if (report.scheduledReportId)
    msg.setScheduledReportId(report.scheduledReportId);
  return msg;
};

export const fromProto = (msg: proto.PastReport): PastReport => {
  return {
    id: msg.getId(),
    name: msg.getName(),
    status: $enum(PastReportStatus).asValueOrThrow(msg.getStatus()),
    date: dateTimeMessageToDateObject(msg.getDate()),
    scheduledReportId: msg.getScheduledReportId(),
  };
};
