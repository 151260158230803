import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { gateway } from 'libs/generated-grpc-web';

@Component({
  selector: 'mg-challenge-response-notification',
  templateUrl: './MgChallengeResponseNotification.element.html',
  styleUrls: ['./MgChallengeResponseNotification.element.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MgChallengeResponseNotificationElement {
  @Input()
  notification?: gateway.notification_pb.Notification.AsObject;
}
