import { Validators } from '@angular/forms';

import {
  DEFAULT_BEHAVIOR_SMS_TEMPLATE,
  DefaultBehaviorEmailTemplate,
} from 'libs/shared';

import { MgValidators } from '@app/src/app/input/validators';

import { setDefaultRestrictionControlValue } from '@shared/components/form-restriction-input/form-restriction-input.constants';

import {
  BmTypesBehaviorEditFormFields,
  ICON_CONFIG_DEFAULTS,
} from '../components/bm-types-behavior-edit/bm-types-behavior-edit.constants';
import { BehaviorMsgCategory } from '../constants';

export const getBmTypeFormConfig = () => {
  return {
    [BmTypesBehaviorEditFormFields.NAME]: [
      '',
      [Validators.required, Validators.minLength(1), Validators.maxLength(25)],
    ],
    [BmTypesBehaviorEditFormFields.ICON_COLOR]: [
      ICON_CONFIG_DEFAULTS[BehaviorMsgCategory.PRAISE].color,
    ],
    [BmTypesBehaviorEditFormFields.ICON]: [
      ICON_CONFIG_DEFAULTS[BehaviorMsgCategory.PRAISE].icon,
    ],
    [BmTypesBehaviorEditFormFields.POINTS]: [0],
    [BmTypesBehaviorEditFormFields.HALL_PASS_ID]: [undefined],
    [BmTypesBehaviorEditFormFields.PARENT_EMAIL]: [false],
    [BmTypesBehaviorEditFormFields.PARENT_EMAIL_BODY]: [
      DefaultBehaviorEmailTemplate.BODY,
      [MgValidators.templateValidator],
    ],
    [BmTypesBehaviorEditFormFields.PARENT_EMAIL_SUBJECT]: [
      DefaultBehaviorEmailTemplate.SUBJECT,
      [MgValidators.templateValidator, Validators.maxLength(255)],
    ],
    [BmTypesBehaviorEditFormFields.PARENT_SMS]: false,
    [BmTypesBehaviorEditFormFields.PARENT_SMS_TEMPLATE]: [
      DEFAULT_BEHAVIOR_SMS_TEMPLATE,
      [MgValidators.templateValidator, Validators.maxLength(255)],
    ],
    [BmTypesBehaviorEditFormFields.SEND_ADMIN_EMAIL]: false,
    [BmTypesBehaviorEditFormFields.ADMIN_EMAIL]: [
      [],
      [MgValidators.allValuesAsEmailsValidator],
    ],
    [BmTypesBehaviorEditFormFields.CATEGORY]: [
      BehaviorMsgCategory.PRAISE,
      Validators.required,
    ],
    [BmTypesBehaviorEditFormFields.RESTRICTIONS]:
      setDefaultRestrictionControlValue(),
  } as const;
};
