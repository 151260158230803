import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';

import { WebcamModule } from 'ngx-webcam';

import { MgOverlayToolsModule } from '@app/src/app/overlay/tools';

import { GenericButtonModule } from '@shared/components/button';
import { OverlayModule } from '@shared/components/modal-overlay';
import { CommonUIModules } from '@shared/modules';

import { WebcamModalComponent } from './webcam-modal.component';

@NgModule({
  imports: [
    // Common dependencies
    CommonUIModules,

    // Minga dependencies
    MgOverlayToolsModule,
    OverlayModule,
    GenericButtonModule,

    // External dependencies
    CommonModule,
    FlexLayoutModule,
    WebcamModule,
  ],
  declarations: [WebcamModalComponent],
  exports: [WebcamModalComponent],
})
export class WebcamModalModule {}
