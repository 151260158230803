/**
 * Raw enum values represent self granted hp validation error codes
 */
export enum IHallPassErrorType {
  MAX_PASSES = 1,
  NO_PARTY,
  NO_PASS,
  MAX_ACTIVE_PASSES,
  MAX_HALL_PASS_TYPE_PASSES,
  NOT_IN_HALL_PASS_TYPE_LIST,
  BLACK_OUT_WINDOW,
  NOT_PAST_MIN_WAIT_PERIOD,
  HALL_PASS_ACTIVE,
  MAX_TYPE_PASSES,
  HALL_PASS_NOT_ENABLED,
  NOT_AVAILABLE_IN_KIOSK,
  HALL_PASS_TYPE_NOT_ACTIVE,
}

export interface HallPassError {
  type: IHallPassErrorType;
  cause?: HallPassErrorCause;
}

export interface IHallPassValidationError {
  personId: number;
  name: string;
  personHash: string;
  error: IHallPassErrorType;
  hallPassName?: string;
  maxPasses?: number;
  cause?: HallPassErrorCause;
}

export interface HallPassErrorCause {
  errorType: IHallPassErrorType;
  blackOutWindowId?: number;
  noPartyGroupId?: number;
}

export interface HallPassDeniedPerson {
  personId: number;
  cause: HallPassErrorCause;
}

export enum HallPassErrorCauseExplanation {
  MAX_PASSES = 'Max daily total passes',
  NO_PARTY = 'No party group',
  NO_PASS = 'No pass group',
  MAX_ACTIVE_PASSES = 'Max active passes',
  MAX_HALL_PASS_TYPE_PASSES = 'Max simultaneous pass type',
  NOT_IN_HALL_PASS_TYPE_LIST = 'Student list restriction',
  BLACK_OUT_WINDOW = 'Blackout',
  NOT_PAST_MIN_WAIT_PERIOD = 'Wait time between passes',
  HALL_PASS_ACTIVE = 'Active hall pass',
  MAX_TYPE_PASSES = 'Max daily student pass type limit',
  HALL_PASS_NOT_ENABLED = 'Hall passes disabled',
  NOT_AVAILABLE_IN_KIOSK = 'Hall pass not available in kiosk',
  HALL_PASS_TYPE_NOT_ACTIVE = 'Hall pass disabled',
  USER = 'User',
}

// change to enum for strings, so we can use as filter
export function hallPassErrorToDeniedBy(error: IHallPassErrorType) {
  switch (error) {
    case IHallPassErrorType.BLACK_OUT_WINDOW:
      return HallPassErrorCauseExplanation.BLACK_OUT_WINDOW;
    case IHallPassErrorType.NO_PARTY:
      return HallPassErrorCauseExplanation.NO_PARTY;
    case IHallPassErrorType.NO_PASS:
      return HallPassErrorCauseExplanation.NO_PASS;
    case IHallPassErrorType.MAX_ACTIVE_PASSES:
      return HallPassErrorCauseExplanation.MAX_ACTIVE_PASSES;
    case IHallPassErrorType.MAX_HALL_PASS_TYPE_PASSES:
      return HallPassErrorCauseExplanation.MAX_HALL_PASS_TYPE_PASSES;
    case IHallPassErrorType.NOT_IN_HALL_PASS_TYPE_LIST:
      return HallPassErrorCauseExplanation.NOT_IN_HALL_PASS_TYPE_LIST;
    case IHallPassErrorType.NOT_PAST_MIN_WAIT_PERIOD:
      return HallPassErrorCauseExplanation.NOT_PAST_MIN_WAIT_PERIOD;
    case IHallPassErrorType.HALL_PASS_ACTIVE:
      return HallPassErrorCauseExplanation.HALL_PASS_ACTIVE;
    case IHallPassErrorType.MAX_PASSES:
      return HallPassErrorCauseExplanation.MAX_PASSES;
    case IHallPassErrorType.MAX_TYPE_PASSES:
      return HallPassErrorCauseExplanation.MAX_TYPE_PASSES;
    case IHallPassErrorType.HALL_PASS_NOT_ENABLED:
      return HallPassErrorCauseExplanation.HALL_PASS_NOT_ENABLED;
    case IHallPassErrorType.NOT_AVAILABLE_IN_KIOSK:
      return HallPassErrorCauseExplanation.NOT_AVAILABLE_IN_KIOSK;
    case IHallPassErrorType.HALL_PASS_TYPE_NOT_ACTIVE:
      return HallPassErrorCauseExplanation.HALL_PASS_TYPE_NOT_ACTIVE;
    default:
      console.error('Error: Hall Pass Error Type not recognized');
      return '';
  }
}
