// source: designed_svg/designed_svg.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

import * as jspb from 'google-protobuf';
export namespace DesignedSvg {
  export interface AsObject {
    svgUrl: string;
    svgDataMap: [string, string][];
    svgManipulationsMap: [string, string][];
    stockImageSlotList: string[];
  }
}
export class DesignedSvg extends jspb.Message {
  static readonly displayName = "DesignedSvg";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [4];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, DesignedSvg.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): DesignedSvg.AsObject {
    return DesignedSvg.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!DesignedSvg} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: DesignedSvg) {
    var f: any;
    var obj: any = {
      svgUrl: jspb.Message.getFieldWithDefault(msg, 1, ""),
      svgDataMap: (f = msg.getSvgDataMap()) ? f.toObject(includeInstance, undefined) : [],
      svgManipulationsMap: (f = msg.getSvgManipulationsMap()) ? f.toObject(includeInstance, undefined) : [],
      stockImageSlotList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!DesignedSvg}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new DesignedSvg;
    return DesignedSvg.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!DesignedSvg} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!DesignedSvg}
   */
  static deserializeBinaryFromReader(msg: DesignedSvg, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setSvgUrl(value1);
        break;
      case 2:
        var value2 = msg.getSvgDataMap();
        reader.readMessage(value2, function(message, reader) {
          jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readString, null, "");
           });
        break;
      case 3:
        var value3 = msg.getSvgManipulationsMap();
        reader.readMessage(value3, function(message, reader) {
          jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readString, null, "");
           });
        break;
      case 4:
        var value4 = /** @type {string} */ (reader.readString());
        msg.addStockImageSlot(value4);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!DesignedSvg} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: DesignedSvg, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getSvgUrl();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
    f = message.getSvgDataMap(true);
    if (f && f.getLength() > 0) {
      f.serializeBinary(2, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeString);
    }
    f = message.getSvgManipulationsMap(true);
    if (f && f.getLength() > 0) {
      f.serializeBinary(3, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeString);
    }
    f = message.getStockImageSlotList();
    if (f.length > 0) {
      writer.writeRepeatedString(
        4,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    DesignedSvg.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string svg_url = 1;
   * @return {string}
   */
  getSvgUrl(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setSvgUrl(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


  /**
   * map<string, string> svg_data = 2;
   * @param {boolean=} opt_noLazyCreate Do not create the map if
   * empty, instead returning `undefined`
   * @return {!jspb.Map<string,string>}
   */
  getSvgDataMap(): jspb.Map<string,string>;
  getSvgDataMap(noLazyCreate: true): jspb.Map<string,string>|undefined;
  getSvgDataMap(noLazyCreate: false): jspb.Map<string,string>;
  getSvgDataMap(opt_noLazyCreate: boolean = false): jspb.Map<string,string> {
    return /** @type {!jspb.Map<string,string>} */ (
        jspb.Message.getMapField(this, 2, opt_noLazyCreate,
        null));
  }


  /**
   * Clears values from the map. The map will be non-null.
   */
  clearSvgDataMap() {
    this.getSvgDataMap().clear();
  }


  /**
   * map<string, string> svg_manipulations = 3;
   * @param {boolean=} opt_noLazyCreate Do not create the map if
   * empty, instead returning `undefined`
   * @return {!jspb.Map<string,string>}
   */
  getSvgManipulationsMap(): jspb.Map<string,string>;
  getSvgManipulationsMap(noLazyCreate: true): jspb.Map<string,string>|undefined;
  getSvgManipulationsMap(noLazyCreate: false): jspb.Map<string,string>;
  getSvgManipulationsMap(opt_noLazyCreate: boolean = false): jspb.Map<string,string> {
    return /** @type {!jspb.Map<string,string>} */ (
        jspb.Message.getMapField(this, 3, opt_noLazyCreate,
        null));
  }


  /**
   * Clears values from the map. The map will be non-null.
   */
  clearSvgManipulationsMap() {
    this.getSvgManipulationsMap().clear();
  }


  /**
   * repeated string stock_image_slot = 4;
   * @return {!Array<string>}
   */
  getStockImageSlotList(): string[] {
    return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 4));
  };


  /** @param {!Array<string>} value */
  setStockImageSlotList(value: string[]) {
    jspb.Message.setField(this, 4, value || []);
  }


  /**
   * @param {string} value
   * @param {number=} opt_index
   */
  addStockImageSlot(value: string, opt_index?: number): void {
    jspb.Message.addToRepeatedField(this, 4, value, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearStockImageSlotList() {
    this.setStockImageSlotList([]);
  }


}
export namespace StudioContentInfo {
  export interface AsObject {
    designedSvg?: DesignedSvg.AsObject;
    templateId: string;
  }
}
export class StudioContentInfo extends jspb.Message {
  static readonly displayName = "StudioContentInfo";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): StudioContentInfo.AsObject {
    return StudioContentInfo.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!StudioContentInfo} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: StudioContentInfo) {
    var f: any;
    var obj: any = {
      designedSvg: (f = msg.getDesignedSvg()) && DesignedSvg.toObject(includeInstance, f),
      templateId: jspb.Message.getFieldWithDefault(msg, 2, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!StudioContentInfo}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new StudioContentInfo;
    return StudioContentInfo.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!StudioContentInfo} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!StudioContentInfo}
   */
  static deserializeBinaryFromReader(msg: StudioContentInfo, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new DesignedSvg;
        reader.readMessage(value1,DesignedSvg.deserializeBinaryFromReader);
        msg.setDesignedSvg(value1);
        break;
      case 2:
        var value2 = /** @type {string} */ (reader.readString());
        msg.setTemplateId(value2);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!StudioContentInfo} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: StudioContentInfo, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getDesignedSvg();
    if (f != null) {
      writer.writeMessage(
        1,
        f,
        DesignedSvg.serializeBinaryToWriter
      );
    }
    f = message.getTemplateId();
    if (f.length > 0) {
      writer.writeString(
        2,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    StudioContentInfo.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional DesignedSvg designed_svg = 1;
   * @return {?DesignedSvg}
   */
  getDesignedSvg(): DesignedSvg {
    return /** @type{?DesignedSvg} */ (
      jspb.Message.getWrapperField(this, DesignedSvg, 1));
  }


  /** @param {?DesignedSvg|undefined} value */
  setDesignedSvg(value?: DesignedSvg) {
    jspb.Message.setWrapperField(this, 1, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearDesignedSvg() {
    this.setDesignedSvg(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasDesignedSvg(): boolean {
    return jspb.Message.getField(this, 1) != null;
  }


  /**
   * optional string template_id = 2;
   * @return {string}
   */
  getTemplateId(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
  };


  /** @param {string} value */
  setTemplateId(value: string) {
    jspb.Message.setProto3StringField(this, 2, value);
  }


}
