import { Location } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { gateway } from 'libs/generated-grpc-web';
import { ReplaySubject } from 'rxjs';

import { MgModalService } from '@app/src/app/minimal/services/MgModal';
import { RootService } from '@app/src/app/minimal/services/RootService';

import { LandingService } from '../../services';

@Component({
  selector: 'mg-landing-pin',
  templateUrl: './landing-pin.component.html',
  styleUrls: ['./landing-pin.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LandingPinComponent implements OnInit, OnDestroy {
  // Children

  @ViewChild('form', { read: NgForm, static: true })
  form?: NgForm;

  @ViewChild('successOverlayTemplate', { static: true })
  successOverlayTemplate?: TemplateRef<LandingPinComponent>;

  // Clean up

  private readonly _destroyedSubject = new ReplaySubject<void>(1);

  // State
  errorKey = '';
  resetToken = '';

  /** Component constructor */
  constructor(
    public landing: LandingService,
    private _router: Router,
    private _route: ActivatedRoute,
    private _loginManager: gateway.login_ng_grpc_pb.LoginManager,
    private _location: Location,
    private _rootService: RootService,
    private _mgModal: MgModalService,
  ) {}

  ngOnInit(): void {
    this._route.params.subscribe(params => {
      this.resetToken = params.resetToken || '';
    });
  }

  ngOnDestroy(): void {
    this._destroyedSubject.next();
    this._destroyedSubject.complete();
  }

  public goBack() {
    this._location.back();
  }

  public async onSubmit() {
    if (!this.form) return;

    if (!this.form.valid) {
      console.log('pin in invalid format');
      return;
    }

    const pinRequest = new gateway.login_pb.PinRequest();
    pinRequest.setPin(this.landing.pin);

    if (!this.resetToken) {
      pinRequest.setEmail(this.landing.email);
    } else {
      pinRequest.setResetToken(this.resetToken);
    }

    const pinPromise = this._loginManager.pin(pinRequest);
    const response = await this._rootService.addLoadingPromise(pinPromise);

    if (response.getValid()) {
      const personName = response.getPersonName();
      const mingaName = response.getMingaName();
      const email = response.getEmail();

      this.landing.personName = personName;
      this.landing.mingaName = mingaName;
      this.landing.email = email;

      if (this.successOverlayTemplate) {
        const dialogRef = this._mgModal.open(this.successOverlayTemplate, {
          full: true,
          animation: 'fade',
        });
        setTimeout(() => dialogRef.close(), 2000);
        // Wait for the animation to start a tiny bit before heading to the
        // create page.
        await new Promise(resolve => setTimeout(resolve, 1000));
      }
      await this._router.navigate(['/landing', 'create']);
    } else {
      this.errorKey = 'incorrect';
    }
  }
}
