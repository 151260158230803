import { Injectable } from '@angular/core';

import { PointReward } from 'libs/domain';
import { points_pb, points_ng_grpc_pb } from 'libs/generated-grpc-web';
import { PointRewardMapper } from 'libs/shared-grpc';
import { Subject } from 'rxjs';

import { AuthInfoService } from '@app/src/app/minimal/services/AuthInfo';

import { SystemAlertSnackBarService } from '@shared/components/system-alert-snackbar';

@Injectable({ providedIn: 'root' })
export class PointsService {
  // Events
  private readonly _pointsSubtractedSubject = new Subject<number>();
  public readonly onPointsRedeemed$ =
    this._pointsSubtractedSubject.asObservable();

  /** Service Constructor */
  constructor(
    private _systemAlertSnackBar: SystemAlertSnackBarService,
    private _pointsManager: points_ng_grpc_pb.PointsManager,
    private _authInfoService: AuthInfoService,
  ) {}

  public async fetchRewardTypes(activeOnly?: boolean): Promise<PointReward[]> {
    try {
      const result = await this._listRewardTypes(activeOnly);
      return result;
    } catch (error) {
      this._systemAlertSnackBar.error(
        'Failed to fetch point reward types ' + error,
      );
    }
  }

  public async redeemPoints(
    personHash: string,
    points: number,
    rewardItemIds: number[],
  ): Promise<boolean> {
    try {
      const result = await this._redeemPoints(
        personHash,
        points,
        rewardItemIds,
      );
      return result;
    } catch (error) {
      this._systemAlertSnackBar.error('Failed to redeem points ' + error);
    }
  }

  public async fetchRedeemablePoints(personHash: string): Promise<number> {
    try {
      const result = await this._fetchRedeemablePoints(personHash);
      return result;
    } catch (error) {
      this._systemAlertSnackBar.error(
        'Failed to get Redeemable points ' + error,
      );
    }
  }

  private async _redeemPoints(
    personHash: string,
    points: number,
    rewardItemIds: number[],
  ): Promise<boolean> {
    const request = new points_pb.RedeemPointsRequest();
    request.setPersonHash(personHash);
    request.setPoints(points);
    request.setPointRewardIdsList(rewardItemIds);
    const response = await this._pointsManager.redeemPoints(request);
    // Only update the points display if the response was successful and the person hash matches the current user
    if (
      response.getSuccess() &&
      this._authInfoService.authPersonHash === personHash
    ) {
      this._pointsSubtractedSubject.next(points);
    }
    return response.getSuccess();
  }

  private async _listRewardTypes(activeOnly?: boolean): Promise<PointReward[]> {
    const request = new points_pb.GetPointRewardsRequest();
    if (activeOnly) request.setActive(activeOnly);
    const response = await this._pointsManager.getPointRewards(request);
    const pointRewardsList = response.getPointRewardsList();
    return pointRewardsList.map(PointRewardMapper.fromProto) as PointReward[];
  }

  private async _fetchRedeemablePoints(personHash: string): Promise<number> {
    const request = new points_pb.GetRedeemablePointsRequest();
    request.setPersonHash(personHash);
    const response = await this._pointsManager.getRedeemablePoints(request);
    return response.getRedeemablePoints();
  }
}
