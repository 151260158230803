export enum INotificationType {
  POINTS_IMAGE_CHALLENGE = 'POINTS_IMAGE_CHALLENGE',
  POINTS_EVENT_CHECKIN = 'POINTS_EVENT_CHECKIN',
  POINTS_POLL = 'POINTS_POLL',
  POINTS_TEXT_CHALLENGE = 'POINTS_TEXT_CHALLENGE',
  POINTS_CUSTOM = 'POINTS_CUSTOM',
  BADGE_CUSTOM = 'BADGE_CUSTOM',
  BADGE_IMAGE_CHALLENGE = 'BADGE_IMAGE_CHALLENGE',
  BADGE_TEXT_CHALLENGE = 'BADGE_TEXT_CHALLENGE',
  EVENT_INVITE = 'EVENT_INVITE',
  CHALLENGE_INVITE = 'CHALLENGE_INVITE',
  CHALLENGE_RESPONSE_DENIED = 'CHALLENGE_RESPONSE_DENIED',
  HALL_PASS_GRANTED = 'HALL_PASS_GRANTED',
  HALL_PASS_NOTIFY = 'HALL_PASS_NOTIFY',
  HALL_PASS_APPROVAL_REQUEST = 'HALL_PASS_APPROVAL_REQUEST',
  HALL_PASS_APPROVAL_EXPIRED = 'HALL_PASS_APPROVAL_EXPIRED',
  HALL_PASS_APPROVAL_CANCEL = 'HALL_PASS_APPROVAL_CANCEL',
  HALL_PASS_APPROVED = 'HALL_PASS_APPROVED',
  HALL_PASS_DENIED = 'HALL_PASS_DENIED',
  HALL_PASS_ENDED = 'HALL_PASS_ENDED',
  PBIS_BEHAVIOR = 'PBIS_BEHAVIOR',
  ACCOUNT_ARCHIVED = 'ACCOUNT_ARCHIVED',
  BM_PRAISE = 'BM_PRAISE',
  BM_GUIDANCE = 'BM_GUIDANCE',
  BM_CONSEQUENCE_PRAISE = 'BM_CONSEQUENCE_PRAISE',
  BM_CONSEQUENCE_GUIDANCE = 'BM_CONSEQUENCE_GUIDANCE',
  FLEX_TIME_ACTIVITY_ASSIGNED = 'FLEX_TIME_ACTIVITY_ASSIGNED',
  FLEX_TIME_ACTIVITY_REGISTERED = 'FLEX_TIME_ACTIVITY_REGISTERED',
  FLEX_TIME_ACTIVITY_UNASSIGNED = 'FLEX_TIME_ACTIVITY_UNASSIGNED',
  FLEX_TIME_ACTIVITY_REMOVED = 'FLEX_TIME_ACTIVITY_REMOVED',
  SIS_MASS_DELETE = 'SIS_MASS_DELETE',
  FLEX_TIME_ACTIVITY_CAPACITY_EXCEEDED = 'FLEX_TIME_ACTIVITY_CAPACITY_EXCEEDED',
}
