import { Injectable } from '@angular/core';

import { gateway, stream_pb } from 'libs/generated-grpc-web';
import { feed_event_ng_grpc_pb } from 'libs/generated-grpc-web/src/gateway';
import { Cancellable, cancellableMixin } from 'libs/shared';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import {
  IMgStreamFilter,
  mgStreamControlObservable,
} from '@app/src/app/util/stream';

export interface IEventFeedDateRangeOptions {
  /**
   * Only items from this date range
   */
  dateRange: { start: Date; end: Date };
}

export interface IEventFeedBeforeDateOptions {
  /**
   * Only items before this date
   */
  beforeDate: Date;
}

export interface IEventFeedAfterDateOptions {
  /**
   * Only items before this date
   */
  afterDate: Date;
}

export interface IEventFeedGroupOptions {
  /**
   * Only items belonging to group(s)
   */
  groupHashes?: string[];
}

export interface IEventFeedCategoryOptions {
  /**
   * Only items belonging to categories
   */
  category?: string;
}

export type EventFeedDateOptions =
  | {}
  | IEventFeedDateRangeOptions
  | IEventFeedBeforeDateOptions
  | IEventFeedAfterDateOptions;

export type EventFeedOptions = EventFeedDateOptions &
  IEventFeedGroupOptions &
  IEventFeedCategoryOptions;

function makeStreamFilterFromFeedOptions(
  options: EventFeedOptions,
): IMgStreamFilter {
  const filter: IMgStreamFilter = {};

  if (options.groupHashes) {
    filter.groupHashes = options.groupHashes.join(',');
  }

  if (options.category) {
    filter.category = options.category;
  }

  if ('dateRange' in options) {
    const filterOp = new stream_pb.StreamFilterOp();
    const filterOpValue = new stream_pb.StreamFilterOp.WithinInclusiveValue();
    const filterOpStartValue = new stream_pb.StreamFilterOp.Value();
    const filterOpEndValue = new stream_pb.StreamFilterOp.Value();

    filterOpStartValue.setDoubleValue(options.dateRange.start.getTime());
    filterOpEndValue.setDoubleValue(options.dateRange.end.getTime());

    filterOpValue.setStartValue(filterOpStartValue);
    filterOpValue.setEndValue(filterOpEndValue);

    filterOp.setWithinInclusiveValue(filterOpValue);

    filter['templateData.startTimestamp'] = filterOp;
  } else if ('beforeDate' in options) {
    const filterOp = new stream_pb.StreamFilterOp();
    const filterValue = new stream_pb.StreamFilterOp.Value();
    const filterLtValue = new stream_pb.StreamFilterOp.LtValue();

    filterValue.setDoubleValue(options.beforeDate.getTime());
    filterLtValue.setValue(filterValue);
    filterOp.setGtValue(filterLtValue);
    filter['templateData.startTimestamp'] = filterOp;
  } else if ('afterDate' in options) {
    const filterOp = new stream_pb.StreamFilterOp();
    const filterValue = new stream_pb.StreamFilterOp.Value();
    const filterGteValue = new stream_pb.StreamFilterOp.GteValue();

    filterValue.setDoubleValue(options.afterDate.getTime());
    filterGteValue.setValue(filterValue);
    filterOp.setGtValue(filterGteValue);
    filter['templateData.startTimestamp'] = filterOp;
  }

  return filter;
}

export interface IEventFeedItem {
  contentContext: string;
  title: string;
  description: string;
  start: Date | null;
  end: Date | null;
  allDay: boolean | null;

  /**
   * If available the whole short card view object
   */
  shortCard?: gateway.content_views_pb.ShortEventCardView.AsObject;
}

export function toEventFeedItem(
  item: gateway.content_views_pb.ShortEventCardView.AsObject,
): IEventFeedItem | null {
  if (!item) {
    return null;
  }

  const contentContext = item.contextHash;
  let title: string = '';
  let description: string = '';
  let start: Date | null = null;
  let end: Date | null = null;
  let allDay: boolean = item.allDay;

  title = item.title;
  description = item.body;
  if (item.startTimestamp) {
    start = new Date(item.startTimestamp);
  }
  if (item.endTimestamp) {
    end = new Date(item.endTimestamp);
  }

  return {
    contentContext,
    title,
    description,
    start,
    end,
    allDay,
    shortCard: item,
  };
}

@Injectable({ providedIn: 'root' })
export class EventFeed {
  constructor(
    private feedEventManager: gateway.feed_event_ng_grpc_pb.FeedEventManager,
  ) {}

  getEvents(
    options: EventFeedOptions,
  ): Cancellable<Observable<IEventFeedItem[]>> {
    const filter = makeStreamFilterFromFeedOptions(options);
    const stream = mgStreamControlObservable<
      IEventFeedItem,
      feed_event_ng_grpc_pb.FeedEventManager
    >(
      this.feedEventManager,
      'streamAllEventsControl',
      'streamAllEvents',
      filter,
      item => toEventFeedItem(item),
    );

    let seeked = false;
    let timeout: any;
    const loadingSub = stream.loading$.subscribe(loading => {
      clearTimeout(timeout);
      timeout = setTimeout(timeoutHandler, 2000);

      function timeoutHandler() {
        if (!loading && !seeked) {
          seeked = true;
          if (!stream.isDone && !stream.frontExhausted) {
            stream.seekFront();
          } else {
            stream.done();
            clearTimeout(timeout);
          }
        } else {
          seeked = false;
          timeout = setTimeout(timeoutHandler, 2000);
        }
      }
    });

    stream.seekFront();

    const itemSort = (a: IEventFeedItem, b: IEventFeedItem): number => {
      if (a.start && !b.start) return -1;
      if (!a.start && b.start) return 1;
      if (!a.start && !b.start) return 0;

      const aTime = a.start!.getTime();
      const bTime = b.start!.getTime();

      if (aTime > bTime) return 1;
      if (aTime < bTime) return -1;

      return 0;
    };

    return cancellableMixin(
      stream.asObservable().pipe(
        map(items =>
          items
            .filter(item => !!item.item)
            .map(item => item.item)
            .sort(itemSort),
        ),
      ),
      () => stream.done(),
    );
  }
}
