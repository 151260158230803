// source: gateway/join.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

import * as jspb from 'google-protobuf';
import * as image_image_pb from '../image/image_pb';
export namespace PublicJoinMingaInfo {
  export interface AsObject {
    image?: image_image_pb.ImageInfo.AsObject;
    title: string;
    mingaName: string;
  }
}
export class PublicJoinMingaInfo extends jspb.Message {
  static readonly displayName = "PublicJoinMingaInfo";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): PublicJoinMingaInfo.AsObject {
    return PublicJoinMingaInfo.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!PublicJoinMingaInfo} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: PublicJoinMingaInfo) {
    var f: any;
    var obj: any = {
      image: (f = msg.getImage()) && image_image_pb.ImageInfo.toObject(includeInstance, f),
      title: jspb.Message.getFieldWithDefault(msg, 2, ""),
      mingaName: jspb.Message.getFieldWithDefault(msg, 3, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!PublicJoinMingaInfo}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new PublicJoinMingaInfo;
    return PublicJoinMingaInfo.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!PublicJoinMingaInfo} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!PublicJoinMingaInfo}
   */
  static deserializeBinaryFromReader(msg: PublicJoinMingaInfo, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new image_image_pb.ImageInfo;
        reader.readMessage(value1,image_image_pb.ImageInfo.deserializeBinaryFromReader);
        msg.setImage(value1);
        break;
      case 2:
        var value2 = /** @type {string} */ (reader.readString());
        msg.setTitle(value2);
        break;
      case 3:
        var value3 = /** @type {string} */ (reader.readString());
        msg.setMingaName(value3);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!PublicJoinMingaInfo} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: PublicJoinMingaInfo, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getImage();
    if (f != null) {
      writer.writeMessage(
        1,
        f,
        image_image_pb.ImageInfo.serializeBinaryToWriter
      );
    }
    f = message.getTitle();
    if (f.length > 0) {
      writer.writeString(
        2,
        f
      );
    }
    f = message.getMingaName();
    if (f.length > 0) {
      writer.writeString(
        3,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    PublicJoinMingaInfo.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional ImageInfo image = 1;
   * @return {?ImageInfo}
   */
  getImage(): image_image_pb.ImageInfo {
    return /** @type{?ImageInfo} */ (
      jspb.Message.getWrapperField(this, image_image_pb.ImageInfo, 1));
  }


  /** @param {?ImageInfo|undefined} value */
  setImage(value?: image_image_pb.ImageInfo) {
    jspb.Message.setWrapperField(this, 1, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearImage() {
    this.setImage(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasImage(): boolean {
    return jspb.Message.getField(this, 1) != null;
  }


  /**
   * optional string title = 2;
   * @return {string}
   */
  getTitle(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
  };


  /** @param {string} value */
  setTitle(value: string) {
    jspb.Message.setProto3StringField(this, 2, value);
  }


  /**
   * optional string minga_name = 3;
   * @return {string}
   */
  getMingaName(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
  };


  /** @param {string} value */
  setMingaName(value: string) {
    jspb.Message.setProto3StringField(this, 3, value);
  }


}
export namespace JoinMingaInfo {
  export interface AsObject {
    mingaHash: string;
    asset: string;
    title: string;
    slug: string;
    defaultRoleId: number;
    overrideExistingRole: boolean;
    disallowExistingAccounts: boolean;
    defaultRoleType: string;
  }
}
export class JoinMingaInfo extends jspb.Message {
  static readonly displayName = "JoinMingaInfo";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): JoinMingaInfo.AsObject {
    return JoinMingaInfo.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!JoinMingaInfo} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: JoinMingaInfo) {
    var f: any;
    var obj: any = {
      mingaHash: jspb.Message.getFieldWithDefault(msg, 1, ""),
      asset: jspb.Message.getFieldWithDefault(msg, 2, ""),
      title: jspb.Message.getFieldWithDefault(msg, 3, ""),
      slug: jspb.Message.getFieldWithDefault(msg, 4, ""),
      defaultRoleId: jspb.Message.getFieldWithDefault(msg, 5, 0),
      overrideExistingRole: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
      disallowExistingAccounts: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
      defaultRoleType: jspb.Message.getFieldWithDefault(msg, 8, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!JoinMingaInfo}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new JoinMingaInfo;
    return JoinMingaInfo.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!JoinMingaInfo} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!JoinMingaInfo}
   */
  static deserializeBinaryFromReader(msg: JoinMingaInfo, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setMingaHash(value1);
        break;
      case 2:
        var value2 = /** @type {string} */ (reader.readString());
        msg.setAsset(value2);
        break;
      case 3:
        var value3 = /** @type {string} */ (reader.readString());
        msg.setTitle(value3);
        break;
      case 4:
        var value4 = /** @type {string} */ (reader.readString());
        msg.setSlug(value4);
        break;
      case 5:
        var value5 = /** @type {number} */ (reader.readInt32());
        msg.setDefaultRoleId(value5);
        break;
      case 6:
        var value6 = /** @type {boolean} */ (reader.readBool());
        msg.setOverrideExistingRole(value6);
        break;
      case 7:
        var value7 = /** @type {boolean} */ (reader.readBool());
        msg.setDisallowExistingAccounts(value7);
        break;
      case 8:
        var value8 = /** @type {string} */ (reader.readString());
        msg.setDefaultRoleType(value8);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!JoinMingaInfo} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: JoinMingaInfo, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getMingaHash();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
    f = message.getAsset();
    if (f.length > 0) {
      writer.writeString(
        2,
        f
      );
    }
    f = message.getTitle();
    if (f.length > 0) {
      writer.writeString(
        3,
        f
      );
    }
    f = message.getSlug();
    if (f.length > 0) {
      writer.writeString(
        4,
        f
      );
    }
    f = message.getDefaultRoleId();
    if (f !== 0) {
      writer.writeInt32(
        5,
        f
      );
    }
    f = message.getOverrideExistingRole();
    if (f) {
      writer.writeBool(
        6,
        f
      );
    }
    f = message.getDisallowExistingAccounts();
    if (f) {
      writer.writeBool(
        7,
        f
      );
    }
    f = message.getDefaultRoleType();
    if (f.length > 0) {
      writer.writeString(
        8,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    JoinMingaInfo.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string minga_hash = 1;
   * @return {string}
   */
  getMingaHash(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setMingaHash(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


  /**
   * optional string asset = 2;
   * @return {string}
   */
  getAsset(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
  };


  /** @param {string} value */
  setAsset(value: string) {
    jspb.Message.setProto3StringField(this, 2, value);
  }


  /**
   * optional string title = 3;
   * @return {string}
   */
  getTitle(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
  };


  /** @param {string} value */
  setTitle(value: string) {
    jspb.Message.setProto3StringField(this, 3, value);
  }


  /**
   * optional string slug = 4;
   * @return {string}
   */
  getSlug(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
  };


  /** @param {string} value */
  setSlug(value: string) {
    jspb.Message.setProto3StringField(this, 4, value);
  }


  /**
   * optional int32 default_role_id = 5;
   * @return {number}
   */
  getDefaultRoleId(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
  };


  /** @param {number} value */
  setDefaultRoleId(value: number) {
    jspb.Message.setProto3IntField(this, 5, value);
  }


  /**
   * optional bool override_existing_role = 6;
   * @return {boolean}
   */
  getOverrideExistingRole(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
  };


  /** @param {boolean} value */
  setOverrideExistingRole(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 6, value);
  }


  /**
   * optional bool disallow_existing_accounts = 7;
   * @return {boolean}
   */
  getDisallowExistingAccounts(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
  };


  /** @param {boolean} value */
  setDisallowExistingAccounts(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 7, value);
  }


  /**
   * optional string default_role_type = 8;
   * @return {string}
   */
  getDefaultRoleType(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
  };


  /** @param {string} value */
  setDefaultRoleType(value: string) {
    jspb.Message.setProto3StringField(this, 8, value);
  }


}
export namespace FullJoinMingaInfo {
  export interface AsObject {
    joinMingaInfo?: JoinMingaInfo.AsObject;
    publicJoinMingaInfo?: PublicJoinMingaInfo.AsObject;
  }
}
export class FullJoinMingaInfo extends jspb.Message {
  static readonly displayName = "FullJoinMingaInfo";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): FullJoinMingaInfo.AsObject {
    return FullJoinMingaInfo.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!FullJoinMingaInfo} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: FullJoinMingaInfo) {
    var f: any;
    var obj: any = {
      joinMingaInfo: (f = msg.getJoinMingaInfo()) && JoinMingaInfo.toObject(includeInstance, f),
      publicJoinMingaInfo: (f = msg.getPublicJoinMingaInfo()) && PublicJoinMingaInfo.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!FullJoinMingaInfo}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new FullJoinMingaInfo;
    return FullJoinMingaInfo.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!FullJoinMingaInfo} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!FullJoinMingaInfo}
   */
  static deserializeBinaryFromReader(msg: FullJoinMingaInfo, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new JoinMingaInfo;
        reader.readMessage(value1,JoinMingaInfo.deserializeBinaryFromReader);
        msg.setJoinMingaInfo(value1);
        break;
      case 2:
        var value2 = new PublicJoinMingaInfo;
        reader.readMessage(value2,PublicJoinMingaInfo.deserializeBinaryFromReader);
        msg.setPublicJoinMingaInfo(value2);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!FullJoinMingaInfo} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: FullJoinMingaInfo, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getJoinMingaInfo();
    if (f != null) {
      writer.writeMessage(
        1,
        f,
        JoinMingaInfo.serializeBinaryToWriter
      );
    }
    f = message.getPublicJoinMingaInfo();
    if (f != null) {
      writer.writeMessage(
        2,
        f,
        PublicJoinMingaInfo.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    FullJoinMingaInfo.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional JoinMingaInfo join_minga_info = 1;
   * @return {?JoinMingaInfo}
   */
  getJoinMingaInfo(): JoinMingaInfo {
    return /** @type{?JoinMingaInfo} */ (
      jspb.Message.getWrapperField(this, JoinMingaInfo, 1));
  }


  /** @param {?JoinMingaInfo|undefined} value */
  setJoinMingaInfo(value?: JoinMingaInfo) {
    jspb.Message.setWrapperField(this, 1, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearJoinMingaInfo() {
    this.setJoinMingaInfo(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasJoinMingaInfo(): boolean {
    return jspb.Message.getField(this, 1) != null;
  }


  /**
   * optional PublicJoinMingaInfo public_join_minga_info = 2;
   * @return {?PublicJoinMingaInfo}
   */
  getPublicJoinMingaInfo(): PublicJoinMingaInfo {
    return /** @type{?PublicJoinMingaInfo} */ (
      jspb.Message.getWrapperField(this, PublicJoinMingaInfo, 2));
  }


  /** @param {?PublicJoinMingaInfo|undefined} value */
  setPublicJoinMingaInfo(value?: PublicJoinMingaInfo) {
    jspb.Message.setWrapperField(this, 2, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearPublicJoinMingaInfo() {
    this.setPublicJoinMingaInfo(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasPublicJoinMingaInfo(): boolean {
    return jspb.Message.getField(this, 2) != null;
  }


}
export namespace GetJoinMingaInfoRequest {
  export interface AsObject {
    joinMingaSlug: string;
  }
}
export class GetJoinMingaInfoRequest extends jspb.Message {
  static readonly displayName = "GetJoinMingaInfoRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): GetJoinMingaInfoRequest.AsObject {
    return GetJoinMingaInfoRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!GetJoinMingaInfoRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: GetJoinMingaInfoRequest) {
    var f: any;
    var obj: any = {
      joinMingaSlug: jspb.Message.getFieldWithDefault(msg, 1, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!GetJoinMingaInfoRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new GetJoinMingaInfoRequest;
    return GetJoinMingaInfoRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!GetJoinMingaInfoRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!GetJoinMingaInfoRequest}
   */
  static deserializeBinaryFromReader(msg: GetJoinMingaInfoRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setJoinMingaSlug(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!GetJoinMingaInfoRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: GetJoinMingaInfoRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getJoinMingaSlug();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    GetJoinMingaInfoRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string join_minga_slug = 1;
   * @return {string}
   */
  getJoinMingaSlug(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setJoinMingaSlug(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


}
export namespace GetJoinMingaInfoResponse {
  export interface AsObject {
    joinMingaInfo?: JoinMingaInfo.AsObject;
    publicJoinMingaInfo?: PublicJoinMingaInfo.AsObject;
  }
}
export class GetJoinMingaInfoResponse extends jspb.Message {
  static readonly displayName = "GetJoinMingaInfoResponse";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): GetJoinMingaInfoResponse.AsObject {
    return GetJoinMingaInfoResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!GetJoinMingaInfoResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: GetJoinMingaInfoResponse) {
    var f: any;
    var obj: any = {
      joinMingaInfo: (f = msg.getJoinMingaInfo()) && JoinMingaInfo.toObject(includeInstance, f),
      publicJoinMingaInfo: (f = msg.getPublicJoinMingaInfo()) && PublicJoinMingaInfo.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!GetJoinMingaInfoResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new GetJoinMingaInfoResponse;
    return GetJoinMingaInfoResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!GetJoinMingaInfoResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!GetJoinMingaInfoResponse}
   */
  static deserializeBinaryFromReader(msg: GetJoinMingaInfoResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new JoinMingaInfo;
        reader.readMessage(value1,JoinMingaInfo.deserializeBinaryFromReader);
        msg.setJoinMingaInfo(value1);
        break;
      case 2:
        var value2 = new PublicJoinMingaInfo;
        reader.readMessage(value2,PublicJoinMingaInfo.deserializeBinaryFromReader);
        msg.setPublicJoinMingaInfo(value2);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!GetJoinMingaInfoResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: GetJoinMingaInfoResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getJoinMingaInfo();
    if (f != null) {
      writer.writeMessage(
        1,
        f,
        JoinMingaInfo.serializeBinaryToWriter
      );
    }
    f = message.getPublicJoinMingaInfo();
    if (f != null) {
      writer.writeMessage(
        2,
        f,
        PublicJoinMingaInfo.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    GetJoinMingaInfoResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional JoinMingaInfo join_minga_info = 1;
   * @return {?JoinMingaInfo}
   */
  getJoinMingaInfo(): JoinMingaInfo {
    return /** @type{?JoinMingaInfo} */ (
      jspb.Message.getWrapperField(this, JoinMingaInfo, 1));
  }


  /** @param {?JoinMingaInfo|undefined} value */
  setJoinMingaInfo(value?: JoinMingaInfo) {
    jspb.Message.setWrapperField(this, 1, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearJoinMingaInfo() {
    this.setJoinMingaInfo(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasJoinMingaInfo(): boolean {
    return jspb.Message.getField(this, 1) != null;
  }


  /**
   * optional PublicJoinMingaInfo public_join_minga_info = 2;
   * @return {?PublicJoinMingaInfo}
   */
  getPublicJoinMingaInfo(): PublicJoinMingaInfo {
    return /** @type{?PublicJoinMingaInfo} */ (
      jspb.Message.getWrapperField(this, PublicJoinMingaInfo, 2));
  }


  /** @param {?PublicJoinMingaInfo|undefined} value */
  setPublicJoinMingaInfo(value?: PublicJoinMingaInfo) {
    jspb.Message.setWrapperField(this, 2, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearPublicJoinMingaInfo() {
    this.setPublicJoinMingaInfo(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasPublicJoinMingaInfo(): boolean {
    return jspb.Message.getField(this, 2) != null;
  }


}
export namespace CreateJoinMingaInfoRequest {
  export interface AsObject {
    joinMingaSlug: string;
    joinMingaInfo?: JoinMingaInfo.AsObject;
  }
}
export class CreateJoinMingaInfoRequest extends jspb.Message {
  static readonly displayName = "CreateJoinMingaInfoRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): CreateJoinMingaInfoRequest.AsObject {
    return CreateJoinMingaInfoRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!CreateJoinMingaInfoRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: CreateJoinMingaInfoRequest) {
    var f: any;
    var obj: any = {
      joinMingaSlug: jspb.Message.getFieldWithDefault(msg, 1, ""),
      joinMingaInfo: (f = msg.getJoinMingaInfo()) && JoinMingaInfo.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!CreateJoinMingaInfoRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new CreateJoinMingaInfoRequest;
    return CreateJoinMingaInfoRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!CreateJoinMingaInfoRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!CreateJoinMingaInfoRequest}
   */
  static deserializeBinaryFromReader(msg: CreateJoinMingaInfoRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setJoinMingaSlug(value1);
        break;
      case 2:
        var value2 = new JoinMingaInfo;
        reader.readMessage(value2,JoinMingaInfo.deserializeBinaryFromReader);
        msg.setJoinMingaInfo(value2);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!CreateJoinMingaInfoRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: CreateJoinMingaInfoRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getJoinMingaSlug();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
    f = message.getJoinMingaInfo();
    if (f != null) {
      writer.writeMessage(
        2,
        f,
        JoinMingaInfo.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    CreateJoinMingaInfoRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string join_minga_slug = 1;
   * @return {string}
   */
  getJoinMingaSlug(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setJoinMingaSlug(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


  /**
   * optional JoinMingaInfo join_minga_info = 2;
   * @return {?JoinMingaInfo}
   */
  getJoinMingaInfo(): JoinMingaInfo {
    return /** @type{?JoinMingaInfo} */ (
      jspb.Message.getWrapperField(this, JoinMingaInfo, 2));
  }


  /** @param {?JoinMingaInfo|undefined} value */
  setJoinMingaInfo(value?: JoinMingaInfo) {
    jspb.Message.setWrapperField(this, 2, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearJoinMingaInfo() {
    this.setJoinMingaInfo(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasJoinMingaInfo(): boolean {
    return jspb.Message.getField(this, 2) != null;
  }


}
export namespace CreateJoinMingaInfoResponse {
  export interface AsObject {
    joinMingaInfo?: JoinMingaInfo.AsObject;
    publicJoinMingaInfo?: PublicJoinMingaInfo.AsObject;
  }
}
export class CreateJoinMingaInfoResponse extends jspb.Message {
  static readonly displayName = "CreateJoinMingaInfoResponse";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): CreateJoinMingaInfoResponse.AsObject {
    return CreateJoinMingaInfoResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!CreateJoinMingaInfoResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: CreateJoinMingaInfoResponse) {
    var f: any;
    var obj: any = {
      joinMingaInfo: (f = msg.getJoinMingaInfo()) && JoinMingaInfo.toObject(includeInstance, f),
      publicJoinMingaInfo: (f = msg.getPublicJoinMingaInfo()) && PublicJoinMingaInfo.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!CreateJoinMingaInfoResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new CreateJoinMingaInfoResponse;
    return CreateJoinMingaInfoResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!CreateJoinMingaInfoResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!CreateJoinMingaInfoResponse}
   */
  static deserializeBinaryFromReader(msg: CreateJoinMingaInfoResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new JoinMingaInfo;
        reader.readMessage(value1,JoinMingaInfo.deserializeBinaryFromReader);
        msg.setJoinMingaInfo(value1);
        break;
      case 2:
        var value2 = new PublicJoinMingaInfo;
        reader.readMessage(value2,PublicJoinMingaInfo.deserializeBinaryFromReader);
        msg.setPublicJoinMingaInfo(value2);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!CreateJoinMingaInfoResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: CreateJoinMingaInfoResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getJoinMingaInfo();
    if (f != null) {
      writer.writeMessage(
        1,
        f,
        JoinMingaInfo.serializeBinaryToWriter
      );
    }
    f = message.getPublicJoinMingaInfo();
    if (f != null) {
      writer.writeMessage(
        2,
        f,
        PublicJoinMingaInfo.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    CreateJoinMingaInfoResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional JoinMingaInfo join_minga_info = 1;
   * @return {?JoinMingaInfo}
   */
  getJoinMingaInfo(): JoinMingaInfo {
    return /** @type{?JoinMingaInfo} */ (
      jspb.Message.getWrapperField(this, JoinMingaInfo, 1));
  }


  /** @param {?JoinMingaInfo|undefined} value */
  setJoinMingaInfo(value?: JoinMingaInfo) {
    jspb.Message.setWrapperField(this, 1, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearJoinMingaInfo() {
    this.setJoinMingaInfo(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasJoinMingaInfo(): boolean {
    return jspb.Message.getField(this, 1) != null;
  }


  /**
   * optional PublicJoinMingaInfo public_join_minga_info = 2;
   * @return {?PublicJoinMingaInfo}
   */
  getPublicJoinMingaInfo(): PublicJoinMingaInfo {
    return /** @type{?PublicJoinMingaInfo} */ (
      jspb.Message.getWrapperField(this, PublicJoinMingaInfo, 2));
  }


  /** @param {?PublicJoinMingaInfo|undefined} value */
  setPublicJoinMingaInfo(value?: PublicJoinMingaInfo) {
    jspb.Message.setWrapperField(this, 2, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearPublicJoinMingaInfo() {
    this.setPublicJoinMingaInfo(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasPublicJoinMingaInfo(): boolean {
    return jspb.Message.getField(this, 2) != null;
  }


}
export namespace ModifyJoinMingaInfoRequest {
  export interface AsObject {
    joinMingaSlug: string;
    joinMingaInfo?: JoinMingaInfo.AsObject;
  }
}
export class ModifyJoinMingaInfoRequest extends jspb.Message {
  static readonly displayName = "ModifyJoinMingaInfoRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): ModifyJoinMingaInfoRequest.AsObject {
    return ModifyJoinMingaInfoRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!ModifyJoinMingaInfoRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: ModifyJoinMingaInfoRequest) {
    var f: any;
    var obj: any = {
      joinMingaSlug: jspb.Message.getFieldWithDefault(msg, 1, ""),
      joinMingaInfo: (f = msg.getJoinMingaInfo()) && JoinMingaInfo.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!ModifyJoinMingaInfoRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new ModifyJoinMingaInfoRequest;
    return ModifyJoinMingaInfoRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!ModifyJoinMingaInfoRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!ModifyJoinMingaInfoRequest}
   */
  static deserializeBinaryFromReader(msg: ModifyJoinMingaInfoRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setJoinMingaSlug(value1);
        break;
      case 2:
        var value2 = new JoinMingaInfo;
        reader.readMessage(value2,JoinMingaInfo.deserializeBinaryFromReader);
        msg.setJoinMingaInfo(value2);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!ModifyJoinMingaInfoRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: ModifyJoinMingaInfoRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getJoinMingaSlug();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
    f = message.getJoinMingaInfo();
    if (f != null) {
      writer.writeMessage(
        2,
        f,
        JoinMingaInfo.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    ModifyJoinMingaInfoRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string join_minga_slug = 1;
   * @return {string}
   */
  getJoinMingaSlug(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setJoinMingaSlug(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


  /**
   * optional JoinMingaInfo join_minga_info = 2;
   * @return {?JoinMingaInfo}
   */
  getJoinMingaInfo(): JoinMingaInfo {
    return /** @type{?JoinMingaInfo} */ (
      jspb.Message.getWrapperField(this, JoinMingaInfo, 2));
  }


  /** @param {?JoinMingaInfo|undefined} value */
  setJoinMingaInfo(value?: JoinMingaInfo) {
    jspb.Message.setWrapperField(this, 2, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearJoinMingaInfo() {
    this.setJoinMingaInfo(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasJoinMingaInfo(): boolean {
    return jspb.Message.getField(this, 2) != null;
  }


}
export namespace ModifyJoinMingaInfoResponse {
  export interface AsObject {
    joinMingaInfo?: JoinMingaInfo.AsObject;
    publicJoinMingaInfo?: PublicJoinMingaInfo.AsObject;
  }
}
export class ModifyJoinMingaInfoResponse extends jspb.Message {
  static readonly displayName = "ModifyJoinMingaInfoResponse";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): ModifyJoinMingaInfoResponse.AsObject {
    return ModifyJoinMingaInfoResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!ModifyJoinMingaInfoResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: ModifyJoinMingaInfoResponse) {
    var f: any;
    var obj: any = {
      joinMingaInfo: (f = msg.getJoinMingaInfo()) && JoinMingaInfo.toObject(includeInstance, f),
      publicJoinMingaInfo: (f = msg.getPublicJoinMingaInfo()) && PublicJoinMingaInfo.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!ModifyJoinMingaInfoResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new ModifyJoinMingaInfoResponse;
    return ModifyJoinMingaInfoResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!ModifyJoinMingaInfoResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!ModifyJoinMingaInfoResponse}
   */
  static deserializeBinaryFromReader(msg: ModifyJoinMingaInfoResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new JoinMingaInfo;
        reader.readMessage(value1,JoinMingaInfo.deserializeBinaryFromReader);
        msg.setJoinMingaInfo(value1);
        break;
      case 2:
        var value2 = new PublicJoinMingaInfo;
        reader.readMessage(value2,PublicJoinMingaInfo.deserializeBinaryFromReader);
        msg.setPublicJoinMingaInfo(value2);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!ModifyJoinMingaInfoResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: ModifyJoinMingaInfoResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getJoinMingaInfo();
    if (f != null) {
      writer.writeMessage(
        1,
        f,
        JoinMingaInfo.serializeBinaryToWriter
      );
    }
    f = message.getPublicJoinMingaInfo();
    if (f != null) {
      writer.writeMessage(
        2,
        f,
        PublicJoinMingaInfo.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    ModifyJoinMingaInfoResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional JoinMingaInfo join_minga_info = 1;
   * @return {?JoinMingaInfo}
   */
  getJoinMingaInfo(): JoinMingaInfo {
    return /** @type{?JoinMingaInfo} */ (
      jspb.Message.getWrapperField(this, JoinMingaInfo, 1));
  }


  /** @param {?JoinMingaInfo|undefined} value */
  setJoinMingaInfo(value?: JoinMingaInfo) {
    jspb.Message.setWrapperField(this, 1, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearJoinMingaInfo() {
    this.setJoinMingaInfo(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasJoinMingaInfo(): boolean {
    return jspb.Message.getField(this, 1) != null;
  }


  /**
   * optional PublicJoinMingaInfo public_join_minga_info = 2;
   * @return {?PublicJoinMingaInfo}
   */
  getPublicJoinMingaInfo(): PublicJoinMingaInfo {
    return /** @type{?PublicJoinMingaInfo} */ (
      jspb.Message.getWrapperField(this, PublicJoinMingaInfo, 2));
  }


  /** @param {?PublicJoinMingaInfo|undefined} value */
  setPublicJoinMingaInfo(value?: PublicJoinMingaInfo) {
    jspb.Message.setWrapperField(this, 2, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearPublicJoinMingaInfo() {
    this.setPublicJoinMingaInfo(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasPublicJoinMingaInfo(): boolean {
    return jspb.Message.getField(this, 2) != null;
  }


}
export namespace DeleteJoinMingaInfoRequest {
  export interface AsObject {
    joinMingaSlug: string;
  }
}
export class DeleteJoinMingaInfoRequest extends jspb.Message {
  static readonly displayName = "DeleteJoinMingaInfoRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): DeleteJoinMingaInfoRequest.AsObject {
    return DeleteJoinMingaInfoRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!DeleteJoinMingaInfoRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: DeleteJoinMingaInfoRequest) {
    var f: any;
    var obj: any = {
      joinMingaSlug: jspb.Message.getFieldWithDefault(msg, 1, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!DeleteJoinMingaInfoRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new DeleteJoinMingaInfoRequest;
    return DeleteJoinMingaInfoRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!DeleteJoinMingaInfoRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!DeleteJoinMingaInfoRequest}
   */
  static deserializeBinaryFromReader(msg: DeleteJoinMingaInfoRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setJoinMingaSlug(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!DeleteJoinMingaInfoRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: DeleteJoinMingaInfoRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getJoinMingaSlug();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    DeleteJoinMingaInfoRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string join_minga_slug = 1;
   * @return {string}
   */
  getJoinMingaSlug(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setJoinMingaSlug(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


}
export namespace DeleteJoinMingaInfoResponse {
  export interface AsObject {
  }
}
export class DeleteJoinMingaInfoResponse extends jspb.Message {
  static readonly displayName = "DeleteJoinMingaInfoResponse";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): DeleteJoinMingaInfoResponse.AsObject {
    return DeleteJoinMingaInfoResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!DeleteJoinMingaInfoResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: DeleteJoinMingaInfoResponse) {
    var f: any;
    var obj: any = {

    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!DeleteJoinMingaInfoResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new DeleteJoinMingaInfoResponse;
    return DeleteJoinMingaInfoResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!DeleteJoinMingaInfoResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!DeleteJoinMingaInfoResponse}
   */
  static deserializeBinaryFromReader(msg: DeleteJoinMingaInfoResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!DeleteJoinMingaInfoResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: DeleteJoinMingaInfoResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    DeleteJoinMingaInfoResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
}
export namespace ListJoinMingaInfoRequest {
  export interface AsObject {
    mingaHash: string;
  }
}
export class ListJoinMingaInfoRequest extends jspb.Message {
  static readonly displayName = "ListJoinMingaInfoRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): ListJoinMingaInfoRequest.AsObject {
    return ListJoinMingaInfoRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!ListJoinMingaInfoRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: ListJoinMingaInfoRequest) {
    var f: any;
    var obj: any = {
      mingaHash: jspb.Message.getFieldWithDefault(msg, 1, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!ListJoinMingaInfoRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new ListJoinMingaInfoRequest;
    return ListJoinMingaInfoRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!ListJoinMingaInfoRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!ListJoinMingaInfoRequest}
   */
  static deserializeBinaryFromReader(msg: ListJoinMingaInfoRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setMingaHash(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!ListJoinMingaInfoRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: ListJoinMingaInfoRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getMingaHash();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    ListJoinMingaInfoRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string minga_hash = 1;
   * @return {string}
   */
  getMingaHash(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setMingaHash(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


}
export namespace ListJoinMingaInfoResponse {
  export interface AsObject {
    fullJoinMingaInfoList: FullJoinMingaInfo.AsObject[];
  }
}
export class ListJoinMingaInfoResponse extends jspb.Message {
  static readonly displayName = "ListJoinMingaInfoResponse";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [1];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, ListJoinMingaInfoResponse.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): ListJoinMingaInfoResponse.AsObject {
    return ListJoinMingaInfoResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!ListJoinMingaInfoResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: ListJoinMingaInfoResponse) {
    var f: any;
    var obj: any = {
      fullJoinMingaInfoList: jspb.Message.toObjectList(msg.getFullJoinMingaInfoList(),
      FullJoinMingaInfo.toObject, includeInstance)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!ListJoinMingaInfoResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new ListJoinMingaInfoResponse;
    return ListJoinMingaInfoResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!ListJoinMingaInfoResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!ListJoinMingaInfoResponse}
   */
  static deserializeBinaryFromReader(msg: ListJoinMingaInfoResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new FullJoinMingaInfo;
        reader.readMessage(value1,FullJoinMingaInfo.deserializeBinaryFromReader);
        msg.addFullJoinMingaInfo(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!ListJoinMingaInfoResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: ListJoinMingaInfoResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getFullJoinMingaInfoList();
    if (f.length > 0) {
      writer.writeRepeatedMessage(
        1,
        f,
        FullJoinMingaInfo.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    ListJoinMingaInfoResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * repeated FullJoinMingaInfo full_join_minga_info = 1;
   * @return {!Array<!FullJoinMingaInfo>}
   */
  getFullJoinMingaInfoList(): FullJoinMingaInfo[] {
    return /** @type{!Array<!FullJoinMingaInfo>} */ (
      jspb.Message.getRepeatedWrapperField(this, FullJoinMingaInfo, 1));
  }


  /** @param {!Array<!FullJoinMingaInfo>} value */
  setFullJoinMingaInfoList(value?: FullJoinMingaInfo[]) {
    jspb.Message.setRepeatedWrapperField(this, 1, value);
  }


  /**
   * @param {!FullJoinMingaInfo=} opt_value
   * @param {number=} opt_index
   * @return {!FullJoinMingaInfo}
   */
  addFullJoinMingaInfo(opt_value?: FullJoinMingaInfo, opt_index?: number): FullJoinMingaInfo {
    return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, FullJoinMingaInfo, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearFullJoinMingaInfoList() {
    this.setFullJoinMingaInfoList([]);
  }


}
export namespace JoinMingaRequest {
  export interface AsObject {
    joinMingaSlug: string;
    email: string;
    firstName: string;
    lastName: string;
  }
}
export class JoinMingaRequest extends jspb.Message {
  static readonly displayName = "JoinMingaRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): JoinMingaRequest.AsObject {
    return JoinMingaRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!JoinMingaRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: JoinMingaRequest) {
    var f: any;
    var obj: any = {
      joinMingaSlug: jspb.Message.getFieldWithDefault(msg, 1, ""),
      email: jspb.Message.getFieldWithDefault(msg, 2, ""),
      firstName: jspb.Message.getFieldWithDefault(msg, 3, ""),
      lastName: jspb.Message.getFieldWithDefault(msg, 4, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!JoinMingaRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new JoinMingaRequest;
    return JoinMingaRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!JoinMingaRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!JoinMingaRequest}
   */
  static deserializeBinaryFromReader(msg: JoinMingaRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setJoinMingaSlug(value1);
        break;
      case 2:
        var value2 = /** @type {string} */ (reader.readString());
        msg.setEmail(value2);
        break;
      case 3:
        var value3 = /** @type {string} */ (reader.readString());
        msg.setFirstName(value3);
        break;
      case 4:
        var value4 = /** @type {string} */ (reader.readString());
        msg.setLastName(value4);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!JoinMingaRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: JoinMingaRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getJoinMingaSlug();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
    f = message.getEmail();
    if (f.length > 0) {
      writer.writeString(
        2,
        f
      );
    }
    f = message.getFirstName();
    if (f.length > 0) {
      writer.writeString(
        3,
        f
      );
    }
    f = message.getLastName();
    if (f.length > 0) {
      writer.writeString(
        4,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    JoinMingaRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string join_minga_slug = 1;
   * @return {string}
   */
  getJoinMingaSlug(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setJoinMingaSlug(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


  /**
   * optional string email = 2;
   * @return {string}
   */
  getEmail(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
  };


  /** @param {string} value */
  setEmail(value: string) {
    jspb.Message.setProto3StringField(this, 2, value);
  }


  /**
   * optional string first_name = 3;
   * @return {string}
   */
  getFirstName(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
  };


  /** @param {string} value */
  setFirstName(value: string) {
    jspb.Message.setProto3StringField(this, 3, value);
  }


  /**
   * optional string last_name = 4;
   * @return {string}
   */
  getLastName(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
  };


  /** @param {string} value */
  setLastName(value: string) {
    jspb.Message.setProto3StringField(this, 4, value);
  }


}
export namespace JoinMingaResponse {
  export interface AsObject {
    status?: JoinMingaResponse.Status;
    pin: string;
  }
}
export class JoinMingaResponse extends jspb.Message {
  static readonly displayName = "JoinMingaResponse";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): JoinMingaResponse.AsObject {
    return JoinMingaResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!JoinMingaResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: JoinMingaResponse) {
    var f: any;
    var obj: any = {
      status: jspb.Message.getFieldWithDefault(msg, 1, 0),
      pin: jspb.Message.getFieldWithDefault(msg, 2, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!JoinMingaResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new JoinMingaResponse;
    return JoinMingaResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!JoinMingaResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!JoinMingaResponse}
   */
  static deserializeBinaryFromReader(msg: JoinMingaResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {!JoinMingaResponse.Status} */ (reader.readEnum());
        msg.setStatus(value1);
        break;
      case 2:
        var value2 = /** @type {string} */ (reader.readString());
        msg.setPin(value2);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!JoinMingaResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: JoinMingaResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getStatus();
    if (f !== 0.0) {
      writer.writeEnum(
        1,
        f
      );
    }
    f = message.getPin();
    if (f.length > 0) {
      writer.writeString(
        2,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    JoinMingaResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional Status status = 1;
   * @return {!JoinMingaResponse.Status}
   */
  getStatus(): JoinMingaResponse.Status {
    return /** @type {!JoinMingaResponse.Status} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
  };


  /** @param {!JoinMingaResponse.Status} value */
  setStatus(value: JoinMingaResponse.Status) {
    jspb.Message.setProto3EnumField(this, 1, value);
  }


  /**
   * optional string pin = 2;
   * @return {string}
   */
  getPin(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
  };


  /** @param {string} value */
  setPin(value: string) {
    jspb.Message.setProto3StringField(this, 2, value);
  }


}
export namespace JoinMingaResponse {
export enum Status {
  OK = 0,
  NOT_ALLOWED_EXISTING_ACCOUNT = 1,
  EXISTING_ACCOUNT_MISSMATCH_MINGA = 2,
}
} // namespace JoinMingaResponse
