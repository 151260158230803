import { Pipe, PipeTransform } from '@angular/core';

import { FlexTimeActivityInstance } from 'libs/domain';

@Pipe({ name: 'activityCheckin' })
export class ActivityCheckinPipe implements PipeTransform {
  constructor() {}

  transform(instance: FlexTimeActivityInstance): string {
    const registered = +instance.registered || 0;
    const checkinCount = +instance.checkinCount || 0;

    return `${checkinCount} / ${registered}`;
  }
}
