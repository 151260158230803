<ng-container
  *ngIf="dailySchedules$ | async as dailySchedules; else noActivitiesTemplate">
  <div class="tw-flex tw-flex-column tw-gap-6">
    <div
      *ngFor="let day of dailySchedules"
      class="tw-flex tw-flex-column tw-gap-2">
      <ng-container *ngTemplateOutlet="daySectionHeader; context: { day: day }">
      </ng-container>
      <div *ngFor="let period of day.periods">
        <ng-container
          *ngTemplateOutlet="periodCardTemplate; context: { period: period }">
        </ng-container>
      </div>
    </div>
  </div>
</ng-container>

<ng-template
  #daySectionHeader
  let-day="day">
  <div class="tw-flex tw-items-center tw-gap-6 tw-mb-4">
    <div class="separator"></div>
    <mg-text
      class="tw-shrink-0"
      variant="header-sm-secondary">
      {{ day.date }}
    </mg-text>
    <div class="separator"></div>
  </div>
</ng-template>

<ng-template
  #periodCardTemplate
  let-period="period">
  <mg-card class="period-card">
    <div class="tw-flex tw-items-end">
      <div class="tw-flex tw-flex-column tw-align-end">
        <mg-text variant="header-md-secondary">
          {{ period.title }}
        </mg-text>
        <div class="tw-flex tw-items-center tw-gap-2">
          <mg-text variant="body-xs">
            {{ period.date | date: 'EEE MMM dd' }}
          </mg-text>
          <div class="divider"></div>
          <mg-text variant="body-xs">
            {{ period | formatTimeRange }}
          </mg-text>
        </div>
      </div>
      <mg-btn
        *ngIf="
          (media.isMobileView$ | async) === false &&
          (('FLEX_TIME_MANAGE' | hasPermission) ||
            (('FLEX_TIME_VIEW_MY_ACTIVITY' | hasPermission) === false &&
              (isProfileView$ | async) === false &&
              period.enableStudentSelfManagement &&
              !period.myRegistration))
        "
        class="tw-ml-auto"
        variant="filled"
        size="small"
        (pressed)="openActivities(period.id)">
        {{ MESSAGES.REGISTER_LABEL }}
      </mg-btn>
    </div>

    <button
      *ngIf="period?.myRegistration; else notRegisteredTemplate"
      class="registration-btn"
      (click)="openActivityModal(period)">
      <div class="registration tw-flex tw-items-center tw-gap-1">
        <div class="activity-image">
          <mg-ftm-activity-img
            [bannerImage]="
              period.myRegistration.registration.flexTimeActivity.bannerImage
            "
            [imagePath]="
              period.myRegistration.registration.flexTimeActivity.imagePath
            "
            [aspectRatio]="1 / 1">
          </mg-ftm-activity-img>
        </div>
        <div class="tw-flex tw-flex-column tw-gap-0.5">
          <ng-container
            *ngTemplateOutlet="statusTagTemplate; context: { period: period }">
          </ng-container>
          <mg-text variant="header-sm">
            {{ period.myRegistration.registration.flexTimeActivity.name }}
          </mg-text>
        </div>
        <mg-icon
          class="chevron-icon tw-ml-auto"
          iconName="mg-chevron-right">
        </mg-icon>
      </div>
    </button>

    <mg-btn
      *ngIf="
        (media.isMobileView$ | async) &&
        (('FLEX_TIME_MANAGE' | hasPermission) ||
          (('FLEX_TIME_VIEW_MY_ACTIVITY' | hasPermission) === false &&
            (isProfileView$ | async) === false &&
            period.enableStudentSelfManagement &&
            !period.myRegistration))
      "
      variant="filled"
      [wide]="true"
      (pressed)="openActivities(period.id)">
      {{ MESSAGES.REGISTER_LABEL }}
    </mg-btn>
  </mg-card>

  <!-- Not Registered Empty State Template -->
  <ng-template #notRegisteredTemplate>
    <mg-empty-state
      class="thin-empty-state"
      [title]="'No registered activity for this period'"
      [subtitle]="
        period.enableStudentSelfManagement
          ? 'Your activity will be visible here'
          : 'Contact your teacher to be registered'
      ">
    </mg-empty-state>
  </ng-template>
</ng-template>

<!-- No Activities Empty State Template -->
<ng-template #noActivitiesTemplate>
  <mg-empty-state [title]="MESSAGES.NO_ACTIVITIES_AVAILABLE"> </mg-empty-state>
</ng-template>

<!-- Period Information Modal Detailed Info Template -->
<ng-template
  #detailedActivityInformationTemplate
  let-period="period">
  <div class="tw-flex tw-flex-column tw-gap-1 tw-items-stretch tw-w-full">
    <div class="tw-flex tw-justify-between">
      <mg-text
        variant="body-sm"
        color="surface-alt">
        {{ MESSAGES.STATUS_LABEL }}
      </mg-text>
      <ng-container
        *ngTemplateOutlet="statusTagTemplate; context: { period: period }">
      </ng-container>
    </div>
    <div class="tw-flex tw-justify-between">
      <mg-text
        variant="body-sm"
        color="surface-alt">
        {{ MESSAGES.TEACHER_LABEL }}
      </mg-text>
      <mg-text variant="body-sm">
        {{
          period.myRegistration.registration.flexTimeActivity.createdByPerson
            .name
        }}
      </mg-text>
    </div>
    <div class="tw-flex tw-justify-between">
      <mg-text
        variant="body-sm"
        color="surface-alt">
        {{ MESSAGES.LOCATION_LABEL }}
      </mg-text>
      <mg-text variant="body-sm">
        {{ period.myRegistration.registration.flexTimeActivity.location }}
      </mg-text>
    </div>
  </div>
</ng-template>

<!-- Status Tag Template -->
<ng-template
  #statusTagTemplate
  let-period="period">
  <mg-tag
    variant="filled"
    color="green">
    <div class="tw-flex tw-align-center tw-gap-0.5">
      <mg-text
        variant="label-sm-secondary"
        color="on-success-bg">
        {{
          period.myRegistration.cannotUnregister
            ? MESSAGES.ASSIGNED_LABEL
            : MESSAGES.REGISTERED_LABEL
        }}
      </mg-text>
      <mg-icon
        *ngIf="
          ('FLEXTIME_MANAGE_OWN_ACTIVITY' | hasPermission) === false &&
          period.myRegistration.cannotUnregister
        "
        class="lock-icon"
        iconName="mg-lock">
      </mg-icon>
    </div>
  </mg-tag>
</ng-template>
