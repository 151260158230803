import { Component, Input } from '@angular/core';

import * as _ from 'lodash';
import { gateway } from 'libs/generated-grpc-web';

@Component({
  selector: 'mg-search-content',
  templateUrl: './SearchContent.component.html',
  styleUrls: ['./SearchContent.component.scss'],
})
export class SearchContentComponent {
  @Input()
  query: string = '';

  @Input()
  contentType: string = '';

  @Input()
  groupHashes: string = '';

  get streamFilter() {
    return {
      query: this.query,
      contentType: this.contentType,
      groupHashes: this.groupHashes,
    };
  }

  constructor(public searchManager: gateway.search_ng_grpc_pb.SearchManager) {}
}
