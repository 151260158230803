import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';

import { MingaPermission } from 'libs/domain';

import { SaveCancelDialog } from '@app/src/app/dialog/SaveCancel';
import { RootService } from '@app/src/app/minimal/services/RootService';
import { PermissionsService } from '@app/src/app/permissions';
import { ContentState } from '@app/src/app/services/ContentState';

import { Group, MingaGroupMemberRank } from '../models/Group';
import { GroupsFacadeService } from './GroupsFacade';

export interface GroupContextMenuEvent {
  group: Group;
  x: number;
  y: number;
}

/**
 * Control the context menu for groups.
 */
@Injectable()
export class GroupStateService {
  constructor(
    private rootService: RootService,
    private permissions: PermissionsService,
    private dialog: MatDialog,
    private router: Router,
    private groupsFacade: GroupsFacadeService,
    private contentState: ContentState,
  ) {
    this.contentState.onOverrideAi.subscribe(() => {
      this.groupsFacade.dispatchInvalidateGroupsCollection();
    });
  }

  openEdit(group: Group) {
    this.router.navigate([
      '',
      { outlets: { o: ['groups', 'update', group.hash] } },
    ]);
  }

  async openDelete(group: Group) {
    const options = {
      data: {
        text: 'dialog.deleteGroupConfirm',
        saveButtonLocale: 'button.delete',
      },
    };

    const dialog = this.dialog.open(SaveCancelDialog, options);
    dialog.afterClosed().subscribe(result => {
      if (result === true) {
        this.groupsFacade.dispatchDeleteGroup(group);
        // @TODO: Bazel - Should this be awaited?
        this.router.navigate(['/groups/list']);
      }
    });
  }

  async openOwnerLeaveDialog(group: Group) {
    const options = {
      data: {
        text: 'groups.openOwnerLeaveDialog.text',
        saveButtonLocale: 'groups.openOwnerLeaveDialog.button',
      },
    };

    const dialog = this.dialog.open(SaveCancelDialog, options);
    dialog.afterClosed().subscribe(async result => {
      if (result === true) {
        this.openEdit(group);
      }
    });
  }

  async openLeaveDialog(group: Group, noSuccessInterstitial: boolean = false) {
    const options = {
      data: {
        text: 'groups.openLeaveDialog.text',
        saveButtonLocale: 'groups.openLeaveDialog.button',
        textParams: group.name,
      },
    };

    const dialog = this.dialog.open(SaveCancelDialog, options);
    dialog.afterClosed().subscribe(async result => {
      if (result === true) {
        this.groupsFacade.dispatchLeaveGroup(group, noSuccessInterstitial);
      }
    });
  }
  /**
   * Display the context menu for a group.
   * @param ev
   */
  async onGroupContextMenu(ev: GroupContextMenuEvent) {
    const menuItems: { name: string; click: (e: any) => any }[] = [];

    const perms = await Promise.all([
      this.permissions.hasPermission(MingaPermission.CONTENT_GROUP_MANAGE),
      this.permissions.hasPermission(
        MingaPermission.MINGA_CONTENT_UPDATE_OTHERS,
      ),
    ]);

    let [canDeleteOthers, canUpdateOthers] = perms;

    // are you actually in this group?
    if (ev.group.rank) {
      // only edit your own content
      const isOwner = ev.group.rank === MingaGroupMemberRank.OWNER;
      if (isOwner || canUpdateOthers) {
        menuItems.push({
          name: 'Edit',
          click: e => this.openEdit(ev.group),
        });
      }

      if (isOwner) {
        menuItems.push({
          name: 'Leave Group',
          // click: (e) => this.openOwnerLeaveDialog(ev.group),
          click: e => this.openLeaveDialog(ev.group),
        });
      }

      if (isOwner || canDeleteOthers) {
        menuItems.push({
          name: 'Delete',
          click: e => this.openDelete(ev.group),
        });
      }
      // can't leave group if you are the owner, or pending.
      if (ev.group.rank === MingaGroupMemberRank.MEMBER) {
        menuItems.push({
          name: 'Leave Group',
          click: e => this.openLeaveDialog(ev.group),
        });
      }
    } else {
      if (canUpdateOthers) {
        menuItems.push({
          name: 'Edit',
          click: e => this.openEdit(ev.group),
        });
      }

      // not in the group, but can still delete.
      if (canDeleteOthers) {
        menuItems.push({
          name: 'Delete',
          click: e => this.openDelete(ev.group),
        });
      }
    }
    if (menuItems.length > 0) {
      await this.rootService.openGlobalMenu({
        x: ev.x,
        y: ev.y,
        items: menuItems,
      });
    }
  }
}
