import {
  IPointsHistoryItem,
  IPointsRedeemedResult,
  IPointsSummaryResult,
  ITeamPointsSummaryResult,
} from 'libs/domain';

import { ColumnInfo, ExportNameType } from '../types';
import { gradeFinder, nameFinder, roleFinder } from '../utils';
import { firstName, grade, lastName, role } from './columns.constants';
import { DateColumnKeys, NameColumnKeys } from './common.constants';

const issuedBy: ColumnInfo = {
  header: 'Issued by',
  key: NameColumnKeys.TEACHER_NAME,
  tooltip: true,
  accessorFn: (item: any, value?: ExportNameType) => {
    if (item.imported) return 'Imported';
    if (item.issuedBy) return nameFinder(item.issuedBy, value);
    return '';
  },
  defaultValue: 'Automation',
  type: 'text',
  width: 'flex-medium',
};

const pointsStudentIdCol: ColumnInfo = {
  key: 'studentId',
  header: 'ID Number',
  tooltip: true,
  accessorFn: (item: IPointsHistoryItem | IPointsSummaryResult) =>
    item?.issuedTo?.studentId || '',
  type: 'text',
  width: 'large',
};

export const HISTORY: ColumnInfo[] = [
  {
    ...firstName,
    accessorFn: (item: IPointsHistoryItem) => {
      if (item.teamName) return item.teamName;
      if (item.issuedTo) return nameFinder(item.issuedTo, 'first');
      return '';
    },
  },
  {
    ...lastName,
    accessorFn: (item: IPointsHistoryItem) => {
      if (item.issuedTo) return nameFinder(item.issuedTo, 'last');
      return '';
    },
  },
  pointsStudentIdCol,
  {
    ...grade,
    accessorFn: (item: IPointsHistoryItem) => gradeFinder(item.issuedTo),
  },
  {
    ...role,
    accessorFn: (item: IPointsHistoryItem, value?: boolean) =>
      roleFinder(item.issuedTo, value),
  },
  issuedBy,
  {
    key: 'reason',
    header: 'Reason',
    tooltip: true,
    type: 'text',
    width: 'flex-large',
  },
  {
    key: 'points',
    header: 'Points',
    sort: true,
    type: 'number',
    width: 'small',
  },
  {
    key: DateColumnKeys.CREATED_AT,
    header: 'Date',
    accessorFn: (item: any) => item.date,
    sort: true,
    type: 'date',
    width: 'medium',
  },
];

export const TEAM_POINTS: ColumnInfo[] = [
  {
    key: 'team',
    header: 'Name',
    accessorFn: (item: ITeamPointsSummaryResult) => item.team.name,
    type: 'text',
    width: 'medium',
  },
  {
    key: 'totalPoints',
    header: 'Total Points',
    sort: true,
    type: 'number',
    width: 'large',
  },
];

export const STUDENT_POINTS: ColumnInfo[] = [
  firstName,
  lastName,
  pointsStudentIdCol,
  {
    key: 'grade',
    header: 'Grade',
    accessorFn: (item: IPointsSummaryResult) => item?.issuedTo?.grade,
    type: 'text',
    width: 'small',
  },
  {
    key: 'totalPoints',
    header: 'Total Points',
    sort: true,
    type: 'number',
    width: 'medium',
  },
  {
    key: 'redeemablePoints',
    header: 'Redeemable Points',
    // sort: true,
    type: 'number',
    width: 'large',
    defaultValue: '0',
  },
  {
    key: 'redeemedPoints',
    header: 'Redeemed Points',
    // sort: true,
    type: 'number',
    width: 'large',
    defaultValue: '0',
  },
];

export const REWARDS_REDEEMED: ColumnInfo[] = [
  { key: 'reward', header: 'Name', type: 'text', width: 'flex-medium' },
  { key: 'id', header: 'ID', type: 'text', width: 'large' },
  {
    key: 'count',
    header: 'Count',
    sort: true,
    type: 'number',
    width: 'small',
  },
];

export const REDEMPTION_HISTORY: ColumnInfo[] = [
  firstName,
  lastName,
  pointsStudentIdCol,
  {
    key: 'grade',
    header: 'Grade',
    accessorFn: (item: IPointsRedeemedResult) => item?.issuedTo?.grade,
  },
  {
    ...role,
    accessorFn: (item: IPointsRedeemedResult, value?: boolean) =>
      value ? item.issuedTo.role : item.issuedTo.badgeIconUrl,
  },
  {
    key: 'points',
    header: 'Points Redeemed',
    sort: true,
    type: 'number',
    width: 'medium',
  },
  {
    key: DateColumnKeys.CREATED_AT,
    header: 'Date',
    sort: true,
    accessorFn: (item: IPointsRedeemedResult) => item.date,
    type: 'date',
    width: 'medium',
  },
  { key: 'reward', header: 'Reward', type: 'text', width: 'medium' },
  { key: 'rewardId', header: 'Reward ID', type: 'text', width: 'medium' },
];
