// GENERATED CODE -- DO NOT EDIT!

import {grpc} from '@improbable-eng/grpc-web';

import * as content_video_pb from '../content/video_pb';
import * as content_common_pb from '../content/common_pb';
import * as common_delta_pb from '../common/delta_pb';

export class Video {
  static readonly serviceName = "minga.content.Video";

  static readonly New = {
    methodName: "New",
    service: Video,
    requestStream: false,
    responseStream: false,
    requestType: content_video_pb.VideoInfo,
    responseType: content_common_pb.ContentSaveResult,
  };

  static readonly Update = {
    methodName: "Update",
    service: Video,
    requestStream: false,
    responseStream: false,
    requestType: content_video_pb.DeltaVideoInfo,
    responseType: content_common_pb.ContentSaveResult,
  };

  static readonly GetInfo = {
    methodName: "GetInfo",
    service: Video,
    requestStream: false,
    responseStream: false,
    requestType: content_common_pb.ContentInfoQuery,
    responseType: content_video_pb.VideoInfo,
  };

  static readonly NewCloudflareUploadUrl = {
    methodName: "NewCloudflareUploadUrl",
    service: Video,
    requestStream: false,
    responseStream: false,
    requestType: content_video_pb.ClouldflareUploadUrlRequest,
    responseType: content_video_pb.ClouldflareUploadUrlResponse,
  };

  static readonly GetCloudflareVideoStatus = {
    methodName: "GetCloudflareVideoStatus",
    service: Video,
    requestStream: false,
    responseStream: false,
    requestType: content_video_pb.GetCloudflareVideoStatusRequest,
    responseType: content_video_pb.GetCloudflareVideoStatusResponse,
  };

}

export class VideoClient {
  new() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  update() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  getInfo() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  newCloudflareUploadUrl() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  getCloudflareVideoStatus() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
}
