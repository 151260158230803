import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { MgPipesModule } from '@app/src/app/pipes';

import { MgNotificationBaseModule } from '../MgNotificationBase';
import { MgBadgeNotificationElement } from './MgBadgeNotification.element';

@NgModule({
  imports: [
    // Minga dependencies
    MgNotificationBaseModule,
    MgPipesModule,

    // External dependencies
    CommonModule,
  ],
  declarations: [MgBadgeNotificationElement],
  exports: [MgBadgeNotificationElement],
})
export class MgBadgeNotificationModule {}
