import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatSortModule } from '@angular/material/sort';

import { MgIconModule } from '@app/src/app/icon';
import { GenericModule } from '@app/src/app/shared/components/generic';

import { FormModule } from '@shared/components/form';
import { OverlayModule } from '@shared/components/modal-overlay';
import { ReportTableModule } from '@shared/components/report-table';

import { EventPeopleComponent } from './EventPeople.component';

@NgModule({
  imports: [
    // Minga dependencies
    ReportTableModule,
    OverlayModule,
    FormModule,
    MatSortModule,
    MgIconModule,
    GenericModule,

    // External dependencies
    CommonModule,
  ],
  declarations: [EventPeopleComponent],
  exports: [EventPeopleComponent],
})
export class EventPeopleModule {}
