import { TemplatePortal } from '@angular/cdk/portal';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewContainerRef,
} from '@angular/core';

import { gateway } from 'libs/generated-grpc-web';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { GlobalSearchService } from '@modules/global-search';

import { PortalLayoutService } from '@shared/components/portal-layout';

@Component({
  selector: 'mg-global-search-portal',
  template: `
    <ng-container *ngIf="portalContent">
      <ng-template [cdkPortalOutlet]="portalContent"></ng-template>
    </ng-container>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GlobalSearchPortalComponent implements OnInit, OnDestroy {
  // Cleanup

  private readonly _destroyedSubject = new ReplaySubject<void>(1);

  // Portal content

  public portalContent: TemplatePortal<unknown> | null = null;

  /** Component constructor */
  constructor(
    public portalLayout: PortalLayoutService,
    public searchManager: gateway.search_ng_grpc_pb.SearchManager,
    public search: GlobalSearchService,
    public viewContainerRef: ViewContainerRef,
    private _cdr: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this._portalLayoutSearchSubscription();
  }

  ngOnDestroy(): void {
    this._destroyedSubject.next();
    this._destroyedSubject.complete();
  }

  private _portalLayoutSearchSubscription() {
    return this.portalLayout.searchContent$
      .pipe(takeUntil(this._destroyedSubject))
      .subscribe(templateRef => {
        if (!templateRef) this.portalContent = null;
        else {
          this.portalContent = new TemplatePortal<unknown>(
            templateRef,
            this.viewContainerRef,
          );
          this._cdr.markForCheck();
        }
      });
  }
}
