import { Injectable } from '@angular/core';

import { SentryService } from '@app/src/app/minimal/services/Sentry/Sentry.service';

/**
 * Audio Service
 *
 */
@Injectable({ providedIn: 'root' })
export class AudioPlayerService<T extends string> {
  audioMap = {} as Record<T, HTMLAudioElement>;

  /** Constructor */
  constructor(private _sentryService: SentryService) {}

  public async addAudio(options: T[]) {
    options.forEach(audio => {
      if (this.audioMap[audio]) return;
      this.audioMap[audio] = new Audio(audio);
      this.audioMap[audio].load();
    });
  }

  public async removeAudio(options: T[]) {
    options.forEach(audio => {
      if (this.audioMap[audio]) {
        delete this.audioMap[audio];
      }
    });
  }

  public async playAudio(name: T) {
    try {
      if (this.audioMap[name]) {
        await this.audioMap[name].play();
      }
      // lets swallow this, not super crucial if the audio doesn't play
      // some edge case issues with specific safari/mac os versions throwing error on play
    } catch (e) {
      this._sentryService.captureMessageAsError('failed audio playback', e);
    }
  }
}
