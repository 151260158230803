import { Injectable, NgZone } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { grpc } from '@improbable-eng/grpc-web';

import {
  CheckInToEventRequest,
  CheckInToEventResponse,
  GoToEventRequest,
  GoToEventResponse,
  LeaveEventRequest,
  LeaveEventResponse,
  LikePostRequest,
  LikePostResponse,
  UnlikePostRequest,
  UnlikePostResponse,
} from '../gateway/post_pb';

import {
  PostManager as __PostManager,
} from '../gateway/post_pb_service';

@Injectable({providedIn: 'root'})
export class PostManager {

  constructor(private _ngZone: NgZone) {
  }

  likePost(request: LikePostRequest): Promise<LikePostResponse>;
  likePost(request: LikePostRequest, metadata: grpc.Metadata): Promise<LikePostResponse>;
  likePost(request: LikePostRequest, callback: (err: any|null, response: LikePostResponse, metadata: grpc.Metadata) => void): void;
  likePost(request: LikePostRequest, metadata: grpc.Metadata, callback: (err: any|null, response: LikePostResponse, metadata: grpc.Metadata) => void): void;

  likePost(request: LikePostRequest, arg1?: grpc.Metadata|((err: any|null, response: LikePostResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: LikePostResponse, metadata: grpc.Metadata) => void): Promise<LikePostResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<LikePostResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__PostManager.likePost, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  unlikePost(request: UnlikePostRequest): Promise<UnlikePostResponse>;
  unlikePost(request: UnlikePostRequest, metadata: grpc.Metadata): Promise<UnlikePostResponse>;
  unlikePost(request: UnlikePostRequest, callback: (err: any|null, response: UnlikePostResponse, metadata: grpc.Metadata) => void): void;
  unlikePost(request: UnlikePostRequest, metadata: grpc.Metadata, callback: (err: any|null, response: UnlikePostResponse, metadata: grpc.Metadata) => void): void;

  unlikePost(request: UnlikePostRequest, arg1?: grpc.Metadata|((err: any|null, response: UnlikePostResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: UnlikePostResponse, metadata: grpc.Metadata) => void): Promise<UnlikePostResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<UnlikePostResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__PostManager.unlikePost, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  goToEvent(request: GoToEventRequest): Promise<GoToEventResponse>;
  goToEvent(request: GoToEventRequest, metadata: grpc.Metadata): Promise<GoToEventResponse>;
  goToEvent(request: GoToEventRequest, callback: (err: any|null, response: GoToEventResponse, metadata: grpc.Metadata) => void): void;
  goToEvent(request: GoToEventRequest, metadata: grpc.Metadata, callback: (err: any|null, response: GoToEventResponse, metadata: grpc.Metadata) => void): void;

  goToEvent(request: GoToEventRequest, arg1?: grpc.Metadata|((err: any|null, response: GoToEventResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: GoToEventResponse, metadata: grpc.Metadata) => void): Promise<GoToEventResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<GoToEventResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__PostManager.goToEvent, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  leaveEvent(request: LeaveEventRequest): Promise<LeaveEventResponse>;
  leaveEvent(request: LeaveEventRequest, metadata: grpc.Metadata): Promise<LeaveEventResponse>;
  leaveEvent(request: LeaveEventRequest, callback: (err: any|null, response: LeaveEventResponse, metadata: grpc.Metadata) => void): void;
  leaveEvent(request: LeaveEventRequest, metadata: grpc.Metadata, callback: (err: any|null, response: LeaveEventResponse, metadata: grpc.Metadata) => void): void;

  leaveEvent(request: LeaveEventRequest, arg1?: grpc.Metadata|((err: any|null, response: LeaveEventResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: LeaveEventResponse, metadata: grpc.Metadata) => void): Promise<LeaveEventResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<LeaveEventResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__PostManager.leaveEvent, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  checkInToEvent(request: CheckInToEventRequest): Promise<CheckInToEventResponse>;
  checkInToEvent(request: CheckInToEventRequest, metadata: grpc.Metadata): Promise<CheckInToEventResponse>;
  checkInToEvent(request: CheckInToEventRequest, callback: (err: any|null, response: CheckInToEventResponse, metadata: grpc.Metadata) => void): void;
  checkInToEvent(request: CheckInToEventRequest, metadata: grpc.Metadata, callback: (err: any|null, response: CheckInToEventResponse, metadata: grpc.Metadata) => void): void;

  checkInToEvent(request: CheckInToEventRequest, arg1?: grpc.Metadata|((err: any|null, response: CheckInToEventResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: CheckInToEventResponse, metadata: grpc.Metadata) => void): Promise<CheckInToEventResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<CheckInToEventResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__PostManager.checkInToEvent, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

}

