import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  OnDestroy,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { mingaSettingTypes } from 'libs/util';
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { AuthInfoService } from '@app/src/app/minimal/services/AuthInfo';
import { PointsManagerService } from '@app/src/app/services/PointsManager/PointsManager.service';
import { MingaSettingsService } from '@app/src/app/store/Minga/services';

import { PointsService } from '@shared/services/points/Points.service';

import { StMessages } from '../../student-tools/constants';
import {
  toolsPointsRouterLinks,
  toolsPointsRelativeRouterLinks,
} from './constants/tools-points.constants';

@Component({
  selector: 'mg-tools-points',
  templateUrl: './tools-points.component.html',
  styleUrls: ['./tools-points.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToolsPointsComponent implements OnInit, OnDestroy {
  // Component destroyed state
  private readonly _destroyedSubject = new ReplaySubject<void>(1);

  private _totalPoints = new BehaviorSubject(0);
  public totalPoints$ = this._totalPoints.asObservable();
  private _redeemablePoints = new BehaviorSubject(0);
  public redeemablePoints$ = this._redeemablePoints.asObservable();
  public MESSAGES = StMessages;
  public isRewardsStoreEnabled$: Observable<boolean>;
  public isLeaderboardEnabled$ = this._settingsService.getSettingValueObs(
    mingaSettingTypes.FEATURE_TRACKING_PUBLIC_LEADERBOARD,
  );
  public isBehaviorEnabled$ = this._settingsService.isPbisModuleEnabled();
  private _personHash = this._authService.authPersonHash;

  /** Router Links */
  public routerLinks = toolsPointsRouterLinks;

  constructor(
    private _route: ActivatedRoute,
    private _authService: AuthInfoService,
    private _pointsManager: PointsManagerService,
    private _pointsService: PointsService,
    private _settingsService: MingaSettingsService,
  ) {
    this.isRewardsStoreEnabled$ = this._settingsService.getSettingValueObs(
      mingaSettingTypes.PM_REWARDS_STORE,
    );
  }

  ngOnInit() {
    const profilePersonHash = this._route.snapshot.paramMap.get('hash');
    if (profilePersonHash) {
      this.routerLinks = toolsPointsRelativeRouterLinks;
      this._personHash = profilePersonHash;
    }

    this._getPoints();

    this._pointsService.onPointsRedeemed$
      .pipe(takeUntil(this._destroyedSubject))
      .subscribe(redeemedPoints => {
        // Optimistic update
        const currentPoints = this._redeemablePoints.value;
        this._redeemablePoints.next(currentPoints - redeemedPoints);
      });
  }

  private async _getPoints() {
    const response = await this._pointsManager.getProfilePointsSummary(
      this._personHash,
    );

    this._totalPoints.next(response.totalPoints);

    // we dont want to show negative redeemable points
    const redeemablePoints = Math.max(response.redeemablePoints || 0, 0);
    this._redeemablePoints.next(redeemablePoints);
  }

  ngOnDestroy(): void {
    this._destroyedSubject.next();
    this._destroyedSubject.complete();
  }
}
