// GENERATED CODE -- DO NOT EDIT!

import {grpc} from '@improbable-eng/grpc-web';

import * as gateway_post_pb from '../gateway/post_pb';
import * as common_legacy_pb from '../common/legacy_pb';

export class PostManager {
  static readonly serviceName = "PostManager";

  static readonly likePost = {
    methodName: "likePost",
    service: PostManager,
    requestStream: false,
    responseStream: false,
    requestType: gateway_post_pb.LikePostRequest,
    responseType: gateway_post_pb.LikePostResponse,
  };

  static readonly unlikePost = {
    methodName: "unlikePost",
    service: PostManager,
    requestStream: false,
    responseStream: false,
    requestType: gateway_post_pb.UnlikePostRequest,
    responseType: gateway_post_pb.UnlikePostResponse,
  };

  static readonly goToEvent = {
    methodName: "goToEvent",
    service: PostManager,
    requestStream: false,
    responseStream: false,
    requestType: gateway_post_pb.GoToEventRequest,
    responseType: gateway_post_pb.GoToEventResponse,
  };

  static readonly leaveEvent = {
    methodName: "leaveEvent",
    service: PostManager,
    requestStream: false,
    responseStream: false,
    requestType: gateway_post_pb.LeaveEventRequest,
    responseType: gateway_post_pb.LeaveEventResponse,
  };

  static readonly checkInToEvent = {
    methodName: "checkInToEvent",
    service: PostManager,
    requestStream: false,
    responseStream: false,
    requestType: gateway_post_pb.CheckInToEventRequest,
    responseType: gateway_post_pb.CheckInToEventResponse,
  };

}

export class PostManagerClient {
  likePost() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  unlikePost() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  goToEvent() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  leaveEvent() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  checkInToEvent() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
}
