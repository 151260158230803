// GENERATED CODE -- DO NOT EDIT!

import {grpc} from '@improbable-eng/grpc-web';

import * as gateway_gallery_pb from '../gateway/gallery_pb';
import * as common_stream_pb from '../common/stream_pb';
import * as image_image_pb from '../image/image_pb';
import * as gateway_moderation_pb from '../gateway/moderation_pb';

export class MingaGallery {
  static readonly serviceName = "minga.MingaGallery";

  static readonly GetPhoto = {
    methodName: "GetPhoto",
    service: MingaGallery,
    requestStream: false,
    responseStream: false,
    requestType: gateway_gallery_pb.MingaGalleryPhotoRequest,
    responseType: gateway_gallery_pb.MingaGalleryPhotoResponse,
  };

  static readonly Create = {
    methodName: "Create",
    service: MingaGallery,
    requestStream: false,
    responseStream: false,
    requestType: gateway_gallery_pb.MingaGalleryCreateRequest,
    responseType: gateway_gallery_pb.MingaGalleryCreateResponse,
  };

  static readonly Delete = {
    methodName: "Delete",
    service: MingaGallery,
    requestStream: false,
    responseStream: false,
    requestType: gateway_gallery_pb.MingaGalleryDeleteRequest,
    responseType: gateway_gallery_pb.MingaGalleryDeleteResponse,
  };

  static readonly Summary = {
    methodName: "Summary",
    service: MingaGallery,
    requestStream: false,
    responseStream: false,
    requestType: gateway_gallery_pb.MingaGallerySummaryRequest,
    responseType: gateway_gallery_pb.MingaGallerySummaryResponse,
  };

  static readonly GetPhotoTotal = {
    methodName: "GetPhotoTotal",
    service: MingaGallery,
    requestStream: false,
    responseStream: false,
    requestType: gateway_gallery_pb.MingaGalleryGetPhotoTotalRequest,
    responseType: gateway_gallery_pb.MingaGalleryGetPhotoTotalResponse,
  };

  static readonly StreamV2 = {
    methodName: "StreamV2",
    service: MingaGallery,
    requestStream: false,
    responseStream: true,
    requestType: common_stream_pb.StreamID,
    responseType: gateway_gallery_pb.GalleryStreamResponse,
  };

  static readonly StreamV2Control = {
    methodName: "StreamV2Control",
    service: MingaGallery,
    requestStream: false,
    responseStream: false,
    requestType: common_stream_pb.StreamControl,
    responseType: common_stream_pb.StreamControlResponse,
  };

  static readonly Stream = {
    methodName: "Stream",
    service: MingaGallery,
    requestStream: false,
    responseStream: true,
    requestType: common_stream_pb.StreamID,
    responseType: gateway_gallery_pb.GalleryStreamResponse,
  };

  static readonly StreamControl = {
    methodName: "StreamControl",
    service: MingaGallery,
    requestStream: false,
    responseStream: false,
    requestType: common_stream_pb.StreamControl,
    responseType: common_stream_pb.StreamControlResponse,
  };

}

export class MingaGalleryClient {
  getPhoto() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  create() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  delete() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  summary() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  getPhotoTotal() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  streamV2() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  streamV2Control() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  stream() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  streamControl() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
}
