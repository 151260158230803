import { Directive, ElementRef, Input } from '@angular/core';

import { scrollIntoView } from '@app/src/app/util/scroll-into-view';

@Directive({
  exportAs: 'mgScrollIntoView',
  selector: '[mgScrollIntoView]',
})
export class ScrollIntoViewDirective {
  /** @internal */
  private _enabled: boolean;

  @Input('mgScrollIntoView')
  set enabled(value: boolean) {
    const oldValue = this._enabled;
    this._enabled = !!value;

    if (oldValue !== this._enabled && this._enabled) {
      scrollIntoView(this._elementRef.nativeElement as any, {
        align: { top: 0 },
      });
    }
  }

  get enabled() {
    return !!this._enabled;
  }

  constructor(private _elementRef: ElementRef<Element>) {}
}
