import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { MgButtonModule } from '@app/src/app/button';
import { AvatarClusterModule } from '@app/src/app/components/AvatarCluster';
import { MgImageModule } from '@app/src/app/components/MgImage';
import { PointsBannerModule } from '@app/src/app/components/Points/PointsBanner';
import { StreamSwipeStackModule } from '@app/src/app/components/StreamSwipeStack';
import { MgContentLinkModule } from '@app/src/app/content-link';
import { MgEllipsisModule } from '@app/src/app/elements/MgEllipsis';
import { MgShortCardModule } from '@app/src/app/elements/MgShortCard';
import { MgChallengeResponseShortCardModule } from '@app/src/app/elements/MgShortCard/MgChallengeResponseShortCard';
import { MgeIconModule } from '@app/src/app/elements/MgeIcon';
import { MgIconModule } from '@app/src/app/icon';
import { PermissionsModule } from '@app/src/app/permissions';
import { MgPipesModule } from '@app/src/app/pipes';
import { MgSpinnerModule } from '@app/src/app/spinner';
import { UgcLinksHighlightPipeModule } from '@app/src/app/ugc-links/pipes/UgcLinksHighlight';

import { MgChallengeShortCardElement } from './MgChallengeShortCard.element';

@NgModule({
  imports: [
    // Minga dependencies
    MgShortCardModule,
    UgcLinksHighlightPipeModule,
    MgImageModule,
    MgPipesModule,
    MgeIconModule,
    AvatarClusterModule,
    MgButtonModule,
    StreamSwipeStackModule,
    MgChallengeResponseShortCardModule,
    MgEllipsisModule,
    MgContentLinkModule,
    PermissionsModule,
    MgSpinnerModule,
    MgIconModule,
    PointsBannerModule,

    // External dependencies
    CommonModule,
    RouterModule,
  ],
  declarations: [MgChallengeShortCardElement],
  exports: [MgChallengeShortCardElement],
})
export class MgChallengeShortCardModule {}
