// source: mailer/mailer.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

import * as jspb from 'google-protobuf';
import * as common_legacy_pb from '../common/legacy_pb';
export namespace Mail {
  export interface AsObject {
    to: string;
    from: string;
    subject: string;
    text: string;
    html: string;
  }
}
export class Mail extends jspb.Message {
  static readonly displayName = "Mail";
  /**
   * Oneof group definitions for this message. Each group defines the field
   * numbers belonging to that group. When of these fields' value is set, all
   * other fields in the group are cleared. During deserialization, if multiple
   * fields are encountered for a group, only the last value seen will be kept.
   * @private {!Array<!Array<number>>}
   * @const
   */
  static readonly oneofGroups_ = [[10,11]];


  /**
   * @return {Mail.ContentCase}
   */
  getContentCase() {
    return /** @type {Mail.ContentCase} */(jspb.Message.computeOneofCase(this, Mail.oneofGroups_[0]));
  }

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, Mail.oneofGroups_);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): Mail.AsObject {
    return Mail.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!Mail} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: Mail) {
    var f: any;
    var obj: any = {
      to: jspb.Message.getFieldWithDefault(msg, 1, ""),
      from: jspb.Message.getFieldWithDefault(msg, 2, ""),
      subject: jspb.Message.getFieldWithDefault(msg, 3, ""),
      text: jspb.Message.getFieldWithDefault(msg, 10, ""),
      html: jspb.Message.getFieldWithDefault(msg, 11, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!Mail}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new Mail;
    return Mail.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!Mail} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!Mail}
   */
  static deserializeBinaryFromReader(msg: Mail, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setTo(value1);
        break;
      case 2:
        var value2 = /** @type {string} */ (reader.readString());
        msg.setFrom(value2);
        break;
      case 3:
        var value3 = /** @type {string} */ (reader.readString());
        msg.setSubject(value3);
        break;
      case 10:
        var value10 = /** @type {string} */ (reader.readString());
        msg.setText(value10);
        break;
      case 11:
        var value11 = /** @type {string} */ (reader.readString());
        msg.setHtml(value11);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!Mail} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: Mail, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getTo();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
    f = message.getFrom();
    if (f.length > 0) {
      writer.writeString(
        2,
        f
      );
    }
    f = message.getSubject();
    if (f.length > 0) {
      writer.writeString(
        3,
        f
      );
    }
    f = /** @type {string} */ (jspb.Message.getField(message, 10));
    if (f != null) {
      writer.writeString(
        10,
        f
      );
    }
    f = /** @type {string} */ (jspb.Message.getField(message, 11));
    if (f != null) {
      writer.writeString(
        11,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    Mail.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string to = 1;
   * @return {string}
   */
  getTo(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setTo(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


  /**
   * optional string from = 2;
   * @return {string}
   */
  getFrom(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
  };


  /** @param {string} value */
  setFrom(value: string) {
    jspb.Message.setProto3StringField(this, 2, value);
  }


  /**
   * optional string subject = 3;
   * @return {string}
   */
  getSubject(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
  };


  /** @param {string} value */
  setSubject(value: string) {
    jspb.Message.setProto3StringField(this, 3, value);
  }


  /**
   * optional string text = 10;
   * @return {string}
   */
  getText(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
  };


  /** @param {string} value */
  setText(value: string) {
    jspb.Message.setOneofField(this, 10, Mail.oneofGroups_[0], value);
  }


  /**
   * Clears the field making it undefined.
   */
  clearText() {
    jspb.Message.setOneofField(this, 10, Mail.oneofGroups_[0], undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasText(): boolean {
    return jspb.Message.getField(this, 10) != null;
  }


  /**
   * optional string html = 11;
   * @return {string}
   */
  getHtml(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
  };


  /** @param {string} value */
  setHtml(value: string) {
    jspb.Message.setOneofField(this, 11, Mail.oneofGroups_[0], value);
  }


  /**
   * Clears the field making it undefined.
   */
  clearHtml() {
    jspb.Message.setOneofField(this, 11, Mail.oneofGroups_[0], undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasHtml(): boolean {
    return jspb.Message.getField(this, 11) != null;
  }


}
export namespace Mail {
export enum ContentCase {
  CONTENT_NOT_SET = 0,
  TEXT = 10,
  HTML = 11,
}
} // namespace Mail
export namespace AddMailRequest {
  export interface AsObject {
    mail?: Mail.AsObject;
    skipNotify: boolean;
  }
}
export class AddMailRequest extends jspb.Message {
  static readonly displayName = "AddMailRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): AddMailRequest.AsObject {
    return AddMailRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!AddMailRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: AddMailRequest) {
    var f: any;
    var obj: any = {
      mail: (f = msg.getMail()) && Mail.toObject(includeInstance, f),
      skipNotify: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!AddMailRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new AddMailRequest;
    return AddMailRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!AddMailRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!AddMailRequest}
   */
  static deserializeBinaryFromReader(msg: AddMailRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new Mail;
        reader.readMessage(value1,Mail.deserializeBinaryFromReader);
        msg.setMail(value1);
        break;
      case 2:
        var value2 = /** @type {boolean} */ (reader.readBool());
        msg.setSkipNotify(value2);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!AddMailRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: AddMailRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getMail();
    if (f != null) {
      writer.writeMessage(
        1,
        f,
        Mail.serializeBinaryToWriter
      );
    }
    f = message.getSkipNotify();
    if (f) {
      writer.writeBool(
        2,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    AddMailRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional Mail mail = 1;
   * @return {?Mail}
   */
  getMail(): Mail {
    return /** @type{?Mail} */ (
      jspb.Message.getWrapperField(this, Mail, 1));
  }


  /** @param {?Mail|undefined} value */
  setMail(value?: Mail) {
    jspb.Message.setWrapperField(this, 1, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearMail() {
    this.setMail(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasMail(): boolean {
    return jspb.Message.getField(this, 1) != null;
  }


  /**
   * optional bool skip_notify = 2;
   * @return {boolean}
   */
  getSkipNotify(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
  };


  /** @param {boolean} value */
  setSkipNotify(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 2, value);
  }


}
export namespace AddMailResponse {
  export interface AsObject {
    status?: common_legacy_pb.StatusCode;
  }
}
export class AddMailResponse extends jspb.Message {
  static readonly displayName = "AddMailResponse";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): AddMailResponse.AsObject {
    return AddMailResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!AddMailResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: AddMailResponse) {
    var f: any;
    var obj: any = {
      status: jspb.Message.getFieldWithDefault(msg, 1, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!AddMailResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new AddMailResponse;
    return AddMailResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!AddMailResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!AddMailResponse}
   */
  static deserializeBinaryFromReader(msg: AddMailResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {!StatusCode} */ (reader.readEnum());
        msg.setStatus(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!AddMailResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: AddMailResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getStatus();
    if (f !== 0.0) {
      writer.writeEnum(
        1,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    AddMailResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional StatusCode status = 1;
   * @return {!StatusCode}
   */
  getStatus(): common_legacy_pb.StatusCode {
    return /** @type {!StatusCode} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
  };


  /** @param {!StatusCode} value */
  setStatus(value: common_legacy_pb.StatusCode) {
    jspb.Message.setProto3EnumField(this, 1, value);
  }


}
export namespace FlushMailRequest {
  export interface AsObject {
  }
}
export class FlushMailRequest extends jspb.Message {
  static readonly displayName = "FlushMailRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): FlushMailRequest.AsObject {
    return FlushMailRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!FlushMailRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: FlushMailRequest) {
    var f: any;
    var obj: any = {

    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!FlushMailRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new FlushMailRequest;
    return FlushMailRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!FlushMailRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!FlushMailRequest}
   */
  static deserializeBinaryFromReader(msg: FlushMailRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!FlushMailRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: FlushMailRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    FlushMailRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
}
export namespace FlushMailResponse {
  export interface AsObject {
    status?: common_legacy_pb.StatusCode;
  }
}
export class FlushMailResponse extends jspb.Message {
  static readonly displayName = "FlushMailResponse";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): FlushMailResponse.AsObject {
    return FlushMailResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!FlushMailResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: FlushMailResponse) {
    var f: any;
    var obj: any = {
      status: jspb.Message.getFieldWithDefault(msg, 1, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!FlushMailResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new FlushMailResponse;
    return FlushMailResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!FlushMailResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!FlushMailResponse}
   */
  static deserializeBinaryFromReader(msg: FlushMailResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {!StatusCode} */ (reader.readEnum());
        msg.setStatus(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!FlushMailResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: FlushMailResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getStatus();
    if (f !== 0.0) {
      writer.writeEnum(
        1,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    FlushMailResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional StatusCode status = 1;
   * @return {!StatusCode}
   */
  getStatus(): common_legacy_pb.StatusCode {
    return /** @type {!StatusCode} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
  };


  /** @param {!StatusCode} value */
  setStatus(value: common_legacy_pb.StatusCode) {
    jspb.Message.setProto3EnumField(this, 1, value);
  }


}
