import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { MediaObserver } from '@angular/flex-layout';

import { HallPassStatusEnum, IHallPassType } from 'libs/domain';
import { MingaRoleType } from 'libs/domain';
import { getRole } from 'libs/shared';
import { mingaSettingTypes } from 'libs/util';
import { countBy, partition } from 'lodash';
import { Observable, ReplaySubject, combineLatest, of } from 'rxjs';
import { debounceTime, filter, map, takeUntil } from 'rxjs/operators';

import { Person } from '@app/src/app/people';
import { MingaSettingsService } from '@app/src/app/store/Minga/services';

import { TeacherToolsService } from '@modules/teacher-tools/services';

import { CarouselConfig } from '@shared/components/carousel/carousel/carousel';
import { FiltersFormService } from '@shared/components/filters-form';
import { FormSelectComponent } from '@shared/components/form';
import { FormSelectOption } from '@shared/components/form/types';
import { MultiPersonSearchComponent } from '@shared/components/multi-person-search';
import { AppRuntime, AppRuntimeInterface } from '@shared/services/app-runtime';
import { HallPassEvents } from '@shared/services/hall-pass-events';
import { MediaService } from '@shared/services/media';
import { xlsxExport } from '@shared/utils/xlsx-export';

import { HpmDashboardTableComponent } from './components/hpm-dashboard-table/hpm-dashboard-table.component';
import {
  HPM_DASHBOARD_STATUS_OPTIONS,
  HpmDashboardFilter,
  HpmDashboardMessages,
} from './constants/hpm-dashboard.constants';
import { HpmDashboardPassService, HpmDashboardService } from './services';

type PassTypeWithCounts = IHallPassType & {
  count: number;
};
@Component({
  providers: [HpmDashboardService, HpmDashboardPassService],
  selector: 'mg-hpm-dashboard',
  templateUrl: './hpm-dashboard.component.html',
  styleUrls: ['./hpm-dashboard.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HpmDashboardComponent implements OnDestroy, AfterViewInit {
  /** Child Components */
  @ViewChild('statusFilterSelect')
  statusFilterSelect: FormSelectComponent;
  @ViewChild(HpmDashboardTableComponent, { static: true })
  dashboardTable: HpmDashboardTableComponent;
  @ViewChild(MultiPersonSearchComponent, { static: false })
  multiPersonSearch: MultiPersonSearchComponent;

  /** Constants */
  public readonly MESSAGES = HpmDashboardMessages;

  /** General Observables */
  private readonly _destroyed$ = new ReplaySubject<void>(1);
  public panelOpenState = false;

  /** Select Field Options */
  public readonly typesSelectOptions$ = this.hpmDashboard.passTypes$.pipe(
    takeUntil(this._destroyed$),
    map(types => {
      const activeTypes = types.filter(type => type.active);
      const sorted = activeTypes.sort(
        (a: any, b: any) => a?.priority - b?.priority,
      );
      return sorted.map(type => ({
        label: type.name,
        value: type.id,
      }));
    }),
  );

  public readonly passStatusOptions$: Observable<
    FormSelectOption<HallPassStatusEnum>[]
  > = of(HPM_DASHBOARD_STATUS_OPTIONS).pipe(
    map(options =>
      options.map(option => ({
        label: option,
        value: option,
      })),
    ),
  );

  /** Hall Pass Enabled Setting */
  public hallPassEnabled: boolean;
  public readonly hallPassEnabled$ = this._mingaSettings
    .getSettingValueObs(mingaSettingTypes.PASS_HALLPASS_ENABLE)
    .pipe(takeUntil(this._destroyed$))
    .subscribe(value => {
      this.hallPassEnabled = value;
    });

  public carouselConfig: CarouselConfig = {
    grid: { xs: 4, sm: 5, md: 6, lg: 9, xl: 10, all: 0 },
    showNavigation: true,
    showPagination: false,
  };
  /** Hall Pass Types */
  public typeCounts$: Observable<Record<number, number>>;
  public passStatusCounts$: Observable<number>;
  public passTypesWithCounts$: Observable<PassTypeWithCounts[]>;

  public filtersFormStructure$ = combineLatest([
    this.typesSelectOptions$,
    this.passStatusOptions$,
  ]).pipe(
    takeUntil(this._destroyed$),
    map(([hallPassTypes, passStatusOptions]) => {
      return this._filtersFormService.create({
        status: {
          label: 'Status',
          type: 'multi-select',
          options: passStatusOptions,
          default: [
            HallPassStatusEnum.ACTIVE,
            HallPassStatusEnum.OVERDUE,
            HallPassStatusEnum.PENDING_APPROVAL,
          ],
        },
        'pass-type': {
          label: 'Pass type',
          type: 'multi-select',
          options: hallPassTypes,
        },
      });
    }),
  );

  /** Component Constructor */
  constructor(
    public hpmDashboard: HpmDashboardService,
    public media: MediaService,
    public mediaObserver: MediaObserver,
    private _teacherToolsService: TeacherToolsService,
    private _hpmEvents: HallPassEvents,
    private _mingaSettings: MingaSettingsService,
    private _filtersFormService: FiltersFormService,
    @Inject(AppRuntimeInterface) private _runtime: AppRuntime,
  ) {
    // this.typesSelectOptions$ =

    this._hpmEvents.onHallPassCreateSuccess
      .pipe(takeUntil(this._destroyed$))
      .subscribe(_event => this.hpmDashboard.addPasses(_event));
  }

  ngAfterViewInit() {
    this.typeCounts$ = combineLatest([
      this.dashboardTable.filteredData$,
      this.hpmDashboard.filters$,
    ]).pipe(
      debounceTime(100),
      takeUntil(this._destroyed$),
      filter(([, filters]) => {
        return filters.pass_id.length === 0;
      }),
      map(([passes]) => {
        return countBy(passes, 'typeId');
      }),
    );

    this.passStatusCounts$ = this.dashboardTable.filteredData$.pipe(
      takeUntil(this._destroyed$),
      map(passes => passes.length),
    );
    this.passTypesWithCounts$ = combineLatest([
      this.hpmDashboard.passTypes$,
      this.typeCounts$,
    ]).pipe(
      map(([types, countInfo]) => {
        const activeTypes = types.filter(type => type.active);
        const withCounts: PassTypeWithCounts[] = activeTypes.map(type => {
          const count = countInfo[type.id] || 0;
          return {
            ...type,
            count,
            icon: 'hallpass',
          };
        });
        const sorted = withCounts.sort(
          (a: PassTypeWithCounts, b: PassTypeWithCounts) => b?.count - a?.count,
        );
        return sorted;
      }),
    );

    this.hpmDashboard.clearFiltersEvent$
      .pipe(takeUntil(this._destroyed$))
      .subscribe(() => this.multiPersonSearch.clearSelection());
  }

  ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  public async setPeopleFilter(people: Person[]): Promise<void> {
    const [authors, recipients] = partition(
      people,
      person => getRole(person.roleType as MingaRoleType).admin,
    );
    this.hpmDashboard.updateFilter(HpmDashboardFilter.RECIPIENTS, [recipients]);
    this.hpmDashboard.updateFilter(HpmDashboardFilter.AUTHORS, [authors]);
  }

  public async setPassIdFilter(id: number): Promise<void> {
    this.hpmDashboard.updateFilter(HpmDashboardFilter.PASS_ID, [id], true);
  }

  public async setPassStatusSelectFilter(id: number[]): Promise<void> {
    this.hpmDashboard.updateFilter(HpmDashboardFilter.PASS_ID, id, false);
  }

  public get canDownloadExport(): boolean {
    return !this._runtime.isNativeApp();
  }

  public async setShowAuthoredFilter(value: 'all' | 'mine'): Promise<void> {
    if (!value) return;
    this.hpmDashboard.updateFilter(
      HpmDashboardFilter.SHOW_MINE,
      value === 'mine',
    );
  }

  public async setPassStatusFilter(
    status: HallPassStatusEnum[],
  ): Promise<void> {
    this.hpmDashboard.updateFilter(
      HpmDashboardFilter.PASS_STATUS,
      status,
      false,
    );
  }

  public updateFilters(state: any) {
    this.setPassStatusFilter(state?.status ?? []);
    this.setPassStatusSelectFilter(state?.['pass-type'] ?? []);
  }

  public async assignHallPass(): Promise<void> {
    this._teacherToolsService.openHallpassAssignmentForm();
  }

  public getFilteredTableData() {
    if (this.dashboardTable) {
      return this.dashboardTable.getFilteredData();
    }
    return [];
  }

  public async export() {
    if (this.dashboardTable) {
      const tableData = this.dashboardTable.getFilteredData();
      const headers = [
        'Hall Pass',
        'Student Name',
        'ID Number',
        'Issued By',
        'Start Time',
        'End Time',
      ];
      const passData = this.dashboardTable.getTypes();
      const data = tableData.map(pass => [
        passData[pass.typeId].name || '',
        pass.recipientPersonView.displayName || '',
        pass.recipientStudentId || '',
        pass.authorPersonView.displayName || '',
        pass.status.start ? pass.status.start.toString() : '',
        pass.status.end ? pass.status.end.toString() : '',
      ]);
      xlsxExport(headers, data, 'hallPassList.xlsx');
    }
  }
}
