// GENERATED CODE -- DO NOT EDIT!

import {grpc} from '@improbable-eng/grpc-web';

import * as gateway_feed_video_pb from '../gateway/feed_video_pb';
import * as common_legacy_pb from '../common/legacy_pb';
import * as common_delta_pb from '../common/delta_pb';
import * as gateway_mention_pb from '../gateway/mention_pb';
import * as gateway_moderation_pb from '../gateway/moderation_pb';

export class FeedVideoManager {
  static readonly serviceName = "FeedVideoManager";

  static readonly create = {
    methodName: "create",
    service: FeedVideoManager,
    requestStream: false,
    responseStream: false,
    requestType: gateway_feed_video_pb.CreateFeedVideoRequest,
    responseType: gateway_feed_video_pb.CreateFeedVideoResponse,
  };

  static readonly update = {
    methodName: "update",
    service: FeedVideoManager,
    requestStream: false,
    responseStream: false,
    requestType: gateway_feed_video_pb.UpdateFeedVideoRequest,
    responseType: gateway_feed_video_pb.UpdateFeedVideoResponse,
  };

  static readonly delete = {
    methodName: "delete",
    service: FeedVideoManager,
    requestStream: false,
    responseStream: false,
    requestType: gateway_feed_video_pb.DeleteFeedVideoRequest,
    responseType: gateway_feed_video_pb.DeleteFeedVideoResponse,
  };

  static readonly getPublicVimeoToken = {
    methodName: "getPublicVimeoToken",
    service: FeedVideoManager,
    requestStream: false,
    responseStream: false,
    requestType: gateway_feed_video_pb.VimeoPublicTokenRequest,
    responseType: gateway_feed_video_pb.VimeoPublicTokenResponse,
  };

  static readonly getVimeoVideoData = {
    methodName: "getVimeoVideoData",
    service: FeedVideoManager,
    requestStream: false,
    responseStream: false,
    requestType: gateway_feed_video_pb.VimeoVideoDataRequest,
    responseType: gateway_feed_video_pb.VimeoVideoDataResponse,
  };

  static readonly getYouTubeVideoData = {
    methodName: "getYouTubeVideoData",
    service: FeedVideoManager,
    requestStream: false,
    responseStream: false,
    requestType: gateway_feed_video_pb.YouTubeVideoDataRequest,
    responseType: gateway_feed_video_pb.YouTubeVideoDataResponse,
  };

}

export class FeedVideoManagerClient {
  create() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  update() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  delete() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  getPublicVimeoToken() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  getVimeoVideoData() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  getYouTubeVideoData() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
}
