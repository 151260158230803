import { Injectable, OnDestroy } from '@angular/core';

import { Store } from '@ngrx/store';
import { INotificationType } from 'libs/domain';
import { gateway } from 'libs/generated-grpc-web';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { $enum } from 'ts-enum-util';

import { PointsNotificationHandlerService } from '@app/src/app/notifications/points';

import { NotificationListenerBaseService } from '../NotificationListenerBase.service';

/**
 * Listen to incoming notifications to look for points notifications
 */
@Injectable({ providedIn: 'root' })
export class PointsNotificationListenerService
  extends NotificationListenerBaseService
  implements OnDestroy
{
  private _destroyed$ = new ReplaySubject<void>(undefined);

  constructor(
    public store: Store<any>,
    private pointsHandler: PointsNotificationHandlerService,
  ) {
    super(store);

    this.notifications$
      .pipe(takeUntil(this._destroyed$))
      .subscribe(notification => {
        this._handleNotification(notification);
      });
  }

  private async _handleNotification(
    msg: gateway.notification_pb.Notification.AsObject,
  ) {
    const notificationType = $enum(INotificationType).asValueOrThrow(
      msg.notificationType,
    );

    if (this.pointsHandler.isPointsNotification(msg.notificationType || '')) {
      return await this.pointsHandler.handleDisplayPointNotification(msg);
    }
  }

  ngOnDestroy() {
    this._destroyed$.next();
    this._destroyed$.complete();
  }
}
