import { Injectable } from '@angular/core';

import { MingaRoleType } from 'libs/domain';
import {
  student_id_ng_grpc_pb,
  image_pb,
  student_id_pb,
} from 'libs/generated-grpc-web';
import { getRole } from 'libs/shared';
import { HallPassMapper } from 'libs/shared-grpc';
import { StudentScheduleMapper } from 'libs/shared-grpc';
import { map } from 'rxjs/operators';

import { AppConfigService } from '@app/src/app/minimal/services/AppConfig';
import { AuthInfoService } from '@app/src/app/minimal/services/AuthInfo';
import { MingaStoreFacadeService } from '@app/src/app/store/Minga/services';
import { mgResolveImageUrl } from '@app/src/app/util/asset';

import {
  StudentIdImageService,
  StudentIdPresetSize,
} from '@shared/services/student-id-image/StudentIdImage.service';

import {
  DEFAULT_STUDENT_ID_SETTINGS,
  ID_SETTINGS_MAP,
  ViewIdRoutes,
} from '../constants';
import { IdStudentData } from '../types';

@Injectable({ providedIn: 'root' })
export class ViewIdService {
  // Observables

  public viewIdSettings$ = this._mingaStore.getMingaAsObservable().pipe(
    map(mingaInfo => {
      const studentIdSettings = DEFAULT_STUDENT_ID_SETTINGS;

      for (const setting of mingaInfo.settings) {
        const property = ID_SETTINGS_MAP[setting.name] as string;
        if (property) {
          studentIdSettings[property] = setting.value;
        }
      }

      if (!studentIdSettings.idSchoolName) {
        studentIdSettings.idSchoolName = mingaInfo.name;
      }
      if (!studentIdSettings.idLogoAssetPath) {
        studentIdSettings.idLogoAssetPath = mingaInfo.logo || '';
      }

      return studentIdSettings;
    }),
  );

  /** Constructor */
  constructor(
    private _studentIdManager: student_id_ng_grpc_pb.StudentIdManager,
    private _studentIdImage: StudentIdImageService,
    private _mingaStore: MingaStoreFacadeService,
    private _appConfig: AppConfigService,
    private _authInfo: AuthInfoService,
  ) {}

  public async fetchStudentId(personHash: string): Promise<IdStudentData> {
    const request = new student_id_pb.ReadStudentIdRequest();
    request.setPersonHash(personHash);

    const response = await this._studentIdManager.readStudentId(request);
    const studentIdData = await this._getAsStudentIdData(response);

    return studentIdData;
  }

  public async uploadStudentIdPhotoChange(personHash: string, image?: string) {
    if (image) await this.updateImage(image);
    else await this.removeImage(personHash);
  }

  public async updateImage(assetPath: string) {
    const request = new student_id_pb.UpdateStudentIdRequest();
    request.setStudentIdUrl(assetPath);
    request.setPersonHash(this._authInfo.authPersonHash);
    return await this._studentIdManager.updateStudentId(request);
  }

  public async removeImage(personHash: string) {
    const request = new student_id_pb.DeleteStudentIdImageRequest();
    request.setPersonHash(personHash);
    await this._studentIdManager.deleteStudentIdImage(request);
  }

  /**
   * Get the root route for the ID overlay. The remote config will determine if we should be using the new
   * or the legacy templates
   */
  public getIdOverlayRoute(): string {
    return ViewIdRoutes.ROOT;
  }

  public getCurrentSchoolYear() {
    const date = new Date();
    const month = date.getMonth();
    let year = date.getFullYear();

    if (month < 7) year -= 1;

    return year;
  }

  private async _getAsStudentIdData(
    response: student_id_pb.ReadStudentIdResponse,
  ): Promise<IdStudentData> {
    const noAccess = response.getNoAccess();
    const photoIdFileName = response.getFileName();
    const activePasses = response
      .getHallPassesList()
      .map(HallPassMapper.fromProto);
    const mingaIconInfoMessage = response.getMemberIconsList();
    const processedImage = await this.processStudentIdPhoto(photoIdFileName);
    const idPhoto = processedImage ? URL.createObjectURL(processedImage) : '';
    const role = getRole(response.getRoleType() as MingaRoleType).IDRole;
    let noAccessSrc = '';
    if (noAccess) {
      const mingaIconInf: image_pb.ImageInfo.AsObject = noAccess.toObject();
      const icon = mgResolveImageUrl(mingaIconInf, [
        'bannerlibpreview',
        'preview',
        'cardbanner',
        'raw',
      ]);
      noAccessSrc = icon;
    }

    const currentClassesProto = response.getCurrentClassList();

    const currentClasses = currentClassesProto.length
      ? currentClassesProto.map(StudentScheduleMapper.fromProto)
      : null;

    const stickerIcons: string[] = [];
    if (mingaIconInfoMessage) {
      for (const message of mingaIconInfoMessage) {
        const mingaIconInf: image_pb.ImageInfo.AsObject = message.toObject();
        const icon = mgResolveImageUrl(mingaIconInf, [
          'bannerlibpreview',
          'preview',
          'cardbanner',
          'raw',
        ]);
        stickerIcons.push(icon);
      }
    }

    return {
      personHash: response.getPersonHash(),
      studentId: response.getStudentIdNumber(),
      firstName: response.getFirstName(),
      lastName: response.getLastName(),
      grade: response.getGrade(),
      active: response.getActive(),
      customField1: response.getIdField1(),
      customField2: response.getIdField2(),
      mingaAddress: response.getMingaAddress(),
      mingaYear: this.getCurrentSchoolYear(),
      noAccess: noAccessSrc,
      role,
      activePasses,
      idPhoto,
      stickerIcons,
      currentClasses,
    };
  }

  public async processStudentIdPhoto(fileName: string) {
    try {
      let image: Blob | undefined;
      if (fileName) {
        image = await this._studentIdImage.getStudentIdImageAsBlob(
          fileName,
          StudentIdPresetSize.ID_FULL,
        );
      }
      return image;
    } catch (e) {
      return undefined;
    }
  }
}
