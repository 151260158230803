import { Injectable } from '@angular/core';

import { gateway } from 'libs/generated-grpc-web';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { ReportService } from '@app/src/app/services/Report';

import { IAddGalleryPhotoOptions, IDeleteGalleryPhotoOptions } from '../types';

@Injectable()
export abstract class GalleryServiceAbstract {
  public _loading: BehaviorSubject<boolean>;
  public _summary: BehaviorSubject<gateway.gallery_pb.MingaGallerySummaryResponse.AsObject | null>;

  readonly loading$: Observable<boolean>;
  readonly summary$: Observable<gateway.gallery_pb.MingaGallerySummaryResponse.AsObject>;

  constructor(
    public mingaGalleryProto: gateway.gallery_ng_grpc_pb.MingaGallery,
    public reportService: ReportService,
  ) {
    this._loading = new BehaviorSubject<boolean>(false);
    this._summary =
      new BehaviorSubject<gateway.gallery_pb.MingaGallerySummaryResponse.AsObject | null>(
        null,
      );
    this.loading$ = this._loading.asObservable();
    this.summary$ = this._summary.pipe(
      filter(summary => !!summary),
      map(
        summary =>
          summary as gateway.gallery_pb.MingaGallerySummaryResponse.AsObject,
      ),
    );
  }

  /**
   * Get data for summary$; includes photototal, newPhotoCount, newestPhotos and
   * aspect ratio
   */
  abstract fetchSummary(): Promise<void>;

  /**
   *
   * Get the number of photos for a given search query
   *
   *  @param search
   */
  abstract getSearchPhotoTotal(search: string): Promise<number>;

  abstract addPhoto(options: IAddGalleryPhotoOptions): Promise<void>;

  abstract deletePhoto(options: IDeleteGalleryPhotoOptions): Promise<boolean>;

  abstract resolvePhoto(galleryPhotoUuid: string): Promise<boolean>;

  public async _addPhoto(
    summary: BehaviorSubject<gateway.gallery_pb.MingaGallerySummaryResponse.AsObject | null>,
    options: IAddGalleryPhotoOptions,
  ) {
    const request = new gateway.gallery_pb.MingaGalleryCreateRequest();
    request.setDescription(options.description);
    request.setImageAssetList(options.imageAssets);

    for (const taggedGroup of options.taggedGroups) {
      const tagMsg = new gateway.gallery_pb.Tag();
      const groupTagMsg = new gateway.gallery_pb.Tag.GroupTag();
      groupTagMsg.setGroupHash(taggedGroup);

      tagMsg.setGroupTag(groupTagMsg);
      request.addTag(tagMsg);
    }

    for (const taggedPerson of options.taggedPeople) {
      const tagMsg = new gateway.gallery_pb.Tag();
      const personTagMsg = new gateway.gallery_pb.Tag.PersonTag();
      personTagMsg.setPersonHash(taggedPerson);

      tagMsg.setPersonTag(personTagMsg);
      request.addTag(tagMsg);
    }

    for (const taggedContent of options.taggedContent) {
      const tagMsg = new gateway.gallery_pb.Tag();
      const contentTagMsg = new gateway.gallery_pb.Tag.ContentTag();
      contentTagMsg.setContentHash(taggedContent);

      tagMsg.setContentTag(contentTagMsg);
      request.addTag(tagMsg);
    }

    const response = await this.mingaGalleryProto.create(request);

    const moderationList = response.getModerationResultList();
    const photoUuidList = response.getGalleryPhotoUuidList();

    const moderationPassed =
      await this.reportService.handlePhotoModerationResults(
        moderationList,
        photoUuidList,
      );
    if (!moderationPassed) {
      return;
    }

    const summaryValue = summary.getValue();
    if (summaryValue) {
      summaryValue.photoTotal += photoUuidList.length;
      summary.next(summaryValue);
    }
  }

  public async _deletePhoto(
    summary: BehaviorSubject<gateway.gallery_pb.MingaGallerySummaryResponse.AsObject | null>,
    options: IDeleteGalleryPhotoOptions,
  ): Promise<boolean> {
    const request = new gateway.gallery_pb.MingaGalleryDeleteRequest();
    request.setGalleryPhotoUuid(options.galleryPhotoUuid);
    const response = await this.mingaGalleryProto.delete(request);
    const success = response.getSuccess();

    if (success) {
      const summaryValue = this._summary.getValue();
      if (summaryValue) {
        summaryValue.photoTotal = Math.max(0, summaryValue.photoTotal - 1);
        summary.next(summaryValue);
      }
    }

    return success;
  }

  public async _resolvePhoto(galleryPhotoUuid: string) {
    return await this.reportService.resolve({ galleryPhotoUuid });
  }
}
