import { Injectable } from '@angular/core';

import { gateway } from 'libs/generated-grpc-web';

import { PagedScrollerDatasource } from '@app/src/app/components/mg-paged-scroller/PagedScrollerDatasource';
import { EventContentService } from '@app/src/app/minimal/services/EventContent';
import { StreamManager } from '@app/src/app/minimal/services/StreamManager';
import { IEventCalendarSettings } from '@app/src/app/services/EventCalendar';

@Injectable()
export class EventScheduleDataSource extends PagedScrollerDatasource<gateway.content_views_pb.ShortEventCardView.AsObject> {
  protected limit = 10;
  protected offset = 0;
  streamName = 'EventSchedule';
  private _settings: IEventCalendarSettings;

  constructor(
    streamManager: StreamManager,
    private _eventService: EventContentService,
  ) {
    super(streamManager);
  }

  public setSettings(settings: IEventCalendarSettings) {
    this._settings = settings;
    this.restartStream();
  }

  protected async _fetch() {
    if (this.reachedEnd) return;
    const newItems = await this._eventService.getEventsAsStreamItem(
      this._settings,
      this.limit,
      this.offset,
    );
    this.addNewItems(newItems);
  }
}
