// GENERATED CODE -- DO NOT EDIT!

import {grpc} from '@improbable-eng/grpc-web';

import * as gateway_account_pb from '../gateway/account_pb';

export class Account {
  static readonly serviceName = "minga.Account";

  static readonly CancelEval = {
    methodName: "CancelEval",
    service: Account,
    requestStream: false,
    responseStream: false,
    requestType: gateway_account_pb.CancelEvalRequest,
    responseType: gateway_account_pb.CancelEvalResponse,
  };

}

export class AccountClient {
  cancelEval() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
}
