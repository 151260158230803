import { Pipe, PipeTransform } from '@angular/core';

import { FlexTimeActivityInstance } from 'libs/domain';

import { FlexTimePeriodTile } from '../constants';

@Pipe({ name: 'calculateStudentCheckedIn' })
export class CalculateStudentCheckedInPipe implements PipeTransform {
  transform(
    data: FlexTimePeriodTile | FlexTimeActivityInstance,
    type: 'period' | 'activity',
  ): string {
    if (type === 'period') {
      return (
        ((data as FlexTimePeriodTile).count - data.absentees).toString() || '0'
      );
    } else {
      return (
        (
          (data as FlexTimeActivityInstance).registered - data.absentees
        ).toString() || '0'
      );
    }
  }
}
