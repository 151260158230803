import { MingaPermission, MingaRoleType } from 'libs/domain';
import { DisplayNameFormat } from 'libs/util';

import { UserRole } from './UserRole';

export class ManagerRole extends UserRole {
  readonly roleType = MingaRoleType.MANAGER;
  readonly name = 'Manager';
  readonly displayNameFormat = DisplayNameFormat.F_LAST;
  readonly iconUrl = 'assets/roles/manager.svg';
  readonly iconColor = '#A190D8';
  readonly capabilitiesDescription = `Managers will be allowed to share announcements, posts, pictures, and videos`;
  readonly feedPermittable = true;
  readonly galleryPermittable = true;
  readonly groupFeedPermittable = true;
  readonly programManagePermittable = true;
  readonly videoUploadPermittable = true;
  readonly commentPermittable = true;
  readonly groupCreatePermittable = true;
  readonly admin = true;
  readonly requiredFeatures = [];
  readonly sendToMailChimp = true;

  readonly permissions = [
    MingaPermission.CONTENT_DELETE_OTHERS,
    MingaPermission.ACCOUNT_DISPLAYNAME_CHANGE,
    MingaPermission.ACCOUNT_PROFILE_CHANGE,
    MingaPermission.APP_BROADCAST_SEND,
    MingaPermission.ASSET_UPLOAD,
    MingaPermission.CONTENT_ANNOUNCEMENT_CREATE,
    MingaPermission.CONTENT_COMMENT_CREATE,
    MingaPermission.CONTENT_COMMENTS_VIEW,
    MingaPermission.CONTENT_EVENT_CREATE,
    MingaPermission.CONTENT_GROUP_CREATE,
    MingaPermission.CONTENT_GROUP_VIEWALL,
    MingaPermission.CONTENT_GROUP_ENABLED,
    MingaPermission.CONTENT_GROUP_MANAGE,
    MingaPermission.CONTENT_HTML_INCLUDE,
    MingaPermission.CONTENT_LIKES_LIKE,
    MingaPermission.CONTENT_MODERATION_OVERRIDE,
    MingaPermission.CONTENT_POLL_CREATE,
    MingaPermission.CONTENT_POLL_ANSWER,
    MingaPermission.CONTENT_POST_CREATE,
    MingaPermission.CONTENT_POST_SET_TITLE,
    MingaPermission.CONTENT_PUBLISH_DATES_SET,
    MingaPermission.CONTENT_REPORT_CREATE,
    MingaPermission.CONTENT_REPORTS_MANAGE,
    MingaPermission.CONTENT_VIDEO_CREATE,
    MingaPermission.GROUP_BROADCAST_SEND,
    MingaPermission.GROUP_CONTENT_ANNOUNCEMENT_CREATE,
    MingaPermission.GROUP_CONTENT_EVENTS_CREATE,
    MingaPermission.GROUP_CONTENT_POLL_CREATE,
    MingaPermission.GROUP_CONTENT_POST_CREATE,
    MingaPermission.GROUP_CONTENT_VIDEO_CREATE,
    MingaPermission.MINGA_CONTENT_PIN,
    MingaPermission.MINGA_CONTENT_MAIN_FEED,
    MingaPermission.MINGA_FILE_CREATE,
    MingaPermission.MINGA_PROFILE_EDIT,
    MingaPermission.MINGA_PEOPLE_MANAGE,
    MingaPermission.MINGA_POLLS_MANAGE,
    MingaPermission.CONTENT_ANY_GET_DELETED,
    MingaPermission.MINGA_PROGRAMS_MANAGE,
    MingaPermission.GALLERY_ADD_PHOTO,
    MingaPermission.GALLERY_DELETE_OTHERS_PHOTO,
    MingaPermission.CONTENT_CREATED_MINGA_DESIGNER_CONTENT,
    MingaPermission.GROUP_ALLOW_PARENT_GROUP,
    MingaPermission.GROUP_ALLOW_ANY_PARENT_GROUP,
    MingaPermission.EMAIL_CONTENT,
    MingaPermission.EMAIL_CONTENT_AS_GROUP_OWNER,
    MingaPermission.CONTENT_MANAGE_COMMENT_SETTINGS,
    MingaPermission.MINGA_CONTENT_UPDATE_OTHERS,
    MingaPermission.MODERATION_GET_REPORT_NOTIFICATIONS,
    MingaPermission.EVENTS_MANAGE,
    MingaPermission.CONTENT_CHALLENGE_CREATE,
    MingaPermission.GROUP_CONTENT_CHALLENGE_CREATE,
    MingaPermission.CONTENT_CHALLENGE_MANAGE,
    MingaPermission.CHALLENGE_COMPLETE,
    MingaPermission.POINTS_MANAGE,
    MingaPermission.ADMINISTER_STUDENT_ID,
    MingaPermission.HALL_PASS_TYPE_MANAGE,
    MingaPermission.HALL_PASS_MANAGE,
    MingaPermission.HALL_PASS_CREATE,
    /**
     * @deprecated can be removed once support for < 7.2.2 is no longer needed
     */
    MingaPermission.TEACHER_TOOLS,
    MingaPermission.PBIS_TYPE_MANAGE,
    MingaPermission.PBIS_CREATE,
    MingaPermission.PBIS_VIEW_REPORTS,
    MingaPermission.CHECKIN_TYPE_MANAGE,
    MingaPermission.CHECKIN_MANAGE,
    MingaPermission.CHECKIN_VIEW_REPORTS,
    MingaPermission.CONSEQUENCE_CREATE,
    MingaPermission.POINTS_VIEW_REPORTS,
    MingaPermission.MANAGE_REWARDS,
    MingaPermission.FLEX_TIME_MANAGE,
    MingaPermission.FLEX_TIME_VIEW_REPORTS,
    MingaPermission.HALL_PASS_VIEW_REPORTS,
    MingaPermission.POINTS_ALLOCATE_ALLOWED,
    MingaPermission.USER_LIST_VIEW,
    MingaPermission.USER_LIST_ADMIN,
    MingaPermission.SCHEDULED_REPORTS_MANAGE,
    MingaPermission.SCHEDULED_REPORTS_DOWNLOAD_PAST_REPORT,
    MingaPermission.DM_CAN_MESSAGE,
    MingaPermission.CONTENT_SMS_MESSAGE_CREATE,
    MingaPermission.MINGA_MANAGE,
    MingaPermission.DATE_RANGE_PRESETS_MANAGE,
    MingaPermission.DATE_RANGE_PRESETS_VIEW,
    MingaPermission.ID_CHANGE_OWN_PHOTO,
    MingaPermission.ADMIN_TOOLS,
    MingaPermission.STUDENT_SCHEDULE_VIEW,
    MingaPermission.BELL_SCHEDULE_MANAGE,
    MingaPermission.VIEW_PROFILE,
  ];
  readonly overridablePermissions = [
    MingaPermission.CONTENT_SMS_MESSAGE_CREATE,
  ];
}
