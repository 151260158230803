// GENERATED CODE -- DO NOT EDIT!

import {grpc} from '@improbable-eng/grpc-web';

import * as user_list_user_list_member_pb from '../user_list/user_list_member_pb';
import * as user_list_user_list_pb from '../user_list/user_list_pb';

export class UserListsMember {
  static readonly serviceName = "user_list.member.v1.UserListsMember";

  static readonly ListUserListMembers = {
    methodName: "ListUserListMembers",
    service: UserListsMember,
    requestStream: false,
    responseStream: false,
    requestType: user_list_user_list_member_pb.ListMembersRequest,
    responseType: user_list_user_list_member_pb.ListMembersResponse,
  };

  static readonly AddUserListMember = {
    methodName: "AddUserListMember",
    service: UserListsMember,
    requestStream: false,
    responseStream: false,
    requestType: user_list_user_list_member_pb.AddMemberRequest,
    responseType: user_list_user_list_member_pb.AddMemberResponse,
  };

  static readonly RemoveUserListMember = {
    methodName: "RemoveUserListMember",
    service: UserListsMember,
    requestStream: false,
    responseStream: false,
    requestType: user_list_user_list_member_pb.RemoveMemberRequest,
    responseType: user_list_user_list_member_pb.RemoveMemberResponse,
  };

}

export class UserListsMemberClient {
  listUserListMembers() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  addUserListMember() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  removeUserListMember() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
}
