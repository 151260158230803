import { IListTypePermissions, MembershipListType } from 'libs/domain';
import { MingaPermission } from 'libs/domain';

export const ListTypePermissions: IListTypePermissions = {
  [MembershipListType.STICKER]: [
    MingaPermission.ADMINISTER_STUDENT_ID,
    MingaPermission.CHECKIN_MANAGE,
  ],
  [MembershipListType.NO_PARTY]: [MingaPermission.HALL_PASS_TYPE_MANAGE],
  [MembershipListType.NO_PASS]: [MingaPermission.HALL_PASS_TYPE_MANAGE],
  [MembershipListType.NO_GRANT]: [MingaPermission.HALL_PASS_TYPE_MANAGE],
  [MembershipListType.NO_TRACK]: [MingaPermission.PBIS_TYPE_MANAGE],
  [MembershipListType.TRACK]: [MingaPermission.PBIS_TYPE_MANAGE],
  [MembershipListType.SCHOOL_TEAM]: [
    MingaPermission.POINTS_MANAGE,
    MingaPermission.POINTS_VIEW_REPORTS,
  ],
  [MembershipListType.NO_ACCESS]: [MingaPermission.ADMINISTER_STUDENT_ID],
  [MembershipListType.ONE_PASS]: [MingaPermission.HALL_PASS_TYPE_MANAGE],
  [MembershipListType.TWO_PASS]: [MingaPermission.HALL_PASS_TYPE_MANAGE],
  [MembershipListType.CONTENT_MANAGER]: [MingaPermission.CONTENT_EVENT_CREATE],
  [MembershipListType.ID_MANAGER]: [MingaPermission.ADMINISTER_STUDENT_ID],
  [MembershipListType.PASS_TYPE]: [MingaPermission.HALL_PASS_TYPE_MANAGE],
  [MembershipListType.POINTS_MANAGER]: [MingaPermission.MINGA_PEOPLE_MANAGE],
  [MembershipListType.BEHAVIOR_MANAGER]: [MingaPermission.MINGA_PEOPLE_MANAGE],
  [MembershipListType.CHECKIN_MANAGER]: [MingaPermission.MINGA_PEOPLE_MANAGE],
  [MembershipListType.BEHAVIOR_TYPE]: [MingaPermission.PBIS_TYPE_MANAGE],
  [MembershipListType.HP_REPORT_VIEWER]: [MingaPermission.MINGA_PEOPLE_MANAGE],
  [MembershipListType.BEHAVIOR_REPORT_VIEWER]: [
    MingaPermission.MINGA_PEOPLE_MANAGE,
  ],
  [MembershipListType.CHECKIN_REPORT_VIEWER]: [
    MingaPermission.MINGA_PEOPLE_MANAGE,
  ],
  [MembershipListType.POINTS_REPORT_VIEWER]: [
    MingaPermission.MINGA_PEOPLE_MANAGE,
  ],
  [MembershipListType.HALL_PASS_MANAGER]: [MingaPermission.MINGA_PEOPLE_MANAGE],
  [MembershipListType.HALL_PASS_TYPE]: [MingaPermission.HALL_PASS_TYPE_MANAGE],
  [MembershipListType.CHECKIN_RESTRICTION_LIST]: [
    MingaPermission.MINGA_PEOPLE_MANAGE,
  ],
  [MembershipListType.FLEX_RESTRICTION_LIST]: [
    MingaPermission.MINGA_PEOPLE_MANAGE,
    MingaPermission.FLEXTIME_MANAGE_OWN_ACTIVITY,
  ],
  [MembershipListType.FLEX_MANAGER]: [MingaPermission.MINGA_PEOPLE_MANAGE],
  [MembershipListType.FLEX_REPORT_VIEWER]: [
    MingaPermission.MINGA_PEOPLE_MANAGE,
  ],
  [MembershipListType.USER_LIST]: [MingaPermission.USER_LIST_VIEW],
  [MembershipListType.USER_LIST_MANAGER]: [MingaPermission.USER_LIST_VIEW],
};
