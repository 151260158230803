import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { GuardsModule } from '@app/src/app/guards';

import { PeopleSelectorModule } from './people-selector.module';
import { getPeopleSelectorRoutes } from './utils';

@NgModule({
  imports: [
    GuardsModule,
    RouterModule.forChild(getPeopleSelectorRoutes()),
    PeopleSelectorModule,
  ],
  exports: [RouterModule],
})
export class PeopleSelectorRoutesModule {}
