// source: pbis_stats_internal/pbis_stats_internal.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

import * as jspb from 'google-protobuf';
export namespace InternalPbisStat {
  export interface AsObject {
    typeId: number;
    year: number;
    month: number;
    date: string;
    hour: number;
    week: number;
    total: number;
    totalGuidance: number;
    totalPraise: number;
    typeName: string;
    typeCategory: number;
    issuedBy: number;
    issuedTo: number;
    totalPoints: number;
    totalConsequence: number;
    totalOverdueConsequence: number;
  }
}
export class InternalPbisStat extends jspb.Message {
  static readonly displayName = "InternalPbisStat";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): InternalPbisStat.AsObject {
    return InternalPbisStat.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!InternalPbisStat} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: InternalPbisStat) {
    var f: any;
    var obj: any = {
      typeId: jspb.Message.getFieldWithDefault(msg, 1, 0),
      year: jspb.Message.getFieldWithDefault(msg, 2, 0),
      month: jspb.Message.getFieldWithDefault(msg, 3, 0),
      date: jspb.Message.getFieldWithDefault(msg, 4, ""),
      hour: jspb.Message.getFieldWithDefault(msg, 5, 0),
      week: jspb.Message.getFieldWithDefault(msg, 6, 0),
      total: jspb.Message.getFieldWithDefault(msg, 7, 0),
      totalGuidance: jspb.Message.getFieldWithDefault(msg, 8, 0),
      totalPraise: jspb.Message.getFieldWithDefault(msg, 9, 0),
      typeName: jspb.Message.getFieldWithDefault(msg, 10, ""),
      typeCategory: jspb.Message.getFieldWithDefault(msg, 11, 0),
      issuedBy: jspb.Message.getFieldWithDefault(msg, 12, 0),
      issuedTo: jspb.Message.getFieldWithDefault(msg, 13, 0),
      totalPoints: jspb.Message.getFieldWithDefault(msg, 14, 0),
      totalConsequence: jspb.Message.getFieldWithDefault(msg, 15, 0),
      totalOverdueConsequence: jspb.Message.getFieldWithDefault(msg, 16, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!InternalPbisStat}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new InternalPbisStat;
    return InternalPbisStat.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!InternalPbisStat} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!InternalPbisStat}
   */
  static deserializeBinaryFromReader(msg: InternalPbisStat, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {number} */ (reader.readInt32());
        msg.setTypeId(value1);
        break;
      case 2:
        var value2 = /** @type {number} */ (reader.readInt32());
        msg.setYear(value2);
        break;
      case 3:
        var value3 = /** @type {number} */ (reader.readInt32());
        msg.setMonth(value3);
        break;
      case 4:
        var value4 = /** @type {string} */ (reader.readString());
        msg.setDate(value4);
        break;
      case 5:
        var value5 = /** @type {number} */ (reader.readInt32());
        msg.setHour(value5);
        break;
      case 6:
        var value6 = /** @type {number} */ (reader.readInt32());
        msg.setWeek(value6);
        break;
      case 7:
        var value7 = /** @type {number} */ (reader.readInt32());
        msg.setTotal(value7);
        break;
      case 8:
        var value8 = /** @type {number} */ (reader.readInt32());
        msg.setTotalGuidance(value8);
        break;
      case 9:
        var value9 = /** @type {number} */ (reader.readInt32());
        msg.setTotalPraise(value9);
        break;
      case 10:
        var value10 = /** @type {string} */ (reader.readString());
        msg.setTypeName(value10);
        break;
      case 11:
        var value11 = /** @type {number} */ (reader.readInt32());
        msg.setTypeCategory(value11);
        break;
      case 12:
        var value12 = /** @type {number} */ (reader.readInt32());
        msg.setIssuedBy(value12);
        break;
      case 13:
        var value13 = /** @type {number} */ (reader.readInt32());
        msg.setIssuedTo(value13);
        break;
      case 14:
        var value14 = /** @type {number} */ (reader.readInt32());
        msg.setTotalPoints(value14);
        break;
      case 15:
        var value15 = /** @type {number} */ (reader.readInt32());
        msg.setTotalConsequence(value15);
        break;
      case 16:
        var value16 = /** @type {number} */ (reader.readInt32());
        msg.setTotalOverdueConsequence(value16);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!InternalPbisStat} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: InternalPbisStat, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getTypeId();
    if (f !== 0) {
      writer.writeInt32(
        1,
        f
      );
    }
    f = message.getYear();
    if (f !== 0) {
      writer.writeInt32(
        2,
        f
      );
    }
    f = message.getMonth();
    if (f !== 0) {
      writer.writeInt32(
        3,
        f
      );
    }
    f = message.getDate();
    if (f.length > 0) {
      writer.writeString(
        4,
        f
      );
    }
    f = message.getHour();
    if (f !== 0) {
      writer.writeInt32(
        5,
        f
      );
    }
    f = message.getWeek();
    if (f !== 0) {
      writer.writeInt32(
        6,
        f
      );
    }
    f = message.getTotal();
    if (f !== 0) {
      writer.writeInt32(
        7,
        f
      );
    }
    f = message.getTotalGuidance();
    if (f !== 0) {
      writer.writeInt32(
        8,
        f
      );
    }
    f = message.getTotalPraise();
    if (f !== 0) {
      writer.writeInt32(
        9,
        f
      );
    }
    f = message.getTypeName();
    if (f.length > 0) {
      writer.writeString(
        10,
        f
      );
    }
    f = message.getTypeCategory();
    if (f !== 0) {
      writer.writeInt32(
        11,
        f
      );
    }
    f = message.getIssuedBy();
    if (f !== 0) {
      writer.writeInt32(
        12,
        f
      );
    }
    f = message.getIssuedTo();
    if (f !== 0) {
      writer.writeInt32(
        13,
        f
      );
    }
    f = message.getTotalPoints();
    if (f !== 0) {
      writer.writeInt32(
        14,
        f
      );
    }
    f = message.getTotalConsequence();
    if (f !== 0) {
      writer.writeInt32(
        15,
        f
      );
    }
    f = message.getTotalOverdueConsequence();
    if (f !== 0) {
      writer.writeInt32(
        16,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    InternalPbisStat.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional int32 type_id = 1;
   * @return {number}
   */
  getTypeId(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
  };


  /** @param {number} value */
  setTypeId(value: number) {
    jspb.Message.setProto3IntField(this, 1, value);
  }


  /**
   * optional int32 year = 2;
   * @return {number}
   */
  getYear(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
  };


  /** @param {number} value */
  setYear(value: number) {
    jspb.Message.setProto3IntField(this, 2, value);
  }


  /**
   * optional int32 month = 3;
   * @return {number}
   */
  getMonth(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
  };


  /** @param {number} value */
  setMonth(value: number) {
    jspb.Message.setProto3IntField(this, 3, value);
  }


  /**
   * optional string date = 4;
   * @return {string}
   */
  getDate(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
  };


  /** @param {string} value */
  setDate(value: string) {
    jspb.Message.setProto3StringField(this, 4, value);
  }


  /**
   * optional int32 hour = 5;
   * @return {number}
   */
  getHour(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
  };


  /** @param {number} value */
  setHour(value: number) {
    jspb.Message.setProto3IntField(this, 5, value);
  }


  /**
   * optional int32 week = 6;
   * @return {number}
   */
  getWeek(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
  };


  /** @param {number} value */
  setWeek(value: number) {
    jspb.Message.setProto3IntField(this, 6, value);
  }


  /**
   * optional int32 total = 7;
   * @return {number}
   */
  getTotal(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
  };


  /** @param {number} value */
  setTotal(value: number) {
    jspb.Message.setProto3IntField(this, 7, value);
  }


  /**
   * optional int32 total_guidance = 8;
   * @return {number}
   */
  getTotalGuidance(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
  };


  /** @param {number} value */
  setTotalGuidance(value: number) {
    jspb.Message.setProto3IntField(this, 8, value);
  }


  /**
   * optional int32 total_praise = 9;
   * @return {number}
   */
  getTotalPraise(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
  };


  /** @param {number} value */
  setTotalPraise(value: number) {
    jspb.Message.setProto3IntField(this, 9, value);
  }


  /**
   * optional string type_name = 10;
   * @return {string}
   */
  getTypeName(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
  };


  /** @param {string} value */
  setTypeName(value: string) {
    jspb.Message.setProto3StringField(this, 10, value);
  }


  /**
   * optional int32 type_category = 11;
   * @return {number}
   */
  getTypeCategory(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
  };


  /** @param {number} value */
  setTypeCategory(value: number) {
    jspb.Message.setProto3IntField(this, 11, value);
  }


  /**
   * optional int32 issued_by = 12;
   * @return {number}
   */
  getIssuedBy(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
  };


  /** @param {number} value */
  setIssuedBy(value: number) {
    jspb.Message.setProto3IntField(this, 12, value);
  }


  /**
   * optional int32 issued_to = 13;
   * @return {number}
   */
  getIssuedTo(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
  };


  /** @param {number} value */
  setIssuedTo(value: number) {
    jspb.Message.setProto3IntField(this, 13, value);
  }


  /**
   * optional int32 total_points = 14;
   * @return {number}
   */
  getTotalPoints(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
  };


  /** @param {number} value */
  setTotalPoints(value: number) {
    jspb.Message.setProto3IntField(this, 14, value);
  }


  /**
   * optional int32 total_consequence = 15;
   * @return {number}
   */
  getTotalConsequence(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
  };


  /** @param {number} value */
  setTotalConsequence(value: number) {
    jspb.Message.setProto3IntField(this, 15, value);
  }


  /**
   * optional int32 total_overdue_consequence = 16;
   * @return {number}
   */
  getTotalOverdueConsequence(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
  };


  /** @param {number} value */
  setTotalOverdueConsequence(value: number) {
    jspb.Message.setProto3IntField(this, 16, value);
  }


}
export namespace InternalConsequenceStat {
  export interface AsObject {
    typeId: number;
    year: number;
    month: number;
    date: string;
    hour: number;
    week: number;
    totalConsequence: number;
    typeName: string;
    typeCategory: number;
    totalServedConsequence: number;
    totalOutstandingConsequence: number;
    totalOverdueConsequence: number;
    issuedBy: number;
    issuedTo: number;
  }
}
export class InternalConsequenceStat extends jspb.Message {
  static readonly displayName = "InternalConsequenceStat";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): InternalConsequenceStat.AsObject {
    return InternalConsequenceStat.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!InternalConsequenceStat} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: InternalConsequenceStat) {
    var f: any;
    var obj: any = {
      typeId: jspb.Message.getFieldWithDefault(msg, 1, 0),
      year: jspb.Message.getFieldWithDefault(msg, 2, 0),
      month: jspb.Message.getFieldWithDefault(msg, 3, 0),
      date: jspb.Message.getFieldWithDefault(msg, 4, ""),
      hour: jspb.Message.getFieldWithDefault(msg, 5, 0),
      week: jspb.Message.getFieldWithDefault(msg, 6, 0),
      totalConsequence: jspb.Message.getFieldWithDefault(msg, 7, 0),
      typeName: jspb.Message.getFieldWithDefault(msg, 8, ""),
      typeCategory: jspb.Message.getFieldWithDefault(msg, 9, 0),
      totalServedConsequence: jspb.Message.getFieldWithDefault(msg, 10, 0),
      totalOutstandingConsequence: jspb.Message.getFieldWithDefault(msg, 11, 0),
      totalOverdueConsequence: jspb.Message.getFieldWithDefault(msg, 12, 0),
      issuedBy: jspb.Message.getFieldWithDefault(msg, 13, 0),
      issuedTo: jspb.Message.getFieldWithDefault(msg, 14, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!InternalConsequenceStat}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new InternalConsequenceStat;
    return InternalConsequenceStat.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!InternalConsequenceStat} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!InternalConsequenceStat}
   */
  static deserializeBinaryFromReader(msg: InternalConsequenceStat, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {number} */ (reader.readInt32());
        msg.setTypeId(value1);
        break;
      case 2:
        var value2 = /** @type {number} */ (reader.readInt32());
        msg.setYear(value2);
        break;
      case 3:
        var value3 = /** @type {number} */ (reader.readInt32());
        msg.setMonth(value3);
        break;
      case 4:
        var value4 = /** @type {string} */ (reader.readString());
        msg.setDate(value4);
        break;
      case 5:
        var value5 = /** @type {number} */ (reader.readInt32());
        msg.setHour(value5);
        break;
      case 6:
        var value6 = /** @type {number} */ (reader.readInt32());
        msg.setWeek(value6);
        break;
      case 7:
        var value7 = /** @type {number} */ (reader.readInt32());
        msg.setTotalConsequence(value7);
        break;
      case 8:
        var value8 = /** @type {string} */ (reader.readString());
        msg.setTypeName(value8);
        break;
      case 9:
        var value9 = /** @type {number} */ (reader.readInt32());
        msg.setTypeCategory(value9);
        break;
      case 10:
        var value10 = /** @type {number} */ (reader.readInt32());
        msg.setTotalServedConsequence(value10);
        break;
      case 11:
        var value11 = /** @type {number} */ (reader.readInt32());
        msg.setTotalOutstandingConsequence(value11);
        break;
      case 12:
        var value12 = /** @type {number} */ (reader.readInt32());
        msg.setTotalOverdueConsequence(value12);
        break;
      case 13:
        var value13 = /** @type {number} */ (reader.readInt32());
        msg.setIssuedBy(value13);
        break;
      case 14:
        var value14 = /** @type {number} */ (reader.readInt32());
        msg.setIssuedTo(value14);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!InternalConsequenceStat} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: InternalConsequenceStat, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getTypeId();
    if (f !== 0) {
      writer.writeInt32(
        1,
        f
      );
    }
    f = message.getYear();
    if (f !== 0) {
      writer.writeInt32(
        2,
        f
      );
    }
    f = message.getMonth();
    if (f !== 0) {
      writer.writeInt32(
        3,
        f
      );
    }
    f = message.getDate();
    if (f.length > 0) {
      writer.writeString(
        4,
        f
      );
    }
    f = message.getHour();
    if (f !== 0) {
      writer.writeInt32(
        5,
        f
      );
    }
    f = message.getWeek();
    if (f !== 0) {
      writer.writeInt32(
        6,
        f
      );
    }
    f = message.getTotalConsequence();
    if (f !== 0) {
      writer.writeInt32(
        7,
        f
      );
    }
    f = message.getTypeName();
    if (f.length > 0) {
      writer.writeString(
        8,
        f
      );
    }
    f = message.getTypeCategory();
    if (f !== 0) {
      writer.writeInt32(
        9,
        f
      );
    }
    f = message.getTotalServedConsequence();
    if (f !== 0) {
      writer.writeInt32(
        10,
        f
      );
    }
    f = message.getTotalOutstandingConsequence();
    if (f !== 0) {
      writer.writeInt32(
        11,
        f
      );
    }
    f = message.getTotalOverdueConsequence();
    if (f !== 0) {
      writer.writeInt32(
        12,
        f
      );
    }
    f = message.getIssuedBy();
    if (f !== 0) {
      writer.writeInt32(
        13,
        f
      );
    }
    f = message.getIssuedTo();
    if (f !== 0) {
      writer.writeInt32(
        14,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    InternalConsequenceStat.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional int32 type_id = 1;
   * @return {number}
   */
  getTypeId(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
  };


  /** @param {number} value */
  setTypeId(value: number) {
    jspb.Message.setProto3IntField(this, 1, value);
  }


  /**
   * optional int32 year = 2;
   * @return {number}
   */
  getYear(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
  };


  /** @param {number} value */
  setYear(value: number) {
    jspb.Message.setProto3IntField(this, 2, value);
  }


  /**
   * optional int32 month = 3;
   * @return {number}
   */
  getMonth(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
  };


  /** @param {number} value */
  setMonth(value: number) {
    jspb.Message.setProto3IntField(this, 3, value);
  }


  /**
   * optional string date = 4;
   * @return {string}
   */
  getDate(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
  };


  /** @param {string} value */
  setDate(value: string) {
    jspb.Message.setProto3StringField(this, 4, value);
  }


  /**
   * optional int32 hour = 5;
   * @return {number}
   */
  getHour(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
  };


  /** @param {number} value */
  setHour(value: number) {
    jspb.Message.setProto3IntField(this, 5, value);
  }


  /**
   * optional int32 week = 6;
   * @return {number}
   */
  getWeek(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
  };


  /** @param {number} value */
  setWeek(value: number) {
    jspb.Message.setProto3IntField(this, 6, value);
  }


  /**
   * optional int32 total_consequence = 7;
   * @return {number}
   */
  getTotalConsequence(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
  };


  /** @param {number} value */
  setTotalConsequence(value: number) {
    jspb.Message.setProto3IntField(this, 7, value);
  }


  /**
   * optional string type_name = 8;
   * @return {string}
   */
  getTypeName(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
  };


  /** @param {string} value */
  setTypeName(value: string) {
    jspb.Message.setProto3StringField(this, 8, value);
  }


  /**
   * optional int32 type_category = 9;
   * @return {number}
   */
  getTypeCategory(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
  };


  /** @param {number} value */
  setTypeCategory(value: number) {
    jspb.Message.setProto3IntField(this, 9, value);
  }


  /**
   * optional int32 total_served_consequence = 10;
   * @return {number}
   */
  getTotalServedConsequence(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
  };


  /** @param {number} value */
  setTotalServedConsequence(value: number) {
    jspb.Message.setProto3IntField(this, 10, value);
  }


  /**
   * optional int32 total_outstanding_consequence = 11;
   * @return {number}
   */
  getTotalOutstandingConsequence(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
  };


  /** @param {number} value */
  setTotalOutstandingConsequence(value: number) {
    jspb.Message.setProto3IntField(this, 11, value);
  }


  /**
   * optional int32 total_overdue_consequence = 12;
   * @return {number}
   */
  getTotalOverdueConsequence(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
  };


  /** @param {number} value */
  setTotalOverdueConsequence(value: number) {
    jspb.Message.setProto3IntField(this, 12, value);
  }


  /**
   * optional int32 issued_by = 13;
   * @return {number}
   */
  getIssuedBy(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
  };


  /** @param {number} value */
  setIssuedBy(value: number) {
    jspb.Message.setProto3IntField(this, 13, value);
  }


  /**
   * optional int32 issued_to = 14;
   * @return {number}
   */
  getIssuedTo(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
  };


  /** @param {number} value */
  setIssuedTo(value: number) {
    jspb.Message.setProto3IntField(this, 14, value);
  }


}
export namespace InternalGetPbisSummaryReportByTypeRequest {
  export interface AsObject {
    mingaId: number;
    startDate: string;
    endDate: string;
    groupBy: string;
    issuedByList: number[];
    issuedToList: number[];
    behaviorTypeList: number[];
    gradeList: string[];
  }
}
export class InternalGetPbisSummaryReportByTypeRequest extends jspb.Message {
  static readonly displayName = "InternalGetPbisSummaryReportByTypeRequest";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [5,6,7,8];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, InternalGetPbisSummaryReportByTypeRequest.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): InternalGetPbisSummaryReportByTypeRequest.AsObject {
    return InternalGetPbisSummaryReportByTypeRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!InternalGetPbisSummaryReportByTypeRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: InternalGetPbisSummaryReportByTypeRequest) {
    var f: any;
    var obj: any = {
      mingaId: jspb.Message.getFieldWithDefault(msg, 1, 0),
      startDate: jspb.Message.getFieldWithDefault(msg, 2, ""),
      endDate: jspb.Message.getFieldWithDefault(msg, 3, ""),
      groupBy: jspb.Message.getFieldWithDefault(msg, 4, ""),
      issuedByList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f,
      issuedToList: (f = jspb.Message.getRepeatedField(msg, 6)) == null ? undefined : f,
      behaviorTypeList: (f = jspb.Message.getRepeatedField(msg, 7)) == null ? undefined : f,
      gradeList: (f = jspb.Message.getRepeatedField(msg, 8)) == null ? undefined : f
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!InternalGetPbisSummaryReportByTypeRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new InternalGetPbisSummaryReportByTypeRequest;
    return InternalGetPbisSummaryReportByTypeRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!InternalGetPbisSummaryReportByTypeRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!InternalGetPbisSummaryReportByTypeRequest}
   */
  static deserializeBinaryFromReader(msg: InternalGetPbisSummaryReportByTypeRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {number} */ (reader.readInt32());
        msg.setMingaId(value1);
        break;
      case 2:
        var value2 = /** @type {string} */ (reader.readString());
        msg.setStartDate(value2);
        break;
      case 3:
        var value3 = /** @type {string} */ (reader.readString());
        msg.setEndDate(value3);
        break;
      case 4:
        var value4 = /** @type {string} */ (reader.readString());
        msg.setGroupBy(value4);
        break;
      case 5:
        var value5 = /** @type {!Array<number>} */ (reader.readPackedInt32());
        msg.setIssuedByList(value5);
        break;
      case 6:
        var value6 = /** @type {!Array<number>} */ (reader.readPackedInt32());
        msg.setIssuedToList(value6);
        break;
      case 7:
        var value7 = /** @type {!Array<number>} */ (reader.readPackedInt32());
        msg.setBehaviorTypeList(value7);
        break;
      case 8:
        var value8 = /** @type {string} */ (reader.readString());
        msg.addGrade(value8);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!InternalGetPbisSummaryReportByTypeRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: InternalGetPbisSummaryReportByTypeRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getMingaId();
    if (f !== 0) {
      writer.writeInt32(
        1,
        f
      );
    }
    f = message.getStartDate();
    if (f.length > 0) {
      writer.writeString(
        2,
        f
      );
    }
    f = message.getEndDate();
    if (f.length > 0) {
      writer.writeString(
        3,
        f
      );
    }
    f = message.getGroupBy();
    if (f.length > 0) {
      writer.writeString(
        4,
        f
      );
    }
    f = message.getIssuedByList();
    if (f.length > 0) {
      writer.writePackedInt32(
        5,
        f
      );
    }
    f = message.getIssuedToList();
    if (f.length > 0) {
      writer.writePackedInt32(
        6,
        f
      );
    }
    f = message.getBehaviorTypeList();
    if (f.length > 0) {
      writer.writePackedInt32(
        7,
        f
      );
    }
    f = message.getGradeList();
    if (f.length > 0) {
      writer.writeRepeatedString(
        8,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    InternalGetPbisSummaryReportByTypeRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional int32 minga_id = 1;
   * @return {number}
   */
  getMingaId(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
  };


  /** @param {number} value */
  setMingaId(value: number) {
    jspb.Message.setProto3IntField(this, 1, value);
  }


  /**
   * optional string start_date = 2;
   * @return {string}
   */
  getStartDate(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
  };


  /** @param {string} value */
  setStartDate(value: string) {
    jspb.Message.setProto3StringField(this, 2, value);
  }


  /**
   * optional string end_date = 3;
   * @return {string}
   */
  getEndDate(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
  };


  /** @param {string} value */
  setEndDate(value: string) {
    jspb.Message.setProto3StringField(this, 3, value);
  }


  /**
   * optional string group_by = 4;
   * @return {string}
   */
  getGroupBy(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
  };


  /** @param {string} value */
  setGroupBy(value: string) {
    jspb.Message.setProto3StringField(this, 4, value);
  }


  /**
   * repeated int32 issued_by = 5;
   * @return {!Array<number>}
   */
  getIssuedByList(): number[] {
    return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 5));
  };


  /** @param {!Array<number>} value */
  setIssuedByList(value: number[]) {
    jspb.Message.setField(this, 5, value || []);
  }


  /**
   * @param {number} value
   * @param {number=} opt_index
   */
  addIssuedBy(value: number, opt_index?: number): void {
    jspb.Message.addToRepeatedField(this, 5, value, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearIssuedByList() {
    this.setIssuedByList([]);
  }


  /**
   * repeated int32 issued_to = 6;
   * @return {!Array<number>}
   */
  getIssuedToList(): number[] {
    return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 6));
  };


  /** @param {!Array<number>} value */
  setIssuedToList(value: number[]) {
    jspb.Message.setField(this, 6, value || []);
  }


  /**
   * @param {number} value
   * @param {number=} opt_index
   */
  addIssuedTo(value: number, opt_index?: number): void {
    jspb.Message.addToRepeatedField(this, 6, value, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearIssuedToList() {
    this.setIssuedToList([]);
  }


  /**
   * repeated int32 behavior_type = 7;
   * @return {!Array<number>}
   */
  getBehaviorTypeList(): number[] {
    return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 7));
  };


  /** @param {!Array<number>} value */
  setBehaviorTypeList(value: number[]) {
    jspb.Message.setField(this, 7, value || []);
  }


  /**
   * @param {number} value
   * @param {number=} opt_index
   */
  addBehaviorType(value: number, opt_index?: number): void {
    jspb.Message.addToRepeatedField(this, 7, value, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearBehaviorTypeList() {
    this.setBehaviorTypeList([]);
  }


  /**
   * repeated string grade = 8;
   * @return {!Array<string>}
   */
  getGradeList(): string[] {
    return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 8));
  };


  /** @param {!Array<string>} value */
  setGradeList(value: string[]) {
    jspb.Message.setField(this, 8, value || []);
  }


  /**
   * @param {string} value
   * @param {number=} opt_index
   */
  addGrade(value: string, opt_index?: number): void {
    jspb.Message.addToRepeatedField(this, 8, value, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearGradeList() {
    this.setGradeList([]);
  }


}
export namespace InternalGetPbisSummaryReportByTypeResponse {
  export interface AsObject {
    statsList: InternalPbisStat.AsObject[];
  }
}
export class InternalGetPbisSummaryReportByTypeResponse extends jspb.Message {
  static readonly displayName = "InternalGetPbisSummaryReportByTypeResponse";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [1];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, InternalGetPbisSummaryReportByTypeResponse.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): InternalGetPbisSummaryReportByTypeResponse.AsObject {
    return InternalGetPbisSummaryReportByTypeResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!InternalGetPbisSummaryReportByTypeResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: InternalGetPbisSummaryReportByTypeResponse) {
    var f: any;
    var obj: any = {
      statsList: jspb.Message.toObjectList(msg.getStatsList(),
      InternalPbisStat.toObject, includeInstance)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!InternalGetPbisSummaryReportByTypeResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new InternalGetPbisSummaryReportByTypeResponse;
    return InternalGetPbisSummaryReportByTypeResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!InternalGetPbisSummaryReportByTypeResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!InternalGetPbisSummaryReportByTypeResponse}
   */
  static deserializeBinaryFromReader(msg: InternalGetPbisSummaryReportByTypeResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new InternalPbisStat;
        reader.readMessage(value1,InternalPbisStat.deserializeBinaryFromReader);
        msg.addStats(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!InternalGetPbisSummaryReportByTypeResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: InternalGetPbisSummaryReportByTypeResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getStatsList();
    if (f.length > 0) {
      writer.writeRepeatedMessage(
        1,
        f,
        InternalPbisStat.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    InternalGetPbisSummaryReportByTypeResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * repeated InternalPbisStat stats = 1;
   * @return {!Array<!InternalPbisStat>}
   */
  getStatsList(): InternalPbisStat[] {
    return /** @type{!Array<!InternalPbisStat>} */ (
      jspb.Message.getRepeatedWrapperField(this, InternalPbisStat, 1));
  }


  /** @param {!Array<!InternalPbisStat>} value */
  setStatsList(value?: InternalPbisStat[]) {
    jspb.Message.setRepeatedWrapperField(this, 1, value);
  }


  /**
   * @param {!InternalPbisStat=} opt_value
   * @param {number=} opt_index
   * @return {!InternalPbisStat}
   */
  addStats(opt_value?: InternalPbisStat, opt_index?: number): InternalPbisStat {
    return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, InternalPbisStat, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearStatsList() {
    this.setStatsList([]);
  }


}
export namespace InternalGetPbisTypeReportRequest {
  export interface AsObject {
    mingaId: number;
    startDate: string;
    endDate: string;
    issuedByList: number[];
    issuedToList: number[];
    behaviorTypeList: number[];
    gradeList: string[];
  }
}
export class InternalGetPbisTypeReportRequest extends jspb.Message {
  static readonly displayName = "InternalGetPbisTypeReportRequest";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [4,5,6,7];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, InternalGetPbisTypeReportRequest.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): InternalGetPbisTypeReportRequest.AsObject {
    return InternalGetPbisTypeReportRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!InternalGetPbisTypeReportRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: InternalGetPbisTypeReportRequest) {
    var f: any;
    var obj: any = {
      mingaId: jspb.Message.getFieldWithDefault(msg, 1, 0),
      startDate: jspb.Message.getFieldWithDefault(msg, 2, ""),
      endDate: jspb.Message.getFieldWithDefault(msg, 3, ""),
      issuedByList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
      issuedToList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f,
      behaviorTypeList: (f = jspb.Message.getRepeatedField(msg, 6)) == null ? undefined : f,
      gradeList: (f = jspb.Message.getRepeatedField(msg, 7)) == null ? undefined : f
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!InternalGetPbisTypeReportRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new InternalGetPbisTypeReportRequest;
    return InternalGetPbisTypeReportRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!InternalGetPbisTypeReportRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!InternalGetPbisTypeReportRequest}
   */
  static deserializeBinaryFromReader(msg: InternalGetPbisTypeReportRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {number} */ (reader.readInt32());
        msg.setMingaId(value1);
        break;
      case 2:
        var value2 = /** @type {string} */ (reader.readString());
        msg.setStartDate(value2);
        break;
      case 3:
        var value3 = /** @type {string} */ (reader.readString());
        msg.setEndDate(value3);
        break;
      case 4:
        var value4 = /** @type {!Array<number>} */ (reader.readPackedInt32());
        msg.setIssuedByList(value4);
        break;
      case 5:
        var value5 = /** @type {!Array<number>} */ (reader.readPackedInt32());
        msg.setIssuedToList(value5);
        break;
      case 6:
        var value6 = /** @type {!Array<number>} */ (reader.readPackedInt32());
        msg.setBehaviorTypeList(value6);
        break;
      case 7:
        var value7 = /** @type {string} */ (reader.readString());
        msg.addGrade(value7);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!InternalGetPbisTypeReportRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: InternalGetPbisTypeReportRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getMingaId();
    if (f !== 0) {
      writer.writeInt32(
        1,
        f
      );
    }
    f = message.getStartDate();
    if (f.length > 0) {
      writer.writeString(
        2,
        f
      );
    }
    f = message.getEndDate();
    if (f.length > 0) {
      writer.writeString(
        3,
        f
      );
    }
    f = message.getIssuedByList();
    if (f.length > 0) {
      writer.writePackedInt32(
        4,
        f
      );
    }
    f = message.getIssuedToList();
    if (f.length > 0) {
      writer.writePackedInt32(
        5,
        f
      );
    }
    f = message.getBehaviorTypeList();
    if (f.length > 0) {
      writer.writePackedInt32(
        6,
        f
      );
    }
    f = message.getGradeList();
    if (f.length > 0) {
      writer.writeRepeatedString(
        7,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    InternalGetPbisTypeReportRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional int32 minga_id = 1;
   * @return {number}
   */
  getMingaId(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
  };


  /** @param {number} value */
  setMingaId(value: number) {
    jspb.Message.setProto3IntField(this, 1, value);
  }


  /**
   * optional string start_date = 2;
   * @return {string}
   */
  getStartDate(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
  };


  /** @param {string} value */
  setStartDate(value: string) {
    jspb.Message.setProto3StringField(this, 2, value);
  }


  /**
   * optional string end_date = 3;
   * @return {string}
   */
  getEndDate(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
  };


  /** @param {string} value */
  setEndDate(value: string) {
    jspb.Message.setProto3StringField(this, 3, value);
  }


  /**
   * repeated int32 issued_by = 4;
   * @return {!Array<number>}
   */
  getIssuedByList(): number[] {
    return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 4));
  };


  /** @param {!Array<number>} value */
  setIssuedByList(value: number[]) {
    jspb.Message.setField(this, 4, value || []);
  }


  /**
   * @param {number} value
   * @param {number=} opt_index
   */
  addIssuedBy(value: number, opt_index?: number): void {
    jspb.Message.addToRepeatedField(this, 4, value, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearIssuedByList() {
    this.setIssuedByList([]);
  }


  /**
   * repeated int32 issued_to = 5;
   * @return {!Array<number>}
   */
  getIssuedToList(): number[] {
    return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 5));
  };


  /** @param {!Array<number>} value */
  setIssuedToList(value: number[]) {
    jspb.Message.setField(this, 5, value || []);
  }


  /**
   * @param {number} value
   * @param {number=} opt_index
   */
  addIssuedTo(value: number, opt_index?: number): void {
    jspb.Message.addToRepeatedField(this, 5, value, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearIssuedToList() {
    this.setIssuedToList([]);
  }


  /**
   * repeated int32 behavior_type = 6;
   * @return {!Array<number>}
   */
  getBehaviorTypeList(): number[] {
    return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 6));
  };


  /** @param {!Array<number>} value */
  setBehaviorTypeList(value: number[]) {
    jspb.Message.setField(this, 6, value || []);
  }


  /**
   * @param {number} value
   * @param {number=} opt_index
   */
  addBehaviorType(value: number, opt_index?: number): void {
    jspb.Message.addToRepeatedField(this, 6, value, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearBehaviorTypeList() {
    this.setBehaviorTypeList([]);
  }


  /**
   * repeated string grade = 7;
   * @return {!Array<string>}
   */
  getGradeList(): string[] {
    return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 7));
  };


  /** @param {!Array<string>} value */
  setGradeList(value: string[]) {
    jspb.Message.setField(this, 7, value || []);
  }


  /**
   * @param {string} value
   * @param {number=} opt_index
   */
  addGrade(value: string, opt_index?: number): void {
    jspb.Message.addToRepeatedField(this, 7, value, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearGradeList() {
    this.setGradeList([]);
  }


}
export namespace InternalGetPbisTypeReportResponse {
  export interface AsObject {
    statsList: InternalPbisStat.AsObject[];
  }
}
export class InternalGetPbisTypeReportResponse extends jspb.Message {
  static readonly displayName = "InternalGetPbisTypeReportResponse";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [1];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, InternalGetPbisTypeReportResponse.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): InternalGetPbisTypeReportResponse.AsObject {
    return InternalGetPbisTypeReportResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!InternalGetPbisTypeReportResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: InternalGetPbisTypeReportResponse) {
    var f: any;
    var obj: any = {
      statsList: jspb.Message.toObjectList(msg.getStatsList(),
      InternalPbisStat.toObject, includeInstance)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!InternalGetPbisTypeReportResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new InternalGetPbisTypeReportResponse;
    return InternalGetPbisTypeReportResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!InternalGetPbisTypeReportResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!InternalGetPbisTypeReportResponse}
   */
  static deserializeBinaryFromReader(msg: InternalGetPbisTypeReportResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new InternalPbisStat;
        reader.readMessage(value1,InternalPbisStat.deserializeBinaryFromReader);
        msg.addStats(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!InternalGetPbisTypeReportResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: InternalGetPbisTypeReportResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getStatsList();
    if (f.length > 0) {
      writer.writeRepeatedMessage(
        1,
        f,
        InternalPbisStat.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    InternalGetPbisTypeReportResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * repeated InternalPbisStat stats = 1;
   * @return {!Array<!InternalPbisStat>}
   */
  getStatsList(): InternalPbisStat[] {
    return /** @type{!Array<!InternalPbisStat>} */ (
      jspb.Message.getRepeatedWrapperField(this, InternalPbisStat, 1));
  }


  /** @param {!Array<!InternalPbisStat>} value */
  setStatsList(value?: InternalPbisStat[]) {
    jspb.Message.setRepeatedWrapperField(this, 1, value);
  }


  /**
   * @param {!InternalPbisStat=} opt_value
   * @param {number=} opt_index
   * @return {!InternalPbisStat}
   */
  addStats(opt_value?: InternalPbisStat, opt_index?: number): InternalPbisStat {
    return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, InternalPbisStat, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearStatsList() {
    this.setStatsList([]);
  }


}
export namespace InternalGetPbisTopUsersRequest {
  export interface AsObject {
    mingaId: number;
    startDate: string;
    endDate: string;
    issuedByList: number[];
    issuedToList: number[];
    behaviorTypeList: number[];
    gradeList: string[];
    offset: number;
    limit: number;
  }
}
export class InternalGetPbisTopUsersRequest extends jspb.Message {
  static readonly displayName = "InternalGetPbisTopUsersRequest";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [4,5,6,7];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, InternalGetPbisTopUsersRequest.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): InternalGetPbisTopUsersRequest.AsObject {
    return InternalGetPbisTopUsersRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!InternalGetPbisTopUsersRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: InternalGetPbisTopUsersRequest) {
    var f: any;
    var obj: any = {
      mingaId: jspb.Message.getFieldWithDefault(msg, 1, 0),
      startDate: jspb.Message.getFieldWithDefault(msg, 2, ""),
      endDate: jspb.Message.getFieldWithDefault(msg, 3, ""),
      issuedByList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
      issuedToList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f,
      behaviorTypeList: (f = jspb.Message.getRepeatedField(msg, 6)) == null ? undefined : f,
      gradeList: (f = jspb.Message.getRepeatedField(msg, 7)) == null ? undefined : f,
      offset: jspb.Message.getFieldWithDefault(msg, 8, 0),
      limit: jspb.Message.getFieldWithDefault(msg, 9, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!InternalGetPbisTopUsersRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new InternalGetPbisTopUsersRequest;
    return InternalGetPbisTopUsersRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!InternalGetPbisTopUsersRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!InternalGetPbisTopUsersRequest}
   */
  static deserializeBinaryFromReader(msg: InternalGetPbisTopUsersRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {number} */ (reader.readInt32());
        msg.setMingaId(value1);
        break;
      case 2:
        var value2 = /** @type {string} */ (reader.readString());
        msg.setStartDate(value2);
        break;
      case 3:
        var value3 = /** @type {string} */ (reader.readString());
        msg.setEndDate(value3);
        break;
      case 4:
        var value4 = /** @type {!Array<number>} */ (reader.readPackedInt32());
        msg.setIssuedByList(value4);
        break;
      case 5:
        var value5 = /** @type {!Array<number>} */ (reader.readPackedInt32());
        msg.setIssuedToList(value5);
        break;
      case 6:
        var value6 = /** @type {!Array<number>} */ (reader.readPackedInt32());
        msg.setBehaviorTypeList(value6);
        break;
      case 7:
        var value7 = /** @type {string} */ (reader.readString());
        msg.addGrade(value7);
        break;
      case 8:
        var value8 = /** @type {number} */ (reader.readInt32());
        msg.setOffset(value8);
        break;
      case 9:
        var value9 = /** @type {number} */ (reader.readInt32());
        msg.setLimit(value9);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!InternalGetPbisTopUsersRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: InternalGetPbisTopUsersRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getMingaId();
    if (f !== 0) {
      writer.writeInt32(
        1,
        f
      );
    }
    f = message.getStartDate();
    if (f.length > 0) {
      writer.writeString(
        2,
        f
      );
    }
    f = message.getEndDate();
    if (f.length > 0) {
      writer.writeString(
        3,
        f
      );
    }
    f = message.getIssuedByList();
    if (f.length > 0) {
      writer.writePackedInt32(
        4,
        f
      );
    }
    f = message.getIssuedToList();
    if (f.length > 0) {
      writer.writePackedInt32(
        5,
        f
      );
    }
    f = message.getBehaviorTypeList();
    if (f.length > 0) {
      writer.writePackedInt32(
        6,
        f
      );
    }
    f = message.getGradeList();
    if (f.length > 0) {
      writer.writeRepeatedString(
        7,
        f
      );
    }
    f = message.getOffset();
    if (f !== 0) {
      writer.writeInt32(
        8,
        f
      );
    }
    f = message.getLimit();
    if (f !== 0) {
      writer.writeInt32(
        9,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    InternalGetPbisTopUsersRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional int32 minga_id = 1;
   * @return {number}
   */
  getMingaId(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
  };


  /** @param {number} value */
  setMingaId(value: number) {
    jspb.Message.setProto3IntField(this, 1, value);
  }


  /**
   * optional string start_date = 2;
   * @return {string}
   */
  getStartDate(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
  };


  /** @param {string} value */
  setStartDate(value: string) {
    jspb.Message.setProto3StringField(this, 2, value);
  }


  /**
   * optional string end_date = 3;
   * @return {string}
   */
  getEndDate(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
  };


  /** @param {string} value */
  setEndDate(value: string) {
    jspb.Message.setProto3StringField(this, 3, value);
  }


  /**
   * repeated int32 issued_by = 4;
   * @return {!Array<number>}
   */
  getIssuedByList(): number[] {
    return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 4));
  };


  /** @param {!Array<number>} value */
  setIssuedByList(value: number[]) {
    jspb.Message.setField(this, 4, value || []);
  }


  /**
   * @param {number} value
   * @param {number=} opt_index
   */
  addIssuedBy(value: number, opt_index?: number): void {
    jspb.Message.addToRepeatedField(this, 4, value, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearIssuedByList() {
    this.setIssuedByList([]);
  }


  /**
   * repeated int32 issued_to = 5;
   * @return {!Array<number>}
   */
  getIssuedToList(): number[] {
    return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 5));
  };


  /** @param {!Array<number>} value */
  setIssuedToList(value: number[]) {
    jspb.Message.setField(this, 5, value || []);
  }


  /**
   * @param {number} value
   * @param {number=} opt_index
   */
  addIssuedTo(value: number, opt_index?: number): void {
    jspb.Message.addToRepeatedField(this, 5, value, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearIssuedToList() {
    this.setIssuedToList([]);
  }


  /**
   * repeated int32 behavior_type = 6;
   * @return {!Array<number>}
   */
  getBehaviorTypeList(): number[] {
    return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 6));
  };


  /** @param {!Array<number>} value */
  setBehaviorTypeList(value: number[]) {
    jspb.Message.setField(this, 6, value || []);
  }


  /**
   * @param {number} value
   * @param {number=} opt_index
   */
  addBehaviorType(value: number, opt_index?: number): void {
    jspb.Message.addToRepeatedField(this, 6, value, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearBehaviorTypeList() {
    this.setBehaviorTypeList([]);
  }


  /**
   * repeated string grade = 7;
   * @return {!Array<string>}
   */
  getGradeList(): string[] {
    return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 7));
  };


  /** @param {!Array<string>} value */
  setGradeList(value: string[]) {
    jspb.Message.setField(this, 7, value || []);
  }


  /**
   * @param {string} value
   * @param {number=} opt_index
   */
  addGrade(value: string, opt_index?: number): void {
    jspb.Message.addToRepeatedField(this, 7, value, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearGradeList() {
    this.setGradeList([]);
  }


  /**
   * optional int32 offset = 8;
   * @return {number}
   */
  getOffset(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
  };


  /** @param {number} value */
  setOffset(value: number) {
    jspb.Message.setProto3IntField(this, 8, value);
  }


  /**
   * optional int32 limit = 9;
   * @return {number}
   */
  getLimit(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
  };


  /** @param {number} value */
  setLimit(value: number) {
    jspb.Message.setProto3IntField(this, 9, value);
  }


}
export namespace InternalGetPbisTopUsersResponse {
  export interface AsObject {
    statsList: InternalPbisStat.AsObject[];
  }
}
export class InternalGetPbisTopUsersResponse extends jspb.Message {
  static readonly displayName = "InternalGetPbisTopUsersResponse";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [1];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, InternalGetPbisTopUsersResponse.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): InternalGetPbisTopUsersResponse.AsObject {
    return InternalGetPbisTopUsersResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!InternalGetPbisTopUsersResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: InternalGetPbisTopUsersResponse) {
    var f: any;
    var obj: any = {
      statsList: jspb.Message.toObjectList(msg.getStatsList(),
      InternalPbisStat.toObject, includeInstance)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!InternalGetPbisTopUsersResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new InternalGetPbisTopUsersResponse;
    return InternalGetPbisTopUsersResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!InternalGetPbisTopUsersResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!InternalGetPbisTopUsersResponse}
   */
  static deserializeBinaryFromReader(msg: InternalGetPbisTopUsersResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new InternalPbisStat;
        reader.readMessage(value1,InternalPbisStat.deserializeBinaryFromReader);
        msg.addStats(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!InternalGetPbisTopUsersResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: InternalGetPbisTopUsersResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getStatsList();
    if (f.length > 0) {
      writer.writeRepeatedMessage(
        1,
        f,
        InternalPbisStat.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    InternalGetPbisTopUsersResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * repeated InternalPbisStat stats = 1;
   * @return {!Array<!InternalPbisStat>}
   */
  getStatsList(): InternalPbisStat[] {
    return /** @type{!Array<!InternalPbisStat>} */ (
      jspb.Message.getRepeatedWrapperField(this, InternalPbisStat, 1));
  }


  /** @param {!Array<!InternalPbisStat>} value */
  setStatsList(value?: InternalPbisStat[]) {
    jspb.Message.setRepeatedWrapperField(this, 1, value);
  }


  /**
   * @param {!InternalPbisStat=} opt_value
   * @param {number=} opt_index
   * @return {!InternalPbisStat}
   */
  addStats(opt_value?: InternalPbisStat, opt_index?: number): InternalPbisStat {
    return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, InternalPbisStat, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearStatsList() {
    this.setStatsList([]);
  }


}
export namespace InternalGetPbisTopCreatorsRequest {
  export interface AsObject {
    mingaId: number;
    startDate: string;
    endDate: string;
    issuedByList: number[];
    issuedToList: number[];
    behaviorTypeList: number[];
    gradeList: string[];
    offset: number;
    limit: number;
  }
}
export class InternalGetPbisTopCreatorsRequest extends jspb.Message {
  static readonly displayName = "InternalGetPbisTopCreatorsRequest";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [4,5,6,7];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, InternalGetPbisTopCreatorsRequest.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): InternalGetPbisTopCreatorsRequest.AsObject {
    return InternalGetPbisTopCreatorsRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!InternalGetPbisTopCreatorsRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: InternalGetPbisTopCreatorsRequest) {
    var f: any;
    var obj: any = {
      mingaId: jspb.Message.getFieldWithDefault(msg, 1, 0),
      startDate: jspb.Message.getFieldWithDefault(msg, 2, ""),
      endDate: jspb.Message.getFieldWithDefault(msg, 3, ""),
      issuedByList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
      issuedToList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f,
      behaviorTypeList: (f = jspb.Message.getRepeatedField(msg, 6)) == null ? undefined : f,
      gradeList: (f = jspb.Message.getRepeatedField(msg, 7)) == null ? undefined : f,
      offset: jspb.Message.getFieldWithDefault(msg, 8, 0),
      limit: jspb.Message.getFieldWithDefault(msg, 9, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!InternalGetPbisTopCreatorsRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new InternalGetPbisTopCreatorsRequest;
    return InternalGetPbisTopCreatorsRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!InternalGetPbisTopCreatorsRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!InternalGetPbisTopCreatorsRequest}
   */
  static deserializeBinaryFromReader(msg: InternalGetPbisTopCreatorsRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {number} */ (reader.readInt32());
        msg.setMingaId(value1);
        break;
      case 2:
        var value2 = /** @type {string} */ (reader.readString());
        msg.setStartDate(value2);
        break;
      case 3:
        var value3 = /** @type {string} */ (reader.readString());
        msg.setEndDate(value3);
        break;
      case 4:
        var value4 = /** @type {!Array<number>} */ (reader.readPackedInt32());
        msg.setIssuedByList(value4);
        break;
      case 5:
        var value5 = /** @type {!Array<number>} */ (reader.readPackedInt32());
        msg.setIssuedToList(value5);
        break;
      case 6:
        var value6 = /** @type {!Array<number>} */ (reader.readPackedInt32());
        msg.setBehaviorTypeList(value6);
        break;
      case 7:
        var value7 = /** @type {string} */ (reader.readString());
        msg.addGrade(value7);
        break;
      case 8:
        var value8 = /** @type {number} */ (reader.readInt32());
        msg.setOffset(value8);
        break;
      case 9:
        var value9 = /** @type {number} */ (reader.readInt32());
        msg.setLimit(value9);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!InternalGetPbisTopCreatorsRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: InternalGetPbisTopCreatorsRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getMingaId();
    if (f !== 0) {
      writer.writeInt32(
        1,
        f
      );
    }
    f = message.getStartDate();
    if (f.length > 0) {
      writer.writeString(
        2,
        f
      );
    }
    f = message.getEndDate();
    if (f.length > 0) {
      writer.writeString(
        3,
        f
      );
    }
    f = message.getIssuedByList();
    if (f.length > 0) {
      writer.writePackedInt32(
        4,
        f
      );
    }
    f = message.getIssuedToList();
    if (f.length > 0) {
      writer.writePackedInt32(
        5,
        f
      );
    }
    f = message.getBehaviorTypeList();
    if (f.length > 0) {
      writer.writePackedInt32(
        6,
        f
      );
    }
    f = message.getGradeList();
    if (f.length > 0) {
      writer.writeRepeatedString(
        7,
        f
      );
    }
    f = message.getOffset();
    if (f !== 0) {
      writer.writeInt32(
        8,
        f
      );
    }
    f = message.getLimit();
    if (f !== 0) {
      writer.writeInt32(
        9,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    InternalGetPbisTopCreatorsRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional int32 minga_id = 1;
   * @return {number}
   */
  getMingaId(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
  };


  /** @param {number} value */
  setMingaId(value: number) {
    jspb.Message.setProto3IntField(this, 1, value);
  }


  /**
   * optional string start_date = 2;
   * @return {string}
   */
  getStartDate(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
  };


  /** @param {string} value */
  setStartDate(value: string) {
    jspb.Message.setProto3StringField(this, 2, value);
  }


  /**
   * optional string end_date = 3;
   * @return {string}
   */
  getEndDate(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
  };


  /** @param {string} value */
  setEndDate(value: string) {
    jspb.Message.setProto3StringField(this, 3, value);
  }


  /**
   * repeated int32 issued_by = 4;
   * @return {!Array<number>}
   */
  getIssuedByList(): number[] {
    return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 4));
  };


  /** @param {!Array<number>} value */
  setIssuedByList(value: number[]) {
    jspb.Message.setField(this, 4, value || []);
  }


  /**
   * @param {number} value
   * @param {number=} opt_index
   */
  addIssuedBy(value: number, opt_index?: number): void {
    jspb.Message.addToRepeatedField(this, 4, value, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearIssuedByList() {
    this.setIssuedByList([]);
  }


  /**
   * repeated int32 issued_to = 5;
   * @return {!Array<number>}
   */
  getIssuedToList(): number[] {
    return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 5));
  };


  /** @param {!Array<number>} value */
  setIssuedToList(value: number[]) {
    jspb.Message.setField(this, 5, value || []);
  }


  /**
   * @param {number} value
   * @param {number=} opt_index
   */
  addIssuedTo(value: number, opt_index?: number): void {
    jspb.Message.addToRepeatedField(this, 5, value, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearIssuedToList() {
    this.setIssuedToList([]);
  }


  /**
   * repeated int32 behavior_type = 6;
   * @return {!Array<number>}
   */
  getBehaviorTypeList(): number[] {
    return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 6));
  };


  /** @param {!Array<number>} value */
  setBehaviorTypeList(value: number[]) {
    jspb.Message.setField(this, 6, value || []);
  }


  /**
   * @param {number} value
   * @param {number=} opt_index
   */
  addBehaviorType(value: number, opt_index?: number): void {
    jspb.Message.addToRepeatedField(this, 6, value, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearBehaviorTypeList() {
    this.setBehaviorTypeList([]);
  }


  /**
   * repeated string grade = 7;
   * @return {!Array<string>}
   */
  getGradeList(): string[] {
    return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 7));
  };


  /** @param {!Array<string>} value */
  setGradeList(value: string[]) {
    jspb.Message.setField(this, 7, value || []);
  }


  /**
   * @param {string} value
   * @param {number=} opt_index
   */
  addGrade(value: string, opt_index?: number): void {
    jspb.Message.addToRepeatedField(this, 7, value, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearGradeList() {
    this.setGradeList([]);
  }


  /**
   * optional int32 offset = 8;
   * @return {number}
   */
  getOffset(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
  };


  /** @param {number} value */
  setOffset(value: number) {
    jspb.Message.setProto3IntField(this, 8, value);
  }


  /**
   * optional int32 limit = 9;
   * @return {number}
   */
  getLimit(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
  };


  /** @param {number} value */
  setLimit(value: number) {
    jspb.Message.setProto3IntField(this, 9, value);
  }


}
export namespace InternalGetPbisTopCreatorsResponse {
  export interface AsObject {
    statsList: InternalPbisStat.AsObject[];
  }
}
export class InternalGetPbisTopCreatorsResponse extends jspb.Message {
  static readonly displayName = "InternalGetPbisTopCreatorsResponse";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [1];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, InternalGetPbisTopCreatorsResponse.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): InternalGetPbisTopCreatorsResponse.AsObject {
    return InternalGetPbisTopCreatorsResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!InternalGetPbisTopCreatorsResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: InternalGetPbisTopCreatorsResponse) {
    var f: any;
    var obj: any = {
      statsList: jspb.Message.toObjectList(msg.getStatsList(),
      InternalPbisStat.toObject, includeInstance)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!InternalGetPbisTopCreatorsResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new InternalGetPbisTopCreatorsResponse;
    return InternalGetPbisTopCreatorsResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!InternalGetPbisTopCreatorsResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!InternalGetPbisTopCreatorsResponse}
   */
  static deserializeBinaryFromReader(msg: InternalGetPbisTopCreatorsResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new InternalPbisStat;
        reader.readMessage(value1,InternalPbisStat.deserializeBinaryFromReader);
        msg.addStats(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!InternalGetPbisTopCreatorsResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: InternalGetPbisTopCreatorsResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getStatsList();
    if (f.length > 0) {
      writer.writeRepeatedMessage(
        1,
        f,
        InternalPbisStat.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    InternalGetPbisTopCreatorsResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * repeated InternalPbisStat stats = 1;
   * @return {!Array<!InternalPbisStat>}
   */
  getStatsList(): InternalPbisStat[] {
    return /** @type{!Array<!InternalPbisStat>} */ (
      jspb.Message.getRepeatedWrapperField(this, InternalPbisStat, 1));
  }


  /** @param {!Array<!InternalPbisStat>} value */
  setStatsList(value?: InternalPbisStat[]) {
    jspb.Message.setRepeatedWrapperField(this, 1, value);
  }


  /**
   * @param {!InternalPbisStat=} opt_value
   * @param {number=} opt_index
   * @return {!InternalPbisStat}
   */
  addStats(opt_value?: InternalPbisStat, opt_index?: number): InternalPbisStat {
    return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, InternalPbisStat, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearStatsList() {
    this.setStatsList([]);
  }


}
export namespace InternalGetConsequenceTopTypesRequest {
  export interface AsObject {
    mingaId: number;
    startDate: string;
    endDate: string;
    issuedByList: number[];
    issuedToList: number[];
    consequenceTypeList: number[];
    gradeList: string[];
    offset: number;
    limit: number;
  }
}
export class InternalGetConsequenceTopTypesRequest extends jspb.Message {
  static readonly displayName = "InternalGetConsequenceTopTypesRequest";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [4,5,6,7];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, InternalGetConsequenceTopTypesRequest.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): InternalGetConsequenceTopTypesRequest.AsObject {
    return InternalGetConsequenceTopTypesRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!InternalGetConsequenceTopTypesRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: InternalGetConsequenceTopTypesRequest) {
    var f: any;
    var obj: any = {
      mingaId: jspb.Message.getFieldWithDefault(msg, 1, 0),
      startDate: jspb.Message.getFieldWithDefault(msg, 2, ""),
      endDate: jspb.Message.getFieldWithDefault(msg, 3, ""),
      issuedByList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
      issuedToList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f,
      consequenceTypeList: (f = jspb.Message.getRepeatedField(msg, 6)) == null ? undefined : f,
      gradeList: (f = jspb.Message.getRepeatedField(msg, 7)) == null ? undefined : f,
      offset: jspb.Message.getFieldWithDefault(msg, 8, 0),
      limit: jspb.Message.getFieldWithDefault(msg, 9, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!InternalGetConsequenceTopTypesRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new InternalGetConsequenceTopTypesRequest;
    return InternalGetConsequenceTopTypesRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!InternalGetConsequenceTopTypesRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!InternalGetConsequenceTopTypesRequest}
   */
  static deserializeBinaryFromReader(msg: InternalGetConsequenceTopTypesRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {number} */ (reader.readInt32());
        msg.setMingaId(value1);
        break;
      case 2:
        var value2 = /** @type {string} */ (reader.readString());
        msg.setStartDate(value2);
        break;
      case 3:
        var value3 = /** @type {string} */ (reader.readString());
        msg.setEndDate(value3);
        break;
      case 4:
        var value4 = /** @type {!Array<number>} */ (reader.readPackedInt32());
        msg.setIssuedByList(value4);
        break;
      case 5:
        var value5 = /** @type {!Array<number>} */ (reader.readPackedInt32());
        msg.setIssuedToList(value5);
        break;
      case 6:
        var value6 = /** @type {!Array<number>} */ (reader.readPackedInt32());
        msg.setConsequenceTypeList(value6);
        break;
      case 7:
        var value7 = /** @type {string} */ (reader.readString());
        msg.addGrade(value7);
        break;
      case 8:
        var value8 = /** @type {number} */ (reader.readInt32());
        msg.setOffset(value8);
        break;
      case 9:
        var value9 = /** @type {number} */ (reader.readInt32());
        msg.setLimit(value9);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!InternalGetConsequenceTopTypesRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: InternalGetConsequenceTopTypesRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getMingaId();
    if (f !== 0) {
      writer.writeInt32(
        1,
        f
      );
    }
    f = message.getStartDate();
    if (f.length > 0) {
      writer.writeString(
        2,
        f
      );
    }
    f = message.getEndDate();
    if (f.length > 0) {
      writer.writeString(
        3,
        f
      );
    }
    f = message.getIssuedByList();
    if (f.length > 0) {
      writer.writePackedInt32(
        4,
        f
      );
    }
    f = message.getIssuedToList();
    if (f.length > 0) {
      writer.writePackedInt32(
        5,
        f
      );
    }
    f = message.getConsequenceTypeList();
    if (f.length > 0) {
      writer.writePackedInt32(
        6,
        f
      );
    }
    f = message.getGradeList();
    if (f.length > 0) {
      writer.writeRepeatedString(
        7,
        f
      );
    }
    f = message.getOffset();
    if (f !== 0) {
      writer.writeInt32(
        8,
        f
      );
    }
    f = message.getLimit();
    if (f !== 0) {
      writer.writeInt32(
        9,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    InternalGetConsequenceTopTypesRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional int32 minga_id = 1;
   * @return {number}
   */
  getMingaId(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
  };


  /** @param {number} value */
  setMingaId(value: number) {
    jspb.Message.setProto3IntField(this, 1, value);
  }


  /**
   * optional string start_date = 2;
   * @return {string}
   */
  getStartDate(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
  };


  /** @param {string} value */
  setStartDate(value: string) {
    jspb.Message.setProto3StringField(this, 2, value);
  }


  /**
   * optional string end_date = 3;
   * @return {string}
   */
  getEndDate(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
  };


  /** @param {string} value */
  setEndDate(value: string) {
    jspb.Message.setProto3StringField(this, 3, value);
  }


  /**
   * repeated int32 issued_by = 4;
   * @return {!Array<number>}
   */
  getIssuedByList(): number[] {
    return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 4));
  };


  /** @param {!Array<number>} value */
  setIssuedByList(value: number[]) {
    jspb.Message.setField(this, 4, value || []);
  }


  /**
   * @param {number} value
   * @param {number=} opt_index
   */
  addIssuedBy(value: number, opt_index?: number): void {
    jspb.Message.addToRepeatedField(this, 4, value, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearIssuedByList() {
    this.setIssuedByList([]);
  }


  /**
   * repeated int32 issued_to = 5;
   * @return {!Array<number>}
   */
  getIssuedToList(): number[] {
    return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 5));
  };


  /** @param {!Array<number>} value */
  setIssuedToList(value: number[]) {
    jspb.Message.setField(this, 5, value || []);
  }


  /**
   * @param {number} value
   * @param {number=} opt_index
   */
  addIssuedTo(value: number, opt_index?: number): void {
    jspb.Message.addToRepeatedField(this, 5, value, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearIssuedToList() {
    this.setIssuedToList([]);
  }


  /**
   * repeated int32 consequence_type = 6;
   * @return {!Array<number>}
   */
  getConsequenceTypeList(): number[] {
    return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 6));
  };


  /** @param {!Array<number>} value */
  setConsequenceTypeList(value: number[]) {
    jspb.Message.setField(this, 6, value || []);
  }


  /**
   * @param {number} value
   * @param {number=} opt_index
   */
  addConsequenceType(value: number, opt_index?: number): void {
    jspb.Message.addToRepeatedField(this, 6, value, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearConsequenceTypeList() {
    this.setConsequenceTypeList([]);
  }


  /**
   * repeated string grade = 7;
   * @return {!Array<string>}
   */
  getGradeList(): string[] {
    return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 7));
  };


  /** @param {!Array<string>} value */
  setGradeList(value: string[]) {
    jspb.Message.setField(this, 7, value || []);
  }


  /**
   * @param {string} value
   * @param {number=} opt_index
   */
  addGrade(value: string, opt_index?: number): void {
    jspb.Message.addToRepeatedField(this, 7, value, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearGradeList() {
    this.setGradeList([]);
  }


  /**
   * optional int32 offset = 8;
   * @return {number}
   */
  getOffset(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
  };


  /** @param {number} value */
  setOffset(value: number) {
    jspb.Message.setProto3IntField(this, 8, value);
  }


  /**
   * optional int32 limit = 9;
   * @return {number}
   */
  getLimit(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
  };


  /** @param {number} value */
  setLimit(value: number) {
    jspb.Message.setProto3IntField(this, 9, value);
  }


}
export namespace InternalGetConsequenceTopTypesResponse {
  export interface AsObject {
    statsList: InternalConsequenceStat.AsObject[];
  }
}
export class InternalGetConsequenceTopTypesResponse extends jspb.Message {
  static readonly displayName = "InternalGetConsequenceTopTypesResponse";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [1];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, InternalGetConsequenceTopTypesResponse.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): InternalGetConsequenceTopTypesResponse.AsObject {
    return InternalGetConsequenceTopTypesResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!InternalGetConsequenceTopTypesResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: InternalGetConsequenceTopTypesResponse) {
    var f: any;
    var obj: any = {
      statsList: jspb.Message.toObjectList(msg.getStatsList(),
      InternalConsequenceStat.toObject, includeInstance)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!InternalGetConsequenceTopTypesResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new InternalGetConsequenceTopTypesResponse;
    return InternalGetConsequenceTopTypesResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!InternalGetConsequenceTopTypesResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!InternalGetConsequenceTopTypesResponse}
   */
  static deserializeBinaryFromReader(msg: InternalGetConsequenceTopTypesResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new InternalConsequenceStat;
        reader.readMessage(value1,InternalConsequenceStat.deserializeBinaryFromReader);
        msg.addStats(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!InternalGetConsequenceTopTypesResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: InternalGetConsequenceTopTypesResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getStatsList();
    if (f.length > 0) {
      writer.writeRepeatedMessage(
        1,
        f,
        InternalConsequenceStat.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    InternalGetConsequenceTopTypesResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * repeated InternalConsequenceStat stats = 1;
   * @return {!Array<!InternalConsequenceStat>}
   */
  getStatsList(): InternalConsequenceStat[] {
    return /** @type{!Array<!InternalConsequenceStat>} */ (
      jspb.Message.getRepeatedWrapperField(this, InternalConsequenceStat, 1));
  }


  /** @param {!Array<!InternalConsequenceStat>} value */
  setStatsList(value?: InternalConsequenceStat[]) {
    jspb.Message.setRepeatedWrapperField(this, 1, value);
  }


  /**
   * @param {!InternalConsequenceStat=} opt_value
   * @param {number=} opt_index
   * @return {!InternalConsequenceStat}
   */
  addStats(opt_value?: InternalConsequenceStat, opt_index?: number): InternalConsequenceStat {
    return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, InternalConsequenceStat, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearStatsList() {
    this.setStatsList([]);
  }


}
export namespace InternalGetConsequenceTopUsersRequest {
  export interface AsObject {
    mingaId: number;
    startDate: string;
    endDate: string;
    issuedByList: number[];
    issuedToList: number[];
    consequenceTypeList: number[];
    gradeList: string[];
    offset: number;
    limit: number;
  }
}
export class InternalGetConsequenceTopUsersRequest extends jspb.Message {
  static readonly displayName = "InternalGetConsequenceTopUsersRequest";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [4,5,6,7];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, InternalGetConsequenceTopUsersRequest.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): InternalGetConsequenceTopUsersRequest.AsObject {
    return InternalGetConsequenceTopUsersRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!InternalGetConsequenceTopUsersRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: InternalGetConsequenceTopUsersRequest) {
    var f: any;
    var obj: any = {
      mingaId: jspb.Message.getFieldWithDefault(msg, 1, 0),
      startDate: jspb.Message.getFieldWithDefault(msg, 2, ""),
      endDate: jspb.Message.getFieldWithDefault(msg, 3, ""),
      issuedByList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
      issuedToList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f,
      consequenceTypeList: (f = jspb.Message.getRepeatedField(msg, 6)) == null ? undefined : f,
      gradeList: (f = jspb.Message.getRepeatedField(msg, 7)) == null ? undefined : f,
      offset: jspb.Message.getFieldWithDefault(msg, 8, 0),
      limit: jspb.Message.getFieldWithDefault(msg, 9, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!InternalGetConsequenceTopUsersRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new InternalGetConsequenceTopUsersRequest;
    return InternalGetConsequenceTopUsersRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!InternalGetConsequenceTopUsersRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!InternalGetConsequenceTopUsersRequest}
   */
  static deserializeBinaryFromReader(msg: InternalGetConsequenceTopUsersRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {number} */ (reader.readInt32());
        msg.setMingaId(value1);
        break;
      case 2:
        var value2 = /** @type {string} */ (reader.readString());
        msg.setStartDate(value2);
        break;
      case 3:
        var value3 = /** @type {string} */ (reader.readString());
        msg.setEndDate(value3);
        break;
      case 4:
        var value4 = /** @type {!Array<number>} */ (reader.readPackedInt32());
        msg.setIssuedByList(value4);
        break;
      case 5:
        var value5 = /** @type {!Array<number>} */ (reader.readPackedInt32());
        msg.setIssuedToList(value5);
        break;
      case 6:
        var value6 = /** @type {!Array<number>} */ (reader.readPackedInt32());
        msg.setConsequenceTypeList(value6);
        break;
      case 7:
        var value7 = /** @type {string} */ (reader.readString());
        msg.addGrade(value7);
        break;
      case 8:
        var value8 = /** @type {number} */ (reader.readInt32());
        msg.setOffset(value8);
        break;
      case 9:
        var value9 = /** @type {number} */ (reader.readInt32());
        msg.setLimit(value9);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!InternalGetConsequenceTopUsersRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: InternalGetConsequenceTopUsersRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getMingaId();
    if (f !== 0) {
      writer.writeInt32(
        1,
        f
      );
    }
    f = message.getStartDate();
    if (f.length > 0) {
      writer.writeString(
        2,
        f
      );
    }
    f = message.getEndDate();
    if (f.length > 0) {
      writer.writeString(
        3,
        f
      );
    }
    f = message.getIssuedByList();
    if (f.length > 0) {
      writer.writePackedInt32(
        4,
        f
      );
    }
    f = message.getIssuedToList();
    if (f.length > 0) {
      writer.writePackedInt32(
        5,
        f
      );
    }
    f = message.getConsequenceTypeList();
    if (f.length > 0) {
      writer.writePackedInt32(
        6,
        f
      );
    }
    f = message.getGradeList();
    if (f.length > 0) {
      writer.writeRepeatedString(
        7,
        f
      );
    }
    f = message.getOffset();
    if (f !== 0) {
      writer.writeInt32(
        8,
        f
      );
    }
    f = message.getLimit();
    if (f !== 0) {
      writer.writeInt32(
        9,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    InternalGetConsequenceTopUsersRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional int32 minga_id = 1;
   * @return {number}
   */
  getMingaId(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
  };


  /** @param {number} value */
  setMingaId(value: number) {
    jspb.Message.setProto3IntField(this, 1, value);
  }


  /**
   * optional string start_date = 2;
   * @return {string}
   */
  getStartDate(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
  };


  /** @param {string} value */
  setStartDate(value: string) {
    jspb.Message.setProto3StringField(this, 2, value);
  }


  /**
   * optional string end_date = 3;
   * @return {string}
   */
  getEndDate(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
  };


  /** @param {string} value */
  setEndDate(value: string) {
    jspb.Message.setProto3StringField(this, 3, value);
  }


  /**
   * repeated int32 issued_by = 4;
   * @return {!Array<number>}
   */
  getIssuedByList(): number[] {
    return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 4));
  };


  /** @param {!Array<number>} value */
  setIssuedByList(value: number[]) {
    jspb.Message.setField(this, 4, value || []);
  }


  /**
   * @param {number} value
   * @param {number=} opt_index
   */
  addIssuedBy(value: number, opt_index?: number): void {
    jspb.Message.addToRepeatedField(this, 4, value, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearIssuedByList() {
    this.setIssuedByList([]);
  }


  /**
   * repeated int32 issued_to = 5;
   * @return {!Array<number>}
   */
  getIssuedToList(): number[] {
    return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 5));
  };


  /** @param {!Array<number>} value */
  setIssuedToList(value: number[]) {
    jspb.Message.setField(this, 5, value || []);
  }


  /**
   * @param {number} value
   * @param {number=} opt_index
   */
  addIssuedTo(value: number, opt_index?: number): void {
    jspb.Message.addToRepeatedField(this, 5, value, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearIssuedToList() {
    this.setIssuedToList([]);
  }


  /**
   * repeated int32 consequence_type = 6;
   * @return {!Array<number>}
   */
  getConsequenceTypeList(): number[] {
    return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 6));
  };


  /** @param {!Array<number>} value */
  setConsequenceTypeList(value: number[]) {
    jspb.Message.setField(this, 6, value || []);
  }


  /**
   * @param {number} value
   * @param {number=} opt_index
   */
  addConsequenceType(value: number, opt_index?: number): void {
    jspb.Message.addToRepeatedField(this, 6, value, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearConsequenceTypeList() {
    this.setConsequenceTypeList([]);
  }


  /**
   * repeated string grade = 7;
   * @return {!Array<string>}
   */
  getGradeList(): string[] {
    return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 7));
  };


  /** @param {!Array<string>} value */
  setGradeList(value: string[]) {
    jspb.Message.setField(this, 7, value || []);
  }


  /**
   * @param {string} value
   * @param {number=} opt_index
   */
  addGrade(value: string, opt_index?: number): void {
    jspb.Message.addToRepeatedField(this, 7, value, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearGradeList() {
    this.setGradeList([]);
  }


  /**
   * optional int32 offset = 8;
   * @return {number}
   */
  getOffset(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
  };


  /** @param {number} value */
  setOffset(value: number) {
    jspb.Message.setProto3IntField(this, 8, value);
  }


  /**
   * optional int32 limit = 9;
   * @return {number}
   */
  getLimit(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
  };


  /** @param {number} value */
  setLimit(value: number) {
    jspb.Message.setProto3IntField(this, 9, value);
  }


}
export namespace InternalGetConsequenceTopUsersResponse {
  export interface AsObject {
    statsList: InternalConsequenceStat.AsObject[];
  }
}
export class InternalGetConsequenceTopUsersResponse extends jspb.Message {
  static readonly displayName = "InternalGetConsequenceTopUsersResponse";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [1];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, InternalGetConsequenceTopUsersResponse.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): InternalGetConsequenceTopUsersResponse.AsObject {
    return InternalGetConsequenceTopUsersResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!InternalGetConsequenceTopUsersResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: InternalGetConsequenceTopUsersResponse) {
    var f: any;
    var obj: any = {
      statsList: jspb.Message.toObjectList(msg.getStatsList(),
      InternalConsequenceStat.toObject, includeInstance)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!InternalGetConsequenceTopUsersResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new InternalGetConsequenceTopUsersResponse;
    return InternalGetConsequenceTopUsersResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!InternalGetConsequenceTopUsersResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!InternalGetConsequenceTopUsersResponse}
   */
  static deserializeBinaryFromReader(msg: InternalGetConsequenceTopUsersResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new InternalConsequenceStat;
        reader.readMessage(value1,InternalConsequenceStat.deserializeBinaryFromReader);
        msg.addStats(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!InternalGetConsequenceTopUsersResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: InternalGetConsequenceTopUsersResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getStatsList();
    if (f.length > 0) {
      writer.writeRepeatedMessage(
        1,
        f,
        InternalConsequenceStat.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    InternalGetConsequenceTopUsersResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * repeated InternalConsequenceStat stats = 1;
   * @return {!Array<!InternalConsequenceStat>}
   */
  getStatsList(): InternalConsequenceStat[] {
    return /** @type{!Array<!InternalConsequenceStat>} */ (
      jspb.Message.getRepeatedWrapperField(this, InternalConsequenceStat, 1));
  }


  /** @param {!Array<!InternalConsequenceStat>} value */
  setStatsList(value?: InternalConsequenceStat[]) {
    jspb.Message.setRepeatedWrapperField(this, 1, value);
  }


  /**
   * @param {!InternalConsequenceStat=} opt_value
   * @param {number=} opt_index
   * @return {!InternalConsequenceStat}
   */
  addStats(opt_value?: InternalConsequenceStat, opt_index?: number): InternalConsequenceStat {
    return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, InternalConsequenceStat, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearStatsList() {
    this.setStatsList([]);
  }


}
