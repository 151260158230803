import { Injectable } from '@angular/core';
import {
  ActivatedRoute,
  NavigationEnd,
  NavigationStart,
  Router,
} from '@angular/router';

import { BehaviorSubject, Observable, combineLatest, of } from 'rxjs';
import { filter, map, startWith, switchMap } from 'rxjs/operators';

import { AppConfigService } from '@app/src/app/minimal/services/AppConfig';
import { PermissionsService } from '@app/src/app/permissions';
import { MingaSettingsService } from '@app/src/app/store/Minga/services';
import { MingaAppMainRoute, MingaAppRouteData } from '@app/src/app/types';

import { AccessRestrictionsValidator } from '@shared/utils/access-restrictions-validator';

import { NavigationPermissionService } from './navigation-permission.service';
import { AppRouteLevel } from './navigation.service.constants';
import {
  PrimaryNavigationRoute,
  SecondaryNavigationRoute,
} from './navigation.service.types';
import { mapChildren } from './navigation.service.utils';

@Injectable({ providedIn: 'root' })
export class NavigationService {
  // utils

  /** Route access checker */
  private readonly _accessRestrictionsValidator =
    new AccessRestrictionsValidator(
      this._permissions,
      this._settings,
      this._navPermissions,
      this._appConfig,
    );

  public readonly started$ = this.router.events.pipe(
    startWith(new NavigationStart(0, '')),
    filter(event => event instanceof NavigationStart),
  );

  public readonly ended$ = this.router.events.pipe(
    startWith(new NavigationEnd(0, '', '')),
    filter(event => event instanceof NavigationEnd),
  );

  public readonly currentRoute$ = this.ended$.pipe(
    map(e => {
      let currentRoute = this.router.routerState.root;
      while (currentRoute.firstChild) currentRoute = currentRoute.firstChild;
      return currentRoute;
    }),
  );

  /** Route Config */
  public readonly currentRouteConfig$ = this.currentRoute$.pipe(
    map(({ routeConfig = null }) => routeConfig),
  );
  public readonly routeConfig$ = this._getRouteConfig$();

  public readonly primaryTitle$ = this.routeConfig$.pipe(
    map(route => {
      return route?.title ?? '';
    }),
  );

  public readonly secondaryTitle$ = this.currentRoute$.pipe(
    map(route => {
      return (route.routeConfig?.data?.title as string) ?? '';
    }),
  );

  public readonly isDetailView$ = this.currentRoute$.pipe(
    switchMap(route => {
      let parent = route.parent;
      let detailParent: ActivatedRoute = null;
      while (parent) {
        if (parent.routeConfig?.data?.isDetailView) {
          detailParent = parent;
          break;
        }
        parent = parent.parent;
      }

      if (!detailParent) {
        return of({
          route,
          detailId: null,
        });
      }

      return detailParent.params.pipe(
        map(parentParams => {
          const id = Object.values(parentParams)?.[0];
          return {
            route: detailParent,
            detailId: id,
          };
        }),
      );
    }),
    map(data => {
      const { route, detailId } = data;

      if (!detailId) return null;

      const url = this.router.url;
      const primary = route.routeConfig;

      const path = url.includes(detailId)
        ? url.substring(0, url.indexOf(detailId) + detailId.length)
        : url;

      return {
        path,
        children: mapChildren(primary?.children ?? [], [path]),
        detailId,
        data: { ...(route.routeConfig?.data || {}) },
      } as {
        path: string;
        children: ({ path: string } & MingaAppRouteData)[];
        detailId: string;
      } & MingaAppMainRoute['data'];
    }),
  );

  public headerTitleType$: Observable<'title' | 'breadcrumb'> =
    this.isDetailView$.pipe(
      map(isDetailView => {
        return isDetailView ? 'breadcrumb' : 'title';
      }),
    );

  private readonly _primaryNavigationRoutesSubject = new BehaviorSubject<
    PrimaryNavigationRoute[]
  >([]);
  public readonly primaryNavigationRoutes$ =
    this._primaryNavigationRoutesSubject.asObservable();

  public readonly secondaryNavigationRoutes$ =
    this._getSecondaryNavigationRoutes$();

  /** Service constructor */
  constructor(
    public route: ActivatedRoute,
    public router: Router,
    private _permissions: PermissionsService,
    private _settings: MingaSettingsService,
    private _navPermissions: NavigationPermissionService,
    private _appConfig: AppConfigService,
  ) {}

  public relativeNavigate(path: string) {
    this.router.navigate([path], { relativeTo: this.route });
  }

  public listPrimaryNavigationRoutes() {
    const routes = this.router.config.reduce(
      (items, route: MingaAppMainRoute) => {
        if (!route.data?.navigationVisibility) return items;
        items.push({
          canAccess$: this._accessRestrictionsValidator.observeAccess$(
            route.data?.accessRestrictions,
          ),
          path: route.path,
          title: route.data?.title ?? route.path,
          description: route.data?.description ?? '',
          icon: route.data?.icon?.default ?? undefined,
          iconActive: route.data?.icon?.active ?? undefined,
          hasPromotionalModal: route.data?.hasPromotionalModal ?? false,
        });
        return items;
      },
      [] as PrimaryNavigationRoute[],
    );
    this._primaryNavigationRoutesSubject.next(routes);
  }

  private _getRouteConfig$() {
    return this.currentRoute$.pipe(
      map(({ pathFromRoot }) => {
        const primary = pathFromRoot[AppRouteLevel.PRIMARY]?.routeConfig;
        const secondary = pathFromRoot[AppRouteLevel.SECONDARY]?.routeConfig;
        let primaryChildren = primary?.children ?? [];
        if (
          primaryChildren.length === 0 &&
          secondary &&
          secondary.path === ''
        ) {
          primaryChildren = secondary?.children ?? [];
        }
        return {
          path: primary?.path,
          ...(primary?.data || {}),
          children: mapChildren(primaryChildren ?? [], [primary?.path]),
        } as {
          path: string;
          children: ({ path: string } & MingaAppRouteData)[];
        } & MingaAppMainRoute['data'];
      }),
    );
  }

  private _getSecondaryNavigationRoutes$() {
    return combineLatest([this.routeConfig$, this.isDetailView$]).pipe(
      map(([config, isDetailView]) => {
        const source = isDetailView ?? config;
        const filterItem = r => r?.navigationVisibility;

        return (source.children || [])
          .filter(filterItem)
          .reduce((acc, route) => {
            const adapter = (r: typeof route): SecondaryNavigationRoute => {
              const children = (r as any)?.children
                ?.filter(filterItem)
                .map(adapter);

              return {
                canAccess$: this._accessRestrictionsValidator.observeAccess$(
                  r?.accessRestrictions,
                  source?.detailId,
                ),
                path: r.path,
                title: r.title || r.path,
                isBeta: r.isBeta || false,
                description: r.description || r.title || r.path,
                children: children.length ? children : undefined,
                exactPathMatching: !r.path.includes('/'),
              };
            };
            acc.push(adapter(route));
            return acc;
          }, [] as SecondaryNavigationRoute[]);
      }),
    );
  }
}
