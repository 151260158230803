import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';

import { MingaPermission } from 'libs/domain';
import {
  eventCategoryColor,
  EventCategoryEnum,
  eventCategoryPrettyText,
} from 'libs/shared';
import { Observable } from 'rxjs';
import { $enum } from 'ts-enum-util';

import { CalendarScheduleView } from '@app/src/app/components/CalendarSchedule/types';
import { Group } from '@app/src/app/groups/models';
import { GroupsFacadeService } from '@app/src/app/groups/services';
import { PermissionsService } from '@app/src/app/permissions';
import { IEventCalendarSettings } from '@app/src/app/services/EventCalendar';

@Component({
  selector: 'mg-calendar-schedule-options',
  templateUrl: './CalendarScheduleOptions.component.html',
  styleUrls: ['./CalendarScheduleOptions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CalendarScheduleOptionsComponent implements OnInit {
  groups$: Observable<Group[]>;
  eventCategoryKeys: string[] = Object.keys(EventCategoryEnum);
  eventCategory: EventCategoryEnum;

  @Input()
  calendarView: CalendarScheduleView = 'week';

  @Output()
  readonly calendarViewChange: EventEmitter<CalendarScheduleView>;

  @Input()
  includeWeekends: boolean = true;

  @Output()
  readonly includeWeekendsChange: EventEmitter<boolean>;

  @Input()
  groupHashes: string[] = [];

  @Output()
  readonly groupHashesChange: EventEmitter<string[]>;

  @Output()
  readonly eventCategoryChange: EventEmitter<string>;

  @Input()
  showCalendarSettings: boolean = true;

  @Input()
  showPastEvents: boolean = false;

  @Input()
  selectedCategory: string = '';

  @Output()
  readonly showPastEventsChange: EventEmitter<boolean>;

  /**
   * If this is given, then no group hash options will be displayed
   */
  @Input()
  exclusiveGroupHashes: string[] = [];

  @Output()
  submit: EventEmitter<CalendarScheduleOptionsComponent>;

  constructor(
    private groupsFacade: GroupsFacadeService,
    private permissions: PermissionsService,
  ) {
    this.calendarViewChange = new EventEmitter();
    this.includeWeekendsChange = new EventEmitter();
    this.groupHashesChange = new EventEmitter();
    this.eventCategoryChange = new EventEmitter();
    this.showPastEventsChange = new EventEmitter();
    this.submit = new EventEmitter();
    this.groups$ = this.groupsFacade.myGroups$;
  }

  ngOnInit() {
    if (!this.showPastEvents) {
      this.showPastEvents = false;
    }
    if (!this.calendarView) {
      this.calendarView = 'week';
    }
    if (this.permissions.hasPermission(MingaPermission.CONTENT_GROUP_VIEWALL)) {
      this.groups$ = this.groupsFacade.groups$;
    } else {
      this.groups$ = this.groupsFacade.myGroups$;
    }
  }

  onGroupSelectChange(settings: IEventCalendarSettings, groupHash: string) {
    if (groupHash) {
      settings.groupHashes = [groupHash];
    } else {
      delete settings.groupHashes;
    }
  }

  submitClick() {
    this.submit.emit(this);
  }

  public onPastEventsChanged(val: boolean) {
    this.showPastEvents = val;

    this.showPastEventsChange.emit(this.showPastEvents);
  }

  public onCalendarViewChanged(val: CalendarScheduleView) {
    this.calendarView = val;

    this.calendarViewChange.emit(val);
  }

  private _getEventCategoryKey(categoryKey: string): EventCategoryEnum {
    return $enum(EventCategoryEnum).asValueOrDefault(
      categoryKey,
      EventCategoryEnum.OTHER,
    );
  }

  getEventCategoryColor(categoryKey: string) {
    const key = this._getEventCategoryKey(categoryKey);
    return eventCategoryColor(key);
  }

  getEventCategoryPrettyString(categoryKey: string) {
    const key = this._getEventCategoryKey(categoryKey);
    return eventCategoryPrettyText(key);
  }
}
