import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { FileListModule } from '@app/src/app/components/FileList';
import { FileModule } from '@app/src/app/file';
import { MgIconModule } from '@app/src/app/icon';
import { PermissionsModule } from '@app/src/app/permissions';

import { FileListContainerComponent } from './FileListContainer.component';

@NgModule({
  imports: [
    // Minga dependencies
    FileListModule,
    FileModule,
    PermissionsModule,
    MgIconModule,

    // External dependencies
    CommonModule,
  ],
  declarations: [FileListContainerComponent],
  exports: [FileListContainerComponent],
})
export class FileListContainerModule {}
