import { Location } from '@angular/common';
import { Injectable, OnDestroy } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';

import * as day from 'dayjs';
import * as utc from 'dayjs/plugin/utc';
import { RealtimeEventType, RealtimeHallPassMeta } from 'libs/domain';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { AuthInfoService } from '@app/src/app/minimal/services/AuthInfo';
import { RootService } from '@app/src/app/minimal/services/RootService';
import { HallPassService } from '@app/src/app/services/HallPass';

import { HpmRoutes } from '@modules/hallpass-manager';
import { SelectionAssignerRoutes } from '@modules/selection-assigner';

import {
  SystemAlertCloseEvents,
  SystemAlertModalComponent,
  SystemAlertModalService,
  SystemAlertModalType,
} from '@shared/components/system-alert-modal';
import { SystemAlertSnackBarService } from '@shared/components/system-alert-snackbar';
import { ViewIdService } from '@shared/components/view-id/services/view-id.service';
import { MingaAppColor } from '@shared/constants';
import {
  getSecondsTillPendingPassExpiry,
  isRequesterStaff,
} from '@shared/utils/hall-pass';

import { RealtimeEvents } from '../RealtimeEvents';

day.extend(utc);

export type NormalizedMsg = {
  type: RealtimeEventType;
  title: string;
  body?: string;
  hallPass: RealtimeHallPassMeta & {
    secondsToExpire?: number;
    endDateIsoString?: string;
  };
};

type NormalizedWithUserInput = NormalizedMsg & { userInput?: string };

type ExistingDialog = {
  ref: MatDialogRef<SystemAlertModalComponent, any>;
  dismissOnNewNotification?: boolean;
};

type BtnStringOrFn = string | ((msg: NormalizedMsg) => string);

@Injectable({ providedIn: 'root' })
export class HallpassEvents implements OnDestroy {
  private readonly _destroyedSubj = new ReplaySubject<void>(1);
  private _events: RealtimeEventType[] = [
    RealtimeEventType.HALL_PASS_NOTIFY,
    RealtimeEventType.HALL_PASS_GRANTED,
    RealtimeEventType.HALL_PASS_APPROVAL_REQUEST,
    RealtimeEventType.HALL_PASS_APPROVAL_CANCEL,
    RealtimeEventType.HALL_PASS_APPROVED,
    RealtimeEventType.HALL_PASS_DENIED,
    RealtimeEventType.HALL_PASS_ENDED,
  ];
  private _existingDialogs: Record<
    string,
    {
      dialogs: ExistingDialog[];
    }
  > = {};
  private _hallEventHandlers: Record<
    any,
    {
      modalType: SystemAlertModalType;
      icon?: string;
      iconColor?: MingaAppColor;
      closeBtn?: BtnStringOrFn;
      closeAction?: (msg: NormalizedWithUserInput) => Promise<any>;
      confirmActionBtn?: BtnStringOrFn;
      confirmAction?: (msg: NormalizedWithUserInput) => Promise<any>;
      secondaryConfirmAction?: (msg: NormalizedWithUserInput) => Promise<any>;
      dismissOnNewNotification?: boolean; // we may want to hide this notification if a new one comes in
      onNotificationClicked?: (msg: NormalizedMsg) => void; // if use clicks on notification we may want to handle differently
      onExpired?: (msg: NormalizedMsg, modalRef) => void; // if we want to expire notification after X seconds
      detailedMessage?: (msg: NormalizedMsg) => [string, string][];
      showInput?: (msg: NormalizedMsg) => boolean;
    }
  > = {
    // teacher
    [RealtimeEventType.HALL_PASS_APPROVAL_REQUEST]: {
      modalType: SystemAlertModalType.DEFAULT,
      icon: 'mg-hallpass-menu',
      dismissOnNewNotification: true,
      confirmActionBtn: 'Approve',
      closeBtn: 'Deny',
      detailedMessage: (msg: NormalizedMsg) => {
        return this._getBulletList(msg);
      },
      confirmAction: async (msg: NormalizedWithUserInput) => {
        try {
          const hallPassId = msg.hallPass.id;

          if (!hallPassId) throw new Error('No hallpass id found');

          await this._rootService.addLoadingPromise(
            this._hpService.approveHallPass(hallPassId, msg?.userInput),
          );
          this._systemSnackBar.open({
            type: 'success',
            message: 'Hall pass successfully approved',
          });
        } catch (e) {
          this._systemSnackBar.open({
            type: 'error',
            message: `There was an error approving hall pass. ${e.message}`,
          });
        }
      },
      closeAction: async (msg: NormalizedWithUserInput) => {
        try {
          const hallPassId = msg?.hallPass?.id;

          if (!hallPassId) throw new Error('No hallpass id found');
          await this._rootService.addLoadingPromise(
            this._hpService.cancelHallPass(hallPassId, msg?.userInput, true),
          );
          this._systemSnackBar.open({
            type: 'success',
            message: 'Hall pass denied successfully',
          });
        } catch (e) {
          this._systemSnackBar.open({
            type: 'error',
            message: `There was an error approving hall pass. ${e.message}`,
          });
        }
      },
      onNotificationClicked: (msg: NormalizedMsg) => {
        this._router.navigate([HpmRoutes.ROOT]);
      },
      onExpired: (
        msg: NormalizedMsg,
        modalRef: MatDialogRef<SystemAlertModalComponent, any>,
      ) => {
        modalRef.close();
        this._systemAlertModal.open({
          modalType: SystemAlertModalType.ERROR,
          heading: 'Your request for a hall pass has timed out',
          detailedMessage: this._getBulletList(msg),
          closeBtn: 'Close',
        });
      },
      showInput: (msg: NormalizedMsg) => {
        return isRequesterStaff(msg.hallPass);
      },
    },
    // student
    [RealtimeEventType.HALL_PASS_APPROVED]: {
      modalType: SystemAlertModalType.SUCCESS,
      closeAction: async (msg: NormalizedWithUserInput) => {
        const route = this._router.url;
        if (
          route.includes(
            `(modal:${SelectionAssignerRoutes.ROOT}/${SelectionAssignerRoutes.HALL_PASS})`,
          )
        ) {
          this._location.back();
        }
      },
      closeBtn: 'Close',
      confirmAction: async (msg: NormalizedWithUserInput) => {
        this._router.navigate([
          '',
          { outlets: { modal: null, o: [this._viewId.getIdOverlayRoute()] } },
        ]);
      },
      confirmActionBtn: (msg: NormalizedMsg) => {
        if (msg.hallPass.issuedTo.hash !== this._authInfo.authPersonHash) {
          return '';
        }
        return 'View ID';
      },
      detailedMessage: (msg: NormalizedMsg) => {
        return this._getBulletList(msg);
      },
    },
    // student
    [RealtimeEventType.HALL_PASS_DENIED]: {
      modalType: SystemAlertModalType.ERROR,
      closeBtn: 'Close',
      detailedMessage: (msg: NormalizedMsg) => {
        return this._getBulletList(msg);
      },
    },
    // student
    [RealtimeEventType.HALL_PASS_GRANTED]: {
      modalType: SystemAlertModalType.DEFAULT,
      closeBtn: 'Close',
      icon: 'mg-hallpass-menu',
    },
    // teacher
    [RealtimeEventType.HALL_PASS_NOTIFY]: {
      modalType: SystemAlertModalType.DEFAULT,
      closeBtn: 'Close',
      icon: 'mg-hallpass-menu',
    },
    [RealtimeEventType.HALL_PASS_ENDED]: {
      modalType: SystemAlertModalType.SUCCESS,
      closeBtn: 'Close',
      detailedMessage: (msg: NormalizedMsg) => {
        return this._getBulletList(msg);
      },
    },
    // student
    [RealtimeEventType.HALL_PASS_APPROVAL_CANCEL]: {
      modalType: SystemAlertModalType.DEFAULT,
      icon: 'mg-hallpass-menu',
      closeBtn: 'Close',
      detailedMessage: (msg: NormalizedMsg) => {
        return this._getBulletList(msg, 'Cancelled by');
      },
    },
  };

  constructor(
    private _realtimeEvents: RealtimeEvents,
    private _systemAlertModal: SystemAlertModalService,
    private _hpService: HallPassService,
    private _systemSnackBar: SystemAlertSnackBarService,
    private _router: Router,
    private _rootService: RootService,
    private _location: Location,
    private _authInfo: AuthInfoService,
    private _viewId: ViewIdService,
  ) {}

  public setup() {
    this._realtimeEvents
      .observe(this._events)
      .pipe(takeUntil(this._destroyedSubj))
      .subscribe(data => {
        const normalized = this._normalizeData(data);
        this.handleEvent(normalized);
      });
  }

  ngOnDestroy(): void {
    this._destroyedSubj.next();
    this._destroyedSubj.complete();
  }

  public async handleEvent(
    normalized: NormalizedMsg,
  ): Promise<SystemAlertCloseEvents> {
    const handler = this._hallEventHandlers[normalized.type];
    const passId = normalized.hallPass?.id;

    if (!handler) return;

    // we may need to close any dialogs currently open for this pass
    this._closeExistingDialogs(this._existingDialogs[passId]?.dialogs);

    if (!normalized.title) return;

    const expireTimer =
      handler.onExpired &&
      normalized.hallPass?.secondsToExpire &&
      normalized.hallPass.secondsToExpire > 0
        ? normalized.hallPass.secondsToExpire
        : null;

    const detailedMessage = handler.detailedMessage
      ? handler.detailedMessage(normalized)
      : null;

    const showInput = handler.showInput ? handler.showInput(normalized) : false;

    const modalRef = await this._systemAlertModal.open({
      ...handler,
      heading: normalized.title,
      message: normalized.body,
      detailedMessage,
      ...this._getBtnLabels(handler, normalized),
      input: showInput
        ? {
            placeholder: 'Note to requester (optional)',
          }
        : undefined,
      ...(expireTimer
        ? {
            timer: expireTimer,
            onTimerEnd: () => handler.onExpired(normalized, modalRef),
          }
        : {}),
    });

    this.setDialogRef(
      {
        ref: modalRef,
        dismissOnNewNotification: handler.dismissOnNewNotification,
      },
      normalized.hallPass?.id,
    );

    const response = await modalRef.afterClosed().toPromise();

    const type = response?.type;
    const userInput = response?.inputValue;

    if (type === SystemAlertCloseEvents.CONFIRM && handler.confirmAction) {
      await handler.confirmAction({ ...normalized, userInput });
    }

    if (type === SystemAlertCloseEvents.CLOSE && handler.closeAction) {
      await handler.closeAction({ ...normalized, userInput });
    }

    if (
      type === SystemAlertCloseEvents.SECONDARY_CONFIRM &&
      handler.secondaryConfirmAction
    ) {
      await handler.secondaryConfirmAction({ ...normalized, userInput });
    }

    return type;
  }

  public setDialogRef(dialog: ExistingDialog, passId: number) {
    if (!passId) return;

    const existingDialogs = this._existingDialogs[passId]?.dialogs || [];
    this._existingDialogs[passId] = {
      dialogs: [...existingDialogs, dialog],
    };
  }

  // Close any existing dialogs related to this pass only
  private _closeExistingDialogs(existingDialogs: ExistingDialog[]) {
    if (!existingDialogs) return;

    existingDialogs
      .filter(dialog => dialog?.ref && dialog?.dismissOnNewNotification)
      .forEach(dialog => {
        dialog.ref.close();
      });
  }

  private _getBtnLabels(handler, normalized) {
    const confirmActionBtn =
      typeof handler.confirmActionBtn === 'function'
        ? handler.confirmActionBtn(normalized)
        : handler.confirmActionBtn;
    const closeBtn =
      typeof handler.closeBtn === 'function'
        ? handler.closeBtn(normalized)
        : handler.closeBtn;

    return {
      confirmActionBtn,
      closeBtn,
    };
  }

  private _getBulletList(
    msg: NormalizedMsg,
    requesterLabel = 'Requested by',
  ): [string, string][] {
    const rows: [string, string][] = [['Hall pass', msg.hallPass.passName]];

    if (msg.hallPass.requestedBy) {
      rows.push([requesterLabel, msg.hallPass.requestedBy.name]);
    }

    const approver =
      msg.hallPass.approvedBy?.name || msg.hallPass.issuedBy.name;
    rows.push(['Approver', approver]);
    if (msg.hallPass.endedBy) {
      rows.push(['Ended by', msg.hallPass.endedBy.name]);
    }
    rows.push(['Student', msg.hallPass.issuedTo.name]);
    return rows;
  }

  private _normalizeData(msg): NormalizedMsg {
    const notification = msg.payload?.notification;

    const metaData = (notification as any).metadata || {};

    return {
      type: msg.type,
      title: notification?.title,
      body: notification?.body,
      hallPass: {
        ...(metaData.hallPass || {}),
        secondsToExpire: metaData.hallPass?.endDateIsoString
          ? getSecondsTillPendingPassExpiry(metaData.hallPass.endDateIsoString)
          : 0,
      },
    };
  }
}
