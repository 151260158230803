<div class="full-width-layout">
  <ng-container *ngTemplateOutlet="mainContentTemplate"></ng-container>
</div>

<ng-template #mainContentTemplate>
  <!-- Header -->
  <ng-container #subpageHeader>
    <ng-container
      *ngIf="media.isMobileView$ | async; else headerTemplate"
      [ngTemplateOutlet]="mobileHeaderTemplate">
    </ng-container>
  </ng-container>

  <!-- Quick Actions -->
  <ng-container *ngTemplateOutlet="quickActionsTemplate"></ng-container>
  <!-- Main Content -->
  <mg-mm-dashboard-table></mg-mm-dashboard-table>
</ng-template>

<!-- Static Header Template -->
<ng-template #staticHeaderTemplate>
  <div fxFlex="nogrow">
    <span class="header-title">
      {{ MESSAGES.PAGE_TITLE }}
    </span>
  </div>
</ng-template>

<!-- People Search Template -->
<ng-template #searchQueryFilterTemplate>
  <div fxFlex.gt-sm="250px">
    <mg-form-text-input
      [label]="MESSAGES.SEARCH_INPUT_LABEL"
      [labelBackground]="'white'"
      [placeholder]="MESSAGES.SEARCH_INPUT_PLACEHOLDER"
      [control]="dashboardFilters.searchQueryControl">
    </mg-form-text-input>
  </div>
</ng-template>

<!-- Partner Filter Template -->
<ng-template #partnerFilterTemplate>
  <div fxFlex.gt-sm="200px">
    <mg-form-select
      placeholder="Partner"
      [labelBackground]="'white'"
      [value]="(dashboardFilters.filters$ | async)?.partner"
      [closeOnSelect]="false"
      [multiple]="true"
      [searchable]="true"
      [isFullWidth]="true"
      [options]="dashboardFilters.partnerOptions$ | async"
      (selectionChanged)="dashboardFilters.setFilter(FILTERS.PARTNER, $event)">
    </mg-form-select>
  </div>
</ng-template>

<!-- Subscription Filter Template -->
<ng-template #subscriptionFilterTemplate>
  <div class="filters-select">
    <mg-form-select
      placeholder="Subscription"
      [labelBackground]="'white'"
      [value]="(dashboardFilters.filters$ | async)?.subscription"
      [closeOnSelect]="false"
      [multiple]="true"
      [searchable]="true"
      [isFullWidth]="true"
      [options]="dashboardFilters.subscriptionOptions$ | async"
      (selectionChanged)="
        dashboardFilters.setFilter(FILTERS.SUBSCRIPTION, $event)
      ">
    </mg-form-select>
  </div>
</ng-template>

<!-- Preset View Template -->
<ng-template #presetViewTemplate>
  <mat-button-toggle-group
    name="presetViewFilter"
    class="filter-toggle-group"
    [value]="dashboardTable.columnPreset"
    (change)="dashboardTable.switchColumnPreset($event.value)">
    <mat-button-toggle
      *ngFor="let option of PRESETS"
      [value]="option.value">
      <mg-text variant="body-sm-secondary">
        {{ option.label }}
      </mg-text>
    </mat-button-toggle>
  </mat-button-toggle-group>
</ng-template>

<!-- Module Filter Template -->
<ng-template #moduleFilterTemplate>
  <div class="filters-select">
    <mg-form-select
      placeholder="Module"
      [labelBackground]="'white'"
      [value]="(dashboardFilters.filters$ | async)?.module"
      [closeOnSelect]="false"
      [multiple]="true"
      [searchable]="true"
      [isFullWidth]="true"
      [options]="dashboardFilters.moduleOptions$ | async"
      (selectionChanged)="dashboardFilters.setFilter(FILTERS.MODULE, $event)">
    </mg-form-select>
  </div>
</ng-template>

<!-- District Filter Template -->
<ng-template #districtFilterTemplate>
  <div fxFlex.gt-sm="200px">
    <mg-form-select
      placeholder="District"
      [labelBackground]="'white'"
      [value]="(dashboardFilters.filters$ | async)?.district"
      [closeOnSelect]="false"
      [multiple]="true"
      [searchable]="true"
      [isFullWidth]="true"
      [options]="dashboardFilters.districtOptions$ | async"
      (selectionChanged)="dashboardFilters.setFilter(FILTERS.DISTRICT, $event)">
    </mg-form-select>
  </div>
</ng-template>

<!-- State Filter Template -->
<ng-template #stateFilterTemplate>
  <div fxFlex.gt-sm="200px">
    <mg-form-select
      placeholder="State"
      [labelBackground]="'white'"
      [value]="(dashboardFilters.filters$ | async)?.state"
      [closeOnSelect]="false"
      [multiple]="true"
      [searchable]="true"
      [isFullWidth]="true"
      [options]="dashboardFilters.stateOptions$ | async"
      (selectionChanged)="dashboardFilters.setFilter(FILTERS.STATE, $event)">
    </mg-form-select>
  </div>
</ng-template>

<!-- Status Filter Template -->
<ng-template #statusFilterTemplate>
  <div fxFlex.gt-sm="200px">
    <mg-form-select
      placeholder="Type"
      [labelBackground]="'white'"
      [value]="(dashboardFilters.filters$ | async)?.status"
      [closeOnSelect]="false"
      [multiple]="true"
      [searchable]="true"
      [isFullWidth]="true"
      [options]="dashboardFilters.statusOptions$ | async"
      (selectionChanged)="dashboardFilters.setFilter(FILTERS.STATUS, $event)">
    </mg-form-select>
  </div>
</ng-template>

<!-- Paused Filter Template -->
<ng-template #pausedFilterTemplate>
  <div fxFlex.gt-sm="200px">
    <mg-form-select
      placeholder="Status"
      [labelBackground]="'white'"
      [value]="(dashboardFilters.filters$ | async)?.paused"
      [closeOnSelect]="false"
      [multiple]="true"
      [searchable]="true"
      [isFullWidth]="true"
      [options]="dashboardFilters.pausedOptions$ | async"
      (selectionChanged)="dashboardFilters.setFilter(FILTERS.PAUSED, $event)">
    </mg-form-select>
  </div>
</ng-template>

<!-- Support Tier Filter Template -->
<ng-template #supportTierFilterTemplate>
  <div fxFlex.gt-sm="200px">
    <mg-form-select
      placeholder="Tier"
      [labelBackground]="'white'"
      [value]="(dashboardFilters.filters$ | async)?.supportTier"
      [closeOnSelect]="false"
      [multiple]="true"
      [searchable]="true"
      [isFullWidth]="true"
      [options]="dashboardFilters.supportTierOptions$ | async"
      (selectionChanged)="
        dashboardFilters.setFilter(FILTERS.SUPPORT_TIER, $event)
      ">
    </mg-form-select>
  </div>
</ng-template>

<!-- Filter Buttons Template -->
<ng-template #filterButtonsTemplate>
  <div
    fxLayoutAlign="start center"
    [class.filters-spacing]="!('SUPERADMIN' | hasPermission)">
    <mg-btn
      [variant]="(media.isMobileView$ | async) ? 'outlined' : 'text'"
      [size]="(media.isMobileView$ | async) ? 'medium' : 'small'"
      [responsive]="true"
      [wide]="(media$ | async) === 'xs'"
      (pressed)="dashboardFilters.clearFilters()">
      {{ MESSAGES.CLEAR_FILTER_BUTTON_LABEL }}
    </mg-btn>
  </div>
</ng-template>

<!-- Add Minga Template -->
<ng-template #addMingaTemplate>
  <div
    class="btn-add-new"
    fxFlex="noshrink"
    fxLayoutAlign="end end">
    <mg-btn
      mat-button
      variant="filled"
      iconSet="minga"
      iconRight="down-toggle"
      [responsive]="true"
      [wide]="(media$ | async) === 'xs'"
      [matMenuTriggerFor]="dropdownMenu">
      {{ MESSAGES.ADD_NEW_BUTTON_LABEL }}
    </mg-btn>
    <mat-menu
      #dropdownMenu="matMenu"
      xPosition="before">
      <button
        mat-menu-item
        (click)="dashboardService.openMingaEditModal()">
        {{ MESSAGES.NEW_MINGA_BUTTON_LABEL }}
      </button>
      <button
        mat-menu-item
        (click)="dashboardService.openMingaPartnerEditModal()">
        {{ MESSAGES.NEW_PARTNER_BUTTON_LABEL }}
      </button>
    </mat-menu>
  </div>
</ng-template>

<!-- Quick Actions Template -->
<ng-template #quickActionsTemplate>
  <div
    fxLayout="row"
    fxLayoutAlign="end center"
    fxLayoutGap="16px"
    class="quick-action-container">
    <div fxFlex="nogrow">
      <mg-btn
        variant="text"
        size="small"
        iconSet="material"
        icon="refresh"
        [disabled]="dashboardService.isLoading$ | async"
        (pressed)="dashboardService.fetchAllData()">
        {{ MESSAGES.BUTTON_LABEL_REFRESH }}
      </mg-btn>
    </div>
    <div
      fxHide.xs
      fxFlex="nogrow">
      <mg-btn
        *ngIf="canDownloadExport"
        mat-button
        variant="text"
        size="small"
        iconSet="minga"
        icon="mg-export"
        (pressed)="export()">
        {{ MESSAGES.BUTTON_LABEL_EXPORT }}
      </mg-btn>
    </div>
    <div
      fxFlex="nogrow"
      [class.is-disabled]="!(this.dashboardService.data$ | async)?.length">
      <mg-btn
        variant="text"
        size="small"
        iconRight="expand_more"
        [matMenuTriggerFor]="menu"
        [disabled]="!(this.dashboardService.data$ | async)?.length">
        {{ MESSAGES.COLUMN_SETTINGS_BUTTON }}
      </mg-btn>
      <mat-menu
        #menu="matMenu"
        xPosition="before">
        <div (click)="$event.stopPropagation()">
          <ng-container *ngTemplateOutlet="toggleColumnsMenuTemplate">
          </ng-container>
        </div>
      </mat-menu>
    </div>
  </div>
</ng-template>

<!-- Toggle Columns Menu Template: 
  @TODO See manager-overlay-reports-subpage for almost duplicate drop down
  toggle code, merge both into a component. -->
<ng-template #toggleColumnsMenuTemplate>
  <div class="toggle-columns-container">
    <ng-container *ngFor="let section of toggleOptions$ | async">
      <div class="toggle-columns-group">
        <mg-text
          variant="body-xs-secondary"
          spacing="2-half">
          {{ section.title }}
        </mg-text>
        <div
          *ngFor="let column of section.options"
          class="toggle-item">
          <mg-form-checkbox
            [checked]="
              dashboardTable.displayedColumn$
                | async
                | includes: column.value[0]
            "
            (changed)="dashboardTable.toggleDisplayedColumn(column.value)">
          </mg-form-checkbox>
          <mg-text
            variant="body-xs-secondary"
            fontWeight="400">
            {{ column.label }}
          </mg-text>
        </div>
      </div>
    </ng-container>
  </div>
</ng-template>

<!-- Desktop Header -->
<ng-template #headerTemplate>
  <div class="filters">
    <ng-container *ngTemplateOutlet="searchQueryFilterTemplate"></ng-container>
    <ng-container
      *ngIf="isSuperAdmin | async"
      [ngTemplateOutlet]="districtFilterTemplate"></ng-container>
    <ng-container
      *ngIf="isSuperAdmin | async"
      [ngTemplateOutlet]="partnerFilterTemplate"></ng-container>

    <ng-container
      *ngIf="isSuperAdmin | async"
      [ngTemplateOutlet]="stateFilterTemplate"></ng-container>
    <ng-container
      *ngIf="isSuperAdmin | async"
      [ngTemplateOutlet]="statusFilterTemplate"></ng-container>
    <ng-container
      *ngIf="isSuperAdmin | async"
      [ngTemplateOutlet]="pausedFilterTemplate"></ng-container>

    <ng-container *ngTemplateOutlet="moduleFilterTemplate"></ng-container>
    <ng-container *ngTemplateOutlet="subscriptionFilterTemplate"></ng-container>
    <ng-container *ngTemplateOutlet="supportTierFilterTemplate"></ng-container>
    <ng-container *ngTemplateOutlet="presetViewTemplate"></ng-container>

    <ng-container *ngTemplateOutlet="filterButtonsTemplate"></ng-container>

    <ng-container
      *ngIf="isSuperAdmin | async"
      [ngTemplateOutlet]="addMingaTemplate"></ng-container>
  </div>
</ng-template>

<!-- Mobile Header -->
<ng-template #mobileHeaderTemplate>
  <mg-collapsible
    [collapseText]="MESSAGES.HIDE_FILTERS"
    [expandText]="MESSAGES.SHOW_FILTERS">
    <div class="filters">
      <ng-container
        *ngTemplateOutlet="searchQueryFilterTemplate"></ng-container>
      <ng-container
        *ngIf="isSuperAdmin | async"
        [ngTemplateOutlet]="districtFilterTemplate"></ng-container>
      <ng-container
        *ngIf="isSuperAdmin | async"
        [ngTemplateOutlet]="partnerFilterTemplate"></ng-container>

      <ng-container
        *ngIf="isSuperAdmin | async"
        [ngTemplateOutlet]="stateFilterTemplate"></ng-container>
      <ng-container
        *ngIf="isSuperAdmin | async"
        [ngTemplateOutlet]="statusFilterTemplate"></ng-container>
      <ng-container
        *ngIf="isSuperAdmin | async"
        [ngTemplateOutlet]="pausedFilterTemplate"></ng-container>

      <ng-container *ngTemplateOutlet="moduleFilterTemplate"></ng-container>
      <ng-container
        *ngTemplateOutlet="subscriptionFilterTemplate"></ng-container>
      <ng-container
        [ngTemplateOutlet]="supportTierFilterTemplate"></ng-container>
      <ng-container *ngTemplateOutlet="presetViewTemplate"></ng-container>

      <ng-container *ngTemplateOutlet="filterButtonsTemplate"></ng-container>

      <ng-container
        *ngIf="isSuperAdmin | async"
        [ngTemplateOutlet]="addMingaTemplate"></ng-container>
    </div>
  </mg-collapsible>
</ng-template>
