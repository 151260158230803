import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';

import { MgButtonModule } from '@app/src/app/button';
import { MgSpinnerModule } from '@app/src/app/spinner';

import { GroupJoinButtonComponent } from './GroupJoinButton.component';

@NgModule({
  imports: [
    // Minga dependencies
    MgButtonModule,
    MgSpinnerModule,

    // External dependencies
    CommonModule,
    FlexLayoutModule,
  ],
  declarations: [GroupJoinButtonComponent],
  exports: [GroupJoinButtonComponent],
})
export class GroupJoinButtonModule {}
