import { Location } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  Optional,
  Inject,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { PersonViewMinimal } from 'libs/generated-grpc-web';
import { from, Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { OnOverlayClose } from '@app/src/app/misc/overlay';
import { ListMembershipService } from '@app/src/app/services/ListMembership';
import { PeopleManagerService } from '@app/src/app/services/PeopleManager';
import { downloadjs } from '@app/src/app/util/downloadjs';
import { IMgStreamFilter } from '@app/src/app/util/stream/stream';

import {
  MODAL_OVERLAY_DATA,
  ModalOverlayPrimaryHeaderBackground,
} from '@shared/components/modal-overlay';

@Component({
  selector: 'mg-view-membership-list-members',
  templateUrl: './ViewMembershipListMembers.component.html',
  styleUrls: ['./ViewMembershipListMembers.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ViewMembershipListMembersComponent
  implements OnInit, OnOverlayClose
{
  modalConfig = {
    headerBg: ModalOverlayPrimaryHeaderBackground.PINK,
  };

  listId: number | null = null;
  isOverlay = true;
  members$: Observable<PersonViewMinimal.AsObject[]> | null = null;

  constructor(
    private peopleMembershipService: ListMembershipService,
    private peopleManagerService: PeopleManagerService,
    private _location: Location,
    private route: ActivatedRoute,
    @Optional() @Inject(MODAL_OVERLAY_DATA) public dialogData: any,
  ) {}

  get streamFilter() {
    const filter: IMgStreamFilter = {};

    return filter;
  }

  ngOnInit(): void {
    // if dialog data is available, we are in a modal.
    if (this.dialogData?.membershipListId && !this.dialogData?.members) {
      this.listId = this.dialogData.membershipListId;
      this.isOverlay = false;
      this.members$ = from(
        this.peopleMembershipService.getMembersOfList(this.listId),
      );
    } else if (this.dialogData?.members) {
      this.listId = 0;
      this.isOverlay = false;
      // if you are doing this override make sure the list you are trying to access
      // has the same permission block as readPeople()
      this.members$ = from(
        this.peopleManagerService.readPeople(
          this.dialogData.members,
          false,
          true,
        ),
      );
    } else {
      this.members$ = this.route.paramMap.pipe(
        switchMap(params => {
          this.listId = parseInt(params.get('id'));
          return this.peopleMembershipService.getMembersOfList(this.listId);
        }),
      );
    }
  }

  onOverlayClose(): boolean | Promise<boolean> {
    this._location.back();
    return true;
  }

  async exportMembershipList() {
    if (!this.listId) return;

    const obs = this.peopleMembershipService.downloadStickerMembershipList(
      this.listId,
    );
    let filename = '';
    let fileData = new Uint8Array(0);

    await new Promise((resolve, reject) => {
      obs.subscribe(
        (chunk: any) => {
          if (chunk.hasChunk()) {
            const chunkData = chunk.getChunk_asU8();
            const newFileData = new Uint8Array(
              fileData.length + chunkData.length,
            );
            newFileData.set(fileData);
            newFileData.set(chunkData, fileData.length);
            fileData = newFileData;
          } else if (chunk.hasFilename()) {
            filename = chunk.getFilename();
          }
        },
        reject,
        resolve as any,
      );
    });

    // @TODO: @types/downloadjs doesn't agree with the typings here
    downloadjs(<any>fileData, filename);
  }
}
