import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { Observable } from 'rxjs';

import { AppConfigService } from '@app/src/app/minimal/services/AppConfig';

/**
 * Displaying the 'current' country. This wraps `<mg-country>` with the app's
 * current country from app config.
 */
@Component({
  selector: 'mg-current-country',
  templateUrl: './CurrentCountry.component.html',
  styleUrls: ['./CurrentCountry.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CurrentCountryComponent {
  @Input()
  hideText: boolean = false;

  country$: Observable<string | null>;

  constructor(appConfigService: AppConfigService) {
    this.country$ = appConfigService.countrySubject.asObservable();
  }
}
