import { Injectable } from '@angular/core';
import { Data, Router } from '@angular/router';

import { ICheckinReason } from 'libs/domain';

import { NavPermissions } from '@app/src/app/minimal/services/NavPermissions';

/**
 * Checkin Flow Service
 */
@Injectable({ providedIn: 'root' })
export class CheckinFlowService {
  private _originPath = '';
  private _reason: ICheckinReason;

  constructor(
    private _router: Router,
    private _navPermissions: NavPermissions,
  ) {}

  goToCheckin(disableOrigin: boolean = false) {
    if (!disableOrigin) {
      this._originPath = this._router.url;
    }
    this._router.navigate(['/checkin', { outlets: { o: null } }]);
  }

  resetData() {
    this._reason = undefined;
  }

  setReason(reason: ICheckinReason): void {
    this._reason = reason;
  }

  getReason(): ICheckinReason {
    return this._reason;
  }

  returnFromCheckinRoute() {
    if (this._originPath === this._router.url) {
      this._router.navigateByUrl('/');
    } else {
      this._router.navigateByUrl(this._originPath || '/');
    }
  }

  /**
   * Checks if user has route permissions for a given tab.
   *
   * @param permissions data.permissions of student id route tab data
   */

  hasTabRoutePermissions(permissions: Data) {
    return this._navPermissions.hasTabRoutePermissions(permissions);
  }
}
