// GENERATED CODE -- DO NOT EDIT!

import {grpc} from '@improbable-eng/grpc-web';

import * as assetservice_assetservice_pb from '../assetservice/assetservice_pb';

export class AssetCrud {
  static readonly serviceName = "AssetCrud";

  static readonly NewAsset = {
    methodName: "NewAsset",
    service: AssetCrud,
    requestStream: false,
    responseStream: false,
    requestType: assetservice_assetservice_pb.NewAssetRequest,
    responseType: assetservice_assetservice_pb.NewAssetResponse,
  };

  static readonly UploadChunk = {
    methodName: "UploadChunk",
    service: AssetCrud,
    requestStream: false,
    responseStream: false,
    requestType: assetservice_assetservice_pb.AssetChunk,
    responseType: assetservice_assetservice_pb.AssetChunkConfirmation,
  };

  static readonly GetAsset = {
    methodName: "GetAsset",
    service: AssetCrud,
    requestStream: false,
    responseStream: false,
    requestType: assetservice_assetservice_pb.GetAssetRequest,
    responseType: assetservice_assetservice_pb.GetAssetResponse,
  };

  static readonly DeleteAsset = {
    methodName: "DeleteAsset",
    service: AssetCrud,
    requestStream: false,
    responseStream: false,
    requestType: assetservice_assetservice_pb.DeleteAssetRequest,
    responseType: assetservice_assetservice_pb.DeleteAssetResponse,
  };

  static readonly ReplaceAsset = {
    methodName: "ReplaceAsset",
    service: AssetCrud,
    requestStream: false,
    responseStream: false,
    requestType: assetservice_assetservice_pb.ReplaceAssetRequest,
    responseType: assetservice_assetservice_pb.NewAssetResponse,
  };

  static readonly ProcessAsset = {
    methodName: "ProcessAsset",
    service: AssetCrud,
    requestStream: false,
    responseStream: false,
    requestType: assetservice_assetservice_pb.ProcessAssetRequest,
    responseType: assetservice_assetservice_pb.ProcessAssetResponse,
  };

  static readonly StreamAssetProcessProgress = {
    methodName: "StreamAssetProcessProgress",
    service: AssetCrud,
    requestStream: false,
    responseStream: true,
    requestType: assetservice_assetservice_pb.ProcessAssetProgressRequest,
    responseType: assetservice_assetservice_pb.ProcessAssetProgress,
  };

  static readonly SetAsset = {
    methodName: "SetAsset",
    service: AssetCrud,
    requestStream: false,
    responseStream: false,
    requestType: assetservice_assetservice_pb.SetAssetRequest,
    responseType: assetservice_assetservice_pb.SetAssetResponse,
  };

}

export class AssetCrudClient {
  newAsset() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  uploadChunk() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  getAsset() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  deleteAsset() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  replaceAsset() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  processAsset() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  streamAssetProcessProgress() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  setAsset() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
}
