import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';

import { MingaRoleType } from 'libs/domain';

import { AuthInfoService } from '@app/src/app/minimal/services/AuthInfo';

import { KioskRoute } from '@modules/kiosk';

/**
 * Guard for non authenticated routes to redirect authenticated kiosk user back to kiosk home
 */
@Injectable()
export class KioskNonAuthedGuard implements CanActivate {
  constructor(private _router: Router, private _authInfo: AuthInfoService) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): boolean | UrlTree {
    if ((this._authInfo.authInfo as any)?.role === MingaRoleType.KIOSK) {
      const originIsKioskLogout =
        !!this._router.getCurrentNavigation()?.extras?.state?.kioskLogout;
      const urlSegments = route.url.map(segment => segment.path);
      if (urlSegments[0] !== KioskRoute.ROOT && !originIsKioskLogout) {
        return this._router.createUrlTree([`/${KioskRoute.ROOT}`]);
      }
    }
    return true;
  }
}
