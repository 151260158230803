import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule } from '@angular/forms';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRippleModule } from '@angular/material/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';

import { MgEmptyStateModule } from '@app/src/app/components/EmptyState';
import { MgImageModule } from '@app/src/app/components/MgImage';
import { MgPagedScrollerModule } from '@app/src/app/components/mg-paged-scroller';
import { PeopleSearchInputModule } from '@app/src/app/components/people-search-input';
import { MgDirectivesModule } from '@app/src/app/directives';
import { MgIconModule } from '@app/src/app/icon';
import { PermissionsModule } from '@app/src/app/permissions';
import { MgPipesModule } from '@app/src/app/pipes';
import { MgSpinnerModule } from '@app/src/app/spinner';

import { TeacherToolsModule } from '@modules/teacher-tools';

import { CarouselModule } from '@shared/components/carousel/carousel.module';
import { CollapsibleModule } from '@shared/components/collapsible';
import { CountTileModule } from '@shared/components/count-tile';
import { CountTileCarouselModule } from '@shared/components/count-tile-carousel';
import { FiltersFormModule } from '@shared/components/filters-form';
import { FormModule } from '@shared/components/form';
import { GenericModule } from '@shared/components/generic';
import { ManagerOverlayModule } from '@shared/components/manager-overlay';
import {
  ModalOverlayService,
  OverlayModule,
} from '@shared/components/modal-overlay';
import { MultiPersonSearchModule } from '@shared/components/multi-person-search';
import { SelectFieldsModule } from '@shared/components/select-fields';
import { SystemAlertModule } from '@shared/components/system-alert';
import { TextModule } from '@shared/components/text';
import { TilesCarouselModule } from '@shared/components/tiles-carousel';
import { TooltipModule } from '@shared/components/tooltip';
import { StudentPictureModule } from '@shared/directives/student-picture/student-picture.module';
import { ArrayPipesModule } from '@shared/pipes';

import { HpmDashboardTableComponent } from './components/hpm-dashboard-table/hpm-dashboard-table.component';
import { HpmDashboardComponent } from './hpm-dashboard.component';
import { HpmDashboardPipesModule } from './pipes/hpm-dashboard-pipes.module';

@NgModule({
  imports: [
    // Minga dependencies
    ManagerOverlayModule,
    TilesCarouselModule,
    CountTileModule,
    MgSpinnerModule,
    PeopleSearchInputModule,
    SelectFieldsModule,
    MgEmptyStateModule,
    MgPagedScrollerModule,
    MgImageModule,
    MgPipesModule,
    MgIconModule,
    MultiPersonSearchModule,
    OverlayModule,
    FormModule,
    ArrayPipesModule,
    CollapsibleModule,
    MgDirectivesModule,
    TooltipModule,
    CountTileCarouselModule,
    TeacherToolsModule,
    PermissionsModule,
    TextModule,
    GenericModule,
    CarouselModule,
    HpmDashboardPipesModule,
    SystemAlertModule,
    StudentPictureModule,
    FiltersFormModule,

    // External dependencies
    CommonModule,
    FlexLayoutModule,
    MatTableModule,
    MatSortModule,
    MatCheckboxModule,
    MatPaginatorModule,
    FormsModule,
    MatButtonToggleModule,
    MatProgressSpinnerModule,
    MatTooltipModule,
    MatExpansionModule,
    MatRippleModule,
  ],
  exports: [HpmDashboardComponent],
  declarations: [
    // Directives

    // Components
    HpmDashboardComponent,
    HpmDashboardTableComponent,
  ],
  providers: [ModalOverlayService],
})
export class HpmDashboardModule {}
