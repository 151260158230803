import { Injectable, NgZone } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { grpc } from '@improbable-eng/grpc-web';

import {
  CreateUserListRequest,
  DeleteUserListRequest,
  DeleteUserListResponse,
  GetUserListMinimalsRequest,
  GetUserListMinimalsResponse,
  GetUserListRequest,
  ListUserListsRequest,
  ListUserListsResponse,
  SetHomeroomRequest,
  SetHomeroomResponse,
  UpdateUserListRequest,
  UserList,
} from '../user_list/user_list_pb';

import {
  UserLists as __UserLists,
} from '../user_list/user_list_pb_service';

@Injectable({providedIn: 'root'})
export class UserLists {

  constructor(private _ngZone: NgZone) {
  }

  listUserLists(request: ListUserListsRequest): Promise<ListUserListsResponse>;
  listUserLists(request: ListUserListsRequest, metadata: grpc.Metadata): Promise<ListUserListsResponse>;
  listUserLists(request: ListUserListsRequest, callback: (err: any|null, response: ListUserListsResponse, metadata: grpc.Metadata) => void): void;
  listUserLists(request: ListUserListsRequest, metadata: grpc.Metadata, callback: (err: any|null, response: ListUserListsResponse, metadata: grpc.Metadata) => void): void;

  listUserLists(request: ListUserListsRequest, arg1?: grpc.Metadata|((err: any|null, response: ListUserListsResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: ListUserListsResponse, metadata: grpc.Metadata) => void): Promise<ListUserListsResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<ListUserListsResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__UserLists.ListUserLists, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  getUserList(request: GetUserListRequest): Promise<UserList>;
  getUserList(request: GetUserListRequest, metadata: grpc.Metadata): Promise<UserList>;
  getUserList(request: GetUserListRequest, callback: (err: any|null, response: UserList, metadata: grpc.Metadata) => void): void;
  getUserList(request: GetUserListRequest, metadata: grpc.Metadata, callback: (err: any|null, response: UserList, metadata: grpc.Metadata) => void): void;

  getUserList(request: GetUserListRequest, arg1?: grpc.Metadata|((err: any|null, response: UserList, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: UserList, metadata: grpc.Metadata) => void): Promise<UserList>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<UserList>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__UserLists.GetUserList, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  createUserList(request: CreateUserListRequest): Promise<UserList>;
  createUserList(request: CreateUserListRequest, metadata: grpc.Metadata): Promise<UserList>;
  createUserList(request: CreateUserListRequest, callback: (err: any|null, response: UserList, metadata: grpc.Metadata) => void): void;
  createUserList(request: CreateUserListRequest, metadata: grpc.Metadata, callback: (err: any|null, response: UserList, metadata: grpc.Metadata) => void): void;

  createUserList(request: CreateUserListRequest, arg1?: grpc.Metadata|((err: any|null, response: UserList, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: UserList, metadata: grpc.Metadata) => void): Promise<UserList>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<UserList>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__UserLists.CreateUserList, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  updateUserList(request: UpdateUserListRequest): Promise<UserList>;
  updateUserList(request: UpdateUserListRequest, metadata: grpc.Metadata): Promise<UserList>;
  updateUserList(request: UpdateUserListRequest, callback: (err: any|null, response: UserList, metadata: grpc.Metadata) => void): void;
  updateUserList(request: UpdateUserListRequest, metadata: grpc.Metadata, callback: (err: any|null, response: UserList, metadata: grpc.Metadata) => void): void;

  updateUserList(request: UpdateUserListRequest, arg1?: grpc.Metadata|((err: any|null, response: UserList, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: UserList, metadata: grpc.Metadata) => void): Promise<UserList>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<UserList>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__UserLists.UpdateUserList, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  deleteUserList(request: DeleteUserListRequest): Promise<DeleteUserListResponse>;
  deleteUserList(request: DeleteUserListRequest, metadata: grpc.Metadata): Promise<DeleteUserListResponse>;
  deleteUserList(request: DeleteUserListRequest, callback: (err: any|null, response: DeleteUserListResponse, metadata: grpc.Metadata) => void): void;
  deleteUserList(request: DeleteUserListRequest, metadata: grpc.Metadata, callback: (err: any|null, response: DeleteUserListResponse, metadata: grpc.Metadata) => void): void;

  deleteUserList(request: DeleteUserListRequest, arg1?: grpc.Metadata|((err: any|null, response: DeleteUserListResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: DeleteUserListResponse, metadata: grpc.Metadata) => void): Promise<DeleteUserListResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<DeleteUserListResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__UserLists.DeleteUserList, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  getUserListMinimals(request: GetUserListMinimalsRequest): Promise<GetUserListMinimalsResponse>;
  getUserListMinimals(request: GetUserListMinimalsRequest, metadata: grpc.Metadata): Promise<GetUserListMinimalsResponse>;
  getUserListMinimals(request: GetUserListMinimalsRequest, callback: (err: any|null, response: GetUserListMinimalsResponse, metadata: grpc.Metadata) => void): void;
  getUserListMinimals(request: GetUserListMinimalsRequest, metadata: grpc.Metadata, callback: (err: any|null, response: GetUserListMinimalsResponse, metadata: grpc.Metadata) => void): void;

  getUserListMinimals(request: GetUserListMinimalsRequest, arg1?: grpc.Metadata|((err: any|null, response: GetUserListMinimalsResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: GetUserListMinimalsResponse, metadata: grpc.Metadata) => void): Promise<GetUserListMinimalsResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<GetUserListMinimalsResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__UserLists.GetUserListMinimals, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  setHomeroom(request: SetHomeroomRequest): Promise<SetHomeroomResponse>;
  setHomeroom(request: SetHomeroomRequest, metadata: grpc.Metadata): Promise<SetHomeroomResponse>;
  setHomeroom(request: SetHomeroomRequest, callback: (err: any|null, response: SetHomeroomResponse, metadata: grpc.Metadata) => void): void;
  setHomeroom(request: SetHomeroomRequest, metadata: grpc.Metadata, callback: (err: any|null, response: SetHomeroomResponse, metadata: grpc.Metadata) => void): void;

  setHomeroom(request: SetHomeroomRequest, arg1?: grpc.Metadata|((err: any|null, response: SetHomeroomResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: SetHomeroomResponse, metadata: grpc.Metadata) => void): Promise<SetHomeroomResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<SetHomeroomResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__UserLists.SetHomeroom, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

}

