import { gateway } from 'libs/generated-grpc-web';

import { isGroupMemberInfoAMember } from '@app/src/app/util/groups';

export interface GroupDetails {
  hash: string;
  members: gateway.mingaGroup_pb.GroupMemberInfo.AsObject[];
}

export namespace GroupDetails {
  export function fromMessage(
    msg: gateway.mingaGroup_pb.GroupDetails,
  ): GroupDetails {
    return {
      hash: msg.getHash(),
      members: msg.getMemberList().map(m => m.toObject()),
    };
  }

  export function isMember(details: GroupDetails, memberHash: string): boolean {
    if (!details) {
      console.error(`isMember() cannot find member without group details`);
    }

    for (const member of details.members) {
      if (member.person.personHash == memberHash) {
        return true;
      }
    }
    return false;
  }

  export function memberCount(
    details: GroupDetails,
    includePending: boolean = false,
  ): number {
    if (!details) {
      console.error(
        `memberCount() cannot find member count without group details`,
      );
      return 0;
    }

    let members = details.members;
    if (!includePending) {
      // don't count pending members as members.
      members = details.members.filter(member =>
        isGroupMemberInfoAMember(member.rank),
      );
    }

    return members.length;
  }
}
