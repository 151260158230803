import { Injectable } from '@angular/core';

import { gateway } from 'libs/generated-grpc-web';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { GroupsFacadeService } from '@app/src/app/groups/services';
import {
  GalleryServiceBase,
  IAddGalleryPhotoOptions,
  IDeleteGalleryPhotoOptions,
} from '@app/src/app/routes/gallery/services/GalleryServiceBase';
import { ReportService } from '@app/src/app/services/Report';

@Injectable({ providedIn: 'root' })
export class MingaGalleryService extends GalleryServiceBase {
  protected _loading: BehaviorSubject<boolean>;
  protected _summary: BehaviorSubject<gateway.gallery_pb.MingaGallerySummaryResponse.AsObject | null>;

  readonly loading$: Observable<boolean>;
  readonly summary$: Observable<gateway.gallery_pb.MingaGallerySummaryResponse.AsObject>;

  constructor(
    protected mingaGalleryProto: gateway.gallery_ng_grpc_pb.MingaGallery,
    protected reportService: ReportService,
    protected groupsFacade: GroupsFacadeService,
  ) {
    super(mingaGalleryProto, reportService);
    this._loading = new BehaviorSubject<boolean>(false);
    this._summary =
      new BehaviorSubject<gateway.gallery_pb.MingaGallerySummaryResponse.AsObject | null>(
        null,
      );

    this.loading$ = this._loading.asObservable();
    this.summary$ = this._summary.pipe(
      filter(summary => !!summary),
      map(
        summary =>
          summary as gateway.gallery_pb.MingaGallerySummaryResponse.AsObject,
      ),
    );
  }

  async fetchSummary() {
    const request = new gateway.gallery_pb.MingaGallerySummaryRequest();
    const wasLoading = this._loading.getValue();

    const parentHashes = await this.groupsFacade.getParentGroupHashes();
    if (parentHashes && parentHashes.length > 0) {
      /* TODO: support all groups, not just first */
      request.setGroupHash(parentHashes[0]);
    }

    try {
      if (!wasLoading) this._loading.next(true);
      const response = await this.mingaGalleryProto.summary(request);
      this._summary.next(response.toObject());
    } finally {
      if (!wasLoading) this._loading.next(false);
    }
  }

  async getSearchPhotoTotal(search: string): Promise<number> {
    const request = new gateway.gallery_pb.MingaGalleryGetPhotoTotalRequest();
    request.setSearchQuery(search);

    const parentHashes = await this.groupsFacade.getParentGroupHashes();
    if (parentHashes && parentHashes.length > 0) {
      request.setGroupHash(parentHashes[0]);
    }

    const response = await this.mingaGalleryProto.getPhotoTotal(request);
    return response.getPhotoTotal();
  }

  async addPhoto(options: IAddGalleryPhotoOptions) {
    return await this._addPhoto(this._summary, options);
  }

  async deletePhoto(options: IDeleteGalleryPhotoOptions): Promise<boolean> {
    return await this._deletePhoto(this._summary, options);
  }

  async resolvePhoto(galleryPhotoUuid: string): Promise<boolean> {
    return await this._resolvePhoto(galleryPhotoUuid);
  }
}
