import { Injectable, Injector } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Resolve,
  RouterStateSnapshot,
} from '@angular/router';

import { ChallengeContentInfoService } from '@app/src/app/modules/challenges/services';
import { ChallengeRouteData } from '@app/src/app/modules/challenges/services';

@Injectable({ providedIn: 'root' })
export class ChallengeRouteDataResolver implements Resolve<ChallengeRouteData> {
  constructor(
    private studioContentInfoService: ChallengeContentInfoService,
    private injector: Injector,
  ) {}

  async resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Promise<ChallengeRouteData> {
    const routeData = new ChallengeRouteData(
      route,
      this.injector,
      this.studioContentInfoService,
    );
    await routeData.initDataFromRoute();
    return routeData;
  }
}
