import { RestrictionErrorCode, RestrictionErrorMinimal } from 'libs/domain';
import { restriction_pb } from 'libs/generated-grpc-ts';

export const toProto = (
  error: RestrictionErrorMinimal,
): restriction_pb.RestrictionError => {
  const message = new restriction_pb.RestrictionError();
  if (error.code) message.setCode(error.code);
  if (error.personHash) message.setPersonHash(error.personHash);
  if (error.personName) message.setPersonName(error.personName);
  if (error.message) message.setMessage(error.message);
  return message;
};

export const fromProto = (
  message: restriction_pb.RestrictionError,
): RestrictionErrorMinimal => {
  return {
    code: message.getCode() as RestrictionErrorCode,
    personHash: message.getPersonHash(),
    personName: message.getPersonName(),
    message: message.getMessage(),
  };
};
