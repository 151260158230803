import { MingaRoleType } from 'libs/domain';

import { badgeRoleNameToIconUrl, roleTypeToIconUrl } from '../../';
import { getRole } from '../../';
import { ExportNameType, ExportPersonType } from '../types';

export const nameFinder = (item: ExportPersonType, name?: ExportNameType) => {
  if (name === 'first') {
    return item?.firstName;
  } else if (name === 'last') {
    return item?.lastName;
  } else {
    return item?.firstName ? item?.firstName + ' ' + item?.lastName : '';
  }
};

export const studentIdFinder = (item?: { studentId?: string }) =>
  item?.studentId || '';

export const gradeFinder = (item?: { grade?: string }) => item?.grade || '';

export const roleFinder = (
  item?: {
    role?: string;
    badgeRoleName?: string;
    badgeIconUrl?: string;
    roleType?: MingaRoleType;
  },
  role?: boolean,
) => {
  if (item?.roleType) {
    const roleObj = getRole(item.roleType);
    item.role = roleObj?.name;
    item.badgeIconUrl = roleTypeToIconUrl(item.roleType);
  } else if (item?.badgeRoleName) {
    item.badgeIconUrl = badgeRoleNameToIconUrl(item.badgeRoleName);
  }
  return (role ? item?.role || item?.badgeRoleName : item?.badgeIconUrl) || '';
};

export const getHallPassNote = (
  note?: string,
  noteToRequester?: string,
): string => {
  let message = '';
  if (note) {
    // if there's also a note to requester we need a label
    message = noteToRequester ? `Note: ${note}` : note;
  }

  if (noteToRequester) {
    message += `\nNote to requester: ${noteToRequester}`;
  }

  return message;
};
