// GENERATED CODE -- DO NOT EDIT!

import {grpc} from '@improbable-eng/grpc-web';

import * as content_poll_style_pb from '../../content/poll/style_pb';
import * as common_stream_pb from '../../common/stream_pb';
import * as common_delta_pb from '../../common/delta_pb';

export class PollStyleLibrary {
  static readonly serviceName = "minga.content.PollStyleLibrary";

  static readonly Get = {
    methodName: "Get",
    service: PollStyleLibrary,
    requestStream: false,
    responseStream: false,
    requestType: content_poll_style_pb.PollStyleLibraryGet,
    responseType: content_poll_style_pb.PollStyle,
  };

  static readonly Add = {
    methodName: "Add",
    service: PollStyleLibrary,
    requestStream: false,
    responseStream: false,
    requestType: content_poll_style_pb.PollStyleLibraryAdd,
    responseType: content_poll_style_pb.PollStyle,
  };

  static readonly Update = {
    methodName: "Update",
    service: PollStyleLibrary,
    requestStream: false,
    responseStream: false,
    requestType: content_poll_style_pb.PollStyleLibraryUpdate,
    responseType: content_poll_style_pb.PollStyle,
  };

  static readonly Delete = {
    methodName: "Delete",
    service: PollStyleLibrary,
    requestStream: false,
    responseStream: false,
    requestType: content_poll_style_pb.PollStyleLibraryDelete,
    responseType: content_poll_style_pb.PollStyle,
  };

  static readonly SetArchived = {
    methodName: "SetArchived",
    service: PollStyleLibrary,
    requestStream: false,
    responseStream: false,
    requestType: content_poll_style_pb.PollStyleLibrarySetArchived,
    responseType: content_poll_style_pb.PollStyle,
  };

  static readonly List = {
    methodName: "List",
    service: PollStyleLibrary,
    requestStream: false,
    responseStream: false,
    requestType: content_poll_style_pb.PollStyleLibraryList,
    responseType: content_poll_style_pb.PollStyleList,
  };

  static readonly Search = {
    methodName: "Search",
    service: PollStyleLibrary,
    requestStream: false,
    responseStream: true,
    requestType: common_stream_pb.StreamID,
    responseType: content_poll_style_pb.PollStyleLibrarySearchResponse,
  };

  static readonly SearchControl = {
    methodName: "SearchControl",
    service: PollStyleLibrary,
    requestStream: false,
    responseStream: false,
    requestType: common_stream_pb.StreamControl,
    responseType: common_stream_pb.StreamControlResponse,
  };

}

export class PollStyleLibraryClient {
  get() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  add() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  update() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  delete() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  setArchived() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  list() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  search() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  searchControl() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
}
