<mat-table
  matSort
  class="list-table no-explicit-mobile-layout list-table-relaxed"
  [fxHide]="dataSource.data?.length === 0"
  [dataSource]="dataSource"
  [trackBy]="trackById">
  <!-- Icon Column -->
  <ng-container matColumnDef="icon">
    <mat-header-cell
      *matHeaderCellDef
      class="text-center"></mat-header-cell>
    <mat-cell
      *matCellDef="let item"
      class="text-center">
      <mg-icon
        class="checkin-icon"
        [iconName]="item.icon"
        [style]="{ '--icon-color': item.color }">
      </mg-icon>
    </mat-cell>
  </ng-container>

  <!-- Name Column -->
  <ng-container matColumnDef="name">
    <mat-header-cell
      *matHeaderCellDef
      mat-sort-header
      class="w-sm-flex name-column">
      {{ MESSAGES.COLUMN_LABEL_REASON }}
    </mat-header-cell>
    <mat-cell
      *matCellDef="let item"
      class="w-sm-flex name-column">
      <mg-text
        variant="header-sm"
        [numberOfLines]="2">
        {{ item.name }}
      </mg-text>
    </mat-cell>
  </ng-container>

  <!-- Status Column -->
  <ng-container matColumnDef="status">
    <mat-header-cell *matHeaderCellDef></mat-header-cell>
    <mat-cell *matCellDef="let item">
      <div
        fxLayout="row"
        fxLayoutAlign="start start"
        fxLayoutGap="12px">
        <mg-icon
          iconName="mg-auto-end"
          matTooltipClass="mg-tooltip"
          [matTooltip]="
            item.autoEndHallPass ? 'Audo-end hall pass' : 'Manually ended'
          "
          [class.disabled]="!item.autoEndHallPass">
        </mg-icon>
        <mg-icon
          iconName="mg-multiple-check-ins"
          matTooltipClass="mg-tooltip"
          [matTooltip]="
            item.allowMultipleCheckins
              ? 'Multiple check ins'
              : 'Single check in'
          "
          [class.disabled]="!item.allowMultipleCheckins">
        </mg-icon>
      </div>
    </mat-cell>
  </ng-container>

  <!-- Points Column -->
  <ng-container matColumnDef="points">
    <mat-header-cell
      *matHeaderCellDef
      class="text-center">
      {{ MESSAGES.COLUMN_LABEL_POINTS }}
    </mat-header-cell>
    <mat-cell
      *matCellDef="let item"
      class="text-center">
      <mg-text variant="header-sm">
        {{ item.pointReward }}
      </mg-text>
    </mat-cell>
  </ng-container>

  <!-- QR Code Column -->
  <ng-container matColumnDef="qr-code">
    <mat-header-cell
      *matHeaderCellDef
      class="text-center">
    </mat-header-cell>
    <mat-cell
      *matCellDef="let item"
      class="text-center">
      <mg-btn
        variant="icon"
        iconSet="minga"
        icon="mg-qr-code"
        tooltip="View QR Code"
        [id]="'checkin-types-click-qr-code'"
        [disabled]="!item.selfCheckIn"
        (pressed)="openQrViewer(item)">
      </mg-btn>
    </mat-cell>
  </ng-container>

  <!-- Edit Column -->
  <ng-container matColumnDef="edit">
    <mat-header-cell
      *matHeaderCellDef
      class="text-center">
    </mat-header-cell>
    <mat-cell
      *matCellDef="let item"
      class="text-center">
      <mg-btn
        variant="icon"
        iconSet="minga"
        icon="mg-edit"
        tooltip="Edit"
        [id]="'checkin-types-click-edit'"
        (pressed)="handleOnClickEditReason(item)">
      </mg-btn>
    </mat-cell>
  </ng-container>

  <!-- Active Column -->
  <ng-container matColumnDef="active">
    <mat-header-cell
      *matHeaderCellDef
      class="text-center">
    </mat-header-cell>
    <mat-cell
      *matCellDef="let item"
      class="text-center">
      <mg-form-toggle
        [id]="'checkin-types-click-toggle-' + item.id"
        [value]="item.active"
        (valueChange)="onStatusChange(item)">
      </mg-form-toggle>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="mobile">
    <mat-header-cell *matHeaderCellDef></mat-header-cell>
    <mat-cell *matCellDef="let item">
      <div class="tw-flex tw-items-center tw-gap-1 tw-w-full">
        <mg-icon
          class="checkin-icon"
          [iconName]="item.icon"
          [style]="{ '--icon-color': item.color }">
        </mg-icon>
        <mg-text
          class="tw-flex tw-grow"
          variant="header-sm"
          [numberOfLines]="2">
          {{ item.name }}
        </mg-text>
        <mg-btn
          variant="icon"
          iconSet="minga"
          icon="mg-edit"
          [id]="'checkin-types-click-edit'"
          (pressed)="handleOnClickEditReason(item)">
        </mg-btn>
        <mg-form-toggle
          [id]="'checkin-types-click-toggle-' + item.id"
          [value]="item.active"
          (valueChange)="onStatusChange(item)">
        </mg-form-toggle>
      </div>
    </mat-cell>
  </ng-container>

  <mat-header-row *matHeaderRowDef="displayedColumns$ | async"></mat-header-row>
  <mat-row *matRowDef="let row; columns: displayedColumns$ | async"></mat-row>
</mat-table>

<mg-empty-state
  *ngIf="dataSource.data?.length === 0"
  [title]="MESSAGES.NO_RESULTS_TITLE"
  [subtitle]="MESSAGES.NO_RESULTS_MESSAGE">
</mg-empty-state>

<!-- Pagination -->
<div class="tw-mt-6">
  <mg-paginator [pageSizeOptions]="[50, 20, 10]"></mg-paginator>
</div>

<!-- Mobile Card Template -->
<ng-template
  #mobileCardTemplate
  let-item="item">
  <mg-card
    paddingX="3"
    paddingY="2">
  </mg-card>
</ng-template>
