// GENERATED CODE -- DO NOT EDIT!

import {grpc} from '@improbable-eng/grpc-web';

import * as hall_pass_hall_pass_pb from '../hall_pass/hall_pass_pb';
import * as google_protobuf_wrappers_pb from '../google/protobuf/wrappers_pb';
import * as image_image_pb from '../image/image_pb';
import * as common_date_pb from '../common/date_pb';
import * as common_stream_pb from '../common/stream_pb';
import * as gateway_person_view_pb from '../gateway/person_view_pb';
import * as restriction_restriction_pb from '../restriction/restriction_pb';
import * as membership_list_membership_list_pb from '../membership_list/membership_list_pb';
import * as student_schedule_student_schedule_pb from '../student_schedule/student_schedule_pb';

export class HallPassManager {
  static readonly serviceName = "minga.hall_pass.HallPassManager";

  static readonly UpdateHallPassType = {
    methodName: "UpdateHallPassType",
    service: HallPassManager,
    requestStream: false,
    responseStream: false,
    requestType: hall_pass_hall_pass_pb.UpdateHallPassTypeRequest,
    responseType: hall_pass_hall_pass_pb.UpdateHallPassTypeResponse,
  };

  static readonly DeleteHallPassType = {
    methodName: "DeleteHallPassType",
    service: HallPassManager,
    requestStream: false,
    responseStream: false,
    requestType: hall_pass_hall_pass_pb.DeleteHallPassTypeRequest,
    responseType: hall_pass_hall_pass_pb.DeleteHallPassTypeResponse,
  };

  static readonly CreateHallPass = {
    methodName: "CreateHallPass",
    service: HallPassManager,
    requestStream: false,
    responseStream: false,
    requestType: hall_pass_hall_pass_pb.CreateHallPassRequest,
    responseType: hall_pass_hall_pass_pb.CreateHallPassResponse,
  };

  static readonly GetMingaHallPassType = {
    methodName: "GetMingaHallPassType",
    service: HallPassManager,
    requestStream: false,
    responseStream: false,
    requestType: hall_pass_hall_pass_pb.GetMingaHallPassTypeRequest,
    responseType: hall_pass_hall_pass_pb.GetMingaHallPassTypeResponse,
  };

  static readonly GetMingaHallPassTypes = {
    methodName: "GetMingaHallPassTypes",
    service: HallPassManager,
    requestStream: false,
    responseStream: false,
    requestType: hall_pass_hall_pass_pb.GetMingaHallPassTypesRequest,
    responseType: hall_pass_hall_pass_pb.GetMingaHallPassTypesResponse,
  };

  static readonly AddDefaultHallPassTypesToMinga = {
    methodName: "AddDefaultHallPassTypesToMinga",
    service: HallPassManager,
    requestStream: false,
    responseStream: false,
    requestType: hall_pass_hall_pass_pb.AddDefaultHallPassTypesRequest,
    responseType: hall_pass_hall_pass_pb.AddDefaultHallPassTypesResponse,
  };

  static readonly GetMingaHallPassTypesCounts = {
    methodName: "GetMingaHallPassTypesCounts",
    service: HallPassManager,
    requestStream: false,
    responseStream: false,
    requestType: hall_pass_hall_pass_pb.GetMingaHallPassTypesCountsRequest,
    responseType: hall_pass_hall_pass_pb.GetMingaHallPassTypesCountsResponse,
  };

  static readonly ExpireHallPass = {
    methodName: "ExpireHallPass",
    service: HallPassManager,
    requestStream: false,
    responseStream: false,
    requestType: hall_pass_hall_pass_pb.ExpireHallPassRequest,
    responseType: hall_pass_hall_pass_pb.ExpireHallPassResponse,
  };

  static readonly ApproveHallPass = {
    methodName: "ApproveHallPass",
    service: HallPassManager,
    requestStream: false,
    responseStream: false,
    requestType: hall_pass_hall_pass_pb.ApproveHallPassRequest,
    responseType: hall_pass_hall_pass_pb.ApproveHallPassResponse,
  };

  static readonly StartHallPass = {
    methodName: "StartHallPass",
    service: HallPassManager,
    requestStream: false,
    responseStream: false,
    requestType: hall_pass_hall_pass_pb.StartHallPassRequest,
    responseType: hall_pass_hall_pass_pb.StartHallPassResponse,
  };

  static readonly CancelHallPass = {
    methodName: "CancelHallPass",
    service: HallPassManager,
    requestStream: false,
    responseStream: false,
    requestType: hall_pass_hall_pass_pb.CancelHallPassRequest,
    responseType: hall_pass_hall_pass_pb.CancelHallPassResponse,
  };

  static readonly streamMingaHallPasses = {
    methodName: "streamMingaHallPasses",
    service: HallPassManager,
    requestStream: false,
    responseStream: true,
    requestType: common_stream_pb.StreamID,
    responseType: hall_pass_hall_pass_pb.StreamHallPassWithTypeItem,
  };

  static readonly streamMingaHallPassesControl = {
    methodName: "streamMingaHallPassesControl",
    service: HallPassManager,
    requestStream: false,
    responseStream: false,
    requestType: common_stream_pb.StreamControl,
    responseType: common_stream_pb.StreamControlResponse,
  };

  static readonly GetHallPassesForRecipient = {
    methodName: "GetHallPassesForRecipient",
    service: HallPassManager,
    requestStream: false,
    responseStream: false,
    requestType: hall_pass_hall_pass_pb.GetHallPassesForRecipientRequest,
    responseType: hall_pass_hall_pass_pb.GetHallPassesForRecipientResponse,
  };

  static readonly GetMembershipLists = {
    methodName: "GetMembershipLists",
    service: HallPassManager,
    requestStream: false,
    responseStream: false,
    requestType: hall_pass_hall_pass_pb.GetMembershipListsRequest,
    responseType: hall_pass_hall_pass_pb.GetMembershipListsResponse,
  };

  static readonly CheckHallPassValidity = {
    methodName: "CheckHallPassValidity",
    service: HallPassManager,
    requestStream: false,
    responseStream: false,
    requestType: hall_pass_hall_pass_pb.CheckHallPassValidityRequest,
    responseType: hall_pass_hall_pass_pb.CheckHallPassValidityResponse,
  };

  static readonly UpdateHallPassBlackOutWindow = {
    methodName: "UpdateHallPassBlackOutWindow",
    service: HallPassManager,
    requestStream: false,
    responseStream: false,
    requestType: hall_pass_hall_pass_pb.UpdateHallPassBlackOutWindowRequest,
    responseType: hall_pass_hall_pass_pb.UpdateHallPassBlackOutWindowResponse,
  };

  static readonly DeleteHallPassBlackOutWindow = {
    methodName: "DeleteHallPassBlackOutWindow",
    service: HallPassManager,
    requestStream: false,
    responseStream: false,
    requestType: hall_pass_hall_pass_pb.DeleteHallPassBlackOutWindowRequest,
    responseType: hall_pass_hall_pass_pb.DeleteHallPassBlackOutWindowResponse,
  };

  static readonly GetHallPassBlackOutWindows = {
    methodName: "GetHallPassBlackOutWindows",
    service: HallPassManager,
    requestStream: false,
    responseStream: false,
    requestType: hall_pass_hall_pass_pb.GetHallPassBlackOutWindowsRequest,
    responseType: hall_pass_hall_pass_pb.GetHallPassBlackOutWindowsResponse,
  };

  static readonly GetHallPasses = {
    methodName: "GetHallPasses",
    service: HallPassManager,
    requestStream: false,
    responseStream: false,
    requestType: hall_pass_hall_pass_pb.GetHallPassesRequest,
    responseType: hall_pass_hall_pass_pb.GetHallPassesResponse,
  };

  static readonly UpdateHallPass = {
    methodName: "UpdateHallPass",
    service: HallPassManager,
    requestStream: false,
    responseStream: false,
    requestType: hall_pass_hall_pass_pb.UpdateHallPassRequest,
    responseType: hall_pass_hall_pass_pb.UpdateHallPassResponse,
  };

  static readonly ArchiveHallPass = {
    methodName: "ArchiveHallPass",
    service: HallPassManager,
    requestStream: false,
    responseStream: false,
    requestType: hall_pass_hall_pass_pb.ArchiveHallPassRequest,
    responseType: hall_pass_hall_pass_pb.ArchiveHallPassResponse,
  };

}

export class HallPassManagerClient {
  updateHallPassType() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  deleteHallPassType() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  createHallPass() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  getMingaHallPassType() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  getMingaHallPassTypes() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  addDefaultHallPassTypesToMinga() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  getMingaHallPassTypesCounts() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  expireHallPass() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  approveHallPass() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  startHallPass() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  cancelHallPass() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  streamMingaHallPasses() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  streamMingaHallPassesControl() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  getHallPassesForRecipient() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  getMembershipLists() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  checkHallPassValidity() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  updateHallPassBlackOutWindow() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  deleteHallPassBlackOutWindow() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  getHallPassBlackOutWindows() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  getHallPasses() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  updateHallPass() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  archiveHallPass() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
}
