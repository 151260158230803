import { Injectable, NgZone } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { grpc } from '@improbable-eng/grpc-web';

import {
  CreateFeedVideoRequest,
  CreateFeedVideoResponse,
  DeleteFeedVideoRequest,
  DeleteFeedVideoResponse,
  UpdateFeedVideoRequest,
  UpdateFeedVideoResponse,
  VimeoPublicTokenRequest,
  VimeoPublicTokenResponse,
  VimeoVideoDataRequest,
  VimeoVideoDataResponse,
  YouTubeVideoDataRequest,
  YouTubeVideoDataResponse,
} from '../gateway/feed_video_pb';

import {
  FeedVideoManager as __FeedVideoManager,
} from '../gateway/feed_video_pb_service';

/**
  * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
  FeedVideoManager service (all rpc calls available for Content that are POST to Feeds)
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *  */
@Injectable({providedIn: 'root'})
export class FeedVideoManager {

  constructor(private _ngZone: NgZone) {
  }

  create(request: CreateFeedVideoRequest): Promise<CreateFeedVideoResponse>;
  create(request: CreateFeedVideoRequest, metadata: grpc.Metadata): Promise<CreateFeedVideoResponse>;
  create(request: CreateFeedVideoRequest, callback: (err: any|null, response: CreateFeedVideoResponse, metadata: grpc.Metadata) => void): void;
  create(request: CreateFeedVideoRequest, metadata: grpc.Metadata, callback: (err: any|null, response: CreateFeedVideoResponse, metadata: grpc.Metadata) => void): void;

  create(request: CreateFeedVideoRequest, arg1?: grpc.Metadata|((err: any|null, response: CreateFeedVideoResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: CreateFeedVideoResponse, metadata: grpc.Metadata) => void): Promise<CreateFeedVideoResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<CreateFeedVideoResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__FeedVideoManager.create, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  update(request: UpdateFeedVideoRequest): Promise<UpdateFeedVideoResponse>;
  update(request: UpdateFeedVideoRequest, metadata: grpc.Metadata): Promise<UpdateFeedVideoResponse>;
  update(request: UpdateFeedVideoRequest, callback: (err: any|null, response: UpdateFeedVideoResponse, metadata: grpc.Metadata) => void): void;
  update(request: UpdateFeedVideoRequest, metadata: grpc.Metadata, callback: (err: any|null, response: UpdateFeedVideoResponse, metadata: grpc.Metadata) => void): void;

  update(request: UpdateFeedVideoRequest, arg1?: grpc.Metadata|((err: any|null, response: UpdateFeedVideoResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: UpdateFeedVideoResponse, metadata: grpc.Metadata) => void): Promise<UpdateFeedVideoResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<UpdateFeedVideoResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__FeedVideoManager.update, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  delete(request: DeleteFeedVideoRequest): Promise<DeleteFeedVideoResponse>;
  delete(request: DeleteFeedVideoRequest, metadata: grpc.Metadata): Promise<DeleteFeedVideoResponse>;
  delete(request: DeleteFeedVideoRequest, callback: (err: any|null, response: DeleteFeedVideoResponse, metadata: grpc.Metadata) => void): void;
  delete(request: DeleteFeedVideoRequest, metadata: grpc.Metadata, callback: (err: any|null, response: DeleteFeedVideoResponse, metadata: grpc.Metadata) => void): void;

  delete(request: DeleteFeedVideoRequest, arg1?: grpc.Metadata|((err: any|null, response: DeleteFeedVideoResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: DeleteFeedVideoResponse, metadata: grpc.Metadata) => void): Promise<DeleteFeedVideoResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<DeleteFeedVideoResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__FeedVideoManager.delete, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  getPublicVimeoToken(request: VimeoPublicTokenRequest): Promise<VimeoPublicTokenResponse>;
  getPublicVimeoToken(request: VimeoPublicTokenRequest, metadata: grpc.Metadata): Promise<VimeoPublicTokenResponse>;
  getPublicVimeoToken(request: VimeoPublicTokenRequest, callback: (err: any|null, response: VimeoPublicTokenResponse, metadata: grpc.Metadata) => void): void;
  getPublicVimeoToken(request: VimeoPublicTokenRequest, metadata: grpc.Metadata, callback: (err: any|null, response: VimeoPublicTokenResponse, metadata: grpc.Metadata) => void): void;

  getPublicVimeoToken(request: VimeoPublicTokenRequest, arg1?: grpc.Metadata|((err: any|null, response: VimeoPublicTokenResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: VimeoPublicTokenResponse, metadata: grpc.Metadata) => void): Promise<VimeoPublicTokenResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<VimeoPublicTokenResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__FeedVideoManager.getPublicVimeoToken, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  getVimeoVideoData(request: VimeoVideoDataRequest): Promise<VimeoVideoDataResponse>;
  getVimeoVideoData(request: VimeoVideoDataRequest, metadata: grpc.Metadata): Promise<VimeoVideoDataResponse>;
  getVimeoVideoData(request: VimeoVideoDataRequest, callback: (err: any|null, response: VimeoVideoDataResponse, metadata: grpc.Metadata) => void): void;
  getVimeoVideoData(request: VimeoVideoDataRequest, metadata: grpc.Metadata, callback: (err: any|null, response: VimeoVideoDataResponse, metadata: grpc.Metadata) => void): void;

  getVimeoVideoData(request: VimeoVideoDataRequest, arg1?: grpc.Metadata|((err: any|null, response: VimeoVideoDataResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: VimeoVideoDataResponse, metadata: grpc.Metadata) => void): Promise<VimeoVideoDataResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<VimeoVideoDataResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__FeedVideoManager.getVimeoVideoData, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  getYouTubeVideoData(request: YouTubeVideoDataRequest): Promise<YouTubeVideoDataResponse>;
  getYouTubeVideoData(request: YouTubeVideoDataRequest, metadata: grpc.Metadata): Promise<YouTubeVideoDataResponse>;
  getYouTubeVideoData(request: YouTubeVideoDataRequest, callback: (err: any|null, response: YouTubeVideoDataResponse, metadata: grpc.Metadata) => void): void;
  getYouTubeVideoData(request: YouTubeVideoDataRequest, metadata: grpc.Metadata, callback: (err: any|null, response: YouTubeVideoDataResponse, metadata: grpc.Metadata) => void): void;

  getYouTubeVideoData(request: YouTubeVideoDataRequest, arg1?: grpc.Metadata|((err: any|null, response: YouTubeVideoDataResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: YouTubeVideoDataResponse, metadata: grpc.Metadata) => void): Promise<YouTubeVideoDataResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<YouTubeVideoDataResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__FeedVideoManager.getYouTubeVideoData, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

}

