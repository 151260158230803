import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

import Fuse from 'fuse.js';
import { Observable, Subscription } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  switchMap,
  takeUntil,
} from 'rxjs/operators';

import { SystemAlertSnackBarService } from '@shared/components/system-alert-snackbar';

import { FormGroupedSelectBaseDirective } from '../form-grouped-select/form-grouped-select-base.util';
import { OptionItem } from '../form-grouped-select/form-grouped-select.types';

@Component({
  selector: 'mg-form-sheet-grouped-select',
  templateUrl: './form-sheet-grouped-select.component.html',
  styleUrls: ['./form-sheet-grouped-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormSheetGroupedSelectComponent
  extends FormGroupedSelectBaseDirective
  implements OnInit, OnDestroy
{
  @Input() set value(value: OptionItem[] | OptionItem | null) {
    this.onSelect(value);
  }
  @Input() commitChanges$: Observable<any>;
  @Input() clearSelection: Observable<void>;
  @Output() commitChanges: EventEmitter<OptionItem | OptionItem[] | null> =
    new EventEmitter();

  public readonly searchControl = new UntypedFormControl('');
  public selected = new Map<number | string, OptionItem>();

  private _clearSelectionSubscription: Subscription;

  constructor(
    public cdr: ChangeDetectorRef,
    public snackBar: SystemAlertSnackBarService,
  ) {
    super(
      cdr,
      snackBar,
      new Fuse<OptionItem>([], {
        isCaseSensitive: false,
        shouldSort: true,
        keys: ['label', 'value', 'category.label'],
        threshold: 0.2,
      }),
    );
  }

  ngOnInit(): void {
    this._searchChanges();
    this.commitChanges$?.pipe(takeUntil(this._destroyedSubj)).subscribe(() => {
      const selected = Array.from(this.selected.values());
      const emitted = this.multiple ? selected : selected[0];
      this.commitChanges.emit(emitted);
    });

    if (this._categoryOpenByDefault) {
      this.setCategory(this._categoryOpenByDefault, false);
    }

    this._clearSelectionSubscription = this.clearSelection?.subscribe(() => {
      this.selected.clear();
      this.selectChange?.emit(null);
      this.cdr.markForCheck();
    });
  }

  ngOnDestroy(): void {
    this._clearSelectionSubscription?.unsubscribe();
  }

  public onSelect(option: OptionItem | OptionItem[] | null) {
    if (option) {
      const options = Array.isArray(option) ? option : [option];
      options.forEach(o => {
        const key = this._getKey(o);
        this.selected.set(key, o);
      });
    } else {
      this.selected = new Map();
    }
  }

  public optionClicked(option: OptionItem) {
    if (!this.multiple) {
      this.selected = new Map();
    }

    const key = this._getKey(option);

    if (this.selected.has(key)) {
      this.selected.delete(key);
    } else {
      this.selected.set(key, option);
    }
  }

  public isSelected(option: OptionItem) {
    return this.selected.has(this._getKey(option));
  }

  public resetSearch() {
    this.searchControl.reset(null, { emitEvent: false });
    this._searchFilterSubj.next('');
    this.back();
  }

  private _searchChanges() {
    this.searchControl.valueChanges
      .pipe(
        takeUntil(this._destroyedSubj),
        debounceTime(300),
        distinctUntilChanged(),
        switchMap(async searchValue => {
          await this.onSearch({ term: searchValue });
          return searchValue;
        }),
      )
      .subscribe(async searchValue => {
        this._searchFilterSubj.next(searchValue);
      });
  }

  /**
   * Need to namespace the keys by category to avoid id collisions
   */
  private _getKey(option: OptionItem) {
    return `${option.category.value}:${option.value}`;
  }
}
