import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import * as day from 'dayjs';
import * as localizedFormat from 'dayjs/plugin/localizedFormat';
import { ReportTypes } from 'libs/domain';

import { CarouselStylePresets } from '@shared/components/tiles-carousel';
import { getQueryParamsFromDateRange } from '@shared/utils';

import { CheckinManagerRoutes } from '../../checkin-manager.constants';
import {
  CheckinManagerReportFilterType,
  CheckinManagerReportsService,
  CheckinManagerReportType,
} from '../checkin-manager-reports';
import { CheckinDashboardMessages } from './constants/checkin-manager-dashboard.constants';
import { CheckinManagerDashboardService } from './services';

day.extend(localizedFormat);

/**
 * Checkin Dashboard Component
 *
 * Root of the checkin manager, shows the checkin page with stats.
 */
@Component({
  selector: 'mg-checkin-manager-dashboard',
  templateUrl: './checkin-manager-dashboard.component.html',
  styleUrls: ['./checkin-manager-dashboard.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [CheckinManagerDashboardService],
})
export class CheckinManagerDashboardComponent implements OnInit {
  /** Messages */
  MESSAGES: typeof CheckinDashboardMessages = CheckinDashboardMessages;
  public readonly CAROUSEL_PRESETS = CarouselStylePresets;

  /** Date */
  todaysDate: string;

  /** Component Constructor */
  constructor(
    public checkinDashboard: CheckinManagerDashboardService,
    private _router: Router,
    private _checkInReportsService: CheckinManagerReportsService,
  ) {}

  /** Component Lifecycle: On Mount */
  ngOnInit(): void {
    this.todaysDate = day().format('LL');
  }

  /**
   * Go To Reports
   */
  public async goToReports(
    reasonId: number,
    goToAbsents: boolean,
  ): Promise<void> {
    this._checkInReportsService.setFilter(
      CheckinManagerReportFilterType.REASON,
      reasonId,
    );
    this._checkInReportsService.setFilter(
      CheckinManagerReportFilterType.TYPE,
      goToAbsents
        ? CheckinManagerReportType.ABSENTEES
        : CheckinManagerReportType.CHECKINS,
    );
    const startDate = day().startOf('day');
    const endDate = day().endOf('day');
    const queryParams = getQueryParamsFromDateRange({ startDate, endDate });

    this._router.navigate(
      [
        CheckinManagerRoutes.ROOT,
        CheckinManagerRoutes.REPORTS,
        ReportTypes.CHECKIN,
      ],
      {
        queryParams,
      },
    );
  }
}
