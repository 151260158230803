// GENERATED CODE -- DO NOT EDIT!

import {grpc} from '@improbable-eng/grpc-web';

import * as gateway_preferences_pb from '../gateway/preferences_pb';

export class UserPreferences {
  static readonly serviceName = "UserPreferences";

  static readonly Metadata = {
    methodName: "Metadata",
    service: UserPreferences,
    requestStream: false,
    responseStream: false,
    requestType: gateway_preferences_pb.UserPreferencesMetadataRequest,
    responseType: gateway_preferences_pb.UserPreferencesMetadataResponse,
  };

  static readonly Get = {
    methodName: "Get",
    service: UserPreferences,
    requestStream: false,
    responseStream: false,
    requestType: gateway_preferences_pb.UserPreferencesGetRequest,
    responseType: gateway_preferences_pb.UserPreferencesGetResponse,
  };

  static readonly Update = {
    methodName: "Update",
    service: UserPreferences,
    requestStream: false,
    responseStream: false,
    requestType: gateway_preferences_pb.UserPreferencesUpdateRequest,
    responseType: gateway_preferences_pb.UserPreferencesUpdateResponse,
  };

  static readonly Reset = {
    methodName: "Reset",
    service: UserPreferences,
    requestStream: false,
    responseStream: false,
    requestType: gateway_preferences_pb.UserPreferencesResetRequest,
    responseType: gateway_preferences_pb.UserPreferencesResetResponse,
  };

  static readonly FtueMarkSeen = {
    methodName: "FtueMarkSeen",
    service: UserPreferences,
    requestStream: false,
    responseStream: false,
    requestType: gateway_preferences_pb.FtueMarkSeenRequest,
    responseType: gateway_preferences_pb.FtueMarkSeenResponse,
  };

}

export class UserPreferencesClient {
  metadata() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  get() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  update() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  reset() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  ftueMarkSeen() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
}
