// GENERATED CODE -- DO NOT EDIT!

import {grpc} from '@improbable-eng/grpc-web';

import * as gateway_notification_pb from '../gateway/notification_pb';
import * as common_legacy_pb from '../common/legacy_pb';
import * as common_stream_pb from '../common/stream_pb';
import * as gateway_person_view_pb from '../gateway/person_view_pb';
import * as image_image_pb from '../image/image_pb';

export class NotificationManager {
  static readonly serviceName = "NotificationManager";

  static readonly sendBroadcast = {
    methodName: "sendBroadcast",
    service: NotificationManager,
    requestStream: false,
    responseStream: false,
    requestType: gateway_notification_pb.BroadcastNotificationRequest,
    responseType: gateway_notification_pb.BroadcastNotificationResponse,
  };

  static readonly Listen = {
    methodName: "Listen",
    service: NotificationManager,
    requestStream: false,
    responseStream: true,
    requestType: gateway_notification_pb.NotificationListenRequest,
    responseType: gateway_notification_pb.NotificationListenResponse,
  };

  static readonly StreamNotifications = {
    methodName: "StreamNotifications",
    service: NotificationManager,
    requestStream: false,
    responseStream: true,
    requestType: common_stream_pb.StreamID,
    responseType: gateway_notification_pb.StreamNotificationsResponse,
  };

  static readonly StreamNotificationsControl = {
    methodName: "StreamNotificationsControl",
    service: NotificationManager,
    requestStream: false,
    responseStream: false,
    requestType: common_stream_pb.StreamControl,
    responseType: common_stream_pb.StreamControlResponse,
  };

  static readonly MarkViewed = {
    methodName: "MarkViewed",
    service: NotificationManager,
    requestStream: false,
    responseStream: false,
    requestType: gateway_notification_pb.MarkNotificationsViewedRequest,
    responseType: gateway_notification_pb.MarkNotificationsViewedResponse,
  };

  static readonly MarkRead = {
    methodName: "MarkRead",
    service: NotificationManager,
    requestStream: false,
    responseStream: false,
    requestType: gateway_notification_pb.MarkNotificationsReadRequest,
    responseType: gateway_notification_pb.MarkNotificationsReadResponse,
  };

  static readonly LoadUnviewedNotifications = {
    methodName: "LoadUnviewedNotifications",
    service: NotificationManager,
    requestStream: false,
    responseStream: false,
    requestType: gateway_notification_pb.LoadUnviewedNotificationsRequest,
    responseType: gateway_notification_pb.LoadUnviewedNotificationsResponse,
  };

  static readonly GetNotifications = {
    methodName: "GetNotifications",
    service: NotificationManager,
    requestStream: false,
    responseStream: false,
    requestType: gateway_notification_pb.GetNotificationsRequest,
    responseType: gateway_notification_pb.GetNotificationsResponse,
  };

}

export class NotificationToken {
  static readonly serviceName = "NotificationToken";

  static readonly updateDeviceToken = {
    methodName: "updateDeviceToken",
    service: NotificationToken,
    requestStream: false,
    responseStream: false,
    requestType: gateway_notification_pb.UpdateDeviceRequest,
    responseType: gateway_notification_pb.UpdateDeviceResponse,
  };

}

export class NotificationManagerClient {
  sendBroadcast() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  listen() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  streamNotifications() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  streamNotificationsControl() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  markViewed() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  markRead() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  loadUnviewedNotifications() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  getNotifications() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
}

export class NotificationTokenClient {
  updateDeviceToken() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
}
