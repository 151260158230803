import { MingaPermission } from 'libs/domain';

import { MingaAppRoutes } from '@app/src/app/types';

import { AccessRestrictionsGuard } from '@shared/guards';

import { IdRoute } from './constants';
import { IdComponent } from './id.component';

export const ID_ROUTES: MingaAppRoutes = [
  {
    path: '',
    component: IdComponent,
    data: {
      title: 'ID',
    },
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./components/id-dashboard').then(m => m.IdDashboardModule),
        canActivate: [AccessRestrictionsGuard],
        data: {
          title: 'Dashboard',
          enableAutoRedirect: true,
          navigationVisibility: true,
          accessRestrictions: {
            permissions: {
              atLeastOne: [
                MingaPermission.MINGA_PEOPLE_MANAGE,
                MingaPermission.ADMINISTER_STUDENT_ID,
                MingaPermission.VIEW_STUDENT_ID_DASHBOARD,
              ],
            },
          },
        },
      },
      {
        path: IdRoute.STICKERS,
        loadChildren: () =>
          import('./components/id-stickers').then(m => m.IdStickerssModule),
        canActivate: [AccessRestrictionsGuard],
        data: {
          title: 'Stickers',
          navigationVisibility: true,
          accessRestrictions: {
            permissions: {
              atLeastOne: [
                MingaPermission.MINGA_PEOPLE_MANAGE,
                MingaPermission.ADMINISTER_STUDENT_ID,
              ],
            },
          },
        },
      },
      {
        path: IdRoute.PHOTOS,
        loadChildren: () =>
          import('./components/id-photos').then(m => m.IdPhotosModule),
        canActivate: [AccessRestrictionsGuard],
        data: {
          title: 'Photos',
          navigationVisibility: true,
          accessRestrictions: {
            permissions: {
              atLeastOne: [
                MingaPermission.MINGA_PEOPLE_MANAGE,
                MingaPermission.ADMINISTER_STUDENT_ID,
              ],
            },
          },
        },
      },
      {
        path: IdRoute.SETTINGS,
        loadChildren: () =>
          import('./components/id-settings').then(m => m.IdSettingsModule),
        canActivate: [AccessRestrictionsGuard],
        data: {
          title: 'Settings',
          navigationVisibility: true,
          accessRestrictions: {
            permissions: {
              atLeastOne: [MingaPermission.MINGA_ACCOUNT_MANAGE],
            },
          },
        },
      },
    ],
  },
];
