import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';

import { MgIconModule } from '@app/src/app/icon';
import { RevHeroService } from '@app/src/app/services/RevHero';
import { MgSpinnerModule } from '@app/src/app/spinner';

import { FormModule } from '@shared/components/form';
import { GenericModule } from '@shared/components/generic';
import { ManagerOverlayModule } from '@shared/components/manager-overlay';
import { OverlayModule } from '@shared/components/modal-overlay';
import { TextModule } from '@shared/components/text';

import { MmSubscriptionFeatureModalComponent } from './components/mm-subscription-feature-modal/mm-subscription-feature-modal.component';
import { MmSubscriptionUpgradeModalComponent } from './components/mm-subscription-upgrade-modal/mm-subscription-upgrade-modal.component';
import { MmSubscriptionComponent } from './mm-subscription.component';
import { ConvertToPrettyNumberPipe } from './pipes/ConvertToPrettyNumber.pipe';

@NgModule({
  imports: [
    // Minga dependencies
    ManagerOverlayModule,
    FormModule,
    MgIconModule,
    GenericModule,
    TextModule,
    OverlayModule,
    MgSpinnerModule,

    // External dependencies
    CommonModule,
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule,
    MatIconModule,
    MatDialogModule,
  ],
  declarations: [
    MmSubscriptionComponent,
    MmSubscriptionFeatureModalComponent,
    MmSubscriptionUpgradeModalComponent,
    ConvertToPrettyNumberPipe,
  ],
  exports: [MmSubscriptionComponent],
  providers: [RevHeroService],
})
export class MmSubscriptionModule {}
