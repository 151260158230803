// GENERATED CODE -- DO NOT EDIT!

import {grpc} from '@improbable-eng/grpc-web';

import * as gateway_publisher_pb from '../gateway/publisher_pb';

export class Publisher {
  static readonly serviceName = "Publisher";

  static readonly republish = {
    methodName: "republish",
    service: Publisher,
    requestStream: false,
    responseStream: true,
    requestType: gateway_publisher_pb.RepublishRequest,
    responseType: gateway_publisher_pb.RepublishResponse,
  };

}

export class PublisherClient {
  republish() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
}
