import { Injectable } from '@angular/core';

import { firebase } from '@app/src/firebase';
import { getFileExtension, getFileTitle } from 'libs/util';

import { AppConfigService } from '@app/src/app/minimal/services/AppConfig';
import { MingaStoreFacadeService } from '@app/src/app/store/Minga/services';

import { retryWithBackoff } from '@shared/utils';

// enum that refers to the locations of different sizes
// of images for student ids, generated by cloud function
export enum StudentIdPresetSize {
  RAW = 'raw', // the unchanged image
  ID_FULL = 'id_full', // full size for full page id
  THUMB = 'thumb', // thumb for list page.
  AVATAR = 'avatar', // 95x95 size of the id
}

/**
 * Service for uploading student id images to firebase storage and
 * getting the url to student id images in firebase storage.
 */
@Injectable({ providedIn: 'root' })
export class StudentIdImageService {
  constructor(
    private appConfig: AppConfigService,
    private mingaService: MingaStoreFacadeService,
  ) {}
  /**
   * Upload the given file to firebase storage as the given student's id image.
   *
   * @param file
   * @param personHash
   * @returns
   */
  async uploadFile(file: File, personHash: string) {
    const extension = getFileExtension(file.name);
    const fileName = personHash + '.' + extension;

    const fileRef = await this._getRefPath(fileName);
    const metadata = {
      customMetadata: {
        personHash,
      },
    };
    const task = fileRef.put(file, metadata);
    return task;
  }

  async uploadBase64(file: string, name: string, personHash: string) {
    const extension = getFileExtension(name, true);
    const fileName = personHash + '.' + extension;

    const fileRef = await this._getRefPath(fileName);
    const metadata = {
      customMetadata: {
        personHash,
      },
    };
    const task = fileRef.putString(file, 'data_url', metadata);

    return task;
  }

  /**
   * Get the url to the file for displaying the image. Gets the download
   *  url (secured url) from firebase storage.
   *
   * @param fileName - the filename of the file we want the url to.
   * @returns the download url to the given image.
   */
  async getFilePathToStudentIdImage(
    fileName: string,
    preset: StudentIdPresetSize = StudentIdPresetSize.RAW,
  ): Promise<string> {
    let url = '';
    if (preset !== StudentIdPresetSize.AVATAR) {
      const fileRef = await this._getRefPath(fileName, preset);
      try {
        url = await fileRef.getDownloadURL();
      } catch (e) {
        // if the preset isn't found, use the raw size.
        if (preset !== StudentIdPresetSize.RAW) {
          url = await this.getFilePathToStudentIdImage(
            fileName,
            StudentIdPresetSize.RAW,
          );
        } else {
          // after trying the raw size if we still can't find the file, throw the error.
          throw e;
        }
      }
    } else {
      const filePath = await this._getFilePath(fileName, preset);
      const assetPrefix = new URL(await this.appConfig.getAssetUrlPrefix());

      url = assetPrefix + filePath;
      const testLink = await fetch(url, { cache: 'reload' });

      if (!testLink.ok) {
        url = await this.getFilePathToStudentIdImage(
          fileName,
          StudentIdPresetSize.RAW,
        );
      }
    }

    return url;
  }

  async getStudentIdImageAsBlob(
    fileName: string,
    preset: StudentIdPresetSize = StudentIdPresetSize.RAW,
  ): Promise<Blob> {
    if (!fileName) {
      return null;
    }
    let url = '';
    const fileRef = await this._getRefPath(fileName, preset);

    try {
      return await retryWithBackoff<Blob>(
        async () => {
          url = await fileRef.getDownloadURL();
          const result = await fetch(url, { cache: 'reload' });

          if (!result.ok) {
            throw new Error('Failed to fetch image');
          }

          return result.blob();
        },
        3,
        200,
      );
    } catch (e) {
      // if the preset isn't found, use the raw size.
      if (preset !== StudentIdPresetSize.RAW) {
        return await this.getStudentIdImageAsBlob(
          fileName,
          StudentIdPresetSize.RAW,
        );
      }

      // we hit here we've retried a bunch, time to fail
      throw e;
    }
  }

  /**
   * Get the firebase reference for the given file name.
   *
   * @param filename
   * @param preset
   * @returns firebase reference to the given file.
   */
  private async _getRefPath(
    filename: string,
    preset: StudentIdPresetSize = StudentIdPresetSize.RAW,
  ): Promise<firebase.storage.Reference> {
    const storage = await this._getStorage();
    const storageref = storage.ref();
    const filePath = await this._getFilePath(filename, preset);

    return storageref.child(filePath);
  }

  /**
   * Gets the generated file path under firebase storage for the file, using
   * the given preset. Files are stored in storage at path
   * <mingaHash>/<preset>/<personHash>.jpg
   *
   * @param filename
   * @param preset
   * @returns string that is the path in the bucket to look for.
   */
  private async _getFilePath(
    filename: string,
    preset: StudentIdPresetSize = StudentIdPresetSize.RAW,
  ): Promise<string> {
    const minga = await this.mingaService.getMingaAsPromise();
    const mingaHash = minga.hash;
    let folder = '';
    if (preset == StudentIdPresetSize.AVATAR) {
      folder = 'studentid/';
      const ext = getFileExtension(filename, true);
      const name = getFileTitle(filename);
      filename = name + '.' + ext;
    }
    return folder + mingaHash + '/' + preset + '/' + filename;
  }

  /**
   * Get a reference to firebase storage
   *
   * @returns Storage
   */
  private async _getStorage(): Promise<firebase.storage.Storage> {
    const idBucket = await this.appConfig.getIdBucket();
    const storage = firebase.app().storage('gs://' + idBucket);

    return storage;
  }
}
