import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  Input,
  OnDestroy,
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

import { BmReportsFilters } from 'libs/domain';
import {
  BehaviorSubject,
  Observable,
  ReplaySubject,
  combineLatest,
  from,
} from 'rxjs';
import { map, takeUntil, tap } from 'rxjs/operators';

import { Person } from '@app/src/app/people';
import { GradesService } from '@app/src/app/services/Grades';

import {
  ScheduledReportEditFormFields,
  getFilterGroupControlKey,
} from '@modules/minga-manager/components/mm-scheduled-reports/constants';
import {
  ClientSupportedScheduledReportFilter,
  ScheduledReportType,
} from '@modules/minga-manager/components/mm-scheduled-reports/types';

import { FormSelectOption } from '@shared/components/form';
import { UserListCategory } from '@shared/components/user-list-filter/user-list.types';
import { PbisService } from '@shared/services/pbis';

import { setIssuedOnPeopleSelect } from '../../utils/mm-sr-reports-edit.utils';
import {
  BehaviorReportMessages,
  BehaviorReportFields,
} from './sr-behavior-filters.constants';

@Component({
  selector: 'mg-sr-behavior-filters',
  templateUrl: './sr-behavior-filters.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SrBehaviorFiltersComponent implements OnInit, OnDestroy {
  private _destroyed$ = new ReplaySubject<void>(1);

  @Input() public form: UntypedFormGroup;
  @Input() defaultValues$: Observable<ClientSupportedScheduledReportFilter>;

  private _filtersSubject = new BehaviorSubject<
    Record<BehaviorReportFields, any>
  >(null);
  public filters$ = this._filtersSubject.asObservable();
  public PARENT_FIELDS = ScheduledReportEditFormFields;

  public UserListCategory = UserListCategory;

  private _defaultIssuedHashesSubject = new BehaviorSubject<string[]>([]);
  public defaultIssuedHashes$ = this._defaultIssuedHashesSubject.asObservable();
  public gradeOptions$ = this._gradesService.grades$.pipe(
    tap(() => this._gradesService.fetchIfNeeded()),
    map(grades => {
      return grades.map(grade => ({ label: grade, value: grade }));
    }),
  );
  public MESSAGES = BehaviorReportMessages;
  public FIELDS = BehaviorReportFields;
  public getFilterGroupControlKey = getFilterGroupControlKey;
  public filterGroup;
  public behaviorTypes$: Observable<FormSelectOption<any>[]> = this._pbisService
    .getTypes()
    .pipe(
      map(types => {
        return types.map(type => ({
          label: type.name,
          value: type.id,
        }));
      }),
    );

  constructor(
    private _pbisService: PbisService,
    private _gradesService: GradesService,
  ) {}

  ngOnInit(): void {
    // wait till we have all the dropdown data before setting values on it
    combineLatest([
      this.defaultValues$,
      this.gradeOptions$,
      this.behaviorTypes$,
    ])
      .pipe(takeUntil(this._destroyed$))
      .subscribe(([defaultValues]) => {
        this._onReady(defaultValues);
      });
  }

  ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  public onPersonSelected(people: Partial<Person>[]) {
    setIssuedOnPeopleSelect(people, this.form, {
      reportType: ScheduledReportType.PBIS_HISTORY,
      issuedToKey: this.FIELDS.ISSUED_TO,
      issuedByKey: this.FIELDS.ISSUED_BY,
    });
  }

  private _onReady(defaultValues: ClientSupportedScheduledReportFilter) {
    const {
      issuedTo = [],
      issuedBy = [],
      types = [],
      grades = [],
      userList = [],
    } = (defaultValues?.filters || {}) as BmReportsFilters;

    this._defaultIssuedHashesSubject.next([...issuedTo, ...issuedBy]);

    this._filtersSubject.next({
      [BehaviorReportFields.ISSUED_TO]: [issuedTo],
      [BehaviorReportFields.ISSUED_BY]: [issuedBy],
      [BehaviorReportFields.TYPE]: [types],
      [BehaviorReportFields.GRADE]: [grades],
      [BehaviorReportFields.USER_LIST]: [userList],
    });
  }
}
