import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable } from '@angular/material/table';
import { Router } from '@angular/router';

import {
  FlexTimeActivity,
  FlexTimeActivityInstance,
  FlexTimePeriod,
} from 'libs/domain';
import { BehaviorSubject, ReplaySubject, combineLatest, of } from 'rxjs';
import { map, shareReplay, takeUntil } from 'rxjs/operators';

import { AuthInfoService } from '@app/src/app/minimal/services/AuthInfo';
import { RootService } from '@app/src/app/minimal/services/RootService';

import {
  ActivityCardFields,
  ActivityListFilters,
} from '@modules/flex-time-manager/components/ftm-shared/ftm-shared.constants';

import { ConfirmationDialogComponent } from '@shared/components/confirmation-dialog';
import {
  MODAL_OVERLAY_DATA,
  ModalOverlayPrimaryHeaderBackground,
  ModalOverlayRef,
  ModalOverlayServiceCloseEventType,
} from '@shared/components/modal-overlay';
import {
  FlexTimeActivityInstanceService,
  FlexTimeActivityService,
  FlexTimePeriodService,
} from '@shared/services/flex-time';
import { FlexTimePermissionsService } from '@shared/services/flex-time/flex-time-permissions';
import { MediaService } from '@shared/services/media';
import { makeMessageFormGroup } from '@shared/utils/message-form-group';

import { FlexTimeManagerRoutes } from '../../../../constants';
import { FtmPeriodsActivityEditMessage } from '../../constants';
import {
  FtmPeriodsActivityEditModalData,
  FtmPeriodsActivityEditModalResponse,
} from '../../types';

@Component({
  selector: 'mg-ftm-periods-activity-edit',
  templateUrl: './ftm-periods-activity-edit.component.html',
  styleUrls: ['./ftm-periods-activity-edit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FtmPeriodsActivityEditComponent implements OnInit, OnDestroy {
  /** Child Components */
  @ViewChild(MatTable) matTable: MatTable<FlexTimeActivity>;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

  /** Constants */
  public readonly MSG = FtmPeriodsActivityEditMessage;
  public readonly MODAL_CONFIG = {
    headerBg: ModalOverlayPrimaryHeaderBackground.ALT_TEAL,
  };

  /** General Observables */
  private _destroyed = new ReplaySubject<void>(1);

  /** Teacher View */
  private _isAdminView = this._flexPermissionService.isFlexTimeAdmin();
  public readonly isAdminView$ = of(this._isAdminView).pipe(
    takeUntil(this._destroyed),
    shareReplay(),
  );

  /** Form */
  public readonly ftaInstanceForm = makeMessageFormGroup<
    FlexTimeActivityInstance,
    'flexTimeActivityId' | 'flexTimePeriodId' | 'flexTimeActivity'
  >(
    {
      flexTimePeriodId: {
        initialValue: this.dialogData.id,
        validators: [Validators.required],
      },
      flexTimeActivityId: {
        initialValue: undefined,
        validators: [],
      },
      flexTimeActivity: {
        initialValue: undefined,
        validators: [],
      },
    },
    this._fb,
  );

  private _filterChangeSubj = new BehaviorSubject<ActivityListFilters>({
    periodFilter: this.dialogData?.id || null,
    teacherFilter: this.dialogData.myActivities
      ? this._authInfoService.authPersonHash
      : '',
    searchFilter: '',
  });

  private _filterChange$ = this._filterChangeSubj.asObservable();

  public listDefaultValues = {
    periodId: this.dialogData.id || null,
    teacher: this.dialogData.myActivities
      ? this._authInfoService.authPerson
      : null,
  };

  /** FlexTime Period */
  private readonly _period = new BehaviorSubject<FlexTimePeriod>(null);
  public readonly periods$ = this._period.asObservable().pipe(
    shareReplay(),
    map(period => [period]),
  );
  private _isLoadingPeriodData = new BehaviorSubject<boolean>(false);
  public readonly isLoadingPeriodData$ =
    this._isLoadingPeriodData.asObservable();
  private _originalInstanceId: number;

  /** FlexTime Activities */
  private readonly _activities = new BehaviorSubject<
    FlexTimeActivity[] | undefined
  >(undefined);

  private readonly _activities$ = this._activities
    .asObservable()
    .pipe(shareReplay());
  private _isLoadingActivities = new BehaviorSubject<boolean>(false);
  public readonly isLoadingActivities$ =
    this._isLoadingActivities.asObservable();

  public filteredActivities$ = combineLatest([
    this._activities$,
    this._filterChange$,
  ]).pipe(
    map(([activities, filters]) => {
      const { searchFilter, teacherFilter } = filters;

      const filterFunctions = [];

      const filterByTeacher = a => a.createdByPerson.hash === teacherFilter;
      const filterBySearch = a =>
        a.name.toLowerCase().includes(searchFilter.trim().toLowerCase());

      if (teacherFilter) filterFunctions.push(filterByTeacher);
      if (searchFilter) filterFunctions.push(filterBySearch);

      const filtered = (activities || []).reduce((result, obj) => {
        if (filterFunctions.every(filterFn => filterFn(obj))) {
          result.push(obj);
        }
        return result;
      }, []);

      return filtered;
    }),
  );

  /** Loading */
  public readonly isLoading$ = combineLatest([
    this.isLoadingPeriodData$,
    this.isLoadingActivities$,
  ]).pipe(map(loading => loading.every(v => v)));

  public CARD_FIELDS = [
    ActivityCardFields.NAME,
    ActivityCardFields.DESCRIPTION,
    ActivityCardFields.LOCATION,
    ActivityCardFields.TEACHER,
    ActivityCardFields.SEATS,
  ];

  /** Component Constructor */
  constructor(
    @Inject(MODAL_OVERLAY_DATA)
    public dialogData: FtmPeriodsActivityEditModalData,
    private _modalRef: ModalOverlayRef<
      FtmPeriodsActivityEditModalResponse,
      FtmPeriodsActivityEditModalData
    >,
    public media: MediaService,
    private _dialog: MatDialog,
    private _fb: UntypedFormBuilder,
    private _router: Router,
    private _ftPeriod: FlexTimePeriodService,
    private _ftActivity: FlexTimeActivityService,
    private _ftActivityInstance: FlexTimeActivityInstanceService,
    private _authInfoService: AuthInfoService,
    private _flexPermissionService: FlexTimePermissionsService,
    private _rootService: RootService,
  ) {}

  ngOnInit(): void {
    this._getActivitiesData();
    this._getPeriodData(this.dialogData.id);
  }

  ngOnDestroy(): void {
    this._destroyed.next();
    this._destroyed.complete();
    this._period.complete();
    this._isLoadingActivities.complete();
    this._isLoadingPeriodData.complete();
    this._activities.complete();
  }

  public setActive(activity) {
    const { id } = activity;
    const idControl = this.ftaInstanceForm.controls.flexTimeActivityId;
    const activityControl = this.ftaInstanceForm.controls.flexTimeActivity;
    if (this._isAdminView) {
      idControl.setValue(id);
    } else {
      const newId = idControl.value === id ? undefined : id;
      idControl.setValue(newId);
    }
    activityControl.setValue(activity);
    idControl.markAsDirty();
    activityControl.markAsDirty();
  }

  public async submit(): Promise<void> {
    if (!this.ftaInstanceForm.canSubmit) return;
    const formPayload = this.ftaInstanceForm.getPayload();

    const period = this._period.value;
    let willReplace = false;

    if (
      this._authInfoService.authPersonHash !==
      formPayload.flexTimeActivity?.createdByPerson.hash
    ) {
      for (const activity of period.activityInstances) {
        if (activity.createdBy === formPayload.flexTimeActivity?.createdBy) {
          willReplace = true;
        }
      }
    }

    if (willReplace) {
      const confirmationDialog = this._dialog.open(
        ConfirmationDialogComponent,
        {
          data: {
            text: {
              description: `Choosing this activity will replace this teacher's other activity in this period. Are you sure you want to do that?`,
              deleteBtn: 'Confirm',
            },
          },
        },
      );
      confirmationDialog.afterClosed().subscribe(async response => {
        if (!response || !response.confirmed || response.cancelled) return;
        await this._rootService.addLoadingPromise(
          this._createInstance(formPayload),
        );
      });
    } else {
      await this._rootService.addLoadingPromise(
        this._createInstance(formPayload),
      );
    }
  }

  private async _createInstance(payload: FlexTimeActivityInstance) {
    await this._ftActivityInstance.create(payload).then(instance => {
      this._modalRef.close(ModalOverlayServiceCloseEventType.CREATE, {
        instance,
      });
    });
  }

  public async remove() {
    const confirmationDialog = this._dialog.open(ConfirmationDialogComponent, {
      data: {
        text: {
          description: `Are you sure you want to remove this activity?`,
          deleteBtn: 'Confirm',
        },
      },
    });
    confirmationDialog.afterClosed().subscribe(async response => {
      if (!response || !response.confirmed || response.cancelled) return;

      this._modalRef.close(ModalOverlayServiceCloseEventType.DELETE);
    });
  }

  public async cancel(): Promise<void> {
    const confirmationDialog = this._dialog.open(ConfirmationDialogComponent, {
      data: {
        text: {
          description: `Are you sure you want to discard the changes made to  this flex time period?`,
          deleteBtn: 'Confirm',
        },
      },
    });
    confirmationDialog.afterClosed().subscribe(async response => {
      if (!response || !response.confirmed || response.cancelled) return;
      this._modalRef.close(ModalOverlayServiceCloseEventType.CLOSE);
    });
  }

  public goToActivityTemplate() {
    this._modalRef.close(ModalOverlayServiceCloseEventType.NAVIGATE);
    this._router.navigate([
      './',
      FlexTimeManagerRoutes.ROOT,
      FlexTimeManagerRoutes.ACTIVITY_TEMPLATES,
    ]);
  }

  public onFilterChange(event) {
    this._filterChangeSubj.next(event);
  }

  private async _getActivitiesData(): Promise<void> {
    try {
      this._isLoadingActivities.next(true);
      const result = await this._ftActivity.fetchAll(
        this._isAdminView && !this.dialogData.myActivities
          ? undefined
          : this._authInfoService.authPersonHash,
      );
      this._activities.next([...result]);
    } catch (error) {
      this._modalRef.close(ModalOverlayServiceCloseEventType.CLOSE);
    } finally {
      this._isLoadingActivities.next(false);
    }
  }

  private async _getPeriodData(id: number): Promise<void> {
    if (!id) return;
    try {
      this._isLoadingPeriodData.next(true);
      this.ftaInstanceForm.controls.flexTimePeriodId.setValue(id);
      const results = await this._ftPeriod.fetch(id);
      if (
        results.activityInstances?.length &&
        this.dialogData?.currentInstanceId
      ) {
        const control = this.ftaInstanceForm.controls.flexTimeActivityId;
        const instance = results.activityInstances.find(
          a => a.id === this.dialogData?.currentInstanceId,
        );

        if (instance) {
          control.setValue(instance.id);
          this._originalInstanceId = instance.id;
        }
      }
      this._period.next(results);
    } catch (error) {
    } finally {
      this._isLoadingPeriodData.next(false);
    }
  }
}
