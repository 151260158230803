import * as Color from 'color';

export const lightenColor = (color: string, lighten: number): string => {
  return Color(color).hsl().lighten(lighten).string();
};

export const darkenColor = (color: string, darken: number): string => {
  return Color(color).hsl().darken(darken).string();
};

/**
 * More smarts then `lightenColor`, will look at color and apply different
 * levels of alpha, lightening and saturation based on the color
 * and how dark it is
 */
export const smartLightenColor = (color: string): string => {
  // for really dark colors we need to tweak the levels a bit more
  const LUMINOSTY_THRESHOLD = 0.05;

  const colorObj = Color(color);

  const IS_DARK = colorObj.isDark();
  const IS_LOW_LUMINOSITY = colorObj.luminosity() < LUMINOSTY_THRESHOLD;
  const lighten = IS_DARK ? 0.7 : 0.2;
  const alpha = IS_DARK ? (IS_LOW_LUMINOSITY ? 0.05 : 0.15) : 0.2;
  const saturation = IS_DARK ? 0.5 : null;

  return Color(color)
    .alpha(alpha)
    .lighten(lighten)
    .saturate(saturation)
    .hsl()
    .toString();
};
