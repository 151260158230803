import { checkin_pb } from 'libs/generated-grpc-ts';
import { flex_time_stats_pb } from 'libs/generated-grpc-ts';
import { hall_pass_stats_pb } from 'libs/generated-grpc-ts';
import { pbis_stats_pb } from 'libs/generated-grpc-ts';
import { points_pb } from 'libs/generated-grpc-ts';
import { DayOfWeekEnum } from 'libs/util';

import {
  BmReportsFilters,
  CheckinManagerReportFilters,
  FtmReportFilters,
  HpmReportsFilters,
  PmReportsFilters,
} from '../';
import { ReportTypes } from '../';

export enum ScheduledReportFrequency {
  'Daily',
  'Weekly',
}

export type ScheduledReportDeliveryType = 'email' | 'sftp';

export type CheckinReportType =
  | ReportTypes.CHECKIN
  | ReportTypes.CHECKIN_ABSENTEES
  | ReportTypes.CHECKIN_HISTORY;

export type PointsReportType =
  | ReportTypes.POINTS_HISTORY
  | ReportTypes.POINTS_SUMMARY
  | ReportTypes.POINTS_TEAM_SUMMARY
  | ReportTypes.POINTS_REWARDS_REDEEMED
  | ReportTypes.POINTS_REDEEMED;

export type HallPassReportType =
  | ReportTypes.HALL_PASS_HISTORY
  | ReportTypes.HALL_PASS_OVERDUE
  | ReportTypes.HALL_PASS_STUDENT
  | ReportTypes.HALL_PASS_STAFF
  | ReportTypes.HALL_PASS_SUMMARY
  | ReportTypes.HALL_PASS_TYPES;

export type PbisReportType =
  | ReportTypes.PBIS_CONS_HISTORY
  | ReportTypes.PBIS_CONS_OVERDUE
  | ReportTypes.PBIS_CONS_TYPES
  | ReportTypes.PBIS_HISTORY
  | ReportTypes.PBIS_STAFF
  | ReportTypes.PBIS_STUDENT
  | ReportTypes.PBIS_SUMMARY
  | ReportTypes.PBIS_TYPES
  | ReportTypes.PBIS_AUTOMATION_COUNT;

export type FlexReportType =
  | ReportTypes.FLEX_PERIOD_REGISTERED
  | ReportTypes.FLEX_PERIOD_UNREGISTERED
  | ReportTypes.FLEX_ACTIVITIES
  | ReportTypes.FLEX_STUDENTS
  | ReportTypes.FLEX_STAFF;

/**
 * Discriminated union to type guard filters.
 */
export type ScheduledReportFilters =
  | {
      reportType: CheckinReportType;
      filters: CheckinManagerReportFilters;
    }
  | {
      reportType: PointsReportType;
      filters: PmReportsFilters;
    }
  | {
      reportType: HallPassReportType;
      filters: HpmReportsFilters;
    }
  | {
      reportType: PbisReportType;
      filters: BmReportsFilters;
    }
  | {
      reportType: FlexReportType;
      filters: FtmReportFilters;
    };

/**
 * This doesn't need to be a discrimated union because of how the
 * backend uses it.
 */
export type ScheduledReportFilterMessage =
  | points_pb.PointsReportFilters
  | hall_pass_stats_pb.HallPassReportFilters
  | flex_time_stats_pb.FlexReportFilters
  | pbis_stats_pb.PbisReportFilters
  | checkin_pb.CheckInReportFilters;

export type ScheduledReport = {
  active: boolean;
  createdBy: string;
  id: number;
  name: string;
  frequency: ScheduledReportFrequency;
  /** @todo change to template literal type string after we upgrade to TS 4.1+ */
  time: string;
  deliveryType: ScheduledReportDeliveryType[];
  day?: DayOfWeekEnum;
  email?: string[];
  sftpHost?: string;
  sftpUserName?: string;
  sftpPassword?: string;
  createdById: number;
  createdAt?: Date;
} & ScheduledReportFilters;

export type ScheduledReportMinimal = Omit<
  ScheduledReport,
  'filters' | 'email' | 'sftpHost' | 'sftpUserName' | 'sftpPassword'
>;

export type ScheduledReportUnmapped = Omit<ScheduledReport, 'filters'> & {
  filters: ScheduledReportFilterMessage;
};
