import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  TrackByFunction,
} from '@angular/core';

import { gateway } from 'libs/generated-grpc-web';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { GalleryServiceBase } from '@app/src/app/routes/gallery/services/GalleryServiceBase';
import { MingaGalleryService } from '@app/src/app/routes/gallery/services/MingaGallery';

@Component({
  selector: 'mg-photo-gallery-preview-empty',
  template: '<ng-content></ng-content>',
})
export class PhotoGalleryPreviewSlot_Empty {}

@Component({
  selector: 'mg-photo-gallery-preview-loading',
  template: '<ng-content></ng-content>',
})
export class PhotoGalleryPreviewSlot_Loading {}

@Component({
  selector: 'mg-photo-gallery-preview',
  templateUrl: './PhotoGalleryPreview.component.html',
  styleUrls: ['./PhotoGalleryPreview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PhotoGalleryPreviewComponent {
  trackBy: TrackByFunction<gateway.gallery_pb.GalleryItem.AsObject>;

  // @NOTE: If you change this value make sure you change it in
  // PhotoGalleryPreview.component.scss as well
  readonly _rowHeight = 63;

  newPhotosCount$!: Observable<number>;
  newPhotosCountDisplay$!: Observable<string>;
  newestPhotosList$!: Observable<gateway.gallery_pb.GalleryItem.AsObject[]>;
  hasNewPhotos$!: Observable<boolean>;
  loading$!: Observable<boolean>;
  maxAspectRatio$!: Observable<number>;
  minAspectRatio$!: Observable<number>;

  galleryService?: GalleryServiceBase;

  @Input('galleryService')
  set setGalleryService(service: GalleryServiceBase) {
    this.galleryService = service;
    // fetch new data
    this.galleryService.fetchSummary();
    // init observers
    this._initObservers();
  }

  constructor(private cdr: ChangeDetectorRef) {
    this.trackBy = (
      index: number,
      item: gateway.gallery_pb.GalleryItem.AsObject,
    ) => {
      return item.galleryPhotoUuid;
    };
  }

  private _initObservers() {
    if (!this.galleryService) {
      throw new Error('Gallery service not provided to component');
    }
    this.newPhotosCount$ = this.galleryService.summary$.pipe(
      map(summary => summary.newPhotosCount),
    );

    this.minAspectRatio$ = this.galleryService.summary$.pipe(
      map(summary => summary.photoAspectRatioPerRow),
    );
    this.maxAspectRatio$ = this.minAspectRatio$.pipe(map(min => min + 2));
    this.newPhotosCountDisplay$ = this.newPhotosCount$.pipe(
      map(count => {
        if (count > 99) {
          return '99+';
        } else if (count <= 0) {
          return '';
        } else {
          return `+${count}`;
        }
      }),
    );
    this.newestPhotosList$ = this.galleryService.summary$.pipe(
      map(summary => summary.newestPhotosList),
    );
    this.hasNewPhotos$ = this.newestPhotosList$.pipe(
      map(photos => photos.length > 0),
    );
    this.loading$ = this.galleryService.loading$;
  }

  getTileWidth(tile: gateway.gallery_pb.GalleryItem.AsObject): number {
    const imageInfo = tile.imageInfo;
    const sizeMap = imageInfo?.sizeMap || [];
    return sizeMap[0][1]?.width || 0;
  }

  getTileHeight(tile: gateway.gallery_pb.GalleryItem.AsObject): number {
    const imageInfo = tile.imageInfo;
    const sizeMap = imageInfo?.sizeMap || [];
    return sizeMap[0][1]?.height || 0;
  }
}
