import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { MediaObserver } from '@angular/flex-layout';

import { gateway } from 'libs/generated-grpc-web';
import { Subscription } from 'rxjs';

import { ICommentDisplay } from '@app/src/app/content-views/components/CommentView';
import { CommentInputComponent } from '@app/src/app/inputs';
import { MENTION_CHAR } from '@app/src/app/mentions/constants';
import {
  ContentEvents,
  ICommentSuccessEvent,
} from '@app/src/app/minimal/services/ContentEvents';
import { ContentState } from '@app/src/app/services/ContentState';
import { scrollIntoView } from '@app/src/app/util/scroll-into-view';

const DEFAULT_REPLY_PLACEHOLDER = 'input.comment.placeholder';
const DEFAULT_REPLY_BUTTON = 'button.post';

@Component({
  selector: 'mg-comment-list-view',
  templateUrl: './CommentListView.component.html',
  styleUrls: ['./CommentListView.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CommentListViewComponent
  implements OnInit, AfterViewInit, OnDestroy, OnChanges
{
  @Input()
  comments: ICommentDisplay[] = [];

  @Input()
  canComment: boolean = false;

  @Input()
  commentCount: number = 0;

  @Input()
  contextHash: string = '';

  @Input()
  disableActions: boolean = false;

  @Input()
  useFixedCommentInput: boolean = true;

  @Output()
  commentCountUpdate: EventEmitter<number> = new EventEmitter();

  @Output()
  commentsChange: EventEmitter<ICommentDisplay[]> = new EventEmitter();

  loadingComments: boolean = false;
  replyHash: string = '';
  replyPlaceholder: string = DEFAULT_REPLY_PLACEHOLDER;
  replyButtonText: string = DEFAULT_REPLY_BUTTON;
  replyParent: string = '';
  commentPrefix: string = '';
  currentCommentsPage: number = -1;

  commentEventsSubscription: Subscription;

  @ViewChild('commentListEl', { static: false })
  commentListEl?: ElementRef;

  @ViewChild('commentInputMobileEl', { static: false })
  commentInputMobile?: CommentInputComponent;

  @ViewChild('commentInputDesktopEl', { static: false })
  commentInputDesktop?: CommentInputComponent;

  mqAlias: string = '';
  mqAliasSubscription: Subscription;

  constructor(
    private commentManager: gateway.comment_ng_grpc_pb.CommentManager,
    private contentEvents: ContentEvents,
    private contentState: ContentState,
    public media: MediaObserver,
    private _cdr: ChangeDetectorRef,
  ) {
    this.mqAliasSubscription = media.asObservable().subscribe(mediaChanges => {
      this.mqAlias = mediaChanges[0].mqAlias;
    });

    this.commentEventsSubscription =
      this.contentEvents.onCommentSuccess.subscribe(e => {
        this.onCommentSuccess(e);
      });
  }

  ngOnDestroy(): void {
    if (this.commentEventsSubscription) {
      this.commentEventsSubscription.unsubscribe();
    }
    if (this.mqAliasSubscription) {
      this.mqAliasSubscription.unsubscribe();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.contextHash) {
      // update replyHash when contextHash is changed
      this.replyHash = this.contextHash;
    }
  }

  get remainingCommentCount() {
    return this.commentCount - this.countComments();
  }

  countComments(): number {
    let count = 0;
    for (const comment of this.comments) {
      count += 1;
      const childCount = comment.obj.commentList.length;
      count += childCount;
    }

    return count;
  }

  ngOnInit() {
    this.replyHash = this.contextHash;
  }

  replyClick(e?: any) {
    this.focusCommentInput(e);
  }

  cancelComment(e?: any) {
    this.resetCommentInput();
  }

  readonly _trackByHash = (index: number, item: ICommentDisplay) => {
    return item.obj.commentContextHash;
  };

  _onContentDeleted(ev: string) {
    for (let i = 0; i < this.comments.length; i++) {
      const comment = this.comments[i];
      // this will handle a top level comment only
      if (comment && comment.obj && comment.obj.commentContextHash == ev) {
        this.comments.splice(i, 1);
        this.commentCount -= 1;
        this.commentCountUpdate.emit(this.commentCount);
        this.commentsChange.emit(this.comments);
      }
      // if the comment we are removing is a child comment
      // then we have to look at the commentLists too
      if (comment.obj.commentList.length > 0) {
        for (let i = 0; i < comment.obj.commentList.length; i++) {
          const childComment = comment.obj.commentList[i];
          if (childComment && childComment.commentContextHash == ev) {
            comment.obj.commentList.splice(i, 1);
            this.commentCount -= 1;
            this.commentCountUpdate.emit(this.commentCount);
            this.commentsChange.emit(this.comments);
          }
        }
      }
    }
    // trigger change detection
    this.comments = [...this.comments];
    this._cdr.markForCheck();
  }

  onCommentSuccess(ev: ICommentSuccessEvent) {
    if (this.contextHash === ev.parentContentContextHash) {
      this.commentCount += 1;

      let newComment: ICommentDisplay = {
        anim: 'fade',
        obj: {
          commentContextHash: ev.newCommentContextHash,
          timestamp: Date.now(),
          text: ev.newCommentBody,
          authorPersonView: ev.newCommentPersonView,
          likeCount: 0,
          commentListPage: -1,
          deleted: false,
          hasLiked: false,

          // These are deprecated
          authorAvatarUrl: '',
          authorDisplayName: '',
          textMentionRangeList: [],
          commentList: [],
          commentCount: 0,
        },
        parentContextHash: this.contextHash,
      };

      if (this.contextHash == ev.replyContentContextHash) {
        this.comments.push(newComment);
        this.commentCountUpdate.emit(this.commentCount);
        this.commentsChange.emit(this.comments);
      } else {
        for (let comment of this.comments) {
          if (
            comment.obj.commentContextHash == ev.replyContentContextHash ||
            comment.obj.commentContextHash == ev.parentContentContextHash
          ) {
            const list = [...comment.obj.commentList];
            comment.obj.commentCount += 1;
            list.push(newComment.obj);
            comment.obj.commentList = list;
            this.commentCountUpdate.emit(this.commentCount);
            this.commentsChange.emit(this.comments);
            break;
          }
        }
      }
      this.comments = [...this.comments];
    }
  }

  // Reset comment to the state of commenting on this long card
  resetCommentInput() {
    this.commentPrefix = '';
    this.replyPlaceholder = DEFAULT_REPLY_PLACEHOLDER;
    this.replyButtonText = DEFAULT_REPLY_BUTTON;
    this.replyHash = this.contextHash;
  }

  async focusCommentInput(comment: ICommentDisplay | null = null) {
    this.commentPrefix = '';
    if (comment && comment.obj.commentContextHash) {
      this.replyParent = this.contextHash;
      this.replyPlaceholder = 'input.comment.placeholderReply';
      this.replyButtonText = 'button.reply';
      let personView = comment.obj.authorPersonView;

      if (personView) {
        let tagString = MENTION_CHAR + personView.displayName;
        this.commentPrefix = `<mg-mention hash="${personView.personHash}">${tagString}</mg-mention>`;
      }
      // set reply after changing commentPrefix so that commentInput ngOnChanges
      // occurs to get new value before focus()
      this.replyHash =
        comment.parentContextHash || comment.obj.commentContextHash;
    } else {
      this.replyPlaceholder = DEFAULT_REPLY_PLACEHOLDER;
      this.replyButtonText = DEFAULT_REPLY_BUTTON;
      this.replyHash = this.contextHash;
    }

    setTimeout(() => {
      if (this.mqAlias != 'xs' && this.commentInputDesktop) {
        this.commentInputDesktop.focus();
      } else if (this.commentInputMobile) {
        this.commentInputMobile.focus();
      }
    });
  }

  ngAfterViewInit() {
    let focusedElsewhere = false;
    const consumedScrollIntoView = this.contentState.consume(
      this.contextHash,
      'commentsIntoView',
    );

    if (consumedScrollIntoView) {
      this.scrollCommentsIntoView();
      focusedElsewhere = true;
    }

    const consumedCommentInputFocus = this.contentState.consume(
      this.contextHash,
      'commentInputFocus',
    );

    if (consumedCommentInputFocus) {
      this.focusCommentInput();
      focusedElsewhere = true;
    }

    const consumedCommentReplyInputFocus = this.contentState.consume(
      this.contextHash,
      'commentReplyInputFocus',
    );

    if (consumedCommentReplyInputFocus) {
      for (let comment of this.comments) {
        if (
          this.contentState.consume(
            comment.obj.commentContextHash,
            'commentInputFocus',
          )
        ) {
          this.focusCommentInput(comment);
          focusedElsewhere = true;
        }
      }
    }

    // click on overlay-nav or this element to fix iOS focus on <html> and user
    // stuck..
    if (!focusedElsewhere) {
      const overlayNav = document.getElementsByClassName('overlay-wrap');
      if (overlayNav && overlayNav.length) {
        scrollIntoView(
          // @TODO: @types/scroll-into-view disagree with these typings
          <any>overlayNav[0],
          {
            time: 0, // default is half a second
          },
        );
      } else {
        // scrollIntoView(this.element.nativeElement);
      }
    }
  }

  private removeLocalComment(commentHash: string) {
    let commentIndex = this.comments.findIndex(comment => {
      return comment.obj.commentContextHash === commentHash;
    });

    if (commentIndex > -1) {
      this.comments.splice(commentIndex, 1);
      return true;
    } else {
      return false;
    }
  }

  async nextComments() {
    this.loadingComments = true;
    this.currentCommentsPage += 1;
    let request = new gateway.comment_pb.FetchCommentsRequest();
    request.setContextHash(this.contextHash);
    request.setPage(this.currentCommentsPage);
    let response = await this.commentManager
      .fetchComments(request)
      .catch(err => {
        this.loadingComments = false;
        return Promise.reject(err);
      });

    let commentList = response.getCommentList();
    let removedComments = 0;

    let newComments: ICommentDisplay[] = [];
    for (let comment of commentList) {
      let existed = this.removeLocalComment(comment.getCommentContextHash());
      newComments.push({
        anim: 'fade',
        obj: comment.toObject(),
        parentContextHash: this.contextHash,
      });

      if (existed) {
        removedComments += 1;
      }
    }
    this.comments = newComments.concat(this.comments);

    if (removedComments && removedComments == commentList.length) {
      await this.nextComments();
    }

    this.loadingComments = false;
    this._cdr.markForCheck();
  }

  scrollCommentsIntoView() {
    if (this.commentListEl) {
      let el = this.commentListEl.nativeElement;
      scrollIntoView(el, {});
    }

    return false;
  }
}
