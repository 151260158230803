import { NgModule } from '@angular/core';

import { MessageDialogModule } from '@app/src/app/minimal/components/MessageDialog';

import { MingaSnackService } from './MingaSnack.service';

@NgModule({
  imports: [MessageDialogModule],
  providers: [MingaSnackService],
})
export class MingaSnackModule {}
