import { INewNotification } from 'libs/domain';
import { INewNotificationMapper } from 'libs/shared-grpc';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { RealtimeDataConsumer, RealtimeDataSender } from './';

const REALTIME_NEW_NOTIFICATION_KEY = 'notification';

export class RealtimeNotificationConsumer {
  readonly newNotification$: Observable<INewNotification>;

  constructor(_dataConsumer: RealtimeDataConsumer) {
    this.newNotification$ = _dataConsumer.messageNotice$.pipe(
      map(payload => payload[REALTIME_NEW_NOTIFICATION_KEY]),
      filter(newDm => typeof newDm !== 'undefined'),
      map(msgBin => msgBin as string),
      map(INewNotificationMapper.fromBase64),
    );
  }
}

export class RealtimeNotificationSender {
  constructor(private _dataSender: RealtimeDataSender) {}

  async sendNewMessageNotice(message: INewNotification): Promise<void> {
    const payload = {
      [REALTIME_NEW_NOTIFICATION_KEY]: INewNotificationMapper.toBase64(message),
    };

    await this._dataSender.send(payload);
  }
}
