import { gateway } from 'libs/generated-grpc-web';

import { IMingaFile, MingaFiles } from '@app/src/app/models/MingaFiles';

export interface GroupFiles {
  hash: string;
  files: IMingaFile[];
}

export interface IGroupFile extends IMingaFile {}

export namespace GroupFiles {
  export function fromMessage(
    msg: gateway.mingaGroup_pb.GroupFiles,
  ): GroupFiles {
    return {
      hash: msg.getHash(),
      files: msg.getFilesList().map(file => {
        const fileObj = file.toObject();
        return {
          file: fileObj,
          status: { fileKey: getStatusKey(fileObj) },
        };
      }),
    };
  }

  // complete file name with extension
  export function getStatusKey(
    groupFile:
      | gateway.file_pb.FileDetails.AsObject
      | gateway.content_views_pb.ShortFileCardView.AsObject,
  ) {
    return MingaFiles.getStatusKey(groupFile);
  }
}
