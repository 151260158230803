import { MingaRoleType } from 'libs/domain';
import { IReportPerson } from 'libs/domain';
import { report_person_pb } from 'libs/generated-grpc-ts';
import { $enum } from 'ts-enum-util';

export const toProto = (
  person: IReportPerson,
): report_person_pb.ReportPerson => {
  const proto = new report_person_pb.ReportPerson();
  proto.setId(person.id);
  proto.setFirstName(person.firstName);
  proto.setLastName(person.lastName);
  proto.setEmail(person.email);
  proto.setGrade(person.grade);
  proto.setRoleType(person.roleType);
  proto.setPersonHash(person.personHash);
  proto.setStudentId(person.studentId);
  return proto;
};

export const fromProto = (
  proto: report_person_pb.ReportPerson,
): IReportPerson => {
  const roleType = $enum(MingaRoleType).asValueOrThrow(proto.getRoleType());
  return {
    id: proto.getId(),
    firstName: proto.getFirstName(),
    lastName: proto.getLastName(),
    email: proto.getEmail(),
    grade: proto.getGrade(),
    roleType,
    personHash: proto.getPersonHash(),
    studentId: proto.getStudentId(),
  };
};
