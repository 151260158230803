import { ChangeDetectionStrategy, Component } from '@angular/core';

import { FtmReportsActivityDatasourceService } from '../../services';

@Component({
  selector: 'mg-ftm-reports-activity',
  templateUrl: './ftm-reports-activity.component.html',
  styleUrls: ['./ftm-reports-activity.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [FtmReportsActivityDatasourceService],
})
export class FtmReportsActivityComponent {
  constructor(public ds: FtmReportsActivityDatasourceService) {}
}
