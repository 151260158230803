import { Component, Input, OnInit } from '@angular/core';

import { IMembershipList, MembershipListType } from 'libs/domain';
import { BehaviorSubject } from 'rxjs';

import { StickerBannersService } from '@modules/id/components/id-stickers/services';
import { ToolsStickersMessage } from '@modules/tools/tools.constants';

import { ModalOverlayService } from '@shared/components/modal-overlay';
import { SystemAlertSnackBarService } from '@shared/components/system-alert-snackbar';
import { MediaService } from '@shared/services/media';
import { StickersService } from '@shared/services/stickers';

import { ToolsStickersViewModalComponent } from '../tools-stickers-view-modal/tools-stickers-view-modal.component';

@Component({
  selector: 'mg-tools-stickers',
  templateUrl: './tools-stickers.component.html',
  styleUrls: ['./tools-stickers.component.scss'],
})
export class ToolsStickersComponent implements OnInit {
  /** Constants */
  public readonly MESSAGES = ToolsStickersMessage;

  /** Inputs */
  @Input() personHash: string;

  /** Observables */
  private _stickersSubject = new BehaviorSubject<IMembershipList[]>([]);
  public readonly stickers$ = this._stickersSubject.asObservable();
  private _loadingSubject = new BehaviorSubject(false);
  public readonly loading$ = this._loadingSubject.asObservable();

  /** Visible Stickers */
  public visibleStickers: IMembershipList[] = [];

  /** Constructor */
  constructor(
    public mediaService: MediaService,
    private _stickerBannerService: StickerBannersService,
    private _stickerService: StickersService,
    private _systemAlertSnackBar: SystemAlertSnackBarService,
    private _modalOverlay: ModalOverlayService,
  ) {}

  public ngOnInit(): void {
    this._fetchStickers();
  }

  public getStickerPath(item: IMembershipList) {
    return this._stickerBannerService.getStickerPath(item);
  }

  public openViewModal() {
    this._modalOverlay.open(ToolsStickersViewModalComponent, {
      data: {
        personHash: this.personHash,
        stickers: this._stickersSubject.value,
      },
    });
  }

  private async _fetchStickers(): Promise<void> {
    try {
      if (!this.personHash) {
        return;
      }

      this._loadingSubject.next(true);
      const response = await this._stickerService.getStickersByPerson(
        this.personHash,
      );
      response.sort((a, b) =>
        a.listType === MembershipListType.NO_ACCESS
          ? -1
          : b.listType === MembershipListType.NO_ACCESS
          ? 1
          : 0,
      );
      this._stickersSubject.next(response);
    } catch (e) {
      this._systemAlertSnackBar.error(
        ToolsStickersMessage.ERROR_LOADING_STICKERS,
      );
    } finally {
      this._loadingSubject.next(false);
    }
  }
}
