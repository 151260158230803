import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ParentGroupPickerModule } from '@app/src/app/groups/containers/ParentGroupPicker';
import { PermissionsModule } from '@app/src/app/permissions';

import { DefaultParentGroupPickerComponent } from './DefaultParentGroupPicker.component';

@NgModule({
  imports: [
    // Minga dependencies
    ParentGroupPickerModule,
    PermissionsModule,
    // External dependencies
    CommonModule,
  ],
  declarations: [DefaultParentGroupPickerComponent],
  exports: [DefaultParentGroupPickerComponent],
})
export class DefaultParentGroupPickerModule {}
