import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { FileModule } from '@app/src/app/file';
import { MessageDialogModule } from '@app/src/app/minimal/components/MessageDialog';

import { FileUploadComponent } from './FileUpload.component';

@NgModule({
  imports: [
    // Minga dependencies
    FileModule,
    MessageDialogModule,

    // External dependencies
    CommonModule,
    MatProgressSpinnerModule,
    MatIconModule,
  ],
  declarations: [FileUploadComponent],
  exports: [FileUploadComponent],
})
export class FileUploadModule {}
