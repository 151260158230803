// source: gateway/gallery.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

import * as jspb from 'google-protobuf';
import * as common_stream_pb from '../common/stream_pb';
import * as image_image_pb from '../image/image_pb';
import * as gateway_moderation_pb from '../gateway/moderation_pb';
export namespace Tag {
  export interface AsObject {
    personTag?: Tag.PersonTag.AsObject;
    groupTag?: Tag.GroupTag.AsObject;
    contentTag?: Tag.ContentTag.AsObject;
  }
}
export class Tag extends jspb.Message {
  static readonly displayName = "Tag";
  /**
   * Oneof group definitions for this message. Each group defines the field
   * numbers belonging to that group. When of these fields' value is set, all
   * other fields in the group are cleared. During deserialization, if multiple
   * fields are encountered for a group, only the last value seen will be kept.
   * @private {!Array<!Array<number>>}
   * @const
   */
  static readonly oneofGroups_ = [[3,4,5]];


  /**
   * @return {Tag.TagCase}
   */
  getTagCase() {
    return /** @type {Tag.TagCase} */(jspb.Message.computeOneofCase(this, Tag.oneofGroups_[0]));
  }

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, Tag.oneofGroups_);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): Tag.AsObject {
    return Tag.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!Tag} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: Tag) {
    var f: any;
    var obj: any = {
      personTag: (f = msg.getPersonTag()) && Tag.PersonTag.toObject(includeInstance, f),
      groupTag: (f = msg.getGroupTag()) && Tag.GroupTag.toObject(includeInstance, f),
      contentTag: (f = msg.getContentTag()) && Tag.ContentTag.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!Tag}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new Tag;
    return Tag.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!Tag} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!Tag}
   */
  static deserializeBinaryFromReader(msg: Tag, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 3:
        var value3 = new Tag.PersonTag;
        reader.readMessage(value3,Tag.PersonTag.deserializeBinaryFromReader);
        msg.setPersonTag(value3);
        break;
      case 4:
        var value4 = new Tag.GroupTag;
        reader.readMessage(value4,Tag.GroupTag.deserializeBinaryFromReader);
        msg.setGroupTag(value4);
        break;
      case 5:
        var value5 = new Tag.ContentTag;
        reader.readMessage(value5,Tag.ContentTag.deserializeBinaryFromReader);
        msg.setContentTag(value5);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!Tag} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: Tag, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getPersonTag();
    if (f != null) {
      writer.writeMessage(
        3,
        f,
        Tag.PersonTag.serializeBinaryToWriter
      );
    }
    f = message.getGroupTag();
    if (f != null) {
      writer.writeMessage(
        4,
        f,
        Tag.GroupTag.serializeBinaryToWriter
      );
    }
    f = message.getContentTag();
    if (f != null) {
      writer.writeMessage(
        5,
        f,
        Tag.ContentTag.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    Tag.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional PersonTag person_tag = 3;
   * @return {?Tag.PersonTag}
   */
  getPersonTag(): Tag.PersonTag {
    return /** @type{?Tag.PersonTag} */ (
      jspb.Message.getWrapperField(this, Tag.PersonTag, 3));
  }


  /** @param {?Tag.PersonTag|undefined} value */
  setPersonTag(value?: Tag.PersonTag) {
    jspb.Message.setOneofWrapperField(this, 3, Tag.oneofGroups_[0], value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearPersonTag() {
    this.setPersonTag(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasPersonTag(): boolean {
    return jspb.Message.getField(this, 3) != null;
  }


  /**
   * optional GroupTag group_tag = 4;
   * @return {?Tag.GroupTag}
   */
  getGroupTag(): Tag.GroupTag {
    return /** @type{?Tag.GroupTag} */ (
      jspb.Message.getWrapperField(this, Tag.GroupTag, 4));
  }


  /** @param {?Tag.GroupTag|undefined} value */
  setGroupTag(value?: Tag.GroupTag) {
    jspb.Message.setOneofWrapperField(this, 4, Tag.oneofGroups_[0], value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearGroupTag() {
    this.setGroupTag(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasGroupTag(): boolean {
    return jspb.Message.getField(this, 4) != null;
  }


  /**
   * optional ContentTag content_tag = 5;
   * @return {?Tag.ContentTag}
   */
  getContentTag(): Tag.ContentTag {
    return /** @type{?Tag.ContentTag} */ (
      jspb.Message.getWrapperField(this, Tag.ContentTag, 5));
  }


  /** @param {?Tag.ContentTag|undefined} value */
  setContentTag(value?: Tag.ContentTag) {
    jspb.Message.setOneofWrapperField(this, 5, Tag.oneofGroups_[0], value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearContentTag() {
    this.setContentTag(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasContentTag(): boolean {
    return jspb.Message.getField(this, 5) != null;
  }


}
export namespace Tag {
export enum TagCase {
  TAG_NOT_SET = 0,
  PERSON_TAG = 3,
  GROUP_TAG = 4,
  CONTENT_TAG = 5,
}
} // namespace Tag
export namespace Tag {
export namespace PersonTag {
  export interface AsObject {
    personHash: string;
    profileImageUrl: string;
    displayName: string;
    roleType: string;
  }
}
export class PersonTag extends jspb.Message {
  static readonly displayName = "Tag.PersonTag";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): PersonTag.AsObject {
    return PersonTag.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!PersonTag} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: PersonTag) {
    var f: any;
    var obj: any = {
      personHash: jspb.Message.getFieldWithDefault(msg, 1, ""),
      profileImageUrl: jspb.Message.getFieldWithDefault(msg, 2, ""),
      displayName: jspb.Message.getFieldWithDefault(msg, 3, ""),
      roleType: jspb.Message.getFieldWithDefault(msg, 4, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!PersonTag}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new PersonTag;
    return PersonTag.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!Tag.PersonTag} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!Tag.PersonTag}
   */
  static deserializeBinaryFromReader(msg: PersonTag, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setPersonHash(value1);
        break;
      case 2:
        var value2 = /** @type {string} */ (reader.readString());
        msg.setProfileImageUrl(value2);
        break;
      case 3:
        var value3 = /** @type {string} */ (reader.readString());
        msg.setDisplayName(value3);
        break;
      case 4:
        var value4 = /** @type {string} */ (reader.readString());
        msg.setRoleType(value4);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!Tag.PersonTag} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: Tag.PersonTag, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getPersonHash();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
    f = message.getProfileImageUrl();
    if (f.length > 0) {
      writer.writeString(
        2,
        f
      );
    }
    f = message.getDisplayName();
    if (f.length > 0) {
      writer.writeString(
        3,
        f
      );
    }
    f = message.getRoleType();
    if (f.length > 0) {
      writer.writeString(
        4,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    Tag.PersonTag.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string person_hash = 1;
   * @return {string}
   */
  getPersonHash(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setPersonHash(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


  /**
   * optional string profile_image_url = 2;
   * @return {string}
   */
  getProfileImageUrl(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
  };


  /** @param {string} value */
  setProfileImageUrl(value: string) {
    jspb.Message.setProto3StringField(this, 2, value);
  }


  /**
   * optional string display_name = 3;
   * @return {string}
   */
  getDisplayName(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
  };


  /** @param {string} value */
  setDisplayName(value: string) {
    jspb.Message.setProto3StringField(this, 3, value);
  }


  /**
   * optional string role_type = 4;
   * @return {string}
   */
  getRoleType(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
  };


  /** @param {string} value */
  setRoleType(value: string) {
    jspb.Message.setProto3StringField(this, 4, value);
  }


}
} // namespace Tag
export namespace Tag {
export namespace GroupTag {
  export interface AsObject {
    groupHash: string;
    groupName: string;
  }
}
export class GroupTag extends jspb.Message {
  static readonly displayName = "Tag.GroupTag";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): GroupTag.AsObject {
    return GroupTag.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!GroupTag} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: GroupTag) {
    var f: any;
    var obj: any = {
      groupHash: jspb.Message.getFieldWithDefault(msg, 1, ""),
      groupName: jspb.Message.getFieldWithDefault(msg, 2, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!GroupTag}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new GroupTag;
    return GroupTag.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!Tag.GroupTag} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!Tag.GroupTag}
   */
  static deserializeBinaryFromReader(msg: GroupTag, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setGroupHash(value1);
        break;
      case 2:
        var value2 = /** @type {string} */ (reader.readString());
        msg.setGroupName(value2);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!Tag.GroupTag} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: Tag.GroupTag, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getGroupHash();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
    f = message.getGroupName();
    if (f.length > 0) {
      writer.writeString(
        2,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    Tag.GroupTag.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string group_hash = 1;
   * @return {string}
   */
  getGroupHash(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setGroupHash(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


  /**
   * optional string group_name = 2;
   * @return {string}
   */
  getGroupName(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
  };


  /** @param {string} value */
  setGroupName(value: string) {
    jspb.Message.setProto3StringField(this, 2, value);
  }


}
} // namespace Tag
export namespace Tag {
export namespace ContentTag {
  export interface AsObject {
    contentHash: string;
    contentContextHash: string;
    contentTagTitle: string;
  }
}
export class ContentTag extends jspb.Message {
  static readonly displayName = "Tag.ContentTag";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): ContentTag.AsObject {
    return ContentTag.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!ContentTag} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: ContentTag) {
    var f: any;
    var obj: any = {
      contentHash: jspb.Message.getFieldWithDefault(msg, 1, ""),
      contentContextHash: jspb.Message.getFieldWithDefault(msg, 2, ""),
      contentTagTitle: jspb.Message.getFieldWithDefault(msg, 3, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!ContentTag}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new ContentTag;
    return ContentTag.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!Tag.ContentTag} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!Tag.ContentTag}
   */
  static deserializeBinaryFromReader(msg: ContentTag, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setContentHash(value1);
        break;
      case 2:
        var value2 = /** @type {string} */ (reader.readString());
        msg.setContentContextHash(value2);
        break;
      case 3:
        var value3 = /** @type {string} */ (reader.readString());
        msg.setContentTagTitle(value3);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!Tag.ContentTag} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: Tag.ContentTag, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getContentHash();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
    f = message.getContentContextHash();
    if (f.length > 0) {
      writer.writeString(
        2,
        f
      );
    }
    f = message.getContentTagTitle();
    if (f.length > 0) {
      writer.writeString(
        3,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    Tag.ContentTag.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string content_hash = 1;
   * @return {string}
   */
  getContentHash(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setContentHash(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


  /**
   * optional string content_context_hash = 2;
   * @return {string}
   */
  getContentContextHash(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
  };


  /** @param {string} value */
  setContentContextHash(value: string) {
    jspb.Message.setProto3StringField(this, 2, value);
  }


  /**
   * optional string content_tag_title = 3;
   * @return {string}
   */
  getContentTagTitle(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
  };


  /** @param {string} value */
  setContentTagTitle(value: string) {
    jspb.Message.setProto3StringField(this, 3, value);
  }


}
} // namespace Tag
export namespace GalleryItem {
  export interface AsObject {
    galleryPhotoUuid: string;
    imageInfo?: image_image_pb.ImageInfo.AsObject;
    description: string;
    authorDisplayName: string;
    authorPersonHash: string;
    timestamp: number;
    tagList: Tag.AsObject[];
  }
}
export class GalleryItem extends jspb.Message {
  static readonly displayName = "GalleryItem";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [7];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, GalleryItem.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): GalleryItem.AsObject {
    return GalleryItem.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!GalleryItem} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: GalleryItem) {
    var f: any;
    var obj: any = {
      galleryPhotoUuid: jspb.Message.getFieldWithDefault(msg, 1, ""),
      imageInfo: (f = msg.getImageInfo()) && image_image_pb.ImageInfo.toObject(includeInstance, f),
      description: jspb.Message.getFieldWithDefault(msg, 3, ""),
      authorDisplayName: jspb.Message.getFieldWithDefault(msg, 4, ""),
      authorPersonHash: jspb.Message.getFieldWithDefault(msg, 5, ""),
      timestamp: jspb.Message.getFieldWithDefault(msg, 6, 0),
      tagList: jspb.Message.toObjectList(msg.getTagList(),
      Tag.toObject, includeInstance)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!GalleryItem}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new GalleryItem;
    return GalleryItem.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!GalleryItem} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!GalleryItem}
   */
  static deserializeBinaryFromReader(msg: GalleryItem, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setGalleryPhotoUuid(value1);
        break;
      case 2:
        var value2 = new image_image_pb.ImageInfo;
        reader.readMessage(value2,image_image_pb.ImageInfo.deserializeBinaryFromReader);
        msg.setImageInfo(value2);
        break;
      case 3:
        var value3 = /** @type {string} */ (reader.readString());
        msg.setDescription(value3);
        break;
      case 4:
        var value4 = /** @type {string} */ (reader.readString());
        msg.setAuthorDisplayName(value4);
        break;
      case 5:
        var value5 = /** @type {string} */ (reader.readString());
        msg.setAuthorPersonHash(value5);
        break;
      case 6:
        var value6 = /** @type {number} */ (reader.readUint64());
        msg.setTimestamp(value6);
        break;
      case 7:
        var value7 = new Tag;
        reader.readMessage(value7,Tag.deserializeBinaryFromReader);
        msg.addTag(value7);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!GalleryItem} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: GalleryItem, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getGalleryPhotoUuid();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
    f = message.getImageInfo();
    if (f != null) {
      writer.writeMessage(
        2,
        f,
        image_image_pb.ImageInfo.serializeBinaryToWriter
      );
    }
    f = message.getDescription();
    if (f.length > 0) {
      writer.writeString(
        3,
        f
      );
    }
    f = message.getAuthorDisplayName();
    if (f.length > 0) {
      writer.writeString(
        4,
        f
      );
    }
    f = message.getAuthorPersonHash();
    if (f.length > 0) {
      writer.writeString(
        5,
        f
      );
    }
    f = message.getTimestamp();
    if (f !== 0) {
      writer.writeUint64(
        6,
        f
      );
    }
    f = message.getTagList();
    if (f.length > 0) {
      writer.writeRepeatedMessage(
        7,
        f,
        Tag.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    GalleryItem.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string gallery_photo_uuid = 1;
   * @return {string}
   */
  getGalleryPhotoUuid(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setGalleryPhotoUuid(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


  /**
   * optional ImageInfo image_info = 2;
   * @return {?ImageInfo}
   */
  getImageInfo(): image_image_pb.ImageInfo {
    return /** @type{?ImageInfo} */ (
      jspb.Message.getWrapperField(this, image_image_pb.ImageInfo, 2));
  }


  /** @param {?ImageInfo|undefined} value */
  setImageInfo(value?: image_image_pb.ImageInfo) {
    jspb.Message.setWrapperField(this, 2, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearImageInfo() {
    this.setImageInfo(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasImageInfo(): boolean {
    return jspb.Message.getField(this, 2) != null;
  }


  /**
   * optional string description = 3;
   * @return {string}
   */
  getDescription(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
  };


  /** @param {string} value */
  setDescription(value: string) {
    jspb.Message.setProto3StringField(this, 3, value);
  }


  /**
   * optional string author_display_name = 4;
   * @return {string}
   */
  getAuthorDisplayName(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
  };


  /** @param {string} value */
  setAuthorDisplayName(value: string) {
    jspb.Message.setProto3StringField(this, 4, value);
  }


  /**
   * optional string author_person_hash = 5;
   * @return {string}
   */
  getAuthorPersonHash(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
  };


  /** @param {string} value */
  setAuthorPersonHash(value: string) {
    jspb.Message.setProto3StringField(this, 5, value);
  }


  /**
   * optional uint64 timestamp = 6;
   * @return {number}
   */
  getTimestamp(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
  };


  /** @param {number} value */
  setTimestamp(value: number) {
    jspb.Message.setProto3IntField(this, 6, value);
  }


  /**
   * repeated Tag tag = 7;
   * @return {!Array<!Tag>}
   */
  getTagList(): Tag[] {
    return /** @type{!Array<!Tag>} */ (
      jspb.Message.getRepeatedWrapperField(this, Tag, 7));
  }


  /** @param {!Array<!Tag>} value */
  setTagList(value?: Tag[]) {
    jspb.Message.setRepeatedWrapperField(this, 7, value);
  }


  /**
   * @param {!Tag=} opt_value
   * @param {number=} opt_index
   * @return {!Tag}
   */
  addTag(opt_value?: Tag, opt_index?: number): Tag {
    return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, Tag, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearTagList() {
    this.setTagList([]);
  }


}
export namespace GallerySection {
  export interface AsObject {
    title: string;
    itemList: GalleryItem.AsObject[];
  }
}
export class GallerySection extends jspb.Message {
  static readonly displayName = "GallerySection";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [2];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, GallerySection.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): GallerySection.AsObject {
    return GallerySection.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!GallerySection} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: GallerySection) {
    var f: any;
    var obj: any = {
      title: jspb.Message.getFieldWithDefault(msg, 1, ""),
      itemList: jspb.Message.toObjectList(msg.getItemList(),
      GalleryItem.toObject, includeInstance)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!GallerySection}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new GallerySection;
    return GallerySection.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!GallerySection} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!GallerySection}
   */
  static deserializeBinaryFromReader(msg: GallerySection, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setTitle(value1);
        break;
      case 2:
        var value2 = new GalleryItem;
        reader.readMessage(value2,GalleryItem.deserializeBinaryFromReader);
        msg.addItem(value2);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!GallerySection} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: GallerySection, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getTitle();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
    f = message.getItemList();
    if (f.length > 0) {
      writer.writeRepeatedMessage(
        2,
        f,
        GalleryItem.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    GallerySection.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string title = 1;
   * @return {string}
   */
  getTitle(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setTitle(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


  /**
   * repeated GalleryItem item = 2;
   * @return {!Array<!GalleryItem>}
   */
  getItemList(): GalleryItem[] {
    return /** @type{!Array<!GalleryItem>} */ (
      jspb.Message.getRepeatedWrapperField(this, GalleryItem, 2));
  }


  /** @param {!Array<!GalleryItem>} value */
  setItemList(value?: GalleryItem[]) {
    jspb.Message.setRepeatedWrapperField(this, 2, value);
  }


  /**
   * @param {!GalleryItem=} opt_value
   * @param {number=} opt_index
   * @return {!GalleryItem}
   */
  addItem(opt_value?: GalleryItem, opt_index?: number): GalleryItem {
    return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, GalleryItem, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearItemList() {
    this.setItemList([]);
  }


}
export namespace MingaGalleryCreateRequest {
  export interface AsObject {
    imageAssetList: string[];
    description: string;
    tagList: Tag.AsObject[];
  }
}
export class MingaGalleryCreateRequest extends jspb.Message {
  static readonly displayName = "MingaGalleryCreateRequest";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [1,3];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, MingaGalleryCreateRequest.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): MingaGalleryCreateRequest.AsObject {
    return MingaGalleryCreateRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!MingaGalleryCreateRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: MingaGalleryCreateRequest) {
    var f: any;
    var obj: any = {
      imageAssetList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
      description: jspb.Message.getFieldWithDefault(msg, 2, ""),
      tagList: jspb.Message.toObjectList(msg.getTagList(),
      Tag.toObject, includeInstance)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!MingaGalleryCreateRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new MingaGalleryCreateRequest;
    return MingaGalleryCreateRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!MingaGalleryCreateRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!MingaGalleryCreateRequest}
   */
  static deserializeBinaryFromReader(msg: MingaGalleryCreateRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.addImageAsset(value1);
        break;
      case 2:
        var value2 = /** @type {string} */ (reader.readString());
        msg.setDescription(value2);
        break;
      case 3:
        var value3 = new Tag;
        reader.readMessage(value3,Tag.deserializeBinaryFromReader);
        msg.addTag(value3);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!MingaGalleryCreateRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: MingaGalleryCreateRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getImageAssetList();
    if (f.length > 0) {
      writer.writeRepeatedString(
        1,
        f
      );
    }
    f = message.getDescription();
    if (f.length > 0) {
      writer.writeString(
        2,
        f
      );
    }
    f = message.getTagList();
    if (f.length > 0) {
      writer.writeRepeatedMessage(
        3,
        f,
        Tag.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    MingaGalleryCreateRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * repeated string image_asset = 1;
   * @return {!Array<string>}
   */
  getImageAssetList(): string[] {
    return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
  };


  /** @param {!Array<string>} value */
  setImageAssetList(value: string[]) {
    jspb.Message.setField(this, 1, value || []);
  }


  /**
   * @param {string} value
   * @param {number=} opt_index
   */
  addImageAsset(value: string, opt_index?: number): void {
    jspb.Message.addToRepeatedField(this, 1, value, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearImageAssetList() {
    this.setImageAssetList([]);
  }


  /**
   * optional string description = 2;
   * @return {string}
   */
  getDescription(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
  };


  /** @param {string} value */
  setDescription(value: string) {
    jspb.Message.setProto3StringField(this, 2, value);
  }


  /**
   * repeated Tag tag = 3;
   * @return {!Array<!Tag>}
   */
  getTagList(): Tag[] {
    return /** @type{!Array<!Tag>} */ (
      jspb.Message.getRepeatedWrapperField(this, Tag, 3));
  }


  /** @param {!Array<!Tag>} value */
  setTagList(value?: Tag[]) {
    jspb.Message.setRepeatedWrapperField(this, 3, value);
  }


  /**
   * @param {!Tag=} opt_value
   * @param {number=} opt_index
   * @return {!Tag}
   */
  addTag(opt_value?: Tag, opt_index?: number): Tag {
    return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, Tag, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearTagList() {
    this.setTagList([]);
  }


}
export namespace MingaGalleryCreateResponse {
  export interface AsObject {
    galleryPhotoUuidList: string[];
    moderationResultList: gateway_moderation_pb.ModerationResult.AsObject[];
  }
}
export class MingaGalleryCreateResponse extends jspb.Message {
  static readonly displayName = "MingaGalleryCreateResponse";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [1,2];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, MingaGalleryCreateResponse.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): MingaGalleryCreateResponse.AsObject {
    return MingaGalleryCreateResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!MingaGalleryCreateResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: MingaGalleryCreateResponse) {
    var f: any;
    var obj: any = {
      galleryPhotoUuidList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
      moderationResultList: jspb.Message.toObjectList(msg.getModerationResultList(),
      gateway_moderation_pb.ModerationResult.toObject, includeInstance)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!MingaGalleryCreateResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new MingaGalleryCreateResponse;
    return MingaGalleryCreateResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!MingaGalleryCreateResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!MingaGalleryCreateResponse}
   */
  static deserializeBinaryFromReader(msg: MingaGalleryCreateResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.addGalleryPhotoUuid(value1);
        break;
      case 2:
        var value2 = new gateway_moderation_pb.ModerationResult;
        reader.readMessage(value2,gateway_moderation_pb.ModerationResult.deserializeBinaryFromReader);
        msg.addModerationResult(value2);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!MingaGalleryCreateResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: MingaGalleryCreateResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getGalleryPhotoUuidList();
    if (f.length > 0) {
      writer.writeRepeatedString(
        1,
        f
      );
    }
    f = message.getModerationResultList();
    if (f.length > 0) {
      writer.writeRepeatedMessage(
        2,
        f,
        gateway_moderation_pb.ModerationResult.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    MingaGalleryCreateResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * repeated string gallery_photo_uuid = 1;
   * @return {!Array<string>}
   */
  getGalleryPhotoUuidList(): string[] {
    return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
  };


  /** @param {!Array<string>} value */
  setGalleryPhotoUuidList(value: string[]) {
    jspb.Message.setField(this, 1, value || []);
  }


  /**
   * @param {string} value
   * @param {number=} opt_index
   */
  addGalleryPhotoUuid(value: string, opt_index?: number): void {
    jspb.Message.addToRepeatedField(this, 1, value, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearGalleryPhotoUuidList() {
    this.setGalleryPhotoUuidList([]);
  }


  /**
   * repeated ModerationResult moderation_result = 2;
   * @return {!Array<!ModerationResult>}
   */
  getModerationResultList(): gateway_moderation_pb.ModerationResult[] {
    return /** @type{!Array<!ModerationResult>} */ (
      jspb.Message.getRepeatedWrapperField(this, gateway_moderation_pb.ModerationResult, 2));
  }


  /** @param {!Array<!ModerationResult>} value */
  setModerationResultList(value?: gateway_moderation_pb.ModerationResult[]) {
    jspb.Message.setRepeatedWrapperField(this, 2, value);
  }


  /**
   * @param {!ModerationResult=} opt_value
   * @param {number=} opt_index
   * @return {!ModerationResult}
   */
  addModerationResult(opt_value?: gateway_moderation_pb.ModerationResult, opt_index?: number): gateway_moderation_pb.ModerationResult {
    return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, gateway_moderation_pb.ModerationResult, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearModerationResultList() {
    this.setModerationResultList([]);
  }


}
export namespace MingaGalleryDeleteRequest {
  export interface AsObject {
    galleryPhotoUuid: string;
  }
}
export class MingaGalleryDeleteRequest extends jspb.Message {
  static readonly displayName = "MingaGalleryDeleteRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): MingaGalleryDeleteRequest.AsObject {
    return MingaGalleryDeleteRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!MingaGalleryDeleteRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: MingaGalleryDeleteRequest) {
    var f: any;
    var obj: any = {
      galleryPhotoUuid: jspb.Message.getFieldWithDefault(msg, 1, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!MingaGalleryDeleteRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new MingaGalleryDeleteRequest;
    return MingaGalleryDeleteRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!MingaGalleryDeleteRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!MingaGalleryDeleteRequest}
   */
  static deserializeBinaryFromReader(msg: MingaGalleryDeleteRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setGalleryPhotoUuid(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!MingaGalleryDeleteRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: MingaGalleryDeleteRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getGalleryPhotoUuid();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    MingaGalleryDeleteRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string gallery_photo_uuid = 1;
   * @return {string}
   */
  getGalleryPhotoUuid(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setGalleryPhotoUuid(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


}
export namespace MingaGalleryDeleteResponse {
  export interface AsObject {
    success: boolean;
  }
}
export class MingaGalleryDeleteResponse extends jspb.Message {
  static readonly displayName = "MingaGalleryDeleteResponse";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): MingaGalleryDeleteResponse.AsObject {
    return MingaGalleryDeleteResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!MingaGalleryDeleteResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: MingaGalleryDeleteResponse) {
    var f: any;
    var obj: any = {
      success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!MingaGalleryDeleteResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new MingaGalleryDeleteResponse;
    return MingaGalleryDeleteResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!MingaGalleryDeleteResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!MingaGalleryDeleteResponse}
   */
  static deserializeBinaryFromReader(msg: MingaGalleryDeleteResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {boolean} */ (reader.readBool());
        msg.setSuccess(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!MingaGalleryDeleteResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: MingaGalleryDeleteResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getSuccess();
    if (f) {
      writer.writeBool(
        1,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    MingaGalleryDeleteResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional bool success = 1;
   * @return {boolean}
   */
  getSuccess(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
  };


  /** @param {boolean} value */
  setSuccess(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 1, value);
  }


}
export namespace GalleryStreamResponse {
  export interface AsObject {
    itemMetadata?: common_stream_pb.StreamItemMetadata.AsObject;
    item?: GallerySection.AsObject;
  }
}
export class GalleryStreamResponse extends jspb.Message {
  static readonly displayName = "GalleryStreamResponse";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): GalleryStreamResponse.AsObject {
    return GalleryStreamResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!GalleryStreamResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: GalleryStreamResponse) {
    var f: any;
    var obj: any = {
      itemMetadata: (f = msg.getItemMetadata()) && common_stream_pb.StreamItemMetadata.toObject(includeInstance, f),
      item: (f = msg.getItem()) && GallerySection.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!GalleryStreamResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new GalleryStreamResponse;
    return GalleryStreamResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!GalleryStreamResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!GalleryStreamResponse}
   */
  static deserializeBinaryFromReader(msg: GalleryStreamResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new common_stream_pb.StreamItemMetadata;
        reader.readMessage(value1,common_stream_pb.StreamItemMetadata.deserializeBinaryFromReader);
        msg.setItemMetadata(value1);
        break;
      case 2:
        var value2 = new GallerySection;
        reader.readMessage(value2,GallerySection.deserializeBinaryFromReader);
        msg.setItem(value2);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!GalleryStreamResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: GalleryStreamResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getItemMetadata();
    if (f != null) {
      writer.writeMessage(
        1,
        f,
        common_stream_pb.StreamItemMetadata.serializeBinaryToWriter
      );
    }
    f = message.getItem();
    if (f != null) {
      writer.writeMessage(
        2,
        f,
        GallerySection.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    GalleryStreamResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional StreamItemMetadata item_metadata = 1;
   * @return {?StreamItemMetadata}
   */
  getItemMetadata(): common_stream_pb.StreamItemMetadata {
    return /** @type{?StreamItemMetadata} */ (
      jspb.Message.getWrapperField(this, common_stream_pb.StreamItemMetadata, 1));
  }


  /** @param {?StreamItemMetadata|undefined} value */
  setItemMetadata(value?: common_stream_pb.StreamItemMetadata) {
    jspb.Message.setWrapperField(this, 1, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearItemMetadata() {
    this.setItemMetadata(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasItemMetadata(): boolean {
    return jspb.Message.getField(this, 1) != null;
  }


  /**
   * optional GallerySection item = 2;
   * @return {?GallerySection}
   */
  getItem(): GallerySection {
    return /** @type{?GallerySection} */ (
      jspb.Message.getWrapperField(this, GallerySection, 2));
  }


  /** @param {?GallerySection|undefined} value */
  setItem(value?: GallerySection) {
    jspb.Message.setWrapperField(this, 2, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearItem() {
    this.setItem(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasItem(): boolean {
    return jspb.Message.getField(this, 2) != null;
  }


}
export namespace MingaGallerySummaryRequest {
  export interface AsObject {
    eventContentHash: string;
    groupHash: string;
  }
}
export class MingaGallerySummaryRequest extends jspb.Message {
  static readonly displayName = "MingaGallerySummaryRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): MingaGallerySummaryRequest.AsObject {
    return MingaGallerySummaryRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!MingaGallerySummaryRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: MingaGallerySummaryRequest) {
    var f: any;
    var obj: any = {
      eventContentHash: jspb.Message.getFieldWithDefault(msg, 1, ""),
      groupHash: jspb.Message.getFieldWithDefault(msg, 2, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!MingaGallerySummaryRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new MingaGallerySummaryRequest;
    return MingaGallerySummaryRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!MingaGallerySummaryRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!MingaGallerySummaryRequest}
   */
  static deserializeBinaryFromReader(msg: MingaGallerySummaryRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setEventContentHash(value1);
        break;
      case 2:
        var value2 = /** @type {string} */ (reader.readString());
        msg.setGroupHash(value2);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!MingaGallerySummaryRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: MingaGallerySummaryRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getEventContentHash();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
    f = message.getGroupHash();
    if (f.length > 0) {
      writer.writeString(
        2,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    MingaGallerySummaryRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string event_content_hash = 1;
   * @return {string}
   */
  getEventContentHash(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setEventContentHash(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


  /**
   * optional string group_hash = 2;
   * @return {string}
   */
  getGroupHash(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
  };


  /** @param {string} value */
  setGroupHash(value: string) {
    jspb.Message.setProto3StringField(this, 2, value);
  }


}
export namespace MingaGallerySummaryResponse {
  export interface AsObject {
    photoTotal: number;
    newPhotosCount: number;
    newestPhotosList: GalleryItem.AsObject[];
    photoAspectRatioPerRow: number;
  }
}
export class MingaGallerySummaryResponse extends jspb.Message {
  static readonly displayName = "MingaGallerySummaryResponse";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [3];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, MingaGallerySummaryResponse.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): MingaGallerySummaryResponse.AsObject {
    return MingaGallerySummaryResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!MingaGallerySummaryResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: MingaGallerySummaryResponse) {
    var f: any;
    var obj: any = {
      photoTotal: jspb.Message.getFieldWithDefault(msg, 1, 0),
      newPhotosCount: jspb.Message.getFieldWithDefault(msg, 2, 0),
      newestPhotosList: jspb.Message.toObjectList(msg.getNewestPhotosList(),
      GalleryItem.toObject, includeInstance),
      photoAspectRatioPerRow: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!MingaGallerySummaryResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new MingaGallerySummaryResponse;
    return MingaGallerySummaryResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!MingaGallerySummaryResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!MingaGallerySummaryResponse}
   */
  static deserializeBinaryFromReader(msg: MingaGallerySummaryResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {number} */ (reader.readInt32());
        msg.setPhotoTotal(value1);
        break;
      case 2:
        var value2 = /** @type {number} */ (reader.readInt32());
        msg.setNewPhotosCount(value2);
        break;
      case 3:
        var value3 = new GalleryItem;
        reader.readMessage(value3,GalleryItem.deserializeBinaryFromReader);
        msg.addNewestPhotos(value3);
        break;
      case 4:
        var value4 = /** @type {number} */ (reader.readFloat());
        msg.setPhotoAspectRatioPerRow(value4);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!MingaGallerySummaryResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: MingaGallerySummaryResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getPhotoTotal();
    if (f !== 0) {
      writer.writeInt32(
        1,
        f
      );
    }
    f = message.getNewPhotosCount();
    if (f !== 0) {
      writer.writeInt32(
        2,
        f
      );
    }
    f = message.getNewestPhotosList();
    if (f.length > 0) {
      writer.writeRepeatedMessage(
        3,
        f,
        GalleryItem.serializeBinaryToWriter
      );
    }
    f = message.getPhotoAspectRatioPerRow();
    if (f !== 0.0) {
      writer.writeFloat(
        4,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    MingaGallerySummaryResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional int32 photo_total = 1;
   * @return {number}
   */
  getPhotoTotal(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
  };


  /** @param {number} value */
  setPhotoTotal(value: number) {
    jspb.Message.setProto3IntField(this, 1, value);
  }


  /**
   * optional int32 new_photos_count = 2;
   * @return {number}
   */
  getNewPhotosCount(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
  };


  /** @param {number} value */
  setNewPhotosCount(value: number) {
    jspb.Message.setProto3IntField(this, 2, value);
  }


  /**
   * repeated GalleryItem newest_photos = 3;
   * @return {!Array<!GalleryItem>}
   */
  getNewestPhotosList(): GalleryItem[] {
    return /** @type{!Array<!GalleryItem>} */ (
      jspb.Message.getRepeatedWrapperField(this, GalleryItem, 3));
  }


  /** @param {!Array<!GalleryItem>} value */
  setNewestPhotosList(value?: GalleryItem[]) {
    jspb.Message.setRepeatedWrapperField(this, 3, value);
  }


  /**
   * @param {!GalleryItem=} opt_value
   * @param {number=} opt_index
   * @return {!GalleryItem}
   */
  addNewestPhotos(opt_value?: GalleryItem, opt_index?: number): GalleryItem {
    return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, GalleryItem, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearNewestPhotosList() {
    this.setNewestPhotosList([]);
  }


  /**
   * optional float photo_aspect_ratio_per_row = 4;
   * @return {number}
   */
  getPhotoAspectRatioPerRow(): number {
    return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
  };


  /** @param {number} value */
  setPhotoAspectRatioPerRow(value: number) {
    jspb.Message.setProto3FloatField(this, 4, value);
  }


}
export namespace MingaGalleryPhotoRequest {
  export interface AsObject {
    galleryPhotoUuid: string;
  }
}
export class MingaGalleryPhotoRequest extends jspb.Message {
  static readonly displayName = "MingaGalleryPhotoRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): MingaGalleryPhotoRequest.AsObject {
    return MingaGalleryPhotoRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!MingaGalleryPhotoRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: MingaGalleryPhotoRequest) {
    var f: any;
    var obj: any = {
      galleryPhotoUuid: jspb.Message.getFieldWithDefault(msg, 1, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!MingaGalleryPhotoRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new MingaGalleryPhotoRequest;
    return MingaGalleryPhotoRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!MingaGalleryPhotoRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!MingaGalleryPhotoRequest}
   */
  static deserializeBinaryFromReader(msg: MingaGalleryPhotoRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setGalleryPhotoUuid(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!MingaGalleryPhotoRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: MingaGalleryPhotoRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getGalleryPhotoUuid();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    MingaGalleryPhotoRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string gallery_photo_uuid = 1;
   * @return {string}
   */
  getGalleryPhotoUuid(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setGalleryPhotoUuid(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


}
export namespace MingaGalleryPhotoResponse {
  export interface AsObject {
    photo?: GalleryItem.AsObject;
  }
}
export class MingaGalleryPhotoResponse extends jspb.Message {
  static readonly displayName = "MingaGalleryPhotoResponse";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): MingaGalleryPhotoResponse.AsObject {
    return MingaGalleryPhotoResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!MingaGalleryPhotoResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: MingaGalleryPhotoResponse) {
    var f: any;
    var obj: any = {
      photo: (f = msg.getPhoto()) && GalleryItem.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!MingaGalleryPhotoResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new MingaGalleryPhotoResponse;
    return MingaGalleryPhotoResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!MingaGalleryPhotoResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!MingaGalleryPhotoResponse}
   */
  static deserializeBinaryFromReader(msg: MingaGalleryPhotoResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new GalleryItem;
        reader.readMessage(value1,GalleryItem.deserializeBinaryFromReader);
        msg.setPhoto(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!MingaGalleryPhotoResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: MingaGalleryPhotoResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getPhoto();
    if (f != null) {
      writer.writeMessage(
        1,
        f,
        GalleryItem.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    MingaGalleryPhotoResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional GalleryItem photo = 1;
   * @return {?GalleryItem}
   */
  getPhoto(): GalleryItem {
    return /** @type{?GalleryItem} */ (
      jspb.Message.getWrapperField(this, GalleryItem, 1));
  }


  /** @param {?GalleryItem|undefined} value */
  setPhoto(value?: GalleryItem) {
    jspb.Message.setWrapperField(this, 1, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearPhoto() {
    this.setPhoto(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasPhoto(): boolean {
    return jspb.Message.getField(this, 1) != null;
  }


}
export namespace MingaGalleryGetPhotoTotalRequest {
  export interface AsObject {
    searchQuery: string;
    eventContentHash: string;
    groupHash: string;
  }
}
export class MingaGalleryGetPhotoTotalRequest extends jspb.Message {
  static readonly displayName = "MingaGalleryGetPhotoTotalRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): MingaGalleryGetPhotoTotalRequest.AsObject {
    return MingaGalleryGetPhotoTotalRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!MingaGalleryGetPhotoTotalRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: MingaGalleryGetPhotoTotalRequest) {
    var f: any;
    var obj: any = {
      searchQuery: jspb.Message.getFieldWithDefault(msg, 1, ""),
      eventContentHash: jspb.Message.getFieldWithDefault(msg, 2, ""),
      groupHash: jspb.Message.getFieldWithDefault(msg, 3, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!MingaGalleryGetPhotoTotalRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new MingaGalleryGetPhotoTotalRequest;
    return MingaGalleryGetPhotoTotalRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!MingaGalleryGetPhotoTotalRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!MingaGalleryGetPhotoTotalRequest}
   */
  static deserializeBinaryFromReader(msg: MingaGalleryGetPhotoTotalRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setSearchQuery(value1);
        break;
      case 2:
        var value2 = /** @type {string} */ (reader.readString());
        msg.setEventContentHash(value2);
        break;
      case 3:
        var value3 = /** @type {string} */ (reader.readString());
        msg.setGroupHash(value3);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!MingaGalleryGetPhotoTotalRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: MingaGalleryGetPhotoTotalRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getSearchQuery();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
    f = message.getEventContentHash();
    if (f.length > 0) {
      writer.writeString(
        2,
        f
      );
    }
    f = message.getGroupHash();
    if (f.length > 0) {
      writer.writeString(
        3,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    MingaGalleryGetPhotoTotalRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string search_query = 1;
   * @return {string}
   */
  getSearchQuery(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setSearchQuery(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


  /**
   * optional string event_content_hash = 2;
   * @return {string}
   */
  getEventContentHash(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
  };


  /** @param {string} value */
  setEventContentHash(value: string) {
    jspb.Message.setProto3StringField(this, 2, value);
  }


  /**
   * optional string group_hash = 3;
   * @return {string}
   */
  getGroupHash(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
  };


  /** @param {string} value */
  setGroupHash(value: string) {
    jspb.Message.setProto3StringField(this, 3, value);
  }


}
export namespace MingaGalleryGetPhotoTotalResponse {
  export interface AsObject {
    photoTotal: number;
  }
}
export class MingaGalleryGetPhotoTotalResponse extends jspb.Message {
  static readonly displayName = "MingaGalleryGetPhotoTotalResponse";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): MingaGalleryGetPhotoTotalResponse.AsObject {
    return MingaGalleryGetPhotoTotalResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!MingaGalleryGetPhotoTotalResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: MingaGalleryGetPhotoTotalResponse) {
    var f: any;
    var obj: any = {
      photoTotal: jspb.Message.getFieldWithDefault(msg, 1, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!MingaGalleryGetPhotoTotalResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new MingaGalleryGetPhotoTotalResponse;
    return MingaGalleryGetPhotoTotalResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!MingaGalleryGetPhotoTotalResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!MingaGalleryGetPhotoTotalResponse}
   */
  static deserializeBinaryFromReader(msg: MingaGalleryGetPhotoTotalResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {number} */ (reader.readInt32());
        msg.setPhotoTotal(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!MingaGalleryGetPhotoTotalResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: MingaGalleryGetPhotoTotalResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getPhotoTotal();
    if (f !== 0) {
      writer.writeInt32(
        1,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    MingaGalleryGetPhotoTotalResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional int32 photo_total = 1;
   * @return {number}
   */
  getPhotoTotal(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
  };


  /** @param {number} value */
  setPhotoTotal(value: number) {
    jspb.Message.setProto3IntField(this, 1, value);
  }


}
