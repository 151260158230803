// TS2742
import * as $ngrx_store_store from '@ngrx/store';
import * as $ngrx_store_src_models from '@ngrx/store/src/models';
import { Update } from '@ngrx/entity';
import { Action, createAction, props } from '@ngrx/store';
// TS2742
import { IMingaSetting, MingaMinimalModel } from 'libs/domain';
import { IMingaSubscription } from 'libs/domain';

export const setMingaCollection = createAction(
  '[Minga] Set MingaCollection',
  props<{ payload: MingaMinimalModel[] }>(),
);

export const setCurrentMinga = createAction(
  '[Minga] Set Current Minga',
  props<{ payload: MingaMinimalModel }>(),
);

export const updateSettings = createAction(
  '[Minga] Update Settings',
  props<{ payload: Update<MingaMinimalModel> }>(),
);

export const setActiveMingaSubscription = createAction(
  '[Minga] Set Active Minga Subscription',
  props<{ mingaSubscription: IMingaSubscription }>(),
);

export const fetchAllMingas = createAction('[Minga] Get All Mingas');

export type MingaActions =
  | typeof setMingaCollection
  | typeof setCurrentMinga
  | typeof setActiveMingaSubscription
  | typeof fetchAllMingas;
