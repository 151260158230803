import {
  ChallengeColor,
  ChallengePrivacy,
  ChallengeType,
  IChallenge,
} from 'libs/domain';
import { MingaRoleType } from 'libs/domain';
import { challenge_pb, gateway } from 'libs/generated-grpc-ts';
import { $enum } from 'ts-enum-util';

import { DateProtoMapper } from '../common';
import { IVideoUploadProtoMapper } from '../video_upload';

export namespace IChallengeProtoMapper {
  export function fromChallengeInfoMessage(
    message: challenge_pb.ChallengeInfo,
  ): IChallenge {
    const videoUpload = IVideoUploadProtoMapper.fromIVideoUploadMessage(
      message.getVideo(),
    );

    const date = message.getDueDate();
    if (!date) {
      throw new Error('Due date is required');
    }
    const dueDate = DateProtoMapper.dateTimeMessageToDateObject(date);

    const allowedRoles = message.getAllowedRolesList().map(role => {
      return $enum(MingaRoleType).asValueOrThrow(role);
    });

    return {
      body: message.getBody(),
      commentsAllowed: message.getCommentsAllowed(),
      contentHash: message.getContentHash(),
      dueDate: dueDate,
      privacy: $enum(ChallengePrivacy).asValueOrDefault(
        message.getPrivacy(),
        ChallengePrivacy.PUBLIC,
      ),
      title: message.getTitle(),
      type: $enum(ChallengeType).asValueOrDefault(
        message.getType(),
        ChallengeType.TEXT,
      ),
      bannerHash: message.getBannerHash(),
      video: videoUpload,
      videoId: null,
      bannerAsset: message.getBannerAsset(),
      color: $enum(ChallengeColor).asValueOrDefault(
        message.getColor(),
        ChallengeColor.RED,
      ),
      allowedRoles: allowedRoles,
      invitedPeopleCount: message.getInvitePeopleCount(),
      invitedResponseCount: message.getInviteResponseCount(),
      requiresApproval: message.getRequiresApproval(),
    };
  }

  export function toChallengeInfoMessage(
    challenge: IChallenge,
  ): challenge_pb.ChallengeInfo {
    const message = new challenge_pb.ChallengeInfo();
    if (challenge.bannerHash) {
      message.setBannerHash(challenge.bannerHash);
    }

    if (challenge.contentHash) {
      message.setContentHash(challenge.contentHash);
    }

    if (challenge.contextHash) {
      message.setContextHash(challenge.contextHash);
    }

    message.setBody(challenge.body);
    message.setCommentsAllowed(challenge.commentsAllowed);
    if (challenge.dueDate) {
      message.setDueDate(
        DateProtoMapper.dateObjectToDateTimeMessage(challenge.dueDate),
      );
    }
    message.setPrivacy(challenge.privacy);
    message.setTitle(challenge.title);
    message.setType(challenge.type);

    if (challenge.video) {
      const video = IVideoUploadProtoMapper.toIVideoUploadMessage(
        challenge.video,
      );
      message.setVideo(video);
    }

    message.setColor(challenge.color);
    message.setAllowedRolesList(challenge.allowedRoles);
    message.setRequiresApproval(challenge.requiresApproval || false);

    message.setInvitePeopleCount(challenge.invitedPeopleCount || 0);
    message.setInviteResponseCount(challenge.invitedResponseCount || 0);

    return message;
  }

  export function toLongCardChallengeViewMessage(
    challenge: IChallenge,
  ): gateway.content_views_pb.LongChallengeCardView {
    const message = new gateway.content_views_pb.LongChallengeCardView();

    if (challenge.contentHash) {
      message.setContentHash(challenge.contentHash);
    }

    message.setBody(challenge.body);
    message.setCommentsAllowed(challenge.commentsAllowed);
    if (challenge.dueDate) {
      message.setDueDate(
        DateProtoMapper.dateObjectToDateTimeMessage(challenge.dueDate),
      );
    }
    message.setPrivacy(challenge.privacy);
    message.setTitle(challenge.title);
    message.setChallengeType(challenge.type);

    if (challenge.video && challenge.video.cloudflareUid) {
      message.setCloudflareUid(challenge.video.cloudflareUid);
      message.setCloudflareReady(!!challenge.video.processing);
    }
    message.setColor(challenge.color);
    message.setAllowedRolesList(challenge.allowedRoles);
    message.setRequiresApproval(!!challenge.requiresApproval);
    return message;
  }
}
