import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { day } from 'libs/day';
import { gateway, date_pb } from 'libs/generated-grpc-web';

import { Color } from '@app/src/app/util/color';

import { BaseShortCardClass } from '../BaseShortCardClass';

const MAX_DISPLAY_PERSON = 6;

@Component({
  selector: 'mg-birthday-short-card',
  templateUrl: './MgBirthdayShortCard.element.html',
  styleUrls: ['./MgBirthdayShortCard.element.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MgBirthdayShortCardElement extends BaseShortCardClass {
  @Input()
  content?: gateway.content_views_pb.ShortBirthdayCardView.AsObject;

  @Input()
  pinned: boolean = false;

  @Input()
  context: string = '';

  @Input()
  markKeywords: string = '';

  @Input()
  commentRoles: string[] | null = null;

  get highPixelRatio() {
    return devicePixelRatio > 1;
  }

  birthdateTopLine(birthdate: date_pb.Date.AsObject) {
    if (!birthdate) {
      return '';
    }

    const date = new Date(birthdate.year, birthdate.month - 1, birthdate.day);
    return day(date).format('dddd, MMMM').toUpperCase();
  }

  birthdateBtmNum(birthdate: date_pb.Date.AsObject) {
    if (!birthdate) {
      return '';
    }

    const date = new Date(birthdate.year, birthdate.month - 1, birthdate.day);

    return day(date).format('D');
  }

  birthdateBtmNumSuffix(birthdate: date_pb.Date.AsObject) {
    if (!birthdate) {
      return '';
    }

    const date = new Date(birthdate.year, birthdate.month - 1, birthdate.day);

    const num = day(date).format('D');
    const dayWithOrdinal = day(date).format('Do');

    return dayWithOrdinal.substr(num.length);
  }

  birthdayPersonNames(): string {
    if (!this.content) return '';

    const personList = this.content.birthdayPersonList;
    let names = personList
      .slice(0, 6)
      .map(p => p.displayName)
      .join(', ');

    if (personList.length > MAX_DISPLAY_PERSON) {
      names += '... +' + (personList.length - MAX_DISPLAY_PERSON) + ' More';
    }

    return names;
  }

  displayBirthdayList() {
    if (!this.content) return [];
    return this.content.birthdayPersonList.slice(0, MAX_DISPLAY_PERSON);
  }

  bannerGradientStyle() {
    const hasGradientOverlap =
      (this.content && this.content.gradientOverlap) || false;
    const backgroundColor =
      this.content && this.content.backgroundColor
        ? this.content.backgroundColor
        : '#33CCFF';

    const style: any = {};
    if (hasGradientOverlap) {
      const bgColorObj = new Color(backgroundColor);
      const transBgColor = bgColorObj.alpha(0).toString();

      style[
        'background'
      ] = `linear-gradient(${transBgColor}, ${backgroundColor})`;
    }

    return style;
  }
}
