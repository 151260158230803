// GENERATED CODE -- DO NOT EDIT!

import {grpc} from '@improbable-eng/grpc-web';

import * as automation_automation_pb from '../automation/automation_pb';

export class AutomationManager {
  static readonly serviceName = "automation.AutomationManager";

  static readonly ListAutomations = {
    methodName: "ListAutomations",
    service: AutomationManager,
    requestStream: false,
    responseStream: false,
    requestType: automation_automation_pb.ListAutomationsRequest,
    responseType: automation_automation_pb.ListAutomationsResponse,
  };

  static readonly GetAutomation = {
    methodName: "GetAutomation",
    service: AutomationManager,
    requestStream: false,
    responseStream: false,
    requestType: automation_automation_pb.GetAutomationRequest,
    responseType: automation_automation_pb.Automation,
  };

  static readonly CreateAutomation = {
    methodName: "CreateAutomation",
    service: AutomationManager,
    requestStream: false,
    responseStream: false,
    requestType: automation_automation_pb.CreateAutomationRequest,
    responseType: automation_automation_pb.Automation,
  };

  static readonly UpdateAutomation = {
    methodName: "UpdateAutomation",
    service: AutomationManager,
    requestStream: false,
    responseStream: false,
    requestType: automation_automation_pb.UpdateAutomationRequest,
    responseType: automation_automation_pb.Automation,
  };

  static readonly DeleteAutomation = {
    methodName: "DeleteAutomation",
    service: AutomationManager,
    requestStream: false,
    responseStream: false,
    requestType: automation_automation_pb.DeleteAutomationRequest,
    responseType: automation_automation_pb.DeleteAutomationResponse,
  };

  static readonly ResetAutomationCount = {
    methodName: "ResetAutomationCount",
    service: AutomationManager,
    requestStream: false,
    responseStream: false,
    requestType: automation_automation_pb.ResetAutomationCountRequest,
    responseType: automation_automation_pb.ResetAutomationCountResponse,
  };

  static readonly ResetAllAutomationsForPeople = {
    methodName: "ResetAllAutomationsForPeople",
    service: AutomationManager,
    requestStream: false,
    responseStream: false,
    requestType: automation_automation_pb.AllAutomationsResetRequest,
    responseType: automation_automation_pb.AllAutomationsResetResponse,
  };

  static readonly ResetAllAutomations = {
    methodName: "ResetAllAutomations",
    service: AutomationManager,
    requestStream: false,
    responseStream: false,
    requestType: automation_automation_pb.AllAutomationsResetRequest,
    responseType: automation_automation_pb.AllAutomationsResetResponse,
  };

}

export class AutomationManagerClient {
  listAutomations() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  getAutomation() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  createAutomation() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  updateAutomation() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  deleteAutomation() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  resetAutomationCount() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  resetAllAutomationsForPeople() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  resetAllAutomations() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
}
