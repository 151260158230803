import { Injectable } from '@angular/core';

import { filter, first } from 'rxjs/operators';

import { KioskPermissionsService } from '@shared/services/kiosk/kiosk-permissions.service';

import { AuthInfoService } from '../minimal/services/AuthInfo';
import { GenericEvents } from './generic/GenericEvents';
import { HallpassEvents } from './hallpass/HallpassEvents';

@Injectable({ providedIn: 'root' })
export class RealtimeEventHandlers {
  constructor(
    private _authInfo: AuthInfoService,
    private _hallpassEvents: HallpassEvents,
    private _genericEvents: GenericEvents,
    private _kioskPermissions: KioskPermissionsService,
  ) {}

  public init() {
    this._authInfo.authPersonHash$
      .pipe(
        filter(authPersonHash => !!authPersonHash),
        first(),
      )
      .subscribe(authPersonHash => {
        // if user is in kiosk mode we don't want to setup any high level handlers
        if (!this._kioskPermissions.isUserInKioskMode()) {
          // any high level handlers added here
          this._hallpassEvents.setup();
          this._genericEvents.setup();
        }
      });
  }
}
