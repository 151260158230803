import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';

import Fuse from 'fuse.js';
import { IConversationWithReadStatus } from 'libs/domain';
import { combineLatest, ReplaySubject } from 'rxjs';
import { map, startWith, takeUntil } from 'rxjs/operators';

import { FirebaseMessaging } from '@app/src/app/firebase/messaging';
import { ConversationNavigator } from '@app/src/app/modules/direct-message/services/conversation-navigator.service';
import { MessagingFacade } from '@app/src/app/modules/direct-message/store';
import { addPeopleToConversations } from '@app/src/app/modules/direct-message/utils';
import { PeopleFacadeService } from '@app/src/app/people';
import { MingaSettingsService } from '@app/src/app/store/Minga/services';

import { GlobalSearchService } from '@modules/global-search';

import {
  DIRECT_MESSAGES_SEARCH_FUSE_OPTIONS,
  DirectMessageMessage,
} from '../../constants';

@Component({
  selector: 'mg-dm-list',
  templateUrl: './dm-list.component.html',
  styleUrls: ['./dm-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DmListComponent implements OnInit, OnDestroy {
  // Constants
  public readonly MSG = DirectMessageMessage;

  // Cleanup

  private _destroyedSubject = new ReplaySubject<void>(1);

  // State

  readonly showDirectMessaging$ = this._settingService.isDmModuleEnabled();

  private readonly _peopleConversations$ = addPeopleToConversations(
    this._peopleFacade.mapPeopleCollectionAsPerson(
      this._peopleFacade.getAllPeopleDetails(),
    ),
    this._messagingFacade.getConversations(),
  );

  public readonly filteredConversations$ = combineLatest([
    this.globalSearch.value$,
    this._peopleConversations$,
  ]).pipe(
    takeUntil(this._destroyedSubject),
    map(([searchText, items]) => {
      if (!searchText) return [];
      return (new Fuse(items, DIRECT_MESSAGES_SEARCH_FUSE_OPTIONS) as any)
        .search(searchText)
        .map((fResult: any) => fResult.item.conversation)
        .filter((result: any) => !!result);
    }),
    startWith([]),
  );

  readonly trackByFn = (index: number, item: IConversationWithReadStatus) => {
    return item.id;
  };

  /** Component constructor */
  constructor(
    public globalSearch: GlobalSearchService,
    private _conversationNavigator: ConversationNavigator,
    private _messagingFacade: MessagingFacade,
    private _firebaseMessaging: FirebaseMessaging,
    private _peopleFacade: PeopleFacadeService,
    private _settingService: MingaSettingsService,
  ) {}

  ngOnInit() {
    this._firebaseMessaging.requestPermission();
  }

  ngOnDestroy(): void {
    this._destroyedSubject.next();
    this._destroyedSubject.complete();
  }

  newConversationClick() {
    this._conversationNavigator.navigateCreateConversation();
  }

  onClickConversationPreview(ev: any) {
    this._conversationNavigator.navigateByConversationId(ev.data.id);
  }

  async onConversationClick(convId: number) {
    this.globalSearch.close();
    await this._conversationNavigator.navigateByConversationId(convId);
  }
}
