// GENERATED CODE -- DO NOT EDIT!

import {grpc} from '@improbable-eng/grpc-web';

import * as gateway_feed_event_pb from '../gateway/feed_event_pb';
import * as common_legacy_pb from '../common/legacy_pb';
import * as common_stream_pb from '../common/stream_pb';
import * as common_delta_pb from '../common/delta_pb';
import * as gateway_mention_pb from '../gateway/mention_pb';
import * as gateway_feed_pb from '../gateway/feed_pb';
import * as gateway_person_view_pb from '../gateway/person_view_pb';
import * as gateway_moderation_pb from '../gateway/moderation_pb';
import * as gateway_content_views_pb from '../gateway/content_views_pb';

export class FeedEventManager {
  static readonly serviceName = "FeedEventManager";

  static readonly create = {
    methodName: "create",
    service: FeedEventManager,
    requestStream: false,
    responseStream: false,
    requestType: gateway_feed_event_pb.CreateFeedEventRequest,
    responseType: gateway_feed_event_pb.CreateFeedEventResponse,
  };

  static readonly update = {
    methodName: "update",
    service: FeedEventManager,
    requestStream: false,
    responseStream: false,
    requestType: gateway_feed_event_pb.UpdateFeedEventRequest,
    responseType: gateway_feed_event_pb.UpdateFeedEventResponse,
  };

  static readonly delete = {
    methodName: "delete",
    service: FeedEventManager,
    requestStream: false,
    responseStream: false,
    requestType: gateway_feed_event_pb.DeleteFeedEventRequest,
    responseType: gateway_feed_event_pb.DeleteFeedEventResponse,
  };

  static readonly getCalendarEvents = {
    methodName: "getCalendarEvents",
    service: FeedEventManager,
    requestStream: false,
    responseStream: false,
    requestType: gateway_feed_event_pb.CalendarEventsRequest,
    responseType: gateway_feed_event_pb.CalendarEventsResponse,
  };

  static readonly streamUpcomingEvents = {
    methodName: "streamUpcomingEvents",
    service: FeedEventManager,
    requestStream: false,
    responseStream: true,
    requestType: common_stream_pb.StreamID,
    responseType: gateway_feed_pb.StreamContentFeedResponse,
  };

  static readonly streamUpcomingEventsControl = {
    methodName: "streamUpcomingEventsControl",
    service: FeedEventManager,
    requestStream: false,
    responseStream: false,
    requestType: common_stream_pb.StreamControl,
    responseType: common_stream_pb.StreamControlResponse,
  };

  static readonly streamGoingEvents = {
    methodName: "streamGoingEvents",
    service: FeedEventManager,
    requestStream: false,
    responseStream: true,
    requestType: common_stream_pb.StreamID,
    responseType: gateway_feed_pb.StreamContentFeedResponse,
  };

  static readonly streamGoingEventsControl = {
    methodName: "streamGoingEventsControl",
    service: FeedEventManager,
    requestStream: false,
    responseStream: false,
    requestType: common_stream_pb.StreamControl,
    responseType: common_stream_pb.StreamControlResponse,
  };

  static readonly streamPastEvents = {
    methodName: "streamPastEvents",
    service: FeedEventManager,
    requestStream: false,
    responseStream: true,
    requestType: common_stream_pb.StreamID,
    responseType: gateway_feed_pb.StreamContentFeedResponse,
  };

  static readonly streamPastEventsControl = {
    methodName: "streamPastEventsControl",
    service: FeedEventManager,
    requestStream: false,
    responseStream: false,
    requestType: common_stream_pb.StreamControl,
    responseType: common_stream_pb.StreamControlResponse,
  };

  static readonly streamEventGoingPeople = {
    methodName: "streamEventGoingPeople",
    service: FeedEventManager,
    requestStream: false,
    responseStream: true,
    requestType: common_stream_pb.StreamID,
    responseType: gateway_feed_event_pb.StreamEventGoingPeopleFeedResponse,
  };

  static readonly streamEventGoingPeopleControl = {
    methodName: "streamEventGoingPeopleControl",
    service: FeedEventManager,
    requestStream: false,
    responseStream: false,
    requestType: common_stream_pb.StreamControl,
    responseType: common_stream_pb.StreamControlResponse,
  };

  static readonly streamEventManagerPeople = {
    methodName: "streamEventManagerPeople",
    service: FeedEventManager,
    requestStream: false,
    responseStream: true,
    requestType: common_stream_pb.StreamID,
    responseType: gateway_feed_event_pb.StreamEventManagerPeopleFeedResponse,
  };

  static readonly streamEventManagerPeopleControl = {
    methodName: "streamEventManagerPeopleControl",
    service: FeedEventManager,
    requestStream: false,
    responseStream: false,
    requestType: common_stream_pb.StreamControl,
    responseType: common_stream_pb.StreamControlResponse,
  };

  static readonly streamAllEvents = {
    methodName: "streamAllEvents",
    service: FeedEventManager,
    requestStream: false,
    responseStream: true,
    requestType: common_stream_pb.StreamID,
    responseType: gateway_feed_pb.StreamContentFeedResponse,
  };

  static readonly streamAllEventsControl = {
    methodName: "streamAllEventsControl",
    service: FeedEventManager,
    requestStream: false,
    responseStream: false,
    requestType: common_stream_pb.StreamControl,
    responseType: common_stream_pb.StreamControlResponse,
  };

  static readonly ExportPeople = {
    methodName: "ExportPeople",
    service: FeedEventManager,
    requestStream: false,
    responseStream: true,
    requestType: gateway_feed_event_pb.ExportEventGoingPeopleFeedRequest,
    responseType: gateway_feed_event_pb.ExportEventGoingPeopleFeedChunk,
  };

  static readonly GetEvents = {
    methodName: "GetEvents",
    service: FeedEventManager,
    requestStream: false,
    responseStream: false,
    requestType: gateway_feed_event_pb.GetEventsRequest,
    responseType: gateway_feed_event_pb.GetEventsResponse,
  };

}

export class FeedEventManagerClient {
  create() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  update() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  delete() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  getCalendarEvents() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  streamUpcomingEvents() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  streamUpcomingEventsControl() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  streamGoingEvents() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  streamGoingEventsControl() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  streamPastEvents() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  streamPastEventsControl() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  streamEventGoingPeople() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  streamEventGoingPeopleControl() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  streamEventManagerPeople() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  streamEventManagerPeopleControl() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  streamAllEvents() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  streamAllEventsControl() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  exportPeople() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  getEvents() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
}
