import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTooltipModule } from '@angular/material/tooltip';

import { MgIconModule } from '@app/src/app/icon';
import { MgSpinnerModule } from '@app/src/app/spinner';

import { GenericButtonModule } from '@shared/components/button';

import { GenericModule } from '../generic';
import { TextModule } from '../text';
import { ModalOverlayPrimaryComponent } from './components';
import { ModalOverlayDialogComponent } from './components/modal-overlay-dialog/modal-overlay-dialog.component';
import { ModalOverlaySuccessComponent } from './components/modal-overlay-success';
import { ModalOverlayService } from './services';

@NgModule({
  imports: [
    // Minga dependencies
    MgIconModule,
    MgSpinnerModule,
    GenericButtonModule,
    TextModule,
    GenericModule,

    // External dependencies
    CommonModule,
    FlexLayoutModule,
    MatButtonModule,
    MatDialogModule,
    MatTooltipModule,
  ],
  declarations: [
    ModalOverlayPrimaryComponent,
    ModalOverlaySuccessComponent,
    ModalOverlayDialogComponent,
  ],
  exports: [
    ModalOverlayPrimaryComponent,
    ModalOverlaySuccessComponent,
    ModalOverlayDialogComponent,
  ],
  providers: [ModalOverlayService],
})
export class OverlayModule {}
