import { MingaPermission, MingaRoleType } from '../permissions';

export enum MembershipListType {
  STICKER = 'STICKER',
  NO_PARTY = 'NO_PARTY',
  NO_PASS = 'NO_PASS',
  NO_GRANT = 'NO_GRANT',
  TRACK = 'TRACK',
  NO_TRACK = 'NO_TRACK',
  SCHOOL_TEAM = 'SCHOOL_TEAM',
  NO_ACCESS = 'NO_ACCESS',
  ONE_PASS = 'ONE_PASS',
  TWO_PASS = 'TWO_PASS',
  CONTENT_MANAGER = 'CONTENT_MANAGER',
  ID_MANAGER = 'ID_MANAGER',
  PASS_TYPE = 'PASS_TYPE',
  POINTS_MANAGER = 'POINTS_MANAGER',
  CHECKIN_MANAGER = 'CHECKIN_MANAGER',
  BEHAVIOR_MANAGER = 'BEHAVIOR_MANAGER',
  BEHAVIOR_TYPE = 'BEHAVIOR_TYPE',
  BEHAVIOR_REPORT_VIEWER = 'BEHAVIOR_REPORT_VIEWER',
  CHECKIN_REPORT_VIEWER = 'CHECKIN_REPORT_VIEWER',
  POINTS_REPORT_VIEWER = 'POINTS_REPORT_VIEWER',
  HP_REPORT_VIEWER = 'HP_REPORT_VIEWER',
  HALL_PASS_MANAGER = 'HALL_PASS_MANAGER',
  HALL_PASS_TYPE = 'HALL_PASS_TYPE',
  CHECKIN_RESTRICTION_LIST = 'CHECKIN_RESTRICTION_LIST',
  FLEX_RESTRICTION_LIST = 'FLEX_RESTRICTION_LIST',
  FLEX_MANAGER = 'FLEX_MANAGER',
  FLEX_REPORT_VIEWER = 'FLEX_REPORT_VIEWER',
  USER_LIST = 'USER_LIST',
  USER_LIST_MANAGER = 'USER_LIST_MANAGER',
}

export const MembershipListRoleRestrictions: Record<
  MembershipListType,
  MingaRoleType[]
> = {
  [MembershipListType.STICKER]: [],
  [MembershipListType.NO_PARTY]: [],
  [MembershipListType.NO_PASS]: [],
  [MembershipListType.NO_GRANT]: [],
  [MembershipListType.TRACK]: [],
  [MembershipListType.NO_TRACK]: [],
  [MembershipListType.SCHOOL_TEAM]: [],
  [MembershipListType.NO_ACCESS]: [],
  [MembershipListType.ONE_PASS]: [],
  [MembershipListType.TWO_PASS]: [],
  [MembershipListType.CONTENT_MANAGER]: [],
  [MembershipListType.ID_MANAGER]: [],
  [MembershipListType.PASS_TYPE]: [],
  [MembershipListType.POINTS_MANAGER]: [],
  [MembershipListType.CHECKIN_MANAGER]: [],
  [MembershipListType.BEHAVIOR_MANAGER]: [],
  [MembershipListType.BEHAVIOR_TYPE]: [],
  [MembershipListType.BEHAVIOR_REPORT_VIEWER]: [],
  [MembershipListType.CHECKIN_REPORT_VIEWER]: [],
  [MembershipListType.POINTS_REPORT_VIEWER]: [],
  [MembershipListType.HP_REPORT_VIEWER]: [],
  [MembershipListType.HALL_PASS_MANAGER]: [],
  [MembershipListType.HALL_PASS_TYPE]: [],
  [MembershipListType.CHECKIN_RESTRICTION_LIST]: [],
  [MembershipListType.FLEX_RESTRICTION_LIST]: [],
  [MembershipListType.FLEX_MANAGER]: [],
  [MembershipListType.FLEX_REPORT_VIEWER]: [],
  [MembershipListType.USER_LIST]: [],
  [MembershipListType.USER_LIST_MANAGER]: [
    MingaRoleType.SUPERADMIN,
    MingaRoleType.DISTRICT_MANAGER,
    MingaRoleType.OWNER,
    MingaRoleType.MANAGER,
    MingaRoleType.TEACHER,
    MingaRoleType.STAFF,
  ],
};

export type IListTypePermissions = {
  [key in MembershipListType]: MingaPermission[];
};
