import * as day from 'dayjs';
import { IConsequenceType } from 'libs/domain';
import { IHallPassType } from 'libs/domain';
import { IPbisType } from 'libs/domain';

import { OptionItem } from '@app/src/app/shared/components/form/components/form-grouped-select/form-grouped-select.types';

import {
  BEHAVIOR_FIELDS,
  FORM_FIELDS,
  HALLPASS_FIELDS,
} from '../constants/tt-my-class.constants';

export enum AssignmentType {
  HALLPASS = 'hallpass',
  BEHAVIOR = 'behavior',
  CONSEQUENCE = 'consequence',
  CHECKIN = 'checkin',
  COMMUNICATION = 'communication',
}

export enum CategoryType {
  HALLPASS = 'hallpass',
  GUIDANCE = 'guidance',
  PRAISE = 'praise',
  CONSEQUENCE = 'consequence',
  MOST_USED = 'most_used',
  MOST_USED_BY_LIST = 'most_used_by_list',
  COMMUNICATION = 'communication',
}

export type ActionCategory = {
  label: string;
  value: CategoryType;
  icon: string;
  id: string; // used for analytics
  iconNamespace?: string;
  hideFromSearch?: boolean;
};

export type ActionItem = {
  value: number;
  label: string;
  color: string;
  icon: string;
  count: number;
  iconNamespace?: string;
  category: ActionCategory;
  assignmentType: AssignmentType;
  data: IConsequenceType | IHallPassType | IPbisType | null;
};

export type ActionGroup = ActionCategory & {
  items: Array<Omit<ActionItem, 'category'>>;
};

export type Assignment = {
  value: number;
  assignmentType: AssignmentType;
};

export type HallPassFormData = {
  [HALLPASS_FIELDS.NOTE]: string;
  [HALLPASS_FIELDS.DURATION]: number;
  [HALLPASS_FIELDS.DATE]: day.Dayjs;
  [HALLPASS_FIELDS.TIME]: string;
  [HALLPASS_FIELDS.APPROVED_BY]?: {
    hash: string;
    name: string;
  };
};

export type BehaviorFormData = {
  [BEHAVIOR_FIELDS.NOTE]: string;
};

export type ConsequenceFormData = {
  [HALLPASS_FIELDS.NOTE]: string;
  [HALLPASS_FIELDS.DATE]: day.Dayjs;
};

export type FormData =
  | {
      [FORM_FIELDS.CATEGORY]: CategoryType;
      [FORM_FIELDS.SELECTED_ACTION]: ActionItem;
      [FORM_FIELDS.SELECTED_STUDENTS]: string[];
      [FORM_FIELDS.LIST_FILTER]: OptionItem[];
      [FORM_FIELDS.SORT_FIRST_NAME_FILTER]: boolean;
      [FORM_FIELDS.HIDE_PICTURE_FILTER]: boolean;
      [FORM_FIELDS.SEARCH_TEXT_FILTER]: string;
    } & (
      | {
          [AssignmentType.HALLPASS]: HallPassFormData;
        }
      | {
          [AssignmentType.BEHAVIOR]: BehaviorFormData;
        }
      | {
          [AssignmentType.CONSEQUENCE]: ConsequenceFormData;
        }
    );

export type FormState =
  | 'idle'
  | 'loading'
  | 'error'
  | 'submitting'
  | 'randomizing';

export type Student = {
  firstName: string;
  lastName: string;
  profileImageUrl: string;
  personHash: string;
  filename: string;
  studentId?: string;
};
