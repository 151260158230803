import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';

import { MgIconModule } from '@app/src/app/icon';

import { GenericModule } from '@shared/components/generic';

import { MgOverlayNavItemModule } from '../../components/MgOverlayNavItem';
import { MgOverlayStructureModule } from '../../directives/MgOverlayStructure';
import { MgLegacyOverlayNavComponent } from './MgLegacyOverlayNav.component';

@NgModule({
  imports: [
    // Minga dependencies
    MgOverlayStructureModule,
    MgOverlayNavItemModule,
    MgIconModule,
    GenericModule,

    // External dependencies
    CommonModule,
    FlexLayoutModule,
  ],
  declarations: [MgLegacyOverlayNavComponent],
  exports: [MgLegacyOverlayNavComponent],
})
export class MgLegacyOverlayNavModule {}
