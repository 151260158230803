import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';

import { ICheckinReason, MembershipListType } from 'libs/domain';
import { mingaSettingTypes } from 'libs/util';
import { combineLatest, Observable, of, ReplaySubject } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';

import { RootService } from '@app/src/app/minimal/services/RootService';
import { HallPassService } from '@app/src/app/services/HallPass';
import { COLOR_PICKER_PRESETS } from '@app/src/app/shared/components/form/constants';
import { MingaSettingsService } from '@app/src/app/store/Minga/services';
import { scrollIntoView } from '@app/src/app/util/scroll-into-view';

import { CrudFormBase } from '@shared/components/crud-form-base/crud-form-base.abstract';
import { markNestedFormGroupAsDirty } from '@shared/components/crud-form-base/crud-form-base.utils';
import {
  fromRestrictionInput,
  setDefaultRestrictionControlValue,
  toRestrictionInput,
} from '@shared/components/form-restriction-input/form-restriction-input.constants';
import { ImageViewerModalComponent } from '@shared/components/image-viewer-modal';
import {
  MODAL_OVERLAY_DATA,
  ModalOverlayPrimaryHeaderBackground,
  ModalOverlayRef,
  ModalOverlayService,
  ModalOverlayServiceCloseEventType,
} from '@shared/components/modal-overlay';
import {
  SystemAlertCloseEvents,
  SystemAlertModalService,
  SystemAlertModalType,
} from '@shared/components/system-alert-modal';
import { SystemAlertSnackBarService } from '@shared/components/system-alert-snackbar';
import { CheckinService } from '@shared/services/checkin';
import { KioskPermissionsService } from '@shared/services/kiosk/kiosk-permissions.service';
import { PbisService } from '@shared/services/pbis';
import QrCodeService from '@shared/services/qrcode';

import { CHECKIN_ICONS } from '../../checkin-manager-reasons.constants';
import {
  CheckinManagerReasonsEditMessages,
  CheckInReasonsFormFields,
  createForm,
  setForm,
} from './checkin-manager-reasons-edit.constants';
import {
  CheckinManagerReasonsEditData,
  CheckinManagerReasonsEditDialogData,
  CheckinManagerReasonsEditDialogResponse,
} from './checkin-manager-reasons-edit.types';

@Component({
  selector: 'mg-checkin-manager-reasons-edit',
  templateUrl: './checkin-manager-reasons-edit.component.html',
  styleUrls: ['./checkin-manager-reasons-edit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckinManagerReasonsEditComponent
  extends CrudFormBase<ICheckinReason>
  implements OnInit, OnDestroy
{
  // Children

  @ViewChild('crudForm', { static: false })
  crudForm?: ElementRef<HTMLElement>;

  // Form

  public readonly form = this._fb.group({
    ...createForm(),
    [CheckInReasonsFormFields.RESTRICTIONS]:
      setDefaultRestrictionControlValue(),
  });

  // Constants

  public readonly MESSAGES: typeof CheckinManagerReasonsEditMessages =
    CheckinManagerReasonsEditMessages;
  public readonly MEMBERSHIP_LIST_TYPES = MembershipListType;
  public readonly FORM_FIELDS = CheckInReasonsFormFields;
  public readonly CHECKIN_ICONS = CHECKIN_ICONS;
  public readonly PRESET_COLORS = Object.values(COLOR_PICKER_PRESETS).reduce(
    (acc, curr) => [...acc, ...curr],
    [],
  );

  // General Subjects

  private _destroyedSubject = new ReplaySubject<void>(1);

  // Reason Data

  private _reason$: Observable<ICheckinReason>;
  data: CheckinManagerReasonsEditData = {
    original: undefined,
    changes: undefined,
  };

  // Getters

  get isNewReason(): boolean {
    return this.dialogData.type === 'new' ? true : false;
  }
  get modalTitle(): string {
    return this.isNewReason
      ? CheckinManagerReasonsEditMessages.MODAL_TITLE_NEW_CHECKIN
      : CheckinManagerReasonsEditMessages.MODAL_TITLE_CHECKIN;
  }

  // General Observables

  public readonly hasPbis$: Observable<boolean> =
    this._settingsService.isPbisModuleEnabled();
  public readonly hasHp$: Observable<boolean> =
    this._settingsService.isHallPassModuleEnabled();
  public readonly hasCons$: Observable<boolean> =
    this._settingsService.getSettingValueObs(
      mingaSettingTypes.BM_CONSEQUENCE_ENABLE,
    );
  public readonly hallPasses$: Observable<any> = this._hallPassService
    .getHallPassTypes(false)
    .pipe(
      map(types => {
        return types
          .map(type => ({
            label: type.name,
            contextLabel: !type.active
              ? CheckinManagerReasonsEditMessages.HALLPASS_DISABLE_MSG
              : '',
            value: type.id,
            disabled: !type.active,
          }))
          .sort((a, b) => {
            const disabledDiff = +a.disabled - +b.disabled;
            if (disabledDiff !== 0) {
              return disabledDiff;
            }
            return a.label.localeCompare(b.label);
          });
      }),
    );
  public readonly behaviors$: Observable<any> = this._behaviorService
    .getTypes(true)
    .pipe(
      takeUntil(this._destroyedSubject),
      map(types => types.map(type => ({ label: type.name, value: type.id }))),
    );
  public readonly consequences$: Observable<any> = this._behaviorService
    .getConsTypes(true)
    .pipe(
      takeUntil(this._destroyedSubject),
      map(types => types.map(type => ({ label: type.name, value: type.id }))),
    );
  public readonly kioskEnabled$: Observable<boolean> = this._settingsService
    .getSettingValueObs(mingaSettingTypes.CHECKIN_KIOSK)
    .pipe(takeUntil(this._destroyedSubject));

  // Component constructor

  constructor(
    @Inject(MODAL_OVERLAY_DATA)
    public dialogData: CheckinManagerReasonsEditDialogData,
    private _modalOverlay: ModalOverlayService,
    private _modalOverlayRef: ModalOverlayRef<
      CheckinManagerReasonsEditDialogResponse,
      CheckinManagerReasonsEditDialogData
    >,
    private _fb: UntypedFormBuilder,
    private _cdr: ChangeDetectorRef,
    private _checkinService: CheckinService,
    private _settingsService: MingaSettingsService,
    private _hallPassService: HallPassService,
    private _behaviorService: PbisService,
    private _qrCodeService: QrCodeService,
    public kioskPermissions: KioskPermissionsService,
    private _rootService: RootService,
    private _systemAlertModal: SystemAlertModalService,
  ) {
    super({
      id: dialogData?.id,
      get: async id => {
        return await this._checkinService.getReason(id);
      },
      create: async data => {
        return await this._checkinService.updateReason(data as ICheckinReason);
      },
      update: async data => {
        return await this._checkinService.updateReason(data as ICheckinReason);
      },
      delete: async data => {
        await this._checkinService.deleteReason(data.id);
        return data;
      },
      onSetForm: (data, isNew) => {
        if (isNew) return;

        setForm(data, this.form);
        if (data.restriction) {
          this.form
            .get(CheckInReasonsFormFields.RESTRICTIONS)
            .patchValue(toRestrictionInput(data.restriction));
        }
      },
      onValidate: () => {
        markNestedFormGroupAsDirty(this.form);
        return this.form.valid;
      },
      onSuccess: (type, data) => {
        if (type === 'delete') {
          this._modalOverlayRef.close(
            ModalOverlayServiceCloseEventType.DELETE,
            {
              deleted: data.id,
            },
          );
        }

        if (type === 'create') {
          this._modalOverlayRef.close(
            ModalOverlayServiceCloseEventType.CREATE,
            {
              created: data,
            },
          );
        }

        if (type === 'update') {
          this._modalOverlayRef.close(
            ModalOverlayServiceCloseEventType.SUBMIT,
            {
              updated: data,
            },
          );
        }
      },
      onSubmit: data => {
        const formValues = this.form.value;

        const reason: ICheckinReason = {
          ...data,
          ...formValues,
          restriction: {
            id: data.restriction?.id ?? undefined,
            ...fromRestrictionInput(
              formValues[CheckInReasonsFormFields.RESTRICTIONS],
              data.restriction,
            ),
          },
        };

        return reason;
      },
      onShowLoader: promise => {
        return this._rootService.addLoadingPromise(promise);
      },
      onCancel: async () => {
        if (this.form.pristine) {
          this._modalOverlayRef.close(ModalOverlayServiceCloseEventType.CLOSE);
          return;
        }

        const modalRef = await this._systemAlertModal.open({
          modalType: SystemAlertModalType.WARNING,
          heading: 'Are you sure you want to discard this reason?',
          message: 'You will lose any unsaved changes',
          closeBtn: 'Cancel',
          confirmActionBtn: 'Discard',
        });

        const response = await modalRef.afterClosed().toPromise();

        if (response?.type === SystemAlertCloseEvents.CONFIRM) {
          this._modalOverlayRef.close(ModalOverlayServiceCloseEventType.CLOSE);
        }
      },
      onDelete: async () => {
        const modalRef = await this._systemAlertModal.open({
          modalType: SystemAlertModalType.WARNING,
          heading: 'Are you sure you want to delete this reason?',
          message: "This action can't be undone",
          closeBtn: 'Cancel',
          confirmActionBtn: 'Delete',
        });

        const response = await modalRef.afterClosed().toPromise();

        return response?.type === SystemAlertCloseEvents.CONFIRM;
      },
      onFormStateChange: state => {
        if (state === 'invalid') {
          // scroll to top of form
          scrollIntoView(this.crudForm.nativeElement, {
            align: { top: 0 },
          });
        }
      },
    });
  }

  // Lifecycle Hooks

  ngOnInit(): void {
    this.init();
    const { data } = this.dialogData;

    this._reason$ = this.isNewReason
      ? of(data as ICheckinReason)
      : this._checkinService.getReasonObs(data as number);
    this._reason$
      .pipe(takeUntil(this._destroyedSubject))
      .subscribe(reason => this._handleReasonSub(reason));

    this._handleDeletedHallPassTypes();
  }

  ngOnDestroy(): void {
    this._destroyedSubject.next();
    this._destroyedSubject.complete();
  }

  // Public Methods

  public async openQrViewer() {
    const url = await this._qrCodeService.createCheckinReasonQrCode(
      this.data.original.id,
    );
    this._modalOverlay.open(ImageViewerModalComponent, {
      data: {
        imageUrl: url,
        title: 'Print QR Code',
        headerColor: ModalOverlayPrimaryHeaderBackground.TEAL,
        buttonColor: ModalOverlayPrimaryHeaderBackground.ORANGE,
      },
    });
  }

  // Private Methods

  private _handleReasonSub(reason: ICheckinReason): void {
    this.data.original = { ...reason };
    this.data.changes = { ...reason };

    this._cdr.markForCheck();
  }

  /**
   * If a hall pass type is deleted, we need to remove it from the checkin reason
   */
  private _handleDeletedHallPassTypes() {
    combineLatest([this._reason$, this.hallPasses$])
      .pipe(
        takeUntil(this._destroyedSubject),
        filter(([reason, _]) => {
          return !!reason.hallPassId;
        }),
      )
      .subscribe();
  }
}
