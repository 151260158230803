import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  HostBinding,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import * as day from 'dayjs';
import * as advFormat from 'dayjs/plugin/advancedFormat';
import * as custParse from 'dayjs/plugin/customParseFormat';
import '@app/src/globals';
import { gateway } from 'libs/generated-grpc-web';

import { AnalyticsService } from '@app/src/app/minimal/services/Analytics';

import { CustomDatePickerHeader } from './CustomDatePickerHeader.component';

day.extend(advFormat);
day.extend(custParse);

export const MG_DATE_TIME_PICKER_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => DateTimePickerComponent),
  multi: true,
};

@Component({
  providers: [MG_DATE_TIME_PICKER_VALUE_ACCESSOR],
  selector: 'mg-date-time-picker',
  templateUrl: './DateTimePicker.component.html',
  styleUrls: ['./DateTimePicker.component.scss'],
})
export class DateTimePickerComponent implements ControlValueAccessor, OnInit {
  private _onChange: (value: any) => void;
  private _onTouched: () => void;

  readonly customDTHeader = CustomDatePickerHeader;
  timeInput: string = '';
  isIos: boolean = !!window.MINGA_APP_IOS;

  showNativeTime: boolean = false;

  private _date: day.Dayjs | null = null;

  get date(): day.Dayjs | null {
    return this._date;
  }

  @Input()
  set date(date: day.Dayjs | null) {
    // to protect against Date objects being passed in
    if (date) {
      this._date = day(date);
    } else {
      this._date = null;
    }
  }

  @ViewChild('time-picker', { static: true })
  timePickerEl: ElementRef;

  @Input()
  disabled: boolean = false;

  @Input()
  minDate: Date | null = null;

  @Input()
  maxDate: Date | null = null;

  @Input()
  placeholder: string = '';

  @Input()
  datePlaceholder: string = '';

  @Input()
  timePlaceholder: string = '';

  @Input()
  floatLabel: string = '';

  @Input()
  utc: boolean = false;

  @Input()
  dateOnly: boolean = false;

  @Input()
  timeOnly: boolean = false;

  @Input()
  canReset: boolean = false;

  @Output()
  dateOnlyChange: EventEmitter<boolean> = new EventEmitter();

  @Input('appearance')
  appearance: string = 'outline';

  @Input()
  timePrefix: string = '';

  @Input()
  datePrefix: string = '';

  @Input()
  dateHintLocale: string = '';

  @Input('mgMultiLineHint')
  multiLineHint: boolean = false;

  @Input()
  mgFormStyles: boolean = true;

  @Input()
  required: boolean = false;

  @Input()
  dateError: string = '';

  @Input()
  timeError: string = '';

  @Input()
  stackInputs: boolean = false;

  // Pass-through to mgFormField
  @Input()
  hideOptionalMarker: boolean = false;

  @Input()
  hasCustomHeader: boolean = false;

  get shouldShowDateResetIcon(): boolean {
    return !!this.date && this.canReset;
  }

  get shouldShowTimeResetIcon(): boolean {
    return !!this.timeInput && this.canReset;
  }

  @HostBinding('class.row-flex')
  get rowFlex(): boolean {
    return !this.stackInputs && !this.dateOnly && !this.timeOnly;
  }

  get timeFormat() {
    let format = 'h:mm a';
    if (this.isIos) {
      format = 'h:mm A';
    }

    return format;
  }

  get displayDate() {
    if (!this.date) {
      return '';
    }

    let m = day(this.date);

    return m.format('MMM Do, YYYY');
  }

  // Ignore changes to display date. That's handled through actual date changes
  set displayDate(value) {}

  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    private analytics: AnalyticsService,
    private element: ElementRef,
  ) {}

  ngOnInit() {
    if (
      !window.MINGA_APP_BROWSER &&
      (<any>window).cordova.plugins['DateTimePicker']
    ) {
      this.showNativeTime = true;
    }
  }

  private _showNativePicker() {
    const datePicker = (<any>window).cordova.plugins['DateTimePicker'];
    datePicker.show(
      {
        mode: 'time',
        date: this.date ? this.date.toDate() : new Date(),
        // locale: 'EN',
        okText: 'Select',
        cancelText: 'Cancel',
        minuteInterval: 5,
        allowOldDates: true,
        allowFutureDates: true,
        // android only options
        is24HourView: false,
      },
      (newDate: any) => {
        console.log('native date success', newDate);
        if (typeof newDate == 'object') {
          // expected with android, date is returned on the success event
          this._onPickerDateSuccess(newDate);
        } else {
          // on done callback for ios, expect date string only in format
          // MM/DD/YYYY
        }
      },
      (err: any) => {
        // Handle error.
        const message = '_showNewNativePicker error: ' + err.message;
        console.error(message);
        this.analytics.sendException(
          message,
          gateway.connect_pb.errorPriority.ALERT,
        );
      },
      (newDate: Date) => {
        console.log('native date success 2', newDate);
        // update when changed
        this._onPickerDateSuccess(newDate);
      },
    );
  }

  private _onPickerDateSuccess(newDate: Date) {
    // Handle new date.

    // convert date string into h::mm a
    const dateGiven = new Date(newDate);
    let hoursGiven = dateGiven.getHours();

    let suffix = 'am';
    if (hoursGiven >= 12) {
      hoursGiven -= 12;
      suffix = 'pm';
    }
    const minutesFormatted = ('0' + dateGiven.getMinutes()).slice(-2);

    const dateString = `${hoursGiven}:${minutesFormatted} ${suffix}`;
    this.timeInput = dateString;

    console.log('new time input on picker success', dateGiven, dateString);

    this._setDateFromTimeString(dateString);
    this.timeInputBlur();

    this._changeDetectorRef.detectChanges();
  }

  resetDate(e?: Event) {
    if (e) {
      e.stopImmediatePropagation();
      e.preventDefault();
    }
    this.date = null;
    this.timeInput = '';

    this.triggerOnTouched();
    this.triggerOnChange();
  }

  resetTimeInput(e) {
    e.stopImmediatePropagation();
    e.preventDefault();
    this.timeInput = '';

    this.triggerOnTouched();
    this.triggerOnChange();
  }

  showTimePicker(event) {
    if (!window.MINGA_APP_BROWSER) {
      const datePicker = (<any>window).cordova.plugins['DateTimePicker'];
      if (!datePicker) {
        const message = '[DateTimePicker] showTimePicker() missing datePicker';
        console.error(message);
        this.analytics.sendException(
          message,
          gateway.connect_pb.errorPriority.ALERT,
        );
      }
      if (event) {
        event.stopImmediatePropagation();
        event.preventDefault();
      }

      if (window.MINGA_APP_IOS || window.MINGA_APP_ANDROID) {
        this._showNativePicker();
      }
    }
  }

  registerOnChange(fn) {
    this._onChange = fn;
  }

  registerOnTouched(fn) {
    this._onTouched = fn;
  }

  triggerOnTouched() {
    if (this._onChange) {
      this._onTouched();
    }
  }

  triggerOnChange() {
    if (this._onChange) {
      if (this.date && this.utc) {
        this._onChange(day(this.date).utc(true).toDate());
      } else if (this.date) {
        this._onChange(this.date.toDate());
      } else {
        this._onChange(null);
      }
    }
  }

  writeValue(value: any) {
    if (!value) {
      this.date = null;
      this.timeInput = '';
      return;
    }

    let m = day(value);

    if (!m.isValid()) {
      console.warn('<mg-date-time-picker> ignoring invalid date');
    } else {
      if (this.utc) {
        m = m.utc();
      }

      this.date = m;
      this.timeInput = m.format(this.timeFormat);
      this._changeDetectorRef.detectChanges();
    }
  }

  getDatePlaceholder() {
    return this.datePlaceholder || this.placeholder || '';
  }

  getTimePlaceholder() {
    return this.timePlaceholder || this.placeholder || '';
  }

  dateChange(value) {
    if (value === null) {
      this.resetDate();
    } else if (!this.timeInput) {
      this.resetTime();
    } else {
      this.date = value;
      // set the time from the timeInput (else will get reset to 0:00:00)
      this._setDateFromTimeString(this.timeInput);
    }

    this.triggerOnTouched();
    this.triggerOnChange();
  }

  private _setDateFromTimeString(value: string) {
    const m = this._parseTimeInput(value);

    if (m.isValid()) {
      const hours = m.hour();
      const minutes = m.minute();

      if (!this.date) {
        this.date = day();
      }
      if (this.date) {
        this.date = this.date.hour(hours);
        this.date = this.date.minute(minutes);
        this.date = this.date.second(0);
        this.date = this.date.millisecond(0);
      }

      this.triggerOnTouched();
      this.triggerOnChange();
    } else {
      console.warn(`set date string: invalid time format provided: ${value}`);
    }
  }

  private _parseTimeInput(value: string) {
    value = value.trim();
    let m: day.Dayjs;
    const formats = [
      this.timeFormat,
      'ha',
      'h a',
      'h:mma',
      'h:mmA',
      'h:mm a',
      'h:mm A',
    ];
    for (const format of formats) {
      m = day(value, format, true);
      if (m.isValid()) {
        break;
      }
    }
    return m;
  }

  timeInputChange(value) {
    this._setDateFromTimeString(value);
  }

  resetTime() {
    if (!this.date) {
      this.date = day();
    }
    if (this.date) {
      if (this.dateOnly) {
        // when only date input, set the time to the start of the day
        this.date = this.date.hour(0);
      } else {
        this.date = this.date.hour(12);
      }
      this.date = this.date.minute(0);
      this.date = this.date.second(0);
      this.date = this.date.millisecond(0);
      this.timeInput = this.date.format(this.timeFormat);
    }
  }

  timeInputBlur() {
    if (!this.timeInput) {
      return this.resetTime();
    }

    const m = this._parseTimeInput(this.timeInput);
    const isNewDate = !!this.date ? !m.isSame(this.date) : true;

    if (m.isValid()) {
      // if not a new date, then nothing to do...
      if (!isNewDate) return;

      this.timeInput = m.format(this.timeFormat);
      let hours = m.hour();
      let minutes = m.minute();

      if (!this.date) {
        this.date = day();
      }
      if (this.date) {
        this.date.hour(hours);
        this.date.minute(minutes);
        this.date.second(0);
        this.date.millisecond(0);
      }
    } else {
      console.warn(`invalid time input provided: ${this.timeInput}`);
      this.resetTime();
    }

    this.triggerOnTouched();
    this.triggerOnChange();
  }

  getTimeInputType() {
    if (window.MINGA_DEVICE_IOS) {
      return 'time';
    } else {
      return 'text';
    }
  }
}
