import * as day from 'dayjs';
import { PersonViewMinimal } from 'libs/generated-grpc-ts';

import { EditableReportRecord } from '../';
import { Restriction } from '../restrictions';

export interface IConsequence {
  id: number;
  typeId: number;
  type?: IConsequenceType;
  status?: string;
  completedAt?: Date;
  completeBy?: Date;
  complete?: boolean;
  issuedAt: Date;
  issuedTo: number;
  recipientId: number;
  issuedToPerson?: PersonViewMinimal.AsObject;
  issuedToEmail?: string;
  issuedBy?: number;
  issuedByPerson?: PersonViewMinimal.AsObject;
  mingaId?: number;
  imported?: boolean;
  note?: string;
  automationName?: string;
  sourceId?: number | null;
  automationId?: number;
  handledByOverdueAction?: OverdueConsequenceActionType | null;
  actionName?: string;
  completedBy?: number;
  completedByPerson?: PersonViewMinimal.AsObject;
  actionId?: number;
  sourceOverdueActionType: string | null;
}

export type EditableConsequence = IConsequence & EditableReportRecord;

export interface UpdateConsequences {
  id?: number;
  type: number;
  status?: string;
  completedAt?: day.Dayjs;
  completeBy?: day.Dayjs;
  issuedAt?: day.Dayjs;
  issuedTo: string[];
  issuedBy?: number;
  issuedByPerson?: string;
  notes?: string;
  automationId?: number;
  sourceId?: number;
  actionId?: number;
  handledByOverdueAction?: OverdueConsequenceActionType;
}

export enum OverdueConsequenceActionType {
  ADDITIONAL = 'additional',
  ESCALATE = 'escalate',
}

export type OverdueConsequenceAction = {
  type: OverdueConsequenceActionType;
  consequenceId: number;
  completeBy: number | null;
};

export interface IConsequenceType {
  id: number;
  name: string;
  description?: string;
  categoryId: number;
  addNotes: boolean;
  sendParentEmail: boolean;
  sendParentSms: boolean;
  sendAdminEmail: boolean;
  adminEmails: string[];
  active: boolean;
  dailyConsequenceCount?: number;
  points?: number;
  type?: number;
  stickerId?: number;
  emailTemplate?: {
    subject: string;
    body: string;
  };
  smsTemplate?: {
    body: string;
  };
  iconType?: string;
  iconColor?: string;
  enableDueDate: boolean;
  removeStickerId?: number;
  restriction?: Restriction;
  overdueActionId: number | null;
  overdueActionDueDate: number | null;
  overdueActionType: OverdueConsequenceActionType | null;
  overdueAction: ExistingConsequenceType | null;
}

export type ExistingConsequenceType = IConsequenceType & {
  dailyConsequenceCount: number;
};

export interface IConsequenceSummaryResult {
  typeId: number;
  year?: number;
  month?: number;
  date?: string;
  hour?: number;
  week?: number;
  total?: number;
  typeName: string;
  typeCategory: number;
  totalActive?: number;
  totalComplete?: number;
  totalOverdue?: number;
}

export enum ConsequenceTypesEnum {
  NOTIFICATION = 1,
  WARNING,
  DETENTION,
  REFERRAL,
  OTHER,
}

export const autoCompleteTypes = [
  ConsequenceTypesEnum.WARNING,
  ConsequenceTypesEnum.NOTIFICATION,
  ConsequenceTypesEnum.OTHER,
];

export enum ConsequenceStatuses {
  OVERDUE = 'Overdue',
  NOT_COMPLETED = 'Not completed',
  COMPLETE = 'Completed',
}

export const ConsStatus = {
  OVERDUE: ConsequenceStatuses.OVERDUE,
  NOT_COMPLETED: ConsequenceStatuses.NOT_COMPLETED,
  COMPLETED: ConsequenceStatuses.COMPLETE,
};

export interface IConsequenceClassTypeCount {
  count: number;
  id: number;
  name: string;
  description?: string;
  categoryId: number;
  addNotes: boolean;
  active: boolean;
  iconType?: string;
  iconColor?: string;
  enableDueDate: boolean;
}
