// GENERATED CODE -- DO NOT EDIT!

import {grpc} from '@improbable-eng/grpc-web';

import * as gateway_mention_pb from '../gateway/mention_pb';
import * as gateway_person_view_pb from '../gateway/person_view_pb';
import * as common_legacy_pb from '../common/legacy_pb';

export class Mention {
  static readonly serviceName = "Mention";

  static readonly searchMention = {
    methodName: "searchMention",
    service: Mention,
    requestStream: false,
    responseStream: false,
    requestType: gateway_mention_pb.SearchMentionRequest,
    responseType: gateway_mention_pb.SearchMentionResponse,
  };

}

export class MentionClient {
  searchMention() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
}
