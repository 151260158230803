import { Pipe, PipeTransform } from '@angular/core';

import { getAppColor, MingaAppColor } from '@shared/constants';

@Pipe({
  name: 'appColor',
})
export class AppColorPipe implements PipeTransform {
  transform(color: MingaAppColor): string {
    if (!color) return '';

    // if user passes a hex color, just use it
    if (color.startsWith('#')) return color;

    // otherwise this is a color from our system so lets retrieve it via css var
    return getAppColor(color);
  }
}
