import { TemplatePortal } from '@angular/cdk/portal';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostListener,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { NavigationStart, Router } from '@angular/router';

import { legacy_pb } from 'libs/generated-grpc-web';
import { ReplaySubject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

import { MgValidators } from '@app/src/app/input/validators';
import { AppConfigService } from '@app/src/app/minimal/services/AppConfig';
import { AuthInfoService } from '@app/src/app/minimal/services/AuthInfo';

import { LandingRoute } from '@modules/landing';

import {
  SystemAlertCloseEvents,
  SystemAlertModalService,
  SystemAlertModalType,
} from '@shared/components/system-alert-modal';
import { SystemAlertSnackBarService } from '@shared/components/system-alert-snackbar';
import { KioskPermissionsService } from '@shared/services/kiosk/kiosk-permissions.service';
import { KioskService as KioskShared } from '@shared/services/kiosk/kiosk.service';

import {
  KIOSK_ACTION_CONFIG,
  KioskCategoryMessage,
  KioskMenuAction,
  KioskMessage,
  KioskRoute,
} from './constants';
import {
  KioskAudioService,
  KioskLayoutService,
  KioskService,
} from './services';

@Component({
  selector: 'mg-kiosk',
  templateUrl: './kiosk.component.html',
  styleUrls: ['./kiosk.component.scss'],
  providers: [KioskAudioService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KioskComponent implements OnInit, OnDestroy {
  @ViewChild('logOutTemplate') logOutTemplate: TemplateRef<any>;
  // Constants

  public readonly MSG = KioskMessage;
  public readonly ACTIONS = KioskMenuAction;

  // Clean up

  private readonly _destroyedSubject = new ReplaySubject<void>(1);

  // Portals

  public footerPortal: TemplatePortal<unknown> | null = null;

  // Check if one of the kiosk settings have been enabled
  public isKioskEnabled$ = this._kioskPermissions.isKioskEnabled();

  // Check if a kiosk is set up
  public isKioskMode$ = this.kiosk.isKioskMode$;

  public form = this._fb.group({
    email: ['', [Validators.required]],
    pin: ['', [Validators.required, MgValidators.KioskPinValidator]],
  });

  constructor(
    public kiosk: KioskService,
    public kioskLayout: KioskLayoutService,
    public viewContainerRef: ViewContainerRef,
    private _cdr: ChangeDetectorRef,
    private _router: Router,
    private _appConfig: AppConfigService,
    private _kioskPermissions: KioskPermissionsService,
    private _fb: UntypedFormBuilder,
    private _authInfo: AuthInfoService,
    private _systemAlertModal: SystemAlertModalService,
    private _kioskShared: KioskShared,
    private _snackbar: SystemAlertSnackBarService,
    private _dialog: MatDialog,
  ) {}

  @HostListener('contextmenu')
  _allowContextMenu() {
    return false;
  }

  // Lifecycle

  ngOnInit(): void {
    this._setKioskMode();
    this._portalLayoutFooterSubscription();
    this._preventNavigationChangesOutsideKiosk();
    const emailControl = this.form.get('email');
    emailControl.disable();
    emailControl.setValue(this._authInfo.authPerson.email);
  }

  ngOnDestroy(): void {
    this._destroyedSubject.next();
    this._destroyedSubject.complete();
  }

  // Private methods
  private _portalLayoutFooterSubscription() {
    return this.kioskLayout.footerContent$
      .pipe(takeUntil(this._destroyedSubject))
      .subscribe(templateRef => {
        setTimeout(() => {
          if (!templateRef) {
            this.footerPortal = null;
          } else {
            this.footerPortal = new TemplatePortal<unknown>(
              templateRef,
              this.viewContainerRef,
            );
          }
          this._cdr.markForCheck();
        }, 0);
      });
  }

  private _setKioskMode() {
    this._kioskPermissions.kioskSettings$
      .pipe(takeUntil(this._destroyedSubject), take(1))
      .subscribe(
        ([hallPassKioskEnabled, checkInKioskEnabled, flexTimeKioskEnabled]) => {
          // XOR operation
          if (
            hallPassKioskEnabled !==
            (checkInKioskEnabled || flexTimeKioskEnabled)
          ) {
            if (hallPassKioskEnabled) {
              this.kiosk.setCategory(KioskCategoryMessage.HALLPASS);
            } else if (checkInKioskEnabled || flexTimeKioskEnabled) {
              this.kiosk.setCategory(KioskCategoryMessage.CHECKIN);
            }
          }
        },
      );
  }

  public async devReset() {
    const env = await this._appConfig.getEnvironment();
    if (env === 'dev' || env === 'stage') this.kiosk.resetKiosk();
  }

  public async handleKioskAction(action: KioskMenuAction) {
    const requiresPin = this._appConfig.getValue('kioskLogoutRequiresPin');
    this.kiosk.alertModalOpenSubject.next(true);

    try {
      if (requiresPin) {
        await this._handleActionWithPin(action);
      } else {
        await this._handleActionWithoutPin(action);
      }
    } catch (error) {
      this._snackbar.error('An error occured, please try again');
    }
  }

  public async sendEmail() {
    try {
      const response = await this.kiosk.requestPin();

      if (response !== legacy_pb.StatusCode.OK)
        throw new Error('Unable to send email, please try again');

      const modalRef = await this._systemAlertModal.open({
        modalType: SystemAlertModalType.SUCCESS,
        heading: KioskMessage.SYSTEM_ALERT_MODAL_PIN_SUCCESS_TITLE,
        confirmActionBtn: 'Back',
        closeBtn: 'Cancel',
      });
      const { type } = await modalRef.beforeClosed().toPromise();
      if (type === SystemAlertCloseEvents.CLOSE)
        this._systemAlertModal.closeAll();
    } catch (error) {
      this._snackbar.error('Unable to send email, please try again');
    }
  }

  private async _handleActionWithoutPin(action: KioskMenuAction) {
    await this._handleAction(action);
  }

  private async _handleActionWithPin(action: KioskMenuAction) {
    // resetting the pin field to pristine in order to avoid validation errors
    this.form.get('pin').reset();

    const modalConfig = KIOSK_ACTION_CONFIG[action];

    const modalRef = await this._systemAlertModal.open({
      modalType: SystemAlertModalType.WARNING,
      heading: modalConfig.heading,
      confirmActionBtn: modalConfig.confirmActionBtn,
      closeBtn: 'Cancel',
      templateRef: this.logOutTemplate,
    });
    const result = await modalRef.beforeClosed().toPromise();

    if (result?.type === SystemAlertCloseEvents.CONFIRM) {
      const pin = this.form.get('pin').value;
      const validPin = await this._kioskShared.validatePin(pin);
      if (validPin) {
        await this._handleAction(action);
      } else {
        const errorModalRef = await this._systemAlertModal.open({
          modalType: SystemAlertModalType.ERROR,
          heading: KioskMessage.SYSTEM_ALERT_MODAL_LOGOUT_ERROR_TITLE,
          message: KioskMessage.SYSTEM_ALERT_MODAL_LOGOUT_ERROR_SUBTITLE,
        });
        await errorModalRef.beforeClosed().toPromise(); // needed for focus flow to wait until modal is closed
      }
    }
    this.kiosk.alertModalOpenSubject.next(false);
  }

  private async _handleAction(action: KioskMenuAction) {
    switch (action) {
      case KioskMenuAction.LOGOUT:
        await this.kiosk.logout();
        break;
      case KioskMenuAction.EDIT:
        await this.kiosk.editKiosk();
        break;
      case KioskMenuAction.RESET:
        this.kiosk.resetKiosk();
        break;
    }
  }

  /**
   * We have route guards on all existing routes to ensure that the user is always
   * returned to kiosk but this is a extra safe guard in case new routes are added without it
   */
  private _preventNavigationChangesOutsideKiosk() {
    const kioskRoute = `/${KioskRoute.ROOT}`;
    this._router.events
      .pipe(takeUntil(this._destroyedSubject))
      .subscribe(event => {
        if (event instanceof NavigationStart) {
          const nonKioskRoute = !event.url.includes(kioskRoute);
          const notKioskLogin = event.url.includes(LandingRoute.KIOSK_LOGIN);

          if (nonKioskRoute && notKioskLogin) {
            this._router.navigate([kioskRoute]);
          }
        }
      });
  }
}
