import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { EditableConsequence, ConsequenceStatuses } from 'libs/domain';
import { MingaPermission } from 'libs/domain';
import {
  BehaviorReportColumns,
  ColumnInfo,
  TemplateColumnKeys,
} from 'libs/shared';

import { ReportDatasourceService } from '@app/src/app/components/manager-report/services/report-datasource.service';
import { AuthService } from '@app/src/app/minimal/services/Auth';
import { PermissionsService } from '@app/src/app/permissions';

import { ModalOverlayService } from '@shared/components/modal-overlay';
import { SystemAlertModalService } from '@shared/components/system-alert-modal';
import { SystemAlertSnackBarService } from '@shared/components/system-alert-snackbar';

import { BmReportsConsHistoryEditComponent } from '../components/bm-reports-cons-history/bm-reports-cons-history-edit.component';
import { BmReportsService } from './bm-reports.service';

@Injectable()
export class BmReportsConsequenceHistoryDatasourceService extends ReportDatasourceService<EditableConsequence> {
  protected _editForm = BmReportsConsHistoryEditComponent;

  constructor(
    _router: Router,
    private _pbisReportsService: BmReportsService,
    _alertModal: SystemAlertModalService,
    private _permissions: PermissionsService,
    _snackBar: SystemAlertSnackBarService,
    _modalOverlay: ModalOverlayService,
    private _authService: AuthService,
  ) {
    super(
      _router,
      _pbisReportsService,
      BmReportsConsequenceHistoryDatasourceService.name,
      _alertModal,
      _snackBar,
      _modalOverlay,
    );
    this._archiveFn = this._pbisReportsService.deleteConsequence.bind(
      this._pbisReportsService,
    );
    let displayCol: ColumnInfo[] = [
      { key: TemplateColumnKeys.EDIT, header: '', columnClasses: ['w-16'] },
      { header: '', key: TemplateColumnKeys.ARCHIVE, columnClasses: ['w-16'] },
    ];
    if (!this._permissions.hasPermission(MingaPermission.PBIS_TYPE_MANAGE))
      displayCol = [];
    super.setDisplayColumns(
      BehaviorReportColumns.CONSEQUENCE_HISTORY,
      displayCol,
    );
  }

  async fetch(offset: number, limit: number) {
    return await this._pbisReportsService.getConsequenceHistory(
      offset,
      limit,
      this._currentSort,
    );
  }

  toggleComplete(ids: number[], complete: boolean) {
    const currentUser =
      this._authService.getPersonViewMinimalFromCurrentPerson();
    this._tableLoading.next(true);
    // Angular change detection is fickle, need to copy and replace the array and row
    const itemsCopy = [...this._items];
    for (let i = 0; i < itemsCopy.length; i++) {
      const row = itemsCopy[i];
      if (ids.includes(row.id)) {
        const newRow = {
          ...row,
          complete,
          completedAt: complete ? new Date() : undefined,
          completedByPerson: complete ? currentUser.toObject() : undefined,
          status: complete
            ? ConsequenceStatuses.COMPLETE
            : ConsequenceStatuses.NOT_COMPLETED,
        };
        itemsCopy[i] = newRow;
      }
    }

    this._items = itemsCopy;
    this._itemsSubject.next(this._items);
    this._tableLoading.next(false);
  }
}
