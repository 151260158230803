import { Injectable } from '@angular/core';

import { SentryService } from '@app/src/app/minimal/services/Sentry/Sentry.service';

/**
 * Service to handle the brightness of the device. Currently only works on
 * mobile devices.
 */
@Injectable({ providedIn: 'root' })
export class BrightnessService {
  /** float 0-1 is a brightness level, -1 represents a system default */
  private _brightness: number = null;
  private _brightnessPlugin = (window as any).cordova?.plugins?.brightness;

  constructor(private _sentryService: SentryService) {}

  public deviceSupportsBrightnessChange(): boolean {
    return (window as any).cordova?.plugins?.brightness;
  }

  public async cacheUsersBrightness(): Promise<number> {
    return new Promise((resolve, reject) => {
      try {
        this._brightnessPlugin.getBrightness(
          current => {
            this._brightness = current;
            resolve(current);
          },
          e => {
            this._sentryService.captureMessageAsError(
              'Issue with caching brightness',
              e,
            );
            reject(e);
          },
        );
      } catch (e) {
        this._sentryService.captureMessageAsError(
          'Error caching user brightness',
          e,
        );
        reject(e);
      }
    });
  }

  /**
   * @param brightness 0-1 float
   */
  public async setBrightness(brightness: number): Promise<'OK'> {
    return await this._setBrightness(brightness);
  }

  public async revertBrightness() {
    if (typeof this._brightness !== null) {
      return await this._setBrightness(this._brightness);
    }
  }

  private async _setBrightness(brightness: number): Promise<'OK'> {
    return new Promise((resolve, reject) => {
      this._brightnessPlugin.setBrightness(
        brightness,
        success => {
          resolve('OK');
        },
        e => {
          this._sentryService.captureMessageAsError(
            '_Setting brightness error',
            e,
          );
          reject(e);
        },
      );
    });
  }
}
