import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

import { BmReportsFilters } from 'libs/domain';
import {
  BehaviorSubject,
  Observable,
  ReplaySubject,
  combineLatest,
  from,
} from 'rxjs';
import { map, takeUntil, tap } from 'rxjs/operators';

import { Person } from '@app/src/app/people';
import { GradesService } from '@app/src/app/services/Grades';

import {
  ScheduledReportEditFormFields,
  getFilterGroupControlKey,
} from '@modules/minga-manager/components/mm-scheduled-reports/constants';
import {
  ClientSupportedScheduledReportFilter,
  ScheduledReportType,
} from '@modules/minga-manager/components/mm-scheduled-reports/types';

import { UserListCategory } from '@shared/components/user-list-filter/user-list.types';
import { PbisService } from '@shared/services/pbis';

import { setIssuedOnPeopleSelect } from '../../utils/mm-sr-reports-edit.utils';
import {
  ConsequenceFields,
  ConsequenceMessages,
} from './sr-consequence-overdue-filters.constants';

@Component({
  selector: 'mg-sr-consequence-overdue-filters',
  templateUrl: './sr-consequence-overdue-filters.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SrConsequenceOverdueFiltersComponent implements OnInit, OnDestroy {
  private _destroyed$ = new ReplaySubject<void>(1);

  @Input() public form: UntypedFormGroup;
  @Input() defaultValues$: Observable<ClientSupportedScheduledReportFilter>;

  private _filtersSubject = new BehaviorSubject<Record<ConsequenceFields, any>>(
    null,
  );
  public filters$ = this._filtersSubject.asObservable();
  public PARENT_FIELDS = ScheduledReportEditFormFields;

  public UserListCategory = UserListCategory;

  private _defaultIssuedHashesSubject = new BehaviorSubject<string[]>([]);
  public defaultIssuedHashes$ = this._defaultIssuedHashesSubject.asObservable();
  public gradeOptions$ = this._gradesService.grades$.pipe(
    tap(() => this._gradesService.fetchIfNeeded()),
    map(grades => {
      return grades.map(grade => ({ label: grade, value: grade }));
    }),
  );
  public MESSAGES = ConsequenceMessages;
  public FIELDS = ConsequenceFields;
  public getFilterGroupControlKey = getFilterGroupControlKey;
  public filterGroup;

  public consequenceTypes$ = this._pbisService
    .getConsTypes(true)
    .pipe(
      map(types => types.map(type => ({ label: type.name, value: type.id }))),
    );

  constructor(
    private _pbisService: PbisService,
    private _gradesService: GradesService,
  ) {}

  ngOnInit(): void {
    // wait till we have all the dropdown data before setting values on it
    combineLatest([
      this.defaultValues$,
      this.gradeOptions$,
      this.consequenceTypes$,
    ])
      .pipe(takeUntil(this._destroyed$))
      .subscribe(([defaultValues]) => {
        this._onReady(defaultValues);
      });
  }

  ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  public onPersonSelected(people: Partial<Person>[]) {
    setIssuedOnPeopleSelect(people, this.form, {
      reportType: ScheduledReportType.PBIS_HISTORY,
      issuedToKey: this.FIELDS.ISSUED_TO,
      issuedByKey: this.FIELDS.ISSUED_BY,
    });
  }

  private _onReady(defaultValues: ClientSupportedScheduledReportFilter) {
    const {
      issuedTo = [],
      issuedBy = [],
      grades = [],
      consTypes = [],
      userList = [],
    } = (defaultValues?.filters || {}) as BmReportsFilters;

    this._defaultIssuedHashesSubject.next([...issuedTo, ...issuedBy]);

    this._filtersSubject.next({
      [ConsequenceFields.ISSUED_TO]: [issuedTo],
      [ConsequenceFields.ISSUED_BY]: [issuedBy],
      [ConsequenceFields.TYPE]: [consTypes],
      [ConsequenceFields.GRADE]: [grades],
      [ConsequenceFields.USER_LIST]: [userList],
    });
  }
}
