export enum HallPassStatusEnum {
  ACTIVE = 'Active',
  EXPIRED = 'Expired', // Not used. Sinonymous with 'ended'
  SCHEDULED = 'Scheduled',
  OVERDUE = 'Overdue',
  ENDED = 'Ended',
  PENDING_APPROVAL = 'Pending',
  DENIED = 'Denied',
  OVERRIDDEN = 'Overridden',
  APPROVED = 'Approved',
  RESTRICTED = 'Restricted',
}
