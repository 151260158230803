import { Injectable, NgZone } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { grpc } from '@improbable-eng/grpc-web';

import {
  ValidateAssignmentRequest,
  ValidateAssignmentResponse,
} from '../flex_time/flex_time_activity_pb';

import {
  FlexTimeActivity as __FlexTimeActivity,
} from '../flex_time/flex_time_activity_pb_service';

@Injectable({providedIn: 'root'})
export class FlexTimeActivity {

  constructor(private _ngZone: NgZone) {
  }

  validateAssignment(request: ValidateAssignmentRequest): Promise<ValidateAssignmentResponse>;
  validateAssignment(request: ValidateAssignmentRequest, metadata: grpc.Metadata): Promise<ValidateAssignmentResponse>;
  validateAssignment(request: ValidateAssignmentRequest, callback: (err: any|null, response: ValidateAssignmentResponse, metadata: grpc.Metadata) => void): void;
  validateAssignment(request: ValidateAssignmentRequest, metadata: grpc.Metadata, callback: (err: any|null, response: ValidateAssignmentResponse, metadata: grpc.Metadata) => void): void;

  validateAssignment(request: ValidateAssignmentRequest, arg1?: grpc.Metadata|((err: any|null, response: ValidateAssignmentResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: ValidateAssignmentResponse, metadata: grpc.Metadata) => void): Promise<ValidateAssignmentResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<ValidateAssignmentResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__FlexTimeActivity.ValidateAssignment, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

}

