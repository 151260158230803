import {
  Injectable,
  InjectionToken,
  Injector,
  Optional,
  SkipSelf,
} from '@angular/core';

import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { distinctUntilChanged, map, switchMap, tap } from 'rxjs/operators';

import { Group } from '@app/src/app/groups/models';
import {
  IRouterDerivedStateGroupFetcher,
  ROUTER_DERIVED_STATE_GROUP_FETCHER,
} from '@app/src/app/state/tokens/routerDerivedStateGroupFetcher';

@Injectable({ providedIn: 'root' })
export class RouterDerivedStateService {
  constructor(
    @Optional() @SkipSelf() parentModule: RouterDerivedStateService,
    private store: Store<any>,
    private injector: Injector,
  ) {
    // if(parentModule) {
    //   throw new Error(
    //     'RouterDerivedStateService already loaded. Import it in root module
    //     only');
    // }
  }

  currentGroupHash$: Observable<string> = this.store.pipe(
    map(state => {
      if (state.router && state.router.state && state.router.state.params) {
        return state.router.state.params.groupHash || '';
      }

      return '';
    }),
  );

  currentGroup$: Observable<Group | null> = this.currentGroupHash$.pipe(
    distinctUntilChanged(),
    switchMap(groupHash => {
      if (!groupHash) {
        return of(null);
      }

      let groupFetcher: IRouterDerivedStateGroupFetcher | null = null;
      groupFetcher = this.injector.get(
        ROUTER_DERIVED_STATE_GROUP_FETCHER,
        null,
      );

      if (!groupFetcher) {
        console.error(
          `Cannot get current group within module that does not provide 'ROUTER_DERIVED_STATE_GROUP_FETCHER'`,
        );
        return of(null);
      }

      return groupFetcher.getGroup(groupHash);
    }),
  );

  isCurrentGroupMember$: Observable<boolean> = this.currentGroup$.pipe(
    map(group => !!(group && Group.isMember(group))),
  );
}
