import { Injectable } from '@angular/core';

import { IHallPass } from 'libs/domain';
import { hall_pass_pb } from 'libs/generated-grpc-web';
import { dateTimeObjectToDateObject } from 'libs/shared-grpc';
import { mingaSettingTypes } from 'libs/util';

import { HallPassService } from '@app/src/app/services/HallPass';
import { MingaSettingsService } from '@app/src/app/store/Minga/services';

import { ErrorHandlerService } from '@shared/services/error-handler';

import {
  KioskHallPassSummaryData,
  KioskHallPassTypeData,
  KioskPerson,
  KioskType,
} from '../types';

@Injectable()
export class KioskHallPassService {
  // Service constructor

  constructor(
    private _errorHandler: ErrorHandlerService,
    private _hallPassService: HallPassService,
    private _mingaSettings: MingaSettingsService,
  ) {}

  public async createPass(
    hallPassTypeId: number,
    person: KioskPerson,
    duration: number,
  ) {
    try {
      return await this._hallPassService.createHallPass(
        hallPassTypeId,
        [person.personHash],
        duration,
        {
          createdViaKiosk: true,
        },
      );
    } catch (error) {
      throw this._errorHandler.gateWayError(
        `Failed to create hall pass`,
        error,
        true,
      );
    }
  }

  public async endPass(hallPassId: number, endedByIdHash: string) {
    try {
      return await this._hallPassService.expireHallPass(
        hallPassId,
        new Date(),
        endedByIdHash,
      );
    } catch (error) {
      throw this._errorHandler.gateWayError(
        `Sorry, could not end hall pass`,
        error,
        true,
      );
    }
  }

  public async fetchPassesForPerson(personHash: string): Promise<IHallPass> {
    try {
      const passes = await this._hallPassService.getHallPassesForRecipient(
        personHash,
      );
      const activePass = passes.find(pass => pass.endDate > new Date());

      return activePass || null;
    } catch (error) {
      throw this._errorHandler.gateWayError(
        `Error fetching hall passes`,
        error,
        true,
      );
    }
  }

  public hallPassToSummary(
    pass: IHallPass | hall_pass_pb.HallPassWithType.AsObject,
    type?: KioskType<KioskHallPassTypeData>,
  ): KioskHallPassSummaryData {
    if (!pass) return null;
    const { hallPassType, ...rest } = pass;

    let fields: any = {};

    if ((pass as any)?.hallPass) {
      const hallPass = (pass as hall_pass_pb.HallPassWithType.AsObject)
        .hallPass;
      fields = {
        studentName: hallPass.recipientPersonView?.displayName || '',
        start: dateTimeObjectToDateObject(hallPass?.startDateTime),
        expire: dateTimeObjectToDateObject(hallPass?.expiredDateTime),
        end: dateTimeObjectToDateObject(hallPass?.endedTime),
      };
    } else {
      const hallPass = rest as Omit<IHallPass, 'hallPassType'>;
      fields = {
        studentName: hallPass.recipientPersonViewMinimal?.displayName || '',
        start: hallPass.startDate,
        expire: hallPass.expiredDate,
        end: hallPass.endDate,
      };
    }

    const typeEndingMethod =
      type?.data.manuallyEndPass || !!hallPassType?.manuallyEndPass;

    return {
      name: type?.name || hallPassType?.name,
      iconName: type?.icon || hallPassType?.bannerHash,
      iconColor: type?.color || hallPassType?.color,
      endingMethod: this.getPassEndingMethod(typeEndingMethod),
      ...fields,
    };
  }

  public getPassEndingMethod(
    typeEndingMethod: boolean,
  ): 'Manual' | 'Automatic' {
    const globalEndSetting = this._mingaSettings.getSettingValue(
      mingaSettingTypes.PASS_MUST_MANUALLY_END,
    );

    const endingMethod = typeEndingMethod ?? globalEndSetting;
    return endingMethod ? 'Manual' : 'Automatic';
  }
}
