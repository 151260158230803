import { Injectable, OnDestroy } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';

import { Observable, ReplaySubject, of } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

import { MingaSettingsService } from '@app/src/app/store/Minga/services';

import { FlexTimePermissionsService } from '@shared/services/flex-time/flex-time-permissions';

import { FlexTimeManagerRoutes } from '../constants';

@Injectable()
export class FlexReporting implements CanActivate {
  constructor(
    private _router: Router,
    private _settingsService: MingaSettingsService,
    private _flexPermissions: FlexTimePermissionsService,
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> {
    const canActivate$ = this._settingsService.isFlexTimeModuleEnabled().pipe(
      map(flexEnabled => {
        return flexEnabled && this._flexPermissions.isFlexReporter();
      }),
    );

    return canActivate$.pipe(
      map(canActivate => {
        const isDashboardRoute =
          route.url[0]?.path === FlexTimeManagerRoutes.DASHBOARD;
        if (!canActivate && isDashboardRoute) {
          // cannot use relativeTo to navigate as it apparently does not work with guards
          return this._router.parseUrl(
            `${FlexTimeManagerRoutes.ROOT}/${FlexTimeManagerRoutes.ACTIVITIES}`,
          );
        }

        return canActivate;
      }),
    );
  }
}
