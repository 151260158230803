// source: restriction/restriction.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

import * as jspb from 'google-protobuf';
import * as membership_list_membership_list_pb from '../membership_list/membership_list_pb';
export namespace Restriction {
  export interface AsObject {
    id: number;
    rolesList: string[];
    gradesList: string[];
    mingaGroupHashesList: string[];
    stickerIdsList: number[];
    restrictedCheckinReasonId: number;
    membershipList?: membership_list_membership_list_pb.MembershipList.AsObject;
    membershipListId: number;
    userListsList: number[];
  }
}
export class Restriction extends jspb.Message {
  static readonly displayName = "Restriction";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [2,3,4,5,9];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, Restriction.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): Restriction.AsObject {
    return Restriction.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!Restriction} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: Restriction) {
    var f: any;
    var obj: any = {
      id: jspb.Message.getFieldWithDefault(msg, 1, 0),
      rolesList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
      gradesList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
      mingaGroupHashesList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
      stickerIdsList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f,
      restrictedCheckinReasonId: jspb.Message.getFieldWithDefault(msg, 6, 0),
      membershipList: (f = msg.getMembershipList()) && membership_list_membership_list_pb.MembershipList.toObject(includeInstance, f),
      membershipListId: jspb.Message.getFieldWithDefault(msg, 8, 0),
      userListsList: (f = jspb.Message.getRepeatedField(msg, 9)) == null ? undefined : f
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!Restriction}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new Restriction;
    return Restriction.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!Restriction} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!Restriction}
   */
  static deserializeBinaryFromReader(msg: Restriction, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {number} */ (reader.readInt32());
        msg.setId(value1);
        break;
      case 2:
        var value2 = /** @type {string} */ (reader.readString());
        msg.addRoles(value2);
        break;
      case 3:
        var value3 = /** @type {string} */ (reader.readString());
        msg.addGrades(value3);
        break;
      case 4:
        var value4 = /** @type {string} */ (reader.readString());
        msg.addMingaGroupHashes(value4);
        break;
      case 5:
        var value5 = /** @type {!Array<number>} */ (reader.readPackedInt32());
        msg.setStickerIdsList(value5);
        break;
      case 6:
        var value6 = /** @type {number} */ (reader.readInt32());
        msg.setRestrictedCheckinReasonId(value6);
        break;
      case 7:
        var value7 = new membership_list_membership_list_pb.MembershipList;
        reader.readMessage(value7,membership_list_membership_list_pb.MembershipList.deserializeBinaryFromReader);
        msg.setMembershipList(value7);
        break;
      case 8:
        var value8 = /** @type {number} */ (reader.readInt32());
        msg.setMembershipListId(value8);
        break;
      case 9:
        var value9 = /** @type {!Array<number>} */ (reader.readPackedInt32());
        msg.setUserListsList(value9);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!Restriction} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: Restriction, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getId();
    if (f !== 0) {
      writer.writeInt32(
        1,
        f
      );
    }
    f = message.getRolesList();
    if (f.length > 0) {
      writer.writeRepeatedString(
        2,
        f
      );
    }
    f = message.getGradesList();
    if (f.length > 0) {
      writer.writeRepeatedString(
        3,
        f
      );
    }
    f = message.getMingaGroupHashesList();
    if (f.length > 0) {
      writer.writeRepeatedString(
        4,
        f
      );
    }
    f = message.getStickerIdsList();
    if (f.length > 0) {
      writer.writePackedInt32(
        5,
        f
      );
    }
    f = message.getRestrictedCheckinReasonId();
    if (f !== 0) {
      writer.writeInt32(
        6,
        f
      );
    }
    f = message.getMembershipList();
    if (f != null) {
      writer.writeMessage(
        7,
        f,
        membership_list_membership_list_pb.MembershipList.serializeBinaryToWriter
      );
    }
    f = message.getMembershipListId();
    if (f !== 0) {
      writer.writeInt32(
        8,
        f
      );
    }
    f = message.getUserListsList();
    if (f.length > 0) {
      writer.writePackedInt32(
        9,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    Restriction.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional int32 id = 1;
   * @return {number}
   */
  getId(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
  };


  /** @param {number} value */
  setId(value: number) {
    jspb.Message.setProto3IntField(this, 1, value);
  }


  /**
   * repeated string roles = 2;
   * @return {!Array<string>}
   */
  getRolesList(): string[] {
    return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
  };


  /** @param {!Array<string>} value */
  setRolesList(value: string[]) {
    jspb.Message.setField(this, 2, value || []);
  }


  /**
   * @param {string} value
   * @param {number=} opt_index
   */
  addRoles(value: string, opt_index?: number): void {
    jspb.Message.addToRepeatedField(this, 2, value, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearRolesList() {
    this.setRolesList([]);
  }


  /**
   * repeated string grades = 3;
   * @return {!Array<string>}
   */
  getGradesList(): string[] {
    return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
  };


  /** @param {!Array<string>} value */
  setGradesList(value: string[]) {
    jspb.Message.setField(this, 3, value || []);
  }


  /**
   * @param {string} value
   * @param {number=} opt_index
   */
  addGrades(value: string, opt_index?: number): void {
    jspb.Message.addToRepeatedField(this, 3, value, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearGradesList() {
    this.setGradesList([]);
  }


  /**
   * repeated string minga_group_hashes = 4;
   * @return {!Array<string>}
   */
  getMingaGroupHashesList(): string[] {
    return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 4));
  };


  /** @param {!Array<string>} value */
  setMingaGroupHashesList(value: string[]) {
    jspb.Message.setField(this, 4, value || []);
  }


  /**
   * @param {string} value
   * @param {number=} opt_index
   */
  addMingaGroupHashes(value: string, opt_index?: number): void {
    jspb.Message.addToRepeatedField(this, 4, value, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearMingaGroupHashesList() {
    this.setMingaGroupHashesList([]);
  }


  /**
   * repeated int32 sticker_ids = 5;
   * @return {!Array<number>}
   */
  getStickerIdsList(): number[] {
    return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 5));
  };


  /** @param {!Array<number>} value */
  setStickerIdsList(value: number[]) {
    jspb.Message.setField(this, 5, value || []);
  }


  /**
   * @param {number} value
   * @param {number=} opt_index
   */
  addStickerIds(value: number, opt_index?: number): void {
    jspb.Message.addToRepeatedField(this, 5, value, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearStickerIdsList() {
    this.setStickerIdsList([]);
  }


  /**
   * optional int32 restricted_checkin_reason_id = 6;
   * @return {number}
   */
  getRestrictedCheckinReasonId(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
  };


  /** @param {number} value */
  setRestrictedCheckinReasonId(value: number) {
    jspb.Message.setProto3IntField(this, 6, value);
  }


  /**
   * optional membership_list.MembershipList membership_list = 7;
   * @return {?MembershipList}
   */
  getMembershipList(): membership_list_membership_list_pb.MembershipList {
    return /** @type{?MembershipList} */ (
      jspb.Message.getWrapperField(this, membership_list_membership_list_pb.MembershipList, 7));
  }


  /** @param {?MembershipList|undefined} value */
  setMembershipList(value?: membership_list_membership_list_pb.MembershipList) {
    jspb.Message.setWrapperField(this, 7, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearMembershipList() {
    this.setMembershipList(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasMembershipList(): boolean {
    return jspb.Message.getField(this, 7) != null;
  }


  /**
   * optional int32 membership_list_id = 8;
   * @return {number}
   */
  getMembershipListId(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
  };


  /** @param {number} value */
  setMembershipListId(value: number) {
    jspb.Message.setProto3IntField(this, 8, value);
  }


  /**
   * repeated int32 user_lists = 9;
   * @return {!Array<number>}
   */
  getUserListsList(): number[] {
    return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 9));
  };


  /** @param {!Array<number>} value */
  setUserListsList(value: number[]) {
    jspb.Message.setField(this, 9, value || []);
  }


  /**
   * @param {number} value
   * @param {number=} opt_index
   */
  addUserLists(value: number, opt_index?: number): void {
    jspb.Message.addToRepeatedField(this, 9, value, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearUserListsList() {
    this.setUserListsList([]);
  }


}
export namespace RestrictionError {
  export interface AsObject {
    code: string;
    personHash: string;
    personName: string;
    message: string;
  }
}
export class RestrictionError extends jspb.Message {
  static readonly displayName = "RestrictionError";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): RestrictionError.AsObject {
    return RestrictionError.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!RestrictionError} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: RestrictionError) {
    var f: any;
    var obj: any = {
      code: jspb.Message.getFieldWithDefault(msg, 1, ""),
      personHash: jspb.Message.getFieldWithDefault(msg, 2, ""),
      personName: jspb.Message.getFieldWithDefault(msg, 3, ""),
      message: jspb.Message.getFieldWithDefault(msg, 4, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!RestrictionError}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new RestrictionError;
    return RestrictionError.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!RestrictionError} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!RestrictionError}
   */
  static deserializeBinaryFromReader(msg: RestrictionError, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setCode(value1);
        break;
      case 2:
        var value2 = /** @type {string} */ (reader.readString());
        msg.setPersonHash(value2);
        break;
      case 3:
        var value3 = /** @type {string} */ (reader.readString());
        msg.setPersonName(value3);
        break;
      case 4:
        var value4 = /** @type {string} */ (reader.readString());
        msg.setMessage(value4);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!RestrictionError} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: RestrictionError, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getCode();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
    f = message.getPersonHash();
    if (f.length > 0) {
      writer.writeString(
        2,
        f
      );
    }
    f = message.getPersonName();
    if (f.length > 0) {
      writer.writeString(
        3,
        f
      );
    }
    f = message.getMessage();
    if (f.length > 0) {
      writer.writeString(
        4,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    RestrictionError.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string code = 1;
   * @return {string}
   */
  getCode(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setCode(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


  /**
   * optional string person_hash = 2;
   * @return {string}
   */
  getPersonHash(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
  };


  /** @param {string} value */
  setPersonHash(value: string) {
    jspb.Message.setProto3StringField(this, 2, value);
  }


  /**
   * optional string person_name = 3;
   * @return {string}
   */
  getPersonName(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
  };


  /** @param {string} value */
  setPersonName(value: string) {
    jspb.Message.setProto3StringField(this, 3, value);
  }


  /**
   * optional string message = 4;
   * @return {string}
   */
  getMessage(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
  };


  /** @param {string} value */
  setMessage(value: string) {
    jspb.Message.setProto3StringField(this, 4, value);
  }


}
