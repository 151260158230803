// source: content/poll/style.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

import * as jspb from 'google-protobuf';
import * as common_stream_pb from '../../common/stream_pb';
import * as common_delta_pb from '../../common/delta_pb';
export namespace PollStyle {
  export interface AsObject {
    pollStyleAsset: string;
    pollStyleId: number;
    archived: boolean;
    adminOnly: boolean;
  }
}
export class PollStyle extends jspb.Message {
  static readonly displayName = "PollStyle";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): PollStyle.AsObject {
    return PollStyle.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!PollStyle} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: PollStyle) {
    var f: any;
    var obj: any = {
      pollStyleAsset: jspb.Message.getFieldWithDefault(msg, 1, ""),
      pollStyleId: jspb.Message.getFieldWithDefault(msg, 2, 0),
      archived: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
      adminOnly: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!PollStyle}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new PollStyle;
    return PollStyle.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!PollStyle} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!PollStyle}
   */
  static deserializeBinaryFromReader(msg: PollStyle, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setPollStyleAsset(value1);
        break;
      case 2:
        var value2 = /** @type {number} */ (reader.readUint32());
        msg.setPollStyleId(value2);
        break;
      case 3:
        var value3 = /** @type {boolean} */ (reader.readBool());
        msg.setArchived(value3);
        break;
      case 4:
        var value4 = /** @type {boolean} */ (reader.readBool());
        msg.setAdminOnly(value4);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!PollStyle} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: PollStyle, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getPollStyleAsset();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
    f = message.getPollStyleId();
    if (f !== 0) {
      writer.writeUint32(
        2,
        f
      );
    }
    f = message.getArchived();
    if (f) {
      writer.writeBool(
        3,
        f
      );
    }
    f = message.getAdminOnly();
    if (f) {
      writer.writeBool(
        4,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    PollStyle.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string poll_style_asset = 1;
   * @return {string}
   */
  getPollStyleAsset(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setPollStyleAsset(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


  /**
   * optional uint32 poll_style_id = 2;
   * @return {number}
   */
  getPollStyleId(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
  };


  /** @param {number} value */
  setPollStyleId(value: number) {
    jspb.Message.setProto3IntField(this, 2, value);
  }


  /**
   * optional bool archived = 3;
   * @return {boolean}
   */
  getArchived(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
  };


  /** @param {boolean} value */
  setArchived(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 3, value);
  }


  /**
   * optional bool admin_only = 4;
   * @return {boolean}
   */
  getAdminOnly(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
  };


  /** @param {boolean} value */
  setAdminOnly(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 4, value);
  }


}
export namespace PollStyleList {
  export interface AsObject {
    pollStyleList: PollStyle.AsObject[];
  }
}
export class PollStyleList extends jspb.Message {
  static readonly displayName = "PollStyleList";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [1];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, PollStyleList.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): PollStyleList.AsObject {
    return PollStyleList.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!PollStyleList} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: PollStyleList) {
    var f: any;
    var obj: any = {
      pollStyleList: jspb.Message.toObjectList(msg.getPollStyleList(),
      PollStyle.toObject, includeInstance)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!PollStyleList}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new PollStyleList;
    return PollStyleList.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!PollStyleList} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!PollStyleList}
   */
  static deserializeBinaryFromReader(msg: PollStyleList, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new PollStyle;
        reader.readMessage(value1,PollStyle.deserializeBinaryFromReader);
        msg.addPollStyle(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!PollStyleList} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: PollStyleList, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getPollStyleList();
    if (f.length > 0) {
      writer.writeRepeatedMessage(
        1,
        f,
        PollStyle.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    PollStyleList.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * repeated PollStyle poll_style = 1;
   * @return {!Array<!PollStyle>}
   */
  getPollStyleList(): PollStyle[] {
    return /** @type{!Array<!PollStyle>} */ (
      jspb.Message.getRepeatedWrapperField(this, PollStyle, 1));
  }


  /** @param {!Array<!PollStyle>} value */
  setPollStyleList(value?: PollStyle[]) {
    jspb.Message.setRepeatedWrapperField(this, 1, value);
  }


  /**
   * @param {!PollStyle=} opt_value
   * @param {number=} opt_index
   * @return {!PollStyle}
   */
  addPollStyle(opt_value?: PollStyle, opt_index?: number): PollStyle {
    return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, PollStyle, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearPollStyleList() {
    this.setPollStyleList([]);
  }


}
export namespace PollStyleLibraryGet {
  export interface AsObject {
    pollStyleId: number;
  }
}
export class PollStyleLibraryGet extends jspb.Message {
  static readonly displayName = "PollStyleLibraryGet";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): PollStyleLibraryGet.AsObject {
    return PollStyleLibraryGet.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!PollStyleLibraryGet} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: PollStyleLibraryGet) {
    var f: any;
    var obj: any = {
      pollStyleId: jspb.Message.getFieldWithDefault(msg, 1, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!PollStyleLibraryGet}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new PollStyleLibraryGet;
    return PollStyleLibraryGet.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!PollStyleLibraryGet} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!PollStyleLibraryGet}
   */
  static deserializeBinaryFromReader(msg: PollStyleLibraryGet, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {number} */ (reader.readUint32());
        msg.setPollStyleId(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!PollStyleLibraryGet} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: PollStyleLibraryGet, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getPollStyleId();
    if (f !== 0) {
      writer.writeUint32(
        1,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    PollStyleLibraryGet.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional uint32 poll_style_id = 1;
   * @return {number}
   */
  getPollStyleId(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
  };


  /** @param {number} value */
  setPollStyleId(value: number) {
    jspb.Message.setProto3IntField(this, 1, value);
  }


}
export namespace PollStyleLibraryAdd {
  export interface AsObject {
    pollStyleAsset: string;
    archived: boolean;
    adminOnly: boolean;
  }
}
export class PollStyleLibraryAdd extends jspb.Message {
  static readonly displayName = "PollStyleLibraryAdd";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): PollStyleLibraryAdd.AsObject {
    return PollStyleLibraryAdd.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!PollStyleLibraryAdd} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: PollStyleLibraryAdd) {
    var f: any;
    var obj: any = {
      pollStyleAsset: jspb.Message.getFieldWithDefault(msg, 1, ""),
      archived: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
      adminOnly: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!PollStyleLibraryAdd}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new PollStyleLibraryAdd;
    return PollStyleLibraryAdd.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!PollStyleLibraryAdd} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!PollStyleLibraryAdd}
   */
  static deserializeBinaryFromReader(msg: PollStyleLibraryAdd, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setPollStyleAsset(value1);
        break;
      case 2:
        var value2 = /** @type {boolean} */ (reader.readBool());
        msg.setArchived(value2);
        break;
      case 3:
        var value3 = /** @type {boolean} */ (reader.readBool());
        msg.setAdminOnly(value3);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!PollStyleLibraryAdd} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: PollStyleLibraryAdd, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getPollStyleAsset();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
    f = message.getArchived();
    if (f) {
      writer.writeBool(
        2,
        f
      );
    }
    f = message.getAdminOnly();
    if (f) {
      writer.writeBool(
        3,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    PollStyleLibraryAdd.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string poll_style_asset = 1;
   * @return {string}
   */
  getPollStyleAsset(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setPollStyleAsset(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


  /**
   * optional bool archived = 2;
   * @return {boolean}
   */
  getArchived(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
  };


  /** @param {boolean} value */
  setArchived(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 2, value);
  }


  /**
   * optional bool admin_only = 3;
   * @return {boolean}
   */
  getAdminOnly(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
  };


  /** @param {boolean} value */
  setAdminOnly(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 3, value);
  }


}
export namespace PollStyleLibraryUpdate {
  export interface AsObject {
    pollStyleId: number;
    pollStyleAsset?: common_delta_pb.StringDelta.AsObject;
    archived: boolean;
    adminOnly: boolean;
  }
}
export class PollStyleLibraryUpdate extends jspb.Message {
  static readonly displayName = "PollStyleLibraryUpdate";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): PollStyleLibraryUpdate.AsObject {
    return PollStyleLibraryUpdate.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!PollStyleLibraryUpdate} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: PollStyleLibraryUpdate) {
    var f: any;
    var obj: any = {
      pollStyleId: jspb.Message.getFieldWithDefault(msg, 1, 0),
      pollStyleAsset: (f = msg.getPollStyleAsset()) && common_delta_pb.StringDelta.toObject(includeInstance, f),
      archived: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
      adminOnly: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!PollStyleLibraryUpdate}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new PollStyleLibraryUpdate;
    return PollStyleLibraryUpdate.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!PollStyleLibraryUpdate} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!PollStyleLibraryUpdate}
   */
  static deserializeBinaryFromReader(msg: PollStyleLibraryUpdate, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {number} */ (reader.readUint32());
        msg.setPollStyleId(value1);
        break;
      case 2:
        var value2 = new common_delta_pb.StringDelta;
        reader.readMessage(value2,common_delta_pb.StringDelta.deserializeBinaryFromReader);
        msg.setPollStyleAsset(value2);
        break;
      case 3:
        var value3 = /** @type {boolean} */ (reader.readBool());
        msg.setArchived(value3);
        break;
      case 4:
        var value4 = /** @type {boolean} */ (reader.readBool());
        msg.setAdminOnly(value4);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!PollStyleLibraryUpdate} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: PollStyleLibraryUpdate, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getPollStyleId();
    if (f !== 0) {
      writer.writeUint32(
        1,
        f
      );
    }
    f = message.getPollStyleAsset();
    if (f != null) {
      writer.writeMessage(
        2,
        f,
        common_delta_pb.StringDelta.serializeBinaryToWriter
      );
    }
    f = message.getArchived();
    if (f) {
      writer.writeBool(
        3,
        f
      );
    }
    f = message.getAdminOnly();
    if (f) {
      writer.writeBool(
        4,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    PollStyleLibraryUpdate.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional uint32 poll_style_id = 1;
   * @return {number}
   */
  getPollStyleId(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
  };


  /** @param {number} value */
  setPollStyleId(value: number) {
    jspb.Message.setProto3IntField(this, 1, value);
  }


  /**
   * optional minga.common.StringDelta poll_style_asset = 2;
   * @return {?StringDelta}
   */
  getPollStyleAsset(): common_delta_pb.StringDelta {
    return /** @type{?StringDelta} */ (
      jspb.Message.getWrapperField(this, common_delta_pb.StringDelta, 2));
  }


  /** @param {?StringDelta|undefined} value */
  setPollStyleAsset(value?: common_delta_pb.StringDelta) {
    jspb.Message.setWrapperField(this, 2, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearPollStyleAsset() {
    this.setPollStyleAsset(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasPollStyleAsset(): boolean {
    return jspb.Message.getField(this, 2) != null;
  }


  /**
   * optional bool archived = 3;
   * @return {boolean}
   */
  getArchived(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
  };


  /** @param {boolean} value */
  setArchived(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 3, value);
  }


  /**
   * optional bool admin_only = 4;
   * @return {boolean}
   */
  getAdminOnly(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
  };


  /** @param {boolean} value */
  setAdminOnly(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 4, value);
  }


}
export namespace PollStyleLibraryDelete {
  export interface AsObject {
    pollStyleId: number;
  }
}
export class PollStyleLibraryDelete extends jspb.Message {
  static readonly displayName = "PollStyleLibraryDelete";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): PollStyleLibraryDelete.AsObject {
    return PollStyleLibraryDelete.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!PollStyleLibraryDelete} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: PollStyleLibraryDelete) {
    var f: any;
    var obj: any = {
      pollStyleId: jspb.Message.getFieldWithDefault(msg, 1, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!PollStyleLibraryDelete}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new PollStyleLibraryDelete;
    return PollStyleLibraryDelete.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!PollStyleLibraryDelete} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!PollStyleLibraryDelete}
   */
  static deserializeBinaryFromReader(msg: PollStyleLibraryDelete, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {number} */ (reader.readUint32());
        msg.setPollStyleId(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!PollStyleLibraryDelete} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: PollStyleLibraryDelete, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getPollStyleId();
    if (f !== 0) {
      writer.writeUint32(
        1,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    PollStyleLibraryDelete.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional uint32 poll_style_id = 1;
   * @return {number}
   */
  getPollStyleId(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
  };


  /** @param {number} value */
  setPollStyleId(value: number) {
    jspb.Message.setProto3IntField(this, 1, value);
  }


}
export namespace PollStyleLibrarySetArchived {
  export interface AsObject {
    pollStyleId: number;
    archived: boolean;
  }
}
export class PollStyleLibrarySetArchived extends jspb.Message {
  static readonly displayName = "PollStyleLibrarySetArchived";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): PollStyleLibrarySetArchived.AsObject {
    return PollStyleLibrarySetArchived.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!PollStyleLibrarySetArchived} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: PollStyleLibrarySetArchived) {
    var f: any;
    var obj: any = {
      pollStyleId: jspb.Message.getFieldWithDefault(msg, 1, 0),
      archived: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!PollStyleLibrarySetArchived}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new PollStyleLibrarySetArchived;
    return PollStyleLibrarySetArchived.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!PollStyleLibrarySetArchived} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!PollStyleLibrarySetArchived}
   */
  static deserializeBinaryFromReader(msg: PollStyleLibrarySetArchived, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {number} */ (reader.readUint32());
        msg.setPollStyleId(value1);
        break;
      case 2:
        var value2 = /** @type {boolean} */ (reader.readBool());
        msg.setArchived(value2);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!PollStyleLibrarySetArchived} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: PollStyleLibrarySetArchived, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getPollStyleId();
    if (f !== 0) {
      writer.writeUint32(
        1,
        f
      );
    }
    f = message.getArchived();
    if (f) {
      writer.writeBool(
        2,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    PollStyleLibrarySetArchived.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional uint32 poll_style_id = 1;
   * @return {number}
   */
  getPollStyleId(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
  };


  /** @param {number} value */
  setPollStyleId(value: number) {
    jspb.Message.setProto3IntField(this, 1, value);
  }


  /**
   * optional bool archived = 2;
   * @return {boolean}
   */
  getArchived(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
  };


  /** @param {boolean} value */
  setArchived(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 2, value);
  }


}
export namespace PollStyleLibraryList {
  export interface AsObject {
    count: number;
    offset: number;
  }
}
export class PollStyleLibraryList extends jspb.Message {
  static readonly displayName = "PollStyleLibraryList";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): PollStyleLibraryList.AsObject {
    return PollStyleLibraryList.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!PollStyleLibraryList} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: PollStyleLibraryList) {
    var f: any;
    var obj: any = {
      count: jspb.Message.getFieldWithDefault(msg, 1, 0),
      offset: jspb.Message.getFieldWithDefault(msg, 2, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!PollStyleLibraryList}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new PollStyleLibraryList;
    return PollStyleLibraryList.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!PollStyleLibraryList} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!PollStyleLibraryList}
   */
  static deserializeBinaryFromReader(msg: PollStyleLibraryList, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {number} */ (reader.readInt32());
        msg.setCount(value1);
        break;
      case 2:
        var value2 = /** @type {number} */ (reader.readInt32());
        msg.setOffset(value2);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!PollStyleLibraryList} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: PollStyleLibraryList, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getCount();
    if (f !== 0) {
      writer.writeInt32(
        1,
        f
      );
    }
    f = message.getOffset();
    if (f !== 0) {
      writer.writeInt32(
        2,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    PollStyleLibraryList.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional int32 count = 1;
   * @return {number}
   */
  getCount(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
  };


  /** @param {number} value */
  setCount(value: number) {
    jspb.Message.setProto3IntField(this, 1, value);
  }


  /**
   * optional int32 offset = 2;
   * @return {number}
   */
  getOffset(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
  };


  /** @param {number} value */
  setOffset(value: number) {
    jspb.Message.setProto3IntField(this, 2, value);
  }


}
export namespace PollStyleLibrarySearchResponse {
  export interface AsObject {
    itemMetadata?: common_stream_pb.StreamItemMetadata.AsObject;
    item?: PollStyle.AsObject;
  }
}
export class PollStyleLibrarySearchResponse extends jspb.Message {
  static readonly displayName = "PollStyleLibrarySearchResponse";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): PollStyleLibrarySearchResponse.AsObject {
    return PollStyleLibrarySearchResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!PollStyleLibrarySearchResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: PollStyleLibrarySearchResponse) {
    var f: any;
    var obj: any = {
      itemMetadata: (f = msg.getItemMetadata()) && common_stream_pb.StreamItemMetadata.toObject(includeInstance, f),
      item: (f = msg.getItem()) && PollStyle.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!PollStyleLibrarySearchResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new PollStyleLibrarySearchResponse;
    return PollStyleLibrarySearchResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!PollStyleLibrarySearchResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!PollStyleLibrarySearchResponse}
   */
  static deserializeBinaryFromReader(msg: PollStyleLibrarySearchResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new common_stream_pb.StreamItemMetadata;
        reader.readMessage(value1,common_stream_pb.StreamItemMetadata.deserializeBinaryFromReader);
        msg.setItemMetadata(value1);
        break;
      case 2:
        var value2 = new PollStyle;
        reader.readMessage(value2,PollStyle.deserializeBinaryFromReader);
        msg.setItem(value2);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!PollStyleLibrarySearchResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: PollStyleLibrarySearchResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getItemMetadata();
    if (f != null) {
      writer.writeMessage(
        1,
        f,
        common_stream_pb.StreamItemMetadata.serializeBinaryToWriter
      );
    }
    f = message.getItem();
    if (f != null) {
      writer.writeMessage(
        2,
        f,
        PollStyle.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    PollStyleLibrarySearchResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional StreamItemMetadata item_metadata = 1;
   * @return {?StreamItemMetadata}
   */
  getItemMetadata(): common_stream_pb.StreamItemMetadata {
    return /** @type{?StreamItemMetadata} */ (
      jspb.Message.getWrapperField(this, common_stream_pb.StreamItemMetadata, 1));
  }


  /** @param {?StreamItemMetadata|undefined} value */
  setItemMetadata(value?: common_stream_pb.StreamItemMetadata) {
    jspb.Message.setWrapperField(this, 1, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearItemMetadata() {
    this.setItemMetadata(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasItemMetadata(): boolean {
    return jspb.Message.getField(this, 1) != null;
  }


  /**
   * optional PollStyle item = 2;
   * @return {?PollStyle}
   */
  getItem(): PollStyle {
    return /** @type{?PollStyle} */ (
      jspb.Message.getWrapperField(this, PollStyle, 2));
  }


  /** @param {?PollStyle|undefined} value */
  setItem(value?: PollStyle) {
    jspb.Message.setWrapperField(this, 2, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearItem() {
    this.setItem(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasItem(): boolean {
    return jspb.Message.getField(this, 2) != null;
  }


}
