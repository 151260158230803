import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatRippleModule } from '@angular/material/core';

import { MgEmptyStateModule } from '@app/src/app/components/EmptyState';
import { MgImageModule } from '@app/src/app/components/MgImage';
import { MgIconModule } from '@app/src/app/icon';
import { PermissionsModule } from '@app/src/app/permissions';
import { MgPipesModule } from '@app/src/app/pipes';
import { MgSpinnerModule } from '@app/src/app/spinner';

import { FormModule } from '@shared/components/form';
import { GenericModule } from '@shared/components/generic';
import { OverlayModule } from '@shared/components/modal-overlay';
import { TextModule } from '@shared/components/text/text.module';

import { FtmActivitesStudentSchedulerComponent } from './components/ftm-activites-student-scheduler/ftm-activites-student-scheduler.component';
import { FtmActivityCardComponent } from './components/ftm-activity-card/ftm-activity-card.component';
import { FtmActivityImgComponent } from './components/ftm-activity-img/ftm-activity-img.component';
import { FtmActivitySelectorListComponent } from './components/ftm-activity-selector-list/ftm-activity-selector-list.component';
import { FtmActivitySelectorModalComponent } from './components/ftm-activity-selector-modal/ftm-activity-selector-modal';
import { ActivityCheckinPipe, CanEditActivityInstancePipe } from './pipes';
import { ActivityRegisteredPipe } from './pipes/ActivityRegistered.pipe';
import { CanManageActivityRegistrationPipe } from './pipes/CanManageActivityRegistration.pipe';

@NgModule({
  declarations: [
    FtmActivityImgComponent,
    FtmActivityCardComponent,
    FtmActivitesStudentSchedulerComponent,
    FtmActivitySelectorModalComponent,
    ActivityRegisteredPipe,
    ActivityCheckinPipe,
    FtmActivitySelectorListComponent,
    CanEditActivityInstancePipe,
    CanManageActivityRegistrationPipe,
  ],
  imports: [
    CommonModule,
    MgImageModule,
    MgIconModule,
    MgPipesModule,
    MgSpinnerModule,
    TextModule,
    FlexLayoutModule,
    OverlayModule,
    MatRippleModule,
    MgEmptyStateModule,
    FormModule,
    GenericModule,
    PermissionsModule,
  ],
  exports: [
    FtmActivityImgComponent,
    FtmActivityCardComponent,
    FtmActivitesStudentSchedulerComponent,
    FtmActivitySelectorModalComponent,
    ActivityRegisteredPipe,
    ActivityCheckinPipe,
    FtmActivitySelectorListComponent,
    CanEditActivityInstancePipe,
    CanManageActivityRegistrationPipe,
  ],
})
export class FtmSharedModule {}
