import { image_pb, mingaGroup_pb, gateway } from 'libs/generated-grpc-web';

export interface Group {
  hash: string;
  name: string;
  banner?: image_pb.ImageInfo;
  bannerHash?: string;
  isPrivate: boolean;
  isParent: boolean;
  parentGroupHash: string;
  description: string;
  category: string;
  categoryId: number;
  rank?: MingaGroupMemberRank | null;
  bannerObject?: gateway.banner_pb.Banner;
  memberCount?: number;
  allowedRoleTypes?: string[];
  allowedCommentingRoleTypes?: string[];
  currentMemberRank?: MingaGroupMemberRank;
}

export enum MingaGroupMemberRank {
  PENDING = 'PENDING',
  OWNER = 'OWNER',
  MEMBER = 'MEMBER',
  LOADING = 'LOADING',
}

export namespace Group {
  export function fromMessage(msg: gateway.mingaGroup_pb.Group): Group {
    let group: Group = {
      hash: msg.getHash(),
      name: msg.getName(),
      isPrivate: msg.getPrivate(),
      isParent: msg.getIsParent(),
      parentGroupHash: msg.getParentGroupHash(),
      description: msg.getDescription(),
      category: msg.getCategoryName(),
      categoryId: msg.getCategoryId(),
      memberCount: msg.getMemberCount(),
      currentMemberRank: msg.getCurrentMemberRank() as MingaGroupMemberRank,
    };

    const allowedRoleTypes = msg.getAllowedRoleTypesList();

    if (allowedRoleTypes) {
      group.allowedRoleTypes = allowedRoleTypes;
    }

    const allowedCommentRoleTypes = msg.getAllowedCommentingRoleTypesList();

    if (allowedCommentRoleTypes) {
      group.allowedCommentingRoleTypes = allowedCommentRoleTypes;
    }

    if (msg.hasBannerImageInfo()) {
      group.banner = msg.getBannerImageInfo();
    }

    if (msg.hasBannerHash()) {
      group.bannerHash = msg.getBannerHash();
    }

    if (msg.hasBannerObject()) {
      group.bannerObject = msg.getBannerObject();
    }

    return group;
  }

  export function isMember(group: Group | null): boolean {
    if (!group) {
      return false;
    }
    if (
      group.rank === MingaGroupMemberRank.MEMBER ||
      group.rank === MingaGroupMemberRank.OWNER
    ) {
      return true;
    }
    return false;
  }

  export function isOwner(group: Group | null): boolean {
    if (!group) {
      return false;
    }
    if (group.rank === MingaGroupMemberRank.OWNER) {
      return true;
    }
    return false;
  }

  export function isPending(group: Group | null): boolean {
    if (!group) {
      return false;
    }
    if (group.rank === MingaGroupMemberRank.PENDING) {
      return true;
    }
    return false;
  }
}

export function resolveMyGroupStatusRank(
  rank: number,
): MingaGroupMemberRank | null {
  if (rank === gateway.mingaGroup_pb.GroupMemberInfo.Rank.MEMBER) {
    return MingaGroupMemberRank.MEMBER;
  } else if (rank === gateway.mingaGroup_pb.GroupMemberInfo.Rank.OWNER) {
    return MingaGroupMemberRank.OWNER;
  } else if (rank === gateway.mingaGroup_pb.GroupMemberInfo.Rank.PENDING) {
    return MingaGroupMemberRank.PENDING;
  } else {
    console.warn(`'resolveMyGroupStatusRank' unhandled rank ${rank}`);
    return null;
  }
}

export function resolveMyGroupRankMemberInfo(
  rank: MingaGroupMemberRank,
): gateway.mingaGroup_pb.GroupMemberInfo.Rank | null {
  if (rank === MingaGroupMemberRank.MEMBER) {
    return gateway.mingaGroup_pb.GroupMemberInfo.Rank.MEMBER;
  } else if (rank === MingaGroupMemberRank.OWNER) {
    return gateway.mingaGroup_pb.GroupMemberInfo.Rank.OWNER;
  } else if (rank === MingaGroupMemberRank.PENDING) {
    return gateway.mingaGroup_pb.GroupMemberInfo.Rank.PENDING;
  }
  return null;
}
