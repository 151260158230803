// source: content/eventCollection/event_collection.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

import * as jspb from 'google-protobuf';
export namespace EventViewMinimal {
  export interface AsObject {
    contentHash: string;
    name: string;
    contextHash: string;
  }
}
export class EventViewMinimal extends jspb.Message {
  static readonly displayName = "EventViewMinimal";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): EventViewMinimal.AsObject {
    return EventViewMinimal.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!EventViewMinimal} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: EventViewMinimal) {
    var f: any;
    var obj: any = {
      contentHash: jspb.Message.getFieldWithDefault(msg, 1, ""),
      name: jspb.Message.getFieldWithDefault(msg, 2, ""),
      contextHash: jspb.Message.getFieldWithDefault(msg, 3, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!EventViewMinimal}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new EventViewMinimal;
    return EventViewMinimal.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!EventViewMinimal} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!EventViewMinimal}
   */
  static deserializeBinaryFromReader(msg: EventViewMinimal, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setContentHash(value1);
        break;
      case 2:
        var value2 = /** @type {string} */ (reader.readString());
        msg.setName(value2);
        break;
      case 3:
        var value3 = /** @type {string} */ (reader.readString());
        msg.setContextHash(value3);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!EventViewMinimal} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: EventViewMinimal, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getContentHash();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
    f = message.getName();
    if (f.length > 0) {
      writer.writeString(
        2,
        f
      );
    }
    f = message.getContextHash();
    if (f.length > 0) {
      writer.writeString(
        3,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    EventViewMinimal.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string content_hash = 1;
   * @return {string}
   */
  getContentHash(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setContentHash(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


  /**
   * optional string name = 2;
   * @return {string}
   */
  getName(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
  };


  /** @param {string} value */
  setName(value: string) {
    jspb.Message.setProto3StringField(this, 2, value);
  }


  /**
   * optional string context_hash = 3;
   * @return {string}
   */
  getContextHash(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
  };


  /** @param {string} value */
  setContextHash(value: string) {
    jspb.Message.setProto3StringField(this, 3, value);
  }


}
export namespace GetEventCollectionRequest {
  export interface AsObject {
    token: string;
  }
}
export class GetEventCollectionRequest extends jspb.Message {
  static readonly displayName = "GetEventCollectionRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): GetEventCollectionRequest.AsObject {
    return GetEventCollectionRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!GetEventCollectionRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: GetEventCollectionRequest) {
    var f: any;
    var obj: any = {
      token: jspb.Message.getFieldWithDefault(msg, 1, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!GetEventCollectionRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new GetEventCollectionRequest;
    return GetEventCollectionRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!GetEventCollectionRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!GetEventCollectionRequest}
   */
  static deserializeBinaryFromReader(msg: GetEventCollectionRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setToken(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!GetEventCollectionRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: GetEventCollectionRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getToken();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    GetEventCollectionRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string token = 1;
   * @return {string}
   */
  getToken(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setToken(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


}
export namespace GetEventCollectionResponse {
  export interface AsObject {
    token: string;
    event?: EventViewMinimal.AsObject;
    removeEvent: string;
  }
}
export class GetEventCollectionResponse extends jspb.Message {
  static readonly displayName = "GetEventCollectionResponse";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): GetEventCollectionResponse.AsObject {
    return GetEventCollectionResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!GetEventCollectionResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: GetEventCollectionResponse) {
    var f: any;
    var obj: any = {
      token: jspb.Message.getFieldWithDefault(msg, 1, ""),
      event: (f = msg.getEvent()) && EventViewMinimal.toObject(includeInstance, f),
      removeEvent: jspb.Message.getFieldWithDefault(msg, 3, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!GetEventCollectionResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new GetEventCollectionResponse;
    return GetEventCollectionResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!GetEventCollectionResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!GetEventCollectionResponse}
   */
  static deserializeBinaryFromReader(msg: GetEventCollectionResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setToken(value1);
        break;
      case 2:
        var value2 = new EventViewMinimal;
        reader.readMessage(value2,EventViewMinimal.deserializeBinaryFromReader);
        msg.setEvent(value2);
        break;
      case 3:
        var value3 = /** @type {string} */ (reader.readString());
        msg.setRemoveEvent(value3);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!GetEventCollectionResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: GetEventCollectionResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getToken();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
    f = message.getEvent();
    if (f != null) {
      writer.writeMessage(
        2,
        f,
        EventViewMinimal.serializeBinaryToWriter
      );
    }
    f = message.getRemoveEvent();
    if (f.length > 0) {
      writer.writeString(
        3,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    GetEventCollectionResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string token = 1;
   * @return {string}
   */
  getToken(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setToken(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


  /**
   * optional EventViewMinimal event = 2;
   * @return {?EventViewMinimal}
   */
  getEvent(): EventViewMinimal {
    return /** @type{?EventViewMinimal} */ (
      jspb.Message.getWrapperField(this, EventViewMinimal, 2));
  }


  /** @param {?EventViewMinimal|undefined} value */
  setEvent(value?: EventViewMinimal) {
    jspb.Message.setWrapperField(this, 2, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearEvent() {
    this.setEvent(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasEvent(): boolean {
    return jspb.Message.getField(this, 2) != null;
  }


  /**
   * optional string remove_event = 3;
   * @return {string}
   */
  getRemoveEvent(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
  };


  /** @param {string} value */
  setRemoveEvent(value: string) {
    jspb.Message.setProto3StringField(this, 3, value);
  }


}
