// GENERATED CODE -- DO NOT EDIT!

import {grpc} from '@improbable-eng/grpc-web';

import * as user_list_user_list_pb from '../user_list/user_list_pb';
import * as common_date_pb from '../common/date_pb';

export class UserLists {
  static readonly serviceName = "user_list.v1.UserLists";

  static readonly ListUserLists = {
    methodName: "ListUserLists",
    service: UserLists,
    requestStream: false,
    responseStream: false,
    requestType: user_list_user_list_pb.ListUserListsRequest,
    responseType: user_list_user_list_pb.ListUserListsResponse,
  };

  static readonly GetUserList = {
    methodName: "GetUserList",
    service: UserLists,
    requestStream: false,
    responseStream: false,
    requestType: user_list_user_list_pb.GetUserListRequest,
    responseType: user_list_user_list_pb.UserList,
  };

  static readonly CreateUserList = {
    methodName: "CreateUserList",
    service: UserLists,
    requestStream: false,
    responseStream: false,
    requestType: user_list_user_list_pb.CreateUserListRequest,
    responseType: user_list_user_list_pb.UserList,
  };

  static readonly UpdateUserList = {
    methodName: "UpdateUserList",
    service: UserLists,
    requestStream: false,
    responseStream: false,
    requestType: user_list_user_list_pb.UpdateUserListRequest,
    responseType: user_list_user_list_pb.UserList,
  };

  static readonly DeleteUserList = {
    methodName: "DeleteUserList",
    service: UserLists,
    requestStream: false,
    responseStream: false,
    requestType: user_list_user_list_pb.DeleteUserListRequest,
    responseType: user_list_user_list_pb.DeleteUserListResponse,
  };

  static readonly GetUserListMinimals = {
    methodName: "GetUserListMinimals",
    service: UserLists,
    requestStream: false,
    responseStream: false,
    requestType: user_list_user_list_pb.GetUserListMinimalsRequest,
    responseType: user_list_user_list_pb.GetUserListMinimalsResponse,
  };

  static readonly SetHomeroom = {
    methodName: "SetHomeroom",
    service: UserLists,
    requestStream: false,
    responseStream: false,
    requestType: user_list_user_list_pb.SetHomeroomRequest,
    responseType: user_list_user_list_pb.SetHomeroomResponse,
  };

}

export class UserListsClient {
  listUserLists() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  getUserList() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  createUserList() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  updateUserList() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  deleteUserList() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  getUserListMinimals() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  setHomeroom() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
}
