import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { BarcodeScanner } from '@app/src/app/barcodeScanner';

import { BmTypesAutomationResetModalData } from '@modules/behavior-manager/components/bm-types/components/bm-types-automation-reset/bm-types-automation-reset.component';

import { ModalOverlayService } from '@shared/components/modal-overlay';
import { SystemAlertModalService } from '@shared/components/system-alert-modal';
import { SystemAlertSnackBarService } from '@shared/components/system-alert-snackbar';
import { AutomationService } from '@shared/services/automation';

import { PeopleSelectorService } from '../people-selector.service';
import { PeopleSelectorFormService } from '../ps-form.service';
import { PsCollectionSearchImplService } from '../search-impl/ps-collection-search.impl.service';

@Injectable()
export class PsAutomationResetService extends PeopleSelectorFormService<'Automation Reset'> {
  /** Service Constructor */
  constructor(
    public router: Router,
    public snackbar: SystemAlertSnackBarService,
    public barCodeScanner: BarcodeScanner,
    public peopleSelector: PeopleSelectorService,
    private _psCollectionSearch: PsCollectionSearchImplService,
    private _automationService: AutomationService,
    private _systemModal: SystemAlertModalService,
    private _modalOverlay: ModalOverlayService<
      any,
      BmTypesAutomationResetModalData
    >,
  ) {
    super(
      {
        name: 'Automation Reset',
        pageDefinitions: {
          reset: {
            submitFn: async => this.submitReset(),
            searchFn: async =>
              this._psCollectionSearch.collectionSearch('text', undefined),
          },
        },
      },
      router,
      snackbar,
      barCodeScanner,
      peopleSelector,
    );

    this.setConfig({
      name: 'Automation Reset',
      pageDefinitions: {
        reset: {
          submitFn: async () => this.submitReset(),
          searchFn: async (type, filters) =>
            this._psCollectionSearch.collectionSearch(type, filters),
        },
      },
    });
  }

  public async submitReset(): Promise<void> {
    const { automationIds, count } = this.data;
    const selectedHashes = this.selection.getSelectionHashes();
    this.peopleSelector.close();
  }
}
