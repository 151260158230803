import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { mingaSettingTypes } from 'libs/util';
import { Observable, ReplaySubject } from 'rxjs';
import { distinctUntilChanged, map, takeUntil } from 'rxjs/operators';

import { ContentNavigationTypes } from '@app/src/app/content/constants';
import { Group } from '@app/src/app/groups/models';
import { AuthInfoService } from '@app/src/app/minimal/services/AuthInfo';
import { BadgeManager } from '@app/src/app/minimal/services/BadgeManager';
import { StreamManager } from '@app/src/app/minimal/services/StreamManager';
import { ChallengesRouteService } from '@app/src/app/modules/challenges/services';
import { NavigationFtueKeys } from '@app/src/app/navigation';
import { RootNavService } from '@app/src/app/services/RootNav';
import { MingaSettingsService } from '@app/src/app/store/Minga/services';

@Component({
  selector: 'mg-content-nav',
  templateUrl: './ContentNav.component.html',
  styleUrls: ['./ContentNav.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContentNavComponent implements OnDestroy, OnInit {
  readonly CONTENT_TYPES = ContentNavigationTypes;
  currentGroup: Group | null = null;
  private _direction = '';

  addContentLinkType: 'g' | 'm' = 'm';

  currentPersonHash$: Observable<string>;

  isCommunityEnabled = true;

  readonly isCommunityModuleEnabled$: Observable<boolean>;

  readonly isGroupsEnabled$: Observable<boolean>;

  readonly isPhotoGalleryEnabled$: Observable<boolean>;

  readonly isDistrictEnabled$: Observable<boolean>;

  readonly inParentGroup$: Observable<boolean>;

  readonly canPostContent$: Observable<boolean>;

  readonly isStudentIdEnabled$: Observable<boolean>;

  readonly isTrackingEnabled$: Observable<boolean>;

  readonly isHallPassEnabled$: Observable<boolean>;

  readonly isSMSEnabled$: Observable<boolean>;

  readonly isLeaderboardEnabled$: Observable<boolean>;

  readonly canStudentsSelfGrantHallPasses$: Observable<boolean>;

  readonly isOwner$: Observable<boolean>;

  readonly ftueKeys = NavigationFtueKeys;

  private _destroyed$ = new ReplaySubject<void>(1);

  constructor(
    private _rootNav: RootNavService,
    private _streamManager: StreamManager,
    readonly badgeManager: BadgeManager,
    private _challengesRouteService: ChallengesRouteService,
    private _authInfoService: AuthInfoService,
    public dialog: MatDialog,
    private _settingService: MingaSettingsService,
  ) {
    this.isCommunityModuleEnabled$ =
      this._settingService.isCommunityModuleEnabled();

    this._rootNav.currentGroup$
      .pipe(takeUntil(this._destroyed$))
      .subscribe(group => {
        this.currentGroup = group;
      });

    // we do groupHash separately since it doesn't rely on groups having loaded
    // yet, so we don't have to worry about accidentally posting to
    // main feed if groups haven't loaded yet.
    this._rootNav.currentGroupHash$
      .pipe(takeUntil(this._destroyed$))
      .subscribe(groupHash => {
        if (groupHash) {
          this.addContentLinkType = 'g';
        } else {
          this.addContentLinkType = 'm';
        }
      });

    this.isPhotoGalleryEnabled$ =
      this._settingService.isPhotoGalleryModuleEnabled();

    this.isGroupsEnabled$ = this._settingService.getSettingValueObs(
      mingaSettingTypes.FEATURE_ENABLE_GROUPS,
    );

    this.isDistrictEnabled$ = this._settingService.getSettingValueObs(
      mingaSettingTypes.FEATURE_DISTRICT_ENABLED,
    );

    this.canPostContent$ = _rootNav.canPostContent();

    this.inParentGroup$ = _rootNav.inParentGroup();

    this.isOwner$ = _rootNav.isOwner$;

    this.isStudentIdEnabled$ = this._settingService.isIdModuleEnabled();

    this.isTrackingEnabled$ = this._settingService.isPbisModuleEnabled();

    this.isHallPassEnabled$ = this._settingService.isHallPassModuleEnabled();

    this.isSMSEnabled$ = this._settingService.getSettingValueObs(
      mingaSettingTypes.ENABLE_SMS,
    );

    this.isLeaderboardEnabled$ = this._settingService.getSettingValueObs(
      mingaSettingTypes.FEATURE_TRACKING_PUBLIC_LEADERBOARD,
    );

    this.canStudentsSelfGrantHallPasses$ =
      this._settingService.getSettingValueObs(
        mingaSettingTypes.PASS_STUDENTS_GRANT,
      );

    this.currentPersonHash$ = this._authInfoService.authPerson$.pipe(
      map(person => person.hash),
    );
  }

  ngOnInit() {
    this.isCommunityModuleEnabled$
      .pipe(takeUntil(this._destroyed$), distinctUntilChanged())
      .subscribe(isEnabled => {
        this.isCommunityEnabled = isEnabled;
      });
  }

  ngOnDestroy() {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  restartStream(streamName: string | string[]) {
    this._streamManager.restartStreams(streamName);
  }

  createChallenge(type: 'm' | 'g' | 'p') {
    let groupHash = null;
    if (this.currentGroup) {
      groupHash = this.currentGroup.hash;
    }
    this._challengesRouteService.gotoCreate(type, groupHash);
  }

  gotoChallengeAdmin() {
    this._challengesRouteService.gotoAdminSummary();
  }
}
