import { Injectable, NgZone } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { grpc } from '@improbable-eng/grpc-web';

import {
  StreamControl,
  StreamControlResponse,
  StreamID,
} from '../common/stream_pb';
import {
  OverrideModerationRequest,
  OverrideModerationResponse,
  ReportContentRequest,
  ReportContentResponse,
  ReportStatsRequest,
  ReportStatsResponse,
  ReportedContextsRequest,
  ReportedContextsResponse,
  ReportedUgcRequest,
  ReportedUgcResponse,
  UnReportContentRequest,
  StreamModerationItem,
} from '../gateway/content_report_pb';

import {
  ReportContent as __ReportContent,
  ReportStreamManager as __ReportStreamManager,
} from '../gateway/content_report_pb_service';

@Injectable({providedIn: 'root'})
export class ReportContent {

  constructor(private _ngZone: NgZone) {
  }

  report(request: ReportContentRequest): Promise<ReportContentResponse>;
  report(request: ReportContentRequest, metadata: grpc.Metadata): Promise<ReportContentResponse>;
  report(request: ReportContentRequest, callback: (err: any|null, response: ReportContentResponse, metadata: grpc.Metadata) => void): void;
  report(request: ReportContentRequest, metadata: grpc.Metadata, callback: (err: any|null, response: ReportContentResponse, metadata: grpc.Metadata) => void): void;

  report(request: ReportContentRequest, arg1?: grpc.Metadata|((err: any|null, response: ReportContentResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: ReportContentResponse, metadata: grpc.Metadata) => void): Promise<ReportContentResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<ReportContentResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__ReportContent.report, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  resolve(request: UnReportContentRequest): Promise<ReportContentResponse>;
  resolve(request: UnReportContentRequest, metadata: grpc.Metadata): Promise<ReportContentResponse>;
  resolve(request: UnReportContentRequest, callback: (err: any|null, response: ReportContentResponse, metadata: grpc.Metadata) => void): void;
  resolve(request: UnReportContentRequest, metadata: grpc.Metadata, callback: (err: any|null, response: ReportContentResponse, metadata: grpc.Metadata) => void): void;

  resolve(request: UnReportContentRequest, arg1?: grpc.Metadata|((err: any|null, response: ReportContentResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: ReportContentResponse, metadata: grpc.Metadata) => void): Promise<ReportContentResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<ReportContentResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__ReportContent.resolve, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  /**
    Deprecated as of 4.2.0
  */
  retrieveReportedContexts(request: ReportedContextsRequest): Promise<ReportedContextsResponse>;
  /**
    Deprecated as of 4.2.0
  */
  retrieveReportedContexts(request: ReportedContextsRequest, metadata: grpc.Metadata): Promise<ReportedContextsResponse>;
  /**
    Deprecated as of 4.2.0
  */
  retrieveReportedContexts(request: ReportedContextsRequest, callback: (err: any|null, response: ReportedContextsResponse, metadata: grpc.Metadata) => void): void;
  /**
    Deprecated as of 4.2.0
  */
  retrieveReportedContexts(request: ReportedContextsRequest, metadata: grpc.Metadata, callback: (err: any|null, response: ReportedContextsResponse, metadata: grpc.Metadata) => void): void;

  retrieveReportedContexts(request: ReportedContextsRequest, arg1?: grpc.Metadata|((err: any|null, response: ReportedContextsResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: ReportedContextsResponse, metadata: grpc.Metadata) => void): Promise<ReportedContextsResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<ReportedContextsResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__ReportContent.retrieveReportedContexts, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  retrieveReportedUgc(request: ReportedUgcRequest): Promise<ReportedUgcResponse>;
  retrieveReportedUgc(request: ReportedUgcRequest, metadata: grpc.Metadata): Promise<ReportedUgcResponse>;
  retrieveReportedUgc(request: ReportedUgcRequest, callback: (err: any|null, response: ReportedUgcResponse, metadata: grpc.Metadata) => void): void;
  retrieveReportedUgc(request: ReportedUgcRequest, metadata: grpc.Metadata, callback: (err: any|null, response: ReportedUgcResponse, metadata: grpc.Metadata) => void): void;

  retrieveReportedUgc(request: ReportedUgcRequest, arg1?: grpc.Metadata|((err: any|null, response: ReportedUgcResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: ReportedUgcResponse, metadata: grpc.Metadata) => void): Promise<ReportedUgcResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<ReportedUgcResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__ReportContent.retrieveReportedUgc, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  override(request: OverrideModerationRequest): Promise<OverrideModerationResponse>;
  override(request: OverrideModerationRequest, metadata: grpc.Metadata): Promise<OverrideModerationResponse>;
  override(request: OverrideModerationRequest, callback: (err: any|null, response: OverrideModerationResponse, metadata: grpc.Metadata) => void): void;
  override(request: OverrideModerationRequest, metadata: grpc.Metadata, callback: (err: any|null, response: OverrideModerationResponse, metadata: grpc.Metadata) => void): void;

  override(request: OverrideModerationRequest, arg1?: grpc.Metadata|((err: any|null, response: OverrideModerationResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: OverrideModerationResponse, metadata: grpc.Metadata) => void): Promise<OverrideModerationResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<OverrideModerationResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__ReportContent.override, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  retrieveStats(request: ReportStatsRequest): Promise<ReportStatsResponse>;
  retrieveStats(request: ReportStatsRequest, metadata: grpc.Metadata): Promise<ReportStatsResponse>;
  retrieveStats(request: ReportStatsRequest, callback: (err: any|null, response: ReportStatsResponse, metadata: grpc.Metadata) => void): void;
  retrieveStats(request: ReportStatsRequest, metadata: grpc.Metadata, callback: (err: any|null, response: ReportStatsResponse, metadata: grpc.Metadata) => void): void;

  retrieveStats(request: ReportStatsRequest, arg1?: grpc.Metadata|((err: any|null, response: ReportStatsResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: ReportStatsResponse, metadata: grpc.Metadata) => void): Promise<ReportStatsResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<ReportStatsResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__ReportContent.retrieveStats, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

}


@Injectable({providedIn: 'root'})
export class ReportStreamManager {

  constructor(private _ngZone: NgZone) {
  }

  streamReports(request: StreamID): {cancel():void;close():void}&Observable<StreamModerationItem>;
  streamReports(request: StreamID, metadata: grpc.Metadata): {cancel():void;close():void}&Observable<StreamModerationItem>;
  streamReports(request: StreamID, onMessage: (message?: StreamModerationItem) => void,onError?: (err: any) => void,onEnd?: (code: number, msg: string|undefined, metadata: grpc.Metadata) => void): void;
  streamReports(request: StreamID, metadata: grpc.Metadata, onMessage: (message?: StreamModerationItem) => void,onError?: (err: any) => void,onEnd?: (code: number, msg: string|undefined, metadata: grpc.Metadata) => void): void;

  streamReports(request: StreamID, arg1?: grpc.Metadata|((message?: StreamModerationItem) => void), arg2?: ((message?: StreamModerationItem) => void)|((err: any) => void), arg3?: ((err: any) => void)|((code: number, msg: string|undefined, metadata: grpc.Metadata) => void), arg4?: (code: number, msg: string|undefined, metadata: grpc.Metadata) => void): {cancel():void;close():void}&Observable<StreamModerationItem>|void {
    let ret: any;
    let metadata: any;

    let onMessage: any;
    let onError: any;
    let onEnd: any;
    if(typeof arg1 === 'function') {
      onMessage = arg1;
      onError = arg2;
      onEnd = arg3;
    } else if(typeof arg2 === 'function') {
      metadata = arg1;
      onMessage = arg2;
      onError = arg3;
      onEnd = arg4;
    } else {
      metadata = arg1;
    }

    if(!onMessage) {
      let subject = new Subject<StreamModerationItem>();
      ret = subject.asObservable();

      onMessage = (response: any) => {
        subject.next(response);
      };

      onError = (err: any) => {
        subject.error(err);
      };

      onEnd = (code: any, msg: any, metadata: any) => {
        subject.complete();
      };

    } else {
      if(!onError) {
        onError = (err: any) => console.error(err);
      }

      if(!onEnd) {
        onEnd = (code: any, msg: any, metadata: any) => {};
      }
    }

    let req = grpc.invoke(__ReportStreamManager.StreamReports, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onMessage: response => this._ngZone.run(() => {
        onMessage(response);
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code == grpc.Code.OK) {
          onEnd(code, msg, metadata);
        } else {
          let err = new Error(code + ' ' + (msg||''));
          (<any>err).code = code;
          onError(err);
        }
      })
    });

    (<any>ret).close = () => {
      console.warn('[Angular Grpc] .close() is deprecated use .cancel() instead');
      return req.close();
    };
    (<any>ret).cancel = () => req.close();
    return ret;
  }

  streamReportsControl(request: StreamControl): Promise<StreamControlResponse>;
  streamReportsControl(request: StreamControl, metadata: grpc.Metadata): Promise<StreamControlResponse>;
  streamReportsControl(request: StreamControl, callback: (err: any|null, response: StreamControlResponse, metadata: grpc.Metadata) => void): void;
  streamReportsControl(request: StreamControl, metadata: grpc.Metadata, callback: (err: any|null, response: StreamControlResponse, metadata: grpc.Metadata) => void): void;

  streamReportsControl(request: StreamControl, arg1?: grpc.Metadata|((err: any|null, response: StreamControlResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: StreamControlResponse, metadata: grpc.Metadata) => void): Promise<StreamControlResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<StreamControlResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__ReportStreamManager.StreamReportsControl, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

}

