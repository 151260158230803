// source: gateway/feed_video.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

import * as jspb from 'google-protobuf';
import * as common_legacy_pb from '../common/legacy_pb';
import * as common_delta_pb from '../common/delta_pb';
import * as gateway_mention_pb from '../gateway/mention_pb';
import * as gateway_moderation_pb from '../gateway/moderation_pb';
export namespace FeedVideo {
  export interface AsObject {
    title: string;
    body: string;
    url: string;
    thumbnail: string;
    width: number;
    height: number;
    publishTimestamp: number;
    unpublishTimstamp: number;
    status: boolean;
    bodyMentionRangeList: gateway_mention_pb.MentionRange.AsObject[];
  }
}
export class FeedVideo extends jspb.Message {
  static readonly displayName = "FeedVideo";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [10];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, FeedVideo.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): FeedVideo.AsObject {
    return FeedVideo.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!FeedVideo} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: FeedVideo) {
    var f: any;
    var obj: any = {
      title: jspb.Message.getFieldWithDefault(msg, 1, ""),
      body: jspb.Message.getFieldWithDefault(msg, 2, ""),
      url: jspb.Message.getFieldWithDefault(msg, 3, ""),
      thumbnail: jspb.Message.getFieldWithDefault(msg, 4, ""),
      width: jspb.Message.getFieldWithDefault(msg, 5, 0),
      height: jspb.Message.getFieldWithDefault(msg, 6, 0),
      publishTimestamp: jspb.Message.getFieldWithDefault(msg, 7, 0),
      unpublishTimstamp: jspb.Message.getFieldWithDefault(msg, 8, 0),
      status: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
      bodyMentionRangeList: jspb.Message.toObjectList(msg.getBodyMentionRangeList(),
      gateway_mention_pb.MentionRange.toObject, includeInstance)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!FeedVideo}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new FeedVideo;
    return FeedVideo.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!FeedVideo} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!FeedVideo}
   */
  static deserializeBinaryFromReader(msg: FeedVideo, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setTitle(value1);
        break;
      case 2:
        var value2 = /** @type {string} */ (reader.readString());
        msg.setBody(value2);
        break;
      case 3:
        var value3 = /** @type {string} */ (reader.readString());
        msg.setUrl(value3);
        break;
      case 4:
        var value4 = /** @type {string} */ (reader.readString());
        msg.setThumbnail(value4);
        break;
      case 5:
        var value5 = /** @type {number} */ (reader.readInt32());
        msg.setWidth(value5);
        break;
      case 6:
        var value6 = /** @type {number} */ (reader.readInt32());
        msg.setHeight(value6);
        break;
      case 7:
        var value7 = /** @type {number} */ (reader.readUint64());
        msg.setPublishTimestamp(value7);
        break;
      case 8:
        var value8 = /** @type {number} */ (reader.readUint64());
        msg.setUnpublishTimstamp(value8);
        break;
      case 9:
        var value9 = /** @type {boolean} */ (reader.readBool());
        msg.setStatus(value9);
        break;
      case 10:
        var value10 = new gateway_mention_pb.MentionRange;
        reader.readMessage(value10,gateway_mention_pb.MentionRange.deserializeBinaryFromReader);
        msg.addBodyMentionRange(value10);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!FeedVideo} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: FeedVideo, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getTitle();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
    f = message.getBody();
    if (f.length > 0) {
      writer.writeString(
        2,
        f
      );
    }
    f = message.getUrl();
    if (f.length > 0) {
      writer.writeString(
        3,
        f
      );
    }
    f = message.getThumbnail();
    if (f.length > 0) {
      writer.writeString(
        4,
        f
      );
    }
    f = message.getWidth();
    if (f !== 0) {
      writer.writeInt32(
        5,
        f
      );
    }
    f = message.getHeight();
    if (f !== 0) {
      writer.writeInt32(
        6,
        f
      );
    }
    f = message.getPublishTimestamp();
    if (f !== 0) {
      writer.writeUint64(
        7,
        f
      );
    }
    f = message.getUnpublishTimstamp();
    if (f !== 0) {
      writer.writeUint64(
        8,
        f
      );
    }
    f = message.getStatus();
    if (f) {
      writer.writeBool(
        9,
        f
      );
    }
    f = message.getBodyMentionRangeList();
    if (f.length > 0) {
      writer.writeRepeatedMessage(
        10,
        f,
        gateway_mention_pb.MentionRange.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    FeedVideo.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string title = 1;
   * @return {string}
   */
  getTitle(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setTitle(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


  /**
   * optional string body = 2;
   * @return {string}
   */
  getBody(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
  };


  /** @param {string} value */
  setBody(value: string) {
    jspb.Message.setProto3StringField(this, 2, value);
  }


  /**
   * optional string url = 3;
   * @return {string}
   */
  getUrl(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
  };


  /** @param {string} value */
  setUrl(value: string) {
    jspb.Message.setProto3StringField(this, 3, value);
  }


  /**
   * optional string thumbnail = 4;
   * @return {string}
   */
  getThumbnail(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
  };


  /** @param {string} value */
  setThumbnail(value: string) {
    jspb.Message.setProto3StringField(this, 4, value);
  }


  /**
   * optional int32 width = 5;
   * @return {number}
   */
  getWidth(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
  };


  /** @param {number} value */
  setWidth(value: number) {
    jspb.Message.setProto3IntField(this, 5, value);
  }


  /**
   * optional int32 height = 6;
   * @return {number}
   */
  getHeight(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
  };


  /** @param {number} value */
  setHeight(value: number) {
    jspb.Message.setProto3IntField(this, 6, value);
  }


  /**
   * optional uint64 publish_timestamp = 7;
   * @return {number}
   */
  getPublishTimestamp(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
  };


  /** @param {number} value */
  setPublishTimestamp(value: number) {
    jspb.Message.setProto3IntField(this, 7, value);
  }


  /**
   * optional uint64 unpublish_timstamp = 8;
   * @return {number}
   */
  getUnpublishTimstamp(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
  };


  /** @param {number} value */
  setUnpublishTimstamp(value: number) {
    jspb.Message.setProto3IntField(this, 8, value);
  }


  /**
   * optional bool status = 9;
   * @return {boolean}
   */
  getStatus(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
  };


  /** @param {boolean} value */
  setStatus(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 9, value);
  }


  /**
   * repeated MentionRange body_mention_range = 10;
   * @return {!Array<!MentionRange>}
   */
  getBodyMentionRangeList(): gateway_mention_pb.MentionRange[] {
    return /** @type{!Array<!MentionRange>} */ (
      jspb.Message.getRepeatedWrapperField(this, gateway_mention_pb.MentionRange, 10));
  }


  /** @param {!Array<!MentionRange>} value */
  setBodyMentionRangeList(value?: gateway_mention_pb.MentionRange[]) {
    jspb.Message.setRepeatedWrapperField(this, 10, value);
  }


  /**
   * @param {!MentionRange=} opt_value
   * @param {number=} opt_index
   * @return {!MentionRange}
   */
  addBodyMentionRange(opt_value?: gateway_mention_pb.MentionRange, opt_index?: number): gateway_mention_pb.MentionRange {
    return jspb.Message.addToRepeatedWrapperField(this, 10, opt_value, gateway_mention_pb.MentionRange, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearBodyMentionRangeList() {
    this.setBodyMentionRangeList([]);
  }


}
export namespace FeedVideoDelta {
  export interface AsObject {
    hash: string;
    title?: common_delta_pb.StringDelta.AsObject;
    body?: common_delta_pb.StringDelta.AsObject;
    url?: common_delta_pb.StringDelta.AsObject;
    assetList: string[];
  }
}
export class FeedVideoDelta extends jspb.Message {
  static readonly displayName = "FeedVideoDelta";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [5];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, FeedVideoDelta.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): FeedVideoDelta.AsObject {
    return FeedVideoDelta.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!FeedVideoDelta} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: FeedVideoDelta) {
    var f: any;
    var obj: any = {
      hash: jspb.Message.getFieldWithDefault(msg, 1, ""),
      title: (f = msg.getTitle()) && common_delta_pb.StringDelta.toObject(includeInstance, f),
      body: (f = msg.getBody()) && common_delta_pb.StringDelta.toObject(includeInstance, f),
      url: (f = msg.getUrl()) && common_delta_pb.StringDelta.toObject(includeInstance, f),
      assetList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!FeedVideoDelta}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new FeedVideoDelta;
    return FeedVideoDelta.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!FeedVideoDelta} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!FeedVideoDelta}
   */
  static deserializeBinaryFromReader(msg: FeedVideoDelta, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setHash(value1);
        break;
      case 2:
        var value2 = new common_delta_pb.StringDelta;
        reader.readMessage(value2,common_delta_pb.StringDelta.deserializeBinaryFromReader);
        msg.setTitle(value2);
        break;
      case 3:
        var value3 = new common_delta_pb.StringDelta;
        reader.readMessage(value3,common_delta_pb.StringDelta.deserializeBinaryFromReader);
        msg.setBody(value3);
        break;
      case 4:
        var value4 = new common_delta_pb.StringDelta;
        reader.readMessage(value4,common_delta_pb.StringDelta.deserializeBinaryFromReader);
        msg.setUrl(value4);
        break;
      case 5:
        var value5 = /** @type {string} */ (reader.readString());
        msg.addAsset(value5);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!FeedVideoDelta} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: FeedVideoDelta, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getHash();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
    f = message.getTitle();
    if (f != null) {
      writer.writeMessage(
        2,
        f,
        common_delta_pb.StringDelta.serializeBinaryToWriter
      );
    }
    f = message.getBody();
    if (f != null) {
      writer.writeMessage(
        3,
        f,
        common_delta_pb.StringDelta.serializeBinaryToWriter
      );
    }
    f = message.getUrl();
    if (f != null) {
      writer.writeMessage(
        4,
        f,
        common_delta_pb.StringDelta.serializeBinaryToWriter
      );
    }
    f = message.getAssetList();
    if (f.length > 0) {
      writer.writeRepeatedString(
        5,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    FeedVideoDelta.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string hash = 1;
   * @return {string}
   */
  getHash(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setHash(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


  /**
   * optional minga.common.StringDelta title = 2;
   * @return {?StringDelta}
   */
  getTitle(): common_delta_pb.StringDelta {
    return /** @type{?StringDelta} */ (
      jspb.Message.getWrapperField(this, common_delta_pb.StringDelta, 2));
  }


  /** @param {?StringDelta|undefined} value */
  setTitle(value?: common_delta_pb.StringDelta) {
    jspb.Message.setWrapperField(this, 2, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearTitle() {
    this.setTitle(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasTitle(): boolean {
    return jspb.Message.getField(this, 2) != null;
  }


  /**
   * optional minga.common.StringDelta body = 3;
   * @return {?StringDelta}
   */
  getBody(): common_delta_pb.StringDelta {
    return /** @type{?StringDelta} */ (
      jspb.Message.getWrapperField(this, common_delta_pb.StringDelta, 3));
  }


  /** @param {?StringDelta|undefined} value */
  setBody(value?: common_delta_pb.StringDelta) {
    jspb.Message.setWrapperField(this, 3, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearBody() {
    this.setBody(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasBody(): boolean {
    return jspb.Message.getField(this, 3) != null;
  }


  /**
   * optional minga.common.StringDelta url = 4;
   * @return {?StringDelta}
   */
  getUrl(): common_delta_pb.StringDelta {
    return /** @type{?StringDelta} */ (
      jspb.Message.getWrapperField(this, common_delta_pb.StringDelta, 4));
  }


  /** @param {?StringDelta|undefined} value */
  setUrl(value?: common_delta_pb.StringDelta) {
    jspb.Message.setWrapperField(this, 4, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearUrl() {
    this.setUrl(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasUrl(): boolean {
    return jspb.Message.getField(this, 4) != null;
  }


  /**
   * repeated string asset = 5;
   * @return {!Array<string>}
   */
  getAssetList(): string[] {
    return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 5));
  };


  /** @param {!Array<string>} value */
  setAssetList(value: string[]) {
    jspb.Message.setField(this, 5, value || []);
  }


  /**
   * @param {string} value
   * @param {number=} opt_index
   */
  addAsset(value: string, opt_index?: number): void {
    jspb.Message.addToRepeatedField(this, 5, value, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearAssetList() {
    this.setAssetList([]);
  }


}
export namespace CreateFeedVideoRequest {
  export interface AsObject {
    post?: FeedVideo.AsObject;
  }
}
export class CreateFeedVideoRequest extends jspb.Message {
  static readonly displayName = "CreateFeedVideoRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): CreateFeedVideoRequest.AsObject {
    return CreateFeedVideoRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!CreateFeedVideoRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: CreateFeedVideoRequest) {
    var f: any;
    var obj: any = {
      post: (f = msg.getPost()) && FeedVideo.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!CreateFeedVideoRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new CreateFeedVideoRequest;
    return CreateFeedVideoRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!CreateFeedVideoRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!CreateFeedVideoRequest}
   */
  static deserializeBinaryFromReader(msg: CreateFeedVideoRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new FeedVideo;
        reader.readMessage(value1,FeedVideo.deserializeBinaryFromReader);
        msg.setPost(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!CreateFeedVideoRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: CreateFeedVideoRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getPost();
    if (f != null) {
      writer.writeMessage(
        1,
        f,
        FeedVideo.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    CreateFeedVideoRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional FeedVideo post = 1;
   * @return {?FeedVideo}
   */
  getPost(): FeedVideo {
    return /** @type{?FeedVideo} */ (
      jspb.Message.getWrapperField(this, FeedVideo, 1));
  }


  /** @param {?FeedVideo|undefined} value */
  setPost(value?: FeedVideo) {
    jspb.Message.setWrapperField(this, 1, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearPost() {
    this.setPost(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasPost(): boolean {
    return jspb.Message.getField(this, 1) != null;
  }


}
export namespace CreateFeedVideoResponse {
  export interface AsObject {
    status?: common_legacy_pb.StatusCode;
    reason?: common_legacy_pb.StatusReason.AsObject;
    moderationResult?: gateway_moderation_pb.ModerationResult.AsObject;
  }
}
export class CreateFeedVideoResponse extends jspb.Message {
  static readonly displayName = "CreateFeedVideoResponse";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): CreateFeedVideoResponse.AsObject {
    return CreateFeedVideoResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!CreateFeedVideoResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: CreateFeedVideoResponse) {
    var f: any;
    var obj: any = {
      status: jspb.Message.getFieldWithDefault(msg, 1, 0),
      reason: (f = msg.getReason()) && common_legacy_pb.StatusReason.toObject(includeInstance, f),
      moderationResult: (f = msg.getModerationResult()) && gateway_moderation_pb.ModerationResult.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!CreateFeedVideoResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new CreateFeedVideoResponse;
    return CreateFeedVideoResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!CreateFeedVideoResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!CreateFeedVideoResponse}
   */
  static deserializeBinaryFromReader(msg: CreateFeedVideoResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {!StatusCode} */ (reader.readEnum());
        msg.setStatus(value1);
        break;
      case 2:
        var value2 = new common_legacy_pb.StatusReason;
        reader.readMessage(value2,common_legacy_pb.StatusReason.deserializeBinaryFromReader);
        msg.setReason(value2);
        break;
      case 3:
        var value3 = new gateway_moderation_pb.ModerationResult;
        reader.readMessage(value3,gateway_moderation_pb.ModerationResult.deserializeBinaryFromReader);
        msg.setModerationResult(value3);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!CreateFeedVideoResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: CreateFeedVideoResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getStatus();
    if (f !== 0.0) {
      writer.writeEnum(
        1,
        f
      );
    }
    f = message.getReason();
    if (f != null) {
      writer.writeMessage(
        2,
        f,
        common_legacy_pb.StatusReason.serializeBinaryToWriter
      );
    }
    f = message.getModerationResult();
    if (f != null) {
      writer.writeMessage(
        3,
        f,
        gateway_moderation_pb.ModerationResult.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    CreateFeedVideoResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional StatusCode status = 1;
   * @return {!StatusCode}
   */
  getStatus(): common_legacy_pb.StatusCode {
    return /** @type {!StatusCode} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
  };


  /** @param {!StatusCode} value */
  setStatus(value: common_legacy_pb.StatusCode) {
    jspb.Message.setProto3EnumField(this, 1, value);
  }


  /**
   * optional StatusReason reason = 2;
   * @return {?StatusReason}
   */
  getReason(): common_legacy_pb.StatusReason {
    return /** @type{?StatusReason} */ (
      jspb.Message.getWrapperField(this, common_legacy_pb.StatusReason, 2));
  }


  /** @param {?StatusReason|undefined} value */
  setReason(value?: common_legacy_pb.StatusReason) {
    jspb.Message.setWrapperField(this, 2, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearReason() {
    this.setReason(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasReason(): boolean {
    return jspb.Message.getField(this, 2) != null;
  }


  /**
   * optional minga.ModerationResult moderation_result = 3;
   * @return {?ModerationResult}
   */
  getModerationResult(): gateway_moderation_pb.ModerationResult {
    return /** @type{?ModerationResult} */ (
      jspb.Message.getWrapperField(this, gateway_moderation_pb.ModerationResult, 3));
  }


  /** @param {?ModerationResult|undefined} value */
  setModerationResult(value?: gateway_moderation_pb.ModerationResult) {
    jspb.Message.setWrapperField(this, 3, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearModerationResult() {
    this.setModerationResult(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasModerationResult(): boolean {
    return jspb.Message.getField(this, 3) != null;
  }


}
export namespace UpdateFeedVideoRequest {
  export interface AsObject {
    post?: FeedVideoDelta.AsObject;
  }
}
export class UpdateFeedVideoRequest extends jspb.Message {
  static readonly displayName = "UpdateFeedVideoRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): UpdateFeedVideoRequest.AsObject {
    return UpdateFeedVideoRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!UpdateFeedVideoRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: UpdateFeedVideoRequest) {
    var f: any;
    var obj: any = {
      post: (f = msg.getPost()) && FeedVideoDelta.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!UpdateFeedVideoRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new UpdateFeedVideoRequest;
    return UpdateFeedVideoRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!UpdateFeedVideoRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!UpdateFeedVideoRequest}
   */
  static deserializeBinaryFromReader(msg: UpdateFeedVideoRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new FeedVideoDelta;
        reader.readMessage(value1,FeedVideoDelta.deserializeBinaryFromReader);
        msg.setPost(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!UpdateFeedVideoRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: UpdateFeedVideoRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getPost();
    if (f != null) {
      writer.writeMessage(
        1,
        f,
        FeedVideoDelta.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    UpdateFeedVideoRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional FeedVideoDelta post = 1;
   * @return {?FeedVideoDelta}
   */
  getPost(): FeedVideoDelta {
    return /** @type{?FeedVideoDelta} */ (
      jspb.Message.getWrapperField(this, FeedVideoDelta, 1));
  }


  /** @param {?FeedVideoDelta|undefined} value */
  setPost(value?: FeedVideoDelta) {
    jspb.Message.setWrapperField(this, 1, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearPost() {
    this.setPost(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasPost(): boolean {
    return jspb.Message.getField(this, 1) != null;
  }


}
export namespace UpdateFeedVideoResponse {
  export interface AsObject {
    status?: common_legacy_pb.StatusCode;
    reason?: common_legacy_pb.StatusReason.AsObject;
  }
}
export class UpdateFeedVideoResponse extends jspb.Message {
  static readonly displayName = "UpdateFeedVideoResponse";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): UpdateFeedVideoResponse.AsObject {
    return UpdateFeedVideoResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!UpdateFeedVideoResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: UpdateFeedVideoResponse) {
    var f: any;
    var obj: any = {
      status: jspb.Message.getFieldWithDefault(msg, 1, 0),
      reason: (f = msg.getReason()) && common_legacy_pb.StatusReason.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!UpdateFeedVideoResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new UpdateFeedVideoResponse;
    return UpdateFeedVideoResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!UpdateFeedVideoResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!UpdateFeedVideoResponse}
   */
  static deserializeBinaryFromReader(msg: UpdateFeedVideoResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {!StatusCode} */ (reader.readEnum());
        msg.setStatus(value1);
        break;
      case 2:
        var value2 = new common_legacy_pb.StatusReason;
        reader.readMessage(value2,common_legacy_pb.StatusReason.deserializeBinaryFromReader);
        msg.setReason(value2);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!UpdateFeedVideoResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: UpdateFeedVideoResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getStatus();
    if (f !== 0.0) {
      writer.writeEnum(
        1,
        f
      );
    }
    f = message.getReason();
    if (f != null) {
      writer.writeMessage(
        2,
        f,
        common_legacy_pb.StatusReason.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    UpdateFeedVideoResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional StatusCode status = 1;
   * @return {!StatusCode}
   */
  getStatus(): common_legacy_pb.StatusCode {
    return /** @type {!StatusCode} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
  };


  /** @param {!StatusCode} value */
  setStatus(value: common_legacy_pb.StatusCode) {
    jspb.Message.setProto3EnumField(this, 1, value);
  }


  /**
   * optional StatusReason reason = 2;
   * @return {?StatusReason}
   */
  getReason(): common_legacy_pb.StatusReason {
    return /** @type{?StatusReason} */ (
      jspb.Message.getWrapperField(this, common_legacy_pb.StatusReason, 2));
  }


  /** @param {?StatusReason|undefined} value */
  setReason(value?: common_legacy_pb.StatusReason) {
    jspb.Message.setWrapperField(this, 2, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearReason() {
    this.setReason(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasReason(): boolean {
    return jspb.Message.getField(this, 2) != null;
  }


}
export namespace DeleteFeedVideoRequest {
  export interface AsObject {
    contextHash: string;
  }
}
export class DeleteFeedVideoRequest extends jspb.Message {
  static readonly displayName = "DeleteFeedVideoRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): DeleteFeedVideoRequest.AsObject {
    return DeleteFeedVideoRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!DeleteFeedVideoRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: DeleteFeedVideoRequest) {
    var f: any;
    var obj: any = {
      contextHash: jspb.Message.getFieldWithDefault(msg, 1, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!DeleteFeedVideoRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new DeleteFeedVideoRequest;
    return DeleteFeedVideoRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!DeleteFeedVideoRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!DeleteFeedVideoRequest}
   */
  static deserializeBinaryFromReader(msg: DeleteFeedVideoRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setContextHash(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!DeleteFeedVideoRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: DeleteFeedVideoRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getContextHash();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    DeleteFeedVideoRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string context_hash = 1;
   * @return {string}
   */
  getContextHash(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setContextHash(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


}
export namespace DeleteFeedVideoResponse {
  export interface AsObject {
    status?: common_legacy_pb.StatusCode;
    reason?: common_legacy_pb.StatusReason.AsObject;
  }
}
export class DeleteFeedVideoResponse extends jspb.Message {
  static readonly displayName = "DeleteFeedVideoResponse";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): DeleteFeedVideoResponse.AsObject {
    return DeleteFeedVideoResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!DeleteFeedVideoResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: DeleteFeedVideoResponse) {
    var f: any;
    var obj: any = {
      status: jspb.Message.getFieldWithDefault(msg, 1, 0),
      reason: (f = msg.getReason()) && common_legacy_pb.StatusReason.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!DeleteFeedVideoResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new DeleteFeedVideoResponse;
    return DeleteFeedVideoResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!DeleteFeedVideoResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!DeleteFeedVideoResponse}
   */
  static deserializeBinaryFromReader(msg: DeleteFeedVideoResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {!StatusCode} */ (reader.readEnum());
        msg.setStatus(value1);
        break;
      case 2:
        var value2 = new common_legacy_pb.StatusReason;
        reader.readMessage(value2,common_legacy_pb.StatusReason.deserializeBinaryFromReader);
        msg.setReason(value2);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!DeleteFeedVideoResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: DeleteFeedVideoResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getStatus();
    if (f !== 0.0) {
      writer.writeEnum(
        1,
        f
      );
    }
    f = message.getReason();
    if (f != null) {
      writer.writeMessage(
        2,
        f,
        common_legacy_pb.StatusReason.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    DeleteFeedVideoResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional StatusCode status = 1;
   * @return {!StatusCode}
   */
  getStatus(): common_legacy_pb.StatusCode {
    return /** @type {!StatusCode} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
  };


  /** @param {!StatusCode} value */
  setStatus(value: common_legacy_pb.StatusCode) {
    jspb.Message.setProto3EnumField(this, 1, value);
  }


  /**
   * optional StatusReason reason = 2;
   * @return {?StatusReason}
   */
  getReason(): common_legacy_pb.StatusReason {
    return /** @type{?StatusReason} */ (
      jspb.Message.getWrapperField(this, common_legacy_pb.StatusReason, 2));
  }


  /** @param {?StatusReason|undefined} value */
  setReason(value?: common_legacy_pb.StatusReason) {
    jspb.Message.setWrapperField(this, 2, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearReason() {
    this.setReason(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasReason(): boolean {
    return jspb.Message.getField(this, 2) != null;
  }


}
export namespace VimeoPublicTokenRequest {
  export interface AsObject {
  }
}
export class VimeoPublicTokenRequest extends jspb.Message {
  static readonly displayName = "VimeoPublicTokenRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): VimeoPublicTokenRequest.AsObject {
    return VimeoPublicTokenRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!VimeoPublicTokenRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: VimeoPublicTokenRequest) {
    var f: any;
    var obj: any = {

    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!VimeoPublicTokenRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new VimeoPublicTokenRequest;
    return VimeoPublicTokenRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!VimeoPublicTokenRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!VimeoPublicTokenRequest}
   */
  static deserializeBinaryFromReader(msg: VimeoPublicTokenRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!VimeoPublicTokenRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: VimeoPublicTokenRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    VimeoPublicTokenRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
}
export namespace VimeoPublicTokenResponse {
  export interface AsObject {
    status?: common_legacy_pb.StatusCode;
    reason?: common_legacy_pb.StatusReason.AsObject;
    token: string;
  }
}
export class VimeoPublicTokenResponse extends jspb.Message {
  static readonly displayName = "VimeoPublicTokenResponse";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): VimeoPublicTokenResponse.AsObject {
    return VimeoPublicTokenResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!VimeoPublicTokenResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: VimeoPublicTokenResponse) {
    var f: any;
    var obj: any = {
      status: jspb.Message.getFieldWithDefault(msg, 1, 0),
      reason: (f = msg.getReason()) && common_legacy_pb.StatusReason.toObject(includeInstance, f),
      token: jspb.Message.getFieldWithDefault(msg, 3, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!VimeoPublicTokenResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new VimeoPublicTokenResponse;
    return VimeoPublicTokenResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!VimeoPublicTokenResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!VimeoPublicTokenResponse}
   */
  static deserializeBinaryFromReader(msg: VimeoPublicTokenResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {!StatusCode} */ (reader.readEnum());
        msg.setStatus(value1);
        break;
      case 2:
        var value2 = new common_legacy_pb.StatusReason;
        reader.readMessage(value2,common_legacy_pb.StatusReason.deserializeBinaryFromReader);
        msg.setReason(value2);
        break;
      case 3:
        var value3 = /** @type {string} */ (reader.readString());
        msg.setToken(value3);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!VimeoPublicTokenResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: VimeoPublicTokenResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getStatus();
    if (f !== 0.0) {
      writer.writeEnum(
        1,
        f
      );
    }
    f = message.getReason();
    if (f != null) {
      writer.writeMessage(
        2,
        f,
        common_legacy_pb.StatusReason.serializeBinaryToWriter
      );
    }
    f = message.getToken();
    if (f.length > 0) {
      writer.writeString(
        3,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    VimeoPublicTokenResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional StatusCode status = 1;
   * @return {!StatusCode}
   */
  getStatus(): common_legacy_pb.StatusCode {
    return /** @type {!StatusCode} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
  };


  /** @param {!StatusCode} value */
  setStatus(value: common_legacy_pb.StatusCode) {
    jspb.Message.setProto3EnumField(this, 1, value);
  }


  /**
   * optional StatusReason reason = 2;
   * @return {?StatusReason}
   */
  getReason(): common_legacy_pb.StatusReason {
    return /** @type{?StatusReason} */ (
      jspb.Message.getWrapperField(this, common_legacy_pb.StatusReason, 2));
  }


  /** @param {?StatusReason|undefined} value */
  setReason(value?: common_legacy_pb.StatusReason) {
    jspb.Message.setWrapperField(this, 2, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearReason() {
    this.setReason(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasReason(): boolean {
    return jspb.Message.getField(this, 2) != null;
  }


  /**
   * optional string token = 3;
   * @return {string}
   */
  getToken(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
  };


  /** @param {string} value */
  setToken(value: string) {
    jspb.Message.setProto3StringField(this, 3, value);
  }


}
export namespace VideoThumbnail {
  export interface AsObject {
    url: string;
    width: number;
    height: number;
    custom: boolean;
  }
}
export class VideoThumbnail extends jspb.Message {
  static readonly displayName = "VideoThumbnail";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): VideoThumbnail.AsObject {
    return VideoThumbnail.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!VideoThumbnail} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: VideoThumbnail) {
    var f: any;
    var obj: any = {
      url: jspb.Message.getFieldWithDefault(msg, 1, ""),
      width: jspb.Message.getFieldWithDefault(msg, 2, 0),
      height: jspb.Message.getFieldWithDefault(msg, 3, 0),
      custom: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!VideoThumbnail}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new VideoThumbnail;
    return VideoThumbnail.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!VideoThumbnail} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!VideoThumbnail}
   */
  static deserializeBinaryFromReader(msg: VideoThumbnail, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setUrl(value1);
        break;
      case 2:
        var value2 = /** @type {number} */ (reader.readInt32());
        msg.setWidth(value2);
        break;
      case 3:
        var value3 = /** @type {number} */ (reader.readInt32());
        msg.setHeight(value3);
        break;
      case 4:
        var value4 = /** @type {boolean} */ (reader.readBool());
        msg.setCustom(value4);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!VideoThumbnail} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: VideoThumbnail, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getUrl();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
    f = message.getWidth();
    if (f !== 0) {
      writer.writeInt32(
        2,
        f
      );
    }
    f = message.getHeight();
    if (f !== 0) {
      writer.writeInt32(
        3,
        f
      );
    }
    f = message.getCustom();
    if (f) {
      writer.writeBool(
        4,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    VideoThumbnail.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string url = 1;
   * @return {string}
   */
  getUrl(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setUrl(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


  /**
   * optional int32 width = 2;
   * @return {number}
   */
  getWidth(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
  };


  /** @param {number} value */
  setWidth(value: number) {
    jspb.Message.setProto3IntField(this, 2, value);
  }


  /**
   * optional int32 height = 3;
   * @return {number}
   */
  getHeight(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
  };


  /** @param {number} value */
  setHeight(value: number) {
    jspb.Message.setProto3IntField(this, 3, value);
  }


  /**
   * optional bool custom = 4;
   * @return {boolean}
   */
  getCustom(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
  };


  /** @param {boolean} value */
  setCustom(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 4, value);
  }


}
export namespace VimeoVideoDataRequest {
  export interface AsObject {
    videoUrl: string;
    clientToken: string;
  }
}
export class VimeoVideoDataRequest extends jspb.Message {
  static readonly displayName = "VimeoVideoDataRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): VimeoVideoDataRequest.AsObject {
    return VimeoVideoDataRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!VimeoVideoDataRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: VimeoVideoDataRequest) {
    var f: any;
    var obj: any = {
      videoUrl: jspb.Message.getFieldWithDefault(msg, 1, ""),
      clientToken: jspb.Message.getFieldWithDefault(msg, 2, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!VimeoVideoDataRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new VimeoVideoDataRequest;
    return VimeoVideoDataRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!VimeoVideoDataRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!VimeoVideoDataRequest}
   */
  static deserializeBinaryFromReader(msg: VimeoVideoDataRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setVideoUrl(value1);
        break;
      case 2:
        var value2 = /** @type {string} */ (reader.readString());
        msg.setClientToken(value2);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!VimeoVideoDataRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: VimeoVideoDataRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getVideoUrl();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
    f = message.getClientToken();
    if (f.length > 0) {
      writer.writeString(
        2,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    VimeoVideoDataRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string video_url = 1;
   * @return {string}
   */
  getVideoUrl(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setVideoUrl(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


  /**
   * optional string client_token = 2;
   * @return {string}
   */
  getClientToken(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
  };


  /** @param {string} value */
  setClientToken(value: string) {
    jspb.Message.setProto3StringField(this, 2, value);
  }


}
export namespace VimeoVideoDataResponse {
  export interface AsObject {
    status?: common_legacy_pb.StatusCode;
    reason?: common_legacy_pb.StatusReason.AsObject;
    token: string;
    vimeoId: string;
    title: string;
    body: string;
    width: number;
    height: number;
    thumbnail?: VideoThumbnail.AsObject;
  }
}
export class VimeoVideoDataResponse extends jspb.Message {
  static readonly displayName = "VimeoVideoDataResponse";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): VimeoVideoDataResponse.AsObject {
    return VimeoVideoDataResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!VimeoVideoDataResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: VimeoVideoDataResponse) {
    var f: any;
    var obj: any = {
      status: jspb.Message.getFieldWithDefault(msg, 1, 0),
      reason: (f = msg.getReason()) && common_legacy_pb.StatusReason.toObject(includeInstance, f),
      token: jspb.Message.getFieldWithDefault(msg, 3, ""),
      vimeoId: jspb.Message.getFieldWithDefault(msg, 4, ""),
      title: jspb.Message.getFieldWithDefault(msg, 5, ""),
      body: jspb.Message.getFieldWithDefault(msg, 6, ""),
      width: jspb.Message.getFieldWithDefault(msg, 7, 0),
      height: jspb.Message.getFieldWithDefault(msg, 8, 0),
      thumbnail: (f = msg.getThumbnail()) && VideoThumbnail.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!VimeoVideoDataResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new VimeoVideoDataResponse;
    return VimeoVideoDataResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!VimeoVideoDataResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!VimeoVideoDataResponse}
   */
  static deserializeBinaryFromReader(msg: VimeoVideoDataResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {!StatusCode} */ (reader.readEnum());
        msg.setStatus(value1);
        break;
      case 2:
        var value2 = new common_legacy_pb.StatusReason;
        reader.readMessage(value2,common_legacy_pb.StatusReason.deserializeBinaryFromReader);
        msg.setReason(value2);
        break;
      case 3:
        var value3 = /** @type {string} */ (reader.readString());
        msg.setToken(value3);
        break;
      case 4:
        var value4 = /** @type {string} */ (reader.readString());
        msg.setVimeoId(value4);
        break;
      case 5:
        var value5 = /** @type {string} */ (reader.readString());
        msg.setTitle(value5);
        break;
      case 6:
        var value6 = /** @type {string} */ (reader.readString());
        msg.setBody(value6);
        break;
      case 7:
        var value7 = /** @type {number} */ (reader.readInt32());
        msg.setWidth(value7);
        break;
      case 8:
        var value8 = /** @type {number} */ (reader.readInt32());
        msg.setHeight(value8);
        break;
      case 9:
        var value9 = new VideoThumbnail;
        reader.readMessage(value9,VideoThumbnail.deserializeBinaryFromReader);
        msg.setThumbnail(value9);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!VimeoVideoDataResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: VimeoVideoDataResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getStatus();
    if (f !== 0.0) {
      writer.writeEnum(
        1,
        f
      );
    }
    f = message.getReason();
    if (f != null) {
      writer.writeMessage(
        2,
        f,
        common_legacy_pb.StatusReason.serializeBinaryToWriter
      );
    }
    f = message.getToken();
    if (f.length > 0) {
      writer.writeString(
        3,
        f
      );
    }
    f = message.getVimeoId();
    if (f.length > 0) {
      writer.writeString(
        4,
        f
      );
    }
    f = message.getTitle();
    if (f.length > 0) {
      writer.writeString(
        5,
        f
      );
    }
    f = message.getBody();
    if (f.length > 0) {
      writer.writeString(
        6,
        f
      );
    }
    f = message.getWidth();
    if (f !== 0) {
      writer.writeInt32(
        7,
        f
      );
    }
    f = message.getHeight();
    if (f !== 0) {
      writer.writeInt32(
        8,
        f
      );
    }
    f = message.getThumbnail();
    if (f != null) {
      writer.writeMessage(
        9,
        f,
        VideoThumbnail.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    VimeoVideoDataResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional StatusCode status = 1;
   * @return {!StatusCode}
   */
  getStatus(): common_legacy_pb.StatusCode {
    return /** @type {!StatusCode} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
  };


  /** @param {!StatusCode} value */
  setStatus(value: common_legacy_pb.StatusCode) {
    jspb.Message.setProto3EnumField(this, 1, value);
  }


  /**
   * optional StatusReason reason = 2;
   * @return {?StatusReason}
   */
  getReason(): common_legacy_pb.StatusReason {
    return /** @type{?StatusReason} */ (
      jspb.Message.getWrapperField(this, common_legacy_pb.StatusReason, 2));
  }


  /** @param {?StatusReason|undefined} value */
  setReason(value?: common_legacy_pb.StatusReason) {
    jspb.Message.setWrapperField(this, 2, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearReason() {
    this.setReason(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasReason(): boolean {
    return jspb.Message.getField(this, 2) != null;
  }


  /**
   * optional string token = 3;
   * @return {string}
   */
  getToken(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
  };


  /** @param {string} value */
  setToken(value: string) {
    jspb.Message.setProto3StringField(this, 3, value);
  }


  /**
   * optional string vimeo_id = 4;
   * @return {string}
   */
  getVimeoId(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
  };


  /** @param {string} value */
  setVimeoId(value: string) {
    jspb.Message.setProto3StringField(this, 4, value);
  }


  /**
   * optional string title = 5;
   * @return {string}
   */
  getTitle(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
  };


  /** @param {string} value */
  setTitle(value: string) {
    jspb.Message.setProto3StringField(this, 5, value);
  }


  /**
   * optional string body = 6;
   * @return {string}
   */
  getBody(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
  };


  /** @param {string} value */
  setBody(value: string) {
    jspb.Message.setProto3StringField(this, 6, value);
  }


  /**
   * optional int32 width = 7;
   * @return {number}
   */
  getWidth(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
  };


  /** @param {number} value */
  setWidth(value: number) {
    jspb.Message.setProto3IntField(this, 7, value);
  }


  /**
   * optional int32 height = 8;
   * @return {number}
   */
  getHeight(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
  };


  /** @param {number} value */
  setHeight(value: number) {
    jspb.Message.setProto3IntField(this, 8, value);
  }


  /**
   * optional VideoThumbnail thumbnail = 9;
   * @return {?VideoThumbnail}
   */
  getThumbnail(): VideoThumbnail {
    return /** @type{?VideoThumbnail} */ (
      jspb.Message.getWrapperField(this, VideoThumbnail, 9));
  }


  /** @param {?VideoThumbnail|undefined} value */
  setThumbnail(value?: VideoThumbnail) {
    jspb.Message.setWrapperField(this, 9, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearThumbnail() {
    this.setThumbnail(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasThumbnail(): boolean {
    return jspb.Message.getField(this, 9) != null;
  }


}
export namespace YouTubeVideoDataRequest {
  export interface AsObject {
    videoUrl: string;
    youTubeId: string;
  }
}
export class YouTubeVideoDataRequest extends jspb.Message {
  static readonly displayName = "YouTubeVideoDataRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): YouTubeVideoDataRequest.AsObject {
    return YouTubeVideoDataRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!YouTubeVideoDataRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: YouTubeVideoDataRequest) {
    var f: any;
    var obj: any = {
      videoUrl: jspb.Message.getFieldWithDefault(msg, 1, ""),
      youTubeId: jspb.Message.getFieldWithDefault(msg, 2, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!YouTubeVideoDataRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new YouTubeVideoDataRequest;
    return YouTubeVideoDataRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!YouTubeVideoDataRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!YouTubeVideoDataRequest}
   */
  static deserializeBinaryFromReader(msg: YouTubeVideoDataRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setVideoUrl(value1);
        break;
      case 2:
        var value2 = /** @type {string} */ (reader.readString());
        msg.setYouTubeId(value2);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!YouTubeVideoDataRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: YouTubeVideoDataRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getVideoUrl();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
    f = message.getYouTubeId();
    if (f.length > 0) {
      writer.writeString(
        2,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    YouTubeVideoDataRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string video_url = 1;
   * @return {string}
   */
  getVideoUrl(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setVideoUrl(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


  /**
   * optional string you_tube_id = 2;
   * @return {string}
   */
  getYouTubeId(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
  };


  /** @param {string} value */
  setYouTubeId(value: string) {
    jspb.Message.setProto3StringField(this, 2, value);
  }


}
export namespace YouTubeVideoDataResponse {
  export interface AsObject {
    youTubeId: string;
    title: string;
    body: string;
    width: number;
    height: number;
    thumbnail?: VideoThumbnail.AsObject;
  }
}
export class YouTubeVideoDataResponse extends jspb.Message {
  static readonly displayName = "YouTubeVideoDataResponse";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): YouTubeVideoDataResponse.AsObject {
    return YouTubeVideoDataResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!YouTubeVideoDataResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: YouTubeVideoDataResponse) {
    var f: any;
    var obj: any = {
      youTubeId: jspb.Message.getFieldWithDefault(msg, 1, ""),
      title: jspb.Message.getFieldWithDefault(msg, 2, ""),
      body: jspb.Message.getFieldWithDefault(msg, 3, ""),
      width: jspb.Message.getFieldWithDefault(msg, 4, 0),
      height: jspb.Message.getFieldWithDefault(msg, 5, 0),
      thumbnail: (f = msg.getThumbnail()) && VideoThumbnail.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!YouTubeVideoDataResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new YouTubeVideoDataResponse;
    return YouTubeVideoDataResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!YouTubeVideoDataResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!YouTubeVideoDataResponse}
   */
  static deserializeBinaryFromReader(msg: YouTubeVideoDataResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setYouTubeId(value1);
        break;
      case 2:
        var value2 = /** @type {string} */ (reader.readString());
        msg.setTitle(value2);
        break;
      case 3:
        var value3 = /** @type {string} */ (reader.readString());
        msg.setBody(value3);
        break;
      case 4:
        var value4 = /** @type {number} */ (reader.readInt32());
        msg.setWidth(value4);
        break;
      case 5:
        var value5 = /** @type {number} */ (reader.readInt32());
        msg.setHeight(value5);
        break;
      case 6:
        var value6 = new VideoThumbnail;
        reader.readMessage(value6,VideoThumbnail.deserializeBinaryFromReader);
        msg.setThumbnail(value6);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!YouTubeVideoDataResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: YouTubeVideoDataResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getYouTubeId();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
    f = message.getTitle();
    if (f.length > 0) {
      writer.writeString(
        2,
        f
      );
    }
    f = message.getBody();
    if (f.length > 0) {
      writer.writeString(
        3,
        f
      );
    }
    f = message.getWidth();
    if (f !== 0) {
      writer.writeInt32(
        4,
        f
      );
    }
    f = message.getHeight();
    if (f !== 0) {
      writer.writeInt32(
        5,
        f
      );
    }
    f = message.getThumbnail();
    if (f != null) {
      writer.writeMessage(
        6,
        f,
        VideoThumbnail.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    YouTubeVideoDataResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string you_tube_id = 1;
   * @return {string}
   */
  getYouTubeId(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setYouTubeId(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


  /**
   * optional string title = 2;
   * @return {string}
   */
  getTitle(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
  };


  /** @param {string} value */
  setTitle(value: string) {
    jspb.Message.setProto3StringField(this, 2, value);
  }


  /**
   * optional string body = 3;
   * @return {string}
   */
  getBody(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
  };


  /** @param {string} value */
  setBody(value: string) {
    jspb.Message.setProto3StringField(this, 3, value);
  }


  /**
   * optional int32 width = 4;
   * @return {number}
   */
  getWidth(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
  };


  /** @param {number} value */
  setWidth(value: number) {
    jspb.Message.setProto3IntField(this, 4, value);
  }


  /**
   * optional int32 height = 5;
   * @return {number}
   */
  getHeight(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
  };


  /** @param {number} value */
  setHeight(value: number) {
    jspb.Message.setProto3IntField(this, 5, value);
  }


  /**
   * optional VideoThumbnail thumbnail = 6;
   * @return {?VideoThumbnail}
   */
  getThumbnail(): VideoThumbnail {
    return /** @type{?VideoThumbnail} */ (
      jspb.Message.getWrapperField(this, VideoThumbnail, 6));
  }


  /** @param {?VideoThumbnail|undefined} value */
  setThumbnail(value?: VideoThumbnail) {
    jspb.Message.setWrapperField(this, 6, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearThumbnail() {
    this.setThumbnail(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasThumbnail(): boolean {
    return jspb.Message.getField(this, 6) != null;
  }


}
