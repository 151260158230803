import { Injectable } from '@angular/core';

import { MingaPermission } from 'libs/domain';
import { mingaSettingTypes } from 'libs/util';

import { AppConfigService } from '@app/src/app/minimal/services/AppConfig';
import { PermissionsService } from '@app/src/app/permissions';
import { MingaSettingsService } from '@app/src/app/store/Minga/services';

@Injectable({ providedIn: 'root' })
export class BellSchedulePermissionsService {
  constructor(
    private _appConfig: AppConfigService,
    private _settingsService: MingaSettingsService,
    private _permissions: PermissionsService,
  ) {}

  /**
   * Top level check to enable bell schedule crud features
   */
  public async isBellScheduleEnabled(): Promise<boolean> {
    const scheduleFlag = this._appConfig.getValue('bellScheduleEnabled');

    const scheduleSetting = await this._settingsService.getSettingValue(
      mingaSettingTypes.BELL_SCHEDULE_ENABLE,
    );

    return scheduleFlag && scheduleSetting;
  }

  /**
   * Depending on the current users role and bell schedule settings, determine if bell schedule is enabled
   */
  public async isBellScheduleEnabledForCurrentUser(): Promise<boolean> {
    const isEnabledForTeachersStudents =
      await this._settingsService.getSettingValue(
        mingaSettingTypes.BELL_SCHEDULE_ENABLE_FOR_TEACHERS_STUDENTS,
      );

    if (isEnabledForTeachersStudents) return true;

    const bellScheduleEnabled = await this.isBellScheduleEnabled();
    const isOwner = this._permissions.hasPermission(
      MingaPermission.MINGA_ACCOUNT_MANAGE,
    );

    return isOwner && bellScheduleEnabled;
  }
}
