export interface sisSyncLog {
  numCreated: number;
  numUpdated: number;
  numIgnored: number;
  numArchived: number;
  numRestored: number;
  numErrors: number;
  fileLink: string;
  updatedAt?: Date;
  error?: string;
  type?: string;
}

export interface sisUnifiedSyncLog extends sisSyncLog {
  termTotals?: sisSyncLogTotal;
  sectionTotals?: sisSyncLogTotal;
  studentTotals?: sisSyncLogTotal;
  teacherTotals?: sisSyncLogTotal;
  staffTotals?: sisSyncLogTotal;
  termFileLink?: string;
  sectionFileLink?: string;
  studentFileLink?: string;
  teacherFileLink?: string;
  staffFileLink?: string;
}

export interface sisSyncLogTotal {
  updated: number;
  created: number;
  restored: number;
  ignored: number;
  archived: number;
  errors: number;
}

export enum SisSyncLogErrorType {
  MASS_DELETE = 'mass_delete',
}
