import { ChartOptions, ChartType, Chart, Tooltip } from 'chart.js';
import { formatMinutesIntoHours } from 'libs/domain';

Chart.register(Tooltip);

export enum HpmReportsSummaryMessages {
  STAT_CARD_TOTAL_PASSES = 'Number of Passes',
  STAT_CARD_TOTAL_TIME = 'Total Pass Time',

  SECTION_HEADING_USERS = 'Top Students',
  SECTION_HEADING_CREATORS = 'Top Staff',
  SECTION_HEADING_HALL_PASSES = 'Top Hall Passes',

  BUTTON_SEE_ALL = 'See all',

  NO_DATA = "There's no data for this time period you've selected.",
}

export const BAR_CHART_TIME_Y = {
  beginAtZero: true,
  ticks: {
    color: '#B2B2B2',
    font: {
      size: 14,
      weight: 'bold',
      family: 'Muli',
    },
    maxTicksLimit: 3,
    callback: (val: number) => formatMinutesIntoHours(val),
  },
  grid: {
    display: true,
    lineWidth: 0,
    drawBorder: true,
    borderColor: '2480c5',
    borderWidth: 2,
  },
  title: {
    display: true,
    text: 'Time',
    color: '#B2B2B2',
    font: {
      size: 14,
      weight: 'bold',
      family: 'Muli',
    },
  },
};

export const BAR_CHART_PASS_Y = {
  beginAtZero: true,
  ticks: {
    color: '#B2B2B2',
    font: {
      size: 14,
      weight: 'bold',
      family: 'Muli',
    },
    maxTicksLimit: 3,
    callback: (val: number) => val,
  },
  grid: {
    display: true,
    lineWidth: 0,
    drawBorder: true,
    borderColor: '2480c5',
    borderWidth: 2,
  },
  title: {
    display: true,
    text: 'Passes',
    color: '#B2B2B2',
    font: {
      size: 14,
      weight: 'bold',
      family: 'Muli',
    },
  },
};

export const BAR_CHART_OPTIONS: ChartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    tooltip: {
      titleFont: {
        family: 'Muli',
        size: 14,
      },
      bodyFont: {
        family: 'Muli',
        size: 14,
      },
      titleMarginBottom: 10,
      padding: 10,
      callbacks: {
        label: function (tooltipItem): string {
          const type = tooltipItem.dataset.label;
          if (type) {
            //ugly hack, but no easy way to get what the units should be
            const hasTimeInLabel = type.indexOf('time');
            if (hasTimeInLabel > -1) {
              return (
                ' ' +
                type +
                ' : ' +
                formatMinutesIntoHours(Number(tooltipItem.raw))
              );
            } else {
              return ' ' + type + ' : ' + tooltipItem.raw;
            }
          }

          return ' ' + tooltipItem.raw;
        },
      },
    },
  },
  scales: {
    x: {
      stacked: true,
      ticks: {
        color: '#B2B2B2',
        font: {
          size: 16,
          weight: 'bold',
          family: 'Muli',
        },
      },
      grid: {
        display: true,
        lineWidth: 0,
        color: 'transparent',
        drawBorder: true,
        borderColor: '#2480c5',
        borderWidth: 2,
      },
    },
    y: BAR_CHART_TIME_Y,
  },
};

export const HPM_CHART = {
  options: BAR_CHART_OPTIONS as ChartOptions,
  type: 'bar' as ChartType,
  legend: true,
  plugins: [],
};
