import { Injectable, OnDestroy } from '@angular/core';

import { IHallPassBlackOut } from 'libs/domain';
import { hall_pass_ng_grpc_pb, hall_pass_pb } from 'libs/generated-grpc-web';
import { HallPassBlackOutMapper } from 'libs/shared-grpc';
import { BehaviorSubject, ReplaySubject } from 'rxjs';

import {
  ModalOverlayService,
  ModalOverlayServiceCloseEventType,
} from '@shared/components/modal-overlay';
import { SystemAlertSnackBarService } from '@shared/components/system-alert-snackbar';

import { HpmRestrictionsBlackoutEditComponent } from '../components/hpm-restrictions-blackout-edit/hpm-restrictions-blackout-edit.component';
import {
  HPM_RESTRICTIONS_INITIAL_SCHEDULE_ACTIVE_DAYS_STATE,
  HpmRestrictionsBlackoutEditMessages,
} from '../constants';
import {
  HpmRestrictionsBlackoutEditModalData,
  HpmRestrictionsBlackoutEditModalResponse,
} from '../types';

@Injectable()
export class HpmRestrictionsBlackoutService implements OnDestroy {
  /** General Observables */
  private _destroyed$ = new ReplaySubject<void>(1);

  /** Schedules */
  private readonly _schedules$ = new BehaviorSubject<IHallPassBlackOut[]>([]);
  public readonly schedules$ = this._schedules$.asObservable();

  /** Events */
  private _scheduleCreated$ = new BehaviorSubject<number | undefined>(null);
  public readonly scheduleCreated$ = this._scheduleCreated$.asObservable();

  /** Component Constructor */
  constructor(
    private _hpm: hall_pass_ng_grpc_pb.HallPassManager,
    private _systemAlertSnackBar: SystemAlertSnackBarService,

    private _modalOverlay: ModalOverlayService<
      HpmRestrictionsBlackoutEditModalResponse,
      HpmRestrictionsBlackoutEditModalData
    >,
  ) {}

  ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
    this._schedules$.complete();
    this._scheduleCreated$.complete();
  }

  public async openEditModal(options?: HpmRestrictionsBlackoutEditModalData) {
    const modal = this._modalOverlay.open(
      HpmRestrictionsBlackoutEditComponent,
      {
        data: { ...options },
        disposeOnNavigation: false,
      },
    );
    modal.afterClosed.subscribe(response => {
      if (!response) return;
      const { type } = response;
      switch (type) {
        case ModalOverlayServiceCloseEventType.SUBMIT: {
          this.refetchAllSchedules();
          return;
        }
        case ModalOverlayServiceCloseEventType.CREATE: {
          this.refetchAllSchedules();
          return;
        }
        case ModalOverlayServiceCloseEventType.DELETE: {
          this.refetchAllSchedules();
          return;
        }
      }
    });
  }

  public async refetchAllSchedules(): Promise<void> {
    this.initService();
  }

  public async create(schedule: IHallPassBlackOut): Promise<void> {
    try {
      const newSchedule = await this._createSchedule(schedule);
      this._scheduleCreated$.next(newSchedule.id);
      this._systemAlertSnackBar.success(
        `Successfully created "${schedule.name}"`,
      );
    } catch (error) {
      this._systemAlertSnackBar.error(
        `Failed to update status for "${schedule.name}"`,
      );
    }
  }

  public async update(schedule: IHallPassBlackOut): Promise<void> {
    try {
      await this._updateSchedule(schedule);
      this._systemAlertSnackBar.success(
        `Successfully updated "${schedule.name}"`,
      );
    } catch (error) {
      this._systemAlertSnackBar.error(`Failed to update "${schedule.name}"`);
    }
  }

  public async updateActiveDays(
    schedule: IHallPassBlackOut,
    activeDays: string[],
  ): Promise<void> {
    if (!schedule) return;
    const activeDaysObj = {
      ...HPM_RESTRICTIONS_INITIAL_SCHEDULE_ACTIVE_DAYS_STATE,
    };
    activeDays.forEach(day => (activeDaysObj[`block${day}`] = true));
    try {
      await this._updateSchedule({
        ...schedule,
        ...activeDaysObj,
      });
      this._systemAlertSnackBar.success(
        `Successfully updated active days for "${
          schedule.name ||
          HpmRestrictionsBlackoutEditMessages.NEW_SCHEDULE_NAME_PLACEHOLDER
        }"`,
      );
    } catch (error) {
      this._systemAlertSnackBar.error(
        `Failed to update active days for "${
          schedule.name ||
          HpmRestrictionsBlackoutEditMessages.NEW_SCHEDULE_NAME_PLACEHOLDER
        }"`,
      );
    }
  }

  public async updateStatus(
    schedule: IHallPassBlackOut,
    active: boolean,
  ): Promise<void> {
    if (!schedule) return;
    const { name } = schedule;
    try {
      await this._updateSchedule({
        ...schedule,
        active,
      });
      this._systemAlertSnackBar.success(
        `Successfully updated status for "${name}"`,
      );
    } catch (error) {
      this._systemAlertSnackBar.error(`Failed to update status for "${name}"`);
    }
  }

  public async delete(schedule: IHallPassBlackOut): Promise<void> {
    const { id, name } = schedule;
    try {
      await this._deleteSchedule(id);
      this._systemAlertSnackBar.success(`Successfully deleted "${name}"`);
    } catch (error) {
      this._systemAlertSnackBar.error(`Failed to delete "${name}"`);
    }
  }

  public async initService(): Promise<void> {
    try {
      const schedules = await this._listSchedules();
      this._schedules$.next(schedules);
    } catch (error) {
      this._systemAlertSnackBar.error('Failed to fetch schedules');
    }
  }

  private async _listSchedules(): Promise<IHallPassBlackOut[]> {
    const request = new hall_pass_pb.GetHallPassBlackOutWindowsRequest();
    const response = await this._hpm.getHallPassBlackOutWindows(request);
    const blackOutWindowListProto = response.getHallPassBlackOutWindowsList();
    return blackOutWindowListProto.map(blackOutWindow =>
      HallPassBlackOutMapper.fromProto(blackOutWindow),
    );
  }

  private async _createSchedule(
    schedule: IHallPassBlackOut,
  ): Promise<IHallPassBlackOut> {
    const request = new hall_pass_pb.UpdateHallPassBlackOutWindowRequest();
    request.setBlackOutWindowInfo(HallPassBlackOutMapper.toProto(schedule));
    const response = await this._hpm.updateHallPassBlackOutWindow(request);
    return HallPassBlackOutMapper.fromProto(response.getBlackOutWindowInfo());
  }

  private async _updateSchedule(
    blackOutInfo: IHallPassBlackOut,
  ): Promise<void> {
    const request = new hall_pass_pb.UpdateHallPassBlackOutWindowRequest();
    const blackOutInfoProto = HallPassBlackOutMapper.toProto(blackOutInfo);
    request.setBlackOutWindowInfo(blackOutInfoProto);
    await this._hpm.updateHallPassBlackOutWindow(request);
  }

  private async _deleteSchedule(id: number): Promise<void> {
    const request = new hall_pass_pb.DeleteHallPassBlackOutWindowRequest();
    request.setBlackOutWindowId(id);
    await this._hpm.deleteHallPassBlackOutWindow(request);
  }
}
