import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { RouterModule } from '@angular/router';

import { FtueHighlightPipeModule } from '@app/src/app/ftue/Highlight/pipes/FtueHighlight';
import { NavLinkModule } from '@app/src/app/navigation/components/NavLink';
import { SubNavModule } from '@app/src/app/navigation/components/SubNav';
import { PermissionsModule } from '@app/src/app/permissions';

import { ContentNavComponent } from './ContentNav.component';

@NgModule({
  imports: [
    // Minga dependencies
    NavLinkModule,
    SubNavModule,
    FtueHighlightPipeModule,
    PermissionsModule,
    FlexLayoutModule,

    // External dependencies
    CommonModule,
    RouterModule,
    MatDialogModule,
    MatDatepickerModule,
  ],
  declarations: [ContentNavComponent],
  exports: [ContentNavComponent],
})
export class ContentNavModule {}
