import { Router } from '@angular/router';

import { MingaRoleType } from 'libs/domain';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';

import { BarcodeScanner } from '@app/src/app/barcodeScanner';
import { PermissionsService } from '@app/src/app/permissions';

import { ModalOverlayPrimaryHeaderBackground } from '@shared/components/modal-overlay';
import { SystemAlertSnackBarService } from '@shared/components/system-alert-snackbar';
import { xlsxExport } from '@shared/utils/xlsx-export';

import {
  PsFormBarcode,
  PsFormFilter,
  PsFormPages,
  PsFormSearchResults,
  PsFormSelectionModel,
} from '../abstracts';
import {
  DEFAULT_PS_FORM_FILTERS,
  PEOPLE_SELECTOR_FORMS,
  PS_CAMERA_NOT_FOUND_REOPEN_DELAY,
  PS_CAMERA_REOPEN_DELAY,
} from '../constants';
import {
  PeopleSelectorFormServiceConfig,
  PeopleSelectorForms,
  PsFormName,
  PsFormOptions,
  PsFormsConfig,
  PsFormsConfigPageDefinitions,
  PsSearchMode,
  PsSearchType,
  PsSubmitType,
} from '../types';
import { PeopleSelectorService } from './people-selector.service';

export interface PeopleSelectorFormService {
  /**
   * This method is called when the form modal component is opened.
   */
  onInit?(): void;
  /**
   * This method is called when the form modal component is destroyed,
   * use it to clean up resources or subscriptions in their respective
   * form service class.
   */
  onDestroy?(): void;
}

export abstract class PeopleSelectorFormService<
  FormName extends PsFormName = any,
> extends PsFormBarcode {
  /** Form Config */
  public formTitle: string;
  public config = this._makeConfig(this._config.name);
  public data = {} as PsFormOptions<FormName>;
  public isReadOnly = false;

  /** Search Mode */
  private readonly _searchModeSubj = new BehaviorSubject<PsSearchMode>(
    'keywords',
  );
  public readonly searchMode$ = this._searchModeSubj.asObservable();

  /** Pages */
  public readonly pages = new PsFormPages<FormName>(this.config.pages);

  /** Selection */
  public readonly selection = new PsFormSelectionModel();

  /** Filters */
  public readonly filters = new PsFormFilter();

  // Loading
  private readonly _isSubmittingSubject = new BehaviorSubject(false);
  public readonly isSubmitting$ = this._isSubmittingSubject.asObservable();

  /** Search Results */
  public readonly searchResults = new PsFormSearchResults(
    this.pages.activePath$,
    this.pages.activePageType$,
    this.filters.state$,
    this.selection.selectedHashes$,
  );

  public canSubmit$ = combineLatest([
    this.pages.activePage$,
    this.pages.disabledPages$,
    this.selection.selection$,
  ]).pipe(
    map(([{ maxSelection, path }, disabledPages, selection]) => {
      if (disabledPages.includes(path)) return false;
      const selectionSize = selection.length;
      if (!selectionSize) return false;
      return maxSelection ? selectionSize === maxSelection : true;
    }),
  );

  public updated = false;

  /** Service Constructor */
  constructor(
    private _config: PeopleSelectorFormServiceConfig<FormName>,
    public router: Router,
    public snackBar: SystemAlertSnackBarService,
    public barCodeScanner: BarcodeScanner,
    public peopleSelector: PeopleSelectorService,
    protected _permissions?: PermissionsService,
  ) {
    super();
  }

  public setSearchMode(type: PsSearchMode) {
    this._searchModeSubj.next(type);
  }

  public getCurrentSearchMode(): PsSearchMode {
    return this._searchModeSubj.getValue();
  }

  public async setData(data: PsFormOptions<FormName>) {
    this.data = data;
  }

  public setTitle(val: string) {
    this.formTitle = val;
  }

  public setIsReadOnly(isReadOnly: boolean) {
    this.isReadOnly = isReadOnly;
  }

  public setPagesBlacklist(blacklist: string[]) {
    this.pages.setPagesBlacklist(blacklist);
  }

  public async search(
    type: PsSearchType = 'text',
    filters = this.filters.getCurrentState(),
  ) {
    try {
      this.searchResults.setLoadingState(true);
      const { searchFn } = await this._getDefinition();
      const searchResultsForTab = this.pages.getActivePath();
      const result = await searchFn(type, filters);
      const activeTab = this.pages.getActivePath();

      // If user switches tabs before search results are returned, ignore the results
      if (searchResultsForTab === activeTab) {
        this.searchResults.setData(result);
      }
    } catch (error) {
      this.snackBar.open({
        type: 'error',
        message: error.message,
      });
    } finally {
      this.searchResults.setLoadingState(false);
    }
  }

  public async submit(type: PsSubmitType = 'text') {
    this.peopleSelector.emitEvent('submitted');
    const { submitFn } = await this._getDefinition();
    try {
      this._isSubmittingSubject.next(true);
      await submitFn(type);
      this.updated = true;
    } catch (error) {
      this.peopleSelector.emitEvent('submission-error');
    } finally {
      this._isSubmittingSubject.next(false);
      this._handleAfterSubmission(type);
    }
  }

  public async exportData(): Promise<void> {
    const { data } = this.searchResults.dataSource;
    const headers = ['Name', 'Student ID', 'Grade', 'Status'];
    const rows = data.map(item => [
      item.displayName,
      item.studentId,
      item.grade,
      item.status,
    ]);
    xlsxExport(headers, rows, this.config.title + '.xlsx');
  }

  public async cleanup(hardReset = false, path?: string) {
    const activePath = path || this.pages.getActivePath();
    const formPath = this.config.path;
    this.filters.clear();
    this.selection.clear();
    this.searchResults.reset();
    if (hardReset) {
      this.updated = false;
      this.data = {} as PsFormOptions<FormName>;
      this.peopleSelector.removePageDataFromSessionStorage(
        formPath,
        activePath,
      );
      this.pages.reset();
    }
  }

  public async onSearchScanNoResults(
    id: string,
    searchMode: PsSearchMode,
  ): Promise<void> {
    const snackRef = this.snackBar.open({
      message: `No person found for id: ${id}`,
      type: 'error',
    });
    if (searchMode === 'camera') {
      await new Promise(resolve =>
        setTimeout(resolve, PS_CAMERA_NOT_FOUND_REOPEN_DELAY),
      ).then(() => {
        snackRef.dismiss();
        this.peopleSelector.emitEvent('open-camera');
      });
    } else if (searchMode === 'barcode') {
      this.peopleSelector.emitEvent('focus-barcode');
    }
  }

  public async onListScanNoResults(
    id: string,
    searchMode: PsSearchMode,
  ): Promise<void> {
    this.snackBar.open({
      message: `No person found for id: ${id}`,
      type: 'error',
    });
  }

  private async _handleAfterSubmission(type: PsSubmitType) {
    const pageType = this.pages.getActivePageType();
    if (pageType === 'list') {
      this.filters.clear();
      this.selection.clear();
      await this.search();
    } else {
      await this.cleanup();
    }
    switch (type) {
      case 'text': {
        break;
      }
      case 'barcode': {
        this.peopleSelector.emitEvent('focus-barcode');
        break;
      }
      case 'camera': {
        await new Promise(resolve =>
          setTimeout(resolve, PS_CAMERA_REOPEN_DELAY),
        ).then(() => this.peopleSelector.emitEvent('open-camera'));
        break;
      }
      default: {
        break;
      }
    }
  }

  private async _getDefinition() {
    const activePath = this.pages.getActivePath();
    return this.config.pages[activePath as any];
  }

  public setConfig(config: PeopleSelectorFormServiceConfig<FormName>) {
    this._config = config;
    this.config = this._makeConfig(this._config.name);
  }

  private _makeConfig(formName: PsFormName) {
    console.log('makeConfig', this._config);
    const {
      pages,
      path,
      theme = {
        primaryButton: 'navy',
        headerBg: ModalOverlayPrimaryHeaderBackground.PEOPLE_BLUE,
      },
    } = PEOPLE_SELECTOR_FORMS[formName] as PeopleSelectorForms[FormName] & {
      theme?: PsFormsConfig['theme'];
    };
    const pagesCloned = {
      ...pages,
    } as unknown as PsFormsConfigPageDefinitions;
    const isStudent =
      this._permissions?.hasRoleType(MingaRoleType.STUDENT) ||
      this._permissions?.hasRoleType(MingaRoleType.STUDENT_LEADER);
    const filters = isStudent
      ? DEFAULT_PS_FORM_FILTERS.filter(f => f !== 'lists')
      : DEFAULT_PS_FORM_FILTERS;
    for (const page in pages) {
      if (pages.hasOwnProperty(page)) {
        Object.assign(pagesCloned[page], {
          submitFn: this._config.pageDefinitions[page].submitFn,
          searchFn: this._config.pageDefinitions[page].searchFn,
          filters,
        });
      }
    }
    return {
      title: this._config.name,
      path,
      theme,
      pages: pagesCloned,
    };
  }
}
