import { Component, EventEmitter, OnDestroy, Output } from '@angular/core';

import { IConversation, IConversationWithReadStatus } from 'libs/domain';
import { ReplaySubject, Observable } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

import { MessagingFacade } from '@app/src/app/modules/direct-message/store';
import { IClickConversationPreviewEvent } from '@app/src/app/modules/direct-message/types';

@Component({
  selector: 'mg-dm-list-feed',
  templateUrl: './dm-list-feed.component.html',
  styleUrls: ['./dm-list-feed.component.scss'],
})
export class DmListFeedComponent implements OnDestroy {
  private _destroyedSubject = new ReplaySubject<void>(1);

  @Output()
  clickConversationPreview: EventEmitter<IClickConversationPreviewEvent>;

  readonly conversations$: Observable<
    ReadonlyArray<IConversationWithReadStatus>
  >;

  readonly trackByFn = (index: number, item: IConversation) => {
    return item.id;
  };

  constructor(private _messagingFacade: MessagingFacade) {
    this.clickConversationPreview = new EventEmitter();
    this.conversations$ = this._messagingFacade.getConversations().pipe(
      takeUntil(this._destroyedSubject),
      map(conversations =>
        conversations.filter(conv => {
          return !!conv.lastMessageDate;
        }),
      ),
    );
  }

  ngOnDestroy() {
    this._destroyedSubject.next();
    this._destroyedSubject.complete();
  }
}
