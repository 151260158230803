// GENERATED CODE -- DO NOT EDIT!

import {grpc} from '@improbable-eng/grpc-web';

import * as section_section_pb from '../section/section_pb';
import * as common_date_pb from '../common/date_pb';

export class Sections {
  static readonly serviceName = "section.v1.Sections";

  static readonly GetSections = {
    methodName: "GetSections",
    service: Sections,
    requestStream: false,
    responseStream: false,
    requestType: section_section_pb.GetSectionsRequest,
    responseType: section_section_pb.GetSectionsResponse,
  };

  static readonly CreateSection = {
    methodName: "CreateSection",
    service: Sections,
    requestStream: false,
    responseStream: false,
    requestType: section_section_pb.CreateSectionRequest,
    responseType: section_section_pb.CreateSectionResponse,
  };

  static readonly UpdateSection = {
    methodName: "UpdateSection",
    service: Sections,
    requestStream: false,
    responseStream: false,
    requestType: section_section_pb.UpdateSectionRequest,
    responseType: section_section_pb.UpdateSectionResponse,
  };

  static readonly DeleteSection = {
    methodName: "DeleteSection",
    service: Sections,
    requestStream: false,
    responseStream: false,
    requestType: section_section_pb.DeleteSectionRequest,
    responseType: section_section_pb.DeleteSectionResponse,
  };

}

export class SectionsClient {
  getSections() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  createSection() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  updateSection() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  deleteSection() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
}
