import { day } from 'libs/day';
import { FlexRegistrationInfo, FlexActivityStats } from 'libs/domain';

import { ColumnInfo, ExportNameType } from '../types';
import { gradeFinder, nameFinder, roleFinder, studentIdFinder } from '../utils';
import {
  firstName,
  grade,
  lastName,
  role,
  studentId,
} from './columns.constants';
import {
  DateColumnKeys,
  NameColumnKeys,
  TimeColumnKeys,
} from './common.constants';

const totalTime: ColumnInfo = {
  header: 'Total Time',
  key: 'totalTime',
  type: 'text',
  width: 'medium',
  sort: true,
};

const assigned: ColumnInfo = {
  header: 'Assigned',
  key: 'assigned',
  type: 'number',
  width: 'small',
  sort: true,
};
const registered: ColumnInfo = {
  header: 'Registered',
  key: 'registered',
  type: 'number',
  width: 'small',
  sort: true,
};
const checkIns: ColumnInfo = {
  header: 'Check ins',
  key: 'checkIns',
  type: 'number',
  width: 'medium',
  sort: true,
};
const attendance: ColumnInfo = {
  header: 'Attendance %',
  key: 'attendance',
  type: 'number',
  width: 'medium',
  accessorFn: (item: FlexActivityStats) => {
    const denom = item.registered + item.assigned;
    if (!denom) return 0;
    const percent = (item.checkIns / denom) * 100;
    return percent ? Math.round(percent) : 0;
  },
};

export const REGISTRATION: ColumnInfo[] = [
  {
    header: 'Status',
    key: 'status',
    sort: true,
    type: 'tag',
    width: 'small',
    accessorFn: (item: FlexRegistrationInfo) => item.checkinStatus,
  },
  {
    ...firstName,
    accessorFn: (item: FlexRegistrationInfo) => {
      return nameFinder(item.student, 'first');
    },
  },
  {
    ...lastName,
    accessorFn: (item: FlexRegistrationInfo) => {
      return nameFinder(item.student, 'last');
    },
  },
  {
    header: 'ID Number',
    key: 'studentId',
    tooltip: true,
    type: 'text',
    width: 'large',
    accessorFn: (item: FlexRegistrationInfo) => studentIdFinder(item.student),
  },
  {
    ...grade,
    accessorFn: (item: FlexRegistrationInfo) => gradeFinder(item.student),
  },
  {
    ...role,
    accessorFn: (item: FlexRegistrationInfo, value?: boolean) =>
      roleFinder(item.student, value),
  },
  {
    header: 'Registration',
    key: 'type',
    type: 'text',
    width: 'medium',
    accessorFn: (item: FlexRegistrationInfo) => item.type,
  },
  {
    header: 'Registered by',
    key: 'registerer',
    type: 'text',
    width: 'flex-medium',
    accessorFn: (item: FlexRegistrationInfo) =>
      `${item.registerer.firstName} ${item.registerer.lastName}`,
    sort: true,
  },
  {
    header: 'Register Date',
    key: DateColumnKeys.DATE,
    accessorFn: (item: FlexRegistrationInfo) => item.registeredDate,
    sort: true,
    type: 'date',
    width: 'medium',
  },
  {
    header: 'Period',
    key: 'period',
    tooltip: true,
    type: 'text',
    width: 'flex-medium',
  },
  {
    header: 'Period Date',
    key: 'periodDate',
    sort: true,
    type: 'date',
    width: 'medium',
    accessorFn: (item: FlexRegistrationInfo) =>
      day(item.periodDate).format('MMM D, YYYY'),
  },
  {
    header: 'Period Time',
    key: 'periodStartTime',
    type: 'date',
    width: 'large',
    accessorFn: (item: FlexRegistrationInfo) =>
      day(item.periodStartTime, 'HH:mm:ss').format('hh:mm a'),
  },
  {
    header: 'Activity',
    key: 'activityName',
    type: 'text',
    width: 'flex-medium',
  },
  {
    header: 'Check in Time',
    key: TimeColumnKeys.CHECKIN_TIME,
    accessorFn: (item: FlexRegistrationInfo) => item.checkinDate,
    type: 'date',
    width: 'medium',
  },
  {
    header: 'Teacher',
    key: NameColumnKeys.TEACHER_NAME,
    tooltip: true,
    accessorFn: (item: FlexRegistrationInfo, value?: ExportNameType) => {
      return item.teacher ? nameFinder(item.teacher, value) : '';
    },
    type: 'text',
    width: 'flex-medium',
  },
  {
    header: 'Location',
    key: 'activityLocation',
    type: 'text',
    width: 'medium',
  },
];

export const UNREGISTERED: ColumnInfo[] = [
  { ...firstName, accessorFn: undefined },
  { ...lastName, accessorFn: undefined },
  { ...studentId, accessorFn: undefined },
  { ...grade, accessorFn: undefined },
  { ...role, accessorFn: undefined },
];

export const ACTIVITIES: ColumnInfo[] = [
  {
    header: 'Activity',
    key: 'name',
    type: 'text',
    width: 'flex-medium',
    sort: true,
  },
  registered,
  assigned,
  checkIns,
  attendance,
  totalTime,
];

export const STAFF: ColumnInfo[] = [
  firstName,
  lastName,
  studentId,
  role,
  {
    header: 'Activities',
    key: 'activities',
    type: 'number',
    width: 'small',
    sort: true,
  },
  registered,
  assigned,
  checkIns,
  attendance,
  totalTime,
];

export const STUDENTS: ColumnInfo[] = [
  firstName,
  lastName,
  studentId,
  grade,
  role,
  assigned,
  registered,
  checkIns,
  { ...attendance, accessorFn: undefined },
  {
    header: 'Total Time',
    key: 'totalTime',
    type: 'text',
    width: 'medium',
    sort: true,
  },
];
