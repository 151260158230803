import { gateway } from 'libs/generated-grpc-ts';
import { PersonViewMinimal } from 'libs/generated-grpc-ts';

import { asObjectToMessage } from '../';

export namespace CommentListPresentationMapper {
  export function fromPresentationToCommentContentMessage(
    commentList: gateway.comment_pb.CommentContent.AsObject[],
    likesList?: Map<string, boolean>,
  ): gateway.comment_pb.CommentContent[] {
    const buildCommentMsg = (
      comment: gateway.comment_pb.CommentContent.AsObject,
    ): gateway.comment_pb.CommentContent => {
      const commentMsg = new gateway.comment_pb.CommentContent();
      commentMsg.setText(comment.text);
      commentMsg.setCommentContextHash(comment.commentContextHash);
      commentMsg.setTimestamp(comment.timestamp);
      commentMsg.setTimestamp(comment.timestamp);
      commentMsg.setLikeCount(comment.likeCount);
      commentMsg.setCommentCount(comment.commentCount);
      commentMsg.setDeleted(!!comment.deleted);
      commentMsg.setAuthorPersonView(
        asObjectToMessage(comment.authorPersonView, new PersonViewMinimal()),
      );
      if (likesList) {
        commentMsg.setHasLiked(
          likesList.get(comment.commentContextHash) || false,
        );
      }
      const commentList: gateway.comment_pb.CommentContent[] = [];
      for (const child of comment.commentList) {
        const childMsg = buildCommentMsg(child);
        commentList.push(childMsg);
      }
      commentMsg.setCommentList(commentList);

      return commentMsg;
    };

    const comments: gateway.comment_pb.CommentContent[] = commentList.map(
      comment => {
        return buildCommentMsg(comment);
      },
    );

    return comments;
  }
}
