import { PersonEventStatus } from 'libs/util';

export enum EventStatus {
  GOING = 'GOING',
  INTERESTED = 'INTERESTED',
  INVITED = 'INVITED',
  CHECKED_IN = 'CHECKED_IN',
  CHECKED_OUT = 'CHECKED_OUT',
  NOT_GOING = 'NOT_GOING',
  CHECK_IN = 'CHECK_IN',
  CHECK_OUT = 'CHECK_OUT',
  NONE = 'NONE',
  TICKETED = 'TICKETED',
  NOT_CHECKED_IN = 'NOT_CHECKED_IN',
}

export const PERSON_STATUS_EVENT_STATUS_MAP: Record<
  PersonEventStatus,
  EventStatus
> = {
  [PersonEventStatus.Going]: EventStatus.GOING,
  [PersonEventStatus.CheckedIn]: EventStatus.CHECKED_IN,
  [PersonEventStatus.CheckedOut]: EventStatus.CHECKED_OUT,
  [PersonEventStatus.Interested]: EventStatus.INTERESTED,
  [PersonEventStatus.NotGoing]: EventStatus.NOT_GOING,
};
