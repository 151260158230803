import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { event_pb, event_ng_grpc_pb } from 'libs/generated-grpc-web';

import { BarcodeScanner } from '@app/src/app/barcodeScanner';
import { EventManagementService } from '@app/src/app/events/services/EventManagement';
import { RootService } from '@app/src/app/minimal/services/RootService';

import {
  SystemAlertModalHangTime,
  SystemAlertModalService,
  SystemAlertModalType,
} from '@shared/components/system-alert-modal';
import { SystemAlertSnackBarService } from '@shared/components/system-alert-snackbar';

import { PeopleSelectorService } from '../people-selector.service';
import { PeopleSelectorFormService } from '../ps-form.service';
import { PsCollectionSearchImplService } from '../search-impl/ps-collection-search.impl.service';

@Injectable()
export class PsTicketsService extends PeopleSelectorFormService<'Tickets'> {
  /** Service Constructor */
  constructor(
    public router: Router,
    public snackbar: SystemAlertSnackBarService,
    public barCodeScanner: BarcodeScanner,
    public peopleSelector: PeopleSelectorService,
    private _psCollectionSearch: PsCollectionSearchImplService,
    private _systemAlertModal: SystemAlertModalService,
    private _eventService: event_ng_grpc_pb.Event,
    private _eventManagementService: EventManagementService,
    private _rootService: RootService,
  ) {
    super(
      {
        name: 'Tickets',
        pageDefinitions: {
          add: {
            submitFn: async => this.add(),
            searchFn: async =>
              this._psCollectionSearch.collectionSearch('text', undefined),
          },
        },
      },
      router,
      snackbar,
      barCodeScanner,
      peopleSelector,
    );

    this.setConfig({
      name: 'Tickets',
      pageDefinitions: {
        add: {
          submitFn: async () => this.add(),
          searchFn: async (type, filters) =>
            this._psCollectionSearch.collectionSearch(type, filters),
        },
      },
    });
  }

  public async add(): Promise<void> {
    const contextHash = this.data.contextHash;
    if (contextHash) {
      const personHashes = this.selection
        .getSelection()
        .map(person => person.personHash);
      if (personHashes.length) {
        const request = new event_pb.AddEventTicketsRequest();
        const tickets = this.selection.getSelection().map(person => {
          const ticket = new event_pb.EventTicket();
          ticket.setEmail(person.email);
          return ticket;
        });
        request.setContextHash(contextHash);
        request.setTicketList(tickets);
        await this._rootService.addLoadingPromise(
          this._eventService.addEventTickets(request),
        );

        const modalRef = await this._systemAlertModal.open({
          modalType: SystemAlertModalType.SUCCESS,
          heading: 'Added Tickets',
          hangTime: SystemAlertModalHangTime.LONG,
        });
        await modalRef.afterClosed().toPromise();
      }
    }
  }
}
