import { sisFields } from 'libs/domain';
import { gateway } from 'libs/generated-grpc-ts';

export const toProto = (settings: sisFields): gateway.minga_pb.sisFields => {
  const message = new gateway.minga_pb.sisFields();
  message.setUpdateExisting(settings.updateExisting);
  message.setArchiveMissing(settings.archiveMissing);
  message.setUpdateExistingStudents(settings.updateExistingStudents);
  message.setArchiveMissingStudents(settings.archiveMissingStudents);
  message.setUpdateExistingTeachers(settings.updateExistingTeachers);
  message.setArchiveMissingTeachers(settings.archiveMissingTeachers);
  message.setUpdateExistingStaff(settings.updateExistingStaff);
  message.setArchiveMissingStaff(settings.archiveMissingStaff);
  message.setUpdateExistingAdmins(settings.updateExistingAdmins);
  message.setArchiveMissingAdmins(settings.archiveMissingAdmins);
  message.setActive(settings.active);
  message.setRosteringMethod(settings.rosteringMethod);
  message.setStudentRostering(settings.studentRostering);
  message.setTeacherRostering(settings.teacherRostering);
  message.setStaffRostering(settings.staffRostering);
  message.setAdminRostering(settings.adminRostering);
  message.setClassListRostering(settings.classListRostering);
  message.setParentRostering(settings.parentRostering);

  if (settings.url) message.setUrl(settings.url);
  if (settings.clientId) message.setClientId(settings.clientId);
  if (settings.clientSecret) message.setClientSecret(settings.clientSecret);
  if (settings.authUrl) message.setAuthUrl(settings.authUrl);
  if (settings.schoolId) message.setSchoolId(settings.schoolId);
  if (settings.studentUrl) message.setStudentUrl(settings.studentUrl);
  if (settings.teacherUrl) message.setTeacherUrl(settings.teacherUrl);
  if (settings.lastModified) message.setLastModified(settings.lastModified);
  if (settings.sisType) message.setSisType(settings.sisType);
  if (settings.accessToken) message.setAccessToken(settings.accessToken);
  if (settings.applicationId) message.setApplicationId(settings.applicationId);
  if (settings.idNumberFieldName)
    message.setIdNumberFieldName(settings.idNumberFieldName);
  if (settings.notificationEmails)
    message.setNotificationEmailsList(settings.notificationEmails);
  if (settings.grades) message.setGradesList(settings.grades);
  else message.setGradesNull(true);
  if (settings.termSourcedIds)
    message.setTermSourcedIdsList(settings.termSourcedIds);
  else message.setTermSourcedIdsNull(true);

  return message;
};

export const fromProto = (message: gateway.minga_pb.sisFields): sisFields => {
  // We use undefined as a valid case to avoid doing an extra request to the server.
  // Protobuf inits all repeated fields to [], hence the null flags.
  let termSourcedIds = message.getTermSourcedIdsNull()
    ? undefined
    : message.getTermSourcedIdsList();
  let grades = message.getGradesNull() ? undefined : message.getGradesList();

  // trim strings to avoid URL fetch errors
  return {
    url: message.getUrl()?.trim(),
    clientId: message.getClientId()?.trim(),
    clientSecret: message.getClientSecret()?.trim(),
    authUrl: message.getAuthUrl()?.trim(),
    schoolId: message.getSchoolId()?.trim(),
    updateExisting: message.getUpdateExisting(),
    archiveMissing: message.getArchiveMissing(),
    updateExistingStudents: message.getUpdateExistingStudents(),
    archiveMissingStudents: message.getArchiveMissingStudents(),
    updateExistingTeachers: message.getUpdateExistingTeachers(),
    archiveMissingTeachers: message.getArchiveMissingTeachers(),
    updateExistingStaff: message.getUpdateExistingStaff(),
    archiveMissingStaff: message.getArchiveMissingStaff(),
    updateExistingAdmins: message.getUpdateExistingAdmins(),
    archiveMissingAdmins: message.getArchiveMissingAdmins(),
    active: message.getActive(),
    studentUrl: message.getStudentUrl()?.trim(),
    teacherUrl: message.getTeacherUrl()?.trim(),
    lastModified: message.getLastModified(),
    rosteringMethod: message.getRosteringMethod(),
    studentRostering: message.getStudentRostering(),
    teacherRostering: message.getTeacherRostering(),
    staffRostering: message.getStaffRostering(),
    adminRostering: message.getAdminRostering(),
    classListRostering: message.getClassListRostering(),
    sisType: message.getSisType(),
    accessToken: message.getAccessToken()?.trim(),
    applicationId: message.getApplicationId()?.trim(),
    parentRostering: message.getParentRostering(),
    idNumberFieldName: message.getIdNumberFieldName()?.trim(),
    notificationEmails: message.getNotificationEmailsList(),
    termSourcedIds,
    grades,
  } as sisFields;
};
