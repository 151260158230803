import { BinaryWriter } from 'google-protobuf';
import { INewDirectMessage } from 'libs/domain';
import { realtime_pb } from 'libs/generated-grpc-ts';

import { MessageMapper } from '../messaging';

export namespace INewDirectMessageMapper {
  export function fromBase64(b64: string): INewDirectMessage {
    const proto = realtime_pb.NewDirectMessage.deserializeBinary(b64);
    return fromProto(proto);
  }

  export function toBase64(newDm: INewDirectMessage) {
    const binaryWriter = new BinaryWriter();
    realtime_pb.NewDirectMessage.serializeBinaryToWriter(
      toProto(newDm),
      binaryWriter,
    );
    return binaryWriter.getResultBase64String();
  }

  export function fromProto(
    msg: realtime_pb.NewDirectMessage,
  ): INewDirectMessage {
    return {
      conversationId: msg.getConversationId(),
      messageId: msg.getMessageId(),
      body: msg.getBody(),
      timestamp: msg.getTimestamp(),
      authorPersonHash: msg.getAuthorPersonHash(),
      attachmentList:
        MessageMapper.fromProtoMessageAttachmentToIMessageAttachment(
          msg.getAttachmentList(),
        ),
    };
  }

  export function toProto(
    newDm: INewDirectMessage,
  ): realtime_pb.NewDirectMessage {
    const msg = new realtime_pb.NewDirectMessage();
    msg.setConversationId(newDm.conversationId);
    msg.setMessageId(newDm.messageId);
    msg.setBody(newDm.body);
    msg.setTimestamp(newDm.timestamp);
    msg.setAuthorPersonHash(newDm.authorPersonHash);
    msg.setAttachmentList(
      MessageMapper.fromIMessageAttachmentToProtoMessageAttachment(
        newDm.attachmentList,
      ),
    );
    return msg;
  }
}
