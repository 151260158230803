import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';

import { MgImageModule } from '@app/src/app/components/MgImage';
import { MgIconModule } from '@app/src/app/icon';

import { GenericButtonModule } from '../button';
import { GenericModule } from '../generic';
import { TextModule } from '../text/text.module';
import { PointsRewardsListComponent } from './points-rewards-list.component';

@NgModule({
  imports: [
    // Minga dependencies
    MgIconModule,
    MgImageModule,
    TextModule,
    GenericModule,

    // External dependencies
    CommonModule,
    FlexLayoutModule,
  ],
  exports: [PointsRewardsListComponent],
  declarations: [PointsRewardsListComponent],
})
export class PointsRewardsListModule {}
