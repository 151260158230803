import { Pipe, PipeTransform } from '@angular/core';

import { ReportTypes } from 'libs/domain';

import { REPORT_TYPE_OPTIONS } from '../constants';

@Pipe({ name: 'reportTypeLabel' })
export class ReportTypeLabelPipe implements PipeTransform {
  transform(type: ReportTypes): string {
    const selected = REPORT_TYPE_OPTIONS.find(
      option => option.value === (type as any),
    );

    return selected?.label || '';
  }
}
