import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  HostListener,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { MediaObserver } from '@angular/flex-layout';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';

import { HallPassStatusEnum } from 'libs/domain';
import { MingaPermission } from 'libs/domain';
import { getHallPassNote } from 'libs/shared';
import { mingaSettingTypes } from 'libs/util';
import { flatten, get } from 'lodash';
import {
  BehaviorSubject,
  Observable,
  ReplaySubject,
  Subscription,
  combineLatest,
} from 'rxjs';
import { distinctUntilChanged, map, takeUntil, tap } from 'rxjs/operators';

import { AuthInfoService } from '@app/src/app/minimal/services/AuthInfo';
import { PermissionsService } from '@app/src/app/permissions';
import { MingaSettingsService } from '@app/src/app/store/Minga/services';

import { BellSchedulePermissionsService } from '@modules/minga-manager/components/mm-bell-schedule/services/bell-schedule-permissions.service';

import {
  SystemAlertModalService,
  SystemAlertModalType,
} from '@shared/components/system-alert-modal';
import { Variant } from '@shared/components/text';

import {
  HPM_DASHBOARD_TABLE_COLUMNS,
  HpmDashboardTableColumn,
  HpmDashboardTableMessages,
  TABLE_VIEWPORT_WIDTH_BREAKPOINT,
} from '../../constants';
import { HPM_DASHBOARD_STATUS_COLOR_MAP } from '../../constants/hpm-dashboard-styling.constants';
import { HpmDashboardPassService, HpmDashboardService } from '../../services';
import {
  HpmDashboardFilters,
  HpmDashboardTableFilters,
  HpmDashboardTableItem,
} from '../../types';

/**
 * Hall Pass Manager Dashboard Table
 *
 * @link https://v9.material.angular.io/components/table/overview
 * @link https://v9.material.angular.io/components/sort/overview
 */
@Component({
  selector: 'mg-hpm-dashboard-table',
  templateUrl: './hpm-dashboard-table.component.html',
  styleUrls: ['./hpm-dashboard-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HpmDashboardTableComponent implements AfterViewInit, OnDestroy {
  /** Mat Table API */
  @ViewChild(MatTable) table: MatTable<any>;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;

  /** Constants */
  public readonly MESSAGES = HpmDashboardTableMessages;
  public readonly COLUMN_DEF = HpmDashboardTableColumn;
  public readonly PASS_STATUS = HallPassStatusEnum;
  public readonly imageSizes = ['cardbanner'];
  public readonly STATUS_COLOR_MAP = HPM_DASHBOARD_STATUS_COLOR_MAP;
  public readonly MOBILE_BREAKPOINTS = ['xs', 'sm', 'md'];

  /** General Observables */
  private readonly _destroyed$ = new ReplaySubject<void>(1);
  public readonly media$: Observable<any>;
  public readonly mediaSubscription$: Subscription;
  public readonly mobilePanelState = new BehaviorSubject<'opened' | 'closed'>(
    'closed',
  );
  public readonly mobileFontSize$: Observable<Variant>;

  /** User */
  public loggedInUserHash: string;

  /** Settings */
  // @TODO: if true, hide the notes column
  public readonly isNoteAllowed$: Observable<boolean> =
    this._settings.getSettingValueObs(mingaSettingTypes.PASS_ALLOW_NOTE);
  private _isNoteAllowed: boolean;

  /** Show recently ended setting */
  public readonly showRecentlyEndedSetting$ =
    this._mingaSettings.getSettingValueObs(
      mingaSettingTypes.PASS_SHOW_RECENTLY_ENDED,
    );

  /** Recently ended timer setting */
  public readonly recentlyEndedSetting$ =
    this._mingaSettings.getSettingValueObs(
      mingaSettingTypes.PASS_RECENTLY_ENDED_FILTER,
    );

  public manuallyEndedPassesSetting: boolean;
  public readonly manuallyEndedPassesSetting$ = this._mingaSettings
    .getSettingValueObs(mingaSettingTypes.PASS_MUST_MANUALLY_END)
    .pipe(
      tap(value => {
        this.manuallyEndedPassesSetting = value;
      }),
    );

  /** Pass Types Data */
  public readonly typesData$: Observable<any>;

  /** Table Data */
  public readonly dataSource = new MatTableDataSource<HpmDashboardTableItem>(
    [],
  );
  private readonly _filteredData$ = new BehaviorSubject<
    HpmDashboardTableItem[]
  >([]);
  public readonly filteredData$ = this._filteredData$.asObservable();

  private _displayedColumnsSubject = new BehaviorSubject<string[]>([]);
  public readonly displayedColumns$: Observable<string[]> =
    this._displayedColumnsSubject.asObservable();
  public typeData;

  // using manage hall pass type permission as a way to derive if the user is a hall pass admin vs teacher
  public isHallPassAdmin$ = this._permissions.observePermission(
    MingaPermission.HALL_PASS_TYPE_MANAGE,
  );
  public authHash$ = this.authInfo.authPersonHash$;

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this._getDisplayedColumns(event.target.innerWidth);
  }

  /** Component Constructor */
  constructor(
    public mediaObserver: MediaObserver,
    public hpmDashboard: HpmDashboardService,
    public passActions: HpmDashboardPassService,
    public authInfo: AuthInfoService,
    private _settings: MingaSettingsService,
    private _permissions: PermissionsService,
    private _mingaSettings: MingaSettingsService,
    private _bsPerm: BellSchedulePermissionsService,
    private _systemAlertModal: SystemAlertModalService,
  ) {
    this.isNoteAllowed$.subscribe(value => (this._isNoteAllowed = value));

    this.typesData$ = this.hpmDashboard.passTypes$.pipe(
      takeUntil(this._destroyed$),
      map(
        types =>
          types.reduce(
            (hashMap, type) => ({
              [type.id]: type,
              ...hashMap,
            }),
            {},
          ) as any,
      ),
      tap(() => this.hpmDashboard.setLoadingStatus(false)),
    );

    this.typesData$
      .pipe(takeUntil(this._destroyed$))
      .subscribe(x => (this.typeData = x));

    combineLatest([
      this.hpmDashboard.filters$,
      this.hpmDashboard.passes$,
      this.showRecentlyEndedSetting$,
      this.recentlyEndedSetting$,
    ])
      .pipe(takeUntil(this._destroyed$))
      .subscribe(
        ([filters, passes, showRecentlyEnded, recentlyEndedFilterSetting]) => {
          this.dataSource.data = passes;
          this._applyFilters(
            filters,
            showRecentlyEnded,
            recentlyEndedFilterSetting,
          );
        },
      );

    this.media$ = this.mediaObserver.asObservable().pipe(
      takeUntil(this._destroyed$),
      map(change => change[0].mqAlias),
      distinctUntilChanged(),
    );

    this.authInfo.authPerson$
      .pipe(takeUntil(this._destroyed$))
      .subscribe(data => {
        this.loggedInUserHash = data.hash;
      });
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.dataSource.sort.active = 'status.priority';
    this.dataSource.sort.direction = 'asc';
    this.dataSource.filterPredicate = this._tableFilter;
    this.dataSource.sortingDataAccessor = get;
    this._getDisplayedColumns(window.innerWidth);
  }

  ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  public trackById(index: number, pass) {
    if (!pass) return index;
    return pass.id;
  }

  public getFilteredData() {
    return this.dataSource.filteredData;
  }

  public getTypes() {
    return this.typeData;
  }

  public showIcon(type: any): boolean {
    return !(type.manuallyEndPass ?? this.manuallyEndedPassesSetting);
  }

  public getNote(note, noteToRequester): string {
    return getHallPassNote(note, noteToRequester);
  }

  public getPassCurrentClass(pass: HpmDashboardTableItem) {
    if (!pass.currentClassesList?.length) return;
    let output = '';
    for (const currentClass of pass.currentClassesList) {
      output += `Class: ${currentClass.name}\n`;
    }
    return output;
  }

  public showMobileNote(note: string) {
    this._systemAlertModal.open({
      modalType: SystemAlertModalType.DEFAULT,
      heading: 'Hall pass note:',
      message: note,
      icon: 'mg-description',
    });
  }

  private _applyFilters(
    filters: HpmDashboardFilters,
    showRecentlyEnded: boolean,
    recentlyEndedFilterSetting: number,
  ) {
    this.dataSource.filter = JSON.stringify({
      ...filters,
      teacher_hash: this.loggedInUserHash,
      showRecentlyEnded,
      recentlyEndedFilterSetting,
    });
    this._filteredData$.next(this.getFilteredData());
  }

  private _tableFilter(data: HpmDashboardTableItem, f: string) {
    const {
      authors,
      pass_id,
      pass_status,
      recipients,
      show_mine,
      teacher_hash,
      showRecentlyEnded,
    } = JSON.parse(f) as HpmDashboardTableFilters;

    const recipientsNames = flatten(recipients).map(
      person => person.displayName,
    );
    const filterRecipients = recipientsNames.length
      ? recipientsNames.includes(data.recipientPersonView.displayName)
      : true;

    const authorHashes = flatten(authors).map(person => person?.hash);
    const authorHash = data.authorPersonView.personHash;
    const filterAuthors = authorHashes.length
      ? authorHashes.includes(authorHash)
      : true;

    const filterByPassStatus = pass_status.length
      ? data.status.state === HallPassStatusEnum.ENDED &&
        showRecentlyEnded &&
        pass_status.includes(HallPassStatusEnum.ACTIVE)
        ? true
        : pass_status.includes(data.status.state)
      : true;
    const filterType = pass_id.length ? pass_id.includes(data.typeId) : true;
    const filterMyPasses = show_mine
      ? data.authorPersonView.personHash === teacher_hash ||
        data.approvedByPersonView?.personHash === teacher_hash
      : true;
    return [
      filterRecipients,
      filterAuthors,
      filterType,
      filterMyPasses,
      filterByPassStatus,
    ].every(v => v);
  }

  private async _getDisplayedColumns(width: number) {
    // 1218px viewport --> 920px table width (when sidebar expanded)
    if (width < TABLE_VIEWPORT_WIDTH_BREAKPOINT) {
      this._displayedColumnsSubject.next([HpmDashboardTableColumn.MOBILE]);
    } else {
      let col = HPM_DASHBOARD_TABLE_COLUMNS;
      if (!this._isNoteAllowed) {
        col = col.filter(c => c !== HpmDashboardTableColumn.NOTES);
      }
      const bsAccess = await this._bsPerm.isBellScheduleEnabledForCurrentUser();
      if (!bsAccess) {
        col = col.filter(c => c !== HpmDashboardTableColumn.CURRENT_CLASS);
      }

      this._displayedColumnsSubject.next(col);
    }
  }
}
