import { Directionality } from '@angular/cdk/bidi';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  NgZone,
  OnChanges,
  OnInit,
  Optional,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MatDateFormats,
} from '@angular/material/core';
import { MatMonthView } from '@angular/material/datepicker';

import { ICalendarSelectDay } from '@app/src/app/minimal/services/EventContent';

@Component({
  templateUrl: './CustomMonthView.component.html',
  selector: 'mg-month-view',
  styleUrls: ['./CustomMonthView.component.scss'],
  exportAs: 'matMonthView',
})
export class CustomMonthView<D>
  extends MatMonthView<D>
  implements OnInit, OnChanges
{
  @Input()
  multiSelectDays: ICalendarSelectDay[] = [];
  @Output()
  onLastRowClick: EventEmitter<any> = new EventEmitter();
  @Input()
  filteringDays: ICalendarSelectDay[] = [];

  filteredDays: ICalendarSelectDay[] = [];

  filteredFilteringDays: ICalendarSelectDay[] = [];

  constructor(
    _changeDetectorRef: ChangeDetectorRef,
    @Optional() @Inject(MAT_DATE_FORMATS) _dateFormats: MatDateFormats,
    @Optional() _dateAdapter: DateAdapter<D>,
    @Optional() _dir?: Directionality,
  ) {
    super(_changeDetectorRef, _dateFormats, _dateAdapter, _dir);

    const firstDayOfWeek = this._dateAdapter.getFirstDayOfWeek();
    const narrowWeekdays = this._dateAdapter.getDayOfWeekNames('narrow');
    const shortWeekdays = this._dateAdapter.getDayOfWeekNames('short');
    const longWeekdays = this._dateAdapter.getDayOfWeekNames('long');
    let weekdays = longWeekdays.map((long, i) => {
      return { long, narrow: narrowWeekdays[i], short: shortWeekdays[i] };
    });
    this._weekdays = weekdays
      .slice(firstDayOfWeek)
      .concat(weekdays.slice(0, firstDayOfWeek));
  }

  ngOnInit() {
    this.filterMultiSelectDays();
    this.filterFilteringDays();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.activeDate) {
      this.filterMultiSelectDays();
      this.filterFilteringDays();
    }
    if (changes.multiSelectDays) {
      this.filterMultiSelectDays();
    }
    if (changes.filteringDays) {
      this.filterFilteringDays();
    }
  }

  // go through filterDays and add if not a duplicate, prioritizing going
  private _addTofilteredDays(
    addDay: ICalendarSelectDay,
    daysArray = this.filteredDays,
  ) {
    for (let i = 0; i < daysArray.length; ++i) {
      let day = daysArray[i];
      if (day.date.getDate() == addDay.date.getDate()) {
        if (!day.going && addDay.going) {
          daysArray[i] = addDay;
          return;
        }
        if (!day.checkedIn && addDay.checkedIn) {
          daysArray[i] = addDay;
          return;
        }
      }
    }

    daysArray.push(addDay);
  }

  isActiveMonthInThePast() {
    const monthNames = this._dateAdapter.getMonthNames('short');

    const today = new Date();
    const currentMonthIndex = today.getMonth();
    const currentYear = today.getFullYear();
    const activeYear = this._dateAdapter.getYear(this.activeDate);

    if (activeYear < currentYear) {
      return true;
    } else if (activeYear > currentYear) {
      return false;
    } else if (
      this._dateAdapter.getMonth(this.activeDate) < currentMonthIndex
    ) {
      return true;
    } else {
      return false;
    }
  }

  _onLastRowClick(e) {
    this.onLastRowClick.emit(e);
  }

  filterMultiSelectDays() {
    // since we're filtering new data, reset
    this.filteredDays = [];

    if (!this.multiSelectDays) return;
    for (let day of this.multiSelectDays) {
      if (!day || !day.date) {
        day = null;
      } else if (
        day.date.getMonth() != this._dateAdapter.getMonth(this.activeDate) ||
        day.date.getFullYear() != this._dateAdapter.getYear(this.activeDate)
      ) {
        day = null;
      } else {
        this._addTofilteredDays(day);
      }
    }
  }

  filterFilteringDays() {
    // since we're filtering new data, reset
    this.filteredFilteringDays = [];

    if (!this.filteringDays || !this.filteringDays.length) {
      // set to falsey value
      this.filteredFilteringDays = null;
      return;
    }

    for (let day of this.filteringDays) {
      if (!day || !day.date) {
        day = null;
      } else if (
        day.date.getMonth() != this._dateAdapter.getMonth(this.activeDate)
      ) {
        day = null;
      } else {
        this._addTofilteredDays(day, this.filteredFilteringDays);
      }
    }
  }
}
