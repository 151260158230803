import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';

import { TranslateModule } from '@ngx-translate/core';

import { MgFormFieldModule } from '@app/src/app/form-field';

import { MgButtonModule } from '../../button';
import { SaveCancelDialog } from './SaveCancel.component';

@NgModule({
  imports: [
    // Minga dependencies
    MgButtonModule,
    MgFormFieldModule,
    // External dependencies
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    TranslateModule.forChild(),
  ],
  declarations: [SaveCancelDialog],
  exports: [SaveCancelDialog],
})
export class SaveCancelDialogModule {}
