import { NgModule } from '@angular/core';

import { MgStreamScrollerModule } from '@app/src/app/components/MgStreamScroller';
import { MgContentFeedItemModule } from '@app/src/app/elements/MgContentFeedItem';

import { CommonFormModules, CommonUIModules } from '@shared/modules';

import { GlobalSearchContentComponent } from './components/global-search-content/global-search-content.component';
import { GlobalSearchPeopleComponent } from './components/global-search-people/global-search-people.component';
import { GlobalSearchPortalComponent } from './components/global-search-portal.component';
import { SearchAreaDirective } from './directives';
import { GlobalSearchComponent } from './global-search.component';

@NgModule({
  imports: [
    // Common modules
    CommonUIModules,
    CommonFormModules,

    // Minga dependencies
    MgContentFeedItemModule,
    MgStreamScrollerModule,

    // External dependencies
  ],
  declarations: [
    // Components
    GlobalSearchComponent,
    GlobalSearchPortalComponent,
    GlobalSearchContentComponent,
    GlobalSearchPeopleComponent,

    // Directives
    SearchAreaDirective,
  ],
  exports: [
    // Components
    GlobalSearchComponent,
    // RouterModule,

    // Directives
    SearchAreaDirective,
  ],
})
export class GlobalSearchModule {}
