import { Injectable, NgZone } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { grpc } from '@improbable-eng/grpc-web';

import {
  StreamControl,
  StreamControlResponse,
  StreamID,
} from '../common/stream_pb';
import {
  MessagingCheckUnreadRequest,
  MessagingCheckUnreadResponse,
  MessagingGetConversationRequest,
  MessagingGetConversationResponse,
  MessagingGetConversationsRequest,
  MessagingGetConversationsResponse,
  MessagingGetMessagesRequest,
  MessagingGetMessagesResponse,
  MessagingMarkAsReadRequest,
  MessagingMarkAsReadResponse,
  MessagingSendMessageRequest,
  MessagingSendMessageResponse,
  MessagingStartConversationRequest,
  MessagingStartConversationResponse,
  MessagingStreamConversationsResponse,
} from '../messaging/messaging_pb';

import {
  Messaging as __Messaging,
} from '../messaging/messaging_pb_service';

@Injectable({providedIn: 'root'})
export class Messaging {

  constructor(private _ngZone: NgZone) {
  }

  /**
    / Start a new conversation
  */
  startConversation(request: MessagingStartConversationRequest): Promise<MessagingStartConversationResponse>;
  /**
    / Start a new conversation
  */
  startConversation(request: MessagingStartConversationRequest, metadata: grpc.Metadata): Promise<MessagingStartConversationResponse>;
  /**
    / Start a new conversation
  */
  startConversation(request: MessagingStartConversationRequest, callback: (err: any|null, response: MessagingStartConversationResponse, metadata: grpc.Metadata) => void): void;
  /**
    / Start a new conversation
  */
  startConversation(request: MessagingStartConversationRequest, metadata: grpc.Metadata, callback: (err: any|null, response: MessagingStartConversationResponse, metadata: grpc.Metadata) => void): void;

  startConversation(request: MessagingStartConversationRequest, arg1?: grpc.Metadata|((err: any|null, response: MessagingStartConversationResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: MessagingStartConversationResponse, metadata: grpc.Metadata) => void): Promise<MessagingStartConversationResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<MessagingStartConversationResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__Messaging.StartConversation, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  /**
    / Add a new message to an existing conversation
  */
  sendMessage(request: MessagingSendMessageRequest): Promise<MessagingSendMessageResponse>;
  /**
    / Add a new message to an existing conversation
  */
  sendMessage(request: MessagingSendMessageRequest, metadata: grpc.Metadata): Promise<MessagingSendMessageResponse>;
  /**
    / Add a new message to an existing conversation
  */
  sendMessage(request: MessagingSendMessageRequest, callback: (err: any|null, response: MessagingSendMessageResponse, metadata: grpc.Metadata) => void): void;
  /**
    / Add a new message to an existing conversation
  */
  sendMessage(request: MessagingSendMessageRequest, metadata: grpc.Metadata, callback: (err: any|null, response: MessagingSendMessageResponse, metadata: grpc.Metadata) => void): void;

  sendMessage(request: MessagingSendMessageRequest, arg1?: grpc.Metadata|((err: any|null, response: MessagingSendMessageResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: MessagingSendMessageResponse, metadata: grpc.Metadata) => void): Promise<MessagingSendMessageResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<MessagingSendMessageResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__Messaging.SendMessage, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  /**
    / Marks messages as read
  */
  markAsRead(request: MessagingMarkAsReadRequest): Promise<MessagingMarkAsReadResponse>;
  /**
    / Marks messages as read
  */
  markAsRead(request: MessagingMarkAsReadRequest, metadata: grpc.Metadata): Promise<MessagingMarkAsReadResponse>;
  /**
    / Marks messages as read
  */
  markAsRead(request: MessagingMarkAsReadRequest, callback: (err: any|null, response: MessagingMarkAsReadResponse, metadata: grpc.Metadata) => void): void;
  /**
    / Marks messages as read
  */
  markAsRead(request: MessagingMarkAsReadRequest, metadata: grpc.Metadata, callback: (err: any|null, response: MessagingMarkAsReadResponse, metadata: grpc.Metadata) => void): void;

  markAsRead(request: MessagingMarkAsReadRequest, arg1?: grpc.Metadata|((err: any|null, response: MessagingMarkAsReadResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: MessagingMarkAsReadResponse, metadata: grpc.Metadata) => void): Promise<MessagingMarkAsReadResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<MessagingMarkAsReadResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__Messaging.MarkAsRead, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  /**
    / Get a single conversation
  */
  getConversation(request: MessagingGetConversationRequest): Promise<MessagingGetConversationResponse>;
  /**
    / Get a single conversation
  */
  getConversation(request: MessagingGetConversationRequest, metadata: grpc.Metadata): Promise<MessagingGetConversationResponse>;
  /**
    / Get a single conversation
  */
  getConversation(request: MessagingGetConversationRequest, callback: (err: any|null, response: MessagingGetConversationResponse, metadata: grpc.Metadata) => void): void;
  /**
    / Get a single conversation
  */
  getConversation(request: MessagingGetConversationRequest, metadata: grpc.Metadata, callback: (err: any|null, response: MessagingGetConversationResponse, metadata: grpc.Metadata) => void): void;

  getConversation(request: MessagingGetConversationRequest, arg1?: grpc.Metadata|((err: any|null, response: MessagingGetConversationResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: MessagingGetConversationResponse, metadata: grpc.Metadata) => void): Promise<MessagingGetConversationResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<MessagingGetConversationResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__Messaging.GetConversation, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  /**
    / Get all conversations for authenticated user
  */
  getConversations(request: MessagingGetConversationsRequest): {cancel():void;close():void}&Observable<MessagingGetConversationsResponse>;
  /**
    / Get all conversations for authenticated user
  */
  getConversations(request: MessagingGetConversationsRequest, metadata: grpc.Metadata): {cancel():void;close():void}&Observable<MessagingGetConversationsResponse>;
  /**
    / Get all conversations for authenticated user
  */
  getConversations(request: MessagingGetConversationsRequest, onMessage: (message?: MessagingGetConversationsResponse) => void,onError?: (err: any) => void,onEnd?: (code: number, msg: string|undefined, metadata: grpc.Metadata) => void): void;
  /**
    / Get all conversations for authenticated user
  */
  getConversations(request: MessagingGetConversationsRequest, metadata: grpc.Metadata, onMessage: (message?: MessagingGetConversationsResponse) => void,onError?: (err: any) => void,onEnd?: (code: number, msg: string|undefined, metadata: grpc.Metadata) => void): void;

  getConversations(request: MessagingGetConversationsRequest, arg1?: grpc.Metadata|((message?: MessagingGetConversationsResponse) => void), arg2?: ((message?: MessagingGetConversationsResponse) => void)|((err: any) => void), arg3?: ((err: any) => void)|((code: number, msg: string|undefined, metadata: grpc.Metadata) => void), arg4?: (code: number, msg: string|undefined, metadata: grpc.Metadata) => void): {cancel():void;close():void}&Observable<MessagingGetConversationsResponse>|void {
    let ret: any;
    let metadata: any;

    let onMessage: any;
    let onError: any;
    let onEnd: any;
    if(typeof arg1 === 'function') {
      onMessage = arg1;
      onError = arg2;
      onEnd = arg3;
    } else if(typeof arg2 === 'function') {
      metadata = arg1;
      onMessage = arg2;
      onError = arg3;
      onEnd = arg4;
    } else {
      metadata = arg1;
    }

    if(!onMessage) {
      let subject = new Subject<MessagingGetConversationsResponse>();
      ret = subject.asObservable();

      onMessage = (response: any) => {
        subject.next(response);
      };

      onError = (err: any) => {
        subject.error(err);
      };

      onEnd = (code: any, msg: any, metadata: any) => {
        subject.complete();
      };

    } else {
      if(!onError) {
        onError = (err: any) => console.error(err);
      }

      if(!onEnd) {
        onEnd = (code: any, msg: any, metadata: any) => {};
      }
    }

    let req = grpc.invoke(__Messaging.GetConversations, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onMessage: response => this._ngZone.run(() => {
        onMessage(response);
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code == grpc.Code.OK) {
          onEnd(code, msg, metadata);
        } else {
          let err = new Error(code + ' ' + (msg||''));
          (<any>err).code = code;
          onError(err);
        }
      })
    });

    (<any>ret).close = () => {
      console.warn('[Angular Grpc] .close() is deprecated use .cancel() instead');
      return req.close();
    };
    (<any>ret).cancel = () => req.close();
    return ret;
  }

  /**
    / Get all conversations for a minga, if user has permissions
  */
  getAllConversations(request: MessagingGetConversationRequest): {cancel():void;close():void}&Observable<MessagingGetConversationsResponse>;
  /**
    / Get all conversations for a minga, if user has permissions
  */
  getAllConversations(request: MessagingGetConversationRequest, metadata: grpc.Metadata): {cancel():void;close():void}&Observable<MessagingGetConversationsResponse>;
  /**
    / Get all conversations for a minga, if user has permissions
  */
  getAllConversations(request: MessagingGetConversationRequest, onMessage: (message?: MessagingGetConversationsResponse) => void,onError?: (err: any) => void,onEnd?: (code: number, msg: string|undefined, metadata: grpc.Metadata) => void): void;
  /**
    / Get all conversations for a minga, if user has permissions
  */
  getAllConversations(request: MessagingGetConversationRequest, metadata: grpc.Metadata, onMessage: (message?: MessagingGetConversationsResponse) => void,onError?: (err: any) => void,onEnd?: (code: number, msg: string|undefined, metadata: grpc.Metadata) => void): void;

  getAllConversations(request: MessagingGetConversationRequest, arg1?: grpc.Metadata|((message?: MessagingGetConversationsResponse) => void), arg2?: ((message?: MessagingGetConversationsResponse) => void)|((err: any) => void), arg3?: ((err: any) => void)|((code: number, msg: string|undefined, metadata: grpc.Metadata) => void), arg4?: (code: number, msg: string|undefined, metadata: grpc.Metadata) => void): {cancel():void;close():void}&Observable<MessagingGetConversationsResponse>|void {
    let ret: any;
    let metadata: any;

    let onMessage: any;
    let onError: any;
    let onEnd: any;
    if(typeof arg1 === 'function') {
      onMessage = arg1;
      onError = arg2;
      onEnd = arg3;
    } else if(typeof arg2 === 'function') {
      metadata = arg1;
      onMessage = arg2;
      onError = arg3;
      onEnd = arg4;
    } else {
      metadata = arg1;
    }

    if(!onMessage) {
      let subject = new Subject<MessagingGetConversationsResponse>();
      ret = subject.asObservable();

      onMessage = (response: any) => {
        subject.next(response);
      };

      onError = (err: any) => {
        subject.error(err);
      };

      onEnd = (code: any, msg: any, metadata: any) => {
        subject.complete();
      };

    } else {
      if(!onError) {
        onError = (err: any) => console.error(err);
      }

      if(!onEnd) {
        onEnd = (code: any, msg: any, metadata: any) => {};
      }
    }

    let req = grpc.invoke(__Messaging.GetAllConversations, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onMessage: response => this._ngZone.run(() => {
        onMessage(response);
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code == grpc.Code.OK) {
          onEnd(code, msg, metadata);
        } else {
          let err = new Error(code + ' ' + (msg||''));
          (<any>err).code = code;
          onError(err);
        }
      })
    });

    (<any>ret).close = () => {
      console.warn('[Angular Grpc] .close() is deprecated use .cancel() instead');
      return req.close();
    };
    (<any>ret).cancel = () => req.close();
    return ret;
  }

  /**
    / Get all messages for conversation
  */
  getMessages(request: MessagingGetMessagesRequest): {cancel():void;close():void}&Observable<MessagingGetMessagesResponse>;
  /**
    / Get all messages for conversation
  */
  getMessages(request: MessagingGetMessagesRequest, metadata: grpc.Metadata): {cancel():void;close():void}&Observable<MessagingGetMessagesResponse>;
  /**
    / Get all messages for conversation
  */
  getMessages(request: MessagingGetMessagesRequest, onMessage: (message?: MessagingGetMessagesResponse) => void,onError?: (err: any) => void,onEnd?: (code: number, msg: string|undefined, metadata: grpc.Metadata) => void): void;
  /**
    / Get all messages for conversation
  */
  getMessages(request: MessagingGetMessagesRequest, metadata: grpc.Metadata, onMessage: (message?: MessagingGetMessagesResponse) => void,onError?: (err: any) => void,onEnd?: (code: number, msg: string|undefined, metadata: grpc.Metadata) => void): void;

  getMessages(request: MessagingGetMessagesRequest, arg1?: grpc.Metadata|((message?: MessagingGetMessagesResponse) => void), arg2?: ((message?: MessagingGetMessagesResponse) => void)|((err: any) => void), arg3?: ((err: any) => void)|((code: number, msg: string|undefined, metadata: grpc.Metadata) => void), arg4?: (code: number, msg: string|undefined, metadata: grpc.Metadata) => void): {cancel():void;close():void}&Observable<MessagingGetMessagesResponse>|void {
    let ret: any;
    let metadata: any;

    let onMessage: any;
    let onError: any;
    let onEnd: any;
    if(typeof arg1 === 'function') {
      onMessage = arg1;
      onError = arg2;
      onEnd = arg3;
    } else if(typeof arg2 === 'function') {
      metadata = arg1;
      onMessage = arg2;
      onError = arg3;
      onEnd = arg4;
    } else {
      metadata = arg1;
    }

    if(!onMessage) {
      let subject = new Subject<MessagingGetMessagesResponse>();
      ret = subject.asObservable();

      onMessage = (response: any) => {
        subject.next(response);
      };

      onError = (err: any) => {
        subject.error(err);
      };

      onEnd = (code: any, msg: any, metadata: any) => {
        subject.complete();
      };

    } else {
      if(!onError) {
        onError = (err: any) => console.error(err);
      }

      if(!onEnd) {
        onEnd = (code: any, msg: any, metadata: any) => {};
      }
    }

    let req = grpc.invoke(__Messaging.GetMessages, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onMessage: response => this._ngZone.run(() => {
        onMessage(response);
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code == grpc.Code.OK) {
          onEnd(code, msg, metadata);
        } else {
          let err = new Error(code + ' ' + (msg||''));
          (<any>err).code = code;
          onError(err);
        }
      })
    });

    (<any>ret).close = () => {
      console.warn('[Angular Grpc] .close() is deprecated use .cancel() instead');
      return req.close();
    };
    (<any>ret).cancel = () => req.close();
    return ret;
  }

  /**
    / Check if there is any unread conversations, for improved load at root.
  */
  checkForAnyUnread(request: MessagingCheckUnreadRequest): Promise<MessagingCheckUnreadResponse>;
  /**
    / Check if there is any unread conversations, for improved load at root.
  */
  checkForAnyUnread(request: MessagingCheckUnreadRequest, metadata: grpc.Metadata): Promise<MessagingCheckUnreadResponse>;
  /**
    / Check if there is any unread conversations, for improved load at root.
  */
  checkForAnyUnread(request: MessagingCheckUnreadRequest, callback: (err: any|null, response: MessagingCheckUnreadResponse, metadata: grpc.Metadata) => void): void;
  /**
    / Check if there is any unread conversations, for improved load at root.
  */
  checkForAnyUnread(request: MessagingCheckUnreadRequest, metadata: grpc.Metadata, callback: (err: any|null, response: MessagingCheckUnreadResponse, metadata: grpc.Metadata) => void): void;

  checkForAnyUnread(request: MessagingCheckUnreadRequest, arg1?: grpc.Metadata|((err: any|null, response: MessagingCheckUnreadResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: MessagingCheckUnreadResponse, metadata: grpc.Metadata) => void): Promise<MessagingCheckUnreadResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<MessagingCheckUnreadResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__Messaging.CheckForAnyUnread, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  /**
    / Stream existing conversations the currently authenticated user has
  */
  streamConversations(request: StreamID): {cancel():void;close():void}&Observable<MessagingStreamConversationsResponse>;
  /**
    / Stream existing conversations the currently authenticated user has
  */
  streamConversations(request: StreamID, metadata: grpc.Metadata): {cancel():void;close():void}&Observable<MessagingStreamConversationsResponse>;
  /**
    / Stream existing conversations the currently authenticated user has
  */
  streamConversations(request: StreamID, onMessage: (message?: MessagingStreamConversationsResponse) => void,onError?: (err: any) => void,onEnd?: (code: number, msg: string|undefined, metadata: grpc.Metadata) => void): void;
  /**
    / Stream existing conversations the currently authenticated user has
  */
  streamConversations(request: StreamID, metadata: grpc.Metadata, onMessage: (message?: MessagingStreamConversationsResponse) => void,onError?: (err: any) => void,onEnd?: (code: number, msg: string|undefined, metadata: grpc.Metadata) => void): void;

  streamConversations(request: StreamID, arg1?: grpc.Metadata|((message?: MessagingStreamConversationsResponse) => void), arg2?: ((message?: MessagingStreamConversationsResponse) => void)|((err: any) => void), arg3?: ((err: any) => void)|((code: number, msg: string|undefined, metadata: grpc.Metadata) => void), arg4?: (code: number, msg: string|undefined, metadata: grpc.Metadata) => void): {cancel():void;close():void}&Observable<MessagingStreamConversationsResponse>|void {
    let ret: any;
    let metadata: any;

    let onMessage: any;
    let onError: any;
    let onEnd: any;
    if(typeof arg1 === 'function') {
      onMessage = arg1;
      onError = arg2;
      onEnd = arg3;
    } else if(typeof arg2 === 'function') {
      metadata = arg1;
      onMessage = arg2;
      onError = arg3;
      onEnd = arg4;
    } else {
      metadata = arg1;
    }

    if(!onMessage) {
      let subject = new Subject<MessagingStreamConversationsResponse>();
      ret = subject.asObservable();

      onMessage = (response: any) => {
        subject.next(response);
      };

      onError = (err: any) => {
        subject.error(err);
      };

      onEnd = (code: any, msg: any, metadata: any) => {
        subject.complete();
      };

    } else {
      if(!onError) {
        onError = (err: any) => console.error(err);
      }

      if(!onEnd) {
        onEnd = (code: any, msg: any, metadata: any) => {};
      }
    }

    let req = grpc.invoke(__Messaging.StreamConversations, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onMessage: response => this._ngZone.run(() => {
        onMessage(response);
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code == grpc.Code.OK) {
          onEnd(code, msg, metadata);
        } else {
          let err = new Error(code + ' ' + (msg||''));
          (<any>err).code = code;
          onError(err);
        }
      })
    });

    (<any>ret).close = () => {
      console.warn('[Angular Grpc] .close() is deprecated use .cancel() instead');
      return req.close();
    };
    (<any>ret).cancel = () => req.close();
    return ret;
  }

  streamConversationsControl(request: StreamControl): Promise<StreamControlResponse>;
  streamConversationsControl(request: StreamControl, metadata: grpc.Metadata): Promise<StreamControlResponse>;
  streamConversationsControl(request: StreamControl, callback: (err: any|null, response: StreamControlResponse, metadata: grpc.Metadata) => void): void;
  streamConversationsControl(request: StreamControl, metadata: grpc.Metadata, callback: (err: any|null, response: StreamControlResponse, metadata: grpc.Metadata) => void): void;

  streamConversationsControl(request: StreamControl, arg1?: grpc.Metadata|((err: any|null, response: StreamControlResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: StreamControlResponse, metadata: grpc.Metadata) => void): Promise<StreamControlResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<StreamControlResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__Messaging.StreamConversationsControl, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

}

