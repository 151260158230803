import { Directive, HostListener, Input } from '@angular/core';

import { ContentEvents } from '@app/src/app/minimal/services/ContentEvents';

import {
  IResolvedContentLinkValue,
  resolveContentLinkValue,
} from './contentLinkUtil';

@Directive({
  selector: '[mgContentContextMenu]',
  exportAs: 'mgContentContextMenu',
})
export class MgContentContextMenuDirective {
  private _value = IResolvedContentLinkValue.defaultValue();

  @Input()
  set mgContentContextMenu(value: any) {
    this._value = resolveContentLinkValue(value);
  }

  constructor(private contentEvents: ContentEvents) {}

  /**
   * Check to see if we have a valid content link. Does not mean content exists
   * it just means opening the content will be attempted.
   */
  isValidContentContextLink(): boolean {
    return !!this._value.context;
  }

  @HostListener('contextmenu', ['$event'])
  _handleContentLinkContextMenu(ev: any) {
    if (!this.isValidContentContextLink()) {
      return;
    }

    ev.preventDefault();
    ev.stopPropagation();
    ev.stopImmediatePropagation();

    // hammerjs bug, stop propagation does nothing to stop hammerjs events..
    const cancelClass = 'mg-cancel-content-link';
    const evTarget = ev.target;
    evTarget.classList.add(cancelClass);

    const shortCardParent = ev.target.closest('mg-any-short-card');
    let addedToParent = false;
    if (shortCardParent && !shortCardParent.classList.contains(cancelClass)) {
      shortCardParent.classList.add(cancelClass);
      addedToParent = true;
    }

    const { pageX, pageY } = ev;

    this.contentEvents.emitContextMenuOpen({
      contentContextHash: this._value.context,
      contentHash: this._value.contentHash || '',
      x: pageX,
      y: pageY,
    });

    // restore after giving mgContentLink hammerjs tap event handler a moment
    setTimeout(() => {
      evTarget.classList.remove(cancelClass);
      if (addedToParent) {
        shortCardParent.classList.remove(cancelClass);
      }
    }, 1000);
  }
}
