import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { MgIconModule } from '@app/src/app/icon';

import { CarouselItemComponent } from './carousel-item/carousel-item.component';
import {
  CarouselDefDirective,
  CarouselItemDirective,
  CarouselOutletDirective,
} from './carousel.directive';
import { CarouselComponent } from './carousel/carousel.component';

@NgModule({
  imports: [CommonModule, MgIconModule],
  exports: [
    CarouselComponent,
    CarouselItemComponent,
    CarouselItemDirective,
    CarouselDefDirective,
    CarouselOutletDirective,
  ],
  declarations: [
    CarouselComponent,
    CarouselItemComponent,
    CarouselItemDirective,
    CarouselDefDirective,
    CarouselOutletDirective,
  ],
})

// Basically a rip of this https://github.com/uiuniversal/ngu-carousel with some small mods
export class CarouselModule {}
