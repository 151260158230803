import * as day from 'dayjs';
import { HallPassStatusEnum } from 'libs/domain';
import { hall_pass_pb } from 'libs/generated-grpc-web';
import { dateTimeObjectToDateObject } from 'libs/shared-grpc';

import { HPM_DASHBOARD_STATUS_PRIORITY_MAP as priorityMap } from '../constants/hpm-dashboard-styling.constants';
import {
  HpmDashboardFilters,
  HpmDashboardPassStatus,
  HpmDashboardTableItem,
} from '../types';

export enum HpmDashboardMessages {
  BUTTON_LABEL_ASSIGN_HALLPASS = 'Assign hall pass',
  BUTTON_LABEL_REFRESH = 'Refresh dashboard',
  BUTTON_LABEL_EXPORT = 'Export list',

  SELECT_LABEL_STATUS = 'Status',
  SELECT_LABEL_TYPE = 'Pass Type',
  SELECT_OPTION_ALL = 'All',

  ACTIVE_PASSES_TITLE = 'Total passes:',

  ALL_PASSES = 'All passes',
  MY_PASSES = 'My passes',

  ACTIVE = 'Active',
  OVERDUE = 'Overdue',
  ENDED = 'Ended',
  SCHEDULED = 'Scheduled',

  BUTTON_LABEL_CLEAR_FILTERS = 'Clear filters',
  BUTTON_LABEL_EXPAND_FILTERS = 'View all filters',
  BUTTON_LABEL_COLLAPSE_HIDE_FITLERS = 'Hide filters',
  BUTTON_LABEL_COLLAPSE_SHOW_FITLERS = 'Show filters',

  TOGGLE_AUTO_REFRESH = 'Auto refresh',

  HALLPASSES_DISABLED_ALERT = 'Hall passes have been disabled',
}

export enum HpmDashboardTableMessages {
  COLUMN_LABEL_INDICATOR = '',
  COLUMN_LABEL_RECIPIENT_PICTURE = '',
  COLUMN_LABEL_RECIPIENT_NAME = 'Name',
  COLUMN_LABEL_AUTHOR_NAME = 'Approved by',
  COLUMN_LABEL_ENDED_BY = 'Ended by',
  COLUMN_LABEL_START_TIME = 'Start time',
  COLUMN_LABEL_TIMER = 'Timer',
  COLUMN_LABEL_STATUS = 'Status',
  COLUMN_LABEL_ACTIONS = '',
  COLUMN_LABEL_END_TIME = 'End time',
  COLUMN_LABEL_PASS_TYPE = 'Pass',
  COLUMN_LABEL_NOTES = 'Note',
  COLUMN_LABEL_END_AUTOMATIC = 'Automatic',
  COLUMN_LABEL_CURRENT_CLASS = 'Current class',

  BUTTON_START = 'Start',
  BUTTON_END = 'End',
  BUTTON_CANCEL = 'Cancel',
  BUTTON_APPROVE = 'Approve',
  BUTTON_DENY = 'Deny',

  EMPTY_STATE_TITLE = 'No hall passes found',
  EMPTY_STATE_SUBTITLE = 'Try changing your search or filtering and try again.',
}

export enum HpmActionMessages {
  START_SUCCESS = 'Hall pass started successfully',
  START_ERROR = 'Failed to start hall pass',
  CANCEL_CONFIRM_TITLE = 'Cancel Pass',
  CANCEL_CONFIRM_DESC = 'Are you sure you want to cancel this hall pass?',
  CANCEL_CONFIRM_BTN = 'Cancel Pass',
  CANCEL_SUCCESS = 'Hall pass cancelled successfully',
  CANCEL_ERROR = 'Failed to cancel Hall Pass',
  END_SUCCESS = 'Hall pass ended successfully',
  END_ERROR = 'Failed to end hall pass',
  APPROVE_CONFIRM_TITLE = 'Approve Pass',
  APPROVE_CONFIRM_DESC = 'Are you sure you want to approve this hall pass?',
  APPROVE_CONFIRM_BTN = 'Approve',
  APPROVE_SUCCESSS = 'Hall pass successfully approved',
  APPROVE_ERROR = 'Failed to approve hall pass.',
  PENDING_TITLE = 'Hall pass request',
  DENY_CONFIRM_TITLE = 'Deny Pass',
  DENY_CONFIRM_DESC = 'Are you sure you want to deny this hall pass?',
  DENY_CONFIRN_BTN = 'Deny Pass',
  DENY_SUCCESSS = 'Hall pass denied successfully',
  DENY_ERROR = 'Failed to deny hall pass.',
}

export enum HomDashboardEndPassMessages {
  MODAL_TITLE = 'End Hall Pass',
  BUTTON_TEXT_END_PASS = 'End pass',
}

export enum HpmDashboardStreamFilters {
  PERSON = 'searchUserText',
  TYPE = 'hallPassTypeId',
  ROLE = 'role',
  STATUS = 'status',
}

export enum HpmDashboardTableColumn {
  INDICATOR = 'INDICATOR',
  STATUS = 'status.priority',
  TIMER = 'TIMER',
  RECIPIENT_PICTURE = 'RECIPIENT_PICTURE',
  RECIPIENT_NAME = 'recipientPersonView.displayName',
  AUTHOR_NAME = 'AUTHOR_NAME',
  ENDED_BY_NAME = 'ENDED_BY_NAME',
  START_TIME = 'status.start',
  END_TIME = 'status.end',
  NOTES = 'NOTES',
  PASS_TYPE = 'PASS_TYPE',
  ACTIONS = 'ACTIONS',
  MOBILE = 'MOBILE',
  CURRENT_CLASS = 'CURRENT_CLASS',
}

export enum HpmDashboardFilter {
  AUTHORS = 'authors',
  RECIPIENTS = 'recipients',
  SHOW_MINE = 'show_mine',
  PASS_STATUS = 'pass_status',
  PASS_ID = 'pass_id',
}

export const HPM_DASHBOARD_TABLE_COLUMNS: string[] = [
  HpmDashboardTableColumn.INDICATOR,
  HpmDashboardTableColumn.STATUS,
  HpmDashboardTableColumn.TIMER,
  HpmDashboardTableColumn.RECIPIENT_PICTURE,
  HpmDashboardTableColumn.RECIPIENT_NAME,
  HpmDashboardTableColumn.CURRENT_CLASS,
  HpmDashboardTableColumn.PASS_TYPE,
  HpmDashboardTableColumn.NOTES,
  HpmDashboardTableColumn.START_TIME,
  HpmDashboardTableColumn.END_TIME,
  HpmDashboardTableColumn.AUTHOR_NAME,
  HpmDashboardTableColumn.ENDED_BY_NAME,
  HpmDashboardTableColumn.ACTIONS,
];

export const HPM_DASHBOARD_FILTERS_INITIAL_STATE_ADMIN: HpmDashboardFilters = {
  pass_status: [
    HallPassStatusEnum.ACTIVE,
    HallPassStatusEnum.OVERDUE,
    HallPassStatusEnum.PENDING_APPROVAL,
  ],
  authors: [],
  pass_id: [],
  recipients: [],
  show_mine: false,
};

export const HPM_DASHBOARD_FILTERS_INITIAL_STATE_TEACHER: HpmDashboardFilters =
  {
    pass_status: [
      HallPassStatusEnum.ACTIVE,
      HallPassStatusEnum.OVERDUE,
      HallPassStatusEnum.PENDING_APPROVAL,
    ],
    authors: [],
    pass_id: [],
    recipients: [],
    show_mine: true,
  };

export const HPM_DASHBOARD_STATUS_OPTIONS = [
  HallPassStatusEnum.ACTIVE,
  HallPassStatusEnum.OVERDUE,
  HallPassStatusEnum.SCHEDULED,
  HallPassStatusEnum.ENDED,
  HallPassStatusEnum.PENDING_APPROVAL,
  HallPassStatusEnum.DENIED,
];

export const AUTO_REFRESH_POLL_RATE = 60000;
export const PASS_REQUEST_DEBOUNCE_RATE = 200;

export const AUTO_REFRESH_STATE = 'autoHPRefreshState';

export const makePassStatusDetails = (
  now: day.Dayjs,
  start: Date,
  expire: Date,
  end: Date,
  pendingApproval: boolean,
  denied: Date,
): HpmDashboardPassStatus => {
  const startMoment = day(start);
  const expireMoment = day(expire);
  const endMoment = day(end);

  // teacher approval flow
  if (!denied && pendingApproval) {
    return {
      state: HallPassStatusEnum.PENDING_APPROVAL,
      start: null,
      expire,
      end,
      priority: priorityMap[HallPassStatusEnum.PENDING_APPROVAL],
    };
  }

  let state = HallPassStatusEnum.ACTIVE;

  if (now.isBefore(startMoment, 'minutes')) {
    state = HallPassStatusEnum.SCHEDULED;
  } else if (denied && denied > start) {
    state = HallPassStatusEnum.DENIED;
  } else if (
    now.isSameOrBefore(expireMoment, 'seconds') &&
    !now.isSameOrAfter(endMoment, 'seconds')
  ) {
    state = HallPassStatusEnum.ACTIVE;
  } else if (
    now.isAfter(expireMoment, 'seconds') &&
    now.isBefore(endMoment, 'seconds')
  ) {
    state = HallPassStatusEnum.OVERDUE;
  } else if (now.isSameOrAfter(endMoment, 'seconds')) {
    state = HallPassStatusEnum.ENDED;
  }

  return {
    state,
    start,
    expire,
    end,
    priority: priorityMap[state],
  };
};

export const mapHallPassToDashboardItem = (
  item: hall_pass_pb.HallPassWithType.AsObject,
  now: day.Dayjs,
): HpmDashboardTableItem => {
  const { hallPass, recipientStudentId, recipientStudentIdFilename } = item;
  const start = dateTimeObjectToDateObject(hallPass?.startDateTime);
  const expire = hallPass?.expiredDateTime
    ? dateTimeObjectToDateObject(hallPass?.expiredDateTime)
    : undefined;
  const end = hallPass?.endedTime
    ? dateTimeObjectToDateObject(hallPass?.endedTime)
    : undefined;
  const denied = hallPass?.deniedTime
    ? dateTimeObjectToDateObject(hallPass.deniedTime)
    : undefined;

  const pendingApproval = !hallPass?.approvalDate;
  const status = makePassStatusDetails(
    now,
    start,
    expire,
    end,
    pendingApproval,
    denied,
  );

  return {
    ...hallPass,
    recipientStudentId,
    recipientStudentIdFilename,
    status,
  };
};

export const TABLE_VIEWPORT_WIDTH_BREAKPOINT = 1218;
