import { MingaRoleType } from 'libs/domain';

import { getRole } from '../../';

export type BadeRoleNameToType = (
  roleName: string,
) => MingaRoleType | undefined;

export const badgeRoleNameToMingaRoleType = (
  badgeRoleName: string,
): MingaRoleType | undefined => {
  switch (badgeRoleName) {
    case 'Super Admin':
      return MingaRoleType.SUPERADMIN;
      break;
    case 'District Manager':
      return MingaRoleType.DISTRICT_MANAGER;
      break;
    case 'District Leader':
      return MingaRoleType.DISTRICT_LEADER;
      break;
    case 'School Manager':
      return MingaRoleType.SCHOOL_MANAGER;
      break;
    case 'Owner':
      return MingaRoleType.OWNER;
      break;
    case 'Manager':
      return MingaRoleType.MANAGER;
      break;
    case 'Teacher':
      return MingaRoleType.TEACHER;
      break;
    case 'Staff':
      return MingaRoleType.STAFF;
      break;
    case 'Student Leader':
      return MingaRoleType.STUDENT_LEADER;
      break;
    case 'Student':
      return MingaRoleType.STUDENT;
      break;
    case 'Parent':
      return MingaRoleType.PARENT;
      break;
    case 'Attendee':
      return MingaRoleType.ATTENDEE;
      break;
    case 'Read-Only':
      return MingaRoleType.READ_ONLY;
      break;
    case 'Kiosk':
      return MingaRoleType.KIOSK;
      break;
    default:
      console.log(
        'badgeRoleNameToMingaRoleType unhandled badgeRoleName:',
        badgeRoleName,
      );
      break;
  }
};

export const badgeRoleNameToIconUrl = (badgeRoleName: string): string => {
  const roleType = badgeRoleNameToMingaRoleType(badgeRoleName);

  return roleType ? roleTypeToIconUrl(roleType) : '';
};

export const roleTypeToDefaultProfileUrl = (
  roleType: MingaRoleType,
): string => {
  return `https://cdn.minga.io/assets/${roleType}.svg`;
};
export const roleTypeToIconUrl = (roleType: MingaRoleType): string => {
  const role = getRole(roleType);
  return role.iconUrl;
};
