import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { MingaPermission, MingaRoleType } from 'libs/domain';
import { filter, map, switchMap } from 'rxjs/operators';

import { PermissionsService } from '@app/src/app/permissions';
import { MingaManagerService } from '@app/src/app/services/MingaManager';

import { MmScheduledReportsService } from '@modules/minga-manager/components/mm-scheduled-reports/services';

import { SystemAlertSnackBarService } from '@shared/components/system-alert-snackbar';

import {
  AuthStoreActions,
  authCleared,
  authCustomSsoError,
  authInit,
  authSsoError,
  authSuccess,
  authUpdated,
  loginSuccess,
  setPreAuthRoute,
} from '../actions';
import { selectPreAuthUrl } from '../selectors';

@Injectable()
export class AuthStoreEffects {
  authUpdated$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(authUpdated.type),
        map(() => {}),
      ),
    { dispatch: false },
  );

  authCleared$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(authCleared.type),
        map(() => this.router.navigate(['/landing'])),
      ),
    { dispatch: false },
  );

  preAuthUrlNavigate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authUpdated.type, authInit.type),
      switchMap(() => this.store$.select(selectPreAuthUrl)),
      filter(v => !!v),
      map(v => v as string),
      map(preAuthUrl => this.router.navigateByUrl(preAuthUrl)),
      map(() => setPreAuthRoute({ preAuthUrl: '' })),
    ),
  );

  ssoError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(authSsoError),
        map(action => {
          this._systemAlertSnackBar.error(
            'Oops! We got an error, trying to login via Single Sign On: ' +
              action.ssoError.message,
          );
        }),
      ),
    { dispatch: false },
  );
  customSsoError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(authCustomSsoError),
        map(action => {
          this._systemAlertSnackBar.error(action.customSsoError.message);
        }),
      ),
    { dispatch: false },
  );
  loginSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(loginSuccess),
        map(async action => {
          const { usersMingas, role } = action;
          if (
            usersMingas?.length === 1 ||
            this._permissions.hasPermission(MingaPermission.SUPERADMIN) ||
            role === MingaRoleType.KIOSK
          ) {
            return;
          }

          await this._mingaManagerService.openSwitchMingaModal({
            modalTitle: 'Choose a Minga',
            disableClose: true,
          });
        }),
      ),
    { dispatch: false },
  );
  authSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(authSuccess),
        map(async action => {
          // TODO is there a better place to fire something once a user is logged in?
          this._mmScheduledReportsService.captureDownloadReportAction();
        }),
      ),
    { dispatch: false },
  );

  constructor(
    private actions$: Actions<AuthStoreActions>,
    private store$: Store<any>,
    private router: Router,
    private _systemAlertSnackBar: SystemAlertSnackBarService,
    private _mingaManagerService: MingaManagerService,
    private _permissions: PermissionsService,
    private _mmScheduledReportsService: MmScheduledReportsService,
  ) {}
}
