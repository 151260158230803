import { Injectable } from '@angular/core';

import {
  email_content_pb,
  email_content_ng_grpc_pb,
} from 'libs/generated-grpc-web';
import { BehaviorSubject, Observable } from 'rxjs';

import { IEmailContentSend } from '@app/src/app/content-common/services/EmailContent/types';

@Injectable({ providedIn: 'root' })
export class EmailContentService {
  private fetched: boolean = false;
  private fetching: boolean = false;
  private allowedContentTypes$: BehaviorSubject<string[]>;

  constructor(
    private emailContentProto: email_content_ng_grpc_pb.EmailContent,
  ) {
    this.allowedContentTypes$ = new BehaviorSubject<string[]>([]);
  }

  async send(contentHash: string, options: IEmailContentSend) {
    const req = new email_content_pb.EmailContentSendRequest();
    req.setContentHash(contentHash);
    req.setIncludeUnregistered(options.includeUnregistered);
    req.setRoleTypeList(options.roleTypes);

    await this.emailContentProto.send(req);
  }

  observeAllowedContentTypes(): Observable<string[]> {
    this.ensureConfigFetch();
    return this.allowedContentTypes$.asObservable();
  }

  async getAllowedContentTypes(): Promise<string[]> {
    await this.ensureConfigFetch();
    return this.allowedContentTypes$.getValue();
  }

  invalidateConfig() {
    this.fetched = false;
  }

  // Ensure we are either fetching or make sure we have fetched
  private async ensureConfigFetch() {
    if (!this.fetched && !this.fetching) {
      await this.fetchConfig();
    }
  }

  private async fetchConfig() {
    this.fetching = true;
    const req = new email_content_pb.EmailContentConfigRequest();
    try {
      const resp = await this.emailContentProto.config(req);
      this.allowedContentTypes$.next(resp.getAllowedContentTypeList());
      this.fetched = true;
    } finally {
      this.fetching = false;
    }
  }
}
