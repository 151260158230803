export enum ReportReason {
  CUSTOM = 0,  // deprecated
  LANGUAGE = 1,  // deprecated
  CONTENT = 2,  // deprecated
  SPIRIT = 3,  // deprecated
  BULLYING = 4,  // deprecated
  INAPPROPRIATE = 5,
  ABUSIVE = 6,
  INAPPROPRIATE_IMAGES = 7,
  INAPPROPRIATE_IMAGES_AND_TEXT = 8,
  INAPPROPRIATE_IMAGES_TEXT_ONLY = 9,
}

export namespace ReportReason {
  const reasonStrings =
    [
      'other', 
      'the post contains inappropriate language',
      'the post contains inappropriate content/messages',
      'the content is not in the spirit of Minga (positive)',
      'the content could be considered bullying', 
      'inappropriate', 
      'abusive',
      'inappropriate image(s)',
      'inappropriate image(s) and text',
      'inappropriate image(s) text'
    ]

    const verboseReasonString = [
      'the content was reported as either inappropriate or abusive',
      'the content was reported as inappropriate',
      'the content was reported as abusive',
      'the content contains inappropriate image(s)',
      'the content contains inappropriate image(s) and text',
      'the content contains inappropriate text in the image(s)',
    ];

  /**
   * Returns a reason's human readable string
   */
  export function toString(reportReason: ReportReason): string {
    if(reportReason == ReportReason.CUSTOM) {
      return reasonStrings[reportReason];
    } else if(reportReason < ReportReason.SPIRIT) {
      return reasonStrings[ReportReason.INAPPROPRIATE];
    } else if(reportReason <= ReportReason.BULLYING) {
      return reasonStrings[ReportReason.ABUSIVE];
    } else {
      return reasonStrings[reportReason];
    }
  }

  /**
   * Returns a reason's human readable verbose string
   */
  export function toVerboseString(reportReason: ReportReason): string {
    if(reportReason == ReportReason.CUSTOM) {
      return verboseReasonString[0];
    } else if(reportReason < ReportReason.SPIRIT ||
      reportReason == ReportReason.INAPPROPRIATE) {
      return verboseReasonString[1];
    } else if(reportReason <= ReportReason.BULLYING ||
      reportReason == ReportReason.ABUSIVE) {
      return verboseReasonString[2];
    } else if(reportReason == ReportReason.INAPPROPRIATE_IMAGES) {
      return verboseReasonString[3];
    } else if(reportReason == ReportReason.INAPPROPRIATE_IMAGES_AND_TEXT) {
      return verboseReasonString[4];
    } else if(reportReason == ReportReason.INAPPROPRIATE_IMAGES_TEXT_ONLY) {
      return verboseReasonString[5];
    } else {
      return verboseReasonString[0];
    }
  }

  /**
   * Returns the human readable strings with indexes matching the enum
   * updated April 1, 2019 to only inappropriate and abusive options
   * @param returnCustom deprecated option
   */
  export function getStringArray(returnCustom: boolean = true): string[] {
    return reasonStrings.slice(
      ReportReason.INAPPROPRIATE, ReportReason.INAPPROPRIATE_IMAGES);
  }
}
