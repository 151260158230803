import {
  IHallPassExpiredResult,
  IHallPassStatsExpiredTimeResult,
  IHallPassStatsPeople,
  IHallPassStatsPeopleResult,
  IHallPassSummaryResult,
  IHallPassUserResult,
} from 'libs/domain';
import { hall_pass_stats_pb as hps_pb } from 'libs/generated-grpc-ts';

/**
 * Hall Pass Statistics - Summary Mapper
 */
export namespace HpsSummaryMapper {
  export const toProto = (item: IHallPassSummaryResult): hps_pb.HpsSummary => {
    const msg = new hps_pb.HpsSummary();
    if (item.date) {
      msg.setDate(item.date);
    }
    if (item.typeId) {
      msg.setTypeId(item.typeId);
    }
    if (item.month) {
      msg.setMonth(item.month);
    }
    if (item.hour) {
      msg.setHour(item.hour);
    }
    if (item.week) {
      msg.setWeek(item.week);
    }
    if (item.totalTime) {
      msg.setTotalTime(item.totalTime);
    }
    if (item.totalPasses) {
      msg.setTotalPasses(item.totalPasses);
    }
    if (item.totalPasses) {
      msg.setTotalPasses(item.totalPasses);
    }
    if (item.typeColor) {
      msg.setTypeColor(item.typeColor);
    }
    if (item.typeName) {
      msg.setTypeName(item.typeName);
    }
    if (item.year) {
      msg.setYear(item.year);
    }
    if (item.totalDenied) {
      msg.setTotalDenied(item.totalDenied);
    }
    return msg;
  };
  export const fromProto = (msg: hps_pb.HpsSummary): IHallPassSummaryResult => {
    const typeColor = msg.getTypeColor();
    const typeName = msg.getTypeName();
    const date = msg.getDate() as any;
    const hour = msg.getHour();
    const month = msg.getMonth();
    const totalPasses = msg.getTotalPasses();
    const totalTime = msg.getTotalTime();
    const typeId = msg.getTypeId();
    const week = msg.getWeek();
    const year = msg.getYear();
    const totalDenied = msg.getTotalDenied();
    return {
      typeColor,
      typeName,
      date,
      hour,
      month,
      totalPasses,
      totalTime,
      typeId,
      week,
      year,
      totalDenied,
    };
  };
}

/**
 * Hall Pass Statistics - Person Info Mapper
 *
 */
export namespace HpsPersonInfoMapper {
  export const toProto = (val: IHallPassStatsPeople): hps_pb.HpsPersonInfo => {
    const msg = new hps_pb.HpsPersonInfo();
    if (val.firstName) {
      msg.setFirstName(val.firstName);
    }
    if (val.lastName) msg.setLastName(val.lastName);
    if (val.grade) {
      msg.setGrade(val.grade);
    }
    if (val.id) {
      msg.setId(val.id);
    }
    if (val.role) {
      msg.setRole(val.role);
    }
    if (val.studentId) {
      msg.setStudentId(val.studentId);
    }
    if (val.badgeIconUrl) {
      msg.setBadgeIconUrl(val.badgeIconUrl);
    }
    if (val.personHash) {
      msg.setPersonHash(val.personHash);
    }
    return msg;
  };
  export const fromProto = (
    msg: hps_pb.HpsPersonInfo,
  ): IHallPassStatsPeople => {
    const firstName = msg.getFirstName();
    const lastName = msg.getLastName();
    const grade = msg.getGrade();
    const id = msg.getId();
    const role = msg.getRole();
    const studentId = msg.getStudentId();
    const badgeIconUrl = msg.getBadgeIconUrl();
    const personHash = msg.getPersonHash();
    return {
      firstName,
      lastName,
      grade,
      id,
      role,
      studentId,
      badgeIconUrl,
      personHash,
    };
  };
}

/**
 * Hall Pass Statistics - Person Stats Mapper
 */
export namespace HpsPersonStatsMapper {
  export const toProto = (val: IHallPassUserResult): hps_pb.HpsPersonStats => {
    const msg = new hps_pb.HpsPersonStats();
    if (val.date) {
      msg.setDate(val.date as any);
    }
    if (val.hour) {
      msg.setHour(val.hour);
    }
    if (val.issuedBy) {
      msg.setIssuedby(val.issuedBy.toString());
    }
    if (val.issuedTo) {
      msg.setIssuedto(val.issuedTo.toString());
    }
    if (val.month) {
      msg.setMonth(val.month);
    }
    if (val.totalPasses) {
      msg.setTotalPasses(val.totalPasses);
    }
    if (val.totalTime) {
      msg.setTotalTime(val.totalTime);
    }
    if (val.typeId) {
      msg.setTypeid(val.typeId);
    }
    if (val.week) {
      msg.setWeek(val.week);
    }
    if (val.totalDenied) {
      msg.setTotalDenied(val.totalDenied);
    }
    if (val.totalOverridden) {
      msg.setTotalOverridden(val.totalOverridden);
    }
    return msg;
  };
  export const fromProto = (
    msg: hps_pb.HpsPersonStats,
  ): IHallPassUserResult => {
    const date = msg.getDate() as any;
    const hour = msg.getHour();
    const issuedBy = +msg.getIssuedby();
    const issuedTo = +msg.getIssuedto();
    const month = msg.getMonth();
    const totalPasses = msg.getTotalPasses();
    const totalTime = msg.getTotalTime();
    const typeId = msg.getTypeid();
    const week = msg.getWeek();
    const totalDenied = msg.getTotalDenied();
    const totalOverridden = msg.getTotalOverridden();
    return {
      date,
      hour,
      issuedBy,
      issuedTo,
      month,
      totalPasses,
      totalTime,
      typeId,
      week,
      totalDenied,
      totalOverridden,
    };
  };
}
/**
 * Hall Pass Statistics - Person Mapper
 */
export namespace HpsPersonMapper {
  export const toProto = (
    val: IHallPassStatsPeopleResult,
  ): hps_pb.HpsPerson => {
    const msg = new hps_pb.HpsPerson();
    if (val.person) {
      msg.setInfo(HpsPersonInfoMapper.toProto(val.person));
    }
    if (val.stats) {
      msg.setStats(HpsPersonStatsMapper.toProto(val.stats));
    }
    return msg;
  };
  export const fromProto = (
    msg: hps_pb.HpsPerson,
  ): IHallPassStatsPeopleResult => {
    const person = HpsPersonInfoMapper.fromProto(msg.getInfo());
    const stats = HpsPersonStatsMapper.fromProto(msg.getStats());
    return {
      person,
      stats,
    };
  };
}

export namespace HpsExpiredStatsMapper {
  export const toProto = (
    val: IHallPassExpiredResult,
  ): hps_pb.HpsPersonExpiredStats => {
    const msg = new hps_pb.HpsPersonExpiredStats();
    if (val.noOfExpiredPasses) {
      msg.setNoOfLatePasses(val.noOfExpiredPasses as any);
    }
    if (val.totalExpiredTime) {
      msg.setTotalLateTime(val.totalExpiredTime);
      return msg;
    }
    return msg;
  };

  export const fromProto = (
    val: hps_pb.HpsPersonExpiredStats,
  ): IHallPassExpiredResult => {
    return {
      noOfExpiredPasses: val.getNoOfLatePasses(),
      totalExpiredTime: val.getTotalLateTime(),
    };
  };
}

export namespace HpsExpiredTimeResultMapper {
  export const toProto = (
    val: IHallPassStatsExpiredTimeResult,
  ): hps_pb.HpsPersonExpired => {
    const msg = new hps_pb.HpsPersonExpired();
    if (val.person) {
      msg.setInfo(HpsPersonInfoMapper.toProto(val.person));
    }
    if (val.expiredStats) {
      msg.setExpiredStats(HpsExpiredStatsMapper.toProto(val.expiredStats));
    }
    return msg;
  };

  export const fromProto = (
    msg: hps_pb.HpsPersonExpired,
  ): IHallPassStatsExpiredTimeResult => {
    const person = HpsPersonInfoMapper.fromProto(msg.getInfo());
    const expiredStats = HpsExpiredStatsMapper.fromProto(msg.getExpiredStats());
    return {
      person,
      expiredStats,
    };
  };
}
