import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { MgButtonModule } from '@app/src/app/button';
import { MgIconModule } from '@app/src/app/icon';

import { GenericModule } from '@shared/components/generic';

import { FileInputComponent } from './FileInput.component';

@NgModule({
  imports: [
    // Minga dependencies
    MgIconModule,
    MgButtonModule,
    GenericModule,

    // External dependencies
    CommonModule,
  ],
  declarations: [FileInputComponent],
  exports: [FileInputComponent],
})
export class FileInputModule {}
