import { Injectable, NgZone } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { grpc } from '@improbable-eng/grpc-web';

import {
  AcceptMingaGroupInviteRequest,
  AcceptMingaGroupInviteResponse,
  AcceptMingaGroupMemberRequest,
  AcceptMingaGroupMemberResponse,
  AddMingaGroupAssetsRequest,
  AddMingaGroupMembersRequest,
  AddMingaGroupMembersResponse,
  CancelJoinMingaGroupRequest,
  CancelJoinMingaGroupResponse,
  CreateMingaGroupRequest,
  CreateMingaGroupResponse,
  DeclineMingaGroupInviteRequest,
  DeclineMingaGroupInviteResponse,
  DeclineMingaGroupMemberRequest,
  DeclineMingaGroupMemberResponse,
  DeleteMingaGroupRequest,
  DeleteMingaGroupResponse,
  ExportGroupMembersChunk,
  ExportGroupMembersRequest,
  GetMingaGroupDetailsRequest,
  GetMingaGroupDetailsResponse,
  GetMingaGroupFilesRequest,
  GetMingaGroupsRequest,
  GetMingaGroupsResponse,
  GetPersonGroupsRequest,
  GetPersonGroupsResponse,
  InviteMingaGroupMemberRequest,
  InviteMingaGroupMemberResponse,
  JoinMingaGroupRequest,
  JoinMingaGroupResponse,
  LeaveMingaGroupRequest,
  LeaveMingaGroupResponse,
  MingaGroupFilesResponse,
  RemoveMingaGroupAssetRequest,
  RemoveMingaGroupMemberRequest,
  RemoveMingaGroupMemberResponse,
  UpdateMingaGroupMembersRequest,
  UpdateMingaGroupMembersResponse,
  UpdateMingaGroupRequest,
  UpdateMingaGroupResponse,
} from '../gateway/mingaGroup_pb';

import {
  MingaGroup as __MingaGroup,
} from '../gateway/mingaGroup_pb_service';

@Injectable({providedIn: 'root'})
export class MingaGroup {

  constructor(private _ngZone: NgZone) {
  }

  create(request: CreateMingaGroupRequest): Promise<CreateMingaGroupResponse>;
  create(request: CreateMingaGroupRequest, metadata: grpc.Metadata): Promise<CreateMingaGroupResponse>;
  create(request: CreateMingaGroupRequest, callback: (err: any|null, response: CreateMingaGroupResponse, metadata: grpc.Metadata) => void): void;
  create(request: CreateMingaGroupRequest, metadata: grpc.Metadata, callback: (err: any|null, response: CreateMingaGroupResponse, metadata: grpc.Metadata) => void): void;

  create(request: CreateMingaGroupRequest, arg1?: grpc.Metadata|((err: any|null, response: CreateMingaGroupResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: CreateMingaGroupResponse, metadata: grpc.Metadata) => void): Promise<CreateMingaGroupResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<CreateMingaGroupResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__MingaGroup.Create, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  update(request: UpdateMingaGroupRequest): Promise<UpdateMingaGroupResponse>;
  update(request: UpdateMingaGroupRequest, metadata: grpc.Metadata): Promise<UpdateMingaGroupResponse>;
  update(request: UpdateMingaGroupRequest, callback: (err: any|null, response: UpdateMingaGroupResponse, metadata: grpc.Metadata) => void): void;
  update(request: UpdateMingaGroupRequest, metadata: grpc.Metadata, callback: (err: any|null, response: UpdateMingaGroupResponse, metadata: grpc.Metadata) => void): void;

  update(request: UpdateMingaGroupRequest, arg1?: grpc.Metadata|((err: any|null, response: UpdateMingaGroupResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: UpdateMingaGroupResponse, metadata: grpc.Metadata) => void): Promise<UpdateMingaGroupResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<UpdateMingaGroupResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__MingaGroup.Update, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  getDetails(request: GetMingaGroupDetailsRequest): Promise<GetMingaGroupDetailsResponse>;
  getDetails(request: GetMingaGroupDetailsRequest, metadata: grpc.Metadata): Promise<GetMingaGroupDetailsResponse>;
  getDetails(request: GetMingaGroupDetailsRequest, callback: (err: any|null, response: GetMingaGroupDetailsResponse, metadata: grpc.Metadata) => void): void;
  getDetails(request: GetMingaGroupDetailsRequest, metadata: grpc.Metadata, callback: (err: any|null, response: GetMingaGroupDetailsResponse, metadata: grpc.Metadata) => void): void;

  getDetails(request: GetMingaGroupDetailsRequest, arg1?: grpc.Metadata|((err: any|null, response: GetMingaGroupDetailsResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: GetMingaGroupDetailsResponse, metadata: grpc.Metadata) => void): Promise<GetMingaGroupDetailsResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<GetMingaGroupDetailsResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__MingaGroup.GetDetails, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  getMingaGroups(request: GetMingaGroupsRequest): Promise<GetMingaGroupsResponse>;
  getMingaGroups(request: GetMingaGroupsRequest, metadata: grpc.Metadata): Promise<GetMingaGroupsResponse>;
  getMingaGroups(request: GetMingaGroupsRequest, callback: (err: any|null, response: GetMingaGroupsResponse, metadata: grpc.Metadata) => void): void;
  getMingaGroups(request: GetMingaGroupsRequest, metadata: grpc.Metadata, callback: (err: any|null, response: GetMingaGroupsResponse, metadata: grpc.Metadata) => void): void;

  getMingaGroups(request: GetMingaGroupsRequest, arg1?: grpc.Metadata|((err: any|null, response: GetMingaGroupsResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: GetMingaGroupsResponse, metadata: grpc.Metadata) => void): Promise<GetMingaGroupsResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<GetMingaGroupsResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__MingaGroup.GetMingaGroups, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  getPersonGroups(request: GetPersonGroupsRequest): Promise<GetPersonGroupsResponse>;
  getPersonGroups(request: GetPersonGroupsRequest, metadata: grpc.Metadata): Promise<GetPersonGroupsResponse>;
  getPersonGroups(request: GetPersonGroupsRequest, callback: (err: any|null, response: GetPersonGroupsResponse, metadata: grpc.Metadata) => void): void;
  getPersonGroups(request: GetPersonGroupsRequest, metadata: grpc.Metadata, callback: (err: any|null, response: GetPersonGroupsResponse, metadata: grpc.Metadata) => void): void;

  getPersonGroups(request: GetPersonGroupsRequest, arg1?: grpc.Metadata|((err: any|null, response: GetPersonGroupsResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: GetPersonGroupsResponse, metadata: grpc.Metadata) => void): Promise<GetPersonGroupsResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<GetPersonGroupsResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__MingaGroup.GetPersonGroups, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  inviteMember(request: InviteMingaGroupMemberRequest): Promise<InviteMingaGroupMemberResponse>;
  inviteMember(request: InviteMingaGroupMemberRequest, metadata: grpc.Metadata): Promise<InviteMingaGroupMemberResponse>;
  inviteMember(request: InviteMingaGroupMemberRequest, callback: (err: any|null, response: InviteMingaGroupMemberResponse, metadata: grpc.Metadata) => void): void;
  inviteMember(request: InviteMingaGroupMemberRequest, metadata: grpc.Metadata, callback: (err: any|null, response: InviteMingaGroupMemberResponse, metadata: grpc.Metadata) => void): void;

  inviteMember(request: InviteMingaGroupMemberRequest, arg1?: grpc.Metadata|((err: any|null, response: InviteMingaGroupMemberResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: InviteMingaGroupMemberResponse, metadata: grpc.Metadata) => void): Promise<InviteMingaGroupMemberResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<InviteMingaGroupMemberResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__MingaGroup.InviteMember, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  acceptMember(request: AcceptMingaGroupMemberRequest): Promise<AcceptMingaGroupMemberResponse>;
  acceptMember(request: AcceptMingaGroupMemberRequest, metadata: grpc.Metadata): Promise<AcceptMingaGroupMemberResponse>;
  acceptMember(request: AcceptMingaGroupMemberRequest, callback: (err: any|null, response: AcceptMingaGroupMemberResponse, metadata: grpc.Metadata) => void): void;
  acceptMember(request: AcceptMingaGroupMemberRequest, metadata: grpc.Metadata, callback: (err: any|null, response: AcceptMingaGroupMemberResponse, metadata: grpc.Metadata) => void): void;

  acceptMember(request: AcceptMingaGroupMemberRequest, arg1?: grpc.Metadata|((err: any|null, response: AcceptMingaGroupMemberResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: AcceptMingaGroupMemberResponse, metadata: grpc.Metadata) => void): Promise<AcceptMingaGroupMemberResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<AcceptMingaGroupMemberResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__MingaGroup.AcceptMember, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  declineMember(request: DeclineMingaGroupMemberRequest): Promise<DeclineMingaGroupMemberResponse>;
  declineMember(request: DeclineMingaGroupMemberRequest, metadata: grpc.Metadata): Promise<DeclineMingaGroupMemberResponse>;
  declineMember(request: DeclineMingaGroupMemberRequest, callback: (err: any|null, response: DeclineMingaGroupMemberResponse, metadata: grpc.Metadata) => void): void;
  declineMember(request: DeclineMingaGroupMemberRequest, metadata: grpc.Metadata, callback: (err: any|null, response: DeclineMingaGroupMemberResponse, metadata: grpc.Metadata) => void): void;

  declineMember(request: DeclineMingaGroupMemberRequest, arg1?: grpc.Metadata|((err: any|null, response: DeclineMingaGroupMemberResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: DeclineMingaGroupMemberResponse, metadata: grpc.Metadata) => void): Promise<DeclineMingaGroupMemberResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<DeclineMingaGroupMemberResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__MingaGroup.DeclineMember, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  removeMember(request: RemoveMingaGroupMemberRequest): Promise<RemoveMingaGroupMemberResponse>;
  removeMember(request: RemoveMingaGroupMemberRequest, metadata: grpc.Metadata): Promise<RemoveMingaGroupMemberResponse>;
  removeMember(request: RemoveMingaGroupMemberRequest, callback: (err: any|null, response: RemoveMingaGroupMemberResponse, metadata: grpc.Metadata) => void): void;
  removeMember(request: RemoveMingaGroupMemberRequest, metadata: grpc.Metadata, callback: (err: any|null, response: RemoveMingaGroupMemberResponse, metadata: grpc.Metadata) => void): void;

  removeMember(request: RemoveMingaGroupMemberRequest, arg1?: grpc.Metadata|((err: any|null, response: RemoveMingaGroupMemberResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: RemoveMingaGroupMemberResponse, metadata: grpc.Metadata) => void): Promise<RemoveMingaGroupMemberResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<RemoveMingaGroupMemberResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__MingaGroup.RemoveMember, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  /**
    Accept an invite to a group
  */
  acceptInvite(request: AcceptMingaGroupInviteRequest): Promise<AcceptMingaGroupInviteResponse>;
  /**
    Accept an invite to a group
  */
  acceptInvite(request: AcceptMingaGroupInviteRequest, metadata: grpc.Metadata): Promise<AcceptMingaGroupInviteResponse>;
  /**
    Accept an invite to a group
  */
  acceptInvite(request: AcceptMingaGroupInviteRequest, callback: (err: any|null, response: AcceptMingaGroupInviteResponse, metadata: grpc.Metadata) => void): void;
  /**
    Accept an invite to a group
  */
  acceptInvite(request: AcceptMingaGroupInviteRequest, metadata: grpc.Metadata, callback: (err: any|null, response: AcceptMingaGroupInviteResponse, metadata: grpc.Metadata) => void): void;

  acceptInvite(request: AcceptMingaGroupInviteRequest, arg1?: grpc.Metadata|((err: any|null, response: AcceptMingaGroupInviteResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: AcceptMingaGroupInviteResponse, metadata: grpc.Metadata) => void): Promise<AcceptMingaGroupInviteResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<AcceptMingaGroupInviteResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__MingaGroup.AcceptInvite, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  declineInvite(request: DeclineMingaGroupInviteRequest): Promise<DeclineMingaGroupInviteResponse>;
  declineInvite(request: DeclineMingaGroupInviteRequest, metadata: grpc.Metadata): Promise<DeclineMingaGroupInviteResponse>;
  declineInvite(request: DeclineMingaGroupInviteRequest, callback: (err: any|null, response: DeclineMingaGroupInviteResponse, metadata: grpc.Metadata) => void): void;
  declineInvite(request: DeclineMingaGroupInviteRequest, metadata: grpc.Metadata, callback: (err: any|null, response: DeclineMingaGroupInviteResponse, metadata: grpc.Metadata) => void): void;

  declineInvite(request: DeclineMingaGroupInviteRequest, arg1?: grpc.Metadata|((err: any|null, response: DeclineMingaGroupInviteResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: DeclineMingaGroupInviteResponse, metadata: grpc.Metadata) => void): Promise<DeclineMingaGroupInviteResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<DeclineMingaGroupInviteResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__MingaGroup.DeclineInvite, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  /**
    Join a minga group
  */
  join(request: JoinMingaGroupRequest): Promise<JoinMingaGroupResponse>;
  /**
    Join a minga group
  */
  join(request: JoinMingaGroupRequest, metadata: grpc.Metadata): Promise<JoinMingaGroupResponse>;
  /**
    Join a minga group
  */
  join(request: JoinMingaGroupRequest, callback: (err: any|null, response: JoinMingaGroupResponse, metadata: grpc.Metadata) => void): void;
  /**
    Join a minga group
  */
  join(request: JoinMingaGroupRequest, metadata: grpc.Metadata, callback: (err: any|null, response: JoinMingaGroupResponse, metadata: grpc.Metadata) => void): void;

  join(request: JoinMingaGroupRequest, arg1?: grpc.Metadata|((err: any|null, response: JoinMingaGroupResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: JoinMingaGroupResponse, metadata: grpc.Metadata) => void): Promise<JoinMingaGroupResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<JoinMingaGroupResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__MingaGroup.Join, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  leave(request: LeaveMingaGroupRequest): Promise<LeaveMingaGroupResponse>;
  leave(request: LeaveMingaGroupRequest, metadata: grpc.Metadata): Promise<LeaveMingaGroupResponse>;
  leave(request: LeaveMingaGroupRequest, callback: (err: any|null, response: LeaveMingaGroupResponse, metadata: grpc.Metadata) => void): void;
  leave(request: LeaveMingaGroupRequest, metadata: grpc.Metadata, callback: (err: any|null, response: LeaveMingaGroupResponse, metadata: grpc.Metadata) => void): void;

  leave(request: LeaveMingaGroupRequest, arg1?: grpc.Metadata|((err: any|null, response: LeaveMingaGroupResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: LeaveMingaGroupResponse, metadata: grpc.Metadata) => void): Promise<LeaveMingaGroupResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<LeaveMingaGroupResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__MingaGroup.Leave, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  cancelJoin(request: CancelJoinMingaGroupRequest): Promise<CancelJoinMingaGroupResponse>;
  cancelJoin(request: CancelJoinMingaGroupRequest, metadata: grpc.Metadata): Promise<CancelJoinMingaGroupResponse>;
  cancelJoin(request: CancelJoinMingaGroupRequest, callback: (err: any|null, response: CancelJoinMingaGroupResponse, metadata: grpc.Metadata) => void): void;
  cancelJoin(request: CancelJoinMingaGroupRequest, metadata: grpc.Metadata, callback: (err: any|null, response: CancelJoinMingaGroupResponse, metadata: grpc.Metadata) => void): void;

  cancelJoin(request: CancelJoinMingaGroupRequest, arg1?: grpc.Metadata|((err: any|null, response: CancelJoinMingaGroupResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: CancelJoinMingaGroupResponse, metadata: grpc.Metadata) => void): Promise<CancelJoinMingaGroupResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<CancelJoinMingaGroupResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__MingaGroup.CancelJoin, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  deleteGroup(request: DeleteMingaGroupRequest): Promise<DeleteMingaGroupResponse>;
  deleteGroup(request: DeleteMingaGroupRequest, metadata: grpc.Metadata): Promise<DeleteMingaGroupResponse>;
  deleteGroup(request: DeleteMingaGroupRequest, callback: (err: any|null, response: DeleteMingaGroupResponse, metadata: grpc.Metadata) => void): void;
  deleteGroup(request: DeleteMingaGroupRequest, metadata: grpc.Metadata, callback: (err: any|null, response: DeleteMingaGroupResponse, metadata: grpc.Metadata) => void): void;

  deleteGroup(request: DeleteMingaGroupRequest, arg1?: grpc.Metadata|((err: any|null, response: DeleteMingaGroupResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: DeleteMingaGroupResponse, metadata: grpc.Metadata) => void): Promise<DeleteMingaGroupResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<DeleteMingaGroupResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__MingaGroup.DeleteGroup, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  addFileAssets(request: AddMingaGroupAssetsRequest): Promise<MingaGroupFilesResponse>;
  addFileAssets(request: AddMingaGroupAssetsRequest, metadata: grpc.Metadata): Promise<MingaGroupFilesResponse>;
  addFileAssets(request: AddMingaGroupAssetsRequest, callback: (err: any|null, response: MingaGroupFilesResponse, metadata: grpc.Metadata) => void): void;
  addFileAssets(request: AddMingaGroupAssetsRequest, metadata: grpc.Metadata, callback: (err: any|null, response: MingaGroupFilesResponse, metadata: grpc.Metadata) => void): void;

  addFileAssets(request: AddMingaGroupAssetsRequest, arg1?: grpc.Metadata|((err: any|null, response: MingaGroupFilesResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: MingaGroupFilesResponse, metadata: grpc.Metadata) => void): Promise<MingaGroupFilesResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<MingaGroupFilesResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__MingaGroup.AddFileAssets, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  getFiles(request: GetMingaGroupFilesRequest): Promise<MingaGroupFilesResponse>;
  getFiles(request: GetMingaGroupFilesRequest, metadata: grpc.Metadata): Promise<MingaGroupFilesResponse>;
  getFiles(request: GetMingaGroupFilesRequest, callback: (err: any|null, response: MingaGroupFilesResponse, metadata: grpc.Metadata) => void): void;
  getFiles(request: GetMingaGroupFilesRequest, metadata: grpc.Metadata, callback: (err: any|null, response: MingaGroupFilesResponse, metadata: grpc.Metadata) => void): void;

  getFiles(request: GetMingaGroupFilesRequest, arg1?: grpc.Metadata|((err: any|null, response: MingaGroupFilesResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: MingaGroupFilesResponse, metadata: grpc.Metadata) => void): Promise<MingaGroupFilesResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<MingaGroupFilesResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__MingaGroup.GetFiles, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  removeFileAsset(request: RemoveMingaGroupAssetRequest): Promise<MingaGroupFilesResponse>;
  removeFileAsset(request: RemoveMingaGroupAssetRequest, metadata: grpc.Metadata): Promise<MingaGroupFilesResponse>;
  removeFileAsset(request: RemoveMingaGroupAssetRequest, callback: (err: any|null, response: MingaGroupFilesResponse, metadata: grpc.Metadata) => void): void;
  removeFileAsset(request: RemoveMingaGroupAssetRequest, metadata: grpc.Metadata, callback: (err: any|null, response: MingaGroupFilesResponse, metadata: grpc.Metadata) => void): void;

  removeFileAsset(request: RemoveMingaGroupAssetRequest, arg1?: grpc.Metadata|((err: any|null, response: MingaGroupFilesResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: MingaGroupFilesResponse, metadata: grpc.Metadata) => void): Promise<MingaGroupFilesResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<MingaGroupFilesResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__MingaGroup.RemoveFileAsset, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  updateMembers(request: UpdateMingaGroupMembersRequest): Promise<UpdateMingaGroupMembersResponse>;
  updateMembers(request: UpdateMingaGroupMembersRequest, metadata: grpc.Metadata): Promise<UpdateMingaGroupMembersResponse>;
  updateMembers(request: UpdateMingaGroupMembersRequest, callback: (err: any|null, response: UpdateMingaGroupMembersResponse, metadata: grpc.Metadata) => void): void;
  updateMembers(request: UpdateMingaGroupMembersRequest, metadata: grpc.Metadata, callback: (err: any|null, response: UpdateMingaGroupMembersResponse, metadata: grpc.Metadata) => void): void;

  updateMembers(request: UpdateMingaGroupMembersRequest, arg1?: grpc.Metadata|((err: any|null, response: UpdateMingaGroupMembersResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: UpdateMingaGroupMembersResponse, metadata: grpc.Metadata) => void): Promise<UpdateMingaGroupMembersResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<UpdateMingaGroupMembersResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__MingaGroup.UpdateMembers, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  addMembers(request: AddMingaGroupMembersRequest): Promise<AddMingaGroupMembersResponse>;
  addMembers(request: AddMingaGroupMembersRequest, metadata: grpc.Metadata): Promise<AddMingaGroupMembersResponse>;
  addMembers(request: AddMingaGroupMembersRequest, callback: (err: any|null, response: AddMingaGroupMembersResponse, metadata: grpc.Metadata) => void): void;
  addMembers(request: AddMingaGroupMembersRequest, metadata: grpc.Metadata, callback: (err: any|null, response: AddMingaGroupMembersResponse, metadata: grpc.Metadata) => void): void;

  addMembers(request: AddMingaGroupMembersRequest, arg1?: grpc.Metadata|((err: any|null, response: AddMingaGroupMembersResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: AddMingaGroupMembersResponse, metadata: grpc.Metadata) => void): Promise<AddMingaGroupMembersResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<AddMingaGroupMembersResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__MingaGroup.AddMembers, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  exportGroupMembers(request: ExportGroupMembersRequest): {cancel():void;close():void}&Observable<ExportGroupMembersChunk>;
  exportGroupMembers(request: ExportGroupMembersRequest, metadata: grpc.Metadata): {cancel():void;close():void}&Observable<ExportGroupMembersChunk>;
  exportGroupMembers(request: ExportGroupMembersRequest, onMessage: (message?: ExportGroupMembersChunk) => void,onError?: (err: any) => void,onEnd?: (code: number, msg: string|undefined, metadata: grpc.Metadata) => void): void;
  exportGroupMembers(request: ExportGroupMembersRequest, metadata: grpc.Metadata, onMessage: (message?: ExportGroupMembersChunk) => void,onError?: (err: any) => void,onEnd?: (code: number, msg: string|undefined, metadata: grpc.Metadata) => void): void;

  exportGroupMembers(request: ExportGroupMembersRequest, arg1?: grpc.Metadata|((message?: ExportGroupMembersChunk) => void), arg2?: ((message?: ExportGroupMembersChunk) => void)|((err: any) => void), arg3?: ((err: any) => void)|((code: number, msg: string|undefined, metadata: grpc.Metadata) => void), arg4?: (code: number, msg: string|undefined, metadata: grpc.Metadata) => void): {cancel():void;close():void}&Observable<ExportGroupMembersChunk>|void {
    let ret: any;
    let metadata: any;

    let onMessage: any;
    let onError: any;
    let onEnd: any;
    if(typeof arg1 === 'function') {
      onMessage = arg1;
      onError = arg2;
      onEnd = arg3;
    } else if(typeof arg2 === 'function') {
      metadata = arg1;
      onMessage = arg2;
      onError = arg3;
      onEnd = arg4;
    } else {
      metadata = arg1;
    }

    if(!onMessage) {
      let subject = new Subject<ExportGroupMembersChunk>();
      ret = subject.asObservable();

      onMessage = (response: any) => {
        subject.next(response);
      };

      onError = (err: any) => {
        subject.error(err);
      };

      onEnd = (code: any, msg: any, metadata: any) => {
        subject.complete();
      };

    } else {
      if(!onError) {
        onError = (err: any) => console.error(err);
      }

      if(!onEnd) {
        onEnd = (code: any, msg: any, metadata: any) => {};
      }
    }

    let req = grpc.invoke(__MingaGroup.ExportGroupMembers, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onMessage: response => this._ngZone.run(() => {
        onMessage(response);
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code == grpc.Code.OK) {
          onEnd(code, msg, metadata);
        } else {
          let err = new Error(code + ' ' + (msg||''));
          (<any>err).code = code;
          onError(err);
        }
      })
    });

    (<any>ret).close = () => {
      console.warn('[Angular Grpc] .close() is deprecated use .cancel() instead');
      return req.close();
    };
    (<any>ret).cancel = () => req.close();
    return ret;
  }

}

