import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatRippleModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { RouterModule } from '@angular/router';

import { MgImageModule } from '@app/src/app/components/MgImage';
import { MgTextareaModule } from '@app/src/app/components/Textarea';
import { PeopleSearchInputModule } from '@app/src/app/components/people-search-input';
import { MgDateTimePickerModule } from '@app/src/app/date-time-picker';
import { MgIconModule } from '@app/src/app/icon';
import { PermissionsModule } from '@app/src/app/permissions';
import { MgPipesModule } from '@app/src/app/pipes';
import { MgSpinnerModule } from '@app/src/app/spinner';

import { FormModule } from '@shared/components/form';
import { GenericModule } from '@shared/components/generic';
import { OverlayModule } from '@shared/components/modal-overlay';
import { TextModule } from '@shared/components/text/text.module';
import { TilesCarouselModule } from '@shared/components/tiles-carousel';

import { HallPassTimeSelectComponent } from './components/hall-pass-time-select/hall-pass-time-select.component';
import { SaBehaviorComponent } from './components/sa-behavior/sa-behavior.component';
import { SaCheckinComponent } from './components/sa-checkin/sa-checkin.component';
import { SaHallpassComponent } from './components/sa-hallpass/sa-hallpass.component';
import { SaSharedModule } from './components/sa-shared/sa-shared.module';
import { SelectionAssignerTabPipe } from './pipes';
import { SelectionAssignerComponent } from './selection-assigner.component';

@NgModule({
  imports: [
    // Minga dependencies
    OverlayModule,
    TilesCarouselModule,
    TextModule,
    MgIconModule,
    MgImageModule,
    MgPipesModule,
    FormModule,
    PeopleSearchInputModule,
    MgDateTimePickerModule,
    MgTextareaModule,
    MgSpinnerModule,
    GenericModule,
    SaSharedModule,
    PermissionsModule,

    // External dependencies
    CommonModule,
    RouterModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    FormsModule,
    MatRippleModule,
    MatInputModule,
    MatExpansionModule,
    MatChipsModule,
    MatDatepickerModule,
    MatButtonToggleModule,
    MatIconModule,
    MatCheckboxModule,
  ],
  declarations: [
    SelectionAssignerComponent,
    SaHallpassComponent,
    HallPassTimeSelectComponent,
    SaBehaviorComponent,
    SaCheckinComponent,
    SelectionAssignerTabPipe,
  ],
})
export class SelectionAssignerModule {}
