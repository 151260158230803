import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

import * as day from 'dayjs';
import { IPbisType } from 'libs/domain';
import { ReplaySubject } from 'rxjs';

import {
  BEHAVIOR_FIELDS,
  BEHAVIOR_GROUP,
  MyClassMessages,
} from '../../constants/tt-my-class.constants';
import { AssignmentType } from '../../types/tt-my-class.types';

@Component({
  selector: 'mg-tt-behavior-fields',
  templateUrl: './tt-behavior-fields.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TtBehaviorFieldsComponent implements OnInit, OnDestroy {
  @Input() form: UntypedFormGroup;
  @Input() type: IPbisType;

  private _destroyedSubject = new ReplaySubject<void>(1);
  public today = day();
  public group: UntypedFormGroup;
  public BEHAVIOR_FIELDS = BEHAVIOR_FIELDS;
  public MESSAGES = MyClassMessages;

  constructor(private _fb: UntypedFormBuilder) {}

  ngOnInit(): void {
    this._setFormGroup();
  }

  ngOnDestroy(): void {
    this.form.removeControl(AssignmentType.BEHAVIOR);
    this.form.updateValueAndValidity();
    this._destroyedSubject.next();
    this._destroyedSubject.complete();
  }

  private _setFormGroup() {
    this.group = this._fb.group(BEHAVIOR_GROUP());
    this.form.addControl(AssignmentType.BEHAVIOR, this.group);
    this.form.updateValueAndValidity();
  }
}
