import { Injectable } from '@angular/core';

import { IMembershipList, MembershipListType } from 'libs/domain';
import { PersonViewMinimal } from 'libs/generated-grpc-web';
import {
  membership_list_pb,
  student_id_ng_grpc_pb,
  membership_list_ng_grpc_pb,
} from 'libs/generated-grpc-web';
import { MembershipListMapper } from 'libs/shared-grpc';
import { BehaviorSubject, Observable } from 'rxjs';

import { SystemAlertSnackBarService } from '@shared/components/system-alert-snackbar';

export const MembershipListNames = {
  [MembershipListType.TRACK]: 'Track List',
  [MembershipListType.NO_TRACK]: 'No Track List',
  [MembershipListType.NO_ACCESS]: 'No Access List',
  [MembershipListType.ID_MANAGER]: 'ID Managers',
  [MembershipListType.POINTS_MANAGER]: 'Points Managers',
  [MembershipListType.CHECKIN_MANAGER]: 'Check in Managers',
  [MembershipListType.BEHAVIOR_MANAGER]: 'Behavior Managers',
  [MembershipListType.HP_REPORT_VIEWER]: 'Hall Pass Report Viewers',
  [MembershipListType.BEHAVIOR_REPORT_VIEWER]: 'Behavior Report Viewers',
  [MembershipListType.CHECKIN_REPORT_VIEWER]: 'Check in Report Viewers',
  [MembershipListType.POINTS_REPORT_VIEWER]: 'Point Report Viewers',
  [MembershipListType.HALL_PASS_MANAGER]: 'Hall Pass Managers',
  [MembershipListType.FLEX_MANAGER]: 'Flex Managers',
  [MembershipListType.FLEX_REPORT_VIEWER]: 'Flex Report Viewers',
};

@Injectable({ providedIn: 'root' })
export class ListMembershipService {
  private _listId: number | null = null;
  listUpdated$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    private _studentIdManager: student_id_ng_grpc_pb.StudentIdManager,
    private _listManager: membership_list_ng_grpc_pb.MembershipListManager,
    private _systemAlertSnackBar: SystemAlertSnackBarService,
  ) {}

  async getMembershipList(
    listId: number,
    includeMembers?: boolean,
  ): Promise<IMembershipList> {
    const request = new membership_list_pb.GetMembershipListRequest();
    request.setListId(listId);
    if (includeMembers) {
      request.setIncludeMembers(true);
    }

    const response: membership_list_pb.GetMembershipListResponse =
      await this._listManager.getMembershipList(request);
    const list = response.getMembershipList();

    return MembershipListMapper.fromProto(list);
  }

  async getMembershipListByContextHash(
    contextHash: string,
  ): Promise<IMembershipList | undefined> {
    const request = new membership_list_pb.GetMembershipListRequest();
    request.setContextHash(contextHash);

    const response: membership_list_pb.GetMembershipListResponse =
      await this._listManager.getMembershipList(request);
    const list = response.getMembershipList();

    return list !== undefined
      ? MembershipListMapper.fromProto(list)
      : undefined;
  }

  /**
   * Get Membership List By Type
   */
  async getMembershipListByType(
    type: MembershipListType[],
    activeOnly = false,
  ): Promise<IMembershipList[]> {
    const req = new membership_list_pb.GetMembershipListsRequest();
    req.setListTypeList(type);
    req.setActiveOnly(activeOnly);
    const res = await this._listManager.getMembershipLists(req);
    const lists = res.getMembershipListList();
    return lists.map(MembershipListMapper.fromProto);
  }

  /**
   * Gets membership lists or creates them if they DNE
   */
  async getOrCreateMembershipLists(types: MembershipListType[]) {
    let lists = [];
    try {
      lists = await this.getMembershipListByType(types);
    } catch (err) {
      this._systemAlertSnackBar.error(
        'Something went wrong trying to get membership lists! ' + err,
      );
      return;
    }

    try {
      for (const type of types) {
        if (!lists.find(list => list.listType === type)) {
          const list = await this.updateMembershipList({
            name: MembershipListNames[type],
            listType: type,
          });
          lists.push(list);
        }
      }
    } catch (err) {
      this._systemAlertSnackBar.error(
        'Something went wrong trying to create membership lists! ' + err,
      );
    }
    return lists;
  }

  async updateMembershipList(list: IMembershipList): Promise<IMembershipList> {
    const request = new membership_list_pb.UpdateMembershipListRequest();
    const listMsg = MembershipListMapper.toProto(list);
    request.setMembershipList(listMsg);
    const response = await this._listManager.updateMembershipList(request);
    const newList = response.getMembershipList();
    return MembershipListMapper.fromProto(newList);
  }

  async deleteMembershipList(listId: number) {
    const request = new membership_list_pb.DeleteMembershipListRequest();
    request.setListId(listId);
    await this._listManager.deleteMembershipList(request);
  }

  async addMembersToList(
    personHashes: string[],
    listId: number,
    contextHash?: string,
  ) {
    const request = new membership_list_pb.AddMembersToMembershipListRequest();
    request.setListId(listId);
    request.setPersonHashList(personHashes);
    if (contextHash) request.setContextHash(contextHash);

    const response = await this._listManager.addMembersToMembershipList(
      request,
    );
    this.listUpdated$.next(true);

    const validHashes = response.getValidHashesList();
    const invalidHashes = response.getInvalidHashesList();

    return { validHashes, invalidHashes };
  }

  async removeMembersFromList(
    personHashes: string[],
    listId: number,
    contextHash?: string,
  ) {
    const request =
      new membership_list_pb.RemoveMembersToMembershipListRequest();
    request.setListId(listId);
    request.setPersonHashList(personHashes);
    if (contextHash) request.setContextHash(contextHash);
    await this._listManager.removeMembersFromMembershipList(request);
    this.listUpdated$.next(true);
  }

  async removeAllMembersFromList(listId: number) {
    const request = new membership_list_pb.RemoveAllMembersFromListRequest();
    request.setListId(listId);
    await this._listManager.removeAllMembersFromList(request);
    this.listUpdated$.next(true);
  }

  async getMembersOfList(
    listId: number,
  ): Promise<PersonViewMinimal.AsObject[]> {
    const request = new membership_list_pb.GetMembershipListMembersRequest();
    request.setListId(listId);

    const response: membership_list_pb.GetMembershipListMembersResponse =
      await this._listManager.getMembershipListMembers(request);

    const members = response.getPersonsList();

    return members.map(member => member.toObject());
  }

  async getListsByPerson(opts: {
    personHash: string;
    types: MembershipListType[];
  }): Promise<IMembershipList[]> {
    const request = new membership_list_pb.GetMembershipsForPersonRequest();
    request.setPersonHash(opts.personHash);
    request.setListTypesList(opts.types);

    const response: membership_list_pb.GetMembershipsForPersonResponse =
      await this._listManager.getMembershipsForPerson(request);

    const lists = response.getMembershipListList();
    return lists.map(MembershipListMapper.fromProto);
  }

  // save clicked listId for people selector use.
  setActivatedListId(listId: number) {
    this._listId = listId;
  }

  // get clicked listId for people selector use.
  getActivatedListId() {
    return this._listId;
  }

  downloadStickerMembershipList(
    listId: number,
  ): Observable<membership_list_pb.ExportMembershipListChunk> {
    const request = new membership_list_pb.ExportMembershipListRequest();
    request.setListId(listId);

    return this._listManager.exportMembershipList(request);
  }
}
