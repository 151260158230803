import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import * as day from 'dayjs';
import { FlexTimeActivityInstance } from 'libs/domain';
import { BehaviorSubject, combineLatest, ReplaySubject } from 'rxjs';
import { map, startWith, takeUntil } from 'rxjs/operators';

import {
  SystemAlertModalHangTime,
  SystemAlertModalService,
  SystemAlertModalType,
} from '@shared/components/system-alert-modal';
import { defaultFilteringNavigationBehaviors } from '@shared/utils';

import { StMessages } from '../../constants';
import { StudentToolsFlexTimeService } from '../../services';
import { Schedule } from '../../types';

const FILTER_TYPES = ['all', 'registered', 'unregistered'] as const;
type ScheduleFilter = typeof FILTER_TYPES[number];

@Component({
  selector: 'mg-st-flex-time',
  templateUrl: './st-flex-time.component.html',
  styleUrls: ['./st-flex-time.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StFlexTimeComponent implements OnInit, OnDestroy {
  private readonly _destroyedSubject = new ReplaySubject<void>(1);

  public periodsFilter$ = this._route.queryParams.pipe(
    startWith({
      periods: 'all',
    }),
    map(params =>
      FILTER_TYPES.includes(params.periods) ? params.periods : 'all',
    ),
  );

  public schedule$ = combineLatest([
    this.periodsFilter$,
    this._stFlexTimeService.schedule$,
  ]).pipe(
    takeUntil(this._destroyedSubject),
    map(([filter, schedule]) => {
      if (!schedule) return null;

      return this._filterSchedule(filter, schedule);
    }),
  );

  private _registeredPeriodCountSubject = new BehaviorSubject<number>(0);
  public registeredPeriodCount$ =
    this._registeredPeriodCountSubject.asObservable();

  private _unregisteredPeriodCountSubject = new BehaviorSubject<number>(0);
  public unregisteredPeriodCount$ =
    this._unregisteredPeriodCountSubject.asObservable();

  public scheduleIsLoading$ = this._stFlexTimeService.scheduleIsLoading$;

  public MESSAGES = StMessages;

  public personHash = '';

  // Component Constructor

  constructor(
    private _router: Router,
    private _route: ActivatedRoute,
    private _stFlexTimeService: StudentToolsFlexTimeService,
    private _systemAlertModal: SystemAlertModalService,
  ) {}

  // Lifecycle Hooks

  ngOnInit(): void {
    this.personHash = this._route.snapshot.paramMap.get('hash');
    this._fetchSchedule();
  }

  ngOnDestroy(): void {
    this._destroyedSubject.next();
    this._destroyedSubject.complete();
  }

  // Public Methods

  public async activityRegistered(activityInstance: FlexTimeActivityInstance) {
    this._fetchSchedule(false);

    this._systemAlertModal.open({
      modalType: SystemAlertModalType.SUCCESS,
      heading: StMessages.REGISTRATION_SUCCESS_TITLE,
      message: `${StMessages.REGISTRATION_SUCCESS_SUB_MESSAGE} ${activityInstance.flexTimeActivity?.name}`,
      hangTime: SystemAlertModalHangTime.SHORT,
    });
  }

  public filterSchedules(event: any) {
    this._router.navigate([], {
      queryParams: {
        ['periods']: event,
      },
      ...defaultFilteringNavigationBehaviors,
    });
  }

  // Private methods

  private async _fetchSchedule(showLoader = true) {
    const startDate = day();
    const endDate = day().add(1, 'month');
    await this._stFlexTimeService.fetchSchedule({
      startDate,
      endDate,
      showLoader,
      personHash: this.personHash,
    });
  }

  private _filterSchedule(filter: ScheduleFilter, schedule: Schedule) {
    const filteredSchedule = schedule.flexTimePeriodWithRegistration.filter(
      period => {
        if (filter === 'all') return true;
        if (filter === 'registered') return !!period.myRegistration;
        if (filter === 'unregistered') return !period.myRegistration;

        return false; // fallback
      },
    );

    this._registeredPeriodCountSubject.next(
      schedule.flexTimePeriodWithRegistration.filter(
        period => !!period.myRegistration,
      ).length,
    );
    this._unregisteredPeriodCountSubject.next(
      schedule.flexTimePeriodWithRegistration.filter(
        period => !period.myRegistration,
      ).length,
    );

    return {
      ...schedule,
      flexTimePeriodWithRegistration: filteredSchedule,
    };
  }
}
