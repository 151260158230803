import { CdkScrollableModule, ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatRippleModule } from '@angular/material/core';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';

import { MgIconModule } from '@app/src/app/icon';
import { ScrollTargetDirectiveModule } from '@app/src/app/misc/ScrollTarget/directive';
import { NavTabsModule } from '@app/src/app/navigation/components/NavTabs';
import { SideNavTabsModule } from '@app/src/app/navigation/containers/SideNavTabs';
import { MgSpinnerModule } from '@app/src/app/spinner';

import { FormModule } from '../form';
import { GenericModule } from '../generic';
import { ReportTableModule } from '../report-table';
import { TextModule } from '../text';
import {
  ManagerOverlayReportsSubpageComponent,
  ManagerOverlaySubpageComponent,
} from './components';
import { ManagerOverlayComponent } from './manager-overlay.component';

@NgModule({
  imports: [
    // Minga dependencies
    ScrollTargetDirectiveModule,
    SideNavTabsModule,
    MgIconModule,
    MgSpinnerModule,
    NavTabsModule,
    GenericModule,
    TextModule,
    FormModule,
    ReportTableModule,

    // External dependencies
    CommonModule,
    FlexLayoutModule,
    CdkScrollableModule,
    ScrollingModule,
    MatMenuModule,
    MatSelectModule,
    MatSortModule,
    MatTableModule,
    MatRippleModule,
  ],
  declarations: [
    ManagerOverlayComponent,
    ManagerOverlaySubpageComponent,
    ManagerOverlayReportsSubpageComponent,
  ],
  exports: [
    ManagerOverlayComponent,
    ManagerOverlaySubpageComponent,
    ManagerOverlayReportsSubpageComponent,
  ],
})
export class ManagerOverlayModule {}
