import { AbstractControl, UntypedFormGroup } from '@angular/forms';

export class MessageFormGroupController<Message, EditableMessageProperties> {
  private _originalMessage: Message;

  get canSubmit() {
    return this._formGroup.dirty && this._formGroup.valid;
  }

  public readonly controls = this._formGroup.controls as Record<
    EditableMessageProperties & string,
    AbstractControl
  >;
  /** Constructor */
  constructor(private _formGroup: UntypedFormGroup) {}

  public getControl(controlName: EditableMessageProperties): AbstractControl {
    return this._formGroup.get(controlName as any);
  }

  public isValid() {
    for (const controlName in this._formGroup.controls) {
      if (this._formGroup.controls.hasOwnProperty(controlName)) {
        const control = this._formGroup.get(controlName);
        control.markAsDirty();
      }
    }
    this._formGroup.markAllAsTouched();
    this._formGroup.updateValueAndValidity();
    return this._formGroup.valid;
  }

  public setValues(data: Message) {
    this._originalMessage = data;
    for (const property in data) {
      if (data.hasOwnProperty(property) && this._formGroup.controls[property]) {
        const control = this._formGroup.get(property);
        control.setValue(data[property] as any);
      }
    }
    this._formGroup.markAsPristine();
    this._formGroup.markAsUntouched();
  }

  public getPayload() {
    const payload: Message = { ...this._originalMessage };
    for (const controlName in this._formGroup.controls) {
      if (this._formGroup.controls.hasOwnProperty(controlName)) {
        const control = this._formGroup.get(controlName);
        payload[controlName] = control.value;
      }
    }
    return payload;
  }
}
