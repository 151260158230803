import { graph_pb } from 'libs/generated-grpc-ts';

export interface AggregateDataPoint {
  timeValue: string;
  value: number;
}

export const toProto = (graphData: AggregateDataPoint): graph_pb.GraphData => {
  const proto = new graph_pb.GraphData();
  proto.setTimeValue(graphData.timeValue);
  proto.setValue(graphData.value);
  return proto;
};

export const fromProto = (proto: graph_pb.GraphData): AggregateDataPoint => {
  return {
    value: proto.getValue(),
    timeValue: proto.getTimeValue(),
  };
};
