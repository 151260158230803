import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, ignoreElements, map, switchMap } from 'rxjs/operators';

import { SuccessDialog } from '@app/src/app/dialog/SuccessDialog';
import { closeCurrentOverlay } from '@app/src/app/util/overlay';

import { SystemAlertSnackBarService } from '@shared/components/system-alert-snackbar';

import {
  GroupCollectionActions,
  GroupDetailsActions,
  GroupFormActions,
} from '../actions';
import { GroupsService } from '../services';

@Injectable()
export class GroupFormEffects {
  constructor(
    private actions$: Actions<GroupFormActions.TypeUnion>,
    private groupsService: GroupsService,
    private _systemAlertSnackBar: SystemAlertSnackBarService,
    private router: Router,
    private dialog: MatDialog,
    private store: Store<any>,
  ) {}

  updateGroup$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GroupFormActions.TypeEnum.UpdateGroup),
      switchMap((action: GroupFormActions.UpdateGroupAction) => {
        return this.groupsService
          .updateGroup(action.payload.groupHash, action.payload.groupInputs)
          .pipe(
            map(({ group, groupDetails }) => {
              const dialog = this.dialog.open(SuccessDialog, {
                data: { text: `Group Updated!` },
              });
              // the reducer will not remove banner if the group was changed
              // to use bannerObject, and vice versa. So lets add the property to
              // the object so that it will be overwritten with null in the store.
              if (!group.banner) {
                group.banner = null;
              } else if (!group.bannerObject) {
                group.bannerObject = null;
              }
              closeCurrentOverlay(this.router);
              return new GroupFormActions.UpdateGroupSuccess(
                group,
                groupDetails,
              );
            }),
            catchError(err => of(new GroupFormActions.UpdateGroupFailure(err))),
          );
      }),
    ),
  );

  createGroup$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GroupFormActions.TypeEnum.CreateGroup),
      switchMap((action: GroupFormActions.CreateGroupAction) => {
        return this.groupsService.createGroup(action.payload).pipe(
          map(
            ({ group, groupDetails }) =>
              new GroupFormActions.CreateGroupSuccess(group, groupDetails),
          ),
          catchError(err => of(new GroupFormActions.CreateGroupFailure(err))),
        );
      }),
    ),
  );

  createGroupFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(GroupFormActions.TypeEnum.CreateGroupFailure),
        switchMap((action: GroupFormActions.CreateGroupFailure | any): any => {
          // @TODO: not really happy with how this is done.
          if ((action.error as any).action) {
            this.store.dispatch((action.error as any).action);
            return of((action.error as any).action);
          }

          this._systemAlertSnackBar.error('Failed to create group');
          return ignoreElements();
        }),
      ),
    { dispatch: false },
  );

  updateGroupFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(GroupFormActions.TypeEnum.UpdateGroupFailure),
        switchMap((action: GroupFormActions.UpdateGroupFailure | any): any => {
          // @TODO: not really happy with how this is done.
          if ((action.error as any).action) {
            this.store.dispatch((action.error as any).action);
            return of((action.error as any).action);
          } else if ((action.error as any).message) {
            this._systemAlertSnackBar.error((action.error as any).message);
          } else {
            this._systemAlertSnackBar.error('Failed to update group');
          }

          return ignoreElements();
        }),
      ),
    { dispatch: false },
  );

  updateGroupLocally$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GroupFormActions.TypeEnum.UpdateGroupSuccess),
      map(
        (action: GroupFormActions.UpdateGroupSuccess) =>
          new GroupCollectionActions.UpdateGroupLocally(action.group),
      ),
    ),
  );

  updateGroupDetailsLocally$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        GroupFormActions.TypeEnum.UpdateGroupSuccess,
        GroupFormActions.TypeEnum.CreateGroupSuccess,
      ),
      map(
        (
          action:
            | GroupFormActions.UpdateGroupSuccess
            | GroupFormActions.CreateGroupSuccess,
        ) =>
          new GroupDetailsActions.UpdateGroupDetailsLocally(
            action.groupDetails,
          ),
      ),
    ),
  );

  addGroupLocally$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GroupFormActions.TypeEnum.CreateGroupSuccess),
      map((action: GroupFormActions.CreateGroupSuccess) => {
        const dialog = this.dialog.open(SuccessDialog, {
          data: { text: `Group Created Successfully!` },
        });
        closeCurrentOverlay(this.router);
        return new GroupCollectionActions.AddGroupLocally(action.group);
      }),
    ),
  );
}
