// GENERATED CODE -- DO NOT EDIT!

import {grpc} from '@improbable-eng/grpc-web';

import * as gateway_profileContent_pb from '../gateway/profileContent_pb';
import * as common_stream_pb from '../common/stream_pb';
import * as gateway_feed_pb from '../gateway/feed_pb';

export class ProfileContent {
  static readonly serviceName = "minga.profile.ProfileContent";

  static readonly Search = {
    methodName: "Search",
    service: ProfileContent,
    requestStream: false,
    responseStream: true,
    requestType: common_stream_pb.StreamID,
    responseType: gateway_profileContent_pb.SearchProfileContentResponse,
  };

  static readonly SearchControl = {
    methodName: "SearchControl",
    service: ProfileContent,
    requestStream: false,
    responseStream: false,
    requestType: common_stream_pb.StreamControl,
    responseType: common_stream_pb.StreamControlResponse,
  };

}

export class ProfileContentClient {
  search() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  searchControl() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
}
