import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map } from 'rxjs/operators';

import { RolesService } from '@app/src/app/roles/services';
import { TypeEnum, TypeUnion } from '@app/src/app/store/root/rootActions';

@Injectable({ providedIn: 'root' })
export class RolesEffects {
  rolesInvalidate$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(TypeEnum.ChangeMinga, TypeEnum.Logout),
        map(() => this._rolesService.clear()),
      ),
    { dispatch: false },
  );

  constructor(
    private _rolesService: RolesService,
    private _actions$: Actions<TypeUnion>,
  ) {}
}
