/* eslint-disable prefer-arrow/prefer-arrow-functions */

/* eslint-disable @typescript-eslint/no-namespace */
import { IMingaSetting, MingaMinimalModel } from 'libs/domain';
import { SisConfig } from 'libs/domain';
import { RosteringMethod } from 'libs/domain';
import { sisFields } from 'libs/domain';
import { gateway } from 'libs/generated-grpc-ts';
import { MingaSupportTier, stringToMingaTypeEnum } from 'libs/util';
import { $enum } from 'ts-enum-util';

import { MingaFeatureToggleMapper } from '../feature_toggle';
import { SisSettingsMapper } from '../sis';

export namespace MingaMinimalProtoMapper {
  export function mingaMinimalToMingaMinimalProto(
    minga: MingaMinimalModel,
  ): gateway.minga_pb.MingaInfoMinimal {
    const minimal = new gateway.minga_pb.MingaInfoMinimal();
    minimal.setMingaHash(minga.hash);
    minimal.setMingaLogo(minga.logo || '');
    minimal.setMingaName(minga.name);
    minimal.setPaused(minga.paused);
    minimal.setMingaType(minga.mingaType);
    minga.settings?.forEach(row => {
      const setting = new gateway.minga_pb.MingaSetting();
      setting.setKeyName(row.name);
      setting.setValue(JSON.stringify(row.value));
      minimal.addSettings(setting);
    });
    if (minga.featureToggle)
      minimal.setFeatureToggle(
        MingaFeatureToggleMapper.toProto(minga.featureToggle),
      );
    // @TODO: remove sis_type because it's redundant
    if (minga.sisType) minimal.setSisType(minga.sisType);
    if (minga.sisSettings) {
      minimal.setSisSettings(SisSettingsMapper.toProto(minga.sisSettings));
    }

    if (minga.hubspotId) {
      minimal.setHubspotId(minga.hubspotId);
    }

    if (minga.districtId) {
      minimal.setDistrictId(minga.districtId);
    }

    if (minga.mingaSupportTier) {
      minimal.setMingaSupportTier(minga.mingaSupportTier);
    }

    return minimal;
  }

  export function mingaMinimalProtoToMingaMinimal(
    minga: gateway.minga_pb.MingaInfoMinimal,
  ): MingaMinimalModel {
    const mingaType = stringToMingaTypeEnum(minga.getMingaType());
    const settingsList = minga.getSettingsList();
    const featureToggle = minga.getFeatureToggle();
    const hubspotId = minga.getHubspotId();
    const districtId = minga.getDistrictId();
    const settings: IMingaSetting[] = [];
    const mingaSupportTier = (MingaSupportTier as any)[
      minga.getMingaSupportTier()
    ];
    settingsList.forEach(setting => {
      try {
        settings.push({
          name: setting.getKeyName(),
          value: JSON.parse(setting.getValue()) as string,
        });
      } catch (e) {
        console.log(e, setting);
      }
    });

    // @TODO: remove sis_type because it's redundant
    let sisType: SisConfig.SisMethod | undefined;
    if (minga.getSisType()) {
      sisType = $enum(SisConfig.SisType).asValueOrDefault(minga.getSisType());
      if (!sisType) sisType = RosteringMethod.SFTP;
    }

    let sisSettings: sisFields | undefined;
    const sisSettingsProto = minga.getSisSettings();
    if (sisSettingsProto) {
      sisSettings = SisSettingsMapper.fromProto(sisSettingsProto);
    }

    return {
      hash: minga.getMingaHash(),
      logo: minga.getMingaLogo(),
      name: minga.getMingaName(),
      paused: minga.getPaused(),
      mingaType,
      settings,
      featureToggle: featureToggle.toObject(),
      sisType,
      sisSettings,
      hubspotId,
      districtId,
      mingaSupportTier,
    };
  }

  export function MingaProtoToMingaMinimal(
    minga: gateway.minga_pb.Minga,
    settingsList: gateway.minga_pb.MingaSetting[],
    featureToggle?: gateway.minga_pb.MingaFeatureToggle,
  ): MingaMinimalModel {
    const mingaType = stringToMingaTypeEnum(minga.getMingaType());
    const settings: IMingaSetting[] = [];
    settingsList.forEach(setting => {
      settings.push({
        name: setting.getKeyName(),
        value: JSON.parse(setting.getValue()) as IMingaSetting,
      });
    });
    return {
      hash: minga.getHash().getValue(),
      logo: minga.getImageUrl(),
      name: minga.getName(),
      paused: minga.getPaused(),
      mingaType,
      settings,
      featureToggle: featureToggle?.toObject(),
      mingaSupportTier: (MingaSupportTier as any)[minga.getMingaSupportTier()],
    };
  }
}
