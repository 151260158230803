import { day } from 'libs/day';
import { FlexCheckInStatuses } from 'libs/domain';
import { FtmReportFilters } from 'libs/domain';
import { flex_time_stats_pb } from 'libs/generated-grpc-ts';
import { $enum } from 'ts-enum-util';

import { dateObjectToDateTimeMessage, dateTimeMessageToDayjsObject } from '../';

export const toProto = (
  filter: FtmReportFilters,
  limit?: number,
  offset?: number,
  onlyOnePeriod?: boolean,
): flex_time_stats_pb.FlexReportFilters => {
  const filterMessage = new flex_time_stats_pb.FlexReportFilters();
  filterMessage.setOffset(offset || 0);
  if (limit) filterMessage.setLimit(limit);
  if (filter.startDate) {
    filterMessage.setStartDate(
      dateObjectToDateTimeMessage(filter.startDate.toDate()),
    );
  }
  if (filter.endDate) {
    filterMessage.setEndDate(
      dateObjectToDateTimeMessage(filter.endDate.toDate()),
    );
  }
  if (filter.registrant) filterMessage.setRegistrantHashList(filter.registrant);
  if (filter.registerer) filterMessage.setRegistererHashList(filter.registerer);
  if (filter.periods) {
    filterMessage.setPeriodIdList(
      onlyOnePeriod ? filter.periods.slice(0, 1) : filter.periods,
    );
  }
  if (filter.activities) filterMessage.setActivityIdList(filter.activities);
  if (filter.checkinStatus) {
    filterMessage.setCheckinStatus(filter.checkinStatus);
  }
  filterMessage.setUserListList(filter.userList);
  return filterMessage;
};

export const fromProto = (
  proto: flex_time_stats_pb.FlexReportFilters,
): FtmReportFilters & { limit?: number; offset?: number } => {
  const checkinStatusProto = proto.getCheckinStatus();
  let checkinStatus: FlexCheckInStatuses | undefined;
  if (checkinStatusProto) {
    checkinStatus =
      $enum(FlexCheckInStatuses).asValueOrThrow(checkinStatusProto);
  }
  const startDateProto = proto.getStartDate();
  const endDateProto = proto.getEndDate();
  let startDate: day.Dayjs | undefined;
  let endDate: day.Dayjs | undefined;
  if (startDateProto) startDate = dateTimeMessageToDayjsObject(startDateProto);
  if (endDateProto) endDate = dateTimeMessageToDayjsObject(endDateProto);
  return {
    startDate,
    endDate,
    registrant: proto.getRegistrantHashList(),
    registerer: proto.getRegistererHashList(),
    periods: proto.getPeriodIdList(),
    activities: proto.getActivityIdList(),
    checkinStatus,
    limit: proto.getLimit(),
    offset: proto.getOffset(),
    userList: proto.getUserListList(),
  };
};
