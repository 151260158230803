export enum RoleFields {
  DEFAULT = 'DEFAULT',
  GRAD = 'GRAD',
  STUDENT = 'STUDENT',
  GRAD_STUDENT = 'GRAD_STUDENT',
}

export namespace RoleFields {
  export enum Index {
    DEFAULT = 0,
    GRAD = 1,
    STUDENT = 2,
    GRAD_STUDENT = 3,
  }

  export function fromIndex(index: RoleFields.Index) {
    switch(index) {
      default:
        console.warn('RoleFields.fromIndex() unknown index ' + index +
          ' defaulting to DEFAULT');
      case Index.DEFAULT: return RoleFields.DEFAULT;
      case Index.GRAD: return RoleFields.GRAD;
      case Index.STUDENT: return RoleFields.STUDENT;
      case Index.GRAD_STUDENT: return RoleFields.GRAD_STUDENT;
    }
  }

  export function toIndex(format: RoleFields) {
    switch(format) {
      default:
        console.warn('RoleFields.toIndex() unknown format ' + format +
          ' defaulting to DEFAULT');
      case RoleFields.DEFAULT: return Index.DEFAULT;
      case RoleFields.GRAD: return Index.GRAD;
      case RoleFields.STUDENT: return Index.STUDENT;
      case RoleFields.GRAD_STUDENT: return Index.GRAD_STUDENT;
    }
  }
}
