// source: content/post.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

import * as jspb from 'google-protobuf';
import * as content_common_pb from '../content/common_pb';
import * as common_delta_pb from '../common/delta_pb';
import * as designed_svg_designed_svg_pb from '../designed_svg/designed_svg_pb';
export namespace PostInfo {
  export interface AsObject {
    assetList: string[];
    title: string;
    body: string;
    studioContentInfo?: designed_svg_designed_svg_pb.StudioContentInfo.AsObject;
    logoAnchor?: content_common_pb.LogoAnchor;
    customLogoAsset: string;
  }
}
export class PostInfo extends jspb.Message {
  static readonly displayName = "PostInfo";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [1];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, PostInfo.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): PostInfo.AsObject {
    return PostInfo.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!PostInfo} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: PostInfo) {
    var f: any;
    var obj: any = {
      assetList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
      title: jspb.Message.getFieldWithDefault(msg, 2, ""),
      body: jspb.Message.getFieldWithDefault(msg, 3, ""),
      studioContentInfo: (f = msg.getStudioContentInfo()) && designed_svg_designed_svg_pb.StudioContentInfo.toObject(includeInstance, f),
      logoAnchor: jspb.Message.getFieldWithDefault(msg, 5, 0),
      customLogoAsset: jspb.Message.getFieldWithDefault(msg, 6, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!PostInfo}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new PostInfo;
    return PostInfo.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!PostInfo} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!PostInfo}
   */
  static deserializeBinaryFromReader(msg: PostInfo, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.addAsset(value1);
        break;
      case 2:
        var value2 = /** @type {string} */ (reader.readString());
        msg.setTitle(value2);
        break;
      case 3:
        var value3 = /** @type {string} */ (reader.readString());
        msg.setBody(value3);
        break;
      case 4:
        var value4 = new designed_svg_designed_svg_pb.StudioContentInfo;
        reader.readMessage(value4,designed_svg_designed_svg_pb.StudioContentInfo.deserializeBinaryFromReader);
        msg.setStudioContentInfo(value4);
        break;
      case 5:
        var value5 = /** @type {!LogoAnchor} */ (reader.readEnum());
        msg.setLogoAnchor(value5);
        break;
      case 6:
        var value6 = /** @type {string} */ (reader.readString());
        msg.setCustomLogoAsset(value6);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!PostInfo} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: PostInfo, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getAssetList();
    if (f.length > 0) {
      writer.writeRepeatedString(
        1,
        f
      );
    }
    f = message.getTitle();
    if (f.length > 0) {
      writer.writeString(
        2,
        f
      );
    }
    f = message.getBody();
    if (f.length > 0) {
      writer.writeString(
        3,
        f
      );
    }
    f = message.getStudioContentInfo();
    if (f != null) {
      writer.writeMessage(
        4,
        f,
        designed_svg_designed_svg_pb.StudioContentInfo.serializeBinaryToWriter
      );
    }
    f = message.getLogoAnchor();
    if (f !== 0.0) {
      writer.writeEnum(
        5,
        f
      );
    }
    f = message.getCustomLogoAsset();
    if (f.length > 0) {
      writer.writeString(
        6,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    PostInfo.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * repeated string asset = 1;
   * @return {!Array<string>}
   */
  getAssetList(): string[] {
    return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
  };


  /** @param {!Array<string>} value */
  setAssetList(value: string[]) {
    jspb.Message.setField(this, 1, value || []);
  }


  /**
   * @param {string} value
   * @param {number=} opt_index
   */
  addAsset(value: string, opt_index?: number): void {
    jspb.Message.addToRepeatedField(this, 1, value, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearAssetList() {
    this.setAssetList([]);
  }


  /**
   * optional string title = 2;
   * @return {string}
   */
  getTitle(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
  };


  /** @param {string} value */
  setTitle(value: string) {
    jspb.Message.setProto3StringField(this, 2, value);
  }


  /**
   * optional string body = 3;
   * @return {string}
   */
  getBody(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
  };


  /** @param {string} value */
  setBody(value: string) {
    jspb.Message.setProto3StringField(this, 3, value);
  }


  /**
   * optional minga.StudioContentInfo studio_content_info = 4;
   * @return {?StudioContentInfo}
   */
  getStudioContentInfo(): designed_svg_designed_svg_pb.StudioContentInfo {
    return /** @type{?StudioContentInfo} */ (
      jspb.Message.getWrapperField(this, designed_svg_designed_svg_pb.StudioContentInfo, 4));
  }


  /** @param {?StudioContentInfo|undefined} value */
  setStudioContentInfo(value?: designed_svg_designed_svg_pb.StudioContentInfo) {
    jspb.Message.setWrapperField(this, 4, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearStudioContentInfo() {
    this.setStudioContentInfo(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasStudioContentInfo(): boolean {
    return jspb.Message.getField(this, 4) != null;
  }


  /**
   * optional LogoAnchor logo_anchor = 5;
   * @return {!LogoAnchor}
   */
  getLogoAnchor(): content_common_pb.LogoAnchor {
    return /** @type {!LogoAnchor} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
  };


  /** @param {!LogoAnchor} value */
  setLogoAnchor(value: content_common_pb.LogoAnchor) {
    jspb.Message.setProto3EnumField(this, 5, value);
  }


  /**
   * optional string custom_logo_asset = 6;
   * @return {string}
   */
  getCustomLogoAsset(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
  };


  /** @param {string} value */
  setCustomLogoAsset(value: string) {
    jspb.Message.setProto3StringField(this, 6, value);
  }


}
export namespace DeltaPostInfo {
  export interface AsObject {
    contentHash: string;
    assetList?: common_delta_pb.RepeatedStringDelta.AsObject;
    title?: common_delta_pb.StringDelta.AsObject;
    body?: common_delta_pb.StringDelta.AsObject;
    studioContentInfo?: designed_svg_designed_svg_pb.StudioContentInfo.AsObject;
    logoAnchor?: content_common_pb.LogoAnchorDelta.AsObject;
    customLogoAsset?: common_delta_pb.StringDelta.AsObject;
  }
}
export class DeltaPostInfo extends jspb.Message {
  static readonly displayName = "DeltaPostInfo";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): DeltaPostInfo.AsObject {
    return DeltaPostInfo.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!DeltaPostInfo} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: DeltaPostInfo) {
    var f: any;
    var obj: any = {
      contentHash: jspb.Message.getFieldWithDefault(msg, 1, ""),
      assetList: (f = msg.getAssetList()) && common_delta_pb.RepeatedStringDelta.toObject(includeInstance, f),
      title: (f = msg.getTitle()) && common_delta_pb.StringDelta.toObject(includeInstance, f),
      body: (f = msg.getBody()) && common_delta_pb.StringDelta.toObject(includeInstance, f),
      studioContentInfo: (f = msg.getStudioContentInfo()) && designed_svg_designed_svg_pb.StudioContentInfo.toObject(includeInstance, f),
      logoAnchor: (f = msg.getLogoAnchor()) && content_common_pb.LogoAnchorDelta.toObject(includeInstance, f),
      customLogoAsset: (f = msg.getCustomLogoAsset()) && common_delta_pb.StringDelta.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!DeltaPostInfo}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new DeltaPostInfo;
    return DeltaPostInfo.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!DeltaPostInfo} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!DeltaPostInfo}
   */
  static deserializeBinaryFromReader(msg: DeltaPostInfo, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setContentHash(value1);
        break;
      case 2:
        var value2 = new common_delta_pb.RepeatedStringDelta;
        reader.readMessage(value2,common_delta_pb.RepeatedStringDelta.deserializeBinaryFromReader);
        msg.setAssetList(value2);
        break;
      case 3:
        var value3 = new common_delta_pb.StringDelta;
        reader.readMessage(value3,common_delta_pb.StringDelta.deserializeBinaryFromReader);
        msg.setTitle(value3);
        break;
      case 4:
        var value4 = new common_delta_pb.StringDelta;
        reader.readMessage(value4,common_delta_pb.StringDelta.deserializeBinaryFromReader);
        msg.setBody(value4);
        break;
      case 5:
        var value5 = new designed_svg_designed_svg_pb.StudioContentInfo;
        reader.readMessage(value5,designed_svg_designed_svg_pb.StudioContentInfo.deserializeBinaryFromReader);
        msg.setStudioContentInfo(value5);
        break;
      case 6:
        var value6 = new content_common_pb.LogoAnchorDelta;
        reader.readMessage(value6,content_common_pb.LogoAnchorDelta.deserializeBinaryFromReader);
        msg.setLogoAnchor(value6);
        break;
      case 7:
        var value7 = new common_delta_pb.StringDelta;
        reader.readMessage(value7,common_delta_pb.StringDelta.deserializeBinaryFromReader);
        msg.setCustomLogoAsset(value7);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!DeltaPostInfo} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: DeltaPostInfo, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getContentHash();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
    f = message.getAssetList();
    if (f != null) {
      writer.writeMessage(
        2,
        f,
        common_delta_pb.RepeatedStringDelta.serializeBinaryToWriter
      );
    }
    f = message.getTitle();
    if (f != null) {
      writer.writeMessage(
        3,
        f,
        common_delta_pb.StringDelta.serializeBinaryToWriter
      );
    }
    f = message.getBody();
    if (f != null) {
      writer.writeMessage(
        4,
        f,
        common_delta_pb.StringDelta.serializeBinaryToWriter
      );
    }
    f = message.getStudioContentInfo();
    if (f != null) {
      writer.writeMessage(
        5,
        f,
        designed_svg_designed_svg_pb.StudioContentInfo.serializeBinaryToWriter
      );
    }
    f = message.getLogoAnchor();
    if (f != null) {
      writer.writeMessage(
        6,
        f,
        content_common_pb.LogoAnchorDelta.serializeBinaryToWriter
      );
    }
    f = message.getCustomLogoAsset();
    if (f != null) {
      writer.writeMessage(
        7,
        f,
        common_delta_pb.StringDelta.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    DeltaPostInfo.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string content_hash = 1;
   * @return {string}
   */
  getContentHash(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setContentHash(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


  /**
   * optional minga.common.RepeatedStringDelta asset_list = 2;
   * @return {?RepeatedStringDelta}
   */
  getAssetList(): common_delta_pb.RepeatedStringDelta {
    return /** @type{?RepeatedStringDelta} */ (
      jspb.Message.getWrapperField(this, common_delta_pb.RepeatedStringDelta, 2));
  }


  /** @param {?RepeatedStringDelta|undefined} value */
  setAssetList(value?: common_delta_pb.RepeatedStringDelta) {
    jspb.Message.setWrapperField(this, 2, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearAssetList() {
    this.setAssetList(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasAssetList(): boolean {
    return jspb.Message.getField(this, 2) != null;
  }


  /**
   * optional minga.common.StringDelta title = 3;
   * @return {?StringDelta}
   */
  getTitle(): common_delta_pb.StringDelta {
    return /** @type{?StringDelta} */ (
      jspb.Message.getWrapperField(this, common_delta_pb.StringDelta, 3));
  }


  /** @param {?StringDelta|undefined} value */
  setTitle(value?: common_delta_pb.StringDelta) {
    jspb.Message.setWrapperField(this, 3, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearTitle() {
    this.setTitle(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasTitle(): boolean {
    return jspb.Message.getField(this, 3) != null;
  }


  /**
   * optional minga.common.StringDelta body = 4;
   * @return {?StringDelta}
   */
  getBody(): common_delta_pb.StringDelta {
    return /** @type{?StringDelta} */ (
      jspb.Message.getWrapperField(this, common_delta_pb.StringDelta, 4));
  }


  /** @param {?StringDelta|undefined} value */
  setBody(value?: common_delta_pb.StringDelta) {
    jspb.Message.setWrapperField(this, 4, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearBody() {
    this.setBody(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasBody(): boolean {
    return jspb.Message.getField(this, 4) != null;
  }


  /**
   * optional minga.StudioContentInfo studio_content_info = 5;
   * @return {?StudioContentInfo}
   */
  getStudioContentInfo(): designed_svg_designed_svg_pb.StudioContentInfo {
    return /** @type{?StudioContentInfo} */ (
      jspb.Message.getWrapperField(this, designed_svg_designed_svg_pb.StudioContentInfo, 5));
  }


  /** @param {?StudioContentInfo|undefined} value */
  setStudioContentInfo(value?: designed_svg_designed_svg_pb.StudioContentInfo) {
    jspb.Message.setWrapperField(this, 5, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearStudioContentInfo() {
    this.setStudioContentInfo(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasStudioContentInfo(): boolean {
    return jspb.Message.getField(this, 5) != null;
  }


  /**
   * optional LogoAnchorDelta logo_anchor = 6;
   * @return {?LogoAnchorDelta}
   */
  getLogoAnchor(): content_common_pb.LogoAnchorDelta {
    return /** @type{?LogoAnchorDelta} */ (
      jspb.Message.getWrapperField(this, content_common_pb.LogoAnchorDelta, 6));
  }


  /** @param {?LogoAnchorDelta|undefined} value */
  setLogoAnchor(value?: content_common_pb.LogoAnchorDelta) {
    jspb.Message.setWrapperField(this, 6, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearLogoAnchor() {
    this.setLogoAnchor(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasLogoAnchor(): boolean {
    return jspb.Message.getField(this, 6) != null;
  }


  /**
   * optional minga.common.StringDelta custom_logo_asset = 7;
   * @return {?StringDelta}
   */
  getCustomLogoAsset(): common_delta_pb.StringDelta {
    return /** @type{?StringDelta} */ (
      jspb.Message.getWrapperField(this, common_delta_pb.StringDelta, 7));
  }


  /** @param {?StringDelta|undefined} value */
  setCustomLogoAsset(value?: common_delta_pb.StringDelta) {
    jspb.Message.setWrapperField(this, 7, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearCustomLogoAsset() {
    this.setCustomLogoAsset(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasCustomLogoAsset(): boolean {
    return jspb.Message.getField(this, 7) != null;
  }


}
