import { day } from 'libs/day';
import { MingaRoleType } from 'libs/domain';
import { CheckinManagerReportFilters } from 'libs/domain';
import { checkin_pb } from 'libs/generated-grpc-ts';

import { dateObjectToDateTimeMessage, dateTimeMessageToDayjsObject } from '../';

export const toProto = (
  filter: CheckinManagerReportFilters,
  limit?: number,
  offset?: number,
): checkin_pb.CheckInReportFilters => {
  const filterMessage = new checkin_pb.CheckInReportFilters();
  filterMessage.setOffset(offset || 0);
  if (limit) filterMessage.setLimit(limit);
  if (filter.startDate)
    filterMessage.setStartDate(
      dateObjectToDateTimeMessage(filter.startDate.toDate()),
    );
  if (filter.endDate)
    filterMessage.setEndDate(
      dateObjectToDateTimeMessage(filter.endDate.toDate()),
    );
  if (filter.reason) filterMessage.setCheckinReasonIdsList(filter.reason);
  filterMessage.setGradeList(filter.grade);
  if (filter.issuedBy) filterMessage.setIssuedByList(filter.issuedBy);
  if (filter.issuedTo) filterMessage.setIssuedToList(filter.issuedTo);
  if (filter.role) filterMessage.setRolesList(filter.role);
  filterMessage.setBypassLenCheck(true);
  filterMessage.setUserListList(filter.userList);
  return filterMessage;
};

export const fromProto = (
  proto: checkin_pb.CheckInReportFilters,
): CheckinManagerReportFilters & {
  limit?: number;
  offset?: number;
  bypassLenCheck?: boolean;
} => {
  const startDateProto = proto.getStartDate();
  const endDateProto = proto.getEndDate();
  let startDate: day.Dayjs | undefined;
  let endDate: day.Dayjs | undefined;
  if (startDateProto) startDate = dateTimeMessageToDayjsObject(startDateProto);
  if (endDateProto) endDate = dateTimeMessageToDayjsObject(endDateProto);
  return {
    startDate,
    endDate,
    reason: proto.getCheckinReasonIdsList(),
    grade: proto.getGradeList(),
    issuedTo: proto.getIssuedToList(),
    issuedBy: proto.getIssuedByList(),
    role: proto.getRolesList() as MingaRoleType[],
    limit: proto.getLimit(),
    offset: proto.getOffset(),
    bypassLenCheck: proto.getBypassLenCheck(),
    userList: proto.getUserListList(),
  };
};
