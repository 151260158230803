import { IAssetProcessedPaths } from 'libs/domain';
import { IBadge } from 'libs/domain';
import { badge_pb } from 'libs/generated-grpc-ts';
import { image_pb } from 'libs/generated-grpc-ts';

import {
  dateObjectToDateMessage,
  dateObjectToDateTimeMessage,
  dateTimeMessageToDateObject,
} from '../';

export namespace IBadgeProtoMapper {
  /**
   * Map a badge proto message to an IBadge.
   * @param message
   */
  export function fromBadgeMessage(message: badge_pb.Badge): IBadge {
    const image = message.getBanner() || message.getAsset();
    const assetPaths: IAssetProcessedPaths = {};
    if (image) {
      const imageInfo = image.toObject();
      imageInfo.sizeMap.forEach(entry => {
        assetPaths[entry[0]] = entry[1];
      });
    }

    const date = dateTimeMessageToDateObject(message.getDate());

    return {
      id: message.getId(),
      name: message.getName() || '',
      description: message.getDescription() || '',
      image: assetPaths,
      date,
      contentContextHashes: message.getContentContextHashList(),
    };
  }

  /**
   * Map an IBadge to a badge proto message.
   * @param badge
   */
  export function toBadgeMessage(badge: IBadge): badge_pb.Badge {
    const message = new badge_pb.Badge();
    message.setId(badge.id);
    message.setName(badge.name);
    message.setDescription(badge.description);
    message.setDate(dateObjectToDateTimeMessage(badge.date));

    const imageInfo = new image_pb.ImageInfo();
    const sizeMap = imageInfo.getSizeMap();

    for (let sizeKey in badge.image) {
      const imgSize = new image_pb.ImageSize();
      const sizePath = badge.image[sizeKey];
      imgSize.setPath(sizePath.path);
      imgSize.setWidth(sizePath.width);
      imgSize.setHeight(sizePath.height);

      sizeMap.set(sizeKey, imgSize);
    }

    // setting as banner, but could be set as asset if we were wishing to know
    // it was a custom badge image or stock banner
    message.setBanner(imageInfo);

    if (badge.contentContextHashes) {
      message.setContentContextHashList(badge.contentContextHashes);
    }

    return message;
  }
}
