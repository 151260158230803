import { RESTRICTION_ERRORS } from './RestrictionError.constants';
import { RestrictionErrorCode } from './RestrictionError.types';

export class RestrictionError extends Error {
  public readonly type: 'flex' | 'check-in';
  public readonly code: RestrictionErrorCode;
  public readonly person: {
    hash: string;
    name: string;
  };

  constructor(
    type: 'flex' | 'check-in',
    code: RestrictionErrorCode,
    person: {
      hash: string;
      name: string;
    },
  ) {
    const { message } = RESTRICTION_ERRORS[code];
    super(message);
    this.name = 'RestrictionError';
    this.type = type;
    this.code = code;
    this.person = person;
  }
}
