import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { LogoAnchoredModule } from '@app/src/app/components/LogoAnchored';
import { MgShortCardModule } from '@app/src/app/elements/MgShortCard';
import { DesignedSvgViewerModule } from '@app/src/app/minga-designer/components/DesignedSvgViewer';
import { UgcLinksHighlightPipeModule } from '@app/src/app/ugc-links/pipes/UgcLinksHighlight';

import { MgPostShortCardElement } from './MgPostShortCard.element';

@NgModule({
  imports: [
    // Minga dependencies
    MgShortCardModule,
    UgcLinksHighlightPipeModule,
    DesignedSvgViewerModule,
    LogoAnchoredModule,

    // External dependencies
    CommonModule,
  ],
  declarations: [MgPostShortCardElement],
  exports: [MgPostShortCardElement],
})
export class MgPostShortCardModule {}
