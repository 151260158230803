// GENERATED CODE -- DO NOT EDIT!

import {grpc} from '@improbable-eng/grpc-web';

import * as gateway_join_pb from '../gateway/join_pb';
import * as image_image_pb from '../image/image_pb';

export class JoinMinga {
  static readonly serviceName = "minga.JoinMinga";

  static readonly GetPublicJoinMingaInfo = {
    methodName: "GetPublicJoinMingaInfo",
    service: JoinMinga,
    requestStream: false,
    responseStream: false,
    requestType: gateway_join_pb.GetJoinMingaInfoRequest,
    responseType: gateway_join_pb.PublicJoinMingaInfo,
  };

  static readonly GetJoinMingaInfo = {
    methodName: "GetJoinMingaInfo",
    service: JoinMinga,
    requestStream: false,
    responseStream: false,
    requestType: gateway_join_pb.GetJoinMingaInfoRequest,
    responseType: gateway_join_pb.GetJoinMingaInfoResponse,
  };

  static readonly CreateJoinMingaInfo = {
    methodName: "CreateJoinMingaInfo",
    service: JoinMinga,
    requestStream: false,
    responseStream: false,
    requestType: gateway_join_pb.CreateJoinMingaInfoRequest,
    responseType: gateway_join_pb.CreateJoinMingaInfoResponse,
  };

  static readonly ModifyJoinMingaInfo = {
    methodName: "ModifyJoinMingaInfo",
    service: JoinMinga,
    requestStream: false,
    responseStream: false,
    requestType: gateway_join_pb.ModifyJoinMingaInfoRequest,
    responseType: gateway_join_pb.ModifyJoinMingaInfoResponse,
  };

  static readonly DeleteJoinMingaInfo = {
    methodName: "DeleteJoinMingaInfo",
    service: JoinMinga,
    requestStream: false,
    responseStream: false,
    requestType: gateway_join_pb.DeleteJoinMingaInfoRequest,
    responseType: gateway_join_pb.DeleteJoinMingaInfoResponse,
  };

  static readonly ListJoinMingaInfo = {
    methodName: "ListJoinMingaInfo",
    service: JoinMinga,
    requestStream: false,
    responseStream: false,
    requestType: gateway_join_pb.ListJoinMingaInfoRequest,
    responseType: gateway_join_pb.ListJoinMingaInfoResponse,
  };

  static readonly Join = {
    methodName: "Join",
    service: JoinMinga,
    requestStream: false,
    responseStream: false,
    requestType: gateway_join_pb.JoinMingaRequest,
    responseType: gateway_join_pb.JoinMingaResponse,
  };

}

export class JoinMingaClient {
  getPublicJoinMingaInfo() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  getJoinMingaInfo() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  createJoinMingaInfo() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  modifyJoinMingaInfo() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  deleteJoinMingaInfo() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  listJoinMingaInfo() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  join() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
}
