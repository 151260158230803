import { Validators } from '@angular/forms';

import { ICheckinReason } from 'libs/domain';

import * as formUtils from '@shared/components/crud-form-base/crud-form-base.utils';
import { FormConfig } from '@shared/components/crud-form-base/crud-form-base.types';

export enum CheckinManagerReasonsEditMessages {
  MODAL_TITLE_CHECKIN = 'Edit Reason',
  MODAL_TITLE_NEW_CHECKIN = 'Create Reason',

  BUTTON_LABEL_SAVE = 'Save',
  BUTTON_LABEL_DELETE = 'Delete',
  BUTTON_LABEL_CANCEL = 'Cancel',
  BUTTON_LABEL_CREATE = 'Create',
  BUTTON_LABEL_ROLE = 'Role',
  BUTTON_LABEL_STICKERS = 'Stickers',
  BUTTON_LABEL_GROUPS = 'Groups',
  BUTTON_LABEL_GRADES = 'Grades',
  BUTTON_LABEL_REASONS = 'Reasons',
  BUTTON_LABEL_PRINT_QR = 'View QR code',

  LABEL_NAME = 'Name',
  LABEL_CATEGORY = 'Category',
  LABEL_COLOR = 'Color',
  LABEL_ICON = 'Icon',
  LABEL_POINTS = 'Points awarded or subtracted (optional)',
  LABEL_RESTRICTION = 'Limit who can be checked in with this reason',
  LABEL_ABSENTEES = 'Track absentees',
  LABEL_AUTOMATIONS = 'Create automations',
  LABEL_ASSIGN_BEHAVIOR = 'Auto assign behavior',
  LABEL_ASSIGN_HALL_PASS = 'Auto assign hall pass',
  LABEL_AVAILABLE_IN_KIOSK = 'Enable this reason in kiosk mode',

  HALLPASS_DISABLE_MSG = 'This hall pass type is currently disabled',

  ERROR_SAVING = 'Failed to update reason',

  HELP_TEXT = 'Restrict Check In / Out privileges to specific Roles, ID Stickers, Groups, Grades or other Reasons',

  LABEL_COMPLETE_CONSEQUENCE = 'Auto complete most outstanding consequence',

  LABEL_SELECT_NONE = 'None',

  AUTO_END_HALL_PASS_MSG = 'Auto-end hall pass on check in',
  TOOLTIP_AUTO_END_HALL_PASS = 'Active hall passes will automatcially end when the user is checked in to this reason',

  TRACK_ABSENTEES_MSG = 'Track absentees',
  NO_ACCESS_MSG = 'Apply no access filter',
  TOGGLE_LABEL_SELF_CHECKIN = 'Allow self check in',
  TOGGLE_LABEL_CHECKOUT = 'Allow check outs',
  TOGGLE_LABEL_STUDENT_PHOTO = 'Show student photo on check in',

  TOOLTIP_STUDENT_PHOTO = 'The student photo will be displayed on the screen after check in',
  TOOLTIP_NO_ACCESS = 'Prevent users on the "No Access" list from checking in.',
  TOOLTIP_CONS = 'When users are checked in to this reason, their earliest outstanding consequence of the selected type(s) will be completed.',
  TOOLTIP_KIOSK = 'Allow to check in with kiosk mode. Check in kiosk needs to be turned on in the check in general settings',
  TOGGLE_ALLOW_MULTIPLE_CHECKINS = 'Allow multiple check ins',

  KIOSK_TITLE = 'Kiosk mode',
  RESTRICTION_TITLE = 'Reason restrictions',
  AUTOMATIONS_TITLE = 'Automations',

  INVALID_FORM_MESSAGE = 'Please complete the missing information(s)',
}

export enum CheckInReasonsFormFields {
  NAME = 'name',
  ICON = 'icon',
  COLOR = 'color',
  POINTS = 'pointReward',
  KIOSK = 'availableInKiosk',
  RESTRICTIONS = 'restriction',
  BEHAVIOR = 'behaviorId',
  HALLPASS = 'hallPassId',
  CONSEQUENCE = 'consequenceId',
  AUTO_END = 'autoEndHallPass',
  ABSENTEES = 'showAbsentees',
  NO_ACCESS = 'blockNoAccess',
  MULTIPLE_CHECKINS = 'allowMultipleCheckins',
  QR_CODE = 'selfCheckIn',
  PHOTO = 'enableStudentPhoto',
}

const FORM: FormConfig<
  // form helpers dont have support for nested form arrays yet
  // this excluded field will be initialized inside the component
  Exclude<CheckInReasonsFormFields, CheckInReasonsFormFields.RESTRICTIONS>,
  ICheckinReason
> = {
  [CheckInReasonsFormFields.NAME]: [
    '',
    [Validators.required, Validators.minLength(1), Validators.maxLength(25)],
  ],
  [CheckInReasonsFormFields.POINTS]: [0],
  [CheckInReasonsFormFields.ABSENTEES]: [false],
  [CheckInReasonsFormFields.NO_ACCESS]: [false],
  [CheckInReasonsFormFields.QR_CODE]: [false],
  [CheckInReasonsFormFields.MULTIPLE_CHECKINS]: [false],
  [CheckInReasonsFormFields.HALLPASS]: [null],
  [CheckInReasonsFormFields.BEHAVIOR]: [null],
  [CheckInReasonsFormFields.CONSEQUENCE]: [null],
  [CheckInReasonsFormFields.ICON]: ['checkin-1', [Validators.required]],
  [CheckInReasonsFormFields.COLOR]: ['#1d9fb9', [Validators.required]],
  [CheckInReasonsFormFields.KIOSK]: [false],
  [CheckInReasonsFormFields.AUTO_END]: [false],
  [CheckInReasonsFormFields.PHOTO]: [false],
};

export const createForm = formUtils.createForm(FORM);
export const setForm = formUtils.setForm(FORM);
export const getData = formUtils.getData(FORM);
