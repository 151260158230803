// GENERATED CODE -- DO NOT EDIT!

import {grpc} from '@improbable-eng/grpc-web';

import * as gateway_mingaGroup_pb from '../gateway/mingaGroup_pb';
import * as gateway_moderation_pb from '../gateway/moderation_pb';
import * as image_image_pb from '../image/image_pb';
import * as gateway_person_view_pb from '../gateway/person_view_pb';
import * as gateway_banner_pb from '../gateway/banner_pb';
import * as gateway_file_pb from '../gateway/file_pb';

export class MingaGroup {
  static readonly serviceName = "minga.group.MingaGroup";

  static readonly Create = {
    methodName: "Create",
    service: MingaGroup,
    requestStream: false,
    responseStream: false,
    requestType: gateway_mingaGroup_pb.CreateMingaGroupRequest,
    responseType: gateway_mingaGroup_pb.CreateMingaGroupResponse,
  };

  static readonly Update = {
    methodName: "Update",
    service: MingaGroup,
    requestStream: false,
    responseStream: false,
    requestType: gateway_mingaGroup_pb.UpdateMingaGroupRequest,
    responseType: gateway_mingaGroup_pb.UpdateMingaGroupResponse,
  };

  static readonly GetDetails = {
    methodName: "GetDetails",
    service: MingaGroup,
    requestStream: false,
    responseStream: false,
    requestType: gateway_mingaGroup_pb.GetMingaGroupDetailsRequest,
    responseType: gateway_mingaGroup_pb.GetMingaGroupDetailsResponse,
  };

  static readonly GetMingaGroups = {
    methodName: "GetMingaGroups",
    service: MingaGroup,
    requestStream: false,
    responseStream: false,
    requestType: gateway_mingaGroup_pb.GetMingaGroupsRequest,
    responseType: gateway_mingaGroup_pb.GetMingaGroupsResponse,
  };

  static readonly GetPersonGroups = {
    methodName: "GetPersonGroups",
    service: MingaGroup,
    requestStream: false,
    responseStream: false,
    requestType: gateway_mingaGroup_pb.GetPersonGroupsRequest,
    responseType: gateway_mingaGroup_pb.GetPersonGroupsResponse,
  };

  static readonly InviteMember = {
    methodName: "InviteMember",
    service: MingaGroup,
    requestStream: false,
    responseStream: false,
    requestType: gateway_mingaGroup_pb.InviteMingaGroupMemberRequest,
    responseType: gateway_mingaGroup_pb.InviteMingaGroupMemberResponse,
  };

  static readonly AcceptMember = {
    methodName: "AcceptMember",
    service: MingaGroup,
    requestStream: false,
    responseStream: false,
    requestType: gateway_mingaGroup_pb.AcceptMingaGroupMemberRequest,
    responseType: gateway_mingaGroup_pb.AcceptMingaGroupMemberResponse,
  };

  static readonly DeclineMember = {
    methodName: "DeclineMember",
    service: MingaGroup,
    requestStream: false,
    responseStream: false,
    requestType: gateway_mingaGroup_pb.DeclineMingaGroupMemberRequest,
    responseType: gateway_mingaGroup_pb.DeclineMingaGroupMemberResponse,
  };

  static readonly RemoveMember = {
    methodName: "RemoveMember",
    service: MingaGroup,
    requestStream: false,
    responseStream: false,
    requestType: gateway_mingaGroup_pb.RemoveMingaGroupMemberRequest,
    responseType: gateway_mingaGroup_pb.RemoveMingaGroupMemberResponse,
  };

  static readonly AcceptInvite = {
    methodName: "AcceptInvite",
    service: MingaGroup,
    requestStream: false,
    responseStream: false,
    requestType: gateway_mingaGroup_pb.AcceptMingaGroupInviteRequest,
    responseType: gateway_mingaGroup_pb.AcceptMingaGroupInviteResponse,
  };

  static readonly DeclineInvite = {
    methodName: "DeclineInvite",
    service: MingaGroup,
    requestStream: false,
    responseStream: false,
    requestType: gateway_mingaGroup_pb.DeclineMingaGroupInviteRequest,
    responseType: gateway_mingaGroup_pb.DeclineMingaGroupInviteResponse,
  };

  static readonly Join = {
    methodName: "Join",
    service: MingaGroup,
    requestStream: false,
    responseStream: false,
    requestType: gateway_mingaGroup_pb.JoinMingaGroupRequest,
    responseType: gateway_mingaGroup_pb.JoinMingaGroupResponse,
  };

  static readonly Leave = {
    methodName: "Leave",
    service: MingaGroup,
    requestStream: false,
    responseStream: false,
    requestType: gateway_mingaGroup_pb.LeaveMingaGroupRequest,
    responseType: gateway_mingaGroup_pb.LeaveMingaGroupResponse,
  };

  static readonly CancelJoin = {
    methodName: "CancelJoin",
    service: MingaGroup,
    requestStream: false,
    responseStream: false,
    requestType: gateway_mingaGroup_pb.CancelJoinMingaGroupRequest,
    responseType: gateway_mingaGroup_pb.CancelJoinMingaGroupResponse,
  };

  static readonly DeleteGroup = {
    methodName: "DeleteGroup",
    service: MingaGroup,
    requestStream: false,
    responseStream: false,
    requestType: gateway_mingaGroup_pb.DeleteMingaGroupRequest,
    responseType: gateway_mingaGroup_pb.DeleteMingaGroupResponse,
  };

  static readonly AddFileAssets = {
    methodName: "AddFileAssets",
    service: MingaGroup,
    requestStream: false,
    responseStream: false,
    requestType: gateway_mingaGroup_pb.AddMingaGroupAssetsRequest,
    responseType: gateway_mingaGroup_pb.MingaGroupFilesResponse,
  };

  static readonly GetFiles = {
    methodName: "GetFiles",
    service: MingaGroup,
    requestStream: false,
    responseStream: false,
    requestType: gateway_mingaGroup_pb.GetMingaGroupFilesRequest,
    responseType: gateway_mingaGroup_pb.MingaGroupFilesResponse,
  };

  static readonly RemoveFileAsset = {
    methodName: "RemoveFileAsset",
    service: MingaGroup,
    requestStream: false,
    responseStream: false,
    requestType: gateway_mingaGroup_pb.RemoveMingaGroupAssetRequest,
    responseType: gateway_mingaGroup_pb.MingaGroupFilesResponse,
  };

  static readonly UpdateMembers = {
    methodName: "UpdateMembers",
    service: MingaGroup,
    requestStream: false,
    responseStream: false,
    requestType: gateway_mingaGroup_pb.UpdateMingaGroupMembersRequest,
    responseType: gateway_mingaGroup_pb.UpdateMingaGroupMembersResponse,
  };

  static readonly AddMembers = {
    methodName: "AddMembers",
    service: MingaGroup,
    requestStream: false,
    responseStream: false,
    requestType: gateway_mingaGroup_pb.AddMingaGroupMembersRequest,
    responseType: gateway_mingaGroup_pb.AddMingaGroupMembersResponse,
  };

  static readonly ExportGroupMembers = {
    methodName: "ExportGroupMembers",
    service: MingaGroup,
    requestStream: false,
    responseStream: true,
    requestType: gateway_mingaGroup_pb.ExportGroupMembersRequest,
    responseType: gateway_mingaGroup_pb.ExportGroupMembersChunk,
  };

}

export class MingaGroupClient {
  create() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  update() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  getDetails() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  getMingaGroups() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  getPersonGroups() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  inviteMember() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  acceptMember() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  declineMember() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  removeMember() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  acceptInvite() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  declineInvite() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  join() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  leave() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  cancelJoin() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  deleteGroup() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  addFileAssets() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  getFiles() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  removeFileAsset() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  updateMembers() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  addMembers() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  exportGroupMembers() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
}
