import { NgModule } from '@angular/core';

import { AddProgramToMingaModule } from './AddProgramToMinga';
import { BroadcastNotificationModule } from './BroadcastNotification';
import { CancelConfirmationDialogModule } from './CancelConfirmation';
import { DebugContentDialogModule } from './DebugContentDialog';
import { DeleteConfirmationModule } from './DeleteConfirmation';
import { DeleteImageConfirmationModule } from './DeleteImageConfirmation';
import { DeleteProgramConfirmationModule } from './DeleteProgramConfirmation';
import { DialogViewModule } from './DialogView';
import { EventCodeCheckInModule } from './EventCodeCheckIn';
import { KeepDiscardDialogModule } from './KeepDiscard';
import { RemoveProgramFromMingaDialogModule } from './RemoveProgramFromMinga';
import { SaveCancelDialogModule } from './SaveCancel';
import { SendFeedbackModule } from './SendFeedback';
import { StayLeaveDialogModule } from './StayLeave';
import { SuccessDialogModule } from './SuccessDialog';

/**
 * @deprecated - Use individual modules instead
 */
@NgModule({
  imports: [
    AddProgramToMingaModule,
    BroadcastNotificationModule,
    DebugContentDialogModule,
    DeleteConfirmationModule,
    DeleteImageConfirmationModule,
    DeleteProgramConfirmationModule,
    DialogViewModule,
    EventCodeCheckInModule,
    KeepDiscardDialogModule,
    RemoveProgramFromMingaDialogModule,
    SendFeedbackModule,
    SuccessDialogModule,
    StayLeaveDialogModule,
    SaveCancelDialogModule,
    CancelConfirmationDialogModule,
  ],
  exports: [
    AddProgramToMingaModule,
    BroadcastNotificationModule,
    DebugContentDialogModule,
    DeleteConfirmationModule,
    DeleteImageConfirmationModule,
    DeleteProgramConfirmationModule,
    DialogViewModule,
    EventCodeCheckInModule,
    KeepDiscardDialogModule,
    RemoveProgramFromMingaDialogModule,
    SendFeedbackModule,
    SuccessDialogModule,
    StayLeaveDialogModule,
    SaveCancelDialogModule,
    CancelConfirmationDialogModule,
  ],
})
export class MgDialogsModule {}
