import { MingaPermission, MingaRoleType } from 'libs/domain';
import { DisplayNameFormat, RoleFields } from 'libs/util';

import { RoleCategory, UserRole } from './UserRole';

export class StudentRole extends UserRole {
  readonly roleType = MingaRoleType.STUDENT;
  readonly name = 'Student';
  readonly displayNameFormat = DisplayNameFormat.FIRST_LAST;
  readonly iconUrl = 'assets/roles/student.svg';
  readonly iconColor = '#0B6B83';
  readonly capabilitiesDescription = `Students will be allowed to share posts, pictures, and videos`;
  readonly feedPermittable = true;
  readonly galleryPermittable = true;
  readonly roleFields: RoleFields = RoleFields.GRAD_STUDENT;
  readonly groupFeedPermittable = true;
  readonly programManagePermittable = false;
  readonly videoUploadPermittable = true;
  readonly commentPermittable = true;
  readonly groupCreatePermittable = true;
  readonly admin = false;
  readonly requiredFeatures = [];
  readonly sendToMailChimp = false;
  readonly IDRole = RoleCategory.STUDENT;

  readonly permissions = [
    MingaPermission.ACCOUNT_PROFILE_CHANGE,
    MingaPermission.ASSET_UPLOAD,
    MingaPermission.CONTENT_COMMENT_CREATE,
    MingaPermission.CONTENT_COMMENTS_VIEW,
    MingaPermission.CONTENT_GROUP_ENABLED,
    MingaPermission.CONTENT_LIKES_LIKE,
    MingaPermission.CONTENT_POST_CREATE,
    MingaPermission.CONTENT_POST_SET_TITLE,
    MingaPermission.CONTENT_REPORT_CREATE,
    MingaPermission.CONTENT_VIDEO_CREATE,
    MingaPermission.GROUP_CONTENT_POST_CREATE,
    MingaPermission.GROUP_CONTENT_VIDEO_CREATE,
    MingaPermission.CONTENT_POLL_ANSWER,
    MingaPermission.CONTENT_CREATED_MINGA_DESIGNER_CONTENT,
    MingaPermission.GROUP_ALLOW_PARENT_GROUP,
    MingaPermission.CONTENT_PUBLISH_DATES_SET,
    MingaPermission.CHALLENGE_COMPLETE,
    MingaPermission.STUDENT_TOOLS,
    MingaPermission.SELF_CHECKIN,
    MingaPermission.FLEX_TIME_REGISTER_FOR_ACTIVITY,
    MingaPermission.STUDENT_SCHEDULE_VIEW,
  ];
  readonly overridablePermissions = [];
}
