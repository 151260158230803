// GENERATED CODE -- DO NOT EDIT!

import {grpc} from '@improbable-eng/grpc-web';

import * as people_collection_people_collection_pb from '../people_collection/people_collection_pb';
import * as gateway_person_view_pb from '../gateway/person_view_pb';
import * as common_order_pb from '../common/order_pb';

export class PeopleCollection {
  static readonly serviceName = "PeopleCollection";

  static readonly GetPeopleCollection = {
    methodName: "GetPeopleCollection",
    service: PeopleCollection,
    requestStream: false,
    responseStream: true,
    requestType: people_collection_people_collection_pb.GetPeopleCollectionRequest,
    responseType: people_collection_people_collection_pb.GetPeopleCollectionResponse,
  };

  static readonly SearchPeopleCollection = {
    methodName: "SearchPeopleCollection",
    service: PeopleCollection,
    requestStream: false,
    responseStream: true,
    requestType: people_collection_people_collection_pb.SearchPeopleCollectionRequest,
    responseType: people_collection_people_collection_pb.SearchPeopleCollectionResponse,
  };

  static readonly GetPerson = {
    methodName: "GetPerson",
    service: PeopleCollection,
    requestStream: false,
    responseStream: false,
    requestType: people_collection_people_collection_pb.GetPersonRequest,
    responseType: people_collection_people_collection_pb.GetPersonResponse,
  };

  static readonly GetPeopleByRoles = {
    methodName: "GetPeopleByRoles",
    service: PeopleCollection,
    requestStream: false,
    responseStream: false,
    requestType: people_collection_people_collection_pb.GetPeopleByRolesRequest,
    responseType: people_collection_people_collection_pb.GetPeopleByRolesResponse,
  };

  static readonly GetPeopleForSelector = {
    methodName: "GetPeopleForSelector",
    service: PeopleCollection,
    requestStream: false,
    responseStream: false,
    requestType: people_collection_people_collection_pb.GetPeopleForSelectorRequest,
    responseType: people_collection_people_collection_pb.GetPeopleForSelectorResponse,
  };

  static readonly ExportPeopleForSelector = {
    methodName: "ExportPeopleForSelector",
    service: PeopleCollection,
    requestStream: false,
    responseStream: true,
    requestType: people_collection_people_collection_pb.GetPeopleForSelectorRequest,
    responseType: people_collection_people_collection_pb.ExportPeopleForSelectorChunk,
  };

  static readonly GetPeopleFromFile = {
    methodName: "GetPeopleFromFile",
    service: PeopleCollection,
    requestStream: false,
    responseStream: false,
    requestType: people_collection_people_collection_pb.GetPeopleFromFileRequest,
    responseType: people_collection_people_collection_pb.GetPeopleFromFileResponse,
  };

  static readonly GetPersonByStudentId = {
    methodName: "GetPersonByStudentId",
    service: PeopleCollection,
    requestStream: false,
    responseStream: false,
    requestType: people_collection_people_collection_pb.GetPersonByStudentIdRequest,
    responseType: people_collection_people_collection_pb.GetPersonByStudentIdResponse,
  };

  static readonly Summary = {
    methodName: "Summary",
    service: PeopleCollection,
    requestStream: false,
    responseStream: false,
    requestType: people_collection_people_collection_pb.PeopleCollectionSummaryRequest,
    responseType: people_collection_people_collection_pb.PeopleCollectionSummaryResponse,
  };

}

export class PeopleCollectionClient {
  getPeopleCollection() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  searchPeopleCollection() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  getPerson() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  getPeopleByRoles() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  getPeopleForSelector() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  exportPeopleForSelector() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  getPeopleFromFile() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  getPersonByStudentId() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  summary() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
}
