<mg-overlay-primary
  size="auto"
  [overlayTitle]="modalTitle"
  [isLoading]="(formState$ | async) === 'loading'"
  [disableEscape]="true">
  <ng-container *ngIf="data.changes">
    <ng-container *ngTemplateOutlet="mainTemplate"></ng-container>
  </ng-container>
  <ng-template #overlayFooter>
    <ng-container *ngTemplateOutlet="footerTemplate"></ng-container>
  </ng-template>
</mg-overlay-primary>

<!-- Main Content -->
<ng-template #mainTemplate>
  <main
    #crudForm
    class="modal-form">
    <form
      class="tw-flex tw-flex-column tw-justify-start tw-items-stretch tw-gap-4"
      id="checkin-reason-form"
      [formGroup]="form"
      (ngSubmit)="submit()">
      <fieldset
        *ngIf="(formState$ | async) === 'invalid'"
        class="form-section">
        <mg-system-alert
          type="error"
          [message]="MESSAGES.INVALID_FORM_MESSAGE">
        </mg-system-alert>
      </fieldset>

      <fieldset class="form-section">
        <!-- Name -->
        <mg-form-text-input
          inputType="text"
          [id]="'checkin-types-click-name'"
          [label]="MESSAGES.LABEL_NAME"
          [control]="form.get(FORM_FIELDS.NAME)">
        </mg-form-text-input>

        <!-- Icon -->
        <div
          fxLayout="row"
          fxLayoutAlign="space-between center">
          <mg-text variant="body-md">
            {{ MESSAGES.LABEL_ICON }}
          </mg-text>
          <mg-form-select
            appendTo="body"
            [value]="form.get(FORM_FIELDS.ICON).value"
            [control]="form.get(FORM_FIELDS.ICON)"
            [closeOnSelect]="true"
            [floatingLabel]="false"
            [multiple]="false"
            [searchable]="false"
            [condensed]="true"
            [options]="CHECKIN_ICONS">
            <ng-template let-item="item">
              <div class="icon-wrap">
                <mg-icon
                  [iconName]="item.label"
                  [style.color]="form.get(FORM_FIELDS.COLOR).value">
                </mg-icon>
              </div>
            </ng-template>
          </mg-form-select>
        </div>

        <!-- Color Field -->
        <div
          fxLayout="row"
          fxLayoutAlign="space-between center">
          <mg-text variant="body-md">
            {{ MESSAGES.LABEL_COLOR }}
          </mg-text>
          <div>
            <mg-form-text-input
              inputType="color"
              list="colorDatalist"
              [condensed]="true"
              [control]="form.get(FORM_FIELDS.COLOR)">
            </mg-form-text-input>
            <datalist id="colorDatalist">
              <div *ngFor="let item of PRESET_COLORS">
                <option>{{ item }}</option>
              </div>
            </datalist>
          </div>
        </div>

        <!-- Points -->
        <div
          fxLayout="row"
          fxLayoutAlign="space-between center">
          <mg-text variant="body-md">{{ MESSAGES.LABEL_POINTS }}</mg-text>
          <div>
            <mg-form-text-input
              inputType="number"
              [id]="'checkin-types-click-points'"
              [control]="form.get(FORM_FIELDS.POINTS)">
            </mg-form-text-input>
          </div>
        </div>
      </fieldset>

      <div class="divider"></div>

      <fieldset class="form-section">
        <!-- Kiosk -->
        <ng-container *ngIf="kioskPermissions.isKioskFeatureEnabled()">
          <div
            fxLayout="column"
            fxLayoutAlign="center start">
            <mg-text
              variant="header-sm-secondary"
              spacing="1">
              {{ MESSAGES.KIOSK_TITLE }}
            </mg-text>
          </div>

          <mg-form-toggle
            [id]="'checkin-types-click-kiosk'"
            [label]="MESSAGES.LABEL_AVAILABLE_IN_KIOSK"
            [tooltip]="MESSAGES.TOOLTIP_KIOSK"
            [disabled]="(kioskEnabled$ | async) === false"
            [control]="form.get(FORM_FIELDS.KIOSK)">
          </mg-form-toggle>

          <div class="divider"></div>
        </ng-container>
      </fieldset>

      <fieldset class="form-section">
        <!-- Restrictions -->
        <div
          fxLayout="column"
          fxLayoutAlign="center start">
          <mg-text
            variant="header-sm-secondary"
            spacing="1">
            {{ MESSAGES.RESTRICTION_TITLE }}
          </mg-text>
        </div>

        <!-- Eligble Users -->
        <mg-form-restriction-input
          [label]="MESSAGES.LABEL_RESTRICTION"
          [required]="true"
          [membershipListType]="MEMBERSHIP_LIST_TYPES.CHECKIN_RESTRICTION_LIST"
          [value]="form.get(FORM_FIELDS.RESTRICTIONS).value"
          [control]="form.get(FORM_FIELDS.RESTRICTIONS)">
        </mg-form-restriction-input>
      </fieldset>

      <div class="divider"></div>

      <fieldset class="form-section">
        <!-- Automations -->
        <div
          fxLayout="column"
          fxLayoutAlign="center start">
          <mg-text
            variant="header-sm-secondary"
            spacing="1">
            {{ MESSAGES.AUTOMATIONS_TITLE }}
          </mg-text>
        </div>

        <mg-form-select
          [placeholder]="MESSAGES.LABEL_ASSIGN_BEHAVIOR"
          [value]="form.get(FORM_FIELDS.BEHAVIOR).value"
          [multiple]="false"
          [searchable]="true"
          [options]="behaviors$ | async"
          [isClearable]="true"
          [disabled]="(hasPbis$ | async) !== true"
          [control]="form.get(FORM_FIELDS.BEHAVIOR)"
          [enableMobileSheet]="true">
        </mg-form-select>

        <!-- Assign Hall Pass -->
        <mg-form-select
          [placeholder]="MESSAGES.LABEL_ASSIGN_HALL_PASS"
          [value]="form.get(FORM_FIELDS.HALLPASS).value"
          [multiple]="false"
          [searchable]="true"
          [options]="hallPasses$ | async"
          [isClearable]="true"
          [disabled]="(hasHp$ | async) !== true"
          [enableMobileSheet]="true"
          [control]="form.get(FORM_FIELDS.HALLPASS)">
          <ng-template let-item="item">
            <mg-form-select-option [option]="item"></mg-form-select-option>
          </ng-template>
        </mg-form-select>

        <mg-form-select
          [id]="'checkin-types-click-complete-conseq'"
          [placeholder]="MESSAGES.LABEL_COMPLETE_CONSEQUENCE"
          [value]="form.get(FORM_FIELDS.CONSEQUENCE).value"
          [multiple]="false"
          [options]="consequences$ | async"
          [isClearable]="true"
          [control]="form.get(FORM_FIELDS.CONSEQUENCE)"
          [enableMobileSheet]="true"
          [disabled]="(hasCons$ | async) !== true">
        </mg-form-select>

        <ng-container
          [ngTemplateOutlet]="toggleTemplate"
          [ngTemplateOutletContext]="{
            $implicit: MESSAGES.AUTO_END_HALL_PASS_MSG,
            control: form.get(FORM_FIELDS.AUTO_END),
            tooltip: MESSAGES.TOOLTIP_AUTO_END_HALL_PASS,
            id: 'checkin-types-click-auto-end-hall-pass'
          }">
        </ng-container>
      </fieldset>

      <div class="divider"></div>

      <fieldset class="form-section">
        <!-- Toggles -->
        <ng-container
          [ngTemplateOutlet]="toggleTemplate"
          [ngTemplateOutletContext]="{
            $implicit: MESSAGES.TRACK_ABSENTEES_MSG,
            control: form.get(FORM_FIELDS.ABSENTEES),
            tooltip: '',
            id: 'checkin-types-click-track-absentees'
          }">
        </ng-container>
        <ng-container
          [ngTemplateOutlet]="toggleTemplate"
          [ngTemplateOutletContext]="{
            $implicit: MESSAGES.NO_ACCESS_MSG,
            control: form.get(FORM_FIELDS.NO_ACCESS),
            tooltip: MESSAGES.TOOLTIP_NO_ACCESS,
            id: 'checkin-types-click-no-access'
          }">
        </ng-container>

        <ng-container
          [ngTemplateOutlet]="toggleTemplate"
          [ngTemplateOutletContext]="{
            $implicit: MESSAGES.TOGGLE_ALLOW_MULTIPLE_CHECKINS,
            control: form.get(FORM_FIELDS.MULTIPLE_CHECKINS),
            tooltip: '',
            id: 'checkin-types-click-allow-multiple'
          }">
        </ng-container>

        <!-- Enable self check in / out -->
        <ng-container
          [ngTemplateOutlet]="toggleTemplate"
          [ngTemplateOutletContext]="{
            $implicit: MESSAGES.TOGGLE_LABEL_SELF_CHECKIN,
            control: form.get(FORM_FIELDS.QR_CODE),
            tooltip: '',
            id: 'checkin-types-click-allow-self'
          }">
        </ng-container>

        <!-- View QR code -->
        <div
          *ngIf="!(isNew$ | async)"
          fxLayout="row"
          fxLayoutAlign="start center">
          <mg-btn
            variant="outlined"
            size="small"
            [id]="'checkin-types-click-qr-code'"
            [disabled]="!form.get(FORM_FIELDS.QR_CODE).value"
            (pressed)="openQrViewer()">
            {{ MESSAGES.BUTTON_LABEL_PRINT_QR }}
          </mg-btn>
        </div>

        <!-- Enable student photo field -->
        <ng-container
          [ngTemplateOutlet]="toggleTemplate"
          [ngTemplateOutletContext]="{
            $implicit: MESSAGES.TOGGLE_LABEL_STUDENT_PHOTO,
            control: form.get(FORM_FIELDS.PHOTO),
            tooltip: MESSAGES.TOOLTIP_STUDENT_PHOTO,
            id: 'checkin-types-click-photo'
          }">
        </ng-container>
      </fieldset>
    </form>
  </main>
</ng-template>

<!-- Template: Modal Footer -->
<ng-template #footerTemplate>
  <div
    class="overlay-footer"
    fxLayout="row"
    fxLayoutAlign="space-between center">
    <div>
      <mg-btn
        [id]="'checkin-types-click-delete'"
        [variant]="(isNew$ | async) ? 'text' : 'destructive'"
        (pressed)="confirmDelete()">
        {{
          (isNew$ | async)
            ? MESSAGES.BUTTON_LABEL_CANCEL
            : MESSAGES.BUTTON_LABEL_DELETE
        }}
      </mg-btn>
    </div>
    <div>
      <mg-btn
        attrType="submit"
        attrFormId="checkin-reason-form"
        variant="filled"
        [id]="'checkin-types-click-save'"
        [disabled]="!canSubmit">
        {{
          (isNew$ | async)
            ? MESSAGES.BUTTON_LABEL_CREATE
            : MESSAGES.BUTTON_LABEL_SAVE
        }}
      </mg-btn>
    </div>
  </div>
</ng-template>

<!-- Toggle Template  -->
<ng-template
  #toggleTemplate
  let-name
  let-control="control"
  let-tooltip="tooltip"
  let-id="id">
  <div class="toggle-row">
    <div>
      <mg-text
        element="span"
        variant="body">
        {{ name }}
      </mg-text>
      <mg-tooltip
        *ngIf="tooltip"
        [label]="tooltip">
      </mg-tooltip>
    </div>
    <mat-slide-toggle
      [attr.data-analytics]="id"
      [formControl]="control">
    </mat-slide-toggle>
  </div>
</ng-template>
