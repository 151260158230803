import { Injectable, OnDestroy } from '@angular/core';

import { Store } from '@ngrx/store';
import { INotificationType } from 'libs/domain';
import { gateway } from 'libs/generated-grpc-web';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { $enum } from 'ts-enum-util';

import { NotificationListenerBaseService } from '../NotificationListenerBase.service';
import { BehaviorsNotificationHandlerService } from './BehaviorsNotificationHandler.service';

/**
 * Listen to incoming notifications for behavior notifications
 */
@Injectable({ providedIn: 'root' })
export class BehaviorsNotificationListenerService
  extends NotificationListenerBaseService
  implements OnDestroy
{
  private readonly _BEHAVIOR_NOTIFICATION_TYPES = [
    INotificationType.BM_PRAISE,
    INotificationType.BM_GUIDANCE,
    INotificationType.BM_CONSEQUENCE_PRAISE,
    INotificationType.BM_CONSEQUENCE_GUIDANCE,
  ];

  private _destroyed$ = new ReplaySubject<void>(undefined);

  constructor(
    public store: Store<any>,
    private _notificationHandler: BehaviorsNotificationHandlerService,
  ) {
    super(store);

    this.notifications$
      .pipe(takeUntil(this._destroyed$))
      .subscribe(notification => {
        this._handleNotification(notification);
      });
  }

  ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  private async _handleNotification(
    msg: gateway.notification_pb.Notification.AsObject,
  ) {
    const notificationType = $enum(INotificationType).asValueOrThrow(
      msg.notificationType,
    );

    if (this._isBehaviorNotification(notificationType))
      this._notificationHandler.handleBehaviorNotification(msg);
  }

  private _isBehaviorNotification(
    notificationType: INotificationType,
  ): boolean {
    if (this._BEHAVIOR_NOTIFICATION_TYPES.includes(notificationType))
      return true;

    return false;
  }
}
