import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';

import { MgEmptyStateModule } from '@app/src/app/components/EmptyState';
import { FileInputUploadModule } from '@app/src/app/components/Input/FileInputUpload';
import { MgImageModule } from '@app/src/app/components/MgImage';
import { MgFormFieldModule } from '@app/src/app/form-field';
import { MgIconModule } from '@app/src/app/icon';
import { MgPipesModule } from '@app/src/app/pipes';
import { MgSpinnerModule } from '@app/src/app/spinner';

import { FormModule } from '@shared/components/form';
import { OverlayModule } from '@shared/components/modal-overlay';
import { TooltipModule } from '@shared/components/tooltip';

import { GenericModule } from '../generic';
import { ReportTableModule } from '../report-table/report-table.module';
import { MobileCardModule } from '../tables/mobile-card/mobile-card.module';
import { TextModule } from '../text/text.module';
import { MltEditComponent } from './components/mlt-edit/mlt-edit.component';
import { MltImageEditComponent } from './components/mlt-image-edit/mlt-image-edit.component';
import { MembershipListTableComponent } from './membership-list-table.component';
import {
  MembershipListDefaultNamePipe,
  MembershipListDescriptionPipe,
} from './pipes';

@NgModule({
  imports: [
    // Minga dependencies
    MgIconModule,
    MgSpinnerModule,
    TooltipModule,
    TextModule,
    MgEmptyStateModule,
    MgImageModule,
    MgPipesModule,
    OverlayModule,
    FormModule,
    MgFormFieldModule,
    FileInputUploadModule,
    GenericModule,
    MobileCardModule,
    ReportTableModule,

    // External dependencies
    CommonModule,
    FlexLayoutModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    ReactiveFormsModule,
    FormsModule,
  ],
  declarations: [
    // Components
    MembershipListTableComponent,
    MltImageEditComponent,
    MltEditComponent,

    //Pipes
    MembershipListDescriptionPipe,
    MembershipListDefaultNamePipe,
  ],
  exports: [MembershipListTableComponent],
})
export class MembershipListTableModule {}
