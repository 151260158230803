import { Injectable } from '@angular/core';

import { MingaPermission } from 'libs/domain';
import { gateway } from 'libs/generated-grpc-web';
import { filter, first } from 'rxjs/operators';

import {
  GroupsFacadeService,
  GroupsService,
} from '@app/src/app/groups/services';
import { AppConfigService } from '@app/src/app/minimal/services/AppConfig';
import { AuthInfoService } from '@app/src/app/minimal/services/AuthInfo';
import { MessagingFacade } from '@app/src/app/modules/direct-message/store';
import { NotificationListener } from '@app/src/app/notifications';
import { BadgeNotificationListenerService } from '@app/src/app/notifications/badge';
import { BehaviorsNotificationListenerService } from '@app/src/app/notifications/behaviors';
import { NotificationClickHandler } from '@app/src/app/notifications/clickHandler';
import { InviteNotificationListenerService } from '@app/src/app/notifications/invites';
import { LegacyNotificationHandler } from '@app/src/app/notifications/legacy';
import { PointsNotificationListenerService } from '@app/src/app/notifications/points';
import { PermissionsService } from '@app/src/app/permissions';
import { AppUpdateService } from '@app/src/app/services/AppUpdate/AppUpdate.service';
import { NotificationBadgeSyncService } from '@app/src/app/services/NotificationBadgeSync';

@Injectable({ providedIn: 'root' })
export class StartupService {
  constructor(
    private legacyNotificationHandler: LegacyNotificationHandler,
    private notificationClickHandler: NotificationClickHandler,
    private pointsNotificationHandler: PointsNotificationListenerService,
    private inviteNotificationHandler: InviteNotificationListenerService,
    private badgeNotificationHandler: BadgeNotificationListenerService,
    private messagingService: MessagingFacade,
    private notificationListener: NotificationListener,
    private appConfig: AppConfigService,
    private appUpdater: AppUpdateService,
    private notificationBadgeSyncService: NotificationBadgeSyncService,
    private groupsFacade: GroupsFacadeService,
    private authInfo: AuthInfoService,
    private behaviorsNotificationhandler: BehaviorsNotificationListenerService,
    private loginService: gateway.login_ng_grpc_pb.LoginManager,
    private groupsService: GroupsService,
    private _permissionsService: PermissionsService,
  ) {}

  /**
   * Things that need to get started up after the initial load of the app, once
   * we have a verified user
   */
  async postStartup() {
    this.appUpdater.init();

    // if user is in kiosk mode we dont want any kind of notifications to popup
    if (!this._permissionsService.hasPermission(MingaPermission.KIOSK_MANAGE)) {
      // not awaiting on purpose.
      this.loadPostStartupData();
      this.notificationBadgeSyncService.syncBadgeCount();
    }
  }

  async loadPostStartupData() {
    // load some data from the backend that the client will need in the background
    const data = await this.loginService.onStartup(
      new gateway.login_pb.OnStartupRequest(),
    );
    const groupsMessage = data.getMingaGroups();
    const notificationMessage = data.getUnreadNotificationsList();
    this.loadGroupsData(groupsMessage);
    this.loadMessagingData(data);
    this.loadNotificationData(notificationMessage);
  }

  async loadGroupsData(message: gateway.mingaGroup_pb.GetMingaGroupsResponse) {
    const groupData = this.groupsService.mapAllGroupResponse(message);
    this.groupsFacade.dispatchLoadGroupsSuccess(groupData.groups);
  }

  async loadNotificationData(
    notifications: gateway.notification_pb.Notification[],
  ) {
    this.notificationListener.dispatchNotifications(notifications);
  }

  async loadMessagingData(response: gateway.login_pb.OnStartupResponse) {
    this.messagingService.updateAnyUnreadStatus(
      response.getHasUnreadDirectMessages(),
    );
  }

  /**
   * Things needed to run on initial startup
   */
  async startup() {
    // Unused here. Only needed for construction to setup subscriptions
    this.legacyNotificationHandler;
    this.notificationClickHandler;
    this.pointsNotificationHandler;
    this.inviteNotificationHandler;
    this.badgeNotificationHandler;
    this.behaviorsNotificationhandler;

    this.appConfig.ensureCountrySet();
    this.authInfo.authInfo$
      .pipe(
        filter(payload => !!payload?.person),
        first(),
      )
      .subscribe(payload => {
        this.postStartup();
      });
  }
}
