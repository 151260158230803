import { EditablePbisBehavior, IPbisBehavior } from 'libs/domain';
import { pbis_pb } from 'libs/generated-grpc-ts';
import { PersonViewMinimal } from 'libs/generated-grpc-ts';

import * as PbisTypeMapper from './PbisType.mapper';
import { StudentScheduleMapper } from '../';
import {
  asObjectToMessage,
  dateObjectToDateTimeMessage,
  dateTimeMessageToDateObject,
} from '../';

export const toProto = (behavior: IPbisBehavior): pbis_pb.PbisBehavior => {
  const msg = new pbis_pb.PbisBehavior();
  if (behavior.id) msg.setId(behavior.id);
  if (behavior.mingaId) msg.setMingaId(behavior.mingaId);
  if (behavior.message) msg.setMessage(behavior.message);
  if (behavior.pbisTypeId) msg.setPbisTypeId(behavior.pbisTypeId);
  if (behavior.type) msg.setType(PbisTypeMapper.toProto(behavior.type));
  if (behavior.recipientId) msg.setRecipientId(behavior.recipientId);
  if (behavior.recipient) {
    msg.setRecipient(
      asObjectToMessage(behavior.recipient, new PersonViewMinimal()),
    );
  }
  if (behavior.createdBy) msg.setCreatedBy(behavior.createdBy);
  if (behavior.creator) {
    msg.setCreator(
      asObjectToMessage(behavior.creator, new PersonViewMinimal()),
    );
  }
  if (behavior.createdAt) {
    msg.setCreatedAt(dateObjectToDateTimeMessage(behavior.createdAt));
  }
  if (behavior.points) msg.setPoints(behavior.points);
  if (behavior.imported) msg.setImported(behavior.imported);
  if (behavior.currentClass?.length) {
    const sections = behavior.currentClass.map(StudentScheduleMapper.toProto);
    msg.setCurrentClassesList(sections);
    // deprecated but for b/c
    msg.setCurrentClass(sections[0]);
  }
  return msg;
};

export const toProtoEditable = (
  behavior: EditablePbisBehavior,
): pbis_pb.EditablePbisBehavior => {
  const msg = toProto(behavior);
  const editable = new pbis_pb.EditablePbisBehavior();
  editable.setBehavior(msg);
  if (behavior.manuallyUpdatedAt) {
    editable.setManuallyUpdatedAt(
      dateObjectToDateTimeMessage(behavior.manuallyUpdatedAt),
    );
  }
  return editable;
};

export const fromProto = (behavior: pbis_pb.PbisBehavior): IPbisBehavior => {
  const currentClassList = behavior.getCurrentClassesList();
  return {
    id: behavior.getId(),
    mingaId: behavior.getMingaId(),
    message: behavior.getMessage(),
    pbisTypeId: behavior.getPbisTypeId(),
    type: PbisTypeMapper.fromProto(behavior.getType()),
    recipientId: behavior.getRecipientId(),
    recipient: behavior.getRecipient()?.toObject(),
    createdBy: behavior.getCreatedBy(),
    creator: behavior.getCreator()?.toObject(),
    createdAt: dateTimeMessageToDateObject(behavior.getCreatedAt()),
    points: behavior.getPoints(),
    imported: behavior.getImported(),
    currentClass: currentClassList?.length
      ? currentClassList.map(StudentScheduleMapper.fromProto)
      : undefined,
  };
};

export const fromProtoEditable = (
  behavior: pbis_pb.EditablePbisBehavior,
): EditablePbisBehavior => {
  const updatedAtProto = behavior.getManuallyUpdatedAt();
  return {
    ...fromProto(behavior.getBehavior()),
    manuallyUpdatedAt: updatedAtProto
      ? dateTimeMessageToDateObject(updatedAtProto)
      : undefined,
  };
};
