export const mapListToOption = list => ({
  value: list.id,
  label: list.title,
  data: list,
});

export const mapFlexActivityToOption = (activity, timeTransformer) => {
  const { flexTimeActivity, flexTimePeriod, ...rest } = activity;
  // strip banner image since it blows up local storage

  const { bannerImage, ...restActivity } = flexTimeActivity;

  const formattedTimeRange = flexTimePeriod
    ? `- ${timeTransformer(flexTimePeriod)}`
    : '';

  return {
    value: activity.id,
    label: `${activity.flexTimeActivity.name}`,
    optionLabel: `${activity.flexTimeActivity.name} ${formattedTimeRange}`,
    data: {
      ...rest,
      flexTimeActivity: restActivity,
    },
  };
};
