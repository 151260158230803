import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Router } from '@angular/router';

import { MingaSettingsService } from '@app/src/app/store/Minga/services';

import { ViewIdService } from '@shared/components/view-id/services/view-id.service';
import { MediaService } from '@shared/services/media';

import { MyClassHallPassService } from '../../services/my-class-hallpasses.service';
import { StudentItemBaseDirective } from '../../services/student-item.base';

@Component({
  selector: 'mg-tt-student-list-item',
  templateUrl: './tt-student-list-item.component.html',
  styleUrls: ['./tt-student-list-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TtStudentListItemComponent extends StudentItemBaseDirective {
  constructor(
    public mediaService: MediaService,
    public viewId: ViewIdService,
    public router: Router,
    public mingaSettings: MingaSettingsService,
    public myClassHallPassService: MyClassHallPassService,
  ) {
    super(mediaService, viewId, router, mingaSettings, myClassHallPassService);
  }
}
