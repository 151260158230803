import { NgModule } from '@angular/core';

import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { peopleCollectionStoreKeyname, reducer } from './store/state';

@NgModule({
  imports: [
    StoreModule.forFeature({
      name: peopleCollectionStoreKeyname,
      reducer,
    }),
    EffectsModule.forFeature([]),
  ],
  declarations: [],

  providers: [],
})
export class PeopleModule {}
