// GENERATED CODE -- DO NOT EDIT!

import {grpc} from '@improbable-eng/grpc-web';

import * as content_challenge_challenge_pb from '../../content/challenge/challenge_pb';
import * as content_common_pb from '../../content/common_pb';
import * as common_date_pb from '../../common/date_pb';
import * as content_video_upload_video_upload_pb from '../../content/video_upload/video_upload_pb';
import * as common_delta_pb from '../../common/delta_pb';
import * as gateway_person_view_pb from '../../gateway/person_view_pb';

export class Challenge {
  static readonly serviceName = "minga.content.Challenge";

  static readonly New = {
    methodName: "New",
    service: Challenge,
    requestStream: false,
    responseStream: false,
    requestType: content_challenge_challenge_pb.ChallengeInfo,
    responseType: content_common_pb.ContentSaveResult,
  };

  static readonly Update = {
    methodName: "Update",
    service: Challenge,
    requestStream: false,
    responseStream: false,
    requestType: content_challenge_challenge_pb.ChallengeInfo,
    responseType: content_common_pb.ContentSaveResult,
  };

  static readonly InvitePeople = {
    methodName: "InvitePeople",
    service: Challenge,
    requestStream: false,
    responseStream: false,
    requestType: content_challenge_challenge_pb.InviteChallengePeople,
    responseType: content_challenge_challenge_pb.ChallengeInfo,
  };

  static readonly GetInvitedPeople = {
    methodName: "GetInvitedPeople",
    service: Challenge,
    requestStream: false,
    responseStream: false,
    requestType: content_common_pb.ContentInfoQuery,
    responseType: content_challenge_challenge_pb.ChallengeInvitePeople,
  };

  static readonly GetInfo = {
    methodName: "GetInfo",
    service: Challenge,
    requestStream: false,
    responseStream: false,
    requestType: content_common_pb.ContentInfoQuery,
    responseType: content_challenge_challenge_pb.ChallengeInfo,
  };

}

export class ChallengeClient {
  new() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  update() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  invitePeople() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  getInvitedPeople() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  getInfo() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
}
