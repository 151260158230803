import { Injectable, NgZone } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { grpc } from '@improbable-eng/grpc-web';

import {
  AddCommentRequest,
  AddCommentResponse,
  FetchCommentsRequest,
  FetchCommentsResponse,
} from '../gateway/comment_pb';

import {
  CommentManager as __CommentManager,
} from '../gateway/comment_pb_service';

@Injectable({providedIn: 'root'})
export class CommentManager {

  constructor(private _ngZone: NgZone) {
  }

  addComment(request: AddCommentRequest): Promise<AddCommentResponse>;
  addComment(request: AddCommentRequest, metadata: grpc.Metadata): Promise<AddCommentResponse>;
  addComment(request: AddCommentRequest, callback: (err: any|null, response: AddCommentResponse, metadata: grpc.Metadata) => void): void;
  addComment(request: AddCommentRequest, metadata: grpc.Metadata, callback: (err: any|null, response: AddCommentResponse, metadata: grpc.Metadata) => void): void;

  addComment(request: AddCommentRequest, arg1?: grpc.Metadata|((err: any|null, response: AddCommentResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: AddCommentResponse, metadata: grpc.Metadata) => void): Promise<AddCommentResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<AddCommentResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__CommentManager.addComment, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  fetchComments(request: FetchCommentsRequest): Promise<FetchCommentsResponse>;
  fetchComments(request: FetchCommentsRequest, metadata: grpc.Metadata): Promise<FetchCommentsResponse>;
  fetchComments(request: FetchCommentsRequest, callback: (err: any|null, response: FetchCommentsResponse, metadata: grpc.Metadata) => void): void;
  fetchComments(request: FetchCommentsRequest, metadata: grpc.Metadata, callback: (err: any|null, response: FetchCommentsResponse, metadata: grpc.Metadata) => void): void;

  fetchComments(request: FetchCommentsRequest, arg1?: grpc.Metadata|((err: any|null, response: FetchCommentsResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: FetchCommentsResponse, metadata: grpc.Metadata) => void): Promise<FetchCommentsResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<FetchCommentsResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__CommentManager.fetchComments, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

}

