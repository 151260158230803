import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { gateway } from 'libs/generated-grpc-web';

@Component({
  selector: 'mg-badge-notification',
  templateUrl: './MgBadgeNotification.element.html',
  styleUrls: ['./MgBadgeNotification.element.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MgBadgeNotificationElement {
  @Input()
  notification?: gateway.notification_pb.Notification.AsObject;
}
