// GENERATED CODE -- DO NOT EDIT!

import {grpc} from '@improbable-eng/grpc-web';

import * as gateway_connect_pb from '../gateway/connect_pb';
import * as common_legacy_pb from '../common/legacy_pb';

export class AppConfig {
  static readonly serviceName = "AppConfig";

  static readonly getAppConfig = {
    methodName: "getAppConfig",
    service: AppConfig,
    requestStream: false,
    responseStream: false,
    requestType: gateway_connect_pb.AppConfigRequest,
    responseType: gateway_connect_pb.AppConfigResponse,
  };

}

export class LocaleSync {
  static readonly serviceName = "LocaleSync";

  static readonly getLocaleSync = {
    methodName: "getLocaleSync",
    service: LocaleSync,
    requestStream: false,
    responseStream: false,
    requestType: gateway_connect_pb.LocaleSyncRequest,
    responseType: gateway_connect_pb.LocaleSyncResponse,
  };

}

export class ErrorReporter {
  static readonly serviceName = "ErrorReporter";

  static readonly sendErrorReport = {
    methodName: "sendErrorReport",
    service: ErrorReporter,
    requestStream: false,
    responseStream: false,
    requestType: gateway_connect_pb.ErrorReportRequest,
    responseType: gateway_connect_pb.ErrorReportResponse,
  };

}

export class AppConfigClient {
  getAppConfig() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
}

export class LocaleSyncClient {
  getLocaleSync() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
}

export class ErrorReporterClient {
  sendErrorReport() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
}
