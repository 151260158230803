import { Absentee, Checkin } from 'libs/domain';

import { ColumnInfo, ExportNameType } from '../types';
import { assignedToAccessorFn, nameFinder } from '../utils';
import {
  firstName,
  grade,
  lastName,
  note,
  role,
  studentId,
  currentClass,
  sectionId,
  periodId,
  termId,
} from './columns.constants';
import {
  DateColumnKeys,
  NameColumnKeys,
  TimeColumnKeys,
} from './common.constants';

const reason: ColumnInfo = {
  header: 'Reason',
  key: 'checkinReasonName',
  tooltip: true,
  accessorFn: (row: Checkin) => row.reasonName || '',
  type: 'text',
  width: 'flex-large',
};

export const REASON_HISTORY: ColumnInfo[] = [
  reason,
  {
    ...firstName,
    accessorFn: (row: Checkin) => assignedToAccessorFn(row, 'first'),
  },
  {
    ...lastName,
    accessorFn: (row: Checkin) => assignedToAccessorFn(row, 'last'),
  },
  studentId,
  grade,
  role,
  note,
  currentClass,
  sectionId,
  periodId,
  termId,
  {
    header: 'Issued by',
    key: NameColumnKeys.TEACHER_NAME,
    tooltip: true,
    accessorFn: (row: Checkin, value: ExportNameType) => {
      return nameFinder(row.checkinBy, value) || 'Automation';
    },
    type: 'text',
    width: 'flex-medium',
  },
  {
    header: 'Date',
    key: DateColumnKeys.CHECKIN_DATE,
    accessorFn: (row: Checkin) => {
      return row.checkinTime;
    },
    sort: true,
    type: 'date',
    width: 'medium',
  },
  {
    header: 'Time',
    key: TimeColumnKeys.TIME,
    accessorFn: (row: Checkin) => {
      return row.checkinTime;
    },
    type: 'date',
    width: 'small',
  },
  // Commented out while we are not using the checkout feature.
  // {
  //   header: 'Checked Out By',
  //   key: NameColumnKeys.TEACHER_NAME_2,
  //   tooltip: true,
  //   accessorFn: (row: Checkin, name?: ExportNameType) => {
  //     return row.checkoutBy ? nameFinder(row.checkoutBy, name) || '' : '';
  //   },
  //   columnClasses: ['w-md-flex'],
  // },
  // {
  //   header: 'Check Out Date',
  //   key: 'checkOutDate',
  //   accessorFn: (row: Checkin) => {
  //     return getDate(row.checkoutTime);
  //   },
  //   columnClasses: ['w-sm-width'],
  // },
  // {
  //   header: 'Check Out Time',
  //   key: 'checkOutTime',
  //   accessorFn: (row: Checkin) => {
  //     return getTime(row.checkoutTime);
  //   },
  //   columnClasses: ['w-sm-width'],
  // },
  {
    header: 'Last Modified',
    key: DateColumnKeys.MANUALLY_UPDATED_AT,
    type: 'date',
    width: 'medium',
  },
];

export const ABSENTEES: ColumnInfo[] = [
  { ...reason, accessorFn: (row: Absentee) => row.reason?.name || '' },
  firstName,
  lastName,
  studentId,
  grade,
  role,
];
