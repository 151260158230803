import { Component, Inject } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'mg-dialog-savecancel',
  templateUrl: './SaveCancel.component.html',
  styleUrls: ['./SaveCancel.component.scss'],
})
export class SaveCancelDialog {
  public text = '';
  public body = '';
  public bodyParams: any;
  public saveButtonLocaleKey = 'button.save';
  public cancelButtonLocaleKey = 'button.cancel';
  public translateParams: any;

  public inputValue = '';
  public inputControl: UntypedFormControl;
  public inputPlaceholder = '';
  public inputType = '';
  public originalValue = '';

  constructor(
    private _dialogRef: MatDialogRef<SaveCancelDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    if (data.text) {
      this.text = data.text;
    }
    if (data.body) {
      this.body = data.body;
    }
    if (data.saveButtonLocale) {
      this.saveButtonLocaleKey = data.saveButtonLocale;
    }
    if (data.inputType) {
      this.inputType = data.inputType;
    }
    if (data.inputPlaceholder) {
      this.inputPlaceholder = data.inputPlaceholder;
    }
    if (data.inputControl) {
      this.inputControl = data.inputControl;
    }
    if (data.inputValue) {
      this.inputValue = data.inputValue;
      this.originalValue = this.inputValue;
    }
    if (data.textParams) {
      this.translateParams = { value: data.textParams };
    } else {
      this.translateParams = { value: '' };
    }
    if (data.bodyParams) {
      this.bodyParams = { value: data.bodyParams };
    } else {
      this.bodyParams = { value: '' };
    }
    if (data.cancelButtonLocale) {
      this.cancelButtonLocaleKey = data.cancelButtonLocale;
    }
  }

  get inputControlErrorKey() {
    if (!this.inputControl) return '';

    return this.inputControl.errors
      ? Object.keys(this.inputControl.errors)[0]
      : '';
  }

  get disableSaveButton() {
    if (!this.inputControl) return false;

    return this.inputControl.invalid || this.inputValue === this.originalValue;
  }

  onKeyDown(e: KeyboardEvent) {
    if (e.key === 'Enter') {
      this.close(true);
    }
  }

  cancel() {
    this._dialogRef.close(null);
  }

  close(save: boolean = true) {
    let response: any = save;

    if (save && this.inputType && this.inputControl) {
      response = this.inputValue;
    }
    // Delay on purpose to feel like you hit the correct option
    setTimeout(() => {
      this._dialogRef.close(response);
    }, 300);
  }
}
