import { Injectable } from '@angular/core';

import { Dictionary } from '@ngrx/entity/src/models';
import { select, Store } from '@ngrx/store';
import { MingaPermission } from 'libs/domain';
import { gateway } from 'libs/generated-grpc-web';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import {
  filter,
  first,
  map,
  switchMap,
  take,
  withLatestFrom,
} from 'rxjs/operators';

import { AuthInfoService } from '@app/src/app/minimal/services/AuthInfo';
import { SentryService } from '@app/src/app/minimal/services/Sentry/Sentry.service';
import { PermissionsService } from '@app/src/app/permissions';
import {
  MingaStoreFacadeService,
  MingaSettingsService,
} from '@app/src/app/store/Minga/services';
import { NotificationActions } from '@app/src/app/store/Notifications/actions/notifications.actions';
import { NotificationStoreModel } from '@app/src/app/store/Notifications/model/notification.model';
import { NotificationsState } from '@app/src/app/store/Notifications/selectors';

import {
  GroupCollectionActions,
  GroupDetailsActions,
  GroupFormActions,
  GroupMemberActions,
} from '../../actions';
import {
  Group,
  MingaGroupMemberRank,
  resolveMyGroupStatusRank,
} from '../../models/Group';
import { GroupDetails } from '../../models/GroupDetails';
import {
  getAllGroupDetails,
  getAllGroups,
  getAllGroupsForSchool,
  getGroupEntities,
  getGroupsByCategoryName,
  getGroupsExpires,
  getGroupsLoaded,
  getGroupsLoading,
  getLoadingGroupForm,
  getMyGroups,
  getMyParentGroups,
  getParentGroups,
  getTotalGroups,
  getTotalMyGroups,
  getTotalSchoolGroups,
} from '../../selectors';

/**
 * Service to interact with the groups store. Provides selectors,
 * dispatchers and utility functions.
 */
@Injectable({ providedIn: 'root' })
export class GroupsFacadeService {
  private currentGroupHash$: BehaviorSubject<string> = new BehaviorSubject('');

  readonly groups$ = this.store.pipe(select(getAllGroups));
  readonly groupsMap$ = this.store.pipe(select(getGroupEntities));
  readonly allGroupDetails$ = this.store.pipe(select(getAllGroupDetails));
  readonly totalGroups$ = this.store.pipe(select(getTotalGroups));
  readonly myGroupsTotalGroups$ = this.store.pipe(select(getTotalMyGroups));
  readonly myGroups$ = this.store.pipe(select(getMyGroups));
  readonly groupsLoading$ = this.store.pipe(select(getGroupsLoading));
  readonly groupsLoaded$ = this.store.pipe(select(getGroupsLoaded));
  readonly groupNotifications$ = this.store.pipe(
    select(NotificationsState.selectAll),
  );
  readonly groupsExpires$ = this.store.pipe(select(getGroupsExpires));
  readonly groupFormLoading$ = this.store.pipe(select(getLoadingGroupForm));
  private readonly parentGroups$ = this.store.pipe(select(getParentGroups));
  private readonly myParentGroups$ = this.store.pipe(select(getMyParentGroups));
  readonly groupsForSchool$ = this.store.pipe(select(getAllGroupsForSchool));
  readonly groupsForSchoolTotal$ = this.store.pipe(
    select(getTotalSchoolGroups),
  );

  /// Key=categoryName
  private readonly categoryGroupObservables: Map<string, Observable<Group[]>>;

  constructor(
    private store: Store<any>,
    private authInfo: AuthInfoService,
    private permissions: PermissionsService,
    private mingaStore: MingaStoreFacadeService,
    private sentryService: SentryService,
    private _settingService: MingaSettingsService,
  ) {
    this.categoryGroupObservables = new Map();
  }

  /*******************************************
   * Keep track of what group context we are in.
   ********************************************/

  /**
   * Get an observable of the current group hash.
   */
  getCurrentGroupHashObservable(): Observable<string> {
    return this.currentGroupHash$.asObservable();
  }

  /**
   * Get the current value at this point in time of the current group hash.
   */
  getCurrentGroupHash(): string {
    return this.currentGroupHash$.getValue();
  }

  /**
   * Change which group is the current context.
   * @param hash
   */
  setCurrentGroupHash(hash: string) {
    this.currentGroupHash$.next(hash);
  }

  /**********************
   * Selectors
   **********************/

  /**
   * Get all Group Objects in the minga.
   */
  getAllGroups(): Observable<Group[]> {
    return this.groups$;
  }

  /**
   * Get all Groups that should show up in the all groups page
   * when 'district' mode is enabled and they have picked a school.
   */
  getAllGroupsForSchool(): Observable<Group[]> {
    return this.groupsForSchool$;
  }

  /**
   * Get all groups that are parent groups
   */
  getParentGroups(): Observable<Group[]> {
    return this.parentGroups$;
  }

  /**
   * Get all groups with a specific category
   */
  getGroupsByCategory(categoryName: string): Observable<Group[]> {
    let obs = this.categoryGroupObservables.get(categoryName);
    if (!obs) {
      obs = this.store.pipe(select(getGroupsByCategoryName(categoryName)));
      this.categoryGroupObservables.set(categoryName, obs);
    }

    return obs;
  }

  /**
   * Get all parentg groups the currently authenticated user is associated with
   */
  getMyParentGroups(): Observable<Group[]> {
    return this.myParentGroups$;
  }

  /**
   * Get a map/dictionary of the groups
   * with hash as key.
   */
  getAllGroupsMap(): Observable<Dictionary<Group>> {
    return this.groupsMap$;
  }

  getGroup(groupHash: string): Observable<Group | null> {
    return this.getAllGroups().pipe(
      map(groups => groups.find(group => group.hash == groupHash) || null),
    );
  }

  getGroupDetails(groupHash: string): Observable<GroupDetails | null> {
    return this.allGroupDetails$.pipe(
      map(groups => groups.find(group => group.hash == groupHash) || null),
    );
  }

  /**
   * Get an observable of the current group that is being viewed.
   */
  getCurrentGroup(): Observable<Group> {
    return this.getCurrentGroupHashObservable().pipe(
      switchMap(groupHash => this.getGroup(groupHash)),
      filter(group => !!group),
      map(group => group!),
    );
  }

  /**
   * Get an observable of the current group that is being viewed. If there is
   * is no current group being viewed `null` is emitted
   */
  getCurrentGroupIfSet(): Observable<Group | null> {
    return this.getCurrentGroupHashObservable().pipe(
      switchMap(groupHash => (groupHash ? this.getGroup(groupHash) : of(null))),
      map(group => group),
    );
  }

  /**
   * Get an observable of the details of the current group that is being viewed.
   */
  getCurrentGroupDetails(): Observable<GroupDetails> {
    return this.getCurrentGroupHashObservable().pipe(
      switchMap(groupHash => this.getGroupDetails(groupHash)),
      filter(groupDetails => !!groupDetails),
      map(groupDetails => groupDetails!),
    );
  }

  /**
   * Get how many groups are in the minga.
   */
  getTotalGroupsCount(): Observable<number> {
    return this.totalGroups$;
  }

  /**
   * Get how many groups are in the minga filtered by what
   * schools you are in.
   */
  getTotalSchoolGroupsCount(): Observable<number> {
    return this.groupsForSchoolTotal$;
  }

  /**
   * Get how many groups the user is a member of.
   */
  getTotalMyGroupsCount(): Observable<number> {
    return this.myGroupsTotalGroups$;
  }

  getMyGroups(): Observable<Group[]> {
    return this.myGroups$;
  }

  getGroupsLoading(): Observable<boolean> {
    return this.groupsLoading$;
  }

  getGroupsLoaded(): Observable<boolean> {
    return this.groupsLoaded$;
  }

  getGroupsExpires(): Observable<number> {
    return this.groupsExpires$;
  }

  getNewContentNotifications(): Observable<NotificationStoreModel[]> {
    return this.groupNotifications$.pipe(
      map(notifications => notifications.filter(item => !!item.groupHash)),
    );
  }

  /**
   * Returns all the current users group but sorts based on activity
   */
  getMyGroupsSortedByActivity(): Observable<Group[]> {
    return combineLatest([this.myGroups$, this.groupNotifications$]).pipe(
      take(1),
      map(([groups, newContent]) => {
        return groups.sort((a, b) => {
          const aHasNew = +newContent.some(n => n.groupHash === a.hash);
          const bHasNew = +newContent.some(n => n.groupHash === b.hash);

          return bHasNew - aHasNew;
        });
      }),
    );
  }

  /**********************
   * Action dispatchers
   **********************/

  /**
   * Load all the groups for the minga from the server,
   * but only get them if the  current data is old or non-existant.
   */
  async dispatchLoadAll() {
    const groupsEnabled = await this._settingService.isCommunityModuleEnabled();
    if (groupsEnabled) {
      this.store.dispatch(new GroupCollectionActions.LoadAllGroupsInfo());
    }
  }

  /**
   * Load all the groups for the minga from the server,
   * regardless of last time groups were grabbed.
   */
  dispatchLoadCollection() {
    this.store.dispatch(new GroupCollectionActions.LoadGroupsCollection());
  }

  dispatchLoadGroupsSuccess(groups: Group[]) {
    this.store.dispatch(
      new GroupCollectionActions.LoadGroupsSuccess(
        groups,
        this.getExpiryTime(),
      ),
    );
  }

  dispatchRequestToJoinGroup(
    group: Group,
    disableSuccessAction: boolean = false,
  ) {
    // signal to the component that the request is in progress.
    let rank: any;
    group.isPrivate
      ? (rank = MingaGroupMemberRank.LOADING)
      : (rank = MingaGroupMemberRank.MEMBER);
    const newGroup = Object.assign({}, group, { rank: rank });
    if (disableSuccessAction) {
      this.store.dispatch(new GroupMemberActions.JoinGroupNoConfirm(newGroup));
    } else {
      this.store.dispatch(new GroupMemberActions.JoinGroup(newGroup));
    }
  }

  dispatchCreateGroup(groupInputs: gateway.mingaGroup_pb.GroupInputs) {
    this.store.dispatch(new GroupFormActions.CreateGroupAction(groupInputs));
  }

  dispatchUpdateGroup(
    groupHash: string,
    groupInputs: gateway.mingaGroup_pb.GroupInputs,
  ) {
    this.store.dispatch(
      new GroupFormActions.UpdateGroupAction({ groupHash, groupInputs }),
    );
  }

  dispatchUpdateGroupLocally(group: Group) {
    this.store.dispatch(new GroupCollectionActions.UpdateGroupLocally(group));
  }

  dispatchCancelRequestToJoinGroup(group: Group) {
    this.store.dispatch(new GroupMemberActions.CancelJoinGroup(group));
  }

  dispatchLoadGroupDetails(groupHash: string) {
    if (groupHash) {
      this.store.dispatch(new GroupDetailsActions.LoadGroupDetails(groupHash));
    }
  }

  dispatchLeaveGroup(group: Group, noSuccessInterstitial: boolean = false) {
    this.store.dispatch(
      new GroupMemberActions.LeaveGroup(group, noSuccessInterstitial),
    );
  }

  dispatchRemoveGroupMember(
    group: Group,
    memberPersonHash: string,
    noSuccessInterstitial: boolean = false,
  ) {
    this.store.dispatch(
      new GroupMemberActions.RemoveGroupMember(
        group,
        memberPersonHash,
        noSuccessInterstitial,
      ),
    );
  }

  dispatchDeclineGroupMember(group: Group, memberPersonHash: string) {
    this.store.dispatch(
      new GroupMemberActions.DeclineGroupMember(group, memberPersonHash),
    );
  }

  dispatchAcceptGroupMember(group: Group, memberPersonHash: string) {
    this.store.dispatch(
      new GroupMemberActions.AcceptGroupMember(group, memberPersonHash),
    );
  }

  dispatchDeleteGroup(group: Group) {
    this.store.dispatch(new GroupCollectionActions.DeleteGroup(group));
  }

  dispatchMarkAsRead(groupHash: string) {
    const notification: NotificationStoreModel = { groupHash: groupHash };
    this.store.dispatch(
      new NotificationActions.MarkNotificationAsReadAction(notification),
    );
  }

  dispatchInvalidateGroupsCollection() {
    this.store.dispatch(
      new GroupCollectionActions.InvalidateGroupsCollection(),
    );
  }

  dispatchUpdateGroupMembers(
    groupHash: string,
    memberUpdates: Map<string, MingaGroupMemberRank | null>,
  ) {
    this.store.dispatch(
      new GroupMemberActions.UpdateGroupMembers(groupHash, memberUpdates),
    );
  }

  dispatchAddGroupMembers(groupHash: string, memberHashes: string[]) {
    this.store.dispatch(
      new GroupMemberActions.AddGroupMembers(groupHash, memberHashes),
    );
  }

  dispatchRemoveGroupMembers(groupHash: string, memberHashes: string[]) {
    this.store.dispatch(
      new GroupMemberActions.RemoveGroupMembers(groupHash, memberHashes),
    );
  }

  /**************************
   * Utility functions.
   **************************/

  /**
   * Remove the rank from the group, usually to remove the current
   * user from the group.
   * @param group
   */
  removeRankFromGroup(group: Group) {
    group.rank = null;
    return group;
  }

  /**
   * Get when the groups store cache should expire
   * triggering re-grabbing the data.
   */
  getExpiryTime(): number {
    // expiry time is 5 minutes.
    return new Date().getTime() + 300 * 1000;
  }

  getCurrentUserRankFromGroupDetails(
    groupDetails: GroupDetails,
  ): MingaGroupMemberRank | null {
    const person = this.authInfo.authInfo?.person;
    if (person) {
      const personInMembers = groupDetails.members.find(
        member => member.person!.personHash === person.personHash,
      );
      if (personInMembers) {
        return resolveMyGroupStatusRank(personInMembers.rank!);
      }
    }

    return null;
  }

  /**
   * Use a groupDetails object to update the corresponding Group model
   * in the group store.
   * @param groupDetails
   * @param groupsMap
   */
  updateGroupStoreFromGroupDetails(
    groupDetails: GroupDetails,
    groupsMap: Dictionary<Group>,
  ) {
    const newRank = this.getCurrentUserRankFromGroupDetails(groupDetails);
    const group = groupsMap[groupDetails.hash];
    if (group) {
      if (newRank) {
        if (group.rank !== newRank) {
          group.rank = newRank;
        }
      }
      group.memberCount = GroupDetails.memberCount(groupDetails);

      this.dispatchUpdateGroupLocally(group);
    }
  }

  canPostContentTypeToGroup(
    group: Group,
    contentPermission: MingaPermission,
  ): boolean {
    return this.canPostContentToGroup(group, contentPermission);
  }

  /**
   * Check if current user can post to a given group, can check content type
   * permission as well.
   * @param group group to check roles agains
   * @param contentPermission content type permission to check against if group
   *   member and not an owner/superadmin (optional)
   */
  canPostContentToGroup(
    group: Group,
    contentPermission?: MingaPermission,
  ): boolean {
    let canPostToGroup =
      Group.isOwner(group) ||
      this.permissions.hasPermission(MingaPermission.SUPERADMIN);

    // check if group member can post to group
    if (!canPostToGroup && Group.isMember(group) && group.allowedRoleTypes) {
      // if content type permission added, check it
      if (
        contentPermission &&
        !this.permissions.hasPermission(contentPermission)
      ) {
        return false;
      }
      // chech if user has role that is allowed in the group
      for (let groupRole of group.allowedRoleTypes) {
        if (this.permissions.hasRoleType(groupRole)) {
          canPostToGroup = true;
          break;
        }
      }
    }
    return canPostToGroup;
  }

  /**
   * Get a list of groups, filtered as necessary
   * based on the district feature being enabled.
   */
  getAllGroupsList(): Observable<Group[]> {
    return combineLatest([
      this.mingaStore.observeDistrictFeatureEnabled(),
      this.getMyParentGroups(),
    ]).pipe(
      withLatestFrom(
        this.permissions.observePermission(
          MingaPermission.GROUP_ALLOW_ANY_PARENT_GROUP,
        ),
      ),
      switchMap(([[enabled, myParentGroups], canSeeAllParentGroups]) => {
        if (enabled && myParentGroups.length > 0 && !canSeeAllParentGroups) {
          return this.getAllGroupsForSchool();
        } else {
          return this.getAllGroups();
        }
      }),
    );
  }
  /**
   * Get the total # of groups, filtered as necessary
   * based on the district feature being enabled.
   */
  getAllGroupsListTotal(): Observable<number> {
    return combineLatest([
      this.mingaStore.observeDistrictFeatureEnabled(),
      this.getMyParentGroups(),
    ]).pipe(
      withLatestFrom(
        this.permissions.observePermission(
          MingaPermission.GROUP_ALLOW_ANY_PARENT_GROUP,
        ),
      ),
      switchMap(([[enabled, myParentGroups], canSeeAllParentGroups]) => {
        if (enabled && myParentGroups.length > 0 && !canSeeAllParentGroups) {
          return this.groupsForSchoolTotal$;
        } else {
          return this.totalGroups$;
        }
      }),
    );
  }

  /**
   * Get all parent group hashes as a promise.
   * If district feature is not enabled, returns null.
   */
  async getParentGroupHashes(): Promise<string[] | null> {
    const isDistrict = await this.mingaStore.hasDistrictFeatureEnabled();
    if (!isDistrict) {
      return null;
    }

    const parentGroups = await this.getMyParentGroups()
      .pipe(first())
      .toPromise();

    if (parentGroups) {
      return parentGroups.map(group => group.hash);
    }

    return null;
  }

  /**
   * If our store is missing this group, it must have been added since the last
   * time we updated the list, so lets get the list again.
   * @param groupHash
   * @returns
   */
  async loadGroupCollectionIfMissing(groupHash: string) {
    if (!groupHash) {
      return;
    }
    const group = await this.getGroup(groupHash).pipe(first()).toPromise();
    if (!group) {
      this.dispatchLoadCollection();
    }
  }

  public isMemberOrOwnerOfCurrentGroup(): Observable<boolean> {
    return this.getCurrentGroup().pipe(
      map(group => {
        return Group.isMember(group) || Group.isOwner(group);
      }),
    );
  }

  public canAccessCurrentGroupPolls(): Observable<boolean> {
    return combineLatest([
      this.isMemberOrOwnerOfCurrentGroup(),
      this.permissions.observePermission(MingaPermission.CONTENT_POLL_CREATE),
    ]).pipe(
      map(([isMemberOrOwner, contentPollCreatePerm]) => {
        return contentPollCreatePerm || isMemberOrOwner;
      }),
    );
  }

  public canAccessAllGroups(): Observable<boolean> {
    return this.permissions.observePermission(
      MingaPermission.CONTENT_GROUP_VIEWALL,
    );
  }
}
