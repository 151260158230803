import { ContentType, IContentEventMinimal } from 'libs/domain';
import { event_collection_pb } from 'libs/generated-grpc-ts';

export const fromIContentEventMinimal = (
  contentEvent: IContentEventMinimal,
): event_collection_pb.EventViewMinimal => {
  const message = new event_collection_pb.EventViewMinimal();

  message.setContentHash(contentEvent.hash);
  message.setName(contentEvent.contentTagTitle);
  message.setContextHash(contentEvent.contextHash);

  return message;
};

export const toIContentEventMinimal = (
  eventView: event_collection_pb.EventViewMinimal,
): IContentEventMinimal => {
  return {
    hash: eventView.getContentHash(),
    contentTagTitle: eventView.getName(),
    contentType: ContentType.EVENT,
    contextHash: eventView.getContextHash(),
  };
};
