import { Router } from '@angular/router';

import { gateway } from 'libs/generated-grpc-web';
import { BehaviorSubject } from 'rxjs';

import {
  GalleryLightboxComponent,
  GalleryLightboxDataSource,
  IGalleryLightboxItem,
} from '@app/src/app/components/Lightbox/GalleryLightbox';
import { MentionsService } from '@app/src/app/mentions';
import { AuthService } from '@app/src/app/minimal/services/Auth';
import { AuthInfoService } from '@app/src/app/minimal/services/AuthInfo';
import { PermissionsService } from '@app/src/app/permissions';

import { HomeGalleryComponent } from '../home-gallery.component';
import { GalleryItemWrapper } from './gallery-item-wrapper.utils';

export class MingaGallerySingleGalleryLightboxDataSource
  implements GalleryLightboxDataSource
{
  total$: BehaviorSubject<number>;
  items$: BehaviorSubject<IGalleryLightboxItem[]>;

  constructor(
    private _galleryRoute: HomeGalleryComponent,
    private _authService: AuthService,
    private _permissions: PermissionsService,
    private _router: Router,
    private _mentionsService: MentionsService,
    private _lightbox: GalleryLightboxComponent,
    private _authInfoService: AuthInfoService,
  ) {
    this.total$ = new BehaviorSubject<number>(0);
    this.items$ = new BehaviorSubject<IGalleryLightboxItem[]>([]);
  }

  hasNext() {
    return false;
  }

  hasPrevious() {
    return false;
  }

  next(): void {
    return;
  }

  previous(): void {
    return;
  }

  setGalleryItem(galleryItem: gateway.gallery_pb.GalleryItem.AsObject) {
    const wrapperItem = new GalleryItemWrapper(
      galleryItem,
      0,
      this._galleryRoute,
      this._authService,
      this._permissions,
      this._mentionsService,
      this._authInfoService,
    );
    this.items$.next([wrapperItem]);
    this.total$.next(1);
  }

  dispose() {
    this.items$.complete();
    this.total$.complete();
  }
}
