import { IMingaSubscription } from 'libs/domain';
import { MingaSubscriptionPlans } from 'libs/domain';

export const sortMingaSubscriptions = (
  subscriptions: IMingaSubscription[],
): IMingaSubscription[] => {
  if (!subscriptions || subscriptions.length === 0) return [];

  const freeSubscription = subscriptions.find(
    subscription => subscription.planId === MingaSubscriptionPlans.SCHOOL_FREE,
  );
  if (!freeSubscription)
    return subscriptions.sort((a, b) => a.mingaSize - b.mingaSize);
  const freeSubIndex = subscriptions.indexOf(freeSubscription);
  subscriptions.splice(freeSubIndex, 1);
  subscriptions.sort((a, b) => a.mingaSize - b.mingaSize);
  subscriptions.push(freeSubscription);

  return subscriptions;
};
