// source: user_list/user_list_member.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

import * as jspb from 'google-protobuf';
import * as user_list_user_list_pb from '../user_list/user_list_pb';
export namespace ListMembersRequest {
  export interface AsObject {
    userListId: number;
  }
}
export class ListMembersRequest extends jspb.Message {
  static readonly displayName = "ListMembersRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): ListMembersRequest.AsObject {
    return ListMembersRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!ListMembersRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: ListMembersRequest) {
    var f: any;
    var obj: any = {
      userListId: jspb.Message.getFieldWithDefault(msg, 1, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!ListMembersRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new ListMembersRequest;
    return ListMembersRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!ListMembersRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!ListMembersRequest}
   */
  static deserializeBinaryFromReader(msg: ListMembersRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {number} */ (reader.readInt32());
        msg.setUserListId(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!ListMembersRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: ListMembersRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getUserListId();
    if (f !== 0) {
      writer.writeInt32(
        1,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    ListMembersRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional int32 user_list_id = 1;
   * @return {number}
   */
  getUserListId(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
  };


  /** @param {number} value */
  setUserListId(value: number) {
    jspb.Message.setProto3IntField(this, 1, value);
  }


}
export namespace ListMembersResponse {
  export interface AsObject {
    membersList: user_list_user_list_pb.UserList.Member.AsObject[];
  }
}
export class ListMembersResponse extends jspb.Message {
  static readonly displayName = "ListMembersResponse";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [1];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, ListMembersResponse.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): ListMembersResponse.AsObject {
    return ListMembersResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!ListMembersResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: ListMembersResponse) {
    var f: any;
    var obj: any = {
      membersList: jspb.Message.toObjectList(msg.getMembersList(),
      user_list_user_list_pb.UserList.Member.toObject, includeInstance)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!ListMembersResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new ListMembersResponse;
    return ListMembersResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!ListMembersResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!ListMembersResponse}
   */
  static deserializeBinaryFromReader(msg: ListMembersResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new user_list_user_list_pb.UserList.Member;
        reader.readMessage(value1,user_list_user_list_pb.UserList.Member.deserializeBinaryFromReader);
        msg.addMembers(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!ListMembersResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: ListMembersResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getMembersList();
    if (f.length > 0) {
      writer.writeRepeatedMessage(
        1,
        f,
        user_list_user_list_pb.UserList.Member.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    ListMembersResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * repeated user_list.v1.UserList.Member members = 1;
   * @return {!Array<!UserList.Member>}
   */
  getMembersList(): user_list_user_list_pb.UserList.Member[] {
    return /** @type{!Array<!UserList.Member>} */ (
      jspb.Message.getRepeatedWrapperField(this, user_list_user_list_pb.UserList.Member, 1));
  }


  /** @param {!Array<!UserList.Member>} value */
  setMembersList(value?: user_list_user_list_pb.UserList.Member[]) {
    jspb.Message.setRepeatedWrapperField(this, 1, value);
  }


  /**
   * @param {!UserList.Member=} opt_value
   * @param {number=} opt_index
   * @return {!UserList.Member}
   */
  addMembers(opt_value?: user_list_user_list_pb.UserList.Member, opt_index?: number): user_list_user_list_pb.UserList.Member {
    return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, user_list_user_list_pb.UserList.Member, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearMembersList() {
    this.setMembersList([]);
  }


}
export namespace AddMemberRequest {
  export interface AsObject {
    userListId: number;
    hashList: string[];
  }
}
export class AddMemberRequest extends jspb.Message {
  static readonly displayName = "AddMemberRequest";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [2];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, AddMemberRequest.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): AddMemberRequest.AsObject {
    return AddMemberRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!AddMemberRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: AddMemberRequest) {
    var f: any;
    var obj: any = {
      userListId: jspb.Message.getFieldWithDefault(msg, 1, 0),
      hashList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!AddMemberRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new AddMemberRequest;
    return AddMemberRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!AddMemberRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!AddMemberRequest}
   */
  static deserializeBinaryFromReader(msg: AddMemberRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {number} */ (reader.readInt32());
        msg.setUserListId(value1);
        break;
      case 2:
        var value2 = /** @type {string} */ (reader.readString());
        msg.addHash(value2);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!AddMemberRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: AddMemberRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getUserListId();
    if (f !== 0) {
      writer.writeInt32(
        1,
        f
      );
    }
    f = message.getHashList();
    if (f.length > 0) {
      writer.writeRepeatedString(
        2,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    AddMemberRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional int32 user_list_id = 1;
   * @return {number}
   */
  getUserListId(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
  };


  /** @param {number} value */
  setUserListId(value: number) {
    jspb.Message.setProto3IntField(this, 1, value);
  }


  /**
   * repeated string hash = 2;
   * @return {!Array<string>}
   */
  getHashList(): string[] {
    return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
  };


  /** @param {!Array<string>} value */
  setHashList(value: string[]) {
    jspb.Message.setField(this, 2, value || []);
  }


  /**
   * @param {string} value
   * @param {number=} opt_index
   */
  addHash(value: string, opt_index?: number): void {
    jspb.Message.addToRepeatedField(this, 2, value, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearHashList() {
    this.setHashList([]);
  }


}
export namespace AddMemberResponse {
  export interface AsObject {
    userList?: user_list_user_list_pb.UserList.AsObject;
    memberList: user_list_user_list_pb.UserList.Member.AsObject[];
  }
}
export class AddMemberResponse extends jspb.Message {
  static readonly displayName = "AddMemberResponse";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [2];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, AddMemberResponse.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): AddMemberResponse.AsObject {
    return AddMemberResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!AddMemberResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: AddMemberResponse) {
    var f: any;
    var obj: any = {
      userList: (f = msg.getUserList()) && user_list_user_list_pb.UserList.toObject(includeInstance, f),
      memberList: jspb.Message.toObjectList(msg.getMemberList(),
      user_list_user_list_pb.UserList.Member.toObject, includeInstance)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!AddMemberResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new AddMemberResponse;
    return AddMemberResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!AddMemberResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!AddMemberResponse}
   */
  static deserializeBinaryFromReader(msg: AddMemberResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new user_list_user_list_pb.UserList;
        reader.readMessage(value1,user_list_user_list_pb.UserList.deserializeBinaryFromReader);
        msg.setUserList(value1);
        break;
      case 2:
        var value2 = new user_list_user_list_pb.UserList.Member;
        reader.readMessage(value2,user_list_user_list_pb.UserList.Member.deserializeBinaryFromReader);
        msg.addMember(value2);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!AddMemberResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: AddMemberResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getUserList();
    if (f != null) {
      writer.writeMessage(
        1,
        f,
        user_list_user_list_pb.UserList.serializeBinaryToWriter
      );
    }
    f = message.getMemberList();
    if (f.length > 0) {
      writer.writeRepeatedMessage(
        2,
        f,
        user_list_user_list_pb.UserList.Member.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    AddMemberResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional user_list.v1.UserList user_list = 1;
   * @return {?UserList}
   */
  getUserList(): user_list_user_list_pb.UserList {
    return /** @type{?UserList} */ (
      jspb.Message.getWrapperField(this, user_list_user_list_pb.UserList, 1));
  }


  /** @param {?UserList|undefined} value */
  setUserList(value?: user_list_user_list_pb.UserList) {
    jspb.Message.setWrapperField(this, 1, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearUserList() {
    this.setUserList(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasUserList(): boolean {
    return jspb.Message.getField(this, 1) != null;
  }


  /**
   * repeated user_list.v1.UserList.Member member = 2;
   * @return {!Array<!UserList.Member>}
   */
  getMemberList(): user_list_user_list_pb.UserList.Member[] {
    return /** @type{!Array<!UserList.Member>} */ (
      jspb.Message.getRepeatedWrapperField(this, user_list_user_list_pb.UserList.Member, 2));
  }


  /** @param {!Array<!UserList.Member>} value */
  setMemberList(value?: user_list_user_list_pb.UserList.Member[]) {
    jspb.Message.setRepeatedWrapperField(this, 2, value);
  }


  /**
   * @param {!UserList.Member=} opt_value
   * @param {number=} opt_index
   * @return {!UserList.Member}
   */
  addMember(opt_value?: user_list_user_list_pb.UserList.Member, opt_index?: number): user_list_user_list_pb.UserList.Member {
    return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, user_list_user_list_pb.UserList.Member, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearMemberList() {
    this.setMemberList([]);
  }


}
export namespace RemoveMemberRequest {
  export interface AsObject {
    userListId: number;
    hashList: string[];
  }
}
export class RemoveMemberRequest extends jspb.Message {
  static readonly displayName = "RemoveMemberRequest";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [2];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, RemoveMemberRequest.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): RemoveMemberRequest.AsObject {
    return RemoveMemberRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!RemoveMemberRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: RemoveMemberRequest) {
    var f: any;
    var obj: any = {
      userListId: jspb.Message.getFieldWithDefault(msg, 1, 0),
      hashList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!RemoveMemberRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new RemoveMemberRequest;
    return RemoveMemberRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!RemoveMemberRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!RemoveMemberRequest}
   */
  static deserializeBinaryFromReader(msg: RemoveMemberRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {number} */ (reader.readInt32());
        msg.setUserListId(value1);
        break;
      case 2:
        var value2 = /** @type {string} */ (reader.readString());
        msg.addHash(value2);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!RemoveMemberRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: RemoveMemberRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getUserListId();
    if (f !== 0) {
      writer.writeInt32(
        1,
        f
      );
    }
    f = message.getHashList();
    if (f.length > 0) {
      writer.writeRepeatedString(
        2,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    RemoveMemberRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional int32 user_list_id = 1;
   * @return {number}
   */
  getUserListId(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
  };


  /** @param {number} value */
  setUserListId(value: number) {
    jspb.Message.setProto3IntField(this, 1, value);
  }


  /**
   * repeated string hash = 2;
   * @return {!Array<string>}
   */
  getHashList(): string[] {
    return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
  };


  /** @param {!Array<string>} value */
  setHashList(value: string[]) {
    jspb.Message.setField(this, 2, value || []);
  }


  /**
   * @param {string} value
   * @param {number=} opt_index
   */
  addHash(value: string, opt_index?: number): void {
    jspb.Message.addToRepeatedField(this, 2, value, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearHashList() {
    this.setHashList([]);
  }


}
export namespace RemoveMemberResponse {
  export interface AsObject {
    userList?: user_list_user_list_pb.UserList.AsObject;
    memberList: user_list_user_list_pb.UserList.Member.AsObject[];
  }
}
export class RemoveMemberResponse extends jspb.Message {
  static readonly displayName = "RemoveMemberResponse";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [2];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, RemoveMemberResponse.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): RemoveMemberResponse.AsObject {
    return RemoveMemberResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!RemoveMemberResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: RemoveMemberResponse) {
    var f: any;
    var obj: any = {
      userList: (f = msg.getUserList()) && user_list_user_list_pb.UserList.toObject(includeInstance, f),
      memberList: jspb.Message.toObjectList(msg.getMemberList(),
      user_list_user_list_pb.UserList.Member.toObject, includeInstance)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!RemoveMemberResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new RemoveMemberResponse;
    return RemoveMemberResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!RemoveMemberResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!RemoveMemberResponse}
   */
  static deserializeBinaryFromReader(msg: RemoveMemberResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new user_list_user_list_pb.UserList;
        reader.readMessage(value1,user_list_user_list_pb.UserList.deserializeBinaryFromReader);
        msg.setUserList(value1);
        break;
      case 2:
        var value2 = new user_list_user_list_pb.UserList.Member;
        reader.readMessage(value2,user_list_user_list_pb.UserList.Member.deserializeBinaryFromReader);
        msg.addMember(value2);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!RemoveMemberResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: RemoveMemberResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getUserList();
    if (f != null) {
      writer.writeMessage(
        1,
        f,
        user_list_user_list_pb.UserList.serializeBinaryToWriter
      );
    }
    f = message.getMemberList();
    if (f.length > 0) {
      writer.writeRepeatedMessage(
        2,
        f,
        user_list_user_list_pb.UserList.Member.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    RemoveMemberResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional user_list.v1.UserList user_list = 1;
   * @return {?UserList}
   */
  getUserList(): user_list_user_list_pb.UserList {
    return /** @type{?UserList} */ (
      jspb.Message.getWrapperField(this, user_list_user_list_pb.UserList, 1));
  }


  /** @param {?UserList|undefined} value */
  setUserList(value?: user_list_user_list_pb.UserList) {
    jspb.Message.setWrapperField(this, 1, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearUserList() {
    this.setUserList(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasUserList(): boolean {
    return jspb.Message.getField(this, 1) != null;
  }


  /**
   * repeated user_list.v1.UserList.Member member = 2;
   * @return {!Array<!UserList.Member>}
   */
  getMemberList(): user_list_user_list_pb.UserList.Member[] {
    return /** @type{!Array<!UserList.Member>} */ (
      jspb.Message.getRepeatedWrapperField(this, user_list_user_list_pb.UserList.Member, 2));
  }


  /** @param {!Array<!UserList.Member>} value */
  setMemberList(value?: user_list_user_list_pb.UserList.Member[]) {
    jspb.Message.setRepeatedWrapperField(this, 2, value);
  }


  /**
   * @param {!UserList.Member=} opt_value
   * @param {number=} opt_index
   * @return {!UserList.Member}
   */
  addMember(opt_value?: user_list_user_list_pb.UserList.Member, opt_index?: number): user_list_user_list_pb.UserList.Member {
    return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, user_list_user_list_pb.UserList.Member, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearMemberList() {
    this.setMemberList([]);
  }


}
