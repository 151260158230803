// source: section/section.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

import * as jspb from 'google-protobuf';
import * as common_date_pb from '../common/date_pb';
export namespace Section {
  export interface AsObject {
    id: number;
    sourcedId: string;
    title: string;
    courseSourcedId: string;
    status: string;
    schoolSourcedId: string;
    classCode: string;
    classType: string;
    dateLastModified?: common_date_pb.DateTime.AsObject;
    location: string;
    subjectsList: string[];
    periodsList: string[];
    gradesList: string[];
    termSourcedIdsList: string[];
    resourceSourcedIdsList: string[];
  }
}
export class Section extends jspb.Message {
  static readonly displayName = "Section";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [11,12,13,14,15];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, Section.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): Section.AsObject {
    return Section.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!Section} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: Section) {
    var f: any;
    var obj: any = {
      id: jspb.Message.getFieldWithDefault(msg, 1, 0),
      sourcedId: jspb.Message.getFieldWithDefault(msg, 2, ""),
      title: jspb.Message.getFieldWithDefault(msg, 3, ""),
      courseSourcedId: jspb.Message.getFieldWithDefault(msg, 4, ""),
      status: jspb.Message.getFieldWithDefault(msg, 5, ""),
      schoolSourcedId: jspb.Message.getFieldWithDefault(msg, 6, ""),
      classCode: jspb.Message.getFieldWithDefault(msg, 7, ""),
      classType: jspb.Message.getFieldWithDefault(msg, 8, ""),
      dateLastModified: (f = msg.getDateLastModified()) && common_date_pb.DateTime.toObject(includeInstance, f),
      location: jspb.Message.getFieldWithDefault(msg, 10, ""),
      subjectsList: (f = jspb.Message.getRepeatedField(msg, 11)) == null ? undefined : f,
      periodsList: (f = jspb.Message.getRepeatedField(msg, 12)) == null ? undefined : f,
      gradesList: (f = jspb.Message.getRepeatedField(msg, 13)) == null ? undefined : f,
      termSourcedIdsList: (f = jspb.Message.getRepeatedField(msg, 14)) == null ? undefined : f,
      resourceSourcedIdsList: (f = jspb.Message.getRepeatedField(msg, 15)) == null ? undefined : f
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!Section}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new Section;
    return Section.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!Section} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!Section}
   */
  static deserializeBinaryFromReader(msg: Section, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {number} */ (reader.readInt32());
        msg.setId(value1);
        break;
      case 2:
        var value2 = /** @type {string} */ (reader.readString());
        msg.setSourcedId(value2);
        break;
      case 3:
        var value3 = /** @type {string} */ (reader.readString());
        msg.setTitle(value3);
        break;
      case 4:
        var value4 = /** @type {string} */ (reader.readString());
        msg.setCourseSourcedId(value4);
        break;
      case 5:
        var value5 = /** @type {string} */ (reader.readString());
        msg.setStatus(value5);
        break;
      case 6:
        var value6 = /** @type {string} */ (reader.readString());
        msg.setSchoolSourcedId(value6);
        break;
      case 7:
        var value7 = /** @type {string} */ (reader.readString());
        msg.setClassCode(value7);
        break;
      case 8:
        var value8 = /** @type {string} */ (reader.readString());
        msg.setClassType(value8);
        break;
      case 9:
        var value9 = new common_date_pb.DateTime;
        reader.readMessage(value9,common_date_pb.DateTime.deserializeBinaryFromReader);
        msg.setDateLastModified(value9);
        break;
      case 10:
        var value10 = /** @type {string} */ (reader.readString());
        msg.setLocation(value10);
        break;
      case 11:
        var value11 = /** @type {string} */ (reader.readString());
        msg.addSubjects(value11);
        break;
      case 12:
        var value12 = /** @type {string} */ (reader.readString());
        msg.addPeriods(value12);
        break;
      case 13:
        var value13 = /** @type {string} */ (reader.readString());
        msg.addGrades(value13);
        break;
      case 14:
        var value14 = /** @type {string} */ (reader.readString());
        msg.addTermSourcedIds(value14);
        break;
      case 15:
        var value15 = /** @type {string} */ (reader.readString());
        msg.addResourceSourcedIds(value15);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!Section} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: Section, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getId();
    if (f !== 0) {
      writer.writeInt32(
        1,
        f
      );
    }
    f = message.getSourcedId();
    if (f.length > 0) {
      writer.writeString(
        2,
        f
      );
    }
    f = message.getTitle();
    if (f.length > 0) {
      writer.writeString(
        3,
        f
      );
    }
    f = message.getCourseSourcedId();
    if (f.length > 0) {
      writer.writeString(
        4,
        f
      );
    }
    f = message.getStatus();
    if (f.length > 0) {
      writer.writeString(
        5,
        f
      );
    }
    f = message.getSchoolSourcedId();
    if (f.length > 0) {
      writer.writeString(
        6,
        f
      );
    }
    f = message.getClassCode();
    if (f.length > 0) {
      writer.writeString(
        7,
        f
      );
    }
    f = message.getClassType();
    if (f.length > 0) {
      writer.writeString(
        8,
        f
      );
    }
    f = message.getDateLastModified();
    if (f != null) {
      writer.writeMessage(
        9,
        f,
        common_date_pb.DateTime.serializeBinaryToWriter
      );
    }
    f = message.getLocation();
    if (f.length > 0) {
      writer.writeString(
        10,
        f
      );
    }
    f = message.getSubjectsList();
    if (f.length > 0) {
      writer.writeRepeatedString(
        11,
        f
      );
    }
    f = message.getPeriodsList();
    if (f.length > 0) {
      writer.writeRepeatedString(
        12,
        f
      );
    }
    f = message.getGradesList();
    if (f.length > 0) {
      writer.writeRepeatedString(
        13,
        f
      );
    }
    f = message.getTermSourcedIdsList();
    if (f.length > 0) {
      writer.writeRepeatedString(
        14,
        f
      );
    }
    f = message.getResourceSourcedIdsList();
    if (f.length > 0) {
      writer.writeRepeatedString(
        15,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    Section.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional int32 id = 1;
   * @return {number}
   */
  getId(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
  };


  /** @param {number} value */
  setId(value: number) {
    jspb.Message.setProto3IntField(this, 1, value);
  }


  /**
   * optional string sourced_id = 2;
   * @return {string}
   */
  getSourcedId(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
  };


  /** @param {string} value */
  setSourcedId(value: string) {
    jspb.Message.setProto3StringField(this, 2, value);
  }


  /**
   * optional string title = 3;
   * @return {string}
   */
  getTitle(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
  };


  /** @param {string} value */
  setTitle(value: string) {
    jspb.Message.setProto3StringField(this, 3, value);
  }


  /**
   * optional string course_sourced_id = 4;
   * @return {string}
   */
  getCourseSourcedId(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
  };


  /** @param {string} value */
  setCourseSourcedId(value: string) {
    jspb.Message.setProto3StringField(this, 4, value);
  }


  /**
   * optional string status = 5;
   * @return {string}
   */
  getStatus(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
  };


  /** @param {string} value */
  setStatus(value: string) {
    jspb.Message.setProto3StringField(this, 5, value);
  }


  /**
   * optional string school_sourced_id = 6;
   * @return {string}
   */
  getSchoolSourcedId(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
  };


  /** @param {string} value */
  setSchoolSourcedId(value: string) {
    jspb.Message.setProto3StringField(this, 6, value);
  }


  /**
   * optional string class_code = 7;
   * @return {string}
   */
  getClassCode(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
  };


  /** @param {string} value */
  setClassCode(value: string) {
    jspb.Message.setProto3StringField(this, 7, value);
  }


  /**
   * optional string class_type = 8;
   * @return {string}
   */
  getClassType(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
  };


  /** @param {string} value */
  setClassType(value: string) {
    jspb.Message.setProto3StringField(this, 8, value);
  }


  /**
   * optional minga.common.DateTime date_last_modified = 9;
   * @return {?DateTime}
   */
  getDateLastModified(): common_date_pb.DateTime {
    return /** @type{?DateTime} */ (
      jspb.Message.getWrapperField(this, common_date_pb.DateTime, 9));
  }


  /** @param {?DateTime|undefined} value */
  setDateLastModified(value?: common_date_pb.DateTime) {
    jspb.Message.setWrapperField(this, 9, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearDateLastModified() {
    this.setDateLastModified(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasDateLastModified(): boolean {
    return jspb.Message.getField(this, 9) != null;
  }


  /**
   * optional string location = 10;
   * @return {string}
   */
  getLocation(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
  };


  /** @param {string} value */
  setLocation(value: string) {
    jspb.Message.setProto3StringField(this, 10, value);
  }


  /**
   * repeated string subjects = 11;
   * @return {!Array<string>}
   */
  getSubjectsList(): string[] {
    return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 11));
  };


  /** @param {!Array<string>} value */
  setSubjectsList(value: string[]) {
    jspb.Message.setField(this, 11, value || []);
  }


  /**
   * @param {string} value
   * @param {number=} opt_index
   */
  addSubjects(value: string, opt_index?: number): void {
    jspb.Message.addToRepeatedField(this, 11, value, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearSubjectsList() {
    this.setSubjectsList([]);
  }


  /**
   * repeated string periods = 12;
   * @return {!Array<string>}
   */
  getPeriodsList(): string[] {
    return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 12));
  };


  /** @param {!Array<string>} value */
  setPeriodsList(value: string[]) {
    jspb.Message.setField(this, 12, value || []);
  }


  /**
   * @param {string} value
   * @param {number=} opt_index
   */
  addPeriods(value: string, opt_index?: number): void {
    jspb.Message.addToRepeatedField(this, 12, value, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearPeriodsList() {
    this.setPeriodsList([]);
  }


  /**
   * repeated string grades = 13;
   * @return {!Array<string>}
   */
  getGradesList(): string[] {
    return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 13));
  };


  /** @param {!Array<string>} value */
  setGradesList(value: string[]) {
    jspb.Message.setField(this, 13, value || []);
  }


  /**
   * @param {string} value
   * @param {number=} opt_index
   */
  addGrades(value: string, opt_index?: number): void {
    jspb.Message.addToRepeatedField(this, 13, value, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearGradesList() {
    this.setGradesList([]);
  }


  /**
   * repeated string term_sourced_ids = 14;
   * @return {!Array<string>}
   */
  getTermSourcedIdsList(): string[] {
    return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 14));
  };


  /** @param {!Array<string>} value */
  setTermSourcedIdsList(value: string[]) {
    jspb.Message.setField(this, 14, value || []);
  }


  /**
   * @param {string} value
   * @param {number=} opt_index
   */
  addTermSourcedIds(value: string, opt_index?: number): void {
    jspb.Message.addToRepeatedField(this, 14, value, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearTermSourcedIdsList() {
    this.setTermSourcedIdsList([]);
  }


  /**
   * repeated string resource_sourced_ids = 15;
   * @return {!Array<string>}
   */
  getResourceSourcedIdsList(): string[] {
    return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 15));
  };


  /** @param {!Array<string>} value */
  setResourceSourcedIdsList(value: string[]) {
    jspb.Message.setField(this, 15, value || []);
  }


  /**
   * @param {string} value
   * @param {number=} opt_index
   */
  addResourceSourcedIds(value: string, opt_index?: number): void {
    jspb.Message.addToRepeatedField(this, 15, value, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearResourceSourcedIdsList() {
    this.setResourceSourcedIdsList([]);
  }


}
export namespace GetSectionsRequest {
  export interface AsObject {
    limit: number;
    offset: number;
  }
}
export class GetSectionsRequest extends jspb.Message {
  static readonly displayName = "GetSectionsRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): GetSectionsRequest.AsObject {
    return GetSectionsRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!GetSectionsRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: GetSectionsRequest) {
    var f: any;
    var obj: any = {
      limit: jspb.Message.getFieldWithDefault(msg, 1, 0),
      offset: jspb.Message.getFieldWithDefault(msg, 2, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!GetSectionsRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new GetSectionsRequest;
    return GetSectionsRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!GetSectionsRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!GetSectionsRequest}
   */
  static deserializeBinaryFromReader(msg: GetSectionsRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {number} */ (reader.readInt32());
        msg.setLimit(value1);
        break;
      case 2:
        var value2 = /** @type {number} */ (reader.readInt32());
        msg.setOffset(value2);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!GetSectionsRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: GetSectionsRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getLimit();
    if (f !== 0) {
      writer.writeInt32(
        1,
        f
      );
    }
    f = message.getOffset();
    if (f !== 0) {
      writer.writeInt32(
        2,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    GetSectionsRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional int32 limit = 1;
   * @return {number}
   */
  getLimit(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
  };


  /** @param {number} value */
  setLimit(value: number) {
    jspb.Message.setProto3IntField(this, 1, value);
  }


  /**
   * optional int32 offset = 2;
   * @return {number}
   */
  getOffset(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
  };


  /** @param {number} value */
  setOffset(value: number) {
    jspb.Message.setProto3IntField(this, 2, value);
  }


}
export namespace GetSectionsResponse {
  export interface AsObject {
    sectionsList: Section.AsObject[];
  }
}
export class GetSectionsResponse extends jspb.Message {
  static readonly displayName = "GetSectionsResponse";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [1];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, GetSectionsResponse.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): GetSectionsResponse.AsObject {
    return GetSectionsResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!GetSectionsResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: GetSectionsResponse) {
    var f: any;
    var obj: any = {
      sectionsList: jspb.Message.toObjectList(msg.getSectionsList(),
      Section.toObject, includeInstance)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!GetSectionsResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new GetSectionsResponse;
    return GetSectionsResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!GetSectionsResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!GetSectionsResponse}
   */
  static deserializeBinaryFromReader(msg: GetSectionsResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new Section;
        reader.readMessage(value1,Section.deserializeBinaryFromReader);
        msg.addSections(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!GetSectionsResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: GetSectionsResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getSectionsList();
    if (f.length > 0) {
      writer.writeRepeatedMessage(
        1,
        f,
        Section.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    GetSectionsResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * repeated Section sections = 1;
   * @return {!Array<!Section>}
   */
  getSectionsList(): Section[] {
    return /** @type{!Array<!Section>} */ (
      jspb.Message.getRepeatedWrapperField(this, Section, 1));
  }


  /** @param {!Array<!Section>} value */
  setSectionsList(value?: Section[]) {
    jspb.Message.setRepeatedWrapperField(this, 1, value);
  }


  /**
   * @param {!Section=} opt_value
   * @param {number=} opt_index
   * @return {!Section}
   */
  addSections(opt_value?: Section, opt_index?: number): Section {
    return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, Section, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearSectionsList() {
    this.setSectionsList([]);
  }


}
export namespace CreateSectionRequest {
  export interface AsObject {
    section?: Section.AsObject;
  }
}
export class CreateSectionRequest extends jspb.Message {
  static readonly displayName = "CreateSectionRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): CreateSectionRequest.AsObject {
    return CreateSectionRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!CreateSectionRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: CreateSectionRequest) {
    var f: any;
    var obj: any = {
      section: (f = msg.getSection()) && Section.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!CreateSectionRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new CreateSectionRequest;
    return CreateSectionRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!CreateSectionRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!CreateSectionRequest}
   */
  static deserializeBinaryFromReader(msg: CreateSectionRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new Section;
        reader.readMessage(value1,Section.deserializeBinaryFromReader);
        msg.setSection(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!CreateSectionRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: CreateSectionRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getSection();
    if (f != null) {
      writer.writeMessage(
        1,
        f,
        Section.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    CreateSectionRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional Section section = 1;
   * @return {?Section}
   */
  getSection(): Section {
    return /** @type{?Section} */ (
      jspb.Message.getWrapperField(this, Section, 1));
  }


  /** @param {?Section|undefined} value */
  setSection(value?: Section) {
    jspb.Message.setWrapperField(this, 1, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearSection() {
    this.setSection(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasSection(): boolean {
    return jspb.Message.getField(this, 1) != null;
  }


}
export namespace CreateSectionResponse {
  export interface AsObject {
    section?: Section.AsObject;
  }
}
export class CreateSectionResponse extends jspb.Message {
  static readonly displayName = "CreateSectionResponse";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): CreateSectionResponse.AsObject {
    return CreateSectionResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!CreateSectionResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: CreateSectionResponse) {
    var f: any;
    var obj: any = {
      section: (f = msg.getSection()) && Section.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!CreateSectionResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new CreateSectionResponse;
    return CreateSectionResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!CreateSectionResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!CreateSectionResponse}
   */
  static deserializeBinaryFromReader(msg: CreateSectionResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new Section;
        reader.readMessage(value1,Section.deserializeBinaryFromReader);
        msg.setSection(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!CreateSectionResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: CreateSectionResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getSection();
    if (f != null) {
      writer.writeMessage(
        1,
        f,
        Section.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    CreateSectionResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional Section section = 1;
   * @return {?Section}
   */
  getSection(): Section {
    return /** @type{?Section} */ (
      jspb.Message.getWrapperField(this, Section, 1));
  }


  /** @param {?Section|undefined} value */
  setSection(value?: Section) {
    jspb.Message.setWrapperField(this, 1, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearSection() {
    this.setSection(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasSection(): boolean {
    return jspb.Message.getField(this, 1) != null;
  }


}
export namespace UpdateSectionRequest {
  export interface AsObject {
    section?: Section.AsObject;
  }
}
export class UpdateSectionRequest extends jspb.Message {
  static readonly displayName = "UpdateSectionRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): UpdateSectionRequest.AsObject {
    return UpdateSectionRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!UpdateSectionRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: UpdateSectionRequest) {
    var f: any;
    var obj: any = {
      section: (f = msg.getSection()) && Section.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!UpdateSectionRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new UpdateSectionRequest;
    return UpdateSectionRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!UpdateSectionRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!UpdateSectionRequest}
   */
  static deserializeBinaryFromReader(msg: UpdateSectionRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new Section;
        reader.readMessage(value1,Section.deserializeBinaryFromReader);
        msg.setSection(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!UpdateSectionRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: UpdateSectionRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getSection();
    if (f != null) {
      writer.writeMessage(
        1,
        f,
        Section.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    UpdateSectionRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional Section section = 1;
   * @return {?Section}
   */
  getSection(): Section {
    return /** @type{?Section} */ (
      jspb.Message.getWrapperField(this, Section, 1));
  }


  /** @param {?Section|undefined} value */
  setSection(value?: Section) {
    jspb.Message.setWrapperField(this, 1, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearSection() {
    this.setSection(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasSection(): boolean {
    return jspb.Message.getField(this, 1) != null;
  }


}
export namespace UpdateSectionResponse {
  export interface AsObject {
    section?: Section.AsObject;
  }
}
export class UpdateSectionResponse extends jspb.Message {
  static readonly displayName = "UpdateSectionResponse";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): UpdateSectionResponse.AsObject {
    return UpdateSectionResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!UpdateSectionResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: UpdateSectionResponse) {
    var f: any;
    var obj: any = {
      section: (f = msg.getSection()) && Section.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!UpdateSectionResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new UpdateSectionResponse;
    return UpdateSectionResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!UpdateSectionResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!UpdateSectionResponse}
   */
  static deserializeBinaryFromReader(msg: UpdateSectionResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new Section;
        reader.readMessage(value1,Section.deserializeBinaryFromReader);
        msg.setSection(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!UpdateSectionResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: UpdateSectionResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getSection();
    if (f != null) {
      writer.writeMessage(
        1,
        f,
        Section.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    UpdateSectionResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional Section section = 1;
   * @return {?Section}
   */
  getSection(): Section {
    return /** @type{?Section} */ (
      jspb.Message.getWrapperField(this, Section, 1));
  }


  /** @param {?Section|undefined} value */
  setSection(value?: Section) {
    jspb.Message.setWrapperField(this, 1, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearSection() {
    this.setSection(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasSection(): boolean {
    return jspb.Message.getField(this, 1) != null;
  }


}
export namespace DeleteSectionRequest {
  export interface AsObject {
    id: number;
  }
}
export class DeleteSectionRequest extends jspb.Message {
  static readonly displayName = "DeleteSectionRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): DeleteSectionRequest.AsObject {
    return DeleteSectionRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!DeleteSectionRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: DeleteSectionRequest) {
    var f: any;
    var obj: any = {
      id: jspb.Message.getFieldWithDefault(msg, 1, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!DeleteSectionRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new DeleteSectionRequest;
    return DeleteSectionRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!DeleteSectionRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!DeleteSectionRequest}
   */
  static deserializeBinaryFromReader(msg: DeleteSectionRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {number} */ (reader.readInt32());
        msg.setId(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!DeleteSectionRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: DeleteSectionRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getId();
    if (f !== 0) {
      writer.writeInt32(
        1,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    DeleteSectionRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional int32 id = 1;
   * @return {number}
   */
  getId(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
  };


  /** @param {number} value */
  setId(value: number) {
    jspb.Message.setProto3IntField(this, 1, value);
  }


}
export namespace DeleteSectionResponse {
  export interface AsObject {
  }
}
export class DeleteSectionResponse extends jspb.Message {
  static readonly displayName = "DeleteSectionResponse";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): DeleteSectionResponse.AsObject {
    return DeleteSectionResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!DeleteSectionResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: DeleteSectionResponse) {
    var f: any;
    var obj: any = {

    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!DeleteSectionResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new DeleteSectionResponse;
    return DeleteSectionResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!DeleteSectionResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!DeleteSectionResponse}
   */
  static deserializeBinaryFromReader(msg: DeleteSectionResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!DeleteSectionResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: DeleteSectionResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    DeleteSectionResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
}
