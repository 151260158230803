import { Injectable, OnDestroy } from '@angular/core';

import { RealtimeEventType } from 'libs/domain';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { SystemAlertModalService } from '@shared/components/system-alert-modal';

import { RealtimeEvents } from '../RealtimeEvents';
import { EventSubscription } from '../RealtimeEvents.types';

/**
 * Add any high level real time events here, these should be fairly generic,
 */
@Injectable({ providedIn: 'root' })
export class GenericEvents implements OnDestroy {
  private readonly _destroyedSubj = new ReplaySubject<void>(1);
  private _events: RealtimeEventType[] = [
    RealtimeEventType.GENERIC_NOTIFICATION,
  ];

  private _handlers: Record<
    RealtimeEventType.GENERIC_NOTIFICATION,
    (data: EventSubscription<RealtimeEventType>) => Promise<void>
  > = {
    [RealtimeEventType.GENERIC_NOTIFICATION]: data =>
      this._openSystemAlertModal(
        data as EventSubscription<RealtimeEventType.GENERIC_NOTIFICATION>,
      ),
  };

  constructor(
    private _realtimeEvents: RealtimeEvents,
    private _systemAlertModal: SystemAlertModalService,
  ) {}

  public setup() {
    this._realtimeEvents
      .observe(this._events)
      .pipe(takeUntil(this._destroyedSubj))
      .subscribe(data => {
        const handler = this._handlers[data.type];
        if (handler) {
          handler(data);
        }
      });
  }

  private async _openSystemAlertModal(
    data: EventSubscription<RealtimeEventType.GENERIC_NOTIFICATION>,
  ): Promise<void> {
    const { payload } = data;

    if (!payload.systemAlertData) return;

    try {
      // we have to parse this because we were storing the detailed message as a
      // string (not array) to appease our Firestore overloads
      const detailedMessage = JSON.parse(
        (payload.systemAlertData.detailedMessage as any) || '',
      );
      await this._systemAlertModal.open({
        ...payload.systemAlertData,
        modalType: payload.systemAlertData.modalType as any,
        detailedMessage,
      });
    } catch (error) {
      console.error('Error opening system alert modal', error);
    }
  }

  ngOnDestroy(): void {
    this._destroyedSubj.next();
    this._destroyedSubj.complete();
  }
}
