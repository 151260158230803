import { ChangeDetectionStrategy, Component } from '@angular/core';

import { FtmReportsStaffDatasourceService } from '../../services';

@Component({
  selector: 'mg-ftm-reports-staff',
  templateUrl: './ftm-reports-staff.component.html',
  styleUrls: ['./ftm-reports-staff.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [FtmReportsStaffDatasourceService],
})
export class FtmReportsStaffComponent {
  constructor(public ds: FtmReportsStaffDatasourceService) {}
}
