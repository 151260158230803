// GENERATED CODE -- DO NOT EDIT!

import {grpc} from '@improbable-eng/grpc-web';

import * as content_mingaGroup_pb from '../content/mingaGroup_pb';
import * as common_delta_pb from '../common/delta_pb';
import * as common_date_pb from '../common/date_pb';
import * as gateway_moderation_pb from '../gateway/moderation_pb';

export class MingaGroupContent {
  static readonly serviceName = "group.content.MingaGroupContent";

  static readonly AddProgram = {
    methodName: "AddProgram",
    service: MingaGroupContent,
    requestStream: false,
    responseStream: false,
    requestType: content_mingaGroup_pb.MingaGroupAddProgram,
    responseType: content_mingaGroup_pb.MingaGroupProgramResult,
  };

  static readonly RemoveProgram = {
    methodName: "RemoveProgram",
    service: MingaGroupContent,
    requestStream: false,
    responseStream: false,
    requestType: content_mingaGroup_pb.MingaGroupRemoveProgram,
    responseType: content_mingaGroup_pb.MingaGroupProgramResult,
  };

  static readonly AddContent = {
    methodName: "AddContent",
    service: MingaGroupContent,
    requestStream: false,
    responseStream: false,
    requestType: content_mingaGroup_pb.MingaGroupAddContent,
    responseType: content_mingaGroup_pb.MingaGroupContentResult,
  };

  static readonly RemoveContent = {
    methodName: "RemoveContent",
    service: MingaGroupContent,
    requestStream: false,
    responseStream: false,
    requestType: content_mingaGroup_pb.MingaGroupRemoveContent,
    responseType: content_mingaGroup_pb.MingaGroupContentResult,
  };

  static readonly MoveContent = {
    methodName: "MoveContent",
    service: MingaGroupContent,
    requestStream: false,
    responseStream: false,
    requestType: content_mingaGroup_pb.MingaGroupMoveContent,
    responseType: content_mingaGroup_pb.MingaGroupContentResult,
  };

  static readonly UnpinContent = {
    methodName: "UnpinContent",
    service: MingaGroupContent,
    requestStream: false,
    responseStream: false,
    requestType: content_mingaGroup_pb.MingaGroupUnpinContent,
    responseType: content_mingaGroup_pb.MingaGroupUnpinContentResult,
  };

}

export class MingaGroupContentClient {
  addProgram() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  removeProgram() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  addContent() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  removeContent() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  moveContent() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  unpinContent() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
}
