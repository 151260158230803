import { MingaRoleType } from 'libs/domain';

import { IUserRole } from './interface';
import { AttendeeRole } from './internal/AttendeeRole';
import { DistrictLeaderRole } from './internal/DistrictLeaderRole';
import { DistrictManagerRole } from './internal/DistrictManagerRole';
import { KioskRole } from './internal/KioskRole';
import { ManagerRole } from './internal/ManagerRole';
import { OwnerRole } from './internal/OwnerRole';
import { ParentRole } from './internal/ParentRole';
import { ReadOnlyRole } from './internal/ReadOnlyRole';
import { SchoolManagerRole } from './internal/SchoolManagerRole';
import { StaffRole } from './internal/StaffRole';
import { StudentLeaderRole } from './internal/StudentLeaderRole';
import { StudentRole } from './internal/StudentRole';
import { SuperAdminRole } from './internal/SuperAdminRole';
import { TeacherRole } from './internal/TeacherRole';

const roleTypeMap = {
  [MingaRoleType.ATTENDEE]: new AttendeeRole(),
  [MingaRoleType.MANAGER]: new ManagerRole(),
  [MingaRoleType.OWNER]: new OwnerRole(),
  [MingaRoleType.PARENT]: new ParentRole(),
  [MingaRoleType.READ_ONLY]: new ReadOnlyRole(),
  [MingaRoleType.STUDENT_LEADER]: new StudentLeaderRole(),
  [MingaRoleType.STUDENT]: new StudentRole(),
  [MingaRoleType.SUPERADMIN]: new SuperAdminRole(),
  [MingaRoleType.TEACHER]: new TeacherRole(),
  [MingaRoleType.STAFF]: new StaffRole(),
  [MingaRoleType.SCHOOL_MANAGER]: new SchoolManagerRole(),
  [MingaRoleType.DISTRICT_LEADER]: new DistrictLeaderRole(),
  [MingaRoleType.DISTRICT_MANAGER]: new DistrictManagerRole(),
  [MingaRoleType.KIOSK]: new KioskRole(),
};

export const getRole = (role: MingaRoleType): IUserRole => {
  if (roleTypeMap[role]) {
    return roleTypeMap[role];
  }

  throw new Error(`Role type '${role}' does not exist`);
};

export * from './interface';
