import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import * as day from 'dayjs';
import * as xlsx from 'xlsx';

import { downloadjs } from '@app/src/app/util/downloadjs';

export interface IResolveListUploadErrorsDialogData {
  errors?: IListUploadDialogErrorRow[];
  columnNames?: string[];
  dialogText?: string;
}

export interface IListUploadDialogErrorRow {
  recordIndex: number;
  errorMessage: string | string[];
  record: string[];
}
@Component({
  selector: 'mg-resolve-upload-errors-dialog',
  templateUrl: './ResolveListUploadErrorsDialog.component.html',
  styleUrls: ['./ResolveListUploadErrorsDialog.component.scss'],
})
export class ResolveListUploadErrorsDialogComponent {
  errors: IListUploadDialogErrorRow[] = [];

  constructor(
    private matDialog: MatDialogRef<ResolveListUploadErrorsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IResolveListUploadErrorsDialogData,
  ) {
    if (data.errors) {
      this.errors = data.errors;
    }
  }

  goToError(index: number) {
    if (index === -1) return;
    this.matDialog.close(index);
  }

  private _createXlsxWorkbook() {
    const workBook = xlsx.utils.book_new();
    const headers = this.data.columnNames || [];

    headers.push('Errors');

    let newRows = this.data.errors?.map((errRecord, index) => {
      const record = errRecord.record;
      let errMsg = '';

      if (Array.isArray(errRecord.errorMessage)) {
        errMsg = errRecord.errorMessage.join('\n');
      } else {
        errMsg = errRecord.errorMessage;
      }
      record.push(errMsg);

      return record;
    });

    if (!newRows) {
      newRows = [];
    }

    // add headers to top.
    newRows.unshift(headers);

    const sheet = xlsx.utils.aoa_to_sheet(newRows, {
      cellDates: true,
    });

    if (!sheet['!cols']) {
      sheet['!cols'] = [];
    }

    xlsx.utils.book_append_sheet(workBook, sheet);

    return workBook;
  }

  clearErrors() {
    this.matDialog.close(true);
  }

  downloadXlsx() {
    const workBook = this._createXlsxWorkbook();
    const date = day().format('YYYY-MM-DD');

    xlsx.writeFile(workBook, `${date}-minga-upload-errors.xlsx`);
  }

  downloadCsv() {
    const workBook = this._createXlsxWorkbook();

    const csvData = xlsx.utils.sheet_to_csv(
      workBook.Sheets[workBook.SheetNames[0]],
    );

    const date = day().format('YYYY-MM-DD');

    downloadjs(csvData, `${date}-minga-upload-errors.csv`, 'text/csv');
  }
}
