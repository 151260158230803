import { MingaAppRoutes } from '@app/src/app/types';

import { KioskCategorySelectorComponent } from './components/kiosk-category-selector/kiosk-category-selector.component';
import { KioskCheckInComponent } from './components/kiosk-check-in/kiosk-check-in.component';
import { KioskHallPassComponent } from './components/kiosk-hall-pass/kiosk-hall-pass.component';
import { KioskRoute } from './constants';
import { KioskGuard, KioskCategoryGuard } from './guards';
import { KioskComponent } from './kiosk.component';

export const KIOSK_ROUTES: MingaAppRoutes = [
  {
    path: '',
    component: KioskComponent,
    data: {
      title: 'Kiosk',
    },
    children: [
      // Category selector
      {
        path: '',
        component: KioskCategorySelectorComponent,
        canActivate: [KioskGuard],
        data: {
          title: 'Select',
          navigationVisibility: false,
        },
      },
      // Hall pass
      {
        path: KioskRoute.HALL_PASS,
        component: KioskHallPassComponent,
        canActivate: [KioskCategoryGuard],
        data: {
          title: 'Hall Pass',
          category: 'hall-pass',
        },
      },
      // Check in
      {
        path: KioskRoute.CHECK_IN,
        component: KioskCheckInComponent,
        canActivate: [KioskCategoryGuard],
        data: {
          title: 'Check In',
          category: 'check-in',
        },
      },
    ],
  },
];
