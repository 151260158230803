import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';

import { Observable, ReplaySubject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

import { PsData } from '@modules/people-selector';

@Component({
  selector: 'mg-automation-counter-reset',
  templateUrl: './automation-counter-reset.component.html',
  styleUrls: ['./automation-counter-reset.component.scss'],
})
export class AutomationCounterResetComponent implements OnInit {
  /** General Subjects */
  private _destroyedSubject = new ReplaySubject<void>(1);

  /** Form Controls */
  public readonly form = this._fb.group({
    automationIds: [[], [Validators.required]],
    count: [0, [Validators.required, Validators.min(0)]],
  });

  public selectionSize$: Observable<number>;

  @Input() type: 'all' | 'selection' | 'report';

  @Input() countFC: UntypedFormControl;

  @Input() selection$: Observable<PsData[]>;

  @Input() isLoading$: Observable<boolean>;

  @Output() editSelection = new EventEmitter<PsData[]>();

  constructor(private _fb: UntypedFormBuilder) {}

  ngOnInit(): void {
    if (this.selection$) {
      this.selectionSize$ = this.selection$.pipe(
        takeUntil(this._destroyedSubject),
        map(selection => selection.length),
      );
    }
  }

  /** Computed Values */
  get canSubmit(): boolean {
    return this.countFC.dirty && this.countFC.valid;
  }
}
