import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';

import * as day from 'dayjs';
import { IConsequenceType } from 'libs/domain';
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { MgValidators } from '@app/src/app/input/validators';

import {
  CONSEQUENCE_FIELDS,
  CONSEQUENCE_GROUP,
  MyClassMessages,
} from '../../constants/tt-my-class.constants';
import { AssignmentType } from '../../types/tt-my-class.types';

@Component({
  selector: 'mg-tt-consequence-fields',
  templateUrl: './tt-consequence-fields.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TtConsequenceFieldsComponent implements OnInit, OnDestroy {
  @Input() form: UntypedFormGroup;
  @Input() set type(consequenceType: IConsequenceType) {
    if (consequenceType) {
      this._consequenceTypeSubject.next(consequenceType);
    }
  }

  private _consequenceTypeSubject = new BehaviorSubject<IConsequenceType>(null);
  public consequenceType$ = this._consequenceTypeSubject.asObservable();
  private _destroyedSubject = new ReplaySubject<void>(1);
  public today = day();
  public group: UntypedFormGroup;
  public CONSEQUENCE_FIELDS = CONSEQUENCE_FIELDS;
  public MESSAGES = MyClassMessages;

  constructor(private _fb: UntypedFormBuilder) {}

  ngOnInit(): void {
    this._setFormGroup();

    this.consequenceType$
      .pipe(takeUntil(this._destroyedSubject))
      .subscribe(consequenceType => {
        this._onConsequenceTypeChange(consequenceType);
      });
  }

  ngOnDestroy(): void {
    this.form.removeControl(AssignmentType.CONSEQUENCE);
    this.form.updateValueAndValidity();
    this._destroyedSubject.next();
    this._destroyedSubject.complete();
  }

  private _setFormGroup() {
    this.group = this._fb.group(CONSEQUENCE_GROUP());
    this.form.addControl(AssignmentType.CONSEQUENCE, this.group);
    this.form.updateValueAndValidity();
  }

  private _onConsequenceTypeChange(consequenceType: IConsequenceType) {
    const dateControl = this.group.get(CONSEQUENCE_FIELDS.DATE);

    if (consequenceType.enableDueDate) {
      dateControl.setValue(this.today.add(1, 'day'));
      dateControl.setValidators([
        Validators.required,
        MgValidators.DateNotInPastValidator,
      ]);
    } else {
      dateControl.setValue(null);
      dateControl.setValidators(null);
      dateControl.markAsUntouched();
    }

    dateControl.updateValueAndValidity();
    this.group.updateValueAndValidity();
    this.form.updateValueAndValidity();
  }
}
