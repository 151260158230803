// GENERATED CODE -- DO NOT EDIT!

import {grpc} from '@improbable-eng/grpc-web';

import * as studio_content_studio_stock_stickers_pb from '../studio_content/studio_stock_stickers_pb';
import * as image_image_pb from '../image/image_pb';
import * as common_stream_pb from '../common/stream_pb';

export class StudioStockStickers {
  static readonly serviceName = "StudioStockStickers";

  static readonly StreamStockStickers = {
    methodName: "StreamStockStickers",
    service: StudioStockStickers,
    requestStream: false,
    responseStream: true,
    requestType: common_stream_pb.StreamID,
    responseType: studio_content_studio_stock_stickers_pb.StudioStockStickersStreamResponse,
  };

  static readonly StreamStockStickersControl = {
    methodName: "StreamStockStickersControl",
    service: StudioStockStickers,
    requestStream: false,
    responseStream: false,
    requestType: common_stream_pb.StreamControl,
    responseType: common_stream_pb.StreamControlResponse,
  };

}

export class StudioStockStickersClient {
  streamStockStickers() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  streamStockStickersControl() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
}
