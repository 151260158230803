// GENERATED CODE -- DO NOT EDIT!

import {grpc} from '@improbable-eng/grpc-web';

import * as period_period_pb from '../period/period_pb';

export class PeriodManager {
  static readonly serviceName = "period.PeriodManager";

  static readonly GetPeriods = {
    methodName: "GetPeriods",
    service: PeriodManager,
    requestStream: false,
    responseStream: false,
    requestType: period_period_pb.GetPeriodsRequest,
    responseType: period_period_pb.GetPeriodsResponse,
  };

}

export class PeriodManagerClient {
  getPeriods() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
}
