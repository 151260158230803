// GENERATED CODE -- DO NOT EDIT!

import {grpc} from '@improbable-eng/grpc-web';

import * as content_program_pb from '../content/program_pb';
import * as common_delta_pb from '../common/delta_pb';
import * as gateway_moderation_pb from '../gateway/moderation_pb';
import * as gateway_minga_pb from '../gateway/minga_pb';

export class ProgramManager {
  static readonly serviceName = "minga.content.ProgramManager";

  static readonly New = {
    methodName: "New",
    service: ProgramManager,
    requestStream: false,
    responseStream: false,
    requestType: content_program_pb.ProgramInfo,
    responseType: content_program_pb.ProgramSaveResult,
  };

  static readonly Update = {
    methodName: "Update",
    service: ProgramManager,
    requestStream: false,
    responseStream: false,
    requestType: content_program_pb.DeltaProgramInfo,
    responseType: content_program_pb.ProgramSaveResult,
  };

  static readonly GetInfo = {
    methodName: "GetInfo",
    service: ProgramManager,
    requestStream: false,
    responseStream: false,
    requestType: content_program_pb.ProgramInfoQuery,
    responseType: content_program_pb.ProgramInfo,
  };

  static readonly AddContent = {
    methodName: "AddContent",
    service: ProgramManager,
    requestStream: false,
    responseStream: false,
    requestType: content_program_pb.ProgramAddContent,
    responseType: content_program_pb.ProgramContentSaveResult,
  };

  static readonly RemoveContent = {
    methodName: "RemoveContent",
    service: ProgramManager,
    requestStream: false,
    responseStream: false,
    requestType: content_program_pb.ProgramRemoveContent,
    responseType: content_program_pb.ProgramContentSaveResult,
  };

  static readonly MoveContent = {
    methodName: "MoveContent",
    service: ProgramManager,
    requestStream: false,
    responseStream: false,
    requestType: content_program_pb.ProgramMoveContent,
    responseType: content_program_pb.ProgramContentSaveResult,
  };

  static readonly GetInstanceInfo = {
    methodName: "GetInstanceInfo",
    service: ProgramManager,
    requestStream: false,
    responseStream: false,
    requestType: content_program_pb.ProgramInstanceInfoQuery,
    responseType: content_program_pb.ProgramInfo,
  };

  static readonly GetDetails = {
    methodName: "GetDetails",
    service: ProgramManager,
    requestStream: false,
    responseStream: false,
    requestType: content_program_pb.ProgramInfoQuery,
    responseType: content_program_pb.ProgramDetails,
  };

  static readonly GetMingas = {
    methodName: "GetMingas",
    service: ProgramManager,
    requestStream: false,
    responseStream: false,
    requestType: content_program_pb.ProgramInfoQuery,
    responseType: content_program_pb.ProgramMingasResult,
  };

}

export class ProgramManagerClient {
  new() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  update() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  getInfo() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  addContent() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  removeContent() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  moveContent() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  getInstanceInfo() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  getDetails() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  getMingas() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
}
