import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';

import { MgButtonModule } from '@app/src/app/button';
import { MgIconModule } from '@app/src/app/icon';
import { MgSpinnerModule } from '@app/src/app/spinner';

import { GenericButtonModule } from '@shared/components/button';

import { EventConfirmationDialogComponent } from './EventConfirmationDialog.component';

@NgModule({
  imports: [
    // Minga dependencies
    MgSpinnerModule,
    MgButtonModule,
    MgIconModule,
    GenericButtonModule,

    // External dependencies
    CommonModule,
    FlexLayoutModule,
    MatCheckboxModule,
    FormsModule,
  ],
  declarations: [EventConfirmationDialogComponent],
  exports: [EventConfirmationDialogComponent],
})
export class EventConfirmationDialogModule {}
