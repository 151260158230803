<div
  class="section-header-container"
  fxLayout="row"
  fxLayout.xs="column"
  fxLayoutAlign="start start"
  fxLayoutAlign.lt-md="start center"
  fxLayoutAlign.xs="start stretch"
  fxLayoutGap.xs="20px">
  <div
    fxFlex="grow"
    [ngStyle.xs]="{ textAlign: 'center' }">
    <span class="section-header">{{ MESSAGES.SECTION_TITLE }}</span>
    <mg-tooltip [label]="MESSAGES.TOOLTIP_SCHEDULE"></mg-tooltip>
  </div>
</div>

<div>
  <mat-table
    class="list-table no-explicit-mobile-layout list-table-relaxed"
    [fxHide]="(tableData$ | async).length === 0"
    [dataSource]="tableData$ | async">
    <!-- Name Column -->
    <ng-container matColumnDef="name">
      <mat-header-cell *matHeaderCellDef>
        {{ MESSAGES.COLUMN_LABEL_LIST_NAME }}
      </mat-header-cell>
      <mat-cell *matCellDef="let item">
        <div class="multiline-ellipsis">
          <mg-text variant="body-sm-secondary">
            {{ item.name || MESSAGES.COLUMN_LABEL_LIST_NAME }}
          </mg-text>
        </div>
      </mat-cell>
    </ng-container>

    <!-- Days Active Column -->
    <ng-container matColumnDef="daysActive">
      <mat-header-cell *matHeaderCellDef>
        {{ MESSAGES.COLUMN_LABEL_DAYS_ACTIVE }}
      </mat-header-cell>
      <mat-cell *matCellDef="let item">
        <mg-weekday-toggle
          [readonly]="true"
          [initialValue]="item | getBlockDays">
        </mg-weekday-toggle>
      </mat-cell>
    </ng-container>

    <!-- Edit Column -->
    <ng-container matColumnDef="edit">
      <mat-header-cell *matHeaderCellDef>
        {{ MESSAGES.COLUMN_LABEL_EDIT }}
      </mat-header-cell>
      <mat-cell *matCellDef="let item">
        <mg-btn
          variant="icon"
          iconSet="minga"
          icon="mg-edit"
          (pressed)="hpmResBlackout.openEditModal({ id: item.id })">
        </mg-btn>
      </mat-cell>
    </ng-container>

    <!-- Status Column -->
    <ng-container matColumnDef="status">
      <mat-header-cell *matHeaderCellDef>
        {{ MESSAGES.COLUMN_LABEL_STATUS }}
      </mat-header-cell>
      <mat-cell *matCellDef="let item">
        <mg-form-toggle
          [id]="'hallpass-restrictions-click-acgive-blackout'"
          [value]="item?.active"
          (valueChange)="hpmResBlackout.updateStatus(item, $event)">
        </mg-form-toggle>
      </mat-cell>
    </ng-container>

    <!-- Tablet View  -->
    <ng-container matColumnDef="tablet">
      <mat-header-cell *matHeaderCellDef></mat-header-cell>
      <mat-cell *matCellDef="let item">
        <div
          class="tablet-view-container"
          fxLayout="column"
          fxLayoutGap="15px">
          <div
            fxLayout="row"
            fxLayoutAlign="start center"
            fxLayoutGap="20px">
            <div fxFlex="grow">
              <mg-text variant="header-sm">
                {{ item.name || MESSAGES.COLUMN_LABEL_LIST_NAME }}
              </mg-text>
            </div>
            <div fxFlex="nogrow">
              <mg-btn
                variant="icon"
                iconSet="minga"
                icon="mg-edit"
                (pressed)="hpmResBlackout.openEditModal({ id: item.id })">
              </mg-btn>
            </div>
          </div>
          <div
            fxLayout="row"
            fxLayoutAlign="space-between center">
            <mg-weekday-toggle
              [readonly]="true"
              [initialValue]="item | getBlockDays"
              (valueChanged)="hpmResBlackout.updateActiveDays(item, $event)">
            </mg-weekday-toggle>
            <div fxFlex="nogrow">
              <mg-form-toggle
                [value]="item?.active"
                (valueChange)="hpmResBlackout.updateStatus(item, $event)">
              </mg-form-toggle>
            </div>
          </div>
        </div>
      </mat-cell>
    </ng-container>

    <!-- Mobile View -->
    <ng-container matColumnDef="mobile">
      <mat-header-cell *matHeaderCellDef></mat-header-cell>
      <mat-cell *matCellDef="let item">
        <div class="tw-flex tw-justify-between tw-items-center tw-w-full">
          <mg-text
            variant="header-sm"
            [numberOfLines]="2">
            {{ item.name || MESSAGES.COLUMN_LABEL_LIST_NAME }}
          </mg-text>
          <div class="tw-flex tw-items-center">
            <mg-btn
              variant="icon"
              iconSet="minga"
              icon="mg-edit"
              (pressed)="hpmResBlackout.openEditModal({ id: item.id })">
            </mg-btn>
            <mg-form-toggle
              [value]="item?.active"
              (valueChange)="hpmResBlackout.updateStatus(item, $event)">
            </mg-form-toggle>
          </div>
        </div>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns$ | async">
    </mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns$ | async">
    </mat-row>
  </mat-table>
</div>
<div
  *ngIf="!(tableData$ | async)?.length"
  class="empty-state-container">
  <mg-empty-state
    [title]="'No results'"
    [subtitle]="'Create a new blackout schedule'">
  </mg-empty-state>
</div>
<div
  *ngIf="(tableData$ | async)?.length > 4"
  fxLayout="row"
  fxLayoutAlign="center center"
  [ngStyle]="{ 'marginTop.rem': 1 }"></div>
