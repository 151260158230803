import { date_pb } from 'libs/generated-grpc-ts';
import {
  gateway,
  challenge_response_pb,
  image_pb,
} from 'libs/generated-grpc-ts';
import { PersonViewMinimal } from 'libs/generated-grpc-ts';

import { asObjectToMessage } from '../';
import { CommentListPresentationMapper } from '../content';

export namespace ChallengePresentationMapper {
  export function fromPresentationToLongCardMessage(
    longCardData: gateway.content_views_pb.LongChallengeCardView.AsObject,
  ): gateway.content_views_pb.LongChallengeCardView {
    const longCard = new gateway.content_views_pb.LongChallengeCardView();

    longCard.setContentHash(longCardData.contentHash);
    longCard.setCommentCount(longCardData.commentCount);
    longCard.setTotalCommentCount(longCardData.commentCount);
    longCard.setBody(longCardData.body);
    longCard.setLikeCount(longCardData.likeCount);
    longCard.setPrivacy(longCardData.privacy);
    longCard.setTitle(longCardData.title);
    longCard.setChallengeType(longCardData.challengeType);
    longCard.setColor(longCardData.color);
    longCard.setPoints(longCardData.points);
    longCard.setAllowedRolesList(longCardData.allowedRolesList);
    longCard.setRequiresApproval(longCardData.requiresApproval);
    if (longCardData.dueDate) {
      longCard.setDueDate(
        asObjectToMessage(longCardData.dueDate, new date_pb.Date()),
      );
    }
    longCard.setHasResponded(longCardData.hasResponded);
    longCard.setRespondedPeopleCount(longCardData.respondedPeopleCount);
    longCard.setTimestamp(longCardData.timestamp);

    longCard.setResponsesSummaryList(
      longCardData.responsesSummaryList.map(item => {
        const msg = new gateway.content_views_pb.ChallengeResponseSummaryItem();
        msg.setBody(item.body);
        msg.setContentContextHash(item.contentContextHash);
        msg.setAuthorPersonView(
          asObjectToMessage(item.authorPersonView, new PersonViewMinimal()),
        );

        const imgInfo = item.featuredImage;
        if (imgInfo) {
          const imageInfo = new image_pb.ImageInfo();
          let sizeMap = imageInfo.getSizeMap();

          for (let [key, value] of imgInfo.sizeMap) {
            const imgSize = new image_pb.ImageSize();
            imgSize.setPath(value.path);
            imgSize.setWidth(value.width);
            imgSize.setHeight(value.height);

            sizeMap.set(key, imgSize);
          }

          msg.setFeaturedImage(imageInfo);
        }

        return msg;
      }),
    );

    if (longCardData && longCardData.cloudflareUid) {
      longCard.setCloudflareVideoUrl(longCardData.cloudflareVideoUrl);
      longCard.setCloudflareUid(longCardData.cloudflareUid);
      longCard.setCloudflareReady(longCardData.cloudflareReady);
    }
    const imgInfo = longCardData?.featuredImage;
    if (imgInfo) {
      const imageInfo = new image_pb.ImageInfo();
      let sizeMap = imageInfo.getSizeMap();

      for (let [key, value] of imgInfo.sizeMap) {
        const imgSize = new image_pb.ImageSize();
        imgSize.setPath(value.path);
        imgSize.setWidth(value.width);
        imgSize.setHeight(value.height);

        sizeMap.set(key, imgSize);
      }

      longCard.setFeaturedImage(imageInfo);
    }

    const badgeInfo = longCardData?.badgeImage;
    if (badgeInfo) {
      const imageInfo = new image_pb.ImageInfo();
      let sizeMap = imageInfo.getSizeMap();

      for (let [key, value] of badgeInfo.sizeMap) {
        const imgSize = new image_pb.ImageSize();
        imgSize.setPath(value.path);
        imgSize.setWidth(value.width);
        imgSize.setHeight(value.height);

        sizeMap.set(key, imgSize);
      }

      longCard.setBadgeImage(imageInfo);
    }

    longCard.setAuthorPersonView(
      asObjectToMessage(longCardData.authorPersonView, new PersonViewMinimal()),
    );

    if (longCardData?.allowedCommentingRoleTypesList) {
      longCard.setAllowedCommentingRoleTypesList(
        longCardData?.allowedCommentingRoleTypesList,
      );
    }
    if (longCardData.ownerGroupHash) {
      longCard.setOwnerGroupHash(longCardData.ownerGroupHash);
    }
    const comments =
      CommentListPresentationMapper.fromPresentationToCommentContentMessage(
        longCardData.commentList,
      );

    longCard.setCommentList(comments);

    return longCard;
  }
}
