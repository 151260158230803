import { IEventValidationError } from 'libs/domain';
import { event_pb } from 'libs/generated-grpc-ts';

export namespace IEventValidationErrorMapper {
  export function fromProto(
    error: event_pb.EventValidityError,
  ): IEventValidationError {
    return {
      name: error.getName(),
      personHash: error.getPersonHash(),
      error: error.getErrorCode(),
    };
  }
}
