export enum CustomSsoType {
  CLEVER = 'clever',
}

export interface User<T> {
  id: string;
  data?: T;
}

// Lets match interface response with what firebase validate method returns
export interface SsoResponse {
  uid: string;
  email?: string;
}

export type OAuthServiceImpl = {
  validateSso: (token: string) => Promise<SsoResponse>;
};
