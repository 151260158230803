import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { gateway } from 'libs/generated-grpc-web';

import { ContentEvents } from '@app/src/app/minimal/services/ContentEvents';
import { ContentState } from '@app/src/app/services/ContentState';

import { MgMiniCardBaseElement } from '../MgMiniCardBaseElement';

@Component({
  selector: 'mg-announcement-mini-card',
  templateUrl: './MgAnnouncementMiniCard.element.html',
  styleUrls: ['./MgAnnouncementMiniCard.element.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MgAnnouncementMiniCardElement extends MgMiniCardBaseElement {
  @Input()
  content?: gateway.content_views_pb.ShortAnnouncementCardView.AsObject;

  @Input()
  context: string = '';

  constructor(
    public contentevents: ContentEvents,
    public contentstate: ContentState,
  ) {
    super();
  }
}
