export const RESTRICTION_ERRORS = {
  GRADE: {
    title: 'Wrong grade',
    message: 'This person is not in a required grade',
  },
  GROUP: {
    title: 'Not group member',
    message: 'This person is not in a required group',
  },
  NO_ACCESS: {
    title: 'No access list',
    message: 'This person is currently on the no access list',
  },
  NOT_MEMBER: {
    title: 'Not a member',
    message: 'This person does not meet the membership requirement',
  },
  ROLE: {
    title: 'Wrong role',
    message: 'This person does not meet the role requirements',
  },
  STICKER: {
    title: 'No sticker',
    message: 'This person does not meet the sticker requirements',
  },
  NOT_REGISTERED: {
    title: 'Not registered',
    message: 'This person is not registered',
  },
  NOT_CHECKED_IN: {
    title: 'Not checked in',
    message: 'This person does meet prior check in requirements',
  },
  ALREADY_CHECKED_IN: {
    title: 'Already checked in',
    message: 'This person has already been checked in today',
  },
  DOESNT_EXIST: {
    title: 'Not found',
    message: 'Does not exist',
  },
  PERSON_DOESNT_EXIST: {
    title: 'Not found',
    message: 'This person does not exist',
  },
  NO_RULES: {
    title: 'No rules',
    message: 'Does not have any restrictions',
  },
  UNKNOWN: {
    title: 'Unknown error',
    message: 'An unexpected error has occured',
  },
  NO_SPACE: {
    title: 'No space available',
    message: 'The activity is full',
  },
  NO_TICKET: {
    title: 'Need a ticket',
    message: 'Need an event ticket to check in',
  },
  INVALID_CODE: {
    title: 'Invalid code',
    message: 'The code entered is invalid',
  },
  SYSTEM_ERROR: {
    title: 'System error',
    message: 'Unable to check in at the moment, please try again later',
  },
  NOT_AVAILABLE_IN_KIOSK: {
    title: 'Not available in kiosk',
    message: 'This check in is not enabled for kiosk use',
  },
  USER_LIST: {
    title: 'Not on list',
    message: 'This person is not on the required list',
  },
  MULTIPLE_HOMEROOMS: {
    title: 'Multiple homerooms',
    message: 'Multiple homerooms attached',
  },
  NO_HOMEROOM: {
    title: 'No homeroom',
    message: 'No homeroom attached',
  },
  NO_HOMEROOM_ACTIVITY: {
    title: 'No homeroom activity',
    message: 'No homeroom teacher activity',
  },
  GENERIC_HOMEROOM_ASSIGN: {
    title: 'Unable to assign homeroom activity',
    message: 'There was a problem assigning homeroom activity',
  },
} as const;

export const ACTIVITY_RESTRICTION_ERRORS_TITLES = {
  GRADE: {
    header: `This person doesn't match the activity restriction`,
  },
  GROUP: {
    header: `This person doesn't match the activity restriction`,
  },
  NO_ACCESS: {
    header: `This person doesn't match the activity restriction`,
  },
  NOT_MEMBER: {
    header: `This person doesn't match the activity restriction`,
  },
  ROLE: {
    header: `This person doesn't match the activity restriction`,
  },
  STICKER: {
    header: `This person doesn't match the activity restriction`,
  },
  NOT_REGISTERED: {
    header: `This person doesn't match the activity restriction`,
  },
  NOT_CHECKED_IN: {
    header: `This person doesn't match the activity restriction`,
  },
  ALREADY_CHECKED_IN: {
    header: `This person doesn't match the activity restriction`,
  },
  DOESNT_EXIST: {
    header: `This person doesn't match the activity restriction`,
  },
  PERSON_DOESNT_EXIST: {
    header: `This person doesn't match the activity restriction`,
  },
  NO_RULES: {
    header: `This person doesn't match the activity restriction`,
  },
  UNKNOWN: {
    header: `This person doesn't match the activity restriction`,
  },
  NO_SPACE: {
    header: `This activity is at maximum capacity`,
  },
} as const;
