import { gateway } from 'libs/generated-grpc-web';

import { IMgStreamItem } from '@app/src/app/util/stream';

import { StreamSectionWithPhotoIndex } from '../types/home-gallery.types';

export const getGlobalIndex = (
  sections: IMgStreamItem<gateway.gallery_pb.GallerySection.AsObject>[],
  sectionIndex: number,
  photoIndex: number,
): number => {
  return sections
    .slice(0, sectionIndex)
    .reduce(
      (globalIndex, section) => (globalIndex += section.item.itemList.length),
      photoIndex,
    );
};

export const getGlobalPhotosRange = (
  sections: IMgStreamItem<gateway.gallery_pb.GallerySection.AsObject>[],
  globalStartIndex: number,
  globalEndIndex: number,
): gateway.gallery_pb.GalleryItem.AsObject[] => {
  const allItems: gateway.gallery_pb.GalleryItem.AsObject[] = [];
  for (const section of sections) {
    const sectionItems = section.item.itemList;

    allItems.push(...sectionItems);

    if (allItems.length > globalEndIndex) {
      break;
    }
  }

  return allItems.slice(globalStartIndex, globalEndIndex);
};

export const findStreamSectionFromGlobalIndex = (
  sections: IMgStreamItem<gateway.gallery_pb.GallerySection.AsObject>[],
  globalIndex: number,
): StreamSectionWithPhotoIndex | null => {
  let currentIndex = 0;
  for (const streamSection of sections) {
    currentIndex += streamSection.item.itemList.length;

    if (currentIndex > globalIndex) {
      const photoIndex = currentIndex - globalIndex;
      return { streamSection, photoIndex };
    }
  }

  return null;
};
