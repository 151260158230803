import { ICheckinPerson } from 'libs/domain';
import { checkin_pb } from 'libs/generated-grpc-ts';

export const toProto = (person: ICheckinPerson): checkin_pb.CheckinPerson => {
  const personProto = new checkin_pb.CheckinPerson();
  if (person.id) personProto.setId(person.id);
  if (person.hash) personProto.setHash(person.hash);
  personProto.setDisplayName(person.displayName);
  if (person.grade) personProto.setGrade(person.grade);
  if (person.studentId) personProto.setStudentId(person.studentId);
  if (person.badgeIconUrl) personProto.setBadgeIconUrl(person.badgeIconUrl);
  if (person.badgeIconColor)
    personProto.setBadgeIconColor(person.badgeIconColor);
  if (person.badgeRoleName) personProto.setBadgeRoleName(person.badgeRoleName);
  personProto.setFirstName(person.firstName);
  personProto.setLastName(person.lastName);
  return personProto;
};

export const fromProto = (person: checkin_pb.CheckinPerson): ICheckinPerson => {
  return {
    id: person.getId(),
    hash: person.getHash(),
    displayName: person.getDisplayName(),
    grade: person.getGrade(),
    studentId: person.getStudentId(),
    badgeIconUrl: person.getBadgeIconUrl(),
    badgeIconColor: person.getBadgeIconColor(),
    badgeRoleName: person.getBadgeRoleName(),
    firstName: person.getFirstName(),
    lastName: person.getLastName(),
  };
};
