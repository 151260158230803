// GENERATED CODE -- DO NOT EDIT!

import {grpc} from '@improbable-eng/grpc-web';

import * as checkin_checkin_pb from '../checkin/checkin_pb';
import * as common_date_pb from '../common/date_pb';
import * as membership_list_membership_list_pb from '../membership_list/membership_list_pb';
import * as restriction_restriction_pb from '../restriction/restriction_pb';
import * as student_schedule_student_schedule_pb from '../student_schedule/student_schedule_pb';

export class CheckinManager {
  static readonly serviceName = "checkin.CheckinManager";

  static readonly SelfCheckIn = {
    methodName: "SelfCheckIn",
    service: CheckinManager,
    requestStream: false,
    responseStream: false,
    requestType: checkin_checkin_pb.SelfCheckInRequest,
    responseType: checkin_checkin_pb.SelfCheckInResponse,
  };

  static readonly SelfCheckOut = {
    methodName: "SelfCheckOut",
    service: CheckinManager,
    requestStream: false,
    responseStream: false,
    requestType: checkin_checkin_pb.SelfCheckOutRequest,
    responseType: checkin_checkin_pb.SelfCheckOutResponse,
  };

  static readonly Checkin = {
    methodName: "Checkin",
    service: CheckinManager,
    requestStream: false,
    responseStream: false,
    requestType: checkin_checkin_pb.CheckinRequest,
    responseType: checkin_checkin_pb.CheckinResponse,
  };

  static readonly Checkout = {
    methodName: "Checkout",
    service: CheckinManager,
    requestStream: false,
    responseStream: false,
    requestType: checkin_checkin_pb.CheckoutRequest,
    responseType: checkin_checkin_pb.CheckoutResponse,
  };

  static readonly GetMingaCheckinReason = {
    methodName: "GetMingaCheckinReason",
    service: CheckinManager,
    requestStream: false,
    responseStream: false,
    requestType: checkin_checkin_pb.GetMingaCheckinReasonRequest,
    responseType: checkin_checkin_pb.GetMingaCheckinReasonResponse,
  };

  static readonly GetMingaCheckinReasons = {
    methodName: "GetMingaCheckinReasons",
    service: CheckinManager,
    requestStream: false,
    responseStream: false,
    requestType: checkin_checkin_pb.GetMingaCheckinReasonsRequest,
    responseType: checkin_checkin_pb.GetMingaCheckinReasonsResponse,
  };

  static readonly UpsertCheckinReason = {
    methodName: "UpsertCheckinReason",
    service: CheckinManager,
    requestStream: false,
    responseStream: false,
    requestType: checkin_checkin_pb.UpsertCheckinReasonRequest,
    responseType: checkin_checkin_pb.UpsertCheckinReasonResponse,
  };

  static readonly DeleteCheckinReason = {
    methodName: "DeleteCheckinReason",
    service: CheckinManager,
    requestStream: false,
    responseStream: false,
    requestType: checkin_checkin_pb.DeleteCheckinReasonRequest,
    responseType: checkin_checkin_pb.DeleteCheckinReasonResponse,
  };

  static readonly AddDefaultCheckinReasonsToMinga = {
    methodName: "AddDefaultCheckinReasonsToMinga",
    service: CheckinManager,
    requestStream: false,
    responseStream: false,
    requestType: checkin_checkin_pb.AddDefaultCheckinReasonsRequest,
    responseType: checkin_checkin_pb.AddDefaultCheckinReasonsResponse,
  };

  static readonly ArchiveCheckin = {
    methodName: "ArchiveCheckin",
    service: CheckinManager,
    requestStream: false,
    responseStream: false,
    requestType: checkin_checkin_pb.ArchiveCheckinRequest,
    responseType: checkin_checkin_pb.ArchiveCheckinResponse,
  };

  static readonly UpdateCheckin = {
    methodName: "UpdateCheckin",
    service: CheckinManager,
    requestStream: false,
    responseStream: false,
    requestType: checkin_checkin_pb.UpdateCheckinRequest,
    responseType: checkin_checkin_pb.UpdateCheckinResponse,
  };

  static readonly ToggleCheckinReasonActive = {
    methodName: "ToggleCheckinReasonActive",
    service: CheckinManager,
    requestStream: false,
    responseStream: false,
    requestType: checkin_checkin_pb.ToggleCheckinReasonActiveRequest,
    responseType: checkin_checkin_pb.ToggleCheckinReasonActiveResponse,
  };

}

export class CheckinManagerClient {
  selfCheckIn() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  selfCheckOut() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  checkin() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  checkout() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  getMingaCheckinReason() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  getMingaCheckinReasons() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  upsertCheckinReason() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  deleteCheckinReason() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  addDefaultCheckinReasonsToMinga() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  archiveCheckin() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  updateCheckin() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  toggleCheckinReasonActive() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
}
