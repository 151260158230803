import { IPersonIdentityListItem } from 'libs/domain';
import { student_id_pb } from 'libs/generated-grpc-ts';

export namespace IPersonIdentityListProtoMapper {
  export function toProto(
    person: IPersonIdentityListItem,
  ): student_id_pb.PersonIdentityListItem {
    const msg = new student_id_pb.PersonIdentityListItem();
    msg.setEmail(person.email);
    msg.setPersonHash(person.personHash);
    msg.setName(person.name);
    msg.setStudentId(person.studentId);

    return msg;
  }

  export function fromProto(
    person: student_id_pb.PersonIdentityListItem,
  ): IPersonIdentityListItem {
    return {
      personHash: person.getPersonHash(),
      email: person.getEmail(),
      name: person.getName(),
      studentId: person.getStudentId(),
    };
  }
}
