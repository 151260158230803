import { CheckinReasonIcons, DefaultHallPassTypes } from 'libs/domain';

import { FormSelectOption } from '@shared/components/form';

export enum CheckinTypesMessages {
  PAGE_TITLE = 'Check In Reasons',

  BUTTON_CREATE_NEW = 'Create reason',

  COLUMN_TITLE_REASON = 'Reason',
  COLUMN_TITLE_CATEGORY = 'Category',
  COLUMN_TITLE_POINTS = 'Points (optional)',
  COLUMN_TITLE_STICKER = 'Attach stickers',
  COLUMN_TITLE_ICONS = 'Icons',
  COLUMN_TITLE_SHOW_ABSENTS = 'Show absents',
  COLUMN_TITLE_STATUS = 'Status',
  COLUMN_TITLE_DELETE = 'Delete',
}

export const CHECKIN_ICONS: FormSelectOption<string>[] = [
  ...Object.values(CheckinReasonIcons).map(icon => ({
    label: icon,
    value: icon,
  })),
  ...Object.values(DefaultHallPassTypes).map(type => ({
    label: 'hp-' + type,
    value: 'hp-' + type,
  })),
];
