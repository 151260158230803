import { Validators } from '@angular/forms';

import * as day from 'dayjs';
import {
  BmReportsFilters,
  CheckinManagerReportFilters,
  FtmReportFilters,
  HpmReportsFilters,
} from 'libs/domain';
import {
  ScheduledReportDeliveryType,
  ScheduledReportFrequency,
} from 'libs/domain';
import { DayOfWeekEnum } from 'libs/util';

import {
  ClientSupportedScheduledReportFilter,
  ScheduledReportType,
  SupportedDashboardFilterType,
} from '../types';

export enum ScheduledReportTableColumn {
  NAME = 'name',
  CREATED_BY = 'createdBy',
  TYPE = 'type',
  SCHEDULE = 'schedule',
  TIME = 'time',
  DELIVERY_METHODS = 'deliveryType',
  ACTIVE = 'active',
  EDIT = 'edit',
  MOBILE = 'mobile',
  CREATED_AT = 'createdAt',
}

export const SCHEDULED_REPORT_TABLE_COLUMNS: string[] = [
  ScheduledReportTableColumn.NAME,
  ScheduledReportTableColumn.CREATED_BY,
  ScheduledReportTableColumn.TYPE,
  ScheduledReportTableColumn.SCHEDULE,
  ScheduledReportTableColumn.TIME,
  ScheduledReportTableColumn.DELIVERY_METHODS,
  ScheduledReportTableColumn.EDIT,
  ScheduledReportTableColumn.ACTIVE,
  ScheduledReportTableColumn.CREATED_AT,
];

export enum ScheduledReportEditMessage {
  MODAL_TITLE = 'Schedule a report',
  FIELD_LABEL_NAME = 'Report name',
  FIELDSET_REPORT_TYPE = 'Report type',
  FIELD_LABEL_REPORT_TYPE = 'Report type',
  FIELDSET_FREQUENCE = 'Frequency of reports',
  FIELD_LABEL_FREQUENCE = 'Frequency',
  FIELD_LABEL_TIME = 'Time',
  FIELD_LABEL_DATE = 'Day',
  FIELDSET_DELIVERY_METHOD = 'Delivery method',
  FIELD_LABEL_DELIVERY_METHOD = 'Method',
  FIELD_LABEL_EMAIL = 'List of users to send email to',
  FIELD_LABEL_EMAIL2 = 'Name',
  FIELD_LABEL_SFTP_INFO = 'SFTP info',
  FIELD_LABEL_SFTP_HOST = 'Host',
  FIELD_LABEL_SFTP_USERNAME = 'Username',
  FIELD_LABEL_SFTP_PASSWORD = 'Password',
  BUTTON_LABEL_CANCEL = 'Cancel',
  BUTTON_LABEL_DELETE = 'Delete',
  BUTTON_LABEL_CREATE = 'Create',
  BUTTON_LABEL_CREATING = 'Creating',
  BUTTON_LABEL_UPDATE = 'Save',
  BUTTON_LABEL_UPDATING = 'Saving',

  ERROR_INVALID_EMAILS = 'You must provide only valid email addresses',
  ERROR_INVALID_FILTERS = 'You must set at least 1 filter',
  ERROR_GENERIC = 'Please complete the missing information',

  UPDATE_ERROR = 'There was an error updating the report',
  CREATE_ERROR = 'There was an error creating the report',
  DELETE_ERROR = 'There was an error deleting the report',
  GET_ERROR = 'There was an problem fetching the report',

  DELETE_CONFIRM_TITLE = 'Are you sure you want to delete this report?',
  DELETE_CONFIRM_DISCARD_TITLE = 'Are you sure you want to discard this report?',
  DELETE_CONFIRM_DESC = "You'll still have access to the reports history",
  DELETE_CONFIRM_DISCARD_DESC = 'You will lose any unsaved changes',
  DELETE_CONFIRM_CANCEL_BTN = 'Cancel',
  DELETE_CONFIRM_DELETE_BTN = 'Delete',
  DELETE_CONFIRM_DISCARD_BTN = 'Discard',
}

export enum ScheduledReportEditFormFields {
  NAME = 'name',
  REPORT_TYPE = 'reportType',
  REPORT_FILTERS = 'filters',
  FREQUENCY = 'frequency',
  TIME = 'time',
  DAY = 'day',
  ACTIVE = 'active',
  DELIVERY_TYPE = 'deliveryType',
  EMAIL = 'email',
  SFTP_HOST = 'sftpHost',
  SFTP_USER_NAME = 'sftpUserName',
  SFTP_PASSWORD = 'sftpPassword',
}

export const getDayEnum = (date: day.Dayjs): DayOfWeekEnum => {
  return DayOfWeekEnum[
    date.format('ddd').toUpperCase() as keyof typeof DayOfWeekEnum
  ];
};

export const SCHEDULED_REPORTS_EDIT_FORMGROUP = {
  [ScheduledReportEditFormFields.NAME]: [
    null,
    [Validators.required, Validators.maxLength(50)],
  ],
  [ScheduledReportEditFormFields.REPORT_TYPE]: [
    ScheduledReportType.PBIS_HISTORY,
    [Validators.required],
  ],
  [ScheduledReportEditFormFields.FREQUENCY]: [
    ScheduledReportFrequency.Daily,
    [Validators.required],
  ],
  [ScheduledReportEditFormFields.TIME]: [null, [Validators.required]],
  [ScheduledReportEditFormFields.DAY]: [null],
  [ScheduledReportEditFormFields.DELIVERY_TYPE]: [null, [Validators.required]],
  [ScheduledReportEditFormFields.EMAIL]: [[]],
  [ScheduledReportEditFormFields.SFTP_HOST]: [null],
  [ScheduledReportEditFormFields.SFTP_USER_NAME]: [null],
  [ScheduledReportEditFormFields.SFTP_PASSWORD]: [null],
};

type Options<T> = Array<{
  label: string;
  value: T;
}>;

export const REPORT_TYPE_OPTIONS: Options<ScheduledReportType> = [
  { label: 'Behavior History Report', value: ScheduledReportType.PBIS_HISTORY },
  {
    label: 'Consequence History Report',
    value: ScheduledReportType.PBIS_CONS_HISTORY,
  },
  {
    label: 'Overdue Consequences Report',
    value: ScheduledReportType.PBIS_OVERDUE_CONS,
  },
  {
    label: 'Check in History Report',
    value: ScheduledReportType.CHECKIN_HISTORY,
  },
  {
    label: 'Hall Pass History Report',
    value: ScheduledReportType.HALL_PASS_HISTORY,
  },
  {
    label: 'Overdue Hall Pass Report',
    value: ScheduledReportType.HALL_PASS_OVERDUE,
  },
  {
    label: 'Staff Hall Passes Report',
    value: ScheduledReportType.HALL_PASS_STAFF,
  },
  {
    label: 'Student Hall Passes Report',
    value: ScheduledReportType.HALL_PASS_STUDENT,
  },
  {
    label: 'Hall Pass Types Report',
    value: ScheduledReportType.HALL_PASS_TYPES,
  },
  {
    label: 'FlexTime History Report',
    value: ScheduledReportType.FLEX_PERIOD_REGISTERED,
  },
  {
    label: 'FlexTime Unregistered Report',
    value: ScheduledReportType.FLEX_PERIOD_UNREGISTERED,
  },
  {
    label: 'FlexTime Activity Report',
    value: ScheduledReportType.FLEX_ACTIVITIES,
  },
  {
    label: 'FlexTime Student Report',
    value: ScheduledReportType.FLEX_STUDENTS,
  },
  {
    label: 'FlexTime Staff Report',
    value: ScheduledReportType.FLEX_STAFF,
  },
];
export const getMapperConfig: (
  filters: SupportedDashboardFilterType,
) => Record<
  ScheduledReportType,
  ClientSupportedScheduledReportFilter
> = filters => {
  const hallPassMapper = (reportType, hpFilters) => {
    const f = hpFilters as HpmReportsFilters;
    return {
      reportType,
      filters: {
        issuedTo: [...(f.issuedTo || [])],
        issuedBy: [...(f.issuedBy || [])],
        hallpassType: f.hallpassType,
        createdBy: f.createdBy,
      } as HpmReportsFilters,
    };
  };

  const pbisMapper = (reportType, pbisFitlers) => {
    const f = pbisFitlers as BmReportsFilters;
    return {
      reportType,
      filters: {
        issuedTo: f.issuedTo || [],
        issuedBy: f.issuedBy || [],
        types: f.types,
        grades: f.grades,
        consTypes: f.consTypes,
        consCatTypes: f.consCatTypes,
        consStatus: f.consStatus,
      } as BmReportsFilters,
    };
  };

  const checkinMapper = (reportType, checkinFitlers) => {
    const f = checkinFitlers as CheckinManagerReportFilters;
    return {
      reportType,
      filters: {
        issuedTo: [...(f.issuedTo || [])],
        issuedBy: [...(f.issuedBy || [])],
        reason: f.reason,
        grade: f.grade,
        role: f.role,
      } as CheckinManagerReportFilters,
    };
  };

  const flexMapper = (reportType, flexFilters) => {
    const f = flexFilters as FtmReportFilters;
    return {
      reportType,
      filters: {
        registrant: f.registrant,
        periods: f.periods,
        activities: f.activities,
        checkinStatus: f.checkinStatus,
        startDate: f.startDate,
        endDate: f.endDate,
        userList: f.userList,
      } as FtmReportFilters,
    };
  };

  return {
    [ScheduledReportType.PBIS_HISTORY]: pbisMapper(
      ScheduledReportType.PBIS_HISTORY,
      filters,
    ),
    [ScheduledReportType.PBIS_CONS_HISTORY]: pbisMapper(
      ScheduledReportType.PBIS_CONS_HISTORY,
      filters,
    ),
    [ScheduledReportType.PBIS_OVERDUE_CONS]: pbisMapper(
      ScheduledReportType.PBIS_OVERDUE_CONS,
      filters,
    ),
    [ScheduledReportType.CHECKIN_HISTORY]: checkinMapper(
      ScheduledReportType.CHECKIN_HISTORY,
      filters,
    ),
    [ScheduledReportType.HALL_PASS_HISTORY]: hallPassMapper(
      ScheduledReportType.HALL_PASS_HISTORY,
      filters,
    ),

    [ScheduledReportType.HALL_PASS_OVERDUE]: hallPassMapper(
      ScheduledReportType.HALL_PASS_OVERDUE,
      filters,
    ),

    [ScheduledReportType.HALL_PASS_STAFF]: hallPassMapper(
      ScheduledReportType.HALL_PASS_STAFF,
      filters,
    ),

    [ScheduledReportType.HALL_PASS_STUDENT]: hallPassMapper(
      ScheduledReportType.HALL_PASS_STUDENT,
      filters,
    ),

    [ScheduledReportType.HALL_PASS_TYPES]: hallPassMapper(
      ScheduledReportType.HALL_PASS_TYPES,
      filters,
    ),

    [ScheduledReportType.FLEX_PERIOD_REGISTERED]: flexMapper(
      ScheduledReportType.FLEX_PERIOD_REGISTERED,
      filters,
    ),
    [ScheduledReportType.FLEX_PERIOD_UNREGISTERED]: flexMapper(
      ScheduledReportType.FLEX_PERIOD_UNREGISTERED,
      filters,
    ),
    [ScheduledReportType.FLEX_ACTIVITIES]: flexMapper(
      ScheduledReportType.FLEX_ACTIVITIES,
      filters,
    ),
    [ScheduledReportType.FLEX_STUDENTS]: flexMapper(
      ScheduledReportType.FLEX_STUDENTS,
      filters,
    ),
    [ScheduledReportType.FLEX_STAFF]: flexMapper(
      ScheduledReportType.FLEX_STAFF,
      filters,
    ),
  };
};

export const FREQUENCY_TYPES: Record<ScheduledReportFrequency, string> = {
  [ScheduledReportFrequency.Daily]: 'Daily',
  [ScheduledReportFrequency.Weekly]: 'Weekly',
};

export const FREQUENCY_OPTIONS = Object.entries(FREQUENCY_TYPES).map(
  ([key, value]) => ({
    value: +key,
    label: value,
  }),
);
export const DELIVERY_TYPES: Record<ScheduledReportDeliveryType, string> = {
  email: 'Email',
  sftp: 'SFTP',
};

export const DELIVERY_OPTIONS = Object.entries(DELIVERY_TYPES).map(
  ([key, value]) => ({
    value: key,
    label: value,
  }),
);

export const DAY_OPTIONS: Options<DayOfWeekEnum> = [
  { label: 'Monday', value: DayOfWeekEnum.MON },
  { label: 'Tuesday', value: DayOfWeekEnum.TUE },
  { label: 'Wednesday', value: DayOfWeekEnum.WED },
  { label: 'Thursday', value: DayOfWeekEnum.THU },
  { label: 'Friday', value: DayOfWeekEnum.FRI },
  { label: 'Saturday', value: DayOfWeekEnum.SAT },
  { label: 'Sunday', value: DayOfWeekEnum.SUN },
];

export const getFilterGroupControlKey = (controlKey: string): string => {
  return `${ScheduledReportEditFormFields.REPORT_FILTERS}.${controlKey}`;
};

export enum ScheduledReportMessages {
  TITLE = 'Scheduled reports',
  SCHEDULED_TITLE = 'Reports created',
  SCHEDULE_INFO = 'Only behavior reports are available at the moment',
  HISTORY_TITLE = 'Reports history',
  HISTORY_INFO = 'Reports can be viewed for 30 days',
}

export enum ScheduledReportTableMessages {
  COL_NAME = 'Title',
  COL_CREATED_BY = 'Created by',
  COL_TYPE = 'Type',
  COL_SCHEDULE = 'Schedule',
  COL_TIME = 'Time',
  COL_DELIVERY = 'Delivery',
  COL_ACTIVE = 'Active',
  COL_EDIT = 'Edit',

  FREQUENCY_DAILY = 'Daily',
  FREQUENCY_WEEKLY = 'Weekly',

  NO_RESULTS_TITLE = 'No scheduled report found',
  NO_RESULTS_DESC = 'Scheduled reports will be visible here',

  SNACKBAR_CREATE_SUCCESS = 'Scheduled report created',
  SNACKBAR_UPDATE_SUCCESS = 'Scheduled report updated',
  SNACKBAR_DELETE_SUCCESS = 'Scheduled report deleted',
  SNACKBAR_TOOGLE_ACTIVE_SUCCESS = 'Scheduled report activated',
  SNACKBAR_TOOGLE_DEACTIVE_SUCCESS = 'Scheduled report deactivated',
}
export enum HistoryTableMessages {
  COL_STATUS = 'Status',
  COL_NAME = 'Name',
  COL_DATE = 'Date',
  COL_TIME = 'Time',
  COL_DOWNLOAD = 'Download',

  STATUS_SUCCESS = 'Success',
  STATUS_ERROR = 'Error',

  NO_RESULTS_TITLE = 'No report history found',
  NO_RESULTS_DESC = 'Your scheduled report history will appear here',
}

export enum ScheduledHistoryTableColumn {
  STATUS = 'status',
  NAME = 'name',
  DATE = 'date',
  TIME = 'time',
  DOWNLOAD = 'download',
}

export enum ScheduleReportMessages {
  SCHEDULED_REPORT_CREATED_SUCCESS = 'Scheduled report created',
  SCHEDULED_REPORT_CREATED_BTN = 'View',
}

export const TABLE_VIEWPORT_WIDTH_BREAKPOINT = 1090;
