// GENERATED CODE -- DO NOT EDIT!

import {grpc} from '@improbable-eng/grpc-web';

import * as sms_message_content_sms_message_content_pb from '../sms_message_content/sms_message_content_pb';
import * as content_common_pb from '../content/common_pb';
import * as common_delta_pb from '../common/delta_pb';

export class SmsMessageContent {
  static readonly serviceName = "minga.content.SmsMessageContent";

  static readonly New = {
    methodName: "New",
    service: SmsMessageContent,
    requestStream: false,
    responseStream: false,
    requestType: sms_message_content_sms_message_content_pb.SmsMessageInfo,
    responseType: content_common_pb.ContentSaveResult,
  };

  static readonly Update = {
    methodName: "Update",
    service: SmsMessageContent,
    requestStream: false,
    responseStream: false,
    requestType: sms_message_content_sms_message_content_pb.DeltaSmsMessageInfo,
    responseType: content_common_pb.ContentSaveResult,
  };

  static readonly GetInfo = {
    methodName: "GetInfo",
    service: SmsMessageContent,
    requestStream: false,
    responseStream: false,
    requestType: content_common_pb.ContentInfoQuery,
    responseType: sms_message_content_sms_message_content_pb.SmsMessageInfo,
  };

}

export class SmsMessageContentClient {
  new() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  update() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  getInfo() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
}
