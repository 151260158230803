import { NgModule } from '@angular/core';

import { MgPipesModule } from '@app/src/app/pipes';

import { MgNotificationBaseModule } from '../MgNotificationBase';
import { MgChallengeInviteNotificationElement } from './MgChallengeInviteNotification.element';

@NgModule({
  imports: [
    // Minga dependencies
    MgNotificationBaseModule,
    MgPipesModule,

    // External dependencies
  ],
  declarations: [MgChallengeInviteNotificationElement],
  exports: [MgChallengeInviteNotificationElement],
})
export class MgChallengeInviteNotificationModule {}
