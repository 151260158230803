import {
  createFeatureSelector,
  createSelector,
  MemoizedSelector,
} from '@ngrx/store';
import { MingaMinimalModel } from 'libs/domain';
import { IMingaFeatureToggle } from 'libs/domain';
import { getSettingsAsObject, mingaSettingTypes } from 'libs/util';

import {
  adapter,
  MingaCollectionState,
  MingaState,
} from '../reducers/minga.reducer';

export const getMingasState: MemoizedSelector<any, MingaState> =
  createFeatureSelector<MingaState>('mingaStore');

export const getMingaEntitiesState: MemoizedSelector<any, any> = createSelector(
  getMingasState,
  state => {
    return state.mingaCollection;
  },
);

export const currentMingaHash = (state: MingaCollectionState) => {
  return state.currentMingaHash;
};

const selectors = adapter.getSelectors(getMingaEntitiesState);

export const getAllMingas = selectors.selectAll;

export const getCurrentMingaHash: MemoizedSelector<any, any> = createSelector(
  getMingaEntitiesState,
  currentMingaHash,
);

export const getCurrentMinga: MemoizedSelector<any, any> = createSelector(
  getAllMingas,
  getCurrentMingaHash,
  (mingas, currentMingaHash) => {
    const current = mingas.filter(minga => minga.hash === currentMingaHash);
    return current.pop();
  },
);

export const getActiveMingaSubscription = createSelector(
  getMingaEntitiesState,
  state => state.activeMingaSubscription,
);

export const getMingaByHash = createSelector(
  getAllMingas,
  (mingas: MingaMinimalModel[], mingaHash: string) => {
    const selectedMinga = mingas.filter(minga => minga.hash === mingaHash);
    return selectedMinga.pop();
  },
);

export const getAllSettings = createSelector(
  getCurrentMinga,
  (minga: MingaMinimalModel) => {
    return minga?.settings;
  },
);

export const getAllSettingsAsObject = createSelector(
  getCurrentMinga,
  (minga: MingaMinimalModel) => {
    const settings = minga?.settings;
    const allSettings = getSettingsAsObject();

    if (!settings) return {};

    for (const setting of settings) {
      if (setting.value !== undefined) {
        allSettings[setting.name] = setting.value;
      }
    }
    return allSettings;
  },
);

export const getFeatureToggles = createSelector(
  getCurrentMinga,
  (minga: MingaMinimalModel) => {
    return minga?.featureToggle;
  },
);

export const getSettingValue = (settingKey: mingaSettingTypes) =>
  createSelector(getCurrentMinga, (minga: MingaMinimalModel) => {
    if (!minga?.settings) {
      return undefined;
    }
    const value = minga.settings.find(setting => setting.name == settingKey);
    return value?.value;
  });

export const getModuleEnabled = (moduleName: keyof IMingaFeatureToggle) =>
  createSelector(getCurrentMinga, (minga: MingaMinimalModel) => {
    const features = minga?.featureToggle;
    if (!features) {
      return false;
    }
    return features[moduleName];
  });
