export enum ContentType {
  COMPUTE = 'COMPUTE',
  GROUP = 'GROUP',
  POST = 'POST',
  IMAGE = 'IMAGE',
  COMMENT = 'COMMENT',
  VIDEO = 'VIDEO',
  EVENT = 'EVENT',
  ANNOUNCEMENT = 'ANNOUNCEMENT',
  INSPIRATION = 'INSPIRATION',
  BIRTHDAY = 'BIRTHDAY',
  PROGRAM = 'PROGRAM',
  MINGA = 'MINGA',
  POLL = 'POLL',
  FILE = 'FILE',
  CHALLENGE = 'CHALLENGE',
  CHALLENGE_RESPONSE = 'CHALLENGE_RESPONSE',
  SMS_MESSAGE = 'SMS_MESSAGE',
}

export namespace ContentTypeUtil {
  export function isContentType(value: any): value is ContentType {
    switch(value) {
      case ContentType.COMPUTE:
      case ContentType.GROUP:
      case ContentType.POST:
      case ContentType.IMAGE:
      case ContentType.COMMENT:
      case ContentType.VIDEO:
      case ContentType.EVENT:
      case ContentType.ANNOUNCEMENT:
      case ContentType.INSPIRATION:
      case ContentType.BIRTHDAY:
      case ContentType.PROGRAM:
      case ContentType.MINGA:
      case ContentType.POLL:
      case ContentType.CHALLENGE:
      case ContentType.CHALLENGE_RESPONSE:
      case ContentType.SMS_MESSAGE:
      case ContentType.FILE: return true;
    }

    return false;
  }

  export function isFeedContentType(value: any): value is ContentType {
    switch(value) {
      case ContentType.POST:
      case ContentType.VIDEO:
      case ContentType.EVENT:
      case ContentType.ANNOUNCEMENT:
      case ContentType.INSPIRATION:
      case ContentType.BIRTHDAY:
      case ContentType.POLL:
      case ContentType.CHALLENGE:
      case ContentType.SMS_MESSAGE:
      case ContentType.FILE: return true;
    }

    return false;
  }

  export function isContentTypeArray(arr: any[]): arr is ContentType[] {
    for(const el of arr) {
      if(!ContentTypeUtil.isContentType(el)) {
        return false;
      }
    }

    return true;
  }
}

export interface IContent {
  readonly hash: string;
  readonly contentType: ContentType;

  /**
   * String used for displaying this content when it's tagged
   */
  readonly contentTagTitle: string;
}
