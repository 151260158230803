import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { points_pb, points_ng_grpc_pb } from 'libs/generated-grpc-web';
import { map } from 'rxjs/operators';

import { AuthInfoService } from '@app/src/app/minimal/services/AuthInfo';
import { dateMessageObjectToDateObject } from '@app/src/app/util/date';

import { ToolsMessages } from '@modules/tools/tools.constants';

@Component({
  selector: 'mg-tools-your-points',
  templateUrl: './tools-your-points.component.html',
  styleUrls: ['./tools-your-points.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToolsYourPointsComponent {
  public authPersonHash$ = this._route.snapshot.paramMap.has('hash')
    ? this._route.paramMap.pipe(map(paramMap => paramMap.get('hash')))
    : this._authInfo.authPersonHash$;
  private _pointMapHandling: Map<points_pb.PointsHistoryItem.AsObject, string> =
    new Map();
  private _dateRange: Date[] = [];
  public MESSAGES = ToolsMessages;

  constructor(
    public pointsProto: points_ng_grpc_pb.PointsManager,
    private _route: ActivatedRoute,
    private _authInfo: AuthInfoService,
  ) {}

  public getFilter(hash) {
    return { personHash: hash };
  }

  public checkForSeparator(
    item: points_pb.PointsHistoryItem.AsObject | undefined,
  ) {
    let date = new Date();
    let sep = '';
    if (item?.date && typeof this._pointMapHandling.get(item) != 'string') {
      date = dateMessageObjectToDateObject(item.date);
      let thisYear = date.getFullYear();
      if (!this._dateRange.length) {
        if (date.getMonth() < 7) {
          thisYear--;
        }
        const yearStart = new Date(`${thisYear}-08-01`);
        this._dateRange.push(yearStart);
        const yearEnd = new Date(`${thisYear + 1}-07-31`);
        this._dateRange.push(yearEnd);
        sep = `${thisYear}/${thisYear + 1}`;
      } else if (!(date >= this._dateRange[0] && date < this._dateRange[1])) {
        this._dateRange[0].setFullYear(thisYear - 1);
        this._dateRange[1].setFullYear(thisYear);
        sep = `${thisYear - 1}/${thisYear}`;
      }

      this._pointMapHandling.set(item, sep);
    }
    return this._pointMapHandling.get(item);
  }
}
