import * as _ from 'lodash';

import { ILinearGradient, IRadialGradient } from './gradientTypes';

export type DesignedSvgGradientData = ILinearGradient | IRadialGradient;
export type DesignedSvgImageSlotType = 'sticker' | 'photo';
export type DesignedSvgImageData = {
  src: string;
  width: number;
  height: number;
  type?: DesignedSvgImageSlotType;
};
export type DesignedSvgTextData = {
  text: string;
  /**
   * Optionally provide a color for the text slot to override the templates
   * default.
   */
  color?: string;

  /**
   * Optionally provide a font size for the text slot to override the templates
   * default.
   */
  fontSize?: number;

  /**
   * Optionally provide a text anchor. Same values as the svg `text-anchor`
   * attribute are valid here.
   */
  textAnchor?: 'start' | 'middle' | 'end';

  /**
   * Optionally provide a text decoration. Same values as the svg
   * `text-decoration` attribute are valid here.
   */
  textDecoration?: string;

  /**
   * Optionally provide the font weight. May be from 100 to 900
   */
  fontWeight?: number;

  /**
   * Optionally provide font style. Same values as the svg CSS style
   * `font-style` are valid here.
   */
  fontStyle?: string;

  /**
   * Optionally provide a font font family. Google fonts or built-in fonts are
   * valid here. Only a single font family is valid.
   */
  fontFamily?: string;
};

/**
 * All possible SVG data values. May not be valid for each type of slot. Please
 * use `DesignedSvgData.is*` functions for proper determination
 */
export type DesignedSvgDataValue =
  | string
  | DesignedSvgImageData
  | DesignedSvgTextData
  | ILinearGradient
  | IRadialGradient;

export type DesignedSvgSlotType = 'img' | 'txt' | 'bg';

/**
 * Valid types for a background slot
 */
export type DesignedSvgBackgroundSlotValue =
  | string
  | DesignedSvgGradientData
  | DesignedSvgImageData;

/**
 * Valid types for an image slot
 */
export type DesignedSvgImageSlotValue = string | DesignedSvgImageData;

/**
 * Valid types for a text slot
 */
export type DesignedSvgTextSlotValue = string | DesignedSvgTextData;

export type DesignedSvgImageSlotPair = ['img', DesignedSvgImageSlotValue];
export type DesignedSvgTextSlotPair = ['txt', DesignedSvgTextSlotValue];
export type DesignedSvgBackgroundSlotPair = [
  'bg',
  DesignedSvgBackgroundSlotValue,
];

export type DesignedSvgSlotPair =
  | DesignedSvgImageSlotPair
  | DesignedSvgTextSlotPair
  | DesignedSvgBackgroundSlotPair;

export type AnyDesignedSvgSlotPair = [
  DesignedSvgSlotType,
  DesignedSvgDataValue,
];

/**
 * Special 'fixed' data on designed svg data
 */
export interface IDesignedSvgFixedData {
  additionalSlots?: DesignedSvgSlotPair[];
}

/**
 * Data that is injected into a designed SVG slots.
 * @NOTE `ILinearGradient` and `IRadialGradient` values are only applicable to
 * `minga_bg_*` keys
 */
export interface IDesignedSvgSlotData {
  [keyname: string]: DesignedSvgDataValue | undefined;
}

export type DesignedSvgData = IDesignedSvgFixedData & IDesignedSvgSlotData;

export namespace DesignedSvgData {
  export function isImage(key: string): boolean;
  export function isImage(
    key: string,
    value: DesignedSvgDataValue | undefined,
  ): value is string;

  export function isImage(
    key: string,
    value?: DesignedSvgDataValue,
  ): value is DesignedSvgImageSlotValue {
    return key.startsWith('minga_image_');
  }

  export function isBackground(key: string): boolean;
  export function isBackground(
    key: string,
    value: DesignedSvgDataValue | undefined,
  ): value is DesignedSvgBackgroundSlotValue;

  export function isBackground(
    key: string,
    value?: DesignedSvgDataValue,
  ): value is DesignedSvgBackgroundSlotValue {
    return key.startsWith('minga_bg_');
  }

  export function isText(key: string): boolean;
  export function isText(
    key: string,
    value: DesignedSvgDataValue | undefined,
  ): value is DesignedSvgTextSlotValue;

  export function isText(
    key: string,
    value?: DesignedSvgDataValue,
  ): value is DesignedSvgTextSlotValue {
    return key.startsWith('minga_text_');
  }

  export function isAdditionalSlot(slotId: string): boolean;
  export function isAdditionalSlot(
    slotId: string,
    value: any,
  ): value is DesignedSvgSlotPair;

  export function isAdditionalSlot(slotId: string, value?: any): boolean {
    return slotId.startsWith('additionalSlots[');
  }

  export function getAdditionalSlotIndex(slotId: string): number {
    if (!isAdditionalSlot(slotId)) {
      throw new Error('getAdditionalSlotIndex() called on non-additional slot');
    }

    return parseInt(slotId.substr('additionalSlots['.length));
  }

  export function getSlotValue(svgData: DesignedSvgData, slotId: string) {
    if (isAdditionalSlot(slotId)) {
      return getAdditionalSlotValuePair(svgData, slotId)[1];
    } else {
      return _.get(svgData, slotId) || '';
    }
  }

  export function getAdditionalSlotValuePair(
    svgData: DesignedSvgData,
    slotId: string,
  ) {
    const val = _.get(svgData, slotId) as unknown as AnyDesignedSvgSlotPair;
    if (!val) {
      throw new Error(`Invalid additional slot value pair id ${slotId}`);
    }
    if (!Array.isArray(val)) {
      throw new Error(`Invalid additional slot value: ${val}`);
    }
    return val;
  }

  /**
   * Get the image slot type based on the slotId and value
   */
  export function getImageSlotType(
    slotId: string,
    value: DesignedSvgImageSlotValue,
  ): DesignedSvgImageSlotType {
    if (typeof value === 'string' || !value.type) {
      return isAdditionalSlot(slotId) ? 'sticker' : 'photo';
    }

    return value.type;
  }

  export function setSlotValue(
    svgData: DesignedSvgData,
    slotId: string,
    value: DesignedSvgDataValue,
  ) {
    if (isAdditionalSlot(slotId)) {
      const slotPair = getAdditionalSlotValuePair(svgData, slotId);
      slotPair[1] = value;
    } else {
      _.set(svgData, slotId, value);
    }
  }

  /**
   * Get the `DesignedSvgSlotType` from the slotId
   * @param slotId - Slot to check
   * @param svgData - Supply the svg data if you are unsure if your slot id may
   *                  be an 'additional' slot. If you do not an exception will
   *                  occur.
   */
  export function getSlotType(slotId: string, svgData?: DesignedSvgData) {
    if (isImage(slotId)) return 'img';
    else if (isText(slotId)) return 'txt';
    else if (isBackground(slotId)) return 'bg';

    if (isAdditionalSlot(slotId)) {
      if (!svgData) {
        throw new Error(
          'getSlotType with additionalSlot id must include svgData',
        );
      }

      return getAdditionalSlotValuePair(svgData, slotId)[0];
    }

    throw new Error(`Invalid slot id: ${slotId}`);
  }
}
