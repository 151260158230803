// GENERATED CODE -- DO NOT EDIT!

import {grpc} from '@improbable-eng/grpc-web';

import * as student_student_id_pb from '../student/student_id_pb';
import * as image_image_pb from '../image/image_pb';
import * as common_stream_pb from '../common/stream_pb';
import * as common_order_pb from '../common/order_pb';
import * as common_delta_pb from '../common/delta_pb';
import * as gateway_person_view_pb from '../gateway/person_view_pb';
import * as hall_pass_hall_pass_pb from '../hall_pass/hall_pass_pb';
import * as student_schedule_student_schedule_pb from '../student_schedule/student_schedule_pb';

export class StudentIdManager {
  static readonly serviceName = "student.StudentIdManager";

  static readonly ReadStudentId = {
    methodName: "ReadStudentId",
    service: StudentIdManager,
    requestStream: false,
    responseStream: false,
    requestType: student_student_id_pb.ReadStudentIdRequest,
    responseType: student_student_id_pb.ReadStudentIdResponse,
  };

  static readonly UpdateStudentId = {
    methodName: "UpdateStudentId",
    service: StudentIdManager,
    requestStream: false,
    responseStream: false,
    requestType: student_student_id_pb.UpdateStudentIdRequest,
    responseType: student_student_id_pb.UpdateStudentIdResponse,
  };

  static readonly DeleteStudentIdImage = {
    methodName: "DeleteStudentIdImage",
    service: StudentIdManager,
    requestStream: false,
    responseStream: false,
    requestType: student_student_id_pb.DeleteStudentIdImageRequest,
    responseType: student_student_id_pb.DeleteStudentIdImageResponse,
  };

  static readonly DeleteStudentId = {
    methodName: "DeleteStudentId",
    service: StudentIdManager,
    requestStream: false,
    responseStream: false,
    requestType: student_student_id_pb.DeleteStudentIdRequest,
    responseType: student_student_id_pb.DeleteStudentIdResponse,
  };

  static readonly GetStudentIdStatusCounts = {
    methodName: "GetStudentIdStatusCounts",
    service: StudentIdManager,
    requestStream: false,
    responseStream: false,
    requestType: student_student_id_pb.StudentIdStatusCountRequest,
    responseType: student_student_id_pb.StudentIdStatusCountResponse,
  };

  static readonly streamMingaStudentIds = {
    methodName: "streamMingaStudentIds",
    service: StudentIdManager,
    requestStream: false,
    responseStream: true,
    requestType: common_stream_pb.StreamID,
    responseType: student_student_id_pb.StreamStudentIdItem,
  };

  static readonly streamMingaStudentIdsControl = {
    methodName: "streamMingaStudentIdsControl",
    service: StudentIdManager,
    requestStream: false,
    responseStream: false,
    requestType: common_stream_pb.StreamControl,
    responseType: common_stream_pb.StreamControlResponse,
  };

  static readonly GetPersonIdentityList = {
    methodName: "GetPersonIdentityList",
    service: StudentIdManager,
    requestStream: false,
    responseStream: false,
    requestType: student_student_id_pb.PersonIdentityListRequest,
    responseType: student_student_id_pb.PersonIdentityListResponse,
  };

  static readonly BulkIdImageUpload = {
    methodName: "BulkIdImageUpload",
    service: StudentIdManager,
    requestStream: false,
    responseStream: false,
    requestType: student_student_id_pb.BulkIdImageUploadRequest,
    responseType: student_student_id_pb.BulkIdImageUploadResponse,
  };

  static readonly ExportStudentIdList = {
    methodName: "ExportStudentIdList",
    service: StudentIdManager,
    requestStream: false,
    responseStream: true,
    requestType: student_student_id_pb.ExportStudentIdListRequest,
    responseType: student_student_id_pb.ExportStudentIdListChunk,
  };

  static readonly GetMingaStudentIds = {
    methodName: "GetMingaStudentIds",
    service: StudentIdManager,
    requestStream: false,
    responseStream: false,
    requestType: student_student_id_pb.GetMingaStudentIdsRequest,
    responseType: student_student_id_pb.GetMingaStudentIdsResponse,
  };

}

export class StudentIdManagerClient {
  readStudentId() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  updateStudentId() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  deleteStudentIdImage() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  deleteStudentId() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  getStudentIdStatusCounts() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  streamMingaStudentIds() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  streamMingaStudentIdsControl() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  getPersonIdentityList() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  bulkIdImageUpload() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  exportStudentIdList() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  getMingaStudentIds() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
}
