import { UntypedFormControl } from '@angular/forms';

import Fuse from 'fuse.js';
import { BehaviorSubject, of, ReplaySubject, timer } from 'rxjs';
import { debounce, map, takeUntil } from 'rxjs/operators';

import {
  FORM_SHEET_SELECT_FUSE_OPTIONS,
  FORM_SHEET_SELECT_SEARCH_DEBOUNCE,
} from '../constants';
import { FormSheetSelectOption } from '../types';

export class FormSheetSelectFilter {
  // Form control

  public readonly control = new UntypedFormControl();

  // State

  private readonly _filteredData = new BehaviorSubject<FormSheetSelectOption[]>(
    this._data,
  );
  public readonly filteredData$ = this._filteredData.asObservable();

  public readonly size$ = this.filteredData$.pipe(map(item => item.length));
  public readonly hasFilteredData$ = this.size$.pipe(map(size => size > 0));

  // Subscriptions

  private readonly _filterSubscription = this._subscription();

  // Fuse

  private readonly _fuse = new Fuse<FormSheetSelectOption>(
    this._data,
    FORM_SHEET_SELECT_FUSE_OPTIONS,
  );

  /** Utility constructor */
  constructor(
    private _data: FormSheetSelectOption[],
    private _destroyedSubject: ReplaySubject<void>,
  ) {}

  public destroy() {
    this._filteredData.complete();
    this._filterSubscription.unsubscribe();
  }

  private _subscription() {
    return this.control.valueChanges
      .pipe(
        takeUntil(this._destroyedSubject),
        debounce(v =>
          v !== '' ? timer(FORM_SHEET_SELECT_SEARCH_DEBOUNCE) : of({}),
        ),
      )
      .subscribe(value => {
        let result = this._data;
        if (value.length) {
          result = this._fuse.search(value).map(({ item }) => item);
        }
        this._filteredData.next(result);
      });
  }
}
