import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import * as _ from 'lodash';
import { ChallengeType } from 'libs/domain';
import { RecursivePartial } from 'libs/util';

import { KeepDiscardDialogComponent } from '@app/src/app/dialog';
import { IChallengeDraft } from '@app/src/app/modules/challenges/types';
import { UserStorage } from '@app/src/app/services/UserStorage';

const STORAGE_DRAFT_KEY = 'MingaChallengeDraft';

function mergeDrafts(
  draft: IChallengeDraft,
  ...drafts: RecursivePartial<IChallengeDraft>[]
): IChallengeDraft;
function mergeDrafts(
  draft: RecursivePartial<IChallengeDraft>,
  ...drafts: RecursivePartial<IChallengeDraft>[]
): RecursivePartial<IChallengeDraft>;
function mergeDrafts(
  draft: RecursivePartial<IChallengeDraft> | IChallengeDraft,
  ...drafts: RecursivePartial<IChallengeDraft>[]
): RecursivePartial<IChallengeDraft> | IChallengeDraft {
  return _.merge(draft, ...drafts);
}

@Injectable({ providedIn: 'root' })
export class ChallengesDraftService {
  /** @internal */
  private draftSaveTimeout: any;
  /** @internal - pending draft that is going to be saved delayed */
  private _pendingDraft: RecursivePartial<IChallengeDraft> = {};

  constructor(private userStorage: UserStorage, private matDialog: MatDialog) {}

  async cancelDraft() {
    let dialogRef = this.matDialog.open(KeepDiscardDialogComponent);
    const value = await dialogRef.beforeClosed().toPromise();

    if (value === 'keep') {
      return '';
    } else if (value === 'discard') {
      await this.clearDraft();
      return '';
    } else {
      throw new Error('User abort');
    }
  }

  async getDraftType(draft?: IChallengeDraft): Promise<ChallengeType> {
    if (!draft) {
      const _draft = await this.getDraft();
      if (_draft) {
        draft = _draft;
      } else {
        throw new Error(`No Challenge Draft found for draft type!`);
      }
    }

    return draft.type;
  }

  async clearDraft() {
    await this.userStorage.setItem(STORAGE_DRAFT_KEY, null);
  }

  /**
   * Get the stored draft.
   * @returns `null` when no draft was stored
   */
  async getDraft(): Promise<IChallengeDraft | null> {
    const draft = await this.userStorage.getItem<IChallengeDraft>(
      STORAGE_DRAFT_KEY,
    );
    return draft || null;
  }

  /**
   * Get the stored draft. If there is no stored draft call `defaultFn` and
   * store and return the result.
   */
  async getDraftDefaulted(
    contentHash: string,
    defaultFn: () => IChallengeDraft | Promise<IChallengeDraft>,
  ): Promise<IChallengeDraft> {
    let draft = await this.getDraft();

    if (!draft || (contentHash && !(draft.contentHash == contentHash))) {
      draft = await defaultFn();
      await this.storeDraft(draft);
    }

    return draft;
  }

  /**
   * Get the stored draft and return empty object if not set
   */
  async getDraftAssert(): Promise<IChallengeDraft | {}> {
    const draft = await this.getDraft();
    if (!draft) {
      return {};
    }

    return draft;
  }

  async saveDraft(draft: RecursivePartial<IChallengeDraft>) {
    const pendingDraft = { ...this._pendingDraft };
    // clear roles in old draft so that they aren't merged back in
    pendingDraft.allowedRoles = [];

    this._pendingDraft = mergeDrafts(pendingDraft, draft);
    await this.savePendingDraft();
  }

  saveDraftDelayed(draft: RecursivePartial<IChallengeDraft>, time = 1000) {
    const pendingDraft = { ...this._pendingDraft };
    // clear roles in old draft so that they aren't merged back in
    pendingDraft.allowedRoles = [];

    this._pendingDraft = mergeDrafts(pendingDraft, draft);

    clearTimeout(this.draftSaveTimeout);
    this.draftSaveTimeout = setTimeout(() => this.savePendingDraft(), time);
  }

  private async savePendingDraft() {
    clearTimeout(this.draftSaveTimeout);
    const pendingDraft = { ...this._pendingDraft };
    this._pendingDraft = {};

    const previousDraft = await this.getDraftAssert();
    // clear roles in old draft so that they aren't merged back in
    if ('allowedRoles' in previousDraft) {
      previousDraft.allowedRoles = [];
    }

    const newDraft = mergeDrafts(previousDraft, pendingDraft);
    if (!pendingDraft.banner && pendingDraft.uploadedImage)
      newDraft.banner = undefined;
    if (!pendingDraft.badge && pendingDraft.uploadedBadge) {
      newDraft.badge = undefined;
    }

    await this.storeDraft(newDraft);
  }

  private async storeDraft(
    draft: IChallengeDraft | RecursivePartial<IChallengeDraft>,
  ) {
    await this.userStorage.setItem<
      IChallengeDraft | RecursivePartial<IChallengeDraft>
    >(STORAGE_DRAFT_KEY, draft);
  }
}
