import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { RouterModule } from '@angular/router';

import { MgEmptyStateModule } from '@app/src/app/components/EmptyState';
import { MgStreamScrollerModule } from '@app/src/app/components/MgStreamScroller';
import { MgDetailedModerationItemModule } from '@app/src/app/elements/MgDetailedModerationItem';
import { MgIconModule } from '@app/src/app/icon';
import { SearchModule } from '@app/src/app/search';
import { MgSpinnerModule } from '@app/src/app/spinner';

import { DirectMessageListModule } from '@modules/direct-message/components/dm-list/dm-list.module';
import { LayoutLegacyContentModule } from '@modules/layout/components/layout-legacy-content/layout-legacy-content.module';

import { GenericModule } from '@shared/components/generic';

import { ModerationDirectMessagesComponent } from './components/moderation-direct-messages/moderation-direct-messages.component';
import { ModerationPostsComponent } from './components/moderation-posts/moderation-posts.component';
import { ModerationReportsComponent } from './components/moderation-reports/moderation-reports.component';
import { ModerationComponent } from './moderation.component';

@NgModule({
  imports: [
    // Minga dependencies
    LayoutLegacyContentModule,
    MgIconModule,
    MgStreamScrollerModule,
    MgDetailedModerationItemModule,
    MgEmptyStateModule,
    MgSpinnerModule,
    SearchModule,
    GenericModule,
    MatButtonToggleModule,
    DirectMessageListModule,

    // External dependencies
    CommonModule,
    FlexLayoutModule,
    RouterModule,
  ],
  declarations: [
    ModerationComponent,
    ModerationPostsComponent,
    ModerationReportsComponent,
    ModerationDirectMessagesComponent,
  ],
  exports: [
    ModerationComponent,
    ModerationPostsComponent,
    ModerationReportsComponent,
    ModerationDirectMessagesComponent,
  ],
})
export class ModerationModule {}
