export enum MingaSubscriptionPlans {
  SCHOOL_100 = 'MingaLite100_Monthly',
  SCHOOL_250 = 'mingaSchool_250',
  SCHOOL_500 = 'mingaSchool_500',
  SCHOOL_750 = 'mingaSchool_750',
  SCHOOL_1000 = 'mingaSchool_1000',
  SCHOOL_1250 = 'mingaSchool_1250',
  SCHOOL_1500 = 'MingaLite1500_Monthly',
  SCHOOL_2500 = 'mingaSchool_2500',
  SCHOOL_3500 = 'mingaSchool_3500',
  SCHOOL_5000 = 'MingaSchool 5000',
  SCHOOL_5000_PLUS = 'mingaSchool_5000+',
  SCHOOL_FREE = 'Minga_Free',
}
