import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';

import { Observable, Subscription } from 'rxjs';

import { GroupsFacadeService } from '@app/src/app/groups/services';

@Component({
  selector: 'mg-welcome-district-school-select',
  templateUrl: './WelcomeDistrictSchoolSelect.component.html',
  styleUrls: ['./WelcomeDistrictSchoolSelect.component.scss'],
})
export class WelcomeDistrictSchoolSelectComponent implements OnInit, OnDestroy {
  readonly stepCount = 4;
  step: number = 4;
  canClickDone = false;
  groupSub: Subscription;

  @Output()
  done: EventEmitter<void> = new EventEmitter();

  constructor(private groupsFacadeService: GroupsFacadeService) {
    this.groupSub = this.groupsFacadeService
      .getMyParentGroups()
      .subscribe(groups => {
        if (groups.length > 0) {
          this.canClickDone = true;
        } else {
          this.canClickDone = false;
        }
      });
  }

  ngOnInit() {
    this.groupsFacadeService.dispatchLoadAll();
  }

  ngOnDestroy() {
    this.groupSub.unsubscribe();
  }
}
