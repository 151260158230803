// source: student/student_id.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

import * as jspb from 'google-protobuf';
import * as image_image_pb from '../image/image_pb';
import * as common_stream_pb from '../common/stream_pb';
import * as common_order_pb from '../common/order_pb';
import * as common_delta_pb from '../common/delta_pb';
import * as gateway_person_view_pb from '../gateway/person_view_pb';
import * as hall_pass_hall_pass_pb from '../hall_pass/hall_pass_pb';
import * as student_schedule_student_schedule_pb from '../student_schedule/student_schedule_pb';
export namespace ReadStudentIdRequest {
  export interface AsObject {
    personHash: string;
  }
}
export class ReadStudentIdRequest extends jspb.Message {
  static readonly displayName = "ReadStudentIdRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): ReadStudentIdRequest.AsObject {
    return ReadStudentIdRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!ReadStudentIdRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: ReadStudentIdRequest) {
    var f: any;
    var obj: any = {
      personHash: jspb.Message.getFieldWithDefault(msg, 1, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!ReadStudentIdRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new ReadStudentIdRequest;
    return ReadStudentIdRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!ReadStudentIdRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!ReadStudentIdRequest}
   */
  static deserializeBinaryFromReader(msg: ReadStudentIdRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setPersonHash(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!ReadStudentIdRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: ReadStudentIdRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getPersonHash();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    ReadStudentIdRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string person_hash = 1;
   * @return {string}
   */
  getPersonHash(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setPersonHash(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


}
export namespace ReadStudentIdResponse {
  export interface AsObject {
    studentId?: image_image_pb.ImageInfo.AsObject;
    asbMember: boolean;
    studentIdUrl: string;
    mingaCustomIcon?: image_image_pb.ImageInfo.AsObject;
    grade: string;
    studentIdNumber: string;
    name: string;
    mingaAddress: string;
    memberIconsList: image_image_pb.ImageInfo.AsObject[];
    fileName: string;
    active: boolean;
    tempPhoto: boolean;
    roleType: string;
    badgeIconUrl: string;
    personHash: string;
    printId: boolean;
    firstName: string;
    lastName: string;
    noAccess?: image_image_pb.ImageInfo.AsObject;
    idField1: string;
    idField2: string;
    hallPassesList: hall_pass_hall_pass_pb.HallPass.AsObject[];
    currentClassList: student_schedule_student_schedule_pb.StudentSection.AsObject[];
  }
}
export class ReadStudentIdResponse extends jspb.Message {
  static readonly displayName = "ReadStudentIdResponse";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [9,22,23];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, ReadStudentIdResponse.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): ReadStudentIdResponse.AsObject {
    return ReadStudentIdResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!ReadStudentIdResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: ReadStudentIdResponse) {
    var f: any;
    var obj: any = {
      studentId: (f = msg.getStudentId()) && image_image_pb.ImageInfo.toObject(includeInstance, f),
      asbMember: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
      studentIdUrl: jspb.Message.getFieldWithDefault(msg, 3, ""),
      mingaCustomIcon: (f = msg.getMingaCustomIcon()) && image_image_pb.ImageInfo.toObject(includeInstance, f),
      grade: jspb.Message.getFieldWithDefault(msg, 5, ""),
      studentIdNumber: jspb.Message.getFieldWithDefault(msg, 6, ""),
      name: jspb.Message.getFieldWithDefault(msg, 7, ""),
      mingaAddress: jspb.Message.getFieldWithDefault(msg, 8, ""),
      memberIconsList: jspb.Message.toObjectList(msg.getMemberIconsList(),
      image_image_pb.ImageInfo.toObject, includeInstance),
      fileName: jspb.Message.getFieldWithDefault(msg, 10, ""),
      active: jspb.Message.getBooleanFieldWithDefault(msg, 11, false),
      tempPhoto: jspb.Message.getBooleanFieldWithDefault(msg, 12, false),
      roleType: jspb.Message.getFieldWithDefault(msg, 13, ""),
      badgeIconUrl: jspb.Message.getFieldWithDefault(msg, 14, ""),
      personHash: jspb.Message.getFieldWithDefault(msg, 15, ""),
      printId: jspb.Message.getBooleanFieldWithDefault(msg, 16, false),
      firstName: jspb.Message.getFieldWithDefault(msg, 17, ""),
      lastName: jspb.Message.getFieldWithDefault(msg, 18, ""),
      noAccess: (f = msg.getNoAccess()) && image_image_pb.ImageInfo.toObject(includeInstance, f),
      idField1: jspb.Message.getFieldWithDefault(msg, 20, ""),
      idField2: jspb.Message.getFieldWithDefault(msg, 21, ""),
      hallPassesList: jspb.Message.toObjectList(msg.getHallPassesList(),
      hall_pass_hall_pass_pb.HallPass.toObject, includeInstance),
      currentClassList: jspb.Message.toObjectList(msg.getCurrentClassList(),
      student_schedule_student_schedule_pb.StudentSection.toObject, includeInstance)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!ReadStudentIdResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new ReadStudentIdResponse;
    return ReadStudentIdResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!ReadStudentIdResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!ReadStudentIdResponse}
   */
  static deserializeBinaryFromReader(msg: ReadStudentIdResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new image_image_pb.ImageInfo;
        reader.readMessage(value1,image_image_pb.ImageInfo.deserializeBinaryFromReader);
        msg.setStudentId(value1);
        break;
      case 2:
        var value2 = /** @type {boolean} */ (reader.readBool());
        msg.setAsbMember(value2);
        break;
      case 3:
        var value3 = /** @type {string} */ (reader.readString());
        msg.setStudentIdUrl(value3);
        break;
      case 4:
        var value4 = new image_image_pb.ImageInfo;
        reader.readMessage(value4,image_image_pb.ImageInfo.deserializeBinaryFromReader);
        msg.setMingaCustomIcon(value4);
        break;
      case 5:
        var value5 = /** @type {string} */ (reader.readString());
        msg.setGrade(value5);
        break;
      case 6:
        var value6 = /** @type {string} */ (reader.readString());
        msg.setStudentIdNumber(value6);
        break;
      case 7:
        var value7 = /** @type {string} */ (reader.readString());
        msg.setName(value7);
        break;
      case 8:
        var value8 = /** @type {string} */ (reader.readString());
        msg.setMingaAddress(value8);
        break;
      case 9:
        var value9 = new image_image_pb.ImageInfo;
        reader.readMessage(value9,image_image_pb.ImageInfo.deserializeBinaryFromReader);
        msg.addMemberIcons(value9);
        break;
      case 10:
        var value10 = /** @type {string} */ (reader.readString());
        msg.setFileName(value10);
        break;
      case 11:
        var value11 = /** @type {boolean} */ (reader.readBool());
        msg.setActive(value11);
        break;
      case 12:
        var value12 = /** @type {boolean} */ (reader.readBool());
        msg.setTempPhoto(value12);
        break;
      case 13:
        var value13 = /** @type {string} */ (reader.readString());
        msg.setRoleType(value13);
        break;
      case 14:
        var value14 = /** @type {string} */ (reader.readString());
        msg.setBadgeIconUrl(value14);
        break;
      case 15:
        var value15 = /** @type {string} */ (reader.readString());
        msg.setPersonHash(value15);
        break;
      case 16:
        var value16 = /** @type {boolean} */ (reader.readBool());
        msg.setPrintId(value16);
        break;
      case 17:
        var value17 = /** @type {string} */ (reader.readString());
        msg.setFirstName(value17);
        break;
      case 18:
        var value18 = /** @type {string} */ (reader.readString());
        msg.setLastName(value18);
        break;
      case 19:
        var value19 = new image_image_pb.ImageInfo;
        reader.readMessage(value19,image_image_pb.ImageInfo.deserializeBinaryFromReader);
        msg.setNoAccess(value19);
        break;
      case 20:
        var value20 = /** @type {string} */ (reader.readString());
        msg.setIdField1(value20);
        break;
      case 21:
        var value21 = /** @type {string} */ (reader.readString());
        msg.setIdField2(value21);
        break;
      case 22:
        var value22 = new hall_pass_hall_pass_pb.HallPass;
        reader.readMessage(value22,hall_pass_hall_pass_pb.HallPass.deserializeBinaryFromReader);
        msg.addHallPasses(value22);
        break;
      case 23:
        var value23 = new student_schedule_student_schedule_pb.StudentSection;
        reader.readMessage(value23,student_schedule_student_schedule_pb.StudentSection.deserializeBinaryFromReader);
        msg.addCurrentClass(value23);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!ReadStudentIdResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: ReadStudentIdResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getStudentId();
    if (f != null) {
      writer.writeMessage(
        1,
        f,
        image_image_pb.ImageInfo.serializeBinaryToWriter
      );
    }
    f = message.getAsbMember();
    if (f) {
      writer.writeBool(
        2,
        f
      );
    }
    f = message.getStudentIdUrl();
    if (f.length > 0) {
      writer.writeString(
        3,
        f
      );
    }
    f = message.getMingaCustomIcon();
    if (f != null) {
      writer.writeMessage(
        4,
        f,
        image_image_pb.ImageInfo.serializeBinaryToWriter
      );
    }
    f = message.getGrade();
    if (f.length > 0) {
      writer.writeString(
        5,
        f
      );
    }
    f = message.getStudentIdNumber();
    if (f.length > 0) {
      writer.writeString(
        6,
        f
      );
    }
    f = message.getName();
    if (f.length > 0) {
      writer.writeString(
        7,
        f
      );
    }
    f = message.getMingaAddress();
    if (f.length > 0) {
      writer.writeString(
        8,
        f
      );
    }
    f = message.getMemberIconsList();
    if (f.length > 0) {
      writer.writeRepeatedMessage(
        9,
        f,
        image_image_pb.ImageInfo.serializeBinaryToWriter
      );
    }
    f = message.getFileName();
    if (f.length > 0) {
      writer.writeString(
        10,
        f
      );
    }
    f = message.getActive();
    if (f) {
      writer.writeBool(
        11,
        f
      );
    }
    f = message.getTempPhoto();
    if (f) {
      writer.writeBool(
        12,
        f
      );
    }
    f = message.getRoleType();
    if (f.length > 0) {
      writer.writeString(
        13,
        f
      );
    }
    f = message.getBadgeIconUrl();
    if (f.length > 0) {
      writer.writeString(
        14,
        f
      );
    }
    f = message.getPersonHash();
    if (f.length > 0) {
      writer.writeString(
        15,
        f
      );
    }
    f = message.getPrintId();
    if (f) {
      writer.writeBool(
        16,
        f
      );
    }
    f = message.getFirstName();
    if (f.length > 0) {
      writer.writeString(
        17,
        f
      );
    }
    f = message.getLastName();
    if (f.length > 0) {
      writer.writeString(
        18,
        f
      );
    }
    f = message.getNoAccess();
    if (f != null) {
      writer.writeMessage(
        19,
        f,
        image_image_pb.ImageInfo.serializeBinaryToWriter
      );
    }
    f = message.getIdField1();
    if (f.length > 0) {
      writer.writeString(
        20,
        f
      );
    }
    f = message.getIdField2();
    if (f.length > 0) {
      writer.writeString(
        21,
        f
      );
    }
    f = message.getHallPassesList();
    if (f.length > 0) {
      writer.writeRepeatedMessage(
        22,
        f,
        hall_pass_hall_pass_pb.HallPass.serializeBinaryToWriter
      );
    }
    f = message.getCurrentClassList();
    if (f.length > 0) {
      writer.writeRepeatedMessage(
        23,
        f,
        student_schedule_student_schedule_pb.StudentSection.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    ReadStudentIdResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional ImageInfo student_id = 1;
   * @return {?ImageInfo}
   */
  getStudentId(): image_image_pb.ImageInfo {
    return /** @type{?ImageInfo} */ (
      jspb.Message.getWrapperField(this, image_image_pb.ImageInfo, 1));
  }


  /** @param {?ImageInfo|undefined} value */
  setStudentId(value?: image_image_pb.ImageInfo) {
    jspb.Message.setWrapperField(this, 1, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearStudentId() {
    this.setStudentId(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasStudentId(): boolean {
    return jspb.Message.getField(this, 1) != null;
  }


  /**
   * optional bool asb_member = 2;
   * @return {boolean}
   */
  getAsbMember(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
  };


  /** @param {boolean} value */
  setAsbMember(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 2, value);
  }


  /**
   * optional string student_id_url = 3;
   * @return {string}
   */
  getStudentIdUrl(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
  };


  /** @param {string} value */
  setStudentIdUrl(value: string) {
    jspb.Message.setProto3StringField(this, 3, value);
  }


  /**
   * optional ImageInfo minga_custom_icon = 4;
   * @return {?ImageInfo}
   */
  getMingaCustomIcon(): image_image_pb.ImageInfo {
    return /** @type{?ImageInfo} */ (
      jspb.Message.getWrapperField(this, image_image_pb.ImageInfo, 4));
  }


  /** @param {?ImageInfo|undefined} value */
  setMingaCustomIcon(value?: image_image_pb.ImageInfo) {
    jspb.Message.setWrapperField(this, 4, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearMingaCustomIcon() {
    this.setMingaCustomIcon(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasMingaCustomIcon(): boolean {
    return jspb.Message.getField(this, 4) != null;
  }


  /**
   * optional string grade = 5;
   * @return {string}
   */
  getGrade(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
  };


  /** @param {string} value */
  setGrade(value: string) {
    jspb.Message.setProto3StringField(this, 5, value);
  }


  /**
   * optional string student_id_number = 6;
   * @return {string}
   */
  getStudentIdNumber(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
  };


  /** @param {string} value */
  setStudentIdNumber(value: string) {
    jspb.Message.setProto3StringField(this, 6, value);
  }


  /**
   * optional string name = 7;
   * @return {string}
   */
  getName(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
  };


  /** @param {string} value */
  setName(value: string) {
    jspb.Message.setProto3StringField(this, 7, value);
  }


  /**
   * optional string minga_address = 8;
   * @return {string}
   */
  getMingaAddress(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
  };


  /** @param {string} value */
  setMingaAddress(value: string) {
    jspb.Message.setProto3StringField(this, 8, value);
  }


  /**
   * repeated ImageInfo member_icons = 9;
   * @return {!Array<!ImageInfo>}
   */
  getMemberIconsList(): image_image_pb.ImageInfo[] {
    return /** @type{!Array<!ImageInfo>} */ (
      jspb.Message.getRepeatedWrapperField(this, image_image_pb.ImageInfo, 9));
  }


  /** @param {!Array<!ImageInfo>} value */
  setMemberIconsList(value?: image_image_pb.ImageInfo[]) {
    jspb.Message.setRepeatedWrapperField(this, 9, value);
  }


  /**
   * @param {!ImageInfo=} opt_value
   * @param {number=} opt_index
   * @return {!ImageInfo}
   */
  addMemberIcons(opt_value?: image_image_pb.ImageInfo, opt_index?: number): image_image_pb.ImageInfo {
    return jspb.Message.addToRepeatedWrapperField(this, 9, opt_value, image_image_pb.ImageInfo, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearMemberIconsList() {
    this.setMemberIconsList([]);
  }


  /**
   * optional string file_name = 10;
   * @return {string}
   */
  getFileName(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
  };


  /** @param {string} value */
  setFileName(value: string) {
    jspb.Message.setProto3StringField(this, 10, value);
  }


  /**
   * optional bool active = 11;
   * @return {boolean}
   */
  getActive(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
  };


  /** @param {boolean} value */
  setActive(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 11, value);
  }


  /**
   * optional bool temp_photo = 12;
   * @return {boolean}
   */
  getTempPhoto(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 12, false));
  };


  /** @param {boolean} value */
  setTempPhoto(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 12, value);
  }


  /**
   * optional string role_type = 13;
   * @return {string}
   */
  getRoleType(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
  };


  /** @param {string} value */
  setRoleType(value: string) {
    jspb.Message.setProto3StringField(this, 13, value);
  }


  /**
   * optional string badge_icon_url = 14;
   * @return {string}
   */
  getBadgeIconUrl(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
  };


  /** @param {string} value */
  setBadgeIconUrl(value: string) {
    jspb.Message.setProto3StringField(this, 14, value);
  }


  /**
   * optional string person_hash = 15;
   * @return {string}
   */
  getPersonHash(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
  };


  /** @param {string} value */
  setPersonHash(value: string) {
    jspb.Message.setProto3StringField(this, 15, value);
  }


  /**
   * optional bool print_id = 16;
   * @return {boolean}
   */
  getPrintId(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 16, false));
  };


  /** @param {boolean} value */
  setPrintId(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 16, value);
  }


  /**
   * optional string first_name = 17;
   * @return {string}
   */
  getFirstName(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
  };


  /** @param {string} value */
  setFirstName(value: string) {
    jspb.Message.setProto3StringField(this, 17, value);
  }


  /**
   * optional string last_name = 18;
   * @return {string}
   */
  getLastName(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
  };


  /** @param {string} value */
  setLastName(value: string) {
    jspb.Message.setProto3StringField(this, 18, value);
  }


  /**
   * optional ImageInfo no_access = 19;
   * @return {?ImageInfo}
   */
  getNoAccess(): image_image_pb.ImageInfo {
    return /** @type{?ImageInfo} */ (
      jspb.Message.getWrapperField(this, image_image_pb.ImageInfo, 19));
  }


  /** @param {?ImageInfo|undefined} value */
  setNoAccess(value?: image_image_pb.ImageInfo) {
    jspb.Message.setWrapperField(this, 19, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearNoAccess() {
    this.setNoAccess(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasNoAccess(): boolean {
    return jspb.Message.getField(this, 19) != null;
  }


  /**
   * optional string id_field_1 = 20;
   * @return {string}
   */
  getIdField1(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
  };


  /** @param {string} value */
  setIdField1(value: string) {
    jspb.Message.setProto3StringField(this, 20, value);
  }


  /**
   * optional string id_field_2 = 21;
   * @return {string}
   */
  getIdField2(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 21, ""));
  };


  /** @param {string} value */
  setIdField2(value: string) {
    jspb.Message.setProto3StringField(this, 21, value);
  }


  /**
   * repeated minga.hall_pass.HallPass hall_passes = 22;
   * @return {!Array<!HallPass>}
   */
  getHallPassesList(): hall_pass_hall_pass_pb.HallPass[] {
    return /** @type{!Array<!HallPass>} */ (
      jspb.Message.getRepeatedWrapperField(this, hall_pass_hall_pass_pb.HallPass, 22));
  }


  /** @param {!Array<!HallPass>} value */
  setHallPassesList(value?: hall_pass_hall_pass_pb.HallPass[]) {
    jspb.Message.setRepeatedWrapperField(this, 22, value);
  }


  /**
   * @param {!HallPass=} opt_value
   * @param {number=} opt_index
   * @return {!HallPass}
   */
  addHallPasses(opt_value?: hall_pass_hall_pass_pb.HallPass, opt_index?: number): hall_pass_hall_pass_pb.HallPass {
    return jspb.Message.addToRepeatedWrapperField(this, 22, opt_value, hall_pass_hall_pass_pb.HallPass, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearHallPassesList() {
    this.setHallPassesList([]);
  }


  /**
   * repeated minga.student_schedule.StudentSection current_class = 23;
   * @return {!Array<!StudentSection>}
   */
  getCurrentClassList(): student_schedule_student_schedule_pb.StudentSection[] {
    return /** @type{!Array<!StudentSection>} */ (
      jspb.Message.getRepeatedWrapperField(this, student_schedule_student_schedule_pb.StudentSection, 23));
  }


  /** @param {!Array<!StudentSection>} value */
  setCurrentClassList(value?: student_schedule_student_schedule_pb.StudentSection[]) {
    jspb.Message.setRepeatedWrapperField(this, 23, value);
  }


  /**
   * @param {!StudentSection=} opt_value
   * @param {number=} opt_index
   * @return {!StudentSection}
   */
  addCurrentClass(opt_value?: student_schedule_student_schedule_pb.StudentSection, opt_index?: number): student_schedule_student_schedule_pb.StudentSection {
    return jspb.Message.addToRepeatedWrapperField(this, 23, opt_value, student_schedule_student_schedule_pb.StudentSection, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearCurrentClassList() {
    this.setCurrentClassList([]);
  }


}
export namespace UpdateStudentIdRequest {
  export interface AsObject {
    assetPath: string;
    asbMember: boolean;
    studentIdUrl: string;
    personHash: string;
    active?: common_delta_pb.BoolDelta.AsObject;
    studentIdNumber?: common_delta_pb.StringDelta.AsObject;
    displayName?: common_delta_pb.StringDelta.AsObject;
    fileName?: common_delta_pb.StringDelta.AsObject;
    tempPhoto?: common_delta_pb.BoolDelta.AsObject;
    printId?: common_delta_pb.BoolDelta.AsObject;
    firstName?: common_delta_pb.StringDelta.AsObject;
    lastName?: common_delta_pb.StringDelta.AsObject;
  }
}
export class UpdateStudentIdRequest extends jspb.Message {
  static readonly displayName = "UpdateStudentIdRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): UpdateStudentIdRequest.AsObject {
    return UpdateStudentIdRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!UpdateStudentIdRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: UpdateStudentIdRequest) {
    var f: any;
    var obj: any = {
      assetPath: jspb.Message.getFieldWithDefault(msg, 1, ""),
      asbMember: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
      studentIdUrl: jspb.Message.getFieldWithDefault(msg, 3, ""),
      personHash: jspb.Message.getFieldWithDefault(msg, 4, ""),
      active: (f = msg.getActive()) && common_delta_pb.BoolDelta.toObject(includeInstance, f),
      studentIdNumber: (f = msg.getStudentIdNumber()) && common_delta_pb.StringDelta.toObject(includeInstance, f),
      displayName: (f = msg.getDisplayName()) && common_delta_pb.StringDelta.toObject(includeInstance, f),
      fileName: (f = msg.getFileName()) && common_delta_pb.StringDelta.toObject(includeInstance, f),
      tempPhoto: (f = msg.getTempPhoto()) && common_delta_pb.BoolDelta.toObject(includeInstance, f),
      printId: (f = msg.getPrintId()) && common_delta_pb.BoolDelta.toObject(includeInstance, f),
      firstName: (f = msg.getFirstName()) && common_delta_pb.StringDelta.toObject(includeInstance, f),
      lastName: (f = msg.getLastName()) && common_delta_pb.StringDelta.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!UpdateStudentIdRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new UpdateStudentIdRequest;
    return UpdateStudentIdRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!UpdateStudentIdRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!UpdateStudentIdRequest}
   */
  static deserializeBinaryFromReader(msg: UpdateStudentIdRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setAssetPath(value1);
        break;
      case 2:
        var value2 = /** @type {boolean} */ (reader.readBool());
        msg.setAsbMember(value2);
        break;
      case 3:
        var value3 = /** @type {string} */ (reader.readString());
        msg.setStudentIdUrl(value3);
        break;
      case 4:
        var value4 = /** @type {string} */ (reader.readString());
        msg.setPersonHash(value4);
        break;
      case 5:
        var value5 = new common_delta_pb.BoolDelta;
        reader.readMessage(value5,common_delta_pb.BoolDelta.deserializeBinaryFromReader);
        msg.setActive(value5);
        break;
      case 6:
        var value6 = new common_delta_pb.StringDelta;
        reader.readMessage(value6,common_delta_pb.StringDelta.deserializeBinaryFromReader);
        msg.setStudentIdNumber(value6);
        break;
      case 7:
        var value7 = new common_delta_pb.StringDelta;
        reader.readMessage(value7,common_delta_pb.StringDelta.deserializeBinaryFromReader);
        msg.setDisplayName(value7);
        break;
      case 8:
        var value8 = new common_delta_pb.StringDelta;
        reader.readMessage(value8,common_delta_pb.StringDelta.deserializeBinaryFromReader);
        msg.setFileName(value8);
        break;
      case 9:
        var value9 = new common_delta_pb.BoolDelta;
        reader.readMessage(value9,common_delta_pb.BoolDelta.deserializeBinaryFromReader);
        msg.setTempPhoto(value9);
        break;
      case 10:
        var value10 = new common_delta_pb.BoolDelta;
        reader.readMessage(value10,common_delta_pb.BoolDelta.deserializeBinaryFromReader);
        msg.setPrintId(value10);
        break;
      case 11:
        var value11 = new common_delta_pb.StringDelta;
        reader.readMessage(value11,common_delta_pb.StringDelta.deserializeBinaryFromReader);
        msg.setFirstName(value11);
        break;
      case 12:
        var value12 = new common_delta_pb.StringDelta;
        reader.readMessage(value12,common_delta_pb.StringDelta.deserializeBinaryFromReader);
        msg.setLastName(value12);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!UpdateStudentIdRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: UpdateStudentIdRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getAssetPath();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
    f = message.getAsbMember();
    if (f) {
      writer.writeBool(
        2,
        f
      );
    }
    f = message.getStudentIdUrl();
    if (f.length > 0) {
      writer.writeString(
        3,
        f
      );
    }
    f = message.getPersonHash();
    if (f.length > 0) {
      writer.writeString(
        4,
        f
      );
    }
    f = message.getActive();
    if (f != null) {
      writer.writeMessage(
        5,
        f,
        common_delta_pb.BoolDelta.serializeBinaryToWriter
      );
    }
    f = message.getStudentIdNumber();
    if (f != null) {
      writer.writeMessage(
        6,
        f,
        common_delta_pb.StringDelta.serializeBinaryToWriter
      );
    }
    f = message.getDisplayName();
    if (f != null) {
      writer.writeMessage(
        7,
        f,
        common_delta_pb.StringDelta.serializeBinaryToWriter
      );
    }
    f = message.getFileName();
    if (f != null) {
      writer.writeMessage(
        8,
        f,
        common_delta_pb.StringDelta.serializeBinaryToWriter
      );
    }
    f = message.getTempPhoto();
    if (f != null) {
      writer.writeMessage(
        9,
        f,
        common_delta_pb.BoolDelta.serializeBinaryToWriter
      );
    }
    f = message.getPrintId();
    if (f != null) {
      writer.writeMessage(
        10,
        f,
        common_delta_pb.BoolDelta.serializeBinaryToWriter
      );
    }
    f = message.getFirstName();
    if (f != null) {
      writer.writeMessage(
        11,
        f,
        common_delta_pb.StringDelta.serializeBinaryToWriter
      );
    }
    f = message.getLastName();
    if (f != null) {
      writer.writeMessage(
        12,
        f,
        common_delta_pb.StringDelta.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    UpdateStudentIdRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string asset_path = 1;
   * @return {string}
   */
  getAssetPath(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setAssetPath(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


  /**
   * optional bool asb_member = 2;
   * @return {boolean}
   */
  getAsbMember(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
  };


  /** @param {boolean} value */
  setAsbMember(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 2, value);
  }


  /**
   * optional string student_id_url = 3;
   * @return {string}
   */
  getStudentIdUrl(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
  };


  /** @param {string} value */
  setStudentIdUrl(value: string) {
    jspb.Message.setProto3StringField(this, 3, value);
  }


  /**
   * optional string person_hash = 4;
   * @return {string}
   */
  getPersonHash(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
  };


  /** @param {string} value */
  setPersonHash(value: string) {
    jspb.Message.setProto3StringField(this, 4, value);
  }


  /**
   * optional minga.common.BoolDelta active = 5;
   * @return {?BoolDelta}
   */
  getActive(): common_delta_pb.BoolDelta {
    return /** @type{?BoolDelta} */ (
      jspb.Message.getWrapperField(this, common_delta_pb.BoolDelta, 5));
  }


  /** @param {?BoolDelta|undefined} value */
  setActive(value?: common_delta_pb.BoolDelta) {
    jspb.Message.setWrapperField(this, 5, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearActive() {
    this.setActive(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasActive(): boolean {
    return jspb.Message.getField(this, 5) != null;
  }


  /**
   * optional minga.common.StringDelta student_id_number = 6;
   * @return {?StringDelta}
   */
  getStudentIdNumber(): common_delta_pb.StringDelta {
    return /** @type{?StringDelta} */ (
      jspb.Message.getWrapperField(this, common_delta_pb.StringDelta, 6));
  }


  /** @param {?StringDelta|undefined} value */
  setStudentIdNumber(value?: common_delta_pb.StringDelta) {
    jspb.Message.setWrapperField(this, 6, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearStudentIdNumber() {
    this.setStudentIdNumber(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasStudentIdNumber(): boolean {
    return jspb.Message.getField(this, 6) != null;
  }


  /**
   * optional minga.common.StringDelta display_name = 7;
   * @return {?StringDelta}
   */
  getDisplayName(): common_delta_pb.StringDelta {
    return /** @type{?StringDelta} */ (
      jspb.Message.getWrapperField(this, common_delta_pb.StringDelta, 7));
  }


  /** @param {?StringDelta|undefined} value */
  setDisplayName(value?: common_delta_pb.StringDelta) {
    jspb.Message.setWrapperField(this, 7, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearDisplayName() {
    this.setDisplayName(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasDisplayName(): boolean {
    return jspb.Message.getField(this, 7) != null;
  }


  /**
   * optional minga.common.StringDelta file_name = 8;
   * @return {?StringDelta}
   */
  getFileName(): common_delta_pb.StringDelta {
    return /** @type{?StringDelta} */ (
      jspb.Message.getWrapperField(this, common_delta_pb.StringDelta, 8));
  }


  /** @param {?StringDelta|undefined} value */
  setFileName(value?: common_delta_pb.StringDelta) {
    jspb.Message.setWrapperField(this, 8, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearFileName() {
    this.setFileName(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasFileName(): boolean {
    return jspb.Message.getField(this, 8) != null;
  }


  /**
   * optional minga.common.BoolDelta temp_photo = 9;
   * @return {?BoolDelta}
   */
  getTempPhoto(): common_delta_pb.BoolDelta {
    return /** @type{?BoolDelta} */ (
      jspb.Message.getWrapperField(this, common_delta_pb.BoolDelta, 9));
  }


  /** @param {?BoolDelta|undefined} value */
  setTempPhoto(value?: common_delta_pb.BoolDelta) {
    jspb.Message.setWrapperField(this, 9, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearTempPhoto() {
    this.setTempPhoto(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasTempPhoto(): boolean {
    return jspb.Message.getField(this, 9) != null;
  }


  /**
   * optional minga.common.BoolDelta print_id = 10;
   * @return {?BoolDelta}
   */
  getPrintId(): common_delta_pb.BoolDelta {
    return /** @type{?BoolDelta} */ (
      jspb.Message.getWrapperField(this, common_delta_pb.BoolDelta, 10));
  }


  /** @param {?BoolDelta|undefined} value */
  setPrintId(value?: common_delta_pb.BoolDelta) {
    jspb.Message.setWrapperField(this, 10, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearPrintId() {
    this.setPrintId(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasPrintId(): boolean {
    return jspb.Message.getField(this, 10) != null;
  }


  /**
   * optional minga.common.StringDelta first_name = 11;
   * @return {?StringDelta}
   */
  getFirstName(): common_delta_pb.StringDelta {
    return /** @type{?StringDelta} */ (
      jspb.Message.getWrapperField(this, common_delta_pb.StringDelta, 11));
  }


  /** @param {?StringDelta|undefined} value */
  setFirstName(value?: common_delta_pb.StringDelta) {
    jspb.Message.setWrapperField(this, 11, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearFirstName() {
    this.setFirstName(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasFirstName(): boolean {
    return jspb.Message.getField(this, 11) != null;
  }


  /**
   * optional minga.common.StringDelta last_name = 12;
   * @return {?StringDelta}
   */
  getLastName(): common_delta_pb.StringDelta {
    return /** @type{?StringDelta} */ (
      jspb.Message.getWrapperField(this, common_delta_pb.StringDelta, 12));
  }


  /** @param {?StringDelta|undefined} value */
  setLastName(value?: common_delta_pb.StringDelta) {
    jspb.Message.setWrapperField(this, 12, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearLastName() {
    this.setLastName(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasLastName(): boolean {
    return jspb.Message.getField(this, 12) != null;
  }


}
export namespace UpdateStudentIdResponse {
  export interface AsObject {
  }
}
export class UpdateStudentIdResponse extends jspb.Message {
  static readonly displayName = "UpdateStudentIdResponse";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): UpdateStudentIdResponse.AsObject {
    return UpdateStudentIdResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!UpdateStudentIdResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: UpdateStudentIdResponse) {
    var f: any;
    var obj: any = {

    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!UpdateStudentIdResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new UpdateStudentIdResponse;
    return UpdateStudentIdResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!UpdateStudentIdResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!UpdateStudentIdResponse}
   */
  static deserializeBinaryFromReader(msg: UpdateStudentIdResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!UpdateStudentIdResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: UpdateStudentIdResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    UpdateStudentIdResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
}
export namespace DeleteStudentIdImageRequest {
  export interface AsObject {
    personHash: string;
  }
}
export class DeleteStudentIdImageRequest extends jspb.Message {
  static readonly displayName = "DeleteStudentIdImageRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): DeleteStudentIdImageRequest.AsObject {
    return DeleteStudentIdImageRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!DeleteStudentIdImageRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: DeleteStudentIdImageRequest) {
    var f: any;
    var obj: any = {
      personHash: jspb.Message.getFieldWithDefault(msg, 1, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!DeleteStudentIdImageRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new DeleteStudentIdImageRequest;
    return DeleteStudentIdImageRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!DeleteStudentIdImageRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!DeleteStudentIdImageRequest}
   */
  static deserializeBinaryFromReader(msg: DeleteStudentIdImageRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setPersonHash(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!DeleteStudentIdImageRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: DeleteStudentIdImageRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getPersonHash();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    DeleteStudentIdImageRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string person_hash = 1;
   * @return {string}
   */
  getPersonHash(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setPersonHash(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


}
export namespace DeleteStudentIdImageResponse {
  export interface AsObject {
  }
}
export class DeleteStudentIdImageResponse extends jspb.Message {
  static readonly displayName = "DeleteStudentIdImageResponse";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): DeleteStudentIdImageResponse.AsObject {
    return DeleteStudentIdImageResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!DeleteStudentIdImageResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: DeleteStudentIdImageResponse) {
    var f: any;
    var obj: any = {

    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!DeleteStudentIdImageResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new DeleteStudentIdImageResponse;
    return DeleteStudentIdImageResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!DeleteStudentIdImageResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!DeleteStudentIdImageResponse}
   */
  static deserializeBinaryFromReader(msg: DeleteStudentIdImageResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!DeleteStudentIdImageResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: DeleteStudentIdImageResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    DeleteStudentIdImageResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
}
export namespace DeleteStudentIdRequest {
  export interface AsObject {
  }
}
export class DeleteStudentIdRequest extends jspb.Message {
  static readonly displayName = "DeleteStudentIdRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): DeleteStudentIdRequest.AsObject {
    return DeleteStudentIdRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!DeleteStudentIdRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: DeleteStudentIdRequest) {
    var f: any;
    var obj: any = {

    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!DeleteStudentIdRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new DeleteStudentIdRequest;
    return DeleteStudentIdRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!DeleteStudentIdRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!DeleteStudentIdRequest}
   */
  static deserializeBinaryFromReader(msg: DeleteStudentIdRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!DeleteStudentIdRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: DeleteStudentIdRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    DeleteStudentIdRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
}
export namespace DeleteStudentIdResponse {
  export interface AsObject {
  }
}
export class DeleteStudentIdResponse extends jspb.Message {
  static readonly displayName = "DeleteStudentIdResponse";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): DeleteStudentIdResponse.AsObject {
    return DeleteStudentIdResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!DeleteStudentIdResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: DeleteStudentIdResponse) {
    var f: any;
    var obj: any = {

    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!DeleteStudentIdResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new DeleteStudentIdResponse;
    return DeleteStudentIdResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!DeleteStudentIdResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!DeleteStudentIdResponse}
   */
  static deserializeBinaryFromReader(msg: DeleteStudentIdResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!DeleteStudentIdResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: DeleteStudentIdResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    DeleteStudentIdResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
}
export namespace PersonIdentityListRequest {
  export interface AsObject {
  }
}
export class PersonIdentityListRequest extends jspb.Message {
  static readonly displayName = "PersonIdentityListRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): PersonIdentityListRequest.AsObject {
    return PersonIdentityListRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!PersonIdentityListRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: PersonIdentityListRequest) {
    var f: any;
    var obj: any = {

    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!PersonIdentityListRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new PersonIdentityListRequest;
    return PersonIdentityListRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!PersonIdentityListRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!PersonIdentityListRequest}
   */
  static deserializeBinaryFromReader(msg: PersonIdentityListRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!PersonIdentityListRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: PersonIdentityListRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    PersonIdentityListRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
}
export namespace PersonIdentityListItem {
  export interface AsObject {
    personHash: string;
    email: string;
    name: string;
    studentId: string;
  }
}
export class PersonIdentityListItem extends jspb.Message {
  static readonly displayName = "PersonIdentityListItem";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): PersonIdentityListItem.AsObject {
    return PersonIdentityListItem.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!PersonIdentityListItem} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: PersonIdentityListItem) {
    var f: any;
    var obj: any = {
      personHash: jspb.Message.getFieldWithDefault(msg, 1, ""),
      email: jspb.Message.getFieldWithDefault(msg, 2, ""),
      name: jspb.Message.getFieldWithDefault(msg, 3, ""),
      studentId: jspb.Message.getFieldWithDefault(msg, 4, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!PersonIdentityListItem}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new PersonIdentityListItem;
    return PersonIdentityListItem.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!PersonIdentityListItem} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!PersonIdentityListItem}
   */
  static deserializeBinaryFromReader(msg: PersonIdentityListItem, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setPersonHash(value1);
        break;
      case 2:
        var value2 = /** @type {string} */ (reader.readString());
        msg.setEmail(value2);
        break;
      case 3:
        var value3 = /** @type {string} */ (reader.readString());
        msg.setName(value3);
        break;
      case 4:
        var value4 = /** @type {string} */ (reader.readString());
        msg.setStudentId(value4);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!PersonIdentityListItem} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: PersonIdentityListItem, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getPersonHash();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
    f = message.getEmail();
    if (f.length > 0) {
      writer.writeString(
        2,
        f
      );
    }
    f = message.getName();
    if (f.length > 0) {
      writer.writeString(
        3,
        f
      );
    }
    f = message.getStudentId();
    if (f.length > 0) {
      writer.writeString(
        4,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    PersonIdentityListItem.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string person_hash = 1;
   * @return {string}
   */
  getPersonHash(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setPersonHash(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


  /**
   * optional string email = 2;
   * @return {string}
   */
  getEmail(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
  };


  /** @param {string} value */
  setEmail(value: string) {
    jspb.Message.setProto3StringField(this, 2, value);
  }


  /**
   * optional string name = 3;
   * @return {string}
   */
  getName(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
  };


  /** @param {string} value */
  setName(value: string) {
    jspb.Message.setProto3StringField(this, 3, value);
  }


  /**
   * optional string student_id = 4;
   * @return {string}
   */
  getStudentId(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
  };


  /** @param {string} value */
  setStudentId(value: string) {
    jspb.Message.setProto3StringField(this, 4, value);
  }


}
export namespace PersonIdentityListResponse {
  export interface AsObject {
    rowsList: PersonIdentityListItem.AsObject[];
  }
}
export class PersonIdentityListResponse extends jspb.Message {
  static readonly displayName = "PersonIdentityListResponse";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [1];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, PersonIdentityListResponse.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): PersonIdentityListResponse.AsObject {
    return PersonIdentityListResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!PersonIdentityListResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: PersonIdentityListResponse) {
    var f: any;
    var obj: any = {
      rowsList: jspb.Message.toObjectList(msg.getRowsList(),
      PersonIdentityListItem.toObject, includeInstance)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!PersonIdentityListResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new PersonIdentityListResponse;
    return PersonIdentityListResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!PersonIdentityListResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!PersonIdentityListResponse}
   */
  static deserializeBinaryFromReader(msg: PersonIdentityListResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new PersonIdentityListItem;
        reader.readMessage(value1,PersonIdentityListItem.deserializeBinaryFromReader);
        msg.addRows(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!PersonIdentityListResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: PersonIdentityListResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getRowsList();
    if (f.length > 0) {
      writer.writeRepeatedMessage(
        1,
        f,
        PersonIdentityListItem.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    PersonIdentityListResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * repeated PersonIdentityListItem rows = 1;
   * @return {!Array<!PersonIdentityListItem>}
   */
  getRowsList(): PersonIdentityListItem[] {
    return /** @type{!Array<!PersonIdentityListItem>} */ (
      jspb.Message.getRepeatedWrapperField(this, PersonIdentityListItem, 1));
  }


  /** @param {!Array<!PersonIdentityListItem>} value */
  setRowsList(value?: PersonIdentityListItem[]) {
    jspb.Message.setRepeatedWrapperField(this, 1, value);
  }


  /**
   * @param {!PersonIdentityListItem=} opt_value
   * @param {number=} opt_index
   * @return {!PersonIdentityListItem}
   */
  addRows(opt_value?: PersonIdentityListItem, opt_index?: number): PersonIdentityListItem {
    return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, PersonIdentityListItem, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearRowsList() {
    this.setRowsList([]);
  }


}
export namespace BulkIdImageUploadRequest {
  export interface AsObject {
    rowsList: BulkIdImageUploadRow.AsObject[];
  }
}
export class BulkIdImageUploadRequest extends jspb.Message {
  static readonly displayName = "BulkIdImageUploadRequest";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [1];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, BulkIdImageUploadRequest.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): BulkIdImageUploadRequest.AsObject {
    return BulkIdImageUploadRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!BulkIdImageUploadRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: BulkIdImageUploadRequest) {
    var f: any;
    var obj: any = {
      rowsList: jspb.Message.toObjectList(msg.getRowsList(),
      BulkIdImageUploadRow.toObject, includeInstance)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!BulkIdImageUploadRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new BulkIdImageUploadRequest;
    return BulkIdImageUploadRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!BulkIdImageUploadRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!BulkIdImageUploadRequest}
   */
  static deserializeBinaryFromReader(msg: BulkIdImageUploadRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new BulkIdImageUploadRow;
        reader.readMessage(value1,BulkIdImageUploadRow.deserializeBinaryFromReader);
        msg.addRows(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!BulkIdImageUploadRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: BulkIdImageUploadRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getRowsList();
    if (f.length > 0) {
      writer.writeRepeatedMessage(
        1,
        f,
        BulkIdImageUploadRow.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    BulkIdImageUploadRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * repeated BulkIdImageUploadRow rows = 1;
   * @return {!Array<!BulkIdImageUploadRow>}
   */
  getRowsList(): BulkIdImageUploadRow[] {
    return /** @type{!Array<!BulkIdImageUploadRow>} */ (
      jspb.Message.getRepeatedWrapperField(this, BulkIdImageUploadRow, 1));
  }


  /** @param {!Array<!BulkIdImageUploadRow>} value */
  setRowsList(value?: BulkIdImageUploadRow[]) {
    jspb.Message.setRepeatedWrapperField(this, 1, value);
  }


  /**
   * @param {!BulkIdImageUploadRow=} opt_value
   * @param {number=} opt_index
   * @return {!BulkIdImageUploadRow}
   */
  addRows(opt_value?: BulkIdImageUploadRow, opt_index?: number): BulkIdImageUploadRow {
    return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, BulkIdImageUploadRow, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearRowsList() {
    this.setRowsList([]);
  }


}
export namespace BulkIdImageUploadRow {
  export interface AsObject {
    personHash: string;
    fileName: string;
    error: string;
  }
}
export class BulkIdImageUploadRow extends jspb.Message {
  static readonly displayName = "BulkIdImageUploadRow";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): BulkIdImageUploadRow.AsObject {
    return BulkIdImageUploadRow.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!BulkIdImageUploadRow} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: BulkIdImageUploadRow) {
    var f: any;
    var obj: any = {
      personhash: jspb.Message.getFieldWithDefault(msg, 1, ""),
      fileName: jspb.Message.getFieldWithDefault(msg, 2, ""),
      error: jspb.Message.getFieldWithDefault(msg, 3, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!BulkIdImageUploadRow}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new BulkIdImageUploadRow;
    return BulkIdImageUploadRow.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!BulkIdImageUploadRow} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!BulkIdImageUploadRow}
   */
  static deserializeBinaryFromReader(msg: BulkIdImageUploadRow, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setPersonhash(value1);
        break;
      case 2:
        var value2 = /** @type {string} */ (reader.readString());
        msg.setFileName(value2);
        break;
      case 3:
        var value3 = /** @type {string} */ (reader.readString());
        msg.setError(value3);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!BulkIdImageUploadRow} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: BulkIdImageUploadRow, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getPersonhash();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
    f = message.getFileName();
    if (f.length > 0) {
      writer.writeString(
        2,
        f
      );
    }
    f = message.getError();
    if (f.length > 0) {
      writer.writeString(
        3,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    BulkIdImageUploadRow.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string personHash = 1;
   * @return {string}
   */
  getPersonhash(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setPersonhash(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


  /**
   * optional string file_name = 2;
   * @return {string}
   */
  getFileName(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
  };


  /** @param {string} value */
  setFileName(value: string) {
    jspb.Message.setProto3StringField(this, 2, value);
  }


  /**
   * optional string error = 3;
   * @return {string}
   */
  getError(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
  };


  /** @param {string} value */
  setError(value: string) {
    jspb.Message.setProto3StringField(this, 3, value);
  }


}
export namespace BulkIdImageUploadResponse {
  export interface AsObject {
    rowsList: BulkIdImageUploadRow.AsObject[];
  }
}
export class BulkIdImageUploadResponse extends jspb.Message {
  static readonly displayName = "BulkIdImageUploadResponse";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [1];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, BulkIdImageUploadResponse.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): BulkIdImageUploadResponse.AsObject {
    return BulkIdImageUploadResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!BulkIdImageUploadResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: BulkIdImageUploadResponse) {
    var f: any;
    var obj: any = {
      rowsList: jspb.Message.toObjectList(msg.getRowsList(),
      BulkIdImageUploadRow.toObject, includeInstance)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!BulkIdImageUploadResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new BulkIdImageUploadResponse;
    return BulkIdImageUploadResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!BulkIdImageUploadResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!BulkIdImageUploadResponse}
   */
  static deserializeBinaryFromReader(msg: BulkIdImageUploadResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new BulkIdImageUploadRow;
        reader.readMessage(value1,BulkIdImageUploadRow.deserializeBinaryFromReader);
        msg.addRows(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!BulkIdImageUploadResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: BulkIdImageUploadResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getRowsList();
    if (f.length > 0) {
      writer.writeRepeatedMessage(
        1,
        f,
        BulkIdImageUploadRow.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    BulkIdImageUploadResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * repeated BulkIdImageUploadRow rows = 1;
   * @return {!Array<!BulkIdImageUploadRow>}
   */
  getRowsList(): BulkIdImageUploadRow[] {
    return /** @type{!Array<!BulkIdImageUploadRow>} */ (
      jspb.Message.getRepeatedWrapperField(this, BulkIdImageUploadRow, 1));
  }


  /** @param {!Array<!BulkIdImageUploadRow>} value */
  setRowsList(value?: BulkIdImageUploadRow[]) {
    jspb.Message.setRepeatedWrapperField(this, 1, value);
  }


  /**
   * @param {!BulkIdImageUploadRow=} opt_value
   * @param {number=} opt_index
   * @return {!BulkIdImageUploadRow}
   */
  addRows(opt_value?: BulkIdImageUploadRow, opt_index?: number): BulkIdImageUploadRow {
    return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, BulkIdImageUploadRow, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearRowsList() {
    this.setRowsList([]);
  }


}
export namespace GetMembershipListMembersResponse {
  export interface AsObject {
    personList: gateway_person_view_pb.PersonViewMinimal.AsObject[];
  }
}
export class GetMembershipListMembersResponse extends jspb.Message {
  static readonly displayName = "GetMembershipListMembersResponse";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [1];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, GetMembershipListMembersResponse.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): GetMembershipListMembersResponse.AsObject {
    return GetMembershipListMembersResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!GetMembershipListMembersResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: GetMembershipListMembersResponse) {
    var f: any;
    var obj: any = {
      personList: jspb.Message.toObjectList(msg.getPersonList(),
      gateway_person_view_pb.PersonViewMinimal.toObject, includeInstance)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!GetMembershipListMembersResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new GetMembershipListMembersResponse;
    return GetMembershipListMembersResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!GetMembershipListMembersResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!GetMembershipListMembersResponse}
   */
  static deserializeBinaryFromReader(msg: GetMembershipListMembersResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new gateway_person_view_pb.PersonViewMinimal;
        reader.readMessage(value1,gateway_person_view_pb.PersonViewMinimal.deserializeBinaryFromReader);
        msg.addPerson(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!GetMembershipListMembersResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: GetMembershipListMembersResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getPersonList();
    if (f.length > 0) {
      writer.writeRepeatedMessage(
        1,
        f,
        gateway_person_view_pb.PersonViewMinimal.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    GetMembershipListMembersResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * repeated PersonViewMinimal person = 1;
   * @return {!Array<!PersonViewMinimal>}
   */
  getPersonList(): gateway_person_view_pb.PersonViewMinimal[] {
    return /** @type{!Array<!PersonViewMinimal>} */ (
      jspb.Message.getRepeatedWrapperField(this, gateway_person_view_pb.PersonViewMinimal, 1));
  }


  /** @param {!Array<!PersonViewMinimal>} value */
  setPersonList(value?: gateway_person_view_pb.PersonViewMinimal[]) {
    jspb.Message.setRepeatedWrapperField(this, 1, value);
  }


  /**
   * @param {!PersonViewMinimal=} opt_value
   * @param {number=} opt_index
   * @return {!PersonViewMinimal}
   */
  addPerson(opt_value?: gateway_person_view_pb.PersonViewMinimal, opt_index?: number): gateway_person_view_pb.PersonViewMinimal {
    return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, gateway_person_view_pb.PersonViewMinimal, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearPersonList() {
    this.setPersonList([]);
  }


}
export namespace StreamStudentIdItem {
  export interface AsObject {
    itemMetadata?: common_stream_pb.StreamItemMetadata.AsObject;
    item?: ReadStudentIdResponse.AsObject;
  }
}
export class StreamStudentIdItem extends jspb.Message {
  static readonly displayName = "StreamStudentIdItem";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): StreamStudentIdItem.AsObject {
    return StreamStudentIdItem.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!StreamStudentIdItem} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: StreamStudentIdItem) {
    var f: any;
    var obj: any = {
      itemMetadata: (f = msg.getItemMetadata()) && common_stream_pb.StreamItemMetadata.toObject(includeInstance, f),
      item: (f = msg.getItem()) && ReadStudentIdResponse.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!StreamStudentIdItem}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new StreamStudentIdItem;
    return StreamStudentIdItem.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!StreamStudentIdItem} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!StreamStudentIdItem}
   */
  static deserializeBinaryFromReader(msg: StreamStudentIdItem, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new common_stream_pb.StreamItemMetadata;
        reader.readMessage(value1,common_stream_pb.StreamItemMetadata.deserializeBinaryFromReader);
        msg.setItemMetadata(value1);
        break;
      case 2:
        var value2 = new ReadStudentIdResponse;
        reader.readMessage(value2,ReadStudentIdResponse.deserializeBinaryFromReader);
        msg.setItem(value2);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!StreamStudentIdItem} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: StreamStudentIdItem, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getItemMetadata();
    if (f != null) {
      writer.writeMessage(
        1,
        f,
        common_stream_pb.StreamItemMetadata.serializeBinaryToWriter
      );
    }
    f = message.getItem();
    if (f != null) {
      writer.writeMessage(
        2,
        f,
        ReadStudentIdResponse.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    StreamStudentIdItem.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional StreamItemMetadata item_metadata = 1;
   * @return {?StreamItemMetadata}
   */
  getItemMetadata(): common_stream_pb.StreamItemMetadata {
    return /** @type{?StreamItemMetadata} */ (
      jspb.Message.getWrapperField(this, common_stream_pb.StreamItemMetadata, 1));
  }


  /** @param {?StreamItemMetadata|undefined} value */
  setItemMetadata(value?: common_stream_pb.StreamItemMetadata) {
    jspb.Message.setWrapperField(this, 1, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearItemMetadata() {
    this.setItemMetadata(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasItemMetadata(): boolean {
    return jspb.Message.getField(this, 1) != null;
  }


  /**
   * optional ReadStudentIdResponse item = 2;
   * @return {?ReadStudentIdResponse}
   */
  getItem(): ReadStudentIdResponse {
    return /** @type{?ReadStudentIdResponse} */ (
      jspb.Message.getWrapperField(this, ReadStudentIdResponse, 2));
  }


  /** @param {?ReadStudentIdResponse|undefined} value */
  setItem(value?: ReadStudentIdResponse) {
    jspb.Message.setWrapperField(this, 2, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearItem() {
    this.setItem(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasItem(): boolean {
    return jspb.Message.getField(this, 2) != null;
  }


}
export namespace GetMingaStudentIdsRequest {
  export interface AsObject {
    filter?: common_stream_pb.StreamFilter.AsObject;
    limit: number;
    offset: number;
    orderBy?: common_order_pb.OrderBy.AsObject;
  }
}
export class GetMingaStudentIdsRequest extends jspb.Message {
  static readonly displayName = "GetMingaStudentIdsRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): GetMingaStudentIdsRequest.AsObject {
    return GetMingaStudentIdsRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!GetMingaStudentIdsRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: GetMingaStudentIdsRequest) {
    var f: any;
    var obj: any = {
      filter: (f = msg.getFilter()) && common_stream_pb.StreamFilter.toObject(includeInstance, f),
      limit: jspb.Message.getFieldWithDefault(msg, 2, 0),
      offset: jspb.Message.getFieldWithDefault(msg, 3, 0),
      orderBy: (f = msg.getOrderBy()) && common_order_pb.OrderBy.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!GetMingaStudentIdsRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new GetMingaStudentIdsRequest;
    return GetMingaStudentIdsRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!GetMingaStudentIdsRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!GetMingaStudentIdsRequest}
   */
  static deserializeBinaryFromReader(msg: GetMingaStudentIdsRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new common_stream_pb.StreamFilter;
        reader.readMessage(value1,common_stream_pb.StreamFilter.deserializeBinaryFromReader);
        msg.setFilter(value1);
        break;
      case 2:
        var value2 = /** @type {number} */ (reader.readInt32());
        msg.setLimit(value2);
        break;
      case 3:
        var value3 = /** @type {number} */ (reader.readInt32());
        msg.setOffset(value3);
        break;
      case 4:
        var value4 = new common_order_pb.OrderBy;
        reader.readMessage(value4,common_order_pb.OrderBy.deserializeBinaryFromReader);
        msg.setOrderBy(value4);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!GetMingaStudentIdsRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: GetMingaStudentIdsRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getFilter();
    if (f != null) {
      writer.writeMessage(
        1,
        f,
        common_stream_pb.StreamFilter.serializeBinaryToWriter
      );
    }
    f = message.getLimit();
    if (f !== 0) {
      writer.writeInt32(
        2,
        f
      );
    }
    f = message.getOffset();
    if (f !== 0) {
      writer.writeInt32(
        3,
        f
      );
    }
    f = message.getOrderBy();
    if (f != null) {
      writer.writeMessage(
        4,
        f,
        common_order_pb.OrderBy.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    GetMingaStudentIdsRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional StreamFilter filter = 1;
   * @return {?StreamFilter}
   */
  getFilter(): common_stream_pb.StreamFilter {
    return /** @type{?StreamFilter} */ (
      jspb.Message.getWrapperField(this, common_stream_pb.StreamFilter, 1));
  }


  /** @param {?StreamFilter|undefined} value */
  setFilter(value?: common_stream_pb.StreamFilter) {
    jspb.Message.setWrapperField(this, 1, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearFilter() {
    this.setFilter(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasFilter(): boolean {
    return jspb.Message.getField(this, 1) != null;
  }


  /**
   * optional int32 limit = 2;
   * @return {number}
   */
  getLimit(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
  };


  /** @param {number} value */
  setLimit(value: number) {
    jspb.Message.setProto3IntField(this, 2, value);
  }


  /**
   * optional int32 offset = 3;
   * @return {number}
   */
  getOffset(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
  };


  /** @param {number} value */
  setOffset(value: number) {
    jspb.Message.setProto3IntField(this, 3, value);
  }


  /**
   * optional OrderBy order_by = 4;
   * @return {?OrderBy}
   */
  getOrderBy(): common_order_pb.OrderBy {
    return /** @type{?OrderBy} */ (
      jspb.Message.getWrapperField(this, common_order_pb.OrderBy, 4));
  }


  /** @param {?OrderBy|undefined} value */
  setOrderBy(value?: common_order_pb.OrderBy) {
    jspb.Message.setWrapperField(this, 4, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearOrderBy() {
    this.setOrderBy(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasOrderBy(): boolean {
    return jspb.Message.getField(this, 4) != null;
  }


}
export namespace GetMingaStudentIdsResponse {
  export interface AsObject {
    itemsList: StreamStudentIdItem.AsObject[];
    totalSize: number;
  }
}
export class GetMingaStudentIdsResponse extends jspb.Message {
  static readonly displayName = "GetMingaStudentIdsResponse";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [1];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, GetMingaStudentIdsResponse.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): GetMingaStudentIdsResponse.AsObject {
    return GetMingaStudentIdsResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!GetMingaStudentIdsResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: GetMingaStudentIdsResponse) {
    var f: any;
    var obj: any = {
      itemsList: jspb.Message.toObjectList(msg.getItemsList(),
      StreamStudentIdItem.toObject, includeInstance),
      totalSize: jspb.Message.getFieldWithDefault(msg, 2, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!GetMingaStudentIdsResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new GetMingaStudentIdsResponse;
    return GetMingaStudentIdsResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!GetMingaStudentIdsResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!GetMingaStudentIdsResponse}
   */
  static deserializeBinaryFromReader(msg: GetMingaStudentIdsResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new StreamStudentIdItem;
        reader.readMessage(value1,StreamStudentIdItem.deserializeBinaryFromReader);
        msg.addItems(value1);
        break;
      case 2:
        var value2 = /** @type {number} */ (reader.readInt32());
        msg.setTotalSize(value2);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!GetMingaStudentIdsResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: GetMingaStudentIdsResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getItemsList();
    if (f.length > 0) {
      writer.writeRepeatedMessage(
        1,
        f,
        StreamStudentIdItem.serializeBinaryToWriter
      );
    }
    f = message.getTotalSize();
    if (f !== 0) {
      writer.writeInt32(
        2,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    GetMingaStudentIdsResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * repeated StreamStudentIdItem items = 1;
   * @return {!Array<!StreamStudentIdItem>}
   */
  getItemsList(): StreamStudentIdItem[] {
    return /** @type{!Array<!StreamStudentIdItem>} */ (
      jspb.Message.getRepeatedWrapperField(this, StreamStudentIdItem, 1));
  }


  /** @param {!Array<!StreamStudentIdItem>} value */
  setItemsList(value?: StreamStudentIdItem[]) {
    jspb.Message.setRepeatedWrapperField(this, 1, value);
  }


  /**
   * @param {!StreamStudentIdItem=} opt_value
   * @param {number=} opt_index
   * @return {!StreamStudentIdItem}
   */
  addItems(opt_value?: StreamStudentIdItem, opt_index?: number): StreamStudentIdItem {
    return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, StreamStudentIdItem, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearItemsList() {
    this.setItemsList([]);
  }


  /**
   * optional int32 total_size = 2;
   * @return {number}
   */
  getTotalSize(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
  };


  /** @param {number} value */
  setTotalSize(value: number) {
    jspb.Message.setProto3IntField(this, 2, value);
  }


}
export namespace StudentIdStatusCountRequest {
  export interface AsObject {
  }
}
export class StudentIdStatusCountRequest extends jspb.Message {
  static readonly displayName = "StudentIdStatusCountRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): StudentIdStatusCountRequest.AsObject {
    return StudentIdStatusCountRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!StudentIdStatusCountRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: StudentIdStatusCountRequest) {
    var f: any;
    var obj: any = {

    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!StudentIdStatusCountRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new StudentIdStatusCountRequest;
    return StudentIdStatusCountRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!StudentIdStatusCountRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!StudentIdStatusCountRequest}
   */
  static deserializeBinaryFromReader(msg: StudentIdStatusCountRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!StudentIdStatusCountRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: StudentIdStatusCountRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    StudentIdStatusCountRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
}
export namespace StudentIdStatusCountResponse {
  export interface AsObject {
    active: number;
    inactive: number;
    noPhoto: number;
    tempPhoto: number;
    printId: number;
  }
}
export class StudentIdStatusCountResponse extends jspb.Message {
  static readonly displayName = "StudentIdStatusCountResponse";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): StudentIdStatusCountResponse.AsObject {
    return StudentIdStatusCountResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!StudentIdStatusCountResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: StudentIdStatusCountResponse) {
    var f: any;
    var obj: any = {
      active: jspb.Message.getFieldWithDefault(msg, 1, 0),
      inactive: jspb.Message.getFieldWithDefault(msg, 2, 0),
      noPhoto: jspb.Message.getFieldWithDefault(msg, 3, 0),
      tempPhoto: jspb.Message.getFieldWithDefault(msg, 4, 0),
      printId: jspb.Message.getFieldWithDefault(msg, 5, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!StudentIdStatusCountResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new StudentIdStatusCountResponse;
    return StudentIdStatusCountResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!StudentIdStatusCountResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!StudentIdStatusCountResponse}
   */
  static deserializeBinaryFromReader(msg: StudentIdStatusCountResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {number} */ (reader.readInt32());
        msg.setActive(value1);
        break;
      case 2:
        var value2 = /** @type {number} */ (reader.readInt32());
        msg.setInactive(value2);
        break;
      case 3:
        var value3 = /** @type {number} */ (reader.readInt32());
        msg.setNoPhoto(value3);
        break;
      case 4:
        var value4 = /** @type {number} */ (reader.readInt32());
        msg.setTempPhoto(value4);
        break;
      case 5:
        var value5 = /** @type {number} */ (reader.readInt32());
        msg.setPrintId(value5);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!StudentIdStatusCountResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: StudentIdStatusCountResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getActive();
    if (f !== 0) {
      writer.writeInt32(
        1,
        f
      );
    }
    f = message.getInactive();
    if (f !== 0) {
      writer.writeInt32(
        2,
        f
      );
    }
    f = message.getNoPhoto();
    if (f !== 0) {
      writer.writeInt32(
        3,
        f
      );
    }
    f = message.getTempPhoto();
    if (f !== 0) {
      writer.writeInt32(
        4,
        f
      );
    }
    f = message.getPrintId();
    if (f !== 0) {
      writer.writeInt32(
        5,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    StudentIdStatusCountResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional int32 active = 1;
   * @return {number}
   */
  getActive(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
  };


  /** @param {number} value */
  setActive(value: number) {
    jspb.Message.setProto3IntField(this, 1, value);
  }


  /**
   * optional int32 inactive = 2;
   * @return {number}
   */
  getInactive(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
  };


  /** @param {number} value */
  setInactive(value: number) {
    jspb.Message.setProto3IntField(this, 2, value);
  }


  /**
   * optional int32 no_photo = 3;
   * @return {number}
   */
  getNoPhoto(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
  };


  /** @param {number} value */
  setNoPhoto(value: number) {
    jspb.Message.setProto3IntField(this, 3, value);
  }


  /**
   * optional int32 temp_photo = 4;
   * @return {number}
   */
  getTempPhoto(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
  };


  /** @param {number} value */
  setTempPhoto(value: number) {
    jspb.Message.setProto3IntField(this, 4, value);
  }


  /**
   * optional int32 print_id = 5;
   * @return {number}
   */
  getPrintId(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
  };


  /** @param {number} value */
  setPrintId(value: number) {
    jspb.Message.setProto3IntField(this, 5, value);
  }


}
export namespace ExportStudentIdListRequest {
  export interface AsObject {
    filter?: common_stream_pb.StreamFilter.AsObject;
  }
}
export class ExportStudentIdListRequest extends jspb.Message {
  static readonly displayName = "ExportStudentIdListRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): ExportStudentIdListRequest.AsObject {
    return ExportStudentIdListRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!ExportStudentIdListRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: ExportStudentIdListRequest) {
    var f: any;
    var obj: any = {
      filter: (f = msg.getFilter()) && common_stream_pb.StreamFilter.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!ExportStudentIdListRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new ExportStudentIdListRequest;
    return ExportStudentIdListRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!ExportStudentIdListRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!ExportStudentIdListRequest}
   */
  static deserializeBinaryFromReader(msg: ExportStudentIdListRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new common_stream_pb.StreamFilter;
        reader.readMessage(value1,common_stream_pb.StreamFilter.deserializeBinaryFromReader);
        msg.setFilter(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!ExportStudentIdListRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: ExportStudentIdListRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getFilter();
    if (f != null) {
      writer.writeMessage(
        1,
        f,
        common_stream_pb.StreamFilter.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    ExportStudentIdListRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional StreamFilter filter = 1;
   * @return {?StreamFilter}
   */
  getFilter(): common_stream_pb.StreamFilter {
    return /** @type{?StreamFilter} */ (
      jspb.Message.getWrapperField(this, common_stream_pb.StreamFilter, 1));
  }


  /** @param {?StreamFilter|undefined} value */
  setFilter(value?: common_stream_pb.StreamFilter) {
    jspb.Message.setWrapperField(this, 1, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearFilter() {
    this.setFilter(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasFilter(): boolean {
    return jspb.Message.getField(this, 1) != null;
  }


}
export namespace ExportStudentIdListChunk {
  export interface AsObject {
    chunk?: (string|Uint8Array);
    filename: string;
  }
}
export class ExportStudentIdListChunk extends jspb.Message {
  static readonly displayName = "ExportStudentIdListChunk";
  /**
   * Oneof group definitions for this message. Each group defines the field
   * numbers belonging to that group. When of these fields' value is set, all
   * other fields in the group are cleared. During deserialization, if multiple
   * fields are encountered for a group, only the last value seen will be kept.
   * @private {!Array<!Array<number>>}
   * @const
   */
  static readonly oneofGroups_ = [[1,2]];


  /**
   * @return {ExportStudentIdListChunk.InfoCase}
   */
  getInfoCase() {
    return /** @type {ExportStudentIdListChunk.InfoCase} */(jspb.Message.computeOneofCase(this, ExportStudentIdListChunk.oneofGroups_[0]));
  }

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, ExportStudentIdListChunk.oneofGroups_);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): ExportStudentIdListChunk.AsObject {
    return ExportStudentIdListChunk.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!ExportStudentIdListChunk} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: ExportStudentIdListChunk) {
    var f: any;
    var obj: any = {
      chunk: msg.getChunk_asB64(),
      filename: jspb.Message.getFieldWithDefault(msg, 2, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!ExportStudentIdListChunk}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new ExportStudentIdListChunk;
    return ExportStudentIdListChunk.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!ExportStudentIdListChunk} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!ExportStudentIdListChunk}
   */
  static deserializeBinaryFromReader(msg: ExportStudentIdListChunk, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {!Uint8Array} */ (reader.readBytes());
        msg.setChunk(value1);
        break;
      case 2:
        var value2 = /** @type {string} */ (reader.readString());
        msg.setFilename(value2);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!ExportStudentIdListChunk} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: ExportStudentIdListChunk, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 1));
    if (f != null) {
      writer.writeBytes(
        1,
        f
      );
    }
    f = /** @type {string} */ (jspb.Message.getField(message, 2));
    if (f != null) {
      writer.writeString(
        2,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    ExportStudentIdListChunk.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional bytes chunk = 1;
   * @return {!(string|Uint8Array)}
   */
  getChunk(): (string|Uint8Array) {
    return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /**
   * optional bytes chunk = 1;
   * This is a type-conversion wrapper around `getChunk()`
   * @return {string}
   */
  getChunk_asB64() {
    return /** @type {string} */ (jspb.Message.bytesAsB64(
        this.getChunk()));
  }


  /**
   * optional bytes chunk = 1;
   * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getChunk()`
   * @return {!Uint8Array}
   */
  getChunk_asU8() {
    return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
        this.getChunk()));
  }


  /** @param {!(string|Uint8Array)} value */
  setChunk(value: (string|Uint8Array)) {
    jspb.Message.setOneofField(this, 1, ExportStudentIdListChunk.oneofGroups_[0], value);
  }


  /**
   * Clears the field making it undefined.
   */
  clearChunk() {
    jspb.Message.setOneofField(this, 1, ExportStudentIdListChunk.oneofGroups_[0], undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasChunk(): boolean {
    return jspb.Message.getField(this, 1) != null;
  }


  /**
   * optional string filename = 2;
   * @return {string}
   */
  getFilename(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
  };


  /** @param {string} value */
  setFilename(value: string) {
    jspb.Message.setOneofField(this, 2, ExportStudentIdListChunk.oneofGroups_[0], value);
  }


  /**
   * Clears the field making it undefined.
   */
  clearFilename() {
    jspb.Message.setOneofField(this, 2, ExportStudentIdListChunk.oneofGroups_[0], undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasFilename(): boolean {
    return jspb.Message.getField(this, 2) != null;
  }


}
export namespace ExportStudentIdListChunk {
export enum InfoCase {
  INFO_NOT_SET = 0,
  CHUNK = 1,
  FILENAME = 2,
}
} // namespace ExportStudentIdListChunk
