import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';
import { INotificationType } from 'libs/domain';
import { gateway } from 'libs/generated-grpc-web';
import { $enum } from 'ts-enum-util';

import { NotificationActions } from '@app/src/app/store/Notifications/actions';
import { NotificationStoreModel } from '@app/src/app/store/Notifications/model';

import {
  SystemAlertModalHangTime,
  SystemAlertModalService,
  SystemAlertModalType,
} from '@shared/components/system-alert-modal';

@Injectable({ providedIn: 'root' })
export class FlexTimeNotificationHandlerService {
  constructor(
    private _systemAlertModal: SystemAlertModalService,
    private _store: Store<any>,
  ) {}

  public isFlexTimeNotification(type: string) {
    if (!type) return false;
    const flexTimeNotificationTypes = [
      INotificationType.FLEX_TIME_ACTIVITY_ASSIGNED,
      INotificationType.FLEX_TIME_ACTIVITY_REGISTERED,
      INotificationType.FLEX_TIME_ACTIVITY_UNASSIGNED,
      INotificationType.FLEX_TIME_ACTIVITY_REMOVED,
      INotificationType.FLEX_TIME_ACTIVITY_CAPACITY_EXCEEDED,
    ];

    return flexTimeNotificationTypes.includes(
      $enum(INotificationType).asValueOrDefault(type, null),
    );
  }

  public handleFlexTimeNotification(
    msg: gateway.notification_pb.Notification.AsObject,
  ) {
    this._openNotificationOverlay(msg);

    const notification: NotificationStoreModel = { id: msg.notificationId };
    this._store.dispatch(
      new NotificationActions.MarkNotificationAsReadAction(notification),
    );
  }

  private _openNotificationOverlay(
    msg: gateway.notification_pb.Notification.AsObject,
  ) {
    this._systemAlertModal.open({
      modalType: SystemAlertModalType.DEFAULT,
      heading: msg.title,
      iconColor: 'flextime',
      message: msg.body,
      icon: msg.iconUrl,
      hangTime: SystemAlertModalHangTime.LONG,
    });
  }
}
