import { IDefaultHallPassType } from '../';

export enum DefaultHallPassTypes {
  RESTROOM = 'restroom',
  HEALTH = 'health',
  OFFICE = 'office',
  HALL = 'hall',
  LIBRARY = 'library',
  FOUNTAIN = 'fountain',
  TARDY = 'tardy',
  ATHLETICS = 'athletics',
  MEDIA = 'media',
  TRANSFER = 'transfer',
}

export enum DefaultHallPassTypeIcons {
  RESTROOM = 'hp-restroom',
  HEALTH = 'hp-health',
  OFFICE = 'hp-office',
  HALL = 'hp-hall',
  LIBRARY = 'hp-library',
  FOUNTAIN = 'hp-fountain',
  TARDY = 'hp-tardy',
  ATHLETICS = 'hp-athletics',
  MEDIA = 'hp-media',
  TRANSFER = 'hp-transfer',
}

// default hall pass types for each new Minga
export const defaultHallPassTypes: IDefaultHallPassType[] = [
  {
    name: 'Restroom',
    iconName: DefaultHallPassTypeIcons.RESTROOM,
    bannerReference: 'DEFAULT_HALL_PASS_REST_ROOM',
    color: '#95C6E1',
  },
  {
    name: 'Library',
    iconName: DefaultHallPassTypeIcons.LIBRARY,
    bannerReference: 'DEFAULT_HALL_PASS_LIBRARY',
    color: '#7973B2',
  },
  {
    name: 'Fountain',
    iconName: DefaultHallPassTypeIcons.FOUNTAIN,
    bannerReference: 'DEFAULT_HALL_PASS_WATER_FOUNTAIN',
    color: '#71A3D5',
  },
  {
    name: 'Health',
    iconName: DefaultHallPassTypeIcons.HEALTH,
    bannerReference: 'DEFAULT_HALL_PASS_HEALTH_WELLNESS',
    color: '#7BB9AF',
  },
  {
    name: 'Office',
    iconName: DefaultHallPassTypeIcons.OFFICE,
    bannerReference: 'DEFAULT_HALL_PASS_OFFICE',
    color: '#E5A583',
  },
  {
    name: 'Hall',
    iconName: DefaultHallPassTypeIcons.HALL,
    bannerReference: 'DEFAULT_HALL_PASS_HALL_PASS',
    color: '#E091A3',
  },
  {
    name: 'Tardy',
    iconName: DefaultHallPassTypeIcons.TARDY,
    bannerReference: 'DEFAULT_HALL_PASS_TARDY',
    color: '#DAE36E',
  },
  {
    name: 'Athletics',
    iconName: DefaultHallPassTypeIcons.ATHLETICS,
    bannerReference: 'DEFAULT_HALL_PASS_ATHLETICS',
    color: '#87B8AC',
  },
  {
    name: 'Media',
    iconName: DefaultHallPassTypeIcons.MEDIA,
    bannerReference: 'DEFAULT_HALL_PASS_MEDIA',
    color: '#32A3B8',
  },
  {
    name: 'Transfer',
    iconName: DefaultHallPassTypeIcons.TRANSFER,
    bannerReference: 'DEFAULT_HALL_PASS_TRANSFER',
    color: '#21A28E',
  },
];
