import { IMembershipList, MembershipListType } from 'libs/domain';

export const MEMBERSHIP_LISTS_DATA: Record<
  MembershipListType,
  {
    description: string;
    defaultConfig: Partial<IMembershipList>;
  }
> = {
  BEHAVIOR_MANAGER: {
    description:
      'Users added to this list will be allowed to assign behaviors, access the dashboard and reporting, complete consequences and update types.',
    defaultConfig: {},
  },
  BEHAVIOR_REPORT_VIEWER: {
    description:
      'Users added to this list will be allowed to access reporting and the dashboard.',
    defaultConfig: {},
  },
  BEHAVIOR_TYPE: {
    description: 'Limit who can Assign this Behavior',
    defaultConfig: {
      name: 'Assigners List',
    },
  },
  CHECKIN_MANAGER: {
    description:
      'Users added to this list will be allowed to create Check Ins, access reporting, update reasons, and access the dashboard.',
    defaultConfig: {},
  },
  CHECKIN_REPORT_VIEWER: {
    description:
      'Users added to this list will be allowed to access reporting and the dashboard.',
    defaultConfig: {},
  },
  CHECKIN_RESTRICTION_LIST: {
    description:
      'Only users added to this list will be able to check in with this reason.',
    defaultConfig: {
      name: 'Limited To',
    },
  },
  CONTENT_MANAGER: {
    description: '',
    defaultConfig: {
      name: 'Event managers',
    },
  },
  FLEX_MANAGER: {
    description:
      'Users added to this list will be allowed to create flex activities and periods, access reporting, assign activities to periods, update settings and access the dashboard.',
    defaultConfig: {},
  },
  FLEX_REPORT_VIEWER: {
    description:
      'Users added to this list will be allowed to access the flex reports and dashboard.',
    defaultConfig: {},
  },
  FLEX_RESTRICTION_LIST: {
    description:
      'Only users added to this list will be able to register and check in for this flex activity.',
    defaultConfig: {
      name: 'Limited To',
    },
  },
  HALL_PASS_MANAGER: {
    description:
      'Users added to this list will be allowed to assign hall passes, access reporting and the dashboard, edit hall pass types and change restrictions',
    defaultConfig: {},
  },
  HP_REPORT_VIEWER: {
    description:
      'Users added to this list will be allowed to access reporting and the dashboard.',
    defaultConfig: {},
  },
  HALL_PASS_TYPE: {
    description: 'Limit who this Hall Pass can be assigned to',
    defaultConfig: {
      name: 'Assigners List',
    },
  },
  ID_MANAGER: {
    description:
      'Users added to this list will be allowed to update and create stickers, edit IDs, and access the dashboard.',
    defaultConfig: {},
  },
  NO_ACCESS: {
    description:
      "Users added to this list will not be allowed to check in to events or toggled Check In Reasons. Also puts a 'No Access' sticker on the user's ID.",
    defaultConfig: {},
  },
  NO_GRANT: {
    description: 'Staff added to this list cannot issue hall passes',
    defaultConfig: {},
  },
  NO_PARTY: {
    description:
      'Allows you to create a group of users that are not allowed in the hallway at the same time.',
    defaultConfig: {},
  },
  NO_PASS: {
    description: 'Students added to this list cannot be issued any hall passes',
    defaultConfig: {},
  },
  NO_TRACK: {
    description:
      'Users added to this list will not be allowed to assign behaviors.',
    defaultConfig: {},
  },
  ONE_PASS: {
    description: 'Students added to this list can only use 1 hall pass per day',
    defaultConfig: {},
  },
  PASS_TYPE: {
    description:
      'Limit the students this pass can be assigned to or which student can create this pass.',
    defaultConfig: {
      name: 'Student list',
    },
  },
  POINTS_MANAGER: {
    description:
      'Users added to this list will be allowed to update and create teams, access reporting, update and give rewards, and access the dashboard.',
    defaultConfig: {},
  },
  POINTS_REPORT_VIEWER: {
    description:
      'Users added to this list will be allowed to access reporting.',
    defaultConfig: {},
  },
  SCHOOL_TEAM: {
    description: '',
    defaultConfig: {
      name: 'Assignees List',
    },
  },
  STICKER: {
    description: '',
    defaultConfig: {
      priority: 0,
      assetPath: '',
      bannerHash: '',
    },
  },
  TRACK: {
    description:
      'Users added to this list will be allowed to assign behaviors even if their role does not allow it.',
    defaultConfig: {},
  },
  TWO_PASS: {
    description:
      'Students added to this list can only use 2 hall passes per day',
    defaultConfig: {},
  },
  USER_LIST: {
    description: '',
    defaultConfig: {
      name: 'Members',
    },
  },
  USER_LIST_MANAGER: {
    description:
      'Allow users to edit and use this list (Admins are managers by default)',
    defaultConfig: {
      name: 'List managers',
    },
  },
};
