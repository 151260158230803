import { Injector, Type } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';

import {
  ContentManager,
  ContextualContentManager,
} from '@app/src/app/content-common/services/ContentManager';
import { MingaContentManager } from '@app/src/app/content-common/services/ContentManager/minga';
import { GroupsFacadeService } from '@app/src/app/groups/services';

function replaceParams(
  str: string,
  params: { [keyname: string]: string | undefined },
): string {
  let result = str;

  for (const paramName in params) {
    const paramValue = params[paramName] || '';
    result = result.replace(`:${paramName}`, paramValue);
  }

  return result;
}

function createPath(
  pathStr: string,
  params: { [keyname: string]: string | undefined },
): string[] {
  return replaceParams(pathStr, params).split('/');
}

export abstract class ContentRouteData {
  contextHash: string = '';
  contentHash: string = '';
  contentManager: ContextualContentManager<any, any, ContentManager<any, any>>;
  programHash: string = '';
  _groupHash: string = '';
  groupHashGetter = () => this._groupHash;

  constructor(
    protected snapshot: ActivatedRouteSnapshot,
    protected injector: Injector,
  ) {
    // default to just minga content manager to make typescript happy.
    // typescript doesn't like that this could be null, but it *wont be*
    // @ts-ignore
    const contentManagerInj: ContentManager<any, any> =
      this.injector.get(MingaContentManager);
    this.contentManager = new ContextualContentManager(
      contentManagerInj,
      () => null,
    );
  }

  async initDataFromRoute(): Promise<void> {
    this.contextHash = this.snapshot.paramMap.get('contextHash') || '';
    this.contentHash = this.snapshot.paramMap.get('contentHash') || '';
    this._groupHash = this.snapshot.paramMap.get('groupHash') || '';
    this.programHash = this.snapshot.paramMap.get('programHash') || '';
    let groupHashGetter = () => this.snapshot.paramMap.get('groupHash') || '';

    const contentManagerType: Type<any> = this.snapshot.data.contentManagerType;
    const contentManagerInj: ContentManager<any, any> = this.injector.get(
      contentManagerType,
      null,
    );
    if (!contentManagerInj) {
      throw new Error('Missing or invalid contentManagerType in route data');
    }
    // @TODO: Remove this and use the route params instead
    if (
      !groupHashGetter() &&
      contentManagerInj.name === 'GroupContentManager'
    ) {
      const groupsFacade =
        this.injector.get<GroupsFacadeService>(GroupsFacadeService);
      groupHashGetter = () => groupsFacade.getCurrentGroupHash();
    }

    this.contentManager = new ContextualContentManager(
      contentManagerInj,
      () => groupHashGetter() || this.programHash || null,
    );

    if (this.contentHash) {
      await this.getContentInfo();
    }

    await this.getAdditionalRouteData();
  }

  protected abstract getContentInfo(): void;

  protected abstract getAdditionalRouteData(): void;

  protected getRouteParams() {
    const params = {
      contextHash: this.contextHash,
      contentHash: this.contentHash,
      groupHash: this.groupHash,
      programHash: this.programHash,
    };

    return params;
  }

  get groupHash(): string {
    return this._groupHash;
  }

  get nextPath() {
    return [
      ...createPath(
        this.snapshot.parent!.routeConfig!.path!,
        this.getRouteParams(),
      ),
      ...createPath(this.snapshot.data.nextPath || '', this.getRouteParams()),
    ];
  }
  get previousPath() {
    return [
      ...createPath(
        this.snapshot.parent!.routeConfig!.path!,
        this.getRouteParams(),
      ),
      ...createPath(
        this.snapshot.data.previousPath || '',
        this.getRouteParams(),
      ),
    ];
  }
  get parentPath() {
    return [
      ...createPath(
        this.snapshot.parent!.routeConfig!.path!,
        this.getRouteParams(),
      ),
    ];
  }
}
