<div
  class="container input-alignment-fix"
  [attr.data-analytics]="'grouped-select-' + (id || '')"
  [attr.data-test]="'grouped-select-' + (id || '')"
  [class.has-multi]="multiple"
  [class.disable-wrapping]="disableWrapping">
  <div class="custom-dropdown-arrow">
    <mg-icon [iconName]="(isOpen$ | async) ? 'up-toggle' : 'down-toggle'">
    </mg-icon>
  </div>

  <div
    *ngIf="clearable && selectedValue"
    class="custom-clear-icon"
    (click)="clear()">
    <mat-icon>close</mat-icon>
  </div>

  <label
    *ngIf="showLabel"
    class="input-label focused"
    [ngClass]="labelBackground"
    [class.has-input]="!!selectedValue">
    {{ placeholder }}
  </label>

  <div
    *ngIf="disableWrapping"
    class="tag-collection"
    (click)="onTagCollectionClick()">
    <mg-tag-collection
      [maxLines]="1"
      [maxTagChars]="17"
      type="removeable-select-tag"
      showMoreType="select-tag"
      size="medium"
      [tags]="selectedValue | map: TAG_MAPPER"
      tagNameKey="label"
      (pressed)="onTagItemClicked($event)"></mg-tag-collection>
  </div>

  <ng-select
    #select
    class="group-select"
    [clearable]="clearable"
    [virtualScroll]="true"
    [disabled]="disabled"
    [placeholder]="placeholder"
    [clearable]="clearable"
    [items]="options$ | async"
    [groupBy]="groupByFn"
    [trackByFn]="trackByFn"
    [groupValue]="groupValueFn"
    [appendTo]="appendTo"
    (change)="onSelect($event)"
    (search)="onSearch($event)"
    (blur)="onBlur($event)"
    (open)="setOpenState(true)"
    (close)="setOpenState(false)"
    [(ngModel)]="selectedValue"
    [loading]="loading"
    [compareWith]="compareWithFn"
    [multiple]="multiple">
    <ng-template
      *ngIf="multiple"
      ng-label-tmp
      let-option="item"
      let-clear="clear">
      <!-- With multi select and no wrap these options are invisible since we're using mg-tag-collection above to handle 
      displaying them but we're leaving this in the dom so the cursor position is accurate -->
      <div class="selected-option multi-select-option">
        <div
          class="label"
          [title]="option.label">
          <span>{{ option.label }}</span>
        </div>
        <span
          class="close"
          (click)="clear(option)">
          <mat-icon>close</mat-icon>
        </span>
      </div>
    </ng-template>
    <ng-template
      ng-optgroup-tmp
      let-option="item"
      let-index="index">
      <ng-container
        *ngIf="(activeCategory$ | async)?.value; else standard"
        [ngTemplateOutlet]="categoryBack"
        [ngTemplateOutletContext]="{
          label: option?.label
        }"></ng-container>

      <ng-template #standard>
        <div
          class="group-header"
          fxLayout="row"
          fxLayoutAlign="start center">
          <mg-text
            variant="body-sm-secondary"
            color="surface-alt">
            {{ option?.label }}
          </mg-text>
        </div>
      </ng-template>
    </ng-template>

    <ng-template
      ng-option-tmp
      let-option="item">
      <ng-container
        *ngIf="
          (selectType$ | async) === SELECT_TYPE.OPTION;
          else categoryOption
        ">
        <div
          class="option-list"
          [attr.data-analytics]="
            'grouped-select-option-' +
              option?.category?.label +
              '-' +
              option?.value | slugify
          "
          [attr.data-test]="
            'grouped-select-option-' +
              option?.category?.label +
              '-' +
              option?.value | slugify
          "
          fxLayout="row"
          fxLayoutAlign="start center"
          fxLayoutGap="8px"
          matTooltipClass="mg-tooltip"
          [matTooltipShowDelay]="500"
          [matTooltip]="option?.optionLabel || option?.label">
          <mg-icon
            *ngIf="option?.icon"
            [iconName]="option?.icon"
            [icon-namespace]="option?.iconNamespace"
            [style.color]="option.color"></mg-icon>
          <mg-text
            variant="body-md"
            [numberOfLines]="1">
            {{ option?.optionLabel || option?.label }}</mg-text
          >
        </div>
      </ng-container>
      <ng-template #categoryOption>
        <div class="option-category">
          <button
            [attr.data-analytics]="
              'grouped-select-category-' + option?.label | slugify
            "
            [attr.data-test]="
              'grouped-select-category-' + option?.label | slugify
            "
            (click)="setCategory(option)"
            type="button">
            <div
              fxLayout="row"
              fxLayoutAlign="space-between center">
              <div
                fxLayout="row"
                fxLayoutAlign="start center"
                fxLayoutGap="8px">
                <mg-icon
                  *ngIf="option?.icon"
                  [iconName]="option?.icon"
                  [icon-namespace]="option?.iconNamespace"
                  [style.color]="option?.iconColor"></mg-icon>
                <mg-text variant="body-md">
                  {{ option?.label }}
                </mg-text>
              </div>
              <mg-icon iconName="mg-chevron-right"></mg-icon>
            </div>
          </button>
        </div>
      </ng-template>
    </ng-template>

    <ng-template ng-loadingspinner-tmp>
      <mg-spinner
        variant="rainbow"
        [diameter]="20"
        [thickness]="2">
      </mg-spinner>
    </ng-template>

    <ng-template
      ng-notfound-tmp
      let-option="item">
      <div class="not-found">
        <ng-container
          *ngIf="activeCategory$ | async"
          [ngTemplateOutlet]="categoryBack"
          [ngTemplateOutletContext]="{
            label: (activeCategory$ | async)?.label
          }"></ng-container>

        <div
          *ngIf="lazyLoading$ | async; else noResults"
          class="options-loading">
          <mg-spinner
            variant="rainbow"
            [diameter]="20"
            [thickness]="2">
          </mg-spinner>
        </div>

        <ng-template #noResults>
          <mg-text
            color="surface-alt"
            variant="body-md"
            [numberOfLines]="1">
            No results found</mg-text
          >
        </ng-template>
      </div>
    </ng-template>
  </ng-select>
</div>

<ng-template
  #categoryBack
  let-label="label">
  <div class="group-header-back">
    <button
      type="button"
      (click)="back()">
      <div
        fxLayout="row"
        fxLayoutAlign="start center">
        <mg-icon iconName="mg-chevron-left"></mg-icon>
        <mg-text
          variant="body-sm-secondary"
          color="surface-alt">
          {{ label }}
        </mg-text>
      </div>
    </button>
  </div>
</ng-template>
