import { IHallPassClassTypeCount } from 'libs/domain';
import { class_types_pb } from 'libs/generated-grpc-ts';
import { wrappers_pb } from 'libs/generated-grpc-ts';

export const toProto = (
  typeCount: IHallPassClassTypeCount,
): class_types_pb.HallPassTypeCount => {
  const msg = new class_types_pb.HallPassTypeCount();

  if (typeCount.id) msg.setId(typeCount.id);
  if (typeCount.name) msg.setName(typeCount.name);
  if (typeCount.asset) msg.setAsset(typeCount.asset);
  if (typeCount.bannerHash) msg.setBannerHash(typeCount.bannerHash);
  if (typeCount.color) msg.setColor(typeCount.color);
  if (typeCount.active) msg.setActive(typeCount.active);
  if (typeCount.priority) msg.setPriority(typeCount.priority);
  if (typeCount.defaultHallPassTime)
    msg.setDefaultHallPassTime(typeCount.defaultHallPassTime);
  if (typeCount.requireTeacherApproval)
    msg.setRequireTeacherApproval(typeCount.requireTeacherApproval);

  if (typeCount.count) msg.setCount(typeCount.count);

  // if the function is called from the frontend it will come as a boolean value
  // if the function is called from the backend it will come as a number value since our db saves as 0/1
  if (
    typeof typeCount.manuallyEndPass == 'boolean' ||
    typeof typeCount.manuallyEndPass == 'number'
  ) {
    const wrapper = new wrappers_pb.BoolValue();
    wrapper.setValue(typeCount.manuallyEndPass);
    msg.setManuallyEndPass(wrapper);
  }

  return msg;
};

export const fromProto = (
  msg: class_types_pb.HallPassTypeCount,
): IHallPassClassTypeCount => {
  const typeCounts: IHallPassClassTypeCount = {
    count: msg.getCount(),
    id: msg.getId(),
    name: msg.getName(),
    asset: msg.getAsset(),
    bannerHash: msg.getBannerHash(),
    color: msg.getColor(),
    active: msg.getActive(),
    priority: msg.getPriority(),
    defaultHallPassTime: msg.getDefaultHallPassTime(),
    requireTeacherApproval: msg.getRequireTeacherApproval(),
    manuallyEndPass: msg.hasManuallyEndPass()
      ? msg.getManuallyEndPass().getValue()
      : undefined,
  };

  return typeCounts;
};
