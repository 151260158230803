<!-- Overlay -->
<mg-overlay-primary
  size="auto"
  [overlayTitle]="modalData.title"
  [isLoading]="false">
  <!-- Main Content -->
  <ng-container *ngTemplateOutlet="mainContentTemplate"></ng-container>
  <!-- Footer -->
  <ng-template #overlayFooter>
    <ng-container *ngTemplateOutlet="footerTemplate"></ng-container>
  </ng-template>
</mg-overlay-primary>

<!-- Main Content Template -->
<ng-template #mainContentTemplate>
  <main [ngStyle.gt-md]="{ 'min-width.px': 720 }">
    <mg-system-alert
      type="error"
      [message]="errorCount$ | async">
    </mg-system-alert>
    <mg-text
      variant="body-sm"
      spacing="1">
      {{ modalData.subMessage }}
    </mg-text>
    <ng-container *ngTemplateOutlet="quickActionsTemplate"></ng-container>
    <ng-container *ngTemplateOutlet="tableTemplate"></ng-container>
    <!-- Pagination -->
    <div
      fxLayout="row"
      fxLayoutAlign="center center">
      <mg-paginator
        class="paginator"
        [pageSizeOptions]="[20, 50, 100]">
      </mg-paginator>
    </div>
  </main>
</ng-template>

<!-- Modal Footer Template -->
<ng-template #footerTemplate>
  <footer
    class="overlay-footer"
    fxLayout="row"
    fxLayout.xs="column"
    fxLayoutAlign="space-between center"
    fxLayoutAlign.xs="start stretch"
    fxLayoutGap="16px">
    <div>
      <mg-btn
        [fxHide]="modalData.displayOnly && !modalData?.closeLabel"
        variant="text"
        (pressed)="close()">
        {{ modalData?.closeLabel || MSG.BUTTON_LABEL_CLOSE }}
      </mg-btn>
    </div>
    <div>
      <mg-btn
        [disabled]="
          !modalData.displayOnly &&
          (this.selection.selected === undefined ||
            this.selection.selected.length === 0)
        "
        (pressed)="submit()">
        {{
          modalData.displayOnly
            ? modalData?.submitLabel || MSG.BUTTON_LABEL_DONE
            : modalData?.submitLabel || MSG.BUTTON_LABEL_CONFIRM
        }}
      </mg-btn>
    </div>
  </footer>
</ng-template>

<!-- Quick Actions Template -->
<ng-template #quickActionsTemplate>
  <div
    fxLayout="row"
    fxLayoutAlign="end center">
    <mg-btn
      *ngIf="canDownloadExport"
      variant="text"
      size="small"
      icon="mg-export"
      iconSet="minga"
      (pressed)="exportList()">
      {{ MSG.BUTTON_LABEL_EXPORT }}
    </mg-btn>
  </div>
</ng-template>

<!-- Table Template -->
<ng-template #tableTemplate>
  <mat-table
    matSort
    matSortActive="status"
    matSortDirection="asc"
    matSortDisableClear
    [dataSource]="dataSource"
    [trackBy]="trackById">
    <!-- Select Column -->
    <ng-container matColumnDef="select">
      <mat-header-cell *matHeaderCellDef>
        <mg-form-checkbox
          [checked]="selection.hasValue() && isAllSelected()"
          [indeterminate]="selection.hasValue() && !isAllSelected()"
          (changed)="masterToggle()">
        </mg-form-checkbox>
      </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <mg-form-checkbox
          *ngIf="row.status === 'error' && !row?.readonly"
          [checked]="selection.isSelected(row)"
          (changed)="selection.toggle(row)">
        </mg-form-checkbox>
      </mat-cell>
    </ng-container>

    <!-- Status Column -->
    <ng-container matColumnDef="status">
      <mat-header-cell
        *matHeaderCellDef
        mat-sort-header>
        {{ MSG.COL_LABEL_STATUS }}
      </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <mg-tag [color]="row.status === 'success' ? 'green' : 'red'">
          {{ row.status === 'success' ? 'Success' : 'Error' }}
        </mg-tag>
      </mat-cell>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="name">
      <mat-header-cell
        *matHeaderCellDef
        mat-sort-header>
        {{ MSG.COL_LABEL_NAME }}
      </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <div
          class="multiline-ellipsis"
          [title]="row.name">
          {{ row.name }}
        </div>
      </mat-cell>
    </ng-container>

    <!-- Reason Column -->
    <ng-container matColumnDef="reasons">
      <mat-header-cell
        *matHeaderCellDef
        mat-sort-header>
        {{ MSG.COL_LABEL_REASON }}
      </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <div
          fxLayout="column"
          fxLayoutAlign="center start"
          fxLayoutGap="4px">
          <ng-container *ngFor="let reason of row.reasons">
            <div class="reason-container">
              {{ reason }}
            </div>
          </ng-container>
        </div>
        <mg-advanced-tooltip
          *ngIf="row?.advancedTooltip as data"
          [data]="data">
        </mg-advanced-tooltip>
        <mg-tooltip
          *ngIf="row?.reasonDescription as description"
          size="small"
          [label]="description">
        </mg-tooltip>
      </mat-cell>
    </ng-container>

    <!-- Mobile View -->
    <ng-container matColumnDef="mobile">
      <mat-header-cell *matHeaderCellDef></mat-header-cell>
      <mat-cell *matCellDef="let row">
        <div
          fxLayout="row"
          fxLayoutAlign="start stretch"
          fxLayoutGap="10px"
          class="mobile-container">
          <!-- Checkbox -->
          <div fxFlex="nogrow">
            <mg-form-checkbox
              *ngIf="row.status === 'error'"
              [checked]="selection.isSelected(row)"
              (changed)="selection.toggle(row)">
            </mg-form-checkbox>
          </div>
          <div fxFlex="grow">
            <div
              fxLayout="column"
              fxLayoutAlign="start start"
              fxLayoutGap="6px">
              <!-- Status -->
              <div>
                <mg-tag [color]="row.status === 'success' ? 'green' : 'red'">
                  {{ row.status === 'success' ? 'Success' : 'Error' }}
                </mg-tag>
              </div>
              <!-- Name -->
              <div class="name">
                <mg-text variant="header-sm">
                  {{ row.name }}
                </mg-text>
              </div>
              <!-- Reason -->
              <div class="tw-flex">
                <div class="reason-container">
                  {{ row.reasons }}
                </div>
                <mg-advanced-tooltip
                  *ngIf="row?.advancedTooltip as data"
                  [data]="data">
                </mg-advanced-tooltip>
                <mg-tooltip
                  *ngIf="row?.reasonDescription as description"
                  size="small"
                  [label]="description">
                </mg-tooltip>
              </div>
            </div>
          </div>
        </div>
      </mat-cell>
    </ng-container>

    <!-- Headers -->
    <mat-header-row *matHeaderRowDef="displayedColumns$ | async">
    </mat-header-row>

    <!-- Rows -->
    <mat-row *matRowDef="let row; columns: displayedColumns$ | async"></mat-row>
  </mat-table>
</ng-template>
