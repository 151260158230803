import {
  ChangeDetectionStrategy,
  Component,
  ContentChildren,
  Input,
  QueryList,
} from '@angular/core';
import { Router } from '@angular/router';

import { NavLinkComponent } from '@app/src/app/navigation/components/NavLink/NavLink.component';

@Component({
  selector: 'mg-nav-drawer-sub-nav',
  templateUrl: './NavigationDrawerSubNav.component.html',
  styleUrls: ['./NavigationDrawerSubNav.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavigationDrawerSubNavComponent {
  @Input()
  title: string = '';

  @Input()
  icon: string = '';

  @ContentChildren(NavLinkComponent)
  items: QueryList<NavLinkComponent>[] = [];

  constructor(private router: Router) {}

  hasItems(): boolean {
    if (this.items.length > 0) {
      return true;
    }
    return false;
  }
}
