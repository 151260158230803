import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';

import { CheckinReasonType, ICheckinReason } from 'libs/domain';
import { BehaviorSubject, Observable, ReplaySubject, Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

import { CheckinService } from '@shared/services/checkin';

import { CheckinTypesMessages } from './checkin-manager-reasons.constants';

@Component({
  selector: 'mg-checkin-manager-reasons',
  templateUrl: './checkin-manager-reasons.component.html',
  styleUrls: ['./checkin-manager-reasons.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckinManagerReasonsComponent implements OnInit, OnDestroy {
  // Constants

  public readonly MESSAGES: typeof CheckinTypesMessages = CheckinTypesMessages;

  // General Subjects

  private _destroyedSubject = new ReplaySubject<void>(1);

  // Observables

  public types$: Observable<ICheckinReason[]>;

  private readonly _loadingSubject = new BehaviorSubject<boolean>(true);
  public readonly loading$ = this._loadingSubject.asObservable();

  private readonly _onNewReasonCreatedSubject: Subject<ICheckinReason> =
    new Subject<ICheckinReason>();
  public readonly onNewReasonCreated$ =
    this._onNewReasonCreatedSubject.asObservable();

  // Component constructor

  constructor(
    private _checkinService: CheckinService,
    private _cdr: ChangeDetectorRef,
  ) {}

  // Lifecycle Hooks

  ngOnInit(): void {
    this.fetchReasons();
    this.types$
      .pipe(
        takeUntil(this._destroyedSubject),
        tap(() => {
          this._loadingSubject.next(true);
        }),
      )
      .subscribe(() => {
        this._loadingSubject.next(false);
      });
  }

  ngOnDestroy(): void {
    this._destroyedSubject.next();
    this._destroyedSubject.complete();
  }

  // Public Methods

  public async fetchReasons(): Promise<void> {
    this.types$ = this._checkinService.getReasonsObs(false);
  }

  public async handleOnClickCreateNew(): Promise<void> {
    const newReason: ICheckinReason = {
      name: '',
      mingaId: 0,
      active: false,
      pointReward: 0,
      icon: 'checkin-1',
      showAbsentees: false,
      selfCheckIn: false,
      stickerIds: [],
      roles: [],
      checkinReasonType: CheckinReasonType.CHECKIN,
    };

    this._onNewReasonCreatedSubject.next(newReason);
    this._cdr.markForCheck();
  }
}
