import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';

import { MgButtonModule } from '@app/src/app/button';
import { WhatsNewModule } from '@app/src/app/ftue/Welcome/components/WhatsNew';

import { WhatsNewDialog } from './WhatsNewDialog.component';

@NgModule({
  imports: [
    // Minga dependencies
    MgButtonModule,
    WhatsNewModule,

    // External dependencies
    CommonModule,
    MatDialogModule,
  ],
  declarations: [WhatsNewDialog],
  exports: [WhatsNewDialog],
})
export class WhatsNewDialogModule {}
