import { animate, style, transition, trigger } from '@angular/animations';
// Animations
import { Location, LocationStrategy } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  QueryList,
  TemplateRef,
  ViewChild,
  ViewChildren,
  ViewContainerRef,
} from '@angular/core';
import { MediaObserver } from '@angular/flex-layout';
import { MatMenuTrigger } from '@angular/material/menu';
import { RouterOutlet, UrlSegment } from '@angular/router';
import { Router } from '@angular/router';

import { splashScreen } from '@app/src/splashscreen';
import { Store } from '@ngrx/store';
import { ReportReason } from 'libs/util';
import { Observable, ReplaySubject, Subscription } from 'rxjs';

import { NavsearchComponent } from '@app/src/app/components/Navsearch';
import { IEmailContentSend } from '@app/src/app/content-common/services/EmailContent/types';
import { sendContentEmail } from '@app/src/app/content-common/store/actions';
import { MentionsService } from '@app/src/app/mentions';
import { BadgeManager } from '@app/src/app/minimal/services/BadgeManager';
import { ContentEvents } from '@app/src/app/minimal/services/ContentEvents';
import { MgModalService } from '@app/src/app/minimal/services/MgModal';
import { RootService } from '@app/src/app/minimal/services/RootService';
import { StreamManager } from '@app/src/app/minimal/services/StreamManager';
import { IOverlayStepNav, OverlayStepInfo } from '@app/src/app/misc/overlay';
import { MessagingFacade } from '@app/src/app/modules/direct-message/store';
import { NavigationFtueKeys } from '@app/src/app/navigation';
import { PermissionsService } from '@app/src/app/permissions';
import {
  SearchAreaService,
  SearchInstanceType,
  SearchService,
} from '@app/src/app/search';
import { ReportService } from '@app/src/app/services/Report';
import { RootNavService } from '@app/src/app/services/RootNav';
import { MingaSettingsService } from '@app/src/app/store/Minga/services';

interface IFailedModerationItem {
  bodyLocale: string;
  categories: string[];
  bodySecondLocale: string;
  imageCategories: string[];
  reasonIndex: ReportReason | null;
  imageIndex: number | null;
}

const PULL_DOWN_THRESHOLD = 80;

@Component({
  selector: 'mg-layout-legacy',
  templateUrl: './layout-legacy.component.html',
  styleUrls: ['./layout-legacy.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('subnav', [
      transition(':enter', [
        style({ transform: 'translateY(0%)' }),
        animate('50ms ease'),
      ]),
    ]),
  ],
})
export class LayoutLegacyComponent implements OnInit, OnDestroy {
  // Global menu data
  globalMenuItems: any[] = [];
  private _destroyed$ = new ReplaySubject<void>(1);

  // Children
  @ViewChild('globalMenu', { static: true })
  globalMenuEl?: ElementRef;
  @ViewChild('globalMenuTrigger', { static: true })
  globalMenuTriggerEl?: MatMenuTrigger;
  @ViewChild('successOverlayTemplate', { static: true })
  successOverlayTemplate?: TemplateRef<any>;
  @ViewChild('primaryTopToolbar', { read: ElementRef, static: true })
  primaryTopToolbar: ElementRef;
  @ViewChild('modalContainer', { read: ViewContainerRef, static: true })
  modalContainer: ViewContainerRef;
  @ViewChild('modFailedOverlayTemplate', { static: true })
  modFailedOverlayTemplate: TemplateRef<any>;
  @ViewChild('mainWrapEl', { static: true })
  mainWrapEl: ElementRef;
  @ViewChild('searchOutlet', { static: true })
  searchOutlet: RouterOutlet;
  @ViewChild('navSearch', { static: true })
  navSearch: NavsearchComponent;
  @ViewChildren('matMenuTriggerContainer')
  navMatMenuEls: QueryList<ElementRef>;
  @ViewChildren('navMatMenuTrigger')
  navMatMenuTriggerEls: QueryList<MatMenuTrigger>;

  // Inputs
  @Input() overlayEnabled = false;

  /** Search component */
  searchOverlayComponent = null;
  navSearchValue = '';
  readonly hamburgerFtueKeys = [NavigationFtueKeys.addPeople];
  title = 'Minga';
  activeOverlayComponent: any;
  topbarHidden = false;
  overlayPrevious?: OverlayStepInfo;
  overlayNext?: OverlayStepInfo;
  overlayStepNav?: IOverlayStepNav;
  overlayBgColor = '';
  overlayBoxShadow = '';
  disableOverlayPulldown = false;
  touchStartInfo = null;
  pullDownAmount = 0;
  snappedScrollPosition = 0;
  mqAlias = '';
  private _openSideNav = false;
  lastScrollY: number = window.scrollY;
  scrolledY = 0;

  modFailContextHash = '';
  modFailContentHash = '';
  modFailGalleryPhotoUuid = '';
  modFailHeadline = 'default';
  modFailBody = 'default';
  modFailBodySecond = '';
  failBodyTranslateParams: any = {};
  failBodyCatTranslateParams: any;
  readonly modFailItems: Map<number, IFailedModerationItem>;
  modFailSendEmailOptions: IEmailContentSend | null = null;

  private _menuOpenEventSub?: Subscription;

  get openSideNav(): boolean {
    return this.rootNavService.showNavOnTop;
  }

  set openSideNav(bool: boolean) {
    this.rootNavService.showNavOnTop = bool;
  }

  get enableSideNav(): boolean {
    return true;
  }

  readonly shouldShowDirectMessagesIcon$: Observable<boolean>;
  readonly shouldAllowPostAnyways$: Observable<boolean>;
  readonly shouldBlock$: Observable<boolean>;
  hasUnreadMessages$: Observable<boolean>;
  readonly isStudentIdEnabled$: Observable<boolean>;

  /** Component constructor */
  constructor(
    public rootService: RootService,
    public location: Location,
    public router: Router,
    public media: MediaObserver,
    public mgModal: MgModalService,
    public url: LocationStrategy,
    public streamManager: StreamManager,
    public contentEvents: ContentEvents,
    public searchAreaService: SearchAreaService,
    public mentionsService: MentionsService,
    public rootNavService: RootNavService,
    public reportService: ReportService,
    public badgeManager: BadgeManager,
    public search: SearchService,
    public messagingFacade: MessagingFacade,
    public permissionService: PermissionsService,
    public store: Store<any>,
    private _settingService: MingaSettingsService,
  ) {}

  ngOnInit(): void {
    splashScreen.hide();
  }

  ngOnDestroy(): void {
    this._clearMenuPositionSubs();
    this._destroyed$.next();
    this._destroyed$.complete();
    this.rootService.clearOpenGlobalMenuHandle();
  }

  public onSearchOverlayActivate(searchOverlayComponent: any) {
    this.searchOverlayComponent = searchOverlayComponent;
  }

  public onSearchOverlayDeactivate(searchOverlayComponent: any) {
    if (this.searchOverlayComponent === searchOverlayComponent) {
      this.searchOverlayComponent = null;
    }
  }

  public async onOpenGlobalMenu(options: any) {
    const { x, y, items } = options;

    this.globalMenuEl!.nativeElement.style.top = y + 'px';
    this.globalMenuEl!.nativeElement.style.left = x + 'px';
    this.globalMenuItems = items;

    await new Promise(resolve => requestAnimationFrame(resolve));
    this.globalMenuTriggerEl!.openMenu();
  }

  getModFailItemsAsArray() {
    const failItemsArray: any[] = [];
    for (const [key, value] of this.modFailItems.entries()) {
      let textCategories =
        value.categories && value.categories.length
          ? value.categories.join(', ')
          : '';
      const imageCategories =
        value.imageCategories && value.imageCategories.length
          ? value.imageCategories.join(', ')
          : '';
      let hasIndex = false;

      const itemObj: any = {
        bodyLocale: value.bodyLocale,
        bodySecondLocale: value.bodySecondLocale,
      };
      if (value.imageIndex && value.imageIndex >= 0) {
        itemObj.index = value.imageIndex;
        hasIndex = true;
      }

      if (!textCategories && !imageCategories) {
        textCategories = 'inappropriate';
      }
      if (value.bodyLocale === 'image') {
        itemObj.categories = { value: imageCategories };
      } else {
        itemObj.categories = { value: textCategories };
      }
      if (value.bodyLocale.includes('image') && hasIndex) {
        itemObj.hasIndex = true;
        itemObj.bodyLocale += 'Index';
      }

      if (value.bodySecondLocale && value.bodySecondLocale === 'image') {
        itemObj.categoriesSecond = { value: imageCategories };
      } else {
        itemObj.categoriesSecond = { value: textCategories };
      }
      if (value.bodySecondLocale.includes('image') && hasIndex) {
        itemObj.hasIndexSecond = true;
        itemObj.bodySecondLocale += 'Index';
      }

      failItemsArray.push(itemObj);
    }
    return failItemsArray;
  }

  public getModFailReasonList(): string[] {
    const reasonList: string[] = [];

    this.modFailItems.forEach(value => {
      if (!value.categories) return;
      reasonList.push(...value.categories);
    });

    return reasonList;
  }

  async overrideModFail() {
    if (this.modFailContextHash || this.modFailGalleryPhotoUuid) {
      const modFailContextHash = this.modFailContextHash;
      const modFailContentHash = this.modFailContentHash;
      const modFailSendEmailOptions = this.modFailSendEmailOptions;

      await this.rootService.addLoadingPromise(
        this.reportService.overrideAI({
          contentHash: modFailContentHash,
          contextHash: modFailContextHash,
          galleryPhotoUuid: this.modFailGalleryPhotoUuid,
          reasonList: this.getModFailReasonList(),
        }),
      );

      if (modFailSendEmailOptions && modFailContentHash) {
        this.store.dispatch(
          sendContentEmail({
            contentHash: modFailContentHash,
            options: modFailSendEmailOptions,
          }),
        );
      }

      if (this.modFailGalleryPhotoUuid) {
        await this.streamManager.restartStreamIfAvailable('GalleryFeed');
      }

      this.closeActiveModal();
    }
  }

  closeActiveModal() {
    this.mgModal.closeActiveModal();
  }

  private _clearMenuPositionSubs() {
    if (this._menuOpenEventSub) {
      this._menuOpenEventSub.unsubscribe();
      delete this._menuOpenEventSub;
    }
  }

  get isLoading() {
    return this.rootService.isLoading;
  }

  onScrollNaturalShowHide(e, scrollYDelta: number) {
    const toolbarEl = this.primaryTopToolbar.nativeElement;

    if (scrollYDelta > 0) {
      const clientRect = toolbarEl.getBoundingClientRect();

      if (clientRect.top * -1 > clientRect.height) {
        // @TODO: Remove 'absolute' check. Use a variable instead.
        if (toolbarEl.style.position === 'absolute') {
          toolbarEl.style.top = window.scrollY - clientRect.height + 'px';
        }
      }

      if (clientRect.top > 0) {
        toolbarEl.style.position = 'fixed';
        toolbarEl.style.top = '0px';
      }
    } else {
      // Anchor the element
      // @TODO: Remove '0px' check. Use a variable instead.
      if (toolbarEl.style.top === '0px') {
        toolbarEl.style.top = window.scrollY + 'px';
        toolbarEl.style.position = 'absolute';
      }
    }
  }

  onScrollAnimateShowHide(e, scrollYDelta: number) {
    if (window.scrollY <= 0) {
      this.topbarHidden = false;
      return;
    }

    const THRESHOLD = 95;
    this.scrolledY += scrollYDelta;

    if (scrollYDelta > 0) {
      this.scrolledY = Math.max(this.scrolledY, 0);
      if (this.scrolledY > THRESHOLD) {
        this.topbarHidden = false;
        this.scrolledY = 0;
      }
    } else {
      this.scrolledY = Math.min(this.scrolledY, 0);
      if (this.scrolledY < -THRESHOLD) {
        this.topbarHidden = true;
        this.scrolledY = 0;
      }
    }
  }

  pullDownStyle() {
    return { transform: `transformY(${this.pullDownAmount}px)` };
  }

  @HostListener('window:touchstart', ['$event'])
  onTouchStart(_e) {
    if (this.disableOverlayPulldown) return;

    const e = _e as TouchEvent;
    const hasPullHandlers =
      this.activeOverlayComponent || this.rootService.hasPullDownSubscribers();

    if (!hasPullHandlers) {
      return;
    }

    if (window.scrollY <= 0) {
      this.touchStartInfo = {
        scrollY: window.scrollY,
        pageX: (e as any).pageX || e.touches[0].pageX || 0,
        pageY: (e as any).pageY || e.touches[0].pageY || 0,
        targetScrolls: [],
        scrolling: false,
      };
      let target: HTMLElement = e.target as HTMLElement;
      while (target) {
        if (
          target.clientWidth < target.scrollWidth ||
          target.clientHeight < target.scrollHeight
        ) {
          this.touchStartInfo.targetScrolls.push({
            target,
            scrollLeft: target.scrollLeft,
            scrollTop: target.scrollTop,
          });
        }
        target = target.parentElement;
        if (target === document.scrollingElement) {
          break;
        }
      }
    }
  }

  @HostListener('window:touchmove', ['$event'])
  onTouchMove(_e) {
    const e = _e as TouchEvent;
    if (
      !this.touchStartInfo ||
      this.touchStartInfo.scrollY > 0 ||
      this.touchStartInfo.scrolling
    ) {
      return;
    }

    if (window.scrollY <= 0) {
      const pageX = (e as any).pageX || e.touches[0].pageX || 0;
      const pageY = (e as any).pageY || e.touches[0].pageY || 0;
      const offsetY = this.touchStartInfo.pageY - pageY;
      const offsetX = this.touchStartInfo.pageX - pageX;

      if (Math.abs(offsetX) > Math.abs(offsetY)) {
        this.touchStartInfo.scrolling = true;
        this.pullDownAmount = 0;
        return;
      }

      for (const targetScroll of this.touchStartInfo.targetScrolls) {
        const { target, scrollLeft, scrollTop } = targetScroll;
        if (scrollLeft !== target.scrollLeft) {
          this.touchStartInfo.scrolling = true;
          this.pullDownAmount = 0;
          return;
        }

        if (scrollTop !== target.scrollTop) {
          this.touchStartInfo.scrolling = true;
          this.pullDownAmount = 0;
          return;
        }
      }

      if (offsetY < 0) {
        this.pullDownAmount = offsetY;
        return false;
      }
    }
  }

  @HostListener('window:touchend', ['$event'])
  onTouchEnd(e) {
    const pullDownAmount = this.pullDownAmount;

    this.touchStartInfo = null;
    this.pullDownAmount = 0;

    if (pullDownAmount < -PULL_DOWN_THRESHOLD) {
      if (this.activeOverlayComponent) {
        this.closeOverlay();
      } else {
        this.rootService.emitPullDown();
      }
    }
  }

  @HostListener('window:scroll', ['$event'])
  onScroll(e) {
    const scrollYDelta = this.lastScrollY - window.scrollY;

    // We don't need to show/hide the top nav while in an overlay
    if (!this.activeOverlayComponent) {
      // Un comment this to enable the 'natural' top bar show hide
      // this.onScrollNaturalShowHide(e, scrollYDelta);

      // Comment this if you enable the 'natural' top bar show hide
      this.onScrollAnimateShowHide(e, scrollYDelta);
    }

    this.lastScrollY = window.scrollY;
  }

  pullDownTransform(pullDownAmount) {
    if (this.activeOverlayComponent) {
      return `translateY(0)`;
    }

    return `translateY(${-pullDownAmount}px)`;
  }

  /**
   *
   * Toggle a class variable that matches a name by string.
   *
   * @param variableName class variable name
   */
  onToggleNavItemBoolean(variableName: string) {
    if (
      variableName &&
      variableName in this &&
      typeof this[variableName] == 'boolean'
    ) {
      this[variableName] = !this[variableName];
    }
  }

  openAdmin() {}

  back() {
    this.location.back();
  }

  hasOpenedModal() {
    return this.modalContainer.length > 0;
  }

  onNavSearch(value) {
    const searchParams = this.searchOutlet.activatedRoute.snapshot.params;

    const queryUrlSegment = new UrlSegment(value, {});
    const urlSegments = this.searchOutlet.activatedRoute.snapshot.url;
    urlSegments[urlSegments.length - 1] = queryUrlSegment;

    const searchNavSegments = urlSegments.map(seg => seg.path);

    this.router.navigate(['.', { outlets: { search: searchNavSegments } }], {
      replaceUrl: true,
    });
  }

  onNavSearchChange(text: string) {
    this.search.setSearchQuery(text);
  }

  private _preventDefaultClickBehaviors(ev: MouseEvent) {
    ev.preventDefault();
    ev.stopPropagation();
    ev.stopImmediatePropagation();
  }

  clickSearch(ev: MouseEvent) {
    const searchInstanceType = this.search.searchInstanceType;

    if (searchInstanceType === SearchInstanceType.INLINE) {
      // Don't navigate with inline search instance or prevent click behaviors
    } else {
      this._preventDefaultClickBehaviors(ev);
      const query = this.search.searchQuery;

      if (this.searchAreaService.hasActiveSearchArea()) {
        this.router.navigate(['', { outlets: { search: ['q', query] } }]);
      } else {
        this.router.navigate(['', { outlets: { search: ['default', query] } }]);
      }
    }
  }

  async closeSearch() {
    await this.router.navigate(['', { outlets: { search: null } }], {
      replaceUrl: true,
    });
  }

  async closeOverlay() {
    if (
      this.activeOverlayComponent &&
      'onOverlayClose' in this.activeOverlayComponent
    ) {
      const overlayName = this.activeOverlayComponent.constructor.name;
      try {
        let result = this.activeOverlayComponent.onOverlayClose();

        if (result && typeof result == 'object' && 'then' in result) {
          result = await result;
        }

        if (result) {
          // If we returned a truthy value it is consider it handled and we
          // can return early to skip the default behaviour.
          return;
        } else {
          console.warn(overlayName, 'onOverlayClose returned false');
        }
      } catch (err) {
        console.error(overlayName, 'OverlayCloseError:', err);
      }
    }

    // Ran into bug: https://github.com/angular/angular/issues/15338
    // this.router.navigate(['.', {outlets: {o: null}}]);

    await this.router.navigate(['', { outlets: { o: null } }]);
  }

  private async _successDialog() {
    const dialogRef = this.mgModal.open(this.successOverlayTemplate!, {
      full: true,
      animation: 'fade',
    });

    setTimeout(() => dialogRef.close(), 2000);
    // Wait for the animation to start a tiny bit
    await new Promise(resolve => setTimeout(resolve, 1000));
  }

  restartStream(streamName: string | string[]) {
    this.streamManager.restartStreams(streamName);
  }
}
