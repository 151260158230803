import { ChangeDetectionStrategy, Component } from '@angular/core';

import { gateway } from 'libs/generated-grpc-web';

import { GlobalSearchService } from '@modules/global-search';

@Component({
  selector: 'mg-global-search-content',
  templateUrl: './global-search-content.component.html',
  styleUrls: ['./global-search-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GlobalSearchContentComponent {
  /** Component constructor */
  constructor(
    public searchManager: gateway.search_ng_grpc_pb.SearchManager,
    public search: GlobalSearchService,
  ) {}
}
