import * as _ from 'lodash';
import { Observable, Subject } from 'rxjs';

import { MgMentionElement } from '@app/src/app/elements/MgMention';

import { IMentionableResultItem, MENTION_CHAR } from '../../constants';
import {
  IMention,
  IMentionSearchChangeEvent,
  IMentionSearchCommitEvent,
  IMentionSearchEndEvent,
  IMentionSearchStartEvent,
  MentionStrategy,
} from './MentionStrategy';

function getKeyAtCaret(offset: number = 0): string {
  const sel = window.getSelection();
  const range = sel.getRangeAt(0);
  const startContainer = range.startContainer;
  const index = range.startOffset - 1 + offset;

  return startContainer.textContent[index] || '';
}

export class ContentEditableMentionStrategy implements MentionStrategy {
  private element: HTMLElement;
  private _mutationObserver: MutationObserver;
  private _onKeydown: (e: KeyboardEvent) => void;
  private _onKeypress: (e: KeyboardEvent) => void;
  private _onKeyup: (e: KeyboardEvent) => void;
  private _onBlur: (e: any) => void;

  private _mentionSearchStartSubject: Subject<IMentionSearchStartEvent>;
  private _mentionSearchEndSubject: Subject<IMentionSearchEndEvent>;
  private _mentionSearchCommitSubject: Subject<IMentionSearchCommitEvent>;
  private _mentionSearchChangeSubject: Subject<IMentionSearchChangeEvent>;
  private _mentionActive: boolean = false;
  private _mentionStart: { node: Node; offset: number };
  private _mentionSearch: string = '';

  private _lastFocusedNode?: Node;

  get searchStarted() {
    return this._mentionActive;
  }

  constructor() {
    this._mentionSearchStartSubject = new Subject();
    this._mentionSearchEndSubject = new Subject();
    this._mentionSearchCommitSubject = new Subject();
    this._mentionSearchChangeSubject = new Subject();

    this._mutationObserver = new MutationObserver(mutations => {
      let shouldCheckMentionSearch = false;

      for (let mutation of mutations) {
        if (mutation.type === 'characterData') {
          const target = mutation.target;

          if (!this._mentionActive) {
            const textContent = target.textContent;
            const mentionIndex = textContent.lastIndexOf(MENTION_CHAR);

            // start mention if MENTION_CHAR at beginning or not proceeded by
            // whitespace
            if (
              mentionIndex == 0 ||
              (mentionIndex > 0 && /\s/.test(textContent[mentionIndex - 1]))
            ) {
              const sel = window.getSelection();

              if (sel.focusNode === target) {
                const mentionString = sel.focusNode.textContent.substring(
                  mentionIndex,
                  sel.focusOffset,
                );
                if (mentionString.search(/\s/) === -1) {
                  this._startMention(sel.focusNode, mentionIndex);
                  shouldCheckMentionSearch = true;
                  break;
                }
              }
            }
          } else {
            shouldCheckMentionSearch = true;
          }
        }
      }

      if (shouldCheckMentionSearch) {
        this._checkMentionSearch();
      }
    });

    this._onKeyup = e => {
      if (window.MINGA_DEVICE_ANDROID) {
        // https://bugs.chromium.org/p/chromium/issues/detail?id=118639
        const key = e.key === 'Unidentified' ? getKeyAtCaret() : e.key;

        if (!this._mentionActive && key === MENTION_CHAR) {
          // this._startMention();
        } else if (this._mentionActive && key === ' ') {
          const prevChar = getKeyAtCaret(-1);
          if (prevChar === ' ') {
            this._endMention();
          }
        }
      }
    };

    this._onKeypress = e => {};

    this._onKeydown = e => {
      const key = e.key;

      if (key === 'Escape' || key === ' ') {
        const prevChar = this.getPreviousCharacter();
        if (this._mentionActive && prevChar === ' ') {
          this._endMention();
        }
      } else if (key === 'Enter' || key === 'Tab') {
        if (this._mentionActive) {
          // prent any leaving or newlines when mention search is active
          e.preventDefault();
          // mentionableUI element to handle Enter and Tab key events
        }
      } else if (!this._mentionActive && key === MENTION_CHAR) {
        // this._startMention();
      }
    };

    this._onBlur = e => {
      // Give it a bit of a delay so a selection can be made.
      setTimeout(() => {
        this._endMention();
      }, 300);
    };
  }

  getPreviousCharacter() {
    const sel = this.getTextSelection();
    const index = sel.selection.focusOffset - 1;
    const text = sel.focusTextNode.nodeValue;

    return text && text.length >= index ? text[index] : '';
  }

  connect(targetElement: HTMLElement) {
    if (!targetElement.isContentEditable) {
      throw new Error('Element is not contentEditable');
    }

    if (this.element) {
      this.disconnect();
    }

    this.element = targetElement;
    this._initListeners();
    this.getMentions()
      .map(mention => mention.element)
      .filter(element => !!element)
      .forEach(element => this._initMentionElement(element));
  }

  disconnect() {
    if (this._mutationObserver) {
      this._mutationObserver.disconnect();
    }

    if (this.element) {
      this.element.removeEventListener('keyup', this._onKeyup);
      this.element.removeEventListener('keydown', this._onKeydown);
      this.element.removeEventListener('keypress', this._onKeypress);
      this.element.removeEventListener('blur', this._onBlur, false);
    }
  }

  getMentionSearchPos() {
    const pos = { x: 0, y: 0 };

    if (this._mentionActive) {
      const { node, offset } = this._mentionStart;
      const textContent = node.textContent;
      const parentElement = node.parentElement;
      const before = document.createTextNode(textContent.substr(0, offset));
      const after = document.createTextNode(textContent.substr(offset));
      const tempSpan = document.createElement('span');
      parentElement.insertBefore(before, node);
      parentElement.replaceChild(after, node);
      parentElement.insertBefore(tempSpan, after);

      const rect = tempSpan.getBoundingClientRect();

      pos.x = rect.left;
      pos.y = rect.top;

      parentElement.insertBefore(node, before);
      parentElement.removeChild(before);
      parentElement.removeChild(after);
      parentElement.removeChild(tempSpan);
    }

    return pos;
  }

  private _initListeners() {
    this._mutationObserver.observe(this.element, {
      childList: true,
      subtree: true,
      characterData: true,
    });

    this.element.addEventListener('keyup', this._onKeyup, false);
    this.element.addEventListener('keydown', this._onKeydown, false);
    this.element.addEventListener('keypress', this._onKeypress, false);
    this.element.addEventListener('blur', this._onBlur, false);
  }

  private _getMentionSearchString() {
    const { node, offset } = this._mentionStart;
    let searchString = node.textContent;
    let currentNode = node;

    while (currentNode.nextSibling) {
      currentNode = currentNode.nextSibling;
      searchString += currentNode.textContent;
    }

    const afterOffset = searchString.substr(offset).trim();
    // allow for single space, but not two in search
    const wsIndex = afterOffset.search(/\s\s/);

    if (wsIndex > -1) {
      return afterOffset.substr(0, wsIndex);
    } else {
      return afterOffset;
    }
  }

  private _startMention(node: Node, offset: number) {
    if (!node) {
      return;
    }
    this._mentionActive = true;
    this._mentionStart = {
      node: node,
      offset: offset,
    };

    // @HACK: On android chrome this selection seems to be 1 character off.
    //        This should be more looked into, fortunately this doesn't affect
    //        the selection logic too much.
    if (node.textContent[offset] !== MENTION_CHAR) {
      if (node.textContent[offset - 1] === MENTION_CHAR) {
        this._mentionStart.offset -= 1;
      }
    }

    this._mentionSearchStartSubject.next({});
  }

  private _checkMentionSearch() {
    const mentionSearch = this._getMentionSearchString();

    if (!mentionSearch) {
      this._endMention();
    } else {
      if (mentionSearch != this._mentionSearch) {
        this._mentionSearch = mentionSearch;
        this._mentionSearchChangeSubject.next({
          searchString: mentionSearch.substr(MENTION_CHAR.length),
        });
      }
    }
  }

  private _endMention() {
    this._mentionActive = false;
    delete this._mentionStart;
    this._mentionSearchEndSubject.next({});
  }

  private _initMentionElement(mentionEl?: HTMLElement) {
    if (window.MINGA_DEVICE_ANDROID) {
      mentionEl.contentEditable = 'true';

      // Timeout is necessary to skip initial mention element insert
      setTimeout(() => {
        // Self destruct on android
        new MutationObserver(() => mentionEl.remove()).observe(mentionEl, {
          characterData: true,
          childList: true,
          subtree: true,
        });
      }, 0);
    } else {
      mentionEl.contentEditable = 'false';
    }
  }

  private getTextSelection() {
    const sel = window.getSelection();
    const focusTextOffset = sel.focusOffset;

    let node = sel.focusNode;
    while (node && node.nodeType !== document.TEXT_NODE) {
      node = node.firstChild;
    }

    return {
      focusTextNode: node,
      focusTextOffset,
      selection: sel,
    };
  }

  private _insertNewMentionAtActive(mentionString: string, hash: string) {
    const sel = window.getSelection();
    const { node, offset } = this._mentionStart;
    const mentionEl = document.createElement('mg-mention');
    mentionEl.setAttribute('hash', hash);
    this._initMentionElement(mentionEl);
    mentionEl.textContent = mentionString;

    const beforeText = document.createTextNode(
      node.textContent.substr(0, offset),
    );
    const afterText = document.createTextNode(
      ' ' + node.textContent.substring(offset + this._mentionSearch.length + 1),
    );

    node.parentElement.insertBefore(beforeText, node);
    node.parentElement.insertBefore(mentionEl, node);
    node.parentElement.insertBefore(afterText, node);

    node.parentElement.removeChild(node);

    sel.setPosition(afterText, 1);
    return mentionEl;
  }

  commitMentionSearch(item?: IMentionableResultItem) {
    if (!this.searchStarted) {
      throw new Error('Cannot commit mention search when it has not started');
    }

    if (!item && this._mentionActive) {
      this._endMention();
    } else {
      this._commitActiveMention();
    }
  }

  private _commitActiveMention() {
    const searchString = this._getMentionSearchString();
    let insertedMention = false;

    const insertMention = (mentionString: string, hash: string) => {
      if (insertedMention) {
        throw new Error('insertMention called more than once');
      }

      insertedMention = true;
      this._insertNewMentionAtActive(mentionString, hash);
    };

    this._mentionSearchCommitSubject.next({
      searchString: searchString.substr(1),
      get alreadyInsertedMention() {
        return insertedMention;
      },
      insertMention,
    });

    this._endMention();
  }

  get onMentionSearchStart(): Observable<IMentionSearchStartEvent> {
    return this._mentionSearchStartSubject.asObservable();
  }

  get onMentionSearchEnd(): Observable<IMentionSearchEndEvent> {
    return this._mentionSearchEndSubject.asObservable();
  }

  get onMentionSearchCommit(): Observable<IMentionSearchCommitEvent> {
    return this._mentionSearchCommitSubject.asObservable();
  }

  get onMentionSearchChange(): Observable<IMentionSearchChangeEvent> {
    return this._mentionSearchChangeSubject.asObservable();
  }

  getMentions(): IMention[] {
    const mentionElementList = this.element.querySelectorAll('mg-mention');
    const mentionElements: MgMentionElement.Element[] = [];

    for (let i = 0; mentionElementList.length > i; i++) {
      const mentionElement = mentionElementList[i];
      mentionElements.push(mentionElement);
    }

    return mentionElements.map(mentionElement => {
      const mention: IMention = {
        element: mentionElement,
        mentionString: mentionElement.textContent || '',
      };

      return mention;
    });
  }

  removeMention(mentionIndex: number) {
    const mention = this.getMentions()[mentionIndex];
    if (mention) {
      mention.element.parentElement.removeChild(mention.element);
    }
  }

  selectionStartBeforeMention(mentionIndex: number) {
    const mention = this.getMentions()[mentionIndex];
    const sel = window.getSelection();

    if (!mention || !sel) {
      return;
    }

    sel.setBaseAndExtent(mention.element, 0, sel.focusNode, sel.focusOffset);
  }

  selectionEndBeforeMention(mentionIndex: number) {
    const mention = this.getMentions()[mentionIndex];
    const sel = window.getSelection();

    if (!mention || !sel) {
      return;
    }

    sel.setBaseAndExtent(sel.anchorNode, sel.anchorOffset, mention.element, 0);
  }

  selectionStartAfterMention(mentionIndex: number) {
    const mention = this.getMentions()[mentionIndex];
    const sel = window.getSelection();

    if (!mention || !sel) {
      return;
    }

    sel.setBaseAndExtent(
      mention.element,
      mention.mentionString.length,
      sel.focusNode,
      sel.focusOffset,
    );
  }

  selectionEndAfterMention(mentionIndex: number) {
    const mention = this.getMentions()[mentionIndex];
    const sel = window.getSelection();

    if (!mention || !sel) {
      return;
    }

    sel.setBaseAndExtent(
      sel.anchorNode,
      sel.anchorOffset,
      mention.element,
      mention.mentionString.length,
    );
  }

  collapseSelectionBeforeMention(mentionIndex: number) {
    const mention = this.getMentions()[mentionIndex];
    const sel = window.getSelection();

    if (!mention) {
      return;
    }

    sel.collapse(mention.element, 0);
  }

  collapseSelectionAfterMention(mentionIndex: number) {
    const mention = this.getMentions()[mentionIndex];
    const sel = window.getSelection();

    if (!mention) {
      return;
    }

    sel.collapse(mention.element, mention.mentionString.length);
  }
}
