import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { GridModule } from '@angular/flex-layout/grid';
import { MatCheckboxModule } from '@angular/material/checkbox';

import { MgEmptyStateModule } from '@app/src/app/components/EmptyState';
import { MgIconModule } from '@app/src/app/icon';
import { MgPipesModule } from '@app/src/app/pipes';
import { MgSpinnerModule } from '@app/src/app/spinner';

import { CountTileModule } from '@shared/components/count-tile';
import { FormModule } from '@shared/components/form';
import { GenericModule } from '@shared/components/generic';
import { ManagerOverlayModule } from '@shared/components/manager-overlay';
import { TextModule } from '@shared/components/text/text.module';
import { TilesCarouselModule } from '@shared/components/tiles-carousel';
import { CommonPipesModule } from '@shared/modules';

import { FtmSharedModule } from '../ftm-shared/ftm-shared.module';
import { FtmDashboardComponent } from './ftm-dashboard.component';
import {
  CalculateStudentCheckedInPipe,
  GetButtonStatusColorPipe,
  GetPeriodStatusPipe,
  GetStudentRegisteredPipe,
} from './pipes';
import { FtmDashboardService } from './services';

@NgModule({
  imports: [
    // Minga dependencies
    ManagerOverlayModule,
    MgIconModule,
    TilesCarouselModule,
    MgSpinnerModule,
    MgEmptyStateModule,
    CountTileModule,
    FtmSharedModule,
    FormModule,
    TextModule,
    MatCheckboxModule,
    GenericModule,
    CommonPipesModule,
    MgPipesModule,

    // External dependencies
    CommonModule,
    FlexLayoutModule,
    GridModule,
  ],
  declarations: [
    FtmDashboardComponent,
    GetStudentRegisteredPipe,
    CalculateStudentCheckedInPipe,
    GetPeriodStatusPipe,
    GetButtonStatusColorPipe,
  ],
  exports: [FtmDashboardComponent],
  providers: [FtmDashboardService],
})
export class FtmDashboardModule {}
