import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';

import { Store } from '@ngrx/store';

import { AuthInfoService } from '@app/src/app/minimal/services/AuthInfo';
import { setPreAuthRoute } from '@app/src/app/store/AuthStore/actions';

/**
 * Guard to guard whether we have user info. NOTE: we may not be properly
 * logged in at this point, we may just have stored user info loaded, so
 * this is not a sufficient guard if the component does any requests
 * that require authentication.
 */
@Injectable()
export class HasUserGuard implements CanActivate {
  constructor(
    private router: Router,
    private authInfo: AuthInfoService,
    private store$: Store<any>,
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): boolean | UrlTree {
    if (!this.authInfo.authPerson) {
      this.store$.dispatch(setPreAuthRoute({ preAuthUrl: state.url }));
      return this.router.createUrlTree(['/landing']);
    }

    return true;
  }
}
