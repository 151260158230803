import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormControl } from '@angular/forms';

import * as day from 'dayjs';
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { FormSelectAppearance } from '../../types';

type Option = { label: string; value: string };

@Component({
  selector: 'mg-form-multi-date',
  templateUrl: './form-multi-date.component.html',
  styleUrls: ['./form-multi-date.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormMultiDateComponent implements OnInit, OnDestroy {
  @ViewChild('calendarTemplate') calendarTemplate;
  @ViewChild('addBtn') addBtn;

  @Input() placeholder: string;
  @Input()
  set control(formControl: UntypedFormControl | AbstractControl) {
    formControl.setValue(formControl.value || []);
    if (formControl.value?.length) {
      formControl.value.map(d => {
        const date = day(d);
        this._addDate(date);
      });
    }

    this.formControl = formControl as UntypedFormControl;
  }
  @Input() appearance: FormSelectAppearance;
  @Input() isClearable: boolean;
  @Input() appendTo: string;
  @Input() set disabled(isDisabled: boolean) {
    this._isDisabled.next(isDisabled);
    if (isDisabled) {
      this.formControl.disable();
    } else {
      this.formControl.enable();
    }
  }
  @Input() dateFormat = 'MMM D, YYYY';
  @Input() allowPastDate: boolean;

  private _dateValueFormat = 'YYYY-MM-DD'; // if setting default it needs to be in this format
  private _overlayRef: OverlayRef;
  private readonly _destroyed$ = new ReplaySubject<void>(1);
  private _isCalendarOpen = false;
  private _datesSubj = new BehaviorSubject<Option[]>([]);
  public dates$ = this._datesSubj.asObservable();
  public formControl: UntypedFormControl = this._fb.control([]);
  private readonly _isDisabled = new BehaviorSubject<boolean>(false);
  public readonly isDisabled$ = this._isDisabled.asObservable();

  constructor(
    private _fb: UntypedFormBuilder,
    private _overlay: Overlay,
    private _viewContainerRef: ViewContainerRef,
    private _cdr: ChangeDetectorRef,
  ) {}

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(
    event: KeyboardEvent,
  ) {
    this._closeOverlay();
  }

  ngOnInit(): void {
    this.formControl.statusChanges
      .pipe(takeUntil(this._destroyed$))
      .subscribe(status => {
        this._cdr.markForCheck();
      });
  }

  ngOnDestroy(): void {
    this._closeOverlay();
    this._destroyed$.next();
    this._destroyed$.complete();
    this._isDisabled.complete();
  }

  public openCalendar(): void {
    if (this._isInputDisabled()) return;
    if (!this._isCalendarOpen) this._createOverlay();
    else this._closeOverlay();
  }

  public setDate(date: day.Dayjs) {
    if (this._isInputDisabled()) return;
    const dateValue = date.format(this._dateValueFormat);

    if (!this.formControl.value.includes(dateValue)) {
      this._addDate(date);
    }
    this._closeOverlay();
  }

  public remove(date) {
    if (this._isInputDisabled()) return;

    this._removeDate(date);
  }

  private _addDate(date: day.Dayjs) {
    const dateValue = date.format(this._dateValueFormat);
    const controlValue = [...(this.formControl.value || []), dateValue];
    this.formControl.setValue(controlValue);
    const options = [
      ...this._datesSubj.value,
      { label: date.format(this.dateFormat), value: dateValue },
    ];

    const sortedOptions = options.sort((a, b) => {
      return day(a.value).isBefore(day(b.value)) ? -1 : 1;
    });
    this._datesSubj.next(sortedOptions);
  }

  private _removeDate(date: string) {
    const controlValue = (this.formControl.value || []).filter(d => d !== date);
    this.formControl.setValue(controlValue);
    const options = this._datesSubj.value.filter(d => d.value !== date);
    this._datesSubj.next(options);
  }

  private _isInputDisabled(): boolean {
    const disabled = this._isDisabled.value;
    return disabled;
  }

  private _createOverlay() {
    this._overlayRef = this._overlay.create({
      positionStrategy: this._getPositionStrategy(),
      hasBackdrop: true,
      backdropClass: 'cdk-overlay-transparent-backdrop',
    });
    const calendarTemplateRef = new TemplatePortal(
      this.calendarTemplate,
      this._viewContainerRef,
    );
    this._overlayRef.attach(calendarTemplateRef);
    this._overlayRef.backdropClick().subscribe(() => {
      this._overlayRef.dispose();
    });
  }

  private _closeOverlay() {
    if (!this._overlayRef) return;
    this._overlayRef.detach();
    this._isCalendarOpen = false;
  }

  private _getPositionStrategy() {
    return this._overlay
      .position()
      .flexibleConnectedTo(this.addBtn)
      .withPositions([
        {
          originX: 'start',
          originY: 'bottom',
          overlayX: 'start',
          overlayY: 'top',
        },
        {
          originX: 'start',
          originY: 'top',
          overlayX: 'start',
          overlayY: 'bottom',
        },
      ]);
  }
}
