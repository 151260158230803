import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  SimpleChanges,
} from '@angular/core';

import { Subscription } from 'rxjs';

import { LikeService } from '@app/src/app/minimal/services/Like';
import { numeral } from '@app/src/app/util/numeral';

export interface IMgLikeElementProperties {
  count: number;
}

export interface ILikedContent {
  liked: boolean;
  contextHash: string;
}

@Component({
  selector: 'mg-like',
  templateUrl: './MgLike.element.html',
  styleUrls: ['./MgLike.element.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MgLikeElement implements IMgLikeElementProperties, OnDestroy {
  @Input()
  count: number = 0;

  @Output()
  countChange: EventEmitter<number> = new EventEmitter();

  @Input()
  context: string = '';

  @Input()
  liked: boolean = false;

  @Output()
  likedChange: EventEmitter<boolean> = new EventEmitter();

  @Input()
  showtext: boolean = false;

  @Input()
  hideicon: boolean = false;

  @Input()
  hidecount: boolean = false;

  _likeClickAnimation: boolean = false;
  likeSubscription: Subscription | null = null;

  constructor(
    private likeservice: LikeService,
    private _cdr: ChangeDetectorRef,
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.context) {
      this._contextChange();
    }
  }

  _setLiked(value: boolean) {
    this.liked = value;
    this.likedChange.emit(this.liked);
  }

  getLikeText(): string {
    let _likeText = '';

    if (this.hidecount && this.context) {
      const isLiked = this.isLiked();
      _likeText = isLiked ? 'Unlike' : 'Like';
    } else if (this.showtext) {
      _likeText = this.count > 1 || this.count == 0 ? 'Likes' : 'Like';
    }

    return _likeText;
  }

  getDisplayCount() {
    let count = this.count;
    if (this.likeservice) {
      count = this.likeservice.likeCount(this.context, this.count);
    }

    return this.numberize(count);
  }

  _contextChange() {
    if (this.likeSubscription !== null) {
      this.likeSubscription.unsubscribe();
      this.likeSubscription = null;
    }
    const context = this.context;

    if (!context) {
      return;
    }

    this._setLiked(this.likeservice.isLiked(context));

    const sub = this.likeservice
      .onLikedChangedUnique(context)
      .subscribe(liked => {
        this._setLiked(liked);
        this.count += liked ? 1 : -1;
        this.countChange.emit(this.count);
        this._cdr.markForCheck();
      });

    this.likeSubscription = sub;
  }

  ngOnInit() {}

  ngOnDestroy() {
    if (this.likeSubscription) {
      this.likeSubscription.unsubscribe();
      this.likeSubscription = null;
    }
  }

  likeClicked() {
    return this._likeClickAnimation;
  }

  isLiked() {
    return this.likeservice.isLiked(this.context);
  }

  async like(ev: HammerInput) {
    ev.srcEvent.stopImmediatePropagation();
    ev.srcEvent.stopPropagation();
    ev.srcEvent.preventDefault();

    if (!this.context) {
      console.error('<mg-like> context is unset');
      return;
    }

    this._likeClickAnimation = true;

    setTimeout(() => {
      this._likeClickAnimation = false;
    }, 600);

    await this.likeservice.toggleLike(this.context);
  }

  numberize(count: number | string) {
    let value = numeral(count).value();

    if (value > 1000) {
      let numberized: string = numeral(value).format('0a');
      return numberized;
    } else {
      return value;
    }
  }

  getHideIconState() {
    return this.hideicon;
  }
}
