import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';

import { MgPipesModule } from '@app/src/app/pipes';

import { Calendar } from './Calendar.component';
import { CustomCalendarBody } from './CustomCalendarBody.component';
import { CustomCalendarHeader } from './CustomCalendarHeader.component';
import { CustomMonthView } from './CustomMonthView.component';

@NgModule({
  imports: [
    // Minga dependencies
    MgPipesModule,

    // External dependencies
    CommonModule,
    MatDatepickerModule,
    MatButtonModule,
  ],
  declarations: [
    Calendar,
    CustomCalendarBody,
    CustomMonthView,
    CustomCalendarHeader,
  ],
  exports: [
    Calendar,
    CustomCalendarBody,
    CustomMonthView,
    CustomCalendarHeader,
  ],
})
export class CalendarModule {}
