import { designed_svg_pb } from 'libs/generated-grpc-ts';
import { IStudioContentInfo } from 'libs/shared';

import { DesignedSvgMapper } from './DesignedSvgMapper';

export namespace StudioContentInfoMapper {
  export function toIStudioContentInfo(
    studioContentInfo: designed_svg_pb.StudioContentInfo,
  ): IStudioContentInfo {
    const designedSvg = studioContentInfo.getDesignedSvg();

    return {
      designedSvg: DesignedSvgMapper.toIDesignedSvg(designedSvg),
      templateId: studioContentInfo.getTemplateId(),
    };
  }

  export function fromIStudioContentInfo(
    studioContentInfo: IStudioContentInfo,
  ): designed_svg_pb.StudioContentInfo {
    const msg = new designed_svg_pb.StudioContentInfo();

    msg.setDesignedSvg(
      DesignedSvgMapper.fromIDesignedSvg(studioContentInfo.designedSvg),
    );
    msg.setTemplateId(studioContentInfo.templateId);

    return msg;
  }
}
