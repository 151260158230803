import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';

import { TranslateModule } from '@ngx-translate/core';

import { MgEmptyStateModule } from '@app/src/app/components/EmptyState';
import { MgStreamScrollerModule } from '@app/src/app/components/MgStreamScroller';
import { MgPagedScrollerModule } from '@app/src/app/components/mg-paged-scroller';
import { BadgeDialogModule } from '@app/src/app/dialog/BadgeDialog';
import { PointsDialogModule } from '@app/src/app/dialog/PointsDialog';
import { MgNotificationModule } from '@app/src/app/elements/MgNotification';
import { MessageDialogModule } from '@app/src/app/minimal/components/MessageDialog';

import { NotificationsComponent } from './Notifications.component';

@NgModule({
  imports: [
    // Minga dependencies
    MgStreamScrollerModule,
    MgNotificationModule,
    MessageDialogModule,
    MgEmptyStateModule,
    PointsDialogModule,
    BadgeDialogModule,
    MgPagedScrollerModule,

    // External dependencies
    FlexLayoutModule,
    TranslateModule,
  ],
  declarations: [NotificationsComponent],
  exports: [NotificationsComponent],
})
export class NotificationsModule {}
