import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

import { AnalyticsService } from '@app/src/app/minimal/services/Analytics';

import { StudentToolsService } from '../../services';

@Component({
  selector: 'mg-st-root',
  templateUrl: './st-root.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./st-root.component.scss'],
})
export class StRootComponent implements OnInit {
  constructor(
    private _studentToolsService: StudentToolsService,
    private _analytics: AnalyticsService,
  ) {
    this._analytics.logEvent('studentToolsOpened', {});
  }

  ngOnInit(): void {
    this._studentToolsService.getStudentToolsBehaviors();
  }
}
