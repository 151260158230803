import { Injectable, NgZone } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { grpc } from '@improbable-eng/grpc-web';

import {
  StreamControl,
  StreamControlResponse,
  StreamID,
} from '../common/stream_pb';
import {
  ArchivePeopleRequest,
  ArchivePeopleResponse,
  CreatePeopleRequest,
  CreatePeopleResponse,
  DeletePeopleRequest,
  DeletePeopleResponse,
  GetPersonsTeamsRequest,
  GetPersonsTeamsResponse,
  GetRolesRequest,
  GetRolesResponse,
  NextMingaPinRequest,
  NextMingaPinResponse,
  OpenMingaRequest,
  OpenMingaResponse,
  ReadPeopleRequest,
  ReadPeopleResponse,
  SelfArchivePersonRequest,
  SelfArchivePersonResponse,
  UpdatePeopleRequest,
  UpdatePeopleResponse,
  ArchivePeopleBulkRequest,
  ArchivePeopleBulkResponse,
  DeletePeopleBulkRequest,
  DeletePeopleBulkResponse,
  ResendAllInvitesRequest,
  ResendAllInvitesResponse,
  ResendPinEmailRequest,
  ResendPinEmailResponse,
  StreamPeopleResponse,
  SyncPeopleBulkRequest,
  SyncPeopleBulkResponse,
  EmailUniqueRequest,
  EmailUniqueResponse,
  PinUniqueRequest,
  PinUniqueResponse,
  ExportPeopleChunk,
  ExportPeopleRequest,
  MingaPeopleInfo,
  MingaPeopleInfoRequest,
  MingaPeopleSearchResult,
} from '../gateway/people_pb';

import {
  PeopleManager as __PeopleManager,
  PeopleAdminManager as __PeopleAdminManager,
  PersonValidation as __PersonValidation,
  MingaPeopleManager as __MingaPeopleManager,
} from '../gateway/people_pb_service';

/**
  * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
  PeopleManager service (all rpc calls available for people)
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *  */
@Injectable({providedIn: 'root'})
export class PeopleManager {

  constructor(private _ngZone: NgZone) {
  }

  /**
    Multiple Person CRUD operations
  */
  createPeople(request: CreatePeopleRequest): Promise<CreatePeopleResponse>;
  /**
    Multiple Person CRUD operations
  */
  createPeople(request: CreatePeopleRequest, metadata: grpc.Metadata): Promise<CreatePeopleResponse>;
  /**
    Multiple Person CRUD operations
  */
  createPeople(request: CreatePeopleRequest, callback: (err: any|null, response: CreatePeopleResponse, metadata: grpc.Metadata) => void): void;
  /**
    Multiple Person CRUD operations
  */
  createPeople(request: CreatePeopleRequest, metadata: grpc.Metadata, callback: (err: any|null, response: CreatePeopleResponse, metadata: grpc.Metadata) => void): void;

  createPeople(request: CreatePeopleRequest, arg1?: grpc.Metadata|((err: any|null, response: CreatePeopleResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: CreatePeopleResponse, metadata: grpc.Metadata) => void): Promise<CreatePeopleResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<CreatePeopleResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__PeopleManager.createPeople, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  readPeople(request: ReadPeopleRequest): Promise<ReadPeopleResponse>;
  readPeople(request: ReadPeopleRequest, metadata: grpc.Metadata): Promise<ReadPeopleResponse>;
  readPeople(request: ReadPeopleRequest, callback: (err: any|null, response: ReadPeopleResponse, metadata: grpc.Metadata) => void): void;
  readPeople(request: ReadPeopleRequest, metadata: grpc.Metadata, callback: (err: any|null, response: ReadPeopleResponse, metadata: grpc.Metadata) => void): void;

  readPeople(request: ReadPeopleRequest, arg1?: grpc.Metadata|((err: any|null, response: ReadPeopleResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: ReadPeopleResponse, metadata: grpc.Metadata) => void): Promise<ReadPeopleResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<ReadPeopleResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__PeopleManager.readPeople, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  updatePeople(request: UpdatePeopleRequest): Promise<UpdatePeopleResponse>;
  updatePeople(request: UpdatePeopleRequest, metadata: grpc.Metadata): Promise<UpdatePeopleResponse>;
  updatePeople(request: UpdatePeopleRequest, callback: (err: any|null, response: UpdatePeopleResponse, metadata: grpc.Metadata) => void): void;
  updatePeople(request: UpdatePeopleRequest, metadata: grpc.Metadata, callback: (err: any|null, response: UpdatePeopleResponse, metadata: grpc.Metadata) => void): void;

  updatePeople(request: UpdatePeopleRequest, arg1?: grpc.Metadata|((err: any|null, response: UpdatePeopleResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: UpdatePeopleResponse, metadata: grpc.Metadata) => void): Promise<UpdatePeopleResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<UpdatePeopleResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__PeopleManager.updatePeople, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  deletePeople(request: DeletePeopleRequest): Promise<DeletePeopleResponse>;
  deletePeople(request: DeletePeopleRequest, metadata: grpc.Metadata): Promise<DeletePeopleResponse>;
  deletePeople(request: DeletePeopleRequest, callback: (err: any|null, response: DeletePeopleResponse, metadata: grpc.Metadata) => void): void;
  deletePeople(request: DeletePeopleRequest, metadata: grpc.Metadata, callback: (err: any|null, response: DeletePeopleResponse, metadata: grpc.Metadata) => void): void;

  deletePeople(request: DeletePeopleRequest, arg1?: grpc.Metadata|((err: any|null, response: DeletePeopleResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: DeletePeopleResponse, metadata: grpc.Metadata) => void): Promise<DeletePeopleResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<DeletePeopleResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__PeopleManager.deletePeople, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  archivePeople(request: ArchivePeopleRequest): Promise<ArchivePeopleResponse>;
  archivePeople(request: ArchivePeopleRequest, metadata: grpc.Metadata): Promise<ArchivePeopleResponse>;
  archivePeople(request: ArchivePeopleRequest, callback: (err: any|null, response: ArchivePeopleResponse, metadata: grpc.Metadata) => void): void;
  archivePeople(request: ArchivePeopleRequest, metadata: grpc.Metadata, callback: (err: any|null, response: ArchivePeopleResponse, metadata: grpc.Metadata) => void): void;

  archivePeople(request: ArchivePeopleRequest, arg1?: grpc.Metadata|((err: any|null, response: ArchivePeopleResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: ArchivePeopleResponse, metadata: grpc.Metadata) => void): Promise<ArchivePeopleResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<ArchivePeopleResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__PeopleManager.archivePeople, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  selfArchivePerson(request: SelfArchivePersonRequest): Promise<SelfArchivePersonResponse>;
  selfArchivePerson(request: SelfArchivePersonRequest, metadata: grpc.Metadata): Promise<SelfArchivePersonResponse>;
  selfArchivePerson(request: SelfArchivePersonRequest, callback: (err: any|null, response: SelfArchivePersonResponse, metadata: grpc.Metadata) => void): void;
  selfArchivePerson(request: SelfArchivePersonRequest, metadata: grpc.Metadata, callback: (err: any|null, response: SelfArchivePersonResponse, metadata: grpc.Metadata) => void): void;

  selfArchivePerson(request: SelfArchivePersonRequest, arg1?: grpc.Metadata|((err: any|null, response: SelfArchivePersonResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: SelfArchivePersonResponse, metadata: grpc.Metadata) => void): Promise<SelfArchivePersonResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<SelfArchivePersonResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__PeopleManager.selfArchivePerson, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  getNextMingaPin(request: NextMingaPinRequest): Promise<NextMingaPinResponse>;
  getNextMingaPin(request: NextMingaPinRequest, metadata: grpc.Metadata): Promise<NextMingaPinResponse>;
  getNextMingaPin(request: NextMingaPinRequest, callback: (err: any|null, response: NextMingaPinResponse, metadata: grpc.Metadata) => void): void;
  getNextMingaPin(request: NextMingaPinRequest, metadata: grpc.Metadata, callback: (err: any|null, response: NextMingaPinResponse, metadata: grpc.Metadata) => void): void;

  getNextMingaPin(request: NextMingaPinRequest, arg1?: grpc.Metadata|((err: any|null, response: NextMingaPinResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: NextMingaPinResponse, metadata: grpc.Metadata) => void): Promise<NextMingaPinResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<NextMingaPinResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__PeopleManager.getNextMingaPin, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  openMinga(request: OpenMingaRequest): Promise<OpenMingaResponse>;
  openMinga(request: OpenMingaRequest, metadata: grpc.Metadata): Promise<OpenMingaResponse>;
  openMinga(request: OpenMingaRequest, callback: (err: any|null, response: OpenMingaResponse, metadata: grpc.Metadata) => void): void;
  openMinga(request: OpenMingaRequest, metadata: grpc.Metadata, callback: (err: any|null, response: OpenMingaResponse, metadata: grpc.Metadata) => void): void;

  openMinga(request: OpenMingaRequest, arg1?: grpc.Metadata|((err: any|null, response: OpenMingaResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: OpenMingaResponse, metadata: grpc.Metadata) => void): Promise<OpenMingaResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<OpenMingaResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__PeopleManager.openMinga, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  getRoles(request: GetRolesRequest): Promise<GetRolesResponse>;
  getRoles(request: GetRolesRequest, metadata: grpc.Metadata): Promise<GetRolesResponse>;
  getRoles(request: GetRolesRequest, callback: (err: any|null, response: GetRolesResponse, metadata: grpc.Metadata) => void): void;
  getRoles(request: GetRolesRequest, metadata: grpc.Metadata, callback: (err: any|null, response: GetRolesResponse, metadata: grpc.Metadata) => void): void;

  getRoles(request: GetRolesRequest, arg1?: grpc.Metadata|((err: any|null, response: GetRolesResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: GetRolesResponse, metadata: grpc.Metadata) => void): Promise<GetRolesResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<GetRolesResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__PeopleManager.getRoles, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  getTeams(request: GetPersonsTeamsRequest): Promise<GetPersonsTeamsResponse>;
  getTeams(request: GetPersonsTeamsRequest, metadata: grpc.Metadata): Promise<GetPersonsTeamsResponse>;
  getTeams(request: GetPersonsTeamsRequest, callback: (err: any|null, response: GetPersonsTeamsResponse, metadata: grpc.Metadata) => void): void;
  getTeams(request: GetPersonsTeamsRequest, metadata: grpc.Metadata, callback: (err: any|null, response: GetPersonsTeamsResponse, metadata: grpc.Metadata) => void): void;

  getTeams(request: GetPersonsTeamsRequest, arg1?: grpc.Metadata|((err: any|null, response: GetPersonsTeamsResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: GetPersonsTeamsResponse, metadata: grpc.Metadata) => void): Promise<GetPersonsTeamsResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<GetPersonsTeamsResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__PeopleManager.getTeams, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

}


@Injectable({providedIn: 'root'})
export class PeopleAdminManager {

  constructor(private _ngZone: NgZone) {
  }

  searchPeople(request: StreamID): {cancel():void;close():void}&Observable<StreamPeopleResponse>;
  searchPeople(request: StreamID, metadata: grpc.Metadata): {cancel():void;close():void}&Observable<StreamPeopleResponse>;
  searchPeople(request: StreamID, onMessage: (message?: StreamPeopleResponse) => void,onError?: (err: any) => void,onEnd?: (code: number, msg: string|undefined, metadata: grpc.Metadata) => void): void;
  searchPeople(request: StreamID, metadata: grpc.Metadata, onMessage: (message?: StreamPeopleResponse) => void,onError?: (err: any) => void,onEnd?: (code: number, msg: string|undefined, metadata: grpc.Metadata) => void): void;

  searchPeople(request: StreamID, arg1?: grpc.Metadata|((message?: StreamPeopleResponse) => void), arg2?: ((message?: StreamPeopleResponse) => void)|((err: any) => void), arg3?: ((err: any) => void)|((code: number, msg: string|undefined, metadata: grpc.Metadata) => void), arg4?: (code: number, msg: string|undefined, metadata: grpc.Metadata) => void): {cancel():void;close():void}&Observable<StreamPeopleResponse>|void {
    let ret: any;
    let metadata: any;

    let onMessage: any;
    let onError: any;
    let onEnd: any;
    if(typeof arg1 === 'function') {
      onMessage = arg1;
      onError = arg2;
      onEnd = arg3;
    } else if(typeof arg2 === 'function') {
      metadata = arg1;
      onMessage = arg2;
      onError = arg3;
      onEnd = arg4;
    } else {
      metadata = arg1;
    }

    if(!onMessage) {
      let subject = new Subject<StreamPeopleResponse>();
      ret = subject.asObservable();

      onMessage = (response: any) => {
        subject.next(response);
      };

      onError = (err: any) => {
        subject.error(err);
      };

      onEnd = (code: any, msg: any, metadata: any) => {
        subject.complete();
      };

    } else {
      if(!onError) {
        onError = (err: any) => console.error(err);
      }

      if(!onEnd) {
        onEnd = (code: any, msg: any, metadata: any) => {};
      }
    }

    let req = grpc.invoke(__PeopleAdminManager.SearchPeople, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onMessage: response => this._ngZone.run(() => {
        onMessage(response);
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code == grpc.Code.OK) {
          onEnd(code, msg, metadata);
        } else {
          let err = new Error(code + ' ' + (msg||''));
          (<any>err).code = code;
          onError(err);
        }
      })
    });

    (<any>ret).close = () => {
      console.warn('[Angular Grpc] .close() is deprecated use .cancel() instead');
      return req.close();
    };
    (<any>ret).cancel = () => req.close();
    return ret;
  }

  searchPeopleControl(request: StreamControl): Promise<StreamControlResponse>;
  searchPeopleControl(request: StreamControl, metadata: grpc.Metadata): Promise<StreamControlResponse>;
  searchPeopleControl(request: StreamControl, callback: (err: any|null, response: StreamControlResponse, metadata: grpc.Metadata) => void): void;
  searchPeopleControl(request: StreamControl, metadata: grpc.Metadata, callback: (err: any|null, response: StreamControlResponse, metadata: grpc.Metadata) => void): void;

  searchPeopleControl(request: StreamControl, arg1?: grpc.Metadata|((err: any|null, response: StreamControlResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: StreamControlResponse, metadata: grpc.Metadata) => void): Promise<StreamControlResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<StreamControlResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__PeopleAdminManager.SearchPeopleControl, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  deletePeopleBulk(request: DeletePeopleBulkRequest): {cancel():void;close():void}&Observable<DeletePeopleBulkResponse>;
  deletePeopleBulk(request: DeletePeopleBulkRequest, metadata: grpc.Metadata): {cancel():void;close():void}&Observable<DeletePeopleBulkResponse>;
  deletePeopleBulk(request: DeletePeopleBulkRequest, onMessage: (message?: DeletePeopleBulkResponse) => void,onError?: (err: any) => void,onEnd?: (code: number, msg: string|undefined, metadata: grpc.Metadata) => void): void;
  deletePeopleBulk(request: DeletePeopleBulkRequest, metadata: grpc.Metadata, onMessage: (message?: DeletePeopleBulkResponse) => void,onError?: (err: any) => void,onEnd?: (code: number, msg: string|undefined, metadata: grpc.Metadata) => void): void;

  deletePeopleBulk(request: DeletePeopleBulkRequest, arg1?: grpc.Metadata|((message?: DeletePeopleBulkResponse) => void), arg2?: ((message?: DeletePeopleBulkResponse) => void)|((err: any) => void), arg3?: ((err: any) => void)|((code: number, msg: string|undefined, metadata: grpc.Metadata) => void), arg4?: (code: number, msg: string|undefined, metadata: grpc.Metadata) => void): {cancel():void;close():void}&Observable<DeletePeopleBulkResponse>|void {
    let ret: any;
    let metadata: any;

    let onMessage: any;
    let onError: any;
    let onEnd: any;
    if(typeof arg1 === 'function') {
      onMessage = arg1;
      onError = arg2;
      onEnd = arg3;
    } else if(typeof arg2 === 'function') {
      metadata = arg1;
      onMessage = arg2;
      onError = arg3;
      onEnd = arg4;
    } else {
      metadata = arg1;
    }

    if(!onMessage) {
      let subject = new Subject<DeletePeopleBulkResponse>();
      ret = subject.asObservable();

      onMessage = (response: any) => {
        subject.next(response);
      };

      onError = (err: any) => {
        subject.error(err);
      };

      onEnd = (code: any, msg: any, metadata: any) => {
        subject.complete();
      };

    } else {
      if(!onError) {
        onError = (err: any) => console.error(err);
      }

      if(!onEnd) {
        onEnd = (code: any, msg: any, metadata: any) => {};
      }
    }

    let req = grpc.invoke(__PeopleAdminManager.DeletePeopleBulk, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onMessage: response => this._ngZone.run(() => {
        onMessage(response);
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code == grpc.Code.OK) {
          onEnd(code, msg, metadata);
        } else {
          let err = new Error(code + ' ' + (msg||''));
          (<any>err).code = code;
          onError(err);
        }
      })
    });

    (<any>ret).close = () => {
      console.warn('[Angular Grpc] .close() is deprecated use .cancel() instead');
      return req.close();
    };
    (<any>ret).cancel = () => req.close();
    return ret;
  }

  archivePeopleBulk(request: ArchivePeopleBulkRequest): {cancel():void;close():void}&Observable<ArchivePeopleBulkResponse>;
  archivePeopleBulk(request: ArchivePeopleBulkRequest, metadata: grpc.Metadata): {cancel():void;close():void}&Observable<ArchivePeopleBulkResponse>;
  archivePeopleBulk(request: ArchivePeopleBulkRequest, onMessage: (message?: ArchivePeopleBulkResponse) => void,onError?: (err: any) => void,onEnd?: (code: number, msg: string|undefined, metadata: grpc.Metadata) => void): void;
  archivePeopleBulk(request: ArchivePeopleBulkRequest, metadata: grpc.Metadata, onMessage: (message?: ArchivePeopleBulkResponse) => void,onError?: (err: any) => void,onEnd?: (code: number, msg: string|undefined, metadata: grpc.Metadata) => void): void;

  archivePeopleBulk(request: ArchivePeopleBulkRequest, arg1?: grpc.Metadata|((message?: ArchivePeopleBulkResponse) => void), arg2?: ((message?: ArchivePeopleBulkResponse) => void)|((err: any) => void), arg3?: ((err: any) => void)|((code: number, msg: string|undefined, metadata: grpc.Metadata) => void), arg4?: (code: number, msg: string|undefined, metadata: grpc.Metadata) => void): {cancel():void;close():void}&Observable<ArchivePeopleBulkResponse>|void {
    let ret: any;
    let metadata: any;

    let onMessage: any;
    let onError: any;
    let onEnd: any;
    if(typeof arg1 === 'function') {
      onMessage = arg1;
      onError = arg2;
      onEnd = arg3;
    } else if(typeof arg2 === 'function') {
      metadata = arg1;
      onMessage = arg2;
      onError = arg3;
      onEnd = arg4;
    } else {
      metadata = arg1;
    }

    if(!onMessage) {
      let subject = new Subject<ArchivePeopleBulkResponse>();
      ret = subject.asObservable();

      onMessage = (response: any) => {
        subject.next(response);
      };

      onError = (err: any) => {
        subject.error(err);
      };

      onEnd = (code: any, msg: any, metadata: any) => {
        subject.complete();
      };

    } else {
      if(!onError) {
        onError = (err: any) => console.error(err);
      }

      if(!onEnd) {
        onEnd = (code: any, msg: any, metadata: any) => {};
      }
    }

    let req = grpc.invoke(__PeopleAdminManager.ArchivePeopleBulk, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onMessage: response => this._ngZone.run(() => {
        onMessage(response);
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code == grpc.Code.OK) {
          onEnd(code, msg, metadata);
        } else {
          let err = new Error(code + ' ' + (msg||''));
          (<any>err).code = code;
          onError(err);
        }
      })
    });

    (<any>ret).close = () => {
      console.warn('[Angular Grpc] .close() is deprecated use .cancel() instead');
      return req.close();
    };
    (<any>ret).cancel = () => req.close();
    return ret;
  }

  syncPeopleBulk(request: SyncPeopleBulkRequest): {cancel():void;close():void}&Observable<SyncPeopleBulkResponse>;
  syncPeopleBulk(request: SyncPeopleBulkRequest, metadata: grpc.Metadata): {cancel():void;close():void}&Observable<SyncPeopleBulkResponse>;
  syncPeopleBulk(request: SyncPeopleBulkRequest, onMessage: (message?: SyncPeopleBulkResponse) => void,onError?: (err: any) => void,onEnd?: (code: number, msg: string|undefined, metadata: grpc.Metadata) => void): void;
  syncPeopleBulk(request: SyncPeopleBulkRequest, metadata: grpc.Metadata, onMessage: (message?: SyncPeopleBulkResponse) => void,onError?: (err: any) => void,onEnd?: (code: number, msg: string|undefined, metadata: grpc.Metadata) => void): void;

  syncPeopleBulk(request: SyncPeopleBulkRequest, arg1?: grpc.Metadata|((message?: SyncPeopleBulkResponse) => void), arg2?: ((message?: SyncPeopleBulkResponse) => void)|((err: any) => void), arg3?: ((err: any) => void)|((code: number, msg: string|undefined, metadata: grpc.Metadata) => void), arg4?: (code: number, msg: string|undefined, metadata: grpc.Metadata) => void): {cancel():void;close():void}&Observable<SyncPeopleBulkResponse>|void {
    let ret: any;
    let metadata: any;

    let onMessage: any;
    let onError: any;
    let onEnd: any;
    if(typeof arg1 === 'function') {
      onMessage = arg1;
      onError = arg2;
      onEnd = arg3;
    } else if(typeof arg2 === 'function') {
      metadata = arg1;
      onMessage = arg2;
      onError = arg3;
      onEnd = arg4;
    } else {
      metadata = arg1;
    }

    if(!onMessage) {
      let subject = new Subject<SyncPeopleBulkResponse>();
      ret = subject.asObservable();

      onMessage = (response: any) => {
        subject.next(response);
      };

      onError = (err: any) => {
        subject.error(err);
      };

      onEnd = (code: any, msg: any, metadata: any) => {
        subject.complete();
      };

    } else {
      if(!onError) {
        onError = (err: any) => console.error(err);
      }

      if(!onEnd) {
        onEnd = (code: any, msg: any, metadata: any) => {};
      }
    }

    let req = grpc.invoke(__PeopleAdminManager.SyncPeopleBulk, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onMessage: response => this._ngZone.run(() => {
        onMessage(response);
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code == grpc.Code.OK) {
          onEnd(code, msg, metadata);
        } else {
          let err = new Error(code + ' ' + (msg||''));
          (<any>err).code = code;
          onError(err);
        }
      })
    });

    (<any>ret).close = () => {
      console.warn('[Angular Grpc] .close() is deprecated use .cancel() instead');
      return req.close();
    };
    (<any>ret).cancel = () => req.close();
    return ret;
  }

  resendPinEmail(request: ResendPinEmailRequest): Promise<ResendPinEmailResponse>;
  resendPinEmail(request: ResendPinEmailRequest, metadata: grpc.Metadata): Promise<ResendPinEmailResponse>;
  resendPinEmail(request: ResendPinEmailRequest, callback: (err: any|null, response: ResendPinEmailResponse, metadata: grpc.Metadata) => void): void;
  resendPinEmail(request: ResendPinEmailRequest, metadata: grpc.Metadata, callback: (err: any|null, response: ResendPinEmailResponse, metadata: grpc.Metadata) => void): void;

  resendPinEmail(request: ResendPinEmailRequest, arg1?: grpc.Metadata|((err: any|null, response: ResendPinEmailResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: ResendPinEmailResponse, metadata: grpc.Metadata) => void): Promise<ResendPinEmailResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<ResendPinEmailResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__PeopleAdminManager.ResendPinEmail, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  resendAllInvites(request: ResendAllInvitesRequest): Promise<ResendAllInvitesResponse>;
  resendAllInvites(request: ResendAllInvitesRequest, metadata: grpc.Metadata): Promise<ResendAllInvitesResponse>;
  resendAllInvites(request: ResendAllInvitesRequest, callback: (err: any|null, response: ResendAllInvitesResponse, metadata: grpc.Metadata) => void): void;
  resendAllInvites(request: ResendAllInvitesRequest, metadata: grpc.Metadata, callback: (err: any|null, response: ResendAllInvitesResponse, metadata: grpc.Metadata) => void): void;

  resendAllInvites(request: ResendAllInvitesRequest, arg1?: grpc.Metadata|((err: any|null, response: ResendAllInvitesResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: ResendAllInvitesResponse, metadata: grpc.Metadata) => void): Promise<ResendAllInvitesResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<ResendAllInvitesResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__PeopleAdminManager.ResendAllInvites, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

}


@Injectable({providedIn: 'root'})
export class PersonValidation {

  constructor(private _ngZone: NgZone) {
  }

  emailUnique(request: EmailUniqueRequest): Promise<EmailUniqueResponse>;
  emailUnique(request: EmailUniqueRequest, metadata: grpc.Metadata): Promise<EmailUniqueResponse>;
  emailUnique(request: EmailUniqueRequest, callback: (err: any|null, response: EmailUniqueResponse, metadata: grpc.Metadata) => void): void;
  emailUnique(request: EmailUniqueRequest, metadata: grpc.Metadata, callback: (err: any|null, response: EmailUniqueResponse, metadata: grpc.Metadata) => void): void;

  emailUnique(request: EmailUniqueRequest, arg1?: grpc.Metadata|((err: any|null, response: EmailUniqueResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: EmailUniqueResponse, metadata: grpc.Metadata) => void): Promise<EmailUniqueResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<EmailUniqueResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__PersonValidation.emailUnique, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  pinUnique(request: PinUniqueRequest): Promise<PinUniqueResponse>;
  pinUnique(request: PinUniqueRequest, metadata: grpc.Metadata): Promise<PinUniqueResponse>;
  pinUnique(request: PinUniqueRequest, callback: (err: any|null, response: PinUniqueResponse, metadata: grpc.Metadata) => void): void;
  pinUnique(request: PinUniqueRequest, metadata: grpc.Metadata, callback: (err: any|null, response: PinUniqueResponse, metadata: grpc.Metadata) => void): void;

  pinUnique(request: PinUniqueRequest, arg1?: grpc.Metadata|((err: any|null, response: PinUniqueResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: PinUniqueResponse, metadata: grpc.Metadata) => void): Promise<PinUniqueResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<PinUniqueResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__PersonValidation.pinUnique, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

}


/**
  All RPC calls in MingaPeopleManager are relative to the minga you currently 
 have 'open'
  */
@Injectable({providedIn: 'root'})
export class MingaPeopleManager {

  constructor(private _ngZone: NgZone) {
  }

  getInfo(request: MingaPeopleInfoRequest): Promise<MingaPeopleInfo>;
  getInfo(request: MingaPeopleInfoRequest, metadata: grpc.Metadata): Promise<MingaPeopleInfo>;
  getInfo(request: MingaPeopleInfoRequest, callback: (err: any|null, response: MingaPeopleInfo, metadata: grpc.Metadata) => void): void;
  getInfo(request: MingaPeopleInfoRequest, metadata: grpc.Metadata, callback: (err: any|null, response: MingaPeopleInfo, metadata: grpc.Metadata) => void): void;

  getInfo(request: MingaPeopleInfoRequest, arg1?: grpc.Metadata|((err: any|null, response: MingaPeopleInfo, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: MingaPeopleInfo, metadata: grpc.Metadata) => void): Promise<MingaPeopleInfo>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<MingaPeopleInfo>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__MingaPeopleManager.GetInfo, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  exportPeople(request: ExportPeopleRequest): {cancel():void;close():void}&Observable<ExportPeopleChunk>;
  exportPeople(request: ExportPeopleRequest, metadata: grpc.Metadata): {cancel():void;close():void}&Observable<ExportPeopleChunk>;
  exportPeople(request: ExportPeopleRequest, onMessage: (message?: ExportPeopleChunk) => void,onError?: (err: any) => void,onEnd?: (code: number, msg: string|undefined, metadata: grpc.Metadata) => void): void;
  exportPeople(request: ExportPeopleRequest, metadata: grpc.Metadata, onMessage: (message?: ExportPeopleChunk) => void,onError?: (err: any) => void,onEnd?: (code: number, msg: string|undefined, metadata: grpc.Metadata) => void): void;

  exportPeople(request: ExportPeopleRequest, arg1?: grpc.Metadata|((message?: ExportPeopleChunk) => void), arg2?: ((message?: ExportPeopleChunk) => void)|((err: any) => void), arg3?: ((err: any) => void)|((code: number, msg: string|undefined, metadata: grpc.Metadata) => void), arg4?: (code: number, msg: string|undefined, metadata: grpc.Metadata) => void): {cancel():void;close():void}&Observable<ExportPeopleChunk>|void {
    let ret: any;
    let metadata: any;

    let onMessage: any;
    let onError: any;
    let onEnd: any;
    if(typeof arg1 === 'function') {
      onMessage = arg1;
      onError = arg2;
      onEnd = arg3;
    } else if(typeof arg2 === 'function') {
      metadata = arg1;
      onMessage = arg2;
      onError = arg3;
      onEnd = arg4;
    } else {
      metadata = arg1;
    }

    if(!onMessage) {
      let subject = new Subject<ExportPeopleChunk>();
      ret = subject.asObservable();

      onMessage = (response: any) => {
        subject.next(response);
      };

      onError = (err: any) => {
        subject.error(err);
      };

      onEnd = (code: any, msg: any, metadata: any) => {
        subject.complete();
      };

    } else {
      if(!onError) {
        onError = (err: any) => console.error(err);
      }

      if(!onEnd) {
        onEnd = (code: any, msg: any, metadata: any) => {};
      }
    }

    let req = grpc.invoke(__MingaPeopleManager.ExportPeople, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onMessage: response => this._ngZone.run(() => {
        onMessage(response);
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code == grpc.Code.OK) {
          onEnd(code, msg, metadata);
        } else {
          let err = new Error(code + ' ' + (msg||''));
          (<any>err).code = code;
          onError(err);
        }
      })
    });

    (<any>ret).close = () => {
      console.warn('[Angular Grpc] .close() is deprecated use .cancel() instead');
      return req.close();
    };
    (<any>ret).cancel = () => req.close();
    return ret;
  }

  searchPeople(request: StreamID): {cancel():void;close():void}&Observable<MingaPeopleSearchResult>;
  searchPeople(request: StreamID, metadata: grpc.Metadata): {cancel():void;close():void}&Observable<MingaPeopleSearchResult>;
  searchPeople(request: StreamID, onMessage: (message?: MingaPeopleSearchResult) => void,onError?: (err: any) => void,onEnd?: (code: number, msg: string|undefined, metadata: grpc.Metadata) => void): void;
  searchPeople(request: StreamID, metadata: grpc.Metadata, onMessage: (message?: MingaPeopleSearchResult) => void,onError?: (err: any) => void,onEnd?: (code: number, msg: string|undefined, metadata: grpc.Metadata) => void): void;

  searchPeople(request: StreamID, arg1?: grpc.Metadata|((message?: MingaPeopleSearchResult) => void), arg2?: ((message?: MingaPeopleSearchResult) => void)|((err: any) => void), arg3?: ((err: any) => void)|((code: number, msg: string|undefined, metadata: grpc.Metadata) => void), arg4?: (code: number, msg: string|undefined, metadata: grpc.Metadata) => void): {cancel():void;close():void}&Observable<MingaPeopleSearchResult>|void {
    let ret: any;
    let metadata: any;

    let onMessage: any;
    let onError: any;
    let onEnd: any;
    if(typeof arg1 === 'function') {
      onMessage = arg1;
      onError = arg2;
      onEnd = arg3;
    } else if(typeof arg2 === 'function') {
      metadata = arg1;
      onMessage = arg2;
      onError = arg3;
      onEnd = arg4;
    } else {
      metadata = arg1;
    }

    if(!onMessage) {
      let subject = new Subject<MingaPeopleSearchResult>();
      ret = subject.asObservable();

      onMessage = (response: any) => {
        subject.next(response);
      };

      onError = (err: any) => {
        subject.error(err);
      };

      onEnd = (code: any, msg: any, metadata: any) => {
        subject.complete();
      };

    } else {
      if(!onError) {
        onError = (err: any) => console.error(err);
      }

      if(!onEnd) {
        onEnd = (code: any, msg: any, metadata: any) => {};
      }
    }

    let req = grpc.invoke(__MingaPeopleManager.SearchPeople, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onMessage: response => this._ngZone.run(() => {
        onMessage(response);
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code == grpc.Code.OK) {
          onEnd(code, msg, metadata);
        } else {
          let err = new Error(code + ' ' + (msg||''));
          (<any>err).code = code;
          onError(err);
        }
      })
    });

    (<any>ret).close = () => {
      console.warn('[Angular Grpc] .close() is deprecated use .cancel() instead');
      return req.close();
    };
    (<any>ret).cancel = () => req.close();
    return ret;
  }

  searchPeopleControl(request: StreamControl): Promise<StreamControlResponse>;
  searchPeopleControl(request: StreamControl, metadata: grpc.Metadata): Promise<StreamControlResponse>;
  searchPeopleControl(request: StreamControl, callback: (err: any|null, response: StreamControlResponse, metadata: grpc.Metadata) => void): void;
  searchPeopleControl(request: StreamControl, metadata: grpc.Metadata, callback: (err: any|null, response: StreamControlResponse, metadata: grpc.Metadata) => void): void;

  searchPeopleControl(request: StreamControl, arg1?: grpc.Metadata|((err: any|null, response: StreamControlResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: StreamControlResponse, metadata: grpc.Metadata) => void): Promise<StreamControlResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<StreamControlResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__MingaPeopleManager.SearchPeopleControl, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  superAdminSearchPeople(request: StreamID): {cancel():void;close():void}&Observable<MingaPeopleSearchResult>;
  superAdminSearchPeople(request: StreamID, metadata: grpc.Metadata): {cancel():void;close():void}&Observable<MingaPeopleSearchResult>;
  superAdminSearchPeople(request: StreamID, onMessage: (message?: MingaPeopleSearchResult) => void,onError?: (err: any) => void,onEnd?: (code: number, msg: string|undefined, metadata: grpc.Metadata) => void): void;
  superAdminSearchPeople(request: StreamID, metadata: grpc.Metadata, onMessage: (message?: MingaPeopleSearchResult) => void,onError?: (err: any) => void,onEnd?: (code: number, msg: string|undefined, metadata: grpc.Metadata) => void): void;

  superAdminSearchPeople(request: StreamID, arg1?: grpc.Metadata|((message?: MingaPeopleSearchResult) => void), arg2?: ((message?: MingaPeopleSearchResult) => void)|((err: any) => void), arg3?: ((err: any) => void)|((code: number, msg: string|undefined, metadata: grpc.Metadata) => void), arg4?: (code: number, msg: string|undefined, metadata: grpc.Metadata) => void): {cancel():void;close():void}&Observable<MingaPeopleSearchResult>|void {
    let ret: any;
    let metadata: any;

    let onMessage: any;
    let onError: any;
    let onEnd: any;
    if(typeof arg1 === 'function') {
      onMessage = arg1;
      onError = arg2;
      onEnd = arg3;
    } else if(typeof arg2 === 'function') {
      metadata = arg1;
      onMessage = arg2;
      onError = arg3;
      onEnd = arg4;
    } else {
      metadata = arg1;
    }

    if(!onMessage) {
      let subject = new Subject<MingaPeopleSearchResult>();
      ret = subject.asObservable();

      onMessage = (response: any) => {
        subject.next(response);
      };

      onError = (err: any) => {
        subject.error(err);
      };

      onEnd = (code: any, msg: any, metadata: any) => {
        subject.complete();
      };

    } else {
      if(!onError) {
        onError = (err: any) => console.error(err);
      }

      if(!onEnd) {
        onEnd = (code: any, msg: any, metadata: any) => {};
      }
    }

    let req = grpc.invoke(__MingaPeopleManager.SuperAdminSearchPeople, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onMessage: response => this._ngZone.run(() => {
        onMessage(response);
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code == grpc.Code.OK) {
          onEnd(code, msg, metadata);
        } else {
          let err = new Error(code + ' ' + (msg||''));
          (<any>err).code = code;
          onError(err);
        }
      })
    });

    (<any>ret).close = () => {
      console.warn('[Angular Grpc] .close() is deprecated use .cancel() instead');
      return req.close();
    };
    (<any>ret).cancel = () => req.close();
    return ret;
  }

  superAdminSearchPeopleControl(request: StreamControl): Promise<StreamControlResponse>;
  superAdminSearchPeopleControl(request: StreamControl, metadata: grpc.Metadata): Promise<StreamControlResponse>;
  superAdminSearchPeopleControl(request: StreamControl, callback: (err: any|null, response: StreamControlResponse, metadata: grpc.Metadata) => void): void;
  superAdminSearchPeopleControl(request: StreamControl, metadata: grpc.Metadata, callback: (err: any|null, response: StreamControlResponse, metadata: grpc.Metadata) => void): void;

  superAdminSearchPeopleControl(request: StreamControl, arg1?: grpc.Metadata|((err: any|null, response: StreamControlResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: StreamControlResponse, metadata: grpc.Metadata) => void): Promise<StreamControlResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<StreamControlResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__MingaPeopleManager.SuperAdminSearchPeopleControl, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

}

