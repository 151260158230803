import { NgModule } from '@angular/core';

import { ArrayFindPipe } from './array-find.pipe';
import { ArrayIncludesPipe } from './array-includes.pipe';
import { ArrayJoinPipe } from './array-join.pipe';
import { ArrayMapPipe } from './array-map.pipe';
import { ArrayPluckPipe } from './array-pluck.pipe';
import { ArraySomePipe } from './array-some.pipe';

@NgModule({
  declarations: [
    ArrayIncludesPipe,
    ArraySomePipe,
    ArrayPluckPipe,
    ArrayFindPipe,
    ArrayJoinPipe,
    ArrayMapPipe,
  ],
  exports: [
    ArrayIncludesPipe,
    ArraySomePipe,
    ArrayPluckPipe,
    ArrayFindPipe,
    ArrayJoinPipe,
    ArrayMapPipe,
  ],
})
export class ArrayPipesModule {}
