// GENERATED CODE -- DO NOT EDIT!

import {grpc} from '@improbable-eng/grpc-web';

import * as automation_automation_actions_pb from '../automation/automation_actions_pb';
import * as google_protobuf_wrappers_pb from '../google/protobuf/wrappers_pb';
import * as google_protobuf_empty_pb from '../google/protobuf/empty_pb';

export class AutomationActions {
  static readonly serviceName = "automation.actions.v1.AutomationActions";

  static readonly ListAutomationActions = {
    methodName: "ListAutomationActions",
    service: AutomationActions,
    requestStream: false,
    responseStream: false,
    requestType: automation_automation_actions_pb.ListAutomationActionsRequest,
    responseType: automation_automation_actions_pb.ListAutomationActionsResponse,
  };

  static readonly GetAutomationAction = {
    methodName: "GetAutomationAction",
    service: AutomationActions,
    requestStream: false,
    responseStream: false,
    requestType: automation_automation_actions_pb.GetAutomationActionRequest,
    responseType: automation_automation_actions_pb.AutomationAction,
  };

  static readonly CreateAutomationAction = {
    methodName: "CreateAutomationAction",
    service: AutomationActions,
    requestStream: false,
    responseStream: false,
    requestType: automation_automation_actions_pb.CreateAutomationActionRequest,
    responseType: automation_automation_actions_pb.AutomationAction,
  };

  static readonly UpdateAutomationAction = {
    methodName: "UpdateAutomationAction",
    service: AutomationActions,
    requestStream: false,
    responseStream: false,
    requestType: automation_automation_actions_pb.UpdateAutomationActionRequest,
    responseType: automation_automation_actions_pb.AutomationAction,
  };

  static readonly DeleteAutomationAction = {
    methodName: "DeleteAutomationAction",
    service: AutomationActions,
    requestStream: false,
    responseStream: false,
    requestType: automation_automation_actions_pb.DeleteAutomationActionRequest,
    responseType: google_protobuf_empty_pb.Empty,
  };

}

export class AutomationActionsClient {
  listAutomationActions() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  getAutomationAction() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  createAutomationAction() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  updateAutomationAction() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  deleteAutomationAction() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
}
