import { FlexTimeActivityInstance } from '../';
import { IReportPerson } from '../';

export interface Registration {
  id: number;
  personId?: number;
  person?: IReportPerson;
  personHash?: string;
  registration?: FlexTimeActivityInstance | any;
  cannotUnregister?: boolean;
  type?: RegistrationTypes;
  date?: Date;
  assignerId?: number;
  assigner?: IReportPerson;
}

export enum RegistrationTypes {
  FLEX_TIME = 'flex',
}
