import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostListener,
  Injector,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChildren,
  ViewContainerRef,
} from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { Router } from '@angular/router';

import { grpc } from '@improbable-eng/grpc-web';
import {
  HallPassStatusEnum,
  IHallPassType,
  MingaPermission,
  MingaRoleType,
} from 'libs/domain';
import { hall_pass_pb } from 'libs/generated-grpc-web';
import { PbisCategory } from 'libs/shared';
import { mingaSettingTypes } from 'libs/util';
import uniqBy from 'lodash/uniqBy';
import {
  BehaviorSubject,
  Observable,
  ReplaySubject,
  Subject,
  combineLatest,
  from,
  interval,
  of,
  timer,
} from 'rxjs';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  repeatWhen,
  shareReplay,
  startWith,
  switchMap,
  take,
  takeUntil,
  tap,
} from 'rxjs/operators';

import { AuthInfoService } from '@app/src/app/minimal/services/AuthInfo';
import { PeopleFacadeService } from '@app/src/app/people';
import { PermissionsService } from '@app/src/app/permissions';
import { MingaSettingsService } from '@app/src/app/store/Minga/services';
import { scrollIntoView } from '@app/src/app/util/scroll-into-view';

import { HpmDashboardTableItem } from '@modules/hallpass-manager';
import { LayoutService } from '@modules/layout/services';
import { PeopleUserListsEditModalData } from '@modules/people/components/people-userlists';
import { PeopleUserListsEditComponent } from '@modules/people/components/people-userlists/component/people-userslists-edit/people-userlists-edit.component';
import { PeopleRoute } from '@modules/people/types';

import { ModalOverlayService } from '@shared/components/modal-overlay';
import { SystemAlertSnackBarService } from '@shared/components/system-alert-snackbar';
import { UserListCategory } from '@shared/components/user-list-filter';
import { UserListFilterService } from '@shared/components/user-list-filter/services/user-list-filter.service';
import { FlexTimeActivityInstanceService } from '@shared/services/flex-time';
import { HallPassActionsService } from '@shared/services/hall-pass/hallpass-actions.service';
import { MediaService } from '@shared/services/media';

import {
  ERROR_MESSAGES,
  FORM,
  FORM_FIELDS,
  LOCKED_STATES,
  MyClassMessages,
} from './constants/tt-my-class.constants';
import { MyClassActionsService } from './services/my-class-actions.service';
import { MyClassHallPassService } from './services/my-class-hallpasses.service';
import { MyClassLists } from './services/my-class-lists.service';
import { MyClassPreferencesService } from './services/my-class-preferences.service';
import {
  mapActionGroupsToItems,
  mapPersonToStudent,
} from './services/my-class.utils';
import {
  AssignmentType,
  FormState,
  HallPassFormData,
  Student,
} from './types/tt-my-class.types';

@Component({
  selector: 'mg-tt-my-class',
  templateUrl: './tt-my-class.component.html',
  styleUrls: ['./tt-my-class.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    MyClassPreferencesService,
    MyClassHallPassService,
    MyClassLists,
    FlexTimeActivityInstanceService,
  ],
})
export class TtMyClassComponent implements OnInit, OnDestroy {
  @ViewChildren('studentCard') studentCards!: QueryList<ElementRef>;

  private _formState = new BehaviorSubject<FormState>('idle');
  public formState$ = this._formState.asObservable();

  public LOCKED_STATES: FormState[] = LOCKED_STATES;

  private _formErrorsSubject = new BehaviorSubject<string[]>([]);
  public formErrors$ = this._formErrorsSubject.asObservable();

  private _randomizerSubject = new BehaviorSubject<string>(null);
  public randomizer$ = this._randomizerSubject.asObservable();

  private _destroyedSubject = new ReplaySubject<void>(1);

  private _fetchingStudentsSubject = new BehaviorSubject<boolean>(false);
  public fetchingStudents$ = this._fetchingStudentsSubject.asObservable();

  private _searchingAllStudents = new BehaviorSubject<boolean>(false);
  public searchingAllStudents$ = this._searchingAllStudents.asObservable();

  private _mobileProgress = new BehaviorSubject<'select' | 'assign'>('select');
  public mobileProgress$ = this._mobileProgress.asObservable();

  private _assignSuccessSubject = new Subject<void>();
  public assignSuccess$ = this._assignSuccessSubject.asObservable();

  public PEOPLE_ROUTE = PeopleRoute;

  public form = this._fb.group(FORM());
  public FORM_FIELDS = FORM_FIELDS;
  public ASSIGNMENT_TYPE = AssignmentType;

  public MESSAGES = MyClassMessages;

  public actionGroups$ = this._actionsService.actionGroups$;

  public pastAssignments$ = this._actionsService.pastAssignments$;

  public authHash$ = this._authInfo.authPersonHash$;

  public hallPassesByStudent$ = combineLatest([
    this._myClassHallPassService.hallPassesByStudent$,
    this._myClassHallPassService.fetchTypes(),
  ]).pipe(
    map(([hallPassesByStudent, types]) => {
      const hallpassesWithType = new Map<
        string,
        HpmDashboardTableItem & { type: IHallPassType }
      >();
      hallPassesByStudent.forEach((value, key) => {
        const type = types.find(t => t.id === value.typeId);

        hallpassesWithType.set(key, {
          ...value,
          type,
        });
      });

      return hallpassesWithType;
    }),
  );

  public listOptions$ = from(this._userListFilterService.getMyLists());

  public allowAssignShortcut = true;

  private _debouncedSearchText$ = this.form
    .get(FORM_FIELDS.SEARCH_TEXT_FILTER)
    .valueChanges.pipe(
      map((searchText: string) => searchText.trim()),
      debounceTime(300),
      startWith(''),
      shareReplay(1),
      distinctUntilChanged(),
    );

  private _isMobile: boolean;
  private _isDmEnabled = false;

  private _listChangeSideEffect: Record<UserListCategory, (listId) => void> = {
    [UserListCategory.ALL]: listId =>
      this._actionsService.fetchMostUsedByList(listId),
    [UserListCategory.ALL_CURRENT_TERM]: listId =>
      this._actionsService.fetchMostUsedByList(listId),
    [UserListCategory.MY_LISTS]: listId =>
      this._actionsService.fetchMostUsedByList(listId),
    [UserListCategory.MY_LISTS_CURRENT_TERM]: listId =>
      this._actionsService.fetchMostUsedByList(listId),
    // noop for flex activities atm
    [UserListCategory.TODAYS_FLEX_ACTIVITIES]: listId => null,
    [UserListCategory.TODAYS_FLEX_ACTIVITIES_MINE]: listId => null,
  };

  private _studentList$: Observable<Student[]> = this.form
    .get(FORM_FIELDS.LIST_FILTER)
    .valueChanges.pipe(
      debounceTime(100),
      // side effect to fetch the most used actions for the list
      tap(lists => {
        const category = lists?.[0]?.category?.value;
        if (this._listChangeSideEffect[category]) {
          this._listChangeSideEffect[category](lists[0].value);
        }
      }),
      tap(() => this._fetchingStudentsSubject.next(true)),
      switchMap(lists => this._myClassLists.fetchMembersForLists(lists)),
      // dedupe since users might be in multiple lists
      map(members => uniqBy(members, 'personHash')),
      catchError(error => {
        if (error.code === grpc.Code.NotFound) {
          //if it's data not found error then reset the list filter
          this.form.get(FORM_FIELDS.LIST_FILTER).setValue([]);
        } else {
          //if it's system error then throw generic error
          this._systemAlertSnackBar.error(MyClassMessages.FETCH_STUDENTS_ERROR);
        }
        return of([]);
      }),
      tap(members => {
        // we need to reset the selected students to only
        // users who are part of the selected lists, otherwise
        // the teacher could assign to students who aren't even
        // being displayed in the UI anymore
        const selectedStudents = this.form.get(
          FORM_FIELDS.SELECTED_STUDENTS,
        ).value;
        const updatedSelectedList = (selectedStudents || []).filter(hash =>
          members.some(m => m.personHash === hash),
        );
        this.form
          .get(FORM_FIELDS.SELECTED_STUDENTS)
          .setValue(updatedSelectedList);

        this._fetchingStudentsSubject.next(false);
      }),
      // makes sure we keep listening to the stream even if it errors
      repeatWhen(notifications => notifications),
      shareReplay(1),
    );

  public filteredStudentList$: Observable<Student[]> = combineLatest([
    this._studentList$,
    this._debouncedSearchText$,
  ]).pipe(
    map(([studentList, searchText]) => {
      const currentlySelected = this.form.get(
        FORM_FIELDS.SELECTED_STUDENTS,
      ).value;
      const filteredList = searchText
        ? studentList.filter(student => {
            // if a student is currently selected always show them
            if (currentlySelected.includes(student.personHash)) {
              return true;
            }

            const searchVal = searchText.toLowerCase();
            const includesFirstName = student.firstName
              .toLowerCase()
              .includes(searchVal);

            const includesLastName = student.lastName
              .toLowerCase()
              .includes(searchVal);

            const includesStudentId = student.studentId
              .toLowerCase()
              .includes(searchVal);

            return includesFirstName || includesLastName || includesStudentId;
          })
        : [...studentList];

      return filteredList;
    }),
  );

  public studentsWithHallpass$ = combineLatest([
    this.filteredStudentList$,
    this.hallPassesByStudent$,
  ]).pipe(
    map(([students, hallPasses]) => {
      return students.filter(student => {
        const activeOrPendingPass =
          hallPasses?.has(student.personHash) &&
          hallPasses?.get(student.personHash).status.state !==
            HallPassStatusEnum.ENDED;

        return activeOrPendingPass;
      });
    }),
  );

  public studentsWithoutHallpass$ = combineLatest([
    this.filteredStudentList$,
    this.hallPassesByStudent$,
  ]).pipe(
    map(([students, hallPasses]) => {
      return students.filter(student => {
        const recentlyEndedPasses =
          hallPasses?.has(student.personHash) &&
          hallPasses?.get(student.personHash).status.state ===
            HallPassStatusEnum.ENDED;

        return !hallPasses?.has(student.personHash) || recentlyEndedPasses;
      });
    }),
  );

  public studentsFromEntireSchool$: Observable<Student[]> = combineLatest([
    this._debouncedSearchText$,
    this._studentList$,
  ]).pipe(
    tap(() => this._searchingAllStudents.next(true)),
    switchMap(([searchText, inClassStudents]) => {
      if (!searchText) return of([]);
      return this._personFacade.searchPeople(searchText).pipe(
        map(people => {
          // filter out any students that are in the current class
          return people.filter(p => {
            const notInCurrentClass = !inClassStudents.some(
              s => s.personHash === p.hash,
            );
            const isStudentRole = [
              MingaRoleType.STUDENT,
              MingaRoleType.STUDENT_LEADER,
              MingaRoleType.READ_ONLY,
            ].includes(p.roleType as MingaRoleType);
            return notInCurrentClass && isStudentRole;
          });
        }),
      );
    }),
    map(people => {
      return people.map(mapPersonToStudent);
    }),
    tap(() => this._searchingAllStudents.next(false)),
  );

  public canViewProfile$ = combineLatest([
    this._permissions.observePermission(MingaPermission.MINGA_PEOPLE_MANAGE),
    this._settings.getSettingValueObs(mingaSettingTypes.TEACHERS_VIEW_PROFILE),
  ]).pipe(
    map(([canManagePeople, canViewProfile]) => {
      return canManagePeople || canViewProfile;
    }),
  );

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (this._isMobile) return; // only allow the assign shortcut on desktop

    if (event.code === 'Space') {
      // only assign if the shortcut is enabled (ie not in an input field)
      // use class 'block-assignment-input' to disable the assign shortcut for input fields that allow typing
      if (this.allowAssignShortcut) {
        event.preventDefault(); // stop spacebar from toggling the checkboxes
        this.assign();
      }
    }
  }

  // detect focus change and use class 'block-assignment-input' to disable the assign shortcut for input fields that allow typing
  @HostListener('document:focusin', ['$event.target'])
  onFocusChange(target: HTMLElement): void {
    this.allowAssignShortcut = !this._shouldDisableAssignShortcut(target);
  }

  // also need a listener for focusout to re-enable the assign shortcut
  @HostListener('document:focusout', ['$event.target'])
  onFocusOut(): void {
    this.allowAssignShortcut = true;
  }

  constructor(
    public mediaService: MediaService,
    public router: Router,
    private _actionsService: MyClassActionsService,
    private _fb: UntypedFormBuilder,
    private _systemAlertSnackBar: SystemAlertSnackBarService,
    private _preferencesService: MyClassPreferencesService,
    private _myClassHallPassService: MyClassHallPassService,
    private _systemSnackBar: SystemAlertSnackBarService,
    private _modalOverlay: ModalOverlayService,
    private _viewContainerRef: ViewContainerRef,
    private _injector: Injector,
    private _authInfo: AuthInfoService,
    private _hallPassActions: HallPassActionsService,
    private _settingService: MingaSettingsService,
    private _userListFilterService: UserListFilterService,
    private _layout: LayoutService,
    private _personFacade: PeopleFacadeService,
    private _myClassLists: MyClassLists,
    private _settings: MingaSettingsService,
    private _permissions: PermissionsService,
  ) {}

  ngOnInit(): void {
    this._formState.next('loading');

    this._settingService
      .isDmModuleEnabled()
      .subscribe(value => (this._isDmEnabled = value));

    this._actionsService.fetchMyActions(this._isDmEnabled);
    this._myClassHallPassService.initFetchPolling();
    this._getPreferences();
    this._onLayoutTypeChange();

    // auto close side nav on navigation to my class
    this._layout.collapsePrimaryNavigation();
  }

  ngOnDestroy(): void {
    this._destroyedSubject.next();
    this._destroyedSubject.complete();
  }

  public toggleSelectAll(students: Student[]) {
    const control = this.form.get(FORM_FIELDS.SELECTED_STUDENTS);
    const hashes =
      control.value.length === students.length
        ? []
        : students.map(s => s.personHash);
    control.setValue(hashes);
  }

  public selectRandom(students: Student[]) {
    this.form.get(FORM_FIELDS.SELECTED_STUDENTS).setValue([]);
    const DURATION_MS = 2500;
    const INTERVAL_MS = 250;
    const prefersReducedMotion =
      window.matchMedia(`(prefers-reduced-motion: reduce)`).matches === true;

    const hashes = students.map(s => s.personHash);
    const getRandomHash = () => {
      const index = Math.floor(Math.random() * hashes.length);
      return hashes[index];
    };

    const setRandomizer = () => {
      const hash = getRandomHash();
      this._randomizerSubject.next(hash);
    };

    const finishedRandomizing = () => {
      this._randomizerSubject.next(null);
      const hash = getRandomHash();
      this.form.get(FORM_FIELDS.SELECTED_STUDENTS).setValue([hash]);
      this._formState.next('idle');

      const selectedCard = this.studentCards
        .toArray()
        .find(el => el.nativeElement.getAttribute('data-hash') === hash);

      if (selectedCard) {
        scrollIntoView(selectedCard.nativeElement, {
          align: { top: 0, topOffset: this._isMobile ? 0 : 120 },
        });
      }
    };

    this._formState.next('randomizing');

    if (prefersReducedMotion) {
      return finishedRandomizing();
    }

    const stopPolling$ = new Subject();

    setRandomizer();

    interval(INTERVAL_MS)
      .pipe(takeUntil(stopPolling$))
      .subscribe(() => {
        setRandomizer();
      });

    timer(DURATION_MS)
      .pipe(takeUntil(stopPolling$))
      .subscribe(() => {
        stopPolling$.next();
        stopPolling$.complete();
        finishedRandomizing();
      });
  }

  public trackByFn(index: number, item: any) {
    return item.personHash || index;
  }

  public toggleSelected($event, student: Student) {
    const control = this.form.get(FORM_FIELDS.SELECTED_STUDENTS);

    if ($event) {
      control.setValue([...control.value, student.personHash]);
    } else {
      control.setValue(
        control.value.filter(hash => hash !== student.personHash),
      );
    }
  }

  public setMobileProgress(progress: 'select' | 'assign') {
    this._mobileProgress.next(progress);
  }

  public async assign() {
    const state = this._formState.value;
    if (state === 'loading' || state === 'submitting') return;

    this._formErrorsSubject.next([]);

    if (!this.form.valid) {
      this._setInvalidFields();
      this._formState.next('error');
      return;
    }

    this._formState.next('submitting');

    try {
      const formData = this.form.value;
      const response = await this._actionsService.assign(formData);
      this._actionsService.saveAssignment(formData);

      const type = formData.selectedAction.assignmentType;

      let successMessageType = '';

      if (type === AssignmentType.HALLPASS) {
        successMessageType = 'Hall pass';
        this._myClassHallPassService.addPasses(
          response as hall_pass_pb.HallPassWithType.AsObject[],
        );

        const subGroup = formData[AssignmentType.HALLPASS] as HallPassFormData;
        const approveBy = subGroup.approvedBy;

        if (approveBy) {
          const hallPassName = formData.selectedAction.data as IHallPassType;
          this._openPendingDialog(
            approveBy,
            hallPassName.name,
            response as hall_pass_pb.HallPassWithType.AsObject[],
          );
        }
      }

      if (type === AssignmentType.BEHAVIOR) {
        successMessageType =
          formData.selectedAction.data.categoryId === PbisCategory.PRAISE
            ? 'Praise'
            : 'Behavior';
      }

      if (type === AssignmentType.CONSEQUENCE) {
        successMessageType = 'Consequence';
      }

      this._resetAfterAssignment();

      this._assignSuccessSubject.next();

      if (type !== AssignmentType.COMMUNICATION) {
        this._systemSnackBar.open({
          type: 'success',
          message: `${successMessageType} assigned`,
        });
      }
    } catch (error) {
      // the underlying hall pass service handles it's own error messages so users dont need to be shown a generic error
      if (error.name === AssignmentType.HALLPASS) {
        return;
      }

      this._systemSnackBar.open({
        type: 'error',
        message: MyClassMessages.ASSIGNING_ACTION_ERROR,
      });
    } finally {
      this._formState.next('idle');
    }
  }

  public async createUserList() {
    const modalRef = this._modalOverlay.open<PeopleUserListsEditModalData>(
      PeopleUserListsEditComponent,
      {
        data: {
          id: null,
          injector: this._injector,
          sisList: false,
        },
        disposeOnNavigation: false,
      },
      this._viewContainerRef,
    );
    const response = await modalRef.afterClosed.toPromise();

    if (response.data.userList) {
      // lets make the list active
      const listControl = this.form.get(FORM_FIELDS.LIST_FILTER);
      listControl.setValue([response.data.userList.id]);
      listControl.updateValueAndValidity();
    }
  }

  public userListChanged(val: number[] | null) {
    const lists = val ? val : [];
    this.form.get(FORM_FIELDS.LIST_FILTER).setValue(lists);
  }

  public async onHallPassChange(event: {
    action: 'approve' | 'deny' | 'end';
    hallPass: HpmDashboardTableItem & { type: IHallPassType };
    opts?: {
      skipConfirmation?: boolean;
    };
  }) {
    const { action, hallPass, opts } = event;

    if (action === 'end') {
      return await this._myClassHallPassService.end(
        hallPass,
        hallPass.type?.name || 'Hall Pass',
        { skipConfirmation: opts?.skipConfirmation },
      );
    }

    if (opts?.skipConfirmation) {
      if (action === 'approve') {
        return await this._myClassHallPassService.approveHallPassRequest(
          hallPass.id,
        );
      }

      if (action === 'deny') {
        return await this._myClassHallPassService.denyHallPassRequest(
          hallPass.id,
        );
      }
    } else {
      if (action === 'approve' || action === 'deny') {
        return await this._myClassHallPassService.showPendingApprovalDialog(
          hallPass,
          hallPass.type,
        );
      }
    }
  }

  private _resetAfterAssignment() {
    this.form.get(FORM_FIELDS.SELECTED_STUDENTS).setValue([]);
    this.setMobileProgress('select');
  }

  private _setInvalidFields() {
    const invalidFields = [];
    const controls = this.form.controls;
    for (const fieldName in controls) {
      if (controls[fieldName].invalid) {
        const error = ERROR_MESSAGES[fieldName] || 'Required field missing';
        invalidFields.push(error);
      }
    }

    this._formErrorsSubject.next(invalidFields);
  }

  private _getPreferences() {
    combineLatest([
      this.actionGroups$,
      this._actionsService.actionsGroupsLoading$,
    ])
      .pipe(
        takeUntil(this._destroyedSubject),
        filter(([actionGroups, isLoading]) => !isLoading),
        take(1),
      )
      .subscribe(async ([actionGroups]) => {
        this._formState.next('idle');
        const savedPreferences = await this._preferencesService.get();
        this._listenForPreferenceChanges();
        const actions = mapActionGroupsToItems(actionGroups);
        this._preferencesService.apply({
          actions,
          preferences: savedPreferences,
          form: this.form,
        });
      });
  }

  private _listenForPreferenceChanges() {
    this.form.valueChanges
      .pipe(takeUntil(this._destroyedSubject), debounceTime(300))
      .subscribe(values => {
        this._preferencesService.save(values);
      });
  }

  private _onLayoutTypeChange() {
    this.mediaService.isMobileView$
      .pipe(takeUntil(this._destroyedSubject))
      .subscribe(isMobile => {
        this._isMobile = isMobile;
        this.setMobileProgress('select');
      });
  }

  private async _openPendingDialog(
    teacher: {
      name: string;
      hash: string;
    },
    hallPassName: string,
    passes: hall_pass_pb.HallPassWithType.AsObject[],
  ) {
    const timeoutMins = parseInt(
      await this._settingService.getSettingValue(
        mingaSettingTypes.PASS_APPROVAL_REQUEST_TIMEOUT_DURATION_STAFF,
      ),
      10,
    );

    const timeInSecs = timeoutMins * 60;

    const recipients = passes.map(pass => {
      const { hallPass } = pass;
      const passId = hallPass.id;
      return {
        name: `${hallPass.recipientPersonView.firstName} ${hallPass.recipientPersonView.lastName}`,
        passId,
      };
    });

    await this._hallPassActions.showPendingCancellationDialog(
      recipients,
      hallPassName,
      teacher.name,
      timeInSecs,
      {
        onCancel: passId => {
          this._myClassHallPassService.removePass(passId);
        },
      },
    );
  }

  // this method is used to check if the focused element should allow the assign shortcut
  // currently blocked manually by explicitly adding the class 'block-assignment-input' to the element
  // also blocked if the focused element is outside of <main> (currently this is only the minga change menu)
  // in summary: the assign shortcut is blocked if the focused element is outside of <main> or has the class 'block-assignment-input'
  private _shouldDisableAssignShortcut(focusedElement: HTMLElement) {
    // if you're focused on something outside of <main> then the shortcut is blocked
    const mainEl = document.querySelector('main');
    if (!mainEl.contains(focusedElement)) {
      return true;
    }

    // use closest here because the indicator class is not directly on the <input> element, it is on the parent component often
    if (focusedElement.closest('.block-assignment-input')) {
      return true;
    }

    return false;
  }
}
