import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { ICheckinReason } from 'libs/domain';

import { BarcodeScanner } from '@app/src/app/barcodeScanner';

import { CheckinTypes } from '@modules/checkin-manager/checkin-manager.constants';

import { SystemAlertSnackBarService } from '@shared/components/system-alert-snackbar';
import {
  CheckinResponseDetails,
  CheckinService,
} from '@shared/services/checkin';
import { CheckinFlowService } from '@shared/services/checkin/checkin-flow.service';

import { PsData } from '../../types';
import { PeopleSelectorService } from '../people-selector.service';
import { PsCollectionSearchImplService } from '../search-impl/ps-collection-search.impl.service';
import { PsCheckInAbstractService } from './ps-checkin-abstract.service';

@Injectable()
export class PsCheckInService extends PsCheckInAbstractService<'Check In'> {
  /** Service Constructor */
  constructor(
    public router: Router,
    public snackbar: SystemAlertSnackBarService,
    public barCodeScanner: BarcodeScanner,
    public peopleSelector: PeopleSelectorService,
    private _psCollectionSearch: PsCollectionSearchImplService,
    private _checkinService: CheckinService,
    private _checkinOverlayService: CheckinFlowService,
  ) {
    super(
      {
        name: 'Check In',
        pageDefinitions: {
          add: {
            submitFn: async => this.submitAdd(),
            searchFn: async =>
              this._psCollectionSearch.collectionSearch('text', undefined),
          },
        },
      },
      router,
      snackbar,
      barCodeScanner,
      peopleSelector,
    );
    this.setConfig({
      name: 'Check In',
      pageDefinitions: {
        add: {
          submitFn: async () => this.submitAdd(),
          searchFn: async (type, filters) =>
            this._psCollectionSearch.collectionSearch(type, filters),
        },
      },
    });
  }

  public async submitAdd(): Promise<void> {
    const reason = this._checkinOverlayService.getReason();
    const selectedPeople = this.selection.getSelection();
    await this._createCheckIn(reason, selectedPeople);
  }

  private async _createCheckIn(
    reason: ICheckinReason,
    selection: PsData[],
    bypassMultiCheck: boolean = false,
  ) {
    try {
      const selectionHashes = selection.map(({ personHash }) => personHash);
      const note = this.data?.note?.trim();
      const { errors, successPeopleHashes } = await this._makeRequest(
        reason,
        selectionHashes,
        bypassMultiCheck,
        note,
      );
      if (successPeopleHashes.length > 0 && errors.length === 0) {
        await this._showSuccessDialog({
          reasonName: reason.name,
          selectionHashes: successPeopleHashes,
          showProfilePicture: reason.enableStudentPhoto,
        });
      } else if (errors.length > 0) {
        const bypassPeople = await this._handleError({
          errors,
          reasonName: reason.name,
          successPeopleHashes: successPeopleHashes ?? [],
          showProfilePicture: reason.enableStudentPhoto,
        });

        if (bypassPeople.length) {
          await this._makeRequest(reason, bypassPeople, true, note);
          await this._showSuccessDialog({
            reasonName: reason.name,
            selectionHashes: bypassPeople,
            showProfilePicture: reason.enableStudentPhoto,
          });
        }
      }
    } catch (error) {
      this.snackbar.open({
        type: 'error',
        message: 'Something went wrong when trying to make the request',
      });
    }
  }

  private async _makeRequest(
    { id }: ICheckinReason,
    personHashes: string[],
    bypassMultiCheckin: boolean,
    note?: string,
  ) {
    const { type } = this.data;
    let response: CheckinResponseDetails;
    if (type === CheckinTypes.CHECKOUT) {
      response = await this._checkinService.createCheckout(
        personHashes,
        id,
        note,
      );
    } else {
      response = await this._checkinService.createCheckin(personHashes, id, {
        bypassMultiCheckin,
        note,
      });
    }
    return response;
  }
}
