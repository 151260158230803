// GENERATED CODE -- DO NOT EDIT!

import {grpc} from '@improbable-eng/grpc-web';

import * as minga_designer_minga_designer_pb from '../minga_designer/minga_designer_pb';
import * as google_protobuf_wrappers_pb from '../google/protobuf/wrappers_pb';
import * as designed_svg_designed_svg_pb from '../designed_svg/designed_svg_pb';

export class MingaDesigner {
  static readonly serviceName = "minga.MingaDesigner";

  static readonly CreateStockPhoto = {
    methodName: "CreateStockPhoto",
    service: MingaDesigner,
    requestStream: false,
    responseStream: false,
    requestType: minga_designer_minga_designer_pb.MingaDesignerCreateStockPhotoRequest,
    responseType: minga_designer_minga_designer_pb.MingaDesignerCreateStockPhotoResponse,
  };

  static readonly UpdateStockPhoto = {
    methodName: "UpdateStockPhoto",
    service: MingaDesigner,
    requestStream: false,
    responseStream: false,
    requestType: minga_designer_minga_designer_pb.MingaDesignerUpdateStockPhotoRequest,
    responseType: minga_designer_minga_designer_pb.MingaDesignerUpdateStockPhotoResponse,
  };

  static readonly DeleteStockPhoto = {
    methodName: "DeleteStockPhoto",
    service: MingaDesigner,
    requestStream: false,
    responseStream: false,
    requestType: minga_designer_minga_designer_pb.MingaDesignerDeleteStockPhotoRequest,
    responseType: minga_designer_minga_designer_pb.MingaDesignerDeleteThemeResponse,
  };

  static readonly CreateStockSticker = {
    methodName: "CreateStockSticker",
    service: MingaDesigner,
    requestStream: false,
    responseStream: false,
    requestType: minga_designer_minga_designer_pb.MingaDesignerCreateStockStickerRequest,
    responseType: minga_designer_minga_designer_pb.MingaDesignerCreateStockStickerResponse,
  };

  static readonly UpdateStockSticker = {
    methodName: "UpdateStockSticker",
    service: MingaDesigner,
    requestStream: false,
    responseStream: false,
    requestType: minga_designer_minga_designer_pb.MingaDesignerUpdateStockStickerRequest,
    responseType: minga_designer_minga_designer_pb.MingaDesignerUpdateStockStickerResponse,
  };

  static readonly DeleteStockSticker = {
    methodName: "DeleteStockSticker",
    service: MingaDesigner,
    requestStream: false,
    responseStream: false,
    requestType: minga_designer_minga_designer_pb.MingaDesignerDeleteStockStickerRequest,
    responseType: minga_designer_minga_designer_pb.MingaDesignerDeleteThemeResponse,
  };

  static readonly CreateTheme = {
    methodName: "CreateTheme",
    service: MingaDesigner,
    requestStream: false,
    responseStream: false,
    requestType: minga_designer_minga_designer_pb.MingaDesignerCreateThemeRequest,
    responseType: minga_designer_minga_designer_pb.MingaDesignerCreateThemeResponse,
  };

  static readonly UpdateTheme = {
    methodName: "UpdateTheme",
    service: MingaDesigner,
    requestStream: false,
    responseStream: false,
    requestType: minga_designer_minga_designer_pb.MingaDesignerUpdateThemeRequest,
    responseType: minga_designer_minga_designer_pb.MingaDesignerUpdateThemeResponse,
  };

  static readonly DeleteTheme = {
    methodName: "DeleteTheme",
    service: MingaDesigner,
    requestStream: false,
    responseStream: false,
    requestType: minga_designer_minga_designer_pb.MingaDesignerDeleteThemeRequest,
    responseType: minga_designer_minga_designer_pb.MingaDesignerDeleteThemeResponse,
  };

  static readonly CreateTemplate = {
    methodName: "CreateTemplate",
    service: MingaDesigner,
    requestStream: false,
    responseStream: false,
    requestType: minga_designer_minga_designer_pb.MingaDesignerCreateTemplateRequest,
    responseType: minga_designer_minga_designer_pb.MingaDesignerCreateTemplateResponse,
  };

  static readonly UpdateTemplate = {
    methodName: "UpdateTemplate",
    service: MingaDesigner,
    requestStream: false,
    responseStream: false,
    requestType: minga_designer_minga_designer_pb.MingaDesignerUpdateTemplateRequest,
    responseType: minga_designer_minga_designer_pb.MingaDesignerUpdateTemplateResponse,
  };

  static readonly DeleteTemplate = {
    methodName: "DeleteTemplate",
    service: MingaDesigner,
    requestStream: false,
    responseStream: false,
    requestType: minga_designer_minga_designer_pb.MingaDesignerDeleteTemplateRequest,
    responseType: minga_designer_minga_designer_pb.MingaDesignerDeleteTemplateResponse,
  };

  static readonly GetContentInfo = {
    methodName: "GetContentInfo",
    service: MingaDesigner,
    requestStream: false,
    responseStream: false,
    requestType: minga_designer_minga_designer_pb.MingaDesignerGetContentInfoRequest,
    responseType: minga_designer_minga_designer_pb.MingaDesignerGetContentInfoResponse,
  };

}

export class MingaDesignerClient {
  createStockPhoto() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  updateStockPhoto() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  deleteStockPhoto() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  createStockSticker() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  updateStockSticker() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  deleteStockSticker() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  createTheme() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  updateTheme() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  deleteTheme() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  createTemplate() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  updateTemplate() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  deleteTemplate() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  getContentInfo() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
}
