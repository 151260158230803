export enum DayOfWeekEnum {
  MON = 'MON',
  TUE = 'TUE',
  WED = 'WED',
  THU = 'THU',
  FRI = 'FRI',
  SAT = 'SAT',
  SUN = 'SUN',
}

export const getNumericalDayOfWeek = (dayOfWeek: DayOfWeekEnum): number => {
  const dayMap = {
    [DayOfWeekEnum.SUN]: 0,
    [DayOfWeekEnum.MON]: 1,
    [DayOfWeekEnum.TUE]: 2,
    [DayOfWeekEnum.WED]: 3,
    [DayOfWeekEnum.THU]: 4,
    [DayOfWeekEnum.FRI]: 5,
    [DayOfWeekEnum.SAT]: 6,
  };

  return dayMap[dayOfWeek];
};
