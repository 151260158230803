import { IEventTicket, IEventTicketGuest } from 'libs/domain';
import { event_pb } from 'libs/generated-grpc-ts';

import { dateObjectToDateTimeMessage, dateTimeMessageToDateObject } from '../';

export namespace IEventTicketProtoMapper {
  export function fromEventTicketMessage(
    message: event_pb.EventTicket,
  ): IEventTicket {
    const guests = message.getGuestsList();
    const guestList = guests.map(guest => {
      let checkin = guest.getCheckedIn();
      let checkout = guest.getCheckedOut();

      const item: IEventTicketGuest = {
        guestName: guest.getGuestName(),
        guestId: guest.getGuestId(),
      };

      if (checkin) {
        item.checkInTime = dateTimeMessageToDateObject(checkin);
      }
      if (checkout) {
        item.checkOutTime = dateTimeMessageToDateObject(checkout);
      }

      return item;
    });
    return {
      guests: guestList,
      personHash: message.getPersonHash(),
      studentNumber: message.getStudentNumber(),
      email: message.getEmail(),
    };
  }

  export function toEventTicketMessage(
    ticket: IEventTicket,
  ): event_pb.EventTicket {
    const msg = new event_pb.EventTicket();
    if (ticket.personHash) {
      msg.setPersonHash(ticket.personHash);
    }
    if (ticket.guests) {
      for (const guest of ticket.guests) {
        const guestMsg = new event_pb.EventGuest();
        guestMsg.setGuestName(guest.guestName);
        if (guest.guestId) {
          guestMsg.setGuestId(guest.guestId);
        }
        if (guest.checkInTime) {
          guestMsg.setCheckedIn(dateObjectToDateTimeMessage(guest.checkInTime));
        }
        if (guest.checkOutTime) {
          guestMsg.setCheckedOut(
            dateObjectToDateTimeMessage(guest.checkOutTime),
          );
        }
        msg.addGuests(guestMsg);
      }
    }

    return msg;
  }
}
