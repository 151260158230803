// GENERATED CODE -- DO NOT EDIT!

import {grpc} from '@improbable-eng/grpc-web';

import * as gateway_login_pb from '../gateway/login_pb';
import * as common_legacy_pb from '../common/legacy_pb';
import * as common_date_pb from '../common/date_pb';
import * as gateway_people_pb from '../gateway/people_pb';
import * as gateway_person_view_pb from '../gateway/person_view_pb';
import * as gateway_connect_pb from '../gateway/connect_pb';
import * as gateway_minga_pb from '../gateway/minga_pb';
import * as gateway_notification_pb from '../gateway/notification_pb';
import * as gateway_mingaGroup_pb from '../gateway/mingaGroup_pb';

export class LoginManager {
  static readonly serviceName = "LoginManager";

  static readonly Login = {
    methodName: "Login",
    service: LoginManager,
    requestStream: false,
    responseStream: false,
    requestType: gateway_login_pb.LoginRequest,
    responseType: gateway_login_pb.LoginResponse,
  };

  static readonly KioskLogin = {
    methodName: "KioskLogin",
    service: LoginManager,
    requestStream: false,
    responseStream: false,
    requestType: gateway_login_pb.KioskLoginRequest,
    responseType: gateway_login_pb.LoginResponse,
  };

  static readonly DeviceLogin = {
    methodName: "DeviceLogin",
    service: LoginManager,
    requestStream: false,
    responseStream: false,
    requestType: gateway_login_pb.DeviceLoginRequest,
    responseType: gateway_login_pb.LoginResponse,
  };

  static readonly Validate = {
    methodName: "Validate",
    service: LoginManager,
    requestStream: false,
    responseStream: false,
    requestType: gateway_login_pb.LoginValidationRequest,
    responseType: gateway_login_pb.LoginValidationResponse,
  };

  static readonly Email = {
    methodName: "Email",
    service: LoginManager,
    requestStream: false,
    responseStream: false,
    requestType: gateway_login_pb.EmailRequest,
    responseType: gateway_login_pb.EmailResponse,
  };

  static readonly Pin = {
    methodName: "Pin",
    service: LoginManager,
    requestStream: false,
    responseStream: false,
    requestType: gateway_login_pb.PinRequest,
    responseType: gateway_login_pb.PinResponse,
  };

  static readonly CreateAccount = {
    methodName: "CreateAccount",
    service: LoginManager,
    requestStream: false,
    responseStream: false,
    requestType: gateway_login_pb.CreateAccountRequest,
    responseType: gateway_login_pb.CreateAccountResponse,
  };

  static readonly Logout = {
    methodName: "Logout",
    service: LoginManager,
    requestStream: false,
    responseStream: false,
    requestType: gateway_login_pb.LogoutRequest,
    responseType: gateway_login_pb.LogoutResponse,
  };

  static readonly ForgotPassword = {
    methodName: "ForgotPassword",
    service: LoginManager,
    requestStream: false,
    responseStream: false,
    requestType: gateway_login_pb.ForgotPasswordRequest,
    responseType: gateway_login_pb.ForgotPasswordResponse,
  };

  static readonly ResetPassword = {
    methodName: "ResetPassword",
    service: LoginManager,
    requestStream: false,
    responseStream: false,
    requestType: gateway_login_pb.ResetPasswordRequest,
    responseType: gateway_login_pb.ResetPasswordResponse,
  };

  static readonly RetrievePin = {
    methodName: "RetrievePin",
    service: LoginManager,
    requestStream: false,
    responseStream: false,
    requestType: gateway_login_pb.RetrievePinRequest,
    responseType: gateway_login_pb.RetrievePinResponse,
  };

  static readonly GetKioskPin = {
    methodName: "GetKioskPin",
    service: LoginManager,
    requestStream: false,
    responseStream: false,
    requestType: gateway_login_pb.KioskPinRequest,
    responseType: gateway_login_pb.KioskPinResponse,
  };

  static readonly ResetKioskPin = {
    methodName: "ResetKioskPin",
    service: LoginManager,
    requestStream: false,
    responseStream: false,
    requestType: gateway_login_pb.KioskPinRequest,
    responseType: gateway_login_pb.KioskPinResponse,
  };

  static readonly ValidateKioskPin = {
    methodName: "ValidateKioskPin",
    service: LoginManager,
    requestStream: false,
    responseStream: false,
    requestType: gateway_login_pb.ValidateKioskPinRequest,
    responseType: gateway_login_pb.ValidateKioskPinResponse,
  };

  static readonly ForgotKioskPin = {
    methodName: "ForgotKioskPin",
    service: LoginManager,
    requestStream: false,
    responseStream: false,
    requestType: gateway_login_pb.ForgotKioskPinRequest,
    responseType: gateway_login_pb.ForgotKioskPinResponse,
  };

  static readonly OnStartup = {
    methodName: "OnStartup",
    service: LoginManager,
    requestStream: false,
    responseStream: false,
    requestType: gateway_login_pb.OnStartupRequest,
    responseType: gateway_login_pb.OnStartupResponse,
  };

}

export class LoginManagerClient {
  login() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  kioskLogin() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  deviceLogin() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  validate() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  email() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  pin() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  createAccount() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  logout() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  forgotPassword() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  resetPassword() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  retrievePin() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  getKioskPin() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  resetKioskPin() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  validateKioskPin() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  forgotKioskPin() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  onStartup() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
}
