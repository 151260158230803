import { NgModule } from '@angular/core';

import { MgOverlayToolsModule } from '@app/src/app/overlay/tools';

import { BroadcastNotificationDialog } from '../BroadcastNotification';
import { SendFeedbackDialog } from '../SendFeedback';
import { DialogViewComponent } from './DialogView.component';

@NgModule({
  imports: [MgOverlayToolsModule],
  declarations: [DialogViewComponent],
  exports: [DialogViewComponent],
})
export class DialogViewModule {}
