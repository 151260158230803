import { MingaPermission, MingaRoleType } from 'libs/domain';
import { DisplayNameFormat } from 'libs/util';

import { UserRole } from './UserRole';

export class StaffRole extends UserRole {
  readonly roleType = MingaRoleType.STAFF;
  readonly name = 'Staff';
  readonly displayNameFormat = DisplayNameFormat.F_LAST;
  readonly iconUrl = 'assets/roles/staff.svg';
  readonly iconColor = '#7F6ACB';
  readonly capabilitiesDescription = `Staff will be allowed to share posts, pictures, videos, events, and polls`;
  readonly feedPermittable = true;
  readonly galleryPermittable = true;
  readonly groupFeedPermittable = true;
  readonly programManagePermittable = false;
  readonly videoUploadPermittable = true;
  readonly commentPermittable = true;
  readonly groupCreatePermittable = true;
  readonly admin = true;
  readonly requiredFeatures = [];
  readonly sendToMailChimp = true;

  readonly permissions = [
    MingaPermission.ACCOUNT_DISPLAYNAME_CHANGE,
    MingaPermission.ACCOUNT_PROFILE_CHANGE,
    MingaPermission.ASSET_UPLOAD,
    MingaPermission.CONTENT_COMMENT_CREATE,
    MingaPermission.CONTENT_COMMENTS_VIEW,
    MingaPermission.CONTENT_EVENT_CREATE,
    MingaPermission.CONTENT_GROUP_ENABLED,
    MingaPermission.CONTENT_HTML_INCLUDE,
    MingaPermission.CONTENT_LIKES_LIKE,
    MingaPermission.CONTENT_MODERATION_OVERRIDE,
    MingaPermission.CONTENT_POST_CREATE,
    MingaPermission.CONTENT_POST_SET_TITLE,
    MingaPermission.CONTENT_PUBLISH_DATES_SET,
    MingaPermission.CONTENT_REPORT_CREATE,
    MingaPermission.CONTENT_VIDEO_CREATE,
    MingaPermission.GROUP_CONTENT_EVENTS_CREATE,
    MingaPermission.GROUP_CONTENT_POLL_CREATE,
    MingaPermission.GROUP_CONTENT_POST_CREATE,
    MingaPermission.GROUP_CONTENT_VIDEO_CREATE,
    MingaPermission.MINGA_CONTENT_PIN,
    MingaPermission.CONTENT_POLL_ANSWER,
    MingaPermission.CONTENT_CREATED_MINGA_DESIGNER_CONTENT,
    MingaPermission.GROUP_ALLOW_PARENT_GROUP,
    MingaPermission.EMAIL_CONTENT_AS_GROUP_OWNER,
    MingaPermission.GALLERY_DELETE_OTHERS_PHOTO,
    MingaPermission.CONTENT_MANAGE_COMMENT_SETTINGS,
    MingaPermission.CONTENT_CHALLENGE_CREATE,
    MingaPermission.GROUP_CONTENT_CHALLENGE_CREATE,
    MingaPermission.CHALLENGE_COMPLETE,
    MingaPermission.HALL_PASS_MANAGE,
    MingaPermission.TEACHER_TOOLS,
    MingaPermission.VIEW_STUDENT_ID_DASHBOARD,
    MingaPermission.USER_LIST_VIEW,
    MingaPermission.USER_LIST_CREATE,
    MingaPermission.SCHEDULED_REPORTS_DOWNLOAD_PAST_REPORT,
    MingaPermission.DM_CAN_MESSAGE,
    MingaPermission.SELF_CHECKIN,
    MingaPermission.DATE_RANGE_PRESETS_VIEW,
    MingaPermission.VIEW_PROFILE,
    MingaPermission.STUDENT_SCHEDULE_VIEW,
  ];
  readonly overridablePermissions = [];
}
