import { Registration } from 'libs/domain';
import { StudentSection, SectionType } from 'libs/domain';

export const flexToStudentSection = (r: Registration): StudentSection => {
  return {
    id: r.registration?.flexTimeActivity.id,
    name: r.registration?.flexTimeActivity.name,
    period: r.registration?.flexTimePeriod?.title,
    term: '',
    date: r.registration?.flexTimePeriod.date,
    startTime: r.registration?.flexTimePeriod.startTime,
    endTime: r.registration?.flexTimePeriod.endTime,
    teachers: [r.registration?.flexTimeActivity?.createdByPerson?.name],
    location: r.registration?.flexTimeActivity.location,
    type: SectionType.FLEX,
  };
};
