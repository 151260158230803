// GENERATED CODE -- DO NOT EDIT!

import {grpc} from '@improbable-eng/grpc-web';

import * as gateway_announcement_pb from '../gateway/announcement_pb';
import * as common_legacy_pb from '../common/legacy_pb';
import * as gateway_mention_pb from '../gateway/mention_pb';
import * as gateway_moderation_pb from '../gateway/moderation_pb';

export class AnnouncementManager {
  static readonly serviceName = "AnnouncementManager";

  static readonly Create = {
    methodName: "Create",
    service: AnnouncementManager,
    requestStream: false,
    responseStream: false,
    requestType: gateway_announcement_pb.CreateAnnouncementRequest,
    responseType: gateway_announcement_pb.CreateAnnouncementResponse,
  };

}

export class AnnouncementManagerClient {
  create() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
}
