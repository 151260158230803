import { IAutomationAction } from './AutomationActions.types';

export type AutomationTriggerByTypeKind = 'Pbis' | 'Checkin';

export type AutomationTriggerTypeKind = 'HallPass' | 'Pbis' | 'Consequence';

export interface IActionThresholdAutomation {
  id: number;
  mingaId: number;
  name: string;
  description: string;
  note?: string;
  active: boolean;
  steps?: IAutomationAction[];
  resetCounter?: boolean;
  userCreated?: boolean;
  triggers?: {
    pbisTriggers?: AutomationTrigger[];
    checkinReasonTriggers?: AutomationTrigger[];
  };
  autoReset: AutomationAutoResetValue[];
}

export type AutomationTrigger = {
  id: number;
  name?: string;
  iconType?: string;
  iconColor?: string;
};

export type AutomationPayload = Partial<IActionThresholdAutomation>;

export type ConsequenceAutomationPayload = {
  automation?: AutomationPayload;
  completeBy?: number;
};

export type AutomationAutoResetValue =
  | AutomationAutoResetCustom
  | AutomationAutoResetWeekly
  | AutomationAutoResetMonthly
  | AutomationAutoResetDaily;

export enum AutomationAutoResetFrequency {
  CUSTOM = 'custom',
  WEEKLY = 'weekly',
  MONTHLY = 'monthly',
  DAILY = 'daily',
}

export type AutomationAutoResetCustom = {
  frequency: AutomationAutoResetFrequency.CUSTOM;
  time: string;
  date: string;
};

export type AutomationAutoResetWeekly = {
  frequency: AutomationAutoResetFrequency.WEEKLY;
};

export type AutomationAutoResetMonthly = {
  frequency: AutomationAutoResetFrequency.MONTHLY;
};

export type AutomationAutoResetDaily = {
  frequency: AutomationAutoResetFrequency.DAILY;
};
