import { Injectable } from '@angular/core';

import * as day from 'dayjs';
import { HallPassStatusEnum, IHallPassType } from 'libs/domain';
import { RealtimeEventType } from 'libs/domain';
import { Subject } from 'rxjs';

import { AuthInfoService } from '@app/src/app/minimal/services/AuthInfo';
import { RootService } from '@app/src/app/minimal/services/RootService';
import {
  HallpassEvents,
  NormalizedMsg,
} from '@app/src/app/realtime-event/hallpass/HallpassEvents';
import { HallPassService } from '@app/src/app/services/HallPass';

import {
  HpmActionMessages,
  HpmDashboardTableItem,
} from '@modules/hallpass-manager';

import {
  SystemAlertCloseEvents,
  SystemAlertModalService,
  SystemAlertModalType,
} from '@shared/components/system-alert-modal';
import { SystemAlertSnackBarService } from '@shared/components/system-alert-snackbar';

@Injectable({ providedIn: 'root' })
export class HallPassActionsService {
  private _passCountdownChangeSubject = new Subject<{
    id: number;
    type: 'remove' | 'update';
    state?: HallPassStatusEnum;
  }>();
  public readonly passCountdownChange$ =
    this._passCountdownChangeSubject.asObservable();

  constructor(
    private _root: RootService,
    private _hpService: HallPassService,
    private _systemSnackBar: SystemAlertSnackBarService,
    private _systemAlertModal: SystemAlertModalService,
    private _hallPassRealTimeEvents: HallpassEvents,
    private _authInfo: AuthInfoService,
  ) {}

  public async endConfirm(
    pass: HpmDashboardTableItem,
    typeName: string,
    opts?: {
      skipConfirmation?: boolean;
    },
  ): Promise<'success' | void> {
    if (!opts?.skipConfirmation) {
      const currentDate = day();
      const expire = day(pass.status.expire);
      const expireSeconds = expire.diff(currentDate, 'second');
      const modalRef = await this._systemAlertModal.open({
        modalType: SystemAlertModalType.WARNING,
        heading: 'End hall pass?',
        detailedMessage: [
          ['Hall pass', typeName],
          [
            'Student',
            `${pass.recipientPersonView?.firstName} ${pass.recipientPersonView?.lastName}`,
          ],
        ],
        confirmActionBtn: 'End pass',
        closeBtn: 'Cancel',
        timer: expireSeconds,
      });

      const response = await modalRef.afterClosed().toPromise();

      if (response?.type !== SystemAlertCloseEvents.CONFIRM) return;
    }

    try {
      const result = await this._root.addLoadingPromise(
        this._hpService.expireHallPass(pass.id),
      );
      if (result) {
        this._systemSnackBar.open({
          type: 'success',
          message: HpmActionMessages.END_SUCCESS,
        });

        return 'success';
      }
    } catch (err) {
      this._systemSnackBar.open({
        type: 'error',
        message: HpmActionMessages.END_ERROR,
      });
    }
  }

  public async cancelConfirm(id: number): Promise<'success' | void> {
    const modalRef = await this._systemAlertModal.open({
      heading: HpmActionMessages.CANCEL_CONFIRM_TITLE,
      message: HpmActionMessages.CANCEL_CONFIRM_DESC,
      confirmActionBtn: HpmActionMessages.CANCEL_CONFIRM_BTN,
    });

    const response = await modalRef.afterClosed().toPromise();
    if (response?.type === SystemAlertCloseEvents.CONFIRM) {
      return await this._cancel(id);
    }
  }

  public onPassCountdownChange(data: {
    id: number;
    type: 'remove' | 'update';
    state?: HallPassStatusEnum;
  }) {
    this._passCountdownChangeSubject.next(data);
  }

  /**
   * Pending cancellation dialog for hall pass
   */
  public async showPendingCancellationDialog(
    recipients: Array<{
      name: string;
      passId: number;
    }>,
    typeName: string,
    teacherAssignedName: string,
    timer: number,
    callbacks?: {
      onCancel?: (id: number) => void;
      onClose?: () => void;
    },
  ): Promise<void> {
    const { onCancel, onClose } = callbacks || {};
    const showExpiredDialog = detailedMessage => {
      this._systemAlertModal.open({
        modalType: SystemAlertModalType.ERROR,
        heading: 'Your request for a hall pass has timed out',
        detailedMessage,
        closeBtn: 'Close',
      });
    };

    const requesterName = `${this._authInfo.authPerson.firstName} ${this._authInfo.authPerson.lastName}`;

    recipients.forEach(async recipient => {
      const { name, passId } = recipient;
      const detailedMessage: [string, string][] = [
        ['Hall pass', typeName],
        ['Requested by', requesterName],
        ['Approver', teacherAssignedName],
        ['Student', name],
      ];

      const hasExpired = timer <= 0;

      if (hasExpired) {
        return showExpiredDialog(detailedMessage);
      }

      const pendingDialogRef = await this._systemAlertModal.open({
        modalType: SystemAlertModalType.WARNING,
        heading: 'Hall pass request pending',
        detailedMessage,
        timer,
        closeBtn: 'Close',
        confirmActionBtn: 'Cancel Request',
        disableClose: true,
        onTimerEnd: () => {
          pendingDialogRef.close();
          showExpiredDialog(detailedMessage);
        },
      });

      pendingDialogRef.afterClosed().subscribe(async result => {
        if (result?.type === SystemAlertCloseEvents.CONFIRM) {
          await this._cancel(passId);
          if (onCancel) onCancel(passId);
        }

        if (
          result?.type === SystemAlertCloseEvents.CLOSE ||
          result?.type === SystemAlertCloseEvents.CLOSE_ICON
        ) {
          if (onClose) onClose();
        }
      });

      // we need to keep track of this so other hall pass real time events can dismiss this dialog, when pass is approved/denied
      this._hallPassRealTimeEvents.setDialogRef(
        {
          ref: pendingDialogRef,
          dismissOnNewNotification: true,
        },
        passId,
      );
    });
  }

  private async _cancel(id: number): Promise<'success' | void> {
    try {
      await this._root.addLoadingPromise(this._hpService.cancelHallPass(id));
      this._systemSnackBar.open({
        type: 'success',
        message: HpmActionMessages.CANCEL_SUCCESS,
      });

      return 'success';
    } catch (err) {
      this._systemSnackBar.open({
        type: 'error',
        message: `There was an error cancelling hall pass. ${err.message}`,
      });
    }
  }

  public async pendingApprovalDialogConfirm(
    pass: HpmDashboardTableItem,
    type: IHallPassType,
  ): Promise<SystemAlertCloseEvents> {
    const normalized: NormalizedMsg = {
      type: RealtimeEventType.HALL_PASS_APPROVAL_REQUEST,
      title: 'Hall pass request',
      hallPass: {
        id: pass.id,
        passName: type.name || 'Hall Pass',
        issuedTo: {
          hash: pass.recipientPersonView.personHash,
          name: `${pass.recipientPersonView.firstName} ${pass.recipientPersonView.lastName}`,
        },
        issuedBy: {
          hash: pass.authorPersonView.personHash,
          name: `${pass.authorPersonView.firstName} ${pass.authorPersonView.lastName}`,
        },
      },
    };

    if (pass.requestedByPersonView.personHash) {
      normalized.hallPass.requestedBy = {
        hash: pass.requestedByPersonView.personHash,
        name: `${pass.requestedByPersonView.firstName} ${pass.requestedByPersonView.lastName}`,
      };
    }

    return await this._hallPassRealTimeEvents.handleEvent(normalized);
  }
}
