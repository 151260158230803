// GENERATED CODE -- DO NOT EDIT!

import {grpc} from '@improbable-eng/grpc-web';

import * as healthcheck_healthcheck_pb from '../healthcheck/healthcheck_pb';

export class Health {
  static readonly serviceName = "grpc.health.v1.Health";

  static readonly Check = {
    methodName: "Check",
    service: Health,
    requestStream: false,
    responseStream: false,
    requestType: healthcheck_healthcheck_pb.HealthCheckRequest,
    responseType: healthcheck_healthcheck_pb.HealthCheckResponse,
  };

  static readonly Watch = {
    methodName: "Watch",
    service: Health,
    requestStream: false,
    responseStream: true,
    requestType: healthcheck_healthcheck_pb.HealthCheckRequest,
    responseType: healthcheck_healthcheck_pb.HealthCheckResponse,
  };

}

export class HealthClient {
  check() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  watch() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
}
