import { Injectable } from '@angular/core';

import { gateway, legacy_pb } from 'libs/generated-grpc-web';

import { AnalyticsService } from '@app/src/app/minimal/services/Analytics';
import { ContentEvents } from '@app/src/app/minimal/services/ContentEvents';
import { ReportService } from '@app/src/app/services/Report';
import { UserStorage } from '@app/src/app/services/UserStorage';

import { SystemAlertSnackBarService } from '@shared/components/system-alert-snackbar';

export interface ICommentDraft {
  text: string;
}

@Injectable({ providedIn: 'root' })
export class CommentInputService {
  private _memDrafts: { [key: string]: ICommentDraft };

  constructor(
    private userStorage: UserStorage,
    private commentManager: gateway.comment_ng_grpc_pb.CommentManager,
    private contentEvents: ContentEvents,
    private _systemAlertSnackBar: SystemAlertSnackBarService,
    private analytics: AnalyticsService,
    private reportService: ReportService,
  ) {
    this._memDrafts = {};
  }

  private _draftPrefix(contentContext: string) {
    return '~comment:' + contentContext;
  }

  async getDraft(contentContext: string) {
    if (!this._memDrafts[contentContext]) {
      let draft = await this.userStorage.getItem<ICommentDraft>(
        this._draftPrefix(contentContext),
      );

      if (!draft) {
        draft = { text: '' };
      }

      if (typeof draft === 'string') {
        draft = { text: draft };
      }

      this._memDrafts[contentContext] = draft;
    }

    return this._memDrafts[contentContext];
  }

  async saveToDraft(contentContext: string, draft: ICommentDraft) {
    this._memDrafts[contentContext] = draft;
    await this.userStorage.setItem(this._draftPrefix(contentContext), draft);
  }

  async clearDraft(contentContext: string) {
    delete this._memDrafts[contentContext];
    await this.userStorage.removeItem(this._draftPrefix(contentContext));
  }

  async submitDraft(contentContext: string, parentContext: string = '') {
    const addCommentRequest = new gateway.comment_pb.AddCommentRequest();
    let draft = await this.getDraft(contentContext);
    addCommentRequest.setText(draft.text);
    addCommentRequest.setContextHash(contentContext);

    const response = await this.commentManager
      .addComment(addCommentRequest)
      .catch(err => {
        console.error(err);
        let errResponse = new gateway.comment_pb.AddCommentResponse();
        errResponse.setStatus(legacy_pb.StatusCode.ERROR);

        this._systemAlertSnackBar.error(err);

        // @TODO: ADD receiver for this event
        this.contentEvents.emitCommentError({
          parentContentContextHash: contentContext,
          error: err,
        });

        this.analytics.sendCommentCreatedEvent({ success: false, error: err });

        return errResponse;
      });
    const status = response.getStatus();

    if (status == legacy_pb.StatusCode.OK) {
      await this.clearDraft(contentContext);
      const modResult = response.getModerationResult();
      const newCommentContextHash = response.getNewCommentContextHash();
      const passedModeration =
        await this.reportService.handleContentModerationResult(
          modResult,
          newCommentContextHash,
        );

      if (passedModeration) {
        this.analytics.sendCommentCreatedEvent({ success: true });
        this.contentEvents.emitCommentSuccess({
          parentContentContextHash: !!parentContext
            ? parentContext
            : contentContext,
          replyContentContextHash: contentContext,
          newCommentContextHash,
          newCommentBody: response.getNewCommentText(),
          newCommentPersonView: response.getNewCommentPersonView().toObject(),
          success: true,
        });
      } else {
        this.analytics.sendContentBlockedEvent({ type: 'comment' });
      }

      return true;
    } else {
      return false;
    }
  }
}
