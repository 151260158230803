import { Injectable, NgZone } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { grpc } from '@improbable-eng/grpc-web';

import {
  GetMingaRoleSettingsRequest,
  GetMingaRoleSettingsResponse,
  SetCommentPermittableRoleRequest,
  SetCommentPermittableRoleResponse,
  SetFeedPermittableRoleRequest,
  SetFeedPermittableRoleResponse,
  SetGalleryPermittableRoleRequest,
  SetGalleryPermittableRoleResponse,
  SetGroupCreatePermittableRoleRequest,
  SetGroupCreatePermittableRoleResponse,
  SetJoinViaCodeResponse,
  SetJoinViaCodeRoleRequest,
  SetVideoUploadPermittableRoleRequest,
  SetVideoUploadPermittableRoleResponse,
} from '../gateway/minga_roles_pb';

import {
  MingaRoles as __MingaRoles,
} from '../gateway/minga_roles_pb_service';

@Injectable({providedIn: 'root'})
export class MingaRoles {

  constructor(private _ngZone: NgZone) {
  }

  setFeedPermittableRole(request: SetFeedPermittableRoleRequest): Promise<SetFeedPermittableRoleResponse>;
  setFeedPermittableRole(request: SetFeedPermittableRoleRequest, metadata: grpc.Metadata): Promise<SetFeedPermittableRoleResponse>;
  setFeedPermittableRole(request: SetFeedPermittableRoleRequest, callback: (err: any|null, response: SetFeedPermittableRoleResponse, metadata: grpc.Metadata) => void): void;
  setFeedPermittableRole(request: SetFeedPermittableRoleRequest, metadata: grpc.Metadata, callback: (err: any|null, response: SetFeedPermittableRoleResponse, metadata: grpc.Metadata) => void): void;

  setFeedPermittableRole(request: SetFeedPermittableRoleRequest, arg1?: grpc.Metadata|((err: any|null, response: SetFeedPermittableRoleResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: SetFeedPermittableRoleResponse, metadata: grpc.Metadata) => void): Promise<SetFeedPermittableRoleResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<SetFeedPermittableRoleResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__MingaRoles.SetFeedPermittableRole, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  setJoinViaCodeRole(request: SetJoinViaCodeRoleRequest): Promise<SetJoinViaCodeResponse>;
  setJoinViaCodeRole(request: SetJoinViaCodeRoleRequest, metadata: grpc.Metadata): Promise<SetJoinViaCodeResponse>;
  setJoinViaCodeRole(request: SetJoinViaCodeRoleRequest, callback: (err: any|null, response: SetJoinViaCodeResponse, metadata: grpc.Metadata) => void): void;
  setJoinViaCodeRole(request: SetJoinViaCodeRoleRequest, metadata: grpc.Metadata, callback: (err: any|null, response: SetJoinViaCodeResponse, metadata: grpc.Metadata) => void): void;

  setJoinViaCodeRole(request: SetJoinViaCodeRoleRequest, arg1?: grpc.Metadata|((err: any|null, response: SetJoinViaCodeResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: SetJoinViaCodeResponse, metadata: grpc.Metadata) => void): Promise<SetJoinViaCodeResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<SetJoinViaCodeResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__MingaRoles.SetJoinViaCodeRole, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  setGalleryPermittableRole(request: SetGalleryPermittableRoleRequest): Promise<SetGalleryPermittableRoleResponse>;
  setGalleryPermittableRole(request: SetGalleryPermittableRoleRequest, metadata: grpc.Metadata): Promise<SetGalleryPermittableRoleResponse>;
  setGalleryPermittableRole(request: SetGalleryPermittableRoleRequest, callback: (err: any|null, response: SetGalleryPermittableRoleResponse, metadata: grpc.Metadata) => void): void;
  setGalleryPermittableRole(request: SetGalleryPermittableRoleRequest, metadata: grpc.Metadata, callback: (err: any|null, response: SetGalleryPermittableRoleResponse, metadata: grpc.Metadata) => void): void;

  setGalleryPermittableRole(request: SetGalleryPermittableRoleRequest, arg1?: grpc.Metadata|((err: any|null, response: SetGalleryPermittableRoleResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: SetGalleryPermittableRoleResponse, metadata: grpc.Metadata) => void): Promise<SetGalleryPermittableRoleResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<SetGalleryPermittableRoleResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__MingaRoles.SetGalleryPermittableRole, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  setVideoUploadPermittableRole(request: SetVideoUploadPermittableRoleRequest): Promise<SetVideoUploadPermittableRoleResponse>;
  setVideoUploadPermittableRole(request: SetVideoUploadPermittableRoleRequest, metadata: grpc.Metadata): Promise<SetVideoUploadPermittableRoleResponse>;
  setVideoUploadPermittableRole(request: SetVideoUploadPermittableRoleRequest, callback: (err: any|null, response: SetVideoUploadPermittableRoleResponse, metadata: grpc.Metadata) => void): void;
  setVideoUploadPermittableRole(request: SetVideoUploadPermittableRoleRequest, metadata: grpc.Metadata, callback: (err: any|null, response: SetVideoUploadPermittableRoleResponse, metadata: grpc.Metadata) => void): void;

  setVideoUploadPermittableRole(request: SetVideoUploadPermittableRoleRequest, arg1?: grpc.Metadata|((err: any|null, response: SetVideoUploadPermittableRoleResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: SetVideoUploadPermittableRoleResponse, metadata: grpc.Metadata) => void): Promise<SetVideoUploadPermittableRoleResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<SetVideoUploadPermittableRoleResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__MingaRoles.SetVideoUploadPermittableRole, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  setCommentPermittableRole(request: SetCommentPermittableRoleRequest): Promise<SetCommentPermittableRoleResponse>;
  setCommentPermittableRole(request: SetCommentPermittableRoleRequest, metadata: grpc.Metadata): Promise<SetCommentPermittableRoleResponse>;
  setCommentPermittableRole(request: SetCommentPermittableRoleRequest, callback: (err: any|null, response: SetCommentPermittableRoleResponse, metadata: grpc.Metadata) => void): void;
  setCommentPermittableRole(request: SetCommentPermittableRoleRequest, metadata: grpc.Metadata, callback: (err: any|null, response: SetCommentPermittableRoleResponse, metadata: grpc.Metadata) => void): void;

  setCommentPermittableRole(request: SetCommentPermittableRoleRequest, arg1?: grpc.Metadata|((err: any|null, response: SetCommentPermittableRoleResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: SetCommentPermittableRoleResponse, metadata: grpc.Metadata) => void): Promise<SetCommentPermittableRoleResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<SetCommentPermittableRoleResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__MingaRoles.SetCommentPermittableRole, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  setGroupCreatePermittableRole(request: SetGroupCreatePermittableRoleRequest): Promise<SetGroupCreatePermittableRoleResponse>;
  setGroupCreatePermittableRole(request: SetGroupCreatePermittableRoleRequest, metadata: grpc.Metadata): Promise<SetGroupCreatePermittableRoleResponse>;
  setGroupCreatePermittableRole(request: SetGroupCreatePermittableRoleRequest, callback: (err: any|null, response: SetGroupCreatePermittableRoleResponse, metadata: grpc.Metadata) => void): void;
  setGroupCreatePermittableRole(request: SetGroupCreatePermittableRoleRequest, metadata: grpc.Metadata, callback: (err: any|null, response: SetGroupCreatePermittableRoleResponse, metadata: grpc.Metadata) => void): void;

  setGroupCreatePermittableRole(request: SetGroupCreatePermittableRoleRequest, arg1?: grpc.Metadata|((err: any|null, response: SetGroupCreatePermittableRoleResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: SetGroupCreatePermittableRoleResponse, metadata: grpc.Metadata) => void): Promise<SetGroupCreatePermittableRoleResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<SetGroupCreatePermittableRoleResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__MingaRoles.SetGroupCreatePermittableRole, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  getMingaRoleSettings(request: GetMingaRoleSettingsRequest): Promise<GetMingaRoleSettingsResponse>;
  getMingaRoleSettings(request: GetMingaRoleSettingsRequest, metadata: grpc.Metadata): Promise<GetMingaRoleSettingsResponse>;
  getMingaRoleSettings(request: GetMingaRoleSettingsRequest, callback: (err: any|null, response: GetMingaRoleSettingsResponse, metadata: grpc.Metadata) => void): void;
  getMingaRoleSettings(request: GetMingaRoleSettingsRequest, metadata: grpc.Metadata, callback: (err: any|null, response: GetMingaRoleSettingsResponse, metadata: grpc.Metadata) => void): void;

  getMingaRoleSettings(request: GetMingaRoleSettingsRequest, arg1?: grpc.Metadata|((err: any|null, response: GetMingaRoleSettingsResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: GetMingaRoleSettingsResponse, metadata: grpc.Metadata) => void): Promise<GetMingaRoleSettingsResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<GetMingaRoleSettingsResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__MingaRoles.GetMingaRoleSettings, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

}

