import { Injectable, NgZone } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { grpc } from '@improbable-eng/grpc-web';

import {
  ReadProfileRequest,
  ReadProfileResponse,
  UpdateProfileBirthdatePublicRequest,
  UpdateProfileBirthdatePublicResponse,
  UpdateProfileBirthdateRequest,
  UpdateProfileBirthdateResponse,
  UpdateProfileCoverImageRequest,
  UpdateProfileCoverImageResponse,
  UpdateProfileImageRequest,
  UpdateProfileImageResponse,
  UpdateProfileRequest,
  UpdateProfileResponse,
} from '../gateway/profile_pb';

import {
  ProfileService as __ProfileService,
} from '../gateway/profile_pb_service';

@Injectable({providedIn: 'root'})
export class ProfileService {

  constructor(private _ngZone: NgZone) {
  }

  updateProfile(request: UpdateProfileRequest): Promise<UpdateProfileResponse>;
  updateProfile(request: UpdateProfileRequest, metadata: grpc.Metadata): Promise<UpdateProfileResponse>;
  updateProfile(request: UpdateProfileRequest, callback: (err: any|null, response: UpdateProfileResponse, metadata: grpc.Metadata) => void): void;
  updateProfile(request: UpdateProfileRequest, metadata: grpc.Metadata, callback: (err: any|null, response: UpdateProfileResponse, metadata: grpc.Metadata) => void): void;

  updateProfile(request: UpdateProfileRequest, arg1?: grpc.Metadata|((err: any|null, response: UpdateProfileResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: UpdateProfileResponse, metadata: grpc.Metadata) => void): Promise<UpdateProfileResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<UpdateProfileResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__ProfileService.updateProfile, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  updateBirthdate(request: UpdateProfileBirthdateRequest): Promise<UpdateProfileBirthdateResponse>;
  updateBirthdate(request: UpdateProfileBirthdateRequest, metadata: grpc.Metadata): Promise<UpdateProfileBirthdateResponse>;
  updateBirthdate(request: UpdateProfileBirthdateRequest, callback: (err: any|null, response: UpdateProfileBirthdateResponse, metadata: grpc.Metadata) => void): void;
  updateBirthdate(request: UpdateProfileBirthdateRequest, metadata: grpc.Metadata, callback: (err: any|null, response: UpdateProfileBirthdateResponse, metadata: grpc.Metadata) => void): void;

  updateBirthdate(request: UpdateProfileBirthdateRequest, arg1?: grpc.Metadata|((err: any|null, response: UpdateProfileBirthdateResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: UpdateProfileBirthdateResponse, metadata: grpc.Metadata) => void): Promise<UpdateProfileBirthdateResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<UpdateProfileBirthdateResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__ProfileService.updateBirthdate, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  updateBirthdatePublic(request: UpdateProfileBirthdatePublicRequest): Promise<UpdateProfileBirthdatePublicResponse>;
  updateBirthdatePublic(request: UpdateProfileBirthdatePublicRequest, metadata: grpc.Metadata): Promise<UpdateProfileBirthdatePublicResponse>;
  updateBirthdatePublic(request: UpdateProfileBirthdatePublicRequest, callback: (err: any|null, response: UpdateProfileBirthdatePublicResponse, metadata: grpc.Metadata) => void): void;
  updateBirthdatePublic(request: UpdateProfileBirthdatePublicRequest, metadata: grpc.Metadata, callback: (err: any|null, response: UpdateProfileBirthdatePublicResponse, metadata: grpc.Metadata) => void): void;

  updateBirthdatePublic(request: UpdateProfileBirthdatePublicRequest, arg1?: grpc.Metadata|((err: any|null, response: UpdateProfileBirthdatePublicResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: UpdateProfileBirthdatePublicResponse, metadata: grpc.Metadata) => void): Promise<UpdateProfileBirthdatePublicResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<UpdateProfileBirthdatePublicResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__ProfileService.updateBirthdatePublic, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  updateProfileImage(request: UpdateProfileImageRequest): Promise<UpdateProfileImageResponse>;
  updateProfileImage(request: UpdateProfileImageRequest, metadata: grpc.Metadata): Promise<UpdateProfileImageResponse>;
  updateProfileImage(request: UpdateProfileImageRequest, callback: (err: any|null, response: UpdateProfileImageResponse, metadata: grpc.Metadata) => void): void;
  updateProfileImage(request: UpdateProfileImageRequest, metadata: grpc.Metadata, callback: (err: any|null, response: UpdateProfileImageResponse, metadata: grpc.Metadata) => void): void;

  updateProfileImage(request: UpdateProfileImageRequest, arg1?: grpc.Metadata|((err: any|null, response: UpdateProfileImageResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: UpdateProfileImageResponse, metadata: grpc.Metadata) => void): Promise<UpdateProfileImageResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<UpdateProfileImageResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__ProfileService.updateProfileImage, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  updateProfileCoverImage(request: UpdateProfileCoverImageRequest): Promise<UpdateProfileCoverImageResponse>;
  updateProfileCoverImage(request: UpdateProfileCoverImageRequest, metadata: grpc.Metadata): Promise<UpdateProfileCoverImageResponse>;
  updateProfileCoverImage(request: UpdateProfileCoverImageRequest, callback: (err: any|null, response: UpdateProfileCoverImageResponse, metadata: grpc.Metadata) => void): void;
  updateProfileCoverImage(request: UpdateProfileCoverImageRequest, metadata: grpc.Metadata, callback: (err: any|null, response: UpdateProfileCoverImageResponse, metadata: grpc.Metadata) => void): void;

  updateProfileCoverImage(request: UpdateProfileCoverImageRequest, arg1?: grpc.Metadata|((err: any|null, response: UpdateProfileCoverImageResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: UpdateProfileCoverImageResponse, metadata: grpc.Metadata) => void): Promise<UpdateProfileCoverImageResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<UpdateProfileCoverImageResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__ProfileService.updateProfileCoverImage, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  readProfile(request: ReadProfileRequest): Promise<ReadProfileResponse>;
  readProfile(request: ReadProfileRequest, metadata: grpc.Metadata): Promise<ReadProfileResponse>;
  readProfile(request: ReadProfileRequest, callback: (err: any|null, response: ReadProfileResponse, metadata: grpc.Metadata) => void): void;
  readProfile(request: ReadProfileRequest, metadata: grpc.Metadata, callback: (err: any|null, response: ReadProfileResponse, metadata: grpc.Metadata) => void): void;

  readProfile(request: ReadProfileRequest, arg1?: grpc.Metadata|((err: any|null, response: ReadProfileResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: ReadProfileResponse, metadata: grpc.Metadata) => void): Promise<ReadProfileResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<ReadProfileResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__ProfileService.readProfile, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

}

