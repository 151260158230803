// GENERATED CODE -- DO NOT EDIT!

import {grpc} from '@improbable-eng/grpc-web';

import * as gateway_scraper_pb from '../gateway/scraper_pb';

export class MetadataScraper {
  static readonly serviceName = "minga.MetadataScraper";

  static readonly Get = {
    methodName: "Get",
    service: MetadataScraper,
    requestStream: false,
    responseStream: false,
    requestType: gateway_scraper_pb.MetadataScraperGetRequest,
    responseType: gateway_scraper_pb.MetadataScraperGetResponse,
  };

}

export class MetadataScraperClient {
  get() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
}
