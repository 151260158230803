import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
} from '@angular/router';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { MingaSettingsService } from '@app/src/app/store/Minga/services';

import { FlexTimePermissionsService } from '@shared/services/flex-time/flex-time-permissions';

@Injectable()
export class FlexSelfManageOrHigher implements CanActivate {
  constructor(
    private _settingsService: MingaSettingsService,
    private _flexPermissions: FlexTimePermissionsService,
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> {
    return this._settingsService.isFlexTimeModuleEnabled().pipe(
      map(isFlexTimeModuleEnabled => {
        return (
          isFlexTimeModuleEnabled &&
          this._flexPermissions.isFlexTimeSelfManagerOrHigher()
        );
      }),
    );
  }
}
