import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';

import { MgButtonModule } from '@app/src/app/button';
import { MgTextareaModule } from '@app/src/app/components/Textarea';
import { MgIconModule } from '@app/src/app/icon';
import { MentionsModule } from '@app/src/app/mentions';

import { ChallengeResponseApprovalControlsComponent } from './ChallengeResponseApprovalControls.component';

@NgModule({
  imports: [
    MgButtonModule,
    MgIconModule,
    MgTextareaModule,
    MentionsModule,

    // External dependencies
    CommonModule,
    MatIconModule,
    MatInputModule,
    MatFormFieldModule,
    FormsModule,
    FlexLayoutModule,
  ],
  declarations: [ChallengeResponseApprovalControlsComponent],
  exports: [ChallengeResponseApprovalControlsComponent],
})
export class ChallengeResponseApprovalControlsModule {}
