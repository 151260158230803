import {Observable} from 'rxjs';

export interface IRealtimeMessagePayload {
  // string value here should be a base64 representation of a protobuf message
  [key: string]: string|undefined;
}

export abstract class RealtimeDataConsumer {
  abstract readonly messageNotice$: Observable<IRealtimeMessagePayload>;
}

export abstract class RealtimeDataSender {
  abstract send(message: IRealtimeMessagePayload): Promise<void>;
}
