import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { FitGridModule } from '@app/src/app/components/FitGrid';
import { MgImageModule } from '@app/src/app/components/MgImage';
import { MgPipesModule } from '@app/src/app/pipes';

import {
  PhotoGalleryPreviewComponent,
  PhotoGalleryPreviewSlot_Empty,
  PhotoGalleryPreviewSlot_Loading,
} from './PhotoGalleryPreview.component';

@NgModule({
  imports: [
    // Minga dependencies
    FitGridModule,
    MgImageModule,
    MgPipesModule,

    // External dependencies
    CommonModule,
  ],
  declarations: [
    PhotoGalleryPreviewComponent,
    PhotoGalleryPreviewSlot_Empty,
    PhotoGalleryPreviewSlot_Loading,
  ],
  exports: [
    PhotoGalleryPreviewComponent,
    PhotoGalleryPreviewSlot_Empty,
    PhotoGalleryPreviewSlot_Loading,
  ],
})
export class PhotoGalleryPreviewModule {}
