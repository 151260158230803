import { IMingaSetting } from 'libs/domain';
import { MingaRoleType } from 'libs/domain';
import { mingaSettingTypes, MingaTypeValue } from 'libs/util';

import { getRole } from '../../';
import { MingaType } from './MingaType';

export class MingaConferenceMingaType extends MingaType {
  readonly mingaType = MingaTypeValue.CONFERENCE;

  readonly availableRoles = {
    [MingaRoleType.SUPERADMIN]: { weight: 99 },
    [MingaRoleType.OWNER]: { weight: 4 },
    [MingaRoleType.MANAGER]: { weight: 3 },
    [MingaRoleType.ATTENDEE]: { weight: 2 },
    [MingaRoleType.READ_ONLY]: { weight: 1 },
  };

  readonly defaultSettings: IMingaSetting[] = [
    {
      name: mingaSettingTypes.FEATURE_ENABLE_GROUPS,
      value: true,
    },
  ];

  readonly canSelfManageAccountPlan: boolean = false;

  readonly defaultVideoUploadRoleTypes: MingaRoleType[] = [
    MingaRoleType.OWNER,
    MingaRoleType.MANAGER,
  ];

  readonly directMessagingRoleSettings = [];

  getBillableRoles(isPricedPerDefaultRole: boolean = true) {
    return [
      MingaRoleType.OWNER,
      MingaRoleType.MANAGER,
      MingaRoleType.ATTENDEE,
    ].map(getRole);
  }

  getDefaultRole() {
    return getRole(MingaRoleType.ATTENDEE);
  }

  getCreatorRole() {
    return getRole(MingaRoleType.OWNER);
  }
}
