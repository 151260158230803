import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { MingaRoleType } from 'libs/domain';
import { badgeRoleNameToMingaRoleType } from 'libs/shared';
import { BehaviorSubject, ReplaySubject, Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { AnalyticsService } from '@app/src/app/minimal/services/Analytics';
import { AuthService } from '@app/src/app/minimal/services/Auth';
import { AuthInfoService } from '@app/src/app/minimal/services/AuthInfo';
import { RootService } from '@app/src/app/minimal/services/RootService';

import {
  LOGIN_FORM_FIELDS,
  LOGIN_MESSAGES,
  LandingRoute,
} from '@modules/landing/constants';

import { SystemAlertSnackBarService } from '@shared/components/system-alert-snackbar';

import { LandingService } from '../../services';

@Component({
  selector: 'mg-landing-email',
  templateUrl: './landing-email.component.html',
  styleUrls: ['./landing-email.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LandingEmailComponent implements OnInit, OnDestroy {
  // Children

  public LANDING_ROUTE = LandingRoute;

  public form = this._fb.group({
    [LOGIN_FORM_FIELDS.EMAIL]: ['', [Validators.required]],
    [LOGIN_FORM_FIELDS.PASSWORD]: ['', [Validators.required]],
  });

  private readonly _destroyedSubject = new ReplaySubject<void>(1);
  private _destroyed = false;
  private _errorMessageSubject = new BehaviorSubject<string>('');

  public errorMessage$ = this._errorMessageSubject.asObservable();
  public newSignIn = false;
  public detachedSub: Subscription;
  public person$ = this._authInfo.authPerson$.pipe(
    filter(person => {
      // we dont want to show kiosk sessions here since it will just confuse users
      const roleType = badgeRoleNameToMingaRoleType(person?.badgeRoleName);
      return roleType !== MingaRoleType.KIOSK;
    }),
  );
  public FORM_FIELDS = LOGIN_FORM_FIELDS;
  public MESSAGES = LOGIN_MESSAGES;
  public profileImageUrl$ = this.person$.pipe(
    map(person => (person ? person.avatarUrl || '' : '')),
  );

  get country$() {
    return this.landing.country$;
  }

  constructor(
    public landing: LandingService,
    private _rootService: RootService,
    private _router: Router,
    private _authService: AuthService,
    private _analytics: AnalyticsService,
    private _authInfo: AuthInfoService,
    private _systemAlertSnackBar: SystemAlertSnackBarService,
    private _fb: UntypedFormBuilder,
  ) {}

  ngOnInit(): void {
    this.getMobileCredentials();
    this.detachedSub = this._authInfo.detachedSsoProviderInfo$.subscribe(
      info => {
        if (info) {
          this._authService.discardCurrentUser();
          this._systemAlertSnackBar.error(
            `Uh oh! The email, ${info.email}, doesn't seem to belong to a Minga.`,
          );
        }
      },
    );
  }

  ngOnDestroy(): void {
    this._destroyedSubject.next();
    this._destroyedSubject.complete();
    this._destroyed = true;
    if (this.detachedSub) this.detachedSub?.unsubscribe();
  }

  public async getMobileCredentials() {
    await this._authService.getLoginCredentials().then(
      credentials => {
        if (credentials) {
          this.landing.email = credentials.email;
          this.form.get(LOGIN_FORM_FIELDS.EMAIL).setValue(credentials.email);
          this.form
            .get(LOGIN_FORM_FIELDS.PASSWORD)
            .setValue(credentials.password);
        }
      },
      err => {
        console.error(`[LandingEmail] unable to get mobile credentials: `, err);
      },
    );
  }

  public async onSubmit() {
    await this._rootService.addLoadingPromise(this._submit());
  }

  public signout() {
    this._authService.logout();
  }

  public gotoMain() {
    this._router.navigateByUrl('/');
  }

  public gotoCountrySelector(e: MouseEvent) {
    e.preventDefault();
    e.stopImmediatePropagation();
    e.stopPropagation();
    this._router.navigate(['/landing/country'], {
      queryParams: {
        redirect: btoa('/landing/email'),
      },
    });
  }

  public resetErrorState() {
    this._errorMessageSubject.next('');
  }

  private async _submit() {
    if (this.form && this.form.invalid) {
      return;
    }

    this.resetErrorState();

    // reset errors
    let hasNetworkError = false;

    // if submitting, we don't want to show person$ data
    this.newSignIn = true;

    const email = this.form.get(LOGIN_FORM_FIELDS.EMAIL).value;
    const password = this.form.get(LOGIN_FORM_FIELDS.PASSWORD).value;

    const response = await this._authService
      .login(email, password)
      .catch(err => {
        console.error(`[LandingEmail] login error occurred: `, err);
        this._analytics.logEvent('LandingEmail', { error: err });
        hasNetworkError = true;
        this.landing.emitLandingContentExpandHeight(true);
        this._errorMessageSubject.next('Error occurred, try again later');
        return null;
      });

    if (!response) {
      return;
    }

    if (response.success) {
      if (response.mingaName) {
        this.landing.mingaName = response.mingaName;
      }

      if (response.mingaLogo) {
        this.landing.mingaLogo = response.mingaLogo;
      }

      if (response.personName) {
        this.landing.personName = response.personName;
      }

      this.landing.emitLandingContentExpandHeight(false);

      // If our component has already been destroyed, that means we've navigated
      // somewhere else already. Changing the route again could be jarring to
      // the user.
      if (!this._destroyed) {
        await this._router.navigate(['/landing/joining'], {
          skipLocationChange: true,
        });
      }
    } else {
      if (!hasNetworkError) {
        this._errorMessageSubject.next(response.reason);
        this.landing.emitLandingContentExpandHeight(true);
      }
      return response;
    }
  }
}
