import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { MingaRoleType } from 'libs/domain';
import {
  ACTIVITY_RESTRICTION_ERRORS_TITLES,
  RESTRICTION_ERRORS,
} from 'libs/domain';
import { flex_time_activity_pb as ft_activity_pb } from 'libs/generated-grpc-web';

import { BarcodeScanner } from '@app/src/app/barcodeScanner';
import { RootService } from '@app/src/app/minimal/services/RootService';
import { PermissionsService } from '@app/src/app/permissions';

import { PS_CAMERA_NOT_FOUND_REOPEN_DELAY } from '@modules/people-selector/constants';

import {
  SystemAlertModalHangTime,
  SystemAlertModalService,
  SystemAlertModalType,
} from '@shared/components/system-alert-modal';
import { SystemAlertSnackBarService } from '@shared/components/system-alert-snackbar';
import { CheckinService } from '@shared/services/checkin';
import {
  FlexTimePermissionsService,
  FlexTimeRegistrationService,
} from '@shared/services/flex-time';
import { FlexTimeRegistrationValidationService } from '@shared/services/flex-time/flex-time-registration-validation.service';

import { PsData, PsSearchMode } from '../../types';
import { PeopleSelectorService } from '../people-selector.service';
import { PeopleSelectorFormService } from '../ps-form.service';
import { PsCollectionSearchImplService } from '../search-impl/ps-collection-search.impl.service';

@Injectable()
export class PsFlexTimeActivitiesService extends PeopleSelectorFormService<'FlexTime Activity'> {
  /** Service Constructor */
  constructor(
    public router: Router,
    public snackbar: SystemAlertSnackBarService,
    public barCodeScanner: BarcodeScanner,
    public peopleSelector: PeopleSelectorService,
    public permissions: PermissionsService,
    private _psCollectionSearch: PsCollectionSearchImplService,
    private _ftRegistration: FlexTimeRegistrationService,
    private _flexPermissions: FlexTimePermissionsService,
    private _systemAlertModal: SystemAlertModalService,
    private _checkinService: CheckinService,
    private _rootService: RootService,
    private _ftRegistrationValidation: FlexTimeRegistrationValidationService,
  ) {
    super(
      {
        name: 'FlexTime Activity',
        pageDefinitions: {
          assign: {
            submitFn: async =>
              this._ftRegistrationValidation.assign({
                activityId: this.data.flexTimeActivityId,
                periodId: this.data.flexTimePeriodId,
                selectedPeople: this.selection.getSelection(),
                canOverride: this.data.canOverride,
              }),
            searchFn: async =>
              this._psCollectionSearch.collectionSearch('text', undefined),
          },
          register: {
            submitFn: async =>
              this._ftRegistrationValidation.register({
                activityId: this.data.flexTimeActivityId,
                periodId: this.data.flexTimePeriodId,
                selectedPeople: this.selection.getSelection(),
                canOverride: this.data.canOverride,
              }),
            searchFn: async =>
              this._psCollectionSearch.collectionSearch('text', undefined),
          },
          remove: {
            submitFn: async => this.remove(),
            searchFn: async => this._fetchCurrentAssigned(),
          },
          checkin: {
            submitFn: async => this.checkin(),
            searchFn: async => this._fetchCurrentAssigned(),
          },
        },
      },
      router,
      snackbar,
      barCodeScanner,
      peopleSelector,
    );

    this.setConfig({
      name: 'FlexTime Activity',
      pageDefinitions: {
        assign: {
          submitFn: async () =>
            await this._ftRegistrationValidation.assign({
              activityId: this.data.flexTimeActivityId,
              periodId: this.data.flexTimePeriodId,
              selectedPeople: this.selection.getSelection(),
              canOverride: this.data.canOverride,
            }),
          searchFn: async (type, filters) =>
            this._psCollectionSearch.collectionSearch(type, filters),
        },
        register: {
          submitFn: async () =>
            await this._ftRegistrationValidation.register({
              activityId: this.data.flexTimeActivityId,
              periodId: this.data.flexTimePeriodId,
              selectedPeople: this.selection.getSelection(),
              canOverride: this.data.canOverride,
            }),
          searchFn: async (type, filters) =>
            this._psCollectionSearch.collectionSearch(type, filters),
        },
        remove: {
          submitFn: async () => this.remove(),
          searchFn: async () => this._fetchCurrentAssigned(),
        },
        checkin: {
          submitFn: async () => this.checkin(),
          searchFn: async () => this._fetchCurrentAssigned(),
        },
      },
    });
  }

  onInit(): void {
    this._disablePages();
  }

  public async remove(): Promise<void> {
    const selected = this.selection.getSelection();
    try {
      await this._ftRegistration.delete(
        this.data.flexTimeActivityId,
        selected.map(person => person.personHash),
      );
      const modalRef = await this._systemAlertModal.open({
        modalType: SystemAlertModalType.SUCCESS,
        heading: 'Registration Removed',
        message: 'Successfully removed registration for',
        subMessage: selected.map(person => person.displayName).join(', '),
        hangTime: SystemAlertModalHangTime.LONG,
      });
      await modalRef.afterClosed().toPromise();
    } catch (e) {
      this.snackBar.open({
        type: 'error',
        message: 'Error removing registration for user, please try again later',
      });
    }
  }

  public async checkin(
    personHashes = this.selection.getSelectionHashes(),
  ): Promise<void> {
    try {
      await this._checkinService.createCheckin(
        personHashes,
        this.data.checkinReasonId,
      );

      const hasManyPeople = personHashes.length > 1;
      const firstPerson = this.selection.getSelected(personHashes[0]);
      await this.peopleSelector.openDialog({
        type: 'success',
        audioAlerts: true,
        title: hasManyPeople ? undefined : firstPerson.displayName,
        subTitle: hasManyPeople
          ? `${personHashes.length} people checked in`
          : 'Checked in',
        message: this.formTitle,
      });
    } catch (error) {
      //TODO this should be a service
      let offline = true;
      try {
        await this._fetchCurrentAssigned(); // this can be any request pretty much
        offline = false;
      } catch (e) {
        //swallow this
        console.error('offline', e);
      }

      let modalRef;

      if (offline) {
        //offline error
        modalRef = await this._systemAlertModal.open({
          modalType: SystemAlertModalType.ERROR,
          icon: 'mg-offline',
          heading: 'Offline',
          message:
            "We couldn't check in the user because there is no internet connection",
        });
      } else {
        //generic error
        modalRef = await this._systemAlertModal.open({
          modalType: SystemAlertModalType.ERROR,
          heading: 'Check in error',
          message: 'Something went wrong, please try again',
        });
      }

      //wait for the modal to close
      if (modalRef) await modalRef.afterClosed().toPromise();
    }
  }

  public async onListScanNoResults(
    id: string,
    searchMode: PsSearchMode,
  ): Promise<void> {
    const page = this.pages.getActivePath();
    if (page === 'checkin') await this._searchPersonAndCheckin(id, searchMode);
  }

  private async _fetchCurrentAssigned(): Promise<PsData[]> {
    const results = await this._ftRegistration.fetchRegisteredPeople(
      this.data.flexTimeActivityId,
      this.data.checkinReasonId,
    );
    return results.map(person => ({
      personHash: person.personHash,
      displayName: person.displayName,
      grade: person.grade,
      school: person.school,
      studentId: person.studentId,
      status: person.status,
    }));
  }

  private async _searchPersonAndCheckin(
    id: string,
    searchMode: PsSearchMode,
  ): Promise<void> {
    try {
      let hardStop = false;
      const { flexTimeActivityId, flexTimePeriodId } = this.data;
      const canOveride = this._flexPermissions.isFlexTimeAdmin();

      const searchResults = await this._psCollectionSearch.collectionSearch(
        'text',
        {
          ...this.filters.getCurrentState(),
          keywords: id,
        },
      );

      if (!searchResults || searchResults.length === 0) {
        throw Error(`No person found for ID: ${id}`);
      }

      const { displayName, personHash } = searchResults[0];

      const { existingRegistrations, restrictionsErrors } =
        await this._rootService.addLoadingPromise(
          this._ftRegistration.validate(
            flexTimePeriodId,
            [personHash],
            flexTimeActivityId,
          ),
        );

      // wait for user to acknowledge restriction errors
      if (restrictionsErrors && restrictionsErrors.length > 0 && !hardStop) {
        for (const restriction of restrictionsErrors) {
          if (hardStop) break;
          const { code } = restriction;
          const dialogRef = await this.peopleSelector.openDialog({
            type: 'error',
            audioAlerts: true,
            title: displayName,
            message: this.formTitle,
            subTitle: ACTIVITY_RESTRICTION_ERRORS_TITLES[code].header,
            extendedMessage:
              RESTRICTION_ERRORS[code].message ||
              'Would you like to register and check the student in?',
            canContinue: true,
            closeText: 'Close',
            continueText: 'Check in',
          });
          hardStop = dialogRef.action !== 'continue';
        }
      }

      // wait for user to acknowledge registration errors
      if (
        existingRegistrations &&
        existingRegistrations.length > 0 &&
        !hardStop
      ) {
        const {
          activityName,
          activityTeacherName,
          registeredBy,
          activityLocation,
          type,
          studentName,
        } = existingRegistrations[0];
        const isAssigment = type === ft_activity_pb.RegistrationType.ASSIGNMENT;
        const action = isAssigment ? 'assign' : 'register';
        const dialogRef = await this.peopleSelector.openDialog({
          type: 'error',
          audioAlerts: true,
          title: displayName,
          message: this.formTitle,
          subTitle: `${
            isAssigment ? 'Assigned' : 'Registered'
          } to another activity`,
          extendedMessage: canOveride
            ? `Would you like to ${action} and check the student in?`
            : isAssigment
            ? `A student can't be checked in if they are assigned to another activity`
            : 'Would you like to register and check the student in?',
          detailedMessage: [
            [isAssigment ? 'Assigned by' : 'Registered by', registeredBy],
            ['Student', studentName],
            ['Activity', activityName],
            ['Teacher', activityTeacherName],
            ['Location', activityLocation],
          ],
          canContinue: canOveride || !isAssigment,
          closeText: canOveride || !isAssigment ? 'Deny' : 'Close',
          continueText: (canOveride || !isAssigment) && 'Check in',
        });
        hardStop = dialogRef.action !== 'continue';
      }
      // wait for user to acknowledge student is not registered for anything
      else {
        const dialogRef = await this.peopleSelector.openDialog({
          type: 'error',
          audioAlerts: true,
          title: displayName,
          message: this.formTitle,
          subTitle: 'Not registered to this activity',
          extendedMessage:
            'Would you like to register and check the student in?',
          canContinue: true,
          closeText: 'Close',
          continueText: 'Check in',
        });
        hardStop = dialogRef.action !== 'continue';
      }

      if (hardStop) throw new Error('Cancelled check in');

      await this._ftRegistration.assign(
        flexTimeActivityId,
        [personHash],
        false,
        true,
      );

      await this._checkinService.createCheckin(
        [personHash],
        this.data.checkinReasonId,
      );

      await new Promise(resolve => setTimeout(resolve, 100));

      await this.search();

      await this.peopleSelector.openDialog({
        type: 'success',
        audioAlerts: true,
        title: displayName,
        subTitle: 'Checked in',
        message: this.formTitle,
      });

      if (searchMode === 'camera') this.peopleSelector.emitEvent('open-camera');
    } catch (error) {
      const snackRef = this.snackBar.open({
        type: 'error',
        message:
          error?.message || 'Something went wrong, please try again later',
      });
      if (searchMode === 'camera') {
        await new Promise(resolve =>
          setTimeout(resolve, PS_CAMERA_NOT_FOUND_REOPEN_DELAY),
        );
        snackRef.dismiss();
        this.peopleSelector.emitEvent('open-camera');
      }
    }
  }

  private _disablePages() {
    if (this.data.canOverride === false) {
      if (
        this.permissions.hasRoleType(MingaRoleType.TEACHER) ||
        this.permissions.hasRoleType(MingaRoleType.STAFF)
      ) {
        this.pages.addDisabledPage(['assign', 'register', 'remove']);
      }
    }
  }
}
