import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';

import { IConsequence } from 'libs/domain';
import { MingaPermission } from 'libs/domain';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { PermissionsService } from '@app/src/app/permissions';

import {
  BmReportsConsequenceHistoryDatasourceService,
  BmReportsService,
} from '../../services';
import { BmReportActionService } from '../../services/bm-reports-action.service';
import { BmReportsConsHistoryMessages } from './bm-reports-cons-history.constants';

@Component({
  selector: 'mg-bm-reports-cons-history',
  templateUrl: './bm-reports-cons-history.component.html',
  styleUrls: ['./bm-reports-cons-history.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    BmReportsConsequenceHistoryDatasourceService,
    BmReportActionService,
  ],
})
export class BmReportsConsHistoryComponent implements OnDestroy {
  /** Constants */
  public readonly MESSAGES = BmReportsConsHistoryMessages;

  /** General Observables and Subject Behaviors */
  private _destroyedSubject = new ReplaySubject<void>(1);
  public readonly canMultiSelect$ = this._permissions.observePermission(
    MingaPermission.PBIS_TYPE_MANAGE,
  );

  /** Other */
  private _selectableItems: IConsequence[] = [];

  constructor(
    public ds: BmReportsConsequenceHistoryDatasourceService,
    public actionService: BmReportActionService,
    private _bmReportService: BmReportsService,
    private _permissions: PermissionsService,
    private _trackingReports: BmReportsService,
  ) {
    this.ds.items$.pipe(takeUntil(this._destroyedSubject)).subscribe(items => {
      this.actionService.clearSelection();
      this.actionService.setTotalSelectable(items.length);
      this._selectableItems = items;
    });

    this.actionService.toggleComplete
      .pipe(takeUntil(this._destroyedSubject))
      .subscribe(({ ids, complete }) => {
        this.ds.toggleComplete(ids, complete);
      });

    this._bmReportService.filter$
      .pipe(takeUntil(this._destroyedSubject))
      .subscribe(() => this.actionService.clearSelection());
  }

  ngOnDestroy(): void {
    this._destroyedSubject.next();
    this._destroyedSubject.complete();
  }

  public selectAllTableDataRows() {
    this.actionService.masterToggle(this._selectableItems);
  }
}
