// GENERATED CODE -- DO NOT EDIT!

import {grpc} from '@improbable-eng/grpc-web';

import * as gateway_analytics_pb from '../gateway/analytics_pb';
import * as common_legacy_pb from '../common/legacy_pb';

export class AnalyticsClient {
  static readonly serviceName = "AnalyticsClient";

  static readonly setAnalyticsClient = {
    methodName: "setAnalyticsClient",
    service: AnalyticsClient,
    requestStream: false,
    responseStream: false,
    requestType: gateway_analytics_pb.AnalyticsClientRequest,
    responseType: gateway_analytics_pb.AnalyticsClientResponse,
  };

  static readonly setAnalyticsUser = {
    methodName: "setAnalyticsUser",
    service: AnalyticsClient,
    requestStream: false,
    responseStream: false,
    requestType: gateway_analytics_pb.AnalyticsUserRequest,
    responseType: gateway_analytics_pb.AnalyticsUserResponse,
  };

  static readonly serverEmitEvent = {
    methodName: "serverEmitEvent",
    service: AnalyticsClient,
    requestStream: false,
    responseStream: false,
    requestType: gateway_analytics_pb.EmitEventRequest,
    responseType: gateway_analytics_pb.EmitEventResponse,
  };

}

export class AnalyticsClientClient {
  setAnalyticsClient() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  setAnalyticsUser() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  serverEmitEvent() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
}
