import {
  ChangeDetectorRef,
  Directive,
  EventEmitter,
  Injectable,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Output,
} from '@angular/core';

import { uniqBy } from 'lodash';
import flatten from 'lodash/flatten';
import {
  BehaviorSubject,
  Observable,
  ReplaySubject,
  combineLatest,
} from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

import { SystemAlertSnackBarService } from '@shared/components/system-alert-snackbar';

import {
  CategoryItem,
  OptionItem,
  SearchAdapter,
  SelectGroupCategory,
  SelectType,
} from './form-grouped-select.types';

/**
 * Directive() is needed for the input parameters to be properly picked up by
 * classes inheriting from this class.
 *
 * https://stackoverflow.com/a/60526158/2558645
 */
@Directive()
@Injectable()
export abstract class FormGroupedSelectBaseDirective
  implements OnDestroy, OnInit
{
  @Input() set options(options: Array<SelectGroupCategory>) {
    if (options) {
      this._setOptions(options);
    }
  }
  /**
   * Unique id for things like analytics and testing to hook into
   * Important to note changing this could break either of those
   */
  @Input() id: string;
  @Input() disabled: boolean;
  @Input() clearOnSelect = false;
  @Input() loading: boolean;
  @Input() clearable: boolean;
  @Input() multiple: boolean;
  @Input() lazyLoad = false;
  /**
   * Decides if we return user to last spot they were in if they close and return
   * Generally a desired behavior but in some circumstances we want to reset
   */
  @Input() resetMenuOnClose = false;

  @Output() selectChange: EventEmitter<OptionItem | OptionItem[] | null> =
    new EventEmitter();
  @Output() categoryChange: EventEmitter<CategoryItem | null> =
    new EventEmitter();

  /**
   * Default category we open to if user has never selected a category
   */
  protected _categoryOpenByDefault: CategoryItem;
  /**
   * Last category selected by the user, can be null, we use this to
   * return the user to same spot they we're before they exited the dropdown
   */
  protected _lastSelectedCategory: CategoryItem;

  public SELECT_TYPE = SelectType;
  public selectedValue: OptionItem | OptionItem[];

  public _searchAdapter: SearchAdapter | null;

  private _userSearchDataLoaded = false;

  protected _destroyedSubj = new ReplaySubject<void>(1);
  private _dataTreeSubj = new BehaviorSubject<Record<any, SelectGroupCategory>>(
    {},
  );

  private _categories$: Observable<CategoryItem[]> = this._dataTreeSubj.pipe(
    takeUntil(this._destroyedSubj),
    map(dataTree => {
      return Object.values(dataTree).map(category => {
        const { items, ...categoryItem } = category;
        return categoryItem;
      });
    }),
  );

  protected _options$: Observable<OptionItem[]> = this._dataTreeSubj.pipe(
    takeUntil(this._destroyedSubj),
    map(dataTree => {
      const options = flatten(
        Object.values(dataTree).map(category => {
          return (category.items || []).map(option => {
            return {
              ...option,
              category: {
                value: category.value,
                label: category.label,
                icon: category.icon,
                iconColor: category.iconColor,
              },
            };
          });
        }),
      );

      if (this._searchAdapter) {
        this._searchAdapter.setCollection(options);
      }

      return options;
    }),
  );

  private _activeCategorySubj = new BehaviorSubject<CategoryItem>(null);
  public activeCategory$ = this._activeCategorySubj.asObservable();

  protected _selectTypeSubj: BehaviorSubject<SelectType> = new BehaviorSubject(
    SelectType.CATEGORY,
  );
  public selectType$: Observable<SelectType> =
    this._selectTypeSubj.asObservable();

  private _lazyLoadingSubj = new BehaviorSubject<boolean>(false);
  public lazyLoading$ = this._lazyLoadingSubj.asObservable();

  protected _searchFilterSubj = new BehaviorSubject<string>('');
  protected searchFilter$ = this._searchFilterSubj.asObservable();

  private _filteredOptions$: Observable<OptionItem[]> = combineLatest([
    this._options$,
    this.activeCategory$,
  ]).pipe(
    takeUntil(this._destroyedSubj),
    map(([options, filter]) => {
      if (!filter) return options;
      return options.filter(option => option.category.value === filter.value);
    }),
  );

  public options$: Observable<OptionItem[] | CategoryItem[]> = combineLatest([
    this._categories$,
    this._filteredOptions$,
    this.selectType$,
    this._searchFilterSubj,
  ]).pipe(
    map(([categories, options, selectType, searchFilter]) => {
      if (!this._searchAdapter || !searchFilter) {
        return selectType === SelectType.OPTION ? options : categories;
      }

      if (selectType === SelectType.OPTION) {
        const filtered = this._searchAdapter
          .search(searchFilter)
          .map(r => r.item);

        // dedupe since they can live in multiple categories
        return uniqBy(filtered, 'value');
      } else {
        return categories;
      }
    }),
  );

  constructor(
    private _cdr: ChangeDetectorRef,
    private _snackBar: SystemAlertSnackBarService,
    @Optional() private _search: SearchAdapter,
  ) {
    this._searchAdapter = _search;
  }

  public abstract onSelect(
    event: OptionItem | OptionItem[] | null,
    emitChange: boolean,
  ): void;

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this._destroyedSubj.next();
    this._destroyedSubj.complete();
  }

  public trackByFn(item: OptionItem) {
    return item?.value;
  }

  public async handleLazyLoadSearch() {
    if (this.lazyLoad) {
      await this._handleUserSearchDataLoading();
    }
  }

  public async setCategory(category: CategoryItem, emitChange = true) {
    this._setCategory(category);
    this._selectTypeSubj.next(SelectType.OPTION);
    if (emitChange) {
      this.categoryChange.emit(category);
    }

    if (category?.lazyLoad) {
      await this._lazyLoadData(category);
    }
  }

  public reset(forceReset = false) {
    if (this.clearOnSelect || forceReset || !this.selectedValue) {
      this._activeCategorySubj.next(null);
      this.selectedValue = null;
    }
    this._selectTypeSubj.next(SelectType.CATEGORY);
    this._cdr.detectChanges();
  }

  public clear() {
    this.reset(true);
    this.selectChange.emit(null);
  }

  public back() {
    this._setCategory(null);
    this._selectTypeSubj.next(SelectType.CATEGORY);
  }

  /**
   * Side effects related to text search we need to run
   */
  public async onSearch(event: { term: string }) {
    const { term } = event;
    await this.handleLazyLoadSearch();
    this._setCategory(null);
    this._selectTypeSubj.next(term ? SelectType.OPTION : SelectType.CATEGORY);
  }

  protected _setCategory(category) {
    this._lastSelectedCategory = category;
    this._activeCategorySubj.next(category);
  }

  private _setOptions(categories: Array<SelectGroupCategory>) {
    const dataTree = categories.reduce((acc, category) => {
      acc[category.value] = category;
      return acc;
    }, {});
    this._dataTreeSubj.next(dataTree);

    const selected = categories.find(c => c.openByDefault);

    if (selected) {
      const category = this._getCategory(selected.value);
      this._categoryOpenByDefault = category;
    }
  }

  protected _hasValue(value: any | any[]): boolean {
    if (Array.isArray(value)) {
      return value.length > 0;
    }
    return !!value;
  }

  protected _getCategory(type: string | number): CategoryItem {
    const tree = this._dataTreeSubj.value;
    const categoryData = tree[type];

    if (!categoryData) return;

    // lets strip out the items, this can be very large list
    const { items, ...category } = categoryData;
    return category;
  }

  private async _lazyLoadData(category: CategoryItem) {
    try {
      this._lazyLoadingSubj.next(true);
      const tree = this._dataTreeSubj.value;
      const categoryData = tree[category.value];

      if (!categoryData?.fetch) {
        throw new Error('Lazy load category does not have fetch method');
      }

      const items = await categoryData.fetch();
      if (items) {
        categoryData.items = [...items];
        this._dataTreeSubj.next({
          ...tree,
          [category.value]: categoryData,
        });
      }
    } catch (error) {
      console.error(error);
      this._snackBar.error(
        `There was an error loading data for ${category.label}`,
      );
    } finally {
      this._lazyLoadingSubj.next(false);
    }
  }

  /**
   * If we're lazy loading data but a user starts typing prior to any data being loaded
   * we need to load the data for the user search
   */
  protected async _handleUserSearchDataLoading() {
    if (this._userSearchDataLoaded) return;

    this._userSearchDataLoaded = true;

    const categories = Object.values(this._dataTreeSubj.value);
    const category = categories.find(c => c.usedForUserSearch) || categories[0];

    if (category?.lazyLoad) {
      await this._lazyLoadData(category);
    }

    this._userSearchDataLoaded = true;
  }
}
