import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatMenuModule } from '@angular/material/menu';
import { RouterModule } from '@angular/router';

import { MgEmptyStateModule } from '@app/src/app/components/EmptyState';
import { MgIconModule } from '@app/src/app/icon';
import { PermissionsModule } from '@app/src/app/permissions';
import { MgPipesModule } from '@app/src/app/pipes';
import { MgSpinnerModule } from '@app/src/app/spinner';

import { FtmSharedModule } from '@modules/flex-time-manager/components/ftm-shared/ftm-shared.module';

import { GenericButtonModule } from '@shared/components/button';
import { GenericModule } from '@shared/components/generic';
import { OverlayModule } from '@shared/components/modal-overlay';
import { PlaceholderModule } from '@shared/components/placeholder/placeholder.module';
import { ReportTableModule } from '@shared/components/report-table';
import { ScheduleWidgetModule } from '@shared/components/student-schedule-widget/student-schedule-widget.module';
import { TextModule } from '@shared/components/text/text.module';
import { CommonPipesModule, CommonUIModules } from '@shared/modules';
import {
  FlexTimeActivityInstanceService,
  FlexTimePeriodService,
} from '@shared/services/flex-time';

import { SharedToolsModule } from '../shared-tools/shared-tools.module';
import { StBehaviourItemComponent } from './components/st-behaviour-item/st-behaviour-item.component';
import { StBehavioursComponent } from './components/st-behaviours/st-behaviours.component';
import { StCheckinComponent } from './components/st-checkin/st-checkin.component';
import { StFlexTimeComponent } from './components/st-flex-time/st-flex-time.component';
import { StMyToolsComponent } from './components/st-my-tools/st-my-tools.component';
import { StRootComponent } from './components/st-root/st-root.component';
import {
  StudentToolsCheckinService,
  StudentToolsFlexTimeService,
  StudentToolsService,
} from './services';

@NgModule({
  imports: [
    // Common dependencies
    CommonUIModules,

    // Minga dependencies
    PermissionsModule,
    MgEmptyStateModule,
    MgIconModule,
    TextModule,
    SharedToolsModule,
    GenericButtonModule,
    OverlayModule,
    MgSpinnerModule,
    MgPipesModule,
    PlaceholderModule,
    FtmSharedModule,
    GenericModule,
    ReportTableModule,
    SharedToolsModule,
    MatMenuModule,
    ScheduleWidgetModule,
    CommonPipesModule,

    // External dependencies
    CommonModule,
    RouterModule,
    FlexLayoutModule,
  ],
  declarations: [
    StMyToolsComponent,
    StRootComponent,
    StBehavioursComponent,
    StBehaviourItemComponent,
    StCheckinComponent,
    StFlexTimeComponent,
  ],
  exports: [
    StRootComponent,
    StMyToolsComponent,
    StBehavioursComponent,
    StCheckinComponent,
    StFlexTimeComponent,
  ],
  providers: [
    StudentToolsService,
    StudentToolsCheckinService,
    StudentToolsFlexTimeService,
    FlexTimePeriodService,
    FlexTimeActivityInstanceService,
  ],
})
export class StudentToolsModule {}
