// GENERATED CODE -- DO NOT EDIT!

import {grpc} from '@improbable-eng/grpc-web';

import * as misc_category_pb from '../misc/category_pb';

export class CategoryManager {
  static readonly serviceName = "minga.category.CategoryManager";

  static readonly GetAllCategories = {
    methodName: "GetAllCategories",
    service: CategoryManager,
    requestStream: false,
    responseStream: false,
    requestType: misc_category_pb.GetAllCategoriesRequest,
    responseType: misc_category_pb.GetAllCategoriesResponse,
  };

  static readonly GetCategoryTypes = {
    methodName: "GetCategoryTypes",
    service: CategoryManager,
    requestStream: false,
    responseStream: false,
    requestType: misc_category_pb.GetCategoryTypesRequest,
    responseType: misc_category_pb.GetCategoryTypesResponse,
  };

  static readonly AddCategory = {
    methodName: "AddCategory",
    service: CategoryManager,
    requestStream: false,
    responseStream: false,
    requestType: misc_category_pb.AddCategoryRequest,
    responseType: misc_category_pb.AddCategoryResponse,
  };

  static readonly UpdateCategory = {
    methodName: "UpdateCategory",
    service: CategoryManager,
    requestStream: false,
    responseStream: false,
    requestType: misc_category_pb.UpdateCategoryRequest,
    responseType: misc_category_pb.UpdateCategoryResponse,
  };

}

export class CategoryManagerClient {
  getAllCategories() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  getCategoryTypes() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  addCategory() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  updateCategory() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
}
