import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnDestroy,
} from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';

import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

import { PeopleSelectorService, PsData } from '@modules/people-selector';

import {
  MODAL_OVERLAY_DATA,
  ModalOverlayPrimaryHeaderBackground,
  ModalOverlayRef,
  ModalOverlayServiceCloseEventType,
} from '@shared/components/modal-overlay';
import { SystemAlertSnackBarService } from '@shared/components/system-alert-snackbar';
import { AutomationService } from '@shared/services/automation';

import { BehaviorManagerService } from '../../../../services';

export interface BmTypesAutomationResetModalData {
  automationId: number;
  automationGroupName?: string;
  type: 'all' | 'selection';
  selection: PsData[];
}

@Component({
  selector: 'mg-bm-types-automation-reset',
  templateUrl: './bm-types-automation-reset.component.html',
  styleUrls: ['./bm-types-automation-reset.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BmTypesAutomationResetComponent implements OnDestroy {
  /** Constants */
  public readonly MODAL_CONFIG = {
    headerBg: ModalOverlayPrimaryHeaderBackground.GREEN,
  };

  /** General Subjects */
  private _destroyedSubject = new ReplaySubject<void>(1);

  /** Loading */
  private _isLoading = new BehaviorSubject<boolean>(false);
  public readonly isLoading$ = this._isLoading.asObservable();

  /** Selection */
  private readonly _selectionSubject = new BehaviorSubject<PsData[]>([]);
  public readonly selection$ = this._selectionSubject.asObservable();

  public readonly countControl = this._fb.control(0, [
    Validators.required,
    Validators.min(0),
  ]);

  /** Automation Options */
  public readonly automationOptions$ = this._bmService.automations$
    .pipe(takeUntil(this._destroyedSubject))
    .pipe(
      map(automations =>
        automations.map(({ name, id }) => ({
          label: name,
          value: id,
        })),
      ),
    );

  /** Component Constructor */
  constructor(
    @Inject(MODAL_OVERLAY_DATA)
    public dialogData: BmTypesAutomationResetModalData,
    private _fb: UntypedFormBuilder,
    private _bmService: BehaviorManagerService,
    private _modalOverlay: ModalOverlayRef,
    private _dialog: MatDialog,
    private _automationService: AutomationService,
    private _systemAlert: SystemAlertSnackBarService,
    private _peopleSelector: PeopleSelectorService,
  ) {
    this._bmService.fetchTypes();
    if (dialogData?.selection)
      this._selectionSubject.next(dialogData.selection);
  }

  ngOnDestroy(): void {
    this._destroyedSubject.next();
    this._destroyedSubject.complete();
    this._isLoading.complete();
  }

  public async editSelection(): Promise<any> {
    const newSelection = await this._peopleSelector.openStandAlone(
      this.dialogData?.automationGroupName,
      {
        submitLabel: 'Next',
      },
      this._selectionSubject.getValue(),
    );
    this._selectionSubject.next(newSelection);
  }

  public async submit(): Promise<void> {
    try {
      const count = this.countControl.value;
      await this._automationService.resetMultipleAutomationCounts(
        [this.dialogData.automationId],
        count,
        this._selectionSubject.getValue().map(({ personHash }) => personHash),
        this.dialogData.type === 'all',
      );
      this._modalOverlay.close(ModalOverlayServiceCloseEventType.SUBMIT, {});
    } catch (error) {
      this._systemAlert.error('Failed to reset automations stats');
    }
  }

  public async delete(): Promise<void> {
    this._modalOverlay.close(ModalOverlayServiceCloseEventType.CLOSE);
  }
}
