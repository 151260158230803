import {
  Component,
  ContentChild,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  Output,
  TemplateRef,
} from '@angular/core';
import { MediaObserver } from '@angular/flex-layout';

import { ColumnInfo } from 'libs/shared';
import { xor } from 'lodash';
import { ReplaySubject } from 'rxjs';
import { distinctUntilChanged, map, takeUntil } from 'rxjs/operators';

import {
  FormSheetSelectOption,
  FormSheetSelectService,
} from '@shared/components/form/components/form-sheet-select';
import { AppRuntime, AppRuntimeInterface } from '@shared/services/app-runtime';
import { MediaService } from '@shared/services/media';

import { ReportsSubpageMessages } from './constants';

@Component({
  selector: 'mg-manager-overlay-reports-subpage',
  templateUrl: './manager-overlay-reports-subpage.component.html',
  styleUrls: ['./manager-overlay-reports-subpage.component.scss'],
})
export class ManagerOverlayReportsSubpageComponent implements OnDestroy {
  // Template Ref Forwarding

  @ContentChild('header', { read: TemplateRef })
  header: TemplateRef<any>;
  @ContentChild('content', { read: TemplateRef })
  content: TemplateRef<any>;
  @ContentChild('filtersFormReference', { read: TemplateRef })
  filtersFormReference: TemplateRef<any>;

  // Constants

  public readonly MESSAGES = ReportsSubpageMessages;

  // Cleanup

  private _destroyedSubject = new ReplaySubject<void>(1);

  // State

  public readonly media$ = this.mediaObserver.asObservable().pipe(
    takeUntil(this._destroyedSubject),
    map(change => change[0].mqAlias),
    distinctUntilChanged(),
  );

  // Inputs

  @Input() loading: boolean;
  @Input() noBottomPadding: boolean;
  @Input() scheduleReportsButton = true;
  @Input() exportListButton = true;
  @Input() columnSettingsButton = true;
  @Input() dataService: any;

  // Outputs

  @Output() scheduleReportsPressed = new EventEmitter<void>();
  @Output() exportListPressed = new EventEmitter<void>();
  @Output() columnSettingsPressed = new EventEmitter<void>();

  /** Component Constructor */
  constructor(
    public media: MediaService,
    public mediaObserver: MediaObserver,
    @Inject(AppRuntimeInterface) private _runtime: AppRuntime,
    private _formSheetSelect: FormSheetSelectService,
  ) {}

  public get canDownloadExport(): boolean {
    return !this._runtime.isNativeApp();
  }

  ngOnDestroy(): void {
    this._destroyedSubject.next();
    this._destroyedSubject.complete();
  }

  public openColumnsSettingsSheet(): void {
    this.columnSettingsPressed.emit();
    const toggleColumns = this.dataService?.toggleColumns as ColumnInfo[];
    const options: FormSheetSelectOption[] = [];
    const initialSelection: FormSheetSelectOption[] = [];
    toggleColumns.forEach(column => {
      if (!column.header) return;
      const selectOption: FormSheetSelectOption = {
        label: column.header,
        value: column.key,
      };
      options.push(selectOption);
      if (!column.hidden) initialSelection.push(selectOption);
    });
    this._formSheetSelect
      .open({
        title: 'Column Settings',
        fullHeight: true,
        enableSearch: false,
        multiple: true,
        canCancel: true,
        options,
        initialSelection,
      })
      .subscribe(response => {
        if (response?.type === 'submit') {
          xor(
            initialSelection.map(option => option.value),
            response.data.selection.map(option => option.value),
          ).forEach(option => this.dataService.toggleColumn(option));
        }
      });
  }
}
