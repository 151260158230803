import { environment } from '@app/src/environment';

const userAgent = navigator.userAgent || navigator.vendor;

const hasMingaApp = /\(io\.minga\.app\)/i.test(userAgent);

/**
 * TODO:
 * We should try to phase these global constants out and inject them
 * where ever needed using the AppRuntime service. That allows us to
 * decouple the app and make it more testable and flexible in various
 * areas.
 */
const MINGA_DEVICE_ANDROID = /android/i.test(userAgent);
const MINGA_DEVICE_IOS = /iPad|iPhone|iPod/.test(userAgent);
const MINGA_APP_ANDROID = hasMingaApp && MINGA_DEVICE_ANDROID;
const MINGA_APP_IOS = hasMingaApp && MINGA_DEVICE_IOS;
const MINGA_APP_BROWSER = !hasMingaApp;
const MINGA_APP_PLATFORM: 'android' | 'ios' | 'browser' = (() => {
  if (MINGA_APP_ANDROID) return 'android';
  if (MINGA_APP_IOS) return 'ios';
  return 'browser';
})();
const MINGA_DEVICE: 'android' | 'ios' | '' = (() => {
  if (MINGA_DEVICE_ANDROID) return 'android';
  if (MINGA_DEVICE_IOS) return 'ios';
  return '';
})();

const DEFAULT_ANGULAR_GRPC_HOST = (() => {
  // If we have it in config just use that.
  if (environment.mgsrvGatewayHost) {
    return environment.mgsrvGatewayHost;
  }

  // On the browser we have a hostname so we are deducing api from the location
  // hostname.
  if (MINGA_APP_BROWSER) {
    let mgsrvGatewayHost =
      'https://api' + location.hostname.substr(location.hostname.indexOf('.'));

    // If we don't have a domain name set it to localhost
    if (mgsrvGatewayHost.indexOf('.') == -1) {
      mgsrvGatewayHost = 'https://localhost:8443';
    }

    return mgsrvGatewayHost;
  } else {
    return 'https://api.us.minga.io';
  }
})();

const BODY_ENV_SELECTORS = {
  MINGA_DEVICE_ANDROID: {
    selector: 'device-android',
    active: !!MINGA_DEVICE_ANDROID,
  },
  MINGA_DEVICE_IOS: { selector: 'device-ios', active: !!MINGA_DEVICE_IOS },
  MINGA_APP_ANDROID: { selector: 'app-android', active: !!MINGA_APP_ANDROID },
  MINGA_APP_BROWSER: { selector: 'app-browser', active: !!MINGA_APP_BROWSER },
  MINGA_DEVICE: { selector: 'device-' + MINGA_DEVICE, active: !!MINGA_DEVICE },
  MINGA_APP_IOS: { selector: 'app-ios', active: !!MINGA_APP_IOS },
  MINGA_APP_PLATFORM: {
    selector: 'app-platform-' + MINGA_APP_PLATFORM,
    active: !!MINGA_APP_PLATFORM,
  },
};
// add active environment selectors to <body>
for (const [key, value] of Object.entries(BODY_ENV_SELECTORS)) {
  if (value.active) {
    document.body.classList.add(value.selector);
  }
}

Object.defineProperties(window, {
  MINGA_DEVICE_ANDROID: { value: MINGA_DEVICE_ANDROID },
  MINGA_DEVICE_IOS: { value: MINGA_DEVICE_IOS },
  MINGA_APP_ANDROID: { value: MINGA_APP_ANDROID },
  MINGA_APP_BROWSER: { value: MINGA_APP_BROWSER },
  MINGA_DEVICE: { value: MINGA_DEVICE },
  MINGA_APP_IOS: { value: MINGA_APP_IOS },
  MINGA_APP_PLATFORM: { value: MINGA_APP_PLATFORM },
  DEFAULT_ANGULAR_GRPC_HOST: {
    value: DEFAULT_ANGULAR_GRPC_HOST,
    writable: true,
  },
});
