// GENERATED CODE -- DO NOT EDIT!

import {grpc} from '@improbable-eng/grpc-web';

import * as badge_badge_pb from '../badge/badge_pb';
import * as common_date_pb from '../common/date_pb';
import * as image_image_pb from '../image/image_pb';

export class BadgeCollection {
  static readonly serviceName = "minga.badge.BadgeCollection";

  static readonly GetPersonBadges = {
    methodName: "GetPersonBadges",
    service: BadgeCollection,
    requestStream: false,
    responseStream: false,
    requestType: badge_badge_pb.GetPersonBadgesRequest,
    responseType: badge_badge_pb.GetPersonBadgesResponse,
  };

}

export class BadgeCollectionClient {
  getPersonBadges() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
}
