import {$enum} from 'ts-enum-util';

const WHATS_NEW_BASE = 'WHATS_NEW_';

export enum FtueKey {
  DISTRICT_FTUE = 'DISTRICT_FTUE',
  WELCOME_FTUE = 'WELCOME_FTUE',
  AVATAR_FTUE = 'AVATAR_FTUE',
  /**
   * MAKE SURE TO CHANGE THIS IF YOU ARE CHANGING THE WHAT"S NEW
   */
  WHATS_NEW = 'WHATS_NEW_6.1.0',
}


export function stringToFtueKey(key: string): FtueKey|null {
  // if the value of WHATS_NEW has changed, return null, only return current
  if(key.includes(WHATS_NEW_BASE) && key != FtueKey.WHATS_NEW) {
    return null;
  }

  return $enum(FtueKey).asValueOrThrow(key);
}
