export enum MingaTypeValue {
  MINGA = 'MINGA',
  CONFERENCE = 'CONFERENCE',
}

export enum MingaStatusValue {
  DEMO = 'DEMO',
  EVAL = 'EVAL',
  TESTING = 'TESTING',
  LIVE = 'LIVE',
  EXPIRED = 'EXPIRED',
  EVAL_OVER = 'EVAL_OVER',
  // PENDING_DELETION means a deletion was attempted (and failed) or it is okay
  // for the deletion to occur later.
  PENDING_DELETION = 'PENDING_DELETION',
  TRIAL = 'TRIAL',
}

export enum MingaSupportTier {
  CORE = 'CORE',
  PREMIUM = 'PREMIUM',
}

export enum SchoolTypeValue {
  HIGH_SCHOOL = 'HIGH_SCHOOL',
  MIDDLE_SCHOOL = 'MIDDLE_SCHOOL',
  ELEMENTARY = 'ELEMENTARY',
  K12 = 'K12',
  PRIVATE = 'PRIVATE',
  OTHER = 'OTHER',
}

export const schoolTypes = {
  HIGH_SCHOOL: 'Highschool',
  MIDDLE_SCHOOL: 'Middle School',
  ELEMENTARY: 'Elementary',
  K12: 'K12',
  PRIVATE: 'Private',
  OTHER: 'Other',
};

/**
 * Returns a minga type's human readable verbose string
 */
export function mingaTypeToDisplayString(
  type: MingaTypeValue | string,
): string {
  switch (type) {
    case MingaTypeValue.MINGA:
      return 'MingaSchool';
    case MingaTypeValue.CONFERENCE:
      return 'MingaConference';
    default:
      return '';
  }
}

export function stringToMingaTypeEnum(type: string): MingaTypeValue {
  switch (type) {
    case 'MINGA':
      return MingaTypeValue.MINGA;
    case 'CONFERENCE':
      return MingaTypeValue.CONFERENCE;
    default:
      throw new Error('Not a valid minga type');
  }
}
