import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';

import { map } from 'rxjs/operators';

import { AppConfigService } from '@app/src/app/minimal/services/AppConfig';

import { DatePresetsStore } from '@modules/minga-manager/components/mm-date-presets/store/date-presets.store';
import { ClientDatePreset } from '@modules/minga-manager/components/mm-date-presets/types/mm-date-presets.types';
import { getNameLabel } from '@modules/minga-manager/components/mm-date-presets/utils/date-presets.utils';

import { DatePresetMessages } from '@shared/components/date-presets/components/mm-date-presets-edit/mm-date-presets-edit.constants';
import { DatePresetsEditService } from '@shared/components/date-presets/components/mm-date-presets-edit/mm-date-presets-edit.service';
import { ModalOverlayServiceCloseEventType } from '@shared/components/modal-overlay';
import { SystemAlertSnackBarService } from '@shared/components/system-alert-snackbar';

import { DatePickerDefaultPresetKey } from '../../form-date-range.constants';

@Component({
  selector: 'mg-form-date-range-presets',
  templateUrl: './form-date-range-presets.component.html',
  styleUrls: ['./form-date-range-presets.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormDateRangePresetsComponent implements OnInit {
  @Input() public activePreset: ClientDatePreset;
  /**
   * When supplied will be shown instead of the default/custom presets
   */
  @Input() customPresets?: DatePickerDefaultPresetKey[];
  @Output() presetClicked = new EventEmitter<ClientDatePreset>();

  public datePresets$ = this.datePresetsStore.datePresets$.pipe(
    map(presets => {
      if (!this.customPresets?.length) return presets;

      return presets.filter(preset =>
        this.customPresets.includes(preset.id as any),
      );
    }),
  );

  constructor(
    public appConfig: AppConfigService,
    public datePresetsStore: DatePresetsStore,
    private _datePresetEdit: DatePresetsEditService,
    private _systemAlertSnackbar: SystemAlertSnackBarService,
  ) {}

  ngOnInit(): void {
    this.datePresetsStore.getDatePresets({ onlyActive: true });
  }

  public onSelectPreset(preset: ClientDatePreset) {
    this.presetClicked.next(preset);
  }

  public async createPreset() {
    const modalRef = this._datePresetEdit.openEditModal({});

    const response = await modalRef.afterClosed.toPromise();

    const { type, data } = response;

    if (type === ModalOverlayServiceCloseEventType.CREATE) {
      this.datePresetsStore.addDatePreset(data.created);
      this._systemAlertSnackbar.open({
        type: 'success',
        message: DatePresetMessages.SNACKBAR_CREATE_SUCCESS,
      });
    }
  }

  public getNameLabel(item: ClientDatePreset): string {
    return getNameLabel(item);
  }
}
