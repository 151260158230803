import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';

import { Subscription } from 'rxjs';

import { NavsearchComponent } from '@app/src/app/components/Navsearch';
import { SearchService } from '@app/src/app/search';

@Component({
  selector: 'mg-search-overlay',
  templateUrl: './SearchOverlay.component.html',
  styleUrls: ['./SearchOverlay.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchOverlayComponent implements OnChanges, OnInit, OnDestroy {
  @ViewChild('navsearch', { static: true })
  navsearch?: NavsearchComponent;

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(
    event: KeyboardEvent,
  ) {
    if (this.searchOverlayActive) {
      this.clearSearch();
      this._router.navigate(['', { outlets: { search: null } }]);
    }
  }

  @HostBinding('class.mg-search-overlay-active')
  @Input()
  searchOverlayActive: boolean = false;

  @Input()
  navSearchValue: string = this.search.searchQuery;

  @Output()
  navSearchValueChange: EventEmitter<string>;

  private _sub?: Subscription;

  constructor(public search: SearchService, private _router: Router) {
    this.navSearchValueChange = new EventEmitter();
  }

  clearSearch() {
    this.search.resetSearch();
  }

  ngOnInit() {
    this._sub = this.search.searchQuery$.subscribe(searchQuery => {
      this.navSearchValue = searchQuery;
    });
  }

  ngOnDestroy() {
    if (this._sub) {
      this._sub.unsubscribe();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.searchOverlayActive) {
      if (this.searchOverlayActive) {
        requestAnimationFrame(() => {
          this.focusNavsearch();
        });
      }
    }

    if (changes.navSearchValue) {
      this.onNavSearchValueChange(this.navSearchValue, false);
    }
  }

  onNavSearchValueChange(value: string, notify: boolean) {
    this.search.setSearchQuery(value);

    if (notify) {
      this.navSearchValueChange.emit(this.navSearchValue);
    }
  }

  focusNavsearch() {
    if (this.navsearch) {
      this.navsearch.focus();
    }
  }
}
