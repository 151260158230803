import { Injectable } from '@angular/core';

import * as localforage from 'localforage';
import { firebase } from '@app/src/firebase';

const USER_STORAGE_PREFIX = 'mgu~';

const storageErrorResult = Symbol('StorageErrorResult');

@Injectable({ providedIn: 'root' })
export class UserStorage {
  private _initialized: Promise<void>;
  private _initializedResolve: (() => void) | null = null;
  private _currentUserId: string = '';
  private _memFallback: Map<string, any> = new Map();

  constructor() {
    this._initialized = new Promise(resolve => {
      this._initializedResolve = resolve;
    });
  }

  init() {
    if (!this._initializedResolve) {
      throw new Error('UserStorage.init() may only be called once');
    }

    const initializedResolve = this._initializedResolve;
    this._initializedResolve = null;

    firebase.auth().onIdTokenChanged(user => {
      if (user) {
        if (this._currentUserId && this._currentUserId != user.uid) {
          this.clear();
        }

        this._currentUserId = user.uid;
      } else {
        this._currentUserId = '';
      }

      initializedResolve();
    });
  }

  private _prefix() {
    return USER_STORAGE_PREFIX + this._currentUserId + ':';
  }

  async clear() {
    await this._initialized;
    this._memFallback.clear();

    const prefix = this._prefix();

    let keys = await localforage.keys().catch(err => {
      console.error(err);
      return <string[]>[];
    });

    await Promise.all(
      keys.map(async key => {
        if (key.startsWith(prefix)) {
          await localforage.removeItem(key).catch(console.error);
        }
      }),
    );
  }

  async removeItem(key: string): Promise<void> {
    await this._initialized;
    const prefixedKey = this._prefix() + key;
    this._memFallback.delete(prefixedKey);
    await localforage.removeItem(prefixedKey).catch(console.error);
  }

  async setItem<T>(key: string, value: T): Promise<T> {
    await this._initialized;
    const prefixedKey = this._prefix() + key;

    try {
      await localforage.setItem<T>(prefixedKey, value);
      // Deleting the mem fallback in the case of a successful write to local
      // storage just incase they decided to write `null`
      this._memFallback.delete(prefixedKey);
    } catch (err) {
      this._memFallback.set(prefixedKey, value);
      console.error(err);
    }

    return value;
  }

  async getItem<T>(key: string): Promise<T | null> {
    await this._initialized;
    const prefixedKey = this._prefix() + key;
    let result = await localforage.getItem<T | null>(prefixedKey).catch(err => {
      console.error(err);
      return storageErrorResult;
    });

    let value: T | null = null;

    if (result === storageErrorResult) {
      value = this._memFallback.get(prefixedKey);
    } else {
      value = <T | null>result;
    }

    return value;
  }
}
