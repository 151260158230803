import { INewDirectMessage } from 'libs/domain';
import { INewDirectMessageMapper } from 'libs/shared-grpc';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { RealtimeDataConsumer, RealtimeDataSender } from './RealtimeData';

const REALTIME_NEW_DM_MESSAGE_KEY = 'newDm';

export class RealtimeDirectMessageConsumer {
  readonly newMessage$: Observable<INewDirectMessage>;

  constructor(_dataConsumer: RealtimeDataConsumer) {
    this.newMessage$ = _dataConsumer.messageNotice$.pipe(
      map(payload => payload[REALTIME_NEW_DM_MESSAGE_KEY]),
      filter(newDm => typeof newDm !== 'undefined'),
      map(msgBin => msgBin as string),
      map(INewDirectMessageMapper.fromBase64),
    );
  }
}

export class RealtimeDirectMessageSender {
  constructor(private _dataSender: RealtimeDataSender) {}

  async sendNewMessageNotice(message: INewDirectMessage): Promise<void> {
    const payload = {
      [REALTIME_NEW_DM_MESSAGE_KEY]: INewDirectMessageMapper.toBase64(message),
    };

    await this._dataSender.send(payload);
  }
}
