import { FlexUnregisteredInfo } from 'libs/domain';
import { flex_time_stats_pb as flex_proto } from 'libs/generated-grpc-ts';

export const toProto = (
  info: FlexUnregisteredInfo,
): flex_proto.FlexUnregisteredInfo => {
  const proto = new flex_proto.FlexUnregisteredInfo();
  proto.setFirstName(info.firstName);
  proto.setLastName(info.lastName);
  proto.setIconUrl(info.badgeIconUrl);
  proto.setRole(info.role);
  proto.setHash(info.hash);
  if (info.grade) proto.setGrade(info.grade);
  if (info.studentId) proto.setStudentIdNum(info.studentId);
  return proto;
};

export const fromProto = (
  proto: flex_proto.FlexUnregisteredInfo,
): FlexUnregisteredInfo => ({
  firstName: proto.getFirstName(),
  lastName: proto.getLastName(),
  badgeIconUrl: proto.getIconUrl(),
  role: proto.getRole(),
  grade: proto.getGrade(),
  studentId: proto.getStudentIdNum(),
  hash: proto.getHash(),
});
