import { IMembershipList, MembershipListType } from 'libs/domain';
import { membership_list_pb } from 'libs/generated-grpc-ts';
import { $enum } from 'ts-enum-util';

export namespace MembershipListMapper {
  export const toProto = (
    list: IMembershipList,
  ): membership_list_pb.MembershipList => {
    const msg = new membership_list_pb.MembershipList();

    if (list.id) {
      msg.setListId(list.id);
    }

    if (list.name) {
      msg.setListName(list.name);
    }

    if (list.assetPath) {
      msg.setListAsset(list.assetPath);
    }

    if (list.bannerHash) {
      msg.setListBanner(list.bannerHash);
    }

    if (list.bannerImage) {
      msg.setBannerImage(list.bannerImage);
    }

    if (list.active) {
      msg.setListActive(list.active);
    }

    if (list.listType) {
      msg.setListType(list.listType);
    }

    if (list.memberCount) {
      msg.setMemberCount(list.memberCount);
    }

    if (list.members) {
      msg.setMembersList(list.members);
    }

    if (list.contextHash) {
      msg.setContextHash(list.contextHash);
    }

    if (list.color) {
      msg.setColor(list.color);
    }

    if (list.deniedPasses) msg.setDeniedPassCount(list.deniedPasses);

    if (list.location) {
      msg.setLocation(list.location);
    }

    msg.setListPriority(list.priority || 0);

    return msg;
  };

  export const fromProto = (
    list: membership_list_pb.MembershipList,
  ): IMembershipList => {
    const listType = list.getListType()
      ? $enum(MembershipListType).asValueOrThrow(list.getListType())
      : undefined;

    return {
      id: list.getListId(),
      name: list.getListName(),
      assetPath: list.getListAsset(),
      bannerHash: list.getListBanner(),
      bannerImage: list.getBannerImage(),
      memberCount: list.getMemberCount(),
      priority: list.getListPriority(),
      active: list.getListActive(),
      members: list.getMembersList(),
      contextHash: list.getContextHash(),
      color: list.getColor(),
      listType,
      deniedPasses: list.getDeniedPassCount(),
      location: list.getLocation(),
    };
  };
}
