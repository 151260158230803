import { MingaAppMainRoutes } from '@app/src/app/types';

import {
  BehaviorManagerRoutes,
  BehaviorReportsRoutes,
} from '@modules/behavior-manager';
import { CheckinManagerRoutes } from '@modules/checkin-manager';
import {
  FlexTimeManagerRoutes,
  FlexTimeReportsRoutes,
} from '@modules/flex-time-manager';
import { HpmRoutes } from '@modules/hallpass-manager';
import { HomeRoute } from '@modules/home/constants';
import { IdRoute } from '@modules/id';
import { PeopleRoute } from '@modules/people/types';
import { ToolsRoutes } from '@modules/tools';

export const REDIRECT_ROUTES: MingaAppMainRoutes = [
  // My Tools redirect
  {
    path: 'tools/my-tools',
    pathMatch: 'full',
    redirectTo: ToolsRoutes.ROOT,
  },
  {
    path: 'tools/flex-time',
    pathMatch: 'full',
    redirectTo: `${ToolsRoutes.ROOT}/${ToolsRoutes.FLEX_TIME}`,
  },
  {
    path: 'user-preferences',
    pathMatch: 'full',
    redirectTo: `${ToolsRoutes.ROOT}/${ToolsRoutes.PREFERENCES}`,
  },

  // Home redirect
  {
    path: '',
    pathMatch: 'full',
    redirectTo: HomeRoute.ROOT,
  },
  {
    path: 'admin/challenge~polls/challenges',
    pathMatch: 'full',
    redirectTo: `${HomeRoute.ROOT}/${HomeRoute.CHALLENGES}`,
  },
  {
    path: 'admin/challenge~polls/polls',
    pathMatch: 'full',
    redirectTo: `${HomeRoute.ROOT}/${HomeRoute.POLLS}`,
  },
  {
    path: 'profile',
    pathMatch: 'full',
    redirectTo: `${HomeRoute.ROOT}/${HomeRoute.MY_CONTENT}`,
  },
  {
    path: 'moderation/minga',
    pathMatch: 'full',
    redirectTo: `${HomeRoute.ROOT}/${HomeRoute.MODERATION}`,
  },
  {
    path: 'moderation/minga/ugc',
    pathMatch: 'full',
    redirectTo: `${HomeRoute.ROOT}/${HomeRoute.MODERATION}/${HomeRoute.MODERATION_REPORTS}`,
  },
  {
    path: 'moderation/minga/dm',
    pathMatch: 'full',
    redirectTo: `${HomeRoute.ROOT}/${HomeRoute.MODERATION}/${HomeRoute.MODERATION_DIRECT_MESSAGES}`,
  },
  {
    path: 'minga-files',
    pathMatch: 'full',
    redirectTo: `${HomeRoute.ROOT}/${HomeRoute.FILES}`,
  },
  {
    path: 'gallery',
    pathMatch: 'full',
    redirectTo: `${HomeRoute.ROOT}/${HomeRoute.GALLERY}`,
  },

  // People redirect
  {
    path: 'people-manager',
    pathMatch: 'full',
    redirectTo: PeopleRoute.ROOT,
  },
  {
    path: 'people-manager/manage',
    pathMatch: 'full',
    redirectTo: PeopleRoute.ROOT,
  },
  {
    path: 'people-manager/user-lists',
    pathMatch: 'full',
    redirectTo: `${PeopleRoute.ROOT}/${PeopleRoute.USER_LISTS}`,
  },
  {
    path: 'people-manager/roster',
    pathMatch: 'full',
    redirectTo: `${PeopleRoute.ROOT}/${PeopleRoute.ROSTERING}`,
  },

  // ID redirect (old)
  {
    path: 'studentid/manage',
    pathMatch: 'full',
    redirectTo: IdRoute.ROOT,
  },

  // ID redirect (new)
  {
    path: 'studentid',
    pathMatch: 'prefix',
    redirectTo: 'id',
  },

  // Hall pass redirect
  {
    path: 'hall-pass/dashboard',
    pathMatch: 'full',
    redirectTo: HpmRoutes.ROOT,
  },

  // Behavior redirect
  {
    path: 'pbis/dashboard',
    pathMatch: 'full',
    redirectTo: BehaviorManagerRoutes.ROOT,
  },
  {
    path: 'pbis/reports/pbis_summary',
    pathMatch: 'full',
    redirectTo: `${BehaviorManagerRoutes.ROOT}/${BehaviorManagerRoutes.REPORTS}/${BehaviorReportsRoutes.SUMMARY}`,
  },
  {
    path: 'pbis/reports/pbis_types',
    pathMatch: 'full',
    redirectTo: `${BehaviorManagerRoutes.ROOT}/${BehaviorManagerRoutes.REPORTS}/${BehaviorReportsRoutes.TYPES}`,
  },
  {
    path: 'pbis/reports/pbis_staff',
    pathMatch: 'full',
    redirectTo: `${BehaviorManagerRoutes.ROOT}/${BehaviorManagerRoutes.REPORTS}/${BehaviorReportsRoutes.STAFF}`,
  },
  {
    path: 'pbis/reports/pbis_student',
    pathMatch: 'full',
    redirectTo: `${BehaviorManagerRoutes.ROOT}/${BehaviorManagerRoutes.REPORTS}/${BehaviorReportsRoutes.STUDENTS}`,
  },
  {
    path: 'pbis/reports/pbis_history',
    pathMatch: 'full',
    redirectTo: `${BehaviorManagerRoutes.ROOT}/${BehaviorManagerRoutes.REPORTS}/${BehaviorReportsRoutes.HISTORY}`,
  },
  {
    path: 'pbis/reports/pbis_automation_counter',
    pathMatch: 'full',
    redirectTo: `${BehaviorManagerRoutes.ROOT}/${BehaviorManagerRoutes.REPORTS}/${BehaviorReportsRoutes.AUTOMATION_COUNTER}`,
  },
  {
    path: 'pbis/reports/pbis_cons_history',
    pathMatch: 'full',
    redirectTo: `${BehaviorManagerRoutes.ROOT}/${BehaviorManagerRoutes.REPORTS}/${BehaviorReportsRoutes.CONSEQUENCE_HISTORY}`,
  },
  {
    path: 'pbis/reports/pbis_cons_types',
    pathMatch: 'full',
    redirectTo: `${BehaviorManagerRoutes.ROOT}/${BehaviorManagerRoutes.REPORTS}/${BehaviorReportsRoutes.CONSEQUENCE_TYPES}`,
  },
  {
    path: 'pbis/reports/pbis_cons_overdue',
    pathMatch: 'full',
    redirectTo: `${BehaviorManagerRoutes.ROOT}/${BehaviorManagerRoutes.REPORTS}/${BehaviorReportsRoutes.CONSEEQUENCE_OVERDUE}`,
  },
  {
    path: 'pbis/behaviors/behaviors',
    pathMatch: 'full',
    redirectTo: `${BehaviorManagerRoutes.ROOT}/${BehaviorManagerRoutes.BEHAVIORS}`,
  },
  {
    path: 'pbis/behaviors/consequences',
    pathMatch: 'full',
    redirectTo: `${BehaviorManagerRoutes.ROOT}/${BehaviorManagerRoutes.CONSEQUENCES}`,
  },
  {
    path: 'pbis/behaviors/automations',
    pathMatch: 'full',
    redirectTo: `${BehaviorManagerRoutes.ROOT}/${BehaviorManagerRoutes.AUTOMATIONS}`,
  },
  {
    path: 'pbis/settings',
    pathMatch: 'full',
    redirectTo: `${BehaviorManagerRoutes.ROOT}/${BehaviorManagerRoutes.SETTINGS}`,
  },

  // Check in redirect
  {
    path: 'checkin/dashboard',
    pathMatch: 'full',
    redirectTo: CheckinManagerRoutes.ROOT,
  },

  // FlexTime redirect
  {
    path: 'flex-time-manager/dashboard',
    pathMatch: 'full',
    redirectTo: FlexTimeManagerRoutes.ROOT,
  },
  {
    path: 'flex-time-manager/reports/flex_period_registered',
    pathMatch: 'full',
    redirectTo: `${FlexTimeManagerRoutes.ROOT}/${FlexTimeManagerRoutes.REPORTS}/${FlexTimeReportsRoutes.HISTORY}`,
  },
  {
    path: 'flex-time-manager/reports/flex_period_unregistered',
    pathMatch: 'full',
    redirectTo: `${FlexTimeManagerRoutes.ROOT}/${FlexTimeManagerRoutes.REPORTS}/${FlexTimeReportsRoutes.UNREGISTERED}`,
  },
  {
    path: 'flex-time-manager/periods',
    pathMatch: 'full',
    redirectTo: `${FlexTimeManagerRoutes.ROOT}/${FlexTimeManagerRoutes.PERIODS}`,
  },
  {
    path: 'flex-time-manager/activities',
    pathMatch: 'full',
    redirectTo: `${FlexTimeManagerRoutes.ROOT}/${FlexTimeManagerRoutes.ACTIVITIES}`,
  },
  {
    path: 'flex-time-manager/activity-templates',
    pathMatch: 'full',
    redirectTo: `${FlexTimeManagerRoutes.ROOT}/${FlexTimeManagerRoutes.ACTIVITY_TEMPLATES}`,
  },
  {
    path: 'flex-time-manager/settings',
    pathMatch: 'full',
    redirectTo: `${FlexTimeManagerRoutes.ROOT}/${FlexTimeManagerRoutes.SETTINGS}`,
  },
];
