import { Injectable } from '@angular/core';

import { answer_pb, answer_ng_grpc_pb } from 'libs/generated-grpc-web';

import { ContentEvents } from '@app/src/app/minimal/services/ContentEvents';
import { StreamManager } from '@app/src/app/minimal/services/StreamManager';

@Injectable({ providedIn: 'root' })
export class PollAnswerStateService {
  private _pollAnswers: Map<string, number>;

  constructor(
    private pollAnswer: answer_ng_grpc_pb.PollAnswer,
    private streamManager: StreamManager,
    private contentEvents: ContentEvents,
  ) {
    this._pollAnswers = new Map();
  }

  storeAnswers(pollAnswers: Map<string, number>) {
    this._pollAnswers = pollAnswers;
  }

  async setAnswer(pollContextHash: string, choiceId: number) {
    if (!this._pollAnswers.has(pollContextHash)) {
      this._pollAnswers.set(pollContextHash, choiceId);
    }
  }

  hasAnswer(pollContextHash: string): boolean {
    return this._pollAnswers.has(pollContextHash);
  }

  getAnswer(pollContextHash: string): number {
    return this._pollAnswers.get(pollContextHash) || 0;
  }

  async answerPoll(
    pollContextHash: string,
    choiceId: number,
  ): Promise<answer_pb.PollResults> {
    const setPollAnswer = new answer_pb.SetPollAnswer();
    setPollAnswer.setPollContentContextHash(pollContextHash);
    setPollAnswer.setPollChoiceId(choiceId);
    this._pollAnswers.set(pollContextHash, choiceId);
    const results = await this.pollAnswer.set(setPollAnswer).catch(err => {
      this._pollAnswers.delete(pollContextHash);
      throw err;
    });

    await this.streamManager
      .restartStreamIfAvailable('Polls')
      .catch(console.error);
    this.contentEvents.emitPollAnswer(pollContextHash);
    return results;
  }

  async clear() {
    this._pollAnswers = new Map();
  }
}
