import { Validators } from '@angular/forms';

import { AutomationPayload } from 'libs/domain';

export enum BmTypesAutomationMessages {
  TABLE_TITLE = 'Automations',

  COLUMN_TITLE_NAME = 'Name',
  COLUMN_TITLE_STATUS = 'Status',
  COLUMN_TITLE_EDIT = 'Edit',

  EMPTY_STATE_TITLE = 'No automations exist',
  EMPTY_STATE_ACTION_BUTTON = 'Create automation',

  EMPTY_DISABLED_TITLE = 'Automations are disabled',
  EMPTY_DISABLED_DESC = 'You must enable consequences in settings in order to use them',

  BUTTON_RESET_AUTOMATIONS = 'Update counters',

  TOOLTIP = 'Allows you to automate assigning consequences based on the number of behaviors received',

  INACTIVE_GROUP = 'One or more automation groups are currently deactivated',

  BUTTON_CREATE = 'Create automation group',
}

export enum BmTypesAutomationEditMessages {
  MODAL_TITLE_NEW = 'Create An Automation',
  MODAL_TITLE_EXISTING = 'Edit Automation',

  BUTTON_LABEL_CREATE = 'Create',
  BUTTON_LABEL_SAVE = 'Save',
  BUTTON_LABEL_DELETE = 'Delete',
  BUTTON_LABEL_CANCEL = 'Cancel',
  BUTTON_LABEL_RESET_COUNTER = 'Reset counter',
  BUTTON_LABEL_RESET_STUDENT = 'Reset student',

  FIELD_LABEL_NAME = 'Automation name',
  FIELD_LABEL_DESCRIPTION = 'Internal description',
  FIELD_LABEL_NOTE = 'Note to student (optional)',
  FIELD_LABEL_CONSEQUENCE = 'Consequence to assign',
  FIELD_LABEL_COMPLETE_BY = 'Complete by (days)',
  FIELD_LABEL_THRESHOLD = 'Behavior threshold',
  FIELD_LABEL_BEHAVIORS = 'Behaviors',
  FIELD_LABEL_RESET_COUNTER = 'Repeat each time threshold is met',

  TOOLTIP_RESET_COUNTER = 'Repeat this automation each time the threshold is met. If turned off, this automation will only run once per user, until it is reset.',
  TOOLTIP_THRESHOLD = 'Run this automation when this threshold is met',
}

export const BM_TYPES_AUTOMATIONS_TABLE_COLUMNS: string[] = [
  'icon',
  'name',
  'active',
  'edit',
];

export enum BmTypesAutomationEditFormFields {
  NAME = 'name',
  DESCRIPTION = 'description',
  NOTE = 'note',
  COMPLETE_BY = 'completeByDays',
  THRESHOLD = 'threshold',
  TRIGGER_TYPES = 'triggerTypes',
  TRIGGER_BY_TYPES = 'triggerByTypes',
  RESET_COUNTER = 'resetCounter',
}

export const BM_TYPES_AUTOMATIONS_FORMGROUP = {
  [BmTypesAutomationEditFormFields.NAME]: [
    '',
    [Validators.required, Validators.minLength(1), Validators.maxLength(75)],
  ],
  [BmTypesAutomationEditFormFields.DESCRIPTION]: [
    '',
    [Validators.required, Validators.minLength(1), Validators.maxLength(250)],
  ],
  [BmTypesAutomationEditFormFields.NOTE]: [
    '',
    [Validators.minLength(1), Validators.maxLength(125)],
  ],
  [BmTypesAutomationEditFormFields.COMPLETE_BY]: [
    0,
    [Validators.min(0), Validators.max(100)],
  ],
  [BmTypesAutomationEditFormFields.THRESHOLD]: [
    0,
    [Validators.min(0), Validators.max(100)],
  ],
  [BmTypesAutomationEditFormFields.TRIGGER_TYPES]: [
    undefined,
    [Validators.required],
  ],
  [BmTypesAutomationEditFormFields.TRIGGER_BY_TYPES]: [
    [],
    [Validators.required, Validators.min(1)],
  ],
  [BmTypesAutomationEditFormFields.RESET_COUNTER]: [false, []],
};

export const DEFAULT_BEHAVIOR_AUTOMATION: AutomationPayload = {
  active: false,
};
