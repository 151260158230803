import { RosteringMethod } from '../';
import { SisIdFieldKey } from '../';

/**
 * Generic OneRoster Configuration
 */
export type GenericOneRosterConfig = {
  baseUrl: string;
  clientId: string;
  clientSecret: string;
  authUrl: string;
  studentUrl?: string;
  teacherUrl?: string;
  schoolIds: string[];
  sisType: SisType.OTHER;
};

export type AeriesOneRosterConfig = {
  baseUrl: string;
  clientId: string;
  clientSecret: string;
  sisType: SisType.AERIES;
};
export type SkywardOneRosterConfig = {
  baseUrl: string;
  clientId: string;
  clientSecret: string;
  schoolIds: string[];
  sisType: SisType.SKYWARD;
};

export type InfiniteCampusOneRosterConfig = {
  baseUrl: string;
  clientId: string;
  clientSecret: string;
  applicationId: string;
  sisType: SisType.INFINITE_CAMPUS;
};

export type QOneRosterConfig = {
  baseUrl: string;
  clientId: string;
  clientSecret: string;
  sisType: SisType.Q;
};

export type CleverOneRosterConfig = {
  baseUrl: string;
  clientId: string;
  clientSecret: string;
  schoolIds: string[];
  sisType: SisType.CLEVER;
  districtId: string;
};

/**
 * Classlink specific configuration
 */
export type ClassLinkOneRosterConfig = {
  accessToken: string;
  applicationId: string;
  sisType: SisType.CLASSLINK;
  schoolIds: string[];
};

export type RosterstreamOneRosterConfig = {
  dataSourceId: string;
  clientId: string; //publisherID
  clientSecret: string;
  sisType: SisType.ROSTERSTREAM;
  schoolIds: string[];
};
/**
 * Configuration values all SIS types have.
 */
export type SisConfig = {
  active: boolean;
  studentRostering: boolean;
  teacherRostering: boolean;
  staffRostering: boolean;
  adminRostering: boolean;
  parentRostering: boolean;
  classListRostering: boolean;
  updateExisting: boolean;
  archiveMissing: boolean;
  updateExistingStudents: boolean;
  updateExistingStaff: boolean;
  updateExistingTeachers: boolean;
  updateExistingAdmins: boolean;
  archiveMissingStudents: boolean;
  archiveMissingStaff: boolean;
  archiveMissingTeachers: boolean;
  archiveMissingAdmins: boolean;
  notificationEmails: string[];
  idNumberFieldName: SisIdFieldKey;
  schoolIds: string[];
  termSourcedIds: string[];
  grades: string[];
  url: string;
  clientId: string;
  clientSecret: string;
  authUrl: string;
  sisType: string;
};

export enum SisType {
  Q = 'Q',
  //POWERSCHOOL = 'PowerSchool',
  CLASSLINK = 'ClassLink',
  CLEVER = 'Clever',
  AERIES = 'Aeries',
  INFINITE_CAMPUS = 'Infinite Campus',
  OTHER = 'Other',
  //GENESIS = 'Genesis',
  SKYWARD = 'Skyward',
  //SYNERGY = 'Synergy',
  ROSTERSTREAM = 'MingaSync',
}

export type SisMethod = SisType | RosteringMethod;

export enum SisUserAccountTypes {
  TEACHER = 'Teacher',
  STUDENT = 'Student',
  ADMIN = 'Admin',
  ADMINISTRATOR = 'Administrator',
  PROCTOR = 'Proctor',
  RELATIVE = 'Relative',
  STAFF = 'Staff',
  PARENT = 'Parent',
}
