import { SelectionModel } from '@angular/cdk/collections';
import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import {
  SystemAlertCloseEvents,
  SystemAlertModalService,
  SystemAlertModalType,
} from '@shared/components/system-alert-modal';
import { SystemAlertSnackBarService } from '@shared/components/system-alert-snackbar';

@Injectable({ providedIn: 'root' })
export abstract class ReportActionService<TableData> {
  /** Observables */
  public selectionCount$: Observable<number>;

  /** Selection Model */
  protected readonly _selection = new SelectionModel(true, []);

  /** Other */
  private _totalSelectable: number;

  get selectionSize(): number {
    return this._selection.selected.length;
  }

  constructor(
    private _snackbar: SystemAlertSnackBarService,
    private _alertModal: SystemAlertModalService,
  ) {
    this.selectionCount$ = this._selection.changed.pipe(
      switchMap(() => of(this._selection.selected.length)),
    );
  }

  set totalSelectable(total: number) {
    this._totalSelectable = total;
  }

  abstract setTotalSelectable(total: number): void;

  public getSelection(): TableData[] {
    return this._selection.selected;
  }

  public isSelected(data: TableData): boolean {
    return this._selection.isSelected(data);
  }

  public toggle(data: TableData) {
    this._selection.toggle(data);
  }

  public select(...data: TableData[]) {
    this._selection.select(...data);
  }

  public selectAll(allData: TableData[]): void {
    this._selection.select(...allData);
  }

  public deselect(...data: TableData[]) {
    this._selection.deselect(...data);
  }

  public masterToggle(allData: TableData[]): void {
    if (!this.isEmpty()) {
      this._selection.clear();
    } else {
      this._selection.select(...allData);
    }
  }

  public isAllSelected(): boolean {
    return this._totalSelectable === this._selection.selected.length;
  }

  public clearSelection() {
    this._selection.clear();
  }

  public isEmpty(): boolean {
    return this._selection.isEmpty();
  }

  public isSelectionValid(): boolean {
    return this._selection.selected.length > 0;
  }

  public getSelectableCount(): number {
    return this._totalSelectable;
  }

  /** Other Methods */
  public openSnackBar(
    message: string,
    type: 'error' | 'success' | 'default' = 'default',
  ) {
    this._snackbar.open({
      message,
      type,
    });
  }

  public async openAlertModal(
    heading: string,
    message: string,
    confirmActionBtn?: string,
    closeBtn?: string,
  ): Promise<boolean> {
    if (!closeBtn) closeBtn = 'Cancel';
    const modalRef = await this._alertModal.open({
      heading,
      message,
      modalType: SystemAlertModalType.WARNING,
      confirmActionBtn,
      closeBtn,
    });

    const response = await modalRef.afterClosed().toPromise();
    return response ? response?.type === SystemAlertCloseEvents.CONFIRM : false;
  }
}
