import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { PointsHistoryIconModule } from '@app/src/app/components/PointsHistoryIcon';
import { MgPipesModule } from '@app/src/app/pipes';

import { MgNotificationBaseModule } from '../MgNotificationBase';
import { MgPointsNotificationElement } from './MgPointsNotification.element';

@NgModule({
  imports: [
    // Minga dependencies
    MgNotificationBaseModule,
    MgPipesModule,
    PointsHistoryIconModule,
    // External dependencies
    CommonModule,
  ],
  declarations: [MgPointsNotificationElement],
  exports: [MgPointsNotificationElement],
})
export class MgPointsNotificationModule {}
