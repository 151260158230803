import { Injectable, OnDestroy } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';

import { RosteringMethod, SisSyncLogErrorType } from 'libs/domain';
import { gateway, roster_pb } from 'libs/generated-grpc-web';
import { BehaviorSubject, ReplaySubject, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { $enum } from 'ts-enum-util';

import { MingaManagerService } from '@app/src/app/services/MingaManager';

import { ConfirmationDialogComponent } from '@shared/components/confirmation-dialog';
import {
  SystemAlertModalService,
  SystemAlertModalType,
} from '@shared/components/system-alert-modal';
import { SystemAlertSnackBarService } from '@shared/components/system-alert-snackbar';

import { SisSyncErrorDescription } from '../constants';
import { PeopleRosteringMessages } from '../constants/people-rostering.constants';

@Injectable({ providedIn: 'root' })
export class PeopleRosteringService implements OnDestroy {
  public readonly MESSAGES = PeopleRosteringMessages;

  private _rosteringFormGroup = new UntypedFormGroup({
    rosteringMethod: new UntypedFormControl(RosteringMethod.ONE_ROSTER, [
      Validators.required,
    ]),
    methodStatus: new UntypedFormControl(false),
  });
  private _destroyed$ = new ReplaySubject<void>(1);
  private _sisMethods: gateway.minga_pb.sisFields[];

  private _rosteringSisValues = new BehaviorSubject<
    Record<RosteringMethod, gateway.minga_pb.sisFields>
  >({
    [RosteringMethod.ONE_ROSTER]: undefined,
    [RosteringMethod.SFTP]: undefined,
    [RosteringMethod.SIS_API]: undefined,
  });
  public rosteringSisValuesObs = this._rosteringSisValues.asObservable();
  public hasSavedSftpBefore = false;
  private _sisError = new BehaviorSubject<SisSyncLogErrorType>(null);
  public sisErrorObs = this._sisError.asObservable();

  constructor(
    private _dialog: MatDialog,
    private _mingaManager: MingaManagerService,
    private _publisher: gateway.publisher_ng_grpc_pb.Publisher,
    private _snackBar: SystemAlertSnackBarService,
    private _systemAlertModal: SystemAlertModalService,
  ) {
    this.rosteringFormGroup.controls.methodStatus.valueChanges
      .pipe(takeUntil(this._destroyed$))
      .subscribe(async status => {
        if (status) {
          await this._activateSelectedRosteringMethod();
        } else {
          await this._launchDeactivateRosteringPrompt();
        }
      });
  }

  get rosteringFormGroup() {
    return this._rosteringFormGroup;
  }

  get oneRosterSisValuesObs() {
    return this.rosteringSisValuesObs.pipe(
      map(methods => {
        return methods.ONE_ROSTER;
      }),
    );
  }

  get sftpSisValuesObs() {
    return this.rosteringSisValuesObs.pipe(
      map(methods => {
        return methods.SFTP;
      }),
    );
  }

  ngOnDestroy() {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  public async initRosteringSisValues() {
    this._sisMethods = await this._mingaManager.getSIS();
    for (const sisFields of this._sisMethods) {
      const rosteringMethod = $enum(RosteringMethod).asValueOrThrow(
        sisFields.getRosteringMethod(),
      );
      if (rosteringMethod === RosteringMethod.SFTP) {
        this.hasSavedSftpBefore = true;
      }
      const rosteringSisValues = { ...this._rosteringSisValues.value };
      rosteringSisValues[rosteringMethod] = sisFields;
      this._rosteringSisValues.next(rosteringSisValues);

      const controls = this._rosteringFormGroup.controls;
      controls.rosteringMethod.setValue(rosteringMethod);

      if (sisFields.getActive()) controls.methodStatus.setValue(true);
    }
    const sisError = await this._mingaManager.getSISError();
    if (sisError) {
      this._sisError.next($enum(SisSyncLogErrorType).asValueOrThrow(sisError));
    }
  }

  public clearRosteringSisValues(rosteringMethod: RosteringMethod) {
    const rosteringSisValues = { ...this._rosteringSisValues.value };
    rosteringSisValues[rosteringMethod] = undefined;
    this._rosteringSisValues.next(rosteringSisValues);
  }

  public async handleViewError(error: SisSyncLogErrorType) {
    const dialogRef = await this._systemAlertModal.open({
      modalType: SystemAlertModalType.ERROR,
      heading: SisSyncErrorDescription[error].title,
      message: SisSyncErrorDescription[error].description,
      // Skip here just means don't do mass delete, we already have done the rest of the sync
      closeBtn: 'Skip this sync',
      confirmActionBtn: 'Proceed with sync',
    });

    dialogRef
      .afterClosed()
      .pipe(takeUntil(this._destroyed$))
      .subscribe(res => {
        if (res.type === 'CONFIRM') {
          const request = new gateway.minga_pb.TriggerSISSyncRequest();
          request.setMassDelete(SisSyncErrorDescription[error].overrideKey);
          this._mingaManager.triggerSISSync(request);
          this._snackBar.success(SisSyncErrorDescription[error].successMsg);
        }
        this._mingaManager.acknowledgeSISError();
      });
  }

  private async _launchDeactivateRosteringPrompt() {
    const confirmationDialog = this._dialog.open(ConfirmationDialogComponent, {
      data: {
        text: {
          description: this.MESSAGES.ROSTERING_DISABLE_MSG,
          deleteBtn: 'Disable',
        },
      },
    });
    confirmationDialog.afterClosed().subscribe(async res => {
      if (res.confirmed) {
        await this._mingaManager.deactivateAllRosteringMethods();
      } else {
        this._rosteringFormGroup.controls.methodStatus.setValue(true);
      }
    });
  }

  private async _activateSelectedRosteringMethod() {
    const formControl = this._rosteringFormGroup.controls.rosteringMethod;
    const rosteringMethod: RosteringMethod = formControl.value;
    await this._mingaManager.activateRosteringMethod(rosteringMethod);
  }
}
