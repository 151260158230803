import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';

import { IMembershipList, MembershipListType } from 'libs/domain';
import { mingaSettingTypes } from 'libs/util';
import { Observable, ReplaySubject, from } from 'rxjs';

import { ListMembershipService } from '@app/src/app/services/ListMembership';
import { MingaSettingsService } from '@app/src/app/store/Minga/services';

import { LayoutService } from '@modules/layout/services';
import { PointsManagerMessages } from '@modules/points-manager/constants';

import { PmSettingsMessages } from './constants';

@Component({
  selector: 'mg-pm-settings',
  templateUrl: './pm-settings.component.html',
  styleUrls: ['./pm-settings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PmSettingsComponent implements OnInit, OnDestroy {
  lists$: Observable<IMembershipList[]>;

  /** Constants */
  public readonly PM_MESSAGES = PointsManagerMessages;
  public readonly MESSAGES = PmSettingsMessages;
  public readonly MINGA_SETTINGS = mingaSettingTypes;
  public readonly LIST_TYPES = [
    MembershipListType.POINTS_MANAGER,
    MembershipListType.POINTS_REPORT_VIEWER,
  ];

  private _destroyed$ = new ReplaySubject<void>(1);

  /** Observables */
  isLeaderboardEnabled$: Observable<boolean>;
  areRewardsEnabled$: Observable<boolean>;

  /** Component Constructor */
  constructor(
    public layout: LayoutService,
    public settingService: MingaSettingsService,
    private _cdr: ChangeDetectorRef,
    private _membershipService: ListMembershipService,
  ) {
    this.isLeaderboardEnabled$ = this.settingService.getSettingValueObs(
      mingaSettingTypes.FEATURE_TRACKING_PUBLIC_LEADERBOARD,
    );
    this.areRewardsEnabled$ = this.settingService.getSettingValueObs(
      mingaSettingTypes.PM_REWARDS_STORE,
    );
  }

  ngOnInit(): void {
    this.initPointsSettingsLists();
  }

  ngOnDestroy() {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  initPointsSettingsLists() {
    const types = [
      MembershipListType.POINTS_MANAGER,
      MembershipListType.POINTS_REPORT_VIEWER,
    ];
    this.lists$ = from(
      this._membershipService.getOrCreateMembershipLists(types),
    );
    this._cdr.markForCheck();
  }

  reloadLists() {
    this.initPointsSettingsLists();
  }

  public async onEnablePublicLeaderboardChange(state: boolean) {
    if (!state) {
      await this._updatePMSetting(
        mingaSettingTypes.PBIS_INDIVIDUAL_POINTS,
        false,
      );
      await this._updatePMSetting(mingaSettingTypes.PBIS_TEAM_POINTS, false);
    }
  }

  public async onRewardsEnabledChange(isEnabled: boolean) {
    if (!isEnabled) {
      await this.settingService.updateSetting(
        mingaSettingTypes.PM_TEACHERS_REWARDS,
        false,
      );

      await this.settingService.updateSetting(
        mingaSettingTypes.PM_STAFF_REWARDS,
        false,
      );
    }
  }

  private async _updatePMSetting(
    setting: mingaSettingTypes,
    value: boolean | string,
  ) {
    await this.settingService.updateSetting(setting, value);
  }
}
