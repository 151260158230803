import { mingaSettingTypes } from 'libs/util';

import { IdSettings } from '../types';

export enum ViewIdRoutes {
  ROOT = 'view-id',
}

export enum ViewIdMessage {
  INACTIVE_ID = 'Inactive ID',
  INACTIVE_ID_MESSAGE = 'Your ID card is currently locked',
  INACTIVE_ID_SUBMESSAGE = 'Plase see a school administrator for more information',
  PRINT = 'Print',
}

export const DEFAULT_STUDENT_ID_SETTINGS: IdSettings = {
  idSchoolName: '',
  idMingaStudentId: false,
  idLogoAssetPath: '',
  idBackgroundColor: '#1C2F59',
  idFontColor: '#FFFFFF',
  idClockFontSize: 0,
  idBarcodeType: 'CODE128',
  idShowRole: false,
  idShowGrade: false,
  idUploadTempPhoto: false,
  idStaffChangePicture: false,
  idShowEnglishSuicideLifeline: true,
  idShowSpanishSuicideLifeline: false,
  idShowDomesticViolenceHotline: true,
  idShowCustomLifeline: '',
  idEnableGradeColors: false,
  idGradeColors: {},
  idHideNum: false,
  idHideNumOnId: false,
  idEnableOffline: false,
  idShowIdField1: false,
  idShowIdField2: false,
  passStudentsEndPasses: false,
  idCardLayout: 'mingasso',
};

export const ID_SETTINGS_MAP: Record<string, keyof IdSettings> = {
  [mingaSettingTypes.ID_SCHOOL_NAME]: 'idSchoolName',
  [mingaSettingTypes.ID_BACKGROUND_COLOR]: 'idBackgroundColor',
  [mingaSettingTypes.ID_FONT_COLOR]: 'idFontColor',
  [mingaSettingTypes.ID_CLOCK_FONT_SIZE]: 'idClockFontSize',
  [mingaSettingTypes.ID_BARCODE_TYPE]: 'idBarcodeType',
  [mingaSettingTypes.ID_LOGO_ASSET_PATH]: 'idLogoAssetPath',
  [mingaSettingTypes.ID_MINGA_STUDENT_ID]: 'idMingaStudentId',
  [mingaSettingTypes.ID_SHOW_GRADE]: 'idShowGrade',
  [mingaSettingTypes.ID_SHOW_ROLE]: 'idShowRole',
  [mingaSettingTypes.ID_UPLOAD_TEMP_PHOTO]: 'idUploadTempPhoto',
  [mingaSettingTypes.ID_STAFF_CHANGE_PICTURE]: 'idStaffChangePicture',
  [mingaSettingTypes.ID_SHOW_ENGLISH_SUICIDE_LIFELINE]:
    'idShowEnglishSuicideLifeline',
  [mingaSettingTypes.ID_SHOW_SPANISH_SUICIDE_LIFELINE]:
    'idShowSpanishSuicideLifeline',
  [mingaSettingTypes.ID_SHOW_DOMESTIC_VIOLENCE_HOTLINE]:
    'idShowDomesticViolenceHotline',
  [mingaSettingTypes.ID_SHOW_CUSTOM_LIFELINE]: 'idShowCustomLifeline',
  [mingaSettingTypes.ID_ENABLE_GRADE_COLORS]: 'idEnableGradeColors',
  [mingaSettingTypes.ID_GRADE_COLORS]: 'idGradeColors',
  [mingaSettingTypes.HIDE_ID_NUM]: 'idHideNum',
  [mingaSettingTypes.HIDE_ID_NUM_ON_IDS]: 'idHideNumOnId',
  [mingaSettingTypes.ID_ENABLE_OFFLINE]: 'idEnableOffline',
  [mingaSettingTypes.PASS_STUDENTS_END_PASSES]: 'passStudentsEndPasses',
  [mingaSettingTypes.ID_SHOW_ID_FIELD_1]: 'idShowIdField1',
  [mingaSettingTypes.ID_SHOW_ID_FIELD_2]: 'idShowIdField2',
  [mingaSettingTypes.ID_CARD_LAYOUT]: 'idCardLayout',
};

export enum ViewIdCodeFormats {
  BAR_128 = 'CODE128',
  BAR_39 = 'CODE39',
  QR = 'QR Code',
}

export const STUDENT_ID_STORAGE_KEY = 'minga_student_id';
export const STUDENT_ID_PHOTO_STORAGE_KEY = 'studentIdImage';
