import { Injectable, OnDestroy } from '@angular/core';

import { Checkin, EditableCheckin } from 'libs/domain';
import { ReplaySubject } from 'rxjs';

import { RootService } from '@app/src/app/minimal/services/RootService';

import { SystemAlertModalService } from '@shared/components/system-alert-modal';
import { SystemAlertSnackBarService } from '@shared/components/system-alert-snackbar';
import { ReportActionService } from '@shared/services/reports';

import { CheckinManagerReportsService } from '../checkin-manager-reports.service';
import { CheckinManagerHistoryMessages } from '../components/checkin-manager-report-history/checkin-manager-report-history.constants';

@Injectable()
export class CheckinManagerHistoryActionService
  extends ReportActionService<Checkin>
  implements OnDestroy
{
  private _destroyed = new ReplaySubject<void>(1);

  constructor(
    public snackbar: SystemAlertSnackBarService,
    public alertModal: SystemAlertModalService,
    private _rootService: RootService,
    private _cmReportsService: CheckinManagerReportsService,
  ) {
    super(snackbar, alertModal);
  }

  ngOnDestroy(): void {
    this._destroyed.next();
    this._destroyed.complete();
  }

  /** Abstract methods */
  public setTotalSelectable(total: number): void {
    this.totalSelectable = total;
  }

  /** Action methods */
  public async archiveCheckin(): Promise<boolean> {
    if (!this.isSelectionValid()) return;
    try {
      const confirmed = await this.openAlertModal(
        CheckinManagerHistoryMessages.ARCHIVE_HEADING,
        '',
        CheckinManagerHistoryMessages.ARCHIVE_LABEL,
      );

      if (confirmed) {
        const data = this.getSelection() as EditableCheckin[];
        await this._cmReportsService.archiveCheckin(data);
      }
      this._rootService.addLoadingPromise(this._clearSelectionAndRefetch());
      return true;
    } catch (e) {
      this.openSnackBar(`Unable to archive consequence(s): ${e}`, 'error');
      return false;
    }
  }

  public getSelectionMessage(): string {
    if (this.isEmpty) return '';

    const selection = this.getSelection();
    const message = `${selection.length} selected`;
    return message;
  }

  private async _clearSelectionAndRefetch() {
    this._cmReportsService.applyFilter();
    this.clearSelection();
  }
}
