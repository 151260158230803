export enum EventCategoryEnum {
  ACTIVITIES = 'ACTIVITIES',
  ATHLETICS = 'ATHLETICS',
  PERFORMING_ARTS = 'PERFORMING_ARTS',
  OTHER = 'OTHER',
}

const eventCategoryColorMap = {
  [EventCategoryEnum.ACTIVITIES]: '#4DC4CE',
  [EventCategoryEnum.ATHLETICS]: '#d2b532',
  [EventCategoryEnum.PERFORMING_ARTS]: '#df3d94',
  [EventCategoryEnum.OTHER]: '#78d22e',
};

export function eventCategoryPrettyText(category: EventCategoryEnum) {
  switch(category) {
    case EventCategoryEnum.ACTIVITIES: return 'Activities';
    case EventCategoryEnum.ATHLETICS: return 'Athletics';
    case EventCategoryEnum.PERFORMING_ARTS: return 'Performing Arts';
    case EventCategoryEnum.OTHER:
    default: return 'Other';
  }
}

export function eventCategoryColor(eventCategory: EventCategoryEnum): string {
  return eventCategoryColorMap[eventCategory];
}
