// GENERATED CODE -- DO NOT EDIT!

import {grpc} from '@improbable-eng/grpc-web';

import * as class_types_class_types_pb from '../class_types/class_types_pb';
import * as google_protobuf_wrappers_pb from '../google/protobuf/wrappers_pb';
import * as image_image_pb from '../image/image_pb';

export class ClassTypesService {
  static readonly serviceName = "ClassTypesService";

  static readonly GetClassTypes = {
    methodName: "GetClassTypes",
    service: ClassTypesService,
    requestStream: false,
    responseStream: false,
    requestType: class_types_class_types_pb.GetClassTypesRequest,
    responseType: class_types_class_types_pb.GetClassTypesResponse,
  };

  static readonly GetListTypeCounts = {
    methodName: "GetListTypeCounts",
    service: ClassTypesService,
    requestStream: false,
    responseStream: false,
    requestType: class_types_class_types_pb.GetListTypeCountsRequest,
    responseType: class_types_class_types_pb.GetListTypeCountsResponse,
  };

}

export class ClassTypesServiceClient {
  getClassTypes() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  getListTypeCounts() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
}
