import {
  ChangeDetectorRef,
  Component,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import * as xlsx from 'xlsx';
import { Store } from '@ngrx/store';
import PhoneNumber from 'awesome-phonenumber';
import { isEmail } from 'libs/util';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';

import {
  CancelConfirmationDialogComponent,
  SuccessDialog,
} from '@app/src/app/dialog';
import { RootService } from '@app/src/app/minimal/services/RootService';
import { StreamManager } from '@app/src/app/minimal/services/StreamManager';
import {
  PeopleImported,
  PeopleUpdated,
} from '@app/src/app/store/root/rootActions';

import {
  BirthdateListUploadField,
  DisplayNameListUploadField,
  EmailListUploadField,
  FirstNameListUploadField,
  GradeListUploadField,
  GroupListUploadField,
  IdField1UploadField,
  IdField2UploadField,
  IListUploadField,
  IListUploadRowWithError,
  IListUploadValidationResponse,
  LastNameListUploadField,
  ListUploaderRow,
  NameListUploadField,
  ParentEmailUploadField,
  ParentPhoneUploadField,
  PasswordUploadField,
  PhoneNumberListUploadField,
  RoleListUploadField,
  StudentNumberListUploadField,
  XlsListUploaderComponent,
} from '@shared/components/XlsListUploader';
import { ISummaryRow } from '@shared/components/XlsListUploader/ListUploaderSummary';
import { IResolveListUploadErrorsDialogData } from '@shared/components/XlsListUploader/ResolveListUploadErrorsDialog';

import {
  ModalOverlayPrimaryHeaderBackground,
  ModalOverlayRef,
  ModalOverlayServiceCloseEventType,
} from '../modal-overlay';
import { UpdatePeopleByListDialogMessages } from './constants';
import {
  UpdatePeopleByListService,
  UploadPeopleObservable,
} from './services/update-people-by-list.service';
import { UpdatePeopleListState } from './update-people-list-state';

export enum UploadState {
  'SETUP' = 1,
  'UPLOADING',
  'SUMMARY',
}
export interface IArchivePerson {
  email: string;
  grade?: string;
  name: string;
}
@Component({
  templateUrl: './update-people-by-list-dialog.component.html',
  styleUrls: ['./update-people-by-list-dialog.component.scss'],
  providers: [UpdatePeopleListState],
})
export class UpdatePeopleByListComponent implements OnDestroy {
  @ViewChild('xlsUploader', { static: false })
  public xlsUploader: XlsListUploaderComponent;

  public readonly MESSAGES = UpdatePeopleByListDialogMessages;
  public readonly MODAL_COLOR = ModalOverlayPrimaryHeaderBackground.PEOPLE_BLUE;

  files: File[] = [];

  fileSubject: BehaviorSubject<File | null> = new BehaviorSubject<File | null>(
    null,
  );
  files$: Observable<File | null>;

  fields: IListUploadField[] = [];
  includesEmail = false;

  rows: ListUploaderRow[] = [];
  errors: IListUploadRowWithError[] = [];
  /** msg shown when a general error is thrown, not row level error */
  errorMsg: string | null = null;

  emailsToArchive: IArchivePerson[] = [];

  totalProgress = 0;
  currentIndex = 0;
  totalCount = 0;
  totalUpdated = 0;
  totalCreated = 0;
  totalIgnored = 0;

  currentCountSubject: BehaviorSubject<number> = new BehaviorSubject(0);
  currentCount$: Observable<number> = this.currentCountSubject.asObservable();
  currentUploadState: UploadState = UploadState.SETUP;
  uploadStates = UploadState;

  summaryInfo: ISummaryRow[] = [];

  resolveErrorsDialogData: IResolveListUploadErrorsDialogData | null = null;

  /** column headers selected in xls uploader components */
  headers: string[] = [];

  stateSubscription: Subscription;
  canArchive = false;
  /** track whether upload complete ran or not */
  uploadCompleteRan = false;
  firstRowHeaders = false;

  private _stopUpload?: () => void;

  constructor(
    public updatePeopleListState: UpdatePeopleListState,
    private _updatePeopleService: UpdatePeopleByListService,
    private _dialog: MatDialog,
    private _store: Store<any>,
    private _streamManager: StreamManager,
    private _cdr: ChangeDetectorRef,
    private _rootService: RootService,
    private _modalOverlayRef: ModalOverlayRef,
  ) {
    this.files$ = this.fileSubject.asObservable();
    this.fields = [
      new EmailListUploadField(),
      new StudentNumberListUploadField(),
      new RoleListUploadField(),
      new GradeListUploadField(),
      new NameListUploadField(),
      new FirstNameListUploadField(),
      new LastNameListUploadField(),
      new PhoneNumberListUploadField(),
      new DisplayNameListUploadField(),
      new GroupListUploadField(),
      new BirthdateListUploadField(),
      new PasswordUploadField(),
      new IdField1UploadField(),
      new IdField2UploadField(),
      new ParentEmailUploadField(),
      new ParentPhoneUploadField(),
    ];

    this.stateSubscription = this.updatePeopleListState.stateChanged.subscribe(
      (state: UpdatePeopleListState) => {
        if (state.archiveUsers) {
          this.resolveErrorsDialogData = {
            dialogText:
              'Clean up your file to continue to import into Minga. This will remove any problem rows. NOTE: if archiving users not on the uploaded list, resolving problem rows by removing the rows will cause those users to be archived. We recommend updating the uploaded list to fix any issues issues before submitting instead.',
          };
        }
      },
    );
  }

  ngOnDestroy(): void {
    if (this.stateSubscription) {
      this.stateSubscription.unsubscribe();
    }
  }

  get hasFile() {
    return this.files.length > 0;
  }

  async onOverlayClose() {
    this._modalOverlayRef.close(ModalOverlayServiceCloseEventType.CLOSE);

    return true;
  }

  async onFileInputChange() {
    if (!this.files[0]) return;

    this.fileSubject.next(this.files[0]);
  }

  onHeadersChanged(headers: (IListUploadField | null)[]) {
    this.includesEmail = false;
    const emailField = new EmailListUploadField();
    // track whether there is an email field mapped.
    for (const header of headers) {
      if (header?.id === emailField.id) {
        this.includesEmail = true;
      }
    }

    this.headers = headers.map(header => {
      if (header) {
        return header.name;
      } else {
        return '';
      }
    });
  }

  onFirstRowHeaders(value: boolean) {
    this.firstRowHeaders = value;
  }

  async onSubmit(rows: ListUploaderRow[]) {
    this.rows = rows;
    this.totalCount = rows.length;
    this.currentUploadState = UploadState.UPLOADING;
    if (!this.includesEmail) {
      this.updatePeopleListState.useStudentIdAsId = !this.includesEmail;
    }
    const uploadObs = this._updatePeopleService.uploadList(
      this.updatePeopleListState,
      rows,
    );

    this._handleObservingUploading(uploadObs);
  }

  private _handleObservingUploading(uploadObs: UploadPeopleObservable) {
    this._stopUpload = () => uploadObs.cancel();

    uploadObs.subscribe(
      response => {
        const responseIndex = response.getIndex();
        this.totalCreated = response.getTotalCreated();
        this.totalUpdated = response.getTotalUpdated();
        this.totalIgnored = response.getTotalIgnored();
        this.totalProgress = Math.max(
          this.totalProgress,
          response.getTotalProgress() * 100,
        );
        this.currentIndex = responseIndex;
        this.currentCountSubject.next(this.currentIndex + 1);

        if (this.updatePeopleListState.archiveUsers) {
          if (response.getArchivePeopleList().length) {
            this.emailsToArchive = response.getArchivePeopleList().map(msg => {
              return {
                email: msg.getEmail(),
                grade: msg.getGrade(),
                name: msg.getName(),
              };
            });
          }
        }

        if (response.hasError()) {
          const responseErr = response.getError();

          if (responseIndex > -1) {
            const uploadRecord = this.rows[responseIndex];
            if (uploadRecord) {
              this.errors.push({
                row: uploadRecord,
                errors: responseErr.getMessage(),
                recordIndex: response.getIndex(),
              });
            }
          }
        }
        // there might be cases where we're waiting for awhile for
        // the connection to end, so lets just call uploadComplete here.
        if (responseIndex === -1) {
          this.onUploadComplete();
        }
      },
      err => {
        this.errorMsg = err;
        this.currentUploadState = UploadState.SUMMARY;
      },
      () => {
        // ensure that we don't run this twice.
        if (!this.uploadCompleteRan) {
          this.onUploadComplete();
        }
      },
    );
  }

  onUploadComplete() {
    this.uploadCompleteRan = true;
    this.currentUploadState = UploadState.SUMMARY;
    this._streamManager.restartStreamIfAvailable('AdminPeopleFeed');

    this.summaryInfo = [
      { label: 'Total Users Updated:', metric: this.totalUpdated.toString() },
      { label: 'Total Users Created:', metric: this.totalCreated.toString() },
      { label: 'Total Users Ignored:', metric: this.totalIgnored.toString() },
    ];

    if (this.updatePeopleListState.archiveUsers) {
      this.summaryInfo.push({
        label: 'Users set to be Archived: ',
        metric: this.emailsToArchive.length.toString(),
      });
    }

    this._store.dispatch(new PeopleImported());
    this._cdr.markForCheck();
  }

  onValidate(rows: ListUploaderRow[]): IListUploadValidationResponse {
    const rowErrors = [];
    const formatErrors: string[] = [];

    for (let index = 0; index < rows.length; index++) {
      const row = rows[index];
      const errors = [];
      const emailField = new EmailListUploadField();
      const email = emailField.getValue(row);

      if (email) {
        if (!isEmail(email)) {
          errors.push(`Not a valid email: ${email}`);
        }
      }
      const phoneField = new PhoneNumberListUploadField();
      const phone = phoneField.getValue(row);
      if (phone) {
        const pn = new PhoneNumber(phone, 'US');
        if (!pn.isValid()) {
          errors.push(`Invalid phone number: ${phone}`);
        }
      }
      const passwordField = new PasswordUploadField();
      const password = passwordField.getValue(row);
      if (password) {
        if (password.length < 4 || password.length > 16) {
          errors.push(`Password is not 4 to 16 characters: ${password}`);
        }
      }

      if (errors.length > 0) {
        rowErrors.push({ recordIndex: index, errors });
      }
    }
    return { rowErrors, formatErrors };
  }

  onUploadCancelled() {
    if (this._stopUpload) {
      this._stopUpload();
      // add unfinished to errors
      if (this.currentIndex < this.totalCount) {
        for (
          let index = this.currentIndex + 1;
          index < this.rows.length;
          index++
        ) {
          const element = this.rows[index];
          this.errors.push({
            row: element,
            errors: 'Cancelled upload by user',
            recordIndex: index,
          });
        }
      }
      this.currentUploadState = UploadState.SUMMARY;
    }
  }

  archiveUsers() {
    const options = {
      data: {
        title: 'Archive Users',
        text: 'Are you sure you want to archive users missing from this list? This action cannot be undone!',
      },
    };

    const dialogRef = this._dialog.open(
      CancelConfirmationDialogComponent,
      options,
    );

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        const emails = this.emailsToArchive.map(person => person.email);
        this._rootService.addLoadingPromise(
          this._updatePeopleService
            .archivePeople(emails)
            .toPromise()
            .then(() => {
              this._streamManager.restartStreamIfAvailable('AdminPeopleFeed');
              this._store.dispatch(new PeopleUpdated());
              const dialog = this._dialog.open(SuccessDialog, {
                data: {
                  text: `Successfully Archived ${this.emailsToArchive.length} users`,
                },
              });

              this.onOverlayClose();
            }),
        );
      }
    });
  }

  downloadToBeArchived() {
    const workBook = this._createXlsxWorkbook();

    xlsx.writeFile(workBook, `minga-to-be-archived-users.xlsx`);
    this.canArchive = true;
  }

  private _createXlsxWorkbook() {
    const workBook = xlsx.utils.book_new();

    const newRows: string[][] = this.emailsToArchive.map((record, index) => {
      const rowArray = [record.email, record.name, record.grade || ''];

      return rowArray;
    });

    const headers = ['email', 'name', 'grade'];
    newRows.unshift(headers);
    const sheet = xlsx.utils.aoa_to_sheet(newRows, {
      cellDates: true,
    });

    if (!sheet['!cols']) {
      sheet['!cols'] = [];
    }

    xlsx.utils.book_append_sheet(workBook, sheet);

    return workBook;
  }

  reset() {
    this.totalProgress = 0;
    this.files = [];
    this.errors = [];
    this.errorMsg = null;
    this.currentUploadState = UploadState.SETUP;
    this.totalCount = 0;
    this.totalCreated = 0;
    this.totalIgnored = 0;
    this.totalUpdated = 0;
    this.includesEmail = false;
    this.canArchive = false;
    this.currentIndex = 0;
    this.rows = [];
    this.uploadCompleteRan = false;
  }
}
