export function asObjectToMessage<MessageT>(
  object: any, message: MessageT): MessageT;
export function asObjectToMessage<MessageT>(
  object: any, message: MessageT, strict: boolean): MessageT;
export function asObjectToMessage<MessageT>(
  object: any, message: MessageT, ignorePaths: string[]): MessageT;
export function asObjectToMessage<MessageT>(object: any, message: MessageT,
  strictOrIgnorePaths?: boolean|string[]): MessageT {
  if(!object || typeof object !== 'object') {
    return message;
  }

  if(!message || typeof message !== 'object') {
    return message;
  }

  let strict = true;
  let ignorePaths: string[] = [];

  if(typeof strictOrIgnorePaths === 'boolean') {
    strict = strictOrIgnorePaths;
  } else if(Array.isArray(strictOrIgnorePaths)) {
    ignorePaths = strictOrIgnorePaths;
  }

  for(let objKey in object) {
    if(ignorePaths.indexOf(objKey) > -1) {
      continue;
    }

    const msgKeyName = objKey[0].toUpperCase() + objKey.substr(1);
    const msgGetter = 'get' + msgKeyName;
    const msgSetter = 'set' + msgKeyName;
    const value = object[objKey];

    const hasSetter = !!(<any>message)[msgSetter];
    const hasGetter = !!(<any>message)[msgGetter];

    if(!hasSetter) {
      if(strict) {
        throw new Error(
          `No setter for message (${message}) named ${msgSetter}`);
      }

      continue;
    }

    if(typeof (<any>message)[msgSetter] !== 'function') {
      if(strict) {
        throw new Error(`Non-function setter for message ${message}. Got ${
          (<any>message)[msgSetter]}`);
      }

      continue;
    }

    if(!hasGetter) {
      if(strict) {
        throw new Error(
          `No getter for message (${message}) named ${msgGetter}`);
      }

      continue;
    }

    if(typeof (<any>message)[msgGetter] !== 'function') {
      if(strict) {
        throw new Error(`Non-function getter for message ${message}. Got ${
          (<any>message)[msgGetter]}`);
      }

      continue;
    }

    if(typeof value === 'object') {
      if(Array.isArray(value)) {
        if(value.length > 0) {
          console.warn('asObjectToMessage() Repeated fields not supported');
        }
        continue;
      } else if(value) {
        const nestedMessage = (<any>message)[msgGetter]();

        if(!nestedMessage) {
          throw new Error('Single message fields must have default value');
        } else {
          (<any>message)[msgSetter](asObjectToMessage(value, nestedMessage));
        }

        continue;
      }
    } else {
      (<any>message)[msgSetter](value);
    }
  }

  return message;
}
