export enum TemplateColumnKeys {
  DELETE = 'delete',
  ARCHIVE = 'archive',
  SUMMARY = 'summary',
  HISTORY = 'history',
  ROLE = 'role',
  COMPLETE = 'complete',
  ASSIGN = 'assign',
  EDIT = 'edit',
}

export enum NameColumnKeys {
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  TEACHER_NAME = 'teacherName',
  TEACHER_NAME_2 = 'teacherName2',
}

/**
 * Date and time columns are localized differently on the front and back end.
 */
export enum DateColumnKeys {
  DATE = 'date',
  BEHAVIOR_DATE = 'behaviorDate',
  ISSUED_DATE = 'issuedDate',
  DUE_DATE = 'dueDate',
  COMPLETE_DATE = 'completeDate',
  START_DATE = 'startedAt',
  END_DATE = 'actualExpiredAt',
  CREATED_AT = 'createdAt',
  CHECKIN_DATE = 'checkinDate',
  MANUALLY_UPDATED_AT = 'manuallyUpdatedAt',
  DENIED_AT = 'deniedAt',
}

export enum TimeColumnKeys {
  TIME = 'time',
  BEHAVIOR_TIME = 'behaviorTime',
  ISSUED_TIME = 'issuedTime',
  COMPLETE_TIME = 'completeTime',
  START_TIME = 'startedAtTime',
  END_TIME = 'actualExpiredAtTime',
  CHECKIN_TIME = 'checkinTime',
  CHECKOUT_TIME = 'checkoutTime',
  DENIED_TIME = 'deniedTime',
}
