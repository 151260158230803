import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { MatColumnDef } from '@angular/material/table';

import { ColumnInfo } from 'libs/shared';

import { MingaColorsType } from '@shared/constants';

import { ReportTableColumnTemplateComponent } from './rt-column-template.component';

// @TODO look into if this could be a template component for summary, history, complete, and edit
@Component({
  selector: 'mg-action-column',
  styles: ["[class*='w-'] {flex: initial; min-width: 0 !important }"],
  templateUrl: './rt-action-column.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: ReportTableColumnTemplateComponent,
      useExisting: ReportTableActionTemplateComponent,
    },
  ],
})
export class ReportTableActionTemplateComponent implements AfterViewInit {
  @ViewChild(MatColumnDef, { static: true }) columnDef: MatColumnDef;

  // REQUIRED INPUTS
  @Input() iconName;
  @Input() columnInfo: ColumnInfo;

  @Input() colorTheme: MingaColorsType;

  @Output() actionTriggered = new EventEmitter<any>();

  constructor() {}

  ngAfterViewInit(): void {
    if (!this.columnInfo || !this.iconName) {
      throw new Error('Missing required inputs for action column');
    }
  }

  triggerAction(item: any): void {
    this.actionTriggered.emit(item);
  }
}
