export interface StudentSchedule {
  studentSchedule: StudentSection[];
}

export interface StudentSection {
  id: number;
  name: string;
  period?: string; //period name
  term?: string; //term title
  date?: Date;
  startTime?: string;
  endTime?: string;
  teachers?: string[];
  location?: string;
  type?: SectionType;
  bellScheduleId?: number;
  sectionId?: string; //section sourcedId
  periodId?: string; //period shortCode
  termId?: string; //term sourcedId
  sectionNumber?: string; //section sisId which school uses internally
  schoolId?: string; //school sisId which school uses internally
}

export enum SectionType {
  BELL = 'BELL',
  FLEX = 'FLEX',
}
