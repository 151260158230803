<div [style.marginBottom]="'16px'">
  <ng-container [ngTemplateOutlet]="headerTemplate"></ng-container>
</div>
<ng-container *ngTemplateOutlet="mainContentTemplate"></ng-container>

<!-- Header template -->
<ng-template #headerTemplate>
  <div
    fxLayout="row"
    fxLayoutGap="15px"
    fxLayout.lt-md="column">
    <!-- Header Left Content -->
    <div fxFlex>
      <div
        [ngStyle]="
          (media.isMobileView$ | async) ? {} : { 'max-width.px': 660 }
        ">
        <div
          fxLayout="column"
          fxLayoutAlign="start stretch"
          fxLayoutGap="10px">
          <div
            fxLayout="row"
            fxLayoutAlign="start center"
            fxLayoutGap="20px"
            fxLayout.lt-md="column"
            fxLayoutAlign.lt-md="start stretch">
            <!-- Date Filter -->
            <div fxFlex.gt-sm="325px">
              <mg-form-date-range [range]="range"></mg-form-date-range>
            </div>
            <!-- Search Field -->
            <mg-form-text-input
              [label]="MSG.FIELD_LABEL_SEARCH"
              [labelBackground]="'white'"
              [control]="searchControl">
            </mg-form-text-input>
          </div>
        </div>
      </div>
    </div>
    <!-- Header Right Content -->
    <div
      *ngIf="canEdit$ | async"
      fxFlex="nogrow"
      fxFlex.xs="grow"
      fxLayoutAlign.gt-sm="end end">
      <mg-btn
        variant="filled"
        [wide]="true"
        [responsive]="true"
        (pressed)="flexPeriods.openModal()">
        {{ MSG.BUTTON_LABEL_NEW }}
      </mg-btn>
    </div>
  </div>
</ng-template>

<!-- Main Content Template -->
<ng-template #mainContentTemplate>
  <!-- Table -->
  <mat-table
    matSort
    [dataSource]="dataSource"
    [trackBy]="trackById">
    <!-- Title Column -->
    <ng-container matColumnDef="title">
      <mat-header-cell
        *matHeaderCellDef
        mat-sort-header>
        {{ MSG.COLUMN_LABEL_TITLE }}
      </mat-header-cell>
      <mat-cell *matCellDef="let item">
        {{ item.title }}
      </mat-cell>
    </ng-container>

    <!-- Date Column -->
    <ng-container matColumnDef="date">
      <mat-header-cell
        *matHeaderCellDef
        mat-sort-header>
        {{ MSG.COLUMN_LABEL_DATE }}
      </mat-header-cell>
      <mat-cell *matCellDef="let item">
        {{ item.date | date: 'mediumDate' }}
      </mat-cell>
    </ng-container>

    <!-- Time Column -->
    <ng-container matColumnDef="time">
      <mat-header-cell *matHeaderCellDef>
        {{ MSG.COLUMN_LABEL_TIME }}
      </mat-header-cell>
      <mat-cell *matCellDef="let item">
        {{ item | formatTimeRange }}
      </mat-cell>
    </ng-container>

    <!-- Status Column -->
    <ng-container matColumnDef="status">
      <mat-header-cell *matHeaderCellDef>
        {{ MSG.COLUMN_LABEL_STATUS }}
      </mat-header-cell>
      <mat-cell *matCellDef="let item">
        <ng-container
          [ngTemplateOutlet]="periodPermissionStatusesTemplate"
          [ngTemplateOutletContext]="{
            $implicit: item,
            horizontalAlign: 'center',
            verticalAlign: 'center'
          }">
        </ng-container>
      </mat-cell>
    </ng-container>

    <!-- All Activities Column -->
    <ng-container matColumnDef="activities">
      <mat-header-cell *matHeaderCellDef>
        {{ MSG.COLUMN_LABEL_ALL_ACTIVITIES }}
      </mat-header-cell>
      <mat-cell *matCellDef="let item">
        <ng-container
          [ngTemplateOutlet]="periodActivitiesButtonTemplate"
          [ngTemplateOutletContext]="{ $implicit: item }">
        </ng-container>
      </mat-cell>
    </ng-container>

    <!-- My Activities Column -->
    <ng-container matColumnDef="myActivities">
      <mat-header-cell *matHeaderCellDef>
        {{ MSG.COLUMN_LABEL_MY_ACTIVITIES }}
      </mat-header-cell>
      <mat-cell *matCellDef="let item">
        <ng-container
          [ngTemplateOutlet]="periodMyActivitiesButtonTemplate"
          [ngTemplateOutletContext]="{ $implicit: item }">
        </ng-container>
      </mat-cell>
    </ng-container>

    <!-- Total Registered Column -->
    <ng-container matColumnDef="totalRegistered">
      <mat-header-cell *matHeaderCellDef>
        {{ MSG.COLUMN_LABEL_TOTAL_REGISTERED }}
      </mat-header-cell>
      <mat-cell *matCellDef="let item">
        {{ item.activityInstances | totalRegistered }}
      </mat-cell>
    </ng-container>

    <!-- Clone Column -->
    <ng-container matColumnDef="clone">
      <mat-header-cell *matHeaderCellDef>
        {{ MSG.COLUMN_LABEL_CLONE }}
      </mat-header-cell>
      <mat-cell *matCellDef="let item">
        <mg-btn
          [id]="'flextime-periods-click-clone'"
          variant="icon"
          iconSet="minga"
          icon="clone"
          (pressed)="clone(item.id)">
        </mg-btn>
      </mat-cell>
    </ng-container>

    <!-- Edit Column -->
    <ng-container matColumnDef="edit">
      <mat-header-cell *matHeaderCellDef>
        {{ MSG.COLUMN_LABEL_EDIT }}
      </mat-header-cell>
      <mat-cell *matCellDef="let item">
        <mg-btn
          [id]="'flextime-periods-click-edit'"
          variant="icon"
          iconSet="minga"
          icon="mg-edit"
          (pressed)="flexPeriods.openModal(item.id)">
        </mg-btn>
      </mat-cell>
    </ng-container>

    <!-- Mobile View -->
    <ng-container matColumnDef="mobile">
      <mat-header-cell *matHeaderCellDef></mat-header-cell>
      <mat-cell *matCellDef="let item">
        <div
          class="mobile-container"
          fxLayout="column"
          fxLayoutAlign="space-between center"
          [style.width.%]="100">
          <div
            fxLayout="row"
            fxLayoutAlign="space-between">
            <div
              fxFlex="grow"
              fxLayout="column"
              fxLayoutAlign="start start"
              fxLayoutGap="5px">
              <!-- Name -->
              <mg-text
                variant="header-xl"
                textAlign="center"
                fontWeight="700"
                spacing="1">
                {{ item.title }}
              </mg-text>
              <!-- Date -->
              <ng-container
                [ngTemplateOutlet]="periodDetailTemplate"
                [ngTemplateOutletContext]="{
                  $implicit: 'Date',
                  value: (item.date | date: 'mediumDate')
                }">
              </ng-container>
              <!-- Time -->
              <ng-container
                [ngTemplateOutlet]="periodDetailTemplate"
                [ngTemplateOutletContext]="{
                  $implicit: 'Time',
                  value: (item | formatTimeRange)
                }">
              </ng-container>
              <!-- Total Registered -->
              <ng-container
                [ngTemplateOutlet]="periodDetailTemplate"
                [ngTemplateOutletContext]="{
                  $implicit: 'Total Registered',
                  value: (item.activityInstances | totalRegistered)
                }">
              </ng-container>
              <!-- My Activities Button -->
              <div class="mobile-spacing">
                <ng-container
                  [ngTemplateOutlet]="periodMyActivitiesButtonTemplate"
                  [ngTemplateOutletContext]="{ $implicit: item }">
                </ng-container>
              </div>
            </div>
            <div
              fxLayout="row"
              fxLayoutAlign="end start"
              fxLayout="5px">
              <ng-container
                [ngTemplateOutlet]="periodPermissionStatusesTemplate"
                [ngTemplateOutletContext]="{
                  $implicit: item,
                  horizontalAlign: 'end',
                  verticalAlign: 'start'
                }">
              </ng-container>
            </div>
          </div>
          <div class="mobile-divider"></div>
          <div
            fxLayout="row"
            fxLayoutAlign="space-between center"
            [style.width.%]="100">
            <!-- Activities Button -->
            <ng-container
              [ngTemplateOutlet]="periodActivitiesButtonTemplate"
              [ngTemplateOutletContext]="{ $implicit: item }">
            </ng-container>
            <div
              fxLayout="row"
              fxLayout="center center"
              fxLayoutGap="8px">
              <!-- Clone -->
              <div fxFlex="noshrink">
                <mg-btn
                  variant="icon"
                  iconSet="minga"
                  icon="clone"
                  (pressed)="clone(item.id)">
                </mg-btn>
              </div>
              <!-- Edit -->
              <div fxFlex="noshrink">
                <mg-btn
                  variant="icon"
                  iconSet="minga"
                  icon="mg-edit"
                  (pressed)="flexPeriods.openModal(item.id)">
                </mg-btn>
              </div>
            </div>
          </div>
        </div>
      </mat-cell>
    </ng-container>

    <mat-header-row
      *matHeaderRowDef="displayedColumns$ | async"
      [fxHide]="
        (flexPeriods.isLoadingPeriods$ | async) === false && showEmptyState
      ">
    </mat-header-row>

    <mat-row *matRowDef="let row; columns: displayedColumns$ | async">
    </mat-row>
  </mat-table>

  <!-- Loading -->
  <div
    *ngIf="flexPeriods.isLoadingPeriods$ | async"
    class="loading-container">
    <mg-spinner
      [diameter]="40"
      [thickness]="3">
    </mg-spinner>
  </div>

  <!-- Pagination -->
  <div
    class="paginator-container"
    [fxHide]="
      (flexPeriods.isLoadingPeriods$ | async) === false && showEmptyState
    ">
    <mg-paginator [pageSizeOptions]="[100, 50, 20]"></mg-paginator>
  </div>

  <!-- Empty state -->
  <ng-container
    *ngIf="(flexPeriods.isLoadingPeriods$ | async) === false && showEmptyState"
    [ngTemplateOutlet]="noResults">
  </ng-container>
</ng-template>

<!-- Period Detail Template -->
<ng-template
  #periodDetailTemplate
  let-label
  let-value="value">
  <div
    fxLayout="row"
    fxlayoutAlign="center center"
    fxLayoutGap="5px">
    <mg-text
      variant="header-md"
      textAlign="center"
      fontWeight="900">
      {{ label }}:
    </mg-text>
    <mg-text
      variant="header-md"
      textAlign="center"
      fontWeight="700">
      {{ value }}
    </mg-text>
  </div>
</ng-template>

<!-- Activities Button Template -->
<ng-template
  #periodActivitiesButtonTemplate
  let-item>
  <mg-btn
    variant="filled"
    size="small"
    iconSet="minga"
    iconRight="right-arrow-active"
    (pressed)="goToActivities(item)">
    {{ item.activityInstances | countActivityInstances }}
  </mg-btn>
</ng-template>

<!-- My Activities Button Template -->
<ng-template
  #periodMyActivitiesButtonTemplate
  let-item>
  <mg-btn
    *ngIf="
      (item.activityInstances
        | countActivityInstances: authInfo.authPersonHash) > 0;
      else noInstance
    "
    variant="filled"
    size="small"
    [disabled]="
      isInPast(item) ||
      ((canEdit$ | async) === false && !item.enableActivityManagement)
    "
    (pressed)="openMyActivityEdit(item)">
    {{ getMyActivity(item.activityInstances) }}
  </mg-btn>

  <ng-template #noInstance>
    <mg-btn
      variant="outlined"
      size="small"
      [disabled]="
        isInPast(item) ||
        ((canEdit$ | async) === false && !item.enableActivityManagement)
      "
      (pressed)="openMyActivityEdit(item)">
      {{ MSG.BUTTON_LABEL_ADD_ACTIVITY }}
    </mg-btn>
  </ng-template>
</ng-template>

<!-- Period Permission Statuses Template -->
<ng-template
  #periodPermissionStatusesTemplate
  let-item
  let-verticalAlign
  let-horizontalAlign>
  <div
    fxLayout="row"
    fxLayoutAlign="{{ horizontalAlign }} {{ verticalAlign }}"
    class="lock-container">
    <mg-tooltip
      [size]="lockIconSize$ | async"
      [icon]="
        item.enableActivityManagement
          ? PERIOD_LOCKS.ADD_UNLOCKED
          : PERIOD_LOCKS.ADD_LOCKED
      "
      [label]="
        item.enableActivityManagement
          ? EDIT_MSG.FIELD_LABEL_ACTIVITY_MANAGE_ENABLED
          : EDIT_MSG.FIELD_LABEL_ACTIVITY_MANAGE_DISABLED
      "
      [class.lock-add]="!item.enableActivityManagement">
    </mg-tooltip>
    <mg-tooltip
      [size]="lockIconSize$ | async"
      [icon]="
        item.enableStudentManagement
          ? PERIOD_LOCKS.TEACHER_UNLOCKED
          : PERIOD_LOCKS.TEACHER_LOCKED
      "
      [label]="
        item.enableStudentManagement
          ? EDIT_MSG.FIELD_LABEL_STUDENT_MANAGE_ENABLED
          : EDIT_MSG.FIELD_LABEL_STUDENT_MANAGE_DISABLED
      "
      [class.lock-teacher]="!item.enableStudentManagement">
    </mg-tooltip>
    <mg-tooltip
      [size]="lockIconSize$ | async"
      [icon]="
        item.enableStudentSelfManagement
          ? PERIOD_LOCKS.STUDENT_UNLOCKED
          : PERIOD_LOCKS.STUDENT_LOCKED
      "
      [label]="
        item.enableStudentSelfManagement
          ? EDIT_MSG.FIELD_LABEL_STUDENT_SELF_MANAGE_ENABLED
          : EDIT_MSG.FIELD_LABEL_STUDENT_SELF_MANAGE_DISABLED
      "
      [class.lock-student]="!item.enableStudentSelfManagement">
    </mg-tooltip>
  </div>
</ng-template>

<!-- Empty State -->
<ng-template #noResults>
  <mg-empty-state
    [title]="MSG.EMPTY_STATE_TITLE"
    [subtitle]="MSG.EMPTY_STATE_SUBTITLE">
  </mg-empty-state>
</ng-template>
