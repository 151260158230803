import { Injectable } from '@angular/core';

import { LinkEntityType } from 'linkifyjs';

import { linkifyHtml } from '@app/src/app/util/linkify-html';

@Injectable({ providedIn: 'root' })
export class UgcLinksContentService {
  highlightLinks(htmlContent: string): string {
    return linkifyHtml(htmlContent, {
      defaultProtocol: 'https',
      className: 'mg-link',
      ignoreTags: ['mg-mention'],
      validate: (href: string, type: LinkEntityType) => {
        return type === 'url';
      },
    });
  }

  insertLinks(htmlContent: string): string {
    const div = document.createElement('div');
    div.innerHTML = htmlContent;

    let paragraphs = div.querySelectorAll('p');

    const insertLinkDetails = (el: Element) => {
      const sib = el.nextElementSibling;
      el.innerHTML = this.highlightLinks(el.innerHTML);

      const anchorElements = el.querySelectorAll('a');

      anchorElements.forEach(anchorElement => {
        const href = anchorElement.href;
        if (!href.startsWith('http://') && !href.startsWith('https://')) {
          return;
        }

        const detailLinkEl: any = document.createElement('mg-link-detail');
        detailLinkEl.setAttribute('href', href);

        if (el.parentElement) {
          if (!sib) {
            el.parentElement.appendChild(detailLinkEl);
          } else {
            el.parentElement.insertBefore(detailLinkEl, sib);
          }
        } else {
          el.appendChild(detailLinkEl);
        }
      });
    };

    if (paragraphs.length === 0) {
      insertLinkDetails(div);
    } else {
      paragraphs.forEach(insertLinkDetails);
    }

    return div.innerHTML;
  }
}
