import { Injectable } from '@angular/core';

import {
  EditableHallPass,
  HallPassStatusEnum,
  IHallPassType,
} from 'libs/domain';
import { hall_pass_pb, hall_pass_ng_grpc_pb } from 'libs/generated-grpc-web';
import { HallPassMapper, HallPassTypeMapper } from 'libs/shared-grpc';
import { from, Observable } from 'rxjs';

import { dateTimeObjectToDateTimeMessage } from '@app/src/app/util/date';
import { toStreamFilterMessage } from '@app/src/app/util/stream';

import { HpmDashboardFilters } from '@modules/hallpass-manager';

import { ErrorHandlerService } from '../error-handler';

@Injectable({ providedIn: 'root' })
export class HallPassService {
  /** Service Constructor */
  constructor(
    private _hallPasses: hall_pass_ng_grpc_pb.HallPassManager,
    private _errorHandler: ErrorHandlerService,
  ) {}

  public async create(
    hallPassTypeId: number,
    personHashIssuedToList: string[],
    duration: number,
    startDate?: Date,
    teacherHash?: string,
    note?: string,
  ): Promise<void> {
    try {
      const request = new hall_pass_pb.CreateHallPassRequest();
      request.setHallPassTypeId(hallPassTypeId);
      request.setPersonHashIssuedToList(personHashIssuedToList);
      request.setHallPassDuration(duration);
      if (teacherHash) request.setTeacherHash(teacherHash);
      if (note) request.setNote(note);
      if (startDate)
        request.setStartDateTime(dateTimeObjectToDateTimeMessage(startDate));
      await this._hallPasses.createHallPass(request);
    } catch (error) {
      throw this._errorHandler.gateWayError(
        'Failed to create hall pass',
        error,
        true,
      );
    }
  }

  public getHallPassTypes(
    getActiveOnly?: boolean,
    getPermittedOnly?: boolean,
    getKioskPassesOnly?: boolean,
  ): Observable<IHallPassType[]> {
    const request = new hall_pass_pb.GetMingaHallPassTypesRequest();
    if (getActiveOnly) {
      request.setGetActiveOnly(getActiveOnly);
    }

    if (getPermittedOnly) {
      request.setGetPermittedOnly(getPermittedOnly);
    }

    if (getKioskPassesOnly) {
      request.setGetKioskPassesOnly(getKioskPassesOnly);
    }

    return from(this._handleGettingHallPassTypes(request));
  }

  public async updateHallPass(hallPass: EditableHallPass) {
    const request = new hall_pass_pb.UpdateHallPassRequest();
    const hallPassProto = HallPassMapper.toProtoEditable(hallPass);
    request.setHallPass(hallPassProto);
    const response = await this._hallPasses.updateHallPass(request);
    hallPass = HallPassMapper.fromProtoEditable(response.getHallPass());
    return hallPass;
  }

  public async listTypes(activeOnly = false): Promise<IHallPassType[]> {
    const request = new hall_pass_pb.GetMingaHallPassTypesRequest();
    request.setGetActiveOnly(activeOnly);
    const response = await this._hallPasses.getMingaHallPassTypes(request);
    const result = response.getHallPassTypeList();
    return result.map(passProto => HallPassTypeMapper.fromProto(passProto));
  }

  public async listPasses(
    filters: HpmDashboardFilters,
  ): Promise<hall_pass_pb.HallPassWithType.AsObject[]> {
    const request = new hall_pass_pb.GetHallPassesRequest();
    request.setFilter(toStreamFilterMessage(null));
    request.setLimit(1000);
    request.setOffset(0);
    request.setShowCreated(filters.show_mine);
    filters.pass_status.forEach(status => {
      switch (status) {
        case HallPassStatusEnum.ACTIVE: {
          request.setIncludeActive(true);
          break;
        }
        case HallPassStatusEnum.PENDING_APPROVAL: {
          request.setIncludePending(true);
          break;
        }
        case HallPassStatusEnum.OVERDUE: {
          request.setIncludeOverdue(true);
          break;
        }
        case HallPassStatusEnum.SCHEDULED: {
          request.setIncludeScheduled(true);
          break;
        }
        case HallPassStatusEnum.ENDED: {
          request.setIncludeEnded(true);
          break;
        }
        case HallPassStatusEnum.DENIED: {
          request.setIncludeDenied(true);
          break;
        }
        default: {
          break;
        }
      }
    });
    const response = await this._hallPasses.getHallPasses(request);

    return response.getItemsList().map(res => res.getItem().toObject());
  }

  private async _handleGettingHallPassTypes(request: any) {
    const reponse = await this._hallPasses.getMingaHallPassTypes(request);
    const typesProto = reponse.getHallPassTypeList();

    return typesProto.map(HPProto => HallPassTypeMapper.fromProto(HPProto));
  }
}
