import { gateway } from 'libs/generated-grpc-web';

export function isGroupMemberInfoAMember(
  rank: number,
): rank is
  | gateway.mingaGroup_pb.GroupMemberInfo.Rank.MEMBER
  | gateway.mingaGroup_pb.GroupMemberInfo.Rank.OWNER {
  switch (rank) {
    case gateway.mingaGroup_pb.GroupMemberInfo.Rank.MEMBER:
    case gateway.mingaGroup_pb.GroupMemberInfo.Rank.OWNER:
      return true;
    default:
      return false;
  }
}
