import { Injectable } from '@angular/core';

import * as day from 'dayjs';
import { IBellSchedule } from 'libs/domain';
import {
  bell_schedule_pb,
  bell_schedule_ng_grpc_pb,
} from 'libs/generated-grpc-web';
import { BellScheduleMapper } from 'libs/shared-grpc';
import { dayJsObjectToDateTimeMsg } from 'libs/shared-grpc';

import { ScheduleId } from '@modules/minga-manager/components/mm-bell-schedule/types/mm-bell-schedule.types';

import { CacheService } from '../cache/cache.service';
import { CacheKey } from '../cache/cache.types';
import {
  getUpdatedCacheCollection,
  UpdateListCacheParams,
} from '../cache/cache.utils';
import { ErrorHandlerService } from '../error-handler';
import { BellSchedulesService } from './bs-schedules.interface';

@Injectable({ providedIn: 'root' })
export class BsSchedulesService implements BellSchedulesService {
  private _cachedSchedules = this._cacheService.create<IBellSchedule[]>(
    CacheKey.BELL_SCHEDULE_SCHEDULES_LIST,
    data => {
      return this._fetchAll();
    },
    {
      ttl: 60,
    },
  );

  private _calendarCachedSchedules = this._cacheService.create<IBellSchedule[]>(
    CacheKey.BELL_SCHEDULE_CALENDAR_LIST,
    data => {
      return this._fetchAll(true);
    },
    {
      ttl: 60,
    },
  );

  constructor(
    private _cacheService: CacheService,
    private _errorHandler: ErrorHandlerService,
    private _bellScheduleManager: bell_schedule_ng_grpc_pb.BellScheduleManager,
  ) {}

  public async fetchAll(opts?: {
    revalidate?: boolean;
  }): Promise<IBellSchedule[]> {
    return await this._cachedSchedules.get({}, opts).toPromise();
  }

  public async fetchCalender(opts?: {
    revalidate?: boolean;
  }): Promise<IBellSchedule[]> {
    return await this._calendarCachedSchedules.get({}, opts).toPromise();
  }

  private async _fetchAll(
    includeNoSchedule?: boolean,
  ): Promise<IBellSchedule[]> {
    try {
      const request = new bell_schedule_pb.GetBellSchedulesRequest();
      if (includeNoSchedule) {
        request.setIncludeNoSchedule(true);
      } else {
        request.setIncludeNoSchedule(false);
      }

      const result = await this._bellScheduleManager.getBellSchedules(request);
      const bellScheduleList = result.getBellScheduleList();
      const mappedBellScheduleList: IBellSchedule[] = bellScheduleList.map(
        BellScheduleMapper.fromProto,
      );

      return mappedBellScheduleList;
    } catch (error) {
      throw this._errorHandler.gateWayError(
        'Failed to fetch bell schedules',
        error,
        true,
      );
    }
  }

  public async get(id: ScheduleId): Promise<IBellSchedule> {
    try {
      const request = new bell_schedule_pb.GetBellScheduleRequest();

      request.setId(id);

      const result = await this._bellScheduleManager.getBellSchedule(request);
      const bellSchedule = result.getBellSchedule();
      const mappedBellSchedule: IBellSchedule =
        BellScheduleMapper.fromProto(bellSchedule);

      return mappedBellSchedule;
    } catch (error) {
      throw this._errorHandler.gateWayError(
        'Failed to fetch bell schedule',
        error,
        true,
      );
    }
  }

  public async upsert(
    schedule: Partial<IBellSchedule>,
  ): Promise<IBellSchedule> {
    try {
      const request = new bell_schedule_pb.UpsertBellScheduleRequest();
      request.setBellSchedule(
        BellScheduleMapper.toProto(schedule as IBellSchedule),
      );

      const result = await this._bellScheduleManager.upsertBellSchedule(
        request,
      );
      const bellSchedule = result.getBellSchedule();
      const mappedBellSchedule: IBellSchedule =
        BellScheduleMapper.fromProto(bellSchedule);

      return mappedBellSchedule;
    } catch (error) {
      throw this._errorHandler.gateWayError(
        `Failed to ${schedule.id ? 'create' : 'update'} bell schedule`,
        error,
        true,
      );
    }
  }

  public async duplicate(schedule: IBellSchedule): Promise<IBellSchedule> {
    // when we're copying we dont want to copy the terms the schedule is associated with
    const { id, terms, ...rest } = schedule;
    const name = `${rest.name} (Copy)`;

    // strip the ids since it's new items
    const periods = rest.periods.map(p => ({ ...p, id: null }));

    return this.upsert({
      ...rest,
      name,
      periods,
    });
  }

  public async delete(id: ScheduleId): Promise<void> {
    try {
      const request = new bell_schedule_pb.DeleteBellScheduleRequest();
      request.setId(id);
      await this._bellScheduleManager.deleteBellSchedule(request);
    } catch (error) {
      throw this._errorHandler.gateWayError(
        'Failed to delete bell schedule',
        error,
        true,
      );
    }
  }

  public async overrideScheduleForDate(
    scheduleId: ScheduleId | null,
    date: day.Dayjs,
    termId: number,
    isOverride: boolean,
  ): Promise<void> {
    try {
      const request = new bell_schedule_pb.OverrideBellScheduleForDateRequest();
      request.setId(scheduleId);
      request.setDate(dayJsObjectToDateTimeMsg(date));
      request.setTermId(termId);
      request.setIsOverride(isOverride);
      await this._bellScheduleManager.overrideBellScheduleForDate(request);
    } catch (error) {
      throw this._errorHandler.gateWayError(
        'Failed to override bell schedule',
        error,
        true,
      );
    }
  }

  public async updateListCache(
    opts: UpdateListCacheParams<IBellSchedule>,
    includeNoSchedule?: boolean,
  ): Promise<IBellSchedule[]> {
    let schedules: IBellSchedule[] = [];
    if (includeNoSchedule) {
      schedules = await this.fetchCalender();
    } else {
      schedules = await this.fetchAll();
    }
    const updatedList = getUpdatedCacheCollection(opts, schedules);
    if (includeNoSchedule) {
      this._calendarCachedSchedules.set(updatedList);
    } else {
      this._cachedSchedules.set(updatedList);
    }
    return updatedList;
  }
}
