import { Checkin, EditableCheckin } from 'libs/domain';
import { checkin_pb } from 'libs/generated-grpc-ts';

import * as CheckinPersonMapper from './CheckinPerson.mapper';
import * as CheckinReasonMapper from './CheckinReason.mapper';
import { StudentScheduleMapper } from '../';
import {
  dateObjectToDateTimeMessage,
  dateTimeMessageToDateObject,
  dateTimeMessageToDayjsObject,
  dayJsObjectToDateTimeMsg,
} from '../';

export const toProto = (checkin: Checkin): checkin_pb.Checkin => {
  const msg = new checkin_pb.Checkin();
  if (checkin.id) {
    msg.setId(checkin.id);
  }

  if (checkin.reasonId) msg.setReasonId(checkin.reasonId);

  if (checkin.person) {
    msg.setPerson(CheckinPersonMapper.toProto(checkin.person));
  }

  if (checkin.checkinTime) {
    const checkinTime = dayJsObjectToDateTimeMsg(checkin.checkinTime);
    msg.setCheckinTime(checkinTime);
  }

  if (checkin.reason) {
    msg.setReason(CheckinReasonMapper.toProto(checkin.reason));
  }

  if (checkin.reasonName) msg.setReasonName(checkin.reasonName);

  if (checkin.checkinBy) {
    msg.setCheckinby(CheckinPersonMapper.toProto(checkin.checkinBy));
  }

  if (checkin.checkoutBy) {
    msg.setCheckoutBy(CheckinPersonMapper.toProto(checkin.checkoutBy));
  }

  if (checkin.checkoutTime) {
    msg.setCheckoutTime(dayJsObjectToDateTimeMsg(checkin.checkoutTime));
  }

  if (checkin.note) msg.setNote(checkin.note);

  if (checkin.isKiosk) msg.setIsKiosk(checkin.isKiosk);

  if (checkin.currentClass?.length) {
    const sections = checkin.currentClass.map(StudentScheduleMapper.toProto);
    msg.setCurrentClassesList(sections);
    // deprecated but for b/c
    msg.setCurrentClass(sections[0]);
  }

  return msg;
};

export const toProtoEditable = (
  checkin: EditableCheckin,
): checkin_pb.EditableCheckin => {
  const msg = toProto(checkin);
  const editable = new checkin_pb.EditableCheckin();
  editable.setCheckin(msg);
  if (checkin.manuallyUpdatedAt) {
    editable.setManuallyUpdatedAt(
      dateObjectToDateTimeMessage(checkin.manuallyUpdatedAt),
    );
  }
  return editable;
};

export const fromProto = (msg: checkin_pb.Checkin): Checkin => {
  const reasonProto = msg.getReason();
  const reason = reasonProto
    ? CheckinReasonMapper.fromProto(reasonProto)
    : undefined;
  const person = CheckinPersonMapper.fromProto(msg.getPerson());
  const checkinBy = CheckinPersonMapper.fromProto(msg.getCheckinby());
  const checkoutByProto = msg.getCheckoutBy();
  const checkoutBy = checkoutByProto
    ? CheckinPersonMapper.fromProto(checkoutByProto)
    : undefined;
  const checkinTime = dateTimeMessageToDayjsObject(msg.getCheckinTime());
  const checkoutTimeProto = msg.getCheckoutTime();
  const checkoutTime = checkoutTimeProto
    ? dateTimeMessageToDayjsObject(checkoutTimeProto)
    : undefined;
  const currentClassList = msg.getCurrentClassesList();
  return {
    id: msg.getId(),
    reasonId: msg.getReasonId(),
    reason,
    person,
    checkinBy,
    checkinTime,
    checkoutTime,
    checkoutBy,
    reasonName: msg.getReasonName(),
    note: msg.getNote() ?? '',
    isKiosk: msg.getIsKiosk(),
    currentClass: currentClassList
      ? currentClassList.map(StudentScheduleMapper.fromProto)
      : [],
  };
};

export const fromProtoEditable = (
  msg: checkin_pb.EditableCheckin,
): EditableCheckin => {
  const checkin = fromProto(msg.getCheckin());
  const manuallyUpdatedAt = msg.getManuallyUpdatedAt()
    ? dateTimeMessageToDateObject(msg.getManuallyUpdatedAt())
    : undefined;
  return { ...checkin, manuallyUpdatedAt };
};
