import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';

import { MgButtonModule } from '@app/src/app/button';
import { EventGoingStateModule } from '@app/src/app/components/EventGoingState';
import { MgImageModule } from '@app/src/app/components/MgImage';
import { PointsBannerModule } from '@app/src/app/components/Points/PointsBanner';
import { MgContentLinkModule } from '@app/src/app/content-link';
import { MgDateBadgeModule } from '@app/src/app/elements/MgDateBadge';
import { MgLikeModule } from '@app/src/app/elements/MgLike';
import { MgShortCardModule } from '@app/src/app/elements/MgShortCard';
import { MgeIconModule } from '@app/src/app/elements/MgeIcon';
import { MgIconModule } from '@app/src/app/icon';
import { PermissionsModule } from '@app/src/app/permissions';
import { MgPipesModule } from '@app/src/app/pipes';

import { MgEventShortCardElement } from './MgEventShortCard.element';

@NgModule({
  imports: [
    // Minga dependencies
    MgeIconModule,
    MgDateBadgeModule,
    MgShortCardModule,
    MgPipesModule,
    MgLikeModule,
    MgContentLinkModule,
    MgImageModule,
    MgIconModule,
    PointsBannerModule,
    EventGoingStateModule,
    PermissionsModule,
    FlexLayoutModule,
    MgButtonModule,

    // External dependencies
    CommonModule,
  ],
  declarations: [MgEventShortCardElement],
  exports: [MgEventShortCardElement],
})
export class MgEventShortCardModule {}
