import { FlexTimeActivity } from 'libs/domain';
import { flex_time_pb } from 'libs/generated-grpc-ts';

import { RestrictionMapper } from '../restriction';
import { asObjectToMessage } from '../util/message';

export const toProto = (
  activity: FlexTimeActivity,
): flex_time_pb.FlexTimeActivity => {
  const message = new flex_time_pb.FlexTimeActivity();
  if (activity.mingaId) message.setMingaId(activity.mingaId);
  if (activity.id) message.setId(activity.id);
  if (activity.name) message.setName(activity.name);
  if (activity.registrationLimit)
    message.setRegistrationLimit(activity.registrationLimit);
  if (activity.createdBy) message.setCreatedBy(activity.createdBy);
  if (activity.imagePath) message.setImagePath(activity.imagePath);
  if (activity.bannerHash) message.setBannerHash(activity.bannerHash);
  if (activity.restrictionId) message.setRestrictionId(activity.restrictionId);
  if (activity.restriction)
    message.setRestriction(RestrictionMapper.toProto(activity.restriction));
  if (activity.bannerImage) message.setBannerImage(activity.bannerImage);
  if (activity.allowStudentToRegister)
    message.setAllowStudentToRegister(activity.allowStudentToRegister);
  if (activity.description) message.setDescription(activity.description);
  if (activity.createdByPerson)
    message.setCreatedByPerson(
      asObjectToMessage(
        activity.createdByPerson,
        new flex_time_pb.FlexTimeActivityCreatedByPerson(),
      ),
    );
  if (activity.location) message.setLocation(activity.location);
  if (activity.type)
    message.setType(
      asObjectToMessage(activity.type, new flex_time_pb.FlexTimeActivityType()),
    );
  return message;
};

export const fromProto = (
  message: flex_time_pb.FlexTimeActivity,
): FlexTimeActivity => {
  let restriction;

  if (message.getRestriction()) {
    restriction = RestrictionMapper.fromProto(message.getRestriction());
  }

  return {
    mingaId: message.getMingaId(),
    id: message.getId() ?? undefined,
    name: message.getName(),
    description: message.getDescription(),
    registrationLimit: message.getRegistrationLimit(),
    createdBy: message.getCreatedBy(),
    bannerHash: message.getBannerHash() || undefined,
    bannerImage: message.getBannerImage() || undefined,
    imagePath: message.getImagePath() || undefined,
    allowStudentToRegister: message.getAllowStudentToRegister(),
    restrictionId: message.getRestrictionId(),
    restriction,
    createdByPerson: message.getCreatedByPerson()?.toObject(),
    location: message.getLocation(),
    type: message.getType()?.toObject(),
  };
};
