import { day } from 'libs/day';
import { HallPassBlackOutWeekdaysEnum } from 'libs/domain';
import { IHallPassBlackOut, IHallPassBlackOutSchedule } from 'libs/domain';
import { hall_pass_pb } from 'libs/generated-grpc-ts';
import { $enum } from 'ts-enum-util';

export namespace HallPassBlackOutMapper {
  export function toProto(
    hallPassBlackOut: IHallPassBlackOut,
  ): hall_pass_pb.HallPassBlackOut {
    const msg = new hall_pass_pb.HallPassBlackOut();

    if (hallPassBlackOut.id) {
      msg.setId(hallPassBlackOut.id);
    }
    if (hallPassBlackOut.weekday) {
      msg.setWeekday(hallPassBlackOut.weekday);
    }
    if (hallPassBlackOut.startTime) {
      msg.setStartTime(hallPassBlackOut.startTime);
    }
    if (hallPassBlackOut.endTime) {
      msg.setEndTime(hallPassBlackOut.endTime);
    }
    if (hallPassBlackOut.mingaId) {
      msg.setMingaId(hallPassBlackOut.mingaId);
    }
    if (hallPassBlackOut.createdBy) {
      msg.setCreatedBy(hallPassBlackOut.createdBy);
    }
    if (hallPassBlackOut.name) {
      msg.setName(hallPassBlackOut.name);
    }
    if (hallPassBlackOut.active !== undefined) {
      msg.setActive(hallPassBlackOut.active);
    }
    if (hallPassBlackOut.blockMonday !== undefined) {
      msg.setBlockMonday(hallPassBlackOut.blockMonday);
    }
    if (hallPassBlackOut.blockTuesday !== undefined) {
      msg.setBlockTuesday(hallPassBlackOut.blockTuesday);
    }
    if (hallPassBlackOut.blockWednesday !== undefined) {
      msg.setBlockWednesday(hallPassBlackOut.blockWednesday);
    }
    if (hallPassBlackOut.blockThursday !== undefined) {
      msg.setBlockThursday(hallPassBlackOut.blockThursday);
    }
    if (hallPassBlackOut.blockFriday !== undefined) {
      msg.setBlockFriday(hallPassBlackOut.blockFriday);
    }
    if (hallPassBlackOut.blockSaturday !== undefined) {
      msg.setBlockSaturday(hallPassBlackOut.blockSaturday);
    }
    if (hallPassBlackOut.blockSunday !== undefined) {
      msg.setBlockSunday(hallPassBlackOut.blockSunday);
    }
    if (hallPassBlackOut.schedules) {
      msg.setHallPassBlackOutScheduleList(
        hallPassBlackOut.schedules.map(HallPassBlackOutScheduleMapper.toProto),
      );
    }

    return msg;
  }

  export function fromProto(
    msg: hall_pass_pb.HallPassBlackOut,
  ): IHallPassBlackOut {
    const hallPassBlackOut: IHallPassBlackOut = {
      id: msg.getId(),
      startTime: HallPassBlackOutScheduleMapper.parseTime(msg.getStartTime()),
      endTime: HallPassBlackOutScheduleMapper.parseTime(msg.getEndTime()),
      mingaId: msg.getMingaId(),
      createdBy: msg.getCreatedBy(),
      name: msg.getName(),
      active: msg.getActive(),
      blockMonday: msg.getBlockMonday(),
      blockTuesday: msg.getBlockTuesday(),
      blockWednesday: msg.getBlockWednesday(),
      blockThursday: msg.getBlockThursday(),
      blockFriday: msg.getBlockFriday(),
      blockSaturday: msg.getBlockSaturday(),
      blockSunday: msg.getBlockSunday(),
      schedules: msg
        .getHallPassBlackOutScheduleList()
        .map(HallPassBlackOutScheduleMapper.fromProto),
    };

    const weekday = msg.getWeekday();
    if (weekday) {
      hallPassBlackOut.weekday = $enum(
        HallPassBlackOutWeekdaysEnum,
      ).getValueOrDefault(
        msg.getWeekday(),
        HallPassBlackOutWeekdaysEnum.EVERYDAY,
      );
    }

    return hallPassBlackOut;
  }
}

export namespace HallPassBlackOutScheduleMapper {
  export function parseTime(t: string) {
    var d = new Date();
    var time = t.match(/(\d+)(?::(\d\d))?\s*(p?)/);
    d.setHours(parseInt(time![1]) + (time![3] ? 12 : 0));
    d.setMinutes(parseInt(time![2]) || 0);
    d.setSeconds(0);
    return day(d).format('HH:mm:ss');
  }

  export function fromProto(
    msg: hall_pass_pb.HallPassBlackOutSchedule,
  ): IHallPassBlackOutSchedule {
    return {
      startTime: HallPassBlackOutScheduleMapper.parseTime(msg.getStartTime()),
      endTime: HallPassBlackOutScheduleMapper.parseTime(msg.getEndTime()),
    };
  }

  export function toProto(
    hallPassBlackOutSchedule: IHallPassBlackOutSchedule,
  ): hall_pass_pb.HallPassBlackOutSchedule {
    const msg = new hall_pass_pb.HallPassBlackOutSchedule();
    msg.setStartTime(hallPassBlackOutSchedule.startTime);
    msg.setEndTime(hallPassBlackOutSchedule.endTime);
    return msg;
  }
}
