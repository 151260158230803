import { MingaAppRoutes } from '@app/src/app/types';

import {
  ModerationComponent,
  ModerationDirectMessagesComponent,
  ModerationPostsComponent,
  ModerationReportsComponent,
} from '@shared/components/moderation';

import { SuperAdminRoute } from './constants';
import { SuperAdminComponent } from './super-admin.component';

const moderationAbsolutePath = (v: string) =>
  `/${SuperAdminRoute.ROOT}/${SuperAdminRoute.MODERATION}/${v}`;

export const SUPER_ADMIN_ROUTE: MingaAppRoutes = [
  {
    path: '',
    component: SuperAdminComponent,
    children: [
      // Redirect to default page
      {
        path: '',
        pathMatch: 'full',
        redirectTo: SuperAdminRoute.PROGRAMS,
      },
      // Manage programs
      {
        path: SuperAdminRoute.PROGRAMS,
        data: {
          title: 'Manage Programs',
          description: 'Do something about programs',
          navigationVisibility: true,
          viewMode: 'admin',
        },
        loadChildren: () =>
          import('@modules/programs').then(m => m.ProgramModule),
      },
      // Manage banners
      {
        path: SuperAdminRoute.BANNERS,
        data: {
          title: 'Banner Manager',
          description: 'Make banners, probably',
          navigationVisibility: true,
        },
        loadChildren: () =>
          import('./components/sa-banner').then(m => m.SuperAdminBannerModule),
      },
      // Poll backgrounds
      {
        path: SuperAdminRoute.POLL_BACKGROUNDS,
        data: {
          title: 'Poll Backgrounds',
          description: 'Something to do with poll backgrounds',
          navigationVisibility: true,
        },
        loadChildren: () =>
          import('./components/sa-poll-backgrounds').then(
            m => m.SuperAdminPollBackgroundsModule,
          ),
      },
      // People
      {
        path: SuperAdminRoute.PEOPLE,
        data: {
          title: 'People',
          description: 'Manage people or something',
          navigationVisibility: true,
        },
        loadChildren: () =>
          import('./components/sa-people').then(m => m.SuperAdminPeopleModule),
      },
      // Moderation
      {
        path: SuperAdminRoute.MODERATION,
        component: ModerationComponent,
        data: {
          title: 'Moderation',
          description: 'Moderation',
          navigationVisibility: true,
        },
        children: [
          {
            path: '',
            component: ModerationPostsComponent,
            data: {
              title: 'All posts',
              navigationVisibility: true,
              baseUrl: moderationAbsolutePath(''),
              globalContent: true,
            },
          },
          {
            path: 'reports',
            component: ModerationReportsComponent,
            data: {
              title: 'Reported content',
              navigationVisibility: true,
              baseUrl: moderationAbsolutePath('reports'),
              globalContent: true,
            },
          },
          {
            path: 'direct-messages',
            component: ModerationDirectMessagesComponent,
            data: {
              title: 'Direct Messages',
              navigationVisibility: true,
              baseUrl: moderationAbsolutePath('direct-messages'),
              globalContent: true,
            },
          },
        ],
      },
      // Moderation
      {
        path: SuperAdminRoute.DEV_AREA,
        data: {
          title: 'Dev Area',
          description: 'For cool kids only!!',
          navigationVisibility: true,
        },
        loadChildren: () =>
          import('./components/sa-dev').then(m => m.SuperAdminDevModule),
      },
    ],
  },
];
