export enum FtmReportsUnRegMessages {
  ASSIGN_LABEL = 'Assign',
  REGISTER_LABEL = 'Register',
  REGISTER_TO_HOMEROOM = 'Register to homeroom teacher',

  USERLIST_MODAL_HEADING = 'Register to homeroom teacher is not available',
  USERLIST_MODAL_MESSAGE = "You must first create homerooms to register the students to their homeroom teacher's activity. Go to People / User List and select the user lists you would like to use as homerooms",
  USERLIST_MODAL_CONFIRM = 'Go to User List',

  UNREGISTERED_MODAL_HEADING = 'Register unregistered students?',
  UNREGISTERED_MODAL_CLOSE = 'Cancel',
  UNREGISTERED_MODAL_CONFIRM = 'Register',

  ERROR_MODAL_TITLE = 'Registration Summary',
  ERROR_MODAL_MESSAGE = 'could not be registered',
  ERROR_MODAL_SUBMIT = 'Go back to report',
  ERROR_MODAL_CLOSE = 'Close',

  SUCCESS_SNACKBAR_MESSAGE = 'All students successfully registered',
}
