import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { PointsHistoryIconModule } from '@app/src/app/components/PointsHistoryIcon';
import { MgIconModule } from '@app/src/app/icon';
import { MgPipesModule } from '@app/src/app/pipes';

import { PointsHistoryItemComponent } from './PointsHistoryItem.component';

@NgModule({
  imports: [
    // Minga dependencies
    MgIconModule,
    MgPipesModule,
    PointsHistoryIconModule,

    // External dependencies
    CommonModule,
  ],
  declarations: [PointsHistoryItemComponent],
  exports: [PointsHistoryItemComponent],
})
export class PointsHistoryItemModule {}
