import { IGalleryPhoto } from 'libs/domain';
import { gateway } from 'libs/generated-grpc-ts';
import { image_pb } from 'libs/generated-grpc-ts';

export namespace GalleryItemMapper {
  export interface FromIGalleryPhotoExtras {
    imageInfo: image_pb.ImageInfo;
    authorDisplayName: string;
    authorPersonHash: string;
  }

  /**
   * Convert IGalleryPhoto to a protobuf GalleryItem message
   *
   * @param imageInfo - Since `ImageInfo` cannot be purely retrieved from
   * `IGalleryInfo` it is an extra parameter.
   */
  export function fromIGalleryPhoto(
    value: IGalleryPhoto,
    extras: FromIGalleryPhotoExtras,
  ): gateway.gallery_pb.GalleryItem {
    const msg = new gateway.gallery_pb.GalleryItem();
    msg.setDescription(value.description);
    msg.setGalleryPhotoUuid(value.uuid);
    msg.setTimestamp(value.createdTimestamp);
    msg.setImageInfo(extras.imageInfo);
    msg.setAuthorDisplayName(extras.authorDisplayName);
    msg.setAuthorPersonHash(extras.authorPersonHash);

    for (const taggedGroup of value.taggedGroups) {
      const tagMsg = new gateway.gallery_pb.Tag();
      const groupTagMsg = new gateway.gallery_pb.Tag.GroupTag();

      groupTagMsg.setGroupHash(taggedGroup.groupHash);

      tagMsg.setGroupTag(groupTagMsg);
      msg.addTag(tagMsg);
    }

    for (const taggedPerson of value.taggedPeople) {
      const tagMsg = new gateway.gallery_pb.Tag();
      const personTagMsg = new gateway.gallery_pb.Tag.PersonTag();

      personTagMsg.setPersonHash(taggedPerson.personHash);
      personTagMsg.setDisplayName(taggedPerson.displayName);

      tagMsg.setPersonTag(personTagMsg);
      msg.addTag(tagMsg);
    }

    for (const taggedContent of value.taggedContent) {
      const tagMsg = new gateway.gallery_pb.Tag();
      const contentTagMsg = new gateway.gallery_pb.Tag.ContentTag();
      contentTagMsg.setContentHash(taggedContent.hash);
      contentTagMsg.setContentTagTitle(taggedContent.contentTagTitle);

      tagMsg.setContentTag(contentTagMsg);
      msg.addTag(tagMsg);
    }

    return msg;
  }
}
