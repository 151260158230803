import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { FileInputUploadModule } from '@app/src/app/components/Input/FileInputUpload';
import { MgImageModule } from '@app/src/app/components/MgImage';
import { MgPipesModule } from '@app/src/app/pipes';
import { MgSpinnerModule } from '@app/src/app/spinner';

import { CarouselModule } from '@shared/components/carousel/carousel.module';
import { TextModule } from '@shared/components/text/text.module';

import { FormImageSelectComponent } from './form-image-select.component';

@NgModule({
  imports: [
    CommonModule,
    CarouselModule,
    MgImageModule,
    MgPipesModule,
    MgSpinnerModule,
    // TODO importing this breaks the story setup for this component for some reason
    FileInputUploadModule,
    FormsModule,
    TextModule,
  ],
  exports: [FormImageSelectComponent],
  declarations: [FormImageSelectComponent],
})
export class FormImageSelectModule {}
