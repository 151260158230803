// GENERATED CODE -- DO NOT EDIT!

import {grpc} from '@improbable-eng/grpc-web';

import * as gateway_inspiration_pb from '../gateway/inspiration_pb';
import * as common_legacy_pb from '../common/legacy_pb';
import * as gateway_moderation_pb from '../gateway/moderation_pb';

export class InspirationManager {
  static readonly serviceName = "InspirationManager";

  static readonly Create = {
    methodName: "Create",
    service: InspirationManager,
    requestStream: false,
    responseStream: false,
    requestType: gateway_inspiration_pb.CreateInspirationRequest,
    responseType: gateway_inspiration_pb.CreateInspirationResponse,
  };

}

export class InspirationManagerClient {
  create() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
}
