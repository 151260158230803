// GENERATED CODE -- DO NOT EDIT!

import {grpc} from '@improbable-eng/grpc-web';

import * as points_points_pb from '../points/points_pb';
import * as image_image_pb from '../image/image_pb';
import * as gateway_person_view_pb from '../gateway/person_view_pb';
import * as common_stream_pb from '../common/stream_pb';
import * as common_date_pb from '../common/date_pb';

export class PointsManager {
  static readonly serviceName = "points.PointsManager";

  static readonly AddPoints = {
    methodName: "AddPoints",
    service: PointsManager,
    requestStream: false,
    responseStream: false,
    requestType: points_points_pb.AddPointsRequest,
    responseType: points_points_pb.AddPointsResponse,
  };

  static readonly RemovePoints = {
    methodName: "RemovePoints",
    service: PointsManager,
    requestStream: false,
    responseStream: false,
    requestType: points_points_pb.RemovePointsRequest,
    responseType: points_points_pb.RemovePointsResponse,
  };

  static readonly GetTeams = {
    methodName: "GetTeams",
    service: PointsManager,
    requestStream: false,
    responseStream: false,
    requestType: points_points_pb.GetTeamsRequest,
    responseType: points_points_pb.GetTeamsResponse,
  };

  static readonly UpdateTeam = {
    methodName: "UpdateTeam",
    service: PointsManager,
    requestStream: false,
    responseStream: false,
    requestType: points_points_pb.UpdateTeamRequest,
    responseType: points_points_pb.UpdateTeamResponse,
  };

  static readonly RemoveTeam = {
    methodName: "RemoveTeam",
    service: PointsManager,
    requestStream: false,
    responseStream: false,
    requestType: points_points_pb.RemoveTeamRequest,
    responseType: points_points_pb.RemoveTeamResponse,
  };

  static readonly AddMembersToTeam = {
    methodName: "AddMembersToTeam",
    service: PointsManager,
    requestStream: false,
    responseStream: false,
    requestType: points_points_pb.AddMembersToTeamRequest,
    responseType: points_points_pb.AddMembersToTeamResponse,
  };

  static readonly RemoveMembersFromTeam = {
    methodName: "RemoveMembersFromTeam",
    service: PointsManager,
    requestStream: false,
    responseStream: false,
    requestType: points_points_pb.RemoveMembersFromTeamRequest,
    responseType: points_points_pb.RemoveMembersFromTeamResponse,
  };

  static readonly GetTeamMembers = {
    methodName: "GetTeamMembers",
    service: PointsManager,
    requestStream: false,
    responseStream: false,
    requestType: points_points_pb.GetTeamMembersRequest,
    responseType: points_points_pb.GetTeamMembersResponse,
  };

  static readonly TeamLeaderboard = {
    methodName: "TeamLeaderboard",
    service: PointsManager,
    requestStream: false,
    responseStream: false,
    requestType: points_points_pb.GetTeamLeaderboardRequest,
    responseType: points_points_pb.GetTeamLeaderboardResponse,
  };

  static readonly PersonLeaderboard = {
    methodName: "PersonLeaderboard",
    service: PointsManager,
    requestStream: false,
    responseStream: false,
    requestType: points_points_pb.GetPersonLeaderboardRequest,
    responseType: points_points_pb.GetPersonLeaderboardResponse,
  };

  static readonly GetProfilePointsSummary = {
    methodName: "GetProfilePointsSummary",
    service: PointsManager,
    requestStream: false,
    responseStream: false,
    requestType: points_points_pb.GetProfilePointsSummaryRequest,
    responseType: points_points_pb.GetProfilePointsSummaryResponse,
  };

  static readonly GetPointCategories = {
    methodName: "GetPointCategories",
    service: PointsManager,
    requestStream: false,
    responseStream: false,
    requestType: points_points_pb.GetPointCategoriesRequest,
    responseType: points_points_pb.GetPointCategoriesResponse,
  };

  static readonly ResetMingaPointsHistory = {
    methodName: "ResetMingaPointsHistory",
    service: PointsManager,
    requestStream: false,
    responseStream: false,
    requestType: points_points_pb.ResetMingaPointsHistoryRequest,
    responseType: points_points_pb.ResetMingaPointsHistoryResponse,
  };

  static readonly StreamPointsHistory = {
    methodName: "StreamPointsHistory",
    service: PointsManager,
    requestStream: false,
    responseStream: true,
    requestType: common_stream_pb.StreamID,
    responseType: points_points_pb.StreamPointsHistoryResponse,
  };

  static readonly StreamPointsHistoryControl = {
    methodName: "StreamPointsHistoryControl",
    service: PointsManager,
    requestStream: false,
    responseStream: false,
    requestType: common_stream_pb.StreamControl,
    responseType: common_stream_pb.StreamControlResponse,
  };

  static readonly ExportLeaderboard = {
    methodName: "ExportLeaderboard",
    service: PointsManager,
    requestStream: false,
    responseStream: true,
    requestType: points_points_pb.ExportLeaderboardRequest,
    responseType: points_points_pb.ExportLeaderboardChunk,
  };

  static readonly RedeemPoints = {
    methodName: "RedeemPoints",
    service: PointsManager,
    requestStream: false,
    responseStream: false,
    requestType: points_points_pb.RedeemPointsRequest,
    responseType: points_points_pb.RedeemPointsResponse,
  };

  static readonly GetRedeemedPoints = {
    methodName: "GetRedeemedPoints",
    service: PointsManager,
    requestStream: false,
    responseStream: false,
    requestType: points_points_pb.GetRedeemedPointsRequest,
    responseType: points_points_pb.GetRedeemedPointsResponse,
  };

  static readonly GetRedeemablePoints = {
    methodName: "GetRedeemablePoints",
    service: PointsManager,
    requestStream: false,
    responseStream: false,
    requestType: points_points_pb.GetRedeemablePointsRequest,
    responseType: points_points_pb.GetRedeemablePointsResponse,
  };

  static readonly UpsertPointReward = {
    methodName: "UpsertPointReward",
    service: PointsManager,
    requestStream: false,
    responseStream: false,
    requestType: points_points_pb.UpsertPointRewardRequest,
    responseType: points_points_pb.UpsertPointRewardResponse,
  };

  static readonly DeletePointReward = {
    methodName: "DeletePointReward",
    service: PointsManager,
    requestStream: false,
    responseStream: false,
    requestType: points_points_pb.DeletePointRewardRequest,
    responseType: points_points_pb.DeletePointRewardResponse,
  };

  static readonly GetPointReward = {
    methodName: "GetPointReward",
    service: PointsManager,
    requestStream: false,
    responseStream: false,
    requestType: points_points_pb.GetPointRewardRequest,
    responseType: points_points_pb.GetPointRewardResponse,
  };

  static readonly GetPointRewards = {
    methodName: "GetPointRewards",
    service: PointsManager,
    requestStream: false,
    responseStream: false,
    requestType: points_points_pb.GetPointRewardsRequest,
    responseType: points_points_pb.GetPointRewardsResponse,
  };

  static readonly ArchivePointAction = {
    methodName: "ArchivePointAction",
    service: PointsManager,
    requestStream: false,
    responseStream: false,
    requestType: points_points_pb.ArchivePointActionRequest,
    responseType: points_points_pb.ArchivePointActionResponse,
  };

  static readonly ArchivePointRedemption = {
    methodName: "ArchivePointRedemption",
    service: PointsManager,
    requestStream: false,
    responseStream: false,
    requestType: points_points_pb.ArchivePointRedemptionRequest,
    responseType: points_points_pb.ArchivePointRedemptionResponse,
  };

}

export class PointsManagerClient {
  addPoints() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  removePoints() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  getTeams() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  updateTeam() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  removeTeam() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  addMembersToTeam() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  removeMembersFromTeam() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  getTeamMembers() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  teamLeaderboard() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  personLeaderboard() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  getProfilePointsSummary() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  getPointCategories() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  resetMingaPointsHistory() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  streamPointsHistory() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  streamPointsHistoryControl() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  exportLeaderboard() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  redeemPoints() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  getRedeemedPoints() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  getRedeemablePoints() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  upsertPointReward() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  deletePointReward() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  getPointReward() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  getPointRewards() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  archivePointAction() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  archivePointRedemption() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
}
