import { MingaPermission, MingaRoleType } from 'libs/domain';
import { DisplayNameFormat } from 'libs/util';

import { UserRole } from './UserRole';

export class KioskRole extends UserRole {
  readonly roleType = MingaRoleType.KIOSK;
  readonly name = 'Kiosk';
  readonly displayNameFormat = DisplayNameFormat.F_LAST;
  readonly iconUrl = 'assets/roles/kiosk.svg';
  readonly iconColor = '#2465B9';
  readonly capabilitiesDescription = `Kiosk role is a limited role to be used for kiosk devices.`;
  readonly feedPermittable = false;
  readonly galleryPermittable = false;
  readonly groupFeedPermittable = false;
  readonly programManagePermittable = false;
  readonly videoUploadPermittable = false;
  readonly commentPermittable = false;
  readonly groupCreatePermittable = false;
  readonly admin = false;
  readonly requiredFeatures = [];
  readonly sendToMailChimp = false;

  readonly permissions = [MingaPermission.KIOSK_MANAGE];
  readonly overridablePermissions = [];
}
