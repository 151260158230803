import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { RouterModule } from '@angular/router';

import { MgButtonModule } from '@app/src/app/button';
import { MgFormFieldModule } from '@app/src/app/form-field';
import { MgIconModule } from '@app/src/app/icon';
import { MgSpinnerModule } from '@app/src/app/spinner';

import { GenericButtonModule } from '@shared/components/button';
import { FormModule } from '@shared/components/form';
import { GenericModule } from '@shared/components/generic';
import {
  ModalOverlayService,
  OverlayModule,
} from '@shared/components/modal-overlay';
import { TextModule } from '@shared/components/text';

import { AutomationCounterResetComponent } from './automation-counter-reset.component';

@NgModule({
  imports: [
    // Minga dependencies
    GenericModule,
    MgButtonModule,
    MgIconModule,
    MgFormFieldModule,
    MgSpinnerModule,
    GenericButtonModule,
    FormModule,
    TextModule,
    OverlayModule,

    // External dependencies
    CommonModule,
    FlexLayoutModule,
    FormsModule,
    MatFormFieldModule,
    MatIconModule,
    MatSortModule,
    MatInputModule,
    RouterModule,
    ReactiveFormsModule,
    MatTableModule,
  ],
  declarations: [AutomationCounterResetComponent],
  exports: [AutomationCounterResetComponent],
  providers: [ModalOverlayService],
})
export class AutomationCounterResetModule {}
