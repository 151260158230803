import { sms_pb } from 'libs/generated-grpc-ts';
import { ISmsRecipientDetails } from 'libs/shared';

import { asObjectToMessage } from '../';

export namespace MingaSmsDetailsMapper {
  export function fromISmsRecipientDetails(details: ISmsRecipientDetails) {
    const msg = new sms_pb.MingaSmsDetails();
    asObjectToMessage(details, msg, true);
    return msg;
  }

  export function toISmsRecipientDetails(msg: sms_pb.MingaSmsDetails) {
    const details: ISmsRecipientDetails = {
      ...msg.toObject(),
    };

    return details;
  }
}
