// source: hall_pass_stats/hall_pass_stats.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

import * as jspb from 'google-protobuf';
import * as common_date_pb from '../common/date_pb';
export namespace HpsSummary {
  export interface AsObject {
    typeId: number;
    month: number;
    date: string;
    hour: number;
    week: number;
    totalPasses: number;
    totalTime: number;
    typeName: string;
    typeColor: string;
    year: number;
    totalDenied: number;
  }
}
export class HpsSummary extends jspb.Message {
  static readonly displayName = "HpsSummary";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): HpsSummary.AsObject {
    return HpsSummary.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!HpsSummary} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: HpsSummary) {
    var f: any;
    var obj: any = {
      typeId: jspb.Message.getFieldWithDefault(msg, 1, 0),
      month: jspb.Message.getFieldWithDefault(msg, 2, 0),
      date: jspb.Message.getFieldWithDefault(msg, 3, ""),
      hour: jspb.Message.getFieldWithDefault(msg, 4, 0),
      week: jspb.Message.getFieldWithDefault(msg, 5, 0),
      totalPasses: jspb.Message.getFieldWithDefault(msg, 6, 0),
      totalTime: jspb.Message.getFieldWithDefault(msg, 7, 0),
      typeName: jspb.Message.getFieldWithDefault(msg, 8, ""),
      typeColor: jspb.Message.getFieldWithDefault(msg, 9, ""),
      year: jspb.Message.getFieldWithDefault(msg, 10, 0),
      totalDenied: jspb.Message.getFieldWithDefault(msg, 11, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!HpsSummary}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new HpsSummary;
    return HpsSummary.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!HpsSummary} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!HpsSummary}
   */
  static deserializeBinaryFromReader(msg: HpsSummary, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {number} */ (reader.readInt32());
        msg.setTypeId(value1);
        break;
      case 2:
        var value2 = /** @type {number} */ (reader.readInt32());
        msg.setMonth(value2);
        break;
      case 3:
        var value3 = /** @type {string} */ (reader.readString());
        msg.setDate(value3);
        break;
      case 4:
        var value4 = /** @type {number} */ (reader.readInt32());
        msg.setHour(value4);
        break;
      case 5:
        var value5 = /** @type {number} */ (reader.readInt32());
        msg.setWeek(value5);
        break;
      case 6:
        var value6 = /** @type {number} */ (reader.readInt32());
        msg.setTotalPasses(value6);
        break;
      case 7:
        var value7 = /** @type {number} */ (reader.readInt32());
        msg.setTotalTime(value7);
        break;
      case 8:
        var value8 = /** @type {string} */ (reader.readString());
        msg.setTypeName(value8);
        break;
      case 9:
        var value9 = /** @type {string} */ (reader.readString());
        msg.setTypeColor(value9);
        break;
      case 10:
        var value10 = /** @type {number} */ (reader.readInt32());
        msg.setYear(value10);
        break;
      case 11:
        var value11 = /** @type {number} */ (reader.readInt32());
        msg.setTotalDenied(value11);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!HpsSummary} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: HpsSummary, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getTypeId();
    if (f !== 0) {
      writer.writeInt32(
        1,
        f
      );
    }
    f = message.getMonth();
    if (f !== 0) {
      writer.writeInt32(
        2,
        f
      );
    }
    f = message.getDate();
    if (f.length > 0) {
      writer.writeString(
        3,
        f
      );
    }
    f = message.getHour();
    if (f !== 0) {
      writer.writeInt32(
        4,
        f
      );
    }
    f = message.getWeek();
    if (f !== 0) {
      writer.writeInt32(
        5,
        f
      );
    }
    f = message.getTotalPasses();
    if (f !== 0) {
      writer.writeInt32(
        6,
        f
      );
    }
    f = message.getTotalTime();
    if (f !== 0) {
      writer.writeInt32(
        7,
        f
      );
    }
    f = message.getTypeName();
    if (f.length > 0) {
      writer.writeString(
        8,
        f
      );
    }
    f = message.getTypeColor();
    if (f.length > 0) {
      writer.writeString(
        9,
        f
      );
    }
    f = message.getYear();
    if (f !== 0) {
      writer.writeInt32(
        10,
        f
      );
    }
    f = message.getTotalDenied();
    if (f !== 0) {
      writer.writeInt32(
        11,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    HpsSummary.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional int32 type_id = 1;
   * @return {number}
   */
  getTypeId(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
  };


  /** @param {number} value */
  setTypeId(value: number) {
    jspb.Message.setProto3IntField(this, 1, value);
  }


  /**
   * optional int32 month = 2;
   * @return {number}
   */
  getMonth(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
  };


  /** @param {number} value */
  setMonth(value: number) {
    jspb.Message.setProto3IntField(this, 2, value);
  }


  /**
   * optional string date = 3;
   * @return {string}
   */
  getDate(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
  };


  /** @param {string} value */
  setDate(value: string) {
    jspb.Message.setProto3StringField(this, 3, value);
  }


  /**
   * optional int32 hour = 4;
   * @return {number}
   */
  getHour(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
  };


  /** @param {number} value */
  setHour(value: number) {
    jspb.Message.setProto3IntField(this, 4, value);
  }


  /**
   * optional int32 week = 5;
   * @return {number}
   */
  getWeek(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
  };


  /** @param {number} value */
  setWeek(value: number) {
    jspb.Message.setProto3IntField(this, 5, value);
  }


  /**
   * optional int32 total_passes = 6;
   * @return {number}
   */
  getTotalPasses(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
  };


  /** @param {number} value */
  setTotalPasses(value: number) {
    jspb.Message.setProto3IntField(this, 6, value);
  }


  /**
   * optional int32 total_time = 7;
   * @return {number}
   */
  getTotalTime(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
  };


  /** @param {number} value */
  setTotalTime(value: number) {
    jspb.Message.setProto3IntField(this, 7, value);
  }


  /**
   * optional string type_name = 8;
   * @return {string}
   */
  getTypeName(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
  };


  /** @param {string} value */
  setTypeName(value: string) {
    jspb.Message.setProto3StringField(this, 8, value);
  }


  /**
   * optional string type_color = 9;
   * @return {string}
   */
  getTypeColor(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
  };


  /** @param {string} value */
  setTypeColor(value: string) {
    jspb.Message.setProto3StringField(this, 9, value);
  }


  /**
   * optional int32 year = 10;
   * @return {number}
   */
  getYear(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
  };


  /** @param {number} value */
  setYear(value: number) {
    jspb.Message.setProto3IntField(this, 10, value);
  }


  /**
   * optional int32 total_denied = 11;
   * @return {number}
   */
  getTotalDenied(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
  };


  /** @param {number} value */
  setTotalDenied(value: number) {
    jspb.Message.setProto3IntField(this, 11, value);
  }


}
export namespace HpsPersonInfo {
  export interface AsObject {
    name: string;
    studentId: string;
    id: number;
    grade: string;
    role: string;
    badgeIconUrl: string;
    personHash: string;
    firstName: string;
    lastName: string;
  }
}
export class HpsPersonInfo extends jspb.Message {
  static readonly displayName = "HpsPersonInfo";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): HpsPersonInfo.AsObject {
    return HpsPersonInfo.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!HpsPersonInfo} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: HpsPersonInfo) {
    var f: any;
    var obj: any = {
      name: jspb.Message.getFieldWithDefault(msg, 1, ""),
      studentId: jspb.Message.getFieldWithDefault(msg, 2, ""),
      id: jspb.Message.getFieldWithDefault(msg, 3, 0),
      grade: jspb.Message.getFieldWithDefault(msg, 4, ""),
      role: jspb.Message.getFieldWithDefault(msg, 5, ""),
      badgeIconUrl: jspb.Message.getFieldWithDefault(msg, 6, ""),
      personHash: jspb.Message.getFieldWithDefault(msg, 7, ""),
      firstName: jspb.Message.getFieldWithDefault(msg, 8, ""),
      lastName: jspb.Message.getFieldWithDefault(msg, 9, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!HpsPersonInfo}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new HpsPersonInfo;
    return HpsPersonInfo.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!HpsPersonInfo} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!HpsPersonInfo}
   */
  static deserializeBinaryFromReader(msg: HpsPersonInfo, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setName(value1);
        break;
      case 2:
        var value2 = /** @type {string} */ (reader.readString());
        msg.setStudentId(value2);
        break;
      case 3:
        var value3 = /** @type {number} */ (reader.readInt32());
        msg.setId(value3);
        break;
      case 4:
        var value4 = /** @type {string} */ (reader.readString());
        msg.setGrade(value4);
        break;
      case 5:
        var value5 = /** @type {string} */ (reader.readString());
        msg.setRole(value5);
        break;
      case 6:
        var value6 = /** @type {string} */ (reader.readString());
        msg.setBadgeIconUrl(value6);
        break;
      case 7:
        var value7 = /** @type {string} */ (reader.readString());
        msg.setPersonHash(value7);
        break;
      case 8:
        var value8 = /** @type {string} */ (reader.readString());
        msg.setFirstName(value8);
        break;
      case 9:
        var value9 = /** @type {string} */ (reader.readString());
        msg.setLastName(value9);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!HpsPersonInfo} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: HpsPersonInfo, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getName();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
    f = message.getStudentId();
    if (f.length > 0) {
      writer.writeString(
        2,
        f
      );
    }
    f = message.getId();
    if (f !== 0) {
      writer.writeInt32(
        3,
        f
      );
    }
    f = message.getGrade();
    if (f.length > 0) {
      writer.writeString(
        4,
        f
      );
    }
    f = message.getRole();
    if (f.length > 0) {
      writer.writeString(
        5,
        f
      );
    }
    f = message.getBadgeIconUrl();
    if (f.length > 0) {
      writer.writeString(
        6,
        f
      );
    }
    f = message.getPersonHash();
    if (f.length > 0) {
      writer.writeString(
        7,
        f
      );
    }
    f = message.getFirstName();
    if (f.length > 0) {
      writer.writeString(
        8,
        f
      );
    }
    f = message.getLastName();
    if (f.length > 0) {
      writer.writeString(
        9,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    HpsPersonInfo.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string name = 1;
   * @return {string}
   */
  getName(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setName(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


  /**
   * optional string student_id = 2;
   * @return {string}
   */
  getStudentId(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
  };


  /** @param {string} value */
  setStudentId(value: string) {
    jspb.Message.setProto3StringField(this, 2, value);
  }


  /**
   * optional int32 id = 3;
   * @return {number}
   */
  getId(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
  };


  /** @param {number} value */
  setId(value: number) {
    jspb.Message.setProto3IntField(this, 3, value);
  }


  /**
   * optional string grade = 4;
   * @return {string}
   */
  getGrade(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
  };


  /** @param {string} value */
  setGrade(value: string) {
    jspb.Message.setProto3StringField(this, 4, value);
  }


  /**
   * optional string role = 5;
   * @return {string}
   */
  getRole(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
  };


  /** @param {string} value */
  setRole(value: string) {
    jspb.Message.setProto3StringField(this, 5, value);
  }


  /**
   * optional string badge_icon_url = 6;
   * @return {string}
   */
  getBadgeIconUrl(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
  };


  /** @param {string} value */
  setBadgeIconUrl(value: string) {
    jspb.Message.setProto3StringField(this, 6, value);
  }


  /**
   * optional string person_hash = 7;
   * @return {string}
   */
  getPersonHash(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
  };


  /** @param {string} value */
  setPersonHash(value: string) {
    jspb.Message.setProto3StringField(this, 7, value);
  }


  /**
   * optional string first_name = 8;
   * @return {string}
   */
  getFirstName(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
  };


  /** @param {string} value */
  setFirstName(value: string) {
    jspb.Message.setProto3StringField(this, 8, value);
  }


  /**
   * optional string last_name = 9;
   * @return {string}
   */
  getLastName(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
  };


  /** @param {string} value */
  setLastName(value: string) {
    jspb.Message.setProto3StringField(this, 9, value);
  }


}
export namespace HpsPersonStats {
  export interface AsObject {
    typeId: number;
    month: number;
    date: string;
    hour: number;
    week: number;
    totalPasses: number;
    totalTime: number;
    issuedTo: string;
    issuedBy: string;
    totalDenied: number;
    totalOverridden: number;
  }
}
export class HpsPersonStats extends jspb.Message {
  static readonly displayName = "HpsPersonStats";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): HpsPersonStats.AsObject {
    return HpsPersonStats.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!HpsPersonStats} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: HpsPersonStats) {
    var f: any;
    var obj: any = {
      typeid: jspb.Message.getFieldWithDefault(msg, 1, 0),
      month: jspb.Message.getFieldWithDefault(msg, 2, 0),
      date: jspb.Message.getFieldWithDefault(msg, 3, ""),
      hour: jspb.Message.getFieldWithDefault(msg, 4, 0),
      week: jspb.Message.getFieldWithDefault(msg, 5, 0),
      totalPasses: jspb.Message.getFieldWithDefault(msg, 6, 0),
      totalTime: jspb.Message.getFieldWithDefault(msg, 7, 0),
      issuedto: jspb.Message.getFieldWithDefault(msg, 8, ""),
      issuedby: jspb.Message.getFieldWithDefault(msg, 9, ""),
      totalDenied: jspb.Message.getFieldWithDefault(msg, 10, 0),
      totalOverridden: jspb.Message.getFieldWithDefault(msg, 11, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!HpsPersonStats}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new HpsPersonStats;
    return HpsPersonStats.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!HpsPersonStats} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!HpsPersonStats}
   */
  static deserializeBinaryFromReader(msg: HpsPersonStats, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {number} */ (reader.readInt32());
        msg.setTypeid(value1);
        break;
      case 2:
        var value2 = /** @type {number} */ (reader.readInt32());
        msg.setMonth(value2);
        break;
      case 3:
        var value3 = /** @type {string} */ (reader.readString());
        msg.setDate(value3);
        break;
      case 4:
        var value4 = /** @type {number} */ (reader.readInt32());
        msg.setHour(value4);
        break;
      case 5:
        var value5 = /** @type {number} */ (reader.readInt32());
        msg.setWeek(value5);
        break;
      case 6:
        var value6 = /** @type {number} */ (reader.readInt32());
        msg.setTotalPasses(value6);
        break;
      case 7:
        var value7 = /** @type {number} */ (reader.readInt32());
        msg.setTotalTime(value7);
        break;
      case 8:
        var value8 = /** @type {string} */ (reader.readString());
        msg.setIssuedto(value8);
        break;
      case 9:
        var value9 = /** @type {string} */ (reader.readString());
        msg.setIssuedby(value9);
        break;
      case 10:
        var value10 = /** @type {number} */ (reader.readInt32());
        msg.setTotalDenied(value10);
        break;
      case 11:
        var value11 = /** @type {number} */ (reader.readInt32());
        msg.setTotalOverridden(value11);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!HpsPersonStats} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: HpsPersonStats, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getTypeid();
    if (f !== 0) {
      writer.writeInt32(
        1,
        f
      );
    }
    f = message.getMonth();
    if (f !== 0) {
      writer.writeInt32(
        2,
        f
      );
    }
    f = message.getDate();
    if (f.length > 0) {
      writer.writeString(
        3,
        f
      );
    }
    f = message.getHour();
    if (f !== 0) {
      writer.writeInt32(
        4,
        f
      );
    }
    f = message.getWeek();
    if (f !== 0) {
      writer.writeInt32(
        5,
        f
      );
    }
    f = message.getTotalPasses();
    if (f !== 0) {
      writer.writeInt32(
        6,
        f
      );
    }
    f = message.getTotalTime();
    if (f !== 0) {
      writer.writeInt32(
        7,
        f
      );
    }
    f = message.getIssuedto();
    if (f.length > 0) {
      writer.writeString(
        8,
        f
      );
    }
    f = message.getIssuedby();
    if (f.length > 0) {
      writer.writeString(
        9,
        f
      );
    }
    f = message.getTotalDenied();
    if (f !== 0) {
      writer.writeInt32(
        10,
        f
      );
    }
    f = message.getTotalOverridden();
    if (f !== 0) {
      writer.writeInt32(
        11,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    HpsPersonStats.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional int32 typeId = 1;
   * @return {number}
   */
  getTypeid(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
  };


  /** @param {number} value */
  setTypeid(value: number) {
    jspb.Message.setProto3IntField(this, 1, value);
  }


  /**
   * optional int32 month = 2;
   * @return {number}
   */
  getMonth(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
  };


  /** @param {number} value */
  setMonth(value: number) {
    jspb.Message.setProto3IntField(this, 2, value);
  }


  /**
   * optional string date = 3;
   * @return {string}
   */
  getDate(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
  };


  /** @param {string} value */
  setDate(value: string) {
    jspb.Message.setProto3StringField(this, 3, value);
  }


  /**
   * optional int32 hour = 4;
   * @return {number}
   */
  getHour(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
  };


  /** @param {number} value */
  setHour(value: number) {
    jspb.Message.setProto3IntField(this, 4, value);
  }


  /**
   * optional int32 week = 5;
   * @return {number}
   */
  getWeek(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
  };


  /** @param {number} value */
  setWeek(value: number) {
    jspb.Message.setProto3IntField(this, 5, value);
  }


  /**
   * optional int32 total_passes = 6;
   * @return {number}
   */
  getTotalPasses(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
  };


  /** @param {number} value */
  setTotalPasses(value: number) {
    jspb.Message.setProto3IntField(this, 6, value);
  }


  /**
   * optional int32 total_time = 7;
   * @return {number}
   */
  getTotalTime(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
  };


  /** @param {number} value */
  setTotalTime(value: number) {
    jspb.Message.setProto3IntField(this, 7, value);
  }


  /**
   * optional string issuedTo = 8;
   * @return {string}
   */
  getIssuedto(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
  };


  /** @param {string} value */
  setIssuedto(value: string) {
    jspb.Message.setProto3StringField(this, 8, value);
  }


  /**
   * optional string issuedBy = 9;
   * @return {string}
   */
  getIssuedby(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
  };


  /** @param {string} value */
  setIssuedby(value: string) {
    jspb.Message.setProto3StringField(this, 9, value);
  }


  /**
   * optional int32 total_denied = 10;
   * @return {number}
   */
  getTotalDenied(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
  };


  /** @param {number} value */
  setTotalDenied(value: number) {
    jspb.Message.setProto3IntField(this, 10, value);
  }


  /**
   * optional int32 total_overridden = 11;
   * @return {number}
   */
  getTotalOverridden(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
  };


  /** @param {number} value */
  setTotalOverridden(value: number) {
    jspb.Message.setProto3IntField(this, 11, value);
  }


}
export namespace HpsPersonExpiredStats {
  export interface AsObject {
    noOfLatePasses: number;
    totalLateTime: number;
  }
}
export class HpsPersonExpiredStats extends jspb.Message {
  static readonly displayName = "HpsPersonExpiredStats";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): HpsPersonExpiredStats.AsObject {
    return HpsPersonExpiredStats.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!HpsPersonExpiredStats} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: HpsPersonExpiredStats) {
    var f: any;
    var obj: any = {
      noOfLatePasses: jspb.Message.getFieldWithDefault(msg, 1, 0),
      totalLateTime: jspb.Message.getFieldWithDefault(msg, 2, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!HpsPersonExpiredStats}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new HpsPersonExpiredStats;
    return HpsPersonExpiredStats.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!HpsPersonExpiredStats} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!HpsPersonExpiredStats}
   */
  static deserializeBinaryFromReader(msg: HpsPersonExpiredStats, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {number} */ (reader.readInt32());
        msg.setNoOfLatePasses(value1);
        break;
      case 2:
        var value2 = /** @type {number} */ (reader.readInt32());
        msg.setTotalLateTime(value2);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!HpsPersonExpiredStats} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: HpsPersonExpiredStats, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getNoOfLatePasses();
    if (f !== 0) {
      writer.writeInt32(
        1,
        f
      );
    }
    f = message.getTotalLateTime();
    if (f !== 0) {
      writer.writeInt32(
        2,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    HpsPersonExpiredStats.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional int32 no_of_late_passes = 1;
   * @return {number}
   */
  getNoOfLatePasses(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
  };


  /** @param {number} value */
  setNoOfLatePasses(value: number) {
    jspb.Message.setProto3IntField(this, 1, value);
  }


  /**
   * optional int32 total_late_time = 2;
   * @return {number}
   */
  getTotalLateTime(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
  };


  /** @param {number} value */
  setTotalLateTime(value: number) {
    jspb.Message.setProto3IntField(this, 2, value);
  }


}
export namespace HpsPerson {
  export interface AsObject {
    info?: HpsPersonInfo.AsObject;
    stats?: HpsPersonStats.AsObject;
  }
}
export class HpsPerson extends jspb.Message {
  static readonly displayName = "HpsPerson";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): HpsPerson.AsObject {
    return HpsPerson.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!HpsPerson} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: HpsPerson) {
    var f: any;
    var obj: any = {
      info: (f = msg.getInfo()) && HpsPersonInfo.toObject(includeInstance, f),
      stats: (f = msg.getStats()) && HpsPersonStats.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!HpsPerson}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new HpsPerson;
    return HpsPerson.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!HpsPerson} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!HpsPerson}
   */
  static deserializeBinaryFromReader(msg: HpsPerson, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new HpsPersonInfo;
        reader.readMessage(value1,HpsPersonInfo.deserializeBinaryFromReader);
        msg.setInfo(value1);
        break;
      case 2:
        var value2 = new HpsPersonStats;
        reader.readMessage(value2,HpsPersonStats.deserializeBinaryFromReader);
        msg.setStats(value2);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!HpsPerson} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: HpsPerson, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getInfo();
    if (f != null) {
      writer.writeMessage(
        1,
        f,
        HpsPersonInfo.serializeBinaryToWriter
      );
    }
    f = message.getStats();
    if (f != null) {
      writer.writeMessage(
        2,
        f,
        HpsPersonStats.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    HpsPerson.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional HpsPersonInfo info = 1;
   * @return {?HpsPersonInfo}
   */
  getInfo(): HpsPersonInfo {
    return /** @type{?HpsPersonInfo} */ (
      jspb.Message.getWrapperField(this, HpsPersonInfo, 1));
  }


  /** @param {?HpsPersonInfo|undefined} value */
  setInfo(value?: HpsPersonInfo) {
    jspb.Message.setWrapperField(this, 1, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearInfo() {
    this.setInfo(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasInfo(): boolean {
    return jspb.Message.getField(this, 1) != null;
  }


  /**
   * optional HpsPersonStats stats = 2;
   * @return {?HpsPersonStats}
   */
  getStats(): HpsPersonStats {
    return /** @type{?HpsPersonStats} */ (
      jspb.Message.getWrapperField(this, HpsPersonStats, 2));
  }


  /** @param {?HpsPersonStats|undefined} value */
  setStats(value?: HpsPersonStats) {
    jspb.Message.setWrapperField(this, 2, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearStats() {
    this.setStats(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasStats(): boolean {
    return jspb.Message.getField(this, 2) != null;
  }


}
export namespace HpsPersonExpired {
  export interface AsObject {
    info?: HpsPersonInfo.AsObject;
    expiredStats?: HpsPersonExpiredStats.AsObject;
  }
}
export class HpsPersonExpired extends jspb.Message {
  static readonly displayName = "HpsPersonExpired";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): HpsPersonExpired.AsObject {
    return HpsPersonExpired.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!HpsPersonExpired} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: HpsPersonExpired) {
    var f: any;
    var obj: any = {
      info: (f = msg.getInfo()) && HpsPersonInfo.toObject(includeInstance, f),
      expiredStats: (f = msg.getExpiredStats()) && HpsPersonExpiredStats.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!HpsPersonExpired}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new HpsPersonExpired;
    return HpsPersonExpired.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!HpsPersonExpired} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!HpsPersonExpired}
   */
  static deserializeBinaryFromReader(msg: HpsPersonExpired, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new HpsPersonInfo;
        reader.readMessage(value1,HpsPersonInfo.deserializeBinaryFromReader);
        msg.setInfo(value1);
        break;
      case 2:
        var value2 = new HpsPersonExpiredStats;
        reader.readMessage(value2,HpsPersonExpiredStats.deserializeBinaryFromReader);
        msg.setExpiredStats(value2);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!HpsPersonExpired} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: HpsPersonExpired, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getInfo();
    if (f != null) {
      writer.writeMessage(
        1,
        f,
        HpsPersonInfo.serializeBinaryToWriter
      );
    }
    f = message.getExpiredStats();
    if (f != null) {
      writer.writeMessage(
        2,
        f,
        HpsPersonExpiredStats.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    HpsPersonExpired.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional HpsPersonInfo info = 1;
   * @return {?HpsPersonInfo}
   */
  getInfo(): HpsPersonInfo {
    return /** @type{?HpsPersonInfo} */ (
      jspb.Message.getWrapperField(this, HpsPersonInfo, 1));
  }


  /** @param {?HpsPersonInfo|undefined} value */
  setInfo(value?: HpsPersonInfo) {
    jspb.Message.setWrapperField(this, 1, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearInfo() {
    this.setInfo(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasInfo(): boolean {
    return jspb.Message.getField(this, 1) != null;
  }


  /**
   * optional HpsPersonExpiredStats expired_stats = 2;
   * @return {?HpsPersonExpiredStats}
   */
  getExpiredStats(): HpsPersonExpiredStats {
    return /** @type{?HpsPersonExpiredStats} */ (
      jspb.Message.getWrapperField(this, HpsPersonExpiredStats, 2));
  }


  /** @param {?HpsPersonExpiredStats|undefined} value */
  setExpiredStats(value?: HpsPersonExpiredStats) {
    jspb.Message.setWrapperField(this, 2, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearExpiredStats() {
    this.setExpiredStats(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasExpiredStats(): boolean {
    return jspb.Message.getField(this, 2) != null;
  }


}
export namespace HallPassReportFilters {
  export interface AsObject {
    startDate?: common_date_pb.DateTime.AsObject;
    endDate?: common_date_pb.DateTime.AsObject;
    passTypeList: number[];
    studentCreated: string;
    orderByTotalPasses?: SortOrder;
    orderByTotalTime?: SortOrder;
    issuedToList: string[];
    issuedByList: string[];
    limit: number;
    offset: number;
    gradeList: string[];
    userListList: number[];
    statusList: string[];
    deniedByList: number[];
    deniedByBlackoutList: number[];
    deniedByNoPartyList: number[];
  }
}
export class HallPassReportFilters extends jspb.Message {
  static readonly displayName = "HallPassReportFilters";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [5,9,10,13,14,15,16,17,18];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, HallPassReportFilters.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): HallPassReportFilters.AsObject {
    return HallPassReportFilters.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!HallPassReportFilters} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: HallPassReportFilters) {
    var f: any;
    var obj: any = {
      startDate: (f = msg.getStartDate()) && common_date_pb.DateTime.toObject(includeInstance, f),
      endDate: (f = msg.getEndDate()) && common_date_pb.DateTime.toObject(includeInstance, f),
      passTypeList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f,
      studentCreated: jspb.Message.getFieldWithDefault(msg, 6, ""),
      orderByTotalPasses: jspb.Message.getFieldWithDefault(msg, 7, 0),
      orderByTotalTime: jspb.Message.getFieldWithDefault(msg, 8, 0),
      issuedToList: (f = jspb.Message.getRepeatedField(msg, 9)) == null ? undefined : f,
      issuedByList: (f = jspb.Message.getRepeatedField(msg, 10)) == null ? undefined : f,
      limit: jspb.Message.getFieldWithDefault(msg, 11, 0),
      offset: jspb.Message.getFieldWithDefault(msg, 12, 0),
      gradeList: (f = jspb.Message.getRepeatedField(msg, 13)) == null ? undefined : f,
      userListList: (f = jspb.Message.getRepeatedField(msg, 14)) == null ? undefined : f,
      statusList: (f = jspb.Message.getRepeatedField(msg, 15)) == null ? undefined : f,
      deniedByList: (f = jspb.Message.getRepeatedField(msg, 16)) == null ? undefined : f,
      deniedByBlackoutList: (f = jspb.Message.getRepeatedField(msg, 17)) == null ? undefined : f,
      deniedByNoPartyList: (f = jspb.Message.getRepeatedField(msg, 18)) == null ? undefined : f
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!HallPassReportFilters}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new HallPassReportFilters;
    return HallPassReportFilters.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!HallPassReportFilters} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!HallPassReportFilters}
   */
  static deserializeBinaryFromReader(msg: HallPassReportFilters, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new common_date_pb.DateTime;
        reader.readMessage(value1,common_date_pb.DateTime.deserializeBinaryFromReader);
        msg.setStartDate(value1);
        break;
      case 2:
        var value2 = new common_date_pb.DateTime;
        reader.readMessage(value2,common_date_pb.DateTime.deserializeBinaryFromReader);
        msg.setEndDate(value2);
        break;
      case 5:
        var value5 = /** @type {!Array<number>} */ (reader.readPackedInt32());
        msg.setPassTypeList(value5);
        break;
      case 6:
        var value6 = /** @type {string} */ (reader.readString());
        msg.setStudentCreated(value6);
        break;
      case 7:
        var value7 = /** @type {!SortOrder} */ (reader.readEnum());
        msg.setOrderByTotalPasses(value7);
        break;
      case 8:
        var value8 = /** @type {!SortOrder} */ (reader.readEnum());
        msg.setOrderByTotalTime(value8);
        break;
      case 9:
        var value9 = /** @type {string} */ (reader.readString());
        msg.addIssuedTo(value9);
        break;
      case 10:
        var value10 = /** @type {string} */ (reader.readString());
        msg.addIssuedBy(value10);
        break;
      case 11:
        var value11 = /** @type {number} */ (reader.readInt32());
        msg.setLimit(value11);
        break;
      case 12:
        var value12 = /** @type {number} */ (reader.readInt32());
        msg.setOffset(value12);
        break;
      case 13:
        var value13 = /** @type {string} */ (reader.readString());
        msg.addGrade(value13);
        break;
      case 14:
        var value14 = /** @type {!Array<number>} */ (reader.readPackedInt32());
        msg.setUserListList(value14);
        break;
      case 15:
        var value15 = /** @type {string} */ (reader.readString());
        msg.addStatus(value15);
        break;
      case 16:
        var value16 = /** @type {!Array<number>} */ (reader.readPackedInt32());
        msg.setDeniedByList(value16);
        break;
      case 17:
        var value17 = /** @type {!Array<number>} */ (reader.readPackedInt32());
        msg.setDeniedByBlackoutList(value17);
        break;
      case 18:
        var value18 = /** @type {!Array<number>} */ (reader.readPackedInt32());
        msg.setDeniedByNoPartyList(value18);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!HallPassReportFilters} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: HallPassReportFilters, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getStartDate();
    if (f != null) {
      writer.writeMessage(
        1,
        f,
        common_date_pb.DateTime.serializeBinaryToWriter
      );
    }
    f = message.getEndDate();
    if (f != null) {
      writer.writeMessage(
        2,
        f,
        common_date_pb.DateTime.serializeBinaryToWriter
      );
    }
    f = message.getPassTypeList();
    if (f.length > 0) {
      writer.writePackedInt32(
        5,
        f
      );
    }
    f = message.getStudentCreated();
    if (f.length > 0) {
      writer.writeString(
        6,
        f
      );
    }
    f = message.getOrderByTotalPasses();
    if (f !== 0.0) {
      writer.writeEnum(
        7,
        f
      );
    }
    f = message.getOrderByTotalTime();
    if (f !== 0.0) {
      writer.writeEnum(
        8,
        f
      );
    }
    f = message.getIssuedToList();
    if (f.length > 0) {
      writer.writeRepeatedString(
        9,
        f
      );
    }
    f = message.getIssuedByList();
    if (f.length > 0) {
      writer.writeRepeatedString(
        10,
        f
      );
    }
    f = message.getLimit();
    if (f !== 0) {
      writer.writeInt32(
        11,
        f
      );
    }
    f = message.getOffset();
    if (f !== 0) {
      writer.writeInt32(
        12,
        f
      );
    }
    f = message.getGradeList();
    if (f.length > 0) {
      writer.writeRepeatedString(
        13,
        f
      );
    }
    f = message.getUserListList();
    if (f.length > 0) {
      writer.writePackedInt32(
        14,
        f
      );
    }
    f = message.getStatusList();
    if (f.length > 0) {
      writer.writeRepeatedString(
        15,
        f
      );
    }
    f = message.getDeniedByList();
    if (f.length > 0) {
      writer.writePackedInt32(
        16,
        f
      );
    }
    f = message.getDeniedByBlackoutList();
    if (f.length > 0) {
      writer.writePackedInt32(
        17,
        f
      );
    }
    f = message.getDeniedByNoPartyList();
    if (f.length > 0) {
      writer.writePackedInt32(
        18,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    HallPassReportFilters.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional minga.common.DateTime start_date = 1;
   * @return {?DateTime}
   */
  getStartDate(): common_date_pb.DateTime {
    return /** @type{?DateTime} */ (
      jspb.Message.getWrapperField(this, common_date_pb.DateTime, 1));
  }


  /** @param {?DateTime|undefined} value */
  setStartDate(value?: common_date_pb.DateTime) {
    jspb.Message.setWrapperField(this, 1, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearStartDate() {
    this.setStartDate(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasStartDate(): boolean {
    return jspb.Message.getField(this, 1) != null;
  }


  /**
   * optional minga.common.DateTime end_date = 2;
   * @return {?DateTime}
   */
  getEndDate(): common_date_pb.DateTime {
    return /** @type{?DateTime} */ (
      jspb.Message.getWrapperField(this, common_date_pb.DateTime, 2));
  }


  /** @param {?DateTime|undefined} value */
  setEndDate(value?: common_date_pb.DateTime) {
    jspb.Message.setWrapperField(this, 2, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearEndDate() {
    this.setEndDate(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasEndDate(): boolean {
    return jspb.Message.getField(this, 2) != null;
  }


  /**
   * repeated int32 pass_type = 5;
   * @return {!Array<number>}
   */
  getPassTypeList(): number[] {
    return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 5));
  };


  /** @param {!Array<number>} value */
  setPassTypeList(value: number[]) {
    jspb.Message.setField(this, 5, value || []);
  }


  /**
   * @param {number} value
   * @param {number=} opt_index
   */
  addPassType(value: number, opt_index?: number): void {
    jspb.Message.addToRepeatedField(this, 5, value, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearPassTypeList() {
    this.setPassTypeList([]);
  }


  /**
   * optional string student_created = 6;
   * @return {string}
   */
  getStudentCreated(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
  };


  /** @param {string} value */
  setStudentCreated(value: string) {
    jspb.Message.setProto3StringField(this, 6, value);
  }


  /**
   * optional SortOrder order_by_total_passes = 7;
   * @return {!SortOrder}
   */
  getOrderByTotalPasses(): SortOrder {
    return /** @type {!SortOrder} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
  };


  /** @param {!SortOrder} value */
  setOrderByTotalPasses(value: SortOrder) {
    jspb.Message.setProto3EnumField(this, 7, value);
  }


  /**
   * optional SortOrder order_by_total_time = 8;
   * @return {!SortOrder}
   */
  getOrderByTotalTime(): SortOrder {
    return /** @type {!SortOrder} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
  };


  /** @param {!SortOrder} value */
  setOrderByTotalTime(value: SortOrder) {
    jspb.Message.setProto3EnumField(this, 8, value);
  }


  /**
   * repeated string issued_to = 9;
   * @return {!Array<string>}
   */
  getIssuedToList(): string[] {
    return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 9));
  };


  /** @param {!Array<string>} value */
  setIssuedToList(value: string[]) {
    jspb.Message.setField(this, 9, value || []);
  }


  /**
   * @param {string} value
   * @param {number=} opt_index
   */
  addIssuedTo(value: string, opt_index?: number): void {
    jspb.Message.addToRepeatedField(this, 9, value, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearIssuedToList() {
    this.setIssuedToList([]);
  }


  /**
   * repeated string issued_by = 10;
   * @return {!Array<string>}
   */
  getIssuedByList(): string[] {
    return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 10));
  };


  /** @param {!Array<string>} value */
  setIssuedByList(value: string[]) {
    jspb.Message.setField(this, 10, value || []);
  }


  /**
   * @param {string} value
   * @param {number=} opt_index
   */
  addIssuedBy(value: string, opt_index?: number): void {
    jspb.Message.addToRepeatedField(this, 10, value, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearIssuedByList() {
    this.setIssuedByList([]);
  }


  /**
   * optional int32 limit = 11;
   * @return {number}
   */
  getLimit(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
  };


  /** @param {number} value */
  setLimit(value: number) {
    jspb.Message.setProto3IntField(this, 11, value);
  }


  /**
   * optional int32 offset = 12;
   * @return {number}
   */
  getOffset(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
  };


  /** @param {number} value */
  setOffset(value: number) {
    jspb.Message.setProto3IntField(this, 12, value);
  }


  /**
   * repeated string grade = 13;
   * @return {!Array<string>}
   */
  getGradeList(): string[] {
    return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 13));
  };


  /** @param {!Array<string>} value */
  setGradeList(value: string[]) {
    jspb.Message.setField(this, 13, value || []);
  }


  /**
   * @param {string} value
   * @param {number=} opt_index
   */
  addGrade(value: string, opt_index?: number): void {
    jspb.Message.addToRepeatedField(this, 13, value, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearGradeList() {
    this.setGradeList([]);
  }


  /**
   * repeated int32 user_list = 14;
   * @return {!Array<number>}
   */
  getUserListList(): number[] {
    return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 14));
  };


  /** @param {!Array<number>} value */
  setUserListList(value: number[]) {
    jspb.Message.setField(this, 14, value || []);
  }


  /**
   * @param {number} value
   * @param {number=} opt_index
   */
  addUserList(value: number, opt_index?: number): void {
    jspb.Message.addToRepeatedField(this, 14, value, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearUserListList() {
    this.setUserListList([]);
  }


  /**
   * repeated string status = 15;
   * @return {!Array<string>}
   */
  getStatusList(): string[] {
    return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 15));
  };


  /** @param {!Array<string>} value */
  setStatusList(value: string[]) {
    jspb.Message.setField(this, 15, value || []);
  }


  /**
   * @param {string} value
   * @param {number=} opt_index
   */
  addStatus(value: string, opt_index?: number): void {
    jspb.Message.addToRepeatedField(this, 15, value, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearStatusList() {
    this.setStatusList([]);
  }


  /**
   * repeated int32 denied_by = 16;
   * @return {!Array<number>}
   */
  getDeniedByList(): number[] {
    return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 16));
  };


  /** @param {!Array<number>} value */
  setDeniedByList(value: number[]) {
    jspb.Message.setField(this, 16, value || []);
  }


  /**
   * @param {number} value
   * @param {number=} opt_index
   */
  addDeniedBy(value: number, opt_index?: number): void {
    jspb.Message.addToRepeatedField(this, 16, value, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearDeniedByList() {
    this.setDeniedByList([]);
  }


  /**
   * repeated int32 denied_by_blackout = 17;
   * @return {!Array<number>}
   */
  getDeniedByBlackoutList(): number[] {
    return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 17));
  };


  /** @param {!Array<number>} value */
  setDeniedByBlackoutList(value: number[]) {
    jspb.Message.setField(this, 17, value || []);
  }


  /**
   * @param {number} value
   * @param {number=} opt_index
   */
  addDeniedByBlackout(value: number, opt_index?: number): void {
    jspb.Message.addToRepeatedField(this, 17, value, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearDeniedByBlackoutList() {
    this.setDeniedByBlackoutList([]);
  }


  /**
   * repeated int32 denied_by_no_party = 18;
   * @return {!Array<number>}
   */
  getDeniedByNoPartyList(): number[] {
    return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 18));
  };


  /** @param {!Array<number>} value */
  setDeniedByNoPartyList(value: number[]) {
    jspb.Message.setField(this, 18, value || []);
  }


  /**
   * @param {number} value
   * @param {number=} opt_index
   */
  addDeniedByNoParty(value: number, opt_index?: number): void {
    jspb.Message.addToRepeatedField(this, 18, value, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearDeniedByNoPartyList() {
    this.setDeniedByNoPartyList([]);
  }


}
export enum SortOrder {
  NONE = 0,
  ASC = 1,
  DESC = 2,
}
