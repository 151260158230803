import { RewardRedeemedItem } from 'libs/domain';
import { points_pb as proto } from 'libs/generated-grpc-ts';

export const fromProto = (
  msg: proto.RewardRedeemedItem,
): RewardRedeemedItem => {
  return {
    id: msg.getId(),
    reward: msg.getReward(),
    count: msg.getCount(),
  };
};

export const toProto = (item: RewardRedeemedItem): proto.RewardRedeemedItem => {
  const msg = new proto.RewardRedeemedItem();
  msg.setId(item.id);
  msg.setReward(item.reward);
  msg.setCount(item.count);
  return msg;
};
