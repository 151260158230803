import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { MgIconModule } from '@app/src/app/icon';

import { PointsBannerComponent } from './PointsBanner.component';

@NgModule({
  imports: [
    // Minga dependencies
    MgIconModule,

    // External dependencies
    CommonModule,
  ],

  declarations: [PointsBannerComponent],
  exports: [PointsBannerComponent],
})
export class PointsBannerModule {}
