import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'mg-tools-split-card',
  templateUrl: './tools-split-card.component.html',
  styleUrls: ['./tools-split-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToolsSplitCardComponent {
  // Inputs

  @Input() leftTitle: string;
  @Input() leftSubtitle: string;
  @Input() rightTitle: string;
  @Input() rightSubtitle: string;

  // Component constructor

  constructor() {}
}
