import { IConsequenceStatsPeopleResult } from 'libs/domain';
import { pbis_stats_pb } from 'libs/generated-grpc-ts';

export const toProto = (
  item: IConsequenceStatsPeopleResult,
): pbis_stats_pb.ConsequencePerson => {
  const msg = new pbis_stats_pb.ConsequencePerson();
  const info = new pbis_stats_pb.PbisPersonInfo();
  const stats = new pbis_stats_pb.ConsequencePersonStats();

  info.setId(item.person.id);
  info.setPersonHash(item.person.personHash);
  info.setRole(item.person.role);
  info.setStudentId(item.person.studentId);
  info.setBadgeIconUrl(item.person.badgeIconUrl);
  info.setFirstName(item.person.firstName);
  info.setLastName(item.person.lastName);

  stats.setTotalConsequence(item.stats.totalConsequence);
  stats.setTotalOutstandingConsequence(item.stats.totalOutstandingConsequence);
  stats.setTotalServedConsequence(item.stats.totalServedConsequence);
  stats.setTotalOverdueConsequence(item.stats.totalOverdueConsequence);

  msg.setInfo(info);
  msg.setStats(stats);
  return msg;
};

export const fromProto = (
  msg: pbis_stats_pb.ConsequencePerson,
): IConsequenceStatsPeopleResult => {
  const info = msg.getInfo();
  const stats = msg.getStats();
  return {
    person: {
      studentId: info.getStudentId(),
      id: info.getId(),
      grade: info.getGrade(),
      role: info.getRole(),
      badgeIconUrl: info.getBadgeIconUrl(),
      personHash: info.getPersonHash(),
      firstName: info.getFirstName(),
      lastName: info.getLastName(),
    },
    stats: {
      issuedTo: +stats.getIssuedTo(),
      totalConsequence: stats.getTotalConsequence(),
      totalServedConsequence: stats.getTotalServedConsequence(),
      totalOutstandingConsequence: stats.getTotalOutstandingConsequence(),
      totalOverdueConsequence: stats.getTotalOverdueConsequence(),
    },
  };
};
