import { EntityState } from '@ngrx/entity';
import { createFeatureSelector } from '@ngrx/store';
import { MemoizedSelector } from '@ngrx/store/src/selector';

import { NotificationStoreModel } from '../model/notification.model';

/**
 * Notification store just uses the default entity state, with no additional
 * fields.
 */
export type Notifications = EntityState<NotificationStoreModel>;

export const selectNotificationState: MemoizedSelector<any, Notifications> =
  createFeatureSelector<Notifications>('notifications');
