import { Injectable } from '@angular/core';

import * as localforage from 'localforage';
import { firebase } from '@app/src/firebase';

import {
  SystemAlertModalService,
  SystemAlertModalType,
} from '@shared/components/system-alert-modal';

import { FirebaseMessaging } from '../firebase/messaging';
import { AppConfigService } from '../minimal/services/AppConfig';

const REMINDER_KEY = 'notifications:reminder';

interface NotificationPrompt {
  heading?: string;
  message?: string;
}

@Injectable({ providedIn: 'root' })
export class NotificationPermissionService {
  constructor(
    private _systemModal: SystemAlertModalService,
    private _messaging: FirebaseMessaging,
    private _appConfig: AppConfigService,
  ) {}

  public async showEnablePushNotificationPrompt(
    opts?: NotificationPrompt,
  ): Promise<void> {
    const permissionStatus = await this._messaging.getPermissionStatus();

    if (permissionStatus === 'granted') return;

    const {
      heading = 'Enable Push Notifications',
      message = 'To receive real time notifications, please enable them in your notification settings.',
    } = opts || {};

    const THRESHOLD = this._appConfig.getValue('notificationsReminderDuration');
    const reminderShown = await localforage.getItem<number>(REMINDER_KEY);
    const shouldShow = !reminderShown || Date.now() - reminderShown > THRESHOLD;

    if (shouldShow) {
      const dialogRef = await this._systemModal.open({
        modalType: SystemAlertModalType.WARNING,
        heading,
        message,
        closeBtn: 'Close',
      });

      await localforage.setItem(REMINDER_KEY, Date.now());

      dialogRef.afterClosed().subscribe(response => {
        // request permission
        this._messaging.requestPermission();
      });
    }
  }
}

/**
 * Check if web push notifications are supported and enabled
 */
export const canUseWebPushApi = (): boolean =>
  firebase.messaging.isSupported() && !isWebPushDenied();

const isWebPushDenied = (): boolean =>
  !window.Notification || window.Notification.permission === 'denied';
