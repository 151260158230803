import { IModerationDetailTags, IModerationResult } from 'libs/domain';
import { gateway } from 'libs/generated-grpc-ts';

export namespace ModerationResultMapper {
  export function toIModerationResult(
    modResult: gateway.moderation_pb.ModerationResult,
  ): IModerationResult {
    const details = modResult.toObject().detailsList;
    const tags: Map<number, IModerationDetailTags> = new Map();
    const imageTags: Map<number, IModerationDetailTags> = new Map();

    for (let detail of details) {
      let categories: string[] = [];
      let imageCategories: string[] = [];
      for (let tag of detail.tagsList) {
        categories.push(tag.key);
      }
      for (let tag of detail.imageTagsList) {
        imageCategories.push(tag.key);
      }
      const currentTags = tags.get(detail.index);
      if (currentTags) {
        categories = categories.concat(currentTags.categories);
      }

      tags.set(detail.index, { categories, reasonIndex: detail.reasonIndex });

      if (imageCategories.length) {
        const currentImageTags = imageTags.get(detail.index);
        if (currentImageTags) {
          imageCategories = imageCategories.concat(currentImageTags.categories);
        }
        imageTags.set(detail.index, {
          categories: imageCategories,
          reasonIndex: detail.reasonIndex,
        });
      }
    }

    const moderationResult: IModerationResult = {
      allPassed: modResult.getAllPassed(),
      allTextContentPassed: modResult.getAllTextContentPassed(),
      allImageContentPassed: modResult.getAllImageContentPassed(),
      allImageTextContentPassed: modResult.getAllImageTextContentPassed(),
      tags,
      imageTags,
    };
    return moderationResult;
  }
}
