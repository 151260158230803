import { ContentType } from 'libs/domain';
import {
  IModeration,
  IModerationHistory,
  IModerationPair,
  IModerationProviderDetails,
  ModerationNonContentType,
} from 'libs/domain';
import { gateway } from 'libs/generated-grpc-ts';
import { ContentStatus } from 'libs/util';
import { $enum } from 'ts-enum-util';

function pairsToObjArray(
  pairs: gateway.moderation_pb.DetailedModerationResult.Pair[],
): IModerationPair[] {
  return pairs.map(tag => {
    return {
      key: tag.getKey(),
      value: tag.getValue(),
    };
  });
}

export class DetailedModerationResultWrapper implements IModeration {
  /** @internal */
  private _message: gateway.moderation_pb.DetailedModerationResult;

  private _messageDetailsToProviderDetails(
    details: gateway.moderation_pb.DetailedModerationResult.ProviderDetails,
  ): IModerationProviderDetails {
    return {
      tags: pairsToObjArray(details.getTagsList()),
      fileUrl: details.getFileurl(),
      index: details.getIndex(),
      imageTags: pairsToObjArray(details.getImageTagsList()),
      reasonIndex: details.getReasonIndex(),
    };
  }

  private _messageHistoryToModerationHistory(
    history: gateway.moderation_pb.DetailedModerationResult.ContentHistory,
  ): IModerationHistory {
    const details = history.getDetailsList().map(detail => {
      return this._messageDetailsToProviderDetails(detail);
    });
    const status = $enum(ContentStatus).getValueOrThrow(history.getStatus());

    return {
      personId: history.getPersonid(),
      timestamp: history.getTimestamp(),
      personDisplayName: history.getPersonDisplayName(),
      reason: history.getReasonindex(),
      status,
      details,
    };
  }

  get title() {
    return this._message.getTitle();
  }

  get parentContextHash() {
    return this._message.getParentContextHash();
  }

  get contentContextHash() {
    return this._message.getContentContextHash();
  }

  get mingaHash() {
    return this._message.getMingaHash();
  }

  get mingaName() {
    return this._message.getMingaName();
  }

  get contentAuthorHash() {
    return this._message.getContentAuthorHash();
  }

  get timestamp() {
    return this._message.getTimestamp();
  }

  get contentAuthorFirstName() {
    return this._message.getContentAuthorFirstName();
  }

  get contentAuthorLastName() {
    return this._message.getContentAuthorLastName();
  }

  get type() {
    const contentType = $enum(ContentType).getValueOrDefault(
      this._message.getContentType(),
    );
    const modType = $enum(ModerationNonContentType).getValueOrDefault(
      this._message.getContentType(),
    );

    return contentType || modType || ContentType.POST;
  }

  get contentHistory() {
    return this._message.getContentHistoryList().map(history => {
      return this._messageHistoryToModerationHistory(history);
    });
  }

  get status() {
    return $enum(ContentStatus).getValueOrThrow(this._message.getStatus());
  }

  get actionContextHash() {
    return this._message.getActionContextHash();
  }

  get galleryPhotoUuid() {
    return this._message.getGalleryPhotoUuid();
  }

  constructor(message: gateway.moderation_pb.DetailedModerationResult) {
    this._message = message;
  }
}
