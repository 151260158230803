import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  UrlTree,
} from '@angular/router';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AppConfigService } from '@app/src/app/minimal/services/AppConfig';

const getResolvedUrl = (route: ActivatedRouteSnapshot): string => {
  return route.pathFromRoot
    .map(v => v.url.map(segment => segment.toString()).join('/'))
    .join('/');
};

@Injectable()
export class CountryGuard implements CanActivate {
  constructor(
    private _appConfigService: AppConfigService,
    private _router: Router,
  ) {}

  canActivate(snapshot: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
    const queryParams = { ...snapshot.queryParams };
    delete queryParams.country;
    const queryParamsCountry = snapshot.queryParams.country;

    if (queryParamsCountry) {
      this._appConfigService.setAppCountry(queryParamsCountry);
    } else {
      const resolvedUrl = getResolvedUrl(snapshot);
      queryParams.redirect = btoa(resolvedUrl);
    }

    return this._appConfigService.countrySubject.pipe(
      map(country => {
        if (!country) {
          return this._router.createUrlTree(['landing', 'country'], {
            queryParams,
          });
        } else if (queryParamsCountry) {
          return this._router.createUrlTree([], {
            /* Removed unsupported properties by Angular migration: replaceUrl. */ queryParams,
          });
        }

        return true;
      }),
    );
  }
}
