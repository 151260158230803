import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { TranslateModule } from '@ngx-translate/core';

import { MgEmptyStateModule } from '@app/src/app/components/EmptyState';
import { MgImageModule } from '@app/src/app/components/MgImage';
import { MgStreamScrollerModule } from '@app/src/app/components/MgStreamScroller';
import { MgIconModule } from '@app/src/app/icon';
import { PermissionsModule } from '@app/src/app/permissions';
import { MgPipesModule } from '@app/src/app/pipes';

import { CommonUIModules } from '@shared/modules';

import { GalleryPhotoEventStreamComponent } from './GalleryPhotoEventStream.component';

@NgModule({
  imports: [
    // Minga dependencies
    PermissionsModule,
    MgImageModule,
    MgPipesModule,
    MgIconModule,
    MgEmptyStateModule,
    MgStreamScrollerModule,
    CommonUIModules,

    // External dependencies
    CommonModule,
    RouterModule,
    TranslateModule,
  ],
  declarations: [GalleryPhotoEventStreamComponent],
  exports: [GalleryPhotoEventStreamComponent],
})
export class GalleryPhotoEventStreamModule {}
