import { Injectable, NgZone } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { grpc } from '@improbable-eng/grpc-web';

import {
  AddMembersToMembershipListRequest,
  AddMembersToMembershipListResponse,
  DeleteMembershipListRequest,
  DeleteMembershipListResponse,
  ExportMembershipListChunk,
  ExportMembershipListRequest,
  GetMembershipListMembersRequest,
  GetMembershipListMembersResponse,
  GetMembershipListRequest,
  GetMembershipListResponse,
  GetMembershipListsRequest,
  GetMembershipListsResponse,
  GetMembershipsForPersonRequest,
  GetMembershipsForPersonResponse,
  RemoveAllMembersFromListRequest,
  RemoveAllMembersFromListResponse,
  RemoveMembersToMembershipListRequest,
  RemoveMembersToMembershipListResponse,
  UpdateMembershipListRequest,
  UpdateMembershipListResponse,
} from '../membership_list/membership_list_pb';

import {
  MembershipListManager as __MembershipListManager,
} from '../membership_list/membership_list_pb_service';

@Injectable({providedIn: 'root'})
export class MembershipListManager {

  constructor(private _ngZone: NgZone) {
  }

  updateMembershipList(request: UpdateMembershipListRequest): Promise<UpdateMembershipListResponse>;
  updateMembershipList(request: UpdateMembershipListRequest, metadata: grpc.Metadata): Promise<UpdateMembershipListResponse>;
  updateMembershipList(request: UpdateMembershipListRequest, callback: (err: any|null, response: UpdateMembershipListResponse, metadata: grpc.Metadata) => void): void;
  updateMembershipList(request: UpdateMembershipListRequest, metadata: grpc.Metadata, callback: (err: any|null, response: UpdateMembershipListResponse, metadata: grpc.Metadata) => void): void;

  updateMembershipList(request: UpdateMembershipListRequest, arg1?: grpc.Metadata|((err: any|null, response: UpdateMembershipListResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: UpdateMembershipListResponse, metadata: grpc.Metadata) => void): Promise<UpdateMembershipListResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<UpdateMembershipListResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__MembershipListManager.UpdateMembershipList, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  deleteMembershipList(request: DeleteMembershipListRequest): Promise<DeleteMembershipListResponse>;
  deleteMembershipList(request: DeleteMembershipListRequest, metadata: grpc.Metadata): Promise<DeleteMembershipListResponse>;
  deleteMembershipList(request: DeleteMembershipListRequest, callback: (err: any|null, response: DeleteMembershipListResponse, metadata: grpc.Metadata) => void): void;
  deleteMembershipList(request: DeleteMembershipListRequest, metadata: grpc.Metadata, callback: (err: any|null, response: DeleteMembershipListResponse, metadata: grpc.Metadata) => void): void;

  deleteMembershipList(request: DeleteMembershipListRequest, arg1?: grpc.Metadata|((err: any|null, response: DeleteMembershipListResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: DeleteMembershipListResponse, metadata: grpc.Metadata) => void): Promise<DeleteMembershipListResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<DeleteMembershipListResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__MembershipListManager.DeleteMembershipList, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  getMembershipLists(request: GetMembershipListsRequest): Promise<GetMembershipListsResponse>;
  getMembershipLists(request: GetMembershipListsRequest, metadata: grpc.Metadata): Promise<GetMembershipListsResponse>;
  getMembershipLists(request: GetMembershipListsRequest, callback: (err: any|null, response: GetMembershipListsResponse, metadata: grpc.Metadata) => void): void;
  getMembershipLists(request: GetMembershipListsRequest, metadata: grpc.Metadata, callback: (err: any|null, response: GetMembershipListsResponse, metadata: grpc.Metadata) => void): void;

  getMembershipLists(request: GetMembershipListsRequest, arg1?: grpc.Metadata|((err: any|null, response: GetMembershipListsResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: GetMembershipListsResponse, metadata: grpc.Metadata) => void): Promise<GetMembershipListsResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<GetMembershipListsResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__MembershipListManager.GetMembershipLists, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  getMembershipList(request: GetMembershipListRequest): Promise<GetMembershipListResponse>;
  getMembershipList(request: GetMembershipListRequest, metadata: grpc.Metadata): Promise<GetMembershipListResponse>;
  getMembershipList(request: GetMembershipListRequest, callback: (err: any|null, response: GetMembershipListResponse, metadata: grpc.Metadata) => void): void;
  getMembershipList(request: GetMembershipListRequest, metadata: grpc.Metadata, callback: (err: any|null, response: GetMembershipListResponse, metadata: grpc.Metadata) => void): void;

  getMembershipList(request: GetMembershipListRequest, arg1?: grpc.Metadata|((err: any|null, response: GetMembershipListResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: GetMembershipListResponse, metadata: grpc.Metadata) => void): Promise<GetMembershipListResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<GetMembershipListResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__MembershipListManager.GetMembershipList, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  addMembersToMembershipList(request: AddMembersToMembershipListRequest): Promise<AddMembersToMembershipListResponse>;
  addMembersToMembershipList(request: AddMembersToMembershipListRequest, metadata: grpc.Metadata): Promise<AddMembersToMembershipListResponse>;
  addMembersToMembershipList(request: AddMembersToMembershipListRequest, callback: (err: any|null, response: AddMembersToMembershipListResponse, metadata: grpc.Metadata) => void): void;
  addMembersToMembershipList(request: AddMembersToMembershipListRequest, metadata: grpc.Metadata, callback: (err: any|null, response: AddMembersToMembershipListResponse, metadata: grpc.Metadata) => void): void;

  addMembersToMembershipList(request: AddMembersToMembershipListRequest, arg1?: grpc.Metadata|((err: any|null, response: AddMembersToMembershipListResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: AddMembersToMembershipListResponse, metadata: grpc.Metadata) => void): Promise<AddMembersToMembershipListResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<AddMembersToMembershipListResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__MembershipListManager.AddMembersToMembershipList, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  removeMembersFromMembershipList(request: RemoveMembersToMembershipListRequest): Promise<RemoveMembersToMembershipListResponse>;
  removeMembersFromMembershipList(request: RemoveMembersToMembershipListRequest, metadata: grpc.Metadata): Promise<RemoveMembersToMembershipListResponse>;
  removeMembersFromMembershipList(request: RemoveMembersToMembershipListRequest, callback: (err: any|null, response: RemoveMembersToMembershipListResponse, metadata: grpc.Metadata) => void): void;
  removeMembersFromMembershipList(request: RemoveMembersToMembershipListRequest, metadata: grpc.Metadata, callback: (err: any|null, response: RemoveMembersToMembershipListResponse, metadata: grpc.Metadata) => void): void;

  removeMembersFromMembershipList(request: RemoveMembersToMembershipListRequest, arg1?: grpc.Metadata|((err: any|null, response: RemoveMembersToMembershipListResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: RemoveMembersToMembershipListResponse, metadata: grpc.Metadata) => void): Promise<RemoveMembersToMembershipListResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<RemoveMembersToMembershipListResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__MembershipListManager.RemoveMembersFromMembershipList, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  getMembershipListMembers(request: GetMembershipListMembersRequest): Promise<GetMembershipListMembersResponse>;
  getMembershipListMembers(request: GetMembershipListMembersRequest, metadata: grpc.Metadata): Promise<GetMembershipListMembersResponse>;
  getMembershipListMembers(request: GetMembershipListMembersRequest, callback: (err: any|null, response: GetMembershipListMembersResponse, metadata: grpc.Metadata) => void): void;
  getMembershipListMembers(request: GetMembershipListMembersRequest, metadata: grpc.Metadata, callback: (err: any|null, response: GetMembershipListMembersResponse, metadata: grpc.Metadata) => void): void;

  getMembershipListMembers(request: GetMembershipListMembersRequest, arg1?: grpc.Metadata|((err: any|null, response: GetMembershipListMembersResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: GetMembershipListMembersResponse, metadata: grpc.Metadata) => void): Promise<GetMembershipListMembersResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<GetMembershipListMembersResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__MembershipListManager.GetMembershipListMembers, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  getMembershipsForPerson(request: GetMembershipsForPersonRequest): Promise<GetMembershipsForPersonResponse>;
  getMembershipsForPerson(request: GetMembershipsForPersonRequest, metadata: grpc.Metadata): Promise<GetMembershipsForPersonResponse>;
  getMembershipsForPerson(request: GetMembershipsForPersonRequest, callback: (err: any|null, response: GetMembershipsForPersonResponse, metadata: grpc.Metadata) => void): void;
  getMembershipsForPerson(request: GetMembershipsForPersonRequest, metadata: grpc.Metadata, callback: (err: any|null, response: GetMembershipsForPersonResponse, metadata: grpc.Metadata) => void): void;

  getMembershipsForPerson(request: GetMembershipsForPersonRequest, arg1?: grpc.Metadata|((err: any|null, response: GetMembershipsForPersonResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: GetMembershipsForPersonResponse, metadata: grpc.Metadata) => void): Promise<GetMembershipsForPersonResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<GetMembershipsForPersonResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__MembershipListManager.GetMembershipsForPerson, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  removeAllMembersFromList(request: RemoveAllMembersFromListRequest): Promise<RemoveAllMembersFromListResponse>;
  removeAllMembersFromList(request: RemoveAllMembersFromListRequest, metadata: grpc.Metadata): Promise<RemoveAllMembersFromListResponse>;
  removeAllMembersFromList(request: RemoveAllMembersFromListRequest, callback: (err: any|null, response: RemoveAllMembersFromListResponse, metadata: grpc.Metadata) => void): void;
  removeAllMembersFromList(request: RemoveAllMembersFromListRequest, metadata: grpc.Metadata, callback: (err: any|null, response: RemoveAllMembersFromListResponse, metadata: grpc.Metadata) => void): void;

  removeAllMembersFromList(request: RemoveAllMembersFromListRequest, arg1?: grpc.Metadata|((err: any|null, response: RemoveAllMembersFromListResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: RemoveAllMembersFromListResponse, metadata: grpc.Metadata) => void): Promise<RemoveAllMembersFromListResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<RemoveAllMembersFromListResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__MembershipListManager.RemoveAllMembersFromList, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  exportMembershipList(request: ExportMembershipListRequest): {cancel():void;close():void}&Observable<ExportMembershipListChunk>;
  exportMembershipList(request: ExportMembershipListRequest, metadata: grpc.Metadata): {cancel():void;close():void}&Observable<ExportMembershipListChunk>;
  exportMembershipList(request: ExportMembershipListRequest, onMessage: (message?: ExportMembershipListChunk) => void,onError?: (err: any) => void,onEnd?: (code: number, msg: string|undefined, metadata: grpc.Metadata) => void): void;
  exportMembershipList(request: ExportMembershipListRequest, metadata: grpc.Metadata, onMessage: (message?: ExportMembershipListChunk) => void,onError?: (err: any) => void,onEnd?: (code: number, msg: string|undefined, metadata: grpc.Metadata) => void): void;

  exportMembershipList(request: ExportMembershipListRequest, arg1?: grpc.Metadata|((message?: ExportMembershipListChunk) => void), arg2?: ((message?: ExportMembershipListChunk) => void)|((err: any) => void), arg3?: ((err: any) => void)|((code: number, msg: string|undefined, metadata: grpc.Metadata) => void), arg4?: (code: number, msg: string|undefined, metadata: grpc.Metadata) => void): {cancel():void;close():void}&Observable<ExportMembershipListChunk>|void {
    let ret: any;
    let metadata: any;

    let onMessage: any;
    let onError: any;
    let onEnd: any;
    if(typeof arg1 === 'function') {
      onMessage = arg1;
      onError = arg2;
      onEnd = arg3;
    } else if(typeof arg2 === 'function') {
      metadata = arg1;
      onMessage = arg2;
      onError = arg3;
      onEnd = arg4;
    } else {
      metadata = arg1;
    }

    if(!onMessage) {
      let subject = new Subject<ExportMembershipListChunk>();
      ret = subject.asObservable();

      onMessage = (response: any) => {
        subject.next(response);
      };

      onError = (err: any) => {
        subject.error(err);
      };

      onEnd = (code: any, msg: any, metadata: any) => {
        subject.complete();
      };

    } else {
      if(!onError) {
        onError = (err: any) => console.error(err);
      }

      if(!onEnd) {
        onEnd = (code: any, msg: any, metadata: any) => {};
      }
    }

    let req = grpc.invoke(__MembershipListManager.ExportMembershipList, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onMessage: response => this._ngZone.run(() => {
        onMessage(response);
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code == grpc.Code.OK) {
          onEnd(code, msg, metadata);
        } else {
          let err = new Error(code + ' ' + (msg||''));
          (<any>err).code = code;
          onError(err);
        }
      })
    });

    (<any>ret).close = () => {
      console.warn('[Angular Grpc] .close() is deprecated use .cancel() instead');
      return req.close();
    };
    (<any>ret).cancel = () => req.close();
    return ret;
  }

}

