// source: gateway/feed.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

import * as jspb from 'google-protobuf';
import * as common_legacy_pb from '../common/legacy_pb';
import * as common_stream_pb from '../common/stream_pb';
import * as gateway_content_views_pb from '../gateway/content_views_pb';
import * as gateway_person_view_pb from '../gateway/person_view_pb';
export namespace ContentFeedItemView {
  export interface AsObject {
    contentContextHash?: common_legacy_pb.UniqueHash.AsObject;
    pinned: boolean;
    shortCard?: gateway_content_views_pb.ShortCardView.AsObject;
    shortEventCard?: gateway_content_views_pb.ShortEventCardView.AsObject;
    shortAnnoucementCard?: gateway_content_views_pb.ShortAnnouncementCardView.AsObject;
    shortInspirationCard?: gateway_content_views_pb.ShortInspirationCardView.AsObject;
    shortBirthdayCard?: gateway_content_views_pb.ShortBirthdayCardView.AsObject;
    shortPollCard?: gateway_content_views_pb.ShortPollCardView.AsObject;
    shortFileCard?: gateway_content_views_pb.ShortFileCardView.AsObject;
    shortChallengeCard?: gateway_content_views_pb.ShortChallengeCardView.AsObject;
    shortSmsMessageCard?: gateway_content_views_pb.ShortSmsMessageCardView.AsObject;
    allowedCommentingRoleTypesList: string[];
  }
}
export class ContentFeedItemView extends jspb.Message {
  static readonly displayName = "ContentFeedItemView";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [10];

  /**
   * Oneof group definitions for this message. Each group defines the field
   * numbers belonging to that group. When of these fields' value is set, all
   * other fields in the group are cleared. During deserialization, if multiple
   * fields are encountered for a group, only the last value seen will be kept.
   * @private {!Array<!Array<number>>}
   * @const
   */
  static readonly oneofGroups_ = [[2,3,4,5,6,7,9,11,12]];


  /**
   * @return {ContentFeedItemView.ViewCase}
   */
  getViewCase() {
    return /** @type {ContentFeedItemView.ViewCase} */(jspb.Message.computeOneofCase(this, ContentFeedItemView.oneofGroups_[0]));
  }

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, ContentFeedItemView.repeatedFields_, ContentFeedItemView.oneofGroups_);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): ContentFeedItemView.AsObject {
    return ContentFeedItemView.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!ContentFeedItemView} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: ContentFeedItemView) {
    var f: any;
    var obj: any = {
      contentContextHash: (f = msg.getContentContextHash()) && common_legacy_pb.UniqueHash.toObject(includeInstance, f),
      pinned: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
      shortCard: (f = msg.getShortCard()) && gateway_content_views_pb.ShortCardView.toObject(includeInstance, f),
      shortEventCard: (f = msg.getShortEventCard()) && gateway_content_views_pb.ShortEventCardView.toObject(includeInstance, f),
      shortAnnoucementCard: (f = msg.getShortAnnoucementCard()) && gateway_content_views_pb.ShortAnnouncementCardView.toObject(includeInstance, f),
      shortInspirationCard: (f = msg.getShortInspirationCard()) && gateway_content_views_pb.ShortInspirationCardView.toObject(includeInstance, f),
      shortBirthdayCard: (f = msg.getShortBirthdayCard()) && gateway_content_views_pb.ShortBirthdayCardView.toObject(includeInstance, f),
      shortPollCard: (f = msg.getShortPollCard()) && gateway_content_views_pb.ShortPollCardView.toObject(includeInstance, f),
      shortFileCard: (f = msg.getShortFileCard()) && gateway_content_views_pb.ShortFileCardView.toObject(includeInstance, f),
      shortChallengeCard: (f = msg.getShortChallengeCard()) && gateway_content_views_pb.ShortChallengeCardView.toObject(includeInstance, f),
      shortSmsMessageCard: (f = msg.getShortSmsMessageCard()) && gateway_content_views_pb.ShortSmsMessageCardView.toObject(includeInstance, f),
      allowedCommentingRoleTypesList: (f = jspb.Message.getRepeatedField(msg, 10)) == null ? undefined : f
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!ContentFeedItemView}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new ContentFeedItemView;
    return ContentFeedItemView.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!ContentFeedItemView} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!ContentFeedItemView}
   */
  static deserializeBinaryFromReader(msg: ContentFeedItemView, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new common_legacy_pb.UniqueHash;
        reader.readMessage(value1,common_legacy_pb.UniqueHash.deserializeBinaryFromReader);
        msg.setContentContextHash(value1);
        break;
      case 8:
        var value8 = /** @type {boolean} */ (reader.readBool());
        msg.setPinned(value8);
        break;
      case 2:
        var value2 = new gateway_content_views_pb.ShortCardView;
        reader.readMessage(value2,gateway_content_views_pb.ShortCardView.deserializeBinaryFromReader);
        msg.setShortCard(value2);
        break;
      case 3:
        var value3 = new gateway_content_views_pb.ShortEventCardView;
        reader.readMessage(value3,gateway_content_views_pb.ShortEventCardView.deserializeBinaryFromReader);
        msg.setShortEventCard(value3);
        break;
      case 4:
        var value4 = new gateway_content_views_pb.ShortAnnouncementCardView;
        reader.readMessage(value4,gateway_content_views_pb.ShortAnnouncementCardView.deserializeBinaryFromReader);
        msg.setShortAnnoucementCard(value4);
        break;
      case 5:
        var value5 = new gateway_content_views_pb.ShortInspirationCardView;
        reader.readMessage(value5,gateway_content_views_pb.ShortInspirationCardView.deserializeBinaryFromReader);
        msg.setShortInspirationCard(value5);
        break;
      case 6:
        var value6 = new gateway_content_views_pb.ShortBirthdayCardView;
        reader.readMessage(value6,gateway_content_views_pb.ShortBirthdayCardView.deserializeBinaryFromReader);
        msg.setShortBirthdayCard(value6);
        break;
      case 7:
        var value7 = new gateway_content_views_pb.ShortPollCardView;
        reader.readMessage(value7,gateway_content_views_pb.ShortPollCardView.deserializeBinaryFromReader);
        msg.setShortPollCard(value7);
        break;
      case 9:
        var value9 = new gateway_content_views_pb.ShortFileCardView;
        reader.readMessage(value9,gateway_content_views_pb.ShortFileCardView.deserializeBinaryFromReader);
        msg.setShortFileCard(value9);
        break;
      case 11:
        var value11 = new gateway_content_views_pb.ShortChallengeCardView;
        reader.readMessage(value11,gateway_content_views_pb.ShortChallengeCardView.deserializeBinaryFromReader);
        msg.setShortChallengeCard(value11);
        break;
      case 12:
        var value12 = new gateway_content_views_pb.ShortSmsMessageCardView;
        reader.readMessage(value12,gateway_content_views_pb.ShortSmsMessageCardView.deserializeBinaryFromReader);
        msg.setShortSmsMessageCard(value12);
        break;
      case 10:
        var value10 = /** @type {string} */ (reader.readString());
        msg.addAllowedCommentingRoleTypes(value10);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!ContentFeedItemView} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: ContentFeedItemView, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getContentContextHash();
    if (f != null) {
      writer.writeMessage(
        1,
        f,
        common_legacy_pb.UniqueHash.serializeBinaryToWriter
      );
    }
    f = message.getPinned();
    if (f) {
      writer.writeBool(
        8,
        f
      );
    }
    f = message.getShortCard();
    if (f != null) {
      writer.writeMessage(
        2,
        f,
        gateway_content_views_pb.ShortCardView.serializeBinaryToWriter
      );
    }
    f = message.getShortEventCard();
    if (f != null) {
      writer.writeMessage(
        3,
        f,
        gateway_content_views_pb.ShortEventCardView.serializeBinaryToWriter
      );
    }
    f = message.getShortAnnoucementCard();
    if (f != null) {
      writer.writeMessage(
        4,
        f,
        gateway_content_views_pb.ShortAnnouncementCardView.serializeBinaryToWriter
      );
    }
    f = message.getShortInspirationCard();
    if (f != null) {
      writer.writeMessage(
        5,
        f,
        gateway_content_views_pb.ShortInspirationCardView.serializeBinaryToWriter
      );
    }
    f = message.getShortBirthdayCard();
    if (f != null) {
      writer.writeMessage(
        6,
        f,
        gateway_content_views_pb.ShortBirthdayCardView.serializeBinaryToWriter
      );
    }
    f = message.getShortPollCard();
    if (f != null) {
      writer.writeMessage(
        7,
        f,
        gateway_content_views_pb.ShortPollCardView.serializeBinaryToWriter
      );
    }
    f = message.getShortFileCard();
    if (f != null) {
      writer.writeMessage(
        9,
        f,
        gateway_content_views_pb.ShortFileCardView.serializeBinaryToWriter
      );
    }
    f = message.getShortChallengeCard();
    if (f != null) {
      writer.writeMessage(
        11,
        f,
        gateway_content_views_pb.ShortChallengeCardView.serializeBinaryToWriter
      );
    }
    f = message.getShortSmsMessageCard();
    if (f != null) {
      writer.writeMessage(
        12,
        f,
        gateway_content_views_pb.ShortSmsMessageCardView.serializeBinaryToWriter
      );
    }
    f = message.getAllowedCommentingRoleTypesList();
    if (f.length > 0) {
      writer.writeRepeatedString(
        10,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    ContentFeedItemView.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional UniqueHash content_context_hash = 1;
   * @return {?UniqueHash}
   */
  getContentContextHash(): common_legacy_pb.UniqueHash {
    return /** @type{?UniqueHash} */ (
      jspb.Message.getWrapperField(this, common_legacy_pb.UniqueHash, 1));
  }


  /** @param {?UniqueHash|undefined} value */
  setContentContextHash(value?: common_legacy_pb.UniqueHash) {
    jspb.Message.setWrapperField(this, 1, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearContentContextHash() {
    this.setContentContextHash(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasContentContextHash(): boolean {
    return jspb.Message.getField(this, 1) != null;
  }


  /**
   * optional bool pinned = 8;
   * @return {boolean}
   */
  getPinned(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
  };


  /** @param {boolean} value */
  setPinned(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 8, value);
  }


  /**
   * optional ShortCardView short_card = 2;
   * @return {?ShortCardView}
   */
  getShortCard(): gateway_content_views_pb.ShortCardView {
    return /** @type{?ShortCardView} */ (
      jspb.Message.getWrapperField(this, gateway_content_views_pb.ShortCardView, 2));
  }


  /** @param {?ShortCardView|undefined} value */
  setShortCard(value?: gateway_content_views_pb.ShortCardView) {
    jspb.Message.setOneofWrapperField(this, 2, ContentFeedItemView.oneofGroups_[0], value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearShortCard() {
    this.setShortCard(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasShortCard(): boolean {
    return jspb.Message.getField(this, 2) != null;
  }


  /**
   * optional ShortEventCardView short_event_card = 3;
   * @return {?ShortEventCardView}
   */
  getShortEventCard(): gateway_content_views_pb.ShortEventCardView {
    return /** @type{?ShortEventCardView} */ (
      jspb.Message.getWrapperField(this, gateway_content_views_pb.ShortEventCardView, 3));
  }


  /** @param {?ShortEventCardView|undefined} value */
  setShortEventCard(value?: gateway_content_views_pb.ShortEventCardView) {
    jspb.Message.setOneofWrapperField(this, 3, ContentFeedItemView.oneofGroups_[0], value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearShortEventCard() {
    this.setShortEventCard(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasShortEventCard(): boolean {
    return jspb.Message.getField(this, 3) != null;
  }


  /**
   * optional ShortAnnouncementCardView short_annoucement_card = 4;
   * @return {?ShortAnnouncementCardView}
   */
  getShortAnnoucementCard(): gateway_content_views_pb.ShortAnnouncementCardView {
    return /** @type{?ShortAnnouncementCardView} */ (
      jspb.Message.getWrapperField(this, gateway_content_views_pb.ShortAnnouncementCardView, 4));
  }


  /** @param {?ShortAnnouncementCardView|undefined} value */
  setShortAnnoucementCard(value?: gateway_content_views_pb.ShortAnnouncementCardView) {
    jspb.Message.setOneofWrapperField(this, 4, ContentFeedItemView.oneofGroups_[0], value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearShortAnnoucementCard() {
    this.setShortAnnoucementCard(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasShortAnnoucementCard(): boolean {
    return jspb.Message.getField(this, 4) != null;
  }


  /**
   * optional ShortInspirationCardView short_inspiration_card = 5;
   * @return {?ShortInspirationCardView}
   */
  getShortInspirationCard(): gateway_content_views_pb.ShortInspirationCardView {
    return /** @type{?ShortInspirationCardView} */ (
      jspb.Message.getWrapperField(this, gateway_content_views_pb.ShortInspirationCardView, 5));
  }


  /** @param {?ShortInspirationCardView|undefined} value */
  setShortInspirationCard(value?: gateway_content_views_pb.ShortInspirationCardView) {
    jspb.Message.setOneofWrapperField(this, 5, ContentFeedItemView.oneofGroups_[0], value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearShortInspirationCard() {
    this.setShortInspirationCard(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasShortInspirationCard(): boolean {
    return jspb.Message.getField(this, 5) != null;
  }


  /**
   * optional ShortBirthdayCardView short_birthday_card = 6;
   * @return {?ShortBirthdayCardView}
   */
  getShortBirthdayCard(): gateway_content_views_pb.ShortBirthdayCardView {
    return /** @type{?ShortBirthdayCardView} */ (
      jspb.Message.getWrapperField(this, gateway_content_views_pb.ShortBirthdayCardView, 6));
  }


  /** @param {?ShortBirthdayCardView|undefined} value */
  setShortBirthdayCard(value?: gateway_content_views_pb.ShortBirthdayCardView) {
    jspb.Message.setOneofWrapperField(this, 6, ContentFeedItemView.oneofGroups_[0], value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearShortBirthdayCard() {
    this.setShortBirthdayCard(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasShortBirthdayCard(): boolean {
    return jspb.Message.getField(this, 6) != null;
  }


  /**
   * optional ShortPollCardView short_poll_card = 7;
   * @return {?ShortPollCardView}
   */
  getShortPollCard(): gateway_content_views_pb.ShortPollCardView {
    return /** @type{?ShortPollCardView} */ (
      jspb.Message.getWrapperField(this, gateway_content_views_pb.ShortPollCardView, 7));
  }


  /** @param {?ShortPollCardView|undefined} value */
  setShortPollCard(value?: gateway_content_views_pb.ShortPollCardView) {
    jspb.Message.setOneofWrapperField(this, 7, ContentFeedItemView.oneofGroups_[0], value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearShortPollCard() {
    this.setShortPollCard(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasShortPollCard(): boolean {
    return jspb.Message.getField(this, 7) != null;
  }


  /**
   * optional ShortFileCardView short_file_card = 9;
   * @return {?ShortFileCardView}
   */
  getShortFileCard(): gateway_content_views_pb.ShortFileCardView {
    return /** @type{?ShortFileCardView} */ (
      jspb.Message.getWrapperField(this, gateway_content_views_pb.ShortFileCardView, 9));
  }


  /** @param {?ShortFileCardView|undefined} value */
  setShortFileCard(value?: gateway_content_views_pb.ShortFileCardView) {
    jspb.Message.setOneofWrapperField(this, 9, ContentFeedItemView.oneofGroups_[0], value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearShortFileCard() {
    this.setShortFileCard(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasShortFileCard(): boolean {
    return jspb.Message.getField(this, 9) != null;
  }


  /**
   * optional ShortChallengeCardView short_challenge_card = 11;
   * @return {?ShortChallengeCardView}
   */
  getShortChallengeCard(): gateway_content_views_pb.ShortChallengeCardView {
    return /** @type{?ShortChallengeCardView} */ (
      jspb.Message.getWrapperField(this, gateway_content_views_pb.ShortChallengeCardView, 11));
  }


  /** @param {?ShortChallengeCardView|undefined} value */
  setShortChallengeCard(value?: gateway_content_views_pb.ShortChallengeCardView) {
    jspb.Message.setOneofWrapperField(this, 11, ContentFeedItemView.oneofGroups_[0], value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearShortChallengeCard() {
    this.setShortChallengeCard(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasShortChallengeCard(): boolean {
    return jspb.Message.getField(this, 11) != null;
  }


  /**
   * optional ShortSmsMessageCardView short_sms_message_card = 12;
   * @return {?ShortSmsMessageCardView}
   */
  getShortSmsMessageCard(): gateway_content_views_pb.ShortSmsMessageCardView {
    return /** @type{?ShortSmsMessageCardView} */ (
      jspb.Message.getWrapperField(this, gateway_content_views_pb.ShortSmsMessageCardView, 12));
  }


  /** @param {?ShortSmsMessageCardView|undefined} value */
  setShortSmsMessageCard(value?: gateway_content_views_pb.ShortSmsMessageCardView) {
    jspb.Message.setOneofWrapperField(this, 12, ContentFeedItemView.oneofGroups_[0], value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearShortSmsMessageCard() {
    this.setShortSmsMessageCard(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasShortSmsMessageCard(): boolean {
    return jspb.Message.getField(this, 12) != null;
  }


  /**
   * repeated string allowed_commenting_role_types = 10;
   * @return {!Array<string>}
   */
  getAllowedCommentingRoleTypesList(): string[] {
    return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 10));
  };


  /** @param {!Array<string>} value */
  setAllowedCommentingRoleTypesList(value: string[]) {
    jspb.Message.setField(this, 10, value || []);
  }


  /**
   * @param {string} value
   * @param {number=} opt_index
   */
  addAllowedCommentingRoleTypes(value: string, opt_index?: number): void {
    jspb.Message.addToRepeatedField(this, 10, value, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearAllowedCommentingRoleTypesList() {
    this.setAllowedCommentingRoleTypesList([]);
  }


}
export namespace ContentFeedItemView {
export enum ViewCase {
  VIEW_NOT_SET = 0,
  SHORT_CARD = 2,
  SHORT_EVENT_CARD = 3,
  SHORT_ANNOUCEMENT_CARD = 4,
  SHORT_INSPIRATION_CARD = 5,
  SHORT_BIRTHDAY_CARD = 6,
  SHORT_POLL_CARD = 7,
  SHORT_FILE_CARD = 9,
  SHORT_CHALLENGE_CARD = 11,
  SHORT_SMS_MESSAGE_CARD = 12,
}
} // namespace ContentFeedItemView
export namespace TopOfFeedText {
  export interface AsObject {
    prefix: string;
    text: string;
    timestamp: number;
  }
}
export class TopOfFeedText extends jspb.Message {
  static readonly displayName = "TopOfFeedText";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): TopOfFeedText.AsObject {
    return TopOfFeedText.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!TopOfFeedText} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: TopOfFeedText) {
    var f: any;
    var obj: any = {
      prefix: jspb.Message.getFieldWithDefault(msg, 1, ""),
      text: jspb.Message.getFieldWithDefault(msg, 2, ""),
      timestamp: jspb.Message.getFieldWithDefault(msg, 3, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!TopOfFeedText}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new TopOfFeedText;
    return TopOfFeedText.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!TopOfFeedText} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!TopOfFeedText}
   */
  static deserializeBinaryFromReader(msg: TopOfFeedText, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setPrefix(value1);
        break;
      case 2:
        var value2 = /** @type {string} */ (reader.readString());
        msg.setText(value2);
        break;
      case 3:
        var value3 = /** @type {number} */ (reader.readInt64());
        msg.setTimestamp(value3);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!TopOfFeedText} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: TopOfFeedText, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getPrefix();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
    f = message.getText();
    if (f.length > 0) {
      writer.writeString(
        2,
        f
      );
    }
    f = message.getTimestamp();
    if (f !== 0) {
      writer.writeInt64(
        3,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    TopOfFeedText.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string prefix = 1;
   * @return {string}
   */
  getPrefix(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setPrefix(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


  /**
   * optional string text = 2;
   * @return {string}
   */
  getText(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
  };


  /** @param {string} value */
  setText(value: string) {
    jspb.Message.setProto3StringField(this, 2, value);
  }


  /**
   * optional int64 timestamp = 3;
   * @return {number}
   */
  getTimestamp(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
  };


  /** @param {number} value */
  setTimestamp(value: number) {
    jspb.Message.setProto3IntField(this, 3, value);
  }


}
export namespace StreamContentFeedResponse {
  export interface AsObject {
    itemMetadata?: common_stream_pb.StreamItemMetadata.AsObject;
    item?: ContentFeedItemView.AsObject;
  }
}
export class StreamContentFeedResponse extends jspb.Message {
  static readonly displayName = "StreamContentFeedResponse";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): StreamContentFeedResponse.AsObject {
    return StreamContentFeedResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!StreamContentFeedResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: StreamContentFeedResponse) {
    var f: any;
    var obj: any = {
      itemMetadata: (f = msg.getItemMetadata()) && common_stream_pb.StreamItemMetadata.toObject(includeInstance, f),
      item: (f = msg.getItem()) && ContentFeedItemView.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!StreamContentFeedResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new StreamContentFeedResponse;
    return StreamContentFeedResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!StreamContentFeedResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!StreamContentFeedResponse}
   */
  static deserializeBinaryFromReader(msg: StreamContentFeedResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new common_stream_pb.StreamItemMetadata;
        reader.readMessage(value1,common_stream_pb.StreamItemMetadata.deserializeBinaryFromReader);
        msg.setItemMetadata(value1);
        break;
      case 2:
        var value2 = new ContentFeedItemView;
        reader.readMessage(value2,ContentFeedItemView.deserializeBinaryFromReader);
        msg.setItem(value2);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!StreamContentFeedResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: StreamContentFeedResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getItemMetadata();
    if (f != null) {
      writer.writeMessage(
        1,
        f,
        common_stream_pb.StreamItemMetadata.serializeBinaryToWriter
      );
    }
    f = message.getItem();
    if (f != null) {
      writer.writeMessage(
        2,
        f,
        ContentFeedItemView.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    StreamContentFeedResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional StreamItemMetadata item_metadata = 1;
   * @return {?StreamItemMetadata}
   */
  getItemMetadata(): common_stream_pb.StreamItemMetadata {
    return /** @type{?StreamItemMetadata} */ (
      jspb.Message.getWrapperField(this, common_stream_pb.StreamItemMetadata, 1));
  }


  /** @param {?StreamItemMetadata|undefined} value */
  setItemMetadata(value?: common_stream_pb.StreamItemMetadata) {
    jspb.Message.setWrapperField(this, 1, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearItemMetadata() {
    this.setItemMetadata(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasItemMetadata(): boolean {
    return jspb.Message.getField(this, 1) != null;
  }


  /**
   * optional ContentFeedItemView item = 2;
   * @return {?ContentFeedItemView}
   */
  getItem(): ContentFeedItemView {
    return /** @type{?ContentFeedItemView} */ (
      jspb.Message.getWrapperField(this, ContentFeedItemView, 2));
  }


  /** @param {?ContentFeedItemView|undefined} value */
  setItem(value?: ContentFeedItemView) {
    jspb.Message.setWrapperField(this, 2, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearItem() {
    this.setItem(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasItem(): boolean {
    return jspb.Message.getField(this, 2) != null;
  }


}
export namespace GetContentFeedRequest {
  export interface AsObject {
    streamFilter?: common_stream_pb.StreamFilter.AsObject;
    offset: number;
    limit: number;
  }
}
export class GetContentFeedRequest extends jspb.Message {
  static readonly displayName = "GetContentFeedRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): GetContentFeedRequest.AsObject {
    return GetContentFeedRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!GetContentFeedRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: GetContentFeedRequest) {
    var f: any;
    var obj: any = {
      streamFilter: (f = msg.getStreamFilter()) && common_stream_pb.StreamFilter.toObject(includeInstance, f),
      offset: jspb.Message.getFieldWithDefault(msg, 2, 0),
      limit: jspb.Message.getFieldWithDefault(msg, 3, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!GetContentFeedRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new GetContentFeedRequest;
    return GetContentFeedRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!GetContentFeedRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!GetContentFeedRequest}
   */
  static deserializeBinaryFromReader(msg: GetContentFeedRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new common_stream_pb.StreamFilter;
        reader.readMessage(value1,common_stream_pb.StreamFilter.deserializeBinaryFromReader);
        msg.setStreamFilter(value1);
        break;
      case 2:
        var value2 = /** @type {number} */ (reader.readInt32());
        msg.setOffset(value2);
        break;
      case 3:
        var value3 = /** @type {number} */ (reader.readInt32());
        msg.setLimit(value3);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!GetContentFeedRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: GetContentFeedRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getStreamFilter();
    if (f != null) {
      writer.writeMessage(
        1,
        f,
        common_stream_pb.StreamFilter.serializeBinaryToWriter
      );
    }
    f = message.getOffset();
    if (f !== 0) {
      writer.writeInt32(
        2,
        f
      );
    }
    f = message.getLimit();
    if (f !== 0) {
      writer.writeInt32(
        3,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    GetContentFeedRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional StreamFilter stream_filter = 1;
   * @return {?StreamFilter}
   */
  getStreamFilter(): common_stream_pb.StreamFilter {
    return /** @type{?StreamFilter} */ (
      jspb.Message.getWrapperField(this, common_stream_pb.StreamFilter, 1));
  }


  /** @param {?StreamFilter|undefined} value */
  setStreamFilter(value?: common_stream_pb.StreamFilter) {
    jspb.Message.setWrapperField(this, 1, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearStreamFilter() {
    this.setStreamFilter(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasStreamFilter(): boolean {
    return jspb.Message.getField(this, 1) != null;
  }


  /**
   * optional int32 offset = 2;
   * @return {number}
   */
  getOffset(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
  };


  /** @param {number} value */
  setOffset(value: number) {
    jspb.Message.setProto3IntField(this, 2, value);
  }


  /**
   * optional int32 limit = 3;
   * @return {number}
   */
  getLimit(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
  };


  /** @param {number} value */
  setLimit(value: number) {
    jspb.Message.setProto3IntField(this, 3, value);
  }


}
export namespace GetContentFeedResponse {
  export interface AsObject {
    itemsList: StreamContentFeedResponse.AsObject[];
  }
}
export class GetContentFeedResponse extends jspb.Message {
  static readonly displayName = "GetContentFeedResponse";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [1];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, GetContentFeedResponse.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): GetContentFeedResponse.AsObject {
    return GetContentFeedResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!GetContentFeedResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: GetContentFeedResponse) {
    var f: any;
    var obj: any = {
      itemsList: jspb.Message.toObjectList(msg.getItemsList(),
      StreamContentFeedResponse.toObject, includeInstance)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!GetContentFeedResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new GetContentFeedResponse;
    return GetContentFeedResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!GetContentFeedResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!GetContentFeedResponse}
   */
  static deserializeBinaryFromReader(msg: GetContentFeedResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new StreamContentFeedResponse;
        reader.readMessage(value1,StreamContentFeedResponse.deserializeBinaryFromReader);
        msg.addItems(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!GetContentFeedResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: GetContentFeedResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getItemsList();
    if (f.length > 0) {
      writer.writeRepeatedMessage(
        1,
        f,
        StreamContentFeedResponse.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    GetContentFeedResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * repeated StreamContentFeedResponse items = 1;
   * @return {!Array<!StreamContentFeedResponse>}
   */
  getItemsList(): StreamContentFeedResponse[] {
    return /** @type{!Array<!StreamContentFeedResponse>} */ (
      jspb.Message.getRepeatedWrapperField(this, StreamContentFeedResponse, 1));
  }


  /** @param {!Array<!StreamContentFeedResponse>} value */
  setItemsList(value?: StreamContentFeedResponse[]) {
    jspb.Message.setRepeatedWrapperField(this, 1, value);
  }


  /**
   * @param {!StreamContentFeedResponse=} opt_value
   * @param {number=} opt_index
   * @return {!StreamContentFeedResponse}
   */
  addItems(opt_value?: StreamContentFeedResponse, opt_index?: number): StreamContentFeedResponse {
    return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, StreamContentFeedResponse, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearItemsList() {
    this.setItemsList([]);
  }


}
export namespace TopOfFeedRequest {
  export interface AsObject {
  }
}
export class TopOfFeedRequest extends jspb.Message {
  static readonly displayName = "TopOfFeedRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): TopOfFeedRequest.AsObject {
    return TopOfFeedRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!TopOfFeedRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: TopOfFeedRequest) {
    var f: any;
    var obj: any = {

    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!TopOfFeedRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new TopOfFeedRequest;
    return TopOfFeedRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!TopOfFeedRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!TopOfFeedRequest}
   */
  static deserializeBinaryFromReader(msg: TopOfFeedRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!TopOfFeedRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: TopOfFeedRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    TopOfFeedRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
}
export namespace TopOfFeedResponse {
  export interface AsObject {
    status?: common_legacy_pb.StatusCode;
    reason?: common_legacy_pb.StatusReason.AsObject;
    eventsList: gateway_content_views_pb.ShortEventCardView.AsObject[];
    retrievalTimestamp: number;
    text?: TopOfFeedText.AsObject;
  }
}
export class TopOfFeedResponse extends jspb.Message {
  static readonly displayName = "TopOfFeedResponse";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [3];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, TopOfFeedResponse.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): TopOfFeedResponse.AsObject {
    return TopOfFeedResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!TopOfFeedResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: TopOfFeedResponse) {
    var f: any;
    var obj: any = {
      status: jspb.Message.getFieldWithDefault(msg, 1, 0),
      reason: (f = msg.getReason()) && common_legacy_pb.StatusReason.toObject(includeInstance, f),
      eventsList: jspb.Message.toObjectList(msg.getEventsList(),
      gateway_content_views_pb.ShortEventCardView.toObject, includeInstance),
      retrievalTimestamp: jspb.Message.getFieldWithDefault(msg, 10, 0),
      text: (f = msg.getText()) && TopOfFeedText.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!TopOfFeedResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new TopOfFeedResponse;
    return TopOfFeedResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!TopOfFeedResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!TopOfFeedResponse}
   */
  static deserializeBinaryFromReader(msg: TopOfFeedResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {!StatusCode} */ (reader.readEnum());
        msg.setStatus(value1);
        break;
      case 2:
        var value2 = new common_legacy_pb.StatusReason;
        reader.readMessage(value2,common_legacy_pb.StatusReason.deserializeBinaryFromReader);
        msg.setReason(value2);
        break;
      case 3:
        var value3 = new gateway_content_views_pb.ShortEventCardView;
        reader.readMessage(value3,gateway_content_views_pb.ShortEventCardView.deserializeBinaryFromReader);
        msg.addEvents(value3);
        break;
      case 10:
        var value10 = /** @type {number} */ (reader.readInt64());
        msg.setRetrievalTimestamp(value10);
        break;
      case 12:
        var value12 = new TopOfFeedText;
        reader.readMessage(value12,TopOfFeedText.deserializeBinaryFromReader);
        msg.setText(value12);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!TopOfFeedResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: TopOfFeedResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getStatus();
    if (f !== 0.0) {
      writer.writeEnum(
        1,
        f
      );
    }
    f = message.getReason();
    if (f != null) {
      writer.writeMessage(
        2,
        f,
        common_legacy_pb.StatusReason.serializeBinaryToWriter
      );
    }
    f = message.getEventsList();
    if (f.length > 0) {
      writer.writeRepeatedMessage(
        3,
        f,
        gateway_content_views_pb.ShortEventCardView.serializeBinaryToWriter
      );
    }
    f = message.getRetrievalTimestamp();
    if (f !== 0) {
      writer.writeInt64(
        10,
        f
      );
    }
    f = message.getText();
    if (f != null) {
      writer.writeMessage(
        12,
        f,
        TopOfFeedText.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    TopOfFeedResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional StatusCode status = 1;
   * @return {!StatusCode}
   */
  getStatus(): common_legacy_pb.StatusCode {
    return /** @type {!StatusCode} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
  };


  /** @param {!StatusCode} value */
  setStatus(value: common_legacy_pb.StatusCode) {
    jspb.Message.setProto3EnumField(this, 1, value);
  }


  /**
   * optional StatusReason reason = 2;
   * @return {?StatusReason}
   */
  getReason(): common_legacy_pb.StatusReason {
    return /** @type{?StatusReason} */ (
      jspb.Message.getWrapperField(this, common_legacy_pb.StatusReason, 2));
  }


  /** @param {?StatusReason|undefined} value */
  setReason(value?: common_legacy_pb.StatusReason) {
    jspb.Message.setWrapperField(this, 2, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearReason() {
    this.setReason(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasReason(): boolean {
    return jspb.Message.getField(this, 2) != null;
  }


  /**
   * repeated ShortEventCardView events = 3;
   * @return {!Array<!ShortEventCardView>}
   */
  getEventsList(): gateway_content_views_pb.ShortEventCardView[] {
    return /** @type{!Array<!ShortEventCardView>} */ (
      jspb.Message.getRepeatedWrapperField(this, gateway_content_views_pb.ShortEventCardView, 3));
  }


  /** @param {!Array<!ShortEventCardView>} value */
  setEventsList(value?: gateway_content_views_pb.ShortEventCardView[]) {
    jspb.Message.setRepeatedWrapperField(this, 3, value);
  }


  /**
   * @param {!ShortEventCardView=} opt_value
   * @param {number=} opt_index
   * @return {!ShortEventCardView}
   */
  addEvents(opt_value?: gateway_content_views_pb.ShortEventCardView, opt_index?: number): gateway_content_views_pb.ShortEventCardView {
    return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, gateway_content_views_pb.ShortEventCardView, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearEventsList() {
    this.setEventsList([]);
  }


  /**
   * optional int64 retrieval_timestamp = 10;
   * @return {number}
   */
  getRetrievalTimestamp(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
  };


  /** @param {number} value */
  setRetrievalTimestamp(value: number) {
    jspb.Message.setProto3IntField(this, 10, value);
  }


  /**
   * optional TopOfFeedText text = 12;
   * @return {?TopOfFeedText}
   */
  getText(): TopOfFeedText {
    return /** @type{?TopOfFeedText} */ (
      jspb.Message.getWrapperField(this, TopOfFeedText, 12));
  }


  /** @param {?TopOfFeedText|undefined} value */
  setText(value?: TopOfFeedText) {
    jspb.Message.setWrapperField(this, 12, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearText() {
    this.setText(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasText(): boolean {
    return jspb.Message.getField(this, 12) != null;
  }


}
export namespace AddTopOfFeedTextRequest {
  export interface AsObject {
    topOfFeedTextList: TopOfFeedText.AsObject[];
    groupHash: string;
  }
}
export class AddTopOfFeedTextRequest extends jspb.Message {
  static readonly displayName = "AddTopOfFeedTextRequest";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [1];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, AddTopOfFeedTextRequest.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): AddTopOfFeedTextRequest.AsObject {
    return AddTopOfFeedTextRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!AddTopOfFeedTextRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: AddTopOfFeedTextRequest) {
    var f: any;
    var obj: any = {
      topOfFeedTextList: jspb.Message.toObjectList(msg.getTopOfFeedTextList(),
      TopOfFeedText.toObject, includeInstance),
      grouphash: jspb.Message.getFieldWithDefault(msg, 2, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!AddTopOfFeedTextRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new AddTopOfFeedTextRequest;
    return AddTopOfFeedTextRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!AddTopOfFeedTextRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!AddTopOfFeedTextRequest}
   */
  static deserializeBinaryFromReader(msg: AddTopOfFeedTextRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new TopOfFeedText;
        reader.readMessage(value1,TopOfFeedText.deserializeBinaryFromReader);
        msg.addTopOfFeedText(value1);
        break;
      case 2:
        var value2 = /** @type {string} */ (reader.readString());
        msg.setGrouphash(value2);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!AddTopOfFeedTextRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: AddTopOfFeedTextRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getTopOfFeedTextList();
    if (f.length > 0) {
      writer.writeRepeatedMessage(
        1,
        f,
        TopOfFeedText.serializeBinaryToWriter
      );
    }
    f = message.getGrouphash();
    if (f.length > 0) {
      writer.writeString(
        2,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    AddTopOfFeedTextRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * repeated TopOfFeedText top_of_feed_text = 1;
   * @return {!Array<!TopOfFeedText>}
   */
  getTopOfFeedTextList(): TopOfFeedText[] {
    return /** @type{!Array<!TopOfFeedText>} */ (
      jspb.Message.getRepeatedWrapperField(this, TopOfFeedText, 1));
  }


  /** @param {!Array<!TopOfFeedText>} value */
  setTopOfFeedTextList(value?: TopOfFeedText[]) {
    jspb.Message.setRepeatedWrapperField(this, 1, value);
  }


  /**
   * @param {!TopOfFeedText=} opt_value
   * @param {number=} opt_index
   * @return {!TopOfFeedText}
   */
  addTopOfFeedText(opt_value?: TopOfFeedText, opt_index?: number): TopOfFeedText {
    return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, TopOfFeedText, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearTopOfFeedTextList() {
    this.setTopOfFeedTextList([]);
  }


  /**
   * optional string groupHash = 2;
   * @return {string}
   */
  getGrouphash(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
  };


  /** @param {string} value */
  setGrouphash(value: string) {
    jspb.Message.setProto3StringField(this, 2, value);
  }


}
export namespace AddTopOfFeedTextResponse {
  export interface AsObject {
    index: number;
    progress: number;
    totalProgress: number;
    error?: AddTopOfFeedTextResponse.Error.AsObject;
  }
}
export class AddTopOfFeedTextResponse extends jspb.Message {
  static readonly displayName = "AddTopOfFeedTextResponse";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): AddTopOfFeedTextResponse.AsObject {
    return AddTopOfFeedTextResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!AddTopOfFeedTextResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: AddTopOfFeedTextResponse) {
    var f: any;
    var obj: any = {
      index: jspb.Message.getFieldWithDefault(msg, 1, 0),
      progress: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
      totalProgress: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
      error: (f = msg.getError()) && AddTopOfFeedTextResponse.Error.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!AddTopOfFeedTextResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new AddTopOfFeedTextResponse;
    return AddTopOfFeedTextResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!AddTopOfFeedTextResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!AddTopOfFeedTextResponse}
   */
  static deserializeBinaryFromReader(msg: AddTopOfFeedTextResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {number} */ (reader.readInt32());
        msg.setIndex(value1);
        break;
      case 2:
        var value2 = /** @type {number} */ (reader.readFloat());
        msg.setProgress(value2);
        break;
      case 3:
        var value3 = /** @type {number} */ (reader.readFloat());
        msg.setTotalProgress(value3);
        break;
      case 4:
        var value4 = new AddTopOfFeedTextResponse.Error;
        reader.readMessage(value4,AddTopOfFeedTextResponse.Error.deserializeBinaryFromReader);
        msg.setError(value4);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!AddTopOfFeedTextResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: AddTopOfFeedTextResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getIndex();
    if (f !== 0) {
      writer.writeInt32(
        1,
        f
      );
    }
    f = message.getProgress();
    if (f !== 0.0) {
      writer.writeFloat(
        2,
        f
      );
    }
    f = message.getTotalProgress();
    if (f !== 0.0) {
      writer.writeFloat(
        3,
        f
      );
    }
    f = message.getError();
    if (f != null) {
      writer.writeMessage(
        4,
        f,
        AddTopOfFeedTextResponse.Error.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    AddTopOfFeedTextResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional int32 index = 1;
   * @return {number}
   */
  getIndex(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
  };


  /** @param {number} value */
  setIndex(value: number) {
    jspb.Message.setProto3IntField(this, 1, value);
  }


  /**
   * optional float progress = 2;
   * @return {number}
   */
  getProgress(): number {
    return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
  };


  /** @param {number} value */
  setProgress(value: number) {
    jspb.Message.setProto3FloatField(this, 2, value);
  }


  /**
   * optional float total_progress = 3;
   * @return {number}
   */
  getTotalProgress(): number {
    return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
  };


  /** @param {number} value */
  setTotalProgress(value: number) {
    jspb.Message.setProto3FloatField(this, 3, value);
  }


  /**
   * optional Error error = 4;
   * @return {?AddTopOfFeedTextResponse.Error}
   */
  getError(): AddTopOfFeedTextResponse.Error {
    return /** @type{?AddTopOfFeedTextResponse.Error} */ (
      jspb.Message.getWrapperField(this, AddTopOfFeedTextResponse.Error, 4));
  }


  /** @param {?AddTopOfFeedTextResponse.Error|undefined} value */
  setError(value?: AddTopOfFeedTextResponse.Error) {
    jspb.Message.setWrapperField(this, 4, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearError() {
    this.setError(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasError(): boolean {
    return jspb.Message.getField(this, 4) != null;
  }


}
export namespace AddTopOfFeedTextResponse {
export namespace Error {
  export interface AsObject {
    index: number;
    message: string;
  }
}
export class Error extends jspb.Message {
  static readonly displayName = "AddTopOfFeedTextResponse.Error";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): Error.AsObject {
    return Error.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!Error} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: Error) {
    var f: any;
    var obj: any = {
      index: jspb.Message.getFieldWithDefault(msg, 1, 0),
      message: jspb.Message.getFieldWithDefault(msg, 2, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!Error}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new Error;
    return Error.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!AddTopOfFeedTextResponse.Error} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!AddTopOfFeedTextResponse.Error}
   */
  static deserializeBinaryFromReader(msg: Error, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {number} */ (reader.readInt32());
        msg.setIndex(value1);
        break;
      case 2:
        var value2 = /** @type {string} */ (reader.readString());
        msg.setMessage(value2);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!AddTopOfFeedTextResponse.Error} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: AddTopOfFeedTextResponse.Error, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getIndex();
    if (f !== 0) {
      writer.writeInt32(
        1,
        f
      );
    }
    f = message.getMessage();
    if (f.length > 0) {
      writer.writeString(
        2,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    AddTopOfFeedTextResponse.Error.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional int32 index = 1;
   * @return {number}
   */
  getIndex(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
  };


  /** @param {number} value */
  setIndex(value: number) {
    jspb.Message.setProto3IntField(this, 1, value);
  }


  /**
   * optional string message = 2;
   * @return {string}
   */
  getMessage(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
  };


  /** @param {string} value */
  setMessage(value: string) {
    jspb.Message.setProto3StringField(this, 2, value);
  }


}
} // namespace AddTopOfFeedTextResponse
