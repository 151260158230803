import { Pipe, PipeTransform } from '@angular/core';

import { HallPassStatusEnum } from 'libs/domain';

import { HpmDashboardTableItem } from '../types';

/**
 * Determine if the current user has permission to approve/deny a hall pass
 */
@Pipe({ name: 'hpmCanApprove' })
export class HpmDashboardCanApprovePipe implements PipeTransform {
  transform(
    hallPass: HpmDashboardTableItem,
    authHash: string,
    isHallPassManager: boolean,
  ): boolean {
    // no pass/not pending pass
    if (HallPassStatusEnum.PENDING_APPROVAL !== hallPass?.status?.state) {
      return false;
    }

    if (isHallPassManager) {
      return true;
    }

    // if the user is the approver
    if (authHash === hallPass.authorPersonView?.personHash) {
      return true;
    }

    return false;
  }
}
