export interface ICancellable {
  readonly cancelled: boolean;
  readonly cancel: () => void;
}

export type Cancellable<T> = T&ICancellable;

/**
 * Mixin a cancel function to an existing type
 */
export function cancellableMixin<T>(
  v: T,
  cancelFn: () => void,
  ): Cancellable<T> {
  let cancelled: boolean = false;
  (v as any).cancel = () => {
    if(!cancelled) {
      cancelled = true;
      cancelFn();
    }
  };

  Object.defineProperty(v, 'cancelled', {
    get: () => cancelled,
  });

  return v as Cancellable<T>;
}
