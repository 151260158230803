import * as day from 'dayjs';

export enum PmReportsFilter {
  ISSUED_TO = 'issuedTo',
  TEAMS = 'teams',
  BEHAVIOR_TYPES = 'behaviorTypes',
  GRADES = 'grades',
  START_DATE = 'startDate',
  END_DATE = 'endDate',
  REWARDS = 'rewards',
  USER_LIST = 'userList',
}

export interface PmReportsFilters {
  [PmReportsFilter.ISSUED_TO]: string[];
  [PmReportsFilter.TEAMS]: number[];
  [PmReportsFilter.GRADES]: string[];
  [PmReportsFilter.START_DATE]?: day.Dayjs;
  [PmReportsFilter.END_DATE]?: day.Dayjs;
  [PmReportsFilter.REWARDS]: number[];
  [PmReportsFilter.USER_LIST]: number[];
}
