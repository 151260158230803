import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { gateway } from 'libs/generated-grpc-web';

import { BaseShortCardClass } from '../BaseShortCardClass';

export interface IMgVideoShortCardElementProperties {
  content?: gateway.content_views_pb.ShortCardView.AsObject;
  context: string;
}

@Component({
  selector: 'mg-video-short-card',
  templateUrl: './MgVideoShortCard.element.html',
  styleUrls: ['./MgVideoShortCard.element.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MgVideoShortCardElement
  extends BaseShortCardClass
  implements IMgVideoShortCardElementProperties
{
  @Input()
  content?: gateway.content_views_pb.ShortCardView.AsObject;

  @Input()
  pinned: boolean = false;

  @Input()
  context: string = '';

  @Input()
  markKeywords: string = '';

  @Input()
  commentRoles: string[] | null = null;
}
