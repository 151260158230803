import { Injectable, NgZone } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { grpc } from '@improbable-eng/grpc-web';

import {
  StreamControl,
  StreamControlResponse,
  StreamID,
} from '../common/stream_pb';
import {
  AddTopOfFeedTextRequest,
  AddTopOfFeedTextResponse,
  ContentFeedItemView,
  GetContentFeedRequest,
  GetContentFeedResponse,
  StreamContentFeedResponse,
  TopOfFeedRequest,
  TopOfFeedResponse,
} from '../gateway/feed_pb';

import {
  ContentFeed as __ContentFeed,
} from '../gateway/feed_pb_service';

@Injectable({providedIn: 'root'})
export class ContentFeed {

  constructor(private _ngZone: NgZone) {
  }

  /**
    Adds content directly into presentation view with no source of truth.
 Useful for testing.
  */
  addTemporaryContent(request: ContentFeedItemView): Promise<ContentFeedItemView>;
  /**
    Adds content directly into presentation view with no source of truth.
 Useful for testing.
  */
  addTemporaryContent(request: ContentFeedItemView, metadata: grpc.Metadata): Promise<ContentFeedItemView>;
  /**
    Adds content directly into presentation view with no source of truth.
 Useful for testing.
  */
  addTemporaryContent(request: ContentFeedItemView, callback: (err: any|null, response: ContentFeedItemView, metadata: grpc.Metadata) => void): void;
  /**
    Adds content directly into presentation view with no source of truth.
 Useful for testing.
  */
  addTemporaryContent(request: ContentFeedItemView, metadata: grpc.Metadata, callback: (err: any|null, response: ContentFeedItemView, metadata: grpc.Metadata) => void): void;

  addTemporaryContent(request: ContentFeedItemView, arg1?: grpc.Metadata|((err: any|null, response: ContentFeedItemView, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: ContentFeedItemView, metadata: grpc.Metadata) => void): Promise<ContentFeedItemView>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<ContentFeedItemView>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__ContentFeed.addTemporaryContent, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  getTopOfFeed(request: TopOfFeedRequest): Promise<TopOfFeedResponse>;
  getTopOfFeed(request: TopOfFeedRequest, metadata: grpc.Metadata): Promise<TopOfFeedResponse>;
  getTopOfFeed(request: TopOfFeedRequest, callback: (err: any|null, response: TopOfFeedResponse, metadata: grpc.Metadata) => void): void;
  getTopOfFeed(request: TopOfFeedRequest, metadata: grpc.Metadata, callback: (err: any|null, response: TopOfFeedResponse, metadata: grpc.Metadata) => void): void;

  getTopOfFeed(request: TopOfFeedRequest, arg1?: grpc.Metadata|((err: any|null, response: TopOfFeedResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: TopOfFeedResponse, metadata: grpc.Metadata) => void): Promise<TopOfFeedResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<TopOfFeedResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__ContentFeed.getTopOfFeed, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  addTopOfFeedText(request: AddTopOfFeedTextRequest): {cancel():void;close():void}&Observable<AddTopOfFeedTextResponse>;
  addTopOfFeedText(request: AddTopOfFeedTextRequest, metadata: grpc.Metadata): {cancel():void;close():void}&Observable<AddTopOfFeedTextResponse>;
  addTopOfFeedText(request: AddTopOfFeedTextRequest, onMessage: (message?: AddTopOfFeedTextResponse) => void,onError?: (err: any) => void,onEnd?: (code: number, msg: string|undefined, metadata: grpc.Metadata) => void): void;
  addTopOfFeedText(request: AddTopOfFeedTextRequest, metadata: grpc.Metadata, onMessage: (message?: AddTopOfFeedTextResponse) => void,onError?: (err: any) => void,onEnd?: (code: number, msg: string|undefined, metadata: grpc.Metadata) => void): void;

  addTopOfFeedText(request: AddTopOfFeedTextRequest, arg1?: grpc.Metadata|((message?: AddTopOfFeedTextResponse) => void), arg2?: ((message?: AddTopOfFeedTextResponse) => void)|((err: any) => void), arg3?: ((err: any) => void)|((code: number, msg: string|undefined, metadata: grpc.Metadata) => void), arg4?: (code: number, msg: string|undefined, metadata: grpc.Metadata) => void): {cancel():void;close():void}&Observable<AddTopOfFeedTextResponse>|void {
    let ret: any;
    let metadata: any;

    let onMessage: any;
    let onError: any;
    let onEnd: any;
    if(typeof arg1 === 'function') {
      onMessage = arg1;
      onError = arg2;
      onEnd = arg3;
    } else if(typeof arg2 === 'function') {
      metadata = arg1;
      onMessage = arg2;
      onError = arg3;
      onEnd = arg4;
    } else {
      metadata = arg1;
    }

    if(!onMessage) {
      let subject = new Subject<AddTopOfFeedTextResponse>();
      ret = subject.asObservable();

      onMessage = (response: any) => {
        subject.next(response);
      };

      onError = (err: any) => {
        subject.error(err);
      };

      onEnd = (code: any, msg: any, metadata: any) => {
        subject.complete();
      };

    } else {
      if(!onError) {
        onError = (err: any) => console.error(err);
      }

      if(!onEnd) {
        onEnd = (code: any, msg: any, metadata: any) => {};
      }
    }

    let req = grpc.invoke(__ContentFeed.AddTopOfFeedText, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onMessage: response => this._ngZone.run(() => {
        onMessage(response);
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code == grpc.Code.OK) {
          onEnd(code, msg, metadata);
        } else {
          let err = new Error(code + ' ' + (msg||''));
          (<any>err).code = code;
          onError(err);
        }
      })
    });

    (<any>ret).close = () => {
      console.warn('[Angular Grpc] .close() is deprecated use .cancel() instead');
      return req.close();
    };
    (<any>ret).cancel = () => req.close();
    return ret;
  }

  getContentFeed(request: GetContentFeedRequest): Promise<GetContentFeedResponse>;
  getContentFeed(request: GetContentFeedRequest, metadata: grpc.Metadata): Promise<GetContentFeedResponse>;
  getContentFeed(request: GetContentFeedRequest, callback: (err: any|null, response: GetContentFeedResponse, metadata: grpc.Metadata) => void): void;
  getContentFeed(request: GetContentFeedRequest, metadata: grpc.Metadata, callback: (err: any|null, response: GetContentFeedResponse, metadata: grpc.Metadata) => void): void;

  getContentFeed(request: GetContentFeedRequest, arg1?: grpc.Metadata|((err: any|null, response: GetContentFeedResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: GetContentFeedResponse, metadata: grpc.Metadata) => void): Promise<GetContentFeedResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<GetContentFeedResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__ContentFeed.getContentFeed, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  /**
    Streaming content handlers
  */
  streamContentFeed(request: StreamID): {cancel():void;close():void}&Observable<StreamContentFeedResponse>;
  /**
    Streaming content handlers
  */
  streamContentFeed(request: StreamID, metadata: grpc.Metadata): {cancel():void;close():void}&Observable<StreamContentFeedResponse>;
  /**
    Streaming content handlers
  */
  streamContentFeed(request: StreamID, onMessage: (message?: StreamContentFeedResponse) => void,onError?: (err: any) => void,onEnd?: (code: number, msg: string|undefined, metadata: grpc.Metadata) => void): void;
  /**
    Streaming content handlers
  */
  streamContentFeed(request: StreamID, metadata: grpc.Metadata, onMessage: (message?: StreamContentFeedResponse) => void,onError?: (err: any) => void,onEnd?: (code: number, msg: string|undefined, metadata: grpc.Metadata) => void): void;

  streamContentFeed(request: StreamID, arg1?: grpc.Metadata|((message?: StreamContentFeedResponse) => void), arg2?: ((message?: StreamContentFeedResponse) => void)|((err: any) => void), arg3?: ((err: any) => void)|((code: number, msg: string|undefined, metadata: grpc.Metadata) => void), arg4?: (code: number, msg: string|undefined, metadata: grpc.Metadata) => void): {cancel():void;close():void}&Observable<StreamContentFeedResponse>|void {
    let ret: any;
    let metadata: any;

    let onMessage: any;
    let onError: any;
    let onEnd: any;
    if(typeof arg1 === 'function') {
      onMessage = arg1;
      onError = arg2;
      onEnd = arg3;
    } else if(typeof arg2 === 'function') {
      metadata = arg1;
      onMessage = arg2;
      onError = arg3;
      onEnd = arg4;
    } else {
      metadata = arg1;
    }

    if(!onMessage) {
      let subject = new Subject<StreamContentFeedResponse>();
      ret = subject.asObservable();

      onMessage = (response: any) => {
        subject.next(response);
      };

      onError = (err: any) => {
        subject.error(err);
      };

      onEnd = (code: any, msg: any, metadata: any) => {
        subject.complete();
      };

    } else {
      if(!onError) {
        onError = (err: any) => console.error(err);
      }

      if(!onEnd) {
        onEnd = (code: any, msg: any, metadata: any) => {};
      }
    }

    let req = grpc.invoke(__ContentFeed.streamContentFeed, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onMessage: response => this._ngZone.run(() => {
        onMessage(response);
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code == grpc.Code.OK) {
          onEnd(code, msg, metadata);
        } else {
          let err = new Error(code + ' ' + (msg||''));
          (<any>err).code = code;
          onError(err);
        }
      })
    });

    (<any>ret).close = () => {
      console.warn('[Angular Grpc] .close() is deprecated use .cancel() instead');
      return req.close();
    };
    (<any>ret).cancel = () => req.close();
    return ret;
  }

  streamContentFeedControl(request: StreamControl): Promise<StreamControlResponse>;
  streamContentFeedControl(request: StreamControl, metadata: grpc.Metadata): Promise<StreamControlResponse>;
  streamContentFeedControl(request: StreamControl, callback: (err: any|null, response: StreamControlResponse, metadata: grpc.Metadata) => void): void;
  streamContentFeedControl(request: StreamControl, metadata: grpc.Metadata, callback: (err: any|null, response: StreamControlResponse, metadata: grpc.Metadata) => void): void;

  streamContentFeedControl(request: StreamControl, arg1?: grpc.Metadata|((err: any|null, response: StreamControlResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: StreamControlResponse, metadata: grpc.Metadata) => void): Promise<StreamControlResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<StreamControlResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__ContentFeed.streamContentFeedControl, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

}

