// source: gateway/program_stream.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

import * as jspb from 'google-protobuf';
import * as common_stream_pb from '../common/stream_pb';
import * as image_image_pb from '../image/image_pb';
import * as gateway_content_views_pb from '../gateway/content_views_pb';
export namespace StreamProgramInfo {
  export interface AsObject {
    itemMetadata?: common_stream_pb.StreamItemMetadata.AsObject;
    item?: StreamProgramInfo.StreamItem.AsObject;
  }
}
export class StreamProgramInfo extends jspb.Message {
  static readonly displayName = "StreamProgramInfo";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): StreamProgramInfo.AsObject {
    return StreamProgramInfo.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!StreamProgramInfo} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: StreamProgramInfo) {
    var f: any;
    var obj: any = {
      itemMetadata: (f = msg.getItemMetadata()) && common_stream_pb.StreamItemMetadata.toObject(includeInstance, f),
      item: (f = msg.getItem()) && StreamProgramInfo.StreamItem.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!StreamProgramInfo}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new StreamProgramInfo;
    return StreamProgramInfo.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!StreamProgramInfo} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!StreamProgramInfo}
   */
  static deserializeBinaryFromReader(msg: StreamProgramInfo, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new common_stream_pb.StreamItemMetadata;
        reader.readMessage(value1,common_stream_pb.StreamItemMetadata.deserializeBinaryFromReader);
        msg.setItemMetadata(value1);
        break;
      case 2:
        var value2 = new StreamProgramInfo.StreamItem;
        reader.readMessage(value2,StreamProgramInfo.StreamItem.deserializeBinaryFromReader);
        msg.setItem(value2);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!StreamProgramInfo} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: StreamProgramInfo, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getItemMetadata();
    if (f != null) {
      writer.writeMessage(
        1,
        f,
        common_stream_pb.StreamItemMetadata.serializeBinaryToWriter
      );
    }
    f = message.getItem();
    if (f != null) {
      writer.writeMessage(
        2,
        f,
        StreamProgramInfo.StreamItem.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    StreamProgramInfo.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional StreamItemMetadata item_metadata = 1;
   * @return {?StreamItemMetadata}
   */
  getItemMetadata(): common_stream_pb.StreamItemMetadata {
    return /** @type{?StreamItemMetadata} */ (
      jspb.Message.getWrapperField(this, common_stream_pb.StreamItemMetadata, 1));
  }


  /** @param {?StreamItemMetadata|undefined} value */
  setItemMetadata(value?: common_stream_pb.StreamItemMetadata) {
    jspb.Message.setWrapperField(this, 1, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearItemMetadata() {
    this.setItemMetadata(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasItemMetadata(): boolean {
    return jspb.Message.getField(this, 1) != null;
  }


  /**
   * optional StreamItem item = 2;
   * @return {?StreamProgramInfo.StreamItem}
   */
  getItem(): StreamProgramInfo.StreamItem {
    return /** @type{?StreamProgramInfo.StreamItem} */ (
      jspb.Message.getWrapperField(this, StreamProgramInfo.StreamItem, 2));
  }


  /** @param {?StreamProgramInfo.StreamItem|undefined} value */
  setItem(value?: StreamProgramInfo.StreamItem) {
    jspb.Message.setWrapperField(this, 2, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearItem() {
    this.setItem(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasItem(): boolean {
    return jspb.Message.getField(this, 2) != null;
  }


}
export namespace StreamProgramInfo {
export namespace StreamItem {
  export interface AsObject {
    title: string;
    description: string;
    logo?: image_image_pb.ImageInfo.AsObject;
    banner?: image_image_pb.ImageInfo.AsObject;
    mingaCount: number;
    active: boolean;
    contentHash: string;
    permittedRoleTypeList: string[];
  }
}
export class StreamItem extends jspb.Message {
  static readonly displayName = "StreamProgramInfo.StreamItem";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [8];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, StreamProgramInfo.StreamItem.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): StreamItem.AsObject {
    return StreamItem.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!StreamItem} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: StreamItem) {
    var f: any;
    var obj: any = {
      title: jspb.Message.getFieldWithDefault(msg, 1, ""),
      description: jspb.Message.getFieldWithDefault(msg, 2, ""),
      logo: (f = msg.getLogo()) && image_image_pb.ImageInfo.toObject(includeInstance, f),
      banner: (f = msg.getBanner()) && image_image_pb.ImageInfo.toObject(includeInstance, f),
      mingaCount: jspb.Message.getFieldWithDefault(msg, 5, 0),
      active: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
      contentHash: jspb.Message.getFieldWithDefault(msg, 7, ""),
      permittedRoleTypeList: (f = jspb.Message.getRepeatedField(msg, 8)) == null ? undefined : f
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!StreamItem}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new StreamItem;
    return StreamItem.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!StreamProgramInfo.StreamItem} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!StreamProgramInfo.StreamItem}
   */
  static deserializeBinaryFromReader(msg: StreamItem, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setTitle(value1);
        break;
      case 2:
        var value2 = /** @type {string} */ (reader.readString());
        msg.setDescription(value2);
        break;
      case 3:
        var value3 = new image_image_pb.ImageInfo;
        reader.readMessage(value3,image_image_pb.ImageInfo.deserializeBinaryFromReader);
        msg.setLogo(value3);
        break;
      case 4:
        var value4 = new image_image_pb.ImageInfo;
        reader.readMessage(value4,image_image_pb.ImageInfo.deserializeBinaryFromReader);
        msg.setBanner(value4);
        break;
      case 5:
        var value5 = /** @type {number} */ (reader.readInt32());
        msg.setMingaCount(value5);
        break;
      case 6:
        var value6 = /** @type {boolean} */ (reader.readBool());
        msg.setActive(value6);
        break;
      case 7:
        var value7 = /** @type {string} */ (reader.readString());
        msg.setContentHash(value7);
        break;
      case 8:
        var value8 = /** @type {string} */ (reader.readString());
        msg.addPermittedRoleType(value8);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!StreamProgramInfo.StreamItem} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: StreamProgramInfo.StreamItem, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getTitle();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
    f = message.getDescription();
    if (f.length > 0) {
      writer.writeString(
        2,
        f
      );
    }
    f = message.getLogo();
    if (f != null) {
      writer.writeMessage(
        3,
        f,
        image_image_pb.ImageInfo.serializeBinaryToWriter
      );
    }
    f = message.getBanner();
    if (f != null) {
      writer.writeMessage(
        4,
        f,
        image_image_pb.ImageInfo.serializeBinaryToWriter
      );
    }
    f = message.getMingaCount();
    if (f !== 0) {
      writer.writeInt32(
        5,
        f
      );
    }
    f = message.getActive();
    if (f) {
      writer.writeBool(
        6,
        f
      );
    }
    f = message.getContentHash();
    if (f.length > 0) {
      writer.writeString(
        7,
        f
      );
    }
    f = message.getPermittedRoleTypeList();
    if (f.length > 0) {
      writer.writeRepeatedString(
        8,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    StreamProgramInfo.StreamItem.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string title = 1;
   * @return {string}
   */
  getTitle(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setTitle(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


  /**
   * optional string description = 2;
   * @return {string}
   */
  getDescription(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
  };


  /** @param {string} value */
  setDescription(value: string) {
    jspb.Message.setProto3StringField(this, 2, value);
  }


  /**
   * optional ImageInfo logo = 3;
   * @return {?ImageInfo}
   */
  getLogo(): image_image_pb.ImageInfo {
    return /** @type{?ImageInfo} */ (
      jspb.Message.getWrapperField(this, image_image_pb.ImageInfo, 3));
  }


  /** @param {?ImageInfo|undefined} value */
  setLogo(value?: image_image_pb.ImageInfo) {
    jspb.Message.setWrapperField(this, 3, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearLogo() {
    this.setLogo(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasLogo(): boolean {
    return jspb.Message.getField(this, 3) != null;
  }


  /**
   * optional ImageInfo banner = 4;
   * @return {?ImageInfo}
   */
  getBanner(): image_image_pb.ImageInfo {
    return /** @type{?ImageInfo} */ (
      jspb.Message.getWrapperField(this, image_image_pb.ImageInfo, 4));
  }


  /** @param {?ImageInfo|undefined} value */
  setBanner(value?: image_image_pb.ImageInfo) {
    jspb.Message.setWrapperField(this, 4, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearBanner() {
    this.setBanner(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasBanner(): boolean {
    return jspb.Message.getField(this, 4) != null;
  }


  /**
   * optional int32 minga_count = 5;
   * @return {number}
   */
  getMingaCount(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
  };


  /** @param {number} value */
  setMingaCount(value: number) {
    jspb.Message.setProto3IntField(this, 5, value);
  }


  /**
   * optional bool active = 6;
   * @return {boolean}
   */
  getActive(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
  };


  /** @param {boolean} value */
  setActive(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 6, value);
  }


  /**
   * optional string content_hash = 7;
   * @return {string}
   */
  getContentHash(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
  };


  /** @param {string} value */
  setContentHash(value: string) {
    jspb.Message.setProto3StringField(this, 7, value);
  }


  /**
   * repeated string permitted_role_type = 8;
   * @return {!Array<string>}
   */
  getPermittedRoleTypeList(): string[] {
    return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 8));
  };


  /** @param {!Array<string>} value */
  setPermittedRoleTypeList(value: string[]) {
    jspb.Message.setField(this, 8, value || []);
  }


  /**
   * @param {string} value
   * @param {number=} opt_index
   */
  addPermittedRoleType(value: string, opt_index?: number): void {
    jspb.Message.addToRepeatedField(this, 8, value, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearPermittedRoleTypeList() {
    this.setPermittedRoleTypeList([]);
  }


}
} // namespace StreamProgramInfo
export namespace StreamProgramContentInfo {
  export interface AsObject {
    itemMetadata?: common_stream_pb.StreamItemMetadata.AsObject;
    item?: StreamProgramContentInfo.StreamItem.AsObject;
  }
}
export class StreamProgramContentInfo extends jspb.Message {
  static readonly displayName = "StreamProgramContentInfo";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): StreamProgramContentInfo.AsObject {
    return StreamProgramContentInfo.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!StreamProgramContentInfo} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: StreamProgramContentInfo) {
    var f: any;
    var obj: any = {
      itemMetadata: (f = msg.getItemMetadata()) && common_stream_pb.StreamItemMetadata.toObject(includeInstance, f),
      item: (f = msg.getItem()) && StreamProgramContentInfo.StreamItem.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!StreamProgramContentInfo}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new StreamProgramContentInfo;
    return StreamProgramContentInfo.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!StreamProgramContentInfo} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!StreamProgramContentInfo}
   */
  static deserializeBinaryFromReader(msg: StreamProgramContentInfo, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new common_stream_pb.StreamItemMetadata;
        reader.readMessage(value1,common_stream_pb.StreamItemMetadata.deserializeBinaryFromReader);
        msg.setItemMetadata(value1);
        break;
      case 2:
        var value2 = new StreamProgramContentInfo.StreamItem;
        reader.readMessage(value2,StreamProgramContentInfo.StreamItem.deserializeBinaryFromReader);
        msg.setItem(value2);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!StreamProgramContentInfo} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: StreamProgramContentInfo, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getItemMetadata();
    if (f != null) {
      writer.writeMessage(
        1,
        f,
        common_stream_pb.StreamItemMetadata.serializeBinaryToWriter
      );
    }
    f = message.getItem();
    if (f != null) {
      writer.writeMessage(
        2,
        f,
        StreamProgramContentInfo.StreamItem.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    StreamProgramContentInfo.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional StreamItemMetadata item_metadata = 1;
   * @return {?StreamItemMetadata}
   */
  getItemMetadata(): common_stream_pb.StreamItemMetadata {
    return /** @type{?StreamItemMetadata} */ (
      jspb.Message.getWrapperField(this, common_stream_pb.StreamItemMetadata, 1));
  }


  /** @param {?StreamItemMetadata|undefined} value */
  setItemMetadata(value?: common_stream_pb.StreamItemMetadata) {
    jspb.Message.setWrapperField(this, 1, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearItemMetadata() {
    this.setItemMetadata(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasItemMetadata(): boolean {
    return jspb.Message.getField(this, 1) != null;
  }


  /**
   * optional StreamItem item = 2;
   * @return {?StreamProgramContentInfo.StreamItem}
   */
  getItem(): StreamProgramContentInfo.StreamItem {
    return /** @type{?StreamProgramContentInfo.StreamItem} */ (
      jspb.Message.getWrapperField(this, StreamProgramContentInfo.StreamItem, 2));
  }


  /** @param {?StreamProgramContentInfo.StreamItem|undefined} value */
  setItem(value?: StreamProgramContentInfo.StreamItem) {
    jspb.Message.setWrapperField(this, 2, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearItem() {
    this.setItem(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasItem(): boolean {
    return jspb.Message.getField(this, 2) != null;
  }


}
export namespace StreamProgramContentInfo {
export namespace StreamItem {
  export interface AsObject {
    contentHash: string;
    title: string;
    description: string;
    imageInfo?: image_image_pb.ImageInfo.AsObject;
    fullImage: boolean;
    lightContent: boolean;
    positionOffset: number;
    positionRelative: boolean;
    typeString: string;
    icon: string;
    pollLayout?: gateway_content_views_pb.PollLayout;
  }
}
export class StreamItem extends jspb.Message {
  static readonly displayName = "StreamProgramContentInfo.StreamItem";
  /**
   * Oneof group definitions for this message. Each group defines the field
   * numbers belonging to that group. When of these fields' value is set, all
   * other fields in the group are cleared. During deserialization, if multiple
   * fields are encountered for a group, only the last value seen will be kept.
   * @private {!Array<!Array<number>>}
   * @const
   */
  static readonly oneofGroups_ = [[11]];


  /**
   * @return {StreamProgramContentInfo.StreamItem.OverlayImageCase}
   */
  getOverlayImageCase() {
    return /** @type {StreamProgramContentInfo.StreamItem.OverlayImageCase} */(jspb.Message.computeOneofCase(this, StreamProgramContentInfo.StreamItem.oneofGroups_[0]));
  }

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, StreamProgramContentInfo.StreamItem.oneofGroups_);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): StreamItem.AsObject {
    return StreamItem.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!StreamItem} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: StreamItem) {
    var f: any;
    var obj: any = {
      contentHash: jspb.Message.getFieldWithDefault(msg, 1, ""),
      title: jspb.Message.getFieldWithDefault(msg, 2, ""),
      description: jspb.Message.getFieldWithDefault(msg, 3, ""),
      imageInfo: (f = msg.getImageInfo()) && image_image_pb.ImageInfo.toObject(includeInstance, f),
      fullImage: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
      lightContent: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
      positionOffset: jspb.Message.getFieldWithDefault(msg, 7, 0),
      positionRelative: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
      typeString: jspb.Message.getFieldWithDefault(msg, 9, ""),
      icon: jspb.Message.getFieldWithDefault(msg, 10, ""),
      pollLayout: jspb.Message.getFieldWithDefault(msg, 11, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!StreamItem}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new StreamItem;
    return StreamItem.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!StreamProgramContentInfo.StreamItem} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!StreamProgramContentInfo.StreamItem}
   */
  static deserializeBinaryFromReader(msg: StreamItem, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setContentHash(value1);
        break;
      case 2:
        var value2 = /** @type {string} */ (reader.readString());
        msg.setTitle(value2);
        break;
      case 3:
        var value3 = /** @type {string} */ (reader.readString());
        msg.setDescription(value3);
        break;
      case 4:
        var value4 = new image_image_pb.ImageInfo;
        reader.readMessage(value4,image_image_pb.ImageInfo.deserializeBinaryFromReader);
        msg.setImageInfo(value4);
        break;
      case 5:
        var value5 = /** @type {boolean} */ (reader.readBool());
        msg.setFullImage(value5);
        break;
      case 6:
        var value6 = /** @type {boolean} */ (reader.readBool());
        msg.setLightContent(value6);
        break;
      case 7:
        var value7 = /** @type {number} */ (reader.readUint64());
        msg.setPositionOffset(value7);
        break;
      case 8:
        var value8 = /** @type {boolean} */ (reader.readBool());
        msg.setPositionRelative(value8);
        break;
      case 9:
        var value9 = /** @type {string} */ (reader.readString());
        msg.setTypeString(value9);
        break;
      case 10:
        var value10 = /** @type {string} */ (reader.readString());
        msg.setIcon(value10);
        break;
      case 11:
        var value11 = /** @type {!PollLayout} */ (reader.readEnum());
        msg.setPollLayout(value11);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!StreamProgramContentInfo.StreamItem} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: StreamProgramContentInfo.StreamItem, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getContentHash();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
    f = message.getTitle();
    if (f.length > 0) {
      writer.writeString(
        2,
        f
      );
    }
    f = message.getDescription();
    if (f.length > 0) {
      writer.writeString(
        3,
        f
      );
    }
    f = message.getImageInfo();
    if (f != null) {
      writer.writeMessage(
        4,
        f,
        image_image_pb.ImageInfo.serializeBinaryToWriter
      );
    }
    f = message.getFullImage();
    if (f) {
      writer.writeBool(
        5,
        f
      );
    }
    f = message.getLightContent();
    if (f) {
      writer.writeBool(
        6,
        f
      );
    }
    f = message.getPositionOffset();
    if (f !== 0) {
      writer.writeUint64(
        7,
        f
      );
    }
    f = message.getPositionRelative();
    if (f) {
      writer.writeBool(
        8,
        f
      );
    }
    f = message.getTypeString();
    if (f.length > 0) {
      writer.writeString(
        9,
        f
      );
    }
    f = message.getIcon();
    if (f.length > 0) {
      writer.writeString(
        10,
        f
      );
    }
    f = /** @type {!PollLayout} */ (jspb.Message.getField(message, 11));
    if (f != null) {
      writer.writeEnum(
        11,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    StreamProgramContentInfo.StreamItem.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string content_hash = 1;
   * @return {string}
   */
  getContentHash(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setContentHash(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


  /**
   * optional string title = 2;
   * @return {string}
   */
  getTitle(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
  };


  /** @param {string} value */
  setTitle(value: string) {
    jspb.Message.setProto3StringField(this, 2, value);
  }


  /**
   * optional string description = 3;
   * @return {string}
   */
  getDescription(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
  };


  /** @param {string} value */
  setDescription(value: string) {
    jspb.Message.setProto3StringField(this, 3, value);
  }


  /**
   * optional ImageInfo image_info = 4;
   * @return {?ImageInfo}
   */
  getImageInfo(): image_image_pb.ImageInfo {
    return /** @type{?ImageInfo} */ (
      jspb.Message.getWrapperField(this, image_image_pb.ImageInfo, 4));
  }


  /** @param {?ImageInfo|undefined} value */
  setImageInfo(value?: image_image_pb.ImageInfo) {
    jspb.Message.setWrapperField(this, 4, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearImageInfo() {
    this.setImageInfo(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasImageInfo(): boolean {
    return jspb.Message.getField(this, 4) != null;
  }


  /**
   * optional bool full_image = 5;
   * @return {boolean}
   */
  getFullImage(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
  };


  /** @param {boolean} value */
  setFullImage(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 5, value);
  }


  /**
   * optional bool light_content = 6;
   * @return {boolean}
   */
  getLightContent(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
  };


  /** @param {boolean} value */
  setLightContent(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 6, value);
  }


  /**
   * optional uint64 position_offset = 7;
   * @return {number}
   */
  getPositionOffset(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
  };


  /** @param {number} value */
  setPositionOffset(value: number) {
    jspb.Message.setProto3IntField(this, 7, value);
  }


  /**
   * optional bool position_relative = 8;
   * @return {boolean}
   */
  getPositionRelative(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
  };


  /** @param {boolean} value */
  setPositionRelative(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 8, value);
  }


  /**
   * optional string type_string = 9;
   * @return {string}
   */
  getTypeString(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
  };


  /** @param {string} value */
  setTypeString(value: string) {
    jspb.Message.setProto3StringField(this, 9, value);
  }


  /**
   * optional string icon = 10;
   * @return {string}
   */
  getIcon(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
  };


  /** @param {string} value */
  setIcon(value: string) {
    jspb.Message.setProto3StringField(this, 10, value);
  }


  /**
   * optional PollLayout poll_layout = 11;
   * @return {!PollLayout}
   */
  getPollLayout(): gateway_content_views_pb.PollLayout {
    return /** @type {!PollLayout} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
  };


  /** @param {!PollLayout} value */
  setPollLayout(value: gateway_content_views_pb.PollLayout) {
    jspb.Message.setOneofField(this, 11, StreamProgramContentInfo.StreamItem.oneofGroups_[0], value);
  }


  /**
   * Clears the field making it undefined.
   */
  clearPollLayout() {
    jspb.Message.setOneofField(this, 11, StreamProgramContentInfo.StreamItem.oneofGroups_[0], undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasPollLayout(): boolean {
    return jspb.Message.getField(this, 11) != null;
  }


}
} // namespace StreamProgramContentInfo
export namespace StreamProgramContentInfo {
export namespace StreamItem {
export enum OverlayImageCase {
  OVERLAY_IMAGE_NOT_SET = 0,
  POLL_LAYOUT = 11,
}
} // namespace StreamProgramContentInfo
} // namespace StreamItem
