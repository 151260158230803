// source: pbis/pbis.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

import * as jspb from 'google-protobuf';
import * as common_date_pb from '../common/date_pb';
import * as gateway_person_view_pb from '../gateway/person_view_pb';
import * as membership_list_membership_list_pb from '../membership_list/membership_list_pb';
import * as restriction_restriction_pb from '../restriction/restriction_pb';
import * as student_schedule_student_schedule_pb from '../student_schedule/student_schedule_pb';
import * as google_protobuf_wrappers_pb from '../google/protobuf/wrappers_pb';
import * as google_protobuf_empty_pb from '../google/protobuf/empty_pb';
export namespace EmailTemplate {
  export interface AsObject {
    subject: string;
    body: string;
  }
}
export class EmailTemplate extends jspb.Message {
  static readonly displayName = "EmailTemplate";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): EmailTemplate.AsObject {
    return EmailTemplate.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!EmailTemplate} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: EmailTemplate) {
    var f: any;
    var obj: any = {
      subject: jspb.Message.getFieldWithDefault(msg, 1, ""),
      body: jspb.Message.getFieldWithDefault(msg, 2, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!EmailTemplate}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new EmailTemplate;
    return EmailTemplate.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!EmailTemplate} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!EmailTemplate}
   */
  static deserializeBinaryFromReader(msg: EmailTemplate, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setSubject(value1);
        break;
      case 2:
        var value2 = /** @type {string} */ (reader.readString());
        msg.setBody(value2);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!EmailTemplate} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: EmailTemplate, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getSubject();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
    f = message.getBody();
    if (f.length > 0) {
      writer.writeString(
        2,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    EmailTemplate.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string subject = 1;
   * @return {string}
   */
  getSubject(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setSubject(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


  /**
   * optional string body = 2;
   * @return {string}
   */
  getBody(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
  };


  /** @param {string} value */
  setBody(value: string) {
    jspb.Message.setProto3StringField(this, 2, value);
  }


}
export namespace SmsTemplate {
  export interface AsObject {
    body: string;
  }
}
export class SmsTemplate extends jspb.Message {
  static readonly displayName = "SmsTemplate";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): SmsTemplate.AsObject {
    return SmsTemplate.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!SmsTemplate} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: SmsTemplate) {
    var f: any;
    var obj: any = {
      body: jspb.Message.getFieldWithDefault(msg, 1, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!SmsTemplate}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new SmsTemplate;
    return SmsTemplate.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!SmsTemplate} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!SmsTemplate}
   */
  static deserializeBinaryFromReader(msg: SmsTemplate, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setBody(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!SmsTemplate} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: SmsTemplate, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getBody();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    SmsTemplate.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string body = 1;
   * @return {string}
   */
  getBody(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setBody(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


}
export namespace PbisType {
  export interface AsObject {
    id: number;
    name: string;
    categoryId: number;
    active: boolean;
    createdBy: number;
    mingaId: number;
    points: number;
    sendParentEmail: boolean;
    sendParentSms: boolean;
    sendAdminEmail: boolean;
    adminEmailList: string[];
    dailyBehaviorCount: number;
    automationId: number;
    hallPassTypeId: number;
    membershipListId: number;
    membershipList?: membership_list_membership_list_pb.MembershipList.AsObject;
    emailTemplate?: EmailTemplate.AsObject;
    smsTemplate?: SmsTemplate.AsObject;
    iconType: string;
    iconColor: string;
    restriction?: restriction_restriction_pb.Restriction.AsObject;
  }
}
export class PbisType extends jspb.Message {
  static readonly displayName = "PbisType";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [11];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, PbisType.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): PbisType.AsObject {
    return PbisType.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!PbisType} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: PbisType) {
    var f: any;
    var obj: any = {
      id: jspb.Message.getFieldWithDefault(msg, 1, 0),
      name: jspb.Message.getFieldWithDefault(msg, 2, ""),
      categoryId: jspb.Message.getFieldWithDefault(msg, 3, 0),
      active: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
      createdBy: jspb.Message.getFieldWithDefault(msg, 5, 0),
      mingaId: jspb.Message.getFieldWithDefault(msg, 6, 0),
      points: jspb.Message.getFieldWithDefault(msg, 7, 0),
      sendParentEmail: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
      sendParentSms: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
      sendAdminEmail: jspb.Message.getBooleanFieldWithDefault(msg, 10, false),
      adminEmailList: (f = jspb.Message.getRepeatedField(msg, 11)) == null ? undefined : f,
      dailyBehaviorCount: jspb.Message.getFieldWithDefault(msg, 12, 0),
      automationId: jspb.Message.getFieldWithDefault(msg, 13, 0),
      hallPassTypeId: jspb.Message.getFieldWithDefault(msg, 14, 0),
      membershipListId: jspb.Message.getFieldWithDefault(msg, 15, 0),
      membershipList: (f = msg.getMembershipList()) && membership_list_membership_list_pb.MembershipList.toObject(includeInstance, f),
      emailTemplate: (f = msg.getEmailTemplate()) && EmailTemplate.toObject(includeInstance, f),
      smsTemplate: (f = msg.getSmsTemplate()) && SmsTemplate.toObject(includeInstance, f),
      iconType: jspb.Message.getFieldWithDefault(msg, 19, ""),
      iconColor: jspb.Message.getFieldWithDefault(msg, 20, ""),
      restriction: (f = msg.getRestriction()) && restriction_restriction_pb.Restriction.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!PbisType}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new PbisType;
    return PbisType.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!PbisType} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!PbisType}
   */
  static deserializeBinaryFromReader(msg: PbisType, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {number} */ (reader.readInt32());
        msg.setId(value1);
        break;
      case 2:
        var value2 = /** @type {string} */ (reader.readString());
        msg.setName(value2);
        break;
      case 3:
        var value3 = /** @type {number} */ (reader.readInt32());
        msg.setCategoryId(value3);
        break;
      case 4:
        var value4 = /** @type {boolean} */ (reader.readBool());
        msg.setActive(value4);
        break;
      case 5:
        var value5 = /** @type {number} */ (reader.readInt32());
        msg.setCreatedBy(value5);
        break;
      case 6:
        var value6 = /** @type {number} */ (reader.readInt32());
        msg.setMingaId(value6);
        break;
      case 7:
        var value7 = /** @type {number} */ (reader.readInt32());
        msg.setPoints(value7);
        break;
      case 8:
        var value8 = /** @type {boolean} */ (reader.readBool());
        msg.setSendParentEmail(value8);
        break;
      case 9:
        var value9 = /** @type {boolean} */ (reader.readBool());
        msg.setSendParentSms(value9);
        break;
      case 10:
        var value10 = /** @type {boolean} */ (reader.readBool());
        msg.setSendAdminEmail(value10);
        break;
      case 11:
        var value11 = /** @type {string} */ (reader.readString());
        msg.addAdminEmail(value11);
        break;
      case 12:
        var value12 = /** @type {number} */ (reader.readInt32());
        msg.setDailyBehaviorCount(value12);
        break;
      case 13:
        var value13 = /** @type {number} */ (reader.readInt32());
        msg.setAutomationId(value13);
        break;
      case 14:
        var value14 = /** @type {number} */ (reader.readInt32());
        msg.setHallPassTypeId(value14);
        break;
      case 15:
        var value15 = /** @type {number} */ (reader.readInt32());
        msg.setMembershipListId(value15);
        break;
      case 16:
        var value16 = new membership_list_membership_list_pb.MembershipList;
        reader.readMessage(value16,membership_list_membership_list_pb.MembershipList.deserializeBinaryFromReader);
        msg.setMembershipList(value16);
        break;
      case 17:
        var value17 = new EmailTemplate;
        reader.readMessage(value17,EmailTemplate.deserializeBinaryFromReader);
        msg.setEmailTemplate(value17);
        break;
      case 18:
        var value18 = new SmsTemplate;
        reader.readMessage(value18,SmsTemplate.deserializeBinaryFromReader);
        msg.setSmsTemplate(value18);
        break;
      case 19:
        var value19 = /** @type {string} */ (reader.readString());
        msg.setIconType(value19);
        break;
      case 20:
        var value20 = /** @type {string} */ (reader.readString());
        msg.setIconColor(value20);
        break;
      case 21:
        var value21 = new restriction_restriction_pb.Restriction;
        reader.readMessage(value21,restriction_restriction_pb.Restriction.deserializeBinaryFromReader);
        msg.setRestriction(value21);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!PbisType} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: PbisType, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getId();
    if (f !== 0) {
      writer.writeInt32(
        1,
        f
      );
    }
    f = message.getName();
    if (f.length > 0) {
      writer.writeString(
        2,
        f
      );
    }
    f = message.getCategoryId();
    if (f !== 0) {
      writer.writeInt32(
        3,
        f
      );
    }
    f = message.getActive();
    if (f) {
      writer.writeBool(
        4,
        f
      );
    }
    f = message.getCreatedBy();
    if (f !== 0) {
      writer.writeInt32(
        5,
        f
      );
    }
    f = message.getMingaId();
    if (f !== 0) {
      writer.writeInt32(
        6,
        f
      );
    }
    f = message.getPoints();
    if (f !== 0) {
      writer.writeInt32(
        7,
        f
      );
    }
    f = message.getSendParentEmail();
    if (f) {
      writer.writeBool(
        8,
        f
      );
    }
    f = message.getSendParentSms();
    if (f) {
      writer.writeBool(
        9,
        f
      );
    }
    f = message.getSendAdminEmail();
    if (f) {
      writer.writeBool(
        10,
        f
      );
    }
    f = message.getAdminEmailList();
    if (f.length > 0) {
      writer.writeRepeatedString(
        11,
        f
      );
    }
    f = message.getDailyBehaviorCount();
    if (f !== 0) {
      writer.writeInt32(
        12,
        f
      );
    }
    f = message.getAutomationId();
    if (f !== 0) {
      writer.writeInt32(
        13,
        f
      );
    }
    f = message.getHallPassTypeId();
    if (f !== 0) {
      writer.writeInt32(
        14,
        f
      );
    }
    f = message.getMembershipListId();
    if (f !== 0) {
      writer.writeInt32(
        15,
        f
      );
    }
    f = message.getMembershipList();
    if (f != null) {
      writer.writeMessage(
        16,
        f,
        membership_list_membership_list_pb.MembershipList.serializeBinaryToWriter
      );
    }
    f = message.getEmailTemplate();
    if (f != null) {
      writer.writeMessage(
        17,
        f,
        EmailTemplate.serializeBinaryToWriter
      );
    }
    f = message.getSmsTemplate();
    if (f != null) {
      writer.writeMessage(
        18,
        f,
        SmsTemplate.serializeBinaryToWriter
      );
    }
    f = message.getIconType();
    if (f.length > 0) {
      writer.writeString(
        19,
        f
      );
    }
    f = message.getIconColor();
    if (f.length > 0) {
      writer.writeString(
        20,
        f
      );
    }
    f = message.getRestriction();
    if (f != null) {
      writer.writeMessage(
        21,
        f,
        restriction_restriction_pb.Restriction.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    PbisType.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional int32 id = 1;
   * @return {number}
   */
  getId(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
  };


  /** @param {number} value */
  setId(value: number) {
    jspb.Message.setProto3IntField(this, 1, value);
  }


  /**
   * optional string name = 2;
   * @return {string}
   */
  getName(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
  };


  /** @param {string} value */
  setName(value: string) {
    jspb.Message.setProto3StringField(this, 2, value);
  }


  /**
   * optional int32 category_id = 3;
   * @return {number}
   */
  getCategoryId(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
  };


  /** @param {number} value */
  setCategoryId(value: number) {
    jspb.Message.setProto3IntField(this, 3, value);
  }


  /**
   * optional bool active = 4;
   * @return {boolean}
   */
  getActive(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
  };


  /** @param {boolean} value */
  setActive(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 4, value);
  }


  /**
   * optional int32 created_by = 5;
   * @return {number}
   */
  getCreatedBy(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
  };


  /** @param {number} value */
  setCreatedBy(value: number) {
    jspb.Message.setProto3IntField(this, 5, value);
  }


  /**
   * optional int32 minga_id = 6;
   * @return {number}
   */
  getMingaId(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
  };


  /** @param {number} value */
  setMingaId(value: number) {
    jspb.Message.setProto3IntField(this, 6, value);
  }


  /**
   * optional int32 points = 7;
   * @return {number}
   */
  getPoints(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
  };


  /** @param {number} value */
  setPoints(value: number) {
    jspb.Message.setProto3IntField(this, 7, value);
  }


  /**
   * optional bool send_parent_email = 8;
   * @return {boolean}
   */
  getSendParentEmail(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
  };


  /** @param {boolean} value */
  setSendParentEmail(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 8, value);
  }


  /**
   * optional bool send_parent_sms = 9;
   * @return {boolean}
   */
  getSendParentSms(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
  };


  /** @param {boolean} value */
  setSendParentSms(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 9, value);
  }


  /**
   * optional bool send_admin_email = 10;
   * @return {boolean}
   */
  getSendAdminEmail(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
  };


  /** @param {boolean} value */
  setSendAdminEmail(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 10, value);
  }


  /**
   * repeated string admin_email = 11;
   * @return {!Array<string>}
   */
  getAdminEmailList(): string[] {
    return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 11));
  };


  /** @param {!Array<string>} value */
  setAdminEmailList(value: string[]) {
    jspb.Message.setField(this, 11, value || []);
  }


  /**
   * @param {string} value
   * @param {number=} opt_index
   */
  addAdminEmail(value: string, opt_index?: number): void {
    jspb.Message.addToRepeatedField(this, 11, value, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearAdminEmailList() {
    this.setAdminEmailList([]);
  }


  /**
   * optional int32 daily_behavior_count = 12;
   * @return {number}
   */
  getDailyBehaviorCount(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
  };


  /** @param {number} value */
  setDailyBehaviorCount(value: number) {
    jspb.Message.setProto3IntField(this, 12, value);
  }


  /**
   * optional int32 automation_id = 13;
   * @return {number}
   */
  getAutomationId(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
  };


  /** @param {number} value */
  setAutomationId(value: number) {
    jspb.Message.setProto3IntField(this, 13, value);
  }


  /**
   * optional int32 hall_pass_type_id = 14;
   * @return {number}
   */
  getHallPassTypeId(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
  };


  /** @param {number} value */
  setHallPassTypeId(value: number) {
    jspb.Message.setProto3IntField(this, 14, value);
  }


  /**
   * optional int32 membership_list_id = 15;
   * @return {number}
   */
  getMembershipListId(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
  };


  /** @param {number} value */
  setMembershipListId(value: number) {
    jspb.Message.setProto3IntField(this, 15, value);
  }


  /**
   * optional membership_list.MembershipList membership_list = 16;
   * @return {?MembershipList}
   */
  getMembershipList(): membership_list_membership_list_pb.MembershipList {
    return /** @type{?MembershipList} */ (
      jspb.Message.getWrapperField(this, membership_list_membership_list_pb.MembershipList, 16));
  }


  /** @param {?MembershipList|undefined} value */
  setMembershipList(value?: membership_list_membership_list_pb.MembershipList) {
    jspb.Message.setWrapperField(this, 16, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearMembershipList() {
    this.setMembershipList(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasMembershipList(): boolean {
    return jspb.Message.getField(this, 16) != null;
  }


  /**
   * optional EmailTemplate email_template = 17;
   * @return {?EmailTemplate}
   */
  getEmailTemplate(): EmailTemplate {
    return /** @type{?EmailTemplate} */ (
      jspb.Message.getWrapperField(this, EmailTemplate, 17));
  }


  /** @param {?EmailTemplate|undefined} value */
  setEmailTemplate(value?: EmailTemplate) {
    jspb.Message.setWrapperField(this, 17, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearEmailTemplate() {
    this.setEmailTemplate(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasEmailTemplate(): boolean {
    return jspb.Message.getField(this, 17) != null;
  }


  /**
   * optional SmsTemplate sms_template = 18;
   * @return {?SmsTemplate}
   */
  getSmsTemplate(): SmsTemplate {
    return /** @type{?SmsTemplate} */ (
      jspb.Message.getWrapperField(this, SmsTemplate, 18));
  }


  /** @param {?SmsTemplate|undefined} value */
  setSmsTemplate(value?: SmsTemplate) {
    jspb.Message.setWrapperField(this, 18, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearSmsTemplate() {
    this.setSmsTemplate(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasSmsTemplate(): boolean {
    return jspb.Message.getField(this, 18) != null;
  }


  /**
   * optional string icon_type = 19;
   * @return {string}
   */
  getIconType(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
  };


  /** @param {string} value */
  setIconType(value: string) {
    jspb.Message.setProto3StringField(this, 19, value);
  }


  /**
   * optional string icon_color = 20;
   * @return {string}
   */
  getIconColor(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
  };


  /** @param {string} value */
  setIconColor(value: string) {
    jspb.Message.setProto3StringField(this, 20, value);
  }


  /**
   * optional restriction.Restriction restriction = 21;
   * @return {?Restriction}
   */
  getRestriction(): restriction_restriction_pb.Restriction {
    return /** @type{?Restriction} */ (
      jspb.Message.getWrapperField(this, restriction_restriction_pb.Restriction, 21));
  }


  /** @param {?Restriction|undefined} value */
  setRestriction(value?: restriction_restriction_pb.Restriction) {
    jspb.Message.setWrapperField(this, 21, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearRestriction() {
    this.setRestriction(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasRestriction(): boolean {
    return jspb.Message.getField(this, 21) != null;
  }


}
export namespace PbisBehavior {
  export interface AsObject {
    id: number;
    mingaId: number;
    message: string;
    pbisTypeId: number;
    type?: PbisType.AsObject;
    recipientId: number;
    recipient?: gateway_person_view_pb.PersonViewMinimal.AsObject;
    createdBy: number;
    creator?: gateway_person_view_pb.PersonViewMinimal.AsObject;
    createdAt?: common_date_pb.DateTime.AsObject;
    points: number;
    imported: boolean;
    currentClass?: student_schedule_student_schedule_pb.StudentSection.AsObject;
    currentClassesList: student_schedule_student_schedule_pb.StudentSection.AsObject[];
  }
}
export class PbisBehavior extends jspb.Message {
  static readonly displayName = "PbisBehavior";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [14];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, PbisBehavior.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): PbisBehavior.AsObject {
    return PbisBehavior.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!PbisBehavior} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: PbisBehavior) {
    var f: any;
    var obj: any = {
      id: jspb.Message.getFieldWithDefault(msg, 1, 0),
      mingaId: jspb.Message.getFieldWithDefault(msg, 2, 0),
      message: jspb.Message.getFieldWithDefault(msg, 3, ""),
      pbisTypeId: jspb.Message.getFieldWithDefault(msg, 4, 0),
      type: (f = msg.getType()) && PbisType.toObject(includeInstance, f),
      recipientId: jspb.Message.getFieldWithDefault(msg, 6, 0),
      recipient: (f = msg.getRecipient()) && gateway_person_view_pb.PersonViewMinimal.toObject(includeInstance, f),
      createdBy: jspb.Message.getFieldWithDefault(msg, 8, 0),
      creator: (f = msg.getCreator()) && gateway_person_view_pb.PersonViewMinimal.toObject(includeInstance, f),
      createdAt: (f = msg.getCreatedAt()) && common_date_pb.DateTime.toObject(includeInstance, f),
      points: jspb.Message.getFieldWithDefault(msg, 11, 0),
      imported: jspb.Message.getBooleanFieldWithDefault(msg, 12, false),
      currentClass: (f = msg.getCurrentClass()) && student_schedule_student_schedule_pb.StudentSection.toObject(includeInstance, f),
      currentClassesList: jspb.Message.toObjectList(msg.getCurrentClassesList(),
      student_schedule_student_schedule_pb.StudentSection.toObject, includeInstance)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!PbisBehavior}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new PbisBehavior;
    return PbisBehavior.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!PbisBehavior} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!PbisBehavior}
   */
  static deserializeBinaryFromReader(msg: PbisBehavior, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {number} */ (reader.readInt32());
        msg.setId(value1);
        break;
      case 2:
        var value2 = /** @type {number} */ (reader.readInt32());
        msg.setMingaId(value2);
        break;
      case 3:
        var value3 = /** @type {string} */ (reader.readString());
        msg.setMessage(value3);
        break;
      case 4:
        var value4 = /** @type {number} */ (reader.readInt32());
        msg.setPbisTypeId(value4);
        break;
      case 5:
        var value5 = new PbisType;
        reader.readMessage(value5,PbisType.deserializeBinaryFromReader);
        msg.setType(value5);
        break;
      case 6:
        var value6 = /** @type {number} */ (reader.readInt32());
        msg.setRecipientId(value6);
        break;
      case 7:
        var value7 = new gateway_person_view_pb.PersonViewMinimal;
        reader.readMessage(value7,gateway_person_view_pb.PersonViewMinimal.deserializeBinaryFromReader);
        msg.setRecipient(value7);
        break;
      case 8:
        var value8 = /** @type {number} */ (reader.readInt32());
        msg.setCreatedBy(value8);
        break;
      case 9:
        var value9 = new gateway_person_view_pb.PersonViewMinimal;
        reader.readMessage(value9,gateway_person_view_pb.PersonViewMinimal.deserializeBinaryFromReader);
        msg.setCreator(value9);
        break;
      case 10:
        var value10 = new common_date_pb.DateTime;
        reader.readMessage(value10,common_date_pb.DateTime.deserializeBinaryFromReader);
        msg.setCreatedAt(value10);
        break;
      case 11:
        var value11 = /** @type {number} */ (reader.readInt32());
        msg.setPoints(value11);
        break;
      case 12:
        var value12 = /** @type {boolean} */ (reader.readBool());
        msg.setImported(value12);
        break;
      case 13:
        var value13 = new student_schedule_student_schedule_pb.StudentSection;
        reader.readMessage(value13,student_schedule_student_schedule_pb.StudentSection.deserializeBinaryFromReader);
        msg.setCurrentClass(value13);
        break;
      case 14:
        var value14 = new student_schedule_student_schedule_pb.StudentSection;
        reader.readMessage(value14,student_schedule_student_schedule_pb.StudentSection.deserializeBinaryFromReader);
        msg.addCurrentClasses(value14);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!PbisBehavior} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: PbisBehavior, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getId();
    if (f !== 0) {
      writer.writeInt32(
        1,
        f
      );
    }
    f = message.getMingaId();
    if (f !== 0) {
      writer.writeInt32(
        2,
        f
      );
    }
    f = message.getMessage();
    if (f.length > 0) {
      writer.writeString(
        3,
        f
      );
    }
    f = message.getPbisTypeId();
    if (f !== 0) {
      writer.writeInt32(
        4,
        f
      );
    }
    f = message.getType();
    if (f != null) {
      writer.writeMessage(
        5,
        f,
        PbisType.serializeBinaryToWriter
      );
    }
    f = message.getRecipientId();
    if (f !== 0) {
      writer.writeInt32(
        6,
        f
      );
    }
    f = message.getRecipient();
    if (f != null) {
      writer.writeMessage(
        7,
        f,
        gateway_person_view_pb.PersonViewMinimal.serializeBinaryToWriter
      );
    }
    f = message.getCreatedBy();
    if (f !== 0) {
      writer.writeInt32(
        8,
        f
      );
    }
    f = message.getCreator();
    if (f != null) {
      writer.writeMessage(
        9,
        f,
        gateway_person_view_pb.PersonViewMinimal.serializeBinaryToWriter
      );
    }
    f = message.getCreatedAt();
    if (f != null) {
      writer.writeMessage(
        10,
        f,
        common_date_pb.DateTime.serializeBinaryToWriter
      );
    }
    f = message.getPoints();
    if (f !== 0) {
      writer.writeInt32(
        11,
        f
      );
    }
    f = message.getImported();
    if (f) {
      writer.writeBool(
        12,
        f
      );
    }
    f = message.getCurrentClass();
    if (f != null) {
      writer.writeMessage(
        13,
        f,
        student_schedule_student_schedule_pb.StudentSection.serializeBinaryToWriter
      );
    }
    f = message.getCurrentClassesList();
    if (f.length > 0) {
      writer.writeRepeatedMessage(
        14,
        f,
        student_schedule_student_schedule_pb.StudentSection.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    PbisBehavior.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional int32 id = 1;
   * @return {number}
   */
  getId(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
  };


  /** @param {number} value */
  setId(value: number) {
    jspb.Message.setProto3IntField(this, 1, value);
  }


  /**
   * optional int32 minga_id = 2;
   * @return {number}
   */
  getMingaId(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
  };


  /** @param {number} value */
  setMingaId(value: number) {
    jspb.Message.setProto3IntField(this, 2, value);
  }


  /**
   * optional string message = 3;
   * @return {string}
   */
  getMessage(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
  };


  /** @param {string} value */
  setMessage(value: string) {
    jspb.Message.setProto3StringField(this, 3, value);
  }


  /**
   * optional int32 pbis_type_id = 4;
   * @return {number}
   */
  getPbisTypeId(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
  };


  /** @param {number} value */
  setPbisTypeId(value: number) {
    jspb.Message.setProto3IntField(this, 4, value);
  }


  /**
   * optional PbisType type = 5;
   * @return {?PbisType}
   */
  getType(): PbisType {
    return /** @type{?PbisType} */ (
      jspb.Message.getWrapperField(this, PbisType, 5));
  }


  /** @param {?PbisType|undefined} value */
  setType(value?: PbisType) {
    jspb.Message.setWrapperField(this, 5, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearType() {
    this.setType(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasType(): boolean {
    return jspb.Message.getField(this, 5) != null;
  }


  /**
   * optional int32 recipient_id = 6;
   * @return {number}
   */
  getRecipientId(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
  };


  /** @param {number} value */
  setRecipientId(value: number) {
    jspb.Message.setProto3IntField(this, 6, value);
  }


  /**
   * optional PersonViewMinimal recipient = 7;
   * @return {?PersonViewMinimal}
   */
  getRecipient(): gateway_person_view_pb.PersonViewMinimal {
    return /** @type{?PersonViewMinimal} */ (
      jspb.Message.getWrapperField(this, gateway_person_view_pb.PersonViewMinimal, 7));
  }


  /** @param {?PersonViewMinimal|undefined} value */
  setRecipient(value?: gateway_person_view_pb.PersonViewMinimal) {
    jspb.Message.setWrapperField(this, 7, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearRecipient() {
    this.setRecipient(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasRecipient(): boolean {
    return jspb.Message.getField(this, 7) != null;
  }


  /**
   * optional int32 created_by = 8;
   * @return {number}
   */
  getCreatedBy(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
  };


  /** @param {number} value */
  setCreatedBy(value: number) {
    jspb.Message.setProto3IntField(this, 8, value);
  }


  /**
   * optional PersonViewMinimal creator = 9;
   * @return {?PersonViewMinimal}
   */
  getCreator(): gateway_person_view_pb.PersonViewMinimal {
    return /** @type{?PersonViewMinimal} */ (
      jspb.Message.getWrapperField(this, gateway_person_view_pb.PersonViewMinimal, 9));
  }


  /** @param {?PersonViewMinimal|undefined} value */
  setCreator(value?: gateway_person_view_pb.PersonViewMinimal) {
    jspb.Message.setWrapperField(this, 9, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearCreator() {
    this.setCreator(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasCreator(): boolean {
    return jspb.Message.getField(this, 9) != null;
  }


  /**
   * optional minga.common.DateTime created_at = 10;
   * @return {?DateTime}
   */
  getCreatedAt(): common_date_pb.DateTime {
    return /** @type{?DateTime} */ (
      jspb.Message.getWrapperField(this, common_date_pb.DateTime, 10));
  }


  /** @param {?DateTime|undefined} value */
  setCreatedAt(value?: common_date_pb.DateTime) {
    jspb.Message.setWrapperField(this, 10, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearCreatedAt() {
    this.setCreatedAt(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasCreatedAt(): boolean {
    return jspb.Message.getField(this, 10) != null;
  }


  /**
   * optional int32 points = 11;
   * @return {number}
   */
  getPoints(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
  };


  /** @param {number} value */
  setPoints(value: number) {
    jspb.Message.setProto3IntField(this, 11, value);
  }


  /**
   * optional bool imported = 12;
   * @return {boolean}
   */
  getImported(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 12, false));
  };


  /** @param {boolean} value */
  setImported(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 12, value);
  }


  /**
   * optional minga.student_schedule.StudentSection current_class = 13;
   * @return {?StudentSection}
   */
  getCurrentClass(): student_schedule_student_schedule_pb.StudentSection {
    return /** @type{?StudentSection} */ (
      jspb.Message.getWrapperField(this, student_schedule_student_schedule_pb.StudentSection, 13));
  }


  /** @param {?StudentSection|undefined} value */
  setCurrentClass(value?: student_schedule_student_schedule_pb.StudentSection) {
    jspb.Message.setWrapperField(this, 13, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearCurrentClass() {
    this.setCurrentClass(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasCurrentClass(): boolean {
    return jspb.Message.getField(this, 13) != null;
  }


  /**
   * repeated minga.student_schedule.StudentSection current_classes = 14;
   * @return {!Array<!StudentSection>}
   */
  getCurrentClassesList(): student_schedule_student_schedule_pb.StudentSection[] {
    return /** @type{!Array<!StudentSection>} */ (
      jspb.Message.getRepeatedWrapperField(this, student_schedule_student_schedule_pb.StudentSection, 14));
  }


  /** @param {!Array<!StudentSection>} value */
  setCurrentClassesList(value?: student_schedule_student_schedule_pb.StudentSection[]) {
    jspb.Message.setRepeatedWrapperField(this, 14, value);
  }


  /**
   * @param {!StudentSection=} opt_value
   * @param {number=} opt_index
   * @return {!StudentSection}
   */
  addCurrentClasses(opt_value?: student_schedule_student_schedule_pb.StudentSection, opt_index?: number): student_schedule_student_schedule_pb.StudentSection {
    return jspb.Message.addToRepeatedWrapperField(this, 14, opt_value, student_schedule_student_schedule_pb.StudentSection, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearCurrentClassesList() {
    this.setCurrentClassesList([]);
  }


}
export namespace EditablePbisBehavior {
  export interface AsObject {
    behavior?: PbisBehavior.AsObject;
    manuallyUpdatedAt?: common_date_pb.DateTime.AsObject;
  }
}
export class EditablePbisBehavior extends jspb.Message {
  static readonly displayName = "EditablePbisBehavior";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): EditablePbisBehavior.AsObject {
    return EditablePbisBehavior.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!EditablePbisBehavior} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: EditablePbisBehavior) {
    var f: any;
    var obj: any = {
      behavior: (f = msg.getBehavior()) && PbisBehavior.toObject(includeInstance, f),
      manuallyUpdatedAt: (f = msg.getManuallyUpdatedAt()) && common_date_pb.DateTime.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!EditablePbisBehavior}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new EditablePbisBehavior;
    return EditablePbisBehavior.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!EditablePbisBehavior} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!EditablePbisBehavior}
   */
  static deserializeBinaryFromReader(msg: EditablePbisBehavior, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new PbisBehavior;
        reader.readMessage(value1,PbisBehavior.deserializeBinaryFromReader);
        msg.setBehavior(value1);
        break;
      case 2:
        var value2 = new common_date_pb.DateTime;
        reader.readMessage(value2,common_date_pb.DateTime.deserializeBinaryFromReader);
        msg.setManuallyUpdatedAt(value2);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!EditablePbisBehavior} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: EditablePbisBehavior, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getBehavior();
    if (f != null) {
      writer.writeMessage(
        1,
        f,
        PbisBehavior.serializeBinaryToWriter
      );
    }
    f = message.getManuallyUpdatedAt();
    if (f != null) {
      writer.writeMessage(
        2,
        f,
        common_date_pb.DateTime.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    EditablePbisBehavior.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional PbisBehavior behavior = 1;
   * @return {?PbisBehavior}
   */
  getBehavior(): PbisBehavior {
    return /** @type{?PbisBehavior} */ (
      jspb.Message.getWrapperField(this, PbisBehavior, 1));
  }


  /** @param {?PbisBehavior|undefined} value */
  setBehavior(value?: PbisBehavior) {
    jspb.Message.setWrapperField(this, 1, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearBehavior() {
    this.setBehavior(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasBehavior(): boolean {
    return jspb.Message.getField(this, 1) != null;
  }


  /**
   * optional minga.common.DateTime manually_updated_at = 2;
   * @return {?DateTime}
   */
  getManuallyUpdatedAt(): common_date_pb.DateTime {
    return /** @type{?DateTime} */ (
      jspb.Message.getWrapperField(this, common_date_pb.DateTime, 2));
  }


  /** @param {?DateTime|undefined} value */
  setManuallyUpdatedAt(value?: common_date_pb.DateTime) {
    jspb.Message.setWrapperField(this, 2, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearManuallyUpdatedAt() {
    this.setManuallyUpdatedAt(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasManuallyUpdatedAt(): boolean {
    return jspb.Message.getField(this, 2) != null;
  }


}
export namespace Consequence {
  export interface AsObject {
    id: number;
    mingaId: number;
    message: string;
    typeId: number;
    type?: ConsequenceType.AsObject;
    issuedTo: number;
    issuedToPerson?: gateway_person_view_pb.PersonViewMinimal.AsObject;
    issuedBy: number;
    issuedByPerson?: gateway_person_view_pb.PersonViewMinimal.AsObject;
    issuedAt?: common_date_pb.DateTime.AsObject;
    completedAt?: common_date_pb.DateTime.AsObject;
    status: string;
    notes: string;
    completeBy?: common_date_pb.DateTime.AsObject;
    complete: boolean;
    role: string;
    grade: string;
    imported: boolean;
    automationName: string;
    sourceId: number;
    overdueActionType: string;
    actionName: string;
    completedBy: number;
    completedByPerson?: gateway_person_view_pb.PersonViewMinimal.AsObject;
    sourceOverdueActionType: string;
  }
}
export class Consequence extends jspb.Message {
  static readonly displayName = "Consequence";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): Consequence.AsObject {
    return Consequence.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!Consequence} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: Consequence) {
    var f: any;
    var obj: any = {
      id: jspb.Message.getFieldWithDefault(msg, 1, 0),
      mingaId: jspb.Message.getFieldWithDefault(msg, 2, 0),
      message: jspb.Message.getFieldWithDefault(msg, 3, ""),
      typeId: jspb.Message.getFieldWithDefault(msg, 4, 0),
      type: (f = msg.getType()) && ConsequenceType.toObject(includeInstance, f),
      issuedTo: jspb.Message.getFieldWithDefault(msg, 6, 0),
      issuedToPerson: (f = msg.getIssuedToPerson()) && gateway_person_view_pb.PersonViewMinimal.toObject(includeInstance, f),
      issuedBy: jspb.Message.getFieldWithDefault(msg, 8, 0),
      issuedByPerson: (f = msg.getIssuedByPerson()) && gateway_person_view_pb.PersonViewMinimal.toObject(includeInstance, f),
      issuedAt: (f = msg.getIssuedAt()) && common_date_pb.DateTime.toObject(includeInstance, f),
      completedAt: (f = msg.getCompletedAt()) && common_date_pb.DateTime.toObject(includeInstance, f),
      status: jspb.Message.getFieldWithDefault(msg, 12, ""),
      notes: jspb.Message.getFieldWithDefault(msg, 13, ""),
      completeBy: (f = msg.getCompleteBy()) && common_date_pb.DateTime.toObject(includeInstance, f),
      complete: jspb.Message.getBooleanFieldWithDefault(msg, 15, false),
      role: jspb.Message.getFieldWithDefault(msg, 16, ""),
      grade: jspb.Message.getFieldWithDefault(msg, 17, ""),
      imported: jspb.Message.getBooleanFieldWithDefault(msg, 18, false),
      automationName: jspb.Message.getFieldWithDefault(msg, 19, ""),
      sourceId: jspb.Message.getFieldWithDefault(msg, 20, 0),
      overdueActionType: jspb.Message.getFieldWithDefault(msg, 21, ""),
      actionName: jspb.Message.getFieldWithDefault(msg, 22, ""),
      completedBy: jspb.Message.getFieldWithDefault(msg, 23, 0),
      completedByPerson: (f = msg.getCompletedByPerson()) && gateway_person_view_pb.PersonViewMinimal.toObject(includeInstance, f),
      sourceOverdueActionType: jspb.Message.getFieldWithDefault(msg, 25, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!Consequence}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new Consequence;
    return Consequence.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!Consequence} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!Consequence}
   */
  static deserializeBinaryFromReader(msg: Consequence, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {number} */ (reader.readInt32());
        msg.setId(value1);
        break;
      case 2:
        var value2 = /** @type {number} */ (reader.readInt32());
        msg.setMingaId(value2);
        break;
      case 3:
        var value3 = /** @type {string} */ (reader.readString());
        msg.setMessage(value3);
        break;
      case 4:
        var value4 = /** @type {number} */ (reader.readInt32());
        msg.setTypeId(value4);
        break;
      case 5:
        var value5 = new ConsequenceType;
        reader.readMessage(value5,ConsequenceType.deserializeBinaryFromReader);
        msg.setType(value5);
        break;
      case 6:
        var value6 = /** @type {number} */ (reader.readInt32());
        msg.setIssuedTo(value6);
        break;
      case 7:
        var value7 = new gateway_person_view_pb.PersonViewMinimal;
        reader.readMessage(value7,gateway_person_view_pb.PersonViewMinimal.deserializeBinaryFromReader);
        msg.setIssuedToPerson(value7);
        break;
      case 8:
        var value8 = /** @type {number} */ (reader.readInt32());
        msg.setIssuedBy(value8);
        break;
      case 9:
        var value9 = new gateway_person_view_pb.PersonViewMinimal;
        reader.readMessage(value9,gateway_person_view_pb.PersonViewMinimal.deserializeBinaryFromReader);
        msg.setIssuedByPerson(value9);
        break;
      case 10:
        var value10 = new common_date_pb.DateTime;
        reader.readMessage(value10,common_date_pb.DateTime.deserializeBinaryFromReader);
        msg.setIssuedAt(value10);
        break;
      case 11:
        var value11 = new common_date_pb.DateTime;
        reader.readMessage(value11,common_date_pb.DateTime.deserializeBinaryFromReader);
        msg.setCompletedAt(value11);
        break;
      case 12:
        var value12 = /** @type {string} */ (reader.readString());
        msg.setStatus(value12);
        break;
      case 13:
        var value13 = /** @type {string} */ (reader.readString());
        msg.setNotes(value13);
        break;
      case 14:
        var value14 = new common_date_pb.DateTime;
        reader.readMessage(value14,common_date_pb.DateTime.deserializeBinaryFromReader);
        msg.setCompleteBy(value14);
        break;
      case 15:
        var value15 = /** @type {boolean} */ (reader.readBool());
        msg.setComplete(value15);
        break;
      case 16:
        var value16 = /** @type {string} */ (reader.readString());
        msg.setRole(value16);
        break;
      case 17:
        var value17 = /** @type {string} */ (reader.readString());
        msg.setGrade(value17);
        break;
      case 18:
        var value18 = /** @type {boolean} */ (reader.readBool());
        msg.setImported(value18);
        break;
      case 19:
        var value19 = /** @type {string} */ (reader.readString());
        msg.setAutomationName(value19);
        break;
      case 20:
        var value20 = /** @type {number} */ (reader.readInt32());
        msg.setSourceId(value20);
        break;
      case 21:
        var value21 = /** @type {string} */ (reader.readString());
        msg.setOverdueActionType(value21);
        break;
      case 22:
        var value22 = /** @type {string} */ (reader.readString());
        msg.setActionName(value22);
        break;
      case 23:
        var value23 = /** @type {number} */ (reader.readInt32());
        msg.setCompletedBy(value23);
        break;
      case 24:
        var value24 = new gateway_person_view_pb.PersonViewMinimal;
        reader.readMessage(value24,gateway_person_view_pb.PersonViewMinimal.deserializeBinaryFromReader);
        msg.setCompletedByPerson(value24);
        break;
      case 25:
        var value25 = /** @type {string} */ (reader.readString());
        msg.setSourceOverdueActionType(value25);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!Consequence} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: Consequence, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getId();
    if (f !== 0) {
      writer.writeInt32(
        1,
        f
      );
    }
    f = message.getMingaId();
    if (f !== 0) {
      writer.writeInt32(
        2,
        f
      );
    }
    f = message.getMessage();
    if (f.length > 0) {
      writer.writeString(
        3,
        f
      );
    }
    f = message.getTypeId();
    if (f !== 0) {
      writer.writeInt32(
        4,
        f
      );
    }
    f = message.getType();
    if (f != null) {
      writer.writeMessage(
        5,
        f,
        ConsequenceType.serializeBinaryToWriter
      );
    }
    f = message.getIssuedTo();
    if (f !== 0) {
      writer.writeInt32(
        6,
        f
      );
    }
    f = message.getIssuedToPerson();
    if (f != null) {
      writer.writeMessage(
        7,
        f,
        gateway_person_view_pb.PersonViewMinimal.serializeBinaryToWriter
      );
    }
    f = message.getIssuedBy();
    if (f !== 0) {
      writer.writeInt32(
        8,
        f
      );
    }
    f = message.getIssuedByPerson();
    if (f != null) {
      writer.writeMessage(
        9,
        f,
        gateway_person_view_pb.PersonViewMinimal.serializeBinaryToWriter
      );
    }
    f = message.getIssuedAt();
    if (f != null) {
      writer.writeMessage(
        10,
        f,
        common_date_pb.DateTime.serializeBinaryToWriter
      );
    }
    f = message.getCompletedAt();
    if (f != null) {
      writer.writeMessage(
        11,
        f,
        common_date_pb.DateTime.serializeBinaryToWriter
      );
    }
    f = message.getStatus();
    if (f.length > 0) {
      writer.writeString(
        12,
        f
      );
    }
    f = message.getNotes();
    if (f.length > 0) {
      writer.writeString(
        13,
        f
      );
    }
    f = message.getCompleteBy();
    if (f != null) {
      writer.writeMessage(
        14,
        f,
        common_date_pb.DateTime.serializeBinaryToWriter
      );
    }
    f = message.getComplete();
    if (f) {
      writer.writeBool(
        15,
        f
      );
    }
    f = message.getRole();
    if (f.length > 0) {
      writer.writeString(
        16,
        f
      );
    }
    f = message.getGrade();
    if (f.length > 0) {
      writer.writeString(
        17,
        f
      );
    }
    f = message.getImported();
    if (f) {
      writer.writeBool(
        18,
        f
      );
    }
    f = message.getAutomationName();
    if (f.length > 0) {
      writer.writeString(
        19,
        f
      );
    }
    f = message.getSourceId();
    if (f !== 0) {
      writer.writeInt32(
        20,
        f
      );
    }
    f = message.getOverdueActionType();
    if (f.length > 0) {
      writer.writeString(
        21,
        f
      );
    }
    f = message.getActionName();
    if (f.length > 0) {
      writer.writeString(
        22,
        f
      );
    }
    f = message.getCompletedBy();
    if (f !== 0) {
      writer.writeInt32(
        23,
        f
      );
    }
    f = message.getCompletedByPerson();
    if (f != null) {
      writer.writeMessage(
        24,
        f,
        gateway_person_view_pb.PersonViewMinimal.serializeBinaryToWriter
      );
    }
    f = message.getSourceOverdueActionType();
    if (f.length > 0) {
      writer.writeString(
        25,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    Consequence.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional int32 id = 1;
   * @return {number}
   */
  getId(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
  };


  /** @param {number} value */
  setId(value: number) {
    jspb.Message.setProto3IntField(this, 1, value);
  }


  /**
   * optional int32 minga_id = 2;
   * @return {number}
   */
  getMingaId(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
  };


  /** @param {number} value */
  setMingaId(value: number) {
    jspb.Message.setProto3IntField(this, 2, value);
  }


  /**
   * optional string message = 3;
   * @return {string}
   */
  getMessage(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
  };


  /** @param {string} value */
  setMessage(value: string) {
    jspb.Message.setProto3StringField(this, 3, value);
  }


  /**
   * optional int32 type_id = 4;
   * @return {number}
   */
  getTypeId(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
  };


  /** @param {number} value */
  setTypeId(value: number) {
    jspb.Message.setProto3IntField(this, 4, value);
  }


  /**
   * optional ConsequenceType type = 5;
   * @return {?ConsequenceType}
   */
  getType(): ConsequenceType {
    return /** @type{?ConsequenceType} */ (
      jspb.Message.getWrapperField(this, ConsequenceType, 5));
  }


  /** @param {?ConsequenceType|undefined} value */
  setType(value?: ConsequenceType) {
    jspb.Message.setWrapperField(this, 5, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearType() {
    this.setType(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasType(): boolean {
    return jspb.Message.getField(this, 5) != null;
  }


  /**
   * optional int32 issued_to = 6;
   * @return {number}
   */
  getIssuedTo(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
  };


  /** @param {number} value */
  setIssuedTo(value: number) {
    jspb.Message.setProto3IntField(this, 6, value);
  }


  /**
   * optional PersonViewMinimal issued_to_person = 7;
   * @return {?PersonViewMinimal}
   */
  getIssuedToPerson(): gateway_person_view_pb.PersonViewMinimal {
    return /** @type{?PersonViewMinimal} */ (
      jspb.Message.getWrapperField(this, gateway_person_view_pb.PersonViewMinimal, 7));
  }


  /** @param {?PersonViewMinimal|undefined} value */
  setIssuedToPerson(value?: gateway_person_view_pb.PersonViewMinimal) {
    jspb.Message.setWrapperField(this, 7, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearIssuedToPerson() {
    this.setIssuedToPerson(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasIssuedToPerson(): boolean {
    return jspb.Message.getField(this, 7) != null;
  }


  /**
   * optional int32 issued_by = 8;
   * @return {number}
   */
  getIssuedBy(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
  };


  /** @param {number} value */
  setIssuedBy(value: number) {
    jspb.Message.setProto3IntField(this, 8, value);
  }


  /**
   * optional PersonViewMinimal issued_by_person = 9;
   * @return {?PersonViewMinimal}
   */
  getIssuedByPerson(): gateway_person_view_pb.PersonViewMinimal {
    return /** @type{?PersonViewMinimal} */ (
      jspb.Message.getWrapperField(this, gateway_person_view_pb.PersonViewMinimal, 9));
  }


  /** @param {?PersonViewMinimal|undefined} value */
  setIssuedByPerson(value?: gateway_person_view_pb.PersonViewMinimal) {
    jspb.Message.setWrapperField(this, 9, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearIssuedByPerson() {
    this.setIssuedByPerson(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasIssuedByPerson(): boolean {
    return jspb.Message.getField(this, 9) != null;
  }


  /**
   * optional minga.common.DateTime issued_at = 10;
   * @return {?DateTime}
   */
  getIssuedAt(): common_date_pb.DateTime {
    return /** @type{?DateTime} */ (
      jspb.Message.getWrapperField(this, common_date_pb.DateTime, 10));
  }


  /** @param {?DateTime|undefined} value */
  setIssuedAt(value?: common_date_pb.DateTime) {
    jspb.Message.setWrapperField(this, 10, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearIssuedAt() {
    this.setIssuedAt(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasIssuedAt(): boolean {
    return jspb.Message.getField(this, 10) != null;
  }


  /**
   * optional minga.common.DateTime completed_at = 11;
   * @return {?DateTime}
   */
  getCompletedAt(): common_date_pb.DateTime {
    return /** @type{?DateTime} */ (
      jspb.Message.getWrapperField(this, common_date_pb.DateTime, 11));
  }


  /** @param {?DateTime|undefined} value */
  setCompletedAt(value?: common_date_pb.DateTime) {
    jspb.Message.setWrapperField(this, 11, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearCompletedAt() {
    this.setCompletedAt(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasCompletedAt(): boolean {
    return jspb.Message.getField(this, 11) != null;
  }


  /**
   * optional string status = 12;
   * @return {string}
   */
  getStatus(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
  };


  /** @param {string} value */
  setStatus(value: string) {
    jspb.Message.setProto3StringField(this, 12, value);
  }


  /**
   * optional string notes = 13;
   * @return {string}
   */
  getNotes(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
  };


  /** @param {string} value */
  setNotes(value: string) {
    jspb.Message.setProto3StringField(this, 13, value);
  }


  /**
   * optional minga.common.DateTime complete_by = 14;
   * @return {?DateTime}
   */
  getCompleteBy(): common_date_pb.DateTime {
    return /** @type{?DateTime} */ (
      jspb.Message.getWrapperField(this, common_date_pb.DateTime, 14));
  }


  /** @param {?DateTime|undefined} value */
  setCompleteBy(value?: common_date_pb.DateTime) {
    jspb.Message.setWrapperField(this, 14, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearCompleteBy() {
    this.setCompleteBy(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasCompleteBy(): boolean {
    return jspb.Message.getField(this, 14) != null;
  }


  /**
   * optional bool complete = 15;
   * @return {boolean}
   */
  getComplete(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 15, false));
  };


  /** @param {boolean} value */
  setComplete(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 15, value);
  }


  /**
   * optional string role = 16;
   * @return {string}
   */
  getRole(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
  };


  /** @param {string} value */
  setRole(value: string) {
    jspb.Message.setProto3StringField(this, 16, value);
  }


  /**
   * optional string grade = 17;
   * @return {string}
   */
  getGrade(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
  };


  /** @param {string} value */
  setGrade(value: string) {
    jspb.Message.setProto3StringField(this, 17, value);
  }


  /**
   * optional bool imported = 18;
   * @return {boolean}
   */
  getImported(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 18, false));
  };


  /** @param {boolean} value */
  setImported(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 18, value);
  }


  /**
   * optional string automation_name = 19;
   * @return {string}
   */
  getAutomationName(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
  };


  /** @param {string} value */
  setAutomationName(value: string) {
    jspb.Message.setProto3StringField(this, 19, value);
  }


  /**
   * optional int32 source_id = 20;
   * @return {number}
   */
  getSourceId(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 20, 0));
  };


  /** @param {number} value */
  setSourceId(value: number) {
    jspb.Message.setProto3IntField(this, 20, value);
  }


  /**
   * optional string overdue_action_type = 21;
   * @return {string}
   */
  getOverdueActionType(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 21, ""));
  };


  /** @param {string} value */
  setOverdueActionType(value: string) {
    jspb.Message.setProto3StringField(this, 21, value);
  }


  /**
   * optional string action_name = 22;
   * @return {string}
   */
  getActionName(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 22, ""));
  };


  /** @param {string} value */
  setActionName(value: string) {
    jspb.Message.setProto3StringField(this, 22, value);
  }


  /**
   * optional int32 completed_by = 23;
   * @return {number}
   */
  getCompletedBy(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 23, 0));
  };


  /** @param {number} value */
  setCompletedBy(value: number) {
    jspb.Message.setProto3IntField(this, 23, value);
  }


  /**
   * optional PersonViewMinimal completed_by_person = 24;
   * @return {?PersonViewMinimal}
   */
  getCompletedByPerson(): gateway_person_view_pb.PersonViewMinimal {
    return /** @type{?PersonViewMinimal} */ (
      jspb.Message.getWrapperField(this, gateway_person_view_pb.PersonViewMinimal, 24));
  }


  /** @param {?PersonViewMinimal|undefined} value */
  setCompletedByPerson(value?: gateway_person_view_pb.PersonViewMinimal) {
    jspb.Message.setWrapperField(this, 24, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearCompletedByPerson() {
    this.setCompletedByPerson(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasCompletedByPerson(): boolean {
    return jspb.Message.getField(this, 24) != null;
  }


  /**
   * optional string source_overdue_action_type = 25;
   * @return {string}
   */
  getSourceOverdueActionType(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 25, ""));
  };


  /** @param {string} value */
  setSourceOverdueActionType(value: string) {
    jspb.Message.setProto3StringField(this, 25, value);
  }


}
export namespace EditableConsequence {
  export interface AsObject {
    consequence?: Consequence.AsObject;
    manuallyUpdatedAt?: common_date_pb.DateTime.AsObject;
  }
}
export class EditableConsequence extends jspb.Message {
  static readonly displayName = "EditableConsequence";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): EditableConsequence.AsObject {
    return EditableConsequence.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!EditableConsequence} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: EditableConsequence) {
    var f: any;
    var obj: any = {
      consequence: (f = msg.getConsequence()) && Consequence.toObject(includeInstance, f),
      manuallyUpdatedAt: (f = msg.getManuallyUpdatedAt()) && common_date_pb.DateTime.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!EditableConsequence}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new EditableConsequence;
    return EditableConsequence.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!EditableConsequence} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!EditableConsequence}
   */
  static deserializeBinaryFromReader(msg: EditableConsequence, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new Consequence;
        reader.readMessage(value1,Consequence.deserializeBinaryFromReader);
        msg.setConsequence(value1);
        break;
      case 2:
        var value2 = new common_date_pb.DateTime;
        reader.readMessage(value2,common_date_pb.DateTime.deserializeBinaryFromReader);
        msg.setManuallyUpdatedAt(value2);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!EditableConsequence} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: EditableConsequence, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getConsequence();
    if (f != null) {
      writer.writeMessage(
        1,
        f,
        Consequence.serializeBinaryToWriter
      );
    }
    f = message.getManuallyUpdatedAt();
    if (f != null) {
      writer.writeMessage(
        2,
        f,
        common_date_pb.DateTime.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    EditableConsequence.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional Consequence consequence = 1;
   * @return {?Consequence}
   */
  getConsequence(): Consequence {
    return /** @type{?Consequence} */ (
      jspb.Message.getWrapperField(this, Consequence, 1));
  }


  /** @param {?Consequence|undefined} value */
  setConsequence(value?: Consequence) {
    jspb.Message.setWrapperField(this, 1, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearConsequence() {
    this.setConsequence(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasConsequence(): boolean {
    return jspb.Message.getField(this, 1) != null;
  }


  /**
   * optional minga.common.DateTime manually_updated_at = 2;
   * @return {?DateTime}
   */
  getManuallyUpdatedAt(): common_date_pb.DateTime {
    return /** @type{?DateTime} */ (
      jspb.Message.getWrapperField(this, common_date_pb.DateTime, 2));
  }


  /** @param {?DateTime|undefined} value */
  setManuallyUpdatedAt(value?: common_date_pb.DateTime) {
    jspb.Message.setWrapperField(this, 2, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearManuallyUpdatedAt() {
    this.setManuallyUpdatedAt(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasManuallyUpdatedAt(): boolean {
    return jspb.Message.getField(this, 2) != null;
  }


}
export namespace ConsequenceType {
  export interface AsObject {
    id: number;
    name: string;
    description: string;
    categoryId: number;
    addNotes: boolean;
    sendParentEmail: boolean;
    sendParentSms: boolean;
    sendAdminEmail: boolean;
    adminEmailList: string[];
    active: boolean;
    dailyConsequenceCount: number;
    points: number;
    typeId: number;
    stickerId: number;
    emailTemplate?: EmailTemplate.AsObject;
    smsTemplate?: SmsTemplate.AsObject;
    iconType: string;
    iconColor: string;
    enableDueDate: boolean;
    removeStickerId: number;
    restriction?: restriction_restriction_pb.Restriction.AsObject;
    overdueActionId: number;
    overdueActionType: string;
    overdueActionDueDate?: google_protobuf_wrappers_pb.Int32Value.AsObject;
    overdueAction?: ConsequenceType.AsObject;
  }
}
export class ConsequenceType extends jspb.Message {
  static readonly displayName = "ConsequenceType";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [9];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, ConsequenceType.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): ConsequenceType.AsObject {
    return ConsequenceType.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!ConsequenceType} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: ConsequenceType) {
    var f: any;
    var obj: any = {
      id: jspb.Message.getFieldWithDefault(msg, 1, 0),
      name: jspb.Message.getFieldWithDefault(msg, 2, ""),
      description: jspb.Message.getFieldWithDefault(msg, 3, ""),
      categoryId: jspb.Message.getFieldWithDefault(msg, 4, 0),
      addNotes: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
      sendParentEmail: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
      sendParentSms: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
      sendAdminEmail: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
      adminEmailList: (f = jspb.Message.getRepeatedField(msg, 9)) == null ? undefined : f,
      active: jspb.Message.getBooleanFieldWithDefault(msg, 10, false),
      dailyConsequenceCount: jspb.Message.getFieldWithDefault(msg, 11, 0),
      points: jspb.Message.getFieldWithDefault(msg, 12, 0),
      typeId: jspb.Message.getFieldWithDefault(msg, 13, 0),
      stickerId: jspb.Message.getFieldWithDefault(msg, 14, 0),
      emailTemplate: (f = msg.getEmailTemplate()) && EmailTemplate.toObject(includeInstance, f),
      smsTemplate: (f = msg.getSmsTemplate()) && SmsTemplate.toObject(includeInstance, f),
      iconType: jspb.Message.getFieldWithDefault(msg, 17, ""),
      iconColor: jspb.Message.getFieldWithDefault(msg, 18, ""),
      enableDueDate: jspb.Message.getBooleanFieldWithDefault(msg, 19, false),
      removeStickerId: jspb.Message.getFieldWithDefault(msg, 20, 0),
      restriction: (f = msg.getRestriction()) && restriction_restriction_pb.Restriction.toObject(includeInstance, f),
      overdueActionId: jspb.Message.getFieldWithDefault(msg, 22, 0),
      overdueActionType: jspb.Message.getFieldWithDefault(msg, 23, ""),
      overdueActionDueDate: (f = msg.getOverdueActionDueDate()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
      overdueAction: (f = msg.getOverdueAction()) && ConsequenceType.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!ConsequenceType}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new ConsequenceType;
    return ConsequenceType.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!ConsequenceType} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!ConsequenceType}
   */
  static deserializeBinaryFromReader(msg: ConsequenceType, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {number} */ (reader.readInt32());
        msg.setId(value1);
        break;
      case 2:
        var value2 = /** @type {string} */ (reader.readString());
        msg.setName(value2);
        break;
      case 3:
        var value3 = /** @type {string} */ (reader.readString());
        msg.setDescription(value3);
        break;
      case 4:
        var value4 = /** @type {number} */ (reader.readInt32());
        msg.setCategoryId(value4);
        break;
      case 5:
        var value5 = /** @type {boolean} */ (reader.readBool());
        msg.setAddNotes(value5);
        break;
      case 6:
        var value6 = /** @type {boolean} */ (reader.readBool());
        msg.setSendParentEmail(value6);
        break;
      case 7:
        var value7 = /** @type {boolean} */ (reader.readBool());
        msg.setSendParentSms(value7);
        break;
      case 8:
        var value8 = /** @type {boolean} */ (reader.readBool());
        msg.setSendAdminEmail(value8);
        break;
      case 9:
        var value9 = /** @type {string} */ (reader.readString());
        msg.addAdminEmail(value9);
        break;
      case 10:
        var value10 = /** @type {boolean} */ (reader.readBool());
        msg.setActive(value10);
        break;
      case 11:
        var value11 = /** @type {number} */ (reader.readInt32());
        msg.setDailyConsequenceCount(value11);
        break;
      case 12:
        var value12 = /** @type {number} */ (reader.readInt32());
        msg.setPoints(value12);
        break;
      case 13:
        var value13 = /** @type {number} */ (reader.readInt32());
        msg.setTypeId(value13);
        break;
      case 14:
        var value14 = /** @type {number} */ (reader.readInt32());
        msg.setStickerId(value14);
        break;
      case 15:
        var value15 = new EmailTemplate;
        reader.readMessage(value15,EmailTemplate.deserializeBinaryFromReader);
        msg.setEmailTemplate(value15);
        break;
      case 16:
        var value16 = new SmsTemplate;
        reader.readMessage(value16,SmsTemplate.deserializeBinaryFromReader);
        msg.setSmsTemplate(value16);
        break;
      case 17:
        var value17 = /** @type {string} */ (reader.readString());
        msg.setIconType(value17);
        break;
      case 18:
        var value18 = /** @type {string} */ (reader.readString());
        msg.setIconColor(value18);
        break;
      case 19:
        var value19 = /** @type {boolean} */ (reader.readBool());
        msg.setEnableDueDate(value19);
        break;
      case 20:
        var value20 = /** @type {number} */ (reader.readInt32());
        msg.setRemoveStickerId(value20);
        break;
      case 21:
        var value21 = new restriction_restriction_pb.Restriction;
        reader.readMessage(value21,restriction_restriction_pb.Restriction.deserializeBinaryFromReader);
        msg.setRestriction(value21);
        break;
      case 22:
        var value22 = /** @type {number} */ (reader.readInt32());
        msg.setOverdueActionId(value22);
        break;
      case 23:
        var value23 = /** @type {string} */ (reader.readString());
        msg.setOverdueActionType(value23);
        break;
      case 24:
        var value24 = new google_protobuf_wrappers_pb.Int32Value;
        reader.readMessage(value24,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
        msg.setOverdueActionDueDate(value24);
        break;
      case 25:
        var value25 = new ConsequenceType;
        reader.readMessage(value25,ConsequenceType.deserializeBinaryFromReader);
        msg.setOverdueAction(value25);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!ConsequenceType} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: ConsequenceType, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getId();
    if (f !== 0) {
      writer.writeInt32(
        1,
        f
      );
    }
    f = message.getName();
    if (f.length > 0) {
      writer.writeString(
        2,
        f
      );
    }
    f = message.getDescription();
    if (f.length > 0) {
      writer.writeString(
        3,
        f
      );
    }
    f = message.getCategoryId();
    if (f !== 0) {
      writer.writeInt32(
        4,
        f
      );
    }
    f = message.getAddNotes();
    if (f) {
      writer.writeBool(
        5,
        f
      );
    }
    f = message.getSendParentEmail();
    if (f) {
      writer.writeBool(
        6,
        f
      );
    }
    f = message.getSendParentSms();
    if (f) {
      writer.writeBool(
        7,
        f
      );
    }
    f = message.getSendAdminEmail();
    if (f) {
      writer.writeBool(
        8,
        f
      );
    }
    f = message.getAdminEmailList();
    if (f.length > 0) {
      writer.writeRepeatedString(
        9,
        f
      );
    }
    f = message.getActive();
    if (f) {
      writer.writeBool(
        10,
        f
      );
    }
    f = message.getDailyConsequenceCount();
    if (f !== 0) {
      writer.writeInt32(
        11,
        f
      );
    }
    f = message.getPoints();
    if (f !== 0) {
      writer.writeInt32(
        12,
        f
      );
    }
    f = message.getTypeId();
    if (f !== 0) {
      writer.writeInt32(
        13,
        f
      );
    }
    f = message.getStickerId();
    if (f !== 0) {
      writer.writeInt32(
        14,
        f
      );
    }
    f = message.getEmailTemplate();
    if (f != null) {
      writer.writeMessage(
        15,
        f,
        EmailTemplate.serializeBinaryToWriter
      );
    }
    f = message.getSmsTemplate();
    if (f != null) {
      writer.writeMessage(
        16,
        f,
        SmsTemplate.serializeBinaryToWriter
      );
    }
    f = message.getIconType();
    if (f.length > 0) {
      writer.writeString(
        17,
        f
      );
    }
    f = message.getIconColor();
    if (f.length > 0) {
      writer.writeString(
        18,
        f
      );
    }
    f = message.getEnableDueDate();
    if (f) {
      writer.writeBool(
        19,
        f
      );
    }
    f = message.getRemoveStickerId();
    if (f !== 0) {
      writer.writeInt32(
        20,
        f
      );
    }
    f = message.getRestriction();
    if (f != null) {
      writer.writeMessage(
        21,
        f,
        restriction_restriction_pb.Restriction.serializeBinaryToWriter
      );
    }
    f = message.getOverdueActionId();
    if (f !== 0) {
      writer.writeInt32(
        22,
        f
      );
    }
    f = message.getOverdueActionType();
    if (f.length > 0) {
      writer.writeString(
        23,
        f
      );
    }
    f = message.getOverdueActionDueDate();
    if (f != null) {
      writer.writeMessage(
        24,
        f,
        google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
      );
    }
    f = message.getOverdueAction();
    if (f != null) {
      writer.writeMessage(
        25,
        f,
        ConsequenceType.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    ConsequenceType.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional int32 id = 1;
   * @return {number}
   */
  getId(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
  };


  /** @param {number} value */
  setId(value: number) {
    jspb.Message.setProto3IntField(this, 1, value);
  }


  /**
   * optional string name = 2;
   * @return {string}
   */
  getName(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
  };


  /** @param {string} value */
  setName(value: string) {
    jspb.Message.setProto3StringField(this, 2, value);
  }


  /**
   * optional string description = 3;
   * @return {string}
   */
  getDescription(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
  };


  /** @param {string} value */
  setDescription(value: string) {
    jspb.Message.setProto3StringField(this, 3, value);
  }


  /**
   * optional int32 category_id = 4;
   * @return {number}
   */
  getCategoryId(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
  };


  /** @param {number} value */
  setCategoryId(value: number) {
    jspb.Message.setProto3IntField(this, 4, value);
  }


  /**
   * optional bool add_notes = 5;
   * @return {boolean}
   */
  getAddNotes(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
  };


  /** @param {boolean} value */
  setAddNotes(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 5, value);
  }


  /**
   * optional bool send_parent_email = 6;
   * @return {boolean}
   */
  getSendParentEmail(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
  };


  /** @param {boolean} value */
  setSendParentEmail(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 6, value);
  }


  /**
   * optional bool send_parent_sms = 7;
   * @return {boolean}
   */
  getSendParentSms(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
  };


  /** @param {boolean} value */
  setSendParentSms(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 7, value);
  }


  /**
   * optional bool send_admin_email = 8;
   * @return {boolean}
   */
  getSendAdminEmail(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
  };


  /** @param {boolean} value */
  setSendAdminEmail(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 8, value);
  }


  /**
   * repeated string admin_email = 9;
   * @return {!Array<string>}
   */
  getAdminEmailList(): string[] {
    return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 9));
  };


  /** @param {!Array<string>} value */
  setAdminEmailList(value: string[]) {
    jspb.Message.setField(this, 9, value || []);
  }


  /**
   * @param {string} value
   * @param {number=} opt_index
   */
  addAdminEmail(value: string, opt_index?: number): void {
    jspb.Message.addToRepeatedField(this, 9, value, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearAdminEmailList() {
    this.setAdminEmailList([]);
  }


  /**
   * optional bool active = 10;
   * @return {boolean}
   */
  getActive(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
  };


  /** @param {boolean} value */
  setActive(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 10, value);
  }


  /**
   * optional int32 daily_consequence_count = 11;
   * @return {number}
   */
  getDailyConsequenceCount(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
  };


  /** @param {number} value */
  setDailyConsequenceCount(value: number) {
    jspb.Message.setProto3IntField(this, 11, value);
  }


  /**
   * optional int32 points = 12;
   * @return {number}
   */
  getPoints(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
  };


  /** @param {number} value */
  setPoints(value: number) {
    jspb.Message.setProto3IntField(this, 12, value);
  }


  /**
   * optional int32 type_id = 13;
   * @return {number}
   */
  getTypeId(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
  };


  /** @param {number} value */
  setTypeId(value: number) {
    jspb.Message.setProto3IntField(this, 13, value);
  }


  /**
   * optional int32 sticker_id = 14;
   * @return {number}
   */
  getStickerId(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
  };


  /** @param {number} value */
  setStickerId(value: number) {
    jspb.Message.setProto3IntField(this, 14, value);
  }


  /**
   * optional EmailTemplate email_template = 15;
   * @return {?EmailTemplate}
   */
  getEmailTemplate(): EmailTemplate {
    return /** @type{?EmailTemplate} */ (
      jspb.Message.getWrapperField(this, EmailTemplate, 15));
  }


  /** @param {?EmailTemplate|undefined} value */
  setEmailTemplate(value?: EmailTemplate) {
    jspb.Message.setWrapperField(this, 15, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearEmailTemplate() {
    this.setEmailTemplate(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasEmailTemplate(): boolean {
    return jspb.Message.getField(this, 15) != null;
  }


  /**
   * optional SmsTemplate sms_template = 16;
   * @return {?SmsTemplate}
   */
  getSmsTemplate(): SmsTemplate {
    return /** @type{?SmsTemplate} */ (
      jspb.Message.getWrapperField(this, SmsTemplate, 16));
  }


  /** @param {?SmsTemplate|undefined} value */
  setSmsTemplate(value?: SmsTemplate) {
    jspb.Message.setWrapperField(this, 16, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearSmsTemplate() {
    this.setSmsTemplate(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasSmsTemplate(): boolean {
    return jspb.Message.getField(this, 16) != null;
  }


  /**
   * optional string icon_type = 17;
   * @return {string}
   */
  getIconType(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
  };


  /** @param {string} value */
  setIconType(value: string) {
    jspb.Message.setProto3StringField(this, 17, value);
  }


  /**
   * optional string icon_color = 18;
   * @return {string}
   */
  getIconColor(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
  };


  /** @param {string} value */
  setIconColor(value: string) {
    jspb.Message.setProto3StringField(this, 18, value);
  }


  /**
   * optional bool enable_due_date = 19;
   * @return {boolean}
   */
  getEnableDueDate(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 19, false));
  };


  /** @param {boolean} value */
  setEnableDueDate(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 19, value);
  }


  /**
   * optional int32 remove_sticker_id = 20;
   * @return {number}
   */
  getRemoveStickerId(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 20, 0));
  };


  /** @param {number} value */
  setRemoveStickerId(value: number) {
    jspb.Message.setProto3IntField(this, 20, value);
  }


  /**
   * optional restriction.Restriction restriction = 21;
   * @return {?Restriction}
   */
  getRestriction(): restriction_restriction_pb.Restriction {
    return /** @type{?Restriction} */ (
      jspb.Message.getWrapperField(this, restriction_restriction_pb.Restriction, 21));
  }


  /** @param {?Restriction|undefined} value */
  setRestriction(value?: restriction_restriction_pb.Restriction) {
    jspb.Message.setWrapperField(this, 21, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearRestriction() {
    this.setRestriction(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasRestriction(): boolean {
    return jspb.Message.getField(this, 21) != null;
  }


  /**
   * optional int32 overdue_action_id = 22;
   * @return {number}
   */
  getOverdueActionId(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 22, 0));
  };


  /** @param {number} value */
  setOverdueActionId(value: number) {
    jspb.Message.setProto3IntField(this, 22, value);
  }


  /**
   * optional string overdue_action_type = 23;
   * @return {string}
   */
  getOverdueActionType(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 23, ""));
  };


  /** @param {string} value */
  setOverdueActionType(value: string) {
    jspb.Message.setProto3StringField(this, 23, value);
  }


  /**
   * optional google.protobuf.Int32Value overdue_action_due_date = 24;
   * @return {?Int32Value}
   */
  getOverdueActionDueDate(): google_protobuf_wrappers_pb.Int32Value {
    return /** @type{?Int32Value} */ (
      jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 24));
  }


  /** @param {?Int32Value|undefined} value */
  setOverdueActionDueDate(value?: google_protobuf_wrappers_pb.Int32Value) {
    jspb.Message.setWrapperField(this, 24, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearOverdueActionDueDate() {
    this.setOverdueActionDueDate(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasOverdueActionDueDate(): boolean {
    return jspb.Message.getField(this, 24) != null;
  }


  /**
   * optional ConsequenceType overdue_action = 25;
   * @return {?ConsequenceType}
   */
  getOverdueAction(): ConsequenceType {
    return /** @type{?ConsequenceType} */ (
      jspb.Message.getWrapperField(this, ConsequenceType, 25));
  }


  /** @param {?ConsequenceType|undefined} value */
  setOverdueAction(value?: ConsequenceType) {
    jspb.Message.setWrapperField(this, 25, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearOverdueAction() {
    this.setOverdueAction(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasOverdueAction(): boolean {
    return jspb.Message.getField(this, 25) != null;
  }


}
export namespace UpdateConsequences {
  export interface AsObject {
    id: number;
    type: number;
    status: string;
    completedAt?: common_date_pb.DateTime.AsObject;
    completeBy?: common_date_pb.DateTime.AsObject;
    issuedAt?: common_date_pb.DateTime.AsObject;
    issuedToList: string[];
    issuedBy: number;
    notes: string;
    listId: number;
  }
}
export class UpdateConsequences extends jspb.Message {
  static readonly displayName = "UpdateConsequences";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [7];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, UpdateConsequences.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): UpdateConsequences.AsObject {
    return UpdateConsequences.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!UpdateConsequences} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: UpdateConsequences) {
    var f: any;
    var obj: any = {
      id: jspb.Message.getFieldWithDefault(msg, 1, 0),
      type: jspb.Message.getFieldWithDefault(msg, 2, 0),
      status: jspb.Message.getFieldWithDefault(msg, 3, ""),
      completedAt: (f = msg.getCompletedAt()) && common_date_pb.DateTime.toObject(includeInstance, f),
      completeBy: (f = msg.getCompleteBy()) && common_date_pb.DateTime.toObject(includeInstance, f),
      issuedAt: (f = msg.getIssuedAt()) && common_date_pb.DateTime.toObject(includeInstance, f),
      issuedToList: (f = jspb.Message.getRepeatedField(msg, 7)) == null ? undefined : f,
      issuedBy: jspb.Message.getFieldWithDefault(msg, 8, 0),
      notes: jspb.Message.getFieldWithDefault(msg, 9, ""),
      listId: jspb.Message.getFieldWithDefault(msg, 10, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!UpdateConsequences}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new UpdateConsequences;
    return UpdateConsequences.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!UpdateConsequences} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!UpdateConsequences}
   */
  static deserializeBinaryFromReader(msg: UpdateConsequences, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {number} */ (reader.readInt32());
        msg.setId(value1);
        break;
      case 2:
        var value2 = /** @type {number} */ (reader.readInt32());
        msg.setType(value2);
        break;
      case 3:
        var value3 = /** @type {string} */ (reader.readString());
        msg.setStatus(value3);
        break;
      case 4:
        var value4 = new common_date_pb.DateTime;
        reader.readMessage(value4,common_date_pb.DateTime.deserializeBinaryFromReader);
        msg.setCompletedAt(value4);
        break;
      case 5:
        var value5 = new common_date_pb.DateTime;
        reader.readMessage(value5,common_date_pb.DateTime.deserializeBinaryFromReader);
        msg.setCompleteBy(value5);
        break;
      case 6:
        var value6 = new common_date_pb.DateTime;
        reader.readMessage(value6,common_date_pb.DateTime.deserializeBinaryFromReader);
        msg.setIssuedAt(value6);
        break;
      case 7:
        var value7 = /** @type {string} */ (reader.readString());
        msg.addIssuedTo(value7);
        break;
      case 8:
        var value8 = /** @type {number} */ (reader.readInt32());
        msg.setIssuedBy(value8);
        break;
      case 9:
        var value9 = /** @type {string} */ (reader.readString());
        msg.setNotes(value9);
        break;
      case 10:
        var value10 = /** @type {number} */ (reader.readInt32());
        msg.setListId(value10);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!UpdateConsequences} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: UpdateConsequences, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getId();
    if (f !== 0) {
      writer.writeInt32(
        1,
        f
      );
    }
    f = message.getType();
    if (f !== 0) {
      writer.writeInt32(
        2,
        f
      );
    }
    f = message.getStatus();
    if (f.length > 0) {
      writer.writeString(
        3,
        f
      );
    }
    f = message.getCompletedAt();
    if (f != null) {
      writer.writeMessage(
        4,
        f,
        common_date_pb.DateTime.serializeBinaryToWriter
      );
    }
    f = message.getCompleteBy();
    if (f != null) {
      writer.writeMessage(
        5,
        f,
        common_date_pb.DateTime.serializeBinaryToWriter
      );
    }
    f = message.getIssuedAt();
    if (f != null) {
      writer.writeMessage(
        6,
        f,
        common_date_pb.DateTime.serializeBinaryToWriter
      );
    }
    f = message.getIssuedToList();
    if (f.length > 0) {
      writer.writeRepeatedString(
        7,
        f
      );
    }
    f = message.getIssuedBy();
    if (f !== 0) {
      writer.writeInt32(
        8,
        f
      );
    }
    f = message.getNotes();
    if (f.length > 0) {
      writer.writeString(
        9,
        f
      );
    }
    f = message.getListId();
    if (f !== 0) {
      writer.writeInt32(
        10,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    UpdateConsequences.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional int32 id = 1;
   * @return {number}
   */
  getId(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
  };


  /** @param {number} value */
  setId(value: number) {
    jspb.Message.setProto3IntField(this, 1, value);
  }


  /**
   * optional int32 type = 2;
   * @return {number}
   */
  getType(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
  };


  /** @param {number} value */
  setType(value: number) {
    jspb.Message.setProto3IntField(this, 2, value);
  }


  /**
   * optional string status = 3;
   * @return {string}
   */
  getStatus(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
  };


  /** @param {string} value */
  setStatus(value: string) {
    jspb.Message.setProto3StringField(this, 3, value);
  }


  /**
   * optional minga.common.DateTime completed_at = 4;
   * @return {?DateTime}
   */
  getCompletedAt(): common_date_pb.DateTime {
    return /** @type{?DateTime} */ (
      jspb.Message.getWrapperField(this, common_date_pb.DateTime, 4));
  }


  /** @param {?DateTime|undefined} value */
  setCompletedAt(value?: common_date_pb.DateTime) {
    jspb.Message.setWrapperField(this, 4, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearCompletedAt() {
    this.setCompletedAt(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasCompletedAt(): boolean {
    return jspb.Message.getField(this, 4) != null;
  }


  /**
   * optional minga.common.DateTime complete_by = 5;
   * @return {?DateTime}
   */
  getCompleteBy(): common_date_pb.DateTime {
    return /** @type{?DateTime} */ (
      jspb.Message.getWrapperField(this, common_date_pb.DateTime, 5));
  }


  /** @param {?DateTime|undefined} value */
  setCompleteBy(value?: common_date_pb.DateTime) {
    jspb.Message.setWrapperField(this, 5, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearCompleteBy() {
    this.setCompleteBy(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasCompleteBy(): boolean {
    return jspb.Message.getField(this, 5) != null;
  }


  /**
   * optional minga.common.DateTime issued_at = 6;
   * @return {?DateTime}
   */
  getIssuedAt(): common_date_pb.DateTime {
    return /** @type{?DateTime} */ (
      jspb.Message.getWrapperField(this, common_date_pb.DateTime, 6));
  }


  /** @param {?DateTime|undefined} value */
  setIssuedAt(value?: common_date_pb.DateTime) {
    jspb.Message.setWrapperField(this, 6, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearIssuedAt() {
    this.setIssuedAt(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasIssuedAt(): boolean {
    return jspb.Message.getField(this, 6) != null;
  }


  /**
   * repeated string issued_to = 7;
   * @return {!Array<string>}
   */
  getIssuedToList(): string[] {
    return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 7));
  };


  /** @param {!Array<string>} value */
  setIssuedToList(value: string[]) {
    jspb.Message.setField(this, 7, value || []);
  }


  /**
   * @param {string} value
   * @param {number=} opt_index
   */
  addIssuedTo(value: string, opt_index?: number): void {
    jspb.Message.addToRepeatedField(this, 7, value, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearIssuedToList() {
    this.setIssuedToList([]);
  }


  /**
   * optional int32 issued_by = 8;
   * @return {number}
   */
  getIssuedBy(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
  };


  /** @param {number} value */
  setIssuedBy(value: number) {
    jspb.Message.setProto3IntField(this, 8, value);
  }


  /**
   * optional string notes = 9;
   * @return {string}
   */
  getNotes(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
  };


  /** @param {string} value */
  setNotes(value: string) {
    jspb.Message.setProto3StringField(this, 9, value);
  }


  /**
   * optional int32 list_id = 10;
   * @return {number}
   */
  getListId(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
  };


  /** @param {number} value */
  setListId(value: number) {
    jspb.Message.setProto3IntField(this, 10, value);
  }


}
export namespace GetTypesRequest {
  export interface AsObject {
    getActiveOnly: boolean;
    getPermittedOnly: boolean;
  }
}
export class GetTypesRequest extends jspb.Message {
  static readonly displayName = "GetTypesRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): GetTypesRequest.AsObject {
    return GetTypesRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!GetTypesRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: GetTypesRequest) {
    var f: any;
    var obj: any = {
      getActiveOnly: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
      getPermittedOnly: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!GetTypesRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new GetTypesRequest;
    return GetTypesRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!GetTypesRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!GetTypesRequest}
   */
  static deserializeBinaryFromReader(msg: GetTypesRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {boolean} */ (reader.readBool());
        msg.setGetActiveOnly(value1);
        break;
      case 2:
        var value2 = /** @type {boolean} */ (reader.readBool());
        msg.setGetPermittedOnly(value2);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!GetTypesRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: GetTypesRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getGetActiveOnly();
    if (f) {
      writer.writeBool(
        1,
        f
      );
    }
    f = message.getGetPermittedOnly();
    if (f) {
      writer.writeBool(
        2,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    GetTypesRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional bool get_active_only = 1;
   * @return {boolean}
   */
  getGetActiveOnly(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
  };


  /** @param {boolean} value */
  setGetActiveOnly(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 1, value);
  }


  /**
   * optional bool get_permitted_only = 2;
   * @return {boolean}
   */
  getGetPermittedOnly(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
  };


  /** @param {boolean} value */
  setGetPermittedOnly(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 2, value);
  }


}
export namespace GetTypesResponse {
  export interface AsObject {
    typesList: PbisType.AsObject[];
  }
}
export class GetTypesResponse extends jspb.Message {
  static readonly displayName = "GetTypesResponse";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [1];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, GetTypesResponse.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): GetTypesResponse.AsObject {
    return GetTypesResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!GetTypesResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: GetTypesResponse) {
    var f: any;
    var obj: any = {
      typesList: jspb.Message.toObjectList(msg.getTypesList(),
      PbisType.toObject, includeInstance)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!GetTypesResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new GetTypesResponse;
    return GetTypesResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!GetTypesResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!GetTypesResponse}
   */
  static deserializeBinaryFromReader(msg: GetTypesResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new PbisType;
        reader.readMessage(value1,PbisType.deserializeBinaryFromReader);
        msg.addTypes(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!GetTypesResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: GetTypesResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getTypesList();
    if (f.length > 0) {
      writer.writeRepeatedMessage(
        1,
        f,
        PbisType.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    GetTypesResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * repeated PbisType types = 1;
   * @return {!Array<!PbisType>}
   */
  getTypesList(): PbisType[] {
    return /** @type{!Array<!PbisType>} */ (
      jspb.Message.getRepeatedWrapperField(this, PbisType, 1));
  }


  /** @param {!Array<!PbisType>} value */
  setTypesList(value?: PbisType[]) {
    jspb.Message.setRepeatedWrapperField(this, 1, value);
  }


  /**
   * @param {!PbisType=} opt_value
   * @param {number=} opt_index
   * @return {!PbisType}
   */
  addTypes(opt_value?: PbisType, opt_index?: number): PbisType {
    return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, PbisType, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearTypesList() {
    this.setTypesList([]);
  }


}
export namespace GetTypeRequest {
  export interface AsObject {
    id: number;
  }
}
export class GetTypeRequest extends jspb.Message {
  static readonly displayName = "GetTypeRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): GetTypeRequest.AsObject {
    return GetTypeRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!GetTypeRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: GetTypeRequest) {
    var f: any;
    var obj: any = {
      id: jspb.Message.getFieldWithDefault(msg, 1, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!GetTypeRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new GetTypeRequest;
    return GetTypeRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!GetTypeRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!GetTypeRequest}
   */
  static deserializeBinaryFromReader(msg: GetTypeRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {number} */ (reader.readInt32());
        msg.setId(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!GetTypeRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: GetTypeRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getId();
    if (f !== 0) {
      writer.writeInt32(
        1,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    GetTypeRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional int32 id = 1;
   * @return {number}
   */
  getId(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
  };


  /** @param {number} value */
  setId(value: number) {
    jspb.Message.setProto3IntField(this, 1, value);
  }


}
export namespace GetTypeResponse {
  export interface AsObject {
    type?: PbisType.AsObject;
  }
}
export class GetTypeResponse extends jspb.Message {
  static readonly displayName = "GetTypeResponse";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): GetTypeResponse.AsObject {
    return GetTypeResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!GetTypeResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: GetTypeResponse) {
    var f: any;
    var obj: any = {
      type: (f = msg.getType()) && PbisType.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!GetTypeResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new GetTypeResponse;
    return GetTypeResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!GetTypeResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!GetTypeResponse}
   */
  static deserializeBinaryFromReader(msg: GetTypeResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new PbisType;
        reader.readMessage(value1,PbisType.deserializeBinaryFromReader);
        msg.setType(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!GetTypeResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: GetTypeResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getType();
    if (f != null) {
      writer.writeMessage(
        1,
        f,
        PbisType.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    GetTypeResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional PbisType type = 1;
   * @return {?PbisType}
   */
  getType(): PbisType {
    return /** @type{?PbisType} */ (
      jspb.Message.getWrapperField(this, PbisType, 1));
  }


  /** @param {?PbisType|undefined} value */
  setType(value?: PbisType) {
    jspb.Message.setWrapperField(this, 1, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearType() {
    this.setType(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasType(): boolean {
    return jspb.Message.getField(this, 1) != null;
  }


}
export namespace UpdateTypeRequest {
  export interface AsObject {
    type?: PbisType.AsObject;
  }
}
export class UpdateTypeRequest extends jspb.Message {
  static readonly displayName = "UpdateTypeRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): UpdateTypeRequest.AsObject {
    return UpdateTypeRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!UpdateTypeRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: UpdateTypeRequest) {
    var f: any;
    var obj: any = {
      type: (f = msg.getType()) && PbisType.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!UpdateTypeRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new UpdateTypeRequest;
    return UpdateTypeRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!UpdateTypeRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!UpdateTypeRequest}
   */
  static deserializeBinaryFromReader(msg: UpdateTypeRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new PbisType;
        reader.readMessage(value1,PbisType.deserializeBinaryFromReader);
        msg.setType(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!UpdateTypeRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: UpdateTypeRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getType();
    if (f != null) {
      writer.writeMessage(
        1,
        f,
        PbisType.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    UpdateTypeRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional PbisType type = 1;
   * @return {?PbisType}
   */
  getType(): PbisType {
    return /** @type{?PbisType} */ (
      jspb.Message.getWrapperField(this, PbisType, 1));
  }


  /** @param {?PbisType|undefined} value */
  setType(value?: PbisType) {
    jspb.Message.setWrapperField(this, 1, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearType() {
    this.setType(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasType(): boolean {
    return jspb.Message.getField(this, 1) != null;
  }


}
export namespace UpdateTypeReponse {
  export interface AsObject {
    type?: PbisType.AsObject;
  }
}
export class UpdateTypeReponse extends jspb.Message {
  static readonly displayName = "UpdateTypeReponse";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): UpdateTypeReponse.AsObject {
    return UpdateTypeReponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!UpdateTypeReponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: UpdateTypeReponse) {
    var f: any;
    var obj: any = {
      type: (f = msg.getType()) && PbisType.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!UpdateTypeReponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new UpdateTypeReponse;
    return UpdateTypeReponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!UpdateTypeReponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!UpdateTypeReponse}
   */
  static deserializeBinaryFromReader(msg: UpdateTypeReponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new PbisType;
        reader.readMessage(value1,PbisType.deserializeBinaryFromReader);
        msg.setType(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!UpdateTypeReponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: UpdateTypeReponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getType();
    if (f != null) {
      writer.writeMessage(
        1,
        f,
        PbisType.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    UpdateTypeReponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional PbisType type = 1;
   * @return {?PbisType}
   */
  getType(): PbisType {
    return /** @type{?PbisType} */ (
      jspb.Message.getWrapperField(this, PbisType, 1));
  }


  /** @param {?PbisType|undefined} value */
  setType(value?: PbisType) {
    jspb.Message.setWrapperField(this, 1, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearType() {
    this.setType(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasType(): boolean {
    return jspb.Message.getField(this, 1) != null;
  }


}
export namespace DeleteTypeRequest {
  export interface AsObject {
    id: number;
  }
}
export class DeleteTypeRequest extends jspb.Message {
  static readonly displayName = "DeleteTypeRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): DeleteTypeRequest.AsObject {
    return DeleteTypeRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!DeleteTypeRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: DeleteTypeRequest) {
    var f: any;
    var obj: any = {
      id: jspb.Message.getFieldWithDefault(msg, 1, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!DeleteTypeRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new DeleteTypeRequest;
    return DeleteTypeRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!DeleteTypeRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!DeleteTypeRequest}
   */
  static deserializeBinaryFromReader(msg: DeleteTypeRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {number} */ (reader.readInt32());
        msg.setId(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!DeleteTypeRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: DeleteTypeRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getId();
    if (f !== 0) {
      writer.writeInt32(
        1,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    DeleteTypeRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional int32 id = 1;
   * @return {number}
   */
  getId(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
  };


  /** @param {number} value */
  setId(value: number) {
    jspb.Message.setProto3IntField(this, 1, value);
  }


}
export namespace DeleteTypeReponse {
  export interface AsObject {
  }
}
export class DeleteTypeReponse extends jspb.Message {
  static readonly displayName = "DeleteTypeReponse";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): DeleteTypeReponse.AsObject {
    return DeleteTypeReponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!DeleteTypeReponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: DeleteTypeReponse) {
    var f: any;
    var obj: any = {

    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!DeleteTypeReponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new DeleteTypeReponse;
    return DeleteTypeReponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!DeleteTypeReponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!DeleteTypeReponse}
   */
  static deserializeBinaryFromReader(msg: DeleteTypeReponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!DeleteTypeReponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: DeleteTypeReponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    DeleteTypeReponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
}
export namespace AddDefaultPbisTypesRequest {
  export interface AsObject {
    mingaHash: string;
  }
}
export class AddDefaultPbisTypesRequest extends jspb.Message {
  static readonly displayName = "AddDefaultPbisTypesRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): AddDefaultPbisTypesRequest.AsObject {
    return AddDefaultPbisTypesRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!AddDefaultPbisTypesRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: AddDefaultPbisTypesRequest) {
    var f: any;
    var obj: any = {
      mingaHash: jspb.Message.getFieldWithDefault(msg, 1, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!AddDefaultPbisTypesRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new AddDefaultPbisTypesRequest;
    return AddDefaultPbisTypesRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!AddDefaultPbisTypesRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!AddDefaultPbisTypesRequest}
   */
  static deserializeBinaryFromReader(msg: AddDefaultPbisTypesRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setMingaHash(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!AddDefaultPbisTypesRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: AddDefaultPbisTypesRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getMingaHash();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    AddDefaultPbisTypesRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string minga_hash = 1;
   * @return {string}
   */
  getMingaHash(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setMingaHash(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


}
export namespace AddDefaultPbisTypesResponse {
  export interface AsObject {
    errorMessage: string;
  }
}
export class AddDefaultPbisTypesResponse extends jspb.Message {
  static readonly displayName = "AddDefaultPbisTypesResponse";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): AddDefaultPbisTypesResponse.AsObject {
    return AddDefaultPbisTypesResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!AddDefaultPbisTypesResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: AddDefaultPbisTypesResponse) {
    var f: any;
    var obj: any = {
      errorMessage: jspb.Message.getFieldWithDefault(msg, 1, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!AddDefaultPbisTypesResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new AddDefaultPbisTypesResponse;
    return AddDefaultPbisTypesResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!AddDefaultPbisTypesResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!AddDefaultPbisTypesResponse}
   */
  static deserializeBinaryFromReader(msg: AddDefaultPbisTypesResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setErrorMessage(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!AddDefaultPbisTypesResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: AddDefaultPbisTypesResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getErrorMessage();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    AddDefaultPbisTypesResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string error_message = 1;
   * @return {string}
   */
  getErrorMessage(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setErrorMessage(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


}
export namespace CreateBehaviorRequest {
  export interface AsObject {
    typeId: number;
    mingaId: number;
    creatorId: number;
    recipientIdsList: string[];
    message: string;
    listId: number;
  }
}
export class CreateBehaviorRequest extends jspb.Message {
  static readonly displayName = "CreateBehaviorRequest";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [4];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, CreateBehaviorRequest.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): CreateBehaviorRequest.AsObject {
    return CreateBehaviorRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!CreateBehaviorRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: CreateBehaviorRequest) {
    var f: any;
    var obj: any = {
      typeId: jspb.Message.getFieldWithDefault(msg, 1, 0),
      mingaId: jspb.Message.getFieldWithDefault(msg, 2, 0),
      creatorId: jspb.Message.getFieldWithDefault(msg, 3, 0),
      recipientIdsList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
      message: jspb.Message.getFieldWithDefault(msg, 5, ""),
      listId: jspb.Message.getFieldWithDefault(msg, 6, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!CreateBehaviorRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new CreateBehaviorRequest;
    return CreateBehaviorRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!CreateBehaviorRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!CreateBehaviorRequest}
   */
  static deserializeBinaryFromReader(msg: CreateBehaviorRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {number} */ (reader.readInt32());
        msg.setTypeId(value1);
        break;
      case 2:
        var value2 = /** @type {number} */ (reader.readInt32());
        msg.setMingaId(value2);
        break;
      case 3:
        var value3 = /** @type {number} */ (reader.readInt32());
        msg.setCreatorId(value3);
        break;
      case 4:
        var value4 = /** @type {string} */ (reader.readString());
        msg.addRecipientIds(value4);
        break;
      case 5:
        var value5 = /** @type {string} */ (reader.readString());
        msg.setMessage(value5);
        break;
      case 6:
        var value6 = /** @type {number} */ (reader.readInt32());
        msg.setListId(value6);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!CreateBehaviorRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: CreateBehaviorRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getTypeId();
    if (f !== 0) {
      writer.writeInt32(
        1,
        f
      );
    }
    f = message.getMingaId();
    if (f !== 0) {
      writer.writeInt32(
        2,
        f
      );
    }
    f = message.getCreatorId();
    if (f !== 0) {
      writer.writeInt32(
        3,
        f
      );
    }
    f = message.getRecipientIdsList();
    if (f.length > 0) {
      writer.writeRepeatedString(
        4,
        f
      );
    }
    f = message.getMessage();
    if (f.length > 0) {
      writer.writeString(
        5,
        f
      );
    }
    f = message.getListId();
    if (f !== 0) {
      writer.writeInt32(
        6,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    CreateBehaviorRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional int32 type_id = 1;
   * @return {number}
   */
  getTypeId(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
  };


  /** @param {number} value */
  setTypeId(value: number) {
    jspb.Message.setProto3IntField(this, 1, value);
  }


  /**
   * optional int32 minga_id = 2;
   * @return {number}
   */
  getMingaId(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
  };


  /** @param {number} value */
  setMingaId(value: number) {
    jspb.Message.setProto3IntField(this, 2, value);
  }


  /**
   * optional int32 creator_id = 3;
   * @return {number}
   */
  getCreatorId(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
  };


  /** @param {number} value */
  setCreatorId(value: number) {
    jspb.Message.setProto3IntField(this, 3, value);
  }


  /**
   * repeated string recipient_ids = 4;
   * @return {!Array<string>}
   */
  getRecipientIdsList(): string[] {
    return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 4));
  };


  /** @param {!Array<string>} value */
  setRecipientIdsList(value: string[]) {
    jspb.Message.setField(this, 4, value || []);
  }


  /**
   * @param {string} value
   * @param {number=} opt_index
   */
  addRecipientIds(value: string, opt_index?: number): void {
    jspb.Message.addToRepeatedField(this, 4, value, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearRecipientIdsList() {
    this.setRecipientIdsList([]);
  }


  /**
   * optional string message = 5;
   * @return {string}
   */
  getMessage(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
  };


  /** @param {string} value */
  setMessage(value: string) {
    jspb.Message.setProto3StringField(this, 5, value);
  }


  /**
   * optional int32 list_id = 6;
   * @return {number}
   */
  getListId(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
  };


  /** @param {number} value */
  setListId(value: number) {
    jspb.Message.setProto3IntField(this, 6, value);
  }


}
export namespace CreateBehaviorResponse {
  export interface AsObject {
  }
}
export class CreateBehaviorResponse extends jspb.Message {
  static readonly displayName = "CreateBehaviorResponse";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): CreateBehaviorResponse.AsObject {
    return CreateBehaviorResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!CreateBehaviorResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: CreateBehaviorResponse) {
    var f: any;
    var obj: any = {

    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!CreateBehaviorResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new CreateBehaviorResponse;
    return CreateBehaviorResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!CreateBehaviorResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!CreateBehaviorResponse}
   */
  static deserializeBinaryFromReader(msg: CreateBehaviorResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!CreateBehaviorResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: CreateBehaviorResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    CreateBehaviorResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
}
export namespace UpdateConsequenceTypeRequest {
  export interface AsObject {
    consequenceType?: ConsequenceType.AsObject;
  }
}
export class UpdateConsequenceTypeRequest extends jspb.Message {
  static readonly displayName = "UpdateConsequenceTypeRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): UpdateConsequenceTypeRequest.AsObject {
    return UpdateConsequenceTypeRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!UpdateConsequenceTypeRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: UpdateConsequenceTypeRequest) {
    var f: any;
    var obj: any = {
      consequenceType: (f = msg.getConsequenceType()) && ConsequenceType.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!UpdateConsequenceTypeRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new UpdateConsequenceTypeRequest;
    return UpdateConsequenceTypeRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!UpdateConsequenceTypeRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!UpdateConsequenceTypeRequest}
   */
  static deserializeBinaryFromReader(msg: UpdateConsequenceTypeRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new ConsequenceType;
        reader.readMessage(value1,ConsequenceType.deserializeBinaryFromReader);
        msg.setConsequenceType(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!UpdateConsequenceTypeRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: UpdateConsequenceTypeRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getConsequenceType();
    if (f != null) {
      writer.writeMessage(
        1,
        f,
        ConsequenceType.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    UpdateConsequenceTypeRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional ConsequenceType consequence_type = 1;
   * @return {?ConsequenceType}
   */
  getConsequenceType(): ConsequenceType {
    return /** @type{?ConsequenceType} */ (
      jspb.Message.getWrapperField(this, ConsequenceType, 1));
  }


  /** @param {?ConsequenceType|undefined} value */
  setConsequenceType(value?: ConsequenceType) {
    jspb.Message.setWrapperField(this, 1, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearConsequenceType() {
    this.setConsequenceType(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasConsequenceType(): boolean {
    return jspb.Message.getField(this, 1) != null;
  }


}
export namespace UpdateConsequenceTypeResponse {
  export interface AsObject {
    consequenceType?: ConsequenceType.AsObject;
  }
}
export class UpdateConsequenceTypeResponse extends jspb.Message {
  static readonly displayName = "UpdateConsequenceTypeResponse";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): UpdateConsequenceTypeResponse.AsObject {
    return UpdateConsequenceTypeResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!UpdateConsequenceTypeResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: UpdateConsequenceTypeResponse) {
    var f: any;
    var obj: any = {
      consequenceType: (f = msg.getConsequenceType()) && ConsequenceType.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!UpdateConsequenceTypeResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new UpdateConsequenceTypeResponse;
    return UpdateConsequenceTypeResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!UpdateConsequenceTypeResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!UpdateConsequenceTypeResponse}
   */
  static deserializeBinaryFromReader(msg: UpdateConsequenceTypeResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new ConsequenceType;
        reader.readMessage(value1,ConsequenceType.deserializeBinaryFromReader);
        msg.setConsequenceType(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!UpdateConsequenceTypeResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: UpdateConsequenceTypeResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getConsequenceType();
    if (f != null) {
      writer.writeMessage(
        1,
        f,
        ConsequenceType.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    UpdateConsequenceTypeResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional ConsequenceType consequence_type = 1;
   * @return {?ConsequenceType}
   */
  getConsequenceType(): ConsequenceType {
    return /** @type{?ConsequenceType} */ (
      jspb.Message.getWrapperField(this, ConsequenceType, 1));
  }


  /** @param {?ConsequenceType|undefined} value */
  setConsequenceType(value?: ConsequenceType) {
    jspb.Message.setWrapperField(this, 1, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearConsequenceType() {
    this.setConsequenceType(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasConsequenceType(): boolean {
    return jspb.Message.getField(this, 1) != null;
  }


}
export namespace GetConsequenceTypesRequest {
  export interface AsObject {
    activeOnly: boolean;
    getPermittedOnly: boolean;
  }
}
export class GetConsequenceTypesRequest extends jspb.Message {
  static readonly displayName = "GetConsequenceTypesRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): GetConsequenceTypesRequest.AsObject {
    return GetConsequenceTypesRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!GetConsequenceTypesRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: GetConsequenceTypesRequest) {
    var f: any;
    var obj: any = {
      activeOnly: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
      getPermittedOnly: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!GetConsequenceTypesRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new GetConsequenceTypesRequest;
    return GetConsequenceTypesRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!GetConsequenceTypesRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!GetConsequenceTypesRequest}
   */
  static deserializeBinaryFromReader(msg: GetConsequenceTypesRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {boolean} */ (reader.readBool());
        msg.setActiveOnly(value1);
        break;
      case 2:
        var value2 = /** @type {boolean} */ (reader.readBool());
        msg.setGetPermittedOnly(value2);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!GetConsequenceTypesRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: GetConsequenceTypesRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getActiveOnly();
    if (f) {
      writer.writeBool(
        1,
        f
      );
    }
    f = message.getGetPermittedOnly();
    if (f) {
      writer.writeBool(
        2,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    GetConsequenceTypesRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional bool active_only = 1;
   * @return {boolean}
   */
  getActiveOnly(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
  };


  /** @param {boolean} value */
  setActiveOnly(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 1, value);
  }


  /**
   * optional bool get_permitted_only = 2;
   * @return {boolean}
   */
  getGetPermittedOnly(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
  };


  /** @param {boolean} value */
  setGetPermittedOnly(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 2, value);
  }


}
export namespace GetConsequenceTypesResponse {
  export interface AsObject {
    consequenceTypeList: ConsequenceType.AsObject[];
  }
}
export class GetConsequenceTypesResponse extends jspb.Message {
  static readonly displayName = "GetConsequenceTypesResponse";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [1];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, GetConsequenceTypesResponse.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): GetConsequenceTypesResponse.AsObject {
    return GetConsequenceTypesResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!GetConsequenceTypesResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: GetConsequenceTypesResponse) {
    var f: any;
    var obj: any = {
      consequenceTypeList: jspb.Message.toObjectList(msg.getConsequenceTypeList(),
      ConsequenceType.toObject, includeInstance)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!GetConsequenceTypesResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new GetConsequenceTypesResponse;
    return GetConsequenceTypesResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!GetConsequenceTypesResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!GetConsequenceTypesResponse}
   */
  static deserializeBinaryFromReader(msg: GetConsequenceTypesResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new ConsequenceType;
        reader.readMessage(value1,ConsequenceType.deserializeBinaryFromReader);
        msg.addConsequenceType(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!GetConsequenceTypesResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: GetConsequenceTypesResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getConsequenceTypeList();
    if (f.length > 0) {
      writer.writeRepeatedMessage(
        1,
        f,
        ConsequenceType.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    GetConsequenceTypesResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * repeated ConsequenceType consequence_type = 1;
   * @return {!Array<!ConsequenceType>}
   */
  getConsequenceTypeList(): ConsequenceType[] {
    return /** @type{!Array<!ConsequenceType>} */ (
      jspb.Message.getRepeatedWrapperField(this, ConsequenceType, 1));
  }


  /** @param {!Array<!ConsequenceType>} value */
  setConsequenceTypeList(value?: ConsequenceType[]) {
    jspb.Message.setRepeatedWrapperField(this, 1, value);
  }


  /**
   * @param {!ConsequenceType=} opt_value
   * @param {number=} opt_index
   * @return {!ConsequenceType}
   */
  addConsequenceType(opt_value?: ConsequenceType, opt_index?: number): ConsequenceType {
    return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, ConsequenceType, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearConsequenceTypeList() {
    this.setConsequenceTypeList([]);
  }


}
export namespace DeleteConsequenceTypeRequest {
  export interface AsObject {
    id: number;
  }
}
export class DeleteConsequenceTypeRequest extends jspb.Message {
  static readonly displayName = "DeleteConsequenceTypeRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): DeleteConsequenceTypeRequest.AsObject {
    return DeleteConsequenceTypeRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!DeleteConsequenceTypeRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: DeleteConsequenceTypeRequest) {
    var f: any;
    var obj: any = {
      id: jspb.Message.getFieldWithDefault(msg, 1, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!DeleteConsequenceTypeRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new DeleteConsequenceTypeRequest;
    return DeleteConsequenceTypeRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!DeleteConsequenceTypeRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!DeleteConsequenceTypeRequest}
   */
  static deserializeBinaryFromReader(msg: DeleteConsequenceTypeRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {number} */ (reader.readInt32());
        msg.setId(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!DeleteConsequenceTypeRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: DeleteConsequenceTypeRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getId();
    if (f !== 0) {
      writer.writeInt32(
        1,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    DeleteConsequenceTypeRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional int32 id = 1;
   * @return {number}
   */
  getId(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
  };


  /** @param {number} value */
  setId(value: number) {
    jspb.Message.setProto3IntField(this, 1, value);
  }


}
export namespace DeleteConsequenceTypeResponse {
  export interface AsObject {
  }
}
export class DeleteConsequenceTypeResponse extends jspb.Message {
  static readonly displayName = "DeleteConsequenceTypeResponse";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): DeleteConsequenceTypeResponse.AsObject {
    return DeleteConsequenceTypeResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!DeleteConsequenceTypeResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: DeleteConsequenceTypeResponse) {
    var f: any;
    var obj: any = {

    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!DeleteConsequenceTypeResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new DeleteConsequenceTypeResponse;
    return DeleteConsequenceTypeResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!DeleteConsequenceTypeResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!DeleteConsequenceTypeResponse}
   */
  static deserializeBinaryFromReader(msg: DeleteConsequenceTypeResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!DeleteConsequenceTypeResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: DeleteConsequenceTypeResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    DeleteConsequenceTypeResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
}
export namespace CreateConsequenceRequest {
  export interface AsObject {
    consequences?: UpdateConsequences.AsObject;
    listId: number;
  }
}
export class CreateConsequenceRequest extends jspb.Message {
  static readonly displayName = "CreateConsequenceRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): CreateConsequenceRequest.AsObject {
    return CreateConsequenceRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!CreateConsequenceRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: CreateConsequenceRequest) {
    var f: any;
    var obj: any = {
      consequences: (f = msg.getConsequences()) && UpdateConsequences.toObject(includeInstance, f),
      listId: jspb.Message.getFieldWithDefault(msg, 2, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!CreateConsequenceRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new CreateConsequenceRequest;
    return CreateConsequenceRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!CreateConsequenceRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!CreateConsequenceRequest}
   */
  static deserializeBinaryFromReader(msg: CreateConsequenceRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new UpdateConsequences;
        reader.readMessage(value1,UpdateConsequences.deserializeBinaryFromReader);
        msg.setConsequences(value1);
        break;
      case 2:
        var value2 = /** @type {number} */ (reader.readInt32());
        msg.setListId(value2);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!CreateConsequenceRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: CreateConsequenceRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getConsequences();
    if (f != null) {
      writer.writeMessage(
        1,
        f,
        UpdateConsequences.serializeBinaryToWriter
      );
    }
    f = message.getListId();
    if (f !== 0) {
      writer.writeInt32(
        2,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    CreateConsequenceRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional UpdateConsequences consequences = 1;
   * @return {?UpdateConsequences}
   */
  getConsequences(): UpdateConsequences {
    return /** @type{?UpdateConsequences} */ (
      jspb.Message.getWrapperField(this, UpdateConsequences, 1));
  }


  /** @param {?UpdateConsequences|undefined} value */
  setConsequences(value?: UpdateConsequences) {
    jspb.Message.setWrapperField(this, 1, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearConsequences() {
    this.setConsequences(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasConsequences(): boolean {
    return jspb.Message.getField(this, 1) != null;
  }


  /**
   * optional int32 list_id = 2;
   * @return {number}
   */
  getListId(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
  };


  /** @param {number} value */
  setListId(value: number) {
    jspb.Message.setProto3IntField(this, 2, value);
  }


}
export namespace CreateConsequenceResponse {
  export interface AsObject {
    consequenceList: Consequence.AsObject[];
  }
}
export class CreateConsequenceResponse extends jspb.Message {
  static readonly displayName = "CreateConsequenceResponse";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [1];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, CreateConsequenceResponse.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): CreateConsequenceResponse.AsObject {
    return CreateConsequenceResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!CreateConsequenceResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: CreateConsequenceResponse) {
    var f: any;
    var obj: any = {
      consequenceList: jspb.Message.toObjectList(msg.getConsequenceList(),
      Consequence.toObject, includeInstance)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!CreateConsequenceResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new CreateConsequenceResponse;
    return CreateConsequenceResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!CreateConsequenceResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!CreateConsequenceResponse}
   */
  static deserializeBinaryFromReader(msg: CreateConsequenceResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new Consequence;
        reader.readMessage(value1,Consequence.deserializeBinaryFromReader);
        msg.addConsequence(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!CreateConsequenceResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: CreateConsequenceResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getConsequenceList();
    if (f.length > 0) {
      writer.writeRepeatedMessage(
        1,
        f,
        Consequence.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    CreateConsequenceResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * repeated Consequence consequence = 1;
   * @return {!Array<!Consequence>}
   */
  getConsequenceList(): Consequence[] {
    return /** @type{!Array<!Consequence>} */ (
      jspb.Message.getRepeatedWrapperField(this, Consequence, 1));
  }


  /** @param {!Array<!Consequence>} value */
  setConsequenceList(value?: Consequence[]) {
    jspb.Message.setRepeatedWrapperField(this, 1, value);
  }


  /**
   * @param {!Consequence=} opt_value
   * @param {number=} opt_index
   * @return {!Consequence}
   */
  addConsequence(opt_value?: Consequence, opt_index?: number): Consequence {
    return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, Consequence, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearConsequenceList() {
    this.setConsequenceList([]);
  }


}
export namespace GetBehaviorRequest {
  export interface AsObject {
    id: number;
  }
}
export class GetBehaviorRequest extends jspb.Message {
  static readonly displayName = "GetBehaviorRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): GetBehaviorRequest.AsObject {
    return GetBehaviorRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!GetBehaviorRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: GetBehaviorRequest) {
    var f: any;
    var obj: any = {
      id: jspb.Message.getFieldWithDefault(msg, 1, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!GetBehaviorRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new GetBehaviorRequest;
    return GetBehaviorRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!GetBehaviorRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!GetBehaviorRequest}
   */
  static deserializeBinaryFromReader(msg: GetBehaviorRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {number} */ (reader.readInt32());
        msg.setId(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!GetBehaviorRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: GetBehaviorRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getId();
    if (f !== 0) {
      writer.writeInt32(
        1,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    GetBehaviorRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional int32 id = 1;
   * @return {number}
   */
  getId(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
  };


  /** @param {number} value */
  setId(value: number) {
    jspb.Message.setProto3IntField(this, 1, value);
  }


}
export namespace GetBehaviorResponse {
  export interface AsObject {
    behavior?: PbisBehavior.AsObject;
  }
}
export class GetBehaviorResponse extends jspb.Message {
  static readonly displayName = "GetBehaviorResponse";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): GetBehaviorResponse.AsObject {
    return GetBehaviorResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!GetBehaviorResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: GetBehaviorResponse) {
    var f: any;
    var obj: any = {
      behavior: (f = msg.getBehavior()) && PbisBehavior.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!GetBehaviorResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new GetBehaviorResponse;
    return GetBehaviorResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!GetBehaviorResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!GetBehaviorResponse}
   */
  static deserializeBinaryFromReader(msg: GetBehaviorResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new PbisBehavior;
        reader.readMessage(value1,PbisBehavior.deserializeBinaryFromReader);
        msg.setBehavior(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!GetBehaviorResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: GetBehaviorResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getBehavior();
    if (f != null) {
      writer.writeMessage(
        1,
        f,
        PbisBehavior.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    GetBehaviorResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional PbisBehavior behavior = 1;
   * @return {?PbisBehavior}
   */
  getBehavior(): PbisBehavior {
    return /** @type{?PbisBehavior} */ (
      jspb.Message.getWrapperField(this, PbisBehavior, 1));
  }


  /** @param {?PbisBehavior|undefined} value */
  setBehavior(value?: PbisBehavior) {
    jspb.Message.setWrapperField(this, 1, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearBehavior() {
    this.setBehavior(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasBehavior(): boolean {
    return jspb.Message.getField(this, 1) != null;
  }


}
export namespace DeleteBehaviorRequest {
  export interface AsObject {
    id: number;
    idsList: number[];
  }
}
export class DeleteBehaviorRequest extends jspb.Message {
  static readonly displayName = "DeleteBehaviorRequest";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [2];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, DeleteBehaviorRequest.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): DeleteBehaviorRequest.AsObject {
    return DeleteBehaviorRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!DeleteBehaviorRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: DeleteBehaviorRequest) {
    var f: any;
    var obj: any = {
      id: jspb.Message.getFieldWithDefault(msg, 1, 0),
      idsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!DeleteBehaviorRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new DeleteBehaviorRequest;
    return DeleteBehaviorRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!DeleteBehaviorRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!DeleteBehaviorRequest}
   */
  static deserializeBinaryFromReader(msg: DeleteBehaviorRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {number} */ (reader.readInt32());
        msg.setId(value1);
        break;
      case 2:
        var value2 = /** @type {!Array<number>} */ (reader.readPackedInt32());
        msg.setIdsList(value2);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!DeleteBehaviorRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: DeleteBehaviorRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getId();
    if (f !== 0) {
      writer.writeInt32(
        1,
        f
      );
    }
    f = message.getIdsList();
    if (f.length > 0) {
      writer.writePackedInt32(
        2,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    DeleteBehaviorRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional int32 id = 1;
   * @return {number}
   */
  getId(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
  };


  /** @param {number} value */
  setId(value: number) {
    jspb.Message.setProto3IntField(this, 1, value);
  }


  /**
   * repeated int32 ids = 2;
   * @return {!Array<number>}
   */
  getIdsList(): number[] {
    return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 2));
  };


  /** @param {!Array<number>} value */
  setIdsList(value: number[]) {
    jspb.Message.setField(this, 2, value || []);
  }


  /**
   * @param {number} value
   * @param {number=} opt_index
   */
  addIds(value: number, opt_index?: number): void {
    jspb.Message.addToRepeatedField(this, 2, value, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearIdsList() {
    this.setIdsList([]);
  }


}
export namespace DeleteBehaviorResponse {
  export interface AsObject {
  }
}
export class DeleteBehaviorResponse extends jspb.Message {
  static readonly displayName = "DeleteBehaviorResponse";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): DeleteBehaviorResponse.AsObject {
    return DeleteBehaviorResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!DeleteBehaviorResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: DeleteBehaviorResponse) {
    var f: any;
    var obj: any = {

    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!DeleteBehaviorResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new DeleteBehaviorResponse;
    return DeleteBehaviorResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!DeleteBehaviorResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!DeleteBehaviorResponse}
   */
  static deserializeBinaryFromReader(msg: DeleteBehaviorResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!DeleteBehaviorResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: DeleteBehaviorResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    DeleteBehaviorResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
}
export namespace DeleteConsequenceRequest {
  export interface AsObject {
    id: number;
    idsList: number[];
  }
}
export class DeleteConsequenceRequest extends jspb.Message {
  static readonly displayName = "DeleteConsequenceRequest";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [2];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, DeleteConsequenceRequest.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): DeleteConsequenceRequest.AsObject {
    return DeleteConsequenceRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!DeleteConsequenceRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: DeleteConsequenceRequest) {
    var f: any;
    var obj: any = {
      id: jspb.Message.getFieldWithDefault(msg, 1, 0),
      idsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!DeleteConsequenceRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new DeleteConsequenceRequest;
    return DeleteConsequenceRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!DeleteConsequenceRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!DeleteConsequenceRequest}
   */
  static deserializeBinaryFromReader(msg: DeleteConsequenceRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {number} */ (reader.readInt32());
        msg.setId(value1);
        break;
      case 2:
        var value2 = /** @type {!Array<number>} */ (reader.readPackedInt32());
        msg.setIdsList(value2);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!DeleteConsequenceRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: DeleteConsequenceRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getId();
    if (f !== 0) {
      writer.writeInt32(
        1,
        f
      );
    }
    f = message.getIdsList();
    if (f.length > 0) {
      writer.writePackedInt32(
        2,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    DeleteConsequenceRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional int32 id = 1;
   * @return {number}
   */
  getId(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
  };


  /** @param {number} value */
  setId(value: number) {
    jspb.Message.setProto3IntField(this, 1, value);
  }


  /**
   * repeated int32 ids = 2;
   * @return {!Array<number>}
   */
  getIdsList(): number[] {
    return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 2));
  };


  /** @param {!Array<number>} value */
  setIdsList(value: number[]) {
    jspb.Message.setField(this, 2, value || []);
  }


  /**
   * @param {number} value
   * @param {number=} opt_index
   */
  addIds(value: number, opt_index?: number): void {
    jspb.Message.addToRepeatedField(this, 2, value, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearIdsList() {
    this.setIdsList([]);
  }


}
export namespace DeleteConsequenceResponse {
  export interface AsObject {
  }
}
export class DeleteConsequenceResponse extends jspb.Message {
  static readonly displayName = "DeleteConsequenceResponse";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): DeleteConsequenceResponse.AsObject {
    return DeleteConsequenceResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!DeleteConsequenceResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: DeleteConsequenceResponse) {
    var f: any;
    var obj: any = {

    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!DeleteConsequenceResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new DeleteConsequenceResponse;
    return DeleteConsequenceResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!DeleteConsequenceResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!DeleteConsequenceResponse}
   */
  static deserializeBinaryFromReader(msg: DeleteConsequenceResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!DeleteConsequenceResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: DeleteConsequenceResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    DeleteConsequenceResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
}
export namespace CompleteConsequenceRequest {
  export interface AsObject {
    id: number;
    idsList: number[];
  }
}
export class CompleteConsequenceRequest extends jspb.Message {
  static readonly displayName = "CompleteConsequenceRequest";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [2];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, CompleteConsequenceRequest.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): CompleteConsequenceRequest.AsObject {
    return CompleteConsequenceRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!CompleteConsequenceRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: CompleteConsequenceRequest) {
    var f: any;
    var obj: any = {
      id: jspb.Message.getFieldWithDefault(msg, 1, 0),
      idsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!CompleteConsequenceRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new CompleteConsequenceRequest;
    return CompleteConsequenceRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!CompleteConsequenceRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!CompleteConsequenceRequest}
   */
  static deserializeBinaryFromReader(msg: CompleteConsequenceRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {number} */ (reader.readInt32());
        msg.setId(value1);
        break;
      case 2:
        var value2 = /** @type {!Array<number>} */ (reader.readPackedInt32());
        msg.setIdsList(value2);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!CompleteConsequenceRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: CompleteConsequenceRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getId();
    if (f !== 0) {
      writer.writeInt32(
        1,
        f
      );
    }
    f = message.getIdsList();
    if (f.length > 0) {
      writer.writePackedInt32(
        2,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    CompleteConsequenceRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional int32 id = 1;
   * @return {number}
   */
  getId(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
  };


  /** @param {number} value */
  setId(value: number) {
    jspb.Message.setProto3IntField(this, 1, value);
  }


  /**
   * repeated int32 ids = 2;
   * @return {!Array<number>}
   */
  getIdsList(): number[] {
    return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 2));
  };


  /** @param {!Array<number>} value */
  setIdsList(value: number[]) {
    jspb.Message.setField(this, 2, value || []);
  }


  /**
   * @param {number} value
   * @param {number=} opt_index
   */
  addIds(value: number, opt_index?: number): void {
    jspb.Message.addToRepeatedField(this, 2, value, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearIdsList() {
    this.setIdsList([]);
  }


}
export namespace CompleteConsequenceResponse {
  export interface AsObject {
  }
}
export class CompleteConsequenceResponse extends jspb.Message {
  static readonly displayName = "CompleteConsequenceResponse";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): CompleteConsequenceResponse.AsObject {
    return CompleteConsequenceResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!CompleteConsequenceResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: CompleteConsequenceResponse) {
    var f: any;
    var obj: any = {

    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!CompleteConsequenceResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new CompleteConsequenceResponse;
    return CompleteConsequenceResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!CompleteConsequenceResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!CompleteConsequenceResponse}
   */
  static deserializeBinaryFromReader(msg: CompleteConsequenceResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!CompleteConsequenceResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: CompleteConsequenceResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    CompleteConsequenceResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
}
export namespace ConsequenceOrBehavior {
  export interface AsObject {
    behavior?: PbisBehavior.AsObject;
    consequence?: Consequence.AsObject;
  }
}
export class ConsequenceOrBehavior extends jspb.Message {
  static readonly displayName = "ConsequenceOrBehavior";
  /**
   * Oneof group definitions for this message. Each group defines the field
   * numbers belonging to that group. When of these fields' value is set, all
   * other fields in the group are cleared. During deserialization, if multiple
   * fields are encountered for a group, only the last value seen will be kept.
   * @private {!Array<!Array<number>>}
   * @const
   */
  static readonly oneofGroups_ = [[1,2]];


  /**
   * @return {ConsequenceOrBehavior.ItemCase}
   */
  getItemCase() {
    return /** @type {ConsequenceOrBehavior.ItemCase} */(jspb.Message.computeOneofCase(this, ConsequenceOrBehavior.oneofGroups_[0]));
  }

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, ConsequenceOrBehavior.oneofGroups_);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): ConsequenceOrBehavior.AsObject {
    return ConsequenceOrBehavior.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!ConsequenceOrBehavior} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: ConsequenceOrBehavior) {
    var f: any;
    var obj: any = {
      behavior: (f = msg.getBehavior()) && PbisBehavior.toObject(includeInstance, f),
      consequence: (f = msg.getConsequence()) && Consequence.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!ConsequenceOrBehavior}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new ConsequenceOrBehavior;
    return ConsequenceOrBehavior.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!ConsequenceOrBehavior} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!ConsequenceOrBehavior}
   */
  static deserializeBinaryFromReader(msg: ConsequenceOrBehavior, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new PbisBehavior;
        reader.readMessage(value1,PbisBehavior.deserializeBinaryFromReader);
        msg.setBehavior(value1);
        break;
      case 2:
        var value2 = new Consequence;
        reader.readMessage(value2,Consequence.deserializeBinaryFromReader);
        msg.setConsequence(value2);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!ConsequenceOrBehavior} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: ConsequenceOrBehavior, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getBehavior();
    if (f != null) {
      writer.writeMessage(
        1,
        f,
        PbisBehavior.serializeBinaryToWriter
      );
    }
    f = message.getConsequence();
    if (f != null) {
      writer.writeMessage(
        2,
        f,
        Consequence.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    ConsequenceOrBehavior.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional PbisBehavior behavior = 1;
   * @return {?PbisBehavior}
   */
  getBehavior(): PbisBehavior {
    return /** @type{?PbisBehavior} */ (
      jspb.Message.getWrapperField(this, PbisBehavior, 1));
  }


  /** @param {?PbisBehavior|undefined} value */
  setBehavior(value?: PbisBehavior) {
    jspb.Message.setOneofWrapperField(this, 1, ConsequenceOrBehavior.oneofGroups_[0], value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearBehavior() {
    this.setBehavior(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasBehavior(): boolean {
    return jspb.Message.getField(this, 1) != null;
  }


  /**
   * optional Consequence consequence = 2;
   * @return {?Consequence}
   */
  getConsequence(): Consequence {
    return /** @type{?Consequence} */ (
      jspb.Message.getWrapperField(this, Consequence, 2));
  }


  /** @param {?Consequence|undefined} value */
  setConsequence(value?: Consequence) {
    jspb.Message.setOneofWrapperField(this, 2, ConsequenceOrBehavior.oneofGroups_[0], value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearConsequence() {
    this.setConsequence(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasConsequence(): boolean {
    return jspb.Message.getField(this, 2) != null;
  }


}
export namespace ConsequenceOrBehavior {
export enum ItemCase {
  ITEM_NOT_SET = 0,
  BEHAVIOR = 1,
  CONSEQUENCE = 2,
}
} // namespace ConsequenceOrBehavior
export namespace GetStudentToolsBehaviorsResponse {
  export interface AsObject {
    itemList: ConsequenceOrBehavior.AsObject[];
  }
}
export class GetStudentToolsBehaviorsResponse extends jspb.Message {
  static readonly displayName = "GetStudentToolsBehaviorsResponse";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [1];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, GetStudentToolsBehaviorsResponse.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): GetStudentToolsBehaviorsResponse.AsObject {
    return GetStudentToolsBehaviorsResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!GetStudentToolsBehaviorsResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: GetStudentToolsBehaviorsResponse) {
    var f: any;
    var obj: any = {
      itemList: jspb.Message.toObjectList(msg.getItemList(),
      ConsequenceOrBehavior.toObject, includeInstance)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!GetStudentToolsBehaviorsResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new GetStudentToolsBehaviorsResponse;
    return GetStudentToolsBehaviorsResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!GetStudentToolsBehaviorsResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!GetStudentToolsBehaviorsResponse}
   */
  static deserializeBinaryFromReader(msg: GetStudentToolsBehaviorsResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new ConsequenceOrBehavior;
        reader.readMessage(value1,ConsequenceOrBehavior.deserializeBinaryFromReader);
        msg.addItem(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!GetStudentToolsBehaviorsResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: GetStudentToolsBehaviorsResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getItemList();
    if (f.length > 0) {
      writer.writeRepeatedMessage(
        1,
        f,
        ConsequenceOrBehavior.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    GetStudentToolsBehaviorsResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * repeated ConsequenceOrBehavior item = 1;
   * @return {!Array<!ConsequenceOrBehavior>}
   */
  getItemList(): ConsequenceOrBehavior[] {
    return /** @type{!Array<!ConsequenceOrBehavior>} */ (
      jspb.Message.getRepeatedWrapperField(this, ConsequenceOrBehavior, 1));
  }


  /** @param {!Array<!ConsequenceOrBehavior>} value */
  setItemList(value?: ConsequenceOrBehavior[]) {
    jspb.Message.setRepeatedWrapperField(this, 1, value);
  }


  /**
   * @param {!ConsequenceOrBehavior=} opt_value
   * @param {number=} opt_index
   * @return {!ConsequenceOrBehavior}
   */
  addItem(opt_value?: ConsequenceOrBehavior, opt_index?: number): ConsequenceOrBehavior {
    return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, ConsequenceOrBehavior, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearItemList() {
    this.setItemList([]);
  }


}
export namespace GetStudentToolsBehaviorsRequest {
  export interface AsObject {
    personHash: string;
  }
}
export class GetStudentToolsBehaviorsRequest extends jspb.Message {
  static readonly displayName = "GetStudentToolsBehaviorsRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): GetStudentToolsBehaviorsRequest.AsObject {
    return GetStudentToolsBehaviorsRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!GetStudentToolsBehaviorsRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: GetStudentToolsBehaviorsRequest) {
    var f: any;
    var obj: any = {
      personHash: jspb.Message.getFieldWithDefault(msg, 1, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!GetStudentToolsBehaviorsRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new GetStudentToolsBehaviorsRequest;
    return GetStudentToolsBehaviorsRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!GetStudentToolsBehaviorsRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!GetStudentToolsBehaviorsRequest}
   */
  static deserializeBinaryFromReader(msg: GetStudentToolsBehaviorsRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setPersonHash(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!GetStudentToolsBehaviorsRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: GetStudentToolsBehaviorsRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getPersonHash();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    GetStudentToolsBehaviorsRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string person_hash = 1;
   * @return {string}
   */
  getPersonHash(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setPersonHash(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


}
export namespace BulkBehaviorPerson {
  export interface AsObject {
    email: string;
    studentId: string;
    count: number;
  }
}
export class BulkBehaviorPerson extends jspb.Message {
  static readonly displayName = "BulkBehaviorPerson";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): BulkBehaviorPerson.AsObject {
    return BulkBehaviorPerson.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!BulkBehaviorPerson} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: BulkBehaviorPerson) {
    var f: any;
    var obj: any = {
      email: jspb.Message.getFieldWithDefault(msg, 1, ""),
      studentId: jspb.Message.getFieldWithDefault(msg, 2, ""),
      count: jspb.Message.getFieldWithDefault(msg, 3, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!BulkBehaviorPerson}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new BulkBehaviorPerson;
    return BulkBehaviorPerson.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!BulkBehaviorPerson} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!BulkBehaviorPerson}
   */
  static deserializeBinaryFromReader(msg: BulkBehaviorPerson, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setEmail(value1);
        break;
      case 2:
        var value2 = /** @type {string} */ (reader.readString());
        msg.setStudentId(value2);
        break;
      case 3:
        var value3 = /** @type {number} */ (reader.readInt32());
        msg.setCount(value3);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!BulkBehaviorPerson} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: BulkBehaviorPerson, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getEmail();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
    f = message.getStudentId();
    if (f.length > 0) {
      writer.writeString(
        2,
        f
      );
    }
    f = message.getCount();
    if (f !== 0) {
      writer.writeInt32(
        3,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    BulkBehaviorPerson.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string email = 1;
   * @return {string}
   */
  getEmail(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setEmail(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


  /**
   * optional string student_id = 2;
   * @return {string}
   */
  getStudentId(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
  };


  /** @param {string} value */
  setStudentId(value: string) {
    jspb.Message.setProto3StringField(this, 2, value);
  }


  /**
   * optional int32 count = 3;
   * @return {number}
   */
  getCount(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
  };


  /** @param {number} value */
  setCount(value: number) {
    jspb.Message.setProto3IntField(this, 3, value);
  }


}
export namespace ImportBehaviorsRequest {
  export interface AsObject {
    behaviorId: number;
    consequenceId: number;
    peopleList: BulkBehaviorPerson.AsObject[];
  }
}
export class ImportBehaviorsRequest extends jspb.Message {
  static readonly displayName = "ImportBehaviorsRequest";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [3];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, ImportBehaviorsRequest.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): ImportBehaviorsRequest.AsObject {
    return ImportBehaviorsRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!ImportBehaviorsRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: ImportBehaviorsRequest) {
    var f: any;
    var obj: any = {
      behaviorId: jspb.Message.getFieldWithDefault(msg, 1, 0),
      consequenceId: jspb.Message.getFieldWithDefault(msg, 2, 0),
      peopleList: jspb.Message.toObjectList(msg.getPeopleList(),
      BulkBehaviorPerson.toObject, includeInstance)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!ImportBehaviorsRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new ImportBehaviorsRequest;
    return ImportBehaviorsRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!ImportBehaviorsRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!ImportBehaviorsRequest}
   */
  static deserializeBinaryFromReader(msg: ImportBehaviorsRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {number} */ (reader.readInt32());
        msg.setBehaviorId(value1);
        break;
      case 2:
        var value2 = /** @type {number} */ (reader.readInt32());
        msg.setConsequenceId(value2);
        break;
      case 3:
        var value3 = new BulkBehaviorPerson;
        reader.readMessage(value3,BulkBehaviorPerson.deserializeBinaryFromReader);
        msg.addPeople(value3);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!ImportBehaviorsRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: ImportBehaviorsRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getBehaviorId();
    if (f !== 0) {
      writer.writeInt32(
        1,
        f
      );
    }
    f = message.getConsequenceId();
    if (f !== 0) {
      writer.writeInt32(
        2,
        f
      );
    }
    f = message.getPeopleList();
    if (f.length > 0) {
      writer.writeRepeatedMessage(
        3,
        f,
        BulkBehaviorPerson.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    ImportBehaviorsRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional int32 behavior_id = 1;
   * @return {number}
   */
  getBehaviorId(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
  };


  /** @param {number} value */
  setBehaviorId(value: number) {
    jspb.Message.setProto3IntField(this, 1, value);
  }


  /**
   * optional int32 consequence_id = 2;
   * @return {number}
   */
  getConsequenceId(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
  };


  /** @param {number} value */
  setConsequenceId(value: number) {
    jspb.Message.setProto3IntField(this, 2, value);
  }


  /**
   * repeated BulkBehaviorPerson people = 3;
   * @return {!Array<!BulkBehaviorPerson>}
   */
  getPeopleList(): BulkBehaviorPerson[] {
    return /** @type{!Array<!BulkBehaviorPerson>} */ (
      jspb.Message.getRepeatedWrapperField(this, BulkBehaviorPerson, 3));
  }


  /** @param {!Array<!BulkBehaviorPerson>} value */
  setPeopleList(value?: BulkBehaviorPerson[]) {
    jspb.Message.setRepeatedWrapperField(this, 3, value);
  }


  /**
   * @param {!BulkBehaviorPerson=} opt_value
   * @param {number=} opt_index
   * @return {!BulkBehaviorPerson}
   */
  addPeople(opt_value?: BulkBehaviorPerson, opt_index?: number): BulkBehaviorPerson {
    return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, BulkBehaviorPerson, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearPeopleList() {
    this.setPeopleList([]);
  }


}
export namespace ImportBehaviorsResponse {
  export interface AsObject {
    totalSkipped: number;
    totalCreated: number;
    progress: number;
    totalProgress: number;
    errorList: ImportBehaviorsResponse.Error.AsObject[];
  }
}
export class ImportBehaviorsResponse extends jspb.Message {
  static readonly displayName = "ImportBehaviorsResponse";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [5];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, ImportBehaviorsResponse.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): ImportBehaviorsResponse.AsObject {
    return ImportBehaviorsResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!ImportBehaviorsResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: ImportBehaviorsResponse) {
    var f: any;
    var obj: any = {
      totalSkipped: jspb.Message.getFieldWithDefault(msg, 1, 0),
      totalCreated: jspb.Message.getFieldWithDefault(msg, 2, 0),
      progress: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
      totalProgress: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
      errorList: jspb.Message.toObjectList(msg.getErrorList(),
      ImportBehaviorsResponse.Error.toObject, includeInstance)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!ImportBehaviorsResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new ImportBehaviorsResponse;
    return ImportBehaviorsResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!ImportBehaviorsResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!ImportBehaviorsResponse}
   */
  static deserializeBinaryFromReader(msg: ImportBehaviorsResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {number} */ (reader.readInt32());
        msg.setTotalSkipped(value1);
        break;
      case 2:
        var value2 = /** @type {number} */ (reader.readInt32());
        msg.setTotalCreated(value2);
        break;
      case 3:
        var value3 = /** @type {number} */ (reader.readFloat());
        msg.setProgress(value3);
        break;
      case 4:
        var value4 = /** @type {number} */ (reader.readFloat());
        msg.setTotalProgress(value4);
        break;
      case 5:
        var value5 = new ImportBehaviorsResponse.Error;
        reader.readMessage(value5,ImportBehaviorsResponse.Error.deserializeBinaryFromReader);
        msg.addError(value5);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!ImportBehaviorsResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: ImportBehaviorsResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getTotalSkipped();
    if (f !== 0) {
      writer.writeInt32(
        1,
        f
      );
    }
    f = message.getTotalCreated();
    if (f !== 0) {
      writer.writeInt32(
        2,
        f
      );
    }
    f = message.getProgress();
    if (f !== 0.0) {
      writer.writeFloat(
        3,
        f
      );
    }
    f = message.getTotalProgress();
    if (f !== 0.0) {
      writer.writeFloat(
        4,
        f
      );
    }
    f = message.getErrorList();
    if (f.length > 0) {
      writer.writeRepeatedMessage(
        5,
        f,
        ImportBehaviorsResponse.Error.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    ImportBehaviorsResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional int32 total_skipped = 1;
   * @return {number}
   */
  getTotalSkipped(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
  };


  /** @param {number} value */
  setTotalSkipped(value: number) {
    jspb.Message.setProto3IntField(this, 1, value);
  }


  /**
   * optional int32 total_created = 2;
   * @return {number}
   */
  getTotalCreated(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
  };


  /** @param {number} value */
  setTotalCreated(value: number) {
    jspb.Message.setProto3IntField(this, 2, value);
  }


  /**
   * optional float progress = 3;
   * @return {number}
   */
  getProgress(): number {
    return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
  };


  /** @param {number} value */
  setProgress(value: number) {
    jspb.Message.setProto3FloatField(this, 3, value);
  }


  /**
   * optional float total_progress = 4;
   * @return {number}
   */
  getTotalProgress(): number {
    return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
  };


  /** @param {number} value */
  setTotalProgress(value: number) {
    jspb.Message.setProto3FloatField(this, 4, value);
  }


  /**
   * repeated Error error = 5;
   * @return {!Array<!ImportBehaviorsResponse.Error>}
   */
  getErrorList(): ImportBehaviorsResponse.Error[] {
    return /** @type{!Array<!ImportBehaviorsResponse.Error>} */ (
      jspb.Message.getRepeatedWrapperField(this, ImportBehaviorsResponse.Error, 5));
  }


  /** @param {!Array<!ImportBehaviorsResponse.Error>} value */
  setErrorList(value?: ImportBehaviorsResponse.Error[]) {
    jspb.Message.setRepeatedWrapperField(this, 5, value);
  }


  /**
   * @param {!ImportBehaviorsResponse.Error=} opt_value
   * @param {number=} opt_index
   * @return {!ImportBehaviorsResponse.Error}
   */
  addError(opt_value?: ImportBehaviorsResponse.Error, opt_index?: number): ImportBehaviorsResponse.Error {
    return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, ImportBehaviorsResponse.Error, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearErrorList() {
    this.setErrorList([]);
  }


}
export namespace ImportBehaviorsResponse {
export namespace Error {
  export interface AsObject {
    index: number;
    message: string;
  }
}
export class Error extends jspb.Message {
  static readonly displayName = "ImportBehaviorsResponse.Error";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): Error.AsObject {
    return Error.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!Error} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: Error) {
    var f: any;
    var obj: any = {
      index: jspb.Message.getFieldWithDefault(msg, 1, 0),
      message: jspb.Message.getFieldWithDefault(msg, 2, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!Error}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new Error;
    return Error.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!ImportBehaviorsResponse.Error} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!ImportBehaviorsResponse.Error}
   */
  static deserializeBinaryFromReader(msg: Error, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {number} */ (reader.readInt32());
        msg.setIndex(value1);
        break;
      case 2:
        var value2 = /** @type {string} */ (reader.readString());
        msg.setMessage(value2);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!ImportBehaviorsResponse.Error} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: ImportBehaviorsResponse.Error, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getIndex();
    if (f !== 0) {
      writer.writeInt32(
        1,
        f
      );
    }
    f = message.getMessage();
    if (f.length > 0) {
      writer.writeString(
        2,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    ImportBehaviorsResponse.Error.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional int32 index = 1;
   * @return {number}
   */
  getIndex(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
  };


  /** @param {number} value */
  setIndex(value: number) {
    jspb.Message.setProto3IntField(this, 1, value);
  }


  /**
   * optional string message = 2;
   * @return {string}
   */
  getMessage(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
  };


  /** @param {string} value */
  setMessage(value: string) {
    jspb.Message.setProto3StringField(this, 2, value);
  }


}
} // namespace ImportBehaviorsResponse
export namespace UpdateBehaviorRequest {
  export interface AsObject {
    behavior?: EditablePbisBehavior.AsObject;
  }
}
export class UpdateBehaviorRequest extends jspb.Message {
  static readonly displayName = "UpdateBehaviorRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): UpdateBehaviorRequest.AsObject {
    return UpdateBehaviorRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!UpdateBehaviorRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: UpdateBehaviorRequest) {
    var f: any;
    var obj: any = {
      behavior: (f = msg.getBehavior()) && EditablePbisBehavior.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!UpdateBehaviorRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new UpdateBehaviorRequest;
    return UpdateBehaviorRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!UpdateBehaviorRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!UpdateBehaviorRequest}
   */
  static deserializeBinaryFromReader(msg: UpdateBehaviorRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new EditablePbisBehavior;
        reader.readMessage(value1,EditablePbisBehavior.deserializeBinaryFromReader);
        msg.setBehavior(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!UpdateBehaviorRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: UpdateBehaviorRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getBehavior();
    if (f != null) {
      writer.writeMessage(
        1,
        f,
        EditablePbisBehavior.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    UpdateBehaviorRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional EditablePbisBehavior behavior = 1;
   * @return {?EditablePbisBehavior}
   */
  getBehavior(): EditablePbisBehavior {
    return /** @type{?EditablePbisBehavior} */ (
      jspb.Message.getWrapperField(this, EditablePbisBehavior, 1));
  }


  /** @param {?EditablePbisBehavior|undefined} value */
  setBehavior(value?: EditablePbisBehavior) {
    jspb.Message.setWrapperField(this, 1, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearBehavior() {
    this.setBehavior(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasBehavior(): boolean {
    return jspb.Message.getField(this, 1) != null;
  }


}
export namespace UpdateBehaviorResponse {
  export interface AsObject {
    behavior?: EditablePbisBehavior.AsObject;
  }
}
export class UpdateBehaviorResponse extends jspb.Message {
  static readonly displayName = "UpdateBehaviorResponse";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): UpdateBehaviorResponse.AsObject {
    return UpdateBehaviorResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!UpdateBehaviorResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: UpdateBehaviorResponse) {
    var f: any;
    var obj: any = {
      behavior: (f = msg.getBehavior()) && EditablePbisBehavior.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!UpdateBehaviorResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new UpdateBehaviorResponse;
    return UpdateBehaviorResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!UpdateBehaviorResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!UpdateBehaviorResponse}
   */
  static deserializeBinaryFromReader(msg: UpdateBehaviorResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new EditablePbisBehavior;
        reader.readMessage(value1,EditablePbisBehavior.deserializeBinaryFromReader);
        msg.setBehavior(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!UpdateBehaviorResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: UpdateBehaviorResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getBehavior();
    if (f != null) {
      writer.writeMessage(
        1,
        f,
        EditablePbisBehavior.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    UpdateBehaviorResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional EditablePbisBehavior behavior = 1;
   * @return {?EditablePbisBehavior}
   */
  getBehavior(): EditablePbisBehavior {
    return /** @type{?EditablePbisBehavior} */ (
      jspb.Message.getWrapperField(this, EditablePbisBehavior, 1));
  }


  /** @param {?EditablePbisBehavior|undefined} value */
  setBehavior(value?: EditablePbisBehavior) {
    jspb.Message.setWrapperField(this, 1, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearBehavior() {
    this.setBehavior(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasBehavior(): boolean {
    return jspb.Message.getField(this, 1) != null;
  }


}
export namespace UpdateConsequenceRequest {
  export interface AsObject {
    consequence?: EditableConsequence.AsObject;
  }
}
export class UpdateConsequenceRequest extends jspb.Message {
  static readonly displayName = "UpdateConsequenceRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): UpdateConsequenceRequest.AsObject {
    return UpdateConsequenceRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!UpdateConsequenceRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: UpdateConsequenceRequest) {
    var f: any;
    var obj: any = {
      consequence: (f = msg.getConsequence()) && EditableConsequence.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!UpdateConsequenceRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new UpdateConsequenceRequest;
    return UpdateConsequenceRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!UpdateConsequenceRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!UpdateConsequenceRequest}
   */
  static deserializeBinaryFromReader(msg: UpdateConsequenceRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new EditableConsequence;
        reader.readMessage(value1,EditableConsequence.deserializeBinaryFromReader);
        msg.setConsequence(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!UpdateConsequenceRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: UpdateConsequenceRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getConsequence();
    if (f != null) {
      writer.writeMessage(
        1,
        f,
        EditableConsequence.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    UpdateConsequenceRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional EditableConsequence consequence = 1;
   * @return {?EditableConsequence}
   */
  getConsequence(): EditableConsequence {
    return /** @type{?EditableConsequence} */ (
      jspb.Message.getWrapperField(this, EditableConsequence, 1));
  }


  /** @param {?EditableConsequence|undefined} value */
  setConsequence(value?: EditableConsequence) {
    jspb.Message.setWrapperField(this, 1, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearConsequence() {
    this.setConsequence(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasConsequence(): boolean {
    return jspb.Message.getField(this, 1) != null;
  }


}
export namespace UpdateConsequenceResponse {
  export interface AsObject {
    consequence?: EditableConsequence.AsObject;
  }
}
export class UpdateConsequenceResponse extends jspb.Message {
  static readonly displayName = "UpdateConsequenceResponse";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): UpdateConsequenceResponse.AsObject {
    return UpdateConsequenceResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!UpdateConsequenceResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: UpdateConsequenceResponse) {
    var f: any;
    var obj: any = {
      consequence: (f = msg.getConsequence()) && EditableConsequence.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!UpdateConsequenceResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new UpdateConsequenceResponse;
    return UpdateConsequenceResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!UpdateConsequenceResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!UpdateConsequenceResponse}
   */
  static deserializeBinaryFromReader(msg: UpdateConsequenceResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new EditableConsequence;
        reader.readMessage(value1,EditableConsequence.deserializeBinaryFromReader);
        msg.setConsequence(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!UpdateConsequenceResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: UpdateConsequenceResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getConsequence();
    if (f != null) {
      writer.writeMessage(
        1,
        f,
        EditableConsequence.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    UpdateConsequenceResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional EditableConsequence consequence = 1;
   * @return {?EditableConsequence}
   */
  getConsequence(): EditableConsequence {
    return /** @type{?EditableConsequence} */ (
      jspb.Message.getWrapperField(this, EditableConsequence, 1));
  }


  /** @param {?EditableConsequence|undefined} value */
  setConsequence(value?: EditableConsequence) {
    jspb.Message.setWrapperField(this, 1, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearConsequence() {
    this.setConsequence(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasConsequence(): boolean {
    return jspb.Message.getField(this, 1) != null;
  }


}
