export enum USER_PREFERENCES_FIELDS {
  TITLE = 'My Account',
  CHANGE_PHOTO = 'Change photo',
  CHANGE_PASSWORD = 'Change Password',
  UPDATE_PASSWORD = 'Update password',
  CURRENT_PASSWORD_LABEL = 'Current password',
  NEW_PASSWORD_LABEL = 'New password',
  CONFIRM_PASSWORD_LABEL = 'Confirm password',
  DELETE_ACCOUNT = 'Permanently delete your account',
  DELETE_BUTTON = 'Delete Account',
}

export const USER_PREFERENCES_PASSWORD_REQUIREMENTS = {
  HEADER: 'Password must contain:',
  ERROR: {
    REQUIREMENT: 'Password must meet all requirements',
    INVALID: 'Incorrect password',
    MISMATCH: 'Passwords do not match',
  },
  REQUIREMENTS: [
    'at least 1 number',
    'at least 1 special character',
    'minimum of 8 characters',
  ],
};
