import { day } from 'libs/day';
import { date_pb } from 'libs/generated-grpc-ts';

export function assertDateMessageComplete(dateMsg: date_pb.Date) {
  const year = dateMsg.getYear();

  if (year < 1 || year > 9999) {
    throw new Error('Date message: Invalid year [0-9999]');
  }

  const month = dateMsg.getMonth();

  if (month < 1 || month > 12) {
    throw new Error('Date message: Invalid month [1-12]');
  }

  const day = dateMsg.getDay();

  if (day < 1 || day > 31) {
    throw new Error('Date message: Invalid day [1-31]');
  }
}

export function assertDateTimeMessageComplete(dateTimeMsg: date_pb.DateTime) {
  assertDateMessageComplete(dateTimeMsg);

  const seconds = dateTimeMsg.getSeconds();

  if (seconds < 1 || seconds > 86400) {
    throw new Error('Date time message: Invalid seconds [1-86400]');
  }
}

export function dateMessageToDateObject(dateMsg: date_pb.Date): Date {
  return new Date(
    Date.UTC(dateMsg.getYear(), dateMsg.getMonth() - 1, dateMsg.getDay()),
  );
}

export function dateTimeMessageToDateObject(dateTimeMsg: date_pb.DateTime) {
  let utcDate = Date.UTC(
    dateTimeMsg.getYear(),
    dateTimeMsg.getMonth() - 1,
    dateTimeMsg.getDay(),
  );

  utcDate += (dateTimeMsg.getSeconds() - 1) * 1000;

  return new Date(utcDate);
}

/**
 *
 * @param dateTimeMsg - DateTime protobuf object.
 * @param tz - (optional) set date/time in given timezone.
 * @returns
 */
export function dateTimeMessageToDayjsObject(
  dateTimeMsg: date_pb.DateTime,
  tz?: string,
) {
  // @ts-ignore
  let dateTime = day({
    year: dateTimeMsg.getYear(),
    month: dateTimeMsg.getMonth() - 1,
    date: dateTimeMsg.getDay(),
    seconds: dateTimeMsg.getSeconds(),
  });

  if (tz) {
    // @ts-ignore
    dateTime = dateTime.tz(tz, true);
  }
  return dateTime;
}

/**
 * Create a new date object where the date object is faked
 * to set the utc time to the expected local time for storing
 * the date object in the local time.
 *
 * @param date
 * @param timezone
 */
export function tzAdjustDateToStoreInLocalTime(date: Date, timezone: string) {
  // @ts-ignore
  const obj = day(date).tz(timezone);
  const tzAdjustedDate = new Date(
    Date.UTC(
      obj.year(),
      obj.month(),
      obj.date(),
      obj.hour(),
      obj.minute(),
      obj.second(),
    ),
  );

  return tzAdjustedDate;
}

export function dateObjectToDateMessage(date: Date) {
  const dateMsg = new date_pb.Date();
  dateMsg.setYear(date.getUTCFullYear());
  dateMsg.setMonth(date.getUTCMonth() + 1);
  dateMsg.setDay(date.getUTCDate());
  return dateMsg;
}

export function dateObjectToDateTimeMessage(date: Date) {
  const dateTimeMsg = new date_pb.DateTime();
  dateTimeMsg.setYear(date.getUTCFullYear());
  dateTimeMsg.setMonth(date.getUTCMonth() + 1);
  dateTimeMsg.setDay(date.getUTCDate());

  const seconds =
    date.getUTCHours() * 3600 +
    date.getUTCMinutes() * 60 +
    date.getUTCSeconds();

  dateTimeMsg.setSeconds(seconds);

  return dateTimeMsg;
}

export function dateTimeObjectToDateObject(
  dateTime: date_pb.DateTime.AsObject,
) {
  const date = new Date(
    Date.UTC(dateTime.year, dateTime.month - 1, dateTime.day),
  );

  const seconds = dateTime.seconds;

  return new Date(date.getTime() + (seconds + 1) * 1000);
}

export function dayJsObjectToDateTimeMsg(date: day.Dayjs) {
  const dateTimeMsg = new date_pb.DateTime();
  dateTimeMsg.setYear(date.year());
  dateTimeMsg.setMonth(date.month() + 1);
  dateTimeMsg.setDay(date.date());
  dateTimeMsg.setSeconds(
    date.hour() * 3600 + date.minute() * 60 + date.second(),
  );
  return dateTimeMsg;
}
