import { Validators } from '@angular/forms';

import { PointReward } from 'libs/domain';

export enum PmRewardsEditMessages {
  MODAL_TITLE_NEW = 'Create Reward',
  MODAL_TITLE_EXISTING = 'Edit Reward',

  BUTTON_LABEL_CREATE = 'Create',
  BUTTON_LABEL_SAVE = 'Save',
  BUTTON_LABEL_DELETE = 'Delete',
  BUTTON_LABEL_CANCEL = 'Cancel',

  FIELD_LABEL_NAME = 'Reward name',
  FIELD_LABEL_UUID = 'Unique ID',
  FIELD_LABEL_DESCRIPTION = 'Description (optional)',
  FIELD_LABEL_POINT_COST = 'Point cost',

  DELETE_CONFIRMATION_NEW = 'Are you sure you want to discard this reward type?',
  DELETE_CONFIRMATION_EXISTING = 'Are you sure you want to delete this reward type?',
}

export enum PmRewardsEditFormFields {
  NAME = 'name',
  UUID = 'uuid',
  DESCRIPTION = 'description',
  POINT_COST = 'pointCost',
}

export const PM_REWARDS_FORMGROUP = {
  [PmRewardsEditFormFields.NAME]: [
    '',
    [Validators.required, Validators.minLength(1), Validators.maxLength(55)],
  ],
  [PmRewardsEditFormFields.DESCRIPTION]: [
    '',
    [Validators.minLength(1), Validators.maxLength(500)],
  ],
  [PmRewardsEditFormFields.POINT_COST]: [
    0,
    [Validators.required, Validators.min(0)],
  ],
};

export const PM_DEFAULT_REWARD_TYPE = {
  active: false,
} as PointReward;
