import { gateway } from 'libs/generated-grpc-web';

export function isPollClosed(
  shortCard: gateway.content_views_pb.ShortPollCardView.AsObject,
) {
  if (shortCard.closeTimestamp) {
    const now = Date.now();
    const closeDate = shortCard.closeTimestamp;

    if (now >= closeDate) {
      return true;
    }
  }

  return shortCard.pollClosed;
}

export function pollLayoutName(
  pollLayout: gateway.content_views_pb.PollLayout,
) {
  switch (pollLayout) {
    case gateway.content_views_pb.PollLayout.PL_GRID:
      return 'grid';
    case gateway.content_views_pb.PollLayout.PL_LIST:
      return 'list';
  }
  return 'unknown';
}
