import {
  BmReportsFilters,
  CheckinManagerReportFilters,
  FtmReportFilters,
  HpmReportsFilters,
} from 'libs/domain';
import { ReportTypes } from 'libs/domain';
import {
  CheckinReportType,
  HallPassReportType,
  PbisReportType,
  ScheduledReport,
  ScheduledReportFilters,
} from 'libs/domain';
import { FlexReportType } from 'libs/domain';
import { $enum } from 'ts-enum-util';

import { FtmReportFilterForService } from '@modules/flex-time-manager/components/ftm-reports/constants';

// Scheduled report types is just a subset of ReportTypes since we dont currently support all of them
// Adding to this list will also require you update the following items in constants mm-scheduled-reports.constants.ts
// 1.REPORT_TYPE_OPTIONS
// 2.getMapperConfig
// 3.Finally build out your filters component eg. sr-hall-pass-filters.component.ts
// 4. Update mm-sr-reports-edit.component.html filters section (#reportFilters) support your your new sub filters component
export enum ScheduledReportType {
  PBIS_HISTORY = ReportTypes.PBIS_HISTORY,
  PBIS_CONS_HISTORY = ReportTypes.PBIS_CONS_HISTORY,
  CHECKIN_HISTORY = ReportTypes.CHECKIN_HISTORY,
  HALL_PASS_HISTORY = ReportTypes.HALL_PASS_HISTORY,
  HALL_PASS_OVERDUE = ReportTypes.HALL_PASS_OVERDUE,
  HALL_PASS_STAFF = ReportTypes.HALL_PASS_STAFF,
  HALL_PASS_STUDENT = ReportTypes.HALL_PASS_STUDENT,
  HALL_PASS_TYPES = ReportTypes.HALL_PASS_TYPES,
  PBIS_OVERDUE_CONS = ReportTypes.PBIS_CONS_OVERDUE,
  FLEX_PERIOD_UNREGISTERED = ReportTypes.FLEX_PERIOD_UNREGISTERED,
  FLEX_PERIOD_REGISTERED = ReportTypes.FLEX_PERIOD_REGISTERED,
  FLEX_ACTIVITIES = ReportTypes.FLEX_ACTIVITIES,
  FLEX_STUDENTS = ReportTypes.FLEX_STUDENTS,
  FLEX_STAFF = ReportTypes.FLEX_STAFF,
}

export type ClientSupportedScheduledReportFilter =
  | {
      reportType: HallPassReportType;
      filters: HpmReportsFilters;
    }
  | {
      reportType: CheckinReportType;
      filters: CheckinManagerReportFilters;
    }
  | {
      reportType: PbisReportType;
      filters: BmReportsFilters;
    }
  | {
      reportType: FlexReportType;
      filters: FtmReportFilters;
    };

export const SUPPORTED_SCHEDULE_TYPES = $enum(ScheduledReportType).getValues();

export type SupportedDashboardFilterType =
  | BmReportsFilters
  | CheckinManagerReportFilters
  | HpmReportsFilters
  | FtmReportFilterForService;

export interface MmSrReportsEditData {
  id?: number;
  prepopulatedFields?: ScheduledReportFilters;
}

export type MmSrReportsEditResponse = {
  created?: ScheduledReport;
  updated?: ScheduledReport;
  deleted?: number;
};
