import { Injectable } from '@angular/core';

import { ChallengeColor, ChallengePrivacy, ChallengeType } from 'libs/domain';
import { MingaRoleType } from 'libs/domain';
import {
  challenge_ng_grpc_pb,
  common_pb,
  gateway,
} from 'libs/generated-grpc-web';
import { $enum } from 'ts-enum-util';

import { IChallengeDraft } from '@app/src/app/modules/challenges/types';
import { dateTimeMessageToDateObject } from '@app/src/app/util/date';

@Injectable({ providedIn: 'root' })
export class ChallengeContentInfoService {
  constructor(
    private challengeProto: challenge_ng_grpc_pb.Challenge,
    private bannerService: gateway.banner_ng_grpc_pb.BannerLibrary,
  ) {}

  async getContentInfo(contentHash: string): Promise<IChallengeDraft> {
    const request = new common_pb.ContentInfoQuery();
    request.setContentHash(contentHash);
    return this._makeContentInfoRequest(request);
  }

  async getContentInfoByContext(contextHash: string): Promise<IChallengeDraft> {
    const request = new common_pb.ContentInfoQuery();
    request.setContentContextHash(contextHash);
    return this._makeContentInfoRequest(request);
  }

  private async _makeContentInfoRequest(
    request: common_pb.ContentInfoQuery,
  ): Promise<IChallengeDraft> {
    const response = await this.challengeProto.getInfo(request);
    const title = response.getTitle();
    const body = response.getBody();
    const dueDate = dateTimeMessageToDateObject(response.getDueDate());
    const privacy = $enum(ChallengePrivacy).asValueOrThrow(
      response.getPrivacy(),
    );
    const commentsAllowed = response.getCommentsAllowed();
    const uploadedImage = response.getBannerAsset();
    const type = $enum(ChallengeType).asValueOrThrow(response.getType());
    const color = $enum(ChallengeColor).asValueOrThrow(response.getColor());
    const points = response.getPointValue();
    const uploadedBadge = response.getBadgeAsset();
    const allowedRoles = response.getAllowedRolesList().map(role => {
      return $enum(MingaRoleType).asValueOrThrow(role);
    });

    let banner: gateway.banner_pb.Banner.AsObject | undefined;
    if (response.getBannerHash()) {
      const bannerGet = new gateway.banner_pb.BannerLibraryGet();
      bannerGet.setBannerHash(response.getBannerHash());
      banner = (await this.bannerService.get(bannerGet)).toObject();
    }
    let badge: gateway.banner_pb.Banner.AsObject | undefined;
    if (response.getBadgeHash()) {
      const badgeGet = new gateway.banner_pb.BannerLibraryGet();
      badgeGet.setBannerHash(response.getBadgeHash());
      badge = (await this.bannerService.get(badgeGet)).toObject();
    }
    const requiresApproval = response.getRequiresApproval();

    return {
      title,
      body,
      dueDate,
      privacy,
      commentsAllowed,
      uploadedImage,
      banner,
      type,
      contentHash: response.getContentHash() || '',
      contextHash: response.getContextHash() || '',
      color,
      points,
      uploadedBadge,
      badge,
      allowedRoles,
      requiresApproval,
    };
  }
}
