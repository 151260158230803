import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatRippleModule } from '@angular/material/core';

import { TranslateModule } from '@ngx-translate/core';

import { MgButtonModule } from '@app/src/app/button';
import { MgEmptyStateModule } from '@app/src/app/components/EmptyState';
import { MgStreamScrollerModule } from '@app/src/app/components/MgStreamScroller';
import { PointsHistoryItemModule } from '@app/src/app/components/PointsHistoryItem';
import { MgContentLinkModule } from '@app/src/app/content-link';
import { MgAvatarModule } from '@app/src/app/elements/MgAvatar';
import { MgDateSeparatorModule } from '@app/src/app/elements/MgDateSeparator';
import { MgPersonMinimalModule } from '@app/src/app/elements/MgPersonMinimal';
import { MgIconModule } from '@app/src/app/icon';
import { PermissionsModule } from '@app/src/app/permissions';
import { MgPipesModule } from '@app/src/app/pipes';
import { MgSpinnerModule } from '@app/src/app/spinner';

import { ProfilePointsComponent } from './ProfilePoints.component';

@NgModule({
  imports: [
    // Minga dependencies
    MgStreamScrollerModule,
    MgIconModule,
    MgPipesModule,
    MgContentLinkModule,
    PermissionsModule,
    MgEmptyStateModule,
    MgButtonModule,
    MgAvatarModule,
    MgPersonMinimalModule,
    MgSpinnerModule,
    PointsHistoryItemModule,
    MgEmptyStateModule,
    MgStreamScrollerModule,
    MgDateSeparatorModule,

    // External dependencies
    CommonModule,
    TranslateModule,
    MatRippleModule,
    FlexLayoutModule,
  ],
  declarations: [ProfilePointsComponent],
  exports: [ProfilePointsComponent],
})
export class ProfilePointsModule {}
