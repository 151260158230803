import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';
import { gateway } from 'libs/generated-grpc-web';
import { Observable, Subscription } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';

import { FirebaseMessaging } from '@app/src/app/firebase/messaging';
import { NotificationActions } from '@app/src/app/store/Notifications/actions/notifications.actions';
import { NotificationStoreModel } from '@app/src/app/store/Notifications/model';

/**
 * Supplies an observable to listen for incoming notifications
 */
@Injectable({ providedIn: 'root' })
export class NotificationListener {
  /**
   * Long standing observable for incoming notifications
   */
  readonly notificationRecevied$: Observable<gateway.notification_pb.Notification>;

  /**
   * Long standing observable for clicked notifications.
   * @NOTE This is not just _any_ notificiations. This is only the notifications
   *       that specifically have the notification data parameter.
   */
  readonly notificationClicked$: Observable<gateway.notification_pb.Notification>;

  constructor(
    messaging: FirebaseMessaging,
    public notificationManager: gateway.notification_ng_grpc_pb.NotificationManager,
    private store: Store<any>,
  ) {
    this.notificationRecevied$ = messaging.messageReceived$.pipe(
      map(msg => msg.data?.notificationMessageB64),
      filter(msgData => typeof msgData !== 'undefined'),
      map(base64Message =>
        gateway.notification_pb.Notification.deserializeBinary(base64Message),
      ),
    );

    this.notificationClicked$ = messaging.notificationClicked$.pipe(
      map(msg => msg.data?.notificationMessageB64),
      filter(msgData => typeof msgData !== 'undefined'),
      map(base64Message =>
        gateway.notification_pb.Notification.deserializeBinary(base64Message),
      ),
    );

    // handle any real time notifications, to put them in store.
    this.notificationRecevied$.subscribe(msg => {
      this._dispatchNotification(msg);
    });
  }

  /**
   * Load unviewed notifications that were sent while user was gone
   * and load the store with them.
   */
  loadUnviewedNotifications() {
    const request =
      new gateway.notification_pb.LoadUnviewedNotificationsRequest();
    this.notificationManager
      .loadUnviewedNotifications(request)
      .then(response => {
        const notifications = response.getNotificationsList();
        this.dispatchNotifications(notifications);
      });
  }

  dispatchNotifications(notifications: gateway.notification_pb.Notification[]) {
    // we need to dispatch the most recent first so if there's multiple notifications the stacking order is correct
    notifications.sort(
      (a, b) => a.toObject().timestamp - b.toObject().timestamp,
    );

    for (const msg of notifications) {
      this._dispatchNotification(msg);
    }
  }

  /**
   * Send notifications to the ngrx store.
   * @param msg
   */
  private _dispatchNotification(msg: gateway.notification_pb.Notification) {
    const notificationModel: NotificationStoreModel = {
      id: msg.getNotificationId(),
      notificationType: msg.getNotificationType(),
      message: msg.toObject(),
    };
    if (msg.hasActionGroupContent()) {
      const actionData = msg.getActionGroupContent();
      notificationModel.groupHash = actionData.getGroupHash();
    }

    this.store.dispatch(
      new NotificationActions.AddNotificationAction(notificationModel),
    );
  }
}
