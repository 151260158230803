import { MingaPermission, MingaRoleType } from 'libs/domain';
import { DisplayNameFormat, RoleFields } from 'libs/util';

import { RoleCategory, UserRole } from './UserRole';

export class ReadOnlyRole extends UserRole {
  readonly roleType = MingaRoleType.READ_ONLY;
  readonly name = 'Read-Only';
  readonly displayNameFormat = DisplayNameFormat.FIRST_LAST;
  readonly iconUrl = 'assets/roles/read-only.svg';
  readonly iconColor = '#c53635';
  readonly capabilitiesDescription = ``;
  readonly feedPermittable = false;
  readonly galleryPermittable = false;
  readonly roleFields: RoleFields = RoleFields.GRAD_STUDENT;
  readonly groupFeedPermittable = false;
  readonly programManagePermittable = false;
  readonly videoUploadPermittable = false;
  readonly groupCreatePermittable = false;
  readonly admin = false;
  readonly commentPermittable = false;
  readonly requiredFeatures = [];
  readonly sendToMailChimp = false;
  readonly IDRole = RoleCategory.STUDENT;

  readonly permissions = [
    MingaPermission.ACCOUNT_PROFILE_CHANGE,
    MingaPermission.CONTENT_COMMENTS_VIEW,
    MingaPermission.CONTENT_LIKES_LIKE,
    MingaPermission.STUDENT_TOOLS,
    MingaPermission.FLEX_TIME_VIEW_MY_ACTIVITY,
    MingaPermission.STUDENT_SCHEDULE_VIEW,
    MingaPermission.SELF_CHECKIN,
  ];
  readonly overridablePermissions = [];
}
