import { ReportTypes } from 'libs/domain';
import {
  ScheduledReportDeliveryType,
  ScheduledReportMinimal,
} from 'libs/domain';
import { stats_report_pb as proto } from 'libs/generated-grpc-ts';
import { DayOfWeekEnum } from 'libs/util';
import { $enum } from 'ts-enum-util';

import { dateObjectToDateTimeMessage, dateTimeMessageToDateObject } from '../';

export const toProto = (
  report: ScheduledReportMinimal,
): proto.ScheduledReportMinimal => {
  const msg = new proto.ScheduledReportMinimal();
  if (report.id) msg.setId(report.id);
  if (report.name) msg.setName(report.name);
  if (report.reportType) msg.setReportType(report.reportType);
  if (report.frequency) msg.setFrequency(report.frequency);
  if (report.time) msg.setTime(report.time);
  if (report.deliveryType?.length) msg.setDeliveryTypeList(report.deliveryType);
  if (report.createdBy) msg.setCreatedBy(report.createdBy);
  if (report.day) msg.setDay(report.day);
  if (report.createdById) msg.setCreatedById(report.createdById);
  if (report.createdAt)
    msg.setCreatedAt(dateObjectToDateTimeMessage(report.createdAt));
  if (typeof report.active === 'boolean') msg.setActive(report.active);
  return msg;
};

export const fromProto = (
  msg: proto.ScheduledReportMinimal,
): ScheduledReportMinimal => {
  const reportType = $enum(ReportTypes).asValueOrThrow(msg.getReportType());
  if (reportType === ReportTypes.EVENT_PEOPLE)
    throw new Error('Invalid report type for scheduled reports');
  const day = msg.getDay();
  return {
    id: msg.getId(),
    name: msg.getName(),
    reportType,
    frequency: msg.getFrequency(),
    time: msg.getTime(),
    deliveryType: msg.getDeliveryTypeList() as ScheduledReportDeliveryType[],
    createdBy: msg.getCreatedBy(),
    day: day ? $enum(DayOfWeekEnum).asValueOrThrow(msg.getDay()) : undefined,
    active: msg.getActive(),
    createdById: msg.getCreatedById(),
    createdAt: dateTimeMessageToDateObject(msg.getCreatedAt()),
  };
};
