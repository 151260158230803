import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  Output,
} from '@angular/core';

import { ReplaySubject } from 'rxjs';

import { MingaAppColor } from '@shared/constants';

import { GenericTagColor, GenericTagSize, GenericTagType } from './types';

/**
 * @component Generic Tag
 */
@Component({
  selector: 'mg-tag',
  templateUrl: './tag.component.html',
  styleUrls: ['./tag.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GenericTagComponent implements OnDestroy {
  /** General Subjects */
  private readonly _destroyedSubject = new ReplaySubject<void>(1);

  /** Inputs */
  @Input() type: GenericTagType = 'tag';
  @Input() addOnLeft: string;
  @Input() color: GenericTagColor = 'blue';
  @Input() size: GenericTagSize = 'large';
  @Input() iconLeft: string;
  @Input() iconLeftColor: MingaAppColor = 'primary';
  /**
   * Max number of characters before truncating
   */
  @Input() maxChars: number;
  @Input() buttonIconName = 'chevron_right';

  /** Outputs **/
  @Output() pressed = new EventEmitter<void>();

  @HostListener('click') onClick() {
    this.pressed.emit();
  }

  get classes() {
    return {
      'generic-tag': true,
      'generic-button': this.type === 'button',
      'select-tag': this.type === 'select-tag',
      'removeable-select-tag': this.type === 'removeable-select-tag',
      [`color-${this.color}`]: true,
      [this.size]: true,
      'has-max-width': this.maxChars && this.maxChars > 0,
      'has-icon-left': !!this.iconLeft,
    };
  }

  get iconColor() {
    switch (this.color) {
      case 'blue':
        return '#0070e0';
      case 'yellow':
        return '#ce8001';
      default:
        return '#787878';
    }
  }
  /** Component Constructor */
  constructor() {}

  ngOnDestroy(): void {
    this._destroyedSubject.next();
    this._destroyedSubject.complete();
  }
}
