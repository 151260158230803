import { Pipe, PipeTransform } from '@angular/core';

import * as dayjs from 'dayjs';
import { ScheduledReportFrequency } from 'libs/domain';
import { DayOfWeekEnum } from 'libs/util';

const dayString = {
  [DayOfWeekEnum.SUN]: 0,
  [DayOfWeekEnum.MON]: 1,
  [DayOfWeekEnum.TUE]: 2,
  [DayOfWeekEnum.WED]: 3,
  [DayOfWeekEnum.THU]: 4,
  [DayOfWeekEnum.FRI]: 5,
  [DayOfWeekEnum.SAT]: 6,
};

@Pipe({ name: 'frequencyHint' })
export class FrequencyHintPipe implements PipeTransform {
  transform(
    frequency: ScheduledReportFrequency,
    day: DayOfWeekEnum,
    time: string,
  ): string {
    if (!time || (frequency === ScheduledReportFrequency.Weekly && !day)) {
      return '';
    }

    const formmattedTime = dayjs(time, 'HH:mm').format('h:mm a');
    const endDate = dayjs().day(dayString[day]);
    const startDate = endDate.subtract(6, 'day');

    return frequency === ScheduledReportFrequency.Weekly
      ? `The reports will include data from ${startDate.format(
          'dddd',
        )} at 0am till next ${endDate.format('dddd')} at ${formmattedTime}`
      : `The reports will include data from 0am till ${formmattedTime}`;
  }
}
