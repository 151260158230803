import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { MgIconModule } from '@app/src/app/icon';

import { ManagerOverlayModule } from '@shared/components/manager-overlay';

import { BehaviorManagerComponent } from './behavior-manager.component';

@NgModule({
  imports: [
    // Minga dependencies
    MgIconModule,
    ManagerOverlayModule,

    // External dependencies
    CommonModule,
    RouterModule,
  ],
  declarations: [BehaviorManagerComponent],
  exports: [BehaviorManagerComponent],
})
export class BehaviorManagerModule {}
