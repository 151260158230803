export enum ReportStat {
  ALL = 0,
  UGC = 1,
  AI_BLOCKED = 2,
  AI_PASSED = 3,
  DELETED = 4,
}

export namespace ReportStat {
  const statKeyStrings = [
    'ALL',
    'UGC',
    'AI_BLOCKED',
    'AI_PASSED',
    'DELETED',
  ];
  /**
   * Returns a human readable ReportStat name, matches keys for enum
   */
  export function toString(reportStat: ReportStat): string {
    switch(reportStat) {
      // Return the ReportStat key string
      default: return statKeyStrings[reportStat];
    }
  }

  export function getKeys() {
    return statKeyStrings;
  }

  export function showZeroPercentage(reportStat: ReportStat) {
    return reportStat != ReportStat.AI_BLOCKED &&
      reportStat != ReportStat.AI_PASSED;
  }
}