import { Location } from '@angular/common';
import { Injectable, OnDestroy } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import * as day from 'dayjs';
import { MingaPermission } from 'libs/domain';
import { PbisCategory } from 'libs/shared';
import { mingaSettingTypes } from 'libs/util';
import { BehaviorSubject, Observable, ReplaySubject, from } from 'rxjs';
import {
  filter,
  map,
  shareReplay,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs/operators';

import { AuthInfoService } from '@app/src/app/minimal/services/AuthInfo';
import { PermissionsService } from '@app/src/app/permissions';
import { MingaSettingsService } from '@app/src/app/store/Minga/services';

import { ImportBehaviorsDialogComponent } from '@modules/behavior-manager/components/bm-dashboard/import-behaviors-dialog/import-behaviors-dialog.component';
import {
  PeopleSelectorService,
  PsBehaviorsData,
  PsCheckinData,
  PsFlexTimeActivitiesData,
  PsHallPassData,
} from '@modules/people-selector';

import { FormSelectOption } from '@shared/components/form';
import { ModalOverlayService } from '@shared/components/modal-overlay';
import { SystemAlertSnackBarService } from '@shared/components/system-alert-snackbar';
import { ViewIdService } from '@shared/components/view-id/services/view-id.service';
import { getAppColor } from '@shared/constants';
import { CheckinService } from '@shared/services/checkin';
import {
  FlexTimePeriodService,
  FlexTimePermissionsService,
} from '@shared/services/flex-time';
import { HallPassService } from '@shared/services/hall-pass';
import { CreateHallpassService } from '@shared/services/hall-pass/create-hallpass.service';
import { HallPassActionsService } from '@shared/services/hall-pass/hallpass-actions.service';
import { PbisService } from '@shared/services/pbis';

import { SELECTION_ASSIGNER_MODAL_CONFIG } from '../constants';
import {
  CarouselTileData,
  SaAssignOverlayConfig,
  SaFormMap,
  SaSelectorTypes,
} from '../types';
import { hasFilterField, mapFlexActivitesToCarouselData } from '../utils';

@Injectable()
export class SelectionAssignerService implements OnDestroy {
  /** Loading */
  private _isLoadingSubj = new BehaviorSubject<boolean>(false);
  public isLoading$ = this._isLoadingSubj.asObservable();

  /** Behavior Subjects */
  private _activeSelectorSubject = new BehaviorSubject<SaSelectorTypes>(
    undefined,
  );
  private _carouselDataSubject = new BehaviorSubject<CarouselTileData[]>([]);
  private _filterableCarouselDataSubject = new BehaviorSubject<
    FormSelectOption<CarouselTileData[]>[]
  >([]);
  private _destroyedSubject = new ReplaySubject<void>(1);

  /** Form Controls */
  private _typeControl = new UntypedFormControl(undefined, Validators.required);
  private _isValid = false;

  /** Const */
  private _payload: any;
  private _isFlexAdmin = this._flexTimePermissionsService.isFlexTimeAdmin();

  /** Other */
  private _importBehaviors = false;

  /** Service Constructor */
  constructor(
    private _router: Router,
    private _location: Location,
    private _systemSnack: SystemAlertSnackBarService,
    private _peopleSelector: PeopleSelectorService,
    private _hallPassService: HallPassService,
    private _createHpService: CreateHallpassService,
    private _pbisService: PbisService,
    private _checkinService: CheckinService,
    private _flexTimePeriodsService: FlexTimePeriodService,
    private _flexTimePermissionsService: FlexTimePermissionsService,
    private _auth: AuthInfoService,
    private _settingService: MingaSettingsService,
    private _permissions: PermissionsService,
    private _modalOverlayService: ModalOverlayService,
    private _hallPassActions: HallPassActionsService,
    private _viewId: ViewIdService,
  ) {
    this.activeSelector$
      .pipe(
        takeUntil(this._destroyedSubject),
        filter(selector => !!selector && !hasFilterField(selector)),
        tap(() => this._isLoadingSubj.next(true)),
        switchMap(selector => this._fetchCarouselData(selector)),
      )
      .subscribe(dataList => {
        this._carouselDataSubject.next(dataList);
        this._isLoadingSubj.next(false);
      });

    this.activeSelector$
      .pipe(
        takeUntil(this._destroyedSubject),
        filter(selector => !!selector && hasFilterField(selector)),
        tap(() => this._isLoadingSubj.next(true)),
        switchMap(selector => this._fetchFilterableCarouselData(selector)),
      )
      .subscribe(optionList => {
        this._filterableCarouselDataSubject.next(optionList);
        this._isLoadingSubj.next(false);
      });
  }

  get typeFormControl(): UntypedFormControl {
    return this._typeControl;
  }

  get activeSelectorSubject(): BehaviorSubject<SaSelectorTypes> {
    return this._activeSelectorSubject;
  }

  get activeSelector$(): Observable<SaSelectorTypes> {
    return this._activeSelectorSubject.asObservable();
  }

  get carouselDataSubject(): BehaviorSubject<CarouselTileData[]> {
    return this._carouselDataSubject;
  }

  get carouselData$(): Observable<CarouselTileData[]> {
    return this._carouselDataSubject.asObservable();
  }

  get filterableCarouselData$(): Observable<
    FormSelectOption<CarouselTileData[]>[]
  > {
    return this._filterableCarouselDataSubject
      .asObservable()
      .pipe(shareReplay(1));
  }

  get isValid() {
    return this._isValid;
  }

  ngOnDestroy(): void {
    this._destroyedSubject.next();
    this._destroyedSubject.complete();
  }

  public async submit() {
    if (!this.isValid) return;

    if (this._payload?.isSelfGranted) {
      await this._submitSelfGranted();
      return;
    }

    this._submitPeopleSelector();
  }

  public setPayload<K extends SaSelectorTypes, T extends typeof SaFormMap[K]>(
    selectorType: K,
    payload: T,
  ) {
    this._payload = payload;
  }

  public getModalConfig(): SaAssignOverlayConfig {
    return SELECTION_ASSIGNER_MODAL_CONFIG[this._activeSelectorSubject.value];
  }

  public setFormValidity(valid: boolean) {
    this._isValid = valid;
  }

  public reset() {
    this._payload = undefined;
    this._typeControl.reset();
    this._carouselDataSubject.next([]);
  }

  public setImportBehaviors(importBehaviors: boolean) {
    this._importBehaviors = importBehaviors;
  }

  private _fetchCarouselData(
    activeSelector: SaSelectorTypes,
  ): Observable<CarouselTileData[]> {
    switch (activeSelector) {
      case 'hall-pass':
        return this._getHPTypes();
      case 'praise':
        return this._getBehaviorTypes('praise');
      case 'guidance':
        return this._getBehaviorTypes('guidance');
      case 'consequence':
        return this._getConsequenceTypes();
      case 'checkin':
        return this._getCheckinReasons();
      default:
        throw new Error('Invalid selector type ' + activeSelector);
    }
  }

  private _fetchFilterableCarouselData(
    activeSelector: SaSelectorTypes,
  ): Observable<FormSelectOption<CarouselTileData[]>[]> {
    switch (activeSelector) {
      case 'flex-activity':
        return from(this._getFlexTimeActivityOptions());
      default:
        throw new Error('Invalid filterable selector type');
    }
  }

  private _getHPTypes(): Observable<CarouselTileData[]> {
    const isStudent = !this._permissions.hasPermission(
      MingaPermission.HALL_PASS_MANAGE,
    );
    return this._hallPassService.getHallPassTypes(true, true).pipe(
      map(types => {
        return types
          .sort((a, b) => {
            if (a.priority < b.priority) return -1;
            if (a.priority > b.priority) return 1;

            return 0;
          })
          .map(type => {
            return {
              type: 'hall-pass',
              id: type.id,
              name: type.name,
              icon: type.bannerHash,
              color: type.color,
              duration: type.defaultHallPassTime,
              requireTeacherApproval: type.requireTeacherApproval,
              canSelfAssign: type.selfIssue,
            } as CarouselTileData;
          })
          .filter((tile): tile is CarouselTileData => {
            if (!isStudent) return true;

            return tile.type === 'hall-pass' && tile.canSelfAssign;
          });
      }),
    );
  }

  private _getBehaviorTypes(
    type: 'praise' | 'guidance',
  ): Observable<CarouselTileData[]> {
    return this._pbisService.getTypes(true, true).pipe(
      map(behaviorTypes => {
        const filteredTypes = behaviorTypes.filter(
          behaviorType =>
            behaviorType.categoryId === PbisCategory[type.toUpperCase()],
        );
        return filteredTypes.map(behaviorType => {
          const defaultColor =
            type === 'praise'
              ? getAppColor('behavior-praise')
              : getAppColor('behavior-consequence');
          return {
            type,
            id: behaviorType.id,
            name: behaviorType.name,
            icon: behaviorType.iconType || type,
            color: behaviorType.iconColor || defaultColor,
            iconNamespace: behaviorType.iconType ? 'minga.behaviors' : null,
          } as CarouselTileData;
        });
      }),
    );
  }

  private _getConsequenceTypes(): Observable<CarouselTileData[]> {
    return this._pbisService.getConsTypes(true, true).pipe(
      map(consequenceTypes => {
        return consequenceTypes.map(consequenceType => {
          const defaultColor =
            consequenceType.categoryId === 0
              ? getAppColor('behavior-praise')
              : getAppColor('behavior-consequence');
          return {
            type: 'consequence',
            id: consequenceType.id,
            name: consequenceType.name,
            icon: consequenceType.iconType || 'consequence',
            color: consequenceType.iconColor || defaultColor,
            canAddNote: consequenceType.addNotes,
            enableDueDate: consequenceType.enableDueDate,
            iconNamespace: consequenceType.iconType ? 'minga.behaviors' : null,
          } as CarouselTileData;
        });
      }),
    );
  }

  private _getCheckinReasons(): Observable<CarouselTileData[]> {
    return this._checkinService.getReasonsObs().pipe(
      map(reasons => {
        return reasons.map(reason => {
          return {
            type: 'checkin',
            id: reason.id,
            name: reason.name,
            icon: reason.icon,
            color: reason.color,
          } as CarouselTileData;
        });
      }),
    );
  }

  public async _getFlexTimeActivityOptions(): Promise<
    FormSelectOption<CarouselTileData[]>[]
  > {
    const periods = await this._flexTimePeriodsService.fetchAll(
      day(),
      day(),
      this._flexTimePermissionsService.isFlexTimeSelfManager(),
    );
    const teacher = !this._isFlexAdmin ? this._auth.authPerson : null;

    return periods.map(period => {
      const filteredActivityInstances = teacher
        ? period.activityInstances.filter(
            activityInstance =>
              activityInstance.flexTimeActivity?.createdByPerson?.hash ===
              teacher?.hash,
          )
        : period.activityInstances;

      return {
        label: period.title,
        value: mapFlexActivitesToCarouselData(filteredActivityInstances),
      };
    });
  }

  private _submitPeopleSelector() {
    switch (this._activeSelectorSubject.value) {
      case 'hall-pass':
        this._peopleSelector.open(
          'Hall Pass',
          'assign',
          {
            data: {
              ...(this._payload as PsHallPassData),
            },
          },
          true,
        );
        break;
      case 'praise':
      case 'guidance':
      case 'consequence':
        if (this._importBehaviors) {
          this._openImportBehaviorDialog();
          break;
        }

        this._peopleSelector.open(
          'Behavior',
          'assign',
          {
            data: {
              ...(this._payload as PsBehaviorsData),
            },
          },
          true,
        );
        break;
      case 'checkin':
        const checkInType = this._typeControl.value;
        this._peopleSelector.open(
          'Check In',
          'add',
          {
            title: `${checkInType?.name} Check in`,
            data: {
              ...(this._payload as PsCheckinData),
            },
          },
          true,
        );
        break;
      case 'flex-activity':
        const { name, ...flexData } = this._payload;
        this._peopleSelector.open(
          'FlexTime Activity',
          'checkin',
          {
            title: name || 'FlexTime Activity',
            pagesBlacklist: ['remove'],
            data: {
              ...(flexData as PsFlexTimeActivitiesData),
            },
          },
          true,
        );
        break;
      default:
        throw new Error('Invalid selector type');
    }
  }

  private async _submitSelfGranted() {
    switch (this._activeSelectorSubject.value) {
      case 'hall-pass':
        await this._createSelfGrantedHallPass();
        break;
      default:
        throw new Error('Invalid selector type');
    }
  }

  private async _createSelfGrantedHallPass() {
    try {
      const { passIds } = await this._createHpService.createHallPass(
        this._payload.isSelfGranted,
        this._payload.typeId,
        [this._auth.authPerson.hash],
        this._payload.duration,
        this._payload.startDate,
        this._payload.teacherAssignedHash,
        this._payload.note,
      );
      if (this._payload.requireTeacherApproval) {
        if (passIds?.length) {
          // this is the student assignment flow so there should be one pass here
          const passId = passIds[0];
          const timeout = parseInt(
            await this._settingService.getSettingValue(
              mingaSettingTypes.PASS_APPROVAL_REQUEST_TIMEOUT_DURATION,
            ),
            10,
          );
          const timeoutMins = isNaN(timeout) ? 2 : timeout;

          const recipients = [
            {
              name: `${this._auth.authPerson.firstName} ${this._auth.authPerson.lastName}`,
              passId,
            },
          ];

          await this._hallPassActions.showPendingCancellationDialog(
            recipients,
            this._payload.typeName,
            this._payload.teacherAssignedName,
            timeoutMins * 60,
            {
              onClose: () => {
                this._location.back();
              },
            },
          );
        }
      } else {
        this._router.navigate(['/']).then(() => {
          this._router.navigate(
            [
              '',
              {
                outlets: {
                  o: [this._viewId.getIdOverlayRoute()],
                },
              },
            ],
            { replaceUrl: true },
          );
        });
      }
    } catch (error) {
      this._systemSnack.open({
        message: 'Error creating hall pass',
        type: 'error',
      });
    }
  }

  private _openImportBehaviorDialog() {
    this._router.navigate(['/']).then(() => {
      this._modalOverlayService.open(ImportBehaviorsDialogComponent, {
        disposeOnNavigation: false,
        data: {
          type: this._activeSelectorSubject.value,
          id: this.typeFormControl.value.id,
        },
      });
    });
  }
}
