import { Injectable } from '@angular/core';

import {
  FlexReportUnregisteredTableData,
  FtmReportFilterType,
} from 'libs/domain';

import { PeopleSelectorService } from '@modules/people-selector';

import { SystemAlertModalService } from '@shared/components/system-alert-modal';
import { SystemAlertSnackBarService } from '@shared/components/system-alert-snackbar';
import { FlexTimeActivityInstanceService } from '@shared/services/flex-time';
import { FlexTimeRegistrationValidationService } from '@shared/services/flex-time/flex-time-registration-validation.service';
import { ReportActionService } from '@shared/services/reports';

import { FtmReportsUnregisteredDatasourceService } from './ftm-reports-unreg-data.service';
import { FtmReportsService } from './ftm-reports.service';

@Injectable()
export class FtmReportActionService extends ReportActionService<FlexReportUnregisteredTableData> {
  constructor(
    public snackbar: SystemAlertSnackBarService,
    public alertModal: SystemAlertModalService,
    private _flexInstanceService: FlexTimeActivityInstanceService,
    private _ftmDataSourceService: FtmReportsUnregisteredDatasourceService,
    private _ftmReportsService: FtmReportsService,
    public _peopleSelector: PeopleSelectorService,
    private _ftRegistrationValidation: FlexTimeRegistrationValidationService,
  ) {
    super(snackbar, alertModal);
  }

  /** Abstract methods */
  public setTotalSelectable(total: number): void {
    this.totalSelectable = total;
  }

  /** Action methods */
  public async assignActivity(): Promise<boolean> {
    if (!this.isSelectionValid()) return;
    const activityInstance =
      await this._flexInstanceService.openActivitySelector({
        selectorType: 'assign',
        startDate: this._ftmReportsService.filter.startDate,
        endDate: this._ftmReportsService.filter.endDate,
      });

    await this._ftRegistrationValidation.assign({
      activityId: activityInstance.id,
      periodId: activityInstance.flexTimePeriodId,
      selectedPeople: this.getSelection(),
    });
    this._clearSelectionAndRefetch();

    return true;
  }

  public async registerToActivity(): Promise<boolean> {
    if (!this.isSelectionValid()) return;
    const activityInstance =
      await this._flexInstanceService.openActivitySelector({
        selectorType: 'assign',
        startDate: this._ftmReportsService.filter.startDate,
        endDate: this._ftmReportsService.filter.endDate,
      });

    await this._ftRegistrationValidation.register({
      activityId: activityInstance.id,
      periodId: activityInstance.flexTimePeriodId,
      selectedPeople: this.getSelection(),
    });
    this._clearSelectionAndRefetch();

    return true;
  }

  public async registerToHomeroom(): Promise<void> {
    if (!this.isSelectionValid()) return;

    const hashes = this.getSelection().map(person => person.hash);
    const periodId =
      this._ftmReportsService.filter[FtmReportFilterType.PERIODS]?.[0];

    if (!periodId) {
      throw new Error('You must select a period to register to homeroom');
    }

    await this._ftRegistrationValidation.registerToHomeroom({
      periodId,
      hashes,
    });

    this._clearSelectionAndRefetch();
  }

  public getFlexTimeSelectionMessage(): string {
    const data = this.getSelection() as FlexReportUnregisteredTableData[];
    return `${data.length} selected`;
  }

  private async _clearSelectionAndRefetch() {
    await this._ftmDataSourceService.fetch(0, 100);
    this.clearSelection();
  }
}
