// GENERATED CODE -- DO NOT EDIT!

import {grpc} from '@improbable-eng/grpc-web';

import * as flex_time_flex_time_activity_pb from '../flex_time/flex_time_activity_pb';
import * as restriction_restriction_pb from '../restriction/restriction_pb';

export class FlexTimeActivity {
  static readonly serviceName = "flex_time.activity.v1.FlexTimeActivity";

  static readonly ValidateAssignment = {
    methodName: "ValidateAssignment",
    service: FlexTimeActivity,
    requestStream: false,
    responseStream: false,
    requestType: flex_time_flex_time_activity_pb.ValidateAssignmentRequest,
    responseType: flex_time_flex_time_activity_pb.ValidateAssignmentResponse,
  };

}

export class FlexTimeActivityClient {
  validateAssignment() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
}
