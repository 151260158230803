// source: stats_report/stats_report.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

import * as jspb from 'google-protobuf';
import * as hall_pass_hall_pass_pb from '../hall_pass/hall_pass_pb';
import * as hall_pass_stats_hall_pass_stats_pb from '../hall_pass_stats/hall_pass_stats_pb';
import * as checkin_checkin_pb from '../checkin/checkin_pb';
import * as pbis_pbis_pb from '../pbis/pbis_pb';
import * as pbis_stats_pbis_stats_pb from '../pbis_stats/pbis_stats_pb';
import * as points_points_pb from '../points/points_pb';
import * as flex_time_stats_flex_time_stats_pb from '../flex_time_stats/flex_time_stats_pb';
import * as common_date_pb from '../common/date_pb';
import * as content_event_pb from '../content/event_pb';
export namespace ReportSortBy {
  export interface AsObject {
    column: string;
    direction: string;
  }
}
export class ReportSortBy extends jspb.Message {
  static readonly displayName = "ReportSortBy";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): ReportSortBy.AsObject {
    return ReportSortBy.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!ReportSortBy} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: ReportSortBy) {
    var f: any;
    var obj: any = {
      column: jspb.Message.getFieldWithDefault(msg, 1, ""),
      direction: jspb.Message.getFieldWithDefault(msg, 2, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!ReportSortBy}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new ReportSortBy;
    return ReportSortBy.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!ReportSortBy} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!ReportSortBy}
   */
  static deserializeBinaryFromReader(msg: ReportSortBy, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setColumn(value1);
        break;
      case 2:
        var value2 = /** @type {string} */ (reader.readString());
        msg.setDirection(value2);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!ReportSortBy} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: ReportSortBy, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getColumn();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
    f = message.getDirection();
    if (f.length > 0) {
      writer.writeString(
        2,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    ReportSortBy.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string column = 1;
   * @return {string}
   */
  getColumn(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setColumn(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


  /**
   * optional string direction = 2;
   * @return {string}
   */
  getDirection(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
  };


  /** @param {string} value */
  setDirection(value: string) {
    jspb.Message.setProto3StringField(this, 2, value);
  }


}
export namespace GetOrExportReportRequest {
  export interface AsObject {
    hallPassFilters?: hall_pass_stats_hall_pass_stats_pb.HallPassReportFilters.AsObject;
    checkInFilters?: checkin_checkin_pb.CheckInReportFilters.AsObject;
    pbisFilters?: pbis_stats_pbis_stats_pb.PbisReportFilters.AsObject;
    pointsFilters?: points_points_pb.PointsReportFilters.AsObject;
    flexFilters?: flex_time_stats_flex_time_stats_pb.FlexReportFilters.AsObject;
    eventFilters?: content_event_pb.EventReportFilter.AsObject;
    reportType: string;
    sortBy?: ReportSortBy.AsObject;
  }
}
export class GetOrExportReportRequest extends jspb.Message {
  static readonly displayName = "GetOrExportReportRequest";
  /**
   * Oneof group definitions for this message. Each group defines the field
   * numbers belonging to that group. When of these fields' value is set, all
   * other fields in the group are cleared. During deserialization, if multiple
   * fields are encountered for a group, only the last value seen will be kept.
   * @private {!Array<!Array<number>>}
   * @const
   */
  static readonly oneofGroups_ = [[1,2,3,4,5,8]];


  /**
   * @return {GetOrExportReportRequest.FiltersCase}
   */
  getFiltersCase() {
    return /** @type {GetOrExportReportRequest.FiltersCase} */(jspb.Message.computeOneofCase(this, GetOrExportReportRequest.oneofGroups_[0]));
  }

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, GetOrExportReportRequest.oneofGroups_);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): GetOrExportReportRequest.AsObject {
    return GetOrExportReportRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!GetOrExportReportRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: GetOrExportReportRequest) {
    var f: any;
    var obj: any = {
      hallPassFilters: (f = msg.getHallPassFilters()) && hall_pass_stats_hall_pass_stats_pb.HallPassReportFilters.toObject(includeInstance, f),
      checkInFilters: (f = msg.getCheckInFilters()) && checkin_checkin_pb.CheckInReportFilters.toObject(includeInstance, f),
      pbisFilters: (f = msg.getPbisFilters()) && pbis_stats_pbis_stats_pb.PbisReportFilters.toObject(includeInstance, f),
      pointsFilters: (f = msg.getPointsFilters()) && points_points_pb.PointsReportFilters.toObject(includeInstance, f),
      flexFilters: (f = msg.getFlexFilters()) && flex_time_stats_flex_time_stats_pb.FlexReportFilters.toObject(includeInstance, f),
      eventFilters: (f = msg.getEventFilters()) && content_event_pb.EventReportFilter.toObject(includeInstance, f),
      reportType: jspb.Message.getFieldWithDefault(msg, 6, ""),
      sortBy: (f = msg.getSortBy()) && ReportSortBy.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!GetOrExportReportRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new GetOrExportReportRequest;
    return GetOrExportReportRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!GetOrExportReportRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!GetOrExportReportRequest}
   */
  static deserializeBinaryFromReader(msg: GetOrExportReportRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new hall_pass_stats_hall_pass_stats_pb.HallPassReportFilters;
        reader.readMessage(value1,hall_pass_stats_hall_pass_stats_pb.HallPassReportFilters.deserializeBinaryFromReader);
        msg.setHallPassFilters(value1);
        break;
      case 2:
        var value2 = new checkin_checkin_pb.CheckInReportFilters;
        reader.readMessage(value2,checkin_checkin_pb.CheckInReportFilters.deserializeBinaryFromReader);
        msg.setCheckInFilters(value2);
        break;
      case 3:
        var value3 = new pbis_stats_pbis_stats_pb.PbisReportFilters;
        reader.readMessage(value3,pbis_stats_pbis_stats_pb.PbisReportFilters.deserializeBinaryFromReader);
        msg.setPbisFilters(value3);
        break;
      case 4:
        var value4 = new points_points_pb.PointsReportFilters;
        reader.readMessage(value4,points_points_pb.PointsReportFilters.deserializeBinaryFromReader);
        msg.setPointsFilters(value4);
        break;
      case 5:
        var value5 = new flex_time_stats_flex_time_stats_pb.FlexReportFilters;
        reader.readMessage(value5,flex_time_stats_flex_time_stats_pb.FlexReportFilters.deserializeBinaryFromReader);
        msg.setFlexFilters(value5);
        break;
      case 8:
        var value8 = new content_event_pb.EventReportFilter;
        reader.readMessage(value8,content_event_pb.EventReportFilter.deserializeBinaryFromReader);
        msg.setEventFilters(value8);
        break;
      case 6:
        var value6 = /** @type {string} */ (reader.readString());
        msg.setReportType(value6);
        break;
      case 7:
        var value7 = new ReportSortBy;
        reader.readMessage(value7,ReportSortBy.deserializeBinaryFromReader);
        msg.setSortBy(value7);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!GetOrExportReportRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: GetOrExportReportRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getHallPassFilters();
    if (f != null) {
      writer.writeMessage(
        1,
        f,
        hall_pass_stats_hall_pass_stats_pb.HallPassReportFilters.serializeBinaryToWriter
      );
    }
    f = message.getCheckInFilters();
    if (f != null) {
      writer.writeMessage(
        2,
        f,
        checkin_checkin_pb.CheckInReportFilters.serializeBinaryToWriter
      );
    }
    f = message.getPbisFilters();
    if (f != null) {
      writer.writeMessage(
        3,
        f,
        pbis_stats_pbis_stats_pb.PbisReportFilters.serializeBinaryToWriter
      );
    }
    f = message.getPointsFilters();
    if (f != null) {
      writer.writeMessage(
        4,
        f,
        points_points_pb.PointsReportFilters.serializeBinaryToWriter
      );
    }
    f = message.getFlexFilters();
    if (f != null) {
      writer.writeMessage(
        5,
        f,
        flex_time_stats_flex_time_stats_pb.FlexReportFilters.serializeBinaryToWriter
      );
    }
    f = message.getEventFilters();
    if (f != null) {
      writer.writeMessage(
        8,
        f,
        content_event_pb.EventReportFilter.serializeBinaryToWriter
      );
    }
    f = message.getReportType();
    if (f.length > 0) {
      writer.writeString(
        6,
        f
      );
    }
    f = message.getSortBy();
    if (f != null) {
      writer.writeMessage(
        7,
        f,
        ReportSortBy.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    GetOrExportReportRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional minga.hall_pass_stats.HallPassReportFilters hall_pass_filters = 1;
   * @return {?HallPassReportFilters}
   */
  getHallPassFilters(): hall_pass_stats_hall_pass_stats_pb.HallPassReportFilters {
    return /** @type{?HallPassReportFilters} */ (
      jspb.Message.getWrapperField(this, hall_pass_stats_hall_pass_stats_pb.HallPassReportFilters, 1));
  }


  /** @param {?HallPassReportFilters|undefined} value */
  setHallPassFilters(value?: hall_pass_stats_hall_pass_stats_pb.HallPassReportFilters) {
    jspb.Message.setOneofWrapperField(this, 1, GetOrExportReportRequest.oneofGroups_[0], value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearHallPassFilters() {
    this.setHallPassFilters(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasHallPassFilters(): boolean {
    return jspb.Message.getField(this, 1) != null;
  }


  /**
   * optional checkin.CheckInReportFilters check_in_filters = 2;
   * @return {?CheckInReportFilters}
   */
  getCheckInFilters(): checkin_checkin_pb.CheckInReportFilters {
    return /** @type{?CheckInReportFilters} */ (
      jspb.Message.getWrapperField(this, checkin_checkin_pb.CheckInReportFilters, 2));
  }


  /** @param {?CheckInReportFilters|undefined} value */
  setCheckInFilters(value?: checkin_checkin_pb.CheckInReportFilters) {
    jspb.Message.setOneofWrapperField(this, 2, GetOrExportReportRequest.oneofGroups_[0], value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearCheckInFilters() {
    this.setCheckInFilters(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasCheckInFilters(): boolean {
    return jspb.Message.getField(this, 2) != null;
  }


  /**
   * optional minga.pbis_stats.PbisReportFilters pbis_filters = 3;
   * @return {?PbisReportFilters}
   */
  getPbisFilters(): pbis_stats_pbis_stats_pb.PbisReportFilters {
    return /** @type{?PbisReportFilters} */ (
      jspb.Message.getWrapperField(this, pbis_stats_pbis_stats_pb.PbisReportFilters, 3));
  }


  /** @param {?PbisReportFilters|undefined} value */
  setPbisFilters(value?: pbis_stats_pbis_stats_pb.PbisReportFilters) {
    jspb.Message.setOneofWrapperField(this, 3, GetOrExportReportRequest.oneofGroups_[0], value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearPbisFilters() {
    this.setPbisFilters(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasPbisFilters(): boolean {
    return jspb.Message.getField(this, 3) != null;
  }


  /**
   * optional points.PointsReportFilters points_filters = 4;
   * @return {?PointsReportFilters}
   */
  getPointsFilters(): points_points_pb.PointsReportFilters {
    return /** @type{?PointsReportFilters} */ (
      jspb.Message.getWrapperField(this, points_points_pb.PointsReportFilters, 4));
  }


  /** @param {?PointsReportFilters|undefined} value */
  setPointsFilters(value?: points_points_pb.PointsReportFilters) {
    jspb.Message.setOneofWrapperField(this, 4, GetOrExportReportRequest.oneofGroups_[0], value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearPointsFilters() {
    this.setPointsFilters(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasPointsFilters(): boolean {
    return jspb.Message.getField(this, 4) != null;
  }


  /**
   * optional minga.flex_time_stats.FlexReportFilters flex_filters = 5;
   * @return {?FlexReportFilters}
   */
  getFlexFilters(): flex_time_stats_flex_time_stats_pb.FlexReportFilters {
    return /** @type{?FlexReportFilters} */ (
      jspb.Message.getWrapperField(this, flex_time_stats_flex_time_stats_pb.FlexReportFilters, 5));
  }


  /** @param {?FlexReportFilters|undefined} value */
  setFlexFilters(value?: flex_time_stats_flex_time_stats_pb.FlexReportFilters) {
    jspb.Message.setOneofWrapperField(this, 5, GetOrExportReportRequest.oneofGroups_[0], value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearFlexFilters() {
    this.setFlexFilters(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasFlexFilters(): boolean {
    return jspb.Message.getField(this, 5) != null;
  }


  /**
   * optional minga.content.EventReportFilter event_filters = 8;
   * @return {?EventReportFilter}
   */
  getEventFilters(): content_event_pb.EventReportFilter {
    return /** @type{?EventReportFilter} */ (
      jspb.Message.getWrapperField(this, content_event_pb.EventReportFilter, 8));
  }


  /** @param {?EventReportFilter|undefined} value */
  setEventFilters(value?: content_event_pb.EventReportFilter) {
    jspb.Message.setOneofWrapperField(this, 8, GetOrExportReportRequest.oneofGroups_[0], value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearEventFilters() {
    this.setEventFilters(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasEventFilters(): boolean {
    return jspb.Message.getField(this, 8) != null;
  }


  /**
   * optional string report_type = 6;
   * @return {string}
   */
  getReportType(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
  };


  /** @param {string} value */
  setReportType(value: string) {
    jspb.Message.setProto3StringField(this, 6, value);
  }


  /**
   * optional ReportSortBy sort_by = 7;
   * @return {?ReportSortBy}
   */
  getSortBy(): ReportSortBy {
    return /** @type{?ReportSortBy} */ (
      jspb.Message.getWrapperField(this, ReportSortBy, 7));
  }


  /** @param {?ReportSortBy|undefined} value */
  setSortBy(value?: ReportSortBy) {
    jspb.Message.setWrapperField(this, 7, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearSortBy() {
    this.setSortBy(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasSortBy(): boolean {
    return jspb.Message.getField(this, 7) != null;
  }


}
export namespace GetOrExportReportRequest {
export enum FiltersCase {
  FILTERS_NOT_SET = 0,
  HALL_PASS_FILTERS = 1,
  CHECK_IN_FILTERS = 2,
  PBIS_FILTERS = 3,
  POINTS_FILTERS = 4,
  FLEX_FILTERS = 5,
  EVENT_FILTERS = 8,
}
} // namespace GetOrExportReportRequest
export namespace GetReportInfo {
  export interface AsObject {
    hpSummary?: hall_pass_stats_hall_pass_stats_pb.HpsSummary.AsObject;
    hpPerson?: hall_pass_stats_hall_pass_stats_pb.HpsPerson.AsObject;
    hpPersonExpired?: hall_pass_stats_hall_pass_stats_pb.HpsPersonExpired.AsObject;
    hallPass?: hall_pass_hall_pass_pb.HallPass.AsObject;
    checkin?: checkin_checkin_pb.Checkin.AsObject;
    pbisSummary?: pbis_stats_pbis_stats_pb.PbisSummary.AsObject;
    pbisPerson?: pbis_stats_pbis_stats_pb.PbisPerson.AsObject;
    consequencePerson?: pbis_stats_pbis_stats_pb.ConsequencePerson.AsObject;
    consequences?: pbis_pbis_pb.Consequence.AsObject;
    behaviors?: pbis_pbis_pb.PbisBehavior.AsObject;
    pointHistory?: points_points_pb.PointsHistoryItem.AsObject;
    pointSummary?: points_points_pb.PointsSummaryItem.AsObject;
    rewardRedeemed?: points_points_pb.RewardRedeemedItem.AsObject;
    flexReg?: flex_time_stats_flex_time_stats_pb.FlexRegistrationInfo.AsObject;
    flexUnreg?: flex_time_stats_flex_time_stats_pb.FlexUnregisteredInfo.AsObject;
    absentee?: checkin_checkin_pb.CheckinPerson.AsObject;
    pointTeamSummary?: points_points_pb.TeamPointsSummaryItem.AsObject;
    consequenceSummary?: pbis_stats_pbis_stats_pb.ConsequenceSummary.AsObject;
    pointsRedeemed?: points_points_pb.PointsRedeemedItem.AsObject;
    pbisAutomationStats?: pbis_stats_pbis_stats_pb.PbisAutomationStats.AsObject;
    editableConsequences?: pbis_pbis_pb.EditableConsequence.AsObject;
    editableBehaviors?: pbis_pbis_pb.EditablePbisBehavior.AsObject;
    editableCheckin?: checkin_checkin_pb.EditableCheckin.AsObject;
    editableHallPass?: hall_pass_hall_pass_pb.EditableHallPass.AsObject;
    eventPerson?: content_event_pb.EventPerson.AsObject;
    flexActivityStats?: flex_time_stats_flex_time_stats_pb.FlexActivityStats.AsObject;
    flexStudentsStats?: flex_time_stats_flex_time_stats_pb.FlexStudentsStats.AsObject;
    flexStaffStats?: flex_time_stats_flex_time_stats_pb.FlexStaffStats.AsObject;
  }
}
export class GetReportInfo extends jspb.Message {
  static readonly displayName = "GetReportInfo";
  /**
   * Oneof group definitions for this message. Each group defines the field
   * numbers belonging to that group. When of these fields' value is set, all
   * other fields in the group are cleared. During deserialization, if multiple
   * fields are encountered for a group, only the last value seen will be kept.
   * @private {!Array<!Array<number>>}
   * @const
   */
  static readonly oneofGroups_ = [[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28]];


  /**
   * @return {GetReportInfo.InfoCase}
   */
  getInfoCase() {
    return /** @type {GetReportInfo.InfoCase} */(jspb.Message.computeOneofCase(this, GetReportInfo.oneofGroups_[0]));
  }

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, GetReportInfo.oneofGroups_);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): GetReportInfo.AsObject {
    return GetReportInfo.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!GetReportInfo} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: GetReportInfo) {
    var f: any;
    var obj: any = {
      hpSummary: (f = msg.getHpSummary()) && hall_pass_stats_hall_pass_stats_pb.HpsSummary.toObject(includeInstance, f),
      hpPerson: (f = msg.getHpPerson()) && hall_pass_stats_hall_pass_stats_pb.HpsPerson.toObject(includeInstance, f),
      hpPersonExpired: (f = msg.getHpPersonExpired()) && hall_pass_stats_hall_pass_stats_pb.HpsPersonExpired.toObject(includeInstance, f),
      hallPass: (f = msg.getHallPass()) && hall_pass_hall_pass_pb.HallPass.toObject(includeInstance, f),
      checkin: (f = msg.getCheckin()) && checkin_checkin_pb.Checkin.toObject(includeInstance, f),
      pbisSummary: (f = msg.getPbisSummary()) && pbis_stats_pbis_stats_pb.PbisSummary.toObject(includeInstance, f),
      pbisPerson: (f = msg.getPbisPerson()) && pbis_stats_pbis_stats_pb.PbisPerson.toObject(includeInstance, f),
      consequencePerson: (f = msg.getConsequencePerson()) && pbis_stats_pbis_stats_pb.ConsequencePerson.toObject(includeInstance, f),
      consequences: (f = msg.getConsequences()) && pbis_pbis_pb.Consequence.toObject(includeInstance, f),
      behaviors: (f = msg.getBehaviors()) && pbis_pbis_pb.PbisBehavior.toObject(includeInstance, f),
      pointHistory: (f = msg.getPointHistory()) && points_points_pb.PointsHistoryItem.toObject(includeInstance, f),
      pointSummary: (f = msg.getPointSummary()) && points_points_pb.PointsSummaryItem.toObject(includeInstance, f),
      rewardRedeemed: (f = msg.getRewardRedeemed()) && points_points_pb.RewardRedeemedItem.toObject(includeInstance, f),
      flexReg: (f = msg.getFlexReg()) && flex_time_stats_flex_time_stats_pb.FlexRegistrationInfo.toObject(includeInstance, f),
      flexUnreg: (f = msg.getFlexUnreg()) && flex_time_stats_flex_time_stats_pb.FlexUnregisteredInfo.toObject(includeInstance, f),
      absentee: (f = msg.getAbsentee()) && checkin_checkin_pb.CheckinPerson.toObject(includeInstance, f),
      pointTeamSummary: (f = msg.getPointTeamSummary()) && points_points_pb.TeamPointsSummaryItem.toObject(includeInstance, f),
      consequenceSummary: (f = msg.getConsequenceSummary()) && pbis_stats_pbis_stats_pb.ConsequenceSummary.toObject(includeInstance, f),
      pointsRedeemed: (f = msg.getPointsRedeemed()) && points_points_pb.PointsRedeemedItem.toObject(includeInstance, f),
      pbisAutomationStats: (f = msg.getPbisAutomationStats()) && pbis_stats_pbis_stats_pb.PbisAutomationStats.toObject(includeInstance, f),
      editableConsequences: (f = msg.getEditableConsequences()) && pbis_pbis_pb.EditableConsequence.toObject(includeInstance, f),
      editableBehaviors: (f = msg.getEditableBehaviors()) && pbis_pbis_pb.EditablePbisBehavior.toObject(includeInstance, f),
      editableCheckin: (f = msg.getEditableCheckin()) && checkin_checkin_pb.EditableCheckin.toObject(includeInstance, f),
      editableHallPass: (f = msg.getEditableHallPass()) && hall_pass_hall_pass_pb.EditableHallPass.toObject(includeInstance, f),
      eventPerson: (f = msg.getEventPerson()) && content_event_pb.EventPerson.toObject(includeInstance, f),
      flexActivityStats: (f = msg.getFlexActivityStats()) && flex_time_stats_flex_time_stats_pb.FlexActivityStats.toObject(includeInstance, f),
      flexStudentsStats: (f = msg.getFlexStudentsStats()) && flex_time_stats_flex_time_stats_pb.FlexStudentsStats.toObject(includeInstance, f),
      flexStaffStats: (f = msg.getFlexStaffStats()) && flex_time_stats_flex_time_stats_pb.FlexStaffStats.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!GetReportInfo}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new GetReportInfo;
    return GetReportInfo.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!GetReportInfo} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!GetReportInfo}
   */
  static deserializeBinaryFromReader(msg: GetReportInfo, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new hall_pass_stats_hall_pass_stats_pb.HpsSummary;
        reader.readMessage(value1,hall_pass_stats_hall_pass_stats_pb.HpsSummary.deserializeBinaryFromReader);
        msg.setHpSummary(value1);
        break;
      case 2:
        var value2 = new hall_pass_stats_hall_pass_stats_pb.HpsPerson;
        reader.readMessage(value2,hall_pass_stats_hall_pass_stats_pb.HpsPerson.deserializeBinaryFromReader);
        msg.setHpPerson(value2);
        break;
      case 3:
        var value3 = new hall_pass_stats_hall_pass_stats_pb.HpsPersonExpired;
        reader.readMessage(value3,hall_pass_stats_hall_pass_stats_pb.HpsPersonExpired.deserializeBinaryFromReader);
        msg.setHpPersonExpired(value3);
        break;
      case 4:
        var value4 = new hall_pass_hall_pass_pb.HallPass;
        reader.readMessage(value4,hall_pass_hall_pass_pb.HallPass.deserializeBinaryFromReader);
        msg.setHallPass(value4);
        break;
      case 5:
        var value5 = new checkin_checkin_pb.Checkin;
        reader.readMessage(value5,checkin_checkin_pb.Checkin.deserializeBinaryFromReader);
        msg.setCheckin(value5);
        break;
      case 6:
        var value6 = new pbis_stats_pbis_stats_pb.PbisSummary;
        reader.readMessage(value6,pbis_stats_pbis_stats_pb.PbisSummary.deserializeBinaryFromReader);
        msg.setPbisSummary(value6);
        break;
      case 7:
        var value7 = new pbis_stats_pbis_stats_pb.PbisPerson;
        reader.readMessage(value7,pbis_stats_pbis_stats_pb.PbisPerson.deserializeBinaryFromReader);
        msg.setPbisPerson(value7);
        break;
      case 8:
        var value8 = new pbis_stats_pbis_stats_pb.ConsequencePerson;
        reader.readMessage(value8,pbis_stats_pbis_stats_pb.ConsequencePerson.deserializeBinaryFromReader);
        msg.setConsequencePerson(value8);
        break;
      case 9:
        var value9 = new pbis_pbis_pb.Consequence;
        reader.readMessage(value9,pbis_pbis_pb.Consequence.deserializeBinaryFromReader);
        msg.setConsequences(value9);
        break;
      case 10:
        var value10 = new pbis_pbis_pb.PbisBehavior;
        reader.readMessage(value10,pbis_pbis_pb.PbisBehavior.deserializeBinaryFromReader);
        msg.setBehaviors(value10);
        break;
      case 11:
        var value11 = new points_points_pb.PointsHistoryItem;
        reader.readMessage(value11,points_points_pb.PointsHistoryItem.deserializeBinaryFromReader);
        msg.setPointHistory(value11);
        break;
      case 12:
        var value12 = new points_points_pb.PointsSummaryItem;
        reader.readMessage(value12,points_points_pb.PointsSummaryItem.deserializeBinaryFromReader);
        msg.setPointSummary(value12);
        break;
      case 13:
        var value13 = new points_points_pb.RewardRedeemedItem;
        reader.readMessage(value13,points_points_pb.RewardRedeemedItem.deserializeBinaryFromReader);
        msg.setRewardRedeemed(value13);
        break;
      case 14:
        var value14 = new flex_time_stats_flex_time_stats_pb.FlexRegistrationInfo;
        reader.readMessage(value14,flex_time_stats_flex_time_stats_pb.FlexRegistrationInfo.deserializeBinaryFromReader);
        msg.setFlexReg(value14);
        break;
      case 15:
        var value15 = new flex_time_stats_flex_time_stats_pb.FlexUnregisteredInfo;
        reader.readMessage(value15,flex_time_stats_flex_time_stats_pb.FlexUnregisteredInfo.deserializeBinaryFromReader);
        msg.setFlexUnreg(value15);
        break;
      case 16:
        var value16 = new checkin_checkin_pb.CheckinPerson;
        reader.readMessage(value16,checkin_checkin_pb.CheckinPerson.deserializeBinaryFromReader);
        msg.setAbsentee(value16);
        break;
      case 17:
        var value17 = new points_points_pb.TeamPointsSummaryItem;
        reader.readMessage(value17,points_points_pb.TeamPointsSummaryItem.deserializeBinaryFromReader);
        msg.setPointTeamSummary(value17);
        break;
      case 18:
        var value18 = new pbis_stats_pbis_stats_pb.ConsequenceSummary;
        reader.readMessage(value18,pbis_stats_pbis_stats_pb.ConsequenceSummary.deserializeBinaryFromReader);
        msg.setConsequenceSummary(value18);
        break;
      case 19:
        var value19 = new points_points_pb.PointsRedeemedItem;
        reader.readMessage(value19,points_points_pb.PointsRedeemedItem.deserializeBinaryFromReader);
        msg.setPointsRedeemed(value19);
        break;
      case 20:
        var value20 = new pbis_stats_pbis_stats_pb.PbisAutomationStats;
        reader.readMessage(value20,pbis_stats_pbis_stats_pb.PbisAutomationStats.deserializeBinaryFromReader);
        msg.setPbisAutomationStats(value20);
        break;
      case 21:
        var value21 = new pbis_pbis_pb.EditableConsequence;
        reader.readMessage(value21,pbis_pbis_pb.EditableConsequence.deserializeBinaryFromReader);
        msg.setEditableConsequences(value21);
        break;
      case 22:
        var value22 = new pbis_pbis_pb.EditablePbisBehavior;
        reader.readMessage(value22,pbis_pbis_pb.EditablePbisBehavior.deserializeBinaryFromReader);
        msg.setEditableBehaviors(value22);
        break;
      case 23:
        var value23 = new checkin_checkin_pb.EditableCheckin;
        reader.readMessage(value23,checkin_checkin_pb.EditableCheckin.deserializeBinaryFromReader);
        msg.setEditableCheckin(value23);
        break;
      case 24:
        var value24 = new hall_pass_hall_pass_pb.EditableHallPass;
        reader.readMessage(value24,hall_pass_hall_pass_pb.EditableHallPass.deserializeBinaryFromReader);
        msg.setEditableHallPass(value24);
        break;
      case 25:
        var value25 = new content_event_pb.EventPerson;
        reader.readMessage(value25,content_event_pb.EventPerson.deserializeBinaryFromReader);
        msg.setEventPerson(value25);
        break;
      case 26:
        var value26 = new flex_time_stats_flex_time_stats_pb.FlexActivityStats;
        reader.readMessage(value26,flex_time_stats_flex_time_stats_pb.FlexActivityStats.deserializeBinaryFromReader);
        msg.setFlexActivityStats(value26);
        break;
      case 27:
        var value27 = new flex_time_stats_flex_time_stats_pb.FlexStudentsStats;
        reader.readMessage(value27,flex_time_stats_flex_time_stats_pb.FlexStudentsStats.deserializeBinaryFromReader);
        msg.setFlexStudentsStats(value27);
        break;
      case 28:
        var value28 = new flex_time_stats_flex_time_stats_pb.FlexStaffStats;
        reader.readMessage(value28,flex_time_stats_flex_time_stats_pb.FlexStaffStats.deserializeBinaryFromReader);
        msg.setFlexStaffStats(value28);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!GetReportInfo} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: GetReportInfo, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getHpSummary();
    if (f != null) {
      writer.writeMessage(
        1,
        f,
        hall_pass_stats_hall_pass_stats_pb.HpsSummary.serializeBinaryToWriter
      );
    }
    f = message.getHpPerson();
    if (f != null) {
      writer.writeMessage(
        2,
        f,
        hall_pass_stats_hall_pass_stats_pb.HpsPerson.serializeBinaryToWriter
      );
    }
    f = message.getHpPersonExpired();
    if (f != null) {
      writer.writeMessage(
        3,
        f,
        hall_pass_stats_hall_pass_stats_pb.HpsPersonExpired.serializeBinaryToWriter
      );
    }
    f = message.getHallPass();
    if (f != null) {
      writer.writeMessage(
        4,
        f,
        hall_pass_hall_pass_pb.HallPass.serializeBinaryToWriter
      );
    }
    f = message.getCheckin();
    if (f != null) {
      writer.writeMessage(
        5,
        f,
        checkin_checkin_pb.Checkin.serializeBinaryToWriter
      );
    }
    f = message.getPbisSummary();
    if (f != null) {
      writer.writeMessage(
        6,
        f,
        pbis_stats_pbis_stats_pb.PbisSummary.serializeBinaryToWriter
      );
    }
    f = message.getPbisPerson();
    if (f != null) {
      writer.writeMessage(
        7,
        f,
        pbis_stats_pbis_stats_pb.PbisPerson.serializeBinaryToWriter
      );
    }
    f = message.getConsequencePerson();
    if (f != null) {
      writer.writeMessage(
        8,
        f,
        pbis_stats_pbis_stats_pb.ConsequencePerson.serializeBinaryToWriter
      );
    }
    f = message.getConsequences();
    if (f != null) {
      writer.writeMessage(
        9,
        f,
        pbis_pbis_pb.Consequence.serializeBinaryToWriter
      );
    }
    f = message.getBehaviors();
    if (f != null) {
      writer.writeMessage(
        10,
        f,
        pbis_pbis_pb.PbisBehavior.serializeBinaryToWriter
      );
    }
    f = message.getPointHistory();
    if (f != null) {
      writer.writeMessage(
        11,
        f,
        points_points_pb.PointsHistoryItem.serializeBinaryToWriter
      );
    }
    f = message.getPointSummary();
    if (f != null) {
      writer.writeMessage(
        12,
        f,
        points_points_pb.PointsSummaryItem.serializeBinaryToWriter
      );
    }
    f = message.getRewardRedeemed();
    if (f != null) {
      writer.writeMessage(
        13,
        f,
        points_points_pb.RewardRedeemedItem.serializeBinaryToWriter
      );
    }
    f = message.getFlexReg();
    if (f != null) {
      writer.writeMessage(
        14,
        f,
        flex_time_stats_flex_time_stats_pb.FlexRegistrationInfo.serializeBinaryToWriter
      );
    }
    f = message.getFlexUnreg();
    if (f != null) {
      writer.writeMessage(
        15,
        f,
        flex_time_stats_flex_time_stats_pb.FlexUnregisteredInfo.serializeBinaryToWriter
      );
    }
    f = message.getAbsentee();
    if (f != null) {
      writer.writeMessage(
        16,
        f,
        checkin_checkin_pb.CheckinPerson.serializeBinaryToWriter
      );
    }
    f = message.getPointTeamSummary();
    if (f != null) {
      writer.writeMessage(
        17,
        f,
        points_points_pb.TeamPointsSummaryItem.serializeBinaryToWriter
      );
    }
    f = message.getConsequenceSummary();
    if (f != null) {
      writer.writeMessage(
        18,
        f,
        pbis_stats_pbis_stats_pb.ConsequenceSummary.serializeBinaryToWriter
      );
    }
    f = message.getPointsRedeemed();
    if (f != null) {
      writer.writeMessage(
        19,
        f,
        points_points_pb.PointsRedeemedItem.serializeBinaryToWriter
      );
    }
    f = message.getPbisAutomationStats();
    if (f != null) {
      writer.writeMessage(
        20,
        f,
        pbis_stats_pbis_stats_pb.PbisAutomationStats.serializeBinaryToWriter
      );
    }
    f = message.getEditableConsequences();
    if (f != null) {
      writer.writeMessage(
        21,
        f,
        pbis_pbis_pb.EditableConsequence.serializeBinaryToWriter
      );
    }
    f = message.getEditableBehaviors();
    if (f != null) {
      writer.writeMessage(
        22,
        f,
        pbis_pbis_pb.EditablePbisBehavior.serializeBinaryToWriter
      );
    }
    f = message.getEditableCheckin();
    if (f != null) {
      writer.writeMessage(
        23,
        f,
        checkin_checkin_pb.EditableCheckin.serializeBinaryToWriter
      );
    }
    f = message.getEditableHallPass();
    if (f != null) {
      writer.writeMessage(
        24,
        f,
        hall_pass_hall_pass_pb.EditableHallPass.serializeBinaryToWriter
      );
    }
    f = message.getEventPerson();
    if (f != null) {
      writer.writeMessage(
        25,
        f,
        content_event_pb.EventPerson.serializeBinaryToWriter
      );
    }
    f = message.getFlexActivityStats();
    if (f != null) {
      writer.writeMessage(
        26,
        f,
        flex_time_stats_flex_time_stats_pb.FlexActivityStats.serializeBinaryToWriter
      );
    }
    f = message.getFlexStudentsStats();
    if (f != null) {
      writer.writeMessage(
        27,
        f,
        flex_time_stats_flex_time_stats_pb.FlexStudentsStats.serializeBinaryToWriter
      );
    }
    f = message.getFlexStaffStats();
    if (f != null) {
      writer.writeMessage(
        28,
        f,
        flex_time_stats_flex_time_stats_pb.FlexStaffStats.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    GetReportInfo.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional minga.hall_pass_stats.HpsSummary hp_summary = 1;
   * @return {?HpsSummary}
   */
  getHpSummary(): hall_pass_stats_hall_pass_stats_pb.HpsSummary {
    return /** @type{?HpsSummary} */ (
      jspb.Message.getWrapperField(this, hall_pass_stats_hall_pass_stats_pb.HpsSummary, 1));
  }


  /** @param {?HpsSummary|undefined} value */
  setHpSummary(value?: hall_pass_stats_hall_pass_stats_pb.HpsSummary) {
    jspb.Message.setOneofWrapperField(this, 1, GetReportInfo.oneofGroups_[0], value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearHpSummary() {
    this.setHpSummary(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasHpSummary(): boolean {
    return jspb.Message.getField(this, 1) != null;
  }


  /**
   * optional minga.hall_pass_stats.HpsPerson hp_person = 2;
   * @return {?HpsPerson}
   */
  getHpPerson(): hall_pass_stats_hall_pass_stats_pb.HpsPerson {
    return /** @type{?HpsPerson} */ (
      jspb.Message.getWrapperField(this, hall_pass_stats_hall_pass_stats_pb.HpsPerson, 2));
  }


  /** @param {?HpsPerson|undefined} value */
  setHpPerson(value?: hall_pass_stats_hall_pass_stats_pb.HpsPerson) {
    jspb.Message.setOneofWrapperField(this, 2, GetReportInfo.oneofGroups_[0], value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearHpPerson() {
    this.setHpPerson(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasHpPerson(): boolean {
    return jspb.Message.getField(this, 2) != null;
  }


  /**
   * optional minga.hall_pass_stats.HpsPersonExpired hp_person_expired = 3;
   * @return {?HpsPersonExpired}
   */
  getHpPersonExpired(): hall_pass_stats_hall_pass_stats_pb.HpsPersonExpired {
    return /** @type{?HpsPersonExpired} */ (
      jspb.Message.getWrapperField(this, hall_pass_stats_hall_pass_stats_pb.HpsPersonExpired, 3));
  }


  /** @param {?HpsPersonExpired|undefined} value */
  setHpPersonExpired(value?: hall_pass_stats_hall_pass_stats_pb.HpsPersonExpired) {
    jspb.Message.setOneofWrapperField(this, 3, GetReportInfo.oneofGroups_[0], value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearHpPersonExpired() {
    this.setHpPersonExpired(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasHpPersonExpired(): boolean {
    return jspb.Message.getField(this, 3) != null;
  }


  /**
   * optional minga.hall_pass.HallPass hall_pass = 4;
   * @return {?HallPass}
   */
  getHallPass(): hall_pass_hall_pass_pb.HallPass {
    return /** @type{?HallPass} */ (
      jspb.Message.getWrapperField(this, hall_pass_hall_pass_pb.HallPass, 4));
  }


  /** @param {?HallPass|undefined} value */
  setHallPass(value?: hall_pass_hall_pass_pb.HallPass) {
    jspb.Message.setOneofWrapperField(this, 4, GetReportInfo.oneofGroups_[0], value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearHallPass() {
    this.setHallPass(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasHallPass(): boolean {
    return jspb.Message.getField(this, 4) != null;
  }


  /**
   * optional checkin.Checkin checkin = 5;
   * @return {?Checkin}
   */
  getCheckin(): checkin_checkin_pb.Checkin {
    return /** @type{?Checkin} */ (
      jspb.Message.getWrapperField(this, checkin_checkin_pb.Checkin, 5));
  }


  /** @param {?Checkin|undefined} value */
  setCheckin(value?: checkin_checkin_pb.Checkin) {
    jspb.Message.setOneofWrapperField(this, 5, GetReportInfo.oneofGroups_[0], value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearCheckin() {
    this.setCheckin(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasCheckin(): boolean {
    return jspb.Message.getField(this, 5) != null;
  }


  /**
   * optional minga.pbis_stats.PbisSummary pbis_summary = 6;
   * @return {?PbisSummary}
   */
  getPbisSummary(): pbis_stats_pbis_stats_pb.PbisSummary {
    return /** @type{?PbisSummary} */ (
      jspb.Message.getWrapperField(this, pbis_stats_pbis_stats_pb.PbisSummary, 6));
  }


  /** @param {?PbisSummary|undefined} value */
  setPbisSummary(value?: pbis_stats_pbis_stats_pb.PbisSummary) {
    jspb.Message.setOneofWrapperField(this, 6, GetReportInfo.oneofGroups_[0], value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearPbisSummary() {
    this.setPbisSummary(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasPbisSummary(): boolean {
    return jspb.Message.getField(this, 6) != null;
  }


  /**
   * optional minga.pbis_stats.PbisPerson pbis_person = 7;
   * @return {?PbisPerson}
   */
  getPbisPerson(): pbis_stats_pbis_stats_pb.PbisPerson {
    return /** @type{?PbisPerson} */ (
      jspb.Message.getWrapperField(this, pbis_stats_pbis_stats_pb.PbisPerson, 7));
  }


  /** @param {?PbisPerson|undefined} value */
  setPbisPerson(value?: pbis_stats_pbis_stats_pb.PbisPerson) {
    jspb.Message.setOneofWrapperField(this, 7, GetReportInfo.oneofGroups_[0], value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearPbisPerson() {
    this.setPbisPerson(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasPbisPerson(): boolean {
    return jspb.Message.getField(this, 7) != null;
  }


  /**
   * optional minga.pbis_stats.ConsequencePerson consequence_person = 8;
   * @return {?ConsequencePerson}
   */
  getConsequencePerson(): pbis_stats_pbis_stats_pb.ConsequencePerson {
    return /** @type{?ConsequencePerson} */ (
      jspb.Message.getWrapperField(this, pbis_stats_pbis_stats_pb.ConsequencePerson, 8));
  }


  /** @param {?ConsequencePerson|undefined} value */
  setConsequencePerson(value?: pbis_stats_pbis_stats_pb.ConsequencePerson) {
    jspb.Message.setOneofWrapperField(this, 8, GetReportInfo.oneofGroups_[0], value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearConsequencePerson() {
    this.setConsequencePerson(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasConsequencePerson(): boolean {
    return jspb.Message.getField(this, 8) != null;
  }


  /**
   * optional minga.pbis.Consequence consequences = 9;
   * @return {?Consequence}
   */
  getConsequences(): pbis_pbis_pb.Consequence {
    return /** @type{?Consequence} */ (
      jspb.Message.getWrapperField(this, pbis_pbis_pb.Consequence, 9));
  }


  /** @param {?Consequence|undefined} value */
  setConsequences(value?: pbis_pbis_pb.Consequence) {
    jspb.Message.setOneofWrapperField(this, 9, GetReportInfo.oneofGroups_[0], value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearConsequences() {
    this.setConsequences(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasConsequences(): boolean {
    return jspb.Message.getField(this, 9) != null;
  }


  /**
   * optional minga.pbis.PbisBehavior behaviors = 10;
   * @return {?PbisBehavior}
   */
  getBehaviors(): pbis_pbis_pb.PbisBehavior {
    return /** @type{?PbisBehavior} */ (
      jspb.Message.getWrapperField(this, pbis_pbis_pb.PbisBehavior, 10));
  }


  /** @param {?PbisBehavior|undefined} value */
  setBehaviors(value?: pbis_pbis_pb.PbisBehavior) {
    jspb.Message.setOneofWrapperField(this, 10, GetReportInfo.oneofGroups_[0], value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearBehaviors() {
    this.setBehaviors(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasBehaviors(): boolean {
    return jspb.Message.getField(this, 10) != null;
  }


  /**
   * optional points.PointsHistoryItem point_history = 11;
   * @return {?PointsHistoryItem}
   */
  getPointHistory(): points_points_pb.PointsHistoryItem {
    return /** @type{?PointsHistoryItem} */ (
      jspb.Message.getWrapperField(this, points_points_pb.PointsHistoryItem, 11));
  }


  /** @param {?PointsHistoryItem|undefined} value */
  setPointHistory(value?: points_points_pb.PointsHistoryItem) {
    jspb.Message.setOneofWrapperField(this, 11, GetReportInfo.oneofGroups_[0], value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearPointHistory() {
    this.setPointHistory(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasPointHistory(): boolean {
    return jspb.Message.getField(this, 11) != null;
  }


  /**
   * optional points.PointsSummaryItem point_summary = 12;
   * @return {?PointsSummaryItem}
   */
  getPointSummary(): points_points_pb.PointsSummaryItem {
    return /** @type{?PointsSummaryItem} */ (
      jspb.Message.getWrapperField(this, points_points_pb.PointsSummaryItem, 12));
  }


  /** @param {?PointsSummaryItem|undefined} value */
  setPointSummary(value?: points_points_pb.PointsSummaryItem) {
    jspb.Message.setOneofWrapperField(this, 12, GetReportInfo.oneofGroups_[0], value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearPointSummary() {
    this.setPointSummary(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasPointSummary(): boolean {
    return jspb.Message.getField(this, 12) != null;
  }


  /**
   * optional points.RewardRedeemedItem reward_redeemed = 13;
   * @return {?RewardRedeemedItem}
   */
  getRewardRedeemed(): points_points_pb.RewardRedeemedItem {
    return /** @type{?RewardRedeemedItem} */ (
      jspb.Message.getWrapperField(this, points_points_pb.RewardRedeemedItem, 13));
  }


  /** @param {?RewardRedeemedItem|undefined} value */
  setRewardRedeemed(value?: points_points_pb.RewardRedeemedItem) {
    jspb.Message.setOneofWrapperField(this, 13, GetReportInfo.oneofGroups_[0], value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearRewardRedeemed() {
    this.setRewardRedeemed(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasRewardRedeemed(): boolean {
    return jspb.Message.getField(this, 13) != null;
  }


  /**
   * optional minga.flex_time_stats.FlexRegistrationInfo flex_reg = 14;
   * @return {?FlexRegistrationInfo}
   */
  getFlexReg(): flex_time_stats_flex_time_stats_pb.FlexRegistrationInfo {
    return /** @type{?FlexRegistrationInfo} */ (
      jspb.Message.getWrapperField(this, flex_time_stats_flex_time_stats_pb.FlexRegistrationInfo, 14));
  }


  /** @param {?FlexRegistrationInfo|undefined} value */
  setFlexReg(value?: flex_time_stats_flex_time_stats_pb.FlexRegistrationInfo) {
    jspb.Message.setOneofWrapperField(this, 14, GetReportInfo.oneofGroups_[0], value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearFlexReg() {
    this.setFlexReg(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasFlexReg(): boolean {
    return jspb.Message.getField(this, 14) != null;
  }


  /**
   * optional minga.flex_time_stats.FlexUnregisteredInfo flex_unreg = 15;
   * @return {?FlexUnregisteredInfo}
   */
  getFlexUnreg(): flex_time_stats_flex_time_stats_pb.FlexUnregisteredInfo {
    return /** @type{?FlexUnregisteredInfo} */ (
      jspb.Message.getWrapperField(this, flex_time_stats_flex_time_stats_pb.FlexUnregisteredInfo, 15));
  }


  /** @param {?FlexUnregisteredInfo|undefined} value */
  setFlexUnreg(value?: flex_time_stats_flex_time_stats_pb.FlexUnregisteredInfo) {
    jspb.Message.setOneofWrapperField(this, 15, GetReportInfo.oneofGroups_[0], value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearFlexUnreg() {
    this.setFlexUnreg(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasFlexUnreg(): boolean {
    return jspb.Message.getField(this, 15) != null;
  }


  /**
   * optional checkin.CheckinPerson absentee = 16;
   * @return {?CheckinPerson}
   */
  getAbsentee(): checkin_checkin_pb.CheckinPerson {
    return /** @type{?CheckinPerson} */ (
      jspb.Message.getWrapperField(this, checkin_checkin_pb.CheckinPerson, 16));
  }


  /** @param {?CheckinPerson|undefined} value */
  setAbsentee(value?: checkin_checkin_pb.CheckinPerson) {
    jspb.Message.setOneofWrapperField(this, 16, GetReportInfo.oneofGroups_[0], value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearAbsentee() {
    this.setAbsentee(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasAbsentee(): boolean {
    return jspb.Message.getField(this, 16) != null;
  }


  /**
   * optional points.TeamPointsSummaryItem point_team_summary = 17;
   * @return {?TeamPointsSummaryItem}
   */
  getPointTeamSummary(): points_points_pb.TeamPointsSummaryItem {
    return /** @type{?TeamPointsSummaryItem} */ (
      jspb.Message.getWrapperField(this, points_points_pb.TeamPointsSummaryItem, 17));
  }


  /** @param {?TeamPointsSummaryItem|undefined} value */
  setPointTeamSummary(value?: points_points_pb.TeamPointsSummaryItem) {
    jspb.Message.setOneofWrapperField(this, 17, GetReportInfo.oneofGroups_[0], value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearPointTeamSummary() {
    this.setPointTeamSummary(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasPointTeamSummary(): boolean {
    return jspb.Message.getField(this, 17) != null;
  }


  /**
   * optional minga.pbis_stats.ConsequenceSummary consequence_summary = 18;
   * @return {?ConsequenceSummary}
   */
  getConsequenceSummary(): pbis_stats_pbis_stats_pb.ConsequenceSummary {
    return /** @type{?ConsequenceSummary} */ (
      jspb.Message.getWrapperField(this, pbis_stats_pbis_stats_pb.ConsequenceSummary, 18));
  }


  /** @param {?ConsequenceSummary|undefined} value */
  setConsequenceSummary(value?: pbis_stats_pbis_stats_pb.ConsequenceSummary) {
    jspb.Message.setOneofWrapperField(this, 18, GetReportInfo.oneofGroups_[0], value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearConsequenceSummary() {
    this.setConsequenceSummary(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasConsequenceSummary(): boolean {
    return jspb.Message.getField(this, 18) != null;
  }


  /**
   * optional points.PointsRedeemedItem points_redeemed = 19;
   * @return {?PointsRedeemedItem}
   */
  getPointsRedeemed(): points_points_pb.PointsRedeemedItem {
    return /** @type{?PointsRedeemedItem} */ (
      jspb.Message.getWrapperField(this, points_points_pb.PointsRedeemedItem, 19));
  }


  /** @param {?PointsRedeemedItem|undefined} value */
  setPointsRedeemed(value?: points_points_pb.PointsRedeemedItem) {
    jspb.Message.setOneofWrapperField(this, 19, GetReportInfo.oneofGroups_[0], value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearPointsRedeemed() {
    this.setPointsRedeemed(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasPointsRedeemed(): boolean {
    return jspb.Message.getField(this, 19) != null;
  }


  /**
   * optional minga.pbis_stats.PbisAutomationStats pbis_automation_stats = 20;
   * @return {?PbisAutomationStats}
   */
  getPbisAutomationStats(): pbis_stats_pbis_stats_pb.PbisAutomationStats {
    return /** @type{?PbisAutomationStats} */ (
      jspb.Message.getWrapperField(this, pbis_stats_pbis_stats_pb.PbisAutomationStats, 20));
  }


  /** @param {?PbisAutomationStats|undefined} value */
  setPbisAutomationStats(value?: pbis_stats_pbis_stats_pb.PbisAutomationStats) {
    jspb.Message.setOneofWrapperField(this, 20, GetReportInfo.oneofGroups_[0], value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearPbisAutomationStats() {
    this.setPbisAutomationStats(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasPbisAutomationStats(): boolean {
    return jspb.Message.getField(this, 20) != null;
  }


  /**
   * optional minga.pbis.EditableConsequence editable_consequences = 21;
   * @return {?EditableConsequence}
   */
  getEditableConsequences(): pbis_pbis_pb.EditableConsequence {
    return /** @type{?EditableConsequence} */ (
      jspb.Message.getWrapperField(this, pbis_pbis_pb.EditableConsequence, 21));
  }


  /** @param {?EditableConsequence|undefined} value */
  setEditableConsequences(value?: pbis_pbis_pb.EditableConsequence) {
    jspb.Message.setOneofWrapperField(this, 21, GetReportInfo.oneofGroups_[0], value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearEditableConsequences() {
    this.setEditableConsequences(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasEditableConsequences(): boolean {
    return jspb.Message.getField(this, 21) != null;
  }


  /**
   * optional minga.pbis.EditablePbisBehavior editable_behaviors = 22;
   * @return {?EditablePbisBehavior}
   */
  getEditableBehaviors(): pbis_pbis_pb.EditablePbisBehavior {
    return /** @type{?EditablePbisBehavior} */ (
      jspb.Message.getWrapperField(this, pbis_pbis_pb.EditablePbisBehavior, 22));
  }


  /** @param {?EditablePbisBehavior|undefined} value */
  setEditableBehaviors(value?: pbis_pbis_pb.EditablePbisBehavior) {
    jspb.Message.setOneofWrapperField(this, 22, GetReportInfo.oneofGroups_[0], value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearEditableBehaviors() {
    this.setEditableBehaviors(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasEditableBehaviors(): boolean {
    return jspb.Message.getField(this, 22) != null;
  }


  /**
   * optional checkin.EditableCheckin editable_checkin = 23;
   * @return {?EditableCheckin}
   */
  getEditableCheckin(): checkin_checkin_pb.EditableCheckin {
    return /** @type{?EditableCheckin} */ (
      jspb.Message.getWrapperField(this, checkin_checkin_pb.EditableCheckin, 23));
  }


  /** @param {?EditableCheckin|undefined} value */
  setEditableCheckin(value?: checkin_checkin_pb.EditableCheckin) {
    jspb.Message.setOneofWrapperField(this, 23, GetReportInfo.oneofGroups_[0], value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearEditableCheckin() {
    this.setEditableCheckin(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasEditableCheckin(): boolean {
    return jspb.Message.getField(this, 23) != null;
  }


  /**
   * optional minga.hall_pass.EditableHallPass editable_hall_pass = 24;
   * @return {?EditableHallPass}
   */
  getEditableHallPass(): hall_pass_hall_pass_pb.EditableHallPass {
    return /** @type{?EditableHallPass} */ (
      jspb.Message.getWrapperField(this, hall_pass_hall_pass_pb.EditableHallPass, 24));
  }


  /** @param {?EditableHallPass|undefined} value */
  setEditableHallPass(value?: hall_pass_hall_pass_pb.EditableHallPass) {
    jspb.Message.setOneofWrapperField(this, 24, GetReportInfo.oneofGroups_[0], value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearEditableHallPass() {
    this.setEditableHallPass(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasEditableHallPass(): boolean {
    return jspb.Message.getField(this, 24) != null;
  }


  /**
   * optional minga.content.EventPerson event_person = 25;
   * @return {?EventPerson}
   */
  getEventPerson(): content_event_pb.EventPerson {
    return /** @type{?EventPerson} */ (
      jspb.Message.getWrapperField(this, content_event_pb.EventPerson, 25));
  }


  /** @param {?EventPerson|undefined} value */
  setEventPerson(value?: content_event_pb.EventPerson) {
    jspb.Message.setOneofWrapperField(this, 25, GetReportInfo.oneofGroups_[0], value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearEventPerson() {
    this.setEventPerson(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasEventPerson(): boolean {
    return jspb.Message.getField(this, 25) != null;
  }


  /**
   * optional minga.flex_time_stats.FlexActivityStats flex_activity_stats = 26;
   * @return {?FlexActivityStats}
   */
  getFlexActivityStats(): flex_time_stats_flex_time_stats_pb.FlexActivityStats {
    return /** @type{?FlexActivityStats} */ (
      jspb.Message.getWrapperField(this, flex_time_stats_flex_time_stats_pb.FlexActivityStats, 26));
  }


  /** @param {?FlexActivityStats|undefined} value */
  setFlexActivityStats(value?: flex_time_stats_flex_time_stats_pb.FlexActivityStats) {
    jspb.Message.setOneofWrapperField(this, 26, GetReportInfo.oneofGroups_[0], value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearFlexActivityStats() {
    this.setFlexActivityStats(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasFlexActivityStats(): boolean {
    return jspb.Message.getField(this, 26) != null;
  }


  /**
   * optional minga.flex_time_stats.FlexStudentsStats flex_students_stats = 27;
   * @return {?FlexStudentsStats}
   */
  getFlexStudentsStats(): flex_time_stats_flex_time_stats_pb.FlexStudentsStats {
    return /** @type{?FlexStudentsStats} */ (
      jspb.Message.getWrapperField(this, flex_time_stats_flex_time_stats_pb.FlexStudentsStats, 27));
  }


  /** @param {?FlexStudentsStats|undefined} value */
  setFlexStudentsStats(value?: flex_time_stats_flex_time_stats_pb.FlexStudentsStats) {
    jspb.Message.setOneofWrapperField(this, 27, GetReportInfo.oneofGroups_[0], value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearFlexStudentsStats() {
    this.setFlexStudentsStats(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasFlexStudentsStats(): boolean {
    return jspb.Message.getField(this, 27) != null;
  }


  /**
   * optional minga.flex_time_stats.FlexStaffStats flex_staff_stats = 28;
   * @return {?FlexStaffStats}
   */
  getFlexStaffStats(): flex_time_stats_flex_time_stats_pb.FlexStaffStats {
    return /** @type{?FlexStaffStats} */ (
      jspb.Message.getWrapperField(this, flex_time_stats_flex_time_stats_pb.FlexStaffStats, 28));
  }


  /** @param {?FlexStaffStats|undefined} value */
  setFlexStaffStats(value?: flex_time_stats_flex_time_stats_pb.FlexStaffStats) {
    jspb.Message.setOneofWrapperField(this, 28, GetReportInfo.oneofGroups_[0], value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearFlexStaffStats() {
    this.setFlexStaffStats(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasFlexStaffStats(): boolean {
    return jspb.Message.getField(this, 28) != null;
  }


}
export namespace GetReportInfo {
export enum InfoCase {
  INFO_NOT_SET = 0,
  HP_SUMMARY = 1,
  HP_PERSON = 2,
  HP_PERSON_EXPIRED = 3,
  HALL_PASS = 4,
  CHECKIN = 5,
  PBIS_SUMMARY = 6,
  PBIS_PERSON = 7,
  CONSEQUENCE_PERSON = 8,
  CONSEQUENCES = 9,
  BEHAVIORS = 10,
  POINT_HISTORY = 11,
  POINT_SUMMARY = 12,
  REWARD_REDEEMED = 13,
  FLEX_REG = 14,
  FLEX_UNREG = 15,
  ABSENTEE = 16,
  POINT_TEAM_SUMMARY = 17,
  CONSEQUENCE_SUMMARY = 18,
  POINTS_REDEEMED = 19,
  PBIS_AUTOMATION_STATS = 20,
  EDITABLE_CONSEQUENCES = 21,
  EDITABLE_BEHAVIORS = 22,
  EDITABLE_CHECKIN = 23,
  EDITABLE_HALL_PASS = 24,
  EVENT_PERSON = 25,
  FLEX_ACTIVITY_STATS = 26,
  FLEX_STUDENTS_STATS = 27,
  FLEX_STAFF_STATS = 28,
}
} // namespace GetReportInfo
export namespace GetReportSharedInfo {
  export interface AsObject {
    checkinReason?: checkin_checkin_pb.CheckinReason.AsObject;
  }
}
export class GetReportSharedInfo extends jspb.Message {
  static readonly displayName = "GetReportSharedInfo";
  /**
   * Oneof group definitions for this message. Each group defines the field
   * numbers belonging to that group. When of these fields' value is set, all
   * other fields in the group are cleared. During deserialization, if multiple
   * fields are encountered for a group, only the last value seen will be kept.
   * @private {!Array<!Array<number>>}
   * @const
   */
  static readonly oneofGroups_ = [[1]];


  /**
   * @return {GetReportSharedInfo.SharedInfoCase}
   */
  getSharedInfoCase() {
    return /** @type {GetReportSharedInfo.SharedInfoCase} */(jspb.Message.computeOneofCase(this, GetReportSharedInfo.oneofGroups_[0]));
  }

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, GetReportSharedInfo.oneofGroups_);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): GetReportSharedInfo.AsObject {
    return GetReportSharedInfo.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!GetReportSharedInfo} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: GetReportSharedInfo) {
    var f: any;
    var obj: any = {
      checkinReason: (f = msg.getCheckinReason()) && checkin_checkin_pb.CheckinReason.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!GetReportSharedInfo}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new GetReportSharedInfo;
    return GetReportSharedInfo.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!GetReportSharedInfo} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!GetReportSharedInfo}
   */
  static deserializeBinaryFromReader(msg: GetReportSharedInfo, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new checkin_checkin_pb.CheckinReason;
        reader.readMessage(value1,checkin_checkin_pb.CheckinReason.deserializeBinaryFromReader);
        msg.setCheckinReason(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!GetReportSharedInfo} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: GetReportSharedInfo, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getCheckinReason();
    if (f != null) {
      writer.writeMessage(
        1,
        f,
        checkin_checkin_pb.CheckinReason.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    GetReportSharedInfo.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional checkin.CheckinReason checkin_reason = 1;
   * @return {?CheckinReason}
   */
  getCheckinReason(): checkin_checkin_pb.CheckinReason {
    return /** @type{?CheckinReason} */ (
      jspb.Message.getWrapperField(this, checkin_checkin_pb.CheckinReason, 1));
  }


  /** @param {?CheckinReason|undefined} value */
  setCheckinReason(value?: checkin_checkin_pb.CheckinReason) {
    jspb.Message.setOneofWrapperField(this, 1, GetReportSharedInfo.oneofGroups_[0], value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearCheckinReason() {
    this.setCheckinReason(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasCheckinReason(): boolean {
    return jspb.Message.getField(this, 1) != null;
  }


}
export namespace GetReportSharedInfo {
export enum SharedInfoCase {
  SHARED_INFO_NOT_SET = 0,
  CHECKIN_REASON = 1,
}
} // namespace GetReportSharedInfo
export namespace GetReportResponse {
  export interface AsObject {
    infoList: GetReportInfo.AsObject[];
    sharedInfo?: GetReportSharedInfo.AsObject;
    pageToken: number;
  }
}
export class GetReportResponse extends jspb.Message {
  static readonly displayName = "GetReportResponse";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [1];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, GetReportResponse.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): GetReportResponse.AsObject {
    return GetReportResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!GetReportResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: GetReportResponse) {
    var f: any;
    var obj: any = {
      infoList: jspb.Message.toObjectList(msg.getInfoList(),
      GetReportInfo.toObject, includeInstance),
      sharedInfo: (f = msg.getSharedInfo()) && GetReportSharedInfo.toObject(includeInstance, f),
      pageToken: jspb.Message.getFieldWithDefault(msg, 3, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!GetReportResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new GetReportResponse;
    return GetReportResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!GetReportResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!GetReportResponse}
   */
  static deserializeBinaryFromReader(msg: GetReportResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new GetReportInfo;
        reader.readMessage(value1,GetReportInfo.deserializeBinaryFromReader);
        msg.addInfo(value1);
        break;
      case 2:
        var value2 = new GetReportSharedInfo;
        reader.readMessage(value2,GetReportSharedInfo.deserializeBinaryFromReader);
        msg.setSharedInfo(value2);
        break;
      case 3:
        var value3 = /** @type {number} */ (reader.readInt32());
        msg.setPageToken(value3);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!GetReportResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: GetReportResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getInfoList();
    if (f.length > 0) {
      writer.writeRepeatedMessage(
        1,
        f,
        GetReportInfo.serializeBinaryToWriter
      );
    }
    f = message.getSharedInfo();
    if (f != null) {
      writer.writeMessage(
        2,
        f,
        GetReportSharedInfo.serializeBinaryToWriter
      );
    }
    f = message.getPageToken();
    if (f !== 0) {
      writer.writeInt32(
        3,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    GetReportResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * repeated GetReportInfo info = 1;
   * @return {!Array<!GetReportInfo>}
   */
  getInfoList(): GetReportInfo[] {
    return /** @type{!Array<!GetReportInfo>} */ (
      jspb.Message.getRepeatedWrapperField(this, GetReportInfo, 1));
  }


  /** @param {!Array<!GetReportInfo>} value */
  setInfoList(value?: GetReportInfo[]) {
    jspb.Message.setRepeatedWrapperField(this, 1, value);
  }


  /**
   * @param {!GetReportInfo=} opt_value
   * @param {number=} opt_index
   * @return {!GetReportInfo}
   */
  addInfo(opt_value?: GetReportInfo, opt_index?: number): GetReportInfo {
    return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, GetReportInfo, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearInfoList() {
    this.setInfoList([]);
  }


  /**
   * optional GetReportSharedInfo shared_info = 2;
   * @return {?GetReportSharedInfo}
   */
  getSharedInfo(): GetReportSharedInfo {
    return /** @type{?GetReportSharedInfo} */ (
      jspb.Message.getWrapperField(this, GetReportSharedInfo, 2));
  }


  /** @param {?GetReportSharedInfo|undefined} value */
  setSharedInfo(value?: GetReportSharedInfo) {
    jspb.Message.setWrapperField(this, 2, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearSharedInfo() {
    this.setSharedInfo(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasSharedInfo(): boolean {
    return jspb.Message.getField(this, 2) != null;
  }


  /**
   * optional int32 page_token = 3;
   * @return {number}
   */
  getPageToken(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
  };


  /** @param {number} value */
  setPageToken(value: number) {
    jspb.Message.setProto3IntField(this, 3, value);
  }


}
export namespace ExportReportChunk {
  export interface AsObject {
    chunk?: (string|Uint8Array);
    filename: string;
  }
}
export class ExportReportChunk extends jspb.Message {
  static readonly displayName = "ExportReportChunk";
  /**
   * Oneof group definitions for this message. Each group defines the field
   * numbers belonging to that group. When of these fields' value is set, all
   * other fields in the group are cleared. During deserialization, if multiple
   * fields are encountered for a group, only the last value seen will be kept.
   * @private {!Array<!Array<number>>}
   * @const
   */
  static readonly oneofGroups_ = [[1,2]];


  /**
   * @return {ExportReportChunk.InfoCase}
   */
  getInfoCase() {
    return /** @type {ExportReportChunk.InfoCase} */(jspb.Message.computeOneofCase(this, ExportReportChunk.oneofGroups_[0]));
  }

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, ExportReportChunk.oneofGroups_);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): ExportReportChunk.AsObject {
    return ExportReportChunk.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!ExportReportChunk} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: ExportReportChunk) {
    var f: any;
    var obj: any = {
      chunk: msg.getChunk_asB64(),
      filename: jspb.Message.getFieldWithDefault(msg, 2, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!ExportReportChunk}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new ExportReportChunk;
    return ExportReportChunk.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!ExportReportChunk} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!ExportReportChunk}
   */
  static deserializeBinaryFromReader(msg: ExportReportChunk, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {!Uint8Array} */ (reader.readBytes());
        msg.setChunk(value1);
        break;
      case 2:
        var value2 = /** @type {string} */ (reader.readString());
        msg.setFilename(value2);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!ExportReportChunk} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: ExportReportChunk, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 1));
    if (f != null) {
      writer.writeBytes(
        1,
        f
      );
    }
    f = /** @type {string} */ (jspb.Message.getField(message, 2));
    if (f != null) {
      writer.writeString(
        2,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    ExportReportChunk.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional bytes chunk = 1;
   * @return {!(string|Uint8Array)}
   */
  getChunk(): (string|Uint8Array) {
    return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /**
   * optional bytes chunk = 1;
   * This is a type-conversion wrapper around `getChunk()`
   * @return {string}
   */
  getChunk_asB64() {
    return /** @type {string} */ (jspb.Message.bytesAsB64(
        this.getChunk()));
  }


  /**
   * optional bytes chunk = 1;
   * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getChunk()`
   * @return {!Uint8Array}
   */
  getChunk_asU8() {
    return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
        this.getChunk()));
  }


  /** @param {!(string|Uint8Array)} value */
  setChunk(value: (string|Uint8Array)) {
    jspb.Message.setOneofField(this, 1, ExportReportChunk.oneofGroups_[0], value);
  }


  /**
   * Clears the field making it undefined.
   */
  clearChunk() {
    jspb.Message.setOneofField(this, 1, ExportReportChunk.oneofGroups_[0], undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasChunk(): boolean {
    return jspb.Message.getField(this, 1) != null;
  }


  /**
   * optional string filename = 2;
   * @return {string}
   */
  getFilename(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
  };


  /** @param {string} value */
  setFilename(value: string) {
    jspb.Message.setOneofField(this, 2, ExportReportChunk.oneofGroups_[0], value);
  }


  /**
   * Clears the field making it undefined.
   */
  clearFilename() {
    jspb.Message.setOneofField(this, 2, ExportReportChunk.oneofGroups_[0], undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasFilename(): boolean {
    return jspb.Message.getField(this, 2) != null;
  }


}
export namespace ExportReportChunk {
export enum InfoCase {
  INFO_NOT_SET = 0,
  CHUNK = 1,
  FILENAME = 2,
}
} // namespace ExportReportChunk
export namespace ScheduledReportMinimal {
  export interface AsObject {
    id: number;
    name: string;
    createdBy: string;
    reportType: string;
    frequency: number;
    time: string;
    deliveryTypeList: string[];
    active: boolean;
    day: string;
    createdById: number;
    createdAt?: common_date_pb.DateTime.AsObject;
  }
}
export class ScheduledReportMinimal extends jspb.Message {
  static readonly displayName = "ScheduledReportMinimal";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [7];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, ScheduledReportMinimal.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): ScheduledReportMinimal.AsObject {
    return ScheduledReportMinimal.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!ScheduledReportMinimal} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: ScheduledReportMinimal) {
    var f: any;
    var obj: any = {
      id: jspb.Message.getFieldWithDefault(msg, 1, 0),
      name: jspb.Message.getFieldWithDefault(msg, 2, ""),
      createdBy: jspb.Message.getFieldWithDefault(msg, 3, ""),
      reportType: jspb.Message.getFieldWithDefault(msg, 4, ""),
      frequency: jspb.Message.getFieldWithDefault(msg, 5, 0),
      time: jspb.Message.getFieldWithDefault(msg, 6, ""),
      deliveryTypeList: (f = jspb.Message.getRepeatedField(msg, 7)) == null ? undefined : f,
      active: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
      day: jspb.Message.getFieldWithDefault(msg, 9, ""),
      createdById: jspb.Message.getFieldWithDefault(msg, 10, 0),
      createdAt: (f = msg.getCreatedAt()) && common_date_pb.DateTime.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!ScheduledReportMinimal}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new ScheduledReportMinimal;
    return ScheduledReportMinimal.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!ScheduledReportMinimal} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!ScheduledReportMinimal}
   */
  static deserializeBinaryFromReader(msg: ScheduledReportMinimal, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {number} */ (reader.readInt32());
        msg.setId(value1);
        break;
      case 2:
        var value2 = /** @type {string} */ (reader.readString());
        msg.setName(value2);
        break;
      case 3:
        var value3 = /** @type {string} */ (reader.readString());
        msg.setCreatedBy(value3);
        break;
      case 4:
        var value4 = /** @type {string} */ (reader.readString());
        msg.setReportType(value4);
        break;
      case 5:
        var value5 = /** @type {number} */ (reader.readInt32());
        msg.setFrequency(value5);
        break;
      case 6:
        var value6 = /** @type {string} */ (reader.readString());
        msg.setTime(value6);
        break;
      case 7:
        var value7 = /** @type {string} */ (reader.readString());
        msg.addDeliveryType(value7);
        break;
      case 8:
        var value8 = /** @type {boolean} */ (reader.readBool());
        msg.setActive(value8);
        break;
      case 9:
        var value9 = /** @type {string} */ (reader.readString());
        msg.setDay(value9);
        break;
      case 10:
        var value10 = /** @type {number} */ (reader.readInt32());
        msg.setCreatedById(value10);
        break;
      case 11:
        var value11 = new common_date_pb.DateTime;
        reader.readMessage(value11,common_date_pb.DateTime.deserializeBinaryFromReader);
        msg.setCreatedAt(value11);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!ScheduledReportMinimal} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: ScheduledReportMinimal, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getId();
    if (f !== 0) {
      writer.writeInt32(
        1,
        f
      );
    }
    f = message.getName();
    if (f.length > 0) {
      writer.writeString(
        2,
        f
      );
    }
    f = message.getCreatedBy();
    if (f.length > 0) {
      writer.writeString(
        3,
        f
      );
    }
    f = message.getReportType();
    if (f.length > 0) {
      writer.writeString(
        4,
        f
      );
    }
    f = message.getFrequency();
    if (f !== 0) {
      writer.writeInt32(
        5,
        f
      );
    }
    f = message.getTime();
    if (f.length > 0) {
      writer.writeString(
        6,
        f
      );
    }
    f = message.getDeliveryTypeList();
    if (f.length > 0) {
      writer.writeRepeatedString(
        7,
        f
      );
    }
    f = message.getActive();
    if (f) {
      writer.writeBool(
        8,
        f
      );
    }
    f = message.getDay();
    if (f.length > 0) {
      writer.writeString(
        9,
        f
      );
    }
    f = message.getCreatedById();
    if (f !== 0) {
      writer.writeInt32(
        10,
        f
      );
    }
    f = message.getCreatedAt();
    if (f != null) {
      writer.writeMessage(
        11,
        f,
        common_date_pb.DateTime.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    ScheduledReportMinimal.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional int32 id = 1;
   * @return {number}
   */
  getId(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
  };


  /** @param {number} value */
  setId(value: number) {
    jspb.Message.setProto3IntField(this, 1, value);
  }


  /**
   * optional string name = 2;
   * @return {string}
   */
  getName(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
  };


  /** @param {string} value */
  setName(value: string) {
    jspb.Message.setProto3StringField(this, 2, value);
  }


  /**
   * optional string created_by = 3;
   * @return {string}
   */
  getCreatedBy(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
  };


  /** @param {string} value */
  setCreatedBy(value: string) {
    jspb.Message.setProto3StringField(this, 3, value);
  }


  /**
   * optional string report_type = 4;
   * @return {string}
   */
  getReportType(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
  };


  /** @param {string} value */
  setReportType(value: string) {
    jspb.Message.setProto3StringField(this, 4, value);
  }


  /**
   * optional int32 frequency = 5;
   * @return {number}
   */
  getFrequency(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
  };


  /** @param {number} value */
  setFrequency(value: number) {
    jspb.Message.setProto3IntField(this, 5, value);
  }


  /**
   * optional string time = 6;
   * @return {string}
   */
  getTime(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
  };


  /** @param {string} value */
  setTime(value: string) {
    jspb.Message.setProto3StringField(this, 6, value);
  }


  /**
   * repeated string delivery_type = 7;
   * @return {!Array<string>}
   */
  getDeliveryTypeList(): string[] {
    return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 7));
  };


  /** @param {!Array<string>} value */
  setDeliveryTypeList(value: string[]) {
    jspb.Message.setField(this, 7, value || []);
  }


  /**
   * @param {string} value
   * @param {number=} opt_index
   */
  addDeliveryType(value: string, opt_index?: number): void {
    jspb.Message.addToRepeatedField(this, 7, value, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearDeliveryTypeList() {
    this.setDeliveryTypeList([]);
  }


  /**
   * optional bool active = 8;
   * @return {boolean}
   */
  getActive(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
  };


  /** @param {boolean} value */
  setActive(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 8, value);
  }


  /**
   * optional string day = 9;
   * @return {string}
   */
  getDay(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
  };


  /** @param {string} value */
  setDay(value: string) {
    jspb.Message.setProto3StringField(this, 9, value);
  }


  /**
   * optional int32 created_by_id = 10;
   * @return {number}
   */
  getCreatedById(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
  };


  /** @param {number} value */
  setCreatedById(value: number) {
    jspb.Message.setProto3IntField(this, 10, value);
  }


  /**
   * optional minga.common.DateTime created_at = 11;
   * @return {?DateTime}
   */
  getCreatedAt(): common_date_pb.DateTime {
    return /** @type{?DateTime} */ (
      jspb.Message.getWrapperField(this, common_date_pb.DateTime, 11));
  }


  /** @param {?DateTime|undefined} value */
  setCreatedAt(value?: common_date_pb.DateTime) {
    jspb.Message.setWrapperField(this, 11, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearCreatedAt() {
    this.setCreatedAt(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasCreatedAt(): boolean {
    return jspb.Message.getField(this, 11) != null;
  }


}
export namespace ScheduledReport {
  export interface AsObject {
    hallPassFilters?: hall_pass_stats_hall_pass_stats_pb.HallPassReportFilters.AsObject;
    checkInFilters?: checkin_checkin_pb.CheckInReportFilters.AsObject;
    pbisFilters?: pbis_stats_pbis_stats_pb.PbisReportFilters.AsObject;
    pointsFilters?: points_points_pb.PointsReportFilters.AsObject;
    flexFilters?: flex_time_stats_flex_time_stats_pb.FlexReportFilters.AsObject;
    id: number;
    reportType: string;
    frequency: number;
    time: string;
    deliveryTypeList: string[];
    emailList: string[];
    sftpHost: string;
    sftpUserName: string;
    sftpPassword: string;
    day: string;
    name: string;
    active: boolean;
    createdBy: string;
    createdById: number;
    createdAt?: common_date_pb.DateTime.AsObject;
  }
}
export class ScheduledReport extends jspb.Message {
  static readonly displayName = "ScheduledReport";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [10,11];

  /**
   * Oneof group definitions for this message. Each group defines the field
   * numbers belonging to that group. When of these fields' value is set, all
   * other fields in the group are cleared. During deserialization, if multiple
   * fields are encountered for a group, only the last value seen will be kept.
   * @private {!Array<!Array<number>>}
   * @const
   */
  static readonly oneofGroups_ = [[1,2,3,4,5]];


  /**
   * @return {ScheduledReport.FiltersCase}
   */
  getFiltersCase() {
    return /** @type {ScheduledReport.FiltersCase} */(jspb.Message.computeOneofCase(this, ScheduledReport.oneofGroups_[0]));
  }

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, ScheduledReport.repeatedFields_, ScheduledReport.oneofGroups_);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): ScheduledReport.AsObject {
    return ScheduledReport.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!ScheduledReport} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: ScheduledReport) {
    var f: any;
    var obj: any = {
      hallPassFilters: (f = msg.getHallPassFilters()) && hall_pass_stats_hall_pass_stats_pb.HallPassReportFilters.toObject(includeInstance, f),
      checkInFilters: (f = msg.getCheckInFilters()) && checkin_checkin_pb.CheckInReportFilters.toObject(includeInstance, f),
      pbisFilters: (f = msg.getPbisFilters()) && pbis_stats_pbis_stats_pb.PbisReportFilters.toObject(includeInstance, f),
      pointsFilters: (f = msg.getPointsFilters()) && points_points_pb.PointsReportFilters.toObject(includeInstance, f),
      flexFilters: (f = msg.getFlexFilters()) && flex_time_stats_flex_time_stats_pb.FlexReportFilters.toObject(includeInstance, f),
      id: jspb.Message.getFieldWithDefault(msg, 6, 0),
      reportType: jspb.Message.getFieldWithDefault(msg, 7, ""),
      frequency: jspb.Message.getFieldWithDefault(msg, 8, 0),
      time: jspb.Message.getFieldWithDefault(msg, 9, ""),
      deliveryTypeList: (f = jspb.Message.getRepeatedField(msg, 10)) == null ? undefined : f,
      emailList: (f = jspb.Message.getRepeatedField(msg, 11)) == null ? undefined : f,
      sftpHost: jspb.Message.getFieldWithDefault(msg, 12, ""),
      sftpUserName: jspb.Message.getFieldWithDefault(msg, 13, ""),
      sftpPassword: jspb.Message.getFieldWithDefault(msg, 14, ""),
      day: jspb.Message.getFieldWithDefault(msg, 15, ""),
      name: jspb.Message.getFieldWithDefault(msg, 16, ""),
      active: jspb.Message.getBooleanFieldWithDefault(msg, 17, false),
      createdBy: jspb.Message.getFieldWithDefault(msg, 18, ""),
      createdById: jspb.Message.getFieldWithDefault(msg, 19, 0),
      createdAt: (f = msg.getCreatedAt()) && common_date_pb.DateTime.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!ScheduledReport}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new ScheduledReport;
    return ScheduledReport.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!ScheduledReport} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!ScheduledReport}
   */
  static deserializeBinaryFromReader(msg: ScheduledReport, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new hall_pass_stats_hall_pass_stats_pb.HallPassReportFilters;
        reader.readMessage(value1,hall_pass_stats_hall_pass_stats_pb.HallPassReportFilters.deserializeBinaryFromReader);
        msg.setHallPassFilters(value1);
        break;
      case 2:
        var value2 = new checkin_checkin_pb.CheckInReportFilters;
        reader.readMessage(value2,checkin_checkin_pb.CheckInReportFilters.deserializeBinaryFromReader);
        msg.setCheckInFilters(value2);
        break;
      case 3:
        var value3 = new pbis_stats_pbis_stats_pb.PbisReportFilters;
        reader.readMessage(value3,pbis_stats_pbis_stats_pb.PbisReportFilters.deserializeBinaryFromReader);
        msg.setPbisFilters(value3);
        break;
      case 4:
        var value4 = new points_points_pb.PointsReportFilters;
        reader.readMessage(value4,points_points_pb.PointsReportFilters.deserializeBinaryFromReader);
        msg.setPointsFilters(value4);
        break;
      case 5:
        var value5 = new flex_time_stats_flex_time_stats_pb.FlexReportFilters;
        reader.readMessage(value5,flex_time_stats_flex_time_stats_pb.FlexReportFilters.deserializeBinaryFromReader);
        msg.setFlexFilters(value5);
        break;
      case 6:
        var value6 = /** @type {number} */ (reader.readInt32());
        msg.setId(value6);
        break;
      case 7:
        var value7 = /** @type {string} */ (reader.readString());
        msg.setReportType(value7);
        break;
      case 8:
        var value8 = /** @type {number} */ (reader.readInt32());
        msg.setFrequency(value8);
        break;
      case 9:
        var value9 = /** @type {string} */ (reader.readString());
        msg.setTime(value9);
        break;
      case 10:
        var value10 = /** @type {string} */ (reader.readString());
        msg.addDeliveryType(value10);
        break;
      case 11:
        var value11 = /** @type {string} */ (reader.readString());
        msg.addEmail(value11);
        break;
      case 12:
        var value12 = /** @type {string} */ (reader.readString());
        msg.setSftpHost(value12);
        break;
      case 13:
        var value13 = /** @type {string} */ (reader.readString());
        msg.setSftpUserName(value13);
        break;
      case 14:
        var value14 = /** @type {string} */ (reader.readString());
        msg.setSftpPassword(value14);
        break;
      case 15:
        var value15 = /** @type {string} */ (reader.readString());
        msg.setDay(value15);
        break;
      case 16:
        var value16 = /** @type {string} */ (reader.readString());
        msg.setName(value16);
        break;
      case 17:
        var value17 = /** @type {boolean} */ (reader.readBool());
        msg.setActive(value17);
        break;
      case 18:
        var value18 = /** @type {string} */ (reader.readString());
        msg.setCreatedBy(value18);
        break;
      case 19:
        var value19 = /** @type {number} */ (reader.readInt32());
        msg.setCreatedById(value19);
        break;
      case 20:
        var value20 = new common_date_pb.DateTime;
        reader.readMessage(value20,common_date_pb.DateTime.deserializeBinaryFromReader);
        msg.setCreatedAt(value20);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!ScheduledReport} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: ScheduledReport, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getHallPassFilters();
    if (f != null) {
      writer.writeMessage(
        1,
        f,
        hall_pass_stats_hall_pass_stats_pb.HallPassReportFilters.serializeBinaryToWriter
      );
    }
    f = message.getCheckInFilters();
    if (f != null) {
      writer.writeMessage(
        2,
        f,
        checkin_checkin_pb.CheckInReportFilters.serializeBinaryToWriter
      );
    }
    f = message.getPbisFilters();
    if (f != null) {
      writer.writeMessage(
        3,
        f,
        pbis_stats_pbis_stats_pb.PbisReportFilters.serializeBinaryToWriter
      );
    }
    f = message.getPointsFilters();
    if (f != null) {
      writer.writeMessage(
        4,
        f,
        points_points_pb.PointsReportFilters.serializeBinaryToWriter
      );
    }
    f = message.getFlexFilters();
    if (f != null) {
      writer.writeMessage(
        5,
        f,
        flex_time_stats_flex_time_stats_pb.FlexReportFilters.serializeBinaryToWriter
      );
    }
    f = message.getId();
    if (f !== 0) {
      writer.writeInt32(
        6,
        f
      );
    }
    f = message.getReportType();
    if (f.length > 0) {
      writer.writeString(
        7,
        f
      );
    }
    f = message.getFrequency();
    if (f !== 0) {
      writer.writeInt32(
        8,
        f
      );
    }
    f = message.getTime();
    if (f.length > 0) {
      writer.writeString(
        9,
        f
      );
    }
    f = message.getDeliveryTypeList();
    if (f.length > 0) {
      writer.writeRepeatedString(
        10,
        f
      );
    }
    f = message.getEmailList();
    if (f.length > 0) {
      writer.writeRepeatedString(
        11,
        f
      );
    }
    f = message.getSftpHost();
    if (f.length > 0) {
      writer.writeString(
        12,
        f
      );
    }
    f = message.getSftpUserName();
    if (f.length > 0) {
      writer.writeString(
        13,
        f
      );
    }
    f = message.getSftpPassword();
    if (f.length > 0) {
      writer.writeString(
        14,
        f
      );
    }
    f = message.getDay();
    if (f.length > 0) {
      writer.writeString(
        15,
        f
      );
    }
    f = message.getName();
    if (f.length > 0) {
      writer.writeString(
        16,
        f
      );
    }
    f = message.getActive();
    if (f) {
      writer.writeBool(
        17,
        f
      );
    }
    f = message.getCreatedBy();
    if (f.length > 0) {
      writer.writeString(
        18,
        f
      );
    }
    f = message.getCreatedById();
    if (f !== 0) {
      writer.writeInt32(
        19,
        f
      );
    }
    f = message.getCreatedAt();
    if (f != null) {
      writer.writeMessage(
        20,
        f,
        common_date_pb.DateTime.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    ScheduledReport.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional minga.hall_pass_stats.HallPassReportFilters hall_pass_filters = 1;
   * @return {?HallPassReportFilters}
   */
  getHallPassFilters(): hall_pass_stats_hall_pass_stats_pb.HallPassReportFilters {
    return /** @type{?HallPassReportFilters} */ (
      jspb.Message.getWrapperField(this, hall_pass_stats_hall_pass_stats_pb.HallPassReportFilters, 1));
  }


  /** @param {?HallPassReportFilters|undefined} value */
  setHallPassFilters(value?: hall_pass_stats_hall_pass_stats_pb.HallPassReportFilters) {
    jspb.Message.setOneofWrapperField(this, 1, ScheduledReport.oneofGroups_[0], value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearHallPassFilters() {
    this.setHallPassFilters(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasHallPassFilters(): boolean {
    return jspb.Message.getField(this, 1) != null;
  }


  /**
   * optional checkin.CheckInReportFilters check_in_filters = 2;
   * @return {?CheckInReportFilters}
   */
  getCheckInFilters(): checkin_checkin_pb.CheckInReportFilters {
    return /** @type{?CheckInReportFilters} */ (
      jspb.Message.getWrapperField(this, checkin_checkin_pb.CheckInReportFilters, 2));
  }


  /** @param {?CheckInReportFilters|undefined} value */
  setCheckInFilters(value?: checkin_checkin_pb.CheckInReportFilters) {
    jspb.Message.setOneofWrapperField(this, 2, ScheduledReport.oneofGroups_[0], value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearCheckInFilters() {
    this.setCheckInFilters(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasCheckInFilters(): boolean {
    return jspb.Message.getField(this, 2) != null;
  }


  /**
   * optional minga.pbis_stats.PbisReportFilters pbis_filters = 3;
   * @return {?PbisReportFilters}
   */
  getPbisFilters(): pbis_stats_pbis_stats_pb.PbisReportFilters {
    return /** @type{?PbisReportFilters} */ (
      jspb.Message.getWrapperField(this, pbis_stats_pbis_stats_pb.PbisReportFilters, 3));
  }


  /** @param {?PbisReportFilters|undefined} value */
  setPbisFilters(value?: pbis_stats_pbis_stats_pb.PbisReportFilters) {
    jspb.Message.setOneofWrapperField(this, 3, ScheduledReport.oneofGroups_[0], value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearPbisFilters() {
    this.setPbisFilters(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasPbisFilters(): boolean {
    return jspb.Message.getField(this, 3) != null;
  }


  /**
   * optional points.PointsReportFilters points_filters = 4;
   * @return {?PointsReportFilters}
   */
  getPointsFilters(): points_points_pb.PointsReportFilters {
    return /** @type{?PointsReportFilters} */ (
      jspb.Message.getWrapperField(this, points_points_pb.PointsReportFilters, 4));
  }


  /** @param {?PointsReportFilters|undefined} value */
  setPointsFilters(value?: points_points_pb.PointsReportFilters) {
    jspb.Message.setOneofWrapperField(this, 4, ScheduledReport.oneofGroups_[0], value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearPointsFilters() {
    this.setPointsFilters(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasPointsFilters(): boolean {
    return jspb.Message.getField(this, 4) != null;
  }


  /**
   * optional minga.flex_time_stats.FlexReportFilters flex_filters = 5;
   * @return {?FlexReportFilters}
   */
  getFlexFilters(): flex_time_stats_flex_time_stats_pb.FlexReportFilters {
    return /** @type{?FlexReportFilters} */ (
      jspb.Message.getWrapperField(this, flex_time_stats_flex_time_stats_pb.FlexReportFilters, 5));
  }


  /** @param {?FlexReportFilters|undefined} value */
  setFlexFilters(value?: flex_time_stats_flex_time_stats_pb.FlexReportFilters) {
    jspb.Message.setOneofWrapperField(this, 5, ScheduledReport.oneofGroups_[0], value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearFlexFilters() {
    this.setFlexFilters(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasFlexFilters(): boolean {
    return jspb.Message.getField(this, 5) != null;
  }


  /**
   * optional int32 id = 6;
   * @return {number}
   */
  getId(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
  };


  /** @param {number} value */
  setId(value: number) {
    jspb.Message.setProto3IntField(this, 6, value);
  }


  /**
   * optional string report_type = 7;
   * @return {string}
   */
  getReportType(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
  };


  /** @param {string} value */
  setReportType(value: string) {
    jspb.Message.setProto3StringField(this, 7, value);
  }


  /**
   * optional int32 frequency = 8;
   * @return {number}
   */
  getFrequency(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
  };


  /** @param {number} value */
  setFrequency(value: number) {
    jspb.Message.setProto3IntField(this, 8, value);
  }


  /**
   * optional string time = 9;
   * @return {string}
   */
  getTime(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
  };


  /** @param {string} value */
  setTime(value: string) {
    jspb.Message.setProto3StringField(this, 9, value);
  }


  /**
   * repeated string delivery_type = 10;
   * @return {!Array<string>}
   */
  getDeliveryTypeList(): string[] {
    return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 10));
  };


  /** @param {!Array<string>} value */
  setDeliveryTypeList(value: string[]) {
    jspb.Message.setField(this, 10, value || []);
  }


  /**
   * @param {string} value
   * @param {number=} opt_index
   */
  addDeliveryType(value: string, opt_index?: number): void {
    jspb.Message.addToRepeatedField(this, 10, value, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearDeliveryTypeList() {
    this.setDeliveryTypeList([]);
  }


  /**
   * repeated string email = 11;
   * @return {!Array<string>}
   */
  getEmailList(): string[] {
    return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 11));
  };


  /** @param {!Array<string>} value */
  setEmailList(value: string[]) {
    jspb.Message.setField(this, 11, value || []);
  }


  /**
   * @param {string} value
   * @param {number=} opt_index
   */
  addEmail(value: string, opt_index?: number): void {
    jspb.Message.addToRepeatedField(this, 11, value, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearEmailList() {
    this.setEmailList([]);
  }


  /**
   * optional string sftp_host = 12;
   * @return {string}
   */
  getSftpHost(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
  };


  /** @param {string} value */
  setSftpHost(value: string) {
    jspb.Message.setProto3StringField(this, 12, value);
  }


  /**
   * optional string sftp_user_name = 13;
   * @return {string}
   */
  getSftpUserName(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
  };


  /** @param {string} value */
  setSftpUserName(value: string) {
    jspb.Message.setProto3StringField(this, 13, value);
  }


  /**
   * optional string sftp_password = 14;
   * @return {string}
   */
  getSftpPassword(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
  };


  /** @param {string} value */
  setSftpPassword(value: string) {
    jspb.Message.setProto3StringField(this, 14, value);
  }


  /**
   * optional string day = 15;
   * @return {string}
   */
  getDay(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
  };


  /** @param {string} value */
  setDay(value: string) {
    jspb.Message.setProto3StringField(this, 15, value);
  }


  /**
   * optional string name = 16;
   * @return {string}
   */
  getName(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
  };


  /** @param {string} value */
  setName(value: string) {
    jspb.Message.setProto3StringField(this, 16, value);
  }


  /**
   * optional bool active = 17;
   * @return {boolean}
   */
  getActive(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 17, false));
  };


  /** @param {boolean} value */
  setActive(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 17, value);
  }


  /**
   * optional string created_by = 18;
   * @return {string}
   */
  getCreatedBy(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
  };


  /** @param {string} value */
  setCreatedBy(value: string) {
    jspb.Message.setProto3StringField(this, 18, value);
  }


  /**
   * optional int32 created_by_id = 19;
   * @return {number}
   */
  getCreatedById(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 19, 0));
  };


  /** @param {number} value */
  setCreatedById(value: number) {
    jspb.Message.setProto3IntField(this, 19, value);
  }


  /**
   * optional minga.common.DateTime created_at = 20;
   * @return {?DateTime}
   */
  getCreatedAt(): common_date_pb.DateTime {
    return /** @type{?DateTime} */ (
      jspb.Message.getWrapperField(this, common_date_pb.DateTime, 20));
  }


  /** @param {?DateTime|undefined} value */
  setCreatedAt(value?: common_date_pb.DateTime) {
    jspb.Message.setWrapperField(this, 20, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearCreatedAt() {
    this.setCreatedAt(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasCreatedAt(): boolean {
    return jspb.Message.getField(this, 20) != null;
  }


}
export namespace ScheduledReport {
export enum FiltersCase {
  FILTERS_NOT_SET = 0,
  HALL_PASS_FILTERS = 1,
  CHECK_IN_FILTERS = 2,
  PBIS_FILTERS = 3,
  POINTS_FILTERS = 4,
  FLEX_FILTERS = 5,
}
} // namespace ScheduledReport
export namespace ListScheduledReportsRequest {
  export interface AsObject {
  }
}
export class ListScheduledReportsRequest extends jspb.Message {
  static readonly displayName = "ListScheduledReportsRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): ListScheduledReportsRequest.AsObject {
    return ListScheduledReportsRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!ListScheduledReportsRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: ListScheduledReportsRequest) {
    var f: any;
    var obj: any = {

    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!ListScheduledReportsRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new ListScheduledReportsRequest;
    return ListScheduledReportsRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!ListScheduledReportsRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!ListScheduledReportsRequest}
   */
  static deserializeBinaryFromReader(msg: ListScheduledReportsRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!ListScheduledReportsRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: ListScheduledReportsRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    ListScheduledReportsRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
}
export namespace ListScheduledReportsResponse {
  export interface AsObject {
    scheduledReportsList: ScheduledReportMinimal.AsObject[];
  }
}
export class ListScheduledReportsResponse extends jspb.Message {
  static readonly displayName = "ListScheduledReportsResponse";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [1];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, ListScheduledReportsResponse.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): ListScheduledReportsResponse.AsObject {
    return ListScheduledReportsResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!ListScheduledReportsResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: ListScheduledReportsResponse) {
    var f: any;
    var obj: any = {
      scheduledReportsList: jspb.Message.toObjectList(msg.getScheduledReportsList(),
      ScheduledReportMinimal.toObject, includeInstance)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!ListScheduledReportsResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new ListScheduledReportsResponse;
    return ListScheduledReportsResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!ListScheduledReportsResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!ListScheduledReportsResponse}
   */
  static deserializeBinaryFromReader(msg: ListScheduledReportsResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new ScheduledReportMinimal;
        reader.readMessage(value1,ScheduledReportMinimal.deserializeBinaryFromReader);
        msg.addScheduledReports(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!ListScheduledReportsResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: ListScheduledReportsResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getScheduledReportsList();
    if (f.length > 0) {
      writer.writeRepeatedMessage(
        1,
        f,
        ScheduledReportMinimal.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    ListScheduledReportsResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * repeated ScheduledReportMinimal scheduled_reports = 1;
   * @return {!Array<!ScheduledReportMinimal>}
   */
  getScheduledReportsList(): ScheduledReportMinimal[] {
    return /** @type{!Array<!ScheduledReportMinimal>} */ (
      jspb.Message.getRepeatedWrapperField(this, ScheduledReportMinimal, 1));
  }


  /** @param {!Array<!ScheduledReportMinimal>} value */
  setScheduledReportsList(value?: ScheduledReportMinimal[]) {
    jspb.Message.setRepeatedWrapperField(this, 1, value);
  }


  /**
   * @param {!ScheduledReportMinimal=} opt_value
   * @param {number=} opt_index
   * @return {!ScheduledReportMinimal}
   */
  addScheduledReports(opt_value?: ScheduledReportMinimal, opt_index?: number): ScheduledReportMinimal {
    return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, ScheduledReportMinimal, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearScheduledReportsList() {
    this.setScheduledReportsList([]);
  }


}
export namespace GetScheduledReportRequest {
  export interface AsObject {
    id: number;
  }
}
export class GetScheduledReportRequest extends jspb.Message {
  static readonly displayName = "GetScheduledReportRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): GetScheduledReportRequest.AsObject {
    return GetScheduledReportRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!GetScheduledReportRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: GetScheduledReportRequest) {
    var f: any;
    var obj: any = {
      id: jspb.Message.getFieldWithDefault(msg, 1, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!GetScheduledReportRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new GetScheduledReportRequest;
    return GetScheduledReportRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!GetScheduledReportRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!GetScheduledReportRequest}
   */
  static deserializeBinaryFromReader(msg: GetScheduledReportRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {number} */ (reader.readInt32());
        msg.setId(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!GetScheduledReportRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: GetScheduledReportRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getId();
    if (f !== 0) {
      writer.writeInt32(
        1,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    GetScheduledReportRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional int32 id = 1;
   * @return {number}
   */
  getId(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
  };


  /** @param {number} value */
  setId(value: number) {
    jspb.Message.setProto3IntField(this, 1, value);
  }


}
export namespace GetScheduledReportResponse {
  export interface AsObject {
    scheduledReport?: ScheduledReport.AsObject;
  }
}
export class GetScheduledReportResponse extends jspb.Message {
  static readonly displayName = "GetScheduledReportResponse";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): GetScheduledReportResponse.AsObject {
    return GetScheduledReportResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!GetScheduledReportResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: GetScheduledReportResponse) {
    var f: any;
    var obj: any = {
      scheduledReport: (f = msg.getScheduledReport()) && ScheduledReport.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!GetScheduledReportResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new GetScheduledReportResponse;
    return GetScheduledReportResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!GetScheduledReportResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!GetScheduledReportResponse}
   */
  static deserializeBinaryFromReader(msg: GetScheduledReportResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new ScheduledReport;
        reader.readMessage(value1,ScheduledReport.deserializeBinaryFromReader);
        msg.setScheduledReport(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!GetScheduledReportResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: GetScheduledReportResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getScheduledReport();
    if (f != null) {
      writer.writeMessage(
        1,
        f,
        ScheduledReport.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    GetScheduledReportResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional ScheduledReport scheduled_report = 1;
   * @return {?ScheduledReport}
   */
  getScheduledReport(): ScheduledReport {
    return /** @type{?ScheduledReport} */ (
      jspb.Message.getWrapperField(this, ScheduledReport, 1));
  }


  /** @param {?ScheduledReport|undefined} value */
  setScheduledReport(value?: ScheduledReport) {
    jspb.Message.setWrapperField(this, 1, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearScheduledReport() {
    this.setScheduledReport(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasScheduledReport(): boolean {
    return jspb.Message.getField(this, 1) != null;
  }


}
export namespace UpsertScheduledReportRequest {
  export interface AsObject {
    scheduledReport?: ScheduledReport.AsObject;
  }
}
export class UpsertScheduledReportRequest extends jspb.Message {
  static readonly displayName = "UpsertScheduledReportRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): UpsertScheduledReportRequest.AsObject {
    return UpsertScheduledReportRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!UpsertScheduledReportRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: UpsertScheduledReportRequest) {
    var f: any;
    var obj: any = {
      scheduledReport: (f = msg.getScheduledReport()) && ScheduledReport.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!UpsertScheduledReportRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new UpsertScheduledReportRequest;
    return UpsertScheduledReportRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!UpsertScheduledReportRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!UpsertScheduledReportRequest}
   */
  static deserializeBinaryFromReader(msg: UpsertScheduledReportRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new ScheduledReport;
        reader.readMessage(value1,ScheduledReport.deserializeBinaryFromReader);
        msg.setScheduledReport(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!UpsertScheduledReportRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: UpsertScheduledReportRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getScheduledReport();
    if (f != null) {
      writer.writeMessage(
        1,
        f,
        ScheduledReport.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    UpsertScheduledReportRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional ScheduledReport scheduled_report = 1;
   * @return {?ScheduledReport}
   */
  getScheduledReport(): ScheduledReport {
    return /** @type{?ScheduledReport} */ (
      jspb.Message.getWrapperField(this, ScheduledReport, 1));
  }


  /** @param {?ScheduledReport|undefined} value */
  setScheduledReport(value?: ScheduledReport) {
    jspb.Message.setWrapperField(this, 1, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearScheduledReport() {
    this.setScheduledReport(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasScheduledReport(): boolean {
    return jspb.Message.getField(this, 1) != null;
  }


}
export namespace UpsertScheduledReportResponse {
  export interface AsObject {
    scheduledReport?: ScheduledReport.AsObject;
  }
}
export class UpsertScheduledReportResponse extends jspb.Message {
  static readonly displayName = "UpsertScheduledReportResponse";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): UpsertScheduledReportResponse.AsObject {
    return UpsertScheduledReportResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!UpsertScheduledReportResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: UpsertScheduledReportResponse) {
    var f: any;
    var obj: any = {
      scheduledReport: (f = msg.getScheduledReport()) && ScheduledReport.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!UpsertScheduledReportResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new UpsertScheduledReportResponse;
    return UpsertScheduledReportResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!UpsertScheduledReportResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!UpsertScheduledReportResponse}
   */
  static deserializeBinaryFromReader(msg: UpsertScheduledReportResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new ScheduledReport;
        reader.readMessage(value1,ScheduledReport.deserializeBinaryFromReader);
        msg.setScheduledReport(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!UpsertScheduledReportResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: UpsertScheduledReportResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getScheduledReport();
    if (f != null) {
      writer.writeMessage(
        1,
        f,
        ScheduledReport.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    UpsertScheduledReportResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional ScheduledReport scheduled_report = 1;
   * @return {?ScheduledReport}
   */
  getScheduledReport(): ScheduledReport {
    return /** @type{?ScheduledReport} */ (
      jspb.Message.getWrapperField(this, ScheduledReport, 1));
  }


  /** @param {?ScheduledReport|undefined} value */
  setScheduledReport(value?: ScheduledReport) {
    jspb.Message.setWrapperField(this, 1, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearScheduledReport() {
    this.setScheduledReport(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasScheduledReport(): boolean {
    return jspb.Message.getField(this, 1) != null;
  }


}
export namespace DeleteScheduledReportRequest {
  export interface AsObject {
    id: number;
  }
}
export class DeleteScheduledReportRequest extends jspb.Message {
  static readonly displayName = "DeleteScheduledReportRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): DeleteScheduledReportRequest.AsObject {
    return DeleteScheduledReportRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!DeleteScheduledReportRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: DeleteScheduledReportRequest) {
    var f: any;
    var obj: any = {
      id: jspb.Message.getFieldWithDefault(msg, 1, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!DeleteScheduledReportRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new DeleteScheduledReportRequest;
    return DeleteScheduledReportRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!DeleteScheduledReportRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!DeleteScheduledReportRequest}
   */
  static deserializeBinaryFromReader(msg: DeleteScheduledReportRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {number} */ (reader.readInt32());
        msg.setId(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!DeleteScheduledReportRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: DeleteScheduledReportRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getId();
    if (f !== 0) {
      writer.writeInt32(
        1,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    DeleteScheduledReportRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional int32 id = 1;
   * @return {number}
   */
  getId(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
  };


  /** @param {number} value */
  setId(value: number) {
    jspb.Message.setProto3IntField(this, 1, value);
  }


}
export namespace DeleteScheduledReportResponse {
  export interface AsObject {
  }
}
export class DeleteScheduledReportResponse extends jspb.Message {
  static readonly displayName = "DeleteScheduledReportResponse";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): DeleteScheduledReportResponse.AsObject {
    return DeleteScheduledReportResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!DeleteScheduledReportResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: DeleteScheduledReportResponse) {
    var f: any;
    var obj: any = {

    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!DeleteScheduledReportResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new DeleteScheduledReportResponse;
    return DeleteScheduledReportResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!DeleteScheduledReportResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!DeleteScheduledReportResponse}
   */
  static deserializeBinaryFromReader(msg: DeleteScheduledReportResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!DeleteScheduledReportResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: DeleteScheduledReportResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    DeleteScheduledReportResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
}
export namespace ActivateScheduledReportRequest {
  export interface AsObject {
    id: number;
  }
}
export class ActivateScheduledReportRequest extends jspb.Message {
  static readonly displayName = "ActivateScheduledReportRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): ActivateScheduledReportRequest.AsObject {
    return ActivateScheduledReportRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!ActivateScheduledReportRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: ActivateScheduledReportRequest) {
    var f: any;
    var obj: any = {
      id: jspb.Message.getFieldWithDefault(msg, 1, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!ActivateScheduledReportRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new ActivateScheduledReportRequest;
    return ActivateScheduledReportRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!ActivateScheduledReportRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!ActivateScheduledReportRequest}
   */
  static deserializeBinaryFromReader(msg: ActivateScheduledReportRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {number} */ (reader.readInt32());
        msg.setId(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!ActivateScheduledReportRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: ActivateScheduledReportRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getId();
    if (f !== 0) {
      writer.writeInt32(
        1,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    ActivateScheduledReportRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional int32 id = 1;
   * @return {number}
   */
  getId(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
  };


  /** @param {number} value */
  setId(value: number) {
    jspb.Message.setProto3IntField(this, 1, value);
  }


}
export namespace ActivateScheduledReportResponse {
  export interface AsObject {
  }
}
export class ActivateScheduledReportResponse extends jspb.Message {
  static readonly displayName = "ActivateScheduledReportResponse";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): ActivateScheduledReportResponse.AsObject {
    return ActivateScheduledReportResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!ActivateScheduledReportResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: ActivateScheduledReportResponse) {
    var f: any;
    var obj: any = {

    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!ActivateScheduledReportResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new ActivateScheduledReportResponse;
    return ActivateScheduledReportResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!ActivateScheduledReportResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!ActivateScheduledReportResponse}
   */
  static deserializeBinaryFromReader(msg: ActivateScheduledReportResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!ActivateScheduledReportResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: ActivateScheduledReportResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    ActivateScheduledReportResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
}
export namespace DownloadPastReportRequest {
  export interface AsObject {
    id: number;
  }
}
export class DownloadPastReportRequest extends jspb.Message {
  static readonly displayName = "DownloadPastReportRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): DownloadPastReportRequest.AsObject {
    return DownloadPastReportRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!DownloadPastReportRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: DownloadPastReportRequest) {
    var f: any;
    var obj: any = {
      id: jspb.Message.getFieldWithDefault(msg, 1, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!DownloadPastReportRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new DownloadPastReportRequest;
    return DownloadPastReportRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!DownloadPastReportRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!DownloadPastReportRequest}
   */
  static deserializeBinaryFromReader(msg: DownloadPastReportRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {number} */ (reader.readInt32());
        msg.setId(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!DownloadPastReportRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: DownloadPastReportRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getId();
    if (f !== 0) {
      writer.writeInt32(
        1,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    DownloadPastReportRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional int32 id = 1;
   * @return {number}
   */
  getId(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
  };


  /** @param {number} value */
  setId(value: number) {
    jspb.Message.setProto3IntField(this, 1, value);
  }


}
export namespace DownloadPastReportResponse {
  export interface AsObject {
    signedUrl: string;
    filename: string;
  }
}
export class DownloadPastReportResponse extends jspb.Message {
  static readonly displayName = "DownloadPastReportResponse";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): DownloadPastReportResponse.AsObject {
    return DownloadPastReportResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!DownloadPastReportResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: DownloadPastReportResponse) {
    var f: any;
    var obj: any = {
      signedUrl: jspb.Message.getFieldWithDefault(msg, 1, ""),
      filename: jspb.Message.getFieldWithDefault(msg, 2, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!DownloadPastReportResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new DownloadPastReportResponse;
    return DownloadPastReportResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!DownloadPastReportResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!DownloadPastReportResponse}
   */
  static deserializeBinaryFromReader(msg: DownloadPastReportResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setSignedUrl(value1);
        break;
      case 2:
        var value2 = /** @type {string} */ (reader.readString());
        msg.setFilename(value2);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!DownloadPastReportResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: DownloadPastReportResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getSignedUrl();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
    f = message.getFilename();
    if (f.length > 0) {
      writer.writeString(
        2,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    DownloadPastReportResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string signed_url = 1;
   * @return {string}
   */
  getSignedUrl(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setSignedUrl(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


  /**
   * optional string filename = 2;
   * @return {string}
   */
  getFilename(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
  };


  /** @param {string} value */
  setFilename(value: string) {
    jspb.Message.setProto3StringField(this, 2, value);
  }


}
export namespace GetPastReportRequest {
  export interface AsObject {
    id: number;
  }
}
export class GetPastReportRequest extends jspb.Message {
  static readonly displayName = "GetPastReportRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): GetPastReportRequest.AsObject {
    return GetPastReportRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!GetPastReportRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: GetPastReportRequest) {
    var f: any;
    var obj: any = {
      id: jspb.Message.getFieldWithDefault(msg, 1, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!GetPastReportRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new GetPastReportRequest;
    return GetPastReportRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!GetPastReportRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!GetPastReportRequest}
   */
  static deserializeBinaryFromReader(msg: GetPastReportRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {number} */ (reader.readInt32());
        msg.setId(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!GetPastReportRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: GetPastReportRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getId();
    if (f !== 0) {
      writer.writeInt32(
        1,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    GetPastReportRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional int32 id = 1;
   * @return {number}
   */
  getId(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
  };


  /** @param {number} value */
  setId(value: number) {
    jspb.Message.setProto3IntField(this, 1, value);
  }


}
export namespace GetPastReportResponse {
  export interface AsObject {
    pastReport?: PastReport.AsObject;
  }
}
export class GetPastReportResponse extends jspb.Message {
  static readonly displayName = "GetPastReportResponse";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): GetPastReportResponse.AsObject {
    return GetPastReportResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!GetPastReportResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: GetPastReportResponse) {
    var f: any;
    var obj: any = {
      pastReport: (f = msg.getPastReport()) && PastReport.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!GetPastReportResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new GetPastReportResponse;
    return GetPastReportResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!GetPastReportResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!GetPastReportResponse}
   */
  static deserializeBinaryFromReader(msg: GetPastReportResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new PastReport;
        reader.readMessage(value1,PastReport.deserializeBinaryFromReader);
        msg.setPastReport(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!GetPastReportResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: GetPastReportResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getPastReport();
    if (f != null) {
      writer.writeMessage(
        1,
        f,
        PastReport.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    GetPastReportResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional PastReport past_report = 1;
   * @return {?PastReport}
   */
  getPastReport(): PastReport {
    return /** @type{?PastReport} */ (
      jspb.Message.getWrapperField(this, PastReport, 1));
  }


  /** @param {?PastReport|undefined} value */
  setPastReport(value?: PastReport) {
    jspb.Message.setWrapperField(this, 1, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearPastReport() {
    this.setPastReport(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasPastReport(): boolean {
    return jspb.Message.getField(this, 1) != null;
  }


}
export namespace PastReport {
  export interface AsObject {
    id: number;
    name: string;
    date?: common_date_pb.DateTime.AsObject;
    status: number;
    scheduledReportId: number;
  }
}
export class PastReport extends jspb.Message {
  static readonly displayName = "PastReport";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): PastReport.AsObject {
    return PastReport.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!PastReport} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: PastReport) {
    var f: any;
    var obj: any = {
      id: jspb.Message.getFieldWithDefault(msg, 1, 0),
      name: jspb.Message.getFieldWithDefault(msg, 2, ""),
      date: (f = msg.getDate()) && common_date_pb.DateTime.toObject(includeInstance, f),
      status: jspb.Message.getFieldWithDefault(msg, 4, 0),
      scheduledReportId: jspb.Message.getFieldWithDefault(msg, 5, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!PastReport}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new PastReport;
    return PastReport.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!PastReport} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!PastReport}
   */
  static deserializeBinaryFromReader(msg: PastReport, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {number} */ (reader.readInt32());
        msg.setId(value1);
        break;
      case 2:
        var value2 = /** @type {string} */ (reader.readString());
        msg.setName(value2);
        break;
      case 3:
        var value3 = new common_date_pb.DateTime;
        reader.readMessage(value3,common_date_pb.DateTime.deserializeBinaryFromReader);
        msg.setDate(value3);
        break;
      case 4:
        var value4 = /** @type {number} */ (reader.readInt32());
        msg.setStatus(value4);
        break;
      case 5:
        var value5 = /** @type {number} */ (reader.readInt32());
        msg.setScheduledReportId(value5);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!PastReport} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: PastReport, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getId();
    if (f !== 0) {
      writer.writeInt32(
        1,
        f
      );
    }
    f = message.getName();
    if (f.length > 0) {
      writer.writeString(
        2,
        f
      );
    }
    f = message.getDate();
    if (f != null) {
      writer.writeMessage(
        3,
        f,
        common_date_pb.DateTime.serializeBinaryToWriter
      );
    }
    f = message.getStatus();
    if (f !== 0) {
      writer.writeInt32(
        4,
        f
      );
    }
    f = message.getScheduledReportId();
    if (f !== 0) {
      writer.writeInt32(
        5,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    PastReport.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional int32 id = 1;
   * @return {number}
   */
  getId(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
  };


  /** @param {number} value */
  setId(value: number) {
    jspb.Message.setProto3IntField(this, 1, value);
  }


  /**
   * optional string name = 2;
   * @return {string}
   */
  getName(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
  };


  /** @param {string} value */
  setName(value: string) {
    jspb.Message.setProto3StringField(this, 2, value);
  }


  /**
   * optional minga.common.DateTime date = 3;
   * @return {?DateTime}
   */
  getDate(): common_date_pb.DateTime {
    return /** @type{?DateTime} */ (
      jspb.Message.getWrapperField(this, common_date_pb.DateTime, 3));
  }


  /** @param {?DateTime|undefined} value */
  setDate(value?: common_date_pb.DateTime) {
    jspb.Message.setWrapperField(this, 3, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearDate() {
    this.setDate(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasDate(): boolean {
    return jspb.Message.getField(this, 3) != null;
  }


  /**
   * optional int32 status = 4;
   * @return {number}
   */
  getStatus(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
  };


  /** @param {number} value */
  setStatus(value: number) {
    jspb.Message.setProto3IntField(this, 4, value);
  }


  /**
   * optional int32 scheduled_report_id = 5;
   * @return {number}
   */
  getScheduledReportId(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
  };


  /** @param {number} value */
  setScheduledReportId(value: number) {
    jspb.Message.setProto3IntField(this, 5, value);
  }


}
export namespace ListPastReportsRequest {
  export interface AsObject {
  }
}
export class ListPastReportsRequest extends jspb.Message {
  static readonly displayName = "ListPastReportsRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): ListPastReportsRequest.AsObject {
    return ListPastReportsRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!ListPastReportsRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: ListPastReportsRequest) {
    var f: any;
    var obj: any = {

    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!ListPastReportsRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new ListPastReportsRequest;
    return ListPastReportsRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!ListPastReportsRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!ListPastReportsRequest}
   */
  static deserializeBinaryFromReader(msg: ListPastReportsRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!ListPastReportsRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: ListPastReportsRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    ListPastReportsRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
}
export namespace ListPastReportsResponse {
  export interface AsObject {
    pastReportsList: PastReport.AsObject[];
  }
}
export class ListPastReportsResponse extends jspb.Message {
  static readonly displayName = "ListPastReportsResponse";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [1];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, ListPastReportsResponse.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): ListPastReportsResponse.AsObject {
    return ListPastReportsResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!ListPastReportsResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: ListPastReportsResponse) {
    var f: any;
    var obj: any = {
      pastReportsList: jspb.Message.toObjectList(msg.getPastReportsList(),
      PastReport.toObject, includeInstance)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!ListPastReportsResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new ListPastReportsResponse;
    return ListPastReportsResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!ListPastReportsResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!ListPastReportsResponse}
   */
  static deserializeBinaryFromReader(msg: ListPastReportsResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new PastReport;
        reader.readMessage(value1,PastReport.deserializeBinaryFromReader);
        msg.addPastReports(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!ListPastReportsResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: ListPastReportsResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getPastReportsList();
    if (f.length > 0) {
      writer.writeRepeatedMessage(
        1,
        f,
        PastReport.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    ListPastReportsResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * repeated PastReport past_reports = 1;
   * @return {!Array<!PastReport>}
   */
  getPastReportsList(): PastReport[] {
    return /** @type{!Array<!PastReport>} */ (
      jspb.Message.getRepeatedWrapperField(this, PastReport, 1));
  }


  /** @param {!Array<!PastReport>} value */
  setPastReportsList(value?: PastReport[]) {
    jspb.Message.setRepeatedWrapperField(this, 1, value);
  }


  /**
   * @param {!PastReport=} opt_value
   * @param {number=} opt_index
   * @return {!PastReport}
   */
  addPastReports(opt_value?: PastReport, opt_index?: number): PastReport {
    return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, PastReport, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearPastReportsList() {
    this.setPastReportsList([]);
  }


}
