import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { IHallPassStatsExpiredTimeResult } from 'libs/domain';
import { HpmReportsFilter } from 'libs/domain';
import { ReportTypes } from 'libs/domain';
import { TemplateColumnKeys, HallPassReportColumns } from 'libs/shared';

import { ReportDatasourceService } from '@app/src/app/components/manager-report/services/report-datasource.service';

import { HpmReportsService } from '../services';

@Injectable()
export class HpmReportsLatePassesService extends ReportDatasourceService<IHallPassStatsExpiredTimeResult> {
  historyInfo = {
    filter: HpmReportsFilter.ISSUED_TO,
    url: '/hall-pass/reports/' + ReportTypes.HALL_PASS_HISTORY,
  };
  summaryInfo = {
    filter: HpmReportsFilter.ISSUED_TO,
    url: '/hall-pass/reports/' + ReportTypes.HALL_PASS_SUMMARY,
  };

  /** Service Constructor */
  constructor(private _hpmReports: HpmReportsService, _router: Router) {
    super(_router, _hpmReports, HpmReportsLatePassesService.name);
    const displayCol = [
      {
        header: '',
        key: TemplateColumnKeys.SUMMARY,
        columnClasses: ['w-16'],
      },
      {
        header: '',
        key: TemplateColumnKeys.HISTORY,
        columnClasses: ['w-16'],
      },
    ];
    super.setDisplayColumns(HallPassReportColumns.OVERDUE, displayCol);
  }

  async fetch(offset: number, limit: number) {
    return await this._hpmReports.getLatePassesReport(
      offset,
      limit,
      this._currentSort,
    );
  }
}
