// source: gateway/post.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

import * as jspb from 'google-protobuf';
import * as common_legacy_pb from '../common/legacy_pb';
export namespace LikePostRequest {
  export interface AsObject {
    contextHash: string;
  }
}
export class LikePostRequest extends jspb.Message {
  static readonly displayName = "LikePostRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): LikePostRequest.AsObject {
    return LikePostRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!LikePostRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: LikePostRequest) {
    var f: any;
    var obj: any = {
      contextHash: jspb.Message.getFieldWithDefault(msg, 1, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!LikePostRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new LikePostRequest;
    return LikePostRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!LikePostRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!LikePostRequest}
   */
  static deserializeBinaryFromReader(msg: LikePostRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setContextHash(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!LikePostRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: LikePostRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getContextHash();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    LikePostRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string context_hash = 1;
   * @return {string}
   */
  getContextHash(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setContextHash(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


}
export namespace LikePostResponse {
  export interface AsObject {
    status?: common_legacy_pb.StatusCode;
    reason?: common_legacy_pb.StatusReason.AsObject;
  }
}
export class LikePostResponse extends jspb.Message {
  static readonly displayName = "LikePostResponse";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): LikePostResponse.AsObject {
    return LikePostResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!LikePostResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: LikePostResponse) {
    var f: any;
    var obj: any = {
      status: jspb.Message.getFieldWithDefault(msg, 1, 0),
      reason: (f = msg.getReason()) && common_legacy_pb.StatusReason.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!LikePostResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new LikePostResponse;
    return LikePostResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!LikePostResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!LikePostResponse}
   */
  static deserializeBinaryFromReader(msg: LikePostResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {!StatusCode} */ (reader.readEnum());
        msg.setStatus(value1);
        break;
      case 2:
        var value2 = new common_legacy_pb.StatusReason;
        reader.readMessage(value2,common_legacy_pb.StatusReason.deserializeBinaryFromReader);
        msg.setReason(value2);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!LikePostResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: LikePostResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getStatus();
    if (f !== 0.0) {
      writer.writeEnum(
        1,
        f
      );
    }
    f = message.getReason();
    if (f != null) {
      writer.writeMessage(
        2,
        f,
        common_legacy_pb.StatusReason.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    LikePostResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional StatusCode status = 1;
   * @return {!StatusCode}
   */
  getStatus(): common_legacy_pb.StatusCode {
    return /** @type {!StatusCode} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
  };


  /** @param {!StatusCode} value */
  setStatus(value: common_legacy_pb.StatusCode) {
    jspb.Message.setProto3EnumField(this, 1, value);
  }


  /**
   * optional StatusReason reason = 2;
   * @return {?StatusReason}
   */
  getReason(): common_legacy_pb.StatusReason {
    return /** @type{?StatusReason} */ (
      jspb.Message.getWrapperField(this, common_legacy_pb.StatusReason, 2));
  }


  /** @param {?StatusReason|undefined} value */
  setReason(value?: common_legacy_pb.StatusReason) {
    jspb.Message.setWrapperField(this, 2, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearReason() {
    this.setReason(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasReason(): boolean {
    return jspb.Message.getField(this, 2) != null;
  }


}
export namespace UnlikePostRequest {
  export interface AsObject {
    contextHash: string;
  }
}
export class UnlikePostRequest extends jspb.Message {
  static readonly displayName = "UnlikePostRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): UnlikePostRequest.AsObject {
    return UnlikePostRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!UnlikePostRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: UnlikePostRequest) {
    var f: any;
    var obj: any = {
      contextHash: jspb.Message.getFieldWithDefault(msg, 1, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!UnlikePostRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new UnlikePostRequest;
    return UnlikePostRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!UnlikePostRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!UnlikePostRequest}
   */
  static deserializeBinaryFromReader(msg: UnlikePostRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setContextHash(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!UnlikePostRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: UnlikePostRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getContextHash();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    UnlikePostRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string context_hash = 1;
   * @return {string}
   */
  getContextHash(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setContextHash(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


}
export namespace UnlikePostResponse {
  export interface AsObject {
    status?: common_legacy_pb.StatusCode;
    reason?: common_legacy_pb.StatusReason.AsObject;
  }
}
export class UnlikePostResponse extends jspb.Message {
  static readonly displayName = "UnlikePostResponse";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): UnlikePostResponse.AsObject {
    return UnlikePostResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!UnlikePostResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: UnlikePostResponse) {
    var f: any;
    var obj: any = {
      status: jspb.Message.getFieldWithDefault(msg, 1, 0),
      reason: (f = msg.getReason()) && common_legacy_pb.StatusReason.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!UnlikePostResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new UnlikePostResponse;
    return UnlikePostResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!UnlikePostResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!UnlikePostResponse}
   */
  static deserializeBinaryFromReader(msg: UnlikePostResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {!StatusCode} */ (reader.readEnum());
        msg.setStatus(value1);
        break;
      case 2:
        var value2 = new common_legacy_pb.StatusReason;
        reader.readMessage(value2,common_legacy_pb.StatusReason.deserializeBinaryFromReader);
        msg.setReason(value2);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!UnlikePostResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: UnlikePostResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getStatus();
    if (f !== 0.0) {
      writer.writeEnum(
        1,
        f
      );
    }
    f = message.getReason();
    if (f != null) {
      writer.writeMessage(
        2,
        f,
        common_legacy_pb.StatusReason.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    UnlikePostResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional StatusCode status = 1;
   * @return {!StatusCode}
   */
  getStatus(): common_legacy_pb.StatusCode {
    return /** @type {!StatusCode} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
  };


  /** @param {!StatusCode} value */
  setStatus(value: common_legacy_pb.StatusCode) {
    jspb.Message.setProto3EnumField(this, 1, value);
  }


  /**
   * optional StatusReason reason = 2;
   * @return {?StatusReason}
   */
  getReason(): common_legacy_pb.StatusReason {
    return /** @type{?StatusReason} */ (
      jspb.Message.getWrapperField(this, common_legacy_pb.StatusReason, 2));
  }


  /** @param {?StatusReason|undefined} value */
  setReason(value?: common_legacy_pb.StatusReason) {
    jspb.Message.setWrapperField(this, 2, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearReason() {
    this.setReason(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasReason(): boolean {
    return jspb.Message.getField(this, 2) != null;
  }


}
export namespace GoToEventRequest {
  export interface AsObject {
    contextHash: string;
  }
}
export class GoToEventRequest extends jspb.Message {
  static readonly displayName = "GoToEventRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): GoToEventRequest.AsObject {
    return GoToEventRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!GoToEventRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: GoToEventRequest) {
    var f: any;
    var obj: any = {
      contextHash: jspb.Message.getFieldWithDefault(msg, 1, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!GoToEventRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new GoToEventRequest;
    return GoToEventRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!GoToEventRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!GoToEventRequest}
   */
  static deserializeBinaryFromReader(msg: GoToEventRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setContextHash(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!GoToEventRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: GoToEventRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getContextHash();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    GoToEventRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string context_hash = 1;
   * @return {string}
   */
  getContextHash(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setContextHash(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


}
export namespace GoToEventResponse {
  export interface AsObject {
    status?: common_legacy_pb.StatusCode;
    reason?: common_legacy_pb.StatusReason.AsObject;
  }
}
export class GoToEventResponse extends jspb.Message {
  static readonly displayName = "GoToEventResponse";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): GoToEventResponse.AsObject {
    return GoToEventResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!GoToEventResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: GoToEventResponse) {
    var f: any;
    var obj: any = {
      status: jspb.Message.getFieldWithDefault(msg, 1, 0),
      reason: (f = msg.getReason()) && common_legacy_pb.StatusReason.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!GoToEventResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new GoToEventResponse;
    return GoToEventResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!GoToEventResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!GoToEventResponse}
   */
  static deserializeBinaryFromReader(msg: GoToEventResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {!StatusCode} */ (reader.readEnum());
        msg.setStatus(value1);
        break;
      case 2:
        var value2 = new common_legacy_pb.StatusReason;
        reader.readMessage(value2,common_legacy_pb.StatusReason.deserializeBinaryFromReader);
        msg.setReason(value2);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!GoToEventResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: GoToEventResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getStatus();
    if (f !== 0.0) {
      writer.writeEnum(
        1,
        f
      );
    }
    f = message.getReason();
    if (f != null) {
      writer.writeMessage(
        2,
        f,
        common_legacy_pb.StatusReason.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    GoToEventResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional StatusCode status = 1;
   * @return {!StatusCode}
   */
  getStatus(): common_legacy_pb.StatusCode {
    return /** @type {!StatusCode} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
  };


  /** @param {!StatusCode} value */
  setStatus(value: common_legacy_pb.StatusCode) {
    jspb.Message.setProto3EnumField(this, 1, value);
  }


  /**
   * optional StatusReason reason = 2;
   * @return {?StatusReason}
   */
  getReason(): common_legacy_pb.StatusReason {
    return /** @type{?StatusReason} */ (
      jspb.Message.getWrapperField(this, common_legacy_pb.StatusReason, 2));
  }


  /** @param {?StatusReason|undefined} value */
  setReason(value?: common_legacy_pb.StatusReason) {
    jspb.Message.setWrapperField(this, 2, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearReason() {
    this.setReason(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasReason(): boolean {
    return jspb.Message.getField(this, 2) != null;
  }


}
export namespace LeaveEventRequest {
  export interface AsObject {
    contextHash: string;
  }
}
export class LeaveEventRequest extends jspb.Message {
  static readonly displayName = "LeaveEventRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): LeaveEventRequest.AsObject {
    return LeaveEventRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!LeaveEventRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: LeaveEventRequest) {
    var f: any;
    var obj: any = {
      contextHash: jspb.Message.getFieldWithDefault(msg, 1, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!LeaveEventRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new LeaveEventRequest;
    return LeaveEventRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!LeaveEventRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!LeaveEventRequest}
   */
  static deserializeBinaryFromReader(msg: LeaveEventRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setContextHash(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!LeaveEventRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: LeaveEventRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getContextHash();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    LeaveEventRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string context_hash = 1;
   * @return {string}
   */
  getContextHash(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setContextHash(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


}
export namespace LeaveEventResponse {
  export interface AsObject {
    status?: common_legacy_pb.StatusCode;
    reason?: common_legacy_pb.StatusReason.AsObject;
  }
}
export class LeaveEventResponse extends jspb.Message {
  static readonly displayName = "LeaveEventResponse";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): LeaveEventResponse.AsObject {
    return LeaveEventResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!LeaveEventResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: LeaveEventResponse) {
    var f: any;
    var obj: any = {
      status: jspb.Message.getFieldWithDefault(msg, 1, 0),
      reason: (f = msg.getReason()) && common_legacy_pb.StatusReason.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!LeaveEventResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new LeaveEventResponse;
    return LeaveEventResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!LeaveEventResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!LeaveEventResponse}
   */
  static deserializeBinaryFromReader(msg: LeaveEventResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {!StatusCode} */ (reader.readEnum());
        msg.setStatus(value1);
        break;
      case 2:
        var value2 = new common_legacy_pb.StatusReason;
        reader.readMessage(value2,common_legacy_pb.StatusReason.deserializeBinaryFromReader);
        msg.setReason(value2);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!LeaveEventResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: LeaveEventResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getStatus();
    if (f !== 0.0) {
      writer.writeEnum(
        1,
        f
      );
    }
    f = message.getReason();
    if (f != null) {
      writer.writeMessage(
        2,
        f,
        common_legacy_pb.StatusReason.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    LeaveEventResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional StatusCode status = 1;
   * @return {!StatusCode}
   */
  getStatus(): common_legacy_pb.StatusCode {
    return /** @type {!StatusCode} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
  };


  /** @param {!StatusCode} value */
  setStatus(value: common_legacy_pb.StatusCode) {
    jspb.Message.setProto3EnumField(this, 1, value);
  }


  /**
   * optional StatusReason reason = 2;
   * @return {?StatusReason}
   */
  getReason(): common_legacy_pb.StatusReason {
    return /** @type{?StatusReason} */ (
      jspb.Message.getWrapperField(this, common_legacy_pb.StatusReason, 2));
  }


  /** @param {?StatusReason|undefined} value */
  setReason(value?: common_legacy_pb.StatusReason) {
    jspb.Message.setWrapperField(this, 2, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearReason() {
    this.setReason(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasReason(): boolean {
    return jspb.Message.getField(this, 2) != null;
  }


}
export namespace CheckInToEventRequest {
  export interface AsObject {
    contextHash: string;
    code: string;
    status: string;
  }
}
export class CheckInToEventRequest extends jspb.Message {
  static readonly displayName = "CheckInToEventRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): CheckInToEventRequest.AsObject {
    return CheckInToEventRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!CheckInToEventRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: CheckInToEventRequest) {
    var f: any;
    var obj: any = {
      contextHash: jspb.Message.getFieldWithDefault(msg, 1, ""),
      code: jspb.Message.getFieldWithDefault(msg, 2, ""),
      status: jspb.Message.getFieldWithDefault(msg, 3, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!CheckInToEventRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new CheckInToEventRequest;
    return CheckInToEventRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!CheckInToEventRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!CheckInToEventRequest}
   */
  static deserializeBinaryFromReader(msg: CheckInToEventRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setContextHash(value1);
        break;
      case 2:
        var value2 = /** @type {string} */ (reader.readString());
        msg.setCode(value2);
        break;
      case 3:
        var value3 = /** @type {string} */ (reader.readString());
        msg.setStatus(value3);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!CheckInToEventRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: CheckInToEventRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getContextHash();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
    f = message.getCode();
    if (f.length > 0) {
      writer.writeString(
        2,
        f
      );
    }
    f = message.getStatus();
    if (f.length > 0) {
      writer.writeString(
        3,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    CheckInToEventRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string context_hash = 1;
   * @return {string}
   */
  getContextHash(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setContextHash(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


  /**
   * optional string code = 2;
   * @return {string}
   */
  getCode(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
  };


  /** @param {string} value */
  setCode(value: string) {
    jspb.Message.setProto3StringField(this, 2, value);
  }


  /**
   * optional string status = 3;
   * @return {string}
   */
  getStatus(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
  };


  /** @param {string} value */
  setStatus(value: string) {
    jspb.Message.setProto3StringField(this, 3, value);
  }


}
export namespace CheckInToEventResponse {
  export interface AsObject {
    status?: common_legacy_pb.StatusCode;
    reason?: common_legacy_pb.StatusReason.AsObject;
    correctCode: boolean;
    errorMessage: string;
  }
}
export class CheckInToEventResponse extends jspb.Message {
  static readonly displayName = "CheckInToEventResponse";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): CheckInToEventResponse.AsObject {
    return CheckInToEventResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!CheckInToEventResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: CheckInToEventResponse) {
    var f: any;
    var obj: any = {
      status: jspb.Message.getFieldWithDefault(msg, 1, 0),
      reason: (f = msg.getReason()) && common_legacy_pb.StatusReason.toObject(includeInstance, f),
      correctCode: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
      errorMessage: jspb.Message.getFieldWithDefault(msg, 4, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!CheckInToEventResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new CheckInToEventResponse;
    return CheckInToEventResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!CheckInToEventResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!CheckInToEventResponse}
   */
  static deserializeBinaryFromReader(msg: CheckInToEventResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {!StatusCode} */ (reader.readEnum());
        msg.setStatus(value1);
        break;
      case 2:
        var value2 = new common_legacy_pb.StatusReason;
        reader.readMessage(value2,common_legacy_pb.StatusReason.deserializeBinaryFromReader);
        msg.setReason(value2);
        break;
      case 3:
        var value3 = /** @type {boolean} */ (reader.readBool());
        msg.setCorrectCode(value3);
        break;
      case 4:
        var value4 = /** @type {string} */ (reader.readString());
        msg.setErrorMessage(value4);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!CheckInToEventResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: CheckInToEventResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getStatus();
    if (f !== 0.0) {
      writer.writeEnum(
        1,
        f
      );
    }
    f = message.getReason();
    if (f != null) {
      writer.writeMessage(
        2,
        f,
        common_legacy_pb.StatusReason.serializeBinaryToWriter
      );
    }
    f = message.getCorrectCode();
    if (f) {
      writer.writeBool(
        3,
        f
      );
    }
    f = message.getErrorMessage();
    if (f.length > 0) {
      writer.writeString(
        4,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    CheckInToEventResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional StatusCode status = 1;
   * @return {!StatusCode}
   */
  getStatus(): common_legacy_pb.StatusCode {
    return /** @type {!StatusCode} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
  };


  /** @param {!StatusCode} value */
  setStatus(value: common_legacy_pb.StatusCode) {
    jspb.Message.setProto3EnumField(this, 1, value);
  }


  /**
   * optional StatusReason reason = 2;
   * @return {?StatusReason}
   */
  getReason(): common_legacy_pb.StatusReason {
    return /** @type{?StatusReason} */ (
      jspb.Message.getWrapperField(this, common_legacy_pb.StatusReason, 2));
  }


  /** @param {?StatusReason|undefined} value */
  setReason(value?: common_legacy_pb.StatusReason) {
    jspb.Message.setWrapperField(this, 2, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearReason() {
    this.setReason(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasReason(): boolean {
    return jspb.Message.getField(this, 2) != null;
  }


  /**
   * optional bool correct_code = 3;
   * @return {boolean}
   */
  getCorrectCode(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
  };


  /** @param {boolean} value */
  setCorrectCode(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 3, value);
  }


  /**
   * optional string error_message = 4;
   * @return {string}
   */
  getErrorMessage(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
  };


  /** @param {string} value */
  setErrorMessage(value: string) {
    jspb.Message.setProto3StringField(this, 4, value);
  }


}
