import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { gateway } from 'libs/generated-grpc-web';
import { PersonViewMinimal } from 'libs/generated-grpc-web';

@Component({
  selector: 'mg-person-notification',
  templateUrl: './MgPersonNotification.element.html',
  styleUrls: ['./MgPersonNotification.element.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MgPersonNotificationElement {
  @Input()
  notification?: gateway.notification_pb.Notification.AsObject;

  get notificationPersonDisplay(): gateway.notification_pb.Notification.DisplayPerson.AsObject | null {
    if (this.notification) {
      return this.notification.displayPerson || null;
    }

    return null;
  }

  /**
   * Highlighted person in notification
   */
  get person(): PersonViewMinimal.AsObject | null {
    if (this.notificationPersonDisplay) {
      return this.notificationPersonDisplay.person || null;
    }

    return null;
  }

  /**
   * Highlighted person in notification
   */
  get otherPerson(): PersonViewMinimal.AsObject | null {
    if (this.notificationPersonDisplay) {
      return this.notificationPersonDisplay.otherPerson || null;
    }

    return null;
  }

  /**
   * Other persons hashes
   */
  get others(): string[] {
    if (this.notificationPersonDisplay) {
      return this.notificationPersonDisplay.othersList || [];
    }

    return [];
  }

  get personProfileImage(): string {
    if (this.person) {
      return this.person.profileImageUrl || '';
    }

    return '';
  }

  boldPersonNames(strippedTitle: string): string {
    let title = strippedTitle;
    let displayNames: any = {};

    if (this.person) {
      displayNames[this.person.displayName] = true;
    }

    if (this.otherPerson) {
      displayNames[this.otherPerson.displayName] = true;
    }

    for (let displayName of Object.keys(displayNames)) {
      title = title.split(displayName).join(`<strong>${displayName}</strong>`);
    }

    return title;
  }
}
