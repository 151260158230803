import { Injectable, OnDestroy } from '@angular/core';

import { ICheckinReason, ICheckinReasonWithCounts } from 'libs/domain';
import { BehaviorSubject, NEVER, ReplaySubject, timer } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';

import { UserStorage } from '@app/src/app/services/UserStorage';

import { SystemAlertSnackBarService } from '@shared/components/system-alert-snackbar';
import { CheckinService } from '@shared/services/checkin';

import { AUTO_REFRESH_STATE, POLLING_INTERVAL } from '../constants';

@Injectable()
export class CheckinManagerDashboardService implements OnDestroy {
  /** Behavior Subjects & Observables */
  private readonly _isLoadingSubj = new BehaviorSubject<boolean>(false);
  public readonly isLoading$ = this._isLoadingSubj.asObservable();

  private readonly _checkinReasonsSubj = new BehaviorSubject<ICheckinReason[]>(
    [],
  );
  public readonly checkinReasons$ = this._checkinReasonsSubj.asObservable();

  private readonly _fetchReasonsSubj = new BehaviorSubject<void>(undefined);

  private readonly _pollStateSubj = new BehaviorSubject<boolean>(false);
  public readonly pollState$ = this._pollStateSubj.asObservable();

  private readonly _destroyedSubj = new ReplaySubject<void>(1);

  constructor(
    private _systemAlertSnackBar: SystemAlertSnackBarService,
    private _checkinService: CheckinService,
    private _userStorage: UserStorage,
  ) {
    this._initPolling();
    this._fetchReasonsSubj
      .asObservable()
      .pipe(takeUntil(this._destroyedSubj))
      .subscribe(async () => await this._fetchReasons());
    this.pollState$
      .pipe(
        takeUntil(this._destroyedSubj),
        switchMap(isEnabled => {
          return isEnabled ? timer(0, POLLING_INTERVAL) : NEVER;
        }),
      )
      .subscribe(() => this._fetchReasonsSubj.next());
  }

  ngOnDestroy(): void {
    this._isLoadingSubj.complete();
    this._checkinReasonsSubj.complete();
    this._fetchReasonsSubj.complete();
    this._pollStateSubj.complete();

    this._destroyedSubj.next();
    this._destroyedSubj.complete();
  }

  public refreshReasons() {
    this._fetchReasonsSubj.next();
  }

  public async togglePollingState() {
    const newPollingState = !this._pollStateSubj.value;
    this._systemAlertSnackBar.success(
      `Auto refresh ${newPollingState ? 'enabled' : 'disabled'}`,
    );
    this._pollStateSubj.next(newPollingState);
    await this._userStorage.setItem(AUTO_REFRESH_STATE, newPollingState);
  }

  private async _fetchReasons(): Promise<void> {
    try {
      this._isLoadingSubj.next(true);
      const reasons = await this._listReasons();
      const sortedReasons = reasons.sort((a, b) => {
        return (b.totalCheckinCount ?? 0) - (a.totalCheckinCount ?? 0);
      });
      this._checkinReasonsSubj.next(sortedReasons);
      this._isLoadingSubj.next(false);
    } catch (e) {
      this._systemAlertSnackBar.error('failed to fetch data: ' + e);
    }
  }

  private async _listReasons(): Promise<ICheckinReasonWithCounts[]> {
    return await this._checkinService.getReasons(true, true);
  }

  private async _initPolling() {
    try {
      const isPollingEnabled: boolean = await this._userStorage.getItem(
        AUTO_REFRESH_STATE,
      );
      this._pollStateSubj.next(isPollingEnabled);
    } catch (e) {
      this._systemAlertSnackBar.error('failed to init auto polling ' + e);
    }
  }
}
