import { gateway } from 'libs/generated-grpc-web';

export interface IPeopleManagerNavigationTab {
  title: string;
  path: string;
}

export type PersonSearchItem =
  gateway.people_pb.MingaPeopleSearchResult.Item.AsObject;

export enum PeopleRoute {
  ROOT = 'people',
  USER_LISTS = 'user-lists',
  ROSTERING = 'rostering',
  VIEW_PROFILE = 'profile/:hash',
}
