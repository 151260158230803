import { IHallPassErrorType } from 'libs/domain';

export enum KioskHallPassMessage {
  TITLE_ACTIVE_PASS = 'You have one active hall pass',
  TITLE_OVERDUE_PASS = 'You have one overdue hall pass',
  TITLE_PASS_CREATED = 'Hall pass created',
  TITLE_PASS_ENDED = 'Hall pass ended',
  BUTTON_END_PASS = 'End hall pass',

  ERROR_GENERIC_HALL_PASS_TITLE = 'Hall pass request denied',
  ERROR_GENERIC_HALL_PASS_SUB_MESSAGE = 'Go see your staff member to be assigned a hall pass manually',

  SHOW_TABLE_TOGGLE_LABEL = 'Show active hall passes',
}

export enum KioskSummaryMessage {
  BUTTON_DONE = 'Done',
}

export const CREATE_HALL_PASS_ERROR_MESSAGE_MAP = {
  [IHallPassErrorType.MAX_PASSES]: `You've reached your hall pass daily limit`,
  [IHallPassErrorType.NO_PARTY]: `CODE: {errorCode}`,
  [IHallPassErrorType.NO_PASS]: `CODE: {errorCode}`,
  [IHallPassErrorType.MAX_ACTIVE_PASSES]: `CODE: {errorCode}`,
  [IHallPassErrorType.MAX_HALL_PASS_TYPE_PASSES]: `CODE: {errorCode}`,
  [IHallPassErrorType.NOT_IN_HALL_PASS_TYPE_LIST]: `CODE: {errorCode}`,
  [IHallPassErrorType.BLACK_OUT_WINDOW]: `You're attempting to create a hall pass during a restricted time period`,
  [IHallPassErrorType.NOT_PAST_MIN_WAIT_PERIOD]: `CODE: {errorCode}`,
  [IHallPassErrorType.HALL_PASS_ACTIVE]: `CODE: {errorCode}`,
  [IHallPassErrorType.MAX_TYPE_PASSES]: `You've reached your daily limit for {passName}`,
  [IHallPassErrorType.HALL_PASS_NOT_ENABLED]: `CODE: {errorCode}`,
  [IHallPassErrorType.NOT_AVAILABLE_IN_KIOSK]: `Hall pass is not available in kiosk`,
  [IHallPassErrorType.HALL_PASS_TYPE_NOT_ACTIVE]: `This hall pass is disabled`,
} as Record<IHallPassErrorType, string>;

export enum KioskHallPassTableMessage {
  COLUMN_LABEL_STATUS = 'Status',
  COLUMN_LABEL_TIMER = 'Timer',
  COLUMN_LABEL_NAME = 'Name',
  COLUMN_LABEL_PASS_TYPE = 'Pass',
  COLUMN_LABEL_START_TIME = 'Start',

  MOBILE_LABEL_START_TIME = 'Start Time',

  SETTING_ENABLED_MESSAGE = 'Show active hall passes enabled',
  SETTING_DISABLED_MESSAGE = 'Show active hall passes disabled',
}

export enum KioskHallPassTableColumn {
  INDICATOR = 'indicator',
  STATUS = 'status',
  TIMER = 'timer',
  RECIPIENT_PICTURE = 'recipientPicture',
  RECIPIENT_NAME = 'recipientName',
  PASS_TYPE = 'passType',
  START_TIME = 'startTime',
  MOBILE = 'mobile',
}

export const KIOSK_HALL_PASS_TABLE_COLUMNS: string[] = [
  KioskHallPassTableColumn.INDICATOR,
  KioskHallPassTableColumn.STATUS,
  KioskHallPassTableColumn.TIMER,
  KioskHallPassTableColumn.RECIPIENT_PICTURE,
  KioskHallPassTableColumn.RECIPIENT_NAME,
  KioskHallPassTableColumn.PASS_TYPE,
  KioskHallPassTableColumn.START_TIME,
];
