// GENERATED CODE -- DO NOT EDIT!

import {grpc} from '@improbable-eng/grpc-web';

import * as avatar_avatar_pb from '../avatar/avatar_pb';

export class AvatarCollection {
  static readonly serviceName = "minga.AvatarCollection";

  static readonly GetAvatars = {
    methodName: "GetAvatars",
    service: AvatarCollection,
    requestStream: false,
    responseStream: false,
    requestType: avatar_avatar_pb.AvatarCollectionGetAvatarsRequest,
    responseType: avatar_avatar_pb.AvatarCollectionGetAvatarsResponse,
  };

}

export class AvatarCollectionClient {
  getAvatars() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
}
