import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Router } from '@angular/router';

import { Store } from '@ngrx/store';
import { ReplaySubject, Subscription, timer } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { selectAuthenticated } from '@app/src/app/store/AuthStore/selectors';

import { LandingService } from '../../services';

@Component({
  selector: 'mg-landing-joining',
  templateUrl: './landing-joining.component.html',
  styleUrls: ['./landing-joining.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LandingJoiningComponent implements OnInit, OnDestroy {
  // Clean up

  private readonly _destroyedSubject = new ReplaySubject<void>(1);

  // Subscriptions

  private _authSub?: Subscription;

  // State

  personName: string;
  mingaName: string;
  mingaLogo: string;

  /** Component constructor */
  constructor(
    public landing: LandingService,
    private _router: Router,
    private _store$: Store<any>,
  ) {}

  ngOnInit(): void {
    const authNav = (authenticated: boolean) => {
      if (authenticated) {
        this._router.navigate(['/']);
      }
    };
    this._authSub = this._store$
      .select(selectAuthenticated)
      .pipe(takeUntil(timer(1500)))
      .subscribe(authNav);
  }

  ngOnDestroy(): void {
    this._destroyedSubject.next();
    this._destroyedSubject.complete();
    if (this._authSub) this._authSub?.unsubscribe();
  }
}
