import { Injectable } from '@angular/core';

import { ScheduledReport, ScheduledReportMinimal } from 'libs/domain';
import { stats_report_pb as stats_report } from 'libs/generated-grpc-web';
import { stats_report_ng_grpc_pb } from 'libs/generated-grpc-web';
import {
  ScheduledReportMapper as ScheduledMappers,
  ScheduledReportMinimalMapper as ScheduledMinimalMappers,
} from 'libs/shared-grpc';

import { ErrorHandlerService } from '@shared/services/error-handler';

@Injectable({ providedIn: 'root' })
export class ScheduledReportsService {
  /** Service Constructor */
  constructor(
    private _errorHandler: ErrorHandlerService,
    private _statsManager: stats_report_ng_grpc_pb.StatsReportManager,
  ) {}

  public async get(id: number): Promise<ScheduledReport> {
    try {
      const request = new stats_report.GetScheduledReportRequest();
      request.setId(id);
      const response = await this._statsManager.getScheduledReport(request);
      return ScheduledMappers.fromProto(response.getScheduledReport());
    } catch (error) {
      throw this._errorHandler.gateWayError(
        `Failed to fetch scheduled report`,
        error,
        true,
      );
    }
  }
  public async getAll(): Promise<ScheduledReportMinimal[]> {
    try {
      const request = new stats_report.ListScheduledReportsRequest();
      const response = await this._statsManager.listScheduledReports(request);
      return response
        .getScheduledReportsList()
        .map(ScheduledMinimalMappers.fromProto);
    } catch (error) {
      throw this._errorHandler.gateWayError(
        `Failed to fetch scheduled reports`,
        error,
        true,
      );
    }
  }

  public async create(
    scheduledReport: ScheduledReport,
  ): Promise<ScheduledReport> {
    try {
      const report = await this._upsert(scheduledReport);
      return report;
    } catch (error) {
      console.log('create backend', error);
      throw this._errorHandler.gateWayError(
        'Failed to create scheduled report',
        error,
        true,
      );
    }
  }

  public async update(
    scheduledReport: ScheduledReport,
  ): Promise<ScheduledReport> {
    try {
      const report = await this._upsert(scheduledReport);
      return report;
    } catch (error) {
      throw this._errorHandler.gateWayError(
        'Failed to update scheduled report',
        error,
        true,
      );
    }
  }

  public async delete(id: number): Promise<void> {
    try {
      const request = new stats_report.DeleteScheduledReportRequest();
      request.setId(id);
      await this._statsManager.deleteScheduledReport(request);
    } catch (error) {
      throw this._errorHandler.gateWayError(
        'Failed to delete scheduled report',
        error,
        true,
      );
    }
  }

  public async toggleActive(id: number): Promise<void> {
    try {
      const request = new stats_report.ActivateScheduledReportRequest();
      request.setId(id);
      await this._statsManager.activateScheduledReport(request);
    } catch (error) {
      throw this._errorHandler.gateWayError(
        'Failed to update active status for scheduled report',
        error,
        true,
      );
    }
  }

  private async _upsert(
    scheduledReport: ScheduledReport,
  ): Promise<ScheduledReport> {
    const request = new stats_report.UpsertScheduledReportRequest();
    request.setScheduledReport(ScheduledMappers.toProto(scheduledReport));
    const response = await this._statsManager.upsertScheduledReport(request);
    return ScheduledMappers.fromProto(response.getScheduledReport());
  }
}
