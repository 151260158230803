import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';

import { MgIconModule } from '@app/src/app/icon';

import { TooltipComponent } from './tooltip.component';

@NgModule({
  imports: [
    // Minga Dependencies
    MgIconModule,

    // External Dependencies
    CommonModule,
    MatTooltipModule,
  ],
  declarations: [TooltipComponent],
  exports: [TooltipComponent],
})
export class TooltipModule {}
