import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { day } from 'libs/day';
import { gateway, content_pb } from 'libs/generated-grpc-web';

@Component({
  selector: 'mg-debug-content-dialog',
  templateUrl: './DebugContentDialog.component.html',
  styleUrls: ['./DebugContentDialog.component.scss'],
})
export class DebugContentDialogComponent {
  contentMetadata?: content_pb.ContentMetadata.AsObject;
  contentContextHash: string = '';
  republishing: boolean = false;

  constructor(
    private dialogRef: MatDialogRef<DebugContentDialogComponent>,
    private publisher: gateway.publisher_ng_grpc_pb.Publisher,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.contentMetadata = data.contentMetadata;
    this.contentContextHash = data.contentContextHash;
  }

  formatTimestamp(timestamp: number) {
    if (!timestamp) {
      return '--';
    }

    return day(timestamp).format('MMM Do @ h:mm a');
  }

  private async _republish() {
    const request = new gateway.publisher_pb.RepublishRequest();
    if (this.contentMetadata) {
      request.setHash(this.contentMetadata.contentHash);
    }

    await this.publisher.republish(request).toPromise();
  }

  private async _republishOne() {
    const request = new gateway.publisher_pb.RepublishRequest();
    request.setHash(this.contentContextHash);

    await this.publisher.republish(request).toPromise();
  }

  async republish() {
    if (this.republishing) return;

    this.republishing = true;

    await this._republish().catch(err => {
      this.republishing = false;
      throw err;
    });

    this.republishing = false;
  }

  async republishOne() {
    if (this.republishing) return;

    this.republishing = true;

    await this._republishOne().catch(err => {
      this.republishing = false;
      throw err;
    });

    this.republishing = false;
  }
}
