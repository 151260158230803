import { Injectable } from '@angular/core';

import { gateway, legacy_pb } from 'libs/generated-grpc-web';

import { AnalyticsService } from '@app/src/app/minimal/services/Analytics';

@Injectable({ providedIn: 'root' })
export class ErrorReporterService {
  constructor(
    private errorReporter: gateway.connect_ng_grpc_pb.ErrorReporter,
    private analytics: AnalyticsService,
  ) {}

  async sendErrorReport(
    context: string,
    message: string,
    priority: number = 0,
  ) {
    let req: gateway.connect_pb.ErrorReportRequest =
      new gateway.connect_pb.ErrorReportRequest();
    let res: gateway.connect_pb.ErrorReportResponse =
      new gateway.connect_pb.ErrorReportResponse();
    console.log('GRPCErrorReportRequest: ', req);
    console.log('GRPCErrorReportResponse: ', res);

    this.analytics.sendException(`[${context}]: ${message}`, priority);

    req.setErrorPriority(priority);
    req.setErrorMessage(message);
    req.setErrorContext(context);

    res = await this.errorReporter.sendErrorReport(req).catch(err => {
      console.error('[ErrorReporter] sendErrorReport error', err);
      let response = new gateway.connect_pb.ErrorReportResponse();
      response.setStatus(legacy_pb.StatusCode.ERROR);
      return response;
    });
    console.log('Error Response Status:', res.getStatus());
    console.log(
      'Error Response error_handler_override:',
      res.getErrorHandlerOverride(),
    );
  }
}
