// GENERATED CODE -- DO NOT EDIT!

import {grpc} from '@improbable-eng/grpc-web';

import * as registration_registration_pb from '../registration/registration_pb';
import * as common_date_pb from '../common/date_pb';
import * as flex_time_flex_time_pb from '../flex_time/flex_time_pb';
import * as restriction_restriction_pb from '../restriction/restriction_pb';
import * as people_collection_people_collection_pb from '../people_collection/people_collection_pb';

export class RegistrationManager {
  static readonly serviceName = "registration.RegistrationManager";

  static readonly Register = {
    methodName: "Register",
    service: RegistrationManager,
    requestStream: false,
    responseStream: false,
    requestType: registration_registration_pb.RegisterRequest,
    responseType: registration_registration_pb.RegisterResponse,
  };

  static readonly Assign = {
    methodName: "Assign",
    service: RegistrationManager,
    requestStream: false,
    responseStream: false,
    requestType: registration_registration_pb.AssignRequest,
    responseType: registration_registration_pb.AssignResponse,
  };

  static readonly GetMyRegistrations = {
    methodName: "GetMyRegistrations",
    service: RegistrationManager,
    requestStream: false,
    responseStream: false,
    requestType: registration_registration_pb.GetMyRegistrationsRequest,
    responseType: registration_registration_pb.GetMyRegistrationsResponse,
  };

  static readonly GetRegisteredPeople = {
    methodName: "GetRegisteredPeople",
    service: RegistrationManager,
    requestStream: false,
    responseStream: false,
    requestType: registration_registration_pb.GetRegisteredPeopleRequest,
    responseType: registration_registration_pb.GetRegisteredPeopleResponse,
  };

  static readonly DeleteRegistration = {
    methodName: "DeleteRegistration",
    service: RegistrationManager,
    requestStream: false,
    responseStream: false,
    requestType: registration_registration_pb.DeleteRegistrationRequest,
    responseType: registration_registration_pb.DeleteRegistrationResponse,
  };

  static readonly RegisterToHomeroom = {
    methodName: "RegisterToHomeroom",
    service: RegistrationManager,
    requestStream: false,
    responseStream: false,
    requestType: registration_registration_pb.RegisterToHomeroomRequest,
    responseType: registration_registration_pb.RegisterToHomeroomResponse,
  };

}

export class RegistrationManagerClient {
  register() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  assign() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  getMyRegistrations() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  getRegisteredPeople() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  deleteRegistration() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  registerToHomeroom() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
}
