import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';

import * as day from 'dayjs';
import { FlexTimeActivityInstance, MinimalFlexTimePeriod } from 'libs/domain';
import {
  BehaviorSubject,
  Observable,
  ReplaySubject,
  combineLatest,
} from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  map,
  shareReplay,
  skip,
  startWith,
  takeUntil,
} from 'rxjs/operators';

import { AuthInfoService } from '@app/src/app/minimal/services/AuthInfo';
import { Person } from '@app/src/app/people';

import { FlexTimeManagerService } from '@modules/flex-time-manager/services';
import { PeopleSelectorService } from '@modules/people-selector';
import { TeacherToolsFlexTimeService } from '@modules/teacher-tools/services/tt-flex-time.service';

import { initializeRange } from '@shared/components/form/components/form-date-range/form-date-range.utils';
import {
  ModalOverlayService,
  ModalOverlayServiceCloseEventType,
} from '@shared/components/modal-overlay';
import { MultiPersonSearchComponent } from '@shared/components/multi-person-search';
import { PaginatorComponent } from '@shared/components/paginator';
import { SystemAlertSnackBarService } from '@shared/components/system-alert-snackbar';
import { CheckinFlowService } from '@shared/services/checkin/checkin-flow.service';
import { FlexTimeActivityInstanceService } from '@shared/services/flex-time';
import { FlexTimePermissionsService } from '@shared/services/flex-time/flex-time-permissions';
import { MediaBreakpoints, MediaService } from '@shared/services/media';

import { FlexTimeManagerMessages } from '../../constants';
import { FtmPeriodsActivityEditComponent } from '../ftm-periods/components/ftm-periods-activity-edit/ftm-periods-activity-edit.component';
import { FtmPeriodsActivityEditModalData } from '../ftm-periods/types';
import {
  FTM_ACTIVITIES_DATE_FILTER,
  FTM_ACTIVITIES_DEFAULT_FILTER,
  FTM_ACTIVITIES_DISPLAY_COLUMNS,
  FtmActivitiesMessages,
} from './constants';

type FtmInstancesClientFilters = {
  periodId: number | undefined;
  textValue: string;
  createdByHash: string | null;
};

type FtmInstancesFilter = {
  people: Partial<Person>[];
};

type FtmInstancesDateFilter = {
  startDate: day.Dayjs;
  endDate: day.Dayjs;
};

@Component({
  selector: 'mg-ftm-activities',
  templateUrl: './ftm-activities.component.html',
  styleUrls: ['./ftm-activities.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FtmActivitiesComponent implements AfterViewInit, OnDestroy {
  @ViewChild(MatTable) matTable: MatTable<FlexTimeActivityInstance>;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MultiPersonSearchComponent)
  private _peopleSearchComponent: MultiPersonSearchComponent;

  @ViewChild(PaginatorComponent)
  paginator: PaginatorComponent;

  /** Constants */
  public readonly FTM_MSG = FlexTimeManagerMessages;
  public readonly MSG = FtmActivitiesMessages;

  /** General Observables */
  private readonly _destroyedSubj = new ReplaySubject<void>(1);

  public readonly hasPeriods$ = this.ftManager.periods$.pipe(
    takeUntil(this._destroyedSubj),
    map(periods => !!periods.length),
  );

  private readonly _filter = new BehaviorSubject<FtmInstancesFilter>(
    FTM_ACTIVITIES_DEFAULT_FILTER,
  );

  public readonly filter$ = this._filter
    .asObservable()
    .pipe(takeUntil(this._destroyedSubj));

  public panelOpenState = false;
  public readonly isMobileView$: Observable<boolean> =
    this.media.breakpoint$.pipe(
      takeUntil(this._destroyedSubj),
      map(breakpoint => {
        return ['xsmall', 'small'].includes(breakpoint);
      }),
    );

  /** Search Filter */
  public readonly searchControl = this._fb.control('');
  private _hasSearchValue = false;
  private readonly _debouncedSearchValue$ =
    this.searchControl.valueChanges.pipe(
      takeUntil(this._destroyedSubj),
      startWith(''),
      debounceTime(300),
      shareReplay(1),
      distinctUntilChanged(),
      map(value => value.toLowerCase()),
    );

  public selfManager = this._flexPermissions.isFlexTimeSelfManager();
  public isAdmin = this._flexPermissions.isFlexTimeAdmin();

  /** Show mine filter */
  private readonly _showMineFilter = new BehaviorSubject<'all' | 'mine'>(
    this._flexPermissions.isFlexTimeAdmin() ? 'all' : 'mine',
  );
  public readonly showMineFilter$ = this._showMineFilter.asObservable();

  /** Table Data */
  public readonly dataSource = new MatTableDataSource<FlexTimeActivityInstance>(
    [],
  );
  private readonly _dataSub = combineLatest([
    this.ftManager.instances$,
    this.ftManager.periodFilter$,
    this.showMineFilter$,
  ])
    .pipe(takeUntil(this._destroyedSubj))
    .subscribe(([instances, periodId, showMine]) => {
      if (periodId) {
        const filters: FtmInstancesClientFilters = {
          textValue: '',
          periodId,
          createdByHash:
            showMine === 'mine' ? this._auth.authPerson.hash : null,
        };
        this.dataSource.filter = JSON.stringify(filters);
      }

      this.dataSource.data = instances;
      this._cdr.markForCheck();
    });

  public readonly displayedColumns$ = this.media.breakpoint$.pipe(
    takeUntil(this._destroyedSubj),
    map(breakpoint => {
      const columns = [...FTM_ACTIVITIES_DISPLAY_COLUMNS];
      const showMobileViewOn: MediaBreakpoints[] = [
        'xsmall',
        'small',
        'medium',
        'medium-large',
      ];
      const displayedColumns = showMobileViewOn.includes(breakpoint)
        ? ['mobile']
        : columns || [];
      return displayedColumns;
    }),
  );

  public activePeriod$: Observable<MinimalFlexTimePeriod> = combineLatest([
    this.ftManager.periods$,
    this.ftManager.periodFilter$,
  ]).pipe(
    map(([periods, periodId]) => {
      return periods?.find(p => p.id === periodId);
    }),
  );

  /**
   * Filters responsible for fetching instance data
   */
  private readonly _filtersSub = combineLatest([
    this.filter$,
    this.ftManager.periodFilter$,
  ])
    .pipe(takeUntil(this._destroyedSubj), debounceTime(100))
    .subscribe(async ([filters, periodId]) => {
      const { start: startDate, end: endDate } = this.range.value;
      if (!periodId || !startDate || !startDate) {
        return;
      }

      const { people } = filters;
      const personHashes = people.map(p => p.hash);

      await this.ftManager.fetchActivityInstances({
        periodId,
        startDate,
        endDate,
        personHashes,
      });
    });

  private readonly _a = this.ftManager.instances$
    .pipe(takeUntil(this._destroyedSubj), debounceTime(100), skip(1))
    .subscribe(() => {
      const people = this._filter.getValue().people;
      this._peopleSearchComponent.setValue(people, true);
    });

  public range = initializeRange();

  /** Empty State */
  get showEmptyState() {
    return this._hasSearchValue
      ? !this.dataSource?.filteredData?.length
      : !this.dataSource?.data?.length;
  }

  /** Component Constructor */
  constructor(
    public media: MediaService,
    public ftManager: FlexTimeManagerService,
    private _cdr: ChangeDetectorRef,
    private _fb: UntypedFormBuilder,
    private _ttFlexTimeService: TeacherToolsFlexTimeService,
    private _flexInstance: FlexTimeActivityInstanceService,
    private _modalOverlay: ModalOverlayService,
    private _systemAlertSnackBar: SystemAlertSnackBarService,
    private _flexPermissions: FlexTimePermissionsService,
    private _checkinOverlayService: CheckinFlowService,
    private _peopleSelector: PeopleSelectorService,
    private _auth: AuthInfoService,
  ) {
    this._setInitialDates();

    combineLatest([
      this._debouncedSearchValue$,
      this.ftManager.periodFilter$,
      this.showMineFilter$,
    ])
      .pipe(takeUntil(this._destroyedSubj))
      .subscribe(([textValue, periodId, showMine]) => {
        this._hasSearchValue = !!periodId || textValue.length > 0;
        const filters: FtmInstancesClientFilters = {
          textValue,
          periodId,
          createdByHash:
            showMine === 'mine' ? this._auth.authPerson.hash : null,
        };
        this.dataSource.filter = JSON.stringify(filters);
        this._cdr.markForCheck();
      });

    this._ttFlexTimeService.activityAssignmentSuccess$
      .pipe(takeUntil(this._destroyedSubj))
      .subscribe(() => this._revalidateData());

    this._peopleSelector
      .eventWhen('after-closed')
      .pipe(takeUntil(this._destroyedSubj), debounceTime(800))
      .subscribe(() => {
        this._revalidateData();
      });

    this.range.valueChanges
      .pipe(takeUntil(this._destroyedSubj))
      .subscribe(range => {
        this.ftManager.setPeriodFilter(null);
        this.ftManager.fetchPeriods(range.start, range.end);
      });
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator.matPaginator;
    this.dataSource.sort = this.sort;
    this.sort?.sort({
      id: 'flexTimePeriod.date',
      start: 'asc',
      disableClear: false,
    });
    this.dataSource.sortingDataAccessor = (
      data: FlexTimeActivityInstance,
      sortHeaderId: string,
    ): string => {
      if (sortHeaderId === 'flexTimeActivity.createdBy') {
        return data.flexTimeActivity?.createdByPerson?.name.toLocaleLowerCase();
      } else if (sortHeaderId === 'flexTimeActivity.name') {
        return data.flexTimeActivity?.name.toLocaleLowerCase();
      } else if (sortHeaderId === 'flexTimeActivity.location') {
        return data.flexTimeActivity?.location.toLocaleLowerCase();
      }
      return '';
    };
    this.dataSource.filterPredicate = this._tableFilter;
  }

  ngOnDestroy(): void {
    this._destroyedSubj.next();
    this._destroyedSubj.complete();
    this._dataSub.unsubscribe();
    this._filtersSub.unsubscribe();
  }

  public setShowMineFilter(value: 'all' | 'mine') {
    if (!value) return;
    this._showMineFilter.next(value);
  }

  public trackById(index: number, item: FlexTimeActivityInstance) {
    return item ? item.id : index;
  }

  public changePersonHashes(people: Partial<Person>[]) {
    this._filter.next({
      ...this._filter.getValue(),
      people,
    });
  }

  public activityCheckIn(item: FlexTimeActivityInstance): void {
    const reason = this._flexInstance.mapFlexActivityInstanceToReason(item);
    this._checkinOverlayService.setReason(reason);

    this._peopleSelector.open('FlexTime Activity', 'checkin', {
      title: item.flexTimeActivity.name,
      data: {
        flexTimeActivityId: item.id,
        checkinReasonId: reason.id,
        registered: item.registered,
        spaces: item.spaces,
        flexTimePeriodId: item.flexTimePeriodId,
        date: item.flexTimePeriod.date,
        endTime: item.flexTimePeriod.endTime,
        canOverride: this._flexPermissions.canManageActivityRegistration(item),
      },
    });
  }

  public assignActivity(activityInstance: FlexTimeActivityInstance): void {
    const canOverride =
      this._flexPermissions.canManageActivityRegistration(activityInstance);
    this._ttFlexTimeService.openActivityPeopleSelector(
      activityInstance,
      canOverride,
    );
  }

  public addActivity(periodId: number) {
    const modalRef = this._modalOverlay.open<FtmPeriodsActivityEditModalData>(
      FtmPeriodsActivityEditComponent,
      {
        data: { id: periodId },
        disposeOnNavigation: false,
      },
    );
    modalRef.afterClosed.subscribe(async response => {
      if (!response) return;
      const { type } = response;
      switch (type) {
        case ModalOverlayServiceCloseEventType.CREATE: {
          this._revalidateData();
          this._systemAlertSnackBar.open({
            type: 'success',
            message: FtmActivitiesMessages.ADD_ACTIVITY_SUCCESS,
          });
          break;
        }
      }
    });
  }

  public deleteActivityInstance(item: FlexTimeActivityInstance) {
    if (!this._flexPermissions.canEditActivityInstance(item)) return;

    this.ftManager.deleteActivityInstance(item.id);
  }

  public isInPast(data: MinimalFlexTimePeriod): boolean {
    const date = day(data.date).format('MMM DD YYYY');
    const parsed = `${date} ${data.endTime}`;

    const periodDate = day(parsed, 'MMM DD YYYY HH:mm:ss');

    return periodDate.isBefore(day(), 'minute');
  }

  private _tableFilter(period: FlexTimeActivityInstance, search: string) {
    const { periodId, textValue, createdByHash } = JSON.parse(
      search,
    ) as FtmInstancesClientFilters;
    const { flexTimeActivity, flexTimePeriod } = period;

    const searchableByStringValues = [
      flexTimeActivity?.name?.toLowerCase(),
      flexTimeActivity?.createdByPerson?.name?.toLowerCase(),
      flexTimeActivity?.location?.toLowerCase(),
    ].filter(v => v);

    const textSearchFilter = textValue.length
      ? searchableByStringValues.some(v => v.includes(textValue))
      : true;

    const filterByPeriodId = periodId ? flexTimePeriod.id === periodId : true;

    const createdBy = createdByHash
      ? flexTimeActivity?.createdByPerson?.hash === createdByHash
      : true;

    return [textSearchFilter, filterByPeriodId, createdBy].every(v => v);
  }

  private _setInitialDates() {
    const { startDate: defaultStart, endDate: defaultEnd } =
      FTM_ACTIVITIES_DATE_FILTER;

    const startDate = window.history.state?.startDate
      ? day(window.history.state?.startDate)
      : defaultStart;
    const endDate = window.history.state?.endDate
      ? day(window.history.state?.endDate)
      : defaultEnd;

    this.range.setValue({ start: startDate, end: endDate });

    this.ftManager.fetchPeriods(startDate, endDate);
  }

  private _revalidateData() {
    this._filter.next({ ...this._filter.getValue() });
  }
}
