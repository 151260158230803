import { Grade } from 'libs/util';

import { MingaRoleType, Restriction } from '../';
import { EditableReportRecord } from '../editableReportRecord';
import { IMembershipList } from '../membershipList';
import { StudentSection } from '../studentSchedule';
import { CheckinReasonType } from './CheckinReasonType';

export interface Checkin {
  id: number;
  reasonId: number;
  reason?: ICheckinReason;
  reasonName?: string;
  person: ICheckinPerson;
  checkinBy: ICheckinPerson;
  checkinTime: any;
  checkoutTime?: any;
  checkoutBy?: ICheckinPerson;
  note?: string;
  isKiosk?: boolean;
  currentClass?: StudentSection[];
}

export type EditableCheckin = Checkin & EditableReportRecord;

export type Absentee = Omit<
  Checkin,
  | 'checkinBy'
  | 'checkoutBy'
  | 'checkinTime'
  | 'checkoutTime'
  | 'id'
  | 'reasonId'
  | 'updatedAt'
> & { reason: ICheckinReason };

export interface ICheckinPerson {
  hash?: string;
  id?: number;
  displayName: string;
  grade?: string;
  studentId?: string;
  badgeIconUrl?: string;
  badgeIconColor?: string;
  badgeRoleName?: string;
  firstName: string;
  lastName: string;
  email?: string;
}

export interface ICheckinReason {
  id?: number;
  name: string;
  createdBy?: number;
  mingaId: number;
  active: boolean;
  pointReward: number;
  icon: string;
  showAbsentees: boolean;
  stickerIds?: number[];
  roles?: MingaRoleType[];
  grades?: Grade[];
  userLists?: number[];
  groupHashes?: string[];
  blockNoAccess?: boolean;
  restrictedCheckinReasonId?: number;
  priority?: number;
  automationId?: number;
  hallPassId?: number;
  behaviorId?: number;
  color?: string;
  consequenceId?: number;
  selfCheckIn: boolean;
  allowMultipleCheckins?: boolean;
  checkinReasonType: CheckinReasonType;
  allowCheckout?: boolean;
  membershipList?: IMembershipList;
  restrictionId?: number;
  membershipListId?: number | null;
  enableStudentPhoto?: boolean;
  availableInKiosk?: boolean;
  autoEndHallPass?: boolean;
  restriction?: Restriction;
}

export interface ICheckinReasonWithCounts extends ICheckinReason {
  numberOfAbsentees?: number;
  totalCheckinCount?: number;
}

export enum CheckinType {
  CHECKIN = 'CHECKIN',
  CHECKOUT = 'CHECKOUT',
  FLEX_TIME_CHECKIN = 'FLEX_TIME_CHECKIN',
  EVENT_CHECKIN = 'EVENT_CHECKIN',
}

export enum CheckinReasonIcons {
  CHECKIN_1 = 'checkin-1',
  CHECKIN_2 = 'checkin-2',
  CHECKIN_3 = 'checkin-3',
  CHECKIN_4 = 'checkin-4',
  CHECKIN_5 = 'checkin-5',
  CHECKIN_6 = 'checkin-6',
  CHECKIN_7 = 'checkin-7',
  CHECKIN_8 = 'checkin-8',
  CHECKIN_9 = 'checkin-9',
}
