import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';

import { gateway, delta_pb } from 'libs/generated-grpc-web';
import { BehaviorSubject, ReplaySubject, Subject } from 'rxjs';

import {
  IMingaLinkFormOptions,
  IMingaProfile,
  MingaManagerService,
} from '@app/src/app/services/MingaManager';
import { UserStorage } from '@app/src/app/services/UserStorage';

import { SystemAlertSnackBarService } from '@shared/components/system-alert-snackbar';

import { UpdateMingaProfilePayload } from '../types';

@Injectable()
export class MingaProfileService implements OnDestroy {
  // Clean up
  private readonly _destroyedSubject = new ReplaySubject<void>(1);

  // State
  private readonly _isLoadingSubject = new BehaviorSubject<boolean>(true);
  public readonly isLoading$ = this._isLoadingSubject.asObservable();

  /** Minga profile data */
  private readonly _dataSubject = new BehaviorSubject<IMingaProfile | null>(
    null,
  );
  public readonly data$ = this._dataSubject.asObservable();

  /** Service constructor */
  constructor(
    private _mingaManagerService: MingaManagerService,
    private _mingaManager: gateway.minga_ng_grpc_pb.MingaManager,
    private _userStorage: UserStorage,
    private _router: Router,
    private _systemSnack: SystemAlertSnackBarService,
  ) {}

  ngOnDestroy(): void {
    this._destroyedSubject.next();
    this._destroyedSubject.complete();
    this._isLoadingSubject.complete();
  }

  public getProfileData() {
    return this._dataSubject.getValue();
  }

  public async fetchMingaProfile(silently = false) {
    try {
      if (!silently) this._isLoadingSubject.next(true);
      const result = await this._mingaManagerService.readUserMinga();
      this._dataSubject.next(result);
    } catch (error) {
      this._systemSnack.error('Failed to fetch minga profile');
    } finally {
      if (!silently) this._isLoadingSubject.next(false);
    }
  }

  public async updateMingaProfile(payload: UpdateMingaProfilePayload) {
    try {
      const request = new gateway.minga_pb.UpdateMingaProfileRequest();
      const minga = new gateway.minga_pb.MingaDelta();
      if (payload?.email) {
        const delta = new delta_pb.StringDelta();
        delta.setNewString(payload.email);
        minga.setEmail(delta);
      }
      if (payload?.name) {
        const delta = new delta_pb.StringDelta();
        delta.setNewString(payload.name);
        minga.setName(delta);
      }
      if (payload?.phone) {
        const delta = new delta_pb.StringDelta();
        delta.setNewString(payload.phone);
        minga.setPhoneNumber(delta);
      }
      if (payload?.placeId) {
        const delta = new delta_pb.StringDelta();
        delta.setNewString(payload.placeId);
        minga.setPlaceId(delta);
      }
      if (payload?.websiteUrl) {
        const delta = new delta_pb.StringDelta();
        delta.setNewString(payload.websiteUrl);
        minga.setWebsiteUrl(delta);
      }
      request.setMinga(minga);
      const result = await this._mingaManager.updateMingaProfile(request);
      const current = this._dataSubject.getValue();
      const updated = {
        ...current,
        ...(result.toObject().minga as any),
      };

      updated.mingaAddress = await this._getAddressFromPlaceId(updated.placeId);
      this._dataSubject.next(updated);
    } catch (error) {
      console.log(error);
      this._systemSnack.error('Failed to update minga profile');
    }
  }

  public async openMingaLinkForm(linkFormOptions: IMingaLinkFormOptions) {
    await this._userStorage.setItem('mingaLinkFormData', linkFormOptions);
    const submittedSubject = new Subject<void>();
    await this._router.navigate(
      ['/', { outlets: { o: ['minga-profile-link'] } }],
      {
        state: { submittedSubject },
      },
    );
    await submittedSubject.toPromise();
    this.fetchMingaProfile(true);
  }

  private async _getAddressFromPlaceId(placeId: string) {
    if (!placeId) return null;

    const dummyDiv = document.createElement('div');
    const placesService = new google.maps.places.PlacesService(dummyDiv);

    return new Promise((resolve, reject) => {
      placesService.getDetails({ placeId }, (result, status) => {
        if (status === google.maps.places.PlacesServiceStatus.OK) {
          resolve(result.formatted_address);
        } else {
          reject('Failed to get place details: ' + status);
        }
      });
    });
  }
}
