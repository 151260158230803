import { Injectable, NgZone } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { grpc } from '@improbable-eng/grpc-web';

import {
  StreamControl,
  StreamControlResponse,
  StreamID,
} from '../common/stream_pb';
import {
  AddDefaultHallPassTypesRequest,
  AddDefaultHallPassTypesResponse,
  ApproveHallPassRequest,
  ApproveHallPassResponse,
  ArchiveHallPassRequest,
  ArchiveHallPassResponse,
  CancelHallPassRequest,
  CancelHallPassResponse,
  CheckHallPassValidityRequest,
  CheckHallPassValidityResponse,
  CreateHallPassRequest,
  CreateHallPassResponse,
  DeleteHallPassBlackOutWindowRequest,
  DeleteHallPassBlackOutWindowResponse,
  DeleteHallPassTypeRequest,
  DeleteHallPassTypeResponse,
  ExpireHallPassRequest,
  ExpireHallPassResponse,
  GetHallPassBlackOutWindowsRequest,
  GetHallPassBlackOutWindowsResponse,
  GetHallPassesForRecipientRequest,
  GetHallPassesForRecipientResponse,
  GetHallPassesRequest,
  GetHallPassesResponse,
  GetMembershipListsRequest,
  GetMembershipListsResponse,
  GetMingaHallPassTypeRequest,
  GetMingaHallPassTypeResponse,
  GetMingaHallPassTypesCountsRequest,
  GetMingaHallPassTypesCountsResponse,
  GetMingaHallPassTypesRequest,
  GetMingaHallPassTypesResponse,
  StartHallPassRequest,
  StartHallPassResponse,
  StreamHallPassWithTypeItem,
  UpdateHallPassBlackOutWindowRequest,
  UpdateHallPassBlackOutWindowResponse,
  UpdateHallPassRequest,
  UpdateHallPassResponse,
  UpdateHallPassTypeRequest,
  UpdateHallPassTypeResponse,
} from '../hall_pass/hall_pass_pb';

import {
  HallPassManager as __HallPassManager,
} from '../hall_pass/hall_pass_pb_service';

@Injectable({providedIn: 'root'})
export class HallPassManager {

  constructor(private _ngZone: NgZone) {
  }

  updateHallPassType(request: UpdateHallPassTypeRequest): Promise<UpdateHallPassTypeResponse>;
  updateHallPassType(request: UpdateHallPassTypeRequest, metadata: grpc.Metadata): Promise<UpdateHallPassTypeResponse>;
  updateHallPassType(request: UpdateHallPassTypeRequest, callback: (err: any|null, response: UpdateHallPassTypeResponse, metadata: grpc.Metadata) => void): void;
  updateHallPassType(request: UpdateHallPassTypeRequest, metadata: grpc.Metadata, callback: (err: any|null, response: UpdateHallPassTypeResponse, metadata: grpc.Metadata) => void): void;

  updateHallPassType(request: UpdateHallPassTypeRequest, arg1?: grpc.Metadata|((err: any|null, response: UpdateHallPassTypeResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: UpdateHallPassTypeResponse, metadata: grpc.Metadata) => void): Promise<UpdateHallPassTypeResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<UpdateHallPassTypeResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__HallPassManager.UpdateHallPassType, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  deleteHallPassType(request: DeleteHallPassTypeRequest): Promise<DeleteHallPassTypeResponse>;
  deleteHallPassType(request: DeleteHallPassTypeRequest, metadata: grpc.Metadata): Promise<DeleteHallPassTypeResponse>;
  deleteHallPassType(request: DeleteHallPassTypeRequest, callback: (err: any|null, response: DeleteHallPassTypeResponse, metadata: grpc.Metadata) => void): void;
  deleteHallPassType(request: DeleteHallPassTypeRequest, metadata: grpc.Metadata, callback: (err: any|null, response: DeleteHallPassTypeResponse, metadata: grpc.Metadata) => void): void;

  deleteHallPassType(request: DeleteHallPassTypeRequest, arg1?: grpc.Metadata|((err: any|null, response: DeleteHallPassTypeResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: DeleteHallPassTypeResponse, metadata: grpc.Metadata) => void): Promise<DeleteHallPassTypeResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<DeleteHallPassTypeResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__HallPassManager.DeleteHallPassType, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  createHallPass(request: CreateHallPassRequest): Promise<CreateHallPassResponse>;
  createHallPass(request: CreateHallPassRequest, metadata: grpc.Metadata): Promise<CreateHallPassResponse>;
  createHallPass(request: CreateHallPassRequest, callback: (err: any|null, response: CreateHallPassResponse, metadata: grpc.Metadata) => void): void;
  createHallPass(request: CreateHallPassRequest, metadata: grpc.Metadata, callback: (err: any|null, response: CreateHallPassResponse, metadata: grpc.Metadata) => void): void;

  createHallPass(request: CreateHallPassRequest, arg1?: grpc.Metadata|((err: any|null, response: CreateHallPassResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: CreateHallPassResponse, metadata: grpc.Metadata) => void): Promise<CreateHallPassResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<CreateHallPassResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__HallPassManager.CreateHallPass, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  getMingaHallPassType(request: GetMingaHallPassTypeRequest): Promise<GetMingaHallPassTypeResponse>;
  getMingaHallPassType(request: GetMingaHallPassTypeRequest, metadata: grpc.Metadata): Promise<GetMingaHallPassTypeResponse>;
  getMingaHallPassType(request: GetMingaHallPassTypeRequest, callback: (err: any|null, response: GetMingaHallPassTypeResponse, metadata: grpc.Metadata) => void): void;
  getMingaHallPassType(request: GetMingaHallPassTypeRequest, metadata: grpc.Metadata, callback: (err: any|null, response: GetMingaHallPassTypeResponse, metadata: grpc.Metadata) => void): void;

  getMingaHallPassType(request: GetMingaHallPassTypeRequest, arg1?: grpc.Metadata|((err: any|null, response: GetMingaHallPassTypeResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: GetMingaHallPassTypeResponse, metadata: grpc.Metadata) => void): Promise<GetMingaHallPassTypeResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<GetMingaHallPassTypeResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__HallPassManager.GetMingaHallPassType, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  getMingaHallPassTypes(request: GetMingaHallPassTypesRequest): Promise<GetMingaHallPassTypesResponse>;
  getMingaHallPassTypes(request: GetMingaHallPassTypesRequest, metadata: grpc.Metadata): Promise<GetMingaHallPassTypesResponse>;
  getMingaHallPassTypes(request: GetMingaHallPassTypesRequest, callback: (err: any|null, response: GetMingaHallPassTypesResponse, metadata: grpc.Metadata) => void): void;
  getMingaHallPassTypes(request: GetMingaHallPassTypesRequest, metadata: grpc.Metadata, callback: (err: any|null, response: GetMingaHallPassTypesResponse, metadata: grpc.Metadata) => void): void;

  getMingaHallPassTypes(request: GetMingaHallPassTypesRequest, arg1?: grpc.Metadata|((err: any|null, response: GetMingaHallPassTypesResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: GetMingaHallPassTypesResponse, metadata: grpc.Metadata) => void): Promise<GetMingaHallPassTypesResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<GetMingaHallPassTypesResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__HallPassManager.GetMingaHallPassTypes, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  addDefaultHallPassTypesToMinga(request: AddDefaultHallPassTypesRequest): Promise<AddDefaultHallPassTypesResponse>;
  addDefaultHallPassTypesToMinga(request: AddDefaultHallPassTypesRequest, metadata: grpc.Metadata): Promise<AddDefaultHallPassTypesResponse>;
  addDefaultHallPassTypesToMinga(request: AddDefaultHallPassTypesRequest, callback: (err: any|null, response: AddDefaultHallPassTypesResponse, metadata: grpc.Metadata) => void): void;
  addDefaultHallPassTypesToMinga(request: AddDefaultHallPassTypesRequest, metadata: grpc.Metadata, callback: (err: any|null, response: AddDefaultHallPassTypesResponse, metadata: grpc.Metadata) => void): void;

  addDefaultHallPassTypesToMinga(request: AddDefaultHallPassTypesRequest, arg1?: grpc.Metadata|((err: any|null, response: AddDefaultHallPassTypesResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: AddDefaultHallPassTypesResponse, metadata: grpc.Metadata) => void): Promise<AddDefaultHallPassTypesResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<AddDefaultHallPassTypesResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__HallPassManager.AddDefaultHallPassTypesToMinga, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  getMingaHallPassTypesCounts(request: GetMingaHallPassTypesCountsRequest): Promise<GetMingaHallPassTypesCountsResponse>;
  getMingaHallPassTypesCounts(request: GetMingaHallPassTypesCountsRequest, metadata: grpc.Metadata): Promise<GetMingaHallPassTypesCountsResponse>;
  getMingaHallPassTypesCounts(request: GetMingaHallPassTypesCountsRequest, callback: (err: any|null, response: GetMingaHallPassTypesCountsResponse, metadata: grpc.Metadata) => void): void;
  getMingaHallPassTypesCounts(request: GetMingaHallPassTypesCountsRequest, metadata: grpc.Metadata, callback: (err: any|null, response: GetMingaHallPassTypesCountsResponse, metadata: grpc.Metadata) => void): void;

  getMingaHallPassTypesCounts(request: GetMingaHallPassTypesCountsRequest, arg1?: grpc.Metadata|((err: any|null, response: GetMingaHallPassTypesCountsResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: GetMingaHallPassTypesCountsResponse, metadata: grpc.Metadata) => void): Promise<GetMingaHallPassTypesCountsResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<GetMingaHallPassTypesCountsResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__HallPassManager.GetMingaHallPassTypesCounts, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  expireHallPass(request: ExpireHallPassRequest): Promise<ExpireHallPassResponse>;
  expireHallPass(request: ExpireHallPassRequest, metadata: grpc.Metadata): Promise<ExpireHallPassResponse>;
  expireHallPass(request: ExpireHallPassRequest, callback: (err: any|null, response: ExpireHallPassResponse, metadata: grpc.Metadata) => void): void;
  expireHallPass(request: ExpireHallPassRequest, metadata: grpc.Metadata, callback: (err: any|null, response: ExpireHallPassResponse, metadata: grpc.Metadata) => void): void;

  expireHallPass(request: ExpireHallPassRequest, arg1?: grpc.Metadata|((err: any|null, response: ExpireHallPassResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: ExpireHallPassResponse, metadata: grpc.Metadata) => void): Promise<ExpireHallPassResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<ExpireHallPassResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__HallPassManager.ExpireHallPass, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  approveHallPass(request: ApproveHallPassRequest): Promise<ApproveHallPassResponse>;
  approveHallPass(request: ApproveHallPassRequest, metadata: grpc.Metadata): Promise<ApproveHallPassResponse>;
  approveHallPass(request: ApproveHallPassRequest, callback: (err: any|null, response: ApproveHallPassResponse, metadata: grpc.Metadata) => void): void;
  approveHallPass(request: ApproveHallPassRequest, metadata: grpc.Metadata, callback: (err: any|null, response: ApproveHallPassResponse, metadata: grpc.Metadata) => void): void;

  approveHallPass(request: ApproveHallPassRequest, arg1?: grpc.Metadata|((err: any|null, response: ApproveHallPassResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: ApproveHallPassResponse, metadata: grpc.Metadata) => void): Promise<ApproveHallPassResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<ApproveHallPassResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__HallPassManager.ApproveHallPass, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  startHallPass(request: StartHallPassRequest): Promise<StartHallPassResponse>;
  startHallPass(request: StartHallPassRequest, metadata: grpc.Metadata): Promise<StartHallPassResponse>;
  startHallPass(request: StartHallPassRequest, callback: (err: any|null, response: StartHallPassResponse, metadata: grpc.Metadata) => void): void;
  startHallPass(request: StartHallPassRequest, metadata: grpc.Metadata, callback: (err: any|null, response: StartHallPassResponse, metadata: grpc.Metadata) => void): void;

  startHallPass(request: StartHallPassRequest, arg1?: grpc.Metadata|((err: any|null, response: StartHallPassResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: StartHallPassResponse, metadata: grpc.Metadata) => void): Promise<StartHallPassResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<StartHallPassResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__HallPassManager.StartHallPass, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  cancelHallPass(request: CancelHallPassRequest): Promise<CancelHallPassResponse>;
  cancelHallPass(request: CancelHallPassRequest, metadata: grpc.Metadata): Promise<CancelHallPassResponse>;
  cancelHallPass(request: CancelHallPassRequest, callback: (err: any|null, response: CancelHallPassResponse, metadata: grpc.Metadata) => void): void;
  cancelHallPass(request: CancelHallPassRequest, metadata: grpc.Metadata, callback: (err: any|null, response: CancelHallPassResponse, metadata: grpc.Metadata) => void): void;

  cancelHallPass(request: CancelHallPassRequest, arg1?: grpc.Metadata|((err: any|null, response: CancelHallPassResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: CancelHallPassResponse, metadata: grpc.Metadata) => void): Promise<CancelHallPassResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<CancelHallPassResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__HallPassManager.CancelHallPass, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  streamMingaHallPasses(request: StreamID): {cancel():void;close():void}&Observable<StreamHallPassWithTypeItem>;
  streamMingaHallPasses(request: StreamID, metadata: grpc.Metadata): {cancel():void;close():void}&Observable<StreamHallPassWithTypeItem>;
  streamMingaHallPasses(request: StreamID, onMessage: (message?: StreamHallPassWithTypeItem) => void,onError?: (err: any) => void,onEnd?: (code: number, msg: string|undefined, metadata: grpc.Metadata) => void): void;
  streamMingaHallPasses(request: StreamID, metadata: grpc.Metadata, onMessage: (message?: StreamHallPassWithTypeItem) => void,onError?: (err: any) => void,onEnd?: (code: number, msg: string|undefined, metadata: grpc.Metadata) => void): void;

  streamMingaHallPasses(request: StreamID, arg1?: grpc.Metadata|((message?: StreamHallPassWithTypeItem) => void), arg2?: ((message?: StreamHallPassWithTypeItem) => void)|((err: any) => void), arg3?: ((err: any) => void)|((code: number, msg: string|undefined, metadata: grpc.Metadata) => void), arg4?: (code: number, msg: string|undefined, metadata: grpc.Metadata) => void): {cancel():void;close():void}&Observable<StreamHallPassWithTypeItem>|void {
    let ret: any;
    let metadata: any;

    let onMessage: any;
    let onError: any;
    let onEnd: any;
    if(typeof arg1 === 'function') {
      onMessage = arg1;
      onError = arg2;
      onEnd = arg3;
    } else if(typeof arg2 === 'function') {
      metadata = arg1;
      onMessage = arg2;
      onError = arg3;
      onEnd = arg4;
    } else {
      metadata = arg1;
    }

    if(!onMessage) {
      let subject = new Subject<StreamHallPassWithTypeItem>();
      ret = subject.asObservable();

      onMessage = (response: any) => {
        subject.next(response);
      };

      onError = (err: any) => {
        subject.error(err);
      };

      onEnd = (code: any, msg: any, metadata: any) => {
        subject.complete();
      };

    } else {
      if(!onError) {
        onError = (err: any) => console.error(err);
      }

      if(!onEnd) {
        onEnd = (code: any, msg: any, metadata: any) => {};
      }
    }

    let req = grpc.invoke(__HallPassManager.streamMingaHallPasses, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onMessage: response => this._ngZone.run(() => {
        onMessage(response);
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code == grpc.Code.OK) {
          onEnd(code, msg, metadata);
        } else {
          let err = new Error(code + ' ' + (msg||''));
          (<any>err).code = code;
          onError(err);
        }
      })
    });

    (<any>ret).close = () => {
      console.warn('[Angular Grpc] .close() is deprecated use .cancel() instead');
      return req.close();
    };
    (<any>ret).cancel = () => req.close();
    return ret;
  }

  streamMingaHallPassesControl(request: StreamControl): Promise<StreamControlResponse>;
  streamMingaHallPassesControl(request: StreamControl, metadata: grpc.Metadata): Promise<StreamControlResponse>;
  streamMingaHallPassesControl(request: StreamControl, callback: (err: any|null, response: StreamControlResponse, metadata: grpc.Metadata) => void): void;
  streamMingaHallPassesControl(request: StreamControl, metadata: grpc.Metadata, callback: (err: any|null, response: StreamControlResponse, metadata: grpc.Metadata) => void): void;

  streamMingaHallPassesControl(request: StreamControl, arg1?: grpc.Metadata|((err: any|null, response: StreamControlResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: StreamControlResponse, metadata: grpc.Metadata) => void): Promise<StreamControlResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<StreamControlResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__HallPassManager.streamMingaHallPassesControl, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  getHallPassesForRecipient(request: GetHallPassesForRecipientRequest): Promise<GetHallPassesForRecipientResponse>;
  getHallPassesForRecipient(request: GetHallPassesForRecipientRequest, metadata: grpc.Metadata): Promise<GetHallPassesForRecipientResponse>;
  getHallPassesForRecipient(request: GetHallPassesForRecipientRequest, callback: (err: any|null, response: GetHallPassesForRecipientResponse, metadata: grpc.Metadata) => void): void;
  getHallPassesForRecipient(request: GetHallPassesForRecipientRequest, metadata: grpc.Metadata, callback: (err: any|null, response: GetHallPassesForRecipientResponse, metadata: grpc.Metadata) => void): void;

  getHallPassesForRecipient(request: GetHallPassesForRecipientRequest, arg1?: grpc.Metadata|((err: any|null, response: GetHallPassesForRecipientResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: GetHallPassesForRecipientResponse, metadata: grpc.Metadata) => void): Promise<GetHallPassesForRecipientResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<GetHallPassesForRecipientResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__HallPassManager.GetHallPassesForRecipient, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  getMembershipLists(request: GetMembershipListsRequest): Promise<GetMembershipListsResponse>;
  getMembershipLists(request: GetMembershipListsRequest, metadata: grpc.Metadata): Promise<GetMembershipListsResponse>;
  getMembershipLists(request: GetMembershipListsRequest, callback: (err: any|null, response: GetMembershipListsResponse, metadata: grpc.Metadata) => void): void;
  getMembershipLists(request: GetMembershipListsRequest, metadata: grpc.Metadata, callback: (err: any|null, response: GetMembershipListsResponse, metadata: grpc.Metadata) => void): void;

  getMembershipLists(request: GetMembershipListsRequest, arg1?: grpc.Metadata|((err: any|null, response: GetMembershipListsResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: GetMembershipListsResponse, metadata: grpc.Metadata) => void): Promise<GetMembershipListsResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<GetMembershipListsResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__HallPassManager.GetMembershipLists, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  checkHallPassValidity(request: CheckHallPassValidityRequest): Promise<CheckHallPassValidityResponse>;
  checkHallPassValidity(request: CheckHallPassValidityRequest, metadata: grpc.Metadata): Promise<CheckHallPassValidityResponse>;
  checkHallPassValidity(request: CheckHallPassValidityRequest, callback: (err: any|null, response: CheckHallPassValidityResponse, metadata: grpc.Metadata) => void): void;
  checkHallPassValidity(request: CheckHallPassValidityRequest, metadata: grpc.Metadata, callback: (err: any|null, response: CheckHallPassValidityResponse, metadata: grpc.Metadata) => void): void;

  checkHallPassValidity(request: CheckHallPassValidityRequest, arg1?: grpc.Metadata|((err: any|null, response: CheckHallPassValidityResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: CheckHallPassValidityResponse, metadata: grpc.Metadata) => void): Promise<CheckHallPassValidityResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<CheckHallPassValidityResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__HallPassManager.CheckHallPassValidity, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  updateHallPassBlackOutWindow(request: UpdateHallPassBlackOutWindowRequest): Promise<UpdateHallPassBlackOutWindowResponse>;
  updateHallPassBlackOutWindow(request: UpdateHallPassBlackOutWindowRequest, metadata: grpc.Metadata): Promise<UpdateHallPassBlackOutWindowResponse>;
  updateHallPassBlackOutWindow(request: UpdateHallPassBlackOutWindowRequest, callback: (err: any|null, response: UpdateHallPassBlackOutWindowResponse, metadata: grpc.Metadata) => void): void;
  updateHallPassBlackOutWindow(request: UpdateHallPassBlackOutWindowRequest, metadata: grpc.Metadata, callback: (err: any|null, response: UpdateHallPassBlackOutWindowResponse, metadata: grpc.Metadata) => void): void;

  updateHallPassBlackOutWindow(request: UpdateHallPassBlackOutWindowRequest, arg1?: grpc.Metadata|((err: any|null, response: UpdateHallPassBlackOutWindowResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: UpdateHallPassBlackOutWindowResponse, metadata: grpc.Metadata) => void): Promise<UpdateHallPassBlackOutWindowResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<UpdateHallPassBlackOutWindowResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__HallPassManager.UpdateHallPassBlackOutWindow, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  deleteHallPassBlackOutWindow(request: DeleteHallPassBlackOutWindowRequest): Promise<DeleteHallPassBlackOutWindowResponse>;
  deleteHallPassBlackOutWindow(request: DeleteHallPassBlackOutWindowRequest, metadata: grpc.Metadata): Promise<DeleteHallPassBlackOutWindowResponse>;
  deleteHallPassBlackOutWindow(request: DeleteHallPassBlackOutWindowRequest, callback: (err: any|null, response: DeleteHallPassBlackOutWindowResponse, metadata: grpc.Metadata) => void): void;
  deleteHallPassBlackOutWindow(request: DeleteHallPassBlackOutWindowRequest, metadata: grpc.Metadata, callback: (err: any|null, response: DeleteHallPassBlackOutWindowResponse, metadata: grpc.Metadata) => void): void;

  deleteHallPassBlackOutWindow(request: DeleteHallPassBlackOutWindowRequest, arg1?: grpc.Metadata|((err: any|null, response: DeleteHallPassBlackOutWindowResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: DeleteHallPassBlackOutWindowResponse, metadata: grpc.Metadata) => void): Promise<DeleteHallPassBlackOutWindowResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<DeleteHallPassBlackOutWindowResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__HallPassManager.DeleteHallPassBlackOutWindow, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  getHallPassBlackOutWindows(request: GetHallPassBlackOutWindowsRequest): Promise<GetHallPassBlackOutWindowsResponse>;
  getHallPassBlackOutWindows(request: GetHallPassBlackOutWindowsRequest, metadata: grpc.Metadata): Promise<GetHallPassBlackOutWindowsResponse>;
  getHallPassBlackOutWindows(request: GetHallPassBlackOutWindowsRequest, callback: (err: any|null, response: GetHallPassBlackOutWindowsResponse, metadata: grpc.Metadata) => void): void;
  getHallPassBlackOutWindows(request: GetHallPassBlackOutWindowsRequest, metadata: grpc.Metadata, callback: (err: any|null, response: GetHallPassBlackOutWindowsResponse, metadata: grpc.Metadata) => void): void;

  getHallPassBlackOutWindows(request: GetHallPassBlackOutWindowsRequest, arg1?: grpc.Metadata|((err: any|null, response: GetHallPassBlackOutWindowsResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: GetHallPassBlackOutWindowsResponse, metadata: grpc.Metadata) => void): Promise<GetHallPassBlackOutWindowsResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<GetHallPassBlackOutWindowsResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__HallPassManager.GetHallPassBlackOutWindows, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  getHallPasses(request: GetHallPassesRequest): Promise<GetHallPassesResponse>;
  getHallPasses(request: GetHallPassesRequest, metadata: grpc.Metadata): Promise<GetHallPassesResponse>;
  getHallPasses(request: GetHallPassesRequest, callback: (err: any|null, response: GetHallPassesResponse, metadata: grpc.Metadata) => void): void;
  getHallPasses(request: GetHallPassesRequest, metadata: grpc.Metadata, callback: (err: any|null, response: GetHallPassesResponse, metadata: grpc.Metadata) => void): void;

  getHallPasses(request: GetHallPassesRequest, arg1?: grpc.Metadata|((err: any|null, response: GetHallPassesResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: GetHallPassesResponse, metadata: grpc.Metadata) => void): Promise<GetHallPassesResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<GetHallPassesResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__HallPassManager.GetHallPasses, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  updateHallPass(request: UpdateHallPassRequest): Promise<UpdateHallPassResponse>;
  updateHallPass(request: UpdateHallPassRequest, metadata: grpc.Metadata): Promise<UpdateHallPassResponse>;
  updateHallPass(request: UpdateHallPassRequest, callback: (err: any|null, response: UpdateHallPassResponse, metadata: grpc.Metadata) => void): void;
  updateHallPass(request: UpdateHallPassRequest, metadata: grpc.Metadata, callback: (err: any|null, response: UpdateHallPassResponse, metadata: grpc.Metadata) => void): void;

  updateHallPass(request: UpdateHallPassRequest, arg1?: grpc.Metadata|((err: any|null, response: UpdateHallPassResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: UpdateHallPassResponse, metadata: grpc.Metadata) => void): Promise<UpdateHallPassResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<UpdateHallPassResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__HallPassManager.UpdateHallPass, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  archiveHallPass(request: ArchiveHallPassRequest): Promise<ArchiveHallPassResponse>;
  archiveHallPass(request: ArchiveHallPassRequest, metadata: grpc.Metadata): Promise<ArchiveHallPassResponse>;
  archiveHallPass(request: ArchiveHallPassRequest, callback: (err: any|null, response: ArchiveHallPassResponse, metadata: grpc.Metadata) => void): void;
  archiveHallPass(request: ArchiveHallPassRequest, metadata: grpc.Metadata, callback: (err: any|null, response: ArchiveHallPassResponse, metadata: grpc.Metadata) => void): void;

  archiveHallPass(request: ArchiveHallPassRequest, arg1?: grpc.Metadata|((err: any|null, response: ArchiveHallPassResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: ArchiveHallPassResponse, metadata: grpc.Metadata) => void): Promise<ArchiveHallPassResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<ArchiveHallPassResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__HallPassManager.ArchiveHallPass, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

}

