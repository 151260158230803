import { Injectable } from '@angular/core';

import { gateway } from 'libs/generated-grpc-web';

import {
  SystemAlertModalService,
  SystemAlertModalType,
} from '@shared/components/system-alert-modal';

import { NotificationListener } from '../NotificationListener.service';

/**
 * @NOTE this class is only here to handle pre-existing actions that were all
 *       clustered together in a 'Notifications' service. If you are adding to
 *       this class and the purpose is not to fix a bug that should be a red
 *       flag. Consider using ngrx store for what you're trying to accomplish
 *       and do it in a different service.
 */
@Injectable({ providedIn: 'root' })
export class LegacyNotificationHandler {
  constructor(
    private _systemAlertModal: SystemAlertModalService,
    notificationListener: NotificationListener,
  ) {
    notificationListener.notificationRecevied$.subscribe(notification => {
      switch (notification.getActionCase()) {
        case gateway.notification_pb.Notification.ActionCase
          .ACTION_ALERT_CONTENT:
          return this._handleAlertContent(
            notification,
            notification.getActionAlertContent(),
          );
      }
    });
  }

  private _handleAlertContent(
    notification: gateway.notification_pb.Notification,
    action: gateway.notification_pb.Notification.ActionAlertContent,
  ) {
    const title = action.getTitle();
    const body = action.getBody();
    if (title || body) {
      this._systemAlertModal.open({
        heading: title,
        message: body,
        modalType: SystemAlertModalType.ERROR,
        icon: 'mg-alert-o',
      });
    }
  }
}
