import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { MediaObserver } from '@angular/flex-layout';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Router } from '@angular/router';

import * as _ from 'lodash';
import { day } from 'libs/day';
import { MingaPermission } from 'libs/domain';
import {
  gateway,
  delta_pb,
  post_pb,
  image_pb,
  post_ng_grpc_pb,
} from 'libs/generated-grpc-web';
import { IDesignedSvg } from 'libs/shared';
import { DesignedSvgMapper } from 'libs/shared-grpc';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { SwipeContainerComponent } from '@app/src/app/components/SwipeContainer';
import { CommentListViewComponent } from '@app/src/app/content-views/components/CommentListView';
import { DeleteImageConfirmationComponent } from '@app/src/app/dialog';
import { Group } from '@app/src/app/groups/models';
import { GroupsFacadeService } from '@app/src/app/groups/services';
import { AuthService } from '@app/src/app/minimal/services/Auth';
import { AuthInfoService } from '@app/src/app/minimal/services/AuthInfo';
import { ContentEvents } from '@app/src/app/minimal/services/ContentEvents';
import { LikeService } from '@app/src/app/minimal/services/Like';
import {
  IMgModal,
  MgModalService,
} from '@app/src/app/minimal/services/MgModal';
import { RootService } from '@app/src/app/minimal/services/RootService';
import { PermissionsService } from '@app/src/app/permissions';
import { CommentHandlerService } from '@app/src/app/services/CommentHandler';
import { ContentState } from '@app/src/app/services/ContentState';
import {
  IDeletedContextReasons,
  ReportService,
} from '@app/src/app/services/Report';
import { IVideoData, VideoService } from '@app/src/app/services/Video';
import { mgResolveAssetUrl } from '@app/src/app/util/asset';
import { IBox, fitIn } from '@app/src/app/util/box';
import { numeral } from '@app/src/app/util/numeral';
import { scrollIntoView } from '@app/src/app/util/scroll-into-view';

import { SystemAlertSnackBarService } from '@shared/components/system-alert-snackbar';

import { ICommentDisplay } from '../CommentView/CommentView.component';

export interface ILongCardDisplayImage {
  index: number;
  progressiveUrls: string[];
  loading: boolean;
  width: number;
  height: number;
  url: string;
}

@Component({
  selector: 'mg-long-card',
  styleUrls: ['./LongCard.component.scss'],
  templateUrl: './LongCard.component.html',
})
export class LongCardComponent
  implements OnChanges, AfterViewInit, OnInit, OnDestroy
{
  /** @internal */
  private _content: gateway.content_views_pb.LongCardView.AsObject | null =
    null;
  /** @internal */
  private _designedSvg: IDesignedSvg | null = null;

  deleting: boolean = false;

  likeSubscription: Subscription;
  currentCommentsPage: number = -1;
  comments: ICommentDisplay[] = [];
  commentCount: number = 0;
  canViewLikes: boolean = false;
  likeClickAnimation: boolean = false;
  contextDeleteData: IDeletedContextReasons = null;
  ownerGroupHash: BehaviorSubject<string> = new BehaviorSubject('');
  group$: Observable<Group>;

  imagesContainerWidth: number = 0;
  displayImages: ILongCardDisplayImage[] = [];
  activeImageIndex: number = 0;

  videoData: IVideoData;
  videoProcessingText: string = '';

  mqAlias: string = '';
  mqAliasSubscription: Subscription;

  canComment$: Observable<boolean>;
  private _commentDataChanged: BehaviorSubject<boolean> = new BehaviorSubject(
    true,
  );
  private _commentDataChanged$: Observable<boolean>;

  /**
   * Optionally supply the Short Card content as a starting point for this
   * long card. It will use the short card content as intemediate data for
   * displaying some information while it loads the rest of the content.
   */
  @Input()
  shortCardContent?: gateway.content_views_pb.ShortCardView.AsObject;

  /**
   * If only the contextHash is supplied the entire long card will wait until
   * it is loaded from the server before displaying.
   */
  @Input()
  contextHash?: string;

  /**
   * Used for editing.
   */
  @Input()
  contentHash?: string;

  /**
   * The long card view. This will get populated by contextHash if available
   * and will be partially filled in by shortCardContent if available. It can
   * also be set directly here.
   */
  @Input()
  set content(content: gateway.content_views_pb.LongCardView.AsObject | null) {
    this._content = content || null;
    this.setDesignedSvgFromContent(this.content);
  }

  get content() {
    return this._content;
  }

  // Updates when `content` updates
  get designedSvg(): IDesignedSvg | null {
    return this._designedSvg;
  }

  /**
   *
   */
  @Input() hideActions: boolean;

  /**
   * Used to disable comments and likes
   */
  @Input() disableActions: boolean;
  @Input() noVideoAutoPlay: boolean;

  @Input() showTopNav = true;

  @ViewChild('debugTemplate', { read: TemplateRef, static: true })
  debugTemplate: TemplateRef<any>;

  @ViewChild('commentListViewEl', { static: false })
  commentListEl: CommentListViewComponent;

  @ViewChild('swipeContainer', { static: false })
  swipeContainer?: SwipeContainerComponent;

  @ViewChild('lightBoxSwipeContainer', { static: false })
  lightBoxSwipeContainer?: SwipeContainerComponent;

  @ViewChild('imageLightBox', { static: true })
  imageLightBox?: TemplateRef<any>;

  safeFullBodyHtml: SafeHtml;

  private _lightBoxModal?: IMgModal;

  constructor(
    private matDialog: MatDialog,
    private rootService: RootService,
    private router: Router,
    private contentEvents: ContentEvents,
    private contentState: ContentState,
    private video: VideoService,
    public media: MediaObserver,
    private sanitizer: DomSanitizer,
    protected permissions: PermissionsService,
    protected auth: AuthService,
    private likeService: LikeService,
    private element: ElementRef,
    private modal: MgModalService,
    private postService: post_ng_grpc_pb.Post,
    private _systemAlertSnackBar: SystemAlertSnackBarService,
    private reportService: ReportService,
    private commentHandler: CommentHandlerService,
    private groupFacade: GroupsFacadeService,
    private authInfoService: AuthInfoService,
  ) {
    this.mqAliasSubscription = media.asObservable().subscribe(mediaChanges => {
      this.mqAlias = mediaChanges[0].mqAlias;
    });

    this.group$ = this.ownerGroupHash.asObservable().pipe(
      switchMap(groupHash => {
        return this.groupFacade.getGroup(groupHash);
      }),
    );

    this._commentDataChanged$ = this._commentDataChanged.asObservable();
    this.canComment$ = this._commentDataChanged$.pipe(
      switchMap(() => {
        return this.commentHandler.canCurrentUserComment(
          this.content?.allowedCommentingRoleTypesList,
          this.content.ownerGroupHash,
        );
      }),
    );
  }

  private _lightBoxContainerSize(): IBox {
    return {
      height: innerHeight - Math.max(100, innerHeight * 0.15),
      width: innerWidth - Math.max(50, innerWidth * 0.1),
    };
  }

  lightBoxImageWidth(img) {
    return fitIn(img, this._lightBoxContainerSize()).width;
  }

  lightBoxImageHeight(img) {
    return fitIn(img, this._lightBoxContainerSize()).height;
  }

  closeLightBox() {
    if (this._lightBoxModal) {
      this._lightBoxModal.close();
    }
  }

  private _getImageAsset(index: number): string {
    const imageList = this.content.imageList;
    const sizeMap = imageList[index].sizeMap;

    for (let [key, value] of sizeMap) {
      if (key === 'raw') {
        return value.path;
      }
    }

    return '';
  }

  isCurrentAuthor() {
    if (this.content && this.content.authorPersonView) {
      const authorHash = this.content.authorPersonView.personHash;
      return this.authInfoService.authPerson?.hash == authorHash;
    }

    return false;
  }

  deleteImageConfirm() {
    const dialog = this.matDialog.open(DeleteImageConfirmationComponent, {
      data: {
        titleLocaleValue: 'picture',
        showSubTitle: false,
      },
    });

    dialog.beforeClosed().subscribe(async value => {
      if (value) {
        const activeIndex = this.activeImageIndex;
        const deltaPostInfo = new post_pb.DeltaPostInfo();
        const assetList = new delta_pb.RepeatedStringDelta();
        const eraseInst = new delta_pb.RepeatedStringDelta.EraseInstruction();
        eraseInst.setEraseOffset(activeIndex);
        eraseInst.setEraseString(this._getImageAsset(activeIndex));

        deltaPostInfo.setContentHash(this.contentHash);
        assetList.addEraseInstruction(eraseInst);
        deltaPostInfo.setAssetList(assetList);

        const [removedImage] = this.content.imageList.splice(activeIndex, 1);
        const [displayImage] = this.displayImages.splice(activeIndex, 1);
        this.swipeContainer.swiper.removeSlide(activeIndex);
        this.lightBoxSwipeContainer.swiper.removeSlide(activeIndex);

        await this.rootService
          .addLoadingPromise(this.postService.update(deltaPostInfo))
          .catch(err => {
            this._systemAlertSnackBar.error(
              'Could not delete photo. Try again later.',
            );
            this.content.imageList.splice(activeIndex, 0, removedImage);
            this.displayImages.splice(activeIndex, 0, displayImage);
          });

        this.contentEvents.emitShortCardViewUpdateEvent({
          contentContextHash: this.contextHash,
          imageList: this.content.imageList,
        });

        if (this.displayImages.length == 0) {
          this.closeLightBox();
        }
      }
    });
  }

  openImageLightBox(e) {
    this._lightBoxModal = this.modal.open(this.imageLightBox, {
      full: true,
      animation: 'fade',
      backgroundColor: '#000000',
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize(e) {
    this.calcImageDimensions();
  }

  get isLiked() {
    if (!this.contextHash) return false;
    return this.likeService.isLiked(this.contextHash);
  }

  getLikeCount() {
    let likeCount = 0;
    if (this.content) {
      likeCount = this.content.likeCount;
    }

    return this.likeService.likeCount(this.contextHash, likeCount);
  }

  shouldLoadImage(imgIndex: number) {
    return _.inRange(
      imgIndex,
      this.activeImageIndex - 2,
      this.activeImageIndex + 2,
    );
  }

  ngAfterViewInit() {
    let focusedElsewhere = false;

    const consumedImageIndex = this.contentState.getStateValue(
      this.contextHash,
      'imageFocusIndex',
    );
    if (consumedImageIndex) {
      this.activeImageIndex = <number>consumedImageIndex;
    }

    // click on overlay-nav or this element to fix iOS focus on <html> and user
    // stuck..
    if (!focusedElsewhere) {
      const overlayNav = document.getElementsByClassName('overlay-wrap');
      if (overlayNav && overlayNav.length) {
        scrollIntoView(
          // @TODO: @types/scroll-into-view disagree with these typings
          <any>overlayNav[0],
          {
            time: 0, // default is half a second
          },
        );
      } else {
        // scrollIntoView(this.element.nativeElement);
      }
    }
  }

  ngOnInit() {
    if (this.content) {
      this.calcImageDimensions();
      this.safeFullBodyHtml = this.sanitizer.bypassSecurityTrustHtml(
        this.content.fullBody,
      );

      this.commentCount = this.content.totalCommentCount;
      this.comments = this.content.commentList.map(obj => ({ obj }));
      this.currentCommentsPage = this.content.commentListPage;

      // update the short card, as fetchContent for long cards give latest data
      this.emitShortCardUpdate();
    }

    this._initLikeChangedHandler();

    this.contextDeleteData = this.reportService.getDeletedReasons(
      this.contextHash,
    );

    // disable actions if this card is deleted
    this.disableActions =
      !this.disableActions && !!this.contextDeleteData
        ? true
        : this.disableActions;

    this.setViewLikesPermission();

    if (this.content) {
      if (this.content.ownerGroupHash) {
        this.ownerGroupHash.next(this.content.ownerGroupHash);
      }
    }

    if (this.content) {
      if (this.content.hasLiked) {
        this.likeService.setLike(this.contextHash);
      }
    }
  }

  ngOnDestroy() {
    this.mqAliasSubscription.unsubscribe();
  }

  _initLikeChangedHandler() {
    if (this.likeSubscription) {
      this.likeSubscription.unsubscribe();
      this.likeSubscription = null;
    }

    const sub = this.likeService
      .onLikedChangedUnique(this.contextHash)
      .subscribe(liked => {
        if (liked) {
          this.content.likeCount += 1;
        } else {
          this.content.likeCount -= 1;
        }
      });

    this.likeSubscription = sub;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.contextHash && !changes.contextHash.isFirstChange()) {
      this._initLikeChangedHandler();
    }

    // Was given short card, and doesn't have long card
    if (changes.shortCardContent) {
      if (!this.content) {
        this.partiallyFillContent(this.shortCardContent);
        this.calcImageDimensions();
      }
    }

    if (changes.content) {
      this.calcImageDimensions();
      this._commentDataChanged.next(true);
      this.safeFullBodyHtml = this.sanitizer.bypassSecurityTrustHtml(
        this.content.fullBody,
      );
      // update short card with latest data
      if (
        this.shortCardContent &&
        this.shortCardContent.totalCommentCount !=
          this.content.totalCommentCount
      ) {
        this.emitShortCardUpdate();
      }
    }

    if (changes.contextHash) {
      this.loadContentFromHash(this.contextHash);
    }
  }

  async calcImageDimensions() {
    let imageList = this.content.imageList;
    this.displayImages = [];

    // If we don't have any images, just cut early.
    if (imageList.length == 0) {
      return;
    }

    for (let imageInfo of imageList) {
      const sizeMap: { [key: string]: image_pb.ImageSize.AsObject } = {};
      imageInfo.sizeMap.forEach(entry => {
        sizeMap[entry[0]] = entry[1];
      });

      const progressiveUrls: string[] = [];

      const sizeInfo =
        sizeMap['longcardbanner'] || sizeMap['cardbanner'] || sizeMap['raw'];

      if (sizeMap['blurloading1']) {
        progressiveUrls.push(sizeMap['blurloading1'].path);
      }

      progressiveUrls.push(sizeInfo.path);

      this.displayImages.push({
        index: this.displayImages.length,
        progressiveUrls: progressiveUrls.map(url => {
          return mgResolveAssetUrl(url);
        }),
        url: mgResolveAssetUrl(sizeInfo.path),
        width: sizeInfo.width,
        height: sizeInfo.height,
        loading: false,
      });
    }

    // adjust video to match new image dimensions
    this.initVideoData();
  }

  onImageChanged(img: ILongCardDisplayImage, newUrl: string) {
    img.url = newUrl;
  }

  loadContentFromHash(contentHash: string) {}

  numberize(count: number | string) {
    let value = numeral(count).value();

    if (value > 1000) {
      let numberized: string = numeral(value).format('0a');
      return numberized;
    } else {
      return value;
    }
  }

  humanize(timestamp: number | string) {
    if (typeof timestamp == 'string') {
      timestamp = parseInt(timestamp);
    }

    return day(timestamp).fromNow();
  }

  openDebug() {
    this.matDialog.open(this.debugTemplate, {
      closeOnNavigation: true,
    });
  }

  partiallyFillContent(
    shortCard: gateway.content_views_pb.ShortCardView.AsObject,
  ) {
    this.content = {
      authorAvatarUrl: shortCard.authorAvatarUrl,
      authorDisplayName: shortCard.authorDisplayName,
      title: shortCard.title,
      fullBody: shortCard.body,
      imageList: shortCard.imageList,
      commentCount: shortCard.commentCount,
      totalCommentCount: shortCard.totalCommentCount,
      likeCount: shortCard.likeCount,
      timestamp: shortCard.timestamp,
      videoUrl: shortCard.videoUrl,
      mediaWidth: shortCard.mediaWidth,
      mediaHeight: shortCard.mediaHeight,
      commentList: [],
      commentListPage: 0,
      authorIconColor: shortCard.authorIconColor,
      authorIconUrl: shortCard.authorIconUrl,
      authorRoleName: shortCard.authorRoleName,
      backgroundColor: shortCard.backgroundColor,
      gradientOverlap: shortCard.gradientOverlap,
      lightContent: shortCard.lightContent,
      disableActions: false,
      gradientSize: shortCard.gradientSize,
      cloudflareVideoUrl: shortCard.cloudflareVideoUrl,
      cloudflareUid: '',
      cloudflareReady: false,
      allowedCommentingRoleTypesList: [],
      ownerGroupHash: '',
      hasLiked: shortCard.hasLiked,
    };
  }

  initVideoData() {
    if (
      !this.content ||
      (!this.content.videoUrl && !this.content.cloudflareUid)
    ) {
      return false;
    }

    let videoData: IVideoData = {
      url: this.content.videoUrl,
      title: this.content.title,
      thumbnails: [],
      width: this.content.mediaWidth,
      height: this.content.mediaHeight,
      cloudflareVideoUrl: this.content.cloudflareVideoUrl,
      cloudflareUid: this.content.cloudflareUid,
      cloudflareReady: this.content.cloudflareReady,
    };

    if (this.content.imageList) {
      let imageData = this.displayImages[0];
      // only give videoData that has dimension data
      if (!imageData.width) return false;

      videoData.thumbnails.push({
        url: imageData.url,
        width: imageData.width,
        height: imageData.height,
      });
    } else {
      return false;
    }

    this.videoData = videoData;
  }

  async like(e?) {
    if (e) {
      e.stopImmediatePropagation();
      e.preventDefault();
    }

    this.likeClickAnimation = true;

    setTimeout(() => {
      this.likeClickAnimation = false;
    }, 600);
    await this.likeService.toggleLike(this.contextHash);
  }

  async setViewLikesPermission() {
    if (
      this.content &&
      this.content.authorPersonView.personHash ==
        this.authInfoService.authPerson?.hash
    ) {
      this.canViewLikes = this.permissions.hasPermission(
        MingaPermission.CONTENT_LIKES_LIKE,
      );
    } else {
      this.canViewLikes = this.permissions.hasPermission(
        MingaPermission.CONTENT_LIKES_VIEW,
      );
    }
  }

  viewLikes() {
    this.contentEvents.emitOnSeePeopleLikes({
      content: this.content,
      contextHash: this.contextHash,
    });
  }

  private emitShortCardUpdate() {
    let commentList: gateway.content_views_pb.FeedComment.AsObject[] = [];
    for (let commentDisplay of this.comments) {
      let comment = commentDisplay.obj;

      if (comment.deleted) continue;

      commentList.push({
        body: comment.text,
        timestamp: comment.timestamp,
        personView: comment.authorPersonView,
        commentContextHash: comment.commentContextHash,
        likeCount: comment.likeCount,
        bodyMentionRangeList: comment.textMentionRangeList,
        displayName: null,
        hasLiked: false,
      });
    }
    this.contentEvents.emitShortCardViewUpdateEvent({
      contentContextHash: this.contextHash,
      commentList: commentList,
      totalCommentCount: this.content.totalCommentCount,
      commentCount: this.content.commentCount,
      likeCount: this.content.likeCount,
    });
  }

  onCommentCountUpdate(count: number) {
    this.commentCount = count;
  }

  private setDesignedSvgFromContent(
    content: gateway.content_views_pb.LongCardView.AsObject | null,
  ) {
    if (content && content.designedSvg) {
      this._designedSvg = DesignedSvgMapper.asObjectToIDesignedSvg(
        content.designedSvg,
      );
    } else {
      this._designedSvg = null;
    }
  }

  groupLinkClick(ev: MouseEvent) {
    ev.preventDefault();
    ev.stopImmediatePropagation();
    ev.stopPropagation();
    if (this.ownerGroupHash) {
      this.router
        .navigate(['', { outlets: { o: null } }])
        .then(() => this.contentState.openGroup(this.ownerGroupHash.value));
    }
  }

  replyClick() {
    this.commentListEl.replyClick();
  }
}
