import { Injectable } from '@angular/core';

import { Observable, Subject } from 'rxjs';

import { IMgStreamControl } from '@app/src/app/util/stream';

export interface IStreamEvent {
  name: string;
}

export interface IStreamManagerMgStreamControl {
  readonly streamControl: IMgStreamControl<any> | null;
}

@Injectable({ providedIn: 'root' })
export class StreamManager {
  private _streams: Map<string, IStreamManagerMgStreamControl>;
  private _onStreamRestart: Subject<IStreamEvent>;

  constructor() {
    this._streams = new Map();

    this._onStreamRestart = new Subject();
  }

  get onStreamRestart(): Observable<IStreamEvent> {
    return this._onStreamRestart.asObservable();
  }

  emitStreamRestart(ev: IStreamEvent) {
    this._onStreamRestart.next(ev);
  }

  isRegistered(name: string) {
    return this._streams.has(name);
  }

  register(name: string, stream: IStreamManagerMgStreamControl) {
    this._streams.set(name, stream);
  }

  unregister(name: string) {
    this._streams.delete(name);
  }

  getStream(name: string) {
    if (!this.isRegistered(name)) {
      throw new Error(`Stream '${name}' is not registered`);
    }

    return this._streams.get(name);
  }

  async restartStream(name: string) {
    let stream = this.getStream(name);
    if (stream && stream.streamControl) {
      await stream.streamControl.restart({ clear: true });
    }
    this.emitStreamRestart({ name: name });
  }

  async restartStreamIfAvailable(name: string) {
    // we emit this because we are also using this for pseudo-streaming
    // done via mg-paged-scroller
    this.emitStreamRestart({ name: name });
    if (!this.isRegistered(name)) {
      return false;
    }

    return this.restartStream(name);
  }

  async restartStreams(streamName: string | string[]) {
    if (!!streamName) {
      if (Array.isArray(streamName)) {
        streamName.forEach(streamName => {
          this.restartStreamIfAvailable(streamName);
        });
      } else {
        await this.restartStreamIfAvailable(streamName);
      }
    }
  }

  async restartAllStreams() {
    const streamNames = this._streams.keys();
    const promises = [];

    for (let streamName of streamNames) {
      promises.push(this.restartStream(streamName));
    }

    return Promise.all(promises);
  }
}
