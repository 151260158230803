import { PersonViewMinimal } from 'libs/generated-grpc-ts';

import { EditableReportRecord } from '../';
import { IMembershipList } from '../';
import { Restriction } from '../';
import { StudentSection } from '../studentSchedule';

export enum PbisNameCategory {
  PRAISE = 'praise',
  GUIDANCE = 'guidance',
  CONSEQUENCE = 'consequence',
}

export interface IPbisType {
  id?: number;
  name?: string;
  categoryId?: number;
  points?: number;
  active?: boolean;
  createdBy?: number;
  mingaId?: number;
  automationIds?: number[];
  sendParentEmail?: boolean;
  sendParentSms?: boolean;
  sendAdminEmail?: boolean;
  adminEmails?: string[];
  dailyBehaviorCount?: number;
  hallPassTypeId?: number;
  canTrackMembershipListId?: number;
  canTrackMembershipList?: IMembershipList;
  emailTemplate?: {
    subject: string;
    body: string;
  };
  smsTemplate?: {
    body: string;
  };
  iconType?: string;
  iconColor?: string;
  restriction?: Restriction;
}

export interface IPbisTypeCategory {
  id?: number;
  name?: string;
}

export type IDefaultPbisType = Pick<
  IPbisType,
  'name' | 'categoryId' | 'points' | 'active'
>;

export interface IPbisBehavior {
  id: number;
  mingaId?: number;
  message?: string;
  pbisTypeId?: number;
  type?: IPbisType;
  recipientId?: number;
  recipient?: PersonViewMinimal.AsObject;
  recipientEmail?: string;
  createdBy?: number;
  creator?: PersonViewMinimal.AsObject;
  createdAt?: any;
  points?: number | null;
  imported?: boolean;
  currentClass?: StudentSection[];
}

export type EditablePbisBehavior = IPbisBehavior & EditableReportRecord;

export interface PbisCategoryData {
  id: number;
  name: string;
  color: string;
}

export interface IPbisClassTypeCount {
  count: number;
  id?: number;
  name?: string;
  categoryId?: number;
  active?: boolean;
  iconType?: string;
  iconColor?: string;
}
