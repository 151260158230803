// TS2742
import * as $ngrx_store_store from '@ngrx/store';
import * as $ngrx_store_src_models from '@ngrx/store/src/models';
import { EntityMap, Predicate, Update } from '@ngrx/entity';
import { createAction, props } from '@ngrx/store';
// TS2742
import {
  IConversationWithReadStatus,
  IMessage,
  IMessageAttachment,
} from 'libs/domain';

export namespace MessagingActions {
  export const fetchMessages = createAction(
    '[Messaging] Fetch Messages',
    props<{ conversationId: number }>(),
  );
  export const fetchMessagesSuccess = createAction(
    '[Messaging] Fetched Messages Successfully',
    props<{ conversationId: number; messages: IMessage[] }>(),
  );
  export const fetchMessagesError = createAction(
    '[Messaging] Fetch Messages Failed',
    props<{ conversationId: number; error: any }>(),
  );
  export const fetchConversation = createAction(
    '[Messaging] Fetch Single Conversation',
    props<{ conversationId: number }>(),
  );
  export const fetchConversationSuccess = createAction(
    '[Messaging] Fetched Single Conversation Successfully',
    props<{
      conversationId: number;
      conversation: IConversationWithReadStatus;
    }>(),
  );
  export const fetchConversationError = createAction(
    '[Messaging] Fetched Single Conversation Error',
    props<{ conversationId: number; error: any }>(),
  );
  export const fetchConversations = createAction(
    '[Messaging] Fetch Conversations',
    props<any>(),
  );
  export const fetchConversationsSuccess = createAction(
    '[Messaging] Fetched Conversations Successfully',
    props<{ conversations: IConversationWithReadStatus[] }>(),
  );
  export const fetchConversationsError = createAction(
    '[Messaging] Fetched Conversations Error',
    props<{ error: any }>(),
  );
  export const setMessages = createAction(
    '[Messaging] Set Messages',
    props<{ conversationId: number; messages: IMessage[] }>(),
  );
  export const sendMessage = createAction(
    '[Messaging] Send Message',
    props<{
      conversationId: number;
      tempMessageId: number;
      authorPersonHash: string;
      bodyText: string;
      assetPathList: string[];
    }>(),
  );
  export const sendMessageSuccess = createAction(
    '[Messaging] Sent Message Successfully',
    props<{
      conversationId: number;
      tempMessageId: number;
      message: IMessage;
    }>(),
  );
  export const sendMessageError = createAction(
    '[Messaging] Send Message Failed',
    props<{ conversationId: number; tempMessageId: number; error: IMessage }>(),
  );
  export const receiveNewMessage = createAction(
    '[Messaging] Received new message',
    props<{ conversationId: number; newMessage: IMessage }>(),
  );
  export const clickNewMessageNotification = createAction(
    '[Messaging] Click new message notification',
    props<{ conversationId: number }>(),
  );
  export const markConversationAsRead = createAction(
    '[Messaging] Mark conversation as read',
    props<{ conversationId: number; personHash: string }>(),
  );
  export const markMessageAsRead = createAction(
    '[Messaging] Mark single message as read',
    props<{ messageId: number }>(),
  );

  export type Union =
    | typeof fetchMessages
    | typeof fetchMessagesSuccess
    | typeof fetchMessagesError
    | typeof setMessages
    | typeof fetchConversation
    | typeof fetchConversationError
    | typeof fetchConversationSuccess
    | typeof sendMessage
    | typeof sendMessageSuccess
    | typeof sendMessageError
    | typeof receiveNewMessage;
}
