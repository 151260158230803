/* eslint-disable prefer-arrow/prefer-arrow-functions */
export enum mingaSettingTypes {
  GOOGLE_VISION_SAFE_SEARCH_ADULT = 'googleVision.safe_search_category_minimums.adult',
  GOOGLE_VISION_SAFE_SEARCH_MEDICAL = 'googleVision.safe_search_category_minimums.medical',
  GOOGLE_VISION_SAFE_SEARCH_VIOLENCE = 'googleVision.safe_search_category_minimums.violence',
  GOOGLE_VISION_SAFE_SEARCH_RACY = 'googleVision.safe_search_category_minimums.racy',
  GOOGLE_VISION_SAFE_SEARCH_SPOOF = 'googleVision.safe_search_category_minimums.spoof',
  GOOGLE_VISION_LABEL_CONFIDENCE_MIN = 'googleVision.label_confidence_minimum',
  GOOGLE_VISION_LABEL_FLAGGED_WORDS = 'googleVision.label_flagged_words',

  FEATURE_ENABLE_GROUPS = 'feature_flags.enable_groups',
  FEATURE_ENABLE_JOIN_CODE = 'feature_flags.enable_public_join_code',
  FEATURE_MONTHLY_DIGEST_EMAIL = 'feature_flags.enable_monthly_digest_email',
  FEATURE_DIRECT_MESSAGING_PERSONAL_PREFERENCE = 'feature_flags.direct_messaging_personal_preference',
  FEATURE_DEMO_DATA = 'feature_flags.demo_data',
  FEATURE_MINGA_DESIGNER = 'feature_flags.minga_designer',
  FEATURE_TRACKING_TEACHER_POINTS = 'feature_flags.tracking_teacher_points',
  FEATURE_TRACKING_STAFF_POINTS = 'feature_flags.tracking_staff_points',
  FEATURE_TRACKING_PUBLIC_LEADERBOARD = 'feature_flags.tracking_public_leaderboard',
  FEATURE_CHALLENGES = 'feature_flags.challenges',
  FEATURE_DISTRICT_ENABLED = 'feature_flags.minga_district',
  FEATURE_SIS = 'feature_flags.sis',
  FEATURE_ALLOW_ACCOUNT_DELETION = 'feature_flags.allow_user_account_deletion',
  FEATURE_ALLOW_STUDENT_EMAIL_CHANGE = 'feature_flags.allow_student_email_change',

  MODERATION_ENABLED = 'moderation.enabled',
  MODERATION_BLOCK = 'moderation.block',

  // STUDENT ID MINGA SETTINGS
  ID_MINGA_STUDENT_ID = 'student_id_settings.minga_student_id',
  ID_SCHOOL_NAME = 'student_id_settings.school_name',
  ID_LOGO_ASSET_PATH = 'student_id_settings.logo_asset_path',
  ID_BACKGROUND_COLOR = 'student_id_settings.background_color',
  ID_FONT_COLOR = 'student_id_settings.font_color',
  ID_BARCODE_TYPE = 'student_id_settings.barcode_type',
  ID_SHOW_ROLE = 'student_id_settings.show_role',
  ID_SHOW_GRADE = 'student_id_settings.show_grade',
  ID_UPLOAD_TEMP_PHOTO = 'student_id_settings.upload_temp_photo',
  ID_SHOW_ENGLISH_SUICIDE_LIFELINE = 'student_id_settings.show_eng_suicide_lifeline',
  ID_SHOW_SPANISH_SUICIDE_LIFELINE = 'student_id_settings.show_spa_suicide_lifeline',
  ID_SHOW_DOMESTIC_VIOLENCE_HOTLINE = 'student_id_settings.show_nat_domestic_violence',
  ID_SHOW_CUSTOM_LIFELINE = 'student_id_settings.show_custom_lifeline',
  ID_ENABLE_GRADE_COLORS = 'student_id_settings.enable_grade_colors',
  ID_GRADE_COLORS = 'student_id_settings.grade_colors',
  ID_ENABLE_OFFLINE = 'student_id_settings.enable_offline_id',
  ID_CLOCK_FONT_SIZE = 'student_id_settings.clock_font_size',
  HIDE_ID_NUM_ON_IDS = 'student_id_settings.hide_id_num',
  ID_SHOW_ID_FIELD_1 = 'student_id_settings.show_id_field_1',
  ID_SHOW_ID_FIELD_2 = 'student_id_settings.show_id_field_2',
  ID_STAFF_CHANGE_PICTURE = 'student_id_settings.enable_staff_id_picture_change',
  ID_CARD_LAYOUT = 'student_id_settings.id_card_layout',

  // HALL PASS MINGA SETTINGS
  PASS_TEACHERS_GRANT = 'hall_pass_settings.teachers_grant_passes',
  PASS_STAFF_GRANT = 'hall_pass_settings.staff_grant_passes',
  PASS_STUDENTS_GRANT = 'hall_pass_settings.student_self_grant_passes',
  PASS_MAX_PASSES = 'hall_pass_settings.max_passes_per_day',
  PASS_MAX_ACTIVE_PASSES = 'hall_pass_settings.max_active_passes',
  PASS_MAX_STUDENT_PASSES = 'hall_pass_settings.max_student_passes_per_day',
  PASS_MAX_ACTIVE_STUDENT_PASSES = 'hall_pass_settings.max_active_student_passes',
  PASS_DURATION_STUDENT_PASSES = 'hall_pass_settings.student_pass_duration',
  PASS_ASSIGN_STAFF = 'hall_pass_settings.assign_staff_to_pass',
  PASS_MIN_WAIT_STUDENT_PASS = 'hall_pass_settings.min_wait_before_next_student_pass',
  PASS_STAFF_BLACKOUT_BYPASS = 'hall_pass_settings.staff_blackout_bypass',
  PASS_STUDENTS_END_PASSES = 'hall_pass_settings.students_can_end_passes',
  PASS_STUDENTS_END_PASSES_FROM_MOBILE = 'hall_pass_settings.students_can_end_passes_from_mobile',
  PASS_MUST_MANUALLY_END = 'hall_pass_settings.manually_end',
  PASS_TIMEOUT = 'hall_pass_settings.pass_timeout',
  PASS_APPROVAL_REQUEST_TIMEOUT_DURATION = 'hall_pass_settings.pass_approval_request_timeout_duration', // student
  PASS_APPROVAL_REQUEST_TIMEOUT_DURATION_STAFF = 'hall_pass_settings.pass_approval_request_timeout_duration_staff',
  PASS_TEACHER_VIEW_REPORTS = 'hall_pass_settings.teacher_view_reports',
  PASS_ALLOW_NOTE = 'hall_pass_settings.allow_hall_pass_note',
  PASS_APPROVAL_TIMEOUT = 'hall_pass_settings.hall_pass_approval_timeout',
  PASS_SHOW_RECENTLY_ENDED = 'hall_pass_settings.hall_pass_show_recently_ended',
  PASS_RECENTLY_ENDED_FILTER = 'hall_pass_settings.hall_pass_recently_ended_filter',
  PASS_HALLPASS_ENABLE = 'hall_pass_settings.hall_pass_enable',
  PASS_HALLPASS_KIOSK = 'hall_pass_settings.kiosk',

  // PBIS MINGA SETTINGS
  PBIS_HP_TARDY = 'pbis_settings.tracking_hp_tardy',
  PBIS_TEACHERS_TRACK = 'pbis_settings.teachers_can_track',
  PBIS_STAFF_TRACK = 'pbis_settings.staff_can_track',
  PBIS_STUDENT_LEADERS_TRACK = 'pbis_settings.student_leaders_can_track',
  PBIS_INDIVIDUAL_POINTS = 'pbis_settings.show_individual_points',
  PBIS_TEAM_POINTS = 'pbis_settings.show_team_points',

  PM_REWARDS_STORE = 'pm_settings.show_rewards_store',
  PM_TEACHERS_REWARDS = 'pm_settings.teachers_can_manage_rewards',
  PM_STAFF_REWARDS = 'pm_settings.staff_can_manage_rewards',

  LAST_POINTS_RESET = 'last_points_reset',

  // BEHAVIOR MINGA SETTINGS
  BM_CONSEQUENCE_ENABLE = 'bm_settings.enable_consequences',
  BM_CONSEQUENCE_TEACHER = 'bm_settings.teachers_issue_consequences',
  BM_CONSEQUENCE_STAFF = 'bm_settings.staff_issue_consequences',

  // FLEX TIME MINGA SETTINGS
  FTM_TEACHERS_ENABLE = 'ftm_settings.enable_flex_time_teachers',
  FTM_STAFF_ENABLE = 'ftm_settings.enable_flex_time_staff',
  FTM_KIOSK = 'ftm_settings.kiosk',
  FTM_AUTO_END_HALL_PASS_ON_CHECK_IN = 'ftm_settings.auto_end_hall_pass_on_check_in',

  // PRIVACY SETTINGS
  HIDE_ID_NUM = 'privacy.hide_student_id_num',

  // SMS SETTINGS
  ENABLE_SMS = 'sms_settings.enable_sms',

  // CHECKIN SETTINGS
  CHECKIN_TRACKING_TOOLS_TEACHERS = 'checkin_settings.enable_teacher_tracking_tools',
  CHECKIN_TRACKING_TOOLS_STAFF = 'checkin_settings.enable_staff_tracking_tools',
  CHECKIN_KIOSK = 'checkin_settings.kiosk',

  // DEMO DATA
  DEMO_DATA_HALL_PASS_COUNT = 'demo_data.hall_pass_count',
  DEMO_DATA_HALL_PASS_COUNT_RANGE = 'demo_data.hall_pass_count_range',
  DEMO_DATA_CHECKIN_COUNT = 'demo_data.checkin_count',
  DEMO_DATA_CHECKIN_COUNT_RANGE = 'demo_data.checkin_count_range',

  //MISC
  DM_ENABLED_ROLES = 'dmEnabledRoles',
  LEGACY_LAYOUT = 'legacyLayout',

  // MY CLASS ** deprecated
  MY_CLASS_ENABLE = 'my_class.enable',

  // Feature Rollout Groups
  FEATURE_ROLLOUT_ENABLE_BETA = 'feature_rollout.enable_beta',
  FEATURE_ROLLOUT_ENABLE_CUSTOMER_COUNCIL = 'feature_rollout.enable_customer_council',

  DATE_PRESETS_INACTIVE = 'date_presets.inactive',

  // STUDENT SCHEDULE
  STUDENT_SCHEDULE_ENABLE = 'student_schedule.enable',
  BELL_SCHEDULE_ENABLE = 'bell_schedule.enable',
  BELL_SCHEDULE_ENABLE_FOR_TEACHERS_STUDENTS = 'bell_schedule.enable_for_teachers_students',
  TEACHERS_VIEW_PROFILE = 'teachers_view_profile',
}

export enum fieldTypes {
  NUMBER = 'NUMBER',
  STRING = 'STRING',
  STRING_ARRAY = 'STRING[]',
  BOOLEAN = 'BOOLEAN',
  DICTIONARY = '{[KEY: STRING]: STRING}',
  DATE = 'DATE',
}

export type enumTest = fieldTypes & mingaSettingTypes;

export type SettingFieldType =
  | {
      type: fieldTypes.BOOLEAN;
      default?: boolean;
    }
  | {
      type: fieldTypes.STRING;
      default?: string;
    }
  | {
      type: fieldTypes.STRING_ARRAY;
      default?: string[];
    }
  | {
      type: fieldTypes.NUMBER;
      default?: number;
    }
  | {
      type: fieldTypes.DATE;
      default?: Date;
    }
  | {
      type: fieldTypes.DICTIONARY;
      default?: { [KEY: string]: string };
    };

export enum settingsCategories {
  MODERATION = 'Moderation Settings',
  FEATURE_FLAGS = 'Feature Flags',
  STUDENT_ID_SETTINGS = 'Student ID Settings',
  HALL_PASS_SETTINGS = 'Hall Pass Settings',
  PBIS_SETTINGS = 'PBIS Settings',
  CHECKIN_SETTINGS = 'Checkin Settings',
  FLEX_TIME_SETTINGS = 'Flex Time Settings',
  PRIVACY_SETTINGS = 'Privacy Settings',
  MISC_SETTINGS = 'Misc Settings',
}

export interface MingaSettingModel {
  keyName: mingaSettingTypes;
  value: string;
  type?: fieldTypes;
}

export type settingsDesc = {
  category: settingsCategories;
  description: string;
  keyName: mingaSettingTypes;
  frontEnd: boolean;
};

export type settingsMetadata = settingsDesc & SettingFieldType;

export type ValidSettingTypes = Readonly<
  Record<mingaSettingTypes, settingsMetadata>
>;

export const validSettingTypes: ValidSettingTypes = {
  [mingaSettingTypes.GOOGLE_VISION_SAFE_SEARCH_ADULT]: {
    type: fieldTypes.NUMBER,
    category: settingsCategories.MODERATION,
    description: '',
    keyName: mingaSettingTypes.GOOGLE_VISION_SAFE_SEARCH_ADULT,
    default: 0.5,
    frontEnd: false,
  },
  [mingaSettingTypes.GOOGLE_VISION_SAFE_SEARCH_MEDICAL]: {
    type: fieldTypes.NUMBER,
    category: settingsCategories.MODERATION,
    description: '',
    keyName: mingaSettingTypes.GOOGLE_VISION_SAFE_SEARCH_MEDICAL,
    default: 0.75,
    frontEnd: false,
  },
  [mingaSettingTypes.GOOGLE_VISION_SAFE_SEARCH_VIOLENCE]: {
    type: fieldTypes.NUMBER,
    category: settingsCategories.MODERATION,
    description: '',
    keyName: mingaSettingTypes.GOOGLE_VISION_SAFE_SEARCH_VIOLENCE,
    default: 0.5,
    frontEnd: false,
  },
  [mingaSettingTypes.GOOGLE_VISION_SAFE_SEARCH_RACY]: {
    type: fieldTypes.NUMBER,
    category: settingsCategories.MODERATION,
    description: '',
    keyName: mingaSettingTypes.GOOGLE_VISION_SAFE_SEARCH_RACY,
    default: 1,
    frontEnd: false,
  },
  [mingaSettingTypes.GOOGLE_VISION_SAFE_SEARCH_SPOOF]: {
    type: fieldTypes.NUMBER,
    category: settingsCategories.MODERATION,
    description: '',
    keyName: mingaSettingTypes.GOOGLE_VISION_SAFE_SEARCH_SPOOF,
    default: 1.5,
    frontEnd: false,
  },
  [mingaSettingTypes.GOOGLE_VISION_LABEL_CONFIDENCE_MIN]: {
    type: fieldTypes.NUMBER,
    category: settingsCategories.MODERATION,
    description: '',
    keyName: mingaSettingTypes.GOOGLE_VISION_LABEL_CONFIDENCE_MIN,
    default: 0.5,
    frontEnd: false,
  },
  [mingaSettingTypes.GOOGLE_VISION_LABEL_FLAGGED_WORDS]: {
    type: fieldTypes.STRING_ARRAY,
    category: settingsCategories.MODERATION,
    description: '',
    keyName: mingaSettingTypes.GOOGLE_VISION_LABEL_FLAGGED_WORDS,
    default: [
      'racism',
      'racist',
      'handgun',
      'gun accessory',
      'gun barrel',
      'revolver',
      'gun',
      'weapon',
      'alcohol',
      'knife',
    ],
    frontEnd: false,
  },
  [mingaSettingTypes.LAST_POINTS_RESET]: {
    type: fieldTypes.DATE,
    category: settingsCategories.MISC_SETTINGS,
    description: '',
    keyName: mingaSettingTypes.LAST_POINTS_RESET,
    frontEnd: false,
  },
  [mingaSettingTypes.FEATURE_ENABLE_GROUPS]: {
    type: fieldTypes.BOOLEAN,
    category: settingsCategories.FEATURE_FLAGS,
    description: '',
    keyName: mingaSettingTypes.FEATURE_ENABLE_GROUPS,
    default: true,
    frontEnd: true,
  },
  [mingaSettingTypes.FEATURE_ENABLE_JOIN_CODE]: {
    type: fieldTypes.BOOLEAN,
    category: settingsCategories.FEATURE_FLAGS,
    description: `This will generate a 4 letter code that can be used to join your Minga - anyone with this code will be able to join.`,
    keyName: mingaSettingTypes.FEATURE_ENABLE_JOIN_CODE,
    frontEnd: true,
  },
  [mingaSettingTypes.FEATURE_MONTHLY_DIGEST_EMAIL]: {
    type: fieldTypes.BOOLEAN,
    category: settingsCategories.FEATURE_FLAGS,
    description: ``,
    keyName: mingaSettingTypes.FEATURE_MONTHLY_DIGEST_EMAIL,
    frontEnd: true,
  },
  [mingaSettingTypes.FEATURE_DIRECT_MESSAGING_PERSONAL_PREFERENCE]: {
    type: fieldTypes.BOOLEAN,
    category: settingsCategories.FEATURE_FLAGS,
    description: `Enable the ability for a user to disable/enable direct messaging in their personal preferences`,
    keyName: mingaSettingTypes.FEATURE_DIRECT_MESSAGING_PERSONAL_PREFERENCE,
    frontEnd: true,
  },
  [mingaSettingTypes.FEATURE_MINGA_DESIGNER]: {
    type: fieldTypes.BOOLEAN,
    category: settingsCategories.FEATURE_FLAGS,
    description: `Enable the ability to create Studio content`,
    keyName: mingaSettingTypes.FEATURE_MINGA_DESIGNER,
    default: false,
    frontEnd: true,
  },
  [mingaSettingTypes.FEATURE_DISTRICT_ENABLED]: {
    type: fieldTypes.BOOLEAN,
    category: settingsCategories.FEATURE_FLAGS,
    description: `Enable the ability to create School Groups in your minga.`,
    keyName: mingaSettingTypes.FEATURE_DISTRICT_ENABLED,
    frontEnd: true,
  },
  [mingaSettingTypes.FEATURE_TRACKING_TEACHER_POINTS]: {
    type: fieldTypes.BOOLEAN,
    category: settingsCategories.PBIS_SETTINGS,
    description: `Enable Teachers To Manage Points`,
    keyName: mingaSettingTypes.FEATURE_TRACKING_TEACHER_POINTS,
    frontEnd: true,
  },
  [mingaSettingTypes.FEATURE_TRACKING_STAFF_POINTS]: {
    type: fieldTypes.BOOLEAN,
    category: settingsCategories.PBIS_SETTINGS,
    description: `Enable Staff To Manage Points`,
    keyName: mingaSettingTypes.FEATURE_TRACKING_STAFF_POINTS,
    frontEnd: true,
  },
  [mingaSettingTypes.FEATURE_TRACKING_PUBLIC_LEADERBOARD]: {
    type: fieldTypes.BOOLEAN,
    category: settingsCategories.PBIS_SETTINGS,
    description: `Enable Public Leaderboard`,
    keyName: mingaSettingTypes.FEATURE_TRACKING_PUBLIC_LEADERBOARD,
    frontEnd: true,
  },
  [mingaSettingTypes.MODERATION_ENABLED]: {
    type: fieldTypes.BOOLEAN,
    category: settingsCategories.MODERATION,
    description: `Enable the automatic checking of submitted content for inappropriate material.`,
    keyName: mingaSettingTypes.MODERATION_ENABLED,
    default: true,
    frontEnd: true,
  },
  [mingaSettingTypes.MODERATION_BLOCK]: {
    type: fieldTypes.BOOLEAN,
    category: settingsCategories.MODERATION,
    description: `Enable the blocking of any content flagged by the moderation engine. If this is disabled, users will be warned but may still submit anyways.`,
    keyName: mingaSettingTypes.MODERATION_BLOCK,
    default: true,
    frontEnd: true,
  },
  [mingaSettingTypes.FEATURE_CHALLENGES]: {
    type: fieldTypes.BOOLEAN,
    category: settingsCategories.FEATURE_FLAGS,
    description: `Enable Challenges BETA`,
    keyName: mingaSettingTypes.FEATURE_CHALLENGES,
    frontEnd: true,
  },
  [mingaSettingTypes.FEATURE_ALLOW_ACCOUNT_DELETION]: {
    type: fieldTypes.BOOLEAN,
    category: settingsCategories.FEATURE_FLAGS,
    description: 'Allow individual users to delete their own account',
    keyName: mingaSettingTypes.FEATURE_ALLOW_ACCOUNT_DELETION,
    default: false,
    frontEnd: true,
  },
  [mingaSettingTypes.ID_MINGA_STUDENT_ID]: {
    type: fieldTypes.BOOLEAN,
    category: settingsCategories.STUDENT_ID_SETTINGS,
    description: `Allow Minga Generated Student ID`,
    keyName: mingaSettingTypes.ID_MINGA_STUDENT_ID,
    default: true,
    frontEnd: true,
  },
  [mingaSettingTypes.ID_SCHOOL_NAME]: {
    type: fieldTypes.STRING,
    category: settingsCategories.STUDENT_ID_SETTINGS,
    description: `Name of school displayed on Student ID`,
    keyName: mingaSettingTypes.ID_SCHOOL_NAME,
    frontEnd: true,
  },
  [mingaSettingTypes.ID_LOGO_ASSET_PATH]: {
    type: fieldTypes.STRING,
    category: settingsCategories.STUDENT_ID_SETTINGS,
    description: `Asset path for school logo image on Student ID`,
    keyName: mingaSettingTypes.ID_LOGO_ASSET_PATH,
    frontEnd: true,
  },
  [mingaSettingTypes.ID_BACKGROUND_COLOR]: {
    type: fieldTypes.STRING,
    category: settingsCategories.STUDENT_ID_SETTINGS,
    description: `Background color for Student ID`,
    keyName: mingaSettingTypes.ID_BACKGROUND_COLOR,
    frontEnd: true,
  },
  [mingaSettingTypes.ID_FONT_COLOR]: {
    type: fieldTypes.STRING,
    category: settingsCategories.STUDENT_ID_SETTINGS,
    description: `Font color for Student ID`,
    keyName: mingaSettingTypes.ID_FONT_COLOR,
    frontEnd: true,
  },
  [mingaSettingTypes.ID_BARCODE_TYPE]: {
    type: fieldTypes.STRING,
    category: settingsCategories.STUDENT_ID_SETTINGS,
    description: `Barcode type on Student ID`,
    keyName: mingaSettingTypes.ID_BARCODE_TYPE,
    default: 'CODE128',
    frontEnd: true,
  },
  [mingaSettingTypes.ID_SHOW_ROLE]: {
    type: fieldTypes.BOOLEAN,
    category: settingsCategories.STUDENT_ID_SETTINGS,
    description: `Show role on Student ID`,
    keyName: mingaSettingTypes.ID_SHOW_ROLE,
    frontEnd: true,
  },
  [mingaSettingTypes.ID_SHOW_GRADE]: {
    type: fieldTypes.BOOLEAN,
    category: settingsCategories.STUDENT_ID_SETTINGS,
    description: `Show grade on Student ID`,
    keyName: mingaSettingTypes.ID_SHOW_GRADE,
    default: true,
    frontEnd: true,
  },
  [mingaSettingTypes.ID_SHOW_ENGLISH_SUICIDE_LIFELINE]: {
    type: fieldTypes.BOOLEAN,
    category: settingsCategories.STUDENT_ID_SETTINGS,
    description: `Show the national English suicide lifeline on Student ID`,
    keyName: mingaSettingTypes.ID_SHOW_ENGLISH_SUICIDE_LIFELINE,
    default: true,
    frontEnd: true,
  },
  [mingaSettingTypes.ID_SHOW_SPANISH_SUICIDE_LIFELINE]: {
    type: fieldTypes.BOOLEAN,
    category: settingsCategories.STUDENT_ID_SETTINGS,
    description: `Show the national Spanish suicide lifeline on Student ID`,
    keyName: mingaSettingTypes.ID_SHOW_SPANISH_SUICIDE_LIFELINE,
    default: true,
    frontEnd: true,
  },
  [mingaSettingTypes.ID_SHOW_DOMESTIC_VIOLENCE_HOTLINE]: {
    type: fieldTypes.BOOLEAN,
    category: settingsCategories.STUDENT_ID_SETTINGS,
    description: `Show the national domestic violence lifeline on Student ID`,
    keyName: mingaSettingTypes.ID_SHOW_DOMESTIC_VIOLENCE_HOTLINE,
    default: true,
    frontEnd: true,
  },
  [mingaSettingTypes.ID_SHOW_CUSTOM_LIFELINE]: {
    type: fieldTypes.STRING,
    category: settingsCategories.STUDENT_ID_SETTINGS,
    description: `Show a custom suicide lifeline on Student ID`,
    keyName: mingaSettingTypes.ID_SHOW_CUSTOM_LIFELINE,
    frontEnd: true,
  },
  [mingaSettingTypes.ID_UPLOAD_TEMP_PHOTO]: {
    type: fieldTypes.BOOLEAN,
    category: settingsCategories.STUDENT_ID_SETTINGS,
    description: `Allow students to upload a temporary Student ID photo`,
    keyName: mingaSettingTypes.ID_UPLOAD_TEMP_PHOTO,
    frontEnd: true,
  },
  [mingaSettingTypes.ID_ENABLE_GRADE_COLORS]: {
    type: fieldTypes.BOOLEAN,
    category: settingsCategories.STUDENT_ID_SETTINGS,
    description: `Enable background ID colors for each grade`,
    keyName: mingaSettingTypes.ID_ENABLE_GRADE_COLORS,
    frontEnd: true,
  },
  [mingaSettingTypes.ID_GRADE_COLORS]: {
    type: fieldTypes.DICTIONARY,
    category: settingsCategories.STUDENT_ID_SETTINGS,
    description: `Background ID colors for each grade`,
    keyName: mingaSettingTypes.ID_GRADE_COLORS,
    frontEnd: true,
  },
  [mingaSettingTypes.ID_CLOCK_FONT_SIZE]: {
    type: fieldTypes.NUMBER,
    category: settingsCategories.STUDENT_ID_SETTINGS,
    description:
      'Increased size of student ID clock, percentage wise, from a base of 1 rem',
    keyName: mingaSettingTypes.ID_CLOCK_FONT_SIZE,
    default: 0,
    frontEnd: true,
  },
  [mingaSettingTypes.HIDE_ID_NUM_ON_IDS]: {
    type: fieldTypes.BOOLEAN,
    category: settingsCategories.STUDENT_ID_SETTINGS,
    description: `Hide student ID number on IDs`,
    keyName: mingaSettingTypes.HIDE_ID_NUM_ON_IDS,
    frontEnd: true,
  },
  [mingaSettingTypes.ID_SHOW_ID_FIELD_1]: {
    type: fieldTypes.BOOLEAN,
    category: settingsCategories.STUDENT_ID_SETTINGS,
    description: `Show custom ID field 1 on IDs`,
    keyName: mingaSettingTypes.ID_SHOW_ID_FIELD_1,
    frontEnd: true,
    default: false,
  },
  [mingaSettingTypes.ID_SHOW_ID_FIELD_2]: {
    type: fieldTypes.BOOLEAN,
    category: settingsCategories.STUDENT_ID_SETTINGS,
    description: `Show custom ID field 2 on IDs`,
    keyName: mingaSettingTypes.ID_SHOW_ID_FIELD_2,
    frontEnd: true,
    default: false,
  },
  [mingaSettingTypes.ID_STAFF_CHANGE_PICTURE]: {
    type: fieldTypes.BOOLEAN,
    category: settingsCategories.STUDENT_ID_SETTINGS,
    description: `Allow staff to change ID pictures in profile`,
    keyName: mingaSettingTypes.ID_STAFF_CHANGE_PICTURE,
    frontEnd: true,
    default: true,
  },
  [mingaSettingTypes.ID_CARD_LAYOUT]: {
    type: fieldTypes.STRING,
    category: settingsCategories.STUDENT_ID_SETTINGS,
    description: `Select which layout to use for student ID cards`,
    keyName: mingaSettingTypes.ID_CARD_LAYOUT,
    frontEnd: true,
    default: 'mingasso',
  },
  [mingaSettingTypes.PASS_TEACHERS_GRANT]: {
    type: fieldTypes.BOOLEAN,
    category: settingsCategories.HALL_PASS_SETTINGS,
    description: `Teachers can grant passes`,
    keyName: mingaSettingTypes.PASS_TEACHERS_GRANT,
    frontEnd: true,
  },
  [mingaSettingTypes.PASS_STAFF_GRANT]: {
    type: fieldTypes.BOOLEAN,
    category: settingsCategories.HALL_PASS_SETTINGS,
    description: `Staff can grant passes`,
    keyName: mingaSettingTypes.PASS_STAFF_GRANT,
    frontEnd: true,
  },
  [mingaSettingTypes.PASS_STUDENTS_GRANT]: {
    type: fieldTypes.BOOLEAN,
    category: settingsCategories.HALL_PASS_SETTINGS,
    description: `Students can self grant passes`,
    keyName: mingaSettingTypes.PASS_STUDENTS_GRANT,
    frontEnd: true,
  },
  [mingaSettingTypes.PASS_MAX_PASSES]: {
    type: fieldTypes.NUMBER,
    category: settingsCategories.HALL_PASS_SETTINGS,
    description: `Max number of passes per student per day`,
    keyName: mingaSettingTypes.PASS_MAX_PASSES,
    default: 8,
    frontEnd: true,
  },
  [mingaSettingTypes.PASS_MAX_ACTIVE_PASSES]: {
    type: fieldTypes.NUMBER,
    category: settingsCategories.HALL_PASS_SETTINGS,
    description: `Max number of active passes at any one time`,
    keyName: mingaSettingTypes.PASS_MAX_ACTIVE_PASSES,
    default: 50,
    frontEnd: true,
  },
  [mingaSettingTypes.PASS_MAX_ACTIVE_STUDENT_PASSES]: {
    type: fieldTypes.NUMBER,
    category: settingsCategories.HALL_PASS_SETTINGS,
    description: `Max number of active student created passes at any one time`,
    keyName: mingaSettingTypes.PASS_MAX_ACTIVE_STUDENT_PASSES,
    default: 50,
    frontEnd: true,
  },
  [mingaSettingTypes.PASS_MAX_STUDENT_PASSES]: {
    type: fieldTypes.NUMBER,
    category: settingsCategories.HALL_PASS_SETTINGS,
    description: `Max number of passes per student per day when a student self grants a pass`,
    keyName: mingaSettingTypes.PASS_MAX_STUDENT_PASSES,
    default: 8,
    frontEnd: true,
  },
  [mingaSettingTypes.PASS_DURATION_STUDENT_PASSES]: {
    type: fieldTypes.NUMBER,
    category: settingsCategories.HALL_PASS_SETTINGS,
    description: `Duration of a student self granted hall pass`,
    keyName: mingaSettingTypes.PASS_DURATION_STUDENT_PASSES,
    default: 5,
    frontEnd: true,
  },
  [mingaSettingTypes.PASS_APPROVAL_REQUEST_TIMEOUT_DURATION]: {
    type: fieldTypes.NUMBER,
    category: settingsCategories.HALL_PASS_SETTINGS,
    description: `Timeout duration of a teacher approved student hall pass`,
    keyName: mingaSettingTypes.PASS_APPROVAL_REQUEST_TIMEOUT_DURATION,
    default: 2, // 2 mins
    frontEnd: true,
  },
  [mingaSettingTypes.PASS_APPROVAL_REQUEST_TIMEOUT_DURATION_STAFF]: {
    type: fieldTypes.NUMBER,
    category: settingsCategories.HALL_PASS_SETTINGS,
    description: `Timeout duration of a teacher approved staff hall pass`,
    keyName: mingaSettingTypes.PASS_APPROVAL_REQUEST_TIMEOUT_DURATION_STAFF,
    default: 5, // 5 mins
    frontEnd: true,
  },
  [mingaSettingTypes.PASS_ASSIGN_STAFF]: {
    type: fieldTypes.BOOLEAN,
    category: settingsCategories.HALL_PASS_SETTINGS,
    description: `Students must assign a teacher to self granted hall passes`,
    keyName: mingaSettingTypes.PASS_ASSIGN_STAFF,
    frontEnd: true,
  },
  [mingaSettingTypes.PASS_MIN_WAIT_STUDENT_PASS]: {
    type: fieldTypes.NUMBER,
    category: settingsCategories.HALL_PASS_SETTINGS,
    description: `Minimum wait time required before next student created pass`,
    keyName: mingaSettingTypes.PASS_MIN_WAIT_STUDENT_PASS,
    default: 0,
    frontEnd: true,
  },
  [mingaSettingTypes.PASS_STAFF_BLACKOUT_BYPASS]: {
    type: fieldTypes.BOOLEAN,
    category: settingsCategories.HALL_PASS_SETTINGS,
    description: `Can teachers and staff be allowed to bypass blackout windows`,
    keyName: mingaSettingTypes.PASS_STAFF_BLACKOUT_BYPASS,
    default: true,
    frontEnd: true,
  },
  [mingaSettingTypes.PASS_MUST_MANUALLY_END]: {
    type: fieldTypes.BOOLEAN,
    category: settingsCategories.HALL_PASS_SETTINGS,
    description: `Hall passes must be manually ended`,
    keyName: mingaSettingTypes.PASS_MUST_MANUALLY_END,
    default: false,
    frontEnd: true,
  },
  [mingaSettingTypes.PASS_STUDENTS_END_PASSES]: {
    type: fieldTypes.BOOLEAN,
    category: settingsCategories.HALL_PASS_SETTINGS,
    description: 'Can students end hall passes themselves',
    keyName: mingaSettingTypes.PASS_STUDENTS_END_PASSES,
    default: false,
    frontEnd: true,
  },
  [mingaSettingTypes.PASS_STUDENTS_END_PASSES_FROM_MOBILE]: {
    type: fieldTypes.BOOLEAN,
    category: settingsCategories.HALL_PASS_SETTINGS,
    description: 'Can students end hall passes from mobile devices',
    keyName: mingaSettingTypes.PASS_STUDENTS_END_PASSES_FROM_MOBILE,
    default: false,
    frontEnd: true,
  },
  [mingaSettingTypes.PASS_TIMEOUT]: {
    type: fieldTypes.NUMBER,
    category: settingsCategories.HALL_PASS_SETTINGS,
    description: `Default Hall Pass Timeout`,
    keyName: mingaSettingTypes.PASS_TIMEOUT,
    default: 60,
    frontEnd: true,
  },
  [mingaSettingTypes.PASS_TEACHER_VIEW_REPORTS]: {
    type: fieldTypes.BOOLEAN,
    category: settingsCategories.HALL_PASS_SETTINGS,
    description:
      'Can teachers/staff view hall pass reports for their own passes',
    keyName: mingaSettingTypes.PASS_TEACHER_VIEW_REPORTS,
    default: false,
    frontEnd: true,
  },
  [mingaSettingTypes.PASS_ALLOW_NOTE]: {
    type: fieldTypes.BOOLEAN,
    category: settingsCategories.HALL_PASS_SETTINGS,
    description: 'Allow staff to add notes to hall passes',
    keyName: mingaSettingTypes.PASS_ALLOW_NOTE,
    default: false,
    frontEnd: true,
  },
  [mingaSettingTypes.PASS_APPROVAL_TIMEOUT]: {
    type: fieldTypes.NUMBER,
    category: settingsCategories.HALL_PASS_SETTINGS,
    description: `Hall pass approval timeout`,
    keyName: mingaSettingTypes.PASS_APPROVAL_TIMEOUT,
    default: 2,
    frontEnd: true,
  },
  [mingaSettingTypes.PASS_SHOW_RECENTLY_ENDED]: {
    type: fieldTypes.BOOLEAN,
    category: settingsCategories.HALL_PASS_SETTINGS,
    description: 'Hall pass recently ended filter',
    keyName: mingaSettingTypes.PASS_SHOW_RECENTLY_ENDED,
    default: true,
    frontEnd: true,
  },
  [mingaSettingTypes.PASS_RECENTLY_ENDED_FILTER]: {
    type: fieldTypes.NUMBER,
    category: settingsCategories.HALL_PASS_SETTINGS,
    description: 'Hall pass recently ended filter',
    keyName: mingaSettingTypes.PASS_RECENTLY_ENDED_FILTER,
    default: 5,
    frontEnd: true,
  },
  [mingaSettingTypes.PASS_HALLPASS_ENABLE]: {
    type: fieldTypes.BOOLEAN,
    category: settingsCategories.HALL_PASS_SETTINGS,
    description: 'Enable Hall Pass',
    keyName: mingaSettingTypes.PASS_HALLPASS_ENABLE,
    default: true,
    frontEnd: true,
  },
  [mingaSettingTypes.PBIS_TEACHERS_TRACK]: {
    type: fieldTypes.BOOLEAN,
    category: settingsCategories.PBIS_SETTINGS,
    description: `Can teachers track PBIS Behaviors`,
    keyName: mingaSettingTypes.PBIS_TEACHERS_TRACK,
    frontEnd: true,
  },
  [mingaSettingTypes.PBIS_STAFF_TRACK]: {
    type: fieldTypes.BOOLEAN,
    category: settingsCategories.PBIS_SETTINGS,
    description: `Can staff track PBIS Behaviors`,
    keyName: mingaSettingTypes.PBIS_STAFF_TRACK,
    frontEnd: true,
  },
  [mingaSettingTypes.PBIS_STUDENT_LEADERS_TRACK]: {
    type: fieldTypes.BOOLEAN,
    category: settingsCategories.PBIS_SETTINGS,
    description: `Can Student Leaders track PBIS Behaviors`,
    keyName: mingaSettingTypes.PBIS_STUDENT_LEADERS_TRACK,
    frontEnd: true,
  },
  [mingaSettingTypes.PBIS_INDIVIDUAL_POINTS]: {
    type: fieldTypes.BOOLEAN,
    category: settingsCategories.PBIS_SETTINGS,
    description: `Show individual students points in leaderboard`,
    keyName: mingaSettingTypes.PBIS_INDIVIDUAL_POINTS,
    frontEnd: true,
  },
  [mingaSettingTypes.PBIS_TEAM_POINTS]: {
    type: fieldTypes.BOOLEAN,
    category: settingsCategories.PBIS_SETTINGS,
    description: `Show team points in leaderboard`,
    keyName: mingaSettingTypes.PBIS_TEAM_POINTS,
    frontEnd: true,
  },
  [mingaSettingTypes.PBIS_HP_TARDY]: {
    type: fieldTypes.BOOLEAN,
    category: settingsCategories.PBIS_SETTINGS,
    description: `Auto assign tardy HP with behaviour`,
    keyName: mingaSettingTypes.PBIS_HP_TARDY,
    frontEnd: true,
  },
  [mingaSettingTypes.PM_REWARDS_STORE]: {
    type: fieldTypes.BOOLEAN,
    category: settingsCategories.PBIS_SETTINGS,
    description: 'Show the points rewards store',
    keyName: mingaSettingTypes.PM_REWARDS_STORE,
    frontEnd: true,
  },
  [mingaSettingTypes.PM_TEACHERS_REWARDS]: {
    type: fieldTypes.BOOLEAN,
    category: settingsCategories.PBIS_SETTINGS,
    description: 'Teachers can manage the rewards store',
    keyName: mingaSettingTypes.PM_TEACHERS_REWARDS,
    frontEnd: true,
  },
  [mingaSettingTypes.PM_STAFF_REWARDS]: {
    type: fieldTypes.BOOLEAN,
    category: settingsCategories.PBIS_SETTINGS,
    description: 'Staff can manage the rewards store',
    keyName: mingaSettingTypes.PM_STAFF_REWARDS,
    frontEnd: true,
  },
  [mingaSettingTypes.BM_CONSEQUENCE_ENABLE]: {
    type: fieldTypes.BOOLEAN,
    category: settingsCategories.PBIS_SETTINGS,
    description: `Enable consequences`,
    keyName: mingaSettingTypes.BM_CONSEQUENCE_ENABLE,
    default: false,
    frontEnd: true,
  },
  [mingaSettingTypes.BM_CONSEQUENCE_TEACHER]: {
    type: fieldTypes.BOOLEAN,
    category: settingsCategories.PBIS_SETTINGS,
    description: `Teachers can issue consequences`,
    keyName: mingaSettingTypes.BM_CONSEQUENCE_TEACHER,
    default: false,
    frontEnd: true,
  },
  [mingaSettingTypes.BM_CONSEQUENCE_STAFF]: {
    type: fieldTypes.BOOLEAN,
    category: settingsCategories.PBIS_SETTINGS,
    description: `Staff can issue consequences`,
    keyName: mingaSettingTypes.BM_CONSEQUENCE_STAFF,
    default: false,
    frontEnd: true,
  },
  [mingaSettingTypes.FTM_TEACHERS_ENABLE]: {
    type: fieldTypes.BOOLEAN,
    category: settingsCategories.FLEX_TIME_SETTINGS,
    description: `Teachers can manage flex time`,
    keyName: mingaSettingTypes.FTM_TEACHERS_ENABLE,
    default: false,
    frontEnd: true,
  },
  [mingaSettingTypes.FTM_STAFF_ENABLE]: {
    type: fieldTypes.BOOLEAN,
    category: settingsCategories.FLEX_TIME_SETTINGS,
    description: `Staff can manage flex time`,
    keyName: mingaSettingTypes.FTM_STAFF_ENABLE,
    default: false,
    frontEnd: true,
  },
  [mingaSettingTypes.FTM_AUTO_END_HALL_PASS_ON_CHECK_IN]: {
    type: fieldTypes.BOOLEAN,
    category: settingsCategories.FLEX_TIME_SETTINGS,
    description: `Auto-end hall pass on flex time check in`,
    keyName: mingaSettingTypes.FTM_AUTO_END_HALL_PASS_ON_CHECK_IN,
    default: false,
    frontEnd: true,
  },
  [mingaSettingTypes.FEATURE_SIS]: {
    type: fieldTypes.BOOLEAN,
    category: settingsCategories.FEATURE_FLAGS,
    description: `Allow SIS integrations for a school`,
    keyName: mingaSettingTypes.FEATURE_SIS,
    frontEnd: true,
  },
  [mingaSettingTypes.HIDE_ID_NUM]: {
    type: fieldTypes.BOOLEAN,
    category: settingsCategories.PRIVACY_SETTINGS,
    description: `Hide Student ID num from Teachers and Staff`,
    keyName: mingaSettingTypes.HIDE_ID_NUM,
    frontEnd: true,
  },
  [mingaSettingTypes.CHECKIN_TRACKING_TOOLS_TEACHERS]: {
    type: fieldTypes.BOOLEAN,
    category: settingsCategories.CHECKIN_SETTINGS,
    description: `Enable Checkin Tracking Tools For Teachers`,
    keyName: mingaSettingTypes.CHECKIN_TRACKING_TOOLS_TEACHERS,
    frontEnd: true,
  },
  [mingaSettingTypes.CHECKIN_TRACKING_TOOLS_STAFF]: {
    type: fieldTypes.BOOLEAN,
    category: settingsCategories.CHECKIN_SETTINGS,
    description: `Enable Checkin Tracking Tools For Staff`,
    keyName: mingaSettingTypes.CHECKIN_TRACKING_TOOLS_STAFF,
    frontEnd: true,
  },
  [mingaSettingTypes.ID_ENABLE_OFFLINE]: {
    type: fieldTypes.BOOLEAN,
    category: settingsCategories.CHECKIN_SETTINGS,
    description: `Enable viewing stickers on IDs when offline`,
    keyName: mingaSettingTypes.ID_ENABLE_OFFLINE,
    frontEnd: true,
  },
  [mingaSettingTypes.DM_ENABLED_ROLES]: {
    type: fieldTypes.STRING_ARRAY,
    category: settingsCategories.MISC_SETTINGS,
    description: `What roles a user can DM`,
    keyName: mingaSettingTypes.DM_ENABLED_ROLES,
    frontEnd: true,
    default: [],
  },
  [mingaSettingTypes.LEGACY_LAYOUT]: {
    type: fieldTypes.BOOLEAN,
    category: settingsCategories.MISC_SETTINGS,
    description: `Enable legacy layout`,
    keyName: mingaSettingTypes.LEGACY_LAYOUT,
    frontEnd: true,
    default: false,
  },
  [mingaSettingTypes.FEATURE_ALLOW_STUDENT_EMAIL_CHANGE]: {
    type: fieldTypes.BOOLEAN,
    category: settingsCategories.PRIVACY_SETTINGS,
    description: `Enable students to change their email`,
    keyName: mingaSettingTypes.FEATURE_ALLOW_STUDENT_EMAIL_CHANGE,
    frontEnd: true,
    default: false,
  },
  [mingaSettingTypes.FEATURE_DEMO_DATA]: {
    type: fieldTypes.BOOLEAN,
    category: settingsCategories.FEATURE_FLAGS,
    description: `Enable scheduled demo data generation`,
    keyName: mingaSettingTypes.FEATURE_DEMO_DATA,
    frontEnd: false,
    default: false,
  },
  [mingaSettingTypes.DEMO_DATA_HALL_PASS_COUNT]: {
    type: fieldTypes.NUMBER,
    category: settingsCategories.FEATURE_FLAGS,
    description: `Number of hall passes to generate per day`,
    keyName: mingaSettingTypes.DEMO_DATA_HALL_PASS_COUNT,
    frontEnd: false,
    default: 100,
  },
  [mingaSettingTypes.DEMO_DATA_HALL_PASS_COUNT_RANGE]: {
    type: fieldTypes.NUMBER,
    category: settingsCategories.FEATURE_FLAGS,
    description: `Range in number of hallpass to generate per day`,
    keyName: mingaSettingTypes.DEMO_DATA_HALL_PASS_COUNT_RANGE,
    frontEnd: false,
    default: 50,
  },
  [mingaSettingTypes.DEMO_DATA_CHECKIN_COUNT]: {
    type: fieldTypes.NUMBER,
    category: settingsCategories.FEATURE_FLAGS,
    description: `Number of checkins to generate per day`,
    keyName: mingaSettingTypes.DEMO_DATA_CHECKIN_COUNT,
    frontEnd: false,
    default: 100,
  },
  [mingaSettingTypes.DEMO_DATA_CHECKIN_COUNT_RANGE]: {
    type: fieldTypes.NUMBER,
    category: settingsCategories.FEATURE_FLAGS,
    description: `Range in number of checkins to generate per day`,
    keyName: mingaSettingTypes.DEMO_DATA_CHECKIN_COUNT_RANGE,
    frontEnd: false,
    default: 50,
  },
  [mingaSettingTypes.ENABLE_SMS]: {
    type: fieldTypes.BOOLEAN,
    category: settingsCategories.FEATURE_FLAGS,
    description: `Enable SMS`,
    keyName: mingaSettingTypes.ENABLE_SMS,
    frontEnd: true,
    default: false,
  },
  [mingaSettingTypes.MY_CLASS_ENABLE]: {
    type: fieldTypes.BOOLEAN,
    category: settingsCategories.FEATURE_FLAGS,
    description: `Enable teachers to use my class`,
    keyName: mingaSettingTypes.MY_CLASS_ENABLE,
    frontEnd: true,
    default: false,
  },
  [mingaSettingTypes.FEATURE_ROLLOUT_ENABLE_BETA]: {
    type: fieldTypes.BOOLEAN,
    category: settingsCategories.FEATURE_FLAGS,
    description: `Enable app feature rollout to beta minga`,
    keyName: mingaSettingTypes.FEATURE_ROLLOUT_ENABLE_BETA,
    frontEnd: true,
    default: false,
  },
  [mingaSettingTypes.FEATURE_ROLLOUT_ENABLE_CUSTOMER_COUNCIL]: {
    type: fieldTypes.BOOLEAN,
    category: settingsCategories.FEATURE_FLAGS,
    description: `Enable app feature rollout to customer council minga`,
    keyName: mingaSettingTypes.FEATURE_ROLLOUT_ENABLE_CUSTOMER_COUNCIL,
    frontEnd: true,
    default: false,
  },
  // Kiosk settings
  [mingaSettingTypes.PASS_HALLPASS_KIOSK]: {
    type: fieldTypes.BOOLEAN,
    category: settingsCategories.HALL_PASS_SETTINGS,
    description: `Enable in kiosk mode`,
    keyName: mingaSettingTypes.PASS_HALLPASS_KIOSK,
    frontEnd: true,
    default: false,
  },
  [mingaSettingTypes.CHECKIN_KIOSK]: {
    type: fieldTypes.BOOLEAN,
    category: settingsCategories.CHECKIN_SETTINGS,
    description: `Enable in kiosk mode`,
    keyName: mingaSettingTypes.CHECKIN_KIOSK,
    frontEnd: true,
    default: false,
  },
  [mingaSettingTypes.FTM_KIOSK]: {
    type: fieldTypes.BOOLEAN,
    category: settingsCategories.FLEX_TIME_SETTINGS,
    description: `Enable in kiosk mode`,
    keyName: mingaSettingTypes.FTM_KIOSK,
    frontEnd: true,
    default: false,
  },
  [mingaSettingTypes.DATE_PRESETS_INACTIVE]: {
    type: fieldTypes.STRING_ARRAY,
    category: settingsCategories.MISC_SETTINGS,
    description: `Disabled default date presets`,
    keyName: mingaSettingTypes.DATE_PRESETS_INACTIVE,
    frontEnd: true,
    default: [],
  },
  [mingaSettingTypes.STUDENT_SCHEDULE_ENABLE]: {
    type: fieldTypes.BOOLEAN,
    category: settingsCategories.FEATURE_FLAGS,
    description: `Enable Student Schedules`,
    keyName: mingaSettingTypes.STUDENT_SCHEDULE_ENABLE,
    frontEnd: true,
    default: false,
  },
  [mingaSettingTypes.BELL_SCHEDULE_ENABLE]: {
    type: fieldTypes.BOOLEAN,
    category: settingsCategories.FEATURE_FLAGS,
    description: `Enable Bell Schedules`,
    keyName: mingaSettingTypes.BELL_SCHEDULE_ENABLE,
    frontEnd: true,
    default: false,
  },
  [mingaSettingTypes.BELL_SCHEDULE_ENABLE_FOR_TEACHERS_STUDENTS]: {
    type: fieldTypes.BOOLEAN,
    category: settingsCategories.FEATURE_FLAGS,
    description: `Enable Bell Schedules for Teachers and Students`,
    keyName: mingaSettingTypes.BELL_SCHEDULE_ENABLE_FOR_TEACHERS_STUDENTS,
    frontEnd: true,
    default: false,
  },
  [mingaSettingTypes.TEACHERS_VIEW_PROFILE]: {
    type: fieldTypes.BOOLEAN,
    category: settingsCategories.MISC_SETTINGS,
    description: `Allow Teachers / Staff to view student profile`,
    keyName: mingaSettingTypes.TEACHERS_VIEW_PROFILE,
    frontEnd: true,
    default: true,
  },
};

const validSettingTypesArray: [mingaSettingTypes, settingsMetadata][] =
  Object.keys(validSettingTypes).map(keyName => {
    return [
      keyName as mingaSettingTypes,
      validSettingTypes[keyName as mingaSettingTypes],
    ] as [mingaSettingTypes, settingsMetadata];
  });

export function getSettingType(keyName: mingaSettingTypes): settingsMetadata;
export function getSettingType(keyName: string): settingsMetadata | null;
export function getSettingType(keyName: string): settingsMetadata | null {
  return (validSettingTypes as any)[keyName] || null;
}

export function getSettingsByCategory(category: settingsCategories) {
  return validSettingTypesArray.filter(e => e[1].category === category);
}

/**
 * Get the default values of all settings.
 */
export function getDefaultSettingsMap(): Map<mingaSettingTypes, any> {
  const items = Object.entries(validSettingTypes);
  const map = new Map<mingaSettingTypes, any>();
  for (const item of items) {
    const setting = item[1];
    if (setting.default !== undefined) {
      map.set(setting.keyName, setting.default);
    }
  }

  return map;
}

export type SettingsRecord = { [K in mingaSettingTypes]?: any };

export function getSettingsAsObject(): SettingsRecord {
  const obj: SettingsRecord = {};
  const settings = validSettingTypesArray;
  for (const setting of settings) {
    const key = setting[0];
    const value = setting[1];
    obj[key] = value.default;
  }

  return obj;
}
