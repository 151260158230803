// GENERATED CODE -- DO NOT EDIT!

import {grpc} from '@improbable-eng/grpc-web';

import * as gateway_test_pb from '../gateway/test_pb';

export class Test {
  static readonly serviceName = "Test";

  static readonly stream = {
    methodName: "stream",
    service: Test,
    requestStream: false,
    responseStream: true,
    requestType: gateway_test_pb.TestMessage,
    responseType: gateway_test_pb.TestMessage,
  };

  static readonly unary = {
    methodName: "unary",
    service: Test,
    requestStream: false,
    responseStream: false,
    requestType: gateway_test_pb.TestMessage,
    responseType: gateway_test_pb.TestMessage,
  };

}

export class TestClient {
  stream() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  unary() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
}
