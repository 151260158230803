<!-- Main Overlay -->
<mg-overlay-primary
  [size]="(modalType$ | async) === MODAL_TYPES.MINGA ? 'large' : 'auto'"
  [overlayTitle]="modalTitle$ | async">
  <ng-container *ngTemplateOutlet="mainTemplate"></ng-container>
  <ng-template #overlayFooter>
    <ng-container *ngTemplateOutlet="footerTemplate"></ng-container>
  </ng-template>
</mg-overlay-primary>

<!-- Main Content -->
<ng-template #mainTemplate>
  <ng-container [ngSwitch]="modalType$ | async">
    <ng-container
      *ngSwitchCase="'MINGA'"
      [ngTemplateOutlet]="mingaTemplate"></ng-container>
    <ng-container
      *ngSwitchCase="'PARTNER'"
      [ngTemplateOutlet]="mingaPartnerTemplate"></ng-container>
    <ng-container
      *ngSwitchCase="'DISTRICT'"
      [ngTemplateOutlet]="mingaDistrictTemplate"></ng-container>
  </ng-container>
</ng-template>

<!-- Modal Footer Template -->
<ng-template #footerTemplate>
  <div
    class="overlay-footer"
    fxLayout="row"
    fxLayoutAlign="space-between center">
    <div>
      <mg-btn
        [variant]="(editingExisting$ | async) ? 'destructive' : 'text'"
        (pressed)="deleteOrCancel()">
        {{
          (editingExisting$ | async)
            ? MESSAGES.BUTTON_LABEL_DELETE
            : MESSAGES.BUTTON_LABEL_CANCEL
        }}
      </mg-btn>
    </div>
    <div>
      <mg-btn
        *ngIf="!_loading"
        variant="filled"
        [disabled]="
          (modalType$ | async) === MODAL_TYPES.MINGA
            ? mingaFormGroup.pristine
            : name.pristine
        "
        (pressed)="submit()">
        {{
          (editingExisting$ | async)
            ? MESSAGES.BUTTON_LABEL_SAVE
            : MESSAGES.BUTTON_LABEL_CREATE
        }}
      </mg-btn>
      <mg-spinner
        *ngIf="_loading"
        [diameter]="55"
        [thickness]="3">
      </mg-spinner>
    </div>
  </div>
</ng-template>

<!-- Minga Template -->
<ng-template #mingaTemplate>
  <div
    *ngIf="editing"
    fxLayout="row"
    fxLayoutAlign="start stretch">
    <div
      class="subtitle"
      fxLayout="row"
      fxLayoutAlign="start center">
      <span *ngIf="isMingaPaused">Minga is paused</span>
    </div>

    <div
      *hasPermission="'SUPERADMIN'"
      fxLayout="row"
      fxLayoutAlign="end center"
      fxLayoutGap="10px">
      <div>
        <mg-btn
          variant="outlined"
          size="small"
          (pressed)="confirmTogglePlayMinga()">
          {{ isMingaPaused ? 'Unpause' : 'Pause' }}
        </mg-btn>
      </div>
      <div fxFlex="noshrink">
        <mg-btn
          variant="filled"
          size="small"
          (pressed)="openMinga()">
          Open minga
        </mg-btn>
      </div>
    </div>
  </div>

  <form
    [formGroup]="mingaFormGroup"
    (keypress)="onKeypress($event)">
    <div fxLayout="column">
      <div
        fxLayout="row-reverse"
        fxLayout.xs="column">
        <div
          fxFlex="25"
          fxLayoutAlign="end start"
          fxLayoutAlign.xs="center center"
          class="avatar-edit-wrap">
          <mg-avatar
            [overflow]="false"
            [square]="true"
            [ngClass]="{
              invalid:
                mingaFormGroup.controls.logo.invalid &&
                mingaFormGroup.controls.logo.touched
            }">
            <div class="avatar-input">
              <div
                class="current-avatar"
                [style.background-image]="'url(' + mingaLogo + ')'"></div>
              <mg-file-input
                mgIconCompact
                #fileInput
                [multiple]="false"
                [customTitleText]="'Change'"
                [ngModelOptions]="{ standalone: true }"
                [(ngModel)]="logoInputFiles"
                formControlName="logo">
              </mg-file-input>
              <mg-file-upload
                *ngIf="logoInputFiles[0]"
                [file]="logoInputFiles[0]"
                [formControl]="uploadFormControl">
              </mg-file-upload>
            </div>
            <mg-spinner
              *ngIf="_loading"
              [diameter]="100"
              [thickness]="3">
            </mg-spinner>
          </mg-avatar>
        </div>

        <div fxFlex="75">
          <div class="subtitle">School Information</div>
          <mg-minga-name
            name="name"
            formControlName="name"
            [required]="true"
            [outerFormControl]="mingaFormGroup.controls.name">
          </mg-minga-name>

          <mg-address
            [ngClass]="{
              invalid:
                mingaFormGroup.controls.placeId.invalid &&
                mingaFormGroup.controls.placeId.touched
            }"
            name="placeId"
            formControlName="placeId"
            [required]="true"
            [outerFormControl]="mingaFormGroup.controls.placeId"
            (placeChange)="onAddressChange($event)">
          </mg-address>

          <mg-email
            name="email"
            formControlName="email"
            [validatePatternOnly]="true"
            [outerFormControl]="mingaFormGroup.controls.email">
          </mg-email>

          <mat-form-field
            mgFormField
            hideRequiredMarker>
            <mat-label>Phone Number</mat-label>
            <input
              matInput
              type="tel"
              name="phone"
              formControlName="phone"
              minlength="3"
              maxlength="50" />

            <mat-error
              *ngIf="
                mingaFormGroup.controls.phone.invalid &&
                (mingaFormGroup.controls.phone.dirty ||
                  mingaFormGroup.controls.phone.touched)
              ">
              <ng-container
                [ngSwitch]="getErrorKey(mingaFormGroup.controls.phone)">
                <span *ngSwitchCase="'pattern'">
                  {{
                    'input.generic.error.pattern'
                      | translate: { value: 'phone number' }
                  }}
                </span>
              </ng-container>
            </mat-error>
          </mat-form-field>

          <mat-form-field
            mgFormField
            hideRequiredMarker>
            <mat-label>Website URL</mat-label>
            <input
              matInput
              type="url"
              name="websiteUrl"
              formControlName="websiteUrl"
              [required]="true"
              minlength="4"
              maxlength="255" />

            <mat-error
              *ngIf="
                mingaFormGroup.controls.websiteUrl.invalid &&
                (mingaFormGroup.controls.websiteUrl.dirty ||
                  mingaFormGroup.controls.websiteUrl.touched)
              ">
              <ng-container
                [ngSwitch]="getErrorKey(mingaFormGroup.controls.websiteUrl)">
                <span *ngSwitchCase="'pattern'">
                  {{
                    'input.generic.error.pattern'
                      | translate: { value: 'web address' }
                  }}
                </span>
                <span *ngSwitchCase="'maxlength'">
                  {{
                    'input.generic.error.pattern'
                      | translate: { value: 'web address' }
                  }}
                </span>
              </ng-container>
            </mat-error>
          </mat-form-field>

          <mat-form-field
            mgFormField
            hideRequiredMarker>
            <mat-label>Minga Type</mat-label>
            <mat-select
              name="mingaType"
              formControlName="mingaType"
              (change)="onMingaTypeChange($event)"
              [required]="true">
              <mat-option
                *ngFor="let mt of mingaTypes"
                [value]="mt.value"
                >{{ mt.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field
            mgFormField
            hideRequiredMarker
            *ngIf="!isConferenceSelected()">
            <mat-select
              name="schoolType"
              formControlName="schoolType"
              [required]="true">
              <mat-option
                *ngFor="let ms of schoolTypes"
                [value]="ms.value"
                >{{ ms.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <div class="form-select">
            <mg-form-select
              [mingaPartnerSearch]="AFFILIATION_TYPES.PARTNER"
              appearance="primary"
              placeholder="Search For Partner"
              [searchable]="true"
              [multiple]="false"
              [isClearable]="true"
              [hideSelected]="true"
              [value]="
                mingaFormGroup.controls.mingaPartnerId.value || undefined
              "
              (selectionChanged)="mingaPartnerChange($event)">
            </mg-form-select>
          </div>

          <div class="form-select">
            <mg-form-select
              [mingaPartnerSearch]="AFFILIATION_TYPES.DISTRICT"
              appearance="primary"
              placeholder="Search For District"
              [searchable]="true"
              [multiple]="false"
              [isClearable]="true"
              [hideSelected]="true"
              [value]="
                mingaFormGroup.controls.mingaDistrictId.value || undefined
              "
              (selectionChanged)="mingaDistrictChange($event)">
            </mg-form-select>
          </div>

          <mat-form-field mgFormField>
            <mat-label>HubSpot Business ID</mat-label>
            <input
              matInput
              type="text"
              name="hubSpotId"
              formControlName="hubSpotId"
              [required]="true"
              minlength="4"
              maxlength="255" />
          </mat-form-field>
        </div>
      </div>
      <div class="divider"></div>
      <div>
        <div
          fxLayout="row"
          fxLayoutAlign="start center"
          fxLayoutGap="5px">
          <img src="assets/logo/MingaLogo_New.png" />
          <div class="subtitle">Minga Subscription</div>
        </div>
        <mat-form-field
          mgFormField
          hideRequiredMarker>
          <mat-label>Minga Status</mat-label>
          <mat-select
            name="mingaStatus"
            formControlName="mingaStatus"
            [required]="true">
            <mat-option
              *ngFor="let ms of mingaStatuses"
              [value]="ms.value"
              >{{ ms.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field
          mgFormField
          hideRequiredMarker>
          <mat-label>Subscription</mat-label>
          <mat-select
            name="mingaSubscriptionId"
            formControlName="mingaSubscriptionId"
            [required]="true">
            <mat-option
              *ngFor="let sub of mingaSubscriptions"
              [value]="sub?.id">
              {{ sub?.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field
          mgFormField
          hideRequiredMarker>
          <mat-label>Tier</mat-label>
          <mat-select
            name="mingaSupportTier"
            formControlName="mingaSupportTier"
            [required]="true">
            <mat-option
              *ngFor="let tier of mingaSupportTiers"
              [value]="tier.value">
              {{ tier.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <div fxLayout="row">
          <mg-form-date
            class="half-width"
            [control]="mingaFormGroup.get('startDate')"
            label="Subscription start date">
          </mg-form-date>
          <mg-form-date
            class="half-width"
            [control]="mingaFormGroup.get('endDate')"
            label="Subscription end date">
          </mg-form-date>
        </div>

        <div class="subtitle">Minga Admin Contact Info</div>
        <mat-form-field
          *ngIf="editing; else firstLastNameTpl"
          mgFormField
          hideRequiredMarker>
          <mat-label>Full Name</mat-label>
          <input
            matInput
            name="adminName"
            maxlength="70"
            formControlName="adminFullName"
            [required]="true" />
          <mat-hint>The name can be up to 70 characters long</mat-hint>
        </mat-form-field>
        <ng-template #firstLastNameTpl>
          <mat-form-field
            mgFormField
            hideRequiredMarker>
            <mat-label>First Name</mat-label>
            <input
              matInput
              name="adminFirstName"
              maxlength="70"
              formControlName="adminFirstName"
              [required]="true" />
            <mat-hint>Name can be up to 70 characters long</mat-hint>
          </mat-form-field>

          <mat-form-field
            mgFormField
            hideRequiredMarker>
            <mat-label>Last Name</mat-label>
            <input
              matInput
              name="adminLastName"
              maxlength="70"
              formControlName="adminLastName"
              [required]="true" />
            <mat-hint>Name can be up to 70 characters long</mat-hint>
          </mat-form-field>
        </ng-template>

        <mat-form-field
          mgFormField
          hideRequiredMarker>
          <mat-label>Email</mat-label>
          <input
            matInput
            type="email"
            name="adminEmail"
            maxlength="100"
            formControlName="adminEmail"
            [required]="true" />
          <mat-hint>Email address for Minga admin</mat-hint>
        </mat-form-field>
        <mat-form-field
          mgFormField
          hideRequiredMarker>
          <mat-label>Phone Number</mat-label>
          <input
            matInput
            name="adminPhoneNumber"
            formControlName="adminPhone"
            maxlength="12"
            [required]="true" />
          <mat-hint>Contact number for the Minga admin</mat-hint>
        </mat-form-field>
        <mat-form-field
          *ngIf="!editing"
          mgFormField
          hideRequiredMarker>
          <mat-label>ID</mat-label>
          <input
            matInput
            name="adminID"
            formControlName="adminID"
            maxlength="20"
            [required]="false" />
          <mat-hint>Optional ID for Minga admin</mat-hint>
        </mat-form-field>
        <input
          type="hidden"
          formControlName="placeId"
          name="placeId" />
      </div>
    </div>
  </form>
  <div class="divider"></div>
  <mat-accordion *ngIf="editing">
    <mat-expansion-panel *ngIf="smsDetails as smsDetails">
      <mat-expansion-panel-header>
        <div class="subtitle">SMS Settings</div>
      </mat-expansion-panel-header>
      <div>
        <div
          fxLayout="row"
          fxLayoutAlign="start center"
          fxLayoutGap="20px">
          <div
            class="subtitle"
            fxLayout="row"
            fxLayoutGap="10px">
            <div>Messages:</div>
            <div>
              {{ smsDetails.currentSendCount }}/{{ smsDetails.maxSendCount }}
            </div>
          </div>
          <mg-btn
            variant="outlined"
            size="small"
            (pressed)="openAddMessageBlockDialog()">
            Add block
          </mg-btn>
        </div>
        <ng-template #addBlockDialogTpl>
          <mat-form-field
            mgFormField
            [mgNoHintMargin]="true"
            required>
            <mat-label>Block Size</mat-label>
            <input
              matInput
              type="number"
              [ngModelOptions]="{ standalone: true }"
              [disabled]="smsLoading"
              [(ngModel)]="currentSmsBlockSize" />
          </mat-form-field>
          <p>
            Results after added block:
            <strong
              >0/{{
                smsDetails.maxSendCount -
                  smsDetails.currentSendCount +
                  currentSmsBlockSize
              }}</strong
            >
          </p>
          <mg-btn
            variant="outlined"
            size="small"
            [disabled]="!currentSmsBlockSize || smsLoading"
            (pressed)="addBlock()">
            Add block
          </mg-btn>
        </ng-template>
      </div>
      <mat-accordion>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div class="subtitle">Advanced SMS Settings</div>
          </mat-expansion-panel-header>
          <mat-form-field mgFormField>
            <mat-label>SMS Maximum Sends</mat-label>
            <input
              matInput
              type="number"
              [ngModelOptions]="{ standalone: true }"
              [(ngModel)]="currentMaxSendCount" />
            <mat-hint
              >Maximum amount of SMS messages allowed to be sent</mat-hint
            >
          </mat-form-field>
          <mg-btn
            variant="outlined"
            size="small"
            [disabled]="
              smsLoading || currentMaxSendCount == smsDetails.maxSendCount
            "
            (pressed)="saveSmsMaxSend()">
            Save
          </mg-btn>
        </mat-expansion-panel>
      </mat-accordion>
    </mat-expansion-panel>
  </mat-accordion>
  <div class="divider"></div>
  <ng-container *ngTemplateOutlet="mingaModulesTemplate"></ng-container>
</ng-template>

<!-- Minga Modules Template -->
<ng-template #mingaModulesTemplate>
  <mg-mm-dashboard-modules
    [mingaHash]="hash"
    (moduleStatesChanged)="onMingaModulesChange($event)">
  </mg-mm-dashboard-modules>
</ng-template>

<!-- Minga Partner Template -->
<ng-template #mingaPartnerTemplate>
  <div [ngStyle.gt-md]="{ 'min-width.px': 620 }">
    <mg-form-text-input
      label="{{ MESSAGES.NAME_INPUT_LABEL }}"
      placeholder="{{ MESSAGES.PARTNER_NAME_INPUT_PLACEHOLDER }}"
      [control]="name">
    </mg-form-text-input>
  </div>
</ng-template>

<!-- Minga District Template -->
<ng-template #mingaDistrictTemplate>
  <div [ngStyle.gt-md]="{ 'min-width.px': 620 }">
    <mg-form-text-input
      label="{{ MESSAGES.NAME_INPUT_LABEL }}"
      placeholder="{{ MESSAGES.DISTRICT_NAME_INPUT_PLACEHOLDER }}"
      [control]="name">
    </mg-form-text-input>
  </div>
</ng-template>
