import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  ViewChild,
} from '@angular/core';

import IsEmail from 'isemail';
import { BehaviorSubject, Observable } from 'rxjs';

import {
  AcceptTypes,
  FileInputComponent,
} from '@app/src/app/components/Input/FileInput';

import {
  BehaviorManagerService,
  ImportBehaviorObservable,
} from '@modules/behavior-manager/services';

import {
  CountListUploadField,
  EmailListUploadField,
  IListUploadField,
  IListUploadValidationResponse,
  ListUploaderRow,
  StudentNumberListUploadField,
  XlsListUploaderComponent,
} from '@shared/components/XlsListUploader';
import { ISummaryRow } from '@shared/components/XlsListUploader/ListUploaderSummary';
import { IResolveListUploadErrorsDialogData } from '@shared/components/XlsListUploader/ResolveListUploadErrorsDialog';
import {
  MODAL_OVERLAY_DATA,
  ModalOverlayPrimaryHeaderBackground,
  ModalOverlayRef,
} from '@shared/components/modal-overlay';
import { UploadState } from '@shared/components/update-people-by-list-dialog';
import { UpdatePeopleByListDialogMessages } from '@shared/components/update-people-by-list-dialog/constants';

@Component({
  selector: 'mg-import-behaviors-dialog',
  templateUrl: './import-behaviors-dialog.component.html',
  styleUrls: ['./import-behaviors-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImportBehaviorsDialogComponent {
  @ViewChild('xlsUploader', { static: false })
  public xlsUploader: XlsListUploaderComponent;

  @ViewChild('fileInput', { static: false })
  public fileInput: FileInputComponent;

  /** Constants */
  public readonly MODAL_TITLE = 'Import Behaviors';
  public readonly MODAL_SUBTITLE =
    'Upload your file with the list of behaviors';
  public readonly MODAL_COLOR = ModalOverlayPrimaryHeaderBackground.GREEN;
  public readonly ACCEPT_TYPES = AcceptTypes;
  public readonly CLICK_HERE = 'Click here';

  /** Upload Variables */
  public files: File[] = [];
  public fileSubject: BehaviorSubject<File | null> =
    new BehaviorSubject<File | null>(null);
  public file$: Observable<File | null> = this.fileSubject.asObservable();
  public fields: IListUploadField[] = [
    new EmailListUploadField(),
    new StudentNumberListUploadField(),
  ];
  public resolveErrorsDialogData: IResolveListUploadErrorsDialogData | null =
    null;
  public totalCreated = 0;
  public totalSkipped = 0;
  public errors: any[] = [];
  public uploadRan = false;
  public currentUploadState: UploadState = UploadState.SETUP;
  public readonly uploadState = UploadState;
  public rows: any[] = [];
  public errorMsg = '';
  public summaryInfo: ISummaryRow[] = [];
  public headers: string[] = [];
  public readonly MESSAGES = UpdatePeopleByListDialogMessages;

  /** Behaviour Variables */
  private _type = '';
  private _id = 0;

  constructor(
    @Inject(MODAL_OVERLAY_DATA)
    public dialogData: any,
    private _bmService: BehaviorManagerService,
    private _cdr: ChangeDetectorRef,
    public modalRef: ModalOverlayRef,
  ) {
    this._type = dialogData.type;
    this._id = dialogData.id;
    const countField = new CountListUploadField();
    countField.required = false;
    this.fields.push(countField);
  }

  public onFileInputChange(): void {
    if (!this.files.length || !this.files[0]) return;
    this.fileSubject.next(this.files[0]);
  }

  public onHeadersChange(headers: (IListUploadField | null)[]): void {
    this.headers = headers.map(header => {
      if (header) {
        return header.name;
      } else {
        return '';
      }
    });
  }

  public onValidate(rows: ListUploaderRow[]): IListUploadValidationResponse {
    const rowErrors = [];
    const formatErrors = [];

    for (let index = 0; index < rows.length; index++) {
      const row = rows[index];
      const emailField = new EmailListUploadField();
      const email = emailField.getValue(row);
      const errors = [];
      if (email && !IsEmail.validate(email)) {
        errors.push('Invalid email');
      }
      const countField = new CountListUploadField();
      const count = countField.getValue(row);
      if (count && (isNaN(count as any) || Number(count) < 0)) {
        errors.push('Invalid count');
      }
      const idField = new StudentNumberListUploadField();
      const id = idField.getValue(row);
      if (!id && !email) {
        errors.push('Missing student ID or email');
      }
      if (errors.length > 0) {
        rowErrors.push({ recordIndex: index, errors });
      }
    }

    return {
      formatErrors,
      rowErrors,
    };
  }

  public reset() {
    this.files = [];
    this.errors = [];
    this.errorMsg = '';
    this.totalCreated = 0;
    this.totalSkipped = 0;
    this.rows = [];
    this.uploadRan = false;
    this.currentUploadState = UploadState.SETUP;
  }

  public onListSubmit(rows: any[]): void {
    this.rows = rows;
    this.currentUploadState = UploadState.UPLOADING;
    const uploadObs = this._bmService.importBehaviors(
      rows,
      this._type,
      this._id,
    );
    this._handleUploadObs(uploadObs);
  }

  private _handleUploadObs(uploadObs: ImportBehaviorObservable): void {
    uploadObs.subscribe(
      response => {
        this.totalCreated = response.getTotalCreated();
        this.totalSkipped = response.getTotalSkipped();
        if (response.getErrorList().length) {
          this.errors = response.getErrorList().map(e => ({
            row: this.rows[e.getIndex()],
            errors: e.getMessage(),
            recordIndex: e.getIndex(),
          }));
        }
        if (response.getProgress() === 1) {
          this._uploadComplete();
        }
      },
      err => {
        this.errorMsg = err;
        this.currentUploadState = UploadState.SUMMARY;
      },
      () => {
        if (!this.uploadRan) {
          this._uploadComplete();
        }
      },
    );
  }

  private _uploadComplete(): void {
    this.uploadRan = true;
    this.currentUploadState = UploadState.SUMMARY;
    this.summaryInfo = [
      {
        label: 'Total Behaviors Created',
        metric: this.totalCreated.toString(),
      },
      { label: 'Total Users Skipped', metric: this.totalSkipped.toString() },
    ];
    this._cdr.markForCheck();
  }
}
