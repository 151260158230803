// GENERATED CODE -- DO NOT EDIT!

import {grpc} from '@improbable-eng/grpc-web';

import * as content_minga_pb from '../content/minga_pb';
import * as common_delta_pb from '../common/delta_pb';
import * as common_date_pb from '../common/date_pb';
import * as gateway_moderation_pb from '../gateway/moderation_pb';

export class MingaContent {
  static readonly serviceName = "minga.content.MingaContent";

  static readonly AddProgram = {
    methodName: "AddProgram",
    service: MingaContent,
    requestStream: false,
    responseStream: false,
    requestType: content_minga_pb.MingaAddProgram,
    responseType: content_minga_pb.MingaProgramResult,
  };

  static readonly RemoveProgram = {
    methodName: "RemoveProgram",
    service: MingaContent,
    requestStream: false,
    responseStream: false,
    requestType: content_minga_pb.MingaRemoveProgram,
    responseType: content_minga_pb.MingaProgramResult,
  };

  static readonly AddContent = {
    methodName: "AddContent",
    service: MingaContent,
    requestStream: false,
    responseStream: false,
    requestType: content_minga_pb.MingaAddContent,
    responseType: content_minga_pb.MingaContentResult,
  };

  static readonly RemoveContent = {
    methodName: "RemoveContent",
    service: MingaContent,
    requestStream: false,
    responseStream: false,
    requestType: content_minga_pb.MingaRemoveContent,
    responseType: content_minga_pb.MingaContentResult,
  };

  static readonly MoveContent = {
    methodName: "MoveContent",
    service: MingaContent,
    requestStream: false,
    responseStream: false,
    requestType: content_minga_pb.MingaMoveContent,
    responseType: content_minga_pb.MingaContentResult,
  };

  static readonly UnpinContent = {
    methodName: "UnpinContent",
    service: MingaContent,
    requestStream: false,
    responseStream: false,
    requestType: content_minga_pb.MingaUnpinContent,
    responseType: content_minga_pb.MingaUnpinContentResult,
  };

  static readonly SetCommentingRoles = {
    methodName: "SetCommentingRoles",
    service: MingaContent,
    requestStream: false,
    responseStream: false,
    requestType: content_minga_pb.MingaSetCommentingRolesRequest,
    responseType: content_minga_pb.MingaSetCommentingRolesResult,
  };

}

export class MingaContentClient {
  addProgram() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  removeProgram() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  addContent() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  removeContent() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  moveContent() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  unpinContent() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  setCommentingRoles() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
}
