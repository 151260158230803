import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { MgModalService } from '@app/src/app/minimal/services/MgModal';

/**
 * This dialog will auto open and close a success overlay. Optionally you
 * can pass in text to customize the dialog's success text.
 */
@Component({
  selector: 'mg-success-dialog',
  templateUrl: './SuccessDialog.component.html',
  styleUrls: ['./SuccessDialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SuccessDialog implements OnInit {
  successText: string = 'Success!';
  timeoutDelay: number = 2000;

  @ViewChild('successOverlayTemplate', { static: true })
  successOverlayTemplate?: TemplateRef<SuccessDialog>;

  constructor(
    private dialogRef: MatDialogRef<SuccessDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private modalService: MgModalService,
  ) {
    if (data['text']) {
      this.successText = data['text'];
      this.timeoutDelay = data['timeoutDelay'];
    }
  }

  ngOnInit() {
    this.dialogRef.afterClosed().subscribe(() => {
      this.showSuccessAnimation();
    });

    this.close();
  }

  async showSuccessAnimation() {
    if (!this.successOverlayTemplate) {
      throw new Error('this.successOverlayTemplate unset');
    }

    let dialogRef = this.modalService.open(this.successOverlayTemplate, {
      full: true,
      animation: 'fade',
    });
    setTimeout(() => dialogRef.close(), this.timeoutDelay);

    // Wait for the animation to start a tiny bit
    await new Promise(resolve => setTimeout(resolve, 1000));
  }

  close() {
    this.dialogRef.close();
  }
}
