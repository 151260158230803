// GENERATED CODE -- DO NOT EDIT!

import {grpc} from '@improbable-eng/grpc-web';

import * as sso_sso_pb from '../sso/sso_pb';

export class SsoManager {
  static readonly serviceName = "SsoManager";

  static readonly UnlinkAccount = {
    methodName: "UnlinkAccount",
    service: SsoManager,
    requestStream: false,
    responseStream: false,
    requestType: sso_sso_pb.UnlinkAccountRequest,
    responseType: sso_sso_pb.UnlinkAccountResponse,
  };

}

export class SsoManagerClient {
  unlinkAccount() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
}
