import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { VirtualScrollerModule } from '@minga/ngx-virtual-scroller';

import { MgEmptyStateModule } from '@app/src/app/components/EmptyState';
import { MgPagedScrollerComponent } from '@app/src/app/components/mg-paged-scroller/mg-paged-scroller.component';
import { MgSpinnerModule } from '@app/src/app/spinner';

@NgModule({
  imports: [
    // Minga dependencies
    MgEmptyStateModule,
    MgSpinnerModule,

    // External dependencies
    CommonModule,
    VirtualScrollerModule,
  ],
  exports: [MgPagedScrollerComponent],
  declarations: [MgPagedScrollerComponent],
})
export class MgPagedScrollerModule {}
