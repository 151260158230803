import { IConsequenceClassTypeCount } from 'libs/domain';
import { class_types_pb } from 'libs/generated-grpc-ts';

export const toProto = (
  typeCount: IConsequenceClassTypeCount,
): class_types_pb.ConsequenceTypeCount => {
  const msg = new class_types_pb.ConsequenceTypeCount();

  if (typeCount.id) msg.setId(typeCount.id);
  if (typeCount.name) msg.setName(typeCount.name);
  if (typeCount.description) msg.setDescription(typeCount.description);
  if (typeCount.addNotes) msg.setAddNotes(typeCount.addNotes);
  if (typeCount.categoryId) msg.setCategoryId(typeCount.categoryId);
  if (typeCount.iconType) msg.setIconType(typeCount.iconType);
  if (typeCount.iconColor) msg.setIconColor(typeCount.iconColor);
  if (typeCount.enableDueDate) msg.setEnableDueDate(typeCount.enableDueDate);
  if (typeCount.active) msg.setActive(typeCount.active);

  if (typeCount.count) msg.setCount(typeCount.count);

  return msg;
};

export const fromProto = (
  msg: class_types_pb.ConsequenceTypeCount,
): IConsequenceClassTypeCount => {
  const typeCounts: any = {
    count: msg.getCount(),
    name: msg.getName(),
    description: msg.getDescription(),
    addNotes: msg.getAddNotes(),
    categoryId: msg.getCategoryId(),
    id: msg.getId(),
    iconType: msg.getIconType(),
    iconColor: msg.getIconColor(),
    enableDueDate: msg.getEnableDueDate(),
    active: msg.getActive(),
  };
  return typeCounts;
};
