import { INotificationType } from 'libs/domain';

export function isBadgeNotification(type: string): boolean {
  if (!type) {
    return false;
  }
  switch (type) {
    case INotificationType.BADGE_IMAGE_CHALLENGE:
      return true;
    case INotificationType.BADGE_TEXT_CHALLENGE:
      return true;
    case INotificationType.BADGE_CUSTOM:
      return true;
    default:
      break;
  }

  return false;
}
