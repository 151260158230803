import { day } from 'libs/day';

export function isMultiDay(start: Date, end: Date, allDay: boolean) {
  let a = day(start.getTime());
  let b = day(end.getTime());
  a = a.add(1, 'day');
  if (allDay && a.isSame(b)) {
    return false;
  } else if (start.getDate() == end.getDate()) {
    return false;
  }
  return true;
}
