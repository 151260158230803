import { IHallPassType } from 'libs/domain';
import { Restriction } from 'libs/domain';
import { wrappers_pb } from 'libs/generated-grpc-ts';
import { hall_pass_pb } from 'libs/generated-grpc-ts';

import { MembershipListMapper } from '../membership_list';
import { RestrictionMapper } from '../restriction';

export const toProto = (type: IHallPassType): hall_pass_pb.HallPassType => {
  const msg = new hall_pass_pb.HallPassType();

  if (type.id) {
    msg.setId(type.id);
  }

  if (type.name) {
    msg.setName(type.name);
  }

  if (type.asset) {
    msg.setAsset(type.asset);
  }

  if (type.bannerHash) {
    msg.setBannerHash(type.bannerHash);
  }

  if (type.color) {
    msg.setColor(type.color);
  }

  if (type.createdBy) {
    msg.setCreatedBy(type.createdBy);
  }

  if (type.mingaId) {
    msg.setMingaId(type.mingaId);
  }

  if (type.active) {
    msg.setActive(type.active);
  }

  if (type.selfIssue) {
    msg.setSelfIssue(type.selfIssue);
  }

  if (typeof type.priority == 'number') {
    msg.setPriority(type.priority);
  }

  if (typeof type.defaultHallPassTime == 'number') {
    msg.setDefaultHallPassTime(type.defaultHallPassTime);
  }

  if (type.ignoreBlackout) {
    msg.setIgnoreBlackout(type.ignoreBlackout);
  }

  if (type.ignoreLimits) {
    msg.setIgnoreLimits(type.ignoreLimits);
  }

  if (typeof type.maxSimultaneousPasses == 'number') {
    msg.setMaxSimultaneousPasses(type.maxSimultaneousPasses);
  }

  if (type.membershipListId) {
    msg.setMembershipListId(type.membershipListId);
  }

  if (type.membershipList) {
    const membershipList = MembershipListMapper.toProto(type.membershipList);
    msg.setMembershipList(membershipList);
  }

  if (type.requireTeacherApproval) {
    msg.setRequireTeacherApproval(type.requireTeacherApproval);
  }

  if (type.restriction) {
    const restrictionMsg = RestrictionMapper.toProto(type.restriction);
    msg.setRestriction(restrictionMsg);
  }

  if (type.dailyPassLimit) {
    msg.setDailyPassLimit(type.dailyPassLimit);
  }

  // if the function is called from the frontend it will come as a boolean value
  // if the function is called from the backend it will come as a number value since our db saves as 0/1
  if (
    typeof type.studentCanEndPass == 'boolean' ||
    typeof type.studentCanEndPass == 'number'
  ) {
    const wrapper = new wrappers_pb.BoolValue();
    wrapper.setValue(type.studentCanEndPass);
    msg.setStudentCanEndPass(wrapper);
  }
  if (
    typeof type.canEndOnMobile == 'boolean' ||
    typeof type.canEndOnMobile == 'number'
  ) {
    const wrapper = new wrappers_pb.BoolValue();
    wrapper.setValue(type.canEndOnMobile);
    msg.setCanEndOnMobile(wrapper);
  }
  if (
    typeof type.manuallyEndPass == 'boolean' ||
    typeof type.manuallyEndPass == 'number'
  ) {
    const wrapper = new wrappers_pb.BoolValue();
    wrapper.setValue(type.manuallyEndPass);
    msg.setManuallyEndPass(wrapper);
  }

  if (type.availableInKiosk) {
    msg.setAvailableInKiosk(type.availableInKiosk);
  }

  return msg;
};

export const fromProto = (msg: hall_pass_pb.HallPassType): IHallPassType => {
  let restriction: Restriction | undefined;
  if (msg.getRestriction()) {
    restriction = RestrictionMapper.fromProto(msg.getRestriction());
  }

  const studentCanEndPass = msg.hasStudentCanEndPass()
    ? msg.getStudentCanEndPass().getValue()
    : undefined;
  const canEndOnMobile = msg.hasCanEndOnMobile()
    ? msg.getCanEndOnMobile().getValue()
    : undefined;
  const manuallyEndPass = msg.hasManuallyEndPass()
    ? msg.getManuallyEndPass().getValue()
    : undefined;

  const id = msg.getId();
  const name = msg.getName();
  const bannerHash = msg.getBannerHash();
  const bannerReference = msg.getBannerReference();
  const asset = msg.getAsset();
  const color = msg.getColor();
  const createdBy = msg.getCreatedBy();
  const mingaId = msg.getMingaId();
  const active = msg.getActive();
  const selfIssue = msg.getSelfIssue();
  const priority = msg.getPriority();
  const defaultHallPassTime = msg.getDefaultHallPassTime();
  const membershipListId = msg.getMembershipListId();
  const membershipList = msg.getMembershipList()
    ? MembershipListMapper.fromProto(msg.getMembershipList())
    : undefined;
  const maxSimultaneousPasses = msg.getMaxSimultaneousPasses();
  const ignoreLimits = msg.getIgnoreLimits();
  const ignoreBlackout = msg.getIgnoreBlackout();
  const requireTeacherApproval = msg.getRequireTeacherApproval();
  const dailyPassLimit = msg.getDailyPassLimit();
  const availableInKiosk = msg.getAvailableInKiosk();

  return {
    id,
    name,
    asset,
    bannerHash,
    bannerReference,
    color,
    createdBy,
    mingaId,
    active,
    selfIssue,
    priority,
    defaultHallPassTime,
    membershipListId,
    maxSimultaneousPasses,
    ignoreLimits,
    ignoreBlackout,
    membershipList,
    requireTeacherApproval,
    restriction,
    dailyPassLimit,
    manuallyEndPass,
    studentCanEndPass,
    canEndOnMobile,
    availableInKiosk,
  };
};
