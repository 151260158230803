import {
  Directive,
  ElementRef,
  HostBinding,
  HostListener,
  Input,
} from '@angular/core';
import { Router } from '@angular/router';

import { ContentState } from '@app/src/app/services/ContentState';

import {
  IResolvedContentLinkValue,
  resolveContentLinkValue,
} from './contentLinkUtil';

@Directive({
  selector: '[mgContentLink]',
  exportAs: 'mgContentLink',
})
export class MgContentLinkDirective {
  private _value = IResolvedContentLinkValue.defaultValue();

  @Input()
  set mgContentLink(value: any) {
    this._value = resolveContentLinkValue(value);
  }

  @Input()
  mgContentLinkFragment: 'comments' | '' = '';

  @Input()
  mgContentLinkFocusCommentInput: boolean = false;

  @HostBinding('style.cursor')
  get cursorStyle() {
    return this.isValidContentLink() ? 'pointer' : '';
  }

  constructor(
    private router: Router,
    private contentState: ContentState,
    private element: ElementRef,
  ) {}

  /**
   * Check to see if we have a valid content link. Does not mean content exists
   * it just means opening the content will be attempted.
   */
  isValidContentLink(): boolean {
    return !!this._value.context;
  }

  @HostListener('tap', ['$event'])
  _handleContentLink(hammerjsEv: any) {
    const ev = hammerjsEv.srcEvent as MouseEvent;
    // If our target is an anchor element and it has an 'href' we assume that
    // the 'href' is preferred over this content link so we ignore the click. If
    // the target is the element this directive was placed on then the
    // assumption is we would want this click to not be ignored.
    if (ev.target !== this.element.nativeElement) {
      if (ev.target instanceof HTMLAnchorElement && ev.target.href) {
        return;
      }
    }

    if (!this.isValidContentLink()) {
      return;
    }

    // Ignore anything marked to cancel
    const evTarget = ev.target as Element;
    if (
      evTarget.closest('.mg-cancel-content-link') ||
      evTarget.classList.contains('mg-cancel-content-link') ||
      evTarget.closest('.post-context-menu-btn')
    ) {
      return;
    }

    // The case when a context menu was opened, and is opened a second time
    if (evTarget.classList.contains('cdk-overlay-pane')) {
      const firstChild = evTarget.firstChild as Element;
      if (firstChild && firstChild.classList.contains('mg-cancel-content-link'))
        return;
    }

    ev.preventDefault();
    ev.stopPropagation();
    ev.stopImmediatePropagation();

    const contentContext = this._value.context;

    // @TODO: Fragment scrolling is not working (at least in this case) so
    // this is still using our legacy want/consume system for scrolling into
    // view
    if (this.mgContentLinkFragment === 'comments') {
      this.contentState.wants(contentContext, 'commentsIntoView');
    }

    if (this.mgContentLinkFocusCommentInput) {
      this.contentState.wants(contentContext, 'commentInputFocus');
    }

    this.router.navigate(['', { outlets: { o: ['view', contentContext] } }], {
      fragment: this.mgContentLinkFragment,
    });
  }
}
