import { Validators } from '@angular/forms';

export enum PeopleUserListsEditMessages {
  TITLE_CREATE = 'Create User List',
  TITLE_EDIT = 'Edit User List',

  INPUT_LABEL_TITLE = 'Title',
  INPUT_LABEL_LIST_UUID = 'List ID',

  INPUT_MESSAGE_LIST_MANAGER = 'List Managers',
  INPUT_SUBMESSAGE_LIST_MANAGER = 'Allow users to edit and use this list (Admins are managers by default)',

  INPUT_MESSAGE_MEMBER = 'Members',

  INPUT_LABEL_LOCATION = 'Location',
  INPUT_LABEL_OWNER = 'List owner',

  INPUT_MESSAGE_PUBLIC = 'Make list public',
  INPUT_SUBMESSAGE_PUBLIC = 'Allows other users to use this list',

  INPUT_MESSAGE_HOMEROOM = 'Use as homeroom',
  INPUT_SUBMESSAGE_HOMEROOM = 'The list owner will be used as the homeroom teacher',

  BUTTON_LABEL_ADD = 'Add',
  BUTTON_LABEL_CLOSE = 'Cancel',
  BUTTON_LABEL_DELETE = 'Delete',
  BUTTON_LABEL_CREATE = 'Create',
  BUTTON_LABEL_EDIT = 'Save',
}

export enum PeopleUserListsEditFormFields {
  TITLE = 'title',
  UUID = 'uuid',
  MANAGER_LIST_ID = 'managerListId',
  PUBLIC = 'public',
  HOMEROOM = 'isHomeroom',
  LOCATION = 'location',
  OWNER = 'owner',
}

export const PEOPLE_USER_LISTS_EDIT_FORM_GROUP = {
  [PeopleUserListsEditFormFields.TITLE]: ['', Validators.required],
  [PeopleUserListsEditFormFields.MANAGER_LIST_ID]: [undefined],
  [PeopleUserListsEditFormFields.PUBLIC]: [false],
  [PeopleUserListsEditFormFields.HOMEROOM]: [false],
  [PeopleUserListsEditFormFields.LOCATION]: [''],
};
