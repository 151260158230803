import { IVideoUpload } from 'libs/domain';
import { video_upload_pb } from 'libs/generated-grpc-ts';

export namespace IVideoUploadProtoMapper {
  export function fromIVideoUploadMessage(
    message: video_upload_pb.VideoUploadInfo,
  ): IVideoUpload | null {
    if (!message) {
      return null;
    }
    return {
      cloudflareUid: message.getCloudflareUid(),
      processing: true,
      videoThumbnail: message.getVideoThumbnail(),
    };
  }

  export function toIVideoUploadMessage(
    video: IVideoUpload,
  ): video_upload_pb.VideoUploadInfo {
    const message = new video_upload_pb.VideoUploadInfo();
    if (video.cloudflareUid) {
      message.setCloudflareUid(video.cloudflareUid);
    }
    if (video.videoThumbnail) {
      message.setVideoThumbnail(video.videoThumbnail);
    }
    // signed token?

    return message;
  }
}
