import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';

import { MgIconModule } from '@app/src/app/icon';

import { GenericModule } from '../generic';
import { ConfirmationDialogComponent } from './confirmation-dialog.component';

@NgModule({
  imports: [
    // Minga dependencies
    MgIconModule,
    GenericModule,

    // External dependencies
    CommonModule,
    FlexLayoutModule,
  ],
  exports: [ConfirmationDialogComponent],
  declarations: [ConfirmationDialogComponent],
})
export class ConfirmationDialogModule {}
