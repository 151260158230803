// source: gateway/login.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

import * as jspb from 'google-protobuf';
import * as common_legacy_pb from '../common/legacy_pb';
import * as common_date_pb from '../common/date_pb';
import * as gateway_people_pb from '../gateway/people_pb';
import * as gateway_person_view_pb from '../gateway/person_view_pb';
import * as gateway_connect_pb from '../gateway/connect_pb';
import * as gateway_minga_pb from '../gateway/minga_pb';
import * as gateway_notification_pb from '../gateway/notification_pb';
import * as gateway_mingaGroup_pb from '../gateway/mingaGroup_pb';
export namespace EventStatusMessage {
  export interface AsObject {
    eventHash: string;
    currentEventStatus: string;
  }
}
export class EventStatusMessage extends jspb.Message {
  static readonly displayName = "EventStatusMessage";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): EventStatusMessage.AsObject {
    return EventStatusMessage.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!EventStatusMessage} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: EventStatusMessage) {
    var f: any;
    var obj: any = {
      eventHash: jspb.Message.getFieldWithDefault(msg, 1, ""),
      currentEventStatus: jspb.Message.getFieldWithDefault(msg, 2, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!EventStatusMessage}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new EventStatusMessage;
    return EventStatusMessage.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!EventStatusMessage} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!EventStatusMessage}
   */
  static deserializeBinaryFromReader(msg: EventStatusMessage, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setEventHash(value1);
        break;
      case 2:
        var value2 = /** @type {string} */ (reader.readString());
        msg.setCurrentEventStatus(value2);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!EventStatusMessage} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: EventStatusMessage, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getEventHash();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
    f = message.getCurrentEventStatus();
    if (f.length > 0) {
      writer.writeString(
        2,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    EventStatusMessage.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string event_hash = 1;
   * @return {string}
   */
  getEventHash(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setEventHash(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


  /**
   * optional string current_event_status = 2;
   * @return {string}
   */
  getCurrentEventStatus(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
  };


  /** @param {string} value */
  setCurrentEventStatus(value: string) {
    jspb.Message.setProto3StringField(this, 2, value);
  }


}
export namespace PersonContentMeta {
  export interface AsObject {
    likeList: string[];
    eventGoingsList: string[];
    eventCheckinsList: string[];
    pollAnswersMap: [string, number][];
    eventStatusesList: EventStatusMessage.AsObject[];
  }
}
export class PersonContentMeta extends jspb.Message {
  static readonly displayName = "PersonContentMeta";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [1,2,3,5];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, PersonContentMeta.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): PersonContentMeta.AsObject {
    return PersonContentMeta.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!PersonContentMeta} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: PersonContentMeta) {
    var f: any;
    var obj: any = {
      likeList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
      eventGoingsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
      eventCheckinsList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
      pollAnswersMap: (f = msg.getPollAnswersMap()) ? f.toObject(includeInstance, undefined) : [],
      eventStatusesList: jspb.Message.toObjectList(msg.getEventStatusesList(),
      EventStatusMessage.toObject, includeInstance)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!PersonContentMeta}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new PersonContentMeta;
    return PersonContentMeta.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!PersonContentMeta} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!PersonContentMeta}
   */
  static deserializeBinaryFromReader(msg: PersonContentMeta, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.addLike(value1);
        break;
      case 2:
        var value2 = /** @type {string} */ (reader.readString());
        msg.addEventGoings(value2);
        break;
      case 3:
        var value3 = /** @type {string} */ (reader.readString());
        msg.addEventCheckins(value3);
        break;
      case 4:
        var value4 = msg.getPollAnswersMap();
        reader.readMessage(value4, function(message, reader) {
          jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readUint32, null, "");
           });
        break;
      case 5:
        var value5 = new EventStatusMessage;
        reader.readMessage(value5,EventStatusMessage.deserializeBinaryFromReader);
        msg.addEventStatuses(value5);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!PersonContentMeta} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: PersonContentMeta, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getLikeList();
    if (f.length > 0) {
      writer.writeRepeatedString(
        1,
        f
      );
    }
    f = message.getEventGoingsList();
    if (f.length > 0) {
      writer.writeRepeatedString(
        2,
        f
      );
    }
    f = message.getEventCheckinsList();
    if (f.length > 0) {
      writer.writeRepeatedString(
        3,
        f
      );
    }
    f = message.getPollAnswersMap(true);
    if (f && f.getLength() > 0) {
      f.serializeBinary(4, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeUint32);
    }
    f = message.getEventStatusesList();
    if (f.length > 0) {
      writer.writeRepeatedMessage(
        5,
        f,
        EventStatusMessage.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    PersonContentMeta.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * repeated string like = 1;
   * @return {!Array<string>}
   */
  getLikeList(): string[] {
    return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
  };


  /** @param {!Array<string>} value */
  setLikeList(value: string[]) {
    jspb.Message.setField(this, 1, value || []);
  }


  /**
   * @param {string} value
   * @param {number=} opt_index
   */
  addLike(value: string, opt_index?: number): void {
    jspb.Message.addToRepeatedField(this, 1, value, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearLikeList() {
    this.setLikeList([]);
  }


  /**
   * repeated string event_goings = 2;
   * @return {!Array<string>}
   */
  getEventGoingsList(): string[] {
    return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
  };


  /** @param {!Array<string>} value */
  setEventGoingsList(value: string[]) {
    jspb.Message.setField(this, 2, value || []);
  }


  /**
   * @param {string} value
   * @param {number=} opt_index
   */
  addEventGoings(value: string, opt_index?: number): void {
    jspb.Message.addToRepeatedField(this, 2, value, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearEventGoingsList() {
    this.setEventGoingsList([]);
  }


  /**
   * repeated string event_checkins = 3;
   * @return {!Array<string>}
   */
  getEventCheckinsList(): string[] {
    return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
  };


  /** @param {!Array<string>} value */
  setEventCheckinsList(value: string[]) {
    jspb.Message.setField(this, 3, value || []);
  }


  /**
   * @param {string} value
   * @param {number=} opt_index
   */
  addEventCheckins(value: string, opt_index?: number): void {
    jspb.Message.addToRepeatedField(this, 3, value, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearEventCheckinsList() {
    this.setEventCheckinsList([]);
  }


  /**
   * map<string, uint32> poll_answers = 4;
   * @param {boolean=} opt_noLazyCreate Do not create the map if
   * empty, instead returning `undefined`
   * @return {!jspb.Map<string,number>}
   */
  getPollAnswersMap(): jspb.Map<string,number>;
  getPollAnswersMap(noLazyCreate: true): jspb.Map<string,number>|undefined;
  getPollAnswersMap(noLazyCreate: false): jspb.Map<string,number>;
  getPollAnswersMap(opt_noLazyCreate: boolean = false): jspb.Map<string,number> {
    return /** @type {!jspb.Map<string,number>} */ (
        jspb.Message.getMapField(this, 4, opt_noLazyCreate,
        null));
  }


  /**
   * Clears values from the map. The map will be non-null.
   */
  clearPollAnswersMap() {
    this.getPollAnswersMap().clear();
  }


  /**
   * repeated EventStatusMessage event_statuses = 5;
   * @return {!Array<!EventStatusMessage>}
   */
  getEventStatusesList(): EventStatusMessage[] {
    return /** @type{!Array<!EventStatusMessage>} */ (
      jspb.Message.getRepeatedWrapperField(this, EventStatusMessage, 5));
  }


  /** @param {!Array<!EventStatusMessage>} value */
  setEventStatusesList(value?: EventStatusMessage[]) {
    jspb.Message.setRepeatedWrapperField(this, 5, value);
  }


  /**
   * @param {!EventStatusMessage=} opt_value
   * @param {number=} opt_index
   * @return {!EventStatusMessage}
   */
  addEventStatuses(opt_value?: EventStatusMessage, opt_index?: number): EventStatusMessage {
    return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, EventStatusMessage, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearEventStatusesList() {
    this.setEventStatusesList([]);
  }


}
export namespace LoginRequest {
  export interface AsObject {
    deprecatedLocalLogin?: LoginRequest.LoginLocal.AsObject;
    email: string;
    password: string;
    deviceId: string;
    firebaseRegistrationToken: string;
    gaClientId: string;
  }
}
export class LoginRequest extends jspb.Message {
  static readonly displayName = "LoginRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): LoginRequest.AsObject {
    return LoginRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!LoginRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: LoginRequest) {
    var f: any;
    var obj: any = {
      deprecatedLocalLogin: (f = msg.getDeprecatedLocalLogin()) && LoginRequest.LoginLocal.toObject(includeInstance, f),
      email: jspb.Message.getFieldWithDefault(msg, 2, ""),
      password: jspb.Message.getFieldWithDefault(msg, 3, ""),
      deviceId: jspb.Message.getFieldWithDefault(msg, 4, ""),
      firebaseRegistrationToken: jspb.Message.getFieldWithDefault(msg, 5, ""),
      gaClientId: jspb.Message.getFieldWithDefault(msg, 6, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!LoginRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new LoginRequest;
    return LoginRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!LoginRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!LoginRequest}
   */
  static deserializeBinaryFromReader(msg: LoginRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new LoginRequest.LoginLocal;
        reader.readMessage(value1,LoginRequest.LoginLocal.deserializeBinaryFromReader);
        msg.setDeprecatedLocalLogin(value1);
        break;
      case 2:
        var value2 = /** @type {string} */ (reader.readString());
        msg.setEmail(value2);
        break;
      case 3:
        var value3 = /** @type {string} */ (reader.readString());
        msg.setPassword(value3);
        break;
      case 4:
        var value4 = /** @type {string} */ (reader.readString());
        msg.setDeviceId(value4);
        break;
      case 5:
        var value5 = /** @type {string} */ (reader.readString());
        msg.setFirebaseRegistrationToken(value5);
        break;
      case 6:
        var value6 = /** @type {string} */ (reader.readString());
        msg.setGaClientId(value6);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!LoginRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: LoginRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getDeprecatedLocalLogin();
    if (f != null) {
      writer.writeMessage(
        1,
        f,
        LoginRequest.LoginLocal.serializeBinaryToWriter
      );
    }
    f = message.getEmail();
    if (f.length > 0) {
      writer.writeString(
        2,
        f
      );
    }
    f = message.getPassword();
    if (f.length > 0) {
      writer.writeString(
        3,
        f
      );
    }
    f = message.getDeviceId();
    if (f.length > 0) {
      writer.writeString(
        4,
        f
      );
    }
    f = message.getFirebaseRegistrationToken();
    if (f.length > 0) {
      writer.writeString(
        5,
        f
      );
    }
    f = message.getGaClientId();
    if (f.length > 0) {
      writer.writeString(
        6,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    LoginRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional LoginLocal deprecated_local_login = 1;
   * @return {?LoginRequest.LoginLocal}
   */
  getDeprecatedLocalLogin(): LoginRequest.LoginLocal {
    return /** @type{?LoginRequest.LoginLocal} */ (
      jspb.Message.getWrapperField(this, LoginRequest.LoginLocal, 1));
  }


  /** @param {?LoginRequest.LoginLocal|undefined} value */
  setDeprecatedLocalLogin(value?: LoginRequest.LoginLocal) {
    jspb.Message.setWrapperField(this, 1, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearDeprecatedLocalLogin() {
    this.setDeprecatedLocalLogin(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasDeprecatedLocalLogin(): boolean {
    return jspb.Message.getField(this, 1) != null;
  }


  /**
   * optional string email = 2;
   * @return {string}
   */
  getEmail(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
  };


  /** @param {string} value */
  setEmail(value: string) {
    jspb.Message.setProto3StringField(this, 2, value);
  }


  /**
   * optional string password = 3;
   * @return {string}
   */
  getPassword(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
  };


  /** @param {string} value */
  setPassword(value: string) {
    jspb.Message.setProto3StringField(this, 3, value);
  }


  /**
   * optional string device_id = 4;
   * @return {string}
   */
  getDeviceId(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
  };


  /** @param {string} value */
  setDeviceId(value: string) {
    jspb.Message.setProto3StringField(this, 4, value);
  }


  /**
   * optional string firebase_registration_token = 5;
   * @return {string}
   */
  getFirebaseRegistrationToken(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
  };


  /** @param {string} value */
  setFirebaseRegistrationToken(value: string) {
    jspb.Message.setProto3StringField(this, 5, value);
  }


  /**
   * optional string ga_client_id = 6;
   * @return {string}
   */
  getGaClientId(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
  };


  /** @param {string} value */
  setGaClientId(value: string) {
    jspb.Message.setProto3StringField(this, 6, value);
  }


}
export namespace LoginRequest {
export namespace LoginLocal {
  export interface AsObject {
    username: string;
    password: string;
    deviceId: string;
  }
}
export class LoginLocal extends jspb.Message {
  static readonly displayName = "LoginRequest.LoginLocal";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): LoginLocal.AsObject {
    return LoginLocal.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!LoginLocal} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: LoginLocal) {
    var f: any;
    var obj: any = {
      username: jspb.Message.getFieldWithDefault(msg, 1, ""),
      password: jspb.Message.getFieldWithDefault(msg, 2, ""),
      deviceId: jspb.Message.getFieldWithDefault(msg, 3, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!LoginLocal}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new LoginLocal;
    return LoginLocal.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!LoginRequest.LoginLocal} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!LoginRequest.LoginLocal}
   */
  static deserializeBinaryFromReader(msg: LoginLocal, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setUsername(value1);
        break;
      case 2:
        var value2 = /** @type {string} */ (reader.readString());
        msg.setPassword(value2);
        break;
      case 3:
        var value3 = /** @type {string} */ (reader.readString());
        msg.setDeviceId(value3);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!LoginRequest.LoginLocal} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: LoginRequest.LoginLocal, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getUsername();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
    f = message.getPassword();
    if (f.length > 0) {
      writer.writeString(
        2,
        f
      );
    }
    f = message.getDeviceId();
    if (f.length > 0) {
      writer.writeString(
        3,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    LoginRequest.LoginLocal.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string username = 1;
   * @return {string}
   */
  getUsername(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setUsername(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


  /**
   * optional string password = 2;
   * @return {string}
   */
  getPassword(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
  };


  /** @param {string} value */
  setPassword(value: string) {
    jspb.Message.setProto3StringField(this, 2, value);
  }


  /**
   * optional string device_id = 3;
   * @return {string}
   */
  getDeviceId(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
  };


  /** @param {string} value */
  setDeviceId(value: string) {
    jspb.Message.setProto3StringField(this, 3, value);
  }


}
} // namespace LoginRequest
export namespace DeviceLoginRequest {
  export interface AsObject {
    deviceId: string;
    firebaseRegistrationToken: string;
    gaClientId: string;
  }
}
export class DeviceLoginRequest extends jspb.Message {
  static readonly displayName = "DeviceLoginRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): DeviceLoginRequest.AsObject {
    return DeviceLoginRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!DeviceLoginRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: DeviceLoginRequest) {
    var f: any;
    var obj: any = {
      deviceId: jspb.Message.getFieldWithDefault(msg, 1, ""),
      firebaseRegistrationToken: jspb.Message.getFieldWithDefault(msg, 2, ""),
      gaClientId: jspb.Message.getFieldWithDefault(msg, 3, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!DeviceLoginRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new DeviceLoginRequest;
    return DeviceLoginRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!DeviceLoginRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!DeviceLoginRequest}
   */
  static deserializeBinaryFromReader(msg: DeviceLoginRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setDeviceId(value1);
        break;
      case 2:
        var value2 = /** @type {string} */ (reader.readString());
        msg.setFirebaseRegistrationToken(value2);
        break;
      case 3:
        var value3 = /** @type {string} */ (reader.readString());
        msg.setGaClientId(value3);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!DeviceLoginRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: DeviceLoginRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getDeviceId();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
    f = message.getFirebaseRegistrationToken();
    if (f.length > 0) {
      writer.writeString(
        2,
        f
      );
    }
    f = message.getGaClientId();
    if (f.length > 0) {
      writer.writeString(
        3,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    DeviceLoginRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string device_id = 1;
   * @return {string}
   */
  getDeviceId(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setDeviceId(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


  /**
   * optional string firebase_registration_token = 2;
   * @return {string}
   */
  getFirebaseRegistrationToken(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
  };


  /** @param {string} value */
  setFirebaseRegistrationToken(value: string) {
    jspb.Message.setProto3StringField(this, 2, value);
  }


  /**
   * optional string ga_client_id = 3;
   * @return {string}
   */
  getGaClientId(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
  };


  /** @param {string} value */
  setGaClientId(value: string) {
    jspb.Message.setProto3StringField(this, 3, value);
  }


}
export namespace LoginResponse {
  export interface AsObject {
    status?: common_legacy_pb.StatusCode;
    customAuthToken: string;
    uid: string;
    personName: string;
    mingaName: string;
    mingaLogo: string;
    reason?: common_legacy_pb.StatusReason.AsObject;
    account?: gateway_people_pb.Account.AsObject;
    personContentMeta?: PersonContentMeta.AsObject;
    personViewMinimal?: gateway_person_view_pb.PersonViewMinimal.AsObject;
    birthdate?: common_date_pb.Date.AsObject;
    publicBirthdate: boolean;
    apiUrl: string;
    appConfig?: gateway_connect_pb.AppConfigResponse.AsObject;
    accountRoleType: string;
    mingaInfo?: gateway_minga_pb.MingaInfoMinimal.AsObject;
    parentGroupHashList: string[];
    dmPersonalPreference: boolean;
    ftueScreensList: string[];
    showRateApp: boolean;
    disabledDm: boolean;
    accountCreated?: common_date_pb.Date.AsObject;
    logins: number;
    userMingasList: gateway_minga_pb.MingaInfoMinimal.AsObject[];
    email: string;
  }
}
export class LoginResponse extends jspb.Message {
  static readonly displayName = "LoginResponse";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [17,19,24];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, LoginResponse.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): LoginResponse.AsObject {
    return LoginResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!LoginResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: LoginResponse) {
    var f: any;
    var obj: any = {
      status: jspb.Message.getFieldWithDefault(msg, 1, 0),
      customAuthToken: jspb.Message.getFieldWithDefault(msg, 2, ""),
      uid: jspb.Message.getFieldWithDefault(msg, 3, ""),
      personName: jspb.Message.getFieldWithDefault(msg, 4, ""),
      mingaName: jspb.Message.getFieldWithDefault(msg, 5, ""),
      mingaLogo: jspb.Message.getFieldWithDefault(msg, 6, ""),
      reason: (f = msg.getReason()) && common_legacy_pb.StatusReason.toObject(includeInstance, f),
      account: (f = msg.getAccount()) && gateway_people_pb.Account.toObject(includeInstance, f),
      personContentMeta: (f = msg.getPersonContentMeta()) && PersonContentMeta.toObject(includeInstance, f),
      personViewMinimal: (f = msg.getPersonViewMinimal()) && gateway_person_view_pb.PersonViewMinimal.toObject(includeInstance, f),
      birthdate: (f = msg.getBirthdate()) && common_date_pb.Date.toObject(includeInstance, f),
      publicBirthdate: jspb.Message.getBooleanFieldWithDefault(msg, 12, false),
      apiUrl: jspb.Message.getFieldWithDefault(msg, 13, ""),
      appConfig: (f = msg.getAppConfig()) && gateway_connect_pb.AppConfigResponse.toObject(includeInstance, f),
      accountRoleType: jspb.Message.getFieldWithDefault(msg, 15, ""),
      mingaInfo: (f = msg.getMingaInfo()) && gateway_minga_pb.MingaInfoMinimal.toObject(includeInstance, f),
      parentGroupHashList: (f = jspb.Message.getRepeatedField(msg, 17)) == null ? undefined : f,
      dmPersonalPreference: jspb.Message.getBooleanFieldWithDefault(msg, 18, false),
      ftueScreensList: (f = jspb.Message.getRepeatedField(msg, 19)) == null ? undefined : f,
      showRateApp: jspb.Message.getBooleanFieldWithDefault(msg, 20, false),
      disabledDm: jspb.Message.getBooleanFieldWithDefault(msg, 21, false),
      accountCreated: (f = msg.getAccountCreated()) && common_date_pb.Date.toObject(includeInstance, f),
      logins: jspb.Message.getFieldWithDefault(msg, 23, 0),
      userMingasList: jspb.Message.toObjectList(msg.getUserMingasList(),
      gateway_minga_pb.MingaInfoMinimal.toObject, includeInstance),
      email: jspb.Message.getFieldWithDefault(msg, 25, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!LoginResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new LoginResponse;
    return LoginResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!LoginResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!LoginResponse}
   */
  static deserializeBinaryFromReader(msg: LoginResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {!StatusCode} */ (reader.readEnum());
        msg.setStatus(value1);
        break;
      case 2:
        var value2 = /** @type {string} */ (reader.readString());
        msg.setCustomAuthToken(value2);
        break;
      case 3:
        var value3 = /** @type {string} */ (reader.readString());
        msg.setUid(value3);
        break;
      case 4:
        var value4 = /** @type {string} */ (reader.readString());
        msg.setPersonName(value4);
        break;
      case 5:
        var value5 = /** @type {string} */ (reader.readString());
        msg.setMingaName(value5);
        break;
      case 6:
        var value6 = /** @type {string} */ (reader.readString());
        msg.setMingaLogo(value6);
        break;
      case 7:
        var value7 = new common_legacy_pb.StatusReason;
        reader.readMessage(value7,common_legacy_pb.StatusReason.deserializeBinaryFromReader);
        msg.setReason(value7);
        break;
      case 8:
        var value8 = new gateway_people_pb.Account;
        reader.readMessage(value8,gateway_people_pb.Account.deserializeBinaryFromReader);
        msg.setAccount(value8);
        break;
      case 9:
        var value9 = new PersonContentMeta;
        reader.readMessage(value9,PersonContentMeta.deserializeBinaryFromReader);
        msg.setPersonContentMeta(value9);
        break;
      case 10:
        var value10 = new gateway_person_view_pb.PersonViewMinimal;
        reader.readMessage(value10,gateway_person_view_pb.PersonViewMinimal.deserializeBinaryFromReader);
        msg.setPersonViewMinimal(value10);
        break;
      case 11:
        var value11 = new common_date_pb.Date;
        reader.readMessage(value11,common_date_pb.Date.deserializeBinaryFromReader);
        msg.setBirthdate(value11);
        break;
      case 12:
        var value12 = /** @type {boolean} */ (reader.readBool());
        msg.setPublicBirthdate(value12);
        break;
      case 13:
        var value13 = /** @type {string} */ (reader.readString());
        msg.setApiUrl(value13);
        break;
      case 14:
        var value14 = new gateway_connect_pb.AppConfigResponse;
        reader.readMessage(value14,gateway_connect_pb.AppConfigResponse.deserializeBinaryFromReader);
        msg.setAppConfig(value14);
        break;
      case 15:
        var value15 = /** @type {string} */ (reader.readString());
        msg.setAccountRoleType(value15);
        break;
      case 16:
        var value16 = new gateway_minga_pb.MingaInfoMinimal;
        reader.readMessage(value16,gateway_minga_pb.MingaInfoMinimal.deserializeBinaryFromReader);
        msg.setMingaInfo(value16);
        break;
      case 17:
        var value17 = /** @type {string} */ (reader.readString());
        msg.addParentGroupHash(value17);
        break;
      case 18:
        var value18 = /** @type {boolean} */ (reader.readBool());
        msg.setDmPersonalPreference(value18);
        break;
      case 19:
        var value19 = /** @type {string} */ (reader.readString());
        msg.addFtueScreens(value19);
        break;
      case 20:
        var value20 = /** @type {boolean} */ (reader.readBool());
        msg.setShowRateApp(value20);
        break;
      case 21:
        var value21 = /** @type {boolean} */ (reader.readBool());
        msg.setDisabledDm(value21);
        break;
      case 22:
        var value22 = new common_date_pb.Date;
        reader.readMessage(value22,common_date_pb.Date.deserializeBinaryFromReader);
        msg.setAccountCreated(value22);
        break;
      case 23:
        var value23 = /** @type {number} */ (reader.readInt32());
        msg.setLogins(value23);
        break;
      case 24:
        var value24 = new gateway_minga_pb.MingaInfoMinimal;
        reader.readMessage(value24,gateway_minga_pb.MingaInfoMinimal.deserializeBinaryFromReader);
        msg.addUserMingas(value24);
        break;
      case 25:
        var value25 = /** @type {string} */ (reader.readString());
        msg.setEmail(value25);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!LoginResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: LoginResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getStatus();
    if (f !== 0.0) {
      writer.writeEnum(
        1,
        f
      );
    }
    f = message.getCustomAuthToken();
    if (f.length > 0) {
      writer.writeString(
        2,
        f
      );
    }
    f = message.getUid();
    if (f.length > 0) {
      writer.writeString(
        3,
        f
      );
    }
    f = message.getPersonName();
    if (f.length > 0) {
      writer.writeString(
        4,
        f
      );
    }
    f = message.getMingaName();
    if (f.length > 0) {
      writer.writeString(
        5,
        f
      );
    }
    f = message.getMingaLogo();
    if (f.length > 0) {
      writer.writeString(
        6,
        f
      );
    }
    f = message.getReason();
    if (f != null) {
      writer.writeMessage(
        7,
        f,
        common_legacy_pb.StatusReason.serializeBinaryToWriter
      );
    }
    f = message.getAccount();
    if (f != null) {
      writer.writeMessage(
        8,
        f,
        gateway_people_pb.Account.serializeBinaryToWriter
      );
    }
    f = message.getPersonContentMeta();
    if (f != null) {
      writer.writeMessage(
        9,
        f,
        PersonContentMeta.serializeBinaryToWriter
      );
    }
    f = message.getPersonViewMinimal();
    if (f != null) {
      writer.writeMessage(
        10,
        f,
        gateway_person_view_pb.PersonViewMinimal.serializeBinaryToWriter
      );
    }
    f = message.getBirthdate();
    if (f != null) {
      writer.writeMessage(
        11,
        f,
        common_date_pb.Date.serializeBinaryToWriter
      );
    }
    f = message.getPublicBirthdate();
    if (f) {
      writer.writeBool(
        12,
        f
      );
    }
    f = message.getApiUrl();
    if (f.length > 0) {
      writer.writeString(
        13,
        f
      );
    }
    f = message.getAppConfig();
    if (f != null) {
      writer.writeMessage(
        14,
        f,
        gateway_connect_pb.AppConfigResponse.serializeBinaryToWriter
      );
    }
    f = message.getAccountRoleType();
    if (f.length > 0) {
      writer.writeString(
        15,
        f
      );
    }
    f = message.getMingaInfo();
    if (f != null) {
      writer.writeMessage(
        16,
        f,
        gateway_minga_pb.MingaInfoMinimal.serializeBinaryToWriter
      );
    }
    f = message.getParentGroupHashList();
    if (f.length > 0) {
      writer.writeRepeatedString(
        17,
        f
      );
    }
    f = message.getDmPersonalPreference();
    if (f) {
      writer.writeBool(
        18,
        f
      );
    }
    f = message.getFtueScreensList();
    if (f.length > 0) {
      writer.writeRepeatedString(
        19,
        f
      );
    }
    f = message.getShowRateApp();
    if (f) {
      writer.writeBool(
        20,
        f
      );
    }
    f = message.getDisabledDm();
    if (f) {
      writer.writeBool(
        21,
        f
      );
    }
    f = message.getAccountCreated();
    if (f != null) {
      writer.writeMessage(
        22,
        f,
        common_date_pb.Date.serializeBinaryToWriter
      );
    }
    f = message.getLogins();
    if (f !== 0) {
      writer.writeInt32(
        23,
        f
      );
    }
    f = message.getUserMingasList();
    if (f.length > 0) {
      writer.writeRepeatedMessage(
        24,
        f,
        gateway_minga_pb.MingaInfoMinimal.serializeBinaryToWriter
      );
    }
    f = message.getEmail();
    if (f.length > 0) {
      writer.writeString(
        25,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    LoginResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional StatusCode status = 1;
   * @return {!StatusCode}
   */
  getStatus(): common_legacy_pb.StatusCode {
    return /** @type {!StatusCode} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
  };


  /** @param {!StatusCode} value */
  setStatus(value: common_legacy_pb.StatusCode) {
    jspb.Message.setProto3EnumField(this, 1, value);
  }


  /**
   * optional string custom_auth_token = 2;
   * @return {string}
   */
  getCustomAuthToken(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
  };


  /** @param {string} value */
  setCustomAuthToken(value: string) {
    jspb.Message.setProto3StringField(this, 2, value);
  }


  /**
   * optional string uid = 3;
   * @return {string}
   */
  getUid(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
  };


  /** @param {string} value */
  setUid(value: string) {
    jspb.Message.setProto3StringField(this, 3, value);
  }


  /**
   * optional string person_name = 4;
   * @return {string}
   */
  getPersonName(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
  };


  /** @param {string} value */
  setPersonName(value: string) {
    jspb.Message.setProto3StringField(this, 4, value);
  }


  /**
   * optional string minga_name = 5;
   * @return {string}
   */
  getMingaName(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
  };


  /** @param {string} value */
  setMingaName(value: string) {
    jspb.Message.setProto3StringField(this, 5, value);
  }


  /**
   * optional string minga_logo = 6;
   * @return {string}
   */
  getMingaLogo(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
  };


  /** @param {string} value */
  setMingaLogo(value: string) {
    jspb.Message.setProto3StringField(this, 6, value);
  }


  /**
   * optional StatusReason reason = 7;
   * @return {?StatusReason}
   */
  getReason(): common_legacy_pb.StatusReason {
    return /** @type{?StatusReason} */ (
      jspb.Message.getWrapperField(this, common_legacy_pb.StatusReason, 7));
  }


  /** @param {?StatusReason|undefined} value */
  setReason(value?: common_legacy_pb.StatusReason) {
    jspb.Message.setWrapperField(this, 7, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearReason() {
    this.setReason(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasReason(): boolean {
    return jspb.Message.getField(this, 7) != null;
  }


  /**
   * optional Account account = 8;
   * @return {?Account}
   */
  getAccount(): gateway_people_pb.Account {
    return /** @type{?Account} */ (
      jspb.Message.getWrapperField(this, gateway_people_pb.Account, 8));
  }


  /** @param {?Account|undefined} value */
  setAccount(value?: gateway_people_pb.Account) {
    jspb.Message.setWrapperField(this, 8, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearAccount() {
    this.setAccount(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasAccount(): boolean {
    return jspb.Message.getField(this, 8) != null;
  }


  /**
   * optional PersonContentMeta person_content_meta = 9;
   * @return {?PersonContentMeta}
   */
  getPersonContentMeta(): PersonContentMeta {
    return /** @type{?PersonContentMeta} */ (
      jspb.Message.getWrapperField(this, PersonContentMeta, 9));
  }


  /** @param {?PersonContentMeta|undefined} value */
  setPersonContentMeta(value?: PersonContentMeta) {
    jspb.Message.setWrapperField(this, 9, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearPersonContentMeta() {
    this.setPersonContentMeta(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasPersonContentMeta(): boolean {
    return jspb.Message.getField(this, 9) != null;
  }


  /**
   * optional PersonViewMinimal person_view_minimal = 10;
   * @return {?PersonViewMinimal}
   */
  getPersonViewMinimal(): gateway_person_view_pb.PersonViewMinimal {
    return /** @type{?PersonViewMinimal} */ (
      jspb.Message.getWrapperField(this, gateway_person_view_pb.PersonViewMinimal, 10));
  }


  /** @param {?PersonViewMinimal|undefined} value */
  setPersonViewMinimal(value?: gateway_person_view_pb.PersonViewMinimal) {
    jspb.Message.setWrapperField(this, 10, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearPersonViewMinimal() {
    this.setPersonViewMinimal(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasPersonViewMinimal(): boolean {
    return jspb.Message.getField(this, 10) != null;
  }


  /**
   * optional minga.common.Date birthdate = 11;
   * @return {?Date}
   */
  getBirthdate(): common_date_pb.Date {
    return /** @type{?Date} */ (
      jspb.Message.getWrapperField(this, common_date_pb.Date, 11));
  }


  /** @param {?Date|undefined} value */
  setBirthdate(value?: common_date_pb.Date) {
    jspb.Message.setWrapperField(this, 11, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearBirthdate() {
    this.setBirthdate(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasBirthdate(): boolean {
    return jspb.Message.getField(this, 11) != null;
  }


  /**
   * optional bool public_birthdate = 12;
   * @return {boolean}
   */
  getPublicBirthdate(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 12, false));
  };


  /** @param {boolean} value */
  setPublicBirthdate(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 12, value);
  }


  /**
   * optional string api_url = 13;
   * @return {string}
   */
  getApiUrl(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
  };


  /** @param {string} value */
  setApiUrl(value: string) {
    jspb.Message.setProto3StringField(this, 13, value);
  }


  /**
   * optional AppConfigResponse app_config = 14;
   * @return {?AppConfigResponse}
   */
  getAppConfig(): gateway_connect_pb.AppConfigResponse {
    return /** @type{?AppConfigResponse} */ (
      jspb.Message.getWrapperField(this, gateway_connect_pb.AppConfigResponse, 14));
  }


  /** @param {?AppConfigResponse|undefined} value */
  setAppConfig(value?: gateway_connect_pb.AppConfigResponse) {
    jspb.Message.setWrapperField(this, 14, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearAppConfig() {
    this.setAppConfig(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasAppConfig(): boolean {
    return jspb.Message.getField(this, 14) != null;
  }


  /**
   * optional string account_role_type = 15;
   * @return {string}
   */
  getAccountRoleType(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
  };


  /** @param {string} value */
  setAccountRoleType(value: string) {
    jspb.Message.setProto3StringField(this, 15, value);
  }


  /**
   * optional MingaInfoMinimal minga_info = 16;
   * @return {?MingaInfoMinimal}
   */
  getMingaInfo(): gateway_minga_pb.MingaInfoMinimal {
    return /** @type{?MingaInfoMinimal} */ (
      jspb.Message.getWrapperField(this, gateway_minga_pb.MingaInfoMinimal, 16));
  }


  /** @param {?MingaInfoMinimal|undefined} value */
  setMingaInfo(value?: gateway_minga_pb.MingaInfoMinimal) {
    jspb.Message.setWrapperField(this, 16, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearMingaInfo() {
    this.setMingaInfo(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasMingaInfo(): boolean {
    return jspb.Message.getField(this, 16) != null;
  }


  /**
   * repeated string parent_group_hash = 17;
   * @return {!Array<string>}
   */
  getParentGroupHashList(): string[] {
    return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 17));
  };


  /** @param {!Array<string>} value */
  setParentGroupHashList(value: string[]) {
    jspb.Message.setField(this, 17, value || []);
  }


  /**
   * @param {string} value
   * @param {number=} opt_index
   */
  addParentGroupHash(value: string, opt_index?: number): void {
    jspb.Message.addToRepeatedField(this, 17, value, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearParentGroupHashList() {
    this.setParentGroupHashList([]);
  }


  /**
   * optional bool dm_personal_preference = 18;
   * @return {boolean}
   */
  getDmPersonalPreference(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 18, false));
  };


  /** @param {boolean} value */
  setDmPersonalPreference(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 18, value);
  }


  /**
   * repeated string ftue_screens = 19;
   * @return {!Array<string>}
   */
  getFtueScreensList(): string[] {
    return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 19));
  };


  /** @param {!Array<string>} value */
  setFtueScreensList(value: string[]) {
    jspb.Message.setField(this, 19, value || []);
  }


  /**
   * @param {string} value
   * @param {number=} opt_index
   */
  addFtueScreens(value: string, opt_index?: number): void {
    jspb.Message.addToRepeatedField(this, 19, value, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearFtueScreensList() {
    this.setFtueScreensList([]);
  }


  /**
   * optional bool show_rate_app = 20;
   * @return {boolean}
   */
  getShowRateApp(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 20, false));
  };


  /** @param {boolean} value */
  setShowRateApp(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 20, value);
  }


  /**
   * optional bool disabled_dm = 21;
   * @return {boolean}
   */
  getDisabledDm(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 21, false));
  };


  /** @param {boolean} value */
  setDisabledDm(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 21, value);
  }


  /**
   * optional minga.common.Date account_created = 22;
   * @return {?Date}
   */
  getAccountCreated(): common_date_pb.Date {
    return /** @type{?Date} */ (
      jspb.Message.getWrapperField(this, common_date_pb.Date, 22));
  }


  /** @param {?Date|undefined} value */
  setAccountCreated(value?: common_date_pb.Date) {
    jspb.Message.setWrapperField(this, 22, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearAccountCreated() {
    this.setAccountCreated(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasAccountCreated(): boolean {
    return jspb.Message.getField(this, 22) != null;
  }


  /**
   * optional int32 logins = 23;
   * @return {number}
   */
  getLogins(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 23, 0));
  };


  /** @param {number} value */
  setLogins(value: number) {
    jspb.Message.setProto3IntField(this, 23, value);
  }


  /**
   * repeated MingaInfoMinimal user_mingas = 24;
   * @return {!Array<!MingaInfoMinimal>}
   */
  getUserMingasList(): gateway_minga_pb.MingaInfoMinimal[] {
    return /** @type{!Array<!MingaInfoMinimal>} */ (
      jspb.Message.getRepeatedWrapperField(this, gateway_minga_pb.MingaInfoMinimal, 24));
  }


  /** @param {!Array<!MingaInfoMinimal>} value */
  setUserMingasList(value?: gateway_minga_pb.MingaInfoMinimal[]) {
    jspb.Message.setRepeatedWrapperField(this, 24, value);
  }


  /**
   * @param {!MingaInfoMinimal=} opt_value
   * @param {number=} opt_index
   * @return {!MingaInfoMinimal}
   */
  addUserMingas(opt_value?: gateway_minga_pb.MingaInfoMinimal, opt_index?: number): gateway_minga_pb.MingaInfoMinimal {
    return jspb.Message.addToRepeatedWrapperField(this, 24, opt_value, gateway_minga_pb.MingaInfoMinimal, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearUserMingasList() {
    this.setUserMingasList([]);
  }


  /**
   * optional string email = 25;
   * @return {string}
   */
  getEmail(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 25, ""));
  };


  /** @param {string} value */
  setEmail(value: string) {
    jspb.Message.setProto3StringField(this, 25, value);
  }


}
export namespace LoginValidationRequest {
  export interface AsObject {
    identityToken: string;
    deviceId: string;
    firebaseRegistrationToken: string;
    gaClientId: string;
    customOauthToken: string;
    customOauthProvider: string;
  }
}
export class LoginValidationRequest extends jspb.Message {
  static readonly displayName = "LoginValidationRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): LoginValidationRequest.AsObject {
    return LoginValidationRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!LoginValidationRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: LoginValidationRequest) {
    var f: any;
    var obj: any = {
      identityToken: jspb.Message.getFieldWithDefault(msg, 1, ""),
      deviceId: jspb.Message.getFieldWithDefault(msg, 2, ""),
      firebaseRegistrationToken: jspb.Message.getFieldWithDefault(msg, 3, ""),
      gaClientId: jspb.Message.getFieldWithDefault(msg, 4, ""),
      customOauthToken: jspb.Message.getFieldWithDefault(msg, 5, ""),
      customOauthProvider: jspb.Message.getFieldWithDefault(msg, 6, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!LoginValidationRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new LoginValidationRequest;
    return LoginValidationRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!LoginValidationRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!LoginValidationRequest}
   */
  static deserializeBinaryFromReader(msg: LoginValidationRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setIdentityToken(value1);
        break;
      case 2:
        var value2 = /** @type {string} */ (reader.readString());
        msg.setDeviceId(value2);
        break;
      case 3:
        var value3 = /** @type {string} */ (reader.readString());
        msg.setFirebaseRegistrationToken(value3);
        break;
      case 4:
        var value4 = /** @type {string} */ (reader.readString());
        msg.setGaClientId(value4);
        break;
      case 5:
        var value5 = /** @type {string} */ (reader.readString());
        msg.setCustomOauthToken(value5);
        break;
      case 6:
        var value6 = /** @type {string} */ (reader.readString());
        msg.setCustomOauthProvider(value6);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!LoginValidationRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: LoginValidationRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getIdentityToken();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
    f = message.getDeviceId();
    if (f.length > 0) {
      writer.writeString(
        2,
        f
      );
    }
    f = message.getFirebaseRegistrationToken();
    if (f.length > 0) {
      writer.writeString(
        3,
        f
      );
    }
    f = message.getGaClientId();
    if (f.length > 0) {
      writer.writeString(
        4,
        f
      );
    }
    f = message.getCustomOauthToken();
    if (f.length > 0) {
      writer.writeString(
        5,
        f
      );
    }
    f = message.getCustomOauthProvider();
    if (f.length > 0) {
      writer.writeString(
        6,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    LoginValidationRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string identity_token = 1;
   * @return {string}
   */
  getIdentityToken(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setIdentityToken(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


  /**
   * optional string device_id = 2;
   * @return {string}
   */
  getDeviceId(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
  };


  /** @param {string} value */
  setDeviceId(value: string) {
    jspb.Message.setProto3StringField(this, 2, value);
  }


  /**
   * optional string firebase_registration_token = 3;
   * @return {string}
   */
  getFirebaseRegistrationToken(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
  };


  /** @param {string} value */
  setFirebaseRegistrationToken(value: string) {
    jspb.Message.setProto3StringField(this, 3, value);
  }


  /**
   * optional string ga_client_id = 4;
   * @return {string}
   */
  getGaClientId(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
  };


  /** @param {string} value */
  setGaClientId(value: string) {
    jspb.Message.setProto3StringField(this, 4, value);
  }


  /**
   * optional string custom_oauth_token = 5;
   * @return {string}
   */
  getCustomOauthToken(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
  };


  /** @param {string} value */
  setCustomOauthToken(value: string) {
    jspb.Message.setProto3StringField(this, 5, value);
  }


  /**
   * optional string custom_oauth_provider = 6;
   * @return {string}
   */
  getCustomOauthProvider(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
  };


  /** @param {string} value */
  setCustomOauthProvider(value: string) {
    jspb.Message.setProto3StringField(this, 6, value);
  }


}
export namespace LoginValidationRequest {
export enum CustomProvider {
  CLEVER = 0,
}
} // namespace LoginValidationRequest
export namespace LoginValidationResponse {
  export interface AsObject {
    status?: common_legacy_pb.StatusCode;
    uid: string;
    customAuthToken: string;
    account?: gateway_people_pb.Account.AsObject;
    personContentMeta?: PersonContentMeta.AsObject;
    mingaName: string;
    mingaLogo: string;
    personViewMinimal?: gateway_person_view_pb.PersonViewMinimal.AsObject;
    birthdate?: common_date_pb.Date.AsObject;
    publicBirthdate: boolean;
    apiUrl: string;
    appConfig?: gateway_connect_pb.AppConfigResponse.AsObject;
    accountRoleType: string;
    mingaInfo?: gateway_minga_pb.MingaInfoMinimal.AsObject;
    personName: string;
    parentGroupHashList: string[];
    dmPersonalPreference: boolean;
    ftueScreensList: string[];
    showRateApp: boolean;
    newAuthProvider: boolean;
    disabledDm: boolean;
    accountCreated?: common_date_pb.Date.AsObject;
    logins: number;
    userMingasList: gateway_minga_pb.MingaInfoMinimal.AsObject[];
    email: string;
  }
}
export class LoginValidationResponse extends jspb.Message {
  static readonly displayName = "LoginValidationResponse";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [18,20,26];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, LoginValidationResponse.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): LoginValidationResponse.AsObject {
    return LoginValidationResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!LoginValidationResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: LoginValidationResponse) {
    var f: any;
    var obj: any = {
      status: jspb.Message.getFieldWithDefault(msg, 1, 0),
      uid: jspb.Message.getFieldWithDefault(msg, 2, ""),
      customAuthToken: jspb.Message.getFieldWithDefault(msg, 3, ""),
      account: (f = msg.getAccount()) && gateway_people_pb.Account.toObject(includeInstance, f),
      personContentMeta: (f = msg.getPersonContentMeta()) && PersonContentMeta.toObject(includeInstance, f),
      mingaName: jspb.Message.getFieldWithDefault(msg, 6, ""),
      mingaLogo: jspb.Message.getFieldWithDefault(msg, 7, ""),
      personViewMinimal: (f = msg.getPersonViewMinimal()) && gateway_person_view_pb.PersonViewMinimal.toObject(includeInstance, f),
      birthdate: (f = msg.getBirthdate()) && common_date_pb.Date.toObject(includeInstance, f),
      publicBirthdate: jspb.Message.getBooleanFieldWithDefault(msg, 12, false),
      apiUrl: jspb.Message.getFieldWithDefault(msg, 13, ""),
      appConfig: (f = msg.getAppConfig()) && gateway_connect_pb.AppConfigResponse.toObject(includeInstance, f),
      accountRoleType: jspb.Message.getFieldWithDefault(msg, 15, ""),
      mingaInfo: (f = msg.getMingaInfo()) && gateway_minga_pb.MingaInfoMinimal.toObject(includeInstance, f),
      personName: jspb.Message.getFieldWithDefault(msg, 17, ""),
      parentGroupHashList: (f = jspb.Message.getRepeatedField(msg, 18)) == null ? undefined : f,
      dmPersonalPreference: jspb.Message.getBooleanFieldWithDefault(msg, 19, false),
      ftueScreensList: (f = jspb.Message.getRepeatedField(msg, 20)) == null ? undefined : f,
      showRateApp: jspb.Message.getBooleanFieldWithDefault(msg, 21, false),
      newAuthProvider: jspb.Message.getBooleanFieldWithDefault(msg, 22, false),
      disabledDm: jspb.Message.getBooleanFieldWithDefault(msg, 23, false),
      accountCreated: (f = msg.getAccountCreated()) && common_date_pb.Date.toObject(includeInstance, f),
      logins: jspb.Message.getFieldWithDefault(msg, 25, 0),
      userMingasList: jspb.Message.toObjectList(msg.getUserMingasList(),
      gateway_minga_pb.MingaInfoMinimal.toObject, includeInstance),
      email: jspb.Message.getFieldWithDefault(msg, 27, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!LoginValidationResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new LoginValidationResponse;
    return LoginValidationResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!LoginValidationResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!LoginValidationResponse}
   */
  static deserializeBinaryFromReader(msg: LoginValidationResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {!StatusCode} */ (reader.readEnum());
        msg.setStatus(value1);
        break;
      case 2:
        var value2 = /** @type {string} */ (reader.readString());
        msg.setUid(value2);
        break;
      case 3:
        var value3 = /** @type {string} */ (reader.readString());
        msg.setCustomAuthToken(value3);
        break;
      case 4:
        var value4 = new gateway_people_pb.Account;
        reader.readMessage(value4,gateway_people_pb.Account.deserializeBinaryFromReader);
        msg.setAccount(value4);
        break;
      case 5:
        var value5 = new PersonContentMeta;
        reader.readMessage(value5,PersonContentMeta.deserializeBinaryFromReader);
        msg.setPersonContentMeta(value5);
        break;
      case 6:
        var value6 = /** @type {string} */ (reader.readString());
        msg.setMingaName(value6);
        break;
      case 7:
        var value7 = /** @type {string} */ (reader.readString());
        msg.setMingaLogo(value7);
        break;
      case 8:
        var value8 = new gateway_person_view_pb.PersonViewMinimal;
        reader.readMessage(value8,gateway_person_view_pb.PersonViewMinimal.deserializeBinaryFromReader);
        msg.setPersonViewMinimal(value8);
        break;
      case 11:
        var value11 = new common_date_pb.Date;
        reader.readMessage(value11,common_date_pb.Date.deserializeBinaryFromReader);
        msg.setBirthdate(value11);
        break;
      case 12:
        var value12 = /** @type {boolean} */ (reader.readBool());
        msg.setPublicBirthdate(value12);
        break;
      case 13:
        var value13 = /** @type {string} */ (reader.readString());
        msg.setApiUrl(value13);
        break;
      case 14:
        var value14 = new gateway_connect_pb.AppConfigResponse;
        reader.readMessage(value14,gateway_connect_pb.AppConfigResponse.deserializeBinaryFromReader);
        msg.setAppConfig(value14);
        break;
      case 15:
        var value15 = /** @type {string} */ (reader.readString());
        msg.setAccountRoleType(value15);
        break;
      case 16:
        var value16 = new gateway_minga_pb.MingaInfoMinimal;
        reader.readMessage(value16,gateway_minga_pb.MingaInfoMinimal.deserializeBinaryFromReader);
        msg.setMingaInfo(value16);
        break;
      case 17:
        var value17 = /** @type {string} */ (reader.readString());
        msg.setPersonName(value17);
        break;
      case 18:
        var value18 = /** @type {string} */ (reader.readString());
        msg.addParentGroupHash(value18);
        break;
      case 19:
        var value19 = /** @type {boolean} */ (reader.readBool());
        msg.setDmPersonalPreference(value19);
        break;
      case 20:
        var value20 = /** @type {string} */ (reader.readString());
        msg.addFtueScreens(value20);
        break;
      case 21:
        var value21 = /** @type {boolean} */ (reader.readBool());
        msg.setShowRateApp(value21);
        break;
      case 22:
        var value22 = /** @type {boolean} */ (reader.readBool());
        msg.setNewAuthProvider(value22);
        break;
      case 23:
        var value23 = /** @type {boolean} */ (reader.readBool());
        msg.setDisabledDm(value23);
        break;
      case 24:
        var value24 = new common_date_pb.Date;
        reader.readMessage(value24,common_date_pb.Date.deserializeBinaryFromReader);
        msg.setAccountCreated(value24);
        break;
      case 25:
        var value25 = /** @type {number} */ (reader.readInt32());
        msg.setLogins(value25);
        break;
      case 26:
        var value26 = new gateway_minga_pb.MingaInfoMinimal;
        reader.readMessage(value26,gateway_minga_pb.MingaInfoMinimal.deserializeBinaryFromReader);
        msg.addUserMingas(value26);
        break;
      case 27:
        var value27 = /** @type {string} */ (reader.readString());
        msg.setEmail(value27);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!LoginValidationResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: LoginValidationResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getStatus();
    if (f !== 0.0) {
      writer.writeEnum(
        1,
        f
      );
    }
    f = message.getUid();
    if (f.length > 0) {
      writer.writeString(
        2,
        f
      );
    }
    f = message.getCustomAuthToken();
    if (f.length > 0) {
      writer.writeString(
        3,
        f
      );
    }
    f = message.getAccount();
    if (f != null) {
      writer.writeMessage(
        4,
        f,
        gateway_people_pb.Account.serializeBinaryToWriter
      );
    }
    f = message.getPersonContentMeta();
    if (f != null) {
      writer.writeMessage(
        5,
        f,
        PersonContentMeta.serializeBinaryToWriter
      );
    }
    f = message.getMingaName();
    if (f.length > 0) {
      writer.writeString(
        6,
        f
      );
    }
    f = message.getMingaLogo();
    if (f.length > 0) {
      writer.writeString(
        7,
        f
      );
    }
    f = message.getPersonViewMinimal();
    if (f != null) {
      writer.writeMessage(
        8,
        f,
        gateway_person_view_pb.PersonViewMinimal.serializeBinaryToWriter
      );
    }
    f = message.getBirthdate();
    if (f != null) {
      writer.writeMessage(
        11,
        f,
        common_date_pb.Date.serializeBinaryToWriter
      );
    }
    f = message.getPublicBirthdate();
    if (f) {
      writer.writeBool(
        12,
        f
      );
    }
    f = message.getApiUrl();
    if (f.length > 0) {
      writer.writeString(
        13,
        f
      );
    }
    f = message.getAppConfig();
    if (f != null) {
      writer.writeMessage(
        14,
        f,
        gateway_connect_pb.AppConfigResponse.serializeBinaryToWriter
      );
    }
    f = message.getAccountRoleType();
    if (f.length > 0) {
      writer.writeString(
        15,
        f
      );
    }
    f = message.getMingaInfo();
    if (f != null) {
      writer.writeMessage(
        16,
        f,
        gateway_minga_pb.MingaInfoMinimal.serializeBinaryToWriter
      );
    }
    f = message.getPersonName();
    if (f.length > 0) {
      writer.writeString(
        17,
        f
      );
    }
    f = message.getParentGroupHashList();
    if (f.length > 0) {
      writer.writeRepeatedString(
        18,
        f
      );
    }
    f = message.getDmPersonalPreference();
    if (f) {
      writer.writeBool(
        19,
        f
      );
    }
    f = message.getFtueScreensList();
    if (f.length > 0) {
      writer.writeRepeatedString(
        20,
        f
      );
    }
    f = message.getShowRateApp();
    if (f) {
      writer.writeBool(
        21,
        f
      );
    }
    f = message.getNewAuthProvider();
    if (f) {
      writer.writeBool(
        22,
        f
      );
    }
    f = message.getDisabledDm();
    if (f) {
      writer.writeBool(
        23,
        f
      );
    }
    f = message.getAccountCreated();
    if (f != null) {
      writer.writeMessage(
        24,
        f,
        common_date_pb.Date.serializeBinaryToWriter
      );
    }
    f = message.getLogins();
    if (f !== 0) {
      writer.writeInt32(
        25,
        f
      );
    }
    f = message.getUserMingasList();
    if (f.length > 0) {
      writer.writeRepeatedMessage(
        26,
        f,
        gateway_minga_pb.MingaInfoMinimal.serializeBinaryToWriter
      );
    }
    f = message.getEmail();
    if (f.length > 0) {
      writer.writeString(
        27,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    LoginValidationResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional StatusCode status = 1;
   * @return {!StatusCode}
   */
  getStatus(): common_legacy_pb.StatusCode {
    return /** @type {!StatusCode} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
  };


  /** @param {!StatusCode} value */
  setStatus(value: common_legacy_pb.StatusCode) {
    jspb.Message.setProto3EnumField(this, 1, value);
  }


  /**
   * optional string uid = 2;
   * @return {string}
   */
  getUid(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
  };


  /** @param {string} value */
  setUid(value: string) {
    jspb.Message.setProto3StringField(this, 2, value);
  }


  /**
   * optional string custom_auth_token = 3;
   * @return {string}
   */
  getCustomAuthToken(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
  };


  /** @param {string} value */
  setCustomAuthToken(value: string) {
    jspb.Message.setProto3StringField(this, 3, value);
  }


  /**
   * optional Account account = 4;
   * @return {?Account}
   */
  getAccount(): gateway_people_pb.Account {
    return /** @type{?Account} */ (
      jspb.Message.getWrapperField(this, gateway_people_pb.Account, 4));
  }


  /** @param {?Account|undefined} value */
  setAccount(value?: gateway_people_pb.Account) {
    jspb.Message.setWrapperField(this, 4, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearAccount() {
    this.setAccount(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasAccount(): boolean {
    return jspb.Message.getField(this, 4) != null;
  }


  /**
   * optional PersonContentMeta person_content_meta = 5;
   * @return {?PersonContentMeta}
   */
  getPersonContentMeta(): PersonContentMeta {
    return /** @type{?PersonContentMeta} */ (
      jspb.Message.getWrapperField(this, PersonContentMeta, 5));
  }


  /** @param {?PersonContentMeta|undefined} value */
  setPersonContentMeta(value?: PersonContentMeta) {
    jspb.Message.setWrapperField(this, 5, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearPersonContentMeta() {
    this.setPersonContentMeta(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasPersonContentMeta(): boolean {
    return jspb.Message.getField(this, 5) != null;
  }


  /**
   * optional string minga_name = 6;
   * @return {string}
   */
  getMingaName(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
  };


  /** @param {string} value */
  setMingaName(value: string) {
    jspb.Message.setProto3StringField(this, 6, value);
  }


  /**
   * optional string minga_logo = 7;
   * @return {string}
   */
  getMingaLogo(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
  };


  /** @param {string} value */
  setMingaLogo(value: string) {
    jspb.Message.setProto3StringField(this, 7, value);
  }


  /**
   * optional PersonViewMinimal person_view_minimal = 8;
   * @return {?PersonViewMinimal}
   */
  getPersonViewMinimal(): gateway_person_view_pb.PersonViewMinimal {
    return /** @type{?PersonViewMinimal} */ (
      jspb.Message.getWrapperField(this, gateway_person_view_pb.PersonViewMinimal, 8));
  }


  /** @param {?PersonViewMinimal|undefined} value */
  setPersonViewMinimal(value?: gateway_person_view_pb.PersonViewMinimal) {
    jspb.Message.setWrapperField(this, 8, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearPersonViewMinimal() {
    this.setPersonViewMinimal(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasPersonViewMinimal(): boolean {
    return jspb.Message.getField(this, 8) != null;
  }


  /**
   * optional minga.common.Date birthdate = 11;
   * @return {?Date}
   */
  getBirthdate(): common_date_pb.Date {
    return /** @type{?Date} */ (
      jspb.Message.getWrapperField(this, common_date_pb.Date, 11));
  }


  /** @param {?Date|undefined} value */
  setBirthdate(value?: common_date_pb.Date) {
    jspb.Message.setWrapperField(this, 11, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearBirthdate() {
    this.setBirthdate(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasBirthdate(): boolean {
    return jspb.Message.getField(this, 11) != null;
  }


  /**
   * optional bool public_birthdate = 12;
   * @return {boolean}
   */
  getPublicBirthdate(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 12, false));
  };


  /** @param {boolean} value */
  setPublicBirthdate(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 12, value);
  }


  /**
   * optional string api_url = 13;
   * @return {string}
   */
  getApiUrl(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
  };


  /** @param {string} value */
  setApiUrl(value: string) {
    jspb.Message.setProto3StringField(this, 13, value);
  }


  /**
   * optional AppConfigResponse app_config = 14;
   * @return {?AppConfigResponse}
   */
  getAppConfig(): gateway_connect_pb.AppConfigResponse {
    return /** @type{?AppConfigResponse} */ (
      jspb.Message.getWrapperField(this, gateway_connect_pb.AppConfigResponse, 14));
  }


  /** @param {?AppConfigResponse|undefined} value */
  setAppConfig(value?: gateway_connect_pb.AppConfigResponse) {
    jspb.Message.setWrapperField(this, 14, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearAppConfig() {
    this.setAppConfig(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasAppConfig(): boolean {
    return jspb.Message.getField(this, 14) != null;
  }


  /**
   * optional string account_role_type = 15;
   * @return {string}
   */
  getAccountRoleType(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
  };


  /** @param {string} value */
  setAccountRoleType(value: string) {
    jspb.Message.setProto3StringField(this, 15, value);
  }


  /**
   * optional MingaInfoMinimal minga_info = 16;
   * @return {?MingaInfoMinimal}
   */
  getMingaInfo(): gateway_minga_pb.MingaInfoMinimal {
    return /** @type{?MingaInfoMinimal} */ (
      jspb.Message.getWrapperField(this, gateway_minga_pb.MingaInfoMinimal, 16));
  }


  /** @param {?MingaInfoMinimal|undefined} value */
  setMingaInfo(value?: gateway_minga_pb.MingaInfoMinimal) {
    jspb.Message.setWrapperField(this, 16, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearMingaInfo() {
    this.setMingaInfo(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasMingaInfo(): boolean {
    return jspb.Message.getField(this, 16) != null;
  }


  /**
   * optional string person_name = 17;
   * @return {string}
   */
  getPersonName(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
  };


  /** @param {string} value */
  setPersonName(value: string) {
    jspb.Message.setProto3StringField(this, 17, value);
  }


  /**
   * repeated string parent_group_hash = 18;
   * @return {!Array<string>}
   */
  getParentGroupHashList(): string[] {
    return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 18));
  };


  /** @param {!Array<string>} value */
  setParentGroupHashList(value: string[]) {
    jspb.Message.setField(this, 18, value || []);
  }


  /**
   * @param {string} value
   * @param {number=} opt_index
   */
  addParentGroupHash(value: string, opt_index?: number): void {
    jspb.Message.addToRepeatedField(this, 18, value, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearParentGroupHashList() {
    this.setParentGroupHashList([]);
  }


  /**
   * optional bool dm_personal_preference = 19;
   * @return {boolean}
   */
  getDmPersonalPreference(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 19, false));
  };


  /** @param {boolean} value */
  setDmPersonalPreference(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 19, value);
  }


  /**
   * repeated string ftue_screens = 20;
   * @return {!Array<string>}
   */
  getFtueScreensList(): string[] {
    return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 20));
  };


  /** @param {!Array<string>} value */
  setFtueScreensList(value: string[]) {
    jspb.Message.setField(this, 20, value || []);
  }


  /**
   * @param {string} value
   * @param {number=} opt_index
   */
  addFtueScreens(value: string, opt_index?: number): void {
    jspb.Message.addToRepeatedField(this, 20, value, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearFtueScreensList() {
    this.setFtueScreensList([]);
  }


  /**
   * optional bool show_rate_app = 21;
   * @return {boolean}
   */
  getShowRateApp(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 21, false));
  };


  /** @param {boolean} value */
  setShowRateApp(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 21, value);
  }


  /**
   * optional bool new_auth_provider = 22;
   * @return {boolean}
   */
  getNewAuthProvider(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 22, false));
  };


  /** @param {boolean} value */
  setNewAuthProvider(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 22, value);
  }


  /**
   * optional bool disabled_dm = 23;
   * @return {boolean}
   */
  getDisabledDm(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 23, false));
  };


  /** @param {boolean} value */
  setDisabledDm(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 23, value);
  }


  /**
   * optional minga.common.Date account_created = 24;
   * @return {?Date}
   */
  getAccountCreated(): common_date_pb.Date {
    return /** @type{?Date} */ (
      jspb.Message.getWrapperField(this, common_date_pb.Date, 24));
  }


  /** @param {?Date|undefined} value */
  setAccountCreated(value?: common_date_pb.Date) {
    jspb.Message.setWrapperField(this, 24, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearAccountCreated() {
    this.setAccountCreated(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasAccountCreated(): boolean {
    return jspb.Message.getField(this, 24) != null;
  }


  /**
   * optional int32 logins = 25;
   * @return {number}
   */
  getLogins(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 25, 0));
  };


  /** @param {number} value */
  setLogins(value: number) {
    jspb.Message.setProto3IntField(this, 25, value);
  }


  /**
   * repeated MingaInfoMinimal user_mingas = 26;
   * @return {!Array<!MingaInfoMinimal>}
   */
  getUserMingasList(): gateway_minga_pb.MingaInfoMinimal[] {
    return /** @type{!Array<!MingaInfoMinimal>} */ (
      jspb.Message.getRepeatedWrapperField(this, gateway_minga_pb.MingaInfoMinimal, 26));
  }


  /** @param {!Array<!MingaInfoMinimal>} value */
  setUserMingasList(value?: gateway_minga_pb.MingaInfoMinimal[]) {
    jspb.Message.setRepeatedWrapperField(this, 26, value);
  }


  /**
   * @param {!MingaInfoMinimal=} opt_value
   * @param {number=} opt_index
   * @return {!MingaInfoMinimal}
   */
  addUserMingas(opt_value?: gateway_minga_pb.MingaInfoMinimal, opt_index?: number): gateway_minga_pb.MingaInfoMinimal {
    return jspb.Message.addToRepeatedWrapperField(this, 26, opt_value, gateway_minga_pb.MingaInfoMinimal, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearUserMingasList() {
    this.setUserMingasList([]);
  }


  /**
   * optional string email = 27;
   * @return {string}
   */
  getEmail(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 27, ""));
  };


  /** @param {string} value */
  setEmail(value: string) {
    jspb.Message.setProto3StringField(this, 27, value);
  }


}
export namespace EmailRequest {
  export interface AsObject {
    email: string;
  }
}
export class EmailRequest extends jspb.Message {
  static readonly displayName = "EmailRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): EmailRequest.AsObject {
    return EmailRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!EmailRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: EmailRequest) {
    var f: any;
    var obj: any = {
      email: jspb.Message.getFieldWithDefault(msg, 1, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!EmailRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new EmailRequest;
    return EmailRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!EmailRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!EmailRequest}
   */
  static deserializeBinaryFromReader(msg: EmailRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setEmail(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!EmailRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: EmailRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getEmail();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    EmailRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string email = 1;
   * @return {string}
   */
  getEmail(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setEmail(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


}
export namespace EmailResponse {
  export interface AsObject {
    status?: common_legacy_pb.StatusCode;
    needsPin: boolean;
    needsPassword: boolean;
    apiUrl: string;
    appConfig?: gateway_connect_pb.AppConfigResponse.AsObject;
  }
}
export class EmailResponse extends jspb.Message {
  static readonly displayName = "EmailResponse";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): EmailResponse.AsObject {
    return EmailResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!EmailResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: EmailResponse) {
    var f: any;
    var obj: any = {
      status: jspb.Message.getFieldWithDefault(msg, 1, 0),
      needsPin: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
      needsPassword: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
      apiUrl: jspb.Message.getFieldWithDefault(msg, 4, ""),
      appConfig: (f = msg.getAppConfig()) && gateway_connect_pb.AppConfigResponse.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!EmailResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new EmailResponse;
    return EmailResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!EmailResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!EmailResponse}
   */
  static deserializeBinaryFromReader(msg: EmailResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {!StatusCode} */ (reader.readEnum());
        msg.setStatus(value1);
        break;
      case 2:
        var value2 = /** @type {boolean} */ (reader.readBool());
        msg.setNeedsPin(value2);
        break;
      case 3:
        var value3 = /** @type {boolean} */ (reader.readBool());
        msg.setNeedsPassword(value3);
        break;
      case 4:
        var value4 = /** @type {string} */ (reader.readString());
        msg.setApiUrl(value4);
        break;
      case 5:
        var value5 = new gateway_connect_pb.AppConfigResponse;
        reader.readMessage(value5,gateway_connect_pb.AppConfigResponse.deserializeBinaryFromReader);
        msg.setAppConfig(value5);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!EmailResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: EmailResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getStatus();
    if (f !== 0.0) {
      writer.writeEnum(
        1,
        f
      );
    }
    f = message.getNeedsPin();
    if (f) {
      writer.writeBool(
        2,
        f
      );
    }
    f = message.getNeedsPassword();
    if (f) {
      writer.writeBool(
        3,
        f
      );
    }
    f = message.getApiUrl();
    if (f.length > 0) {
      writer.writeString(
        4,
        f
      );
    }
    f = message.getAppConfig();
    if (f != null) {
      writer.writeMessage(
        5,
        f,
        gateway_connect_pb.AppConfigResponse.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    EmailResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional StatusCode status = 1;
   * @return {!StatusCode}
   */
  getStatus(): common_legacy_pb.StatusCode {
    return /** @type {!StatusCode} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
  };


  /** @param {!StatusCode} value */
  setStatus(value: common_legacy_pb.StatusCode) {
    jspb.Message.setProto3EnumField(this, 1, value);
  }


  /**
   * optional bool needs_pin = 2;
   * @return {boolean}
   */
  getNeedsPin(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
  };


  /** @param {boolean} value */
  setNeedsPin(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 2, value);
  }


  /**
   * optional bool needs_password = 3;
   * @return {boolean}
   */
  getNeedsPassword(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
  };


  /** @param {boolean} value */
  setNeedsPassword(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 3, value);
  }


  /**
   * optional string api_url = 4;
   * @return {string}
   */
  getApiUrl(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
  };


  /** @param {string} value */
  setApiUrl(value: string) {
    jspb.Message.setProto3StringField(this, 4, value);
  }


  /**
   * optional AppConfigResponse app_config = 5;
   * @return {?AppConfigResponse}
   */
  getAppConfig(): gateway_connect_pb.AppConfigResponse {
    return /** @type{?AppConfigResponse} */ (
      jspb.Message.getWrapperField(this, gateway_connect_pb.AppConfigResponse, 5));
  }


  /** @param {?AppConfigResponse|undefined} value */
  setAppConfig(value?: gateway_connect_pb.AppConfigResponse) {
    jspb.Message.setWrapperField(this, 5, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearAppConfig() {
    this.setAppConfig(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasAppConfig(): boolean {
    return jspb.Message.getField(this, 5) != null;
  }


}
export namespace PinRequest {
  export interface AsObject {
    pin: string;
    email: string;
    resetToken: string;
  }
}
export class PinRequest extends jspb.Message {
  static readonly displayName = "PinRequest";
  /**
   * Oneof group definitions for this message. Each group defines the field
   * numbers belonging to that group. When of these fields' value is set, all
   * other fields in the group are cleared. During deserialization, if multiple
   * fields are encountered for a group, only the last value seen will be kept.
   * @private {!Array<!Array<number>>}
   * @const
   */
  static readonly oneofGroups_ = [[2,3]];


  /**
   * @return {PinRequest.PinAuthCase}
   */
  getPinAuthCase() {
    return /** @type {PinRequest.PinAuthCase} */(jspb.Message.computeOneofCase(this, PinRequest.oneofGroups_[0]));
  }

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, PinRequest.oneofGroups_);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): PinRequest.AsObject {
    return PinRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!PinRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: PinRequest) {
    var f: any;
    var obj: any = {
      pin: jspb.Message.getFieldWithDefault(msg, 1, ""),
      email: jspb.Message.getFieldWithDefault(msg, 2, ""),
      resetToken: jspb.Message.getFieldWithDefault(msg, 3, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!PinRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new PinRequest;
    return PinRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!PinRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!PinRequest}
   */
  static deserializeBinaryFromReader(msg: PinRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setPin(value1);
        break;
      case 2:
        var value2 = /** @type {string} */ (reader.readString());
        msg.setEmail(value2);
        break;
      case 3:
        var value3 = /** @type {string} */ (reader.readString());
        msg.setResetToken(value3);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!PinRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: PinRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getPin();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
    f = /** @type {string} */ (jspb.Message.getField(message, 2));
    if (f != null) {
      writer.writeString(
        2,
        f
      );
    }
    f = /** @type {string} */ (jspb.Message.getField(message, 3));
    if (f != null) {
      writer.writeString(
        3,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    PinRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string pin = 1;
   * @return {string}
   */
  getPin(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setPin(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


  /**
   * optional string email = 2;
   * @return {string}
   */
  getEmail(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
  };


  /** @param {string} value */
  setEmail(value: string) {
    jspb.Message.setOneofField(this, 2, PinRequest.oneofGroups_[0], value);
  }


  /**
   * Clears the field making it undefined.
   */
  clearEmail() {
    jspb.Message.setOneofField(this, 2, PinRequest.oneofGroups_[0], undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasEmail(): boolean {
    return jspb.Message.getField(this, 2) != null;
  }


  /**
   * optional string reset_token = 3;
   * @return {string}
   */
  getResetToken(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
  };


  /** @param {string} value */
  setResetToken(value: string) {
    jspb.Message.setOneofField(this, 3, PinRequest.oneofGroups_[0], value);
  }


  /**
   * Clears the field making it undefined.
   */
  clearResetToken() {
    jspb.Message.setOneofField(this, 3, PinRequest.oneofGroups_[0], undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasResetToken(): boolean {
    return jspb.Message.getField(this, 3) != null;
  }


}
export namespace PinRequest {
export enum PinAuthCase {
  PIN_AUTH_NOT_SET = 0,
  EMAIL = 2,
  RESET_TOKEN = 3,
}
} // namespace PinRequest
export namespace PinResponse {
  export interface AsObject {
    status?: common_legacy_pb.StatusCode;
    valid: boolean;
    personName: string;
    mingaName: string;
    email: string;
  }
}
export class PinResponse extends jspb.Message {
  static readonly displayName = "PinResponse";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): PinResponse.AsObject {
    return PinResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!PinResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: PinResponse) {
    var f: any;
    var obj: any = {
      status: jspb.Message.getFieldWithDefault(msg, 1, 0),
      valid: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
      personName: jspb.Message.getFieldWithDefault(msg, 3, ""),
      mingaName: jspb.Message.getFieldWithDefault(msg, 4, ""),
      email: jspb.Message.getFieldWithDefault(msg, 5, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!PinResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new PinResponse;
    return PinResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!PinResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!PinResponse}
   */
  static deserializeBinaryFromReader(msg: PinResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {!StatusCode} */ (reader.readEnum());
        msg.setStatus(value1);
        break;
      case 2:
        var value2 = /** @type {boolean} */ (reader.readBool());
        msg.setValid(value2);
        break;
      case 3:
        var value3 = /** @type {string} */ (reader.readString());
        msg.setPersonName(value3);
        break;
      case 4:
        var value4 = /** @type {string} */ (reader.readString());
        msg.setMingaName(value4);
        break;
      case 5:
        var value5 = /** @type {string} */ (reader.readString());
        msg.setEmail(value5);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!PinResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: PinResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getStatus();
    if (f !== 0.0) {
      writer.writeEnum(
        1,
        f
      );
    }
    f = message.getValid();
    if (f) {
      writer.writeBool(
        2,
        f
      );
    }
    f = message.getPersonName();
    if (f.length > 0) {
      writer.writeString(
        3,
        f
      );
    }
    f = message.getMingaName();
    if (f.length > 0) {
      writer.writeString(
        4,
        f
      );
    }
    f = message.getEmail();
    if (f.length > 0) {
      writer.writeString(
        5,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    PinResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional StatusCode status = 1;
   * @return {!StatusCode}
   */
  getStatus(): common_legacy_pb.StatusCode {
    return /** @type {!StatusCode} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
  };


  /** @param {!StatusCode} value */
  setStatus(value: common_legacy_pb.StatusCode) {
    jspb.Message.setProto3EnumField(this, 1, value);
  }


  /**
   * optional bool valid = 2;
   * @return {boolean}
   */
  getValid(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
  };


  /** @param {boolean} value */
  setValid(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 2, value);
  }


  /**
   * optional string person_name = 3;
   * @return {string}
   */
  getPersonName(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
  };


  /** @param {string} value */
  setPersonName(value: string) {
    jspb.Message.setProto3StringField(this, 3, value);
  }


  /**
   * optional string minga_name = 4;
   * @return {string}
   */
  getMingaName(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
  };


  /** @param {string} value */
  setMingaName(value: string) {
    jspb.Message.setProto3StringField(this, 4, value);
  }


  /**
   * optional string email = 5;
   * @return {string}
   */
  getEmail(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
  };


  /** @param {string} value */
  setEmail(value: string) {
    jspb.Message.setProto3StringField(this, 5, value);
  }


}
export namespace CreateAccountRequest {
  export interface AsObject {
    email: string;
    password: string;
    deviceId: string;
    firebaseRegistrationToken: string;
    gaClientId: string;
    firstName: string;
    lastName: string;
    phoneNumber: string;
    graduatingYear: number;
    roleType: string;
    pin: string;
    resetToken: string;
    mingaInviteCode: string;
    detachedSsoIdToken: string;
    grade: string;
  }
}
export class CreateAccountRequest extends jspb.Message {
  static readonly displayName = "CreateAccountRequest";
  /**
   * Oneof group definitions for this message. Each group defines the field
   * numbers belonging to that group. When of these fields' value is set, all
   * other fields in the group are cleared. During deserialization, if multiple
   * fields are encountered for a group, only the last value seen will be kept.
   * @private {!Array<!Array<number>>}
   * @const
   */
  static readonly oneofGroups_ = [[1,6,8]];


  /**
   * @return {CreateAccountRequest.AccountAuthCase}
   */
  getAccountAuthCase() {
    return /** @type {CreateAccountRequest.AccountAuthCase} */(jspb.Message.computeOneofCase(this, CreateAccountRequest.oneofGroups_[0]));
  }

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, CreateAccountRequest.oneofGroups_);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): CreateAccountRequest.AsObject {
    return CreateAccountRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!CreateAccountRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: CreateAccountRequest) {
    var f: any;
    var obj: any = {
      email: jspb.Message.getFieldWithDefault(msg, 2, ""),
      password: jspb.Message.getFieldWithDefault(msg, 3, ""),
      deviceId: jspb.Message.getFieldWithDefault(msg, 4, ""),
      firebaseRegistrationToken: jspb.Message.getFieldWithDefault(msg, 5, ""),
      gaClientId: jspb.Message.getFieldWithDefault(msg, 7, ""),
      firstName: jspb.Message.getFieldWithDefault(msg, 9, ""),
      lastName: jspb.Message.getFieldWithDefault(msg, 10, ""),
      phoneNumber: jspb.Message.getFieldWithDefault(msg, 11, ""),
      graduatingYear: jspb.Message.getFieldWithDefault(msg, 12, 0),
      roleType: jspb.Message.getFieldWithDefault(msg, 13, ""),
      pin: jspb.Message.getFieldWithDefault(msg, 1, ""),
      resetToken: jspb.Message.getFieldWithDefault(msg, 6, ""),
      mingaInviteCode: jspb.Message.getFieldWithDefault(msg, 8, ""),
      detachedSsoIdToken: jspb.Message.getFieldWithDefault(msg, 14, ""),
      grade: jspb.Message.getFieldWithDefault(msg, 15, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!CreateAccountRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new CreateAccountRequest;
    return CreateAccountRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!CreateAccountRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!CreateAccountRequest}
   */
  static deserializeBinaryFromReader(msg: CreateAccountRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 2:
        var value2 = /** @type {string} */ (reader.readString());
        msg.setEmail(value2);
        break;
      case 3:
        var value3 = /** @type {string} */ (reader.readString());
        msg.setPassword(value3);
        break;
      case 4:
        var value4 = /** @type {string} */ (reader.readString());
        msg.setDeviceId(value4);
        break;
      case 5:
        var value5 = /** @type {string} */ (reader.readString());
        msg.setFirebaseRegistrationToken(value5);
        break;
      case 7:
        var value7 = /** @type {string} */ (reader.readString());
        msg.setGaClientId(value7);
        break;
      case 9:
        var value9 = /** @type {string} */ (reader.readString());
        msg.setFirstName(value9);
        break;
      case 10:
        var value10 = /** @type {string} */ (reader.readString());
        msg.setLastName(value10);
        break;
      case 11:
        var value11 = /** @type {string} */ (reader.readString());
        msg.setPhoneNumber(value11);
        break;
      case 12:
        var value12 = /** @type {number} */ (reader.readInt32());
        msg.setGraduatingYear(value12);
        break;
      case 13:
        var value13 = /** @type {string} */ (reader.readString());
        msg.setRoleType(value13);
        break;
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setPin(value1);
        break;
      case 6:
        var value6 = /** @type {string} */ (reader.readString());
        msg.setResetToken(value6);
        break;
      case 8:
        var value8 = /** @type {string} */ (reader.readString());
        msg.setMingaInviteCode(value8);
        break;
      case 14:
        var value14 = /** @type {string} */ (reader.readString());
        msg.setDetachedSsoIdToken(value14);
        break;
      case 15:
        var value15 = /** @type {string} */ (reader.readString());
        msg.setGrade(value15);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!CreateAccountRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: CreateAccountRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getEmail();
    if (f.length > 0) {
      writer.writeString(
        2,
        f
      );
    }
    f = message.getPassword();
    if (f.length > 0) {
      writer.writeString(
        3,
        f
      );
    }
    f = message.getDeviceId();
    if (f.length > 0) {
      writer.writeString(
        4,
        f
      );
    }
    f = message.getFirebaseRegistrationToken();
    if (f.length > 0) {
      writer.writeString(
        5,
        f
      );
    }
    f = message.getGaClientId();
    if (f.length > 0) {
      writer.writeString(
        7,
        f
      );
    }
    f = message.getFirstName();
    if (f.length > 0) {
      writer.writeString(
        9,
        f
      );
    }
    f = message.getLastName();
    if (f.length > 0) {
      writer.writeString(
        10,
        f
      );
    }
    f = message.getPhoneNumber();
    if (f.length > 0) {
      writer.writeString(
        11,
        f
      );
    }
    f = message.getGraduatingYear();
    if (f !== 0) {
      writer.writeInt32(
        12,
        f
      );
    }
    f = message.getRoleType();
    if (f.length > 0) {
      writer.writeString(
        13,
        f
      );
    }
    f = /** @type {string} */ (jspb.Message.getField(message, 1));
    if (f != null) {
      writer.writeString(
        1,
        f
      );
    }
    f = /** @type {string} */ (jspb.Message.getField(message, 6));
    if (f != null) {
      writer.writeString(
        6,
        f
      );
    }
    f = /** @type {string} */ (jspb.Message.getField(message, 8));
    if (f != null) {
      writer.writeString(
        8,
        f
      );
    }
    f = message.getDetachedSsoIdToken();
    if (f.length > 0) {
      writer.writeString(
        14,
        f
      );
    }
    f = message.getGrade();
    if (f.length > 0) {
      writer.writeString(
        15,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    CreateAccountRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string email = 2;
   * @return {string}
   */
  getEmail(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
  };


  /** @param {string} value */
  setEmail(value: string) {
    jspb.Message.setProto3StringField(this, 2, value);
  }


  /**
   * optional string password = 3;
   * @return {string}
   */
  getPassword(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
  };


  /** @param {string} value */
  setPassword(value: string) {
    jspb.Message.setProto3StringField(this, 3, value);
  }


  /**
   * optional string device_id = 4;
   * @return {string}
   */
  getDeviceId(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
  };


  /** @param {string} value */
  setDeviceId(value: string) {
    jspb.Message.setProto3StringField(this, 4, value);
  }


  /**
   * optional string firebase_registration_token = 5;
   * @return {string}
   */
  getFirebaseRegistrationToken(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
  };


  /** @param {string} value */
  setFirebaseRegistrationToken(value: string) {
    jspb.Message.setProto3StringField(this, 5, value);
  }


  /**
   * optional string ga_client_id = 7;
   * @return {string}
   */
  getGaClientId(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
  };


  /** @param {string} value */
  setGaClientId(value: string) {
    jspb.Message.setProto3StringField(this, 7, value);
  }


  /**
   * optional string first_name = 9;
   * @return {string}
   */
  getFirstName(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
  };


  /** @param {string} value */
  setFirstName(value: string) {
    jspb.Message.setProto3StringField(this, 9, value);
  }


  /**
   * optional string last_name = 10;
   * @return {string}
   */
  getLastName(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
  };


  /** @param {string} value */
  setLastName(value: string) {
    jspb.Message.setProto3StringField(this, 10, value);
  }


  /**
   * optional string phone_number = 11;
   * @return {string}
   */
  getPhoneNumber(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
  };


  /** @param {string} value */
  setPhoneNumber(value: string) {
    jspb.Message.setProto3StringField(this, 11, value);
  }


  /**
   * optional int32 graduating_year = 12;
   * @return {number}
   */
  getGraduatingYear(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
  };


  /** @param {number} value */
  setGraduatingYear(value: number) {
    jspb.Message.setProto3IntField(this, 12, value);
  }


  /**
   * optional string role_type = 13;
   * @return {string}
   */
  getRoleType(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
  };


  /** @param {string} value */
  setRoleType(value: string) {
    jspb.Message.setProto3StringField(this, 13, value);
  }


  /**
   * optional string pin = 1;
   * @return {string}
   */
  getPin(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setPin(value: string) {
    jspb.Message.setOneofField(this, 1, CreateAccountRequest.oneofGroups_[0], value);
  }


  /**
   * Clears the field making it undefined.
   */
  clearPin() {
    jspb.Message.setOneofField(this, 1, CreateAccountRequest.oneofGroups_[0], undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasPin(): boolean {
    return jspb.Message.getField(this, 1) != null;
  }


  /**
   * optional string reset_token = 6;
   * @return {string}
   */
  getResetToken(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
  };


  /** @param {string} value */
  setResetToken(value: string) {
    jspb.Message.setOneofField(this, 6, CreateAccountRequest.oneofGroups_[0], value);
  }


  /**
   * Clears the field making it undefined.
   */
  clearResetToken() {
    jspb.Message.setOneofField(this, 6, CreateAccountRequest.oneofGroups_[0], undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasResetToken(): boolean {
    return jspb.Message.getField(this, 6) != null;
  }


  /**
   * optional string minga_invite_code = 8;
   * @return {string}
   */
  getMingaInviteCode(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
  };


  /** @param {string} value */
  setMingaInviteCode(value: string) {
    jspb.Message.setOneofField(this, 8, CreateAccountRequest.oneofGroups_[0], value);
  }


  /**
   * Clears the field making it undefined.
   */
  clearMingaInviteCode() {
    jspb.Message.setOneofField(this, 8, CreateAccountRequest.oneofGroups_[0], undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasMingaInviteCode(): boolean {
    return jspb.Message.getField(this, 8) != null;
  }


  /**
   * optional string detached_sso_id_token = 14;
   * @return {string}
   */
  getDetachedSsoIdToken(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
  };


  /** @param {string} value */
  setDetachedSsoIdToken(value: string) {
    jspb.Message.setProto3StringField(this, 14, value);
  }


  /**
   * optional string grade = 15;
   * @return {string}
   */
  getGrade(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
  };


  /** @param {string} value */
  setGrade(value: string) {
    jspb.Message.setProto3StringField(this, 15, value);
  }


}
export namespace CreateAccountRequest {
export enum AccountAuthCase {
  ACCOUNT_AUTH_NOT_SET = 0,
  PIN = 1,
  RESET_TOKEN = 6,
  MINGA_INVITE_CODE = 8,
}
} // namespace CreateAccountRequest
export namespace CreateAccountResponse {
  export interface AsObject {
    status?: common_legacy_pb.StatusCode;
    customAuthToken: string;
    account?: gateway_people_pb.Account.AsObject;
    uid: string;
    personContentMeta?: PersonContentMeta.AsObject;
    personName: string;
    mingaName: string;
    mingaLogo: string;
    personViewMinimal?: gateway_person_view_pb.PersonViewMinimal.AsObject;
    birthdate?: common_date_pb.Date.AsObject;
    publicBirthdate: boolean;
    apiUrl: string;
    appConfig?: gateway_connect_pb.AppConfigResponse.AsObject;
    accountRoleType: string;
    mingaInfo?: gateway_minga_pb.MingaInfoMinimal.AsObject;
    parentGroupHashList: string[];
    dmPersonalPreference: boolean;
    ftueScreensList: string[];
    showRateApp: boolean;
    disabledDm: boolean;
    accountCreated?: common_date_pb.Date.AsObject;
    logins: number;
    userMingasList: gateway_minga_pb.MingaInfoMinimal.AsObject[];
    email: string;
  }
}
export class CreateAccountResponse extends jspb.Message {
  static readonly displayName = "CreateAccountResponse";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [17,19,24];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, CreateAccountResponse.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): CreateAccountResponse.AsObject {
    return CreateAccountResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!CreateAccountResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: CreateAccountResponse) {
    var f: any;
    var obj: any = {
      status: jspb.Message.getFieldWithDefault(msg, 1, 0),
      customAuthToken: jspb.Message.getFieldWithDefault(msg, 2, ""),
      account: (f = msg.getAccount()) && gateway_people_pb.Account.toObject(includeInstance, f),
      uid: jspb.Message.getFieldWithDefault(msg, 4, ""),
      personContentMeta: (f = msg.getPersonContentMeta()) && PersonContentMeta.toObject(includeInstance, f),
      personName: jspb.Message.getFieldWithDefault(msg, 6, ""),
      mingaName: jspb.Message.getFieldWithDefault(msg, 7, ""),
      mingaLogo: jspb.Message.getFieldWithDefault(msg, 8, ""),
      personViewMinimal: (f = msg.getPersonViewMinimal()) && gateway_person_view_pb.PersonViewMinimal.toObject(includeInstance, f),
      birthdate: (f = msg.getBirthdate()) && common_date_pb.Date.toObject(includeInstance, f),
      publicBirthdate: jspb.Message.getBooleanFieldWithDefault(msg, 12, false),
      apiUrl: jspb.Message.getFieldWithDefault(msg, 13, ""),
      appConfig: (f = msg.getAppConfig()) && gateway_connect_pb.AppConfigResponse.toObject(includeInstance, f),
      accountRoleType: jspb.Message.getFieldWithDefault(msg, 15, ""),
      mingaInfo: (f = msg.getMingaInfo()) && gateway_minga_pb.MingaInfoMinimal.toObject(includeInstance, f),
      parentGroupHashList: (f = jspb.Message.getRepeatedField(msg, 17)) == null ? undefined : f,
      dmPersonalPreference: jspb.Message.getBooleanFieldWithDefault(msg, 18, false),
      ftueScreensList: (f = jspb.Message.getRepeatedField(msg, 19)) == null ? undefined : f,
      showRateApp: jspb.Message.getBooleanFieldWithDefault(msg, 20, false),
      disabledDm: jspb.Message.getBooleanFieldWithDefault(msg, 21, false),
      accountCreated: (f = msg.getAccountCreated()) && common_date_pb.Date.toObject(includeInstance, f),
      logins: jspb.Message.getFieldWithDefault(msg, 23, 0),
      userMingasList: jspb.Message.toObjectList(msg.getUserMingasList(),
      gateway_minga_pb.MingaInfoMinimal.toObject, includeInstance),
      email: jspb.Message.getFieldWithDefault(msg, 25, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!CreateAccountResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new CreateAccountResponse;
    return CreateAccountResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!CreateAccountResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!CreateAccountResponse}
   */
  static deserializeBinaryFromReader(msg: CreateAccountResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {!StatusCode} */ (reader.readEnum());
        msg.setStatus(value1);
        break;
      case 2:
        var value2 = /** @type {string} */ (reader.readString());
        msg.setCustomAuthToken(value2);
        break;
      case 3:
        var value3 = new gateway_people_pb.Account;
        reader.readMessage(value3,gateway_people_pb.Account.deserializeBinaryFromReader);
        msg.setAccount(value3);
        break;
      case 4:
        var value4 = /** @type {string} */ (reader.readString());
        msg.setUid(value4);
        break;
      case 5:
        var value5 = new PersonContentMeta;
        reader.readMessage(value5,PersonContentMeta.deserializeBinaryFromReader);
        msg.setPersonContentMeta(value5);
        break;
      case 6:
        var value6 = /** @type {string} */ (reader.readString());
        msg.setPersonName(value6);
        break;
      case 7:
        var value7 = /** @type {string} */ (reader.readString());
        msg.setMingaName(value7);
        break;
      case 8:
        var value8 = /** @type {string} */ (reader.readString());
        msg.setMingaLogo(value8);
        break;
      case 9:
        var value9 = new gateway_person_view_pb.PersonViewMinimal;
        reader.readMessage(value9,gateway_person_view_pb.PersonViewMinimal.deserializeBinaryFromReader);
        msg.setPersonViewMinimal(value9);
        break;
      case 11:
        var value11 = new common_date_pb.Date;
        reader.readMessage(value11,common_date_pb.Date.deserializeBinaryFromReader);
        msg.setBirthdate(value11);
        break;
      case 12:
        var value12 = /** @type {boolean} */ (reader.readBool());
        msg.setPublicBirthdate(value12);
        break;
      case 13:
        var value13 = /** @type {string} */ (reader.readString());
        msg.setApiUrl(value13);
        break;
      case 14:
        var value14 = new gateway_connect_pb.AppConfigResponse;
        reader.readMessage(value14,gateway_connect_pb.AppConfigResponse.deserializeBinaryFromReader);
        msg.setAppConfig(value14);
        break;
      case 15:
        var value15 = /** @type {string} */ (reader.readString());
        msg.setAccountRoleType(value15);
        break;
      case 16:
        var value16 = new gateway_minga_pb.MingaInfoMinimal;
        reader.readMessage(value16,gateway_minga_pb.MingaInfoMinimal.deserializeBinaryFromReader);
        msg.setMingaInfo(value16);
        break;
      case 17:
        var value17 = /** @type {string} */ (reader.readString());
        msg.addParentGroupHash(value17);
        break;
      case 18:
        var value18 = /** @type {boolean} */ (reader.readBool());
        msg.setDmPersonalPreference(value18);
        break;
      case 19:
        var value19 = /** @type {string} */ (reader.readString());
        msg.addFtueScreens(value19);
        break;
      case 20:
        var value20 = /** @type {boolean} */ (reader.readBool());
        msg.setShowRateApp(value20);
        break;
      case 21:
        var value21 = /** @type {boolean} */ (reader.readBool());
        msg.setDisabledDm(value21);
        break;
      case 22:
        var value22 = new common_date_pb.Date;
        reader.readMessage(value22,common_date_pb.Date.deserializeBinaryFromReader);
        msg.setAccountCreated(value22);
        break;
      case 23:
        var value23 = /** @type {number} */ (reader.readInt32());
        msg.setLogins(value23);
        break;
      case 24:
        var value24 = new gateway_minga_pb.MingaInfoMinimal;
        reader.readMessage(value24,gateway_minga_pb.MingaInfoMinimal.deserializeBinaryFromReader);
        msg.addUserMingas(value24);
        break;
      case 25:
        var value25 = /** @type {string} */ (reader.readString());
        msg.setEmail(value25);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!CreateAccountResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: CreateAccountResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getStatus();
    if (f !== 0.0) {
      writer.writeEnum(
        1,
        f
      );
    }
    f = message.getCustomAuthToken();
    if (f.length > 0) {
      writer.writeString(
        2,
        f
      );
    }
    f = message.getAccount();
    if (f != null) {
      writer.writeMessage(
        3,
        f,
        gateway_people_pb.Account.serializeBinaryToWriter
      );
    }
    f = message.getUid();
    if (f.length > 0) {
      writer.writeString(
        4,
        f
      );
    }
    f = message.getPersonContentMeta();
    if (f != null) {
      writer.writeMessage(
        5,
        f,
        PersonContentMeta.serializeBinaryToWriter
      );
    }
    f = message.getPersonName();
    if (f.length > 0) {
      writer.writeString(
        6,
        f
      );
    }
    f = message.getMingaName();
    if (f.length > 0) {
      writer.writeString(
        7,
        f
      );
    }
    f = message.getMingaLogo();
    if (f.length > 0) {
      writer.writeString(
        8,
        f
      );
    }
    f = message.getPersonViewMinimal();
    if (f != null) {
      writer.writeMessage(
        9,
        f,
        gateway_person_view_pb.PersonViewMinimal.serializeBinaryToWriter
      );
    }
    f = message.getBirthdate();
    if (f != null) {
      writer.writeMessage(
        11,
        f,
        common_date_pb.Date.serializeBinaryToWriter
      );
    }
    f = message.getPublicBirthdate();
    if (f) {
      writer.writeBool(
        12,
        f
      );
    }
    f = message.getApiUrl();
    if (f.length > 0) {
      writer.writeString(
        13,
        f
      );
    }
    f = message.getAppConfig();
    if (f != null) {
      writer.writeMessage(
        14,
        f,
        gateway_connect_pb.AppConfigResponse.serializeBinaryToWriter
      );
    }
    f = message.getAccountRoleType();
    if (f.length > 0) {
      writer.writeString(
        15,
        f
      );
    }
    f = message.getMingaInfo();
    if (f != null) {
      writer.writeMessage(
        16,
        f,
        gateway_minga_pb.MingaInfoMinimal.serializeBinaryToWriter
      );
    }
    f = message.getParentGroupHashList();
    if (f.length > 0) {
      writer.writeRepeatedString(
        17,
        f
      );
    }
    f = message.getDmPersonalPreference();
    if (f) {
      writer.writeBool(
        18,
        f
      );
    }
    f = message.getFtueScreensList();
    if (f.length > 0) {
      writer.writeRepeatedString(
        19,
        f
      );
    }
    f = message.getShowRateApp();
    if (f) {
      writer.writeBool(
        20,
        f
      );
    }
    f = message.getDisabledDm();
    if (f) {
      writer.writeBool(
        21,
        f
      );
    }
    f = message.getAccountCreated();
    if (f != null) {
      writer.writeMessage(
        22,
        f,
        common_date_pb.Date.serializeBinaryToWriter
      );
    }
    f = message.getLogins();
    if (f !== 0) {
      writer.writeInt32(
        23,
        f
      );
    }
    f = message.getUserMingasList();
    if (f.length > 0) {
      writer.writeRepeatedMessage(
        24,
        f,
        gateway_minga_pb.MingaInfoMinimal.serializeBinaryToWriter
      );
    }
    f = message.getEmail();
    if (f.length > 0) {
      writer.writeString(
        25,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    CreateAccountResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional StatusCode status = 1;
   * @return {!StatusCode}
   */
  getStatus(): common_legacy_pb.StatusCode {
    return /** @type {!StatusCode} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
  };


  /** @param {!StatusCode} value */
  setStatus(value: common_legacy_pb.StatusCode) {
    jspb.Message.setProto3EnumField(this, 1, value);
  }


  /**
   * optional string custom_auth_token = 2;
   * @return {string}
   */
  getCustomAuthToken(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
  };


  /** @param {string} value */
  setCustomAuthToken(value: string) {
    jspb.Message.setProto3StringField(this, 2, value);
  }


  /**
   * optional Account account = 3;
   * @return {?Account}
   */
  getAccount(): gateway_people_pb.Account {
    return /** @type{?Account} */ (
      jspb.Message.getWrapperField(this, gateway_people_pb.Account, 3));
  }


  /** @param {?Account|undefined} value */
  setAccount(value?: gateway_people_pb.Account) {
    jspb.Message.setWrapperField(this, 3, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearAccount() {
    this.setAccount(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasAccount(): boolean {
    return jspb.Message.getField(this, 3) != null;
  }


  /**
   * optional string uid = 4;
   * @return {string}
   */
  getUid(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
  };


  /** @param {string} value */
  setUid(value: string) {
    jspb.Message.setProto3StringField(this, 4, value);
  }


  /**
   * optional PersonContentMeta person_content_meta = 5;
   * @return {?PersonContentMeta}
   */
  getPersonContentMeta(): PersonContentMeta {
    return /** @type{?PersonContentMeta} */ (
      jspb.Message.getWrapperField(this, PersonContentMeta, 5));
  }


  /** @param {?PersonContentMeta|undefined} value */
  setPersonContentMeta(value?: PersonContentMeta) {
    jspb.Message.setWrapperField(this, 5, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearPersonContentMeta() {
    this.setPersonContentMeta(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasPersonContentMeta(): boolean {
    return jspb.Message.getField(this, 5) != null;
  }


  /**
   * optional string person_name = 6;
   * @return {string}
   */
  getPersonName(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
  };


  /** @param {string} value */
  setPersonName(value: string) {
    jspb.Message.setProto3StringField(this, 6, value);
  }


  /**
   * optional string minga_name = 7;
   * @return {string}
   */
  getMingaName(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
  };


  /** @param {string} value */
  setMingaName(value: string) {
    jspb.Message.setProto3StringField(this, 7, value);
  }


  /**
   * optional string minga_logo = 8;
   * @return {string}
   */
  getMingaLogo(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
  };


  /** @param {string} value */
  setMingaLogo(value: string) {
    jspb.Message.setProto3StringField(this, 8, value);
  }


  /**
   * optional PersonViewMinimal person_view_minimal = 9;
   * @return {?PersonViewMinimal}
   */
  getPersonViewMinimal(): gateway_person_view_pb.PersonViewMinimal {
    return /** @type{?PersonViewMinimal} */ (
      jspb.Message.getWrapperField(this, gateway_person_view_pb.PersonViewMinimal, 9));
  }


  /** @param {?PersonViewMinimal|undefined} value */
  setPersonViewMinimal(value?: gateway_person_view_pb.PersonViewMinimal) {
    jspb.Message.setWrapperField(this, 9, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearPersonViewMinimal() {
    this.setPersonViewMinimal(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasPersonViewMinimal(): boolean {
    return jspb.Message.getField(this, 9) != null;
  }


  /**
   * optional minga.common.Date birthdate = 11;
   * @return {?Date}
   */
  getBirthdate(): common_date_pb.Date {
    return /** @type{?Date} */ (
      jspb.Message.getWrapperField(this, common_date_pb.Date, 11));
  }


  /** @param {?Date|undefined} value */
  setBirthdate(value?: common_date_pb.Date) {
    jspb.Message.setWrapperField(this, 11, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearBirthdate() {
    this.setBirthdate(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasBirthdate(): boolean {
    return jspb.Message.getField(this, 11) != null;
  }


  /**
   * optional bool public_birthdate = 12;
   * @return {boolean}
   */
  getPublicBirthdate(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 12, false));
  };


  /** @param {boolean} value */
  setPublicBirthdate(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 12, value);
  }


  /**
   * optional string api_url = 13;
   * @return {string}
   */
  getApiUrl(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
  };


  /** @param {string} value */
  setApiUrl(value: string) {
    jspb.Message.setProto3StringField(this, 13, value);
  }


  /**
   * optional AppConfigResponse app_config = 14;
   * @return {?AppConfigResponse}
   */
  getAppConfig(): gateway_connect_pb.AppConfigResponse {
    return /** @type{?AppConfigResponse} */ (
      jspb.Message.getWrapperField(this, gateway_connect_pb.AppConfigResponse, 14));
  }


  /** @param {?AppConfigResponse|undefined} value */
  setAppConfig(value?: gateway_connect_pb.AppConfigResponse) {
    jspb.Message.setWrapperField(this, 14, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearAppConfig() {
    this.setAppConfig(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasAppConfig(): boolean {
    return jspb.Message.getField(this, 14) != null;
  }


  /**
   * optional string account_role_type = 15;
   * @return {string}
   */
  getAccountRoleType(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
  };


  /** @param {string} value */
  setAccountRoleType(value: string) {
    jspb.Message.setProto3StringField(this, 15, value);
  }


  /**
   * optional MingaInfoMinimal minga_info = 16;
   * @return {?MingaInfoMinimal}
   */
  getMingaInfo(): gateway_minga_pb.MingaInfoMinimal {
    return /** @type{?MingaInfoMinimal} */ (
      jspb.Message.getWrapperField(this, gateway_minga_pb.MingaInfoMinimal, 16));
  }


  /** @param {?MingaInfoMinimal|undefined} value */
  setMingaInfo(value?: gateway_minga_pb.MingaInfoMinimal) {
    jspb.Message.setWrapperField(this, 16, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearMingaInfo() {
    this.setMingaInfo(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasMingaInfo(): boolean {
    return jspb.Message.getField(this, 16) != null;
  }


  /**
   * repeated string parent_group_hash = 17;
   * @return {!Array<string>}
   */
  getParentGroupHashList(): string[] {
    return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 17));
  };


  /** @param {!Array<string>} value */
  setParentGroupHashList(value: string[]) {
    jspb.Message.setField(this, 17, value || []);
  }


  /**
   * @param {string} value
   * @param {number=} opt_index
   */
  addParentGroupHash(value: string, opt_index?: number): void {
    jspb.Message.addToRepeatedField(this, 17, value, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearParentGroupHashList() {
    this.setParentGroupHashList([]);
  }


  /**
   * optional bool dm_personal_preference = 18;
   * @return {boolean}
   */
  getDmPersonalPreference(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 18, false));
  };


  /** @param {boolean} value */
  setDmPersonalPreference(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 18, value);
  }


  /**
   * repeated string ftue_screens = 19;
   * @return {!Array<string>}
   */
  getFtueScreensList(): string[] {
    return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 19));
  };


  /** @param {!Array<string>} value */
  setFtueScreensList(value: string[]) {
    jspb.Message.setField(this, 19, value || []);
  }


  /**
   * @param {string} value
   * @param {number=} opt_index
   */
  addFtueScreens(value: string, opt_index?: number): void {
    jspb.Message.addToRepeatedField(this, 19, value, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearFtueScreensList() {
    this.setFtueScreensList([]);
  }


  /**
   * optional bool show_rate_app = 20;
   * @return {boolean}
   */
  getShowRateApp(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 20, false));
  };


  /** @param {boolean} value */
  setShowRateApp(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 20, value);
  }


  /**
   * optional bool disabled_dm = 21;
   * @return {boolean}
   */
  getDisabledDm(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 21, false));
  };


  /** @param {boolean} value */
  setDisabledDm(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 21, value);
  }


  /**
   * optional minga.common.Date account_created = 22;
   * @return {?Date}
   */
  getAccountCreated(): common_date_pb.Date {
    return /** @type{?Date} */ (
      jspb.Message.getWrapperField(this, common_date_pb.Date, 22));
  }


  /** @param {?Date|undefined} value */
  setAccountCreated(value?: common_date_pb.Date) {
    jspb.Message.setWrapperField(this, 22, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearAccountCreated() {
    this.setAccountCreated(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasAccountCreated(): boolean {
    return jspb.Message.getField(this, 22) != null;
  }


  /**
   * optional int32 logins = 23;
   * @return {number}
   */
  getLogins(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 23, 0));
  };


  /** @param {number} value */
  setLogins(value: number) {
    jspb.Message.setProto3IntField(this, 23, value);
  }


  /**
   * repeated MingaInfoMinimal user_mingas = 24;
   * @return {!Array<!MingaInfoMinimal>}
   */
  getUserMingasList(): gateway_minga_pb.MingaInfoMinimal[] {
    return /** @type{!Array<!MingaInfoMinimal>} */ (
      jspb.Message.getRepeatedWrapperField(this, gateway_minga_pb.MingaInfoMinimal, 24));
  }


  /** @param {!Array<!MingaInfoMinimal>} value */
  setUserMingasList(value?: gateway_minga_pb.MingaInfoMinimal[]) {
    jspb.Message.setRepeatedWrapperField(this, 24, value);
  }


  /**
   * @param {!MingaInfoMinimal=} opt_value
   * @param {number=} opt_index
   * @return {!MingaInfoMinimal}
   */
  addUserMingas(opt_value?: gateway_minga_pb.MingaInfoMinimal, opt_index?: number): gateway_minga_pb.MingaInfoMinimal {
    return jspb.Message.addToRepeatedWrapperField(this, 24, opt_value, gateway_minga_pb.MingaInfoMinimal, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearUserMingasList() {
    this.setUserMingasList([]);
  }


  /**
   * optional string email = 25;
   * @return {string}
   */
  getEmail(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 25, ""));
  };


  /** @param {string} value */
  setEmail(value: string) {
    jspb.Message.setProto3StringField(this, 25, value);
  }


}
export namespace LogoutRequest {
  export interface AsObject {
    identityToken: string;
    deviceId: string;
  }
}
export class LogoutRequest extends jspb.Message {
  static readonly displayName = "LogoutRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): LogoutRequest.AsObject {
    return LogoutRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!LogoutRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: LogoutRequest) {
    var f: any;
    var obj: any = {
      identityToken: jspb.Message.getFieldWithDefault(msg, 2, ""),
      deviceId: jspb.Message.getFieldWithDefault(msg, 3, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!LogoutRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new LogoutRequest;
    return LogoutRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!LogoutRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!LogoutRequest}
   */
  static deserializeBinaryFromReader(msg: LogoutRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 2:
        var value2 = /** @type {string} */ (reader.readString());
        msg.setIdentityToken(value2);
        break;
      case 3:
        var value3 = /** @type {string} */ (reader.readString());
        msg.setDeviceId(value3);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!LogoutRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: LogoutRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getIdentityToken();
    if (f.length > 0) {
      writer.writeString(
        2,
        f
      );
    }
    f = message.getDeviceId();
    if (f.length > 0) {
      writer.writeString(
        3,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    LogoutRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string identity_token = 2;
   * @return {string}
   */
  getIdentityToken(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
  };


  /** @param {string} value */
  setIdentityToken(value: string) {
    jspb.Message.setProto3StringField(this, 2, value);
  }


  /**
   * optional string device_id = 3;
   * @return {string}
   */
  getDeviceId(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
  };


  /** @param {string} value */
  setDeviceId(value: string) {
    jspb.Message.setProto3StringField(this, 3, value);
  }


}
export namespace LogoutResponse {
  export interface AsObject {
    status?: common_legacy_pb.StatusCode;
    reason?: common_legacy_pb.StatusReason.AsObject;
  }
}
export class LogoutResponse extends jspb.Message {
  static readonly displayName = "LogoutResponse";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): LogoutResponse.AsObject {
    return LogoutResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!LogoutResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: LogoutResponse) {
    var f: any;
    var obj: any = {
      status: jspb.Message.getFieldWithDefault(msg, 1, 0),
      reason: (f = msg.getReason()) && common_legacy_pb.StatusReason.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!LogoutResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new LogoutResponse;
    return LogoutResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!LogoutResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!LogoutResponse}
   */
  static deserializeBinaryFromReader(msg: LogoutResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {!StatusCode} */ (reader.readEnum());
        msg.setStatus(value1);
        break;
      case 2:
        var value2 = new common_legacy_pb.StatusReason;
        reader.readMessage(value2,common_legacy_pb.StatusReason.deserializeBinaryFromReader);
        msg.setReason(value2);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!LogoutResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: LogoutResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getStatus();
    if (f !== 0.0) {
      writer.writeEnum(
        1,
        f
      );
    }
    f = message.getReason();
    if (f != null) {
      writer.writeMessage(
        2,
        f,
        common_legacy_pb.StatusReason.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    LogoutResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional StatusCode status = 1;
   * @return {!StatusCode}
   */
  getStatus(): common_legacy_pb.StatusCode {
    return /** @type {!StatusCode} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
  };


  /** @param {!StatusCode} value */
  setStatus(value: common_legacy_pb.StatusCode) {
    jspb.Message.setProto3EnumField(this, 1, value);
  }


  /**
   * optional StatusReason reason = 2;
   * @return {?StatusReason}
   */
  getReason(): common_legacy_pb.StatusReason {
    return /** @type{?StatusReason} */ (
      jspb.Message.getWrapperField(this, common_legacy_pb.StatusReason, 2));
  }


  /** @param {?StatusReason|undefined} value */
  setReason(value?: common_legacy_pb.StatusReason) {
    jspb.Message.setWrapperField(this, 2, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearReason() {
    this.setReason(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasReason(): boolean {
    return jspb.Message.getField(this, 2) != null;
  }


}
export namespace ForgotPasswordRequest {
  export interface AsObject {
    email: string;
  }
}
export class ForgotPasswordRequest extends jspb.Message {
  static readonly displayName = "ForgotPasswordRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): ForgotPasswordRequest.AsObject {
    return ForgotPasswordRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!ForgotPasswordRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: ForgotPasswordRequest) {
    var f: any;
    var obj: any = {
      email: jspb.Message.getFieldWithDefault(msg, 1, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!ForgotPasswordRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new ForgotPasswordRequest;
    return ForgotPasswordRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!ForgotPasswordRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!ForgotPasswordRequest}
   */
  static deserializeBinaryFromReader(msg: ForgotPasswordRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setEmail(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!ForgotPasswordRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: ForgotPasswordRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getEmail();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    ForgotPasswordRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string email = 1;
   * @return {string}
   */
  getEmail(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setEmail(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


}
export namespace ForgotPasswordResponse {
  export interface AsObject {
    status?: common_legacy_pb.StatusCode;
    reason?: common_legacy_pb.StatusReason.AsObject;
  }
}
export class ForgotPasswordResponse extends jspb.Message {
  static readonly displayName = "ForgotPasswordResponse";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): ForgotPasswordResponse.AsObject {
    return ForgotPasswordResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!ForgotPasswordResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: ForgotPasswordResponse) {
    var f: any;
    var obj: any = {
      status: jspb.Message.getFieldWithDefault(msg, 1, 0),
      reason: (f = msg.getReason()) && common_legacy_pb.StatusReason.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!ForgotPasswordResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new ForgotPasswordResponse;
    return ForgotPasswordResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!ForgotPasswordResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!ForgotPasswordResponse}
   */
  static deserializeBinaryFromReader(msg: ForgotPasswordResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {!StatusCode} */ (reader.readEnum());
        msg.setStatus(value1);
        break;
      case 2:
        var value2 = new common_legacy_pb.StatusReason;
        reader.readMessage(value2,common_legacy_pb.StatusReason.deserializeBinaryFromReader);
        msg.setReason(value2);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!ForgotPasswordResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: ForgotPasswordResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getStatus();
    if (f !== 0.0) {
      writer.writeEnum(
        1,
        f
      );
    }
    f = message.getReason();
    if (f != null) {
      writer.writeMessage(
        2,
        f,
        common_legacy_pb.StatusReason.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    ForgotPasswordResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional StatusCode status = 1;
   * @return {!StatusCode}
   */
  getStatus(): common_legacy_pb.StatusCode {
    return /** @type {!StatusCode} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
  };


  /** @param {!StatusCode} value */
  setStatus(value: common_legacy_pb.StatusCode) {
    jspb.Message.setProto3EnumField(this, 1, value);
  }


  /**
   * optional StatusReason reason = 2;
   * @return {?StatusReason}
   */
  getReason(): common_legacy_pb.StatusReason {
    return /** @type{?StatusReason} */ (
      jspb.Message.getWrapperField(this, common_legacy_pb.StatusReason, 2));
  }


  /** @param {?StatusReason|undefined} value */
  setReason(value?: common_legacy_pb.StatusReason) {
    jspb.Message.setWrapperField(this, 2, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearReason() {
    this.setReason(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasReason(): boolean {
    return jspb.Message.getField(this, 2) != null;
  }


}
export namespace ResetPasswordRequest {
  export interface AsObject {
    resetToken: string;
  }
}
export class ResetPasswordRequest extends jspb.Message {
  static readonly displayName = "ResetPasswordRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): ResetPasswordRequest.AsObject {
    return ResetPasswordRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!ResetPasswordRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: ResetPasswordRequest) {
    var f: any;
    var obj: any = {
      resetToken: jspb.Message.getFieldWithDefault(msg, 1, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!ResetPasswordRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new ResetPasswordRequest;
    return ResetPasswordRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!ResetPasswordRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!ResetPasswordRequest}
   */
  static deserializeBinaryFromReader(msg: ResetPasswordRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setResetToken(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!ResetPasswordRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: ResetPasswordRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getResetToken();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    ResetPasswordRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string reset_token = 1;
   * @return {string}
   */
  getResetToken(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setResetToken(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


}
export namespace ResetPasswordResponse {
  export interface AsObject {
    status?: common_legacy_pb.StatusCode;
    reason?: common_legacy_pb.StatusReason.AsObject;
  }
}
export class ResetPasswordResponse extends jspb.Message {
  static readonly displayName = "ResetPasswordResponse";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): ResetPasswordResponse.AsObject {
    return ResetPasswordResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!ResetPasswordResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: ResetPasswordResponse) {
    var f: any;
    var obj: any = {
      status: jspb.Message.getFieldWithDefault(msg, 1, 0),
      reason: (f = msg.getReason()) && common_legacy_pb.StatusReason.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!ResetPasswordResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new ResetPasswordResponse;
    return ResetPasswordResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!ResetPasswordResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!ResetPasswordResponse}
   */
  static deserializeBinaryFromReader(msg: ResetPasswordResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {!StatusCode} */ (reader.readEnum());
        msg.setStatus(value1);
        break;
      case 2:
        var value2 = new common_legacy_pb.StatusReason;
        reader.readMessage(value2,common_legacy_pb.StatusReason.deserializeBinaryFromReader);
        msg.setReason(value2);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!ResetPasswordResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: ResetPasswordResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getStatus();
    if (f !== 0.0) {
      writer.writeEnum(
        1,
        f
      );
    }
    f = message.getReason();
    if (f != null) {
      writer.writeMessage(
        2,
        f,
        common_legacy_pb.StatusReason.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    ResetPasswordResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional StatusCode status = 1;
   * @return {!StatusCode}
   */
  getStatus(): common_legacy_pb.StatusCode {
    return /** @type {!StatusCode} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
  };


  /** @param {!StatusCode} value */
  setStatus(value: common_legacy_pb.StatusCode) {
    jspb.Message.setProto3EnumField(this, 1, value);
  }


  /**
   * optional StatusReason reason = 2;
   * @return {?StatusReason}
   */
  getReason(): common_legacy_pb.StatusReason {
    return /** @type{?StatusReason} */ (
      jspb.Message.getWrapperField(this, common_legacy_pb.StatusReason, 2));
  }


  /** @param {?StatusReason|undefined} value */
  setReason(value?: common_legacy_pb.StatusReason) {
    jspb.Message.setWrapperField(this, 2, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearReason() {
    this.setReason(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasReason(): boolean {
    return jspb.Message.getField(this, 2) != null;
  }


}
export namespace RetrievePinRequest {
  export interface AsObject {
    minga: string;
    email: string;
    firstName: string;
    lastName: string;
  }
}
export class RetrievePinRequest extends jspb.Message {
  static readonly displayName = "RetrievePinRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): RetrievePinRequest.AsObject {
    return RetrievePinRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!RetrievePinRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: RetrievePinRequest) {
    var f: any;
    var obj: any = {
      minga: jspb.Message.getFieldWithDefault(msg, 1, ""),
      email: jspb.Message.getFieldWithDefault(msg, 2, ""),
      firstName: jspb.Message.getFieldWithDefault(msg, 3, ""),
      lastName: jspb.Message.getFieldWithDefault(msg, 4, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!RetrievePinRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new RetrievePinRequest;
    return RetrievePinRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!RetrievePinRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!RetrievePinRequest}
   */
  static deserializeBinaryFromReader(msg: RetrievePinRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setMinga(value1);
        break;
      case 2:
        var value2 = /** @type {string} */ (reader.readString());
        msg.setEmail(value2);
        break;
      case 3:
        var value3 = /** @type {string} */ (reader.readString());
        msg.setFirstName(value3);
        break;
      case 4:
        var value4 = /** @type {string} */ (reader.readString());
        msg.setLastName(value4);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!RetrievePinRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: RetrievePinRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getMinga();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
    f = message.getEmail();
    if (f.length > 0) {
      writer.writeString(
        2,
        f
      );
    }
    f = message.getFirstName();
    if (f.length > 0) {
      writer.writeString(
        3,
        f
      );
    }
    f = message.getLastName();
    if (f.length > 0) {
      writer.writeString(
        4,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    RetrievePinRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string minga = 1;
   * @return {string}
   */
  getMinga(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setMinga(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


  /**
   * optional string email = 2;
   * @return {string}
   */
  getEmail(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
  };


  /** @param {string} value */
  setEmail(value: string) {
    jspb.Message.setProto3StringField(this, 2, value);
  }


  /**
   * optional string first_name = 3;
   * @return {string}
   */
  getFirstName(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
  };


  /** @param {string} value */
  setFirstName(value: string) {
    jspb.Message.setProto3StringField(this, 3, value);
  }


  /**
   * optional string last_name = 4;
   * @return {string}
   */
  getLastName(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
  };


  /** @param {string} value */
  setLastName(value: string) {
    jspb.Message.setProto3StringField(this, 4, value);
  }


}
export namespace RetrievePinResponse {
  export interface AsObject {
    pin: string;
  }
}
export class RetrievePinResponse extends jspb.Message {
  static readonly displayName = "RetrievePinResponse";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): RetrievePinResponse.AsObject {
    return RetrievePinResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!RetrievePinResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: RetrievePinResponse) {
    var f: any;
    var obj: any = {
      pin: jspb.Message.getFieldWithDefault(msg, 1, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!RetrievePinResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new RetrievePinResponse;
    return RetrievePinResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!RetrievePinResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!RetrievePinResponse}
   */
  static deserializeBinaryFromReader(msg: RetrievePinResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setPin(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!RetrievePinResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: RetrievePinResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getPin();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    RetrievePinResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string pin = 1;
   * @return {string}
   */
  getPin(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setPin(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


}
export namespace KioskLoginRequest {
  export interface AsObject {
    email: string;
    pin: string;
  }
}
export class KioskLoginRequest extends jspb.Message {
  static readonly displayName = "KioskLoginRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): KioskLoginRequest.AsObject {
    return KioskLoginRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!KioskLoginRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: KioskLoginRequest) {
    var f: any;
    var obj: any = {
      email: jspb.Message.getFieldWithDefault(msg, 1, ""),
      pin: jspb.Message.getFieldWithDefault(msg, 2, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!KioskLoginRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new KioskLoginRequest;
    return KioskLoginRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!KioskLoginRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!KioskLoginRequest}
   */
  static deserializeBinaryFromReader(msg: KioskLoginRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setEmail(value1);
        break;
      case 2:
        var value2 = /** @type {string} */ (reader.readString());
        msg.setPin(value2);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!KioskLoginRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: KioskLoginRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getEmail();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
    f = message.getPin();
    if (f.length > 0) {
      writer.writeString(
        2,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    KioskLoginRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string email = 1;
   * @return {string}
   */
  getEmail(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setEmail(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


  /**
   * optional string pin = 2;
   * @return {string}
   */
  getPin(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
  };


  /** @param {string} value */
  setPin(value: string) {
    jspb.Message.setProto3StringField(this, 2, value);
  }


}
export namespace KioskPinRequest {
  export interface AsObject {
  }
}
export class KioskPinRequest extends jspb.Message {
  static readonly displayName = "KioskPinRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): KioskPinRequest.AsObject {
    return KioskPinRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!KioskPinRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: KioskPinRequest) {
    var f: any;
    var obj: any = {

    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!KioskPinRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new KioskPinRequest;
    return KioskPinRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!KioskPinRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!KioskPinRequest}
   */
  static deserializeBinaryFromReader(msg: KioskPinRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!KioskPinRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: KioskPinRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    KioskPinRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
}
export namespace KioskPinResponse {
  export interface AsObject {
    pin: string;
  }
}
export class KioskPinResponse extends jspb.Message {
  static readonly displayName = "KioskPinResponse";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): KioskPinResponse.AsObject {
    return KioskPinResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!KioskPinResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: KioskPinResponse) {
    var f: any;
    var obj: any = {
      pin: jspb.Message.getFieldWithDefault(msg, 1, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!KioskPinResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new KioskPinResponse;
    return KioskPinResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!KioskPinResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!KioskPinResponse}
   */
  static deserializeBinaryFromReader(msg: KioskPinResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setPin(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!KioskPinResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: KioskPinResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getPin();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    KioskPinResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string pin = 1;
   * @return {string}
   */
  getPin(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setPin(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


}
export namespace ValidateKioskPinRequest {
  export interface AsObject {
    pin: string;
  }
}
export class ValidateKioskPinRequest extends jspb.Message {
  static readonly displayName = "ValidateKioskPinRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): ValidateKioskPinRequest.AsObject {
    return ValidateKioskPinRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!ValidateKioskPinRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: ValidateKioskPinRequest) {
    var f: any;
    var obj: any = {
      pin: jspb.Message.getFieldWithDefault(msg, 1, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!ValidateKioskPinRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new ValidateKioskPinRequest;
    return ValidateKioskPinRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!ValidateKioskPinRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!ValidateKioskPinRequest}
   */
  static deserializeBinaryFromReader(msg: ValidateKioskPinRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setPin(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!ValidateKioskPinRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: ValidateKioskPinRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getPin();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    ValidateKioskPinRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string pin = 1;
   * @return {string}
   */
  getPin(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setPin(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


}
export namespace ValidateKioskPinResponse {
  export interface AsObject {
    valid: boolean;
  }
}
export class ValidateKioskPinResponse extends jspb.Message {
  static readonly displayName = "ValidateKioskPinResponse";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): ValidateKioskPinResponse.AsObject {
    return ValidateKioskPinResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!ValidateKioskPinResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: ValidateKioskPinResponse) {
    var f: any;
    var obj: any = {
      valid: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!ValidateKioskPinResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new ValidateKioskPinResponse;
    return ValidateKioskPinResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!ValidateKioskPinResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!ValidateKioskPinResponse}
   */
  static deserializeBinaryFromReader(msg: ValidateKioskPinResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {boolean} */ (reader.readBool());
        msg.setValid(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!ValidateKioskPinResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: ValidateKioskPinResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getValid();
    if (f) {
      writer.writeBool(
        1,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    ValidateKioskPinResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional bool valid = 1;
   * @return {boolean}
   */
  getValid(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
  };


  /** @param {boolean} value */
  setValid(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 1, value);
  }


}
export namespace ForgotKioskPinRequest {
  export interface AsObject {
  }
}
export class ForgotKioskPinRequest extends jspb.Message {
  static readonly displayName = "ForgotKioskPinRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): ForgotKioskPinRequest.AsObject {
    return ForgotKioskPinRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!ForgotKioskPinRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: ForgotKioskPinRequest) {
    var f: any;
    var obj: any = {

    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!ForgotKioskPinRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new ForgotKioskPinRequest;
    return ForgotKioskPinRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!ForgotKioskPinRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!ForgotKioskPinRequest}
   */
  static deserializeBinaryFromReader(msg: ForgotKioskPinRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!ForgotKioskPinRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: ForgotKioskPinRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    ForgotKioskPinRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
}
export namespace ForgotKioskPinResponse {
  export interface AsObject {
    status?: common_legacy_pb.StatusCode;
  }
}
export class ForgotKioskPinResponse extends jspb.Message {
  static readonly displayName = "ForgotKioskPinResponse";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): ForgotKioskPinResponse.AsObject {
    return ForgotKioskPinResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!ForgotKioskPinResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: ForgotKioskPinResponse) {
    var f: any;
    var obj: any = {
      status: jspb.Message.getFieldWithDefault(msg, 1, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!ForgotKioskPinResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new ForgotKioskPinResponse;
    return ForgotKioskPinResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!ForgotKioskPinResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!ForgotKioskPinResponse}
   */
  static deserializeBinaryFromReader(msg: ForgotKioskPinResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {!StatusCode} */ (reader.readEnum());
        msg.setStatus(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!ForgotKioskPinResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: ForgotKioskPinResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getStatus();
    if (f !== 0.0) {
      writer.writeEnum(
        1,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    ForgotKioskPinResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional StatusCode status = 1;
   * @return {!StatusCode}
   */
  getStatus(): common_legacy_pb.StatusCode {
    return /** @type {!StatusCode} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
  };


  /** @param {!StatusCode} value */
  setStatus(value: common_legacy_pb.StatusCode) {
    jspb.Message.setProto3EnumField(this, 1, value);
  }


}
export namespace UpdateFirebaseDeviceRequest {
  export interface AsObject {
  }
}
export class UpdateFirebaseDeviceRequest extends jspb.Message {
  static readonly displayName = "UpdateFirebaseDeviceRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): UpdateFirebaseDeviceRequest.AsObject {
    return UpdateFirebaseDeviceRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!UpdateFirebaseDeviceRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: UpdateFirebaseDeviceRequest) {
    var f: any;
    var obj: any = {

    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!UpdateFirebaseDeviceRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new UpdateFirebaseDeviceRequest;
    return UpdateFirebaseDeviceRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!UpdateFirebaseDeviceRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!UpdateFirebaseDeviceRequest}
   */
  static deserializeBinaryFromReader(msg: UpdateFirebaseDeviceRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!UpdateFirebaseDeviceRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: UpdateFirebaseDeviceRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    UpdateFirebaseDeviceRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
}
export namespace UpdateFirebaseDeviceResponse {
  export interface AsObject {
  }
}
export class UpdateFirebaseDeviceResponse extends jspb.Message {
  static readonly displayName = "UpdateFirebaseDeviceResponse";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): UpdateFirebaseDeviceResponse.AsObject {
    return UpdateFirebaseDeviceResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!UpdateFirebaseDeviceResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: UpdateFirebaseDeviceResponse) {
    var f: any;
    var obj: any = {

    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!UpdateFirebaseDeviceResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new UpdateFirebaseDeviceResponse;
    return UpdateFirebaseDeviceResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!UpdateFirebaseDeviceResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!UpdateFirebaseDeviceResponse}
   */
  static deserializeBinaryFromReader(msg: UpdateFirebaseDeviceResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!UpdateFirebaseDeviceResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: UpdateFirebaseDeviceResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    UpdateFirebaseDeviceResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
}
export namespace OnStartupRequest {
  export interface AsObject {
  }
}
export class OnStartupRequest extends jspb.Message {
  static readonly displayName = "OnStartupRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): OnStartupRequest.AsObject {
    return OnStartupRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!OnStartupRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: OnStartupRequest) {
    var f: any;
    var obj: any = {

    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!OnStartupRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new OnStartupRequest;
    return OnStartupRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!OnStartupRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!OnStartupRequest}
   */
  static deserializeBinaryFromReader(msg: OnStartupRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!OnStartupRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: OnStartupRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    OnStartupRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
}
export namespace OnStartupResponse {
  export interface AsObject {
    unreadNotificationsList: gateway_notification_pb.Notification.AsObject[];
    hasUnreadDirectMessages: boolean;
    mingaGroups?: gateway_mingaGroup_pb.GetMingaGroupsResponse.AsObject;
  }
}
export class OnStartupResponse extends jspb.Message {
  static readonly displayName = "OnStartupResponse";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [1];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, OnStartupResponse.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): OnStartupResponse.AsObject {
    return OnStartupResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!OnStartupResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: OnStartupResponse) {
    var f: any;
    var obj: any = {
      unreadNotificationsList: jspb.Message.toObjectList(msg.getUnreadNotificationsList(),
      gateway_notification_pb.Notification.toObject, includeInstance),
      hasUnreadDirectMessages: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
      mingaGroups: (f = msg.getMingaGroups()) && gateway_mingaGroup_pb.GetMingaGroupsResponse.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!OnStartupResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new OnStartupResponse;
    return OnStartupResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!OnStartupResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!OnStartupResponse}
   */
  static deserializeBinaryFromReader(msg: OnStartupResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new gateway_notification_pb.Notification;
        reader.readMessage(value1,gateway_notification_pb.Notification.deserializeBinaryFromReader);
        msg.addUnreadNotifications(value1);
        break;
      case 2:
        var value2 = /** @type {boolean} */ (reader.readBool());
        msg.setHasUnreadDirectMessages(value2);
        break;
      case 3:
        var value3 = new gateway_mingaGroup_pb.GetMingaGroupsResponse;
        reader.readMessage(value3,gateway_mingaGroup_pb.GetMingaGroupsResponse.deserializeBinaryFromReader);
        msg.setMingaGroups(value3);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!OnStartupResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: OnStartupResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getUnreadNotificationsList();
    if (f.length > 0) {
      writer.writeRepeatedMessage(
        1,
        f,
        gateway_notification_pb.Notification.serializeBinaryToWriter
      );
    }
    f = message.getHasUnreadDirectMessages();
    if (f) {
      writer.writeBool(
        2,
        f
      );
    }
    f = message.getMingaGroups();
    if (f != null) {
      writer.writeMessage(
        3,
        f,
        gateway_mingaGroup_pb.GetMingaGroupsResponse.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    OnStartupResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * repeated Notification unread_notifications = 1;
   * @return {!Array<!Notification>}
   */
  getUnreadNotificationsList(): gateway_notification_pb.Notification[] {
    return /** @type{!Array<!Notification>} */ (
      jspb.Message.getRepeatedWrapperField(this, gateway_notification_pb.Notification, 1));
  }


  /** @param {!Array<!Notification>} value */
  setUnreadNotificationsList(value?: gateway_notification_pb.Notification[]) {
    jspb.Message.setRepeatedWrapperField(this, 1, value);
  }


  /**
   * @param {!Notification=} opt_value
   * @param {number=} opt_index
   * @return {!Notification}
   */
  addUnreadNotifications(opt_value?: gateway_notification_pb.Notification, opt_index?: number): gateway_notification_pb.Notification {
    return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, gateway_notification_pb.Notification, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearUnreadNotificationsList() {
    this.setUnreadNotificationsList([]);
  }


  /**
   * optional bool has_unread_direct_messages = 2;
   * @return {boolean}
   */
  getHasUnreadDirectMessages(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
  };


  /** @param {boolean} value */
  setHasUnreadDirectMessages(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 2, value);
  }


  /**
   * optional minga.group.GetMingaGroupsResponse minga_groups = 3;
   * @return {?GetMingaGroupsResponse}
   */
  getMingaGroups(): gateway_mingaGroup_pb.GetMingaGroupsResponse {
    return /** @type{?GetMingaGroupsResponse} */ (
      jspb.Message.getWrapperField(this, gateway_mingaGroup_pb.GetMingaGroupsResponse, 3));
  }


  /** @param {?GetMingaGroupsResponse|undefined} value */
  setMingaGroups(value?: gateway_mingaGroup_pb.GetMingaGroupsResponse) {
    jspb.Message.setWrapperField(this, 3, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearMingaGroups() {
    this.setMingaGroups(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasMingaGroups(): boolean {
    return jspb.Message.getField(this, 3) != null;
  }


}
