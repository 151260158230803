// source: gateway/inspiration.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

import * as jspb from 'google-protobuf';
import * as common_legacy_pb from '../common/legacy_pb';
import * as gateway_moderation_pb from '../gateway/moderation_pb';
export namespace CreateInspirationRequest {
  export interface AsObject {
    imageAsset: string;
    customQuote: string;
    customQuotePosition?: PositionAnchor;
    publishTimestamp: number;
    unpublishTimstamp: number;
    status: boolean;
    styleBackgroundColor: string;
    styleGradientOverlap: boolean;
    styleLightContent: boolean;
  }
}
export class CreateInspirationRequest extends jspb.Message {
  static readonly displayName = "CreateInspirationRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): CreateInspirationRequest.AsObject {
    return CreateInspirationRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!CreateInspirationRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: CreateInspirationRequest) {
    var f: any;
    var obj: any = {
      imageAsset: jspb.Message.getFieldWithDefault(msg, 1, ""),
      customQuote: jspb.Message.getFieldWithDefault(msg, 2, ""),
      customQuotePosition: jspb.Message.getFieldWithDefault(msg, 3, 0),
      publishTimestamp: jspb.Message.getFieldWithDefault(msg, 4, 0),
      unpublishTimstamp: jspb.Message.getFieldWithDefault(msg, 5, 0),
      status: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
      styleBackgroundColor: jspb.Message.getFieldWithDefault(msg, 7, ""),
      styleGradientOverlap: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
      styleLightContent: jspb.Message.getBooleanFieldWithDefault(msg, 9, false)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!CreateInspirationRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new CreateInspirationRequest;
    return CreateInspirationRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!CreateInspirationRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!CreateInspirationRequest}
   */
  static deserializeBinaryFromReader(msg: CreateInspirationRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setImageAsset(value1);
        break;
      case 2:
        var value2 = /** @type {string} */ (reader.readString());
        msg.setCustomQuote(value2);
        break;
      case 3:
        var value3 = /** @type {!PositionAnchor} */ (reader.readEnum());
        msg.setCustomQuotePosition(value3);
        break;
      case 4:
        var value4 = /** @type {number} */ (reader.readUint64());
        msg.setPublishTimestamp(value4);
        break;
      case 5:
        var value5 = /** @type {number} */ (reader.readUint64());
        msg.setUnpublishTimstamp(value5);
        break;
      case 6:
        var value6 = /** @type {boolean} */ (reader.readBool());
        msg.setStatus(value6);
        break;
      case 7:
        var value7 = /** @type {string} */ (reader.readString());
        msg.setStyleBackgroundColor(value7);
        break;
      case 8:
        var value8 = /** @type {boolean} */ (reader.readBool());
        msg.setStyleGradientOverlap(value8);
        break;
      case 9:
        var value9 = /** @type {boolean} */ (reader.readBool());
        msg.setStyleLightContent(value9);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!CreateInspirationRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: CreateInspirationRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getImageAsset();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
    f = message.getCustomQuote();
    if (f.length > 0) {
      writer.writeString(
        2,
        f
      );
    }
    f = message.getCustomQuotePosition();
    if (f !== 0.0) {
      writer.writeEnum(
        3,
        f
      );
    }
    f = message.getPublishTimestamp();
    if (f !== 0) {
      writer.writeUint64(
        4,
        f
      );
    }
    f = message.getUnpublishTimstamp();
    if (f !== 0) {
      writer.writeUint64(
        5,
        f
      );
    }
    f = message.getStatus();
    if (f) {
      writer.writeBool(
        6,
        f
      );
    }
    f = message.getStyleBackgroundColor();
    if (f.length > 0) {
      writer.writeString(
        7,
        f
      );
    }
    f = message.getStyleGradientOverlap();
    if (f) {
      writer.writeBool(
        8,
        f
      );
    }
    f = message.getStyleLightContent();
    if (f) {
      writer.writeBool(
        9,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    CreateInspirationRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string image_asset = 1;
   * @return {string}
   */
  getImageAsset(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setImageAsset(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


  /**
   * optional string custom_quote = 2;
   * @return {string}
   */
  getCustomQuote(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
  };


  /** @param {string} value */
  setCustomQuote(value: string) {
    jspb.Message.setProto3StringField(this, 2, value);
  }


  /**
   * optional PositionAnchor custom_quote_position = 3;
   * @return {!PositionAnchor}
   */
  getCustomQuotePosition(): PositionAnchor {
    return /** @type {!PositionAnchor} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
  };


  /** @param {!PositionAnchor} value */
  setCustomQuotePosition(value: PositionAnchor) {
    jspb.Message.setProto3EnumField(this, 3, value);
  }


  /**
   * optional uint64 publish_timestamp = 4;
   * @return {number}
   */
  getPublishTimestamp(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
  };


  /** @param {number} value */
  setPublishTimestamp(value: number) {
    jspb.Message.setProto3IntField(this, 4, value);
  }


  /**
   * optional uint64 unpublish_timstamp = 5;
   * @return {number}
   */
  getUnpublishTimstamp(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
  };


  /** @param {number} value */
  setUnpublishTimstamp(value: number) {
    jspb.Message.setProto3IntField(this, 5, value);
  }


  /**
   * optional bool status = 6;
   * @return {boolean}
   */
  getStatus(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
  };


  /** @param {boolean} value */
  setStatus(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 6, value);
  }


  /**
   * optional string style_background_color = 7;
   * @return {string}
   */
  getStyleBackgroundColor(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
  };


  /** @param {string} value */
  setStyleBackgroundColor(value: string) {
    jspb.Message.setProto3StringField(this, 7, value);
  }


  /**
   * optional bool style_gradient_overlap = 8;
   * @return {boolean}
   */
  getStyleGradientOverlap(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
  };


  /** @param {boolean} value */
  setStyleGradientOverlap(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 8, value);
  }


  /**
   * optional bool style_light_content = 9;
   * @return {boolean}
   */
  getStyleLightContent(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
  };


  /** @param {boolean} value */
  setStyleLightContent(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 9, value);
  }


}
export namespace CreateInspirationResponse {
  export interface AsObject {
    status?: common_legacy_pb.StatusCode;
    reason?: common_legacy_pb.StatusReason.AsObject;
    contentHash: string;
    moderationResult?: gateway_moderation_pb.ModerationResult.AsObject;
  }
}
export class CreateInspirationResponse extends jspb.Message {
  static readonly displayName = "CreateInspirationResponse";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): CreateInspirationResponse.AsObject {
    return CreateInspirationResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!CreateInspirationResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: CreateInspirationResponse) {
    var f: any;
    var obj: any = {
      status: jspb.Message.getFieldWithDefault(msg, 1, 0),
      reason: (f = msg.getReason()) && common_legacy_pb.StatusReason.toObject(includeInstance, f),
      contentHash: jspb.Message.getFieldWithDefault(msg, 3, ""),
      moderationResult: (f = msg.getModerationResult()) && gateway_moderation_pb.ModerationResult.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!CreateInspirationResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new CreateInspirationResponse;
    return CreateInspirationResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!CreateInspirationResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!CreateInspirationResponse}
   */
  static deserializeBinaryFromReader(msg: CreateInspirationResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {!StatusCode} */ (reader.readEnum());
        msg.setStatus(value1);
        break;
      case 2:
        var value2 = new common_legacy_pb.StatusReason;
        reader.readMessage(value2,common_legacy_pb.StatusReason.deserializeBinaryFromReader);
        msg.setReason(value2);
        break;
      case 3:
        var value3 = /** @type {string} */ (reader.readString());
        msg.setContentHash(value3);
        break;
      case 4:
        var value4 = new gateway_moderation_pb.ModerationResult;
        reader.readMessage(value4,gateway_moderation_pb.ModerationResult.deserializeBinaryFromReader);
        msg.setModerationResult(value4);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!CreateInspirationResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: CreateInspirationResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getStatus();
    if (f !== 0.0) {
      writer.writeEnum(
        1,
        f
      );
    }
    f = message.getReason();
    if (f != null) {
      writer.writeMessage(
        2,
        f,
        common_legacy_pb.StatusReason.serializeBinaryToWriter
      );
    }
    f = message.getContentHash();
    if (f.length > 0) {
      writer.writeString(
        3,
        f
      );
    }
    f = message.getModerationResult();
    if (f != null) {
      writer.writeMessage(
        4,
        f,
        gateway_moderation_pb.ModerationResult.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    CreateInspirationResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional StatusCode status = 1;
   * @return {!StatusCode}
   */
  getStatus(): common_legacy_pb.StatusCode {
    return /** @type {!StatusCode} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
  };


  /** @param {!StatusCode} value */
  setStatus(value: common_legacy_pb.StatusCode) {
    jspb.Message.setProto3EnumField(this, 1, value);
  }


  /**
   * optional StatusReason reason = 2;
   * @return {?StatusReason}
   */
  getReason(): common_legacy_pb.StatusReason {
    return /** @type{?StatusReason} */ (
      jspb.Message.getWrapperField(this, common_legacy_pb.StatusReason, 2));
  }


  /** @param {?StatusReason|undefined} value */
  setReason(value?: common_legacy_pb.StatusReason) {
    jspb.Message.setWrapperField(this, 2, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearReason() {
    this.setReason(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasReason(): boolean {
    return jspb.Message.getField(this, 2) != null;
  }


  /**
   * optional string content_hash = 3;
   * @return {string}
   */
  getContentHash(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
  };


  /** @param {string} value */
  setContentHash(value: string) {
    jspb.Message.setProto3StringField(this, 3, value);
  }


  /**
   * optional minga.ModerationResult moderation_result = 4;
   * @return {?ModerationResult}
   */
  getModerationResult(): gateway_moderation_pb.ModerationResult {
    return /** @type{?ModerationResult} */ (
      jspb.Message.getWrapperField(this, gateway_moderation_pb.ModerationResult, 4));
  }


  /** @param {?ModerationResult|undefined} value */
  setModerationResult(value?: gateway_moderation_pb.ModerationResult) {
    jspb.Message.setWrapperField(this, 4, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearModerationResult() {
    this.setModerationResult(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasModerationResult(): boolean {
    return jspb.Message.getField(this, 4) != null;
  }


}
export enum PositionAnchor {
  CENTER = 0,
  TOP = 1,
  LEFT = 2,
  RIGHT = 3,
  BOTTOM = 4,
}
