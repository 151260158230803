import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Router } from '@angular/router';

import { gateway } from 'libs/generated-grpc-web';

import {
  IOverlayConfig,
  IOverlayConfigurable,
} from '@app/src/app/misc/overlay';
import { closeCurrentOverlay } from '@app/src/app/util/overlay';

@Component({
  selector: 'mg-sms-message-long-card',
  templateUrl: './SmsMessageLongCard.component.html',
  styleUrls: ['./SmsMessageLongCard.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SmsMessageLongCardComponent implements IOverlayConfigurable {
  @Input()
  contextHash?: string;

  @Input()
  contentHash?: string;

  @Input()
  content?: gateway.content_views_pb.LongSmsMessageCardView.AsObject;

  @Input()
  showTopNav = true;

  get message() {
    return this.content?.message || '';
  }

  get authorName() {
    return this.content?.authorPersonView?.displayName || '';
  }

  get publishDate(): Date | undefined {
    if (this.content?.timestamp) {
      return new Date(this.content.timestamp);
    }
  }

  constructor(private router: Router) {}

  onClose() {
    closeCurrentOverlay(this.router);
  }

  registerOnOverlayConfig(fn: (config: IOverlayConfig) => void) {
    fn({
      borderRadius: '0px',
      contentBackground: 'transparent',
      fullContentBackground: 'white',
      boxShadow: 'none',
      mobileTransparentNav: true,
    });
  }
}
