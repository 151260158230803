import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import * as day from 'dayjs';
import { gateway, date_pb } from 'libs/generated-grpc-web';

import { GroupsFacadeService } from '@app/src/app/groups/services';
import { ConsumableContentDataService } from '@app/src/app/minimal/services/ConsumableContentData';
import { ContentEvents } from '@app/src/app/minimal/services/ContentEvents';
import { IOverlayConfig } from '@app/src/app/misc/overlay';
import { ContentState } from '@app/src/app/services/ContentState';
import { Color } from '@app/src/app/util/color';

import { LongCardBaseClass } from '../LongCardBase/LongCardBaseClass';

const MAX_DISPLAY_PERSON = 6;

@Component({
  selector: 'mg-birthday-long-card',
  templateUrl: './BirthdayLongCard.component.html',
  styleUrls: ['./BirthdayLongCard.component.scss'],
})
export class BirthdayLongCardComponent
  extends LongCardBaseClass
  implements OnInit
{
  @Input()
  content?: gateway.content_views_pb.LongBirthdayCardView.AsObject;

  constructor(
    private consumableData: ConsumableContentDataService,
    contentEvents: ContentEvents,
    router: Router,
    groupFacade: GroupsFacadeService,
    contentState: ContentState,
  ) {
    super(contentEvents, groupFacade, contentState, router);
  }

  get backgroundColor(): string {
    if (this.content) {
      return this.content.backgroundColor;
    }

    return '';
  }

  private onOverlayConfig?: (config: IOverlayConfig) => void;

  registerOnOverlayConfig(fn: (config: IOverlayConfig) => void) {
    this.onOverlayConfig = fn;
  }

  ngOnInit() {
    if (this.onOverlayConfig) {
      this.onOverlayConfig({
        mobileTransparentNav: true,
        background: this.backgroundColor,
      });
    } else {
      console.warn('Birthday long card not within overlay');
    }
  }

  bannerGradientStyle() {
    const bgColor = this.backgroundColor;
    if (!bgColor) {
      return '';
    }

    const bgColorObj = new Color(bgColor);
    const transBgColor = bgColorObj.alpha(0).toString();

    return `linear-gradient(${transBgColor}, ${bgColor})`;
  }

  birthdateTopLine(birthdate: date_pb.Date.AsObject) {
    if (!birthdate) {
      return '';
    }

    const date = new Date(birthdate.year, birthdate.month - 1, birthdate.day);
    return day(date).format('dddd, MMMM').toUpperCase();
  }

  birthdateBtmNum(birthdate: date_pb.Date.AsObject) {
    if (!birthdate) {
      return '';
    }

    const date = new Date(birthdate.year, birthdate.month - 1, birthdate.day);

    return day(date).format('D');
  }

  birthdateBtmNumSuffix(birthdate: date_pb.Date.AsObject) {
    if (!birthdate) {
      return '';
    }

    const date = new Date(birthdate.year, birthdate.month - 1, birthdate.day);

    const num = day(date).format('D');
    const dayWithOrdinal = day(date).format('Do');

    return dayWithOrdinal.substr(num.length);
  }

  birthdayPersonNames(): string {
    if (!this.content) return '';

    const personList = this.content.birthdayPersonList;
    let names = personList
      .slice(0, 6)
      .map(p => p.displayName)
      .join(', ');

    if (personList.length > MAX_DISPLAY_PERSON) {
      names += '... +' + (personList.length - MAX_DISPLAY_PERSON) + ' More';
    }

    return names;
  }

  displayBirthdayList() {
    if (!this.content) return [];
    return this.content.birthdayPersonList.slice(0, MAX_DISPLAY_PERSON);
  }

  openMemberListOverlay() {
    const key = this.contextHash || this.contentHash;
    if (key && this.content) {
      const data = new gateway.content_views_pb.ContentView().toObject();
      data.longBirthdayCard = this.content;
      this.consumableData.storeData(key, data);

      // open the overlay
      this.router.navigate([
        '',
        { outlets: { o: ['view', 'birthday~people', 'today', key] } },
      ]);
    } else {
      console.error(
        `openMemberListOverlay() missing content, contextHash or contentHash`,
      );
    }
  }
}
