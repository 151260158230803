import { day } from 'libs/day';
import { PmReportsFilters } from 'libs/domain';
import { GroupType, getGroupRangeByDate } from 'libs/domain';
import { points_pb } from 'libs/generated-grpc-ts';
import { $enum } from 'ts-enum-util';

import { dateObjectToDateTimeMessage, dateTimeMessageToDayjsObject } from '../';

export const toProto = (
  filters: PmReportsFilters,
  limit?: number,
  offset?: number,
): points_pb.PointsReportFilters => {
  const filterProto = new points_pb.PointsReportFilters();

  if (filters.startDate)
    filterProto.setStartDate(
      dateObjectToDateTimeMessage(filters.startDate.toDate()),
    );
  if (filters.endDate)
    filterProto.setEndDate(
      dateObjectToDateTimeMessage(filters.endDate.toDate()),
    );
  filterProto.setGradeList(filters.grades);
  if (filters.startDate && filters.endDate)
    filterProto.setGroupBy(
      getGroupRangeByDate(filters.startDate, filters.endDate),
    );
  if (filters.issuedTo) filterProto.setIssuedToList(filters.issuedTo);
  if (offset) filterProto.setOffset(offset);
  if (limit) filterProto.setLimit(limit);
  filterProto.setTeamsList(filters.teams);
  filterProto.setRewardList(filters.rewards);
  filterProto.setUserListList(filters.userList);
  return filterProto;
};

export const fromProto = (
  proto: points_pb.PointsReportFilters,
): PmReportsFilters & {
  offset?: number;
  limit?: number;
  groupBy?: GroupType;
} => {
  const startDateProto = proto.getStartDate();
  const endDateProto = proto.getEndDate();
  let startDate: day.Dayjs | undefined;
  let endDate: day.Dayjs | undefined;
  if (startDateProto) startDate = dateTimeMessageToDayjsObject(startDateProto);
  if (endDateProto) endDate = dateTimeMessageToDayjsObject(endDateProto);
  return {
    startDate,
    endDate,
    grades: proto.getGradeList(),
    issuedTo: proto.getIssuedToList(),
    teams: proto.getTeamsList(),
    rewards: proto.getRewardList(),
    offset: proto.getOffset(),
    limit: proto.getLimit(),
    groupBy: $enum(GroupType).asValueOrThrow(proto.getGroupBy()),
    userList: proto.getUserListList(),
  };
};
