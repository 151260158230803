import { Pipe, PipeTransform } from '@angular/core';

import { Student } from '../types/tt-my-class.types';

@Pipe({
  name: 'studentSort',
})
export class StudentSortPipe implements PipeTransform {
  transform(students: Student[], sortByFirstName: boolean): Student[] {
    if (!students || students.length === 0) {
      return [];
    }

    const sortFn = sortByFirstName
      ? (a, b) => a.firstName.localeCompare(b.firstName)
      : (a, b) => a.lastName.localeCompare(b.lastName);

    students.sort(sortFn);
    return students;
  }
}
