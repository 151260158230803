import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import * as day from 'dayjs';
import {
  FlexCheckInStatuses,
  FlexTimeActivityInstance,
  FlexTimePeriod,
  FtmReportFilterType,
} from 'libs/domain';
import { ReportTypes } from 'libs/domain';
import {
  BehaviorSubject,
  Observable,
  ReplaySubject,
  combineLatest,
} from 'rxjs';
import { map, take, takeUntil } from 'rxjs/operators';

import { CarouselStylePresets } from '@shared/components/tiles-carousel';
import {
  FlexTimeActivityInstanceService,
  FlexTimePeriodService,
} from '@shared/services/flex-time';
import { MediaBreakpoints, MediaService } from '@shared/services/media';
import {
  defaultFilteringNavigationBehaviors,
  getDateFromQueryParam,
  getQueryParamFromDate,
  getQueryParamsFromDateRange,
} from '@shared/utils';

import {
  FlexTimeManagerMessages,
  FlexTimeManagerRoutes,
} from '../../constants';
import { FtmReportsService } from '../ftm-reports/services';
import { FlexTimePeriodTile, FtmDashboardMessages } from './constants';
import { FlexInstanceTile, FtmDashboardService } from './services';

const getActivitiesFromPeriods = (
  periods: FlexTimePeriod[],
): FlexTimeActivityInstance[] => {
  return (periods || []).reduce((acc, period) => {
    return acc.concat(period.activityInstances || []);
  }, []);
};

@Component({
  selector: 'mg-ftm-dashboard',
  templateUrl: './ftm-dashboard.component.html',
  styleUrls: ['./ftm-dashboard.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FtmDashboardComponent implements OnDestroy, AfterViewInit {
  /** Constants */
  public readonly FTM_MESSAGES = FlexTimeManagerMessages;
  public readonly MESSAGES = FtmDashboardMessages;
  public readonly CAROUSEL_PRESETS = CarouselStylePresets;

  /** Behavior Subjects */
  private readonly _selectedTeacherHashSubj = new BehaviorSubject<string>('');
  public readonly selectedTeacherHash$ =
    this._selectedTeacherHashSubj.asObservable();

  /** General Observables */
  public readonly teacherHasActivity$: Observable<boolean>;
  private readonly _destroyed = new ReplaySubject<void>(1);

  /** Date */
  public readonly todaysDate = day().format('MMM Do, YYYY');

  public activeDate$: Observable<day.Dayjs> = this._route.queryParams.pipe(
    map(params => {
      return getDateFromQueryParam(params);
    }),
  );

  public presetCarouselLayout$: Observable<CarouselStylePresets> =
    this._media.breakpoint$.pipe(
      map(breakpoint => {
        const isMobile: MediaBreakpoints[] = ['xsmall', 'small'];
        const isTablet: MediaBreakpoints[] = ['medium', 'medium-large'];

        if (isMobile.includes(breakpoint)) {
          return CarouselStylePresets.TWO_X_THREE;
        }
        if (isTablet.includes(breakpoint)) {
          return CarouselStylePresets.THREE_X_THREE;
        }

        return CarouselStylePresets.SIX_X_TWO;
      }),
    );

  public readonly periodOptions$ = this.ftmDashboard.flexPeriods$.pipe(
    map(periods => this._flexPeriods.formatPeriodsOptions(periods)),
  );

  public activityInstances$: Observable<FlexInstanceTile[]> = combineLatest([
    this.ftmDashboard.flexPeriods$,
    this.ftmDashboard.periodFilter$,
  ]).pipe(
    map(([flexPeriods, periodFilter]) => {
      if (!flexPeriods.length) return [];

      const filtered = periodFilter
        ? flexPeriods.filter(p => periodFilter === p.id)
        : flexPeriods;

      const activityInstances = getActivitiesFromPeriods(filtered);

      return activityInstances.map(a => {
        return this.ftmDashboard.mapInstanceToTile(
          a,
          flexPeriods.find(p => a.flexTimePeriodId === p.id),
        );
      });
    }),
  );

  public periodTiles$ = this.ftmDashboard.flexPeriods$.pipe(
    map(periods => {
      const periodsMapped = (periods || []).map(p => {
        const { totalRegistered, totalAbsentees } =
          this._flexInstancesService.getTotalCounts(p.activityInstances);
        const tile: FlexTimePeriodTile = {
          id: p.id,
          name: p.title,
          count: totalRegistered,
          absentees: totalAbsentees,
          date: day(p.date).format('DD/MM/YYYY'),
          startTime: p.startTime,
          endTime: p.endTime,
          totalSpaces: p.totalSpaces,
          dateObject: day(p.date),
        };

        return tile;
      });

      return periodsMapped;
    }),
  );

  /** Component Constructor */
  constructor(
    public ftmDashboard: FtmDashboardService,
    private _media: MediaService,
    private _flexPeriods: FlexTimePeriodService,
    private _ftmReports: FtmReportsService,
    private _router: Router,
    private _flexInstancesService: FlexTimeActivityInstanceService,
    private _route: ActivatedRoute,
  ) {
    this.activeDate$.pipe(takeUntil(this._destroyed)).subscribe(date => {
      this.ftmDashboard.fetchData(true, date);
    });

    this.teacherHasActivity$ = combineLatest([
      this.selectedTeacherHash$,
      this.activityInstances$,
    ]).pipe(
      map(([teacherHash, activityList]) => {
        if (!teacherHash) return true;

        return !!activityList.find(activity => {
          return activity.teacherHash === teacherHash;
        });
      }),
    );
  }

  ngAfterViewInit() {
    this.periodTiles$.pipe(takeUntil(this._destroyed)).subscribe(periods => {
      if (periods?.length > 0) {
        const firstPeriod = periods[0];
        this.setActivePeriod(firstPeriod.id);
      }
    });
  }

  ngOnDestroy(): void {
    this._destroyed.next();
    this._destroyed.complete();
  }

  public async setActivePeriod(periodId?: number): Promise<void> {
    if (!periodId) return;
    this.ftmDashboard.setPeriodFilter(periodId);
  }

  public async goToReports({
    periodId,
    activityId,
    goToAbsents,
    goToCheckedIn,
    goToUnregistered,
  }: {
    periodId?: number;
    activityId?: number;
    goToAbsents?: boolean;
    goToCheckedIn?: boolean;
    goToUnregistered?: boolean;
  }) {
    if (periodId) {
      this._ftmReports.setFilter(FtmReportFilterType.PERIODS, periodId);
    }
    if (activityId) {
      this._ftmReports.setFilter(FtmReportFilterType.ACTIVITIES, activityId);
    }

    if (goToAbsents) {
      this._ftmReports.setFilter(
        FtmReportFilterType.CHECKIN_STATUS,
        FlexCheckInStatuses.ABSENT,
      );
    }

    if (goToCheckedIn) {
      this._ftmReports.setFilter(
        FtmReportFilterType.CHECKIN_STATUS,
        FlexCheckInStatuses.IN,
      );
    }

    let route: string[];
    if (!goToUnregistered) {
      route = [FlexTimeManagerRoutes.ROOT, FlexTimeManagerRoutes.REPORTS];
    } else {
      route = [
        FlexTimeManagerRoutes.ROOT,
        FlexTimeManagerRoutes.REPORTS,
        ReportTypes.FLEX_PERIOD_UNREGISTERED,
      ];
    }

    const date = await this.activeDate$.pipe(take(1)).toPromise();
    const startDate = day(date).startOf('day');
    const endDate = day(date).endOf('day');

    const filters: Record<string, any> = {};
    if (periodId) filters[FtmReportFilterType.PERIODS] = [periodId];
    if (activityId) filters[FtmReportFilterType.ACTIVITIES] = [activityId];

    const queryParams = {
      ...getQueryParamsFromDateRange({ startDate, endDate }),
      filters: JSON.stringify(filters),
    };

    this._router.navigate(route, {
      queryParams,
    });
  }

  public onTeacherSelectChange(teacher?: any) {
    if (!teacher) this._selectedTeacherHashSubj.next('');
    else this._selectedTeacherHashSubj.next(teacher);
  }

  public dateChanged(date: day.Dayjs) {
    this._router.navigate([], {
      queryParams: getQueryParamFromDate(date),
      ...defaultFilteringNavigationBehaviors,
    });
  }
}
