import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule } from '@angular/forms';
import { MatProgressBarModule } from '@angular/material/progress-bar';

import { FileInputModule } from '@app/src/app/components/Input/FileInput';
import { MgMessageBoxModule } from '@app/src/app/components/MessageBox';

import { XlsListUploaderModule } from '@shared/components/XlsListUploader';
import { ListUploaderSummaryModule } from '@shared/components/XlsListUploader/ListUploaderSummary';
import { UpdatePeopleAdditionalFormFieldsModule } from '@shared/components/update-people-by-list-dialog/components/update-people-additional-form-fields/update-people-additional-form-fields.module';

import { GenericButtonModule } from '../button';
import { ModalOverlayService, OverlayModule } from '../modal-overlay';
import { UpdatePeopleByListComponent } from './update-people-by-list-dialog.component';

@NgModule({
  declarations: [UpdatePeopleByListComponent],
  imports: [
    UpdatePeopleAdditionalFormFieldsModule,
    ListUploaderSummaryModule,
    FileInputModule,
    XlsListUploaderModule,
    OverlayModule,
    GenericButtonModule,
    MgMessageBoxModule,

    CommonModule,
    FlexLayoutModule,
    FormsModule,
    MatProgressBarModule,
  ],
  providers: [ModalOverlayService],
  exports: [UpdatePeopleByListComponent],
})
export class UpdatePeopleByListModule {}
