import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
  TrackByFunction,
} from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';

import { grpc } from '@improbable-eng/grpc-web';
import { MingaPermission } from 'libs/domain';
import { gateway } from 'libs/generated-grpc-web';
import { mingaSettingTypes } from 'libs/util';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { SaveCancelDialog } from '@app/src/app/dialog';
import { MgValidators } from '@app/src/app/input/validators';
import { AuthService } from '@app/src/app/minimal/services/Auth';
import { AuthInfoService } from '@app/src/app/minimal/services/AuthInfo';
import { RootService } from '@app/src/app/minimal/services/RootService';
import { PermissionsService } from '@app/src/app/permissions';
import { PeopleManagerService } from '@app/src/app/services/PeopleManager';
import {
  ModalOverlayService,
  ModalOverlayServiceCloseEventType,
} from '@app/src/app/shared/components/modal-overlay';
import {
  SystemAlertCloseEvents,
  SystemAlertModalService,
  SystemAlertModalType,
} from '@app/src/app/shared/components/system-alert-modal';
import {
  MingaSettingsService,
  MingaStoreFacadeService,
} from '@app/src/app/store/Minga/services';

import { StudentIdUploaderDialogService } from '@shared/components/student-id-uploader-dialog';
import { SystemAlertSnackBarService } from '@shared/components/system-alert-snackbar';
import { IdStudentData } from '@shared/components/view-id';
import { ViewIdService } from '@shared/components/view-id/services/view-id.service';
import { StudentIdImageService } from '@shared/services/student-id-image/StudentIdImage.service';

import {
  USER_PREFERENCES_FIELDS,
  USER_PREFERENCES_PASSWORD_REQUIREMENTS,
} from '../../constants/user-preferences.constants';
import { IUserPreference, UserPreferencesService } from '../../services';

interface IGroupedUserPreference {
  category: string;
  preferences: IUserPreference[];
}

@Component({
  templateUrl: './UserPreferencesRoute.component.html',
  styleUrls: ['./UserPreferencesRoute.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserPreferencesRouteComponent implements OnInit {
  readonly person$ = this._authInfo.authPerson$;
  readonly groupedUserPreferences$: Observable<IGroupedUserPreference[]>;

  readonly ssoInfo$ = this._authInfo.linkedSsoProviderInfo$;

  readonly districtFeatureEnabled$: Observable<boolean>;

  public readonly canUserDeleteAccount$ =
    this._settingService.getSettingValueObs(
      mingaSettingTypes.FEATURE_ALLOW_ACCOUNT_DELETION,
    );

  public passwordForm = new FormGroup(
    {
      currentPassword: new FormControl(''),
      newPassword: new FormControl('', [this._passwordRequirementsValidator()]),
      confirmPassword: new FormControl(''),
    },
    { validators: this._passwordMatchValidator() },
  );

  public person = this._authInfo.authPerson;
  public overwriteURL: string;

  public FIELDS = USER_PREFERENCES_FIELDS;
  public PASSWORD_REQUIREMENTS = USER_PREFERENCES_PASSWORD_REQUIREMENTS;

  groupTrackBy: TrackByFunction<IGroupedUserPreference> = (
    index: number,
    group: IGroupedUserPreference,
  ) => group.category;
  preferenceTrackBy: TrackByFunction<IUserPreference> = (
    index: number,
    preference: IUserPreference,
  ) => preference.id;

  constructor(
    private _userPreferences: UserPreferencesService,
    private _rootService: RootService,
    private _authService: AuthService,
    private _authInfo: AuthInfoService,
    mingaStore: MingaStoreFacadeService,
    private _dialog: MatDialog,
    private _permissions: PermissionsService,
    private _systemAlertSnackBar: SystemAlertSnackBarService,
    private _cdr: ChangeDetectorRef,
    private _settingService: MingaSettingsService,
    public profileService: gateway.profile_ng_grpc_pb.ProfileService,
    private _peopleManager: PeopleManagerService,
    private _idUploader: StudentIdUploaderDialogService,
    private _viewId: ViewIdService,
    private _idPictureService: StudentIdImageService,
    private _modalService: SystemAlertModalService,
  ) {
    this.districtFeatureEnabled$ = mingaStore.observeDistrictFeatureEnabled();
    this.groupedUserPreferences$ = this._userPreferences.userPreferences$.pipe(
      map(preferences => {
        const groupPrefs: IGroupedUserPreference[] = [];
        const groupPrefMap = new Map<string, IUserPreference[]>();

        // group preferences by category
        for (const pref of preferences) {
          const currentCat = groupPrefMap.get(pref.category);
          // add new category group if doesn't yet exist
          if (!currentCat) {
            groupPrefMap.set(pref.category, [pref]);
          } else {
            // add pref to existing category
            currentCat.push(pref);
            groupPrefMap.set(pref.category, currentCat);
          }
        }
        // convert to array
        groupPrefMap.forEach((preferences, category) => {
          groupPrefs.push({ category, preferences });
        });

        return groupPrefs;
      }),
    );
  }

  async ngOnInit() {
    await this._userPreferences.fetchIfNeeded();
  }

  public async updatePassword() {
    try {
      const request = new gateway.profile_pb.UpdateProfileRequest();
      request.setNewPassword(this.passwordForm.get('newPassword').value);
      request.setOldPassword(this.passwordForm.get('currentPassword').value);
      request.setPersonHash(this._authInfo.authPersonHash);

      const response = await this._rootService.addLoadingPromise(
        this.profileService.updateProfile(request),
      );

      // don't want to completely get rid of legacy error handling, but in most cases this is bypassed by the catch block
      if (response.getError()) {
        this._systemAlertSnackBar.error(response.getError());
      } else {
        this.passwordForm.reset();
        this._systemAlertSnackBar.success('Successfully changed password');
      }
    } catch (err) {
      const message =
        err.message || 'Failed to update password, try again later';
      const code = err.code || 2;

      //current password incorrect
      if (code === grpc.Code.InvalidArgument) {
        this.passwordForm
          .get('currentPassword')
          .setErrors({ incorrect: this.PASSWORD_REQUIREMENTS.ERROR.INVALID });

        this._cdr.markForCheck();
      }

      this._systemAlertSnackBar.error(message);
    }
  }

  public async changePhoto() {
    // if the ID fetch fails, assume they have no profile picture
    let id: IdStudentData;
    try {
      // have to get id here, because the auth service gives everyone a default profile picture; there's no way to tell if they've uploaded a photo without using the ID
      id = await this._viewId.fetchStudentId(this._authInfo.authPersonHash);
    } catch (err) {
      this._systemAlertSnackBar.error('There was an error fetching ID data');
    }

    const dialogRef = this._idUploader.open({
      data: {
        personHash: this._authInfo.authPersonHash,
        currentImage: id?.idPhoto || '',
      },
    });
    dialogRef.afterClosed.subscribe(async response => {
      if (response?.data) {
        const fileName = response.data?.fileName;
        try {
          await this._viewId.uploadStudentIdPhotoChange(
            this._authInfo.authPersonHash,
            fileName,
          );

          let processedImage: Blob;
          if (fileName) {
            processedImage = await this._viewId.processStudentIdPhoto(fileName);
          }

          const idPhoto = processedImage
            ? URL.createObjectURL(processedImage)
            : '';

          this.overwriteURL = idPhoto;

          this._cdr.markForCheck();
          this._cdr.detectChanges();

          this._systemAlertSnackBar.success('Photo uploaded successfully');
        } catch (_) {
          this._systemAlertSnackBar.error(
            'There was an error uploading the photo',
          );
        }
      }
    });
  }

  private async _updatePreference(
    preference: IUserPreference,
    value: string | boolean,
  ) {
    await this._userPreferences.updatePreference(preference.id, value);
  }

  preferenceValueChange(preference: IUserPreference, value: string | boolean) {
    this._updatePreference(preference, value);
  }

  public async launchArchiveAccountConfDialog() {
    // There are two required prompts to delete your account
    const systemAlertModal = await this._modalService.open({
      modalType: SystemAlertModalType.WARNING,
      heading: 'Delete account',
      message: 'Are you sure you want to delete your account?',
      confirmActionBtn: 'Delete',
      closeBtn: 'Cancel',
    });

    const { type } = await systemAlertModal.afterClosed().toPromise();

    if (type === SystemAlertCloseEvents.CONFIRM) {
      // second prompt
      const finalConfirmModal = await this._modalService.open({
        modalType: SystemAlertModalType.WARNING,
        heading: 'Are you absolutely sure you want to delete your account?',
        message: 'This action cannot be undone.',
        confirmActionBtn: 'Delete',
        closeBtn: 'Cancel',
      });

      const { type } = await finalConfirmModal.afterClosed().toPromise();

      if (type === SystemAlertCloseEvents.CONFIRM) {
        // delete account
        this._archiveAccount();
      }
    }
  }

  private _archiveAccount = () => {
    if (this._authInfo.authPersonHash) {
      this._peopleManager.selfArchivePerson(this._authInfo.authPersonHash);
    }
  };

  private _passwordMatchValidator(): ValidatorFn {
    return (form: FormGroup): ValidationErrors | null => {
      const newPassword = form.get('newPassword');
      const confirmPassword = form.get('confirmPassword');

      if (
        newPassword &&
        confirmPassword &&
        newPassword.value !== confirmPassword.value
      ) {
        confirmPassword.setErrors({
          mismatch: USER_PREFERENCES_PASSWORD_REQUIREMENTS.ERROR.MISMATCH,
        });
        return {
          mismatch: USER_PREFERENCES_PASSWORD_REQUIREMENTS.ERROR.MISMATCH,
        };
      } else {
        confirmPassword.setErrors(null);
        return null;
      }
    };
  }

  // creating a custom validator for password requirements because the built-in validators don't allow for custom error messages
  private _passwordRequirementsValidator(): ValidatorFn {
    return (control: FormControl): ValidationErrors | null => {
      const value = control.value;
      if (!value) {
        return {
          empty: USER_PREFERENCES_PASSWORD_REQUIREMENTS.ERROR.REQUIREMENT,
        };
      }

      // 8 characters or more
      if (value.length < 8) {
        return {
          minLength: USER_PREFERENCES_PASSWORD_REQUIREMENTS.ERROR.REQUIREMENT,
        };
      }

      // at least one letter, one number, and one symbol
      if (!value.match(MgValidators.AtLeastOneLetterOneNumberOneSymbolRegex)) {
        return {
          oneNumberOneSymbol:
            USER_PREFERENCES_PASSWORD_REQUIREMENTS.ERROR.REQUIREMENT,
        };
      }

      return null;
    };
  }
}
