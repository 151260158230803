// GENERATED CODE -- DO NOT EDIT!

import {grpc} from '@improbable-eng/grpc-web';

import * as gateway_search_pb from '../gateway/search_pb';
import * as common_stream_pb from '../common/stream_pb';
import * as gateway_feed_pb from '../gateway/feed_pb';

export class SearchManager {
  static readonly serviceName = "SearchManager";

  static readonly search = {
    methodName: "search",
    service: SearchManager,
    requestStream: false,
    responseStream: true,
    requestType: common_stream_pb.StreamID,
    responseType: gateway_search_pb.SearchResponse,
  };

  static readonly searchControl = {
    methodName: "searchControl",
    service: SearchManager,
    requestStream: false,
    responseStream: false,
    requestType: common_stream_pb.StreamControl,
    responseType: common_stream_pb.StreamControlResponse,
  };

}

export class SearchManagerClient {
  search() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  searchControl() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
}
