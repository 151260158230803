import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';

import { MgPipesModule } from '@app/src/app/pipes';

import { SortHeaderModule } from '@shared/directives/sort-header/sort-header.module';
import { CommonUIModules } from '@shared/modules';

import { CollapsibleModule } from '../collapsible';
import { FormModule } from '../form';
import { MultiPersonSearchModule } from '../multi-person-search';
import { PaginatorModule } from '../paginator';
import { ScheduleWidgetModule } from '../student-schedule-widget/student-schedule-widget.module';
import { StudentScheduleComponent } from './student-schedule.component';

@NgModule({
  declarations: [StudentScheduleComponent],
  exports: [StudentScheduleComponent],
  imports: [
    // Common dependencies
    CommonModule,
    CommonUIModules,

    // Minga depdenecies
    CollapsibleModule,
    MultiPersonSearchModule,
    FormModule,
    PaginatorModule,
    SortHeaderModule,
    ScheduleWidgetModule,
    MgPipesModule,

    // External dependencies
    MatTableModule,
    MatSortModule,
  ],
})
export class StudentScheduleModule {}
