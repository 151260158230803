import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { BarcodeScanner } from '@app/src/app/barcodeScanner';

import {
  SystemAlertModalHangTime,
  SystemAlertModalService,
  SystemAlertModalType,
} from '@shared/components/system-alert-modal';
import { SystemAlertSnackBarService } from '@shared/components/system-alert-snackbar';
import { AutomationService } from '@shared/services/automation';

import { PeopleSelectorService } from '../people-selector.service';
import { PeopleSelectorFormService } from '../ps-form.service';
import { PsCollectionSearchImplService } from '../search-impl/ps-collection-search.impl.service';

@Injectable()
export class PsAutomationService extends PeopleSelectorFormService<'Automation'> {
  /** Service Constructor */
  constructor(
    public router: Router,
    public snackbar: SystemAlertSnackBarService,
    public barCodeScanner: BarcodeScanner,
    public peopleSelector: PeopleSelectorService,
    private _psCollectionSearch: PsCollectionSearchImplService,
    private _automationService: AutomationService,
    private _systemModal: SystemAlertModalService,
  ) {
    super(
      {
        name: 'Automation',
        pageDefinitions: {
          reset: {
            submitFn: async => this.submitReset(),
            searchFn: async =>
              this._psCollectionSearch.collectionSearch('text', undefined),
          },
        },
      },
      router,
      snackbar,
      barCodeScanner,
      peopleSelector,
    );
    //stupid fix because webpack is throwing an error when using arrow
    // functions with parameters in the super call.
    this.setConfig({
      name: 'Automation',
      pageDefinitions: {
        reset: {
          submitFn: async () => this.submitReset(),
          searchFn: async (type, filters) =>
            this._psCollectionSearch.collectionSearch(type, filters),
        },
      },
    });
  }

  public async submitReset(): Promise<void> {
    const { id } = this.data;
    const selectedHashes = this.selection.getSelectionHashes();
    try {
      await this._automationService.resetAutomationCounts(selectedHashes, id);
      const modalRef = await this._systemModal.open({
        modalType: SystemAlertModalType.SUCCESS,
        heading: `Success!`,
        message: `Resetted counters for ${selectedHashes.length} people`,
        hangTime: SystemAlertModalHangTime.LONG,
      });
      await modalRef.afterClosed().toPromise();
    } catch (error) {
      this.snackbar.open({
        message: 'Failed to reset automations stats',
        type: 'error',
      });
    }
  }
}
