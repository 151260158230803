import { Injectable, NgZone } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { grpc } from '@improbable-eng/grpc-web';

import {
  FtueMarkSeenRequest,
  FtueMarkSeenResponse,
  UserPreferencesGetRequest,
  UserPreferencesGetResponse,
  UserPreferencesMetadataRequest,
  UserPreferencesMetadataResponse,
  UserPreferencesResetRequest,
  UserPreferencesResetResponse,
  UserPreferencesUpdateRequest,
  UserPreferencesUpdateResponse,
} from '../gateway/preferences_pb';

import {
  UserPreferences as __UserPreferences,
} from '../gateway/preferences_pb_service';

@Injectable({providedIn: 'root'})
export class UserPreferences {

  constructor(private _ngZone: NgZone) {
  }

  metadata(request: UserPreferencesMetadataRequest): Promise<UserPreferencesMetadataResponse>;
  metadata(request: UserPreferencesMetadataRequest, metadata: grpc.Metadata): Promise<UserPreferencesMetadataResponse>;
  metadata(request: UserPreferencesMetadataRequest, callback: (err: any|null, response: UserPreferencesMetadataResponse, metadata: grpc.Metadata) => void): void;
  metadata(request: UserPreferencesMetadataRequest, metadata: grpc.Metadata, callback: (err: any|null, response: UserPreferencesMetadataResponse, metadata: grpc.Metadata) => void): void;

  metadata(request: UserPreferencesMetadataRequest, arg1?: grpc.Metadata|((err: any|null, response: UserPreferencesMetadataResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: UserPreferencesMetadataResponse, metadata: grpc.Metadata) => void): Promise<UserPreferencesMetadataResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<UserPreferencesMetadataResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__UserPreferences.Metadata, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  get(request: UserPreferencesGetRequest): Promise<UserPreferencesGetResponse>;
  get(request: UserPreferencesGetRequest, metadata: grpc.Metadata): Promise<UserPreferencesGetResponse>;
  get(request: UserPreferencesGetRequest, callback: (err: any|null, response: UserPreferencesGetResponse, metadata: grpc.Metadata) => void): void;
  get(request: UserPreferencesGetRequest, metadata: grpc.Metadata, callback: (err: any|null, response: UserPreferencesGetResponse, metadata: grpc.Metadata) => void): void;

  get(request: UserPreferencesGetRequest, arg1?: grpc.Metadata|((err: any|null, response: UserPreferencesGetResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: UserPreferencesGetResponse, metadata: grpc.Metadata) => void): Promise<UserPreferencesGetResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<UserPreferencesGetResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__UserPreferences.Get, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  update(request: UserPreferencesUpdateRequest): Promise<UserPreferencesUpdateResponse>;
  update(request: UserPreferencesUpdateRequest, metadata: grpc.Metadata): Promise<UserPreferencesUpdateResponse>;
  update(request: UserPreferencesUpdateRequest, callback: (err: any|null, response: UserPreferencesUpdateResponse, metadata: grpc.Metadata) => void): void;
  update(request: UserPreferencesUpdateRequest, metadata: grpc.Metadata, callback: (err: any|null, response: UserPreferencesUpdateResponse, metadata: grpc.Metadata) => void): void;

  update(request: UserPreferencesUpdateRequest, arg1?: grpc.Metadata|((err: any|null, response: UserPreferencesUpdateResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: UserPreferencesUpdateResponse, metadata: grpc.Metadata) => void): Promise<UserPreferencesUpdateResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<UserPreferencesUpdateResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__UserPreferences.Update, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  reset(request: UserPreferencesResetRequest): Promise<UserPreferencesResetResponse>;
  reset(request: UserPreferencesResetRequest, metadata: grpc.Metadata): Promise<UserPreferencesResetResponse>;
  reset(request: UserPreferencesResetRequest, callback: (err: any|null, response: UserPreferencesResetResponse, metadata: grpc.Metadata) => void): void;
  reset(request: UserPreferencesResetRequest, metadata: grpc.Metadata, callback: (err: any|null, response: UserPreferencesResetResponse, metadata: grpc.Metadata) => void): void;

  reset(request: UserPreferencesResetRequest, arg1?: grpc.Metadata|((err: any|null, response: UserPreferencesResetResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: UserPreferencesResetResponse, metadata: grpc.Metadata) => void): Promise<UserPreferencesResetResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<UserPreferencesResetResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__UserPreferences.Reset, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  ftueMarkSeen(request: FtueMarkSeenRequest): Promise<FtueMarkSeenResponse>;
  ftueMarkSeen(request: FtueMarkSeenRequest, metadata: grpc.Metadata): Promise<FtueMarkSeenResponse>;
  ftueMarkSeen(request: FtueMarkSeenRequest, callback: (err: any|null, response: FtueMarkSeenResponse, metadata: grpc.Metadata) => void): void;
  ftueMarkSeen(request: FtueMarkSeenRequest, metadata: grpc.Metadata, callback: (err: any|null, response: FtueMarkSeenResponse, metadata: grpc.Metadata) => void): void;

  ftueMarkSeen(request: FtueMarkSeenRequest, arg1?: grpc.Metadata|((err: any|null, response: FtueMarkSeenResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: FtueMarkSeenResponse, metadata: grpc.Metadata) => void): Promise<FtueMarkSeenResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<FtueMarkSeenResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__UserPreferences.FtueMarkSeen, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

}

