import { BinaryWriter } from 'google-protobuf';
import { INewNotification } from 'libs/domain';
import { realtime_pb } from 'libs/generated-grpc-ts';

import { asObjectToMessage } from '../util/message';

export namespace INewNotificationMapper {
  export function fromBase64(b64: string): INewNotification {
    const proto = realtime_pb.NewNotification.deserializeBinary(b64);
    return fromProto(proto);
  }

  export function toBase64(newDm: INewNotification) {
    const binaryWriter = new BinaryWriter();
    realtime_pb.NewNotification.serializeBinaryToWriter(
      toProto(newDm),
      binaryWriter,
    );
    return binaryWriter.getResultBase64String();
  }

  export function fromProto(
    msg: realtime_pb.NewNotification,
  ): INewNotification {
    return {
      ...msg.toObject(),
    };
  }

  export function toProto(
    newDm: INewNotification,
  ): realtime_pb.NewNotification {
    const msg = new realtime_pb.NewNotification();
    return asObjectToMessage(newDm, msg);
  }
}
