import { CheckinReasonType, ICheckinReasonWithCounts, Restriction } from 'libs/domain';
import { MingaRoleType } from 'libs/domain';
import { checkin_pb } from 'libs/generated-grpc-ts';
import { Grade } from 'libs/util';
import { $enum } from 'ts-enum-util';

import { MembershipListMapper } from '../membership_list';
import { RestrictionMapper } from '../restriction';

export const toProto = (
  list: ICheckinReasonWithCounts,
): checkin_pb.CheckinReason => {
  const msg = new checkin_pb.CheckinReason();
  if (list.id) msg.setId(list.id);
  if (list.name) msg.setName(list.name);
  if (list.createdBy) msg.setCreatedBy(list.createdBy);
  if (list.mingaId) msg.setMingaId(list.mingaId);
  if (list.active) msg.setActive(list.active);
  if (list.icon) msg.setIcon(list.icon as any);
  if (list.pointReward != null) msg.setPointReward(list.pointReward);
  if (list.stickerIds) msg.setStickerIdsList(list.stickerIds);
  if (list.numberOfAbsentees) msg.setNumberOfAbsentees(list.numberOfAbsentees);
  if (list.totalCheckinCount) msg.setTotalCheckinCount(list.totalCheckinCount);
  if (list.roles) msg.setRolesList(list.roles.map(r => MingaRoleType[r]));
  if (list.userLists) msg.setUserListsList(list.userLists);
  if (list.groupHashes) msg.setMingagrouphashesList(list.groupHashes);
  if (list.blockNoAccess) msg.setBlockNoAccess(list.blockNoAccess);
  if (list.priority) msg.setPriority(list.priority);
  if (list.color) msg.setColor(list.color);
  if (list.hallPassId) msg.setHallPassId(list.hallPassId);
  if (list.behaviorId) msg.setBehaviorId(list.behaviorId);
  msg.setAutoEndHallPass(list.autoEndHallPass || false);
  msg.setShowAbsentees(list.showAbsentees);
  msg.setSelfCheckIn(list.selfCheckIn);
  msg.setAllowMultipleCheckins(list.allowMultipleCheckins || false);
  if (list.grades)
    msg.setGradesList(list.grades.map(g => $enum(Grade).asValueOrThrow(g)));
  if (list.restrictedCheckinReasonId)
    msg.setRestrictedCheckinReasonId(list.restrictedCheckinReasonId);
  if (list.consequenceId) msg.setConsequenceId(list.consequenceId);
  if (list.allowCheckout) msg.setAllowCheckout(list.allowCheckout);
  msg.setCheckinReasonType(list.checkinReasonType);
  if (list.membershipList)
    msg.setMembershipList(MembershipListMapper.toProto(list.membershipList));
  if (list.restrictionId) msg.setRestrictionId(list.restrictionId);
  if (list.availableInKiosk) msg.setAvailableInKiosk(list.availableInKiosk);

  if (list.restriction) {
    msg.setRestriction(RestrictionMapper.toProto(list.restriction));
  }

  msg.setEnableStudentPhoto(list.enableStudentPhoto || false);
  return msg;
};

export const fromProto = (
  msg: checkin_pb.CheckinReason,
): ICheckinReasonWithCounts => {
  let restriction: Restriction | undefined;
  if (msg.getRestriction()) {
    restriction = RestrictionMapper.fromProto(msg.getRestriction());
  }

  const id = msg.getId();
  const name = msg.getName();
  const createdBy = msg.getCreatedBy();
  const mingaId = msg.getMingaId();
  const active = msg.getActive();
  const stickerIds = msg.getStickerIdsList();
  const icon = msg.getIcon() as any;
  const rolesStrings = msg.getRolesList();
  const roles = rolesStrings.map(r => $enum(MingaRoleType).asValueOrThrow(r));
  const userLists = msg.getUserListsList();
  const gradeStrings = msg.getGradesList();
  const grades = gradeStrings.map(r => $enum(Grade).asValueOrThrow(r));
  const groupHashes = msg.getMingagrouphashesList();
  const blockNoAccess = msg.getBlockNoAccess();
  const restrictedCheckinReasonId = msg.getRestrictedCheckinReasonId();
  const priority = msg.getPriority();
  const pointReward = msg.getPointReward();
  const autoEndHallPass = msg.getAutoEndHallPass();
  const showAbsentees = msg.getShowAbsentees();
  const numberOfAbsentees = msg.getNumberOfAbsentees();
  const totalCheckinCount = msg.getTotalCheckinCount();
  const hallPassId = msg.getHallPassId();
  const behaviorId = msg.getBehaviorId();
  const color = msg.getColor();
  const selfCheckIn = msg.getSelfCheckIn();
  const consequenceId = msg.getConsequenceId();
  const allowCheckout = msg.getAllowCheckout();
  const checkinReasonType = $enum(CheckinReasonType).asValueOrDefault(
    msg.getCheckinReasonType(),
    CheckinReasonType.CHECKIN,
  );
  const allowMultipleCheckins = msg.getAllowMultipleCheckins();
  const membershipList = msg.getMembershipList()
    ? MembershipListMapper.fromProto(msg.getMembershipList())
    : undefined;

  const availableInKiosk = msg.getAvailableInKiosk();

  const enableStudentPhoto = msg.getEnableStudentPhoto();

  return {
    id,
    name,
    createdBy,
    mingaId,
    active,
    stickerIds,
    icon,
    pointReward,
    showAbsentees,
    numberOfAbsentees,
    totalCheckinCount,
    roles,
    userLists,
    grades,
    groupHashes,
    blockNoAccess,
    restrictedCheckinReasonId,
    priority,
    hallPassId: hallPassId || undefined,
    behaviorId: behaviorId || undefined,
    color,
    selfCheckIn,
    consequenceId: consequenceId || undefined,
    allowCheckout,
    checkinReasonType,
    membershipList,
    restrictionId: msg.getRestrictionId(),
    allowMultipleCheckins,
    enableStudentPhoto,
    availableInKiosk,
    autoEndHallPass,
    restriction,
  };
};
