import { Injectable, NgZone } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { grpc } from '@improbable-eng/grpc-web';

import {
  AutomationAction,
  CreateAutomationActionRequest,
  DeleteAutomationActionRequest,
  GetAutomationActionRequest,
  ListAutomationActionsRequest,
  ListAutomationActionsResponse,
  UpdateAutomationActionRequest,
} from '../automation/automation_actions_pb';
import {
  Empty,
} from '../google/protobuf/empty_pb';

import {
  AutomationActions as __AutomationActions,
} from '../automation/automation_actions_pb_service';

@Injectable({providedIn: 'root'})
export class AutomationActions {

  constructor(private _ngZone: NgZone) {
  }

  listAutomationActions(request: ListAutomationActionsRequest): Promise<ListAutomationActionsResponse>;
  listAutomationActions(request: ListAutomationActionsRequest, metadata: grpc.Metadata): Promise<ListAutomationActionsResponse>;
  listAutomationActions(request: ListAutomationActionsRequest, callback: (err: any|null, response: ListAutomationActionsResponse, metadata: grpc.Metadata) => void): void;
  listAutomationActions(request: ListAutomationActionsRequest, metadata: grpc.Metadata, callback: (err: any|null, response: ListAutomationActionsResponse, metadata: grpc.Metadata) => void): void;

  listAutomationActions(request: ListAutomationActionsRequest, arg1?: grpc.Metadata|((err: any|null, response: ListAutomationActionsResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: ListAutomationActionsResponse, metadata: grpc.Metadata) => void): Promise<ListAutomationActionsResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<ListAutomationActionsResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__AutomationActions.ListAutomationActions, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  getAutomationAction(request: GetAutomationActionRequest): Promise<AutomationAction>;
  getAutomationAction(request: GetAutomationActionRequest, metadata: grpc.Metadata): Promise<AutomationAction>;
  getAutomationAction(request: GetAutomationActionRequest, callback: (err: any|null, response: AutomationAction, metadata: grpc.Metadata) => void): void;
  getAutomationAction(request: GetAutomationActionRequest, metadata: grpc.Metadata, callback: (err: any|null, response: AutomationAction, metadata: grpc.Metadata) => void): void;

  getAutomationAction(request: GetAutomationActionRequest, arg1?: grpc.Metadata|((err: any|null, response: AutomationAction, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: AutomationAction, metadata: grpc.Metadata) => void): Promise<AutomationAction>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<AutomationAction>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__AutomationActions.GetAutomationAction, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  createAutomationAction(request: CreateAutomationActionRequest): Promise<AutomationAction>;
  createAutomationAction(request: CreateAutomationActionRequest, metadata: grpc.Metadata): Promise<AutomationAction>;
  createAutomationAction(request: CreateAutomationActionRequest, callback: (err: any|null, response: AutomationAction, metadata: grpc.Metadata) => void): void;
  createAutomationAction(request: CreateAutomationActionRequest, metadata: grpc.Metadata, callback: (err: any|null, response: AutomationAction, metadata: grpc.Metadata) => void): void;

  createAutomationAction(request: CreateAutomationActionRequest, arg1?: grpc.Metadata|((err: any|null, response: AutomationAction, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: AutomationAction, metadata: grpc.Metadata) => void): Promise<AutomationAction>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<AutomationAction>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__AutomationActions.CreateAutomationAction, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  updateAutomationAction(request: UpdateAutomationActionRequest): Promise<AutomationAction>;
  updateAutomationAction(request: UpdateAutomationActionRequest, metadata: grpc.Metadata): Promise<AutomationAction>;
  updateAutomationAction(request: UpdateAutomationActionRequest, callback: (err: any|null, response: AutomationAction, metadata: grpc.Metadata) => void): void;
  updateAutomationAction(request: UpdateAutomationActionRequest, metadata: grpc.Metadata, callback: (err: any|null, response: AutomationAction, metadata: grpc.Metadata) => void): void;

  updateAutomationAction(request: UpdateAutomationActionRequest, arg1?: grpc.Metadata|((err: any|null, response: AutomationAction, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: AutomationAction, metadata: grpc.Metadata) => void): Promise<AutomationAction>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<AutomationAction>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__AutomationActions.UpdateAutomationAction, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  deleteAutomationAction(request: DeleteAutomationActionRequest): Promise<Empty>;
  deleteAutomationAction(request: DeleteAutomationActionRequest, metadata: grpc.Metadata): Promise<Empty>;
  deleteAutomationAction(request: DeleteAutomationActionRequest, callback: (err: any|null, response: Empty, metadata: grpc.Metadata) => void): void;
  deleteAutomationAction(request: DeleteAutomationActionRequest, metadata: grpc.Metadata, callback: (err: any|null, response: Empty, metadata: grpc.Metadata) => void): void;

  deleteAutomationAction(request: DeleteAutomationActionRequest, arg1?: grpc.Metadata|((err: any|null, response: Empty, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: Empty, metadata: grpc.Metadata) => void): Promise<Empty>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<Empty>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__AutomationActions.DeleteAutomationAction, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

}

