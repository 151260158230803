import {
  Component,
  ChangeDetectionStrategy,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { FormGroup } from '@angular/forms';

import {
  FlexCheckInStatuses,
  FlexReportFilters,
  FtmReportFilters,
  FtmReportFilterType,
} from 'libs/domain';
import { BehaviorSubject, Observable, of, ReplaySubject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { $enum } from 'ts-enum-util';

import { Person } from '@app/src/app/people';

import { FtmActivityTemplatesService } from '@modules/flex-time-manager/components/ftm-activity-templates/services';
import { FtmReportsMessages } from '@modules/flex-time-manager/components/ftm-reports/constants';
import {
  ScheduledReportEditFormFields,
  getFilterGroupControlKey,
} from '@modules/minga-manager/components/mm-scheduled-reports/constants';
import {
  ClientSupportedScheduledReportFilter,
  ScheduledReportType,
} from '@modules/minga-manager/components/mm-scheduled-reports/types';

import { FormSelectOption } from '@shared/components/form';
import { UserListCategory } from '@shared/components/user-list-filter';

type SCHEDULED_REPORT_FILTERS =
  | FtmReportFilterType.ACTIVITIES
  | FtmReportFilterType.CHECKIN_STATUS
  | FtmReportFilterType.REGISTRANT
  | FtmReportFilterType.REGISTERER
  | FtmReportFilterType.USER_LIST;

@Component({
  selector: 'mg-sr-flex-filters',
  templateUrl: './sr-flex-filters.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SrFlexFiltersComponent implements OnInit, OnDestroy {
  @Input() public form: FormGroup;
  @Input()
  public defaultValues$: Observable<ClientSupportedScheduledReportFilter>;

  public readonly FIELDS = FtmReportFilterType;
  public readonly PARENT_FIELDS = ScheduledReportEditFormFields;
  public readonly REPORT_TYPES = ScheduledReportType;
  public readonly MESSAGES = FtmReportsMessages;
  public readonly USER_LIST_CATEGORY = UserListCategory;

  private _filtersSubject = new BehaviorSubject<
    Record<SCHEDULED_REPORT_FILTERS, any>
  >(null);
  public filters$ = this._filtersSubject.asObservable();

  public getFilterGroupControlKey = getFilterGroupControlKey;

  private _destroyed = new ReplaySubject(1);

  public readonly activityOptions$: Observable<FormSelectOption<any>[]> =
    this._ftmActivities.activities$.pipe(
      takeUntil(this._destroyed),
      map(activities =>
        activities.map(activity => ({
          label: activity.name,
          value: activity.id,
        })),
      ),
    );
  public readonly checkinStatusOptions$: Observable<FormSelectOption[]> = of(
    $enum(FlexCheckInStatuses).map(value => ({
      label: value,
      value,
    })),
  );

  constructor(private _ftmActivities: FtmActivityTemplatesService) {}

  ngOnInit(): void {
    this.defaultValues$.pipe(takeUntil(this._destroyed)).subscribe(d => {
      this._onReady(d);
    });
  }

  ngOnDestroy() {
    this._destroyed.next();
    this._destroyed.complete();
  }

  public onPersonSelected(people: Partial<Person>[]) {
    const registrant: string[] = [];
    const registerer: string[] = [];
    for (const person of people) {
      if (
        person.roleType === 'STUDENT' ||
        person.roleType === 'STUDENT_LEADER'
      ) {
        registrant.push(person.hash);
      } else {
        registerer.push(person.hash);
      }
    }
    this.form.get(getFilterGroupControlKey('registrant')).setValue(registrant);
    this.form.get(getFilterGroupControlKey('registerer')).setValue(registerer);
  }

  private _onReady(defaultValues: ClientSupportedScheduledReportFilter) {
    this._ftmActivities.fetchAll();
    const filters = (defaultValues.filters || {}) as FtmReportFilters;
    this._filtersSubject.next({
      [FtmReportFilterType.ACTIVITIES]: [filters.activities],
      [FtmReportFilterType.CHECKIN_STATUS]: [filters.checkinStatus],
      [FtmReportFilterType.REGISTRANT]: [filters.registrant],
      [FtmReportFilterType.REGISTERER]: [filters.registerer],
      [FtmReportFilterType.USER_LIST]: [filters.userList],
    });
  }
}
