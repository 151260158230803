import { Location } from '@angular/common';
import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { IConversation } from 'libs/domain';
import { Observable, ReplaySubject } from 'rxjs';
import { takeUntil, switchMap, share, map } from 'rxjs/operators';

import { AuthInfoService } from '@app/src/app/minimal/services/AuthInfo';
import { MessagingFacade } from '@app/src/app/modules/direct-message/store';
import { Person, PeopleFacadeService } from '@app/src/app/people';

@Component({
  selector: 'mg-dm-conversation-people-overlay',
  templateUrl: './dm-conversation-people-overlay.component.html',
  styleUrls: ['./dm-conversation-people-overlay.component.scss'],
})
export class DmConversationPeopleOverlayComponent implements OnDestroy {
  readonly conversation$: Observable<IConversation>;
  readonly participants$: Observable<Person[]>;
  readonly currentUserHash$: Observable<string>;
  private _destroyed$ = new ReplaySubject<void>(1);

  constructor(
    private _route: ActivatedRoute,
    private _location: Location,
    private _authInfo: AuthInfoService,
    private _peopleFacade: PeopleFacadeService,
    private _messagingFacade: MessagingFacade,
  ) {
    const conversationId$ = this._route.params.pipe(
      map(params => parseInt(params.id, 10)),
    );

    this.conversation$ = conversationId$.pipe(
      takeUntil(this._destroyed$),
      switchMap(convId => this._messagingFacade.getConversation(convId)),
    );

    this.currentUserHash$ = this._authInfo.authInfo$.pipe(
      takeUntil(this._destroyed$),
      map(info => {
        if (info && info.person) {
          return info.person.personHash;
        }
        return '';
      }),
    );

    this.participants$ = this.conversation$.pipe(
      takeUntil(this._destroyed$),
      switchMap(conv => this._peopleFacade.getPeople(conv.participants)),
      map(people => people.filter(p => !!p).map(p => p as Person)),
      share(),
    );
  }

  onClose() {
    this._location.back();
  }

  ngOnDestroy() {
    this._destroyed$.next();
    this._destroyed$.complete();
  }
}
