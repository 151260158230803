import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';

import { VirtualScrollerModule } from '@minga/ngx-virtual-scroller';

import { MgButtonModule } from '@app/src/app/button';
import { MgEmptyStateModule } from '@app/src/app/components/EmptyState';
import { FileInputModule } from '@app/src/app/components/Input/FileInput';
import { PillCheckboxModule } from '@app/src/app/components/Input/PillCheckbox';
import { MessageLightboxModule } from '@app/src/app/components/Lightbox/MessageLightbox';
import { MgImageModule } from '@app/src/app/components/MgImage';
import { NavsearchModule } from '@app/src/app/components/Navsearch';
import { PersonAvatarModule } from '@app/src/app/components/PersonAvatar';
import { MgTextareaModule } from '@app/src/app/components/Textarea';
import { MgDirectivesModule } from '@app/src/app/directives';
import { MgAvatarModule } from '@app/src/app/elements/MgAvatar';
import { MgPersonMinimalModule } from '@app/src/app/elements/MgPersonMinimal';
import { MgFormFieldModule } from '@app/src/app/form-field';
import { MgOverlayToolsModule } from '@app/src/app/overlay/MgOverlayTools.module';
import { MgPipesModule } from '@app/src/app/pipes';
import { MgEscapedHtmlModule } from '@app/src/app/pipes/MgEscapedHtml';
import { MgPersonModule } from '@app/src/app/pipes/MgPerson';
import { MgSpinnerModule } from '@app/src/app/spinner';
import { UgcLinksHighlightPipeModule } from '@app/src/app/ugc-links/pipes/UgcLinksHighlight';

import { OverlayModule } from '@shared/components/modal-overlay';

import { DmConversationPeopleOverlayComponent } from './components/dm-conversation-people-overlay/dm-conversation-people-overlay.component';
import { DmConversationPersonMessageComponent } from './components/dm-conversation-person-message/dm-conversation-person-message.component';
import { DmConversationComponent } from './dm-conversation.component';

@NgModule({
  imports: [
    // Minga dependencies
    MgDirectivesModule,
    MgTextareaModule,
    PersonAvatarModule,
    MgButtonModule,
    MgFormFieldModule,
    UgcLinksHighlightPipeModule,
    MgPipesModule,
    MgSpinnerModule,
    MgEscapedHtmlModule,
    MgEmptyStateModule,
    MgPersonModule,
    MgOverlayToolsModule,
    OverlayModule,
    NavsearchModule,
    FileInputModule,
    MgImageModule,
    MessageLightboxModule,
    MgAvatarModule,
    MgPersonMinimalModule,
    PillCheckboxModule,

    // External dependencies
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    CommonModule,
    FlexLayoutModule,
    VirtualScrollerModule,
  ],
  declarations: [
    DmConversationComponent,
    DmConversationPersonMessageComponent,
    DmConversationPeopleOverlayComponent,
  ],
  exports: [DmConversationPersonMessageComponent],
})
export class DirectMessageConversationModule {}
