<mg-overlay-primary
  size="small"
  [overlayTitle]="'About Minga'">
  <div class="about-us-content">
    <mg-text
      variant="body-sm"
      spacing="4">
      <span>
        You are running Minga version <strong>{{ version }}</strong> on
        <strong>{{ platform }}</strong>
        <br />
        Messaging strategy is <strong>{{ messagingStrategy }}</strong> ({{
          messagingPermissionStatus$ | async
        }})
        <br />
        <!-- outputs a couple characters from remote config to help with debug purposes -->
        {{ appConfig.getValue('REMOTE_DEBUG') }}
      </span>
    </mg-text>

    <mg-text
      variant="header-md-secondary"
      spacing="2">
      About Minga
    </mg-text>
    <mg-text
      variant="body-md"
      spacing="2">
      Our mission is simple: to be the best platform for K-12
      schools—streamlining everyday operations, driving student engagement, and
      giving educators more time to focus on student success.
    </mg-text>
    <mg-text
      variant="body-md"
      spacing="2">
      Schools are more than just buildings—they’re communities. And when schools
      run better, students do better. That’s why we take the hassle out of daily
      operations so educators can focus on what matters—helping students thrive.
    </mg-text>
    <mg-text
      variant="body-md"
      spacing="2">
      We believe in creating schools where students feel connected, engaged, and
      supported. Because when that happens, amazing things follow.
    </mg-text>
    <mg-text
      variant="body-md"
      spacing="2">
      Minga is here to make that a reality, every single day.
    </mg-text>
    <mg-text
      variant="body-md"
      spacing="2">
      <span>
        Have questions or feedback? We’d love to hear from you! Reach out to us
        at
        <strong>hello@minga.io</strong>
      </span>
    </mg-text>
    <mg-text variant="body-md">
      <span>
        Privacy Policy:
        <a
          href="https://minga.io/privacy-policy/"
          target="_blank"
          >https://minga.io/privacy-policy/</a
        >
      </span>
    </mg-text>
    <mg-text variant="body-md">
      <span>
        Terms:
        <a
          href="https://minga.io/acceptable-use-policy/"
          target="_blank"
          >https://minga.io/acceptable-use-policy/</a
        >
      </span>
    </mg-text>
  </div>
</mg-overlay-primary>
