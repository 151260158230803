import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { CountryModule } from '@app/src/app/components/Country';

import { CurrentCountryComponent } from './CurrentCountry.component';

@NgModule({
  imports: [
    // Minga dependencies
    CountryModule,

    // External dependencies
    CommonModule,
  ],
  declarations: [CurrentCountryComponent],
  exports: [CurrentCountryComponent],
})
export class CurrentCountryModule {}
