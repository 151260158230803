import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';

import { MgImageModule } from '@app/src/app/components/MgImage';
import { MgIconModule } from '@app/src/app/icon';
import { MgPipesModule } from '@app/src/app/pipes';

import { CountTileComponent } from './count-tile.component';
import { CountTileContainerDirective } from './directives';

@NgModule({
  imports: [
    // Minga dependencies
    MgIconModule,
    MgImageModule,
    MgPipesModule,

    // External dependencies
    CommonModule,
    MatRippleModule,
    MatIconModule,
  ],
  declarations: [CountTileComponent, CountTileContainerDirective],
  exports: [CountTileComponent, CountTileContainerDirective],
})
export class CountTileModule {}
