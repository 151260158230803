import { IMingaSetting } from 'libs/domain';
import { MingaRoleType } from 'libs/domain';
import { mingaSettingTypes, MingaTypeValue } from 'libs/util';

import { getRole } from '../../';
import { MingaType } from './MingaType';

export class MingaSchoolMingaType extends MingaType {
  readonly mingaType = MingaTypeValue.MINGA;

  readonly availableRoles = {
    [MingaRoleType.SUPERADMIN]: { weight: 99 },
    [MingaRoleType.DISTRICT_MANAGER]: { weight: 11 },
    [MingaRoleType.OWNER]: { weight: 10 },
    [MingaRoleType.MANAGER]: { weight: 9 },
    [MingaRoleType.DISTRICT_LEADER]: { weight: 8 },
    [MingaRoleType.SCHOOL_MANAGER]: { weight: 7 },
    [MingaRoleType.TEACHER]: { weight: 6 },
    [MingaRoleType.STAFF]: { weight: 5 },
    [MingaRoleType.STUDENT_LEADER]: { weight: 4 },
    [MingaRoleType.STUDENT]: { weight: 3 },
    [MingaRoleType.PARENT]: { weight: 2 },
    [MingaRoleType.READ_ONLY]: { weight: 1 },
  };

  readonly defaultSettings: IMingaSetting[] = [
    {
      name: mingaSettingTypes.FEATURE_ENABLE_GROUPS,
      value: true,
    },
    {
      name: mingaSettingTypes.FEATURE_MONTHLY_DIGEST_EMAIL,
      value: false,
    },
  ];

  readonly canSelfManageAccountPlan: boolean = false;

  readonly defaultVideoUploadRoleTypes: MingaRoleType[] = [
    MingaRoleType.DISTRICT_MANAGER,
    MingaRoleType.OWNER,
    MingaRoleType.MANAGER,
    MingaRoleType.SCHOOL_MANAGER,
    MingaRoleType.TEACHER,
    MingaRoleType.STAFF,
    MingaRoleType.DISTRICT_LEADER,
  ];

  /* NOTE: if we add in new roles, make sure to
  change onAfterCreate in the Minga model to ensure
  they get added in the default settings for a newly
   created minga. */
  readonly directMessagingRoleSettings = [
    {
      localeTitleKey: 'messagingSettings.teachersStaffOwnersManagers',
      immutable: true,
      roleGroups: [
        [MingaRoleType.OWNER, MingaRoleType.OWNER],
        [MingaRoleType.TEACHER, MingaRoleType.TEACHER],
        [MingaRoleType.MANAGER, MingaRoleType.MANAGER],
        [MingaRoleType.STAFF, MingaRoleType.STAFF],
        [MingaRoleType.SCHOOL_MANAGER, MingaRoleType.SCHOOL_MANAGER],
        [MingaRoleType.DISTRICT_LEADER, MingaRoleType.DISTRICT_LEADER],
        [MingaRoleType.OWNER, MingaRoleType.TEACHER],
        [MingaRoleType.OWNER, MingaRoleType.MANAGER],
        [MingaRoleType.OWNER, MingaRoleType.STAFF],
        [MingaRoleType.OWNER, MingaRoleType.SCHOOL_MANAGER],
        [MingaRoleType.OWNER, MingaRoleType.DISTRICT_LEADER],
        [MingaRoleType.TEACHER, MingaRoleType.MANAGER],
        [MingaRoleType.TEACHER, MingaRoleType.SCHOOL_MANAGER],
        [MingaRoleType.TEACHER, MingaRoleType.DISTRICT_LEADER],
        [MingaRoleType.TEACHER, MingaRoleType.STAFF],
        [MingaRoleType.MANAGER, MingaRoleType.STAFF],
        [MingaRoleType.MANAGER, MingaRoleType.SCHOOL_MANAGER],
        [MingaRoleType.MANAGER, MingaRoleType.DISTRICT_LEADER],
        [MingaRoleType.SCHOOL_MANAGER, MingaRoleType.DISTRICT_LEADER],
        [MingaRoleType.DISTRICT_MANAGER, MingaRoleType.OWNER],
        [MingaRoleType.DISTRICT_MANAGER, MingaRoleType.MANAGER],
        [MingaRoleType.DISTRICT_MANAGER, MingaRoleType.TEACHER],
        [MingaRoleType.DISTRICT_MANAGER, MingaRoleType.STAFF],
      ],
    },
    {
      localeTitleKey: 'messagingSettings.studentsTeachersOwnersManagers',
      immutable: false,
      roleGroups: [
        [MingaRoleType.STUDENT, MingaRoleType.TEACHER],
        [MingaRoleType.STUDENT, MingaRoleType.STAFF],
        [MingaRoleType.STUDENT, MingaRoleType.OWNER],
        [MingaRoleType.STUDENT, MingaRoleType.MANAGER],
        [MingaRoleType.STUDENT, MingaRoleType.SCHOOL_MANAGER],
        [MingaRoleType.STUDENT, MingaRoleType.DISTRICT_LEADER],
        [MingaRoleType.STUDENT, MingaRoleType.DISTRICT_MANAGER],
        [MingaRoleType.STUDENT_LEADER, MingaRoleType.TEACHER],
        [MingaRoleType.STUDENT_LEADER, MingaRoleType.STAFF],
        [MingaRoleType.STUDENT_LEADER, MingaRoleType.OWNER],
        [MingaRoleType.STUDENT_LEADER, MingaRoleType.MANAGER],
        [MingaRoleType.STUDENT_LEADER, MingaRoleType.SCHOOL_MANAGER],
        [MingaRoleType.STUDENT_LEADER, MingaRoleType.DISTRICT_LEADER],
        [MingaRoleType.STUDENT_LEADER, MingaRoleType.DISTRICT_MANAGER],
      ],
    },
    {
      localeTitleKey: 'messagingSettings.parentsTeachersOwnersManagers',
      immutable: false,
      roleGroups: [
        [MingaRoleType.PARENT, MingaRoleType.TEACHER],
        [MingaRoleType.PARENT, MingaRoleType.STAFF],
        [MingaRoleType.PARENT, MingaRoleType.OWNER],
        [MingaRoleType.PARENT, MingaRoleType.MANAGER],
        [MingaRoleType.PARENT, MingaRoleType.SCHOOL_MANAGER],
        [MingaRoleType.PARENT, MingaRoleType.DISTRICT_LEADER],
        [MingaRoleType.PARENT, MingaRoleType.DISTRICT_MANAGER],
      ],
    },
    {
      localeTitleKey: 'messagingSettings.studentsAndStudentLeaders',
      immutable: false,
      roleGroups: [[MingaRoleType.STUDENT, MingaRoleType.STUDENT_LEADER]],
    },
    {
      localeTitleKey: 'messagingSettings.studentLeaders',
      immutable: false,
      roleGroups: [
        [MingaRoleType.STUDENT_LEADER, MingaRoleType.STUDENT_LEADER],
      ],
    },
    {
      localeTitleKey: 'messagingSettings.students',
      immutable: false,
      roleGroups: [[MingaRoleType.STUDENT, MingaRoleType.STUDENT]],
    },
    {
      localeTitleKey: 'messagingSettings.parents',
      immutable: false,
      roleGroups: [[MingaRoleType.PARENT, MingaRoleType.PARENT]],
    },
  ];

  getBillableRoles(isPricedPerDefaultRole: boolean = true) {
    if (isPricedPerDefaultRole) {
      return [MingaRoleType.STUDENT, MingaRoleType.STUDENT_LEADER].map(getRole);
    } else {
      return [
        MingaRoleType.DISTRICT_MANAGER,
        MingaRoleType.OWNER,
        MingaRoleType.MANAGER,
        MingaRoleType.STUDENT,
        MingaRoleType.TEACHER,
        MingaRoleType.PARENT,
        MingaRoleType.READ_ONLY,
        MingaRoleType.STAFF,
        MingaRoleType.STUDENT_LEADER,
        MingaRoleType.SCHOOL_MANAGER,
        MingaRoleType.DISTRICT_LEADER,
      ].map(getRole);
    }
  }

  getDefaultRole() {
    return getRole(MingaRoleType.STUDENT);
  }

  getCreatorRole() {
    return getRole(MingaRoleType.OWNER);
  }

  getAllowedToJoinRoles() {
    return [MingaRoleType.STUDENT, MingaRoleType.PARENT].map(getRole);
  }

  getInviteRole() {
    return getRole(MingaRoleType.TEACHER);
  }

  /**
   * Return display versions of School and District subscriptions, as the
   * database subscriptions are for internal business purposes, not for
   * customers.
   */
  getDisplaySubscriptions() {
    return [
      {
        planId: 'MingaSchool',
        name: 'School',
        planUrl: 'https://minga.io/minga-school-order/',
        annualFee: '$1499/year',
        mingaSize: null,
        groupSize: null,
        adminUserSize: null,
        premiumStudioTemplates: true,
        premiumPrograms: true,
        id: 10,
        pricedPerDefaultRole: true,
        annualSmsMessageQuota: 0,
      },
      {
        planId: 'MingaDistrict',
        name: 'District',
        planUrl: 'https://minga.io/minga-school-order/',
        annualFee: '$2.50/student/year',
        mingaSize: null,
        groupSize: null,
        adminUserSize: null,
        premiumStudioTemplates: true,
        premiumPrograms: true,
        id: 11,
        pricedPerDefaultRole: true,
        annualSmsMessageQuota: 0,
      },
    ];
  }
}
