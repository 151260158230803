export function nl2p(stringWithNewlines: string) {
  const BRK = '<br>';
  let html = '';
  let lines = stringWithNewlines.split('\n');
  let paragraph = true;
  let paragraphs = 0;

  for(let line of lines) {
    line = line.trim();
    if(!line) {
      paragraphs += 1;
      paragraph = true;
    } else {
      let encoded: string = line;

      if(paragraph) {
        if(html.endsWith(BRK)) {
          html = html.substr(0, html.length - BRK.length);
        }
        if(paragraphs > 0) {
          html += `</p><p>${encoded}`;
        } else {
          html += `<p>${encoded}`;
        }
        paragraph = false;
      } else {
        html += `${BRK}${encoded}`;
      }
    }
  }

  if(!paragraph && paragraphs > 0) {
    html += `</p>`;
  }

  return html;
}