import { Injectable, NgZone } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { grpc } from '@improbable-eng/grpc-web';

import {
  ContentInfoQuery,
  ContentSaveResult,
} from '../content/common_pb';
import {
  AddEventManagerRequest,
  AddEventManagerResponse,
  AddEventTicketsRequest,
  AddEventTicketsResponse,
  CancelEventRequest,
  CancelEventResponse,
  CheckInUsersRequest,
  CheckInUsersResponse,
  CheckOutUsersRequest,
  CheckOutUsersResponse,
  DeltaEventInfo,
  EventInfo,
  EventTicketsPeopleRequest,
  EventTicketsPeopleResponse,
  ExportEventTicketsChunk,
  ExportEventTicketsRequest,
  GetEventGoingPeopleRequest,
  GetEventGoingPeopleResponse,
  GetEventManagementInfoRequest,
  GetEventManagementInfoResponse,
  GetEventTicketsByStudentIdRequest,
  GetEventTicketsByStudentIdResponse,
  GetInvitedPeopleRequest,
  GetInvitedPeopleResponse,
  InvitePeopleRequest,
  InvitePeopleResponse,
  RemoveEventManagerRequest,
  RemoveEventManagerResponse,
  RemovePeopleFromEventRequest,
  RemovePeopleFromEventResponse,
} from '../content/event_pb';

import {
  Event as __Event,
} from '../content/event_pb_service';

@Injectable({providedIn: 'root'})
export class Event {

  constructor(private _ngZone: NgZone) {
  }

  new(request: EventInfo): Promise<ContentSaveResult>;
  new(request: EventInfo, metadata: grpc.Metadata): Promise<ContentSaveResult>;
  new(request: EventInfo, callback: (err: any|null, response: ContentSaveResult, metadata: grpc.Metadata) => void): void;
  new(request: EventInfo, metadata: grpc.Metadata, callback: (err: any|null, response: ContentSaveResult, metadata: grpc.Metadata) => void): void;

  new(request: EventInfo, arg1?: grpc.Metadata|((err: any|null, response: ContentSaveResult, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: ContentSaveResult, metadata: grpc.Metadata) => void): Promise<ContentSaveResult>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<ContentSaveResult>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__Event.New, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  update(request: DeltaEventInfo): Promise<ContentSaveResult>;
  update(request: DeltaEventInfo, metadata: grpc.Metadata): Promise<ContentSaveResult>;
  update(request: DeltaEventInfo, callback: (err: any|null, response: ContentSaveResult, metadata: grpc.Metadata) => void): void;
  update(request: DeltaEventInfo, metadata: grpc.Metadata, callback: (err: any|null, response: ContentSaveResult, metadata: grpc.Metadata) => void): void;

  update(request: DeltaEventInfo, arg1?: grpc.Metadata|((err: any|null, response: ContentSaveResult, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: ContentSaveResult, metadata: grpc.Metadata) => void): Promise<ContentSaveResult>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<ContentSaveResult>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__Event.Update, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  getInfo(request: ContentInfoQuery): Promise<EventInfo>;
  getInfo(request: ContentInfoQuery, metadata: grpc.Metadata): Promise<EventInfo>;
  getInfo(request: ContentInfoQuery, callback: (err: any|null, response: EventInfo, metadata: grpc.Metadata) => void): void;
  getInfo(request: ContentInfoQuery, metadata: grpc.Metadata, callback: (err: any|null, response: EventInfo, metadata: grpc.Metadata) => void): void;

  getInfo(request: ContentInfoQuery, arg1?: grpc.Metadata|((err: any|null, response: EventInfo, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: EventInfo, metadata: grpc.Metadata) => void): Promise<EventInfo>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<EventInfo>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__Event.GetInfo, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  cancelEvent(request: CancelEventRequest): Promise<CancelEventResponse>;
  cancelEvent(request: CancelEventRequest, metadata: grpc.Metadata): Promise<CancelEventResponse>;
  cancelEvent(request: CancelEventRequest, callback: (err: any|null, response: CancelEventResponse, metadata: grpc.Metadata) => void): void;
  cancelEvent(request: CancelEventRequest, metadata: grpc.Metadata, callback: (err: any|null, response: CancelEventResponse, metadata: grpc.Metadata) => void): void;

  cancelEvent(request: CancelEventRequest, arg1?: grpc.Metadata|((err: any|null, response: CancelEventResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: CancelEventResponse, metadata: grpc.Metadata) => void): Promise<CancelEventResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<CancelEventResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__Event.CancelEvent, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  checkInUsers(request: CheckInUsersRequest): Promise<CheckInUsersResponse>;
  checkInUsers(request: CheckInUsersRequest, metadata: grpc.Metadata): Promise<CheckInUsersResponse>;
  checkInUsers(request: CheckInUsersRequest, callback: (err: any|null, response: CheckInUsersResponse, metadata: grpc.Metadata) => void): void;
  checkInUsers(request: CheckInUsersRequest, metadata: grpc.Metadata, callback: (err: any|null, response: CheckInUsersResponse, metadata: grpc.Metadata) => void): void;

  checkInUsers(request: CheckInUsersRequest, arg1?: grpc.Metadata|((err: any|null, response: CheckInUsersResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: CheckInUsersResponse, metadata: grpc.Metadata) => void): Promise<CheckInUsersResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<CheckInUsersResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__Event.CheckInUsers, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  checkOutUsers(request: CheckOutUsersRequest): Promise<CheckOutUsersResponse>;
  checkOutUsers(request: CheckOutUsersRequest, metadata: grpc.Metadata): Promise<CheckOutUsersResponse>;
  checkOutUsers(request: CheckOutUsersRequest, callback: (err: any|null, response: CheckOutUsersResponse, metadata: grpc.Metadata) => void): void;
  checkOutUsers(request: CheckOutUsersRequest, metadata: grpc.Metadata, callback: (err: any|null, response: CheckOutUsersResponse, metadata: grpc.Metadata) => void): void;

  checkOutUsers(request: CheckOutUsersRequest, arg1?: grpc.Metadata|((err: any|null, response: CheckOutUsersResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: CheckOutUsersResponse, metadata: grpc.Metadata) => void): Promise<CheckOutUsersResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<CheckOutUsersResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__Event.CheckOutUsers, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  addEventManager(request: AddEventManagerRequest): Promise<AddEventManagerResponse>;
  addEventManager(request: AddEventManagerRequest, metadata: grpc.Metadata): Promise<AddEventManagerResponse>;
  addEventManager(request: AddEventManagerRequest, callback: (err: any|null, response: AddEventManagerResponse, metadata: grpc.Metadata) => void): void;
  addEventManager(request: AddEventManagerRequest, metadata: grpc.Metadata, callback: (err: any|null, response: AddEventManagerResponse, metadata: grpc.Metadata) => void): void;

  addEventManager(request: AddEventManagerRequest, arg1?: grpc.Metadata|((err: any|null, response: AddEventManagerResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: AddEventManagerResponse, metadata: grpc.Metadata) => void): Promise<AddEventManagerResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<AddEventManagerResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__Event.AddEventManager, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  removeEventManager(request: RemoveEventManagerRequest): Promise<RemoveEventManagerResponse>;
  removeEventManager(request: RemoveEventManagerRequest, metadata: grpc.Metadata): Promise<RemoveEventManagerResponse>;
  removeEventManager(request: RemoveEventManagerRequest, callback: (err: any|null, response: RemoveEventManagerResponse, metadata: grpc.Metadata) => void): void;
  removeEventManager(request: RemoveEventManagerRequest, metadata: grpc.Metadata, callback: (err: any|null, response: RemoveEventManagerResponse, metadata: grpc.Metadata) => void): void;

  removeEventManager(request: RemoveEventManagerRequest, arg1?: grpc.Metadata|((err: any|null, response: RemoveEventManagerResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: RemoveEventManagerResponse, metadata: grpc.Metadata) => void): Promise<RemoveEventManagerResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<RemoveEventManagerResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__Event.RemoveEventManager, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  getInvitedPeople(request: GetInvitedPeopleRequest): Promise<GetInvitedPeopleResponse>;
  getInvitedPeople(request: GetInvitedPeopleRequest, metadata: grpc.Metadata): Promise<GetInvitedPeopleResponse>;
  getInvitedPeople(request: GetInvitedPeopleRequest, callback: (err: any|null, response: GetInvitedPeopleResponse, metadata: grpc.Metadata) => void): void;
  getInvitedPeople(request: GetInvitedPeopleRequest, metadata: grpc.Metadata, callback: (err: any|null, response: GetInvitedPeopleResponse, metadata: grpc.Metadata) => void): void;

  getInvitedPeople(request: GetInvitedPeopleRequest, arg1?: grpc.Metadata|((err: any|null, response: GetInvitedPeopleResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: GetInvitedPeopleResponse, metadata: grpc.Metadata) => void): Promise<GetInvitedPeopleResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<GetInvitedPeopleResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__Event.GetInvitedPeople, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  invitePeople(request: InvitePeopleRequest): Promise<InvitePeopleResponse>;
  invitePeople(request: InvitePeopleRequest, metadata: grpc.Metadata): Promise<InvitePeopleResponse>;
  invitePeople(request: InvitePeopleRequest, callback: (err: any|null, response: InvitePeopleResponse, metadata: grpc.Metadata) => void): void;
  invitePeople(request: InvitePeopleRequest, metadata: grpc.Metadata, callback: (err: any|null, response: InvitePeopleResponse, metadata: grpc.Metadata) => void): void;

  invitePeople(request: InvitePeopleRequest, arg1?: grpc.Metadata|((err: any|null, response: InvitePeopleResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: InvitePeopleResponse, metadata: grpc.Metadata) => void): Promise<InvitePeopleResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<InvitePeopleResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__Event.InvitePeople, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  addEventTickets(request: AddEventTicketsRequest): Promise<AddEventTicketsResponse>;
  addEventTickets(request: AddEventTicketsRequest, metadata: grpc.Metadata): Promise<AddEventTicketsResponse>;
  addEventTickets(request: AddEventTicketsRequest, callback: (err: any|null, response: AddEventTicketsResponse, metadata: grpc.Metadata) => void): void;
  addEventTickets(request: AddEventTicketsRequest, metadata: grpc.Metadata, callback: (err: any|null, response: AddEventTicketsResponse, metadata: grpc.Metadata) => void): void;

  addEventTickets(request: AddEventTicketsRequest, arg1?: grpc.Metadata|((err: any|null, response: AddEventTicketsResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: AddEventTicketsResponse, metadata: grpc.Metadata) => void): Promise<AddEventTicketsResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<AddEventTicketsResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__Event.AddEventTickets, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  getEventTicketsByStudentId(request: GetEventTicketsByStudentIdRequest): Promise<GetEventTicketsByStudentIdResponse>;
  getEventTicketsByStudentId(request: GetEventTicketsByStudentIdRequest, metadata: grpc.Metadata): Promise<GetEventTicketsByStudentIdResponse>;
  getEventTicketsByStudentId(request: GetEventTicketsByStudentIdRequest, callback: (err: any|null, response: GetEventTicketsByStudentIdResponse, metadata: grpc.Metadata) => void): void;
  getEventTicketsByStudentId(request: GetEventTicketsByStudentIdRequest, metadata: grpc.Metadata, callback: (err: any|null, response: GetEventTicketsByStudentIdResponse, metadata: grpc.Metadata) => void): void;

  getEventTicketsByStudentId(request: GetEventTicketsByStudentIdRequest, arg1?: grpc.Metadata|((err: any|null, response: GetEventTicketsByStudentIdResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: GetEventTicketsByStudentIdResponse, metadata: grpc.Metadata) => void): Promise<GetEventTicketsByStudentIdResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<GetEventTicketsByStudentIdResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__Event.GetEventTicketsByStudentId, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  getEventTickets(request: EventTicketsPeopleRequest): Promise<EventTicketsPeopleResponse>;
  getEventTickets(request: EventTicketsPeopleRequest, metadata: grpc.Metadata): Promise<EventTicketsPeopleResponse>;
  getEventTickets(request: EventTicketsPeopleRequest, callback: (err: any|null, response: EventTicketsPeopleResponse, metadata: grpc.Metadata) => void): void;
  getEventTickets(request: EventTicketsPeopleRequest, metadata: grpc.Metadata, callback: (err: any|null, response: EventTicketsPeopleResponse, metadata: grpc.Metadata) => void): void;

  getEventTickets(request: EventTicketsPeopleRequest, arg1?: grpc.Metadata|((err: any|null, response: EventTicketsPeopleResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: EventTicketsPeopleResponse, metadata: grpc.Metadata) => void): Promise<EventTicketsPeopleResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<EventTicketsPeopleResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__Event.GetEventTickets, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  exportTickets(request: ExportEventTicketsRequest): {cancel():void;close():void}&Observable<ExportEventTicketsChunk>;
  exportTickets(request: ExportEventTicketsRequest, metadata: grpc.Metadata): {cancel():void;close():void}&Observable<ExportEventTicketsChunk>;
  exportTickets(request: ExportEventTicketsRequest, onMessage: (message?: ExportEventTicketsChunk) => void,onError?: (err: any) => void,onEnd?: (code: number, msg: string|undefined, metadata: grpc.Metadata) => void): void;
  exportTickets(request: ExportEventTicketsRequest, metadata: grpc.Metadata, onMessage: (message?: ExportEventTicketsChunk) => void,onError?: (err: any) => void,onEnd?: (code: number, msg: string|undefined, metadata: grpc.Metadata) => void): void;

  exportTickets(request: ExportEventTicketsRequest, arg1?: grpc.Metadata|((message?: ExportEventTicketsChunk) => void), arg2?: ((message?: ExportEventTicketsChunk) => void)|((err: any) => void), arg3?: ((err: any) => void)|((code: number, msg: string|undefined, metadata: grpc.Metadata) => void), arg4?: (code: number, msg: string|undefined, metadata: grpc.Metadata) => void): {cancel():void;close():void}&Observable<ExportEventTicketsChunk>|void {
    let ret: any;
    let metadata: any;

    let onMessage: any;
    let onError: any;
    let onEnd: any;
    if(typeof arg1 === 'function') {
      onMessage = arg1;
      onError = arg2;
      onEnd = arg3;
    } else if(typeof arg2 === 'function') {
      metadata = arg1;
      onMessage = arg2;
      onError = arg3;
      onEnd = arg4;
    } else {
      metadata = arg1;
    }

    if(!onMessage) {
      let subject = new Subject<ExportEventTicketsChunk>();
      ret = subject.asObservable();

      onMessage = (response: any) => {
        subject.next(response);
      };

      onError = (err: any) => {
        subject.error(err);
      };

      onEnd = (code: any, msg: any, metadata: any) => {
        subject.complete();
      };

    } else {
      if(!onError) {
        onError = (err: any) => console.error(err);
      }

      if(!onEnd) {
        onEnd = (code: any, msg: any, metadata: any) => {};
      }
    }

    let req = grpc.invoke(__Event.ExportTickets, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onMessage: response => this._ngZone.run(() => {
        onMessage(response);
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code == grpc.Code.OK) {
          onEnd(code, msg, metadata);
        } else {
          let err = new Error(code + ' ' + (msg||''));
          (<any>err).code = code;
          onError(err);
        }
      })
    });

    (<any>ret).close = () => {
      console.warn('[Angular Grpc] .close() is deprecated use .cancel() instead');
      return req.close();
    };
    (<any>ret).cancel = () => req.close();
    return ret;
  }

  removePeopleFromEvent(request: RemovePeopleFromEventRequest): Promise<RemovePeopleFromEventResponse>;
  removePeopleFromEvent(request: RemovePeopleFromEventRequest, metadata: grpc.Metadata): Promise<RemovePeopleFromEventResponse>;
  removePeopleFromEvent(request: RemovePeopleFromEventRequest, callback: (err: any|null, response: RemovePeopleFromEventResponse, metadata: grpc.Metadata) => void): void;
  removePeopleFromEvent(request: RemovePeopleFromEventRequest, metadata: grpc.Metadata, callback: (err: any|null, response: RemovePeopleFromEventResponse, metadata: grpc.Metadata) => void): void;

  removePeopleFromEvent(request: RemovePeopleFromEventRequest, arg1?: grpc.Metadata|((err: any|null, response: RemovePeopleFromEventResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: RemovePeopleFromEventResponse, metadata: grpc.Metadata) => void): Promise<RemovePeopleFromEventResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<RemovePeopleFromEventResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__Event.RemovePeopleFromEvent, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  getEventManagementInfo(request: GetEventManagementInfoRequest): Promise<GetEventManagementInfoResponse>;
  getEventManagementInfo(request: GetEventManagementInfoRequest, metadata: grpc.Metadata): Promise<GetEventManagementInfoResponse>;
  getEventManagementInfo(request: GetEventManagementInfoRequest, callback: (err: any|null, response: GetEventManagementInfoResponse, metadata: grpc.Metadata) => void): void;
  getEventManagementInfo(request: GetEventManagementInfoRequest, metadata: grpc.Metadata, callback: (err: any|null, response: GetEventManagementInfoResponse, metadata: grpc.Metadata) => void): void;

  getEventManagementInfo(request: GetEventManagementInfoRequest, arg1?: grpc.Metadata|((err: any|null, response: GetEventManagementInfoResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: GetEventManagementInfoResponse, metadata: grpc.Metadata) => void): Promise<GetEventManagementInfoResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<GetEventManagementInfoResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__Event.GetEventManagementInfo, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  getEventGoingPeople(request: GetEventGoingPeopleRequest): Promise<GetEventGoingPeopleResponse>;
  getEventGoingPeople(request: GetEventGoingPeopleRequest, metadata: grpc.Metadata): Promise<GetEventGoingPeopleResponse>;
  getEventGoingPeople(request: GetEventGoingPeopleRequest, callback: (err: any|null, response: GetEventGoingPeopleResponse, metadata: grpc.Metadata) => void): void;
  getEventGoingPeople(request: GetEventGoingPeopleRequest, metadata: grpc.Metadata, callback: (err: any|null, response: GetEventGoingPeopleResponse, metadata: grpc.Metadata) => void): void;

  getEventGoingPeople(request: GetEventGoingPeopleRequest, arg1?: grpc.Metadata|((err: any|null, response: GetEventGoingPeopleResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: GetEventGoingPeopleResponse, metadata: grpc.Metadata) => void): Promise<GetEventGoingPeopleResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<GetEventGoingPeopleResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__Event.GetEventGoingPeople, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

}

