export const SUPPORT_TIER_MESSAGES = {
  CORE: {
    title: 'Core',
    description: 'Core features and support',
    features: [
      'Dedicated cusomter success manager',
      '1 guided onboarding session',
      'Email support (6am - 5pm PT)',
      'Live group training sessions',
      'Knowledge base center',
    ],
  },
  PREMIUM: {
    title: 'Premium',
    description: 'Everything in Core, plus:',
    features: [
      'Direct phone line support 6am - 5pm PST',
      'On Demand training sessions with your CSM',
      'Priority support queue (2hr reply)',
      'Virtual teacher training session',
      'Annual strategic advisory session',
    ],
    secondary_heading: 'Premium features',
    secondary_features: ['Bell schedule integration'],
    upgrade_label: 'Upgrade to Premium',
  },
};
