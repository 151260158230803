import { Injectable } from '@angular/core';

import { gateway, legacy_pb } from 'libs/generated-grpc-web';
import { filter, withLatestFrom } from 'rxjs/operators';

import { FirebaseMessaging } from '@app/src/app/firebase/messaging';
import { AuthInfoService } from '@app/src/app/minimal/services/AuthInfo';
import { SentryService } from '@app/src/app/minimal/services/Sentry/Sentry.service';

@Injectable({ providedIn: 'root' })
export class DeviceUpdaterService {
  private _startupCalled: boolean = false;
  private _lastFirebaseToken: string = '';
  constructor(
    private notificationToken: gateway.notification_ng_grpc_pb.NotificationToken,
    private firebaseMessaging: FirebaseMessaging,
    private authInfo: AuthInfoService,
    private sentryService: SentryService,
  ) {}

  startup() {
    if (this._startupCalled) {
      throw new Error('DeviceUpdaterService.startup() may only be called once');
    }
    this._startupCalled = true;

    this.authInfo.authInfo$
      .pipe(filter(info => !!info))
      .subscribe(() => this.updateDevice());

    this.firebaseMessaging.tokenRefresh$
      .pipe(
        withLatestFrom(this.authInfo.authInfo$),
        filter(([, authInfo]) => !!authInfo),
      )
      .subscribe(() => this.updateDevice());
  }

  async updateDevice() {
    try {
      let request = new gateway.notification_pb.UpdateDeviceRequest();
      const deviceId = await this.firebaseMessaging.getDeviceId();
      const firebaseToken = await this.firebaseMessaging.getToken();

      if (this._lastFirebaseToken == firebaseToken) {
        return;
      }

      this._lastFirebaseToken = firebaseToken;

      if (!firebaseToken) {
        return;
      }

      request.setDeviceId(deviceId);
      request.setFirebaseRegistrationToken(firebaseToken);
      const response = await this.notificationToken.updateDeviceToken(request);

      const status = response.getStatus();
      if (status != legacy_pb.StatusCode.OK) {
        await this.sentryService.captureMessageAsError(
          `Notification.service.updateDevice() failed`,
        );
        console.error(`updateDevice() failed`);
      }
    } catch (error) {
      await this.sentryService.captureMessageAsError(
        `Notification.service.updateDevice() failed`,
        error,
      );
    }
  }
}
