import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';

import { MgButtonModule } from '@app/src/app/button';
import { MgIconModule } from '@app/src/app/icon';

import { ResolveListUploadErrorsDialogComponent } from './ResolveListUploadErrorsDialog.component';

@NgModule({
  imports: [
    // Minga dependencies
    MgButtonModule,
    MgIconModule,

    // External dependencies
    CommonModule,
    MatDialogModule,
  ],
  declarations: [ResolveListUploadErrorsDialogComponent],
  exports: [ResolveListUploadErrorsDialogComponent],
})
export class ResolveListUploadErrorsDialogModule {}
