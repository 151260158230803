import { NgModule } from '@angular/core';

import { MgOverlayToolsModule } from '@app/src/app/overlay/tools';

import { ContentUnavailableComponent } from './ContentUnavailable.component';

@NgModule({
  imports: [MgOverlayToolsModule],
  declarations: [ContentUnavailableComponent],
  exports: [ContentUnavailableComponent],
})
export class ContentUnavailableModule {}
