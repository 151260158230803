// GENERATED CODE -- DO NOT EDIT!

import {grpc} from '@improbable-eng/grpc-web';

import * as roster_roster_pb from '../roster/roster_pb';
import * as common_date_pb from '../common/date_pb';

export class RosterManager {
  static readonly serviceName = "roster.RosterManager";

  static readonly GetRosterJobs = {
    methodName: "GetRosterJobs",
    service: RosterManager,
    requestStream: false,
    responseStream: false,
    requestType: roster_roster_pb.GetRosterJobsRequest,
    responseType: roster_roster_pb.GetRosterJobsResponse,
  };

}

export class RosterManagerClient {
  getRosterJobs() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
}
