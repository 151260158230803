import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { BarcodeScanner } from '@app/src/app/barcodeScanner';

import { RedeemPointsModalService } from '@shared/components/redeem-points-modal';
import { SystemAlertSnackBarService } from '@shared/components/system-alert-snackbar';

import { PeopleSelectorService } from '../people-selector.service';
import { PeopleSelectorFormService } from '../ps-form.service';
import { PsCollectionSearchImplService } from '../search-impl/ps-collection-search.impl.service';

@Injectable()
export class PsPointsRewardService extends PeopleSelectorFormService<'Points Reward'> {
  /** Service Constructor */
  constructor(
    public router: Router,
    public snackbar: SystemAlertSnackBarService,
    public barCodeScanner: BarcodeScanner,
    public peopleSelector: PeopleSelectorService,
    private _psCollectionSearch: PsCollectionSearchImplService,
    private _redeemPointsModal: RedeemPointsModalService,
  ) {
    super(
      {
        name: 'Points Reward',
        pageDefinitions: {
          redeem: {
            submitFn: async => this.submitRedeem(),
            searchFn: async =>
              this._psCollectionSearch.collectionSearch('text', undefined),
          },
        },
      },
      router,
      snackbar,
      barCodeScanner,
      peopleSelector,
    );
    //stupid fix because webpack is throwing an error when using arrow
    // functions with parameters in the super call.
    this.setConfig({
      name: 'Points Reward',
      pageDefinitions: {
        redeem: {
          submitFn: async () => this.submitRedeem(),
          searchFn: async (type, filters) =>
            this._psCollectionSearch.collectionSearch(type, filters),
        },
      },
    });
  }

  public async submitRedeem(): Promise<void> {
    const { id } = this.data;
    const person = this.selection.getSelection()[0];
    await this._redeemPointsModal.openModal(person, [id]);
  }
}
