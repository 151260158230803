import { Injectable, NgZone } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { grpc } from '@improbable-eng/grpc-web';

import {
  GetClassTypesRequest,
  GetClassTypesResponse,
  GetListTypeCountsRequest,
  GetListTypeCountsResponse,
} from '../class_types/class_types_pb';

import {
  ClassTypesService as __ClassTypesService,
} from '../class_types/class_types_pb_service';

@Injectable({providedIn: 'root'})
export class ClassTypesService {

  constructor(private _ngZone: NgZone) {
  }

  getClassTypes(request: GetClassTypesRequest): Promise<GetClassTypesResponse>;
  getClassTypes(request: GetClassTypesRequest, metadata: grpc.Metadata): Promise<GetClassTypesResponse>;
  getClassTypes(request: GetClassTypesRequest, callback: (err: any|null, response: GetClassTypesResponse, metadata: grpc.Metadata) => void): void;
  getClassTypes(request: GetClassTypesRequest, metadata: grpc.Metadata, callback: (err: any|null, response: GetClassTypesResponse, metadata: grpc.Metadata) => void): void;

  getClassTypes(request: GetClassTypesRequest, arg1?: grpc.Metadata|((err: any|null, response: GetClassTypesResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: GetClassTypesResponse, metadata: grpc.Metadata) => void): Promise<GetClassTypesResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<GetClassTypesResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__ClassTypesService.GetClassTypes, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  getListTypeCounts(request: GetListTypeCountsRequest): Promise<GetListTypeCountsResponse>;
  getListTypeCounts(request: GetListTypeCountsRequest, metadata: grpc.Metadata): Promise<GetListTypeCountsResponse>;
  getListTypeCounts(request: GetListTypeCountsRequest, callback: (err: any|null, response: GetListTypeCountsResponse, metadata: grpc.Metadata) => void): void;
  getListTypeCounts(request: GetListTypeCountsRequest, metadata: grpc.Metadata, callback: (err: any|null, response: GetListTypeCountsResponse, metadata: grpc.Metadata) => void): void;

  getListTypeCounts(request: GetListTypeCountsRequest, arg1?: grpc.Metadata|((err: any|null, response: GetListTypeCountsResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: GetListTypeCountsResponse, metadata: grpc.Metadata) => void): Promise<GetListTypeCountsResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<GetListTypeCountsResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__ClassTypesService.GetListTypeCounts, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

}

