// GENERATED CODE -- DO NOT EDIT!

import {grpc} from '@improbable-eng/grpc-web';

import * as gateway_profile_pb from '../gateway/profile_pb';
import * as common_legacy_pb from '../common/legacy_pb';
import * as common_date_pb from '../common/date_pb';
import * as gateway_person_view_pb from '../gateway/person_view_pb';
import * as image_image_pb from '../image/image_pb';

export class ProfileService {
  static readonly serviceName = "ProfileService";

  static readonly updateProfile = {
    methodName: "updateProfile",
    service: ProfileService,
    requestStream: false,
    responseStream: false,
    requestType: gateway_profile_pb.UpdateProfileRequest,
    responseType: gateway_profile_pb.UpdateProfileResponse,
  };

  static readonly updateBirthdate = {
    methodName: "updateBirthdate",
    service: ProfileService,
    requestStream: false,
    responseStream: false,
    requestType: gateway_profile_pb.UpdateProfileBirthdateRequest,
    responseType: gateway_profile_pb.UpdateProfileBirthdateResponse,
  };

  static readonly updateBirthdatePublic = {
    methodName: "updateBirthdatePublic",
    service: ProfileService,
    requestStream: false,
    responseStream: false,
    requestType: gateway_profile_pb.UpdateProfileBirthdatePublicRequest,
    responseType: gateway_profile_pb.UpdateProfileBirthdatePublicResponse,
  };

  static readonly updateProfileImage = {
    methodName: "updateProfileImage",
    service: ProfileService,
    requestStream: false,
    responseStream: false,
    requestType: gateway_profile_pb.UpdateProfileImageRequest,
    responseType: gateway_profile_pb.UpdateProfileImageResponse,
  };

  static readonly updateProfileCoverImage = {
    methodName: "updateProfileCoverImage",
    service: ProfileService,
    requestStream: false,
    responseStream: false,
    requestType: gateway_profile_pb.UpdateProfileCoverImageRequest,
    responseType: gateway_profile_pb.UpdateProfileCoverImageResponse,
  };

  static readonly readProfile = {
    methodName: "readProfile",
    service: ProfileService,
    requestStream: false,
    responseStream: false,
    requestType: gateway_profile_pb.ReadProfileRequest,
    responseType: gateway_profile_pb.ReadProfileResponse,
  };

}

export class ProfileServiceClient {
  updateProfile() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  updateBirthdate() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  updateBirthdatePublic() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  updateProfileImage() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  updateProfileCoverImage() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  readProfile() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
}
