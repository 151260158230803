import { MingaPermission, MingaRoleType } from 'libs/domain';
import { DisplayNameFormat } from 'libs/util';

import { UserRole } from './UserRole';

export class AttendeeRole extends UserRole {
  readonly roleType = MingaRoleType.ATTENDEE;
  readonly name = 'Attendee';
  readonly displayNameFormat = DisplayNameFormat.FIRST_LAST;
  readonly iconUrl = '';
  readonly iconColor = '#1DCE4D';
  readonly capabilitiesDescription = `Attendees will be allowed to share posts, pictures, and videos`;
  readonly feedPermittable = true;
  readonly galleryPermittable = true;
  readonly groupFeedPermittable = true;
  readonly programManagePermittable = false;
  readonly videoUploadPermittable = true;
  readonly commentPermittable = true;
  readonly groupCreatePermittable = true;
  readonly admin = false;
  readonly requiredFeatures = [];
  readonly sendToMailChimp = false;

  readonly permissions = [
    MingaPermission.ACCOUNT_PROFILE_CHANGE,
    MingaPermission.ASSET_UPLOAD,
    MingaPermission.CONTENT_COMMENT_CREATE,
    MingaPermission.CONTENT_COMMENTS_VIEW,
    MingaPermission.CONTENT_GROUP_ENABLED,
    MingaPermission.CONTENT_LIKES_LIKE,
    MingaPermission.CONTENT_POST_CREATE,
    MingaPermission.CONTENT_REPORT_CREATE,
    MingaPermission.CONTENT_VIDEO_CREATE,
    MingaPermission.CONTENT_POLL_ANSWER,
    MingaPermission.GROUP_CONTENT_POST_CREATE,
    MingaPermission.GROUP_CONTENT_VIDEO_CREATE,
  ];
  readonly overridablePermissions = [];
}
