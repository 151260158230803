import { NgModule } from '@angular/core';

import { FileListContainerModule } from '@app/src/app/components/FileListContainer';
import { FileModule } from '@app/src/app/file';

import { LayoutLegacyContentModule } from '@modules/layout/components/layout-legacy-content/layout-legacy-content.module';

import { CommonUIModules } from '@shared/modules';

import { HomeActionButtonModule } from '../home-action-button/home-action-button.module';
import { HomeFilesComponent } from './home-files.component';

@NgModule({
  imports: [
    // Common dependencies
    CommonUIModules,

    // Minga dependencies
    FileModule,
    FileListContainerModule,
    LayoutLegacyContentModule,
    HomeActionButtonModule,

    // External dependencies
  ],
  declarations: [HomeFilesComponent],
  exports: [HomeFilesComponent],
})
export class HomeFilesModule {}
