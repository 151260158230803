import { IDateRangePreset } from 'libs/domain';
import { date_range_preset_pb as date_range_pb } from 'libs/generated-grpc-ts';

import { dateTimeMessageToDayjsObject, dayJsObjectToDateTimeMsg } from '../';

export const toProto = (
  preset: Partial<IDateRangePreset>,
): date_range_pb.DateRangePreset => {
  const message = new date_range_pb.DateRangePreset();
  if (preset.id) message.setId(preset.id);
  if (preset.name) message.setName(preset.name);
  if (preset.active) message.setActive(preset.active);
  if (preset.startDate)
    message.setStartDate(dayJsObjectToDateTimeMsg(preset.startDate));
  if (preset.endDate)
    message.setEndDate(dayJsObjectToDateTimeMsg(preset.endDate));
  if (preset.endDateIsCurrentDay)
    message.setEndDateIsCurrentDay(preset.endDateIsCurrentDay);

  return message;
};

export const fromProto = (
  message: date_range_pb.DateRangePreset,
): IDateRangePreset => {
  const dateRange: IDateRangePreset = {
    id: message.getId(),
    name: message.getName(),
    active: message.getActive(),
    startDate: dateTimeMessageToDayjsObject(message.getStartDate()),

    endDateIsCurrentDay: message.getEndDateIsCurrentDay(),
  };

  if (message.getEndDate()) {
    dateRange.endDate = dateTimeMessageToDayjsObject(message.getEndDate());
  }

  return dateRange;
};
