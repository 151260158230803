import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';

import { MgIconModule } from '@app/src/app/icon';

import { TextModule } from '@shared/components/text';
import { CommonUIModules } from '@shared/modules';

import { FormModule } from '../form';
import { OverlayModule } from '../modal-overlay';
import { TooltipModule } from '../tooltip';
import { MmDatePresetsEditComponent } from './components/mm-date-presets-edit/mm-date-presets-edit.component';

@NgModule({
  imports: [
    // Minga dependencies
    TextModule,
    MgIconModule,
    OverlayModule,
    FormModule,
    CommonUIModules,
    ReactiveFormsModule,
    TooltipModule,

    // External dependencies
    CommonModule,
    FlexLayoutModule,
  ],
  declarations: [MmDatePresetsEditComponent],
  exports: [MmDatePresetsEditComponent],
})
export class DatePresetsModule {}
