import { Injectable } from '@angular/core';

import * as google_protobuf_empty_pb from 'google-protobuf/google/protobuf/empty_pb.js';
import {
  AutomationAction,
  CreateAutomationActionPayload,
  ListAutomationActionPayload,
  UpdateAutomationActionPayload,
} from 'libs/domain';
import {
  automation_actions_ng_grpc_pb,
  automation_actions_pb,
} from 'libs/generated-grpc-web';
import { AutomationActionsMapper } from 'libs/shared-grpc';

import { ErrorHandlerService } from '../error-handler';

@Injectable()
export class AutomationActionsService {
  /** Service Constructor */
  constructor(
    private _automationActions: automation_actions_ng_grpc_pb.AutomationActions,
    private _errorHandler: ErrorHandlerService,
  ) {}

  public async fetchAll(
    automationId: number,
  ): Promise<ListAutomationActionPayload[]> {
    try {
      const request = new automation_actions_pb.ListAutomationActionsRequest();
      request.setAutomationId(automationId);
      const response = await this._automationActions.listAutomationActions(
        request,
      );
      return await Promise.all(
        response
          .getAutomationActionsList()
          .map(AutomationActionsMapper.fromProto),
      );
    } catch (error) {
      throw this._errorHandler.gateWayError(
        'failed to fetch all automation actions',
        error,
        true,
      );
    }
  }

  public async fetch(actionId: number): Promise<AutomationAction> {
    try {
      const request = new automation_actions_pb.GetAutomationActionRequest();
      request.setId(actionId);
      const response = await this._automationActions.getAutomationAction(
        request,
      );
      return await AutomationActionsMapper.fromProto(response);
    } catch (error) {
      throw this._errorHandler.gateWayError(
        'failed to fetch automation action',
        error,
        true,
      );
    }
  }

  public async create(
    payload: CreateAutomationActionPayload,
  ): Promise<AutomationAction> {
    try {
      const request = new automation_actions_pb.CreateAutomationActionRequest();
      request.setAutomationAction(
        await AutomationActionsMapper.toProto(payload),
      );
      const response = await this._automationActions.createAutomationAction(
        request,
      );
      return await AutomationActionsMapper.fromProto(response);
    } catch (error) {
      throw this._errorHandler.gateWayError(
        'failed to create automation action',
        error,
        true,
      );
    }
  }

  public async update(
    payload: UpdateAutomationActionPayload,
  ): Promise<AutomationAction> {
    try {
      const request = new automation_actions_pb.UpdateAutomationActionRequest();
      request.setAutomationAction(
        await AutomationActionsMapper.toProto(payload),
      );
      const response = await this._automationActions.updateAutomationAction(
        request,
      );
      return await AutomationActionsMapper.fromProto(response);
    } catch (error) {
      throw this._errorHandler.gateWayError(
        'failed to update automation action',
        error,
        true,
      );
    }
  }

  public async delete(
    actionId: number,
  ): Promise<google_protobuf_empty_pb.Empty.AsObject> {
    try {
      const request = new automation_actions_pb.DeleteAutomationActionRequest();
      request.setId(actionId);
      const response = await this._automationActions.deleteAutomationAction(
        request,
      );
      return response.toObject();
    } catch (error) {
      throw this._errorHandler.gateWayError(
        'failed to delete automation action',
        error,
        true,
      );
    }
  }
}
