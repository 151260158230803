// GENERATED CODE -- DO NOT EDIT!

import {grpc} from '@improbable-eng/grpc-web';

import * as studio_content_studio_stock_photos_pb from '../studio_content/studio_stock_photos_pb';
import * as image_image_pb from '../image/image_pb';
import * as common_stream_pb from '../common/stream_pb';

export class StudioStockPhotos {
  static readonly serviceName = "StudioStockPhotos";

  static readonly StreamStockPhotos = {
    methodName: "StreamStockPhotos",
    service: StudioStockPhotos,
    requestStream: false,
    responseStream: true,
    requestType: common_stream_pb.StreamID,
    responseType: studio_content_studio_stock_photos_pb.StudioStockPhotosStreamResponse,
  };

  static readonly StreamStockPhotosControl = {
    methodName: "StreamStockPhotosControl",
    service: StudioStockPhotos,
    requestStream: false,
    responseStream: false,
    requestType: common_stream_pb.StreamControl,
    responseType: common_stream_pb.StreamControlResponse,
  };

}

export class StudioStockPhotosClient {
  streamStockPhotos() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  streamStockPhotosControl() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
}
