import { SectionType, StudentSection } from 'libs/domain';
import { student_schedule_pb as proto } from 'libs/generated-grpc-ts';

import {
  dateObjectToDateTimeMessage,
  dateTimeMessageToDateObject,
} from '../util/dateAndTime';

export const toProto = (ss: StudentSection): proto.StudentSection => {
  const msg = new proto.StudentSection();
  msg.setId(ss.id);
  msg.setName(ss.name);
  if (ss.period) msg.setPeriod(ss.period);
  if (ss.term) msg.setTerm(ss.term);
  if (ss.date) {
    msg.setDate(dateObjectToDateTimeMessage(ss.date));
  }
  if (ss.startTime) msg.setStartTime(ss.startTime);
  if (ss.endTime) msg.setEndTime(ss.endTime);
  if (ss.teachers) msg.setTeachersList(ss.teachers);
  if (ss.location) msg.setLocation(ss.location);
  if (ss.type) msg.setType(ss.type);
  if (ss.sectionId) msg.setSectionId(ss.sectionId);
  if (ss.periodId) msg.setPeriodId(ss.periodId);
  if (ss.termId) msg.setTermId(ss.termId);
  if (ss.sectionNumber) msg.setSectionNumber(ss.sectionNumber);
  if (ss.schoolId) msg.setSchoolId(ss.schoolId);

  return msg;
};

export const fromProto = (msg: proto.StudentSection): StudentSection => {
  const ss: StudentSection = {
    id: msg.getId(),
    name: msg.getName(),
  };
  if (msg.getPeriod()) ss.period = msg.getPeriod();
  if (msg.getTerm()) ss.term = msg.getTerm();
  if (msg.getDate()) {
    ss.date = dateTimeMessageToDateObject(msg.getDate());
  }
  if (msg.getStartTime()) ss.startTime = msg.getStartTime();
  if (msg.getEndTime()) ss.endTime = msg.getEndTime();
  if (msg.getTeachersList()) ss.teachers = msg.getTeachersList();
  if (msg.getLocation()) ss.location = msg.getLocation();
  if (msg.getType()) ss.type = msg.getType() as SectionType;
  if (msg.getSectionId()) ss.sectionId = msg.getSectionId();
  if (msg.getPeriodId()) ss.periodId = msg.getPeriodId();
  if (msg.getTermId()) ss.termId = msg.getTermId();
  if (msg.getSectionNumber()) ss.sectionNumber = msg.getSectionNumber();
  if (msg.getSchoolId()) ss.schoolId = msg.getSchoolId();

  return ss;
};
