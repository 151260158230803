import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostListener,
  Input,
  Output,
} from '@angular/core';

import * as mgUtil from 'libs/util';
import { day } from 'libs/day';
import { gateway, image_pb } from 'libs/generated-grpc-web';

import { ContentEvents } from '@app/src/app/minimal/services/ContentEvents';
import { ReportService } from '@app/src/app/services/Report';
import { mgResolveImageUrl } from '@app/src/app/util/asset';
import textClipperSafe from '@app/src/app/util/text-clipper-safe';

@Component({
  selector: 'mg-detailed-moderation-item',
  templateUrl: './MgDetailedModerationItem.element.html',
  styleUrls: ['./MgDetailedModerationItem.element.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MgDetailedModerationItemElement {
  @Input()
  content?: gateway.moderation_pb.DetailedModerationResult.AsObject;

  @Output()
  openContentCallback: EventEmitter<CustomEvent>;

  @Output()
  openImagesCallback: EventEmitter<CustomEvent>;

  @Input()
  showFullBody: boolean = false;

  @Input()
  showAllHistory: boolean = false;

  @Input()
  showModeratedText: boolean = false;

  @Input()
  showMinga: boolean = false;

  @Input()
  showLongDateFormat: boolean = false;

  @Input()
  updateStatusFromStream: boolean = false;

  constructor(
    private reportService: ReportService,
    private contentEvents: ContentEvents,
  ) {
    this.openContentCallback = new EventEmitter();
    this.openImagesCallback = new EventEmitter();
  }

  @HostListener('click', ['$event'])
  _onClick(e: MouseEvent) {
    this.showModeratedText = !this.showModeratedText;

    if ('composedPath' in e) {
      const paths = e.composedPath();
      if (paths.length && !this.showFullBody) {
        const firstPath = <HTMLElement>paths[0];
        if (firstPath.className == 'more-report-body') {
          this.noClick(e);

          this.showFullBody = !this.showFullBody;
          window.dispatchEvent(new CustomEvent('resize'));
          return;
        }
      }
      this.openModeratedResult(e);
    }
  }

  /**
   * From the content data returns the resolved status, also updates local
   * storage if the updateStatusFromStream property is set to true.
   * @param content the report stream item
   * @returns bool that is true if this context hash is resolved
   */
  updateFromStream(
    content: gateway.moderation_pb.DetailedModerationResult.AsObject,
    updateStatusFromStream: boolean,
  ) {
    if (typeof this.reportService == 'object') {
      if (updateStatusFromStream) {
        const isResolved = this.isResolved(content);
        // prioritize child
        this.reportService.setReportedUgc(
          {
            contextHash: content.contentContextHash,
            galleryPhotoUuid: content.galleryPhotoUuid,
          },
          isResolved,
        );
        return true;
      }
      this.reportService.setDeletedContent(content);
    }
    return false;
  }

  isReported(
    content:
      | gateway.moderation_pb.DetailedModerationResult.AsObject
      | gateway.moderation_pb.DetailedModerationResult.ContentHistory.AsObject,
  ) {
    return content.status == mgUtil.ContentStatus.REPORTED;
  }

  isResolved(
    content:
      | gateway.moderation_pb.DetailedModerationResult.AsObject
      | gateway.moderation_pb.DetailedModerationResult.ContentHistory.AsObject,
  ) {
    return (
      content.status == mgUtil.ContentStatus.RESOLVED ||
      content.status == mgUtil.ContentStatus.OVERRIDDEN
    );
  }

  isDeleted(
    result:
      | gateway.moderation_pb.DetailedModerationResult.AsObject
      | gateway.moderation_pb.DetailedModerationResult.ContentHistory.AsObject,
  ) {
    return (
      result.status == mgUtil.ContentStatus.DELETED ||
      result.status == mgUtil.ContentStatus.BLOCKED
    );
  }

  getHistoryItemPrefixText(
    historyItem: gateway.moderation_pb.DetailedModerationResult.ContentHistory.AsObject,
  ) {
    let prefix: string = '';
    switch (historyItem.status) {
      case mgUtil.ContentStatus.REPORTED:
        prefix += 'Reported';
        break;
      case mgUtil.ContentStatus.RESOLVED:
        prefix += 'Resolved';
        break;
      case mgUtil.ContentStatus.OVERRIDDEN:
        prefix += 'Published';
        break;
      case mgUtil.ContentStatus.DELETED:
      case mgUtil.ContentStatus.BLOCKED:
        prefix += 'Deleted';
    }

    return prefix;
  }

  getContentTypeText(
    content: gateway.moderation_pb.DetailedModerationResult.AsObject,
  ) {
    if (!content || !content.contentType) return '';
    let typeText = content.contentType;
    typeText = typeText.toLowerCase();
    typeText = typeText[0].toUpperCase() + typeText.substring(1);
    // replace any underscores with spaces
    typeText = typeText.replace(/_/g, ' ');
    return typeText;
  }

  getHistoryItemReason(
    historyItem: gateway.moderation_pb.DetailedModerationResult.ContentHistory.AsObject,
  ) {
    if (
      mgUtil.ContentStatusUtil.hasReason(
        mgUtil.ContentStatus[historyItem.status],
      )
    ) {
      return mgUtil.ReportReason.toString(historyItem.reasonIndex);
    }
    return '';
  }

  getHistoryList(
    content: gateway.moderation_pb.DetailedModerationResult.AsObject,
    showAllHistory: boolean = false,
  ) {
    if (showAllHistory) {
      return content.contentHistoryList;
    } else {
      return content.contentHistoryList.slice(0, 1);
    }
  }

  openContent(e: MouseEvent) {
    this.noClick(e);

    const ev = new CustomEvent('opencontent', { detail: this.content });

    // This isn't working
    // super.dispatchEvent(ev);

    // So we're doing this.
    if (this.openContentCallback) {
      this.openContentCallback.emit(ev);
    }
  }

  toggleShowAllHistory(e: Event) {
    this.noClick(e);

    this.showAllHistory = !this.showAllHistory;
    // setTimeout(() => {
    window.dispatchEvent(new CustomEvent('resize'));
    // }, 100);
  }

  noClick(e: Event) {
    e.preventDefault();
    e.stopPropagation();
    e.stopImmediatePropagation();
  }

  clickContextMenu(e) {
    this.noClick(e);
    const { pageX, pageY } = e;
    if (this.content.contentContextHash) {
      this.contentEvents.emitContextMenuOpen({
        contentContextHash:
          this.content.actionContextHash || this.content.contentContextHash,
        contentHash: null,
        x: pageX,
        y: pageY,
        disableDelete: this.isDeleted(this.content),
      });
    }
  }

  checkmark(value: any) {
    if (typeof value === 'string') {
      switch (value) {
        case 'true':
        case '1':
        case 'yes':
          value = true;
          break;
        case 'false':
        case '0':
        case 'no':
          value = false;
          break;
      }
    }

    if (!!value) {
      return '🗹';
    } else {
      return '☒';
    }
  }

  dateFormat(date: number, longFormat: boolean = this.showLongDateFormat) {
    if (!date) return '';

    const format = !!longFormat ? 'llll' : 'l h:mm A';
    return day(date).format(format);
  }

  getOverriderName(name: string) {
    const unknown = '[unknown]';
    return name || unknown;
  }

  moderatedBodyHTML(html: string, showFullBody: boolean = false) {
    if (showFullBody) {
      return html;
    }
    // strip html
    html = html ? html.replace(/<[^>]+>/g, '') : '';

    const div = document.createElement('div');
    div.innerHTML = html;

    const LINE_WEIGHT = 25;
    // @NOTE: This is only here because newLineWeight is not working.
    const lineCount = div.querySelectorAll('p,br').length;
    const clipAmount = Math.max(25, 45 - lineCount * LINE_WEIGHT);

    return textClipperSafe(html, clipAmount, {
      html: true,
      // @NOTE: newlineWeight is broken
      // newlineWeight: LINE_WEIGHT,
      indicator: `<span class="more-indicator-wrap"><a href="javascript:;" class="more-report-body"></a></span>`,
    });
  }

  getShowBottomFooterLink(
    result: gateway.moderation_pb.DetailedModerationResult.AsObject,
  ) {
    return (
      result &&
      result.contentHistoryList &&
      result.contentHistoryList.length > 1
    );
  }

  getMissingTextText(aiPassed: boolean) {
    if (aiPassed) {
      return `[Image(s) Passed Moderation]`;
    } else {
      return `[Inappropriate Image(s) Blocked]`;
    }
  }

  geAssetSizeKeys(): string[] {
    return ['cardbanner', 'longcardbanner', 'raw'];
  }

  showModerationInfo(
    detailsList: gateway.moderation_pb.DetailedModerationResult.ProviderDetails.AsObject[],
  ) {
    // check to see if we have tags
    for (let detail of detailsList) {
      if (detail.tagsList && detail.tagsList.length) {
        return true;
      }
    }
    return false;
  }

  showItemTags(
    tags: gateway.moderation_pb.DetailedModerationResult.ProviderDetails.AsObject,
  ) {
    // check to see if we have tags
    if (tags.tagsList.length > 0) {
      return true;
    }
    return false;
  }

  getAssetUrl(imageInfo: image_pb.ImageInfo.AsObject) {
    if (!imageInfo) {
      return '';
    }

    return mgResolveImageUrl(imageInfo);
  }

  getMingaName(
    mingaName: string,
    mingaHash: string,
    showMinga: boolean = false,
  ) {
    if (showMinga) {
      return mingaName || mingaHash
        ? mingaName || `minga hash: ${mingaHash}`
        : `[unknown minga]`;
    }
    return null;
  }

  getImageModerationDetails() {
    console.log(`@TODO: getImageModerationDetails`);
    return {};
    // const googleEngineResult = this.result.aiEngineResultList.filter(
    //   item => item.providerName === 'GoogleVision');

    // if(googleEngineResult[0].details) {
    //   return JSON.parse(googleEngineResult[0].details);
    // }
  }

  viewButtonClick(e) {
    // this.noClick(e);
    this.openContent(e);
  }

  openContentImage(e) {
    if (e.fileurl) {
      const ev = new CustomEvent('openImages', {
        detail: { content: this.content, imageIndex: e.index },
      });

      if (this.openImagesCallback) {
        this.openImagesCallback.emit(ev);
      }

      return;
    }
  }

  openModeratedResult(e) {
    if (
      this.content.actionContextHash ||
      this.content.contentContextHash ||
      this.content.parentContextHash ||
      this.content.galleryPhotoUuid
    ) {
      this.openContent(e);
    } else {
      this.noClick(e);
    }
  }

  displayHumanIndex(index) {
    return index + 1;
  }
}
