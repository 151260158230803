import * as path from 'path';

const btoa: any =
  (<any>globalThis).btoa ||
  ((str: string) => Buffer.from(str).toString('base64'));

const atob: any =
  (<any>globalThis).atob ||
  ((str: string) => Buffer.from(str, 'base64').toString());

export interface ICloudFlareUploadMetaRaw {
  c: string;
  e: string;
  v: string;
  [keyname: string]: any;
}

export interface ICloudFlareUploadMeta {
  country: string;
  environment: string;
  clientVersion: string;
  [keyname: string]: any;
}

function metaRawToMeta(
  metaRaw: ICloudFlareUploadMetaRaw,
): ICloudFlareUploadMeta {
  const meta: ICloudFlareUploadMeta = {
    country: metaRaw.c,
    environment: metaRaw.e,
    clientVersion: metaRaw.v,
    ...metaRaw,
  };

  delete meta['c'];
  delete meta['e'];
  delete meta['v'];

  return meta;
}

function metaToMetaRaw(meta: ICloudFlareUploadMeta): ICloudFlareUploadMetaRaw {
  const metaRaw: ICloudFlareUploadMetaRaw = {
    c: meta.country,
    e: meta.environment,
    v: meta.clientVersion,
    ...meta,
  };

  delete metaRaw['country'];
  delete metaRaw['environment'];
  delete metaRaw['clientVersion'];

  return metaRaw;
}

const B64_START = '--';

/**
 * Encode 'meta' into name and return new name
 */
export function cloudflareUrlMetaEncode(
  name: string,
  meta: ICloudFlareUploadMeta,
): string {
  const extname = path.extname(name);
  const basename = path.basename(name, extname);

  const metaRaw = metaToMetaRaw(meta);
  const base64Json = btoa(JSON.stringify(metaRaw));
  const newBasename = basename + B64_START + base64Json + extname;

  return newBasename;
}

/**
 * Decode the `ICloudFlareUploadMeta` originally encoded by
 * `cloudflareUrlMetaEncode()`
 */
export function cloudflareUrlMetaDecode(name: string): ICloudFlareUploadMeta {
  const extname = path.extname(name);
  const basename = path.basename(name, extname);

  const metaStr = basename.substr(
    basename.lastIndexOf(B64_START) + B64_START.length,
  );
  const metaRaw: ICloudFlareUploadMetaRaw = JSON.parse(
    atob(metaStr),
  ) as ICloudFlareUploadMetaRaw;
  return metaRawToMeta(metaRaw);
}
