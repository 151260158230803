import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { EffectsModule } from '@ngrx/effects';
import { ActionReducerMap, StoreModule } from '@ngrx/store';

import { MgAvatarModule } from '@app/src/app/elements/MgAvatar';
import { MgMentionModule } from '@app/src/app/elements/MgMention';
import { MgSpinnerModule } from '@app/src/app/spinner';

import { MentionableUIComponent } from './components/MentionableUI/MentionableUI.component';
import { MgMentionable2Directive } from './directives/Mentionable2.directive';

export const reducers: ActionReducerMap<any> = {};

@NgModule({
  imports: [
    // Minga dependencies
    MgMentionModule,
    MgSpinnerModule,
    MgAvatarModule,

    // External dependencies
    StoreModule.forFeature('mentions', reducers),
    EffectsModule.forFeature([]),
    CommonModule,
  ],
  declarations: [MentionableUIComponent, MgMentionable2Directive],

  exports: [MentionableUIComponent, MgMentionable2Directive],
})
export class MentionsModule {}
