// source: content/poll/answer.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

import * as jspb from 'google-protobuf';
export namespace SetPollAnswer {
  export interface AsObject {
    pollContentContextHash: string;
    pollChoiceId: number;
  }
}
export class SetPollAnswer extends jspb.Message {
  static readonly displayName = "SetPollAnswer";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): SetPollAnswer.AsObject {
    return SetPollAnswer.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!SetPollAnswer} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: SetPollAnswer) {
    var f: any;
    var obj: any = {
      pollContentContextHash: jspb.Message.getFieldWithDefault(msg, 1, ""),
      pollChoiceId: jspb.Message.getFieldWithDefault(msg, 2, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!SetPollAnswer}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new SetPollAnswer;
    return SetPollAnswer.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!SetPollAnswer} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!SetPollAnswer}
   */
  static deserializeBinaryFromReader(msg: SetPollAnswer, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setPollContentContextHash(value1);
        break;
      case 2:
        var value2 = /** @type {number} */ (reader.readUint32());
        msg.setPollChoiceId(value2);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!SetPollAnswer} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: SetPollAnswer, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getPollContentContextHash();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
    f = message.getPollChoiceId();
    if (f !== 0) {
      writer.writeUint32(
        2,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    SetPollAnswer.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string poll_content_context_hash = 1;
   * @return {string}
   */
  getPollContentContextHash(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setPollContentContextHash(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


  /**
   * optional uint32 poll_choice_id = 2;
   * @return {number}
   */
  getPollChoiceId(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
  };


  /** @param {number} value */
  setPollChoiceId(value: number) {
    jspb.Message.setProto3IntField(this, 2, value);
  }


}
export namespace PollResults {
  export interface AsObject {
    totalCount: number;
    pollChoiceCountMap: [number, number][];
  }
}
export class PollResults extends jspb.Message {
  static readonly displayName = "PollResults";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): PollResults.AsObject {
    return PollResults.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!PollResults} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: PollResults) {
    var f: any;
    var obj: any = {
      totalCount: jspb.Message.getFieldWithDefault(msg, 1, 0),
      pollChoiceCountMap: (f = msg.getPollChoiceCountMap()) ? f.toObject(includeInstance, undefined) : []
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!PollResults}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new PollResults;
    return PollResults.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!PollResults} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!PollResults}
   */
  static deserializeBinaryFromReader(msg: PollResults, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {number} */ (reader.readUint32());
        msg.setTotalCount(value1);
        break;
      case 2:
        var value2 = msg.getPollChoiceCountMap();
        reader.readMessage(value2, function(message, reader) {
          jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readUint32, jspb.BinaryReader.prototype.readUint32, null, 0);
           });
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!PollResults} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: PollResults, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getTotalCount();
    if (f !== 0) {
      writer.writeUint32(
        1,
        f
      );
    }
    f = message.getPollChoiceCountMap(true);
    if (f && f.getLength() > 0) {
      f.serializeBinary(2, writer, jspb.BinaryWriter.prototype.writeUint32, jspb.BinaryWriter.prototype.writeUint32);
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    PollResults.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional uint32 total_count = 1;
   * @return {number}
   */
  getTotalCount(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
  };


  /** @param {number} value */
  setTotalCount(value: number) {
    jspb.Message.setProto3IntField(this, 1, value);
  }


  /**
   * map<uint32, uint32> poll_choice_count = 2;
   * @param {boolean=} opt_noLazyCreate Do not create the map if
   * empty, instead returning `undefined`
   * @return {!jspb.Map<number,number>}
   */
  getPollChoiceCountMap(): jspb.Map<number,number>;
  getPollChoiceCountMap(noLazyCreate: true): jspb.Map<number,number>|undefined;
  getPollChoiceCountMap(noLazyCreate: false): jspb.Map<number,number>;
  getPollChoiceCountMap(opt_noLazyCreate: boolean = false): jspb.Map<number,number> {
    return /** @type {!jspb.Map<number,number>} */ (
        jspb.Message.getMapField(this, 2, opt_noLazyCreate,
        null));
  }


  /**
   * Clears values from the map. The map will be non-null.
   */
  clearPollChoiceCountMap() {
    this.getPollChoiceCountMap().clear();
  }


}
