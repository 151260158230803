import {
  ChallengeApprovalState,
  ChallengePrivacy,
  ChallengeType,
  IChallengeResponse,
} from 'libs/domain';
import { challenge_response_pb } from 'libs/generated-grpc-ts';
import { $enum } from 'ts-enum-util';

import { IVideoUploadProtoMapper } from '../video_upload';

export namespace IChallengeResponseProtoMapper {
  export function fromChallengeResponseInfoMessage(
    message: challenge_response_pb.ChallengeResponseInfo,
  ): IChallengeResponse {
    const videoUpload = IVideoUploadProtoMapper.fromIVideoUploadMessage(
      message.getVideo(),
    );

    return {
      body: message.getBody(),
      contentHash: message.getContentHash(),
      privacy: $enum(ChallengePrivacy).asValueOrDefault(
        message.getPrivacy(),
        ChallengePrivacy.PUBLIC,
      ),
      type: $enum(ChallengeType).asValueOrDefault(
        message.getType(),
        ChallengeType.TEXT,
      ),
      video: videoUpload,
      videoId: null,
      backgroundBannerHash: message.getBackgroundBannerHash(),
      backgroundColor: message.getBackgroundColor(),
      // contentid
      asset: message.getAssetPath(),
      ownerContextHash: message.getOwnerContextHash(),
      approved: $enum(ChallengeApprovalState).asValueOrDefault(
        message.getApproved(),
        ChallengeApprovalState.PENDING,
      ),
    };
  }

  export function toChallengeResponseInfoMessage(
    challenge: IChallengeResponse,
  ): challenge_response_pb.ChallengeResponseInfo {
    const message = new challenge_response_pb.ChallengeResponseInfo();
    if (challenge.backgroundBannerHash) {
      message.setBackgroundBannerHash(challenge.backgroundBannerHash);
    }
    if (challenge.backgroundColor) {
      message.setBackgroundColor(challenge.backgroundColor);
    }
    if (challenge.asset) {
      message.setAssetPath(challenge.asset);
    }

    if (challenge.contentHash) {
      message.setContentHash(challenge.contentHash);
    }
    if (challenge.ownerContextHash) {
      message.setOwnerContextHash(challenge.ownerContextHash);
    }

    message.setBody(challenge.body);
    message.setPrivacy(challenge.privacy);
    message.setType(challenge.type);

    if (challenge.video) {
      const video = IVideoUploadProtoMapper.toIVideoUploadMessage(
        challenge.video,
      );
      message.setVideo(video);
    }

    if (challenge.approved) {
      message.setApproved(challenge.approved);
    }

    return message;
  }
}
