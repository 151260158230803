import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { Router, RouterOutlet } from '@angular/router';

import { BehaviorSubject, ReplaySubject, Subject, combineLatest } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';

import { PortalLayoutService } from '@shared/components/portal-layout';
import { RouterOutletOption } from '@shared/constants';
import { NavigationService } from '@shared/services/navigation';

import {
  GlobalSearchMessage,
  GlobalSearchQueryParam,
  SEARCH_INPUT_DEBOUCE_TIME,
} from './constants';
import { GlobalSearchService } from './services';
import { SearchScopeRouteData } from './types';

@Component({
  selector: 'mg-global-search',
  templateUrl: './global-search.component.html',
  styleUrls: ['./global-search.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GlobalSearchComponent implements OnInit, AfterViewInit, OnDestroy {
  // Children

  @ViewChild('searchRouterOutlet', { static: true })
  searchRouterOutlet!: RouterOutlet;

  // Constants

  public readonly MSG = GlobalSearchMessage;

  // Cleanup

  private readonly _destroyedSubject = new ReplaySubject<void>(1);

  // Events

  /** Input focus event */
  public inputFocusSubject = new Subject();

  /**
   * Search field control
   *
   * Main search control used to filter the search results
   */
  public readonly fieldControl = this._fb.control('');

  // State

  private readonly _isActivatedSubject = new BehaviorSubject<boolean>(false);
  public readonly isActivated$ = this._isActivatedSubject.asObservable();

  private readonly _searchScopeConfig =
    new BehaviorSubject<SearchScopeRouteData>(null);
  public readonly searchScopeConfig$ = this._searchScopeConfig.asObservable();

  /** Component constructor */
  constructor(
    public navigation: NavigationService,
    public globalSearch: GlobalSearchService,
    public portalLayout: PortalLayoutService,
    public router: Router,
    private _fb: UntypedFormBuilder,
  ) {}

  ngOnInit(): void {
    this._updateUrlSubscription();
    this._updateGlobalSearchValueSubscription();
  }

  ngAfterViewInit(): void {
    if (this.searchRouterOutlet.isActivated) {
      this._isActivatedSubject.next(true);
    }
  }

  ngOnDestroy(): void {
    this._destroyedSubject.next();
    this._destroyedSubject.complete();
    this._isActivatedSubject.complete();
  }

  @HostListener('document:keydown.escape', ['$event'])
  protected closeShortcut(event: KeyboardEvent) {
    this.close();
  }

  @HostListener('document:keydown.control.shift.k', ['$event'])
  protected async openSearch(event: KeyboardEvent) {
    this.globalSearch.scope$.subscribe(scope => {
      if (!scope) return;
      this.router.navigate(['.', { outlets: { search: `${scope}` } }]);
    });
  }

  public close() {
    this.fieldControl.reset('', { emitEvent: false });
    this.globalSearch.close();
  }

  public hasActivated() {
    this.searchRouterOutlet.activatedRoute.queryParams.subscribe(params => {
      const query = params[GlobalSearchQueryParam.QUERY];
      if (query) this.fieldControl.setValue(query);
    });
    this._searchScopeConfig.next(
      this.searchRouterOutlet.activatedRouteData as SearchScopeRouteData,
    );
    this._isActivatedSubject.next(true);
    this.focusInput();
  }

  public hasDeactivated() {
    this._isActivatedSubject.next(false);
  }

  public focusInput() {
    requestAnimationFrame(() => this.inputFocusSubject.next());
  }

  private _updateGlobalSearchValueSubscription() {
    return this.fieldControl.valueChanges
      .pipe(debounceTime(SEARCH_INPUT_DEBOUCE_TIME))
      .subscribe(v => this.globalSearch.setValue(v));
  }

  private _updateUrlSubscription() {
    return combineLatest([this.globalSearch.scope$, this.globalSearch.value$])
      .pipe(takeUntil(this._destroyedSubject))
      .subscribe(([scope, value]) => {
        if (!scope || !value) return;
        this.router.navigate(
          [
            '',
            {
              outlets: {
                [RouterOutletOption.SEARCH]: scope,
              },
            },
          ],
          {
            replaceUrl: true,
            queryParams: { [GlobalSearchQueryParam.QUERY]: value.trim() },
          },
        );
      });
  }
}
