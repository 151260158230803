import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { MgIconModule } from '@app/src/app/icon';

import { CollapsibleComponent } from './collapsible.component';

@NgModule({
  declarations: [CollapsibleComponent],
  imports: [
    // Minga dependencies
    MgIconModule,

    // External dependencies
    CommonModule,
  ],
  exports: [CollapsibleComponent],
})
export class CollapsibleModule {}
