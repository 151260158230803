import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';

import { PermissionsModule } from '@app/src/app/permissions';
import { MgSpinnerModule } from '@app/src/app/spinner';

import { ManagerOverlayModule } from '@shared/components/manager-overlay';
import { MembershipListTableModule } from '@shared/components/membership-list-table';
import { SettingsModule } from '@shared/components/settings/settings.module';
import { TextModule } from '@shared/components/text';
import { LoadingPipesModule } from '@shared/pipes';

import { FtmSettingsComponent } from './ftm-settings.component';

@NgModule({
  imports: [
    // Minga dependencies
    ManagerOverlayModule,
    SettingsModule,
    PermissionsModule,
    MgSpinnerModule,
    LoadingPipesModule,
    MembershipListTableModule,
    TextModule,

    // External dependencies
    CommonModule,
    FlexLayoutModule,
  ],
  declarations: [FtmSettingsComponent],
  exports: [FtmSettingsComponent],
})
export class FtmSettingsModule {}
