import { designed_svg_pb } from 'libs/generated-grpc-ts';
import {
  DesignedSvgData,
  DesignedSvgManipulations,
  IDesignedSvg,
  IDesignedSvgManipulation,
} from 'libs/shared';

export namespace DesignedSvgMapper {
  export function fromIDesignedSvg(
    svg: IDesignedSvg,
  ): designed_svg_pb.DesignedSvg {
    const msg = new designed_svg_pb.DesignedSvg();

    msg.setSvgUrl(svg.svgUrl);
    const svgDataMap = msg.getSvgDataMap();

    for (const key in svg.svgData) {
      if (!svg.svgData[key]) continue;
      svgDataMap.set(key, JSON.stringify(svg.svgData[key]));
    }

    const svgManipulationsMap = msg.getSvgManipulationsMap();
    if (svgManipulationsMap) {
      for (const key in svg.svgManipulations) {
        if (!svg.svgManipulations[key]) continue;

        svgManipulationsMap.set(key, JSON.stringify(svg.svgManipulations[key]));
      }
    }

    if (svg.stockImageSlots) {
      msg.setStockImageSlotList(svg.stockImageSlots);
    }

    return msg;
  }

  export function toIDesignedSvg(
    msg: designed_svg_pb.DesignedSvg,
  ): IDesignedSvg {
    const svgUrl = msg.getSvgUrl();
    const svgData: DesignedSvgData = {};
    const svgManipulations: DesignedSvgManipulations = {};
    const svgDataMap = msg.getSvgDataMap();
    const svgManipulationsMap = msg.getSvgManipulationsMap();
    const stockImageSlots = msg.getStockImageSlotList();

    svgDataMap.forEach((value, key) => {
      if (typeof value === 'undefined') {
        return;
      }
      svgData[key] = JSON.parse(value) as string;
    });

    if (svgManipulationsMap) {
      svgManipulationsMap.forEach((value, key) => {
        if (!value) {
          return;
        }
        svgManipulations[key] = JSON.parse(value) as IDesignedSvgManipulation;
      });
    }

    return { svgUrl, svgData, svgManipulations, stockImageSlots };
  }

  /**
   * @NOTE There are certain parts of the code base that have the .AsObject
   * version of the designed svg. You should prefer to use `toIDesignedSvg`
   * instead of converting the message to an AsObject and using this.
   */
  export function asObjectToIDesignedSvg(
    msg: designed_svg_pb.DesignedSvg.AsObject,
  ): IDesignedSvg {
    const svgUrl = msg.svgUrl;
    const svgData: DesignedSvgData = {};
    const svgManipulations: DesignedSvgManipulations = {};
    let stockImageSlots: string[] = [];

    for (const [key, value] of msg.svgDataMap) {
      if (typeof value === 'undefined') {
        continue;
      }

      svgData[key] = JSON.parse(value) as string;
    }

    if (msg.svgManipulationsMap) {
      for (const [key, value] of msg.svgManipulationsMap) {
        if (!value) {
          continue;
        }

        svgManipulations[key] = JSON.parse(value) as IDesignedSvgManipulation;
      }
    }

    if (msg.stockImageSlotList) {
      stockImageSlots = msg.stockImageSlotList;
    }

    return { svgUrl, svgData, svgManipulations, stockImageSlots };
  }

  export function fromAsObject(
    obj: designed_svg_pb.DesignedSvg.AsObject,
  ): designed_svg_pb.DesignedSvg {
    return fromIDesignedSvg(asObjectToIDesignedSvg(obj));
  }
}
