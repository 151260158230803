// source: image/image.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

import * as jspb from 'google-protobuf';
export namespace ImageSize {
  export interface AsObject {
    path: string;
    width: number;
    height: number;
  }
}
export class ImageSize extends jspb.Message {
  static readonly displayName = "ImageSize";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): ImageSize.AsObject {
    return ImageSize.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!ImageSize} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: ImageSize) {
    var f: any;
    var obj: any = {
      path: jspb.Message.getFieldWithDefault(msg, 1, ""),
      width: jspb.Message.getFieldWithDefault(msg, 2, 0),
      height: jspb.Message.getFieldWithDefault(msg, 3, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!ImageSize}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new ImageSize;
    return ImageSize.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!ImageSize} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!ImageSize}
   */
  static deserializeBinaryFromReader(msg: ImageSize, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setPath(value1);
        break;
      case 2:
        var value2 = /** @type {number} */ (reader.readInt32());
        msg.setWidth(value2);
        break;
      case 3:
        var value3 = /** @type {number} */ (reader.readInt32());
        msg.setHeight(value3);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!ImageSize} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: ImageSize, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getPath();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
    f = message.getWidth();
    if (f !== 0) {
      writer.writeInt32(
        2,
        f
      );
    }
    f = message.getHeight();
    if (f !== 0) {
      writer.writeInt32(
        3,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    ImageSize.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string path = 1;
   * @return {string}
   */
  getPath(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setPath(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


  /**
   * optional int32 width = 2;
   * @return {number}
   */
  getWidth(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
  };


  /** @param {number} value */
  setWidth(value: number) {
    jspb.Message.setProto3IntField(this, 2, value);
  }


  /**
   * optional int32 height = 3;
   * @return {number}
   */
  getHeight(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
  };


  /** @param {number} value */
  setHeight(value: number) {
    jspb.Message.setProto3IntField(this, 3, value);
  }


}
export namespace ImageInfo {
  export interface AsObject {
    sizeMap: [string, ImageSize.AsObject][];
  }
}
export class ImageInfo extends jspb.Message {
  static readonly displayName = "ImageInfo";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): ImageInfo.AsObject {
    return ImageInfo.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!ImageInfo} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: ImageInfo) {
    var f: any;
    var obj: any = {
      sizeMap: (f = msg.getSizeMap()) ? f.toObject(includeInstance, ImageSize.toObject) : []
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!ImageInfo}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new ImageInfo;
    return ImageInfo.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!ImageInfo} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!ImageInfo}
   */
  static deserializeBinaryFromReader(msg: ImageInfo, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 4:
        var value4 = msg.getSizeMap();
        reader.readMessage(value4, function(message, reader) {
          jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, ImageSize.deserializeBinaryFromReader, "");
           });
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!ImageInfo} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: ImageInfo, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getSizeMap(true);
    if (f && f.getLength() > 0) {
      f.serializeBinary(4, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, ImageSize.serializeBinaryToWriter);
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    ImageInfo.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * map<string, ImageSize> size = 4;
   * @param {boolean=} opt_noLazyCreate Do not create the map if
   * empty, instead returning `undefined`
   * @return {!jspb.Map<string,!ImageSize>}
   */
  getSizeMap(): jspb.Map<string,ImageSize>;
  getSizeMap(noLazyCreate: true): jspb.Map<string,ImageSize>|undefined;
  getSizeMap(noLazyCreate: false): jspb.Map<string,ImageSize>;
  getSizeMap(opt_noLazyCreate: boolean = false): jspb.Map<string,ImageSize> {
    return /** @type {!jspb.Map<string,!ImageSize>} */ (
        jspb.Message.getMapField(this, 4, opt_noLazyCreate,
        ImageSize));
  }


  /**
   * Clears values from the map. The map will be non-null.
   */
  clearSizeMap() {
    this.getSizeMap().clear();
  }


}
