import { Injectable } from '@angular/core';

import { IMembershipList } from 'libs/domain';

@Injectable({ providedIn: 'root' })
export class MembershipListTableService {
  private readonly _temporyReferenceMap = new Map<string, IMembershipList>();

  /** Service Constructor */
  constructor() {}

  public getListFromReference(tempId: string): IMembershipList {
    return this._temporyReferenceMap.get(tempId);
  }

  public createTemporyReference(tempId: string, list: IMembershipList) {
    return this._temporyReferenceMap.set(tempId, list);
  }

  public deleteTemporyReference(tempId: string) {
    return this._temporyReferenceMap.delete(tempId);
  }
}
