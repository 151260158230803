import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';

import { MgTextareaComponent } from '@app/src/app/components/Textarea';

import { CommentInputService } from './CommentInput.service';

@Component({
  selector: 'mg-comment-input',
  templateUrl: './CommentInput.component.html',
  styleUrls: ['./CommentInput.component.scss'],
})
export class CommentInputComponent implements OnInit, OnChanges {
  @ViewChild('textareaInput', { static: true })
  textarea?: MgTextareaComponent;

  @Input()
  contentContext: string = '';

  @Input()
  multiline: boolean = false;

  @Input()
  placeholder: string = 'input.comment.placeholder';

  @Input()
  buttonText: string = 'button.post';

  @Input()
  parentContext: string = '';

  @Input()
  commentPrefix: string = '';

  @Output()
  onClearComment: EventEmitter<string>;

  commentText: string = '';
  commentInputWithCaret: string = '';
  submitting: boolean = false;

  loadingChanges: boolean = false;
  focusingWhileLoading: boolean = false;

  constructor(
    private commentInputService: CommentInputService,
    private element: ElementRef,
  ) {
    this.onClearComment = new EventEmitter();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.contentContext) {
      this.loadingChanges = true;

      this.commentInputService.getDraft(this.contentContext).then(draft => {
        if (draft.text) {
          // If we have a draft just set as is.
          this.commentText = draft.text;
        } else {
          // Otherwise lets give them their comment prefix. (this is usually an
          // @mention)
          this.commentText = this.commentPrefix || '';
          if (this.commentText) {
            this.commentText += ' ';
          }
        }
        this.loadingChanges = false;

        if (this.focusingWhileLoading) {
          setTimeout(() => {
            this.focus();
          });
        }
      });
    }
  }

  saveCommentDelayed() {
    this.commentInputService.saveToDraft(this.contentContext, {
      text: this.commentText,
    });
  }

  ngOnInit() {}

  onKeydown(e: KeyboardEvent) {
    if (e.key === 'Enter' && e.ctrlKey) {
      e.preventDefault();
      e.stopImmediatePropagation();
      e.stopPropagation();
      this.submit();
    }
  }

  clearCommentText() {
    this.commentText = '';
    this.commentInputService.clearDraft(this.contentContext);
    this.onClearComment.emit(this.contentContext);
  }

  async submit(ev?: MouseEvent) {
    if (ev) {
      ev.preventDefault();
      ev.stopImmediatePropagation();
      ev.stopPropagation();
    }

    this.submitting = true;

    let success = await this.commentInputService.submitDraft(
      this.contentContext,
      this.parentContext || this.contentContext,
    );

    if (success) {
      this.clearCommentText();
    }

    this.submitting = false;
  }

  focus() {
    if (this.loadingChanges) {
      this.focusingWhileLoading = true;
      return;
    }
    const el = this.element.nativeElement;

    if (this.textarea) {
      this.textarea.focus();
    }
    this.focusingWhileLoading = false;
  }
}
