import { Router } from '@angular/router';

import { gateway } from 'libs/generated-grpc-web';
import { BehaviorSubject, Observable } from 'rxjs';

import {
  GalleryLightboxComponent,
  GalleryLightboxDataSource,
  IGalleryLightboxItem,
} from '@app/src/app/components/Lightbox/GalleryLightbox';
import { MentionsService } from '@app/src/app/mentions';
import { AuthService } from '@app/src/app/minimal/services/Auth';
import { AuthInfoService } from '@app/src/app/minimal/services/AuthInfo';
import { PermissionsService } from '@app/src/app/permissions';
import { IMgStreamControl, IMgStreamItem } from '@app/src/app/util/stream';

import {
  HOME_GALLERY_ITEMS_IN_CENTER,
  HOME_GALLERY_ITEM_BUFFER,
  HOME_GALLERY_STREAM_LOAD_TRIGGER_COUNT,
} from '../constants/home-gallery-constants';
import { HomeGalleryComponent } from '../home-gallery.component';
import { GalleryItemWrapper } from './gallery-item-wrapper.utils';
import { getGlobalIndex, getGlobalPhotosRange } from './home-gallery.utils';

export class MingaGalleryLightboxDataSource
  implements GalleryLightboxDataSource
{
  total$: Observable<number>;
  items$: BehaviorSubject<IGalleryLightboxItem[]>;
  currentIndex = 0;

  constructor(
    private _galleryRoute: HomeGalleryComponent,
    total$: Observable<number>,
    private _stream: IMgStreamControl<gateway.gallery_pb.GallerySection.AsObject>,
    private _authService: AuthService,
    private _permissions: PermissionsService,
    private _router: Router,
    private _mentionsService: MentionsService,
    private _lightbox: GalleryLightboxComponent,
    private _authInfoService: AuthInfoService,
  ) {
    this.total$ = total$;

    this.items$ = new BehaviorSubject<IGalleryLightboxItem[]>([]);
  }

  next(): void {
    this.currentIndex += 1;
    this._updateViewItems();
  }

  hasNext(): boolean {
    return (
      this.currentIndex < this._stream.length && !this._stream.frontExhausted
    );
  }

  previous(): void {
    this.currentIndex -= 1;
    this._updateViewItems();
  }

  hasPrevious(): boolean {
    return this.currentIndex > 0;
  }

  dispose() {
    this.items$.complete();
  }

  setViewItemsAt(itemIndex: number, photoIndex: number) {
    const items = this._stream._getItems();

    const globalIndex = getGlobalIndex(items, itemIndex, photoIndex);
    this.currentIndex = globalIndex;

    this._updateViewItems(items);
  }

  private _updateViewItems(
    items: IMgStreamItem<gateway.gallery_pb.GallerySection.AsObject>[] = this._stream._getItems(),
  ) {
    const globalIndex = this.currentIndex;
    const extra = Math.abs(Math.min(0, globalIndex - HOME_GALLERY_ITEM_BUFFER));
    const startIndex = Math.max(0, globalIndex - HOME_GALLERY_ITEM_BUFFER);
    const endIndex =
      globalIndex +
      HOME_GALLERY_ITEM_BUFFER +
      HOME_GALLERY_ITEMS_IN_CENTER +
      extra;

    const adjust = Math.min(0, globalIndex - HOME_GALLERY_ITEM_BUFFER);
    const photos = getGlobalPhotosRange(items, startIndex, endIndex);

    const lastStreamItem = items[items.length - 1];
    const lastPhotos = lastStreamItem.item.itemList.slice(
      Math.max(
        0,
        lastStreamItem.item.itemList.length -
          HOME_GALLERY_STREAM_LOAD_TRIGGER_COUNT,
      ),
    );

    for (let i = 0; HOME_GALLERY_STREAM_LOAD_TRIGGER_COUNT > i; ++i) {
      const photo = photos[photos.length - i - 1];
      if (!photo) {
        this._stream.seekFront();
        break;
      }

      for (const lastPhoto of lastPhotos) {
        if (photo.galleryPhotoUuid === lastPhoto.galleryPhotoUuid) {
          this._stream.seekFront();
          break;
        }
      }
    }

    this.items$.next(
      photos.map(
        (item, index) =>
          new GalleryItemWrapper(
            item,
            globalIndex - HOME_GALLERY_ITEM_BUFFER - adjust + index,
            this._galleryRoute,
            this._authService,
            this._permissions,
            this._mentionsService,
            this._authInfoService,
          ),
      ),
    );
  }
}
