import { UntypedFormControl } from '@angular/forms';

import Fuse from 'fuse.js';
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { debounceTime, map, takeUntil } from 'rxjs/operators';

import { SELECT_ELEMENT_FUSE_OPTIONS } from '../constants';
import { SelectElementOption } from '../types';

export class ElementSelectFilter {
  // Form control

  public readonly control = new UntypedFormControl();

  // State

  private _data: SelectElementOption[] = [];

  private readonly _filteredData = new BehaviorSubject<SelectElementOption[]>(
    [],
  );
  public readonly filteredData$ = this._filteredData.asObservable();

  public readonly size$ = this.filteredData$.pipe(map(item => item.length));
  public readonly hasFilteredData$ = this.size$.pipe(map(size => size > 0));

  // Subscriptions

  private readonly _filterSubscription = this._subscription();

  // Fuse

  private _fuse = new Fuse<SelectElementOption>(
    [],
    SELECT_ELEMENT_FUSE_OPTIONS,
  );

  /** Utility constructor */
  constructor(private _destroyedSubject: ReplaySubject<void>) {}

  public init(data: SelectElementOption[]) {
    this._data = data;
    this._filteredData.next(data);
    this._fuse.setCollection(data);
  }

  public destroy() {
    this._filteredData.complete();
    this._filterSubscription.unsubscribe();
  }

  private _subscription() {
    return this.control.valueChanges
      .pipe(takeUntil(this._destroyedSubject), debounceTime(300))
      .subscribe(value => {
        let result = this._data;
        if (value.length) {
          result = this._fuse.search(value).map(({ item }) => item);
        }
        this._filteredData.next(result);
      });
  }
}
