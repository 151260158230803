import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';

import { TranslateModule } from '@ngx-translate/core';

import { MgButtonModule } from '@app/src/app/button';
import { MgFormFieldModule } from '@app/src/app/form-field';
import { MgSpinnerModule } from '@app/src/app/spinner';

import {
  AddressComponent,
  AddressSlot_Hint,
  AddressSlot_Label,
} from './Address.component';

@NgModule({
  imports: [
    // Minga dependencies
    MgFormFieldModule,
    MgSpinnerModule,

    // External dependencies
    CommonModule,
    MgButtonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    TranslateModule,
  ],
  declarations: [AddressComponent, AddressSlot_Label, AddressSlot_Hint],
  exports: [AddressComponent, AddressSlot_Label, AddressSlot_Hint],
})
export class AddressModule {}
