import { Component } from '@angular/core';

import { AuthInfoService } from '@app/src/app/minimal/services/AuthInfo';

import { ToolsService } from '@modules/tools/services';

@Component({
  selector: 'mg-tools-schedule',
  templateUrl: './tools-schedule.component.html',
})
export class ToolsScheduleComponent {
  public toolType$ = this._toolsService.toolType$;
  public authHash$ = this._authInfo.authPersonHash$;
  constructor(
    private _toolsService: ToolsService,
    private _authInfo: AuthInfoService,
  ) {}
}
