import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule } from '@angular/forms';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';

import { TranslateModule } from '@ngx-translate/core';

import { MgButtonModule } from '@app/src/app/button';
import { MgEmptyStateModule } from '@app/src/app/components/EmptyState';
import { FileInputModule } from '@app/src/app/components/Input/FileInput';
import { MgFileExtensionBoxModule } from '@app/src/app/elements/MgFileExtensionBox';
import { MgIconModule } from '@app/src/app/icon';
import { MgSpinnerModule } from '@app/src/app/spinner';

import { GenericModule } from '@shared/components/generic';

import { FileListComponent } from './FileList.component';

@NgModule({
  imports: [
    // Minga dependencies
    MgButtonModule,
    MgIconModule,
    MgSpinnerModule,
    MgFileExtensionBoxModule,
    FileInputModule,
    MgEmptyStateModule,
    GenericModule,

    // External dependencies
    CommonModule,
    FormsModule,
    MatProgressBarModule,
    TranslateModule,
    FlexLayoutModule,
    MatMenuModule,
  ],
  declarations: [FileListComponent],
  exports: [FileListComponent],
})
export class FileListModule {}
