// GENERATED CODE -- DO NOT EDIT!

import {grpc} from '@improbable-eng/grpc-web';

import * as gateway_page_pb from '../gateway/page_pb';
import * as common_legacy_pb from '../common/legacy_pb';

export class PageService {
  static readonly serviceName = "PageService";

  static readonly readPage = {
    methodName: "readPage",
    service: PageService,
    requestStream: false,
    responseStream: false,
    requestType: gateway_page_pb.PageRequest,
    responseType: gateway_page_pb.PageResponse,
  };

}

export class PageServiceClient {
  readPage() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
}
