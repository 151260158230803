import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';

import { MgButtonModule } from '@app/src/app/button';
import { MgSuccessCheckmarkModule } from '@app/src/app/components/SuccessCheckmark';
import { MgFormFieldModule } from '@app/src/app/form-field';
import { MgIconModule } from '@app/src/app/icon';

import { SendFeedbackDialog } from './SendFeedback.component';

@NgModule({
  imports: [
    // Minga dependencies
    MgSuccessCheckmarkModule,
    MgButtonModule,
    MgIconModule,
    MgFormFieldModule,

    // External dependencies
    MatFormFieldModule,
    MatDialogModule,
    MatInputModule,
    FormsModule,
    CommonModule,
  ],
  declarations: [SendFeedbackDialog],
  exports: [SendFeedbackDialog],
})
export class SendFeedbackModule {}
