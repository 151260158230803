import { NgModule } from '@angular/core';

import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { ContentStoreEffects } from '../effects';
import { contentReducer } from '../reducer';
import { contentStoreKeyname } from '../state';

@NgModule({
  imports: [
    StoreModule.forFeature({
      name: contentStoreKeyname,
      reducer: contentReducer,
    }),
    EffectsModule.forFeature([ContentStoreEffects]),
  ],
  providers: [ContentStoreEffects],
})
export class ContentStoreModule {}
