import { Injectable, NgZone } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { grpc } from '@improbable-eng/grpc-web';

import {
  AddDefaultCheckinReasonsRequest,
  AddDefaultCheckinReasonsResponse,
  ArchiveCheckinRequest,
  ArchiveCheckinResponse,
  CheckinRequest,
  CheckinResponse,
  CheckoutRequest,
  CheckoutResponse,
  DeleteCheckinReasonRequest,
  DeleteCheckinReasonResponse,
  GetMingaCheckinReasonRequest,
  GetMingaCheckinReasonResponse,
  GetMingaCheckinReasonsRequest,
  GetMingaCheckinReasonsResponse,
  SelfCheckInRequest,
  SelfCheckInResponse,
  SelfCheckOutRequest,
  SelfCheckOutResponse,
  ToggleCheckinReasonActiveRequest,
  ToggleCheckinReasonActiveResponse,
  UpdateCheckinRequest,
  UpdateCheckinResponse,
  UpsertCheckinReasonRequest,
  UpsertCheckinReasonResponse,
} from '../checkin/checkin_pb';

import {
  CheckinManager as __CheckinManager,
} from '../checkin/checkin_pb_service';

@Injectable({providedIn: 'root'})
export class CheckinManager {

  constructor(private _ngZone: NgZone) {
  }

  selfCheckIn(request: SelfCheckInRequest): Promise<SelfCheckInResponse>;
  selfCheckIn(request: SelfCheckInRequest, metadata: grpc.Metadata): Promise<SelfCheckInResponse>;
  selfCheckIn(request: SelfCheckInRequest, callback: (err: any|null, response: SelfCheckInResponse, metadata: grpc.Metadata) => void): void;
  selfCheckIn(request: SelfCheckInRequest, metadata: grpc.Metadata, callback: (err: any|null, response: SelfCheckInResponse, metadata: grpc.Metadata) => void): void;

  selfCheckIn(request: SelfCheckInRequest, arg1?: grpc.Metadata|((err: any|null, response: SelfCheckInResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: SelfCheckInResponse, metadata: grpc.Metadata) => void): Promise<SelfCheckInResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<SelfCheckInResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__CheckinManager.SelfCheckIn, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  selfCheckOut(request: SelfCheckOutRequest): Promise<SelfCheckOutResponse>;
  selfCheckOut(request: SelfCheckOutRequest, metadata: grpc.Metadata): Promise<SelfCheckOutResponse>;
  selfCheckOut(request: SelfCheckOutRequest, callback: (err: any|null, response: SelfCheckOutResponse, metadata: grpc.Metadata) => void): void;
  selfCheckOut(request: SelfCheckOutRequest, metadata: grpc.Metadata, callback: (err: any|null, response: SelfCheckOutResponse, metadata: grpc.Metadata) => void): void;

  selfCheckOut(request: SelfCheckOutRequest, arg1?: grpc.Metadata|((err: any|null, response: SelfCheckOutResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: SelfCheckOutResponse, metadata: grpc.Metadata) => void): Promise<SelfCheckOutResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<SelfCheckOutResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__CheckinManager.SelfCheckOut, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  checkin(request: CheckinRequest): Promise<CheckinResponse>;
  checkin(request: CheckinRequest, metadata: grpc.Metadata): Promise<CheckinResponse>;
  checkin(request: CheckinRequest, callback: (err: any|null, response: CheckinResponse, metadata: grpc.Metadata) => void): void;
  checkin(request: CheckinRequest, metadata: grpc.Metadata, callback: (err: any|null, response: CheckinResponse, metadata: grpc.Metadata) => void): void;

  checkin(request: CheckinRequest, arg1?: grpc.Metadata|((err: any|null, response: CheckinResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: CheckinResponse, metadata: grpc.Metadata) => void): Promise<CheckinResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<CheckinResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__CheckinManager.Checkin, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  checkout(request: CheckoutRequest): Promise<CheckoutResponse>;
  checkout(request: CheckoutRequest, metadata: grpc.Metadata): Promise<CheckoutResponse>;
  checkout(request: CheckoutRequest, callback: (err: any|null, response: CheckoutResponse, metadata: grpc.Metadata) => void): void;
  checkout(request: CheckoutRequest, metadata: grpc.Metadata, callback: (err: any|null, response: CheckoutResponse, metadata: grpc.Metadata) => void): void;

  checkout(request: CheckoutRequest, arg1?: grpc.Metadata|((err: any|null, response: CheckoutResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: CheckoutResponse, metadata: grpc.Metadata) => void): Promise<CheckoutResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<CheckoutResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__CheckinManager.Checkout, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  getMingaCheckinReason(request: GetMingaCheckinReasonRequest): Promise<GetMingaCheckinReasonResponse>;
  getMingaCheckinReason(request: GetMingaCheckinReasonRequest, metadata: grpc.Metadata): Promise<GetMingaCheckinReasonResponse>;
  getMingaCheckinReason(request: GetMingaCheckinReasonRequest, callback: (err: any|null, response: GetMingaCheckinReasonResponse, metadata: grpc.Metadata) => void): void;
  getMingaCheckinReason(request: GetMingaCheckinReasonRequest, metadata: grpc.Metadata, callback: (err: any|null, response: GetMingaCheckinReasonResponse, metadata: grpc.Metadata) => void): void;

  getMingaCheckinReason(request: GetMingaCheckinReasonRequest, arg1?: grpc.Metadata|((err: any|null, response: GetMingaCheckinReasonResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: GetMingaCheckinReasonResponse, metadata: grpc.Metadata) => void): Promise<GetMingaCheckinReasonResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<GetMingaCheckinReasonResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__CheckinManager.GetMingaCheckinReason, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  getMingaCheckinReasons(request: GetMingaCheckinReasonsRequest): Promise<GetMingaCheckinReasonsResponse>;
  getMingaCheckinReasons(request: GetMingaCheckinReasonsRequest, metadata: grpc.Metadata): Promise<GetMingaCheckinReasonsResponse>;
  getMingaCheckinReasons(request: GetMingaCheckinReasonsRequest, callback: (err: any|null, response: GetMingaCheckinReasonsResponse, metadata: grpc.Metadata) => void): void;
  getMingaCheckinReasons(request: GetMingaCheckinReasonsRequest, metadata: grpc.Metadata, callback: (err: any|null, response: GetMingaCheckinReasonsResponse, metadata: grpc.Metadata) => void): void;

  getMingaCheckinReasons(request: GetMingaCheckinReasonsRequest, arg1?: grpc.Metadata|((err: any|null, response: GetMingaCheckinReasonsResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: GetMingaCheckinReasonsResponse, metadata: grpc.Metadata) => void): Promise<GetMingaCheckinReasonsResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<GetMingaCheckinReasonsResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__CheckinManager.GetMingaCheckinReasons, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  upsertCheckinReason(request: UpsertCheckinReasonRequest): Promise<UpsertCheckinReasonResponse>;
  upsertCheckinReason(request: UpsertCheckinReasonRequest, metadata: grpc.Metadata): Promise<UpsertCheckinReasonResponse>;
  upsertCheckinReason(request: UpsertCheckinReasonRequest, callback: (err: any|null, response: UpsertCheckinReasonResponse, metadata: grpc.Metadata) => void): void;
  upsertCheckinReason(request: UpsertCheckinReasonRequest, metadata: grpc.Metadata, callback: (err: any|null, response: UpsertCheckinReasonResponse, metadata: grpc.Metadata) => void): void;

  upsertCheckinReason(request: UpsertCheckinReasonRequest, arg1?: grpc.Metadata|((err: any|null, response: UpsertCheckinReasonResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: UpsertCheckinReasonResponse, metadata: grpc.Metadata) => void): Promise<UpsertCheckinReasonResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<UpsertCheckinReasonResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__CheckinManager.UpsertCheckinReason, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  deleteCheckinReason(request: DeleteCheckinReasonRequest): Promise<DeleteCheckinReasonResponse>;
  deleteCheckinReason(request: DeleteCheckinReasonRequest, metadata: grpc.Metadata): Promise<DeleteCheckinReasonResponse>;
  deleteCheckinReason(request: DeleteCheckinReasonRequest, callback: (err: any|null, response: DeleteCheckinReasonResponse, metadata: grpc.Metadata) => void): void;
  deleteCheckinReason(request: DeleteCheckinReasonRequest, metadata: grpc.Metadata, callback: (err: any|null, response: DeleteCheckinReasonResponse, metadata: grpc.Metadata) => void): void;

  deleteCheckinReason(request: DeleteCheckinReasonRequest, arg1?: grpc.Metadata|((err: any|null, response: DeleteCheckinReasonResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: DeleteCheckinReasonResponse, metadata: grpc.Metadata) => void): Promise<DeleteCheckinReasonResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<DeleteCheckinReasonResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__CheckinManager.DeleteCheckinReason, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  addDefaultCheckinReasonsToMinga(request: AddDefaultCheckinReasonsRequest): Promise<AddDefaultCheckinReasonsResponse>;
  addDefaultCheckinReasonsToMinga(request: AddDefaultCheckinReasonsRequest, metadata: grpc.Metadata): Promise<AddDefaultCheckinReasonsResponse>;
  addDefaultCheckinReasonsToMinga(request: AddDefaultCheckinReasonsRequest, callback: (err: any|null, response: AddDefaultCheckinReasonsResponse, metadata: grpc.Metadata) => void): void;
  addDefaultCheckinReasonsToMinga(request: AddDefaultCheckinReasonsRequest, metadata: grpc.Metadata, callback: (err: any|null, response: AddDefaultCheckinReasonsResponse, metadata: grpc.Metadata) => void): void;

  addDefaultCheckinReasonsToMinga(request: AddDefaultCheckinReasonsRequest, arg1?: grpc.Metadata|((err: any|null, response: AddDefaultCheckinReasonsResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: AddDefaultCheckinReasonsResponse, metadata: grpc.Metadata) => void): Promise<AddDefaultCheckinReasonsResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<AddDefaultCheckinReasonsResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__CheckinManager.AddDefaultCheckinReasonsToMinga, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  archiveCheckin(request: ArchiveCheckinRequest): Promise<ArchiveCheckinResponse>;
  archiveCheckin(request: ArchiveCheckinRequest, metadata: grpc.Metadata): Promise<ArchiveCheckinResponse>;
  archiveCheckin(request: ArchiveCheckinRequest, callback: (err: any|null, response: ArchiveCheckinResponse, metadata: grpc.Metadata) => void): void;
  archiveCheckin(request: ArchiveCheckinRequest, metadata: grpc.Metadata, callback: (err: any|null, response: ArchiveCheckinResponse, metadata: grpc.Metadata) => void): void;

  archiveCheckin(request: ArchiveCheckinRequest, arg1?: grpc.Metadata|((err: any|null, response: ArchiveCheckinResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: ArchiveCheckinResponse, metadata: grpc.Metadata) => void): Promise<ArchiveCheckinResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<ArchiveCheckinResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__CheckinManager.ArchiveCheckin, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  updateCheckin(request: UpdateCheckinRequest): Promise<UpdateCheckinResponse>;
  updateCheckin(request: UpdateCheckinRequest, metadata: grpc.Metadata): Promise<UpdateCheckinResponse>;
  updateCheckin(request: UpdateCheckinRequest, callback: (err: any|null, response: UpdateCheckinResponse, metadata: grpc.Metadata) => void): void;
  updateCheckin(request: UpdateCheckinRequest, metadata: grpc.Metadata, callback: (err: any|null, response: UpdateCheckinResponse, metadata: grpc.Metadata) => void): void;

  updateCheckin(request: UpdateCheckinRequest, arg1?: grpc.Metadata|((err: any|null, response: UpdateCheckinResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: UpdateCheckinResponse, metadata: grpc.Metadata) => void): Promise<UpdateCheckinResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<UpdateCheckinResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__CheckinManager.UpdateCheckin, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  toggleCheckinReasonActive(request: ToggleCheckinReasonActiveRequest): Promise<ToggleCheckinReasonActiveResponse>;
  toggleCheckinReasonActive(request: ToggleCheckinReasonActiveRequest, metadata: grpc.Metadata): Promise<ToggleCheckinReasonActiveResponse>;
  toggleCheckinReasonActive(request: ToggleCheckinReasonActiveRequest, callback: (err: any|null, response: ToggleCheckinReasonActiveResponse, metadata: grpc.Metadata) => void): void;
  toggleCheckinReasonActive(request: ToggleCheckinReasonActiveRequest, metadata: grpc.Metadata, callback: (err: any|null, response: ToggleCheckinReasonActiveResponse, metadata: grpc.Metadata) => void): void;

  toggleCheckinReasonActive(request: ToggleCheckinReasonActiveRequest, arg1?: grpc.Metadata|((err: any|null, response: ToggleCheckinReasonActiveResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: ToggleCheckinReasonActiveResponse, metadata: grpc.Metadata) => void): Promise<ToggleCheckinReasonActiveResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<ToggleCheckinReasonActiveResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__CheckinManager.ToggleCheckinReasonActive, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

}

