import {
  ChangeDetectionStrategy,
  Component,
  TemplateRef,
  ViewChild,
} from '@angular/core';

import {
  IMgModal,
  MgModalService,
} from '@app/src/app/minimal/services/MgModal';

@Component({
  selector: 'mg-lightbox',
  templateUrl: './Lightbox.component.html',
  styleUrls: ['./Lightbox.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LightboxComponent {
  @ViewChild('lightboxTpl', { static: true })
  lightboxTpl!: TemplateRef<any>;

  private _mgModal: IMgModal | null = null;

  get opened() {
    return !this.closed;
  }

  get closed() {
    return this._mgModal?.closed || true;
  }

  constructor(private mgModalService: MgModalService) {}

  open() {
    this._mgModal = this.mgModalService.open(this.lightboxTpl, {
      backgroundColor: '#000000',
      full: true,
    });
  }

  close() {
    this._mgModal?.close();
    this._mgModal = null;
  }
}
