import { DOCUMENT } from '@angular/common';
import { Injectable, Inject } from '@angular/core';

import { ReplaySubject, Observable } from 'rxjs';

@Injectable()
export class RevHeroService {
  // support loading multiple scripts if necessary in the future
  _loadedLibraries: { [url: string]: ReplaySubject<any> } = {};

  constructor(@Inject(DOCUMENT) private readonly _document: any) {}

  loadScript(url: string): Observable<any> {
    if (this._loadedLibraries[url]) {
      return this._loadedLibraries[url].asObservable();
    }

    this._loadedLibraries[url] = new ReplaySubject();

    const script = this._document.createElement('script');
    script.type = 'text/javascript';
    script.src = url;
    script.onload = () => {
      this._loadedLibraries[url].next();
      this._loadedLibraries[url].complete();
    };

    this._document.body.appendChild(script);

    return this._loadedLibraries[url].asObservable();
  }
}

/* Usage */
/*
this._revHeroService.loadScript('https://scriptUrl.js').subscribe(() => {
   // run when loaded
});
*/
