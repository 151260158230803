import { Action, combineReducers } from '@ngrx/store';

import * as fromMinga from './reducers/minga.reducer';

export * from './mingaStore.module';
export * from './selectors/minga.selectors';
export * from './reducers/minga.reducer';

export const mingaStoreFeatureKey = 'mingaStore';
export interface MingaState {
  mingaCollection: fromMinga.MingaCollectionState;
}

export function reducers(state: MingaState | undefined, action: Action) {
  return combineReducers({ ['mingaCollection']: fromMinga.mingaReducer })(
    state,
    action,
  );
}
