import { Injectable } from '@angular/core';

import { Actions, ofType, createEffect } from '@ngrx/effects';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { MingaManagerService } from '@app/src/app/services/MingaManager';
import { UserStorage } from '@app/src/app/services/UserStorage';
import { TypeEnum, TypeUnion } from '@app/src/app/store/root/rootActions';

export const GRADES_STORAGE_KEY = 'minga_grade_options';

/**
 * Convenience service for grabbing grades
 */
@Injectable({ providedIn: 'root' })
export class GradesService {
  private _currentFetch?: Promise<string[]>;
  private _grades$: BehaviorSubject<string[]>;

  /**
   * All grades available to the current client (based on school type)
   */
  readonly grades$: Observable<string[]>;

  constructor(
    private _mingaManagerService: MingaManagerService,
    private _localStorage: UserStorage,
  ) {
    this._grades$ = new BehaviorSubject<string[]>([]);
    this.grades$ = this._grades$.asObservable();

    this._getLocalGrades().then(value => {
      if (value) this._grades$.next(value);
    });

    (window as any).gradesService = this;
  }

  private async _getLocalGrades() {
    const storedGrades = await this._localStorage.getItem<string[]>(
      GRADES_STORAGE_KEY,
    );

    if (storedGrades) return storedGrades;
    return null;
  }

  private async _storeLocalGrades(grades: string[]) {
    this._grades$.next(grades);
    await this._localStorage.setItem(GRADES_STORAGE_KEY, grades);
  }

  private async _deleteLocalGrades() {
    await this._localStorage.removeItem(GRADES_STORAGE_KEY);
    this._grades$.next([]);
  }

  /**
   * Fetches data only if there is no stored data or if there isn't a fetch
   * already happening
   */
  async fetchIfNeeded(): Promise<void> {
    const isFetchingRoles = !!this._currentFetch;
    if (isFetchingRoles) return;

    const noStoredGrades = this._grades$.getValue().length === 0;
    if (noStoredGrades) await this.fetchGrades();
  }

  /**
   * Fetch or re-fetch grades
   */
  async fetchGrades(): Promise<void> {
    if (this._currentFetch) {
      await this._currentFetch;
      return;
    }

    const thisFetch = this._doGradesFetch();
    try {
      this._currentFetch = thisFetch;
      await this._storeLocalGrades(await thisFetch);
    } finally {
      if (this._currentFetch === thisFetch) {
        delete this._currentFetch;
      }
    }
  }

  /**
   * Clears grades so they need to be fetched again
   */
  clear() {
    this._deleteLocalGrades();
  }

  private async _doGradesFetch(): Promise<string[]> {
    return this._mingaManagerService.readMingaGradeOptions();
  }
}

@Injectable({ providedIn: 'root' })
export class GradesEffects {
  gradesInvalidate$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(TypeEnum.ChangeMinga, TypeEnum.Logout),
        map(() => this._gradesService.clear()),
      ),
    { dispatch: false },
  );

  constructor(
    private _gradesService: GradesService,
    private _actions$: Actions<TypeUnion>,
  ) {}
}
