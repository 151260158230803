import { Injectable, NgZone } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { grpc } from '@improbable-eng/grpc-web';

import {
  AnalyticsClientRequest,
  AnalyticsClientResponse,
  AnalyticsUserRequest,
  AnalyticsUserResponse,
  EmitEventRequest,
  EmitEventResponse,
} from '../gateway/analytics_pb';

import {
  AnalyticsClient as __AnalyticsClient,
} from '../gateway/analytics_pb_service';

@Injectable({providedIn: 'root'})
export class AnalyticsClient {

  constructor(private _ngZone: NgZone) {
  }

  setAnalyticsClient(request: AnalyticsClientRequest): Promise<AnalyticsClientResponse>;
  setAnalyticsClient(request: AnalyticsClientRequest, metadata: grpc.Metadata): Promise<AnalyticsClientResponse>;
  setAnalyticsClient(request: AnalyticsClientRequest, callback: (err: any|null, response: AnalyticsClientResponse, metadata: grpc.Metadata) => void): void;
  setAnalyticsClient(request: AnalyticsClientRequest, metadata: grpc.Metadata, callback: (err: any|null, response: AnalyticsClientResponse, metadata: grpc.Metadata) => void): void;

  setAnalyticsClient(request: AnalyticsClientRequest, arg1?: grpc.Metadata|((err: any|null, response: AnalyticsClientResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: AnalyticsClientResponse, metadata: grpc.Metadata) => void): Promise<AnalyticsClientResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<AnalyticsClientResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__AnalyticsClient.setAnalyticsClient, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  setAnalyticsUser(request: AnalyticsUserRequest): Promise<AnalyticsUserResponse>;
  setAnalyticsUser(request: AnalyticsUserRequest, metadata: grpc.Metadata): Promise<AnalyticsUserResponse>;
  setAnalyticsUser(request: AnalyticsUserRequest, callback: (err: any|null, response: AnalyticsUserResponse, metadata: grpc.Metadata) => void): void;
  setAnalyticsUser(request: AnalyticsUserRequest, metadata: grpc.Metadata, callback: (err: any|null, response: AnalyticsUserResponse, metadata: grpc.Metadata) => void): void;

  setAnalyticsUser(request: AnalyticsUserRequest, arg1?: grpc.Metadata|((err: any|null, response: AnalyticsUserResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: AnalyticsUserResponse, metadata: grpc.Metadata) => void): Promise<AnalyticsUserResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<AnalyticsUserResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__AnalyticsClient.setAnalyticsUser, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  serverEmitEvent(request: EmitEventRequest): Promise<EmitEventResponse>;
  serverEmitEvent(request: EmitEventRequest, metadata: grpc.Metadata): Promise<EmitEventResponse>;
  serverEmitEvent(request: EmitEventRequest, callback: (err: any|null, response: EmitEventResponse, metadata: grpc.Metadata) => void): void;
  serverEmitEvent(request: EmitEventRequest, metadata: grpc.Metadata, callback: (err: any|null, response: EmitEventResponse, metadata: grpc.Metadata) => void): void;

  serverEmitEvent(request: EmitEventRequest, arg1?: grpc.Metadata|((err: any|null, response: EmitEventResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: EmitEventResponse, metadata: grpc.Metadata) => void): Promise<EmitEventResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<EmitEventResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__AnalyticsClient.serverEmitEvent, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

}

