import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ClippedHtmlModule } from '@app/src/app/components/ClippedHtml';
import { MgLikeModule } from '@app/src/app/elements/MgLike';
import { MgPersonMinimalModule } from '@app/src/app/elements/MgPersonMinimal';
import { PermissionsModule } from '@app/src/app/permissions';
import { MgPipesModule } from '@app/src/app/pipes';

import { FeedCommentViewComponent } from './FeedCommentView.component';

@NgModule({
  imports: [
    // Minga dependencies
    MgPersonMinimalModule,
    MgLikeModule,
    MgPipesModule,
    ClippedHtmlModule,
    PermissionsModule,

    // External dependencies
    CommonModule,
  ],
  declarations: [FeedCommentViewComponent],
  exports: [FeedCommentViewComponent],
})
export class FeedCommentViewModule {}
