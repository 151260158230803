import { Injectable, NgZone } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { grpc } from '@improbable-eng/grpc-web';

import {
  StreamControl,
  StreamControlResponse,
  StreamID,
} from '../common/stream_pb';
import {
  BulkIdImageUploadRequest,
  BulkIdImageUploadResponse,
  DeleteStudentIdImageRequest,
  DeleteStudentIdImageResponse,
  DeleteStudentIdRequest,
  DeleteStudentIdResponse,
  ExportStudentIdListChunk,
  ExportStudentIdListRequest,
  GetMingaStudentIdsRequest,
  GetMingaStudentIdsResponse,
  PersonIdentityListRequest,
  PersonIdentityListResponse,
  ReadStudentIdRequest,
  ReadStudentIdResponse,
  StreamStudentIdItem,
  StudentIdStatusCountRequest,
  StudentIdStatusCountResponse,
  UpdateStudentIdRequest,
  UpdateStudentIdResponse,
} from '../student/student_id_pb';

import {
  StudentIdManager as __StudentIdManager,
} from '../student/student_id_pb_service';

@Injectable({providedIn: 'root'})
export class StudentIdManager {

  constructor(private _ngZone: NgZone) {
  }

  readStudentId(request: ReadStudentIdRequest): Promise<ReadStudentIdResponse>;
  readStudentId(request: ReadStudentIdRequest, metadata: grpc.Metadata): Promise<ReadStudentIdResponse>;
  readStudentId(request: ReadStudentIdRequest, callback: (err: any|null, response: ReadStudentIdResponse, metadata: grpc.Metadata) => void): void;
  readStudentId(request: ReadStudentIdRequest, metadata: grpc.Metadata, callback: (err: any|null, response: ReadStudentIdResponse, metadata: grpc.Metadata) => void): void;

  readStudentId(request: ReadStudentIdRequest, arg1?: grpc.Metadata|((err: any|null, response: ReadStudentIdResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: ReadStudentIdResponse, metadata: grpc.Metadata) => void): Promise<ReadStudentIdResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<ReadStudentIdResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__StudentIdManager.ReadStudentId, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  updateStudentId(request: UpdateStudentIdRequest): Promise<UpdateStudentIdResponse>;
  updateStudentId(request: UpdateStudentIdRequest, metadata: grpc.Metadata): Promise<UpdateStudentIdResponse>;
  updateStudentId(request: UpdateStudentIdRequest, callback: (err: any|null, response: UpdateStudentIdResponse, metadata: grpc.Metadata) => void): void;
  updateStudentId(request: UpdateStudentIdRequest, metadata: grpc.Metadata, callback: (err: any|null, response: UpdateStudentIdResponse, metadata: grpc.Metadata) => void): void;

  updateStudentId(request: UpdateStudentIdRequest, arg1?: grpc.Metadata|((err: any|null, response: UpdateStudentIdResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: UpdateStudentIdResponse, metadata: grpc.Metadata) => void): Promise<UpdateStudentIdResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<UpdateStudentIdResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__StudentIdManager.UpdateStudentId, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  deleteStudentIdImage(request: DeleteStudentIdImageRequest): Promise<DeleteStudentIdImageResponse>;
  deleteStudentIdImage(request: DeleteStudentIdImageRequest, metadata: grpc.Metadata): Promise<DeleteStudentIdImageResponse>;
  deleteStudentIdImage(request: DeleteStudentIdImageRequest, callback: (err: any|null, response: DeleteStudentIdImageResponse, metadata: grpc.Metadata) => void): void;
  deleteStudentIdImage(request: DeleteStudentIdImageRequest, metadata: grpc.Metadata, callback: (err: any|null, response: DeleteStudentIdImageResponse, metadata: grpc.Metadata) => void): void;

  deleteStudentIdImage(request: DeleteStudentIdImageRequest, arg1?: grpc.Metadata|((err: any|null, response: DeleteStudentIdImageResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: DeleteStudentIdImageResponse, metadata: grpc.Metadata) => void): Promise<DeleteStudentIdImageResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<DeleteStudentIdImageResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__StudentIdManager.DeleteStudentIdImage, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  deleteStudentId(request: DeleteStudentIdRequest): Promise<DeleteStudentIdResponse>;
  deleteStudentId(request: DeleteStudentIdRequest, metadata: grpc.Metadata): Promise<DeleteStudentIdResponse>;
  deleteStudentId(request: DeleteStudentIdRequest, callback: (err: any|null, response: DeleteStudentIdResponse, metadata: grpc.Metadata) => void): void;
  deleteStudentId(request: DeleteStudentIdRequest, metadata: grpc.Metadata, callback: (err: any|null, response: DeleteStudentIdResponse, metadata: grpc.Metadata) => void): void;

  deleteStudentId(request: DeleteStudentIdRequest, arg1?: grpc.Metadata|((err: any|null, response: DeleteStudentIdResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: DeleteStudentIdResponse, metadata: grpc.Metadata) => void): Promise<DeleteStudentIdResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<DeleteStudentIdResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__StudentIdManager.DeleteStudentId, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  getStudentIdStatusCounts(request: StudentIdStatusCountRequest): Promise<StudentIdStatusCountResponse>;
  getStudentIdStatusCounts(request: StudentIdStatusCountRequest, metadata: grpc.Metadata): Promise<StudentIdStatusCountResponse>;
  getStudentIdStatusCounts(request: StudentIdStatusCountRequest, callback: (err: any|null, response: StudentIdStatusCountResponse, metadata: grpc.Metadata) => void): void;
  getStudentIdStatusCounts(request: StudentIdStatusCountRequest, metadata: grpc.Metadata, callback: (err: any|null, response: StudentIdStatusCountResponse, metadata: grpc.Metadata) => void): void;

  getStudentIdStatusCounts(request: StudentIdStatusCountRequest, arg1?: grpc.Metadata|((err: any|null, response: StudentIdStatusCountResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: StudentIdStatusCountResponse, metadata: grpc.Metadata) => void): Promise<StudentIdStatusCountResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<StudentIdStatusCountResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__StudentIdManager.GetStudentIdStatusCounts, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  streamMingaStudentIds(request: StreamID): {cancel():void;close():void}&Observable<StreamStudentIdItem>;
  streamMingaStudentIds(request: StreamID, metadata: grpc.Metadata): {cancel():void;close():void}&Observable<StreamStudentIdItem>;
  streamMingaStudentIds(request: StreamID, onMessage: (message?: StreamStudentIdItem) => void,onError?: (err: any) => void,onEnd?: (code: number, msg: string|undefined, metadata: grpc.Metadata) => void): void;
  streamMingaStudentIds(request: StreamID, metadata: grpc.Metadata, onMessage: (message?: StreamStudentIdItem) => void,onError?: (err: any) => void,onEnd?: (code: number, msg: string|undefined, metadata: grpc.Metadata) => void): void;

  streamMingaStudentIds(request: StreamID, arg1?: grpc.Metadata|((message?: StreamStudentIdItem) => void), arg2?: ((message?: StreamStudentIdItem) => void)|((err: any) => void), arg3?: ((err: any) => void)|((code: number, msg: string|undefined, metadata: grpc.Metadata) => void), arg4?: (code: number, msg: string|undefined, metadata: grpc.Metadata) => void): {cancel():void;close():void}&Observable<StreamStudentIdItem>|void {
    let ret: any;
    let metadata: any;

    let onMessage: any;
    let onError: any;
    let onEnd: any;
    if(typeof arg1 === 'function') {
      onMessage = arg1;
      onError = arg2;
      onEnd = arg3;
    } else if(typeof arg2 === 'function') {
      metadata = arg1;
      onMessage = arg2;
      onError = arg3;
      onEnd = arg4;
    } else {
      metadata = arg1;
    }

    if(!onMessage) {
      let subject = new Subject<StreamStudentIdItem>();
      ret = subject.asObservable();

      onMessage = (response: any) => {
        subject.next(response);
      };

      onError = (err: any) => {
        subject.error(err);
      };

      onEnd = (code: any, msg: any, metadata: any) => {
        subject.complete();
      };

    } else {
      if(!onError) {
        onError = (err: any) => console.error(err);
      }

      if(!onEnd) {
        onEnd = (code: any, msg: any, metadata: any) => {};
      }
    }

    let req = grpc.invoke(__StudentIdManager.streamMingaStudentIds, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onMessage: response => this._ngZone.run(() => {
        onMessage(response);
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code == grpc.Code.OK) {
          onEnd(code, msg, metadata);
        } else {
          let err = new Error(code + ' ' + (msg||''));
          (<any>err).code = code;
          onError(err);
        }
      })
    });

    (<any>ret).close = () => {
      console.warn('[Angular Grpc] .close() is deprecated use .cancel() instead');
      return req.close();
    };
    (<any>ret).cancel = () => req.close();
    return ret;
  }

  streamMingaStudentIdsControl(request: StreamControl): Promise<StreamControlResponse>;
  streamMingaStudentIdsControl(request: StreamControl, metadata: grpc.Metadata): Promise<StreamControlResponse>;
  streamMingaStudentIdsControl(request: StreamControl, callback: (err: any|null, response: StreamControlResponse, metadata: grpc.Metadata) => void): void;
  streamMingaStudentIdsControl(request: StreamControl, metadata: grpc.Metadata, callback: (err: any|null, response: StreamControlResponse, metadata: grpc.Metadata) => void): void;

  streamMingaStudentIdsControl(request: StreamControl, arg1?: grpc.Metadata|((err: any|null, response: StreamControlResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: StreamControlResponse, metadata: grpc.Metadata) => void): Promise<StreamControlResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<StreamControlResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__StudentIdManager.streamMingaStudentIdsControl, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  getPersonIdentityList(request: PersonIdentityListRequest): Promise<PersonIdentityListResponse>;
  getPersonIdentityList(request: PersonIdentityListRequest, metadata: grpc.Metadata): Promise<PersonIdentityListResponse>;
  getPersonIdentityList(request: PersonIdentityListRequest, callback: (err: any|null, response: PersonIdentityListResponse, metadata: grpc.Metadata) => void): void;
  getPersonIdentityList(request: PersonIdentityListRequest, metadata: grpc.Metadata, callback: (err: any|null, response: PersonIdentityListResponse, metadata: grpc.Metadata) => void): void;

  getPersonIdentityList(request: PersonIdentityListRequest, arg1?: grpc.Metadata|((err: any|null, response: PersonIdentityListResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: PersonIdentityListResponse, metadata: grpc.Metadata) => void): Promise<PersonIdentityListResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<PersonIdentityListResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__StudentIdManager.GetPersonIdentityList, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  bulkIdImageUpload(request: BulkIdImageUploadRequest): Promise<BulkIdImageUploadResponse>;
  bulkIdImageUpload(request: BulkIdImageUploadRequest, metadata: grpc.Metadata): Promise<BulkIdImageUploadResponse>;
  bulkIdImageUpload(request: BulkIdImageUploadRequest, callback: (err: any|null, response: BulkIdImageUploadResponse, metadata: grpc.Metadata) => void): void;
  bulkIdImageUpload(request: BulkIdImageUploadRequest, metadata: grpc.Metadata, callback: (err: any|null, response: BulkIdImageUploadResponse, metadata: grpc.Metadata) => void): void;

  bulkIdImageUpload(request: BulkIdImageUploadRequest, arg1?: grpc.Metadata|((err: any|null, response: BulkIdImageUploadResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: BulkIdImageUploadResponse, metadata: grpc.Metadata) => void): Promise<BulkIdImageUploadResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<BulkIdImageUploadResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__StudentIdManager.BulkIdImageUpload, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  exportStudentIdList(request: ExportStudentIdListRequest): {cancel():void;close():void}&Observable<ExportStudentIdListChunk>;
  exportStudentIdList(request: ExportStudentIdListRequest, metadata: grpc.Metadata): {cancel():void;close():void}&Observable<ExportStudentIdListChunk>;
  exportStudentIdList(request: ExportStudentIdListRequest, onMessage: (message?: ExportStudentIdListChunk) => void,onError?: (err: any) => void,onEnd?: (code: number, msg: string|undefined, metadata: grpc.Metadata) => void): void;
  exportStudentIdList(request: ExportStudentIdListRequest, metadata: grpc.Metadata, onMessage: (message?: ExportStudentIdListChunk) => void,onError?: (err: any) => void,onEnd?: (code: number, msg: string|undefined, metadata: grpc.Metadata) => void): void;

  exportStudentIdList(request: ExportStudentIdListRequest, arg1?: grpc.Metadata|((message?: ExportStudentIdListChunk) => void), arg2?: ((message?: ExportStudentIdListChunk) => void)|((err: any) => void), arg3?: ((err: any) => void)|((code: number, msg: string|undefined, metadata: grpc.Metadata) => void), arg4?: (code: number, msg: string|undefined, metadata: grpc.Metadata) => void): {cancel():void;close():void}&Observable<ExportStudentIdListChunk>|void {
    let ret: any;
    let metadata: any;

    let onMessage: any;
    let onError: any;
    let onEnd: any;
    if(typeof arg1 === 'function') {
      onMessage = arg1;
      onError = arg2;
      onEnd = arg3;
    } else if(typeof arg2 === 'function') {
      metadata = arg1;
      onMessage = arg2;
      onError = arg3;
      onEnd = arg4;
    } else {
      metadata = arg1;
    }

    if(!onMessage) {
      let subject = new Subject<ExportStudentIdListChunk>();
      ret = subject.asObservable();

      onMessage = (response: any) => {
        subject.next(response);
      };

      onError = (err: any) => {
        subject.error(err);
      };

      onEnd = (code: any, msg: any, metadata: any) => {
        subject.complete();
      };

    } else {
      if(!onError) {
        onError = (err: any) => console.error(err);
      }

      if(!onEnd) {
        onEnd = (code: any, msg: any, metadata: any) => {};
      }
    }

    let req = grpc.invoke(__StudentIdManager.ExportStudentIdList, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onMessage: response => this._ngZone.run(() => {
        onMessage(response);
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code == grpc.Code.OK) {
          onEnd(code, msg, metadata);
        } else {
          let err = new Error(code + ' ' + (msg||''));
          (<any>err).code = code;
          onError(err);
        }
      })
    });

    (<any>ret).close = () => {
      console.warn('[Angular Grpc] .close() is deprecated use .cancel() instead');
      return req.close();
    };
    (<any>ret).cancel = () => req.close();
    return ret;
  }

  getMingaStudentIds(request: GetMingaStudentIdsRequest): Promise<GetMingaStudentIdsResponse>;
  getMingaStudentIds(request: GetMingaStudentIdsRequest, metadata: grpc.Metadata): Promise<GetMingaStudentIdsResponse>;
  getMingaStudentIds(request: GetMingaStudentIdsRequest, callback: (err: any|null, response: GetMingaStudentIdsResponse, metadata: grpc.Metadata) => void): void;
  getMingaStudentIds(request: GetMingaStudentIdsRequest, metadata: grpc.Metadata, callback: (err: any|null, response: GetMingaStudentIdsResponse, metadata: grpc.Metadata) => void): void;

  getMingaStudentIds(request: GetMingaStudentIdsRequest, arg1?: grpc.Metadata|((err: any|null, response: GetMingaStudentIdsResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: GetMingaStudentIdsResponse, metadata: grpc.Metadata) => void): Promise<GetMingaStudentIdsResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<GetMingaStudentIdsResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__StudentIdManager.GetMingaStudentIds, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

}

