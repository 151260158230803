// GENERATED CODE -- DO NOT EDIT!

import {grpc} from '@improbable-eng/grpc-web';

import * as content_graphic_pb from '../content/graphic_pb';
import * as content_common_pb from '../content/common_pb';
import * as common_delta_pb from '../common/delta_pb';

export class FullGraphic {
  static readonly serviceName = "minga.content.FullGraphic";

  static readonly New = {
    methodName: "New",
    service: FullGraphic,
    requestStream: false,
    responseStream: false,
    requestType: content_graphic_pb.FullGraphicInfo,
    responseType: content_common_pb.ContentSaveResult,
  };

  static readonly Update = {
    methodName: "Update",
    service: FullGraphic,
    requestStream: false,
    responseStream: false,
    requestType: content_graphic_pb.DeltaFullGraphicInfo,
    responseType: content_common_pb.ContentSaveResult,
  };

  static readonly GetInfo = {
    methodName: "GetInfo",
    service: FullGraphic,
    requestStream: false,
    responseStream: false,
    requestType: content_common_pb.ContentInfoQuery,
    responseType: content_graphic_pb.FullGraphicInfo,
  };

}

export class FullGraphicClient {
  new() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  update() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  getInfo() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
}
