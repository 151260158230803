import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';
import { gateway } from 'libs/generated-grpc-web';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { updateDmPersonalPreference } from '@app/src/app/store/AuthStore/actions';

export interface IUserPreference {
  id: string;
  name: string;
  description: string;
  value: boolean | string;
  category: string;
}

type UserPreferenceValues = {
  [keyname: string]: boolean | string;
};

@Injectable({ providedIn: 'root' })
export class UserPreferencesService {
  private _userPreferencesMetadata$: BehaviorSubject<
    gateway.preferences_pb.UserPreferencesMetadata[]
  >;
  private _userPreferencesValues$: BehaviorSubject<UserPreferenceValues>;

  readonly userPreferences$: Observable<IUserPreference[]>;

  constructor(
    private store: Store<any>,
    private userPreferencesProto: gateway.preferences_ng_grpc_pb.UserPreferences,
  ) {
    this._userPreferencesMetadata$ = new BehaviorSubject<
      gateway.preferences_pb.UserPreferencesMetadata[]
    >([]);
    this._userPreferencesValues$ = new BehaviorSubject<UserPreferenceValues>(
      {},
    );

    const metadataAndValues = combineLatest(
      this._userPreferencesMetadata$,
      this._userPreferencesValues$,
    );

    this.userPreferences$ = metadataAndValues.pipe(
      filter(([metadata]) => metadata.length > 0),
      map(([metadata, values]) => {
        const preferences: IUserPreference[] = [];

        for (const item of metadata) {
          const id = item.getId();

          if (typeof values[id] === 'undefined') {
            continue;
          }

          preferences.push({
            id,
            name: item.getName(),
            description: item.getDescription(),
            value: values[id],
            category: item.getCategory(),
          });
        }

        return preferences;
      }),
    );
  }

  /**
   * Fetch new data regardless if the data has already been fetched
   */
  async fetch() {
    await Promise.all([this.fetchMetadata(), this.fetchPreferencesIfNeeded()]);
  }

  /**
   * Fetch new data if none has been fetched already
   */
  async fetchIfNeeded() {
    await Promise.all([
      this.fetchMetadataIfNeeded(),
      this.fetchPreferencesIfNeeded(),
    ]);
  }

  /**
   * Updates the user preferences immediately and sends a request to the server
   * to save it. If the request throws the value is reverted back to what it was
   * before this was called.
   * @param key
   * @param value
   */
  async updatePreference(key: string, value: string | boolean) {
    const request = new gateway.preferences_pb.UserPreferencesUpdateRequest();
    request.setId(key);
    const valueMsg = new gateway.preferences_pb.UserPreferenceValue();

    if (typeof value === 'string') {
      valueMsg.setStringValue(value);
    } else if (typeof value === 'boolean') {
      valueMsg.setBoolValue(value);
    }

    request.setValue(valueMsg);

    const oldValue = this.getPreferencesValue(key);
    this.setUserPreferencesValue(key, value);

    const response = await this.userPreferencesProto
      .update(request)
      .catch(err => {
        this.setUserPreferencesValue(key, oldValue);
        throw err;
      });

    const updatedUserPreferences = response.getUpdatedUserPreferences();
    this.triggerLocalUpdates(
      key,
      updatedUserPreferences.getIdValuesMap().get(key) || null,
    );
    this.setUserPreferenceValuesFromMessage(updatedUserPreferences);
  }

  async resetPreferences() {
    const request = new gateway.preferences_pb.UserPreferencesResetRequest();
    const response = await this.userPreferencesProto.reset(request);

    const userPreferences = response.getResettedUserPreferences();
    this.setUserPreferenceValuesFromMessage(userPreferences);
  }

  private getPreferencesValue(key: string) {
    const values = this._userPreferencesValues$.getValue();
    return values[key];
  }

  private setUserPreferencesValue(key: string, value: string | boolean) {
    const values = this._userPreferencesValues$.getValue();
    values[key] = value;
    this._userPreferencesValues$.next(values);
  }

  private setUserPreferenceValuesFromMessage(
    msg: gateway.preferences_pb.UserPreferencesInfo,
  ) {
    const values = this._userPreferencesValues$.getValue();
    const idValuesMap = msg.getIdValuesMap();
    idValuesMap.forEach((value, key) => {
      if (value.hasBoolValue()) {
        values[key] = value.getBoolValue();
      } else if (value.hasStringValue()) {
        values[key] = value.getStringValue();
      }
    });

    this._userPreferencesValues$.next(values);
  }

  private async fetchPreferencesIfNeeded() {
    if (Object.keys(this._userPreferencesValues$.getValue()).length === 0) {
      await this.fetchPreferences();
    }
  }

  private async fetchPreferences() {
    const request = new gateway.preferences_pb.UserPreferencesGetRequest();
    const response = await this.userPreferencesProto.get(request);

    const userPreferences = response.getUserPreferences();
    this.setUserPreferenceValuesFromMessage(userPreferences);
  }

  private async fetchMetadataIfNeeded() {
    if (this._userPreferencesMetadata$.getValue().length === 0) {
      await this.fetchMetadata();
    }
  }

  private async fetchMetadata() {
    const request = new gateway.preferences_pb.UserPreferencesMetadataRequest();
    const response = await this.userPreferencesProto.metadata(request);
    this._userPreferencesMetadata$.next(response.getMetadataList());
  }

  private triggerLocalUpdates(
    key: string,
    value: gateway.preferences_pb.UserPreferenceValue | null,
  ) {
    if (!value) {
      console.warn('Invalid triggerLocalUpdates value for key', key);
      return;
    }

    if (key === 'directMessaging' && value.hasBoolValue()) {
      this.store.dispatch(
        updateDmPersonalPreference({
          dmPersonalPreference: value.getBoolValue(),
        }),
      );
    }
  }
}
