export interface IFitGridMinimalTile {
  readonly tileAspectRatio: number;
}

export interface IFitGridRowsOptions {
  readonly maxAspectRatio: number;
  readonly minAspectRatio?: number;
}

export function fitGridRows<T extends IFitGridMinimalTile>(
  tiles: T[], options: IFitGridRowsOptions): T[][] {
  const chunkedTiles: T[][] = [];
  const minAspectRatio = options.minAspectRatio || options.maxAspectRatio;
  const maxAspectRatio = options.maxAspectRatio;

  let currentChunk: T[] = [];
  let currentChunkAspectRatio = 0;

  for(const tile of tiles) {
    const startNextChunk = currentChunk.length >= minAspectRatio &&
      currentChunkAspectRatio + tile.tileAspectRatio > maxAspectRatio;

    if(startNextChunk) {
      chunkedTiles.push(currentChunk);
      currentChunk = [];
      currentChunkAspectRatio = 0;
    }

    currentChunk.push(tile);
    currentChunkAspectRatio += tile.tileAspectRatio;
  }

  if(currentChunk.length > 0) {
    chunkedTiles.push(currentChunk);
  }

  return chunkedTiles;
}
