import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';

import { EventStatus } from 'libs/domain';

export type QuickFilterType = EventStatus | 'My Events';

@Component({
  selector: 'mg-calendar-quick-filter',
  templateUrl: './CalendarQuickFilter.component.html',
  styleUrls: ['./CalendarQuickFilter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CalendarQuickFilterComponent {
  private _quickFilter: QuickFilterType = EventStatus.NONE;

  @Input()
  get quickFilter() {
    return this._quickFilter;
  }
  set quickFilter(value: QuickFilterType) {
    this._quickFilter = value;
  }

  eventStatuses = EventStatus;

  @Output()
  quickFilterChange: EventEmitter<QuickFilterType> = new EventEmitter();

  constructor() {}

  onQuickFilterChange() {
    this.quickFilterChange.emit(this.quickFilter);
  }
}
