import { Pipe } from '@angular/core';

import { combineLatest, Observable, of } from 'rxjs';
import { defaultIfEmpty, map, tap } from 'rxjs/operators';

import { FtueHighlightKey } from '@app/src/app/ftue/Highlight';
import { FtueHighlightService } from '@app/src/app/ftue/Highlight/services';

/**
 * Pipe for observeing the ftue highlight state. The pipe takes in either a
 * single ftue highlight key or a list of ftue highlight keys and returns an
 * boolean observable. The observable emits `true` when the ftue highlight
 * should occur and `false` when it should not.
 *
 * ## EXAMPLES
 *
 * This can easily be used in conjunction with a angular class attribute
 * directive to turn a CSS class on/off depending on whether or not.
 *
 * ```html
 * <div [class.highlighted]="myKey | mgFtueHighlight | async"></div>
 * ```
 *
 * Use with an *ngIf can be a powerful way of showing/hiding content arbitrarily
 * when a ftue highlight key is active.
 * ```html
 * <div *ngIf="myKey | mgFtueHighlight | async">
 *   <!-- Only shows when `myKey` highlight key is active -->
 * </div>
 * ```
 */
@Pipe({ name: 'mgFtueHighlight' })
export class FtueHighlightPipe {
  constructor(private ftueHighlight: FtueHighlightService) {}

  transform(value: any, options?: any): Observable<boolean> {
    if (Array.isArray(value)) {
      const allKeyObs = value.map(key =>
        this.ftueHighlight.observeHighlight(key),
      );
      return combineLatest(allKeyObs).pipe(map(bools => bools.includes(true)));
    } else if (value && (value as FtueHighlightKey)._ftueHighlightKey) {
      const key = value as FtueHighlightKey;
      return this.ftueHighlight.observeHighlight(key);
    }

    return of(false);
  }
}
