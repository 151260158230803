import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { MgIconModule } from '@app/src/app/icon';
import { MgPipesModule } from '@app/src/app/pipes';

import { PointsHistoryIconComponent } from './PointsHistoryIcon.component';

@NgModule({
  imports: [
    // Minga dependencies
    MgIconModule,
    MgPipesModule,

    // External dependencies
    CommonModule,
  ],
  declarations: [PointsHistoryIconComponent],
  exports: [PointsHistoryIconComponent],
})
export class PointsHistoryIconModule {}
