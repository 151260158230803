import { Injectable, NgZone } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { grpc } from '@improbable-eng/grpc-web';

import {
  AssignRequest,
  AssignResponse,
  DeleteRegistrationRequest,
  DeleteRegistrationResponse,
  GetMyRegistrationsRequest,
  GetMyRegistrationsResponse,
  GetRegisteredPeopleRequest,
  GetRegisteredPeopleResponse,
  RegisterRequest,
  RegisterResponse,
  RegisterToHomeroomRequest,
  RegisterToHomeroomResponse,
} from '../registration/registration_pb';

import {
  RegistrationManager as __RegistrationManager,
} from '../registration/registration_pb_service';

@Injectable({providedIn: 'root'})
export class RegistrationManager {

  constructor(private _ngZone: NgZone) {
  }

  register(request: RegisterRequest): Promise<RegisterResponse>;
  register(request: RegisterRequest, metadata: grpc.Metadata): Promise<RegisterResponse>;
  register(request: RegisterRequest, callback: (err: any|null, response: RegisterResponse, metadata: grpc.Metadata) => void): void;
  register(request: RegisterRequest, metadata: grpc.Metadata, callback: (err: any|null, response: RegisterResponse, metadata: grpc.Metadata) => void): void;

  register(request: RegisterRequest, arg1?: grpc.Metadata|((err: any|null, response: RegisterResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: RegisterResponse, metadata: grpc.Metadata) => void): Promise<RegisterResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<RegisterResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__RegistrationManager.Register, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  assign(request: AssignRequest): Promise<AssignResponse>;
  assign(request: AssignRequest, metadata: grpc.Metadata): Promise<AssignResponse>;
  assign(request: AssignRequest, callback: (err: any|null, response: AssignResponse, metadata: grpc.Metadata) => void): void;
  assign(request: AssignRequest, metadata: grpc.Metadata, callback: (err: any|null, response: AssignResponse, metadata: grpc.Metadata) => void): void;

  assign(request: AssignRequest, arg1?: grpc.Metadata|((err: any|null, response: AssignResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: AssignResponse, metadata: grpc.Metadata) => void): Promise<AssignResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<AssignResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__RegistrationManager.Assign, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  getMyRegistrations(request: GetMyRegistrationsRequest): Promise<GetMyRegistrationsResponse>;
  getMyRegistrations(request: GetMyRegistrationsRequest, metadata: grpc.Metadata): Promise<GetMyRegistrationsResponse>;
  getMyRegistrations(request: GetMyRegistrationsRequest, callback: (err: any|null, response: GetMyRegistrationsResponse, metadata: grpc.Metadata) => void): void;
  getMyRegistrations(request: GetMyRegistrationsRequest, metadata: grpc.Metadata, callback: (err: any|null, response: GetMyRegistrationsResponse, metadata: grpc.Metadata) => void): void;

  getMyRegistrations(request: GetMyRegistrationsRequest, arg1?: grpc.Metadata|((err: any|null, response: GetMyRegistrationsResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: GetMyRegistrationsResponse, metadata: grpc.Metadata) => void): Promise<GetMyRegistrationsResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<GetMyRegistrationsResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__RegistrationManager.GetMyRegistrations, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  getRegisteredPeople(request: GetRegisteredPeopleRequest): Promise<GetRegisteredPeopleResponse>;
  getRegisteredPeople(request: GetRegisteredPeopleRequest, metadata: grpc.Metadata): Promise<GetRegisteredPeopleResponse>;
  getRegisteredPeople(request: GetRegisteredPeopleRequest, callback: (err: any|null, response: GetRegisteredPeopleResponse, metadata: grpc.Metadata) => void): void;
  getRegisteredPeople(request: GetRegisteredPeopleRequest, metadata: grpc.Metadata, callback: (err: any|null, response: GetRegisteredPeopleResponse, metadata: grpc.Metadata) => void): void;

  getRegisteredPeople(request: GetRegisteredPeopleRequest, arg1?: grpc.Metadata|((err: any|null, response: GetRegisteredPeopleResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: GetRegisteredPeopleResponse, metadata: grpc.Metadata) => void): Promise<GetRegisteredPeopleResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<GetRegisteredPeopleResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__RegistrationManager.GetRegisteredPeople, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  deleteRegistration(request: DeleteRegistrationRequest): Promise<DeleteRegistrationResponse>;
  deleteRegistration(request: DeleteRegistrationRequest, metadata: grpc.Metadata): Promise<DeleteRegistrationResponse>;
  deleteRegistration(request: DeleteRegistrationRequest, callback: (err: any|null, response: DeleteRegistrationResponse, metadata: grpc.Metadata) => void): void;
  deleteRegistration(request: DeleteRegistrationRequest, metadata: grpc.Metadata, callback: (err: any|null, response: DeleteRegistrationResponse, metadata: grpc.Metadata) => void): void;

  deleteRegistration(request: DeleteRegistrationRequest, arg1?: grpc.Metadata|((err: any|null, response: DeleteRegistrationResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: DeleteRegistrationResponse, metadata: grpc.Metadata) => void): Promise<DeleteRegistrationResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<DeleteRegistrationResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__RegistrationManager.DeleteRegistration, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  registerToHomeroom(request: RegisterToHomeroomRequest): Promise<RegisterToHomeroomResponse>;
  registerToHomeroom(request: RegisterToHomeroomRequest, metadata: grpc.Metadata): Promise<RegisterToHomeroomResponse>;
  registerToHomeroom(request: RegisterToHomeroomRequest, callback: (err: any|null, response: RegisterToHomeroomResponse, metadata: grpc.Metadata) => void): void;
  registerToHomeroom(request: RegisterToHomeroomRequest, metadata: grpc.Metadata, callback: (err: any|null, response: RegisterToHomeroomResponse, metadata: grpc.Metadata) => void): void;

  registerToHomeroom(request: RegisterToHomeroomRequest, arg1?: grpc.Metadata|((err: any|null, response: RegisterToHomeroomResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: RegisterToHomeroomResponse, metadata: grpc.Metadata) => void): Promise<RegisterToHomeroomResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<RegisterToHomeroomResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__RegistrationManager.RegisterToHomeroom, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

}

