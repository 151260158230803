import { date_pb } from 'libs/generated-grpc-ts';

const utcAwareDate = (dateObj: Date, useUTC: boolean) => {
  const dateBase = (date: any) => (getter: string, utcGetter: string) => {
    return useUTC ? date[utcGetter]() : date[getter]();
  };

  const methodGetter = dateBase(dateObj);
  const methods = {
    getSeconds: () => methodGetter('getSeconds', 'getUTCSeconds'),
    getMinutes: () => methodGetter('getMinutes', 'getUTCMinutes'),
    getHours: () => methodGetter('getHours', 'getUTCHours'),
    getMonth: () => methodGetter('getMonth', 'getUTCMonth'),
    getFullYear: () => methodGetter('getFullYear', 'getUTCFullYear'),
    getDate: () => methodGetter('getDate', 'getUTCDate'),
  };
  return methods;
};

export const toProto = (date: Date, useUTC = false): date_pb.DateTime => {
  const dateObj = utcAwareDate(date, useUTC);

  const msg = new date_pb.DateTime();
  const seconds =
    dateObj.getHours() * 3600 +
    dateObj.getMinutes() * 60 +
    dateObj.getSeconds();
  msg.setYear(dateObj.getFullYear());
  msg.setDay(dateObj.getDate());
  msg.setMonth(dateObj.getMonth() + 1);
  msg.setSeconds(seconds + 1);
  return msg;
};

export const fromProto = (message: date_pb.DateTime, useUTC = false) => {
  const dateConstruct = (
    ...args: [number, number, number?, number?, number?, number?, number?]
  ) => {
    return useUTC ? new Date(Date.UTC(...args)) : new Date(...args);
  };

  if (!message) return '';
  const year = message.getYear();
  const month = message.getMonth();
  const day = message.getDay();

  const date = dateConstruct(year, month - 1, day, 23, 59);
  return date;
};
