import { Injectable } from '@angular/core';

import { FlexTimeActivityInstance } from 'libs/domain';
import { MingaPermission } from 'libs/domain';

import { AuthInfoService } from '@app/src/app/minimal/services/AuthInfo';
import { PermissionsService } from '@app/src/app/permissions';

@Injectable({ providedIn: 'root' })
export class FlexTimePermissionsService {
  constructor(
    private _permissions: PermissionsService,
    private _authInfoService: AuthInfoService,
  ) {}

  /**
   * Can manage all aspects of flextime
   * Eg Manager, Owner, School Manager, Super Admins
   */
  public isFlexTimeAdmin(): boolean {
    return this._permissions.hasPermission(MingaPermission.FLEX_TIME_MANAGE);
  }

  /**
   * Can access flex reporting
   */
  public isFlexReporter(): boolean {
    return (
      this._permissions.hasPermission(MingaPermission.FLEX_TIME_VIEW_REPORTS) ||
      this._permissions.hasPermission(MingaPermission.FLEX_TIME_MANAGE)
    );
  }

  /**
   * Can only manage their own flex time related things
   * Eg Staff/Teachers
   */
  public isFlexTimeSelfManager(): boolean {
    return this._permissions.hasPermission(
      MingaPermission.FLEXTIME_MANAGE_OWN_ACTIVITY,
    );
  }

  /**
   * Can only register
   * Eg Students
   */
  public isFlexTimeRegistrant(): boolean {
    return this._permissions.hasPermission(
      MingaPermission.FLEX_TIME_REGISTER_FOR_ACTIVITY,
    );
  }

  public isFlexTimeSelfManagerOrHigher(): boolean {
    return this.isFlexTimeAdmin() || this.isFlexTimeSelfManager();
  }

  /**
   * 1. Admins can edit all things
   * 2. Staff/Teachers can only edit their own activities
   */
  public canEditActivityInstance(instance: FlexTimeActivityInstance): boolean {
    return (
      this.isFlexTimeAdmin() ||
      instance?.flexTimeActivity?.createdByPerson.hash ===
        this._authInfoService.authPersonHash
    );
  }

  /**
   * Permission check for registering/unregistering students for activities
   * 1. Flex admins can do all things
   * 2. Otherwise user needs to be teacher+ role and period has enabled student management, and it needs to be their activity
   */
  public canManageActivityRegistration(
    instance: FlexTimeActivityInstance,
  ): boolean {
    if (this.isFlexTimeAdmin()) return true;

    const enableStudentManagement =
      instance?.flexTimePeriod?.enableStudentManagement;

    return (
      this.isFlexTimeSelfManager() &&
      this.canEditActivityInstance(instance) &&
      enableStudentManagement
    );
  }
}
