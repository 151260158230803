import { INotificationType } from 'libs/domain';

export const isPointsNotification = (type: string): boolean => {
  if (!type) {
    return false;
  }
  switch (type) {
    case INotificationType.POINTS_EVENT_CHECKIN:
      return true;
    case INotificationType.POINTS_IMAGE_CHALLENGE:
      return true;
    case INotificationType.POINTS_TEXT_CHALLENGE:
      return true;
    case INotificationType.POINTS_POLL:
      return true;
    case INotificationType.POINTS_CUSTOM:
      return true;
    default:
      break;
  }

  return false;
};
