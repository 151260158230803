import { Injectable } from '@angular/core';

import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { BadgeManager } from '@app/src/app/minimal/services/BadgeManager';
import { NotificationStoreModel } from '@app/src/app/store/Notifications/model';
import { NotificationsState } from '@app/src/app/store/Notifications/selectors';

import { UserStorage } from '../UserStorage';

@Injectable({ providedIn: 'root' })
export class NotificationBadgeSyncService {
  readonly notifications$: Observable<NotificationStoreModel[]> =
    this.store.pipe(select(NotificationsState.selectAll));

  constructor(
    private badgeManager: BadgeManager,
    private userStorage: UserStorage,
    private store: Store<any>,
  ) {
    this.notifications$.subscribe(notifications => {
      let count = 0;
      if (notifications.length > 0) {
        count = 1;
      }
      this.badgeManager.setBadgeCount('notification', count);

      this._setStoredBadgeCount(count);
    });
  }

  private async _getStoredBadgeCount(): Promise<number> {
    return (
      (await this.userStorage.getItem<number>('notificationBadgeCount')) || 0
    );
  }

  private async _setStoredBadgeCount(count: number) {
    await this.userStorage.setItem('notificationBadgeCount', count);
  }

  async syncBadgeCount() {
    if ((window as any).FirebasePlugin && !window.MINGA_APP_BROWSER) {
      return new Promise((resolve, reject) => {
        (window as any).FirebasePlugin.getBadgeNumber((badgeNumber: any) => {
          this.badgeManager.setBadgeCount('notification', badgeNumber || 0);
          this._setStoredBadgeCount(badgeNumber || 0);
          resolve(null);
        }, reject);
      });
    } else {
      const storedBadgeCount = await this._getStoredBadgeCount();
      this.badgeManager.setBadgeCount('notification', storedBadgeCount);
    }
  }

  clearBadgeCount() {
    this.badgeManager.setBadgeCount('notification', 0);
    this._setStoredBadgeCount(0);
    if ((window as any).FirebasePlugin) {
      (window as any).FirebasePlugin.setBadgeNumber(0);
    }
  }

  getBadgeCount() {
    return this.badgeManager.getBadgeCount('notification');
  }
}
