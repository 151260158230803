import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Router } from '@angular/router';

import {
  OverlayStepInfo,
  parseOverlayStepperResult,
} from '@app/src/app/misc/overlay';
import { OverlayStructureTemplateAddMode } from '@app/src/app/overlay/services/OverlayStructureRegistry';
import { closeCurrentOverlay } from '@app/src/app/util/overlay';

/**
 * @NOTE - This is an implementation of the old overlay navigation in the new
 *         overlay nav system. DO NOT USE IN NEW CODE
 */
@Component({
  selector: 'mg-legacy-overlay-nav',
  templateUrl: './MgLegacyOverlayNav.component.html',
  styleUrls: ['./MgLegacyOverlayNav.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MgLegacyOverlayNavComponent implements OnInit, OnDestroy {
  private _component: any;

  /**
   * Component that implements the various legacy overlay interfaces
   */
  @Input()
  set component(component: any) {
    this._component = component;
    if (component) {
      try {
        this.setupOverlayComponentListeners();
      } catch (err) {
        console.error(err);
      }
    }
  }

  get component() {
    return this._component;
  }

  @Input()
  mode: OverlayStructureTemplateAddMode = 'replace';

  @Input() showTopNav = true;

  overlayNext?: OverlayStepInfo;
  overlayPrevious?: OverlayStepInfo;

  constructor(private _router: Router) {}

  async overlayPreviousClick(ev: MouseEvent) {
    if (typeof this.component.onOverlayPrevious === 'function') {
      this.component.onOverlayPrevious();
    } else if (typeof this.component.onOverlayClose === 'function') {
      try {
        const closeResult = await this.component.onOverlayClose();
        // Falsy result means the onCloseOverlay did _NOT_ handle it and wants
        // the default behaviour.
        if (!closeResult) {
          const unusedPromise = closeCurrentOverlay(this._router);
        }
      } catch (err) {
        console.error(err);
        const unusedPromise = closeCurrentOverlay(this._router);
      }
    } else {
      const unusedPromise = closeCurrentOverlay(this._router);
    }
  }

  overlayNextClick(ev: MouseEvent) {
    if (this.overlayNext && !this.overlayNext.disabled) {
      if (typeof this.component.onOverlayNext === 'function') {
        this.component.onOverlayNext();
      }
    }
  }

  setupOverlayComponentListeners() {
    this.updateOverlayNav();

    const component = this.component;

    const prevDisableHandler = (disabled: boolean) => {
      if (this.overlayPrevious) {
        this.overlayPrevious.disabled = !!disabled;
      }
    };

    const nextDisableHandler = (disabled: boolean) => {
      if (this.overlayNext) {
        this.overlayNext.disabled = !!disabled;
      }
    };

    if (typeof component.registerOnOverlayNextDisabled === 'function') {
      component.registerOnOverlayNextDisabled(nextDisableHandler);
    }

    if (typeof component.registerOnOverlayPreviousDisabled === 'function') {
      component.registerOnOverlayPreviousDisabled(prevDisableHandler);
    }

    if (typeof component.registerOnOverlayNavRefresh === 'function') {
      component.registerOnOverlayNavRefresh(() => this.updateOverlayNav());
    }
  }

  ngOnInit() {}

  ngOnDestroy() {}

  private updateOverlayNav() {
    const overlayComp = this.component;

    if (typeof overlayComp.hasOverlayPrevious === 'function') {
      const overlayPrevious = overlayComp.hasOverlayPrevious();
      this.overlayPrevious = parseOverlayStepperResult(overlayPrevious, {
        defaultText: 'Close',
        defaultIcon: 'close',
      });
    }

    if (typeof overlayComp.hasOverlayNext === 'function') {
      const overlayNext = overlayComp.hasOverlayNext();
      this.overlayNext = parseOverlayStepperResult(overlayNext, {
        defaultText: 'Next',
        defaultIcon: '',
      });
    }

    if (!this.overlayPrevious) {
      this.overlayPrevious = parseOverlayStepperResult(true, {
        defaultText: 'Close',
        defaultIcon: 'close',
      });
    }
  }
}
