<div fxLayout="row">
  <div class="mg-avatar-wrap">
    <mg-placeholder-avatar size="xsmall"></mg-placeholder-avatar>
    <div
      *ngIf="filename$ | async; else noProfilePictureTemplate"
      class="profile-picture"
      [mgStudentPicture]="filename$ | async"
      [role]="role$ | async"></div>
  </div>
  <p
    *ngIf="!noDisplayName"
    class="display-name mg-minicopy">
    {{ displayName$ | async }}
  </p>
</div>

<ng-template #noProfilePictureTemplate>
  <mg-avatar
    [src]="avatarUrl$ | async"
    [color]="color$ | async"
    [borderless]="borderless">
  </mg-avatar>
</ng-template>
