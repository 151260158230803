export enum ReportFilter {
  ALL = 0,
  RESOLVED = 1,
  REPORTED = 2,
  PUBLISHED = 3,
  DELETED = 4,
}

export namespace ReportFilter {
  const keyStrings = [
    'ALL',
    'RESOLVED',
    'REPORTED',
    'PUBLISHED',
    'DELETED',
  ];

  /**
   * Returns a human readable report filter
   */
  export function toString(reportFilter: ReportFilter): string {
    switch(reportFilter) {
      // Return the ReportFilter key by default
      default: return ReportFilter[reportFilter];
    }
  }

  export function getKeys() {
    return keyStrings;
  }
}