import { FlexTimePeriod, MinimalFlexTimePeriod } from 'libs/domain';
import { flex_time_pb } from 'libs/generated-grpc-ts';

import { FlexTimeActivityInstanceMapper } from '.';
import { DateTimeMapper } from '../common';

export const toProto = (
  period: FlexTimePeriod,
  useUTC = true,
): flex_time_pb.FlexTimePeriod => {
  const message = new flex_time_pb.FlexTimePeriod();
  if (period.mingaId) message.setMingaId(period.mingaId);
  if (period.id) message.setId(period.id);
  if (period.title) message.setTitle(period.title);
  if (period.date) message.setDate(DateTimeMapper.toProto(period.date, useUTC));
  if (period.startTime) message.setStartTime(period.startTime);
  if (period.endTime) message.setEndTime(period.endTime);
  if (period.totalSpaces) message.setTotalSpaces(period.totalSpaces);
  message.setEnableActivityManagement(period.enableActivityManagement);
  message.setEnableStudentManagement(period.enableStudentManagement);
  message.setEnableStudentSelfManagement(period.enableStudentSelfManagement);
  if (period.activityInstances?.length) {
    message.setActivityInstancesList(
      period.activityInstances.map(FlexTimeActivityInstanceMapper.toProto),
    );
  }
  return message;
};

export const toMinimalProto = (
  period: FlexTimePeriod,
  useUTC = true,
): flex_time_pb.MinimalFlexTimePeriod => {
  const message = new flex_time_pb.MinimalFlexTimePeriod();
  if (period.id) message.setId(period.id);
  if (period.title) message.setTitle(period.title);
  if (period.date) message.setDate(DateTimeMapper.toProto(period.date, useUTC));
  if (period.startTime) message.setStartTime(period.startTime);
  if (period.endTime) message.setEndTime(period.endTime);
  message.setEnableActivityManagement(period.enableActivityManagement);
  message.setEnableStudentManagement(period.enableStudentManagement);
  message.setEnableStudentSelfManagement(period.enableStudentSelfManagement);

  return message;
};

export const fromMinimalProto = (
  message: flex_time_pb.MinimalFlexTimePeriod,
  useUTC = true,
): MinimalFlexTimePeriod => {
  return {
    id: message.getId(),
    title: message.getTitle(),
    date: DateTimeMapper.fromProto(message.getDate(), useUTC) as any,
    startTime: message.getStartTime(),
    endTime: message.getEndTime(),
    enableActivityManagement: message.getEnableActivityManagement(),
    enableStudentManagement: message.getEnableStudentManagement(),
    enableStudentSelfManagement: message.getEnableStudentSelfManagement(),
  };
};

export const fromProto = (
  message: flex_time_pb.FlexTimePeriod,
  useUTC = true,
): FlexTimePeriod => {
  return {
    mingaId: message.getMingaId(),
    id: message.getId(),
    title: message.getTitle(),
    date: DateTimeMapper.fromProto(message.getDate(), useUTC) as any,
    startTime: message.getStartTime(),
    endTime: message.getEndTime(),
    totalSpaces: message.getTotalSpaces(),
    enableActivityManagement: message.getEnableActivityManagement(),
    enableStudentManagement: message.getEnableStudentManagement(),
    enableStudentSelfManagement: message.getEnableStudentSelfManagement(),
    activityInstances: message
      .getActivityInstancesList()
      .map(FlexTimeActivityInstanceMapper.fromProto),
  };
};
