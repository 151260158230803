import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { ITeamPointsSummaryResult } from 'libs/domain';
import { PointsReportColumns } from 'libs/shared';

import { ReportDatasourceService } from '@app/src/app/components/manager-report/services/report-datasource.service';

import { PmReportsService } from './pm-reports.service';

@Injectable()
export class PmReportsTeamDatasourceService extends ReportDatasourceService<ITeamPointsSummaryResult> {
  constructor(_router: Router, private _pmService: PmReportsService) {
    super(_router, _pmService, PmReportsTeamDatasourceService.name);
    super.setDisplayColumns(PointsReportColumns.TEAM_POINTS);
  }

  async fetch(offset: number, limit: number) {
    return await this._pmService.fetchTeamPointSummary(
      offset,
      limit,
      this._currentSort,
    );
  }
}
