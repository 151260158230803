import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, withLatestFrom } from 'rxjs/operators';

import { FtueService } from '@app/src/app/ftue/Ftue.service';
import { WelcomeService } from '@app/src/app/ftue/Welcome/services';
import { GroupsFacadeService } from '@app/src/app/groups/services';
import { MingaStoreFacadeService } from '@app/src/app/store/Minga/services';
import { TypeEnum } from '@app/src/app/store/root/rootActions';

import { WelcomeActions } from '../actions';

@Injectable()
export class WelcomeEffects {
  welcomeInit$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(WelcomeActions.welcomeInit),
        // @ts-ignore
        map(({ key }) => {
          const hasSeen = this._ftueService.hasSeenScreen(key);
          if (!hasSeen) {
            this._welcomeService.initNewUser();
          }
        }),
      ),
    { dispatch: false },
  );

  districtInit$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(WelcomeActions.districtInit),
        withLatestFrom(
          this._mingaStore.observeDistrictFeatureEnabled(),
          this._groupsFacade.getMyParentGroups(),
        ),
        // @ts-ignore
        map(([{ key }, isEnabled, groups]) => {
          const hasSeen = this._ftueService.hasSeenScreen(key);
          if (isEnabled && !hasSeen && !(groups.length > 0)) {
            this._welcomeService.initDistrictUser();
          } else {
            // mark this as seen locally so that it still comes up if district is
            // turned on later, but does not create a loop.
            this._ftueService.fireNextScreen(key);
          }
        }),
      ),
    { dispatch: false },
  );

  whatsNew$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(WelcomeActions.whatsNew),
        // @ts-ignore
        map(({ key }) => {
          const hasSeen = this._ftueService.hasSeenScreen(key);
          if (!hasSeen) {
            this._welcomeService.initWhatsNew();
          }
        }),
      ),
    { dispatch: false },
  );

  showStudentId$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(TypeEnum.ShowStudentId),
        map(({ key }) => {
          this._welcomeService.showStudentId();
        }),
      ),
    { dispatch: false },
  );

  logout$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(TypeEnum.Logout),
        map(() => {
          this._ftueService.resetScreens();
        }),
      ),
    { dispatch: false },
  );

  constructor(
    private _actions$: Actions<WelcomeActions.Union>,
    private _welcomeService: WelcomeService,
    private _mingaStore: MingaStoreFacadeService,
    private _ftueService: FtueService,
    private _groupsFacade: GroupsFacadeService,
  ) {}
}
