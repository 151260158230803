import { Injectable } from '@angular/core';

import { gateway } from 'libs/generated-grpc-web';

import { PagedScrollerDatasource } from '@app/src/app/components/mg-paged-scroller/PagedScrollerDatasource';
import { StreamManager } from '@app/src/app/minimal/services/StreamManager';
import { ContentFeedService } from '@app/src/app/services/ContentFeed';

@Injectable()
export class HomeFeedDataSource extends PagedScrollerDatasource<gateway.feed_pb.ContentFeedItemView.AsObject> {
  protected limit = 10;
  protected offset = 0;
  protected threshold = 3;
  streamName = 'HomeFeed';

  constructor(
    private _contentFeedService: ContentFeedService,
    streamManager: StreamManager,
  ) {
    super(streamManager);
  }

  protected async _fetch() {
    if (this.reachedEnd) {
      return;
    }
    const newItems = await this._contentFeedService.getHomeFeed(
      this.limit,
      this.offset,
      this.filter,
    );

    this.addNewItems(newItems);
  }
}
