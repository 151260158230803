import {
  Component,
  ContentChild,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  NgZone,
  OnChanges,
  OnInit,
  Output,
  Renderer2,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  AsyncValidator,
  ControlValueAccessor,
  UntypedFormControl,
  UntypedFormGroup,
  NG_ASYNC_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms';

import * as _ from 'lodash';

export const MG_ADDRESS_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => AddressComponent),
  multi: true,
};

const MG_ADDRESS_VALIDATOR: any = {
  provide: NG_ASYNC_VALIDATORS,
  useExisting: forwardRef(() => AddressComponent),
  multi: true,
};

export interface IAddressComponents {
  country: string;
  locality: string;
  administrative_area_level_1: string;
  administrative_area_level_2: string;
  postal_code: string;
  street_number: string;
  street_address: string;
  sublocality: string;
  route: string;
}

export interface IAddressModelValue {
  place_id?: string;
  name: string;
  types: string[];
  components: IAddressComponents;
}

/**
 * Slot component for `<mg-address>` to replace it's `<mat-label>` contents.
 */
@Component({
  selector: 'mg-address-label',
  template: '<ng-content></ng-content>',
})
export class AddressSlot_Label {}

/**
 * Slot component for `<mg-address>` to replace it's `<mat-hint>` contents.
 */
@Component({
  selector: 'mg-address-hint',
  template: '<ng-content></ng-content>',
})
export class AddressSlot_Hint {}

@Component({
  providers: [MG_ADDRESS_VALIDATOR, MG_ADDRESS_VALUE_ACCESSOR],
  selector: 'mg-address',
  templateUrl: './Address.component.html',
  styleUrls: ['./Address.component.scss'],
})
export class AddressComponent
  implements OnInit, OnChanges, ControlValueAccessor, AsyncValidator
{
  private _onChange?: (value: string | null) => void;
  private _onTouched?: Function;
  inputControl: UntypedFormControl;
  _formGroup: UntypedFormGroup;

  displayValue = '';

  placeId = '';

  addressComponents: IAddressComponents = {
    country: '',
    locality: '',
    administrative_area_level_1: '',
    administrative_area_level_2: '',
    postal_code: '',
    street_number: '',
    street_address: '',
    sublocality: '',
    route: '',
  };

  @ContentChild(AddressSlot_Hint, { static: false })
  hintSlot?: AddressSlot_Hint;

  @Input()
  outerFormControl: UntypedFormControl;

  @ViewChild('textInput', { static: true })
  textInput: ElementRef;

  @Input()
  editMode = false;

  @Input()
  types: string[] = [];

  @Input()
  placeholder = '';

  private autocomplete?: google.maps.places.Autocomplete;
  private _updatingWithPlaceId?: Promise<ValidationErrors | null>;

  @Input()
  originalValue = '';

  @Output('save')
  onSaveCallback: EventEmitter<string> = new EventEmitter();

  @Output('focus')
  onFocusCallback: EventEmitter<void> = new EventEmitter();

  @Output('blur')
  onBlurCallback: EventEmitter<any> = new EventEmitter();

  @Input()
  editing = false;

  @Input()
  required = false;

  @Output()
  editingChange = new EventEmitter<boolean>();

  @Output()
  placeChange = new EventEmitter<IAddressModelValue | null>();

  get statusPending() {
    return this.inputControl.pending || this.outerFormControl.pending;
  }

  get saveDisabled() {
    const hasInvalidValue =
      this.statusInvalid || !this.placeId || !this.displayValue;
    const hasDifferentValue = this.placeId != this.originalValue;
    return hasInvalidValue || !hasDifferentValue;
  }

  get statusInvalid() {
    return this.inputControl.dirty && this.inputControl.invalid;
  }

  constructor(private ngZone: NgZone, private _renderer: Renderer2) {
    this._formGroup = new UntypedFormGroup({});
    this._initInputControl();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.editing) {
      this.toggleEdit(this.editing);
    }

    if (changes.types && this.autocomplete) {
      this.autocomplete.setTypes(this.types);
    }
  }

  private _initInputControl() {
    let previousValue = {};
    if (this.inputControl) {
      previousValue = this.inputControl.value;
    }

    const validators: ValidatorFn[] = [];

    this.inputControl = new UntypedFormControl({}, validators);
    this.inputControl.setValue(previousValue);

    this._formGroup.setControl('inputControl', this.inputControl);
  }

  async validate(control: AbstractControl): Promise<ValidationErrors | null> {
    return this._updatingWithPlaceId;
  }

  registerOnChange(onChange) {
    this._onChange = onChange;
  }

  registerOnTouched(onTouched) {
    this._onTouched = onTouched;
  }

  writeValue(value: any) {
    // If the value of the model is a string we assume it's a place_id
    if (typeof value === 'string') {
      if (value) {
        this.updateWithPlaceId(value, false);
      }
    } else if (value && typeof value === 'object') {
      if ('components' in value && Array.isArray(value.components)) {
        const addressKeys = Object.keys(this.addressComponents);
        const valueKeys = Object.keys(value.components);
        const missingKeys = _.difference(valueKeys, addressKeys);

        if (missingKeys.length > 0) {
          console.warn(
            `Value for <mg-address> provided was missing`,
            missingKeys,
            ` in`,
            `the components field`,
          );
          return;
        }

        this.addressComponents = value.components;
      } else if ('place_id' in value) {
        if (typeof value.place_id === 'string' && value.place_id) {
          this.updateWithPlaceId(value, false);
        } else {
          console.warn(
            'Value for <mg-address> had invalid place_id',
            value.place_id,
          );
        }
      } else if ('formatted_address' in value) {
        this.displayValue = value.formatted_address || '';
      }
    }
  }

  private _tryCreateAutoComplete() {
    try {
      const inputEl = <HTMLInputElement>this.textInput.nativeElement;
      this.autocomplete = new google.maps.places.Autocomplete(inputEl, {
        types: this.types,
      });
    } catch (err) {
      return err;
    }
  }

  private async tryCreateAutoComplete(maxAttempts = -1) {
    return new Promise((resolve, reject) => {
      const count = 0;

      const doTry = () => {
        this._tryCreateAutoComplete();

        if (!this.autocomplete) {
          if (count >= maxAttempts) {
            reject(
              new Error(
                `Tried to create auto complete ${maxAttempts} times and failed`,
              ),
            );
          } else {
            setTimeout(doTry, 2000);
          }
        } else {
          resolve(null);
        }
      };

      doTry();
    });
  }

  private onPlaceChanged(
    place: google.maps.places.PlaceResult,
    notify: boolean,
  ) {
    const typeKeys = Object.keys(this.addressComponents);

    for (const addressComponent of place.address_components) {
      for (const typeKey of typeKeys) {
        if (addressComponent.types.includes(typeKey)) {
          this.addressComponents[typeKey] = addressComponent.short_name;
        }
      }
    }

    this.placeId = place.place_id;
    this.displayValue = place.formatted_address;

    const modelValue: IAddressModelValue = {
      place_id: this.placeId,
      name: place.name,
      types: place.types,
      components: this.addressComponents,
    };

    if (notify) {
      this._onChange(this.placeId);
      this._onTouched();
      if (this.outerFormControl) {
        this.outerFormControl.markAsTouched();
        this.outerFormControl.markAsDirty();
      }
    }
    this.placeChange.emit(modelValue);
  }

  private updateWithPlaceId(placeId: string, notify: boolean) {
    const dummyDiv = document.createElement('div');
    const placesService = new google.maps.places.PlacesService(dummyDiv);

    this.setDisabledState(true);

    this._updatingWithPlaceId = new Promise((resolve, reject) => {
      const fields = [
        'adr_address',
        'formatted_address',
        'address_component',
        'place_id',
        'name',
        'type',
      ];
      placesService.getDetails({ placeId, fields }, (place, status) => {
        this.ngZone.run(() => {
          this.setDisabledState(false);

          if (status == google.maps.places.PlacesServiceStatus.OK) {
            this.displayValue = place.formatted_address;
            this.onPlaceChanged(place, notify);
            resolve(null);
          } else {
            console.error(
              `<mg-address> update with place_id (${placeId}) failed with: ` +
                `${status}`,
            );
            this._onChange(null);
            this.placeChange.emit(null);
            resolve({ placeId: true });
          }

          this._updatingWithPlaceId = null;
        });
      });
    });
  }

  private async createAutoComplete() {
    this.setDisabledState(true);
    await this.tryCreateAutoComplete(16);
    this.setDisabledState(false);

    this.autocomplete.addListener('place_changed', () => {
      const place = this.autocomplete.getPlace();

      // the autocomplete listeners run outside of angular so let's bring it in
      this.ngZone.run(() => this.onPlaceChanged(place, true));
    });
  }

  setDisabledState(isDisabled: boolean): void {
    this._renderer.setProperty(
      this.textInput.nativeElement,
      'disabled',
      isDisabled,
    );
  }

  ngOnInit() {
    this.createAutoComplete();
  }

  initEdit() {
    this.displayValue = '';
    this.inputControl.markAsPristine();

    const nativeElement = this.textInput.nativeElement;
    nativeElement.focus();
  }

  clickEdit(e: any) {
    e.preventDefault();
    e.stopImmediatePropagation();
    e.stopPropagation();

    this.toggleEdit(true);
    this.editingChange.emit(this.editing);
  }

  toggleEdit(toggleState = !this.editing) {
    this.editing = toggleState;

    if (this.editing) {
      this.initEdit();
    } else {
      if (typeof this.originalValue == 'string') {
        this.placeId = this.originalValue;
        this.writeValue(this.placeId);
      }
      this.inputControl.markAsPristine();
    }
  }

  onFocus() {
    if (!this.editing) {
      this.toggleEdit(true);
      this.editingChange.emit(this.editing);
      this.onFocusCallback.emit();
    }
  }

  onKeyDown(e: KeyboardEvent) {
    if (this.editMode && e.key === 'Enter') {
      e.preventDefault();
      e.stopImmediatePropagation();
      e.stopPropagation();

      this.onSave();
    }
  }

  onSaveClick(e: any) {
    e.preventDefault();
    e.stopImmediatePropagation();
    e.stopPropagation();
    this.onSave();
  }

  onCancelClick(e: any) {
    e.preventDefault();
    e.stopImmediatePropagation();
    e.stopPropagation();
    this.toggleEdit(false);
    this.editingChange.emit(this.editing);
  }

  onSave() {
    if (this.saveDisabled) return;
    this.onSaveCallback.emit(this.placeId);
  }
}
