import { Injectable, NgZone } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { grpc } from '@improbable-eng/grpc-web';

import {
  CreateDateRangePresetRequest,
  CreateDateRangePresetResponse,
  DeleteDateRangePresetRequest,
  DeleteDateRangePresetResponse,
  GetDateRangePresetRequest,
  GetDateRangePresetResponse,
  GetDateRangePresetsRequest,
  GetDateRangePresetsResponse,
  ToggleDateRangePresetActiveRequest,
  ToggleDateRangePresetActiveResponse,
  UpdateDateRangePresetRequest,
  UpdateDateRangePresetResponse,
} from '../date_range/date_range_preset_pb';

import {
  DateRangePresets as __DateRangePresets,
} from '../date_range/date_range_preset_pb_service';

@Injectable({providedIn: 'root'})
export class DateRangePresets {

  constructor(private _ngZone: NgZone) {
  }

  getDateRangePreset(request: GetDateRangePresetRequest): Promise<GetDateRangePresetResponse>;
  getDateRangePreset(request: GetDateRangePresetRequest, metadata: grpc.Metadata): Promise<GetDateRangePresetResponse>;
  getDateRangePreset(request: GetDateRangePresetRequest, callback: (err: any|null, response: GetDateRangePresetResponse, metadata: grpc.Metadata) => void): void;
  getDateRangePreset(request: GetDateRangePresetRequest, metadata: grpc.Metadata, callback: (err: any|null, response: GetDateRangePresetResponse, metadata: grpc.Metadata) => void): void;

  getDateRangePreset(request: GetDateRangePresetRequest, arg1?: grpc.Metadata|((err: any|null, response: GetDateRangePresetResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: GetDateRangePresetResponse, metadata: grpc.Metadata) => void): Promise<GetDateRangePresetResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<GetDateRangePresetResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__DateRangePresets.GetDateRangePreset, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  getDateRangePresets(request: GetDateRangePresetsRequest): Promise<GetDateRangePresetsResponse>;
  getDateRangePresets(request: GetDateRangePresetsRequest, metadata: grpc.Metadata): Promise<GetDateRangePresetsResponse>;
  getDateRangePresets(request: GetDateRangePresetsRequest, callback: (err: any|null, response: GetDateRangePresetsResponse, metadata: grpc.Metadata) => void): void;
  getDateRangePresets(request: GetDateRangePresetsRequest, metadata: grpc.Metadata, callback: (err: any|null, response: GetDateRangePresetsResponse, metadata: grpc.Metadata) => void): void;

  getDateRangePresets(request: GetDateRangePresetsRequest, arg1?: grpc.Metadata|((err: any|null, response: GetDateRangePresetsResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: GetDateRangePresetsResponse, metadata: grpc.Metadata) => void): Promise<GetDateRangePresetsResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<GetDateRangePresetsResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__DateRangePresets.GetDateRangePresets, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  createDateRangePreset(request: CreateDateRangePresetRequest): Promise<CreateDateRangePresetResponse>;
  createDateRangePreset(request: CreateDateRangePresetRequest, metadata: grpc.Metadata): Promise<CreateDateRangePresetResponse>;
  createDateRangePreset(request: CreateDateRangePresetRequest, callback: (err: any|null, response: CreateDateRangePresetResponse, metadata: grpc.Metadata) => void): void;
  createDateRangePreset(request: CreateDateRangePresetRequest, metadata: grpc.Metadata, callback: (err: any|null, response: CreateDateRangePresetResponse, metadata: grpc.Metadata) => void): void;

  createDateRangePreset(request: CreateDateRangePresetRequest, arg1?: grpc.Metadata|((err: any|null, response: CreateDateRangePresetResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: CreateDateRangePresetResponse, metadata: grpc.Metadata) => void): Promise<CreateDateRangePresetResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<CreateDateRangePresetResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__DateRangePresets.CreateDateRangePreset, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  updateDateRangePreset(request: UpdateDateRangePresetRequest): Promise<UpdateDateRangePresetResponse>;
  updateDateRangePreset(request: UpdateDateRangePresetRequest, metadata: grpc.Metadata): Promise<UpdateDateRangePresetResponse>;
  updateDateRangePreset(request: UpdateDateRangePresetRequest, callback: (err: any|null, response: UpdateDateRangePresetResponse, metadata: grpc.Metadata) => void): void;
  updateDateRangePreset(request: UpdateDateRangePresetRequest, metadata: grpc.Metadata, callback: (err: any|null, response: UpdateDateRangePresetResponse, metadata: grpc.Metadata) => void): void;

  updateDateRangePreset(request: UpdateDateRangePresetRequest, arg1?: grpc.Metadata|((err: any|null, response: UpdateDateRangePresetResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: UpdateDateRangePresetResponse, metadata: grpc.Metadata) => void): Promise<UpdateDateRangePresetResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<UpdateDateRangePresetResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__DateRangePresets.UpdateDateRangePreset, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  deleteDateRangePreset(request: DeleteDateRangePresetRequest): Promise<DeleteDateRangePresetResponse>;
  deleteDateRangePreset(request: DeleteDateRangePresetRequest, metadata: grpc.Metadata): Promise<DeleteDateRangePresetResponse>;
  deleteDateRangePreset(request: DeleteDateRangePresetRequest, callback: (err: any|null, response: DeleteDateRangePresetResponse, metadata: grpc.Metadata) => void): void;
  deleteDateRangePreset(request: DeleteDateRangePresetRequest, metadata: grpc.Metadata, callback: (err: any|null, response: DeleteDateRangePresetResponse, metadata: grpc.Metadata) => void): void;

  deleteDateRangePreset(request: DeleteDateRangePresetRequest, arg1?: grpc.Metadata|((err: any|null, response: DeleteDateRangePresetResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: DeleteDateRangePresetResponse, metadata: grpc.Metadata) => void): Promise<DeleteDateRangePresetResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<DeleteDateRangePresetResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__DateRangePresets.DeleteDateRangePreset, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  toggleDateRangePresetActive(request: ToggleDateRangePresetActiveRequest): Promise<ToggleDateRangePresetActiveResponse>;
  toggleDateRangePresetActive(request: ToggleDateRangePresetActiveRequest, metadata: grpc.Metadata): Promise<ToggleDateRangePresetActiveResponse>;
  toggleDateRangePresetActive(request: ToggleDateRangePresetActiveRequest, callback: (err: any|null, response: ToggleDateRangePresetActiveResponse, metadata: grpc.Metadata) => void): void;
  toggleDateRangePresetActive(request: ToggleDateRangePresetActiveRequest, metadata: grpc.Metadata, callback: (err: any|null, response: ToggleDateRangePresetActiveResponse, metadata: grpc.Metadata) => void): void;

  toggleDateRangePresetActive(request: ToggleDateRangePresetActiveRequest, arg1?: grpc.Metadata|((err: any|null, response: ToggleDateRangePresetActiveResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: ToggleDateRangePresetActiveResponse, metadata: grpc.Metadata) => void): Promise<ToggleDateRangePresetActiveResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<ToggleDateRangePresetActiveResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__DateRangePresets.ToggleDateRangePresetActive, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

}

