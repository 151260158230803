import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnDestroy,
} from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';

import { IMembershipList } from 'libs/domain';
import { sample } from 'lodash';
import { BehaviorSubject, Observable, of, ReplaySubject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

import { mgResolveAssetUrl } from '@app/src/app/util/asset';

import { ConfirmationDialogComponent } from '@shared/components/confirmation-dialog';
import {
  MODAL_OVERLAY_DATA,
  ModalOverlayPrimaryHeaderBackground,
  ModalOverlayRef,
  ModalOverlayServiceCloseEventType,
} from '@shared/components/modal-overlay';
import { SystemAlertSnackBarService } from '@shared/components/system-alert-snackbar';

import { PointsManagerService } from '../../../../services';
import {
  DEFAULT_SCHOOL_TEAM,
  DEFAULT_TEAM_COLORS,
  PM_TEAMS_FORMGROUP,
  PmTeamsEditFormFields,
  PmTeamsEditMessages,
} from '../../constants';
import { PmTeamsService } from '../../services';
import { PmTeamsEditModalData, PmTeamsEditModalResponse } from '../../types';

@Component({
  selector: 'mg-pm-teams-edit',
  templateUrl: './pm-teams-edit.component.html',
  styleUrls: ['./pm-teams-edit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PmTeamsEditComponent implements OnDestroy {
  /** Constants */
  public readonly MESSAGES = PmTeamsEditMessages;
  public readonly MODAL_CONFIG = {
    headerBg: ModalOverlayPrimaryHeaderBackground.GREEN,
  };
  public readonly FORM_FIELD = PmTeamsEditFormFields;

  /** General Observables */
  private _destroyed = new ReplaySubject<void>(1);
  private _isLoading = new BehaviorSubject<boolean>(false);
  public readonly isLoading$ = this._isLoading.asObservable();

  /** Form Controls */
  public readonly form = this._fb.group(PM_TEAMS_FORMGROUP);

  /** Automation */
  public readonly modalTitle$: Observable<string>;
  private readonly _schoolTeam = new BehaviorSubject<IMembershipList>({
    ...DEFAULT_SCHOOL_TEAM,
  });
  public readonly schoolTeam$ = this._schoolTeam.asObservable();
  public assetPath?: string;
  public isNew$: Observable<boolean>;
  private _isNew: boolean;

  /** Computed Getters */
  get canSubmit(): boolean {
    return this.form.dirty && this.form.valid;
  }

  public readonly membershipLists$ = this.schoolTeam$.pipe(
    takeUntil(this._destroyed),
    map(({ id }) => [{ id }]),
  );

  /** Component Constructor */
  constructor(
    @Inject(MODAL_OVERLAY_DATA)
    public dialogData: PmTeamsEditModalData,
    private _modalOverlay: ModalOverlayRef<
      PmTeamsEditModalData,
      PmTeamsEditModalResponse
    >,
    private _dialog: MatDialog,
    private _systemAlertSnackBar: SystemAlertSnackBarService,
    private _fb: UntypedFormBuilder,
    private _teamsService: PmTeamsService,
    private _pmService: PointsManagerService,
  ) {
    if (this.dialogData?.image) {
      this.assetPath = mgResolveAssetUrl(this.dialogData.image);
    }
    this._isNew = this.dialogData.id ? false : true;
    this.isNew$ = of(this._isNew);
    this._initTeamData(this.dialogData?.id);
    this.modalTitle$ = of(
      this._isNew
        ? PmTeamsEditMessages.MODAL_TITLE_NEW
        : PmTeamsEditMessages.MODAL_TITLE_EXISTING,
    );
  }

  ngOnDestroy(): void {
    this._destroyed.next();
    this._destroyed.complete();
    this._isLoading.complete();
  }

  public async submit(): Promise<void> {
    if (!this.canSubmit) return;
    const payload = this._getPayload();
    if (this._isNew) {
      await this._teamsService.create(payload);
      this._modalOverlay.close(ModalOverlayServiceCloseEventType.CREATE);
    } else {
      await this._teamsService.update(payload);
      this._modalOverlay.close(ModalOverlayServiceCloseEventType.SUBMIT);
    }
  }

  public async delete(): Promise<void> {
    const confirmationDialog = this._dialog.open(ConfirmationDialogComponent, {
      data: {
        text: {
          description: this._isNew
            ? `Are you sure you want to discard this new school team?`
            : `Are you sure you want to delete this school team?`,
          deleteBtn: 'Delete',
        },
      },
    });
    confirmationDialog.afterClosed().subscribe(async response => {
      if (!response || !response.confirmed || response.cancelled) return;
      if (!this._isNew) {
        const team = this._schoolTeam.getValue();
        this._teamsService.deleteTeam(team.id);
        this._modalOverlay.close(ModalOverlayServiceCloseEventType.DELETE);
      } else {
        this._modalOverlay.close(ModalOverlayServiceCloseEventType.CLOSE);
      }
    });
  }

  public async changeAsset(assetPath: any) {
    const team = this._schoolTeam.getValue();
    team.assetPath = assetPath;
    this._schoolTeam.next({ ...team });
    this.form.markAsDirty();
  }

  private async _initTeamData(id?: number): Promise<void> {
    if (!id) {
      const color = sample(DEFAULT_TEAM_COLORS);
      this._schoolTeam.next({
        ...this._schoolTeam.getValue(),
        color,
      });
      this.form.get(this.FORM_FIELD.COLOR).setValue(color);
      this.form.markAsPristine();
      this.form.markAsUntouched();
    } else {
      try {
        this._isLoading.next(true);
        const result = await this._pmService.fetchTeam(id);
        this._schoolTeam.next({ ...result });
        this._setFormValues(result);
      } catch (error) {
        this._systemAlertSnackBar.error('Something went wrong.');
        this._modalOverlay.close(ModalOverlayServiceCloseEventType.CLOSE);
      } finally {
        this._isLoading.next(false);
      }
    }
  }

  private _setFormValues(list: IMembershipList) {
    for (const key in list) {
      if (list.hasOwnProperty(key) && this.form.controls[key]) {
        this.form.get(key).setValue(list[key]);
      }
    }
    this.form.markAsPristine();
    this.form.markAsUntouched();
  }

  private _getPayload(): IMembershipList {
    const payload: IMembershipList = { ...this._schoolTeam.getValue() };
    for (const field in this.form.controls) {
      if (this.form.controls.hasOwnProperty(field)) {
        payload[field] = this.form.controls[field].value;
      }
    }
    return payload;
  }
}
