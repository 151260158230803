import { Component, OnInit } from '@angular/core';
import { OnDestroy } from '@angular/core';
import { MediaObserver } from '@angular/flex-layout';
import { ActivatedRoute, Router } from '@angular/router';

import * as mgUtil from 'libs/util';
import { gateway } from 'libs/generated-grpc-web';
import { ReplaySubject } from 'rxjs';

import { ContentEvents } from '@app/src/app/minimal/services/ContentEvents';
import { StreamManager } from '@app/src/app/minimal/services/StreamManager';
import { PermissionsService } from '@app/src/app/permissions';
import { ContentState } from '@app/src/app/services/ContentState';
import { ReportService } from '@app/src/app/services/Report';

import { SystemAlertSnackBarService } from '@shared/components/system-alert-snackbar';

import { IReportContentFilter } from '../../types/minga-report-feed-base.types';
import { MingaReportFeedBaseClass } from '../../utils/minga-report-feed-base.utils';

@Component({
  selector: 'mg-moderation-posts',
  templateUrl: './moderation-posts.component.html',
  styleUrls: ['./moderation-posts.component.scss'],
})
export class ModerationPostsComponent
  extends MingaReportFeedBaseClass
  implements OnInit, OnDestroy
{
  // Clean up
  private readonly _destroyedSubject = new ReplaySubject<void>(1);

  /** Constants */
  public readonly SEARCH_FILTER_TYPE = mgUtil.ReportFilter;

  get feedName() {
    if (this.globalContent) {
      return `MingaGlobalModerationFeed`;
    } else {
      return `MingaModerationFeed`;
    }
  }

  /** Component Constructor */
  constructor(
    protected router: Router,
    protected reportService: ReportService,
    protected contentEvents: ContentEvents,
    protected systemAlertSnackBar: SystemAlertSnackBarService,
    protected route: ActivatedRoute,
    protected permissions: PermissionsService,
    protected contentState: ContentState,
    protected streamManager: StreamManager,
    protected media: MediaObserver,
    public adminModerationManager: gateway.moderation_ng_grpc_pb.AdminModerationManager,
  ) {
    super(
      router,
      reportService,
      contentEvents,
      systemAlertSnackBar,
      route,
      permissions,
      contentState,
      streamManager,
      media,
    );
    this.defaultNoResultText = `moderated content`;
  }

  ngOnInit() {
    this.initSetup();
  }

  ngOnDestroy(): void {
    this._destroyedSubject.next();
    this._destroyedSubject.complete();
    this.unsubscribeSubscriptions();
  }

  async initTabs(resetToFirst: boolean = true) {
    this.primaryStatFilters = [
      {
        title: 'All',
        countText: null,
        total: 0,
        cached: true,
        primary: true,
        filterActive: true,
        searchFilter: mgUtil.ReportFilter.ALL,
        filterLinks: [
          {
            text: 'moderated content',
          },
        ],
      },
      {
        title: 'Published',
        countText: this._getTabStatText(mgUtil.ReportFilter.PUBLISHED),
        total: 0,
        cached: true,
        primary: true,
        searchFilter: mgUtil.ReportFilter.PUBLISHED,
        filterLinks: [
          {
            text: 'published content',
          },
        ],
      },
      {
        title: 'Deleted',
        countText: this._getTabStatText(mgUtil.ReportFilter.DELETED),
        total: 0,
        cached: true,
        primary: true,
        searchFilter: mgUtil.ReportFilter.DELETED,
        filterLinks: [
          {
            text: 'deleted or unpublished content',
          },
        ],
      },
    ];

    if (resetToFirst) {
      this.resetToFirstFilter();
    }
  }

  updateContentFilter(moderationFilter: mgUtil.ReportFilter) {
    const filter: IReportContentFilter = {
      moderationFilter,
      global: this.globalContent,
    };
    this.contentFilter = filter;
  }

  private _getTabStatText(reportStat: mgUtil.ReportFilter) {
    return this.getTabStatText(reportStat, true);
  }
}
