import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  forwardRef,
  Input,
  OnInit,
} from '@angular/core';
import {
  AbstractControl,
  ControlContainer,
  ControlValueAccessor,
  FormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  FormGroupDirective,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
  Validators,
} from '@angular/forms';

import { MgValidators } from '@app/src/app/input/validators';

export const MG_GROUP_NAME_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => MingaNameComponent),
  multi: true,
};

const MG_GROUP_NAME_VALIDATOR: any = {
  provide: NG_VALIDATORS,
  useExisting: forwardRef(() => MingaNameComponent),
  multi: true,
};

/**
 * Slot component for changing `<mg-minga-name>`'s `<mat-label>`
 */
@Component({
  selector: 'mg-minga-name-label',
  template: '<ng-content></ng-content>',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MingaNameSlot_Label {}

/**
 * Slot component for changing `<mg-minga-name>`'s `<mat-hint>`
 */
@Component({
  selector: 'mg-minga-name-hint',
  template: '<ng-content></ng-content>',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MingaNameSlot_Hint {}

@Component({
  selector: 'mg-minga-name',
  providers: [MG_GROUP_NAME_VALUE_ACCESSOR, MG_GROUP_NAME_VALIDATOR],
  templateUrl: './MingaName.component.html',
  styleUrls: ['./MingaName.component.scss'],
})
export class MingaNameComponent
  implements ControlValueAccessor, Validator, OnInit
{
  @ContentChild(MingaNameSlot_Label, { static: false })
  labelSlot?: MingaNameSlot_Label;

  @ContentChild(MingaNameSlot_Hint, { static: false })
  hintSlot?: MingaNameSlot_Hint;

  value: string = '';

  @Input()
  disabled = false;

  @Input()
  outerFormControl: UntypedFormControl;

  @Input()
  required: boolean = false;

  onChange;
  onTouched;

  _formGroup: UntypedFormGroup;
  inputControl: UntypedFormControl;

  constructor() {}

  // Pass through our internal
  validate(control: AbstractControl): ValidationErrors | null {
    return this.inputControl.errors;
  }

  ngOnInit() {
    this.inputControl = new UntypedFormControl(
      { value: this.value, disabled: this.disabled },
      [Validators.required, Validators.maxLength(70), MgValidators.NoSymbols],
    );

    this._formGroup = new UntypedFormGroup({ inputControl: this.inputControl });
  }

  get inputControlErrorKey() {
    if (!this.inputControl.errors) {
      return '';
    }

    return Object.keys(this.inputControl.errors)[0];
  }

  writeValue(value: any): void {
    // null is allowed as a way to clear value
    if (value === null || typeof value == 'undefined') {
      value = '';
    }

    if (typeof value == 'string') {
      this.value = value;
      this.inputControl.setValue(this.value);
    } else {
      console.warn('<mg-minga-name> model tired to change to non-string value');
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  change(value) {
    if (this.onChange) {
      this.onChange(value);
      this.onTouched();
    }

    // update internal value
    this.value = value;
  }
}
