import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
  OnInit,
} from '@angular/core';
import { Router } from '@angular/router';

import * as day from 'dayjs';
import * as relativeTime from 'dayjs/plugin/relativeTime';
import { gateway } from 'libs/generated-grpc-web';

import { LikeService } from '@app/src/app/minimal/services/Like';
import { ContentState } from '@app/src/app/services/ContentState';

day.extend(relativeTime);

@Component({
  selector: 'mg-feed-comment-view',
  templateUrl: './FeedCommentView.component.html',
  styleUrls: ['./FeedCommentView.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FeedCommentViewComponent implements OnInit {
  @Input()
  comment?: gateway.content_views_pb.FeedComment.AsObject;

  @Input()
  contentContext = '';

  @Input()
  disableActions = false;

  @HostBinding('class.light-content')
  @Input()
  lightContent = false;

  get commentBody(): string {
    if (this.comment) {
      return this.comment.body;
    }

    return '';
  }

  get commentBodyFirstLine(): string {
    const body = this.commentBody;
    const div = document.createElement('div');
    div.innerHTML = body;
    const firstChild = div.firstChild;

    if (firstChild) {
      return firstChild.textContent || '';
    }

    return '';
  }

  get commentBodyOtherLines(): string {
    const body = this.commentBody;
    const div = document.createElement('div');
    div.innerHTML = body;
    const firstChild = div.firstChild;

    if (firstChild) {
      div.removeChild(firstChild);
    }

    return div.innerHTML;
  }

  constructor(
    private router: Router,
    private contentState: ContentState,
    public likeservice: LikeService,
  ) {}

  ngOnInit(): void {
    if (this.comment.hasLiked) {
      this.likeservice.setLike(this.comment.commentContextHash);
    }
  }

  humanize(timestamp: any) {
    if (typeof timestamp == 'string') {
      timestamp = parseInt(timestamp);
    }

    return day(timestamp).fromNow();
  }

  commentReplyActionClick(ev: MouseEvent) {
    if (!this.comment) return;

    ev.preventDefault();
    ev.stopPropagation();
    ev.stopImmediatePropagation();

    const contentContext = this.contentContext;
    const commentContext = this.comment.commentContextHash;

    this.contentState.wants(contentContext, 'commentReplyInputFocus');
    this.contentState.wants(commentContext, 'commentInputFocus');
    this.router.navigate(['', { outlets: { o: ['view', contentContext] } }], {
      fragment: 'comments',
    });
  }
}
