import { createFeatureSelector, createSelector } from '@ngrx/store';

import { authStoreKeyname, AuthStoreState } from '../state';

export const selectAuthStore =
  createFeatureSelector<AuthStoreState>(authStoreKeyname);

export const selectPreAuthUrl = createSelector(
  selectAuthStore,
  state => state.preAuthUrl,
);

export const selectAuthenticated = createSelector(
  selectAuthStore,
  state => state.authenticated,
);

export const selectAuthPerson = createSelector(
  selectAuthStore,
  state => state.authPerson,
);
export const selectAuthTokenPayload = createSelector(
  selectAuthStore,
  state => state.authTokenPayload,
);

export const selectAuthResponse = createSelector(
  selectAuthStore,
  state => state.authResponse,
);

export const selectDetachedSsoProviderInfo = createSelector(
  selectAuthStore,
  state => state.detachedSsoProviderInfo,
);

export const selectLinkedSsoProviderInfo = createSelector(
  selectAuthStore,
  state => state.linkedSsoProviderInfo,
);

export const selectSsoError = createSelector(
  selectAuthStore,
  state => state.ssoError,
);
