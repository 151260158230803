import { FlexActivityStats } from 'libs/domain';
import { flex_time_stats_pb as flex_stats_pb } from 'libs/generated-grpc-ts';

export const toProto = (item: FlexActivityStats) => {
  const msg = new flex_stats_pb.FlexActivityStats();
  msg.setName(item.name);
  msg.setRegistered(item.registered);
  msg.setAssigned(item.assigned);
  msg.setCheckIns(item.checkIns);
  msg.setTotalTime(item.totalTime);
  msg.setActivityId(item.activityId);
  return msg;
};

export const fromProto = (
  msg: flex_stats_pb.FlexActivityStats,
): FlexActivityStats => ({
  activityId: msg.getActivityId(),
  name: msg.getName(),
  registered: msg.getRegistered(),
  assigned: msg.getAssigned(),
  checkIns: msg.getCheckIns(),
  totalTime: msg.getTotalTime(),
});
