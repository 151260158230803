import {
  ChangeDetectionStrategy,
  Component,
  HostListener,
  Input,
  ViewEncapsulation,
} from '@angular/core';
import { Router } from '@angular/router';

import { MgAnyShortCardElement } from '@app/src/app/elements/MgAnyShortCard';
import {
  IShortCardChallengeClickEvent,
  IShortCardChallengeResponseClickEvent,
} from '@app/src/app/elements/MgShortCard/MgChallengeShortCard';
import { ContentState } from '@app/src/app/services/ContentState';

export type MgContentFeedItemDisplay = '' | 'shortCard' | 'miniCard';

export type MgContentFeedItemContentType = any;

export interface IMgContentFeedItemElementProperties {
  context: string;
  content: MgContentFeedItemContentType | null;
  markKeywords: string;
  display: MgContentFeedItemDisplay;
}

@Component({
  selector: 'mg-content-feed-item',
  templateUrl: './MgContentFeedItem.element.html',
  styleUrls: ['./MgContentFeedItem.element.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MgContentFeedItemElement
  implements IMgContentFeedItemElementProperties
{
  @Input()
  context: string = '';

  @Input()
  content: MgContentFeedItemContentType | null = null;

  @Input()
  markKeywords: string = '';

  @Input()
  display: MgContentFeedItemDisplay = '';

  constructor(private router: Router, private contentState: ContentState) {}

  get isPinned(): boolean {
    if (this.content) {
      return this.content.pinned;
    }

    return false;
  }

  get shouldDisplayShortCard(): boolean {
    return !this.display || this.display === 'shortCard';
  }

  get shouldDisplayMiniCard(): boolean {
    return this.display === 'miniCard';
  }

  getContentLinkContext(context: string) {
    if (this.content) {
      // Returning an empty string disables the contentLink directive
      if (this.content.shortSmsMessageCard) return '';
      if (this.content.shortAnnoucementCard) return '';
    }
    return context;
  }

  @HostListener('click', ['$event'])
  _onClick(ev: MouseEvent) {}

  onChallengeClick(ev: IShortCardChallengeClickEvent) {
    this.router.navigate([
      '',
      { outlets: { o: ['view', ev.challengeContextHash] } },
    ]);
  }

  onChallengeResponseClick(ev: IShortCardChallengeResponseClickEvent) {
    this.contentState.setStateValue(
      ev.challengeContextHash,
      'responseContextHash',
      ev.responseContextHash,
    );

    this.router.navigate([
      '',
      { outlets: { o: ['view', ev.challengeContextHash] } },
    ]);
  }
}
