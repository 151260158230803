import { Component, Input, OnInit, ViewChild } from '@angular/core';

import { gateway } from 'libs/generated-grpc-web';

import { IOverlayConfig } from '@app/src/app/misc/overlay';
import { Color } from '@app/src/app/util/color';

import { LongCardBaseComponent } from '../LongCardBase/LongCardBase.component';
import { LongCardBaseClass } from '../LongCardBase/LongCardBaseClass';

@Component({
  selector: 'mg-inspiration-long-card',
  templateUrl: './InspirationLongCard.component.html',
  styleUrls: ['./InspirationLongCard.component.scss'],
})
export class InspirationLongCardComponent
  extends LongCardBaseClass
  implements OnInit
{
  @Input() showTopNav = true;

  @ViewChild('longCardBase', { static: true })
  longCardBase: LongCardBaseComponent;

  private onOverlayConfig?: (config: IOverlayConfig) => void;

  content?: gateway.content_views_pb.LongInspirationCardView.AsObject;

  ngOnInit() {
    if (this.onOverlayConfig) {
      this.onOverlayConfig({
        mobileTransparentNav: true,
        background: this.content.backgroundColor,
      });
    } else {
      console.warn(
        'InspirationLongCard does not have onOverlayConfig. Background color will not represent style of card.',
      );
    }
  }

  mgCheckStyleChanges() {
    this.longCardBase.mgCheckStyleChanges();
  }

  getImagePaddingBottom() {
    const image = this.content.image;
    if (!image) {
      return;
    }

    const { width, height } = image.sizeMap[0][1];

    return (height / width) * 100 + '%';
  }

  getInspImageUrl() {
    return this.getImageUrl(this.content.image, [
      'longcardbanner',
      'cardbannerfree',
    ]);
  }

  registerOnOverlayConfig(fn: (config: IOverlayConfig) => void) {
    this.onOverlayConfig = fn;
  }

  bannerGradientStyle() {
    const bgColor = this.content.backgroundColor;
    if (!bgColor) {
      return '';
    }

    const bgColorObj = new Color(bgColor);
    const transBgColor = bgColorObj.alpha(0).toString();

    return `linear-gradient(${transBgColor}, ${bgColor})`;
  }

  bannerGradientSize(size: number) {
    const gradientSize = this.content.gradientSize || 0;
    return (gradientSize * size - 1) * -1 * 100 + '%';
  }
}
