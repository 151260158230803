// GENERATED CODE -- DO NOT EDIT!

import {grpc} from '@improbable-eng/grpc-web';

import * as gateway_minga_roles_pb from '../gateway/minga_roles_pb';

export class MingaRoles {
  static readonly serviceName = "minga.MingaRoles";

  static readonly SetFeedPermittableRole = {
    methodName: "SetFeedPermittableRole",
    service: MingaRoles,
    requestStream: false,
    responseStream: false,
    requestType: gateway_minga_roles_pb.SetFeedPermittableRoleRequest,
    responseType: gateway_minga_roles_pb.SetFeedPermittableRoleResponse,
  };

  static readonly SetJoinViaCodeRole = {
    methodName: "SetJoinViaCodeRole",
    service: MingaRoles,
    requestStream: false,
    responseStream: false,
    requestType: gateway_minga_roles_pb.SetJoinViaCodeRoleRequest,
    responseType: gateway_minga_roles_pb.SetJoinViaCodeResponse,
  };

  static readonly SetGalleryPermittableRole = {
    methodName: "SetGalleryPermittableRole",
    service: MingaRoles,
    requestStream: false,
    responseStream: false,
    requestType: gateway_minga_roles_pb.SetGalleryPermittableRoleRequest,
    responseType: gateway_minga_roles_pb.SetGalleryPermittableRoleResponse,
  };

  static readonly SetVideoUploadPermittableRole = {
    methodName: "SetVideoUploadPermittableRole",
    service: MingaRoles,
    requestStream: false,
    responseStream: false,
    requestType: gateway_minga_roles_pb.SetVideoUploadPermittableRoleRequest,
    responseType: gateway_minga_roles_pb.SetVideoUploadPermittableRoleResponse,
  };

  static readonly SetCommentPermittableRole = {
    methodName: "SetCommentPermittableRole",
    service: MingaRoles,
    requestStream: false,
    responseStream: false,
    requestType: gateway_minga_roles_pb.SetCommentPermittableRoleRequest,
    responseType: gateway_minga_roles_pb.SetCommentPermittableRoleResponse,
  };

  static readonly SetGroupCreatePermittableRole = {
    methodName: "SetGroupCreatePermittableRole",
    service: MingaRoles,
    requestStream: false,
    responseStream: false,
    requestType: gateway_minga_roles_pb.SetGroupCreatePermittableRoleRequest,
    responseType: gateway_minga_roles_pb.SetGroupCreatePermittableRoleResponse,
  };

  static readonly GetMingaRoleSettings = {
    methodName: "GetMingaRoleSettings",
    service: MingaRoles,
    requestStream: false,
    responseStream: false,
    requestType: gateway_minga_roles_pb.GetMingaRoleSettingsRequest,
    responseType: gateway_minga_roles_pb.GetMingaRoleSettingsResponse,
  };

}

export class MingaRolesClient {
  setFeedPermittableRole() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  setJoinViaCodeRole() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  setGalleryPermittableRole() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  setVideoUploadPermittableRole() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  setCommentPermittableRole() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  setGroupCreatePermittableRole() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  getMingaRoleSettings() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
}
