import { Injectable } from '@angular/core';

import { sms_ng_grpc_pb, sms_pb } from 'libs/generated-grpc-web';
import { ISmsRecipientDetails } from 'libs/shared';
import { MingaSmsDetailsMapper } from 'libs/shared-grpc';

@Injectable({ providedIn: 'root' })
export class SmsMinga {
  constructor(private smsMingaProto: sms_ng_grpc_pb.SmsMinga) {}

  async getDetails(): Promise<ISmsRecipientDetails> {
    const request = new sms_pb.SmsMingaGetDetailsRequest();
    const details = await this.smsMingaProto.getDetails(request);
    return MingaSmsDetailsMapper.toISmsRecipientDetails(details);
  }
}
