import { Injectable } from '@angular/core';

import * as _ from 'lodash';
import { gateway, stream_pb } from 'libs/generated-grpc-web';

import { ContentEvents } from '@app/src/app/minimal/services/ContentEvents';
import {
  IMgStreamFilter,
  IMgStreamItem,
  toStreamFilterMessage,
} from '@app/src/app/util/stream';

@Injectable({ providedIn: 'root' })
export class ContentFeedService {
  constructor(
    private _contentEvents: ContentEvents,
    private _contentFeedProto: gateway.feed_ng_grpc_pb.ContentFeed,
    private _notificationProto: gateway.notification_ng_grpc_pb.NotificationManager,
  ) {}

  feedItemClick(e: any) {
    const contentFeedItem: gateway.feed_pb.ContentFeedItemView.AsObject =
      e.model.item.data;

    // There should be a better way of prevening short card opens with these
    // types...
    if (
      contentFeedItem.shortPollCard ||
      contentFeedItem.shortAnnoucementCard ||
      contentFeedItem.shortFileCard ||
      contentFeedItem.shortChallengeCard ||
      contentFeedItem.shortSmsMessageCard
    ) {
      return;
    }

    let contextHash = _.get(contentFeedItem, 'contentContextHash.value', '');

    if (!contextHash) {
      contextHash = _.get(
        contentFeedItem,
        'feedItemView.contentContextHash.value',
        '',
      );
    }

    if (contextHash) {
      this._contentEvents.emitContentOpen({ contentContextHash: contextHash });
    } else {
      console.warn('Could not find context hash on feed item.');
    }
  }

  async getHomeFeed(
    limit: number,
    offset: number,
    filter: IMgStreamFilter,
  ): Promise<IMgStreamItem<gateway.feed_pb.ContentFeedItemView.AsObject>[]> {
    const request = new gateway.feed_pb.GetContentFeedRequest();
    request.setLimit(limit);
    request.setOffset(offset);
    request.setStreamFilter(toStreamFilterMessage(filter));

    const response = await this._contentFeedProto.getContentFeed(request);
    const items = response.getItemsList();

    return items.map(item => this._mapStreamItemResponse(item));
  }

  async getNotifications(limit: number, offset: number) {
    const request = new gateway.notification_pb.GetNotificationsRequest();
    request.setLimit(limit);
    request.setOffset(offset);

    const response = await this._notificationProto.getNotifications(request);
    const notifications = response.getNotificationsList();
    return notifications.map(item => this._mapStreamItemResponse(item, false));
  }

  _mapStreamItemResponse(res: any, toObject = true) {
    const item = toObject ? res.getItem().toObject() : res.getItem();
    const itemMetadata: stream_pb.StreamItemMetadata = res.getItemMetadata();
    const itemIndex = itemMetadata?.getIndex() || null;
    const itemId = itemMetadata?.getId() || null;

    return { item, itemIndex, itemId };
  }
}
