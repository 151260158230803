import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';

import { MingaPermission } from 'libs/domain';
import { first } from 'rxjs/operators';

import { PermissionsService } from '@app/src/app/permissions';

import { Group } from '../models/Group';
import { GroupsFacadeService } from '../services/';

@Injectable()
export class GroupTabGuard implements CanActivate {
  constructor(
    private groupsFacade: GroupsFacadeService,
    private router: Router,
    private permission: PermissionsService,
  ) {}

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    let groupHash;
    if (route.parent) {
      // group hash comes from the parent route and is no longer available on
      // child routes.
      groupHash = route.parent.paramMap.get('groupHash');
    }
    if (!groupHash) {
      return false;
    }

    const group = await this.groupsFacade
      .getGroup(groupHash)
      .pipe(first())
      .toPromise();
    const canViewAll = await this.permission.hasPermission(
      MingaPermission.CONTENT_GROUP_VIEWALL,
    );
    if (canViewAll) {
      return true;
    }

    if (group && Group.isMember(group)) {
      return true;
    }

    (() => {
      this.router.navigate(['/groups/view/' + groupHash + '/about']);
    })();
    return false;
  }
}
