import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';

import { PageModule } from '@app/src/app/page';

import { OverlayModule } from '@shared/components/modal-overlay';
import { CommonUIModules } from '@shared/modules';

import { AboutUsComponent } from './AboutUs.component';

@NgModule({
  imports: [
    // Minga dependencies
    CommonUIModules,
    OverlayModule,
    CommonUIModules,
    PageModule,

    // External dependencies
    CommonModule,
    MatIconModule,
    MatExpansionModule,
    MatListModule,
  ],
  declarations: [AboutUsComponent],
  exports: [AboutUsComponent],
})
export class AboutUsModule {}
