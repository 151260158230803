import { InjectionToken } from '@angular/core';

import { Observable } from 'rxjs';

import { Group } from '@app/src/app/groups/models';

export interface IRouterDerivedStateGroupFetcher {
  getGroup(groupHash: string): Observable<Group>;
}

export const ROUTER_DERIVED_STATE_GROUP_FETCHER =
  new InjectionToken<IRouterDerivedStateGroupFetcher>('GroupFetcher');
