import { MingaPermission, MingaRoleType } from 'libs/domain';
import { DisplayNameFormat, RoleFields, mingaSettingTypes } from 'libs/util';

import { IUserRole } from '../interface';

export enum RoleCategory {
  STAFF = 'Staff',
  STUDENT = 'Student',
  PARENT = 'Parent',
}

export abstract class UserRole implements IUserRole {
  abstract readonly name: string;
  abstract readonly roleType: MingaRoleType;
  abstract readonly displayNameFormat: DisplayNameFormat;
  abstract readonly iconUrl: string;
  abstract readonly iconColor: string;
  abstract readonly permissions: ReadonlyArray<MingaPermission>;
  abstract readonly overridablePermissions: ReadonlyArray<MingaPermission>;
  abstract readonly capabilitiesDescription: string;
  abstract readonly feedPermittable: boolean;
  abstract readonly galleryPermittable: boolean;
  readonly roleFields: RoleFields = RoleFields.DEFAULT;
  abstract readonly groupFeedPermittable: boolean;
  abstract readonly programManagePermittable: boolean;
  abstract readonly videoUploadPermittable: boolean;
  abstract readonly admin: boolean;
  abstract readonly commentPermittable: boolean;
  abstract readonly groupCreatePermittable: boolean;
  abstract readonly requiredFeatures: mingaSettingTypes[];
  abstract readonly sendToMailChimp: boolean;
  readonly IDRole: string = RoleCategory.STAFF;

  hasPermission(permission: MingaPermission): boolean {
    return this.permissions.findIndex(row => row === permission) !== -1;
  }

  hasOverridablePermission(perm: MingaPermission): boolean {
    return this.overridablePermissions.findIndex(row => row === perm) !== -1;
  }

  equals(role: UserRole): boolean {
    return role.roleType === this.roleType;
  }
}
