import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';

import { IMembershipList, MembershipListType } from 'libs/domain';
import { MingaPermission } from 'libs/domain';
import {
  CreateUserListPayload,
  DEFAULT_USER_LIST,
  UpdateUserListPayload,
  UserList,
} from 'libs/domain';
import { BehaviorSubject, ReplaySubject, combineLatest } from 'rxjs';
import { distinctUntilChanged, map, take, takeUntil } from 'rxjs/operators';

import { AuthInfoService } from '@app/src/app/minimal/services/AuthInfo';
import { PermissionsService } from '@app/src/app/permissions';
import { ListMembershipService } from '@app/src/app/services/ListMembership';

import { MembershipListTableLists } from '@shared/components/membership-list-table';
import {
  MODAL_OVERLAY_DATA,
  ModalOverlayPrimaryHeaderBackground,
  ModalOverlayRef,
  ModalOverlayServiceCloseEventType,
} from '@shared/components/modal-overlay';
import { SystemAlertSnackBarService } from '@shared/components/system-alert-snackbar';
import { UserListFilterService } from '@shared/components/user-list-filter/services/user-list-filter.service';
import { UserListService } from '@shared/services/user-list';

import {
  PEOPLE_USER_LISTS_EDIT_FORM_GROUP,
  PeopleUserListsEditFormFields,
  PeopleUserListsEditMessages,
} from '../../constants';
import { PeopleUserListsPageStore } from '../../services';
import {
  PeopleUserListsEditModalData,
  PeopleUserListsEditModalResponse,
} from '../../types';

@Component({
  selector: 'mg-people-userlists-edit',
  templateUrl: './people-userlists-edit.component.html',
  styleUrls: ['./people-userlists-edit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PeopleUserListsEditComponent implements OnInit, OnDestroy {
  public readonly ulStore: PeopleUserListsPageStore = this.data.injector.get(
    PeopleUserListsPageStore,
  );

  /** General subjects */
  private readonly _destroyedSubject = new ReplaySubject<void>(1);

  /** Observables */
  private _isManagerSubject = new BehaviorSubject<boolean>(false);
  public readonly isManager$ = this._isManagerSubject.asObservable();

  private _isCreatorSubject = new BehaviorSubject<boolean>(false);
  public readonly isCreator$ = this._isCreatorSubject.asObservable();

  private _isAdminSubject = new BehaviorSubject<boolean>(false);
  public readonly isAdmin$ = this._isAdminSubject.asObservable();

  public readonly shouldDisabletoggle$ = combineLatest([
    this.isManager$,
    this.isCreator$,
    this.isAdmin$,
  ]).pipe(
    takeUntil(this._destroyedSubject),
    map(
      ([isManager, isCreator, isAdmin]) => !(isAdmin || isCreator) && isManager,
    ),
  );

  /** Constants */
  public readonly MSG = PeopleUserListsEditMessages;
  public readonly FORM_FIELD = PeopleUserListsEditFormFields;

  /** Modal config */
  public readonly MODAL_CONFIG = {
    headerBg: ModalOverlayPrimaryHeaderBackground.PEOPLE_BLUE,
  };
  public readonly modalTitle$ = this.ulStore.isCreatingNewList$.pipe(
    takeUntil(this._destroyedSubject),
    map(isCreating =>
      isCreating ? this.MSG.TITLE_CREATE : this.MSG.TITLE_EDIT,
    ),
  );

  /** Form Controls */
  public readonly form = this._fb.group(PEOPLE_USER_LISTS_EDIT_FORM_GROUP);

  /** Computed Getters */
  get lists(): MembershipListTableLists[] {
    const managerListId = this.form.get(this.FORM_FIELD.MANAGER_LIST_ID)?.value;
    return this.data.id
      ? managerListId
        ? [{ id: managerListId }, { id: this.data.id }]
        : [{ type: MembershipListType.USER_LIST_MANAGER }, { id: this.data.id }]
      : [
          { type: MembershipListType.USER_LIST_MANAGER },
          { type: MembershipListType.USER_LIST },
        ];
  }

  /** Is submitting/saving */
  public readonly isSubmitting$ = combineLatest([
    this.ulStore.isCreatingNewList$,
    this.ulStore.isLoading$,
  ]).pipe(
    takeUntil(this._destroyedSubject),
    map(([isCreatingNewList, { create, update }]) =>
      isCreatingNewList ? create : update,
    ),
  );

  /** Subscriptions */
  private _isCreatingNewListSubscription = this._handleIsCreatingStream();
  private _activeListSubscription = this._handleActiveListStream();

  /** Misc */
  private _userListId = new BehaviorSubject<number>(undefined);
  private _isNew = true;
  private _triggerManagerNotice = true;

  constructor(
    @Inject(MODAL_OVERLAY_DATA)
    public data: PeopleUserListsEditModalData,
    private _listMembershipService: ListMembershipService,
    private _authInfoService: AuthInfoService,
    private _permissionsService: PermissionsService,
    private _ulService: UserListService,
    private _fb: UntypedFormBuilder,
    private _cdr: ChangeDetectorRef,
    private _modalRef: ModalOverlayRef<
      PeopleUserListsEditModalResponse,
      PeopleUserListsEditModalData
    >,
    private _userListFilterService: UserListFilterService,
    private _snackBar: SystemAlertSnackBarService,
  ) {}

  async ngOnInit(): Promise<void> {
    const personHash = this._authInfoService.authPersonHash;
    const managerListId = this.form.get(this.FORM_FIELD.MANAGER_LIST_ID).value;
    if (managerListId) {
      const list = await this._listMembershipService.getMembershipList(
        managerListId,
        true,
      );
      if (list.members.includes(personHash)) this._isManagerSubject.next(true);
    }

    if (
      this._permissionsService.hasPermission(MingaPermission.USER_LIST_ADMIN)
    ) {
      this._isAdminSubject.next(true);
    }

    const listId = this.data.id;
    if (listId) {
      this._isNew = false;
      const list = await this._listMembershipService.getMembershipList(
        listId,
        true,
      );
      if (list.creator?.hash === personHash) this._isCreatorSubject.next(true);
    }
  }

  ngOnDestroy(): void {
    this._isManagerSubject.complete();
    this._isAdminSubject.complete();
    this._isCreatorSubject.complete();
    this._destroyedSubject.next();
    this._destroyedSubject.complete();
    this._activeListSubscription.unsubscribe();
    this._isCreatingNewListSubscription.unsubscribe();
  }

  public onMembershipListChange(list: IMembershipList) {
    if (list.id && list.listType === MembershipListType.USER_LIST_MANAGER) {
      this.form.controls[this.FORM_FIELD.MANAGER_LIST_ID].setValue(list.id);
      if (this._triggerManagerNotice) {
        this._snackBar.open({
          type: 'warning',
          message: 'Managers added, please save changes!',
        });
      }
    }
    if (list.id && list.listType === MembershipListType.USER_LIST) {
      this._userListId.next(list.id);
    }
  }

  public async close(): Promise<void> {
    const isCreatingNewList = await this.ulStore.isCreatingNewList$
      .pipe(take(1))
      .toPromise();

    const listId = this.data.id || this._userListId.value;

    if (isCreatingNewList) {
      if (listId) await this._ulService.delete(listId);
      this._modalRef.close(ModalOverlayServiceCloseEventType.CLOSE);
    } else {
      this.ulStore.deleteList(() =>
        this._modalRef.close(ModalOverlayServiceCloseEventType.DELETE),
      );
    }
  }

  public async submit(): Promise<void> {
    if (!this._validate()) return;
    if (!this.data.id && !this._userListId.value) {
      this.ulStore.createList({
        payload: this._getCreatePayload(),
        afterSuccess: userList =>
          this._modalRef.close(ModalOverlayServiceCloseEventType.CREATE, {
            userList,
          }),
      });
    } else {
      this.ulStore.updateList({
        changes: this._getUpdatePayload(),
        afterSuccess: userList =>
          this._modalRef.close(ModalOverlayServiceCloseEventType.SUBMIT, {
            userList,
          }),
      });
    }
    this._userListFilterService.clearCache();
  }

  public _validate(): boolean {
    if (!this.form.valid) {
      for (const field in this.form.controls) {
        if (this.form.controls.hasOwnProperty(field)) {
          this.form.controls[field].markAsTouched();
        }
      }
      return false;
    }
    return true;
  }

  private _handleActiveListStream() {
    return this.ulStore.activeList$
      .pipe(takeUntil(this._destroyedSubject), distinctUntilChanged())
      .subscribe(list => {
        if (list) this._setFormValues(list);
        this._cdr.markForCheck();
      });
  }

  private _handleIsCreatingStream() {
    return this.ulStore.isCreatingNewList$
      .pipe(takeUntil(this._destroyedSubject))
      .subscribe(isCreatingNewList => {
        if (isCreatingNewList) return;
        this.form.addControl(
          PeopleUserListsEditFormFields.UUID,
          this._fb.control('', [Validators.required]),
        );
        this.form.addControl(
          PeopleUserListsEditFormFields.OWNER,
          this._fb.control('', [Validators.required]),
        );
        this._cdr.markForCheck();
      });
  }

  private _setFormValues(list: UserList) {
    for (const key in list) {
      if (list.hasOwnProperty(key) && this.form.controls[key]) {
        this.form.get(key).setValue(list[key]);
      }
    }
    if (this.form.get(this.FORM_FIELD.MANAGER_LIST_ID).value) {
      this._triggerManagerNotice = false;
    }

    if (this.form.get(this.FORM_FIELD.OWNER)) {
      this.form
        .get(this.FORM_FIELD.OWNER)
        .setValue(`${list.creator?.firstName} ${list.creator?.lastName}`);
    }
    this.form.markAsPristine();
    this.form.markAsUntouched();
  }

  // user list is created without members
  private _getCreatePayload(): CreateUserListPayload {
    const payload: CreateUserListPayload = { ...DEFAULT_USER_LIST };
    for (const field in this.form.controls) {
      if (this.form.controls.hasOwnProperty(field)) {
        payload[field] = this.form.controls[field].value;
        if (typeof payload[field] === 'string') {
          payload[field] = payload[field].trim();
        }
      }
    }
    return payload;
  }

  // user list was already created when members were added
  private _getUpdatePayload(): UpdateUserListPayload {
    const payload = {} as UpdateUserListPayload;
    for (const field in this.form.controls) {
      if (this.form.controls.hasOwnProperty(field)) {
        payload[field] = this.form.controls[field].value;
        if (typeof payload[field] === 'string') {
          payload[field] = payload[field].trim();
        }
      }
    }
    if (this._userListId.value) {
      payload.id = this._userListId.value;
    }
    if (this._isNew) {
      payload.active = true;
    }
    return payload;
  }
}
