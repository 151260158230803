import { NgModule } from '@angular/core';

import { SaveCancelDialogModule } from '@app/src/app/dialog/SaveCancel';

import { GroupStateService } from './GroupState.service';
import { GroupsService } from './Groups.service';

@NgModule({
  imports: [SaveCancelDialogModule],
  providers: [GroupsService, GroupStateService],
})
export class GroupServicesModule {}
