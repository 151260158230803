// GENERATED CODE -- DO NOT EDIT!

import {grpc} from '@improbable-eng/grpc-web';

import * as membership_list_membership_list_pb from '../membership_list/membership_list_pb';
import * as image_image_pb from '../image/image_pb';
import * as gateway_person_view_pb from '../gateway/person_view_pb';

export class MembershipListManager {
  static readonly serviceName = "membership_list.MembershipListManager";

  static readonly UpdateMembershipList = {
    methodName: "UpdateMembershipList",
    service: MembershipListManager,
    requestStream: false,
    responseStream: false,
    requestType: membership_list_membership_list_pb.UpdateMembershipListRequest,
    responseType: membership_list_membership_list_pb.UpdateMembershipListResponse,
  };

  static readonly DeleteMembershipList = {
    methodName: "DeleteMembershipList",
    service: MembershipListManager,
    requestStream: false,
    responseStream: false,
    requestType: membership_list_membership_list_pb.DeleteMembershipListRequest,
    responseType: membership_list_membership_list_pb.DeleteMembershipListResponse,
  };

  static readonly GetMembershipLists = {
    methodName: "GetMembershipLists",
    service: MembershipListManager,
    requestStream: false,
    responseStream: false,
    requestType: membership_list_membership_list_pb.GetMembershipListsRequest,
    responseType: membership_list_membership_list_pb.GetMembershipListsResponse,
  };

  static readonly GetMembershipList = {
    methodName: "GetMembershipList",
    service: MembershipListManager,
    requestStream: false,
    responseStream: false,
    requestType: membership_list_membership_list_pb.GetMembershipListRequest,
    responseType: membership_list_membership_list_pb.GetMembershipListResponse,
  };

  static readonly AddMembersToMembershipList = {
    methodName: "AddMembersToMembershipList",
    service: MembershipListManager,
    requestStream: false,
    responseStream: false,
    requestType: membership_list_membership_list_pb.AddMembersToMembershipListRequest,
    responseType: membership_list_membership_list_pb.AddMembersToMembershipListResponse,
  };

  static readonly RemoveMembersFromMembershipList = {
    methodName: "RemoveMembersFromMembershipList",
    service: MembershipListManager,
    requestStream: false,
    responseStream: false,
    requestType: membership_list_membership_list_pb.RemoveMembersToMembershipListRequest,
    responseType: membership_list_membership_list_pb.RemoveMembersToMembershipListResponse,
  };

  static readonly GetMembershipListMembers = {
    methodName: "GetMembershipListMembers",
    service: MembershipListManager,
    requestStream: false,
    responseStream: false,
    requestType: membership_list_membership_list_pb.GetMembershipListMembersRequest,
    responseType: membership_list_membership_list_pb.GetMembershipListMembersResponse,
  };

  static readonly GetMembershipsForPerson = {
    methodName: "GetMembershipsForPerson",
    service: MembershipListManager,
    requestStream: false,
    responseStream: false,
    requestType: membership_list_membership_list_pb.GetMembershipsForPersonRequest,
    responseType: membership_list_membership_list_pb.GetMembershipsForPersonResponse,
  };

  static readonly RemoveAllMembersFromList = {
    methodName: "RemoveAllMembersFromList",
    service: MembershipListManager,
    requestStream: false,
    responseStream: false,
    requestType: membership_list_membership_list_pb.RemoveAllMembersFromListRequest,
    responseType: membership_list_membership_list_pb.RemoveAllMembersFromListResponse,
  };

  static readonly ExportMembershipList = {
    methodName: "ExportMembershipList",
    service: MembershipListManager,
    requestStream: false,
    responseStream: true,
    requestType: membership_list_membership_list_pb.ExportMembershipListRequest,
    responseType: membership_list_membership_list_pb.ExportMembershipListChunk,
  };

}

export class MembershipListManagerClient {
  updateMembershipList() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  deleteMembershipList() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  getMembershipLists() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  getMembershipList() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  addMembersToMembershipList() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  removeMembersFromMembershipList() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  getMembershipListMembers() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  getMembershipsForPerson() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  removeAllMembersFromList() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  exportMembershipList() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
}
