// source: bell_schedule/bell_schedule.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

import * as jspb from 'google-protobuf';
import * as period_period_pb from '../period/period_pb';
import * as common_date_pb from '../common/date_pb';
export namespace Term {
  export interface AsObject {
    id: number;
    title: string;
  }
}
export class Term extends jspb.Message {
  static readonly displayName = "Term";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): Term.AsObject {
    return Term.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!Term} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: Term) {
    var f: any;
    var obj: any = {
      id: jspb.Message.getFieldWithDefault(msg, 1, 0),
      title: jspb.Message.getFieldWithDefault(msg, 2, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!Term}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new Term;
    return Term.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!Term} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!Term}
   */
  static deserializeBinaryFromReader(msg: Term, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {number} */ (reader.readInt32());
        msg.setId(value1);
        break;
      case 2:
        var value2 = /** @type {string} */ (reader.readString());
        msg.setTitle(value2);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!Term} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: Term, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getId();
    if (f !== 0) {
      writer.writeInt32(
        1,
        f
      );
    }
    f = message.getTitle();
    if (f.length > 0) {
      writer.writeString(
        2,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    Term.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional int32 id = 1;
   * @return {number}
   */
  getId(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
  };


  /** @param {number} value */
  setId(value: number) {
    jspb.Message.setProto3IntField(this, 1, value);
  }


  /**
   * optional string title = 2;
   * @return {string}
   */
  getTitle(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
  };


  /** @param {string} value */
  setTitle(value: string) {
    jspb.Message.setProto3StringField(this, 2, value);
  }


}
export namespace BellSchedule {
  export interface AsObject {
    id: number;
    name: string;
    periodsList: period_period_pb.Period.AsObject[];
    daysList: string[];
    termIdsList: number[];
    mingaId: number;
  }
}
export class BellSchedule extends jspb.Message {
  static readonly displayName = "BellSchedule";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [3,4,5];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, BellSchedule.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): BellSchedule.AsObject {
    return BellSchedule.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!BellSchedule} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: BellSchedule) {
    var f: any;
    var obj: any = {
      id: jspb.Message.getFieldWithDefault(msg, 1, 0),
      name: jspb.Message.getFieldWithDefault(msg, 2, ""),
      periodsList: jspb.Message.toObjectList(msg.getPeriodsList(),
      period_period_pb.Period.toObject, includeInstance),
      daysList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
      termIdsList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f,
      mingaId: jspb.Message.getFieldWithDefault(msg, 6, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!BellSchedule}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new BellSchedule;
    return BellSchedule.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!BellSchedule} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!BellSchedule}
   */
  static deserializeBinaryFromReader(msg: BellSchedule, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {number} */ (reader.readInt32());
        msg.setId(value1);
        break;
      case 2:
        var value2 = /** @type {string} */ (reader.readString());
        msg.setName(value2);
        break;
      case 3:
        var value3 = new period_period_pb.Period;
        reader.readMessage(value3,period_period_pb.Period.deserializeBinaryFromReader);
        msg.addPeriods(value3);
        break;
      case 4:
        var value4 = /** @type {string} */ (reader.readString());
        msg.addDays(value4);
        break;
      case 5:
        var value5 = /** @type {!Array<number>} */ (reader.readPackedInt32());
        msg.setTermIdsList(value5);
        break;
      case 6:
        var value6 = /** @type {number} */ (reader.readInt32());
        msg.setMingaId(value6);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!BellSchedule} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: BellSchedule, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getId();
    if (f !== 0) {
      writer.writeInt32(
        1,
        f
      );
    }
    f = message.getName();
    if (f.length > 0) {
      writer.writeString(
        2,
        f
      );
    }
    f = message.getPeriodsList();
    if (f.length > 0) {
      writer.writeRepeatedMessage(
        3,
        f,
        period_period_pb.Period.serializeBinaryToWriter
      );
    }
    f = message.getDaysList();
    if (f.length > 0) {
      writer.writeRepeatedString(
        4,
        f
      );
    }
    f = message.getTermIdsList();
    if (f.length > 0) {
      writer.writePackedInt32(
        5,
        f
      );
    }
    f = message.getMingaId();
    if (f !== 0) {
      writer.writeInt32(
        6,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    BellSchedule.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional int32 id = 1;
   * @return {number}
   */
  getId(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
  };


  /** @param {number} value */
  setId(value: number) {
    jspb.Message.setProto3IntField(this, 1, value);
  }


  /**
   * optional string name = 2;
   * @return {string}
   */
  getName(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
  };


  /** @param {string} value */
  setName(value: string) {
    jspb.Message.setProto3StringField(this, 2, value);
  }


  /**
   * repeated period.Period periods = 3;
   * @return {!Array<!Period>}
   */
  getPeriodsList(): period_period_pb.Period[] {
    return /** @type{!Array<!Period>} */ (
      jspb.Message.getRepeatedWrapperField(this, period_period_pb.Period, 3));
  }


  /** @param {!Array<!Period>} value */
  setPeriodsList(value?: period_period_pb.Period[]) {
    jspb.Message.setRepeatedWrapperField(this, 3, value);
  }


  /**
   * @param {!Period=} opt_value
   * @param {number=} opt_index
   * @return {!Period}
   */
  addPeriods(opt_value?: period_period_pb.Period, opt_index?: number): period_period_pb.Period {
    return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, period_period_pb.Period, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearPeriodsList() {
    this.setPeriodsList([]);
  }


  /**
   * repeated string days = 4;
   * @return {!Array<string>}
   */
  getDaysList(): string[] {
    return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 4));
  };


  /** @param {!Array<string>} value */
  setDaysList(value: string[]) {
    jspb.Message.setField(this, 4, value || []);
  }


  /**
   * @param {string} value
   * @param {number=} opt_index
   */
  addDays(value: string, opt_index?: number): void {
    jspb.Message.addToRepeatedField(this, 4, value, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearDaysList() {
    this.setDaysList([]);
  }


  /**
   * repeated int32 term_ids = 5;
   * @return {!Array<number>}
   */
  getTermIdsList(): number[] {
    return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 5));
  };


  /** @param {!Array<number>} value */
  setTermIdsList(value: number[]) {
    jspb.Message.setField(this, 5, value || []);
  }


  /**
   * @param {number} value
   * @param {number=} opt_index
   */
  addTermIds(value: number, opt_index?: number): void {
    jspb.Message.addToRepeatedField(this, 5, value, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearTermIdsList() {
    this.setTermIdsList([]);
  }


  /**
   * optional int32 minga_id = 6;
   * @return {number}
   */
  getMingaId(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
  };


  /** @param {number} value */
  setMingaId(value: number) {
    jspb.Message.setProto3IntField(this, 6, value);
  }


}
export namespace IBellSchedule {
  export interface AsObject {
    id: number;
    name: string;
    periodsList: period_period_pb.Period.AsObject[];
    daysList: string[];
    termsList: Term.AsObject[];
    termIdsList: number[];
    overrideDatesList: OverrideDates.AsObject[];
  }
}
export class IBellSchedule extends jspb.Message {
  static readonly displayName = "IBellSchedule";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [3,4,5,6,7];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, IBellSchedule.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): IBellSchedule.AsObject {
    return IBellSchedule.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!IBellSchedule} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: IBellSchedule) {
    var f: any;
    var obj: any = {
      id: jspb.Message.getFieldWithDefault(msg, 1, 0),
      name: jspb.Message.getFieldWithDefault(msg, 2, ""),
      periodsList: jspb.Message.toObjectList(msg.getPeriodsList(),
      period_period_pb.Period.toObject, includeInstance),
      daysList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
      termsList: jspb.Message.toObjectList(msg.getTermsList(),
      Term.toObject, includeInstance),
      termIdsList: (f = jspb.Message.getRepeatedField(msg, 6)) == null ? undefined : f,
      overrideDatesList: jspb.Message.toObjectList(msg.getOverrideDatesList(),
      OverrideDates.toObject, includeInstance)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!IBellSchedule}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new IBellSchedule;
    return IBellSchedule.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!IBellSchedule} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!IBellSchedule}
   */
  static deserializeBinaryFromReader(msg: IBellSchedule, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {number} */ (reader.readInt32());
        msg.setId(value1);
        break;
      case 2:
        var value2 = /** @type {string} */ (reader.readString());
        msg.setName(value2);
        break;
      case 3:
        var value3 = new period_period_pb.Period;
        reader.readMessage(value3,period_period_pb.Period.deserializeBinaryFromReader);
        msg.addPeriods(value3);
        break;
      case 4:
        var value4 = /** @type {string} */ (reader.readString());
        msg.addDays(value4);
        break;
      case 5:
        var value5 = new Term;
        reader.readMessage(value5,Term.deserializeBinaryFromReader);
        msg.addTerms(value5);
        break;
      case 6:
        var value6 = /** @type {!Array<number>} */ (reader.readPackedInt32());
        msg.setTermIdsList(value6);
        break;
      case 7:
        var value7 = new OverrideDates;
        reader.readMessage(value7,OverrideDates.deserializeBinaryFromReader);
        msg.addOverrideDates(value7);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!IBellSchedule} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: IBellSchedule, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getId();
    if (f !== 0) {
      writer.writeInt32(
        1,
        f
      );
    }
    f = message.getName();
    if (f.length > 0) {
      writer.writeString(
        2,
        f
      );
    }
    f = message.getPeriodsList();
    if (f.length > 0) {
      writer.writeRepeatedMessage(
        3,
        f,
        period_period_pb.Period.serializeBinaryToWriter
      );
    }
    f = message.getDaysList();
    if (f.length > 0) {
      writer.writeRepeatedString(
        4,
        f
      );
    }
    f = message.getTermsList();
    if (f.length > 0) {
      writer.writeRepeatedMessage(
        5,
        f,
        Term.serializeBinaryToWriter
      );
    }
    f = message.getTermIdsList();
    if (f.length > 0) {
      writer.writePackedInt32(
        6,
        f
      );
    }
    f = message.getOverrideDatesList();
    if (f.length > 0) {
      writer.writeRepeatedMessage(
        7,
        f,
        OverrideDates.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    IBellSchedule.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional int32 id = 1;
   * @return {number}
   */
  getId(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
  };


  /** @param {number} value */
  setId(value: number) {
    jspb.Message.setProto3IntField(this, 1, value);
  }


  /**
   * optional string name = 2;
   * @return {string}
   */
  getName(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
  };


  /** @param {string} value */
  setName(value: string) {
    jspb.Message.setProto3StringField(this, 2, value);
  }


  /**
   * repeated period.Period periods = 3;
   * @return {!Array<!Period>}
   */
  getPeriodsList(): period_period_pb.Period[] {
    return /** @type{!Array<!Period>} */ (
      jspb.Message.getRepeatedWrapperField(this, period_period_pb.Period, 3));
  }


  /** @param {!Array<!Period>} value */
  setPeriodsList(value?: period_period_pb.Period[]) {
    jspb.Message.setRepeatedWrapperField(this, 3, value);
  }


  /**
   * @param {!Period=} opt_value
   * @param {number=} opt_index
   * @return {!Period}
   */
  addPeriods(opt_value?: period_period_pb.Period, opt_index?: number): period_period_pb.Period {
    return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, period_period_pb.Period, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearPeriodsList() {
    this.setPeriodsList([]);
  }


  /**
   * repeated string days = 4;
   * @return {!Array<string>}
   */
  getDaysList(): string[] {
    return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 4));
  };


  /** @param {!Array<string>} value */
  setDaysList(value: string[]) {
    jspb.Message.setField(this, 4, value || []);
  }


  /**
   * @param {string} value
   * @param {number=} opt_index
   */
  addDays(value: string, opt_index?: number): void {
    jspb.Message.addToRepeatedField(this, 4, value, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearDaysList() {
    this.setDaysList([]);
  }


  /**
   * repeated Term terms = 5;
   * @return {!Array<!Term>}
   */
  getTermsList(): Term[] {
    return /** @type{!Array<!Term>} */ (
      jspb.Message.getRepeatedWrapperField(this, Term, 5));
  }


  /** @param {!Array<!Term>} value */
  setTermsList(value?: Term[]) {
    jspb.Message.setRepeatedWrapperField(this, 5, value);
  }


  /**
   * @param {!Term=} opt_value
   * @param {number=} opt_index
   * @return {!Term}
   */
  addTerms(opt_value?: Term, opt_index?: number): Term {
    return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, Term, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearTermsList() {
    this.setTermsList([]);
  }


  /**
   * repeated int32 term_ids = 6;
   * @return {!Array<number>}
   */
  getTermIdsList(): number[] {
    return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 6));
  };


  /** @param {!Array<number>} value */
  setTermIdsList(value: number[]) {
    jspb.Message.setField(this, 6, value || []);
  }


  /**
   * @param {number} value
   * @param {number=} opt_index
   */
  addTermIds(value: number, opt_index?: number): void {
    jspb.Message.addToRepeatedField(this, 6, value, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearTermIdsList() {
    this.setTermIdsList([]);
  }


  /**
   * repeated OverrideDates override_dates = 7;
   * @return {!Array<!OverrideDates>}
   */
  getOverrideDatesList(): OverrideDates[] {
    return /** @type{!Array<!OverrideDates>} */ (
      jspb.Message.getRepeatedWrapperField(this, OverrideDates, 7));
  }


  /** @param {!Array<!OverrideDates>} value */
  setOverrideDatesList(value?: OverrideDates[]) {
    jspb.Message.setRepeatedWrapperField(this, 7, value);
  }


  /**
   * @param {!OverrideDates=} opt_value
   * @param {number=} opt_index
   * @return {!OverrideDates}
   */
  addOverrideDates(opt_value?: OverrideDates, opt_index?: number): OverrideDates {
    return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, OverrideDates, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearOverrideDatesList() {
    this.setOverrideDatesList([]);
  }


}
export namespace OverrideDates {
  export interface AsObject {
    termId: number;
    datesList: common_date_pb.DateTime.AsObject[];
  }
}
export class OverrideDates extends jspb.Message {
  static readonly displayName = "OverrideDates";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [2];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, OverrideDates.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): OverrideDates.AsObject {
    return OverrideDates.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!OverrideDates} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: OverrideDates) {
    var f: any;
    var obj: any = {
      termId: jspb.Message.getFieldWithDefault(msg, 1, 0),
      datesList: jspb.Message.toObjectList(msg.getDatesList(),
      common_date_pb.DateTime.toObject, includeInstance)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!OverrideDates}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new OverrideDates;
    return OverrideDates.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!OverrideDates} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!OverrideDates}
   */
  static deserializeBinaryFromReader(msg: OverrideDates, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {number} */ (reader.readInt32());
        msg.setTermId(value1);
        break;
      case 2:
        var value2 = new common_date_pb.DateTime;
        reader.readMessage(value2,common_date_pb.DateTime.deserializeBinaryFromReader);
        msg.addDates(value2);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!OverrideDates} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: OverrideDates, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getTermId();
    if (f !== 0) {
      writer.writeInt32(
        1,
        f
      );
    }
    f = message.getDatesList();
    if (f.length > 0) {
      writer.writeRepeatedMessage(
        2,
        f,
        common_date_pb.DateTime.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    OverrideDates.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional int32 term_id = 1;
   * @return {number}
   */
  getTermId(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
  };


  /** @param {number} value */
  setTermId(value: number) {
    jspb.Message.setProto3IntField(this, 1, value);
  }


  /**
   * repeated minga.common.DateTime dates = 2;
   * @return {!Array<!DateTime>}
   */
  getDatesList(): common_date_pb.DateTime[] {
    return /** @type{!Array<!DateTime>} */ (
      jspb.Message.getRepeatedWrapperField(this, common_date_pb.DateTime, 2));
  }


  /** @param {!Array<!DateTime>} value */
  setDatesList(value?: common_date_pb.DateTime[]) {
    jspb.Message.setRepeatedWrapperField(this, 2, value);
  }


  /**
   * @param {!DateTime=} opt_value
   * @param {number=} opt_index
   * @return {!DateTime}
   */
  addDates(opt_value?: common_date_pb.DateTime, opt_index?: number): common_date_pb.DateTime {
    return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, common_date_pb.DateTime, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearDatesList() {
    this.setDatesList([]);
  }


}
export namespace GetBellSchedulesRequest {
  export interface AsObject {
    includeNoSchedule: boolean;
  }
}
export class GetBellSchedulesRequest extends jspb.Message {
  static readonly displayName = "GetBellSchedulesRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): GetBellSchedulesRequest.AsObject {
    return GetBellSchedulesRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!GetBellSchedulesRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: GetBellSchedulesRequest) {
    var f: any;
    var obj: any = {
      includeNoSchedule: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!GetBellSchedulesRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new GetBellSchedulesRequest;
    return GetBellSchedulesRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!GetBellSchedulesRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!GetBellSchedulesRequest}
   */
  static deserializeBinaryFromReader(msg: GetBellSchedulesRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {boolean} */ (reader.readBool());
        msg.setIncludeNoSchedule(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!GetBellSchedulesRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: GetBellSchedulesRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getIncludeNoSchedule();
    if (f) {
      writer.writeBool(
        1,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    GetBellSchedulesRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional bool include_no_schedule = 1;
   * @return {boolean}
   */
  getIncludeNoSchedule(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
  };


  /** @param {boolean} value */
  setIncludeNoSchedule(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 1, value);
  }


}
export namespace GetBellSchedulesResponse {
  export interface AsObject {
    bellScheduleList: IBellSchedule.AsObject[];
  }
}
export class GetBellSchedulesResponse extends jspb.Message {
  static readonly displayName = "GetBellSchedulesResponse";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [1];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, GetBellSchedulesResponse.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): GetBellSchedulesResponse.AsObject {
    return GetBellSchedulesResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!GetBellSchedulesResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: GetBellSchedulesResponse) {
    var f: any;
    var obj: any = {
      bellScheduleList: jspb.Message.toObjectList(msg.getBellScheduleList(),
      IBellSchedule.toObject, includeInstance)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!GetBellSchedulesResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new GetBellSchedulesResponse;
    return GetBellSchedulesResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!GetBellSchedulesResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!GetBellSchedulesResponse}
   */
  static deserializeBinaryFromReader(msg: GetBellSchedulesResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new IBellSchedule;
        reader.readMessage(value1,IBellSchedule.deserializeBinaryFromReader);
        msg.addBellSchedule(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!GetBellSchedulesResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: GetBellSchedulesResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getBellScheduleList();
    if (f.length > 0) {
      writer.writeRepeatedMessage(
        1,
        f,
        IBellSchedule.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    GetBellSchedulesResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * repeated IBellSchedule bell_schedule = 1;
   * @return {!Array<!IBellSchedule>}
   */
  getBellScheduleList(): IBellSchedule[] {
    return /** @type{!Array<!IBellSchedule>} */ (
      jspb.Message.getRepeatedWrapperField(this, IBellSchedule, 1));
  }


  /** @param {!Array<!IBellSchedule>} value */
  setBellScheduleList(value?: IBellSchedule[]) {
    jspb.Message.setRepeatedWrapperField(this, 1, value);
  }


  /**
   * @param {!IBellSchedule=} opt_value
   * @param {number=} opt_index
   * @return {!IBellSchedule}
   */
  addBellSchedule(opt_value?: IBellSchedule, opt_index?: number): IBellSchedule {
    return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, IBellSchedule, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearBellScheduleList() {
    this.setBellScheduleList([]);
  }


}
export namespace GetBellScheduleRequest {
  export interface AsObject {
    id: number;
  }
}
export class GetBellScheduleRequest extends jspb.Message {
  static readonly displayName = "GetBellScheduleRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): GetBellScheduleRequest.AsObject {
    return GetBellScheduleRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!GetBellScheduleRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: GetBellScheduleRequest) {
    var f: any;
    var obj: any = {
      id: jspb.Message.getFieldWithDefault(msg, 1, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!GetBellScheduleRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new GetBellScheduleRequest;
    return GetBellScheduleRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!GetBellScheduleRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!GetBellScheduleRequest}
   */
  static deserializeBinaryFromReader(msg: GetBellScheduleRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {number} */ (reader.readInt32());
        msg.setId(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!GetBellScheduleRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: GetBellScheduleRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getId();
    if (f !== 0) {
      writer.writeInt32(
        1,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    GetBellScheduleRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional int32 id = 1;
   * @return {number}
   */
  getId(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
  };


  /** @param {number} value */
  setId(value: number) {
    jspb.Message.setProto3IntField(this, 1, value);
  }


}
export namespace GetBellScheduleResponse {
  export interface AsObject {
    bellSchedule?: IBellSchedule.AsObject;
  }
}
export class GetBellScheduleResponse extends jspb.Message {
  static readonly displayName = "GetBellScheduleResponse";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): GetBellScheduleResponse.AsObject {
    return GetBellScheduleResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!GetBellScheduleResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: GetBellScheduleResponse) {
    var f: any;
    var obj: any = {
      bellSchedule: (f = msg.getBellSchedule()) && IBellSchedule.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!GetBellScheduleResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new GetBellScheduleResponse;
    return GetBellScheduleResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!GetBellScheduleResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!GetBellScheduleResponse}
   */
  static deserializeBinaryFromReader(msg: GetBellScheduleResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new IBellSchedule;
        reader.readMessage(value1,IBellSchedule.deserializeBinaryFromReader);
        msg.setBellSchedule(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!GetBellScheduleResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: GetBellScheduleResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getBellSchedule();
    if (f != null) {
      writer.writeMessage(
        1,
        f,
        IBellSchedule.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    GetBellScheduleResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional IBellSchedule bell_schedule = 1;
   * @return {?IBellSchedule}
   */
  getBellSchedule(): IBellSchedule {
    return /** @type{?IBellSchedule} */ (
      jspb.Message.getWrapperField(this, IBellSchedule, 1));
  }


  /** @param {?IBellSchedule|undefined} value */
  setBellSchedule(value?: IBellSchedule) {
    jspb.Message.setWrapperField(this, 1, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearBellSchedule() {
    this.setBellSchedule(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasBellSchedule(): boolean {
    return jspb.Message.getField(this, 1) != null;
  }


}
export namespace UpsertBellScheduleRequest {
  export interface AsObject {
    bellSchedule?: IBellSchedule.AsObject;
  }
}
export class UpsertBellScheduleRequest extends jspb.Message {
  static readonly displayName = "UpsertBellScheduleRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): UpsertBellScheduleRequest.AsObject {
    return UpsertBellScheduleRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!UpsertBellScheduleRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: UpsertBellScheduleRequest) {
    var f: any;
    var obj: any = {
      bellSchedule: (f = msg.getBellSchedule()) && IBellSchedule.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!UpsertBellScheduleRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new UpsertBellScheduleRequest;
    return UpsertBellScheduleRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!UpsertBellScheduleRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!UpsertBellScheduleRequest}
   */
  static deserializeBinaryFromReader(msg: UpsertBellScheduleRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new IBellSchedule;
        reader.readMessage(value1,IBellSchedule.deserializeBinaryFromReader);
        msg.setBellSchedule(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!UpsertBellScheduleRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: UpsertBellScheduleRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getBellSchedule();
    if (f != null) {
      writer.writeMessage(
        1,
        f,
        IBellSchedule.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    UpsertBellScheduleRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional IBellSchedule bell_schedule = 1;
   * @return {?IBellSchedule}
   */
  getBellSchedule(): IBellSchedule {
    return /** @type{?IBellSchedule} */ (
      jspb.Message.getWrapperField(this, IBellSchedule, 1));
  }


  /** @param {?IBellSchedule|undefined} value */
  setBellSchedule(value?: IBellSchedule) {
    jspb.Message.setWrapperField(this, 1, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearBellSchedule() {
    this.setBellSchedule(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasBellSchedule(): boolean {
    return jspb.Message.getField(this, 1) != null;
  }


}
export namespace UpsertBellScheduleResponse {
  export interface AsObject {
    bellSchedule?: IBellSchedule.AsObject;
  }
}
export class UpsertBellScheduleResponse extends jspb.Message {
  static readonly displayName = "UpsertBellScheduleResponse";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): UpsertBellScheduleResponse.AsObject {
    return UpsertBellScheduleResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!UpsertBellScheduleResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: UpsertBellScheduleResponse) {
    var f: any;
    var obj: any = {
      bellSchedule: (f = msg.getBellSchedule()) && IBellSchedule.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!UpsertBellScheduleResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new UpsertBellScheduleResponse;
    return UpsertBellScheduleResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!UpsertBellScheduleResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!UpsertBellScheduleResponse}
   */
  static deserializeBinaryFromReader(msg: UpsertBellScheduleResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new IBellSchedule;
        reader.readMessage(value1,IBellSchedule.deserializeBinaryFromReader);
        msg.setBellSchedule(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!UpsertBellScheduleResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: UpsertBellScheduleResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getBellSchedule();
    if (f != null) {
      writer.writeMessage(
        1,
        f,
        IBellSchedule.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    UpsertBellScheduleResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional IBellSchedule bell_schedule = 1;
   * @return {?IBellSchedule}
   */
  getBellSchedule(): IBellSchedule {
    return /** @type{?IBellSchedule} */ (
      jspb.Message.getWrapperField(this, IBellSchedule, 1));
  }


  /** @param {?IBellSchedule|undefined} value */
  setBellSchedule(value?: IBellSchedule) {
    jspb.Message.setWrapperField(this, 1, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearBellSchedule() {
    this.setBellSchedule(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasBellSchedule(): boolean {
    return jspb.Message.getField(this, 1) != null;
  }


}
export namespace DeleteBellScheduleRequest {
  export interface AsObject {
    id: number;
  }
}
export class DeleteBellScheduleRequest extends jspb.Message {
  static readonly displayName = "DeleteBellScheduleRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): DeleteBellScheduleRequest.AsObject {
    return DeleteBellScheduleRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!DeleteBellScheduleRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: DeleteBellScheduleRequest) {
    var f: any;
    var obj: any = {
      id: jspb.Message.getFieldWithDefault(msg, 1, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!DeleteBellScheduleRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new DeleteBellScheduleRequest;
    return DeleteBellScheduleRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!DeleteBellScheduleRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!DeleteBellScheduleRequest}
   */
  static deserializeBinaryFromReader(msg: DeleteBellScheduleRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {number} */ (reader.readInt32());
        msg.setId(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!DeleteBellScheduleRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: DeleteBellScheduleRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getId();
    if (f !== 0) {
      writer.writeInt32(
        1,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    DeleteBellScheduleRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional int32 id = 1;
   * @return {number}
   */
  getId(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
  };


  /** @param {number} value */
  setId(value: number) {
    jspb.Message.setProto3IntField(this, 1, value);
  }


}
export namespace DeleteBellScheduleResponse {
  export interface AsObject {
  }
}
export class DeleteBellScheduleResponse extends jspb.Message {
  static readonly displayName = "DeleteBellScheduleResponse";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): DeleteBellScheduleResponse.AsObject {
    return DeleteBellScheduleResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!DeleteBellScheduleResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: DeleteBellScheduleResponse) {
    var f: any;
    var obj: any = {

    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!DeleteBellScheduleResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new DeleteBellScheduleResponse;
    return DeleteBellScheduleResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!DeleteBellScheduleResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!DeleteBellScheduleResponse}
   */
  static deserializeBinaryFromReader(msg: DeleteBellScheduleResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!DeleteBellScheduleResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: DeleteBellScheduleResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    DeleteBellScheduleResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
}
export namespace OverrideBellScheduleForDateRequest {
  export interface AsObject {
    id: number;
    date?: common_date_pb.DateTime.AsObject;
    termId: number;
    isOverride: boolean;
  }
}
export class OverrideBellScheduleForDateRequest extends jspb.Message {
  static readonly displayName = "OverrideBellScheduleForDateRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): OverrideBellScheduleForDateRequest.AsObject {
    return OverrideBellScheduleForDateRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!OverrideBellScheduleForDateRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: OverrideBellScheduleForDateRequest) {
    var f: any;
    var obj: any = {
      id: jspb.Message.getFieldWithDefault(msg, 1, 0),
      date: (f = msg.getDate()) && common_date_pb.DateTime.toObject(includeInstance, f),
      termId: jspb.Message.getFieldWithDefault(msg, 3, 0),
      isOverride: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!OverrideBellScheduleForDateRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new OverrideBellScheduleForDateRequest;
    return OverrideBellScheduleForDateRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!OverrideBellScheduleForDateRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!OverrideBellScheduleForDateRequest}
   */
  static deserializeBinaryFromReader(msg: OverrideBellScheduleForDateRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {number} */ (reader.readInt32());
        msg.setId(value1);
        break;
      case 2:
        var value2 = new common_date_pb.DateTime;
        reader.readMessage(value2,common_date_pb.DateTime.deserializeBinaryFromReader);
        msg.setDate(value2);
        break;
      case 3:
        var value3 = /** @type {number} */ (reader.readInt32());
        msg.setTermId(value3);
        break;
      case 4:
        var value4 = /** @type {boolean} */ (reader.readBool());
        msg.setIsOverride(value4);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!OverrideBellScheduleForDateRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: OverrideBellScheduleForDateRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getId();
    if (f !== 0) {
      writer.writeInt32(
        1,
        f
      );
    }
    f = message.getDate();
    if (f != null) {
      writer.writeMessage(
        2,
        f,
        common_date_pb.DateTime.serializeBinaryToWriter
      );
    }
    f = message.getTermId();
    if (f !== 0) {
      writer.writeInt32(
        3,
        f
      );
    }
    f = message.getIsOverride();
    if (f) {
      writer.writeBool(
        4,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    OverrideBellScheduleForDateRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional int32 id = 1;
   * @return {number}
   */
  getId(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
  };


  /** @param {number} value */
  setId(value: number) {
    jspb.Message.setProto3IntField(this, 1, value);
  }


  /**
   * optional minga.common.DateTime date = 2;
   * @return {?DateTime}
   */
  getDate(): common_date_pb.DateTime {
    return /** @type{?DateTime} */ (
      jspb.Message.getWrapperField(this, common_date_pb.DateTime, 2));
  }


  /** @param {?DateTime|undefined} value */
  setDate(value?: common_date_pb.DateTime) {
    jspb.Message.setWrapperField(this, 2, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearDate() {
    this.setDate(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasDate(): boolean {
    return jspb.Message.getField(this, 2) != null;
  }


  /**
   * optional int32 term_id = 3;
   * @return {number}
   */
  getTermId(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
  };


  /** @param {number} value */
  setTermId(value: number) {
    jspb.Message.setProto3IntField(this, 3, value);
  }


  /**
   * optional bool is_override = 4;
   * @return {boolean}
   */
  getIsOverride(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
  };


  /** @param {boolean} value */
  setIsOverride(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 4, value);
  }


}
export namespace OverrideBellScheduleForDateResponse {
  export interface AsObject {
  }
}
export class OverrideBellScheduleForDateResponse extends jspb.Message {
  static readonly displayName = "OverrideBellScheduleForDateResponse";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): OverrideBellScheduleForDateResponse.AsObject {
    return OverrideBellScheduleForDateResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!OverrideBellScheduleForDateResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: OverrideBellScheduleForDateResponse) {
    var f: any;
    var obj: any = {

    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!OverrideBellScheduleForDateResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new OverrideBellScheduleForDateResponse;
    return OverrideBellScheduleForDateResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!OverrideBellScheduleForDateResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!OverrideBellScheduleForDateResponse}
   */
  static deserializeBinaryFromReader(msg: OverrideBellScheduleForDateResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!OverrideBellScheduleForDateResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: OverrideBellScheduleForDateResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    OverrideBellScheduleForDateResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
}
