/* eslint-disable @typescript-eslint/no-namespace */
import { IConsequenceType, ExistingConsequenceType } from 'libs/domain';
import { Restriction } from 'libs/domain';
import { pbis_pb } from 'libs/generated-grpc-ts';
import { wrappers_pb } from 'libs/generated-grpc-ts';

import { asObjectToMessage } from '../';
import { RestrictionMapper } from '../restriction';

export namespace ConsequenceTypeMapper {
  export const toProto = (
    type: ExistingConsequenceType,
  ): pbis_pb.ConsequenceType => {
    const msg = new pbis_pb.ConsequenceType();
    msg.setName(type.name);
    msg.setCategoryId(type.categoryId);
    msg.setAddNotes(type.addNotes);
    msg.setSendParentEmail(type.sendParentEmail);
    msg.setSendParentSms(type.sendParentSms);
    msg.setSendAdminEmail(type.sendAdminEmail);
    msg.setActive(type.active);
    msg.setDailyConsequenceCount(type.dailyConsequenceCount);

    if (type.points) msg.setPoints(type.points);
    if (type.description) msg.setDescription(type.description);
    if (type.sendAdminEmail && type.adminEmails)
      msg.setAdminEmailList(type.adminEmails);
    if (type.id) {
      msg.setId(type.id);
    }
    if (type.type) {
      msg.setTypeId(type.type);
    }
    if (type.stickerId) {
      msg.setStickerId(type.stickerId);
    }
    if (type.emailTemplate)
      msg.setEmailTemplate(
        asObjectToMessage(type.emailTemplate, new pbis_pb.EmailTemplate()),
      );
    if (type.smsTemplate)
      msg.setSmsTemplate(
        asObjectToMessage(type.smsTemplate, new pbis_pb.SmsTemplate()),
      );

    msg.setEnableDueDate(type.enableDueDate);
    if (type.iconType) msg.setIconType(type.iconType);
    if (type.iconColor) msg.setIconColor(type.iconColor);
    if (type.removeStickerId) msg.setRemoveStickerId(type.removeStickerId);
    if (type.overdueActionId) msg.setOverdueActionId(type.overdueActionId);
    if (type.overdueActionType)
      msg.setOverdueActionType(type.overdueActionType);
    if (type.overdueActionDueDate !== null) {
      const wrapper = new wrappers_pb.Int32Value();
      wrapper.setValue(type.overdueActionDueDate);
      msg.setOverdueActionDueDate(wrapper);
    }
    if (type.overdueAction) {
      msg.setOverdueAction(toProto(type.overdueAction));
    }

    if (type.restriction) {
      const restrictionMsg = RestrictionMapper.toProto(type.restriction);
      msg.setRestriction(restrictionMsg);
    }

    return msg;
  };

  export const fromProto = (
    msg: pbis_pb.ConsequenceType,
  ): IConsequenceType | ExistingConsequenceType => {
    let restriction: Restriction | undefined;
    if (msg.getRestriction()) {
      restriction = RestrictionMapper.fromProto(msg.getRestriction());
    }

    const overdueAction = msg.getOverdueAction();
    const consequenceType: any = {
      name: msg.getName(),
      description: msg.getDescription(),
      categoryId: msg.getCategoryId(),
      addNotes: msg.getAddNotes(),
      sendParentEmail: msg.getSendParentEmail(),
      sendParentSms: msg.getSendParentSms(),
      sendAdminEmail: msg.getSendAdminEmail(),
      adminEmails: msg.getAdminEmailList(),
      active: msg.getActive(),
      dailyConsequenceCount: msg.getDailyConsequenceCount(),
      points: msg.getPoints(),
      type: msg.getTypeId(),
      stickerId: msg.getStickerId(),
      emailTemplate: msg.getEmailTemplate()?.toObject(),
      smsTemplate: msg.getSmsTemplate()?.toObject(),
      enableDueDate: msg.getEnableDueDate(),
      iconType: msg.getIconType(),
      iconColor: msg.getIconColor(),
      removeStickerId: msg.getRemoveStickerId(),
      restriction,
      overdueActionId: msg.getOverdueActionId(),
      overdueActionDueDate: msg.hasOverdueActionDueDate()
        ? msg.getOverdueActionDueDate().getValue()
        : null,
      overdueActionType: msg.getOverdueActionType(),
      overdueAction: overdueAction ? fromProto(overdueAction) : null,
    };

    if (msg.getId()) {
      consequenceType['id'] = msg.getId();
    }

    return consequenceType;
  };
}
