import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';

import { Store } from '@ngrx/store';
import { IModerationDetailTags } from 'libs/domain';
import { MingaPermission } from 'libs/domain';
import { ReportReason, mingaSettingTypes } from 'libs/util';
import { ReplaySubject, combineLatest } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

import { IEmailContentSend } from '@app/src/app/content-common/services/EmailContent/types';
import { sendContentEmail } from '@app/src/app/content-common/store/actions';
import { IFailedModerationEvent } from '@app/src/app/minimal/services/ContentEvents';
import { ContentEvents } from '@app/src/app/minimal/services/ContentEvents';
import { MgModalService } from '@app/src/app/minimal/services/MgModal';
import { RootService } from '@app/src/app/minimal/services/RootService';
import { StreamManager } from '@app/src/app/minimal/services/StreamManager';
import { PermissionsService } from '@app/src/app/permissions';
import { ReportService } from '@app/src/app/services/Report';
import { MingaSettingsService } from '@app/src/app/store/Minga/services';

import { IFailedModerationItem } from '../../types';

/**
 * Temporary file to add support for old root functionality that should be
 * redone and added to the new layout component.
 *
 * @deprecated do not add new features here
 */
@Component({
  selector: 'mg-layout-legacy-root',
  templateUrl: './layout-legacy-root.component.html',
  styleUrls: ['./layout-legacy-root.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LayoutLegacyRootComponent implements OnInit, OnDestroy {
  // Children

  @ViewChild('modFailedOverlayTemplate', { static: true })
  modFailedOverlayTemplate: TemplateRef<any>;
  @ViewChild('successOverlayTemplate', { static: true })
  successOverlayTemplate?: TemplateRef<any>;

  // Cleanup

  private readonly _destroyedSubject = new ReplaySubject<void>(1);

  // State

  public modFailContextHash = '';
  public modFailContentHash = '';
  public modFailGalleryPhotoUuid = '';
  public modFailHeadline = 'default';
  public modFailBody = 'default';
  public modFailBodySecond = '';
  public failBodyTranslateParams: any = {};
  public failBodyCatTranslateParams: any;
  readonly modFailItems: Map<number, IFailedModerationItem> = new Map();
  public modFailSendEmailOptions: IEmailContentSend | null = null;

  readonly shouldBlock$ = this._settings.getSettingValueObs(
    mingaSettingTypes.MODERATION_BLOCK,
  );

  readonly shouldAllowPostAnyways$ = combineLatest([
    this._permission.observePermission(
      MingaPermission.CONTENT_MODERATION_OVERRIDE,
    ),
    this.shouldBlock$,
  ]).pipe(
    map(([permission, shouldBlock]) => {
      if (permission) return true;
      else if (!shouldBlock) return true;
      return false;
    }),
  );

  // Computed getters

  get isLoading() {
    return this._rootService.isLoading;
  }

  /** Component constructor */
  constructor(
    public store: Store<any>,
    public reportService: ReportService,
    private _contentEvents: ContentEvents,
    private _rootService: RootService,
    private _streamManager: StreamManager,
    private _permission: PermissionsService,
    private _settings: MingaSettingsService,
    private _mgModal: MgModalService,
  ) {}

  ngOnInit(): void {
    this._contentEvents.onFailedModeration
      .pipe(takeUntil(this._destroyedSubject))
      .subscribe(ev => this._onFailedModeration(ev));
    this._contentEvents.onSuccessModeration
      .pipe(takeUntil(this._destroyedSubject))
      .subscribe(ev => this._successDialog());
  }

  ngOnDestroy(): void {
    this._destroyedSubject.next();
    this._destroyedSubject.complete();
  }

  public closeActiveModal() {
    this._mgModal.closeActiveModal();
  }

  public async overrideModFail() {
    if (this.modFailContextHash || this.modFailGalleryPhotoUuid) {
      const modFailContextHash = this.modFailContextHash;
      const modFailContentHash = this.modFailContentHash;
      const modFailSendEmailOptions = this.modFailSendEmailOptions;

      this.reportService.overrideAI({
        contentHash: modFailContentHash,
        contextHash: modFailContextHash,
        galleryPhotoUuid: this.modFailGalleryPhotoUuid,
        reasonList: this._getModFailReasonList(),
      });

      if (modFailSendEmailOptions && modFailContentHash) {
        this.store.dispatch(
          sendContentEmail({
            contentHash: modFailContentHash,
            options: modFailSendEmailOptions,
          }),
        );
      }

      if (this.modFailGalleryPhotoUuid) {
        await this._streamManager.restartStreamIfAvailable('GalleryFeed');
      }

      this.closeActiveModal();
    }
  }

  private _getModFailReasonList(): string[] {
    const reasonList: string[] = [];

    this.modFailItems.forEach(value => {
      if (!value.categories) return;
      reasonList.push(...value.categories);
    });

    return reasonList;
  }

  private async _successDialog() {
    const dialogRef = this._mgModal.open(this.successOverlayTemplate!, {
      full: true,
      animation: 'fade',
    });

    setTimeout(() => dialogRef.close(), 2000);
    // Wait for the animation to start a tiny bit
    await new Promise(resolve => setTimeout(resolve, 1000));
  }

  getModFailItemsAsArray() {
    const failItemsArray: any[] = [];
    for (const [key, value] of this.modFailItems.entries()) {
      let textCategories =
        value.categories && value.categories.length
          ? value.categories.join(', ')
          : '';
      const imageCategories =
        value.imageCategories && value.imageCategories.length
          ? value.imageCategories.join(', ')
          : '';
      let hasIndex = false;

      const itemObj: any = {
        bodyLocale: value.bodyLocale,
        bodySecondLocale: value.bodySecondLocale,
      };
      if (value.imageIndex && value.imageIndex >= 0) {
        itemObj.index = value.imageIndex;
        hasIndex = true;
      }

      if (!textCategories && !imageCategories) {
        textCategories = 'inappropriate';
      }
      if (value.bodyLocale === 'image') {
        itemObj.categories = { value: imageCategories };
      } else {
        itemObj.categories = { value: textCategories };
      }
      if (value.bodyLocale.includes('image') && hasIndex) {
        itemObj.hasIndex = true;
        itemObj.bodyLocale += 'Index';
      }

      if (value.bodySecondLocale && value.bodySecondLocale === 'image') {
        itemObj.categoriesSecond = { value: imageCategories };
      } else {
        itemObj.categoriesSecond = { value: textCategories };
      }
      if (value.bodySecondLocale.includes('image') && hasIndex) {
        itemObj.hasIndexSecond = true;
        itemObj.bodySecondLocale += 'Index';
      }

      failItemsArray.push(itemObj);
    }
    return failItemsArray;
  }

  private _onFailedModeration(ev: IFailedModerationEvent) {
    // reset stored items from previous failed moderation events
    this.modFailContextHash = '';
    this.modFailGalleryPhotoUuid = '';
    this.modFailContentHash = '';

    this.modFailSendEmailOptions = ev.emailContentSend;

    if (ev.contextHash) {
      this.modFailContentHash = ev.contentHash || '';
      this.modFailContextHash = ev.contextHash;
    } else if (ev.galleryPhotoUuid) {
      this.modFailGalleryPhotoUuid = ev.galleryPhotoUuid;
    } else {
      throw new Error(
        `_onFailedModeration() event called with no contextHash and no galleryPhotoUuid`,
      );
    }

    this.closeActiveModal();

    // default settings
    this.modFailHeadline = 'default';
    this.modFailBodySecond = '';
    this.failBodyTranslateParams = { value: 'inappropriate' };
    this.failBodyCatTranslateParams = null;
    this.modFailItems.clear();

    if (ev.imageAndTextFailed) {
      this.modFailBody = 'imageText';
      this.modFailBodySecond = 'image';
    } else if (ev.imageTextFailed) {
      this.modFailBody = 'imageText';
    } else if (ev.imageFailed) {
      this.modFailBody = 'image';
    } else if (ev.textFailed) {
      this.modFailBody = 'text';
    } else {
      this.modFailBody = 'default';
    }

    let indexes = '';
    let categories = '';

    const setModItem = (
      index: number,
      bodyLocale: string,
      tags: IModerationDetailTags | null = null,
      imageTags: IModerationDetailTags | null = null,
    ) => {
      const currentValue = this.modFailItems.get(index);
      let imageCategories: string[] = [];
      let textCategories: string[] = [];
      let bodySecondLocale = '';

      // don't set if no applicable tags
      if (
        (!imageTags || !imageTags.categories.length) &&
        (!tags || !tags.categories.length)
      ) {
        return;
      }

      let reasonIndex = tags && tags.reasonIndex ? tags.reasonIndex : null;
      if (!reasonIndex && imageTags) {
        reasonIndex = imageTags.reasonIndex ? imageTags.reasonIndex : null;
      }

      if (currentValue && currentValue.imageCategories) {
        imageCategories = currentValue.imageCategories;

        if (imageTags && imageTags.categories.length) {
          // concat with any existing
          imageCategories = currentValue.imageCategories.concat(
            imageTags.categories,
          );
        }
      } else if (imageTags && imageTags.categories.length) {
        imageCategories = imageTags.categories;
      }

      if (currentValue && currentValue.categories) {
        textCategories = currentValue.categories;

        if (tags && tags.categories.length) {
          // concat with any existing
          textCategories = currentValue.categories.concat(tags.categories);
        }
      } else if (tags && tags.categories.length) {
        textCategories = tags.categories;
      }

      if (
        currentValue &&
        currentValue.bodyLocale &&
        currentValue.bodyLocale !== bodyLocale &&
        !currentValue.bodySecondLocale
      ) {
        // add second body if different than current and no second passed.
        bodySecondLocale = bodyLocale;
        bodyLocale = currentValue.bodyLocale;
      }

      if (bodyLocale === 'text' && textCategories.length > 1) {
        bodyLocale = 'textMultiple';
      }

      this.modFailItems.set(index, {
        bodyLocale: bodyLocale || 'default',
        categories: textCategories,
        imageCategories,
        bodySecondLocale,
        reasonIndex,
        imageIndex: index >= 0 ? index + 1 : null, // add one to index to start not at zero
      });
    };

    if (ev.tags || ev.imageTags) {
      if (ev.tags) {
        for (const [key, value] of ev.tags.entries()) {
          let locale = 'text';
          if (
            (ev.imageTextFailed || ev.imageAndTextFailed) &&
            value.reasonIndex &&
            value.reasonIndex !== ReportReason.INAPPROPRIATE &&
            value.reasonIndex !== ReportReason.ABUSIVE
          ) {
            locale = 'image';
          }
          setModItem(key, locale, value);
        }
      }
      if (ev.imageTags) {
        for (const [key, value] of ev.imageTags.entries()) {
          setModItem(key, 'image', null, value);
        }
      }

      // remove trailing commas
      if (categories) {
        categories = categories.substring(0, categories.length - 2);
        this.failBodyTranslateParams.value = categories;
      }
      if (indexes && ev.tags && ev.tags.size > 1) {
        indexes = indexes.substring(0, indexes.length - 2);
        this.failBodyTranslateParams.value = indexes;
        this.failBodyCatTranslateParams = { value: categories };
        this.modFailHeadline = 'multiplePosts';
        this.modFailBody = 'imageIndex';
      }
    }

    if (this.modFailedOverlayTemplate) {
      this._mgModal.open(this.modFailedOverlayTemplate);
    }
  }
}
