import { createAction, props } from '@ngrx/store';

import { IEmailContentSend } from '@app/src/app/content-common/services/EmailContent/types';

export const sendContentEmail = createAction(
  '[Content] Send content email',
  props<{ contentHash: string; options: IEmailContentSend }>(),
);

export const sendContentEmailFailed = createAction(
  '[Content] Content email failed to send',
  props<{ contentHash: string; options: IEmailContentSend; error: any }>(),
);

export const sendContentEmailSucceeded = createAction(
  '[Content] Content email send success',
  props<{ contentHash: string; options: IEmailContentSend }>(),
);

export type ContentActions =
  | typeof sendContentEmail
  | typeof sendContentEmailFailed
  | typeof sendContentEmailSucceeded;
