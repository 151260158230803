import { UserList, UserListMinimal } from 'libs/domain';
import { user_list_pb } from 'libs/generated-grpc-ts';

import { dateTimeMessageToDayjsObject, dayJsObjectToDateTimeMsg } from '../';

export const toProto = async (
  list: Partial<UserList>,
): Promise<user_list_pb.UserList> => {
  const message = new user_list_pb.UserList();
  if (list.createTime) {
    message.setCreateTime(dayJsObjectToDateTimeMsg(list.createTime));
  }
  if (list.updateTime) {
    message.setUpdateTime(dayJsObjectToDateTimeMsg(list.updateTime));
  }
  if (list.id) message.setId(list.id);
  if (list.uuid) message.setUuid(list.uuid);
  if (list.termSourceIds) {
    message.setTermSourceIdsList(list.termSourceIds);
  }
  if (list.periodSourceIds) {
    message.setPeriodSourceIdsList(list.periodSourceIds);
  }
  if (list.public) message.setPublic(list.public);
  if (list.active) message.setActive(list.active);
  if (list.title) message.setTitle(list.title);
  if (list.description) message.setDescription(list.description);
  if (list.memberCount) message.setMemberCount(list.memberCount);
  if (list.managerListId) message.setManagerListId(list.managerListId);
  if (list.creator) {
    message.setCreator(
      new user_list_pb.UserList.Creator([
        list.creator.hash,
        list.creator.firstName,
        list.creator.lastName,
      ]),
    );
  }
  if (list.managers) {
    message.setManagersList(
      list.managers.map(
        ({ hash, firstName, lastName }) =>
          new user_list_pb.UserList.Manager([hash, firstName, lastName]),
      ),
    );
  }

  if (list.isHomeroom) message.setIsHomeroom(list.isHomeroom);
  if (list.location) message.setLocation(list.location);
  if (list.sourcedId) message.setSourcedId(list.sourcedId);

  return message;
};

export const fromProto = async (
  message: user_list_pb.UserList,
): Promise<UserList> => {
  const createTime = message.getCreateTime();
  const updatedTime = message.getUpdateTime();
  const creator = message.getCreator();
  const managers: UserList['managers'] = await Promise.all(
    message.getManagersList().map(async managerMessage => ({
      hash: managerMessage.getHash(),
      firstName: managerMessage.getFirstName(),
      lastName: managerMessage.getLastName(),
    })),
  );
  return {
    createTime: createTime && dateTimeMessageToDayjsObject(createTime),
    updateTime: updatedTime && dateTimeMessageToDayjsObject(updatedTime),
    id: message.getId(),
    uuid: message.getUuid(),
    termSourceIds: message.getTermSourceIdsList(),
    periodSourceIds: message.getPeriodSourceIdsList(),
    public: message.getPublic(),
    active: message.getActive(),
    title: message.getTitle(),
    description: message.getDescription(),
    memberCount: message.getMemberCount(),
    creator: {
      hash: creator?.getHash(),
      firstName: creator?.getFirstName() ?? '',
      lastName: creator?.getLastName() ?? '',
    },
    managerListId: message.getManagerListId(),
    managers: managers ?? [],
    isHomeroom: message.getIsHomeroom(),
    location: message.getLocation(),
    sourcedId: message.getSourcedId(),
  };
};

export const toProtoMinimal = (
  list: UserListMinimal,
): user_list_pb.UserListMinimal => {
  const message = new user_list_pb.UserListMinimal();
  if (list.id) message.setId(list.id);
  if (list.title) message.setTitle(list.title);
  message.setCurrentTerm(list.currentTerm);
  message.setIsHomeroom(list.isHomeroom);
  return message;
};

export const fromProtoMinimal = (
  message: user_list_pb.UserListMinimal,
): UserListMinimal => ({
  id: message.getId(),
  title: message.getTitle(),
  currentTerm: message.getCurrentTerm(),
  isHomeroom: message.getIsHomeroom(),
});
