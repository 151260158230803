import { EventPerson } from 'libs/domain';
import { event_pb } from 'libs/generated-grpc-ts';

import { dateObjectToDateTimeMessage, dateTimeMessageToDateObject } from '../';

export const toProto = (eventPerson: EventPerson): event_pb.EventPerson => {
  const msg = new event_pb.EventPerson();
  if (eventPerson.name) msg.setName(eventPerson.name);
  if (eventPerson.tickets) msg.setTickets(eventPerson.tickets);
  if (eventPerson.checkinTime)
    msg.setCheckinTime(dateObjectToDateTimeMessage(eventPerson.checkinTime));
  if (eventPerson.checkoutTime)
    msg.setCheckoutTime(dateObjectToDateTimeMessage(eventPerson.checkoutTime));
  if (eventPerson.studentId) msg.setStudentId(eventPerson.studentId);
  return msg;
};

export const fromProto = (eventPerson: event_pb.EventPerson): EventPerson => {
  const checkinTimeProto = eventPerson.getCheckinTime();
  const checkoutTimeProto = eventPerson.getCheckoutTime();
  return {
    name: eventPerson.getName(),
    tickets: eventPerson.getTickets(),
    checkinTime: checkinTimeProto
      ? dateTimeMessageToDateObject(checkinTimeProto)
      : undefined,
    checkoutTime: checkoutTimeProto
      ? dateTimeMessageToDateObject(checkoutTimeProto)
      : undefined,
    studentId: eventPerson.getStudentId(),
  };
};
