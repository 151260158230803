import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';

import { MgIconModule } from '@app/src/app/icon';

import { TextModule } from '@shared/components/text';
import { AppColorPipeModule } from '@shared/pipes/app-color/app-color-pipe.module';

import { GenericButtonComponent } from './components/button';
import { GenericCardComponent } from './components/card';
import { GenericChipComponent } from './components/chip';
import { GenericMenuItemComponent } from './components/menu';
import { GenericTagComponent } from './components/tag';
import { TagCollectionComponent } from './components/tag-collection//tag-collection.component';

@NgModule({
  imports: [
    // Minga dependencies
    TextModule,
    MgIconModule,

    // External dependencies
    CommonModule,
    FlexLayoutModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatRippleModule,
    MatTooltipModule,
    AppColorPipeModule,
  ],
  declarations: [
    GenericButtonComponent,
    GenericCardComponent,
    GenericChipComponent,
    GenericTagComponent,
    GenericMenuItemComponent,
    TagCollectionComponent,
  ],
  exports: [
    TextModule,
    MgIconModule,
    MatRippleModule,
    GenericButtonComponent,
    GenericCardComponent,
    GenericChipComponent,
    GenericTagComponent,
    GenericMenuItemComponent,
    TagCollectionComponent,
  ],
})
export class GenericModule {}
