// GENERATED CODE -- DO NOT EDIT!

import {grpc} from '@improbable-eng/grpc-web';

import * as points_internal_points_internal_pb from '../points_internal/points_internal_pb';

export class InternalPointsStatsService {
  static readonly serviceName = "minga.points_internal.InternalPointsStatsService";

  static readonly GetPointsSummaryReport = {
    methodName: "GetPointsSummaryReport",
    service: InternalPointsStatsService,
    requestStream: false,
    responseStream: false,
    requestType: points_internal_points_internal_pb.GetPointsSummaryReportRequest,
    responseType: points_internal_points_internal_pb.GetPointsSummaryReportResponse,
  };

  static readonly GetTeamPointsSummaryReport = {
    methodName: "GetTeamPointsSummaryReport",
    service: InternalPointsStatsService,
    requestStream: false,
    responseStream: false,
    requestType: points_internal_points_internal_pb.GetTeamPointsSummaryReportRequest,
    responseType: points_internal_points_internal_pb.GetTeamPointsSummaryReportResponse,
  };

}

export class InternalPointsStatsServiceClient {
  getPointsSummaryReport() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  getTeamPointsSummaryReport() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
}
