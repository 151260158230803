import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';

import { ReplaySubject } from 'rxjs';

import { RootService } from '@app/src/app/minimal/services/RootService';

@Component({
  selector: 'mg-layout-global-menu',
  templateUrl: './layout-global-menu.component.html',
  styleUrls: ['./layout-global-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LayoutGlobalMenuComponent implements OnDestroy {
  // Children
  @ViewChild('globalMenu', { static: true })
  globalMenuEl?: ElementRef;
  @ViewChild('globalMenuTrigger', { static: true })
  globalMenuTriggerEl?: MatMenuTrigger;

  // Cleanup
  private readonly _destroyedSubject = new ReplaySubject<void>(1);

  // Inputs
  @Input()
  public readonly overlayIsActive: boolean;

  // State
  globalMenuItems: any[] = [];

  /** Component constructor */
  constructor(private _root: RootService) {
    this._root.onOpenGlobalMenu((options: any) =>
      this._onOpenGlobalMenu(options),
    );
  }

  ngOnDestroy(): void {
    this._destroyedSubject.next();
    this._destroyedSubject.complete();
  }

  private async _onOpenGlobalMenu(options: any) {
    const { x, y, items } = options;

    this.globalMenuEl!.nativeElement.style.top = y + 'px';
    this.globalMenuEl!.nativeElement.style.left = x + 'px';
    this.globalMenuItems = items;

    await new Promise(resolve => requestAnimationFrame(resolve));
    this.globalMenuTriggerEl!.openMenu();
  }
}
