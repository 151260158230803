import { Directive, OnDestroy } from '@angular/core';

import { MingaSearchInfo } from 'libs/domain';
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  takeUntil,
  tap,
} from 'rxjs/operators';

import { MingaManagerService } from '@app/src/app/services/MingaManager';

import { FormSelectOption } from '@shared/components/form';

import { FormSelectComponent } from '../components/form-select/form-select.component';

/**
 * Minga Search Directive
 * Directive is to be used with form-select component, and lets you search
 * the list of mingas.
 */
@Directive({
  selector: '[mingaSearch]',
  exportAs: 'mingaSearch',
})
export class MingaSearchDirective implements OnDestroy {
  isLoading$: BehaviorSubject<boolean>;
  private _destroyed$ = new ReplaySubject<void>(1);

  constructor(
    private _host: FormSelectComponent,
    private _mingaService: MingaManagerService,
  ) {
    // search text input observable
    _host.searchInput$
      .pipe(
        takeUntil(this._destroyed$),
        distinctUntilChanged(),
        tap(() => this.isLoading$.next(true)),
        debounceTime(800),
      )
      .subscribe(async value => {
        this._mapAndAssignSearchResults(
          await this._mingaService.searchForMinga(value),
        );
        this.isLoading$.next(false);
      });

    this.isLoading$ = this._host.isLoading$;
  }

  ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  private _mapAndAssignSearchResults(searchResults: MingaSearchInfo[]) {
    this._host.options = searchResults.map(item => {
      return {
        label: item.name,
        value: item.hash,
      };
    }) as FormSelectOption<string>[];

    this._host.refreshSelectMenu();
  }
}
