import { Action } from '@ngrx/store';
import { IModerationResult } from 'libs/domain';
import { gateway } from 'libs/generated-grpc-web';

import { IEmailContentSend } from '@app/src/app/content-common/services/EmailContent/types';

export interface IModerationFailedActionOptions {
  contentHash?: string;
  contextHash?: string;
  galleryPhotoUuid?: string;
  moderation: IModerationResult;
  emailContentSend: IEmailContentSend | null;
}

export enum TypeEnum {
  Logout = '[App] logout',
  ChangeMinga = '[App] change Minga',
  ModerationFailed = '[App] Moderation failed',
  ModerationOverrideSuccess = '[App] Moderation override success',
  ShowStudentId = '[App] Show StudentId',
  PeopleUpdated = '[App] People updated',
  PeopleCreated = '[App] People created',
  PeopleArchived = '[App] People archived',
  PeopleImported = '[App] People imported',
}

export abstract class RootAction implements Action {
  abstract readonly type: TypeEnum;
}

export class LogoutAction implements RootAction {
  readonly type = TypeEnum.Logout;
}

export class ShowStudentIdAction implements RootAction {
  readonly type = TypeEnum.ShowStudentId;
}

export class ChangeMingaAction implements RootAction {
  readonly type = TypeEnum.ChangeMinga;
  constructor(public contextHash: string) {}
}

export class ModerationFailedAction implements RootAction {
  readonly type = TypeEnum.ModerationFailed;
  constructor(public data: IModerationFailedActionOptions) {}
}

export class ModerationOverrideSuccess implements RootAction {
  readonly type = TypeEnum.ModerationOverrideSuccess;
  constructor() {}
}

export class PeopleUpdated implements RootAction {
  readonly type = TypeEnum.PeopleUpdated;
  constructor(public person?: gateway.people_pb.Person[]) {}
}

export class PeopleCreated implements RootAction {
  readonly type = TypeEnum.PeopleCreated;
  constructor(public person?: gateway.people_pb.Person[]) {}
}

export class PeopleArchived implements RootAction {
  readonly type = TypeEnum.PeopleArchived;
  constructor(public personHashes?: string[]) {}
}

export class PeopleImported implements RootAction {
  readonly type = TypeEnum.PeopleImported;
  constructor(public personHashes?: string[]) {}
}

export type TypeUnion =
  | LogoutAction
  | ChangeMingaAction
  | ModerationFailedAction
  | ModerationOverrideSuccess
  | PeopleCreated
  | PeopleArchived
  | PeopleUpdated
  | PeopleImported;
