/** Keeps track of various report types.
 * Used on Front End for url routing and report requests.
 * Used on Back End to determine which report to generate.
 */
export enum ReportTypes {
  HALL_PASS_HISTORY = 'hall_pass_history',
  HALL_PASS_OVERDUE = 'hall_pass_overdue',
  HALL_PASS_STAFF = 'hall_pass_staff',
  HALL_PASS_STUDENT = 'hall_pass_student',
  HALL_PASS_SUMMARY = 'hall_pass_summary',
  HALL_PASS_TYPES = 'hall_pass_types',
  CHECKIN = 'checkin',
  CHECKIN_ABSENTEES = 'checkin_absentees',
  CHECKIN_HISTORY = 'checkin_history',
  PBIS_SUMMARY = 'pbis_summary',
  PBIS_STAFF = 'pbis_staff',
  PBIS_STUDENT = 'pbis_student',
  PBIS_TYPES = 'pbis_types',
  PBIS_HISTORY = 'pbis_history',
  PBIS_CONS_HISTORY = 'pbis_cons_history',
  PBIS_CONS_OVERDUE = 'pbis_cons_overdue',
  PBIS_CONS_TYPES = 'pbis_cons_types',
  POINTS_SUMMARY = 'points_summary',
  POINTS_TEAM_SUMMARY = 'points_team_summary',
  POINTS_REWARDS_REDEEMED = 'points_rewards',
  POINTS_HISTORY = 'points_history',
  FLEX_PERIOD_UNREGISTERED = 'flex_period_unregistered',
  FLEX_PERIOD_REGISTERED = 'flex_period_registered',
  FLEX_ACTIVITIES = 'flex_activities',
  FLEX_STUDENTS = 'flex_students',
  FLEX_STAFF = 'flex-staff',
  POINTS_REDEEMED = 'points_redeemed',
  PBIS_AUTOMATION_COUNT = 'pbis_automation_counter',
  EVENT_PEOPLE = 'event_people',
}

/**
 * Used to determine which filter is on a proto request
 */
export enum ReportFilters {
  HALL_PASS = 1,
  CHECK_IN,
  PBIS,
  POINTS,
  FLEX,
  EVENT,
}

export const GetReportFilterCase = (type: ReportTypes): ReportFilters => {
  if (
    type === ReportTypes.HALL_PASS_HISTORY ||
    type === ReportTypes.HALL_PASS_OVERDUE ||
    type === ReportTypes.HALL_PASS_STUDENT ||
    type === ReportTypes.HALL_PASS_STAFF ||
    type === ReportTypes.HALL_PASS_SUMMARY ||
    type === ReportTypes.HALL_PASS_TYPES
  ) {
    return ReportFilters.HALL_PASS;
  } else if (
    type === ReportTypes.CHECKIN ||
    type === ReportTypes.CHECKIN_ABSENTEES ||
    type === ReportTypes.CHECKIN_HISTORY
  ) {
    return ReportFilters.CHECK_IN;
  } else if (
    type === ReportTypes.PBIS_SUMMARY ||
    type === ReportTypes.PBIS_STAFF ||
    type === ReportTypes.PBIS_STUDENT ||
    type === ReportTypes.PBIS_TYPES ||
    type === ReportTypes.PBIS_HISTORY ||
    type === ReportTypes.PBIS_CONS_HISTORY ||
    type === ReportTypes.PBIS_CONS_OVERDUE ||
    type === ReportTypes.PBIS_CONS_TYPES
  ) {
    return ReportFilters.PBIS;
  } else if (
    type === ReportTypes.POINTS_SUMMARY ||
    type === ReportTypes.POINTS_TEAM_SUMMARY ||
    type === ReportTypes.POINTS_REWARDS_REDEEMED ||
    type === ReportTypes.POINTS_HISTORY ||
    type === ReportTypes.POINTS_REDEEMED
  ) {
    return ReportFilters.POINTS;
  } else if (
    type === ReportTypes.FLEX_PERIOD_UNREGISTERED ||
    type === ReportTypes.FLEX_PERIOD_REGISTERED ||
    type === ReportTypes.FLEX_ACTIVITIES ||
    type === ReportTypes.FLEX_STUDENTS ||
    type === ReportTypes.FLEX_STAFF
  ) {
    return ReportFilters.FLEX;
  } else if (type === ReportTypes.EVENT_PEOPLE) {
    return ReportFilters.EVENT;
  } else {
    throw new Error('Invalid report type');
  }
};
