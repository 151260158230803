// source: gateway/scraper.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

import * as jspb from 'google-protobuf';
export namespace SiteMetadata {
  export interface AsObject {
    audio: string;
    author: string;
    date: string;
    description: string;
    video: string;
    image: string;
    lang: string;
    logo: string;
    publisher: string;
    title: string;
    url: string;
  }
}
export class SiteMetadata extends jspb.Message {
  static readonly displayName = "SiteMetadata";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): SiteMetadata.AsObject {
    return SiteMetadata.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!SiteMetadata} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: SiteMetadata) {
    var f: any;
    var obj: any = {
      audio: jspb.Message.getFieldWithDefault(msg, 1, ""),
      author: jspb.Message.getFieldWithDefault(msg, 2, ""),
      date: jspb.Message.getFieldWithDefault(msg, 3, ""),
      description: jspb.Message.getFieldWithDefault(msg, 4, ""),
      video: jspb.Message.getFieldWithDefault(msg, 5, ""),
      image: jspb.Message.getFieldWithDefault(msg, 6, ""),
      lang: jspb.Message.getFieldWithDefault(msg, 7, ""),
      logo: jspb.Message.getFieldWithDefault(msg, 8, ""),
      publisher: jspb.Message.getFieldWithDefault(msg, 9, ""),
      title: jspb.Message.getFieldWithDefault(msg, 10, ""),
      url: jspb.Message.getFieldWithDefault(msg, 11, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!SiteMetadata}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new SiteMetadata;
    return SiteMetadata.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!SiteMetadata} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!SiteMetadata}
   */
  static deserializeBinaryFromReader(msg: SiteMetadata, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setAudio(value1);
        break;
      case 2:
        var value2 = /** @type {string} */ (reader.readString());
        msg.setAuthor(value2);
        break;
      case 3:
        var value3 = /** @type {string} */ (reader.readString());
        msg.setDate(value3);
        break;
      case 4:
        var value4 = /** @type {string} */ (reader.readString());
        msg.setDescription(value4);
        break;
      case 5:
        var value5 = /** @type {string} */ (reader.readString());
        msg.setVideo(value5);
        break;
      case 6:
        var value6 = /** @type {string} */ (reader.readString());
        msg.setImage(value6);
        break;
      case 7:
        var value7 = /** @type {string} */ (reader.readString());
        msg.setLang(value7);
        break;
      case 8:
        var value8 = /** @type {string} */ (reader.readString());
        msg.setLogo(value8);
        break;
      case 9:
        var value9 = /** @type {string} */ (reader.readString());
        msg.setPublisher(value9);
        break;
      case 10:
        var value10 = /** @type {string} */ (reader.readString());
        msg.setTitle(value10);
        break;
      case 11:
        var value11 = /** @type {string} */ (reader.readString());
        msg.setUrl(value11);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!SiteMetadata} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: SiteMetadata, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getAudio();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
    f = message.getAuthor();
    if (f.length > 0) {
      writer.writeString(
        2,
        f
      );
    }
    f = message.getDate();
    if (f.length > 0) {
      writer.writeString(
        3,
        f
      );
    }
    f = message.getDescription();
    if (f.length > 0) {
      writer.writeString(
        4,
        f
      );
    }
    f = message.getVideo();
    if (f.length > 0) {
      writer.writeString(
        5,
        f
      );
    }
    f = message.getImage();
    if (f.length > 0) {
      writer.writeString(
        6,
        f
      );
    }
    f = message.getLang();
    if (f.length > 0) {
      writer.writeString(
        7,
        f
      );
    }
    f = message.getLogo();
    if (f.length > 0) {
      writer.writeString(
        8,
        f
      );
    }
    f = message.getPublisher();
    if (f.length > 0) {
      writer.writeString(
        9,
        f
      );
    }
    f = message.getTitle();
    if (f.length > 0) {
      writer.writeString(
        10,
        f
      );
    }
    f = message.getUrl();
    if (f.length > 0) {
      writer.writeString(
        11,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    SiteMetadata.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string audio = 1;
   * @return {string}
   */
  getAudio(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setAudio(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


  /**
   * optional string author = 2;
   * @return {string}
   */
  getAuthor(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
  };


  /** @param {string} value */
  setAuthor(value: string) {
    jspb.Message.setProto3StringField(this, 2, value);
  }


  /**
   * optional string date = 3;
   * @return {string}
   */
  getDate(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
  };


  /** @param {string} value */
  setDate(value: string) {
    jspb.Message.setProto3StringField(this, 3, value);
  }


  /**
   * optional string description = 4;
   * @return {string}
   */
  getDescription(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
  };


  /** @param {string} value */
  setDescription(value: string) {
    jspb.Message.setProto3StringField(this, 4, value);
  }


  /**
   * optional string video = 5;
   * @return {string}
   */
  getVideo(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
  };


  /** @param {string} value */
  setVideo(value: string) {
    jspb.Message.setProto3StringField(this, 5, value);
  }


  /**
   * optional string image = 6;
   * @return {string}
   */
  getImage(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
  };


  /** @param {string} value */
  setImage(value: string) {
    jspb.Message.setProto3StringField(this, 6, value);
  }


  /**
   * optional string lang = 7;
   * @return {string}
   */
  getLang(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
  };


  /** @param {string} value */
  setLang(value: string) {
    jspb.Message.setProto3StringField(this, 7, value);
  }


  /**
   * optional string logo = 8;
   * @return {string}
   */
  getLogo(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
  };


  /** @param {string} value */
  setLogo(value: string) {
    jspb.Message.setProto3StringField(this, 8, value);
  }


  /**
   * optional string publisher = 9;
   * @return {string}
   */
  getPublisher(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
  };


  /** @param {string} value */
  setPublisher(value: string) {
    jspb.Message.setProto3StringField(this, 9, value);
  }


  /**
   * optional string title = 10;
   * @return {string}
   */
  getTitle(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
  };


  /** @param {string} value */
  setTitle(value: string) {
    jspb.Message.setProto3StringField(this, 10, value);
  }


  /**
   * optional string url = 11;
   * @return {string}
   */
  getUrl(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
  };


  /** @param {string} value */
  setUrl(value: string) {
    jspb.Message.setProto3StringField(this, 11, value);
  }


}
export namespace MetadataScraperGetRequest {
  export interface AsObject {
    url: string;
  }
}
export class MetadataScraperGetRequest extends jspb.Message {
  static readonly displayName = "MetadataScraperGetRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): MetadataScraperGetRequest.AsObject {
    return MetadataScraperGetRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!MetadataScraperGetRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: MetadataScraperGetRequest) {
    var f: any;
    var obj: any = {
      url: jspb.Message.getFieldWithDefault(msg, 1, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!MetadataScraperGetRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new MetadataScraperGetRequest;
    return MetadataScraperGetRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!MetadataScraperGetRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!MetadataScraperGetRequest}
   */
  static deserializeBinaryFromReader(msg: MetadataScraperGetRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setUrl(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!MetadataScraperGetRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: MetadataScraperGetRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getUrl();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    MetadataScraperGetRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string url = 1;
   * @return {string}
   */
  getUrl(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setUrl(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


}
export namespace MetadataScraperGetResponse {
  export interface AsObject {
    siteMetadata?: SiteMetadata.AsObject;
    hidden: boolean;
  }
}
export class MetadataScraperGetResponse extends jspb.Message {
  static readonly displayName = "MetadataScraperGetResponse";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): MetadataScraperGetResponse.AsObject {
    return MetadataScraperGetResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!MetadataScraperGetResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: MetadataScraperGetResponse) {
    var f: any;
    var obj: any = {
      siteMetadata: (f = msg.getSiteMetadata()) && SiteMetadata.toObject(includeInstance, f),
      hidden: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!MetadataScraperGetResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new MetadataScraperGetResponse;
    return MetadataScraperGetResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!MetadataScraperGetResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!MetadataScraperGetResponse}
   */
  static deserializeBinaryFromReader(msg: MetadataScraperGetResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new SiteMetadata;
        reader.readMessage(value1,SiteMetadata.deserializeBinaryFromReader);
        msg.setSiteMetadata(value1);
        break;
      case 2:
        var value2 = /** @type {boolean} */ (reader.readBool());
        msg.setHidden(value2);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!MetadataScraperGetResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: MetadataScraperGetResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getSiteMetadata();
    if (f != null) {
      writer.writeMessage(
        1,
        f,
        SiteMetadata.serializeBinaryToWriter
      );
    }
    f = message.getHidden();
    if (f) {
      writer.writeBool(
        2,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    MetadataScraperGetResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional SiteMetadata site_metadata = 1;
   * @return {?SiteMetadata}
   */
  getSiteMetadata(): SiteMetadata {
    return /** @type{?SiteMetadata} */ (
      jspb.Message.getWrapperField(this, SiteMetadata, 1));
  }


  /** @param {?SiteMetadata|undefined} value */
  setSiteMetadata(value?: SiteMetadata) {
    jspb.Message.setWrapperField(this, 1, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearSiteMetadata() {
    this.setSiteMetadata(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasSiteMetadata(): boolean {
    return jspb.Message.getField(this, 1) != null;
  }


  /**
   * optional bool hidden = 2;
   * @return {boolean}
   */
  getHidden(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
  };


  /** @param {boolean} value */
  setHidden(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 2, value);
  }


}
