import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';
import { FtueKey, stringToFtueKey } from 'libs/domain';
import { gateway } from 'libs/generated-grpc-web';

import { WelcomeActions } from '@app/src/app/ftue/Welcome/actions';

@Injectable({ providedIn: 'root' })
export class FtueService {
  private _seenScreens = new Set<FtueKey>();
  private _initialScreens = [
    // @ts-ignore
    { key: FtueKey.WELCOME_FTUE, action: WelcomeActions.welcomeInit() },
    // @ts-ignore
    { key: FtueKey.DISTRICT_FTUE, action: WelcomeActions.districtInit() },
    // @ts-ignore
    { key: FtueKey.WHATS_NEW, action: WelcomeActions.whatsNew() },
  ];

  constructor(
    private _userPreferencesProto: gateway.preferences_ng_grpc_pb.UserPreferences,
    private _store: Store<any>,
  ) {}

  async init(screens: string[]) {
    if (screens) {
      screens.forEach(screen => {
        const key = stringToFtueKey(screen);
        if (key) {
          this._seenScreens.add(key);
        }
      });

      await this.fireNextScreen();
    }
  }

  async markAsSeen(key: FtueKey) {
    const request = new gateway.preferences_pb.FtueMarkSeenRequest();
    if (key) {
      request.setKey(key);
    }
    await this._userPreferencesProto.ftueMarkSeen(request);
    await this.addSeenScreen(key);
  }

  async addSeenScreen(screen: FtueKey) {
    this._seenScreens.add(screen);
  }

  /**
   * Show the next unseen FtueScreen.
   * @param previousKey - set this screen as locally seen, to avoid loops
   */
  async fireNextScreen(previousKey?: FtueKey) {
    if (previousKey) {
      await this.addSeenScreen(previousKey);
    }

    for (const screen of this._initialScreens) {
      if (!this.hasSeenScreen(screen.key)) {
        this._store.dispatch(screen.action);
        return;
      }
    }
  }

  hasSeenScreen(screen: FtueKey): boolean {
    return this._seenScreens.has(screen);
  }

  resetScreens(): void {
    this._seenScreens.clear();
  }
}
