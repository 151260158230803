import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { RewardRedeemedItem } from 'libs/domain';
import { PointsReportColumns } from 'libs/shared';

import { ReportDatasourceService } from '@app/src/app/components/manager-report/services/report-datasource.service';

import { PmReportsService } from './pm-reports.service';

@Injectable()
export class PmReportsRewardsRedeemedDatasourceService extends ReportDatasourceService<RewardRedeemedItem> {
  constructor(_router: Router, private _pmService: PmReportsService) {
    super(_router, _pmService, PmReportsRewardsRedeemedDatasourceService.name);
    super.setDisplayColumns(PointsReportColumns.REWARDS_REDEEMED);
  }

  async fetch(offset: number, limit: number) {
    return await this._pmService.fetchRewardsRedeemed(
      offset,
      limit,
      this._currentSort,
    );
  }
}
