import { MingaRoleType } from 'libs/domain';
import { Restriction } from 'libs/domain';
import { restriction_pb } from 'libs/generated-grpc-ts';
import { Grade } from 'libs/util';
import { $enum } from 'ts-enum-util';

import { MembershipListMapper } from '../membership_list';

export const toProto = (
  restriction: Restriction,
): restriction_pb.Restriction => {
  const message = new restriction_pb.Restriction();
  if (restriction.id) message.setId(restriction.id);
  if (restriction.roles) message.setRolesList(restriction.roles);
  if (restriction.userLists) message.setUserListsList(restriction.userLists);
  if (restriction.grades) message.setGradesList(restriction.grades);
  if (restriction.groupHashes)
    message.setMingaGroupHashesList(restriction.groupHashes);
  if (restriction.stickerIds) message.setStickerIdsList(restriction.stickerIds);
  if (restriction.restrictedCheckinReasonId)
    message.setRestrictedCheckinReasonId(restriction.restrictedCheckinReasonId);
  if (restriction.membershipList)
    message.setMembershipList(
      MembershipListMapper.toProto(restriction.membershipList),
    );
  if (restriction.membershipListId)
    message.setMembershipListId(restriction.membershipListId);
  return message;
};

export const fromProto = (msg: restriction_pb.Restriction): Restriction => {
  const rolesStrings = msg.getRolesList();
  const roles = rolesStrings.map(r => $enum(MingaRoleType).asValueOrThrow(r));
  const gradeStrings = msg.getGradesList();
  const grades = gradeStrings.map(r => $enum(Grade).asValueOrThrow(r));
  return {
    id: msg.getId() ?? undefined,
    roles,
    userLists: msg.getUserListsList(),
    grades,
    groupHashes: msg.getMingaGroupHashesList(),
    stickerIds: msg.getStickerIdsList(),
    restrictedCheckinReasonId: msg.getRestrictedCheckinReasonId(),
    membershipList: msg.getMembershipList()
      ? MembershipListMapper.fromProto(msg.getMembershipList())
      : undefined,
    membershipListId: msg.getMembershipListId(),
  };
};
