import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';

import { ICheckinReason } from 'libs/domain';
import { Observable, ReplaySubject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

import { ImageViewerModalComponent } from '@shared/components/image-viewer-modal';
import {
  BackDropColor,
  ModalOverlayService,
  ModalOverlayServiceCloseEventType,
} from '@shared/components/modal-overlay';
import { PaginatorComponent } from '@shared/components/paginator';
import { SystemAlertSnackBarService } from '@shared/components/system-alert-snackbar';
import { CheckinService } from '@shared/services/checkin';
import { MediaService } from '@shared/services/media';
import QrCodeService from '@shared/services/qrcode';

import { CheckinManagerReasonsEditComponent } from '../checkin-manager-reasons-edit';
import {
  CheckinManagerReasonsTableMessages,
  DISPLAYED_COLUMNS,
  MOBILE_DISPLAYED_COLUMNS,
} from './checkin-manager-reasons-table.constants';

@Component({
  selector: 'mg-checkin-manager-reasons-table',
  templateUrl: './checkin-manager-reasons-table.component.html',
  styleUrls: ['./checkin-manager-reasons-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckinManagerReasonsTableComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  // Children

  @ViewChild(MatTable) table: MatTable<any>;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(PaginatorComponent)
  paginator: PaginatorComponent;

  // Constants

  public readonly MESSAGES: typeof CheckinManagerReasonsTableMessages =
    CheckinManagerReasonsTableMessages;

  // Subjects

  private _destroyedSubject = new ReplaySubject<void>(1);

  // Table

  public dataSource = new MatTableDataSource<ICheckinReason>([]);
  public readonly displayedColumns$ = this._media.breakpoint$.pipe(
    takeUntil(this._destroyedSubject),
    map(bp => {
      if (['xsmall', 'small'].includes(bp)) {
        return MOBILE_DISPLAYED_COLUMNS;
      } else {
        return DISPLAYED_COLUMNS;
      }
    }),
  );

  // Inputs

  @Input()
  set data(reasons: ICheckinReason[]) {
    this.dataSource.data = reasons;
    this.dataSource.sort = this.sort;
  }
  @Input()
  onNewReasonCreated: Observable<ICheckinReason>;

  // Outputs

  @Output()
  refetchReasons: EventEmitter<string> = new EventEmitter();

  // Component constructor

  constructor(
    private _media: MediaService,
    private _checkinService: CheckinService,
    private _cdr: ChangeDetectorRef,
    private _modalOverlay: ModalOverlayService,
    private _systemAlertSnackBar: SystemAlertSnackBarService,
    private _qrCode: QrCodeService,
  ) {}

  // Lifecycle Hooks

  ngOnInit(): void {
    this.onNewReasonCreated
      .pipe(takeUntil(this._destroyedSubject))
      .subscribe(d => this._handleOnNewReasonCreatedSub(d));
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator.matPaginator;
  }

  ngOnDestroy(): void {
    this._destroyedSubject.next();
    this._destroyedSubject.complete();
  }

  // Public methods

  public async handleOnClickEditReason(
    item: ICheckinReason,
    isNewItem?: boolean,
  ): Promise<void> {
    if (!item) return;
    const { id } = item;
    const overlayData = {
      type: isNewItem ? 'new' : 'existing',
      data: isNewItem ? item : id,
      id: id,
    };

    const overlayRef = this._modalOverlay.open(
      CheckinManagerReasonsEditComponent,
      {
        backdrop: BackDropColor.ORANGE,
        data: overlayData,
        disposeOnNavigation: false,
      },
    );

    overlayRef.afterClosed.subscribe(async response => {
      if (!response) return;

      const { type, data } = response;

      // optimistically adding/updating/removing rows
      switch (type) {
        case ModalOverlayServiceCloseEventType.CREATE: {
          this.dataSource.data = [data.created, ...this.dataSource.data];
          this._systemAlertSnackBar.success(
            'Successfully created new check in reason',
          );
          this._cdr.markForCheck();
          return;
        }
        case ModalOverlayServiceCloseEventType.SUBMIT: {
          this.dataSource.data = this.dataSource.data.map(reason =>
            reason.id === data.updated.id ? data.updated : reason,
          );
          this._systemAlertSnackBar.success(
            'Successfully updated check in reason',
          );
          this._cdr.markForCheck();
          return;
        }
        case ModalOverlayServiceCloseEventType.DELETE: {
          this.dataSource.data = this.dataSource.data.filter(
            reason => reason.id !== data.deleted,
          );
          this._systemAlertSnackBar.success(
            'Successfully deleted check in reason',
          );
          this._cdr.markForCheck();
          return;
        }
        default: {
          return;
        }
      }
    });
  }

  public async openQrViewer(item: ICheckinReason): Promise<void> {
    const url = await this._qrCode.createCheckinReasonQrCode(item.id);
    this._modalOverlay.open(ImageViewerModalComponent, {
      data: {
        imageUrl: url,
        title: 'Print QR Code',
      },
    });
  }

  public async onStatusChange(item: ICheckinReason): Promise<void> {
    const checkinReason: ICheckinReason = item;
    try {
      await this._checkinService.toggleCheckinReasonActive(checkinReason);
      checkinReason.active = !checkinReason.active;
    } catch (error) {
      this._systemAlertSnackBar.error(
        CheckinManagerReasonsTableMessages.SNACK_UPDATE_FAIL,
      );
    }
  }

  public trackById(index: number, item: ICheckinReason) {
    return item ? item.id : index;
  }

  // Private methods

  private _handleOnNewReasonCreatedSub(reason: ICheckinReason): void {
    this.handleOnClickEditReason(reason, true);
  }
}
