import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  HostBinding,
  Input,
} from '@angular/core';

import { day } from 'libs/day';

import { __mingaGetIcon } from '@app/src/app/util/icon';

@Component({
  selector: 'mg-notification-base-image',
  template: '<ng-content></ng-content>',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MgNotificationBaseSlot_Image {}

@Component({
  selector: 'mg-notification-base-title',
  template: '<ng-content></ng-content>',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./MgNotificationBaseTitle.element.scss'],
})
export class MgNotificationBaseSlot_Title {
  /**
   * Class to set whether the title should be clipped to one line
   * @default truncated is set to true
   */
  @HostBinding('class.truncated')
  @Input()
  truncated: boolean = true;
}

@Component({
  selector: 'mg-notification-base-body',
  template: '<ng-content></ng-content>',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MgNotificationBaseSlot_Body {}

@Component({
  selector: 'mg-notification-base-timestamp',
  template: '<ng-content></ng-content>',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MgNotificationBaseSlot_Timestamp {}

@Component({
  selector: 'mg-notification-base-footer',
  template: '<ng-content></ng-content>',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MgNotificationBaseSlot_Footer {}

@Component({
  selector: 'mg-notification-base',
  templateUrl: './MgNotificationBase.element.html',
  styleUrls: ['./MgNotificationBase.element.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MgNotificationBaseElement {
  @ContentChild(MgNotificationBaseSlot_Image, { static: false })
  imageSlot?: MgNotificationBaseSlot_Image;

  @ContentChild(MgNotificationBaseSlot_Title, { static: false })
  titleSlot?: MgNotificationBaseSlot_Title;

  @ContentChild(MgNotificationBaseSlot_Body, { static: false })
  bodySlot?: MgNotificationBaseSlot_Body;

  @ContentChild(MgNotificationBaseSlot_Timestamp, { static: false })
  timestampSlot?: MgNotificationBaseSlot_Timestamp;

  @ContentChild(MgNotificationBaseSlot_Footer, { static: false })
  footerSlot?: MgNotificationBaseSlot_Footer;

  @Input()
  avatarSrc: string = '';

  /**
   * Flag to specify if notification has been viewed or not
   */
  @HostBinding('class.viewed')
  @Input()
  viewed: boolean = false;

  /**
   * Flag to specify if notification has been read or not
   */
  @HostBinding('class.read')
  @Input()
  read: boolean = false;

  /**
   * UTC Timestamp when this notification was sent
   */
  @Input()
  timestamp: number = 0;

  /**
   * Name of icon or url to image to display as icon
   */
  @Input()
  icon: string = '';

  /**
   * Icon color
   */
  @Input()
  iconColor: string = '';

  get hasAvatar(): boolean {
    return !!this.avatarSrc;
  }

  constructor() {}

  renderTimestamp(timestamp: number) {
    if (!timestamp) return '';

    return day(timestamp).fromNow();
  }
}
