import { Injectable, OnDestroy } from '@angular/core';

import { UserList } from 'libs/domain';
import { user_list_ng_grpc_pb, user_list_pb } from 'libs/generated-grpc-web';
import { Observable, ReplaySubject } from 'rxjs';
import { map, startWith, takeUntil } from 'rxjs/operators';

import { SystemAlertModalService } from '@shared/components/system-alert-modal';
import { SystemAlertSnackBarService } from '@shared/components/system-alert-snackbar';
import { ReportActionService } from '@shared/services/reports';

@Injectable()
export class PeopleUserListsActionService
  extends ReportActionService<UserList>
  implements OnDestroy
{
  // Subjects

  private _destroyedSubject = new ReplaySubject<void>(1);

  public readonly homeroomSelectionState$: Observable<
    'all-homeroom' | 'none-homeroom' | 'mixed'
  > = this.selectionCount$.pipe(
    takeUntil(this._destroyedSubject),
    startWith(0), // needed for proper rendering during first selection
    map(() => {
      const selection = this.getSelection();

      if (selection.length === 0) return 'none-homeroom';

      const allHomeroom = selection.every(item => item.isHomeroom === true);
      const noneHomeroom = selection.every(item => item.isHomeroom === false);

      if (allHomeroom) {
        return 'all-homeroom';
      } else if (noneHomeroom) {
        return 'none-homeroom';
      } else {
        return 'mixed';
      }
    }),
  );

  // Constructor

  constructor(
    public snackbar: SystemAlertSnackBarService,
    public alertModal: SystemAlertModalService,
    private _userLists: user_list_ng_grpc_pb.UserLists,
  ) {
    super(snackbar, alertModal);
  }

  // Lifecycle Hooks

  ngOnDestroy(): void {
    this._destroyedSubject.next();
    this._destroyedSubject.complete();
  }

  // Abstract Implementations

  public setTotalSelectable(total: number): void {
    this.totalSelectable = total;
  }

  // Public Methods

  public async setAsHomeroom(enableHomeroom: boolean) {
    const data = this.getSelection().map(list => list.id);

    const request = new user_list_pb.SetHomeroomRequest();
    request.setIdsList(data);
    request.setEnableHomeroom(enableHomeroom);

    await this._userLists.setHomeroom(request);

    return data;
  }
}
