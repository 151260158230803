import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

import * as day from 'dayjs';
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ClientDatePreset } from '@modules/minga-manager/components/mm-date-presets/types/mm-date-presets.types';

import { BottomSheetEventType } from '@shared/components/bottom-sheet/bottom-sheet.types';
import { BottomSheetService } from '@shared/components/bottom-sheet/services/bottom-sheet.service';
import { MediaService } from '@shared/services/media';

import { FormErrorMessages } from '../../constants';
import { FormDateRangeDesktopComponent } from './components/form-date-range-desktop/form-date-range-desktop.component';
import { FormDateRangeMobileComponent } from './components/form-date-range-mobile/form-date-range-mobile.component';
import {
  DATE_PICKER_DEFAULT_PRESETS,
  DatePickerDefaultPresetKey,
} from './form-date-range.constants';
import {
  RangeBottomSheetData,
  RangeBottomSheetResponse,
} from './form-date-range.types';
import { initializeRange } from './form-date-range.utils';
import { RangeResponseData } from './services/form-range-abstract';
import { FormRangeService } from './services/form-range.service';

type DateInput = 'start' | 'end';

@Component({
  selector: 'mg-form-date-range',
  templateUrl: './form-date-range.component.html',
  styleUrls: ['./form-date-range.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormDateRangeComponent implements OnInit, OnDestroy {
  @ViewChild('startInput', { static: true })
  public startInput: ElementRef;
  @ViewChild('endInput', { static: true })
  public endInput: ElementRef;

  public overlayRef: OverlayRef;
  @ViewChild('calendarOverlay') calendarOverlay: TemplateRef<any>;
  @ViewChild('container', { static: true }) container: ElementRef;

  public presets: ClientDatePreset[] = DATE_PICKER_DEFAULT_PRESETS.map(
    preset => ({
      id: preset.value,
      name: preset.label,
      active: true,
      startDate: preset.start(),
      endDate: preset.end(),
      endDateIsCurrentDay: false,
      isDefaultPreset: true,
    }),
  );

  // Constants
  public readonly FORM_ERROR_MESSAGES = FormErrorMessages;

  // Cleanup
  private _destroyedSubject = new ReplaySubject<void>(1);

  private _mobileFlowSubject = new BehaviorSubject<boolean>(false);

  // State
  private _overlayRef: OverlayRef;
  public mobileFlow$ = this._mobileFlowSubject.asObservable();
  public rangeGroup = initializeRange();
  private readonly _inputIsFocusedSubject =
    new BehaviorSubject<DateInput | null>(null);
  public readonly inputIsFocused$ = this._inputIsFocusedSubject.asObservable();

  // initial range on init
  private _initialRange = {
    start: null,
    end: null,
  };

  // last successfully selected valid date range
  private _lastCommitedRange = {
    start: null,
    end: null,
  };

  // Inputs
  @Input() format = 'MMM D, YYYY';
  @Input() hint: string;
  @Input() range: UntypedFormGroup;

  /** Set mininum date user select */
  @Input() minDate: day.Dayjs;
  /** Set maximum date user can select */
  @Input() maxDate: day.Dayjs;
  /**
   * Unique id for things like analytics and testing to hook into
   * Important to note changing this could break either of those
   */
  @Input() id: string;
  @Input() hidePresets = false;
  @Input() startPlaceholder?: string;
  @Input() endPlaceholder?: string;
  /**
   * When supplied will be shown instead of the default/custom presets
   */
  @Input() customPresets?: DatePickerDefaultPresetKey[];
  @Input() disabled: boolean;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['range']) {
      this.rangeGroup = this.range;

      if (
        !this._initialRange.start &&
        this.range.value.start &&
        this.range.value.end
      ) {
        this._initialRange = {
          start: this.range.value.start,
          end: this.range.value.end,
        };
      }
    }

    if (changes['disabled']) {
      if (this.disabled) {
        this.rangeGroup.disable();
      } else {
        this.rangeGroup.enable();
      }
    }
  }

  /** Component Constructor */
  constructor(
    private _mediaService: MediaService,
    private _bottomSheet: BottomSheetService<
      RangeBottomSheetData,
      RangeBottomSheetResponse
    >,
    private _cdr: ChangeDetectorRef,
    private _overlay: Overlay,
    private _rangeService: FormRangeService,
  ) {}

  ngOnInit(): void {
    this._handleMobileFlow();
    this._handlePickerRangeChange();

    this.rangeGroup.valueChanges
      .pipe(takeUntil(this._destroyedSubject))
      .subscribe(value => {
        const { start, end } = value;
        // lets save the first valid initial range
        if (!this._initialRange.start && start && end) {
          this._initialRange = {
            start,
            end,
          };
        }
        // needed to reflect changes in picker ui
        this._cdr.markForCheck();
      });
  }

  ngOnDestroy(): void {
    this._destroyedSubject.next();
    this._destroyedSubject.complete();
    this._inputIsFocusedSubject.complete();
  }

  public openCalendar(): void {
    this._inputIsFocusedSubject.next('start');

    if (this._mobileFlowSubject.value) {
      this._openMobileCalendar();
    } else {
      this._openDesktopCalendar();
    }
  }

  private _openMobileCalendar() {
    const ref = this._bottomSheet.open(FormDateRangeMobileComponent, {
      data: {
        range: this.rangeGroup,
        minDate: this.minDate,
        maxDate: this.maxDate,
        hidePresets: this.hidePresets,
        customPresets: this.customPresets,
      },
    });
    ref.afterDismissed().subscribe(response => {
      this._onCalendarClosed(response);
    });
  }

  private _openDesktopCalendar() {
    this._overlayRef = this._overlay.create({
      positionStrategy: this._overlay
        .position()
        .flexibleConnectedTo(this.container)
        .withPositions([
          {
            originX: 'start',
            originY: 'bottom',
            overlayX: 'start',
            overlayY: 'top',
          },
        ]),
      hasBackdrop: true,
      backdropClass: 'cdk-overlay-transparent-backdrop',
      disposeOnNavigation: true,
    });

    const calendarPortal = new ComponentPortal(FormDateRangeDesktopComponent);
    const componentRef = this._overlayRef.attach(calendarPortal);
    componentRef.instance.range = this.rangeGroup;
    componentRef.instance.minDate = this.minDate;
    componentRef.instance.maxDate = this.maxDate;
    componentRef.instance.hidePresets = this.hidePresets;
    componentRef.instance.customPresets = this.customPresets;
    componentRef.instance.onClose.subscribe(data => {
      this._onCalendarClosed(data);
    });

    this._overlayRef.backdropClick().subscribe(() => {
      this._onCalendarClosed({
        type: BottomSheetEventType.CLOSE,
        data: {},
      });
    });

    this._overlayRef.keydownEvents().subscribe(event => {
      if (event.key === 'Escape') {
        this._onCalendarClosed({
          type: BottomSheetEventType.ESC,
          data: {},
        });
      }
    });
  }

  private _onCalendarClosed(data: RangeResponseData) {
    this._closeCalendarOverlay();
    if (data.type === 'submit') {
      this._commitRange(
        this.rangeGroup.get('start').value,
        this.rangeGroup.get('end').value,
      );

      return;
    }

    if (data.type === 'cancel') {
      // On cancel lets revert date to initial state when user visited the page
      const { start, end } = this._initialRange;
      const finalStart = start ? start : day().subtract(7, 'day');
      const finalEnd = end ? end : day();

      this._commitRange(finalStart, finalEnd);
      return;
    }

    if (
      data.type === BottomSheetEventType.CLOSE ||
      data.type === BottomSheetEventType.ESC
    ) {
      this._cancelChanges();
      return;
    }
  }

  private _handleMobileFlow() {
    this._mediaService.breakpoint$
      .pipe(takeUntil(this._destroyedSubject))
      .subscribe(breakpoint => {
        this._mobileFlowSubject.next(breakpoint === 'xsmall');
      });
  }

  private _cancelChanges() {
    const start = this._lastCommitedRange.start || this._initialRange.start;
    const end = this._lastCommitedRange.end || this._initialRange.end;
    this._commitRange(start, end);
  }

  private _commitRange(start: day.Dayjs, end?: day.Dayjs) {
    const range = {
      start: start.startOf('day'),
      end: end ? end.endOf('day') : null,
    };
    this.rangeGroup.setValue(range);
    this.rangeGroup.markAsTouched();
    this.rangeGroup.markAsDirty();

    this._lastCommitedRange = {
      ...range,
    };
    this._cdr.markForCheck();
  }

  private _closeCalendarOverlay() {
    if (!this._overlayRef) return;
    this._overlayRef.detach();
    this._inputIsFocusedSubject.next(null);
    this.startInput?.nativeElement.blur();
    this.endInput?.nativeElement.blur();
  }

  private _handlePickerRangeChange() {
    this._rangeService.rangeChange$
      .pipe(takeUntil(this._destroyedSubject))
      .subscribe(event => {
        const { start, end } = event;
        const focus = end ? 'start' : 'end';
        this._inputIsFocusedSubject.next(focus);
      });
  }
}
