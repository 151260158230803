// source: content/program.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

import * as jspb from 'google-protobuf';
import * as common_delta_pb from '../common/delta_pb';
import * as gateway_moderation_pb from '../gateway/moderation_pb';
import * as gateway_minga_pb from '../gateway/minga_pb';
export namespace ProgramInfo {
  export interface AsObject {
    title: string;
    description: string;
    logo: string;
    bannerList: string[];
    active: boolean;
    permittedRoleTypeList: string[];
  }
}
export class ProgramInfo extends jspb.Message {
  static readonly displayName = "ProgramInfo";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [4,6];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, ProgramInfo.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): ProgramInfo.AsObject {
    return ProgramInfo.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!ProgramInfo} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: ProgramInfo) {
    var f: any;
    var obj: any = {
      title: jspb.Message.getFieldWithDefault(msg, 1, ""),
      description: jspb.Message.getFieldWithDefault(msg, 2, ""),
      logo: jspb.Message.getFieldWithDefault(msg, 3, ""),
      bannerList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
      active: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
      permittedRoleTypeList: (f = jspb.Message.getRepeatedField(msg, 6)) == null ? undefined : f
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!ProgramInfo}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new ProgramInfo;
    return ProgramInfo.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!ProgramInfo} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!ProgramInfo}
   */
  static deserializeBinaryFromReader(msg: ProgramInfo, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setTitle(value1);
        break;
      case 2:
        var value2 = /** @type {string} */ (reader.readString());
        msg.setDescription(value2);
        break;
      case 3:
        var value3 = /** @type {string} */ (reader.readString());
        msg.setLogo(value3);
        break;
      case 4:
        var value4 = /** @type {string} */ (reader.readString());
        msg.addBanner(value4);
        break;
      case 5:
        var value5 = /** @type {boolean} */ (reader.readBool());
        msg.setActive(value5);
        break;
      case 6:
        var value6 = /** @type {string} */ (reader.readString());
        msg.addPermittedRoleType(value6);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!ProgramInfo} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: ProgramInfo, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getTitle();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
    f = message.getDescription();
    if (f.length > 0) {
      writer.writeString(
        2,
        f
      );
    }
    f = message.getLogo();
    if (f.length > 0) {
      writer.writeString(
        3,
        f
      );
    }
    f = message.getBannerList();
    if (f.length > 0) {
      writer.writeRepeatedString(
        4,
        f
      );
    }
    f = message.getActive();
    if (f) {
      writer.writeBool(
        5,
        f
      );
    }
    f = message.getPermittedRoleTypeList();
    if (f.length > 0) {
      writer.writeRepeatedString(
        6,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    ProgramInfo.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string title = 1;
   * @return {string}
   */
  getTitle(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setTitle(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


  /**
   * optional string description = 2;
   * @return {string}
   */
  getDescription(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
  };


  /** @param {string} value */
  setDescription(value: string) {
    jspb.Message.setProto3StringField(this, 2, value);
  }


  /**
   * optional string logo = 3;
   * @return {string}
   */
  getLogo(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
  };


  /** @param {string} value */
  setLogo(value: string) {
    jspb.Message.setProto3StringField(this, 3, value);
  }


  /**
   * repeated string banner = 4;
   * @return {!Array<string>}
   */
  getBannerList(): string[] {
    return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 4));
  };


  /** @param {!Array<string>} value */
  setBannerList(value: string[]) {
    jspb.Message.setField(this, 4, value || []);
  }


  /**
   * @param {string} value
   * @param {number=} opt_index
   */
  addBanner(value: string, opt_index?: number): void {
    jspb.Message.addToRepeatedField(this, 4, value, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearBannerList() {
    this.setBannerList([]);
  }


  /**
   * optional bool active = 5;
   * @return {boolean}
   */
  getActive(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
  };


  /** @param {boolean} value */
  setActive(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 5, value);
  }


  /**
   * repeated string permitted_role_type = 6;
   * @return {!Array<string>}
   */
  getPermittedRoleTypeList(): string[] {
    return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 6));
  };


  /** @param {!Array<string>} value */
  setPermittedRoleTypeList(value: string[]) {
    jspb.Message.setField(this, 6, value || []);
  }


  /**
   * @param {string} value
   * @param {number=} opt_index
   */
  addPermittedRoleType(value: string, opt_index?: number): void {
    jspb.Message.addToRepeatedField(this, 6, value, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearPermittedRoleTypeList() {
    this.setPermittedRoleTypeList([]);
  }


}
export namespace DeltaProgramInfo {
  export interface AsObject {
    title?: common_delta_pb.StringDelta.AsObject;
    description?: common_delta_pb.StringDelta.AsObject;
    logo?: common_delta_pb.StringDelta.AsObject;
    banner?: common_delta_pb.RepeatedStringDelta.AsObject;
    contentHash: string;
    active?: common_delta_pb.BoolDelta.AsObject;
    permittedRoleTypeList: string[];
  }
}
export class DeltaProgramInfo extends jspb.Message {
  static readonly displayName = "DeltaProgramInfo";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [7];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, DeltaProgramInfo.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): DeltaProgramInfo.AsObject {
    return DeltaProgramInfo.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!DeltaProgramInfo} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: DeltaProgramInfo) {
    var f: any;
    var obj: any = {
      title: (f = msg.getTitle()) && common_delta_pb.StringDelta.toObject(includeInstance, f),
      description: (f = msg.getDescription()) && common_delta_pb.StringDelta.toObject(includeInstance, f),
      logo: (f = msg.getLogo()) && common_delta_pb.StringDelta.toObject(includeInstance, f),
      banner: (f = msg.getBanner()) && common_delta_pb.RepeatedStringDelta.toObject(includeInstance, f),
      contentHash: jspb.Message.getFieldWithDefault(msg, 5, ""),
      active: (f = msg.getActive()) && common_delta_pb.BoolDelta.toObject(includeInstance, f),
      permittedRoleTypeList: (f = jspb.Message.getRepeatedField(msg, 7)) == null ? undefined : f
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!DeltaProgramInfo}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new DeltaProgramInfo;
    return DeltaProgramInfo.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!DeltaProgramInfo} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!DeltaProgramInfo}
   */
  static deserializeBinaryFromReader(msg: DeltaProgramInfo, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new common_delta_pb.StringDelta;
        reader.readMessage(value1,common_delta_pb.StringDelta.deserializeBinaryFromReader);
        msg.setTitle(value1);
        break;
      case 2:
        var value2 = new common_delta_pb.StringDelta;
        reader.readMessage(value2,common_delta_pb.StringDelta.deserializeBinaryFromReader);
        msg.setDescription(value2);
        break;
      case 3:
        var value3 = new common_delta_pb.StringDelta;
        reader.readMessage(value3,common_delta_pb.StringDelta.deserializeBinaryFromReader);
        msg.setLogo(value3);
        break;
      case 4:
        var value4 = new common_delta_pb.RepeatedStringDelta;
        reader.readMessage(value4,common_delta_pb.RepeatedStringDelta.deserializeBinaryFromReader);
        msg.setBanner(value4);
        break;
      case 5:
        var value5 = /** @type {string} */ (reader.readString());
        msg.setContentHash(value5);
        break;
      case 6:
        var value6 = new common_delta_pb.BoolDelta;
        reader.readMessage(value6,common_delta_pb.BoolDelta.deserializeBinaryFromReader);
        msg.setActive(value6);
        break;
      case 7:
        var value7 = /** @type {string} */ (reader.readString());
        msg.addPermittedRoleType(value7);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!DeltaProgramInfo} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: DeltaProgramInfo, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getTitle();
    if (f != null) {
      writer.writeMessage(
        1,
        f,
        common_delta_pb.StringDelta.serializeBinaryToWriter
      );
    }
    f = message.getDescription();
    if (f != null) {
      writer.writeMessage(
        2,
        f,
        common_delta_pb.StringDelta.serializeBinaryToWriter
      );
    }
    f = message.getLogo();
    if (f != null) {
      writer.writeMessage(
        3,
        f,
        common_delta_pb.StringDelta.serializeBinaryToWriter
      );
    }
    f = message.getBanner();
    if (f != null) {
      writer.writeMessage(
        4,
        f,
        common_delta_pb.RepeatedStringDelta.serializeBinaryToWriter
      );
    }
    f = message.getContentHash();
    if (f.length > 0) {
      writer.writeString(
        5,
        f
      );
    }
    f = message.getActive();
    if (f != null) {
      writer.writeMessage(
        6,
        f,
        common_delta_pb.BoolDelta.serializeBinaryToWriter
      );
    }
    f = message.getPermittedRoleTypeList();
    if (f.length > 0) {
      writer.writeRepeatedString(
        7,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    DeltaProgramInfo.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional minga.common.StringDelta title = 1;
   * @return {?StringDelta}
   */
  getTitle(): common_delta_pb.StringDelta {
    return /** @type{?StringDelta} */ (
      jspb.Message.getWrapperField(this, common_delta_pb.StringDelta, 1));
  }


  /** @param {?StringDelta|undefined} value */
  setTitle(value?: common_delta_pb.StringDelta) {
    jspb.Message.setWrapperField(this, 1, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearTitle() {
    this.setTitle(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasTitle(): boolean {
    return jspb.Message.getField(this, 1) != null;
  }


  /**
   * optional minga.common.StringDelta description = 2;
   * @return {?StringDelta}
   */
  getDescription(): common_delta_pb.StringDelta {
    return /** @type{?StringDelta} */ (
      jspb.Message.getWrapperField(this, common_delta_pb.StringDelta, 2));
  }


  /** @param {?StringDelta|undefined} value */
  setDescription(value?: common_delta_pb.StringDelta) {
    jspb.Message.setWrapperField(this, 2, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearDescription() {
    this.setDescription(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasDescription(): boolean {
    return jspb.Message.getField(this, 2) != null;
  }


  /**
   * optional minga.common.StringDelta logo = 3;
   * @return {?StringDelta}
   */
  getLogo(): common_delta_pb.StringDelta {
    return /** @type{?StringDelta} */ (
      jspb.Message.getWrapperField(this, common_delta_pb.StringDelta, 3));
  }


  /** @param {?StringDelta|undefined} value */
  setLogo(value?: common_delta_pb.StringDelta) {
    jspb.Message.setWrapperField(this, 3, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearLogo() {
    this.setLogo(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasLogo(): boolean {
    return jspb.Message.getField(this, 3) != null;
  }


  /**
   * optional minga.common.RepeatedStringDelta banner = 4;
   * @return {?RepeatedStringDelta}
   */
  getBanner(): common_delta_pb.RepeatedStringDelta {
    return /** @type{?RepeatedStringDelta} */ (
      jspb.Message.getWrapperField(this, common_delta_pb.RepeatedStringDelta, 4));
  }


  /** @param {?RepeatedStringDelta|undefined} value */
  setBanner(value?: common_delta_pb.RepeatedStringDelta) {
    jspb.Message.setWrapperField(this, 4, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearBanner() {
    this.setBanner(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasBanner(): boolean {
    return jspb.Message.getField(this, 4) != null;
  }


  /**
   * optional string content_hash = 5;
   * @return {string}
   */
  getContentHash(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
  };


  /** @param {string} value */
  setContentHash(value: string) {
    jspb.Message.setProto3StringField(this, 5, value);
  }


  /**
   * optional minga.common.BoolDelta active = 6;
   * @return {?BoolDelta}
   */
  getActive(): common_delta_pb.BoolDelta {
    return /** @type{?BoolDelta} */ (
      jspb.Message.getWrapperField(this, common_delta_pb.BoolDelta, 6));
  }


  /** @param {?BoolDelta|undefined} value */
  setActive(value?: common_delta_pb.BoolDelta) {
    jspb.Message.setWrapperField(this, 6, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearActive() {
    this.setActive(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasActive(): boolean {
    return jspb.Message.getField(this, 6) != null;
  }


  /**
   * repeated string permitted_role_type = 7;
   * @return {!Array<string>}
   */
  getPermittedRoleTypeList(): string[] {
    return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 7));
  };


  /** @param {!Array<string>} value */
  setPermittedRoleTypeList(value: string[]) {
    jspb.Message.setField(this, 7, value || []);
  }


  /**
   * @param {string} value
   * @param {number=} opt_index
   */
  addPermittedRoleType(value: string, opt_index?: number): void {
    jspb.Message.addToRepeatedField(this, 7, value, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearPermittedRoleTypeList() {
    this.setPermittedRoleTypeList([]);
  }


}
export namespace ProgramDetails {
  export interface AsObject {
    programInfo?: ProgramInfo.AsObject;
    mingaCount: number;
    contentCount: number;
  }
}
export class ProgramDetails extends jspb.Message {
  static readonly displayName = "ProgramDetails";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): ProgramDetails.AsObject {
    return ProgramDetails.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!ProgramDetails} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: ProgramDetails) {
    var f: any;
    var obj: any = {
      programInfo: (f = msg.getProgramInfo()) && ProgramInfo.toObject(includeInstance, f),
      mingaCount: jspb.Message.getFieldWithDefault(msg, 2, 0),
      contentCount: jspb.Message.getFieldWithDefault(msg, 3, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!ProgramDetails}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new ProgramDetails;
    return ProgramDetails.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!ProgramDetails} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!ProgramDetails}
   */
  static deserializeBinaryFromReader(msg: ProgramDetails, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new ProgramInfo;
        reader.readMessage(value1,ProgramInfo.deserializeBinaryFromReader);
        msg.setProgramInfo(value1);
        break;
      case 2:
        var value2 = /** @type {number} */ (reader.readInt32());
        msg.setMingaCount(value2);
        break;
      case 3:
        var value3 = /** @type {number} */ (reader.readInt32());
        msg.setContentCount(value3);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!ProgramDetails} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: ProgramDetails, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getProgramInfo();
    if (f != null) {
      writer.writeMessage(
        1,
        f,
        ProgramInfo.serializeBinaryToWriter
      );
    }
    f = message.getMingaCount();
    if (f !== 0) {
      writer.writeInt32(
        2,
        f
      );
    }
    f = message.getContentCount();
    if (f !== 0) {
      writer.writeInt32(
        3,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    ProgramDetails.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional ProgramInfo program_info = 1;
   * @return {?ProgramInfo}
   */
  getProgramInfo(): ProgramInfo {
    return /** @type{?ProgramInfo} */ (
      jspb.Message.getWrapperField(this, ProgramInfo, 1));
  }


  /** @param {?ProgramInfo|undefined} value */
  setProgramInfo(value?: ProgramInfo) {
    jspb.Message.setWrapperField(this, 1, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearProgramInfo() {
    this.setProgramInfo(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasProgramInfo(): boolean {
    return jspb.Message.getField(this, 1) != null;
  }


  /**
   * optional int32 minga_count = 2;
   * @return {number}
   */
  getMingaCount(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
  };


  /** @param {number} value */
  setMingaCount(value: number) {
    jspb.Message.setProto3IntField(this, 2, value);
  }


  /**
   * optional int32 content_count = 3;
   * @return {number}
   */
  getContentCount(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
  };


  /** @param {number} value */
  setContentCount(value: number) {
    jspb.Message.setProto3IntField(this, 3, value);
  }


}
export namespace ProgramSaveResult {
  export interface AsObject {
    contentHash: string;
  }
}
export class ProgramSaveResult extends jspb.Message {
  static readonly displayName = "ProgramSaveResult";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): ProgramSaveResult.AsObject {
    return ProgramSaveResult.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!ProgramSaveResult} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: ProgramSaveResult) {
    var f: any;
    var obj: any = {
      contentHash: jspb.Message.getFieldWithDefault(msg, 1, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!ProgramSaveResult}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new ProgramSaveResult;
    return ProgramSaveResult.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!ProgramSaveResult} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!ProgramSaveResult}
   */
  static deserializeBinaryFromReader(msg: ProgramSaveResult, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setContentHash(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!ProgramSaveResult} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: ProgramSaveResult, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getContentHash();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    ProgramSaveResult.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string content_hash = 1;
   * @return {string}
   */
  getContentHash(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setContentHash(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


}
export namespace ProgramInfoQuery {
  export interface AsObject {
    includeDeleted: boolean;
    programHash: string;
  }
}
export class ProgramInfoQuery extends jspb.Message {
  static readonly displayName = "ProgramInfoQuery";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): ProgramInfoQuery.AsObject {
    return ProgramInfoQuery.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!ProgramInfoQuery} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: ProgramInfoQuery) {
    var f: any;
    var obj: any = {
      includeDeleted: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
      programHash: jspb.Message.getFieldWithDefault(msg, 2, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!ProgramInfoQuery}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new ProgramInfoQuery;
    return ProgramInfoQuery.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!ProgramInfoQuery} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!ProgramInfoQuery}
   */
  static deserializeBinaryFromReader(msg: ProgramInfoQuery, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {boolean} */ (reader.readBool());
        msg.setIncludeDeleted(value1);
        break;
      case 2:
        var value2 = /** @type {string} */ (reader.readString());
        msg.setProgramHash(value2);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!ProgramInfoQuery} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: ProgramInfoQuery, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getIncludeDeleted();
    if (f) {
      writer.writeBool(
        1,
        f
      );
    }
    f = message.getProgramHash();
    if (f.length > 0) {
      writer.writeString(
        2,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    ProgramInfoQuery.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional bool include_deleted = 1;
   * @return {boolean}
   */
  getIncludeDeleted(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
  };


  /** @param {boolean} value */
  setIncludeDeleted(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 1, value);
  }


  /**
   * optional string program_hash = 2;
   * @return {string}
   */
  getProgramHash(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
  };


  /** @param {string} value */
  setProgramHash(value: string) {
    jspb.Message.setProto3StringField(this, 2, value);
  }


}
export namespace ProgramInstanceInfoQuery {
  export interface AsObject {
    programContextHash: string;
  }
}
export class ProgramInstanceInfoQuery extends jspb.Message {
  static readonly displayName = "ProgramInstanceInfoQuery";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): ProgramInstanceInfoQuery.AsObject {
    return ProgramInstanceInfoQuery.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!ProgramInstanceInfoQuery} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: ProgramInstanceInfoQuery) {
    var f: any;
    var obj: any = {
      programContextHash: jspb.Message.getFieldWithDefault(msg, 1, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!ProgramInstanceInfoQuery}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new ProgramInstanceInfoQuery;
    return ProgramInstanceInfoQuery.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!ProgramInstanceInfoQuery} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!ProgramInstanceInfoQuery}
   */
  static deserializeBinaryFromReader(msg: ProgramInstanceInfoQuery, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setProgramContextHash(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!ProgramInstanceInfoQuery} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: ProgramInstanceInfoQuery, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getProgramContextHash();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    ProgramInstanceInfoQuery.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string program_context_hash = 1;
   * @return {string}
   */
  getProgramContextHash(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setProgramContextHash(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


}
export namespace ProgramContentPosition {
  export interface AsObject {
    offset: number;
    relativeOffset: boolean;
  }
}
export class ProgramContentPosition extends jspb.Message {
  static readonly displayName = "ProgramContentPosition";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): ProgramContentPosition.AsObject {
    return ProgramContentPosition.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!ProgramContentPosition} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: ProgramContentPosition) {
    var f: any;
    var obj: any = {
      offset: jspb.Message.getFieldWithDefault(msg, 1, 0),
      relativeOffset: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!ProgramContentPosition}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new ProgramContentPosition;
    return ProgramContentPosition.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!ProgramContentPosition} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!ProgramContentPosition}
   */
  static deserializeBinaryFromReader(msg: ProgramContentPosition, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {number} */ (reader.readUint64());
        msg.setOffset(value1);
        break;
      case 2:
        var value2 = /** @type {boolean} */ (reader.readBool());
        msg.setRelativeOffset(value2);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!ProgramContentPosition} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: ProgramContentPosition, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getOffset();
    if (f !== 0) {
      writer.writeUint64(
        1,
        f
      );
    }
    f = message.getRelativeOffset();
    if (f) {
      writer.writeBool(
        2,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    ProgramContentPosition.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional uint64 offset = 1;
   * @return {number}
   */
  getOffset(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
  };


  /** @param {number} value */
  setOffset(value: number) {
    jspb.Message.setProto3IntField(this, 1, value);
  }


  /**
   * optional bool relative_offset = 2;
   * @return {boolean}
   */
  getRelativeOffset(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
  };


  /** @param {boolean} value */
  setRelativeOffset(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 2, value);
  }


}
export namespace ProgramAddContent {
  export interface AsObject {
    programHash: string;
    contentHash: string;
    contentStartPosition?: ProgramContentPosition.AsObject;
    contentEndPosition?: ProgramContentPosition.AsObject;
  }
}
export class ProgramAddContent extends jspb.Message {
  static readonly displayName = "ProgramAddContent";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): ProgramAddContent.AsObject {
    return ProgramAddContent.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!ProgramAddContent} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: ProgramAddContent) {
    var f: any;
    var obj: any = {
      programHash: jspb.Message.getFieldWithDefault(msg, 1, ""),
      contentHash: jspb.Message.getFieldWithDefault(msg, 2, ""),
      contentStartPosition: (f = msg.getContentStartPosition()) && ProgramContentPosition.toObject(includeInstance, f),
      contentEndPosition: (f = msg.getContentEndPosition()) && ProgramContentPosition.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!ProgramAddContent}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new ProgramAddContent;
    return ProgramAddContent.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!ProgramAddContent} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!ProgramAddContent}
   */
  static deserializeBinaryFromReader(msg: ProgramAddContent, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setProgramHash(value1);
        break;
      case 2:
        var value2 = /** @type {string} */ (reader.readString());
        msg.setContentHash(value2);
        break;
      case 3:
        var value3 = new ProgramContentPosition;
        reader.readMessage(value3,ProgramContentPosition.deserializeBinaryFromReader);
        msg.setContentStartPosition(value3);
        break;
      case 4:
        var value4 = new ProgramContentPosition;
        reader.readMessage(value4,ProgramContentPosition.deserializeBinaryFromReader);
        msg.setContentEndPosition(value4);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!ProgramAddContent} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: ProgramAddContent, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getProgramHash();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
    f = message.getContentHash();
    if (f.length > 0) {
      writer.writeString(
        2,
        f
      );
    }
    f = message.getContentStartPosition();
    if (f != null) {
      writer.writeMessage(
        3,
        f,
        ProgramContentPosition.serializeBinaryToWriter
      );
    }
    f = message.getContentEndPosition();
    if (f != null) {
      writer.writeMessage(
        4,
        f,
        ProgramContentPosition.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    ProgramAddContent.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string program_hash = 1;
   * @return {string}
   */
  getProgramHash(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setProgramHash(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


  /**
   * optional string content_hash = 2;
   * @return {string}
   */
  getContentHash(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
  };


  /** @param {string} value */
  setContentHash(value: string) {
    jspb.Message.setProto3StringField(this, 2, value);
  }


  /**
   * optional ProgramContentPosition content_start_position = 3;
   * @return {?ProgramContentPosition}
   */
  getContentStartPosition(): ProgramContentPosition {
    return /** @type{?ProgramContentPosition} */ (
      jspb.Message.getWrapperField(this, ProgramContentPosition, 3));
  }


  /** @param {?ProgramContentPosition|undefined} value */
  setContentStartPosition(value?: ProgramContentPosition) {
    jspb.Message.setWrapperField(this, 3, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearContentStartPosition() {
    this.setContentStartPosition(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasContentStartPosition(): boolean {
    return jspb.Message.getField(this, 3) != null;
  }


  /**
   * optional ProgramContentPosition content_end_position = 4;
   * @return {?ProgramContentPosition}
   */
  getContentEndPosition(): ProgramContentPosition {
    return /** @type{?ProgramContentPosition} */ (
      jspb.Message.getWrapperField(this, ProgramContentPosition, 4));
  }


  /** @param {?ProgramContentPosition|undefined} value */
  setContentEndPosition(value?: ProgramContentPosition) {
    jspb.Message.setWrapperField(this, 4, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearContentEndPosition() {
    this.setContentEndPosition(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasContentEndPosition(): boolean {
    return jspb.Message.getField(this, 4) != null;
  }


}
export namespace ProgramRemoveContent {
  export interface AsObject {
    programHash: string;
    contentHash: string;
  }
}
export class ProgramRemoveContent extends jspb.Message {
  static readonly displayName = "ProgramRemoveContent";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): ProgramRemoveContent.AsObject {
    return ProgramRemoveContent.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!ProgramRemoveContent} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: ProgramRemoveContent) {
    var f: any;
    var obj: any = {
      programHash: jspb.Message.getFieldWithDefault(msg, 1, ""),
      contentHash: jspb.Message.getFieldWithDefault(msg, 2, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!ProgramRemoveContent}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new ProgramRemoveContent;
    return ProgramRemoveContent.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!ProgramRemoveContent} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!ProgramRemoveContent}
   */
  static deserializeBinaryFromReader(msg: ProgramRemoveContent, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setProgramHash(value1);
        break;
      case 2:
        var value2 = /** @type {string} */ (reader.readString());
        msg.setContentHash(value2);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!ProgramRemoveContent} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: ProgramRemoveContent, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getProgramHash();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
    f = message.getContentHash();
    if (f.length > 0) {
      writer.writeString(
        2,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    ProgramRemoveContent.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string program_hash = 1;
   * @return {string}
   */
  getProgramHash(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setProgramHash(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


  /**
   * optional string content_hash = 2;
   * @return {string}
   */
  getContentHash(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
  };


  /** @param {string} value */
  setContentHash(value: string) {
    jspb.Message.setProto3StringField(this, 2, value);
  }


}
export namespace ProgramMoveContent {
  export interface AsObject {
    programHash: string;
    contentHash: string;
    contentStartPosition?: ProgramContentPosition.AsObject;
    contentEndPosition?: ProgramContentPosition.AsObject;
  }
}
export class ProgramMoveContent extends jspb.Message {
  static readonly displayName = "ProgramMoveContent";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): ProgramMoveContent.AsObject {
    return ProgramMoveContent.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!ProgramMoveContent} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: ProgramMoveContent) {
    var f: any;
    var obj: any = {
      programHash: jspb.Message.getFieldWithDefault(msg, 1, ""),
      contentHash: jspb.Message.getFieldWithDefault(msg, 2, ""),
      contentStartPosition: (f = msg.getContentStartPosition()) && ProgramContentPosition.toObject(includeInstance, f),
      contentEndPosition: (f = msg.getContentEndPosition()) && ProgramContentPosition.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!ProgramMoveContent}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new ProgramMoveContent;
    return ProgramMoveContent.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!ProgramMoveContent} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!ProgramMoveContent}
   */
  static deserializeBinaryFromReader(msg: ProgramMoveContent, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setProgramHash(value1);
        break;
      case 2:
        var value2 = /** @type {string} */ (reader.readString());
        msg.setContentHash(value2);
        break;
      case 3:
        var value3 = new ProgramContentPosition;
        reader.readMessage(value3,ProgramContentPosition.deserializeBinaryFromReader);
        msg.setContentStartPosition(value3);
        break;
      case 4:
        var value4 = new ProgramContentPosition;
        reader.readMessage(value4,ProgramContentPosition.deserializeBinaryFromReader);
        msg.setContentEndPosition(value4);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!ProgramMoveContent} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: ProgramMoveContent, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getProgramHash();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
    f = message.getContentHash();
    if (f.length > 0) {
      writer.writeString(
        2,
        f
      );
    }
    f = message.getContentStartPosition();
    if (f != null) {
      writer.writeMessage(
        3,
        f,
        ProgramContentPosition.serializeBinaryToWriter
      );
    }
    f = message.getContentEndPosition();
    if (f != null) {
      writer.writeMessage(
        4,
        f,
        ProgramContentPosition.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    ProgramMoveContent.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string program_hash = 1;
   * @return {string}
   */
  getProgramHash(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setProgramHash(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


  /**
   * optional string content_hash = 2;
   * @return {string}
   */
  getContentHash(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
  };


  /** @param {string} value */
  setContentHash(value: string) {
    jspb.Message.setProto3StringField(this, 2, value);
  }


  /**
   * optional ProgramContentPosition content_start_position = 3;
   * @return {?ProgramContentPosition}
   */
  getContentStartPosition(): ProgramContentPosition {
    return /** @type{?ProgramContentPosition} */ (
      jspb.Message.getWrapperField(this, ProgramContentPosition, 3));
  }


  /** @param {?ProgramContentPosition|undefined} value */
  setContentStartPosition(value?: ProgramContentPosition) {
    jspb.Message.setWrapperField(this, 3, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearContentStartPosition() {
    this.setContentStartPosition(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasContentStartPosition(): boolean {
    return jspb.Message.getField(this, 3) != null;
  }


  /**
   * optional ProgramContentPosition content_end_position = 4;
   * @return {?ProgramContentPosition}
   */
  getContentEndPosition(): ProgramContentPosition {
    return /** @type{?ProgramContentPosition} */ (
      jspb.Message.getWrapperField(this, ProgramContentPosition, 4));
  }


  /** @param {?ProgramContentPosition|undefined} value */
  setContentEndPosition(value?: ProgramContentPosition) {
    jspb.Message.setWrapperField(this, 4, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearContentEndPosition() {
    this.setContentEndPosition(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasContentEndPosition(): boolean {
    return jspb.Message.getField(this, 4) != null;
  }


}
export namespace ProgramContentSaveResult {
  export interface AsObject {
    contentHash: string;
    moderationResult?: gateway_moderation_pb.ModerationResult.AsObject;
  }
}
export class ProgramContentSaveResult extends jspb.Message {
  static readonly displayName = "ProgramContentSaveResult";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): ProgramContentSaveResult.AsObject {
    return ProgramContentSaveResult.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!ProgramContentSaveResult} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: ProgramContentSaveResult) {
    var f: any;
    var obj: any = {
      contentHash: jspb.Message.getFieldWithDefault(msg, 1, ""),
      moderationResult: (f = msg.getModerationResult()) && gateway_moderation_pb.ModerationResult.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!ProgramContentSaveResult}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new ProgramContentSaveResult;
    return ProgramContentSaveResult.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!ProgramContentSaveResult} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!ProgramContentSaveResult}
   */
  static deserializeBinaryFromReader(msg: ProgramContentSaveResult, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setContentHash(value1);
        break;
      case 3:
        var value3 = new gateway_moderation_pb.ModerationResult;
        reader.readMessage(value3,gateway_moderation_pb.ModerationResult.deserializeBinaryFromReader);
        msg.setModerationResult(value3);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!ProgramContentSaveResult} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: ProgramContentSaveResult, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getContentHash();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
    f = message.getModerationResult();
    if (f != null) {
      writer.writeMessage(
        3,
        f,
        gateway_moderation_pb.ModerationResult.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    ProgramContentSaveResult.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string content_hash = 1;
   * @return {string}
   */
  getContentHash(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setContentHash(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


  /**
   * optional minga.ModerationResult moderation_result = 3;
   * @return {?ModerationResult}
   */
  getModerationResult(): gateway_moderation_pb.ModerationResult {
    return /** @type{?ModerationResult} */ (
      jspb.Message.getWrapperField(this, gateway_moderation_pb.ModerationResult, 3));
  }


  /** @param {?ModerationResult|undefined} value */
  setModerationResult(value?: gateway_moderation_pb.ModerationResult) {
    jspb.Message.setWrapperField(this, 3, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearModerationResult() {
    this.setModerationResult(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasModerationResult(): boolean {
    return jspb.Message.getField(this, 3) != null;
  }


}
export namespace ProgramMingasResult {
  export interface AsObject {
    mingaList: gateway_minga_pb.MingaInfoMinimal.AsObject[];
  }
}
export class ProgramMingasResult extends jspb.Message {
  static readonly displayName = "ProgramMingasResult";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [1];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, ProgramMingasResult.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): ProgramMingasResult.AsObject {
    return ProgramMingasResult.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!ProgramMingasResult} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: ProgramMingasResult) {
    var f: any;
    var obj: any = {
      mingaList: jspb.Message.toObjectList(msg.getMingaList(),
      gateway_minga_pb.MingaInfoMinimal.toObject, includeInstance)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!ProgramMingasResult}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new ProgramMingasResult;
    return ProgramMingasResult.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!ProgramMingasResult} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!ProgramMingasResult}
   */
  static deserializeBinaryFromReader(msg: ProgramMingasResult, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new gateway_minga_pb.MingaInfoMinimal;
        reader.readMessage(value1,gateway_minga_pb.MingaInfoMinimal.deserializeBinaryFromReader);
        msg.addMinga(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!ProgramMingasResult} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: ProgramMingasResult, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getMingaList();
    if (f.length > 0) {
      writer.writeRepeatedMessage(
        1,
        f,
        gateway_minga_pb.MingaInfoMinimal.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    ProgramMingasResult.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * repeated MingaInfoMinimal minga = 1;
   * @return {!Array<!MingaInfoMinimal>}
   */
  getMingaList(): gateway_minga_pb.MingaInfoMinimal[] {
    return /** @type{!Array<!MingaInfoMinimal>} */ (
      jspb.Message.getRepeatedWrapperField(this, gateway_minga_pb.MingaInfoMinimal, 1));
  }


  /** @param {!Array<!MingaInfoMinimal>} value */
  setMingaList(value?: gateway_minga_pb.MingaInfoMinimal[]) {
    jspb.Message.setRepeatedWrapperField(this, 1, value);
  }


  /**
   * @param {!MingaInfoMinimal=} opt_value
   * @param {number=} opt_index
   * @return {!MingaInfoMinimal}
   */
  addMinga(opt_value?: gateway_minga_pb.MingaInfoMinimal, opt_index?: number): gateway_minga_pb.MingaInfoMinimal {
    return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, gateway_minga_pb.MingaInfoMinimal, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearMingaList() {
    this.setMingaList([]);
  }


}
