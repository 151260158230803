import {
  IModeration,
  IModerationHistory,
  IModerationProviderDetails,
} from 'libs/domain';
import { gateway } from 'libs/generated-grpc-ts';

import { DetailedModerationResultWrapper } from './DetailedModerationResultWrapper';

export namespace DetailedModerationResultMapper {
  export function fromIModerationProviderDetailsToProviderDetails(
    details: IModerationProviderDetails,
  ) {
    const message =
      new gateway.moderation_pb.DetailedModerationResult.ProviderDetails();

    details.tags.forEach(tag => {
      const pair = new gateway.moderation_pb.DetailedModerationResult.Pair();

      pair.setKey(tag.key);
      pair.setValue(tag.value);

      message.addTags(pair);
    });
    message.setFileurl(details.fileUrl);
    message.setIndex(details.index);
    details.imageTags.forEach(tag => {
      const pair = new gateway.moderation_pb.DetailedModerationResult.Pair();

      pair.setKey(tag.key);
      pair.setValue(tag.value);

      message.addImageTags(pair);
    });
    if (typeof details.reasonIndex != 'undefined') {
      message.setReasonIndex(details.reasonIndex);
    }

    return message;
  }

  export function fromIModerationHistoryToContentHistory(
    history: IModerationHistory,
  ): gateway.moderation_pb.DetailedModerationResult.ContentHistory {
    const message =
      new gateway.moderation_pb.DetailedModerationResult.ContentHistory();

    if (history.personId) {
      message.setPersonid(history.personId);
    }
    message.setTimestamp(history.timestamp);
    message.setPersonDisplayName(history.personDisplayName);
    message.setReasonindex(history.reason);
    message.setStatus(history.status);

    return message;
  }

  /**
   * Convert IModeration to a protobuf DetailedModerationResult message
   *
   * @param moderation IModeration
   */
  export function fromIModeration(
    moderation: IModeration,
  ): gateway.moderation_pb.DetailedModerationResult {
    const message = new gateway.moderation_pb.DetailedModerationResult();

    message.setTitle(moderation.title);
    message.setParentContextHash(moderation.parentContextHash);
    message.setContentContextHash(moderation.contentContextHash);
    message.setMingaHash(moderation.mingaHash);
    message.setMingaName(moderation.mingaName);
    message.setContentAuthorHash(moderation.contentAuthorHash);
    message.setTimestamp(moderation.timestamp);
    message.setContentAuthorFirstName(moderation.contentAuthorFirstName);
    message.setContentAuthorLastName(moderation.contentAuthorLastName);
    message.setContentType(moderation.type);
    moderation.contentHistory.forEach(history => {
      message.addContentHistory(
        fromIModerationHistoryToContentHistory(history),
      );
    });
    message.setStatus(moderation.status);
    message.setActionContextHash(moderation.actionContextHash);
    message.setGalleryPhotoUuid(moderation.galleryPhotoUuid);

    return message;
  }

  export function toIModeration(
    result: gateway.moderation_pb.DetailedModerationResult,
  ): IModeration {
    return new DetailedModerationResultWrapper(result);
  }
}
