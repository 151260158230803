import { Location } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, NgForm } from '@angular/forms';
import { Router } from '@angular/router';

import { legacy_pb } from 'libs/generated-grpc-web';
import { ReplaySubject } from 'rxjs';

import { AuthService } from '@app/src/app/minimal/services/Auth';
import { RootService } from '@app/src/app/minimal/services/RootService';

import { SystemAlertSnackBarService } from '@shared/components/system-alert-snackbar';

import { LandingService } from '../../services';

@Component({
  selector: 'mg-landing-forgot',
  templateUrl: './landing-forgot.component.html',
  styleUrls: ['./landing-forgot.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LandingForgotComponent implements OnDestroy {
  // Children
  @ViewChild('form', { read: NgForm, static: true })
  form?: NgForm;

  // Clean up

  private readonly _destroyedSubject = new ReplaySubject<void>(1);

  // State
  formGroup = new UntypedFormGroup({
    email: new UntypedFormControl(),
  });

  /** Component constructor */
  constructor(
    public landing: LandingService,
    private _router: Router,
    private _rootService: RootService,
    private _systemAlertSnackBar: SystemAlertSnackBarService,
    private _location: Location,
    private _authService: AuthService,
  ) {}

  ngOnDestroy(): void {
    this._destroyedSubject.next();
    this._destroyedSubject.complete();
  }

  public goBack() {
    this._location.back();
  }

  public async onSubmit() {
    const email = this.landing.email;

    const navPromise = Promise.resolve()
      .then(() => this._authService.forgotPassword(email))
      .then(async response => {
        const status = response.getStatus();
        if (status !== legacy_pb.StatusCode.OK) {
          this._systemAlertSnackBar.error(
            `Failed to reset password. Try again later.`,
          );
          throw new Error('Could not send email');
        }
      })
      // @TODO: Add reset password network call
      // .then(() => firebase.auth().sendPasswordResetEmail(email))
      .then(() => this._router.navigateByUrl('/landing'));
    await this._rootService.addLoadingPromise(navPromise);
    this._systemAlertSnackBar.success(`Email sent to ${email}`);
  }
}
