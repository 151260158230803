<mg-report-table
  matSort
  [noResultsMessage]="noResultMessage"
  [pageSizeOptions]="[100, 300, 500]"
  [dataSourceService]="ds"
  [selectable]="canMultiSelect$ | async"
  [actionService]="actionService"
  [spaceAfterContent]="true"
  [selectionMessage]="actionService.getFlexTimeSelectionMessage()"
  (matSortChange)="ds.sort($event)">
  <mg-role-column [datasource]="ds"></mg-role-column>
  <ng-template #actionBar>
    <mg-btn
      *ngIf="actionService.isSelectionValid()"
      variant="text"
      (click)="actionService.assignActivity()">
      {{ MESSAGES.ASSIGN_LABEL }}
    </mg-btn>
    <mg-btn
      *ngIf="actionService.isSelectionValid()"
      variant="text"
      (click)="actionService.registerToActivity()">
      {{ MESSAGES.REGISTER_LABEL }}
    </mg-btn>
    <mg-btn
      *ngIf="actionService.isSelectionValid()"
      variant="text"
      (click)="actionService.registerToHomeroom()">
      {{ MESSAGES.REGISTER_TO_HOMEROOM }}
    </mg-btn>
  </ng-template>
</mg-report-table>
