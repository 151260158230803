import { IConsequenceSummaryResult } from 'libs/domain';
import { pbis_stats_pb } from 'libs/generated-grpc-ts';

export namespace ConsequenceSummaryMapper {
  export const toProto = (
    item: IConsequenceSummaryResult,
  ): pbis_stats_pb.ConsequenceSummary => {
    const msg = new pbis_stats_pb.ConsequenceSummary();
    if (item.date) {
      msg.setDate(item.date);
    }
    if (item.typeId) {
      msg.setTypeId(item.typeId);
    }
    if (item.month) {
      msg.setMonth(item.month);
    }
    if (item.hour) {
      msg.setHour(item.hour);
    }
    if (item.week) {
      msg.setWeek(item.week);
    }
    if (item.total) {
      msg.setTotal(item.total);
    }
    if (item.typeName) {
      msg.setTypeName(item.typeName);
    }
    if (item.typeCategory) {
      msg.setTypeCategory(item.typeCategory);
    }
    if (item.year) {
      msg.setYear(item.year);
    }
    if (item.totalActive) msg.setTotalActive(item.totalActive);
    if (item.totalComplete) msg.setTotalComplete(item.totalComplete);
    if (item.totalOverdue) msg.setTotalOverdue(item.totalOverdue);
    return msg;
  };
  export const fromProto = (
    msg: pbis_stats_pb.ConsequenceSummary,
  ): IConsequenceSummaryResult => {
    const typeName = msg.getTypeName();
    const typeCategory = msg.getTypeCategory();
    const date = msg.getDate() as any;
    const hour = msg.getHour();
    const month = msg.getMonth();
    const total = msg.getTotal();
    const typeId = msg.getTypeId();
    const week = msg.getWeek();
    const year = msg.getYear();
    const totalActive = msg.getTotalActive();
    const totalComplete = msg.getTotalComplete();
    const totalOverdue = msg.getTotalOverdue();
    return {
      typeName,
      typeCategory,
      date,
      hour,
      month,
      total,
      typeId,
      week,
      year,
      totalActive,
      totalComplete,
      totalOverdue,
    };
  };
}
