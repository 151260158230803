export enum ContentStatus {
  OVERRIDDEN = 'OVERRIDDEN',
  RESOLVED = 'RESOLVED',
  REPORTED = 'REPORTED',
  DELETED = 'DELETED',
  BLOCKED = 'BLOCKED',
}

export namespace ContentStatusUtil {
  export function hasReason(status: ContentStatus) {
    return status == ContentStatus.REPORTED || status == ContentStatus.BLOCKED;
  }
}
