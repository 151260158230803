import { day } from 'libs/day';
import {
  FtmReportFilters,
  FtmReportFilterType,
  IReportPerson,
} from 'libs/domain';
import { ReportTypes } from 'libs/domain';

import { Person } from '@app/src/app/people';

import { SelectFieldsOption } from '@shared/components/select-fields';

export enum FtmReportsMessages {
  BUTTON_APPLY_FILTER = 'Apply filters',
  BUTTON_CLEAR_FILTER = 'Clear filters',
  SELECT_ACTIVITY = 'Activity',
  SELECT_PERIOD = 'Period',
  SELECT_CHECKIN_STATUS = 'Status',
  SELECT_LABEL_USER_LIST = 'User list',
  UNREGISTERED_NO_PERIOD_ERROR = 'Need at least one period selected to find unregistered students',

  SHOW_FILTERS = 'Show filters',
  HIDE_FILTERS = 'Hide filters',
}

export enum FtmReportTypes {
  HISTORY = 'FlexTime History Report',
  UNREGISTERED = 'FlexTime Unregistered Report',
  ACTIVITIES = 'Activities Report',
  STUDENTS = 'Students Report',
  STAFF = 'Staff Report',
}

export const FtmReportPageOptions: SelectFieldsOption<ReportTypes>[] = [
  { value: ReportTypes.FLEX_PERIOD_REGISTERED, label: FtmReportTypes.HISTORY },
  {
    value: ReportTypes.FLEX_PERIOD_UNREGISTERED,
    label: FtmReportTypes.UNREGISTERED,
  },
  { value: ReportTypes.FLEX_ACTIVITIES, label: FtmReportTypes.ACTIVITIES },
  { value: ReportTypes.FLEX_STUDENTS, label: FtmReportTypes.STUDENTS },
  { value: ReportTypes.FLEX_STAFF, label: FtmReportTypes.STAFF },
];

// Small change for FE to utilize Person Search return type
export type FtmReportFilterForService = Omit<FtmReportFilters, 'registrant'> & {
  registrant: (Person & IReportPerson)[];
};

export const FTM_REPORTS_FILTER_INIT_STATE: FtmReportFilterForService = {
  periods: [],
  registrant: [],
  activities: [],
  startDate: day().subtract(7, 'd').startOf('day'),
  endDate: day().endOf('day'),
  userList: [],
};

export const FTM_REPORTS_FILTER_NON_ARRAY_TYPES = [
  FtmReportFilterType.CHECKIN_STATUS,
  FtmReportFilterType.START_DATE,
  FtmReportFilterType.END_DATE,
];
