import { ChallengeApprovalState } from 'libs/domain';
import {
  stream_pb,
  challenge_response_pb,
  gateway,
} from 'libs/generated-grpc-ts';

export namespace ChallengeResponseSummaryItemMapper {
  /**
   * Convert a summary item to a challenge response card (partially). Useful
   * for showing a response card while it's still being loaded.
   */
  export function toChallengeResponseCard(
    summary: gateway.content_views_pb.ChallengeResponseSummaryItem.AsObject,
  ): challenge_response_pb.ChallengeResponseCard.AsObject {
    return {
      contentHash: '',
      contextHash: summary.contentContextHash,
      body: summary.body,
      ownerContextHash: '',
      backgroundBannerHash: '',
      backgroundColor: '',
      type: '',
      privacy: '',
      likeCount: 0,
      commentCount: 0,
      commentListPage: 0,
      totalCommentCount: 0,
      commentList: [],
      authorPersonView: summary.authorPersonView,
      featuredImage: summary.featuredImage,
      color: 'RED',
      approved: ChallengeApprovalState.PENDING,
    };
  }

  export function toStreamChallengeResponseCardResponse(
    summary: gateway.content_views_pb.ChallengeResponseSummaryItem.AsObject,
    index: number,
  ): challenge_response_pb.StreamChallengeResponseCardResponse.AsObject {
    const streamResp: challenge_response_pb.StreamChallengeResponseCardResponse.AsObject =
      {
        item: toChallengeResponseCard(summary),
        itemMetadata: {
          index,
          id: summary.contentContextHash,
          placeholder: false,
          position: stream_pb.StreamItemPosition.UPSERT,
        },
      };

    return streamResp;
  }
}
