// GENERATED CODE -- DO NOT EDIT!

import {grpc} from '@improbable-eng/grpc-web';

import * as gateway_moderation_pb from '../gateway/moderation_pb';
import * as common_legacy_pb from '../common/legacy_pb';
import * as common_stream_pb from '../common/stream_pb';
import * as image_image_pb from '../image/image_pb';

export class AdminModerationManager {
  static readonly serviceName = "minga.AdminModerationManager";

  static readonly StreamDetailedModerationResults = {
    methodName: "StreamDetailedModerationResults",
    service: AdminModerationManager,
    requestStream: false,
    responseStream: true,
    requestType: common_stream_pb.StreamID,
    responseType: gateway_moderation_pb.StreamDetailedModerationResult,
  };

  static readonly StreamDetailedModerationResultsControl = {
    methodName: "StreamDetailedModerationResultsControl",
    service: AdminModerationManager,
    requestStream: false,
    responseStream: false,
    requestType: common_stream_pb.StreamControl,
    responseType: common_stream_pb.StreamControlResponse,
  };

}

export class AdminModerationManagerClient {
  streamDetailedModerationResults() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  streamDetailedModerationResultsControl() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
}
