import { Injectable, NgZone } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { grpc } from '@improbable-eng/grpc-web';

import {
  StreamControl,
  StreamControlResponse,
  StreamID,
} from '../common/stream_pb';
import {
  BroadcastNotificationRequest,
  BroadcastNotificationResponse,
  GetNotificationsRequest,
  GetNotificationsResponse,
  LoadUnviewedNotificationsRequest,
  LoadUnviewedNotificationsResponse,
  MarkNotificationsReadRequest,
  MarkNotificationsReadResponse,
  MarkNotificationsViewedRequest,
  MarkNotificationsViewedResponse,
  NotificationListenRequest,
  NotificationListenResponse,
  StreamNotificationsResponse,
  UpdateDeviceRequest,
  UpdateDeviceResponse,
} from '../gateway/notification_pb';

import {
  NotificationManager as __NotificationManager,
  NotificationToken as __NotificationToken,
} from '../gateway/notification_pb_service';

@Injectable({providedIn: 'root'})
export class NotificationManager {

  constructor(private _ngZone: NgZone) {
  }

  sendBroadcast(request: BroadcastNotificationRequest): Promise<BroadcastNotificationResponse>;
  sendBroadcast(request: BroadcastNotificationRequest, metadata: grpc.Metadata): Promise<BroadcastNotificationResponse>;
  sendBroadcast(request: BroadcastNotificationRequest, callback: (err: any|null, response: BroadcastNotificationResponse, metadata: grpc.Metadata) => void): void;
  sendBroadcast(request: BroadcastNotificationRequest, metadata: grpc.Metadata, callback: (err: any|null, response: BroadcastNotificationResponse, metadata: grpc.Metadata) => void): void;

  sendBroadcast(request: BroadcastNotificationRequest, arg1?: grpc.Metadata|((err: any|null, response: BroadcastNotificationResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: BroadcastNotificationResponse, metadata: grpc.Metadata) => void): Promise<BroadcastNotificationResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<BroadcastNotificationResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__NotificationManager.sendBroadcast, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  listen(request: NotificationListenRequest): {cancel():void;close():void}&Observable<NotificationListenResponse>;
  listen(request: NotificationListenRequest, metadata: grpc.Metadata): {cancel():void;close():void}&Observable<NotificationListenResponse>;
  listen(request: NotificationListenRequest, onMessage: (message?: NotificationListenResponse) => void,onError?: (err: any) => void,onEnd?: (code: number, msg: string|undefined, metadata: grpc.Metadata) => void): void;
  listen(request: NotificationListenRequest, metadata: grpc.Metadata, onMessage: (message?: NotificationListenResponse) => void,onError?: (err: any) => void,onEnd?: (code: number, msg: string|undefined, metadata: grpc.Metadata) => void): void;

  listen(request: NotificationListenRequest, arg1?: grpc.Metadata|((message?: NotificationListenResponse) => void), arg2?: ((message?: NotificationListenResponse) => void)|((err: any) => void), arg3?: ((err: any) => void)|((code: number, msg: string|undefined, metadata: grpc.Metadata) => void), arg4?: (code: number, msg: string|undefined, metadata: grpc.Metadata) => void): {cancel():void;close():void}&Observable<NotificationListenResponse>|void {
    let ret: any;
    let metadata: any;

    let onMessage: any;
    let onError: any;
    let onEnd: any;
    if(typeof arg1 === 'function') {
      onMessage = arg1;
      onError = arg2;
      onEnd = arg3;
    } else if(typeof arg2 === 'function') {
      metadata = arg1;
      onMessage = arg2;
      onError = arg3;
      onEnd = arg4;
    } else {
      metadata = arg1;
    }

    if(!onMessage) {
      let subject = new Subject<NotificationListenResponse>();
      ret = subject.asObservable();

      onMessage = (response: any) => {
        subject.next(response);
      };

      onError = (err: any) => {
        subject.error(err);
      };

      onEnd = (code: any, msg: any, metadata: any) => {
        subject.complete();
      };

    } else {
      if(!onError) {
        onError = (err: any) => console.error(err);
      }

      if(!onEnd) {
        onEnd = (code: any, msg: any, metadata: any) => {};
      }
    }

    let req = grpc.invoke(__NotificationManager.Listen, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onMessage: response => this._ngZone.run(() => {
        onMessage(response);
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code == grpc.Code.OK) {
          onEnd(code, msg, metadata);
        } else {
          let err = new Error(code + ' ' + (msg||''));
          (<any>err).code = code;
          onError(err);
        }
      })
    });

    (<any>ret).close = () => {
      console.warn('[Angular Grpc] .close() is deprecated use .cancel() instead');
      return req.close();
    };
    (<any>ret).cancel = () => req.close();
    return ret;
  }

  streamNotifications(request: StreamID): {cancel():void;close():void}&Observable<StreamNotificationsResponse>;
  streamNotifications(request: StreamID, metadata: grpc.Metadata): {cancel():void;close():void}&Observable<StreamNotificationsResponse>;
  streamNotifications(request: StreamID, onMessage: (message?: StreamNotificationsResponse) => void,onError?: (err: any) => void,onEnd?: (code: number, msg: string|undefined, metadata: grpc.Metadata) => void): void;
  streamNotifications(request: StreamID, metadata: grpc.Metadata, onMessage: (message?: StreamNotificationsResponse) => void,onError?: (err: any) => void,onEnd?: (code: number, msg: string|undefined, metadata: grpc.Metadata) => void): void;

  streamNotifications(request: StreamID, arg1?: grpc.Metadata|((message?: StreamNotificationsResponse) => void), arg2?: ((message?: StreamNotificationsResponse) => void)|((err: any) => void), arg3?: ((err: any) => void)|((code: number, msg: string|undefined, metadata: grpc.Metadata) => void), arg4?: (code: number, msg: string|undefined, metadata: grpc.Metadata) => void): {cancel():void;close():void}&Observable<StreamNotificationsResponse>|void {
    let ret: any;
    let metadata: any;

    let onMessage: any;
    let onError: any;
    let onEnd: any;
    if(typeof arg1 === 'function') {
      onMessage = arg1;
      onError = arg2;
      onEnd = arg3;
    } else if(typeof arg2 === 'function') {
      metadata = arg1;
      onMessage = arg2;
      onError = arg3;
      onEnd = arg4;
    } else {
      metadata = arg1;
    }

    if(!onMessage) {
      let subject = new Subject<StreamNotificationsResponse>();
      ret = subject.asObservable();

      onMessage = (response: any) => {
        subject.next(response);
      };

      onError = (err: any) => {
        subject.error(err);
      };

      onEnd = (code: any, msg: any, metadata: any) => {
        subject.complete();
      };

    } else {
      if(!onError) {
        onError = (err: any) => console.error(err);
      }

      if(!onEnd) {
        onEnd = (code: any, msg: any, metadata: any) => {};
      }
    }

    let req = grpc.invoke(__NotificationManager.StreamNotifications, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onMessage: response => this._ngZone.run(() => {
        onMessage(response);
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code == grpc.Code.OK) {
          onEnd(code, msg, metadata);
        } else {
          let err = new Error(code + ' ' + (msg||''));
          (<any>err).code = code;
          onError(err);
        }
      })
    });

    (<any>ret).close = () => {
      console.warn('[Angular Grpc] .close() is deprecated use .cancel() instead');
      return req.close();
    };
    (<any>ret).cancel = () => req.close();
    return ret;
  }

  streamNotificationsControl(request: StreamControl): Promise<StreamControlResponse>;
  streamNotificationsControl(request: StreamControl, metadata: grpc.Metadata): Promise<StreamControlResponse>;
  streamNotificationsControl(request: StreamControl, callback: (err: any|null, response: StreamControlResponse, metadata: grpc.Metadata) => void): void;
  streamNotificationsControl(request: StreamControl, metadata: grpc.Metadata, callback: (err: any|null, response: StreamControlResponse, metadata: grpc.Metadata) => void): void;

  streamNotificationsControl(request: StreamControl, arg1?: grpc.Metadata|((err: any|null, response: StreamControlResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: StreamControlResponse, metadata: grpc.Metadata) => void): Promise<StreamControlResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<StreamControlResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__NotificationManager.StreamNotificationsControl, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  markViewed(request: MarkNotificationsViewedRequest): Promise<MarkNotificationsViewedResponse>;
  markViewed(request: MarkNotificationsViewedRequest, metadata: grpc.Metadata): Promise<MarkNotificationsViewedResponse>;
  markViewed(request: MarkNotificationsViewedRequest, callback: (err: any|null, response: MarkNotificationsViewedResponse, metadata: grpc.Metadata) => void): void;
  markViewed(request: MarkNotificationsViewedRequest, metadata: grpc.Metadata, callback: (err: any|null, response: MarkNotificationsViewedResponse, metadata: grpc.Metadata) => void): void;

  markViewed(request: MarkNotificationsViewedRequest, arg1?: grpc.Metadata|((err: any|null, response: MarkNotificationsViewedResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: MarkNotificationsViewedResponse, metadata: grpc.Metadata) => void): Promise<MarkNotificationsViewedResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<MarkNotificationsViewedResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__NotificationManager.MarkViewed, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  markRead(request: MarkNotificationsReadRequest): Promise<MarkNotificationsReadResponse>;
  markRead(request: MarkNotificationsReadRequest, metadata: grpc.Metadata): Promise<MarkNotificationsReadResponse>;
  markRead(request: MarkNotificationsReadRequest, callback: (err: any|null, response: MarkNotificationsReadResponse, metadata: grpc.Metadata) => void): void;
  markRead(request: MarkNotificationsReadRequest, metadata: grpc.Metadata, callback: (err: any|null, response: MarkNotificationsReadResponse, metadata: grpc.Metadata) => void): void;

  markRead(request: MarkNotificationsReadRequest, arg1?: grpc.Metadata|((err: any|null, response: MarkNotificationsReadResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: MarkNotificationsReadResponse, metadata: grpc.Metadata) => void): Promise<MarkNotificationsReadResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<MarkNotificationsReadResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__NotificationManager.MarkRead, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  loadUnviewedNotifications(request: LoadUnviewedNotificationsRequest): Promise<LoadUnviewedNotificationsResponse>;
  loadUnviewedNotifications(request: LoadUnviewedNotificationsRequest, metadata: grpc.Metadata): Promise<LoadUnviewedNotificationsResponse>;
  loadUnviewedNotifications(request: LoadUnviewedNotificationsRequest, callback: (err: any|null, response: LoadUnviewedNotificationsResponse, metadata: grpc.Metadata) => void): void;
  loadUnviewedNotifications(request: LoadUnviewedNotificationsRequest, metadata: grpc.Metadata, callback: (err: any|null, response: LoadUnviewedNotificationsResponse, metadata: grpc.Metadata) => void): void;

  loadUnviewedNotifications(request: LoadUnviewedNotificationsRequest, arg1?: grpc.Metadata|((err: any|null, response: LoadUnviewedNotificationsResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: LoadUnviewedNotificationsResponse, metadata: grpc.Metadata) => void): Promise<LoadUnviewedNotificationsResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<LoadUnviewedNotificationsResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__NotificationManager.LoadUnviewedNotifications, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  getNotifications(request: GetNotificationsRequest): Promise<GetNotificationsResponse>;
  getNotifications(request: GetNotificationsRequest, metadata: grpc.Metadata): Promise<GetNotificationsResponse>;
  getNotifications(request: GetNotificationsRequest, callback: (err: any|null, response: GetNotificationsResponse, metadata: grpc.Metadata) => void): void;
  getNotifications(request: GetNotificationsRequest, metadata: grpc.Metadata, callback: (err: any|null, response: GetNotificationsResponse, metadata: grpc.Metadata) => void): void;

  getNotifications(request: GetNotificationsRequest, arg1?: grpc.Metadata|((err: any|null, response: GetNotificationsResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: GetNotificationsResponse, metadata: grpc.Metadata) => void): Promise<GetNotificationsResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<GetNotificationsResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__NotificationManager.GetNotifications, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

}


@Injectable({providedIn: 'root'})
export class NotificationToken {

  constructor(private _ngZone: NgZone) {
  }

  updateDeviceToken(request: UpdateDeviceRequest): Promise<UpdateDeviceResponse>;
  updateDeviceToken(request: UpdateDeviceRequest, metadata: grpc.Metadata): Promise<UpdateDeviceResponse>;
  updateDeviceToken(request: UpdateDeviceRequest, callback: (err: any|null, response: UpdateDeviceResponse, metadata: grpc.Metadata) => void): void;
  updateDeviceToken(request: UpdateDeviceRequest, metadata: grpc.Metadata, callback: (err: any|null, response: UpdateDeviceResponse, metadata: grpc.Metadata) => void): void;

  updateDeviceToken(request: UpdateDeviceRequest, arg1?: grpc.Metadata|((err: any|null, response: UpdateDeviceResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: UpdateDeviceResponse, metadata: grpc.Metadata) => void): Promise<UpdateDeviceResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<UpdateDeviceResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__NotificationToken.updateDeviceToken, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

}

