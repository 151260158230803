import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  OnInit,
} from '@angular/core';
import { Router } from '@angular/router';

import { gateway } from 'libs/generated-grpc-web';

import { GroupsFacadeService } from '@app/src/app/groups/services';
import { ContentEvents } from '@app/src/app/minimal/services/ContentEvents';
import { IOverlayConfig } from '@app/src/app/misc/overlay';
import { ContentState } from '@app/src/app/services/ContentState';

import { LongCardBaseClass } from '../LongCardBase/LongCardBaseClass';

@Component({
  selector: 'mg-announcement-long-card',
  templateUrl: './AnnouncementLongCard.component.html',
  styleUrls: ['./AnnouncementLongCard.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AnnouncementLongCardComponent
  extends LongCardBaseClass
  implements OnInit
{
  private onOverlayConfig?: (config: IOverlayConfig) => void;
  content?: gateway.content_views_pb.LongAnnouncementCardView.AsObject;

  @Input() showTopNav = true;

  constructor(
    contentEvents: ContentEvents,
    groupFacade: GroupsFacadeService,
    contentState: ContentState,
    router: Router,
  ) {
    super(contentEvents, groupFacade, contentState, router);
  }

  ngOnInit() {
    if (this.content && this.content.ownerGroupHash) {
      this.ownerGroupHash.next(this.content.ownerGroupHash);
    }
    setTimeout(() => {
      if (!this.onOverlayConfig) {
        console.warn(
          'Announcement Long Card is not an overlay. The overlay config may not represent the Announcement Long Card accurately',
        );
      }
    }, 1000);
  }

  registerOnOverlayConfig(fn: (config: IOverlayConfig) => void) {
    this.onOverlayConfig = fn;
    this.onOverlayConfig({
      mobileTransparentNav: true,
      contentBackground: 'transparent',
      fullContentBackground: 'white',
      boxShadow: 'none',
    });
  }
}
