import { Injectable } from '@angular/core';
import { Sort } from '@angular/material/sort';

import {
  FlexRegistrationInfo,
  FtmReportFilterType,
  MingaRoleType,
} from 'libs/domain';
import { ReportTypes } from 'libs/domain';
import {
  gateway,
  flex_time_ng_grpc_pb,
  flex_time_pb as flex_proto,
  stats_report_pb as report_proto,
  stats_report_ng_grpc_pb,
} from 'libs/generated-grpc-web';
import {
  FlexActivityStatsMapper,
  FlexStaffStatsMapper,
  FlexTimeRegistrationMapper as flexRegMapper,
  FlexTimeStudentsMapper,
} from 'libs/shared-grpc';
import { FlexTimeUnregisteredInfoMapper } from 'libs/shared-grpc';
import { FlexFilterMapper } from 'libs/shared-grpc';

import { ReportsService } from '@app/src/app/components/manager-report/services/report-service.service';
import { RootService } from '@app/src/app/minimal/services/RootService';

import {
  FTM_REPORTS_FILTER_INIT_STATE,
  FTM_REPORTS_FILTER_NON_ARRAY_TYPES,
  FtmReportFilterForService,
} from '../constants';

@Injectable()
export class FtmReportsService extends ReportsService<FtmReportFilterForService> {
  constructor(
    _reportManager: stats_report_ng_grpc_pb.StatsReportManager,
    _rootService: RootService,
    private _flexManager: flex_time_ng_grpc_pb.FlexTimeManager,
  ) {
    super(
      FTM_REPORTS_FILTER_INIT_STATE,
      FTM_REPORTS_FILTER_NON_ARRAY_TYPES,
      undefined,
      _reportManager,
      _rootService,
    );
  }

  async getRegistrantsReport(offset = 0, limit = 10, sort?: Sort) {
    const { infos, pageToken } = await this._getReport(
      ReportTypes.FLEX_PERIOD_REGISTERED,
      offset,
      limit,
      sort,
    );
    return {
      items: infos.map(info => flexRegMapper.fromProto(info.getFlexReg())),
      pageToken,
    };
  }

  async getUnregisteredReport(offset = 0, limit = 10, sort?: Sort) {
    const { infos, pageToken } = await this._getReport(
      ReportTypes.FLEX_PERIOD_UNREGISTERED,
      offset,
      limit,
      sort,
    );
    return {
      items: infos.map(info =>
        FlexTimeUnregisteredInfoMapper.fromProto(info.getFlexUnreg()),
      ),
      pageToken,
    };
  }

  async getActivitiesReport(offset = 0, limit = 10, sort?: Sort) {
    const { infos, pageToken } = await this._getReport(
      ReportTypes.FLEX_ACTIVITIES,
      offset,
      limit,
      sort,
    );
    return {
      items: infos.map(info =>
        FlexActivityStatsMapper.fromProto(info.getFlexActivityStats()),
      ),
      pageToken,
    };
  }

  async getAStudentsReport(offset = 0, limit = 10, sort?: Sort) {
    const { infos, pageToken } = await this._getReport(
      ReportTypes.FLEX_STUDENTS,
      offset,
      limit,
      sort,
    );
    return {
      items: infos.map(info =>
        FlexTimeStudentsMapper.fromProto(info.getFlexStudentsStats()),
      ),
      pageToken,
    };
  }

  async getStaffReport(offset = 0, limit = 10, sort?: Sort) {
    const { infos, pageToken } = await this._getReport(
      ReportTypes.FLEX_STAFF,
      offset,
      limit,
      sort,
    );
    return {
      items: infos.map(info =>
        FlexStaffStatsMapper.fromProto(info.getFlexStaffStats()),
      ),
      pageToken,
    };
  }

  _handleSetFilter(filter: FtmReportFilterType, value: any) {
    switch (filter) {
      case FtmReportFilterType.ACTIVITIES: {
        this.filter.activities = value;
        break;
      }
      case FtmReportFilterType.REGISTRANT: {
        this.filter.registrant = value;
        break;
      }
      case FtmReportFilterType.PERIODS: {
        this.filter.periods = value;
        break;
      }
      case FtmReportFilterType.CHECKIN_STATUS: {
        this.filter.checkinStatus = value;
        break;
      }
      case FtmReportFilterType.START_DATE: {
        this.filter.startDate = value;
        break;
      }
      case FtmReportFilterType.END_DATE: {
        this.filter.endDate = value;
        break;
      }
      case FtmReportFilterType.USER_LIST: {
        this.filter.userList = value;
        break;
      }
      default: {
        break;
      }
    }
  }

  protected _mapFiltersToFilterMessage(
    reportType: ReportTypes = ReportTypes.FLEX_PERIOD_REGISTERED,
    offset: number = 0,
    limit?: number,
  ) {
    const onlyOnePeriod = reportType === ReportTypes.FLEX_PERIOD_UNREGISTERED;
    const req = new report_proto.GetOrExportReportRequest();
    const registrant: string[] = [];
    const registerer: string[] = [];
    this.filter.registrant?.forEach(p => {
      if (
        p.roleType === MingaRoleType.STUDENT ||
        p.roleType === MingaRoleType.STUDENT_LEADER
      ) {
        registrant.push(p.hash || p.personHash);
      } else {
        registerer.push(p.hash || p.personHash);
      }
    });
    const filter = {
      ...this.filter,
      registrant,
      registerer,
    };
    const filterMessage = FlexFilterMapper.toProto(
      filter,
      limit,
      offset,
      onlyOnePeriod,
    );
    req.setFlexFilters(filterMessage);
    req.setReportType(reportType);
    return req;
  }

  public async archiveFlexRegistration(
    reg: FlexRegistrationInfo | FlexRegistrationInfo[],
  ) {
    let ftIds: number[] = [];
    if (Array.isArray(reg)) {
      ftIds = reg.map(p => p.id);
    } else {
      ftIds = [reg.id];
    }
    const req = new flex_proto.ArchiveFlexTimeRegistrationRequest();
    req.setIdsList(ftIds);
    await this._flexManager.archiveFlexTimeRegistration(req);
  }

  _convertTableDataForFilter(data: any) {
    if (data.activityId) {
      return data.activityId;
    }
    return super._convertTableDataForFilter(data);
  }
}
