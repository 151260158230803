// source: gateway/banner.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

import * as jspb from 'google-protobuf';
import * as google_protobuf_wrappers_pb from '../google/protobuf/wrappers_pb';
import * as common_legacy_pb from '../common/legacy_pb';
import * as common_stream_pb from '../common/stream_pb';
import * as image_image_pb from '../image/image_pb';
export namespace Banner {
  export interface AsObject {
    image?: image_image_pb.ImageInfo.AsObject;
    headlineOptions?: Banner.HeadlineOptions.AsObject;
    categoryList: Banner.Category[];
    keywordList: string[];
    bannerHash: string;
    archived: boolean;
    type: string;
  }
}
export class Banner extends jspb.Message {
  static readonly displayName = "Banner";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [3,4];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, Banner.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): Banner.AsObject {
    return Banner.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!Banner} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: Banner) {
    var f: any;
    var obj: any = {
      image: (f = msg.getImage()) && image_image_pb.ImageInfo.toObject(includeInstance, f),
      headlineOptions: (f = msg.getHeadlineOptions()) && Banner.HeadlineOptions.toObject(includeInstance, f),
      categoryList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
      keywordList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
      bannerHash: jspb.Message.getFieldWithDefault(msg, 5, ""),
      archived: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
      type: jspb.Message.getFieldWithDefault(msg, 7, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!Banner}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new Banner;
    return Banner.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!Banner} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!Banner}
   */
  static deserializeBinaryFromReader(msg: Banner, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new image_image_pb.ImageInfo;
        reader.readMessage(value1,image_image_pb.ImageInfo.deserializeBinaryFromReader);
        msg.setImage(value1);
        break;
      case 2:
        var value2 = new Banner.HeadlineOptions;
        reader.readMessage(value2,Banner.HeadlineOptions.deserializeBinaryFromReader);
        msg.setHeadlineOptions(value2);
        break;
      case 3:
        var value3 = /** @type {!Array<!Banner.Category>} */ (reader.readPackedEnum());
        msg.setCategoryList(value3);
        break;
      case 4:
        var value4 = /** @type {string} */ (reader.readString());
        msg.addKeyword(value4);
        break;
      case 5:
        var value5 = /** @type {string} */ (reader.readString());
        msg.setBannerHash(value5);
        break;
      case 6:
        var value6 = /** @type {boolean} */ (reader.readBool());
        msg.setArchived(value6);
        break;
      case 7:
        var value7 = /** @type {string} */ (reader.readString());
        msg.setType(value7);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!Banner} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: Banner, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getImage();
    if (f != null) {
      writer.writeMessage(
        1,
        f,
        image_image_pb.ImageInfo.serializeBinaryToWriter
      );
    }
    f = message.getHeadlineOptions();
    if (f != null) {
      writer.writeMessage(
        2,
        f,
        Banner.HeadlineOptions.serializeBinaryToWriter
      );
    }
    f = message.getCategoryList();
    if (f.length > 0) {
      writer.writePackedEnum(
        3,
        f
      );
    }
    f = message.getKeywordList();
    if (f.length > 0) {
      writer.writeRepeatedString(
        4,
        f
      );
    }
    f = message.getBannerHash();
    if (f.length > 0) {
      writer.writeString(
        5,
        f
      );
    }
    f = message.getArchived();
    if (f) {
      writer.writeBool(
        6,
        f
      );
    }
    f = message.getType();
    if (f.length > 0) {
      writer.writeString(
        7,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    Banner.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional ImageInfo image = 1;
   * @return {?ImageInfo}
   */
  getImage(): image_image_pb.ImageInfo {
    return /** @type{?ImageInfo} */ (
      jspb.Message.getWrapperField(this, image_image_pb.ImageInfo, 1));
  }


  /** @param {?ImageInfo|undefined} value */
  setImage(value?: image_image_pb.ImageInfo) {
    jspb.Message.setWrapperField(this, 1, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearImage() {
    this.setImage(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasImage(): boolean {
    return jspb.Message.getField(this, 1) != null;
  }


  /**
   * optional HeadlineOptions headline_options = 2;
   * @return {?Banner.HeadlineOptions}
   */
  getHeadlineOptions(): Banner.HeadlineOptions {
    return /** @type{?Banner.HeadlineOptions} */ (
      jspb.Message.getWrapperField(this, Banner.HeadlineOptions, 2));
  }


  /** @param {?Banner.HeadlineOptions|undefined} value */
  setHeadlineOptions(value?: Banner.HeadlineOptions) {
    jspb.Message.setWrapperField(this, 2, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearHeadlineOptions() {
    this.setHeadlineOptions(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasHeadlineOptions(): boolean {
    return jspb.Message.getField(this, 2) != null;
  }


  /**
   * repeated Category category = 3;
   * @return {!Array<!Banner.Category>}
   */
  getCategoryList(): Banner.Category[] {
    return /** @type {!Array<!Banner.Category>} */ (jspb.Message.getRepeatedField(this, 3));
  };


  /** @param {!Array<!Banner.Category>} value */
  setCategoryList(value: Banner.Category[]) {
    jspb.Message.setField(this, 3, value || []);
  }


  /**
   * @param {!Banner.Category} value
   * @param {number=} opt_index
   */
  addCategory(value: Banner.Category, opt_index?: number): void {
    jspb.Message.addToRepeatedField(this, 3, value, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearCategoryList() {
    this.setCategoryList([]);
  }


  /**
   * repeated string keyword = 4;
   * @return {!Array<string>}
   */
  getKeywordList(): string[] {
    return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 4));
  };


  /** @param {!Array<string>} value */
  setKeywordList(value: string[]) {
    jspb.Message.setField(this, 4, value || []);
  }


  /**
   * @param {string} value
   * @param {number=} opt_index
   */
  addKeyword(value: string, opt_index?: number): void {
    jspb.Message.addToRepeatedField(this, 4, value, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearKeywordList() {
    this.setKeywordList([]);
  }


  /**
   * optional string banner_hash = 5;
   * @return {string}
   */
  getBannerHash(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
  };


  /** @param {string} value */
  setBannerHash(value: string) {
    jspb.Message.setProto3StringField(this, 5, value);
  }


  /**
   * optional bool archived = 6;
   * @return {boolean}
   */
  getArchived(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
  };


  /** @param {boolean} value */
  setArchived(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 6, value);
  }


  /**
   * optional string type = 7;
   * @return {string}
   */
  getType(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
  };


  /** @param {string} value */
  setType(value: string) {
    jspb.Message.setProto3StringField(this, 7, value);
  }


}
export namespace Banner {
export enum Category {
  GENERAL = 0,
  SPORTS = 1,
  SCHOOL_EVENTS = 2,
  ART = 3,
  CAUSES = 4,
  FOOD = 5,
  SEASONAL = 6,
  GROUP = 7,
}
} // namespace Banner
export namespace Banner {
export namespace HeadlineOptions {
  export interface AsObject {
    offsetX: number;
    offsetY: number;
    anchor?: Banner.HeadlineOptions.Anchor;
    align?: Banner.HeadlineOptions.Align;
    fontFamily: string;
    fontColor: string;
    fontWeight: string;
    italic: boolean;
    lineHeight: number;
    minFontSize: number;
    maxFontSize: number;
  }
}
export class HeadlineOptions extends jspb.Message {
  static readonly displayName = "Banner.HeadlineOptions";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): HeadlineOptions.AsObject {
    return HeadlineOptions.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!HeadlineOptions} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: HeadlineOptions) {
    var f: any;
    var obj: any = {
      offsetX: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
      offsetY: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
      anchor: jspb.Message.getFieldWithDefault(msg, 3, 0),
      align: jspb.Message.getFieldWithDefault(msg, 4, 0),
      fontFamily: jspb.Message.getFieldWithDefault(msg, 5, ""),
      fontColor: jspb.Message.getFieldWithDefault(msg, 6, ""),
      fontWeight: jspb.Message.getFieldWithDefault(msg, 7, ""),
      italic: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
      lineHeight: jspb.Message.getFloatingPointFieldWithDefault(msg, 9, 0.0),
      minFontSize: jspb.Message.getFloatingPointFieldWithDefault(msg, 10, 0.0),
      maxFontSize: jspb.Message.getFloatingPointFieldWithDefault(msg, 11, 0.0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!HeadlineOptions}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new HeadlineOptions;
    return HeadlineOptions.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!Banner.HeadlineOptions} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!Banner.HeadlineOptions}
   */
  static deserializeBinaryFromReader(msg: HeadlineOptions, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {number} */ (reader.readFloat());
        msg.setOffsetX(value1);
        break;
      case 2:
        var value2 = /** @type {number} */ (reader.readFloat());
        msg.setOffsetY(value2);
        break;
      case 3:
        var value3 = /** @type {!Banner.HeadlineOptions.Anchor} */ (reader.readEnum());
        msg.setAnchor(value3);
        break;
      case 4:
        var value4 = /** @type {!Banner.HeadlineOptions.Align} */ (reader.readEnum());
        msg.setAlign(value4);
        break;
      case 5:
        var value5 = /** @type {string} */ (reader.readString());
        msg.setFontFamily(value5);
        break;
      case 6:
        var value6 = /** @type {string} */ (reader.readString());
        msg.setFontColor(value6);
        break;
      case 7:
        var value7 = /** @type {string} */ (reader.readString());
        msg.setFontWeight(value7);
        break;
      case 8:
        var value8 = /** @type {boolean} */ (reader.readBool());
        msg.setItalic(value8);
        break;
      case 9:
        var value9 = /** @type {number} */ (reader.readFloat());
        msg.setLineHeight(value9);
        break;
      case 10:
        var value10 = /** @type {number} */ (reader.readFloat());
        msg.setMinFontSize(value10);
        break;
      case 11:
        var value11 = /** @type {number} */ (reader.readFloat());
        msg.setMaxFontSize(value11);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!Banner.HeadlineOptions} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: Banner.HeadlineOptions, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getOffsetX();
    if (f !== 0.0) {
      writer.writeFloat(
        1,
        f
      );
    }
    f = message.getOffsetY();
    if (f !== 0.0) {
      writer.writeFloat(
        2,
        f
      );
    }
    f = message.getAnchor();
    if (f !== 0.0) {
      writer.writeEnum(
        3,
        f
      );
    }
    f = message.getAlign();
    if (f !== 0.0) {
      writer.writeEnum(
        4,
        f
      );
    }
    f = message.getFontFamily();
    if (f.length > 0) {
      writer.writeString(
        5,
        f
      );
    }
    f = message.getFontColor();
    if (f.length > 0) {
      writer.writeString(
        6,
        f
      );
    }
    f = message.getFontWeight();
    if (f.length > 0) {
      writer.writeString(
        7,
        f
      );
    }
    f = message.getItalic();
    if (f) {
      writer.writeBool(
        8,
        f
      );
    }
    f = message.getLineHeight();
    if (f !== 0.0) {
      writer.writeFloat(
        9,
        f
      );
    }
    f = message.getMinFontSize();
    if (f !== 0.0) {
      writer.writeFloat(
        10,
        f
      );
    }
    f = message.getMaxFontSize();
    if (f !== 0.0) {
      writer.writeFloat(
        11,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    Banner.HeadlineOptions.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional float offset_x = 1;
   * @return {number}
   */
  getOffsetX(): number {
    return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
  };


  /** @param {number} value */
  setOffsetX(value: number) {
    jspb.Message.setProto3FloatField(this, 1, value);
  }


  /**
   * optional float offset_y = 2;
   * @return {number}
   */
  getOffsetY(): number {
    return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
  };


  /** @param {number} value */
  setOffsetY(value: number) {
    jspb.Message.setProto3FloatField(this, 2, value);
  }


  /**
   * optional Anchor anchor = 3;
   * @return {!Banner.HeadlineOptions.Anchor}
   */
  getAnchor(): Banner.HeadlineOptions.Anchor {
    return /** @type {!Banner.HeadlineOptions.Anchor} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
  };


  /** @param {!Banner.HeadlineOptions.Anchor} value */
  setAnchor(value: Banner.HeadlineOptions.Anchor) {
    jspb.Message.setProto3EnumField(this, 3, value);
  }


  /**
   * optional Align align = 4;
   * @return {!Banner.HeadlineOptions.Align}
   */
  getAlign(): Banner.HeadlineOptions.Align {
    return /** @type {!Banner.HeadlineOptions.Align} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
  };


  /** @param {!Banner.HeadlineOptions.Align} value */
  setAlign(value: Banner.HeadlineOptions.Align) {
    jspb.Message.setProto3EnumField(this, 4, value);
  }


  /**
   * optional string font_family = 5;
   * @return {string}
   */
  getFontFamily(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
  };


  /** @param {string} value */
  setFontFamily(value: string) {
    jspb.Message.setProto3StringField(this, 5, value);
  }


  /**
   * optional string font_color = 6;
   * @return {string}
   */
  getFontColor(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
  };


  /** @param {string} value */
  setFontColor(value: string) {
    jspb.Message.setProto3StringField(this, 6, value);
  }


  /**
   * optional string font_weight = 7;
   * @return {string}
   */
  getFontWeight(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
  };


  /** @param {string} value */
  setFontWeight(value: string) {
    jspb.Message.setProto3StringField(this, 7, value);
  }


  /**
   * optional bool italic = 8;
   * @return {boolean}
   */
  getItalic(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
  };


  /** @param {boolean} value */
  setItalic(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 8, value);
  }


  /**
   * optional float line_height = 9;
   * @return {number}
   */
  getLineHeight(): number {
    return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 9, 0.0));
  };


  /** @param {number} value */
  setLineHeight(value: number) {
    jspb.Message.setProto3FloatField(this, 9, value);
  }


  /**
   * optional float min_font_size = 10;
   * @return {number}
   */
  getMinFontSize(): number {
    return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 10, 0.0));
  };


  /** @param {number} value */
  setMinFontSize(value: number) {
    jspb.Message.setProto3FloatField(this, 10, value);
  }


  /**
   * optional float max_font_size = 11;
   * @return {number}
   */
  getMaxFontSize(): number {
    return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 11, 0.0));
  };


  /** @param {number} value */
  setMaxFontSize(value: number) {
    jspb.Message.setProto3FloatField(this, 11, value);
  }


}
} // namespace Banner
export namespace Banner {
export namespace HeadlineOptions {
export enum Anchor {
  TOP_LEFT = 0,
  TOP_CENTER = 1,
  TOP_RIGHT = 2,
  MID_LEFT = 3,
  MID_CENTER = 4,
  MID_RIGHT = 5,
  BTM_LEFT = 6,
  BTM_CENTER = 7,
  BTM_RIGHT = 8,
}
} // namespace Banner
} // namespace HeadlineOptions
export namespace Banner {
export namespace HeadlineOptions {
export enum Align {
  LEFT = 0,
  CENTER = 1,
  RIGHT = 2,
}
} // namespace Banner
} // namespace HeadlineOptions
export namespace Banners {
  export interface AsObject {
    bannersList: Banner.AsObject[];
  }
}
export class Banners extends jspb.Message {
  static readonly displayName = "Banners";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [1];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, Banners.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): Banners.AsObject {
    return Banners.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!Banners} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: Banners) {
    var f: any;
    var obj: any = {
      bannersList: jspb.Message.toObjectList(msg.getBannersList(),
      Banner.toObject, includeInstance)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!Banners}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new Banners;
    return Banners.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!Banners} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!Banners}
   */
  static deserializeBinaryFromReader(msg: Banners, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new Banner;
        reader.readMessage(value1,Banner.deserializeBinaryFromReader);
        msg.addBanners(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!Banners} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: Banners, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getBannersList();
    if (f.length > 0) {
      writer.writeRepeatedMessage(
        1,
        f,
        Banner.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    Banners.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * repeated Banner banners = 1;
   * @return {!Array<!Banner>}
   */
  getBannersList(): Banner[] {
    return /** @type{!Array<!Banner>} */ (
      jspb.Message.getRepeatedWrapperField(this, Banner, 1));
  }


  /** @param {!Array<!Banner>} value */
  setBannersList(value?: Banner[]) {
    jspb.Message.setRepeatedWrapperField(this, 1, value);
  }


  /**
   * @param {!Banner=} opt_value
   * @param {number=} opt_index
   * @return {!Banner}
   */
  addBanners(opt_value?: Banner, opt_index?: number): Banner {
    return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, Banner, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearBannersList() {
    this.setBannersList([]);
  }


}
export namespace BannerLibraryGet {
  export interface AsObject {
    bannerHash: string;
  }
}
export class BannerLibraryGet extends jspb.Message {
  static readonly displayName = "BannerLibraryGet";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): BannerLibraryGet.AsObject {
    return BannerLibraryGet.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!BannerLibraryGet} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: BannerLibraryGet) {
    var f: any;
    var obj: any = {
      bannerHash: jspb.Message.getFieldWithDefault(msg, 1, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!BannerLibraryGet}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new BannerLibraryGet;
    return BannerLibraryGet.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!BannerLibraryGet} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!BannerLibraryGet}
   */
  static deserializeBinaryFromReader(msg: BannerLibraryGet, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setBannerHash(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!BannerLibraryGet} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: BannerLibraryGet, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getBannerHash();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    BannerLibraryGet.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string banner_hash = 1;
   * @return {string}
   */
  getBannerHash(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setBannerHash(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


}
export namespace BannerLibraryGetAllByType {
  export interface AsObject {
    type: string;
  }
}
export class BannerLibraryGetAllByType extends jspb.Message {
  static readonly displayName = "BannerLibraryGetAllByType";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): BannerLibraryGetAllByType.AsObject {
    return BannerLibraryGetAllByType.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!BannerLibraryGetAllByType} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: BannerLibraryGetAllByType) {
    var f: any;
    var obj: any = {
      type: jspb.Message.getFieldWithDefault(msg, 1, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!BannerLibraryGetAllByType}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new BannerLibraryGetAllByType;
    return BannerLibraryGetAllByType.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!BannerLibraryGetAllByType} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!BannerLibraryGetAllByType}
   */
  static deserializeBinaryFromReader(msg: BannerLibraryGetAllByType, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setType(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!BannerLibraryGetAllByType} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: BannerLibraryGetAllByType, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getType();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    BannerLibraryGetAllByType.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string type = 1;
   * @return {string}
   */
  getType(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setType(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


}
export namespace BannerLibraryAdd {
  export interface AsObject {
    asset: string;
    headlineOptions?: Banner.HeadlineOptions.AsObject;
    categoryList: Banner.Category[];
    keywordList: string[];
    type: string;
    reference: string;
  }
}
export class BannerLibraryAdd extends jspb.Message {
  static readonly displayName = "BannerLibraryAdd";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [3,4];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, BannerLibraryAdd.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): BannerLibraryAdd.AsObject {
    return BannerLibraryAdd.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!BannerLibraryAdd} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: BannerLibraryAdd) {
    var f: any;
    var obj: any = {
      asset: jspb.Message.getFieldWithDefault(msg, 1, ""),
      headlineOptions: (f = msg.getHeadlineOptions()) && Banner.HeadlineOptions.toObject(includeInstance, f),
      categoryList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
      keywordList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
      type: jspb.Message.getFieldWithDefault(msg, 5, ""),
      reference: jspb.Message.getFieldWithDefault(msg, 6, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!BannerLibraryAdd}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new BannerLibraryAdd;
    return BannerLibraryAdd.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!BannerLibraryAdd} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!BannerLibraryAdd}
   */
  static deserializeBinaryFromReader(msg: BannerLibraryAdd, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setAsset(value1);
        break;
      case 2:
        var value2 = new Banner.HeadlineOptions;
        reader.readMessage(value2,Banner.HeadlineOptions.deserializeBinaryFromReader);
        msg.setHeadlineOptions(value2);
        break;
      case 3:
        var value3 = /** @type {!Array<!Banner.Category>} */ (reader.readPackedEnum());
        msg.setCategoryList(value3);
        break;
      case 4:
        var value4 = /** @type {string} */ (reader.readString());
        msg.addKeyword(value4);
        break;
      case 5:
        var value5 = /** @type {string} */ (reader.readString());
        msg.setType(value5);
        break;
      case 6:
        var value6 = /** @type {string} */ (reader.readString());
        msg.setReference(value6);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!BannerLibraryAdd} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: BannerLibraryAdd, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getAsset();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
    f = message.getHeadlineOptions();
    if (f != null) {
      writer.writeMessage(
        2,
        f,
        Banner.HeadlineOptions.serializeBinaryToWriter
      );
    }
    f = message.getCategoryList();
    if (f.length > 0) {
      writer.writePackedEnum(
        3,
        f
      );
    }
    f = message.getKeywordList();
    if (f.length > 0) {
      writer.writeRepeatedString(
        4,
        f
      );
    }
    f = message.getType();
    if (f.length > 0) {
      writer.writeString(
        5,
        f
      );
    }
    f = message.getReference();
    if (f.length > 0) {
      writer.writeString(
        6,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    BannerLibraryAdd.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string asset = 1;
   * @return {string}
   */
  getAsset(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setAsset(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


  /**
   * optional Banner.HeadlineOptions headline_options = 2;
   * @return {?Banner.HeadlineOptions}
   */
  getHeadlineOptions(): Banner.HeadlineOptions {
    return /** @type{?Banner.HeadlineOptions} */ (
      jspb.Message.getWrapperField(this, Banner.HeadlineOptions, 2));
  }


  /** @param {?Banner.HeadlineOptions|undefined} value */
  setHeadlineOptions(value?: Banner.HeadlineOptions) {
    jspb.Message.setWrapperField(this, 2, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearHeadlineOptions() {
    this.setHeadlineOptions(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasHeadlineOptions(): boolean {
    return jspb.Message.getField(this, 2) != null;
  }


  /**
   * repeated Banner.Category category = 3;
   * @return {!Array<!Banner.Category>}
   */
  getCategoryList(): Banner.Category[] {
    return /** @type {!Array<!Banner.Category>} */ (jspb.Message.getRepeatedField(this, 3));
  };


  /** @param {!Array<!Banner.Category>} value */
  setCategoryList(value: Banner.Category[]) {
    jspb.Message.setField(this, 3, value || []);
  }


  /**
   * @param {!Banner.Category} value
   * @param {number=} opt_index
   */
  addCategory(value: Banner.Category, opt_index?: number): void {
    jspb.Message.addToRepeatedField(this, 3, value, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearCategoryList() {
    this.setCategoryList([]);
  }


  /**
   * repeated string keyword = 4;
   * @return {!Array<string>}
   */
  getKeywordList(): string[] {
    return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 4));
  };


  /** @param {!Array<string>} value */
  setKeywordList(value: string[]) {
    jspb.Message.setField(this, 4, value || []);
  }


  /**
   * @param {string} value
   * @param {number=} opt_index
   */
  addKeyword(value: string, opt_index?: number): void {
    jspb.Message.addToRepeatedField(this, 4, value, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearKeywordList() {
    this.setKeywordList([]);
  }


  /**
   * optional string type = 5;
   * @return {string}
   */
  getType(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
  };


  /** @param {string} value */
  setType(value: string) {
    jspb.Message.setProto3StringField(this, 5, value);
  }


  /**
   * optional string reference = 6;
   * @return {string}
   */
  getReference(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
  };


  /** @param {string} value */
  setReference(value: string) {
    jspb.Message.setProto3StringField(this, 6, value);
  }


}
export namespace BannerLibraryUpdate {
  export interface AsObject {
    bannerHash: string;
    newAsset?: google_protobuf_wrappers_pb.StringValue.AsObject;
    headlineOptions?: Banner.HeadlineOptions.AsObject;
    categoryList: Banner.Category[];
    keywordList: string[];
    type: string;
  }
}
export class BannerLibraryUpdate extends jspb.Message {
  static readonly displayName = "BannerLibraryUpdate";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [4,5];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, BannerLibraryUpdate.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): BannerLibraryUpdate.AsObject {
    return BannerLibraryUpdate.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!BannerLibraryUpdate} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: BannerLibraryUpdate) {
    var f: any;
    var obj: any = {
      bannerHash: jspb.Message.getFieldWithDefault(msg, 1, ""),
      newAsset: (f = msg.getNewAsset()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
      headlineOptions: (f = msg.getHeadlineOptions()) && Banner.HeadlineOptions.toObject(includeInstance, f),
      categoryList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
      keywordList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f,
      type: jspb.Message.getFieldWithDefault(msg, 6, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!BannerLibraryUpdate}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new BannerLibraryUpdate;
    return BannerLibraryUpdate.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!BannerLibraryUpdate} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!BannerLibraryUpdate}
   */
  static deserializeBinaryFromReader(msg: BannerLibraryUpdate, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setBannerHash(value1);
        break;
      case 2:
        var value2 = new google_protobuf_wrappers_pb.StringValue;
        reader.readMessage(value2,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
        msg.setNewAsset(value2);
        break;
      case 3:
        var value3 = new Banner.HeadlineOptions;
        reader.readMessage(value3,Banner.HeadlineOptions.deserializeBinaryFromReader);
        msg.setHeadlineOptions(value3);
        break;
      case 4:
        var value4 = /** @type {!Array<!Banner.Category>} */ (reader.readPackedEnum());
        msg.setCategoryList(value4);
        break;
      case 5:
        var value5 = /** @type {string} */ (reader.readString());
        msg.addKeyword(value5);
        break;
      case 6:
        var value6 = /** @type {string} */ (reader.readString());
        msg.setType(value6);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!BannerLibraryUpdate} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: BannerLibraryUpdate, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getBannerHash();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
    f = message.getNewAsset();
    if (f != null) {
      writer.writeMessage(
        2,
        f,
        google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
      );
    }
    f = message.getHeadlineOptions();
    if (f != null) {
      writer.writeMessage(
        3,
        f,
        Banner.HeadlineOptions.serializeBinaryToWriter
      );
    }
    f = message.getCategoryList();
    if (f.length > 0) {
      writer.writePackedEnum(
        4,
        f
      );
    }
    f = message.getKeywordList();
    if (f.length > 0) {
      writer.writeRepeatedString(
        5,
        f
      );
    }
    f = message.getType();
    if (f.length > 0) {
      writer.writeString(
        6,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    BannerLibraryUpdate.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string banner_hash = 1;
   * @return {string}
   */
  getBannerHash(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setBannerHash(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


  /**
   * optional google.protobuf.StringValue new_asset = 2;
   * @return {?StringValue}
   */
  getNewAsset(): google_protobuf_wrappers_pb.StringValue {
    return /** @type{?StringValue} */ (
      jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 2));
  }


  /** @param {?StringValue|undefined} value */
  setNewAsset(value?: google_protobuf_wrappers_pb.StringValue) {
    jspb.Message.setWrapperField(this, 2, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearNewAsset() {
    this.setNewAsset(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasNewAsset(): boolean {
    return jspb.Message.getField(this, 2) != null;
  }


  /**
   * optional Banner.HeadlineOptions headline_options = 3;
   * @return {?Banner.HeadlineOptions}
   */
  getHeadlineOptions(): Banner.HeadlineOptions {
    return /** @type{?Banner.HeadlineOptions} */ (
      jspb.Message.getWrapperField(this, Banner.HeadlineOptions, 3));
  }


  /** @param {?Banner.HeadlineOptions|undefined} value */
  setHeadlineOptions(value?: Banner.HeadlineOptions) {
    jspb.Message.setWrapperField(this, 3, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearHeadlineOptions() {
    this.setHeadlineOptions(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasHeadlineOptions(): boolean {
    return jspb.Message.getField(this, 3) != null;
  }


  /**
   * repeated Banner.Category category = 4;
   * @return {!Array<!Banner.Category>}
   */
  getCategoryList(): Banner.Category[] {
    return /** @type {!Array<!Banner.Category>} */ (jspb.Message.getRepeatedField(this, 4));
  };


  /** @param {!Array<!Banner.Category>} value */
  setCategoryList(value: Banner.Category[]) {
    jspb.Message.setField(this, 4, value || []);
  }


  /**
   * @param {!Banner.Category} value
   * @param {number=} opt_index
   */
  addCategory(value: Banner.Category, opt_index?: number): void {
    jspb.Message.addToRepeatedField(this, 4, value, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearCategoryList() {
    this.setCategoryList([]);
  }


  /**
   * repeated string keyword = 5;
   * @return {!Array<string>}
   */
  getKeywordList(): string[] {
    return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 5));
  };


  /** @param {!Array<string>} value */
  setKeywordList(value: string[]) {
    jspb.Message.setField(this, 5, value || []);
  }


  /**
   * @param {string} value
   * @param {number=} opt_index
   */
  addKeyword(value: string, opt_index?: number): void {
    jspb.Message.addToRepeatedField(this, 5, value, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearKeywordList() {
    this.setKeywordList([]);
  }


  /**
   * optional string type = 6;
   * @return {string}
   */
  getType(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
  };


  /** @param {string} value */
  setType(value: string) {
    jspb.Message.setProto3StringField(this, 6, value);
  }


}
export namespace BannerLibrarySearchResponse {
  export interface AsObject {
    itemMetadata?: common_stream_pb.StreamItemMetadata.AsObject;
    item?: Banner.AsObject;
  }
}
export class BannerLibrarySearchResponse extends jspb.Message {
  static readonly displayName = "BannerLibrarySearchResponse";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): BannerLibrarySearchResponse.AsObject {
    return BannerLibrarySearchResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!BannerLibrarySearchResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: BannerLibrarySearchResponse) {
    var f: any;
    var obj: any = {
      itemMetadata: (f = msg.getItemMetadata()) && common_stream_pb.StreamItemMetadata.toObject(includeInstance, f),
      item: (f = msg.getItem()) && Banner.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!BannerLibrarySearchResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new BannerLibrarySearchResponse;
    return BannerLibrarySearchResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!BannerLibrarySearchResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!BannerLibrarySearchResponse}
   */
  static deserializeBinaryFromReader(msg: BannerLibrarySearchResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new common_stream_pb.StreamItemMetadata;
        reader.readMessage(value1,common_stream_pb.StreamItemMetadata.deserializeBinaryFromReader);
        msg.setItemMetadata(value1);
        break;
      case 2:
        var value2 = new Banner;
        reader.readMessage(value2,Banner.deserializeBinaryFromReader);
        msg.setItem(value2);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!BannerLibrarySearchResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: BannerLibrarySearchResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getItemMetadata();
    if (f != null) {
      writer.writeMessage(
        1,
        f,
        common_stream_pb.StreamItemMetadata.serializeBinaryToWriter
      );
    }
    f = message.getItem();
    if (f != null) {
      writer.writeMessage(
        2,
        f,
        Banner.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    BannerLibrarySearchResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional StreamItemMetadata item_metadata = 1;
   * @return {?StreamItemMetadata}
   */
  getItemMetadata(): common_stream_pb.StreamItemMetadata {
    return /** @type{?StreamItemMetadata} */ (
      jspb.Message.getWrapperField(this, common_stream_pb.StreamItemMetadata, 1));
  }


  /** @param {?StreamItemMetadata|undefined} value */
  setItemMetadata(value?: common_stream_pb.StreamItemMetadata) {
    jspb.Message.setWrapperField(this, 1, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearItemMetadata() {
    this.setItemMetadata(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasItemMetadata(): boolean {
    return jspb.Message.getField(this, 1) != null;
  }


  /**
   * optional Banner item = 2;
   * @return {?Banner}
   */
  getItem(): Banner {
    return /** @type{?Banner} */ (
      jspb.Message.getWrapperField(this, Banner, 2));
  }


  /** @param {?Banner|undefined} value */
  setItem(value?: Banner) {
    jspb.Message.setWrapperField(this, 2, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearItem() {
    this.setItem(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasItem(): boolean {
    return jspb.Message.getField(this, 2) != null;
  }


}
export namespace BannerLibrarySetArchived {
  export interface AsObject {
    bannerHash: string;
    archived: boolean;
  }
}
export class BannerLibrarySetArchived extends jspb.Message {
  static readonly displayName = "BannerLibrarySetArchived";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): BannerLibrarySetArchived.AsObject {
    return BannerLibrarySetArchived.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!BannerLibrarySetArchived} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: BannerLibrarySetArchived) {
    var f: any;
    var obj: any = {
      bannerHash: jspb.Message.getFieldWithDefault(msg, 1, ""),
      archived: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!BannerLibrarySetArchived}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new BannerLibrarySetArchived;
    return BannerLibrarySetArchived.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!BannerLibrarySetArchived} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!BannerLibrarySetArchived}
   */
  static deserializeBinaryFromReader(msg: BannerLibrarySetArchived, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setBannerHash(value1);
        break;
      case 2:
        var value2 = /** @type {boolean} */ (reader.readBool());
        msg.setArchived(value2);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!BannerLibrarySetArchived} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: BannerLibrarySetArchived, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getBannerHash();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
    f = message.getArchived();
    if (f) {
      writer.writeBool(
        2,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    BannerLibrarySetArchived.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string banner_hash = 1;
   * @return {string}
   */
  getBannerHash(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setBannerHash(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


  /**
   * optional bool archived = 2;
   * @return {boolean}
   */
  getArchived(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
  };


  /** @param {boolean} value */
  setArchived(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 2, value);
  }


}
export namespace BannerLibraryDelete {
  export interface AsObject {
    bannerHash: string;
    undo: boolean;
  }
}
export class BannerLibraryDelete extends jspb.Message {
  static readonly displayName = "BannerLibraryDelete";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): BannerLibraryDelete.AsObject {
    return BannerLibraryDelete.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!BannerLibraryDelete} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: BannerLibraryDelete) {
    var f: any;
    var obj: any = {
      bannerHash: jspb.Message.getFieldWithDefault(msg, 1, ""),
      undo: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!BannerLibraryDelete}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new BannerLibraryDelete;
    return BannerLibraryDelete.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!BannerLibraryDelete} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!BannerLibraryDelete}
   */
  static deserializeBinaryFromReader(msg: BannerLibraryDelete, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setBannerHash(value1);
        break;
      case 2:
        var value2 = /** @type {boolean} */ (reader.readBool());
        msg.setUndo(value2);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!BannerLibraryDelete} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: BannerLibraryDelete, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getBannerHash();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
    f = message.getUndo();
    if (f) {
      writer.writeBool(
        2,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    BannerLibraryDelete.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string banner_hash = 1;
   * @return {string}
   */
  getBannerHash(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setBannerHash(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


  /**
   * optional bool undo = 2;
   * @return {boolean}
   */
  getUndo(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
  };


  /** @param {boolean} value */
  setUndo(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 2, value);
  }


}
export namespace BannerLibrarayStats {
  export interface AsObject {
    categoryCountList: BannerLibrarayStats.CategoryCount.AsObject[];
  }
}
export class BannerLibrarayStats extends jspb.Message {
  static readonly displayName = "BannerLibrarayStats";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [1];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, BannerLibrarayStats.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): BannerLibrarayStats.AsObject {
    return BannerLibrarayStats.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!BannerLibrarayStats} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: BannerLibrarayStats) {
    var f: any;
    var obj: any = {
      categoryCountList: jspb.Message.toObjectList(msg.getCategoryCountList(),
      BannerLibrarayStats.CategoryCount.toObject, includeInstance)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!BannerLibrarayStats}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new BannerLibrarayStats;
    return BannerLibrarayStats.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!BannerLibrarayStats} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!BannerLibrarayStats}
   */
  static deserializeBinaryFromReader(msg: BannerLibrarayStats, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new BannerLibrarayStats.CategoryCount;
        reader.readMessage(value1,BannerLibrarayStats.CategoryCount.deserializeBinaryFromReader);
        msg.addCategoryCount(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!BannerLibrarayStats} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: BannerLibrarayStats, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getCategoryCountList();
    if (f.length > 0) {
      writer.writeRepeatedMessage(
        1,
        f,
        BannerLibrarayStats.CategoryCount.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    BannerLibrarayStats.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * repeated CategoryCount category_count = 1;
   * @return {!Array<!BannerLibrarayStats.CategoryCount>}
   */
  getCategoryCountList(): BannerLibrarayStats.CategoryCount[] {
    return /** @type{!Array<!BannerLibrarayStats.CategoryCount>} */ (
      jspb.Message.getRepeatedWrapperField(this, BannerLibrarayStats.CategoryCount, 1));
  }


  /** @param {!Array<!BannerLibrarayStats.CategoryCount>} value */
  setCategoryCountList(value?: BannerLibrarayStats.CategoryCount[]) {
    jspb.Message.setRepeatedWrapperField(this, 1, value);
  }


  /**
   * @param {!BannerLibrarayStats.CategoryCount=} opt_value
   * @param {number=} opt_index
   * @return {!BannerLibrarayStats.CategoryCount}
   */
  addCategoryCount(opt_value?: BannerLibrarayStats.CategoryCount, opt_index?: number): BannerLibrarayStats.CategoryCount {
    return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, BannerLibrarayStats.CategoryCount, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearCategoryCountList() {
    this.setCategoryCountList([]);
  }


}
export namespace BannerLibrarayStats {
export namespace CategoryCount {
  export interface AsObject {
    category?: Banner.Category;
    categoryCount: number;
  }
}
export class CategoryCount extends jspb.Message {
  static readonly displayName = "BannerLibrarayStats.CategoryCount";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): CategoryCount.AsObject {
    return CategoryCount.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!CategoryCount} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: CategoryCount) {
    var f: any;
    var obj: any = {
      category: jspb.Message.getFieldWithDefault(msg, 1, 0),
      categoryCount: jspb.Message.getFieldWithDefault(msg, 2, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!CategoryCount}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new CategoryCount;
    return CategoryCount.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!BannerLibrarayStats.CategoryCount} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!BannerLibrarayStats.CategoryCount}
   */
  static deserializeBinaryFromReader(msg: CategoryCount, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {!Banner.Category} */ (reader.readEnum());
        msg.setCategory(value1);
        break;
      case 2:
        var value2 = /** @type {number} */ (reader.readInt32());
        msg.setCategoryCount(value2);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!BannerLibrarayStats.CategoryCount} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: BannerLibrarayStats.CategoryCount, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getCategory();
    if (f !== 0.0) {
      writer.writeEnum(
        1,
        f
      );
    }
    f = message.getCategoryCount();
    if (f !== 0) {
      writer.writeInt32(
        2,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    BannerLibrarayStats.CategoryCount.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional Banner.Category category = 1;
   * @return {!Banner.Category}
   */
  getCategory(): Banner.Category {
    return /** @type {!Banner.Category} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
  };


  /** @param {!Banner.Category} value */
  setCategory(value: Banner.Category) {
    jspb.Message.setProto3EnumField(this, 1, value);
  }


  /**
   * optional int32 category_count = 2;
   * @return {number}
   */
  getCategoryCount(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
  };


  /** @param {number} value */
  setCategoryCount(value: number) {
    jspb.Message.setProto3IntField(this, 2, value);
  }


}
} // namespace BannerLibrarayStats
export namespace BannerLibraryGetStatsRequest {
  export interface AsObject {
  }
}
export class BannerLibraryGetStatsRequest extends jspb.Message {
  static readonly displayName = "BannerLibraryGetStatsRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): BannerLibraryGetStatsRequest.AsObject {
    return BannerLibraryGetStatsRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!BannerLibraryGetStatsRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: BannerLibraryGetStatsRequest) {
    var f: any;
    var obj: any = {

    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!BannerLibraryGetStatsRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new BannerLibraryGetStatsRequest;
    return BannerLibraryGetStatsRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!BannerLibraryGetStatsRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!BannerLibraryGetStatsRequest}
   */
  static deserializeBinaryFromReader(msg: BannerLibraryGetStatsRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!BannerLibraryGetStatsRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: BannerLibraryGetStatsRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    BannerLibraryGetStatsRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
}
export namespace BannerLibraryGetStatsResponse {
  export interface AsObject {
    stats?: BannerLibrarayStats.AsObject;
  }
}
export class BannerLibraryGetStatsResponse extends jspb.Message {
  static readonly displayName = "BannerLibraryGetStatsResponse";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): BannerLibraryGetStatsResponse.AsObject {
    return BannerLibraryGetStatsResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!BannerLibraryGetStatsResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: BannerLibraryGetStatsResponse) {
    var f: any;
    var obj: any = {
      stats: (f = msg.getStats()) && BannerLibrarayStats.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!BannerLibraryGetStatsResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new BannerLibraryGetStatsResponse;
    return BannerLibraryGetStatsResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!BannerLibraryGetStatsResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!BannerLibraryGetStatsResponse}
   */
  static deserializeBinaryFromReader(msg: BannerLibraryGetStatsResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new BannerLibrarayStats;
        reader.readMessage(value1,BannerLibrarayStats.deserializeBinaryFromReader);
        msg.setStats(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!BannerLibraryGetStatsResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: BannerLibraryGetStatsResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getStats();
    if (f != null) {
      writer.writeMessage(
        1,
        f,
        BannerLibrarayStats.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    BannerLibraryGetStatsResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional BannerLibrarayStats stats = 1;
   * @return {?BannerLibrarayStats}
   */
  getStats(): BannerLibrarayStats {
    return /** @type{?BannerLibrarayStats} */ (
      jspb.Message.getWrapperField(this, BannerLibrarayStats, 1));
  }


  /** @param {?BannerLibrarayStats|undefined} value */
  setStats(value?: BannerLibrarayStats) {
    jspb.Message.setWrapperField(this, 1, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearStats() {
    this.setStats(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasStats(): boolean {
    return jspb.Message.getField(this, 1) != null;
  }


}
