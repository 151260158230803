import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Injectable,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  Output,
  TrackByFunction,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';

import * as _ from 'lodash';
import { IContentEventMinimal } from 'libs/domain';
import { image_pb } from 'libs/generated-grpc-web';
import {
  BehaviorSubject,
  combineLatest,
  merge,
  Observable,
  of,
  Subscription,
} from 'rxjs';
import { combineAll, filter, first, map, reduce, take } from 'rxjs/operators';

import { LightboxComponent } from '@app/src/app/components/Lightbox/Lightbox.component';
import { EventsFacadeService } from '@app/src/app/events/services';
import { FileDownloaderService } from '@app/src/app/file';
import { AnalyticsService } from '@app/src/app/minimal/services/Analytics';
import { RootService } from '@app/src/app/minimal/services/RootService';
import { ReportService } from '@app/src/app/services/Report';
import { mgResolveImageUrl } from '@app/src/app/util/asset';
import { fitIn, IBox } from '@app/src/app/util/box';
import { hammerjs } from '@app/src/app/util/hammerjs';

export interface IGalleryLightboxItemTag {
  id: string | number;
  name$: Observable<string>;
  clickHandler?: (ev: MouseEvent) => any;
}

export interface IGalleryLightboxModerationEvent {
  item: IGalleryLightboxItem;
  leaveGallery?: boolean;
}

export interface IGalleryLightboxModerationOptions {
  resolve?: boolean;
  delete?: boolean;
  publish?: boolean;
  report?: boolean;
}

export interface IGalleryLightboxItem {
  /**
   * The global index
   */
  readonly index: number;

  /**
   * Id used to track items
   */
  readonly id: string | number;

  readonly authorDisplayName: string;

  readonly authorPersonHash: string;

  readonly shouldShowDelete: boolean;

  readonly shouldShowResolve: boolean;

  readonly shouldShowReport: boolean;

  readonly description: string;

  readonly timestamp: number;

  readonly imageInfo: image_pb.ImageInfo.AsObject;

  readonly tags: ReadonlyArray<IGalleryLightboxItemTag>;
}

export interface GalleryLightboxDataSource {
  /**
   * Total count of gallery items
   */
  total$: Observable<number>;

  /** Items that get sourced as view items */
  items$: Observable<IGalleryLightboxItem[]>;

  /** Gets called when new items in `viewItems$` are expected at the back */
  next(): void;

  /** Peeks the next item to see if there is one available */
  hasNext(): boolean;

  /** Gets called when new items in `viewItems$` are expected at the front */
  previous(): void;

  /** Peeks the previous item to see if there is one available */
  hasPrevious(): boolean;
}

export interface IGalleryLightboxItemWithExtra {
  item: IGalleryLightboxItem;
  viewTags$: Observable<IGalleryLightboxItemTag[]>;
  tagCharCount$: Observable<number>;
  hiddenTagsCount$: Observable<number>;
  viewEvents$: Observable<IContentEventMinimal[]>;
  moderationOptions?: IGalleryLightboxModerationOptions;
}

/**
 * Crude calculation on max characters allowed in tagging section
 * @param imageWidth
 * @param imageHeight
 */
function MAX_TAG_CHARACTER_CUT_OFF(
  imageWidth: number,
  imageHeight: number,
): number {
  return (innerWidth / 12) * (imageWidth / imageHeight);
}

function lightboxItemWithExtra(
  item: IGalleryLightboxItem,
  eventsFacade: EventsFacadeService,
): IGalleryLightboxItemWithExtra {
  // longcardbanner default size
  const DEFAULT_IMAGE_WIDTH = 1200;
  const DEFAULT_IMAGE_HEIGHT = 900;
  const tagsCount = item.tags.length;

  const imageWidth = () => {
    if (item && item.imageInfo) {
      return item.imageInfo.sizeMap[1][1].width;
    }
    // longcardbanner default size
    return DEFAULT_IMAGE_WIDTH;
  };
  const imageHeight = () => {
    if (item && item.imageInfo) {
      return item.imageInfo.sizeMap[1][1].height;
    }
    return DEFAULT_IMAGE_HEIGHT;
  };

  // @NOTE: This is a little off because if any of the names change this doesn't
  const tagCharCount$ = merge(
    ...item.tags.map(t => t.name$.pipe(take(1))),
  ).pipe(reduce((acc, name) => (acc += name.length), 0));

  const viewTags$ = merge(
    ...item.tags.map(t =>
      t.name$.pipe(
        take(1),
        map(resolvedName => of({ resolvedName, tag: t })),
      ),
    ),
  ).pipe(
    combineAll(),
    map(tagsWithResolvedNames => {
      const cutoff = MAX_TAG_CHARACTER_CUT_OFF(imageWidth(), imageHeight());
      const viewTags: IGalleryLightboxItemTag[] = [];
      let length = 0;
      for (const tag of tagsWithResolvedNames) {
        length += tag.resolvedName.length;
        if (length >= cutoff) {
          break;
        }
        if (tag.resolvedName) {
          viewTags.push(tag.tag);
        }
      }
      return viewTags;
    }),
  );

  const hiddenTagsCount$ = viewTags$.pipe(
    map(viewTags => tagsCount - viewTags.length),
  );

  const viewEvents$ = combineLatest(
    viewTags$,
    eventsFacade.getAllEvents(),
  ).pipe(
    map(([tags, events]) => {
      return tags
        .map(tag => {
          const event = events.find(event => event.hash == tag.id);
          return event;
        })
        .filter(event => !!event)
        .map(event => event as IContentEventMinimal);
    }),
  );

  // filter out event tags
  // @NOTE: this is a temporary thing till clicking a tag filters the gallery
  // not by a simple text search
  const viewNonEventTags$ = combineLatest(viewTags$, viewEvents$).pipe(
    map(([tags, events]) => {
      return tags
        .map(tag => {
          const eventTag = events.find(event => event.hash == tag.id);
          if (!eventTag) {
            return tag;
          }
          return null;
        })
        .filter(tag => !!tag)
        .map(tag => tag as IGalleryLightboxItemTag);
    }),
  );

  return {
    item,
    viewTags$: viewNonEventTags$,
    tagCharCount$,
    hiddenTagsCount$,
    viewEvents$,
  };
}

function swipeMultiplier(
  deltaX: number,
  currentIndex: number,
  itemsCount: number,
) {
  return (deltaX > 0 && currentIndex === 0) ||
    (deltaX < 0 && currentIndex === itemsCount - 1)
    ? 0.12
    : 1;
}

interface IViewItemInfo {
  readonly id: number | string;
  readonly globalIndex: number;
}

@Component({
  selector: 'mg-gallery-lightbox',
  templateUrl: './GalleryLightbox.component.html',
  styleUrls: ['./GalleryLightbox.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GalleryLightboxComponent implements OnInit, OnDestroy {
  private _dataSource: GalleryLightboxDataSource | null = null;
  _activeGlobalIndex: number = -1;

  /** @internal */
  private _viewItemsInfo: IViewItemInfo[] = [];

  /** @internal */
  private _lastPanDelta: number = 0;

  /** @internal */
  private _itemsContainerWidth: number = 0;

  /** @internal */
  private _itemsContainerPanPosition: number = 0;

  /** @internal */
  private _frameHandler: number = 0;

  /** @internal */
  private _dataSrcItemsSub: Subscription | null = null;

  /** @internal */
  private _hmjs: any | null = null;

  _trackBy: TrackByFunction<IGalleryLightboxItem>;

  @ViewChild('lightbox', { static: true })
  lightbox?: LightboxComponent;

  @ViewChild('itemsContainerElement', { static: true })
  itemsContainerElement!: ElementRef;

  @ViewChild('itemsContainerElement', { static: true })
  contentContainerElement!: ElementRef;

  @Output()
  deleteItem: EventEmitter<IGalleryLightboxModerationEvent>;

  @Output()
  resolveItem: EventEmitter<IGalleryLightboxModerationEvent>;

  @Output()
  reportItem: EventEmitter<IGalleryLightboxItem>;

  /**
   *
   * Emit boolean with TRUE being when closed should leave the gallery page,
   * FALSE when after closing the lightbox the user should stay on the gallery.
   */
  @Output()
  closed: EventEmitter<boolean>;

  @Input()
  set dataSource(value: GalleryLightboxDataSource | null) {
    this._dataSource = value;

    if (this._dataSource) {
      if (this._dataSrcItemsSub) {
        this._dataSrcItemsSub.unsubscribe();
        this._dataSrcItemsSub = null;
      }

      this._dataSrcItemsSub = this._dataSource.items$.subscribe(items => {
        const oldItems = this._viewItems.getValue();
        const viewItems: (IGalleryLightboxItemWithExtra | undefined)[] = [];
        const newItems: IGalleryLightboxItemWithExtra[] = [];

        // for(const item of items) {
        items.forEach(item => {
          const existingIdx = oldItems.findIndex(a => a.item.id == item.id);
          if (existingIdx === -1) {
            newItems.push(lightboxItemWithExtra(item, this.eventsFacade));
          } else {
            viewItems[existingIdx] = lightboxItemWithExtra(
              item,
              this.eventsFacade,
            );
          }
        });

        while (newItems.length) {
          const newItem = newItems.pop() as IGalleryLightboxItemWithExtra;
          const availableIndex = viewItems.findIndex(item => !item);

          if (availableIndex === -1) {
            viewItems.push(newItem);
          } else {
            viewItems[availableIndex] = newItem;
          }
        }

        const filteredViewItems = viewItems
          .filter(item => !!item)
          .map(item => item as IGalleryLightboxItemWithExtra);

        // add moderation & report data for items
        filteredViewItems.forEach(item => {
          const galleryPhotoUuid = `${item.item.id}`;
          item.moderationOptions = {};

          const galleryPhotoDeleted =
            this.reportService.hasGalleryPhotoBeenDeleted(galleryPhotoUuid);
          // you can delete if it hasn't been deleted
          const canDelete = !galleryPhotoDeleted;
          item.moderationOptions['resolve'] = this.reportService.isReported({
            galleryPhotoUuid,
          });
          item.moderationOptions['delete'] =
            canDelete && item.item.shouldShowDelete;
          // if not deleted, can report
          item.moderationOptions['report'] = !galleryPhotoDeleted;
          // can publish if can't delete, as was already deleted
          item.moderationOptions['publish'] = !canDelete;
        });

        this._viewItemsInfo = filteredViewItems.map(item => ({
          globalIndex: item.item.index,
          id: item.item.id,
        }));
        this.calcContainerWidth();

        this._viewItems.next(filteredViewItems);

        this.slideToActive();
        this.cdr.markForCheck();
      });
    }
  }

  get dataSource() {
    return this._dataSource;
  }

  get itemsContainerWidth(): number {
    return this._itemsContainerWidth;
  }

  /** @internal */
  private _viewItems: BehaviorSubject<IGalleryLightboxItemWithExtra[]>;
  viewItems$: Observable<IGalleryLightboxItemWithExtra[]>;

  constructor(
    private ngZone: NgZone,
    private cdr: ChangeDetectorRef,
    private rootService: RootService,
    private fileDownloader: FileDownloaderService,
    private eventsFacade: EventsFacadeService,
    private router: Router,
    private reportService: ReportService,
    private analyticsService: AnalyticsService,
  ) {
    this._trackBy = (index: number, item: IGalleryLightboxItem) => {
      return item.id;
    };
    this.deleteItem = new EventEmitter<IGalleryLightboxModerationEvent>();
    this.resolveItem = new EventEmitter<IGalleryLightboxModerationEvent>();
    this.reportItem = new EventEmitter<IGalleryLightboxItem>();
    this.closed = new EventEmitter<boolean>();
    this._viewItems = new BehaviorSubject<IGalleryLightboxItemWithExtra[]>([]);
    this.viewItems$ = this._viewItems.asObservable();
  }

  @HostListener('window:resize', [])
  onWindowResize() {
    this.calcContainerWidth();
    this.slideToActive();
    this.cdr.markForCheck();
  }

  viewTags(tags: ReadonlyArray<IGalleryLightboxItemTag>) {
    return tags.length;
  }

  deleteItemClick(ev: MouseEvent, item: IGalleryLightboxItem) {
    this.deleteItem.emit({
      item,
      leaveGallery:
        !!this.reportService.consumeGalleryPhotoModerationLightbox(),
    });
  }

  resolveItemClick(ev: MouseEvent, item: IGalleryLightboxItem) {
    this.resolveItem.emit({
      item,
      leaveGallery:
        !!this.reportService.consumeGalleryPhotoModerationLightbox(),
    });
    this.close();
  }

  reportItemClick(ev: MouseEvent, item: IGalleryLightboxItem) {
    this.close();
    if (!!this.reportService.consumeGalleryPhotoModerationLightbox()) {
      // leave gallery and then open report
      this.reportService.backAndOpenReportOverlay(`${item.id}`);
    } else {
      this.reportItem.emit(item);
    }
  }

  async publishItemClick(ev: MouseEvent, item: IGalleryLightboxItem) {
    const galleryPhotoUuid: string = `${item.id}`;
    const reasons = this.reportService.getDeletedPhotoReasons(galleryPhotoUuid);

    await this.rootService.addLoadingPromise(
      this.reportService.overrideAI({
        contentHash: '',
        contextHash: '',
        reasonList: reasons.reasons,
        galleryPhotoUuid,
      }),
    );

    this.close();
    this.closed.emit(
      !!this.reportService.consumeGalleryPhotoModerationLightbox(),
    );
  }

  openEventGallery(contextHash: string) {
    this.close();
    this.router.navigate(['gallery', 'event', contextHash]);
  }

  onContextmenu(ev: any, item: IGalleryLightboxItem) {
    ev.preventDefault();
    ev.stopImmediatePropagation();

    const imgUrl = mgResolveImageUrl(item.imageInfo, 'raw');

    this.rootService.openGlobalMenu({
      x: ev.pageX,
      y: ev.pageY,
      items: [
        {
          click: () => this.fileDownloader.downloadFromUrl(imgUrl),
          name: 'Download',
        },
      ],
    });
  }

  setActiveByGlobalIndex(globalIndex: number) {
    this._activeGlobalIndex = globalIndex;
  }

  setActiveFromId(itemId: number | string) {
    for (const viewItemInfo of this._viewItemsInfo) {
      if (viewItemInfo.id == itemId) {
        this._activeGlobalIndex = viewItemInfo.globalIndex;
        return;
      }
    }

    // @TODO: SHould this be here? Should this method even exist?
    this._activeGlobalIndex = -1;
  }

  ngOnInit() {
    this.ngZone.runOutsideAngular(() => {
      const itemsElement = this.itemsContainerElement.nativeElement;
      const element = this.contentContainerElement.nativeElement;

      const pan = new hammerjs.Pan({});

      this._hmjs = new hammerjs.Manager(element, {});

      this._hmjs.add([pan]);

      this._hmjs.on('panstart', (ev: any) => this.onPanstart(ev, itemsElement));
      this._hmjs.on('panend', (ev: any) => this.onPanend(ev, itemsElement));
      this._hmjs.on('pancancel', (ev: any) =>
        this.onPancancel(ev, itemsElement),
      );
      this._hmjs.on('panleft panright', (ev: any) =>
        this.onPanmove(ev, itemsElement),
      );
    });

    this.analyticsService.sendViewGalleryPost();
  }

  ngOnDestroy() {
    if (this._dataSrcItemsSub) {
      this._dataSrcItemsSub.unsubscribe();
      this._dataSrcItemsSub = null;
    }

    if (this._hmjs) {
      if (this._hmjs.destroy) {
        this._hmjs.destroy();
      }

      this._hmjs = null;
    }
  }

  private _getGlobalIndexRange() {
    let biggestIndex = 0;

    for (const viewItemInfo of this._viewItemsInfo) {
      if (viewItemInfo.globalIndex > biggestIndex) {
        biggestIndex = viewItemInfo.globalIndex;
      }
    }

    let smallestIndex = biggestIndex;

    for (const viewItemInfo of this._viewItemsInfo) {
      if (viewItemInfo.globalIndex < smallestIndex) {
        smallestIndex = viewItemInfo.globalIndex;
      }
    }

    return { biggestIndex, smallestIndex };
  }

  private calcContainerWidth() {
    const { biggestIndex } = this._getGlobalIndexRange();

    this._itemsContainerWidth = biggestIndex * window.innerWidth;
  }

  open() {
    this.lightbox?.open();
  }

  close() {
    this.lightbox?.close();
  }

  getViewItemImagePadding(item: image_pb.ImageInfo.AsObject): string {
    const imageSize = item.sizeMap[0][1];
    const imageSizeCommonDenominator = imageSize.height / imageSize.width;
    return `${imageSizeCommonDenominator * 100}%`;
  }

  private _lightBoxContainerSize(makeRoomForTags: boolean): IBox {
    const heightMultiplier = makeRoomForTags ? 0.35 : 0.25;

    const height: number =
      innerHeight - Math.max(100, innerHeight * heightMultiplier);
    let width: number;

    if (innerWidth < 600) {
      width = innerWidth - 32;
    } else {
      width = innerWidth - Math.max(50, innerWidth * 0.1);
    }

    return { width, height };
  }

  lightBoxImageWidth(img: image_pb.ImageInfo.AsObject, hasTags: boolean) {
    return fitIn(img.sizeMap[0][1], this._lightBoxContainerSize(hasTags)).width;
  }

  lightBoxImageHeight(img: image_pb.ImageInfo.AsObject, hasTags: boolean) {
    return fitIn(img.sizeMap[0][1], this._lightBoxContainerSize(hasTags))
      .height;
  }

  /**
   *
   * @param index global index of item in datasource
   */
  slideTo(index: number) {}

  /**
   *
   * @param index global index of item in datasource
   */
  snapTo(index: number) {}

  slideNext() {
    this.analyticsService.sendViewGalleryPost();
    this._activeGlobalIndex += 1;
    this.slideToActive();
    this.dataSource?.next();
    this.cdr.markForCheck();
  }

  slidePrevious() {
    this.analyticsService.sendViewGalleryPost();
    this._activeGlobalIndex -= 1;
    this.slideToActive();
    this.dataSource?.previous();
    this.cdr.markForCheck();
  }

  hasNext() {
    const { biggestIndex } = this._getGlobalIndexRange();
    return this._activeGlobalIndex + 1 <= biggestIndex;
  }

  hasPrevious() {
    const { smallestIndex } = this._getGlobalIndexRange();
    return this._activeGlobalIndex - 1 >= smallestIndex;
  }

  _setNearestToActive() {
    const currentPosition = this._itemsContainerPanPosition;
    const itemWidth = window.innerWidth;

    const { biggestIndex } = this._getGlobalIndexRange();

    const newIndex = Math.max(
      0,
      Math.min(Math.abs(Math.round(currentPosition / itemWidth)), biggestIndex),
    );

    const newPanPosition = -newIndex * itemWidth;
    this._itemsContainerPanPosition = newPanPosition;
    this._activeGlobalIndex = newIndex;
  }

  slideToNearest() {
    this._setNearestToActive();
    this.update();
    this.cdr.markForCheck();
  }

  slideToActive() {
    const itemWidth = window.innerWidth;

    const newPanPosition = -(this._activeGlobalIndex * itemWidth);

    this.itemsContainerElement.nativeElement.style.transform = `translate3d(${newPanPosition}px, 0px, 0px)`;

    this._itemsContainerPanPosition = newPanPosition;
    this.update();
  }

  snapToActive() {}

  snapToNearest() {}

  update() {
    const itemsElement = this.itemsContainerElement.nativeElement;
    this._update(itemsElement);
  }

  private onPanstart(ev: any, containerElement: any) {
    this._lastPanDelta = 0;
    containerElement.style.transitionDuration = '0ms';
  }

  private onPanend(ev: any, containerElement: any) {
    // Add extra to the pan position if velocity is high enough. Clamping the
    // clamping the velocity so you can't skip items in the lightbox.
    const clampedVelocityX = Math.min(Math.max(ev.overallVelocityX, -3), 3);
    this._itemsContainerPanPosition += clampedVelocityX * (innerWidth * 0.1);

    containerElement.style.transitionDuration = '';
    const previousActiveIndex = this._activeGlobalIndex;

    this._setNearestToActive();

    if (this._activeGlobalIndex != previousActiveIndex) {
      if (this._activeGlobalIndex > previousActiveIndex) {
        this.dataSource?.next();
      } else if (this._activeGlobalIndex < previousActiveIndex) {
        this.dataSource?.previous();
      }
      this.update();
      this.cdr.markForCheck();
    }
  }

  private onPancancel(ev: any, containerElement: any) {
    containerElement.style.transitionDuration = '';
    this.slideToActive();
  }

  private onPanmove(ev: any, containerElement: any) {
    const { biggestIndex } = this._getGlobalIndexRange();
    const deltaX =
      ev.deltaX *
      swipeMultiplier(ev.deltaX, this._activeGlobalIndex, biggestIndex + 1);
    const delta = deltaX - this._lastPanDelta;
    this._itemsContainerPanPosition += delta;
    this._lastPanDelta = deltaX;

    if (!this._frameHandler) {
      this._frameHandler = requestAnimationFrame(() => {
        this._frameHandler = 0;
        this._update(containerElement);
      });
    }
  }

  private _update(containerElement: any) {
    const position = this._itemsContainerPanPosition;
    const translate3d = `translate3d(${position}px, 0px, 0px)`;
    containerElement.style.transform = translate3d;
  }
}
