// source: gateway/page.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

import * as jspb from 'google-protobuf';
import * as common_legacy_pb from '../common/legacy_pb';
export namespace Page {
  export interface AsObject {
    hash?: common_legacy_pb.UniqueHash.AsObject;
    path: string;
    htmlContent: string;
    style?: PageStyle;
  }
}
export class Page extends jspb.Message {
  static readonly displayName = "Page";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): Page.AsObject {
    return Page.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!Page} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: Page) {
    var f: any;
    var obj: any = {
      hash: (f = msg.getHash()) && common_legacy_pb.UniqueHash.toObject(includeInstance, f),
      path: jspb.Message.getFieldWithDefault(msg, 2, ""),
      htmlContent: jspb.Message.getFieldWithDefault(msg, 3, ""),
      style: jspb.Message.getFieldWithDefault(msg, 4, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!Page}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new Page;
    return Page.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!Page} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!Page}
   */
  static deserializeBinaryFromReader(msg: Page, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new common_legacy_pb.UniqueHash;
        reader.readMessage(value1,common_legacy_pb.UniqueHash.deserializeBinaryFromReader);
        msg.setHash(value1);
        break;
      case 2:
        var value2 = /** @type {string} */ (reader.readString());
        msg.setPath(value2);
        break;
      case 3:
        var value3 = /** @type {string} */ (reader.readString());
        msg.setHtmlContent(value3);
        break;
      case 4:
        var value4 = /** @type {!PageStyle} */ (reader.readEnum());
        msg.setStyle(value4);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!Page} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: Page, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getHash();
    if (f != null) {
      writer.writeMessage(
        1,
        f,
        common_legacy_pb.UniqueHash.serializeBinaryToWriter
      );
    }
    f = message.getPath();
    if (f.length > 0) {
      writer.writeString(
        2,
        f
      );
    }
    f = message.getHtmlContent();
    if (f.length > 0) {
      writer.writeString(
        3,
        f
      );
    }
    f = message.getStyle();
    if (f !== 0.0) {
      writer.writeEnum(
        4,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    Page.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional UniqueHash hash = 1;
   * @return {?UniqueHash}
   */
  getHash(): common_legacy_pb.UniqueHash {
    return /** @type{?UniqueHash} */ (
      jspb.Message.getWrapperField(this, common_legacy_pb.UniqueHash, 1));
  }


  /** @param {?UniqueHash|undefined} value */
  setHash(value?: common_legacy_pb.UniqueHash) {
    jspb.Message.setWrapperField(this, 1, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearHash() {
    this.setHash(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasHash(): boolean {
    return jspb.Message.getField(this, 1) != null;
  }


  /**
   * optional string path = 2;
   * @return {string}
   */
  getPath(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
  };


  /** @param {string} value */
  setPath(value: string) {
    jspb.Message.setProto3StringField(this, 2, value);
  }


  /**
   * optional string html_content = 3;
   * @return {string}
   */
  getHtmlContent(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
  };


  /** @param {string} value */
  setHtmlContent(value: string) {
    jspb.Message.setProto3StringField(this, 3, value);
  }


  /**
   * optional PageStyle style = 4;
   * @return {!PageStyle}
   */
  getStyle(): PageStyle {
    return /** @type {!PageStyle} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
  };


  /** @param {!PageStyle} value */
  setStyle(value: PageStyle) {
    jspb.Message.setProto3EnumField(this, 4, value);
  }


}
export namespace PageRequest {
  export interface AsObject {
    hash?: common_legacy_pb.UniqueHash.AsObject;
    pagePath: string;
  }
}
export class PageRequest extends jspb.Message {
  static readonly displayName = "PageRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): PageRequest.AsObject {
    return PageRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!PageRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: PageRequest) {
    var f: any;
    var obj: any = {
      hash: (f = msg.getHash()) && common_legacy_pb.UniqueHash.toObject(includeInstance, f),
      pagePath: jspb.Message.getFieldWithDefault(msg, 2, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!PageRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new PageRequest;
    return PageRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!PageRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!PageRequest}
   */
  static deserializeBinaryFromReader(msg: PageRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new common_legacy_pb.UniqueHash;
        reader.readMessage(value1,common_legacy_pb.UniqueHash.deserializeBinaryFromReader);
        msg.setHash(value1);
        break;
      case 2:
        var value2 = /** @type {string} */ (reader.readString());
        msg.setPagePath(value2);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!PageRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: PageRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getHash();
    if (f != null) {
      writer.writeMessage(
        1,
        f,
        common_legacy_pb.UniqueHash.serializeBinaryToWriter
      );
    }
    f = message.getPagePath();
    if (f.length > 0) {
      writer.writeString(
        2,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    PageRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional UniqueHash hash = 1;
   * @return {?UniqueHash}
   */
  getHash(): common_legacy_pb.UniqueHash {
    return /** @type{?UniqueHash} */ (
      jspb.Message.getWrapperField(this, common_legacy_pb.UniqueHash, 1));
  }


  /** @param {?UniqueHash|undefined} value */
  setHash(value?: common_legacy_pb.UniqueHash) {
    jspb.Message.setWrapperField(this, 1, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearHash() {
    this.setHash(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasHash(): boolean {
    return jspb.Message.getField(this, 1) != null;
  }


  /**
   * optional string page_path = 2;
   * @return {string}
   */
  getPagePath(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
  };


  /** @param {string} value */
  setPagePath(value: string) {
    jspb.Message.setProto3StringField(this, 2, value);
  }


}
export namespace PageResponse {
  export interface AsObject {
    status?: common_legacy_pb.StatusCode;
    page?: Page.AsObject;
  }
}
export class PageResponse extends jspb.Message {
  static readonly displayName = "PageResponse";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): PageResponse.AsObject {
    return PageResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!PageResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: PageResponse) {
    var f: any;
    var obj: any = {
      status: jspb.Message.getFieldWithDefault(msg, 1, 0),
      page: (f = msg.getPage()) && Page.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!PageResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new PageResponse;
    return PageResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!PageResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!PageResponse}
   */
  static deserializeBinaryFromReader(msg: PageResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {!StatusCode} */ (reader.readEnum());
        msg.setStatus(value1);
        break;
      case 2:
        var value2 = new Page;
        reader.readMessage(value2,Page.deserializeBinaryFromReader);
        msg.setPage(value2);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!PageResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: PageResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getStatus();
    if (f !== 0.0) {
      writer.writeEnum(
        1,
        f
      );
    }
    f = message.getPage();
    if (f != null) {
      writer.writeMessage(
        2,
        f,
        Page.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    PageResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional StatusCode status = 1;
   * @return {!StatusCode}
   */
  getStatus(): common_legacy_pb.StatusCode {
    return /** @type {!StatusCode} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
  };


  /** @param {!StatusCode} value */
  setStatus(value: common_legacy_pb.StatusCode) {
    jspb.Message.setProto3EnumField(this, 1, value);
  }


  /**
   * optional Page page = 2;
   * @return {?Page}
   */
  getPage(): Page {
    return /** @type{?Page} */ (
      jspb.Message.getWrapperField(this, Page, 2));
  }


  /** @param {?Page|undefined} value */
  setPage(value?: Page) {
    jspb.Message.setWrapperField(this, 2, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearPage() {
    this.setPage(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasPage(): boolean {
    return jspb.Message.getField(this, 2) != null;
  }


}
export enum PageStyle {
  DRY = 0,
}
