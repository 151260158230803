import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { IActionThresholdAutomation } from 'libs/domain';
import { ReportTypes } from 'libs/domain';
import { mingaSettingTypes } from 'libs/util';
import { BehaviorSubject, ReplaySubject, combineLatest } from 'rxjs';
import { delay, filter, map, takeUntil, tap } from 'rxjs/operators';

import { MingaSettingsService } from '@app/src/app/store/Minga/services';

import { BehaviorManagerService } from '@modules/behavior-manager/services';
import { LayoutService } from '@modules/layout/services';

import {
  ModalOverlayService,
  ModalOverlayServiceCloseEventType,
} from '@shared/components/modal-overlay';
import { SystemAlertSnackBarService } from '@shared/components/system-alert-snackbar';
import { AutomationGroupService } from '@shared/services/automation';

import {
  BehaviorManagerRoutes,
  BehaviorsSubRoutes,
} from '../../../../constants';
import { BmTypesAutomationMessages } from '../../constants';
import { BmTypesAutomationService } from '../../services';
import {
  BmTypesAutomationEditModalData,
  BmTypesAutomationEditModalResponse,
} from '../../types';
import { BmTypesAutomationsEditGroupComponent } from '../bm-types-automations-edit-group/bm-types-automations-edit-group.component';

@Component({
  selector: 'mg-bm-types-automations',
  templateUrl: './bm-types-automations.component.html',
  styleUrls: ['./bm-types-automations.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BmTypesAutomationsComponent implements OnDestroy, OnInit {
  /** Constants */
  public readonly MESSAGES = BmTypesAutomationMessages;

  /** General subjects */
  private readonly _destroyedSubject = new ReplaySubject<void>(1);

  /** Loading state */
  private readonly _isLoadingSubject = new BehaviorSubject<boolean>(true);
  public readonly isLoading$ = this._isLoadingSubject.asObservable();

  /** Expanded groups state */
  private _expandedGroupsSubject = new BehaviorSubject<number[]>([]);
  public readonly expandedGroups$ = this._expandedGroupsSubject.asObservable();

  /** Toggle State */
  private _togglesSubj = new BehaviorSubject<Set<number>>(new Set());
  public toggles$ = this._togglesSubj
    .asObservable()
    .pipe(map(toggles => toggles.values));

  /** Behavior types */
  public BEHAVIOR_TYPES;

  /** Automation groups */
  private readonly _automationGroupSubject = new BehaviorSubject<
    IActionThresholdAutomation[]
  >([]);
  public readonly automationGroups$ =
    this._automationGroupSubject.asObservable();

  private readonly _groupsWithDisabledConsequenceSubject = new BehaviorSubject<
    IActionThresholdAutomation[]
  >([]);
  public readonly groupsWithDisabledConsequence$ =
    this._groupsWithDisabledConsequenceSubject.asObservable();

  /** Inactive group detected */
  public readonly inactiveGroup$ = this.automationGroups$.pipe(
    takeUntil(this._destroyedSubject),
    map(groups => groups.some(g => !g.active)),
  );

  public readonly automationGroupsWithInactiveBehavior$ = combineLatest([
    this.automationGroups$,
    this.behaviorManager.getBehaviorTypes(false),
  ]).pipe(
    map(([groups, types]) => {
      this._setBehaviorTypes(types);

      return groups.filter(a =>
        (a?.triggers?.pbisTriggers || []).some(
          t => this.BEHAVIOR_TYPES[t.id]?.active === false,
        ),
      );
    }),
  );

  /** No results */
  public readonly noResults$ = this.automationGroups$.pipe(
    takeUntil(this._destroyedSubject),
    map(groups => groups.length === 0),
  );

  public consequencesEnabled$ = this._settingsService.getSettingValueObs(
    mingaSettingTypes.BM_CONSEQUENCE_ENABLE,
  );

  private _scrollToGroupSubject = new BehaviorSubject<number | null>(null);
  public scrollToGroup$ = this._scrollToGroupSubject.asObservable();

  /** Component Constructor */
  constructor(
    public behaviorManager: BehaviorManagerService,
    public bmAutomations: BmTypesAutomationService,
    public systemAlert: SystemAlertSnackBarService,
    public layout: LayoutService,
    private _automationGroup: AutomationGroupService,
    private _router: Router,
    private _modalOverlay: ModalOverlayService<
      BmTypesAutomationEditModalResponse,
      BmTypesAutomationEditModalData
    >,
    private _settingsService: MingaSettingsService,
    public activeRoute: ActivatedRoute,
    private _bmTypesAutomation: BmTypesAutomationService,
  ) {}

  ngOnInit(): void {
    this.bmAutomations.automationGroupUpdated$
      .pipe(takeUntil(this._destroyedSubject))
      .subscribe(({ action, data }) => {
        this._onGroupUpdated(action, data);
      });

    this.consequencesEnabled$
      .pipe(takeUntil(this._destroyedSubject))
      .subscribe(enabled => {
        if (enabled) {
          this._fetchInitialData();
          this._fetchGroupsWithDisabledConsequence();
        } else {
          this._isLoadingSubject.next(false);
        }
      });

    this._bmTypesAutomation.newlyCreatedAutomationGroup$
      .pipe(
        takeUntil(this._destroyedSubject),
        filter(id => !!id),
        tap(id => this._scrollToGroupSubject.next(id)),
        // without a bit of a delay we don't get the scrollTo to work
        delay(100),
      )
      .subscribe(() => {
        this._scrollToGroupSubject.next(null);
      });
  }

  ngOnDestroy(): void {
    this._destroyedSubject.next();
    this._destroyedSubject.complete();
    this._isLoadingSubject.complete();
    this._automationGroupSubject.complete();
  }

  public trackByFn(index, automationGroup: IActionThresholdAutomation) {
    return automationGroup?.id || index;
  }

  public async editGroup(id?: number): Promise<void> {
    const editModal = this._modalOverlay.open(
      BmTypesAutomationsEditGroupComponent,
      {
        data: { id },
        disposeOnNavigation: false,
      },
    );
    editModal.afterClosed.subscribe(async response => {
      if (!response) return;
      const { type, data } = response;
      switch (type) {
        case ModalOverlayServiceCloseEventType.SUBMIT: {
          this.systemAlert.success('Successfully updated automation group');
          const updatedGroup = (data as any)
            ?.updated as IActionThresholdAutomation;
          this._updateGroup(updatedGroup);
          break;
        }
        case ModalOverlayServiceCloseEventType.DELETE: {
          this.systemAlert.success('Successfully deleted automation group');
          const deleted = (data as any)?.deleted as IActionThresholdAutomation;
          this._deleteGroup(deleted);
          break;
        }
        default: {
          break;
        }
      }

      // we route back to automations landing page in case the edit steps form is currently open
      this._router.navigate([
        BehaviorManagerRoutes.ROOT,
        BehaviorManagerRoutes.AUTOMATIONS,
      ]);
    });
  }

  public async toggleGroupActiveStatus(
    group: IActionThresholdAutomation,
    value: boolean,
  ): Promise<void> {
    try {
      const result = await this._automationGroup.update({
        ...group,
        active: value,
      });
      const groups = this._automationGroupSubject.getValue();
      const index = groups.findIndex(g => g.id === group.id);
      groups[index] = result;
      this._automationGroupSubject.next(groups);
      this.systemAlert.success('Successfully updated automation group');
    } catch (error) {
      // error snackbar already handled internally by crud service
    }
  }

  public getReportUrl(item: IActionThresholdAutomation) {
    return `/${BehaviorManagerRoutes.ROOT}/${BehaviorManagerRoutes.REPORTS}/${ReportTypes.PBIS_AUTOMATION_COUNT}?automationType=${item.id}`;
  }

  // need this for mobile which doesn't support opening a new tab with [href] and target="_blank"
  public goToSummary(item: IActionThresholdAutomation) {
    if (!window.MINGA_APP_BROWSER) {
      this._router.navigateByUrl(this.getReportUrl(item));
    }
  }

  public expandItem(id: number) {
    const expandedGroups = this._expandedGroupsSubject.getValue();
    if (expandedGroups.includes(id)) return;
    this._expandedGroupsSubject.next([...expandedGroups, id]);
  }

  public collapseItem(id: number) {
    const expandedGroups = this._expandedGroupsSubject.getValue();
    if (!expandedGroups.includes(id)) return;
    this._expandedGroupsSubject.next(expandedGroups.filter(g => g !== id));
  }

  public async editStep(
    automationGroup: IActionThresholdAutomation,
    stepId?: number,
  ) {
    const segments: any[] = [
      BehaviorManagerRoutes.ROOT,
      BehaviorManagerRoutes.AUTOMATIONS,
      BehaviorsSubRoutes.AUTOMATIONS_STEP_EDIT,
      automationGroup.id,
    ];

    if (stepId) segments.push(stepId);

    this._router.navigate(segments);
  }

  private async _fetchInitialData(): Promise<any> {
    try {
      this._isLoadingSubject.next(true);
      const result = await this._automationGroup.fetchAll();
      this._setAutomationGroups(result);
      if (result.length > 0) this.expandItem(result[0].id);
    } finally {
      this._isLoadingSubject.next(false);
    }
  }
  private async _fetchGroupsWithDisabledConsequence(): Promise<void> {
    try {
      const result = await this._automationGroup.fetchAll(true);
      this._groupsWithDisabledConsequenceSubject.next(result);
      // suppress since this isn't crucial
    } catch (error) {}
  }

  private _setAutomationGroups(groups: IActionThresholdAutomation[]) {
    (groups || []).sort((a, b) =>
      a.name?.toLocaleLowerCase().localeCompare(b.name?.toLocaleLowerCase()),
    );
    this._automationGroupSubject.next(groups);
  }

  private _setBehaviorTypes(behaviors) {
    const hashMap = {};
    behaviors.forEach(behavior => {
      hashMap[behavior.id] = behavior;
    });

    this.BEHAVIOR_TYPES = hashMap;
  }

  private _addGroup(group: IActionThresholdAutomation) {
    const groups = this._automationGroupSubject.getValue();
    groups.unshift(group);
    this._setAutomationGroups(groups);
  }

  private _updateGroup(group: IActionThresholdAutomation) {
    const groups = this._automationGroupSubject.getValue();
    const index = groups.findIndex(g => g.id === group.id);
    groups[index] = group;
    this._setAutomationGroups(groups);
  }

  private _deleteGroup(group: IActionThresholdAutomation) {
    const groups = this._automationGroupSubject.getValue();
    const filtered = groups.filter(g => g.id !== group.id);
    this._setAutomationGroups(filtered);
  }

  private _onGroupUpdated(type, group) {
    // we only care about create atm since this is being triggered from create modal, the other actions are handled direclty in this component
    if (type === 'create') {
      this._addGroup(group);
    }

    // re-validate the disabled consequence groups because this might have changed
    this._fetchGroupsWithDisabledConsequence();
  }
}
