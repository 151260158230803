import { Location, LocationStrategy } from '@angular/common';
import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';

import { MgModalService } from '@app/src/app/minimal/services/MgModal';
import {
  IOverlayConfig,
  IOverlayConfigurable,
  IOverlayNavRefreshable,
  IOverlayPreviousStepper,
  OnOverlayClose,
  OverlayStepInfo,
} from '@app/src/app/misc/overlay';
import { MingaStoreFacadeService } from '@app/src/app/store/Minga/services/MingaStoreFacade.service';

import {
  BroadcastNotificationDialog,
  IBroadcastNotificationDialogResponse,
} from '../BroadcastNotification';
import { SendFeedbackDialog } from '../SendFeedback';

@Component({
  selector: 'mg-dialog-view',
  templateUrl: './DialogView.component.html',
})
export class DialogViewComponent
  implements IOverlayPreviousStepper, OnOverlayClose, IOverlayConfigurable
{
  private onOverlayConfig?: (config: IOverlayConfig) => void;
  constructor(
    private activeRoute: ActivatedRoute,
    private url: LocationStrategy,
    private dialog: MatDialog,
    private mingaStore: MingaStoreFacadeService,
    private modalService: MgModalService,
    private _location: Location,
  ) {
    this.activeRoute.url.subscribe(url => {
      if (this.url.path().includes('broadcast')) {
        this.openBroadcastDialog();
      } else if (this.url.path().includes('feedback')) {
        this.openFeedbackDialog();
      }
    });
  }

  registerOnOverlayConfig(fn: (config: IOverlayConfig) => void) {
    this.onOverlayConfig = fn;

    this.onOverlayConfig({
      disableOverlayPulldown: true,
    });
  }

  async onOverlayPrevious() {
    this._location.back();
  }

  hasOverlayPrevious(): OverlayStepInfo {
    return {
      icon: '',
      text: '',
    };
  }

  async onOverlayClose() {
    this._location.back();

    return true;
  }

  async openBroadcastDialog() {
    const minga = await this.mingaStore.getMingaAsPromise();
    let options = { data: { mingaName: minga.name }, autoFocus: false };

    let dialog = this.dialog.open(BroadcastNotificationDialog, options);
    dialog
      .afterClosed()
      .subscribe(async (result: IBroadcastNotificationDialogResponse) => {
        if (result && !result.canceled && result.successOverlay) {
          let dialogRef = this.modalService.open(result.successOverlay, {
            full: true,
            animation: 'fade',
          });
          setTimeout(() => dialogRef.close(), 2000);
          // Wait for the animation to start a tiny bit
          await new Promise(resolve => setTimeout(resolve, 1000));
        }
        await this.onOverlayClose();
      });
  }

  async openFeedbackDialog() {
    let dialog = this.dialog.open(SendFeedbackDialog, {
      autoFocus: !window.MINGA_DEVICE_IOS,
    });
    dialog.afterClosed().subscribe(async result => {
      if (result && result.successOverlay) {
        let dialogRef = this.modalService.open(result.successOverlay, {
          full: true,
          animation: 'fade',
        });
        setTimeout(() => dialogRef.close(), 2000);
        // Wait for the animation to start a tiny bit{
        await new Promise(resolve => setTimeout(resolve, 1000));
      }
      await this.onOverlayClose();
    });
  }
}
