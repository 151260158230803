import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { IConsequenceStatsPeopleResult } from 'libs/domain';
import {
  BehaviorReportColumns,
  ColumnInfo,
  TemplateColumnKeys,
} from 'libs/shared';

import { ReportDatasourceService } from '@app/src/app/components/manager-report/services/report-datasource.service';

import { BHStudentConsequenceHistoryInfo } from '../constants';
import { BmReportsService } from './bm-reports.service';

@Injectable()
export class BmReportsConsequenceOverdueDatasourceService extends ReportDatasourceService<IConsequenceStatsPeopleResult> {
  historyInfo = BHStudentConsequenceHistoryInfo;

  constructor(_router: Router, private _pbisReportsService: BmReportsService) {
    super(_router, _pbisReportsService);
    const displayCol: ColumnInfo[] = [
      { key: TemplateColumnKeys.HISTORY, header: 'History' },
    ];
    super.setDisplayColumns(
      BehaviorReportColumns.CONSEQUENCE_OVERDUE,
      displayCol,
    );
  }

  async fetch(offset: number, limit: number) {
    return await this._pbisReportsService.getConsequenceOverdue(
      offset,
      limit,
      this._currentSort,
    );
  }
}
