import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { gateway } from 'libs/generated-grpc-web';

import { ContentEvents } from '@app/src/app/minimal/services/ContentEvents';
import { ContentState } from '@app/src/app/services/ContentState';

import { MgMiniCardBaseElement } from '../MgMiniCardBaseElement';

@Component({
  selector: 'mg-basic-mini-card',
  templateUrl: './MgBasicMiniCard.element.html',
  styleUrls: ['./MgBasicMiniCard.element.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MgBasicMiniCardElement extends MgMiniCardBaseElement {
  @Input()
  content?: gateway.content_views_pb.ShortCardView.AsObject;

  @Input()
  context: string = '';

  constructor(
    public contentevents: ContentEvents,
    public contentstate: ContentState,
  ) {
    super();
  }
}
