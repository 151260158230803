import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { MgOverlayToolsModule } from '@app/src/app/overlay/tools';

import { LongCardBaseModule } from '../LongCardBase';
import { InspirationLongCardComponent } from './InspirationLongCard.component';

@NgModule({
  imports: [
    // Minga dependencies
    LongCardBaseModule,
    MgOverlayToolsModule,

    // External dependencies
    CommonModule,
  ],
  declarations: [InspirationLongCardComponent],
  exports: [InspirationLongCardComponent],
})
export class InspirationLongCardModule {}
