import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';

import { MgEmptyStateModule } from '@app/src/app/components/EmptyState';
import { MgPipesModule } from '@app/src/app/pipes';
import { MgSpinnerModule } from '@app/src/app/spinner';

import { FormModule } from '@shared/components/form';
import { GenericModule } from '@shared/components/generic';
import { ManagerOverlayModule } from '@shared/components/manager-overlay';
import { OverlayModule } from '@shared/components/modal-overlay';
import { MultiPersonSearchModule } from '@shared/components/multi-person-search';
import { PaginatorModule } from '@shared/components/paginator';
import { SystemAlertModule } from '@shared/components/system-alert';
import { MobileCardModule } from '@shared/components/tables/mobile-card/mobile-card.module';
import { TextModule } from '@shared/components/text';
import { UserListFilterModule } from '@shared/components/user-list-filter/user-list-filter.module';
import { ArrayPipesModule } from '@shared/pipes';

import { MmSrHistoryTableComponent } from './components/mm-sr-history-table/mm-sr-history-table.component';
import { SrBehaviorFiltersComponent } from './components/mm-sr-reports-edit/components/sr-behavior-filters/sr-behavior-filters.component';
import { SrCheckinFiltersComponent } from './components/mm-sr-reports-edit/components/sr-checkin-filters/sr-checkin-filters.component';
import { SrConsequenceFiltersComponent } from './components/mm-sr-reports-edit/components/sr-consequence-filters/sr-consequence-filters.component';
import { SrConsequenceOverdueFiltersComponent } from './components/mm-sr-reports-edit/components/sr-consequence-overdue-filters/sr-consequence-overdue-filters.component';
import { SrFiltersWrapComponent } from './components/mm-sr-reports-edit/components/sr-filters-wrap/sr-filters-wrap.component';
import { SrFlexFiltersComponent } from './components/mm-sr-reports-edit/components/sr-flex-filters/sr-flex-filters.component';
import { SrHallPassFiltersComponent } from './components/mm-sr-reports-edit/components/sr-hall-pass-filters/sr-hall-pass-filters.component';
import { MmSrReportsEditComponent } from './components/mm-sr-reports-edit/mm-sr-reports-edit.component';
import { MmSrReportsTableComponent } from './components/mm-sr-reports-table/mm-sr-reports-table.component';
import { MmScheduledReportsComponent } from './mm-scheduled-reports.component';
import { DeliveryTypeLabelPipe } from './pipes/delivery-type-label.pipe';
import { FrequencyHintPipe } from './pipes/frequency-hint.pipe';
import { FrequencyLabelPipe } from './pipes/frequency-label.pipe';
import { ReportErrorPipe } from './pipes/report-error.pipe';
import { ReportTypeLabelPipe } from './pipes/report-type-label.pipe';

@NgModule({
  declarations: [
    MmScheduledReportsComponent,
    MmSrReportsTableComponent,
    MmSrReportsEditComponent,
    SrBehaviorFiltersComponent,
    MmSrHistoryTableComponent,
    ReportTypeLabelPipe,
    FrequencyLabelPipe,
    DeliveryTypeLabelPipe,
    ReportErrorPipe,
    SrCheckinFiltersComponent,
    SrFiltersWrapComponent,
    SrHallPassFiltersComponent,
    SrConsequenceFiltersComponent,
    SrConsequenceOverdueFiltersComponent,
    FrequencyHintPipe,
    SrFlexFiltersComponent,
  ],
  imports: [
    CommonModule,
    ManagerOverlayModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    TextModule,
    GenericModule,
    OverlayModule,
    FormModule,
    FlexLayoutModule,
    MgSpinnerModule,
    MgEmptyStateModule,
    GenericModule,
    MultiPersonSearchModule,
    ReactiveFormsModule,
    ArrayPipesModule,
    MgPipesModule,
    MobileCardModule,
    SystemAlertModule,
    MatButtonToggleModule,
    PaginatorModule,
    MatExpansionModule,
    UserListFilterModule,
  ],
})
export class MmScheduledReportsModule {}
