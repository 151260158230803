// GENERATED CODE -- DO NOT EDIT!

import {grpc} from '@improbable-eng/grpc-web';

import * as content_email_content_pb from '../content/email_content_pb';

export class EmailContent {
  static readonly serviceName = "EmailContent";

  static readonly Send = {
    methodName: "Send",
    service: EmailContent,
    requestStream: false,
    responseStream: false,
    requestType: content_email_content_pb.EmailContentSendRequest,
    responseType: content_email_content_pb.EmailContentSendResponse,
  };

  static readonly Config = {
    methodName: "Config",
    service: EmailContent,
    requestStream: false,
    responseStream: false,
    requestType: content_email_content_pb.EmailContentConfigRequest,
    responseType: content_email_content_pb.EmailContentConfigResponse,
  };

}

export class EmailContentClient {
  send() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  config() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
}
