import { MingaPermission, MingaRoleType } from 'libs/domain';
import { DisplayNameFormat, mingaSettingTypes } from 'libs/util';

import { UserRole } from './UserRole';

/**
 * @deprecated no longer used
 */
export class DistrictLeaderRole extends UserRole {
  readonly roleType = MingaRoleType.DISTRICT_LEADER;
  readonly name = 'District Leader';
  readonly displayNameFormat = DisplayNameFormat.F_LAST;
  readonly iconUrl = 'assets/roles/district.svg';
  readonly iconColor = '#1D2F5A';
  readonly capabilitiesDescription = `District leaders will be allowed to share announcements, posts, pictures, and videos`;
  readonly feedPermittable = true;
  readonly galleryPermittable = true;
  readonly groupFeedPermittable = true;
  readonly programManagePermittable = true;
  readonly videoUploadPermittable = true;
  readonly commentPermittable = true;
  readonly groupCreatePermittable = true;
  readonly admin = true;
  readonly requiredFeatures = [mingaSettingTypes.FEATURE_DISTRICT_ENABLED];
  readonly sendToMailChimp = false;

  readonly permissions = [
    MingaPermission.ACCOUNT_DISPLAYNAME_CHANGE,
    MingaPermission.ACCOUNT_PROFILE_CHANGE,
    MingaPermission.APP_BROADCAST_SEND,
    MingaPermission.ASSET_UPLOAD,
    MingaPermission.CONTENT_ANNOUNCEMENT_CREATE,
    MingaPermission.CONTENT_COMMENT_CREATE,
    MingaPermission.CONTENT_COMMENTS_VIEW,
    MingaPermission.CONTENT_EVENT_CREATE,
    MingaPermission.CONTENT_GROUP_CREATE,
    MingaPermission.CONTENT_GROUP_VIEWALL,
    MingaPermission.CONTENT_GROUP_ENABLED,
    MingaPermission.CONTENT_GROUP_MANAGE,
    MingaPermission.CONTENT_HTML_INCLUDE,
    MingaPermission.CONTENT_LIKES_LIKE,
    MingaPermission.CONTENT_MODERATION_OVERRIDE,
    MingaPermission.CONTENT_POLL_CREATE,
    MingaPermission.CONTENT_POLL_ANSWER,
    MingaPermission.CONTENT_POST_CREATE,
    MingaPermission.CONTENT_POST_SET_TITLE,
    MingaPermission.CONTENT_PUBLISH_DATES_SET,
    MingaPermission.CONTENT_REPORT_CREATE,
    MingaPermission.CONTENT_VIDEO_CREATE,
    MingaPermission.GROUP_BROADCAST_SEND,
    MingaPermission.GROUP_CONTENT_ANNOUNCEMENT_CREATE,
    MingaPermission.GROUP_CONTENT_EVENTS_CREATE,
    MingaPermission.GROUP_CONTENT_POLL_CREATE,
    MingaPermission.GROUP_CONTENT_POST_CREATE,
    MingaPermission.GROUP_CONTENT_VIDEO_CREATE,
    MingaPermission.MINGA_CONTENT_PIN,
    MingaPermission.MINGA_FILE_CREATE,
    MingaPermission.MINGA_POLLS_MANAGE,
    MingaPermission.MINGA_PROGRAMS_MANAGE,
    MingaPermission.CONTENT_CREATED_MINGA_DESIGNER_CONTENT,
    MingaPermission.GROUP_ALLOW_PARENT_GROUP,
    MingaPermission.GROUP_ALLOW_ANY_PARENT_GROUP,
    MingaPermission.EMAIL_CONTENT,
    MingaPermission.EMAIL_CONTENT_AS_GROUP_OWNER,
    MingaPermission.CONTENT_MANAGE_COMMENT_SETTINGS,
    MingaPermission.CONTENT_CHALLENGE_CREATE,
    MingaPermission.GROUP_CONTENT_CHALLENGE_CREATE,
    MingaPermission.CONTENT_CHALLENGE_MANAGE,
    MingaPermission.CHALLENGE_COMPLETE,
    MingaPermission.SCHEDULED_REPORTS_MANAGE,
    MingaPermission.SCHEDULED_REPORTS_DOWNLOAD_PAST_REPORT,
  ];
  readonly overridablePermissions = [];
}
