// source: automation/automation.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

import * as jspb from 'google-protobuf';
export namespace TriggerType {
  export interface AsObject {
    id: number;
    triggerTypeKind?: TriggerTypeKind;
  }
}
export class TriggerType extends jspb.Message {
  static readonly displayName = "TriggerType";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): TriggerType.AsObject {
    return TriggerType.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!TriggerType} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: TriggerType) {
    var f: any;
    var obj: any = {
      id: jspb.Message.getFieldWithDefault(msg, 1, 0),
      triggerTypeKind: jspb.Message.getFieldWithDefault(msg, 2, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!TriggerType}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new TriggerType;
    return TriggerType.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!TriggerType} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!TriggerType}
   */
  static deserializeBinaryFromReader(msg: TriggerType, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {number} */ (reader.readInt32());
        msg.setId(value1);
        break;
      case 2:
        var value2 = /** @type {!TriggerTypeKind} */ (reader.readEnum());
        msg.setTriggerTypeKind(value2);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!TriggerType} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: TriggerType, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getId();
    if (f !== 0) {
      writer.writeInt32(
        1,
        f
      );
    }
    f = message.getTriggerTypeKind();
    if (f !== 0.0) {
      writer.writeEnum(
        2,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    TriggerType.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional int32 id = 1;
   * @return {number}
   */
  getId(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
  };


  /** @param {number} value */
  setId(value: number) {
    jspb.Message.setProto3IntField(this, 1, value);
  }


  /**
   * optional TriggerTypeKind trigger_type_kind = 2;
   * @return {!TriggerTypeKind}
   */
  getTriggerTypeKind(): TriggerTypeKind {
    return /** @type {!TriggerTypeKind} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
  };


  /** @param {!TriggerTypeKind} value */
  setTriggerTypeKind(value: TriggerTypeKind) {
    jspb.Message.setProto3EnumField(this, 2, value);
  }


}
export namespace TriggerByType {
  export interface AsObject {
    id: number;
    triggerByTypeKind?: TriggerByType.TriggerByTypeKind;
  }
}
export class TriggerByType extends jspb.Message {
  static readonly displayName = "TriggerByType";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): TriggerByType.AsObject {
    return TriggerByType.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!TriggerByType} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: TriggerByType) {
    var f: any;
    var obj: any = {
      id: jspb.Message.getFieldWithDefault(msg, 1, 0),
      triggerByTypeKind: jspb.Message.getFieldWithDefault(msg, 2, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!TriggerByType}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new TriggerByType;
    return TriggerByType.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!TriggerByType} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!TriggerByType}
   */
  static deserializeBinaryFromReader(msg: TriggerByType, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {number} */ (reader.readInt32());
        msg.setId(value1);
        break;
      case 2:
        var value2 = /** @type {!TriggerByType.TriggerByTypeKind} */ (reader.readEnum());
        msg.setTriggerByTypeKind(value2);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!TriggerByType} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: TriggerByType, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getId();
    if (f !== 0) {
      writer.writeInt32(
        1,
        f
      );
    }
    f = message.getTriggerByTypeKind();
    if (f !== 0.0) {
      writer.writeEnum(
        2,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    TriggerByType.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional int32 id = 1;
   * @return {number}
   */
  getId(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
  };


  /** @param {number} value */
  setId(value: number) {
    jspb.Message.setProto3IntField(this, 1, value);
  }


  /**
   * optional TriggerByTypeKind trigger_by_type_kind = 2;
   * @return {!TriggerByType.TriggerByTypeKind}
   */
  getTriggerByTypeKind(): TriggerByType.TriggerByTypeKind {
    return /** @type {!TriggerByType.TriggerByTypeKind} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
  };


  /** @param {!TriggerByType.TriggerByTypeKind} value */
  setTriggerByTypeKind(value: TriggerByType.TriggerByTypeKind) {
    jspb.Message.setProto3EnumField(this, 2, value);
  }


}
export namespace TriggerByType {
export enum TriggerByTypeKind {
  PBIS = 0,
  CHECKIN = 1,
}
} // namespace TriggerByType
export namespace Automation {
  export interface AsObject {
    id: number;
    mingaId: number;
    name: string;
    description: string;
    note: string;
    triggerByTypesList: TriggerByType.AsObject[];
    triggerTypesList: TriggerType.AsObject[];
    threshold: number;
    active: boolean;
    dailyAutomationCount: number;
    completeByDays: number;
    resetCounter: boolean;
    triggers?: AutomationTriggers.AsObject;
    autoResetList: AutomationAutoReset.AsObject[];
  }
}
export class Automation extends jspb.Message {
  static readonly displayName = "Automation";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [6,7,14];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, Automation.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): Automation.AsObject {
    return Automation.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!Automation} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: Automation) {
    var f: any;
    var obj: any = {
      id: jspb.Message.getFieldWithDefault(msg, 1, 0),
      mingaId: jspb.Message.getFieldWithDefault(msg, 2, 0),
      name: jspb.Message.getFieldWithDefault(msg, 3, ""),
      description: jspb.Message.getFieldWithDefault(msg, 4, ""),
      note: jspb.Message.getFieldWithDefault(msg, 5, ""),
      triggerByTypesList: jspb.Message.toObjectList(msg.getTriggerByTypesList(),
      TriggerByType.toObject, includeInstance),
      triggerTypesList: jspb.Message.toObjectList(msg.getTriggerTypesList(),
      TriggerType.toObject, includeInstance),
      threshold: jspb.Message.getFieldWithDefault(msg, 8, 0),
      active: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
      dailyAutomationCount: jspb.Message.getFieldWithDefault(msg, 10, 0),
      completeByDays: jspb.Message.getFieldWithDefault(msg, 11, 0),
      resetCounter: jspb.Message.getBooleanFieldWithDefault(msg, 12, false),
      triggers: (f = msg.getTriggers()) && AutomationTriggers.toObject(includeInstance, f),
      autoResetList: jspb.Message.toObjectList(msg.getAutoResetList(),
      AutomationAutoReset.toObject, includeInstance)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!Automation}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new Automation;
    return Automation.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!Automation} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!Automation}
   */
  static deserializeBinaryFromReader(msg: Automation, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {number} */ (reader.readInt32());
        msg.setId(value1);
        break;
      case 2:
        var value2 = /** @type {number} */ (reader.readInt32());
        msg.setMingaId(value2);
        break;
      case 3:
        var value3 = /** @type {string} */ (reader.readString());
        msg.setName(value3);
        break;
      case 4:
        var value4 = /** @type {string} */ (reader.readString());
        msg.setDescription(value4);
        break;
      case 5:
        var value5 = /** @type {string} */ (reader.readString());
        msg.setNote(value5);
        break;
      case 6:
        var value6 = new TriggerByType;
        reader.readMessage(value6,TriggerByType.deserializeBinaryFromReader);
        msg.addTriggerByTypes(value6);
        break;
      case 7:
        var value7 = new TriggerType;
        reader.readMessage(value7,TriggerType.deserializeBinaryFromReader);
        msg.addTriggerTypes(value7);
        break;
      case 8:
        var value8 = /** @type {number} */ (reader.readInt32());
        msg.setThreshold(value8);
        break;
      case 9:
        var value9 = /** @type {boolean} */ (reader.readBool());
        msg.setActive(value9);
        break;
      case 10:
        var value10 = /** @type {number} */ (reader.readInt32());
        msg.setDailyAutomationCount(value10);
        break;
      case 11:
        var value11 = /** @type {number} */ (reader.readInt32());
        msg.setCompleteByDays(value11);
        break;
      case 12:
        var value12 = /** @type {boolean} */ (reader.readBool());
        msg.setResetCounter(value12);
        break;
      case 13:
        var value13 = new AutomationTriggers;
        reader.readMessage(value13,AutomationTriggers.deserializeBinaryFromReader);
        msg.setTriggers(value13);
        break;
      case 14:
        var value14 = new AutomationAutoReset;
        reader.readMessage(value14,AutomationAutoReset.deserializeBinaryFromReader);
        msg.addAutoReset(value14);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!Automation} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: Automation, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getId();
    if (f !== 0) {
      writer.writeInt32(
        1,
        f
      );
    }
    f = message.getMingaId();
    if (f !== 0) {
      writer.writeInt32(
        2,
        f
      );
    }
    f = message.getName();
    if (f.length > 0) {
      writer.writeString(
        3,
        f
      );
    }
    f = message.getDescription();
    if (f.length > 0) {
      writer.writeString(
        4,
        f
      );
    }
    f = message.getNote();
    if (f.length > 0) {
      writer.writeString(
        5,
        f
      );
    }
    f = message.getTriggerByTypesList();
    if (f.length > 0) {
      writer.writeRepeatedMessage(
        6,
        f,
        TriggerByType.serializeBinaryToWriter
      );
    }
    f = message.getTriggerTypesList();
    if (f.length > 0) {
      writer.writeRepeatedMessage(
        7,
        f,
        TriggerType.serializeBinaryToWriter
      );
    }
    f = message.getThreshold();
    if (f !== 0) {
      writer.writeInt32(
        8,
        f
      );
    }
    f = message.getActive();
    if (f) {
      writer.writeBool(
        9,
        f
      );
    }
    f = message.getDailyAutomationCount();
    if (f !== 0) {
      writer.writeInt32(
        10,
        f
      );
    }
    f = message.getCompleteByDays();
    if (f !== 0) {
      writer.writeInt32(
        11,
        f
      );
    }
    f = message.getResetCounter();
    if (f) {
      writer.writeBool(
        12,
        f
      );
    }
    f = message.getTriggers();
    if (f != null) {
      writer.writeMessage(
        13,
        f,
        AutomationTriggers.serializeBinaryToWriter
      );
    }
    f = message.getAutoResetList();
    if (f.length > 0) {
      writer.writeRepeatedMessage(
        14,
        f,
        AutomationAutoReset.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    Automation.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional int32 id = 1;
   * @return {number}
   */
  getId(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
  };


  /** @param {number} value */
  setId(value: number) {
    jspb.Message.setProto3IntField(this, 1, value);
  }


  /**
   * optional int32 minga_id = 2;
   * @return {number}
   */
  getMingaId(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
  };


  /** @param {number} value */
  setMingaId(value: number) {
    jspb.Message.setProto3IntField(this, 2, value);
  }


  /**
   * optional string name = 3;
   * @return {string}
   */
  getName(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
  };


  /** @param {string} value */
  setName(value: string) {
    jspb.Message.setProto3StringField(this, 3, value);
  }


  /**
   * optional string description = 4;
   * @return {string}
   */
  getDescription(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
  };


  /** @param {string} value */
  setDescription(value: string) {
    jspb.Message.setProto3StringField(this, 4, value);
  }


  /**
   * optional string note = 5;
   * @return {string}
   */
  getNote(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
  };


  /** @param {string} value */
  setNote(value: string) {
    jspb.Message.setProto3StringField(this, 5, value);
  }


  /**
   * repeated TriggerByType trigger_by_types = 6;
   * @return {!Array<!TriggerByType>}
   */
  getTriggerByTypesList(): TriggerByType[] {
    return /** @type{!Array<!TriggerByType>} */ (
      jspb.Message.getRepeatedWrapperField(this, TriggerByType, 6));
  }


  /** @param {!Array<!TriggerByType>} value */
  setTriggerByTypesList(value?: TriggerByType[]) {
    jspb.Message.setRepeatedWrapperField(this, 6, value);
  }


  /**
   * @param {!TriggerByType=} opt_value
   * @param {number=} opt_index
   * @return {!TriggerByType}
   */
  addTriggerByTypes(opt_value?: TriggerByType, opt_index?: number): TriggerByType {
    return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, TriggerByType, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearTriggerByTypesList() {
    this.setTriggerByTypesList([]);
  }


  /**
   * repeated TriggerType trigger_types = 7;
   * @return {!Array<!TriggerType>}
   */
  getTriggerTypesList(): TriggerType[] {
    return /** @type{!Array<!TriggerType>} */ (
      jspb.Message.getRepeatedWrapperField(this, TriggerType, 7));
  }


  /** @param {!Array<!TriggerType>} value */
  setTriggerTypesList(value?: TriggerType[]) {
    jspb.Message.setRepeatedWrapperField(this, 7, value);
  }


  /**
   * @param {!TriggerType=} opt_value
   * @param {number=} opt_index
   * @return {!TriggerType}
   */
  addTriggerTypes(opt_value?: TriggerType, opt_index?: number): TriggerType {
    return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, TriggerType, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearTriggerTypesList() {
    this.setTriggerTypesList([]);
  }


  /**
   * optional int32 threshold = 8;
   * @return {number}
   */
  getThreshold(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
  };


  /** @param {number} value */
  setThreshold(value: number) {
    jspb.Message.setProto3IntField(this, 8, value);
  }


  /**
   * optional bool active = 9;
   * @return {boolean}
   */
  getActive(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
  };


  /** @param {boolean} value */
  setActive(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 9, value);
  }


  /**
   * optional int32 daily_automation_count = 10;
   * @return {number}
   */
  getDailyAutomationCount(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
  };


  /** @param {number} value */
  setDailyAutomationCount(value: number) {
    jspb.Message.setProto3IntField(this, 10, value);
  }


  /**
   * optional int32 complete_by_days = 11;
   * @return {number}
   */
  getCompleteByDays(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
  };


  /** @param {number} value */
  setCompleteByDays(value: number) {
    jspb.Message.setProto3IntField(this, 11, value);
  }


  /**
   * optional bool reset_counter = 12;
   * @return {boolean}
   */
  getResetCounter(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 12, false));
  };


  /** @param {boolean} value */
  setResetCounter(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 12, value);
  }


  /**
   * optional AutomationTriggers triggers = 13;
   * @return {?AutomationTriggers}
   */
  getTriggers(): AutomationTriggers {
    return /** @type{?AutomationTriggers} */ (
      jspb.Message.getWrapperField(this, AutomationTriggers, 13));
  }


  /** @param {?AutomationTriggers|undefined} value */
  setTriggers(value?: AutomationTriggers) {
    jspb.Message.setWrapperField(this, 13, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearTriggers() {
    this.setTriggers(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasTriggers(): boolean {
    return jspb.Message.getField(this, 13) != null;
  }


  /**
   * repeated AutomationAutoReset auto_reset = 14;
   * @return {!Array<!AutomationAutoReset>}
   */
  getAutoResetList(): AutomationAutoReset[] {
    return /** @type{!Array<!AutomationAutoReset>} */ (
      jspb.Message.getRepeatedWrapperField(this, AutomationAutoReset, 14));
  }


  /** @param {!Array<!AutomationAutoReset>} value */
  setAutoResetList(value?: AutomationAutoReset[]) {
    jspb.Message.setRepeatedWrapperField(this, 14, value);
  }


  /**
   * @param {!AutomationAutoReset=} opt_value
   * @param {number=} opt_index
   * @return {!AutomationAutoReset}
   */
  addAutoReset(opt_value?: AutomationAutoReset, opt_index?: number): AutomationAutoReset {
    return jspb.Message.addToRepeatedWrapperField(this, 14, opt_value, AutomationAutoReset, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearAutoResetList() {
    this.setAutoResetList([]);
  }


}
export namespace AutomationAutoReset {
  export interface AsObject {
    time: string;
    frequency: string;
    dayOfWeek: string;
    date: string;
  }
}
export class AutomationAutoReset extends jspb.Message {
  static readonly displayName = "AutomationAutoReset";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): AutomationAutoReset.AsObject {
    return AutomationAutoReset.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!AutomationAutoReset} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: AutomationAutoReset) {
    var f: any;
    var obj: any = {
      time: jspb.Message.getFieldWithDefault(msg, 1, ""),
      frequency: jspb.Message.getFieldWithDefault(msg, 2, ""),
      dayOfWeek: jspb.Message.getFieldWithDefault(msg, 3, ""),
      date: jspb.Message.getFieldWithDefault(msg, 4, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!AutomationAutoReset}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new AutomationAutoReset;
    return AutomationAutoReset.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!AutomationAutoReset} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!AutomationAutoReset}
   */
  static deserializeBinaryFromReader(msg: AutomationAutoReset, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setTime(value1);
        break;
      case 2:
        var value2 = /** @type {string} */ (reader.readString());
        msg.setFrequency(value2);
        break;
      case 3:
        var value3 = /** @type {string} */ (reader.readString());
        msg.setDayOfWeek(value3);
        break;
      case 4:
        var value4 = /** @type {string} */ (reader.readString());
        msg.setDate(value4);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!AutomationAutoReset} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: AutomationAutoReset, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getTime();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
    f = message.getFrequency();
    if (f.length > 0) {
      writer.writeString(
        2,
        f
      );
    }
    f = message.getDayOfWeek();
    if (f.length > 0) {
      writer.writeString(
        3,
        f
      );
    }
    f = message.getDate();
    if (f.length > 0) {
      writer.writeString(
        4,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    AutomationAutoReset.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string time = 1;
   * @return {string}
   */
  getTime(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setTime(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


  /**
   * optional string frequency = 2;
   * @return {string}
   */
  getFrequency(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
  };


  /** @param {string} value */
  setFrequency(value: string) {
    jspb.Message.setProto3StringField(this, 2, value);
  }


  /**
   * optional string day_of_week = 3;
   * @return {string}
   */
  getDayOfWeek(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
  };


  /** @param {string} value */
  setDayOfWeek(value: string) {
    jspb.Message.setProto3StringField(this, 3, value);
  }


  /**
   * optional string date = 4;
   * @return {string}
   */
  getDate(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
  };


  /** @param {string} value */
  setDate(value: string) {
    jspb.Message.setProto3StringField(this, 4, value);
  }


}
export namespace AutomationTriggers {
  export interface AsObject {
    behaviorsList: AutomationTrigger.AsObject[];
    checkinReasonList: AutomationTrigger.AsObject[];
  }
}
export class AutomationTriggers extends jspb.Message {
  static readonly displayName = "AutomationTriggers";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [1,2];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, AutomationTriggers.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): AutomationTriggers.AsObject {
    return AutomationTriggers.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!AutomationTriggers} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: AutomationTriggers) {
    var f: any;
    var obj: any = {
      behaviorsList: jspb.Message.toObjectList(msg.getBehaviorsList(),
      AutomationTrigger.toObject, includeInstance),
      checkinReasonList: jspb.Message.toObjectList(msg.getCheckinReasonList(),
      AutomationTrigger.toObject, includeInstance)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!AutomationTriggers}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new AutomationTriggers;
    return AutomationTriggers.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!AutomationTriggers} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!AutomationTriggers}
   */
  static deserializeBinaryFromReader(msg: AutomationTriggers, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new AutomationTrigger;
        reader.readMessage(value1,AutomationTrigger.deserializeBinaryFromReader);
        msg.addBehaviors(value1);
        break;
      case 2:
        var value2 = new AutomationTrigger;
        reader.readMessage(value2,AutomationTrigger.deserializeBinaryFromReader);
        msg.addCheckinReason(value2);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!AutomationTriggers} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: AutomationTriggers, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getBehaviorsList();
    if (f.length > 0) {
      writer.writeRepeatedMessage(
        1,
        f,
        AutomationTrigger.serializeBinaryToWriter
      );
    }
    f = message.getCheckinReasonList();
    if (f.length > 0) {
      writer.writeRepeatedMessage(
        2,
        f,
        AutomationTrigger.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    AutomationTriggers.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * repeated AutomationTrigger behaviors = 1;
   * @return {!Array<!AutomationTrigger>}
   */
  getBehaviorsList(): AutomationTrigger[] {
    return /** @type{!Array<!AutomationTrigger>} */ (
      jspb.Message.getRepeatedWrapperField(this, AutomationTrigger, 1));
  }


  /** @param {!Array<!AutomationTrigger>} value */
  setBehaviorsList(value?: AutomationTrigger[]) {
    jspb.Message.setRepeatedWrapperField(this, 1, value);
  }


  /**
   * @param {!AutomationTrigger=} opt_value
   * @param {number=} opt_index
   * @return {!AutomationTrigger}
   */
  addBehaviors(opt_value?: AutomationTrigger, opt_index?: number): AutomationTrigger {
    return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, AutomationTrigger, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearBehaviorsList() {
    this.setBehaviorsList([]);
  }


  /**
   * repeated AutomationTrigger checkin_reason = 2;
   * @return {!Array<!AutomationTrigger>}
   */
  getCheckinReasonList(): AutomationTrigger[] {
    return /** @type{!Array<!AutomationTrigger>} */ (
      jspb.Message.getRepeatedWrapperField(this, AutomationTrigger, 2));
  }


  /** @param {!Array<!AutomationTrigger>} value */
  setCheckinReasonList(value?: AutomationTrigger[]) {
    jspb.Message.setRepeatedWrapperField(this, 2, value);
  }


  /**
   * @param {!AutomationTrigger=} opt_value
   * @param {number=} opt_index
   * @return {!AutomationTrigger}
   */
  addCheckinReason(opt_value?: AutomationTrigger, opt_index?: number): AutomationTrigger {
    return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, AutomationTrigger, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearCheckinReasonList() {
    this.setCheckinReasonList([]);
  }


}
export namespace AutomationTrigger {
  export interface AsObject {
    id: number;
    name: string;
    iconType: string;
    iconColor: string;
  }
}
export class AutomationTrigger extends jspb.Message {
  static readonly displayName = "AutomationTrigger";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): AutomationTrigger.AsObject {
    return AutomationTrigger.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!AutomationTrigger} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: AutomationTrigger) {
    var f: any;
    var obj: any = {
      id: jspb.Message.getFieldWithDefault(msg, 1, 0),
      name: jspb.Message.getFieldWithDefault(msg, 2, ""),
      iconType: jspb.Message.getFieldWithDefault(msg, 3, ""),
      iconColor: jspb.Message.getFieldWithDefault(msg, 4, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!AutomationTrigger}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new AutomationTrigger;
    return AutomationTrigger.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!AutomationTrigger} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!AutomationTrigger}
   */
  static deserializeBinaryFromReader(msg: AutomationTrigger, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {number} */ (reader.readInt32());
        msg.setId(value1);
        break;
      case 2:
        var value2 = /** @type {string} */ (reader.readString());
        msg.setName(value2);
        break;
      case 3:
        var value3 = /** @type {string} */ (reader.readString());
        msg.setIconType(value3);
        break;
      case 4:
        var value4 = /** @type {string} */ (reader.readString());
        msg.setIconColor(value4);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!AutomationTrigger} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: AutomationTrigger, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getId();
    if (f !== 0) {
      writer.writeInt32(
        1,
        f
      );
    }
    f = message.getName();
    if (f.length > 0) {
      writer.writeString(
        2,
        f
      );
    }
    f = message.getIconType();
    if (f.length > 0) {
      writer.writeString(
        3,
        f
      );
    }
    f = message.getIconColor();
    if (f.length > 0) {
      writer.writeString(
        4,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    AutomationTrigger.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional int32 id = 1;
   * @return {number}
   */
  getId(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
  };


  /** @param {number} value */
  setId(value: number) {
    jspb.Message.setProto3IntField(this, 1, value);
  }


  /**
   * optional string name = 2;
   * @return {string}
   */
  getName(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
  };


  /** @param {string} value */
  setName(value: string) {
    jspb.Message.setProto3StringField(this, 2, value);
  }


  /**
   * optional string icon_type = 3;
   * @return {string}
   */
  getIconType(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
  };


  /** @param {string} value */
  setIconType(value: string) {
    jspb.Message.setProto3StringField(this, 3, value);
  }


  /**
   * optional string icon_color = 4;
   * @return {string}
   */
  getIconColor(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
  };


  /** @param {string} value */
  setIconColor(value: string) {
    jspb.Message.setProto3StringField(this, 4, value);
  }


}
export namespace ListAutomationsRequest {
  export interface AsObject {
    triggerType?: TriggerTypeKind;
    active: boolean;
    onlyDisabledConsequence: boolean;
  }
}
export class ListAutomationsRequest extends jspb.Message {
  static readonly displayName = "ListAutomationsRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): ListAutomationsRequest.AsObject {
    return ListAutomationsRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!ListAutomationsRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: ListAutomationsRequest) {
    var f: any;
    var obj: any = {
      triggerType: jspb.Message.getFieldWithDefault(msg, 1, 0),
      active: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
      onlyDisabledConsequence: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!ListAutomationsRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new ListAutomationsRequest;
    return ListAutomationsRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!ListAutomationsRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!ListAutomationsRequest}
   */
  static deserializeBinaryFromReader(msg: ListAutomationsRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {!TriggerTypeKind} */ (reader.readEnum());
        msg.setTriggerType(value1);
        break;
      case 2:
        var value2 = /** @type {boolean} */ (reader.readBool());
        msg.setActive(value2);
        break;
      case 3:
        var value3 = /** @type {boolean} */ (reader.readBool());
        msg.setOnlyDisabledConsequence(value3);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!ListAutomationsRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: ListAutomationsRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getTriggerType();
    if (f !== 0.0) {
      writer.writeEnum(
        1,
        f
      );
    }
    f = message.getActive();
    if (f) {
      writer.writeBool(
        2,
        f
      );
    }
    f = message.getOnlyDisabledConsequence();
    if (f) {
      writer.writeBool(
        3,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    ListAutomationsRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional TriggerTypeKind trigger_type = 1;
   * @return {!TriggerTypeKind}
   */
  getTriggerType(): TriggerTypeKind {
    return /** @type {!TriggerTypeKind} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
  };


  /** @param {!TriggerTypeKind} value */
  setTriggerType(value: TriggerTypeKind) {
    jspb.Message.setProto3EnumField(this, 1, value);
  }


  /**
   * optional bool active = 2;
   * @return {boolean}
   */
  getActive(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
  };


  /** @param {boolean} value */
  setActive(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 2, value);
  }


  /**
   * optional bool only_disabled_consequence = 3;
   * @return {boolean}
   */
  getOnlyDisabledConsequence(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
  };


  /** @param {boolean} value */
  setOnlyDisabledConsequence(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 3, value);
  }


}
export namespace ListAutomationsResponse {
  export interface AsObject {
    automationsList: Automation.AsObject[];
  }
}
export class ListAutomationsResponse extends jspb.Message {
  static readonly displayName = "ListAutomationsResponse";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [1];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, ListAutomationsResponse.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): ListAutomationsResponse.AsObject {
    return ListAutomationsResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!ListAutomationsResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: ListAutomationsResponse) {
    var f: any;
    var obj: any = {
      automationsList: jspb.Message.toObjectList(msg.getAutomationsList(),
      Automation.toObject, includeInstance)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!ListAutomationsResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new ListAutomationsResponse;
    return ListAutomationsResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!ListAutomationsResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!ListAutomationsResponse}
   */
  static deserializeBinaryFromReader(msg: ListAutomationsResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new Automation;
        reader.readMessage(value1,Automation.deserializeBinaryFromReader);
        msg.addAutomations(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!ListAutomationsResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: ListAutomationsResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getAutomationsList();
    if (f.length > 0) {
      writer.writeRepeatedMessage(
        1,
        f,
        Automation.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    ListAutomationsResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * repeated Automation automations = 1;
   * @return {!Array<!Automation>}
   */
  getAutomationsList(): Automation[] {
    return /** @type{!Array<!Automation>} */ (
      jspb.Message.getRepeatedWrapperField(this, Automation, 1));
  }


  /** @param {!Array<!Automation>} value */
  setAutomationsList(value?: Automation[]) {
    jspb.Message.setRepeatedWrapperField(this, 1, value);
  }


  /**
   * @param {!Automation=} opt_value
   * @param {number=} opt_index
   * @return {!Automation}
   */
  addAutomations(opt_value?: Automation, opt_index?: number): Automation {
    return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, Automation, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearAutomationsList() {
    this.setAutomationsList([]);
  }


}
export namespace GetAutomationRequest {
  export interface AsObject {
    id: number;
  }
}
export class GetAutomationRequest extends jspb.Message {
  static readonly displayName = "GetAutomationRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): GetAutomationRequest.AsObject {
    return GetAutomationRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!GetAutomationRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: GetAutomationRequest) {
    var f: any;
    var obj: any = {
      id: jspb.Message.getFieldWithDefault(msg, 1, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!GetAutomationRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new GetAutomationRequest;
    return GetAutomationRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!GetAutomationRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!GetAutomationRequest}
   */
  static deserializeBinaryFromReader(msg: GetAutomationRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {number} */ (reader.readInt32());
        msg.setId(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!GetAutomationRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: GetAutomationRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getId();
    if (f !== 0) {
      writer.writeInt32(
        1,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    GetAutomationRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional int32 id = 1;
   * @return {number}
   */
  getId(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
  };


  /** @param {number} value */
  setId(value: number) {
    jspb.Message.setProto3IntField(this, 1, value);
  }


}
export namespace CreateAutomationRequest {
  export interface AsObject {
    automation?: Automation.AsObject;
  }
}
export class CreateAutomationRequest extends jspb.Message {
  static readonly displayName = "CreateAutomationRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): CreateAutomationRequest.AsObject {
    return CreateAutomationRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!CreateAutomationRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: CreateAutomationRequest) {
    var f: any;
    var obj: any = {
      automation: (f = msg.getAutomation()) && Automation.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!CreateAutomationRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new CreateAutomationRequest;
    return CreateAutomationRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!CreateAutomationRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!CreateAutomationRequest}
   */
  static deserializeBinaryFromReader(msg: CreateAutomationRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new Automation;
        reader.readMessage(value1,Automation.deserializeBinaryFromReader);
        msg.setAutomation(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!CreateAutomationRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: CreateAutomationRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getAutomation();
    if (f != null) {
      writer.writeMessage(
        1,
        f,
        Automation.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    CreateAutomationRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional Automation automation = 1;
   * @return {?Automation}
   */
  getAutomation(): Automation {
    return /** @type{?Automation} */ (
      jspb.Message.getWrapperField(this, Automation, 1));
  }


  /** @param {?Automation|undefined} value */
  setAutomation(value?: Automation) {
    jspb.Message.setWrapperField(this, 1, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearAutomation() {
    this.setAutomation(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasAutomation(): boolean {
    return jspb.Message.getField(this, 1) != null;
  }


}
export namespace UpdateAutomationRequest {
  export interface AsObject {
    automation?: Automation.AsObject;
  }
}
export class UpdateAutomationRequest extends jspb.Message {
  static readonly displayName = "UpdateAutomationRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): UpdateAutomationRequest.AsObject {
    return UpdateAutomationRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!UpdateAutomationRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: UpdateAutomationRequest) {
    var f: any;
    var obj: any = {
      automation: (f = msg.getAutomation()) && Automation.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!UpdateAutomationRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new UpdateAutomationRequest;
    return UpdateAutomationRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!UpdateAutomationRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!UpdateAutomationRequest}
   */
  static deserializeBinaryFromReader(msg: UpdateAutomationRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new Automation;
        reader.readMessage(value1,Automation.deserializeBinaryFromReader);
        msg.setAutomation(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!UpdateAutomationRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: UpdateAutomationRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getAutomation();
    if (f != null) {
      writer.writeMessage(
        1,
        f,
        Automation.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    UpdateAutomationRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional Automation automation = 1;
   * @return {?Automation}
   */
  getAutomation(): Automation {
    return /** @type{?Automation} */ (
      jspb.Message.getWrapperField(this, Automation, 1));
  }


  /** @param {?Automation|undefined} value */
  setAutomation(value?: Automation) {
    jspb.Message.setWrapperField(this, 1, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearAutomation() {
    this.setAutomation(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasAutomation(): boolean {
    return jspb.Message.getField(this, 1) != null;
  }


}
export namespace DeleteAutomationRequest {
  export interface AsObject {
    id: number;
  }
}
export class DeleteAutomationRequest extends jspb.Message {
  static readonly displayName = "DeleteAutomationRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): DeleteAutomationRequest.AsObject {
    return DeleteAutomationRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!DeleteAutomationRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: DeleteAutomationRequest) {
    var f: any;
    var obj: any = {
      id: jspb.Message.getFieldWithDefault(msg, 1, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!DeleteAutomationRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new DeleteAutomationRequest;
    return DeleteAutomationRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!DeleteAutomationRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!DeleteAutomationRequest}
   */
  static deserializeBinaryFromReader(msg: DeleteAutomationRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {number} */ (reader.readInt32());
        msg.setId(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!DeleteAutomationRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: DeleteAutomationRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getId();
    if (f !== 0) {
      writer.writeInt32(
        1,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    DeleteAutomationRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional int32 id = 1;
   * @return {number}
   */
  getId(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
  };


  /** @param {number} value */
  setId(value: number) {
    jspb.Message.setProto3IntField(this, 1, value);
  }


}
export namespace DeleteAutomationResponse {
  export interface AsObject {
  }
}
export class DeleteAutomationResponse extends jspb.Message {
  static readonly displayName = "DeleteAutomationResponse";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): DeleteAutomationResponse.AsObject {
    return DeleteAutomationResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!DeleteAutomationResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: DeleteAutomationResponse) {
    var f: any;
    var obj: any = {

    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!DeleteAutomationResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new DeleteAutomationResponse;
    return DeleteAutomationResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!DeleteAutomationResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!DeleteAutomationResponse}
   */
  static deserializeBinaryFromReader(msg: DeleteAutomationResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!DeleteAutomationResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: DeleteAutomationResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    DeleteAutomationResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
}
export namespace ResetAutomationCountRequest {
  export interface AsObject {
    id: number;
    hashesList: string[];
  }
}
export class ResetAutomationCountRequest extends jspb.Message {
  static readonly displayName = "ResetAutomationCountRequest";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [2];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, ResetAutomationCountRequest.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): ResetAutomationCountRequest.AsObject {
    return ResetAutomationCountRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!ResetAutomationCountRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: ResetAutomationCountRequest) {
    var f: any;
    var obj: any = {
      id: jspb.Message.getFieldWithDefault(msg, 1, 0),
      hashesList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!ResetAutomationCountRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new ResetAutomationCountRequest;
    return ResetAutomationCountRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!ResetAutomationCountRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!ResetAutomationCountRequest}
   */
  static deserializeBinaryFromReader(msg: ResetAutomationCountRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {number} */ (reader.readInt32());
        msg.setId(value1);
        break;
      case 2:
        var value2 = /** @type {string} */ (reader.readString());
        msg.addHashes(value2);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!ResetAutomationCountRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: ResetAutomationCountRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getId();
    if (f !== 0) {
      writer.writeInt32(
        1,
        f
      );
    }
    f = message.getHashesList();
    if (f.length > 0) {
      writer.writeRepeatedString(
        2,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    ResetAutomationCountRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional int32 id = 1;
   * @return {number}
   */
  getId(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
  };


  /** @param {number} value */
  setId(value: number) {
    jspb.Message.setProto3IntField(this, 1, value);
  }


  /**
   * repeated string hashes = 2;
   * @return {!Array<string>}
   */
  getHashesList(): string[] {
    return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
  };


  /** @param {!Array<string>} value */
  setHashesList(value: string[]) {
    jspb.Message.setField(this, 2, value || []);
  }


  /**
   * @param {string} value
   * @param {number=} opt_index
   */
  addHashes(value: string, opt_index?: number): void {
    jspb.Message.addToRepeatedField(this, 2, value, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearHashesList() {
    this.setHashesList([]);
  }


}
export namespace ResetAutomationCountResponse {
  export interface AsObject {
  }
}
export class ResetAutomationCountResponse extends jspb.Message {
  static readonly displayName = "ResetAutomationCountResponse";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): ResetAutomationCountResponse.AsObject {
    return ResetAutomationCountResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!ResetAutomationCountResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: ResetAutomationCountResponse) {
    var f: any;
    var obj: any = {

    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!ResetAutomationCountResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new ResetAutomationCountResponse;
    return ResetAutomationCountResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!ResetAutomationCountResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!ResetAutomationCountResponse}
   */
  static deserializeBinaryFromReader(msg: ResetAutomationCountResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!ResetAutomationCountResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: ResetAutomationCountResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    ResetAutomationCountResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
}
export namespace AllAutomationsResetRequest {
  export interface AsObject {
    automationIdsList: number[];
    personHashesList: string[];
    counterValue: number;
  }
}
export class AllAutomationsResetRequest extends jspb.Message {
  static readonly displayName = "AllAutomationsResetRequest";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [1,2];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, AllAutomationsResetRequest.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): AllAutomationsResetRequest.AsObject {
    return AllAutomationsResetRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!AllAutomationsResetRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: AllAutomationsResetRequest) {
    var f: any;
    var obj: any = {
      automationIdsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
      personHashesList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
      counterValue: jspb.Message.getFieldWithDefault(msg, 3, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!AllAutomationsResetRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new AllAutomationsResetRequest;
    return AllAutomationsResetRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!AllAutomationsResetRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!AllAutomationsResetRequest}
   */
  static deserializeBinaryFromReader(msg: AllAutomationsResetRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {!Array<number>} */ (reader.readPackedInt32());
        msg.setAutomationIdsList(value1);
        break;
      case 2:
        var value2 = /** @type {string} */ (reader.readString());
        msg.addPersonHashes(value2);
        break;
      case 3:
        var value3 = /** @type {number} */ (reader.readInt32());
        msg.setCounterValue(value3);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!AllAutomationsResetRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: AllAutomationsResetRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getAutomationIdsList();
    if (f.length > 0) {
      writer.writePackedInt32(
        1,
        f
      );
    }
    f = message.getPersonHashesList();
    if (f.length > 0) {
      writer.writeRepeatedString(
        2,
        f
      );
    }
    f = message.getCounterValue();
    if (f !== 0) {
      writer.writeInt32(
        3,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    AllAutomationsResetRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * repeated int32 automation_ids = 1;
   * @return {!Array<number>}
   */
  getAutomationIdsList(): number[] {
    return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 1));
  };


  /** @param {!Array<number>} value */
  setAutomationIdsList(value: number[]) {
    jspb.Message.setField(this, 1, value || []);
  }


  /**
   * @param {number} value
   * @param {number=} opt_index
   */
  addAutomationIds(value: number, opt_index?: number): void {
    jspb.Message.addToRepeatedField(this, 1, value, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearAutomationIdsList() {
    this.setAutomationIdsList([]);
  }


  /**
   * repeated string person_hashes = 2;
   * @return {!Array<string>}
   */
  getPersonHashesList(): string[] {
    return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
  };


  /** @param {!Array<string>} value */
  setPersonHashesList(value: string[]) {
    jspb.Message.setField(this, 2, value || []);
  }


  /**
   * @param {string} value
   * @param {number=} opt_index
   */
  addPersonHashes(value: string, opt_index?: number): void {
    jspb.Message.addToRepeatedField(this, 2, value, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearPersonHashesList() {
    this.setPersonHashesList([]);
  }


  /**
   * optional int32 counter_value = 3;
   * @return {number}
   */
  getCounterValue(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
  };


  /** @param {number} value */
  setCounterValue(value: number) {
    jspb.Message.setProto3IntField(this, 3, value);
  }


}
export namespace AllAutomationsResetResponse {
  export interface AsObject {
    totalUpdated: number;
  }
}
export class AllAutomationsResetResponse extends jspb.Message {
  static readonly displayName = "AllAutomationsResetResponse";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): AllAutomationsResetResponse.AsObject {
    return AllAutomationsResetResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!AllAutomationsResetResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: AllAutomationsResetResponse) {
    var f: any;
    var obj: any = {
      totalUpdated: jspb.Message.getFieldWithDefault(msg, 1, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!AllAutomationsResetResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new AllAutomationsResetResponse;
    return AllAutomationsResetResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!AllAutomationsResetResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!AllAutomationsResetResponse}
   */
  static deserializeBinaryFromReader(msg: AllAutomationsResetResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {number} */ (reader.readInt32());
        msg.setTotalUpdated(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!AllAutomationsResetResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: AllAutomationsResetResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getTotalUpdated();
    if (f !== 0) {
      writer.writeInt32(
        1,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    AllAutomationsResetResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional int32 total_updated = 1;
   * @return {number}
   */
  getTotalUpdated(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
  };


  /** @param {number} value */
  setTotalUpdated(value: number) {
    jspb.Message.setProto3IntField(this, 1, value);
  }


}
export enum TriggerTypeKind {
  HALLPASS = 0,
  PBIS = 1,
  CONSEQUENCE = 2,
}
