import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';

import { UserListMinimal } from 'libs/domain';
import { BehaviorSubject, Observable } from 'rxjs';

import { BottomSheetEventType, BottomSheetService } from '../bottom-sheet';
import { FormGroupedSelectComponent } from '../form/components/form-grouped-select/form-grouped-select.component';
import {
  CategoryItem,
  OptionItem,
  SelectGroupCategory,
} from '../form/components/form-grouped-select/form-grouped-select.types';
import { SystemAlertSnackBarService } from '../system-alert-snackbar';
import { UserListFilterMobileComponent } from './components/user-list-filter-mobile/user-list-filter-mobile.component';
import { UserListFilterService } from './services/user-list-filter.service';
import { UserListFilterMessages } from './user-list.constants';
import {
  InitialValue,
  UserListBottomSheetData,
  UserListBottomSheetResponse,
  UserListCategory,
} from './user-list.types';

@Component({
  selector: 'mg-user-list-filter',
  templateUrl: './user-list-filter.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./user-list-filter.component.scss'],
})
export class UserListFilterComponent implements OnInit {
  // Children
  @ViewChild('formSelectComponent')
  formSelectComponent: FormGroupedSelectComponent;

  /**
   * By default we lazy load all the lists but there's times we want to prefetch
   * especially in forms when we need to set default values
   */
  @Input() prefetchData: UserListCategory[] = [];
  @Input() id: string;
  @Input() set value(value: InitialValue) {
    this._setInitialLists(value);
  }
  @Input() disabled: boolean;
  @Input() placeholder: string;
  @Input() multiple = true;
  /**
   * desktop = standard dropdown
   * mobile = opens input inside a bottom sheet
   * in-bottom-sheet = used when the component is already inside a bottom sheet (filters)
   */
  @Input() layout: 'desktop' | 'mobile' | 'in-bottom-sheet' = 'desktop';
  @Input() commitChanges$: Observable<any>;
  @Input() returnMode: 'value' | 'full' = 'value';
  @Input() clearSelection: Observable<void>;
  @Output() selectChanged: EventEmitter<
    | number
    | number[]
    | OptionItem<number, UserListMinimal>
    | OptionItem<number, UserListMinimal>[]
    | null
  > = new EventEmitter();
  /**
   * Used with mobile bottom sheet experience
   */
  @Output() commitChanges: EventEmitter<
    | number
    | number[]
    | OptionItem<number, UserListMinimal>
    | OptionItem<number, UserListMinimal>[]
    | null
  > = new EventEmitter();

  @Input() appendTo: string;
  /**
   * If enabled this will only show 1 row of pills with show more functionality instead of wrapping onto multiple lines
   */
  @Input() disableWrapping: boolean;

  private _dataLoadedSubj = new BehaviorSubject<boolean>(false);
  public dataLoaded$ = this._dataLoadedSubj.asObservable();

  private _lastSelectedCategory: CategoryItem;

  private _initialOptionsSubject = new BehaviorSubject<
    OptionItem<number, UserListMinimal>[] | OptionItem<number, UserListMinimal>
  >(null);
  public initialOptions$ = this._initialOptionsSubject.asObservable();

  @Input() options: SelectGroupCategory<string, number, UserListMinimal>[] = [
    {
      value: UserListCategory.MY_LISTS_CURRENT_TERM,
      label: UserListFilterMessages.MY_LISTS_CURRENT_TERM,
      items: [],
      fetch: () => this._userListFilterService.getMyListsCurrentTerm(),
      lazyLoad: true,
      openByDefault: true,
    },
    {
      value: UserListCategory.MY_LISTS,
      label: UserListFilterMessages.MY_LISTS,
      items: [],
      fetch: () => this._userListFilterService.getMyLists(),
      lazyLoad: true,
    },
    {
      value: UserListCategory.ALL_CURRENT_TERM,
      label: UserListFilterMessages.ALL_LISTS_CURRENT_TERM,
      items: [],
      fetch: () => this._userListFilterService.getAllListsCurrentTerm(),
      lazyLoad: true,
    },
    {
      value: UserListCategory.ALL,
      label: UserListFilterMessages.ALL_LISTS,
      items: [],
      fetch: () => this._userListFilterService.getAllLists(),
      usedForUserSearch: true,
      lazyLoad: true,
    },
  ];

  constructor(
    private _userListFilterService: UserListFilterService,
    private _snackBar: SystemAlertSnackBarService,
    private _bottomSheet: BottomSheetService<
      UserListBottomSheetData,
      UserListBottomSheetResponse
    >,
  ) {}

  ngOnInit(): void {
    this._prefetchData();
  }

  public selectChangeHandler(
    value?:
      | OptionItem<number, UserListMinimal>
      | OptionItem<number, UserListMinimal>[],
  ): void {
    if (!value) {
      this.selectChanged.emit(null);
      return;
    }

    const getReturnValue = list =>
      this.returnMode === 'value' ? list.data?.id : list;

    const data = Array.isArray(value)
      ? value.map(getReturnValue)
      : getReturnValue(value);

    this.selectChanged.emit(data);
  }

  public openBottomSheet(): void {
    const options = this._lastSelectedCategory
      ? this.options.map(cat => {
          return {
            ...cat,
            openByDefault: cat.value === this._lastSelectedCategory.value,
          };
        })
      : this.options;

    const ref = this._bottomSheet.open(UserListFilterMobileComponent, {
      data: {
        id: this.id,
        initialOptions: this._initialOptionsSubject.value,
        disabled: this.disabled,
        options,
        multiple: this.multiple,
      },
    });

    ref.afterDismissed().subscribe(response => {
      if (response.type === BottomSheetEventType.SUBMIT) {
        const lists = response.data?.selectedLists;
        this.selectChangeHandler(lists);
        this._lastSelectedCategory = response.data?.lastSelectedCategory;
      }
    });
  }

  public focus(): void {
    if (this.layout === 'desktop') {
      this.formSelectComponent.focus();
    }
  }

  public clear(): void {
    this.selectChanged.emit(null);
  }

  public onCommitChanges(lists) {
    this.commitChanges.emit(lists);
  }

  private async _prefetchData(): Promise<void> {
    if (!this.prefetchData) {
      this._dataLoadedSubj.next(true);
      return;
    }

    try {
      const fetchers = this.options
        .filter(cat => this.prefetchData.includes(cat.value as any))
        .map(async cat => {
          const options = await cat.fetch();
          cat.items = options;
        });

      await Promise.all(fetchers);
    } catch (error) {
      console.error('Error preloading data', error);
      this._snackBar.error('There was an issue loading user lists');
    } finally {
      this._dataLoadedSubj.next(true);
    }
  }

  private async _setInitialLists(initialLists: InitialValue) {
    if (
      !initialLists ||
      (Array.isArray(initialLists) && !initialLists.length)
    ) {
      this._initialOptionsSubject.next([]);
      return;
    }

    const listArray = Array.isArray(initialLists)
      ? initialLists
      : [initialLists];

    const implicit = listArray.filter(l => typeof l === 'number') as number[];
    const implicitList = await this._getImplicitLists(implicit);
    const explicitList = listArray.filter(
      l => typeof l !== 'number',
    ) as OptionItem[];

    try {
      const selectedLists = [];

      if (implicitList.length) {
        selectedLists.push(...implicitList);
      }
      if (explicitList.length) {
        selectedLists.push(...explicitList);
      }

      if (selectedLists.length) {
        this._initialOptionsSubject.next(
          this.multiple ? selectedLists : selectedLists[0],
        );
      }
    } catch (error) {
      this._snackBar.error('There was an issue loading user lists');
    }
  }

  /**
   * When initial values are numbers we know it's refering to ids of user lists
   */
  private async _getImplicitLists(
    listIds: number[],
  ): Promise<OptionItem<number, UserListMinimal>[]> {
    if (!listIds?.length) return [];

    const lists = await this._userListFilterService.getAllLists();

    const selectedLists = [];

    for (const id of listIds) {
      const list = lists.find(l => l.data.id === id);
      if (list) {
        selectedLists.push({
          ...list,
          category: {
            value: UserListCategory.ALL,
            label: UserListFilterMessages.ALL_LISTS,
          },
        });
      }
    }

    return selectedLists;
  }
}
