import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';

import { MgButtonModule } from '@app/src/app/button';
import { MgEmptyStateModule } from '@app/src/app/components/EmptyState';
import { MgIconModule } from '@app/src/app/icon';
import { MgSpinnerModule } from '@app/src/app/spinner';

import { GenericButtonModule } from '@shared/components/button';
import { FormModule } from '@shared/components/form';
import { GenericModule } from '@shared/components/generic';
import { PaginatorModule } from '@shared/components/paginator';
import { TextModule } from '@shared/components/text';

import { SortHeaderModule } from '../../directives/sort-header/sort-header.module';
import { OverlayModule } from '../modal-overlay';
import { TooltipModule } from '../tooltip';
import { ReportTableActionTemplateComponent } from './components/columns/rt-action-column.component';
import { ReportTableArchiveTemplateComponent } from './components/columns/rt-archive-column.component';
import { ReportTableColumnTemplateComponent } from './components/columns/rt-column-template.component';
import { ReportTableCompleteTemplateComponent } from './components/columns/rt-complete-column.component';
import { ReportTableEditTemplateComponent } from './components/columns/rt-edit-column.component';
import { ReportTableHistoryTemplateComponent } from './components/columns/rt-history-column.component';
import { ReportTableModalTableTemplateComponent } from './components/columns/rt-modal-table-column.component';
import { ReportTableRoleTemplateComponent } from './components/columns/rt-role-column.component';
import { ReportTableSummaryTemplateComponent } from './components/columns/rt-summary-column.component';
import { ReportTableModalTableShellComponent } from './components/modal-shell-table/rt-modal-table-shell.component';
import { ReportTableCellClassesPipe } from './pipes/ReportTableCellClasses.pipe';
import { ReportTableCellContentPipe } from './pipes/ReportTableCellContent.pipe';
import { ReportTableCellStylesPipe } from './pipes/ReportTableCellStyles.pipe';
import { ReportTableHeaderCellClassesPipe } from './pipes/ReportTableHeaderCellClasses.pipe';
import { StatusTagColorPipe } from './pipes/StatusTagColor.pipe';
import { ReportTableComponent } from './report-table.component';

@NgModule({
  imports: [
    // Minga dependencies
    MgButtonModule,
    MgSpinnerModule,
    MgIconModule,
    MgEmptyStateModule,
    GenericButtonModule,
    TextModule,
    PaginatorModule,
    GenericModule,
    FormModule,
    TooltipModule,
    SortHeaderModule,
    OverlayModule,

    // External dependencies
    CommonModule,
    FlexLayoutModule,
    MatTableModule,
    MatTooltipModule,
    MatSortModule,
    MatMenuModule,
    MatCheckboxModule,
    MatIconModule,
  ],
  declarations: [
    // Main component
    ReportTableComponent,

    // Pipes
    ReportTableCellContentPipe,
    ReportTableHeaderCellClassesPipe,
    ReportTableCellClassesPipe,
    ReportTableCellStylesPipe,
    StatusTagColorPipe,

    // Column template components
    ReportTableColumnTemplateComponent,
    ReportTableCompleteTemplateComponent,
    ReportTableSummaryTemplateComponent,
    ReportTableRoleTemplateComponent,
    ReportTableHistoryTemplateComponent,
    ReportTableArchiveTemplateComponent,
    ReportTableEditTemplateComponent,
    ReportTableActionTemplateComponent,
    ReportTableModalTableTemplateComponent,
    ReportTableModalTableShellComponent,
  ],
  exports: [
    ReportTableComponent,
    ReportTableColumnTemplateComponent,
    ReportTableCompleteTemplateComponent,
    ReportTableSummaryTemplateComponent,
    ReportTableRoleTemplateComponent,
    ReportTableHistoryTemplateComponent,
    ReportTableArchiveTemplateComponent,
    ReportTableEditTemplateComponent,
    ReportTableActionTemplateComponent,
    ReportTableModalTableTemplateComponent,
    StatusTagColorPipe,
  ],
})
export class ReportTableModule {}
