// source: minga_stats_internal/minga_stats_internal.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

import * as jspb from 'google-protobuf';
import * as common_date_pb from '../common/date_pb';
export namespace InternalMingaStats {
  export interface AsObject {
    mingaId: number;
    dailyActiveCount: number;
    weeklyActiveCount: number;
    monthlyActiveCount: number;
    hallPassCount: number;
    postCount: number;
    behaviorCount: number;
  }
}
export class InternalMingaStats extends jspb.Message {
  static readonly displayName = "InternalMingaStats";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): InternalMingaStats.AsObject {
    return InternalMingaStats.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!InternalMingaStats} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: InternalMingaStats) {
    var f: any;
    var obj: any = {
      mingaId: jspb.Message.getFieldWithDefault(msg, 1, 0),
      dailyActiveCount: jspb.Message.getFieldWithDefault(msg, 2, 0),
      weeklyActiveCount: jspb.Message.getFieldWithDefault(msg, 3, 0),
      monthlyActiveCount: jspb.Message.getFieldWithDefault(msg, 4, 0),
      hallPassCount: jspb.Message.getFieldWithDefault(msg, 5, 0),
      postCount: jspb.Message.getFieldWithDefault(msg, 6, 0),
      behaviorCount: jspb.Message.getFieldWithDefault(msg, 7, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!InternalMingaStats}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new InternalMingaStats;
    return InternalMingaStats.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!InternalMingaStats} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!InternalMingaStats}
   */
  static deserializeBinaryFromReader(msg: InternalMingaStats, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {number} */ (reader.readInt32());
        msg.setMingaId(value1);
        break;
      case 2:
        var value2 = /** @type {number} */ (reader.readInt32());
        msg.setDailyActiveCount(value2);
        break;
      case 3:
        var value3 = /** @type {number} */ (reader.readInt32());
        msg.setWeeklyActiveCount(value3);
        break;
      case 4:
        var value4 = /** @type {number} */ (reader.readInt32());
        msg.setMonthlyActiveCount(value4);
        break;
      case 5:
        var value5 = /** @type {number} */ (reader.readInt32());
        msg.setHallPassCount(value5);
        break;
      case 6:
        var value6 = /** @type {number} */ (reader.readInt32());
        msg.setPostCount(value6);
        break;
      case 7:
        var value7 = /** @type {number} */ (reader.readInt32());
        msg.setBehaviorCount(value7);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!InternalMingaStats} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: InternalMingaStats, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getMingaId();
    if (f !== 0) {
      writer.writeInt32(
        1,
        f
      );
    }
    f = message.getDailyActiveCount();
    if (f !== 0) {
      writer.writeInt32(
        2,
        f
      );
    }
    f = message.getWeeklyActiveCount();
    if (f !== 0) {
      writer.writeInt32(
        3,
        f
      );
    }
    f = message.getMonthlyActiveCount();
    if (f !== 0) {
      writer.writeInt32(
        4,
        f
      );
    }
    f = message.getHallPassCount();
    if (f !== 0) {
      writer.writeInt32(
        5,
        f
      );
    }
    f = message.getPostCount();
    if (f !== 0) {
      writer.writeInt32(
        6,
        f
      );
    }
    f = message.getBehaviorCount();
    if (f !== 0) {
      writer.writeInt32(
        7,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    InternalMingaStats.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional int32 minga_id = 1;
   * @return {number}
   */
  getMingaId(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
  };


  /** @param {number} value */
  setMingaId(value: number) {
    jspb.Message.setProto3IntField(this, 1, value);
  }


  /**
   * optional int32 daily_active_count = 2;
   * @return {number}
   */
  getDailyActiveCount(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
  };


  /** @param {number} value */
  setDailyActiveCount(value: number) {
    jspb.Message.setProto3IntField(this, 2, value);
  }


  /**
   * optional int32 weekly_active_count = 3;
   * @return {number}
   */
  getWeeklyActiveCount(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
  };


  /** @param {number} value */
  setWeeklyActiveCount(value: number) {
    jspb.Message.setProto3IntField(this, 3, value);
  }


  /**
   * optional int32 monthly_active_count = 4;
   * @return {number}
   */
  getMonthlyActiveCount(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
  };


  /** @param {number} value */
  setMonthlyActiveCount(value: number) {
    jspb.Message.setProto3IntField(this, 4, value);
  }


  /**
   * optional int32 hall_pass_count = 5;
   * @return {number}
   */
  getHallPassCount(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
  };


  /** @param {number} value */
  setHallPassCount(value: number) {
    jspb.Message.setProto3IntField(this, 5, value);
  }


  /**
   * optional int32 post_count = 6;
   * @return {number}
   */
  getPostCount(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
  };


  /** @param {number} value */
  setPostCount(value: number) {
    jspb.Message.setProto3IntField(this, 6, value);
  }


  /**
   * optional int32 behavior_count = 7;
   * @return {number}
   */
  getBehaviorCount(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
  };


  /** @param {number} value */
  setBehaviorCount(value: number) {
    jspb.Message.setProto3IntField(this, 7, value);
  }


}
export namespace InternalGetMingaStatsRequest {
  export interface AsObject {
    mingaIdsList: number[];
  }
}
export class InternalGetMingaStatsRequest extends jspb.Message {
  static readonly displayName = "InternalGetMingaStatsRequest";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [1];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, InternalGetMingaStatsRequest.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): InternalGetMingaStatsRequest.AsObject {
    return InternalGetMingaStatsRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!InternalGetMingaStatsRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: InternalGetMingaStatsRequest) {
    var f: any;
    var obj: any = {
      mingaIdsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!InternalGetMingaStatsRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new InternalGetMingaStatsRequest;
    return InternalGetMingaStatsRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!InternalGetMingaStatsRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!InternalGetMingaStatsRequest}
   */
  static deserializeBinaryFromReader(msg: InternalGetMingaStatsRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {!Array<number>} */ (reader.readPackedInt32());
        msg.setMingaIdsList(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!InternalGetMingaStatsRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: InternalGetMingaStatsRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getMingaIdsList();
    if (f.length > 0) {
      writer.writePackedInt32(
        1,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    InternalGetMingaStatsRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * repeated int32 minga_ids = 1;
   * @return {!Array<number>}
   */
  getMingaIdsList(): number[] {
    return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 1));
  };


  /** @param {!Array<number>} value */
  setMingaIdsList(value: number[]) {
    jspb.Message.setField(this, 1, value || []);
  }


  /**
   * @param {number} value
   * @param {number=} opt_index
   */
  addMingaIds(value: number, opt_index?: number): void {
    jspb.Message.addToRepeatedField(this, 1, value, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearMingaIdsList() {
    this.setMingaIdsList([]);
  }


}
export namespace InternalGetMingaStatsResponse {
  export interface AsObject {
    mingaStatsList: InternalMingaStats.AsObject[];
  }
}
export class InternalGetMingaStatsResponse extends jspb.Message {
  static readonly displayName = "InternalGetMingaStatsResponse";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [1];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, InternalGetMingaStatsResponse.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): InternalGetMingaStatsResponse.AsObject {
    return InternalGetMingaStatsResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!InternalGetMingaStatsResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: InternalGetMingaStatsResponse) {
    var f: any;
    var obj: any = {
      mingaStatsList: jspb.Message.toObjectList(msg.getMingaStatsList(),
      InternalMingaStats.toObject, includeInstance)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!InternalGetMingaStatsResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new InternalGetMingaStatsResponse;
    return InternalGetMingaStatsResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!InternalGetMingaStatsResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!InternalGetMingaStatsResponse}
   */
  static deserializeBinaryFromReader(msg: InternalGetMingaStatsResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new InternalMingaStats;
        reader.readMessage(value1,InternalMingaStats.deserializeBinaryFromReader);
        msg.addMingaStats(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!InternalGetMingaStatsResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: InternalGetMingaStatsResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getMingaStatsList();
    if (f.length > 0) {
      writer.writeRepeatedMessage(
        1,
        f,
        InternalMingaStats.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    InternalGetMingaStatsResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * repeated InternalMingaStats minga_stats = 1;
   * @return {!Array<!InternalMingaStats>}
   */
  getMingaStatsList(): InternalMingaStats[] {
    return /** @type{!Array<!InternalMingaStats>} */ (
      jspb.Message.getRepeatedWrapperField(this, InternalMingaStats, 1));
  }


  /** @param {!Array<!InternalMingaStats>} value */
  setMingaStatsList(value?: InternalMingaStats[]) {
    jspb.Message.setRepeatedWrapperField(this, 1, value);
  }


  /**
   * @param {!InternalMingaStats=} opt_value
   * @param {number=} opt_index
   * @return {!InternalMingaStats}
   */
  addMingaStats(opt_value?: InternalMingaStats, opt_index?: number): InternalMingaStats {
    return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, InternalMingaStats, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearMingaStatsList() {
    this.setMingaStatsList([]);
  }


}
export namespace InternalGetMingaStatsAggregateRequest {
  export interface AsObject {
    mingaIdsList: number[];
    startDate?: common_date_pb.Date.AsObject;
    endDate?: common_date_pb.Date.AsObject;
    groupBy?: InternalGetMingaStatsAggregateRequest.GroupBy;
    type?: InternalGetMingaStatsAggregateRequest.Type;
  }
}
export class InternalGetMingaStatsAggregateRequest extends jspb.Message {
  static readonly displayName = "InternalGetMingaStatsAggregateRequest";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [1];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, InternalGetMingaStatsAggregateRequest.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): InternalGetMingaStatsAggregateRequest.AsObject {
    return InternalGetMingaStatsAggregateRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!InternalGetMingaStatsAggregateRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: InternalGetMingaStatsAggregateRequest) {
    var f: any;
    var obj: any = {
      mingaIdsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
      startDate: (f = msg.getStartDate()) && common_date_pb.Date.toObject(includeInstance, f),
      endDate: (f = msg.getEndDate()) && common_date_pb.Date.toObject(includeInstance, f),
      groupBy: jspb.Message.getFieldWithDefault(msg, 4, 0),
      type: jspb.Message.getFieldWithDefault(msg, 5, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!InternalGetMingaStatsAggregateRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new InternalGetMingaStatsAggregateRequest;
    return InternalGetMingaStatsAggregateRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!InternalGetMingaStatsAggregateRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!InternalGetMingaStatsAggregateRequest}
   */
  static deserializeBinaryFromReader(msg: InternalGetMingaStatsAggregateRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {!Array<number>} */ (reader.readPackedInt32());
        msg.setMingaIdsList(value1);
        break;
      case 2:
        var value2 = new common_date_pb.Date;
        reader.readMessage(value2,common_date_pb.Date.deserializeBinaryFromReader);
        msg.setStartDate(value2);
        break;
      case 3:
        var value3 = new common_date_pb.Date;
        reader.readMessage(value3,common_date_pb.Date.deserializeBinaryFromReader);
        msg.setEndDate(value3);
        break;
      case 4:
        var value4 = /** @type {!InternalGetMingaStatsAggregateRequest.GroupBy} */ (reader.readEnum());
        msg.setGroupBy(value4);
        break;
      case 5:
        var value5 = /** @type {!InternalGetMingaStatsAggregateRequest.Type} */ (reader.readEnum());
        msg.setType(value5);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!InternalGetMingaStatsAggregateRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: InternalGetMingaStatsAggregateRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getMingaIdsList();
    if (f.length > 0) {
      writer.writePackedInt32(
        1,
        f
      );
    }
    f = message.getStartDate();
    if (f != null) {
      writer.writeMessage(
        2,
        f,
        common_date_pb.Date.serializeBinaryToWriter
      );
    }
    f = message.getEndDate();
    if (f != null) {
      writer.writeMessage(
        3,
        f,
        common_date_pb.Date.serializeBinaryToWriter
      );
    }
    f = message.getGroupBy();
    if (f !== 0.0) {
      writer.writeEnum(
        4,
        f
      );
    }
    f = message.getType();
    if (f !== 0.0) {
      writer.writeEnum(
        5,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    InternalGetMingaStatsAggregateRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * repeated int32 minga_ids = 1;
   * @return {!Array<number>}
   */
  getMingaIdsList(): number[] {
    return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 1));
  };


  /** @param {!Array<number>} value */
  setMingaIdsList(value: number[]) {
    jspb.Message.setField(this, 1, value || []);
  }


  /**
   * @param {number} value
   * @param {number=} opt_index
   */
  addMingaIds(value: number, opt_index?: number): void {
    jspb.Message.addToRepeatedField(this, 1, value, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearMingaIdsList() {
    this.setMingaIdsList([]);
  }


  /**
   * optional minga.common.Date start_date = 2;
   * @return {?Date}
   */
  getStartDate(): common_date_pb.Date {
    return /** @type{?Date} */ (
      jspb.Message.getWrapperField(this, common_date_pb.Date, 2));
  }


  /** @param {?Date|undefined} value */
  setStartDate(value?: common_date_pb.Date) {
    jspb.Message.setWrapperField(this, 2, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearStartDate() {
    this.setStartDate(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasStartDate(): boolean {
    return jspb.Message.getField(this, 2) != null;
  }


  /**
   * optional minga.common.Date end_date = 3;
   * @return {?Date}
   */
  getEndDate(): common_date_pb.Date {
    return /** @type{?Date} */ (
      jspb.Message.getWrapperField(this, common_date_pb.Date, 3));
  }


  /** @param {?Date|undefined} value */
  setEndDate(value?: common_date_pb.Date) {
    jspb.Message.setWrapperField(this, 3, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearEndDate() {
    this.setEndDate(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasEndDate(): boolean {
    return jspb.Message.getField(this, 3) != null;
  }


  /**
   * optional GroupBy group_by = 4;
   * @return {!InternalGetMingaStatsAggregateRequest.GroupBy}
   */
  getGroupBy(): InternalGetMingaStatsAggregateRequest.GroupBy {
    return /** @type {!InternalGetMingaStatsAggregateRequest.GroupBy} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
  };


  /** @param {!InternalGetMingaStatsAggregateRequest.GroupBy} value */
  setGroupBy(value: InternalGetMingaStatsAggregateRequest.GroupBy) {
    jspb.Message.setProto3EnumField(this, 4, value);
  }


  /**
   * optional Type type = 5;
   * @return {!InternalGetMingaStatsAggregateRequest.Type}
   */
  getType(): InternalGetMingaStatsAggregateRequest.Type {
    return /** @type {!InternalGetMingaStatsAggregateRequest.Type} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
  };


  /** @param {!InternalGetMingaStatsAggregateRequest.Type} value */
  setType(value: InternalGetMingaStatsAggregateRequest.Type) {
    jspb.Message.setProto3EnumField(this, 5, value);
  }


}
export namespace InternalGetMingaStatsAggregateRequest {
export enum GroupBy {
  DATE = 0,
  WEEK = 1,
  MONTH = 2,
}
} // namespace InternalGetMingaStatsAggregateRequest
export namespace InternalGetMingaStatsAggregateRequest {
export enum Type {
  POST = 0,
  ACTIVE_USER = 1,
  BEHAVIOR = 2,
  HALL_PASS = 3,
  ACTIVE_STUDENT = 4,
}
} // namespace InternalGetMingaStatsAggregateRequest
export namespace AggregateDataPoint {
  export interface AsObject {
    timeValue: string;
    value: number;
  }
}
export class AggregateDataPoint extends jspb.Message {
  static readonly displayName = "AggregateDataPoint";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): AggregateDataPoint.AsObject {
    return AggregateDataPoint.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!AggregateDataPoint} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: AggregateDataPoint) {
    var f: any;
    var obj: any = {
      timeValue: jspb.Message.getFieldWithDefault(msg, 1, ""),
      value: jspb.Message.getFieldWithDefault(msg, 2, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!AggregateDataPoint}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new AggregateDataPoint;
    return AggregateDataPoint.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!AggregateDataPoint} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!AggregateDataPoint}
   */
  static deserializeBinaryFromReader(msg: AggregateDataPoint, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setTimeValue(value1);
        break;
      case 2:
        var value2 = /** @type {number} */ (reader.readInt32());
        msg.setValue(value2);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!AggregateDataPoint} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: AggregateDataPoint, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getTimeValue();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
    f = message.getValue();
    if (f !== 0) {
      writer.writeInt32(
        2,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    AggregateDataPoint.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string time_value = 1;
   * @return {string}
   */
  getTimeValue(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setTimeValue(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


  /**
   * optional int32 value = 2;
   * @return {number}
   */
  getValue(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
  };


  /** @param {number} value */
  setValue(value: number) {
    jspb.Message.setProto3IntField(this, 2, value);
  }


}
export namespace InternalGetMingaStatsAggregateResponse {
  export interface AsObject {
    dataPointsList: AggregateDataPoint.AsObject[];
  }
}
export class InternalGetMingaStatsAggregateResponse extends jspb.Message {
  static readonly displayName = "InternalGetMingaStatsAggregateResponse";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [1];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, InternalGetMingaStatsAggregateResponse.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): InternalGetMingaStatsAggregateResponse.AsObject {
    return InternalGetMingaStatsAggregateResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!InternalGetMingaStatsAggregateResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: InternalGetMingaStatsAggregateResponse) {
    var f: any;
    var obj: any = {
      dataPointsList: jspb.Message.toObjectList(msg.getDataPointsList(),
      AggregateDataPoint.toObject, includeInstance)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!InternalGetMingaStatsAggregateResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new InternalGetMingaStatsAggregateResponse;
    return InternalGetMingaStatsAggregateResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!InternalGetMingaStatsAggregateResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!InternalGetMingaStatsAggregateResponse}
   */
  static deserializeBinaryFromReader(msg: InternalGetMingaStatsAggregateResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new AggregateDataPoint;
        reader.readMessage(value1,AggregateDataPoint.deserializeBinaryFromReader);
        msg.addDataPoints(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!InternalGetMingaStatsAggregateResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: InternalGetMingaStatsAggregateResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getDataPointsList();
    if (f.length > 0) {
      writer.writeRepeatedMessage(
        1,
        f,
        AggregateDataPoint.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    InternalGetMingaStatsAggregateResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * repeated AggregateDataPoint data_points = 1;
   * @return {!Array<!AggregateDataPoint>}
   */
  getDataPointsList(): AggregateDataPoint[] {
    return /** @type{!Array<!AggregateDataPoint>} */ (
      jspb.Message.getRepeatedWrapperField(this, AggregateDataPoint, 1));
  }


  /** @param {!Array<!AggregateDataPoint>} value */
  setDataPointsList(value?: AggregateDataPoint[]) {
    jspb.Message.setRepeatedWrapperField(this, 1, value);
  }


  /**
   * @param {!AggregateDataPoint=} opt_value
   * @param {number=} opt_index
   * @return {!AggregateDataPoint}
   */
  addDataPoints(opt_value?: AggregateDataPoint, opt_index?: number): AggregateDataPoint {
    return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, AggregateDataPoint, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearDataPointsList() {
    this.setDataPointsList([]);
  }


}
export namespace InternalGetMingaStatsAggregateCountRequest {
  export interface AsObject {
    mingaIdsList: number[];
    startDate?: common_date_pb.Date.AsObject;
    endDate?: common_date_pb.Date.AsObject;
  }
}
export class InternalGetMingaStatsAggregateCountRequest extends jspb.Message {
  static readonly displayName = "InternalGetMingaStatsAggregateCountRequest";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [1];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, InternalGetMingaStatsAggregateCountRequest.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): InternalGetMingaStatsAggregateCountRequest.AsObject {
    return InternalGetMingaStatsAggregateCountRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!InternalGetMingaStatsAggregateCountRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: InternalGetMingaStatsAggregateCountRequest) {
    var f: any;
    var obj: any = {
      mingaIdsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
      startDate: (f = msg.getStartDate()) && common_date_pb.Date.toObject(includeInstance, f),
      endDate: (f = msg.getEndDate()) && common_date_pb.Date.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!InternalGetMingaStatsAggregateCountRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new InternalGetMingaStatsAggregateCountRequest;
    return InternalGetMingaStatsAggregateCountRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!InternalGetMingaStatsAggregateCountRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!InternalGetMingaStatsAggregateCountRequest}
   */
  static deserializeBinaryFromReader(msg: InternalGetMingaStatsAggregateCountRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {!Array<number>} */ (reader.readPackedInt32());
        msg.setMingaIdsList(value1);
        break;
      case 2:
        var value2 = new common_date_pb.Date;
        reader.readMessage(value2,common_date_pb.Date.deserializeBinaryFromReader);
        msg.setStartDate(value2);
        break;
      case 3:
        var value3 = new common_date_pb.Date;
        reader.readMessage(value3,common_date_pb.Date.deserializeBinaryFromReader);
        msg.setEndDate(value3);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!InternalGetMingaStatsAggregateCountRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: InternalGetMingaStatsAggregateCountRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getMingaIdsList();
    if (f.length > 0) {
      writer.writePackedInt32(
        1,
        f
      );
    }
    f = message.getStartDate();
    if (f != null) {
      writer.writeMessage(
        2,
        f,
        common_date_pb.Date.serializeBinaryToWriter
      );
    }
    f = message.getEndDate();
    if (f != null) {
      writer.writeMessage(
        3,
        f,
        common_date_pb.Date.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    InternalGetMingaStatsAggregateCountRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * repeated int32 minga_ids = 1;
   * @return {!Array<number>}
   */
  getMingaIdsList(): number[] {
    return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 1));
  };


  /** @param {!Array<number>} value */
  setMingaIdsList(value: number[]) {
    jspb.Message.setField(this, 1, value || []);
  }


  /**
   * @param {number} value
   * @param {number=} opt_index
   */
  addMingaIds(value: number, opt_index?: number): void {
    jspb.Message.addToRepeatedField(this, 1, value, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearMingaIdsList() {
    this.setMingaIdsList([]);
  }


  /**
   * optional minga.common.Date start_date = 2;
   * @return {?Date}
   */
  getStartDate(): common_date_pb.Date {
    return /** @type{?Date} */ (
      jspb.Message.getWrapperField(this, common_date_pb.Date, 2));
  }


  /** @param {?Date|undefined} value */
  setStartDate(value?: common_date_pb.Date) {
    jspb.Message.setWrapperField(this, 2, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearStartDate() {
    this.setStartDate(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasStartDate(): boolean {
    return jspb.Message.getField(this, 2) != null;
  }


  /**
   * optional minga.common.Date end_date = 3;
   * @return {?Date}
   */
  getEndDate(): common_date_pb.Date {
    return /** @type{?Date} */ (
      jspb.Message.getWrapperField(this, common_date_pb.Date, 3));
  }


  /** @param {?Date|undefined} value */
  setEndDate(value?: common_date_pb.Date) {
    jspb.Message.setWrapperField(this, 3, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearEndDate() {
    this.setEndDate(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasEndDate(): boolean {
    return jspb.Message.getField(this, 3) != null;
  }


}
export namespace InternalGetMingaStatsAggregateCountResponse {
  export interface AsObject {
    activeUserCount: number;
    activeStudentCount: number;
    hallPassCount: number;
    postCount: number;
    behaviorCount: number;
    checkinCount: number;
    pointCount: number;
    activeUser7DayCount: number;
    activeUser30DayCount: number;
  }
}
export class InternalGetMingaStatsAggregateCountResponse extends jspb.Message {
  static readonly displayName = "InternalGetMingaStatsAggregateCountResponse";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): InternalGetMingaStatsAggregateCountResponse.AsObject {
    return InternalGetMingaStatsAggregateCountResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!InternalGetMingaStatsAggregateCountResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: InternalGetMingaStatsAggregateCountResponse) {
    var f: any;
    var obj: any = {
      activeUserCount: jspb.Message.getFieldWithDefault(msg, 1, 0),
      activeStudentCount: jspb.Message.getFieldWithDefault(msg, 2, 0),
      hallPassCount: jspb.Message.getFieldWithDefault(msg, 3, 0),
      postCount: jspb.Message.getFieldWithDefault(msg, 4, 0),
      behaviorCount: jspb.Message.getFieldWithDefault(msg, 5, 0),
      checkinCount: jspb.Message.getFieldWithDefault(msg, 6, 0),
      pointCount: jspb.Message.getFieldWithDefault(msg, 7, 0),
      activeUser7DayCount: jspb.Message.getFieldWithDefault(msg, 8, 0),
      activeUser30DayCount: jspb.Message.getFieldWithDefault(msg, 9, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!InternalGetMingaStatsAggregateCountResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new InternalGetMingaStatsAggregateCountResponse;
    return InternalGetMingaStatsAggregateCountResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!InternalGetMingaStatsAggregateCountResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!InternalGetMingaStatsAggregateCountResponse}
   */
  static deserializeBinaryFromReader(msg: InternalGetMingaStatsAggregateCountResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {number} */ (reader.readInt32());
        msg.setActiveUserCount(value1);
        break;
      case 2:
        var value2 = /** @type {number} */ (reader.readInt32());
        msg.setActiveStudentCount(value2);
        break;
      case 3:
        var value3 = /** @type {number} */ (reader.readInt32());
        msg.setHallPassCount(value3);
        break;
      case 4:
        var value4 = /** @type {number} */ (reader.readInt32());
        msg.setPostCount(value4);
        break;
      case 5:
        var value5 = /** @type {number} */ (reader.readInt32());
        msg.setBehaviorCount(value5);
        break;
      case 6:
        var value6 = /** @type {number} */ (reader.readInt32());
        msg.setCheckinCount(value6);
        break;
      case 7:
        var value7 = /** @type {number} */ (reader.readInt32());
        msg.setPointCount(value7);
        break;
      case 8:
        var value8 = /** @type {number} */ (reader.readInt32());
        msg.setActiveUser7DayCount(value8);
        break;
      case 9:
        var value9 = /** @type {number} */ (reader.readInt32());
        msg.setActiveUser30DayCount(value9);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!InternalGetMingaStatsAggregateCountResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: InternalGetMingaStatsAggregateCountResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getActiveUserCount();
    if (f !== 0) {
      writer.writeInt32(
        1,
        f
      );
    }
    f = message.getActiveStudentCount();
    if (f !== 0) {
      writer.writeInt32(
        2,
        f
      );
    }
    f = message.getHallPassCount();
    if (f !== 0) {
      writer.writeInt32(
        3,
        f
      );
    }
    f = message.getPostCount();
    if (f !== 0) {
      writer.writeInt32(
        4,
        f
      );
    }
    f = message.getBehaviorCount();
    if (f !== 0) {
      writer.writeInt32(
        5,
        f
      );
    }
    f = message.getCheckinCount();
    if (f !== 0) {
      writer.writeInt32(
        6,
        f
      );
    }
    f = message.getPointCount();
    if (f !== 0) {
      writer.writeInt32(
        7,
        f
      );
    }
    f = message.getActiveUser7DayCount();
    if (f !== 0) {
      writer.writeInt32(
        8,
        f
      );
    }
    f = message.getActiveUser30DayCount();
    if (f !== 0) {
      writer.writeInt32(
        9,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    InternalGetMingaStatsAggregateCountResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional int32 active_user_count = 1;
   * @return {number}
   */
  getActiveUserCount(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
  };


  /** @param {number} value */
  setActiveUserCount(value: number) {
    jspb.Message.setProto3IntField(this, 1, value);
  }


  /**
   * optional int32 active_student_count = 2;
   * @return {number}
   */
  getActiveStudentCount(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
  };


  /** @param {number} value */
  setActiveStudentCount(value: number) {
    jspb.Message.setProto3IntField(this, 2, value);
  }


  /**
   * optional int32 hall_pass_count = 3;
   * @return {number}
   */
  getHallPassCount(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
  };


  /** @param {number} value */
  setHallPassCount(value: number) {
    jspb.Message.setProto3IntField(this, 3, value);
  }


  /**
   * optional int32 post_count = 4;
   * @return {number}
   */
  getPostCount(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
  };


  /** @param {number} value */
  setPostCount(value: number) {
    jspb.Message.setProto3IntField(this, 4, value);
  }


  /**
   * optional int32 behavior_count = 5;
   * @return {number}
   */
  getBehaviorCount(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
  };


  /** @param {number} value */
  setBehaviorCount(value: number) {
    jspb.Message.setProto3IntField(this, 5, value);
  }


  /**
   * optional int32 checkin_count = 6;
   * @return {number}
   */
  getCheckinCount(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
  };


  /** @param {number} value */
  setCheckinCount(value: number) {
    jspb.Message.setProto3IntField(this, 6, value);
  }


  /**
   * optional int32 point_count = 7;
   * @return {number}
   */
  getPointCount(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
  };


  /** @param {number} value */
  setPointCount(value: number) {
    jspb.Message.setProto3IntField(this, 7, value);
  }


  /**
   * optional int32 active_user_7_day_count = 8;
   * @return {number}
   */
  getActiveUser7DayCount(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
  };


  /** @param {number} value */
  setActiveUser7DayCount(value: number) {
    jspb.Message.setProto3IntField(this, 8, value);
  }


  /**
   * optional int32 active_user_30_day_count = 9;
   * @return {number}
   */
  getActiveUser30DayCount(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
  };


  /** @param {number} value */
  setActiveUser30DayCount(value: number) {
    jspb.Message.setProto3IntField(this, 9, value);
  }


}
