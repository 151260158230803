import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { MgImageModule } from '@app/src/app/components/MgImage';
import { MgLikeModule } from '@app/src/app/elements/MgLike';
import { MgShortCardModule } from '@app/src/app/elements/MgShortCard';
import { MgPipesModule } from '@app/src/app/pipes';

import { MgEventMiniCardElement } from './MgEventMiniCard.element';

@NgModule({
  imports: [
    // Minga dependencies
    MgShortCardModule,
    MgLikeModule,
    MgImageModule,
    MgPipesModule,

    // External dependnecies
    CommonModule,
  ],
  declarations: [MgEventMiniCardElement],
  exports: [MgEventMiniCardElement],
})
export class MgEventMiniCardModule {}
