import { Component, Inject, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { ICheckinReason } from 'libs/domain';
import { Observable, ReplaySubject, of } from 'rxjs';

import { ConfirmationDialogComponent } from '@shared/components/confirmation-dialog';
import {
  MODAL_OVERLAY_DATA,
  ModalOverlayPrimaryHeaderBackground,
  ModalOverlayRef,
  ModalOverlayServiceCloseEventType,
} from '@shared/components/modal-overlay';

import { StudentToolsCheckinMessages } from '../../constants';
import { StudentToolsCheckinService } from '../../services';
import {
  StudentToolsCheckinData,
  StudentToolsCheckinResponse,
} from '../../types';

@Component({
  selector: 'mg-st-checkin',
  templateUrl: './st-checkin.component.html',
  styleUrls: ['./st-checkin.component.scss'],
})
export class StCheckinComponent implements OnDestroy {
  /** Constants */
  public readonly MESSAGES = StudentToolsCheckinMessages;
  public readonly MODAL_COLOR = ModalOverlayPrimaryHeaderBackground.BLUE;

  /** General Observables */
  private readonly _destroyed = new ReplaySubject<void>(1);

  /** Reason Data */
  public readonly reason$: Observable<ICheckinReason>;

  /** Component Constructor */
  constructor(
    @Inject(MODAL_OVERLAY_DATA)
    public dialogData: StudentToolsCheckinData,
    private _modalRef: ModalOverlayRef<
      StudentToolsCheckinResponse,
      StudentToolsCheckinData
    >,
    private _dialog: MatDialog,
    private _selfCheckinService: StudentToolsCheckinService,
  ) {
    this.reason$ = of(this.dialogData.reason);
  }

  ngOnDestroy(): void {
    this._destroyed.next();
    this._destroyed.complete();
  }

  public async submit(): Promise<void> {
    const reasonId = this.dialogData.reason.id;
    await this._selfCheckinService.selfCheck(reasonId);
    this._modalRef.close(ModalOverlayServiceCloseEventType.SUBMIT, {
      reasonId,
    });
  }

  public cancel() {
    this._modalRef.close(ModalOverlayServiceCloseEventType.CLOSE);
  }
}
