export enum UserListFilterMessages {
  ALL_LISTS = 'All lists',
  MY_LISTS = 'My lists',
  MY_LISTS_CURRENT_TERM = 'My lists (current term)',
  ALL_LISTS_CURRENT_TERM = 'All lists (current term)',
  TODAYS_FLEX_ACTIVITIES = 'All activities (today)',
  TODAYS_FLEX_ACTIVITIES_MINE = 'My activities (today)',

  USER_LIST_MOBILE_TITLE = 'User List',
  USER_LIST_MOBILE_UNSELECT_ALL = 'Unselect all',
  USER_LIST_MOBILE_SUBMIT = 'Done',
}
