// GENERATED CODE -- DO NOT EDIT!

import {grpc} from '@improbable-eng/grpc-web';

import * as gateway_banner_pb from '../gateway/banner_pb';
import * as google_protobuf_wrappers_pb from '../google/protobuf/wrappers_pb';
import * as common_legacy_pb from '../common/legacy_pb';
import * as common_stream_pb from '../common/stream_pb';
import * as image_image_pb from '../image/image_pb';

export class BannerLibrary {
  static readonly serviceName = "minga.banner.BannerLibrary";

  static readonly GetStats = {
    methodName: "GetStats",
    service: BannerLibrary,
    requestStream: false,
    responseStream: false,
    requestType: gateway_banner_pb.BannerLibraryGetStatsRequest,
    responseType: gateway_banner_pb.BannerLibraryGetStatsResponse,
  };

  static readonly Get = {
    methodName: "Get",
    service: BannerLibrary,
    requestStream: false,
    responseStream: false,
    requestType: gateway_banner_pb.BannerLibraryGet,
    responseType: gateway_banner_pb.Banner,
  };

  static readonly GetAllByType = {
    methodName: "GetAllByType",
    service: BannerLibrary,
    requestStream: false,
    responseStream: false,
    requestType: gateway_banner_pb.BannerLibraryGetAllByType,
    responseType: gateway_banner_pb.Banners,
  };

  static readonly Add = {
    methodName: "Add",
    service: BannerLibrary,
    requestStream: false,
    responseStream: false,
    requestType: gateway_banner_pb.BannerLibraryAdd,
    responseType: gateway_banner_pb.Banner,
  };

  static readonly Update = {
    methodName: "Update",
    service: BannerLibrary,
    requestStream: false,
    responseStream: false,
    requestType: gateway_banner_pb.BannerLibraryUpdate,
    responseType: gateway_banner_pb.Banner,
  };

  static readonly Delete = {
    methodName: "Delete",
    service: BannerLibrary,
    requestStream: false,
    responseStream: false,
    requestType: gateway_banner_pb.BannerLibraryDelete,
    responseType: gateway_banner_pb.Banner,
  };

  static readonly SetArchived = {
    methodName: "SetArchived",
    service: BannerLibrary,
    requestStream: false,
    responseStream: false,
    requestType: gateway_banner_pb.BannerLibrarySetArchived,
    responseType: gateway_banner_pb.Banner,
  };

  static readonly Search = {
    methodName: "Search",
    service: BannerLibrary,
    requestStream: false,
    responseStream: true,
    requestType: common_stream_pb.StreamID,
    responseType: gateway_banner_pb.BannerLibrarySearchResponse,
  };

  static readonly SearchControl = {
    methodName: "SearchControl",
    service: BannerLibrary,
    requestStream: false,
    responseStream: false,
    requestType: common_stream_pb.StreamControl,
    responseType: common_stream_pb.StreamControlResponse,
  };

}

export class BannerLibraryClient {
  getStats() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  get() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  getAllByType() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  add() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  update() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  delete() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  setArchived() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  search() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  searchControl() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
}
