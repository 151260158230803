import { IDateRangePreset } from 'libs/domain';

import { DYNAMIC_DATES } from '@shared/components/form/components/form-date-range/form-date-range.constants';
import { DatePickerPreset } from '@shared/components/form/components/form-date-range/form-date-range.types';

import { ClientDatePreset } from '../types/mm-date-presets.types';

export const getNameLabel = (item: ClientDatePreset): string => {
  const { name, startDate, endDate, endDateIsCurrentDay } = item;

  if (name) return name;

  let formatted = startDate ? startDate.format('MMM D, YYYY') : '';

  if (endDateIsCurrentDay) {
    formatted += ' - today';
  } else {
    formatted += ` - ${endDate ? endDate.format('MMM D, YYYY') : ''}`;
  }

  return formatted;
};

export const customDatePresetMapper = (
  item: IDateRangePreset,
): ClientDatePreset => {
  const endDate = item.endDateIsCurrentDay
    ? DYNAMIC_DATES.endOfToday()
    : item.endDate;

  return {
    ...item,
    isDefaultPreset: false,
    endDate,
  };
};

export const defaultDatePresetMapper = (
  item: DatePickerPreset,
  inactivePresets: string[],
): ClientDatePreset => ({
  id: item.value,
  name: item.label,
  active: !inactivePresets.includes(item.value),
  startDate: item.start(),
  endDate: item.end(),
  endDateIsCurrentDay: false,
  isDefaultPreset: true,
});
