import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { MgAvatarModule } from '@app/src/app/elements/MgAvatar';
import { MgPersonMinimalModule } from '@app/src/app/elements/MgPersonMinimal';

import { MgPersonListElement } from './MgPersonListElement.element';

@NgModule({
  imports: [
    // Minga dependencies
    MgAvatarModule,
    MgPersonMinimalModule,

    // External dependencies
    CommonModule,
  ],
  declarations: [MgPersonListElement],
  exports: [MgPersonListElement],
})
export class MgPersonListElementModule {}
