import { InjectionToken } from '@angular/core';

import { Observable } from 'rxjs';

import {
  IMgStreamControl,
  IMgStreamFilter,
  IMgStreamItem,
} from '@app/src/app/util/stream';

import { MgStreamScrollerItemDirective } from './MgStreamScrollerItem.directive';

export interface IMgStreamScrollerInstance<ServiceT = any> {
  filter: IMgStreamFilter | null;

  service: ServiceT | null;

  rpcStream?: keyof ServiceT;

  rpcControl?: keyof ServiceT;

  /**
   * The number of elements to be rendered above & below the current virtual
   * scroller viewport. Defaults to `5`, you may need to increase this for
   * smaller elements.
   */
  bufferAmount: number;

  /**
   * How many items close to the top or bottom should a load trigger
   */
  loadThreshold: number;

  grid: boolean;

  stream: IMgStreamControl<any> | null;
  items$: Observable<IMgStreamItem<any>[]> | null;

  readonly frontExhausted: boolean;
  readonly backExhausted: boolean;
  readonly isDone: boolean;
  readonly loading$: Observable<boolean>;

  restartStream(): void;

  isStreamEmpty(): boolean;

  unregisterStreamItem(streamItem: MgStreamScrollerItemDirective): void;

  registerStreamItem(streamItem: MgStreamScrollerItemDirective): void;
}

export const MG_STREAM_SCROLLER_INSTANCE =
  new InjectionToken<IMgStreamScrollerInstance>('IMgStreamScrollerInstance');
