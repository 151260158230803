import { IPointsRedeemedResult } from 'libs/domain';
import { points_pb } from 'libs/generated-grpc-ts';

import { dateTimeMessageToDayjsObject, dayJsObjectToDateTimeMsg } from '../';

export const toProto = (
  item: IPointsRedeemedResult,
): points_pb.PointsRedeemedItem => {
  const msg = new points_pb.PointsRedeemedItem();
  msg.setId(item.id);
  msg.setPoints(item.points);
  msg.setReward(item.reward);
  msg.setRewardId(item.rewardId);
  msg.setDate(dayJsObjectToDateTimeMsg(item.date));
  const issuedTo = new points_pb.PointsHistoryItemPerson();
  issuedTo.setFirstName(item.issuedTo.firstName);
  issuedTo.setLastName(item.issuedTo.lastName);
  issuedTo.setStudentId(item.issuedTo.studentId);
  issuedTo.setGrade(item.issuedTo.grade);
  issuedTo.setBadgeIconUrl(item.issuedTo.badgeIconUrl);
  issuedTo.setBadgeRoleName(item.issuedTo.role);
  msg.setIssuedTo(issuedTo);
  return msg;
};

export const fromProto = (
  msg: points_pb.PointsRedeemedItem,
): IPointsRedeemedResult => ({
  id: msg.getId(),
  points: msg.getPoints(),
  reward: msg.getReward(),
  rewardId: msg.getRewardId(),
  date: dateTimeMessageToDayjsObject(msg.getDate()),
  issuedTo: {
    firstName: msg.getIssuedTo().getFirstName(),
    lastName: msg.getIssuedTo().getLastName(),
    studentId: msg.getIssuedTo().getStudentId(),
    grade: msg.getIssuedTo().getGrade(),
    badgeIconUrl: msg.getIssuedTo().getBadgeIconUrl(),
    role: msg.getIssuedTo().getBadgeRoleName(),
  },
});
