import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';

import { MgIconModule } from '@app/src/app/icon';
import { MgOverlayToolsModule } from '@app/src/app/overlay/tools';
import { MgPipesModule } from '@app/src/app/pipes';
import { MgSpinnerModule } from '@app/src/app/spinner';

import { FormModule } from '../form';
import { GenericModule } from '../generic';
import { TextModule } from '../text/text.module';
import { SystemAlertModalComponent } from './system-alert-modal.component';

@NgModule({
  imports: [
    // Minga dependencies
    MgIconModule,
    MgSpinnerModule,
    MgOverlayToolsModule,
    TextModule,
    MgPipesModule,
    GenericModule,
    FormModule,

    // External dependencies
    CommonModule,
    FlexLayoutModule,
  ],
  declarations: [SystemAlertModalComponent],
  exports: [SystemAlertModalComponent],
})
export class SystemAlertModalModule {}
