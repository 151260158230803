/** Content Types for user creation. Used in URLs. */
export enum ContentNavigationTypes {
  SMS_MESSAGE = 'sms_message',
  POST = 'post',
  FULL_GRAPHIC = 'fullGraphic',
  VIDEO = 'video',
  ANNOUNCEMENT = 'announcement',
  EVENT = 'event',
  POLL = 'poll',
  CHALLENGE_RESPONSE = 'challenge_response',
}
