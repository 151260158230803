import { EventStatus } from 'libs/domain';
import { EventReportFilters } from 'libs/domain';
import { event_pb } from 'libs/generated-grpc-ts';
import { $enum } from 'ts-enum-util';

export const toProto = (
  filter: EventReportFilters,
  limit?: number,
  offset?: number,
): event_pb.EventReportFilter => {
  const filterMessage = new event_pb.EventReportFilter();
  if (offset) filterMessage.setOffset(offset);
  if (limit) filterMessage.setLimit(limit);
  filterMessage.setContextHash(filter.eventContext);
  filterMessage.setPersonStatus(filter.personStatus);
  return filterMessage;
};

export const fromProto = (
  proto: event_pb.EventReportFilter,
): EventReportFilters & { limit?: number; offset?: number } => {
  const personStatus = $enum(EventStatus).asValueOrThrow(
    proto.getPersonStatus(),
  );
  return {
    eventContext: proto.getContextHash(),
    personStatus,
    limit: proto.getLimit(),
    offset: proto.getOffset(),
  };
};
