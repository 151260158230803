// source: gateway/feed_event.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

import * as jspb from 'google-protobuf';
import * as common_legacy_pb from '../common/legacy_pb';
import * as common_stream_pb from '../common/stream_pb';
import * as common_delta_pb from '../common/delta_pb';
import * as gateway_mention_pb from '../gateway/mention_pb';
import * as gateway_feed_pb from '../gateway/feed_pb';
import * as gateway_person_view_pb from '../gateway/person_view_pb';
import * as gateway_moderation_pb from '../gateway/moderation_pb';
import * as gateway_content_views_pb from '../gateway/content_views_pb';
export namespace FeedEvent {
  export interface AsObject {
    title: string;
    body: string;
    assetList: string[];
    publishTimestamp: number;
    unpublishTimstamp: number;
    status: boolean;
    bodyMentionRangeList: gateway_mention_pb.MentionRange.AsObject[];
    startTimestamp: number;
    endTimestamp: number;
    allowGoing: boolean;
    hostedBy: string;
    location: string;
    eventCode: string;
    bannerHash: string;
    allDay: boolean;
    cancelled: boolean;
    ticketUrl: string;
    eventCategory: string;
    ticketInfo: string;
    ticketPrice: number;
  }
}
export class FeedEvent extends jspb.Message {
  static readonly displayName = "FeedEvent";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [3,7];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, FeedEvent.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): FeedEvent.AsObject {
    return FeedEvent.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!FeedEvent} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: FeedEvent) {
    var f: any;
    var obj: any = {
      title: jspb.Message.getFieldWithDefault(msg, 1, ""),
      body: jspb.Message.getFieldWithDefault(msg, 2, ""),
      assetList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
      publishTimestamp: jspb.Message.getFieldWithDefault(msg, 4, 0),
      unpublishTimstamp: jspb.Message.getFieldWithDefault(msg, 5, 0),
      status: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
      bodyMentionRangeList: jspb.Message.toObjectList(msg.getBodyMentionRangeList(),
      gateway_mention_pb.MentionRange.toObject, includeInstance),
      startTimestamp: jspb.Message.getFieldWithDefault(msg, 8, 0),
      endTimestamp: jspb.Message.getFieldWithDefault(msg, 9, 0),
      allowGoing: jspb.Message.getBooleanFieldWithDefault(msg, 10, false),
      hostedBy: jspb.Message.getFieldWithDefault(msg, 11, ""),
      location: jspb.Message.getFieldWithDefault(msg, 12, ""),
      eventCode: jspb.Message.getFieldWithDefault(msg, 13, ""),
      bannerHash: jspb.Message.getFieldWithDefault(msg, 14, ""),
      allDay: jspb.Message.getBooleanFieldWithDefault(msg, 15, false),
      cancelled: jspb.Message.getBooleanFieldWithDefault(msg, 16, false),
      ticketUrl: jspb.Message.getFieldWithDefault(msg, 17, ""),
      eventCategory: jspb.Message.getFieldWithDefault(msg, 18, ""),
      ticketInfo: jspb.Message.getFieldWithDefault(msg, 19, ""),
      ticketPrice: jspb.Message.getFloatingPointFieldWithDefault(msg, 20, 0.0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!FeedEvent}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new FeedEvent;
    return FeedEvent.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!FeedEvent} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!FeedEvent}
   */
  static deserializeBinaryFromReader(msg: FeedEvent, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setTitle(value1);
        break;
      case 2:
        var value2 = /** @type {string} */ (reader.readString());
        msg.setBody(value2);
        break;
      case 3:
        var value3 = /** @type {string} */ (reader.readString());
        msg.addAsset(value3);
        break;
      case 4:
        var value4 = /** @type {number} */ (reader.readUint64());
        msg.setPublishTimestamp(value4);
        break;
      case 5:
        var value5 = /** @type {number} */ (reader.readUint64());
        msg.setUnpublishTimstamp(value5);
        break;
      case 6:
        var value6 = /** @type {boolean} */ (reader.readBool());
        msg.setStatus(value6);
        break;
      case 7:
        var value7 = new gateway_mention_pb.MentionRange;
        reader.readMessage(value7,gateway_mention_pb.MentionRange.deserializeBinaryFromReader);
        msg.addBodyMentionRange(value7);
        break;
      case 8:
        var value8 = /** @type {number} */ (reader.readUint64());
        msg.setStartTimestamp(value8);
        break;
      case 9:
        var value9 = /** @type {number} */ (reader.readUint64());
        msg.setEndTimestamp(value9);
        break;
      case 10:
        var value10 = /** @type {boolean} */ (reader.readBool());
        msg.setAllowGoing(value10);
        break;
      case 11:
        var value11 = /** @type {string} */ (reader.readString());
        msg.setHostedBy(value11);
        break;
      case 12:
        var value12 = /** @type {string} */ (reader.readString());
        msg.setLocation(value12);
        break;
      case 13:
        var value13 = /** @type {string} */ (reader.readString());
        msg.setEventCode(value13);
        break;
      case 14:
        var value14 = /** @type {string} */ (reader.readString());
        msg.setBannerHash(value14);
        break;
      case 15:
        var value15 = /** @type {boolean} */ (reader.readBool());
        msg.setAllDay(value15);
        break;
      case 16:
        var value16 = /** @type {boolean} */ (reader.readBool());
        msg.setCancelled(value16);
        break;
      case 17:
        var value17 = /** @type {string} */ (reader.readString());
        msg.setTicketUrl(value17);
        break;
      case 18:
        var value18 = /** @type {string} */ (reader.readString());
        msg.setEventCategory(value18);
        break;
      case 19:
        var value19 = /** @type {string} */ (reader.readString());
        msg.setTicketInfo(value19);
        break;
      case 20:
        var value20 = /** @type {number} */ (reader.readFloat());
        msg.setTicketPrice(value20);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!FeedEvent} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: FeedEvent, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getTitle();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
    f = message.getBody();
    if (f.length > 0) {
      writer.writeString(
        2,
        f
      );
    }
    f = message.getAssetList();
    if (f.length > 0) {
      writer.writeRepeatedString(
        3,
        f
      );
    }
    f = message.getPublishTimestamp();
    if (f !== 0) {
      writer.writeUint64(
        4,
        f
      );
    }
    f = message.getUnpublishTimstamp();
    if (f !== 0) {
      writer.writeUint64(
        5,
        f
      );
    }
    f = message.getStatus();
    if (f) {
      writer.writeBool(
        6,
        f
      );
    }
    f = message.getBodyMentionRangeList();
    if (f.length > 0) {
      writer.writeRepeatedMessage(
        7,
        f,
        gateway_mention_pb.MentionRange.serializeBinaryToWriter
      );
    }
    f = message.getStartTimestamp();
    if (f !== 0) {
      writer.writeUint64(
        8,
        f
      );
    }
    f = message.getEndTimestamp();
    if (f !== 0) {
      writer.writeUint64(
        9,
        f
      );
    }
    f = message.getAllowGoing();
    if (f) {
      writer.writeBool(
        10,
        f
      );
    }
    f = message.getHostedBy();
    if (f.length > 0) {
      writer.writeString(
        11,
        f
      );
    }
    f = message.getLocation();
    if (f.length > 0) {
      writer.writeString(
        12,
        f
      );
    }
    f = message.getEventCode();
    if (f.length > 0) {
      writer.writeString(
        13,
        f
      );
    }
    f = message.getBannerHash();
    if (f.length > 0) {
      writer.writeString(
        14,
        f
      );
    }
    f = message.getAllDay();
    if (f) {
      writer.writeBool(
        15,
        f
      );
    }
    f = message.getCancelled();
    if (f) {
      writer.writeBool(
        16,
        f
      );
    }
    f = message.getTicketUrl();
    if (f.length > 0) {
      writer.writeString(
        17,
        f
      );
    }
    f = message.getEventCategory();
    if (f.length > 0) {
      writer.writeString(
        18,
        f
      );
    }
    f = message.getTicketInfo();
    if (f.length > 0) {
      writer.writeString(
        19,
        f
      );
    }
    f = message.getTicketPrice();
    if (f !== 0.0) {
      writer.writeFloat(
        20,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    FeedEvent.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string title = 1;
   * @return {string}
   */
  getTitle(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setTitle(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


  /**
   * optional string body = 2;
   * @return {string}
   */
  getBody(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
  };


  /** @param {string} value */
  setBody(value: string) {
    jspb.Message.setProto3StringField(this, 2, value);
  }


  /**
   * repeated string asset = 3;
   * @return {!Array<string>}
   */
  getAssetList(): string[] {
    return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
  };


  /** @param {!Array<string>} value */
  setAssetList(value: string[]) {
    jspb.Message.setField(this, 3, value || []);
  }


  /**
   * @param {string} value
   * @param {number=} opt_index
   */
  addAsset(value: string, opt_index?: number): void {
    jspb.Message.addToRepeatedField(this, 3, value, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearAssetList() {
    this.setAssetList([]);
  }


  /**
   * optional uint64 publish_timestamp = 4;
   * @return {number}
   */
  getPublishTimestamp(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
  };


  /** @param {number} value */
  setPublishTimestamp(value: number) {
    jspb.Message.setProto3IntField(this, 4, value);
  }


  /**
   * optional uint64 unpublish_timstamp = 5;
   * @return {number}
   */
  getUnpublishTimstamp(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
  };


  /** @param {number} value */
  setUnpublishTimstamp(value: number) {
    jspb.Message.setProto3IntField(this, 5, value);
  }


  /**
   * optional bool status = 6;
   * @return {boolean}
   */
  getStatus(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
  };


  /** @param {boolean} value */
  setStatus(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 6, value);
  }


  /**
   * repeated MentionRange body_mention_range = 7;
   * @return {!Array<!MentionRange>}
   */
  getBodyMentionRangeList(): gateway_mention_pb.MentionRange[] {
    return /** @type{!Array<!MentionRange>} */ (
      jspb.Message.getRepeatedWrapperField(this, gateway_mention_pb.MentionRange, 7));
  }


  /** @param {!Array<!MentionRange>} value */
  setBodyMentionRangeList(value?: gateway_mention_pb.MentionRange[]) {
    jspb.Message.setRepeatedWrapperField(this, 7, value);
  }


  /**
   * @param {!MentionRange=} opt_value
   * @param {number=} opt_index
   * @return {!MentionRange}
   */
  addBodyMentionRange(opt_value?: gateway_mention_pb.MentionRange, opt_index?: number): gateway_mention_pb.MentionRange {
    return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, gateway_mention_pb.MentionRange, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearBodyMentionRangeList() {
    this.setBodyMentionRangeList([]);
  }


  /**
   * optional uint64 start_timestamp = 8;
   * @return {number}
   */
  getStartTimestamp(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
  };


  /** @param {number} value */
  setStartTimestamp(value: number) {
    jspb.Message.setProto3IntField(this, 8, value);
  }


  /**
   * optional uint64 end_timestamp = 9;
   * @return {number}
   */
  getEndTimestamp(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
  };


  /** @param {number} value */
  setEndTimestamp(value: number) {
    jspb.Message.setProto3IntField(this, 9, value);
  }


  /**
   * optional bool allow_going = 10;
   * @return {boolean}
   */
  getAllowGoing(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
  };


  /** @param {boolean} value */
  setAllowGoing(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 10, value);
  }


  /**
   * optional string hosted_by = 11;
   * @return {string}
   */
  getHostedBy(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
  };


  /** @param {string} value */
  setHostedBy(value: string) {
    jspb.Message.setProto3StringField(this, 11, value);
  }


  /**
   * optional string location = 12;
   * @return {string}
   */
  getLocation(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
  };


  /** @param {string} value */
  setLocation(value: string) {
    jspb.Message.setProto3StringField(this, 12, value);
  }


  /**
   * optional string event_code = 13;
   * @return {string}
   */
  getEventCode(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
  };


  /** @param {string} value */
  setEventCode(value: string) {
    jspb.Message.setProto3StringField(this, 13, value);
  }


  /**
   * optional string banner_hash = 14;
   * @return {string}
   */
  getBannerHash(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
  };


  /** @param {string} value */
  setBannerHash(value: string) {
    jspb.Message.setProto3StringField(this, 14, value);
  }


  /**
   * optional bool all_day = 15;
   * @return {boolean}
   */
  getAllDay(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 15, false));
  };


  /** @param {boolean} value */
  setAllDay(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 15, value);
  }


  /**
   * optional bool cancelled = 16;
   * @return {boolean}
   */
  getCancelled(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 16, false));
  };


  /** @param {boolean} value */
  setCancelled(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 16, value);
  }


  /**
   * optional string ticket_url = 17;
   * @return {string}
   */
  getTicketUrl(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
  };


  /** @param {string} value */
  setTicketUrl(value: string) {
    jspb.Message.setProto3StringField(this, 17, value);
  }


  /**
   * optional string event_category = 18;
   * @return {string}
   */
  getEventCategory(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
  };


  /** @param {string} value */
  setEventCategory(value: string) {
    jspb.Message.setProto3StringField(this, 18, value);
  }


  /**
   * optional string ticket_info = 19;
   * @return {string}
   */
  getTicketInfo(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
  };


  /** @param {string} value */
  setTicketInfo(value: string) {
    jspb.Message.setProto3StringField(this, 19, value);
  }


  /**
   * optional float ticket_price = 20;
   * @return {number}
   */
  getTicketPrice(): number {
    return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 20, 0.0));
  };


  /** @param {number} value */
  setTicketPrice(value: number) {
    jspb.Message.setProto3FloatField(this, 20, value);
  }


}
export namespace FeedEventDelta {
  export interface AsObject {
    hash: string;
    title?: common_delta_pb.StringDelta.AsObject;
    body?: common_delta_pb.StringDelta.AsObject;
    startTimestamp?: common_delta_pb.Uint64Delta.AsObject;
    endTimestamp?: common_delta_pb.Uint64Delta.AsObject;
    allowGoing?: common_delta_pb.BoolDelta.AsObject;
    hostedBy?: common_delta_pb.StringDelta.AsObject;
    eventCode?: common_delta_pb.StringDelta.AsObject;
    allDay?: common_delta_pb.BoolDelta.AsObject;
    ticketUrl?: common_delta_pb.StringDelta.AsObject;
    eventCategory?: common_delta_pb.StringDelta.AsObject;
    ticketInfo?: common_delta_pb.StringDelta.AsObject;
    ticketPrice?: common_delta_pb.FloatDelta.AsObject;
  }
}
export class FeedEventDelta extends jspb.Message {
  static readonly displayName = "FeedEventDelta";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): FeedEventDelta.AsObject {
    return FeedEventDelta.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!FeedEventDelta} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: FeedEventDelta) {
    var f: any;
    var obj: any = {
      hash: jspb.Message.getFieldWithDefault(msg, 1, ""),
      title: (f = msg.getTitle()) && common_delta_pb.StringDelta.toObject(includeInstance, f),
      body: (f = msg.getBody()) && common_delta_pb.StringDelta.toObject(includeInstance, f),
      startTimestamp: (f = msg.getStartTimestamp()) && common_delta_pb.Uint64Delta.toObject(includeInstance, f),
      endTimestamp: (f = msg.getEndTimestamp()) && common_delta_pb.Uint64Delta.toObject(includeInstance, f),
      allowGoing: (f = msg.getAllowGoing()) && common_delta_pb.BoolDelta.toObject(includeInstance, f),
      hostedBy: (f = msg.getHostedBy()) && common_delta_pb.StringDelta.toObject(includeInstance, f),
      eventCode: (f = msg.getEventCode()) && common_delta_pb.StringDelta.toObject(includeInstance, f),
      allDay: (f = msg.getAllDay()) && common_delta_pb.BoolDelta.toObject(includeInstance, f),
      ticketUrl: (f = msg.getTicketUrl()) && common_delta_pb.StringDelta.toObject(includeInstance, f),
      eventCategory: (f = msg.getEventCategory()) && common_delta_pb.StringDelta.toObject(includeInstance, f),
      ticketInfo: (f = msg.getTicketInfo()) && common_delta_pb.StringDelta.toObject(includeInstance, f),
      ticketPrice: (f = msg.getTicketPrice()) && common_delta_pb.FloatDelta.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!FeedEventDelta}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new FeedEventDelta;
    return FeedEventDelta.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!FeedEventDelta} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!FeedEventDelta}
   */
  static deserializeBinaryFromReader(msg: FeedEventDelta, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setHash(value1);
        break;
      case 2:
        var value2 = new common_delta_pb.StringDelta;
        reader.readMessage(value2,common_delta_pb.StringDelta.deserializeBinaryFromReader);
        msg.setTitle(value2);
        break;
      case 3:
        var value3 = new common_delta_pb.StringDelta;
        reader.readMessage(value3,common_delta_pb.StringDelta.deserializeBinaryFromReader);
        msg.setBody(value3);
        break;
      case 4:
        var value4 = new common_delta_pb.Uint64Delta;
        reader.readMessage(value4,common_delta_pb.Uint64Delta.deserializeBinaryFromReader);
        msg.setStartTimestamp(value4);
        break;
      case 5:
        var value5 = new common_delta_pb.Uint64Delta;
        reader.readMessage(value5,common_delta_pb.Uint64Delta.deserializeBinaryFromReader);
        msg.setEndTimestamp(value5);
        break;
      case 6:
        var value6 = new common_delta_pb.BoolDelta;
        reader.readMessage(value6,common_delta_pb.BoolDelta.deserializeBinaryFromReader);
        msg.setAllowGoing(value6);
        break;
      case 7:
        var value7 = new common_delta_pb.StringDelta;
        reader.readMessage(value7,common_delta_pb.StringDelta.deserializeBinaryFromReader);
        msg.setHostedBy(value7);
        break;
      case 8:
        var value8 = new common_delta_pb.StringDelta;
        reader.readMessage(value8,common_delta_pb.StringDelta.deserializeBinaryFromReader);
        msg.setEventCode(value8);
        break;
      case 9:
        var value9 = new common_delta_pb.BoolDelta;
        reader.readMessage(value9,common_delta_pb.BoolDelta.deserializeBinaryFromReader);
        msg.setAllDay(value9);
        break;
      case 10:
        var value10 = new common_delta_pb.StringDelta;
        reader.readMessage(value10,common_delta_pb.StringDelta.deserializeBinaryFromReader);
        msg.setTicketUrl(value10);
        break;
      case 11:
        var value11 = new common_delta_pb.StringDelta;
        reader.readMessage(value11,common_delta_pb.StringDelta.deserializeBinaryFromReader);
        msg.setEventCategory(value11);
        break;
      case 12:
        var value12 = new common_delta_pb.StringDelta;
        reader.readMessage(value12,common_delta_pb.StringDelta.deserializeBinaryFromReader);
        msg.setTicketInfo(value12);
        break;
      case 13:
        var value13 = new common_delta_pb.FloatDelta;
        reader.readMessage(value13,common_delta_pb.FloatDelta.deserializeBinaryFromReader);
        msg.setTicketPrice(value13);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!FeedEventDelta} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: FeedEventDelta, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getHash();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
    f = message.getTitle();
    if (f != null) {
      writer.writeMessage(
        2,
        f,
        common_delta_pb.StringDelta.serializeBinaryToWriter
      );
    }
    f = message.getBody();
    if (f != null) {
      writer.writeMessage(
        3,
        f,
        common_delta_pb.StringDelta.serializeBinaryToWriter
      );
    }
    f = message.getStartTimestamp();
    if (f != null) {
      writer.writeMessage(
        4,
        f,
        common_delta_pb.Uint64Delta.serializeBinaryToWriter
      );
    }
    f = message.getEndTimestamp();
    if (f != null) {
      writer.writeMessage(
        5,
        f,
        common_delta_pb.Uint64Delta.serializeBinaryToWriter
      );
    }
    f = message.getAllowGoing();
    if (f != null) {
      writer.writeMessage(
        6,
        f,
        common_delta_pb.BoolDelta.serializeBinaryToWriter
      );
    }
    f = message.getHostedBy();
    if (f != null) {
      writer.writeMessage(
        7,
        f,
        common_delta_pb.StringDelta.serializeBinaryToWriter
      );
    }
    f = message.getEventCode();
    if (f != null) {
      writer.writeMessage(
        8,
        f,
        common_delta_pb.StringDelta.serializeBinaryToWriter
      );
    }
    f = message.getAllDay();
    if (f != null) {
      writer.writeMessage(
        9,
        f,
        common_delta_pb.BoolDelta.serializeBinaryToWriter
      );
    }
    f = message.getTicketUrl();
    if (f != null) {
      writer.writeMessage(
        10,
        f,
        common_delta_pb.StringDelta.serializeBinaryToWriter
      );
    }
    f = message.getEventCategory();
    if (f != null) {
      writer.writeMessage(
        11,
        f,
        common_delta_pb.StringDelta.serializeBinaryToWriter
      );
    }
    f = message.getTicketInfo();
    if (f != null) {
      writer.writeMessage(
        12,
        f,
        common_delta_pb.StringDelta.serializeBinaryToWriter
      );
    }
    f = message.getTicketPrice();
    if (f != null) {
      writer.writeMessage(
        13,
        f,
        common_delta_pb.FloatDelta.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    FeedEventDelta.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string hash = 1;
   * @return {string}
   */
  getHash(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setHash(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


  /**
   * optional minga.common.StringDelta title = 2;
   * @return {?StringDelta}
   */
  getTitle(): common_delta_pb.StringDelta {
    return /** @type{?StringDelta} */ (
      jspb.Message.getWrapperField(this, common_delta_pb.StringDelta, 2));
  }


  /** @param {?StringDelta|undefined} value */
  setTitle(value?: common_delta_pb.StringDelta) {
    jspb.Message.setWrapperField(this, 2, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearTitle() {
    this.setTitle(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasTitle(): boolean {
    return jspb.Message.getField(this, 2) != null;
  }


  /**
   * optional minga.common.StringDelta body = 3;
   * @return {?StringDelta}
   */
  getBody(): common_delta_pb.StringDelta {
    return /** @type{?StringDelta} */ (
      jspb.Message.getWrapperField(this, common_delta_pb.StringDelta, 3));
  }


  /** @param {?StringDelta|undefined} value */
  setBody(value?: common_delta_pb.StringDelta) {
    jspb.Message.setWrapperField(this, 3, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearBody() {
    this.setBody(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasBody(): boolean {
    return jspb.Message.getField(this, 3) != null;
  }


  /**
   * optional minga.common.Uint64Delta start_timestamp = 4;
   * @return {?Uint64Delta}
   */
  getStartTimestamp(): common_delta_pb.Uint64Delta {
    return /** @type{?Uint64Delta} */ (
      jspb.Message.getWrapperField(this, common_delta_pb.Uint64Delta, 4));
  }


  /** @param {?Uint64Delta|undefined} value */
  setStartTimestamp(value?: common_delta_pb.Uint64Delta) {
    jspb.Message.setWrapperField(this, 4, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearStartTimestamp() {
    this.setStartTimestamp(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasStartTimestamp(): boolean {
    return jspb.Message.getField(this, 4) != null;
  }


  /**
   * optional minga.common.Uint64Delta end_timestamp = 5;
   * @return {?Uint64Delta}
   */
  getEndTimestamp(): common_delta_pb.Uint64Delta {
    return /** @type{?Uint64Delta} */ (
      jspb.Message.getWrapperField(this, common_delta_pb.Uint64Delta, 5));
  }


  /** @param {?Uint64Delta|undefined} value */
  setEndTimestamp(value?: common_delta_pb.Uint64Delta) {
    jspb.Message.setWrapperField(this, 5, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearEndTimestamp() {
    this.setEndTimestamp(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasEndTimestamp(): boolean {
    return jspb.Message.getField(this, 5) != null;
  }


  /**
   * optional minga.common.BoolDelta allow_going = 6;
   * @return {?BoolDelta}
   */
  getAllowGoing(): common_delta_pb.BoolDelta {
    return /** @type{?BoolDelta} */ (
      jspb.Message.getWrapperField(this, common_delta_pb.BoolDelta, 6));
  }


  /** @param {?BoolDelta|undefined} value */
  setAllowGoing(value?: common_delta_pb.BoolDelta) {
    jspb.Message.setWrapperField(this, 6, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearAllowGoing() {
    this.setAllowGoing(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasAllowGoing(): boolean {
    return jspb.Message.getField(this, 6) != null;
  }


  /**
   * optional minga.common.StringDelta hosted_by = 7;
   * @return {?StringDelta}
   */
  getHostedBy(): common_delta_pb.StringDelta {
    return /** @type{?StringDelta} */ (
      jspb.Message.getWrapperField(this, common_delta_pb.StringDelta, 7));
  }


  /** @param {?StringDelta|undefined} value */
  setHostedBy(value?: common_delta_pb.StringDelta) {
    jspb.Message.setWrapperField(this, 7, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearHostedBy() {
    this.setHostedBy(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasHostedBy(): boolean {
    return jspb.Message.getField(this, 7) != null;
  }


  /**
   * optional minga.common.StringDelta event_code = 8;
   * @return {?StringDelta}
   */
  getEventCode(): common_delta_pb.StringDelta {
    return /** @type{?StringDelta} */ (
      jspb.Message.getWrapperField(this, common_delta_pb.StringDelta, 8));
  }


  /** @param {?StringDelta|undefined} value */
  setEventCode(value?: common_delta_pb.StringDelta) {
    jspb.Message.setWrapperField(this, 8, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearEventCode() {
    this.setEventCode(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasEventCode(): boolean {
    return jspb.Message.getField(this, 8) != null;
  }


  /**
   * optional minga.common.BoolDelta all_day = 9;
   * @return {?BoolDelta}
   */
  getAllDay(): common_delta_pb.BoolDelta {
    return /** @type{?BoolDelta} */ (
      jspb.Message.getWrapperField(this, common_delta_pb.BoolDelta, 9));
  }


  /** @param {?BoolDelta|undefined} value */
  setAllDay(value?: common_delta_pb.BoolDelta) {
    jspb.Message.setWrapperField(this, 9, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearAllDay() {
    this.setAllDay(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasAllDay(): boolean {
    return jspb.Message.getField(this, 9) != null;
  }


  /**
   * optional minga.common.StringDelta ticket_url = 10;
   * @return {?StringDelta}
   */
  getTicketUrl(): common_delta_pb.StringDelta {
    return /** @type{?StringDelta} */ (
      jspb.Message.getWrapperField(this, common_delta_pb.StringDelta, 10));
  }


  /** @param {?StringDelta|undefined} value */
  setTicketUrl(value?: common_delta_pb.StringDelta) {
    jspb.Message.setWrapperField(this, 10, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearTicketUrl() {
    this.setTicketUrl(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasTicketUrl(): boolean {
    return jspb.Message.getField(this, 10) != null;
  }


  /**
   * optional minga.common.StringDelta event_category = 11;
   * @return {?StringDelta}
   */
  getEventCategory(): common_delta_pb.StringDelta {
    return /** @type{?StringDelta} */ (
      jspb.Message.getWrapperField(this, common_delta_pb.StringDelta, 11));
  }


  /** @param {?StringDelta|undefined} value */
  setEventCategory(value?: common_delta_pb.StringDelta) {
    jspb.Message.setWrapperField(this, 11, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearEventCategory() {
    this.setEventCategory(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasEventCategory(): boolean {
    return jspb.Message.getField(this, 11) != null;
  }


  /**
   * optional minga.common.StringDelta ticket_info = 12;
   * @return {?StringDelta}
   */
  getTicketInfo(): common_delta_pb.StringDelta {
    return /** @type{?StringDelta} */ (
      jspb.Message.getWrapperField(this, common_delta_pb.StringDelta, 12));
  }


  /** @param {?StringDelta|undefined} value */
  setTicketInfo(value?: common_delta_pb.StringDelta) {
    jspb.Message.setWrapperField(this, 12, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearTicketInfo() {
    this.setTicketInfo(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasTicketInfo(): boolean {
    return jspb.Message.getField(this, 12) != null;
  }


  /**
   * optional minga.common.FloatDelta ticket_price = 13;
   * @return {?FloatDelta}
   */
  getTicketPrice(): common_delta_pb.FloatDelta {
    return /** @type{?FloatDelta} */ (
      jspb.Message.getWrapperField(this, common_delta_pb.FloatDelta, 13));
  }


  /** @param {?FloatDelta|undefined} value */
  setTicketPrice(value?: common_delta_pb.FloatDelta) {
    jspb.Message.setWrapperField(this, 13, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearTicketPrice() {
    this.setTicketPrice(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasTicketPrice(): boolean {
    return jspb.Message.getField(this, 13) != null;
  }


}
export namespace CreateFeedEventRequest {
  export interface AsObject {
    post?: FeedEvent.AsObject;
  }
}
export class CreateFeedEventRequest extends jspb.Message {
  static readonly displayName = "CreateFeedEventRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): CreateFeedEventRequest.AsObject {
    return CreateFeedEventRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!CreateFeedEventRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: CreateFeedEventRequest) {
    var f: any;
    var obj: any = {
      post: (f = msg.getPost()) && FeedEvent.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!CreateFeedEventRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new CreateFeedEventRequest;
    return CreateFeedEventRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!CreateFeedEventRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!CreateFeedEventRequest}
   */
  static deserializeBinaryFromReader(msg: CreateFeedEventRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new FeedEvent;
        reader.readMessage(value1,FeedEvent.deserializeBinaryFromReader);
        msg.setPost(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!CreateFeedEventRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: CreateFeedEventRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getPost();
    if (f != null) {
      writer.writeMessage(
        1,
        f,
        FeedEvent.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    CreateFeedEventRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional FeedEvent post = 1;
   * @return {?FeedEvent}
   */
  getPost(): FeedEvent {
    return /** @type{?FeedEvent} */ (
      jspb.Message.getWrapperField(this, FeedEvent, 1));
  }


  /** @param {?FeedEvent|undefined} value */
  setPost(value?: FeedEvent) {
    jspb.Message.setWrapperField(this, 1, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearPost() {
    this.setPost(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasPost(): boolean {
    return jspb.Message.getField(this, 1) != null;
  }


}
export namespace CreateFeedEventResponse {
  export interface AsObject {
    status?: common_legacy_pb.StatusCode;
    reason?: common_legacy_pb.StatusReason.AsObject;
    moderationResult?: gateway_moderation_pb.ModerationResult.AsObject;
  }
}
export class CreateFeedEventResponse extends jspb.Message {
  static readonly displayName = "CreateFeedEventResponse";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): CreateFeedEventResponse.AsObject {
    return CreateFeedEventResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!CreateFeedEventResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: CreateFeedEventResponse) {
    var f: any;
    var obj: any = {
      status: jspb.Message.getFieldWithDefault(msg, 1, 0),
      reason: (f = msg.getReason()) && common_legacy_pb.StatusReason.toObject(includeInstance, f),
      moderationResult: (f = msg.getModerationResult()) && gateway_moderation_pb.ModerationResult.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!CreateFeedEventResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new CreateFeedEventResponse;
    return CreateFeedEventResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!CreateFeedEventResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!CreateFeedEventResponse}
   */
  static deserializeBinaryFromReader(msg: CreateFeedEventResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {!StatusCode} */ (reader.readEnum());
        msg.setStatus(value1);
        break;
      case 2:
        var value2 = new common_legacy_pb.StatusReason;
        reader.readMessage(value2,common_legacy_pb.StatusReason.deserializeBinaryFromReader);
        msg.setReason(value2);
        break;
      case 3:
        var value3 = new gateway_moderation_pb.ModerationResult;
        reader.readMessage(value3,gateway_moderation_pb.ModerationResult.deserializeBinaryFromReader);
        msg.setModerationResult(value3);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!CreateFeedEventResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: CreateFeedEventResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getStatus();
    if (f !== 0.0) {
      writer.writeEnum(
        1,
        f
      );
    }
    f = message.getReason();
    if (f != null) {
      writer.writeMessage(
        2,
        f,
        common_legacy_pb.StatusReason.serializeBinaryToWriter
      );
    }
    f = message.getModerationResult();
    if (f != null) {
      writer.writeMessage(
        3,
        f,
        gateway_moderation_pb.ModerationResult.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    CreateFeedEventResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional StatusCode status = 1;
   * @return {!StatusCode}
   */
  getStatus(): common_legacy_pb.StatusCode {
    return /** @type {!StatusCode} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
  };


  /** @param {!StatusCode} value */
  setStatus(value: common_legacy_pb.StatusCode) {
    jspb.Message.setProto3EnumField(this, 1, value);
  }


  /**
   * optional StatusReason reason = 2;
   * @return {?StatusReason}
   */
  getReason(): common_legacy_pb.StatusReason {
    return /** @type{?StatusReason} */ (
      jspb.Message.getWrapperField(this, common_legacy_pb.StatusReason, 2));
  }


  /** @param {?StatusReason|undefined} value */
  setReason(value?: common_legacy_pb.StatusReason) {
    jspb.Message.setWrapperField(this, 2, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearReason() {
    this.setReason(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasReason(): boolean {
    return jspb.Message.getField(this, 2) != null;
  }


  /**
   * optional minga.ModerationResult moderation_result = 3;
   * @return {?ModerationResult}
   */
  getModerationResult(): gateway_moderation_pb.ModerationResult {
    return /** @type{?ModerationResult} */ (
      jspb.Message.getWrapperField(this, gateway_moderation_pb.ModerationResult, 3));
  }


  /** @param {?ModerationResult|undefined} value */
  setModerationResult(value?: gateway_moderation_pb.ModerationResult) {
    jspb.Message.setWrapperField(this, 3, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearModerationResult() {
    this.setModerationResult(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasModerationResult(): boolean {
    return jspb.Message.getField(this, 3) != null;
  }


}
export namespace UpdateFeedEventRequest {
  export interface AsObject {
    post?: FeedEventDelta.AsObject;
  }
}
export class UpdateFeedEventRequest extends jspb.Message {
  static readonly displayName = "UpdateFeedEventRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): UpdateFeedEventRequest.AsObject {
    return UpdateFeedEventRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!UpdateFeedEventRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: UpdateFeedEventRequest) {
    var f: any;
    var obj: any = {
      post: (f = msg.getPost()) && FeedEventDelta.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!UpdateFeedEventRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new UpdateFeedEventRequest;
    return UpdateFeedEventRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!UpdateFeedEventRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!UpdateFeedEventRequest}
   */
  static deserializeBinaryFromReader(msg: UpdateFeedEventRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new FeedEventDelta;
        reader.readMessage(value1,FeedEventDelta.deserializeBinaryFromReader);
        msg.setPost(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!UpdateFeedEventRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: UpdateFeedEventRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getPost();
    if (f != null) {
      writer.writeMessage(
        1,
        f,
        FeedEventDelta.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    UpdateFeedEventRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional FeedEventDelta post = 1;
   * @return {?FeedEventDelta}
   */
  getPost(): FeedEventDelta {
    return /** @type{?FeedEventDelta} */ (
      jspb.Message.getWrapperField(this, FeedEventDelta, 1));
  }


  /** @param {?FeedEventDelta|undefined} value */
  setPost(value?: FeedEventDelta) {
    jspb.Message.setWrapperField(this, 1, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearPost() {
    this.setPost(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasPost(): boolean {
    return jspb.Message.getField(this, 1) != null;
  }


}
export namespace UpdateFeedEventResponse {
  export interface AsObject {
    status?: common_legacy_pb.StatusCode;
    reason?: common_legacy_pb.StatusReason.AsObject;
  }
}
export class UpdateFeedEventResponse extends jspb.Message {
  static readonly displayName = "UpdateFeedEventResponse";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): UpdateFeedEventResponse.AsObject {
    return UpdateFeedEventResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!UpdateFeedEventResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: UpdateFeedEventResponse) {
    var f: any;
    var obj: any = {
      status: jspb.Message.getFieldWithDefault(msg, 1, 0),
      reason: (f = msg.getReason()) && common_legacy_pb.StatusReason.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!UpdateFeedEventResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new UpdateFeedEventResponse;
    return UpdateFeedEventResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!UpdateFeedEventResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!UpdateFeedEventResponse}
   */
  static deserializeBinaryFromReader(msg: UpdateFeedEventResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {!StatusCode} */ (reader.readEnum());
        msg.setStatus(value1);
        break;
      case 2:
        var value2 = new common_legacy_pb.StatusReason;
        reader.readMessage(value2,common_legacy_pb.StatusReason.deserializeBinaryFromReader);
        msg.setReason(value2);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!UpdateFeedEventResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: UpdateFeedEventResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getStatus();
    if (f !== 0.0) {
      writer.writeEnum(
        1,
        f
      );
    }
    f = message.getReason();
    if (f != null) {
      writer.writeMessage(
        2,
        f,
        common_legacy_pb.StatusReason.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    UpdateFeedEventResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional StatusCode status = 1;
   * @return {!StatusCode}
   */
  getStatus(): common_legacy_pb.StatusCode {
    return /** @type {!StatusCode} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
  };


  /** @param {!StatusCode} value */
  setStatus(value: common_legacy_pb.StatusCode) {
    jspb.Message.setProto3EnumField(this, 1, value);
  }


  /**
   * optional StatusReason reason = 2;
   * @return {?StatusReason}
   */
  getReason(): common_legacy_pb.StatusReason {
    return /** @type{?StatusReason} */ (
      jspb.Message.getWrapperField(this, common_legacy_pb.StatusReason, 2));
  }


  /** @param {?StatusReason|undefined} value */
  setReason(value?: common_legacy_pb.StatusReason) {
    jspb.Message.setWrapperField(this, 2, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearReason() {
    this.setReason(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasReason(): boolean {
    return jspb.Message.getField(this, 2) != null;
  }


}
export namespace DeleteFeedEventRequest {
  export interface AsObject {
    contextHash: string;
  }
}
export class DeleteFeedEventRequest extends jspb.Message {
  static readonly displayName = "DeleteFeedEventRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): DeleteFeedEventRequest.AsObject {
    return DeleteFeedEventRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!DeleteFeedEventRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: DeleteFeedEventRequest) {
    var f: any;
    var obj: any = {
      contextHash: jspb.Message.getFieldWithDefault(msg, 1, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!DeleteFeedEventRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new DeleteFeedEventRequest;
    return DeleteFeedEventRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!DeleteFeedEventRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!DeleteFeedEventRequest}
   */
  static deserializeBinaryFromReader(msg: DeleteFeedEventRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setContextHash(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!DeleteFeedEventRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: DeleteFeedEventRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getContextHash();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    DeleteFeedEventRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string context_hash = 1;
   * @return {string}
   */
  getContextHash(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setContextHash(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


}
export namespace DeleteFeedEventResponse {
  export interface AsObject {
    status?: common_legacy_pb.StatusCode;
    reason?: common_legacy_pb.StatusReason.AsObject;
  }
}
export class DeleteFeedEventResponse extends jspb.Message {
  static readonly displayName = "DeleteFeedEventResponse";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): DeleteFeedEventResponse.AsObject {
    return DeleteFeedEventResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!DeleteFeedEventResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: DeleteFeedEventResponse) {
    var f: any;
    var obj: any = {
      status: jspb.Message.getFieldWithDefault(msg, 1, 0),
      reason: (f = msg.getReason()) && common_legacy_pb.StatusReason.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!DeleteFeedEventResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new DeleteFeedEventResponse;
    return DeleteFeedEventResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!DeleteFeedEventResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!DeleteFeedEventResponse}
   */
  static deserializeBinaryFromReader(msg: DeleteFeedEventResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {!StatusCode} */ (reader.readEnum());
        msg.setStatus(value1);
        break;
      case 2:
        var value2 = new common_legacy_pb.StatusReason;
        reader.readMessage(value2,common_legacy_pb.StatusReason.deserializeBinaryFromReader);
        msg.setReason(value2);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!DeleteFeedEventResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: DeleteFeedEventResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getStatus();
    if (f !== 0.0) {
      writer.writeEnum(
        1,
        f
      );
    }
    f = message.getReason();
    if (f != null) {
      writer.writeMessage(
        2,
        f,
        common_legacy_pb.StatusReason.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    DeleteFeedEventResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional StatusCode status = 1;
   * @return {!StatusCode}
   */
  getStatus(): common_legacy_pb.StatusCode {
    return /** @type {!StatusCode} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
  };


  /** @param {!StatusCode} value */
  setStatus(value: common_legacy_pb.StatusCode) {
    jspb.Message.setProto3EnumField(this, 1, value);
  }


  /**
   * optional StatusReason reason = 2;
   * @return {?StatusReason}
   */
  getReason(): common_legacy_pb.StatusReason {
    return /** @type{?StatusReason} */ (
      jspb.Message.getWrapperField(this, common_legacy_pb.StatusReason, 2));
  }


  /** @param {?StatusReason|undefined} value */
  setReason(value?: common_legacy_pb.StatusReason) {
    jspb.Message.setWrapperField(this, 2, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearReason() {
    this.setReason(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasReason(): boolean {
    return jspb.Message.getField(this, 2) != null;
  }


}
export namespace CalendarEventDay {
  export interface AsObject {
    going: boolean;
    checkedIn: boolean;
    date: number;
    endDate: number;
    eventCategory: string;
  }
}
export class CalendarEventDay extends jspb.Message {
  static readonly displayName = "CalendarEventDay";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): CalendarEventDay.AsObject {
    return CalendarEventDay.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!CalendarEventDay} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: CalendarEventDay) {
    var f: any;
    var obj: any = {
      going: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
      checkedIn: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
      date: jspb.Message.getFieldWithDefault(msg, 5, 0),
      endDate: jspb.Message.getFieldWithDefault(msg, 6, 0),
      eventCategory: jspb.Message.getFieldWithDefault(msg, 7, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!CalendarEventDay}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new CalendarEventDay;
    return CalendarEventDay.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!CalendarEventDay} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!CalendarEventDay}
   */
  static deserializeBinaryFromReader(msg: CalendarEventDay, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {boolean} */ (reader.readBool());
        msg.setGoing(value1);
        break;
      case 2:
        var value2 = /** @type {boolean} */ (reader.readBool());
        msg.setCheckedIn(value2);
        break;
      case 5:
        var value5 = /** @type {number} */ (reader.readUint64());
        msg.setDate(value5);
        break;
      case 6:
        var value6 = /** @type {number} */ (reader.readUint64());
        msg.setEndDate(value6);
        break;
      case 7:
        var value7 = /** @type {string} */ (reader.readString());
        msg.setEventCategory(value7);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!CalendarEventDay} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: CalendarEventDay, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getGoing();
    if (f) {
      writer.writeBool(
        1,
        f
      );
    }
    f = message.getCheckedIn();
    if (f) {
      writer.writeBool(
        2,
        f
      );
    }
    f = message.getDate();
    if (f !== 0) {
      writer.writeUint64(
        5,
        f
      );
    }
    f = message.getEndDate();
    if (f !== 0) {
      writer.writeUint64(
        6,
        f
      );
    }
    f = message.getEventCategory();
    if (f.length > 0) {
      writer.writeString(
        7,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    CalendarEventDay.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional bool going = 1;
   * @return {boolean}
   */
  getGoing(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
  };


  /** @param {boolean} value */
  setGoing(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 1, value);
  }


  /**
   * optional bool checked_in = 2;
   * @return {boolean}
   */
  getCheckedIn(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
  };


  /** @param {boolean} value */
  setCheckedIn(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 2, value);
  }


  /**
   * optional uint64 date = 5;
   * @return {number}
   */
  getDate(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
  };


  /** @param {number} value */
  setDate(value: number) {
    jspb.Message.setProto3IntField(this, 5, value);
  }


  /**
   * optional uint64 end_date = 6;
   * @return {number}
   */
  getEndDate(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
  };


  /** @param {number} value */
  setEndDate(value: number) {
    jspb.Message.setProto3IntField(this, 6, value);
  }


  /**
   * optional string event_category = 7;
   * @return {string}
   */
  getEventCategory(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
  };


  /** @param {string} value */
  setEventCategory(value: string) {
    jspb.Message.setProto3StringField(this, 7, value);
  }


}
export namespace CalendarEventsRequest {
  export interface AsObject {
  }
}
export class CalendarEventsRequest extends jspb.Message {
  static readonly displayName = "CalendarEventsRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): CalendarEventsRequest.AsObject {
    return CalendarEventsRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!CalendarEventsRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: CalendarEventsRequest) {
    var f: any;
    var obj: any = {

    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!CalendarEventsRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new CalendarEventsRequest;
    return CalendarEventsRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!CalendarEventsRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!CalendarEventsRequest}
   */
  static deserializeBinaryFromReader(msg: CalendarEventsRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!CalendarEventsRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: CalendarEventsRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    CalendarEventsRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
}
export namespace CalendarEventsResponse {
  export interface AsObject {
    status?: common_legacy_pb.StatusCode;
    reason?: common_legacy_pb.StatusReason.AsObject;
    eventsList: CalendarEventDay.AsObject[];
  }
}
export class CalendarEventsResponse extends jspb.Message {
  static readonly displayName = "CalendarEventsResponse";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [3];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, CalendarEventsResponse.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): CalendarEventsResponse.AsObject {
    return CalendarEventsResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!CalendarEventsResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: CalendarEventsResponse) {
    var f: any;
    var obj: any = {
      status: jspb.Message.getFieldWithDefault(msg, 1, 0),
      reason: (f = msg.getReason()) && common_legacy_pb.StatusReason.toObject(includeInstance, f),
      eventsList: jspb.Message.toObjectList(msg.getEventsList(),
      CalendarEventDay.toObject, includeInstance)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!CalendarEventsResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new CalendarEventsResponse;
    return CalendarEventsResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!CalendarEventsResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!CalendarEventsResponse}
   */
  static deserializeBinaryFromReader(msg: CalendarEventsResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {!StatusCode} */ (reader.readEnum());
        msg.setStatus(value1);
        break;
      case 2:
        var value2 = new common_legacy_pb.StatusReason;
        reader.readMessage(value2,common_legacy_pb.StatusReason.deserializeBinaryFromReader);
        msg.setReason(value2);
        break;
      case 3:
        var value3 = new CalendarEventDay;
        reader.readMessage(value3,CalendarEventDay.deserializeBinaryFromReader);
        msg.addEvents(value3);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!CalendarEventsResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: CalendarEventsResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getStatus();
    if (f !== 0.0) {
      writer.writeEnum(
        1,
        f
      );
    }
    f = message.getReason();
    if (f != null) {
      writer.writeMessage(
        2,
        f,
        common_legacy_pb.StatusReason.serializeBinaryToWriter
      );
    }
    f = message.getEventsList();
    if (f.length > 0) {
      writer.writeRepeatedMessage(
        3,
        f,
        CalendarEventDay.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    CalendarEventsResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional StatusCode status = 1;
   * @return {!StatusCode}
   */
  getStatus(): common_legacy_pb.StatusCode {
    return /** @type {!StatusCode} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
  };


  /** @param {!StatusCode} value */
  setStatus(value: common_legacy_pb.StatusCode) {
    jspb.Message.setProto3EnumField(this, 1, value);
  }


  /**
   * optional StatusReason reason = 2;
   * @return {?StatusReason}
   */
  getReason(): common_legacy_pb.StatusReason {
    return /** @type{?StatusReason} */ (
      jspb.Message.getWrapperField(this, common_legacy_pb.StatusReason, 2));
  }


  /** @param {?StatusReason|undefined} value */
  setReason(value?: common_legacy_pb.StatusReason) {
    jspb.Message.setWrapperField(this, 2, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearReason() {
    this.setReason(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasReason(): boolean {
    return jspb.Message.getField(this, 2) != null;
  }


  /**
   * repeated CalendarEventDay events = 3;
   * @return {!Array<!CalendarEventDay>}
   */
  getEventsList(): CalendarEventDay[] {
    return /** @type{!Array<!CalendarEventDay>} */ (
      jspb.Message.getRepeatedWrapperField(this, CalendarEventDay, 3));
  }


  /** @param {!Array<!CalendarEventDay>} value */
  setEventsList(value?: CalendarEventDay[]) {
    jspb.Message.setRepeatedWrapperField(this, 3, value);
  }


  /**
   * @param {!CalendarEventDay=} opt_value
   * @param {number=} opt_index
   * @return {!CalendarEventDay}
   */
  addEvents(opt_value?: CalendarEventDay, opt_index?: number): CalendarEventDay {
    return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, CalendarEventDay, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearEventsList() {
    this.setEventsList([]);
  }


}
export namespace StreamEventGoingPeopleFeedResponse {
  export interface AsObject {
    itemMetadata?: common_stream_pb.StreamItemMetadata.AsObject;
    item?: gateway_person_view_pb.PersonViewEvent.AsObject;
  }
}
export class StreamEventGoingPeopleFeedResponse extends jspb.Message {
  static readonly displayName = "StreamEventGoingPeopleFeedResponse";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): StreamEventGoingPeopleFeedResponse.AsObject {
    return StreamEventGoingPeopleFeedResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!StreamEventGoingPeopleFeedResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: StreamEventGoingPeopleFeedResponse) {
    var f: any;
    var obj: any = {
      itemMetadata: (f = msg.getItemMetadata()) && common_stream_pb.StreamItemMetadata.toObject(includeInstance, f),
      item: (f = msg.getItem()) && gateway_person_view_pb.PersonViewEvent.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!StreamEventGoingPeopleFeedResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new StreamEventGoingPeopleFeedResponse;
    return StreamEventGoingPeopleFeedResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!StreamEventGoingPeopleFeedResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!StreamEventGoingPeopleFeedResponse}
   */
  static deserializeBinaryFromReader(msg: StreamEventGoingPeopleFeedResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new common_stream_pb.StreamItemMetadata;
        reader.readMessage(value1,common_stream_pb.StreamItemMetadata.deserializeBinaryFromReader);
        msg.setItemMetadata(value1);
        break;
      case 2:
        var value2 = new gateway_person_view_pb.PersonViewEvent;
        reader.readMessage(value2,gateway_person_view_pb.PersonViewEvent.deserializeBinaryFromReader);
        msg.setItem(value2);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!StreamEventGoingPeopleFeedResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: StreamEventGoingPeopleFeedResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getItemMetadata();
    if (f != null) {
      writer.writeMessage(
        1,
        f,
        common_stream_pb.StreamItemMetadata.serializeBinaryToWriter
      );
    }
    f = message.getItem();
    if (f != null) {
      writer.writeMessage(
        2,
        f,
        gateway_person_view_pb.PersonViewEvent.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    StreamEventGoingPeopleFeedResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional StreamItemMetadata item_metadata = 1;
   * @return {?StreamItemMetadata}
   */
  getItemMetadata(): common_stream_pb.StreamItemMetadata {
    return /** @type{?StreamItemMetadata} */ (
      jspb.Message.getWrapperField(this, common_stream_pb.StreamItemMetadata, 1));
  }


  /** @param {?StreamItemMetadata|undefined} value */
  setItemMetadata(value?: common_stream_pb.StreamItemMetadata) {
    jspb.Message.setWrapperField(this, 1, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearItemMetadata() {
    this.setItemMetadata(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasItemMetadata(): boolean {
    return jspb.Message.getField(this, 1) != null;
  }


  /**
   * optional PersonViewEvent item = 2;
   * @return {?PersonViewEvent}
   */
  getItem(): gateway_person_view_pb.PersonViewEvent {
    return /** @type{?PersonViewEvent} */ (
      jspb.Message.getWrapperField(this, gateway_person_view_pb.PersonViewEvent, 2));
  }


  /** @param {?PersonViewEvent|undefined} value */
  setItem(value?: gateway_person_view_pb.PersonViewEvent) {
    jspb.Message.setWrapperField(this, 2, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearItem() {
    this.setItem(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasItem(): boolean {
    return jspb.Message.getField(this, 2) != null;
  }


}
export namespace StreamEventManagerPeopleFeedResponse {
  export interface AsObject {
    itemMetadata?: common_stream_pb.StreamItemMetadata.AsObject;
    item?: gateway_person_view_pb.PersonViewMinimal.AsObject;
  }
}
export class StreamEventManagerPeopleFeedResponse extends jspb.Message {
  static readonly displayName = "StreamEventManagerPeopleFeedResponse";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): StreamEventManagerPeopleFeedResponse.AsObject {
    return StreamEventManagerPeopleFeedResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!StreamEventManagerPeopleFeedResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: StreamEventManagerPeopleFeedResponse) {
    var f: any;
    var obj: any = {
      itemMetadata: (f = msg.getItemMetadata()) && common_stream_pb.StreamItemMetadata.toObject(includeInstance, f),
      item: (f = msg.getItem()) && gateway_person_view_pb.PersonViewMinimal.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!StreamEventManagerPeopleFeedResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new StreamEventManagerPeopleFeedResponse;
    return StreamEventManagerPeopleFeedResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!StreamEventManagerPeopleFeedResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!StreamEventManagerPeopleFeedResponse}
   */
  static deserializeBinaryFromReader(msg: StreamEventManagerPeopleFeedResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new common_stream_pb.StreamItemMetadata;
        reader.readMessage(value1,common_stream_pb.StreamItemMetadata.deserializeBinaryFromReader);
        msg.setItemMetadata(value1);
        break;
      case 2:
        var value2 = new gateway_person_view_pb.PersonViewMinimal;
        reader.readMessage(value2,gateway_person_view_pb.PersonViewMinimal.deserializeBinaryFromReader);
        msg.setItem(value2);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!StreamEventManagerPeopleFeedResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: StreamEventManagerPeopleFeedResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getItemMetadata();
    if (f != null) {
      writer.writeMessage(
        1,
        f,
        common_stream_pb.StreamItemMetadata.serializeBinaryToWriter
      );
    }
    f = message.getItem();
    if (f != null) {
      writer.writeMessage(
        2,
        f,
        gateway_person_view_pb.PersonViewMinimal.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    StreamEventManagerPeopleFeedResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional StreamItemMetadata item_metadata = 1;
   * @return {?StreamItemMetadata}
   */
  getItemMetadata(): common_stream_pb.StreamItemMetadata {
    return /** @type{?StreamItemMetadata} */ (
      jspb.Message.getWrapperField(this, common_stream_pb.StreamItemMetadata, 1));
  }


  /** @param {?StreamItemMetadata|undefined} value */
  setItemMetadata(value?: common_stream_pb.StreamItemMetadata) {
    jspb.Message.setWrapperField(this, 1, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearItemMetadata() {
    this.setItemMetadata(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasItemMetadata(): boolean {
    return jspb.Message.getField(this, 1) != null;
  }


  /**
   * optional PersonViewMinimal item = 2;
   * @return {?PersonViewMinimal}
   */
  getItem(): gateway_person_view_pb.PersonViewMinimal {
    return /** @type{?PersonViewMinimal} */ (
      jspb.Message.getWrapperField(this, gateway_person_view_pb.PersonViewMinimal, 2));
  }


  /** @param {?PersonViewMinimal|undefined} value */
  setItem(value?: gateway_person_view_pb.PersonViewMinimal) {
    jspb.Message.setWrapperField(this, 2, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearItem() {
    this.setItem(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasItem(): boolean {
    return jspb.Message.getField(this, 2) != null;
  }


}
export namespace ExportEventGoingPeopleFeedRequest {
  export interface AsObject {
    statusFilter: string;
    contextHash: string;
  }
}
export class ExportEventGoingPeopleFeedRequest extends jspb.Message {
  static readonly displayName = "ExportEventGoingPeopleFeedRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): ExportEventGoingPeopleFeedRequest.AsObject {
    return ExportEventGoingPeopleFeedRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!ExportEventGoingPeopleFeedRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: ExportEventGoingPeopleFeedRequest) {
    var f: any;
    var obj: any = {
      statusFilter: jspb.Message.getFieldWithDefault(msg, 1, ""),
      contextHash: jspb.Message.getFieldWithDefault(msg, 2, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!ExportEventGoingPeopleFeedRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new ExportEventGoingPeopleFeedRequest;
    return ExportEventGoingPeopleFeedRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!ExportEventGoingPeopleFeedRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!ExportEventGoingPeopleFeedRequest}
   */
  static deserializeBinaryFromReader(msg: ExportEventGoingPeopleFeedRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setStatusFilter(value1);
        break;
      case 2:
        var value2 = /** @type {string} */ (reader.readString());
        msg.setContextHash(value2);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!ExportEventGoingPeopleFeedRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: ExportEventGoingPeopleFeedRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getStatusFilter();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
    f = message.getContextHash();
    if (f.length > 0) {
      writer.writeString(
        2,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    ExportEventGoingPeopleFeedRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string status_filter = 1;
   * @return {string}
   */
  getStatusFilter(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setStatusFilter(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


  /**
   * optional string context_hash = 2;
   * @return {string}
   */
  getContextHash(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
  };


  /** @param {string} value */
  setContextHash(value: string) {
    jspb.Message.setProto3StringField(this, 2, value);
  }


}
export namespace ExportEventGoingPeopleFeedChunk {
  export interface AsObject {
    chunk?: (string|Uint8Array);
    filename: string;
  }
}
export class ExportEventGoingPeopleFeedChunk extends jspb.Message {
  static readonly displayName = "ExportEventGoingPeopleFeedChunk";
  /**
   * Oneof group definitions for this message. Each group defines the field
   * numbers belonging to that group. When of these fields' value is set, all
   * other fields in the group are cleared. During deserialization, if multiple
   * fields are encountered for a group, only the last value seen will be kept.
   * @private {!Array<!Array<number>>}
   * @const
   */
  static readonly oneofGroups_ = [[1,2]];


  /**
   * @return {ExportEventGoingPeopleFeedChunk.InfoCase}
   */
  getInfoCase() {
    return /** @type {ExportEventGoingPeopleFeedChunk.InfoCase} */(jspb.Message.computeOneofCase(this, ExportEventGoingPeopleFeedChunk.oneofGroups_[0]));
  }

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, ExportEventGoingPeopleFeedChunk.oneofGroups_);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): ExportEventGoingPeopleFeedChunk.AsObject {
    return ExportEventGoingPeopleFeedChunk.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!ExportEventGoingPeopleFeedChunk} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: ExportEventGoingPeopleFeedChunk) {
    var f: any;
    var obj: any = {
      chunk: msg.getChunk_asB64(),
      filename: jspb.Message.getFieldWithDefault(msg, 2, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!ExportEventGoingPeopleFeedChunk}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new ExportEventGoingPeopleFeedChunk;
    return ExportEventGoingPeopleFeedChunk.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!ExportEventGoingPeopleFeedChunk} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!ExportEventGoingPeopleFeedChunk}
   */
  static deserializeBinaryFromReader(msg: ExportEventGoingPeopleFeedChunk, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {!Uint8Array} */ (reader.readBytes());
        msg.setChunk(value1);
        break;
      case 2:
        var value2 = /** @type {string} */ (reader.readString());
        msg.setFilename(value2);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!ExportEventGoingPeopleFeedChunk} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: ExportEventGoingPeopleFeedChunk, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 1));
    if (f != null) {
      writer.writeBytes(
        1,
        f
      );
    }
    f = /** @type {string} */ (jspb.Message.getField(message, 2));
    if (f != null) {
      writer.writeString(
        2,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    ExportEventGoingPeopleFeedChunk.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional bytes chunk = 1;
   * @return {!(string|Uint8Array)}
   */
  getChunk(): (string|Uint8Array) {
    return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /**
   * optional bytes chunk = 1;
   * This is a type-conversion wrapper around `getChunk()`
   * @return {string}
   */
  getChunk_asB64() {
    return /** @type {string} */ (jspb.Message.bytesAsB64(
        this.getChunk()));
  }


  /**
   * optional bytes chunk = 1;
   * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getChunk()`
   * @return {!Uint8Array}
   */
  getChunk_asU8() {
    return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
        this.getChunk()));
  }


  /** @param {!(string|Uint8Array)} value */
  setChunk(value: (string|Uint8Array)) {
    jspb.Message.setOneofField(this, 1, ExportEventGoingPeopleFeedChunk.oneofGroups_[0], value);
  }


  /**
   * Clears the field making it undefined.
   */
  clearChunk() {
    jspb.Message.setOneofField(this, 1, ExportEventGoingPeopleFeedChunk.oneofGroups_[0], undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasChunk(): boolean {
    return jspb.Message.getField(this, 1) != null;
  }


  /**
   * optional string filename = 2;
   * @return {string}
   */
  getFilename(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
  };


  /** @param {string} value */
  setFilename(value: string) {
    jspb.Message.setOneofField(this, 2, ExportEventGoingPeopleFeedChunk.oneofGroups_[0], value);
  }


  /**
   * Clears the field making it undefined.
   */
  clearFilename() {
    jspb.Message.setOneofField(this, 2, ExportEventGoingPeopleFeedChunk.oneofGroups_[0], undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasFilename(): boolean {
    return jspb.Message.getField(this, 2) != null;
  }


}
export namespace ExportEventGoingPeopleFeedChunk {
export enum InfoCase {
  INFO_NOT_SET = 0,
  CHUNK = 1,
  FILENAME = 2,
}
} // namespace ExportEventGoingPeopleFeedChunk
export namespace GetEventsRequest {
  export interface AsObject {
    limit: number;
    offset: number;
    eventType: string;
    groupHashList: string[];
    category: string;
    startDate: string;
    endDate: string;
  }
}
export class GetEventsRequest extends jspb.Message {
  static readonly displayName = "GetEventsRequest";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [4];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, GetEventsRequest.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): GetEventsRequest.AsObject {
    return GetEventsRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!GetEventsRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: GetEventsRequest) {
    var f: any;
    var obj: any = {
      limit: jspb.Message.getFieldWithDefault(msg, 1, 0),
      offset: jspb.Message.getFieldWithDefault(msg, 2, 0),
      eventType: jspb.Message.getFieldWithDefault(msg, 3, ""),
      groupHashList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
      category: jspb.Message.getFieldWithDefault(msg, 5, ""),
      startDate: jspb.Message.getFieldWithDefault(msg, 6, ""),
      endDate: jspb.Message.getFieldWithDefault(msg, 7, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!GetEventsRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new GetEventsRequest;
    return GetEventsRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!GetEventsRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!GetEventsRequest}
   */
  static deserializeBinaryFromReader(msg: GetEventsRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {number} */ (reader.readInt32());
        msg.setLimit(value1);
        break;
      case 2:
        var value2 = /** @type {number} */ (reader.readInt32());
        msg.setOffset(value2);
        break;
      case 3:
        var value3 = /** @type {string} */ (reader.readString());
        msg.setEventType(value3);
        break;
      case 4:
        var value4 = /** @type {string} */ (reader.readString());
        msg.addGroupHash(value4);
        break;
      case 5:
        var value5 = /** @type {string} */ (reader.readString());
        msg.setCategory(value5);
        break;
      case 6:
        var value6 = /** @type {string} */ (reader.readString());
        msg.setStartDate(value6);
        break;
      case 7:
        var value7 = /** @type {string} */ (reader.readString());
        msg.setEndDate(value7);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!GetEventsRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: GetEventsRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getLimit();
    if (f !== 0) {
      writer.writeInt32(
        1,
        f
      );
    }
    f = message.getOffset();
    if (f !== 0) {
      writer.writeInt32(
        2,
        f
      );
    }
    f = message.getEventType();
    if (f.length > 0) {
      writer.writeString(
        3,
        f
      );
    }
    f = message.getGroupHashList();
    if (f.length > 0) {
      writer.writeRepeatedString(
        4,
        f
      );
    }
    f = message.getCategory();
    if (f.length > 0) {
      writer.writeString(
        5,
        f
      );
    }
    f = message.getStartDate();
    if (f.length > 0) {
      writer.writeString(
        6,
        f
      );
    }
    f = message.getEndDate();
    if (f.length > 0) {
      writer.writeString(
        7,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    GetEventsRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional int32 limit = 1;
   * @return {number}
   */
  getLimit(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
  };


  /** @param {number} value */
  setLimit(value: number) {
    jspb.Message.setProto3IntField(this, 1, value);
  }


  /**
   * optional int32 offset = 2;
   * @return {number}
   */
  getOffset(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
  };


  /** @param {number} value */
  setOffset(value: number) {
    jspb.Message.setProto3IntField(this, 2, value);
  }


  /**
   * optional string event_type = 3;
   * @return {string}
   */
  getEventType(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
  };


  /** @param {string} value */
  setEventType(value: string) {
    jspb.Message.setProto3StringField(this, 3, value);
  }


  /**
   * repeated string group_hash = 4;
   * @return {!Array<string>}
   */
  getGroupHashList(): string[] {
    return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 4));
  };


  /** @param {!Array<string>} value */
  setGroupHashList(value: string[]) {
    jspb.Message.setField(this, 4, value || []);
  }


  /**
   * @param {string} value
   * @param {number=} opt_index
   */
  addGroupHash(value: string, opt_index?: number): void {
    jspb.Message.addToRepeatedField(this, 4, value, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearGroupHashList() {
    this.setGroupHashList([]);
  }


  /**
   * optional string category = 5;
   * @return {string}
   */
  getCategory(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
  };


  /** @param {string} value */
  setCategory(value: string) {
    jspb.Message.setProto3StringField(this, 5, value);
  }


  /**
   * optional string start_date = 6;
   * @return {string}
   */
  getStartDate(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
  };


  /** @param {string} value */
  setStartDate(value: string) {
    jspb.Message.setProto3StringField(this, 6, value);
  }


  /**
   * optional string end_date = 7;
   * @return {string}
   */
  getEndDate(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
  };


  /** @param {string} value */
  setEndDate(value: string) {
    jspb.Message.setProto3StringField(this, 7, value);
  }


}
export namespace GetEventsResponse {
  export interface AsObject {
    eventList: gateway_content_views_pb.ShortEventCardView.AsObject[];
  }
}
export class GetEventsResponse extends jspb.Message {
  static readonly displayName = "GetEventsResponse";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [1];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, GetEventsResponse.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): GetEventsResponse.AsObject {
    return GetEventsResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!GetEventsResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: GetEventsResponse) {
    var f: any;
    var obj: any = {
      eventList: jspb.Message.toObjectList(msg.getEventList(),
      gateway_content_views_pb.ShortEventCardView.toObject, includeInstance)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!GetEventsResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new GetEventsResponse;
    return GetEventsResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!GetEventsResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!GetEventsResponse}
   */
  static deserializeBinaryFromReader(msg: GetEventsResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new gateway_content_views_pb.ShortEventCardView;
        reader.readMessage(value1,gateway_content_views_pb.ShortEventCardView.deserializeBinaryFromReader);
        msg.addEvent(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!GetEventsResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: GetEventsResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getEventList();
    if (f.length > 0) {
      writer.writeRepeatedMessage(
        1,
        f,
        gateway_content_views_pb.ShortEventCardView.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    GetEventsResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * repeated ShortEventCardView event = 1;
   * @return {!Array<!ShortEventCardView>}
   */
  getEventList(): gateway_content_views_pb.ShortEventCardView[] {
    return /** @type{!Array<!ShortEventCardView>} */ (
      jspb.Message.getRepeatedWrapperField(this, gateway_content_views_pb.ShortEventCardView, 1));
  }


  /** @param {!Array<!ShortEventCardView>} value */
  setEventList(value?: gateway_content_views_pb.ShortEventCardView[]) {
    jspb.Message.setRepeatedWrapperField(this, 1, value);
  }


  /**
   * @param {!ShortEventCardView=} opt_value
   * @param {number=} opt_index
   * @return {!ShortEventCardView}
   */
  addEvent(opt_value?: gateway_content_views_pb.ShortEventCardView, opt_index?: number): gateway_content_views_pb.ShortEventCardView {
    return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, gateway_content_views_pb.ShortEventCardView, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearEventList() {
    this.setEventList([]);
  }


}
