export enum DisplayNameFormat {
  FIRST_L = 'FIRST_L',
  LAST_F = 'LAST_F',
  FIRST_LAST = 'FIRST_LAST',
  LAST_FIRST = 'LAST_FIRST',
  F_LAST = 'F_LAST',
  L_FIRST = 'L_FIRST',
}

/**
 * We should turn off the ESLint for named functions. They are completely valid
 * and we shouldn't be reporting them as errors.
 *
 * This file needs to be cleaned up and made more robust, see tests.
 */
export namespace DisplayNameFormat {
  export enum Index {
    FIRST_L = 0,
    LAST_F = 1,
    FIRST_LAST = 2,
    LAST_FIRST = 3,
    F_LAST = 4,
    L_FIRST = 5,
  }

  export function ensureIndex(index: number) {
    switch (index) {
      case Index.FIRST_L:
      case Index.LAST_F:
      case Index.FIRST_LAST:
      case Index.LAST_FIRST:
      case Index.F_LAST:
      case Index.L_FIRST:
        return <Index>index;
    }

    throw new Error('Invalid display name format index: ' + index);
  }

  export function fromIndex(index: DisplayNameFormat.Index) {
    switch (index) {
      default:
        console.warn(
          'DisplayNameFormat.fromIndex() unknown index ' +
            index +
            ' defaulting to FIRST_L',
        );
      case Index.FIRST_L:
        return DisplayNameFormat.FIRST_L;
      case Index.LAST_F:
        return DisplayNameFormat.LAST_F;
      case Index.FIRST_LAST:
        return DisplayNameFormat.FIRST_LAST;
      case Index.LAST_FIRST:
        return DisplayNameFormat.LAST_FIRST;
      case Index.F_LAST:
        return DisplayNameFormat.F_LAST;
      case Index.L_FIRST:
        return DisplayNameFormat.L_FIRST;
    }
  }

  export function toIndex(format: DisplayNameFormat) {
    switch (format) {
      default:
        console.warn(
          'DisplayNameFormat.toIndex() unknown format ' +
            format +
            ' defaulting to FIRST_L',
        );
      case DisplayNameFormat.FIRST_L:
        return Index.FIRST_L;
      case DisplayNameFormat.LAST_F:
        return Index.LAST_F;
      case DisplayNameFormat.FIRST_LAST:
        return Index.FIRST_LAST;
      case DisplayNameFormat.LAST_FIRST:
        return Index.LAST_FIRST;
      case DisplayNameFormat.F_LAST:
        return Index.F_LAST;
      case DisplayNameFormat.L_FIRST:
        return Index.L_FIRST;
    }
  }

  export function format(
    first: string,
    last: string,
    format: DisplayNameFormat,
  ): string {
    first = first || ' ';
    last = last || ' ';

    let displayName: string = '';

    switch (format) {
      default:
        console.warn(
          'DisplayNameFormat.format unknown format (' +
            format +
            ') defaulting to FIRST_L',
        );
      case DisplayNameFormat.FIRST_L:
        displayName = (first + ' ' + last[0].toUpperCase()).trim();
        break;
      case DisplayNameFormat.FIRST_LAST:
        displayName = (first + ' ' + last).trim();
        break;
      case DisplayNameFormat.LAST_F:
        displayName = (last + ' ' + first[0].toUpperCase()).trim();
        break;
      case DisplayNameFormat.LAST_FIRST:
        displayName = (last + ' ' + first).trim();
        break;
      case DisplayNameFormat.F_LAST:
        displayName = (first[0].toUpperCase() + ' ' + last).trim();
        break;
      case DisplayNameFormat.L_FIRST:
        displayName = (last[0].toUpperCase() + ' ' + first).trim();
        break;
    }

    return displayName;
  }
}
