import { MingaRoleType } from 'libs/domain';
import { IPointsHistoryItem, PointHistoryType } from 'libs/domain';
import { points_pb } from 'libs/generated-grpc-ts';
import { $enum } from 'ts-enum-util';

import { dateObjectToDateTimeMessage, dateTimeMessageToDateObject } from '../';

export const toProto = (
  item: IPointsHistoryItem,
): points_pb.PointsHistoryItem => {
  const msg = new points_pb.PointsHistoryItem();

  msg.setId(item.id);
  msg.setPoints(item.points);
  msg.setReason(item.reason);
  msg.setType(item.type);
  if (item.issuedBy) {
    const personMsg = new points_pb.PointsHistoryItemPerson();
    personMsg.setPersonHash(item.issuedBy?.personHash);
    personMsg.setStudentId(item.issuedBy?.studentId || '');
    personMsg.setFirstName(item.issuedBy?.firstName || '');
    personMsg.setLastName(item.issuedBy?.lastName || '');
    personMsg.setGrade(item.issuedBy?.grade || '');
    personMsg.setId(item.issuedBy?.id || 0);
    personMsg.setEmail(item.issuedBy?.email || '');
    personMsg.setBadgeRoleName(item.issuedBy?.roleType || '');
    msg.setIssuedBy(personMsg);
  }
  if (item.issuedTo) {
    const personMsg = new points_pb.PointsHistoryItemPerson();
    personMsg.setPersonHash(item.issuedTo?.personHash);
    personMsg.setStudentId(item.issuedTo?.studentId || '');
    personMsg.setFirstName(item.issuedTo?.firstName || '');
    personMsg.setLastName(item.issuedTo?.lastName || '');
    personMsg.setGrade(item.issuedTo?.grade || '');
    personMsg.setId(item.issuedTo?.id || 0);
    personMsg.setEmail(item.issuedTo?.email || '');
    personMsg.setBadgeRoleName(item.issuedTo?.roleType || '');
    msg.setIssuedTo(personMsg);
  }

  if (item.teamName) {
    msg.setTeamName(item.teamName);
    msg.setTeamId(item.teamId || 0);
  }

  const dateMsg = dateObjectToDateTimeMessage(item.date);
  msg.setDate(dateMsg);
  if (item.imported) msg.setImported(item.imported);

  return msg;
};

export const fromProto = (
  msg: points_pb.PointsHistoryItem,
): IPointsHistoryItem => {
  const date = dateTimeMessageToDateObject(msg.getDate());
  const points = msg.getPoints();
  const reason = msg.getReason();
  const issuedBy = msg.getIssuedBy();
  const issuedTo = msg.getIssuedTo();
  const typeValue = msg.getType();
  const type = $enum(PointHistoryType).asValueOrDefault(
    typeValue,
    PointHistoryType.CUSTOM,
  );
  return {
    id: msg.getId(),
    date,
    points,
    reason,
    issuedBy: issuedBy
      ? {
          id: issuedBy.getId(),
          email: issuedBy.getEmail(),
          personHash: issuedBy.getPersonHash(),
          studentId: issuedBy.getStudentId(),
          firstName: issuedBy.getFirstName(),
          lastName: issuedBy.getLastName(),
          grade: issuedBy.getGrade(),
          roleType: $enum(MingaRoleType).asValueOrThrow(
            issuedBy.getBadgeRoleName(),
          ),
        }
      : undefined,
    issuedTo: issuedTo
      ? {
          id: issuedTo.getId(),
          email: issuedTo.getEmail(),
          personHash: issuedTo.getPersonHash(),
          studentId: issuedTo.getStudentId(),
          firstName: issuedTo.getFirstName(),
          lastName: issuedTo.getLastName(),
          grade: issuedTo.getGrade(),
          roleType: $enum(MingaRoleType).asValueOrThrow(
            issuedTo.getBadgeRoleName(),
          ),
        }
      : undefined,
    type,
    imported: msg.getImported(),
    teamName: msg.getTeamName(),
    teamId: msg.getTeamId(),
  };
};
