import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';

import { Subscription } from 'rxjs';

import { __mingaGetIcon } from '@app/src/app/util/icon';

export interface IMgeIconElementProperties {
  icon: string;
}

/**
 * Minga _Element_ icon element. This is an actual element that represents the
 * icons available in <mg-icon> in angular.
 */
@Component({
  selector: 'mge-icon',
  templateUrl: './MgeIcon.element.html',
  styleUrls: ['./MgeIcon.element.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MgeIconElement implements IMgeIconElementProperties, OnChanges {
  @Input()
  icon: string = '';

  private _iconSubscription?: Subscription;
  private _svgIcon?: SVGElement;

  constructor(private _elementRef: ElementRef) {}

  get svgIcon(): SVGElement | null {
    return this._svgIcon || null;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.icon) {
      this._iconChange(this.icon);
    }
  }

  private _iconChange(icon: any) {
    if (typeof icon !== 'string') {
      return;
    }

    if (this._iconSubscription) {
      this._iconSubscription.unsubscribe();
      if (this._svgIcon) {
        this._svgIcon.remove();
        delete this._svgIcon;
      }
    }

    this._iconSubscription = __mingaGetIcon(icon).subscribe(svgIcon => {
      this._svgIcon = svgIcon;
      if (this._elementRef.nativeElement) {
        this._elementRef.nativeElement.appendChild(svgIcon);
      }
    });
  }
}
