// GENERATED CODE -- DO NOT EDIT!

import {grpc} from '@improbable-eng/grpc-web';

import * as gateway_program_context_stream_pb from '../gateway/program_context_stream_pb';
import * as common_stream_pb from '../common/stream_pb';
import * as image_image_pb from '../image/image_pb';
import * as gateway_content_views_pb from '../gateway/content_views_pb';

export class ProgramContextStreamManager {
  static readonly serviceName = "minga.content.ProgramContextStreamManager";

  static readonly StreamProgramContexts = {
    methodName: "StreamProgramContexts",
    service: ProgramContextStreamManager,
    requestStream: false,
    responseStream: true,
    requestType: common_stream_pb.StreamID,
    responseType: gateway_program_context_stream_pb.StreamProgramContextInfo,
  };

  static readonly StreamProgramContextsControl = {
    methodName: "StreamProgramContextsControl",
    service: ProgramContextStreamManager,
    requestStream: false,
    responseStream: false,
    requestType: common_stream_pb.StreamControl,
    responseType: common_stream_pb.StreamControlResponse,
  };

  static readonly StreamProgramContextContent = {
    methodName: "StreamProgramContextContent",
    service: ProgramContextStreamManager,
    requestStream: false,
    responseStream: true,
    requestType: common_stream_pb.StreamID,
    responseType: gateway_program_context_stream_pb.StreamProgramContextContentInfo,
  };

  static readonly StreamProgramContextContentControl = {
    methodName: "StreamProgramContextContentControl",
    service: ProgramContextStreamManager,
    requestStream: false,
    responseStream: false,
    requestType: common_stream_pb.StreamControl,
    responseType: common_stream_pb.StreamControlResponse,
  };

}

export class ProgramContextStreamManagerClient {
  streamProgramContexts() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  streamProgramContextsControl() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  streamProgramContextContent() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  streamProgramContextContentControl() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
}
