import { Pipe, PipeTransform } from '@angular/core';

import { IHallPassBlackOut } from 'libs/domain';
import { pickBy } from 'lodash';

/**
 * Get Blocked Days
 */
@Pipe({ name: 'getBlockDays' })
export class HpmRestrictionsGetBlackoutDaysPipe implements PipeTransform {
  transform(schedule: IHallPassBlackOut): string[] {
    return Object.keys(
      pickBy(
        {
          Monday: schedule.blockMonday,
          Tuesday: schedule.blockTuesday,
          Wednesday: schedule.blockWednesday,
          Thursday: schedule.blockThursday,
          Friday: schedule.blockFriday,
          Saturday: schedule.blockSaturday,
          Sunday: schedule.blockSunday,
        },
        day => !!day,
      ),
    );
  }
}
