import { IMingaSetting } from 'libs/domain';
import { IMingaSubscription } from 'libs/domain';
import { MingaPermission, MingaRoleType } from 'libs/domain';
import { MingaTypeValue } from 'libs/util';
import { mingaSettingTypes } from 'libs/util';

import { getRole, IUserRole } from '../../';
import { SettingsService } from '../../';
import {
  IMingaType,
  IMingaTypeDirectMessagingRoleSettings,
  MingaTypeAvailableRoles,
} from '../interface';

export abstract class MingaType implements IMingaType {
  abstract readonly availableRoles: MingaTypeAvailableRoles;
  abstract readonly mingaType: MingaTypeValue;
  abstract readonly defaultSettings: IMingaSetting[];
  abstract readonly canSelfManageAccountPlan: boolean;
  abstract readonly defaultVideoUploadRoleTypes: MingaRoleType[];
  abstract readonly directMessagingRoleSettings: IMingaTypeDirectMessagingRoleSettings[];

  abstract getDefaultRole(): IUserRole;
  abstract getCreatorRole(): IUserRole;

  getInviteRole() {
    return this.getDefaultRole();
  }

  getAvailableRoleTypes(): MingaRoleType[] {
    return <MingaRoleType[]>Object.keys(this.availableRoles);
  }

  getAvailableRoles(): IUserRole[] {
    return this.getAvailableRoleTypes().map(getRole);
  }

  getAvailableRolesWithPermission(perm: MingaPermission): IUserRole[] {
    return this.getAvailableRoles().filter(r => r.hasPermission(perm));
  }

  getBillableRoles(isPricedPerDefaultRole: boolean = true): IUserRole[] {
    return this.getAvailableRoles();
  }

  getAllowedToJoinRoles(): IUserRole[] {
    return [this.getDefaultRole()];
  }

  getMingaRolesFilteredForUserRole(role: IUserRole): IUserRole[] {
    const options = this.availableRoles[role.roleType];
    const filteredRoles: IUserRole[] = [];

    if (options) {
      const roleWeight = options.weight;

      for (const key in this.availableRoles) {
        const roleType = <MingaRoleType>key;

        const options = this.availableRoles[roleType];
        if (options && options.weight <= roleWeight) {
          filteredRoles.push(getRole(roleType));
        }
      }
    }

    return filteredRoles;
  }

  maxAvailableRole(role: IUserRole, target: IUserRole): IUserRole {
    const roleWeight = this._getRoleWeight(role);
    const targetWeight = this._getRoleWeight(target);

    if (roleWeight >= targetWeight) {
      return target;
    } else {
      return role;
    }
  }

  getDefaultMingaSettingsAsMap(): Map<string, any> {
    return new Map(
      this.defaultSettings.map(setting => [setting.name, setting.value]),
    );
  }

  private _getRoleWeight(role: IUserRole): number {
    return this.availableRoles[role.roleType]?.weight || 0;
  }

  getImmutableCommentRoles(): IUserRole[] {
    return [
      getRole(MingaRoleType.MANAGER),
      getRole(MingaRoleType.OWNER),
      getRole(MingaRoleType.DISTRICT_LEADER),
      getRole(MingaRoleType.DISTRICT_MANAGER),
    ];
  }

  getDisplaySubscriptions(): IMingaSubscription[] {
    return [];
  }

  getImmutableGroupCreateRoles(): IUserRole[] {
    return [
      getRole(MingaRoleType.MANAGER),
      getRole(MingaRoleType.OWNER),
      getRole(MingaRoleType.DISTRICT_LEADER),
      getRole(MingaRoleType.SCHOOL_MANAGER),
      getRole(MingaRoleType.DISTRICT_MANAGER),
    ];
  }
}
