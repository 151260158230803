import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

import * as day from 'dayjs';
import { UserListMinimal } from 'libs/domain';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AuthInfoService } from '@app/src/app/minimal/services/AuthInfo';
import { MingaSettingsService } from '@app/src/app/store/Minga/services';

import {
  OptionItem,
  SelectGroupCategory,
} from '@shared/components/form/components/form-grouped-select/form-grouped-select.types';
import {
  UserListCategory,
  UserListFilterMessages,
  UserListFilterService,
} from '@shared/components/user-list-filter';
import { getAppColor } from '@shared/constants';
import { FlexTimeActivityInstanceService } from '@shared/services/flex-time';
import { MediaService } from '@shared/services/media';

import {
  FORM_FIELDS,
  LOCKED_STATES,
  MyClassMessages,
} from '../../constants/tt-my-class.constants';
import { FormState } from '../../types/tt-my-class.types';

@Component({
  selector: 'mg-tt-filters',
  templateUrl: './tt-filters.component.html',
  styleUrls: ['./tt-filters.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TtFiltersComponent {
  @Input() form: UntypedFormGroup;
  @Input() searchingAllStudents = false;
  @Input() formState: FormState;
  @Input() totalNumberOfInClassStudents: number;
  @Input() selectedNumberOfStudents: number;

  @Output() userListChanged = new EventEmitter<
    OptionItem<number, UserListMinimal>[] | null
  >();
  @Output() selectRandom = new EventEmitter();
  @Output() toggleSelectAll = new EventEmitter();

  public MESSAGES = MyClassMessages;
  public FORM_FIELDS = FORM_FIELDS;
  public UserListCategory = UserListCategory;
  public LOCKED_STATES: FormState[] = LOCKED_STATES;
  private _DEFAULT_USER_LIST_OPTIONS = [
    {
      value: UserListCategory.MY_LISTS_CURRENT_TERM,
      label: UserListFilterMessages.MY_LISTS_CURRENT_TERM,
      items: [],
      fetch: () => this._userListFilterService.getMyListsCurrentTerm(),
      lazyLoad: true,
      openByDefault: true,
    },
    {
      value: UserListCategory.MY_LISTS,
      label: UserListFilterMessages.MY_LISTS,
      items: [],
      fetch: () => this._userListFilterService.getMyLists(),
      lazyLoad: true,
    },
    {
      value: UserListCategory.ALL_CURRENT_TERM,
      label: UserListFilterMessages.ALL_LISTS_CURRENT_TERM,
      items: [],
      fetch: () => this._userListFilterService.getAllListsCurrentTerm(),
      lazyLoad: true,
    },
    {
      value: UserListCategory.ALL,
      label: UserListFilterMessages.ALL_LISTS,
      items: [],
      fetch: () => this._userListFilterService.getAllLists(),
      usedForUserSearch: true,
      lazyLoad: true,
    },
  ];

  private _FLEX_USER_LIST_OPTIONS = [
    {
      value: UserListCategory.TODAYS_FLEX_ACTIVITIES_MINE,
      label: UserListFilterMessages.TODAYS_FLEX_ACTIVITIES_MINE,
      icon: 'mg-flextime-menu-o',
      iconColor: getAppColor('flextime'),
      items: [],
      fetch: () =>
        this._flexActivitiesService.getFlexActivitesListByUser({
          startDate: day().startOf('day'),
          endDate: day().endOf('day'),
          userHash: this._authInfoService.authPersonHash,
        }),
      lazyLoad: true,
    },
    {
      value: UserListCategory.TODAYS_FLEX_ACTIVITIES,
      label: UserListFilterMessages.TODAYS_FLEX_ACTIVITIES,
      icon: 'mg-flextime-menu-o',
      iconColor: getAppColor('flextime'),
      items: [],
      fetch: () =>
        this._flexActivitiesService.getFlexActivitesList({
          startDate: day().startOf('day'),
          endDate: day().endOf('day'),
        }),
      lazyLoad: true,
    },
  ];

  public userListOptions$: Observable<
    SelectGroupCategory<string, number, UserListMinimal>[]
  > = this._mingaSettings.isFlexTimeModuleEnabled().pipe(
    map(isFlexTimeEnabled => {
      if (isFlexTimeEnabled) {
        return [
          ...this._DEFAULT_USER_LIST_OPTIONS,
          ...this._FLEX_USER_LIST_OPTIONS,
        ];
      } else {
        return this._DEFAULT_USER_LIST_OPTIONS;
      }
    }),
  );

  constructor(
    public mediaService: MediaService,
    private _userListFilterService: UserListFilterService,
    private _flexActivitiesService: FlexTimeActivityInstanceService,
    private _authInfoService: AuthInfoService,
    private _mingaSettings: MingaSettingsService,
  ) {}

  public onUserListChange(
    val: OptionItem<number, UserListMinimal>[] | null,
  ): void {
    this.userListChanged.emit(val);
  }

  public onSelectRandom(): void {
    this.selectRandom.emit();
  }

  public onToggleSelectAll(): void {
    this.toggleSelectAll.emit();
  }
}
