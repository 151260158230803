import { Period } from 'libs/domain';
import { period_pb as proto } from 'libs/generated-grpc-ts';

export const toProto = (period: Period): proto.Period => {
  const msg = new proto.Period();
  if (period.id) msg.setId(period.id);
  msg.setShortCode(period.shortCode);
  msg.setPeriodName(period.periodName);
  msg.setStartTime(period.startTime);
  msg.setEndTime(period.endTime);

  return msg;
};

export const fromProto = (msg: proto.Period): Period => {
  const periodD: Period = {
    id: msg.getId(),
    shortCode: msg.getShortCode(),
    periodName: msg.getPeriodName(),
    startTime: msg.getStartTime(),
    endTime: msg.getEndTime(),
  };

  return periodD;
};
