import { Injectable, NgZone } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { grpc } from '@improbable-eng/grpc-web';

import {
  StreamControl,
  StreamControlResponse,
  StreamID,
} from '../common/stream_pb';
import {
  GalleryStreamResponse,
  MingaGalleryCreateRequest,
  MingaGalleryCreateResponse,
  MingaGalleryDeleteRequest,
  MingaGalleryDeleteResponse,
  MingaGalleryGetPhotoTotalRequest,
  MingaGalleryGetPhotoTotalResponse,
  MingaGalleryPhotoRequest,
  MingaGalleryPhotoResponse,
  MingaGallerySummaryRequest,
  MingaGallerySummaryResponse,
} from '../gateway/gallery_pb';

import {
  MingaGallery as __MingaGallery,
} from '../gateway/gallery_pb_service';

/**
  Service for viewing/manipulating a minga's gallery
  */
@Injectable({providedIn: 'root'})
export class MingaGallery {

  constructor(private _ngZone: NgZone) {
  }

  /**
    Get single gallery photo details
  */
  getPhoto(request: MingaGalleryPhotoRequest): Promise<MingaGalleryPhotoResponse>;
  /**
    Get single gallery photo details
  */
  getPhoto(request: MingaGalleryPhotoRequest, metadata: grpc.Metadata): Promise<MingaGalleryPhotoResponse>;
  /**
    Get single gallery photo details
  */
  getPhoto(request: MingaGalleryPhotoRequest, callback: (err: any|null, response: MingaGalleryPhotoResponse, metadata: grpc.Metadata) => void): void;
  /**
    Get single gallery photo details
  */
  getPhoto(request: MingaGalleryPhotoRequest, metadata: grpc.Metadata, callback: (err: any|null, response: MingaGalleryPhotoResponse, metadata: grpc.Metadata) => void): void;

  getPhoto(request: MingaGalleryPhotoRequest, arg1?: grpc.Metadata|((err: any|null, response: MingaGalleryPhotoResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: MingaGalleryPhotoResponse, metadata: grpc.Metadata) => void): Promise<MingaGalleryPhotoResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<MingaGalleryPhotoResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__MingaGallery.GetPhoto, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  /**
    Create a new item in the minga gallery
  */
  create(request: MingaGalleryCreateRequest): Promise<MingaGalleryCreateResponse>;
  /**
    Create a new item in the minga gallery
  */
  create(request: MingaGalleryCreateRequest, metadata: grpc.Metadata): Promise<MingaGalleryCreateResponse>;
  /**
    Create a new item in the minga gallery
  */
  create(request: MingaGalleryCreateRequest, callback: (err: any|null, response: MingaGalleryCreateResponse, metadata: grpc.Metadata) => void): void;
  /**
    Create a new item in the minga gallery
  */
  create(request: MingaGalleryCreateRequest, metadata: grpc.Metadata, callback: (err: any|null, response: MingaGalleryCreateResponse, metadata: grpc.Metadata) => void): void;

  create(request: MingaGalleryCreateRequest, arg1?: grpc.Metadata|((err: any|null, response: MingaGalleryCreateResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: MingaGalleryCreateResponse, metadata: grpc.Metadata) => void): Promise<MingaGalleryCreateResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<MingaGalleryCreateResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__MingaGallery.Create, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  /**
    Delete an existing item in the minga gallery
  */
  delete(request: MingaGalleryDeleteRequest): Promise<MingaGalleryDeleteResponse>;
  /**
    Delete an existing item in the minga gallery
  */
  delete(request: MingaGalleryDeleteRequest, metadata: grpc.Metadata): Promise<MingaGalleryDeleteResponse>;
  /**
    Delete an existing item in the minga gallery
  */
  delete(request: MingaGalleryDeleteRequest, callback: (err: any|null, response: MingaGalleryDeleteResponse, metadata: grpc.Metadata) => void): void;
  /**
    Delete an existing item in the minga gallery
  */
  delete(request: MingaGalleryDeleteRequest, metadata: grpc.Metadata, callback: (err: any|null, response: MingaGalleryDeleteResponse, metadata: grpc.Metadata) => void): void;

  delete(request: MingaGalleryDeleteRequest, arg1?: grpc.Metadata|((err: any|null, response: MingaGalleryDeleteResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: MingaGalleryDeleteResponse, metadata: grpc.Metadata) => void): Promise<MingaGalleryDeleteResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<MingaGalleryDeleteResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__MingaGallery.Delete, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  /**
    Returns a summary of the minga gallery
  */
  summary(request: MingaGallerySummaryRequest): Promise<MingaGallerySummaryResponse>;
  /**
    Returns a summary of the minga gallery
  */
  summary(request: MingaGallerySummaryRequest, metadata: grpc.Metadata): Promise<MingaGallerySummaryResponse>;
  /**
    Returns a summary of the minga gallery
  */
  summary(request: MingaGallerySummaryRequest, callback: (err: any|null, response: MingaGallerySummaryResponse, metadata: grpc.Metadata) => void): void;
  /**
    Returns a summary of the minga gallery
  */
  summary(request: MingaGallerySummaryRequest, metadata: grpc.Metadata, callback: (err: any|null, response: MingaGallerySummaryResponse, metadata: grpc.Metadata) => void): void;

  summary(request: MingaGallerySummaryRequest, arg1?: grpc.Metadata|((err: any|null, response: MingaGallerySummaryResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: MingaGallerySummaryResponse, metadata: grpc.Metadata) => void): Promise<MingaGallerySummaryResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<MingaGallerySummaryResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__MingaGallery.Summary, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  /**
    Get the photo total. This is a lite version of `Summary`
  */
  getPhotoTotal(request: MingaGalleryGetPhotoTotalRequest): Promise<MingaGalleryGetPhotoTotalResponse>;
  /**
    Get the photo total. This is a lite version of `Summary`
  */
  getPhotoTotal(request: MingaGalleryGetPhotoTotalRequest, metadata: grpc.Metadata): Promise<MingaGalleryGetPhotoTotalResponse>;
  /**
    Get the photo total. This is a lite version of `Summary`
  */
  getPhotoTotal(request: MingaGalleryGetPhotoTotalRequest, callback: (err: any|null, response: MingaGalleryGetPhotoTotalResponse, metadata: grpc.Metadata) => void): void;
  /**
    Get the photo total. This is a lite version of `Summary`
  */
  getPhotoTotal(request: MingaGalleryGetPhotoTotalRequest, metadata: grpc.Metadata, callback: (err: any|null, response: MingaGalleryGetPhotoTotalResponse, metadata: grpc.Metadata) => void): void;

  getPhotoTotal(request: MingaGalleryGetPhotoTotalRequest, arg1?: grpc.Metadata|((err: any|null, response: MingaGalleryGetPhotoTotalResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: MingaGalleryGetPhotoTotalResponse, metadata: grpc.Metadata) => void): Promise<MingaGalleryGetPhotoTotalResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<MingaGalleryGetPhotoTotalResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__MingaGallery.GetPhotoTotal, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  streamV2(request: StreamID): {cancel():void;close():void}&Observable<GalleryStreamResponse>;
  streamV2(request: StreamID, metadata: grpc.Metadata): {cancel():void;close():void}&Observable<GalleryStreamResponse>;
  streamV2(request: StreamID, onMessage: (message?: GalleryStreamResponse) => void,onError?: (err: any) => void,onEnd?: (code: number, msg: string|undefined, metadata: grpc.Metadata) => void): void;
  streamV2(request: StreamID, metadata: grpc.Metadata, onMessage: (message?: GalleryStreamResponse) => void,onError?: (err: any) => void,onEnd?: (code: number, msg: string|undefined, metadata: grpc.Metadata) => void): void;

  streamV2(request: StreamID, arg1?: grpc.Metadata|((message?: GalleryStreamResponse) => void), arg2?: ((message?: GalleryStreamResponse) => void)|((err: any) => void), arg3?: ((err: any) => void)|((code: number, msg: string|undefined, metadata: grpc.Metadata) => void), arg4?: (code: number, msg: string|undefined, metadata: grpc.Metadata) => void): {cancel():void;close():void}&Observable<GalleryStreamResponse>|void {
    let ret: any;
    let metadata: any;

    let onMessage: any;
    let onError: any;
    let onEnd: any;
    if(typeof arg1 === 'function') {
      onMessage = arg1;
      onError = arg2;
      onEnd = arg3;
    } else if(typeof arg2 === 'function') {
      metadata = arg1;
      onMessage = arg2;
      onError = arg3;
      onEnd = arg4;
    } else {
      metadata = arg1;
    }

    if(!onMessage) {
      let subject = new Subject<GalleryStreamResponse>();
      ret = subject.asObservable();

      onMessage = (response: any) => {
        subject.next(response);
      };

      onError = (err: any) => {
        subject.error(err);
      };

      onEnd = (code: any, msg: any, metadata: any) => {
        subject.complete();
      };

    } else {
      if(!onError) {
        onError = (err: any) => console.error(err);
      }

      if(!onEnd) {
        onEnd = (code: any, msg: any, metadata: any) => {};
      }
    }

    let req = grpc.invoke(__MingaGallery.StreamV2, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onMessage: response => this._ngZone.run(() => {
        onMessage(response);
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code == grpc.Code.OK) {
          onEnd(code, msg, metadata);
        } else {
          let err = new Error(code + ' ' + (msg||''));
          (<any>err).code = code;
          onError(err);
        }
      })
    });

    (<any>ret).close = () => {
      console.warn('[Angular Grpc] .close() is deprecated use .cancel() instead');
      return req.close();
    };
    (<any>ret).cancel = () => req.close();
    return ret;
  }

  streamV2Control(request: StreamControl): Promise<StreamControlResponse>;
  streamV2Control(request: StreamControl, metadata: grpc.Metadata): Promise<StreamControlResponse>;
  streamV2Control(request: StreamControl, callback: (err: any|null, response: StreamControlResponse, metadata: grpc.Metadata) => void): void;
  streamV2Control(request: StreamControl, metadata: grpc.Metadata, callback: (err: any|null, response: StreamControlResponse, metadata: grpc.Metadata) => void): void;

  streamV2Control(request: StreamControl, arg1?: grpc.Metadata|((err: any|null, response: StreamControlResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: StreamControlResponse, metadata: grpc.Metadata) => void): Promise<StreamControlResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<StreamControlResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__MingaGallery.StreamV2Control, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  /**
    Deprecated as of 3.8.0
  */
  stream(request: StreamID): {cancel():void;close():void}&Observable<GalleryStreamResponse>;
  /**
    Deprecated as of 3.8.0
  */
  stream(request: StreamID, metadata: grpc.Metadata): {cancel():void;close():void}&Observable<GalleryStreamResponse>;
  /**
    Deprecated as of 3.8.0
  */
  stream(request: StreamID, onMessage: (message?: GalleryStreamResponse) => void,onError?: (err: any) => void,onEnd?: (code: number, msg: string|undefined, metadata: grpc.Metadata) => void): void;
  /**
    Deprecated as of 3.8.0
  */
  stream(request: StreamID, metadata: grpc.Metadata, onMessage: (message?: GalleryStreamResponse) => void,onError?: (err: any) => void,onEnd?: (code: number, msg: string|undefined, metadata: grpc.Metadata) => void): void;

  stream(request: StreamID, arg1?: grpc.Metadata|((message?: GalleryStreamResponse) => void), arg2?: ((message?: GalleryStreamResponse) => void)|((err: any) => void), arg3?: ((err: any) => void)|((code: number, msg: string|undefined, metadata: grpc.Metadata) => void), arg4?: (code: number, msg: string|undefined, metadata: grpc.Metadata) => void): {cancel():void;close():void}&Observable<GalleryStreamResponse>|void {
    let ret: any;
    let metadata: any;

    let onMessage: any;
    let onError: any;
    let onEnd: any;
    if(typeof arg1 === 'function') {
      onMessage = arg1;
      onError = arg2;
      onEnd = arg3;
    } else if(typeof arg2 === 'function') {
      metadata = arg1;
      onMessage = arg2;
      onError = arg3;
      onEnd = arg4;
    } else {
      metadata = arg1;
    }

    if(!onMessage) {
      let subject = new Subject<GalleryStreamResponse>();
      ret = subject.asObservable();

      onMessage = (response: any) => {
        subject.next(response);
      };

      onError = (err: any) => {
        subject.error(err);
      };

      onEnd = (code: any, msg: any, metadata: any) => {
        subject.complete();
      };

    } else {
      if(!onError) {
        onError = (err: any) => console.error(err);
      }

      if(!onEnd) {
        onEnd = (code: any, msg: any, metadata: any) => {};
      }
    }

    let req = grpc.invoke(__MingaGallery.Stream, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onMessage: response => this._ngZone.run(() => {
        onMessage(response);
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code == grpc.Code.OK) {
          onEnd(code, msg, metadata);
        } else {
          let err = new Error(code + ' ' + (msg||''));
          (<any>err).code = code;
          onError(err);
        }
      })
    });

    (<any>ret).close = () => {
      console.warn('[Angular Grpc] .close() is deprecated use .cancel() instead');
      return req.close();
    };
    (<any>ret).cancel = () => req.close();
    return ret;
  }

  /**
    Deprecated as of 3.8.0
  */
  streamControl(request: StreamControl): Promise<StreamControlResponse>;
  /**
    Deprecated as of 3.8.0
  */
  streamControl(request: StreamControl, metadata: grpc.Metadata): Promise<StreamControlResponse>;
  /**
    Deprecated as of 3.8.0
  */
  streamControl(request: StreamControl, callback: (err: any|null, response: StreamControlResponse, metadata: grpc.Metadata) => void): void;
  /**
    Deprecated as of 3.8.0
  */
  streamControl(request: StreamControl, metadata: grpc.Metadata, callback: (err: any|null, response: StreamControlResponse, metadata: grpc.Metadata) => void): void;

  streamControl(request: StreamControl, arg1?: grpc.Metadata|((err: any|null, response: StreamControlResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: StreamControlResponse, metadata: grpc.Metadata) => void): Promise<StreamControlResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<StreamControlResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__MingaGallery.StreamControl, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

}

