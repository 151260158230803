import { CategoryType } from 'libs/domain';
import { Category } from 'libs/domain';
import { category_pb } from 'libs/generated-grpc-ts';
import { $enum } from 'ts-enum-util';

export const toProto = (category: Category): category_pb.Category => {
  const message = new category_pb.Category();
  message.setId(category.id);
  message.setName(category.name);
  message.setType(category.type);
  message.setAutoParent(category.autoParent);
  return message;
};

export const fromProto = (message: category_pb.Category): Category => {
  return {
    id: message.getId(),
    name: message.getName(),
    type: $enum(CategoryType).asValueOrThrow(message.getType()),
    autoParent: message.getAutoParent(),
  };
};
