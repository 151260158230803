import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatRippleModule } from '@angular/material/core';
import { MatExpansionModule } from '@angular/material/expansion';

import { MgImageModule } from '@app/src/app/components/MgImage';
import { MgIconModule } from '@app/src/app/icon';
import { MgPipesModule } from '@app/src/app/pipes';

import { TextModule } from '@shared/components/text';

import { MobileCardComponent } from './mobile-card.component';

@NgModule({
  declarations: [MobileCardComponent],
  exports: [MobileCardComponent],
  imports: [
    CommonModule,
    MatExpansionModule,
    TextModule,
    MgIconModule,
    MatRippleModule,
    MgImageModule,
    MgPipesModule,
  ],
})
export class MobileCardModule {}
