import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatDialogModule } from '@angular/material/dialog';

import { MgButtonModule } from '@app/src/app/button';

import {
  DialogComponent,
  DialogSlot_Action,
  DialogSlot_Content,
  DialogSlot_Title,
} from './Dialog.component';

@NgModule({
  imports: [
    // Minga dependencies
    MgButtonModule,

    // External dependencies
    CommonModule,
    FlexLayoutModule,
    MatDialogModule,
  ],
  declarations: [
    DialogSlot_Content,
    DialogSlot_Title,
    DialogSlot_Action,
    DialogComponent,
  ],
  exports: [
    DialogSlot_Content,
    DialogSlot_Title,
    DialogSlot_Action,
    DialogComponent,
  ],
})
export class DialogModule {}
