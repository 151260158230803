import { FlexTimeActivityInstance } from 'libs/domain';
import { flex_time_pb } from 'libs/generated-grpc-ts';

import { FlexTimeActivityMapper, FlexTimePeriodMapper } from '.';

export const toProto = (
  activityInstance: FlexTimeActivityInstance,
): flex_time_pb.FlexTimeActivityInstance => {
  const message = new flex_time_pb.FlexTimeActivityInstance();
  if (activityInstance.mingaId) message.setMingaId(activityInstance.mingaId);
  if (activityInstance.id) message.setId(activityInstance.id);
  if (activityInstance.checkinReasonId)
    message.setCheckinReasonId(activityInstance.checkinReasonId);
  if (activityInstance.flexTimeActivityId)
    message.setActivityId(activityInstance.flexTimeActivityId);
  if (activityInstance.flexTimePeriodId)
    message.setPeriodId(activityInstance.flexTimePeriodId);
  if (activityInstance.flexTimeActivity) {
    message.setActivity(
      FlexTimeActivityMapper.toProto(activityInstance.flexTimeActivity),
    );
  }
  if (activityInstance.flexTimePeriod) {
    message.setPeriod(
      FlexTimePeriodMapper.toProto(activityInstance.flexTimePeriod),
    );
  }
  if (activityInstance.createdBy)
    message.setCreatedBy(activityInstance.createdBy);
  if (activityInstance.registered)
    message.setRegistered(activityInstance.registered);
  if (activityInstance.spaces) message.setSpaces(activityInstance.spaces);
  if (activityInstance.absentees)
    message.setAbsentees(activityInstance.absentees);
  if (activityInstance.checkinCount)
    message.setCheckinCount(activityInstance.checkinCount);
  return message;
};

export const fromProto = (
  message: flex_time_pb.FlexTimeActivityInstance,
): FlexTimeActivityInstance => {
  return {
    mingaId: message.getMingaId(),
    id: message.getId() ?? undefined,
    checkinReasonId: message.getCheckinReasonId() ?? undefined,
    flexTimeActivityId: message.getActivityId() ?? undefined,
    flexTimePeriodId: message.getPeriodId() ?? undefined,
    flexTimeActivity: message.getActivity()
      ? FlexTimeActivityMapper.fromProto(message.getActivity())
      : undefined,
    flexTimePeriod: message.getPeriod()
      ? FlexTimePeriodMapper.fromProto(message.getPeriod())
      : undefined,
    createdBy: message.getCreatedBy(),
    spaces: message.getSpaces(),
    registered: message.getRegistered(),
    absentees: message.getAbsentees(),
    checkinCount: message.getCheckinCount(),
  };
};
