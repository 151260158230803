import { Injectable, NgZone } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { grpc } from '@improbable-eng/grpc-web';

import {
  StreamControl,
  StreamControlResponse,
  StreamID,
} from '../../common/stream_pb';
import {
  ApproveOrDeclineResponseRequest,
  ApproveOrDeclineResponseResponse,
  ChallengeResponseInfo,
  StreamChallengeResponseCardResponse,
  StreamChallengeResponsesAdminResponse,
} from '../../content/challenge/challenge_response_pb';
import {
  ContentInfoQuery,
  ContentSaveResult,
} from '../../content/common_pb';

import {
  ChallengeResponse as __ChallengeResponse,
} from '../../content/challenge/challenge_response_pb_service';

@Injectable({providedIn: 'root'})
export class ChallengeResponse {

  constructor(private _ngZone: NgZone) {
  }

  new(request: ChallengeResponseInfo): Promise<ContentSaveResult>;
  new(request: ChallengeResponseInfo, metadata: grpc.Metadata): Promise<ContentSaveResult>;
  new(request: ChallengeResponseInfo, callback: (err: any|null, response: ContentSaveResult, metadata: grpc.Metadata) => void): void;
  new(request: ChallengeResponseInfo, metadata: grpc.Metadata, callback: (err: any|null, response: ContentSaveResult, metadata: grpc.Metadata) => void): void;

  new(request: ChallengeResponseInfo, arg1?: grpc.Metadata|((err: any|null, response: ContentSaveResult, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: ContentSaveResult, metadata: grpc.Metadata) => void): Promise<ContentSaveResult>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<ContentSaveResult>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__ChallengeResponse.New, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  getInfo(request: ContentInfoQuery): Promise<ChallengeResponseInfo>;
  getInfo(request: ContentInfoQuery, metadata: grpc.Metadata): Promise<ChallengeResponseInfo>;
  getInfo(request: ContentInfoQuery, callback: (err: any|null, response: ChallengeResponseInfo, metadata: grpc.Metadata) => void): void;
  getInfo(request: ContentInfoQuery, metadata: grpc.Metadata, callback: (err: any|null, response: ChallengeResponseInfo, metadata: grpc.Metadata) => void): void;

  getInfo(request: ContentInfoQuery, arg1?: grpc.Metadata|((err: any|null, response: ChallengeResponseInfo, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: ChallengeResponseInfo, metadata: grpc.Metadata) => void): Promise<ChallengeResponseInfo>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<ChallengeResponseInfo>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__ChallengeResponse.GetInfo, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  streamResponsesAdmin(request: StreamID): {cancel():void;close():void}&Observable<StreamChallengeResponsesAdminResponse>;
  streamResponsesAdmin(request: StreamID, metadata: grpc.Metadata): {cancel():void;close():void}&Observable<StreamChallengeResponsesAdminResponse>;
  streamResponsesAdmin(request: StreamID, onMessage: (message?: StreamChallengeResponsesAdminResponse) => void,onError?: (err: any) => void,onEnd?: (code: number, msg: string|undefined, metadata: grpc.Metadata) => void): void;
  streamResponsesAdmin(request: StreamID, metadata: grpc.Metadata, onMessage: (message?: StreamChallengeResponsesAdminResponse) => void,onError?: (err: any) => void,onEnd?: (code: number, msg: string|undefined, metadata: grpc.Metadata) => void): void;

  streamResponsesAdmin(request: StreamID, arg1?: grpc.Metadata|((message?: StreamChallengeResponsesAdminResponse) => void), arg2?: ((message?: StreamChallengeResponsesAdminResponse) => void)|((err: any) => void), arg3?: ((err: any) => void)|((code: number, msg: string|undefined, metadata: grpc.Metadata) => void), arg4?: (code: number, msg: string|undefined, metadata: grpc.Metadata) => void): {cancel():void;close():void}&Observable<StreamChallengeResponsesAdminResponse>|void {
    let ret: any;
    let metadata: any;

    let onMessage: any;
    let onError: any;
    let onEnd: any;
    if(typeof arg1 === 'function') {
      onMessage = arg1;
      onError = arg2;
      onEnd = arg3;
    } else if(typeof arg2 === 'function') {
      metadata = arg1;
      onMessage = arg2;
      onError = arg3;
      onEnd = arg4;
    } else {
      metadata = arg1;
    }

    if(!onMessage) {
      let subject = new Subject<StreamChallengeResponsesAdminResponse>();
      ret = subject.asObservable();

      onMessage = (response: any) => {
        subject.next(response);
      };

      onError = (err: any) => {
        subject.error(err);
      };

      onEnd = (code: any, msg: any, metadata: any) => {
        subject.complete();
      };

    } else {
      if(!onError) {
        onError = (err: any) => console.error(err);
      }

      if(!onEnd) {
        onEnd = (code: any, msg: any, metadata: any) => {};
      }
    }

    let req = grpc.invoke(__ChallengeResponse.StreamResponsesAdmin, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onMessage: response => this._ngZone.run(() => {
        onMessage(response);
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code == grpc.Code.OK) {
          onEnd(code, msg, metadata);
        } else {
          let err = new Error(code + ' ' + (msg||''));
          (<any>err).code = code;
          onError(err);
        }
      })
    });

    (<any>ret).close = () => {
      console.warn('[Angular Grpc] .close() is deprecated use .cancel() instead');
      return req.close();
    };
    (<any>ret).cancel = () => req.close();
    return ret;
  }

  streamResponsesAdminControl(request: StreamControl): Promise<StreamControlResponse>;
  streamResponsesAdminControl(request: StreamControl, metadata: grpc.Metadata): Promise<StreamControlResponse>;
  streamResponsesAdminControl(request: StreamControl, callback: (err: any|null, response: StreamControlResponse, metadata: grpc.Metadata) => void): void;
  streamResponsesAdminControl(request: StreamControl, metadata: grpc.Metadata, callback: (err: any|null, response: StreamControlResponse, metadata: grpc.Metadata) => void): void;

  streamResponsesAdminControl(request: StreamControl, arg1?: grpc.Metadata|((err: any|null, response: StreamControlResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: StreamControlResponse, metadata: grpc.Metadata) => void): Promise<StreamControlResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<StreamControlResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__ChallengeResponse.StreamResponsesAdminControl, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  streamResponses(request: StreamID): {cancel():void;close():void}&Observable<StreamChallengeResponseCardResponse>;
  streamResponses(request: StreamID, metadata: grpc.Metadata): {cancel():void;close():void}&Observable<StreamChallengeResponseCardResponse>;
  streamResponses(request: StreamID, onMessage: (message?: StreamChallengeResponseCardResponse) => void,onError?: (err: any) => void,onEnd?: (code: number, msg: string|undefined, metadata: grpc.Metadata) => void): void;
  streamResponses(request: StreamID, metadata: grpc.Metadata, onMessage: (message?: StreamChallengeResponseCardResponse) => void,onError?: (err: any) => void,onEnd?: (code: number, msg: string|undefined, metadata: grpc.Metadata) => void): void;

  streamResponses(request: StreamID, arg1?: grpc.Metadata|((message?: StreamChallengeResponseCardResponse) => void), arg2?: ((message?: StreamChallengeResponseCardResponse) => void)|((err: any) => void), arg3?: ((err: any) => void)|((code: number, msg: string|undefined, metadata: grpc.Metadata) => void), arg4?: (code: number, msg: string|undefined, metadata: grpc.Metadata) => void): {cancel():void;close():void}&Observable<StreamChallengeResponseCardResponse>|void {
    let ret: any;
    let metadata: any;

    let onMessage: any;
    let onError: any;
    let onEnd: any;
    if(typeof arg1 === 'function') {
      onMessage = arg1;
      onError = arg2;
      onEnd = arg3;
    } else if(typeof arg2 === 'function') {
      metadata = arg1;
      onMessage = arg2;
      onError = arg3;
      onEnd = arg4;
    } else {
      metadata = arg1;
    }

    if(!onMessage) {
      let subject = new Subject<StreamChallengeResponseCardResponse>();
      ret = subject.asObservable();

      onMessage = (response: any) => {
        subject.next(response);
      };

      onError = (err: any) => {
        subject.error(err);
      };

      onEnd = (code: any, msg: any, metadata: any) => {
        subject.complete();
      };

    } else {
      if(!onError) {
        onError = (err: any) => console.error(err);
      }

      if(!onEnd) {
        onEnd = (code: any, msg: any, metadata: any) => {};
      }
    }

    let req = grpc.invoke(__ChallengeResponse.StreamResponses, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onMessage: response => this._ngZone.run(() => {
        onMessage(response);
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code == grpc.Code.OK) {
          onEnd(code, msg, metadata);
        } else {
          let err = new Error(code + ' ' + (msg||''));
          (<any>err).code = code;
          onError(err);
        }
      })
    });

    (<any>ret).close = () => {
      console.warn('[Angular Grpc] .close() is deprecated use .cancel() instead');
      return req.close();
    };
    (<any>ret).cancel = () => req.close();
    return ret;
  }

  streamResponsesControl(request: StreamControl): Promise<StreamControlResponse>;
  streamResponsesControl(request: StreamControl, metadata: grpc.Metadata): Promise<StreamControlResponse>;
  streamResponsesControl(request: StreamControl, callback: (err: any|null, response: StreamControlResponse, metadata: grpc.Metadata) => void): void;
  streamResponsesControl(request: StreamControl, metadata: grpc.Metadata, callback: (err: any|null, response: StreamControlResponse, metadata: grpc.Metadata) => void): void;

  streamResponsesControl(request: StreamControl, arg1?: grpc.Metadata|((err: any|null, response: StreamControlResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: StreamControlResponse, metadata: grpc.Metadata) => void): Promise<StreamControlResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<StreamControlResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__ChallengeResponse.StreamResponsesControl, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  approveOrDeclineResponse(request: ApproveOrDeclineResponseRequest): Promise<ApproveOrDeclineResponseResponse>;
  approveOrDeclineResponse(request: ApproveOrDeclineResponseRequest, metadata: grpc.Metadata): Promise<ApproveOrDeclineResponseResponse>;
  approveOrDeclineResponse(request: ApproveOrDeclineResponseRequest, callback: (err: any|null, response: ApproveOrDeclineResponseResponse, metadata: grpc.Metadata) => void): void;
  approveOrDeclineResponse(request: ApproveOrDeclineResponseRequest, metadata: grpc.Metadata, callback: (err: any|null, response: ApproveOrDeclineResponseResponse, metadata: grpc.Metadata) => void): void;

  approveOrDeclineResponse(request: ApproveOrDeclineResponseRequest, arg1?: grpc.Metadata|((err: any|null, response: ApproveOrDeclineResponseResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: ApproveOrDeclineResponseResponse, metadata: grpc.Metadata) => void): Promise<ApproveOrDeclineResponseResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<ApproveOrDeclineResponseResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__ChallengeResponse.ApproveOrDeclineResponse, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

}

