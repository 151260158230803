import { Injectable } from '@angular/core';
import { Sort } from '@angular/material/sort';

import { IPointsHistoryItem, IPointsRedeemedResult } from 'libs/domain';
import { PmReportsFilter, PmReportsFilters } from 'libs/domain';
import { ReportTypes } from 'libs/domain';
import {
  points_ng_grpc_pb,
  stats_report_ng_grpc_pb,
  points_pb as point_pb,
  stats_report_pb as report_pb,
} from 'libs/generated-grpc-web';
import { PointHistoryItemMapper } from 'libs/shared-grpc';
import {
  IPointsRedeemedMapper,
  IPointSummaryItemMapper,
  ITeamPointsSummaryItemMapper,
  RewardsRedeemedMapper,
} from 'libs/shared-grpc';
import { PointFilterMapper } from 'libs/shared-grpc';

import { ReportsService } from '@app/src/app/components/manager-report/services/report-service.service';
import { RootService } from '@app/src/app/minimal/services/RootService';

import {
  PM_REPORTS_FILTERS_INITIAL_STATE,
  PM_REPORTS_NON_ARRAY_TYPES,
  PM_REPORTS_PERSON_EMITTER_ACCESSORS,
} from '../constants';

@Injectable()
export class PmReportsService extends ReportsService<PmReportsFilters> {
  /** Service Constructor */
  constructor(
    private _reportService: stats_report_ng_grpc_pb.StatsReportManager,
    _rootService: RootService,
    private _pmManager: points_ng_grpc_pb.PointsManager,
  ) {
    super(
      PM_REPORTS_FILTERS_INITIAL_STATE,
      PM_REPORTS_NON_ARRAY_TYPES,
      PM_REPORTS_PERSON_EMITTER_ACCESSORS,
      _reportService,
      _rootService,
    );
  }

  protected _handleSetFilter(filter: PmReportsFilter, value: any): void {
    this.filter[filter] = value;
  }

  public async fetchPointSummary(offset = 0, limit?: number, sort?: Sort) {
    const { infos, pageToken } = await this._getReport(
      ReportTypes.POINTS_SUMMARY,
      offset,
      limit,
      sort,
    );
    return {
      items: infos.map(info =>
        IPointSummaryItemMapper.fromProto(info.getPointSummary()),
      ),
      pageToken,
    };
  }

  public async fetchTeamPointSummary(offset = 0, limit?: number, sort?: Sort) {
    const { infos, pageToken } = await this._getReport(
      ReportTypes.POINTS_TEAM_SUMMARY,
      offset,
      limit,
      sort,
    );
    return {
      items: infos.map(info =>
        ITeamPointsSummaryItemMapper.fromProto(info.getPointTeamSummary()),
      ),
      pageToken,
    };
  }

  public async fetchPointHistories(offset = 0, limit?: number, sort?: Sort) {
    const { infos, pageToken } = await this._getReport(
      ReportTypes.POINTS_HISTORY,
      offset,
      limit,
      sort,
    );
    return {
      items: infos.map(info =>
        PointHistoryItemMapper.fromProto(info.getPointHistory()),
      ),
      pageToken,
    };
  }

  public async fetchRewardsRedeemed(offset = 0, limit = 10, sort?: Sort) {
    const { infos, pageToken } = await this._getReport(
      ReportTypes.POINTS_REWARDS_REDEEMED,
      offset,
      limit,
      sort,
    );
    return {
      items: infos.map(info =>
        RewardsRedeemedMapper.fromProto(info.getRewardRedeemed()),
      ),
      pageToken,
    };
  }

  public async fetchPointsRedeemed(offset = 0, limit = 10, sort?: Sort) {
    const { infos, pageToken } = await this._getReport(
      ReportTypes.POINTS_REDEEMED,
      offset,
      limit,
      sort,
    );
    return {
      items: infos.map(info =>
        IPointsRedeemedMapper.fromProto(info.getPointsRedeemed()),
      ),
      pageToken,
    };
  }

  protected _mapFiltersToFilterMessage(
    reportType: ReportTypes,
    offset?: number,
    limit?: number,
  ): report_pb.GetOrExportReportRequest {
    const req = new report_pb.GetOrExportReportRequest();
    const filter = {
      ...this.filter,
      issuedTo: this.filter.issuedTo?.map((p: any) => p.hash || p.personHash),
    };
    const filterProto = PointFilterMapper.toProto(filter, limit, offset);
    req.setReportType(reportType);
    req.setPointsFilters(filterProto);
    return req;
  }

  protected _convertTableDataForFilter(value: any) {
    if (value.issuedTo) {
      return value.issuedTo;
    } else if (value.teamId) {
      return value.teamId;
    }
    return value;
  }

  public async archivePointAction(
    row: IPointsHistoryItem | IPointsHistoryItem[],
  ) {
    let ids: number[] = [];
    if (Array.isArray(row)) {
      ids = row.map(r => r.id);
    } else {
      ids = [row.id];
    }
    const req = new point_pb.ArchivePointActionRequest();
    req.setIdsList(ids);
    await this._pmManager.archivePointAction(req);
  }

  public async archivePointRedemption(
    row: IPointsRedeemedResult | IPointsRedeemedResult[],
  ) {
    let ids: number[] = [];
    if (Array.isArray(row)) {
      ids = row.map(r => r.id);
    } else {
      ids = [row.id];
    }

    const req = new point_pb.ArchivePointRedemptionRequest();
    req.setIdsList(ids);
    await this._pmManager.archivePointRedemption(req);
  }
}
