export enum MingaPermission {
  SUPERADMIN = 1,
  CONTENT_DELETE_OTHERS,
  ACCOUNT_DISPLAYNAME_CHANGE,
  ACCOUNT_PROFILE_CHANGE,
  APP_BROADCAST_SEND,
  ASSET_UPLOAD,
  CONTENT_ANNOUNCEMENT_CREATE,
  CONTENT_ANY_GET_DELETED,
  CONTENT_COMMENT_CREATE,
  CONTENT_COMMENTS_VIEW,
  CONTENT_EVENT_CREATE,
  CONTENT_FULL_GRAPHIC_CREATE,
  CONTENT_GROUP_CREATE,
  CONTENT_GROUP_VIEWALL,
  CONTENT_GROUP_ENABLED,
  CONTENT_HTML_INCLUDE,
  CONTENT_LIKES_LIKE,
  CONTENT_LIKES_VIEW,
  CONTENT_MODERATION_OVERRIDE,
  CONTENT_POLL_CREATE,
  CONTENT_POLL_ANSWER,
  CONTENT_POST_CREATE,
  CONTENT_POST_SET_TITLE,
  CONTENT_PUBLISH_DATES_SET,
  CONTENT_REPORT_CREATE,
  CONTENT_REPORTS_MANAGE,
  CONTENT_VIDEO_CREATE,
  MINGA_ACCOUNT_MANAGE,
  GROUP_BROADCAST_SEND,
  GROUP_CONTENT_ANNOUNCEMENT_CREATE,
  GROUP_CONTENT_FULL_GRAPHIC_CREATE,
  GROUP_CONTENT_EVENTS_CREATE,
  GROUP_CONTENT_POLL_CREATE,
  GROUP_CONTENT_POST_CREATE,
  GROUP_CONTENT_VIDEO_CREATE,
  MINGA_BLOCKORDER_UPLOAD,
  MINGA_CONTENT_PIN,
  MINGA_CONTENT_MAIN_FEED,
  MINGA_CONTENT_UPDATE_OTHERS,
  MINGA_FILE_CREATE,
  MINGA_PROFILE_EDIT,
  MINGA_PEOPLE_MANAGE,
  MINGA_POLLS_MANAGE,
  MINGA_PROGRAMS_MANAGE,
  PEOPLE_DELETE,
  CONTENT_GROUP_MANAGE,
  GALLERY_ADD_PHOTO,
  GALLERY_DELETE_OTHERS_PHOTO,
  VIDEO_UPLOAD,
  CONTENT_CREATED_MINGA_DESIGNER_CONTENT,

  /**
   * Can add/remove a parent group to a group only from the list of parent
   * groups you're associated with.
   */
  GROUP_ALLOW_PARENT_GROUP,

  /**
   * Can add/remove a parent group to a group
   */
  GROUP_ALLOW_ANY_PARENT_GROUP,

  /**
   * Denote the user as a parent which may have different functionality
   */
  IS_PARENT,

  /**
   * Allowed to email content to other users
   */
  EMAIL_CONTENT,

  /**
   * Allowed to email content to the group you are an owner of
   */
  EMAIL_CONTENT_AS_GROUP_OWNER,
  /**
   * Allowed to change who can comment on a post.
   */
  CONTENT_MANAGE_COMMENT_SETTINGS,

  /**
   * Get sent notifications if content is reported.
   */
  MODERATION_GET_REPORT_NOTIFICATIONS,

  /**
   * Can always manage events
   */
  EVENTS_MANAGE,
  CONTENT_CHALLENGE_CREATE,
  GROUP_CONTENT_CHALLENGE_CREATE,
  CONTENT_CHALLENGE_MANAGE,
  CHALLENGE_COMPLETE,

  /**
   * Can arbitrarily add/remove points from people and teams
   */
  POINTS_MANAGE,

  /**
   * Can create SMS message content
   */
  CONTENT_SMS_MESSAGE_CREATE,
  GROUP_CONTENT_SMS_MESSAGE_CREATE,
  /**
   * Can allocate points if the setting is enabled.
   */
  POINTS_ALLOCATE_ALLOWED,
  /**
   * If have this permission, can access all student id manager, except mass
   * uploading images and changing student id settings.
   */
  ADMINISTER_STUDENT_ID,
  /**
   * If have this permission, user can change student id settings.
   */
  ADMINISTER_STUDENT_ID_SETTINGS,
  /**
   * Can create, edit, remove hall pass types
   */
  HALL_PASS_TYPE_MANAGE,
  /**
   * Can view and end hall passes
   */
  HALL_PASS_MANAGE,
  /**
   * Can only create a hall pass
   */
  HALL_PASS_CREATE,
  /**
   * Can use teacher tools
   */
  TEACHER_TOOLS,
  /**
   * Can view the student ID Dashboard.
   */
  VIEW_STUDENT_ID_DASHBOARD,
  /**
   * Can view and manage PBIS behavior types
   */
  PBIS_TYPE_MANAGE,
  /**
   * Can award PBIS behaviors
   */
  PBIS_CREATE,
  /**
   * Can view and manage PBIS behavior types
   */
  PBIS_VIEW_REPORTS,
  /**
   * Can manually issue consequences
   */
  CONSEQUENCE_CREATE,
  /**
   * Can manage checkin types
   */
  CHECKIN_TYPE_MANAGE,
  /**
   * Can checkin and checkout
   */
  CHECKIN_MANAGE,
  /**
   * Can view check in reports
   */
  CHECKIN_VIEW_REPORTS,
  /**
   * Can view check in reports
   */
  SELF_CHECKIN,
  /**
   * Can view a limited set of hall pass reports
   */
  HALL_PASS_VIEW_LIMITED_REPORTS,
  /**
   * Can view full set of hall pass reports?
   */
  HALL_PASS_VIEW_REPORTS,
  /**
   * Can view the Minga Manager Dashboard.
   */
  VIEW_MINGA_DASHBOARD,
  /**
   * Can view points reports
   */
  POINTS_VIEW_REPORTS,
  /**
   * Can manage rewards and redeem points
   */
  MANAGE_REWARDS,
  /**
   * Can use student tools
   */
  STUDENT_TOOLS,
  /** Flex time related permissions */
  FLEX_TIME_MANAGE,
  FLEX_TIME_VIEW_REPORTS,
  FLEXTIME_MANAGE_OWN_ACTIVITY,
  FLEX_TIME_REGISTER_FOR_ACTIVITY,
  /**
   * User List create and manager permissions
   */
  USER_LIST_VIEW,
  USER_LIST_MANAGE,
  USER_LIST_CREATE,
  USER_LIST_ADMIN,
  /**
   * Scheduled reports permissions
   */
  SCHEDULED_REPORTS_MANAGE,
  SCHEDULED_REPORTS_DOWNLOAD_PAST_REPORT, // user needs this permission but also added to specific scheduled report to download
  /**
   * Can always use direct messaging. Only applies uni-directionally
   */
  DM_CAN_MESSAGE,
  /**
   * Can manage various managers and settings
   */
  MINGA_MANAGE,
  FLEX_TIME_VIEW_MY_ACTIVITY,
  /**
   * Can manage date range presets
   */
  DATE_RANGE_PRESETS_MANAGE,
  KIOSK_MANAGE,
  DATE_RANGE_PRESETS_VIEW,
  ID_CHANGE_OWN_PHOTO,

  ADMIN_TOOLS,
  STUDENT_SCHEDULE_VIEW,
  BELL_SCHEDULE_MANAGE,
  /**
   * View a users profile
   */
  VIEW_PROFILE,
}

export type MingaPermissionName = keyof typeof MingaPermission;

export enum MingaRoleType {
  SUPERADMIN = 'SUPERADMIN',
  OWNER = 'OWNER',
  TEACHER = 'TEACHER',
  STAFF = 'STAFF',
  STUDENT_LEADER = 'STUDENT_LEADER',
  STUDENT = 'STUDENT',
  READ_ONLY = 'READ_ONLY',
  MANAGER = 'MANAGER',
  ATTENDEE = 'ATTENDEE',
  PARENT = 'PARENT',
  SCHOOL_MANAGER = 'SCHOOL_MANAGER', // No longer a valid role.
  DISTRICT_LEADER = 'DISTRICT_LEADER', // No longer a valid role.
  DISTRICT_MANAGER = 'DISTRICT_MANAGER',
  KIOSK = 'KIOSK',
}

const legacyMingaRoleIdMap = {
  [MingaRoleType.SUPERADMIN]: 1,
  [MingaRoleType.OWNER]: 3,
  [MingaRoleType.TEACHER]: 4,
  [MingaRoleType.STUDENT_LEADER]: 5,
  [MingaRoleType.STUDENT]: 6,
  [MingaRoleType.PARENT]: 7,
  [MingaRoleType.READ_ONLY]: 8,

  // New roles need ids still to support older clients
  [MingaRoleType.ATTENDEE]: 9,
  [MingaRoleType.MANAGER]: 11,
  [MingaRoleType.STAFF]: 12,
  [MingaRoleType.SCHOOL_MANAGER]: 13,
  [MingaRoleType.DISTRICT_LEADER]: 14,
  [MingaRoleType.DISTRICT_MANAGER]: 15,
  [MingaRoleType.KIOSK]: 16,
};

// Permissions that everyone has
const defaultLegacyPermissions = [
  'content.comments.deleteOwn',
  'content.announcement.delete',
  'content.event.delete',
  'content.file.delete',
  'content.fullGraphic.delete',
  'content.group.delete',
  'content.poll.delete',
  'content.post.deleteOwn',
  'content.video.deleteOwn',
  'minga.content.removeOwn',
  'app.notifications.list',
  'asset.get.any',
  'content.banner.list',
  'content.banner.search',
  'content.post.get',
  'feed.content.list',
];

const legacyPermissionMap = {
  [MingaPermission.CONTENT_DELETE_OTHERS]: [
    'content.comments.delete',
    'content.announcement.deleteOthers',
    'content.event.deleteOthers',
    'content.file.deleteOthers',
    'content.fullGraphic.deleteOthers',
    'content.group.deleteOthers',
    'content.poll.deleteOthers',
    'content.post.deleteOthers',
    'content.video.deleteOthers',
    'minga.file.delete',
    'minga.content.removeAny',
  ],
  [MingaPermission.ACCOUNT_DISPLAYNAME_CHANGE]: ['account.displayName.change'],
  [MingaPermission.ACCOUNT_PROFILE_CHANGE]: ['account.profile.change'],
  [MingaPermission.SUPERADMIN]: [
    'admin.access.addRole',
    'admin.access.deleteRole',
    'admin.access.updateRole',
    'admin.access.list',
    'admin.content.removeAny',
    'app.broadcast.global',
    'app.dev.testing',
    'asset.process.any',
    'content.banner.add',
    'content.banner.archive',
    'content.banner.delete',
    'content.banner.edit',
    'content.banner.searchArchive',
    'content.pollstyle.archive',
    'content.pollstyle.add',
    'content.pollstyle.archive',
    'content.pollstyle.edit',
    'content.pollstyle.delete',
    'content.pollstyle.listAdmin',
    'content.programs.create',
    'content.programs.delete',
    'content.programs.list',
    'content.programs.update',
    'minga.pause.negate',
    'mingaadmin.enable_all_features',
    'mingaadmin.mingas.browse',
    'mingaadmin.mingas.list',
    'mingaadmin.mingas.delete',
    'mingaadmin.mingas.create',
    'mingaadmin.mingas.update',
    'mingaadmin.programs.list',
    'mingaadmin.programs.remove',
    'mingadmin.reports.list',
    'content.group.updateOthers',
  ],
  [MingaPermission.APP_BROADCAST_SEND]: ['app.broadcast.send'],
  [MingaPermission.ASSET_UPLOAD]: ['asset.set.any', 'asset.upload.any'],
  [MingaPermission.CONTENT_ANNOUNCEMENT_CREATE]: [
    'content.announcement.create',
    'content.announcement.update',
  ],
  [MingaPermission.CONTENT_COMMENT_CREATE]: ['content.comments.create'],
  [MingaPermission.CONTENT_COMMENTS_VIEW]: ['content.comments.view'],
  [MingaPermission.CONTENT_EVENT_CREATE]: [
    'content.event.create',
    'content.event.update',
    'content.events.update',
    'content.events.create',
  ],
  [MingaPermission.CONTENT_FULL_GRAPHIC_CREATE]: [
    'content.fullGraphic.update',
    'content.inspiration.create',
    'content.inspiration.style',
  ],
  [MingaPermission.CONTENT_GROUP_CREATE]: ['content.group.create'],
  [MingaPermission.CONTENT_GROUP_ENABLED]: ['content.group.enabled'],
  [MingaPermission.CONTENT_GROUP_VIEWALL]: ['content.group.viewall'],
  [MingaPermission.CONTENT_HTML_INCLUDE]: ['content.html.include'],
  [MingaPermission.CONTENT_LIKES_LIKE]: [
    'content.likes.set',
    'content.likes.viewOwn',
  ],
  [MingaPermission.CONTENT_LIKES_VIEW]: ['content.likes.viewOthers'],
  [MingaPermission.CONTENT_MODERATION_OVERRIDE]: [
    'content.moderation.get',
    'content.moderation.override',
  ],
  [MingaPermission.CONTENT_POLL_CREATE]: [
    'content.poll.create',
    'content.poll.update',
  ],
  [MingaPermission.CONTENT_POST_CREATE]: [
    'content.post.create',
    'content.post.update',
  ],
  [MingaPermission.CONTENT_POST_SET_TITLE]: ['content.post.setTitle'],
  [MingaPermission.CONTENT_PUBLISH_DATES_SET]: ['content.publishDates.set'],
  [MingaPermission.CONTENT_REPORT_CREATE]: ['content.report.create'],
  [MingaPermission.CONTENT_REPORTS_MANAGE]: [
    'content.report.list',
    'content.report.resolve',
    'content.report.stats',
  ],
  [MingaPermission.CONTENT_VIDEO_CREATE]: [
    'content.video.create',
    'content.video.update',
  ],
  [MingaPermission.MINGA_ACCOUNT_MANAGE]: [
    'minga.account.read',
    'minga.account.updateOwn',
    'minga.pause.contact',
  ],
  [MingaPermission.GROUP_BROADCAST_SEND]: ['group.broadcast.send'],
  [MingaPermission.GROUP_CONTENT_ANNOUNCEMENT_CREATE]: [
    'group.content.announcement.create',
  ],
  [MingaPermission.GROUP_CONTENT_FULL_GRAPHIC_CREATE]: [
    'group.content.inspiration.create',
  ],
  [MingaPermission.GROUP_CONTENT_EVENTS_CREATE]: [
    'group.content.events.create',
  ],
  [MingaPermission.GROUP_CONTENT_POLL_CREATE]: ['group.content.poll.create'],
  [MingaPermission.GROUP_CONTENT_POST_CREATE]: ['group.content.post.create'],
  [MingaPermission.GROUP_CONTENT_VIDEO_CREATE]: ['group.content.video.create'],
  [MingaPermission.MINGA_BLOCKORDER_UPLOAD]: ['minga.blockorder.upload'],
  [MingaPermission.MINGA_CONTENT_PIN]: [
    'minga.content.editPin',
    'minga.content.unpin',
  ],
  [MingaPermission.MINGA_CONTENT_MAIN_FEED]: ['minga.content.mainFeed'],
  [MingaPermission.MINGA_CONTENT_UPDATE_OTHERS]: ['minga.content.updateOthers'],
  [MingaPermission.MINGA_FILE_CREATE]: ['minga.file.add'],
  [MingaPermission.MINGA_PROFILE_EDIT]: ['minga.mingas.updateOwn'],
  [MingaPermission.MINGA_PEOPLE_MANAGE]: [
    'minga.people.create',
    'minga.people.list',
    'peopleadmin.people.archive',
    'peopleadmin.people.create',
    'peopleadmin.people.createBulk',
    'peopleadmin.people.list',
    'peopleadmin.people.update',
    'minga.people.createBulk',
  ],
  [MingaPermission.MINGA_POLLS_MANAGE]: [
    'minga.poll.list',
    'minga.poll.listUnpublished',
    'minga.poll.listVotes',
  ],
  [MingaPermission.MINGA_PROGRAMS_MANAGE]: [
    'minga.programs.add',
    'minga.programs.remove',
    'minga.programs.list',
  ],
  [MingaPermission.PEOPLE_DELETE]: ['peopleadmin.people.delete'],
};

export function convertLegacyPermission(
  legacyPermission: string,
): MingaPermission {
  for (const perm in legacyPermissionMap) {
    const legacyPerms = (<any>legacyPermissionMap)[perm];
    if (legacyPerms.includes(legacyPermission)) {
      return <MingaPermission>parseInt(perm);
    }
  }

  throw new Error(`legacyPermission ${legacyPermission} is invalid`);
}

function getLegacyPermissions(permission: MingaPermission): string[] {
  return (<any>legacyPermissionMap)[permission] || [];
}

export function getAllLegacyPermissions(
  permissions: readonly MingaPermission[],
): string[] {
  const legacyPermissions = (<string[]>[]).concat(defaultLegacyPermissions);
  return legacyPermissions.concat(...permissions.map(getLegacyPermissions));
}

export function getLegacyRoleId(roleType: MingaRoleType): number {
  const legacyId = legacyMingaRoleIdMap[roleType];
  return legacyId || 0;
}

export function getRoleTypeFromLegacyId(legacyRoleId: number): MingaRoleType {
  for (const key in legacyMingaRoleIdMap) {
    const roleType = <MingaRoleType>key;

    if (legacyRoleId === getLegacyRoleId(roleType)) {
      return roleType;
    }
  }

  throw new Error(`Invalid legacy id ${legacyRoleId}`);
}
