import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { Router, RouterEvent } from '@angular/router';

import { MingaMinimalModel } from 'libs/domain';
import { IMingaFeatureToggleKeys } from 'libs/domain';
import { MingaPermission } from 'libs/domain';
import { PersonViewMinimal } from 'libs/generated-grpc-web';
import { mingaSettingTypes } from 'libs/util';
import { Observable, Subscription } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

import { AuthService } from '@app/src/app/minimal/services/Auth';
import { StreamManager } from '@app/src/app/minimal/services/StreamManager';
import { NavigationFtueKeys } from '@app/src/app/navigation';
import { PermissionsService } from '@app/src/app/permissions';
import { LinkOpenerService } from '@app/src/app/services/LinkOpener';
import { MingaManagerService } from '@app/src/app/services/MingaManager';
import { MingaSettingsService } from '@app/src/app/store/Minga/services';
import { MingaStoreFacadeService } from '@app/src/app/store/Minga/services/MingaStoreFacade.service';

import { FlexTimeManagerRoutes } from '@modules/flex-time-manager';
import { MingaManagerRoutes } from '@modules/minga-manager';

import { SystemAlertSnackBarService } from '@shared/components/system-alert-snackbar';
import { CheckinFlowService } from '@shared/services/checkin';
import { FlexTimePermissionsService } from '@shared/services/flex-time/flex-time-permissions';
import { PbisRouteService } from '@shared/services/pbis/pbis-route.service';
import { UserpilotService } from '@shared/services/userpilot/Userpilot.service';

import { NavLinkIconColors } from '../NavLink';

@Component({
  selector: 'mg-navigation-drawer',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./NavigationDrawer.component.scss'],
  templateUrl: `NavigationDrawer.component.html`,
})
export class NavigationDrawerComponent
  implements OnDestroy, OnInit, AfterViewInit
{
  /** Inputs */
  @Input() showProfile = false;
  @Input() person?: PersonViewMinimal.AsObject;
  @Input() mingaName = '';
  @Input() mingaLogo = '';

  /** Outputs */
  @Output() closeNavigation = new EventEmitter<boolean>();

  readonly NUM_ICON_COLORS = 5;
  readonly ftueKeys = NavigationFtueKeys;
  private _mingaSub?: Subscription;
  private _routerSub?: Subscription;
  private _communitySub?: Subscription;
  enableMingaDashboard = false;
  isPhotoGalleryEnabled$: Observable<boolean>;
  isChallengesEnabled$: Observable<boolean>;
  isLeaderboardEnabled$: Observable<boolean>;
  isCommunityEnabled = true;
  isSISEnabled$: Observable<boolean>;
  minga$: Observable<MingaMinimalModel>;
  NavLinkIconColors = NavLinkIconColors;

  featureToggles = IMingaFeatureToggleKeys;

  isSuperAdmin$: Observable<boolean> = this.permissions.observePermission(
    MingaPermission.SUPERADMIN,
  );

  get nativeElement() {
    return this._elementRef.nativeElement;
  }

  @ViewChild('navigationDrawerLinks', { static: false })
  navLinkWrapper: ElementRef;

  public mingas$: Observable<MingaMinimalModel[]> =
    this._mingaStore.getAllMingas();

  constructor(
    private _streamManager: StreamManager,
    private _router: Router,
    protected permissions: PermissionsService,
    private _changeDetectorRef: ChangeDetectorRef,
    private _elementRef: ElementRef,
    private _auth: AuthService,
    private _mingaStore: MingaStoreFacadeService,
    private _linkOpener: LinkOpenerService,
    private _pbisRouteService: PbisRouteService,
    private _checkinOverlayService: CheckinFlowService,
    private _renderer: Renderer2,
    private _settingService: MingaSettingsService,
    private _userPilotService: UserpilotService,
    private _flexPermissionsService: FlexTimePermissionsService,
    private _mingaManagerService: MingaManagerService,
    private _systemAlertSnackBar: SystemAlertSnackBarService,
  ) {
    this.init();
    this.isPhotoGalleryEnabled$ =
      this._settingService.isPhotoGalleryModuleEnabled();

    this.minga$ = this._mingaStore.getMingaAsObservable();

    this.isLeaderboardEnabled$ = this._settingService.getSettingValueObs(
      mingaSettingTypes.FEATURE_TRACKING_PUBLIC_LEADERBOARD,
    );
  }

  ngOnInit() {
    this._communitySub = this._settingService
      .isCommunityModuleEnabled()
      .pipe(distinctUntilChanged())
      .subscribe(isEnabled => {
        this.isCommunityEnabled = isEnabled;
      });
  }

  ngOnDestroy() {
    if (this._mingaSub) {
      this._mingaSub.unsubscribe();
    }
    if (this._routerSub) {
      this._routerSub.unsubscribe();
    }
    if (this._communitySub) {
      this._communitySub.unsubscribe();
    }
  }

  async init() {
    this._routerSub = this._router.events.subscribe((e: RouterEvent) => {
      //.update for the case of having active or no longer active nav item
      this._changeDetectorRef.markForCheck();
    });
  }

  ngAfterViewInit(): void {
    setTimeout(() => this.initNavLinkIconColors(), 800);
  }

  initNavLinkIconColors() {
    let index = 0;
    const childNavLinks = this.navLinkWrapper.nativeElement.children;
    childNavLinks.forEach(element => {
      const remainder = index % this.NUM_ICON_COLORS;
      this._renderer.addClass(element, `nav-link-color-${remainder}`);
      index++;
    });
  }

  onRouterLinkClick() {
    this.closeNavigation.emit(true);
  }

  restartStream(streamName: string | string[]) {
    this.closeNavigation.emit(true);
    this._streamManager.restartStreams(streamName);
  }

  gotoHelpDocs() {
    if (window.MINGA_APP_IOS) {
      this._linkOpener.open('https://minga.io/helpcenter/');
    } else {
      this._userPilotService.openResourceCenter();
    }
  }

  // @todo remove this whole navigation module
  /** @deprecated not used anymore, removing content to delete otherwised unused file **/
  gotoStudentIdAdminSummary() {}

  gotoHallPasses() {
    this._router.navigate(['/hall-pass', { outlets: { o: null } }]);
  }

  gotoMingaManager() {
    const link = this.permissions.hasPermission(
      MingaPermission.MINGA_ACCOUNT_MANAGE,
    )
      ? `/${MingaManagerRoutes.ROOT}`
      : `/${MingaManagerRoutes.ROOT}/${MingaManagerRoutes.SCHEDULED_REPORTS}`;
    this._router.navigate([link]);
  }

  gotoPbis() {
    this._pbisRouteService.gotoPbis();
  }

  gotoPointsManager() {
    this._router.navigate(['/points-manager']);
  }

  canViewPointsManager() {
    if (!window.MINGA_APP_BROWSER) {
      return this.permissions.hasPermission(
        [
          MingaPermission.POINTS_MANAGE,
          MingaPermission.MANAGE_REWARDS,
          MingaPermission.MINGA_ACCOUNT_MANAGE,
        ],
        false,
      );
    } else {
      return this.permissions.hasPermission(
        MingaPermission.POINTS_VIEW_REPORTS,
        false,
      );
    }
  }

  gotoFlexTimeManager() {
    const link = this._flexPermissionsService.isFlexReporter()
      ? `/${FlexTimeManagerRoutes.ROOT}`
      : `/${FlexTimeManagerRoutes.ROOT}/${FlexTimeManagerRoutes.PERIODS}`;
    this._router.navigate([link]);
  }

  gotoCheckin() {
    this._checkinOverlayService.goToCheckin();
  }

  async logout() {
    if (await this._auth.logoutDialog()) {
      await this._router.navigateByUrl('/landing');
    }
  }

  public async openMingaSwitcher() {
    try {
      const minga = await this._mingaManagerService.openSwitchMingaModal();

      this._systemAlertSnackBar.open({
        type: 'success',
        message: `Successfully switched to ${minga.name}`,
      });
      this._router.navigate(['/']);
    } catch (e) {
      // this isn't really an error so lets just suppress it
      // just means they didnt switch mingas
    }
  }
}
