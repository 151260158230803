import {
  AfterViewInit,
  Directive,
  ElementRef,
  Input,
  Renderer2,
} from '@angular/core';

import { MingaRoleType } from 'libs/domain';

import {
  StudentIdImageService,
  StudentIdPresetSize,
} from '@shared/services/student-id-image/StudentIdImage.service';
import { getRoleAvatar } from '@shared/utils/get-role-avatar';

@Directive({
  exportAs: 'mgStudentPicture',
  selector: '[mgStudentPicture]',
})
export class StudentPictureDirective implements AfterViewInit {
  @Input() mgStudentPicture: string;
  @Input() role: MingaRoleType;

  constructor(
    private _el: ElementRef,
    private _renderer: Renderer2,
    private _studentIdImage: StudentIdImageService,
  ) {}

  ngAfterViewInit(): void {
    this._getStudentIdImage();
  }

  private async _getStudentIdImage(): Promise<any> {
    if (!this.mgStudentPicture) return;

    let path = '';
    try {
      path = await this._studentIdImage.getFilePathToStudentIdImage(
        this.mgStudentPicture,
        StudentIdPresetSize.THUMB,
      );
    } catch (e) {
      // suppress not found error and replace with role smiley
      path = getRoleAvatar(this.role);
    } finally {
      // this is a global mixin that will apply commonly used styles such as background positioning, border radius, etc.
      // only style other than a possible override needed would be to set a size for the image
      this._renderer.addClass(this._el.nativeElement, 'profile-picture');
      this._el.nativeElement.style.backgroundImage = `url(${path})`;
    }
  }
}
