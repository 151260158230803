import { PortalModule } from '@angular/cdk/portal';
import { NgModule } from '@angular/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';

import { MgDirectivesModule } from '@app/src/app/directives';

import { HpmDashboardPipesModule } from '@modules/hallpass-manager/components/hpm-dashboard/pipes/hpm-dashboard-pipes.module';
import { SaSharedModule } from '@modules/selection-assigner/components/sa-shared/sa-shared.module';

import { StudentPictureModule } from '@shared/directives/student-picture/student-picture.module';
import { CommonFormModules, CommonUIModules } from '@shared/modules';

import { KioskSummaryComponent } from './components/common/kiosk-summary/kiosk-summary.component';
import { KioskTypeAssignerComponent } from './components/common/kiosk-type-assigner/kiosk-type-assigner.component';
import { KioskTypeSelectorComponent } from './components/common/kiosk-type-selector/kiosk-type-selector.component';
import { KioskCategorySelectorComponent } from './components/kiosk-category-selector/kiosk-category-selector.component';
import { KioskCheckInComponent } from './components/kiosk-check-in/kiosk-check-in.component';
import { KioskHallPassTableComponent } from './components/kiosk-hall-pass-table/kiosk-hall-pass-table.component';
import { KioskHallPassComponent } from './components/kiosk-hall-pass/kiosk-hall-pass.component';
import { KioskCategoryGuard, KioskGuard } from './guards';
import { KioskComponent } from './kiosk.component';
import { KIOSK_ROUTES } from './kiosk.routes';
import { KioskHallPassTimerPipe } from './pipes';
import { KioskService } from './services';

@NgModule({
  imports: [
    // Routing
    RouterModule.forChild(KIOSK_ROUTES),

    // Common modules
    CommonUIModules,
    CommonFormModules,

    // Minga dependencies
    SaSharedModule,
    //HpmDashboardPipesModule, //this is causing a runtime error, but not totally sure if it's needed for kiosk
    StudentPictureModule,
    MgDirectivesModule,

    // External dependencies
    RouterModule,
    PortalModule,
    MatMenuModule,
    MatTableModule,
    MatTooltipModule,
    MatExpansionModule,
    MatProgressSpinnerModule,
  ],
  declarations: [
    KioskComponent,
    KioskSummaryComponent,
    KioskCheckInComponent,
    KioskHallPassComponent,
    KioskHallPassTableComponent,
    KioskTypeAssignerComponent,
    KioskTypeSelectorComponent,
    KioskCategorySelectorComponent,

    // Pipes
    KioskHallPassTimerPipe,
  ],
  exports: [],
  providers: [KioskService, KioskGuard, KioskCategoryGuard],
})
export class KioskModule {}
