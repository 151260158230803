import { ContentStatus, ReportReason } from 'libs/util';

import { ContentType } from '../';

export enum ModerationNonContentType {
  GALLERY_PHOTO = 'GALLERY_PHOTO',
}

export interface IModerationPair {
  key: string;
  value: string;
}

export interface IModerationProviderDetails {
  readonly tags: IModerationPair[];
  readonly fileUrl: string;
  readonly index: number;
  readonly imageTags: IModerationPair[];
  readonly reasonIndex?: number;
}

export interface IModerationHistory {
  readonly personId: number | null;
  readonly timestamp: number;
  readonly personDisplayName: string;
  readonly reason: ReportReason;
  readonly status: ContentStatus;
  readonly details: IModerationProviderDetails[];
}

export interface IModeration {
  title: string;
  parentContextHash: string;
  contentContextHash: string;
  mingaHash: string;
  mingaName: string;
  contentAuthorHash: string;
  timestamp: number;
  contentAuthorFirstName: string;
  contentAuthorLastName: string;
  type: ModerationNonContentType | ContentType;
  contentHistory: IModerationHistory[];
  status: ContentStatus;
  actionContextHash: string;
  galleryPhotoUuid: string;
}

export interface IModerationDetailTags {
  categories: string[];
  reasonIndex?: ReportReason;
}

export interface IModerationResult {
  allPassed: boolean;
  allTextContentPassed?: boolean;
  allImageContentPassed?: boolean;
  allImageTextContentPassed?: boolean;
  tags?: Map<number, IModerationDetailTags>;
  imageTags?: Map<number, IModerationDetailTags>;
}

/**
 * Interface for the result of a moderation engine's
 * moderation function.
 */
export interface IModerationEngineResponse {
  response: boolean; // whether the content passed moderation or not
  provider: string; // provider name
  type: moderationType;
  aiData?: any[]; // raw data from engine we want to store for analysis
  details: IModerationEngineDetails[];
  reasonIndex?: ReportReason;
}

export interface IModerationEngineDetails {
  index: number;
  tags: { [key: string]: number };
  imageTags?: { [key: string]: number };
  fileUrl?: string;
  reasonIndex?: number;
}

export enum moderationType {
  IMAGE = 'IMAGE',
  TEXT = 'TEXT',
}
