// GENERATED CODE -- DO NOT EDIT!

import {grpc} from '@improbable-eng/grpc-web';

import * as content_poll_answer_pb from '../../content/poll/answer_pb';

export class PollAnswer {
  static readonly serviceName = "minga.content.PollAnswer";

  static readonly Set = {
    methodName: "Set",
    service: PollAnswer,
    requestStream: false,
    responseStream: false,
    requestType: content_poll_answer_pb.SetPollAnswer,
    responseType: content_poll_answer_pb.PollResults,
  };

}

export class PollAnswerClient {
  set() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
}
