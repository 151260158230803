import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';

import * as localforage from 'localforage';
import {
  CoercedEnvironment,
  environment,
  getAppOrigin,
  getVersion,
  IEnvironmentConfig,
  IEnvironmentConfigCountry,
  IEnvironmentConfigFirebase,
} from '@app/src/environment';
import { BehaviorSubject, Subject } from 'rxjs';

import { SentryService } from '@app/src/app/minimal/services/Sentry/Sentry.service';

import { RemoteConfigService } from '@shared/services/config/remote-config.service';
import { REMOTE_CONFIG_COERCED_MAPPER } from '@shared/utils/remote-config';

export enum MingaCountryEnum {
  CAN = 'CAN',
  USA = 'USA',
  INTL = 'INTL',
}

/**
 * Functions for dealing with the AppConfig. Mostly deals with app urls to use.
 */
@Injectable({ providedIn: 'root' })
export class AppConfigService {
  private localCountry: string | null = null;
  private coercedMapper: CoercedEnvironment = {
    // default getters from environment config
    ...Object.keys(environment).reduce(
      (acc: CoercedEnvironment, key: string) => {
        acc[key] = () => environment[key];
        return acc;
      },
      {} as CoercedEnvironment,
    ),
    // getters retrieved from remote config
    ...REMOTE_CONFIG_COERCED_MAPPER,
  };

  private DEFAULT_COUNTRY: string = MingaCountryEnum.CAN;

  countrySubject: Subject<string> = new BehaviorSubject('');

  constructor(
    private injector: Injector,
    private sentryService: SentryService,
    private _remoteConfig: RemoteConfigService,
  ) {
    // make sure we run through the whole app url setup
    // if the country was changed.
    this.countrySubject.subscribe(async value => {
      if (value) {
        await this.handleAppSetup();
      }
    });
  }

  async startup() {
    try {
      await this._remoteConfig.startup();
    } catch (err) {
      this.sentryService.captureMessageAsError(
        'Error starting up remote config in AppConfigService.',
        err,
      );
    }

    if (this.localCountry === null) {
      const country = await this.getAppCountry();

      if (country) {
        this.localCountry = country;
        this.countrySubject.next(country);
      }
    }
    await this.handleAppSetup();

    this.sentryService.startup();
  }

  public getValue<K extends keyof IEnvironmentConfig>(
    key: K,
  ): IEnvironmentConfig[K] | null {
    const getCoercedValue = this.coercedMapper[key];
    return getCoercedValue
      ? (getCoercedValue() as IEnvironmentConfig[K])
      : null;
  }

  async getCountryAppUrls(): Promise<IEnvironmentConfigCountry[]> {
    const countryUrls = this.getValue('countryAppUrls');
    return countryUrls;
  }

  async getFirebaseConfig(): Promise<IEnvironmentConfigFirebase> {
    return environment.firebaseConfig;
  }

  async getEnvironment() {
    // should not be overwritten remotely so we get direct from config file.
    return environment.environment;
  }

  async getClever() {
    let postUrl = '';
    if (window.MINGA_APP_ANDROID) {
      postUrl = '/android';
    } else if (window.MINGA_APP_IOS) {
      postUrl = '/ios';
    }
    const clever = { ...environment.clever };
    clever.redirectUri = clever.redirectUri + postUrl;
    const enableLogin = this.getValue('cleverEnableLogin');

    //override the config if we want to enable login
    if (enableLogin === true) {
      clever.loginEnabled = true;
    } else if (enableLogin === false) {
      clever.loginEnabled = false;
    }
    return clever;
  }

  getGtmConfig() {
    return environment.gtm;
  }

  getAppVersion() {
    // should not be overwritten remotely so we get direct from config file.
    return getVersion();
  }

  getAppOrigin() {
    return getAppOrigin();
  }

  async ensureCountrySet() {
    const country = await this.getAppCountry();
    if (!country) {
      const router = this.injector.get(Router);
      await router.navigate(['/landing/country']);
    }
  }

  /**
   * Set important global variables from the configuration that is currently
   * set.
   */
  setAppConfigGlobals(apiUrl: string, assetUrlPrefix: string) {
    window.DEFAULT_ANGULAR_GRPC_HOST = apiUrl;
    Object.defineProperty(window, 'MINGA_ASSET_URL_PREFIX', {
      value: assetUrlPrefix,
      configurable: true,
    });
  }

  public async getApiUrl() {
    const countryUrls = await this.getAppCountryUrls();
    if (countryUrls) {
      return countryUrls.apiUrl;
    }

    const defaultApiUrl = this.getValue('mgsrvGatewayHost');

    return defaultApiUrl;
  }

  public async getIdBucket() {
    const countryUrls = await this.getAppCountryUrls();
    if (countryUrls) {
      return countryUrls.idBucket;
    }
    const defaultIdBucket = this.getValue('idBucket');

    return defaultIdBucket;
  }

  public async getSuggestionUrl() {
    const countryUrls = await this.getAppCountryUrls();
    if (countryUrls) {
      return countryUrls.suggestionUrl;
    }
    const defaultUrl = this.getValue('suggestionUrl');

    return defaultUrl;
  }

  public async getAllApiUrls(): Promise<string[]> {
    const countryAppConfigList = await this.getCountryAppUrls();

    return countryAppConfigList.map(c => c.apiUrl);
  }

  public async getAssetUrlPrefix() {
    const countryUrls = await this.getAppCountryUrls();

    if (countryUrls) {
      return countryUrls.cdnUrl;
    }

    const defaultApiUrl = this.getValue('assetUrlPrefix');

    return defaultApiUrl;
  }

  public async getAppCountry() {
    if (this.localCountry) {
      return this.localCountry;
    }

    const cachedAppCountry = await localforage
      .getItem<string>('mgCountry')
      .catch(err => {
        console.error(err);
        return null;
      });

    return cachedAppCountry;
  }

  /**
   * Save the country in local storage and local memory.
   * @param country
   */
  public async setAppCountry(country: string) {
    try {
      await localforage.setItem('mgCountry', country);
      this.localCountry = country;
      this.countrySubject.next(country);
    } catch (err) {
      console.error(err);
    }
  }

  public async setAppCountryFromApiUrl(apiUrl: string) {
    const countryAppConfigList = await this.getCountryAppUrls();

    const appUrlObj = countryAppConfigList.find(
      appUrlObj => appUrlObj.apiUrl === apiUrl,
    );
    if (appUrlObj) {
      await this.setAppCountry(appUrlObj.country);
    }
  }

  /**
   * Remove stored country so that they can re-select their country.
   */
  public async clearAppCountry() {
    try {
      this.localCountry = null;
      await localforage.removeItem('mgCountry');
    } catch (err) {
      console.error(err);
    }
  }

  /**
   * Get the appConfig and sets the global variables to use the values
   * from appConfig, using the country that has been set.
   */
  private async handleAppSetup() {
    const apiUrl = await this.getApiUrl();
    const assetUrlPrefix = await this.getAssetUrlPrefix();
    this.setAppConfigGlobals(apiUrl, assetUrlPrefix);
  }

  private async getAppCountryUrls(): Promise<IEnvironmentConfigCountry | null> {
    const country = await this.getAppCountry();

    if (country) {
      return this.getCountryUrlsFromCountry(country);
    }
    // no country set, return default country...
    return this.getCountryUrlsFromCountry(this.DEFAULT_COUNTRY);
  }

  private async getCountryUrlsFromCountry(countryToFind: string) {
    const countryUrls = await this.getCountryAppUrls();
    const foundCountry = countryUrls.find(
      country => country.country == countryToFind,
    );

    if (foundCountry) {
      return foundCountry;
    }
    return null;
  }
}
