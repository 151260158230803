import { Injectable, NgZone } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { grpc } from '@improbable-eng/grpc-web';

import {
  AllAutomationsResetRequest,
  AllAutomationsResetResponse,
  Automation,
  CreateAutomationRequest,
  DeleteAutomationRequest,
  DeleteAutomationResponse,
  GetAutomationRequest,
  ListAutomationsRequest,
  ListAutomationsResponse,
  ResetAutomationCountRequest,
  ResetAutomationCountResponse,
  UpdateAutomationRequest,
} from '../automation/automation_pb';

import {
  AutomationManager as __AutomationManager,
} from '../automation/automation_pb_service';

@Injectable({providedIn: 'root'})
export class AutomationManager {

  constructor(private _ngZone: NgZone) {
  }

  listAutomations(request: ListAutomationsRequest): Promise<ListAutomationsResponse>;
  listAutomations(request: ListAutomationsRequest, metadata: grpc.Metadata): Promise<ListAutomationsResponse>;
  listAutomations(request: ListAutomationsRequest, callback: (err: any|null, response: ListAutomationsResponse, metadata: grpc.Metadata) => void): void;
  listAutomations(request: ListAutomationsRequest, metadata: grpc.Metadata, callback: (err: any|null, response: ListAutomationsResponse, metadata: grpc.Metadata) => void): void;

  listAutomations(request: ListAutomationsRequest, arg1?: grpc.Metadata|((err: any|null, response: ListAutomationsResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: ListAutomationsResponse, metadata: grpc.Metadata) => void): Promise<ListAutomationsResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<ListAutomationsResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__AutomationManager.ListAutomations, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  getAutomation(request: GetAutomationRequest): Promise<Automation>;
  getAutomation(request: GetAutomationRequest, metadata: grpc.Metadata): Promise<Automation>;
  getAutomation(request: GetAutomationRequest, callback: (err: any|null, response: Automation, metadata: grpc.Metadata) => void): void;
  getAutomation(request: GetAutomationRequest, metadata: grpc.Metadata, callback: (err: any|null, response: Automation, metadata: grpc.Metadata) => void): void;

  getAutomation(request: GetAutomationRequest, arg1?: grpc.Metadata|((err: any|null, response: Automation, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: Automation, metadata: grpc.Metadata) => void): Promise<Automation>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<Automation>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__AutomationManager.GetAutomation, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  createAutomation(request: CreateAutomationRequest): Promise<Automation>;
  createAutomation(request: CreateAutomationRequest, metadata: grpc.Metadata): Promise<Automation>;
  createAutomation(request: CreateAutomationRequest, callback: (err: any|null, response: Automation, metadata: grpc.Metadata) => void): void;
  createAutomation(request: CreateAutomationRequest, metadata: grpc.Metadata, callback: (err: any|null, response: Automation, metadata: grpc.Metadata) => void): void;

  createAutomation(request: CreateAutomationRequest, arg1?: grpc.Metadata|((err: any|null, response: Automation, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: Automation, metadata: grpc.Metadata) => void): Promise<Automation>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<Automation>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__AutomationManager.CreateAutomation, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  updateAutomation(request: UpdateAutomationRequest): Promise<Automation>;
  updateAutomation(request: UpdateAutomationRequest, metadata: grpc.Metadata): Promise<Automation>;
  updateAutomation(request: UpdateAutomationRequest, callback: (err: any|null, response: Automation, metadata: grpc.Metadata) => void): void;
  updateAutomation(request: UpdateAutomationRequest, metadata: grpc.Metadata, callback: (err: any|null, response: Automation, metadata: grpc.Metadata) => void): void;

  updateAutomation(request: UpdateAutomationRequest, arg1?: grpc.Metadata|((err: any|null, response: Automation, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: Automation, metadata: grpc.Metadata) => void): Promise<Automation>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<Automation>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__AutomationManager.UpdateAutomation, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  deleteAutomation(request: DeleteAutomationRequest): Promise<DeleteAutomationResponse>;
  deleteAutomation(request: DeleteAutomationRequest, metadata: grpc.Metadata): Promise<DeleteAutomationResponse>;
  deleteAutomation(request: DeleteAutomationRequest, callback: (err: any|null, response: DeleteAutomationResponse, metadata: grpc.Metadata) => void): void;
  deleteAutomation(request: DeleteAutomationRequest, metadata: grpc.Metadata, callback: (err: any|null, response: DeleteAutomationResponse, metadata: grpc.Metadata) => void): void;

  deleteAutomation(request: DeleteAutomationRequest, arg1?: grpc.Metadata|((err: any|null, response: DeleteAutomationResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: DeleteAutomationResponse, metadata: grpc.Metadata) => void): Promise<DeleteAutomationResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<DeleteAutomationResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__AutomationManager.DeleteAutomation, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  resetAutomationCount(request: ResetAutomationCountRequest): Promise<ResetAutomationCountResponse>;
  resetAutomationCount(request: ResetAutomationCountRequest, metadata: grpc.Metadata): Promise<ResetAutomationCountResponse>;
  resetAutomationCount(request: ResetAutomationCountRequest, callback: (err: any|null, response: ResetAutomationCountResponse, metadata: grpc.Metadata) => void): void;
  resetAutomationCount(request: ResetAutomationCountRequest, metadata: grpc.Metadata, callback: (err: any|null, response: ResetAutomationCountResponse, metadata: grpc.Metadata) => void): void;

  resetAutomationCount(request: ResetAutomationCountRequest, arg1?: grpc.Metadata|((err: any|null, response: ResetAutomationCountResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: ResetAutomationCountResponse, metadata: grpc.Metadata) => void): Promise<ResetAutomationCountResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<ResetAutomationCountResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__AutomationManager.ResetAutomationCount, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  resetAllAutomationsForPeople(request: AllAutomationsResetRequest): Promise<AllAutomationsResetResponse>;
  resetAllAutomationsForPeople(request: AllAutomationsResetRequest, metadata: grpc.Metadata): Promise<AllAutomationsResetResponse>;
  resetAllAutomationsForPeople(request: AllAutomationsResetRequest, callback: (err: any|null, response: AllAutomationsResetResponse, metadata: grpc.Metadata) => void): void;
  resetAllAutomationsForPeople(request: AllAutomationsResetRequest, metadata: grpc.Metadata, callback: (err: any|null, response: AllAutomationsResetResponse, metadata: grpc.Metadata) => void): void;

  resetAllAutomationsForPeople(request: AllAutomationsResetRequest, arg1?: grpc.Metadata|((err: any|null, response: AllAutomationsResetResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: AllAutomationsResetResponse, metadata: grpc.Metadata) => void): Promise<AllAutomationsResetResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<AllAutomationsResetResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__AutomationManager.ResetAllAutomationsForPeople, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  resetAllAutomations(request: AllAutomationsResetRequest): Promise<AllAutomationsResetResponse>;
  resetAllAutomations(request: AllAutomationsResetRequest, metadata: grpc.Metadata): Promise<AllAutomationsResetResponse>;
  resetAllAutomations(request: AllAutomationsResetRequest, callback: (err: any|null, response: AllAutomationsResetResponse, metadata: grpc.Metadata) => void): void;
  resetAllAutomations(request: AllAutomationsResetRequest, metadata: grpc.Metadata, callback: (err: any|null, response: AllAutomationsResetResponse, metadata: grpc.Metadata) => void): void;

  resetAllAutomations(request: AllAutomationsResetRequest, arg1?: grpc.Metadata|((err: any|null, response: AllAutomationsResetResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: AllAutomationsResetResponse, metadata: grpc.Metadata) => void): Promise<AllAutomationsResetResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<AllAutomationsResetResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__AutomationManager.ResetAllAutomations, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

}

