<mg-card
  paddingX="0"
  paddingY="2"
  class="split-container">
  <!-- Left Section -->
  <section class="tw-flex tw-flex-column tw-items-center">
    <mg-text
      class="green-header"
      variant="header-xl-secondary">
      {{ leftTitle }}
    </mg-text>
    <mg-text
      variant="body-sm"
      color="surface-alt">
      {{ leftSubtitle }}
    </mg-text>
  </section>

  <div class="separator"></div>

  <!-- Right Section -->
  <section class="tw-flex tw-flex-column tw-items-center">
    <mg-text
      class="red-header"
      variant="header-xl-secondary">
      {{ rightTitle }}
    </mg-text>
    <mg-text
      variant="body-sm"
      color="surface-alt">
      {{ rightSubtitle }}
    </mg-text>
  </section>
</mg-card>
