// GENERATED CODE -- DO NOT EDIT!

import {grpc} from '@improbable-eng/grpc-web';

import * as gateway_landing_pb from '../gateway/landing_pb';

export class Landing {
  static readonly serviceName = "minga.landing.Landing";

  static readonly SendEmailVerification = {
    methodName: "SendEmailVerification",
    service: Landing,
    requestStream: false,
    responseStream: false,
    requestType: gateway_landing_pb.SendEmailVerificationRequest,
    responseType: gateway_landing_pb.SendEmailVerificationResponse,
  };

  static readonly CreateMinga = {
    methodName: "CreateMinga",
    service: Landing,
    requestStream: false,
    responseStream: true,
    requestType: gateway_landing_pb.CreateMingaRequest,
    responseType: gateway_landing_pb.CreateMingaResponse,
  };

  static readonly VerifyEmail = {
    methodName: "VerifyEmail",
    service: Landing,
    requestStream: false,
    responseStream: false,
    requestType: gateway_landing_pb.VerifyEmailRequest,
    responseType: gateway_landing_pb.VerifyEmailResponse,
  };

  static readonly VerifyPinOrCode = {
    methodName: "VerifyPinOrCode",
    service: Landing,
    requestStream: false,
    responseStream: false,
    requestType: gateway_landing_pb.VerifyPinOrCodeRequest,
    responseType: gateway_landing_pb.VerifyPinOrCodeResponse,
  };

  static readonly VerifyPinEmail = {
    methodName: "VerifyPinEmail",
    service: Landing,
    requestStream: false,
    responseStream: false,
    requestType: gateway_landing_pb.VerifyPinEmailRequest,
    responseType: gateway_landing_pb.VerifyPinEmailResponse,
  };

  static readonly UpdateActiveCampaign = {
    methodName: "UpdateActiveCampaign",
    service: Landing,
    requestStream: false,
    responseStream: false,
    requestType: gateway_landing_pb.ActiveCampaignEventRequest,
    responseType: gateway_landing_pb.ActiveCampaignEventResponse,
  };

}

export class LandingClient {
  sendEmailVerification() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  createMinga() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  verifyEmail() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  verifyPinOrCode() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  verifyPinEmail() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  updateActiveCampaign() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
}
