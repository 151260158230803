import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MediaChange, MediaObserver } from '@angular/flex-layout';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';

import { IMembershipList, MembershipListType } from 'libs/domain';
import { BehaviorSubject, Observable, ReplaySubject, Subscription } from 'rxjs';
import { distinctUntilChanged, map, share, takeUntil } from 'rxjs/operators';

import { ViewMembershipListMembersComponent } from '@app/src/app/membership-list/ViewMembershipListMembers';
import { PointsManagerService } from '@app/src/app/services/PointsManager';

import { ModalOverlayService } from '@shared/components/modal-overlay';

import { PointsManagerMessages } from '../../constants';
import { PointsManagerService as PmService } from '../../services';
import { PmTeamsMessages, PM_TEAMS_TABLE_COLUMNS } from './constants';
import { PmTeamsService } from './services';

@Component({
  selector: 'mg-pm-teams',
  templateUrl: './pm-teams.component.html',
  styleUrls: ['./pm-teams.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PmTeamsComponent implements OnInit, OnDestroy {
  @ViewChild(MatTable) matTable: MatTable<IMembershipList>;
  @ViewChild(MatSort, { static: false }) sort: MatSort;

  /** Constants */
  public readonly PM_MESSAGES = PointsManagerMessages;
  public readonly MESSAGES = PmTeamsMessages;

  public readonly lists = [MembershipListType.SCHOOL_TEAM];

  /** General Observables */
  private readonly _destroyed = new ReplaySubject<void>(1);
  public readonly media$: Observable<string>;
  private readonly _isLoading = new BehaviorSubject(true);
  public readonly isLoading$ = this._isLoading.asObservable().pipe(share());

  /** Table Data */
  public readonly dataSource = new MatTableDataSource<IMembershipList>([]);
  private _displayedColumns$ = new BehaviorSubject<string[]>(
    PM_TEAMS_TABLE_COLUMNS,
  );
  public readonly displayedColumns$ = this._displayedColumns$.asObservable();
  public readonly tableData$: Subscription;
  public readonly newCreatedListId$: Observable<number>;

  /** Component Constructor */
  constructor(
    public teamsService: PmTeamsService,
    public mediaObserver: MediaObserver,
    private _cdr: ChangeDetectorRef,
    private _router: Router,
    private _pointsManager: PointsManagerService,
    private _pmService: PmService,
    private _modalOverlay: ModalOverlayService,
  ) {
    this.media$ = this.mediaObserver.asObservable().pipe(
      takeUntil(this._destroyed),
      map(change => change[0].mqAlias),
      distinctUntilChanged(),
    );
    this.mediaObserver
      .asObservable()
      .pipe(takeUntil(this._destroyed))
      .subscribe(val => this._handleDisplayedColumns(val));
    this.tableData$ = this._pmService.teams$
      .pipe(takeUntil(this._destroyed))
      .subscribe(data => {
        this.dataSource.data = data;
        this.dataSource.sort = this.sort;
        this._cdr.markForCheck();
      });
  }

  ngOnInit(): void {
    this._initialLoad();
  }

  ngOnDestroy(): void {
    this._destroyed.next();
    this._destroyed.complete();
    this._isLoading.complete();
    this.tableData$.unsubscribe();
  }

  public trackById(index: number, item: IMembershipList) {
    return item ? item.id : index;
  }

  public openMembersList(item: IMembershipList) {
    if (!item.id) return;
    this._modalOverlay.open(ViewMembershipListMembersComponent, {
      data: {
        membershipListId: item.id,
      },
      disposeOnNavigation: false,
    });
  }

  private async _initialLoad(): Promise<void> {
    this._isLoading.next(true);
    await this._pmService.fetchTeams();
    this._isLoading.next(false);
  }

  private _handleDisplayedColumns(val: MediaChange[]) {
    const { mqAlias } = val[0];
    const displayedColumns = ['xs', 'sm', 'md'].includes(mqAlias)
      ? ['mobile']
      : PM_TEAMS_TABLE_COLUMNS;
    this._displayedColumns$.next(displayedColumns);
  }
}
