import { Pipe, PipeTransform } from '@angular/core';

import { Person } from '@app/src/app/people';

@Pipe({ name: 'getPeopleNameLabels' })
export class FiltersFormGetPeopleNameLabelsPipe implements PipeTransform {
  transform(people: Partial<Person>[]) {
    return people.map(({ firstName, lastName }) =>
      `${firstName} ${lastName}`.trim(),
    );
  }
}
