// GENERATED CODE -- DO NOT EDIT!

import {grpc} from '@improbable-eng/grpc-web';

import * as flex_time_flex_time_pb from '../flex_time/flex_time_pb';
import * as common_date_pb from '../common/date_pb';
import * as image_image_pb from '../image/image_pb';
import * as restriction_restriction_pb from '../restriction/restriction_pb';
import * as misc_category_pb from '../misc/category_pb';

export class FlexTimeManager {
  static readonly serviceName = "flex_time.FlexTimeManager";

  static readonly ListFlexTimePeriods = {
    methodName: "ListFlexTimePeriods",
    service: FlexTimeManager,
    requestStream: false,
    responseStream: false,
    requestType: flex_time_flex_time_pb.ListFlexTimePeriodsRequest,
    responseType: flex_time_flex_time_pb.ListFlexTimePeriodsResponse,
  };

  static readonly ListMinimalFlexTimePeriods = {
    methodName: "ListMinimalFlexTimePeriods",
    service: FlexTimeManager,
    requestStream: false,
    responseStream: false,
    requestType: flex_time_flex_time_pb.ListMinimalFlexTimePeriodsRequest,
    responseType: flex_time_flex_time_pb.ListMinimalFlexTimePeriodsResponse,
  };

  static readonly UpsertFlexTimePeriod = {
    methodName: "UpsertFlexTimePeriod",
    service: FlexTimeManager,
    requestStream: false,
    responseStream: false,
    requestType: flex_time_flex_time_pb.UpsertFlexTimePeriodRequest,
    responseType: flex_time_flex_time_pb.FlexTimePeriod,
  };

  static readonly CloneFlexTimePeriod = {
    methodName: "CloneFlexTimePeriod",
    service: FlexTimeManager,
    requestStream: false,
    responseStream: false,
    requestType: flex_time_flex_time_pb.UpsertFlexTimePeriodRequest,
    responseType: flex_time_flex_time_pb.FlexTimePeriod,
  };

  static readonly GetFlexTimePeriod = {
    methodName: "GetFlexTimePeriod",
    service: FlexTimeManager,
    requestStream: false,
    responseStream: false,
    requestType: flex_time_flex_time_pb.GetFlexTimePeriodRequest,
    responseType: flex_time_flex_time_pb.FlexTimePeriod,
  };

  static readonly DeleteFlexTimePeriod = {
    methodName: "DeleteFlexTimePeriod",
    service: FlexTimeManager,
    requestStream: false,
    responseStream: false,
    requestType: flex_time_flex_time_pb.DeleteFlexTimePeriodRequest,
    responseType: flex_time_flex_time_pb.DeleteFlexTimePeriodResponse,
  };

  static readonly ListFlexTimeActivities = {
    methodName: "ListFlexTimeActivities",
    service: FlexTimeManager,
    requestStream: false,
    responseStream: false,
    requestType: flex_time_flex_time_pb.ListFlexTimeActivitiesRequest,
    responseType: flex_time_flex_time_pb.ListFlexTimeActivitiesResponse,
  };

  static readonly GetFlexTimeActivity = {
    methodName: "GetFlexTimeActivity",
    service: FlexTimeManager,
    requestStream: false,
    responseStream: false,
    requestType: flex_time_flex_time_pb.GetFlexTimeActivityRequest,
    responseType: flex_time_flex_time_pb.FlexTimeActivity,
  };

  static readonly UpsertFlexTimeActivity = {
    methodName: "UpsertFlexTimeActivity",
    service: FlexTimeManager,
    requestStream: false,
    responseStream: false,
    requestType: flex_time_flex_time_pb.UpsertFlexTimeActivityRequest,
    responseType: flex_time_flex_time_pb.FlexTimeActivity,
  };

  static readonly DeleteFlexTimeActivity = {
    methodName: "DeleteFlexTimeActivity",
    service: FlexTimeManager,
    requestStream: false,
    responseStream: false,
    requestType: flex_time_flex_time_pb.DeleteFlexTimeActivityRequest,
    responseType: flex_time_flex_time_pb.DeleteFlexTimeActivityResponse,
  };

  static readonly ArchiveFlexTimeActivity = {
    methodName: "ArchiveFlexTimeActivity",
    service: FlexTimeManager,
    requestStream: false,
    responseStream: false,
    requestType: flex_time_flex_time_pb.ArchiveFlexTimeActivityRequest,
    responseType: flex_time_flex_time_pb.ArchiveFlexTimeActivityResponse,
  };

  static readonly ListFlexTimeActivityInstances = {
    methodName: "ListFlexTimeActivityInstances",
    service: FlexTimeManager,
    requestStream: false,
    responseStream: false,
    requestType: flex_time_flex_time_pb.ListFlexTimeActivityInstancesRequest,
    responseType: flex_time_flex_time_pb.ListFlexTimeActivityInstancesResponse,
  };

  static readonly UpsertFlexTimeActivityInstance = {
    methodName: "UpsertFlexTimeActivityInstance",
    service: FlexTimeManager,
    requestStream: false,
    responseStream: false,
    requestType: flex_time_flex_time_pb.UpsertFlexTimeActivityInstanceRequest,
    responseType: flex_time_flex_time_pb.UpsertFlexTimeActivityInstanceResponse,
  };

  static readonly DeleteFlexTimeActivityInstance = {
    methodName: "DeleteFlexTimeActivityInstance",
    service: FlexTimeManager,
    requestStream: false,
    responseStream: false,
    requestType: flex_time_flex_time_pb.DeleteFlexTimeActivityInstanceRequest,
    responseType: flex_time_flex_time_pb.DeleteFlexTimeActivityInstanceResponse,
  };

  static readonly GetFlexTimeActivityTypes = {
    methodName: "GetFlexTimeActivityTypes",
    service: FlexTimeManager,
    requestStream: false,
    responseStream: false,
    requestType: flex_time_flex_time_pb.GetFlexTimeActivityTypesRequest,
    responseType: flex_time_flex_time_pb.GetFlexTimeActivityTypesResponse,
  };

  static readonly ValidateAssign = {
    methodName: "ValidateAssign",
    service: FlexTimeManager,
    requestStream: false,
    responseStream: false,
    requestType: flex_time_flex_time_pb.ValidateAssignRequest,
    responseType: flex_time_flex_time_pb.ValidateAssignResponse,
  };

  static readonly ArchiveFlexTimeRegistration = {
    methodName: "ArchiveFlexTimeRegistration",
    service: FlexTimeManager,
    requestStream: false,
    responseStream: false,
    requestType: flex_time_flex_time_pb.ArchiveFlexTimeRegistrationRequest,
    responseType: flex_time_flex_time_pb.ArchiveFlexTimeRegistrationResponse,
  };

}

export class FlexTimeManagerClient {
  listFlexTimePeriods() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  listMinimalFlexTimePeriods() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  upsertFlexTimePeriod() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  cloneFlexTimePeriod() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  getFlexTimePeriod() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  deleteFlexTimePeriod() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  listFlexTimeActivities() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  getFlexTimeActivity() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  upsertFlexTimeActivity() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  deleteFlexTimeActivity() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  archiveFlexTimeActivity() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  listFlexTimeActivityInstances() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  upsertFlexTimeActivityInstance() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  deleteFlexTimeActivityInstance() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  getFlexTimeActivityTypes() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  validateAssign() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  archiveFlexTimeRegistration() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
}
