import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule } from '@angular/forms';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRippleModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';

import { MgEmptyStateModule } from '@app/src/app/components/EmptyState';
import { MgFormFieldModule } from '@app/src/app/form-field';
import { MgIconModule } from '@app/src/app/icon';
import { PermissionsModule } from '@app/src/app/permissions';
import { MgPipesModule } from '@app/src/app/pipes';
import { MgSpinnerModule } from '@app/src/app/spinner';

import { AdvancedTooltipModule } from '@shared/components/advanced-tooltip';
import { FiltersFormModule } from '@shared/components/filters-form';
import { FormModule } from '@shared/components/form';
import { GenericModule } from '@shared/components/generic';
import { OverlayModule } from '@shared/components/modal-overlay';
import { PaginatorModule } from '@shared/components/paginator';
import { SystemAlertModule } from '@shared/components/system-alert';
import { SystemAlertSnackBarModule } from '@shared/components/system-alert-snackbar';
import { TextModule } from '@shared/components/text/text.module';
import { TooltipModule } from '@shared/components/tooltip';
import { UserListFilterModule } from '@shared/components/user-list-filter/user-list-filter.module';
import { StudentPictureModule } from '@shared/directives/student-picture/student-picture.module';
import { ArrayPipesModule } from '@shared/pipes';

import { PsDialogComponent } from './components/ps-dialog/ps-dialog.component';
import { PsModalComponent } from './components/ps-modal/ps-modal.component';
import { PsSearchComponent } from './components/ps-search/ps-search.component';
import { PsSummaryComponent } from './components/ps-summary/ps-summary.component';
import { PsTableComponent } from './components/ps-table/ps-table.component';
import { PeopleSelectorComponent } from './people-selector.component';
import { PsCollectionSearchImplService } from './services';
import { getPeopleSelectorFormProviders } from './utils';

@NgModule({
  imports: [
    // Minga dependencies
    MgIconModule,
    MgSpinnerModule,
    OverlayModule,
    MgEmptyStateModule,
    MgFormFieldModule,
    FormModule,
    TextModule,
    ArrayPipesModule,
    SystemAlertModule,
    SystemAlertSnackBarModule,
    MatSnackBarModule,
    GenericModule,
    TooltipModule,
    AdvancedTooltipModule,
    PaginatorModule,
    MgPipesModule,
    UserListFilterModule,
    PermissionsModule,
    FiltersFormModule,
    StudentPictureModule,

    // External dependencies
    CommonModule,
    FlexLayoutModule,
    MatExpansionModule,
    MatCheckboxModule,
    FormsModule,
    MatTabsModule,
    MatButtonToggleModule,
    MatPaginatorModule,
    MatTableModule,
    MatSortModule,
    MatInputModule,
    MatRippleModule,
    MatDialogModule,
  ],
  declarations: [
    PeopleSelectorComponent,
    PsModalComponent,
    PsTableComponent,
    PsSearchComponent,
    PsDialogComponent,
    PsSummaryComponent,
  ],
  providers: [
    PsCollectionSearchImplService,
    ...getPeopleSelectorFormProviders(),
  ],
})
export class PeopleSelectorModule {}
