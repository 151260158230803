import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatRippleModule } from '@angular/material/core';

import { ClippedHtmlModule } from '@app/src/app/components/ClippedHtml';
import { MgAvatarModule } from '@app/src/app/elements/MgAvatar';
import { MgLikeModule } from '@app/src/app/elements/MgLike';
import { MgPersonMinimalModule } from '@app/src/app/elements/MgPersonMinimal';
import { MgIconModule } from '@app/src/app/icon';
import { MgPipesModule } from '@app/src/app/pipes';
import { MgSpinnerModule } from '@app/src/app/spinner';

import { CommentViewComponent } from './CommentView.component';

@NgModule({
  imports: [
    // Minga dependencies
    MgAvatarModule,
    MgLikeModule,
    MgSpinnerModule,
    MgIconModule,
    MgPersonMinimalModule,
    MgPipesModule,
    ClippedHtmlModule,

    // External dependencies
    CommonModule,
    MatRippleModule,
    FlexLayoutModule,
  ],
  declarations: [CommentViewComponent],
  exports: [CommentViewComponent],
})
export class CommentViewModule {}
