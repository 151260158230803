import {
  IConversation,
  IConversationWithReadStatus,
  IMessage,
} from 'libs/domain';
import { messaging_pb } from 'libs/generated-grpc-ts';

export function getLastMessageBodyPreview(message: IMessage) {
  if (message.body) {
    return message.body;
  } else if (message.attachmentList.length) {
    const attachmentLength = message.attachmentList.length;
    // once we have other attachments update the type
    let type = 'picture';

    return attachmentLength + ` new ${type}${attachmentLength > 1 ? 's' : ''}!`;
  }
  return '';
}

export namespace ConversationPreviewMapper {
  /**
   * Convert an IConversation and IMessage into a `ConversationPreview` message
   * @param readStatus - Extra property that `IMessage` and `IConversation`
   *                     cannot determine. This should be set in context.
   */
  export function fromIConversationAndIMessage(
    conv: IConversation,
    latestMsg: IMessage | null,
    readStatus: boolean,
  ): messaging_pb.ConversationPreview {
    const msg = new messaging_pb.ConversationPreview();

    msg.setConversationId(conv.id);
    msg.setParticipantPeopleList(([] as string[]).concat(conv.participants));

    if (latestMsg) {
      msg.setLastMessageBody(getLastMessageBodyPreview(latestMsg));
      msg.setLastMessageTimestamp(latestMsg.createdDate.getTime());
    }
    msg.setReadStatus(readStatus);

    return msg;
  }

  export function toIConversation(
    msg: messaging_pb.ConversationPreview,
  ): IConversation {
    const lastMessageTimestamp = msg.getLastMessageTimestamp();
    return {
      id: msg.getConversationId(),
      lastMessageDate: lastMessageTimestamp
        ? new Date(lastMessageTimestamp)
        : null,
      participants: msg.getParticipantPeopleList(),
    };
  }

  export function toIConversationWithReadStatus(
    msg: messaging_pb.ConversationPreview,
  ): IConversationWithReadStatus {
    return {
      ...toIConversation(msg),
      readStatus: msg.getReadStatus(),
      lastMessageBody: msg.getLastMessageBody(),
    };
  }
}
