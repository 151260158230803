import { IPeopleCollectionPersonDetails } from 'libs/domain';
import { people_collection_pb } from 'libs/generated-grpc-ts';

export const toProto = (
  person: IPeopleCollectionPersonDetails,
): people_collection_pb.PeopleCollectionPersonDetails => {
  const msg = new people_collection_pb.PeopleCollectionPersonDetails();
  msg.setPersonHash(person.personHash);
  msg.setProfileImageUrl(person.profileImageUrl);
  msg.setDisplayName(person.displayName);
  msg.setFirstName(person.firstName);
  msg.setLastName(person.lastName);

  msg.setBadgeIconUrl(person.badgeIconUrl);
  msg.setBadgeIconColor(person.badgeIconColor);
  msg.setBadgeRoleName(person.badgeRoleName);
  msg.setRoleType(person.roleType);
  msg.setDmEnabled(person.dmEnabled);
  msg.setStudentId(person.studentId);
  msg.setEmail(person.email);

  return msg;
};

export const fromProto = (
  person: people_collection_pb.PeopleCollectionPersonDetails,
): IPeopleCollectionPersonDetails => {
  return {
    personHash: person.getPersonHash(),
    profileImageUrl: person.getProfileImageUrl(),
    displayName: person.getDisplayName(),
    firstName: person.getFirstName(),
    lastName: person.getLastName(),
    badgeIconColor: person.getBadgeIconColor(),
    badgeIconUrl: person.getBadgeIconUrl(),
    badgeRoleName: person.getBadgeRoleName(),
    roleType: person.getRoleType(),
    dmEnabled: person.getDmEnabled(),
    studentId: person.getStudentId(),
    email: person.getEmail(),
  };
};
