import { Injectable } from '@angular/core';

import { BannerType } from 'libs/domain';
import { IMembershipList } from 'libs/domain';
import { gateway } from 'libs/generated-grpc-web';

@Injectable({ providedIn: 'root' })
export class StickerBannersService {
  constructor(
    private _bannerLibrary: gateway.banner_ng_grpc_pb.BannerLibrary,
  ) {}

  public async getStickerImageList(): Promise<gateway.banner_pb.Banner[]> {
    const request = new gateway.banner_pb.BannerLibraryGetAllByType();
    request.setType(BannerType.STICKER);

    const response = await this._bannerLibrary.getAllByType(request);
    return response.getBannersList();
  }

  public getStickerPath(item: IMembershipList) {
    if (item?.assetPath) {
      return item.assetPath;
    }

    if (item?.bannerImage) {
      return item.bannerImage;
    }

    return '';
  }
}
