// source: gateway/moderation.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

import * as jspb from 'google-protobuf';
import * as common_legacy_pb from '../common/legacy_pb';
import * as common_stream_pb from '../common/stream_pb';
import * as image_image_pb from '../image/image_pb';
export namespace ModerationResult {
  export interface AsObject {
    allPassed: boolean;
    allTextContentPassed: boolean;
    allImageContentPassed: boolean;
    allImageTextContentPassed: boolean;
    detailsList: DetailedModerationResult.ProviderDetails.AsObject[];
  }
}
export class ModerationResult extends jspb.Message {
  static readonly displayName = "ModerationResult";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [5];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, ModerationResult.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): ModerationResult.AsObject {
    return ModerationResult.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!ModerationResult} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: ModerationResult) {
    var f: any;
    var obj: any = {
      allPassed: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
      allTextContentPassed: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
      allImageContentPassed: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
      allImageTextContentPassed: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
      detailsList: jspb.Message.toObjectList(msg.getDetailsList(),
      DetailedModerationResult.ProviderDetails.toObject, includeInstance)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!ModerationResult}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new ModerationResult;
    return ModerationResult.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!ModerationResult} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!ModerationResult}
   */
  static deserializeBinaryFromReader(msg: ModerationResult, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {boolean} */ (reader.readBool());
        msg.setAllPassed(value1);
        break;
      case 2:
        var value2 = /** @type {boolean} */ (reader.readBool());
        msg.setAllTextContentPassed(value2);
        break;
      case 3:
        var value3 = /** @type {boolean} */ (reader.readBool());
        msg.setAllImageContentPassed(value3);
        break;
      case 4:
        var value4 = /** @type {boolean} */ (reader.readBool());
        msg.setAllImageTextContentPassed(value4);
        break;
      case 5:
        var value5 = new DetailedModerationResult.ProviderDetails;
        reader.readMessage(value5,DetailedModerationResult.ProviderDetails.deserializeBinaryFromReader);
        msg.addDetails(value5);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!ModerationResult} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: ModerationResult, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getAllPassed();
    if (f) {
      writer.writeBool(
        1,
        f
      );
    }
    f = message.getAllTextContentPassed();
    if (f) {
      writer.writeBool(
        2,
        f
      );
    }
    f = message.getAllImageContentPassed();
    if (f) {
      writer.writeBool(
        3,
        f
      );
    }
    f = message.getAllImageTextContentPassed();
    if (f) {
      writer.writeBool(
        4,
        f
      );
    }
    f = message.getDetailsList();
    if (f.length > 0) {
      writer.writeRepeatedMessage(
        5,
        f,
        DetailedModerationResult.ProviderDetails.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    ModerationResult.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional bool all_passed = 1;
   * @return {boolean}
   */
  getAllPassed(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
  };


  /** @param {boolean} value */
  setAllPassed(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 1, value);
  }


  /**
   * optional bool all_text_content_passed = 2;
   * @return {boolean}
   */
  getAllTextContentPassed(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
  };


  /** @param {boolean} value */
  setAllTextContentPassed(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 2, value);
  }


  /**
   * optional bool all_image_content_passed = 3;
   * @return {boolean}
   */
  getAllImageContentPassed(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
  };


  /** @param {boolean} value */
  setAllImageContentPassed(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 3, value);
  }


  /**
   * optional bool all_image_text_content_passed = 4;
   * @return {boolean}
   */
  getAllImageTextContentPassed(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
  };


  /** @param {boolean} value */
  setAllImageTextContentPassed(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 4, value);
  }


  /**
   * repeated DetailedModerationResult.ProviderDetails details = 5;
   * @return {!Array<!DetailedModerationResult.ProviderDetails>}
   */
  getDetailsList(): DetailedModerationResult.ProviderDetails[] {
    return /** @type{!Array<!DetailedModerationResult.ProviderDetails>} */ (
      jspb.Message.getRepeatedWrapperField(this, DetailedModerationResult.ProviderDetails, 5));
  }


  /** @param {!Array<!DetailedModerationResult.ProviderDetails>} value */
  setDetailsList(value?: DetailedModerationResult.ProviderDetails[]) {
    jspb.Message.setRepeatedWrapperField(this, 5, value);
  }


  /**
   * @param {!DetailedModerationResult.ProviderDetails=} opt_value
   * @param {number=} opt_index
   * @return {!DetailedModerationResult.ProviderDetails}
   */
  addDetails(opt_value?: DetailedModerationResult.ProviderDetails, opt_index?: number): DetailedModerationResult.ProviderDetails {
    return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, DetailedModerationResult.ProviderDetails, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearDetailsList() {
    this.setDetailsList([]);
  }


}
export namespace DetailedModerationResult {
  export interface AsObject {
    title: string;
    parentContextHash: string;
    contentContextHash: string;
    mingaHash: string;
    mingaName: string;
    contentAuthorHash: string;
    timestamp: number;
    contentAuthorFirstName: string;
    contentAuthorLastName: string;
    contentType: string;
    contentHistoryList: DetailedModerationResult.ContentHistory.AsObject[];
    status: string;
    actionContextHash: string;
    galleryPhotoUuid: string;
  }
}
export class DetailedModerationResult extends jspb.Message {
  static readonly displayName = "DetailedModerationResult";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [11];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, DetailedModerationResult.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): DetailedModerationResult.AsObject {
    return DetailedModerationResult.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!DetailedModerationResult} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: DetailedModerationResult) {
    var f: any;
    var obj: any = {
      title: jspb.Message.getFieldWithDefault(msg, 1, ""),
      parentContextHash: jspb.Message.getFieldWithDefault(msg, 2, ""),
      contentContextHash: jspb.Message.getFieldWithDefault(msg, 3, ""),
      mingaHash: jspb.Message.getFieldWithDefault(msg, 4, ""),
      mingaName: jspb.Message.getFieldWithDefault(msg, 5, ""),
      contentAuthorHash: jspb.Message.getFieldWithDefault(msg, 6, ""),
      timestamp: jspb.Message.getFieldWithDefault(msg, 7, 0),
      contentAuthorFirstName: jspb.Message.getFieldWithDefault(msg, 8, ""),
      contentAuthorLastName: jspb.Message.getFieldWithDefault(msg, 9, ""),
      contentType: jspb.Message.getFieldWithDefault(msg, 10, ""),
      contentHistoryList: jspb.Message.toObjectList(msg.getContentHistoryList(),
      DetailedModerationResult.ContentHistory.toObject, includeInstance),
      status: jspb.Message.getFieldWithDefault(msg, 12, ""),
      actionContextHash: jspb.Message.getFieldWithDefault(msg, 13, ""),
      galleryPhotoUuid: jspb.Message.getFieldWithDefault(msg, 14, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!DetailedModerationResult}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new DetailedModerationResult;
    return DetailedModerationResult.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!DetailedModerationResult} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!DetailedModerationResult}
   */
  static deserializeBinaryFromReader(msg: DetailedModerationResult, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setTitle(value1);
        break;
      case 2:
        var value2 = /** @type {string} */ (reader.readString());
        msg.setParentContextHash(value2);
        break;
      case 3:
        var value3 = /** @type {string} */ (reader.readString());
        msg.setContentContextHash(value3);
        break;
      case 4:
        var value4 = /** @type {string} */ (reader.readString());
        msg.setMingaHash(value4);
        break;
      case 5:
        var value5 = /** @type {string} */ (reader.readString());
        msg.setMingaName(value5);
        break;
      case 6:
        var value6 = /** @type {string} */ (reader.readString());
        msg.setContentAuthorHash(value6);
        break;
      case 7:
        var value7 = /** @type {number} */ (reader.readUint64());
        msg.setTimestamp(value7);
        break;
      case 8:
        var value8 = /** @type {string} */ (reader.readString());
        msg.setContentAuthorFirstName(value8);
        break;
      case 9:
        var value9 = /** @type {string} */ (reader.readString());
        msg.setContentAuthorLastName(value9);
        break;
      case 10:
        var value10 = /** @type {string} */ (reader.readString());
        msg.setContentType(value10);
        break;
      case 11:
        var value11 = new DetailedModerationResult.ContentHistory;
        reader.readMessage(value11,DetailedModerationResult.ContentHistory.deserializeBinaryFromReader);
        msg.addContentHistory(value11);
        break;
      case 12:
        var value12 = /** @type {string} */ (reader.readString());
        msg.setStatus(value12);
        break;
      case 13:
        var value13 = /** @type {string} */ (reader.readString());
        msg.setActionContextHash(value13);
        break;
      case 14:
        var value14 = /** @type {string} */ (reader.readString());
        msg.setGalleryPhotoUuid(value14);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!DetailedModerationResult} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: DetailedModerationResult, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getTitle();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
    f = message.getParentContextHash();
    if (f.length > 0) {
      writer.writeString(
        2,
        f
      );
    }
    f = message.getContentContextHash();
    if (f.length > 0) {
      writer.writeString(
        3,
        f
      );
    }
    f = message.getMingaHash();
    if (f.length > 0) {
      writer.writeString(
        4,
        f
      );
    }
    f = message.getMingaName();
    if (f.length > 0) {
      writer.writeString(
        5,
        f
      );
    }
    f = message.getContentAuthorHash();
    if (f.length > 0) {
      writer.writeString(
        6,
        f
      );
    }
    f = message.getTimestamp();
    if (f !== 0) {
      writer.writeUint64(
        7,
        f
      );
    }
    f = message.getContentAuthorFirstName();
    if (f.length > 0) {
      writer.writeString(
        8,
        f
      );
    }
    f = message.getContentAuthorLastName();
    if (f.length > 0) {
      writer.writeString(
        9,
        f
      );
    }
    f = message.getContentType();
    if (f.length > 0) {
      writer.writeString(
        10,
        f
      );
    }
    f = message.getContentHistoryList();
    if (f.length > 0) {
      writer.writeRepeatedMessage(
        11,
        f,
        DetailedModerationResult.ContentHistory.serializeBinaryToWriter
      );
    }
    f = message.getStatus();
    if (f.length > 0) {
      writer.writeString(
        12,
        f
      );
    }
    f = message.getActionContextHash();
    if (f.length > 0) {
      writer.writeString(
        13,
        f
      );
    }
    f = message.getGalleryPhotoUuid();
    if (f.length > 0) {
      writer.writeString(
        14,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    DetailedModerationResult.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string title = 1;
   * @return {string}
   */
  getTitle(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setTitle(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


  /**
   * optional string parent_context_hash = 2;
   * @return {string}
   */
  getParentContextHash(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
  };


  /** @param {string} value */
  setParentContextHash(value: string) {
    jspb.Message.setProto3StringField(this, 2, value);
  }


  /**
   * optional string content_context_hash = 3;
   * @return {string}
   */
  getContentContextHash(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
  };


  /** @param {string} value */
  setContentContextHash(value: string) {
    jspb.Message.setProto3StringField(this, 3, value);
  }


  /**
   * optional string minga_hash = 4;
   * @return {string}
   */
  getMingaHash(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
  };


  /** @param {string} value */
  setMingaHash(value: string) {
    jspb.Message.setProto3StringField(this, 4, value);
  }


  /**
   * optional string minga_name = 5;
   * @return {string}
   */
  getMingaName(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
  };


  /** @param {string} value */
  setMingaName(value: string) {
    jspb.Message.setProto3StringField(this, 5, value);
  }


  /**
   * optional string content_author_hash = 6;
   * @return {string}
   */
  getContentAuthorHash(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
  };


  /** @param {string} value */
  setContentAuthorHash(value: string) {
    jspb.Message.setProto3StringField(this, 6, value);
  }


  /**
   * optional uint64 timestamp = 7;
   * @return {number}
   */
  getTimestamp(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
  };


  /** @param {number} value */
  setTimestamp(value: number) {
    jspb.Message.setProto3IntField(this, 7, value);
  }


  /**
   * optional string content_author_first_name = 8;
   * @return {string}
   */
  getContentAuthorFirstName(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
  };


  /** @param {string} value */
  setContentAuthorFirstName(value: string) {
    jspb.Message.setProto3StringField(this, 8, value);
  }


  /**
   * optional string content_author_last_name = 9;
   * @return {string}
   */
  getContentAuthorLastName(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
  };


  /** @param {string} value */
  setContentAuthorLastName(value: string) {
    jspb.Message.setProto3StringField(this, 9, value);
  }


  /**
   * optional string content_type = 10;
   * @return {string}
   */
  getContentType(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
  };


  /** @param {string} value */
  setContentType(value: string) {
    jspb.Message.setProto3StringField(this, 10, value);
  }


  /**
   * repeated ContentHistory content_history = 11;
   * @return {!Array<!DetailedModerationResult.ContentHistory>}
   */
  getContentHistoryList(): DetailedModerationResult.ContentHistory[] {
    return /** @type{!Array<!DetailedModerationResult.ContentHistory>} */ (
      jspb.Message.getRepeatedWrapperField(this, DetailedModerationResult.ContentHistory, 11));
  }


  /** @param {!Array<!DetailedModerationResult.ContentHistory>} value */
  setContentHistoryList(value?: DetailedModerationResult.ContentHistory[]) {
    jspb.Message.setRepeatedWrapperField(this, 11, value);
  }


  /**
   * @param {!DetailedModerationResult.ContentHistory=} opt_value
   * @param {number=} opt_index
   * @return {!DetailedModerationResult.ContentHistory}
   */
  addContentHistory(opt_value?: DetailedModerationResult.ContentHistory, opt_index?: number): DetailedModerationResult.ContentHistory {
    return jspb.Message.addToRepeatedWrapperField(this, 11, opt_value, DetailedModerationResult.ContentHistory, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearContentHistoryList() {
    this.setContentHistoryList([]);
  }


  /**
   * optional string status = 12;
   * @return {string}
   */
  getStatus(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
  };


  /** @param {string} value */
  setStatus(value: string) {
    jspb.Message.setProto3StringField(this, 12, value);
  }


  /**
   * optional string action_context_hash = 13;
   * @return {string}
   */
  getActionContextHash(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
  };


  /** @param {string} value */
  setActionContextHash(value: string) {
    jspb.Message.setProto3StringField(this, 13, value);
  }


  /**
   * optional string gallery_photo_uuid = 14;
   * @return {string}
   */
  getGalleryPhotoUuid(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
  };


  /** @param {string} value */
  setGalleryPhotoUuid(value: string) {
    jspb.Message.setProto3StringField(this, 14, value);
  }


}
export namespace DetailedModerationResult {
export namespace Pair {
  export interface AsObject {
    key: string;
    value: string;
  }
}
export class Pair extends jspb.Message {
  static readonly displayName = "DetailedModerationResult.Pair";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): Pair.AsObject {
    return Pair.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!Pair} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: Pair) {
    var f: any;
    var obj: any = {
      key: jspb.Message.getFieldWithDefault(msg, 1, ""),
      value: jspb.Message.getFieldWithDefault(msg, 2, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!Pair}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new Pair;
    return Pair.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!DetailedModerationResult.Pair} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!DetailedModerationResult.Pair}
   */
  static deserializeBinaryFromReader(msg: Pair, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setKey(value1);
        break;
      case 2:
        var value2 = /** @type {string} */ (reader.readString());
        msg.setValue(value2);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!DetailedModerationResult.Pair} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: DetailedModerationResult.Pair, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getKey();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
    f = message.getValue();
    if (f.length > 0) {
      writer.writeString(
        2,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    DetailedModerationResult.Pair.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string key = 1;
   * @return {string}
   */
  getKey(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setKey(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


  /**
   * optional string value = 2;
   * @return {string}
   */
  getValue(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
  };


  /** @param {string} value */
  setValue(value: string) {
    jspb.Message.setProto3StringField(this, 2, value);
  }


}
} // namespace DetailedModerationResult
export namespace DetailedModerationResult {
export namespace ContentHistory {
  export interface AsObject {
    personId: number;
    timestamp: number;
    personDisplayName: string;
    reasonIndex: number;
    status: string;
    detailsList: DetailedModerationResult.ProviderDetails.AsObject[];
  }
}
export class ContentHistory extends jspb.Message {
  static readonly displayName = "DetailedModerationResult.ContentHistory";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [6];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, DetailedModerationResult.ContentHistory.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): ContentHistory.AsObject {
    return ContentHistory.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!ContentHistory} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: ContentHistory) {
    var f: any;
    var obj: any = {
      personid: jspb.Message.getFieldWithDefault(msg, 1, 0),
      timestamp: jspb.Message.getFieldWithDefault(msg, 2, 0),
      personDisplayName: jspb.Message.getFieldWithDefault(msg, 3, ""),
      reasonindex: jspb.Message.getFieldWithDefault(msg, 4, 0),
      status: jspb.Message.getFieldWithDefault(msg, 5, ""),
      detailsList: jspb.Message.toObjectList(msg.getDetailsList(),
      DetailedModerationResult.ProviderDetails.toObject, includeInstance)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!ContentHistory}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new ContentHistory;
    return ContentHistory.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!DetailedModerationResult.ContentHistory} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!DetailedModerationResult.ContentHistory}
   */
  static deserializeBinaryFromReader(msg: ContentHistory, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {number} */ (reader.readUint32());
        msg.setPersonid(value1);
        break;
      case 2:
        var value2 = /** @type {number} */ (reader.readUint64());
        msg.setTimestamp(value2);
        break;
      case 3:
        var value3 = /** @type {string} */ (reader.readString());
        msg.setPersonDisplayName(value3);
        break;
      case 4:
        var value4 = /** @type {number} */ (reader.readSint32());
        msg.setReasonindex(value4);
        break;
      case 5:
        var value5 = /** @type {string} */ (reader.readString());
        msg.setStatus(value5);
        break;
      case 6:
        var value6 = new DetailedModerationResult.ProviderDetails;
        reader.readMessage(value6,DetailedModerationResult.ProviderDetails.deserializeBinaryFromReader);
        msg.addDetails(value6);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!DetailedModerationResult.ContentHistory} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: DetailedModerationResult.ContentHistory, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getPersonid();
    if (f !== 0) {
      writer.writeUint32(
        1,
        f
      );
    }
    f = message.getTimestamp();
    if (f !== 0) {
      writer.writeUint64(
        2,
        f
      );
    }
    f = message.getPersonDisplayName();
    if (f.length > 0) {
      writer.writeString(
        3,
        f
      );
    }
    f = message.getReasonindex();
    if (f !== 0) {
      writer.writeSint32(
        4,
        f
      );
    }
    f = message.getStatus();
    if (f.length > 0) {
      writer.writeString(
        5,
        f
      );
    }
    f = message.getDetailsList();
    if (f.length > 0) {
      writer.writeRepeatedMessage(
        6,
        f,
        DetailedModerationResult.ProviderDetails.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    DetailedModerationResult.ContentHistory.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional uint32 personId = 1;
   * @return {number}
   */
  getPersonid(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
  };


  /** @param {number} value */
  setPersonid(value: number) {
    jspb.Message.setProto3IntField(this, 1, value);
  }


  /**
   * optional uint64 timestamp = 2;
   * @return {number}
   */
  getTimestamp(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
  };


  /** @param {number} value */
  setTimestamp(value: number) {
    jspb.Message.setProto3IntField(this, 2, value);
  }


  /**
   * optional string person_display_name = 3;
   * @return {string}
   */
  getPersonDisplayName(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
  };


  /** @param {string} value */
  setPersonDisplayName(value: string) {
    jspb.Message.setProto3StringField(this, 3, value);
  }


  /**
   * optional sint32 reasonIndex = 4;
   * @return {number}
   */
  getReasonindex(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
  };


  /** @param {number} value */
  setReasonindex(value: number) {
    jspb.Message.setProto3IntField(this, 4, value);
  }


  /**
   * optional string status = 5;
   * @return {string}
   */
  getStatus(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
  };


  /** @param {string} value */
  setStatus(value: string) {
    jspb.Message.setProto3StringField(this, 5, value);
  }


  /**
   * repeated ProviderDetails details = 6;
   * @return {!Array<!DetailedModerationResult.ProviderDetails>}
   */
  getDetailsList(): DetailedModerationResult.ProviderDetails[] {
    return /** @type{!Array<!DetailedModerationResult.ProviderDetails>} */ (
      jspb.Message.getRepeatedWrapperField(this, DetailedModerationResult.ProviderDetails, 6));
  }


  /** @param {!Array<!DetailedModerationResult.ProviderDetails>} value */
  setDetailsList(value?: DetailedModerationResult.ProviderDetails[]) {
    jspb.Message.setRepeatedWrapperField(this, 6, value);
  }


  /**
   * @param {!DetailedModerationResult.ProviderDetails=} opt_value
   * @param {number=} opt_index
   * @return {!DetailedModerationResult.ProviderDetails}
   */
  addDetails(opt_value?: DetailedModerationResult.ProviderDetails, opt_index?: number): DetailedModerationResult.ProviderDetails {
    return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, DetailedModerationResult.ProviderDetails, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearDetailsList() {
    this.setDetailsList([]);
  }


}
} // namespace DetailedModerationResult
export namespace DetailedModerationResult {
export namespace ProviderDetails {
  export interface AsObject {
    tagsList: DetailedModerationResult.Pair.AsObject[];
    fileUrl: string;
    index: number;
    imageTagsList: DetailedModerationResult.Pair.AsObject[];
    reasonIndex: number;
  }
}
export class ProviderDetails extends jspb.Message {
  static readonly displayName = "DetailedModerationResult.ProviderDetails";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [1,4];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, DetailedModerationResult.ProviderDetails.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): ProviderDetails.AsObject {
    return ProviderDetails.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!ProviderDetails} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: ProviderDetails) {
    var f: any;
    var obj: any = {
      tagsList: jspb.Message.toObjectList(msg.getTagsList(),
      DetailedModerationResult.Pair.toObject, includeInstance),
      fileurl: jspb.Message.getFieldWithDefault(msg, 2, ""),
      index: jspb.Message.getFieldWithDefault(msg, 3, 0),
      imageTagsList: jspb.Message.toObjectList(msg.getImageTagsList(),
      DetailedModerationResult.Pair.toObject, includeInstance),
      reasonIndex: jspb.Message.getFieldWithDefault(msg, 5, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!ProviderDetails}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new ProviderDetails;
    return ProviderDetails.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!DetailedModerationResult.ProviderDetails} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!DetailedModerationResult.ProviderDetails}
   */
  static deserializeBinaryFromReader(msg: ProviderDetails, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new DetailedModerationResult.Pair;
        reader.readMessage(value1,DetailedModerationResult.Pair.deserializeBinaryFromReader);
        msg.addTags(value1);
        break;
      case 2:
        var value2 = /** @type {string} */ (reader.readString());
        msg.setFileurl(value2);
        break;
      case 3:
        var value3 = /** @type {number} */ (reader.readSint32());
        msg.setIndex(value3);
        break;
      case 4:
        var value4 = new DetailedModerationResult.Pair;
        reader.readMessage(value4,DetailedModerationResult.Pair.deserializeBinaryFromReader);
        msg.addImageTags(value4);
        break;
      case 5:
        var value5 = /** @type {number} */ (reader.readInt32());
        msg.setReasonIndex(value5);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!DetailedModerationResult.ProviderDetails} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: DetailedModerationResult.ProviderDetails, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getTagsList();
    if (f.length > 0) {
      writer.writeRepeatedMessage(
        1,
        f,
        DetailedModerationResult.Pair.serializeBinaryToWriter
      );
    }
    f = message.getFileurl();
    if (f.length > 0) {
      writer.writeString(
        2,
        f
      );
    }
    f = message.getIndex();
    if (f !== 0) {
      writer.writeSint32(
        3,
        f
      );
    }
    f = message.getImageTagsList();
    if (f.length > 0) {
      writer.writeRepeatedMessage(
        4,
        f,
        DetailedModerationResult.Pair.serializeBinaryToWriter
      );
    }
    f = message.getReasonIndex();
    if (f !== 0) {
      writer.writeInt32(
        5,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    DetailedModerationResult.ProviderDetails.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * repeated Pair tags = 1;
   * @return {!Array<!DetailedModerationResult.Pair>}
   */
  getTagsList(): DetailedModerationResult.Pair[] {
    return /** @type{!Array<!DetailedModerationResult.Pair>} */ (
      jspb.Message.getRepeatedWrapperField(this, DetailedModerationResult.Pair, 1));
  }


  /** @param {!Array<!DetailedModerationResult.Pair>} value */
  setTagsList(value?: DetailedModerationResult.Pair[]) {
    jspb.Message.setRepeatedWrapperField(this, 1, value);
  }


  /**
   * @param {!DetailedModerationResult.Pair=} opt_value
   * @param {number=} opt_index
   * @return {!DetailedModerationResult.Pair}
   */
  addTags(opt_value?: DetailedModerationResult.Pair, opt_index?: number): DetailedModerationResult.Pair {
    return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, DetailedModerationResult.Pair, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearTagsList() {
    this.setTagsList([]);
  }


  /**
   * optional string fileUrl = 2;
   * @return {string}
   */
  getFileurl(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
  };


  /** @param {string} value */
  setFileurl(value: string) {
    jspb.Message.setProto3StringField(this, 2, value);
  }


  /**
   * optional sint32 index = 3;
   * @return {number}
   */
  getIndex(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
  };


  /** @param {number} value */
  setIndex(value: number) {
    jspb.Message.setProto3IntField(this, 3, value);
  }


  /**
   * repeated Pair image_tags = 4;
   * @return {!Array<!DetailedModerationResult.Pair>}
   */
  getImageTagsList(): DetailedModerationResult.Pair[] {
    return /** @type{!Array<!DetailedModerationResult.Pair>} */ (
      jspb.Message.getRepeatedWrapperField(this, DetailedModerationResult.Pair, 4));
  }


  /** @param {!Array<!DetailedModerationResult.Pair>} value */
  setImageTagsList(value?: DetailedModerationResult.Pair[]) {
    jspb.Message.setRepeatedWrapperField(this, 4, value);
  }


  /**
   * @param {!DetailedModerationResult.Pair=} opt_value
   * @param {number=} opt_index
   * @return {!DetailedModerationResult.Pair}
   */
  addImageTags(opt_value?: DetailedModerationResult.Pair, opt_index?: number): DetailedModerationResult.Pair {
    return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, DetailedModerationResult.Pair, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearImageTagsList() {
    this.setImageTagsList([]);
  }


  /**
   * optional int32 reason_index = 5;
   * @return {number}
   */
  getReasonIndex(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
  };


  /** @param {number} value */
  setReasonIndex(value: number) {
    jspb.Message.setProto3IntField(this, 5, value);
  }


}
} // namespace DetailedModerationResult
export namespace StreamDetailedModerationResult {
  export interface AsObject {
    itemMetadata?: common_stream_pb.StreamItemMetadata.AsObject;
    item?: DetailedModerationResult.AsObject;
  }
}
export class StreamDetailedModerationResult extends jspb.Message {
  static readonly displayName = "StreamDetailedModerationResult";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): StreamDetailedModerationResult.AsObject {
    return StreamDetailedModerationResult.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!StreamDetailedModerationResult} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: StreamDetailedModerationResult) {
    var f: any;
    var obj: any = {
      itemMetadata: (f = msg.getItemMetadata()) && common_stream_pb.StreamItemMetadata.toObject(includeInstance, f),
      item: (f = msg.getItem()) && DetailedModerationResult.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!StreamDetailedModerationResult}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new StreamDetailedModerationResult;
    return StreamDetailedModerationResult.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!StreamDetailedModerationResult} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!StreamDetailedModerationResult}
   */
  static deserializeBinaryFromReader(msg: StreamDetailedModerationResult, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new common_stream_pb.StreamItemMetadata;
        reader.readMessage(value1,common_stream_pb.StreamItemMetadata.deserializeBinaryFromReader);
        msg.setItemMetadata(value1);
        break;
      case 2:
        var value2 = new DetailedModerationResult;
        reader.readMessage(value2,DetailedModerationResult.deserializeBinaryFromReader);
        msg.setItem(value2);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!StreamDetailedModerationResult} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: StreamDetailedModerationResult, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getItemMetadata();
    if (f != null) {
      writer.writeMessage(
        1,
        f,
        common_stream_pb.StreamItemMetadata.serializeBinaryToWriter
      );
    }
    f = message.getItem();
    if (f != null) {
      writer.writeMessage(
        2,
        f,
        DetailedModerationResult.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    StreamDetailedModerationResult.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional StreamItemMetadata item_metadata = 1;
   * @return {?StreamItemMetadata}
   */
  getItemMetadata(): common_stream_pb.StreamItemMetadata {
    return /** @type{?StreamItemMetadata} */ (
      jspb.Message.getWrapperField(this, common_stream_pb.StreamItemMetadata, 1));
  }


  /** @param {?StreamItemMetadata|undefined} value */
  setItemMetadata(value?: common_stream_pb.StreamItemMetadata) {
    jspb.Message.setWrapperField(this, 1, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearItemMetadata() {
    this.setItemMetadata(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasItemMetadata(): boolean {
    return jspb.Message.getField(this, 1) != null;
  }


  /**
   * optional DetailedModerationResult item = 2;
   * @return {?DetailedModerationResult}
   */
  getItem(): DetailedModerationResult {
    return /** @type{?DetailedModerationResult} */ (
      jspb.Message.getWrapperField(this, DetailedModerationResult, 2));
  }


  /** @param {?DetailedModerationResult|undefined} value */
  setItem(value?: DetailedModerationResult) {
    jspb.Message.setWrapperField(this, 2, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearItem() {
    this.setItem(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasItem(): boolean {
    return jspb.Message.getField(this, 2) != null;
  }


}
