// source: messaging/realtime.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

import * as jspb from 'google-protobuf';
import * as messaging_messaging_pb from '../messaging/messaging_pb';
export namespace NewDirectMessage {
  export interface AsObject {
    conversationId: number;
    messageId: number;
    body: string;
    timestamp: number;
    authorPersonHash: string;
    attachmentList: messaging_messaging_pb.Message.MessageAttachment.AsObject[];
  }
}
export class NewDirectMessage extends jspb.Message {
  static readonly displayName = "NewDirectMessage";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [6];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, NewDirectMessage.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): NewDirectMessage.AsObject {
    return NewDirectMessage.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!NewDirectMessage} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: NewDirectMessage) {
    var f: any;
    var obj: any = {
      conversationId: jspb.Message.getFieldWithDefault(msg, 1, 0),
      messageId: jspb.Message.getFieldWithDefault(msg, 2, 0),
      body: jspb.Message.getFieldWithDefault(msg, 3, ""),
      timestamp: jspb.Message.getFieldWithDefault(msg, 4, 0),
      authorPersonHash: jspb.Message.getFieldWithDefault(msg, 5, ""),
      attachmentList: jspb.Message.toObjectList(msg.getAttachmentList(),
      messaging_messaging_pb.Message.MessageAttachment.toObject, includeInstance)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!NewDirectMessage}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new NewDirectMessage;
    return NewDirectMessage.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!NewDirectMessage} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!NewDirectMessage}
   */
  static deserializeBinaryFromReader(msg: NewDirectMessage, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {number} */ (reader.readUint32());
        msg.setConversationId(value1);
        break;
      case 2:
        var value2 = /** @type {number} */ (reader.readUint32());
        msg.setMessageId(value2);
        break;
      case 3:
        var value3 = /** @type {string} */ (reader.readString());
        msg.setBody(value3);
        break;
      case 4:
        var value4 = /** @type {number} */ (reader.readUint64());
        msg.setTimestamp(value4);
        break;
      case 5:
        var value5 = /** @type {string} */ (reader.readString());
        msg.setAuthorPersonHash(value5);
        break;
      case 6:
        var value6 = new messaging_messaging_pb.Message.MessageAttachment;
        reader.readMessage(value6,messaging_messaging_pb.Message.MessageAttachment.deserializeBinaryFromReader);
        msg.addAttachment(value6);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!NewDirectMessage} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: NewDirectMessage, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getConversationId();
    if (f !== 0) {
      writer.writeUint32(
        1,
        f
      );
    }
    f = message.getMessageId();
    if (f !== 0) {
      writer.writeUint32(
        2,
        f
      );
    }
    f = message.getBody();
    if (f.length > 0) {
      writer.writeString(
        3,
        f
      );
    }
    f = message.getTimestamp();
    if (f !== 0) {
      writer.writeUint64(
        4,
        f
      );
    }
    f = message.getAuthorPersonHash();
    if (f.length > 0) {
      writer.writeString(
        5,
        f
      );
    }
    f = message.getAttachmentList();
    if (f.length > 0) {
      writer.writeRepeatedMessage(
        6,
        f,
        messaging_messaging_pb.Message.MessageAttachment.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    NewDirectMessage.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional uint32 conversation_id = 1;
   * @return {number}
   */
  getConversationId(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
  };


  /** @param {number} value */
  setConversationId(value: number) {
    jspb.Message.setProto3IntField(this, 1, value);
  }


  /**
   * optional uint32 message_id = 2;
   * @return {number}
   */
  getMessageId(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
  };


  /** @param {number} value */
  setMessageId(value: number) {
    jspb.Message.setProto3IntField(this, 2, value);
  }


  /**
   * optional string body = 3;
   * @return {string}
   */
  getBody(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
  };


  /** @param {string} value */
  setBody(value: string) {
    jspb.Message.setProto3StringField(this, 3, value);
  }


  /**
   * optional uint64 timestamp = 4;
   * @return {number}
   */
  getTimestamp(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
  };


  /** @param {number} value */
  setTimestamp(value: number) {
    jspb.Message.setProto3IntField(this, 4, value);
  }


  /**
   * optional string author_person_hash = 5;
   * @return {string}
   */
  getAuthorPersonHash(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
  };


  /** @param {string} value */
  setAuthorPersonHash(value: string) {
    jspb.Message.setProto3StringField(this, 5, value);
  }


  /**
   * repeated minga.Message.MessageAttachment attachment = 6;
   * @return {!Array<!Message.MessageAttachment>}
   */
  getAttachmentList(): messaging_messaging_pb.Message.MessageAttachment[] {
    return /** @type{!Array<!Message.MessageAttachment>} */ (
      jspb.Message.getRepeatedWrapperField(this, messaging_messaging_pb.Message.MessageAttachment, 6));
  }


  /** @param {!Array<!Message.MessageAttachment>} value */
  setAttachmentList(value?: messaging_messaging_pb.Message.MessageAttachment[]) {
    jspb.Message.setRepeatedWrapperField(this, 6, value);
  }


  /**
   * @param {!Message.MessageAttachment=} opt_value
   * @param {number=} opt_index
   * @return {!Message.MessageAttachment}
   */
  addAttachment(opt_value?: messaging_messaging_pb.Message.MessageAttachment, opt_index?: number): messaging_messaging_pb.Message.MessageAttachment {
    return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, messaging_messaging_pb.Message.MessageAttachment, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearAttachmentList() {
    this.setAttachmentList([]);
  }


}
export namespace NewNotification {
  export interface AsObject {
  }
}
export class NewNotification extends jspb.Message {
  static readonly displayName = "NewNotification";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): NewNotification.AsObject {
    return NewNotification.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!NewNotification} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: NewNotification) {
    var f: any;
    var obj: any = {

    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!NewNotification}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new NewNotification;
    return NewNotification.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!NewNotification} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!NewNotification}
   */
  static deserializeBinaryFromReader(msg: NewNotification, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!NewNotification} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: NewNotification, writer: jspb.BinaryWriter) {
    var f: any = undefined;
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    NewNotification.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
}
