import { InjectionToken } from '@angular/core';
import { Route } from '@angular/router';

import { PreventNavigationGuard } from '@app/src/app/guards';

import { PEOPLE_SELECTOR_FORMS } from '../constants';
import { PeopleSelectorComponent } from '../people-selector.component';
import { PeopleSelectorFormService } from '../services';
import { PsFormName, PsFormsConfig, PeopleSelectorRouteData } from '../types';

export const getPeopleSelectorInjectionToken = (
  formName: string,
): InjectionToken<PeopleSelectorFormService> => {
  return new InjectionToken<PeopleSelectorFormService>(
    `people-selector-${formName}`,
  );
};

export const getPeopleSelectorRoutes = (): Route[] => {
  return Object.keys(PEOPLE_SELECTOR_FORMS).map(
    (formName: PsFormName): Route => ({
      path: `${PEOPLE_SELECTOR_FORMS[formName].path}/:page`,
      component: PeopleSelectorComponent,
      canDeactivate: [PreventNavigationGuard],
      data: {
        injectionToken: PEOPLE_SELECTOR_FORMS[formName]?.injectionToken,
      } as PeopleSelectorRouteData,
    }),
  );
};

export const getPeopleSelectorFormProviders = () => {
  return Object.keys(PEOPLE_SELECTOR_FORMS).map(
    (formName: keyof typeof PEOPLE_SELECTOR_FORMS) => ({
      provide: PEOPLE_SELECTOR_FORMS[formName].injectionToken,
      useClass: PEOPLE_SELECTOR_FORMS[formName].service,
    }),
  );
};

export const validatePeopleSelectorFormConfig = <
  Text extends string,
  Config extends PsFormsConfig<Text>,
  Obj extends Config,
>(
  param: Obj,
) => param;
