import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';

import { NgSelectModule } from '@ng-select/ng-select';

import { MgIconModule } from '@app/src/app/icon';
import { MgSpinnerModule } from '@app/src/app/spinner';

import { MultiPersonSearchComponent } from './multi-person-search.component';

@NgModule({
  imports: [
    // Minga dependencies
    MgIconModule,
    MgSpinnerModule,

    // External dependencies
    CommonModule,
    NgSelectModule,
    MatIconModule,
    FormsModule,
  ],
  declarations: [MultiPersonSearchComponent],
  exports: [MultiPersonSearchComponent],
})
export class MultiPersonSearchModule {}
