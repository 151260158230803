// GENERATED CODE -- DO NOT EDIT!

import {grpc} from '@improbable-eng/grpc-web';

import * as term_term_pb from '../term/term_pb';
import * as common_date_pb from '../common/date_pb';

export class Terms {
  static readonly serviceName = "term.v1.Terms";

  static readonly GetTerm = {
    methodName: "GetTerm",
    service: Terms,
    requestStream: false,
    responseStream: false,
    requestType: term_term_pb.GetTermRequest,
    responseType: term_term_pb.GetTermResponse,
  };

  static readonly GetTerms = {
    methodName: "GetTerms",
    service: Terms,
    requestStream: false,
    responseStream: false,
    requestType: term_term_pb.GetTermsRequest,
    responseType: term_term_pb.GetTermsResponse,
  };

  static readonly GetTermsFromSis = {
    methodName: "GetTermsFromSis",
    service: Terms,
    requestStream: false,
    responseStream: false,
    requestType: term_term_pb.GetSisTermsRequest,
    responseType: term_term_pb.GetSisTermsResponse,
  };

  static readonly CreateTerm = {
    methodName: "CreateTerm",
    service: Terms,
    requestStream: false,
    responseStream: false,
    requestType: term_term_pb.CreateTermRequest,
    responseType: term_term_pb.CreateTermResponse,
  };

  static readonly UpdateTerm = {
    methodName: "UpdateTerm",
    service: Terms,
    requestStream: false,
    responseStream: false,
    requestType: term_term_pb.UpdateTermRequest,
    responseType: term_term_pb.UpdateTermResponse,
  };

  static readonly DeleteTerm = {
    methodName: "DeleteTerm",
    service: Terms,
    requestStream: false,
    responseStream: false,
    requestType: term_term_pb.DeleteTermRequest,
    responseType: term_term_pb.DeleteTermResponse,
  };

}

export class TermsClient {
  getTerm() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  getTerms() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  getTermsFromSis() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  createTerm() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  updateTerm() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  deleteTerm() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
}
