// GENERATED CODE -- DO NOT EDIT!

import {grpc} from '@improbable-eng/grpc-web';

import * as gateway_feedback_pb from '../gateway/feedback_pb';
import * as common_legacy_pb from '../common/legacy_pb';

export class FeedbackService {
  static readonly serviceName = "FeedbackService";

  static readonly sendFeedback = {
    methodName: "sendFeedback",
    service: FeedbackService,
    requestStream: false,
    responseStream: false,
    requestType: gateway_feedback_pb.SendFeedbackRequest,
    responseType: gateway_feedback_pb.SendFeedbackResponse,
  };

}

export class FeedbackServiceClient {
  sendFeedback() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
}
