import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatSnackBarModule } from '@angular/material/snack-bar';

import { MgIconModule } from '@app/src/app/icon';

import { GenericButtonModule } from '@shared/components/button';

import { SystemAlertModule } from '../system-alert';
import { SystemAlertSnackbarComponent } from './system-alert-snackbar.component';

@NgModule({
  imports: [
    // Minga dependencies
    MgIconModule,
    GenericButtonModule,
    MatSnackBarModule,
    SystemAlertModule,

    // External dependencies
    CommonModule,
    FlexLayoutModule,
  ],
  declarations: [SystemAlertSnackbarComponent],
  exports: [SystemAlertSnackbarComponent],
})
export class SystemAlertSnackBarModule {}
