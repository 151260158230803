// source: content/content.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

import * as jspb from 'google-protobuf';
import * as content_common_pb from '../content/common_pb';
export namespace ContentMetadata {
  export interface AsObject {
    typeString: string;
    contentHash: string;
    ownerMingaHash: string;
    programContextHash: string;
    publishTimestamp: number;
    unpublishTimestamp: number;
    pinEndTimestamp: number;
    ownerGroupHash: string;
    authorPersonHash: string;
    mingaDesignerContent: boolean;
    contentContextHash: string;
    allowedCommentingRoleTypesList: string[];
    recurringEventContentHash: string;
    recurringEventContextHash: string;
  }
}
export class ContentMetadata extends jspb.Message {
  static readonly displayName = "ContentMetadata";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [12];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, ContentMetadata.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): ContentMetadata.AsObject {
    return ContentMetadata.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!ContentMetadata} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: ContentMetadata) {
    var f: any;
    var obj: any = {
      typeString: jspb.Message.getFieldWithDefault(msg, 1, ""),
      contentHash: jspb.Message.getFieldWithDefault(msg, 2, ""),
      ownerMingaHash: jspb.Message.getFieldWithDefault(msg, 3, ""),
      programContextHash: jspb.Message.getFieldWithDefault(msg, 4, ""),
      publishTimestamp: jspb.Message.getFieldWithDefault(msg, 5, 0),
      unpublishTimestamp: jspb.Message.getFieldWithDefault(msg, 6, 0),
      pinEndTimestamp: jspb.Message.getFieldWithDefault(msg, 7, 0),
      ownerGroupHash: jspb.Message.getFieldWithDefault(msg, 8, ""),
      authorPersonHash: jspb.Message.getFieldWithDefault(msg, 9, ""),
      mingaDesignerContent: jspb.Message.getBooleanFieldWithDefault(msg, 10, false),
      contentContextHash: jspb.Message.getFieldWithDefault(msg, 11, ""),
      allowedCommentingRoleTypesList: (f = jspb.Message.getRepeatedField(msg, 12)) == null ? undefined : f,
      recurringEventContentHash: jspb.Message.getFieldWithDefault(msg, 13, ""),
      recurringEventContextHash: jspb.Message.getFieldWithDefault(msg, 14, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!ContentMetadata}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new ContentMetadata;
    return ContentMetadata.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!ContentMetadata} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!ContentMetadata}
   */
  static deserializeBinaryFromReader(msg: ContentMetadata, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setTypeString(value1);
        break;
      case 2:
        var value2 = /** @type {string} */ (reader.readString());
        msg.setContentHash(value2);
        break;
      case 3:
        var value3 = /** @type {string} */ (reader.readString());
        msg.setOwnerMingaHash(value3);
        break;
      case 4:
        var value4 = /** @type {string} */ (reader.readString());
        msg.setProgramContextHash(value4);
        break;
      case 5:
        var value5 = /** @type {number} */ (reader.readUint64());
        msg.setPublishTimestamp(value5);
        break;
      case 6:
        var value6 = /** @type {number} */ (reader.readUint64());
        msg.setUnpublishTimestamp(value6);
        break;
      case 7:
        var value7 = /** @type {number} */ (reader.readUint64());
        msg.setPinEndTimestamp(value7);
        break;
      case 8:
        var value8 = /** @type {string} */ (reader.readString());
        msg.setOwnerGroupHash(value8);
        break;
      case 9:
        var value9 = /** @type {string} */ (reader.readString());
        msg.setAuthorPersonHash(value9);
        break;
      case 10:
        var value10 = /** @type {boolean} */ (reader.readBool());
        msg.setMingaDesignerContent(value10);
        break;
      case 11:
        var value11 = /** @type {string} */ (reader.readString());
        msg.setContentContextHash(value11);
        break;
      case 12:
        var value12 = /** @type {string} */ (reader.readString());
        msg.addAllowedCommentingRoleTypes(value12);
        break;
      case 13:
        var value13 = /** @type {string} */ (reader.readString());
        msg.setRecurringEventContentHash(value13);
        break;
      case 14:
        var value14 = /** @type {string} */ (reader.readString());
        msg.setRecurringEventContextHash(value14);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!ContentMetadata} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: ContentMetadata, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getTypeString();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
    f = message.getContentHash();
    if (f.length > 0) {
      writer.writeString(
        2,
        f
      );
    }
    f = message.getOwnerMingaHash();
    if (f.length > 0) {
      writer.writeString(
        3,
        f
      );
    }
    f = message.getProgramContextHash();
    if (f.length > 0) {
      writer.writeString(
        4,
        f
      );
    }
    f = message.getPublishTimestamp();
    if (f !== 0) {
      writer.writeUint64(
        5,
        f
      );
    }
    f = message.getUnpublishTimestamp();
    if (f !== 0) {
      writer.writeUint64(
        6,
        f
      );
    }
    f = message.getPinEndTimestamp();
    if (f !== 0) {
      writer.writeUint64(
        7,
        f
      );
    }
    f = message.getOwnerGroupHash();
    if (f.length > 0) {
      writer.writeString(
        8,
        f
      );
    }
    f = message.getAuthorPersonHash();
    if (f.length > 0) {
      writer.writeString(
        9,
        f
      );
    }
    f = message.getMingaDesignerContent();
    if (f) {
      writer.writeBool(
        10,
        f
      );
    }
    f = message.getContentContextHash();
    if (f.length > 0) {
      writer.writeString(
        11,
        f
      );
    }
    f = message.getAllowedCommentingRoleTypesList();
    if (f.length > 0) {
      writer.writeRepeatedString(
        12,
        f
      );
    }
    f = message.getRecurringEventContentHash();
    if (f.length > 0) {
      writer.writeString(
        13,
        f
      );
    }
    f = message.getRecurringEventContextHash();
    if (f.length > 0) {
      writer.writeString(
        14,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    ContentMetadata.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string type_string = 1;
   * @return {string}
   */
  getTypeString(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setTypeString(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


  /**
   * optional string content_hash = 2;
   * @return {string}
   */
  getContentHash(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
  };


  /** @param {string} value */
  setContentHash(value: string) {
    jspb.Message.setProto3StringField(this, 2, value);
  }


  /**
   * optional string owner_minga_hash = 3;
   * @return {string}
   */
  getOwnerMingaHash(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
  };


  /** @param {string} value */
  setOwnerMingaHash(value: string) {
    jspb.Message.setProto3StringField(this, 3, value);
  }


  /**
   * optional string program_context_hash = 4;
   * @return {string}
   */
  getProgramContextHash(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
  };


  /** @param {string} value */
  setProgramContextHash(value: string) {
    jspb.Message.setProto3StringField(this, 4, value);
  }


  /**
   * optional uint64 publish_timestamp = 5;
   * @return {number}
   */
  getPublishTimestamp(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
  };


  /** @param {number} value */
  setPublishTimestamp(value: number) {
    jspb.Message.setProto3IntField(this, 5, value);
  }


  /**
   * optional uint64 unpublish_timestamp = 6;
   * @return {number}
   */
  getUnpublishTimestamp(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
  };


  /** @param {number} value */
  setUnpublishTimestamp(value: number) {
    jspb.Message.setProto3IntField(this, 6, value);
  }


  /**
   * optional uint64 pin_end_timestamp = 7;
   * @return {number}
   */
  getPinEndTimestamp(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
  };


  /** @param {number} value */
  setPinEndTimestamp(value: number) {
    jspb.Message.setProto3IntField(this, 7, value);
  }


  /**
   * optional string owner_group_hash = 8;
   * @return {string}
   */
  getOwnerGroupHash(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
  };


  /** @param {string} value */
  setOwnerGroupHash(value: string) {
    jspb.Message.setProto3StringField(this, 8, value);
  }


  /**
   * optional string author_person_hash = 9;
   * @return {string}
   */
  getAuthorPersonHash(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
  };


  /** @param {string} value */
  setAuthorPersonHash(value: string) {
    jspb.Message.setProto3StringField(this, 9, value);
  }


  /**
   * optional bool minga_designer_content = 10;
   * @return {boolean}
   */
  getMingaDesignerContent(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
  };


  /** @param {boolean} value */
  setMingaDesignerContent(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 10, value);
  }


  /**
   * optional string content_context_hash = 11;
   * @return {string}
   */
  getContentContextHash(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
  };


  /** @param {string} value */
  setContentContextHash(value: string) {
    jspb.Message.setProto3StringField(this, 11, value);
  }


  /**
   * repeated string allowed_commenting_role_types = 12;
   * @return {!Array<string>}
   */
  getAllowedCommentingRoleTypesList(): string[] {
    return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 12));
  };


  /** @param {!Array<string>} value */
  setAllowedCommentingRoleTypesList(value: string[]) {
    jspb.Message.setField(this, 12, value || []);
  }


  /**
   * @param {string} value
   * @param {number=} opt_index
   */
  addAllowedCommentingRoleTypes(value: string, opt_index?: number): void {
    jspb.Message.addToRepeatedField(this, 12, value, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearAllowedCommentingRoleTypesList() {
    this.setAllowedCommentingRoleTypesList([]);
  }


  /**
   * optional string recurring_event_content_hash = 13;
   * @return {string}
   */
  getRecurringEventContentHash(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
  };


  /** @param {string} value */
  setRecurringEventContentHash(value: string) {
    jspb.Message.setProto3StringField(this, 13, value);
  }


  /**
   * optional string recurring_event_context_hash = 14;
   * @return {string}
   */
  getRecurringEventContextHash(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
  };


  /** @param {string} value */
  setRecurringEventContextHash(value: string) {
    jspb.Message.setProto3StringField(this, 14, value);
  }


}
