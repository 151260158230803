<span [ngClass]="classes">
  <mg-icon
    *ngIf="iconLeft"
    class="icon-left icon"
    [iconName]="iconLeft"
    [style.color]="iconLeftColor | appColor"></mg-icon>
  <span
    [style.maxWidth]="maxChars ? maxChars + 'ch' : 'none'"
    class="inner-content">
    <!-- Add on left -->
    <ng-container *ngIf="addOnLeft">
      <mg-text
        class="add-on-left"
        color="surface-alt"
        variant="body-sm-secondary">
        {{ addOnLeft }}
      </mg-text>
    </ng-container>
    <span class="text-wrap">
      <ng-content> </ng-content>
    </span>
    <mat-icon
      *ngIf="type === 'button'"
      inline="true"
      aria-hidden="false"
      [style.color]="iconColor">
      {{ buttonIconName }}
    </mat-icon>
  </span>
  <mg-icon
    *ngIf="type === 'removeable-select-tag'"
    class="close icon icon-right"
    iconName="mg-cross"></mg-icon>
</span>
