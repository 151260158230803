import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule } from '@angular/forms';
import { MatRadioModule } from '@angular/material/radio';

import { MgButtonModule } from '@app/src/app/button';
import { MgSuccessCheckmarkModule } from '@app/src/app/components/SuccessCheckmark';
import { MgOverlayToolsModule } from '@app/src/app/overlay/tools';

import { ReportFormComponent } from './ReportForm.component';

@NgModule({
  imports: [
    // Minga dependencies
    MgSuccessCheckmarkModule,
    MgButtonModule,
    MgOverlayToolsModule,

    // External Dependencies
    FlexLayoutModule,
    MatRadioModule,
    CommonModule,
    FormsModule,
  ],
  declarations: [ReportFormComponent],
  exports: [ReportFormComponent],
})
export class ReportFormModule {}
