import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule } from '@angular/forms';
import { MatButtonToggleModule } from '@angular/material/button-toggle';

import { MgEmptyStateModule } from '@app/src/app/components/EmptyState';
import { FileInputModule } from '@app/src/app/components/Input/FileInput';
import { MgMessageBoxModule } from '@app/src/app/components/MessageBox';
import { MgIconModule } from '@app/src/app/icon';
import { MgSpinnerModule } from '@app/src/app/spinner';

import { XlsListUploaderModule } from '@shared/components/XlsListUploader';
import { ListUploaderSummaryModule } from '@shared/components/XlsListUploader/ListUploaderSummary';
import { CountTileModule } from '@shared/components/count-tile';
import { FormModule } from '@shared/components/form';
import { GenericModule } from '@shared/components/generic';
import { OverlayModule } from '@shared/components/modal-overlay';
import { TextModule } from '@shared/components/text';
import { TilesCarouselModule } from '@shared/components/tiles-carousel';

import { BmDashboardComponent } from './bm-dashboard.component';
import { ImportBehaviorsDialogComponent } from './import-behaviors-dialog/import-behaviors-dialog.component';
import { BmDashboardTileColorsPipe } from './pipes';
import { BmDashboardService } from './services/bm-dashboard.service';

@NgModule({
  imports: [
    // Minga dependencies
    MgIconModule,
    TilesCarouselModule,
    MgSpinnerModule,
    MgEmptyStateModule,
    CountTileModule,
    XlsListUploaderModule,
    FileInputModule,
    OverlayModule,
    ListUploaderSummaryModule,
    MgMessageBoxModule,
    GenericModule,
    FormModule,
    TextModule,

    // External dependencies
    CommonModule,
    FlexLayoutModule,
    MatButtonToggleModule,
    FormsModule,
  ],
  declarations: [
    // Pipes
    BmDashboardTileColorsPipe,

    // Components
    BmDashboardComponent,
    ImportBehaviorsDialogComponent,
  ],
  exports: [BmDashboardComponent],
  providers: [BmDashboardService],
})
export class BmDashboardModule {}
