import { Injectable } from '@angular/core';

import { MingaPermission } from 'libs/domain';
import { mingaSettingTypes } from 'libs/util';
import { Observable, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';

import { AppConfigService } from '@app/src/app/minimal/services/AppConfig';
import { PermissionsService } from '@app/src/app/permissions';
import { MingaSettingsService } from '@app/src/app/store/Minga/services';

@Injectable({ providedIn: 'root' })
export class KioskPermissionsService {
  // settings related to kiosk
  public kioskSettings$ = combineLatest([
    this._settingsService.getSettingValueObs(
      mingaSettingTypes.PASS_HALLPASS_KIOSK,
    ),
    this._settingsService.getSettingValueObs(mingaSettingTypes.CHECKIN_KIOSK),
    this._settingsService.getSettingValueObs(mingaSettingTypes.FTM_KIOSK),
  ]);

  constructor(
    private _permissionsService: PermissionsService,
    private _settingsService: MingaSettingsService,
    private _appConfig: AppConfigService,
  ) {}

  public isKioskEnabled(): Observable<boolean> {
    return this.kioskSettings$.pipe(
      map(values => {
        const isKioskEnabled = this.isKioskFeatureEnabled();
        return values.some(value => value === true) && isKioskEnabled;
      }),
    );
  }

  public isUserInKioskModeObs(): Observable<boolean> {
    return this._permissionsService.observePermission(
      MingaPermission.KIOSK_MANAGE,
    );
  }

  /**
   * When using the boolean version we need to make user we have auth before evaluating
   */
  public isUserInKioskMode(): boolean {
    return this._permissionsService.hasPermission(MingaPermission.KIOSK_MANAGE);
  }

  /**
   * If the feature in general is enabled for users
   */
  public isKioskFeatureEnabled(): boolean {
    return this._appConfig.getValue('kioskEnabled');
  }
}
