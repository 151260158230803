import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';

import { IBellSchedule } from 'libs/domain';
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';

import { RootService } from '@app/src/app/minimal/services/RootService';
import { scrollIntoView } from '@app/src/app/util/scroll-into-view';

import { CrudFormBase } from '@shared/components/crud-form-base/crud-form-base.abstract';
import { markNestedFormGroupAsDirty } from '@shared/components/crud-form-base/crud-form-base.utils';
import { FormSelectOption } from '@shared/components/form';
import { initializeRange } from '@shared/components/form/components/form-date-range/form-date-range.utils';
import {
  MODAL_OVERLAY_DATA,
  ModalOverlayRef,
  ModalOverlayServiceCloseEventType,
} from '@shared/components/modal-overlay';
import {
  SystemAlertCloseEvents,
  SystemAlertModalService,
  SystemAlertModalType,
} from '@shared/components/system-alert-modal';
import { SystemAlertSnackBarService } from '@shared/components/system-alert-snackbar';
import { BsSchedulesService } from '@shared/services/bell-schedule/bs-schedules.service';
import { BsTermsService } from '@shared/services/bell-schedule/bs-terms.service';

import {
  createForm,
  getData,
  setForm,
  TERM_FORM_FIELDS,
  TERMS_MESSAGES,
} from '../../constants/mm-bs-terms.constants';
import {
  BsTermEditData,
  BsTermEditResponse,
  ClientTerm,
} from '../../types/mm-bell-schedule.types';
import { noOverlappingDaysValidator } from '../../validators/noOverlappingDays.validator';
import { noOverlappingTermsValidator } from '../../validators/noOverlappingTerms.validator';

@Component({
  selector: 'mg-mm-bs-terms-edit',
  templateUrl: './mm-bs-terms-edit.component.html',
  styleUrls: ['./mm-bs-terms-edit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MmBsTermsEditComponent
  extends CrudFormBase<ClientTerm>
  implements OnInit, OnDestroy
{
  private _destroyed$ = new ReplaySubject<void>(1);

  private _loadingData = new BehaviorSubject<boolean>(true);
  public readonly loadingData$ = this._loadingData.asObservable();

  private _schedulesSubject = new BehaviorSubject<IBellSchedule[]>([]);
  public scheduleOptions$: Observable<FormSelectOption<number>[]> =
    this._schedulesSubject.pipe(
      map(schedules => {
        return schedules.map(s => ({ value: s.id, label: s.name }));
      }),
    );

  private _termsSubject = new BehaviorSubject<ClientTerm[]>([]);
  public termsOptions$: Observable<FormSelectOption<string>[]> =
    this._termsSubject.pipe(
      map(terms => {
        return terms.map(s => ({ value: s.sourcedId, label: s.sourcedId }));
      }),
    );

  public MESSAGES = TERMS_MESSAGES;
  public FORM_FIELDS = TERM_FORM_FIELDS;
  public readonly form = this._fb.group({
    ...createForm(),
    [TERM_FORM_FIELDS.DATE_RANGE]: initializeRange({
      start: {
        value: null,
        validators: [Validators.required],
      },
      end: {
        value: null,
        validators: [Validators.required],
      },
    }),
  });

  // if a term is being synced from SIS we need to disable fields
  public _isSisSyncedSubject = new BehaviorSubject<boolean>(false);
  public isSisSynced$ = this._isSisSyncedSubject.asObservable();

  @ViewChild('crudForm', { static: false })
  crudForm?: ElementRef<HTMLElement>;

  constructor(
    @Inject(MODAL_OVERLAY_DATA)
    public dialogData: BsTermEditData,
    private _modalRef: ModalOverlayRef<BsTermEditResponse, BsTermEditData>,
    private _fb: UntypedFormBuilder,
    private _systemAlertModal: SystemAlertModalService,
    public rootService: RootService,
    private _bsSchedulesService: BsSchedulesService,
    private _bsTermsService: BsTermsService,
    private _snackBarService: SystemAlertSnackBarService,
  ) {
    super({
      id: dialogData?.id,
      get: async id => {
        return this._bsTermsService.get(id);
      },
      create: data => {
        return this._bsTermsService.create(data as ClientTerm);
      },
      update: data => {
        return this._bsTermsService.update(data as ClientTerm);
      },
      delete: async data => {
        await this._bsTermsService.delete(data.id);
        return data;
      },
      onSetForm: data => {
        this._setSisSyncedTerm(data);
        setForm(data, this.form);
      },
      onValidate: data => {
        markNestedFormGroupAsDirty(this.form);
        return this.form.valid;
      },
      onSuccess: (type, data) => {
        if (type === 'delete') {
          this._modalRef.close(ModalOverlayServiceCloseEventType.DELETE, {
            deleted: data.id,
          });
        }

        if (type === 'create') {
          this._modalRef.close(ModalOverlayServiceCloseEventType.CREATE, {
            created: data,
          });
        }

        if (type === 'update') {
          this._modalRef.close(ModalOverlayServiceCloseEventType.SUBMIT, {
            updated: data,
          });
        }
      },
      onSubmit: data => {
        const formData: any = getData(data, this.form);
        const { dateRange, ...rest } = formData;
        const mapped = {
          ...rest,
        };

        if (dateRange?.start) {
          mapped.startDate = dateRange.start;
        }

        if (dateRange?.end) {
          mapped.endDate = dateRange.end;
        }

        return mapped;
      },
      onShowLoader: promise => rootService.addLoadingPromise(promise),
      onCancel: async () => {
        if (this.form.pristine) {
          this._modalRef.close(ModalOverlayServiceCloseEventType.CLOSE);
          return;
        }

        const modalRef = await this._systemAlertModal.open({
          modalType: SystemAlertModalType.WARNING,
          heading: TERMS_MESSAGES.DELETE_CONFIRM_DISCARD_TITLE,
          message: TERMS_MESSAGES.DELETE_CONFIRM_DISCARD_DESC,
          closeBtn: TERMS_MESSAGES.DELETE_CONFIRM_CANCEL_BTN,
          confirmActionBtn: TERMS_MESSAGES.DELETE_CONFIRM_DISCARD_BTN,
        });

        const response = await modalRef.afterClosed().toPromise();

        if (response?.type === SystemAlertCloseEvents.CONFIRM) {
          this._modalRef.close(ModalOverlayServiceCloseEventType.CLOSE);
        }
      },
      onDelete: async () => {
        const modalRef = await this._systemAlertModal.open({
          modalType: SystemAlertModalType.WARNING,
          heading: TERMS_MESSAGES.DELETE_CONFIRM_TITLE,
          message: TERMS_MESSAGES.DELETE_CONFIRM_DESC,
          closeBtn: TERMS_MESSAGES.DELETE_CONFIRM_CANCEL_BTN,
          confirmActionBtn: TERMS_MESSAGES.DELETE_CONFIRM_DELETE_BTN,
        });

        const response = await modalRef.afterClosed().toPromise();

        return response?.type === SystemAlertCloseEvents.CONFIRM;
      },
      onFormStateChange: state => {
        if (state === 'invalid') {
          // scroll to top of form
          scrollIntoView(this.crudForm.nativeElement, {
            align: { top: 0 },
          });
        }
      },
    });
  }

  ngOnInit(): void {
    this.init();
    this._getInitialData();
  }

  ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  private async _getInitialData() {
    try {
      this._loadingData.next(true);

      const [schedules, terms] = await Promise.all([
        this._bsSchedulesService.fetchAll(),
        this._bsTermsService.fetchAll(),
      ]);

      this._schedulesSubject.next(schedules);
      this._termsSubject.next(terms);

      const excludeCurrentTerm = terms.filter(
        t => t.id !== this.dialogData?.id,
      );
      this.form.setValidators(noOverlappingTermsValidator(excludeCurrentTerm));
      this.form.setValidators(noOverlappingDaysValidator(schedules));
      this.form.updateValueAndValidity();
    } catch (e) {
      this._snackBarService.error(TERMS_MESSAGES.ERROR_LOADING_DATA);
    } finally {
      this._loadingData.next(false);
    }
  }

  private _setSisSyncedTerm(data) {
    const isSisSynced = !!data.id && !!data.sourcedId;
    this._isSisSyncedSubject.next(isSisSynced);
    const shortCodeControl = this.form.get(TERM_FORM_FIELDS.SHORT_CODE);
    // TODO disable for now we might remove this field all together
    shortCodeControl.disable();

    if (isSisSynced) {
      shortCodeControl.setValidators([Validators.required]);
      shortCodeControl.updateValueAndValidity();
    }
  }
}
