import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { RouterModule } from '@angular/router';

import { TranslateModule } from '@ngx-translate/core';

import { CommentListViewModule } from '@app/src/app/content-views/components/CommentListView';
import { MgDirectivesModule } from '@app/src/app/directives';
import { MgIconModule } from '@app/src/app/icon';
import { InputsModule } from '@app/src/app/inputs';
import { PermissionsModule } from '@app/src/app/permissions';
import { MgPipesModule } from '@app/src/app/pipes';
import { MgSpinnerModule } from '@app/src/app/spinner';

import { CommentViewModule } from '../CommentView';
import { LongCardBaseComponent } from './LongCardBase.component';

@NgModule({
  imports: [
    // Minga dependencies
    MgIconModule,
    MgSpinnerModule,
    CommentViewModule,
    InputsModule,
    MgPipesModule,
    MgDirectivesModule,
    PermissionsModule,
    CommentListViewModule,

    // External dependencies
    CommonModule,
    TranslateModule,
    FlexLayoutModule,
    RouterModule,
  ],
  declarations: [LongCardBaseComponent],
  exports: [LongCardBaseComponent],
})
export class LongCardBaseModule {}
