import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
  TrackByFunction,
} from '@angular/core';

import { gateway } from 'libs/generated-grpc-web';

@Component({
  selector: 'mg-gallery-photo-event-stream',
  templateUrl: './GalleryPhotoEventStream.component.html',
  styleUrls: ['./GalleryPhotoEventStream.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GalleryPhotoEventStreamComponent {
  trackBy: TrackByFunction<gateway.gallery_pb.GalleryItem.AsObject>;
  @Input() contextHash: string;
  @Input() contentHash: string;
  @Input() searchQuery: string;
  @Input() selectedGalleryPhoto:
    | gateway.gallery_pb.GalleryItem.AsObject
    | string = '';

  @Output() galleryPhotoClick: EventEmitter<string> = new EventEmitter();

  constructor(
    private _cdr: ChangeDetectorRef,
    public galleryService: gateway.gallery_ng_grpc_pb.MingaGallery,
  ) {
    this.trackBy = (
      index: number,
      item: gateway.gallery_pb.GalleryItem.AsObject,
    ) => {
      return item.galleryPhotoUuid;
    };
  }

  get filter() {
    const filter: any = {};

    if (this.contentHash) {
      filter.contentHash = this.contentHash;
    }

    return filter;
  }

  isBannerSelected(item: gateway.gallery_pb.GalleryItem.AsObject) {
    if (
      !this.selectedGalleryPhoto ||
      typeof this.selectedGalleryPhoto == 'string'
    ) {
      return false;
    }
    return this.selectedGalleryPhoto.galleryPhotoUuid === item.galleryPhotoUuid;
  }

  clickItem(item: gateway.gallery_pb.GalleryItem.AsObject) {
    this.selectedGalleryPhoto = item;
    this.galleryPhotoClick.emit(item.galleryPhotoUuid);
    this._cdr.markForCheck();
  }
}
