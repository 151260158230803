import { Injectable, isDevMode } from '@angular/core';

import { SentryService } from '@app/src/app/minimal/services/Sentry/Sentry.service';

import { SystemAlertSnackBarService } from '@shared/components/system-alert-snackbar';

import { CreateErrorOptions } from './types';

@Injectable({ providedIn: 'root' })
export class ErrorHandlerService {
  private _isDevMode = isDevMode();

  /** Service Constructor */
  constructor(
    private _sentry: SentryService,
    private _systemAlertSnackBar: SystemAlertSnackBarService,
  ) {}

  public gateWayError(
    message,
    context,
    openSnackBar: boolean,
    snackOpts = {},
    useClientSideMessage = true,
  ) {
    const errorMessage = useClientSideMessage
      ? message
      : context?.message || message;
    if (openSnackBar) {
      this._systemAlertSnackBar.open({
        type: 'error',
        message: errorMessage,
        ...snackOpts,
      });
    }
    const error = this._createError({
      type: 'SERVER_ERROR',
      message: errorMessage,
      stack: context,
    });
    this._handleError(error);
    return error;
  }

  private _createError(options: CreateErrorOptions): Error {
    const error = new Error(options.message);
    error.name = options.type;
    error.stack = options?.stack;
    return error;
  }

  private _handleError(error: Error) {
    this._handleDevError(error);
    this._handleProdError(error);
  }

  private _handleProdError(error: Error) {
    if (this._isDevMode) return;
    this._sentry.captureMessageAsError(error.message);
  }

  private _handleDevError(error: Error) {
    if (!this._isDevMode) return;
    console.error(error);
  }
}
