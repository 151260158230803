// GENERATED CODE -- DO NOT EDIT!

import {grpc} from '@improbable-eng/grpc-web';

import * as content_event_pb from '../content/event_pb';
import * as content_common_pb from '../content/common_pb';
import * as common_delta_pb from '../common/delta_pb';
import * as common_date_pb from '../common/date_pb';
import * as gateway_person_view_pb from '../gateway/person_view_pb';
import * as restriction_restriction_pb from '../restriction/restriction_pb';

export class Event {
  static readonly serviceName = "minga.content.Event";

  static readonly New = {
    methodName: "New",
    service: Event,
    requestStream: false,
    responseStream: false,
    requestType: content_event_pb.EventInfo,
    responseType: content_common_pb.ContentSaveResult,
  };

  static readonly Update = {
    methodName: "Update",
    service: Event,
    requestStream: false,
    responseStream: false,
    requestType: content_event_pb.DeltaEventInfo,
    responseType: content_common_pb.ContentSaveResult,
  };

  static readonly GetInfo = {
    methodName: "GetInfo",
    service: Event,
    requestStream: false,
    responseStream: false,
    requestType: content_common_pb.ContentInfoQuery,
    responseType: content_event_pb.EventInfo,
  };

  static readonly CancelEvent = {
    methodName: "CancelEvent",
    service: Event,
    requestStream: false,
    responseStream: false,
    requestType: content_event_pb.CancelEventRequest,
    responseType: content_event_pb.CancelEventResponse,
  };

  static readonly CheckInUsers = {
    methodName: "CheckInUsers",
    service: Event,
    requestStream: false,
    responseStream: false,
    requestType: content_event_pb.CheckInUsersRequest,
    responseType: content_event_pb.CheckInUsersResponse,
  };

  static readonly CheckOutUsers = {
    methodName: "CheckOutUsers",
    service: Event,
    requestStream: false,
    responseStream: false,
    requestType: content_event_pb.CheckOutUsersRequest,
    responseType: content_event_pb.CheckOutUsersResponse,
  };

  static readonly AddEventManager = {
    methodName: "AddEventManager",
    service: Event,
    requestStream: false,
    responseStream: false,
    requestType: content_event_pb.AddEventManagerRequest,
    responseType: content_event_pb.AddEventManagerResponse,
  };

  static readonly RemoveEventManager = {
    methodName: "RemoveEventManager",
    service: Event,
    requestStream: false,
    responseStream: false,
    requestType: content_event_pb.RemoveEventManagerRequest,
    responseType: content_event_pb.RemoveEventManagerResponse,
  };

  static readonly GetInvitedPeople = {
    methodName: "GetInvitedPeople",
    service: Event,
    requestStream: false,
    responseStream: false,
    requestType: content_event_pb.GetInvitedPeopleRequest,
    responseType: content_event_pb.GetInvitedPeopleResponse,
  };

  static readonly InvitePeople = {
    methodName: "InvitePeople",
    service: Event,
    requestStream: false,
    responseStream: false,
    requestType: content_event_pb.InvitePeopleRequest,
    responseType: content_event_pb.InvitePeopleResponse,
  };

  static readonly AddEventTickets = {
    methodName: "AddEventTickets",
    service: Event,
    requestStream: false,
    responseStream: false,
    requestType: content_event_pb.AddEventTicketsRequest,
    responseType: content_event_pb.AddEventTicketsResponse,
  };

  static readonly GetEventTicketsByStudentId = {
    methodName: "GetEventTicketsByStudentId",
    service: Event,
    requestStream: false,
    responseStream: false,
    requestType: content_event_pb.GetEventTicketsByStudentIdRequest,
    responseType: content_event_pb.GetEventTicketsByStudentIdResponse,
  };

  static readonly GetEventTickets = {
    methodName: "GetEventTickets",
    service: Event,
    requestStream: false,
    responseStream: false,
    requestType: content_event_pb.EventTicketsPeopleRequest,
    responseType: content_event_pb.EventTicketsPeopleResponse,
  };

  static readonly ExportTickets = {
    methodName: "ExportTickets",
    service: Event,
    requestStream: false,
    responseStream: true,
    requestType: content_event_pb.ExportEventTicketsRequest,
    responseType: content_event_pb.ExportEventTicketsChunk,
  };

  static readonly RemovePeopleFromEvent = {
    methodName: "RemovePeopleFromEvent",
    service: Event,
    requestStream: false,
    responseStream: false,
    requestType: content_event_pb.RemovePeopleFromEventRequest,
    responseType: content_event_pb.RemovePeopleFromEventResponse,
  };

  static readonly GetEventManagementInfo = {
    methodName: "GetEventManagementInfo",
    service: Event,
    requestStream: false,
    responseStream: false,
    requestType: content_event_pb.GetEventManagementInfoRequest,
    responseType: content_event_pb.GetEventManagementInfoResponse,
  };

  static readonly GetEventGoingPeople = {
    methodName: "GetEventGoingPeople",
    service: Event,
    requestStream: false,
    responseStream: false,
    requestType: content_event_pb.GetEventGoingPeopleRequest,
    responseType: content_event_pb.GetEventGoingPeopleResponse,
  };

}

export class EventClient {
  new() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  update() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  getInfo() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  cancelEvent() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  checkInUsers() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  checkOutUsers() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  addEventManager() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  removeEventManager() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  getInvitedPeople() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  invitePeople() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  addEventTickets() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  getEventTicketsByStudentId() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  getEventTickets() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  exportTickets() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  removePeopleFromEvent() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  getEventManagementInfo() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  getEventGoingPeople() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
}
