// GENERATED CODE -- DO NOT EDIT!

import {grpc} from '@improbable-eng/grpc-web';

import * as student_schedule_student_schedule_pb from '../student_schedule/student_schedule_pb';
import * as common_date_pb from '../common/date_pb';

export class StudentScheduleManager {
  static readonly serviceName = "minga.student_schedule.StudentScheduleManager";

  static readonly GetStudentScheduleForRecipient = {
    methodName: "GetStudentScheduleForRecipient",
    service: StudentScheduleManager,
    requestStream: false,
    responseStream: false,
    requestType: student_schedule_student_schedule_pb.GetStudentScheduleForRecipientRequest,
    responseType: student_schedule_student_schedule_pb.GetStudentScheduleForRecipientResponse,
  };

}

export class StudentScheduleManagerClient {
  getStudentScheduleForRecipient() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
}
