import { ILinearGradient, IRadialGradient } from 'libs/shared';

const SVG_NS = 'http://www.w3.org/2000/svg';

export function createSvgLinearGradient(
  linearGradient: ILinearGradient,
): SVGLinearGradientElement {
  const element = document.createElementNS(SVG_NS, 'linearGradient');

  if (linearGradient.transform) {
    if (linearGradient.transform.rotate) {
      // We subtract 90 degrees to match the CSS coordinates. We either had to
      // modify the CSS one to match the SVG one or vice versa. I chose CSS
      const degrees = linearGradient.transform.degrees - 90;
      element.setAttribute('gradientTransform', `rotate(${degrees}, 0.5, 0.5)`);
    }
  }

  for (const stop of linearGradient.stops) {
    const stopElement = document.createElementNS(SVG_NS, 'stop');
    stopElement.setAttribute('offset', stop.offset);
    stopElement.setAttribute('stop-color', stop.color);
    element.appendChild(stopElement);
  }

  return element;
}

export function createSvgRadialGradient(
  radialElement: IRadialGradient,
): SVGRadialGradientElement {
  const element = document.createElementNS(SVG_NS, 'radialGradient');

  for (const stop of radialElement.stops) {
    const stopElement = document.createElementNS(SVG_NS, 'stop');
    stopElement.setAttribute('offset', stop.offset);
    stopElement.setAttribute('stop-color', stop.color);
    element.appendChild(stopElement);
  }

  return element;
}
