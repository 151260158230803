import { Injectable } from '@angular/core';

import { gateway } from 'libs/generated-grpc-web';
import { String } from 'lodash';
import { from, Observable } from 'rxjs';

import { IMingaFileOptions } from '@app/src/app/file/public_interfaces';
import { ReportService } from '@app/src/app/services/Report';
import { NotificationStoreModel } from '@app/src/app/store/Notifications/model';

import {
  Group,
  MingaGroupMemberRank,
  resolveMyGroupRankMemberInfo,
  resolveMyGroupStatusRank,
} from '../models/Group';
import { GroupComposite } from '../models/GroupComposite';
import { GroupDetails } from '../models/GroupDetails';
import { GroupFiles, IGroupFile } from '../models/GroupFiles';

export interface GetGroupsResponse {
  groups: Group[];
}

/**
 * Service for making requests to the backend for Groups.
 */
@Injectable()
export class GroupsService {
  constructor(
    private mingaGroupService: gateway.mingaGroup_ng_grpc_pb.MingaGroup,
    private reportService: ReportService,
  ) {}

  public getAllGroups(): Observable<GetGroupsResponse> {
    const request = new gateway.mingaGroup_pb.GetMingaGroupsRequest();
    return from(this.getAllGroupsRequest(request));
  }

  getPersonGroups(personHash: string) {
    if (!personHash)
      throw new Error(`[GroupsService] getPersonGroups() missing person hash`);

    const request = new gateway.mingaGroup_pb.GetPersonGroupsRequest();
    request.setPersonHash(personHash);

    return from(this._getPersonGroupsRequest(request));
  }

  private async _getPersonGroupsRequest(
    request: gateway.mingaGroup_pb.GetPersonGroupsRequest,
  ) {
    const response = await this.mingaGroupService.getPersonGroups(request);
    const groupList = response.getGroupList();
    return groupList.map(group => Group.fromMessage(group));
  }

  createGroup(
    inputs: gateway.mingaGroup_pb.GroupInputs,
  ): Observable<GroupComposite> {
    const request = new gateway.mingaGroup_pb.CreateMingaGroupRequest();
    request.setInputs(inputs);
    return from(this.createGroupRequest(request));
  }

  updateGroup(
    groupHash: string,
    inputs: gateway.mingaGroup_pb.GroupInputs,
  ): Observable<GroupComposite> {
    const request = new gateway.mingaGroup_pb.UpdateMingaGroupRequest();
    request.setMingaGroupHash(groupHash);
    request.setInputs(inputs);
    return from(this.updateGroupRequest(request));
  }

  private async updateGroupRequest(
    request: gateway.mingaGroup_pb.UpdateMingaGroupRequest,
  ): Promise<GroupComposite> {
    const response: gateway.mingaGroup_pb.UpdateMingaGroupResponse =
      await this.mingaGroupService.update(request);

    const moderationResult = response.getModerationResult();
    const contextHash = response.getContentContextHash();
    const passedModeration =
      await this.reportService.handleContentModerationResult(
        moderationResult,
        contextHash,
      );
    if (!passedModeration) {
      throw new Error(`Group update failed moderation`);
    }

    return {
      group: Group.fromMessage(response.getGroup()),
      groupDetails: GroupDetails.fromMessage(response.getGroupDetails()),
    };
  }

  private async createGroupRequest(
    request: gateway.mingaGroup_pb.CreateMingaGroupRequest,
  ): Promise<GroupComposite> {
    const response = await this.mingaGroupService.create(request);
    const moderationResult = response.getModerationResult();
    const contextHash = response.getContentContextHash();
    const passedModeration =
      await this.reportService.handleContentModerationResult(
        moderationResult,
        contextHash,
      );
    if (!passedModeration) {
      throw new Error(`Group create failed moderation`);
    }
    const group = Group.fromMessage(response.getGroup());

    // if you are creating a group, then you are the owner.
    group.rank = MingaGroupMemberRank.OWNER;

    // return group info to add into store.
    return {
      group: group,
      groupDetails: GroupDetails.fromMessage(response.getGroupDetails()),
    };
  }

  public async getAllGroupsRequest(
    request: gateway.mingaGroup_pb.GetMingaGroupsRequest,
  ): Promise<GetGroupsResponse> {
    const response = await this.mingaGroupService.getMingaGroups(request);
    return this.mapAllGroupResponse(response);
  }

  public mapAllGroupResponse(
    response: gateway.mingaGroup_pb.GetMingaGroupsResponse,
  ) {
    const groupList = response.getGroupList();
    const myGroupsMap = response.getMyGroupsRankMap();
    const groups = groupList.map(group => {
      let g = Group.fromMessage(group);
      let myGroup = myGroupsMap.get(g.hash);
      // not in myGroups, then they don't have a rank in the group.
      // myGroup would be 0 if pending, so we check if it's null which
      // would mean they are not in the group.
      if (myGroup != null) {
        g.rank = resolveMyGroupStatusRank(myGroup);
      }

      return g;
    });

    return { groups: groups };
  }

  public addGroupToMyGroups(group: Group): Observable<GroupComposite> {
    return from(this.joinGroupRequest(group));
  }

  private async joinGroupRequest(group: Group): Promise<GroupComposite> {
    const request = new gateway.mingaGroup_pb.JoinMingaGroupRequest();
    request.setMingaGroupHash(group.hash);
    const response = await this.mingaGroupService.join(request);

    const rank = resolveMyGroupStatusRank(response.getMyGroupsRank());

    group.rank = rank;

    const groupDetails = response.getGroupDetails();

    return {
      group: group,
      groupDetails: GroupDetails.fromMessage(response.getGroupDetails()),
    };
  }

  cancelJoinGroup(group: Group): Observable<Group> {
    return from(this.cancelGroupRequest(group));
  }

  private async cancelGroupRequest(group: Group) {
    const request = new gateway.mingaGroup_pb.CancelJoinMingaGroupRequest();
    request.setMingaGroupHash(group.hash);
    const response = await this.mingaGroupService.cancelJoin(request);
    return group;
  }

  acceptGroupMember(
    group: Group,
    personHash: string,
  ): Observable<GroupDetails | null> {
    return from(this.acceptGroupMemberRequest(group, personHash));
  }

  private async acceptGroupMemberRequest(
    group: Group,
    personHash: string,
  ): Promise<GroupDetails | null> {
    const request = new gateway.mingaGroup_pb.AcceptMingaGroupMemberRequest();
    request.setMingaGroupHash(group.hash);
    request.setPersonMemberHash(personHash);

    const response = await this.mingaGroupService.acceptMember(request);
    if (!response) return null;

    const responseObj = response.toObject();
    const details = responseObj.groupDetails;
    if (!details) return null;

    return {
      hash: details.hash,
      members: details.memberList,
    };
  }

  declineGroupMember(
    group: Group,
    personHash: string,
  ): Observable<GroupDetails | null> {
    return from(this.declineGroupMemberRequest(group, personHash));
  }

  private async declineGroupMemberRequest(
    group: Group,
    personHash: string,
  ): Promise<GroupDetails | null> {
    const request = new gateway.mingaGroup_pb.DeclineMingaGroupMemberRequest();
    request.setMingaGroupHash(group.hash);
    request.setPersonMemberHash(personHash);

    const response = await this.mingaGroupService.declineMember(request);
    if (!response) return null;

    const responseObj = response.toObject();
    const details = responseObj.groupDetails;
    if (!details) return null;

    return {
      hash: details.hash,
      members: details.memberList,
    };
  }

  public getGroupDetails(groupHash: string): Observable<GroupDetails> {
    return from(this.getGroupDetailsRequest(groupHash));
  }

  removeGroupMember(
    group: Group,
    personHash: string,
  ): Observable<GroupDetails | null> {
    return from(this.removeGroupMemberRequest(group, personHash));
  }

  private async removeGroupMemberRequest(
    group: Group,
    personHash: string,
  ): Promise<GroupDetails | null> {
    const request = new gateway.mingaGroup_pb.RemoveMingaGroupMemberRequest();
    request.setMingaGroupHash(group.hash);
    request.setPersonMemberHash(personHash);

    const response = await this.mingaGroupService.removeMember(request);
    if (!response) return null;

    const responseObj = response.toObject();
    const details = responseObj.groupDetails;
    if (!details) return null;

    return {
      hash: details.hash,
      members: details.memberList,
    };
  }

  updateMembers(
    groupHash: string,
    memberMap: Map<string, MingaGroupMemberRank | null>,
  ): Observable<GroupDetails> {
    return from(this.updateGroupMembersRequest(groupHash, memberMap));
  }

  private async updateGroupMembersRequest(
    groupHash: string,
    memberMap: Map<string, MingaGroupMemberRank | null>,
  ): Promise<GroupDetails> {
    const request = new gateway.mingaGroup_pb.UpdateMingaGroupMembersRequest();
    request.setMingaGroupHash(groupHash);
    const requestMap = request.getNewMemberRankMap();

    for (let [key, value] of memberMap.entries()) {
      const newRank = resolveMyGroupRankMemberInfo(value!);
      if (newRank === null) {
        request.addMembersToRemove(key);
      } else {
        requestMap.set(key, newRank);
        if (newRank === gateway.mingaGroup_pb.GroupMemberInfo.Rank.MEMBER) {
          request.addNewMembers(key);
        }
      }
    }

    const response = await this.mingaGroupService.updateMembers(request);
    return GroupDetails.fromMessage(response.getGroupDetails());
  }

  addMembers(
    groupHash: string,
    memberHashes: string[],
  ): Observable<GroupDetails> {
    return from(this.addGroupMembersRequest(groupHash, memberHashes));
  }

  private async addGroupMembersRequest(
    groupHash: string,
    memberHashes: string[],
  ): Promise<GroupDetails> {
    const request = new gateway.mingaGroup_pb.AddMingaGroupMembersRequest();
    request.setMingaGroupHash(groupHash);
    request.setPersonMemberHashList(memberHashes);

    const response = await this.mingaGroupService.addMembers(request);
    return GroupDetails.fromMessage(response.getGroupDetails());
  }

  private async getGroupDetailsRequest(
    groupHash: string,
  ): Promise<GroupDetails> {
    const request = new gateway.mingaGroup_pb.GetMingaGroupDetailsRequest();
    request.setMingaGroupHash(groupHash);
    const response = await this.mingaGroupService.getDetails(request);
    return GroupDetails.fromMessage(response.getGroupDetails());
  }

  removeMembers(
    groupHash: string,
    memberHashes: string[],
  ): Observable<GroupDetails> {
    return from(this.removeGroupMembersRequest(groupHash, memberHashes));
  }

  private async removeGroupMembersRequest(
    groupHash: string,
    memberHashes: string[],
  ): Promise<GroupDetails> {
    const request = new gateway.mingaGroup_pb.RemoveMingaGroupMemberRequest();
    request.setMingaGroupHash(groupHash);
    request.setMemberHashList(memberHashes);

    const response = await this.mingaGroupService.removeMember(request);
    return GroupDetails.fromMessage(response.getGroupDetails());
  }

  public deleteGroup(
    group: Group,
  ): Observable<gateway.mingaGroup_pb.DeleteMingaGroupResponse> {
    return from(this.deleteGroupRequest(group));
  }

  private async deleteGroupRequest(
    group: Group,
  ): Promise<gateway.mingaGroup_pb.DeleteMingaGroupResponse> {
    const request = new gateway.mingaGroup_pb.DeleteMingaGroupRequest();
    request.setMingaGroupHash(group.hash);
    const response = await this.mingaGroupService.deleteGroup(request);
    return response;
  }

  async addGroupFiles(groupHash: string, files: IMingaFileOptions[]) {
    const request = new gateway.mingaGroup_pb.AddMingaGroupAssetsRequest();
    request.setMingaGroupHash(groupHash);

    for (let file of files) {
      if (!file.assetPath && !file.url) {
        throw new Error(`Unable to add non asset or url group file`);
      }
      const asset = new gateway.file_pb.FileInfo();
      asset.setAssetPath(file.assetPath || '');
      asset.setUrl(file.url || '');
      asset.setName(file.title || '');

      request.addAsset(asset);
    }

    const response = await this.mingaGroupService.addFileAssets(request);

    return this._convertFilesResponse(response, groupHash);
  }

  async removeGroupFile(
    groupHash: string,
    fileHash: string,
  ): Promise<IGroupFile[]> {
    const request = new gateway.mingaGroup_pb.RemoveMingaGroupAssetRequest();
    request.setMingaGroupHash(groupHash);

    request.setFileHash(fileHash);

    const response = await this.mingaGroupService.removeFileAsset(request);
    const convertedObj = this._convertFilesResponse(response, groupHash);

    return convertedObj.files;
  }

  async getGroupFiles(groupHash: string): Promise<IGroupFile[]> {
    const request = new gateway.mingaGroup_pb.GetMingaGroupFilesRequest();
    request.setMingaGroupHash(groupHash);

    const response = await this.mingaGroupService.getFiles(request);
    const convertedObj = this._convertFilesResponse(response, groupHash);

    return convertedObj.files;
  }

  private _convertFilesResponse(
    response: gateway.mingaGroup_pb.MingaGroupFilesResponse,
    groupHash: string,
  ): GroupFiles {
    if (response.hasFiles()) {
      return GroupFiles.fromMessage(response.getFiles());
    }
    return {
      hash: groupHash,
      files: [],
    };
  }
}
