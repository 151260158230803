import {
  AfterContentInit,
  ChangeDetectionStrategy,
  Component,
  Input,
  ViewChild,
} from '@angular/core';
import { MatColumnDef } from '@angular/material/table';

import { ColumnInfo, TemplateColumnKeys } from 'libs/shared';

import { ReportDatasourceService } from '@app/src/app/components/manager-report/services/report-datasource.service';

import { ReportTableColumnTemplateComponent } from './rt-column-template.component';

@Component({
  selector: 'mg-edit-column',
  styles: ["[class*='w-'] {flex: initial; min-width: 0 !important }"],
  template: `<ng-container matColumnDef="{{ datasource.TEMPLATE_KEYS.EDIT }}">
    <mat-header-cell
      *matHeaderCellDef
      [mat-sort-header]="datasource.TEMPLATE_KEYS.EDIT"
      [disabled]="true"
      [ngClass]="editColumn.columnClasses">
    </mat-header-cell>
    <mat-cell
      *matCellDef="let item; let i = index"
      class="button-container"
      [ngClass]="editColumn.columnClasses">
      <mg-btn
        variant="icon"
        icon="mg-edit"
        iconSet="minga"
        tooltip="Edit"
        (pressed)="datasource.editItem(item, i)">
      </mg-btn>
    </mat-cell>
  </ng-container>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: ReportTableColumnTemplateComponent,
      useExisting: ReportTableEditTemplateComponent,
    },
  ],
})
export class ReportTableEditTemplateComponent implements AfterContentInit {
  @ViewChild(MatColumnDef, { static: true }) columnDef: MatColumnDef;

  editColumn: ColumnInfo;

  @Input()
  datasource: ReportDatasourceService<any>;

  constructor() {}

  ngAfterContentInit(): void {
    this.editColumn = this.datasource
      .getTemplateColumns()
      .find(col => col.key === TemplateColumnKeys.EDIT);
  }
}
