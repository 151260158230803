import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { MgButtonModule } from '@app/src/app/button';
import { StepIndicatorModule } from '@app/src/app/components/StepIndicator';
import { FtueVideoModule } from '@app/src/app/ftue/common/FtueVideo';
import { MgIconModule } from '@app/src/app/icon';

import { WhatsNewComponent } from './WhatsNew.component';

@NgModule({
  imports: [
    // Minga dependencies
    MgIconModule,
    MgButtonModule,
    FtueVideoModule,
    StepIndicatorModule,

    // External dependencies
    CommonModule,
  ],
  declarations: [WhatsNewComponent],
  exports: [WhatsNewComponent],
})
export class WhatsNewModule {}
