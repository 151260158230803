// source: gateway/landing.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

import * as jspb from 'google-protobuf';
export namespace MingaUserPasswordCreds {
  export interface AsObject {
    password: string;
    emailVerificationCode: string;
  }
}
export class MingaUserPasswordCreds extends jspb.Message {
  static readonly displayName = "MingaUserPasswordCreds";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): MingaUserPasswordCreds.AsObject {
    return MingaUserPasswordCreds.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!MingaUserPasswordCreds} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: MingaUserPasswordCreds) {
    var f: any;
    var obj: any = {
      password: jspb.Message.getFieldWithDefault(msg, 1, ""),
      emailVerificationCode: jspb.Message.getFieldWithDefault(msg, 2, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!MingaUserPasswordCreds}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new MingaUserPasswordCreds;
    return MingaUserPasswordCreds.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!MingaUserPasswordCreds} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!MingaUserPasswordCreds}
   */
  static deserializeBinaryFromReader(msg: MingaUserPasswordCreds, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setPassword(value1);
        break;
      case 2:
        var value2 = /** @type {string} */ (reader.readString());
        msg.setEmailVerificationCode(value2);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!MingaUserPasswordCreds} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: MingaUserPasswordCreds, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getPassword();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
    f = message.getEmailVerificationCode();
    if (f.length > 0) {
      writer.writeString(
        2,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    MingaUserPasswordCreds.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string password = 1;
   * @return {string}
   */
  getPassword(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setPassword(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


  /**
   * optional string email_verification_code = 2;
   * @return {string}
   */
  getEmailVerificationCode(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
  };


  /** @param {string} value */
  setEmailVerificationCode(value: string) {
    jspb.Message.setProto3StringField(this, 2, value);
  }


}
export namespace MingaUserSsoCreds {
  export interface AsObject {
    detachedSsoIdToken: string;
  }
}
export class MingaUserSsoCreds extends jspb.Message {
  static readonly displayName = "MingaUserSsoCreds";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): MingaUserSsoCreds.AsObject {
    return MingaUserSsoCreds.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!MingaUserSsoCreds} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: MingaUserSsoCreds) {
    var f: any;
    var obj: any = {
      detachedSsoIdToken: jspb.Message.getFieldWithDefault(msg, 1, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!MingaUserSsoCreds}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new MingaUserSsoCreds;
    return MingaUserSsoCreds.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!MingaUserSsoCreds} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!MingaUserSsoCreds}
   */
  static deserializeBinaryFromReader(msg: MingaUserSsoCreds, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setDetachedSsoIdToken(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!MingaUserSsoCreds} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: MingaUserSsoCreds, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getDetachedSsoIdToken();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    MingaUserSsoCreds.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string detached_sso_id_token = 1;
   * @return {string}
   */
  getDetachedSsoIdToken(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setDetachedSsoIdToken(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


}
export namespace SendEmailVerificationRequest {
  export interface AsObject {
    email: string;
  }
}
export class SendEmailVerificationRequest extends jspb.Message {
  static readonly displayName = "SendEmailVerificationRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): SendEmailVerificationRequest.AsObject {
    return SendEmailVerificationRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!SendEmailVerificationRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: SendEmailVerificationRequest) {
    var f: any;
    var obj: any = {
      email: jspb.Message.getFieldWithDefault(msg, 1, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!SendEmailVerificationRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new SendEmailVerificationRequest;
    return SendEmailVerificationRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!SendEmailVerificationRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!SendEmailVerificationRequest}
   */
  static deserializeBinaryFromReader(msg: SendEmailVerificationRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setEmail(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!SendEmailVerificationRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: SendEmailVerificationRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getEmail();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    SendEmailVerificationRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string email = 1;
   * @return {string}
   */
  getEmail(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setEmail(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


}
export namespace SendEmailVerificationResponse {
  export interface AsObject {
  }
}
export class SendEmailVerificationResponse extends jspb.Message {
  static readonly displayName = "SendEmailVerificationResponse";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): SendEmailVerificationResponse.AsObject {
    return SendEmailVerificationResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!SendEmailVerificationResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: SendEmailVerificationResponse) {
    var f: any;
    var obj: any = {

    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!SendEmailVerificationResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new SendEmailVerificationResponse;
    return SendEmailVerificationResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!SendEmailVerificationResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!SendEmailVerificationResponse}
   */
  static deserializeBinaryFromReader(msg: SendEmailVerificationResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!SendEmailVerificationResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: SendEmailVerificationResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    SendEmailVerificationResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
}
export namespace VerifyEmailRequest {
  export interface AsObject {
    email: string;
    verificationCode: string;
    firstName: string;
    lastName: string;
    createFlow: boolean;
  }
}
export class VerifyEmailRequest extends jspb.Message {
  static readonly displayName = "VerifyEmailRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): VerifyEmailRequest.AsObject {
    return VerifyEmailRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!VerifyEmailRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: VerifyEmailRequest) {
    var f: any;
    var obj: any = {
      email: jspb.Message.getFieldWithDefault(msg, 1, ""),
      verificationCode: jspb.Message.getFieldWithDefault(msg, 2, ""),
      firstName: jspb.Message.getFieldWithDefault(msg, 3, ""),
      lastName: jspb.Message.getFieldWithDefault(msg, 4, ""),
      createFlow: jspb.Message.getBooleanFieldWithDefault(msg, 5, false)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!VerifyEmailRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new VerifyEmailRequest;
    return VerifyEmailRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!VerifyEmailRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!VerifyEmailRequest}
   */
  static deserializeBinaryFromReader(msg: VerifyEmailRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setEmail(value1);
        break;
      case 2:
        var value2 = /** @type {string} */ (reader.readString());
        msg.setVerificationCode(value2);
        break;
      case 3:
        var value3 = /** @type {string} */ (reader.readString());
        msg.setFirstName(value3);
        break;
      case 4:
        var value4 = /** @type {string} */ (reader.readString());
        msg.setLastName(value4);
        break;
      case 5:
        var value5 = /** @type {boolean} */ (reader.readBool());
        msg.setCreateFlow(value5);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!VerifyEmailRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: VerifyEmailRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getEmail();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
    f = message.getVerificationCode();
    if (f.length > 0) {
      writer.writeString(
        2,
        f
      );
    }
    f = message.getFirstName();
    if (f.length > 0) {
      writer.writeString(
        3,
        f
      );
    }
    f = message.getLastName();
    if (f.length > 0) {
      writer.writeString(
        4,
        f
      );
    }
    f = message.getCreateFlow();
    if (f) {
      writer.writeBool(
        5,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    VerifyEmailRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string email = 1;
   * @return {string}
   */
  getEmail(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setEmail(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


  /**
   * optional string verification_code = 2;
   * @return {string}
   */
  getVerificationCode(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
  };


  /** @param {string} value */
  setVerificationCode(value: string) {
    jspb.Message.setProto3StringField(this, 2, value);
  }


  /**
   * optional string first_name = 3;
   * @return {string}
   */
  getFirstName(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
  };


  /** @param {string} value */
  setFirstName(value: string) {
    jspb.Message.setProto3StringField(this, 3, value);
  }


  /**
   * optional string last_name = 4;
   * @return {string}
   */
  getLastName(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
  };


  /** @param {string} value */
  setLastName(value: string) {
    jspb.Message.setProto3StringField(this, 4, value);
  }


  /**
   * optional bool create_flow = 5;
   * @return {boolean}
   */
  getCreateFlow(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
  };


  /** @param {boolean} value */
  setCreateFlow(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 5, value);
  }


}
export namespace VerifyEmailResponse {
  export interface AsObject {
    emailVerified: boolean;
  }
}
export class VerifyEmailResponse extends jspb.Message {
  static readonly displayName = "VerifyEmailResponse";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): VerifyEmailResponse.AsObject {
    return VerifyEmailResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!VerifyEmailResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: VerifyEmailResponse) {
    var f: any;
    var obj: any = {
      emailVerified: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!VerifyEmailResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new VerifyEmailResponse;
    return VerifyEmailResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!VerifyEmailResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!VerifyEmailResponse}
   */
  static deserializeBinaryFromReader(msg: VerifyEmailResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {boolean} */ (reader.readBool());
        msg.setEmailVerified(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!VerifyEmailResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: VerifyEmailResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getEmailVerified();
    if (f) {
      writer.writeBool(
        1,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    VerifyEmailResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional bool email_verified = 1;
   * @return {boolean}
   */
  getEmailVerified(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
  };


  /** @param {boolean} value */
  setEmailVerified(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 1, value);
  }


}
export namespace CreateMingaRequest {
  export interface AsObject {
    deprecatedCreatorEmailVerificationCode: string;
    deprecatedCreatorPassword: string;
    creator?: CreateMingaRequest.PersonInfo.AsObject;
    mingaDetails?: CreateMingaRequest.MingaInfo.AsObject;
    flowIdentifier?: CreateMingaRequest.FlowIdentifier;
    passwordCreds?: MingaUserPasswordCreds.AsObject;
    ssoCreds?: MingaUserSsoCreds.AsObject;
  }
}
export class CreateMingaRequest extends jspb.Message {
  static readonly displayName = "CreateMingaRequest";
  /**
   * Oneof group definitions for this message. Each group defines the field
   * numbers belonging to that group. When of these fields' value is set, all
   * other fields in the group are cleared. During deserialization, if multiple
   * fields are encountered for a group, only the last value seen will be kept.
   * @private {!Array<!Array<number>>}
   * @const
   */
  static readonly oneofGroups_ = [[6,7]];


  /**
   * @return {CreateMingaRequest.CreatorUserCredsCase}
   */
  getCreatorUserCredsCase() {
    return /** @type {CreateMingaRequest.CreatorUserCredsCase} */(jspb.Message.computeOneofCase(this, CreateMingaRequest.oneofGroups_[0]));
  }

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, CreateMingaRequest.oneofGroups_);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): CreateMingaRequest.AsObject {
    return CreateMingaRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!CreateMingaRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: CreateMingaRequest) {
    var f: any;
    var obj: any = {
      deprecatedCreatorEmailVerificationCode: jspb.Message.getFieldWithDefault(msg, 1, ""),
      deprecatedCreatorPassword: jspb.Message.getFieldWithDefault(msg, 2, ""),
      creator: (f = msg.getCreator()) && CreateMingaRequest.PersonInfo.toObject(includeInstance, f),
      mingaDetails: (f = msg.getMingaDetails()) && CreateMingaRequest.MingaInfo.toObject(includeInstance, f),
      flowIdentifier: jspb.Message.getFieldWithDefault(msg, 5, 0),
      passwordCreds: (f = msg.getPasswordCreds()) && MingaUserPasswordCreds.toObject(includeInstance, f),
      ssoCreds: (f = msg.getSsoCreds()) && MingaUserSsoCreds.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!CreateMingaRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new CreateMingaRequest;
    return CreateMingaRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!CreateMingaRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!CreateMingaRequest}
   */
  static deserializeBinaryFromReader(msg: CreateMingaRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setDeprecatedCreatorEmailVerificationCode(value1);
        break;
      case 2:
        var value2 = /** @type {string} */ (reader.readString());
        msg.setDeprecatedCreatorPassword(value2);
        break;
      case 3:
        var value3 = new CreateMingaRequest.PersonInfo;
        reader.readMessage(value3,CreateMingaRequest.PersonInfo.deserializeBinaryFromReader);
        msg.setCreator(value3);
        break;
      case 4:
        var value4 = new CreateMingaRequest.MingaInfo;
        reader.readMessage(value4,CreateMingaRequest.MingaInfo.deserializeBinaryFromReader);
        msg.setMingaDetails(value4);
        break;
      case 5:
        var value5 = /** @type {!CreateMingaRequest.FlowIdentifier} */ (reader.readEnum());
        msg.setFlowIdentifier(value5);
        break;
      case 6:
        var value6 = new MingaUserPasswordCreds;
        reader.readMessage(value6,MingaUserPasswordCreds.deserializeBinaryFromReader);
        msg.setPasswordCreds(value6);
        break;
      case 7:
        var value7 = new MingaUserSsoCreds;
        reader.readMessage(value7,MingaUserSsoCreds.deserializeBinaryFromReader);
        msg.setSsoCreds(value7);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!CreateMingaRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: CreateMingaRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getDeprecatedCreatorEmailVerificationCode();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
    f = message.getDeprecatedCreatorPassword();
    if (f.length > 0) {
      writer.writeString(
        2,
        f
      );
    }
    f = message.getCreator();
    if (f != null) {
      writer.writeMessage(
        3,
        f,
        CreateMingaRequest.PersonInfo.serializeBinaryToWriter
      );
    }
    f = message.getMingaDetails();
    if (f != null) {
      writer.writeMessage(
        4,
        f,
        CreateMingaRequest.MingaInfo.serializeBinaryToWriter
      );
    }
    f = message.getFlowIdentifier();
    if (f !== 0.0) {
      writer.writeEnum(
        5,
        f
      );
    }
    f = message.getPasswordCreds();
    if (f != null) {
      writer.writeMessage(
        6,
        f,
        MingaUserPasswordCreds.serializeBinaryToWriter
      );
    }
    f = message.getSsoCreds();
    if (f != null) {
      writer.writeMessage(
        7,
        f,
        MingaUserSsoCreds.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    CreateMingaRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string deprecated_creator_email_verification_code = 1;
   * @return {string}
   */
  getDeprecatedCreatorEmailVerificationCode(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setDeprecatedCreatorEmailVerificationCode(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


  /**
   * optional string deprecated_creator_password = 2;
   * @return {string}
   */
  getDeprecatedCreatorPassword(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
  };


  /** @param {string} value */
  setDeprecatedCreatorPassword(value: string) {
    jspb.Message.setProto3StringField(this, 2, value);
  }


  /**
   * optional PersonInfo creator = 3;
   * @return {?CreateMingaRequest.PersonInfo}
   */
  getCreator(): CreateMingaRequest.PersonInfo {
    return /** @type{?CreateMingaRequest.PersonInfo} */ (
      jspb.Message.getWrapperField(this, CreateMingaRequest.PersonInfo, 3));
  }


  /** @param {?CreateMingaRequest.PersonInfo|undefined} value */
  setCreator(value?: CreateMingaRequest.PersonInfo) {
    jspb.Message.setWrapperField(this, 3, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearCreator() {
    this.setCreator(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasCreator(): boolean {
    return jspb.Message.getField(this, 3) != null;
  }


  /**
   * optional MingaInfo minga_details = 4;
   * @return {?CreateMingaRequest.MingaInfo}
   */
  getMingaDetails(): CreateMingaRequest.MingaInfo {
    return /** @type{?CreateMingaRequest.MingaInfo} */ (
      jspb.Message.getWrapperField(this, CreateMingaRequest.MingaInfo, 4));
  }


  /** @param {?CreateMingaRequest.MingaInfo|undefined} value */
  setMingaDetails(value?: CreateMingaRequest.MingaInfo) {
    jspb.Message.setWrapperField(this, 4, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearMingaDetails() {
    this.setMingaDetails(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasMingaDetails(): boolean {
    return jspb.Message.getField(this, 4) != null;
  }


  /**
   * optional FlowIdentifier flow_identifier = 5;
   * @return {!CreateMingaRequest.FlowIdentifier}
   */
  getFlowIdentifier(): CreateMingaRequest.FlowIdentifier {
    return /** @type {!CreateMingaRequest.FlowIdentifier} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
  };


  /** @param {!CreateMingaRequest.FlowIdentifier} value */
  setFlowIdentifier(value: CreateMingaRequest.FlowIdentifier) {
    jspb.Message.setProto3EnumField(this, 5, value);
  }


  /**
   * optional MingaUserPasswordCreds password_creds = 6;
   * @return {?MingaUserPasswordCreds}
   */
  getPasswordCreds(): MingaUserPasswordCreds {
    return /** @type{?MingaUserPasswordCreds} */ (
      jspb.Message.getWrapperField(this, MingaUserPasswordCreds, 6));
  }


  /** @param {?MingaUserPasswordCreds|undefined} value */
  setPasswordCreds(value?: MingaUserPasswordCreds) {
    jspb.Message.setOneofWrapperField(this, 6, CreateMingaRequest.oneofGroups_[0], value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearPasswordCreds() {
    this.setPasswordCreds(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasPasswordCreds(): boolean {
    return jspb.Message.getField(this, 6) != null;
  }


  /**
   * optional MingaUserSsoCreds sso_creds = 7;
   * @return {?MingaUserSsoCreds}
   */
  getSsoCreds(): MingaUserSsoCreds {
    return /** @type{?MingaUserSsoCreds} */ (
      jspb.Message.getWrapperField(this, MingaUserSsoCreds, 7));
  }


  /** @param {?MingaUserSsoCreds|undefined} value */
  setSsoCreds(value?: MingaUserSsoCreds) {
    jspb.Message.setOneofWrapperField(this, 7, CreateMingaRequest.oneofGroups_[0], value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearSsoCreds() {
    this.setSsoCreds(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasSsoCreds(): boolean {
    return jspb.Message.getField(this, 7) != null;
  }


}
export namespace CreateMingaRequest {
export enum CreatorUserCredsCase {
  CREATOR_USER_CREDS_NOT_SET = 0,
  PASSWORD_CREDS = 6,
  SSO_CREDS = 7,
}
} // namespace CreateMingaRequest
export namespace CreateMingaRequest {
export enum FlowIdentifier {
  TEACHER = 0,
  SCHOOL_ADMIN = 1,
}
} // namespace CreateMingaRequest
export namespace CreateMingaRequest {
export namespace PersonInfo {
  export interface AsObject {
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
  }
}
export class PersonInfo extends jspb.Message {
  static readonly displayName = "CreateMingaRequest.PersonInfo";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): PersonInfo.AsObject {
    return PersonInfo.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!PersonInfo} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: PersonInfo) {
    var f: any;
    var obj: any = {
      firstName: jspb.Message.getFieldWithDefault(msg, 1, ""),
      lastName: jspb.Message.getFieldWithDefault(msg, 2, ""),
      email: jspb.Message.getFieldWithDefault(msg, 3, ""),
      phone: jspb.Message.getFieldWithDefault(msg, 4, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!PersonInfo}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new PersonInfo;
    return PersonInfo.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!CreateMingaRequest.PersonInfo} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!CreateMingaRequest.PersonInfo}
   */
  static deserializeBinaryFromReader(msg: PersonInfo, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setFirstName(value1);
        break;
      case 2:
        var value2 = /** @type {string} */ (reader.readString());
        msg.setLastName(value2);
        break;
      case 3:
        var value3 = /** @type {string} */ (reader.readString());
        msg.setEmail(value3);
        break;
      case 4:
        var value4 = /** @type {string} */ (reader.readString());
        msg.setPhone(value4);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!CreateMingaRequest.PersonInfo} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: CreateMingaRequest.PersonInfo, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getFirstName();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
    f = message.getLastName();
    if (f.length > 0) {
      writer.writeString(
        2,
        f
      );
    }
    f = message.getEmail();
    if (f.length > 0) {
      writer.writeString(
        3,
        f
      );
    }
    f = message.getPhone();
    if (f.length > 0) {
      writer.writeString(
        4,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    CreateMingaRequest.PersonInfo.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string first_name = 1;
   * @return {string}
   */
  getFirstName(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setFirstName(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


  /**
   * optional string last_name = 2;
   * @return {string}
   */
  getLastName(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
  };


  /** @param {string} value */
  setLastName(value: string) {
    jspb.Message.setProto3StringField(this, 2, value);
  }


  /**
   * optional string email = 3;
   * @return {string}
   */
  getEmail(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
  };


  /** @param {string} value */
  setEmail(value: string) {
    jspb.Message.setProto3StringField(this, 3, value);
  }


  /**
   * optional string phone = 4;
   * @return {string}
   */
  getPhone(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
  };


  /** @param {string} value */
  setPhone(value: string) {
    jspb.Message.setProto3StringField(this, 4, value);
  }


}
} // namespace CreateMingaRequest
export namespace CreateMingaRequest {
export namespace MingaInfo {
  export interface AsObject {
    name: string;
    placeId: string;
  }
}
export class MingaInfo extends jspb.Message {
  static readonly displayName = "CreateMingaRequest.MingaInfo";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): MingaInfo.AsObject {
    return MingaInfo.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!MingaInfo} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: MingaInfo) {
    var f: any;
    var obj: any = {
      name: jspb.Message.getFieldWithDefault(msg, 1, ""),
      placeId: jspb.Message.getFieldWithDefault(msg, 2, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!MingaInfo}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new MingaInfo;
    return MingaInfo.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!CreateMingaRequest.MingaInfo} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!CreateMingaRequest.MingaInfo}
   */
  static deserializeBinaryFromReader(msg: MingaInfo, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setName(value1);
        break;
      case 2:
        var value2 = /** @type {string} */ (reader.readString());
        msg.setPlaceId(value2);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!CreateMingaRequest.MingaInfo} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: CreateMingaRequest.MingaInfo, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getName();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
    f = message.getPlaceId();
    if (f.length > 0) {
      writer.writeString(
        2,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    CreateMingaRequest.MingaInfo.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string name = 1;
   * @return {string}
   */
  getName(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setName(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


  /**
   * optional string place_id = 2;
   * @return {string}
   */
  getPlaceId(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
  };


  /** @param {string} value */
  setPlaceId(value: string) {
    jspb.Message.setProto3StringField(this, 2, value);
  }


}
} // namespace CreateMingaRequest
export namespace CreateMingaResponse {
  export interface AsObject {
    progressUpdate?: CreateMingaResponse.ProgressUpdate.AsObject;
    creatorAuth?: CreateMingaResponse.CreatorAuth.AsObject;
    mingaDetails?: CreateMingaResponse.MingaDetails.AsObject;
  }
}
export class CreateMingaResponse extends jspb.Message {
  static readonly displayName = "CreateMingaResponse";
  /**
   * Oneof group definitions for this message. Each group defines the field
   * numbers belonging to that group. When of these fields' value is set, all
   * other fields in the group are cleared. During deserialization, if multiple
   * fields are encountered for a group, only the last value seen will be kept.
   * @private {!Array<!Array<number>>}
   * @const
   */
  static readonly oneofGroups_ = [[1,2,3]];


  /**
   * @return {CreateMingaResponse.ProgressResponsesCase}
   */
  getProgressResponsesCase() {
    return /** @type {CreateMingaResponse.ProgressResponsesCase} */(jspb.Message.computeOneofCase(this, CreateMingaResponse.oneofGroups_[0]));
  }

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, CreateMingaResponse.oneofGroups_);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): CreateMingaResponse.AsObject {
    return CreateMingaResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!CreateMingaResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: CreateMingaResponse) {
    var f: any;
    var obj: any = {
      progressUpdate: (f = msg.getProgressUpdate()) && CreateMingaResponse.ProgressUpdate.toObject(includeInstance, f),
      creatorAuth: (f = msg.getCreatorAuth()) && CreateMingaResponse.CreatorAuth.toObject(includeInstance, f),
      mingaDetails: (f = msg.getMingaDetails()) && CreateMingaResponse.MingaDetails.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!CreateMingaResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new CreateMingaResponse;
    return CreateMingaResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!CreateMingaResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!CreateMingaResponse}
   */
  static deserializeBinaryFromReader(msg: CreateMingaResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new CreateMingaResponse.ProgressUpdate;
        reader.readMessage(value1,CreateMingaResponse.ProgressUpdate.deserializeBinaryFromReader);
        msg.setProgressUpdate(value1);
        break;
      case 2:
        var value2 = new CreateMingaResponse.CreatorAuth;
        reader.readMessage(value2,CreateMingaResponse.CreatorAuth.deserializeBinaryFromReader);
        msg.setCreatorAuth(value2);
        break;
      case 3:
        var value3 = new CreateMingaResponse.MingaDetails;
        reader.readMessage(value3,CreateMingaResponse.MingaDetails.deserializeBinaryFromReader);
        msg.setMingaDetails(value3);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!CreateMingaResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: CreateMingaResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getProgressUpdate();
    if (f != null) {
      writer.writeMessage(
        1,
        f,
        CreateMingaResponse.ProgressUpdate.serializeBinaryToWriter
      );
    }
    f = message.getCreatorAuth();
    if (f != null) {
      writer.writeMessage(
        2,
        f,
        CreateMingaResponse.CreatorAuth.serializeBinaryToWriter
      );
    }
    f = message.getMingaDetails();
    if (f != null) {
      writer.writeMessage(
        3,
        f,
        CreateMingaResponse.MingaDetails.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    CreateMingaResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional ProgressUpdate progress_update = 1;
   * @return {?CreateMingaResponse.ProgressUpdate}
   */
  getProgressUpdate(): CreateMingaResponse.ProgressUpdate {
    return /** @type{?CreateMingaResponse.ProgressUpdate} */ (
      jspb.Message.getWrapperField(this, CreateMingaResponse.ProgressUpdate, 1));
  }


  /** @param {?CreateMingaResponse.ProgressUpdate|undefined} value */
  setProgressUpdate(value?: CreateMingaResponse.ProgressUpdate) {
    jspb.Message.setOneofWrapperField(this, 1, CreateMingaResponse.oneofGroups_[0], value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearProgressUpdate() {
    this.setProgressUpdate(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasProgressUpdate(): boolean {
    return jspb.Message.getField(this, 1) != null;
  }


  /**
   * optional CreatorAuth creator_auth = 2;
   * @return {?CreateMingaResponse.CreatorAuth}
   */
  getCreatorAuth(): CreateMingaResponse.CreatorAuth {
    return /** @type{?CreateMingaResponse.CreatorAuth} */ (
      jspb.Message.getWrapperField(this, CreateMingaResponse.CreatorAuth, 2));
  }


  /** @param {?CreateMingaResponse.CreatorAuth|undefined} value */
  setCreatorAuth(value?: CreateMingaResponse.CreatorAuth) {
    jspb.Message.setOneofWrapperField(this, 2, CreateMingaResponse.oneofGroups_[0], value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearCreatorAuth() {
    this.setCreatorAuth(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasCreatorAuth(): boolean {
    return jspb.Message.getField(this, 2) != null;
  }


  /**
   * optional MingaDetails minga_details = 3;
   * @return {?CreateMingaResponse.MingaDetails}
   */
  getMingaDetails(): CreateMingaResponse.MingaDetails {
    return /** @type{?CreateMingaResponse.MingaDetails} */ (
      jspb.Message.getWrapperField(this, CreateMingaResponse.MingaDetails, 3));
  }


  /** @param {?CreateMingaResponse.MingaDetails|undefined} value */
  setMingaDetails(value?: CreateMingaResponse.MingaDetails) {
    jspb.Message.setOneofWrapperField(this, 3, CreateMingaResponse.oneofGroups_[0], value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearMingaDetails() {
    this.setMingaDetails(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasMingaDetails(): boolean {
    return jspb.Message.getField(this, 3) != null;
  }


}
export namespace CreateMingaResponse {
export enum ProgressResponsesCase {
  PROGRESS_RESPONSES_NOT_SET = 0,
  PROGRESS_UPDATE = 1,
  CREATOR_AUTH = 2,
  MINGA_DETAILS = 3,
}
} // namespace CreateMingaResponse
export namespace CreateMingaResponse {
export namespace ProgressUpdate {
  export interface AsObject {
    progressPercentage: number;
    progressName: string;
  }
}
export class ProgressUpdate extends jspb.Message {
  static readonly displayName = "CreateMingaResponse.ProgressUpdate";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): ProgressUpdate.AsObject {
    return ProgressUpdate.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!ProgressUpdate} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: ProgressUpdate) {
    var f: any;
    var obj: any = {
      progressPercentage: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
      progressName: jspb.Message.getFieldWithDefault(msg, 2, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!ProgressUpdate}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new ProgressUpdate;
    return ProgressUpdate.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!CreateMingaResponse.ProgressUpdate} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!CreateMingaResponse.ProgressUpdate}
   */
  static deserializeBinaryFromReader(msg: ProgressUpdate, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {number} */ (reader.readFloat());
        msg.setProgressPercentage(value1);
        break;
      case 2:
        var value2 = /** @type {string} */ (reader.readString());
        msg.setProgressName(value2);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!CreateMingaResponse.ProgressUpdate} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: CreateMingaResponse.ProgressUpdate, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getProgressPercentage();
    if (f !== 0.0) {
      writer.writeFloat(
        1,
        f
      );
    }
    f = message.getProgressName();
    if (f.length > 0) {
      writer.writeString(
        2,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    CreateMingaResponse.ProgressUpdate.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional float progress_percentage = 1;
   * @return {number}
   */
  getProgressPercentage(): number {
    return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
  };


  /** @param {number} value */
  setProgressPercentage(value: number) {
    jspb.Message.setProto3FloatField(this, 1, value);
  }


  /**
   * optional string progress_name = 2;
   * @return {string}
   */
  getProgressName(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
  };


  /** @param {string} value */
  setProgressName(value: string) {
    jspb.Message.setProto3StringField(this, 2, value);
  }


}
} // namespace CreateMingaResponse
export namespace CreateMingaResponse {
export namespace CreatorAuth {
  export interface AsObject {
    customAuthToken: string;
  }
}
export class CreatorAuth extends jspb.Message {
  static readonly displayName = "CreateMingaResponse.CreatorAuth";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): CreatorAuth.AsObject {
    return CreatorAuth.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!CreatorAuth} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: CreatorAuth) {
    var f: any;
    var obj: any = {
      customAuthToken: jspb.Message.getFieldWithDefault(msg, 1, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!CreatorAuth}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new CreatorAuth;
    return CreatorAuth.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!CreateMingaResponse.CreatorAuth} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!CreateMingaResponse.CreatorAuth}
   */
  static deserializeBinaryFromReader(msg: CreatorAuth, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setCustomAuthToken(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!CreateMingaResponse.CreatorAuth} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: CreateMingaResponse.CreatorAuth, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getCustomAuthToken();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    CreateMingaResponse.CreatorAuth.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string custom_auth_token = 1;
   * @return {string}
   */
  getCustomAuthToken(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setCustomAuthToken(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


}
} // namespace CreateMingaResponse
export namespace CreateMingaResponse {
export namespace MingaDetails {
  export interface AsObject {
    mingaHash: string;
  }
}
export class MingaDetails extends jspb.Message {
  static readonly displayName = "CreateMingaResponse.MingaDetails";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): MingaDetails.AsObject {
    return MingaDetails.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!MingaDetails} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: MingaDetails) {
    var f: any;
    var obj: any = {
      mingaHash: jspb.Message.getFieldWithDefault(msg, 1, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!MingaDetails}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new MingaDetails;
    return MingaDetails.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!CreateMingaResponse.MingaDetails} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!CreateMingaResponse.MingaDetails}
   */
  static deserializeBinaryFromReader(msg: MingaDetails, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setMingaHash(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!CreateMingaResponse.MingaDetails} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: CreateMingaResponse.MingaDetails, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getMingaHash();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    CreateMingaResponse.MingaDetails.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string minga_hash = 1;
   * @return {string}
   */
  getMingaHash(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setMingaHash(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


}
} // namespace CreateMingaResponse
export namespace VerifyPinOrCodeRequest {
  export interface AsObject {
    pinOrCode: string;
  }
}
export class VerifyPinOrCodeRequest extends jspb.Message {
  static readonly displayName = "VerifyPinOrCodeRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): VerifyPinOrCodeRequest.AsObject {
    return VerifyPinOrCodeRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!VerifyPinOrCodeRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: VerifyPinOrCodeRequest) {
    var f: any;
    var obj: any = {
      pinOrCode: jspb.Message.getFieldWithDefault(msg, 1, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!VerifyPinOrCodeRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new VerifyPinOrCodeRequest;
    return VerifyPinOrCodeRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!VerifyPinOrCodeRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!VerifyPinOrCodeRequest}
   */
  static deserializeBinaryFromReader(msg: VerifyPinOrCodeRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setPinOrCode(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!VerifyPinOrCodeRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: VerifyPinOrCodeRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getPinOrCode();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    VerifyPinOrCodeRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string pin_or_code = 1;
   * @return {string}
   */
  getPinOrCode(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setPinOrCode(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


}
export namespace VerifyPinOrCodeResponse {
  export interface AsObject {
    pin?: VerifyPinOrCodeResponse.PinDetails.AsObject;
    code?: VerifyPinOrCodeResponse.CodeDetails.AsObject;
    fullMinga: boolean;
  }
}
export class VerifyPinOrCodeResponse extends jspb.Message {
  static readonly displayName = "VerifyPinOrCodeResponse";
  /**
   * Oneof group definitions for this message. Each group defines the field
   * numbers belonging to that group. When of these fields' value is set, all
   * other fields in the group are cleared. During deserialization, if multiple
   * fields are encountered for a group, only the last value seen will be kept.
   * @private {!Array<!Array<number>>}
   * @const
   */
  static readonly oneofGroups_ = [[1,2]];


  /**
   * @return {VerifyPinOrCodeResponse.DetailsCase}
   */
  getDetailsCase() {
    return /** @type {VerifyPinOrCodeResponse.DetailsCase} */(jspb.Message.computeOneofCase(this, VerifyPinOrCodeResponse.oneofGroups_[0]));
  }

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, VerifyPinOrCodeResponse.oneofGroups_);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): VerifyPinOrCodeResponse.AsObject {
    return VerifyPinOrCodeResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!VerifyPinOrCodeResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: VerifyPinOrCodeResponse) {
    var f: any;
    var obj: any = {
      pin: (f = msg.getPin()) && VerifyPinOrCodeResponse.PinDetails.toObject(includeInstance, f),
      code: (f = msg.getCode()) && VerifyPinOrCodeResponse.CodeDetails.toObject(includeInstance, f),
      fullMinga: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!VerifyPinOrCodeResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new VerifyPinOrCodeResponse;
    return VerifyPinOrCodeResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!VerifyPinOrCodeResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!VerifyPinOrCodeResponse}
   */
  static deserializeBinaryFromReader(msg: VerifyPinOrCodeResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new VerifyPinOrCodeResponse.PinDetails;
        reader.readMessage(value1,VerifyPinOrCodeResponse.PinDetails.deserializeBinaryFromReader);
        msg.setPin(value1);
        break;
      case 2:
        var value2 = new VerifyPinOrCodeResponse.CodeDetails;
        reader.readMessage(value2,VerifyPinOrCodeResponse.CodeDetails.deserializeBinaryFromReader);
        msg.setCode(value2);
        break;
      case 3:
        var value3 = /** @type {boolean} */ (reader.readBool());
        msg.setFullMinga(value3);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!VerifyPinOrCodeResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: VerifyPinOrCodeResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getPin();
    if (f != null) {
      writer.writeMessage(
        1,
        f,
        VerifyPinOrCodeResponse.PinDetails.serializeBinaryToWriter
      );
    }
    f = message.getCode();
    if (f != null) {
      writer.writeMessage(
        2,
        f,
        VerifyPinOrCodeResponse.CodeDetails.serializeBinaryToWriter
      );
    }
    f = message.getFullMinga();
    if (f) {
      writer.writeBool(
        3,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    VerifyPinOrCodeResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional PinDetails pin = 1;
   * @return {?VerifyPinOrCodeResponse.PinDetails}
   */
  getPin(): VerifyPinOrCodeResponse.PinDetails {
    return /** @type{?VerifyPinOrCodeResponse.PinDetails} */ (
      jspb.Message.getWrapperField(this, VerifyPinOrCodeResponse.PinDetails, 1));
  }


  /** @param {?VerifyPinOrCodeResponse.PinDetails|undefined} value */
  setPin(value?: VerifyPinOrCodeResponse.PinDetails) {
    jspb.Message.setOneofWrapperField(this, 1, VerifyPinOrCodeResponse.oneofGroups_[0], value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearPin() {
    this.setPin(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasPin(): boolean {
    return jspb.Message.getField(this, 1) != null;
  }


  /**
   * optional CodeDetails code = 2;
   * @return {?VerifyPinOrCodeResponse.CodeDetails}
   */
  getCode(): VerifyPinOrCodeResponse.CodeDetails {
    return /** @type{?VerifyPinOrCodeResponse.CodeDetails} */ (
      jspb.Message.getWrapperField(this, VerifyPinOrCodeResponse.CodeDetails, 2));
  }


  /** @param {?VerifyPinOrCodeResponse.CodeDetails|undefined} value */
  setCode(value?: VerifyPinOrCodeResponse.CodeDetails) {
    jspb.Message.setOneofWrapperField(this, 2, VerifyPinOrCodeResponse.oneofGroups_[0], value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearCode() {
    this.setCode(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasCode(): boolean {
    return jspb.Message.getField(this, 2) != null;
  }


  /**
   * optional bool full_minga = 3;
   * @return {boolean}
   */
  getFullMinga(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
  };


  /** @param {boolean} value */
  setFullMinga(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 3, value);
  }


}
export namespace VerifyPinOrCodeResponse {
export enum DetailsCase {
  DETAILS_NOT_SET = 0,
  PIN = 1,
  CODE = 2,
}
} // namespace VerifyPinOrCodeResponse
export namespace VerifyPinOrCodeResponse {
export namespace RoleTypeInfo {
  export interface AsObject {
    roleType: string;
    roleName: string;
    allowGradeInput: boolean;
  }
}
export class RoleTypeInfo extends jspb.Message {
  static readonly displayName = "VerifyPinOrCodeResponse.RoleTypeInfo";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): RoleTypeInfo.AsObject {
    return RoleTypeInfo.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!RoleTypeInfo} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: RoleTypeInfo) {
    var f: any;
    var obj: any = {
      roleType: jspb.Message.getFieldWithDefault(msg, 1, ""),
      roleName: jspb.Message.getFieldWithDefault(msg, 2, ""),
      allowGradeInput: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!RoleTypeInfo}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new RoleTypeInfo;
    return RoleTypeInfo.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!VerifyPinOrCodeResponse.RoleTypeInfo} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!VerifyPinOrCodeResponse.RoleTypeInfo}
   */
  static deserializeBinaryFromReader(msg: RoleTypeInfo, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setRoleType(value1);
        break;
      case 2:
        var value2 = /** @type {string} */ (reader.readString());
        msg.setRoleName(value2);
        break;
      case 3:
        var value3 = /** @type {boolean} */ (reader.readBool());
        msg.setAllowGradeInput(value3);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!VerifyPinOrCodeResponse.RoleTypeInfo} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: VerifyPinOrCodeResponse.RoleTypeInfo, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getRoleType();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
    f = message.getRoleName();
    if (f.length > 0) {
      writer.writeString(
        2,
        f
      );
    }
    f = message.getAllowGradeInput();
    if (f) {
      writer.writeBool(
        3,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    VerifyPinOrCodeResponse.RoleTypeInfo.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string role_type = 1;
   * @return {string}
   */
  getRoleType(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setRoleType(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


  /**
   * optional string role_name = 2;
   * @return {string}
   */
  getRoleName(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
  };


  /** @param {string} value */
  setRoleName(value: string) {
    jspb.Message.setProto3StringField(this, 2, value);
  }


  /**
   * optional bool allow_grade_input = 3;
   * @return {boolean}
   */
  getAllowGradeInput(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
  };


  /** @param {boolean} value */
  setAllowGradeInput(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 3, value);
  }


}
} // namespace VerifyPinOrCodeResponse
export namespace VerifyPinOrCodeResponse {
export namespace PinDetails {
  export interface AsObject {
    confirmEmail: boolean;
    mingaName: string;
    mingaLogo: string;
    mingaType: string;
    allowedRoleTypeInfoList: VerifyPinOrCodeResponse.RoleTypeInfo.AsObject[];
    gradeOptionsList: string[];
  }
}
export class PinDetails extends jspb.Message {
  static readonly displayName = "VerifyPinOrCodeResponse.PinDetails";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [5,6];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, VerifyPinOrCodeResponse.PinDetails.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): PinDetails.AsObject {
    return PinDetails.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!PinDetails} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: PinDetails) {
    var f: any;
    var obj: any = {
      confirmEmail: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
      mingaName: jspb.Message.getFieldWithDefault(msg, 2, ""),
      mingaLogo: jspb.Message.getFieldWithDefault(msg, 3, ""),
      mingaType: jspb.Message.getFieldWithDefault(msg, 4, ""),
      allowedRoleTypeInfoList: jspb.Message.toObjectList(msg.getAllowedRoleTypeInfoList(),
      VerifyPinOrCodeResponse.RoleTypeInfo.toObject, includeInstance),
      gradeOptionsList: (f = jspb.Message.getRepeatedField(msg, 6)) == null ? undefined : f
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!PinDetails}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new PinDetails;
    return PinDetails.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!VerifyPinOrCodeResponse.PinDetails} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!VerifyPinOrCodeResponse.PinDetails}
   */
  static deserializeBinaryFromReader(msg: PinDetails, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {boolean} */ (reader.readBool());
        msg.setConfirmEmail(value1);
        break;
      case 2:
        var value2 = /** @type {string} */ (reader.readString());
        msg.setMingaName(value2);
        break;
      case 3:
        var value3 = /** @type {string} */ (reader.readString());
        msg.setMingaLogo(value3);
        break;
      case 4:
        var value4 = /** @type {string} */ (reader.readString());
        msg.setMingaType(value4);
        break;
      case 5:
        var value5 = new VerifyPinOrCodeResponse.RoleTypeInfo;
        reader.readMessage(value5,VerifyPinOrCodeResponse.RoleTypeInfo.deserializeBinaryFromReader);
        msg.addAllowedRoleTypeInfo(value5);
        break;
      case 6:
        var value6 = /** @type {string} */ (reader.readString());
        msg.addGradeOptions(value6);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!VerifyPinOrCodeResponse.PinDetails} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: VerifyPinOrCodeResponse.PinDetails, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getConfirmEmail();
    if (f) {
      writer.writeBool(
        1,
        f
      );
    }
    f = message.getMingaName();
    if (f.length > 0) {
      writer.writeString(
        2,
        f
      );
    }
    f = message.getMingaLogo();
    if (f.length > 0) {
      writer.writeString(
        3,
        f
      );
    }
    f = message.getMingaType();
    if (f.length > 0) {
      writer.writeString(
        4,
        f
      );
    }
    f = message.getAllowedRoleTypeInfoList();
    if (f.length > 0) {
      writer.writeRepeatedMessage(
        5,
        f,
        VerifyPinOrCodeResponse.RoleTypeInfo.serializeBinaryToWriter
      );
    }
    f = message.getGradeOptionsList();
    if (f.length > 0) {
      writer.writeRepeatedString(
        6,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    VerifyPinOrCodeResponse.PinDetails.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional bool confirm_email = 1;
   * @return {boolean}
   */
  getConfirmEmail(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
  };


  /** @param {boolean} value */
  setConfirmEmail(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 1, value);
  }


  /**
   * optional string minga_name = 2;
   * @return {string}
   */
  getMingaName(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
  };


  /** @param {string} value */
  setMingaName(value: string) {
    jspb.Message.setProto3StringField(this, 2, value);
  }


  /**
   * optional string minga_logo = 3;
   * @return {string}
   */
  getMingaLogo(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
  };


  /** @param {string} value */
  setMingaLogo(value: string) {
    jspb.Message.setProto3StringField(this, 3, value);
  }


  /**
   * optional string minga_type = 4;
   * @return {string}
   */
  getMingaType(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
  };


  /** @param {string} value */
  setMingaType(value: string) {
    jspb.Message.setProto3StringField(this, 4, value);
  }


  /**
   * repeated RoleTypeInfo allowed_role_type_info = 5;
   * @return {!Array<!VerifyPinOrCodeResponse.RoleTypeInfo>}
   */
  getAllowedRoleTypeInfoList(): VerifyPinOrCodeResponse.RoleTypeInfo[] {
    return /** @type{!Array<!VerifyPinOrCodeResponse.RoleTypeInfo>} */ (
      jspb.Message.getRepeatedWrapperField(this, VerifyPinOrCodeResponse.RoleTypeInfo, 5));
  }


  /** @param {!Array<!VerifyPinOrCodeResponse.RoleTypeInfo>} value */
  setAllowedRoleTypeInfoList(value?: VerifyPinOrCodeResponse.RoleTypeInfo[]) {
    jspb.Message.setRepeatedWrapperField(this, 5, value);
  }


  /**
   * @param {!VerifyPinOrCodeResponse.RoleTypeInfo=} opt_value
   * @param {number=} opt_index
   * @return {!VerifyPinOrCodeResponse.RoleTypeInfo}
   */
  addAllowedRoleTypeInfo(opt_value?: VerifyPinOrCodeResponse.RoleTypeInfo, opt_index?: number): VerifyPinOrCodeResponse.RoleTypeInfo {
    return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, VerifyPinOrCodeResponse.RoleTypeInfo, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearAllowedRoleTypeInfoList() {
    this.setAllowedRoleTypeInfoList([]);
  }


  /**
   * repeated string grade_options = 6;
   * @return {!Array<string>}
   */
  getGradeOptionsList(): string[] {
    return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 6));
  };


  /** @param {!Array<string>} value */
  setGradeOptionsList(value: string[]) {
    jspb.Message.setField(this, 6, value || []);
  }


  /**
   * @param {string} value
   * @param {number=} opt_index
   */
  addGradeOptions(value: string, opt_index?: number): void {
    jspb.Message.addToRepeatedField(this, 6, value, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearGradeOptionsList() {
    this.setGradeOptionsList([]);
  }


}
} // namespace VerifyPinOrCodeResponse
export namespace VerifyPinOrCodeResponse {
export namespace CodeDetails {
  export interface AsObject {
    mingaName: string;
    mingaLogo: string;
    mingaType: string;
    allowedRoleTypeInfoList: VerifyPinOrCodeResponse.RoleTypeInfo.AsObject[];
    gradeOptionsList: string[];
  }
}
export class CodeDetails extends jspb.Message {
  static readonly displayName = "VerifyPinOrCodeResponse.CodeDetails";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [4,5];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, VerifyPinOrCodeResponse.CodeDetails.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): CodeDetails.AsObject {
    return CodeDetails.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!CodeDetails} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: CodeDetails) {
    var f: any;
    var obj: any = {
      mingaName: jspb.Message.getFieldWithDefault(msg, 1, ""),
      mingaLogo: jspb.Message.getFieldWithDefault(msg, 2, ""),
      mingaType: jspb.Message.getFieldWithDefault(msg, 3, ""),
      allowedRoleTypeInfoList: jspb.Message.toObjectList(msg.getAllowedRoleTypeInfoList(),
      VerifyPinOrCodeResponse.RoleTypeInfo.toObject, includeInstance),
      gradeOptionsList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!CodeDetails}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new CodeDetails;
    return CodeDetails.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!VerifyPinOrCodeResponse.CodeDetails} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!VerifyPinOrCodeResponse.CodeDetails}
   */
  static deserializeBinaryFromReader(msg: CodeDetails, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setMingaName(value1);
        break;
      case 2:
        var value2 = /** @type {string} */ (reader.readString());
        msg.setMingaLogo(value2);
        break;
      case 3:
        var value3 = /** @type {string} */ (reader.readString());
        msg.setMingaType(value3);
        break;
      case 4:
        var value4 = new VerifyPinOrCodeResponse.RoleTypeInfo;
        reader.readMessage(value4,VerifyPinOrCodeResponse.RoleTypeInfo.deserializeBinaryFromReader);
        msg.addAllowedRoleTypeInfo(value4);
        break;
      case 5:
        var value5 = /** @type {string} */ (reader.readString());
        msg.addGradeOptions(value5);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!VerifyPinOrCodeResponse.CodeDetails} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: VerifyPinOrCodeResponse.CodeDetails, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getMingaName();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
    f = message.getMingaLogo();
    if (f.length > 0) {
      writer.writeString(
        2,
        f
      );
    }
    f = message.getMingaType();
    if (f.length > 0) {
      writer.writeString(
        3,
        f
      );
    }
    f = message.getAllowedRoleTypeInfoList();
    if (f.length > 0) {
      writer.writeRepeatedMessage(
        4,
        f,
        VerifyPinOrCodeResponse.RoleTypeInfo.serializeBinaryToWriter
      );
    }
    f = message.getGradeOptionsList();
    if (f.length > 0) {
      writer.writeRepeatedString(
        5,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    VerifyPinOrCodeResponse.CodeDetails.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string minga_name = 1;
   * @return {string}
   */
  getMingaName(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setMingaName(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


  /**
   * optional string minga_logo = 2;
   * @return {string}
   */
  getMingaLogo(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
  };


  /** @param {string} value */
  setMingaLogo(value: string) {
    jspb.Message.setProto3StringField(this, 2, value);
  }


  /**
   * optional string minga_type = 3;
   * @return {string}
   */
  getMingaType(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
  };


  /** @param {string} value */
  setMingaType(value: string) {
    jspb.Message.setProto3StringField(this, 3, value);
  }


  /**
   * repeated RoleTypeInfo allowed_role_type_info = 4;
   * @return {!Array<!VerifyPinOrCodeResponse.RoleTypeInfo>}
   */
  getAllowedRoleTypeInfoList(): VerifyPinOrCodeResponse.RoleTypeInfo[] {
    return /** @type{!Array<!VerifyPinOrCodeResponse.RoleTypeInfo>} */ (
      jspb.Message.getRepeatedWrapperField(this, VerifyPinOrCodeResponse.RoleTypeInfo, 4));
  }


  /** @param {!Array<!VerifyPinOrCodeResponse.RoleTypeInfo>} value */
  setAllowedRoleTypeInfoList(value?: VerifyPinOrCodeResponse.RoleTypeInfo[]) {
    jspb.Message.setRepeatedWrapperField(this, 4, value);
  }


  /**
   * @param {!VerifyPinOrCodeResponse.RoleTypeInfo=} opt_value
   * @param {number=} opt_index
   * @return {!VerifyPinOrCodeResponse.RoleTypeInfo}
   */
  addAllowedRoleTypeInfo(opt_value?: VerifyPinOrCodeResponse.RoleTypeInfo, opt_index?: number): VerifyPinOrCodeResponse.RoleTypeInfo {
    return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, VerifyPinOrCodeResponse.RoleTypeInfo, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearAllowedRoleTypeInfoList() {
    this.setAllowedRoleTypeInfoList([]);
  }


  /**
   * repeated string grade_options = 5;
   * @return {!Array<string>}
   */
  getGradeOptionsList(): string[] {
    return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 5));
  };


  /** @param {!Array<string>} value */
  setGradeOptionsList(value: string[]) {
    jspb.Message.setField(this, 5, value || []);
  }


  /**
   * @param {string} value
   * @param {number=} opt_index
   */
  addGradeOptions(value: string, opt_index?: number): void {
    jspb.Message.addToRepeatedField(this, 5, value, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearGradeOptionsList() {
    this.setGradeOptionsList([]);
  }


}
} // namespace VerifyPinOrCodeResponse
export namespace VerifyPinEmailRequest {
  export interface AsObject {
    email: string;
    pin: string;
  }
}
export class VerifyPinEmailRequest extends jspb.Message {
  static readonly displayName = "VerifyPinEmailRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): VerifyPinEmailRequest.AsObject {
    return VerifyPinEmailRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!VerifyPinEmailRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: VerifyPinEmailRequest) {
    var f: any;
    var obj: any = {
      email: jspb.Message.getFieldWithDefault(msg, 1, ""),
      pin: jspb.Message.getFieldWithDefault(msg, 2, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!VerifyPinEmailRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new VerifyPinEmailRequest;
    return VerifyPinEmailRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!VerifyPinEmailRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!VerifyPinEmailRequest}
   */
  static deserializeBinaryFromReader(msg: VerifyPinEmailRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setEmail(value1);
        break;
      case 2:
        var value2 = /** @type {string} */ (reader.readString());
        msg.setPin(value2);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!VerifyPinEmailRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: VerifyPinEmailRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getEmail();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
    f = message.getPin();
    if (f.length > 0) {
      writer.writeString(
        2,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    VerifyPinEmailRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string email = 1;
   * @return {string}
   */
  getEmail(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setEmail(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


  /**
   * optional string pin = 2;
   * @return {string}
   */
  getPin(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
  };


  /** @param {string} value */
  setPin(value: string) {
    jspb.Message.setProto3StringField(this, 2, value);
  }


}
export namespace VerifyPinEmailResponse {
  export interface AsObject {
    matched: boolean;
  }
}
export class VerifyPinEmailResponse extends jspb.Message {
  static readonly displayName = "VerifyPinEmailResponse";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): VerifyPinEmailResponse.AsObject {
    return VerifyPinEmailResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!VerifyPinEmailResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: VerifyPinEmailResponse) {
    var f: any;
    var obj: any = {
      matched: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!VerifyPinEmailResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new VerifyPinEmailResponse;
    return VerifyPinEmailResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!VerifyPinEmailResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!VerifyPinEmailResponse}
   */
  static deserializeBinaryFromReader(msg: VerifyPinEmailResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 2:
        var value2 = /** @type {boolean} */ (reader.readBool());
        msg.setMatched(value2);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!VerifyPinEmailResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: VerifyPinEmailResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getMatched();
    if (f) {
      writer.writeBool(
        2,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    VerifyPinEmailResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional bool matched = 2;
   * @return {boolean}
   */
  getMatched(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
  };


  /** @param {boolean} value */
  setMatched(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 2, value);
  }


}
export namespace ActiveCampaignEventRequest {
  export interface AsObject {
    event: string;
    mingaType: string;
    dealId: string;
    activeCampaignContact?: ActiveCampaignEventRequest.ActiveCampaignContact.AsObject;
  }
}
export class ActiveCampaignEventRequest extends jspb.Message {
  static readonly displayName = "ActiveCampaignEventRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): ActiveCampaignEventRequest.AsObject {
    return ActiveCampaignEventRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!ActiveCampaignEventRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: ActiveCampaignEventRequest) {
    var f: any;
    var obj: any = {
      event: jspb.Message.getFieldWithDefault(msg, 1, ""),
      mingatype: jspb.Message.getFieldWithDefault(msg, 2, ""),
      dealid: jspb.Message.getFieldWithDefault(msg, 3, ""),
      activecampaigncontact: (f = msg.getActivecampaigncontact()) && ActiveCampaignEventRequest.ActiveCampaignContact.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!ActiveCampaignEventRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new ActiveCampaignEventRequest;
    return ActiveCampaignEventRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!ActiveCampaignEventRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!ActiveCampaignEventRequest}
   */
  static deserializeBinaryFromReader(msg: ActiveCampaignEventRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setEvent(value1);
        break;
      case 2:
        var value2 = /** @type {string} */ (reader.readString());
        msg.setMingatype(value2);
        break;
      case 3:
        var value3 = /** @type {string} */ (reader.readString());
        msg.setDealid(value3);
        break;
      case 4:
        var value4 = new ActiveCampaignEventRequest.ActiveCampaignContact;
        reader.readMessage(value4,ActiveCampaignEventRequest.ActiveCampaignContact.deserializeBinaryFromReader);
        msg.setActivecampaigncontact(value4);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!ActiveCampaignEventRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: ActiveCampaignEventRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getEvent();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
    f = message.getMingatype();
    if (f.length > 0) {
      writer.writeString(
        2,
        f
      );
    }
    f = message.getDealid();
    if (f.length > 0) {
      writer.writeString(
        3,
        f
      );
    }
    f = message.getActivecampaigncontact();
    if (f != null) {
      writer.writeMessage(
        4,
        f,
        ActiveCampaignEventRequest.ActiveCampaignContact.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    ActiveCampaignEventRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string event = 1;
   * @return {string}
   */
  getEvent(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setEvent(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


  /**
   * optional string mingaType = 2;
   * @return {string}
   */
  getMingatype(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
  };


  /** @param {string} value */
  setMingatype(value: string) {
    jspb.Message.setProto3StringField(this, 2, value);
  }


  /**
   * optional string dealId = 3;
   * @return {string}
   */
  getDealid(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
  };


  /** @param {string} value */
  setDealid(value: string) {
    jspb.Message.setProto3StringField(this, 3, value);
  }


  /**
   * optional ActiveCampaignContact activeCampaignContact = 4;
   * @return {?ActiveCampaignEventRequest.ActiveCampaignContact}
   */
  getActivecampaigncontact(): ActiveCampaignEventRequest.ActiveCampaignContact {
    return /** @type{?ActiveCampaignEventRequest.ActiveCampaignContact} */ (
      jspb.Message.getWrapperField(this, ActiveCampaignEventRequest.ActiveCampaignContact, 4));
  }


  /** @param {?ActiveCampaignEventRequest.ActiveCampaignContact|undefined} value */
  setActivecampaigncontact(value?: ActiveCampaignEventRequest.ActiveCampaignContact) {
    jspb.Message.setWrapperField(this, 4, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearActivecampaigncontact() {
    this.setActivecampaigncontact(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasActivecampaigncontact(): boolean {
    return jspb.Message.getField(this, 4) != null;
  }


}
export namespace ActiveCampaignEventRequest {
export namespace ActiveCampaignContact {
  export interface AsObject {
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    accountName: string;
    accountPlaceId: string;
    mingaHash: string;
  }
}
export class ActiveCampaignContact extends jspb.Message {
  static readonly displayName = "ActiveCampaignEventRequest.ActiveCampaignContact";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): ActiveCampaignContact.AsObject {
    return ActiveCampaignContact.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!ActiveCampaignContact} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: ActiveCampaignContact) {
    var f: any;
    var obj: any = {
      firstName: jspb.Message.getFieldWithDefault(msg, 1, ""),
      lastName: jspb.Message.getFieldWithDefault(msg, 2, ""),
      email: jspb.Message.getFieldWithDefault(msg, 3, ""),
      phone: jspb.Message.getFieldWithDefault(msg, 4, ""),
      accountName: jspb.Message.getFieldWithDefault(msg, 5, ""),
      accountPlaceId: jspb.Message.getFieldWithDefault(msg, 6, ""),
      mingaHash: jspb.Message.getFieldWithDefault(msg, 7, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!ActiveCampaignContact}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new ActiveCampaignContact;
    return ActiveCampaignContact.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!ActiveCampaignEventRequest.ActiveCampaignContact} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!ActiveCampaignEventRequest.ActiveCampaignContact}
   */
  static deserializeBinaryFromReader(msg: ActiveCampaignContact, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setFirstName(value1);
        break;
      case 2:
        var value2 = /** @type {string} */ (reader.readString());
        msg.setLastName(value2);
        break;
      case 3:
        var value3 = /** @type {string} */ (reader.readString());
        msg.setEmail(value3);
        break;
      case 4:
        var value4 = /** @type {string} */ (reader.readString());
        msg.setPhone(value4);
        break;
      case 5:
        var value5 = /** @type {string} */ (reader.readString());
        msg.setAccountName(value5);
        break;
      case 6:
        var value6 = /** @type {string} */ (reader.readString());
        msg.setAccountPlaceId(value6);
        break;
      case 7:
        var value7 = /** @type {string} */ (reader.readString());
        msg.setMingaHash(value7);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!ActiveCampaignEventRequest.ActiveCampaignContact} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: ActiveCampaignEventRequest.ActiveCampaignContact, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getFirstName();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
    f = message.getLastName();
    if (f.length > 0) {
      writer.writeString(
        2,
        f
      );
    }
    f = message.getEmail();
    if (f.length > 0) {
      writer.writeString(
        3,
        f
      );
    }
    f = message.getPhone();
    if (f.length > 0) {
      writer.writeString(
        4,
        f
      );
    }
    f = message.getAccountName();
    if (f.length > 0) {
      writer.writeString(
        5,
        f
      );
    }
    f = message.getAccountPlaceId();
    if (f.length > 0) {
      writer.writeString(
        6,
        f
      );
    }
    f = message.getMingaHash();
    if (f.length > 0) {
      writer.writeString(
        7,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    ActiveCampaignEventRequest.ActiveCampaignContact.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string first_name = 1;
   * @return {string}
   */
  getFirstName(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setFirstName(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


  /**
   * optional string last_name = 2;
   * @return {string}
   */
  getLastName(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
  };


  /** @param {string} value */
  setLastName(value: string) {
    jspb.Message.setProto3StringField(this, 2, value);
  }


  /**
   * optional string email = 3;
   * @return {string}
   */
  getEmail(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
  };


  /** @param {string} value */
  setEmail(value: string) {
    jspb.Message.setProto3StringField(this, 3, value);
  }


  /**
   * optional string phone = 4;
   * @return {string}
   */
  getPhone(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
  };


  /** @param {string} value */
  setPhone(value: string) {
    jspb.Message.setProto3StringField(this, 4, value);
  }


  /**
   * optional string account_name = 5;
   * @return {string}
   */
  getAccountName(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
  };


  /** @param {string} value */
  setAccountName(value: string) {
    jspb.Message.setProto3StringField(this, 5, value);
  }


  /**
   * optional string account_place_id = 6;
   * @return {string}
   */
  getAccountPlaceId(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
  };


  /** @param {string} value */
  setAccountPlaceId(value: string) {
    jspb.Message.setProto3StringField(this, 6, value);
  }


  /**
   * optional string minga_hash = 7;
   * @return {string}
   */
  getMingaHash(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
  };


  /** @param {string} value */
  setMingaHash(value: string) {
    jspb.Message.setProto3StringField(this, 7, value);
  }


}
} // namespace ActiveCampaignEventRequest
export namespace ActiveCampaignEventResponse {
  export interface AsObject {
    dealId: string;
    stage: string;
  }
}
export class ActiveCampaignEventResponse extends jspb.Message {
  static readonly displayName = "ActiveCampaignEventResponse";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): ActiveCampaignEventResponse.AsObject {
    return ActiveCampaignEventResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!ActiveCampaignEventResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: ActiveCampaignEventResponse) {
    var f: any;
    var obj: any = {
      dealid: jspb.Message.getFieldWithDefault(msg, 1, ""),
      stage: jspb.Message.getFieldWithDefault(msg, 2, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!ActiveCampaignEventResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new ActiveCampaignEventResponse;
    return ActiveCampaignEventResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!ActiveCampaignEventResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!ActiveCampaignEventResponse}
   */
  static deserializeBinaryFromReader(msg: ActiveCampaignEventResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setDealid(value1);
        break;
      case 2:
        var value2 = /** @type {string} */ (reader.readString());
        msg.setStage(value2);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!ActiveCampaignEventResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: ActiveCampaignEventResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getDealid();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
    f = message.getStage();
    if (f.length > 0) {
      writer.writeString(
        2,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    ActiveCampaignEventResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string dealId = 1;
   * @return {string}
   */
  getDealid(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setDealid(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


  /**
   * optional string stage = 2;
   * @return {string}
   */
  getStage(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
  };


  /** @param {string} value */
  setStage(value: string) {
    jspb.Message.setProto3StringField(this, 2, value);
  }


}
