export const AcceptedImageFileMimes: Array<string> =
  ['image/png', 'image/gif', 'image/jpeg', 'image/webp', 'image/tiff'];

export function sanitizeFilename(filename: string) {
  filename = filename.replace(/(\(|\)|\?|\[|\]|\*|\#|\\|\/)/g, '');
  filename = filename.replace(/ /g, '_');

  // Remove duplicate - and _
  filename = filename.replace(/(\-|_)(\-|_)+/g, '-');
  return filename;
}

export function getFileExtension(fileName: string, pure?: boolean) {
  const endIndex = fileName.lastIndexOf('.');

  let extension =
    endIndex >= 0 ? fileName.substring(endIndex + 1).toUpperCase() : '';

  if(pure && extension) {
    if(extension.match(/[^a-zA-Z]+/)) {
      // bad ext, need to parse
      const ext = extension.match(/([a-zA-Z]+)[^a-zA-Z]/);
      if(ext) {
        extension = ext[1] || '';
      }
    }
  }

  return extension;
}

export function getFileTitle(fileName: string) {
  let startIndex = fileName.lastIndexOf('/');
  if(startIndex < 0) {
    startIndex = fileName.lastIndexOf('\\');
  }
  const endIndex = fileName.lastIndexOf('.');
  const foundTitle = fileName.substring(startIndex + 1, endIndex);
  return foundTitle;
}
