import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { RouterModule } from '@angular/router';

import { NaviconModule } from '@app/src/app/components/Navicon';
import { FtueHighlightPipeModule } from '@app/src/app/ftue/Highlight/pipes/FtueHighlight';
import { NavLinkModule } from '@app/src/app/navigation/components/NavLink';

import { FormModule } from '@shared/components/form';

import { NavigationDrawerSubNavComponent } from './NavigationDrawerSubNav.component';

@NgModule({
  imports: [
    // Minga dependencies
    FtueHighlightPipeModule,
    NavLinkModule,
    NaviconModule,
    FormModule,

    // External dependencies
    CommonModule,
    RouterModule,
    MatExpansionModule,
    MatListModule,
    MatMenuModule,
    FlexLayoutModule,
  ],
  declarations: [NavigationDrawerSubNavComponent],
  exports: [NavigationDrawerSubNavComponent],
})
export class NavigationDrawerSubNavModule {}
