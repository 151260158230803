import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';

import dayjs from 'dayjs';
import { day } from 'libs/day';
import { IBellSchedule } from 'libs/domain';
import {
  BehaviorSubject,
  combineLatest,
  Observable,
  ReplaySubject,
} from 'rxjs';
import { debounceTime, filter, map, takeUntil } from 'rxjs/operators';
import { $enum } from 'ts-enum-util';

import { RootService } from '@app/src/app/minimal/services/RootService';

import { FormSelectOption } from '@shared/components/form';
import { SystemAlertSnackBarService } from '@shared/components/system-alert-snackbar';
import { Weekday } from '@shared/components/weekday-toggle';
import {
  BellSchedulesInterface,
  BellSchedulesService,
} from '@shared/services/bell-schedule/bs-schedules.interface';
import {
  BellScheduleTermsInterface,
  BellScheduleTermsService,
} from '@shared/services/bell-schedule/bs-terms.interface';
import {
  defaultFilteringNavigationBehaviors,
  QueryParamKey,
} from '@shared/utils';

import {
  BS_CALENDAR_COLUMN_LABELS,
  BS_CALENDAR_COLUMNS,
  BS_CALENDAR_MESSAGES,
  BSCalendarColumns,
} from '../../constants/mm-bs-calendar.constants';
import { BellScheduleCacheService } from '../../services/bell-schedule-cache.service';
import { ClientTerm } from '../../types/mm-bell-schedule.types';

/** Just local convenience types for the table data. */
export type TableSchedule = {
  id: string;
  date: day.Dayjs;
  bellId?: number;
  // keep track of both of these in the table data so we can expose a way to clear the override
  overrideBellId?: number;
  state?: State;
};
type State = 'none' | 'default' | 'custom';

@Component({
  selector: 'mg-mm-bs-calendar',
  templateUrl: './mm-bs-calendar.component.html',
  styleUrls: ['./mm-bs-calendar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [BellScheduleCacheService],
})

//
export class MmBsCalendarComponent implements OnInit, OnDestroy {
  /** Constants */
  public readonly MESSAGES = BS_CALENDAR_MESSAGES;

  /** Table */
  public readonly TABLE_COLUMN = BS_CALENDAR_COLUMNS;
  public readonly TABLE_COLUMN_LABEL = BS_CALENDAR_COLUMN_LABELS;
  public readonly tableColumns = BSCalendarColumns;
  public readonly tableDataSource = new MatTableDataSource<TableSchedule>([]);

  /** Data */
  private _errors: string[] = [];

  /** Subjects */
  private _destroyed$ = new ReplaySubject<void>(1);
  private _currentTermSubject = new BehaviorSubject<ClientTerm>(undefined);
  private _schedulesSubject = new BehaviorSubject<IBellSchedule[]>([]);
  private _termsSubject = new BehaviorSubject<ClientTerm[]>([]);
  private readonly _isLoadingSubject = new BehaviorSubject(true);

  /** Observables */
  public readonly currentTerm$ = this._currentTermSubject.asObservable();
  public readonly isLoading$ = this._isLoadingSubject.asObservable();

  public readonly termsOptions$: Observable<FormSelectOption[]> =
    this._termsSubject.pipe(
      takeUntil(this._destroyed$),
      map(terms => {
        return terms.map(t => ({
          value: t.id,
          label: t.title,
        }));
      }),
    );

  private _schedulesByTerm$: Observable<IBellSchedule[]> = combineLatest([
    this._schedulesSubject,
    this._currentTermSubject,
  ]).pipe(
    filter(([schedules, currentTerm]) => !!currentTerm?.id),
    map(([schedules, currentTerm]) => {
      return schedules.filter(schedule => {
        return schedule.terms.some(t => t.id === currentTerm.id);
      });
    }),
  );

  // unused schedules can still be used by any of the terms for overrides
  private _unusedSchedules$: Observable<IBellSchedule[]> =
    this._schedulesSubject.pipe(
      takeUntil(this._destroyed$),
      map(schedules => {
        return schedules.filter(s => s.terms.length === 0);
      }),
    );

  public readonly scheduleOptions$: Observable<FormSelectOption[]> =
    combineLatest([this._schedulesByTerm$, this._unusedSchedules$]).pipe(
      takeUntil(this._destroyed$),
      map(([schedules, unusedSchedules]) => {
        const all = [...schedules, ...unusedSchedules];
        return all.map(s => ({ value: s.id, label: s.name }));
      }),
    );

  /**
   * If you want to change the wiring for the services provided, see
   * mm-bell-schedule.module.ts
   */
  constructor(
    private _systemAlertSnackBar: SystemAlertSnackBarService,
    @Inject(BellSchedulesInterface)
    private _schedulesService: BellSchedulesService,
    @Inject(BellScheduleTermsInterface)
    private _termsService: BellScheduleTermsService,
    private _rootService: RootService,
    private _router: Router,
    public route: ActivatedRoute,
    private _cdr: ChangeDetectorRef,
    private _bsCacheService: BellScheduleCacheService,
  ) {
    /** Generate table data whenever the current term changes. */
    combineLatest([
      this.currentTerm$,
      this._schedulesByTerm$,
      this._unusedSchedules$,
    ])
      .pipe(
        takeUntil(this._destroyed$),
        filter(([term]) => !!term?.id),
        debounceTime(50),
      )
      .subscribe(([term, schedulesByTerm, unusedSchedules]) => {
        // Reset errors for the selected term.
        this._errors = [];

        this.tableDataSource.data = this._generateTableData(
          term,
          schedulesByTerm,
          unusedSchedules,
        );

        this._cdr.markForCheck();
      });

    combineLatest([this.route.queryParams, this._termsSubject])
      .pipe(
        takeUntil(this._destroyed$),
        filter(([queryParams, terms]) => !!terms.length),
      )
      .subscribe(([queryParams, terms]) => {
        const fallback = terms[0];
        const termQuery = +queryParams[QueryParamKey.TERM];
        const term = terms.find(t => t.id === termQuery) || fallback;
        this._currentTermSubject.next(term);
      });
  }

  ngOnInit(): void {
    this._loadInitialData();
  }

  private async _loadInitialData(): Promise<void> {
    this._isLoadingSubject.next(true);

    try {
      const [terms, schedules] = await Promise.all([
        this._termsService.fetchAll(),
        this._schedulesService.fetchCalender(),
      ]);
      this._termsSubject.next(terms);
      this._schedulesSubject.next(schedules);
    } catch (error) {
      this._systemAlertSnackBar.error(this.MESSAGES.ERROR_LOADING_DATA);
    } finally {
      this._isLoadingSubject.next(false);
    }
  }

  ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  public tooltipForState(state?: State): string {
    if (state === 'default') {
      return BS_CALENDAR_MESSAGES.ASSIGNED_DEFAULT;
    } else if (state === 'custom') {
      return BS_CALENDAR_MESSAGES.ASSIGNED_CUSTOM;
    } else {
      return BS_CALENDAR_MESSAGES.ASSIGNED_NONE;
    }
  }

  public iconForState(state?: State): string {
    if (state === 'custom') {
      return 'mg-custom-schedule';
    } else {
      return 'mg-check-fill';
    }
  }

  public trackByFn(index: number, item: TableSchedule) {
    return item.id;
  }

  public changedSelectedTerm(id) {
    this._router.navigate([], {
      queryParams: {
        [QueryParamKey.TERM]: id,
      },
      ...defaultFilteringNavigationBehaviors,
    });
  }

  public async changedBellSchedule(
    scheduleId: number | null,
    tableSchedule: TableSchedule,
  ) {
    const { date, overrideBellId, bellId } = tableSchedule;
    // if we're changing back to the original schedule we need to remove the current override
    const newBellScheduleId = scheduleId === bellId ? null : scheduleId;
    const isOverride = scheduleId === bellId ? false : true;
    const termId = this._currentTermSubject.value.id;

    await this._rootService.addLoadingPromise(
      this._schedulesService.overrideScheduleForDate(
        newBellScheduleId,
        date,
        termId,
        isOverride,
      ),
    );

    // revalidate all the schedule data to cache bust and optimitiscally update this table ui
    const newSchedule = await this._schedulesService.fetchCalender({
      revalidate: true,
    });
    this._schedulesSubject.next(newSchedule);

    this._systemAlertSnackBar.success(BS_CALENDAR_MESSAGES.SCHEDULE_CHANGED);

    // delete all bell schedule caches
    this._bsCacheService.clearBellScheduleCache();
  }

  public hasErrors(): boolean {
    return this._errors.length > 0;
  }

  public hasData(): boolean {
    return (
      this._schedulesSubject.value.length > 0 &&
      this._termsSubject.value.length > 0
    );
  }

  /**
   * Generate the schedule data for the table from the term and bell schedules.
   */
  private _generateTableData(
    term: ClientTerm,
    termSchedules: IBellSchedule[],
    unusedSchedules: IBellSchedule[],
    minDays: number = 1,
    maxDays: number = 400, // we can't assume the max is 365, test data is showing > 365
  ): TableSchedule[] {
    /**
     * Get the day count between the term dates. Diff from the end to the
     * start for a positive day count.
     */
    const getDayCount = (start: day.Dayjs, end: day.Dayjs): number => {
      const days = end.diff(start, 'days');

      if (days < minDays) {
        return 0;
      } else if (days > maxDays) {
        return maxDays;
      } else {
        // +1 to include the end day of the range.
        return days + 1;
      }
    };

    /**
     * For readability only generating the dates here, merging the
     * dates and schedules in another step.
     */
    const makeDatesWithinRange = (start: string, end: string): day.Dayjs[] => {
      const startDate = day(start, 'YYYY-MM-DD').startOf('day');
      const endDate = day(end, 'YYYY-MM-DD').startOf('day');
      const dayCount = getDayCount(startDate, endDate);

      // Set errors for day count lengths, if any.
      if (dayCount < minDays) {
        this._errors.push('The term length must be at least one day.');
      } else if (dayCount > maxDays) {
        this._errors.push(
          `The term (${term.title}) length must be less or equal to one year (${maxDays} days).`,
        );
      }

      return Array.from({ length: dayCount }, (_, i) => {
        return startDate.add(i, 'days').startOf('day');
      });
    };

    if (term.startDateString && term.endDateString) {
      const dates = makeDatesWithinRange(
        term.startDateString,
        term.endDateString,
      );

      /** Convert the date, term and schedules to table data type. */
      const convertToTableData = (date: dayjs.Dayjs): TableSchedule => {
        const dayOfWeek = date.day();

        /** Get the weekday for the current date. */
        const weekday = $enum(Weekday)
          .getValues()
          .find((_value, index) => dayOfWeek === index);

        /** Get the selected bell schedule for the current weekday */
        const selectedSchedule = termSchedules.find(schedule =>
          schedule.days.includes(weekday),
        );

        const overrides = [...termSchedules, ...unusedSchedules].filter(s => {
          if (s.overrideDates?.size) {
            const overrideDates = s.overrideDates.get(term.id);
            return overrideDates?.some(d => day(d).isSame(date, 'day'));
          } else {
            return false;
          }
        });

        const overrideBellId = overrides?.[0]?.id;

        const bellId = selectedSchedule?.id;
        const state =
          overrideBellId || overrideBellId === 0
            ? 'custom'
            : bellId
            ? 'default'
            : 'none';

        return {
          id: date.format('YYYY-MM-DD'),
          date,
          state,
          overrideBellId,
          ...(bellId ? { bellId } : {}),
        };
      };

      return dates.map(convertToTableData);
    } else {
      this._errors.push(`${term.title} must have a start and end date.`);
      return [];
    }
  }
}
