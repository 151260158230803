import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

import { MgButtonModule } from '@app/src/app/button';
import { MgIconModule } from '@app/src/app/icon';
import { MgOverlayToolsModule } from '@app/src/app/overlay/MgOverlayTools.module';

import { ResolveListUploadErrorsDialogModule } from '@shared/components/XlsListUploader/ResolveListUploadErrorsDialog';
import { XlsListUploaderComponent } from '@shared/components/XlsListUploader/XlsListUploader.component';
import { OverlayModule } from '@shared/components/modal-overlay';
import { TextModule } from '@shared/components/text/text.module';

import { GenericButtonModule } from '../button';
import { SystemAlertModule } from '../system-alert';

@NgModule({
  declarations: [XlsListUploaderComponent],
  imports: [
    // Minga dependencies
    MgButtonModule,
    ResolveListUploadErrorsDialogModule,
    MgOverlayToolsModule,
    MgIconModule,
    OverlayModule,
    TextModule,
    GenericButtonModule,
    SystemAlertModule,

    // External dependencies
    CommonModule,
    FormsModule,
    FlexLayoutModule,
    MatProgressBarModule,
    MatPaginatorModule,
    MatCheckboxModule,
    MatSelectModule,
    MatFormFieldModule,
    MatSlideToggleModule,
    ReactiveFormsModule,
    FormsModule,
  ],
  exports: [XlsListUploaderComponent],
})
export class XlsListUploaderModule {}
