import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { Dictionary } from '@ngrx/entity/src/models';

import { NotificationStoreModel } from '../model/notification.model';
import { selectNotificationState } from './NotificationSelectors';

export const entityAdapter: EntityAdapter<NotificationStoreModel> =
  createEntityAdapter<NotificationStoreModel>({
    selectId: (notification: NotificationStoreModel) => notification.id || '',
  });

/**
 * @internal use NotificationsState.select*() methods instead
 */
export const _selectors = entityAdapter.getSelectors(selectNotificationState);

export const initialState = entityAdapter.getInitialState({});

export const selectAll: (state: any) => NotificationStoreModel[] =
  _selectors.selectAll;

export const selectEntities: (
  state: any,
) => Dictionary<NotificationStoreModel> = _selectors.selectEntities;

export const selectIds: (state: any) => string[] | number[] =
  _selectors.selectIds;

export const selectTotal: (state: any) => number = _selectors.selectTotal;
