import { Pipe, PipeTransform } from '@angular/core';

import { MingaPermission } from 'libs/domain';
import { mingaSettingTypes } from 'libs/util';
import { Observable, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';

import { PermissionsService } from '@app/src/app/permissions';
import { MingaSettingsService } from '@app/src/app/store/Minga/services';

import { PBIS_CHECKIN_TYPES } from '@modules/teacher-tools/constants/tt-view.constants';
import { ITeacherToolsMenu } from '@modules/teacher-tools/teacher-tools.types';

const PERMISSIONS_BY_TYPE: Record<PBIS_CHECKIN_TYPES, (perms) => boolean> = {
  [PBIS_CHECKIN_TYPES.PRAISE]: perms => perms.canCreatePbis,
  [PBIS_CHECKIN_TYPES.GUIDANCE]: perms => perms.canCreatePbis,
  [PBIS_CHECKIN_TYPES.CONSEQUENCE]: perms =>
    perms.consequencesEnabled &&
    (perms.pbisTypeManage || perms.canCreateConsequence),
  [PBIS_CHECKIN_TYPES.MANAGE_POINTS]: perms =>
    perms.canAllocatePoints || perms.canManagePoints,
  [PBIS_CHECKIN_TYPES.REWARDS]: perms =>
    perms.canManageRewards && perms.isRewardsStoreEnabled,
};

@Pipe({
  name: 'filterPbisOptions',
})
export class FilterPbisOptionsPipe implements PipeTransform {
  constructor(
    private _settingsService: MingaSettingsService,
    private _permissions: PermissionsService,
  ) {}

  transform(options: ITeacherToolsMenu[]): Observable<ITeacherToolsMenu[]> {
    return combineLatest([
      this._settingsService.getSettingValueObs(
        mingaSettingTypes.BM_CONSEQUENCE_ENABLE,
      ),
      this._permissions.observePermission(MingaPermission.CONSEQUENCE_CREATE),
      this._permissions.observePermission(MingaPermission.MANAGE_REWARDS),
      this._permissions.observePermission(
        MingaPermission.POINTS_ALLOCATE_ALLOWED,
      ),
      this._permissions.observePermission(MingaPermission.POINTS_MANAGE),
      this._settingsService.getSettingValueObs(
        mingaSettingTypes.FEATURE_TRACKING_PUBLIC_LEADERBOARD,
      ),
      this._permissions.observePermission(MingaPermission.PBIS_CREATE),
      this._settingsService.getSettingValueObs(
        mingaSettingTypes.PM_REWARDS_STORE,
      ),
      this._permissions.observePermission(MingaPermission.PBIS_TYPE_MANAGE),
    ]).pipe(
      map(
        ([
          consequencesEnabled,
          canCreateConsequence,
          canManageRewards,
          canAllocatePoints,
          canManagePoints,
          isLeaderboardEnabled,
          canCreatePbis,
          isRewardsStoreEnabled,
          pbisTypeManage,
        ]) => {
          if (!options) return [];
          return options
            ?.filter(o => PERMISSIONS_BY_TYPE[o.type])
            ?.filter(o => {
              const { type } = o;

              const hasPermission = PERMISSIONS_BY_TYPE[type];
              return hasPermission({
                consequencesEnabled,
                canCreateConsequence,
                canManageRewards,
                canAllocatePoints,
                canManagePoints,
                isLeaderboardEnabled,
                canCreatePbis,
                isRewardsStoreEnabled,
                pbisTypeManage,
              });
            });
        },
      ),
    );
  }
}
