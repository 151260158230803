import { IPointsSummaryResult } from 'libs/domain';
import { points_pb } from 'libs/generated-grpc-ts';

export const toProto = (
  result: IPointsSummaryResult,
): points_pb.PointsSummaryItem => {
  const item = new points_pb.PointsSummaryItem();
  if (result.issuedTo) {
    const person = new points_pb.PointsHistoryItemPerson();
    person.setFirstName(result.issuedTo.firstName);
    person.setLastName(result.issuedTo.lastName);
    if (result.issuedTo.name) person.setName(result.issuedTo.name);
    if (result.issuedTo.studentId)
      person.setStudentId(result.issuedTo.studentId);
    if (result.issuedTo.grade) person.setGrade(result.issuedTo.grade);
    if (result.issuedTo.personHash)
      person.setPersonHash(result.issuedTo.personHash);
    item.setIssuedTo(person);
  }
  if (result.totalPoints) {
    item.setTotalPoints(result.totalPoints);
  }
  if (typeof result.redeemablePoints === 'number') {
    item.setRedeemablePoints(result.redeemablePoints);
  }
  if (typeof result.redeemedPoints === 'number') {
    item.setRedeemedPoints(result.redeemedPoints);
  }
  return item;
};

export const fromProto = (
  msg: points_pb.PointsSummaryItem,
): IPointsSummaryResult => {
  const person = msg.getIssuedTo();
  return {
    issuedTo: {
      studentId: person.getStudentId(),
      name: person.getName(),
      grade: person.getGrade(),
      firstName: person.getFirstName(),
      lastName: person.getLastName(),
      personHash: person.getPersonHash(),
    },
    totalPoints: msg.getTotalPoints(),
    redeemablePoints: msg.getRedeemablePoints(),
    redeemedPoints: msg.getRedeemedPoints(),
  };
};
