import { Injectable } from '@angular/core';

import * as $ngrx_effects_effects from '@ngrx/effects';
//TS2742
import * as $ngrx_store_src_models from '@ngrx/store/src/models';
//TS2742
import * as _ from 'lodash';
import * as $rxjs from 'rxjs';
import { Actions, createEffect, ofType } from '@ngrx/effects';
//TS2742
import { filter, map } from 'rxjs/operators';

import {
  addAuthParentGroup,
  removeAuthParentGroup,
} from '@app/src/app/store/AuthStore/actions';

import { GroupMemberActions } from '../../actions';
import { Group } from '../../models';

/**
 * Return `true` if group is a parent and we are a member
 */
function onlyParentGroupAndMember(group: Group) {
  // Using group.rank to check if we are a member
  return !!(group.isParent && group.rank);
}

/**
 * Effects involving parent groups
 */
@Injectable()
export class ParentGroupEffects {
  leaveParentGroup$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GroupMemberActions.TypeEnum.RemoveGroupMemberSuccess),
      map(({ payload }) => payload),
      filter(onlyParentGroupAndMember),
      map(group => removeAuthParentGroup({ parentGroupHash: group.hash })),
    ),
  );

  joinParentGroup$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GroupMemberActions.TypeEnum.JoinGroupSuccess),
      map(({ payload }) => payload),
      filter(onlyParentGroupAndMember),
      map(group => addAuthParentGroup({ parentGroupHash: group.hash })),
    ),
  );

  constructor(private actions$: Actions) {}
}
