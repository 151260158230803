import {
  hallPassErrorToDeniedBy,
  HallPassStatusEnum,
  IHallPass,
} from 'libs/domain';

import { ColumnInfo } from '../types';
import {
  assignedToAccessorFn,
  nameFinder,
  totalTimeAccessorFn,
} from '../utils';
import {
  assignedTo,
  assignedToLast,
  firstName,
  grade,
  lastName,
  noteAndRequesterNote,
  role,
  studentId,
  currentClass,
} from './columns.constants';
import { DateColumnKeys, TimeColumnKeys } from './common.constants';

const numPassCol: ColumnInfo = {
  header: 'Assigned',
  key: 'totalPasses',
  accessorFn: (item: any) => {
    return (
      item?.totalPasses ||
      item?.stats?.totalPasses ||
      item?.expiredStats?.noOfExpiredPasses ||
      '0'
    );
  },
  sort: true,
  type: 'number',
  width: 'small',
};

const numDeniedPassCol: ColumnInfo = {
  header: 'Denied',
  key: 'totalDenied',
  accessorFn: (item: any) => {
    return item?.totalDenied || item?.stats?.totalDenied || '0';
  },
  type: 'number',
  width: 'small',
  sort: true,
};

const numOverriddenPassCol: ColumnInfo = {
  header: 'Overridden',
  key: 'totalOverridden',
  accessorFn: (item: any) => {
    return item?.totalOverridden || item?.stats?.totalOverridden || '0';
  },
  type: 'number',
  width: 'medium',
  sort: true,
};

const totalTimeCol: ColumnInfo = {
  header: 'Total Time',
  key: 'totalTime',
  accessorFn: totalTimeAccessorFn,
  sort: true,
  type: 'number',
  width: 'medium',
};

const hallPassNameCol: ColumnInfo = {
  header: 'Pass Type',
  key: 'hallPassName',
  tooltip: true,
  accessorFn: (item: any, colour?: boolean) => {
    if (colour) return item?.typeColor || item?.hallPassType?.color || '';
    return item?.typeName || item?.hallPassType?.name || '';
  },
  type: 'text',
  width: 'flex-medium',
};

export const STUDENTS: ColumnInfo[] = [
  {
    ...firstName,
    accessorFn: (item: any) => assignedToAccessorFn(item, 'first'),
  },
  {
    ...lastName,
    accessorFn: (item: any) => assignedToAccessorFn(item, 'last'),
  },
  studentId,
  grade,
  role,
  numPassCol,
  numDeniedPassCol,
  totalTimeCol,
];

export const STAFF: ColumnInfo[] = [
  {
    ...firstName,
    accessorFn: (item: any) => assignedToAccessorFn(item, 'first'),
    sort: false,
  },
  {
    ...lastName,
    accessorFn: (item: any) => assignedToAccessorFn(item, 'last'),
    sort: false,
  },
  studentId,
  role,
  numPassCol,
  numDeniedPassCol,
  numOverriddenPassCol,
  totalTimeCol,
];

export const TYPES: ColumnInfo[] = [
  hallPassNameCol,
  numPassCol,
  numDeniedPassCol,
  totalTimeCol,
];

export const HISTORY: ColumnInfo[] = [
  {
    header: 'Status',
    key: 'status',
    type: 'tag',
    width: 'small',
  },
  hallPassNameCol,
  assignedTo,
  assignedToLast,
  studentId,
  {
    header: 'Created by',
    key: 'createdBy',
    tooltip: true,
    type: 'text',
    width: 'flex-medium',
  },
  {
    header: 'Approved by',
    key: 'hpApprovedBy',
    tooltip: true,
    accessorFn: (item: IHallPass) => {
      if (
        item.status === HallPassStatusEnum.APPROVED ||
        item.status === HallPassStatusEnum.OVERRIDDEN
      ) {
        return item.authorPersonViewMinimal
          ? nameFinder(item.authorPersonViewMinimal)
          : '';
      } else {
        return '';
      }
    },
    type: 'text',
    width: 'flex-medium',
  },
  {
    header: 'Denied by',
    key: 'deniedBy',
    tooltip: true,
    type: 'text',
    width: 'flex-medium',
    accessorFn: (item: IHallPass) => {
      if (item.deniedByPerson) {
        return item.deniedByPerson;
      } else if (item.deniedByError) {
        return hallPassErrorToDeniedBy(item.deniedByError);
      }
    },
  },
  {
    header: 'Denied Reason',
    key: 'deniedByReason',
    type: 'text',
    width: 'flex-medium',
  },
  {
    header: 'Ended by',
    key: 'endedByName',
    tooltip: true,
    sort: true,
    accessorFn: (item: any) => {
      if (item.endedByPersonViewMinimal) {
        return nameFinder(item.endedByPersonViewMinimal);
      } else if (item.hallPassType?.manuallyEndPass) {
        return undefined;
      }
      return 'Automatic';
    },
    type: 'text',
    width: 'flex-medium',
  },
  noteAndRequesterNote,
  {
    header: 'Date',
    key: DateColumnKeys.START_DATE,
    tooltip: true,
    sort: true,
    accessorFn: (item: IHallPass) => item.startDate,
    type: 'date',
    width: 'medium',
  },
  {
    header: 'Time',
    key: TimeColumnKeys.START_TIME,
    accessorFn: (item: IHallPass) => item.startDate,
    type: 'date',
    width: 'small',
  },
  {
    header: 'End Date',
    key: DateColumnKeys.END_DATE,
    tooltip: true,
    sort: true,
    accessorFn: (item: IHallPass) => item.endDate,
    type: 'date',
    width: 'small',
  },
  {
    header: 'End Time',
    key: TimeColumnKeys.END_TIME,
    accessorFn: (item: IHallPass) => item.endDate,
    type: 'date',
    width: 'medium',
  },
  {
    header: 'Last Modified',
    key: DateColumnKeys.MANUALLY_UPDATED_AT,
    type: 'date',
    width: 'medium',
  },
];

export const HISTORY_BELL_SCHEDULE: ColumnInfo[] = [
  ...HISTORY.slice(0, 5),
  currentClass,
  ...HISTORY.slice(5),
];

export const OVERDUE: ColumnInfo[] = [
  {
    ...firstName,
    accessorFn: (item: any) => assignedToAccessorFn(item, 'first'),
  },
  {
    ...lastName,
    accessorFn: (item: any) => assignedToAccessorFn(item, 'last'),
  },
  studentId,
  grade,
  role,
  numPassCol,
  {
    header: 'Total Overdue Time',
    key: 'totalExpiredTime',
    accessorFn: totalTimeAccessorFn,
    sort: true,
    type: 'date',
    width: 'large',
  },
];
