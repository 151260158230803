import { Injectable, NgZone } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { grpc } from '@improbable-eng/grpc-web';

import {
  AssetChunk,
  AssetChunkConfirmation,
  DeleteAssetRequest,
  DeleteAssetResponse,
  GetAssetRequest,
  GetAssetResponse,
  NewAssetRequest,
  NewAssetResponse,
  ProcessAssetProgress,
  ProcessAssetProgressRequest,
  ProcessAssetRequest,
  ProcessAssetResponse,
  ReplaceAssetRequest,
  SetAssetRequest,
  SetAssetResponse,
} from '../assetservice/assetservice_pb';

import {
  AssetCrud as __AssetCrud,
} from '../assetservice/assetservice_pb_service';

@Injectable({providedIn: 'root'})
export class AssetCrud {

  constructor(private _ngZone: NgZone) {
  }

  newAsset(request: NewAssetRequest): Promise<NewAssetResponse>;
  newAsset(request: NewAssetRequest, metadata: grpc.Metadata): Promise<NewAssetResponse>;
  newAsset(request: NewAssetRequest, callback: (err: any|null, response: NewAssetResponse, metadata: grpc.Metadata) => void): void;
  newAsset(request: NewAssetRequest, metadata: grpc.Metadata, callback: (err: any|null, response: NewAssetResponse, metadata: grpc.Metadata) => void): void;

  newAsset(request: NewAssetRequest, arg1?: grpc.Metadata|((err: any|null, response: NewAssetResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: NewAssetResponse, metadata: grpc.Metadata) => void): Promise<NewAssetResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<NewAssetResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__AssetCrud.NewAsset, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  uploadChunk(request: AssetChunk): Promise<AssetChunkConfirmation>;
  uploadChunk(request: AssetChunk, metadata: grpc.Metadata): Promise<AssetChunkConfirmation>;
  uploadChunk(request: AssetChunk, callback: (err: any|null, response: AssetChunkConfirmation, metadata: grpc.Metadata) => void): void;
  uploadChunk(request: AssetChunk, metadata: grpc.Metadata, callback: (err: any|null, response: AssetChunkConfirmation, metadata: grpc.Metadata) => void): void;

  uploadChunk(request: AssetChunk, arg1?: grpc.Metadata|((err: any|null, response: AssetChunkConfirmation, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: AssetChunkConfirmation, metadata: grpc.Metadata) => void): Promise<AssetChunkConfirmation>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<AssetChunkConfirmation>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__AssetCrud.UploadChunk, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  getAsset(request: GetAssetRequest): Promise<GetAssetResponse>;
  getAsset(request: GetAssetRequest, metadata: grpc.Metadata): Promise<GetAssetResponse>;
  getAsset(request: GetAssetRequest, callback: (err: any|null, response: GetAssetResponse, metadata: grpc.Metadata) => void): void;
  getAsset(request: GetAssetRequest, metadata: grpc.Metadata, callback: (err: any|null, response: GetAssetResponse, metadata: grpc.Metadata) => void): void;

  getAsset(request: GetAssetRequest, arg1?: grpc.Metadata|((err: any|null, response: GetAssetResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: GetAssetResponse, metadata: grpc.Metadata) => void): Promise<GetAssetResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<GetAssetResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__AssetCrud.GetAsset, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  deleteAsset(request: DeleteAssetRequest): Promise<DeleteAssetResponse>;
  deleteAsset(request: DeleteAssetRequest, metadata: grpc.Metadata): Promise<DeleteAssetResponse>;
  deleteAsset(request: DeleteAssetRequest, callback: (err: any|null, response: DeleteAssetResponse, metadata: grpc.Metadata) => void): void;
  deleteAsset(request: DeleteAssetRequest, metadata: grpc.Metadata, callback: (err: any|null, response: DeleteAssetResponse, metadata: grpc.Metadata) => void): void;

  deleteAsset(request: DeleteAssetRequest, arg1?: grpc.Metadata|((err: any|null, response: DeleteAssetResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: DeleteAssetResponse, metadata: grpc.Metadata) => void): Promise<DeleteAssetResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<DeleteAssetResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__AssetCrud.DeleteAsset, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  replaceAsset(request: ReplaceAssetRequest): Promise<NewAssetResponse>;
  replaceAsset(request: ReplaceAssetRequest, metadata: grpc.Metadata): Promise<NewAssetResponse>;
  replaceAsset(request: ReplaceAssetRequest, callback: (err: any|null, response: NewAssetResponse, metadata: grpc.Metadata) => void): void;
  replaceAsset(request: ReplaceAssetRequest, metadata: grpc.Metadata, callback: (err: any|null, response: NewAssetResponse, metadata: grpc.Metadata) => void): void;

  replaceAsset(request: ReplaceAssetRequest, arg1?: grpc.Metadata|((err: any|null, response: NewAssetResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: NewAssetResponse, metadata: grpc.Metadata) => void): Promise<NewAssetResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<NewAssetResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__AssetCrud.ReplaceAsset, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  processAsset(request: ProcessAssetRequest): Promise<ProcessAssetResponse>;
  processAsset(request: ProcessAssetRequest, metadata: grpc.Metadata): Promise<ProcessAssetResponse>;
  processAsset(request: ProcessAssetRequest, callback: (err: any|null, response: ProcessAssetResponse, metadata: grpc.Metadata) => void): void;
  processAsset(request: ProcessAssetRequest, metadata: grpc.Metadata, callback: (err: any|null, response: ProcessAssetResponse, metadata: grpc.Metadata) => void): void;

  processAsset(request: ProcessAssetRequest, arg1?: grpc.Metadata|((err: any|null, response: ProcessAssetResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: ProcessAssetResponse, metadata: grpc.Metadata) => void): Promise<ProcessAssetResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<ProcessAssetResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__AssetCrud.ProcessAsset, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  streamAssetProcessProgress(request: ProcessAssetProgressRequest): {cancel():void;close():void}&Observable<ProcessAssetProgress>;
  streamAssetProcessProgress(request: ProcessAssetProgressRequest, metadata: grpc.Metadata): {cancel():void;close():void}&Observable<ProcessAssetProgress>;
  streamAssetProcessProgress(request: ProcessAssetProgressRequest, onMessage: (message?: ProcessAssetProgress) => void,onError?: (err: any) => void,onEnd?: (code: number, msg: string|undefined, metadata: grpc.Metadata) => void): void;
  streamAssetProcessProgress(request: ProcessAssetProgressRequest, metadata: grpc.Metadata, onMessage: (message?: ProcessAssetProgress) => void,onError?: (err: any) => void,onEnd?: (code: number, msg: string|undefined, metadata: grpc.Metadata) => void): void;

  streamAssetProcessProgress(request: ProcessAssetProgressRequest, arg1?: grpc.Metadata|((message?: ProcessAssetProgress) => void), arg2?: ((message?: ProcessAssetProgress) => void)|((err: any) => void), arg3?: ((err: any) => void)|((code: number, msg: string|undefined, metadata: grpc.Metadata) => void), arg4?: (code: number, msg: string|undefined, metadata: grpc.Metadata) => void): {cancel():void;close():void}&Observable<ProcessAssetProgress>|void {
    let ret: any;
    let metadata: any;

    let onMessage: any;
    let onError: any;
    let onEnd: any;
    if(typeof arg1 === 'function') {
      onMessage = arg1;
      onError = arg2;
      onEnd = arg3;
    } else if(typeof arg2 === 'function') {
      metadata = arg1;
      onMessage = arg2;
      onError = arg3;
      onEnd = arg4;
    } else {
      metadata = arg1;
    }

    if(!onMessage) {
      let subject = new Subject<ProcessAssetProgress>();
      ret = subject.asObservable();

      onMessage = (response: any) => {
        subject.next(response);
      };

      onError = (err: any) => {
        subject.error(err);
      };

      onEnd = (code: any, msg: any, metadata: any) => {
        subject.complete();
      };

    } else {
      if(!onError) {
        onError = (err: any) => console.error(err);
      }

      if(!onEnd) {
        onEnd = (code: any, msg: any, metadata: any) => {};
      }
    }

    let req = grpc.invoke(__AssetCrud.StreamAssetProcessProgress, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onMessage: response => this._ngZone.run(() => {
        onMessage(response);
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code == grpc.Code.OK) {
          onEnd(code, msg, metadata);
        } else {
          let err = new Error(code + ' ' + (msg||''));
          (<any>err).code = code;
          onError(err);
        }
      })
    });

    (<any>ret).close = () => {
      console.warn('[Angular Grpc] .close() is deprecated use .cancel() instead');
      return req.close();
    };
    (<any>ret).cancel = () => req.close();
    return ret;
  }

  setAsset(request: SetAssetRequest): Promise<SetAssetResponse>;
  setAsset(request: SetAssetRequest, metadata: grpc.Metadata): Promise<SetAssetResponse>;
  setAsset(request: SetAssetRequest, callback: (err: any|null, response: SetAssetResponse, metadata: grpc.Metadata) => void): void;
  setAsset(request: SetAssetRequest, metadata: grpc.Metadata, callback: (err: any|null, response: SetAssetResponse, metadata: grpc.Metadata) => void): void;

  setAsset(request: SetAssetRequest, arg1?: grpc.Metadata|((err: any|null, response: SetAssetResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: SetAssetResponse, metadata: grpc.Metadata) => void): Promise<SetAssetResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<SetAssetResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__AssetCrud.SetAsset, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

}

