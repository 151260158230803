import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Router } from '@angular/router';

import { eventCategoryColor, EventCategoryEnum } from 'libs/shared';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { $enum } from 'ts-enum-util';

import {
  CalendarScheduleEventDate,
  CalendarScheduleView,
  ICalendarScheduleEvent,
} from '@app/src/app/components/CalendarSchedule/types';
import {
  EventCalendarService,
  IEventCalendarSettings,
} from '@app/src/app/services/EventCalendar';
import {
  EventFeed,
  EventFeedOptions,
  IEventFeedItem,
} from '@app/src/app/services/EventFeed';

function toCalendarEvent(item: IEventFeedItem): ICalendarScheduleEvent {
  const category = $enum(EventCategoryEnum).getValueOrDefault(
    item.shortCard?.category,
    EventCategoryEnum.OTHER,
  );

  const date: CalendarScheduleEventDate = item.allDay
    ? { start: item.start!, allDay: true }
    : { start: item.start!, end: item.end };

  return {
    date: date,
    color: eventCategoryColor(category),
    title: item.title,
    // Purposely do not set description by design
    description: '',
    key: item.contentContext,
  };
}

@Component({
  templateUrl: './EventCalendar.component.html',
  styleUrls: ['./EventCalendar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EventCalendarComponent implements OnDestroy, OnInit {
  goingEventsCount: number = 0;
  searchText: string = '';
  activeDate = new Date();

  readonly calendarEvents$: Observable<ICalendarScheduleEvent[]>;
  readonly settings$: Observable<Readonly<IEventCalendarSettings>>;

  constructor(
    private eventCalendarService: EventCalendarService,
    private router: Router,
  ) {
    this.settings$ = eventCalendarService.settings$;
    this.calendarEvents$ = eventCalendarService.filteredEvents$.pipe(
      map(items => items.map(toCalendarEvent)),
    );
  }

  ngOnInit() {}

  ngOnDestroy() {}

  onActiveDateChange(newDate: Date) {}

  onClickCalendarEventItem(item: ICalendarScheduleEvent) {
    if (typeof item.key !== 'string') {
      console.error('Cannot open calendar item due to invalid key');
      return;
    }

    // This is kind of a work around.
    const contentContext = item.key;
    this.router.navigate(['', { outlets: { o: ['view', contentContext] } }]);
  }
}
