import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { AbstractControl, UntypedFormControl } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';

import * as day from 'dayjs';
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';
import { map, startWith, takeUntil } from 'rxjs/operators';

import { MediaService } from '@shared/services/media';

import { FormErrorMessages } from '../../constants';
import {
  FormAppearanceVariant,
  FormLabelBackground,
  FormSizes,
} from '../../types';
import { FormDateHeaderComponent } from '../form-date-range/components/form-date-header/form-date-header.component';

@Component({
  selector: 'mg-form-date',
  templateUrl: './form-date.component.html',
  styleUrls: ['./form-date.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormDateComponent implements OnInit, OnDestroy, OnChanges {
  // Children
  @ViewChild('inputContainer') inputContainer;
  @ViewChild('picker') picker;

  // Constants
  public readonly FORM_ERROR_MESSAGES = FormErrorMessages;

  // Cleanup
  private _destroyedSubject = new ReplaySubject<void>(1);

  // State
  public formControl = new UntypedFormControl();
  private readonly _inputIsFocused$ = new BehaviorSubject<boolean>(false);
  public readonly inputIsFocused$ = this._inputIsFocused$.asObservable();
  public customHeader = FormDateHeaderComponent;

  // Inputs
  @Input() label = 'Date';
  @Input() labelBackground: FormLabelBackground = 'white';
  @Input() isClearable = false;
  @Input() widthSize: FormSizes = 'large';
  @Input() format = 'MMM D, YYYY';
  @Input() appearance?: FormAppearanceVariant = 'primary';
  @Input() hint: string;
  @Input() set control(formControl: UntypedFormControl | AbstractControl) {
    this.formControl = formControl as UntypedFormControl;
  }
  /** Set mininum date user select */
  @Input() minDate: day.Dayjs;
  /** Set maximum date user can select */
  @Input() maxDate: day.Dayjs;
  /**
   * Unique id for things like analytics and testing to hook into
   * Important to note changing this could break either of those
   */
  @Input() id: string;
  @Input() makeEndOfDay = false;

  /**
   * Alternative approach to using form control for value changes
   */
  @Input() value: day.Dayjs | null = null;
  @Output() dateChanged = new EventEmitter<day.Dayjs>();

  public formattedDate$: Observable<string>;

  get isValid() {
    return (
      this.formControl.valid &&
      this.formControl.dirty &&
      this.formControl.touched
    );
  }

  get inputDisabled() {
    return this.formControl.disabled;
  }

  get formStyleClasses(): Record<string, boolean> {
    return {
      [this.widthSize]: true,
      [this.labelBackground]: true,
    };
  }

  /** Component Constructor */
  constructor(public mediaService: MediaService) {}

  ngOnInit(): void {
    this.formattedDate$ = this.formControl.valueChanges.pipe(
      startWith(this.formControl.value),
      takeUntil(this._destroyedSubject),
      map((value: day.Dayjs) => value?.format(this.format) ?? ''),
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.value && !this.control) {
      this.formControl.setValue(this.value);
    }
  }

  ngOnDestroy(): void {
    this._destroyedSubject.next();
    this._destroyedSubject.complete();
    this._inputIsFocused$.complete();
  }

  public openCalendar(): void {
    this.picker?.open();
  }

  public async inputGainedFocus(): Promise<void> {
    this._inputIsFocused$.next(true);
  }

  public async inputLostFocus(): Promise<void> {
    this._inputIsFocused$.next(false);
  }

  public dateChange(event: MatDatepickerInputEvent<Date>) {
    let formattedDate = event.value ? day(event.value) : null;
    if (this.makeEndOfDay && formattedDate) {
      formattedDate = formattedDate.endOf('day');
    }
    this.formControl.setValue(formattedDate, { emitEvent: true });
    this.formControl.markAsDirty();
    this.dateChanged.emit(formattedDate);
  }

  public clearValue() {
    if (!this.isClearable) return;
    this.formControl.setValue(null, { emitEvent: true });
    this.formControl.markAsDirty();
    this._closePicker();
  }

  private _closePicker() {
    this.picker?.close();
  }
}
