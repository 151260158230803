// source: period/period.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

import * as jspb from 'google-protobuf';
export namespace Period {
  export interface AsObject {
    id: number;
    shortCode: string;
    periodName: string;
    startTime: string;
    endTime: string;
  }
}
export class Period extends jspb.Message {
  static readonly displayName = "Period";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): Period.AsObject {
    return Period.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!Period} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: Period) {
    var f: any;
    var obj: any = {
      id: jspb.Message.getFieldWithDefault(msg, 1, 0),
      shortCode: jspb.Message.getFieldWithDefault(msg, 2, ""),
      periodName: jspb.Message.getFieldWithDefault(msg, 3, ""),
      startTime: jspb.Message.getFieldWithDefault(msg, 4, ""),
      endTime: jspb.Message.getFieldWithDefault(msg, 5, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!Period}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new Period;
    return Period.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!Period} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!Period}
   */
  static deserializeBinaryFromReader(msg: Period, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {number} */ (reader.readInt32());
        msg.setId(value1);
        break;
      case 2:
        var value2 = /** @type {string} */ (reader.readString());
        msg.setShortCode(value2);
        break;
      case 3:
        var value3 = /** @type {string} */ (reader.readString());
        msg.setPeriodName(value3);
        break;
      case 4:
        var value4 = /** @type {string} */ (reader.readString());
        msg.setStartTime(value4);
        break;
      case 5:
        var value5 = /** @type {string} */ (reader.readString());
        msg.setEndTime(value5);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!Period} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: Period, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getId();
    if (f !== 0) {
      writer.writeInt32(
        1,
        f
      );
    }
    f = message.getShortCode();
    if (f.length > 0) {
      writer.writeString(
        2,
        f
      );
    }
    f = message.getPeriodName();
    if (f.length > 0) {
      writer.writeString(
        3,
        f
      );
    }
    f = message.getStartTime();
    if (f.length > 0) {
      writer.writeString(
        4,
        f
      );
    }
    f = message.getEndTime();
    if (f.length > 0) {
      writer.writeString(
        5,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    Period.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional int32 id = 1;
   * @return {number}
   */
  getId(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
  };


  /** @param {number} value */
  setId(value: number) {
    jspb.Message.setProto3IntField(this, 1, value);
  }


  /**
   * optional string short_code = 2;
   * @return {string}
   */
  getShortCode(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
  };


  /** @param {string} value */
  setShortCode(value: string) {
    jspb.Message.setProto3StringField(this, 2, value);
  }


  /**
   * optional string period_name = 3;
   * @return {string}
   */
  getPeriodName(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
  };


  /** @param {string} value */
  setPeriodName(value: string) {
    jspb.Message.setProto3StringField(this, 3, value);
  }


  /**
   * optional string start_time = 4;
   * @return {string}
   */
  getStartTime(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
  };


  /** @param {string} value */
  setStartTime(value: string) {
    jspb.Message.setProto3StringField(this, 4, value);
  }


  /**
   * optional string end_time = 5;
   * @return {string}
   */
  getEndTime(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
  };


  /** @param {string} value */
  setEndTime(value: string) {
    jspb.Message.setProto3StringField(this, 5, value);
  }


}
export namespace GetPeriodsRequest {
  export interface AsObject {
  }
}
export class GetPeriodsRequest extends jspb.Message {
  static readonly displayName = "GetPeriodsRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): GetPeriodsRequest.AsObject {
    return GetPeriodsRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!GetPeriodsRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: GetPeriodsRequest) {
    var f: any;
    var obj: any = {

    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!GetPeriodsRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new GetPeriodsRequest;
    return GetPeriodsRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!GetPeriodsRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!GetPeriodsRequest}
   */
  static deserializeBinaryFromReader(msg: GetPeriodsRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!GetPeriodsRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: GetPeriodsRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    GetPeriodsRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
}
export namespace GetPeriodsResponse {
  export interface AsObject {
    periodsList: Period.AsObject[];
  }
}
export class GetPeriodsResponse extends jspb.Message {
  static readonly displayName = "GetPeriodsResponse";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [1];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, GetPeriodsResponse.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): GetPeriodsResponse.AsObject {
    return GetPeriodsResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!GetPeriodsResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: GetPeriodsResponse) {
    var f: any;
    var obj: any = {
      periodsList: jspb.Message.toObjectList(msg.getPeriodsList(),
      Period.toObject, includeInstance)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!GetPeriodsResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new GetPeriodsResponse;
    return GetPeriodsResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!GetPeriodsResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!GetPeriodsResponse}
   */
  static deserializeBinaryFromReader(msg: GetPeriodsResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new Period;
        reader.readMessage(value1,Period.deserializeBinaryFromReader);
        msg.addPeriods(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!GetPeriodsResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: GetPeriodsResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getPeriodsList();
    if (f.length > 0) {
      writer.writeRepeatedMessage(
        1,
        f,
        Period.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    GetPeriodsResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * repeated Period periods = 1;
   * @return {!Array<!Period>}
   */
  getPeriodsList(): Period[] {
    return /** @type{!Array<!Period>} */ (
      jspb.Message.getRepeatedWrapperField(this, Period, 1));
  }


  /** @param {!Array<!Period>} value */
  setPeriodsList(value?: Period[]) {
    jspb.Message.setRepeatedWrapperField(this, 1, value);
  }


  /**
   * @param {!Period=} opt_value
   * @param {number=} opt_index
   * @return {!Period}
   */
  addPeriods(opt_value?: Period, opt_index?: number): Period {
    return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, Period, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearPeriodsList() {
    this.setPeriodsList([]);
  }


}
