// source: messaging_settings/messaging_settings.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

import * as jspb from 'google-protobuf';
export namespace RoleTypeListWrapper {
  export interface AsObject {
    roleTypeList: string[];
  }
}
export class RoleTypeListWrapper extends jspb.Message {
  static readonly displayName = "RoleTypeListWrapper";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [1];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, RoleTypeListWrapper.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): RoleTypeListWrapper.AsObject {
    return RoleTypeListWrapper.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!RoleTypeListWrapper} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: RoleTypeListWrapper) {
    var f: any;
    var obj: any = {
      roleTypeList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!RoleTypeListWrapper}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new RoleTypeListWrapper;
    return RoleTypeListWrapper.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!RoleTypeListWrapper} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!RoleTypeListWrapper}
   */
  static deserializeBinaryFromReader(msg: RoleTypeListWrapper, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.addRoleType(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!RoleTypeListWrapper} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: RoleTypeListWrapper, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getRoleTypeList();
    if (f.length > 0) {
      writer.writeRepeatedString(
        1,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    RoleTypeListWrapper.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * repeated string role_type = 1;
   * @return {!Array<string>}
   */
  getRoleTypeList(): string[] {
    return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
  };


  /** @param {!Array<string>} value */
  setRoleTypeList(value: string[]) {
    jspb.Message.setField(this, 1, value || []);
  }


  /**
   * @param {string} value
   * @param {number=} opt_index
   */
  addRoleType(value: string, opt_index?: number): void {
    jspb.Message.addToRepeatedField(this, 1, value, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearRoleTypeList() {
    this.setRoleTypeList([]);
  }


}
export namespace MessagingSettingsSetRoleMessagingRequest {
  export interface AsObject {
    roleTypeListList: RoleTypeListWrapper.AsObject[];
    enabled: boolean;
  }
}
export class MessagingSettingsSetRoleMessagingRequest extends jspb.Message {
  static readonly displayName = "MessagingSettingsSetRoleMessagingRequest";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [1];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, MessagingSettingsSetRoleMessagingRequest.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): MessagingSettingsSetRoleMessagingRequest.AsObject {
    return MessagingSettingsSetRoleMessagingRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!MessagingSettingsSetRoleMessagingRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: MessagingSettingsSetRoleMessagingRequest) {
    var f: any;
    var obj: any = {
      roleTypeListList: jspb.Message.toObjectList(msg.getRoleTypeListList(),
      RoleTypeListWrapper.toObject, includeInstance),
      enabled: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!MessagingSettingsSetRoleMessagingRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new MessagingSettingsSetRoleMessagingRequest;
    return MessagingSettingsSetRoleMessagingRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!MessagingSettingsSetRoleMessagingRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!MessagingSettingsSetRoleMessagingRequest}
   */
  static deserializeBinaryFromReader(msg: MessagingSettingsSetRoleMessagingRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new RoleTypeListWrapper;
        reader.readMessage(value1,RoleTypeListWrapper.deserializeBinaryFromReader);
        msg.addRoleTypeList(value1);
        break;
      case 2:
        var value2 = /** @type {boolean} */ (reader.readBool());
        msg.setEnabled(value2);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!MessagingSettingsSetRoleMessagingRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: MessagingSettingsSetRoleMessagingRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getRoleTypeListList();
    if (f.length > 0) {
      writer.writeRepeatedMessage(
        1,
        f,
        RoleTypeListWrapper.serializeBinaryToWriter
      );
    }
    f = message.getEnabled();
    if (f) {
      writer.writeBool(
        2,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    MessagingSettingsSetRoleMessagingRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * repeated RoleTypeListWrapper role_type_list = 1;
   * @return {!Array<!RoleTypeListWrapper>}
   */
  getRoleTypeListList(): RoleTypeListWrapper[] {
    return /** @type{!Array<!RoleTypeListWrapper>} */ (
      jspb.Message.getRepeatedWrapperField(this, RoleTypeListWrapper, 1));
  }


  /** @param {!Array<!RoleTypeListWrapper>} value */
  setRoleTypeListList(value?: RoleTypeListWrapper[]) {
    jspb.Message.setRepeatedWrapperField(this, 1, value);
  }


  /**
   * @param {!RoleTypeListWrapper=} opt_value
   * @param {number=} opt_index
   * @return {!RoleTypeListWrapper}
   */
  addRoleTypeList(opt_value?: RoleTypeListWrapper, opt_index?: number): RoleTypeListWrapper {
    return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, RoleTypeListWrapper, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearRoleTypeListList() {
    this.setRoleTypeListList([]);
  }


  /**
   * optional bool enabled = 2;
   * @return {boolean}
   */
  getEnabled(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
  };


  /** @param {boolean} value */
  setEnabled(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 2, value);
  }


}
export namespace MessagingSettingsSetRoleMessagingResponse {
  export interface AsObject {
  }
}
export class MessagingSettingsSetRoleMessagingResponse extends jspb.Message {
  static readonly displayName = "MessagingSettingsSetRoleMessagingResponse";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): MessagingSettingsSetRoleMessagingResponse.AsObject {
    return MessagingSettingsSetRoleMessagingResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!MessagingSettingsSetRoleMessagingResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: MessagingSettingsSetRoleMessagingResponse) {
    var f: any;
    var obj: any = {

    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!MessagingSettingsSetRoleMessagingResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new MessagingSettingsSetRoleMessagingResponse;
    return MessagingSettingsSetRoleMessagingResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!MessagingSettingsSetRoleMessagingResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!MessagingSettingsSetRoleMessagingResponse}
   */
  static deserializeBinaryFromReader(msg: MessagingSettingsSetRoleMessagingResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!MessagingSettingsSetRoleMessagingResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: MessagingSettingsSetRoleMessagingResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    MessagingSettingsSetRoleMessagingResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
}
export namespace MessagingSettingsGetRoleMessagingRequest {
  export interface AsObject {
  }
}
export class MessagingSettingsGetRoleMessagingRequest extends jspb.Message {
  static readonly displayName = "MessagingSettingsGetRoleMessagingRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): MessagingSettingsGetRoleMessagingRequest.AsObject {
    return MessagingSettingsGetRoleMessagingRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!MessagingSettingsGetRoleMessagingRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: MessagingSettingsGetRoleMessagingRequest) {
    var f: any;
    var obj: any = {

    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!MessagingSettingsGetRoleMessagingRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new MessagingSettingsGetRoleMessagingRequest;
    return MessagingSettingsGetRoleMessagingRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!MessagingSettingsGetRoleMessagingRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!MessagingSettingsGetRoleMessagingRequest}
   */
  static deserializeBinaryFromReader(msg: MessagingSettingsGetRoleMessagingRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!MessagingSettingsGetRoleMessagingRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: MessagingSettingsGetRoleMessagingRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    MessagingSettingsGetRoleMessagingRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
}
export namespace MessagingSettingsGetRoleMessagingResponse {
  export interface AsObject {
    settingList: MessagingSettingsGetRoleMessagingResponse.Settings.AsObject[];
  }
}
export class MessagingSettingsGetRoleMessagingResponse extends jspb.Message {
  static readonly displayName = "MessagingSettingsGetRoleMessagingResponse";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [1];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, MessagingSettingsGetRoleMessagingResponse.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): MessagingSettingsGetRoleMessagingResponse.AsObject {
    return MessagingSettingsGetRoleMessagingResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!MessagingSettingsGetRoleMessagingResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: MessagingSettingsGetRoleMessagingResponse) {
    var f: any;
    var obj: any = {
      settingList: jspb.Message.toObjectList(msg.getSettingList(),
      MessagingSettingsGetRoleMessagingResponse.Settings.toObject, includeInstance)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!MessagingSettingsGetRoleMessagingResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new MessagingSettingsGetRoleMessagingResponse;
    return MessagingSettingsGetRoleMessagingResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!MessagingSettingsGetRoleMessagingResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!MessagingSettingsGetRoleMessagingResponse}
   */
  static deserializeBinaryFromReader(msg: MessagingSettingsGetRoleMessagingResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new MessagingSettingsGetRoleMessagingResponse.Settings;
        reader.readMessage(value1,MessagingSettingsGetRoleMessagingResponse.Settings.deserializeBinaryFromReader);
        msg.addSetting(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!MessagingSettingsGetRoleMessagingResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: MessagingSettingsGetRoleMessagingResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getSettingList();
    if (f.length > 0) {
      writer.writeRepeatedMessage(
        1,
        f,
        MessagingSettingsGetRoleMessagingResponse.Settings.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    MessagingSettingsGetRoleMessagingResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * repeated Settings setting = 1;
   * @return {!Array<!MessagingSettingsGetRoleMessagingResponse.Settings>}
   */
  getSettingList(): MessagingSettingsGetRoleMessagingResponse.Settings[] {
    return /** @type{!Array<!MessagingSettingsGetRoleMessagingResponse.Settings>} */ (
      jspb.Message.getRepeatedWrapperField(this, MessagingSettingsGetRoleMessagingResponse.Settings, 1));
  }


  /** @param {!Array<!MessagingSettingsGetRoleMessagingResponse.Settings>} value */
  setSettingList(value?: MessagingSettingsGetRoleMessagingResponse.Settings[]) {
    jspb.Message.setRepeatedWrapperField(this, 1, value);
  }


  /**
   * @param {!MessagingSettingsGetRoleMessagingResponse.Settings=} opt_value
   * @param {number=} opt_index
   * @return {!MessagingSettingsGetRoleMessagingResponse.Settings}
   */
  addSetting(opt_value?: MessagingSettingsGetRoleMessagingResponse.Settings, opt_index?: number): MessagingSettingsGetRoleMessagingResponse.Settings {
    return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, MessagingSettingsGetRoleMessagingResponse.Settings, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearSettingList() {
    this.setSettingList([]);
  }


}
export namespace MessagingSettingsGetRoleMessagingResponse {
export namespace Settings {
  export interface AsObject {
    roleTypeListList: RoleTypeListWrapper.AsObject[];
    enabled: boolean;
    localeTitleKey: string;
    immutable: boolean;
  }
}
export class Settings extends jspb.Message {
  static readonly displayName = "MessagingSettingsGetRoleMessagingResponse.Settings";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [1];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, MessagingSettingsGetRoleMessagingResponse.Settings.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): Settings.AsObject {
    return Settings.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!Settings} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: Settings) {
    var f: any;
    var obj: any = {
      roleTypeListList: jspb.Message.toObjectList(msg.getRoleTypeListList(),
      RoleTypeListWrapper.toObject, includeInstance),
      enabled: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
      localeTitleKey: jspb.Message.getFieldWithDefault(msg, 3, ""),
      immutable: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!Settings}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new Settings;
    return Settings.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!MessagingSettingsGetRoleMessagingResponse.Settings} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!MessagingSettingsGetRoleMessagingResponse.Settings}
   */
  static deserializeBinaryFromReader(msg: Settings, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new RoleTypeListWrapper;
        reader.readMessage(value1,RoleTypeListWrapper.deserializeBinaryFromReader);
        msg.addRoleTypeList(value1);
        break;
      case 2:
        var value2 = /** @type {boolean} */ (reader.readBool());
        msg.setEnabled(value2);
        break;
      case 3:
        var value3 = /** @type {string} */ (reader.readString());
        msg.setLocaleTitleKey(value3);
        break;
      case 4:
        var value4 = /** @type {boolean} */ (reader.readBool());
        msg.setImmutable(value4);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!MessagingSettingsGetRoleMessagingResponse.Settings} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: MessagingSettingsGetRoleMessagingResponse.Settings, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getRoleTypeListList();
    if (f.length > 0) {
      writer.writeRepeatedMessage(
        1,
        f,
        RoleTypeListWrapper.serializeBinaryToWriter
      );
    }
    f = message.getEnabled();
    if (f) {
      writer.writeBool(
        2,
        f
      );
    }
    f = message.getLocaleTitleKey();
    if (f.length > 0) {
      writer.writeString(
        3,
        f
      );
    }
    f = message.getImmutable();
    if (f) {
      writer.writeBool(
        4,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    MessagingSettingsGetRoleMessagingResponse.Settings.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * repeated RoleTypeListWrapper role_type_list = 1;
   * @return {!Array<!RoleTypeListWrapper>}
   */
  getRoleTypeListList(): RoleTypeListWrapper[] {
    return /** @type{!Array<!RoleTypeListWrapper>} */ (
      jspb.Message.getRepeatedWrapperField(this, RoleTypeListWrapper, 1));
  }


  /** @param {!Array<!RoleTypeListWrapper>} value */
  setRoleTypeListList(value?: RoleTypeListWrapper[]) {
    jspb.Message.setRepeatedWrapperField(this, 1, value);
  }


  /**
   * @param {!RoleTypeListWrapper=} opt_value
   * @param {number=} opt_index
   * @return {!RoleTypeListWrapper}
   */
  addRoleTypeList(opt_value?: RoleTypeListWrapper, opt_index?: number): RoleTypeListWrapper {
    return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, RoleTypeListWrapper, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearRoleTypeListList() {
    this.setRoleTypeListList([]);
  }


  /**
   * optional bool enabled = 2;
   * @return {boolean}
   */
  getEnabled(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
  };


  /** @param {boolean} value */
  setEnabled(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 2, value);
  }


  /**
   * optional string locale_title_key = 3;
   * @return {string}
   */
  getLocaleTitleKey(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
  };


  /** @param {string} value */
  setLocaleTitleKey(value: string) {
    jspb.Message.setProto3StringField(this, 3, value);
  }


  /**
   * optional bool immutable = 4;
   * @return {boolean}
   */
  getImmutable(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
  };


  /** @param {boolean} value */
  setImmutable(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 4, value);
  }


}
} // namespace MessagingSettingsGetRoleMessagingResponse
