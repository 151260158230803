// GENERATED CODE -- DO NOT EDIT!

import {grpc} from '@improbable-eng/grpc-web';

import * as gateway_program_stream_pb from '../gateway/program_stream_pb';
import * as common_stream_pb from '../common/stream_pb';
import * as image_image_pb from '../image/image_pb';
import * as gateway_content_views_pb from '../gateway/content_views_pb';

export class ProgramStreamManager {
  static readonly serviceName = "minga.content.ProgramStreamManager";

  static readonly StreamPrograms = {
    methodName: "StreamPrograms",
    service: ProgramStreamManager,
    requestStream: false,
    responseStream: true,
    requestType: common_stream_pb.StreamID,
    responseType: gateway_program_stream_pb.StreamProgramInfo,
  };

  static readonly StreamProgramsControl = {
    methodName: "StreamProgramsControl",
    service: ProgramStreamManager,
    requestStream: false,
    responseStream: false,
    requestType: common_stream_pb.StreamControl,
    responseType: common_stream_pb.StreamControlResponse,
  };

  static readonly StreamProgramContent = {
    methodName: "StreamProgramContent",
    service: ProgramStreamManager,
    requestStream: false,
    responseStream: true,
    requestType: common_stream_pb.StreamID,
    responseType: gateway_program_stream_pb.StreamProgramContentInfo,
  };

  static readonly StreamProgramContentControl = {
    methodName: "StreamProgramContentControl",
    service: ProgramStreamManager,
    requestStream: false,
    responseStream: false,
    requestType: common_stream_pb.StreamControl,
    responseType: common_stream_pb.StreamControlResponse,
  };

}

export class ProgramStreamManagerClient {
  streamPrograms() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  streamProgramsControl() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  streamProgramContent() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  streamProgramContentControl() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
}
