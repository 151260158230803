import { Injectable, OnDestroy } from '@angular/core';

import { IMembershipList, MembershipListType } from 'libs/domain';
import { BehaviorSubject } from 'rxjs';
import { share } from 'rxjs/operators';

import { ListMembershipService } from '@app/src/app/services/ListMembership';

import { SystemAlertSnackBarService } from '@shared/components/system-alert-snackbar';

@Injectable()
export class PointsManagerService implements OnDestroy {
  /** School Teams */
  private readonly _isLoadingTeams = new BehaviorSubject(false);
  public readonly isLoadingTeams$ = this._isLoadingTeams
    .asObservable()
    .pipe(share());
  private readonly _teams = new BehaviorSubject<IMembershipList[]>([]);
  public readonly teams$ = this._teams.asObservable().pipe(share());

  /** Service Constructor */
  constructor(
    private _listService: ListMembershipService,
    private _systemAlertSnackBar: SystemAlertSnackBarService,
  ) {}

  ngOnDestroy(): void {
    this._isLoadingTeams.complete();
    this._teams.complete();
  }

  public getTeams(): IMembershipList[] {
    return this._teams.getValue();
  }

  public setTeams(teams: IMembershipList[]) {
    this._teams.next(teams);
  }

  public async fetchTeam(id): Promise<IMembershipList> {
    try {
      const result = await this._listService.getMembershipList(id, true);
      return result;
    } catch (error) {
      this._systemAlertSnackBar.error(
        `Failed to fetch school team with the id: ${id}`,
      );
    }
  }

  public async fetchTeams(): Promise<void> {
    try {
      this._isLoadingTeams.next(true);
      const results = await this._listTeams();
      this.setTeams([...results]);
    } catch (error) {
    } finally {
      this._isLoadingTeams.next(false);
    }
  }

  private async _listTeams(): Promise<IMembershipList[]> {
    const result = this._listService.getMembershipListByType([
      MembershipListType.SCHOOL_TEAM,
    ]);
    return result;
  }
}
