import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { RouterModule } from '@angular/router';

import { StoreModule } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';

import { MgButtonModule } from '@app/src/app/button';
import { MgEmptyStateModule } from '@app/src/app/components/EmptyState';
import { MgStreamScrollerModule } from '@app/src/app/components/MgStreamScroller';
import { TopOfFeedModule } from '@app/src/app/components/TopOfFeed';
import { MgPagedScrollerModule } from '@app/src/app/components/mg-paged-scroller/mg-paged-scroller.module';
import { MgDateTimePickerModule } from '@app/src/app/date-time-picker';
import { MgContentFeedItemModule } from '@app/src/app/elements/MgContentFeedItem';
import { MgDateSeparatorModule } from '@app/src/app/elements/MgDateSeparator';
import { PermissionsModule } from '@app/src/app/permissions';
import { RoleSelectModule } from '@app/src/app/roles/containers/RoleSelect';
import { SearchModule } from '@app/src/app/search';
import { SearchContentModule } from '@app/src/app/search-content';
import { MgSpinnerModule } from '@app/src/app/spinner';

import { LayoutLegacyContentModule } from '@modules/layout/components/layout-legacy-content/layout-legacy-content.module';

import { HomeActionButtonModule } from '../home-action-button/home-action-button.module';
import { HomeContentComponent } from './home-content.component';

@NgModule({
  imports: [
    // Minga dependencies
    LayoutLegacyContentModule,
    HomeActionButtonModule,
    MgContentFeedItemModule,
    SearchContentModule,
    SearchModule,
    TopOfFeedModule,
    MgEmptyStateModule,
    PermissionsModule,
    MgPagedScrollerModule,
    MgStreamScrollerModule,
    MgButtonModule,
    MgSpinnerModule,
    MgDateTimePickerModule,
    MgDateSeparatorModule,
    RoleSelectModule,

    // External dependencies
    CommonModule,
    FlexLayoutModule,
    RouterModule,
    TranslateModule,
    StoreModule,
  ],
  declarations: [HomeContentComponent],
  exports: [HomeContentComponent],
})
export class HomeContentModule {}
