import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';

import { MembershipListType } from 'libs/domain';
import { IPbisType } from 'libs/domain';
import { mingaSettingTypes } from 'libs/util';
import { Observable, ReplaySubject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

import { RootService } from '@app/src/app/minimal/services/RootService';
import { MingaSettingsService } from '@app/src/app/store/Minga/services';
import { scrollIntoView } from '@app/src/app/util/scroll-into-view';

import { SelectOption } from '@modules/behavior-manager/components/bm-reports/types';
import { BehaviorManagerService } from '@modules/behavior-manager/services';

import { CrudFormBase } from '@shared/components/crud-form-base/crud-form-base.abstract';
import { markNestedFormGroupAsDirty } from '@shared/components/crud-form-base/crud-form-base.utils';
import {
  FormRestrictionTabs,
  fromRestrictionInput,
  toRestrictionInput,
} from '@shared/components/form-restriction-input/form-restriction-input.constants';
import { COLOR_PICKER_PRESETS } from '@shared/components/form/constants';
import {
  MODAL_OVERLAY_DATA,
  ModalOverlayPrimaryHeaderBackground,
  ModalOverlayRef,
  ModalOverlayServiceCloseEventType,
} from '@shared/components/modal-overlay';
import {
  SystemAlertCloseEvents,
  SystemAlertModalService,
  SystemAlertModalType,
} from '@shared/components/system-alert-modal';

import { BehaviorMsgCategory } from '../../constants';
import {
  BmTypesBehaviorEditModalData,
  BmTypesBehaviorEditModalResponse,
} from '../../types';
import { getBmTypeFormConfig } from '../../utils/bm-types-behavior-edit.utils';
import {
  BmTypesBehaviorEditFormFields,
  BmTypesBehaviorEditMessages,
  ICON_CONFIG_DEFAULTS,
  ICON_OPTIONS,
} from './bm-types-behavior-edit.constants';

@Component({
  selector: 'mg-bm-types-behavior-edit',
  templateUrl: './bm-types-behavior-edit.component.html',
  styleUrls: ['./bm-types-behavior-edit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BmTypesBehaviorEditComponent
  extends CrudFormBase<IPbisType>
  implements OnInit, OnDestroy
{
  public toolbarData: Record<string, any>;
  private _destroyed = new ReplaySubject<void>(1);

  /** Constants */
  public readonly MESSAGES = BmTypesBehaviorEditMessages;
  public readonly MEMBERSHIP_LIST_TYPE = MembershipListType;
  public readonly RESTRICTION_TABS = FormRestrictionTabs;
  public readonly FORM_FIELD = BmTypesBehaviorEditFormFields;
  public readonly MSG_CATEGORY = BehaviorMsgCategory;
  public readonly MODAL_CONFIG = {
    headerBg: ModalOverlayPrimaryHeaderBackground.GREEN,
  };

  @ViewChild('formElement', { static: false })
  formElement?: ElementRef<HTMLElement>;

  public readonly hasSmsEnabled$: Observable<boolean> =
    this._settingService.getSettingValueObs(mingaSettingTypes.ENABLE_SMS);
  public readonly hasHpEnabled$: Observable<boolean> =
    this._settingService.isHallPassModuleEnabled();

  public readonly hallPassTypesOptions$ = this._bmService.hallPassTypes$.pipe(
    takeUntil(this._destroyed),
    map(types =>
      types.map(type => ({
        label: type.name,
        value: type.id,
      })),
    ),
  );

  public readonly form = this._fb.group(getBmTypeFormConfig());
  public PRESET_COLORS = Object.values(COLOR_PICKER_PRESETS).reduce(
    (acc, curr) => [...acc, ...curr],
    [],
  );
  public ICON_OPTIONS = ICON_OPTIONS;

  public emailOptions: SelectOption[];

  constructor(
    @Inject(MODAL_OVERLAY_DATA)
    public dialogData: BmTypesBehaviorEditModalData,
    private _modalRef: ModalOverlayRef<
      BmTypesBehaviorEditModalResponse,
      BmTypesBehaviorEditModalData
    >,
    private _fb: UntypedFormBuilder,
    public _bmService: BehaviorManagerService,
    private _settingService: MingaSettingsService,
    public rootService: RootService,
    private _systemAlertModal: SystemAlertModalService,
  ) {
    super({
      id: dialogData?.id,
      get: id => {
        return this._bmService.fetchType(id);
      },
      create: data => {
        return this._bmService.createType(data);
      },
      update: data => {
        return this._bmService.updateType(data);
      },
      delete: async data => {
        await this._bmService.deleteType(data.id);
        return data;
      },
      onSetForm: (type, isNew) => {
        if (isNew) return;

        const nameField = this.form.get(BmTypesBehaviorEditFormFields.NAME);
        const pointsField = this.form.get(BmTypesBehaviorEditFormFields.POINTS);
        const parentEmailField = this.form.get(
          BmTypesBehaviorEditFormFields.PARENT_EMAIL,
        );
        const parentSmsField = this.form.get(
          BmTypesBehaviorEditFormFields.PARENT_SMS,
        );
        const sendAdminEmail = this.form.get(
          BmTypesBehaviorEditFormFields.SEND_ADMIN_EMAIL,
        );
        const adminEmail = this.form.get(
          BmTypesBehaviorEditFormFields.ADMIN_EMAIL,
        );
        const categoryId = this.form.get(
          BmTypesBehaviorEditFormFields.CATEGORY,
        );
        const hallPassType = this.form.get(
          BmTypesBehaviorEditFormFields.HALL_PASS_ID,
        );
        const iconColorType = this.form.get(
          BmTypesBehaviorEditFormFields.ICON_COLOR,
        );
        const iconType = this.form.get(BmTypesBehaviorEditFormFields.ICON);

        nameField.setValue(type.name);
        pointsField.setValue(type.points);
        parentEmailField.setValue(type.sendParentEmail);
        parentSmsField.setValue(type.sendParentSms);
        sendAdminEmail.setValue(type.sendAdminEmail);
        adminEmail.setValue(type.adminEmails);
        categoryId.setValue(type.categoryId);
        hallPassType.setValue(type.hallPassTypeId || undefined);
        if (type.iconColor) iconColorType.setValue(type.iconColor);
        if (type.iconType) iconType.setValue(type.iconType);

        if (type.emailTemplate?.body) {
          this.form
            .get(BmTypesBehaviorEditFormFields.PARENT_EMAIL_BODY)
            .setValue(type.emailTemplate?.body);
        }
        if (type.emailTemplate?.subject) {
          this.form
            .get(BmTypesBehaviorEditFormFields.PARENT_EMAIL_SUBJECT)
            .setValue(type.emailTemplate?.subject);
        }
        if (type.smsTemplate?.body) {
          this.form
            .get(BmTypesBehaviorEditFormFields.PARENT_SMS_TEMPLATE)
            .setValue(type.smsTemplate?.body);
        }

        this.emailOptions = [];
        adminEmail.value.forEach(email => {
          this.emailOptions.push({ value: email, label: email });
        });

        if (type.restriction) {
          this.form
            .get(BmTypesBehaviorEditFormFields.RESTRICTIONS)
            .patchValue(toRestrictionInput(type.restriction));
        }

        this.form.markAsPristine();
        this.form.markAsUntouched();
      },
      onValidate: () => {
        markNestedFormGroupAsDirty(this.form);
        return this.form.valid;
      },
      onSuccess: (type, data) => {
        if (type === 'delete') {
          this._modalRef.close(ModalOverlayServiceCloseEventType.DELETE, {
            id: data.id,
          });
        }

        if (type === 'create') {
          this._modalRef.close(ModalOverlayServiceCloseEventType.CREATE, {
            id: data.id,
          });
        }

        if (type === 'update') {
          this._modalRef.close(ModalOverlayServiceCloseEventType.SUBMIT, {
            id: data.id,
          });
        }
      },
      onSubmit: data => {
        const name = this.form.get(BmTypesBehaviorEditFormFields.NAME).value;
        const points = this.form.get(
          BmTypesBehaviorEditFormFields.POINTS,
        ).value;
        const sendParentEmail = this.form.get(
          BmTypesBehaviorEditFormFields.PARENT_EMAIL,
        ).value;
        const sendParentSms = this.form.get(
          BmTypesBehaviorEditFormFields.PARENT_SMS,
        ).value;
        const sendAdminEmail = this.form.get(
          BmTypesBehaviorEditFormFields.SEND_ADMIN_EMAIL,
        ).value;
        const adminEmail = this.form.get(
          BmTypesBehaviorEditFormFields.ADMIN_EMAIL,
        ).value;
        const categoryId = this.form.get(
          BmTypesBehaviorEditFormFields.CATEGORY,
        ).value;
        const hallPassTypeId =
          this.form.get(BmTypesBehaviorEditFormFields.HALL_PASS_ID).value ||
          null;

        const subject =
          this.form.get(BmTypesBehaviorEditFormFields.PARENT_EMAIL_SUBJECT)
            .value || null;
        const emailBody =
          this.form.get(BmTypesBehaviorEditFormFields.PARENT_EMAIL_BODY)
            .value || null;
        const smsBody =
          this.form.get(BmTypesBehaviorEditFormFields.PARENT_SMS_TEMPLATE)
            .value || null;

        const iconColor = this.form.get(
          BmTypesBehaviorEditFormFields.ICON_COLOR,
        ).value;
        const iconType = this.form.get(
          BmTypesBehaviorEditFormFields.ICON,
        ).value;

        const restriction =
          this.form.get(BmTypesBehaviorEditFormFields.RESTRICTIONS).value ||
          undefined;

        const type: IPbisType = {
          ...data,
          name,
          points,
          sendParentEmail,
          sendParentSms,
          sendAdminEmail,
          adminEmails: adminEmail,
          categoryId,
          hallPassTypeId,
          emailTemplate: { subject, body: emailBody },
          smsTemplate: { body: smsBody },
          iconColor,
          iconType,
          active: data.active ?? true,
          restriction: {
            id: data.restriction?.id ?? undefined,
            ...fromRestrictionInput(restriction, data.restriction),
          },
        };

        return type;
      },
      onShowLoader: promise => rootService.addLoadingPromise(promise),
      onCancel: async () => {
        if (this.form.pristine) {
          this._modalRef.close(ModalOverlayServiceCloseEventType.CLOSE);
          return;
        }

        const modalRef = await this._systemAlertModal.open({
          modalType: SystemAlertModalType.WARNING,
          heading: BmTypesBehaviorEditMessages.DELETE_CONFIRM_DISCARD_TITLE,
          message: BmTypesBehaviorEditMessages.DELETE_CONFIRM_DISCARD_DESC,
          closeBtn: BmTypesBehaviorEditMessages.DELETE_CONFIRM_CANCEL_BTN,
          confirmActionBtn:
            BmTypesBehaviorEditMessages.DELETE_CONFIRM_DISCARD_BTN,
        });

        const response = await modalRef.afterClosed().toPromise();

        if (response?.type === SystemAlertCloseEvents.CONFIRM) {
          this._modalRef.close(ModalOverlayServiceCloseEventType.CLOSE);
        }
      },
      onDelete: async () => {
        const modalRef = await this._systemAlertModal.open({
          modalType: SystemAlertModalType.WARNING,
          heading: BmTypesBehaviorEditMessages.DELETE_CONFIRM_TITLE,
          closeBtn: BmTypesBehaviorEditMessages.DELETE_CONFIRM_CANCEL_BTN,
          confirmActionBtn:
            BmTypesBehaviorEditMessages.DELETE_CONFIRM_DELETE_BTN,
        });

        const response = await modalRef.afterClosed().toPromise();

        return response?.type === SystemAlertCloseEvents.CONFIRM;
      },
      onFormStateChange: state => {
        if (state === 'invalid') {
          // scroll to top of form
          scrollIntoView(this.formElement.nativeElement, {
            align: { top: 0 },
          });
        }
      },
    });

    this.form.valueChanges
      .pipe(takeUntil(this._destroyed))
      .subscribe(formValue => {
        this.toolbarData = {
          typeName: formValue.NAME,
          points: formValue.POINTS,
        };
      });
  }

  ngOnInit(): void {
    this._bmService.fetchHallPassTypes();
    this.init();
  }

  ngOnDestroy(): void {
    this._destroyed.next();
    this._destroyed.complete();
  }

  public addNew(email: string) {
    return { label: email, value: email };
  }

  public setCategory(category) {
    this.form.get(BmTypesBehaviorEditFormFields.CATEGORY).setValue(category);
    this._setDefaultIcon(category);
  }

  public async onAdminEmailChange(emails) {
    this.form.get(BmTypesBehaviorEditFormFields.ADMIN_EMAIL).setValue(emails);
  }

  private _setDefaultIcon(category: BehaviorMsgCategory) {
    const iconColor = this.form.get(BmTypesBehaviorEditFormFields.ICON_COLOR);
    const iconType = this.form.get(BmTypesBehaviorEditFormFields.ICON);
    const selectedType = ICON_CONFIG_DEFAULTS[category];
    const isNew = this.isNewSubj.getValue();

    if (!selectedType || !isNew) return;

    if (iconColor.untouched) {
      const defaultColor = selectedType.color;
      iconColor.setValue(defaultColor);
    }

    if (iconType.untouched) {
      const defaultIcon = selectedType.icon;
      iconType.setValue(defaultIcon);
    }
  }
}
