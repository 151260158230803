import { Directive, HostBinding, HostListener, Input } from '@angular/core';

import { ContentEvents } from '@app/src/app/minimal/services/ContentEvents';

import {
  IResolvedContentLinkValue,
  resolveContentLinkValue,
} from './contentLinkUtil';

@Directive({
  selector: '[mgContentContextMenuClick]',
  exportAs: 'mgContentContextMenuClick',
})
export class MgContentContextMenuClickDirective {
  private _value = IResolvedContentLinkValue.defaultValue();

  @Input()
  set mgContentContextMenuClick(value: any) {
    this._value = resolveContentLinkValue(value);
  }

  @HostBinding('class.mg-cancel-swipe-stack-tap')
  readonly cancelSwipeStackTap: boolean = true;

  @HostBinding('class.mg-cancel-content-link')
  readonly cancelContentLink: boolean = true;

  constructor(private contentEvents: ContentEvents) {}

  /**
   * Check to see if we have a valid content link. Does not mean content exists
   * it just means opening the content will be attempted.
   */
  isValidContentContextLink(): boolean {
    return !!this._value.context;
  }

  @HostListener('tap', ['$event'])
  _handleContentLinkContextMenu(ev: any) {
    if (!this.isValidContentContextLink()) {
      return;
    }

    ev.srcEvent.preventDefault();
    ev.srcEvent.stopPropagation();
    ev.srcEvent.stopImmediatePropagation();
    const { pageX, pageY, clientX, clientY } = ev.srcEvent as any;

    let x = pageX;
    let y = pageY;
    // if this is an overlay, pageY and pageX
    // will be wrong if we have scrolled.
    if (this._value.overlay) {
      x = clientX;
      y = clientY;
    }

    this.contentEvents.emitContextMenuOpen({
      contentContextHash: this._value.context,
      contentHash: this._value.contentHash || '',
      x: x,
      y: y,
    });
  }
}
