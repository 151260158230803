// GENERATED CODE -- DO NOT EDIT!

import {grpc} from '@improbable-eng/grpc-web';

import * as content_poll_pb from '../content/poll_pb';
import * as content_common_pb from '../content/common_pb';
import * as common_delta_pb from '../common/delta_pb';
import * as common_date_pb from '../common/date_pb';
import * as common_stream_pb from '../common/stream_pb';
import * as gateway_feed_pb from '../gateway/feed_pb';
import * as gateway_content_views_pb from '../gateway/content_views_pb';

export class Poll {
  static readonly serviceName = "minga.content.Poll";

  static readonly New = {
    methodName: "New",
    service: Poll,
    requestStream: false,
    responseStream: false,
    requestType: content_poll_pb.PollInfo,
    responseType: content_common_pb.ContentSaveResult,
  };

  static readonly Update = {
    methodName: "Update",
    service: Poll,
    requestStream: false,
    responseStream: false,
    requestType: content_poll_pb.DeltaPollInfo,
    responseType: content_common_pb.ContentSaveResult,
  };

  static readonly GetInfo = {
    methodName: "GetInfo",
    service: Poll,
    requestStream: false,
    responseStream: false,
    requestType: content_common_pb.ContentInfoQuery,
    responseType: content_poll_pb.PollInfo,
  };

  static readonly StreamPolls = {
    methodName: "StreamPolls",
    service: Poll,
    requestStream: false,
    responseStream: true,
    requestType: common_stream_pb.StreamID,
    responseType: gateway_feed_pb.StreamContentFeedResponse,
  };

  static readonly StreamPollsControl = {
    methodName: "StreamPollsControl",
    service: Poll,
    requestStream: false,
    responseStream: false,
    requestType: common_stream_pb.StreamControl,
    responseType: common_stream_pb.StreamControlResponse,
  };

  static readonly StreamPollsV2 = {
    methodName: "StreamPollsV2",
    service: Poll,
    requestStream: false,
    responseStream: true,
    requestType: common_stream_pb.StreamID,
    responseType: content_poll_pb.StreamPollsV2Item,
  };

  static readonly StreamPollsV2Control = {
    methodName: "StreamPollsV2Control",
    service: Poll,
    requestStream: false,
    responseStream: false,
    requestType: common_stream_pb.StreamControl,
    responseType: common_stream_pb.StreamControlResponse,
  };

}

export class PollClient {
  new() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  update() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  getInfo() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  streamPolls() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  streamPollsControl() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  streamPollsV2() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  streamPollsV2Control() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
}
