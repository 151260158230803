import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { RouterModule } from '@angular/router';

import { PhotoGalleryAddPhotoModule } from '@app/src/app/components/TopOfFeed/PhotoGalleryAddPhoto';
import { SuggestedContentModule } from '@app/src/app/components/TopOfFeed/SuggestedContent';
import { PermissionsModule } from '@app/src/app/permissions';

import { PhotoGalleryPreviewModule } from './PhotoGalleryPreview';
import { TopOfFeedComponent } from './TopOfFeed.component';

@NgModule({
  imports: [
    // Minga dependencies
    PhotoGalleryPreviewModule,
    PermissionsModule,
    PhotoGalleryAddPhotoModule,
    SuggestedContentModule,

    // External dependencies
    CommonModule,
    FlexLayoutModule,
    RouterModule,
  ],
  declarations: [TopOfFeedComponent],
  exports: [TopOfFeedComponent],
})
export class TopOfFeedModule {}
