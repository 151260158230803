<mg-text
  variant="header-md-secondary"
  spacing="4">
  {{ MESSAGES.SUBSCRIPTION_DETAILS_TITLE }}
</mg-text>

<!-- Subscription Details -->
<div
  *ngIf="subInfo$ | async as subInfo; else isLoadingTemplate"
  class="subscription">
  <ng-container *ngFor="let info of subInfo">
    <ng-container
      [ngTemplateOutlet]="subscriptionInfoTemplate"
      [ngTemplateOutletContext]="{ $implicit: info }">
    </ng-container>
  </ng-container>
</div>

<div class="heading-spacing">
  <mg-text
    variant="header-md-secondary"
    spacing="4">
    {{ MESSAGES.MINGA_MODULES_TITLE }}
  </mg-text>
</div>

<!-- Module Cards -->
<div
  fxLayout="row wrap"
  fxLayoutAlign="start center"
  fxLayoutGap="12px">
  <ng-container *ngFor="let info of moduleInfo$ | async">
    <ng-container
      *ngIf="
        mingaSettings.getModuleEnabledObs(info.moduleToggleKey) | async;
        else upgradeCardTemplate
      "
      [ngTemplateOutlet]="moduleCardTemplate"
      [ngTemplateOutletContext]="{ $implicit: info, canUpgrade: false }">
    </ng-container>

    <ng-template #upgradeCardTemplate>
      <ng-container
        [ngTemplateOutlet]="moduleCardTemplate"
        [ngTemplateOutletContext]="{ $implicit: info, canUpgrade: true }">
      </ng-container>
    </ng-template>
  </ng-container>
</div>

<div class="tw-mt-4">
  <mg-text
    variant="header-md-secondary"
    spacing="4">
    {{ MESSAGES.SUPPORT_TITLE }}
  </mg-text>
</div>

<!-- Support Tier -->
<!-- If we add more support tiers, make this a template -->
<div
  *ngIf="minga$ | async as minga"
  class="support-tiers">
  <!-- Core -->
  <mg-card
    *ngIf="minga.mingaSupportTier === MINGA_SUPPORT_TIER.CORE"
    class="module-card-container">
    <div
      class="tw-flex tw-flex-column tw-gap-2"
      [style.width.%]="100">
      <mg-tag
        size="small"
        color="blue">
        {{ MESSAGES.ENABLED_LABEL }}</mg-tag
      >
      <div class="module-card-header">
        <!-- Placeholder -->
        <mg-icon
          class="module-card-icon tw-hidden"
          iconName="mg-hallpass-menu"
          [style.color]="'black'">
        </mg-icon>
        <mg-text
          variant="header-sm-secondary"
          fontWeight="900">
          {{ SUPPORT_TIER_MESSAGES.CORE.title }}
        </mg-text>
      </div>
      <mg-text variant="body-xs-secondary">
        {{ SUPPORT_TIER_MESSAGES.CORE.description }}
      </mg-text>
      <div class="tw-flex tw-flex-column tw-gap-1 tw-items-start">
        <div
          *ngFor="let feature of SUPPORT_TIER_MESSAGES.CORE.features"
          class="module-card-details">
          <mg-icon class="module-card-icon blue">mg-checkmark</mg-icon>
          <mg-text variant="body-xs">
            {{ feature }}
          </mg-text>
        </div>
      </div>
    </div>
  </mg-card>

  <!--Premium-->
  <div id="premium">
    <mg-card
      class="module-card-container"
      [class.upgrade-card]="
        minga.mingaSupportTier !== MINGA_SUPPORT_TIER.PREMIUM
      ">
      <div
        class="tw-flex tw-flex-column tw-gap-2"
        [style.width.%]="100">
        <mg-tag
          size="small"
          [color]="
            minga.mingaSupportTier !== MINGA_SUPPORT_TIER.PREMIUM
              ? 'green'
              : 'blue'
          ">
          {{
            minga.mingaSupportTier !== MINGA_SUPPORT_TIER.PREMIUM
              ? MESSAGES.UPGRADE_TAG
              : MESSAGES.ENABLED_LABEL
          }}</mg-tag
        >
        <div class="module-card-header">
          <mg-icon class="module-card-icon yellow tw-hidden">
            {{ 'mg-hallpass-menu' }}
            <!-- Placeholder -->
          </mg-icon>
          <mg-text
            variant="header-sm-secondary"
            fontWeight="900">
            {{ SUPPORT_TIER_MESSAGES.PREMIUM.title }}
          </mg-text>
        </div>
        <mg-text
          *ngIf="!isPremium"
          variant="body-xs-secondary">
          {{ SUPPORT_TIER_MESSAGES.PREMIUM.description }}
        </mg-text>
        <div class="tw-flex tw-flex-column tw-gap-1 tw-items-start">
          <div
            *ngFor="let feature of SUPPORT_TIER_MESSAGES.PREMIUM.features"
            class="module-card-details">
            <mg-icon class="module-card-icon blue">mg-checkmark</mg-icon>
            <mg-text variant="body-xs">
              {{ feature }}
            </mg-text>
          </div>
        </div>
        <mg-text variant="body-xs-secondary">
          {{ SUPPORT_TIER_MESSAGES.PREMIUM.secondary_heading }}
        </mg-text>
        <div class="tw-flex tw-flex-column tw-gap-1 tw-items-start">
          <div
            *ngFor="
              let feature of SUPPORT_TIER_MESSAGES.PREMIUM.secondary_features
            "
            class="module-card-details">
            <mg-icon class="module-card-icon blue">mg-checkmark</mg-icon>
            <mg-text variant="body-xs">
              {{ feature }}
            </mg-text>
          </div>
        </div>
        <div class="module-card-actions">
          <mg-btn
            *ngIf="minga.mingaSupportTier !== MINGA_SUPPORT_TIER.PREMIUM"
            size="small"
            (pressed)="openUpgradeRequestModal()">
            {{ SUPPORT_TIER_MESSAGES.PREMIUM.upgrade_label }}
          </mg-btn>
        </div>
      </div>
    </mg-card>
    <div
      *ngIf="minga.mingaSupportTier !== MINGA_SUPPORT_TIER.PREMIUM"
      class="background-blur-container">
      <div class="blur1 background-blur-dot"></div>
      <div class="blur2 background-blur-dot"></div>
      <div class="blur3 background-blur-dot"></div>
    </div>
  </div>
</div>

<!-- Subscription Detail Template -->
<ng-template
  #subscriptionInfoTemplate
  let-subscription>
  <div
    fxLayout="column"
    fxLayoutAlign="start start">
    <div class="subscription-container">
      <mg-icon class="subscription-icon">{{ subscription.icon }}</mg-icon>
      <div class="subscription-info">
        <mg-text variant="label-md">
          {{ subscription.title }}
        </mg-text>
        <div fxLayout="row">
          <mg-text
            variant="body-sm-secondary"
            fontWeight="900"
            [color]="
              subscription.type === SUB_INFO_TYPE.SMS_MESSAGES &&
              isSMSCountNearMax(subscription)
                ? 'error'
                : 'surface'
            ">
            {{ subscription.value | convertToPrettyNumber: subscription.type }}
          </mg-text>
          <ng-container *ngIf="subscription.maxValue">
            <mg-text
              variant="body-sm-secondary"
              fontWeight="900">
              &nbsp;/&nbsp;
            </mg-text>
            <mg-text
              class="subscription-max-value"
              variant="body-xs-secondary"
              color="surface-alt">
              {{
                subscription.maxValue | convertToPrettyNumber: subscription.type
              }}
            </mg-text>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<!-- Module Card Template -->
<ng-template
  #moduleCardTemplate
  let-module
  let-canUpgrade="canUpgrade">
  <mg-card
    class="module-card-container"
    [class.upgrade-card]="canUpgrade">
    <div
      class="tw-flex tw-flex-column tw-gap-2"
      [style.width.%]="100">
      <mg-tag
        size="small"
        [color]="canUpgrade ? 'green' : 'blue'">
        {{ canUpgrade ? MESSAGES.UPGRADE_TAG : MESSAGES.ENABLED_LABEL }}</mg-tag
      >
      <div class="module-card-header">
        <mg-icon
          class="module-card-icon"
          [style.color]="'var(' + module.colorVar + ')'">
          {{ module.icon }}
        </mg-icon>
        <mg-text
          variant="header-sm-secondary"
          fontWeight="900">
          {{ module.title }}
        </mg-text>
      </div>
      <mg-text variant="body-xs-secondary">
        {{ module.description }}
      </mg-text>
      <div class="tw-flex tw-flex-column tw-gap-1 tw-items-start">
        <div
          *ngFor="let feature of module.features"
          class="module-card-details">
          <mg-icon class="module-card-icon blue">mg-checkmark</mg-icon>
          <mg-text variant="body-xs">
            {{ feature }}
          </mg-text>
        </div>
      </div>
      <div class="module-card-actions">
        <mg-btn
          *ngIf="canUpgrade"
          variant="text"
          size="small"
          [href]="module.learnMoreLink">
          {{ MESSAGES.LEARN_MORE_LABEL }}
        </mg-btn>
        <mg-btn
          *ngIf="canUpgrade"
          size="small"
          (pressed)="openUpgradeRequestModal()">
          {{ MESSAGES.UPGRADE_LABEL }}
        </mg-btn>
      </div>
    </div>
  </mg-card>
</ng-template>

<ng-template #isLoadingTemplate>
  <mg-spinner></mg-spinner>
</ng-template>
