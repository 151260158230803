// https://github.com/bazelbuild/rules_nodejs/issues/1013
import { createAction } from '@ngrx/store';
import { FtueKey } from 'libs/domain';

// eslint-disable-next-line
export namespace WelcomeActions {
  /**
   * This action gets triggered when the welcome service is to be initialized.
   */
  export const welcomeInit = createAction(
    '[Welcome] init',
    // @ts-ignore
    (key = FtueKey.WELCOME_FTUE) => ({ key }),
  );

  /**
   * This action, if user hasn't selected school in district and is a district
   * enabled Minga, will prompt a user to pick their school.
   */
  export const districtInit = createAction(
    '[Welcome] district',
    // @ts-ignore
    (key = FtueKey.DISTRICT_FTUE) => ({ key }),
  );

  /**
   * This action is prompted to show a user what the latest version of the app
   * has that's new.
   */
  export const whatsNew = createAction(
    '[Welcome] whats new',
    // @ts-ignore
    (key = FtueKey.WHATS_NEW) => ({ key }),
  );

  export type Union =
    | typeof welcomeInit
    | typeof districtInit
    | typeof whatsNew;
}
