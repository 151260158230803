import { Directive, Input, OnDestroy } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormControl } from '@angular/forms';

import { isEmpty } from 'lodash';
import { BehaviorSubject } from 'rxjs';

/**
 * Abstract for shared custom ng-select functionality.
 *
 * @link https://github.com/ng-select/ng-select
 */
@Directive()
export abstract class NgSelectModified implements OnDestroy {
  @Input() floatingLabel = true;
  @Input() multiple: boolean;
  @Input() placeholder: string;
  @Input() value: any;
  @Input() control: UntypedFormControl | AbstractControl = this._fb?.control([]);

  constructor(protected _fb?: UntypedFormBuilder) {}

  private readonly _isOpen$ = new BehaviorSubject<boolean>(false);
  public readonly isOpen$ = this._isOpen$.asObservable();

  get displayPlaceholder() {
    return this._isOpen$.value ? '' : this.placeholder;
  }

  get showLabel() {
    if (!this.floatingLabel) return false;
    if (this._isOpen$.value) return true;
    let valueToConsider;
    let hasValue;
    if (this.multiple) {
      valueToConsider = this.value?.length || this.control?.value?.length;
      hasValue = valueToConsider;
    } else {
      valueToConsider = this.value || this.control?.value;
      if (
        typeof valueToConsider === 'object' ||
        Array.isArray(valueToConsider)
      ) {
        hasValue = !isEmpty(valueToConsider);
      } else hasValue = !!valueToConsider;
    }

    return hasValue && this.placeholder;
  }

  public async setOpenState(val: boolean) {
    this._isOpen$.next(val);
  }

  ngOnDestroy() {
    this._isOpen$.complete();
  }
}
