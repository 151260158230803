// GENERATED CODE -- DO NOT EDIT!

import {grpc} from '@improbable-eng/grpc-web';

import * as gateway_people_pb from '../gateway/people_pb';
import * as common_delta_pb from '../common/delta_pb';
import * as common_date_pb from '../common/date_pb';
import * as common_legacy_pb from '../common/legacy_pb';
import * as common_stream_pb from '../common/stream_pb';
import * as gateway_person_view_pb from '../gateway/person_view_pb';

export class PeopleManager {
  static readonly serviceName = "PeopleManager";

  static readonly createPeople = {
    methodName: "createPeople",
    service: PeopleManager,
    requestStream: false,
    responseStream: false,
    requestType: gateway_people_pb.CreatePeopleRequest,
    responseType: gateway_people_pb.CreatePeopleResponse,
  };

  static readonly readPeople = {
    methodName: "readPeople",
    service: PeopleManager,
    requestStream: false,
    responseStream: false,
    requestType: gateway_people_pb.ReadPeopleRequest,
    responseType: gateway_people_pb.ReadPeopleResponse,
  };

  static readonly updatePeople = {
    methodName: "updatePeople",
    service: PeopleManager,
    requestStream: false,
    responseStream: false,
    requestType: gateway_people_pb.UpdatePeopleRequest,
    responseType: gateway_people_pb.UpdatePeopleResponse,
  };

  static readonly deletePeople = {
    methodName: "deletePeople",
    service: PeopleManager,
    requestStream: false,
    responseStream: false,
    requestType: gateway_people_pb.DeletePeopleRequest,
    responseType: gateway_people_pb.DeletePeopleResponse,
  };

  static readonly archivePeople = {
    methodName: "archivePeople",
    service: PeopleManager,
    requestStream: false,
    responseStream: false,
    requestType: gateway_people_pb.ArchivePeopleRequest,
    responseType: gateway_people_pb.ArchivePeopleResponse,
  };

  static readonly selfArchivePerson = {
    methodName: "selfArchivePerson",
    service: PeopleManager,
    requestStream: false,
    responseStream: false,
    requestType: gateway_people_pb.SelfArchivePersonRequest,
    responseType: gateway_people_pb.SelfArchivePersonResponse,
  };

  static readonly getNextMingaPin = {
    methodName: "getNextMingaPin",
    service: PeopleManager,
    requestStream: false,
    responseStream: false,
    requestType: gateway_people_pb.NextMingaPinRequest,
    responseType: gateway_people_pb.NextMingaPinResponse,
  };

  static readonly openMinga = {
    methodName: "openMinga",
    service: PeopleManager,
    requestStream: false,
    responseStream: false,
    requestType: gateway_people_pb.OpenMingaRequest,
    responseType: gateway_people_pb.OpenMingaResponse,
  };

  static readonly getRoles = {
    methodName: "getRoles",
    service: PeopleManager,
    requestStream: false,
    responseStream: false,
    requestType: gateway_people_pb.GetRolesRequest,
    responseType: gateway_people_pb.GetRolesResponse,
  };

  static readonly getTeams = {
    methodName: "getTeams",
    service: PeopleManager,
    requestStream: false,
    responseStream: false,
    requestType: gateway_people_pb.GetPersonsTeamsRequest,
    responseType: gateway_people_pb.GetPersonsTeamsResponse,
  };

}

export class PeopleAdminManager {
  static readonly serviceName = "PeopleAdminManager";

  static readonly SearchPeople = {
    methodName: "SearchPeople",
    service: PeopleAdminManager,
    requestStream: false,
    responseStream: true,
    requestType: common_stream_pb.StreamID,
    responseType: gateway_people_pb.StreamPeopleResponse,
  };

  static readonly SearchPeopleControl = {
    methodName: "SearchPeopleControl",
    service: PeopleAdminManager,
    requestStream: false,
    responseStream: false,
    requestType: common_stream_pb.StreamControl,
    responseType: common_stream_pb.StreamControlResponse,
  };

  static readonly DeletePeopleBulk = {
    methodName: "DeletePeopleBulk",
    service: PeopleAdminManager,
    requestStream: false,
    responseStream: true,
    requestType: gateway_people_pb.DeletePeopleBulkRequest,
    responseType: gateway_people_pb.DeletePeopleBulkResponse,
  };

  static readonly ArchivePeopleBulk = {
    methodName: "ArchivePeopleBulk",
    service: PeopleAdminManager,
    requestStream: false,
    responseStream: true,
    requestType: gateway_people_pb.ArchivePeopleBulkRequest,
    responseType: gateway_people_pb.ArchivePeopleBulkResponse,
  };

  static readonly SyncPeopleBulk = {
    methodName: "SyncPeopleBulk",
    service: PeopleAdminManager,
    requestStream: false,
    responseStream: true,
    requestType: gateway_people_pb.SyncPeopleBulkRequest,
    responseType: gateway_people_pb.SyncPeopleBulkResponse,
  };

  static readonly ResendPinEmail = {
    methodName: "ResendPinEmail",
    service: PeopleAdminManager,
    requestStream: false,
    responseStream: false,
    requestType: gateway_people_pb.ResendPinEmailRequest,
    responseType: gateway_people_pb.ResendPinEmailResponse,
  };

  static readonly ResendAllInvites = {
    methodName: "ResendAllInvites",
    service: PeopleAdminManager,
    requestStream: false,
    responseStream: false,
    requestType: gateway_people_pb.ResendAllInvitesRequest,
    responseType: gateway_people_pb.ResendAllInvitesResponse,
  };

}

export class PersonValidation {
  static readonly serviceName = "PersonValidation";

  static readonly emailUnique = {
    methodName: "emailUnique",
    service: PersonValidation,
    requestStream: false,
    responseStream: false,
    requestType: gateway_people_pb.EmailUniqueRequest,
    responseType: gateway_people_pb.EmailUniqueResponse,
  };

  static readonly pinUnique = {
    methodName: "pinUnique",
    service: PersonValidation,
    requestStream: false,
    responseStream: false,
    requestType: gateway_people_pb.PinUniqueRequest,
    responseType: gateway_people_pb.PinUniqueResponse,
  };

}

export class MingaPeopleManager {
  static readonly serviceName = "MingaPeopleManager";

  static readonly GetInfo = {
    methodName: "GetInfo",
    service: MingaPeopleManager,
    requestStream: false,
    responseStream: false,
    requestType: gateway_people_pb.MingaPeopleInfoRequest,
    responseType: gateway_people_pb.MingaPeopleInfo,
  };

  static readonly ExportPeople = {
    methodName: "ExportPeople",
    service: MingaPeopleManager,
    requestStream: false,
    responseStream: true,
    requestType: gateway_people_pb.ExportPeopleRequest,
    responseType: gateway_people_pb.ExportPeopleChunk,
  };

  static readonly SearchPeople = {
    methodName: "SearchPeople",
    service: MingaPeopleManager,
    requestStream: false,
    responseStream: true,
    requestType: common_stream_pb.StreamID,
    responseType: gateway_people_pb.MingaPeopleSearchResult,
  };

  static readonly SearchPeopleControl = {
    methodName: "SearchPeopleControl",
    service: MingaPeopleManager,
    requestStream: false,
    responseStream: false,
    requestType: common_stream_pb.StreamControl,
    responseType: common_stream_pb.StreamControlResponse,
  };

  static readonly SuperAdminSearchPeople = {
    methodName: "SuperAdminSearchPeople",
    service: MingaPeopleManager,
    requestStream: false,
    responseStream: true,
    requestType: common_stream_pb.StreamID,
    responseType: gateway_people_pb.MingaPeopleSearchResult,
  };

  static readonly SuperAdminSearchPeopleControl = {
    methodName: "SuperAdminSearchPeopleControl",
    service: MingaPeopleManager,
    requestStream: false,
    responseStream: false,
    requestType: common_stream_pb.StreamControl,
    responseType: common_stream_pb.StreamControlResponse,
  };

}

export class PeopleManagerClient {
  createPeople() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  readPeople() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  updatePeople() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  deletePeople() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  archivePeople() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  selfArchivePerson() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  getNextMingaPin() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  openMinga() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  getRoles() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  getTeams() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
}

export class PeopleAdminManagerClient {
  searchPeople() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  searchPeopleControl() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  deletePeopleBulk() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  archivePeopleBulk() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  syncPeopleBulk() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  resendPinEmail() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  resendAllInvites() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
}

export class PersonValidationClient {
  emailUnique() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  pinUnique() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
}

export class MingaPeopleManagerClient {
  getInfo() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  exportPeople() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  searchPeople() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  searchPeopleControl() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  superAdminSearchPeople() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  superAdminSearchPeopleControl() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
}
