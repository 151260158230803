import { CommonModule } from '@angular/common';
import { Injector, NgModule } from '@angular/core';

import { MgContentLinkModule } from '@app/src/app/content-link';
import { MgAnyMiniCardModule } from '@app/src/app/elements/MgAnyMiniCard';
import { MgAnyShortCardModule } from '@app/src/app/elements/MgAnyShortCard';
import { MgDateSeparatorModule } from '@app/src/app/elements/MgDateSeparator';

import { MgContentFeedItemElement } from './MgContentFeedItem.element';

@NgModule({
  imports: [
    // Minga dependencies
    MgDateSeparatorModule,
    MgAnyMiniCardModule,
    MgAnyShortCardModule,
    MgContentLinkModule,

    // External dependencies
    CommonModule,
  ],
  declarations: [MgContentFeedItemElement],
  exports: [MgContentFeedItemElement],
})
export class MgContentFeedItemModule {
  constructor(injector: Injector) {
    if (!customElements.get('minga-content-feed-item')) {
      //       //   MgContentFeedItemElement,
      //   {injector},
      // ));
    }
  }
}
