import { Injectable, OnDestroy } from '@angular/core';

import { IConsequence, IConsequenceType } from 'libs/domain';
import { IHallPassType } from 'libs/domain';
import { IPbisType } from 'libs/domain';
import { hall_pass_pb } from 'libs/generated-grpc-web';
import { PbisCategory } from 'libs/shared';
import {
  BehaviorSubject,
  combineLatest,
  Observable,
  of,
  ReplaySubject,
} from 'rxjs';
import {
  catchError,
  distinctUntilChanged,
  map,
  switchMap,
  take,
} from 'rxjs/operators';

import { RootService } from '@app/src/app/minimal/services/RootService';
import { OptionItem } from '@app/src/app/shared/components/form/components/form-grouped-select/form-grouped-select.types';
import { UserListCategory } from '@app/src/app/shared/components/user-list-filter';

import { BehaviorIconListType } from '@modules/behavior-manager/components/bm-types/constants';
import { ConversationNavigator } from '@modules/direct-message';
import { MessagingFacade } from '@modules/direct-message/store';
import { combineDateAndTime } from '@modules/selection-assigner/utils';

import { SystemAlertSnackBarService } from '@shared/components/system-alert-snackbar';
import { getAppColor } from '@shared/constants';
import { ConsequenceService } from '@shared/services/consequence';
import { CreateHallpassService } from '@shared/services/hall-pass';
import {
  Actions,
  MostUsedByList,
  MyActionsService,
} from '@shared/services/my-class/my-actions.service';
import { PbisService } from '@shared/services/pbis';

import {
  MOST_USED_COUNT,
  MyClassMessages,
} from '../constants/tt-my-class.constants';
import {
  ActionGroup,
  ActionItem,
  AssignmentType,
  BehaviorFormData,
  CategoryType,
  ConsequenceFormData,
  FormData,
  HallPassFormData,
} from '../types/tt-my-class.types';
import {
  behaviorMapper,
  consequenceMapper,
  countSort,
  hallPassMapper,
  mapActionGroupsToItems,
} from './my-class.utils';

@Injectable()
export class MyClassActionsService implements OnDestroy {
  private _destroyedSubject = new ReplaySubject<void>(1);

  private _pastAssignmentsSubject = new BehaviorSubject<
    Map<string, ActionItem[]>
  >(new Map());
  public pastAssignments$ = this._pastAssignmentsSubject.asObservable();

  private _actionsGroupsLoadingSubject = new BehaviorSubject<boolean>(false);
  public actionsGroupsLoading$ =
    this._actionsGroupsLoadingSubject.asObservable();

  private _mostUsedByListIdSubject = new BehaviorSubject<number>(null);
  private _mostUsedByList$: Observable<MostUsedByList> =
    this._mostUsedByListIdSubject.pipe(
      distinctUntilChanged(),
      switchMap(listId => {
        if (!listId) return of(null);
        return this._myActionsService.fetchMostUsedByList(listId);
      }),
      catchError(error => {
        // no need to alert users, this isn't critical
        return of(null);
      }),
    );

  private _actionGroupsSubject = new BehaviorSubject<ActionGroup[]>([]);
  public actionGroups$: Observable<ActionGroup[]> = combineLatest([
    this._actionGroupsSubject,
    this._mostUsedByList$,
  ]).pipe(
    map(([actionGroups, mostUsedByList]) => {
      const mostUsedLists = this._getMostUsedLists(
        actionGroups,
        mostUsedByList,
      );

      return [...mostUsedLists, ...actionGroups];
    }),
  );

  constructor(
    private _consequenceService: ConsequenceService,
    private _behaviorService: PbisService,
    private _createHpService: CreateHallpassService,
    private _rootService: RootService,
    private _myActionsService: MyActionsService,
    private _systemSnackBar: SystemAlertSnackBarService,
    private _messagingFacade: MessagingFacade,
    private _conversationNavigator: ConversationNavigator,
  ) {}

  ngOnDestroy(): void {
    this._destroyedSubject.next();
    this._destroyedSubject.complete();
  }

  public async fetchMyActions(includeDirectMessage: boolean): Promise<void> {
    try {
      this._actionsGroupsLoadingSubject.next(true);
      const results = await this._myActionsService.fetchAll();

      this._setActionGroups(results, includeDirectMessage);
    } catch (error) {
      this._systemSnackBar.open({
        type: 'error',
        message: MyClassMessages.FETCH_ACTIONS_ERROR,
      });
    } finally {
      this._actionsGroupsLoadingSubject.next(false);
    }
  }

  public saveAssignment(data: FormData): void {
    const personHashes = data.selectedStudents;
    const actionItem = data.selectedAction;
    const existing = this._pastAssignmentsSubject.value;

    personHashes
      .filter(hash => hash)
      .forEach(hash => {
        const assignments = existing.get(hash) || [];

        this._pastAssignmentsSubject.next(
          existing.set(hash, [...assignments, actionItem]),
        );
      });
  }

  public async assign(
    data: FormData,
  ): Promise<IConsequence[] | hall_pass_pb.HallPassWithType.AsObject[] | void> {
    const recipientHashes = data.selectedStudents;
    const type = data.selectedAction.assignmentType;
    const listId = this._getListId(data.listFilter);

    if (type === AssignmentType.HALLPASS) {
      const typeData = data.selectedAction.data as IHallPassType;
      const subGroup = data[AssignmentType.HALLPASS] as HallPassFormData;
      const date = subGroup.date;
      const time = subGroup.time;
      const duration = subGroup.duration;
      const startDate =
        date && time ? combineDateAndTime(date, time).toDate() : null;
      const note = subGroup.note;
      const teacherAssignedHash = subGroup.approvedBy?.hash || null;

      const { passes } = await this._createHpService.createHallPass(
        false,
        typeData.id,
        recipientHashes,
        duration,
        startDate,
        teacherAssignedHash,
        note,
        false,
        listId,
      );

      if (passes.length === 0) {
        const error = new Error('No hall passes created');
        error.name = type;
        throw error;
      }

      return passes;
    }

    if (type === AssignmentType.BEHAVIOR) {
      const typeData = data.selectedAction.data as IPbisType;
      const subGroup = data[AssignmentType.BEHAVIOR] as BehaviorFormData;
      const note = subGroup.note;

      return await this._rootService.addLoadingPromise(
        this._behaviorService.createBehavior({
          typeId: typeData.id,
          message: note,
          recipientIds: recipientHashes,
          listId,
        }),
      );
    }

    if (type === AssignmentType.CONSEQUENCE) {
      const typeData = data.selectedAction.data as IConsequenceType;
      const subGroup = data[AssignmentType.CONSEQUENCE] as ConsequenceFormData;
      const note = subGroup.note;
      const completeBy = subGroup.date;

      return await this._rootService.addLoadingPromise(
        this._consequenceService.createConsequences(
          {
            type: typeData.id,
            completeBy,
            issuedTo: recipientHashes,
            notes: note,
          },
          listId,
        ),
      );
    }

    if (type === AssignmentType.COMMUNICATION) {
      const personHashes = recipientHashes;
      const conv = await this._messagingFacade
        .startConversation(personHashes)
        .pipe(take(1))
        .toPromise();
      await this._conversationNavigator.navigateByConversationId(conv.id);
    }
  }

  public fetchMostUsedByList(listId: number): void {
    this._mostUsedByListIdSubject.next(listId);
  }

  private _setActionGroups(
    types: Actions,
    includeDirectMessage: boolean,
  ): void {
    const consequences = types[AssignmentType.CONSEQUENCE];
    const behaviors = types[AssignmentType.BEHAVIOR];
    const hallPasses = types[AssignmentType.HALLPASS];

    const activeConsequences = consequences.filter(t => t.active);
    const activeBehaviors = behaviors.filter(t => t.active);
    const activeHallPasses = hallPasses.filter(t => t.active);

    const actionGroups: ActionGroup[] = [];

    if (activeHallPasses.length) {
      const hallpasses: ActionGroup = {
        value: CategoryType.HALLPASS,
        label: MyClassMessages.SELECT_CATEGORY_HALLPASS,
        icon: 'mg-hallpass-menu',
        items: activeHallPasses.map(hallPassMapper).sort(countSort),
        id: 'tools-myclass-click-action-hp',
      };

      actionGroups.push(hallpasses);
    }

    if (activeBehaviors.length) {
      const praisesTypes = activeBehaviors.filter(
        b => b.categoryId === PbisCategory.PRAISE,
      );

      if (praisesTypes.length) {
        const praises: ActionGroup = {
          value: CategoryType.PRAISE,
          label: MyClassMessages.SELECT_CATEGORY_PRAISE,
          icon: BehaviorIconListType.BEHAVIOR_PRAISE,
          iconNamespace: 'minga.behaviors',
          items: praisesTypes.map(behaviorMapper).sort(countSort),
          id: 'tools-myclass-click-action-prai',
        };

        actionGroups.push(praises);
      }

      const guidanceTypes = activeBehaviors.filter(
        b => b.categoryId === PbisCategory.GUIDANCE,
      );

      if (guidanceTypes.length) {
        const guidances: ActionGroup = {
          value: CategoryType.GUIDANCE,
          label: MyClassMessages.SELECT_CATEGORY_GUIDANCE,
          icon: BehaviorIconListType.BEHAVIOR_GUIDANCE,
          iconNamespace: 'minga.behaviors',
          items: guidanceTypes.map(behaviorMapper).sort(countSort),
          id: 'tools-myclass-click-action-guid',
        };

        actionGroups.push(guidances);
      }
    }

    if (activeConsequences.length) {
      const consequencesGroup: ActionGroup = {
        value: CategoryType.CONSEQUENCE,
        label: MyClassMessages.SELECT_CATEGORY_CONSEQUENCE,
        icon: 'mg-behavior-menu',
        items: activeConsequences.map(consequenceMapper).sort(countSort),
        id: 'tools-myclass-click-action-csq',
      };

      actionGroups.push(consequencesGroup);
    }

    if (includeDirectMessage) {
      const communicationGroup: ActionGroup = {
        value: CategoryType.COMMUNICATION,
        label: MyClassMessages.SELECT_CATEGORY_COMMUNICATION,
        icon: 'mg-chat',
        items: [
          {
            value: 0,
            label: 'Direct messaging',
            color: getAppColor('primary'),
            icon: 'mg-chat',
            count: 0,
            assignmentType: AssignmentType.COMMUNICATION,
            data: null,
          },
        ],
        id: 'tools-myclass-click-action-dm',
      };

      actionGroups.push(communicationGroup);
    }

    this._actionGroupsSubject.next(actionGroups);
  }

  private _getMostUsedLists(
    actionGroups: ActionGroup[],
    mostUsedByList: MostUsedByList,
  ): ActionGroup[] {
    const assignItemsToList = (category: CategoryType, lists, items) => {
      lists.find(l => l.value === category).items = items
        .sort(countSort)
        .slice(0, MOST_USED_COUNT);
    };

    const lists: ActionGroup[] = [
      {
        value: CategoryType.MOST_USED,
        label: MyClassMessages.SELECT_CATEGORY_MOST_USED,
        icon: 'star',
        iconNamespace: 'minga.behaviors',
        items: [],
        id: 'tools-myclass-click-action-most',
        hideFromSearch: true,
      },
      {
        value: CategoryType.MOST_USED_BY_LIST,
        label: MyClassMessages.SELECT_CATEGORY_MOST_USED_BY_LIST,
        icon: 'mg-per-list',
        items: [],
        id: 'tools-myclass-click-action-most-by-list',
        hideFromSearch: true,
      },
    ];

    const actions = mapActionGroupsToItems(actionGroups);

    assignItemsToList(CategoryType.MOST_USED, lists, actions);

    if (mostUsedByList) {
      const actionsForMostUsedByList = actions
        .filter(a => mostUsedByList[a.assignmentType]?.[a.value])
        .map(a => {
          const count = mostUsedByList[a.assignmentType][a.value];
          return {
            ...a,
            count,
            data: {
              ...a.data,
              count,
            },
          };
        });

      assignItemsToList(
        CategoryType.MOST_USED_BY_LIST,
        lists,
        actionsForMostUsedByList,
      );
    }

    return lists;
  }

  /**
   * We use this list id to attach it to the assignment to determined most used actions per list
   * But now that the list filter can contain flex activities we want to make
   * sure we filter those out when saving since they don't have a list id
   */
  private _getListId(listFilter: OptionItem[]): number {
    const VALID_LIST_TYPES = [
      UserListCategory.ALL,
      UserListCategory.ALL_CURRENT_TERM,
      UserListCategory.MY_LISTS,
      UserListCategory.MY_LISTS_CURRENT_TERM,
    ];
    return listFilter?.length &&
      VALID_LIST_TYPES.includes(listFilter[0].category.value)
      ? listFilter[0].value
      : null;
  }
}
