// source: hall_pass/hall_pass.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

import * as jspb from 'google-protobuf';
import * as google_protobuf_wrappers_pb from '../google/protobuf/wrappers_pb';
import * as image_image_pb from '../image/image_pb';
import * as common_date_pb from '../common/date_pb';
import * as common_stream_pb from '../common/stream_pb';
import * as gateway_person_view_pb from '../gateway/person_view_pb';
import * as restriction_restriction_pb from '../restriction/restriction_pb';
import * as membership_list_membership_list_pb from '../membership_list/membership_list_pb';
import * as student_schedule_student_schedule_pb from '../student_schedule/student_schedule_pb';
export namespace HallPassType {
  export interface AsObject {
    id: number;
    name: string;
    asset?: image_image_pb.ImageInfo.AsObject;
    bannerHash: string;
    bannerReference: string;
    color: string;
    createdBy: number;
    mingaId: number;
    active: boolean;
    selfIssue: boolean;
    priority: number;
    defaultHallPassTime: number;
    membershipListId: number;
    maxSimultaneousPasses: number;
    ignoreLimits: boolean;
    ignoreBlackout: boolean;
    membershipList?: membership_list_membership_list_pb.MembershipList.AsObject;
    requireTeacherApproval: boolean;
    restriction?: restriction_restriction_pb.Restriction.AsObject;
    dailyPassLimit: number;
    manuallyEndPass?: google_protobuf_wrappers_pb.BoolValue.AsObject;
    studentCanEndPass?: google_protobuf_wrappers_pb.BoolValue.AsObject;
    canEndOnMobile?: google_protobuf_wrappers_pb.BoolValue.AsObject;
    availableInKiosk: boolean;
  }
}
export class HallPassType extends jspb.Message {
  static readonly displayName = "HallPassType";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): HallPassType.AsObject {
    return HallPassType.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!HallPassType} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: HallPassType) {
    var f: any;
    var obj: any = {
      id: jspb.Message.getFieldWithDefault(msg, 1, 0),
      name: jspb.Message.getFieldWithDefault(msg, 2, ""),
      asset: (f = msg.getAsset()) && image_image_pb.ImageInfo.toObject(includeInstance, f),
      bannerHash: jspb.Message.getFieldWithDefault(msg, 5, ""),
      bannerReference: jspb.Message.getFieldWithDefault(msg, 6, ""),
      color: jspb.Message.getFieldWithDefault(msg, 7, ""),
      createdBy: jspb.Message.getFieldWithDefault(msg, 8, 0),
      mingaId: jspb.Message.getFieldWithDefault(msg, 9, 0),
      active: jspb.Message.getBooleanFieldWithDefault(msg, 10, false),
      selfIssue: jspb.Message.getBooleanFieldWithDefault(msg, 11, false),
      priority: jspb.Message.getFieldWithDefault(msg, 12, 0),
      defaultHallPassTime: jspb.Message.getFieldWithDefault(msg, 13, 0),
      membershipListId: jspb.Message.getFieldWithDefault(msg, 14, 0),
      maxSimultaneousPasses: jspb.Message.getFieldWithDefault(msg, 15, 0),
      ignoreLimits: jspb.Message.getBooleanFieldWithDefault(msg, 16, false),
      ignoreBlackout: jspb.Message.getBooleanFieldWithDefault(msg, 17, false),
      membershipList: (f = msg.getMembershipList()) && membership_list_membership_list_pb.MembershipList.toObject(includeInstance, f),
      requireTeacherApproval: jspb.Message.getBooleanFieldWithDefault(msg, 19, false),
      restriction: (f = msg.getRestriction()) && restriction_restriction_pb.Restriction.toObject(includeInstance, f),
      dailyPassLimit: jspb.Message.getFieldWithDefault(msg, 21, 0),
      manuallyEndPass: (f = msg.getManuallyEndPass()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f),
      studentCanEndPass: (f = msg.getStudentCanEndPass()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f),
      canEndOnMobile: (f = msg.getCanEndOnMobile()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f),
      availableInKiosk: jspb.Message.getBooleanFieldWithDefault(msg, 25, false)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!HallPassType}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new HallPassType;
    return HallPassType.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!HallPassType} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!HallPassType}
   */
  static deserializeBinaryFromReader(msg: HallPassType, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {number} */ (reader.readInt32());
        msg.setId(value1);
        break;
      case 2:
        var value2 = /** @type {string} */ (reader.readString());
        msg.setName(value2);
        break;
      case 4:
        var value4 = new image_image_pb.ImageInfo;
        reader.readMessage(value4,image_image_pb.ImageInfo.deserializeBinaryFromReader);
        msg.setAsset(value4);
        break;
      case 5:
        var value5 = /** @type {string} */ (reader.readString());
        msg.setBannerHash(value5);
        break;
      case 6:
        var value6 = /** @type {string} */ (reader.readString());
        msg.setBannerReference(value6);
        break;
      case 7:
        var value7 = /** @type {string} */ (reader.readString());
        msg.setColor(value7);
        break;
      case 8:
        var value8 = /** @type {number} */ (reader.readInt32());
        msg.setCreatedBy(value8);
        break;
      case 9:
        var value9 = /** @type {number} */ (reader.readInt32());
        msg.setMingaId(value9);
        break;
      case 10:
        var value10 = /** @type {boolean} */ (reader.readBool());
        msg.setActive(value10);
        break;
      case 11:
        var value11 = /** @type {boolean} */ (reader.readBool());
        msg.setSelfIssue(value11);
        break;
      case 12:
        var value12 = /** @type {number} */ (reader.readInt32());
        msg.setPriority(value12);
        break;
      case 13:
        var value13 = /** @type {number} */ (reader.readInt32());
        msg.setDefaultHallPassTime(value13);
        break;
      case 14:
        var value14 = /** @type {number} */ (reader.readInt32());
        msg.setMembershipListId(value14);
        break;
      case 15:
        var value15 = /** @type {number} */ (reader.readInt32());
        msg.setMaxSimultaneousPasses(value15);
        break;
      case 16:
        var value16 = /** @type {boolean} */ (reader.readBool());
        msg.setIgnoreLimits(value16);
        break;
      case 17:
        var value17 = /** @type {boolean} */ (reader.readBool());
        msg.setIgnoreBlackout(value17);
        break;
      case 18:
        var value18 = new membership_list_membership_list_pb.MembershipList;
        reader.readMessage(value18,membership_list_membership_list_pb.MembershipList.deserializeBinaryFromReader);
        msg.setMembershipList(value18);
        break;
      case 19:
        var value19 = /** @type {boolean} */ (reader.readBool());
        msg.setRequireTeacherApproval(value19);
        break;
      case 20:
        var value20 = new restriction_restriction_pb.Restriction;
        reader.readMessage(value20,restriction_restriction_pb.Restriction.deserializeBinaryFromReader);
        msg.setRestriction(value20);
        break;
      case 21:
        var value21 = /** @type {number} */ (reader.readInt32());
        msg.setDailyPassLimit(value21);
        break;
      case 22:
        var value22 = new google_protobuf_wrappers_pb.BoolValue;
        reader.readMessage(value22,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
        msg.setManuallyEndPass(value22);
        break;
      case 23:
        var value23 = new google_protobuf_wrappers_pb.BoolValue;
        reader.readMessage(value23,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
        msg.setStudentCanEndPass(value23);
        break;
      case 24:
        var value24 = new google_protobuf_wrappers_pb.BoolValue;
        reader.readMessage(value24,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
        msg.setCanEndOnMobile(value24);
        break;
      case 25:
        var value25 = /** @type {boolean} */ (reader.readBool());
        msg.setAvailableInKiosk(value25);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!HallPassType} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: HallPassType, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getId();
    if (f !== 0) {
      writer.writeInt32(
        1,
        f
      );
    }
    f = message.getName();
    if (f.length > 0) {
      writer.writeString(
        2,
        f
      );
    }
    f = message.getAsset();
    if (f != null) {
      writer.writeMessage(
        4,
        f,
        image_image_pb.ImageInfo.serializeBinaryToWriter
      );
    }
    f = message.getBannerHash();
    if (f.length > 0) {
      writer.writeString(
        5,
        f
      );
    }
    f = message.getBannerReference();
    if (f.length > 0) {
      writer.writeString(
        6,
        f
      );
    }
    f = message.getColor();
    if (f.length > 0) {
      writer.writeString(
        7,
        f
      );
    }
    f = message.getCreatedBy();
    if (f !== 0) {
      writer.writeInt32(
        8,
        f
      );
    }
    f = message.getMingaId();
    if (f !== 0) {
      writer.writeInt32(
        9,
        f
      );
    }
    f = message.getActive();
    if (f) {
      writer.writeBool(
        10,
        f
      );
    }
    f = message.getSelfIssue();
    if (f) {
      writer.writeBool(
        11,
        f
      );
    }
    f = message.getPriority();
    if (f !== 0) {
      writer.writeInt32(
        12,
        f
      );
    }
    f = message.getDefaultHallPassTime();
    if (f !== 0) {
      writer.writeInt32(
        13,
        f
      );
    }
    f = message.getMembershipListId();
    if (f !== 0) {
      writer.writeInt32(
        14,
        f
      );
    }
    f = message.getMaxSimultaneousPasses();
    if (f !== 0) {
      writer.writeInt32(
        15,
        f
      );
    }
    f = message.getIgnoreLimits();
    if (f) {
      writer.writeBool(
        16,
        f
      );
    }
    f = message.getIgnoreBlackout();
    if (f) {
      writer.writeBool(
        17,
        f
      );
    }
    f = message.getMembershipList();
    if (f != null) {
      writer.writeMessage(
        18,
        f,
        membership_list_membership_list_pb.MembershipList.serializeBinaryToWriter
      );
    }
    f = message.getRequireTeacherApproval();
    if (f) {
      writer.writeBool(
        19,
        f
      );
    }
    f = message.getRestriction();
    if (f != null) {
      writer.writeMessage(
        20,
        f,
        restriction_restriction_pb.Restriction.serializeBinaryToWriter
      );
    }
    f = message.getDailyPassLimit();
    if (f !== 0) {
      writer.writeInt32(
        21,
        f
      );
    }
    f = message.getManuallyEndPass();
    if (f != null) {
      writer.writeMessage(
        22,
        f,
        google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
      );
    }
    f = message.getStudentCanEndPass();
    if (f != null) {
      writer.writeMessage(
        23,
        f,
        google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
      );
    }
    f = message.getCanEndOnMobile();
    if (f != null) {
      writer.writeMessage(
        24,
        f,
        google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
      );
    }
    f = message.getAvailableInKiosk();
    if (f) {
      writer.writeBool(
        25,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    HallPassType.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional int32 id = 1;
   * @return {number}
   */
  getId(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
  };


  /** @param {number} value */
  setId(value: number) {
    jspb.Message.setProto3IntField(this, 1, value);
  }


  /**
   * optional string name = 2;
   * @return {string}
   */
  getName(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
  };


  /** @param {string} value */
  setName(value: string) {
    jspb.Message.setProto3StringField(this, 2, value);
  }


  /**
   * optional ImageInfo asset = 4;
   * @return {?ImageInfo}
   */
  getAsset(): image_image_pb.ImageInfo {
    return /** @type{?ImageInfo} */ (
      jspb.Message.getWrapperField(this, image_image_pb.ImageInfo, 4));
  }


  /** @param {?ImageInfo|undefined} value */
  setAsset(value?: image_image_pb.ImageInfo) {
    jspb.Message.setWrapperField(this, 4, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearAsset() {
    this.setAsset(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasAsset(): boolean {
    return jspb.Message.getField(this, 4) != null;
  }


  /**
   * optional string banner_hash = 5;
   * @return {string}
   */
  getBannerHash(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
  };


  /** @param {string} value */
  setBannerHash(value: string) {
    jspb.Message.setProto3StringField(this, 5, value);
  }


  /**
   * optional string banner_reference = 6;
   * @return {string}
   */
  getBannerReference(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
  };


  /** @param {string} value */
  setBannerReference(value: string) {
    jspb.Message.setProto3StringField(this, 6, value);
  }


  /**
   * optional string color = 7;
   * @return {string}
   */
  getColor(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
  };


  /** @param {string} value */
  setColor(value: string) {
    jspb.Message.setProto3StringField(this, 7, value);
  }


  /**
   * optional int32 created_by = 8;
   * @return {number}
   */
  getCreatedBy(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
  };


  /** @param {number} value */
  setCreatedBy(value: number) {
    jspb.Message.setProto3IntField(this, 8, value);
  }


  /**
   * optional int32 minga_id = 9;
   * @return {number}
   */
  getMingaId(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
  };


  /** @param {number} value */
  setMingaId(value: number) {
    jspb.Message.setProto3IntField(this, 9, value);
  }


  /**
   * optional bool active = 10;
   * @return {boolean}
   */
  getActive(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
  };


  /** @param {boolean} value */
  setActive(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 10, value);
  }


  /**
   * optional bool self_issue = 11;
   * @return {boolean}
   */
  getSelfIssue(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
  };


  /** @param {boolean} value */
  setSelfIssue(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 11, value);
  }


  /**
   * optional int32 priority = 12;
   * @return {number}
   */
  getPriority(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
  };


  /** @param {number} value */
  setPriority(value: number) {
    jspb.Message.setProto3IntField(this, 12, value);
  }


  /**
   * optional int32 default_hall_pass_time = 13;
   * @return {number}
   */
  getDefaultHallPassTime(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
  };


  /** @param {number} value */
  setDefaultHallPassTime(value: number) {
    jspb.Message.setProto3IntField(this, 13, value);
  }


  /**
   * optional int32 membership_list_id = 14;
   * @return {number}
   */
  getMembershipListId(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
  };


  /** @param {number} value */
  setMembershipListId(value: number) {
    jspb.Message.setProto3IntField(this, 14, value);
  }


  /**
   * optional int32 max_simultaneous_passes = 15;
   * @return {number}
   */
  getMaxSimultaneousPasses(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
  };


  /** @param {number} value */
  setMaxSimultaneousPasses(value: number) {
    jspb.Message.setProto3IntField(this, 15, value);
  }


  /**
   * optional bool ignore_limits = 16;
   * @return {boolean}
   */
  getIgnoreLimits(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 16, false));
  };


  /** @param {boolean} value */
  setIgnoreLimits(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 16, value);
  }


  /**
   * optional bool ignore_blackout = 17;
   * @return {boolean}
   */
  getIgnoreBlackout(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 17, false));
  };


  /** @param {boolean} value */
  setIgnoreBlackout(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 17, value);
  }


  /**
   * optional membership_list.MembershipList membership_list = 18;
   * @return {?MembershipList}
   */
  getMembershipList(): membership_list_membership_list_pb.MembershipList {
    return /** @type{?MembershipList} */ (
      jspb.Message.getWrapperField(this, membership_list_membership_list_pb.MembershipList, 18));
  }


  /** @param {?MembershipList|undefined} value */
  setMembershipList(value?: membership_list_membership_list_pb.MembershipList) {
    jspb.Message.setWrapperField(this, 18, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearMembershipList() {
    this.setMembershipList(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasMembershipList(): boolean {
    return jspb.Message.getField(this, 18) != null;
  }


  /**
   * optional bool require_teacher_approval = 19;
   * @return {boolean}
   */
  getRequireTeacherApproval(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 19, false));
  };


  /** @param {boolean} value */
  setRequireTeacherApproval(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 19, value);
  }


  /**
   * optional restriction.Restriction restriction = 20;
   * @return {?Restriction}
   */
  getRestriction(): restriction_restriction_pb.Restriction {
    return /** @type{?Restriction} */ (
      jspb.Message.getWrapperField(this, restriction_restriction_pb.Restriction, 20));
  }


  /** @param {?Restriction|undefined} value */
  setRestriction(value?: restriction_restriction_pb.Restriction) {
    jspb.Message.setWrapperField(this, 20, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearRestriction() {
    this.setRestriction(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasRestriction(): boolean {
    return jspb.Message.getField(this, 20) != null;
  }


  /**
   * optional int32 daily_pass_limit = 21;
   * @return {number}
   */
  getDailyPassLimit(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 21, 0));
  };


  /** @param {number} value */
  setDailyPassLimit(value: number) {
    jspb.Message.setProto3IntField(this, 21, value);
  }


  /**
   * optional google.protobuf.BoolValue manually_end_pass = 22;
   * @return {?BoolValue}
   */
  getManuallyEndPass(): google_protobuf_wrappers_pb.BoolValue {
    return /** @type{?BoolValue} */ (
      jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 22));
  }


  /** @param {?BoolValue|undefined} value */
  setManuallyEndPass(value?: google_protobuf_wrappers_pb.BoolValue) {
    jspb.Message.setWrapperField(this, 22, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearManuallyEndPass() {
    this.setManuallyEndPass(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasManuallyEndPass(): boolean {
    return jspb.Message.getField(this, 22) != null;
  }


  /**
   * optional google.protobuf.BoolValue student_can_end_pass = 23;
   * @return {?BoolValue}
   */
  getStudentCanEndPass(): google_protobuf_wrappers_pb.BoolValue {
    return /** @type{?BoolValue} */ (
      jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 23));
  }


  /** @param {?BoolValue|undefined} value */
  setStudentCanEndPass(value?: google_protobuf_wrappers_pb.BoolValue) {
    jspb.Message.setWrapperField(this, 23, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearStudentCanEndPass() {
    this.setStudentCanEndPass(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasStudentCanEndPass(): boolean {
    return jspb.Message.getField(this, 23) != null;
  }


  /**
   * optional google.protobuf.BoolValue can_end_on_mobile = 24;
   * @return {?BoolValue}
   */
  getCanEndOnMobile(): google_protobuf_wrappers_pb.BoolValue {
    return /** @type{?BoolValue} */ (
      jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 24));
  }


  /** @param {?BoolValue|undefined} value */
  setCanEndOnMobile(value?: google_protobuf_wrappers_pb.BoolValue) {
    jspb.Message.setWrapperField(this, 24, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearCanEndOnMobile() {
    this.setCanEndOnMobile(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasCanEndOnMobile(): boolean {
    return jspb.Message.getField(this, 24) != null;
  }


  /**
   * optional bool available_in_kiosk = 25;
   * @return {boolean}
   */
  getAvailableInKiosk(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 25, false));
  };


  /** @param {boolean} value */
  setAvailableInKiosk(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 25, value);
  }


}
export namespace HallPass {
  export interface AsObject {
    id: number;
    typeId: number;
    recipientPersonView?: gateway_person_view_pb.PersonViewMinimal.AsObject;
    authorPersonView?: gateway_person_view_pb.PersonViewMinimal.AsObject;
    startDateTime?: common_date_pb.DateTime.AsObject;
    expiredDateTime?: common_date_pb.DateTime.AsObject;
    endedByPersonView?: gateway_person_view_pb.PersonViewMinimal.AsObject;
    typeInfo?: HallPassType.AsObject;
    note: string;
    endedTime?: common_date_pb.DateTime.AsObject;
    studentCreated: boolean;
    startDateString: string;
    endDateString: string;
    approvalDate?: common_date_pb.DateTime.AsObject;
    approvedByPersonView?: gateway_person_view_pb.PersonViewMinimal.AsObject;
    isKiosk: boolean;
    noteToRequester: string;
    requestedByPersonView?: gateway_person_view_pb.PersonViewMinimal.AsObject;
    deniedByReason: string;
    deniedByPerson: string;
    deniedByError: number;
    deniedTime?: common_date_pb.DateTime.AsObject;
    status: string;
    createdBy: string;
    currentClass?: student_schedule_student_schedule_pb.StudentSection.AsObject;
    currentClassesList: student_schedule_student_schedule_pb.StudentSection.AsObject[];
  }
}
export class HallPass extends jspb.Message {
  static readonly displayName = "HallPass";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [26];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, HallPass.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): HallPass.AsObject {
    return HallPass.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!HallPass} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: HallPass) {
    var f: any;
    var obj: any = {
      id: jspb.Message.getFieldWithDefault(msg, 1, 0),
      typeId: jspb.Message.getFieldWithDefault(msg, 2, 0),
      recipientPersonView: (f = msg.getRecipientPersonView()) && gateway_person_view_pb.PersonViewMinimal.toObject(includeInstance, f),
      authorPersonView: (f = msg.getAuthorPersonView()) && gateway_person_view_pb.PersonViewMinimal.toObject(includeInstance, f),
      startDateTime: (f = msg.getStartDateTime()) && common_date_pb.DateTime.toObject(includeInstance, f),
      expiredDateTime: (f = msg.getExpiredDateTime()) && common_date_pb.DateTime.toObject(includeInstance, f),
      endedByPersonView: (f = msg.getEndedByPersonView()) && gateway_person_view_pb.PersonViewMinimal.toObject(includeInstance, f),
      typeInfo: (f = msg.getTypeInfo()) && HallPassType.toObject(includeInstance, f),
      note: jspb.Message.getFieldWithDefault(msg, 9, ""),
      endedTime: (f = msg.getEndedTime()) && common_date_pb.DateTime.toObject(includeInstance, f),
      studentCreated: jspb.Message.getBooleanFieldWithDefault(msg, 11, false),
      startDateString: jspb.Message.getFieldWithDefault(msg, 12, ""),
      endDateString: jspb.Message.getFieldWithDefault(msg, 13, ""),
      approvalDate: (f = msg.getApprovalDate()) && common_date_pb.DateTime.toObject(includeInstance, f),
      approvedByPersonView: (f = msg.getApprovedByPersonView()) && gateway_person_view_pb.PersonViewMinimal.toObject(includeInstance, f),
      isKiosk: jspb.Message.getBooleanFieldWithDefault(msg, 16, false),
      noteToRequester: jspb.Message.getFieldWithDefault(msg, 17, ""),
      requestedByPersonView: (f = msg.getRequestedByPersonView()) && gateway_person_view_pb.PersonViewMinimal.toObject(includeInstance, f),
      deniedByReason: jspb.Message.getFieldWithDefault(msg, 19, ""),
      deniedByPerson: jspb.Message.getFieldWithDefault(msg, 20, ""),
      deniedByError: jspb.Message.getFieldWithDefault(msg, 21, 0),
      deniedTime: (f = msg.getDeniedTime()) && common_date_pb.DateTime.toObject(includeInstance, f),
      status: jspb.Message.getFieldWithDefault(msg, 23, ""),
      createdBy: jspb.Message.getFieldWithDefault(msg, 24, ""),
      currentClass: (f = msg.getCurrentClass()) && student_schedule_student_schedule_pb.StudentSection.toObject(includeInstance, f),
      currentClassesList: jspb.Message.toObjectList(msg.getCurrentClassesList(),
      student_schedule_student_schedule_pb.StudentSection.toObject, includeInstance)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!HallPass}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new HallPass;
    return HallPass.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!HallPass} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!HallPass}
   */
  static deserializeBinaryFromReader(msg: HallPass, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {number} */ (reader.readInt32());
        msg.setId(value1);
        break;
      case 2:
        var value2 = /** @type {number} */ (reader.readInt32());
        msg.setTypeId(value2);
        break;
      case 3:
        var value3 = new gateway_person_view_pb.PersonViewMinimal;
        reader.readMessage(value3,gateway_person_view_pb.PersonViewMinimal.deserializeBinaryFromReader);
        msg.setRecipientPersonView(value3);
        break;
      case 4:
        var value4 = new gateway_person_view_pb.PersonViewMinimal;
        reader.readMessage(value4,gateway_person_view_pb.PersonViewMinimal.deserializeBinaryFromReader);
        msg.setAuthorPersonView(value4);
        break;
      case 5:
        var value5 = new common_date_pb.DateTime;
        reader.readMessage(value5,common_date_pb.DateTime.deserializeBinaryFromReader);
        msg.setStartDateTime(value5);
        break;
      case 6:
        var value6 = new common_date_pb.DateTime;
        reader.readMessage(value6,common_date_pb.DateTime.deserializeBinaryFromReader);
        msg.setExpiredDateTime(value6);
        break;
      case 7:
        var value7 = new gateway_person_view_pb.PersonViewMinimal;
        reader.readMessage(value7,gateway_person_view_pb.PersonViewMinimal.deserializeBinaryFromReader);
        msg.setEndedByPersonView(value7);
        break;
      case 8:
        var value8 = new HallPassType;
        reader.readMessage(value8,HallPassType.deserializeBinaryFromReader);
        msg.setTypeInfo(value8);
        break;
      case 9:
        var value9 = /** @type {string} */ (reader.readString());
        msg.setNote(value9);
        break;
      case 10:
        var value10 = new common_date_pb.DateTime;
        reader.readMessage(value10,common_date_pb.DateTime.deserializeBinaryFromReader);
        msg.setEndedTime(value10);
        break;
      case 11:
        var value11 = /** @type {boolean} */ (reader.readBool());
        msg.setStudentCreated(value11);
        break;
      case 12:
        var value12 = /** @type {string} */ (reader.readString());
        msg.setStartDateString(value12);
        break;
      case 13:
        var value13 = /** @type {string} */ (reader.readString());
        msg.setEndDateString(value13);
        break;
      case 14:
        var value14 = new common_date_pb.DateTime;
        reader.readMessage(value14,common_date_pb.DateTime.deserializeBinaryFromReader);
        msg.setApprovalDate(value14);
        break;
      case 15:
        var value15 = new gateway_person_view_pb.PersonViewMinimal;
        reader.readMessage(value15,gateway_person_view_pb.PersonViewMinimal.deserializeBinaryFromReader);
        msg.setApprovedByPersonView(value15);
        break;
      case 16:
        var value16 = /** @type {boolean} */ (reader.readBool());
        msg.setIsKiosk(value16);
        break;
      case 17:
        var value17 = /** @type {string} */ (reader.readString());
        msg.setNoteToRequester(value17);
        break;
      case 18:
        var value18 = new gateway_person_view_pb.PersonViewMinimal;
        reader.readMessage(value18,gateway_person_view_pb.PersonViewMinimal.deserializeBinaryFromReader);
        msg.setRequestedByPersonView(value18);
        break;
      case 19:
        var value19 = /** @type {string} */ (reader.readString());
        msg.setDeniedByReason(value19);
        break;
      case 20:
        var value20 = /** @type {string} */ (reader.readString());
        msg.setDeniedByPerson(value20);
        break;
      case 21:
        var value21 = /** @type {number} */ (reader.readInt32());
        msg.setDeniedByError(value21);
        break;
      case 22:
        var value22 = new common_date_pb.DateTime;
        reader.readMessage(value22,common_date_pb.DateTime.deserializeBinaryFromReader);
        msg.setDeniedTime(value22);
        break;
      case 23:
        var value23 = /** @type {string} */ (reader.readString());
        msg.setStatus(value23);
        break;
      case 24:
        var value24 = /** @type {string} */ (reader.readString());
        msg.setCreatedBy(value24);
        break;
      case 25:
        var value25 = new student_schedule_student_schedule_pb.StudentSection;
        reader.readMessage(value25,student_schedule_student_schedule_pb.StudentSection.deserializeBinaryFromReader);
        msg.setCurrentClass(value25);
        break;
      case 26:
        var value26 = new student_schedule_student_schedule_pb.StudentSection;
        reader.readMessage(value26,student_schedule_student_schedule_pb.StudentSection.deserializeBinaryFromReader);
        msg.addCurrentClasses(value26);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!HallPass} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: HallPass, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getId();
    if (f !== 0) {
      writer.writeInt32(
        1,
        f
      );
    }
    f = message.getTypeId();
    if (f !== 0) {
      writer.writeInt32(
        2,
        f
      );
    }
    f = message.getRecipientPersonView();
    if (f != null) {
      writer.writeMessage(
        3,
        f,
        gateway_person_view_pb.PersonViewMinimal.serializeBinaryToWriter
      );
    }
    f = message.getAuthorPersonView();
    if (f != null) {
      writer.writeMessage(
        4,
        f,
        gateway_person_view_pb.PersonViewMinimal.serializeBinaryToWriter
      );
    }
    f = message.getStartDateTime();
    if (f != null) {
      writer.writeMessage(
        5,
        f,
        common_date_pb.DateTime.serializeBinaryToWriter
      );
    }
    f = message.getExpiredDateTime();
    if (f != null) {
      writer.writeMessage(
        6,
        f,
        common_date_pb.DateTime.serializeBinaryToWriter
      );
    }
    f = message.getEndedByPersonView();
    if (f != null) {
      writer.writeMessage(
        7,
        f,
        gateway_person_view_pb.PersonViewMinimal.serializeBinaryToWriter
      );
    }
    f = message.getTypeInfo();
    if (f != null) {
      writer.writeMessage(
        8,
        f,
        HallPassType.serializeBinaryToWriter
      );
    }
    f = message.getNote();
    if (f.length > 0) {
      writer.writeString(
        9,
        f
      );
    }
    f = message.getEndedTime();
    if (f != null) {
      writer.writeMessage(
        10,
        f,
        common_date_pb.DateTime.serializeBinaryToWriter
      );
    }
    f = message.getStudentCreated();
    if (f) {
      writer.writeBool(
        11,
        f
      );
    }
    f = message.getStartDateString();
    if (f.length > 0) {
      writer.writeString(
        12,
        f
      );
    }
    f = message.getEndDateString();
    if (f.length > 0) {
      writer.writeString(
        13,
        f
      );
    }
    f = message.getApprovalDate();
    if (f != null) {
      writer.writeMessage(
        14,
        f,
        common_date_pb.DateTime.serializeBinaryToWriter
      );
    }
    f = message.getApprovedByPersonView();
    if (f != null) {
      writer.writeMessage(
        15,
        f,
        gateway_person_view_pb.PersonViewMinimal.serializeBinaryToWriter
      );
    }
    f = message.getIsKiosk();
    if (f) {
      writer.writeBool(
        16,
        f
      );
    }
    f = message.getNoteToRequester();
    if (f.length > 0) {
      writer.writeString(
        17,
        f
      );
    }
    f = message.getRequestedByPersonView();
    if (f != null) {
      writer.writeMessage(
        18,
        f,
        gateway_person_view_pb.PersonViewMinimal.serializeBinaryToWriter
      );
    }
    f = message.getDeniedByReason();
    if (f.length > 0) {
      writer.writeString(
        19,
        f
      );
    }
    f = message.getDeniedByPerson();
    if (f.length > 0) {
      writer.writeString(
        20,
        f
      );
    }
    f = message.getDeniedByError();
    if (f !== 0) {
      writer.writeInt32(
        21,
        f
      );
    }
    f = message.getDeniedTime();
    if (f != null) {
      writer.writeMessage(
        22,
        f,
        common_date_pb.DateTime.serializeBinaryToWriter
      );
    }
    f = message.getStatus();
    if (f.length > 0) {
      writer.writeString(
        23,
        f
      );
    }
    f = message.getCreatedBy();
    if (f.length > 0) {
      writer.writeString(
        24,
        f
      );
    }
    f = message.getCurrentClass();
    if (f != null) {
      writer.writeMessage(
        25,
        f,
        student_schedule_student_schedule_pb.StudentSection.serializeBinaryToWriter
      );
    }
    f = message.getCurrentClassesList();
    if (f.length > 0) {
      writer.writeRepeatedMessage(
        26,
        f,
        student_schedule_student_schedule_pb.StudentSection.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    HallPass.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional int32 id = 1;
   * @return {number}
   */
  getId(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
  };


  /** @param {number} value */
  setId(value: number) {
    jspb.Message.setProto3IntField(this, 1, value);
  }


  /**
   * optional int32 type_id = 2;
   * @return {number}
   */
  getTypeId(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
  };


  /** @param {number} value */
  setTypeId(value: number) {
    jspb.Message.setProto3IntField(this, 2, value);
  }


  /**
   * optional PersonViewMinimal recipient_person_view = 3;
   * @return {?PersonViewMinimal}
   */
  getRecipientPersonView(): gateway_person_view_pb.PersonViewMinimal {
    return /** @type{?PersonViewMinimal} */ (
      jspb.Message.getWrapperField(this, gateway_person_view_pb.PersonViewMinimal, 3));
  }


  /** @param {?PersonViewMinimal|undefined} value */
  setRecipientPersonView(value?: gateway_person_view_pb.PersonViewMinimal) {
    jspb.Message.setWrapperField(this, 3, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearRecipientPersonView() {
    this.setRecipientPersonView(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasRecipientPersonView(): boolean {
    return jspb.Message.getField(this, 3) != null;
  }


  /**
   * optional PersonViewMinimal author_person_view = 4;
   * @return {?PersonViewMinimal}
   */
  getAuthorPersonView(): gateway_person_view_pb.PersonViewMinimal {
    return /** @type{?PersonViewMinimal} */ (
      jspb.Message.getWrapperField(this, gateway_person_view_pb.PersonViewMinimal, 4));
  }


  /** @param {?PersonViewMinimal|undefined} value */
  setAuthorPersonView(value?: gateway_person_view_pb.PersonViewMinimal) {
    jspb.Message.setWrapperField(this, 4, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearAuthorPersonView() {
    this.setAuthorPersonView(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasAuthorPersonView(): boolean {
    return jspb.Message.getField(this, 4) != null;
  }


  /**
   * optional minga.common.DateTime start_date_time = 5;
   * @return {?DateTime}
   */
  getStartDateTime(): common_date_pb.DateTime {
    return /** @type{?DateTime} */ (
      jspb.Message.getWrapperField(this, common_date_pb.DateTime, 5));
  }


  /** @param {?DateTime|undefined} value */
  setStartDateTime(value?: common_date_pb.DateTime) {
    jspb.Message.setWrapperField(this, 5, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearStartDateTime() {
    this.setStartDateTime(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasStartDateTime(): boolean {
    return jspb.Message.getField(this, 5) != null;
  }


  /**
   * optional minga.common.DateTime expired_date_time = 6;
   * @return {?DateTime}
   */
  getExpiredDateTime(): common_date_pb.DateTime {
    return /** @type{?DateTime} */ (
      jspb.Message.getWrapperField(this, common_date_pb.DateTime, 6));
  }


  /** @param {?DateTime|undefined} value */
  setExpiredDateTime(value?: common_date_pb.DateTime) {
    jspb.Message.setWrapperField(this, 6, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearExpiredDateTime() {
    this.setExpiredDateTime(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasExpiredDateTime(): boolean {
    return jspb.Message.getField(this, 6) != null;
  }


  /**
   * optional PersonViewMinimal ended_by_person_view = 7;
   * @return {?PersonViewMinimal}
   */
  getEndedByPersonView(): gateway_person_view_pb.PersonViewMinimal {
    return /** @type{?PersonViewMinimal} */ (
      jspb.Message.getWrapperField(this, gateway_person_view_pb.PersonViewMinimal, 7));
  }


  /** @param {?PersonViewMinimal|undefined} value */
  setEndedByPersonView(value?: gateway_person_view_pb.PersonViewMinimal) {
    jspb.Message.setWrapperField(this, 7, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearEndedByPersonView() {
    this.setEndedByPersonView(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasEndedByPersonView(): boolean {
    return jspb.Message.getField(this, 7) != null;
  }


  /**
   * optional HallPassType type_info = 8;
   * @return {?HallPassType}
   */
  getTypeInfo(): HallPassType {
    return /** @type{?HallPassType} */ (
      jspb.Message.getWrapperField(this, HallPassType, 8));
  }


  /** @param {?HallPassType|undefined} value */
  setTypeInfo(value?: HallPassType) {
    jspb.Message.setWrapperField(this, 8, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearTypeInfo() {
    this.setTypeInfo(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasTypeInfo(): boolean {
    return jspb.Message.getField(this, 8) != null;
  }


  /**
   * optional string note = 9;
   * @return {string}
   */
  getNote(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
  };


  /** @param {string} value */
  setNote(value: string) {
    jspb.Message.setProto3StringField(this, 9, value);
  }


  /**
   * optional minga.common.DateTime ended_time = 10;
   * @return {?DateTime}
   */
  getEndedTime(): common_date_pb.DateTime {
    return /** @type{?DateTime} */ (
      jspb.Message.getWrapperField(this, common_date_pb.DateTime, 10));
  }


  /** @param {?DateTime|undefined} value */
  setEndedTime(value?: common_date_pb.DateTime) {
    jspb.Message.setWrapperField(this, 10, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearEndedTime() {
    this.setEndedTime(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasEndedTime(): boolean {
    return jspb.Message.getField(this, 10) != null;
  }


  /**
   * optional bool student_created = 11;
   * @return {boolean}
   */
  getStudentCreated(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
  };


  /** @param {boolean} value */
  setStudentCreated(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 11, value);
  }


  /**
   * optional string start_date_string = 12;
   * @return {string}
   */
  getStartDateString(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
  };


  /** @param {string} value */
  setStartDateString(value: string) {
    jspb.Message.setProto3StringField(this, 12, value);
  }


  /**
   * optional string end_date_string = 13;
   * @return {string}
   */
  getEndDateString(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
  };


  /** @param {string} value */
  setEndDateString(value: string) {
    jspb.Message.setProto3StringField(this, 13, value);
  }


  /**
   * optional minga.common.DateTime approval_date = 14;
   * @return {?DateTime}
   */
  getApprovalDate(): common_date_pb.DateTime {
    return /** @type{?DateTime} */ (
      jspb.Message.getWrapperField(this, common_date_pb.DateTime, 14));
  }


  /** @param {?DateTime|undefined} value */
  setApprovalDate(value?: common_date_pb.DateTime) {
    jspb.Message.setWrapperField(this, 14, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearApprovalDate() {
    this.setApprovalDate(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasApprovalDate(): boolean {
    return jspb.Message.getField(this, 14) != null;
  }


  /**
   * optional PersonViewMinimal approved_by_person_view = 15;
   * @return {?PersonViewMinimal}
   */
  getApprovedByPersonView(): gateway_person_view_pb.PersonViewMinimal {
    return /** @type{?PersonViewMinimal} */ (
      jspb.Message.getWrapperField(this, gateway_person_view_pb.PersonViewMinimal, 15));
  }


  /** @param {?PersonViewMinimal|undefined} value */
  setApprovedByPersonView(value?: gateway_person_view_pb.PersonViewMinimal) {
    jspb.Message.setWrapperField(this, 15, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearApprovedByPersonView() {
    this.setApprovedByPersonView(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasApprovedByPersonView(): boolean {
    return jspb.Message.getField(this, 15) != null;
  }


  /**
   * optional bool is_kiosk = 16;
   * @return {boolean}
   */
  getIsKiosk(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 16, false));
  };


  /** @param {boolean} value */
  setIsKiosk(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 16, value);
  }


  /**
   * optional string note_to_requester = 17;
   * @return {string}
   */
  getNoteToRequester(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
  };


  /** @param {string} value */
  setNoteToRequester(value: string) {
    jspb.Message.setProto3StringField(this, 17, value);
  }


  /**
   * optional PersonViewMinimal requested_by_person_view = 18;
   * @return {?PersonViewMinimal}
   */
  getRequestedByPersonView(): gateway_person_view_pb.PersonViewMinimal {
    return /** @type{?PersonViewMinimal} */ (
      jspb.Message.getWrapperField(this, gateway_person_view_pb.PersonViewMinimal, 18));
  }


  /** @param {?PersonViewMinimal|undefined} value */
  setRequestedByPersonView(value?: gateway_person_view_pb.PersonViewMinimal) {
    jspb.Message.setWrapperField(this, 18, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearRequestedByPersonView() {
    this.setRequestedByPersonView(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasRequestedByPersonView(): boolean {
    return jspb.Message.getField(this, 18) != null;
  }


  /**
   * optional string denied_by_reason = 19;
   * @return {string}
   */
  getDeniedByReason(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
  };


  /** @param {string} value */
  setDeniedByReason(value: string) {
    jspb.Message.setProto3StringField(this, 19, value);
  }


  /**
   * optional string denied_by_person = 20;
   * @return {string}
   */
  getDeniedByPerson(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
  };


  /** @param {string} value */
  setDeniedByPerson(value: string) {
    jspb.Message.setProto3StringField(this, 20, value);
  }


  /**
   * optional int32 denied_by_error = 21;
   * @return {number}
   */
  getDeniedByError(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 21, 0));
  };


  /** @param {number} value */
  setDeniedByError(value: number) {
    jspb.Message.setProto3IntField(this, 21, value);
  }


  /**
   * optional minga.common.DateTime denied_time = 22;
   * @return {?DateTime}
   */
  getDeniedTime(): common_date_pb.DateTime {
    return /** @type{?DateTime} */ (
      jspb.Message.getWrapperField(this, common_date_pb.DateTime, 22));
  }


  /** @param {?DateTime|undefined} value */
  setDeniedTime(value?: common_date_pb.DateTime) {
    jspb.Message.setWrapperField(this, 22, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearDeniedTime() {
    this.setDeniedTime(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasDeniedTime(): boolean {
    return jspb.Message.getField(this, 22) != null;
  }


  /**
   * optional string status = 23;
   * @return {string}
   */
  getStatus(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 23, ""));
  };


  /** @param {string} value */
  setStatus(value: string) {
    jspb.Message.setProto3StringField(this, 23, value);
  }


  /**
   * optional string created_by = 24;
   * @return {string}
   */
  getCreatedBy(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 24, ""));
  };


  /** @param {string} value */
  setCreatedBy(value: string) {
    jspb.Message.setProto3StringField(this, 24, value);
  }


  /**
   * optional minga.student_schedule.StudentSection current_class = 25;
   * @return {?StudentSection}
   */
  getCurrentClass(): student_schedule_student_schedule_pb.StudentSection {
    return /** @type{?StudentSection} */ (
      jspb.Message.getWrapperField(this, student_schedule_student_schedule_pb.StudentSection, 25));
  }


  /** @param {?StudentSection|undefined} value */
  setCurrentClass(value?: student_schedule_student_schedule_pb.StudentSection) {
    jspb.Message.setWrapperField(this, 25, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearCurrentClass() {
    this.setCurrentClass(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasCurrentClass(): boolean {
    return jspb.Message.getField(this, 25) != null;
  }


  /**
   * repeated minga.student_schedule.StudentSection current_classes = 26;
   * @return {!Array<!StudentSection>}
   */
  getCurrentClassesList(): student_schedule_student_schedule_pb.StudentSection[] {
    return /** @type{!Array<!StudentSection>} */ (
      jspb.Message.getRepeatedWrapperField(this, student_schedule_student_schedule_pb.StudentSection, 26));
  }


  /** @param {!Array<!StudentSection>} value */
  setCurrentClassesList(value?: student_schedule_student_schedule_pb.StudentSection[]) {
    jspb.Message.setRepeatedWrapperField(this, 26, value);
  }


  /**
   * @param {!StudentSection=} opt_value
   * @param {number=} opt_index
   * @return {!StudentSection}
   */
  addCurrentClasses(opt_value?: student_schedule_student_schedule_pb.StudentSection, opt_index?: number): student_schedule_student_schedule_pb.StudentSection {
    return jspb.Message.addToRepeatedWrapperField(this, 26, opt_value, student_schedule_student_schedule_pb.StudentSection, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearCurrentClassesList() {
    this.setCurrentClassesList([]);
  }


}
export namespace EditableHallPass {
  export interface AsObject {
    hallPass?: HallPass.AsObject;
    manuallyUpdatedAt?: common_date_pb.DateTime.AsObject;
  }
}
export class EditableHallPass extends jspb.Message {
  static readonly displayName = "EditableHallPass";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): EditableHallPass.AsObject {
    return EditableHallPass.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!EditableHallPass} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: EditableHallPass) {
    var f: any;
    var obj: any = {
      hallPass: (f = msg.getHallPass()) && HallPass.toObject(includeInstance, f),
      manuallyUpdatedAt: (f = msg.getManuallyUpdatedAt()) && common_date_pb.DateTime.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!EditableHallPass}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new EditableHallPass;
    return EditableHallPass.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!EditableHallPass} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!EditableHallPass}
   */
  static deserializeBinaryFromReader(msg: EditableHallPass, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new HallPass;
        reader.readMessage(value1,HallPass.deserializeBinaryFromReader);
        msg.setHallPass(value1);
        break;
      case 2:
        var value2 = new common_date_pb.DateTime;
        reader.readMessage(value2,common_date_pb.DateTime.deserializeBinaryFromReader);
        msg.setManuallyUpdatedAt(value2);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!EditableHallPass} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: EditableHallPass, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getHallPass();
    if (f != null) {
      writer.writeMessage(
        1,
        f,
        HallPass.serializeBinaryToWriter
      );
    }
    f = message.getManuallyUpdatedAt();
    if (f != null) {
      writer.writeMessage(
        2,
        f,
        common_date_pb.DateTime.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    EditableHallPass.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional HallPass hall_pass = 1;
   * @return {?HallPass}
   */
  getHallPass(): HallPass {
    return /** @type{?HallPass} */ (
      jspb.Message.getWrapperField(this, HallPass, 1));
  }


  /** @param {?HallPass|undefined} value */
  setHallPass(value?: HallPass) {
    jspb.Message.setWrapperField(this, 1, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearHallPass() {
    this.setHallPass(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasHallPass(): boolean {
    return jspb.Message.getField(this, 1) != null;
  }


  /**
   * optional minga.common.DateTime manually_updated_at = 2;
   * @return {?DateTime}
   */
  getManuallyUpdatedAt(): common_date_pb.DateTime {
    return /** @type{?DateTime} */ (
      jspb.Message.getWrapperField(this, common_date_pb.DateTime, 2));
  }


  /** @param {?DateTime|undefined} value */
  setManuallyUpdatedAt(value?: common_date_pb.DateTime) {
    jspb.Message.setWrapperField(this, 2, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearManuallyUpdatedAt() {
    this.setManuallyUpdatedAt(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasManuallyUpdatedAt(): boolean {
    return jspb.Message.getField(this, 2) != null;
  }


}
export namespace HallPassWithType {
  export interface AsObject {
    hallPass?: HallPass.AsObject;
    hallPassType?: HallPassType.AsObject;
    recipientStudentId: string;
    recipientStudentIdFilename: string;
  }
}
export class HallPassWithType extends jspb.Message {
  static readonly displayName = "HallPassWithType";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): HallPassWithType.AsObject {
    return HallPassWithType.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!HallPassWithType} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: HallPassWithType) {
    var f: any;
    var obj: any = {
      hallPass: (f = msg.getHallPass()) && HallPass.toObject(includeInstance, f),
      hallPassType: (f = msg.getHallPassType()) && HallPassType.toObject(includeInstance, f),
      recipientStudentId: jspb.Message.getFieldWithDefault(msg, 3, ""),
      recipientStudentIdFilename: jspb.Message.getFieldWithDefault(msg, 4, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!HallPassWithType}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new HallPassWithType;
    return HallPassWithType.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!HallPassWithType} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!HallPassWithType}
   */
  static deserializeBinaryFromReader(msg: HallPassWithType, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new HallPass;
        reader.readMessage(value1,HallPass.deserializeBinaryFromReader);
        msg.setHallPass(value1);
        break;
      case 2:
        var value2 = new HallPassType;
        reader.readMessage(value2,HallPassType.deserializeBinaryFromReader);
        msg.setHallPassType(value2);
        break;
      case 3:
        var value3 = /** @type {string} */ (reader.readString());
        msg.setRecipientStudentId(value3);
        break;
      case 4:
        var value4 = /** @type {string} */ (reader.readString());
        msg.setRecipientStudentIdFilename(value4);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!HallPassWithType} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: HallPassWithType, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getHallPass();
    if (f != null) {
      writer.writeMessage(
        1,
        f,
        HallPass.serializeBinaryToWriter
      );
    }
    f = message.getHallPassType();
    if (f != null) {
      writer.writeMessage(
        2,
        f,
        HallPassType.serializeBinaryToWriter
      );
    }
    f = message.getRecipientStudentId();
    if (f.length > 0) {
      writer.writeString(
        3,
        f
      );
    }
    f = message.getRecipientStudentIdFilename();
    if (f.length > 0) {
      writer.writeString(
        4,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    HallPassWithType.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional HallPass hall_pass = 1;
   * @return {?HallPass}
   */
  getHallPass(): HallPass {
    return /** @type{?HallPass} */ (
      jspb.Message.getWrapperField(this, HallPass, 1));
  }


  /** @param {?HallPass|undefined} value */
  setHallPass(value?: HallPass) {
    jspb.Message.setWrapperField(this, 1, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearHallPass() {
    this.setHallPass(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasHallPass(): boolean {
    return jspb.Message.getField(this, 1) != null;
  }


  /**
   * optional HallPassType hall_pass_type = 2;
   * @return {?HallPassType}
   */
  getHallPassType(): HallPassType {
    return /** @type{?HallPassType} */ (
      jspb.Message.getWrapperField(this, HallPassType, 2));
  }


  /** @param {?HallPassType|undefined} value */
  setHallPassType(value?: HallPassType) {
    jspb.Message.setWrapperField(this, 2, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearHallPassType() {
    this.setHallPassType(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasHallPassType(): boolean {
    return jspb.Message.getField(this, 2) != null;
  }


  /**
   * optional string recipient_student_id = 3;
   * @return {string}
   */
  getRecipientStudentId(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
  };


  /** @param {string} value */
  setRecipientStudentId(value: string) {
    jspb.Message.setProto3StringField(this, 3, value);
  }


  /**
   * optional string recipient_student_id_filename = 4;
   * @return {string}
   */
  getRecipientStudentIdFilename(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
  };


  /** @param {string} value */
  setRecipientStudentIdFilename(value: string) {
    jspb.Message.setProto3StringField(this, 4, value);
  }


}
export namespace HallPassBlackOut {
  export interface AsObject {
    id: number;
    weekday: string;
    startTime: string;
    endTime: string;
    mingaId: number;
    createdBy: number;
    name: string;
    active: boolean;
    blockMonday: boolean;
    blockTuesday: boolean;
    blockWednesday: boolean;
    blockThursday: boolean;
    blockFriday: boolean;
    blockSaturday: boolean;
    blockSunday: boolean;
    hallPassBlackOutScheduleList: HallPassBlackOutSchedule.AsObject[];
  }
}
export class HallPassBlackOut extends jspb.Message {
  static readonly displayName = "HallPassBlackOut";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [16];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, HallPassBlackOut.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): HallPassBlackOut.AsObject {
    return HallPassBlackOut.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!HallPassBlackOut} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: HallPassBlackOut) {
    var f: any;
    var obj: any = {
      id: jspb.Message.getFieldWithDefault(msg, 1, 0),
      weekday: jspb.Message.getFieldWithDefault(msg, 2, ""),
      startTime: jspb.Message.getFieldWithDefault(msg, 3, ""),
      endTime: jspb.Message.getFieldWithDefault(msg, 4, ""),
      mingaId: jspb.Message.getFieldWithDefault(msg, 5, 0),
      createdBy: jspb.Message.getFieldWithDefault(msg, 6, 0),
      name: jspb.Message.getFieldWithDefault(msg, 7, ""),
      active: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
      blockMonday: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
      blockTuesday: jspb.Message.getBooleanFieldWithDefault(msg, 10, false),
      blockWednesday: jspb.Message.getBooleanFieldWithDefault(msg, 11, false),
      blockThursday: jspb.Message.getBooleanFieldWithDefault(msg, 12, false),
      blockFriday: jspb.Message.getBooleanFieldWithDefault(msg, 13, false),
      blockSaturday: jspb.Message.getBooleanFieldWithDefault(msg, 14, false),
      blockSunday: jspb.Message.getBooleanFieldWithDefault(msg, 15, false),
      hallPassBlackOutScheduleList: jspb.Message.toObjectList(msg.getHallPassBlackOutScheduleList(),
      HallPassBlackOutSchedule.toObject, includeInstance)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!HallPassBlackOut}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new HallPassBlackOut;
    return HallPassBlackOut.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!HallPassBlackOut} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!HallPassBlackOut}
   */
  static deserializeBinaryFromReader(msg: HallPassBlackOut, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {number} */ (reader.readInt32());
        msg.setId(value1);
        break;
      case 2:
        var value2 = /** @type {string} */ (reader.readString());
        msg.setWeekday(value2);
        break;
      case 3:
        var value3 = /** @type {string} */ (reader.readString());
        msg.setStartTime(value3);
        break;
      case 4:
        var value4 = /** @type {string} */ (reader.readString());
        msg.setEndTime(value4);
        break;
      case 5:
        var value5 = /** @type {number} */ (reader.readInt32());
        msg.setMingaId(value5);
        break;
      case 6:
        var value6 = /** @type {number} */ (reader.readInt32());
        msg.setCreatedBy(value6);
        break;
      case 7:
        var value7 = /** @type {string} */ (reader.readString());
        msg.setName(value7);
        break;
      case 8:
        var value8 = /** @type {boolean} */ (reader.readBool());
        msg.setActive(value8);
        break;
      case 9:
        var value9 = /** @type {boolean} */ (reader.readBool());
        msg.setBlockMonday(value9);
        break;
      case 10:
        var value10 = /** @type {boolean} */ (reader.readBool());
        msg.setBlockTuesday(value10);
        break;
      case 11:
        var value11 = /** @type {boolean} */ (reader.readBool());
        msg.setBlockWednesday(value11);
        break;
      case 12:
        var value12 = /** @type {boolean} */ (reader.readBool());
        msg.setBlockThursday(value12);
        break;
      case 13:
        var value13 = /** @type {boolean} */ (reader.readBool());
        msg.setBlockFriday(value13);
        break;
      case 14:
        var value14 = /** @type {boolean} */ (reader.readBool());
        msg.setBlockSaturday(value14);
        break;
      case 15:
        var value15 = /** @type {boolean} */ (reader.readBool());
        msg.setBlockSunday(value15);
        break;
      case 16:
        var value16 = new HallPassBlackOutSchedule;
        reader.readMessage(value16,HallPassBlackOutSchedule.deserializeBinaryFromReader);
        msg.addHallPassBlackOutSchedule(value16);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!HallPassBlackOut} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: HallPassBlackOut, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getId();
    if (f !== 0) {
      writer.writeInt32(
        1,
        f
      );
    }
    f = message.getWeekday();
    if (f.length > 0) {
      writer.writeString(
        2,
        f
      );
    }
    f = message.getStartTime();
    if (f.length > 0) {
      writer.writeString(
        3,
        f
      );
    }
    f = message.getEndTime();
    if (f.length > 0) {
      writer.writeString(
        4,
        f
      );
    }
    f = message.getMingaId();
    if (f !== 0) {
      writer.writeInt32(
        5,
        f
      );
    }
    f = message.getCreatedBy();
    if (f !== 0) {
      writer.writeInt32(
        6,
        f
      );
    }
    f = message.getName();
    if (f.length > 0) {
      writer.writeString(
        7,
        f
      );
    }
    f = message.getActive();
    if (f) {
      writer.writeBool(
        8,
        f
      );
    }
    f = message.getBlockMonday();
    if (f) {
      writer.writeBool(
        9,
        f
      );
    }
    f = message.getBlockTuesday();
    if (f) {
      writer.writeBool(
        10,
        f
      );
    }
    f = message.getBlockWednesday();
    if (f) {
      writer.writeBool(
        11,
        f
      );
    }
    f = message.getBlockThursday();
    if (f) {
      writer.writeBool(
        12,
        f
      );
    }
    f = message.getBlockFriday();
    if (f) {
      writer.writeBool(
        13,
        f
      );
    }
    f = message.getBlockSaturday();
    if (f) {
      writer.writeBool(
        14,
        f
      );
    }
    f = message.getBlockSunday();
    if (f) {
      writer.writeBool(
        15,
        f
      );
    }
    f = message.getHallPassBlackOutScheduleList();
    if (f.length > 0) {
      writer.writeRepeatedMessage(
        16,
        f,
        HallPassBlackOutSchedule.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    HallPassBlackOut.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional int32 id = 1;
   * @return {number}
   */
  getId(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
  };


  /** @param {number} value */
  setId(value: number) {
    jspb.Message.setProto3IntField(this, 1, value);
  }


  /**
   * optional string weekday = 2;
   * @return {string}
   */
  getWeekday(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
  };


  /** @param {string} value */
  setWeekday(value: string) {
    jspb.Message.setProto3StringField(this, 2, value);
  }


  /**
   * optional string start_time = 3;
   * @return {string}
   */
  getStartTime(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
  };


  /** @param {string} value */
  setStartTime(value: string) {
    jspb.Message.setProto3StringField(this, 3, value);
  }


  /**
   * optional string end_time = 4;
   * @return {string}
   */
  getEndTime(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
  };


  /** @param {string} value */
  setEndTime(value: string) {
    jspb.Message.setProto3StringField(this, 4, value);
  }


  /**
   * optional int32 minga_id = 5;
   * @return {number}
   */
  getMingaId(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
  };


  /** @param {number} value */
  setMingaId(value: number) {
    jspb.Message.setProto3IntField(this, 5, value);
  }


  /**
   * optional int32 created_by = 6;
   * @return {number}
   */
  getCreatedBy(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
  };


  /** @param {number} value */
  setCreatedBy(value: number) {
    jspb.Message.setProto3IntField(this, 6, value);
  }


  /**
   * optional string name = 7;
   * @return {string}
   */
  getName(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
  };


  /** @param {string} value */
  setName(value: string) {
    jspb.Message.setProto3StringField(this, 7, value);
  }


  /**
   * optional bool active = 8;
   * @return {boolean}
   */
  getActive(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
  };


  /** @param {boolean} value */
  setActive(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 8, value);
  }


  /**
   * optional bool block_monday = 9;
   * @return {boolean}
   */
  getBlockMonday(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
  };


  /** @param {boolean} value */
  setBlockMonday(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 9, value);
  }


  /**
   * optional bool block_tuesday = 10;
   * @return {boolean}
   */
  getBlockTuesday(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
  };


  /** @param {boolean} value */
  setBlockTuesday(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 10, value);
  }


  /**
   * optional bool block_wednesday = 11;
   * @return {boolean}
   */
  getBlockWednesday(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
  };


  /** @param {boolean} value */
  setBlockWednesday(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 11, value);
  }


  /**
   * optional bool block_thursday = 12;
   * @return {boolean}
   */
  getBlockThursday(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 12, false));
  };


  /** @param {boolean} value */
  setBlockThursday(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 12, value);
  }


  /**
   * optional bool block_friday = 13;
   * @return {boolean}
   */
  getBlockFriday(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 13, false));
  };


  /** @param {boolean} value */
  setBlockFriday(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 13, value);
  }


  /**
   * optional bool block_saturday = 14;
   * @return {boolean}
   */
  getBlockSaturday(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 14, false));
  };


  /** @param {boolean} value */
  setBlockSaturday(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 14, value);
  }


  /**
   * optional bool block_sunday = 15;
   * @return {boolean}
   */
  getBlockSunday(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 15, false));
  };


  /** @param {boolean} value */
  setBlockSunday(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 15, value);
  }


  /**
   * repeated HallPassBlackOutSchedule hall_pass_black_out_schedule = 16;
   * @return {!Array<!HallPassBlackOutSchedule>}
   */
  getHallPassBlackOutScheduleList(): HallPassBlackOutSchedule[] {
    return /** @type{!Array<!HallPassBlackOutSchedule>} */ (
      jspb.Message.getRepeatedWrapperField(this, HallPassBlackOutSchedule, 16));
  }


  /** @param {!Array<!HallPassBlackOutSchedule>} value */
  setHallPassBlackOutScheduleList(value?: HallPassBlackOutSchedule[]) {
    jspb.Message.setRepeatedWrapperField(this, 16, value);
  }


  /**
   * @param {!HallPassBlackOutSchedule=} opt_value
   * @param {number=} opt_index
   * @return {!HallPassBlackOutSchedule}
   */
  addHallPassBlackOutSchedule(opt_value?: HallPassBlackOutSchedule, opt_index?: number): HallPassBlackOutSchedule {
    return jspb.Message.addToRepeatedWrapperField(this, 16, opt_value, HallPassBlackOutSchedule, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearHallPassBlackOutScheduleList() {
    this.setHallPassBlackOutScheduleList([]);
  }


}
export namespace HallPassBlackOutSchedule {
  export interface AsObject {
    startTime: string;
    endTime: string;
  }
}
export class HallPassBlackOutSchedule extends jspb.Message {
  static readonly displayName = "HallPassBlackOutSchedule";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): HallPassBlackOutSchedule.AsObject {
    return HallPassBlackOutSchedule.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!HallPassBlackOutSchedule} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: HallPassBlackOutSchedule) {
    var f: any;
    var obj: any = {
      startTime: jspb.Message.getFieldWithDefault(msg, 1, ""),
      endTime: jspb.Message.getFieldWithDefault(msg, 2, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!HallPassBlackOutSchedule}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new HallPassBlackOutSchedule;
    return HallPassBlackOutSchedule.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!HallPassBlackOutSchedule} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!HallPassBlackOutSchedule}
   */
  static deserializeBinaryFromReader(msg: HallPassBlackOutSchedule, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setStartTime(value1);
        break;
      case 2:
        var value2 = /** @type {string} */ (reader.readString());
        msg.setEndTime(value2);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!HallPassBlackOutSchedule} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: HallPassBlackOutSchedule, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getStartTime();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
    f = message.getEndTime();
    if (f.length > 0) {
      writer.writeString(
        2,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    HallPassBlackOutSchedule.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string start_time = 1;
   * @return {string}
   */
  getStartTime(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setStartTime(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


  /**
   * optional string end_time = 2;
   * @return {string}
   */
  getEndTime(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
  };


  /** @param {string} value */
  setEndTime(value: string) {
    jspb.Message.setProto3StringField(this, 2, value);
  }


}
export namespace UpdateHallPassTypeRequest {
  export interface AsObject {
    hallPassType?: HallPassType.AsObject;
  }
}
export class UpdateHallPassTypeRequest extends jspb.Message {
  static readonly displayName = "UpdateHallPassTypeRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): UpdateHallPassTypeRequest.AsObject {
    return UpdateHallPassTypeRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!UpdateHallPassTypeRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: UpdateHallPassTypeRequest) {
    var f: any;
    var obj: any = {
      hallPassType: (f = msg.getHallPassType()) && HallPassType.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!UpdateHallPassTypeRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new UpdateHallPassTypeRequest;
    return UpdateHallPassTypeRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!UpdateHallPassTypeRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!UpdateHallPassTypeRequest}
   */
  static deserializeBinaryFromReader(msg: UpdateHallPassTypeRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new HallPassType;
        reader.readMessage(value1,HallPassType.deserializeBinaryFromReader);
        msg.setHallPassType(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!UpdateHallPassTypeRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: UpdateHallPassTypeRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getHallPassType();
    if (f != null) {
      writer.writeMessage(
        1,
        f,
        HallPassType.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    UpdateHallPassTypeRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional HallPassType hall_pass_type = 1;
   * @return {?HallPassType}
   */
  getHallPassType(): HallPassType {
    return /** @type{?HallPassType} */ (
      jspb.Message.getWrapperField(this, HallPassType, 1));
  }


  /** @param {?HallPassType|undefined} value */
  setHallPassType(value?: HallPassType) {
    jspb.Message.setWrapperField(this, 1, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearHallPassType() {
    this.setHallPassType(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasHallPassType(): boolean {
    return jspb.Message.getField(this, 1) != null;
  }


}
export namespace UpdateHallPassTypeResponse {
  export interface AsObject {
    hallPassType?: HallPassType.AsObject;
  }
}
export class UpdateHallPassTypeResponse extends jspb.Message {
  static readonly displayName = "UpdateHallPassTypeResponse";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): UpdateHallPassTypeResponse.AsObject {
    return UpdateHallPassTypeResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!UpdateHallPassTypeResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: UpdateHallPassTypeResponse) {
    var f: any;
    var obj: any = {
      hallPassType: (f = msg.getHallPassType()) && HallPassType.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!UpdateHallPassTypeResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new UpdateHallPassTypeResponse;
    return UpdateHallPassTypeResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!UpdateHallPassTypeResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!UpdateHallPassTypeResponse}
   */
  static deserializeBinaryFromReader(msg: UpdateHallPassTypeResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 2:
        var value2 = new HallPassType;
        reader.readMessage(value2,HallPassType.deserializeBinaryFromReader);
        msg.setHallPassType(value2);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!UpdateHallPassTypeResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: UpdateHallPassTypeResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getHallPassType();
    if (f != null) {
      writer.writeMessage(
        2,
        f,
        HallPassType.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    UpdateHallPassTypeResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional HallPassType hall_pass_type = 2;
   * @return {?HallPassType}
   */
  getHallPassType(): HallPassType {
    return /** @type{?HallPassType} */ (
      jspb.Message.getWrapperField(this, HallPassType, 2));
  }


  /** @param {?HallPassType|undefined} value */
  setHallPassType(value?: HallPassType) {
    jspb.Message.setWrapperField(this, 2, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearHallPassType() {
    this.setHallPassType(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasHallPassType(): boolean {
    return jspb.Message.getField(this, 2) != null;
  }


}
export namespace DeleteHallPassTypeRequest {
  export interface AsObject {
    typeId: number;
  }
}
export class DeleteHallPassTypeRequest extends jspb.Message {
  static readonly displayName = "DeleteHallPassTypeRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): DeleteHallPassTypeRequest.AsObject {
    return DeleteHallPassTypeRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!DeleteHallPassTypeRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: DeleteHallPassTypeRequest) {
    var f: any;
    var obj: any = {
      typeId: jspb.Message.getFieldWithDefault(msg, 1, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!DeleteHallPassTypeRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new DeleteHallPassTypeRequest;
    return DeleteHallPassTypeRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!DeleteHallPassTypeRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!DeleteHallPassTypeRequest}
   */
  static deserializeBinaryFromReader(msg: DeleteHallPassTypeRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {number} */ (reader.readInt32());
        msg.setTypeId(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!DeleteHallPassTypeRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: DeleteHallPassTypeRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getTypeId();
    if (f !== 0) {
      writer.writeInt32(
        1,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    DeleteHallPassTypeRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional int32 type_id = 1;
   * @return {number}
   */
  getTypeId(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
  };


  /** @param {number} value */
  setTypeId(value: number) {
    jspb.Message.setProto3IntField(this, 1, value);
  }


}
export namespace DeleteHallPassTypeResponse {
  export interface AsObject {
  }
}
export class DeleteHallPassTypeResponse extends jspb.Message {
  static readonly displayName = "DeleteHallPassTypeResponse";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): DeleteHallPassTypeResponse.AsObject {
    return DeleteHallPassTypeResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!DeleteHallPassTypeResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: DeleteHallPassTypeResponse) {
    var f: any;
    var obj: any = {

    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!DeleteHallPassTypeResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new DeleteHallPassTypeResponse;
    return DeleteHallPassTypeResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!DeleteHallPassTypeResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!DeleteHallPassTypeResponse}
   */
  static deserializeBinaryFromReader(msg: DeleteHallPassTypeResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!DeleteHallPassTypeResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: DeleteHallPassTypeResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    DeleteHallPassTypeResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
}
export namespace CreateHallPassRequest {
  export interface AsObject {
    hallPassTypeId: number;
    personHashIssuedToList: string[];
    startDateTime?: common_date_pb.DateTime.AsObject;
    expiredDateTime?: common_date_pb.DateTime.AsObject;
    hallPassDuration: number;
    teacherHash: string;
    note: string;
    ignoreRestrictions: boolean;
    isKiosk: boolean;
    listId: number;
  }
}
export class CreateHallPassRequest extends jspb.Message {
  static readonly displayName = "CreateHallPassRequest";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [2];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, CreateHallPassRequest.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): CreateHallPassRequest.AsObject {
    return CreateHallPassRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!CreateHallPassRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: CreateHallPassRequest) {
    var f: any;
    var obj: any = {
      hallPassTypeId: jspb.Message.getFieldWithDefault(msg, 1, 0),
      personHashIssuedToList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
      startDateTime: (f = msg.getStartDateTime()) && common_date_pb.DateTime.toObject(includeInstance, f),
      expiredDateTime: (f = msg.getExpiredDateTime()) && common_date_pb.DateTime.toObject(includeInstance, f),
      hallPassDuration: jspb.Message.getFieldWithDefault(msg, 5, 0),
      teacherHash: jspb.Message.getFieldWithDefault(msg, 6, ""),
      note: jspb.Message.getFieldWithDefault(msg, 7, ""),
      ignoreRestrictions: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
      isKiosk: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
      listId: jspb.Message.getFieldWithDefault(msg, 10, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!CreateHallPassRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new CreateHallPassRequest;
    return CreateHallPassRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!CreateHallPassRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!CreateHallPassRequest}
   */
  static deserializeBinaryFromReader(msg: CreateHallPassRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {number} */ (reader.readInt32());
        msg.setHallPassTypeId(value1);
        break;
      case 2:
        var value2 = /** @type {string} */ (reader.readString());
        msg.addPersonHashIssuedTo(value2);
        break;
      case 3:
        var value3 = new common_date_pb.DateTime;
        reader.readMessage(value3,common_date_pb.DateTime.deserializeBinaryFromReader);
        msg.setStartDateTime(value3);
        break;
      case 4:
        var value4 = new common_date_pb.DateTime;
        reader.readMessage(value4,common_date_pb.DateTime.deserializeBinaryFromReader);
        msg.setExpiredDateTime(value4);
        break;
      case 5:
        var value5 = /** @type {number} */ (reader.readInt32());
        msg.setHallPassDuration(value5);
        break;
      case 6:
        var value6 = /** @type {string} */ (reader.readString());
        msg.setTeacherHash(value6);
        break;
      case 7:
        var value7 = /** @type {string} */ (reader.readString());
        msg.setNote(value7);
        break;
      case 8:
        var value8 = /** @type {boolean} */ (reader.readBool());
        msg.setIgnoreRestrictions(value8);
        break;
      case 9:
        var value9 = /** @type {boolean} */ (reader.readBool());
        msg.setIsKiosk(value9);
        break;
      case 10:
        var value10 = /** @type {number} */ (reader.readInt32());
        msg.setListId(value10);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!CreateHallPassRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: CreateHallPassRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getHallPassTypeId();
    if (f !== 0) {
      writer.writeInt32(
        1,
        f
      );
    }
    f = message.getPersonHashIssuedToList();
    if (f.length > 0) {
      writer.writeRepeatedString(
        2,
        f
      );
    }
    f = message.getStartDateTime();
    if (f != null) {
      writer.writeMessage(
        3,
        f,
        common_date_pb.DateTime.serializeBinaryToWriter
      );
    }
    f = message.getExpiredDateTime();
    if (f != null) {
      writer.writeMessage(
        4,
        f,
        common_date_pb.DateTime.serializeBinaryToWriter
      );
    }
    f = message.getHallPassDuration();
    if (f !== 0) {
      writer.writeInt32(
        5,
        f
      );
    }
    f = message.getTeacherHash();
    if (f.length > 0) {
      writer.writeString(
        6,
        f
      );
    }
    f = message.getNote();
    if (f.length > 0) {
      writer.writeString(
        7,
        f
      );
    }
    f = message.getIgnoreRestrictions();
    if (f) {
      writer.writeBool(
        8,
        f
      );
    }
    f = message.getIsKiosk();
    if (f) {
      writer.writeBool(
        9,
        f
      );
    }
    f = message.getListId();
    if (f !== 0) {
      writer.writeInt32(
        10,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    CreateHallPassRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional int32 hall_pass_type_id = 1;
   * @return {number}
   */
  getHallPassTypeId(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
  };


  /** @param {number} value */
  setHallPassTypeId(value: number) {
    jspb.Message.setProto3IntField(this, 1, value);
  }


  /**
   * repeated string person_hash_issued_to = 2;
   * @return {!Array<string>}
   */
  getPersonHashIssuedToList(): string[] {
    return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
  };


  /** @param {!Array<string>} value */
  setPersonHashIssuedToList(value: string[]) {
    jspb.Message.setField(this, 2, value || []);
  }


  /**
   * @param {string} value
   * @param {number=} opt_index
   */
  addPersonHashIssuedTo(value: string, opt_index?: number): void {
    jspb.Message.addToRepeatedField(this, 2, value, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearPersonHashIssuedToList() {
    this.setPersonHashIssuedToList([]);
  }


  /**
   * optional minga.common.DateTime start_date_time = 3;
   * @return {?DateTime}
   */
  getStartDateTime(): common_date_pb.DateTime {
    return /** @type{?DateTime} */ (
      jspb.Message.getWrapperField(this, common_date_pb.DateTime, 3));
  }


  /** @param {?DateTime|undefined} value */
  setStartDateTime(value?: common_date_pb.DateTime) {
    jspb.Message.setWrapperField(this, 3, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearStartDateTime() {
    this.setStartDateTime(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasStartDateTime(): boolean {
    return jspb.Message.getField(this, 3) != null;
  }


  /**
   * optional minga.common.DateTime expired_date_time = 4;
   * @return {?DateTime}
   */
  getExpiredDateTime(): common_date_pb.DateTime {
    return /** @type{?DateTime} */ (
      jspb.Message.getWrapperField(this, common_date_pb.DateTime, 4));
  }


  /** @param {?DateTime|undefined} value */
  setExpiredDateTime(value?: common_date_pb.DateTime) {
    jspb.Message.setWrapperField(this, 4, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearExpiredDateTime() {
    this.setExpiredDateTime(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasExpiredDateTime(): boolean {
    return jspb.Message.getField(this, 4) != null;
  }


  /**
   * optional int32 hall_pass_duration = 5;
   * @return {number}
   */
  getHallPassDuration(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
  };


  /** @param {number} value */
  setHallPassDuration(value: number) {
    jspb.Message.setProto3IntField(this, 5, value);
  }


  /**
   * optional string teacher_hash = 6;
   * @return {string}
   */
  getTeacherHash(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
  };


  /** @param {string} value */
  setTeacherHash(value: string) {
    jspb.Message.setProto3StringField(this, 6, value);
  }


  /**
   * optional string note = 7;
   * @return {string}
   */
  getNote(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
  };


  /** @param {string} value */
  setNote(value: string) {
    jspb.Message.setProto3StringField(this, 7, value);
  }


  /**
   * optional bool ignore_restrictions = 8;
   * @return {boolean}
   */
  getIgnoreRestrictions(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
  };


  /** @param {boolean} value */
  setIgnoreRestrictions(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 8, value);
  }


  /**
   * optional bool is_kiosk = 9;
   * @return {boolean}
   */
  getIsKiosk(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
  };


  /** @param {boolean} value */
  setIsKiosk(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 9, value);
  }


  /**
   * optional int32 list_id = 10;
   * @return {number}
   */
  getListId(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
  };


  /** @param {number} value */
  setListId(value: number) {
    jspb.Message.setProto3IntField(this, 10, value);
  }


}
export namespace CreateHallPassResponse {
  export interface AsObject {
    errorsList: CheckHallPassValidityError.AsObject[];
    hallPassIdsList: number[];
    hallPassesList: HallPassWithType.AsObject[];
  }
}
export class CreateHallPassResponse extends jspb.Message {
  static readonly displayName = "CreateHallPassResponse";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [1,2,3];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, CreateHallPassResponse.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): CreateHallPassResponse.AsObject {
    return CreateHallPassResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!CreateHallPassResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: CreateHallPassResponse) {
    var f: any;
    var obj: any = {
      errorsList: jspb.Message.toObjectList(msg.getErrorsList(),
      CheckHallPassValidityError.toObject, includeInstance),
      hallPassIdsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
      hallPassesList: jspb.Message.toObjectList(msg.getHallPassesList(),
      HallPassWithType.toObject, includeInstance)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!CreateHallPassResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new CreateHallPassResponse;
    return CreateHallPassResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!CreateHallPassResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!CreateHallPassResponse}
   */
  static deserializeBinaryFromReader(msg: CreateHallPassResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new CheckHallPassValidityError;
        reader.readMessage(value1,CheckHallPassValidityError.deserializeBinaryFromReader);
        msg.addErrors(value1);
        break;
      case 2:
        var value2 = /** @type {!Array<number>} */ (reader.readPackedInt32());
        msg.setHallPassIdsList(value2);
        break;
      case 3:
        var value3 = new HallPassWithType;
        reader.readMessage(value3,HallPassWithType.deserializeBinaryFromReader);
        msg.addHallPasses(value3);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!CreateHallPassResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: CreateHallPassResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getErrorsList();
    if (f.length > 0) {
      writer.writeRepeatedMessage(
        1,
        f,
        CheckHallPassValidityError.serializeBinaryToWriter
      );
    }
    f = message.getHallPassIdsList();
    if (f.length > 0) {
      writer.writePackedInt32(
        2,
        f
      );
    }
    f = message.getHallPassesList();
    if (f.length > 0) {
      writer.writeRepeatedMessage(
        3,
        f,
        HallPassWithType.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    CreateHallPassResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * repeated CheckHallPassValidityError errors = 1;
   * @return {!Array<!CheckHallPassValidityError>}
   */
  getErrorsList(): CheckHallPassValidityError[] {
    return /** @type{!Array<!CheckHallPassValidityError>} */ (
      jspb.Message.getRepeatedWrapperField(this, CheckHallPassValidityError, 1));
  }


  /** @param {!Array<!CheckHallPassValidityError>} value */
  setErrorsList(value?: CheckHallPassValidityError[]) {
    jspb.Message.setRepeatedWrapperField(this, 1, value);
  }


  /**
   * @param {!CheckHallPassValidityError=} opt_value
   * @param {number=} opt_index
   * @return {!CheckHallPassValidityError}
   */
  addErrors(opt_value?: CheckHallPassValidityError, opt_index?: number): CheckHallPassValidityError {
    return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, CheckHallPassValidityError, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearErrorsList() {
    this.setErrorsList([]);
  }


  /**
   * repeated int32 hall_pass_ids = 2;
   * @return {!Array<number>}
   */
  getHallPassIdsList(): number[] {
    return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 2));
  };


  /** @param {!Array<number>} value */
  setHallPassIdsList(value: number[]) {
    jspb.Message.setField(this, 2, value || []);
  }


  /**
   * @param {number} value
   * @param {number=} opt_index
   */
  addHallPassIds(value: number, opt_index?: number): void {
    jspb.Message.addToRepeatedField(this, 2, value, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearHallPassIdsList() {
    this.setHallPassIdsList([]);
  }


  /**
   * repeated HallPassWithType hall_passes = 3;
   * @return {!Array<!HallPassWithType>}
   */
  getHallPassesList(): HallPassWithType[] {
    return /** @type{!Array<!HallPassWithType>} */ (
      jspb.Message.getRepeatedWrapperField(this, HallPassWithType, 3));
  }


  /** @param {!Array<!HallPassWithType>} value */
  setHallPassesList(value?: HallPassWithType[]) {
    jspb.Message.setRepeatedWrapperField(this, 3, value);
  }


  /**
   * @param {!HallPassWithType=} opt_value
   * @param {number=} opt_index
   * @return {!HallPassWithType}
   */
  addHallPasses(opt_value?: HallPassWithType, opt_index?: number): HallPassWithType {
    return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, HallPassWithType, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearHallPassesList() {
    this.setHallPassesList([]);
  }


}
export namespace ExpireHallPassRequest {
  export interface AsObject {
    hallPassId: number;
    expiredDateTime?: common_date_pb.DateTime.AsObject;
    endedByHash: string;
  }
}
export class ExpireHallPassRequest extends jspb.Message {
  static readonly displayName = "ExpireHallPassRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): ExpireHallPassRequest.AsObject {
    return ExpireHallPassRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!ExpireHallPassRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: ExpireHallPassRequest) {
    var f: any;
    var obj: any = {
      hallPassId: jspb.Message.getFieldWithDefault(msg, 1, 0),
      expiredDateTime: (f = msg.getExpiredDateTime()) && common_date_pb.DateTime.toObject(includeInstance, f),
      endedByHash: jspb.Message.getFieldWithDefault(msg, 3, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!ExpireHallPassRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new ExpireHallPassRequest;
    return ExpireHallPassRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!ExpireHallPassRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!ExpireHallPassRequest}
   */
  static deserializeBinaryFromReader(msg: ExpireHallPassRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {number} */ (reader.readInt32());
        msg.setHallPassId(value1);
        break;
      case 2:
        var value2 = new common_date_pb.DateTime;
        reader.readMessage(value2,common_date_pb.DateTime.deserializeBinaryFromReader);
        msg.setExpiredDateTime(value2);
        break;
      case 3:
        var value3 = /** @type {string} */ (reader.readString());
        msg.setEndedByHash(value3);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!ExpireHallPassRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: ExpireHallPassRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getHallPassId();
    if (f !== 0) {
      writer.writeInt32(
        1,
        f
      );
    }
    f = message.getExpiredDateTime();
    if (f != null) {
      writer.writeMessage(
        2,
        f,
        common_date_pb.DateTime.serializeBinaryToWriter
      );
    }
    f = message.getEndedByHash();
    if (f.length > 0) {
      writer.writeString(
        3,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    ExpireHallPassRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional int32 hall_pass_id = 1;
   * @return {number}
   */
  getHallPassId(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
  };


  /** @param {number} value */
  setHallPassId(value: number) {
    jspb.Message.setProto3IntField(this, 1, value);
  }


  /**
   * optional minga.common.DateTime expired_date_time = 2;
   * @return {?DateTime}
   */
  getExpiredDateTime(): common_date_pb.DateTime {
    return /** @type{?DateTime} */ (
      jspb.Message.getWrapperField(this, common_date_pb.DateTime, 2));
  }


  /** @param {?DateTime|undefined} value */
  setExpiredDateTime(value?: common_date_pb.DateTime) {
    jspb.Message.setWrapperField(this, 2, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearExpiredDateTime() {
    this.setExpiredDateTime(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasExpiredDateTime(): boolean {
    return jspb.Message.getField(this, 2) != null;
  }


  /**
   * optional string ended_by_hash = 3;
   * @return {string}
   */
  getEndedByHash(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
  };


  /** @param {string} value */
  setEndedByHash(value: string) {
    jspb.Message.setProto3StringField(this, 3, value);
  }


}
export namespace ExpireHallPassResponse {
  export interface AsObject {
  }
}
export class ExpireHallPassResponse extends jspb.Message {
  static readonly displayName = "ExpireHallPassResponse";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): ExpireHallPassResponse.AsObject {
    return ExpireHallPassResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!ExpireHallPassResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: ExpireHallPassResponse) {
    var f: any;
    var obj: any = {

    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!ExpireHallPassResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new ExpireHallPassResponse;
    return ExpireHallPassResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!ExpireHallPassResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!ExpireHallPassResponse}
   */
  static deserializeBinaryFromReader(msg: ExpireHallPassResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!ExpireHallPassResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: ExpireHallPassResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    ExpireHallPassResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
}
export namespace ApproveHallPassRequest {
  export interface AsObject {
    hallPassId: number;
    noteToRequester: string;
  }
}
export class ApproveHallPassRequest extends jspb.Message {
  static readonly displayName = "ApproveHallPassRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): ApproveHallPassRequest.AsObject {
    return ApproveHallPassRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!ApproveHallPassRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: ApproveHallPassRequest) {
    var f: any;
    var obj: any = {
      hallPassId: jspb.Message.getFieldWithDefault(msg, 1, 0),
      noteToRequester: jspb.Message.getFieldWithDefault(msg, 2, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!ApproveHallPassRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new ApproveHallPassRequest;
    return ApproveHallPassRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!ApproveHallPassRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!ApproveHallPassRequest}
   */
  static deserializeBinaryFromReader(msg: ApproveHallPassRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {number} */ (reader.readInt32());
        msg.setHallPassId(value1);
        break;
      case 2:
        var value2 = /** @type {string} */ (reader.readString());
        msg.setNoteToRequester(value2);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!ApproveHallPassRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: ApproveHallPassRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getHallPassId();
    if (f !== 0) {
      writer.writeInt32(
        1,
        f
      );
    }
    f = message.getNoteToRequester();
    if (f.length > 0) {
      writer.writeString(
        2,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    ApproveHallPassRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional int32 hall_pass_id = 1;
   * @return {number}
   */
  getHallPassId(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
  };


  /** @param {number} value */
  setHallPassId(value: number) {
    jspb.Message.setProto3IntField(this, 1, value);
  }


  /**
   * optional string note_to_requester = 2;
   * @return {string}
   */
  getNoteToRequester(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
  };


  /** @param {string} value */
  setNoteToRequester(value: string) {
    jspb.Message.setProto3StringField(this, 2, value);
  }


}
export namespace ApproveHallPassResponse {
  export interface AsObject {
  }
}
export class ApproveHallPassResponse extends jspb.Message {
  static readonly displayName = "ApproveHallPassResponse";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): ApproveHallPassResponse.AsObject {
    return ApproveHallPassResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!ApproveHallPassResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: ApproveHallPassResponse) {
    var f: any;
    var obj: any = {

    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!ApproveHallPassResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new ApproveHallPassResponse;
    return ApproveHallPassResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!ApproveHallPassResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!ApproveHallPassResponse}
   */
  static deserializeBinaryFromReader(msg: ApproveHallPassResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!ApproveHallPassResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: ApproveHallPassResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    ApproveHallPassResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
}
export namespace StartHallPassRequest {
  export interface AsObject {
    hallPassId: number;
  }
}
export class StartHallPassRequest extends jspb.Message {
  static readonly displayName = "StartHallPassRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): StartHallPassRequest.AsObject {
    return StartHallPassRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!StartHallPassRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: StartHallPassRequest) {
    var f: any;
    var obj: any = {
      hallPassId: jspb.Message.getFieldWithDefault(msg, 1, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!StartHallPassRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new StartHallPassRequest;
    return StartHallPassRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!StartHallPassRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!StartHallPassRequest}
   */
  static deserializeBinaryFromReader(msg: StartHallPassRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {number} */ (reader.readInt32());
        msg.setHallPassId(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!StartHallPassRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: StartHallPassRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getHallPassId();
    if (f !== 0) {
      writer.writeInt32(
        1,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    StartHallPassRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional int32 hall_pass_id = 1;
   * @return {number}
   */
  getHallPassId(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
  };


  /** @param {number} value */
  setHallPassId(value: number) {
    jspb.Message.setProto3IntField(this, 1, value);
  }


}
export namespace StartHallPassResponse {
  export interface AsObject {
  }
}
export class StartHallPassResponse extends jspb.Message {
  static readonly displayName = "StartHallPassResponse";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): StartHallPassResponse.AsObject {
    return StartHallPassResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!StartHallPassResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: StartHallPassResponse) {
    var f: any;
    var obj: any = {

    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!StartHallPassResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new StartHallPassResponse;
    return StartHallPassResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!StartHallPassResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!StartHallPassResponse}
   */
  static deserializeBinaryFromReader(msg: StartHallPassResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!StartHallPassResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: StartHallPassResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    StartHallPassResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
}
export namespace CancelHallPassRequest {
  export interface AsObject {
    hallPassId: number;
    noteToRequester: string;
    denying: boolean;
  }
}
export class CancelHallPassRequest extends jspb.Message {
  static readonly displayName = "CancelHallPassRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): CancelHallPassRequest.AsObject {
    return CancelHallPassRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!CancelHallPassRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: CancelHallPassRequest) {
    var f: any;
    var obj: any = {
      hallPassId: jspb.Message.getFieldWithDefault(msg, 1, 0),
      noteToRequester: jspb.Message.getFieldWithDefault(msg, 2, ""),
      denying: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!CancelHallPassRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new CancelHallPassRequest;
    return CancelHallPassRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!CancelHallPassRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!CancelHallPassRequest}
   */
  static deserializeBinaryFromReader(msg: CancelHallPassRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {number} */ (reader.readInt32());
        msg.setHallPassId(value1);
        break;
      case 2:
        var value2 = /** @type {string} */ (reader.readString());
        msg.setNoteToRequester(value2);
        break;
      case 3:
        var value3 = /** @type {boolean} */ (reader.readBool());
        msg.setDenying(value3);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!CancelHallPassRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: CancelHallPassRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getHallPassId();
    if (f !== 0) {
      writer.writeInt32(
        1,
        f
      );
    }
    f = message.getNoteToRequester();
    if (f.length > 0) {
      writer.writeString(
        2,
        f
      );
    }
    f = message.getDenying();
    if (f) {
      writer.writeBool(
        3,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    CancelHallPassRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional int32 hall_pass_id = 1;
   * @return {number}
   */
  getHallPassId(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
  };


  /** @param {number} value */
  setHallPassId(value: number) {
    jspb.Message.setProto3IntField(this, 1, value);
  }


  /**
   * optional string note_to_requester = 2;
   * @return {string}
   */
  getNoteToRequester(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
  };


  /** @param {string} value */
  setNoteToRequester(value: string) {
    jspb.Message.setProto3StringField(this, 2, value);
  }


  /**
   * optional bool denying = 3;
   * @return {boolean}
   */
  getDenying(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
  };


  /** @param {boolean} value */
  setDenying(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 3, value);
  }


}
export namespace CancelHallPassResponse {
  export interface AsObject {
  }
}
export class CancelHallPassResponse extends jspb.Message {
  static readonly displayName = "CancelHallPassResponse";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): CancelHallPassResponse.AsObject {
    return CancelHallPassResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!CancelHallPassResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: CancelHallPassResponse) {
    var f: any;
    var obj: any = {

    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!CancelHallPassResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new CancelHallPassResponse;
    return CancelHallPassResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!CancelHallPassResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!CancelHallPassResponse}
   */
  static deserializeBinaryFromReader(msg: CancelHallPassResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!CancelHallPassResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: CancelHallPassResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    CancelHallPassResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
}
export namespace GetMingaHallPassTypeRequest {
  export interface AsObject {
    hallPassTypeId: number;
  }
}
export class GetMingaHallPassTypeRequest extends jspb.Message {
  static readonly displayName = "GetMingaHallPassTypeRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): GetMingaHallPassTypeRequest.AsObject {
    return GetMingaHallPassTypeRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!GetMingaHallPassTypeRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: GetMingaHallPassTypeRequest) {
    var f: any;
    var obj: any = {
      hallPassTypeId: jspb.Message.getFieldWithDefault(msg, 1, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!GetMingaHallPassTypeRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new GetMingaHallPassTypeRequest;
    return GetMingaHallPassTypeRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!GetMingaHallPassTypeRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!GetMingaHallPassTypeRequest}
   */
  static deserializeBinaryFromReader(msg: GetMingaHallPassTypeRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {number} */ (reader.readInt32());
        msg.setHallPassTypeId(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!GetMingaHallPassTypeRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: GetMingaHallPassTypeRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getHallPassTypeId();
    if (f !== 0) {
      writer.writeInt32(
        1,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    GetMingaHallPassTypeRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional int32 hall_pass_type_id = 1;
   * @return {number}
   */
  getHallPassTypeId(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
  };


  /** @param {number} value */
  setHallPassTypeId(value: number) {
    jspb.Message.setProto3IntField(this, 1, value);
  }


}
export namespace GetMingaHallPassTypeResponse {
  export interface AsObject {
    hallPassType?: HallPassType.AsObject;
  }
}
export class GetMingaHallPassTypeResponse extends jspb.Message {
  static readonly displayName = "GetMingaHallPassTypeResponse";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): GetMingaHallPassTypeResponse.AsObject {
    return GetMingaHallPassTypeResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!GetMingaHallPassTypeResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: GetMingaHallPassTypeResponse) {
    var f: any;
    var obj: any = {
      hallPassType: (f = msg.getHallPassType()) && HallPassType.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!GetMingaHallPassTypeResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new GetMingaHallPassTypeResponse;
    return GetMingaHallPassTypeResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!GetMingaHallPassTypeResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!GetMingaHallPassTypeResponse}
   */
  static deserializeBinaryFromReader(msg: GetMingaHallPassTypeResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new HallPassType;
        reader.readMessage(value1,HallPassType.deserializeBinaryFromReader);
        msg.setHallPassType(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!GetMingaHallPassTypeResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: GetMingaHallPassTypeResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getHallPassType();
    if (f != null) {
      writer.writeMessage(
        1,
        f,
        HallPassType.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    GetMingaHallPassTypeResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional HallPassType hall_pass_type = 1;
   * @return {?HallPassType}
   */
  getHallPassType(): HallPassType {
    return /** @type{?HallPassType} */ (
      jspb.Message.getWrapperField(this, HallPassType, 1));
  }


  /** @param {?HallPassType|undefined} value */
  setHallPassType(value?: HallPassType) {
    jspb.Message.setWrapperField(this, 1, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearHallPassType() {
    this.setHallPassType(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasHallPassType(): boolean {
    return jspb.Message.getField(this, 1) != null;
  }


}
export namespace GetMingaHallPassTypesRequest {
  export interface AsObject {
    getActiveOnly: boolean;
    getPermittedOnly: boolean;
    getKioskPassesOnly: boolean;
  }
}
export class GetMingaHallPassTypesRequest extends jspb.Message {
  static readonly displayName = "GetMingaHallPassTypesRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): GetMingaHallPassTypesRequest.AsObject {
    return GetMingaHallPassTypesRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!GetMingaHallPassTypesRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: GetMingaHallPassTypesRequest) {
    var f: any;
    var obj: any = {
      getActiveOnly: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
      getPermittedOnly: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
      getKioskPassesOnly: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!GetMingaHallPassTypesRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new GetMingaHallPassTypesRequest;
    return GetMingaHallPassTypesRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!GetMingaHallPassTypesRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!GetMingaHallPassTypesRequest}
   */
  static deserializeBinaryFromReader(msg: GetMingaHallPassTypesRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {boolean} */ (reader.readBool());
        msg.setGetActiveOnly(value1);
        break;
      case 2:
        var value2 = /** @type {boolean} */ (reader.readBool());
        msg.setGetPermittedOnly(value2);
        break;
      case 3:
        var value3 = /** @type {boolean} */ (reader.readBool());
        msg.setGetKioskPassesOnly(value3);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!GetMingaHallPassTypesRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: GetMingaHallPassTypesRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getGetActiveOnly();
    if (f) {
      writer.writeBool(
        1,
        f
      );
    }
    f = message.getGetPermittedOnly();
    if (f) {
      writer.writeBool(
        2,
        f
      );
    }
    f = message.getGetKioskPassesOnly();
    if (f) {
      writer.writeBool(
        3,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    GetMingaHallPassTypesRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional bool get_active_only = 1;
   * @return {boolean}
   */
  getGetActiveOnly(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
  };


  /** @param {boolean} value */
  setGetActiveOnly(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 1, value);
  }


  /**
   * optional bool get_permitted_only = 2;
   * @return {boolean}
   */
  getGetPermittedOnly(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
  };


  /** @param {boolean} value */
  setGetPermittedOnly(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 2, value);
  }


  /**
   * optional bool get_kiosk_passes_only = 3;
   * @return {boolean}
   */
  getGetKioskPassesOnly(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
  };


  /** @param {boolean} value */
  setGetKioskPassesOnly(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 3, value);
  }


}
export namespace GetMingaHallPassTypesResponse {
  export interface AsObject {
    hallPassTypeList: HallPassType.AsObject[];
  }
}
export class GetMingaHallPassTypesResponse extends jspb.Message {
  static readonly displayName = "GetMingaHallPassTypesResponse";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [1];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, GetMingaHallPassTypesResponse.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): GetMingaHallPassTypesResponse.AsObject {
    return GetMingaHallPassTypesResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!GetMingaHallPassTypesResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: GetMingaHallPassTypesResponse) {
    var f: any;
    var obj: any = {
      hallPassTypeList: jspb.Message.toObjectList(msg.getHallPassTypeList(),
      HallPassType.toObject, includeInstance)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!GetMingaHallPassTypesResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new GetMingaHallPassTypesResponse;
    return GetMingaHallPassTypesResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!GetMingaHallPassTypesResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!GetMingaHallPassTypesResponse}
   */
  static deserializeBinaryFromReader(msg: GetMingaHallPassTypesResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new HallPassType;
        reader.readMessage(value1,HallPassType.deserializeBinaryFromReader);
        msg.addHallPassType(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!GetMingaHallPassTypesResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: GetMingaHallPassTypesResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getHallPassTypeList();
    if (f.length > 0) {
      writer.writeRepeatedMessage(
        1,
        f,
        HallPassType.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    GetMingaHallPassTypesResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * repeated HallPassType hall_pass_type = 1;
   * @return {!Array<!HallPassType>}
   */
  getHallPassTypeList(): HallPassType[] {
    return /** @type{!Array<!HallPassType>} */ (
      jspb.Message.getRepeatedWrapperField(this, HallPassType, 1));
  }


  /** @param {!Array<!HallPassType>} value */
  setHallPassTypeList(value?: HallPassType[]) {
    jspb.Message.setRepeatedWrapperField(this, 1, value);
  }


  /**
   * @param {!HallPassType=} opt_value
   * @param {number=} opt_index
   * @return {!HallPassType}
   */
  addHallPassType(opt_value?: HallPassType, opt_index?: number): HallPassType {
    return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, HallPassType, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearHallPassTypeList() {
    this.setHallPassTypeList([]);
  }


}
export namespace AddDefaultHallPassTypesRequest {
  export interface AsObject {
    mingaHash: string;
  }
}
export class AddDefaultHallPassTypesRequest extends jspb.Message {
  static readonly displayName = "AddDefaultHallPassTypesRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): AddDefaultHallPassTypesRequest.AsObject {
    return AddDefaultHallPassTypesRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!AddDefaultHallPassTypesRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: AddDefaultHallPassTypesRequest) {
    var f: any;
    var obj: any = {
      mingaHash: jspb.Message.getFieldWithDefault(msg, 1, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!AddDefaultHallPassTypesRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new AddDefaultHallPassTypesRequest;
    return AddDefaultHallPassTypesRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!AddDefaultHallPassTypesRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!AddDefaultHallPassTypesRequest}
   */
  static deserializeBinaryFromReader(msg: AddDefaultHallPassTypesRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setMingaHash(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!AddDefaultHallPassTypesRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: AddDefaultHallPassTypesRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getMingaHash();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    AddDefaultHallPassTypesRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string minga_hash = 1;
   * @return {string}
   */
  getMingaHash(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setMingaHash(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


}
export namespace AddDefaultHallPassTypesResponse {
  export interface AsObject {
    errorMessage: string;
  }
}
export class AddDefaultHallPassTypesResponse extends jspb.Message {
  static readonly displayName = "AddDefaultHallPassTypesResponse";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): AddDefaultHallPassTypesResponse.AsObject {
    return AddDefaultHallPassTypesResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!AddDefaultHallPassTypesResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: AddDefaultHallPassTypesResponse) {
    var f: any;
    var obj: any = {
      errorMessage: jspb.Message.getFieldWithDefault(msg, 1, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!AddDefaultHallPassTypesResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new AddDefaultHallPassTypesResponse;
    return AddDefaultHallPassTypesResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!AddDefaultHallPassTypesResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!AddDefaultHallPassTypesResponse}
   */
  static deserializeBinaryFromReader(msg: AddDefaultHallPassTypesResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setErrorMessage(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!AddDefaultHallPassTypesResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: AddDefaultHallPassTypesResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getErrorMessage();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    AddDefaultHallPassTypesResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string error_message = 1;
   * @return {string}
   */
  getErrorMessage(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setErrorMessage(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


}
export namespace GetMingaHallPassTypesCountsRequest {
  export interface AsObject {
    active: boolean;
    expired: boolean;
    scheduled: boolean;
  }
}
export class GetMingaHallPassTypesCountsRequest extends jspb.Message {
  static readonly displayName = "GetMingaHallPassTypesCountsRequest";
  /**
   * Oneof group definitions for this message. Each group defines the field
   * numbers belonging to that group. When of these fields' value is set, all
   * other fields in the group are cleared. During deserialization, if multiple
   * fields are encountered for a group, only the last value seen will be kept.
   * @private {!Array<!Array<number>>}
   * @const
   */
  static readonly oneofGroups_ = [[1,2,3]];


  /**
   * @return {GetMingaHallPassTypesCountsRequest.TimelineCase}
   */
  getTimelineCase() {
    return /** @type {GetMingaHallPassTypesCountsRequest.TimelineCase} */(jspb.Message.computeOneofCase(this, GetMingaHallPassTypesCountsRequest.oneofGroups_[0]));
  }

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, GetMingaHallPassTypesCountsRequest.oneofGroups_);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): GetMingaHallPassTypesCountsRequest.AsObject {
    return GetMingaHallPassTypesCountsRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!GetMingaHallPassTypesCountsRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: GetMingaHallPassTypesCountsRequest) {
    var f: any;
    var obj: any = {
      active: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
      expired: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
      scheduled: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!GetMingaHallPassTypesCountsRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new GetMingaHallPassTypesCountsRequest;
    return GetMingaHallPassTypesCountsRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!GetMingaHallPassTypesCountsRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!GetMingaHallPassTypesCountsRequest}
   */
  static deserializeBinaryFromReader(msg: GetMingaHallPassTypesCountsRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {boolean} */ (reader.readBool());
        msg.setActive(value1);
        break;
      case 2:
        var value2 = /** @type {boolean} */ (reader.readBool());
        msg.setExpired(value2);
        break;
      case 3:
        var value3 = /** @type {boolean} */ (reader.readBool());
        msg.setScheduled(value3);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!GetMingaHallPassTypesCountsRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: GetMingaHallPassTypesCountsRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = /** @type {boolean} */ (jspb.Message.getField(message, 1));
    if (f != null) {
      writer.writeBool(
        1,
        f
      );
    }
    f = /** @type {boolean} */ (jspb.Message.getField(message, 2));
    if (f != null) {
      writer.writeBool(
        2,
        f
      );
    }
    f = /** @type {boolean} */ (jspb.Message.getField(message, 3));
    if (f != null) {
      writer.writeBool(
        3,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    GetMingaHallPassTypesCountsRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional bool active = 1;
   * @return {boolean}
   */
  getActive(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
  };


  /** @param {boolean} value */
  setActive(value: boolean) {
    jspb.Message.setOneofField(this, 1, GetMingaHallPassTypesCountsRequest.oneofGroups_[0], value);
  }


  /**
   * Clears the field making it undefined.
   */
  clearActive() {
    jspb.Message.setOneofField(this, 1, GetMingaHallPassTypesCountsRequest.oneofGroups_[0], undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasActive(): boolean {
    return jspb.Message.getField(this, 1) != null;
  }


  /**
   * optional bool expired = 2;
   * @return {boolean}
   */
  getExpired(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
  };


  /** @param {boolean} value */
  setExpired(value: boolean) {
    jspb.Message.setOneofField(this, 2, GetMingaHallPassTypesCountsRequest.oneofGroups_[0], value);
  }


  /**
   * Clears the field making it undefined.
   */
  clearExpired() {
    jspb.Message.setOneofField(this, 2, GetMingaHallPassTypesCountsRequest.oneofGroups_[0], undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasExpired(): boolean {
    return jspb.Message.getField(this, 2) != null;
  }


  /**
   * optional bool scheduled = 3;
   * @return {boolean}
   */
  getScheduled(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
  };


  /** @param {boolean} value */
  setScheduled(value: boolean) {
    jspb.Message.setOneofField(this, 3, GetMingaHallPassTypesCountsRequest.oneofGroups_[0], value);
  }


  /**
   * Clears the field making it undefined.
   */
  clearScheduled() {
    jspb.Message.setOneofField(this, 3, GetMingaHallPassTypesCountsRequest.oneofGroups_[0], undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasScheduled(): boolean {
    return jspb.Message.getField(this, 3) != null;
  }


}
export namespace GetMingaHallPassTypesCountsRequest {
export enum TimelineCase {
  TIMELINE_NOT_SET = 0,
  ACTIVE = 1,
  EXPIRED = 2,
  SCHEDULED = 3,
}
} // namespace GetMingaHallPassTypesCountsRequest
export namespace GetMingaHallPassTypesCountsResponse {
  export interface AsObject {
    hallPassTypeCountList: GetMingaHallPassTypesCountsResponse.HallPassTypeWithCount.AsObject[];
  }
}
export class GetMingaHallPassTypesCountsResponse extends jspb.Message {
  static readonly displayName = "GetMingaHallPassTypesCountsResponse";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [1];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, GetMingaHallPassTypesCountsResponse.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): GetMingaHallPassTypesCountsResponse.AsObject {
    return GetMingaHallPassTypesCountsResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!GetMingaHallPassTypesCountsResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: GetMingaHallPassTypesCountsResponse) {
    var f: any;
    var obj: any = {
      hallPassTypeCountList: jspb.Message.toObjectList(msg.getHallPassTypeCountList(),
      GetMingaHallPassTypesCountsResponse.HallPassTypeWithCount.toObject, includeInstance)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!GetMingaHallPassTypesCountsResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new GetMingaHallPassTypesCountsResponse;
    return GetMingaHallPassTypesCountsResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!GetMingaHallPassTypesCountsResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!GetMingaHallPassTypesCountsResponse}
   */
  static deserializeBinaryFromReader(msg: GetMingaHallPassTypesCountsResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new GetMingaHallPassTypesCountsResponse.HallPassTypeWithCount;
        reader.readMessage(value1,GetMingaHallPassTypesCountsResponse.HallPassTypeWithCount.deserializeBinaryFromReader);
        msg.addHallPassTypeCount(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!GetMingaHallPassTypesCountsResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: GetMingaHallPassTypesCountsResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getHallPassTypeCountList();
    if (f.length > 0) {
      writer.writeRepeatedMessage(
        1,
        f,
        GetMingaHallPassTypesCountsResponse.HallPassTypeWithCount.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    GetMingaHallPassTypesCountsResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * repeated HallPassTypeWithCount hall_pass_type_count = 1;
   * @return {!Array<!GetMingaHallPassTypesCountsResponse.HallPassTypeWithCount>}
   */
  getHallPassTypeCountList(): GetMingaHallPassTypesCountsResponse.HallPassTypeWithCount[] {
    return /** @type{!Array<!GetMingaHallPassTypesCountsResponse.HallPassTypeWithCount>} */ (
      jspb.Message.getRepeatedWrapperField(this, GetMingaHallPassTypesCountsResponse.HallPassTypeWithCount, 1));
  }


  /** @param {!Array<!GetMingaHallPassTypesCountsResponse.HallPassTypeWithCount>} value */
  setHallPassTypeCountList(value?: GetMingaHallPassTypesCountsResponse.HallPassTypeWithCount[]) {
    jspb.Message.setRepeatedWrapperField(this, 1, value);
  }


  /**
   * @param {!GetMingaHallPassTypesCountsResponse.HallPassTypeWithCount=} opt_value
   * @param {number=} opt_index
   * @return {!GetMingaHallPassTypesCountsResponse.HallPassTypeWithCount}
   */
  addHallPassTypeCount(opt_value?: GetMingaHallPassTypesCountsResponse.HallPassTypeWithCount, opt_index?: number): GetMingaHallPassTypesCountsResponse.HallPassTypeWithCount {
    return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, GetMingaHallPassTypesCountsResponse.HallPassTypeWithCount, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearHallPassTypeCountList() {
    this.setHallPassTypeCountList([]);
  }


}
export namespace GetMingaHallPassTypesCountsResponse {
export namespace HallPassTypeWithCount {
  export interface AsObject {
    hallPassType?: HallPassType.AsObject;
    count: number;
  }
}
export class HallPassTypeWithCount extends jspb.Message {
  static readonly displayName = "GetMingaHallPassTypesCountsResponse.HallPassTypeWithCount";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): HallPassTypeWithCount.AsObject {
    return HallPassTypeWithCount.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!HallPassTypeWithCount} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: HallPassTypeWithCount) {
    var f: any;
    var obj: any = {
      hallPassType: (f = msg.getHallPassType()) && HallPassType.toObject(includeInstance, f),
      count: jspb.Message.getFieldWithDefault(msg, 2, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!HallPassTypeWithCount}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new HallPassTypeWithCount;
    return HallPassTypeWithCount.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!GetMingaHallPassTypesCountsResponse.HallPassTypeWithCount} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!GetMingaHallPassTypesCountsResponse.HallPassTypeWithCount}
   */
  static deserializeBinaryFromReader(msg: HallPassTypeWithCount, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new HallPassType;
        reader.readMessage(value1,HallPassType.deserializeBinaryFromReader);
        msg.setHallPassType(value1);
        break;
      case 2:
        var value2 = /** @type {number} */ (reader.readInt32());
        msg.setCount(value2);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!GetMingaHallPassTypesCountsResponse.HallPassTypeWithCount} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: GetMingaHallPassTypesCountsResponse.HallPassTypeWithCount, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getHallPassType();
    if (f != null) {
      writer.writeMessage(
        1,
        f,
        HallPassType.serializeBinaryToWriter
      );
    }
    f = message.getCount();
    if (f !== 0) {
      writer.writeInt32(
        2,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    GetMingaHallPassTypesCountsResponse.HallPassTypeWithCount.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional HallPassType hall_pass_type = 1;
   * @return {?HallPassType}
   */
  getHallPassType(): HallPassType {
    return /** @type{?HallPassType} */ (
      jspb.Message.getWrapperField(this, HallPassType, 1));
  }


  /** @param {?HallPassType|undefined} value */
  setHallPassType(value?: HallPassType) {
    jspb.Message.setWrapperField(this, 1, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearHallPassType() {
    this.setHallPassType(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasHallPassType(): boolean {
    return jspb.Message.getField(this, 1) != null;
  }


  /**
   * optional int32 count = 2;
   * @return {number}
   */
  getCount(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
  };


  /** @param {number} value */
  setCount(value: number) {
    jspb.Message.setProto3IntField(this, 2, value);
  }


}
} // namespace GetMingaHallPassTypesCountsResponse
export namespace StreamHallPassWithTypeItem {
  export interface AsObject {
    itemMetadata?: common_stream_pb.StreamItemMetadata.AsObject;
    item?: HallPassWithType.AsObject;
  }
}
export class StreamHallPassWithTypeItem extends jspb.Message {
  static readonly displayName = "StreamHallPassWithTypeItem";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): StreamHallPassWithTypeItem.AsObject {
    return StreamHallPassWithTypeItem.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!StreamHallPassWithTypeItem} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: StreamHallPassWithTypeItem) {
    var f: any;
    var obj: any = {
      itemMetadata: (f = msg.getItemMetadata()) && common_stream_pb.StreamItemMetadata.toObject(includeInstance, f),
      item: (f = msg.getItem()) && HallPassWithType.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!StreamHallPassWithTypeItem}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new StreamHallPassWithTypeItem;
    return StreamHallPassWithTypeItem.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!StreamHallPassWithTypeItem} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!StreamHallPassWithTypeItem}
   */
  static deserializeBinaryFromReader(msg: StreamHallPassWithTypeItem, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new common_stream_pb.StreamItemMetadata;
        reader.readMessage(value1,common_stream_pb.StreamItemMetadata.deserializeBinaryFromReader);
        msg.setItemMetadata(value1);
        break;
      case 2:
        var value2 = new HallPassWithType;
        reader.readMessage(value2,HallPassWithType.deserializeBinaryFromReader);
        msg.setItem(value2);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!StreamHallPassWithTypeItem} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: StreamHallPassWithTypeItem, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getItemMetadata();
    if (f != null) {
      writer.writeMessage(
        1,
        f,
        common_stream_pb.StreamItemMetadata.serializeBinaryToWriter
      );
    }
    f = message.getItem();
    if (f != null) {
      writer.writeMessage(
        2,
        f,
        HallPassWithType.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    StreamHallPassWithTypeItem.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional StreamItemMetadata item_metadata = 1;
   * @return {?StreamItemMetadata}
   */
  getItemMetadata(): common_stream_pb.StreamItemMetadata {
    return /** @type{?StreamItemMetadata} */ (
      jspb.Message.getWrapperField(this, common_stream_pb.StreamItemMetadata, 1));
  }


  /** @param {?StreamItemMetadata|undefined} value */
  setItemMetadata(value?: common_stream_pb.StreamItemMetadata) {
    jspb.Message.setWrapperField(this, 1, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearItemMetadata() {
    this.setItemMetadata(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasItemMetadata(): boolean {
    return jspb.Message.getField(this, 1) != null;
  }


  /**
   * optional HallPassWithType item = 2;
   * @return {?HallPassWithType}
   */
  getItem(): HallPassWithType {
    return /** @type{?HallPassWithType} */ (
      jspb.Message.getWrapperField(this, HallPassWithType, 2));
  }


  /** @param {?HallPassWithType|undefined} value */
  setItem(value?: HallPassWithType) {
    jspb.Message.setWrapperField(this, 2, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearItem() {
    this.setItem(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasItem(): boolean {
    return jspb.Message.getField(this, 2) != null;
  }


}
export namespace GetHallPassesRequest {
  export interface AsObject {
    filter?: common_stream_pb.StreamFilter.AsObject;
    limit: number;
    offset: number;
    includeActive: boolean;
    includeEnded: boolean;
    includePending: boolean;
    includeScheduled: boolean;
    includeOverdue: boolean;
    showCreated: boolean;
    includeDenied: boolean;
  }
}
export class GetHallPassesRequest extends jspb.Message {
  static readonly displayName = "GetHallPassesRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): GetHallPassesRequest.AsObject {
    return GetHallPassesRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!GetHallPassesRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: GetHallPassesRequest) {
    var f: any;
    var obj: any = {
      filter: (f = msg.getFilter()) && common_stream_pb.StreamFilter.toObject(includeInstance, f),
      limit: jspb.Message.getFieldWithDefault(msg, 2, 0),
      offset: jspb.Message.getFieldWithDefault(msg, 3, 0),
      includeActive: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
      includeEnded: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
      includePending: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
      includeScheduled: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
      includeOverdue: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
      showCreated: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
      includeDenied: jspb.Message.getBooleanFieldWithDefault(msg, 10, false)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!GetHallPassesRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new GetHallPassesRequest;
    return GetHallPassesRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!GetHallPassesRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!GetHallPassesRequest}
   */
  static deserializeBinaryFromReader(msg: GetHallPassesRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new common_stream_pb.StreamFilter;
        reader.readMessage(value1,common_stream_pb.StreamFilter.deserializeBinaryFromReader);
        msg.setFilter(value1);
        break;
      case 2:
        var value2 = /** @type {number} */ (reader.readInt32());
        msg.setLimit(value2);
        break;
      case 3:
        var value3 = /** @type {number} */ (reader.readInt32());
        msg.setOffset(value3);
        break;
      case 4:
        var value4 = /** @type {boolean} */ (reader.readBool());
        msg.setIncludeActive(value4);
        break;
      case 5:
        var value5 = /** @type {boolean} */ (reader.readBool());
        msg.setIncludeEnded(value5);
        break;
      case 6:
        var value6 = /** @type {boolean} */ (reader.readBool());
        msg.setIncludePending(value6);
        break;
      case 7:
        var value7 = /** @type {boolean} */ (reader.readBool());
        msg.setIncludeScheduled(value7);
        break;
      case 8:
        var value8 = /** @type {boolean} */ (reader.readBool());
        msg.setIncludeOverdue(value8);
        break;
      case 9:
        var value9 = /** @type {boolean} */ (reader.readBool());
        msg.setShowCreated(value9);
        break;
      case 10:
        var value10 = /** @type {boolean} */ (reader.readBool());
        msg.setIncludeDenied(value10);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!GetHallPassesRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: GetHallPassesRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getFilter();
    if (f != null) {
      writer.writeMessage(
        1,
        f,
        common_stream_pb.StreamFilter.serializeBinaryToWriter
      );
    }
    f = message.getLimit();
    if (f !== 0) {
      writer.writeInt32(
        2,
        f
      );
    }
    f = message.getOffset();
    if (f !== 0) {
      writer.writeInt32(
        3,
        f
      );
    }
    f = message.getIncludeActive();
    if (f) {
      writer.writeBool(
        4,
        f
      );
    }
    f = message.getIncludeEnded();
    if (f) {
      writer.writeBool(
        5,
        f
      );
    }
    f = message.getIncludePending();
    if (f) {
      writer.writeBool(
        6,
        f
      );
    }
    f = message.getIncludeScheduled();
    if (f) {
      writer.writeBool(
        7,
        f
      );
    }
    f = message.getIncludeOverdue();
    if (f) {
      writer.writeBool(
        8,
        f
      );
    }
    f = message.getShowCreated();
    if (f) {
      writer.writeBool(
        9,
        f
      );
    }
    f = message.getIncludeDenied();
    if (f) {
      writer.writeBool(
        10,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    GetHallPassesRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional StreamFilter filter = 1;
   * @return {?StreamFilter}
   */
  getFilter(): common_stream_pb.StreamFilter {
    return /** @type{?StreamFilter} */ (
      jspb.Message.getWrapperField(this, common_stream_pb.StreamFilter, 1));
  }


  /** @param {?StreamFilter|undefined} value */
  setFilter(value?: common_stream_pb.StreamFilter) {
    jspb.Message.setWrapperField(this, 1, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearFilter() {
    this.setFilter(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasFilter(): boolean {
    return jspb.Message.getField(this, 1) != null;
  }


  /**
   * optional int32 limit = 2;
   * @return {number}
   */
  getLimit(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
  };


  /** @param {number} value */
  setLimit(value: number) {
    jspb.Message.setProto3IntField(this, 2, value);
  }


  /**
   * optional int32 offset = 3;
   * @return {number}
   */
  getOffset(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
  };


  /** @param {number} value */
  setOffset(value: number) {
    jspb.Message.setProto3IntField(this, 3, value);
  }


  /**
   * optional bool include_active = 4;
   * @return {boolean}
   */
  getIncludeActive(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
  };


  /** @param {boolean} value */
  setIncludeActive(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 4, value);
  }


  /**
   * optional bool include_ended = 5;
   * @return {boolean}
   */
  getIncludeEnded(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
  };


  /** @param {boolean} value */
  setIncludeEnded(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 5, value);
  }


  /**
   * optional bool include_pending = 6;
   * @return {boolean}
   */
  getIncludePending(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
  };


  /** @param {boolean} value */
  setIncludePending(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 6, value);
  }


  /**
   * optional bool include_scheduled = 7;
   * @return {boolean}
   */
  getIncludeScheduled(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
  };


  /** @param {boolean} value */
  setIncludeScheduled(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 7, value);
  }


  /**
   * optional bool include_overdue = 8;
   * @return {boolean}
   */
  getIncludeOverdue(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
  };


  /** @param {boolean} value */
  setIncludeOverdue(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 8, value);
  }


  /**
   * optional bool show_created = 9;
   * @return {boolean}
   */
  getShowCreated(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
  };


  /** @param {boolean} value */
  setShowCreated(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 9, value);
  }


  /**
   * optional bool include_denied = 10;
   * @return {boolean}
   */
  getIncludeDenied(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
  };


  /** @param {boolean} value */
  setIncludeDenied(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 10, value);
  }


}
export namespace GetHallPassesResponse {
  export interface AsObject {
    itemsList: StreamHallPassWithTypeItem.AsObject[];
  }
}
export class GetHallPassesResponse extends jspb.Message {
  static readonly displayName = "GetHallPassesResponse";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [1];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, GetHallPassesResponse.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): GetHallPassesResponse.AsObject {
    return GetHallPassesResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!GetHallPassesResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: GetHallPassesResponse) {
    var f: any;
    var obj: any = {
      itemsList: jspb.Message.toObjectList(msg.getItemsList(),
      StreamHallPassWithTypeItem.toObject, includeInstance)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!GetHallPassesResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new GetHallPassesResponse;
    return GetHallPassesResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!GetHallPassesResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!GetHallPassesResponse}
   */
  static deserializeBinaryFromReader(msg: GetHallPassesResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new StreamHallPassWithTypeItem;
        reader.readMessage(value1,StreamHallPassWithTypeItem.deserializeBinaryFromReader);
        msg.addItems(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!GetHallPassesResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: GetHallPassesResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getItemsList();
    if (f.length > 0) {
      writer.writeRepeatedMessage(
        1,
        f,
        StreamHallPassWithTypeItem.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    GetHallPassesResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * repeated StreamHallPassWithTypeItem items = 1;
   * @return {!Array<!StreamHallPassWithTypeItem>}
   */
  getItemsList(): StreamHallPassWithTypeItem[] {
    return /** @type{!Array<!StreamHallPassWithTypeItem>} */ (
      jspb.Message.getRepeatedWrapperField(this, StreamHallPassWithTypeItem, 1));
  }


  /** @param {!Array<!StreamHallPassWithTypeItem>} value */
  setItemsList(value?: StreamHallPassWithTypeItem[]) {
    jspb.Message.setRepeatedWrapperField(this, 1, value);
  }


  /**
   * @param {!StreamHallPassWithTypeItem=} opt_value
   * @param {number=} opt_index
   * @return {!StreamHallPassWithTypeItem}
   */
  addItems(opt_value?: StreamHallPassWithTypeItem, opt_index?: number): StreamHallPassWithTypeItem {
    return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, StreamHallPassWithTypeItem, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearItemsList() {
    this.setItemsList([]);
  }


}
export namespace GetHallPassesForRecipientRequest {
  export interface AsObject {
    recipientPersonHash: string;
  }
}
export class GetHallPassesForRecipientRequest extends jspb.Message {
  static readonly displayName = "GetHallPassesForRecipientRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): GetHallPassesForRecipientRequest.AsObject {
    return GetHallPassesForRecipientRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!GetHallPassesForRecipientRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: GetHallPassesForRecipientRequest) {
    var f: any;
    var obj: any = {
      recipientPersonHash: jspb.Message.getFieldWithDefault(msg, 1, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!GetHallPassesForRecipientRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new GetHallPassesForRecipientRequest;
    return GetHallPassesForRecipientRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!GetHallPassesForRecipientRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!GetHallPassesForRecipientRequest}
   */
  static deserializeBinaryFromReader(msg: GetHallPassesForRecipientRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setRecipientPersonHash(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!GetHallPassesForRecipientRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: GetHallPassesForRecipientRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getRecipientPersonHash();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    GetHallPassesForRecipientRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string recipient_person_hash = 1;
   * @return {string}
   */
  getRecipientPersonHash(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setRecipientPersonHash(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


}
export namespace GetHallPassesForRecipientResponse {
  export interface AsObject {
    hallPassList: HallPass.AsObject[];
  }
}
export class GetHallPassesForRecipientResponse extends jspb.Message {
  static readonly displayName = "GetHallPassesForRecipientResponse";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [1];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, GetHallPassesForRecipientResponse.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): GetHallPassesForRecipientResponse.AsObject {
    return GetHallPassesForRecipientResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!GetHallPassesForRecipientResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: GetHallPassesForRecipientResponse) {
    var f: any;
    var obj: any = {
      hallPassList: jspb.Message.toObjectList(msg.getHallPassList(),
      HallPass.toObject, includeInstance)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!GetHallPassesForRecipientResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new GetHallPassesForRecipientResponse;
    return GetHallPassesForRecipientResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!GetHallPassesForRecipientResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!GetHallPassesForRecipientResponse}
   */
  static deserializeBinaryFromReader(msg: GetHallPassesForRecipientResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new HallPass;
        reader.readMessage(value1,HallPass.deserializeBinaryFromReader);
        msg.addHallPass(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!GetHallPassesForRecipientResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: GetHallPassesForRecipientResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getHallPassList();
    if (f.length > 0) {
      writer.writeRepeatedMessage(
        1,
        f,
        HallPass.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    GetHallPassesForRecipientResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * repeated HallPass hall_pass = 1;
   * @return {!Array<!HallPass>}
   */
  getHallPassList(): HallPass[] {
    return /** @type{!Array<!HallPass>} */ (
      jspb.Message.getRepeatedWrapperField(this, HallPass, 1));
  }


  /** @param {!Array<!HallPass>} value */
  setHallPassList(value?: HallPass[]) {
    jspb.Message.setRepeatedWrapperField(this, 1, value);
  }


  /**
   * @param {!HallPass=} opt_value
   * @param {number=} opt_index
   * @return {!HallPass}
   */
  addHallPass(opt_value?: HallPass, opt_index?: number): HallPass {
    return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, HallPass, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearHallPassList() {
    this.setHallPassList([]);
  }


}
export namespace GetMembershipListsRequest {
  export interface AsObject {
  }
}
export class GetMembershipListsRequest extends jspb.Message {
  static readonly displayName = "GetMembershipListsRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): GetMembershipListsRequest.AsObject {
    return GetMembershipListsRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!GetMembershipListsRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: GetMembershipListsRequest) {
    var f: any;
    var obj: any = {

    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!GetMembershipListsRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new GetMembershipListsRequest;
    return GetMembershipListsRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!GetMembershipListsRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!GetMembershipListsRequest}
   */
  static deserializeBinaryFromReader(msg: GetMembershipListsRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!GetMembershipListsRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: GetMembershipListsRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    GetMembershipListsRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
}
export namespace GetMembershipListsResponse {
  export interface AsObject {
    listsList: membership_list_membership_list_pb.MembershipList.AsObject[];
  }
}
export class GetMembershipListsResponse extends jspb.Message {
  static readonly displayName = "GetMembershipListsResponse";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [1];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, GetMembershipListsResponse.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): GetMembershipListsResponse.AsObject {
    return GetMembershipListsResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!GetMembershipListsResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: GetMembershipListsResponse) {
    var f: any;
    var obj: any = {
      listsList: jspb.Message.toObjectList(msg.getListsList(),
      membership_list_membership_list_pb.MembershipList.toObject, includeInstance)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!GetMembershipListsResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new GetMembershipListsResponse;
    return GetMembershipListsResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!GetMembershipListsResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!GetMembershipListsResponse}
   */
  static deserializeBinaryFromReader(msg: GetMembershipListsResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new membership_list_membership_list_pb.MembershipList;
        reader.readMessage(value1,membership_list_membership_list_pb.MembershipList.deserializeBinaryFromReader);
        msg.addLists(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!GetMembershipListsResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: GetMembershipListsResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getListsList();
    if (f.length > 0) {
      writer.writeRepeatedMessage(
        1,
        f,
        membership_list_membership_list_pb.MembershipList.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    GetMembershipListsResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * repeated membership_list.MembershipList lists = 1;
   * @return {!Array<!MembershipList>}
   */
  getListsList(): membership_list_membership_list_pb.MembershipList[] {
    return /** @type{!Array<!MembershipList>} */ (
      jspb.Message.getRepeatedWrapperField(this, membership_list_membership_list_pb.MembershipList, 1));
  }


  /** @param {!Array<!MembershipList>} value */
  setListsList(value?: membership_list_membership_list_pb.MembershipList[]) {
    jspb.Message.setRepeatedWrapperField(this, 1, value);
  }


  /**
   * @param {!MembershipList=} opt_value
   * @param {number=} opt_index
   * @return {!MembershipList}
   */
  addLists(opt_value?: membership_list_membership_list_pb.MembershipList, opt_index?: number): membership_list_membership_list_pb.MembershipList {
    return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, membership_list_membership_list_pb.MembershipList, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearListsList() {
    this.setListsList([]);
  }


}
export namespace CheckHallPassValidityRequest {
  export interface AsObject {
    peopleHashesList: string[];
    hallPassTypeId: number;
    startDate?: common_date_pb.DateTime.AsObject;
  }
}
export class CheckHallPassValidityRequest extends jspb.Message {
  static readonly displayName = "CheckHallPassValidityRequest";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [1];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, CheckHallPassValidityRequest.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): CheckHallPassValidityRequest.AsObject {
    return CheckHallPassValidityRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!CheckHallPassValidityRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: CheckHallPassValidityRequest) {
    var f: any;
    var obj: any = {
      peopleHashesList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
      hallPassTypeId: jspb.Message.getFieldWithDefault(msg, 2, 0),
      startDate: (f = msg.getStartDate()) && common_date_pb.DateTime.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!CheckHallPassValidityRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new CheckHallPassValidityRequest;
    return CheckHallPassValidityRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!CheckHallPassValidityRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!CheckHallPassValidityRequest}
   */
  static deserializeBinaryFromReader(msg: CheckHallPassValidityRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.addPeopleHashes(value1);
        break;
      case 2:
        var value2 = /** @type {number} */ (reader.readInt32());
        msg.setHallPassTypeId(value2);
        break;
      case 3:
        var value3 = new common_date_pb.DateTime;
        reader.readMessage(value3,common_date_pb.DateTime.deserializeBinaryFromReader);
        msg.setStartDate(value3);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!CheckHallPassValidityRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: CheckHallPassValidityRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getPeopleHashesList();
    if (f.length > 0) {
      writer.writeRepeatedString(
        1,
        f
      );
    }
    f = message.getHallPassTypeId();
    if (f !== 0) {
      writer.writeInt32(
        2,
        f
      );
    }
    f = message.getStartDate();
    if (f != null) {
      writer.writeMessage(
        3,
        f,
        common_date_pb.DateTime.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    CheckHallPassValidityRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * repeated string people_hashes = 1;
   * @return {!Array<string>}
   */
  getPeopleHashesList(): string[] {
    return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
  };


  /** @param {!Array<string>} value */
  setPeopleHashesList(value: string[]) {
    jspb.Message.setField(this, 1, value || []);
  }


  /**
   * @param {string} value
   * @param {number=} opt_index
   */
  addPeopleHashes(value: string, opt_index?: number): void {
    jspb.Message.addToRepeatedField(this, 1, value, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearPeopleHashesList() {
    this.setPeopleHashesList([]);
  }


  /**
   * optional int32 hall_pass_type_id = 2;
   * @return {number}
   */
  getHallPassTypeId(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
  };


  /** @param {number} value */
  setHallPassTypeId(value: number) {
    jspb.Message.setProto3IntField(this, 2, value);
  }


  /**
   * optional minga.common.DateTime start_date = 3;
   * @return {?DateTime}
   */
  getStartDate(): common_date_pb.DateTime {
    return /** @type{?DateTime} */ (
      jspb.Message.getWrapperField(this, common_date_pb.DateTime, 3));
  }


  /** @param {?DateTime|undefined} value */
  setStartDate(value?: common_date_pb.DateTime) {
    jspb.Message.setWrapperField(this, 3, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearStartDate() {
    this.setStartDate(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasStartDate(): boolean {
    return jspb.Message.getField(this, 3) != null;
  }


}
export namespace CheckHallPassValidityError {
  export interface AsObject {
    errorCode: number;
    personHash: string;
    name: string;
    maxPasses: number;
    hallPassName: string;
  }
}
export class CheckHallPassValidityError extends jspb.Message {
  static readonly displayName = "CheckHallPassValidityError";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): CheckHallPassValidityError.AsObject {
    return CheckHallPassValidityError.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!CheckHallPassValidityError} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: CheckHallPassValidityError) {
    var f: any;
    var obj: any = {
      errorCode: jspb.Message.getFieldWithDefault(msg, 1, 0),
      personHash: jspb.Message.getFieldWithDefault(msg, 2, ""),
      name: jspb.Message.getFieldWithDefault(msg, 3, ""),
      maxPasses: jspb.Message.getFieldWithDefault(msg, 4, 0),
      hallPassName: jspb.Message.getFieldWithDefault(msg, 5, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!CheckHallPassValidityError}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new CheckHallPassValidityError;
    return CheckHallPassValidityError.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!CheckHallPassValidityError} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!CheckHallPassValidityError}
   */
  static deserializeBinaryFromReader(msg: CheckHallPassValidityError, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {number} */ (reader.readInt32());
        msg.setErrorCode(value1);
        break;
      case 2:
        var value2 = /** @type {string} */ (reader.readString());
        msg.setPersonHash(value2);
        break;
      case 3:
        var value3 = /** @type {string} */ (reader.readString());
        msg.setName(value3);
        break;
      case 4:
        var value4 = /** @type {number} */ (reader.readInt32());
        msg.setMaxPasses(value4);
        break;
      case 5:
        var value5 = /** @type {string} */ (reader.readString());
        msg.setHallPassName(value5);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!CheckHallPassValidityError} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: CheckHallPassValidityError, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getErrorCode();
    if (f !== 0) {
      writer.writeInt32(
        1,
        f
      );
    }
    f = message.getPersonHash();
    if (f.length > 0) {
      writer.writeString(
        2,
        f
      );
    }
    f = message.getName();
    if (f.length > 0) {
      writer.writeString(
        3,
        f
      );
    }
    f = message.getMaxPasses();
    if (f !== 0) {
      writer.writeInt32(
        4,
        f
      );
    }
    f = message.getHallPassName();
    if (f.length > 0) {
      writer.writeString(
        5,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    CheckHallPassValidityError.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional int32 error_code = 1;
   * @return {number}
   */
  getErrorCode(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
  };


  /** @param {number} value */
  setErrorCode(value: number) {
    jspb.Message.setProto3IntField(this, 1, value);
  }


  /**
   * optional string person_hash = 2;
   * @return {string}
   */
  getPersonHash(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
  };


  /** @param {string} value */
  setPersonHash(value: string) {
    jspb.Message.setProto3StringField(this, 2, value);
  }


  /**
   * optional string name = 3;
   * @return {string}
   */
  getName(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
  };


  /** @param {string} value */
  setName(value: string) {
    jspb.Message.setProto3StringField(this, 3, value);
  }


  /**
   * optional int32 max_passes = 4;
   * @return {number}
   */
  getMaxPasses(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
  };


  /** @param {number} value */
  setMaxPasses(value: number) {
    jspb.Message.setProto3IntField(this, 4, value);
  }


  /**
   * optional string hall_pass_name = 5;
   * @return {string}
   */
  getHallPassName(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
  };


  /** @param {string} value */
  setHallPassName(value: string) {
    jspb.Message.setProto3StringField(this, 5, value);
  }


}
export namespace CheckHallPassValidityResponse {
  export interface AsObject {
    errorsList: CheckHallPassValidityError.AsObject[];
  }
}
export class CheckHallPassValidityResponse extends jspb.Message {
  static readonly displayName = "CheckHallPassValidityResponse";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [1];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, CheckHallPassValidityResponse.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): CheckHallPassValidityResponse.AsObject {
    return CheckHallPassValidityResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!CheckHallPassValidityResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: CheckHallPassValidityResponse) {
    var f: any;
    var obj: any = {
      errorsList: jspb.Message.toObjectList(msg.getErrorsList(),
      CheckHallPassValidityError.toObject, includeInstance)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!CheckHallPassValidityResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new CheckHallPassValidityResponse;
    return CheckHallPassValidityResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!CheckHallPassValidityResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!CheckHallPassValidityResponse}
   */
  static deserializeBinaryFromReader(msg: CheckHallPassValidityResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new CheckHallPassValidityError;
        reader.readMessage(value1,CheckHallPassValidityError.deserializeBinaryFromReader);
        msg.addErrors(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!CheckHallPassValidityResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: CheckHallPassValidityResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getErrorsList();
    if (f.length > 0) {
      writer.writeRepeatedMessage(
        1,
        f,
        CheckHallPassValidityError.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    CheckHallPassValidityResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * repeated CheckHallPassValidityError errors = 1;
   * @return {!Array<!CheckHallPassValidityError>}
   */
  getErrorsList(): CheckHallPassValidityError[] {
    return /** @type{!Array<!CheckHallPassValidityError>} */ (
      jspb.Message.getRepeatedWrapperField(this, CheckHallPassValidityError, 1));
  }


  /** @param {!Array<!CheckHallPassValidityError>} value */
  setErrorsList(value?: CheckHallPassValidityError[]) {
    jspb.Message.setRepeatedWrapperField(this, 1, value);
  }


  /**
   * @param {!CheckHallPassValidityError=} opt_value
   * @param {number=} opt_index
   * @return {!CheckHallPassValidityError}
   */
  addErrors(opt_value?: CheckHallPassValidityError, opt_index?: number): CheckHallPassValidityError {
    return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, CheckHallPassValidityError, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearErrorsList() {
    this.setErrorsList([]);
  }


}
export namespace UpdateHallPassBlackOutWindowRequest {
  export interface AsObject {
    blackOutWindowInfo?: HallPassBlackOut.AsObject;
  }
}
export class UpdateHallPassBlackOutWindowRequest extends jspb.Message {
  static readonly displayName = "UpdateHallPassBlackOutWindowRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): UpdateHallPassBlackOutWindowRequest.AsObject {
    return UpdateHallPassBlackOutWindowRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!UpdateHallPassBlackOutWindowRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: UpdateHallPassBlackOutWindowRequest) {
    var f: any;
    var obj: any = {
      blackOutWindowInfo: (f = msg.getBlackOutWindowInfo()) && HallPassBlackOut.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!UpdateHallPassBlackOutWindowRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new UpdateHallPassBlackOutWindowRequest;
    return UpdateHallPassBlackOutWindowRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!UpdateHallPassBlackOutWindowRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!UpdateHallPassBlackOutWindowRequest}
   */
  static deserializeBinaryFromReader(msg: UpdateHallPassBlackOutWindowRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new HallPassBlackOut;
        reader.readMessage(value1,HallPassBlackOut.deserializeBinaryFromReader);
        msg.setBlackOutWindowInfo(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!UpdateHallPassBlackOutWindowRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: UpdateHallPassBlackOutWindowRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getBlackOutWindowInfo();
    if (f != null) {
      writer.writeMessage(
        1,
        f,
        HallPassBlackOut.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    UpdateHallPassBlackOutWindowRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional HallPassBlackOut black_out_window_info = 1;
   * @return {?HallPassBlackOut}
   */
  getBlackOutWindowInfo(): HallPassBlackOut {
    return /** @type{?HallPassBlackOut} */ (
      jspb.Message.getWrapperField(this, HallPassBlackOut, 1));
  }


  /** @param {?HallPassBlackOut|undefined} value */
  setBlackOutWindowInfo(value?: HallPassBlackOut) {
    jspb.Message.setWrapperField(this, 1, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearBlackOutWindowInfo() {
    this.setBlackOutWindowInfo(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasBlackOutWindowInfo(): boolean {
    return jspb.Message.getField(this, 1) != null;
  }


}
export namespace UpdateHallPassBlackOutWindowResponse {
  export interface AsObject {
    blackOutWindowInfo?: HallPassBlackOut.AsObject;
  }
}
export class UpdateHallPassBlackOutWindowResponse extends jspb.Message {
  static readonly displayName = "UpdateHallPassBlackOutWindowResponse";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): UpdateHallPassBlackOutWindowResponse.AsObject {
    return UpdateHallPassBlackOutWindowResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!UpdateHallPassBlackOutWindowResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: UpdateHallPassBlackOutWindowResponse) {
    var f: any;
    var obj: any = {
      blackOutWindowInfo: (f = msg.getBlackOutWindowInfo()) && HallPassBlackOut.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!UpdateHallPassBlackOutWindowResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new UpdateHallPassBlackOutWindowResponse;
    return UpdateHallPassBlackOutWindowResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!UpdateHallPassBlackOutWindowResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!UpdateHallPassBlackOutWindowResponse}
   */
  static deserializeBinaryFromReader(msg: UpdateHallPassBlackOutWindowResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 2:
        var value2 = new HallPassBlackOut;
        reader.readMessage(value2,HallPassBlackOut.deserializeBinaryFromReader);
        msg.setBlackOutWindowInfo(value2);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!UpdateHallPassBlackOutWindowResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: UpdateHallPassBlackOutWindowResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getBlackOutWindowInfo();
    if (f != null) {
      writer.writeMessage(
        2,
        f,
        HallPassBlackOut.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    UpdateHallPassBlackOutWindowResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional HallPassBlackOut black_out_window_info = 2;
   * @return {?HallPassBlackOut}
   */
  getBlackOutWindowInfo(): HallPassBlackOut {
    return /** @type{?HallPassBlackOut} */ (
      jspb.Message.getWrapperField(this, HallPassBlackOut, 2));
  }


  /** @param {?HallPassBlackOut|undefined} value */
  setBlackOutWindowInfo(value?: HallPassBlackOut) {
    jspb.Message.setWrapperField(this, 2, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearBlackOutWindowInfo() {
    this.setBlackOutWindowInfo(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasBlackOutWindowInfo(): boolean {
    return jspb.Message.getField(this, 2) != null;
  }


}
export namespace DeleteHallPassBlackOutWindowRequest {
  export interface AsObject {
    blackOutWindowId: number;
  }
}
export class DeleteHallPassBlackOutWindowRequest extends jspb.Message {
  static readonly displayName = "DeleteHallPassBlackOutWindowRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): DeleteHallPassBlackOutWindowRequest.AsObject {
    return DeleteHallPassBlackOutWindowRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!DeleteHallPassBlackOutWindowRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: DeleteHallPassBlackOutWindowRequest) {
    var f: any;
    var obj: any = {
      blackOutWindowId: jspb.Message.getFieldWithDefault(msg, 1, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!DeleteHallPassBlackOutWindowRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new DeleteHallPassBlackOutWindowRequest;
    return DeleteHallPassBlackOutWindowRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!DeleteHallPassBlackOutWindowRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!DeleteHallPassBlackOutWindowRequest}
   */
  static deserializeBinaryFromReader(msg: DeleteHallPassBlackOutWindowRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {number} */ (reader.readInt32());
        msg.setBlackOutWindowId(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!DeleteHallPassBlackOutWindowRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: DeleteHallPassBlackOutWindowRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getBlackOutWindowId();
    if (f !== 0) {
      writer.writeInt32(
        1,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    DeleteHallPassBlackOutWindowRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional int32 black_out_window_id = 1;
   * @return {number}
   */
  getBlackOutWindowId(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
  };


  /** @param {number} value */
  setBlackOutWindowId(value: number) {
    jspb.Message.setProto3IntField(this, 1, value);
  }


}
export namespace DeleteHallPassBlackOutWindowResponse {
  export interface AsObject {
  }
}
export class DeleteHallPassBlackOutWindowResponse extends jspb.Message {
  static readonly displayName = "DeleteHallPassBlackOutWindowResponse";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): DeleteHallPassBlackOutWindowResponse.AsObject {
    return DeleteHallPassBlackOutWindowResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!DeleteHallPassBlackOutWindowResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: DeleteHallPassBlackOutWindowResponse) {
    var f: any;
    var obj: any = {

    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!DeleteHallPassBlackOutWindowResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new DeleteHallPassBlackOutWindowResponse;
    return DeleteHallPassBlackOutWindowResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!DeleteHallPassBlackOutWindowResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!DeleteHallPassBlackOutWindowResponse}
   */
  static deserializeBinaryFromReader(msg: DeleteHallPassBlackOutWindowResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!DeleteHallPassBlackOutWindowResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: DeleteHallPassBlackOutWindowResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    DeleteHallPassBlackOutWindowResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
}
export namespace GetHallPassBlackOutWindowsRequest {
  export interface AsObject {
  }
}
export class GetHallPassBlackOutWindowsRequest extends jspb.Message {
  static readonly displayName = "GetHallPassBlackOutWindowsRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): GetHallPassBlackOutWindowsRequest.AsObject {
    return GetHallPassBlackOutWindowsRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!GetHallPassBlackOutWindowsRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: GetHallPassBlackOutWindowsRequest) {
    var f: any;
    var obj: any = {

    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!GetHallPassBlackOutWindowsRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new GetHallPassBlackOutWindowsRequest;
    return GetHallPassBlackOutWindowsRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!GetHallPassBlackOutWindowsRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!GetHallPassBlackOutWindowsRequest}
   */
  static deserializeBinaryFromReader(msg: GetHallPassBlackOutWindowsRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!GetHallPassBlackOutWindowsRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: GetHallPassBlackOutWindowsRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    GetHallPassBlackOutWindowsRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
}
export namespace GetHallPassBlackOutWindowsResponse {
  export interface AsObject {
    hallPassBlackOutWindowsList: HallPassBlackOut.AsObject[];
  }
}
export class GetHallPassBlackOutWindowsResponse extends jspb.Message {
  static readonly displayName = "GetHallPassBlackOutWindowsResponse";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [1];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, GetHallPassBlackOutWindowsResponse.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): GetHallPassBlackOutWindowsResponse.AsObject {
    return GetHallPassBlackOutWindowsResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!GetHallPassBlackOutWindowsResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: GetHallPassBlackOutWindowsResponse) {
    var f: any;
    var obj: any = {
      hallPassBlackOutWindowsList: jspb.Message.toObjectList(msg.getHallPassBlackOutWindowsList(),
      HallPassBlackOut.toObject, includeInstance)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!GetHallPassBlackOutWindowsResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new GetHallPassBlackOutWindowsResponse;
    return GetHallPassBlackOutWindowsResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!GetHallPassBlackOutWindowsResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!GetHallPassBlackOutWindowsResponse}
   */
  static deserializeBinaryFromReader(msg: GetHallPassBlackOutWindowsResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new HallPassBlackOut;
        reader.readMessage(value1,HallPassBlackOut.deserializeBinaryFromReader);
        msg.addHallPassBlackOutWindows(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!GetHallPassBlackOutWindowsResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: GetHallPassBlackOutWindowsResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getHallPassBlackOutWindowsList();
    if (f.length > 0) {
      writer.writeRepeatedMessage(
        1,
        f,
        HallPassBlackOut.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    GetHallPassBlackOutWindowsResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * repeated HallPassBlackOut hall_pass_black_out_windows = 1;
   * @return {!Array<!HallPassBlackOut>}
   */
  getHallPassBlackOutWindowsList(): HallPassBlackOut[] {
    return /** @type{!Array<!HallPassBlackOut>} */ (
      jspb.Message.getRepeatedWrapperField(this, HallPassBlackOut, 1));
  }


  /** @param {!Array<!HallPassBlackOut>} value */
  setHallPassBlackOutWindowsList(value?: HallPassBlackOut[]) {
    jspb.Message.setRepeatedWrapperField(this, 1, value);
  }


  /**
   * @param {!HallPassBlackOut=} opt_value
   * @param {number=} opt_index
   * @return {!HallPassBlackOut}
   */
  addHallPassBlackOutWindows(opt_value?: HallPassBlackOut, opt_index?: number): HallPassBlackOut {
    return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, HallPassBlackOut, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearHallPassBlackOutWindowsList() {
    this.setHallPassBlackOutWindowsList([]);
  }


}
export namespace UpdateHallPassRequest {
  export interface AsObject {
    hallPass?: EditableHallPass.AsObject;
  }
}
export class UpdateHallPassRequest extends jspb.Message {
  static readonly displayName = "UpdateHallPassRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): UpdateHallPassRequest.AsObject {
    return UpdateHallPassRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!UpdateHallPassRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: UpdateHallPassRequest) {
    var f: any;
    var obj: any = {
      hallPass: (f = msg.getHallPass()) && EditableHallPass.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!UpdateHallPassRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new UpdateHallPassRequest;
    return UpdateHallPassRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!UpdateHallPassRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!UpdateHallPassRequest}
   */
  static deserializeBinaryFromReader(msg: UpdateHallPassRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new EditableHallPass;
        reader.readMessage(value1,EditableHallPass.deserializeBinaryFromReader);
        msg.setHallPass(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!UpdateHallPassRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: UpdateHallPassRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getHallPass();
    if (f != null) {
      writer.writeMessage(
        1,
        f,
        EditableHallPass.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    UpdateHallPassRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional EditableHallPass hall_pass = 1;
   * @return {?EditableHallPass}
   */
  getHallPass(): EditableHallPass {
    return /** @type{?EditableHallPass} */ (
      jspb.Message.getWrapperField(this, EditableHallPass, 1));
  }


  /** @param {?EditableHallPass|undefined} value */
  setHallPass(value?: EditableHallPass) {
    jspb.Message.setWrapperField(this, 1, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearHallPass() {
    this.setHallPass(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasHallPass(): boolean {
    return jspb.Message.getField(this, 1) != null;
  }


}
export namespace UpdateHallPassResponse {
  export interface AsObject {
    hallPass?: EditableHallPass.AsObject;
  }
}
export class UpdateHallPassResponse extends jspb.Message {
  static readonly displayName = "UpdateHallPassResponse";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): UpdateHallPassResponse.AsObject {
    return UpdateHallPassResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!UpdateHallPassResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: UpdateHallPassResponse) {
    var f: any;
    var obj: any = {
      hallPass: (f = msg.getHallPass()) && EditableHallPass.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!UpdateHallPassResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new UpdateHallPassResponse;
    return UpdateHallPassResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!UpdateHallPassResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!UpdateHallPassResponse}
   */
  static deserializeBinaryFromReader(msg: UpdateHallPassResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 2:
        var value2 = new EditableHallPass;
        reader.readMessage(value2,EditableHallPass.deserializeBinaryFromReader);
        msg.setHallPass(value2);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!UpdateHallPassResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: UpdateHallPassResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getHallPass();
    if (f != null) {
      writer.writeMessage(
        2,
        f,
        EditableHallPass.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    UpdateHallPassResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional EditableHallPass hall_pass = 2;
   * @return {?EditableHallPass}
   */
  getHallPass(): EditableHallPass {
    return /** @type{?EditableHallPass} */ (
      jspb.Message.getWrapperField(this, EditableHallPass, 2));
  }


  /** @param {?EditableHallPass|undefined} value */
  setHallPass(value?: EditableHallPass) {
    jspb.Message.setWrapperField(this, 2, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearHallPass() {
    this.setHallPass(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasHallPass(): boolean {
    return jspb.Message.getField(this, 2) != null;
  }


}
export namespace ArchiveHallPassRequest {
  export interface AsObject {
    idsList: number[];
  }
}
export class ArchiveHallPassRequest extends jspb.Message {
  static readonly displayName = "ArchiveHallPassRequest";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [1];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, ArchiveHallPassRequest.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): ArchiveHallPassRequest.AsObject {
    return ArchiveHallPassRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!ArchiveHallPassRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: ArchiveHallPassRequest) {
    var f: any;
    var obj: any = {
      idsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!ArchiveHallPassRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new ArchiveHallPassRequest;
    return ArchiveHallPassRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!ArchiveHallPassRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!ArchiveHallPassRequest}
   */
  static deserializeBinaryFromReader(msg: ArchiveHallPassRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {!Array<number>} */ (reader.readPackedInt32());
        msg.setIdsList(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!ArchiveHallPassRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: ArchiveHallPassRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getIdsList();
    if (f.length > 0) {
      writer.writePackedInt32(
        1,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    ArchiveHallPassRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * repeated int32 ids = 1;
   * @return {!Array<number>}
   */
  getIdsList(): number[] {
    return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 1));
  };


  /** @param {!Array<number>} value */
  setIdsList(value: number[]) {
    jspb.Message.setField(this, 1, value || []);
  }


  /**
   * @param {number} value
   * @param {number=} opt_index
   */
  addIds(value: number, opt_index?: number): void {
    jspb.Message.addToRepeatedField(this, 1, value, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearIdsList() {
    this.setIdsList([]);
  }


}
export namespace ArchiveHallPassResponse {
  export interface AsObject {
  }
}
export class ArchiveHallPassResponse extends jspb.Message {
  static readonly displayName = "ArchiveHallPassResponse";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): ArchiveHallPassResponse.AsObject {
    return ArchiveHallPassResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!ArchiveHallPassResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: ArchiveHallPassResponse) {
    var f: any;
    var obj: any = {

    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!ArchiveHallPassResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new ArchiveHallPassResponse;
    return ArchiveHallPassResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!ArchiveHallPassResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!ArchiveHallPassResponse}
   */
  static deserializeBinaryFromReader(msg: ArchiveHallPassResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!ArchiveHallPassResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: ArchiveHallPassResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    ArchiveHallPassResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
}
