<section
  class="filters-wrap tw-flex tw-flex-column md:tw-flex-row tw-gap-3 tw-w-full tw-flex-wrap block-assignment-input">
  <mg-user-list-filter
    *ngIf="userListOptions$ | async as userListOptions"
    class="input user-list-input"
    [id]="'tools-myclass-click-filter-list'"
    [value]="form.get(FORM_FIELDS.LIST_FILTER).value"
    [placeholder]="MESSAGES.FORM_LABEL_LIST"
    (selectChanged)="onUserListChange($event)"
    [disableWrapping]="true"
    [layout]="(this.mediaService.isMobileView$ | async) ? 'mobile' : 'desktop'"
    [options]="userListOptions"
    returnMode="full">
  </mg-user-list-filter>
  <mg-form-text-input
    class="input search-input"
    type="text"
    iconLeft="search"
    [id]="'tools-myclass-input-search-name'"
    [loading]="searchingAllStudents"
    [control]="form.get(FORM_FIELDS.SEARCH_TEXT_FILTER)"
    [label]="MESSAGES.FORM_LABEL_TEXT_SEARCH"
    [isClearable]="true"
    [isDisabled]="form.get(FORM_FIELDS.LIST_FILTER).value?.length === 0">
  </mg-form-text-input>
  <div class="toggles tw-flex tw-flex-row tw-items-center tw-gap-2 tw-w-full">
    <mg-chip
      [disabled]="LOCKED_STATES | includes: formState"
      [id]="'tools-myclass-click-all'"
      [active]="
        totalNumberOfInClassStudents !== 0 &&
        totalNumberOfInClassStudents === selectedNumberOfStudents
      "
      [disabled]="totalNumberOfInClassStudents === 0"
      (click)="onToggleSelectAll()">
      {{
        totalNumberOfInClassStudents !== 0 &&
        totalNumberOfInClassStudents === selectedNumberOfStudents
          ? MESSAGES.FORM_LABEL_UNSELECT_ALL
          : MESSAGES.FORM_LABEL_SELECT_ALL
      }}
    </mg-chip>
    <mg-chip
      [ngClass]="{ randomizing: formState === 'randomizing' }"
      [id]="'tools-myclass-click-random'"
      [disabled]="
        totalNumberOfInClassStudents === 0 ||
        (LOCKED_STATES | includes: formState)
      "
      (click)="onSelectRandom()">
      {{
        formState === 'randomizing'
          ? MESSAGES.FORM_LABEL_SELECT_RANDOMIZING
          : MESSAGES.FORM_LABEL_SELECT_RANDOM
      }}
    </mg-chip>
    <div class="settings">
      <mg-btn
        variant="icon-secondary"
        iconSet="minga"
        icon="mg-minga-menu-o"
        id="tools-myclass-click-settings"
        [matMenuTriggerFor]="settingsMenu">
      </mg-btn>
      <mat-menu
        #settingsMenu="matMenu"
        xPosition="before">
        <div
          class="settings-menu tw-flex tw-flex-column tw-gap-2 tw-p-3"
          (click)="$event.stopPropagation()">
          <!-- List view still needs some UI decisions ironed out so hiding the setting for now -->
          <!-- <div
            class="tw-flex tw-flex-row tw-w-full tw-justify-between tw-items-center">
            <mg-text variant="body-sm">
              {{ MESSAGES.SETTINGS_PAGE_LAYOUT }}
            </mg-text>
            <div>
              <mg-btn
                variant="icon"
                iconSet="minga"
                icon="mg-grid-view"
                id="tools-myclass-click-grid-view"
                [active]="form.get(FORM_FIELDS.PAGE_LAYOUT).value === 'grid'"
                (pressed)="
                  form.get(FORM_FIELDS.PAGE_LAYOUT).setValue('grid')
                "></mg-btn>
              <mg-btn
                variant="icon"
                iconSet="minga"
                icon="mg-table-rows"
                id="tools-myclass-click-list-view"
                [active]="form.get(FORM_FIELDS.PAGE_LAYOUT).value === 'list'"
                (pressed)="
                  form.get(FORM_FIELDS.PAGE_LAYOUT).setValue('list')
                "></mg-btn>
            </div>
          </div> -->

          <div
            class="tw-flex tw-flex-row tw-w-full tw-justify-between tw-items-center">
            <mg-text variant="body-sm">
              {{ MESSAGES.SETTINGS_SORT_NAME }}
            </mg-text>
            <!-- need to use inverse value here since the label was inverse before and we save this to local storage so need to worry about b/c -->
            <mg-form-toggle
              [value]="!form.get(FORM_FIELDS.SORT_FIRST_NAME_FILTER).value"
              (valueChange)="
                form.get(FORM_FIELDS.SORT_FIRST_NAME_FILTER).setValue(!$event)
              "></mg-form-toggle>
          </div>
          <div
            class="tw-flex tw-flex-row tw-w-full tw-justify-between tw-items-center">
            <mg-text variant="body-sm">
              {{ MESSAGES.SETTINGS_DISPLAY_AVATAR }}
            </mg-text>
            <!-- need to use inverse value here since the label was inverse before and we save this to local storage so need to worry about b/c -->
            <mg-form-toggle
              [value]="!form.get(FORM_FIELDS.HIDE_PICTURE_FILTER).value"
              (valueChange)="
                form.get(FORM_FIELDS.HIDE_PICTURE_FILTER).setValue(!$event)
              "></mg-form-toggle>
          </div>

          <div
            class="tw-flex tw-flex-row tw-w-full tw-justify-between tw-items-center">
            <div class="tw-flex tw-flex-row tw-items-center">
              <mg-text variant="body-sm">
                {{ MESSAGES.SETTINGS_SOUND }}
              </mg-text>
              <mg-tooltip [label]="MESSAGES.SETTINGS_SOUND_TOOLTIP">
              </mg-tooltip>
            </div>
            <mg-form-toggle
              id="tools-myclass-click-sound"
              [value]="form.get(FORM_FIELDS.ENABLED_SOUND).value"
              (valueChange)="
                form.get(FORM_FIELDS.ENABLED_SOUND).setValue($event)
              "></mg-form-toggle>
          </div>
        </div>
      </mat-menu>
    </div>
  </div>
</section>
