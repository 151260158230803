import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { Subject } from 'rxjs';

import { IContentInfoDraftContext } from '@app/src/app/content/public_interfaces';
import { RootService } from '@app/src/app/minimal/services/RootService';

export enum ContentCreationStatus {
  // Creation succeeded
  SUCCESS = 'SUCCESS',

  // User aborted content creation process.
  ABORTED = 'ABORTED',

  UNKNOWN = 'UNKNOWN',
}

export interface IContentCreationResult {
  status: ContentCreationStatus;
  contentHash: string;
}

@Injectable({ providedIn: 'root' })
export class ContentBuilderService {
  private _submitSubjects: Map<any, Subject<any>>;

  constructor(private router: Router, private rootService: RootService) {
    this._submitSubjects = new Map();
  }

  async challengeNavEdit(
    contentHash: string,
    context?: IContentInfoDraftContext,
  ) {
    const queryParams: any = {};
    const prePath = [];
    const postPath = [];

    if (context) {
      switch (context.type) {
        case 'minga':
          prePath.push('m');
          break;
        case 'program':
          prePath.push('p');
          break;
        case 'group':
          prePath.push('g');
          break;
      }

      if (context.hash) {
        postPath.push(context.hash);
      }
      // make sure post gets proper group context set when editing.
      if (context.ownerGroupHash) {
        prePath.push(context.ownerGroupHash);
      }
    }

    await this.router.navigate(
      ['challenge', ...prePath, 'edit', 'content', contentHash, ...postPath],
      {
        queryParams,
      },
    );
    await this.router.navigate(['', { outlets: { o: null } }], {
      queryParams,
    });
  }

  async navEdit(
    type: string,
    contentHash: string,
    context?: IContentInfoDraftContext,
    mingaDesignerContent: boolean = false,
  ): Promise<boolean> {
    const queryParams: any = {};
    const prePath = [];
    const postPath = [];
    let parentPath = '';

    if (context) {
      switch (context.type) {
        case 'minga':
          prePath.push('m');
          break;
        case 'program':
          prePath.push('p');
          break;
        case 'group':
          prePath.push('g');
          break;
      }

      if (context.hash) {
        postPath.push(context.hash);
      }
      // make sure post gets proper group context set when editing.
      if (context.ownerGroupHash) {
        parentPath = '/groups/view/' + context.ownerGroupHash;
      }
    }

    if (mingaDesignerContent) {
      await this.router.navigate([parentPath]);
      return this.router.navigate([
        '',
        {
          outlets: {
            o: [
              'studio',
              ...prePath,
              'edit',
              'build',
              contentHash,
              ...postPath,
            ],
          },
        },
      ]);
    }

    const navResult = await this.router.navigate(
      [
        '',
        {
          outlets: {
            o: ['content', ...prePath, 'edit', type, contentHash, ...postPath],
          },
        },
      ],
      {
        queryParams,
      },
    );

    return navResult;
  }

  private async _navCreate(
    type: string,
    context?: IContentInfoDraftContext,
  ): Promise<boolean> {
    const queryParams: any = {};
    const prePath = [];
    const postPath = [];

    if (context) {
      switch (context.type) {
        case 'minga':
          prePath.push('m');
          break;
        case 'program':
          prePath.push('p');
          break;
        case 'group':
          prePath.push('g');
          break;
      }

      if (context.hash) {
        postPath.push(context.hash);
      }
    }

    const navResult = await this.router.navigate(
      [
        '',
        {
          outlets: { o: ['content', ...prePath, 'create', type, ...postPath] },
        },
      ],
      {
        queryParams,
      },
    );

    return navResult;
  }

  private _ensureSubmitSubject(
    type: string,
    context: IContentInfoDraftContext | null,
  ) {
    const key = JSON.stringify({ type, context });

    if (!this._submitSubjects.has(key)) {
      this._submitSubjects.set(key, new Subject());
    }

    return this._submitSubjects.get(key);
  }

  emitSubmitDone(
    type: string,
    context: IContentInfoDraftContext | null,
    contentHash: string,
  ) {
    const subject = this._ensureSubmitSubject(type, context);
    subject.next(contentHash);
  }

  emitDraftExit() {
    // const subject = this._ensureSubmitSubject(type, context);
    // subject.next(false);
  }

  private _waitForSubmitDone(
    type: string,
    context?: IContentInfoDraftContext,
  ): Promise<string> {
    const subject = this._ensureSubmitSubject(type, context);

    return new Promise((resolve, reject) => {
      const sub = subject.subscribe(
        () => {
          sub.unsubscribe();
          resolve(null);
        },
        err => {
          reject(err);
        },
      );
    });
  }

  private async _create(type: string, context?: IContentInfoDraftContext) {
    const navResult = await this._navCreate(type, context);

    if (!navResult) {
      return {
        status: ContentCreationStatus.UNKNOWN,
        contentHash: '',
      };
    }

    const waitResult = await this._waitForSubmitDone(type, context);

    if (!waitResult) {
      return {
        status: ContentCreationStatus.ABORTED,
        contentHash: '',
      };
    }

    return {
      status: ContentCreationStatus.SUCCESS,
      contentHash: waitResult,
    };
  }

  async createFullGraphic(
    context?: IContentInfoDraftContext,
  ): Promise<IContentCreationResult> {
    return this._create('fullGraphic', context);
  }

  async createPoll(
    context?: IContentInfoDraftContext,
  ): Promise<IContentCreationResult> {
    return this._create('poll', context);
  }

  async createPost(
    context?: IContentInfoDraftContext,
  ): Promise<IContentCreationResult> {
    return this._create('post', context);
  }

  async createVideo(context?: IContentInfoDraftContext) {
    await this._create('video', context);
  }

  async createAnnouncement(context?: IContentInfoDraftContext) {
    await this._create('announcement', context);
  }

  async createEvent(context?: IContentInfoDraftContext) {
    await this._create('event', context);
  }

  async openCreateContextMenu(
    x: number,
    y: number,
    context?: IContentInfoDraftContext,
  ) {
    const contextMenuItems = await this.getCreateContextMenuItems(context);

    let openPromise: Promise<any> | null = null;

    await this.rootService.openGlobalMenu({
      x,
      y,
      items: contextMenuItems.map(item => {
        return {
          name: item.title,
          click: () => (openPromise = item.open()),
        };
      }),
    });

    if (openPromise != null) {
      await openPromise;
    }
  }

  async getCreateContextMenuItems(
    context?: IContentInfoDraftContext,
  ): Promise<any[]> {
    return [
      {
        title: 'Post',
        icon: 'post',
        open: () => this.createPost(context),
      },
      {
        title: 'Video',
        icon: 'video',
        open: () => this.createVideo(context),
      },
      {
        title: 'Event',
        icon: 'calendar',
        open: () => this.createEvent(context),
      },
      {
        title: 'Announcement',
        icon: 'post',
        open: () => this.createAnnouncement(context),
      },
      {
        title: 'Full Graphic',
        icon: 'post',
        open: () => this.createFullGraphic(context),
      },
      {
        title: 'Poll',
        icon: 'poll',
        open: () => this.createPoll(context),
      },
    ];
  }
}
