import { CreateUserListPayload } from './UserList.types';

export const DEFAULT_USER_LIST: CreateUserListPayload = {
  uuid: null,
  termSourceIds: [],
  periodSourceIds: [],
  public: false,
  active: true,
  description: '',
  title: 'New User List',
  managerListId: undefined,
  isHomeroom: false,
  location: '',
};
