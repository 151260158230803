<mg-sr-filters-wrap
  [form]="form"
  [filters]="filters$ | async">
  <ng-container *ngIf="form.get(PARENT_FIELDS.REPORT_FILTERS)">
    <div
      *ngIf="
        (defaultValues$ | async).reportType !== REPORT_TYPES.FLEX_ACTIVITIES
      ">
      <mg-multi-person-search
        *ngIf="form.get(getFilterGroupControlKey(FIELDS.REGISTRANT))"
        (selectionChanged)="onPersonSelected($event)">
      </mg-multi-person-search>
    </div>

    <div
      *ngIf="
        (defaultValues$ | async).reportType ===
        REPORT_TYPES.FLEX_PERIOD_REGISTERED
      ">
      <mg-form-select
        [placeholder]="MESSAGES.SELECT_CHECKIN_STATUS"
        [value]="
          form.get(getFilterGroupControlKey(FIELDS.CHECKIN_STATUS)).value
        "
        [multiple]="false"
        [searchable]="true"
        [isFullWidth]="true"
        [control]="form.get(getFilterGroupControlKey(FIELDS.CHECKIN_STATUS))"
        [options]="checkinStatusOptions$ | async">
      </mg-form-select>
    </div>

    <mg-form-select
      [placeholder]="MESSAGES.SELECT_ACTIVITY"
      [value]="form.get(getFilterGroupControlKey(FIELDS.ACTIVITIES)).value"
      [multiple]="true"
      [searchable]="true"
      [isFullWidth]="true"
      [control]="form.get(getFilterGroupControlKey(FIELDS.ACTIVITIES))"
      [options]="activityOptions$ | async">
    </mg-form-select>

    <div
      *ngIf="
        (defaultValues$ | async).reportType !== REPORT_TYPES.FLEX_ACTIVITIES
      ">
      <mg-user-list-filter
        [prefetchData]="[USER_LIST_CATEGORY.ALL]"
        [placeholder]="MESSAGES.SELECT_LABEL_USER_LIST"
        [value]="form.get(getFilterGroupControlKey(FIELDS.USER_LIST)).value"
        (selectChanged)="
          form.get(getFilterGroupControlKey(FIELDS.USER_LIST)).setValue($event)
        ">
      </mg-user-list-filter>
    </div>
  </ng-container>
</mg-sr-filters-wrap>
