import { ITeamPointsSummaryResult } from 'libs/domain';
import { points_pb } from 'libs/generated-grpc-ts';

export namespace ITeamPointsSummaryItemMapper {
  export function toProto(
    result: ITeamPointsSummaryResult,
  ): points_pb.TeamPointsSummaryItem {
    const item = new points_pb.TeamPointsSummaryItem();
    if (result.team) {
      const team = new points_pb.PointsHistoryItemTeam();
      if (result.team.id) team.setId(result.team.id);
      if (result.team.name) team.setName(result.team.name);
      item.setTeam(team);
    }
    if (result.totalPoints) {
      item.setTotalPoints(result.totalPoints);
    }
    return item;
  }

  export function fromProto(
    msg: points_pb.TeamPointsSummaryItem,
  ): ITeamPointsSummaryResult {
    const team = msg.getTeam();
    const id = team?.getId();
    const name = team?.getName();
    return {
      team: {
        id,
        name,
      },
      totalPoints: msg.getTotalPoints(),
    };
  }
}
