export enum HallPassBlackOutWeekdaysEnum {
  EVERYDAY = 'EVERYDAY',
  MONDAY = 'MONDAY',
  TUESDAY = 'TUESDAY',
  WEDNESDAY = 'WEDNESDAY',
  THURSDAY = 'THURSDAY',
  FRIDAY = 'FRIDAY',
}

export function hallPassBlackOutWeekdaysEnumToPrettyName(
  weekday: HallPassBlackOutWeekdaysEnum,
): string {
  switch (weekday) {
    case HallPassBlackOutWeekdaysEnum.EVERYDAY:
      return 'Everyday';
    case HallPassBlackOutWeekdaysEnum.MONDAY:
      return 'Monday';
    case HallPassBlackOutWeekdaysEnum.TUESDAY:
      return 'Tuesday';
    case HallPassBlackOutWeekdaysEnum.WEDNESDAY:
      return 'Wednesday';
    case HallPassBlackOutWeekdaysEnum.THURSDAY:
      return 'Thursday';
    case HallPassBlackOutWeekdaysEnum.FRIDAY:
      return 'Friday';
    default:
      console.error('Error: Hall Pass Blackout Weekday not recognized');
      return '';
  }
}
