// source: gateway/mingaGroup.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

import * as jspb from 'google-protobuf';
import * as gateway_moderation_pb from '../gateway/moderation_pb';
import * as image_image_pb from '../image/image_pb';
import * as gateway_person_view_pb from '../gateway/person_view_pb';
import * as gateway_banner_pb from '../gateway/banner_pb';
import * as gateway_file_pb from '../gateway/file_pb';
export namespace GroupInputs {
  export interface AsObject {
    name: string;
    description: string;
    private: boolean;
    bannerHash: string;
    bannerAsset: string;
    ownerPersonHash: string;
    categoryId: number;
    allowedRoleTypesList: string[];
    parentGroupHash: string;
    allowedCommentingRoleTypesList: string[];
  }
}
export class GroupInputs extends jspb.Message {
  static readonly displayName = "GroupInputs";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [9,11];

  /**
   * Oneof group definitions for this message. Each group defines the field
   * numbers belonging to that group. When of these fields' value is set, all
   * other fields in the group are cleared. During deserialization, if multiple
   * fields are encountered for a group, only the last value seen will be kept.
   * @private {!Array<!Array<number>>}
   * @const
   */
  static readonly oneofGroups_ = [[5,6]];


  /**
   * @return {GroupInputs.BannerCase}
   */
  getBannerCase() {
    return /** @type {GroupInputs.BannerCase} */(jspb.Message.computeOneofCase(this, GroupInputs.oneofGroups_[0]));
  }

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, GroupInputs.repeatedFields_, GroupInputs.oneofGroups_);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): GroupInputs.AsObject {
    return GroupInputs.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!GroupInputs} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: GroupInputs) {
    var f: any;
    var obj: any = {
      name: jspb.Message.getFieldWithDefault(msg, 2, ""),
      description: jspb.Message.getFieldWithDefault(msg, 3, ""),
      pb_private: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
      bannerHash: jspb.Message.getFieldWithDefault(msg, 5, ""),
      bannerAsset: jspb.Message.getFieldWithDefault(msg, 6, ""),
      ownerPersonHash: jspb.Message.getFieldWithDefault(msg, 7, ""),
      categoryId: jspb.Message.getFieldWithDefault(msg, 8, 0),
      allowedRoleTypesList: (f = jspb.Message.getRepeatedField(msg, 9)) == null ? undefined : f,
      parentGroupHash: jspb.Message.getFieldWithDefault(msg, 10, ""),
      allowedCommentingRoleTypesList: (f = jspb.Message.getRepeatedField(msg, 11)) == null ? undefined : f
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!GroupInputs}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new GroupInputs;
    return GroupInputs.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!GroupInputs} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!GroupInputs}
   */
  static deserializeBinaryFromReader(msg: GroupInputs, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 2:
        var value2 = /** @type {string} */ (reader.readString());
        msg.setName(value2);
        break;
      case 3:
        var value3 = /** @type {string} */ (reader.readString());
        msg.setDescription(value3);
        break;
      case 4:
        var value4 = /** @type {boolean} */ (reader.readBool());
        msg.setPrivate(value4);
        break;
      case 5:
        var value5 = /** @type {string} */ (reader.readString());
        msg.setBannerHash(value5);
        break;
      case 6:
        var value6 = /** @type {string} */ (reader.readString());
        msg.setBannerAsset(value6);
        break;
      case 7:
        var value7 = /** @type {string} */ (reader.readString());
        msg.setOwnerPersonHash(value7);
        break;
      case 8:
        var value8 = /** @type {number} */ (reader.readInt32());
        msg.setCategoryId(value8);
        break;
      case 9:
        var value9 = /** @type {string} */ (reader.readString());
        msg.addAllowedRoleTypes(value9);
        break;
      case 10:
        var value10 = /** @type {string} */ (reader.readString());
        msg.setParentGroupHash(value10);
        break;
      case 11:
        var value11 = /** @type {string} */ (reader.readString());
        msg.addAllowedCommentingRoleTypes(value11);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!GroupInputs} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: GroupInputs, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getName();
    if (f.length > 0) {
      writer.writeString(
        2,
        f
      );
    }
    f = message.getDescription();
    if (f.length > 0) {
      writer.writeString(
        3,
        f
      );
    }
    f = message.getPrivate();
    if (f) {
      writer.writeBool(
        4,
        f
      );
    }
    f = /** @type {string} */ (jspb.Message.getField(message, 5));
    if (f != null) {
      writer.writeString(
        5,
        f
      );
    }
    f = /** @type {string} */ (jspb.Message.getField(message, 6));
    if (f != null) {
      writer.writeString(
        6,
        f
      );
    }
    f = message.getOwnerPersonHash();
    if (f.length > 0) {
      writer.writeString(
        7,
        f
      );
    }
    f = message.getCategoryId();
    if (f !== 0) {
      writer.writeInt32(
        8,
        f
      );
    }
    f = message.getAllowedRoleTypesList();
    if (f.length > 0) {
      writer.writeRepeatedString(
        9,
        f
      );
    }
    f = message.getParentGroupHash();
    if (f.length > 0) {
      writer.writeString(
        10,
        f
      );
    }
    f = message.getAllowedCommentingRoleTypesList();
    if (f.length > 0) {
      writer.writeRepeatedString(
        11,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    GroupInputs.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string name = 2;
   * @return {string}
   */
  getName(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
  };


  /** @param {string} value */
  setName(value: string) {
    jspb.Message.setProto3StringField(this, 2, value);
  }


  /**
   * optional string description = 3;
   * @return {string}
   */
  getDescription(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
  };


  /** @param {string} value */
  setDescription(value: string) {
    jspb.Message.setProto3StringField(this, 3, value);
  }


  /**
   * optional bool private = 4;
   * @return {boolean}
   */
  getPrivate(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
  };


  /** @param {boolean} value */
  setPrivate(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 4, value);
  }


  /**
   * optional string banner_hash = 5;
   * @return {string}
   */
  getBannerHash(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
  };


  /** @param {string} value */
  setBannerHash(value: string) {
    jspb.Message.setOneofField(this, 5, GroupInputs.oneofGroups_[0], value);
  }


  /**
   * Clears the field making it undefined.
   */
  clearBannerHash() {
    jspb.Message.setOneofField(this, 5, GroupInputs.oneofGroups_[0], undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasBannerHash(): boolean {
    return jspb.Message.getField(this, 5) != null;
  }


  /**
   * optional string banner_asset = 6;
   * @return {string}
   */
  getBannerAsset(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
  };


  /** @param {string} value */
  setBannerAsset(value: string) {
    jspb.Message.setOneofField(this, 6, GroupInputs.oneofGroups_[0], value);
  }


  /**
   * Clears the field making it undefined.
   */
  clearBannerAsset() {
    jspb.Message.setOneofField(this, 6, GroupInputs.oneofGroups_[0], undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasBannerAsset(): boolean {
    return jspb.Message.getField(this, 6) != null;
  }


  /**
   * optional string owner_person_hash = 7;
   * @return {string}
   */
  getOwnerPersonHash(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
  };


  /** @param {string} value */
  setOwnerPersonHash(value: string) {
    jspb.Message.setProto3StringField(this, 7, value);
  }


  /**
   * optional int32 category_id = 8;
   * @return {number}
   */
  getCategoryId(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
  };


  /** @param {number} value */
  setCategoryId(value: number) {
    jspb.Message.setProto3IntField(this, 8, value);
  }


  /**
   * repeated string allowed_role_types = 9;
   * @return {!Array<string>}
   */
  getAllowedRoleTypesList(): string[] {
    return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 9));
  };


  /** @param {!Array<string>} value */
  setAllowedRoleTypesList(value: string[]) {
    jspb.Message.setField(this, 9, value || []);
  }


  /**
   * @param {string} value
   * @param {number=} opt_index
   */
  addAllowedRoleTypes(value: string, opt_index?: number): void {
    jspb.Message.addToRepeatedField(this, 9, value, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearAllowedRoleTypesList() {
    this.setAllowedRoleTypesList([]);
  }


  /**
   * optional string parent_group_hash = 10;
   * @return {string}
   */
  getParentGroupHash(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
  };


  /** @param {string} value */
  setParentGroupHash(value: string) {
    jspb.Message.setProto3StringField(this, 10, value);
  }


  /**
   * repeated string allowed_commenting_role_types = 11;
   * @return {!Array<string>}
   */
  getAllowedCommentingRoleTypesList(): string[] {
    return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 11));
  };


  /** @param {!Array<string>} value */
  setAllowedCommentingRoleTypesList(value: string[]) {
    jspb.Message.setField(this, 11, value || []);
  }


  /**
   * @param {string} value
   * @param {number=} opt_index
   */
  addAllowedCommentingRoleTypes(value: string, opt_index?: number): void {
    jspb.Message.addToRepeatedField(this, 11, value, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearAllowedCommentingRoleTypesList() {
    this.setAllowedCommentingRoleTypesList([]);
  }


}
export namespace GroupInputs {
export enum BannerCase {
  BANNER_NOT_SET = 0,
  BANNER_HASH = 5,
  BANNER_ASSET = 6,
}
} // namespace GroupInputs
export namespace GroupMemberInfo {
  export interface AsObject {
    person?: gateway_person_view_pb.PersonViewMinimal.AsObject;
    rank?: GroupMemberInfo.Rank;
  }
}
export class GroupMemberInfo extends jspb.Message {
  static readonly displayName = "GroupMemberInfo";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): GroupMemberInfo.AsObject {
    return GroupMemberInfo.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!GroupMemberInfo} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: GroupMemberInfo) {
    var f: any;
    var obj: any = {
      person: (f = msg.getPerson()) && gateway_person_view_pb.PersonViewMinimal.toObject(includeInstance, f),
      rank: jspb.Message.getFieldWithDefault(msg, 2, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!GroupMemberInfo}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new GroupMemberInfo;
    return GroupMemberInfo.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!GroupMemberInfo} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!GroupMemberInfo}
   */
  static deserializeBinaryFromReader(msg: GroupMemberInfo, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new gateway_person_view_pb.PersonViewMinimal;
        reader.readMessage(value1,gateway_person_view_pb.PersonViewMinimal.deserializeBinaryFromReader);
        msg.setPerson(value1);
        break;
      case 2:
        var value2 = /** @type {!GroupMemberInfo.Rank} */ (reader.readEnum());
        msg.setRank(value2);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!GroupMemberInfo} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: GroupMemberInfo, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getPerson();
    if (f != null) {
      writer.writeMessage(
        1,
        f,
        gateway_person_view_pb.PersonViewMinimal.serializeBinaryToWriter
      );
    }
    f = message.getRank();
    if (f !== 0.0) {
      writer.writeEnum(
        2,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    GroupMemberInfo.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional PersonViewMinimal person = 1;
   * @return {?PersonViewMinimal}
   */
  getPerson(): gateway_person_view_pb.PersonViewMinimal {
    return /** @type{?PersonViewMinimal} */ (
      jspb.Message.getWrapperField(this, gateway_person_view_pb.PersonViewMinimal, 1));
  }


  /** @param {?PersonViewMinimal|undefined} value */
  setPerson(value?: gateway_person_view_pb.PersonViewMinimal) {
    jspb.Message.setWrapperField(this, 1, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearPerson() {
    this.setPerson(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasPerson(): boolean {
    return jspb.Message.getField(this, 1) != null;
  }


  /**
   * optional Rank rank = 2;
   * @return {!GroupMemberInfo.Rank}
   */
  getRank(): GroupMemberInfo.Rank {
    return /** @type {!GroupMemberInfo.Rank} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
  };


  /** @param {!GroupMemberInfo.Rank} value */
  setRank(value: GroupMemberInfo.Rank) {
    jspb.Message.setProto3EnumField(this, 2, value);
  }


}
export namespace GroupMemberInfo {
export enum Rank {
  PENDING = 0,
  MEMBER = 1,
  OWNER = 2,
}
} // namespace GroupMemberInfo
export namespace Group {
  export interface AsObject {
    hash: string;
    name: string;
    description: string;
    private: boolean;
    bannerHash: string;
    bannerAsset: string;
    bannerObject?: gateway_banner_pb.Banner.AsObject;
    bannerImageInfo?: image_image_pb.ImageInfo.AsObject;
    categoryId: number;
    categoryName: string;
    memberCount: number;
    allowedRoleTypesList: string[];
    isParent: boolean;
    parentGroupHash: string;
    allowedCommentingRoleTypesList: string[];
    currentMemberRank: string;
  }
}
export class Group extends jspb.Message {
  static readonly displayName = "Group";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [12,15];

  /**
   * Oneof group definitions for this message. Each group defines the field
   * numbers belonging to that group. When of these fields' value is set, all
   * other fields in the group are cleared. During deserialization, if multiple
   * fields are encountered for a group, only the last value seen will be kept.
   * @private {!Array<!Array<number>>}
   * @const
   */
  static readonly oneofGroups_ = [[5,6,10]];


  /**
   * @return {Group.BannerCase}
   */
  getBannerCase() {
    return /** @type {Group.BannerCase} */(jspb.Message.computeOneofCase(this, Group.oneofGroups_[0]));
  }

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, Group.repeatedFields_, Group.oneofGroups_);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): Group.AsObject {
    return Group.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!Group} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: Group) {
    var f: any;
    var obj: any = {
      hash: jspb.Message.getFieldWithDefault(msg, 1, ""),
      name: jspb.Message.getFieldWithDefault(msg, 2, ""),
      description: jspb.Message.getFieldWithDefault(msg, 3, ""),
      pb_private: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
      bannerHash: jspb.Message.getFieldWithDefault(msg, 5, ""),
      bannerAsset: jspb.Message.getFieldWithDefault(msg, 6, ""),
      bannerObject: (f = msg.getBannerObject()) && gateway_banner_pb.Banner.toObject(includeInstance, f),
      bannerImageInfo: (f = msg.getBannerImageInfo()) && image_image_pb.ImageInfo.toObject(includeInstance, f),
      categoryId: jspb.Message.getFieldWithDefault(msg, 8, 0),
      categoryName: jspb.Message.getFieldWithDefault(msg, 9, ""),
      memberCount: jspb.Message.getFieldWithDefault(msg, 11, 0),
      allowedRoleTypesList: (f = jspb.Message.getRepeatedField(msg, 12)) == null ? undefined : f,
      isParent: jspb.Message.getBooleanFieldWithDefault(msg, 13, false),
      parentGroupHash: jspb.Message.getFieldWithDefault(msg, 14, ""),
      allowedCommentingRoleTypesList: (f = jspb.Message.getRepeatedField(msg, 15)) == null ? undefined : f,
      currentMemberRank: jspb.Message.getFieldWithDefault(msg, 16, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!Group}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new Group;
    return Group.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!Group} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!Group}
   */
  static deserializeBinaryFromReader(msg: Group, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setHash(value1);
        break;
      case 2:
        var value2 = /** @type {string} */ (reader.readString());
        msg.setName(value2);
        break;
      case 3:
        var value3 = /** @type {string} */ (reader.readString());
        msg.setDescription(value3);
        break;
      case 4:
        var value4 = /** @type {boolean} */ (reader.readBool());
        msg.setPrivate(value4);
        break;
      case 5:
        var value5 = /** @type {string} */ (reader.readString());
        msg.setBannerHash(value5);
        break;
      case 6:
        var value6 = /** @type {string} */ (reader.readString());
        msg.setBannerAsset(value6);
        break;
      case 10:
        var value10 = new gateway_banner_pb.Banner;
        reader.readMessage(value10,gateway_banner_pb.Banner.deserializeBinaryFromReader);
        msg.setBannerObject(value10);
        break;
      case 7:
        var value7 = new image_image_pb.ImageInfo;
        reader.readMessage(value7,image_image_pb.ImageInfo.deserializeBinaryFromReader);
        msg.setBannerImageInfo(value7);
        break;
      case 8:
        var value8 = /** @type {number} */ (reader.readInt32());
        msg.setCategoryId(value8);
        break;
      case 9:
        var value9 = /** @type {string} */ (reader.readString());
        msg.setCategoryName(value9);
        break;
      case 11:
        var value11 = /** @type {number} */ (reader.readInt32());
        msg.setMemberCount(value11);
        break;
      case 12:
        var value12 = /** @type {string} */ (reader.readString());
        msg.addAllowedRoleTypes(value12);
        break;
      case 13:
        var value13 = /** @type {boolean} */ (reader.readBool());
        msg.setIsParent(value13);
        break;
      case 14:
        var value14 = /** @type {string} */ (reader.readString());
        msg.setParentGroupHash(value14);
        break;
      case 15:
        var value15 = /** @type {string} */ (reader.readString());
        msg.addAllowedCommentingRoleTypes(value15);
        break;
      case 16:
        var value16 = /** @type {string} */ (reader.readString());
        msg.setCurrentMemberRank(value16);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!Group} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: Group, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getHash();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
    f = message.getName();
    if (f.length > 0) {
      writer.writeString(
        2,
        f
      );
    }
    f = message.getDescription();
    if (f.length > 0) {
      writer.writeString(
        3,
        f
      );
    }
    f = message.getPrivate();
    if (f) {
      writer.writeBool(
        4,
        f
      );
    }
    f = /** @type {string} */ (jspb.Message.getField(message, 5));
    if (f != null) {
      writer.writeString(
        5,
        f
      );
    }
    f = /** @type {string} */ (jspb.Message.getField(message, 6));
    if (f != null) {
      writer.writeString(
        6,
        f
      );
    }
    f = message.getBannerObject();
    if (f != null) {
      writer.writeMessage(
        10,
        f,
        gateway_banner_pb.Banner.serializeBinaryToWriter
      );
    }
    f = message.getBannerImageInfo();
    if (f != null) {
      writer.writeMessage(
        7,
        f,
        image_image_pb.ImageInfo.serializeBinaryToWriter
      );
    }
    f = message.getCategoryId();
    if (f !== 0) {
      writer.writeInt32(
        8,
        f
      );
    }
    f = message.getCategoryName();
    if (f.length > 0) {
      writer.writeString(
        9,
        f
      );
    }
    f = message.getMemberCount();
    if (f !== 0) {
      writer.writeInt32(
        11,
        f
      );
    }
    f = message.getAllowedRoleTypesList();
    if (f.length > 0) {
      writer.writeRepeatedString(
        12,
        f
      );
    }
    f = message.getIsParent();
    if (f) {
      writer.writeBool(
        13,
        f
      );
    }
    f = message.getParentGroupHash();
    if (f.length > 0) {
      writer.writeString(
        14,
        f
      );
    }
    f = message.getAllowedCommentingRoleTypesList();
    if (f.length > 0) {
      writer.writeRepeatedString(
        15,
        f
      );
    }
    f = message.getCurrentMemberRank();
    if (f.length > 0) {
      writer.writeString(
        16,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    Group.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string hash = 1;
   * @return {string}
   */
  getHash(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setHash(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


  /**
   * optional string name = 2;
   * @return {string}
   */
  getName(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
  };


  /** @param {string} value */
  setName(value: string) {
    jspb.Message.setProto3StringField(this, 2, value);
  }


  /**
   * optional string description = 3;
   * @return {string}
   */
  getDescription(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
  };


  /** @param {string} value */
  setDescription(value: string) {
    jspb.Message.setProto3StringField(this, 3, value);
  }


  /**
   * optional bool private = 4;
   * @return {boolean}
   */
  getPrivate(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
  };


  /** @param {boolean} value */
  setPrivate(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 4, value);
  }


  /**
   * optional string banner_hash = 5;
   * @return {string}
   */
  getBannerHash(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
  };


  /** @param {string} value */
  setBannerHash(value: string) {
    jspb.Message.setOneofField(this, 5, Group.oneofGroups_[0], value);
  }


  /**
   * Clears the field making it undefined.
   */
  clearBannerHash() {
    jspb.Message.setOneofField(this, 5, Group.oneofGroups_[0], undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasBannerHash(): boolean {
    return jspb.Message.getField(this, 5) != null;
  }


  /**
   * optional string banner_asset = 6;
   * @return {string}
   */
  getBannerAsset(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
  };


  /** @param {string} value */
  setBannerAsset(value: string) {
    jspb.Message.setOneofField(this, 6, Group.oneofGroups_[0], value);
  }


  /**
   * Clears the field making it undefined.
   */
  clearBannerAsset() {
    jspb.Message.setOneofField(this, 6, Group.oneofGroups_[0], undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasBannerAsset(): boolean {
    return jspb.Message.getField(this, 6) != null;
  }


  /**
   * optional minga.banner.Banner banner_object = 10;
   * @return {?Banner}
   */
  getBannerObject(): gateway_banner_pb.Banner {
    return /** @type{?Banner} */ (
      jspb.Message.getWrapperField(this, gateway_banner_pb.Banner, 10));
  }


  /** @param {?Banner|undefined} value */
  setBannerObject(value?: gateway_banner_pb.Banner) {
    jspb.Message.setOneofWrapperField(this, 10, Group.oneofGroups_[0], value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearBannerObject() {
    this.setBannerObject(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasBannerObject(): boolean {
    return jspb.Message.getField(this, 10) != null;
  }


  /**
   * optional ImageInfo banner_image_info = 7;
   * @return {?ImageInfo}
   */
  getBannerImageInfo(): image_image_pb.ImageInfo {
    return /** @type{?ImageInfo} */ (
      jspb.Message.getWrapperField(this, image_image_pb.ImageInfo, 7));
  }


  /** @param {?ImageInfo|undefined} value */
  setBannerImageInfo(value?: image_image_pb.ImageInfo) {
    jspb.Message.setWrapperField(this, 7, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearBannerImageInfo() {
    this.setBannerImageInfo(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasBannerImageInfo(): boolean {
    return jspb.Message.getField(this, 7) != null;
  }


  /**
   * optional int32 category_id = 8;
   * @return {number}
   */
  getCategoryId(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
  };


  /** @param {number} value */
  setCategoryId(value: number) {
    jspb.Message.setProto3IntField(this, 8, value);
  }


  /**
   * optional string category_name = 9;
   * @return {string}
   */
  getCategoryName(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
  };


  /** @param {string} value */
  setCategoryName(value: string) {
    jspb.Message.setProto3StringField(this, 9, value);
  }


  /**
   * optional int32 member_count = 11;
   * @return {number}
   */
  getMemberCount(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
  };


  /** @param {number} value */
  setMemberCount(value: number) {
    jspb.Message.setProto3IntField(this, 11, value);
  }


  /**
   * repeated string allowed_role_types = 12;
   * @return {!Array<string>}
   */
  getAllowedRoleTypesList(): string[] {
    return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 12));
  };


  /** @param {!Array<string>} value */
  setAllowedRoleTypesList(value: string[]) {
    jspb.Message.setField(this, 12, value || []);
  }


  /**
   * @param {string} value
   * @param {number=} opt_index
   */
  addAllowedRoleTypes(value: string, opt_index?: number): void {
    jspb.Message.addToRepeatedField(this, 12, value, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearAllowedRoleTypesList() {
    this.setAllowedRoleTypesList([]);
  }


  /**
   * optional bool is_parent = 13;
   * @return {boolean}
   */
  getIsParent(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 13, false));
  };


  /** @param {boolean} value */
  setIsParent(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 13, value);
  }


  /**
   * optional string parent_group_hash = 14;
   * @return {string}
   */
  getParentGroupHash(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
  };


  /** @param {string} value */
  setParentGroupHash(value: string) {
    jspb.Message.setProto3StringField(this, 14, value);
  }


  /**
   * repeated string allowed_commenting_role_types = 15;
   * @return {!Array<string>}
   */
  getAllowedCommentingRoleTypesList(): string[] {
    return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 15));
  };


  /** @param {!Array<string>} value */
  setAllowedCommentingRoleTypesList(value: string[]) {
    jspb.Message.setField(this, 15, value || []);
  }


  /**
   * @param {string} value
   * @param {number=} opt_index
   */
  addAllowedCommentingRoleTypes(value: string, opt_index?: number): void {
    jspb.Message.addToRepeatedField(this, 15, value, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearAllowedCommentingRoleTypesList() {
    this.setAllowedCommentingRoleTypesList([]);
  }


  /**
   * optional string current_member_rank = 16;
   * @return {string}
   */
  getCurrentMemberRank(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
  };


  /** @param {string} value */
  setCurrentMemberRank(value: string) {
    jspb.Message.setProto3StringField(this, 16, value);
  }


}
export namespace Group {
export enum BannerCase {
  BANNER_NOT_SET = 0,
  BANNER_HASH = 5,
  BANNER_ASSET = 6,
  BANNER_OBJECT = 10,
}
} // namespace Group
export namespace GroupDetails {
  export interface AsObject {
    hash: string;
    memberList: GroupMemberInfo.AsObject[];
  }
}
export class GroupDetails extends jspb.Message {
  static readonly displayName = "GroupDetails";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [2];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, GroupDetails.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): GroupDetails.AsObject {
    return GroupDetails.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!GroupDetails} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: GroupDetails) {
    var f: any;
    var obj: any = {
      hash: jspb.Message.getFieldWithDefault(msg, 1, ""),
      memberList: jspb.Message.toObjectList(msg.getMemberList(),
      GroupMemberInfo.toObject, includeInstance)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!GroupDetails}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new GroupDetails;
    return GroupDetails.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!GroupDetails} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!GroupDetails}
   */
  static deserializeBinaryFromReader(msg: GroupDetails, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setHash(value1);
        break;
      case 2:
        var value2 = new GroupMemberInfo;
        reader.readMessage(value2,GroupMemberInfo.deserializeBinaryFromReader);
        msg.addMember(value2);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!GroupDetails} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: GroupDetails, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getHash();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
    f = message.getMemberList();
    if (f.length > 0) {
      writer.writeRepeatedMessage(
        2,
        f,
        GroupMemberInfo.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    GroupDetails.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string hash = 1;
   * @return {string}
   */
  getHash(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setHash(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


  /**
   * repeated GroupMemberInfo member = 2;
   * @return {!Array<!GroupMemberInfo>}
   */
  getMemberList(): GroupMemberInfo[] {
    return /** @type{!Array<!GroupMemberInfo>} */ (
      jspb.Message.getRepeatedWrapperField(this, GroupMemberInfo, 2));
  }


  /** @param {!Array<!GroupMemberInfo>} value */
  setMemberList(value?: GroupMemberInfo[]) {
    jspb.Message.setRepeatedWrapperField(this, 2, value);
  }


  /**
   * @param {!GroupMemberInfo=} opt_value
   * @param {number=} opt_index
   * @return {!GroupMemberInfo}
   */
  addMember(opt_value?: GroupMemberInfo, opt_index?: number): GroupMemberInfo {
    return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, GroupMemberInfo, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearMemberList() {
    this.setMemberList([]);
  }


}
export namespace GroupFiles {
  export interface AsObject {
    hash: string;
    filesList: gateway_file_pb.FileDetails.AsObject[];
  }
}
export class GroupFiles extends jspb.Message {
  static readonly displayName = "GroupFiles";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [2];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, GroupFiles.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): GroupFiles.AsObject {
    return GroupFiles.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!GroupFiles} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: GroupFiles) {
    var f: any;
    var obj: any = {
      hash: jspb.Message.getFieldWithDefault(msg, 1, ""),
      filesList: jspb.Message.toObjectList(msg.getFilesList(),
      gateway_file_pb.FileDetails.toObject, includeInstance)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!GroupFiles}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new GroupFiles;
    return GroupFiles.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!GroupFiles} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!GroupFiles}
   */
  static deserializeBinaryFromReader(msg: GroupFiles, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setHash(value1);
        break;
      case 2:
        var value2 = new gateway_file_pb.FileDetails;
        reader.readMessage(value2,gateway_file_pb.FileDetails.deserializeBinaryFromReader);
        msg.addFiles(value2);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!GroupFiles} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: GroupFiles, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getHash();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
    f = message.getFilesList();
    if (f.length > 0) {
      writer.writeRepeatedMessage(
        2,
        f,
        gateway_file_pb.FileDetails.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    GroupFiles.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string hash = 1;
   * @return {string}
   */
  getHash(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setHash(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


  /**
   * repeated FileDetails files = 2;
   * @return {!Array<!FileDetails>}
   */
  getFilesList(): gateway_file_pb.FileDetails[] {
    return /** @type{!Array<!FileDetails>} */ (
      jspb.Message.getRepeatedWrapperField(this, gateway_file_pb.FileDetails, 2));
  }


  /** @param {!Array<!FileDetails>} value */
  setFilesList(value?: gateway_file_pb.FileDetails[]) {
    jspb.Message.setRepeatedWrapperField(this, 2, value);
  }


  /**
   * @param {!FileDetails=} opt_value
   * @param {number=} opt_index
   * @return {!FileDetails}
   */
  addFiles(opt_value?: gateway_file_pb.FileDetails, opt_index?: number): gateway_file_pb.FileDetails {
    return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, gateway_file_pb.FileDetails, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearFilesList() {
    this.setFilesList([]);
  }


}
export namespace CreateMingaGroupRequest {
  export interface AsObject {
    inputs?: GroupInputs.AsObject;
  }
}
export class CreateMingaGroupRequest extends jspb.Message {
  static readonly displayName = "CreateMingaGroupRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): CreateMingaGroupRequest.AsObject {
    return CreateMingaGroupRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!CreateMingaGroupRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: CreateMingaGroupRequest) {
    var f: any;
    var obj: any = {
      inputs: (f = msg.getInputs()) && GroupInputs.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!CreateMingaGroupRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new CreateMingaGroupRequest;
    return CreateMingaGroupRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!CreateMingaGroupRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!CreateMingaGroupRequest}
   */
  static deserializeBinaryFromReader(msg: CreateMingaGroupRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new GroupInputs;
        reader.readMessage(value1,GroupInputs.deserializeBinaryFromReader);
        msg.setInputs(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!CreateMingaGroupRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: CreateMingaGroupRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getInputs();
    if (f != null) {
      writer.writeMessage(
        1,
        f,
        GroupInputs.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    CreateMingaGroupRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional GroupInputs inputs = 1;
   * @return {?GroupInputs}
   */
  getInputs(): GroupInputs {
    return /** @type{?GroupInputs} */ (
      jspb.Message.getWrapperField(this, GroupInputs, 1));
  }


  /** @param {?GroupInputs|undefined} value */
  setInputs(value?: GroupInputs) {
    jspb.Message.setWrapperField(this, 1, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearInputs() {
    this.setInputs(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasInputs(): boolean {
    return jspb.Message.getField(this, 1) != null;
  }


}
export namespace CreateMingaGroupResponse {
  export interface AsObject {
    group?: Group.AsObject;
    groupDetails?: GroupDetails.AsObject;
    moderationResult?: gateway_moderation_pb.ModerationResult.AsObject;
    contentContextHash: string;
  }
}
export class CreateMingaGroupResponse extends jspb.Message {
  static readonly displayName = "CreateMingaGroupResponse";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): CreateMingaGroupResponse.AsObject {
    return CreateMingaGroupResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!CreateMingaGroupResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: CreateMingaGroupResponse) {
    var f: any;
    var obj: any = {
      group: (f = msg.getGroup()) && Group.toObject(includeInstance, f),
      groupDetails: (f = msg.getGroupDetails()) && GroupDetails.toObject(includeInstance, f),
      moderationResult: (f = msg.getModerationResult()) && gateway_moderation_pb.ModerationResult.toObject(includeInstance, f),
      contentContextHash: jspb.Message.getFieldWithDefault(msg, 4, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!CreateMingaGroupResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new CreateMingaGroupResponse;
    return CreateMingaGroupResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!CreateMingaGroupResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!CreateMingaGroupResponse}
   */
  static deserializeBinaryFromReader(msg: CreateMingaGroupResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new Group;
        reader.readMessage(value1,Group.deserializeBinaryFromReader);
        msg.setGroup(value1);
        break;
      case 2:
        var value2 = new GroupDetails;
        reader.readMessage(value2,GroupDetails.deserializeBinaryFromReader);
        msg.setGroupDetails(value2);
        break;
      case 3:
        var value3 = new gateway_moderation_pb.ModerationResult;
        reader.readMessage(value3,gateway_moderation_pb.ModerationResult.deserializeBinaryFromReader);
        msg.setModerationResult(value3);
        break;
      case 4:
        var value4 = /** @type {string} */ (reader.readString());
        msg.setContentContextHash(value4);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!CreateMingaGroupResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: CreateMingaGroupResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getGroup();
    if (f != null) {
      writer.writeMessage(
        1,
        f,
        Group.serializeBinaryToWriter
      );
    }
    f = message.getGroupDetails();
    if (f != null) {
      writer.writeMessage(
        2,
        f,
        GroupDetails.serializeBinaryToWriter
      );
    }
    f = message.getModerationResult();
    if (f != null) {
      writer.writeMessage(
        3,
        f,
        gateway_moderation_pb.ModerationResult.serializeBinaryToWriter
      );
    }
    f = message.getContentContextHash();
    if (f.length > 0) {
      writer.writeString(
        4,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    CreateMingaGroupResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional Group group = 1;
   * @return {?Group}
   */
  getGroup(): Group {
    return /** @type{?Group} */ (
      jspb.Message.getWrapperField(this, Group, 1));
  }


  /** @param {?Group|undefined} value */
  setGroup(value?: Group) {
    jspb.Message.setWrapperField(this, 1, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearGroup() {
    this.setGroup(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasGroup(): boolean {
    return jspb.Message.getField(this, 1) != null;
  }


  /**
   * optional GroupDetails group_details = 2;
   * @return {?GroupDetails}
   */
  getGroupDetails(): GroupDetails {
    return /** @type{?GroupDetails} */ (
      jspb.Message.getWrapperField(this, GroupDetails, 2));
  }


  /** @param {?GroupDetails|undefined} value */
  setGroupDetails(value?: GroupDetails) {
    jspb.Message.setWrapperField(this, 2, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearGroupDetails() {
    this.setGroupDetails(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasGroupDetails(): boolean {
    return jspb.Message.getField(this, 2) != null;
  }


  /**
   * optional minga.ModerationResult moderation_result = 3;
   * @return {?ModerationResult}
   */
  getModerationResult(): gateway_moderation_pb.ModerationResult {
    return /** @type{?ModerationResult} */ (
      jspb.Message.getWrapperField(this, gateway_moderation_pb.ModerationResult, 3));
  }


  /** @param {?ModerationResult|undefined} value */
  setModerationResult(value?: gateway_moderation_pb.ModerationResult) {
    jspb.Message.setWrapperField(this, 3, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearModerationResult() {
    this.setModerationResult(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasModerationResult(): boolean {
    return jspb.Message.getField(this, 3) != null;
  }


  /**
   * optional string content_context_hash = 4;
   * @return {string}
   */
  getContentContextHash(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
  };


  /** @param {string} value */
  setContentContextHash(value: string) {
    jspb.Message.setProto3StringField(this, 4, value);
  }


}
export namespace UpdateMingaGroupRequest {
  export interface AsObject {
    mingaGroupHash: string;
    inputs?: GroupInputs.AsObject;
  }
}
export class UpdateMingaGroupRequest extends jspb.Message {
  static readonly displayName = "UpdateMingaGroupRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): UpdateMingaGroupRequest.AsObject {
    return UpdateMingaGroupRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!UpdateMingaGroupRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: UpdateMingaGroupRequest) {
    var f: any;
    var obj: any = {
      mingaGroupHash: jspb.Message.getFieldWithDefault(msg, 1, ""),
      inputs: (f = msg.getInputs()) && GroupInputs.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!UpdateMingaGroupRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new UpdateMingaGroupRequest;
    return UpdateMingaGroupRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!UpdateMingaGroupRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!UpdateMingaGroupRequest}
   */
  static deserializeBinaryFromReader(msg: UpdateMingaGroupRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setMingaGroupHash(value1);
        break;
      case 2:
        var value2 = new GroupInputs;
        reader.readMessage(value2,GroupInputs.deserializeBinaryFromReader);
        msg.setInputs(value2);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!UpdateMingaGroupRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: UpdateMingaGroupRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getMingaGroupHash();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
    f = message.getInputs();
    if (f != null) {
      writer.writeMessage(
        2,
        f,
        GroupInputs.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    UpdateMingaGroupRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string minga_group_hash = 1;
   * @return {string}
   */
  getMingaGroupHash(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setMingaGroupHash(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


  /**
   * optional GroupInputs inputs = 2;
   * @return {?GroupInputs}
   */
  getInputs(): GroupInputs {
    return /** @type{?GroupInputs} */ (
      jspb.Message.getWrapperField(this, GroupInputs, 2));
  }


  /** @param {?GroupInputs|undefined} value */
  setInputs(value?: GroupInputs) {
    jspb.Message.setWrapperField(this, 2, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearInputs() {
    this.setInputs(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasInputs(): boolean {
    return jspb.Message.getField(this, 2) != null;
  }


}
export namespace UpdateMingaGroupResponse {
  export interface AsObject {
    group?: Group.AsObject;
    groupDetails?: GroupDetails.AsObject;
    moderationResult?: gateway_moderation_pb.ModerationResult.AsObject;
    contentContextHash: string;
  }
}
export class UpdateMingaGroupResponse extends jspb.Message {
  static readonly displayName = "UpdateMingaGroupResponse";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): UpdateMingaGroupResponse.AsObject {
    return UpdateMingaGroupResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!UpdateMingaGroupResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: UpdateMingaGroupResponse) {
    var f: any;
    var obj: any = {
      group: (f = msg.getGroup()) && Group.toObject(includeInstance, f),
      groupDetails: (f = msg.getGroupDetails()) && GroupDetails.toObject(includeInstance, f),
      moderationResult: (f = msg.getModerationResult()) && gateway_moderation_pb.ModerationResult.toObject(includeInstance, f),
      contentContextHash: jspb.Message.getFieldWithDefault(msg, 4, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!UpdateMingaGroupResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new UpdateMingaGroupResponse;
    return UpdateMingaGroupResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!UpdateMingaGroupResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!UpdateMingaGroupResponse}
   */
  static deserializeBinaryFromReader(msg: UpdateMingaGroupResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new Group;
        reader.readMessage(value1,Group.deserializeBinaryFromReader);
        msg.setGroup(value1);
        break;
      case 2:
        var value2 = new GroupDetails;
        reader.readMessage(value2,GroupDetails.deserializeBinaryFromReader);
        msg.setGroupDetails(value2);
        break;
      case 3:
        var value3 = new gateway_moderation_pb.ModerationResult;
        reader.readMessage(value3,gateway_moderation_pb.ModerationResult.deserializeBinaryFromReader);
        msg.setModerationResult(value3);
        break;
      case 4:
        var value4 = /** @type {string} */ (reader.readString());
        msg.setContentContextHash(value4);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!UpdateMingaGroupResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: UpdateMingaGroupResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getGroup();
    if (f != null) {
      writer.writeMessage(
        1,
        f,
        Group.serializeBinaryToWriter
      );
    }
    f = message.getGroupDetails();
    if (f != null) {
      writer.writeMessage(
        2,
        f,
        GroupDetails.serializeBinaryToWriter
      );
    }
    f = message.getModerationResult();
    if (f != null) {
      writer.writeMessage(
        3,
        f,
        gateway_moderation_pb.ModerationResult.serializeBinaryToWriter
      );
    }
    f = message.getContentContextHash();
    if (f.length > 0) {
      writer.writeString(
        4,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    UpdateMingaGroupResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional Group group = 1;
   * @return {?Group}
   */
  getGroup(): Group {
    return /** @type{?Group} */ (
      jspb.Message.getWrapperField(this, Group, 1));
  }


  /** @param {?Group|undefined} value */
  setGroup(value?: Group) {
    jspb.Message.setWrapperField(this, 1, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearGroup() {
    this.setGroup(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasGroup(): boolean {
    return jspb.Message.getField(this, 1) != null;
  }


  /**
   * optional GroupDetails group_details = 2;
   * @return {?GroupDetails}
   */
  getGroupDetails(): GroupDetails {
    return /** @type{?GroupDetails} */ (
      jspb.Message.getWrapperField(this, GroupDetails, 2));
  }


  /** @param {?GroupDetails|undefined} value */
  setGroupDetails(value?: GroupDetails) {
    jspb.Message.setWrapperField(this, 2, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearGroupDetails() {
    this.setGroupDetails(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasGroupDetails(): boolean {
    return jspb.Message.getField(this, 2) != null;
  }


  /**
   * optional minga.ModerationResult moderation_result = 3;
   * @return {?ModerationResult}
   */
  getModerationResult(): gateway_moderation_pb.ModerationResult {
    return /** @type{?ModerationResult} */ (
      jspb.Message.getWrapperField(this, gateway_moderation_pb.ModerationResult, 3));
  }


  /** @param {?ModerationResult|undefined} value */
  setModerationResult(value?: gateway_moderation_pb.ModerationResult) {
    jspb.Message.setWrapperField(this, 3, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearModerationResult() {
    this.setModerationResult(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasModerationResult(): boolean {
    return jspb.Message.getField(this, 3) != null;
  }


  /**
   * optional string content_context_hash = 4;
   * @return {string}
   */
  getContentContextHash(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
  };


  /** @param {string} value */
  setContentContextHash(value: string) {
    jspb.Message.setProto3StringField(this, 4, value);
  }


}
export namespace GetMingaGroupsRequest {
  export interface AsObject {
  }
}
export class GetMingaGroupsRequest extends jspb.Message {
  static readonly displayName = "GetMingaGroupsRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): GetMingaGroupsRequest.AsObject {
    return GetMingaGroupsRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!GetMingaGroupsRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: GetMingaGroupsRequest) {
    var f: any;
    var obj: any = {

    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!GetMingaGroupsRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new GetMingaGroupsRequest;
    return GetMingaGroupsRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!GetMingaGroupsRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!GetMingaGroupsRequest}
   */
  static deserializeBinaryFromReader(msg: GetMingaGroupsRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!GetMingaGroupsRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: GetMingaGroupsRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    GetMingaGroupsRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
}
export namespace GetMingaGroupsResponse {
  export interface AsObject {
    groupList: Group.AsObject[];
    myGroupsRankMap: [string, GroupMemberInfo.Rank][];
  }
}
export class GetMingaGroupsResponse extends jspb.Message {
  static readonly displayName = "GetMingaGroupsResponse";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [1];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, GetMingaGroupsResponse.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): GetMingaGroupsResponse.AsObject {
    return GetMingaGroupsResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!GetMingaGroupsResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: GetMingaGroupsResponse) {
    var f: any;
    var obj: any = {
      groupList: jspb.Message.toObjectList(msg.getGroupList(),
      Group.toObject, includeInstance),
      myGroupsRankMap: (f = msg.getMyGroupsRankMap()) ? f.toObject(includeInstance, undefined) : []
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!GetMingaGroupsResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new GetMingaGroupsResponse;
    return GetMingaGroupsResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!GetMingaGroupsResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!GetMingaGroupsResponse}
   */
  static deserializeBinaryFromReader(msg: GetMingaGroupsResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new Group;
        reader.readMessage(value1,Group.deserializeBinaryFromReader);
        msg.addGroup(value1);
        break;
      case 2:
        var value2 = msg.getMyGroupsRankMap();
        reader.readMessage(value2, function(message, reader) {
          jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readEnum, null, "");
           });
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!GetMingaGroupsResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: GetMingaGroupsResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getGroupList();
    if (f.length > 0) {
      writer.writeRepeatedMessage(
        1,
        f,
        Group.serializeBinaryToWriter
      );
    }
    f = message.getMyGroupsRankMap(true);
    if (f && f.getLength() > 0) {
      f.serializeBinary(2, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeEnum);
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    GetMingaGroupsResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * repeated Group group = 1;
   * @return {!Array<!Group>}
   */
  getGroupList(): Group[] {
    return /** @type{!Array<!Group>} */ (
      jspb.Message.getRepeatedWrapperField(this, Group, 1));
  }


  /** @param {!Array<!Group>} value */
  setGroupList(value?: Group[]) {
    jspb.Message.setRepeatedWrapperField(this, 1, value);
  }


  /**
   * @param {!Group=} opt_value
   * @param {number=} opt_index
   * @return {!Group}
   */
  addGroup(opt_value?: Group, opt_index?: number): Group {
    return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, Group, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearGroupList() {
    this.setGroupList([]);
  }


  /**
   * map<string, GroupMemberInfo.Rank> my_groups_rank = 2;
   * @param {boolean=} opt_noLazyCreate Do not create the map if
   * empty, instead returning `undefined`
   * @return {!jspb.Map<string,!GroupMemberInfo.Rank>}
   */
  getMyGroupsRankMap(): jspb.Map<string,GroupMemberInfo.Rank>;
  getMyGroupsRankMap(noLazyCreate: true): jspb.Map<string,GroupMemberInfo.Rank>|undefined;
  getMyGroupsRankMap(noLazyCreate: false): jspb.Map<string,GroupMemberInfo.Rank>;
  getMyGroupsRankMap(opt_noLazyCreate: boolean = false): jspb.Map<string,GroupMemberInfo.Rank> {
    return /** @type {!jspb.Map<string,!GroupMemberInfo.Rank>} */ (
        jspb.Message.getMapField(this, 2, opt_noLazyCreate,
        null));
  }


  /**
   * Clears values from the map. The map will be non-null.
   */
  clearMyGroupsRankMap() {
    this.getMyGroupsRankMap().clear();
  }


}
export namespace GetPersonGroupsRequest {
  export interface AsObject {
    personHash: string;
  }
}
export class GetPersonGroupsRequest extends jspb.Message {
  static readonly displayName = "GetPersonGroupsRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): GetPersonGroupsRequest.AsObject {
    return GetPersonGroupsRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!GetPersonGroupsRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: GetPersonGroupsRequest) {
    var f: any;
    var obj: any = {
      personHash: jspb.Message.getFieldWithDefault(msg, 1, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!GetPersonGroupsRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new GetPersonGroupsRequest;
    return GetPersonGroupsRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!GetPersonGroupsRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!GetPersonGroupsRequest}
   */
  static deserializeBinaryFromReader(msg: GetPersonGroupsRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setPersonHash(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!GetPersonGroupsRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: GetPersonGroupsRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getPersonHash();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    GetPersonGroupsRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string person_hash = 1;
   * @return {string}
   */
  getPersonHash(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setPersonHash(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


}
export namespace GetPersonGroupsResponse {
  export interface AsObject {
    groupList: Group.AsObject[];
  }
}
export class GetPersonGroupsResponse extends jspb.Message {
  static readonly displayName = "GetPersonGroupsResponse";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [1];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, GetPersonGroupsResponse.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): GetPersonGroupsResponse.AsObject {
    return GetPersonGroupsResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!GetPersonGroupsResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: GetPersonGroupsResponse) {
    var f: any;
    var obj: any = {
      groupList: jspb.Message.toObjectList(msg.getGroupList(),
      Group.toObject, includeInstance)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!GetPersonGroupsResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new GetPersonGroupsResponse;
    return GetPersonGroupsResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!GetPersonGroupsResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!GetPersonGroupsResponse}
   */
  static deserializeBinaryFromReader(msg: GetPersonGroupsResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new Group;
        reader.readMessage(value1,Group.deserializeBinaryFromReader);
        msg.addGroup(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!GetPersonGroupsResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: GetPersonGroupsResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getGroupList();
    if (f.length > 0) {
      writer.writeRepeatedMessage(
        1,
        f,
        Group.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    GetPersonGroupsResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * repeated Group group = 1;
   * @return {!Array<!Group>}
   */
  getGroupList(): Group[] {
    return /** @type{!Array<!Group>} */ (
      jspb.Message.getRepeatedWrapperField(this, Group, 1));
  }


  /** @param {!Array<!Group>} value */
  setGroupList(value?: Group[]) {
    jspb.Message.setRepeatedWrapperField(this, 1, value);
  }


  /**
   * @param {!Group=} opt_value
   * @param {number=} opt_index
   * @return {!Group}
   */
  addGroup(opt_value?: Group, opt_index?: number): Group {
    return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, Group, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearGroupList() {
    this.setGroupList([]);
  }


}
export namespace GetMingaGroupDetailsRequest {
  export interface AsObject {
    mingaGroupHash: string;
  }
}
export class GetMingaGroupDetailsRequest extends jspb.Message {
  static readonly displayName = "GetMingaGroupDetailsRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): GetMingaGroupDetailsRequest.AsObject {
    return GetMingaGroupDetailsRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!GetMingaGroupDetailsRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: GetMingaGroupDetailsRequest) {
    var f: any;
    var obj: any = {
      mingaGroupHash: jspb.Message.getFieldWithDefault(msg, 1, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!GetMingaGroupDetailsRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new GetMingaGroupDetailsRequest;
    return GetMingaGroupDetailsRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!GetMingaGroupDetailsRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!GetMingaGroupDetailsRequest}
   */
  static deserializeBinaryFromReader(msg: GetMingaGroupDetailsRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setMingaGroupHash(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!GetMingaGroupDetailsRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: GetMingaGroupDetailsRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getMingaGroupHash();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    GetMingaGroupDetailsRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string minga_group_hash = 1;
   * @return {string}
   */
  getMingaGroupHash(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setMingaGroupHash(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


}
export namespace GetMingaGroupDetailsResponse {
  export interface AsObject {
    groupDetails?: GroupDetails.AsObject;
  }
}
export class GetMingaGroupDetailsResponse extends jspb.Message {
  static readonly displayName = "GetMingaGroupDetailsResponse";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): GetMingaGroupDetailsResponse.AsObject {
    return GetMingaGroupDetailsResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!GetMingaGroupDetailsResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: GetMingaGroupDetailsResponse) {
    var f: any;
    var obj: any = {
      groupDetails: (f = msg.getGroupDetails()) && GroupDetails.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!GetMingaGroupDetailsResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new GetMingaGroupDetailsResponse;
    return GetMingaGroupDetailsResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!GetMingaGroupDetailsResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!GetMingaGroupDetailsResponse}
   */
  static deserializeBinaryFromReader(msg: GetMingaGroupDetailsResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new GroupDetails;
        reader.readMessage(value1,GroupDetails.deserializeBinaryFromReader);
        msg.setGroupDetails(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!GetMingaGroupDetailsResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: GetMingaGroupDetailsResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getGroupDetails();
    if (f != null) {
      writer.writeMessage(
        1,
        f,
        GroupDetails.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    GetMingaGroupDetailsResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional GroupDetails group_details = 1;
   * @return {?GroupDetails}
   */
  getGroupDetails(): GroupDetails {
    return /** @type{?GroupDetails} */ (
      jspb.Message.getWrapperField(this, GroupDetails, 1));
  }


  /** @param {?GroupDetails|undefined} value */
  setGroupDetails(value?: GroupDetails) {
    jspb.Message.setWrapperField(this, 1, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearGroupDetails() {
    this.setGroupDetails(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasGroupDetails(): boolean {
    return jspb.Message.getField(this, 1) != null;
  }


}
export namespace JoinMingaGroupRequest {
  export interface AsObject {
    mingaGroupHash: string;
  }
}
export class JoinMingaGroupRequest extends jspb.Message {
  static readonly displayName = "JoinMingaGroupRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): JoinMingaGroupRequest.AsObject {
    return JoinMingaGroupRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!JoinMingaGroupRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: JoinMingaGroupRequest) {
    var f: any;
    var obj: any = {
      mingaGroupHash: jspb.Message.getFieldWithDefault(msg, 1, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!JoinMingaGroupRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new JoinMingaGroupRequest;
    return JoinMingaGroupRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!JoinMingaGroupRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!JoinMingaGroupRequest}
   */
  static deserializeBinaryFromReader(msg: JoinMingaGroupRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setMingaGroupHash(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!JoinMingaGroupRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: JoinMingaGroupRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getMingaGroupHash();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    JoinMingaGroupRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string minga_group_hash = 1;
   * @return {string}
   */
  getMingaGroupHash(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setMingaGroupHash(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


}
export namespace JoinMingaGroupResponse {
  export interface AsObject {
    myGroupsRank?: GroupMemberInfo.Rank;
    groupDetails?: GroupDetails.AsObject;
  }
}
export class JoinMingaGroupResponse extends jspb.Message {
  static readonly displayName = "JoinMingaGroupResponse";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): JoinMingaGroupResponse.AsObject {
    return JoinMingaGroupResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!JoinMingaGroupResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: JoinMingaGroupResponse) {
    var f: any;
    var obj: any = {
      myGroupsRank: jspb.Message.getFieldWithDefault(msg, 1, 0),
      groupDetails: (f = msg.getGroupDetails()) && GroupDetails.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!JoinMingaGroupResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new JoinMingaGroupResponse;
    return JoinMingaGroupResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!JoinMingaGroupResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!JoinMingaGroupResponse}
   */
  static deserializeBinaryFromReader(msg: JoinMingaGroupResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {!GroupMemberInfo.Rank} */ (reader.readEnum());
        msg.setMyGroupsRank(value1);
        break;
      case 2:
        var value2 = new GroupDetails;
        reader.readMessage(value2,GroupDetails.deserializeBinaryFromReader);
        msg.setGroupDetails(value2);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!JoinMingaGroupResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: JoinMingaGroupResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getMyGroupsRank();
    if (f !== 0.0) {
      writer.writeEnum(
        1,
        f
      );
    }
    f = message.getGroupDetails();
    if (f != null) {
      writer.writeMessage(
        2,
        f,
        GroupDetails.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    JoinMingaGroupResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional GroupMemberInfo.Rank my_groups_rank = 1;
   * @return {!GroupMemberInfo.Rank}
   */
  getMyGroupsRank(): GroupMemberInfo.Rank {
    return /** @type {!GroupMemberInfo.Rank} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
  };


  /** @param {!GroupMemberInfo.Rank} value */
  setMyGroupsRank(value: GroupMemberInfo.Rank) {
    jspb.Message.setProto3EnumField(this, 1, value);
  }


  /**
   * optional GroupDetails group_details = 2;
   * @return {?GroupDetails}
   */
  getGroupDetails(): GroupDetails {
    return /** @type{?GroupDetails} */ (
      jspb.Message.getWrapperField(this, GroupDetails, 2));
  }


  /** @param {?GroupDetails|undefined} value */
  setGroupDetails(value?: GroupDetails) {
    jspb.Message.setWrapperField(this, 2, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearGroupDetails() {
    this.setGroupDetails(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasGroupDetails(): boolean {
    return jspb.Message.getField(this, 2) != null;
  }


}
export namespace LeaveMingaGroupRequest {
  export interface AsObject {
  }
}
export class LeaveMingaGroupRequest extends jspb.Message {
  static readonly displayName = "LeaveMingaGroupRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): LeaveMingaGroupRequest.AsObject {
    return LeaveMingaGroupRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!LeaveMingaGroupRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: LeaveMingaGroupRequest) {
    var f: any;
    var obj: any = {

    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!LeaveMingaGroupRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new LeaveMingaGroupRequest;
    return LeaveMingaGroupRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!LeaveMingaGroupRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!LeaveMingaGroupRequest}
   */
  static deserializeBinaryFromReader(msg: LeaveMingaGroupRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!LeaveMingaGroupRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: LeaveMingaGroupRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    LeaveMingaGroupRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
}
export namespace LeaveMingaGroupResponse {
  export interface AsObject {
  }
}
export class LeaveMingaGroupResponse extends jspb.Message {
  static readonly displayName = "LeaveMingaGroupResponse";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): LeaveMingaGroupResponse.AsObject {
    return LeaveMingaGroupResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!LeaveMingaGroupResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: LeaveMingaGroupResponse) {
    var f: any;
    var obj: any = {

    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!LeaveMingaGroupResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new LeaveMingaGroupResponse;
    return LeaveMingaGroupResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!LeaveMingaGroupResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!LeaveMingaGroupResponse}
   */
  static deserializeBinaryFromReader(msg: LeaveMingaGroupResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!LeaveMingaGroupResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: LeaveMingaGroupResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    LeaveMingaGroupResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
}
export namespace InviteMingaGroupMemberRequest {
  export interface AsObject {
  }
}
export class InviteMingaGroupMemberRequest extends jspb.Message {
  static readonly displayName = "InviteMingaGroupMemberRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): InviteMingaGroupMemberRequest.AsObject {
    return InviteMingaGroupMemberRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!InviteMingaGroupMemberRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: InviteMingaGroupMemberRequest) {
    var f: any;
    var obj: any = {

    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!InviteMingaGroupMemberRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new InviteMingaGroupMemberRequest;
    return InviteMingaGroupMemberRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!InviteMingaGroupMemberRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!InviteMingaGroupMemberRequest}
   */
  static deserializeBinaryFromReader(msg: InviteMingaGroupMemberRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!InviteMingaGroupMemberRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: InviteMingaGroupMemberRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    InviteMingaGroupMemberRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
}
export namespace InviteMingaGroupMemberResponse {
  export interface AsObject {
  }
}
export class InviteMingaGroupMemberResponse extends jspb.Message {
  static readonly displayName = "InviteMingaGroupMemberResponse";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): InviteMingaGroupMemberResponse.AsObject {
    return InviteMingaGroupMemberResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!InviteMingaGroupMemberResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: InviteMingaGroupMemberResponse) {
    var f: any;
    var obj: any = {

    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!InviteMingaGroupMemberResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new InviteMingaGroupMemberResponse;
    return InviteMingaGroupMemberResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!InviteMingaGroupMemberResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!InviteMingaGroupMemberResponse}
   */
  static deserializeBinaryFromReader(msg: InviteMingaGroupMemberResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!InviteMingaGroupMemberResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: InviteMingaGroupMemberResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    InviteMingaGroupMemberResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
}
export namespace AcceptMingaGroupMemberRequest {
  export interface AsObject {
    mingaGroupHash: string;
    personMemberHash: string;
  }
}
export class AcceptMingaGroupMemberRequest extends jspb.Message {
  static readonly displayName = "AcceptMingaGroupMemberRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): AcceptMingaGroupMemberRequest.AsObject {
    return AcceptMingaGroupMemberRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!AcceptMingaGroupMemberRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: AcceptMingaGroupMemberRequest) {
    var f: any;
    var obj: any = {
      mingaGroupHash: jspb.Message.getFieldWithDefault(msg, 1, ""),
      personMemberHash: jspb.Message.getFieldWithDefault(msg, 2, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!AcceptMingaGroupMemberRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new AcceptMingaGroupMemberRequest;
    return AcceptMingaGroupMemberRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!AcceptMingaGroupMemberRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!AcceptMingaGroupMemberRequest}
   */
  static deserializeBinaryFromReader(msg: AcceptMingaGroupMemberRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setMingaGroupHash(value1);
        break;
      case 2:
        var value2 = /** @type {string} */ (reader.readString());
        msg.setPersonMemberHash(value2);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!AcceptMingaGroupMemberRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: AcceptMingaGroupMemberRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getMingaGroupHash();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
    f = message.getPersonMemberHash();
    if (f.length > 0) {
      writer.writeString(
        2,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    AcceptMingaGroupMemberRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string minga_group_hash = 1;
   * @return {string}
   */
  getMingaGroupHash(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setMingaGroupHash(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


  /**
   * optional string person_member_hash = 2;
   * @return {string}
   */
  getPersonMemberHash(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
  };


  /** @param {string} value */
  setPersonMemberHash(value: string) {
    jspb.Message.setProto3StringField(this, 2, value);
  }


}
export namespace AcceptMingaGroupMemberResponse {
  export interface AsObject {
    groupDetails?: GroupDetails.AsObject;
  }
}
export class AcceptMingaGroupMemberResponse extends jspb.Message {
  static readonly displayName = "AcceptMingaGroupMemberResponse";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): AcceptMingaGroupMemberResponse.AsObject {
    return AcceptMingaGroupMemberResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!AcceptMingaGroupMemberResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: AcceptMingaGroupMemberResponse) {
    var f: any;
    var obj: any = {
      groupDetails: (f = msg.getGroupDetails()) && GroupDetails.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!AcceptMingaGroupMemberResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new AcceptMingaGroupMemberResponse;
    return AcceptMingaGroupMemberResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!AcceptMingaGroupMemberResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!AcceptMingaGroupMemberResponse}
   */
  static deserializeBinaryFromReader(msg: AcceptMingaGroupMemberResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new GroupDetails;
        reader.readMessage(value1,GroupDetails.deserializeBinaryFromReader);
        msg.setGroupDetails(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!AcceptMingaGroupMemberResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: AcceptMingaGroupMemberResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getGroupDetails();
    if (f != null) {
      writer.writeMessage(
        1,
        f,
        GroupDetails.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    AcceptMingaGroupMemberResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional GroupDetails group_details = 1;
   * @return {?GroupDetails}
   */
  getGroupDetails(): GroupDetails {
    return /** @type{?GroupDetails} */ (
      jspb.Message.getWrapperField(this, GroupDetails, 1));
  }


  /** @param {?GroupDetails|undefined} value */
  setGroupDetails(value?: GroupDetails) {
    jspb.Message.setWrapperField(this, 1, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearGroupDetails() {
    this.setGroupDetails(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasGroupDetails(): boolean {
    return jspb.Message.getField(this, 1) != null;
  }


}
export namespace DeclineMingaGroupMemberRequest {
  export interface AsObject {
    mingaGroupHash: string;
    personMemberHash: string;
  }
}
export class DeclineMingaGroupMemberRequest extends jspb.Message {
  static readonly displayName = "DeclineMingaGroupMemberRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): DeclineMingaGroupMemberRequest.AsObject {
    return DeclineMingaGroupMemberRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!DeclineMingaGroupMemberRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: DeclineMingaGroupMemberRequest) {
    var f: any;
    var obj: any = {
      mingaGroupHash: jspb.Message.getFieldWithDefault(msg, 1, ""),
      personMemberHash: jspb.Message.getFieldWithDefault(msg, 2, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!DeclineMingaGroupMemberRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new DeclineMingaGroupMemberRequest;
    return DeclineMingaGroupMemberRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!DeclineMingaGroupMemberRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!DeclineMingaGroupMemberRequest}
   */
  static deserializeBinaryFromReader(msg: DeclineMingaGroupMemberRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setMingaGroupHash(value1);
        break;
      case 2:
        var value2 = /** @type {string} */ (reader.readString());
        msg.setPersonMemberHash(value2);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!DeclineMingaGroupMemberRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: DeclineMingaGroupMemberRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getMingaGroupHash();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
    f = message.getPersonMemberHash();
    if (f.length > 0) {
      writer.writeString(
        2,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    DeclineMingaGroupMemberRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string minga_group_hash = 1;
   * @return {string}
   */
  getMingaGroupHash(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setMingaGroupHash(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


  /**
   * optional string person_member_hash = 2;
   * @return {string}
   */
  getPersonMemberHash(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
  };


  /** @param {string} value */
  setPersonMemberHash(value: string) {
    jspb.Message.setProto3StringField(this, 2, value);
  }


}
export namespace DeclineMingaGroupMemberResponse {
  export interface AsObject {
    groupDetails?: GroupDetails.AsObject;
  }
}
export class DeclineMingaGroupMemberResponse extends jspb.Message {
  static readonly displayName = "DeclineMingaGroupMemberResponse";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): DeclineMingaGroupMemberResponse.AsObject {
    return DeclineMingaGroupMemberResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!DeclineMingaGroupMemberResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: DeclineMingaGroupMemberResponse) {
    var f: any;
    var obj: any = {
      groupDetails: (f = msg.getGroupDetails()) && GroupDetails.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!DeclineMingaGroupMemberResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new DeclineMingaGroupMemberResponse;
    return DeclineMingaGroupMemberResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!DeclineMingaGroupMemberResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!DeclineMingaGroupMemberResponse}
   */
  static deserializeBinaryFromReader(msg: DeclineMingaGroupMemberResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new GroupDetails;
        reader.readMessage(value1,GroupDetails.deserializeBinaryFromReader);
        msg.setGroupDetails(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!DeclineMingaGroupMemberResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: DeclineMingaGroupMemberResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getGroupDetails();
    if (f != null) {
      writer.writeMessage(
        1,
        f,
        GroupDetails.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    DeclineMingaGroupMemberResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional GroupDetails group_details = 1;
   * @return {?GroupDetails}
   */
  getGroupDetails(): GroupDetails {
    return /** @type{?GroupDetails} */ (
      jspb.Message.getWrapperField(this, GroupDetails, 1));
  }


  /** @param {?GroupDetails|undefined} value */
  setGroupDetails(value?: GroupDetails) {
    jspb.Message.setWrapperField(this, 1, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearGroupDetails() {
    this.setGroupDetails(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasGroupDetails(): boolean {
    return jspb.Message.getField(this, 1) != null;
  }


}
export namespace RemoveMingaGroupMemberRequest {
  export interface AsObject {
    mingaGroupHash: string;
    personMemberHash: string;
    memberHashList: string[];
  }
}
export class RemoveMingaGroupMemberRequest extends jspb.Message {
  static readonly displayName = "RemoveMingaGroupMemberRequest";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [3];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, RemoveMingaGroupMemberRequest.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): RemoveMingaGroupMemberRequest.AsObject {
    return RemoveMingaGroupMemberRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!RemoveMingaGroupMemberRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: RemoveMingaGroupMemberRequest) {
    var f: any;
    var obj: any = {
      mingaGroupHash: jspb.Message.getFieldWithDefault(msg, 1, ""),
      personMemberHash: jspb.Message.getFieldWithDefault(msg, 2, ""),
      memberHashList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!RemoveMingaGroupMemberRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new RemoveMingaGroupMemberRequest;
    return RemoveMingaGroupMemberRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!RemoveMingaGroupMemberRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!RemoveMingaGroupMemberRequest}
   */
  static deserializeBinaryFromReader(msg: RemoveMingaGroupMemberRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setMingaGroupHash(value1);
        break;
      case 2:
        var value2 = /** @type {string} */ (reader.readString());
        msg.setPersonMemberHash(value2);
        break;
      case 3:
        var value3 = /** @type {string} */ (reader.readString());
        msg.addMemberHash(value3);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!RemoveMingaGroupMemberRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: RemoveMingaGroupMemberRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getMingaGroupHash();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
    f = message.getPersonMemberHash();
    if (f.length > 0) {
      writer.writeString(
        2,
        f
      );
    }
    f = message.getMemberHashList();
    if (f.length > 0) {
      writer.writeRepeatedString(
        3,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    RemoveMingaGroupMemberRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string minga_group_hash = 1;
   * @return {string}
   */
  getMingaGroupHash(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setMingaGroupHash(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


  /**
   * optional string person_member_hash = 2;
   * @return {string}
   */
  getPersonMemberHash(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
  };


  /** @param {string} value */
  setPersonMemberHash(value: string) {
    jspb.Message.setProto3StringField(this, 2, value);
  }


  /**
   * repeated string member_hash = 3;
   * @return {!Array<string>}
   */
  getMemberHashList(): string[] {
    return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
  };


  /** @param {!Array<string>} value */
  setMemberHashList(value: string[]) {
    jspb.Message.setField(this, 3, value || []);
  }


  /**
   * @param {string} value
   * @param {number=} opt_index
   */
  addMemberHash(value: string, opt_index?: number): void {
    jspb.Message.addToRepeatedField(this, 3, value, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearMemberHashList() {
    this.setMemberHashList([]);
  }


}
export namespace RemoveMingaGroupMemberResponse {
  export interface AsObject {
    groupDetails?: GroupDetails.AsObject;
  }
}
export class RemoveMingaGroupMemberResponse extends jspb.Message {
  static readonly displayName = "RemoveMingaGroupMemberResponse";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): RemoveMingaGroupMemberResponse.AsObject {
    return RemoveMingaGroupMemberResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!RemoveMingaGroupMemberResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: RemoveMingaGroupMemberResponse) {
    var f: any;
    var obj: any = {
      groupDetails: (f = msg.getGroupDetails()) && GroupDetails.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!RemoveMingaGroupMemberResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new RemoveMingaGroupMemberResponse;
    return RemoveMingaGroupMemberResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!RemoveMingaGroupMemberResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!RemoveMingaGroupMemberResponse}
   */
  static deserializeBinaryFromReader(msg: RemoveMingaGroupMemberResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new GroupDetails;
        reader.readMessage(value1,GroupDetails.deserializeBinaryFromReader);
        msg.setGroupDetails(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!RemoveMingaGroupMemberResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: RemoveMingaGroupMemberResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getGroupDetails();
    if (f != null) {
      writer.writeMessage(
        1,
        f,
        GroupDetails.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    RemoveMingaGroupMemberResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional GroupDetails group_details = 1;
   * @return {?GroupDetails}
   */
  getGroupDetails(): GroupDetails {
    return /** @type{?GroupDetails} */ (
      jspb.Message.getWrapperField(this, GroupDetails, 1));
  }


  /** @param {?GroupDetails|undefined} value */
  setGroupDetails(value?: GroupDetails) {
    jspb.Message.setWrapperField(this, 1, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearGroupDetails() {
    this.setGroupDetails(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasGroupDetails(): boolean {
    return jspb.Message.getField(this, 1) != null;
  }


}
export namespace AcceptMingaGroupInviteRequest {
  export interface AsObject {
  }
}
export class AcceptMingaGroupInviteRequest extends jspb.Message {
  static readonly displayName = "AcceptMingaGroupInviteRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): AcceptMingaGroupInviteRequest.AsObject {
    return AcceptMingaGroupInviteRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!AcceptMingaGroupInviteRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: AcceptMingaGroupInviteRequest) {
    var f: any;
    var obj: any = {

    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!AcceptMingaGroupInviteRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new AcceptMingaGroupInviteRequest;
    return AcceptMingaGroupInviteRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!AcceptMingaGroupInviteRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!AcceptMingaGroupInviteRequest}
   */
  static deserializeBinaryFromReader(msg: AcceptMingaGroupInviteRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!AcceptMingaGroupInviteRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: AcceptMingaGroupInviteRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    AcceptMingaGroupInviteRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
}
export namespace AcceptMingaGroupInviteResponse {
  export interface AsObject {
  }
}
export class AcceptMingaGroupInviteResponse extends jspb.Message {
  static readonly displayName = "AcceptMingaGroupInviteResponse";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): AcceptMingaGroupInviteResponse.AsObject {
    return AcceptMingaGroupInviteResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!AcceptMingaGroupInviteResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: AcceptMingaGroupInviteResponse) {
    var f: any;
    var obj: any = {

    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!AcceptMingaGroupInviteResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new AcceptMingaGroupInviteResponse;
    return AcceptMingaGroupInviteResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!AcceptMingaGroupInviteResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!AcceptMingaGroupInviteResponse}
   */
  static deserializeBinaryFromReader(msg: AcceptMingaGroupInviteResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!AcceptMingaGroupInviteResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: AcceptMingaGroupInviteResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    AcceptMingaGroupInviteResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
}
export namespace DeclineMingaGroupInviteRequest {
  export interface AsObject {
  }
}
export class DeclineMingaGroupInviteRequest extends jspb.Message {
  static readonly displayName = "DeclineMingaGroupInviteRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): DeclineMingaGroupInviteRequest.AsObject {
    return DeclineMingaGroupInviteRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!DeclineMingaGroupInviteRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: DeclineMingaGroupInviteRequest) {
    var f: any;
    var obj: any = {

    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!DeclineMingaGroupInviteRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new DeclineMingaGroupInviteRequest;
    return DeclineMingaGroupInviteRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!DeclineMingaGroupInviteRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!DeclineMingaGroupInviteRequest}
   */
  static deserializeBinaryFromReader(msg: DeclineMingaGroupInviteRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!DeclineMingaGroupInviteRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: DeclineMingaGroupInviteRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    DeclineMingaGroupInviteRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
}
export namespace DeclineMingaGroupInviteResponse {
  export interface AsObject {
  }
}
export class DeclineMingaGroupInviteResponse extends jspb.Message {
  static readonly displayName = "DeclineMingaGroupInviteResponse";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): DeclineMingaGroupInviteResponse.AsObject {
    return DeclineMingaGroupInviteResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!DeclineMingaGroupInviteResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: DeclineMingaGroupInviteResponse) {
    var f: any;
    var obj: any = {

    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!DeclineMingaGroupInviteResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new DeclineMingaGroupInviteResponse;
    return DeclineMingaGroupInviteResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!DeclineMingaGroupInviteResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!DeclineMingaGroupInviteResponse}
   */
  static deserializeBinaryFromReader(msg: DeclineMingaGroupInviteResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!DeclineMingaGroupInviteResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: DeclineMingaGroupInviteResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    DeclineMingaGroupInviteResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
}
export namespace CancelJoinMingaGroupRequest {
  export interface AsObject {
    mingaGroupHash: string;
  }
}
export class CancelJoinMingaGroupRequest extends jspb.Message {
  static readonly displayName = "CancelJoinMingaGroupRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): CancelJoinMingaGroupRequest.AsObject {
    return CancelJoinMingaGroupRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!CancelJoinMingaGroupRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: CancelJoinMingaGroupRequest) {
    var f: any;
    var obj: any = {
      mingaGroupHash: jspb.Message.getFieldWithDefault(msg, 1, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!CancelJoinMingaGroupRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new CancelJoinMingaGroupRequest;
    return CancelJoinMingaGroupRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!CancelJoinMingaGroupRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!CancelJoinMingaGroupRequest}
   */
  static deserializeBinaryFromReader(msg: CancelJoinMingaGroupRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setMingaGroupHash(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!CancelJoinMingaGroupRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: CancelJoinMingaGroupRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getMingaGroupHash();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    CancelJoinMingaGroupRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string minga_group_hash = 1;
   * @return {string}
   */
  getMingaGroupHash(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setMingaGroupHash(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


}
export namespace CancelJoinMingaGroupResponse {
  export interface AsObject {
  }
}
export class CancelJoinMingaGroupResponse extends jspb.Message {
  static readonly displayName = "CancelJoinMingaGroupResponse";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): CancelJoinMingaGroupResponse.AsObject {
    return CancelJoinMingaGroupResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!CancelJoinMingaGroupResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: CancelJoinMingaGroupResponse) {
    var f: any;
    var obj: any = {

    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!CancelJoinMingaGroupResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new CancelJoinMingaGroupResponse;
    return CancelJoinMingaGroupResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!CancelJoinMingaGroupResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!CancelJoinMingaGroupResponse}
   */
  static deserializeBinaryFromReader(msg: CancelJoinMingaGroupResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!CancelJoinMingaGroupResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: CancelJoinMingaGroupResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    CancelJoinMingaGroupResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
}
export namespace DeleteMingaGroupRequest {
  export interface AsObject {
    mingaGroupHash: string;
  }
}
export class DeleteMingaGroupRequest extends jspb.Message {
  static readonly displayName = "DeleteMingaGroupRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): DeleteMingaGroupRequest.AsObject {
    return DeleteMingaGroupRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!DeleteMingaGroupRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: DeleteMingaGroupRequest) {
    var f: any;
    var obj: any = {
      mingaGroupHash: jspb.Message.getFieldWithDefault(msg, 1, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!DeleteMingaGroupRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new DeleteMingaGroupRequest;
    return DeleteMingaGroupRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!DeleteMingaGroupRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!DeleteMingaGroupRequest}
   */
  static deserializeBinaryFromReader(msg: DeleteMingaGroupRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setMingaGroupHash(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!DeleteMingaGroupRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: DeleteMingaGroupRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getMingaGroupHash();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    DeleteMingaGroupRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string minga_group_hash = 1;
   * @return {string}
   */
  getMingaGroupHash(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setMingaGroupHash(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


}
export namespace DeleteMingaGroupResponse {
  export interface AsObject {
  }
}
export class DeleteMingaGroupResponse extends jspb.Message {
  static readonly displayName = "DeleteMingaGroupResponse";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): DeleteMingaGroupResponse.AsObject {
    return DeleteMingaGroupResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!DeleteMingaGroupResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: DeleteMingaGroupResponse) {
    var f: any;
    var obj: any = {

    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!DeleteMingaGroupResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new DeleteMingaGroupResponse;
    return DeleteMingaGroupResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!DeleteMingaGroupResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!DeleteMingaGroupResponse}
   */
  static deserializeBinaryFromReader(msg: DeleteMingaGroupResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!DeleteMingaGroupResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: DeleteMingaGroupResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    DeleteMingaGroupResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
}
export namespace AddMingaGroupAssetsRequest {
  export interface AsObject {
    mingaGroupHash: string;
    assetList: gateway_file_pb.FileInfo.AsObject[];
  }
}
export class AddMingaGroupAssetsRequest extends jspb.Message {
  static readonly displayName = "AddMingaGroupAssetsRequest";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [2];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, AddMingaGroupAssetsRequest.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): AddMingaGroupAssetsRequest.AsObject {
    return AddMingaGroupAssetsRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!AddMingaGroupAssetsRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: AddMingaGroupAssetsRequest) {
    var f: any;
    var obj: any = {
      mingaGroupHash: jspb.Message.getFieldWithDefault(msg, 1, ""),
      assetList: jspb.Message.toObjectList(msg.getAssetList(),
      gateway_file_pb.FileInfo.toObject, includeInstance)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!AddMingaGroupAssetsRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new AddMingaGroupAssetsRequest;
    return AddMingaGroupAssetsRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!AddMingaGroupAssetsRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!AddMingaGroupAssetsRequest}
   */
  static deserializeBinaryFromReader(msg: AddMingaGroupAssetsRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setMingaGroupHash(value1);
        break;
      case 2:
        var value2 = new gateway_file_pb.FileInfo;
        reader.readMessage(value2,gateway_file_pb.FileInfo.deserializeBinaryFromReader);
        msg.addAsset(value2);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!AddMingaGroupAssetsRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: AddMingaGroupAssetsRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getMingaGroupHash();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
    f = message.getAssetList();
    if (f.length > 0) {
      writer.writeRepeatedMessage(
        2,
        f,
        gateway_file_pb.FileInfo.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    AddMingaGroupAssetsRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string minga_group_hash = 1;
   * @return {string}
   */
  getMingaGroupHash(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setMingaGroupHash(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


  /**
   * repeated FileInfo asset = 2;
   * @return {!Array<!FileInfo>}
   */
  getAssetList(): gateway_file_pb.FileInfo[] {
    return /** @type{!Array<!FileInfo>} */ (
      jspb.Message.getRepeatedWrapperField(this, gateway_file_pb.FileInfo, 2));
  }


  /** @param {!Array<!FileInfo>} value */
  setAssetList(value?: gateway_file_pb.FileInfo[]) {
    jspb.Message.setRepeatedWrapperField(this, 2, value);
  }


  /**
   * @param {!FileInfo=} opt_value
   * @param {number=} opt_index
   * @return {!FileInfo}
   */
  addAsset(opt_value?: gateway_file_pb.FileInfo, opt_index?: number): gateway_file_pb.FileInfo {
    return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, gateway_file_pb.FileInfo, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearAssetList() {
    this.setAssetList([]);
  }


}
export namespace GetMingaGroupFilesRequest {
  export interface AsObject {
    mingaGroupHash: string;
  }
}
export class GetMingaGroupFilesRequest extends jspb.Message {
  static readonly displayName = "GetMingaGroupFilesRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): GetMingaGroupFilesRequest.AsObject {
    return GetMingaGroupFilesRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!GetMingaGroupFilesRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: GetMingaGroupFilesRequest) {
    var f: any;
    var obj: any = {
      mingaGroupHash: jspb.Message.getFieldWithDefault(msg, 1, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!GetMingaGroupFilesRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new GetMingaGroupFilesRequest;
    return GetMingaGroupFilesRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!GetMingaGroupFilesRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!GetMingaGroupFilesRequest}
   */
  static deserializeBinaryFromReader(msg: GetMingaGroupFilesRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setMingaGroupHash(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!GetMingaGroupFilesRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: GetMingaGroupFilesRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getMingaGroupHash();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    GetMingaGroupFilesRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string minga_group_hash = 1;
   * @return {string}
   */
  getMingaGroupHash(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setMingaGroupHash(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


}
export namespace MingaGroupFilesResponse {
  export interface AsObject {
    files?: GroupFiles.AsObject;
  }
}
export class MingaGroupFilesResponse extends jspb.Message {
  static readonly displayName = "MingaGroupFilesResponse";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): MingaGroupFilesResponse.AsObject {
    return MingaGroupFilesResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!MingaGroupFilesResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: MingaGroupFilesResponse) {
    var f: any;
    var obj: any = {
      files: (f = msg.getFiles()) && GroupFiles.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!MingaGroupFilesResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new MingaGroupFilesResponse;
    return MingaGroupFilesResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!MingaGroupFilesResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!MingaGroupFilesResponse}
   */
  static deserializeBinaryFromReader(msg: MingaGroupFilesResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new GroupFiles;
        reader.readMessage(value1,GroupFiles.deserializeBinaryFromReader);
        msg.setFiles(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!MingaGroupFilesResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: MingaGroupFilesResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getFiles();
    if (f != null) {
      writer.writeMessage(
        1,
        f,
        GroupFiles.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    MingaGroupFilesResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional GroupFiles files = 1;
   * @return {?GroupFiles}
   */
  getFiles(): GroupFiles {
    return /** @type{?GroupFiles} */ (
      jspb.Message.getWrapperField(this, GroupFiles, 1));
  }


  /** @param {?GroupFiles|undefined} value */
  setFiles(value?: GroupFiles) {
    jspb.Message.setWrapperField(this, 1, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearFiles() {
    this.setFiles(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasFiles(): boolean {
    return jspb.Message.getField(this, 1) != null;
  }


}
export namespace RemoveMingaGroupAssetRequest {
  export interface AsObject {
    mingaGroupHash: string;
    fileHash: string;
  }
}
export class RemoveMingaGroupAssetRequest extends jspb.Message {
  static readonly displayName = "RemoveMingaGroupAssetRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): RemoveMingaGroupAssetRequest.AsObject {
    return RemoveMingaGroupAssetRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!RemoveMingaGroupAssetRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: RemoveMingaGroupAssetRequest) {
    var f: any;
    var obj: any = {
      mingaGroupHash: jspb.Message.getFieldWithDefault(msg, 1, ""),
      fileHash: jspb.Message.getFieldWithDefault(msg, 2, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!RemoveMingaGroupAssetRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new RemoveMingaGroupAssetRequest;
    return RemoveMingaGroupAssetRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!RemoveMingaGroupAssetRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!RemoveMingaGroupAssetRequest}
   */
  static deserializeBinaryFromReader(msg: RemoveMingaGroupAssetRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setMingaGroupHash(value1);
        break;
      case 2:
        var value2 = /** @type {string} */ (reader.readString());
        msg.setFileHash(value2);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!RemoveMingaGroupAssetRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: RemoveMingaGroupAssetRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getMingaGroupHash();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
    f = message.getFileHash();
    if (f.length > 0) {
      writer.writeString(
        2,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    RemoveMingaGroupAssetRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string minga_group_hash = 1;
   * @return {string}
   */
  getMingaGroupHash(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setMingaGroupHash(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


  /**
   * optional string file_hash = 2;
   * @return {string}
   */
  getFileHash(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
  };


  /** @param {string} value */
  setFileHash(value: string) {
    jspb.Message.setProto3StringField(this, 2, value);
  }


}
export namespace UpdateMingaGroupMembersRequest {
  export interface AsObject {
    mingaGroupHash: string;
    newMemberRankMap: [string, GroupMemberInfo.Rank][];
    membersToRemoveList: string[];
    newMembersList: string[];
  }
}
export class UpdateMingaGroupMembersRequest extends jspb.Message {
  static readonly displayName = "UpdateMingaGroupMembersRequest";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [3,4];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, UpdateMingaGroupMembersRequest.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): UpdateMingaGroupMembersRequest.AsObject {
    return UpdateMingaGroupMembersRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!UpdateMingaGroupMembersRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: UpdateMingaGroupMembersRequest) {
    var f: any;
    var obj: any = {
      mingaGroupHash: jspb.Message.getFieldWithDefault(msg, 1, ""),
      newMemberRankMap: (f = msg.getNewMemberRankMap()) ? f.toObject(includeInstance, undefined) : [],
      membersToRemoveList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
      newMembersList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!UpdateMingaGroupMembersRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new UpdateMingaGroupMembersRequest;
    return UpdateMingaGroupMembersRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!UpdateMingaGroupMembersRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!UpdateMingaGroupMembersRequest}
   */
  static deserializeBinaryFromReader(msg: UpdateMingaGroupMembersRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setMingaGroupHash(value1);
        break;
      case 2:
        var value2 = msg.getNewMemberRankMap();
        reader.readMessage(value2, function(message, reader) {
          jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readEnum, null, "");
           });
        break;
      case 3:
        var value3 = /** @type {string} */ (reader.readString());
        msg.addMembersToRemove(value3);
        break;
      case 4:
        var value4 = /** @type {string} */ (reader.readString());
        msg.addNewMembers(value4);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!UpdateMingaGroupMembersRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: UpdateMingaGroupMembersRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getMingaGroupHash();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
    f = message.getNewMemberRankMap(true);
    if (f && f.getLength() > 0) {
      f.serializeBinary(2, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeEnum);
    }
    f = message.getMembersToRemoveList();
    if (f.length > 0) {
      writer.writeRepeatedString(
        3,
        f
      );
    }
    f = message.getNewMembersList();
    if (f.length > 0) {
      writer.writeRepeatedString(
        4,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    UpdateMingaGroupMembersRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string minga_group_hash = 1;
   * @return {string}
   */
  getMingaGroupHash(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setMingaGroupHash(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


  /**
   * map<string, GroupMemberInfo.Rank> new_member_rank = 2;
   * @param {boolean=} opt_noLazyCreate Do not create the map if
   * empty, instead returning `undefined`
   * @return {!jspb.Map<string,!GroupMemberInfo.Rank>}
   */
  getNewMemberRankMap(): jspb.Map<string,GroupMemberInfo.Rank>;
  getNewMemberRankMap(noLazyCreate: true): jspb.Map<string,GroupMemberInfo.Rank>|undefined;
  getNewMemberRankMap(noLazyCreate: false): jspb.Map<string,GroupMemberInfo.Rank>;
  getNewMemberRankMap(opt_noLazyCreate: boolean = false): jspb.Map<string,GroupMemberInfo.Rank> {
    return /** @type {!jspb.Map<string,!GroupMemberInfo.Rank>} */ (
        jspb.Message.getMapField(this, 2, opt_noLazyCreate,
        null));
  }


  /**
   * Clears values from the map. The map will be non-null.
   */
  clearNewMemberRankMap() {
    this.getNewMemberRankMap().clear();
  }


  /**
   * repeated string members_to_remove = 3;
   * @return {!Array<string>}
   */
  getMembersToRemoveList(): string[] {
    return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
  };


  /** @param {!Array<string>} value */
  setMembersToRemoveList(value: string[]) {
    jspb.Message.setField(this, 3, value || []);
  }


  /**
   * @param {string} value
   * @param {number=} opt_index
   */
  addMembersToRemove(value: string, opt_index?: number): void {
    jspb.Message.addToRepeatedField(this, 3, value, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearMembersToRemoveList() {
    this.setMembersToRemoveList([]);
  }


  /**
   * repeated string new_members = 4;
   * @return {!Array<string>}
   */
  getNewMembersList(): string[] {
    return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 4));
  };


  /** @param {!Array<string>} value */
  setNewMembersList(value: string[]) {
    jspb.Message.setField(this, 4, value || []);
  }


  /**
   * @param {string} value
   * @param {number=} opt_index
   */
  addNewMembers(value: string, opt_index?: number): void {
    jspb.Message.addToRepeatedField(this, 4, value, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearNewMembersList() {
    this.setNewMembersList([]);
  }


}
export namespace UpdateMingaGroupMembersResponse {
  export interface AsObject {
    groupDetails?: GroupDetails.AsObject;
  }
}
export class UpdateMingaGroupMembersResponse extends jspb.Message {
  static readonly displayName = "UpdateMingaGroupMembersResponse";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): UpdateMingaGroupMembersResponse.AsObject {
    return UpdateMingaGroupMembersResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!UpdateMingaGroupMembersResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: UpdateMingaGroupMembersResponse) {
    var f: any;
    var obj: any = {
      groupDetails: (f = msg.getGroupDetails()) && GroupDetails.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!UpdateMingaGroupMembersResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new UpdateMingaGroupMembersResponse;
    return UpdateMingaGroupMembersResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!UpdateMingaGroupMembersResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!UpdateMingaGroupMembersResponse}
   */
  static deserializeBinaryFromReader(msg: UpdateMingaGroupMembersResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new GroupDetails;
        reader.readMessage(value1,GroupDetails.deserializeBinaryFromReader);
        msg.setGroupDetails(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!UpdateMingaGroupMembersResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: UpdateMingaGroupMembersResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getGroupDetails();
    if (f != null) {
      writer.writeMessage(
        1,
        f,
        GroupDetails.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    UpdateMingaGroupMembersResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional GroupDetails group_details = 1;
   * @return {?GroupDetails}
   */
  getGroupDetails(): GroupDetails {
    return /** @type{?GroupDetails} */ (
      jspb.Message.getWrapperField(this, GroupDetails, 1));
  }


  /** @param {?GroupDetails|undefined} value */
  setGroupDetails(value?: GroupDetails) {
    jspb.Message.setWrapperField(this, 1, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearGroupDetails() {
    this.setGroupDetails(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasGroupDetails(): boolean {
    return jspb.Message.getField(this, 1) != null;
  }


}
export namespace AddMingaGroupMembersRequest {
  export interface AsObject {
    mingaGroupHash: string;
    personMemberHashList: string[];
  }
}
export class AddMingaGroupMembersRequest extends jspb.Message {
  static readonly displayName = "AddMingaGroupMembersRequest";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [2];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, AddMingaGroupMembersRequest.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): AddMingaGroupMembersRequest.AsObject {
    return AddMingaGroupMembersRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!AddMingaGroupMembersRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: AddMingaGroupMembersRequest) {
    var f: any;
    var obj: any = {
      mingaGroupHash: jspb.Message.getFieldWithDefault(msg, 1, ""),
      personMemberHashList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!AddMingaGroupMembersRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new AddMingaGroupMembersRequest;
    return AddMingaGroupMembersRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!AddMingaGroupMembersRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!AddMingaGroupMembersRequest}
   */
  static deserializeBinaryFromReader(msg: AddMingaGroupMembersRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setMingaGroupHash(value1);
        break;
      case 2:
        var value2 = /** @type {string} */ (reader.readString());
        msg.addPersonMemberHash(value2);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!AddMingaGroupMembersRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: AddMingaGroupMembersRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getMingaGroupHash();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
    f = message.getPersonMemberHashList();
    if (f.length > 0) {
      writer.writeRepeatedString(
        2,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    AddMingaGroupMembersRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string minga_group_hash = 1;
   * @return {string}
   */
  getMingaGroupHash(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setMingaGroupHash(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


  /**
   * repeated string person_member_hash = 2;
   * @return {!Array<string>}
   */
  getPersonMemberHashList(): string[] {
    return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
  };


  /** @param {!Array<string>} value */
  setPersonMemberHashList(value: string[]) {
    jspb.Message.setField(this, 2, value || []);
  }


  /**
   * @param {string} value
   * @param {number=} opt_index
   */
  addPersonMemberHash(value: string, opt_index?: number): void {
    jspb.Message.addToRepeatedField(this, 2, value, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearPersonMemberHashList() {
    this.setPersonMemberHashList([]);
  }


}
export namespace AddMingaGroupMembersResponse {
  export interface AsObject {
    groupDetails?: GroupDetails.AsObject;
  }
}
export class AddMingaGroupMembersResponse extends jspb.Message {
  static readonly displayName = "AddMingaGroupMembersResponse";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): AddMingaGroupMembersResponse.AsObject {
    return AddMingaGroupMembersResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!AddMingaGroupMembersResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: AddMingaGroupMembersResponse) {
    var f: any;
    var obj: any = {
      groupDetails: (f = msg.getGroupDetails()) && GroupDetails.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!AddMingaGroupMembersResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new AddMingaGroupMembersResponse;
    return AddMingaGroupMembersResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!AddMingaGroupMembersResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!AddMingaGroupMembersResponse}
   */
  static deserializeBinaryFromReader(msg: AddMingaGroupMembersResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new GroupDetails;
        reader.readMessage(value1,GroupDetails.deserializeBinaryFromReader);
        msg.setGroupDetails(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!AddMingaGroupMembersResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: AddMingaGroupMembersResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getGroupDetails();
    if (f != null) {
      writer.writeMessage(
        1,
        f,
        GroupDetails.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    AddMingaGroupMembersResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional GroupDetails group_details = 1;
   * @return {?GroupDetails}
   */
  getGroupDetails(): GroupDetails {
    return /** @type{?GroupDetails} */ (
      jspb.Message.getWrapperField(this, GroupDetails, 1));
  }


  /** @param {?GroupDetails|undefined} value */
  setGroupDetails(value?: GroupDetails) {
    jspb.Message.setWrapperField(this, 1, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearGroupDetails() {
    this.setGroupDetails(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasGroupDetails(): boolean {
    return jspb.Message.getField(this, 1) != null;
  }


}
export namespace ExportGroupMembersRequest {
  export interface AsObject {
    statusFilter: string;
    contextHash: string;
  }
}
export class ExportGroupMembersRequest extends jspb.Message {
  static readonly displayName = "ExportGroupMembersRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): ExportGroupMembersRequest.AsObject {
    return ExportGroupMembersRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!ExportGroupMembersRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: ExportGroupMembersRequest) {
    var f: any;
    var obj: any = {
      statusFilter: jspb.Message.getFieldWithDefault(msg, 1, ""),
      contextHash: jspb.Message.getFieldWithDefault(msg, 2, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!ExportGroupMembersRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new ExportGroupMembersRequest;
    return ExportGroupMembersRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!ExportGroupMembersRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!ExportGroupMembersRequest}
   */
  static deserializeBinaryFromReader(msg: ExportGroupMembersRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setStatusFilter(value1);
        break;
      case 2:
        var value2 = /** @type {string} */ (reader.readString());
        msg.setContextHash(value2);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!ExportGroupMembersRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: ExportGroupMembersRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getStatusFilter();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
    f = message.getContextHash();
    if (f.length > 0) {
      writer.writeString(
        2,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    ExportGroupMembersRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string status_filter = 1;
   * @return {string}
   */
  getStatusFilter(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setStatusFilter(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


  /**
   * optional string context_hash = 2;
   * @return {string}
   */
  getContextHash(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
  };


  /** @param {string} value */
  setContextHash(value: string) {
    jspb.Message.setProto3StringField(this, 2, value);
  }


}
export namespace ExportGroupMembersChunk {
  export interface AsObject {
    chunk?: (string|Uint8Array);
    filename: string;
  }
}
export class ExportGroupMembersChunk extends jspb.Message {
  static readonly displayName = "ExportGroupMembersChunk";
  /**
   * Oneof group definitions for this message. Each group defines the field
   * numbers belonging to that group. When of these fields' value is set, all
   * other fields in the group are cleared. During deserialization, if multiple
   * fields are encountered for a group, only the last value seen will be kept.
   * @private {!Array<!Array<number>>}
   * @const
   */
  static readonly oneofGroups_ = [[1,2]];


  /**
   * @return {ExportGroupMembersChunk.InfoCase}
   */
  getInfoCase() {
    return /** @type {ExportGroupMembersChunk.InfoCase} */(jspb.Message.computeOneofCase(this, ExportGroupMembersChunk.oneofGroups_[0]));
  }

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, ExportGroupMembersChunk.oneofGroups_);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): ExportGroupMembersChunk.AsObject {
    return ExportGroupMembersChunk.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!ExportGroupMembersChunk} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: ExportGroupMembersChunk) {
    var f: any;
    var obj: any = {
      chunk: msg.getChunk_asB64(),
      filename: jspb.Message.getFieldWithDefault(msg, 2, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!ExportGroupMembersChunk}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new ExportGroupMembersChunk;
    return ExportGroupMembersChunk.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!ExportGroupMembersChunk} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!ExportGroupMembersChunk}
   */
  static deserializeBinaryFromReader(msg: ExportGroupMembersChunk, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {!Uint8Array} */ (reader.readBytes());
        msg.setChunk(value1);
        break;
      case 2:
        var value2 = /** @type {string} */ (reader.readString());
        msg.setFilename(value2);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!ExportGroupMembersChunk} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: ExportGroupMembersChunk, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 1));
    if (f != null) {
      writer.writeBytes(
        1,
        f
      );
    }
    f = /** @type {string} */ (jspb.Message.getField(message, 2));
    if (f != null) {
      writer.writeString(
        2,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    ExportGroupMembersChunk.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional bytes chunk = 1;
   * @return {!(string|Uint8Array)}
   */
  getChunk(): (string|Uint8Array) {
    return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /**
   * optional bytes chunk = 1;
   * This is a type-conversion wrapper around `getChunk()`
   * @return {string}
   */
  getChunk_asB64() {
    return /** @type {string} */ (jspb.Message.bytesAsB64(
        this.getChunk()));
  }


  /**
   * optional bytes chunk = 1;
   * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getChunk()`
   * @return {!Uint8Array}
   */
  getChunk_asU8() {
    return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
        this.getChunk()));
  }


  /** @param {!(string|Uint8Array)} value */
  setChunk(value: (string|Uint8Array)) {
    jspb.Message.setOneofField(this, 1, ExportGroupMembersChunk.oneofGroups_[0], value);
  }


  /**
   * Clears the field making it undefined.
   */
  clearChunk() {
    jspb.Message.setOneofField(this, 1, ExportGroupMembersChunk.oneofGroups_[0], undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasChunk(): boolean {
    return jspb.Message.getField(this, 1) != null;
  }


  /**
   * optional string filename = 2;
   * @return {string}
   */
  getFilename(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
  };


  /** @param {string} value */
  setFilename(value: string) {
    jspb.Message.setOneofField(this, 2, ExportGroupMembersChunk.oneofGroups_[0], value);
  }


  /**
   * Clears the field making it undefined.
   */
  clearFilename() {
    jspb.Message.setOneofField(this, 2, ExportGroupMembersChunk.oneofGroups_[0], undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasFilename(): boolean {
    return jspb.Message.getField(this, 2) != null;
  }


}
export namespace ExportGroupMembersChunk {
export enum InfoCase {
  INFO_NOT_SET = 0,
  CHUNK = 1,
  FILENAME = 2,
}
} // namespace ExportGroupMembersChunk
