import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { gateway } from 'libs/generated-grpc-web';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { AuthInfoService } from '@app/src/app/minimal/services/AuthInfo';
import { ContentEvents } from '@app/src/app/minimal/services/ContentEvents';
import { StreamManager } from '@app/src/app/minimal/services/StreamManager';
import { PersonFormFields } from '@app/src/app/services/PeopleManager';

@Component({
  selector: 'mg-home-content',
  templateUrl: './home-content.component.html',
  styleUrls: ['./home-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HomeContentComponent implements OnInit, OnDestroy {
  // Clean up

  private readonly _destroyedSubject = new ReplaySubject<void>(1);

  // State

  private _personHash: string;

  // I don't know

  private _fields = PersonFormFields.defaultValue();

  // Computed getters

  get isOwnProfile() {
    return this._personHash === this._authInfo.authPersonHash;
  }

  get filter() {
    return {
      personHash: this._personHash || this._authInfo.authPersonHash,
    };
  }

  get emptyFeedString() {
    const prefix = this.isOwnProfile
      ? `You haven't`
      : this._fields.displayName + " hasn't";
    return prefix + ' posted anything yet.';
  }

  /** Component Constructor */
  constructor(
    public profileContent: gateway.profileContent_ng_grpc_pb.ProfileContent,
    private _route: ActivatedRoute,
    private _authInfo: AuthInfoService,
    private _streamManager: StreamManager,

    public _contentEvents: ContentEvents,
  ) {
    this._contentEvents.onContentDeleted
      .pipe(takeUntil(this._destroyedSubject))
      .subscribe(() => {
        this._streamManager.restartStream('profileContent');
      });
    this._contentEvents.onContentFeedsUpdate
      .pipe(takeUntil(this._destroyedSubject))
      .subscribe(() => {
        this._streamManager.restartStream('profileContent');
      });
  }

  ngOnInit(): void {
    const personHashParameter = this._route.snapshot.paramMap.get('hash');
    if (personHashParameter) this._personHash = personHashParameter;
  }

  ngOnDestroy(): void {
    this._destroyedSubject.next();
    this._destroyedSubject.complete();
  }
}
