// source: hall_pass_stats_internal/hall_pass_stats_internal.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

import * as jspb from 'google-protobuf';
export namespace InternalHallPassStat {
  export interface AsObject {
    typeId: number;
    year: number;
    month: number;
    date: string;
    hour: number;
    week: number;
    totalPasses: number;
    totalTime: number;
    typeName: string;
    typeColor: string;
    issuedBy: number;
    issuedTo: number;
  }
}
export class InternalHallPassStat extends jspb.Message {
  static readonly displayName = "InternalHallPassStat";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): InternalHallPassStat.AsObject {
    return InternalHallPassStat.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!InternalHallPassStat} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: InternalHallPassStat) {
    var f: any;
    var obj: any = {
      typeId: jspb.Message.getFieldWithDefault(msg, 1, 0),
      year: jspb.Message.getFieldWithDefault(msg, 2, 0),
      month: jspb.Message.getFieldWithDefault(msg, 3, 0),
      date: jspb.Message.getFieldWithDefault(msg, 4, ""),
      hour: jspb.Message.getFieldWithDefault(msg, 5, 0),
      week: jspb.Message.getFieldWithDefault(msg, 6, 0),
      totalPasses: jspb.Message.getFieldWithDefault(msg, 7, 0),
      totalTime: jspb.Message.getFieldWithDefault(msg, 8, 0),
      typeName: jspb.Message.getFieldWithDefault(msg, 9, ""),
      typeColor: jspb.Message.getFieldWithDefault(msg, 10, ""),
      issuedBy: jspb.Message.getFieldWithDefault(msg, 11, 0),
      issuedTo: jspb.Message.getFieldWithDefault(msg, 12, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!InternalHallPassStat}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new InternalHallPassStat;
    return InternalHallPassStat.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!InternalHallPassStat} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!InternalHallPassStat}
   */
  static deserializeBinaryFromReader(msg: InternalHallPassStat, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {number} */ (reader.readInt32());
        msg.setTypeId(value1);
        break;
      case 2:
        var value2 = /** @type {number} */ (reader.readInt32());
        msg.setYear(value2);
        break;
      case 3:
        var value3 = /** @type {number} */ (reader.readInt32());
        msg.setMonth(value3);
        break;
      case 4:
        var value4 = /** @type {string} */ (reader.readString());
        msg.setDate(value4);
        break;
      case 5:
        var value5 = /** @type {number} */ (reader.readInt32());
        msg.setHour(value5);
        break;
      case 6:
        var value6 = /** @type {number} */ (reader.readInt32());
        msg.setWeek(value6);
        break;
      case 7:
        var value7 = /** @type {number} */ (reader.readInt32());
        msg.setTotalPasses(value7);
        break;
      case 8:
        var value8 = /** @type {number} */ (reader.readInt32());
        msg.setTotalTime(value8);
        break;
      case 9:
        var value9 = /** @type {string} */ (reader.readString());
        msg.setTypeName(value9);
        break;
      case 10:
        var value10 = /** @type {string} */ (reader.readString());
        msg.setTypeColor(value10);
        break;
      case 11:
        var value11 = /** @type {number} */ (reader.readInt32());
        msg.setIssuedBy(value11);
        break;
      case 12:
        var value12 = /** @type {number} */ (reader.readInt32());
        msg.setIssuedTo(value12);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!InternalHallPassStat} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: InternalHallPassStat, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getTypeId();
    if (f !== 0) {
      writer.writeInt32(
        1,
        f
      );
    }
    f = message.getYear();
    if (f !== 0) {
      writer.writeInt32(
        2,
        f
      );
    }
    f = message.getMonth();
    if (f !== 0) {
      writer.writeInt32(
        3,
        f
      );
    }
    f = message.getDate();
    if (f.length > 0) {
      writer.writeString(
        4,
        f
      );
    }
    f = message.getHour();
    if (f !== 0) {
      writer.writeInt32(
        5,
        f
      );
    }
    f = message.getWeek();
    if (f !== 0) {
      writer.writeInt32(
        6,
        f
      );
    }
    f = message.getTotalPasses();
    if (f !== 0) {
      writer.writeInt32(
        7,
        f
      );
    }
    f = message.getTotalTime();
    if (f !== 0) {
      writer.writeInt32(
        8,
        f
      );
    }
    f = message.getTypeName();
    if (f.length > 0) {
      writer.writeString(
        9,
        f
      );
    }
    f = message.getTypeColor();
    if (f.length > 0) {
      writer.writeString(
        10,
        f
      );
    }
    f = message.getIssuedBy();
    if (f !== 0) {
      writer.writeInt32(
        11,
        f
      );
    }
    f = message.getIssuedTo();
    if (f !== 0) {
      writer.writeInt32(
        12,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    InternalHallPassStat.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional int32 type_id = 1;
   * @return {number}
   */
  getTypeId(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
  };


  /** @param {number} value */
  setTypeId(value: number) {
    jspb.Message.setProto3IntField(this, 1, value);
  }


  /**
   * optional int32 year = 2;
   * @return {number}
   */
  getYear(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
  };


  /** @param {number} value */
  setYear(value: number) {
    jspb.Message.setProto3IntField(this, 2, value);
  }


  /**
   * optional int32 month = 3;
   * @return {number}
   */
  getMonth(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
  };


  /** @param {number} value */
  setMonth(value: number) {
    jspb.Message.setProto3IntField(this, 3, value);
  }


  /**
   * optional string date = 4;
   * @return {string}
   */
  getDate(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
  };


  /** @param {string} value */
  setDate(value: string) {
    jspb.Message.setProto3StringField(this, 4, value);
  }


  /**
   * optional int32 hour = 5;
   * @return {number}
   */
  getHour(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
  };


  /** @param {number} value */
  setHour(value: number) {
    jspb.Message.setProto3IntField(this, 5, value);
  }


  /**
   * optional int32 week = 6;
   * @return {number}
   */
  getWeek(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
  };


  /** @param {number} value */
  setWeek(value: number) {
    jspb.Message.setProto3IntField(this, 6, value);
  }


  /**
   * optional int32 total_passes = 7;
   * @return {number}
   */
  getTotalPasses(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
  };


  /** @param {number} value */
  setTotalPasses(value: number) {
    jspb.Message.setProto3IntField(this, 7, value);
  }


  /**
   * optional int32 total_time = 8;
   * @return {number}
   */
  getTotalTime(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
  };


  /** @param {number} value */
  setTotalTime(value: number) {
    jspb.Message.setProto3IntField(this, 8, value);
  }


  /**
   * optional string type_name = 9;
   * @return {string}
   */
  getTypeName(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
  };


  /** @param {string} value */
  setTypeName(value: string) {
    jspb.Message.setProto3StringField(this, 9, value);
  }


  /**
   * optional string type_color = 10;
   * @return {string}
   */
  getTypeColor(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
  };


  /** @param {string} value */
  setTypeColor(value: string) {
    jspb.Message.setProto3StringField(this, 10, value);
  }


  /**
   * optional int32 issued_by = 11;
   * @return {number}
   */
  getIssuedBy(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
  };


  /** @param {number} value */
  setIssuedBy(value: number) {
    jspb.Message.setProto3IntField(this, 11, value);
  }


  /**
   * optional int32 issued_to = 12;
   * @return {number}
   */
  getIssuedTo(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
  };


  /** @param {number} value */
  setIssuedTo(value: number) {
    jspb.Message.setProto3IntField(this, 12, value);
  }


}
export namespace InternalHallPassExpiredStat {
  export interface AsObject {
    issuedTo: number;
    noOfExpiredPasses: number;
    totalExpiredTime: number;
  }
}
export class InternalHallPassExpiredStat extends jspb.Message {
  static readonly displayName = "InternalHallPassExpiredStat";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): InternalHallPassExpiredStat.AsObject {
    return InternalHallPassExpiredStat.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!InternalHallPassExpiredStat} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: InternalHallPassExpiredStat) {
    var f: any;
    var obj: any = {
      issuedTo: jspb.Message.getFieldWithDefault(msg, 1, 0),
      noOfExpiredPasses: jspb.Message.getFieldWithDefault(msg, 2, 0),
      totalExpiredTime: jspb.Message.getFieldWithDefault(msg, 3, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!InternalHallPassExpiredStat}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new InternalHallPassExpiredStat;
    return InternalHallPassExpiredStat.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!InternalHallPassExpiredStat} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!InternalHallPassExpiredStat}
   */
  static deserializeBinaryFromReader(msg: InternalHallPassExpiredStat, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {number} */ (reader.readInt32());
        msg.setIssuedTo(value1);
        break;
      case 2:
        var value2 = /** @type {number} */ (reader.readInt32());
        msg.setNoOfExpiredPasses(value2);
        break;
      case 3:
        var value3 = /** @type {number} */ (reader.readInt32());
        msg.setTotalExpiredTime(value3);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!InternalHallPassExpiredStat} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: InternalHallPassExpiredStat, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getIssuedTo();
    if (f !== 0) {
      writer.writeInt32(
        1,
        f
      );
    }
    f = message.getNoOfExpiredPasses();
    if (f !== 0) {
      writer.writeInt32(
        2,
        f
      );
    }
    f = message.getTotalExpiredTime();
    if (f !== 0) {
      writer.writeInt32(
        3,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    InternalHallPassExpiredStat.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional int32 issued_to = 1;
   * @return {number}
   */
  getIssuedTo(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
  };


  /** @param {number} value */
  setIssuedTo(value: number) {
    jspb.Message.setProto3IntField(this, 1, value);
  }


  /**
   * optional int32 no_of_expired_passes = 2;
   * @return {number}
   */
  getNoOfExpiredPasses(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
  };


  /** @param {number} value */
  setNoOfExpiredPasses(value: number) {
    jspb.Message.setProto3IntField(this, 2, value);
  }


  /**
   * optional int32 total_expired_time = 3;
   * @return {number}
   */
  getTotalExpiredTime(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
  };


  /** @param {number} value */
  setTotalExpiredTime(value: number) {
    jspb.Message.setProto3IntField(this, 3, value);
  }


}
export namespace InternalGetHallPassSummaryReportByTypeRequest {
  export interface AsObject {
    mingaId: number;
    startDate: string;
    endDate: string;
    groupBy: string;
    issuedBy: number;
    issuedTo: number;
    passType: number;
    studentCreated: string;
    orderByTotalPasses?: SortOrder;
    orderByTotalTime?: SortOrder;
    issuedByPeopleList: number[];
    issuedToPeopleList: number[];
    passTypesList: number[];
  }
}
export class InternalGetHallPassSummaryReportByTypeRequest extends jspb.Message {
  static readonly displayName = "InternalGetHallPassSummaryReportByTypeRequest";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [11,12,13];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, InternalGetHallPassSummaryReportByTypeRequest.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): InternalGetHallPassSummaryReportByTypeRequest.AsObject {
    return InternalGetHallPassSummaryReportByTypeRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!InternalGetHallPassSummaryReportByTypeRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: InternalGetHallPassSummaryReportByTypeRequest) {
    var f: any;
    var obj: any = {
      mingaId: jspb.Message.getFieldWithDefault(msg, 1, 0),
      startDate: jspb.Message.getFieldWithDefault(msg, 2, ""),
      endDate: jspb.Message.getFieldWithDefault(msg, 3, ""),
      groupBy: jspb.Message.getFieldWithDefault(msg, 4, ""),
      issuedBy: jspb.Message.getFieldWithDefault(msg, 5, 0),
      issuedTo: jspb.Message.getFieldWithDefault(msg, 6, 0),
      passType: jspb.Message.getFieldWithDefault(msg, 7, 0),
      studentCreated: jspb.Message.getFieldWithDefault(msg, 8, ""),
      orderByTotalPasses: jspb.Message.getFieldWithDefault(msg, 9, 0),
      orderByTotalTime: jspb.Message.getFieldWithDefault(msg, 10, 0),
      issuedByPeopleList: (f = jspb.Message.getRepeatedField(msg, 11)) == null ? undefined : f,
      issuedToPeopleList: (f = jspb.Message.getRepeatedField(msg, 12)) == null ? undefined : f,
      passTypesList: (f = jspb.Message.getRepeatedField(msg, 13)) == null ? undefined : f
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!InternalGetHallPassSummaryReportByTypeRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new InternalGetHallPassSummaryReportByTypeRequest;
    return InternalGetHallPassSummaryReportByTypeRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!InternalGetHallPassSummaryReportByTypeRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!InternalGetHallPassSummaryReportByTypeRequest}
   */
  static deserializeBinaryFromReader(msg: InternalGetHallPassSummaryReportByTypeRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {number} */ (reader.readInt32());
        msg.setMingaId(value1);
        break;
      case 2:
        var value2 = /** @type {string} */ (reader.readString());
        msg.setStartDate(value2);
        break;
      case 3:
        var value3 = /** @type {string} */ (reader.readString());
        msg.setEndDate(value3);
        break;
      case 4:
        var value4 = /** @type {string} */ (reader.readString());
        msg.setGroupBy(value4);
        break;
      case 5:
        var value5 = /** @type {number} */ (reader.readInt32());
        msg.setIssuedBy(value5);
        break;
      case 6:
        var value6 = /** @type {number} */ (reader.readInt32());
        msg.setIssuedTo(value6);
        break;
      case 7:
        var value7 = /** @type {number} */ (reader.readInt32());
        msg.setPassType(value7);
        break;
      case 8:
        var value8 = /** @type {string} */ (reader.readString());
        msg.setStudentCreated(value8);
        break;
      case 9:
        var value9 = /** @type {!SortOrder} */ (reader.readEnum());
        msg.setOrderByTotalPasses(value9);
        break;
      case 10:
        var value10 = /** @type {!SortOrder} */ (reader.readEnum());
        msg.setOrderByTotalTime(value10);
        break;
      case 11:
        var value11 = /** @type {!Array<number>} */ (reader.readPackedInt32());
        msg.setIssuedByPeopleList(value11);
        break;
      case 12:
        var value12 = /** @type {!Array<number>} */ (reader.readPackedInt32());
        msg.setIssuedToPeopleList(value12);
        break;
      case 13:
        var value13 = /** @type {!Array<number>} */ (reader.readPackedInt32());
        msg.setPassTypesList(value13);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!InternalGetHallPassSummaryReportByTypeRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: InternalGetHallPassSummaryReportByTypeRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getMingaId();
    if (f !== 0) {
      writer.writeInt32(
        1,
        f
      );
    }
    f = message.getStartDate();
    if (f.length > 0) {
      writer.writeString(
        2,
        f
      );
    }
    f = message.getEndDate();
    if (f.length > 0) {
      writer.writeString(
        3,
        f
      );
    }
    f = message.getGroupBy();
    if (f.length > 0) {
      writer.writeString(
        4,
        f
      );
    }
    f = message.getIssuedBy();
    if (f !== 0) {
      writer.writeInt32(
        5,
        f
      );
    }
    f = message.getIssuedTo();
    if (f !== 0) {
      writer.writeInt32(
        6,
        f
      );
    }
    f = message.getPassType();
    if (f !== 0) {
      writer.writeInt32(
        7,
        f
      );
    }
    f = message.getStudentCreated();
    if (f.length > 0) {
      writer.writeString(
        8,
        f
      );
    }
    f = message.getOrderByTotalPasses();
    if (f !== 0.0) {
      writer.writeEnum(
        9,
        f
      );
    }
    f = message.getOrderByTotalTime();
    if (f !== 0.0) {
      writer.writeEnum(
        10,
        f
      );
    }
    f = message.getIssuedByPeopleList();
    if (f.length > 0) {
      writer.writePackedInt32(
        11,
        f
      );
    }
    f = message.getIssuedToPeopleList();
    if (f.length > 0) {
      writer.writePackedInt32(
        12,
        f
      );
    }
    f = message.getPassTypesList();
    if (f.length > 0) {
      writer.writePackedInt32(
        13,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    InternalGetHallPassSummaryReportByTypeRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional int32 minga_id = 1;
   * @return {number}
   */
  getMingaId(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
  };


  /** @param {number} value */
  setMingaId(value: number) {
    jspb.Message.setProto3IntField(this, 1, value);
  }


  /**
   * optional string start_date = 2;
   * @return {string}
   */
  getStartDate(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
  };


  /** @param {string} value */
  setStartDate(value: string) {
    jspb.Message.setProto3StringField(this, 2, value);
  }


  /**
   * optional string end_date = 3;
   * @return {string}
   */
  getEndDate(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
  };


  /** @param {string} value */
  setEndDate(value: string) {
    jspb.Message.setProto3StringField(this, 3, value);
  }


  /**
   * optional string group_by = 4;
   * @return {string}
   */
  getGroupBy(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
  };


  /** @param {string} value */
  setGroupBy(value: string) {
    jspb.Message.setProto3StringField(this, 4, value);
  }


  /**
   * optional int32 issued_by = 5;
   * @return {number}
   */
  getIssuedBy(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
  };


  /** @param {number} value */
  setIssuedBy(value: number) {
    jspb.Message.setProto3IntField(this, 5, value);
  }


  /**
   * optional int32 issued_to = 6;
   * @return {number}
   */
  getIssuedTo(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
  };


  /** @param {number} value */
  setIssuedTo(value: number) {
    jspb.Message.setProto3IntField(this, 6, value);
  }


  /**
   * optional int32 pass_type = 7;
   * @return {number}
   */
  getPassType(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
  };


  /** @param {number} value */
  setPassType(value: number) {
    jspb.Message.setProto3IntField(this, 7, value);
  }


  /**
   * optional string student_created = 8;
   * @return {string}
   */
  getStudentCreated(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
  };


  /** @param {string} value */
  setStudentCreated(value: string) {
    jspb.Message.setProto3StringField(this, 8, value);
  }


  /**
   * optional SortOrder order_by_total_passes = 9;
   * @return {!SortOrder}
   */
  getOrderByTotalPasses(): SortOrder {
    return /** @type {!SortOrder} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
  };


  /** @param {!SortOrder} value */
  setOrderByTotalPasses(value: SortOrder) {
    jspb.Message.setProto3EnumField(this, 9, value);
  }


  /**
   * optional SortOrder order_by_total_time = 10;
   * @return {!SortOrder}
   */
  getOrderByTotalTime(): SortOrder {
    return /** @type {!SortOrder} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
  };


  /** @param {!SortOrder} value */
  setOrderByTotalTime(value: SortOrder) {
    jspb.Message.setProto3EnumField(this, 10, value);
  }


  /**
   * repeated int32 issued_by_people = 11;
   * @return {!Array<number>}
   */
  getIssuedByPeopleList(): number[] {
    return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 11));
  };


  /** @param {!Array<number>} value */
  setIssuedByPeopleList(value: number[]) {
    jspb.Message.setField(this, 11, value || []);
  }


  /**
   * @param {number} value
   * @param {number=} opt_index
   */
  addIssuedByPeople(value: number, opt_index?: number): void {
    jspb.Message.addToRepeatedField(this, 11, value, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearIssuedByPeopleList() {
    this.setIssuedByPeopleList([]);
  }


  /**
   * repeated int32 issued_to_people = 12;
   * @return {!Array<number>}
   */
  getIssuedToPeopleList(): number[] {
    return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 12));
  };


  /** @param {!Array<number>} value */
  setIssuedToPeopleList(value: number[]) {
    jspb.Message.setField(this, 12, value || []);
  }


  /**
   * @param {number} value
   * @param {number=} opt_index
   */
  addIssuedToPeople(value: number, opt_index?: number): void {
    jspb.Message.addToRepeatedField(this, 12, value, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearIssuedToPeopleList() {
    this.setIssuedToPeopleList([]);
  }


  /**
   * repeated int32 pass_types = 13;
   * @return {!Array<number>}
   */
  getPassTypesList(): number[] {
    return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 13));
  };


  /** @param {!Array<number>} value */
  setPassTypesList(value: number[]) {
    jspb.Message.setField(this, 13, value || []);
  }


  /**
   * @param {number} value
   * @param {number=} opt_index
   */
  addPassTypes(value: number, opt_index?: number): void {
    jspb.Message.addToRepeatedField(this, 13, value, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearPassTypesList() {
    this.setPassTypesList([]);
  }


}
export namespace InternalGetHallPassSummaryReportByTypeResponse {
  export interface AsObject {
    statsList: InternalHallPassStat.AsObject[];
  }
}
export class InternalGetHallPassSummaryReportByTypeResponse extends jspb.Message {
  static readonly displayName = "InternalGetHallPassSummaryReportByTypeResponse";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [1];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, InternalGetHallPassSummaryReportByTypeResponse.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): InternalGetHallPassSummaryReportByTypeResponse.AsObject {
    return InternalGetHallPassSummaryReportByTypeResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!InternalGetHallPassSummaryReportByTypeResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: InternalGetHallPassSummaryReportByTypeResponse) {
    var f: any;
    var obj: any = {
      statsList: jspb.Message.toObjectList(msg.getStatsList(),
      InternalHallPassStat.toObject, includeInstance)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!InternalGetHallPassSummaryReportByTypeResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new InternalGetHallPassSummaryReportByTypeResponse;
    return InternalGetHallPassSummaryReportByTypeResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!InternalGetHallPassSummaryReportByTypeResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!InternalGetHallPassSummaryReportByTypeResponse}
   */
  static deserializeBinaryFromReader(msg: InternalGetHallPassSummaryReportByTypeResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new InternalHallPassStat;
        reader.readMessage(value1,InternalHallPassStat.deserializeBinaryFromReader);
        msg.addStats(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!InternalGetHallPassSummaryReportByTypeResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: InternalGetHallPassSummaryReportByTypeResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getStatsList();
    if (f.length > 0) {
      writer.writeRepeatedMessage(
        1,
        f,
        InternalHallPassStat.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    InternalGetHallPassSummaryReportByTypeResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * repeated InternalHallPassStat stats = 1;
   * @return {!Array<!InternalHallPassStat>}
   */
  getStatsList(): InternalHallPassStat[] {
    return /** @type{!Array<!InternalHallPassStat>} */ (
      jspb.Message.getRepeatedWrapperField(this, InternalHallPassStat, 1));
  }


  /** @param {!Array<!InternalHallPassStat>} value */
  setStatsList(value?: InternalHallPassStat[]) {
    jspb.Message.setRepeatedWrapperField(this, 1, value);
  }


  /**
   * @param {!InternalHallPassStat=} opt_value
   * @param {number=} opt_index
   * @return {!InternalHallPassStat}
   */
  addStats(opt_value?: InternalHallPassStat, opt_index?: number): InternalHallPassStat {
    return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, InternalHallPassStat, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearStatsList() {
    this.setStatsList([]);
  }


}
export namespace InternalGetHallPassTypeReportRequest {
  export interface AsObject {
    mingaId: number;
    startDate: string;
    endDate: string;
    issuedBy: number;
    issuedTo: number;
    passType: number;
    offset: number;
    limit: number;
    studentCreated: string;
    orderByTotalPasses?: SortOrder;
    orderByTotalTime?: SortOrder;
    issuedByPeopleList: number[];
    issuedToPeopleList: number[];
    passTypesList: number[];
  }
}
export class InternalGetHallPassTypeReportRequest extends jspb.Message {
  static readonly displayName = "InternalGetHallPassTypeReportRequest";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [12,13,14];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, InternalGetHallPassTypeReportRequest.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): InternalGetHallPassTypeReportRequest.AsObject {
    return InternalGetHallPassTypeReportRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!InternalGetHallPassTypeReportRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: InternalGetHallPassTypeReportRequest) {
    var f: any;
    var obj: any = {
      mingaId: jspb.Message.getFieldWithDefault(msg, 1, 0),
      startDate: jspb.Message.getFieldWithDefault(msg, 2, ""),
      endDate: jspb.Message.getFieldWithDefault(msg, 3, ""),
      issuedBy: jspb.Message.getFieldWithDefault(msg, 4, 0),
      issuedTo: jspb.Message.getFieldWithDefault(msg, 5, 0),
      passType: jspb.Message.getFieldWithDefault(msg, 6, 0),
      offset: jspb.Message.getFieldWithDefault(msg, 7, 0),
      limit: jspb.Message.getFieldWithDefault(msg, 8, 0),
      studentCreated: jspb.Message.getFieldWithDefault(msg, 9, ""),
      orderByTotalPasses: jspb.Message.getFieldWithDefault(msg, 10, 0),
      orderByTotalTime: jspb.Message.getFieldWithDefault(msg, 11, 0),
      issuedByPeopleList: (f = jspb.Message.getRepeatedField(msg, 12)) == null ? undefined : f,
      issuedToPeopleList: (f = jspb.Message.getRepeatedField(msg, 13)) == null ? undefined : f,
      passTypesList: (f = jspb.Message.getRepeatedField(msg, 14)) == null ? undefined : f
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!InternalGetHallPassTypeReportRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new InternalGetHallPassTypeReportRequest;
    return InternalGetHallPassTypeReportRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!InternalGetHallPassTypeReportRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!InternalGetHallPassTypeReportRequest}
   */
  static deserializeBinaryFromReader(msg: InternalGetHallPassTypeReportRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {number} */ (reader.readInt32());
        msg.setMingaId(value1);
        break;
      case 2:
        var value2 = /** @type {string} */ (reader.readString());
        msg.setStartDate(value2);
        break;
      case 3:
        var value3 = /** @type {string} */ (reader.readString());
        msg.setEndDate(value3);
        break;
      case 4:
        var value4 = /** @type {number} */ (reader.readInt32());
        msg.setIssuedBy(value4);
        break;
      case 5:
        var value5 = /** @type {number} */ (reader.readInt32());
        msg.setIssuedTo(value5);
        break;
      case 6:
        var value6 = /** @type {number} */ (reader.readInt32());
        msg.setPassType(value6);
        break;
      case 7:
        var value7 = /** @type {number} */ (reader.readInt32());
        msg.setOffset(value7);
        break;
      case 8:
        var value8 = /** @type {number} */ (reader.readInt32());
        msg.setLimit(value8);
        break;
      case 9:
        var value9 = /** @type {string} */ (reader.readString());
        msg.setStudentCreated(value9);
        break;
      case 10:
        var value10 = /** @type {!SortOrder} */ (reader.readEnum());
        msg.setOrderByTotalPasses(value10);
        break;
      case 11:
        var value11 = /** @type {!SortOrder} */ (reader.readEnum());
        msg.setOrderByTotalTime(value11);
        break;
      case 12:
        var value12 = /** @type {!Array<number>} */ (reader.readPackedInt32());
        msg.setIssuedByPeopleList(value12);
        break;
      case 13:
        var value13 = /** @type {!Array<number>} */ (reader.readPackedInt32());
        msg.setIssuedToPeopleList(value13);
        break;
      case 14:
        var value14 = /** @type {!Array<number>} */ (reader.readPackedInt32());
        msg.setPassTypesList(value14);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!InternalGetHallPassTypeReportRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: InternalGetHallPassTypeReportRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getMingaId();
    if (f !== 0) {
      writer.writeInt32(
        1,
        f
      );
    }
    f = message.getStartDate();
    if (f.length > 0) {
      writer.writeString(
        2,
        f
      );
    }
    f = message.getEndDate();
    if (f.length > 0) {
      writer.writeString(
        3,
        f
      );
    }
    f = message.getIssuedBy();
    if (f !== 0) {
      writer.writeInt32(
        4,
        f
      );
    }
    f = message.getIssuedTo();
    if (f !== 0) {
      writer.writeInt32(
        5,
        f
      );
    }
    f = message.getPassType();
    if (f !== 0) {
      writer.writeInt32(
        6,
        f
      );
    }
    f = message.getOffset();
    if (f !== 0) {
      writer.writeInt32(
        7,
        f
      );
    }
    f = message.getLimit();
    if (f !== 0) {
      writer.writeInt32(
        8,
        f
      );
    }
    f = message.getStudentCreated();
    if (f.length > 0) {
      writer.writeString(
        9,
        f
      );
    }
    f = message.getOrderByTotalPasses();
    if (f !== 0.0) {
      writer.writeEnum(
        10,
        f
      );
    }
    f = message.getOrderByTotalTime();
    if (f !== 0.0) {
      writer.writeEnum(
        11,
        f
      );
    }
    f = message.getIssuedByPeopleList();
    if (f.length > 0) {
      writer.writePackedInt32(
        12,
        f
      );
    }
    f = message.getIssuedToPeopleList();
    if (f.length > 0) {
      writer.writePackedInt32(
        13,
        f
      );
    }
    f = message.getPassTypesList();
    if (f.length > 0) {
      writer.writePackedInt32(
        14,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    InternalGetHallPassTypeReportRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional int32 minga_id = 1;
   * @return {number}
   */
  getMingaId(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
  };


  /** @param {number} value */
  setMingaId(value: number) {
    jspb.Message.setProto3IntField(this, 1, value);
  }


  /**
   * optional string start_date = 2;
   * @return {string}
   */
  getStartDate(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
  };


  /** @param {string} value */
  setStartDate(value: string) {
    jspb.Message.setProto3StringField(this, 2, value);
  }


  /**
   * optional string end_date = 3;
   * @return {string}
   */
  getEndDate(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
  };


  /** @param {string} value */
  setEndDate(value: string) {
    jspb.Message.setProto3StringField(this, 3, value);
  }


  /**
   * optional int32 issued_by = 4;
   * @return {number}
   */
  getIssuedBy(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
  };


  /** @param {number} value */
  setIssuedBy(value: number) {
    jspb.Message.setProto3IntField(this, 4, value);
  }


  /**
   * optional int32 issued_to = 5;
   * @return {number}
   */
  getIssuedTo(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
  };


  /** @param {number} value */
  setIssuedTo(value: number) {
    jspb.Message.setProto3IntField(this, 5, value);
  }


  /**
   * optional int32 pass_type = 6;
   * @return {number}
   */
  getPassType(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
  };


  /** @param {number} value */
  setPassType(value: number) {
    jspb.Message.setProto3IntField(this, 6, value);
  }


  /**
   * optional int32 offset = 7;
   * @return {number}
   */
  getOffset(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
  };


  /** @param {number} value */
  setOffset(value: number) {
    jspb.Message.setProto3IntField(this, 7, value);
  }


  /**
   * optional int32 limit = 8;
   * @return {number}
   */
  getLimit(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
  };


  /** @param {number} value */
  setLimit(value: number) {
    jspb.Message.setProto3IntField(this, 8, value);
  }


  /**
   * optional string student_created = 9;
   * @return {string}
   */
  getStudentCreated(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
  };


  /** @param {string} value */
  setStudentCreated(value: string) {
    jspb.Message.setProto3StringField(this, 9, value);
  }


  /**
   * optional SortOrder order_by_total_passes = 10;
   * @return {!SortOrder}
   */
  getOrderByTotalPasses(): SortOrder {
    return /** @type {!SortOrder} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
  };


  /** @param {!SortOrder} value */
  setOrderByTotalPasses(value: SortOrder) {
    jspb.Message.setProto3EnumField(this, 10, value);
  }


  /**
   * optional SortOrder order_by_total_time = 11;
   * @return {!SortOrder}
   */
  getOrderByTotalTime(): SortOrder {
    return /** @type {!SortOrder} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
  };


  /** @param {!SortOrder} value */
  setOrderByTotalTime(value: SortOrder) {
    jspb.Message.setProto3EnumField(this, 11, value);
  }


  /**
   * repeated int32 issued_by_people = 12;
   * @return {!Array<number>}
   */
  getIssuedByPeopleList(): number[] {
    return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 12));
  };


  /** @param {!Array<number>} value */
  setIssuedByPeopleList(value: number[]) {
    jspb.Message.setField(this, 12, value || []);
  }


  /**
   * @param {number} value
   * @param {number=} opt_index
   */
  addIssuedByPeople(value: number, opt_index?: number): void {
    jspb.Message.addToRepeatedField(this, 12, value, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearIssuedByPeopleList() {
    this.setIssuedByPeopleList([]);
  }


  /**
   * repeated int32 issued_to_people = 13;
   * @return {!Array<number>}
   */
  getIssuedToPeopleList(): number[] {
    return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 13));
  };


  /** @param {!Array<number>} value */
  setIssuedToPeopleList(value: number[]) {
    jspb.Message.setField(this, 13, value || []);
  }


  /**
   * @param {number} value
   * @param {number=} opt_index
   */
  addIssuedToPeople(value: number, opt_index?: number): void {
    jspb.Message.addToRepeatedField(this, 13, value, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearIssuedToPeopleList() {
    this.setIssuedToPeopleList([]);
  }


  /**
   * repeated int32 pass_types = 14;
   * @return {!Array<number>}
   */
  getPassTypesList(): number[] {
    return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 14));
  };


  /** @param {!Array<number>} value */
  setPassTypesList(value: number[]) {
    jspb.Message.setField(this, 14, value || []);
  }


  /**
   * @param {number} value
   * @param {number=} opt_index
   */
  addPassTypes(value: number, opt_index?: number): void {
    jspb.Message.addToRepeatedField(this, 14, value, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearPassTypesList() {
    this.setPassTypesList([]);
  }


}
export namespace InternalGetHallPassTypeReportResponse {
  export interface AsObject {
    statsList: InternalHallPassStat.AsObject[];
  }
}
export class InternalGetHallPassTypeReportResponse extends jspb.Message {
  static readonly displayName = "InternalGetHallPassTypeReportResponse";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [1];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, InternalGetHallPassTypeReportResponse.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): InternalGetHallPassTypeReportResponse.AsObject {
    return InternalGetHallPassTypeReportResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!InternalGetHallPassTypeReportResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: InternalGetHallPassTypeReportResponse) {
    var f: any;
    var obj: any = {
      statsList: jspb.Message.toObjectList(msg.getStatsList(),
      InternalHallPassStat.toObject, includeInstance)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!InternalGetHallPassTypeReportResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new InternalGetHallPassTypeReportResponse;
    return InternalGetHallPassTypeReportResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!InternalGetHallPassTypeReportResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!InternalGetHallPassTypeReportResponse}
   */
  static deserializeBinaryFromReader(msg: InternalGetHallPassTypeReportResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new InternalHallPassStat;
        reader.readMessage(value1,InternalHallPassStat.deserializeBinaryFromReader);
        msg.addStats(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!InternalGetHallPassTypeReportResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: InternalGetHallPassTypeReportResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getStatsList();
    if (f.length > 0) {
      writer.writeRepeatedMessage(
        1,
        f,
        InternalHallPassStat.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    InternalGetHallPassTypeReportResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * repeated InternalHallPassStat stats = 1;
   * @return {!Array<!InternalHallPassStat>}
   */
  getStatsList(): InternalHallPassStat[] {
    return /** @type{!Array<!InternalHallPassStat>} */ (
      jspb.Message.getRepeatedWrapperField(this, InternalHallPassStat, 1));
  }


  /** @param {!Array<!InternalHallPassStat>} value */
  setStatsList(value?: InternalHallPassStat[]) {
    jspb.Message.setRepeatedWrapperField(this, 1, value);
  }


  /**
   * @param {!InternalHallPassStat=} opt_value
   * @param {number=} opt_index
   * @return {!InternalHallPassStat}
   */
  addStats(opt_value?: InternalHallPassStat, opt_index?: number): InternalHallPassStat {
    return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, InternalHallPassStat, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearStatsList() {
    this.setStatsList([]);
  }


}
export namespace InternalGetHallPassTopUsersRequest {
  export interface AsObject {
    mingaId: number;
    startDate: string;
    endDate: string;
    issuedBy: number;
    issuedTo: number;
    passType: number;
    offset: number;
    limit: number;
    studentCreated: string;
    orderByTotalPasses?: SortOrder;
    orderByTotalTime?: SortOrder;
    issuedByPeopleList: number[];
    issuedToPeopleList: number[];
    passTypesList: number[];
  }
}
export class InternalGetHallPassTopUsersRequest extends jspb.Message {
  static readonly displayName = "InternalGetHallPassTopUsersRequest";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [12,13,14];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, InternalGetHallPassTopUsersRequest.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): InternalGetHallPassTopUsersRequest.AsObject {
    return InternalGetHallPassTopUsersRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!InternalGetHallPassTopUsersRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: InternalGetHallPassTopUsersRequest) {
    var f: any;
    var obj: any = {
      mingaId: jspb.Message.getFieldWithDefault(msg, 1, 0),
      startDate: jspb.Message.getFieldWithDefault(msg, 2, ""),
      endDate: jspb.Message.getFieldWithDefault(msg, 3, ""),
      issuedBy: jspb.Message.getFieldWithDefault(msg, 4, 0),
      issuedTo: jspb.Message.getFieldWithDefault(msg, 5, 0),
      passType: jspb.Message.getFieldWithDefault(msg, 6, 0),
      offset: jspb.Message.getFieldWithDefault(msg, 7, 0),
      limit: jspb.Message.getFieldWithDefault(msg, 8, 0),
      studentCreated: jspb.Message.getFieldWithDefault(msg, 9, ""),
      orderByTotalPasses: jspb.Message.getFieldWithDefault(msg, 10, 0),
      orderByTotalTime: jspb.Message.getFieldWithDefault(msg, 11, 0),
      issuedByPeopleList: (f = jspb.Message.getRepeatedField(msg, 12)) == null ? undefined : f,
      issuedToPeopleList: (f = jspb.Message.getRepeatedField(msg, 13)) == null ? undefined : f,
      passTypesList: (f = jspb.Message.getRepeatedField(msg, 14)) == null ? undefined : f
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!InternalGetHallPassTopUsersRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new InternalGetHallPassTopUsersRequest;
    return InternalGetHallPassTopUsersRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!InternalGetHallPassTopUsersRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!InternalGetHallPassTopUsersRequest}
   */
  static deserializeBinaryFromReader(msg: InternalGetHallPassTopUsersRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {number} */ (reader.readInt32());
        msg.setMingaId(value1);
        break;
      case 2:
        var value2 = /** @type {string} */ (reader.readString());
        msg.setStartDate(value2);
        break;
      case 3:
        var value3 = /** @type {string} */ (reader.readString());
        msg.setEndDate(value3);
        break;
      case 4:
        var value4 = /** @type {number} */ (reader.readInt32());
        msg.setIssuedBy(value4);
        break;
      case 5:
        var value5 = /** @type {number} */ (reader.readInt32());
        msg.setIssuedTo(value5);
        break;
      case 6:
        var value6 = /** @type {number} */ (reader.readInt32());
        msg.setPassType(value6);
        break;
      case 7:
        var value7 = /** @type {number} */ (reader.readInt32());
        msg.setOffset(value7);
        break;
      case 8:
        var value8 = /** @type {number} */ (reader.readInt32());
        msg.setLimit(value8);
        break;
      case 9:
        var value9 = /** @type {string} */ (reader.readString());
        msg.setStudentCreated(value9);
        break;
      case 10:
        var value10 = /** @type {!SortOrder} */ (reader.readEnum());
        msg.setOrderByTotalPasses(value10);
        break;
      case 11:
        var value11 = /** @type {!SortOrder} */ (reader.readEnum());
        msg.setOrderByTotalTime(value11);
        break;
      case 12:
        var value12 = /** @type {!Array<number>} */ (reader.readPackedInt32());
        msg.setIssuedByPeopleList(value12);
        break;
      case 13:
        var value13 = /** @type {!Array<number>} */ (reader.readPackedInt32());
        msg.setIssuedToPeopleList(value13);
        break;
      case 14:
        var value14 = /** @type {!Array<number>} */ (reader.readPackedInt32());
        msg.setPassTypesList(value14);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!InternalGetHallPassTopUsersRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: InternalGetHallPassTopUsersRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getMingaId();
    if (f !== 0) {
      writer.writeInt32(
        1,
        f
      );
    }
    f = message.getStartDate();
    if (f.length > 0) {
      writer.writeString(
        2,
        f
      );
    }
    f = message.getEndDate();
    if (f.length > 0) {
      writer.writeString(
        3,
        f
      );
    }
    f = message.getIssuedBy();
    if (f !== 0) {
      writer.writeInt32(
        4,
        f
      );
    }
    f = message.getIssuedTo();
    if (f !== 0) {
      writer.writeInt32(
        5,
        f
      );
    }
    f = message.getPassType();
    if (f !== 0) {
      writer.writeInt32(
        6,
        f
      );
    }
    f = message.getOffset();
    if (f !== 0) {
      writer.writeInt32(
        7,
        f
      );
    }
    f = message.getLimit();
    if (f !== 0) {
      writer.writeInt32(
        8,
        f
      );
    }
    f = message.getStudentCreated();
    if (f.length > 0) {
      writer.writeString(
        9,
        f
      );
    }
    f = message.getOrderByTotalPasses();
    if (f !== 0.0) {
      writer.writeEnum(
        10,
        f
      );
    }
    f = message.getOrderByTotalTime();
    if (f !== 0.0) {
      writer.writeEnum(
        11,
        f
      );
    }
    f = message.getIssuedByPeopleList();
    if (f.length > 0) {
      writer.writePackedInt32(
        12,
        f
      );
    }
    f = message.getIssuedToPeopleList();
    if (f.length > 0) {
      writer.writePackedInt32(
        13,
        f
      );
    }
    f = message.getPassTypesList();
    if (f.length > 0) {
      writer.writePackedInt32(
        14,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    InternalGetHallPassTopUsersRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional int32 minga_id = 1;
   * @return {number}
   */
  getMingaId(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
  };


  /** @param {number} value */
  setMingaId(value: number) {
    jspb.Message.setProto3IntField(this, 1, value);
  }


  /**
   * optional string start_date = 2;
   * @return {string}
   */
  getStartDate(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
  };


  /** @param {string} value */
  setStartDate(value: string) {
    jspb.Message.setProto3StringField(this, 2, value);
  }


  /**
   * optional string end_date = 3;
   * @return {string}
   */
  getEndDate(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
  };


  /** @param {string} value */
  setEndDate(value: string) {
    jspb.Message.setProto3StringField(this, 3, value);
  }


  /**
   * optional int32 issued_by = 4;
   * @return {number}
   */
  getIssuedBy(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
  };


  /** @param {number} value */
  setIssuedBy(value: number) {
    jspb.Message.setProto3IntField(this, 4, value);
  }


  /**
   * optional int32 issued_to = 5;
   * @return {number}
   */
  getIssuedTo(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
  };


  /** @param {number} value */
  setIssuedTo(value: number) {
    jspb.Message.setProto3IntField(this, 5, value);
  }


  /**
   * optional int32 pass_type = 6;
   * @return {number}
   */
  getPassType(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
  };


  /** @param {number} value */
  setPassType(value: number) {
    jspb.Message.setProto3IntField(this, 6, value);
  }


  /**
   * optional int32 offset = 7;
   * @return {number}
   */
  getOffset(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
  };


  /** @param {number} value */
  setOffset(value: number) {
    jspb.Message.setProto3IntField(this, 7, value);
  }


  /**
   * optional int32 limit = 8;
   * @return {number}
   */
  getLimit(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
  };


  /** @param {number} value */
  setLimit(value: number) {
    jspb.Message.setProto3IntField(this, 8, value);
  }


  /**
   * optional string student_created = 9;
   * @return {string}
   */
  getStudentCreated(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
  };


  /** @param {string} value */
  setStudentCreated(value: string) {
    jspb.Message.setProto3StringField(this, 9, value);
  }


  /**
   * optional SortOrder order_by_total_passes = 10;
   * @return {!SortOrder}
   */
  getOrderByTotalPasses(): SortOrder {
    return /** @type {!SortOrder} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
  };


  /** @param {!SortOrder} value */
  setOrderByTotalPasses(value: SortOrder) {
    jspb.Message.setProto3EnumField(this, 10, value);
  }


  /**
   * optional SortOrder order_by_total_time = 11;
   * @return {!SortOrder}
   */
  getOrderByTotalTime(): SortOrder {
    return /** @type {!SortOrder} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
  };


  /** @param {!SortOrder} value */
  setOrderByTotalTime(value: SortOrder) {
    jspb.Message.setProto3EnumField(this, 11, value);
  }


  /**
   * repeated int32 issued_by_people = 12;
   * @return {!Array<number>}
   */
  getIssuedByPeopleList(): number[] {
    return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 12));
  };


  /** @param {!Array<number>} value */
  setIssuedByPeopleList(value: number[]) {
    jspb.Message.setField(this, 12, value || []);
  }


  /**
   * @param {number} value
   * @param {number=} opt_index
   */
  addIssuedByPeople(value: number, opt_index?: number): void {
    jspb.Message.addToRepeatedField(this, 12, value, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearIssuedByPeopleList() {
    this.setIssuedByPeopleList([]);
  }


  /**
   * repeated int32 issued_to_people = 13;
   * @return {!Array<number>}
   */
  getIssuedToPeopleList(): number[] {
    return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 13));
  };


  /** @param {!Array<number>} value */
  setIssuedToPeopleList(value: number[]) {
    jspb.Message.setField(this, 13, value || []);
  }


  /**
   * @param {number} value
   * @param {number=} opt_index
   */
  addIssuedToPeople(value: number, opt_index?: number): void {
    jspb.Message.addToRepeatedField(this, 13, value, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearIssuedToPeopleList() {
    this.setIssuedToPeopleList([]);
  }


  /**
   * repeated int32 pass_types = 14;
   * @return {!Array<number>}
   */
  getPassTypesList(): number[] {
    return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 14));
  };


  /** @param {!Array<number>} value */
  setPassTypesList(value: number[]) {
    jspb.Message.setField(this, 14, value || []);
  }


  /**
   * @param {number} value
   * @param {number=} opt_index
   */
  addPassTypes(value: number, opt_index?: number): void {
    jspb.Message.addToRepeatedField(this, 14, value, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearPassTypesList() {
    this.setPassTypesList([]);
  }


}
export namespace InternalGetHallPassTopUsersResponse {
  export interface AsObject {
    statsList: InternalHallPassStat.AsObject[];
  }
}
export class InternalGetHallPassTopUsersResponse extends jspb.Message {
  static readonly displayName = "InternalGetHallPassTopUsersResponse";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [1];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, InternalGetHallPassTopUsersResponse.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): InternalGetHallPassTopUsersResponse.AsObject {
    return InternalGetHallPassTopUsersResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!InternalGetHallPassTopUsersResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: InternalGetHallPassTopUsersResponse) {
    var f: any;
    var obj: any = {
      statsList: jspb.Message.toObjectList(msg.getStatsList(),
      InternalHallPassStat.toObject, includeInstance)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!InternalGetHallPassTopUsersResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new InternalGetHallPassTopUsersResponse;
    return InternalGetHallPassTopUsersResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!InternalGetHallPassTopUsersResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!InternalGetHallPassTopUsersResponse}
   */
  static deserializeBinaryFromReader(msg: InternalGetHallPassTopUsersResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new InternalHallPassStat;
        reader.readMessage(value1,InternalHallPassStat.deserializeBinaryFromReader);
        msg.addStats(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!InternalGetHallPassTopUsersResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: InternalGetHallPassTopUsersResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getStatsList();
    if (f.length > 0) {
      writer.writeRepeatedMessage(
        1,
        f,
        InternalHallPassStat.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    InternalGetHallPassTopUsersResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * repeated InternalHallPassStat stats = 1;
   * @return {!Array<!InternalHallPassStat>}
   */
  getStatsList(): InternalHallPassStat[] {
    return /** @type{!Array<!InternalHallPassStat>} */ (
      jspb.Message.getRepeatedWrapperField(this, InternalHallPassStat, 1));
  }


  /** @param {!Array<!InternalHallPassStat>} value */
  setStatsList(value?: InternalHallPassStat[]) {
    jspb.Message.setRepeatedWrapperField(this, 1, value);
  }


  /**
   * @param {!InternalHallPassStat=} opt_value
   * @param {number=} opt_index
   * @return {!InternalHallPassStat}
   */
  addStats(opt_value?: InternalHallPassStat, opt_index?: number): InternalHallPassStat {
    return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, InternalHallPassStat, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearStatsList() {
    this.setStatsList([]);
  }


}
export namespace InternalGetHallPassTopCreatorsRequest {
  export interface AsObject {
    mingaId: number;
    startDate: string;
    endDate: string;
    issuedBy: number;
    issuedTo: number;
    passType: number;
    offset: number;
    limit: number;
    studentCreated: string;
    orderByTotalPasses?: SortOrder;
    orderByTotalTime?: SortOrder;
    issuedByPeopleList: number[];
    issuedToPeopleList: number[];
    passTypesList: number[];
  }
}
export class InternalGetHallPassTopCreatorsRequest extends jspb.Message {
  static readonly displayName = "InternalGetHallPassTopCreatorsRequest";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [12,13,14];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, InternalGetHallPassTopCreatorsRequest.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): InternalGetHallPassTopCreatorsRequest.AsObject {
    return InternalGetHallPassTopCreatorsRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!InternalGetHallPassTopCreatorsRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: InternalGetHallPassTopCreatorsRequest) {
    var f: any;
    var obj: any = {
      mingaId: jspb.Message.getFieldWithDefault(msg, 1, 0),
      startDate: jspb.Message.getFieldWithDefault(msg, 2, ""),
      endDate: jspb.Message.getFieldWithDefault(msg, 3, ""),
      issuedBy: jspb.Message.getFieldWithDefault(msg, 4, 0),
      issuedTo: jspb.Message.getFieldWithDefault(msg, 5, 0),
      passType: jspb.Message.getFieldWithDefault(msg, 6, 0),
      offset: jspb.Message.getFieldWithDefault(msg, 7, 0),
      limit: jspb.Message.getFieldWithDefault(msg, 8, 0),
      studentCreated: jspb.Message.getFieldWithDefault(msg, 9, ""),
      orderByTotalPasses: jspb.Message.getFieldWithDefault(msg, 10, 0),
      orderByTotalTime: jspb.Message.getFieldWithDefault(msg, 11, 0),
      issuedByPeopleList: (f = jspb.Message.getRepeatedField(msg, 12)) == null ? undefined : f,
      issuedToPeopleList: (f = jspb.Message.getRepeatedField(msg, 13)) == null ? undefined : f,
      passTypesList: (f = jspb.Message.getRepeatedField(msg, 14)) == null ? undefined : f
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!InternalGetHallPassTopCreatorsRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new InternalGetHallPassTopCreatorsRequest;
    return InternalGetHallPassTopCreatorsRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!InternalGetHallPassTopCreatorsRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!InternalGetHallPassTopCreatorsRequest}
   */
  static deserializeBinaryFromReader(msg: InternalGetHallPassTopCreatorsRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {number} */ (reader.readInt32());
        msg.setMingaId(value1);
        break;
      case 2:
        var value2 = /** @type {string} */ (reader.readString());
        msg.setStartDate(value2);
        break;
      case 3:
        var value3 = /** @type {string} */ (reader.readString());
        msg.setEndDate(value3);
        break;
      case 4:
        var value4 = /** @type {number} */ (reader.readInt32());
        msg.setIssuedBy(value4);
        break;
      case 5:
        var value5 = /** @type {number} */ (reader.readInt32());
        msg.setIssuedTo(value5);
        break;
      case 6:
        var value6 = /** @type {number} */ (reader.readInt32());
        msg.setPassType(value6);
        break;
      case 7:
        var value7 = /** @type {number} */ (reader.readInt32());
        msg.setOffset(value7);
        break;
      case 8:
        var value8 = /** @type {number} */ (reader.readInt32());
        msg.setLimit(value8);
        break;
      case 9:
        var value9 = /** @type {string} */ (reader.readString());
        msg.setStudentCreated(value9);
        break;
      case 10:
        var value10 = /** @type {!SortOrder} */ (reader.readEnum());
        msg.setOrderByTotalPasses(value10);
        break;
      case 11:
        var value11 = /** @type {!SortOrder} */ (reader.readEnum());
        msg.setOrderByTotalTime(value11);
        break;
      case 12:
        var value12 = /** @type {!Array<number>} */ (reader.readPackedInt32());
        msg.setIssuedByPeopleList(value12);
        break;
      case 13:
        var value13 = /** @type {!Array<number>} */ (reader.readPackedInt32());
        msg.setIssuedToPeopleList(value13);
        break;
      case 14:
        var value14 = /** @type {!Array<number>} */ (reader.readPackedInt32());
        msg.setPassTypesList(value14);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!InternalGetHallPassTopCreatorsRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: InternalGetHallPassTopCreatorsRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getMingaId();
    if (f !== 0) {
      writer.writeInt32(
        1,
        f
      );
    }
    f = message.getStartDate();
    if (f.length > 0) {
      writer.writeString(
        2,
        f
      );
    }
    f = message.getEndDate();
    if (f.length > 0) {
      writer.writeString(
        3,
        f
      );
    }
    f = message.getIssuedBy();
    if (f !== 0) {
      writer.writeInt32(
        4,
        f
      );
    }
    f = message.getIssuedTo();
    if (f !== 0) {
      writer.writeInt32(
        5,
        f
      );
    }
    f = message.getPassType();
    if (f !== 0) {
      writer.writeInt32(
        6,
        f
      );
    }
    f = message.getOffset();
    if (f !== 0) {
      writer.writeInt32(
        7,
        f
      );
    }
    f = message.getLimit();
    if (f !== 0) {
      writer.writeInt32(
        8,
        f
      );
    }
    f = message.getStudentCreated();
    if (f.length > 0) {
      writer.writeString(
        9,
        f
      );
    }
    f = message.getOrderByTotalPasses();
    if (f !== 0.0) {
      writer.writeEnum(
        10,
        f
      );
    }
    f = message.getOrderByTotalTime();
    if (f !== 0.0) {
      writer.writeEnum(
        11,
        f
      );
    }
    f = message.getIssuedByPeopleList();
    if (f.length > 0) {
      writer.writePackedInt32(
        12,
        f
      );
    }
    f = message.getIssuedToPeopleList();
    if (f.length > 0) {
      writer.writePackedInt32(
        13,
        f
      );
    }
    f = message.getPassTypesList();
    if (f.length > 0) {
      writer.writePackedInt32(
        14,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    InternalGetHallPassTopCreatorsRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional int32 minga_id = 1;
   * @return {number}
   */
  getMingaId(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
  };


  /** @param {number} value */
  setMingaId(value: number) {
    jspb.Message.setProto3IntField(this, 1, value);
  }


  /**
   * optional string start_date = 2;
   * @return {string}
   */
  getStartDate(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
  };


  /** @param {string} value */
  setStartDate(value: string) {
    jspb.Message.setProto3StringField(this, 2, value);
  }


  /**
   * optional string end_date = 3;
   * @return {string}
   */
  getEndDate(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
  };


  /** @param {string} value */
  setEndDate(value: string) {
    jspb.Message.setProto3StringField(this, 3, value);
  }


  /**
   * optional int32 issued_by = 4;
   * @return {number}
   */
  getIssuedBy(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
  };


  /** @param {number} value */
  setIssuedBy(value: number) {
    jspb.Message.setProto3IntField(this, 4, value);
  }


  /**
   * optional int32 issued_to = 5;
   * @return {number}
   */
  getIssuedTo(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
  };


  /** @param {number} value */
  setIssuedTo(value: number) {
    jspb.Message.setProto3IntField(this, 5, value);
  }


  /**
   * optional int32 pass_type = 6;
   * @return {number}
   */
  getPassType(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
  };


  /** @param {number} value */
  setPassType(value: number) {
    jspb.Message.setProto3IntField(this, 6, value);
  }


  /**
   * optional int32 offset = 7;
   * @return {number}
   */
  getOffset(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
  };


  /** @param {number} value */
  setOffset(value: number) {
    jspb.Message.setProto3IntField(this, 7, value);
  }


  /**
   * optional int32 limit = 8;
   * @return {number}
   */
  getLimit(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
  };


  /** @param {number} value */
  setLimit(value: number) {
    jspb.Message.setProto3IntField(this, 8, value);
  }


  /**
   * optional string student_created = 9;
   * @return {string}
   */
  getStudentCreated(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
  };


  /** @param {string} value */
  setStudentCreated(value: string) {
    jspb.Message.setProto3StringField(this, 9, value);
  }


  /**
   * optional SortOrder order_by_total_passes = 10;
   * @return {!SortOrder}
   */
  getOrderByTotalPasses(): SortOrder {
    return /** @type {!SortOrder} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
  };


  /** @param {!SortOrder} value */
  setOrderByTotalPasses(value: SortOrder) {
    jspb.Message.setProto3EnumField(this, 10, value);
  }


  /**
   * optional SortOrder order_by_total_time = 11;
   * @return {!SortOrder}
   */
  getOrderByTotalTime(): SortOrder {
    return /** @type {!SortOrder} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
  };


  /** @param {!SortOrder} value */
  setOrderByTotalTime(value: SortOrder) {
    jspb.Message.setProto3EnumField(this, 11, value);
  }


  /**
   * repeated int32 issued_by_people = 12;
   * @return {!Array<number>}
   */
  getIssuedByPeopleList(): number[] {
    return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 12));
  };


  /** @param {!Array<number>} value */
  setIssuedByPeopleList(value: number[]) {
    jspb.Message.setField(this, 12, value || []);
  }


  /**
   * @param {number} value
   * @param {number=} opt_index
   */
  addIssuedByPeople(value: number, opt_index?: number): void {
    jspb.Message.addToRepeatedField(this, 12, value, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearIssuedByPeopleList() {
    this.setIssuedByPeopleList([]);
  }


  /**
   * repeated int32 issued_to_people = 13;
   * @return {!Array<number>}
   */
  getIssuedToPeopleList(): number[] {
    return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 13));
  };


  /** @param {!Array<number>} value */
  setIssuedToPeopleList(value: number[]) {
    jspb.Message.setField(this, 13, value || []);
  }


  /**
   * @param {number} value
   * @param {number=} opt_index
   */
  addIssuedToPeople(value: number, opt_index?: number): void {
    jspb.Message.addToRepeatedField(this, 13, value, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearIssuedToPeopleList() {
    this.setIssuedToPeopleList([]);
  }


  /**
   * repeated int32 pass_types = 14;
   * @return {!Array<number>}
   */
  getPassTypesList(): number[] {
    return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 14));
  };


  /** @param {!Array<number>} value */
  setPassTypesList(value: number[]) {
    jspb.Message.setField(this, 14, value || []);
  }


  /**
   * @param {number} value
   * @param {number=} opt_index
   */
  addPassTypes(value: number, opt_index?: number): void {
    jspb.Message.addToRepeatedField(this, 14, value, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearPassTypesList() {
    this.setPassTypesList([]);
  }


}
export namespace InternalGetHallPassTopCreatorsResponse {
  export interface AsObject {
    statsList: InternalHallPassStat.AsObject[];
  }
}
export class InternalGetHallPassTopCreatorsResponse extends jspb.Message {
  static readonly displayName = "InternalGetHallPassTopCreatorsResponse";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [1];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, InternalGetHallPassTopCreatorsResponse.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): InternalGetHallPassTopCreatorsResponse.AsObject {
    return InternalGetHallPassTopCreatorsResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!InternalGetHallPassTopCreatorsResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: InternalGetHallPassTopCreatorsResponse) {
    var f: any;
    var obj: any = {
      statsList: jspb.Message.toObjectList(msg.getStatsList(),
      InternalHallPassStat.toObject, includeInstance)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!InternalGetHallPassTopCreatorsResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new InternalGetHallPassTopCreatorsResponse;
    return InternalGetHallPassTopCreatorsResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!InternalGetHallPassTopCreatorsResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!InternalGetHallPassTopCreatorsResponse}
   */
  static deserializeBinaryFromReader(msg: InternalGetHallPassTopCreatorsResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new InternalHallPassStat;
        reader.readMessage(value1,InternalHallPassStat.deserializeBinaryFromReader);
        msg.addStats(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!InternalGetHallPassTopCreatorsResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: InternalGetHallPassTopCreatorsResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getStatsList();
    if (f.length > 0) {
      writer.writeRepeatedMessage(
        1,
        f,
        InternalHallPassStat.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    InternalGetHallPassTopCreatorsResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * repeated InternalHallPassStat stats = 1;
   * @return {!Array<!InternalHallPassStat>}
   */
  getStatsList(): InternalHallPassStat[] {
    return /** @type{!Array<!InternalHallPassStat>} */ (
      jspb.Message.getRepeatedWrapperField(this, InternalHallPassStat, 1));
  }


  /** @param {!Array<!InternalHallPassStat>} value */
  setStatsList(value?: InternalHallPassStat[]) {
    jspb.Message.setRepeatedWrapperField(this, 1, value);
  }


  /**
   * @param {!InternalHallPassStat=} opt_value
   * @param {number=} opt_index
   * @return {!InternalHallPassStat}
   */
  addStats(opt_value?: InternalHallPassStat, opt_index?: number): InternalHallPassStat {
    return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, InternalHallPassStat, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearStatsList() {
    this.setStatsList([]);
  }


}
export namespace InternalGetHallPassExpiredTimeReportRequest {
  export interface AsObject {
    mingaId: number;
    startDate: string;
    endDate: string;
    issuedByPeopleList: number[];
    issuedToPeopleList: number[];
    studentCreated: string;
    passTypesList: number[];
    offset: number;
    limit: number;
    orderByTotalPasses?: SortOrder;
    orderByTotalTime?: SortOrder;
  }
}
export class InternalGetHallPassExpiredTimeReportRequest extends jspb.Message {
  static readonly displayName = "InternalGetHallPassExpiredTimeReportRequest";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [4,5,7];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, InternalGetHallPassExpiredTimeReportRequest.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): InternalGetHallPassExpiredTimeReportRequest.AsObject {
    return InternalGetHallPassExpiredTimeReportRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!InternalGetHallPassExpiredTimeReportRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: InternalGetHallPassExpiredTimeReportRequest) {
    var f: any;
    var obj: any = {
      mingaId: jspb.Message.getFieldWithDefault(msg, 1, 0),
      startDate: jspb.Message.getFieldWithDefault(msg, 2, ""),
      endDate: jspb.Message.getFieldWithDefault(msg, 3, ""),
      issuedByPeopleList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
      issuedToPeopleList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f,
      studentCreated: jspb.Message.getFieldWithDefault(msg, 6, ""),
      passTypesList: (f = jspb.Message.getRepeatedField(msg, 7)) == null ? undefined : f,
      offset: jspb.Message.getFieldWithDefault(msg, 8, 0),
      limit: jspb.Message.getFieldWithDefault(msg, 9, 0),
      orderByTotalPasses: jspb.Message.getFieldWithDefault(msg, 10, 0),
      orderByTotalTime: jspb.Message.getFieldWithDefault(msg, 11, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!InternalGetHallPassExpiredTimeReportRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new InternalGetHallPassExpiredTimeReportRequest;
    return InternalGetHallPassExpiredTimeReportRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!InternalGetHallPassExpiredTimeReportRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!InternalGetHallPassExpiredTimeReportRequest}
   */
  static deserializeBinaryFromReader(msg: InternalGetHallPassExpiredTimeReportRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {number} */ (reader.readInt32());
        msg.setMingaId(value1);
        break;
      case 2:
        var value2 = /** @type {string} */ (reader.readString());
        msg.setStartDate(value2);
        break;
      case 3:
        var value3 = /** @type {string} */ (reader.readString());
        msg.setEndDate(value3);
        break;
      case 4:
        var value4 = /** @type {!Array<number>} */ (reader.readPackedInt32());
        msg.setIssuedByPeopleList(value4);
        break;
      case 5:
        var value5 = /** @type {!Array<number>} */ (reader.readPackedInt32());
        msg.setIssuedToPeopleList(value5);
        break;
      case 6:
        var value6 = /** @type {string} */ (reader.readString());
        msg.setStudentCreated(value6);
        break;
      case 7:
        var value7 = /** @type {!Array<number>} */ (reader.readPackedInt32());
        msg.setPassTypesList(value7);
        break;
      case 8:
        var value8 = /** @type {number} */ (reader.readInt32());
        msg.setOffset(value8);
        break;
      case 9:
        var value9 = /** @type {number} */ (reader.readInt32());
        msg.setLimit(value9);
        break;
      case 10:
        var value10 = /** @type {!SortOrder} */ (reader.readEnum());
        msg.setOrderByTotalPasses(value10);
        break;
      case 11:
        var value11 = /** @type {!SortOrder} */ (reader.readEnum());
        msg.setOrderByTotalTime(value11);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!InternalGetHallPassExpiredTimeReportRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: InternalGetHallPassExpiredTimeReportRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getMingaId();
    if (f !== 0) {
      writer.writeInt32(
        1,
        f
      );
    }
    f = message.getStartDate();
    if (f.length > 0) {
      writer.writeString(
        2,
        f
      );
    }
    f = message.getEndDate();
    if (f.length > 0) {
      writer.writeString(
        3,
        f
      );
    }
    f = message.getIssuedByPeopleList();
    if (f.length > 0) {
      writer.writePackedInt32(
        4,
        f
      );
    }
    f = message.getIssuedToPeopleList();
    if (f.length > 0) {
      writer.writePackedInt32(
        5,
        f
      );
    }
    f = message.getStudentCreated();
    if (f.length > 0) {
      writer.writeString(
        6,
        f
      );
    }
    f = message.getPassTypesList();
    if (f.length > 0) {
      writer.writePackedInt32(
        7,
        f
      );
    }
    f = message.getOffset();
    if (f !== 0) {
      writer.writeInt32(
        8,
        f
      );
    }
    f = message.getLimit();
    if (f !== 0) {
      writer.writeInt32(
        9,
        f
      );
    }
    f = message.getOrderByTotalPasses();
    if (f !== 0.0) {
      writer.writeEnum(
        10,
        f
      );
    }
    f = message.getOrderByTotalTime();
    if (f !== 0.0) {
      writer.writeEnum(
        11,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    InternalGetHallPassExpiredTimeReportRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional int32 minga_id = 1;
   * @return {number}
   */
  getMingaId(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
  };


  /** @param {number} value */
  setMingaId(value: number) {
    jspb.Message.setProto3IntField(this, 1, value);
  }


  /**
   * optional string start_date = 2;
   * @return {string}
   */
  getStartDate(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
  };


  /** @param {string} value */
  setStartDate(value: string) {
    jspb.Message.setProto3StringField(this, 2, value);
  }


  /**
   * optional string end_date = 3;
   * @return {string}
   */
  getEndDate(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
  };


  /** @param {string} value */
  setEndDate(value: string) {
    jspb.Message.setProto3StringField(this, 3, value);
  }


  /**
   * repeated int32 issued_by_people = 4;
   * @return {!Array<number>}
   */
  getIssuedByPeopleList(): number[] {
    return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 4));
  };


  /** @param {!Array<number>} value */
  setIssuedByPeopleList(value: number[]) {
    jspb.Message.setField(this, 4, value || []);
  }


  /**
   * @param {number} value
   * @param {number=} opt_index
   */
  addIssuedByPeople(value: number, opt_index?: number): void {
    jspb.Message.addToRepeatedField(this, 4, value, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearIssuedByPeopleList() {
    this.setIssuedByPeopleList([]);
  }


  /**
   * repeated int32 issued_to_people = 5;
   * @return {!Array<number>}
   */
  getIssuedToPeopleList(): number[] {
    return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 5));
  };


  /** @param {!Array<number>} value */
  setIssuedToPeopleList(value: number[]) {
    jspb.Message.setField(this, 5, value || []);
  }


  /**
   * @param {number} value
   * @param {number=} opt_index
   */
  addIssuedToPeople(value: number, opt_index?: number): void {
    jspb.Message.addToRepeatedField(this, 5, value, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearIssuedToPeopleList() {
    this.setIssuedToPeopleList([]);
  }


  /**
   * optional string student_created = 6;
   * @return {string}
   */
  getStudentCreated(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
  };


  /** @param {string} value */
  setStudentCreated(value: string) {
    jspb.Message.setProto3StringField(this, 6, value);
  }


  /**
   * repeated int32 pass_types = 7;
   * @return {!Array<number>}
   */
  getPassTypesList(): number[] {
    return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 7));
  };


  /** @param {!Array<number>} value */
  setPassTypesList(value: number[]) {
    jspb.Message.setField(this, 7, value || []);
  }


  /**
   * @param {number} value
   * @param {number=} opt_index
   */
  addPassTypes(value: number, opt_index?: number): void {
    jspb.Message.addToRepeatedField(this, 7, value, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearPassTypesList() {
    this.setPassTypesList([]);
  }


  /**
   * optional int32 offset = 8;
   * @return {number}
   */
  getOffset(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
  };


  /** @param {number} value */
  setOffset(value: number) {
    jspb.Message.setProto3IntField(this, 8, value);
  }


  /**
   * optional int32 limit = 9;
   * @return {number}
   */
  getLimit(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
  };


  /** @param {number} value */
  setLimit(value: number) {
    jspb.Message.setProto3IntField(this, 9, value);
  }


  /**
   * optional SortOrder order_by_total_passes = 10;
   * @return {!SortOrder}
   */
  getOrderByTotalPasses(): SortOrder {
    return /** @type {!SortOrder} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
  };


  /** @param {!SortOrder} value */
  setOrderByTotalPasses(value: SortOrder) {
    jspb.Message.setProto3EnumField(this, 10, value);
  }


  /**
   * optional SortOrder order_by_total_time = 11;
   * @return {!SortOrder}
   */
  getOrderByTotalTime(): SortOrder {
    return /** @type {!SortOrder} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
  };


  /** @param {!SortOrder} value */
  setOrderByTotalTime(value: SortOrder) {
    jspb.Message.setProto3EnumField(this, 11, value);
  }


}
export namespace InternalGetHallPassExpiredTimeReportResponse {
  export interface AsObject {
    expiredStatsList: InternalHallPassExpiredStat.AsObject[];
  }
}
export class InternalGetHallPassExpiredTimeReportResponse extends jspb.Message {
  static readonly displayName = "InternalGetHallPassExpiredTimeReportResponse";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [1];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, InternalGetHallPassExpiredTimeReportResponse.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): InternalGetHallPassExpiredTimeReportResponse.AsObject {
    return InternalGetHallPassExpiredTimeReportResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!InternalGetHallPassExpiredTimeReportResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: InternalGetHallPassExpiredTimeReportResponse) {
    var f: any;
    var obj: any = {
      expiredStatsList: jspb.Message.toObjectList(msg.getExpiredStatsList(),
      InternalHallPassExpiredStat.toObject, includeInstance)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!InternalGetHallPassExpiredTimeReportResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new InternalGetHallPassExpiredTimeReportResponse;
    return InternalGetHallPassExpiredTimeReportResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!InternalGetHallPassExpiredTimeReportResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!InternalGetHallPassExpiredTimeReportResponse}
   */
  static deserializeBinaryFromReader(msg: InternalGetHallPassExpiredTimeReportResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new InternalHallPassExpiredStat;
        reader.readMessage(value1,InternalHallPassExpiredStat.deserializeBinaryFromReader);
        msg.addExpiredStats(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!InternalGetHallPassExpiredTimeReportResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: InternalGetHallPassExpiredTimeReportResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getExpiredStatsList();
    if (f.length > 0) {
      writer.writeRepeatedMessage(
        1,
        f,
        InternalHallPassExpiredStat.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    InternalGetHallPassExpiredTimeReportResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * repeated InternalHallPassExpiredStat expired_stats = 1;
   * @return {!Array<!InternalHallPassExpiredStat>}
   */
  getExpiredStatsList(): InternalHallPassExpiredStat[] {
    return /** @type{!Array<!InternalHallPassExpiredStat>} */ (
      jspb.Message.getRepeatedWrapperField(this, InternalHallPassExpiredStat, 1));
  }


  /** @param {!Array<!InternalHallPassExpiredStat>} value */
  setExpiredStatsList(value?: InternalHallPassExpiredStat[]) {
    jspb.Message.setRepeatedWrapperField(this, 1, value);
  }


  /**
   * @param {!InternalHallPassExpiredStat=} opt_value
   * @param {number=} opt_index
   * @return {!InternalHallPassExpiredStat}
   */
  addExpiredStats(opt_value?: InternalHallPassExpiredStat, opt_index?: number): InternalHallPassExpiredStat {
    return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, InternalHallPassExpiredStat, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearExpiredStatsList() {
    this.setExpiredStatsList([]);
  }


}
export enum SortOrder {
  NONE = 0,
  ASC = 1,
  DESC = 2,
}
