import { date_pb } from 'libs/generated-grpc-ts';

export namespace DateProtoMapper {
  export function dateMessageToDateObject(dateMsg: date_pb.Date): Date {
    return new Date(
      Date.UTC(dateMsg.getYear(), dateMsg.getMonth() - 1, dateMsg.getDay()),
    );
  }

  export function dateTimeMessageToDateObject(dateTimeMsg: date_pb.DateTime) {
    let utcDate = Date.UTC(
      dateTimeMsg.getYear(),
      dateTimeMsg.getMonth() - 1,
      dateTimeMsg.getDay(),
    );

    utcDate += (dateTimeMsg.getSeconds() - 1) * 1000;

    return new Date(utcDate);
  }

  export function dateObjectToDateMessage(date: Date) {
    const dateMsg = new date_pb.Date();
    dateMsg.setYear(date.getUTCFullYear());
    dateMsg.setMonth(date.getUTCMonth() + 1);
    dateMsg.setDay(date.getUTCDate());
    return dateMsg;
  }

  export function dateObjectToDateTimeMessage(date: Date) {
    const dateTimeMsg = new date_pb.DateTime();
    dateTimeMsg.setYear(date.getUTCFullYear());
    dateTimeMsg.setMonth(date.getUTCMonth() + 1);
    dateTimeMsg.setDay(date.getUTCDate());

    const seconds =
      date.getUTCHours() * 3600 +
      date.getUTCMinutes() * 60 +
      date.getUTCSeconds();

    dateTimeMsg.setSeconds(seconds);

    return dateTimeMsg;
  }
}
