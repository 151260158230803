// source: pbis_stats/pbis_stats.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

import * as jspb from 'google-protobuf';
import * as common_date_pb from '../common/date_pb';
import * as report_person_report_person_pb from '../report_person/report_person_pb';
import * as google_protobuf_wrappers_pb from '../google/protobuf/wrappers_pb';
import * as google_protobuf_empty_pb from '../google/protobuf/empty_pb';
export namespace PbisSummary {
  export interface AsObject {
    typeId: number;
    month: number;
    date: string;
    hour: number;
    week: number;
    total: number;
    typeName: string;
    typeCategory: number;
    year: number;
  }
}
export class PbisSummary extends jspb.Message {
  static readonly displayName = "PbisSummary";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): PbisSummary.AsObject {
    return PbisSummary.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!PbisSummary} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: PbisSummary) {
    var f: any;
    var obj: any = {
      typeId: jspb.Message.getFieldWithDefault(msg, 1, 0),
      month: jspb.Message.getFieldWithDefault(msg, 2, 0),
      date: jspb.Message.getFieldWithDefault(msg, 3, ""),
      hour: jspb.Message.getFieldWithDefault(msg, 4, 0),
      week: jspb.Message.getFieldWithDefault(msg, 5, 0),
      total: jspb.Message.getFieldWithDefault(msg, 6, 0),
      typeName: jspb.Message.getFieldWithDefault(msg, 7, ""),
      typeCategory: jspb.Message.getFieldWithDefault(msg, 8, 0),
      year: jspb.Message.getFieldWithDefault(msg, 9, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!PbisSummary}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new PbisSummary;
    return PbisSummary.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!PbisSummary} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!PbisSummary}
   */
  static deserializeBinaryFromReader(msg: PbisSummary, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {number} */ (reader.readInt32());
        msg.setTypeId(value1);
        break;
      case 2:
        var value2 = /** @type {number} */ (reader.readInt32());
        msg.setMonth(value2);
        break;
      case 3:
        var value3 = /** @type {string} */ (reader.readString());
        msg.setDate(value3);
        break;
      case 4:
        var value4 = /** @type {number} */ (reader.readInt32());
        msg.setHour(value4);
        break;
      case 5:
        var value5 = /** @type {number} */ (reader.readInt32());
        msg.setWeek(value5);
        break;
      case 6:
        var value6 = /** @type {number} */ (reader.readInt32());
        msg.setTotal(value6);
        break;
      case 7:
        var value7 = /** @type {string} */ (reader.readString());
        msg.setTypeName(value7);
        break;
      case 8:
        var value8 = /** @type {number} */ (reader.readInt32());
        msg.setTypeCategory(value8);
        break;
      case 9:
        var value9 = /** @type {number} */ (reader.readInt32());
        msg.setYear(value9);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!PbisSummary} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: PbisSummary, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getTypeId();
    if (f !== 0) {
      writer.writeInt32(
        1,
        f
      );
    }
    f = message.getMonth();
    if (f !== 0) {
      writer.writeInt32(
        2,
        f
      );
    }
    f = message.getDate();
    if (f.length > 0) {
      writer.writeString(
        3,
        f
      );
    }
    f = message.getHour();
    if (f !== 0) {
      writer.writeInt32(
        4,
        f
      );
    }
    f = message.getWeek();
    if (f !== 0) {
      writer.writeInt32(
        5,
        f
      );
    }
    f = message.getTotal();
    if (f !== 0) {
      writer.writeInt32(
        6,
        f
      );
    }
    f = message.getTypeName();
    if (f.length > 0) {
      writer.writeString(
        7,
        f
      );
    }
    f = message.getTypeCategory();
    if (f !== 0) {
      writer.writeInt32(
        8,
        f
      );
    }
    f = message.getYear();
    if (f !== 0) {
      writer.writeInt32(
        9,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    PbisSummary.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional int32 type_id = 1;
   * @return {number}
   */
  getTypeId(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
  };


  /** @param {number} value */
  setTypeId(value: number) {
    jspb.Message.setProto3IntField(this, 1, value);
  }


  /**
   * optional int32 month = 2;
   * @return {number}
   */
  getMonth(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
  };


  /** @param {number} value */
  setMonth(value: number) {
    jspb.Message.setProto3IntField(this, 2, value);
  }


  /**
   * optional string date = 3;
   * @return {string}
   */
  getDate(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
  };


  /** @param {string} value */
  setDate(value: string) {
    jspb.Message.setProto3StringField(this, 3, value);
  }


  /**
   * optional int32 hour = 4;
   * @return {number}
   */
  getHour(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
  };


  /** @param {number} value */
  setHour(value: number) {
    jspb.Message.setProto3IntField(this, 4, value);
  }


  /**
   * optional int32 week = 5;
   * @return {number}
   */
  getWeek(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
  };


  /** @param {number} value */
  setWeek(value: number) {
    jspb.Message.setProto3IntField(this, 5, value);
  }


  /**
   * optional int32 total = 6;
   * @return {number}
   */
  getTotal(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
  };


  /** @param {number} value */
  setTotal(value: number) {
    jspb.Message.setProto3IntField(this, 6, value);
  }


  /**
   * optional string type_name = 7;
   * @return {string}
   */
  getTypeName(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
  };


  /** @param {string} value */
  setTypeName(value: string) {
    jspb.Message.setProto3StringField(this, 7, value);
  }


  /**
   * optional int32 type_category = 8;
   * @return {number}
   */
  getTypeCategory(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
  };


  /** @param {number} value */
  setTypeCategory(value: number) {
    jspb.Message.setProto3IntField(this, 8, value);
  }


  /**
   * optional int32 year = 9;
   * @return {number}
   */
  getYear(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
  };


  /** @param {number} value */
  setYear(value: number) {
    jspb.Message.setProto3IntField(this, 9, value);
  }


}
export namespace ConsequenceSummary {
  export interface AsObject {
    typeId: number;
    month: number;
    date: string;
    hour: number;
    week: number;
    total: number;
    typeName: string;
    typeCategory: number;
    year: number;
    totalActive: number;
    totalComplete: number;
    totalOverdue: number;
  }
}
export class ConsequenceSummary extends jspb.Message {
  static readonly displayName = "ConsequenceSummary";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): ConsequenceSummary.AsObject {
    return ConsequenceSummary.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!ConsequenceSummary} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: ConsequenceSummary) {
    var f: any;
    var obj: any = {
      typeId: jspb.Message.getFieldWithDefault(msg, 1, 0),
      month: jspb.Message.getFieldWithDefault(msg, 2, 0),
      date: jspb.Message.getFieldWithDefault(msg, 3, ""),
      hour: jspb.Message.getFieldWithDefault(msg, 4, 0),
      week: jspb.Message.getFieldWithDefault(msg, 5, 0),
      total: jspb.Message.getFieldWithDefault(msg, 6, 0),
      typeName: jspb.Message.getFieldWithDefault(msg, 7, ""),
      typeCategory: jspb.Message.getFieldWithDefault(msg, 8, 0),
      year: jspb.Message.getFieldWithDefault(msg, 9, 0),
      totalActive: jspb.Message.getFieldWithDefault(msg, 10, 0),
      totalComplete: jspb.Message.getFieldWithDefault(msg, 11, 0),
      totalOverdue: jspb.Message.getFieldWithDefault(msg, 12, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!ConsequenceSummary}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new ConsequenceSummary;
    return ConsequenceSummary.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!ConsequenceSummary} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!ConsequenceSummary}
   */
  static deserializeBinaryFromReader(msg: ConsequenceSummary, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {number} */ (reader.readInt32());
        msg.setTypeId(value1);
        break;
      case 2:
        var value2 = /** @type {number} */ (reader.readInt32());
        msg.setMonth(value2);
        break;
      case 3:
        var value3 = /** @type {string} */ (reader.readString());
        msg.setDate(value3);
        break;
      case 4:
        var value4 = /** @type {number} */ (reader.readInt32());
        msg.setHour(value4);
        break;
      case 5:
        var value5 = /** @type {number} */ (reader.readInt32());
        msg.setWeek(value5);
        break;
      case 6:
        var value6 = /** @type {number} */ (reader.readInt32());
        msg.setTotal(value6);
        break;
      case 7:
        var value7 = /** @type {string} */ (reader.readString());
        msg.setTypeName(value7);
        break;
      case 8:
        var value8 = /** @type {number} */ (reader.readInt32());
        msg.setTypeCategory(value8);
        break;
      case 9:
        var value9 = /** @type {number} */ (reader.readInt32());
        msg.setYear(value9);
        break;
      case 10:
        var value10 = /** @type {number} */ (reader.readInt32());
        msg.setTotalActive(value10);
        break;
      case 11:
        var value11 = /** @type {number} */ (reader.readInt32());
        msg.setTotalComplete(value11);
        break;
      case 12:
        var value12 = /** @type {number} */ (reader.readInt32());
        msg.setTotalOverdue(value12);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!ConsequenceSummary} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: ConsequenceSummary, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getTypeId();
    if (f !== 0) {
      writer.writeInt32(
        1,
        f
      );
    }
    f = message.getMonth();
    if (f !== 0) {
      writer.writeInt32(
        2,
        f
      );
    }
    f = message.getDate();
    if (f.length > 0) {
      writer.writeString(
        3,
        f
      );
    }
    f = message.getHour();
    if (f !== 0) {
      writer.writeInt32(
        4,
        f
      );
    }
    f = message.getWeek();
    if (f !== 0) {
      writer.writeInt32(
        5,
        f
      );
    }
    f = message.getTotal();
    if (f !== 0) {
      writer.writeInt32(
        6,
        f
      );
    }
    f = message.getTypeName();
    if (f.length > 0) {
      writer.writeString(
        7,
        f
      );
    }
    f = message.getTypeCategory();
    if (f !== 0) {
      writer.writeInt32(
        8,
        f
      );
    }
    f = message.getYear();
    if (f !== 0) {
      writer.writeInt32(
        9,
        f
      );
    }
    f = message.getTotalActive();
    if (f !== 0) {
      writer.writeInt32(
        10,
        f
      );
    }
    f = message.getTotalComplete();
    if (f !== 0) {
      writer.writeInt32(
        11,
        f
      );
    }
    f = message.getTotalOverdue();
    if (f !== 0) {
      writer.writeInt32(
        12,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    ConsequenceSummary.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional int32 type_id = 1;
   * @return {number}
   */
  getTypeId(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
  };


  /** @param {number} value */
  setTypeId(value: number) {
    jspb.Message.setProto3IntField(this, 1, value);
  }


  /**
   * optional int32 month = 2;
   * @return {number}
   */
  getMonth(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
  };


  /** @param {number} value */
  setMonth(value: number) {
    jspb.Message.setProto3IntField(this, 2, value);
  }


  /**
   * optional string date = 3;
   * @return {string}
   */
  getDate(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
  };


  /** @param {string} value */
  setDate(value: string) {
    jspb.Message.setProto3StringField(this, 3, value);
  }


  /**
   * optional int32 hour = 4;
   * @return {number}
   */
  getHour(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
  };


  /** @param {number} value */
  setHour(value: number) {
    jspb.Message.setProto3IntField(this, 4, value);
  }


  /**
   * optional int32 week = 5;
   * @return {number}
   */
  getWeek(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
  };


  /** @param {number} value */
  setWeek(value: number) {
    jspb.Message.setProto3IntField(this, 5, value);
  }


  /**
   * optional int32 total = 6;
   * @return {number}
   */
  getTotal(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
  };


  /** @param {number} value */
  setTotal(value: number) {
    jspb.Message.setProto3IntField(this, 6, value);
  }


  /**
   * optional string type_name = 7;
   * @return {string}
   */
  getTypeName(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
  };


  /** @param {string} value */
  setTypeName(value: string) {
    jspb.Message.setProto3StringField(this, 7, value);
  }


  /**
   * optional int32 type_category = 8;
   * @return {number}
   */
  getTypeCategory(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
  };


  /** @param {number} value */
  setTypeCategory(value: number) {
    jspb.Message.setProto3IntField(this, 8, value);
  }


  /**
   * optional int32 year = 9;
   * @return {number}
   */
  getYear(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
  };


  /** @param {number} value */
  setYear(value: number) {
    jspb.Message.setProto3IntField(this, 9, value);
  }


  /**
   * optional int32 total_active = 10;
   * @return {number}
   */
  getTotalActive(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
  };


  /** @param {number} value */
  setTotalActive(value: number) {
    jspb.Message.setProto3IntField(this, 10, value);
  }


  /**
   * optional int32 total_complete = 11;
   * @return {number}
   */
  getTotalComplete(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
  };


  /** @param {number} value */
  setTotalComplete(value: number) {
    jspb.Message.setProto3IntField(this, 11, value);
  }


  /**
   * optional int32 total_overdue = 12;
   * @return {number}
   */
  getTotalOverdue(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
  };


  /** @param {number} value */
  setTotalOverdue(value: number) {
    jspb.Message.setProto3IntField(this, 12, value);
  }


}
export namespace PbisPersonInfo {
  export interface AsObject {
    name: string;
    studentId: string;
    id: number;
    grade: string;
    role: string;
    badgeIconUrl: string;
    personHash: string;
    firstName: string;
    lastName: string;
  }
}
export class PbisPersonInfo extends jspb.Message {
  static readonly displayName = "PbisPersonInfo";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): PbisPersonInfo.AsObject {
    return PbisPersonInfo.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!PbisPersonInfo} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: PbisPersonInfo) {
    var f: any;
    var obj: any = {
      name: jspb.Message.getFieldWithDefault(msg, 1, ""),
      studentId: jspb.Message.getFieldWithDefault(msg, 2, ""),
      id: jspb.Message.getFieldWithDefault(msg, 3, 0),
      grade: jspb.Message.getFieldWithDefault(msg, 4, ""),
      role: jspb.Message.getFieldWithDefault(msg, 5, ""),
      badgeIconUrl: jspb.Message.getFieldWithDefault(msg, 6, ""),
      personHash: jspb.Message.getFieldWithDefault(msg, 7, ""),
      firstName: jspb.Message.getFieldWithDefault(msg, 8, ""),
      lastName: jspb.Message.getFieldWithDefault(msg, 9, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!PbisPersonInfo}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new PbisPersonInfo;
    return PbisPersonInfo.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!PbisPersonInfo} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!PbisPersonInfo}
   */
  static deserializeBinaryFromReader(msg: PbisPersonInfo, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setName(value1);
        break;
      case 2:
        var value2 = /** @type {string} */ (reader.readString());
        msg.setStudentId(value2);
        break;
      case 3:
        var value3 = /** @type {number} */ (reader.readInt32());
        msg.setId(value3);
        break;
      case 4:
        var value4 = /** @type {string} */ (reader.readString());
        msg.setGrade(value4);
        break;
      case 5:
        var value5 = /** @type {string} */ (reader.readString());
        msg.setRole(value5);
        break;
      case 6:
        var value6 = /** @type {string} */ (reader.readString());
        msg.setBadgeIconUrl(value6);
        break;
      case 7:
        var value7 = /** @type {string} */ (reader.readString());
        msg.setPersonHash(value7);
        break;
      case 8:
        var value8 = /** @type {string} */ (reader.readString());
        msg.setFirstName(value8);
        break;
      case 9:
        var value9 = /** @type {string} */ (reader.readString());
        msg.setLastName(value9);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!PbisPersonInfo} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: PbisPersonInfo, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getName();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
    f = message.getStudentId();
    if (f.length > 0) {
      writer.writeString(
        2,
        f
      );
    }
    f = message.getId();
    if (f !== 0) {
      writer.writeInt32(
        3,
        f
      );
    }
    f = message.getGrade();
    if (f.length > 0) {
      writer.writeString(
        4,
        f
      );
    }
    f = message.getRole();
    if (f.length > 0) {
      writer.writeString(
        5,
        f
      );
    }
    f = message.getBadgeIconUrl();
    if (f.length > 0) {
      writer.writeString(
        6,
        f
      );
    }
    f = message.getPersonHash();
    if (f.length > 0) {
      writer.writeString(
        7,
        f
      );
    }
    f = message.getFirstName();
    if (f.length > 0) {
      writer.writeString(
        8,
        f
      );
    }
    f = message.getLastName();
    if (f.length > 0) {
      writer.writeString(
        9,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    PbisPersonInfo.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string name = 1;
   * @return {string}
   */
  getName(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setName(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


  /**
   * optional string student_id = 2;
   * @return {string}
   */
  getStudentId(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
  };


  /** @param {string} value */
  setStudentId(value: string) {
    jspb.Message.setProto3StringField(this, 2, value);
  }


  /**
   * optional int32 id = 3;
   * @return {number}
   */
  getId(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
  };


  /** @param {number} value */
  setId(value: number) {
    jspb.Message.setProto3IntField(this, 3, value);
  }


  /**
   * optional string grade = 4;
   * @return {string}
   */
  getGrade(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
  };


  /** @param {string} value */
  setGrade(value: string) {
    jspb.Message.setProto3StringField(this, 4, value);
  }


  /**
   * optional string role = 5;
   * @return {string}
   */
  getRole(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
  };


  /** @param {string} value */
  setRole(value: string) {
    jspb.Message.setProto3StringField(this, 5, value);
  }


  /**
   * optional string badge_icon_url = 6;
   * @return {string}
   */
  getBadgeIconUrl(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
  };


  /** @param {string} value */
  setBadgeIconUrl(value: string) {
    jspb.Message.setProto3StringField(this, 6, value);
  }


  /**
   * optional string person_hash = 7;
   * @return {string}
   */
  getPersonHash(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
  };


  /** @param {string} value */
  setPersonHash(value: string) {
    jspb.Message.setProto3StringField(this, 7, value);
  }


  /**
   * optional string first_name = 8;
   * @return {string}
   */
  getFirstName(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
  };


  /** @param {string} value */
  setFirstName(value: string) {
    jspb.Message.setProto3StringField(this, 8, value);
  }


  /**
   * optional string last_name = 9;
   * @return {string}
   */
  getLastName(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
  };


  /** @param {string} value */
  setLastName(value: string) {
    jspb.Message.setProto3StringField(this, 9, value);
  }


}
export namespace PbisAutomationStats {
  export interface AsObject {
    automationGroup: string;
    counter: number;
    person?: report_person_report_person_pb.ReportPerson.AsObject;
    consequence: number;
    overdue: number;
    incomplete: number;
    complete: number;
    automationGroupId: number;
  }
}
export class PbisAutomationStats extends jspb.Message {
  static readonly displayName = "PbisAutomationStats";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): PbisAutomationStats.AsObject {
    return PbisAutomationStats.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!PbisAutomationStats} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: PbisAutomationStats) {
    var f: any;
    var obj: any = {
      automationGroup: jspb.Message.getFieldWithDefault(msg, 1, ""),
      counter: jspb.Message.getFieldWithDefault(msg, 2, 0),
      person: (f = msg.getPerson()) && report_person_report_person_pb.ReportPerson.toObject(includeInstance, f),
      consequence: jspb.Message.getFieldWithDefault(msg, 4, 0),
      overdue: jspb.Message.getFieldWithDefault(msg, 5, 0),
      incomplete: jspb.Message.getFieldWithDefault(msg, 6, 0),
      complete: jspb.Message.getFieldWithDefault(msg, 7, 0),
      automationGroupId: jspb.Message.getFieldWithDefault(msg, 8, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!PbisAutomationStats}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new PbisAutomationStats;
    return PbisAutomationStats.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!PbisAutomationStats} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!PbisAutomationStats}
   */
  static deserializeBinaryFromReader(msg: PbisAutomationStats, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setAutomationGroup(value1);
        break;
      case 2:
        var value2 = /** @type {number} */ (reader.readInt32());
        msg.setCounter(value2);
        break;
      case 3:
        var value3 = new report_person_report_person_pb.ReportPerson;
        reader.readMessage(value3,report_person_report_person_pb.ReportPerson.deserializeBinaryFromReader);
        msg.setPerson(value3);
        break;
      case 4:
        var value4 = /** @type {number} */ (reader.readInt32());
        msg.setConsequence(value4);
        break;
      case 5:
        var value5 = /** @type {number} */ (reader.readInt32());
        msg.setOverdue(value5);
        break;
      case 6:
        var value6 = /** @type {number} */ (reader.readInt32());
        msg.setIncomplete(value6);
        break;
      case 7:
        var value7 = /** @type {number} */ (reader.readInt32());
        msg.setComplete(value7);
        break;
      case 8:
        var value8 = /** @type {number} */ (reader.readInt32());
        msg.setAutomationGroupId(value8);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!PbisAutomationStats} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: PbisAutomationStats, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getAutomationGroup();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
    f = message.getCounter();
    if (f !== 0) {
      writer.writeInt32(
        2,
        f
      );
    }
    f = message.getPerson();
    if (f != null) {
      writer.writeMessage(
        3,
        f,
        report_person_report_person_pb.ReportPerson.serializeBinaryToWriter
      );
    }
    f = message.getConsequence();
    if (f !== 0) {
      writer.writeInt32(
        4,
        f
      );
    }
    f = message.getOverdue();
    if (f !== 0) {
      writer.writeInt32(
        5,
        f
      );
    }
    f = message.getIncomplete();
    if (f !== 0) {
      writer.writeInt32(
        6,
        f
      );
    }
    f = message.getComplete();
    if (f !== 0) {
      writer.writeInt32(
        7,
        f
      );
    }
    f = message.getAutomationGroupId();
    if (f !== 0) {
      writer.writeInt32(
        8,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    PbisAutomationStats.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string automation_group = 1;
   * @return {string}
   */
  getAutomationGroup(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setAutomationGroup(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


  /**
   * optional int32 counter = 2;
   * @return {number}
   */
  getCounter(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
  };


  /** @param {number} value */
  setCounter(value: number) {
    jspb.Message.setProto3IntField(this, 2, value);
  }


  /**
   * optional minga.report_person.ReportPerson person = 3;
   * @return {?ReportPerson}
   */
  getPerson(): report_person_report_person_pb.ReportPerson {
    return /** @type{?ReportPerson} */ (
      jspb.Message.getWrapperField(this, report_person_report_person_pb.ReportPerson, 3));
  }


  /** @param {?ReportPerson|undefined} value */
  setPerson(value?: report_person_report_person_pb.ReportPerson) {
    jspb.Message.setWrapperField(this, 3, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearPerson() {
    this.setPerson(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasPerson(): boolean {
    return jspb.Message.getField(this, 3) != null;
  }


  /**
   * optional int32 consequence = 4;
   * @return {number}
   */
  getConsequence(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
  };


  /** @param {number} value */
  setConsequence(value: number) {
    jspb.Message.setProto3IntField(this, 4, value);
  }


  /**
   * optional int32 overdue = 5;
   * @return {number}
   */
  getOverdue(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
  };


  /** @param {number} value */
  setOverdue(value: number) {
    jspb.Message.setProto3IntField(this, 5, value);
  }


  /**
   * optional int32 incomplete = 6;
   * @return {number}
   */
  getIncomplete(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
  };


  /** @param {number} value */
  setIncomplete(value: number) {
    jspb.Message.setProto3IntField(this, 6, value);
  }


  /**
   * optional int32 complete = 7;
   * @return {number}
   */
  getComplete(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
  };


  /** @param {number} value */
  setComplete(value: number) {
    jspb.Message.setProto3IntField(this, 7, value);
  }


  /**
   * optional int32 automation_group_id = 8;
   * @return {number}
   */
  getAutomationGroupId(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
  };


  /** @param {number} value */
  setAutomationGroupId(value: number) {
    jspb.Message.setProto3IntField(this, 8, value);
  }


}
export namespace PbisPersonStats {
  export interface AsObject {
    typeId: number;
    month: number;
    date: string;
    hour: number;
    week: number;
    totalPraise: number;
    issuedTo: string;
    issuedBy: string;
    totalGuidance: number;
    totalPoints: number;
    totalConsequence: number;
    totalOverdueConsequence: number;
    totalActiveConsequence: number;
    totalCompleteConsequence: number;
    totalEscalatedConsequence: number;
  }
}
export class PbisPersonStats extends jspb.Message {
  static readonly displayName = "PbisPersonStats";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): PbisPersonStats.AsObject {
    return PbisPersonStats.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!PbisPersonStats} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: PbisPersonStats) {
    var f: any;
    var obj: any = {
      typeid: jspb.Message.getFieldWithDefault(msg, 1, 0),
      month: jspb.Message.getFieldWithDefault(msg, 2, 0),
      date: jspb.Message.getFieldWithDefault(msg, 3, ""),
      hour: jspb.Message.getFieldWithDefault(msg, 4, 0),
      week: jspb.Message.getFieldWithDefault(msg, 5, 0),
      totalPraise: jspb.Message.getFieldWithDefault(msg, 6, 0),
      issuedto: jspb.Message.getFieldWithDefault(msg, 7, ""),
      issuedby: jspb.Message.getFieldWithDefault(msg, 8, ""),
      totalGuidance: jspb.Message.getFieldWithDefault(msg, 9, 0),
      totalPoints: jspb.Message.getFieldWithDefault(msg, 10, 0),
      totalConsequence: jspb.Message.getFieldWithDefault(msg, 11, 0),
      totalOverdueConsequence: jspb.Message.getFieldWithDefault(msg, 12, 0),
      totalActiveConsequence: jspb.Message.getFieldWithDefault(msg, 13, 0),
      totalCompleteConsequence: jspb.Message.getFieldWithDefault(msg, 14, 0),
      totalEscalatedConsequence: jspb.Message.getFieldWithDefault(msg, 15, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!PbisPersonStats}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new PbisPersonStats;
    return PbisPersonStats.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!PbisPersonStats} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!PbisPersonStats}
   */
  static deserializeBinaryFromReader(msg: PbisPersonStats, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {number} */ (reader.readInt32());
        msg.setTypeid(value1);
        break;
      case 2:
        var value2 = /** @type {number} */ (reader.readInt32());
        msg.setMonth(value2);
        break;
      case 3:
        var value3 = /** @type {string} */ (reader.readString());
        msg.setDate(value3);
        break;
      case 4:
        var value4 = /** @type {number} */ (reader.readInt32());
        msg.setHour(value4);
        break;
      case 5:
        var value5 = /** @type {number} */ (reader.readInt32());
        msg.setWeek(value5);
        break;
      case 6:
        var value6 = /** @type {number} */ (reader.readInt32());
        msg.setTotalPraise(value6);
        break;
      case 7:
        var value7 = /** @type {string} */ (reader.readString());
        msg.setIssuedto(value7);
        break;
      case 8:
        var value8 = /** @type {string} */ (reader.readString());
        msg.setIssuedby(value8);
        break;
      case 9:
        var value9 = /** @type {number} */ (reader.readInt32());
        msg.setTotalGuidance(value9);
        break;
      case 10:
        var value10 = /** @type {number} */ (reader.readInt32());
        msg.setTotalPoints(value10);
        break;
      case 11:
        var value11 = /** @type {number} */ (reader.readInt32());
        msg.setTotalConsequence(value11);
        break;
      case 12:
        var value12 = /** @type {number} */ (reader.readInt32());
        msg.setTotalOverdueConsequence(value12);
        break;
      case 13:
        var value13 = /** @type {number} */ (reader.readInt32());
        msg.setTotalActiveConsequence(value13);
        break;
      case 14:
        var value14 = /** @type {number} */ (reader.readInt32());
        msg.setTotalCompleteConsequence(value14);
        break;
      case 15:
        var value15 = /** @type {number} */ (reader.readInt32());
        msg.setTotalEscalatedConsequence(value15);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!PbisPersonStats} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: PbisPersonStats, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getTypeid();
    if (f !== 0) {
      writer.writeInt32(
        1,
        f
      );
    }
    f = message.getMonth();
    if (f !== 0) {
      writer.writeInt32(
        2,
        f
      );
    }
    f = message.getDate();
    if (f.length > 0) {
      writer.writeString(
        3,
        f
      );
    }
    f = message.getHour();
    if (f !== 0) {
      writer.writeInt32(
        4,
        f
      );
    }
    f = message.getWeek();
    if (f !== 0) {
      writer.writeInt32(
        5,
        f
      );
    }
    f = message.getTotalPraise();
    if (f !== 0) {
      writer.writeInt32(
        6,
        f
      );
    }
    f = message.getIssuedto();
    if (f.length > 0) {
      writer.writeString(
        7,
        f
      );
    }
    f = message.getIssuedby();
    if (f.length > 0) {
      writer.writeString(
        8,
        f
      );
    }
    f = message.getTotalGuidance();
    if (f !== 0) {
      writer.writeInt32(
        9,
        f
      );
    }
    f = message.getTotalPoints();
    if (f !== 0) {
      writer.writeInt32(
        10,
        f
      );
    }
    f = message.getTotalConsequence();
    if (f !== 0) {
      writer.writeInt32(
        11,
        f
      );
    }
    f = message.getTotalOverdueConsequence();
    if (f !== 0) {
      writer.writeInt32(
        12,
        f
      );
    }
    f = message.getTotalActiveConsequence();
    if (f !== 0) {
      writer.writeInt32(
        13,
        f
      );
    }
    f = message.getTotalCompleteConsequence();
    if (f !== 0) {
      writer.writeInt32(
        14,
        f
      );
    }
    f = message.getTotalEscalatedConsequence();
    if (f !== 0) {
      writer.writeInt32(
        15,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    PbisPersonStats.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional int32 typeId = 1;
   * @return {number}
   */
  getTypeid(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
  };


  /** @param {number} value */
  setTypeid(value: number) {
    jspb.Message.setProto3IntField(this, 1, value);
  }


  /**
   * optional int32 month = 2;
   * @return {number}
   */
  getMonth(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
  };


  /** @param {number} value */
  setMonth(value: number) {
    jspb.Message.setProto3IntField(this, 2, value);
  }


  /**
   * optional string date = 3;
   * @return {string}
   */
  getDate(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
  };


  /** @param {string} value */
  setDate(value: string) {
    jspb.Message.setProto3StringField(this, 3, value);
  }


  /**
   * optional int32 hour = 4;
   * @return {number}
   */
  getHour(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
  };


  /** @param {number} value */
  setHour(value: number) {
    jspb.Message.setProto3IntField(this, 4, value);
  }


  /**
   * optional int32 week = 5;
   * @return {number}
   */
  getWeek(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
  };


  /** @param {number} value */
  setWeek(value: number) {
    jspb.Message.setProto3IntField(this, 5, value);
  }


  /**
   * optional int32 total_praise = 6;
   * @return {number}
   */
  getTotalPraise(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
  };


  /** @param {number} value */
  setTotalPraise(value: number) {
    jspb.Message.setProto3IntField(this, 6, value);
  }


  /**
   * optional string issuedTo = 7;
   * @return {string}
   */
  getIssuedto(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
  };


  /** @param {string} value */
  setIssuedto(value: string) {
    jspb.Message.setProto3StringField(this, 7, value);
  }


  /**
   * optional string issuedBy = 8;
   * @return {string}
   */
  getIssuedby(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
  };


  /** @param {string} value */
  setIssuedby(value: string) {
    jspb.Message.setProto3StringField(this, 8, value);
  }


  /**
   * optional int32 total_guidance = 9;
   * @return {number}
   */
  getTotalGuidance(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
  };


  /** @param {number} value */
  setTotalGuidance(value: number) {
    jspb.Message.setProto3IntField(this, 9, value);
  }


  /**
   * optional int32 total_points = 10;
   * @return {number}
   */
  getTotalPoints(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
  };


  /** @param {number} value */
  setTotalPoints(value: number) {
    jspb.Message.setProto3IntField(this, 10, value);
  }


  /**
   * optional int32 total_consequence = 11;
   * @return {number}
   */
  getTotalConsequence(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
  };


  /** @param {number} value */
  setTotalConsequence(value: number) {
    jspb.Message.setProto3IntField(this, 11, value);
  }


  /**
   * optional int32 total_overdue_consequence = 12;
   * @return {number}
   */
  getTotalOverdueConsequence(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
  };


  /** @param {number} value */
  setTotalOverdueConsequence(value: number) {
    jspb.Message.setProto3IntField(this, 12, value);
  }


  /**
   * optional int32 total_active_consequence = 13;
   * @return {number}
   */
  getTotalActiveConsequence(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
  };


  /** @param {number} value */
  setTotalActiveConsequence(value: number) {
    jspb.Message.setProto3IntField(this, 13, value);
  }


  /**
   * optional int32 total_complete_consequence = 14;
   * @return {number}
   */
  getTotalCompleteConsequence(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
  };


  /** @param {number} value */
  setTotalCompleteConsequence(value: number) {
    jspb.Message.setProto3IntField(this, 14, value);
  }


  /**
   * optional int32 total_escalated_consequence = 15;
   * @return {number}
   */
  getTotalEscalatedConsequence(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
  };


  /** @param {number} value */
  setTotalEscalatedConsequence(value: number) {
    jspb.Message.setProto3IntField(this, 15, value);
  }


}
export namespace ConsequencePersonStats {
  export interface AsObject {
    typeId: number;
    month: number;
    date: string;
    hour: number;
    week: number;
    issuedTo: string;
    totalConsequence: number;
    totalServedConsequence: number;
    totalOutstandingConsequence: number;
    totalOverdueConsequence: number;
  }
}
export class ConsequencePersonStats extends jspb.Message {
  static readonly displayName = "ConsequencePersonStats";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): ConsequencePersonStats.AsObject {
    return ConsequencePersonStats.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!ConsequencePersonStats} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: ConsequencePersonStats) {
    var f: any;
    var obj: any = {
      typeId: jspb.Message.getFieldWithDefault(msg, 1, 0),
      month: jspb.Message.getFieldWithDefault(msg, 2, 0),
      date: jspb.Message.getFieldWithDefault(msg, 3, ""),
      hour: jspb.Message.getFieldWithDefault(msg, 4, 0),
      week: jspb.Message.getFieldWithDefault(msg, 5, 0),
      issuedTo: jspb.Message.getFieldWithDefault(msg, 6, ""),
      totalConsequence: jspb.Message.getFieldWithDefault(msg, 7, 0),
      totalServedConsequence: jspb.Message.getFieldWithDefault(msg, 8, 0),
      totalOutstandingConsequence: jspb.Message.getFieldWithDefault(msg, 9, 0),
      totalOverdueConsequence: jspb.Message.getFieldWithDefault(msg, 10, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!ConsequencePersonStats}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new ConsequencePersonStats;
    return ConsequencePersonStats.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!ConsequencePersonStats} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!ConsequencePersonStats}
   */
  static deserializeBinaryFromReader(msg: ConsequencePersonStats, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {number} */ (reader.readInt32());
        msg.setTypeId(value1);
        break;
      case 2:
        var value2 = /** @type {number} */ (reader.readInt32());
        msg.setMonth(value2);
        break;
      case 3:
        var value3 = /** @type {string} */ (reader.readString());
        msg.setDate(value3);
        break;
      case 4:
        var value4 = /** @type {number} */ (reader.readInt32());
        msg.setHour(value4);
        break;
      case 5:
        var value5 = /** @type {number} */ (reader.readInt32());
        msg.setWeek(value5);
        break;
      case 6:
        var value6 = /** @type {string} */ (reader.readString());
        msg.setIssuedTo(value6);
        break;
      case 7:
        var value7 = /** @type {number} */ (reader.readInt32());
        msg.setTotalConsequence(value7);
        break;
      case 8:
        var value8 = /** @type {number} */ (reader.readInt32());
        msg.setTotalServedConsequence(value8);
        break;
      case 9:
        var value9 = /** @type {number} */ (reader.readInt32());
        msg.setTotalOutstandingConsequence(value9);
        break;
      case 10:
        var value10 = /** @type {number} */ (reader.readInt32());
        msg.setTotalOverdueConsequence(value10);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!ConsequencePersonStats} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: ConsequencePersonStats, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getTypeId();
    if (f !== 0) {
      writer.writeInt32(
        1,
        f
      );
    }
    f = message.getMonth();
    if (f !== 0) {
      writer.writeInt32(
        2,
        f
      );
    }
    f = message.getDate();
    if (f.length > 0) {
      writer.writeString(
        3,
        f
      );
    }
    f = message.getHour();
    if (f !== 0) {
      writer.writeInt32(
        4,
        f
      );
    }
    f = message.getWeek();
    if (f !== 0) {
      writer.writeInt32(
        5,
        f
      );
    }
    f = message.getIssuedTo();
    if (f.length > 0) {
      writer.writeString(
        6,
        f
      );
    }
    f = message.getTotalConsequence();
    if (f !== 0) {
      writer.writeInt32(
        7,
        f
      );
    }
    f = message.getTotalServedConsequence();
    if (f !== 0) {
      writer.writeInt32(
        8,
        f
      );
    }
    f = message.getTotalOutstandingConsequence();
    if (f !== 0) {
      writer.writeInt32(
        9,
        f
      );
    }
    f = message.getTotalOverdueConsequence();
    if (f !== 0) {
      writer.writeInt32(
        10,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    ConsequencePersonStats.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional int32 type_id = 1;
   * @return {number}
   */
  getTypeId(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
  };


  /** @param {number} value */
  setTypeId(value: number) {
    jspb.Message.setProto3IntField(this, 1, value);
  }


  /**
   * optional int32 month = 2;
   * @return {number}
   */
  getMonth(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
  };


  /** @param {number} value */
  setMonth(value: number) {
    jspb.Message.setProto3IntField(this, 2, value);
  }


  /**
   * optional string date = 3;
   * @return {string}
   */
  getDate(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
  };


  /** @param {string} value */
  setDate(value: string) {
    jspb.Message.setProto3StringField(this, 3, value);
  }


  /**
   * optional int32 hour = 4;
   * @return {number}
   */
  getHour(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
  };


  /** @param {number} value */
  setHour(value: number) {
    jspb.Message.setProto3IntField(this, 4, value);
  }


  /**
   * optional int32 week = 5;
   * @return {number}
   */
  getWeek(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
  };


  /** @param {number} value */
  setWeek(value: number) {
    jspb.Message.setProto3IntField(this, 5, value);
  }


  /**
   * optional string issued_to = 6;
   * @return {string}
   */
  getIssuedTo(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
  };


  /** @param {string} value */
  setIssuedTo(value: string) {
    jspb.Message.setProto3StringField(this, 6, value);
  }


  /**
   * optional int32 total_consequence = 7;
   * @return {number}
   */
  getTotalConsequence(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
  };


  /** @param {number} value */
  setTotalConsequence(value: number) {
    jspb.Message.setProto3IntField(this, 7, value);
  }


  /**
   * optional int32 total_served_consequence = 8;
   * @return {number}
   */
  getTotalServedConsequence(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
  };


  /** @param {number} value */
  setTotalServedConsequence(value: number) {
    jspb.Message.setProto3IntField(this, 8, value);
  }


  /**
   * optional int32 total_outstanding_consequence = 9;
   * @return {number}
   */
  getTotalOutstandingConsequence(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
  };


  /** @param {number} value */
  setTotalOutstandingConsequence(value: number) {
    jspb.Message.setProto3IntField(this, 9, value);
  }


  /**
   * optional int32 total_overdue_consequence = 10;
   * @return {number}
   */
  getTotalOverdueConsequence(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
  };


  /** @param {number} value */
  setTotalOverdueConsequence(value: number) {
    jspb.Message.setProto3IntField(this, 10, value);
  }


}
export namespace PbisPerson {
  export interface AsObject {
    info?: PbisPersonInfo.AsObject;
    stats?: PbisPersonStats.AsObject;
  }
}
export class PbisPerson extends jspb.Message {
  static readonly displayName = "PbisPerson";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): PbisPerson.AsObject {
    return PbisPerson.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!PbisPerson} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: PbisPerson) {
    var f: any;
    var obj: any = {
      info: (f = msg.getInfo()) && PbisPersonInfo.toObject(includeInstance, f),
      stats: (f = msg.getStats()) && PbisPersonStats.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!PbisPerson}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new PbisPerson;
    return PbisPerson.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!PbisPerson} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!PbisPerson}
   */
  static deserializeBinaryFromReader(msg: PbisPerson, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new PbisPersonInfo;
        reader.readMessage(value1,PbisPersonInfo.deserializeBinaryFromReader);
        msg.setInfo(value1);
        break;
      case 2:
        var value2 = new PbisPersonStats;
        reader.readMessage(value2,PbisPersonStats.deserializeBinaryFromReader);
        msg.setStats(value2);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!PbisPerson} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: PbisPerson, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getInfo();
    if (f != null) {
      writer.writeMessage(
        1,
        f,
        PbisPersonInfo.serializeBinaryToWriter
      );
    }
    f = message.getStats();
    if (f != null) {
      writer.writeMessage(
        2,
        f,
        PbisPersonStats.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    PbisPerson.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional PbisPersonInfo info = 1;
   * @return {?PbisPersonInfo}
   */
  getInfo(): PbisPersonInfo {
    return /** @type{?PbisPersonInfo} */ (
      jspb.Message.getWrapperField(this, PbisPersonInfo, 1));
  }


  /** @param {?PbisPersonInfo|undefined} value */
  setInfo(value?: PbisPersonInfo) {
    jspb.Message.setWrapperField(this, 1, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearInfo() {
    this.setInfo(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasInfo(): boolean {
    return jspb.Message.getField(this, 1) != null;
  }


  /**
   * optional PbisPersonStats stats = 2;
   * @return {?PbisPersonStats}
   */
  getStats(): PbisPersonStats {
    return /** @type{?PbisPersonStats} */ (
      jspb.Message.getWrapperField(this, PbisPersonStats, 2));
  }


  /** @param {?PbisPersonStats|undefined} value */
  setStats(value?: PbisPersonStats) {
    jspb.Message.setWrapperField(this, 2, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearStats() {
    this.setStats(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasStats(): boolean {
    return jspb.Message.getField(this, 2) != null;
  }


}
export namespace ConsequencePerson {
  export interface AsObject {
    info?: PbisPersonInfo.AsObject;
    stats?: ConsequencePersonStats.AsObject;
  }
}
export class ConsequencePerson extends jspb.Message {
  static readonly displayName = "ConsequencePerson";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): ConsequencePerson.AsObject {
    return ConsequencePerson.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!ConsequencePerson} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: ConsequencePerson) {
    var f: any;
    var obj: any = {
      info: (f = msg.getInfo()) && PbisPersonInfo.toObject(includeInstance, f),
      stats: (f = msg.getStats()) && ConsequencePersonStats.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!ConsequencePerson}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new ConsequencePerson;
    return ConsequencePerson.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!ConsequencePerson} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!ConsequencePerson}
   */
  static deserializeBinaryFromReader(msg: ConsequencePerson, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new PbisPersonInfo;
        reader.readMessage(value1,PbisPersonInfo.deserializeBinaryFromReader);
        msg.setInfo(value1);
        break;
      case 2:
        var value2 = new ConsequencePersonStats;
        reader.readMessage(value2,ConsequencePersonStats.deserializeBinaryFromReader);
        msg.setStats(value2);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!ConsequencePerson} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: ConsequencePerson, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getInfo();
    if (f != null) {
      writer.writeMessage(
        1,
        f,
        PbisPersonInfo.serializeBinaryToWriter
      );
    }
    f = message.getStats();
    if (f != null) {
      writer.writeMessage(
        2,
        f,
        ConsequencePersonStats.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    ConsequencePerson.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional PbisPersonInfo info = 1;
   * @return {?PbisPersonInfo}
   */
  getInfo(): PbisPersonInfo {
    return /** @type{?PbisPersonInfo} */ (
      jspb.Message.getWrapperField(this, PbisPersonInfo, 1));
  }


  /** @param {?PbisPersonInfo|undefined} value */
  setInfo(value?: PbisPersonInfo) {
    jspb.Message.setWrapperField(this, 1, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearInfo() {
    this.setInfo(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasInfo(): boolean {
    return jspb.Message.getField(this, 1) != null;
  }


  /**
   * optional ConsequencePersonStats stats = 2;
   * @return {?ConsequencePersonStats}
   */
  getStats(): ConsequencePersonStats {
    return /** @type{?ConsequencePersonStats} */ (
      jspb.Message.getWrapperField(this, ConsequencePersonStats, 2));
  }


  /** @param {?ConsequencePersonStats|undefined} value */
  setStats(value?: ConsequencePersonStats) {
    jspb.Message.setWrapperField(this, 2, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearStats() {
    this.setStats(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasStats(): boolean {
    return jspb.Message.getField(this, 2) != null;
  }


}
export namespace PbisSortBy {
  export interface AsObject {
    column: string;
    direction: string;
  }
}
export class PbisSortBy extends jspb.Message {
  static readonly displayName = "PbisSortBy";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): PbisSortBy.AsObject {
    return PbisSortBy.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!PbisSortBy} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: PbisSortBy) {
    var f: any;
    var obj: any = {
      column: jspb.Message.getFieldWithDefault(msg, 1, ""),
      direction: jspb.Message.getFieldWithDefault(msg, 2, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!PbisSortBy}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new PbisSortBy;
    return PbisSortBy.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!PbisSortBy} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!PbisSortBy}
   */
  static deserializeBinaryFromReader(msg: PbisSortBy, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setColumn(value1);
        break;
      case 2:
        var value2 = /** @type {string} */ (reader.readString());
        msg.setDirection(value2);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!PbisSortBy} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: PbisSortBy, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getColumn();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
    f = message.getDirection();
    if (f.length > 0) {
      writer.writeString(
        2,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    PbisSortBy.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string column = 1;
   * @return {string}
   */
  getColumn(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setColumn(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


  /**
   * optional string direction = 2;
   * @return {string}
   */
  getDirection(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
  };


  /** @param {string} value */
  setDirection(value: string) {
    jspb.Message.setProto3StringField(this, 2, value);
  }


}
export namespace PbisReportFilters {
  export interface AsObject {
    startDate?: common_date_pb.DateTime.AsObject;
    endDate?: common_date_pb.DateTime.AsObject;
    groupBy: string;
    issuedByList: string[];
    issuedToList: string[];
    behaviorTypeList: number[];
    gradeList: string[];
    consequenceTypeList: number[];
    offset: number;
    limit: number;
    consequenceCatTypeList: number[];
    consequenceStatusList: string[];
    automationTypeList: number[];
    consequenceId: number;
    automationGroupId: number;
    consequenceCategory?: google_protobuf_wrappers_pb.Int32Value.AsObject;
    userListList: number[];
  }
}
export class PbisReportFilters extends jspb.Message {
  static readonly displayName = "PbisReportFilters";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [4,5,6,7,8,11,12,13,17];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, PbisReportFilters.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): PbisReportFilters.AsObject {
    return PbisReportFilters.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!PbisReportFilters} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: PbisReportFilters) {
    var f: any;
    var obj: any = {
      startDate: (f = msg.getStartDate()) && common_date_pb.DateTime.toObject(includeInstance, f),
      endDate: (f = msg.getEndDate()) && common_date_pb.DateTime.toObject(includeInstance, f),
      groupBy: jspb.Message.getFieldWithDefault(msg, 3, ""),
      issuedByList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
      issuedToList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f,
      behaviorTypeList: (f = jspb.Message.getRepeatedField(msg, 6)) == null ? undefined : f,
      gradeList: (f = jspb.Message.getRepeatedField(msg, 7)) == null ? undefined : f,
      consequenceTypeList: (f = jspb.Message.getRepeatedField(msg, 8)) == null ? undefined : f,
      offset: jspb.Message.getFieldWithDefault(msg, 9, 0),
      limit: jspb.Message.getFieldWithDefault(msg, 10, 0),
      consequenceCatTypeList: (f = jspb.Message.getRepeatedField(msg, 11)) == null ? undefined : f,
      consequenceStatusList: (f = jspb.Message.getRepeatedField(msg, 12)) == null ? undefined : f,
      automationTypeList: (f = jspb.Message.getRepeatedField(msg, 13)) == null ? undefined : f,
      consequenceId: jspb.Message.getFieldWithDefault(msg, 14, 0),
      automationGroupId: jspb.Message.getFieldWithDefault(msg, 15, 0),
      consequenceCategory: (f = msg.getConsequenceCategory()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
      userListList: (f = jspb.Message.getRepeatedField(msg, 17)) == null ? undefined : f
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!PbisReportFilters}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new PbisReportFilters;
    return PbisReportFilters.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!PbisReportFilters} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!PbisReportFilters}
   */
  static deserializeBinaryFromReader(msg: PbisReportFilters, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new common_date_pb.DateTime;
        reader.readMessage(value1,common_date_pb.DateTime.deserializeBinaryFromReader);
        msg.setStartDate(value1);
        break;
      case 2:
        var value2 = new common_date_pb.DateTime;
        reader.readMessage(value2,common_date_pb.DateTime.deserializeBinaryFromReader);
        msg.setEndDate(value2);
        break;
      case 3:
        var value3 = /** @type {string} */ (reader.readString());
        msg.setGroupBy(value3);
        break;
      case 4:
        var value4 = /** @type {string} */ (reader.readString());
        msg.addIssuedBy(value4);
        break;
      case 5:
        var value5 = /** @type {string} */ (reader.readString());
        msg.addIssuedTo(value5);
        break;
      case 6:
        var value6 = /** @type {!Array<number>} */ (reader.readPackedInt32());
        msg.setBehaviorTypeList(value6);
        break;
      case 7:
        var value7 = /** @type {string} */ (reader.readString());
        msg.addGrade(value7);
        break;
      case 8:
        var value8 = /** @type {!Array<number>} */ (reader.readPackedInt32());
        msg.setConsequenceTypeList(value8);
        break;
      case 9:
        var value9 = /** @type {number} */ (reader.readInt32());
        msg.setOffset(value9);
        break;
      case 10:
        var value10 = /** @type {number} */ (reader.readInt32());
        msg.setLimit(value10);
        break;
      case 11:
        var value11 = /** @type {!Array<number>} */ (reader.readPackedInt32());
        msg.setConsequenceCatTypeList(value11);
        break;
      case 12:
        var value12 = /** @type {string} */ (reader.readString());
        msg.addConsequenceStatus(value12);
        break;
      case 13:
        var value13 = /** @type {!Array<number>} */ (reader.readPackedInt32());
        msg.setAutomationTypeList(value13);
        break;
      case 14:
        var value14 = /** @type {number} */ (reader.readInt32());
        msg.setConsequenceId(value14);
        break;
      case 15:
        var value15 = /** @type {number} */ (reader.readInt32());
        msg.setAutomationGroupId(value15);
        break;
      case 16:
        var value16 = new google_protobuf_wrappers_pb.Int32Value;
        reader.readMessage(value16,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
        msg.setConsequenceCategory(value16);
        break;
      case 17:
        var value17 = /** @type {!Array<number>} */ (reader.readPackedInt32());
        msg.setUserListList(value17);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!PbisReportFilters} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: PbisReportFilters, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getStartDate();
    if (f != null) {
      writer.writeMessage(
        1,
        f,
        common_date_pb.DateTime.serializeBinaryToWriter
      );
    }
    f = message.getEndDate();
    if (f != null) {
      writer.writeMessage(
        2,
        f,
        common_date_pb.DateTime.serializeBinaryToWriter
      );
    }
    f = message.getGroupBy();
    if (f.length > 0) {
      writer.writeString(
        3,
        f
      );
    }
    f = message.getIssuedByList();
    if (f.length > 0) {
      writer.writeRepeatedString(
        4,
        f
      );
    }
    f = message.getIssuedToList();
    if (f.length > 0) {
      writer.writeRepeatedString(
        5,
        f
      );
    }
    f = message.getBehaviorTypeList();
    if (f.length > 0) {
      writer.writePackedInt32(
        6,
        f
      );
    }
    f = message.getGradeList();
    if (f.length > 0) {
      writer.writeRepeatedString(
        7,
        f
      );
    }
    f = message.getConsequenceTypeList();
    if (f.length > 0) {
      writer.writePackedInt32(
        8,
        f
      );
    }
    f = message.getOffset();
    if (f !== 0) {
      writer.writeInt32(
        9,
        f
      );
    }
    f = message.getLimit();
    if (f !== 0) {
      writer.writeInt32(
        10,
        f
      );
    }
    f = message.getConsequenceCatTypeList();
    if (f.length > 0) {
      writer.writePackedInt32(
        11,
        f
      );
    }
    f = message.getConsequenceStatusList();
    if (f.length > 0) {
      writer.writeRepeatedString(
        12,
        f
      );
    }
    f = message.getAutomationTypeList();
    if (f.length > 0) {
      writer.writePackedInt32(
        13,
        f
      );
    }
    f = message.getConsequenceId();
    if (f !== 0) {
      writer.writeInt32(
        14,
        f
      );
    }
    f = message.getAutomationGroupId();
    if (f !== 0) {
      writer.writeInt32(
        15,
        f
      );
    }
    f = message.getConsequenceCategory();
    if (f != null) {
      writer.writeMessage(
        16,
        f,
        google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
      );
    }
    f = message.getUserListList();
    if (f.length > 0) {
      writer.writePackedInt32(
        17,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    PbisReportFilters.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional minga.common.DateTime start_date = 1;
   * @return {?DateTime}
   */
  getStartDate(): common_date_pb.DateTime {
    return /** @type{?DateTime} */ (
      jspb.Message.getWrapperField(this, common_date_pb.DateTime, 1));
  }


  /** @param {?DateTime|undefined} value */
  setStartDate(value?: common_date_pb.DateTime) {
    jspb.Message.setWrapperField(this, 1, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearStartDate() {
    this.setStartDate(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasStartDate(): boolean {
    return jspb.Message.getField(this, 1) != null;
  }


  /**
   * optional minga.common.DateTime end_date = 2;
   * @return {?DateTime}
   */
  getEndDate(): common_date_pb.DateTime {
    return /** @type{?DateTime} */ (
      jspb.Message.getWrapperField(this, common_date_pb.DateTime, 2));
  }


  /** @param {?DateTime|undefined} value */
  setEndDate(value?: common_date_pb.DateTime) {
    jspb.Message.setWrapperField(this, 2, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearEndDate() {
    this.setEndDate(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasEndDate(): boolean {
    return jspb.Message.getField(this, 2) != null;
  }


  /**
   * optional string group_by = 3;
   * @return {string}
   */
  getGroupBy(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
  };


  /** @param {string} value */
  setGroupBy(value: string) {
    jspb.Message.setProto3StringField(this, 3, value);
  }


  /**
   * repeated string issued_by = 4;
   * @return {!Array<string>}
   */
  getIssuedByList(): string[] {
    return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 4));
  };


  /** @param {!Array<string>} value */
  setIssuedByList(value: string[]) {
    jspb.Message.setField(this, 4, value || []);
  }


  /**
   * @param {string} value
   * @param {number=} opt_index
   */
  addIssuedBy(value: string, opt_index?: number): void {
    jspb.Message.addToRepeatedField(this, 4, value, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearIssuedByList() {
    this.setIssuedByList([]);
  }


  /**
   * repeated string issued_to = 5;
   * @return {!Array<string>}
   */
  getIssuedToList(): string[] {
    return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 5));
  };


  /** @param {!Array<string>} value */
  setIssuedToList(value: string[]) {
    jspb.Message.setField(this, 5, value || []);
  }


  /**
   * @param {string} value
   * @param {number=} opt_index
   */
  addIssuedTo(value: string, opt_index?: number): void {
    jspb.Message.addToRepeatedField(this, 5, value, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearIssuedToList() {
    this.setIssuedToList([]);
  }


  /**
   * repeated int32 behavior_type = 6;
   * @return {!Array<number>}
   */
  getBehaviorTypeList(): number[] {
    return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 6));
  };


  /** @param {!Array<number>} value */
  setBehaviorTypeList(value: number[]) {
    jspb.Message.setField(this, 6, value || []);
  }


  /**
   * @param {number} value
   * @param {number=} opt_index
   */
  addBehaviorType(value: number, opt_index?: number): void {
    jspb.Message.addToRepeatedField(this, 6, value, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearBehaviorTypeList() {
    this.setBehaviorTypeList([]);
  }


  /**
   * repeated string grade = 7;
   * @return {!Array<string>}
   */
  getGradeList(): string[] {
    return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 7));
  };


  /** @param {!Array<string>} value */
  setGradeList(value: string[]) {
    jspb.Message.setField(this, 7, value || []);
  }


  /**
   * @param {string} value
   * @param {number=} opt_index
   */
  addGrade(value: string, opt_index?: number): void {
    jspb.Message.addToRepeatedField(this, 7, value, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearGradeList() {
    this.setGradeList([]);
  }


  /**
   * repeated int32 consequence_type = 8;
   * @return {!Array<number>}
   */
  getConsequenceTypeList(): number[] {
    return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 8));
  };


  /** @param {!Array<number>} value */
  setConsequenceTypeList(value: number[]) {
    jspb.Message.setField(this, 8, value || []);
  }


  /**
   * @param {number} value
   * @param {number=} opt_index
   */
  addConsequenceType(value: number, opt_index?: number): void {
    jspb.Message.addToRepeatedField(this, 8, value, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearConsequenceTypeList() {
    this.setConsequenceTypeList([]);
  }


  /**
   * optional int32 offset = 9;
   * @return {number}
   */
  getOffset(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
  };


  /** @param {number} value */
  setOffset(value: number) {
    jspb.Message.setProto3IntField(this, 9, value);
  }


  /**
   * optional int32 limit = 10;
   * @return {number}
   */
  getLimit(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
  };


  /** @param {number} value */
  setLimit(value: number) {
    jspb.Message.setProto3IntField(this, 10, value);
  }


  /**
   * repeated int32 consequence_cat_type = 11;
   * @return {!Array<number>}
   */
  getConsequenceCatTypeList(): number[] {
    return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 11));
  };


  /** @param {!Array<number>} value */
  setConsequenceCatTypeList(value: number[]) {
    jspb.Message.setField(this, 11, value || []);
  }


  /**
   * @param {number} value
   * @param {number=} opt_index
   */
  addConsequenceCatType(value: number, opt_index?: number): void {
    jspb.Message.addToRepeatedField(this, 11, value, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearConsequenceCatTypeList() {
    this.setConsequenceCatTypeList([]);
  }


  /**
   * repeated string consequence_status = 12;
   * @return {!Array<string>}
   */
  getConsequenceStatusList(): string[] {
    return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 12));
  };


  /** @param {!Array<string>} value */
  setConsequenceStatusList(value: string[]) {
    jspb.Message.setField(this, 12, value || []);
  }


  /**
   * @param {string} value
   * @param {number=} opt_index
   */
  addConsequenceStatus(value: string, opt_index?: number): void {
    jspb.Message.addToRepeatedField(this, 12, value, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearConsequenceStatusList() {
    this.setConsequenceStatusList([]);
  }


  /**
   * repeated int32 automation_type = 13;
   * @return {!Array<number>}
   */
  getAutomationTypeList(): number[] {
    return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 13));
  };


  /** @param {!Array<number>} value */
  setAutomationTypeList(value: number[]) {
    jspb.Message.setField(this, 13, value || []);
  }


  /**
   * @param {number} value
   * @param {number=} opt_index
   */
  addAutomationType(value: number, opt_index?: number): void {
    jspb.Message.addToRepeatedField(this, 13, value, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearAutomationTypeList() {
    this.setAutomationTypeList([]);
  }


  /**
   * optional int32 consequence_id = 14;
   * @return {number}
   */
  getConsequenceId(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
  };


  /** @param {number} value */
  setConsequenceId(value: number) {
    jspb.Message.setProto3IntField(this, 14, value);
  }


  /**
   * optional int32 automation_group_id = 15;
   * @return {number}
   */
  getAutomationGroupId(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
  };


  /** @param {number} value */
  setAutomationGroupId(value: number) {
    jspb.Message.setProto3IntField(this, 15, value);
  }


  /**
   * optional google.protobuf.Int32Value consequence_category = 16;
   * @return {?Int32Value}
   */
  getConsequenceCategory(): google_protobuf_wrappers_pb.Int32Value {
    return /** @type{?Int32Value} */ (
      jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 16));
  }


  /** @param {?Int32Value|undefined} value */
  setConsequenceCategory(value?: google_protobuf_wrappers_pb.Int32Value) {
    jspb.Message.setWrapperField(this, 16, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearConsequenceCategory() {
    this.setConsequenceCategory(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasConsequenceCategory(): boolean {
    return jspb.Message.getField(this, 16) != null;
  }


  /**
   * repeated int32 user_list = 17;
   * @return {!Array<number>}
   */
  getUserListList(): number[] {
    return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 17));
  };


  /** @param {!Array<number>} value */
  setUserListList(value: number[]) {
    jspb.Message.setField(this, 17, value || []);
  }


  /**
   * @param {number} value
   * @param {number=} opt_index
   */
  addUserList(value: number, opt_index?: number): void {
    jspb.Message.addToRepeatedField(this, 17, value, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearUserListList() {
    this.setUserListList([]);
  }


}
export namespace ExportPbisReportRequest {
  export interface AsObject {
    filters?: PbisReportFilters.AsObject;
    reportType: string;
  }
}
export class ExportPbisReportRequest extends jspb.Message {
  static readonly displayName = "ExportPbisReportRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): ExportPbisReportRequest.AsObject {
    return ExportPbisReportRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!ExportPbisReportRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: ExportPbisReportRequest) {
    var f: any;
    var obj: any = {
      filters: (f = msg.getFilters()) && PbisReportFilters.toObject(includeInstance, f),
      reportType: jspb.Message.getFieldWithDefault(msg, 2, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!ExportPbisReportRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new ExportPbisReportRequest;
    return ExportPbisReportRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!ExportPbisReportRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!ExportPbisReportRequest}
   */
  static deserializeBinaryFromReader(msg: ExportPbisReportRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new PbisReportFilters;
        reader.readMessage(value1,PbisReportFilters.deserializeBinaryFromReader);
        msg.setFilters(value1);
        break;
      case 2:
        var value2 = /** @type {string} */ (reader.readString());
        msg.setReportType(value2);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!ExportPbisReportRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: ExportPbisReportRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getFilters();
    if (f != null) {
      writer.writeMessage(
        1,
        f,
        PbisReportFilters.serializeBinaryToWriter
      );
    }
    f = message.getReportType();
    if (f.length > 0) {
      writer.writeString(
        2,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    ExportPbisReportRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional PbisReportFilters filters = 1;
   * @return {?PbisReportFilters}
   */
  getFilters(): PbisReportFilters {
    return /** @type{?PbisReportFilters} */ (
      jspb.Message.getWrapperField(this, PbisReportFilters, 1));
  }


  /** @param {?PbisReportFilters|undefined} value */
  setFilters(value?: PbisReportFilters) {
    jspb.Message.setWrapperField(this, 1, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearFilters() {
    this.setFilters(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasFilters(): boolean {
    return jspb.Message.getField(this, 1) != null;
  }


  /**
   * optional string report_type = 2;
   * @return {string}
   */
  getReportType(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
  };


  /** @param {string} value */
  setReportType(value: string) {
    jspb.Message.setProto3StringField(this, 2, value);
  }


}
