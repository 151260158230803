import { Injectable, NgZone } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { grpc } from '@improbable-eng/grpc-web';

import {
  StreamControl,
  StreamControlResponse,
  StreamID,
} from '../common/stream_pb';
import {
  AddMembersToTeamRequest,
  AddMembersToTeamResponse,
  AddPointsRequest,
  AddPointsResponse,
  ArchivePointActionRequest,
  ArchivePointActionResponse,
  ArchivePointRedemptionRequest,
  ArchivePointRedemptionResponse,
  DeletePointRewardRequest,
  DeletePointRewardResponse,
  ExportLeaderboardChunk,
  ExportLeaderboardRequest,
  GetPersonLeaderboardRequest,
  GetPersonLeaderboardResponse,
  GetPointCategoriesRequest,
  GetPointCategoriesResponse,
  GetPointRewardRequest,
  GetPointRewardResponse,
  GetPointRewardsRequest,
  GetPointRewardsResponse,
  GetProfilePointsSummaryRequest,
  GetProfilePointsSummaryResponse,
  GetRedeemablePointsRequest,
  GetRedeemablePointsResponse,
  GetRedeemedPointsRequest,
  GetRedeemedPointsResponse,
  GetTeamLeaderboardRequest,
  GetTeamLeaderboardResponse,
  GetTeamMembersRequest,
  GetTeamMembersResponse,
  GetTeamsRequest,
  GetTeamsResponse,
  RedeemPointsRequest,
  RedeemPointsResponse,
  RemoveMembersFromTeamRequest,
  RemoveMembersFromTeamResponse,
  RemovePointsRequest,
  RemovePointsResponse,
  RemoveTeamRequest,
  RemoveTeamResponse,
  ResetMingaPointsHistoryRequest,
  ResetMingaPointsHistoryResponse,
  StreamPointsHistoryResponse,
  UpdateTeamRequest,
  UpdateTeamResponse,
  UpsertPointRewardRequest,
  UpsertPointRewardResponse,
} from '../points/points_pb';

import {
  PointsManager as __PointsManager,
} from '../points/points_pb_service';

@Injectable({providedIn: 'root'})
export class PointsManager {

  constructor(private _ngZone: NgZone) {
  }

  addPoints(request: AddPointsRequest): Promise<AddPointsResponse>;
  addPoints(request: AddPointsRequest, metadata: grpc.Metadata): Promise<AddPointsResponse>;
  addPoints(request: AddPointsRequest, callback: (err: any|null, response: AddPointsResponse, metadata: grpc.Metadata) => void): void;
  addPoints(request: AddPointsRequest, metadata: grpc.Metadata, callback: (err: any|null, response: AddPointsResponse, metadata: grpc.Metadata) => void): void;

  addPoints(request: AddPointsRequest, arg1?: grpc.Metadata|((err: any|null, response: AddPointsResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: AddPointsResponse, metadata: grpc.Metadata) => void): Promise<AddPointsResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<AddPointsResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__PointsManager.AddPoints, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  removePoints(request: RemovePointsRequest): Promise<RemovePointsResponse>;
  removePoints(request: RemovePointsRequest, metadata: grpc.Metadata): Promise<RemovePointsResponse>;
  removePoints(request: RemovePointsRequest, callback: (err: any|null, response: RemovePointsResponse, metadata: grpc.Metadata) => void): void;
  removePoints(request: RemovePointsRequest, metadata: grpc.Metadata, callback: (err: any|null, response: RemovePointsResponse, metadata: grpc.Metadata) => void): void;

  removePoints(request: RemovePointsRequest, arg1?: grpc.Metadata|((err: any|null, response: RemovePointsResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: RemovePointsResponse, metadata: grpc.Metadata) => void): Promise<RemovePointsResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<RemovePointsResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__PointsManager.RemovePoints, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  getTeams(request: GetTeamsRequest): Promise<GetTeamsResponse>;
  getTeams(request: GetTeamsRequest, metadata: grpc.Metadata): Promise<GetTeamsResponse>;
  getTeams(request: GetTeamsRequest, callback: (err: any|null, response: GetTeamsResponse, metadata: grpc.Metadata) => void): void;
  getTeams(request: GetTeamsRequest, metadata: grpc.Metadata, callback: (err: any|null, response: GetTeamsResponse, metadata: grpc.Metadata) => void): void;

  getTeams(request: GetTeamsRequest, arg1?: grpc.Metadata|((err: any|null, response: GetTeamsResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: GetTeamsResponse, metadata: grpc.Metadata) => void): Promise<GetTeamsResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<GetTeamsResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__PointsManager.GetTeams, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  updateTeam(request: UpdateTeamRequest): Promise<UpdateTeamResponse>;
  updateTeam(request: UpdateTeamRequest, metadata: grpc.Metadata): Promise<UpdateTeamResponse>;
  updateTeam(request: UpdateTeamRequest, callback: (err: any|null, response: UpdateTeamResponse, metadata: grpc.Metadata) => void): void;
  updateTeam(request: UpdateTeamRequest, metadata: grpc.Metadata, callback: (err: any|null, response: UpdateTeamResponse, metadata: grpc.Metadata) => void): void;

  updateTeam(request: UpdateTeamRequest, arg1?: grpc.Metadata|((err: any|null, response: UpdateTeamResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: UpdateTeamResponse, metadata: grpc.Metadata) => void): Promise<UpdateTeamResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<UpdateTeamResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__PointsManager.UpdateTeam, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  removeTeam(request: RemoveTeamRequest): Promise<RemoveTeamResponse>;
  removeTeam(request: RemoveTeamRequest, metadata: grpc.Metadata): Promise<RemoveTeamResponse>;
  removeTeam(request: RemoveTeamRequest, callback: (err: any|null, response: RemoveTeamResponse, metadata: grpc.Metadata) => void): void;
  removeTeam(request: RemoveTeamRequest, metadata: grpc.Metadata, callback: (err: any|null, response: RemoveTeamResponse, metadata: grpc.Metadata) => void): void;

  removeTeam(request: RemoveTeamRequest, arg1?: grpc.Metadata|((err: any|null, response: RemoveTeamResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: RemoveTeamResponse, metadata: grpc.Metadata) => void): Promise<RemoveTeamResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<RemoveTeamResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__PointsManager.RemoveTeam, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  addMembersToTeam(request: AddMembersToTeamRequest): Promise<AddMembersToTeamResponse>;
  addMembersToTeam(request: AddMembersToTeamRequest, metadata: grpc.Metadata): Promise<AddMembersToTeamResponse>;
  addMembersToTeam(request: AddMembersToTeamRequest, callback: (err: any|null, response: AddMembersToTeamResponse, metadata: grpc.Metadata) => void): void;
  addMembersToTeam(request: AddMembersToTeamRequest, metadata: grpc.Metadata, callback: (err: any|null, response: AddMembersToTeamResponse, metadata: grpc.Metadata) => void): void;

  addMembersToTeam(request: AddMembersToTeamRequest, arg1?: grpc.Metadata|((err: any|null, response: AddMembersToTeamResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: AddMembersToTeamResponse, metadata: grpc.Metadata) => void): Promise<AddMembersToTeamResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<AddMembersToTeamResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__PointsManager.AddMembersToTeam, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  removeMembersFromTeam(request: RemoveMembersFromTeamRequest): Promise<RemoveMembersFromTeamResponse>;
  removeMembersFromTeam(request: RemoveMembersFromTeamRequest, metadata: grpc.Metadata): Promise<RemoveMembersFromTeamResponse>;
  removeMembersFromTeam(request: RemoveMembersFromTeamRequest, callback: (err: any|null, response: RemoveMembersFromTeamResponse, metadata: grpc.Metadata) => void): void;
  removeMembersFromTeam(request: RemoveMembersFromTeamRequest, metadata: grpc.Metadata, callback: (err: any|null, response: RemoveMembersFromTeamResponse, metadata: grpc.Metadata) => void): void;

  removeMembersFromTeam(request: RemoveMembersFromTeamRequest, arg1?: grpc.Metadata|((err: any|null, response: RemoveMembersFromTeamResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: RemoveMembersFromTeamResponse, metadata: grpc.Metadata) => void): Promise<RemoveMembersFromTeamResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<RemoveMembersFromTeamResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__PointsManager.RemoveMembersFromTeam, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  getTeamMembers(request: GetTeamMembersRequest): Promise<GetTeamMembersResponse>;
  getTeamMembers(request: GetTeamMembersRequest, metadata: grpc.Metadata): Promise<GetTeamMembersResponse>;
  getTeamMembers(request: GetTeamMembersRequest, callback: (err: any|null, response: GetTeamMembersResponse, metadata: grpc.Metadata) => void): void;
  getTeamMembers(request: GetTeamMembersRequest, metadata: grpc.Metadata, callback: (err: any|null, response: GetTeamMembersResponse, metadata: grpc.Metadata) => void): void;

  getTeamMembers(request: GetTeamMembersRequest, arg1?: grpc.Metadata|((err: any|null, response: GetTeamMembersResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: GetTeamMembersResponse, metadata: grpc.Metadata) => void): Promise<GetTeamMembersResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<GetTeamMembersResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__PointsManager.GetTeamMembers, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  teamLeaderboard(request: GetTeamLeaderboardRequest): Promise<GetTeamLeaderboardResponse>;
  teamLeaderboard(request: GetTeamLeaderboardRequest, metadata: grpc.Metadata): Promise<GetTeamLeaderboardResponse>;
  teamLeaderboard(request: GetTeamLeaderboardRequest, callback: (err: any|null, response: GetTeamLeaderboardResponse, metadata: grpc.Metadata) => void): void;
  teamLeaderboard(request: GetTeamLeaderboardRequest, metadata: grpc.Metadata, callback: (err: any|null, response: GetTeamLeaderboardResponse, metadata: grpc.Metadata) => void): void;

  teamLeaderboard(request: GetTeamLeaderboardRequest, arg1?: grpc.Metadata|((err: any|null, response: GetTeamLeaderboardResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: GetTeamLeaderboardResponse, metadata: grpc.Metadata) => void): Promise<GetTeamLeaderboardResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<GetTeamLeaderboardResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__PointsManager.TeamLeaderboard, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  personLeaderboard(request: GetPersonLeaderboardRequest): Promise<GetPersonLeaderboardResponse>;
  personLeaderboard(request: GetPersonLeaderboardRequest, metadata: grpc.Metadata): Promise<GetPersonLeaderboardResponse>;
  personLeaderboard(request: GetPersonLeaderboardRequest, callback: (err: any|null, response: GetPersonLeaderboardResponse, metadata: grpc.Metadata) => void): void;
  personLeaderboard(request: GetPersonLeaderboardRequest, metadata: grpc.Metadata, callback: (err: any|null, response: GetPersonLeaderboardResponse, metadata: grpc.Metadata) => void): void;

  personLeaderboard(request: GetPersonLeaderboardRequest, arg1?: grpc.Metadata|((err: any|null, response: GetPersonLeaderboardResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: GetPersonLeaderboardResponse, metadata: grpc.Metadata) => void): Promise<GetPersonLeaderboardResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<GetPersonLeaderboardResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__PointsManager.PersonLeaderboard, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  getProfilePointsSummary(request: GetProfilePointsSummaryRequest): Promise<GetProfilePointsSummaryResponse>;
  getProfilePointsSummary(request: GetProfilePointsSummaryRequest, metadata: grpc.Metadata): Promise<GetProfilePointsSummaryResponse>;
  getProfilePointsSummary(request: GetProfilePointsSummaryRequest, callback: (err: any|null, response: GetProfilePointsSummaryResponse, metadata: grpc.Metadata) => void): void;
  getProfilePointsSummary(request: GetProfilePointsSummaryRequest, metadata: grpc.Metadata, callback: (err: any|null, response: GetProfilePointsSummaryResponse, metadata: grpc.Metadata) => void): void;

  getProfilePointsSummary(request: GetProfilePointsSummaryRequest, arg1?: grpc.Metadata|((err: any|null, response: GetProfilePointsSummaryResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: GetProfilePointsSummaryResponse, metadata: grpc.Metadata) => void): Promise<GetProfilePointsSummaryResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<GetProfilePointsSummaryResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__PointsManager.GetProfilePointsSummary, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  getPointCategories(request: GetPointCategoriesRequest): Promise<GetPointCategoriesResponse>;
  getPointCategories(request: GetPointCategoriesRequest, metadata: grpc.Metadata): Promise<GetPointCategoriesResponse>;
  getPointCategories(request: GetPointCategoriesRequest, callback: (err: any|null, response: GetPointCategoriesResponse, metadata: grpc.Metadata) => void): void;
  getPointCategories(request: GetPointCategoriesRequest, metadata: grpc.Metadata, callback: (err: any|null, response: GetPointCategoriesResponse, metadata: grpc.Metadata) => void): void;

  getPointCategories(request: GetPointCategoriesRequest, arg1?: grpc.Metadata|((err: any|null, response: GetPointCategoriesResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: GetPointCategoriesResponse, metadata: grpc.Metadata) => void): Promise<GetPointCategoriesResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<GetPointCategoriesResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__PointsManager.GetPointCategories, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  resetMingaPointsHistory(request: ResetMingaPointsHistoryRequest): Promise<ResetMingaPointsHistoryResponse>;
  resetMingaPointsHistory(request: ResetMingaPointsHistoryRequest, metadata: grpc.Metadata): Promise<ResetMingaPointsHistoryResponse>;
  resetMingaPointsHistory(request: ResetMingaPointsHistoryRequest, callback: (err: any|null, response: ResetMingaPointsHistoryResponse, metadata: grpc.Metadata) => void): void;
  resetMingaPointsHistory(request: ResetMingaPointsHistoryRequest, metadata: grpc.Metadata, callback: (err: any|null, response: ResetMingaPointsHistoryResponse, metadata: grpc.Metadata) => void): void;

  resetMingaPointsHistory(request: ResetMingaPointsHistoryRequest, arg1?: grpc.Metadata|((err: any|null, response: ResetMingaPointsHistoryResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: ResetMingaPointsHistoryResponse, metadata: grpc.Metadata) => void): Promise<ResetMingaPointsHistoryResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<ResetMingaPointsHistoryResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__PointsManager.ResetMingaPointsHistory, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  streamPointsHistory(request: StreamID): {cancel():void;close():void}&Observable<StreamPointsHistoryResponse>;
  streamPointsHistory(request: StreamID, metadata: grpc.Metadata): {cancel():void;close():void}&Observable<StreamPointsHistoryResponse>;
  streamPointsHistory(request: StreamID, onMessage: (message?: StreamPointsHistoryResponse) => void,onError?: (err: any) => void,onEnd?: (code: number, msg: string|undefined, metadata: grpc.Metadata) => void): void;
  streamPointsHistory(request: StreamID, metadata: grpc.Metadata, onMessage: (message?: StreamPointsHistoryResponse) => void,onError?: (err: any) => void,onEnd?: (code: number, msg: string|undefined, metadata: grpc.Metadata) => void): void;

  streamPointsHistory(request: StreamID, arg1?: grpc.Metadata|((message?: StreamPointsHistoryResponse) => void), arg2?: ((message?: StreamPointsHistoryResponse) => void)|((err: any) => void), arg3?: ((err: any) => void)|((code: number, msg: string|undefined, metadata: grpc.Metadata) => void), arg4?: (code: number, msg: string|undefined, metadata: grpc.Metadata) => void): {cancel():void;close():void}&Observable<StreamPointsHistoryResponse>|void {
    let ret: any;
    let metadata: any;

    let onMessage: any;
    let onError: any;
    let onEnd: any;
    if(typeof arg1 === 'function') {
      onMessage = arg1;
      onError = arg2;
      onEnd = arg3;
    } else if(typeof arg2 === 'function') {
      metadata = arg1;
      onMessage = arg2;
      onError = arg3;
      onEnd = arg4;
    } else {
      metadata = arg1;
    }

    if(!onMessage) {
      let subject = new Subject<StreamPointsHistoryResponse>();
      ret = subject.asObservable();

      onMessage = (response: any) => {
        subject.next(response);
      };

      onError = (err: any) => {
        subject.error(err);
      };

      onEnd = (code: any, msg: any, metadata: any) => {
        subject.complete();
      };

    } else {
      if(!onError) {
        onError = (err: any) => console.error(err);
      }

      if(!onEnd) {
        onEnd = (code: any, msg: any, metadata: any) => {};
      }
    }

    let req = grpc.invoke(__PointsManager.StreamPointsHistory, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onMessage: response => this._ngZone.run(() => {
        onMessage(response);
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code == grpc.Code.OK) {
          onEnd(code, msg, metadata);
        } else {
          let err = new Error(code + ' ' + (msg||''));
          (<any>err).code = code;
          onError(err);
        }
      })
    });

    (<any>ret).close = () => {
      console.warn('[Angular Grpc] .close() is deprecated use .cancel() instead');
      return req.close();
    };
    (<any>ret).cancel = () => req.close();
    return ret;
  }

  streamPointsHistoryControl(request: StreamControl): Promise<StreamControlResponse>;
  streamPointsHistoryControl(request: StreamControl, metadata: grpc.Metadata): Promise<StreamControlResponse>;
  streamPointsHistoryControl(request: StreamControl, callback: (err: any|null, response: StreamControlResponse, metadata: grpc.Metadata) => void): void;
  streamPointsHistoryControl(request: StreamControl, metadata: grpc.Metadata, callback: (err: any|null, response: StreamControlResponse, metadata: grpc.Metadata) => void): void;

  streamPointsHistoryControl(request: StreamControl, arg1?: grpc.Metadata|((err: any|null, response: StreamControlResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: StreamControlResponse, metadata: grpc.Metadata) => void): Promise<StreamControlResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<StreamControlResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__PointsManager.StreamPointsHistoryControl, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  exportLeaderboard(request: ExportLeaderboardRequest): {cancel():void;close():void}&Observable<ExportLeaderboardChunk>;
  exportLeaderboard(request: ExportLeaderboardRequest, metadata: grpc.Metadata): {cancel():void;close():void}&Observable<ExportLeaderboardChunk>;
  exportLeaderboard(request: ExportLeaderboardRequest, onMessage: (message?: ExportLeaderboardChunk) => void,onError?: (err: any) => void,onEnd?: (code: number, msg: string|undefined, metadata: grpc.Metadata) => void): void;
  exportLeaderboard(request: ExportLeaderboardRequest, metadata: grpc.Metadata, onMessage: (message?: ExportLeaderboardChunk) => void,onError?: (err: any) => void,onEnd?: (code: number, msg: string|undefined, metadata: grpc.Metadata) => void): void;

  exportLeaderboard(request: ExportLeaderboardRequest, arg1?: grpc.Metadata|((message?: ExportLeaderboardChunk) => void), arg2?: ((message?: ExportLeaderboardChunk) => void)|((err: any) => void), arg3?: ((err: any) => void)|((code: number, msg: string|undefined, metadata: grpc.Metadata) => void), arg4?: (code: number, msg: string|undefined, metadata: grpc.Metadata) => void): {cancel():void;close():void}&Observable<ExportLeaderboardChunk>|void {
    let ret: any;
    let metadata: any;

    let onMessage: any;
    let onError: any;
    let onEnd: any;
    if(typeof arg1 === 'function') {
      onMessage = arg1;
      onError = arg2;
      onEnd = arg3;
    } else if(typeof arg2 === 'function') {
      metadata = arg1;
      onMessage = arg2;
      onError = arg3;
      onEnd = arg4;
    } else {
      metadata = arg1;
    }

    if(!onMessage) {
      let subject = new Subject<ExportLeaderboardChunk>();
      ret = subject.asObservable();

      onMessage = (response: any) => {
        subject.next(response);
      };

      onError = (err: any) => {
        subject.error(err);
      };

      onEnd = (code: any, msg: any, metadata: any) => {
        subject.complete();
      };

    } else {
      if(!onError) {
        onError = (err: any) => console.error(err);
      }

      if(!onEnd) {
        onEnd = (code: any, msg: any, metadata: any) => {};
      }
    }

    let req = grpc.invoke(__PointsManager.ExportLeaderboard, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onMessage: response => this._ngZone.run(() => {
        onMessage(response);
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code == grpc.Code.OK) {
          onEnd(code, msg, metadata);
        } else {
          let err = new Error(code + ' ' + (msg||''));
          (<any>err).code = code;
          onError(err);
        }
      })
    });

    (<any>ret).close = () => {
      console.warn('[Angular Grpc] .close() is deprecated use .cancel() instead');
      return req.close();
    };
    (<any>ret).cancel = () => req.close();
    return ret;
  }

  redeemPoints(request: RedeemPointsRequest): Promise<RedeemPointsResponse>;
  redeemPoints(request: RedeemPointsRequest, metadata: grpc.Metadata): Promise<RedeemPointsResponse>;
  redeemPoints(request: RedeemPointsRequest, callback: (err: any|null, response: RedeemPointsResponse, metadata: grpc.Metadata) => void): void;
  redeemPoints(request: RedeemPointsRequest, metadata: grpc.Metadata, callback: (err: any|null, response: RedeemPointsResponse, metadata: grpc.Metadata) => void): void;

  redeemPoints(request: RedeemPointsRequest, arg1?: grpc.Metadata|((err: any|null, response: RedeemPointsResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: RedeemPointsResponse, metadata: grpc.Metadata) => void): Promise<RedeemPointsResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<RedeemPointsResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__PointsManager.RedeemPoints, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  getRedeemedPoints(request: GetRedeemedPointsRequest): Promise<GetRedeemedPointsResponse>;
  getRedeemedPoints(request: GetRedeemedPointsRequest, metadata: grpc.Metadata): Promise<GetRedeemedPointsResponse>;
  getRedeemedPoints(request: GetRedeemedPointsRequest, callback: (err: any|null, response: GetRedeemedPointsResponse, metadata: grpc.Metadata) => void): void;
  getRedeemedPoints(request: GetRedeemedPointsRequest, metadata: grpc.Metadata, callback: (err: any|null, response: GetRedeemedPointsResponse, metadata: grpc.Metadata) => void): void;

  getRedeemedPoints(request: GetRedeemedPointsRequest, arg1?: grpc.Metadata|((err: any|null, response: GetRedeemedPointsResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: GetRedeemedPointsResponse, metadata: grpc.Metadata) => void): Promise<GetRedeemedPointsResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<GetRedeemedPointsResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__PointsManager.GetRedeemedPoints, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  getRedeemablePoints(request: GetRedeemablePointsRequest): Promise<GetRedeemablePointsResponse>;
  getRedeemablePoints(request: GetRedeemablePointsRequest, metadata: grpc.Metadata): Promise<GetRedeemablePointsResponse>;
  getRedeemablePoints(request: GetRedeemablePointsRequest, callback: (err: any|null, response: GetRedeemablePointsResponse, metadata: grpc.Metadata) => void): void;
  getRedeemablePoints(request: GetRedeemablePointsRequest, metadata: grpc.Metadata, callback: (err: any|null, response: GetRedeemablePointsResponse, metadata: grpc.Metadata) => void): void;

  getRedeemablePoints(request: GetRedeemablePointsRequest, arg1?: grpc.Metadata|((err: any|null, response: GetRedeemablePointsResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: GetRedeemablePointsResponse, metadata: grpc.Metadata) => void): Promise<GetRedeemablePointsResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<GetRedeemablePointsResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__PointsManager.GetRedeemablePoints, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  upsertPointReward(request: UpsertPointRewardRequest): Promise<UpsertPointRewardResponse>;
  upsertPointReward(request: UpsertPointRewardRequest, metadata: grpc.Metadata): Promise<UpsertPointRewardResponse>;
  upsertPointReward(request: UpsertPointRewardRequest, callback: (err: any|null, response: UpsertPointRewardResponse, metadata: grpc.Metadata) => void): void;
  upsertPointReward(request: UpsertPointRewardRequest, metadata: grpc.Metadata, callback: (err: any|null, response: UpsertPointRewardResponse, metadata: grpc.Metadata) => void): void;

  upsertPointReward(request: UpsertPointRewardRequest, arg1?: grpc.Metadata|((err: any|null, response: UpsertPointRewardResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: UpsertPointRewardResponse, metadata: grpc.Metadata) => void): Promise<UpsertPointRewardResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<UpsertPointRewardResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__PointsManager.UpsertPointReward, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  deletePointReward(request: DeletePointRewardRequest): Promise<DeletePointRewardResponse>;
  deletePointReward(request: DeletePointRewardRequest, metadata: grpc.Metadata): Promise<DeletePointRewardResponse>;
  deletePointReward(request: DeletePointRewardRequest, callback: (err: any|null, response: DeletePointRewardResponse, metadata: grpc.Metadata) => void): void;
  deletePointReward(request: DeletePointRewardRequest, metadata: grpc.Metadata, callback: (err: any|null, response: DeletePointRewardResponse, metadata: grpc.Metadata) => void): void;

  deletePointReward(request: DeletePointRewardRequest, arg1?: grpc.Metadata|((err: any|null, response: DeletePointRewardResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: DeletePointRewardResponse, metadata: grpc.Metadata) => void): Promise<DeletePointRewardResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<DeletePointRewardResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__PointsManager.DeletePointReward, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  getPointReward(request: GetPointRewardRequest): Promise<GetPointRewardResponse>;
  getPointReward(request: GetPointRewardRequest, metadata: grpc.Metadata): Promise<GetPointRewardResponse>;
  getPointReward(request: GetPointRewardRequest, callback: (err: any|null, response: GetPointRewardResponse, metadata: grpc.Metadata) => void): void;
  getPointReward(request: GetPointRewardRequest, metadata: grpc.Metadata, callback: (err: any|null, response: GetPointRewardResponse, metadata: grpc.Metadata) => void): void;

  getPointReward(request: GetPointRewardRequest, arg1?: grpc.Metadata|((err: any|null, response: GetPointRewardResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: GetPointRewardResponse, metadata: grpc.Metadata) => void): Promise<GetPointRewardResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<GetPointRewardResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__PointsManager.GetPointReward, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  getPointRewards(request: GetPointRewardsRequest): Promise<GetPointRewardsResponse>;
  getPointRewards(request: GetPointRewardsRequest, metadata: grpc.Metadata): Promise<GetPointRewardsResponse>;
  getPointRewards(request: GetPointRewardsRequest, callback: (err: any|null, response: GetPointRewardsResponse, metadata: grpc.Metadata) => void): void;
  getPointRewards(request: GetPointRewardsRequest, metadata: grpc.Metadata, callback: (err: any|null, response: GetPointRewardsResponse, metadata: grpc.Metadata) => void): void;

  getPointRewards(request: GetPointRewardsRequest, arg1?: grpc.Metadata|((err: any|null, response: GetPointRewardsResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: GetPointRewardsResponse, metadata: grpc.Metadata) => void): Promise<GetPointRewardsResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<GetPointRewardsResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__PointsManager.GetPointRewards, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  archivePointAction(request: ArchivePointActionRequest): Promise<ArchivePointActionResponse>;
  archivePointAction(request: ArchivePointActionRequest, metadata: grpc.Metadata): Promise<ArchivePointActionResponse>;
  archivePointAction(request: ArchivePointActionRequest, callback: (err: any|null, response: ArchivePointActionResponse, metadata: grpc.Metadata) => void): void;
  archivePointAction(request: ArchivePointActionRequest, metadata: grpc.Metadata, callback: (err: any|null, response: ArchivePointActionResponse, metadata: grpc.Metadata) => void): void;

  archivePointAction(request: ArchivePointActionRequest, arg1?: grpc.Metadata|((err: any|null, response: ArchivePointActionResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: ArchivePointActionResponse, metadata: grpc.Metadata) => void): Promise<ArchivePointActionResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<ArchivePointActionResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__PointsManager.ArchivePointAction, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  archivePointRedemption(request: ArchivePointRedemptionRequest): Promise<ArchivePointRedemptionResponse>;
  archivePointRedemption(request: ArchivePointRedemptionRequest, metadata: grpc.Metadata): Promise<ArchivePointRedemptionResponse>;
  archivePointRedemption(request: ArchivePointRedemptionRequest, callback: (err: any|null, response: ArchivePointRedemptionResponse, metadata: grpc.Metadata) => void): void;
  archivePointRedemption(request: ArchivePointRedemptionRequest, metadata: grpc.Metadata, callback: (err: any|null, response: ArchivePointRedemptionResponse, metadata: grpc.Metadata) => void): void;

  archivePointRedemption(request: ArchivePointRedemptionRequest, arg1?: grpc.Metadata|((err: any|null, response: ArchivePointRedemptionResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: ArchivePointRedemptionResponse, metadata: grpc.Metadata) => void): Promise<ArchivePointRedemptionResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<ArchivePointRedemptionResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__PointsManager.ArchivePointRedemption, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

}

