// GENERATED CODE -- DO NOT EDIT!

import {grpc} from '@improbable-eng/grpc-web';

import * as gateway_likes_pb from '../gateway/likes_pb';
import * as common_stream_pb from '../common/stream_pb';
import * as gateway_person_view_pb from '../gateway/person_view_pb';

export class LikesManager {
  static readonly serviceName = "minga.likes.LikesManager";

  static readonly StreamLikesPeople = {
    methodName: "StreamLikesPeople",
    service: LikesManager,
    requestStream: false,
    responseStream: true,
    requestType: common_stream_pb.StreamID,
    responseType: gateway_likes_pb.StreamLikesPeopleFeedResponse,
  };

  static readonly StreamLikesPeopleControl = {
    methodName: "StreamLikesPeopleControl",
    service: LikesManager,
    requestStream: false,
    responseStream: false,
    requestType: common_stream_pb.StreamControl,
    responseType: common_stream_pb.StreamControlResponse,
  };

}

export class LikesManagerClient {
  streamLikesPeople() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  streamLikesPeopleControl() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
}
