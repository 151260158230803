import { RosteringMethod } from '../';

export interface sisFields {
  url?: string;
  clientId?: string;
  clientSecret?: string;
  authUrl?: string;
  schoolId?: string;
  updateExisting: boolean;
  archiveMissing: boolean;
  active: boolean;
  studentUrl?: string;
  teacherUrl?: string;
  lastModified?: boolean;
  rosteringMethod: string;
  studentRostering: boolean;
  teacherRostering: boolean;
  staffRostering: boolean;
  adminRostering: boolean;
  parentRostering: boolean;
  classListRostering: boolean;
  sisType?: string;
  accessToken?: string;
  applicationId?: string;
  emailList?: string[];
  notificationEmails: string[];
  idNumberFieldName: string;
  updateExistingStudents: boolean;
  updateExistingStaff: boolean;
  updateExistingTeachers: boolean;
  updateExistingAdmins: boolean;
  archiveMissingStudents: boolean;
  archiveMissingStaff: boolean;
  archiveMissingTeachers: boolean;
  archiveMissingAdmins: boolean;
  termSourcedIds?: string[];
  grades?: string[];
}

export enum SisIdFieldKey {
  IDENTIFIER = 'identifier',
  SOURCE_ID = 'sourcedId',
  USERNAME = 'username',
  SIS_ID = 'sis_id',
  STATE_ID = 'state_id',
}

export const createDefaultSisFields = (): sisFields => {
  return {
    url: '',
    clientId: '',
    clientSecret: '',
    authUrl: '',
    schoolId: '',
    studentUrl: '',
    teacherUrl: '',
    lastModified: false,
    accessToken: '',
    applicationId: '',
    emailList: [],
    sisType: '',
    updateExisting: false,
    archiveMissing: false,
    active: false,
    rosteringMethod: RosteringMethod.ONE_ROSTER,
    idNumberFieldName: SisIdFieldKey.IDENTIFIER,
    studentRostering: false,
    teacherRostering: false,
    staffRostering: false,
    adminRostering: false,
    parentRostering: false,
    classListRostering: false,
    notificationEmails: [],
    updateExistingStudents: false,
    updateExistingStaff: false,
    updateExistingTeachers: false,
    updateExistingAdmins: false,
    archiveMissingStudents: false,
    archiveMissingStaff: false,
    archiveMissingTeachers: false,
    archiveMissingAdmins: false,
  };
};
