import { Injector, NgModule } from '@angular/core';

import { MgModalModule } from '@app/src/app/minimal/services/MgModal';

import { MingaManagerService } from './MingaManager.service';

@NgModule({
  imports: [MgModalModule],
  providers: [MingaManagerService],
})
export class MingaManagerServiceModule {}
