import { Action } from '@ngrx/store';
import { gateway } from 'libs/generated-grpc-web';

import { Group } from '../models/Group';
import { GroupDetails } from '../models/GroupDetails';

export namespace GroupFormActions {
  export enum TypeEnum {
    CreateGroup = '[Group] Create Group',
    CreateGroupFailure = '[Group] Create Group Failure',
    CreateGroupSuccess = '[Group] Create Group Success',

    UpdateGroup = '[Group] Update Group',
    UpdateGroupFailure = '[Group] Update Group Failure',
    UpdateGroupSuccess = '[Group] Update Group Success',
  }

  export interface GroupFormAction extends Action {
    readonly type: TypeEnum;
  }

  export class CreateGroupAction implements GroupFormAction {
    readonly type = TypeEnum.CreateGroup;
    constructor(public payload: gateway.mingaGroup_pb.GroupInputs) {}
  }

  export class CreateGroupFailure implements GroupFormAction {
    readonly type = TypeEnum.CreateGroupFailure;
    constructor(public error: Error) {}
  }

  export class CreateGroupSuccess implements GroupFormAction {
    readonly type = TypeEnum.CreateGroupSuccess;
    constructor(public group: Group, public groupDetails: GroupDetails) {}
  }

  export class UpdateGroupAction implements GroupFormAction {
    readonly type = TypeEnum.UpdateGroup;
    constructor(
      public payload: {
        groupHash: string;
        groupInputs: gateway.mingaGroup_pb.GroupInputs;
      },
    ) {}
  }

  export class UpdateGroupFailure implements GroupFormAction {
    readonly type = TypeEnum.UpdateGroupFailure;
    constructor(public error: Error) {}
  }

  export class UpdateGroupSuccess implements GroupFormAction {
    readonly type = TypeEnum.UpdateGroupSuccess;
    constructor(public group: Group, public groupDetails: GroupDetails) {}
  }

  export type TypeUnion =
    | CreateGroupAction
    | CreateGroupFailure
    | CreateGroupSuccess
    | UpdateGroupAction
    | UpdateGroupFailure
    | UpdateGroupSuccess;
}
