import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  HostBinding,
  Input,
} from '@angular/core';

import { IPointsHistoryItem, PointHistoryType } from 'libs/domain';

@Component({
  selector: 'mg-points-history-icon',
  templateUrl: './PointsHistoryIcon.component.html',
  styleUrls: ['./PointsHistoryIcon.component.scss'],
})
export class PointsHistoryIconComponent {
  @Input()
  type?: PointHistoryType;

  @HostBinding('class.disablebg')
  @Input()
  disableBackground: boolean = false;

  getTypeClass() {
    if (!this.type) return PointHistoryType.CUSTOM;
    switch (this.type) {
      case PointHistoryType.EVENT_CHECKIN:
        return 'event';
      case PointHistoryType.CUSTOM:
        return 'custom';
      case PointHistoryType.TEXT_CHALLENGE_RESPONSE:
        return 'text-challenge';
      case PointHistoryType.POLL_RESPONSE:
        return 'poll';
      case PointHistoryType.IMAGE_CHALLENGE_RESPONSE:
        return 'image-challenge';
      case PointHistoryType.PRAISE:
        return 'praise';
      case PointHistoryType.GUIDANCE:
        return 'guidance';
      case PointHistoryType.CONSEQUENCE:
        return 'consequence';
      case PointHistoryType.CHECKIN:
        return 'checkin';
    }
  }
}
