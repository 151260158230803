import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

import { MgIconModule } from '@app/src/app/icon';

import { GenericModule } from '@shared/components/generic';
import { TextModule } from '@shared/components/text';

import { MgImageModule } from '../MgImage';
import { EmptyStateComponent } from './EmptyState.component';

@NgModule({
  imports: [
    // Minga dependencies
    MgIconModule,
    GenericModule,
    TextModule,
    MgImageModule,

    // Exertnal dependencies
    CommonModule,
    MatIconModule,
  ],
  declarations: [EmptyStateComponent],
  exports: [EmptyStateComponent],
})
export class MgEmptyStateModule {}
