import { SisSyncLogErrorType } from 'libs/domain';

export const SisSyncErrorDescription: Record<
  SisSyncLogErrorType,
  {
    title: string;
    description: string;
    overrideKey: boolean;
    successMsg: string;
  }
> = {
  [SisSyncLogErrorType.MASS_DELETE]: {
    title: 'SIS roster sync alert',
    description:
      'More than 50% of users are about to be deleted during this sync. Would you like to proceed or skip this sync until the next scheduled sync?',
    overrideKey: true,
    successMsg: 'Queued sync, please wait for deletion.',
  },
};
