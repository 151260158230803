import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { gateway } from 'libs/generated-grpc-web';

@Component({
  selector: 'mg-challenge-invite-notification',
  templateUrl: './MgChallengeInviteNotification.element.html',
  styleUrls: ['./MgChallengeInviteNotification.element.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MgChallengeInviteNotificationElement {
  @Input()
  notification?: gateway.notification_pb.Notification.AsObject;
}
