import { IAuthPerson } from 'libs/domain';
import { PersonViewMinimal } from 'libs/generated-grpc-ts';

export namespace IAuthPersonMapper {
  export function fromPersonMinimalProto(
    personView: PersonViewMinimal,
  ): IAuthPerson {
    const badgeIconUrl = personView.getBadgeIconUrl();
    const badgeIconColor = personView.getBadgeIconColor();
    const badgeRoleName = personView.getBadgeRoleName();
    const firstName = personView.getFirstName();
    const lastName = personView.getLastName();
    const displayName = personView.getDisplayName();
    const hash = personView.getPersonHash();
    const avatarUrl = personView.getProfileImageUrl();
    const studentId = personView.getStudentId();
    const grade = personView.getGrade();
    const filename = personView.getFilename();
    const role = personView.getRole();

    return {
      badgeIconColor,
      badgeIconUrl,
      badgeRoleName,
      firstName,
      lastName,
      displayName,
      hash,
      avatarUrl,
      studentId,
      grade,
      filename,
      role,
    };
  }

  export function fromPersonMinimalAsObject(
    person: PersonViewMinimal.AsObject,
  ): IAuthPerson {
    return {
      displayName: person.displayName,
      avatarUrl: person.profileImageUrl,
      hash: person.personHash,
      badgeIconColor: person.badgeIconColor,
      badgeIconUrl: person.badgeIconUrl,
      badgeRoleName: person.badgeRoleName,
      firstName: person.firstName,
      lastName: person.lastName,
      filename: person.filename,
      role: person.role,
    };
  }
}
