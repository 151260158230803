// source: content/challenge/challenge.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

import * as jspb from 'google-protobuf';
import * as content_common_pb from '../../content/common_pb';
import * as common_date_pb from '../../common/date_pb';
import * as content_video_upload_video_upload_pb from '../../content/video_upload/video_upload_pb';
import * as common_delta_pb from '../../common/delta_pb';
import * as gateway_person_view_pb from '../../gateway/person_view_pb';
export namespace ChallengeInfo {
  export interface AsObject {
    contentHash: string;
    title: string;
    contextHash: string;
    body: string;
    dueDate?: common_date_pb.DateTime.AsObject;
    commentsAllowed: boolean;
    bannerHash: string;
    bannerAsset: string;
    type: string;
    privacy: string;
    video?: content_video_upload_video_upload_pb.VideoUploadInfo.AsObject;
    color: string;
    pointValue: number;
    badgeHash: string;
    badgeAsset: string;
    allowedRolesList: string[];
    invitePeopleCount: number;
    inviteResponseCount: number;
    requiresApproval: boolean;
  }
}
export class ChallengeInfo extends jspb.Message {
  static readonly displayName = "ChallengeInfo";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [16];

  /**
   * Oneof group definitions for this message. Each group defines the field
   * numbers belonging to that group. When of these fields' value is set, all
   * other fields in the group are cleared. During deserialization, if multiple
   * fields are encountered for a group, only the last value seen will be kept.
   * @private {!Array<!Array<number>>}
   * @const
   */
  static readonly oneofGroups_ = [[7,8],[14,15]];


  /**
   * @return {ChallengeInfo.BannerCase}
   */
  getBannerCase() {
    return /** @type {ChallengeInfo.BannerCase} */(jspb.Message.computeOneofCase(this, ChallengeInfo.oneofGroups_[0]));
  }


  /**
   * @return {ChallengeInfo.BadgeCase}
   */
  getBadgeCase() {
    return /** @type {ChallengeInfo.BadgeCase} */(jspb.Message.computeOneofCase(this, ChallengeInfo.oneofGroups_[1]));
  }

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, ChallengeInfo.repeatedFields_, ChallengeInfo.oneofGroups_);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): ChallengeInfo.AsObject {
    return ChallengeInfo.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!ChallengeInfo} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: ChallengeInfo) {
    var f: any;
    var obj: any = {
      contentHash: jspb.Message.getFieldWithDefault(msg, 1, ""),
      title: jspb.Message.getFieldWithDefault(msg, 2, ""),
      contextHash: jspb.Message.getFieldWithDefault(msg, 3, ""),
      body: jspb.Message.getFieldWithDefault(msg, 4, ""),
      dueDate: (f = msg.getDueDate()) && common_date_pb.DateTime.toObject(includeInstance, f),
      commentsAllowed: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
      bannerHash: jspb.Message.getFieldWithDefault(msg, 7, ""),
      bannerAsset: jspb.Message.getFieldWithDefault(msg, 8, ""),
      type: jspb.Message.getFieldWithDefault(msg, 9, ""),
      privacy: jspb.Message.getFieldWithDefault(msg, 10, ""),
      video: (f = msg.getVideo()) && content_video_upload_video_upload_pb.VideoUploadInfo.toObject(includeInstance, f),
      color: jspb.Message.getFieldWithDefault(msg, 12, ""),
      pointValue: jspb.Message.getFieldWithDefault(msg, 13, 0),
      badgeHash: jspb.Message.getFieldWithDefault(msg, 14, ""),
      badgeAsset: jspb.Message.getFieldWithDefault(msg, 15, ""),
      allowedRolesList: (f = jspb.Message.getRepeatedField(msg, 16)) == null ? undefined : f,
      invitePeopleCount: jspb.Message.getFieldWithDefault(msg, 17, 0),
      inviteResponseCount: jspb.Message.getFieldWithDefault(msg, 18, 0),
      requiresApproval: jspb.Message.getBooleanFieldWithDefault(msg, 19, false)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!ChallengeInfo}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new ChallengeInfo;
    return ChallengeInfo.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!ChallengeInfo} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!ChallengeInfo}
   */
  static deserializeBinaryFromReader(msg: ChallengeInfo, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setContentHash(value1);
        break;
      case 2:
        var value2 = /** @type {string} */ (reader.readString());
        msg.setTitle(value2);
        break;
      case 3:
        var value3 = /** @type {string} */ (reader.readString());
        msg.setContextHash(value3);
        break;
      case 4:
        var value4 = /** @type {string} */ (reader.readString());
        msg.setBody(value4);
        break;
      case 5:
        var value5 = new common_date_pb.DateTime;
        reader.readMessage(value5,common_date_pb.DateTime.deserializeBinaryFromReader);
        msg.setDueDate(value5);
        break;
      case 6:
        var value6 = /** @type {boolean} */ (reader.readBool());
        msg.setCommentsAllowed(value6);
        break;
      case 7:
        var value7 = /** @type {string} */ (reader.readString());
        msg.setBannerHash(value7);
        break;
      case 8:
        var value8 = /** @type {string} */ (reader.readString());
        msg.setBannerAsset(value8);
        break;
      case 9:
        var value9 = /** @type {string} */ (reader.readString());
        msg.setType(value9);
        break;
      case 10:
        var value10 = /** @type {string} */ (reader.readString());
        msg.setPrivacy(value10);
        break;
      case 11:
        var value11 = new content_video_upload_video_upload_pb.VideoUploadInfo;
        reader.readMessage(value11,content_video_upload_video_upload_pb.VideoUploadInfo.deserializeBinaryFromReader);
        msg.setVideo(value11);
        break;
      case 12:
        var value12 = /** @type {string} */ (reader.readString());
        msg.setColor(value12);
        break;
      case 13:
        var value13 = /** @type {number} */ (reader.readInt32());
        msg.setPointValue(value13);
        break;
      case 14:
        var value14 = /** @type {string} */ (reader.readString());
        msg.setBadgeHash(value14);
        break;
      case 15:
        var value15 = /** @type {string} */ (reader.readString());
        msg.setBadgeAsset(value15);
        break;
      case 16:
        var value16 = /** @type {string} */ (reader.readString());
        msg.addAllowedRoles(value16);
        break;
      case 17:
        var value17 = /** @type {number} */ (reader.readInt32());
        msg.setInvitePeopleCount(value17);
        break;
      case 18:
        var value18 = /** @type {number} */ (reader.readInt32());
        msg.setInviteResponseCount(value18);
        break;
      case 19:
        var value19 = /** @type {boolean} */ (reader.readBool());
        msg.setRequiresApproval(value19);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!ChallengeInfo} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: ChallengeInfo, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getContentHash();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
    f = message.getTitle();
    if (f.length > 0) {
      writer.writeString(
        2,
        f
      );
    }
    f = message.getContextHash();
    if (f.length > 0) {
      writer.writeString(
        3,
        f
      );
    }
    f = message.getBody();
    if (f.length > 0) {
      writer.writeString(
        4,
        f
      );
    }
    f = message.getDueDate();
    if (f != null) {
      writer.writeMessage(
        5,
        f,
        common_date_pb.DateTime.serializeBinaryToWriter
      );
    }
    f = message.getCommentsAllowed();
    if (f) {
      writer.writeBool(
        6,
        f
      );
    }
    f = /** @type {string} */ (jspb.Message.getField(message, 7));
    if (f != null) {
      writer.writeString(
        7,
        f
      );
    }
    f = /** @type {string} */ (jspb.Message.getField(message, 8));
    if (f != null) {
      writer.writeString(
        8,
        f
      );
    }
    f = message.getType();
    if (f.length > 0) {
      writer.writeString(
        9,
        f
      );
    }
    f = message.getPrivacy();
    if (f.length > 0) {
      writer.writeString(
        10,
        f
      );
    }
    f = message.getVideo();
    if (f != null) {
      writer.writeMessage(
        11,
        f,
        content_video_upload_video_upload_pb.VideoUploadInfo.serializeBinaryToWriter
      );
    }
    f = message.getColor();
    if (f.length > 0) {
      writer.writeString(
        12,
        f
      );
    }
    f = message.getPointValue();
    if (f !== 0) {
      writer.writeInt32(
        13,
        f
      );
    }
    f = /** @type {string} */ (jspb.Message.getField(message, 14));
    if (f != null) {
      writer.writeString(
        14,
        f
      );
    }
    f = /** @type {string} */ (jspb.Message.getField(message, 15));
    if (f != null) {
      writer.writeString(
        15,
        f
      );
    }
    f = message.getAllowedRolesList();
    if (f.length > 0) {
      writer.writeRepeatedString(
        16,
        f
      );
    }
    f = message.getInvitePeopleCount();
    if (f !== 0) {
      writer.writeInt32(
        17,
        f
      );
    }
    f = message.getInviteResponseCount();
    if (f !== 0) {
      writer.writeInt32(
        18,
        f
      );
    }
    f = message.getRequiresApproval();
    if (f) {
      writer.writeBool(
        19,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    ChallengeInfo.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string content_hash = 1;
   * @return {string}
   */
  getContentHash(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setContentHash(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


  /**
   * optional string title = 2;
   * @return {string}
   */
  getTitle(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
  };


  /** @param {string} value */
  setTitle(value: string) {
    jspb.Message.setProto3StringField(this, 2, value);
  }


  /**
   * optional string context_hash = 3;
   * @return {string}
   */
  getContextHash(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
  };


  /** @param {string} value */
  setContextHash(value: string) {
    jspb.Message.setProto3StringField(this, 3, value);
  }


  /**
   * optional string body = 4;
   * @return {string}
   */
  getBody(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
  };


  /** @param {string} value */
  setBody(value: string) {
    jspb.Message.setProto3StringField(this, 4, value);
  }


  /**
   * optional minga.common.DateTime due_date = 5;
   * @return {?DateTime}
   */
  getDueDate(): common_date_pb.DateTime {
    return /** @type{?DateTime} */ (
      jspb.Message.getWrapperField(this, common_date_pb.DateTime, 5));
  }


  /** @param {?DateTime|undefined} value */
  setDueDate(value?: common_date_pb.DateTime) {
    jspb.Message.setWrapperField(this, 5, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearDueDate() {
    this.setDueDate(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasDueDate(): boolean {
    return jspb.Message.getField(this, 5) != null;
  }


  /**
   * optional bool comments_allowed = 6;
   * @return {boolean}
   */
  getCommentsAllowed(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
  };


  /** @param {boolean} value */
  setCommentsAllowed(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 6, value);
  }


  /**
   * optional string banner_hash = 7;
   * @return {string}
   */
  getBannerHash(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
  };


  /** @param {string} value */
  setBannerHash(value: string) {
    jspb.Message.setOneofField(this, 7, ChallengeInfo.oneofGroups_[0], value);
  }


  /**
   * Clears the field making it undefined.
   */
  clearBannerHash() {
    jspb.Message.setOneofField(this, 7, ChallengeInfo.oneofGroups_[0], undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasBannerHash(): boolean {
    return jspb.Message.getField(this, 7) != null;
  }


  /**
   * optional string banner_asset = 8;
   * @return {string}
   */
  getBannerAsset(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
  };


  /** @param {string} value */
  setBannerAsset(value: string) {
    jspb.Message.setOneofField(this, 8, ChallengeInfo.oneofGroups_[0], value);
  }


  /**
   * Clears the field making it undefined.
   */
  clearBannerAsset() {
    jspb.Message.setOneofField(this, 8, ChallengeInfo.oneofGroups_[0], undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasBannerAsset(): boolean {
    return jspb.Message.getField(this, 8) != null;
  }


  /**
   * optional string type = 9;
   * @return {string}
   */
  getType(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
  };


  /** @param {string} value */
  setType(value: string) {
    jspb.Message.setProto3StringField(this, 9, value);
  }


  /**
   * optional string privacy = 10;
   * @return {string}
   */
  getPrivacy(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
  };


  /** @param {string} value */
  setPrivacy(value: string) {
    jspb.Message.setProto3StringField(this, 10, value);
  }


  /**
   * optional VideoUploadInfo video = 11;
   * @return {?VideoUploadInfo}
   */
  getVideo(): content_video_upload_video_upload_pb.VideoUploadInfo {
    return /** @type{?VideoUploadInfo} */ (
      jspb.Message.getWrapperField(this, content_video_upload_video_upload_pb.VideoUploadInfo, 11));
  }


  /** @param {?VideoUploadInfo|undefined} value */
  setVideo(value?: content_video_upload_video_upload_pb.VideoUploadInfo) {
    jspb.Message.setWrapperField(this, 11, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearVideo() {
    this.setVideo(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasVideo(): boolean {
    return jspb.Message.getField(this, 11) != null;
  }


  /**
   * optional string color = 12;
   * @return {string}
   */
  getColor(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
  };


  /** @param {string} value */
  setColor(value: string) {
    jspb.Message.setProto3StringField(this, 12, value);
  }


  /**
   * optional int32 point_value = 13;
   * @return {number}
   */
  getPointValue(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
  };


  /** @param {number} value */
  setPointValue(value: number) {
    jspb.Message.setProto3IntField(this, 13, value);
  }


  /**
   * optional string badge_hash = 14;
   * @return {string}
   */
  getBadgeHash(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
  };


  /** @param {string} value */
  setBadgeHash(value: string) {
    jspb.Message.setOneofField(this, 14, ChallengeInfo.oneofGroups_[1], value);
  }


  /**
   * Clears the field making it undefined.
   */
  clearBadgeHash() {
    jspb.Message.setOneofField(this, 14, ChallengeInfo.oneofGroups_[1], undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasBadgeHash(): boolean {
    return jspb.Message.getField(this, 14) != null;
  }


  /**
   * optional string badge_asset = 15;
   * @return {string}
   */
  getBadgeAsset(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
  };


  /** @param {string} value */
  setBadgeAsset(value: string) {
    jspb.Message.setOneofField(this, 15, ChallengeInfo.oneofGroups_[1], value);
  }


  /**
   * Clears the field making it undefined.
   */
  clearBadgeAsset() {
    jspb.Message.setOneofField(this, 15, ChallengeInfo.oneofGroups_[1], undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasBadgeAsset(): boolean {
    return jspb.Message.getField(this, 15) != null;
  }


  /**
   * repeated string allowed_roles = 16;
   * @return {!Array<string>}
   */
  getAllowedRolesList(): string[] {
    return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 16));
  };


  /** @param {!Array<string>} value */
  setAllowedRolesList(value: string[]) {
    jspb.Message.setField(this, 16, value || []);
  }


  /**
   * @param {string} value
   * @param {number=} opt_index
   */
  addAllowedRoles(value: string, opt_index?: number): void {
    jspb.Message.addToRepeatedField(this, 16, value, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearAllowedRolesList() {
    this.setAllowedRolesList([]);
  }


  /**
   * optional int32 invite_people_count = 17;
   * @return {number}
   */
  getInvitePeopleCount(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 17, 0));
  };


  /** @param {number} value */
  setInvitePeopleCount(value: number) {
    jspb.Message.setProto3IntField(this, 17, value);
  }


  /**
   * optional int32 invite_response_count = 18;
   * @return {number}
   */
  getInviteResponseCount(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 18, 0));
  };


  /** @param {number} value */
  setInviteResponseCount(value: number) {
    jspb.Message.setProto3IntField(this, 18, value);
  }


  /**
   * optional bool requires_approval = 19;
   * @return {boolean}
   */
  getRequiresApproval(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 19, false));
  };


  /** @param {boolean} value */
  setRequiresApproval(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 19, value);
  }


}
export namespace ChallengeInfo {
export enum BannerCase {
  BANNER_NOT_SET = 0,
  BANNER_HASH = 7,
  BANNER_ASSET = 8,
}
} // namespace ChallengeInfo
export namespace ChallengeInfo {
export enum BadgeCase {
  BADGE_NOT_SET = 0,
  BADGE_HASH = 14,
  BADGE_ASSET = 15,
}
} // namespace ChallengeInfo
export namespace UpdateChallengeInfo {
  export interface AsObject {
    contentHash: string;
    title?: common_delta_pb.StringDelta.AsObject;
    body?: common_delta_pb.StringDelta.AsObject;
    dueDate?: common_delta_pb.DateTimeDelta.AsObject;
    commentsAllowed?: common_delta_pb.BoolDelta.AsObject;
    bannerHash?: common_delta_pb.StringDelta.AsObject;
    bannerAsset?: common_delta_pb.StringDelta.AsObject;
    type?: common_delta_pb.StringDelta.AsObject;
    privacy?: common_delta_pb.StringDelta.AsObject;
    video?: content_video_upload_video_upload_pb.DeltaVideoUploadInfo.AsObject;
    color?: common_delta_pb.StringDelta.AsObject;
    pointValue: number;
    allowedRolesList: string[];
    requiresApproval: boolean;
  }
}
export class UpdateChallengeInfo extends jspb.Message {
  static readonly displayName = "UpdateChallengeInfo";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [17];

  /**
   * Oneof group definitions for this message. Each group defines the field
   * numbers belonging to that group. When of these fields' value is set, all
   * other fields in the group are cleared. During deserialization, if multiple
   * fields are encountered for a group, only the last value seen will be kept.
   * @private {!Array<!Array<number>>}
   * @const
   */
  static readonly oneofGroups_ = [[6,7]];


  /**
   * @return {UpdateChallengeInfo.BannerCase}
   */
  getBannerCase() {
    return /** @type {UpdateChallengeInfo.BannerCase} */(jspb.Message.computeOneofCase(this, UpdateChallengeInfo.oneofGroups_[0]));
  }

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, UpdateChallengeInfo.repeatedFields_, UpdateChallengeInfo.oneofGroups_);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): UpdateChallengeInfo.AsObject {
    return UpdateChallengeInfo.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!UpdateChallengeInfo} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: UpdateChallengeInfo) {
    var f: any;
    var obj: any = {
      contentHash: jspb.Message.getFieldWithDefault(msg, 1, ""),
      title: (f = msg.getTitle()) && common_delta_pb.StringDelta.toObject(includeInstance, f),
      body: (f = msg.getBody()) && common_delta_pb.StringDelta.toObject(includeInstance, f),
      dueDate: (f = msg.getDueDate()) && common_delta_pb.DateTimeDelta.toObject(includeInstance, f),
      commentsAllowed: (f = msg.getCommentsAllowed()) && common_delta_pb.BoolDelta.toObject(includeInstance, f),
      bannerHash: (f = msg.getBannerHash()) && common_delta_pb.StringDelta.toObject(includeInstance, f),
      bannerAsset: (f = msg.getBannerAsset()) && common_delta_pb.StringDelta.toObject(includeInstance, f),
      type: (f = msg.getType()) && common_delta_pb.StringDelta.toObject(includeInstance, f),
      privacy: (f = msg.getPrivacy()) && common_delta_pb.StringDelta.toObject(includeInstance, f),
      video: (f = msg.getVideo()) && content_video_upload_video_upload_pb.DeltaVideoUploadInfo.toObject(includeInstance, f),
      color: (f = msg.getColor()) && common_delta_pb.StringDelta.toObject(includeInstance, f),
      pointValue: jspb.Message.getFieldWithDefault(msg, 12, 0),
      allowedRolesList: (f = jspb.Message.getRepeatedField(msg, 17)) == null ? undefined : f,
      requiresApproval: jspb.Message.getBooleanFieldWithDefault(msg, 18, false)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!UpdateChallengeInfo}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new UpdateChallengeInfo;
    return UpdateChallengeInfo.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!UpdateChallengeInfo} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!UpdateChallengeInfo}
   */
  static deserializeBinaryFromReader(msg: UpdateChallengeInfo, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setContentHash(value1);
        break;
      case 2:
        var value2 = new common_delta_pb.StringDelta;
        reader.readMessage(value2,common_delta_pb.StringDelta.deserializeBinaryFromReader);
        msg.setTitle(value2);
        break;
      case 3:
        var value3 = new common_delta_pb.StringDelta;
        reader.readMessage(value3,common_delta_pb.StringDelta.deserializeBinaryFromReader);
        msg.setBody(value3);
        break;
      case 4:
        var value4 = new common_delta_pb.DateTimeDelta;
        reader.readMessage(value4,common_delta_pb.DateTimeDelta.deserializeBinaryFromReader);
        msg.setDueDate(value4);
        break;
      case 5:
        var value5 = new common_delta_pb.BoolDelta;
        reader.readMessage(value5,common_delta_pb.BoolDelta.deserializeBinaryFromReader);
        msg.setCommentsAllowed(value5);
        break;
      case 6:
        var value6 = new common_delta_pb.StringDelta;
        reader.readMessage(value6,common_delta_pb.StringDelta.deserializeBinaryFromReader);
        msg.setBannerHash(value6);
        break;
      case 7:
        var value7 = new common_delta_pb.StringDelta;
        reader.readMessage(value7,common_delta_pb.StringDelta.deserializeBinaryFromReader);
        msg.setBannerAsset(value7);
        break;
      case 8:
        var value8 = new common_delta_pb.StringDelta;
        reader.readMessage(value8,common_delta_pb.StringDelta.deserializeBinaryFromReader);
        msg.setType(value8);
        break;
      case 9:
        var value9 = new common_delta_pb.StringDelta;
        reader.readMessage(value9,common_delta_pb.StringDelta.deserializeBinaryFromReader);
        msg.setPrivacy(value9);
        break;
      case 10:
        var value10 = new content_video_upload_video_upload_pb.DeltaVideoUploadInfo;
        reader.readMessage(value10,content_video_upload_video_upload_pb.DeltaVideoUploadInfo.deserializeBinaryFromReader);
        msg.setVideo(value10);
        break;
      case 11:
        var value11 = new common_delta_pb.StringDelta;
        reader.readMessage(value11,common_delta_pb.StringDelta.deserializeBinaryFromReader);
        msg.setColor(value11);
        break;
      case 12:
        var value12 = /** @type {number} */ (reader.readInt32());
        msg.setPointValue(value12);
        break;
      case 17:
        var value17 = /** @type {string} */ (reader.readString());
        msg.addAllowedRoles(value17);
        break;
      case 18:
        var value18 = /** @type {boolean} */ (reader.readBool());
        msg.setRequiresApproval(value18);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!UpdateChallengeInfo} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: UpdateChallengeInfo, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getContentHash();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
    f = message.getTitle();
    if (f != null) {
      writer.writeMessage(
        2,
        f,
        common_delta_pb.StringDelta.serializeBinaryToWriter
      );
    }
    f = message.getBody();
    if (f != null) {
      writer.writeMessage(
        3,
        f,
        common_delta_pb.StringDelta.serializeBinaryToWriter
      );
    }
    f = message.getDueDate();
    if (f != null) {
      writer.writeMessage(
        4,
        f,
        common_delta_pb.DateTimeDelta.serializeBinaryToWriter
      );
    }
    f = message.getCommentsAllowed();
    if (f != null) {
      writer.writeMessage(
        5,
        f,
        common_delta_pb.BoolDelta.serializeBinaryToWriter
      );
    }
    f = message.getBannerHash();
    if (f != null) {
      writer.writeMessage(
        6,
        f,
        common_delta_pb.StringDelta.serializeBinaryToWriter
      );
    }
    f = message.getBannerAsset();
    if (f != null) {
      writer.writeMessage(
        7,
        f,
        common_delta_pb.StringDelta.serializeBinaryToWriter
      );
    }
    f = message.getType();
    if (f != null) {
      writer.writeMessage(
        8,
        f,
        common_delta_pb.StringDelta.serializeBinaryToWriter
      );
    }
    f = message.getPrivacy();
    if (f != null) {
      writer.writeMessage(
        9,
        f,
        common_delta_pb.StringDelta.serializeBinaryToWriter
      );
    }
    f = message.getVideo();
    if (f != null) {
      writer.writeMessage(
        10,
        f,
        content_video_upload_video_upload_pb.DeltaVideoUploadInfo.serializeBinaryToWriter
      );
    }
    f = message.getColor();
    if (f != null) {
      writer.writeMessage(
        11,
        f,
        common_delta_pb.StringDelta.serializeBinaryToWriter
      );
    }
    f = message.getPointValue();
    if (f !== 0) {
      writer.writeInt32(
        12,
        f
      );
    }
    f = message.getAllowedRolesList();
    if (f.length > 0) {
      writer.writeRepeatedString(
        17,
        f
      );
    }
    f = message.getRequiresApproval();
    if (f) {
      writer.writeBool(
        18,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    UpdateChallengeInfo.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string content_hash = 1;
   * @return {string}
   */
  getContentHash(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setContentHash(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


  /**
   * optional minga.common.StringDelta title = 2;
   * @return {?StringDelta}
   */
  getTitle(): common_delta_pb.StringDelta {
    return /** @type{?StringDelta} */ (
      jspb.Message.getWrapperField(this, common_delta_pb.StringDelta, 2));
  }


  /** @param {?StringDelta|undefined} value */
  setTitle(value?: common_delta_pb.StringDelta) {
    jspb.Message.setWrapperField(this, 2, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearTitle() {
    this.setTitle(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasTitle(): boolean {
    return jspb.Message.getField(this, 2) != null;
  }


  /**
   * optional minga.common.StringDelta body = 3;
   * @return {?StringDelta}
   */
  getBody(): common_delta_pb.StringDelta {
    return /** @type{?StringDelta} */ (
      jspb.Message.getWrapperField(this, common_delta_pb.StringDelta, 3));
  }


  /** @param {?StringDelta|undefined} value */
  setBody(value?: common_delta_pb.StringDelta) {
    jspb.Message.setWrapperField(this, 3, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearBody() {
    this.setBody(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasBody(): boolean {
    return jspb.Message.getField(this, 3) != null;
  }


  /**
   * optional minga.common.DateTimeDelta due_date = 4;
   * @return {?DateTimeDelta}
   */
  getDueDate(): common_delta_pb.DateTimeDelta {
    return /** @type{?DateTimeDelta} */ (
      jspb.Message.getWrapperField(this, common_delta_pb.DateTimeDelta, 4));
  }


  /** @param {?DateTimeDelta|undefined} value */
  setDueDate(value?: common_delta_pb.DateTimeDelta) {
    jspb.Message.setWrapperField(this, 4, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearDueDate() {
    this.setDueDate(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasDueDate(): boolean {
    return jspb.Message.getField(this, 4) != null;
  }


  /**
   * optional minga.common.BoolDelta comments_allowed = 5;
   * @return {?BoolDelta}
   */
  getCommentsAllowed(): common_delta_pb.BoolDelta {
    return /** @type{?BoolDelta} */ (
      jspb.Message.getWrapperField(this, common_delta_pb.BoolDelta, 5));
  }


  /** @param {?BoolDelta|undefined} value */
  setCommentsAllowed(value?: common_delta_pb.BoolDelta) {
    jspb.Message.setWrapperField(this, 5, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearCommentsAllowed() {
    this.setCommentsAllowed(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasCommentsAllowed(): boolean {
    return jspb.Message.getField(this, 5) != null;
  }


  /**
   * optional minga.common.StringDelta banner_hash = 6;
   * @return {?StringDelta}
   */
  getBannerHash(): common_delta_pb.StringDelta {
    return /** @type{?StringDelta} */ (
      jspb.Message.getWrapperField(this, common_delta_pb.StringDelta, 6));
  }


  /** @param {?StringDelta|undefined} value */
  setBannerHash(value?: common_delta_pb.StringDelta) {
    jspb.Message.setOneofWrapperField(this, 6, UpdateChallengeInfo.oneofGroups_[0], value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearBannerHash() {
    this.setBannerHash(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasBannerHash(): boolean {
    return jspb.Message.getField(this, 6) != null;
  }


  /**
   * optional minga.common.StringDelta banner_asset = 7;
   * @return {?StringDelta}
   */
  getBannerAsset(): common_delta_pb.StringDelta {
    return /** @type{?StringDelta} */ (
      jspb.Message.getWrapperField(this, common_delta_pb.StringDelta, 7));
  }


  /** @param {?StringDelta|undefined} value */
  setBannerAsset(value?: common_delta_pb.StringDelta) {
    jspb.Message.setOneofWrapperField(this, 7, UpdateChallengeInfo.oneofGroups_[0], value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearBannerAsset() {
    this.setBannerAsset(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasBannerAsset(): boolean {
    return jspb.Message.getField(this, 7) != null;
  }


  /**
   * optional minga.common.StringDelta type = 8;
   * @return {?StringDelta}
   */
  getType(): common_delta_pb.StringDelta {
    return /** @type{?StringDelta} */ (
      jspb.Message.getWrapperField(this, common_delta_pb.StringDelta, 8));
  }


  /** @param {?StringDelta|undefined} value */
  setType(value?: common_delta_pb.StringDelta) {
    jspb.Message.setWrapperField(this, 8, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearType() {
    this.setType(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasType(): boolean {
    return jspb.Message.getField(this, 8) != null;
  }


  /**
   * optional minga.common.StringDelta privacy = 9;
   * @return {?StringDelta}
   */
  getPrivacy(): common_delta_pb.StringDelta {
    return /** @type{?StringDelta} */ (
      jspb.Message.getWrapperField(this, common_delta_pb.StringDelta, 9));
  }


  /** @param {?StringDelta|undefined} value */
  setPrivacy(value?: common_delta_pb.StringDelta) {
    jspb.Message.setWrapperField(this, 9, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearPrivacy() {
    this.setPrivacy(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasPrivacy(): boolean {
    return jspb.Message.getField(this, 9) != null;
  }


  /**
   * optional DeltaVideoUploadInfo video = 10;
   * @return {?DeltaVideoUploadInfo}
   */
  getVideo(): content_video_upload_video_upload_pb.DeltaVideoUploadInfo {
    return /** @type{?DeltaVideoUploadInfo} */ (
      jspb.Message.getWrapperField(this, content_video_upload_video_upload_pb.DeltaVideoUploadInfo, 10));
  }


  /** @param {?DeltaVideoUploadInfo|undefined} value */
  setVideo(value?: content_video_upload_video_upload_pb.DeltaVideoUploadInfo) {
    jspb.Message.setWrapperField(this, 10, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearVideo() {
    this.setVideo(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasVideo(): boolean {
    return jspb.Message.getField(this, 10) != null;
  }


  /**
   * optional minga.common.StringDelta color = 11;
   * @return {?StringDelta}
   */
  getColor(): common_delta_pb.StringDelta {
    return /** @type{?StringDelta} */ (
      jspb.Message.getWrapperField(this, common_delta_pb.StringDelta, 11));
  }


  /** @param {?StringDelta|undefined} value */
  setColor(value?: common_delta_pb.StringDelta) {
    jspb.Message.setWrapperField(this, 11, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearColor() {
    this.setColor(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasColor(): boolean {
    return jspb.Message.getField(this, 11) != null;
  }


  /**
   * optional int32 point_value = 12;
   * @return {number}
   */
  getPointValue(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
  };


  /** @param {number} value */
  setPointValue(value: number) {
    jspb.Message.setProto3IntField(this, 12, value);
  }


  /**
   * repeated string allowed_roles = 17;
   * @return {!Array<string>}
   */
  getAllowedRolesList(): string[] {
    return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 17));
  };


  /** @param {!Array<string>} value */
  setAllowedRolesList(value: string[]) {
    jspb.Message.setField(this, 17, value || []);
  }


  /**
   * @param {string} value
   * @param {number=} opt_index
   */
  addAllowedRoles(value: string, opt_index?: number): void {
    jspb.Message.addToRepeatedField(this, 17, value, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearAllowedRolesList() {
    this.setAllowedRolesList([]);
  }


  /**
   * optional bool requires_approval = 18;
   * @return {boolean}
   */
  getRequiresApproval(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 18, false));
  };


  /** @param {boolean} value */
  setRequiresApproval(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 18, value);
  }


}
export namespace UpdateChallengeInfo {
export enum BannerCase {
  BANNER_NOT_SET = 0,
  BANNER_HASH = 6,
  BANNER_ASSET = 7,
}
} // namespace UpdateChallengeInfo
export namespace InvitedChallengePersonInfo {
  export interface AsObject {
    personInfo?: gateway_person_view_pb.PersonViewMinimal.AsObject;
    personEmail: string;
  }
}
export class InvitedChallengePersonInfo extends jspb.Message {
  static readonly displayName = "InvitedChallengePersonInfo";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): InvitedChallengePersonInfo.AsObject {
    return InvitedChallengePersonInfo.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!InvitedChallengePersonInfo} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: InvitedChallengePersonInfo) {
    var f: any;
    var obj: any = {
      personInfo: (f = msg.getPersonInfo()) && gateway_person_view_pb.PersonViewMinimal.toObject(includeInstance, f),
      personEmail: jspb.Message.getFieldWithDefault(msg, 2, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!InvitedChallengePersonInfo}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new InvitedChallengePersonInfo;
    return InvitedChallengePersonInfo.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!InvitedChallengePersonInfo} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!InvitedChallengePersonInfo}
   */
  static deserializeBinaryFromReader(msg: InvitedChallengePersonInfo, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new gateway_person_view_pb.PersonViewMinimal;
        reader.readMessage(value1,gateway_person_view_pb.PersonViewMinimal.deserializeBinaryFromReader);
        msg.setPersonInfo(value1);
        break;
      case 2:
        var value2 = /** @type {string} */ (reader.readString());
        msg.setPersonEmail(value2);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!InvitedChallengePersonInfo} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: InvitedChallengePersonInfo, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getPersonInfo();
    if (f != null) {
      writer.writeMessage(
        1,
        f,
        gateway_person_view_pb.PersonViewMinimal.serializeBinaryToWriter
      );
    }
    f = message.getPersonEmail();
    if (f.length > 0) {
      writer.writeString(
        2,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    InvitedChallengePersonInfo.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional PersonViewMinimal person_info = 1;
   * @return {?PersonViewMinimal}
   */
  getPersonInfo(): gateway_person_view_pb.PersonViewMinimal {
    return /** @type{?PersonViewMinimal} */ (
      jspb.Message.getWrapperField(this, gateway_person_view_pb.PersonViewMinimal, 1));
  }


  /** @param {?PersonViewMinimal|undefined} value */
  setPersonInfo(value?: gateway_person_view_pb.PersonViewMinimal) {
    jspb.Message.setWrapperField(this, 1, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearPersonInfo() {
    this.setPersonInfo(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasPersonInfo(): boolean {
    return jspb.Message.getField(this, 1) != null;
  }


  /**
   * optional string person_email = 2;
   * @return {string}
   */
  getPersonEmail(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
  };


  /** @param {string} value */
  setPersonEmail(value: string) {
    jspb.Message.setProto3StringField(this, 2, value);
  }


}
export namespace InviteChallengePeople {
  export interface AsObject {
    contentHash: string;
    contentContextHash: string;
    invitedPersonHashList: string[];
  }
}
export class InviteChallengePeople extends jspb.Message {
  static readonly displayName = "InviteChallengePeople";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [3];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, InviteChallengePeople.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): InviteChallengePeople.AsObject {
    return InviteChallengePeople.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!InviteChallengePeople} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: InviteChallengePeople) {
    var f: any;
    var obj: any = {
      contentHash: jspb.Message.getFieldWithDefault(msg, 1, ""),
      contentContextHash: jspb.Message.getFieldWithDefault(msg, 2, ""),
      invitedPersonHashList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!InviteChallengePeople}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new InviteChallengePeople;
    return InviteChallengePeople.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!InviteChallengePeople} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!InviteChallengePeople}
   */
  static deserializeBinaryFromReader(msg: InviteChallengePeople, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setContentHash(value1);
        break;
      case 2:
        var value2 = /** @type {string} */ (reader.readString());
        msg.setContentContextHash(value2);
        break;
      case 3:
        var value3 = /** @type {string} */ (reader.readString());
        msg.addInvitedPersonHash(value3);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!InviteChallengePeople} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: InviteChallengePeople, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getContentHash();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
    f = message.getContentContextHash();
    if (f.length > 0) {
      writer.writeString(
        2,
        f
      );
    }
    f = message.getInvitedPersonHashList();
    if (f.length > 0) {
      writer.writeRepeatedString(
        3,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    InviteChallengePeople.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string content_hash = 1;
   * @return {string}
   */
  getContentHash(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setContentHash(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


  /**
   * optional string content_context_hash = 2;
   * @return {string}
   */
  getContentContextHash(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
  };


  /** @param {string} value */
  setContentContextHash(value: string) {
    jspb.Message.setProto3StringField(this, 2, value);
  }


  /**
   * repeated string invited_person_hash = 3;
   * @return {!Array<string>}
   */
  getInvitedPersonHashList(): string[] {
    return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
  };


  /** @param {!Array<string>} value */
  setInvitedPersonHashList(value: string[]) {
    jspb.Message.setField(this, 3, value || []);
  }


  /**
   * @param {string} value
   * @param {number=} opt_index
   */
  addInvitedPersonHash(value: string, opt_index?: number): void {
    jspb.Message.addToRepeatedField(this, 3, value, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearInvitedPersonHashList() {
    this.setInvitedPersonHashList([]);
  }


}
export namespace ChallengeInvitePerson {
  export interface AsObject {
    authorPersonView?: InvitedChallengePersonInfo.AsObject;
    inviteDateTime?: common_date_pb.DateTime.AsObject;
    responseDateTime?: common_date_pb.DateTime.AsObject;
  }
}
export class ChallengeInvitePerson extends jspb.Message {
  static readonly displayName = "ChallengeInvitePerson";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): ChallengeInvitePerson.AsObject {
    return ChallengeInvitePerson.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!ChallengeInvitePerson} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: ChallengeInvitePerson) {
    var f: any;
    var obj: any = {
      authorPersonView: (f = msg.getAuthorPersonView()) && InvitedChallengePersonInfo.toObject(includeInstance, f),
      inviteDateTime: (f = msg.getInviteDateTime()) && common_date_pb.DateTime.toObject(includeInstance, f),
      responseDateTime: (f = msg.getResponseDateTime()) && common_date_pb.DateTime.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!ChallengeInvitePerson}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new ChallengeInvitePerson;
    return ChallengeInvitePerson.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!ChallengeInvitePerson} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!ChallengeInvitePerson}
   */
  static deserializeBinaryFromReader(msg: ChallengeInvitePerson, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new InvitedChallengePersonInfo;
        reader.readMessage(value1,InvitedChallengePersonInfo.deserializeBinaryFromReader);
        msg.setAuthorPersonView(value1);
        break;
      case 2:
        var value2 = new common_date_pb.DateTime;
        reader.readMessage(value2,common_date_pb.DateTime.deserializeBinaryFromReader);
        msg.setInviteDateTime(value2);
        break;
      case 3:
        var value3 = new common_date_pb.DateTime;
        reader.readMessage(value3,common_date_pb.DateTime.deserializeBinaryFromReader);
        msg.setResponseDateTime(value3);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!ChallengeInvitePerson} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: ChallengeInvitePerson, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getAuthorPersonView();
    if (f != null) {
      writer.writeMessage(
        1,
        f,
        InvitedChallengePersonInfo.serializeBinaryToWriter
      );
    }
    f = message.getInviteDateTime();
    if (f != null) {
      writer.writeMessage(
        2,
        f,
        common_date_pb.DateTime.serializeBinaryToWriter
      );
    }
    f = message.getResponseDateTime();
    if (f != null) {
      writer.writeMessage(
        3,
        f,
        common_date_pb.DateTime.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    ChallengeInvitePerson.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional InvitedChallengePersonInfo author_person_view = 1;
   * @return {?InvitedChallengePersonInfo}
   */
  getAuthorPersonView(): InvitedChallengePersonInfo {
    return /** @type{?InvitedChallengePersonInfo} */ (
      jspb.Message.getWrapperField(this, InvitedChallengePersonInfo, 1));
  }


  /** @param {?InvitedChallengePersonInfo|undefined} value */
  setAuthorPersonView(value?: InvitedChallengePersonInfo) {
    jspb.Message.setWrapperField(this, 1, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearAuthorPersonView() {
    this.setAuthorPersonView(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasAuthorPersonView(): boolean {
    return jspb.Message.getField(this, 1) != null;
  }


  /**
   * optional minga.common.DateTime invite_date_time = 2;
   * @return {?DateTime}
   */
  getInviteDateTime(): common_date_pb.DateTime {
    return /** @type{?DateTime} */ (
      jspb.Message.getWrapperField(this, common_date_pb.DateTime, 2));
  }


  /** @param {?DateTime|undefined} value */
  setInviteDateTime(value?: common_date_pb.DateTime) {
    jspb.Message.setWrapperField(this, 2, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearInviteDateTime() {
    this.setInviteDateTime(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasInviteDateTime(): boolean {
    return jspb.Message.getField(this, 2) != null;
  }


  /**
   * optional minga.common.DateTime response_date_time = 3;
   * @return {?DateTime}
   */
  getResponseDateTime(): common_date_pb.DateTime {
    return /** @type{?DateTime} */ (
      jspb.Message.getWrapperField(this, common_date_pb.DateTime, 3));
  }


  /** @param {?DateTime|undefined} value */
  setResponseDateTime(value?: common_date_pb.DateTime) {
    jspb.Message.setWrapperField(this, 3, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearResponseDateTime() {
    this.setResponseDateTime(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasResponseDateTime(): boolean {
    return jspb.Message.getField(this, 3) != null;
  }


}
export namespace ChallengeInvitePeople {
  export interface AsObject {
    personList: ChallengeInvitePerson.AsObject[];
  }
}
export class ChallengeInvitePeople extends jspb.Message {
  static readonly displayName = "ChallengeInvitePeople";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [1];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, ChallengeInvitePeople.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): ChallengeInvitePeople.AsObject {
    return ChallengeInvitePeople.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!ChallengeInvitePeople} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: ChallengeInvitePeople) {
    var f: any;
    var obj: any = {
      personList: jspb.Message.toObjectList(msg.getPersonList(),
      ChallengeInvitePerson.toObject, includeInstance)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!ChallengeInvitePeople}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new ChallengeInvitePeople;
    return ChallengeInvitePeople.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!ChallengeInvitePeople} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!ChallengeInvitePeople}
   */
  static deserializeBinaryFromReader(msg: ChallengeInvitePeople, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new ChallengeInvitePerson;
        reader.readMessage(value1,ChallengeInvitePerson.deserializeBinaryFromReader);
        msg.addPerson(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!ChallengeInvitePeople} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: ChallengeInvitePeople, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getPersonList();
    if (f.length > 0) {
      writer.writeRepeatedMessage(
        1,
        f,
        ChallengeInvitePerson.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    ChallengeInvitePeople.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * repeated ChallengeInvitePerson person = 1;
   * @return {!Array<!ChallengeInvitePerson>}
   */
  getPersonList(): ChallengeInvitePerson[] {
    return /** @type{!Array<!ChallengeInvitePerson>} */ (
      jspb.Message.getRepeatedWrapperField(this, ChallengeInvitePerson, 1));
  }


  /** @param {!Array<!ChallengeInvitePerson>} value */
  setPersonList(value?: ChallengeInvitePerson[]) {
    jspb.Message.setRepeatedWrapperField(this, 1, value);
  }


  /**
   * @param {!ChallengeInvitePerson=} opt_value
   * @param {number=} opt_index
   * @return {!ChallengeInvitePerson}
   */
  addPerson(opt_value?: ChallengeInvitePerson, opt_index?: number): ChallengeInvitePerson {
    return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, ChallengeInvitePerson, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearPersonList() {
    this.setPersonList([]);
  }


}
