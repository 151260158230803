import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';

import { GroupPreviewClickMode } from '@app/src/app/groups/components/MgGroupPreview';
import { NotificationStoreModel } from '@app/src/app/store/Notifications/model';

import { Group } from '../../models/Group';
import {
  GroupContextMenuEvent,
  GroupsFacadeService,
  GroupStateService,
} from '../../services';

@Component({
  selector: 'mg-group-list',
  styleUrls: ['./MgGroupList.component.scss'],
  templateUrl: './MgGroupList.component.html',
  providers: [GroupStateService],
})
export class MgGroupListComponent {
  @Input()
  groups?: Group[];

  /**
   * Configures group preview to have join, select or navigate to group on click
   */
  @Input()
  clickMode: GroupPreviewClickMode = GroupPreviewClickMode.GROUP;

  @Input()
  noSuccessInterstitial: boolean = false;

  @Input()
  newContentNotifications: NotificationStoreModel[] = [];

  @Output()
  groupSelect = new EventEmitter<Group>();

  constructor(
    private groupsFacadeService: GroupsFacadeService,
    private router: Router,
    private groupState: GroupStateService,
  ) {}

  onLeaveGroup(group: Group) {
    this.groupState.openLeaveDialog(group, this.noSuccessInterstitial);
  }

  requestToJoinGroup(group: Group) {
    this.groupsFacadeService.dispatchRequestToJoinGroup(
      group,
      this.noSuccessInterstitial,
    );
  }

  cancelRequestToJoinGroup(group: Group) {
    this.groupsFacadeService.dispatchCancelRequestToJoinGroup(group);
  }

  openEdit(group: Group) {
    this.router.navigate([
      '',
      { outlets: { o: ['groups', 'update', group.hash] } },
    ]);
  }

  onGroupContextMenu(ev: GroupContextMenuEvent) {
    this.groupState.onGroupContextMenu(ev);
  }

  hasNewContent(group: Group): boolean {
    if (
      this.newContentNotifications.find(
        (e: NotificationStoreModel) => e.groupHash === group.hash,
      )
    ) {
      return true;
    }
    return false;
  }
}
