import { PointReward } from 'libs/domain';
import { points_pb } from 'libs/generated-grpc-ts';

export const toProto = (pointReward: PointReward): points_pb.PointReward => {
  const msg = new points_pb.PointReward();
  if (pointReward.id) msg.setId(pointReward.id);
  if (pointReward.active) msg.setActive(pointReward.active);
  if (pointReward.name) msg.setName(pointReward.name);
  if (pointReward.description) msg.setDescription(pointReward.description);
  if (pointReward.pointCost) msg.setPointCost(pointReward.pointCost);
  if (pointReward.imagePath) msg.setImagePath(pointReward.imagePath);
  if (pointReward.uuid) msg.setUuid(pointReward.uuid);
  return msg;
};

export const fromProto = (
  msg: points_pb.PointReward,
): Omit<PointReward, 'mingaId'> => {
  return {
    id: msg.getId(),
    active: msg.getActive(),
    name: msg.getName(),
    description: msg.getDescription(),
    pointCost: msg.getPointCost(),
    imagePath: msg.getImagePath(),
    uuid: msg.getUuid(),
  };
};
