// GENERATED CODE -- DO NOT EDIT!

import {grpc} from '@improbable-eng/grpc-web';

import * as cloudflare_cloudflare_pb from '../cloudflare/cloudflare_pb';

export class CloudflareNotifications {
  static readonly serviceName = "minga.direct.cloudflare.CloudflareNotifications";

  static readonly VideoStatus = {
    methodName: "VideoStatus",
    service: CloudflareNotifications,
    requestStream: false,
    responseStream: false,
    requestType: cloudflare_cloudflare_pb.CloudflareNotificationsVideoStatusRequest,
    responseType: cloudflare_cloudflare_pb.CloudflareNotificationsVideoStatusResponse,
  };

}

export class CloudflareNotificationsClient {
  videoStatus() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
}
