import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { HpmDashboardCanApprovePipe } from './hpm-dashboard-can-approve-pipe';
import { HpmDashboardEndTimePipe } from './hpm-dashboard-endtime.pipe';
import { HpmDashboardCountDownPipe } from './hpm-dashboard-status.pipe';

@NgModule({
  imports: [
    // Minga dependencies
    CommonModule,
  ],
  exports: [
    HpmDashboardCountDownPipe,
    HpmDashboardEndTimePipe,
    HpmDashboardCanApprovePipe,
  ],
  declarations: [
    HpmDashboardCountDownPipe,
    HpmDashboardEndTimePipe,
    HpmDashboardCanApprovePipe,
  ],
})
export class HpmDashboardPipesModule {}
