import { IMingaPartner } from 'libs/domain';
import { gateway } from 'libs/generated-grpc-ts';

export const toProto = (
  partner: IMingaPartner,
): gateway.minga_pb.MingaPartner => {
  const message = new gateway.minga_pb.MingaPartner();
  message.setId(partner.id);
  message.setName(partner.name);
  return message;
};

export const fromProto = (
  message: gateway.minga_pb.MingaPartner,
): IMingaPartner => {
  return {
    id: message.getId(),
    name: message.getName(),
  };
};
