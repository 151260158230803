import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  NgZone,
  OnDestroy,
  Renderer2,
  SimpleChanges,
  ViewChild,
} from '@angular/core';

import { mgResolveAssetUrl } from '@app/src/app/util/asset';

const loadImageCache = new Set<string>();

type Mutable<T> = {
  -readonly [P in keyof T]: T[P];
};

function loadImage(src: string) {
  return new Promise<void>((resolve, reject) => {
    const img = new Image();
    img.onload = () => {
      loadImageCache.add(src);
      requestAnimationFrame(() => resolve());
    };
    img.onerror = reject;
    img.src = src;
  });
}

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'mg-image',
  templateUrl: './MgImage.component.html',
  styleUrls: ['./MgImage.component.scss'],
})
export class MgImageComponent implements OnDestroy {
  private _active: boolean = true;
  private _activePromise: Promise<void>;
  private _activePromiseResolve: () => void;

  @Input()
  mode: 'sequential' | 'parallel' | 'race' = 'sequential';

  @Input()
  srcs: string[] = [];

  @Input()
  aspectRatio: string | number = '';

  @Input('background-size')
  backgroundSize: 'cover' | 'contain' = 'cover';

  /**
   * Tells the image to start loading. By default this is `true`
   *
   * @NOTE for sequential mode the first image is always loaded regardless of
   * `active`s value. The next images won't load until this is set to true in
   * sequential mode.
   */
  @Input()
  set active(value: boolean) {
    const previousValue = this._active;
    this._active = coerceBooleanProperty(value);

    if (this._active != previousValue) {
      this._activePromiseResolve();
      this._activePromise = new Promise(resolve => {
        this._activePromiseResolve = resolve;
      });

      if (!this._active) {
        // Reset as if srcs changed
        this._srcsChanged(this.srcs);
      }
    }
  }

  get active() {
    return this._active;
  }

  @ViewChild('blurredImgEl', { static: true })
  readonly _blurredImgEl!: ElementRef<HTMLDivElement>;

  @ViewChild('imgEl', { static: true })
  readonly _imgEl!: ElementRef<HTMLDivElement>;

  private _loadId: number = 0;
  readonly _loading: boolean = false;

  constructor(
    private _cdr: ChangeDetectorRef,
    private _element: ElementRef,
    private _renderer2: Renderer2,
    private _ngZone: NgZone,
  ) {
    this._activePromiseResolve = () => {};
    this._activePromise = Promise.resolve();
  }

  private _setLoading(loading: boolean) {
    (<Mutable<this>>this)._loading = loading;

    if (this._loading) {
      this._renderer2.addClass(this._element.nativeElement, 'mg-loading');
    } else {
      this._renderer2.removeClass(this._element.nativeElement, 'mg-loading');
    }
  }

  ngOnDestroy() {
    this._loadId = 0;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.srcs) {
      this._srcsChanged(this.srcs);
    }
  }

  private _setCurrentSrc(src: string) {
    this._renderer2.setStyle(
      this._imgEl.nativeElement,
      'background-image',
      `url(${src})`,
    );
  }

  private _setLoadingSrc(src: string) {
    this._renderer2.setStyle(
      this._blurredImgEl.nativeElement,
      'background-image',
      `url(${src})`,
    );
  }

  private _clearLoadingAndCurrentSrc() {
    this._renderer2.setStyle(this._imgEl.nativeElement, 'background-image', '');
    this._renderer2.setStyle(
      this._blurredImgEl.nativeElement,
      'background-image',
      '',
    );
  }

  private async _startLoading(srcs: string[]) {
    this._clearLoadingAndCurrentSrc();

    if (srcs.length == 0) {
      return;
    }

    const loadId = ++this._loadId;
    const checkLoadId = () => loadId === this._loadId;

    const doSequentialImagesLoad = async () => {
      const loadingSrcs = srcs.slice(0, srcs.length - 1).map(mgResolveAssetUrl);
      const finalSrc = mgResolveAssetUrl(srcs[srcs.length - 1]);

      for (const loadingSrc of loadingSrcs) {
        if (!checkLoadId()) return;
        if (!loadImageCache.has(loadingSrc)) {
          await loadImage(loadingSrc);
        }

        if (!checkLoadId()) return;
        this._setLoadingSrc(loadingSrc);
      }

      if (!loadImageCache.has(finalSrc)) {
        if (!checkLoadId()) return;
        await loadImage(finalSrc);
      }

      if (!checkLoadId()) return;
      this._setCurrentSrc(finalSrc);

      await this._waitIfInactive();
    };

    const doRaceImagesLoad = async () => {
      await this._waitIfInactive();

      const src = await Promise.race(
        srcs.map(async src => {
          if (checkLoadId()) {
            await loadImage(src);
          }

          return src;
        }),
      );

      if (checkLoadId()) {
        this._setCurrentSrc(src);
      }
    };

    const doParallelImagesLoad = async () => {
      await this._waitIfInactive();

      let activeIndex = -1;

      await Promise.all(
        srcs.map(async (src, index) => {
          if (!checkLoadId()) {
            return;
          }

          await loadImage(src);
          if (index > activeIndex) {
            this._setCurrentSrc(src);
            activeIndex = index;
          }
        }),
      );
    };

    switch (this.mode) {
      case 'parallel':
        await doParallelImagesLoad();
        break;
      case 'race':
        await doRaceImagesLoad();
        break;
      default:
        console.warn(
          `<mg-image> mode not set or invalid. The allowed modes are 'sequential', 'race', or 'parallel'. Defaulting to 'sequential'.`,
        );
      case 'sequential':
        await doSequentialImagesLoad();
        break;
    }

    if (checkLoadId()) {
      // If we've reached the end and our checkLoadId passed we can re use our
      // existing load id.
      this._loadId = loadId;
    }
  }

  private async _waitIfInactive() {
    if (!this._active) await this._activePromise;
  }

  private _srcsChanged(srcs: string[]) {
    if (!Array.isArray(srcs)) {
      console.warn('<mg-image> requires images to be an array. Got:', srcs);
      return;
    }

    this._ngZone.runOutsideAngular(async () => {
      this._setLoading(true);
      await this._startLoading(srcs).catch(err => {
        this._setLoading(false);
        throw err;
      });
      this._setLoading(false);
    });
  }
}
