// GENERATED CODE -- DO NOT EDIT!

import {grpc} from '@improbable-eng/grpc-web';

import * as content_eventCollection_event_collection_pb from '../../content/eventCollection/event_collection_pb';

export class EventCollection {
  static readonly serviceName = "minga.content.EventCollection";

  static readonly GetEventCollection = {
    methodName: "GetEventCollection",
    service: EventCollection,
    requestStream: false,
    responseStream: true,
    requestType: content_eventCollection_event_collection_pb.GetEventCollectionRequest,
    responseType: content_eventCollection_event_collection_pb.GetEventCollectionResponse,
  };

}

export class EventCollectionClient {
  getEventCollection() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
}
