import { Pipe, PipeTransform } from '@angular/core';

import { PastReportStatus } from 'libs/domain';

@Pipe({ name: 'reportError' })
export class ReportErrorPipe implements PipeTransform {
  transform(reportStatus: PastReportStatus): string {
    const CONFIG: Record<string, string> = {
      [PastReportStatus.EmailError]:
        'There was an issue sending the report to email',
      [PastReportStatus.SftpError]:
        'There was an issue sending the report to sftp',
      [PastReportStatus.Error]: 'There was an issue generating the report',
      [PastReportStatus.ReportGenerationError]:
        'There was an issue generating the report',
    };

    return CONFIG[reportStatus] || '';
  }
}
