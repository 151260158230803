import {
  ChangeDetectionStrategy,
  Component,
  ContentChildren,
  ElementRef,
  Input,
  QueryList,
  ViewChild,
} from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { Router } from '@angular/router';

import { FtueHighlightKey } from '@app/src/app/ftue/Highlight';
import { NavLinkComponent } from '@app/src/app/navigation/components/NavLink/NavLink.component';

@Component({
  selector: 'mg-sub-nav',
  templateUrl: './SubNav.component.html',
  styleUrls: ['./SubNav.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SubNavComponent {
  private _navIndex: number = 0;

  @Input()
  title: string = '';

  @Input()
  header: string = '';

  @Input()
  icon: string = '';

  @Input()
  highlightKey?: FtueHighlightKey;

  @ContentChildren(NavLinkComponent)
  items: QueryList<NavLinkComponent>[] = [];

  @ViewChild(MatMenuTrigger, { static: false })
  trigger: MatMenuTrigger | null = null;

  @Input()
  set navIndex(index: number) {
    this._navIndex = index;
    const indexClassPrefix = 'mg-nav-item-index-';

    this.element.nativeElement.classList.forEach((c: string) => {
      if (c.startsWith(indexClassPrefix)) {
        this.element.nativeElement.classList.remove(c);
      }
    });

    this.element.nativeElement.classList.add(indexClassPrefix + index);
  }

  constructor(private router: Router, private element: ElementRef) {}

  toggleMenu() {
    this.trigger?.openMenu();
  }

  hasItems(): boolean {
    if (this.items.length > 0) {
      return true;
    }
    return false;
  }
}
