import { formatDate } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';

import { ChallengeColor, ChallengeType } from 'libs/domain';
import { MingaRoleType } from 'libs/domain';
import {
  gateway,
  challenge_response_pb,
  challenge_response_ng_grpc_pb,
} from 'libs/generated-grpc-web';
import { PersonViewMinimal } from 'libs/generated-grpc-web';
import { badgeRoleNameToMingaRoleType } from 'libs/shared';
import { ChallengeResponseSummaryItemMapper } from 'libs/shared-grpc';
import { Observable, ReplaySubject, Subscription } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { $enum } from 'ts-enum-util';

import { IAvatarClusterItem } from '@app/src/app/components/AvatarCluster';
import { StreamSwipeStackComponent } from '@app/src/app/components/StreamSwipeStack';
import { AuthInfoService } from '@app/src/app/minimal/services/AuthInfo';
import { ChallengeResponseStackStream } from '@app/src/app/modules/challenges/services/ChallengeResponseStackStream';
import { dateMessageObjectToDateObject } from '@app/src/app/util/date';

import { BaseShortCardClass } from '../BaseShortCardClass';

export interface IMgChallengeShortCardElementProperties {}

const PREVIEW_LIMIT = 2;

export interface IShortCardChallengeClickEvent {
  inputEvent: HammerInput;
  challengeContextHash: string;
}

export interface IShortCardChallengeResponseClickEvent {
  inputEvent: HammerInput;
  challengeContextHash: string;
  responseContextHash: string;
}

@Component({
  selector: 'mg-challenge-short-card',
  templateUrl: './MgChallengeShortCard.element.html',
  styleUrls: ['./MgChallengeShortCard.element.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MgChallengeShortCardElement
  extends BaseShortCardClass
  implements OnInit
{
  /** @internal */
  private _content: gateway.content_views_pb.ShortChallengeCardView.AsObject | null =
    null;

  @ViewChild('streamSwipeStack', { static: false })
  streamSwipeStack?: StreamSwipeStackComponent<challenge_response_pb.ChallengeResponseCard.AsObject>;

  @HostBinding('class.has-responses')
  get hasResponses(): boolean {
    return !!this._content?.responsesSummaryList?.length;
  }

  avatarClusterItems: IAvatarClusterItem[] = [];
  peopleResponsesText: string = '';
  typeStyleClass: string = '';
  dueDate: Date | null = null;
  dueDateSubheading: string = '';
  stackIndex: number = 0;
  currentDate: Date = new Date();
  hasResponded: boolean = false;

  responseStream?: ChallengeResponseStackStream;

  readonly currentUser$: Observable<PersonViewMinimal.AsObject | null>;
  private _destroyed$ = new ReplaySubject<void>(1);
  private _currentUserSubscription?: Subscription;
  hasPermission: boolean = false;

  @Input()
  set content(
    content: gateway.content_views_pb.ShortChallengeCardView.AsObject | null,
  ) {
    this._content = content || null;

    this.updateAvatarClusterItems();
    this.updateTypeClass();
    this.updatePeopleResponsesText();
    this.setDueDate();
    this.setColor();
    this.setHasResponded();
  }

  get content(): gateway.content_views_pb.ShortChallengeCardView.AsObject | null {
    return this._content;
  }

  @Input()
  pinned: boolean = false;

  @Input()
  markKeywords: string = '';

  @Input()
  context: string = '';

  @Input()
  commentRoles: string[] | null = null;

  @Input()
  color: ChallengeColor = ChallengeColor.RED;

  @Output()
  readonly challengeClick: EventEmitter<IShortCardChallengeClickEvent>;

  @Output()
  readonly challengeResponseClick: EventEmitter<IShortCardChallengeResponseClickEvent>;

  constructor(
    private responseProto: challenge_response_ng_grpc_pb.ChallengeResponse,
    private authInfo: AuthInfoService,
  ) {
    super();
    this.challengeClick = new EventEmitter();
    this.challengeResponseClick = new EventEmitter();

    this.currentUser$ = this.authInfo.authInfo$.pipe(
      takeUntil(this._destroyed$),
      map(info => {
        if (info && info.person) {
          return info.person;
        }
        return null;
      }),
    );
  }

  ngOnInit() {
    this.updateStreamDataSource();

    this._currentUserSubscription = this.currentUser$.subscribe(person => {
      if (!this.content?.allowedRolesList) {
        return;
      }

      const personBadgeRoleName = person?.badgeRoleName || '';
      const personRole = badgeRoleNameToMingaRoleType(personBadgeRoleName);
      const allowedRoles = this.content.allowedRolesList.map(role => {
        return $enum(MingaRoleType).getValueOrThrow(role);
      });

      if (personRole && allowedRoles && allowedRoles.includes(personRole)) {
        this.hasPermission = true;
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.content) {
      this.updateAvatarClusterItems();
      this.updatePeopleResponsesText();
      this.updateStreamDataSource();
    }

    if (changes.context) {
      this.updateStreamDataSource();
    }
  }

  ngOnDestroy() {
    this._currentUserSubscription?.unsubscribe();
  }

  updateStreamDataSource() {
    if (this.context) {
      let previewItems: challenge_response_pb.ChallengeResponseCard.AsObject[] =
        [];
      if (this.content) {
        previewItems = this.content.responsesSummaryList.map(
          ChallengeResponseSummaryItemMapper.toChallengeResponseCard,
        );
      }

      if (this.responseStream?.ownerContextHash == this.context) {
        this.responseStream.setPreviewInitialItems(previewItems);
      } else {
        this.responseStream = new ChallengeResponseStackStream(
          this.responseProto,
          previewItems,
          this.context,
        );
      }
    } else {
      delete this.responseStream;
    }
  }

  updateAvatarClusterItems() {
    if (!this._content || !this._content?.responsesSummaryList) {
      this.avatarClusterItems = [];
      return;
    }

    const clusterItems = [];
    for (let response of this._content.responsesSummaryList) {
      if (response.authorPersonView) {
        clusterItems.push({
          src: response.authorPersonView.profileImageUrl,
          color: response.authorPersonView.badgeIconColor,
          personHash: response.authorPersonView.personHash,
        });
      }

      if (clusterItems.length == PREVIEW_LIMIT) {
        break;
      }
    }
    this.avatarClusterItems = clusterItems;
  }

  updateTypeClass() {
    if (!this._content || !this._content.challengeType)
      this.typeStyleClass = '';

    switch (this._content?.challengeType) {
      case ChallengeType.TEXT:
        this.typeStyleClass = 'text';
        break;
      case ChallengeType.VIDEO:
        this.typeStyleClass = 'video';
        break;
      case ChallengeType.IMAGE:
      default:
        this.typeStyleClass = 'photo';
    }
  }

  updatePeopleResponsesText() {
    let responseString = '';

    if (!this._content || !this._content?.responsesSummaryList) {
      this.peopleResponsesText = responseString;
      return;
    }

    for (let i = 0; i < this._content.responsesSummaryList.length; i++) {
      if (i == PREVIEW_LIMIT) {
        if (i === this._content.respondedPeopleCount - 1)
          responseString += '1 more person';
        else
          responseString +=
            'and ' + (this._content.respondedPeopleCount - i) + ' more people';
        break;
      }

      const response = this._content.responsesSummaryList[i];
      responseString += response.authorPersonView?.displayName;
      if (i + 2 < this._content.responsesSummaryList.length) {
        responseString += ', ';
      } else if (i + 1 < this._content.responsesSummaryList.length) {
        responseString += ' and ';
      }
    }
    if (this._content.responsesSummaryList.length === 1)
      responseString += ' has posted their answer!';
    else responseString += ' have posted their answers!';
    this.peopleResponsesText = responseString;
  }

  setDueDate() {
    if (!this._content || !this._content.dueDate) {
      this.dueDate = null;
      return;
    }

    this.dueDate = dateMessageObjectToDateObject(this._content.dueDate);
    this.dueDateSubheading = formatDate(
      this.dueDate,
      'MMM d, y',
      'en-CA',
    ).toUpperCase();
  }

  setColor() {
    if (!this._content || !this._content.color) {
      this.color = ChallengeColor.RED;
      return;
    }

    this.color = (<any>ChallengeColor)[this._content.color];
  }

  setHasResponded() {
    this.hasResponded = this._content?.hasResponded || false;
  }

  onSwipeStackTap(ev: HammerInput) {
    if (!this.streamSwipeStack) return;

    if (this.streamSwipeStack.stackIndex == 0) {
      this.onChallengeClick(ev);
    } else {
      const currentResp = this.streamSwipeStack.currentItemData;
      if (currentResp) {
        this.onResponseClick(ev, currentResp.contextHash);
      }
    }
  }

  private onChallengeClick(ev: HammerInput) {
    if (!this.context) return;

    this.challengeClick.emit({
      inputEvent: ev,
      challengeContextHash: this.context,
    });
  }

  private onResponseClick(ev: HammerInput, responseContextHash: string) {
    if (!this.context) return;

    this.challengeResponseClick.emit({
      inputEvent: ev,
      challengeContextHash: this.context,
      responseContextHash,
    });
  }

  @HostListener('click', ['$event'])
  _onClick(ev: MouseEvent) {
    if (ev.target instanceof Node && ev.target.nodeName === 'A') {
      return;
    }

    ev.preventDefault();
    ev.stopPropagation();
    ev.stopImmediatePropagation();
  }
}
