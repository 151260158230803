<!-- Action bar  -->
<div *ngIf="showActionBar$ | async">
  <section class="action-bar">
    <div
      fxLayout="row"
      fxLayoutAlign="start center"
      fxLayoutGap="8px"
      class="action-buttons">
      <mg-text
        variant="body-xs"
        textAlign="left"
        color="surface-alt"
        [numberOfLines]="1">
        {{ selectionMessage }}
      </mg-text>
      <mg-btn
        *ngIf="!actionService.isEmpty() && !actionService.isAllSelected()"
        variant="text"
        (click)="selectAll()">
        Select all {{ actionService.getSelectableCount() }} results
      </mg-btn>
      <mg-btn
        *ngIf="!actionService.isEmpty()"
        variant="text"
        (click)="clearSelection()">
        Clear selection
      </mg-btn>
      <ng-container
        *ngIf="actionBarTemplate"
        [ngTemplateOutlet]="actionBarTemplate">
      </ng-container>
    </div>
  </section>
</div>

<!-- Table wrapper -->
<div
  class="table-wrapper"
  [class.no-results]="(hasData$ | async) === false"
  [class.showActionBar]="showActionBar$ | async"
  [ngStyle]="wrapperMaxHeight$ | async">
  <!-- Table -->
  <mat-table
    [dataSource]="data$ | async"
    class="report-table">
    <!-- Select checkbox column -->
    <ng-container matColumnDef="report-table-select-col">
      <mat-header-cell *matHeaderCellDef>
        <mg-form-checkbox
          [indeterminate]="(itemsSelected$ | async) === 'some'"
          [checked]="(itemsSelected$ | async) === 'all'"
          (changed)="masterToggle()">
        </mg-form-checkbox>
      </mat-header-cell>
      <mat-cell *matCellDef="let item">
        <mg-form-checkbox
          [checked]="actionService.isSelected(item)"
          (changed)="actionService.toggle(item)">
        </mg-form-checkbox>
      </mat-cell>
    </ng-container>

    <!-- Dynamic columns -->
    <ng-container
      *ngFor="let column of columnsMetaData$ | async; let isLast = last"
      [matColumnDef]="column.key">
      <mat-header-cell
        *matHeaderCellDef="let item"
        mgSortHeader
        [activeColumnKey]="activeSortColumn$ | async"
        [sortable]="sortable"
        [mat-sort-header]="column.key"
        [disabled]="!column.sort"
        [ngClass]="
          column
            | reportTableHeaderCellClasses
              : (spaceAfterContent ? false : isLast)
              : sortable
        "
        [ngStyle]="column | reportTableCellStyles: item"
        (click)="selectActiveColumn(column)">
        {{ column.header }}
      </mat-header-cell>
      <mat-cell
        *matCellDef="let item"
        [ngClass]="
          column | reportTableCellClasses: (spaceAfterContent ? false : isLast)
        "
        [ngStyle]="column | reportTableCellStyles: item">
        <ng-container
          *ngTemplateOutlet="
            defaultCell;
            context: { data: item, column: column }
          ">
        </ng-container>
      </mat-cell>
    </ng-container>

    <!-- Report specific columns -->
    <ng-content></ng-content>

    <!-- Header columns -->
    <mat-header-row
      *matHeaderRowDef="displayedColumns$ | async; sticky: true"
      [style.display]="!(data$ | async)?.length ? 'none' : null">
    </mat-header-row>

    <!-- Main rows -->
    <mat-row
      *matRowDef="let row; columns: displayedColumns$ | async"
      [style.display]="
        (dataSourceService.tableLoading$ | async) ? 'none' : null
      "
      [class.space-after-content]="spaceAfterContent">
    </mat-row>
  </mat-table>
</div>

<!-- Paginator -->
<mg-paginator
  [id]="'report-report-view-click-pagination'"
  [fxHide]="
    !showPaginator ||
    (dataSourceService.infLoading$ | async) ||
    (dataSourceService.tableLoading$ | async) ||
    (hasData$ | async) === false
  "
  [pageSizeOptions]="pageSizeOptions"
  [length]="dataSourceService.pageToken"></mg-paginator>

<!-- Loading  -->
<div
  *ngIf="
    (dataSourceService.infLoading$ | async) ||
    (dataSourceService.tableLoading$ | async)
  "
  class="loading-container">
  <mg-spinner
    [diameter]="40"
    [thickness]="3">
  </mg-spinner>
</div>

<!-- Default cell template -->
<ng-template
  #defaultCell
  let-column="column"
  let-data="data">
  <ng-container *ngIf="data | reportTableCellContent: column as item">
    <ng-container *ngIf="column.key === 'status'; else emptyCell">
      <mg-tag [color]="item | statusTagColor: statusReference">
        {{ item }}
      </mg-tag>
    </ng-container>
    <ng-template #emptyCell>
      <ng-container *ngIf="item === '—'; else dateCell">
        <mg-text
          variant="body-md"
          color="outline">
          {{ item }}
        </mg-text>
      </ng-container>
    </ng-template>
    <ng-template #dateCell>
      <ng-container *ngIf="dateColumns.isValue(column.key); else timeCell">
        <span>
          {{ item | date: 'MMM dd, yyyy' }}
        </span>
      </ng-container>
    </ng-template>
    <ng-template #timeCell>
      <ng-container *ngIf="timeColumns.isValue(column.key); else noteCell">
        <span>
          {{ item | date: 'hh:mm a' }}
        </span>
      </ng-container>
    </ng-template>
    <ng-template #noteCell>
      <ng-container *ngIf="column.key === 'note'; else linkableCell">
        <mg-tooltip
          class="note-icon"
          icon="mg-description"
          size="medium"
          [label]="item">
        </mg-tooltip>
      </ng-container>
    </ng-template>
    <ng-template #linkableCell>
      <ng-container *ngIf="column.linkableFn; else classCell">
        <mg-btn
          [class.disabled-link]="column.linkableFn(data) === undefined"
          variant="text"
          [disabled]="column.linkableFn(data) === undefined"
          [target]="column?.linkableFn(data)?.target"
          [href]="column?.linkableFn(data)?.href">
          {{ item }}
        </mg-btn>
      </ng-container>
    </ng-template>
    <ng-template #classCell>
      <ng-container *ngIf="column.key === 'currentClass'; else standardCell">
        <mg-tooltip
          class="schedule-icon"
          icon="mg-schedule"
          size="medium"
          [label]="item">
        </mg-tooltip>
      </ng-container>
    </ng-template>
    <ng-template #standardCell>
      <div
        matTooltipClass="mg-tooltip"
        [matTooltip]="item"
        [matTooltipDisabled]="!column.tooltip"
        (click)="column.clickable && cellClickEvent(column.key, item)">
        <div
          [class.clickable-cell]="column.clickable"
          fxLayout="row">
          <mg-text
            variant="body-md"
            [numberOfLines]="column.width === 'flex-small' ? 1 : 2">
            {{ item }}
          </mg-text>
          <mg-icon
            *ngIf="column.clickable"
            class="clickable-icon">
            mg-chevron-right
          </mg-icon>
        </div>
      </div>
    </ng-template>
  </ng-container>
</ng-template>

<!-- Empty state -->
<ng-container
  *ngIf="
    visible &&
    (hasData$ | async) === false &&
    (this.dataSourceService.tableLoading$ | async) === false
  ">
  <mg-empty-state
    mg-stream-empty
    [title]="MSG.NO_RESULTS_TITLE"
    [subtitle]="noResultsMessage">
  </mg-empty-state>
</ng-container>
