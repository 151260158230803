import { BellScheduleOptions } from 'libs/domain';
import { Term } from 'libs/domain';
import { term_pb as proto } from 'libs/generated-grpc-ts';

import {
  dateObjectToDateTimeMessage,
  dateTimeMessageToDateObject,
} from '../util/dateAndTime';

export const toProto = (term: Term): proto.Term => {
  const msg = new proto.Term();
  if (term.id) msg.setId(term.id);
  msg.setSourcedId(term.sourcedId);
  msg.setTitle(term.title);
  if (term.status) msg.setStatus(term.status);
  if (term.startDate) {
    msg.setStartDate(dateObjectToDateTimeMessage(term.startDate));
  }
  if (term.endDate) msg.setEndDate(dateObjectToDateTimeMessage(term.endDate));
  if (term.startDateString) msg.setStartDateString(term.startDateString);
  if (term.endDateString) msg.setEndDateString(term.endDateString);
  if (term.type) msg.setType(term.type);
  if (term.schoolYear) msg.setSchoolYear(term.schoolYear);
  if (term.parentSourcedId) msg.setParentSourcedId(term.parentSourcedId);
  if (term.childSourcedIds) msg.setChildSourcedIdsList(term.childSourcedIds);
  if (term.dateLastModified) {
    msg.setDateLastModified(dateObjectToDateTimeMessage(term.dateLastModified));
  }
  if (term.bellSchedules) {
    const bellScheduleMsgs: proto.BellSchedule[] = [];
    term.bellSchedules.forEach(bellSchedule => {
      const bellScheduleMsg = new proto.BellSchedule();
      bellScheduleMsg.setId(bellSchedule.id);
      bellScheduleMsg.setName(bellSchedule.name);
      bellScheduleMsgs.push(bellScheduleMsg);
    });
    msg.setBellSchedulesList(bellScheduleMsgs);
  }

  return msg;
};

export const fromProto = (msg: proto.Term): Term => {
  const term: Term = {
    id: msg.getId(),
    sourcedId: msg.getSourcedId(),
    title: msg.getTitle(),
    status: msg.getStatus(),
    type: msg.getType(),
    schoolYear: msg.getSchoolYear(),
    parentSourcedId: msg.getParentSourcedId(),
    childSourcedIds: msg.getChildSourcedIdsList(),
    startDateString: msg.getStartDateString(),
    endDateString: msg.getEndDateString(),
  };

  if (msg.getDateLastModified()) {
    term.dateLastModified = dateTimeMessageToDateObject(
      msg.getDateLastModified(),
    );
  }

  if (msg.getStartDate()) {
    term.startDate = dateTimeMessageToDateObject(msg.getStartDate());
  }

  if (msg.getEndDate()) {
    term.endDate = dateTimeMessageToDateObject(msg.getEndDate());
  }

  if (msg.getBellSchedulesList()) {
    const bellScheduleList: BellScheduleOptions[] = [];
    if (msg.getBellSchedulesList().length > 0) {
      for (const bellSchedule of msg.getBellSchedulesList()) {
        const bellScheduleD: BellScheduleOptions = {
          id: bellSchedule.getId(),
          name: bellSchedule.getName(),
        };
        bellScheduleList.push(bellScheduleD);
      }
    }
    term.bellSchedules = bellScheduleList;
  }

  return term;
};

export const toSisProto = (term: Term): proto.Term => {
  const msg = new proto.Term();
  msg.setSourcedId(term.sourcedId);
  msg.setTitle(term.title);
  if (term.status) msg.setStatus(term.status);
  if (term.startDate) {
    msg.setStartDate(dateObjectToDateTimeMessage(term.startDate));
  }
  if (term.endDate) msg.setEndDate(dateObjectToDateTimeMessage(term.endDate));
  if (term.type) msg.setType(term.type);
  if (term.schoolYear) msg.setSchoolYear(term.schoolYear);

  return msg;
};

export const fromSisProto = (msg: proto.Term): Term => {
  const term: Term = {
    sourcedId: msg.getSourcedId(),
    title: msg.getTitle(),
    status: msg.getStatus(),
    type: msg.getType(),
    schoolYear: msg.getSchoolYear(),
  };
  if (msg.getStartDate()) {
    term.startDate = dateTimeMessageToDateObject(msg.getStartDate());
  }
  if (msg.getEndDate()) {
    term.endDate = dateTimeMessageToDateObject(msg.getEndDate());
  }
  return term;
};
