import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';

import { MgButtonModule } from '@app/src/app/button';
import { WelcomeDistrictSchoolSelectModule } from '@app/src/app/ftue/Welcome/components/WelcomeDistrictSchoolSelect';

import { DistrictSchoolSelectDialog } from './DistrictSchoolSelectDialog.component';

@NgModule({
  imports: [
    // Minga dependencies
    MgButtonModule,
    WelcomeDistrictSchoolSelectModule,

    // External dependencies
    CommonModule,
    MatDialogModule,
  ],
  declarations: [DistrictSchoolSelectDialog],
  exports: [DistrictSchoolSelectDialog],
})
export class DistrictSchoolSelectDialogModule {}
