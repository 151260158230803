import { Injectable, NgZone } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { grpc } from '@improbable-eng/grpc-web';

import {
  ActivateScheduledReportRequest,
  ActivateScheduledReportResponse,
  DeleteScheduledReportRequest,
  DeleteScheduledReportResponse,
  DownloadPastReportRequest,
  DownloadPastReportResponse,
  ExportReportChunk,
  GetOrExportReportRequest,
  GetPastReportRequest,
  GetPastReportResponse,
  GetReportResponse,
  GetScheduledReportRequest,
  GetScheduledReportResponse,
  ListPastReportsRequest,
  ListPastReportsResponse,
  ListScheduledReportsRequest,
  ListScheduledReportsResponse,
  UpsertScheduledReportRequest,
  UpsertScheduledReportResponse,
} from '../stats_report/stats_report_pb';

import {
  StatsReportManager as __StatsReportManager,
} from '../stats_report/stats_report_pb_service';

@Injectable({providedIn: 'root'})
export class StatsReportManager {

  constructor(private _ngZone: NgZone) {
  }

  getReport(request: GetOrExportReportRequest): Promise<GetReportResponse>;
  getReport(request: GetOrExportReportRequest, metadata: grpc.Metadata): Promise<GetReportResponse>;
  getReport(request: GetOrExportReportRequest, callback: (err: any|null, response: GetReportResponse, metadata: grpc.Metadata) => void): void;
  getReport(request: GetOrExportReportRequest, metadata: grpc.Metadata, callback: (err: any|null, response: GetReportResponse, metadata: grpc.Metadata) => void): void;

  getReport(request: GetOrExportReportRequest, arg1?: grpc.Metadata|((err: any|null, response: GetReportResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: GetReportResponse, metadata: grpc.Metadata) => void): Promise<GetReportResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<GetReportResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__StatsReportManager.GetReport, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  exportReport(request: GetOrExportReportRequest): {cancel():void;close():void}&Observable<ExportReportChunk>;
  exportReport(request: GetOrExportReportRequest, metadata: grpc.Metadata): {cancel():void;close():void}&Observable<ExportReportChunk>;
  exportReport(request: GetOrExportReportRequest, onMessage: (message?: ExportReportChunk) => void,onError?: (err: any) => void,onEnd?: (code: number, msg: string|undefined, metadata: grpc.Metadata) => void): void;
  exportReport(request: GetOrExportReportRequest, metadata: grpc.Metadata, onMessage: (message?: ExportReportChunk) => void,onError?: (err: any) => void,onEnd?: (code: number, msg: string|undefined, metadata: grpc.Metadata) => void): void;

  exportReport(request: GetOrExportReportRequest, arg1?: grpc.Metadata|((message?: ExportReportChunk) => void), arg2?: ((message?: ExportReportChunk) => void)|((err: any) => void), arg3?: ((err: any) => void)|((code: number, msg: string|undefined, metadata: grpc.Metadata) => void), arg4?: (code: number, msg: string|undefined, metadata: grpc.Metadata) => void): {cancel():void;close():void}&Observable<ExportReportChunk>|void {
    let ret: any;
    let metadata: any;

    let onMessage: any;
    let onError: any;
    let onEnd: any;
    if(typeof arg1 === 'function') {
      onMessage = arg1;
      onError = arg2;
      onEnd = arg3;
    } else if(typeof arg2 === 'function') {
      metadata = arg1;
      onMessage = arg2;
      onError = arg3;
      onEnd = arg4;
    } else {
      metadata = arg1;
    }

    if(!onMessage) {
      let subject = new Subject<ExportReportChunk>();
      ret = subject.asObservable();

      onMessage = (response: any) => {
        subject.next(response);
      };

      onError = (err: any) => {
        subject.error(err);
      };

      onEnd = (code: any, msg: any, metadata: any) => {
        subject.complete();
      };

    } else {
      if(!onError) {
        onError = (err: any) => console.error(err);
      }

      if(!onEnd) {
        onEnd = (code: any, msg: any, metadata: any) => {};
      }
    }

    let req = grpc.invoke(__StatsReportManager.ExportReport, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onMessage: response => this._ngZone.run(() => {
        onMessage(response);
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code == grpc.Code.OK) {
          onEnd(code, msg, metadata);
        } else {
          let err = new Error(code + ' ' + (msg||''));
          (<any>err).code = code;
          onError(err);
        }
      })
    });

    (<any>ret).close = () => {
      console.warn('[Angular Grpc] .close() is deprecated use .cancel() instead');
      return req.close();
    };
    (<any>ret).cancel = () => req.close();
    return ret;
  }

  listScheduledReports(request: ListScheduledReportsRequest): Promise<ListScheduledReportsResponse>;
  listScheduledReports(request: ListScheduledReportsRequest, metadata: grpc.Metadata): Promise<ListScheduledReportsResponse>;
  listScheduledReports(request: ListScheduledReportsRequest, callback: (err: any|null, response: ListScheduledReportsResponse, metadata: grpc.Metadata) => void): void;
  listScheduledReports(request: ListScheduledReportsRequest, metadata: grpc.Metadata, callback: (err: any|null, response: ListScheduledReportsResponse, metadata: grpc.Metadata) => void): void;

  listScheduledReports(request: ListScheduledReportsRequest, arg1?: grpc.Metadata|((err: any|null, response: ListScheduledReportsResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: ListScheduledReportsResponse, metadata: grpc.Metadata) => void): Promise<ListScheduledReportsResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<ListScheduledReportsResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__StatsReportManager.ListScheduledReports, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  getScheduledReport(request: GetScheduledReportRequest): Promise<GetScheduledReportResponse>;
  getScheduledReport(request: GetScheduledReportRequest, metadata: grpc.Metadata): Promise<GetScheduledReportResponse>;
  getScheduledReport(request: GetScheduledReportRequest, callback: (err: any|null, response: GetScheduledReportResponse, metadata: grpc.Metadata) => void): void;
  getScheduledReport(request: GetScheduledReportRequest, metadata: grpc.Metadata, callback: (err: any|null, response: GetScheduledReportResponse, metadata: grpc.Metadata) => void): void;

  getScheduledReport(request: GetScheduledReportRequest, arg1?: grpc.Metadata|((err: any|null, response: GetScheduledReportResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: GetScheduledReportResponse, metadata: grpc.Metadata) => void): Promise<GetScheduledReportResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<GetScheduledReportResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__StatsReportManager.GetScheduledReport, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  upsertScheduledReport(request: UpsertScheduledReportRequest): Promise<UpsertScheduledReportResponse>;
  upsertScheduledReport(request: UpsertScheduledReportRequest, metadata: grpc.Metadata): Promise<UpsertScheduledReportResponse>;
  upsertScheduledReport(request: UpsertScheduledReportRequest, callback: (err: any|null, response: UpsertScheduledReportResponse, metadata: grpc.Metadata) => void): void;
  upsertScheduledReport(request: UpsertScheduledReportRequest, metadata: grpc.Metadata, callback: (err: any|null, response: UpsertScheduledReportResponse, metadata: grpc.Metadata) => void): void;

  upsertScheduledReport(request: UpsertScheduledReportRequest, arg1?: grpc.Metadata|((err: any|null, response: UpsertScheduledReportResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: UpsertScheduledReportResponse, metadata: grpc.Metadata) => void): Promise<UpsertScheduledReportResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<UpsertScheduledReportResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__StatsReportManager.UpsertScheduledReport, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  deleteScheduledReport(request: DeleteScheduledReportRequest): Promise<DeleteScheduledReportResponse>;
  deleteScheduledReport(request: DeleteScheduledReportRequest, metadata: grpc.Metadata): Promise<DeleteScheduledReportResponse>;
  deleteScheduledReport(request: DeleteScheduledReportRequest, callback: (err: any|null, response: DeleteScheduledReportResponse, metadata: grpc.Metadata) => void): void;
  deleteScheduledReport(request: DeleteScheduledReportRequest, metadata: grpc.Metadata, callback: (err: any|null, response: DeleteScheduledReportResponse, metadata: grpc.Metadata) => void): void;

  deleteScheduledReport(request: DeleteScheduledReportRequest, arg1?: grpc.Metadata|((err: any|null, response: DeleteScheduledReportResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: DeleteScheduledReportResponse, metadata: grpc.Metadata) => void): Promise<DeleteScheduledReportResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<DeleteScheduledReportResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__StatsReportManager.DeleteScheduledReport, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  activateScheduledReport(request: ActivateScheduledReportRequest): Promise<ActivateScheduledReportResponse>;
  activateScheduledReport(request: ActivateScheduledReportRequest, metadata: grpc.Metadata): Promise<ActivateScheduledReportResponse>;
  activateScheduledReport(request: ActivateScheduledReportRequest, callback: (err: any|null, response: ActivateScheduledReportResponse, metadata: grpc.Metadata) => void): void;
  activateScheduledReport(request: ActivateScheduledReportRequest, metadata: grpc.Metadata, callback: (err: any|null, response: ActivateScheduledReportResponse, metadata: grpc.Metadata) => void): void;

  activateScheduledReport(request: ActivateScheduledReportRequest, arg1?: grpc.Metadata|((err: any|null, response: ActivateScheduledReportResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: ActivateScheduledReportResponse, metadata: grpc.Metadata) => void): Promise<ActivateScheduledReportResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<ActivateScheduledReportResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__StatsReportManager.ActivateScheduledReport, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  listPastReports(request: ListPastReportsRequest): Promise<ListPastReportsResponse>;
  listPastReports(request: ListPastReportsRequest, metadata: grpc.Metadata): Promise<ListPastReportsResponse>;
  listPastReports(request: ListPastReportsRequest, callback: (err: any|null, response: ListPastReportsResponse, metadata: grpc.Metadata) => void): void;
  listPastReports(request: ListPastReportsRequest, metadata: grpc.Metadata, callback: (err: any|null, response: ListPastReportsResponse, metadata: grpc.Metadata) => void): void;

  listPastReports(request: ListPastReportsRequest, arg1?: grpc.Metadata|((err: any|null, response: ListPastReportsResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: ListPastReportsResponse, metadata: grpc.Metadata) => void): Promise<ListPastReportsResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<ListPastReportsResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__StatsReportManager.ListPastReports, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  downloadPastReport(request: DownloadPastReportRequest): Promise<DownloadPastReportResponse>;
  downloadPastReport(request: DownloadPastReportRequest, metadata: grpc.Metadata): Promise<DownloadPastReportResponse>;
  downloadPastReport(request: DownloadPastReportRequest, callback: (err: any|null, response: DownloadPastReportResponse, metadata: grpc.Metadata) => void): void;
  downloadPastReport(request: DownloadPastReportRequest, metadata: grpc.Metadata, callback: (err: any|null, response: DownloadPastReportResponse, metadata: grpc.Metadata) => void): void;

  downloadPastReport(request: DownloadPastReportRequest, arg1?: grpc.Metadata|((err: any|null, response: DownloadPastReportResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: DownloadPastReportResponse, metadata: grpc.Metadata) => void): Promise<DownloadPastReportResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<DownloadPastReportResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__StatsReportManager.DownloadPastReport, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  getPastReport(request: GetPastReportRequest): Promise<GetPastReportResponse>;
  getPastReport(request: GetPastReportRequest, metadata: grpc.Metadata): Promise<GetPastReportResponse>;
  getPastReport(request: GetPastReportRequest, callback: (err: any|null, response: GetPastReportResponse, metadata: grpc.Metadata) => void): void;
  getPastReport(request: GetPastReportRequest, metadata: grpc.Metadata, callback: (err: any|null, response: GetPastReportResponse, metadata: grpc.Metadata) => void): void;

  getPastReport(request: GetPastReportRequest, arg1?: grpc.Metadata|((err: any|null, response: GetPastReportResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: GetPastReportResponse, metadata: grpc.Metadata) => void): Promise<GetPastReportResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<GetPastReportResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__StatsReportManager.GetPastReport, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

}

