import { MingaAppRoutes } from '@app/src/app/types';

import { HasUserGuard } from '@shared/guards';

import { DmConversationCreateComponent } from './components/dm-conversation-create/dm-conversation-create.component';
import { DmConversationPeopleOverlayComponent } from './components/dm-conversation/components/dm-conversation-people-overlay/dm-conversation-people-overlay.component';
import { DmConversationComponent } from './components/dm-conversation/dm-conversation.component';
import { DmListComponent } from './components/dm-list/dm-list.component';
import { DirectMessageComponent } from './direct-message.component';

export enum DirectMessageRoute {
  ROOT = 'messaging',
}

export const DIRECT_MESSAGE_ROUTES: MingaAppRoutes = [
  {
    path: '',
    component: DirectMessageComponent,
    children: [
      {
        path: '',
        pathMatch: 'full',
        component: DmListComponent,
        canActivate: [HasUserGuard],
        data: {
          title: 'List',
          navigationVisibility: false,
          searchScope: 'conversations',
        },
      },
      {
        path: 'create',
        component: DmConversationCreateComponent,
        canActivate: [HasUserGuard],
        data: {
          title: 'Create',
          navigationVisibility: false,
        },
      },
      {
        path: 'c~people/:id',
        component: DmConversationPeopleOverlayComponent,
        canActivate: [HasUserGuard],
        data: {
          title: 'Conversation',
          navigationVisibility: false,
        },
      },
      {
        path: 'c/:id',
        component: DmConversationComponent,
        canActivate: [HasUserGuard],
        data: {
          showInlineAuthorDisplayNames: false,
          showMessageCount: false,
          showExactTimestamps: false,
          enableMessageSearch: false,
        },
      },
      {
        path: 'verbose~c/:id',
        component: DmConversationComponent,
        data: {
          showInlineAuthorDisplayNames: true,
          showMessageCount: true,
          showExactTimestamps: true,
          enableMessageSearch: true,
        },
      },
    ],
  },
];
