import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';

import { TranslateModule } from '@ngx-translate/core';

import { MgButtonModule } from '@app/src/app/button';
import { MgDirectivesModule } from '@app/src/app/directives';
import { MgFormFieldModule } from '@app/src/app/form-field';
import { MgSpinnerModule } from '@app/src/app/spinner';

import { MgEmailComponent, MgEmailSlot_Hint } from './Email.component';
import { MgUniqueEmailDirective } from './MgUniqueEmail.directive';

@NgModule({
  imports: [
    // Minga dependencies
    MgDirectivesModule,
    MgSpinnerModule,
    MgButtonModule,
    MgFormFieldModule,

    // External dependencies
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    TranslateModule.forChild(),
  ],
  declarations: [MgEmailComponent, MgEmailSlot_Hint, MgUniqueEmailDirective],
  exports: [MgEmailComponent, MgEmailSlot_Hint, MgUniqueEmailDirective],
})
export class MgEmailModule {}
