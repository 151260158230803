// GENERATED CODE -- DO NOT EDIT!

import {grpc} from '@improbable-eng/grpc-web';

import * as bell_schedule_bell_schedule_pb from '../bell_schedule/bell_schedule_pb';
import * as period_period_pb from '../period/period_pb';
import * as common_date_pb from '../common/date_pb';

export class BellScheduleManager {
  static readonly serviceName = "minga.bell_schedule.BellScheduleManager";

  static readonly GetBellSchedule = {
    methodName: "GetBellSchedule",
    service: BellScheduleManager,
    requestStream: false,
    responseStream: false,
    requestType: bell_schedule_bell_schedule_pb.GetBellScheduleRequest,
    responseType: bell_schedule_bell_schedule_pb.GetBellScheduleResponse,
  };

  static readonly GetBellSchedules = {
    methodName: "GetBellSchedules",
    service: BellScheduleManager,
    requestStream: false,
    responseStream: false,
    requestType: bell_schedule_bell_schedule_pb.GetBellSchedulesRequest,
    responseType: bell_schedule_bell_schedule_pb.GetBellSchedulesResponse,
  };

  static readonly UpsertBellSchedule = {
    methodName: "UpsertBellSchedule",
    service: BellScheduleManager,
    requestStream: false,
    responseStream: false,
    requestType: bell_schedule_bell_schedule_pb.UpsertBellScheduleRequest,
    responseType: bell_schedule_bell_schedule_pb.UpsertBellScheduleResponse,
  };

  static readonly DeleteBellSchedule = {
    methodName: "DeleteBellSchedule",
    service: BellScheduleManager,
    requestStream: false,
    responseStream: false,
    requestType: bell_schedule_bell_schedule_pb.DeleteBellScheduleRequest,
    responseType: bell_schedule_bell_schedule_pb.DeleteBellScheduleResponse,
  };

  static readonly OverrideBellScheduleForDate = {
    methodName: "OverrideBellScheduleForDate",
    service: BellScheduleManager,
    requestStream: false,
    responseStream: false,
    requestType: bell_schedule_bell_schedule_pb.OverrideBellScheduleForDateRequest,
    responseType: bell_schedule_bell_schedule_pb.OverrideBellScheduleForDateResponse,
  };

}

export class BellScheduleManagerClient {
  getBellSchedule() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  getBellSchedules() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  upsertBellSchedule() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  deleteBellSchedule() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  overrideBellScheduleForDate() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
}
