import { ValidationErrors, ValidatorFn, UntypedFormArray } from '@angular/forms';

import { SCHEDULE_PERIOD_FIELDS } from '../constants/mm-bs-schedules.constants';

export interface PeriodOverlapWarning {
  overlap?: string;
}

export interface PeriodOverlapWarnings {
  [index: number]: PeriodOverlapWarning;
}

export const overlappingPeriodsValidator = (
  formArray: UntypedFormArray,
  allowOverlap: boolean,
): PeriodOverlapWarnings => {
  const warnings: PeriodOverlapWarnings = {};

  const periods = formArray.controls;

  periods
    .filter(period => period.hasError('overlap'))
    .forEach(period => {
      delete period.errors.firstError;
      period.updateValueAndValidity();
    });

  if (periods.length < 2) return null;

  for (let i = 0; i < periods.length; i++) {
    const period = periods[i];
    const startTime = period.get(SCHEDULE_PERIOD_FIELDS.START_TIME).value;
    const endTime = period.get(SCHEDULE_PERIOD_FIELDS.END_TIME).value;

    for (let j = i + 1; j < periods.length; j++) {
      const otherPeriod = periods[j];
      const otherStartTime = otherPeriod.get(
        SCHEDULE_PERIOD_FIELDS.START_TIME,
      ).value;
      const otherEndTime = otherPeriod.get(
        SCHEDULE_PERIOD_FIELDS.END_TIME,
      ).value;

      // Check for overlap logic
      if (startTime < otherEndTime && endTime > otherStartTime) {
        // this line will also set and error on the first period
        // period.setErrors({ ...period.errors, ...overlapError });
        if (allowOverlap) {
          if (!warnings[j]) {
            warnings[j] = {};
          }
          warnings[j].overlap = 'Warning: Overlapping periods detected';
        } else {
          const overlapError = {
            overlap: {
              message: 'Periods cannot overlap. Please enter a valid time',
            },
          };
          otherPeriod.setErrors({
            ...otherPeriod.errors,
            ...overlapError,
          });
        }
      }
    }
  }

  return warnings;
};

export const noOverlappingPeriodsValidator = (): ValidatorFn => {
  return (formArray: UntypedFormArray): ValidationErrors | null => {
    overlappingPeriodsValidator(formArray, false);
    return null;
  };
};
