import { IBannerHeadlineOptions } from 'libs/domain';
import { gateway } from 'libs/generated-grpc-ts';

export namespace IBannerHeadlineOptionsMessageMapper {
  export function toBannerLibraryMessage(
    options: IBannerHeadlineOptions,
  ): gateway.banner_pb.Banner.HeadlineOptions {
    const headlineOptions = new gateway.banner_pb.Banner.HeadlineOptions();
    headlineOptions.setFontColor(options.fontColor);
    headlineOptions.setFontFamily(options.fontFamily);
    if (options.fontWeight) {
      headlineOptions.setFontWeight(options.fontWeight);
    }
    headlineOptions.setOffsetX(options.offsetX);
    headlineOptions.setOffsetY(options.offsetY);
    headlineOptions.setAlign(
      bannerHeadlineAlignSqlEnumToMsgEnum(options.align),
    );
    headlineOptions.setAnchor(
      bannerHeadlineAnchornSqlEnumToMsgEnum(options.anchor),
    );
    headlineOptions.setItalic(options.italic);
    headlineOptions.setMaxFontSize(options.maxFontSize || 3);
    headlineOptions.setMinFontSize(options.minFontSize || 3);
    if (options.lineHeight) {
      headlineOptions.setLineHeight(options.lineHeight);
    } else {
      headlineOptions.setLineHeight(0);
    }

    return headlineOptions;
  }
  export function fromBannerLibraryMessage(
    options: gateway.banner_pb.Banner.HeadlineOptions,
  ): IBannerHeadlineOptions {
    let offsetX = options.getOffsetX() / 100;
    let offsetY = options.getOffsetY() / 100;
    let align = bannerHeadlineAlignMsgEnumToSqlEnum(options.getAlign());
    let anchor = bannerHeadlineAnchorMsgEnumToSqlEnum(options.getAnchor());

    return {
      fontColor: options.getFontColor(),
      fontFamily: options.getFontFamily(),
      fontWeight: options.getFontWeight(),
      offsetX: offsetX,
      offsetY: offsetY,
      italic: options.getItalic(),
      maxFontSize: options.getMaxFontSize() || 3,
      minFontSize: options.getMinFontSize() || 3,
      align: align,
      anchor: anchor,
      lineHeight: options.getLineHeight() || 0,
    };
  }

  export function bannerHeadlineAnchorMsgEnumToSqlEnum(
    anchorEnum: gateway.banner_pb.Banner.HeadlineOptions.Anchor,
  ) {
    switch (anchorEnum) {
      case gateway.banner_pb.Banner.HeadlineOptions.Anchor.TOP_LEFT:
        return 'TOP_LEFT';
      case gateway.banner_pb.Banner.HeadlineOptions.Anchor.TOP_CENTER:
        return 'TOP_CENTER';
      case gateway.banner_pb.Banner.HeadlineOptions.Anchor.TOP_RIGHT:
        return 'TOP_RIGHT';
      case gateway.banner_pb.Banner.HeadlineOptions.Anchor.MID_LEFT:
        return 'MID_LEFT';
      case gateway.banner_pb.Banner.HeadlineOptions.Anchor.MID_CENTER:
        return 'MID_CENTER';
      case gateway.banner_pb.Banner.HeadlineOptions.Anchor.MID_RIGHT:
        return 'MID_RIGHT';
      case gateway.banner_pb.Banner.HeadlineOptions.Anchor.BTM_LEFT:
        return 'BTM_LEFT';
      case gateway.banner_pb.Banner.HeadlineOptions.Anchor.BTM_CENTER:
        return 'BTM_CENTER';
      case gateway.banner_pb.Banner.HeadlineOptions.Anchor.BTM_RIGHT:
        return 'BTM_RIGHT';
    }

    return 'TOP_LEFT';
  }

  export function bannerHeadlineAlignMsgEnumToSqlEnum(
    alignEnum: gateway.banner_pb.Banner.HeadlineOptions.Align,
  ): string {
    switch (alignEnum) {
      case gateway.banner_pb.Banner.HeadlineOptions.Align.CENTER:
        return 'CENTER';
      case gateway.banner_pb.Banner.HeadlineOptions.Align.LEFT:
        return 'LEFT';
      case gateway.banner_pb.Banner.HeadlineOptions.Align.RIGHT:
        return 'RIGHT';
    }

    return 'LEFT';
  }

  export function bannerHeadlineAlignSqlEnumToMsgEnum(
    alignEnum: string,
  ): gateway.banner_pb.Banner.HeadlineOptions.Align {
    switch (alignEnum) {
      case 'LEFT':
        return gateway.banner_pb.Banner.HeadlineOptions.Align.LEFT;
      case 'CENTER':
        return gateway.banner_pb.Banner.HeadlineOptions.Align.CENTER;
      case 'RIGHT':
        return gateway.banner_pb.Banner.HeadlineOptions.Align.RIGHT;
    }

    return gateway.banner_pb.Banner.HeadlineOptions.Align.LEFT;
  }

  export function bannerHeadlineAnchornSqlEnumToMsgEnum(
    anchorEnum: string,
  ): gateway.banner_pb.Banner.HeadlineOptions.Anchor {
    switch (anchorEnum) {
      case 'TOP_LEFT':
        return gateway.banner_pb.Banner.HeadlineOptions.Anchor.TOP_LEFT;
      case 'TOP_CENTER':
        return gateway.banner_pb.Banner.HeadlineOptions.Anchor.TOP_CENTER;
      case 'TOP_RIGHT':
        return gateway.banner_pb.Banner.HeadlineOptions.Anchor.TOP_RIGHT;
      case 'MID_LEFT':
        return gateway.banner_pb.Banner.HeadlineOptions.Anchor.MID_LEFT;
      case 'MID_CENTER':
        return gateway.banner_pb.Banner.HeadlineOptions.Anchor.MID_CENTER;
      case 'MID_RIGHT':
        return gateway.banner_pb.Banner.HeadlineOptions.Anchor.MID_RIGHT;
      case 'BTM_LEFT':
        return gateway.banner_pb.Banner.HeadlineOptions.Anchor.BTM_LEFT;
      case 'BTM_CENTER':
        return gateway.banner_pb.Banner.HeadlineOptions.Anchor.BTM_CENTER;
      case 'BTM_RIGHT':
        return gateway.banner_pb.Banner.HeadlineOptions.Anchor.BTM_RIGHT;
    }

    return gateway.banner_pb.Banner.HeadlineOptions.Anchor.TOP_LEFT;
  }
}
