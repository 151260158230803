import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatMenuModule } from '@angular/material/menu';
import { RouterModule } from '@angular/router';

import { MgIconModule } from '@app/src/app/icon';

import { GenericModule } from '../generic';
import { TextModule } from '../text';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';

@NgModule({
  declarations: [BreadcrumbComponent],
  imports: [
    CommonModule,
    TextModule,
    MgIconModule,
    MatMenuModule,
    RouterModule,
    GenericModule,
    FlexLayoutModule,
  ],
  exports: [BreadcrumbComponent],
})
export class NavigationModule {}
