import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';

import { AppConfigService } from '@app/src/app/minimal/services/AppConfig';

@Injectable({ providedIn: 'root' })
export class LandingService {
  // State

  email = '';
  pin = '';
  mingaName = '';
  personName = '';
  mingaLogo = '';
  passwordToken = '';
  country$: Observable<string | null>;

  private _onLandingContentExpandHeight: BehaviorSubject<boolean>;

  // Computed getters
  get onLandingContentExpandHeight() {
    return this._onLandingContentExpandHeight.asObservable();
  }

  /** Service constructor */
  constructor(appConfigService: AppConfigService) {
    this.country$ = appConfigService.countrySubject.asObservable();
    this._onLandingContentExpandHeight = new BehaviorSubject<boolean>(false);
  }

  public emitLandingContentExpandHeight(ev: boolean) {
    this._onLandingContentExpandHeight.next(ev);
  }
}
