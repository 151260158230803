import { Injectable } from '@angular/core';

import {
  assetservice_ng_grpc_pb,
  assetservice_pb,
  legacy_pb,
} from 'libs/generated-grpc-web';
import { from, Observable, Subject } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

export enum FileUploadStatus {
  /**
   * Pending. In queue.
   */
  PENDING = 0,

  /**
   * Actively Uploading
   */
  UPLOADING = 1,

  /**
   * Done uploading, now in processing
   */
  PROCESSING = 2,

  /**
   * Done uploading and processing
   */
  DONE = 3,

  /**
   * Error. Reason unknown.
   */
  ERROR_UNKNOWN = 4,
}

export const DEFAULT_IMAGE_ACCEPT = 'image/*';

export interface FileUploadState {
  status: FileUploadStatus;
  progress: number;
  assetId: string;
  url?: string;
  errorCode?: assetservice_pb.ResponseCode;
}

const UPLOAD_FILE_CONCURRENCY = 3;
const UPLOAD_CHUNK_SIZE = 512000;
// Upload chunk concurrency per file concurrency
const UPLOAD_CHUNK_CONCURRENCY = 6;

async function blobToArrayBuffer(blob: Blob): Promise<ArrayBuffer> {
  return new Promise<ArrayBuffer>((resolve, reject) => {
    const fileReader = new FileReader();

    fileReader.onload = (e: any) => {
      resolve(e.target.result);
    };

    fileReader.onerror = err => {
      reject(err);
    };

    fileReader.readAsArrayBuffer(blob);
  });
}

@Injectable()
export class FileUploadManager {
  private currentFileUploads: (Observable<FileUploadState> | null)[];
  private pendingFiles: Map<string, File[]>;

  constructor(private assetCrud: assetservice_ng_grpc_pb.AssetCrud) {
    this.currentFileUploads = [];

    for (let i = 0; UPLOAD_FILE_CONCURRENCY > i; ++i) {
      this.currentFileUploads.push(null);
    }

    this.pendingFiles = new Map();
  }

  addPendingFiles(key: string, files: File[]) {
    this.pendingFiles.set(key, files);
  }

  getPendingFiles(key: string) {
    return this.pendingFiles.get(key);
  }

  clearPendingFiles(key: string) {
    this.pendingFiles.delete(key);
  }

  private async _startUpload(
    subject: Subject<FileUploadState>,
    file: Blob,
    accept = DEFAULT_IMAGE_ACCEPT,
    filename: string = '',
  ) {
    const request = new assetservice_pb.NewAssetRequest();
    request.setSize(file.size);
    request.setFilename(filename);
    request.setFileType(file.type);
    request.setInputAccept(accept);

    subject.next({
      status: FileUploadStatus.UPLOADING,
      progress: 0,
      assetId: '',
    });

    let response = await this.assetCrud.newAsset(request).catch(err => {
      console.error(err);
      let response = new assetservice_pb.NewAssetResponse();
      response.setResponseCode(assetservice_pb.ResponseCode.ASSET_FATAL);

      return response;
    });

    let assetId = response.getAssetId();
    let responseCode = response.getResponseCode();

    if (responseCode != assetservice_pb.ResponseCode.ASSET_SUCCESS) {
      subject.next({
        status: FileUploadStatus.ERROR_UNKNOWN,
        progress: 0,
        assetId,
        errorCode: responseCode,
      });

      subject.complete();
      return;
    }

    let uploadId = response.getUploadId();

    let fileSize = file.size;
    let byteOffset = 0;
    let bytesUploaded = 0;
    let chunkIndex = 0;

    let concurrentChunkPromises: Array<Promise<void> | null> = [];
    for (let i = 0; UPLOAD_CHUNK_CONCURRENCY > i; i++) {
      concurrentChunkPromises.push(null);
    }

    while (fileSize > byteOffset) {
      const uploadSingleChunk = async () => {
        let currentChunkIndex = chunkIndex;
        let currentByteOffset = byteOffset;
        byteOffset += UPLOAD_CHUNK_SIZE;
        chunkIndex += 1;
        const chunk = new Uint8Array(
          await blobToArrayBuffer(
            file.slice(
              currentByteOffset,
              currentByteOffset + UPLOAD_CHUNK_SIZE,
            ),
          ),
        );
        const chunkSize = chunk.byteLength;

        let chunkCurrentProgress = 0;
        let intervalCount = 0;

        let interval = setInterval(() => {
          const intervalSize =
            ((chunkSize - chunkCurrentProgress) *
              Math.log10(intervalCount + 1)) /
            100;
          chunkCurrentProgress += intervalSize;

          bytesUploaded += intervalSize;

          subject.next({
            status: FileUploadStatus.UPLOADING,
            progress: bytesUploaded / fileSize,
            assetId,
          });

          intervalCount += 1;
        }, 100);

        let response = await this._uploadChunk(
          currentByteOffset,
          uploadId,
          chunk,
          assetId,
        );

        let responseCode = response.getResponseCode();
        if (
          responseCode ==
          assetservice_pb.AssetChunkConfirmation.ChunkResponseCode.ERROR
        ) {
          let assetResponseCode = response.getAssetResponseCode();

          if (
            assetResponseCode == assetservice_pb.ResponseCode.ASSET_INVALID_TYPE
          ) {
            subject.next({
              status: FileUploadStatus.ERROR_UNKNOWN,
              progress: bytesUploaded / fileSize,
              assetId,
              errorCode: assetResponseCode,
            });
            let err = new Error(`invalid type`);

            subject.complete();
            return Promise.reject(err);
          }
        }

        clearInterval(interval);

        bytesUploaded += chunkSize - chunkCurrentProgress;

        subject.next({
          status: FileUploadStatus.UPLOADING,
          progress: bytesUploaded / fileSize,
          assetId,
        });
      };

      while (fileSize > byteOffset) {
        // the active concurrent chunk count
        const cChunkCount = concurrentChunkPromises.filter(p => !!p).length;

        if (cChunkCount >= UPLOAD_CHUNK_CONCURRENCY) {
          break;
        }

        const uploadPromise = uploadSingleChunk().catch(err => {
          subject.error(err);
        });

        const promiseIndex = concurrentChunkPromises.push(uploadPromise) - 1;

        const unusedPromise = uploadPromise.then(() => {
          concurrentChunkPromises[promiseIndex] = null;
        });
      }

      // Wait until at least one has finished.
      await Promise.race(concurrentChunkPromises.filter(p => !!p));
      if (subject.isStopped) {
        // if stopped, say due to invalid type, break out of the loop
        break;
      }
    }
    // Only continue with all the concurrent chunks if hasn't stopped
    if (!subject.isStopped) {
      await Promise.all(concurrentChunkPromises.filter(p => !!p));
      // @TODO: Separate processing and uploading. They shouldn't have to wait
      // for the previous processing to finish before uploading the next image
      // subject.next({
      //   status: FileUploadStatus.PROCESSING,
      //   progress: 1.0
      // });

      // await this._waitProcessing(uploadId);
      subject.next({
        status: FileUploadStatus.DONE,
        progress: 1.0,
        assetId,
        // @TODO: Fill in this url
        url: '',
      });

      subject.complete();
    }
  }

  private async _uploadChunk(
    byteOffset: number,
    uploadId: number,
    chunk: Uint8Array,
    assetPath: string,
  ) {
    let chunkRequest = new assetservice_pb.AssetChunk();
    chunkRequest.setByteOffset(byteOffset);
    chunkRequest.setUploadId(uploadId);
    chunkRequest.setSourcePart(chunk);
    chunkRequest.setAssetPath(assetPath);

    return await this.assetCrud.uploadChunk(chunkRequest);
  }

  private async _waitProcessing(uploadId) {
    await new Promise(resolve => setTimeout(resolve, 4000));
  }

  /**
   * Uploads file object and returns url
   */
  uploadFile(
    file: File | Blob,
    accept = DEFAULT_IMAGE_ACCEPT,
    filename = (file as any).name || '',
  ): Observable<FileUploadState> {
    let subject = new Subject<FileUploadState>();
    const startAfterCurrentFileUpload = () => {
      let index = this.currentFileUploads.findIndex(v => !v);

      if (index === -1) {
        subject.next({
          status: FileUploadStatus.PENDING,
          progress: 0,
          assetId: '',
        });

        Promise.race(this.currentFileUploads.map(a => a.toPromise())).then(
          () => {
            startAfterCurrentFileUpload();
          },
          err => {
            startAfterCurrentFileUpload();
          },
        );
      } else {
        this.currentFileUploads[index] = subject.asObservable();
        this.currentFileUploads[index].toPromise().then(
          () => {
            this.currentFileUploads[index] = null;
          },
          err => {
            this.currentFileUploads[index] = null;
          },
        );

        this._startUpload(subject, file, accept, filename);
      }
    };

    startAfterCurrentFileUpload();

    return subject.asObservable();
  }

  async fetchAssetSizes(assetId: string) {
    let request = new assetservice_pb.GetAssetRequest();
    request.setAssetId(assetId);

    let response = await this.assetCrud.getAsset(request);
    let sizeMap = response.getAssetSizeMap();
    let sizes: any = {};

    sizeMap.forEach((value, key) => {
      sizes[key] = value.getPath();
    });

    return sizes;
  }

  /**
   * SUPERADMIN only
   */
  processAsset(
    request: assetservice_pb.ProcessAssetRequest,
  ): Observable<assetservice_pb.AssetSize> {
    return from(this.assetCrud.processAsset(request)).pipe(
      switchMap(() => {
        const getAssetRequest = new assetservice_pb.GetAssetRequest();
        getAssetRequest.setAssetId(request.getAssetId());
        return from(this.assetCrud.getAsset(getAssetRequest)).pipe(
          map(resp => {
            const assetSizeMap = resp.getAssetSizeMap();
            return (
              assetSizeMap.get(request.getName()) ||
              new assetservice_pb.AssetSize()
            );
          }),
        );
      }),
    );
  }
}
