export enum TokenStatus {
  VALID = 1,
  EXPIRED = 2,
  UPDATED = 3,
  MINGA_PAUSED = 4,
}

export namespace TokenStatus {
  /**
   * Returns a human readable token status
   */
  export function toString(tokenStatus: TokenStatus): string {
    switch(tokenStatus) {
      // Add cases that return a human readable name for the token status.
      // Example:
      //   case TokenStatus.VALID: return 'Valid Token';

      // Return the TokenStatus key by default
      default: return TokenStatus[tokenStatus];
    }
  }
}
