import {
  ChangeDetectorRef,
  Component,
  ComponentFactory,
  ComponentFactoryResolver,
  ComponentRef,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';

import * as _ from 'lodash';
import { gateway, legacy_pb } from 'libs/generated-grpc-web';

import { ChallengeLongCardComponent } from '@app/src/app/content-views/components/ChallengeLongCard';
import { SmsMessageLongCardComponent } from '@app/src/app/content-views/components/SmsMessageLongCard';
import { RootService } from '@app/src/app/minimal/services/RootService';
import { IOverlayConfig } from '@app/src/app/misc/overlay';

import { AnnouncementLongCardComponent } from '../AnnouncementLongCard/AnnouncementLongCard.component';
import { BirthdayLongCardComponent } from '../BirthdayLongCard/BirthdayLongCard.component';
import { ContentUnavailableComponent } from '../ContentUnavailable/ContentUnavailable.component';
import { EventLongCardComponent } from '../EventLongCard/EventLongCard.component';
import { InspirationLongCardComponent } from '../InspirationLongCard/InspirationLongCard.component';
// View Components
import { LongCardComponent } from '../LongCard/LongCard.component';
import { PollLongCardComponent } from '../PollLongCard/PollLongCard.component';

@Component({
  selector: 'mg-content-view',
  styleUrls: ['./ContentView.component.scss'],
  templateUrl: './ContentView.component.html',
})
export class ContentViewComponent implements OnInit, OnChanges {
  @ViewChild('viewContainer', { read: ViewContainerRef, static: true })
  viewContainer: ViewContainerRef;

  @Input()
  contextHash: string;

  @Input()
  content?: gateway.content_views_pb.ContentView.AsObject;

  private _ngOnInitResolve?: Function;
  private _ngOnInitPromise?: Promise<any>;
  private onOverlayConfig?: (config: IOverlayConfig) => void;
  private onOverlayPreviousUpdate?: () => void;
  private _transparentMobile: boolean = false;
  contentHash: string;
  programContentHash: string;

  private facComp: WeakMap<any, ComponentFactory<any>>;

  hasOverlayPrevious() {
    return {
      icon: 'close',
      text: 'Close',
      xsHideText: this._transparentMobile,
    };
  }

  registerOnOverlayPreviousUpdate(fn) {
    this.onOverlayPreviousUpdate = fn;
  }

  constructor(
    private resolver: ComponentFactoryResolver,
    private route: ActivatedRoute,
    private contentViewsService: gateway.content_views_ng_grpc_pb.ContentViews,
    private rootService: RootService,
    private titleService: Title,
    private _cdr: ChangeDetectorRef,
  ) {
    this.facComp = new WeakMap();

    this._ngOnInitPromise = new Promise(resolve => {
      this._ngOnInitResolve = resolve;
    });

    this.route.params.subscribe(params => {
      if (params.contextHash) {
        this.contextHash = params.contextHash;
        this.fetchContent().then(() => {
          return this.setupContentComponent();
        });
      } else if (params.contentHash) {
        this.contentHash = params.contentHash;
        this.programContentHash = params.programContentHash || '';

        this.fetchContent().then(() => {
          return this.setupContentComponent();
        });
      }
    });
  }

  private async fetchContent() {
    let request = new gateway.content_views_pb.LoadContentRequest();
    let uniqueHash = new legacy_pb.UniqueHash();
    if (this.contextHash) {
      uniqueHash.setValue(this.contextHash);
      request.addContentContextHash(uniqueHash);
    } else {
      uniqueHash.setValue(this.contentHash);
      request.addContentHash(uniqueHash);

      if (this.programContentHash) {
        const programUniqueHash = new legacy_pb.UniqueHash();
        programUniqueHash.setValue(this.programContentHash);
        request.setProgramContentHash(programUniqueHash);
      }
    }

    let response = await this.rootService
      .addLoadingPromise(this.contentViewsService.loadContent(request))
      .catch(async err => {
        await this.makeComponent(ContentUnavailableComponent);
        return Promise.reject(err);
      });

    let status = response.getStatus();

    switch (status) {
      case legacy_pb.StatusCode.OK:
        break;
      default:
        return Promise.reject(new Error('' + status));
    }

    let contentList = response.getContentList();
    if (contentList.length == 0) {
      await this.makeComponent(ContentUnavailableComponent);
      return Promise.reject(
        new Error('Content view fetch content had 0 length'),
      );
    }

    let content = contentList[0].toObject();
    this.content = content;
    this._cdr.markForCheck();
    return this.content;
  }

  private _initCompRefOverlay<T>(compRef: ComponentRef<T>) {
    if (this.onOverlayConfig) {
      this.onOverlayConfig({
        mobileTransparentNav: this._transparentMobile,
        background: (<any>compRef.instance).backgroundColor || '',
      });

      this.onOverlayPreviousUpdate();

      if ('registerOnOverlayConfig' in compRef.instance) {
        let destroyed = false;

        (<any>compRef.instance).registerOnOverlayConfig(
          (config: IOverlayConfig) => {
            if (destroyed) {
              throw new Error(
                'Cannot call OnOverlayConfig on destroyed content view',
              );
            }

            if ('mobileTransparentNav' in config) {
              this._transparentMobile = !!config.mobileTransparentNav;
            }

            this.onOverlayPreviousUpdate();
            return this.onOverlayConfig(config);
          },
        );

        compRef.onDestroy(() => (destroyed = true));
      }
    }
  }

  private async makeComponent<T>(type: new (...args) => T) {
    if (!this.facComp.has(type)) {
      this.facComp.set(type, this.resolver.resolveComponentFactory(type));
    }

    await this._ngOnInitPromise;

    let fac: ComponentFactory<T> = this.facComp.get(type);
    let compRef = this.viewContainer.createComponent(fac);

    this._transparentMobile = !!(<any>compRef.instance).mobileTransparentNav;

    // Fire the component overlay initialization delayed just incase one of the
    // registered callbacks trigger a digest loop. This allows the caller of
    // `makeComponent` to initialize component inputs before a render occurs
    setTimeout(() => {
      this._initCompRefOverlay(compRef);
    });

    return <ComponentRef<T>>compRef;
  }

  registerOnOverlayConfig(fn: (config: IOverlayConfig) => void) {
    this.onOverlayConfig = fn;
  }

  private clearComponent() {
    this.viewContainer.clear();
  }

  async setupContentComponent() {
    let contextHash = _.get(this.content, 'contentContextHash.value', '');

    // Here is where we setup any other components for a content view
    if (this.content.longCard) {
      let longCardCompRef = await this.makeComponent(LongCardComponent);
      longCardCompRef.instance.contextHash = contextHash;
      longCardCompRef.instance.content = this.content.longCard;
      longCardCompRef.instance.contentHash = _.get(
        this.content,
        'contentHash.value',
        '',
      );
      this.titleService.setTitle('View Post');
    } else if (this.content.longEventCard) {
      let longCardCompRef = await this.makeComponent(EventLongCardComponent);
      longCardCompRef.instance.contextHash = contextHash;
      longCardCompRef.instance.content = this.content.longEventCard;
      longCardCompRef.instance.contentHash = _.get(
        this.content,
        'contentHash.value',
        '',
      );
      this.titleService.setTitle('View Event');
    } else if (this.content.longInspirationCard) {
      let longCardCompRef = await this.makeComponent(
        InspirationLongCardComponent,
      );
      longCardCompRef.instance.contextHash = contextHash;
      longCardCompRef.instance.content = this.content.longInspirationCard;
      longCardCompRef.instance.contentHash = _.get(
        this.content,
        'contentHash.value',
        '',
      );
      this.titleService.setTitle('View Full Graphic');
    } else if (this.content.longBirthdayCard) {
      let longCardCompRef = await this.makeComponent(BirthdayLongCardComponent);
      longCardCompRef.instance.contextHash = contextHash;
      longCardCompRef.instance.content = this.content.longBirthdayCard;
      longCardCompRef.instance.contentHash = _.get(
        this.content,
        'contentHash.value',
        '',
      );
      this.titleService.setTitle('View Birthday');
    } else if (this.content.longAnnouncementCard) {
      let longCardCompRef = await this.makeComponent(
        AnnouncementLongCardComponent,
      );
      longCardCompRef.instance.disableActions = true;
      longCardCompRef.instance.contextHash = contextHash;
      longCardCompRef.instance.content = this.content.longAnnouncementCard;
      longCardCompRef.instance.contentHash = _.get(
        this.content,
        'contentHash.value',
        '',
      );
      this.titleService.setTitle('View Announcement');
    } else if (this.content.longPollCard) {
      let longCardCompRef = await this.makeComponent(PollLongCardComponent);
      longCardCompRef.instance.disableActions = true;
      longCardCompRef.instance.contextHash = contextHash;
      longCardCompRef.instance.content = this.content.longPollCard;
      longCardCompRef.instance.contentHash = _.get(
        this.content,
        'contentHash.value',
        '',
      );
      this.titleService.setTitle('View Poll');
    } else if (this.content.longChallengeCard) {
      let longCardCompRef = await this.makeComponent(
        ChallengeLongCardComponent,
      );
      longCardCompRef.instance.contextHash = contextHash;
      longCardCompRef.instance.content = this.content?.longChallengeCard;
      longCardCompRef.instance.contentHash = _.get(
        this.content,
        'contentHash.value',
        '',
      );
      this.titleService.setTitle('View Challenge');
    } else if (this.content!.longSmsMessageCard) {
      let longCardCompRef = await this.makeComponent(
        SmsMessageLongCardComponent,
      );
      longCardCompRef.instance.contextHash = contextHash;
      longCardCompRef.instance.content = this.content!.longSmsMessageCard;
      longCardCompRef.instance.contentHash = _.get(
        this.content,
        'contentHash.value',
        '',
      );
      this.titleService.setTitle('View Challenge');
    } else {
      console.warn(
        '<mg-content-view> could not deduce content component from:',
        this.content,
      );
      this.clearComponent();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.content) {
      this.clearComponent();
      if (this.content) {
        this.setupContentComponent();
      }
    }

    if (changes.contextHash) {
      this.fetchContent();
    }
  }

  ngOnInit(): void {
    this._ngOnInitResolve();
  }
}
