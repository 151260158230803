import {
  Component,
  ElementRef,
  HostListener,
  Inject,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';

import { EventStatus } from 'libs/domain';

import {
  IMgModal,
  MgModalService,
} from '@app/src/app/minimal/services/MgModal';
import { confetti } from '@app/src/app/util/canvasConfetti';

import { IPendingInvitationsDialogOptions } from './types';

/**
 * This dialog will auto open and close a event invitation notice overlay.
 */
@Component({
  selector: 'mg-pending-invitations-dialog',
  templateUrl: './PendingInvitationsDialog.component.html',
  styleUrls: ['./PendingInvitationsDialog.component.scss'],
})
export class PendingInvitationsDialog implements OnInit {
  invitationCount: number = 0;
  innerDialogRef?: IMgModal;

  @ViewChild('invitationsOverlayTemplate', { static: true })
  invitationsOverlayTemplate?: TemplateRef<PendingInvitationsDialog>;

  constructor(
    private dialogRef: MatDialogRef<PendingInvitationsDialog>,
    @Inject(MAT_DIALOG_DATA) public data: IPendingInvitationsDialogOptions,
    private modalService: MgModalService,
    private router: Router,
  ) {
    if (data['invitationCount']) {
      this.invitationCount = data['invitationCount'];
    }
  }

  ngOnInit() {
    this.dialogRef.afterClosed().subscribe(() => {
      this.showDialog();
    });

    this.close();
  }

  async showDialog() {
    if (!this.invitationsOverlayTemplate) {
      throw new Error('this.invitationsOverlayTemplate unset');
    }

    this.innerDialogRef = this.modalService.open(
      this.invitationsOverlayTemplate,
      {
        full: true,
        animation: 'fade',
        backgroundColor: 'rgba(255,255,255,0.95)',
      },
    );

    this.createConfetti();
  }

  close() {
    this.dialogRef.close();
  }

  closeInner() {
    if (this.innerDialogRef) this.innerDialogRef.close();
  }

  gotoEventInvitesSchedule(e: MouseEvent) {
    e.preventDefault();
    e.stopImmediatePropagation();
    e.stopPropagation();

    this.router.navigate(['events', 'schedule', EventStatus.INVITED]);

    this.closeInner();
  }

  createConfetti() {
    if (!this.innerDialogRef) {
      console.error(`createConfetti() failed no dialog ref!`);
    }

    const colors = ['#86BFAC', '#EBD270'];
    var skew = 1;
    var duration = 15 * 1000;
    var animationEnd = Date.now() + duration;

    function randomInRange(min: number, max: number) {
      return Math.random() * (max - min) + min;
    }

    function randomInteger(min: number, max: number) {
      return Math.round(Math.random() * (max - min + 1)) + min;
    }

    const frame = () => {
      var timeLeft = animationEnd - Date.now();
      var ticks = Math.max(200, 500 * (timeLeft / duration));
      skew = Math.max(0.8, skew - 0.001);

      if ((timeLeft = 0)) {
        animationEnd = Date.now() + duration;
      }

      const frameColors = [colors[randomInteger(0, 1)] || colors[0]];

      confetti({
        zIndex: 1001, // to be above the modal
        particleCount: 1,
        startVelocity: 0,
        ticks: ticks,
        origin: {
          x: Math.random(),
          // since particles fall down, skew start toward the top
          y: Math.random() * skew - 0.2,
        },
        colors: frameColors,
        shapes: ['circle'],
        gravity: randomInRange(0.4, 0.9),
        scalar: randomInRange(0.4, 1.5),
        drift: randomInRange(-0.4, 0.4),
      });

      if (!this.innerDialogRef?.closed) {
        requestAnimationFrame(frame);
      }
    };
    // start the show!
    frame();
  }
}
