// GENERATED CODE -- DO NOT EDIT!

import {grpc} from '@improbable-eng/grpc-web';

import * as flex_time_stats_flex_time_stats_pb from '../flex_time_stats/flex_time_stats_pb';
import * as common_date_pb from '../common/date_pb';
import * as report_person_report_person_pb from '../report_person/report_person_pb';

export class FlexTimeStatsManager {
  static readonly serviceName = "minga.flex_time_stats.FlexTimeStatsManager";

  static readonly GetFlexRegistrationReport = {
    methodName: "GetFlexRegistrationReport",
    service: FlexTimeStatsManager,
    requestStream: false,
    responseStream: false,
    requestType: flex_time_stats_flex_time_stats_pb.GetFlexRegistrationReportRequest,
    responseType: flex_time_stats_flex_time_stats_pb.GetFlexRegistrationReportResponse,
  };

  static readonly GetPeriodUnregisteredReport = {
    methodName: "GetPeriodUnregisteredReport",
    service: FlexTimeStatsManager,
    requestStream: false,
    responseStream: false,
    requestType: flex_time_stats_flex_time_stats_pb.GetPeriodUnregisteredReportRequest,
    responseType: flex_time_stats_flex_time_stats_pb.GetPeriodUnregisteredReportResponse,
  };

  static readonly ExportFlexReport = {
    methodName: "ExportFlexReport",
    service: FlexTimeStatsManager,
    requestStream: false,
    responseStream: true,
    requestType: flex_time_stats_flex_time_stats_pb.ExportFlexReportRequest,
    responseType: flex_time_stats_flex_time_stats_pb.ExportFlexReportChunk,
  };

}

export class FlexTimeStatsManagerClient {
  getFlexRegistrationReport() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  getPeriodUnregisteredReport() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  exportFlexReport() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
}
