import { Injectable } from '@angular/core';

import { flatten } from 'lodash';
import { forkJoin, Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { ListMembershipService } from '@app/src/app/services/ListMembership';

import { OptionItem } from '@shared/components/form/components/form-grouped-select/form-grouped-select.types';
import { UserListCategory } from '@shared/components/user-list-filter';
import { CacheService } from '@shared/services/cache/cache.service';
import { CacheKey } from '@shared/services/cache/cache.types';
import { createCacheCollection } from '@shared/services/cache/cache.utils';
import { FlexTimeRegistrationService } from '@shared/services/flex-time';

import { Student } from '../types/tt-my-class.types';
import { mapPersonToStudent } from './my-class.utils';

@Injectable()
export class MyClassLists {
  private _listFetchers: Record<
    UserListCategory,
    (list: OptionItem) => Observable<Student[]>
  > = {
    [UserListCategory.ALL]: list => this._getMembersForList(list),
    [UserListCategory.ALL_CURRENT_TERM]: list => this._getMembersForList(list),
    [UserListCategory.MY_LISTS]: list => this._getMembersForList(list),
    [UserListCategory.MY_LISTS_CURRENT_TERM]: list =>
      this._getMembersForList(list),
    [UserListCategory.TODAYS_FLEX_ACTIVITIES]: list =>
      this._getMembersForFlexActivity(list),
    [UserListCategory.TODAYS_FLEX_ACTIVITIES_MINE]: list =>
      this._getMembersForFlexActivity(list),
  };

  private _cachedLists = createCacheCollection(key =>
    this._cacheService.create<Student[]>(
      `${CacheKey.MY_CLASS_LIST_MEMBERS}:${key}`,
      async listId => {
        const users = await this._listService.getMembersOfList(listId);
        return users.map(mapPersonToStudent);
      },
      {
        ttl: 60,
      },
    ),
  );

  private _cachedFlexLists = createCacheCollection(key =>
    this._cacheService.create<Student[]>(
      `${CacheKey.MY_CLASS_FLEX_LIST_MEMBERS}:${key}`,
      async activityId => {
        const registrants = await this._ftRegistration.fetchRegisteredPeople(
          activityId,
        );

        return registrants.map(mapPersonToStudent);
      },
      {
        ttl: 60,
      },
    ),
  );

  constructor(
    private _cacheService: CacheService,
    private _listService: ListMembershipService,
    private _ftRegistration: FlexTimeRegistrationService,
  ) {}

  public fetchMembersForLists(lists: OptionItem[]): Observable<Student[]> {
    if (!lists || lists.length === 0) {
      return of([]);
    }

    return forkJoin(
      lists
        .filter(list => this._listFetchers[list.category.value])
        .map(
          list =>
            this._listFetchers[list.category.value](list) as Observable<
              Student[]
            >,
        ),
    ).pipe(switchMap(results => of(flatten(results))));
  }

  private _getMembersForList(list: OptionItem): Observable<Student[]> {
    const listId = list.value;
    const cacheItem = this._cachedLists.get(listId);
    return cacheItem.get(listId);
  }

  private _getMembersForFlexActivity(list: OptionItem): Observable<Student[]> {
    const activityId = list.data.id;
    const cacheItem = this._cachedFlexLists.get(activityId);
    return cacheItem.get(activityId);
  }
}
