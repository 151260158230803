import { Inject, Injectable } from '@angular/core';

import { select, Store } from '@ngrx/store';
import Fuse from 'fuse.js';
import { IConversationWithReadStatus, IMessage } from 'libs/domain';
import {
  gateway,
  messaging_ng_grpc_pb,
  messaging_pb,
} from 'libs/generated-grpc-web';
import { RealtimeDirectMessageConsumer } from 'libs/realtime';
import { BehaviorSubject, combineLatest, from, Observable } from 'rxjs';
import { filter, map, mergeMap, take } from 'rxjs/operators';

import { FirebaseMessaging } from '@app/src/app/firebase/messaging';
import { AuthInfoService } from '@app/src/app/minimal/services/AuthInfo';
import { Person } from '@app/src/app/people';
import { IFuseMatchesResult } from '@app/src/app/util/fuse';

import { MessagingActions } from './actions';
import { selectMessagingStore } from './selectors';
import { MessagingState } from './state';

export interface IKeywordMatchedMessage {
  message: IMessage;
  keywords: string[];
}

export interface IMessageSearchWithCountResult<T> {
  readonly messages: T[];
  totalCount: number;
}

export interface IConversationsWithPeople {
  conversation:
    | IConversationWithReadStatus
    | Readonly<IConversationWithReadStatus>;
  people: Person[];
}

@Injectable({ providedIn: 'root' })
export class MessagingFacade {
  /** @internal */
  private lastTempMessageId: number = 0;
  private messaging$: Observable<MessagingState>;

  hasUnreadMessages$: Observable<boolean>;
  hasUnreadMessagesObs$: Observable<boolean>;
  hasUnreadMessagesBS$ = new BehaviorSubject(false);

  constructor(
    private store: Store,
    private authInfoService: AuthInfoService,
    private messagingProto: messaging_ng_grpc_pb.Messaging,
    @Inject(RealtimeDirectMessageConsumer)
    realtime: RealtimeDirectMessageConsumer,
    firebaseMessaging: FirebaseMessaging,
  ) {
    this.messaging$ = store.pipe(select(selectMessagingStore));
    realtime.newMessage$.subscribe(async newDirectMessage => {
      const receivedDate = new Date(newDirectMessage.timestamp);
      const now = new Date();
      const createdDate = receivedDate < now ? receivedDate : now;
      const conversationId = newDirectMessage.conversationId;

      try {
        const conversation = await this.getConversationNoFetch(conversationId)
          .pipe(take(1))
          .toPromise();

        if (!conversation) {
          this.getConversation(conversationId);
        }
      } finally {
        this.store.dispatch(
          MessagingActions.receiveNewMessage({
            conversationId,
            newMessage: {
              id: newDirectMessage.messageId,
              body: newDirectMessage.body,
              attachmentList: newDirectMessage.attachmentList,
              authorPersonHash: newDirectMessage.authorPersonHash,
              statusMap: new Map(),
              createdDate,
            },
          }),
        );
      }
    });

    const conversationNotificationClicked$ =
      firebaseMessaging.notificationClicked$.pipe(
        map(msg => msg.data.conversationId),
        filter(conversationId => !!conversationId),
      );

    conversationNotificationClicked$.subscribe(conversationId =>
      this.store.dispatch(
        MessagingActions.clickNewMessageNotification({ conversationId }),
      ),
    );

    this.hasUnreadMessagesObs$ = this.messaging$.pipe(
      map(state => {
        if (state.conversations.fetching || state.conversations.partial) {
          return false;
        }

        const convos = MessagingState.selectConversationsList(state);
        for (const convo of convos) {
          if (!convo.readStatus) {
            return true;
          }
        }

        return false;
      }),
    );
    this.hasUnreadMessages$ = combineLatest([
      this.hasUnreadMessagesBS$,
      this.hasUnreadMessagesObs$,
    ]).pipe(
      map(([behav, obs]) => {
        return behav || obs;
      }),
    );
  }

  private static _getDefaultFuseOptions(): Fuse.IFuseOptions<
    Readonly<IMessage>
  > {
    return {
      isCaseSensitive: false,
      shouldSort: true,
      keys: ['body'],
      threshold: 0.4,
    };
  }

  sendMessage(
    conversationId: number,
    bodyText: string,
    assetPathList: string[] = [],
  ) {
    const authInfo = this.authInfoService.authInfo;
    const authorPersonHash = authInfo?.person?.personHash || '';
    this.store.dispatch(
      MessagingActions.sendMessage({
        conversationId,
        tempMessageId: --this.lastTempMessageId,
        authorPersonHash,
        bodyText,
        assetPathList,
      }),
    );
  }

  getConversation(
    conversationId: number,
  ): Observable<Readonly<IConversationWithReadStatus>> {
    this.store.dispatch(MessagingActions.fetchConversation({ conversationId }));
    return this.messaging$.pipe(
      map(state => MessagingState.selectConversation(conversationId)(state)),
      filter(conversation => typeof conversation !== 'undefined'),
      map(state => state as IConversationWithReadStatus),
    );
  }

  getConversations(): Observable<
    ReadonlyArray<Readonly<IConversationWithReadStatus>>
  > {
    this.store.dispatch(MessagingActions.fetchConversations({}));
    return this.messaging$.pipe(
      map(state => ({
        fetching: state.conversations.fetching,
        partial: state.conversations.partial,
        conversations: MessagingState.selectConversationsList(state),
      })),
      filter(({ fetching, partial, conversations }) => {
        if (typeof conversations === 'undefined') return false;
        return !fetching || (!partial && conversations.length > 0);
      }),
      map(
        ({ conversations }) => conversations as IConversationWithReadStatus[],
      ),
    );
  }

  getMessages(
    conversationId: number,
  ): Observable<ReadonlyArray<Readonly<IMessage>>> {
    this.store.dispatch(MessagingActions.fetchMessages({ conversationId }));
    return this.messaging$.pipe(
      map(state => ({
        fetching: MessagingState.selectMessagesFetching(conversationId)(state),
        messages: MessagingState.selectMessages(conversationId)(state),
      })),
      filter(({ fetching, messages }) => {
        if (typeof messages === 'undefined') return false;
        return !fetching || messages.length > 0;
      }),
      map(({ messages }) => messages as IMessage[]),
    );
  }

  /**
   * Start a conversation with participants. If there is a conversation with
   * provided participants the existing conversation will open.
   */
  startConversation(
    participants: string[],
  ): Observable<Readonly<IConversationWithReadStatus>> {
    const request = new messaging_pb.MessagingStartConversationRequest();
    request.setParticipantList(participants);
    return from(this.messagingProto.startConversation(request)).pipe(
      mergeMap(resp => this.getConversation(resp.getNewConversationId())),
    );
  }

  markMessageAsRead(messageId: number) {
    this.store.dispatch(MessagingActions.markMessageAsRead({ messageId }));
  }

  markConversationAsRead(conversationId: number) {
    const personHash = this.authInfoService.authPersonHash;
    this.store.dispatch(
      MessagingActions.markConversationAsRead({ conversationId, personHash }),
    );
  }

  private getConversationNoFetch(
    conversationId: number,
  ): Observable<Readonly<IConversationWithReadStatus> | null> {
    return this.store.pipe(
      select(selectMessagingStore),
      select(MessagingState.selectConversation(conversationId)),
      map(conv => conv || null),
    );
  }

  /**
   * From a fuse search result for a message within a conversation, find and
   * return the longest string keywords for the matches found.
   * @param fuseMatches
   * @param item
   */
  private _getMessageSearchSubstrings(
    fuseMatches: IFuseMatchesResult[],
    item: Readonly<IMessage>,
  ): string[] {
    const bodyTextSubstrings: string[] = [];

    for (let fuseMatch of fuseMatches) {
      const arrayIndex = fuseMatch.refIndex;
      // get substring from display of the correct index from the match data
      let maxLength = 0;
      let maxIndex = 0;
      // find greatest length match for highlighting
      for (let i = 0; i < fuseMatch.indices.length; i++) {
        const index = fuseMatch.indices[i];
        if (index.length >= 2) {
          const length = index[1] - index[0];
          if (length > maxLength) {
            maxLength = length;
            maxIndex = i;
          }
        }
      }

      const bodyText = item.body;
      const start = fuseMatch.indices[maxIndex][0];
      const end = fuseMatch.indices[maxIndex][1];

      if (bodyText.length >= end - 1) {
        const substring = bodyText.slice(start, end + 1);
        bodyTextSubstrings.push(substring);
      }
    }
    return bodyTextSubstrings;
  }

  /**
   * Search messages within a conversation. Does not include matches.
   * @param conversationId
   * @param searchText
   */
  searchMessages(
    conversationId: number,
    searchText: string,
  ): Observable<Readonly<IMessageSearchWithCountResult<IMessage>>> {
    const options = MessagingFacade._getDefaultFuseOptions();
    options.includeMatches = false;

    return this.getMessages(conversationId).pipe(
      map(items => {
        const fuse = new Fuse(items, options);
        const fuseResult = <any>fuse.search(searchText);
        return {
          totalCount: items.length,
          messages: fuseResult,
        };
      }),
    );
  }

  /**
   * Search messages within a conversation. By matches making
   * the return type array of IKeywordMatchedMessage within result's messages.
   * @param conversationId
   * @param searchText
   */
  searchMessagesIncludingMatches(
    conversationId: number,
    searchText: string,
  ): Observable<
    Readonly<IMessageSearchWithCountResult<IKeywordMatchedMessage>>
  > {
    const options = MessagingFacade._getDefaultFuseOptions();
    options.includeMatches = true;

    return this.getMessages(conversationId).pipe(
      map(items => {
        const fuse = new Fuse(items, options);
        const fuseResult = <any>fuse.search(searchText);
        // only return matches with keyword strings
        const messages = fuseResult
          .map((result: any) => {
            const keywords = this._getMessageSearchSubstrings(
              result.matches,
              result.item,
            );
            if (keywords.length) {
              return {
                message: result.item,
                keywords,
              };
            }
            return null;
          })
          .filter((result: any) => !!result);
        return {
          totalCount: items.length,
          messages,
        };
      }),
    );
  }

  async checkForAnyUnread() {
    const res = await this.messagingProto.checkForAnyUnread(
      new messaging_pb.MessagingCheckUnreadRequest(),
    );
    this.updateAnyUnreadStatus(res.getUnread());
    return res.getUnread();
  }

  updateAnyUnreadStatus(status: boolean) {
    this.hasUnreadMessagesBS$.next(status);
  }
}
