import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';

import { IHallPass, IHallPassValidationError } from 'libs/domain';
import { hall_pass_pb } from 'libs/generated-grpc-web';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { map, takeUntil, takeWhile } from 'rxjs/operators';

import { RootService } from '@app/src/app/minimal/services/RootService';

import { KioskAudioService, KioskService } from '@modules/kiosk/services';
import { KioskHallPassTableService } from '@modules/kiosk/services/kiosk-hall-pass-table.service';
import { DEFAULT_STAFF_PASS_DURATION } from '@modules/selection-assigner';

import {
  SystemAlertModalService,
  SystemAlertModalType,
} from '@shared/components/system-alert-modal';
import { SystemAlertSnackBarService } from '@shared/components/system-alert-snackbar';
import { getAppColor } from '@shared/constants';
import { HallPassService } from '@shared/services/hall-pass';
import { KioskPermissionsService } from '@shared/services/kiosk/kiosk-permissions.service';
import { MediaService } from '@shared/services/media';

import {
  CREATE_HALL_PASS_ERROR_MESSAGE_MAP,
  KioskHallPassMessage,
} from '../../constants';
import { KioskHallPassService } from '../../services';
import {
  KioskHallPassFormSteps,
  KioskHallPassSummaryData,
  KioskHallPassTypeData,
  KioskType,
} from '../../types';
import { KioskFormAbstractComponent } from '../../utils';
import { KioskSummaryComponent } from '../common/kiosk-summary/kiosk-summary.component';

@Component({
  selector: 'mg-kiosk-hall-pass',
  templateUrl: './kiosk-hall-pass.component.html',
  styleUrls: ['./kiosk-hall-pass.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [KioskHallPassService, KioskHallPassTableService],
})
export class KioskHallPassComponent
  extends KioskFormAbstractComponent<
    KioskHallPassFormSteps,
    KioskHallPassTypeData
  >
  implements OnInit
{
  @ViewChild(KioskSummaryComponent) kioskSummary: KioskSummaryComponent;

  // Constants

  public readonly MSG = KioskHallPassMessage;

  // we need to get all hall pass types since the hall pass table might display
  // types that aren't available in the kiosk still
  public readonly hallPassTypes$ = this._hallPassService.getHallPassTypes(
    true,
    true,
  );

  private _loadingHallPassTable = new BehaviorSubject<boolean>(false);
  public readonly loadingHallPassTable$ =
    this._loadingHallPassTable.asObservable();

  // all types available in the kiosk
  public readonly allTypes$ = this.fetchTypes();
  private readonly _existingPassSubject = new BehaviorSubject<IHallPass>(null);
  public readonly existingPass$ = this._existingPassSubject.asObservable().pipe(
    takeUntil(this._destroyedSubject),
    map(pass => {
      return this._kioskHallPass.hallPassToSummary(pass);
    }),
  );

  private readonly _summaryData = new BehaviorSubject<KioskHallPassSummaryData>(
    null,
  );
  public readonly summaryData$ = this._summaryData.asObservable();

  public readonly remaining$ = this.kiosk.tickerInSeconds$.pipe(
    takeUntil(this._destroyedSubject),
    map(v => (v ?? 0) * 1000),
  );

  public activeHallPasses$ = combineLatest([
    this.hallPassTypes$,
    this.kioskHallPassTable.activePasses$,
  ]).pipe(
    map(([passTypeData, activePasses]) => {
      return activePasses.map(p => {
        const typeInfo = passTypeData.find(type => type.id === p.typeId);
        return {
          id: p.id,
          status: p.status,
          filename: p.recipientPersonView.filename,
          recipientName: p.recipientPersonView.displayName,
          passName: typeInfo ? typeInfo.name : '',
          passIcon: typeInfo ? typeInfo.bannerHash : '',
          passColor: typeInfo ? typeInfo.color : getAppColor('surface'),
          startTime: p.status.start,
          manuallyEndPass: typeInfo ? typeInfo.manuallyEndPass : false,
        };
      });
    }),
  );

  private _alreadyFetched = false;

  // Component constructor

  constructor(
    public kiosk: KioskService,
    public router: Router,
    public media: MediaService,
    public kioskSound: KioskAudioService,
    public root: RootService,
    public systemSnack: SystemAlertSnackBarService,
    public systemAlert: SystemAlertModalService,
    public kioskPermissions: KioskPermissionsService,
    public kioskHallPassTable: KioskHallPassTableService,
    private _kioskHallPass: KioskHallPassService,
    private _hallPassService: HallPassService,
    private _cdr: ChangeDetectorRef,
  ) {
    super(kiosk, router);
  }

  ngOnInit(): void {
    this.updateAvailableTypes();

    // make sure table data is only fetched once and only if toggle is on
    this.kioskHallPassTable.enableTable$
      .pipe(
        takeUntil(this._destroyedSubject),
        takeWhile(() => !this._alreadyFetched),
      )
      .subscribe(isEnabled => {
        if (isEnabled) {
          this._fetchTableData();
          this._alreadyFetched = true;
        }
      });
  }

  onDestroyed() {
    this._summaryData.complete();
    this._existingPassSubject.complete();
  }

  onReset() {
    this._summaryData.next(null);
    this._existingPassSubject.next(null);
  }

  public fetchTypes() {
    return this.loadingObservableWrapper(this.hallPassTypes$).pipe(
      takeUntil(this._destroyedSubject),
      map(types => {
        return types
          .sort((a, b) => {
            if (a.priority < b.priority) return -1;
            if (a.priority > b.priority) return 1;
            return 0;
          })
          .filter(type => type.availableInKiosk && !type.requireTeacherApproval)
          .map((type): KioskType<KioskHallPassTypeData> => {
            return {
              id: type.id,
              name: type.name,
              icon: type.bannerHash,
              color: type.color,
              data: {
                defaultHallPassTime: type.defaultHallPassTime,
                manuallyEndPass: type.manuallyEndPass,
              },
            };
          });
      }),
    );
  }

  public async handleUserTypeSelection(
    selection: KioskType<KioskHallPassTypeData>[],
  ) {
    if (!selection.length) return;
    const [type] = selection;
    this.setSelectedType(type);
    await this.assign();
  }

  public async assign(person = this.getPerson()) {
    try {
      this.setAssignmentInProgress(true);
      this.setPerson(person);
      const type = this.getSelectedType();
      const activePasses = await this._kioskHallPass.fetchPassesForPerson(
        person.personHash,
      );
      if (activePasses) {
        this._existingPassSubject.next(activePasses);
        this.setFormStep('existing-pass');
        return;
      } else if (!type) {
        this.setFormStep('type-selector');
        return;
      }
      const duration =
        type.data.defaultHallPassTime || DEFAULT_STAFF_PASS_DURATION;
      const { errors, passes } = await this.root.addLoadingPromise(
        this._kioskHallPass.createPass(type.id, person, duration),
      );
      if (errors.length) {
        await this._handleRestrictionsErrorList(errors);
      } else {
        await this._handleCreatedNewPass(passes[0], type);
      }
    } catch (error) {
      this.reset();
      this.systemSnack.error('Error assigning hall pass: ' + error?.message);
    } finally {
      this.setAssignmentInProgress(false);
    }
  }

  public async end() {
    try {
      this.kioskSummary.stopTimer();
      const existingPass = this._existingPassSubject.getValue();
      if (!existingPass) throw new Error('No active pass found in state');
      await this.root.addLoadingPromise(
        this._kioskHallPass.endPass(
          existingPass.id,
          existingPass.recipientPersonViewMinimal?.personHash,
        ),
      );
      this.kioskHallPassTable.removePass(existingPass.id);
      this.setFormStep('pass-ended');
    } catch (error) {
      this.systemSnack.error(
        'Sorry, could not end hall pass: ' + error?.message,
      );
    }
  }

  // Private methods

  private async _handleCreatedNewPass(
    pass: hall_pass_pb.HallPassWithType.AsObject,
    type: KioskType<KioskHallPassTypeData>,
  ) {
    const summary = this._kioskHallPass.hallPassToSummary(pass, type);
    this.kioskHallPassTable.addPass(pass);

    this._summaryData.next(summary);
    await this.kioskSound.play('success');
    this.setFormStep('summary');
    this.startAutoClose();
  }

  public async _handleRestrictionsErrorList(
    errors: IHallPassValidationError[],
  ) {
    const firstError = errors[0];
    const response = {
      existingPass: null,
    };
    this.kioskSound.play('error');
    const composedErrorMessage = (
      CREATE_HALL_PASS_ERROR_MESSAGE_MAP[firstError.error] ?? ''
    )
      .replace('{passName}', firstError.hallPassName)
      .replace('{errorCode}', firstError.error.toString()) as string;
    this.systemAlert.open({
      modalType: SystemAlertModalType.ERROR,
      heading: KioskHallPassMessage.ERROR_GENERIC_HALL_PASS_TITLE,
      message: composedErrorMessage,
      subMessage: KioskHallPassMessage.ERROR_GENERIC_HALL_PASS_SUB_MESSAGE,
    });

    this.reset();
    return response;
  }

  private async _fetchTableData() {
    try {
      this._loadingHallPassTable.next(true);
      await this.kioskHallPassTable.fetchActivePasses();
    } catch (error) {
      // lets just swallow this, not super crucial if the table doesn't load
    } finally {
      this._loadingHallPassTable.next(false);
    }
  }
}
