import { IHallPassType } from 'libs/domain';

export interface HpmTypesEditData {
  original: IHallPassType;
  changes: IHallPassType;
  isLoading: boolean;
}

export interface HpmTypesEditDialogData {
  type: 'new' | 'existing';
  data: IHallPassType | number;
}

export const ENDING_METHOD_OPTIONS = [
  {
    label: 'Automatic',
    value: false,
  },
  {
    label: 'Manual',
    value: true,
  },
];
