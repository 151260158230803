// source: flex_time/flex_time.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

import * as jspb from 'google-protobuf';
import * as common_date_pb from '../common/date_pb';
import * as image_image_pb from '../image/image_pb';
import * as restriction_restriction_pb from '../restriction/restriction_pb';
import * as misc_category_pb from '../misc/category_pb';
export namespace FlexTimePeriod {
  export interface AsObject {
    id: number;
    mingaId: number;
    title: string;
    date?: common_date_pb.DateTime.AsObject;
    startTime: string;
    endTime: string;
    enableActivityManagement: boolean;
    enableStudentManagement: boolean;
    enableStudentSelfManagement: boolean;
    activityInstancesList: FlexTimeActivityInstance.AsObject[];
    totalSpaces: number;
  }
}
export class FlexTimePeriod extends jspb.Message {
  static readonly displayName = "FlexTimePeriod";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [10];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, FlexTimePeriod.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): FlexTimePeriod.AsObject {
    return FlexTimePeriod.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!FlexTimePeriod} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: FlexTimePeriod) {
    var f: any;
    var obj: any = {
      id: jspb.Message.getFieldWithDefault(msg, 1, 0),
      mingaId: jspb.Message.getFieldWithDefault(msg, 2, 0),
      title: jspb.Message.getFieldWithDefault(msg, 3, ""),
      date: (f = msg.getDate()) && common_date_pb.DateTime.toObject(includeInstance, f),
      startTime: jspb.Message.getFieldWithDefault(msg, 5, ""),
      endTime: jspb.Message.getFieldWithDefault(msg, 6, ""),
      enableActivityManagement: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
      enableStudentManagement: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
      enableStudentSelfManagement: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
      activityInstancesList: jspb.Message.toObjectList(msg.getActivityInstancesList(),
      FlexTimeActivityInstance.toObject, includeInstance),
      totalSpaces: jspb.Message.getFieldWithDefault(msg, 11, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!FlexTimePeriod}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new FlexTimePeriod;
    return FlexTimePeriod.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!FlexTimePeriod} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!FlexTimePeriod}
   */
  static deserializeBinaryFromReader(msg: FlexTimePeriod, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {number} */ (reader.readInt32());
        msg.setId(value1);
        break;
      case 2:
        var value2 = /** @type {number} */ (reader.readInt32());
        msg.setMingaId(value2);
        break;
      case 3:
        var value3 = /** @type {string} */ (reader.readString());
        msg.setTitle(value3);
        break;
      case 4:
        var value4 = new common_date_pb.DateTime;
        reader.readMessage(value4,common_date_pb.DateTime.deserializeBinaryFromReader);
        msg.setDate(value4);
        break;
      case 5:
        var value5 = /** @type {string} */ (reader.readString());
        msg.setStartTime(value5);
        break;
      case 6:
        var value6 = /** @type {string} */ (reader.readString());
        msg.setEndTime(value6);
        break;
      case 7:
        var value7 = /** @type {boolean} */ (reader.readBool());
        msg.setEnableActivityManagement(value7);
        break;
      case 8:
        var value8 = /** @type {boolean} */ (reader.readBool());
        msg.setEnableStudentManagement(value8);
        break;
      case 9:
        var value9 = /** @type {boolean} */ (reader.readBool());
        msg.setEnableStudentSelfManagement(value9);
        break;
      case 10:
        var value10 = new FlexTimeActivityInstance;
        reader.readMessage(value10,FlexTimeActivityInstance.deserializeBinaryFromReader);
        msg.addActivityInstances(value10);
        break;
      case 11:
        var value11 = /** @type {number} */ (reader.readInt32());
        msg.setTotalSpaces(value11);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!FlexTimePeriod} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: FlexTimePeriod, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getId();
    if (f !== 0) {
      writer.writeInt32(
        1,
        f
      );
    }
    f = message.getMingaId();
    if (f !== 0) {
      writer.writeInt32(
        2,
        f
      );
    }
    f = message.getTitle();
    if (f.length > 0) {
      writer.writeString(
        3,
        f
      );
    }
    f = message.getDate();
    if (f != null) {
      writer.writeMessage(
        4,
        f,
        common_date_pb.DateTime.serializeBinaryToWriter
      );
    }
    f = message.getStartTime();
    if (f.length > 0) {
      writer.writeString(
        5,
        f
      );
    }
    f = message.getEndTime();
    if (f.length > 0) {
      writer.writeString(
        6,
        f
      );
    }
    f = message.getEnableActivityManagement();
    if (f) {
      writer.writeBool(
        7,
        f
      );
    }
    f = message.getEnableStudentManagement();
    if (f) {
      writer.writeBool(
        8,
        f
      );
    }
    f = message.getEnableStudentSelfManagement();
    if (f) {
      writer.writeBool(
        9,
        f
      );
    }
    f = message.getActivityInstancesList();
    if (f.length > 0) {
      writer.writeRepeatedMessage(
        10,
        f,
        FlexTimeActivityInstance.serializeBinaryToWriter
      );
    }
    f = message.getTotalSpaces();
    if (f !== 0) {
      writer.writeInt32(
        11,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    FlexTimePeriod.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional int32 id = 1;
   * @return {number}
   */
  getId(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
  };


  /** @param {number} value */
  setId(value: number) {
    jspb.Message.setProto3IntField(this, 1, value);
  }


  /**
   * optional int32 minga_id = 2;
   * @return {number}
   */
  getMingaId(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
  };


  /** @param {number} value */
  setMingaId(value: number) {
    jspb.Message.setProto3IntField(this, 2, value);
  }


  /**
   * optional string title = 3;
   * @return {string}
   */
  getTitle(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
  };


  /** @param {string} value */
  setTitle(value: string) {
    jspb.Message.setProto3StringField(this, 3, value);
  }


  /**
   * optional minga.common.DateTime date = 4;
   * @return {?DateTime}
   */
  getDate(): common_date_pb.DateTime {
    return /** @type{?DateTime} */ (
      jspb.Message.getWrapperField(this, common_date_pb.DateTime, 4));
  }


  /** @param {?DateTime|undefined} value */
  setDate(value?: common_date_pb.DateTime) {
    jspb.Message.setWrapperField(this, 4, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearDate() {
    this.setDate(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasDate(): boolean {
    return jspb.Message.getField(this, 4) != null;
  }


  /**
   * optional string start_time = 5;
   * @return {string}
   */
  getStartTime(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
  };


  /** @param {string} value */
  setStartTime(value: string) {
    jspb.Message.setProto3StringField(this, 5, value);
  }


  /**
   * optional string end_time = 6;
   * @return {string}
   */
  getEndTime(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
  };


  /** @param {string} value */
  setEndTime(value: string) {
    jspb.Message.setProto3StringField(this, 6, value);
  }


  /**
   * optional bool enable_activity_management = 7;
   * @return {boolean}
   */
  getEnableActivityManagement(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
  };


  /** @param {boolean} value */
  setEnableActivityManagement(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 7, value);
  }


  /**
   * optional bool enable_student_management = 8;
   * @return {boolean}
   */
  getEnableStudentManagement(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
  };


  /** @param {boolean} value */
  setEnableStudentManagement(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 8, value);
  }


  /**
   * optional bool enable_student_self_management = 9;
   * @return {boolean}
   */
  getEnableStudentSelfManagement(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
  };


  /** @param {boolean} value */
  setEnableStudentSelfManagement(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 9, value);
  }


  /**
   * repeated FlexTimeActivityInstance activity_instances = 10;
   * @return {!Array<!FlexTimeActivityInstance>}
   */
  getActivityInstancesList(): FlexTimeActivityInstance[] {
    return /** @type{!Array<!FlexTimeActivityInstance>} */ (
      jspb.Message.getRepeatedWrapperField(this, FlexTimeActivityInstance, 10));
  }


  /** @param {!Array<!FlexTimeActivityInstance>} value */
  setActivityInstancesList(value?: FlexTimeActivityInstance[]) {
    jspb.Message.setRepeatedWrapperField(this, 10, value);
  }


  /**
   * @param {!FlexTimeActivityInstance=} opt_value
   * @param {number=} opt_index
   * @return {!FlexTimeActivityInstance}
   */
  addActivityInstances(opt_value?: FlexTimeActivityInstance, opt_index?: number): FlexTimeActivityInstance {
    return jspb.Message.addToRepeatedWrapperField(this, 10, opt_value, FlexTimeActivityInstance, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearActivityInstancesList() {
    this.setActivityInstancesList([]);
  }


  /**
   * optional int32 total_spaces = 11;
   * @return {number}
   */
  getTotalSpaces(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
  };


  /** @param {number} value */
  setTotalSpaces(value: number) {
    jspb.Message.setProto3IntField(this, 11, value);
  }


}
export namespace MinimalFlexTimePeriod {
  export interface AsObject {
    id: number;
    title: string;
    date?: common_date_pb.DateTime.AsObject;
    startTime: string;
    endTime: string;
    enableActivityManagement: boolean;
    enableStudentManagement: boolean;
    enableStudentSelfManagement: boolean;
  }
}
export class MinimalFlexTimePeriod extends jspb.Message {
  static readonly displayName = "MinimalFlexTimePeriod";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): MinimalFlexTimePeriod.AsObject {
    return MinimalFlexTimePeriod.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!MinimalFlexTimePeriod} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: MinimalFlexTimePeriod) {
    var f: any;
    var obj: any = {
      id: jspb.Message.getFieldWithDefault(msg, 1, 0),
      title: jspb.Message.getFieldWithDefault(msg, 2, ""),
      date: (f = msg.getDate()) && common_date_pb.DateTime.toObject(includeInstance, f),
      startTime: jspb.Message.getFieldWithDefault(msg, 4, ""),
      endTime: jspb.Message.getFieldWithDefault(msg, 5, ""),
      enableActivityManagement: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
      enableStudentManagement: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
      enableStudentSelfManagement: jspb.Message.getBooleanFieldWithDefault(msg, 8, false)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!MinimalFlexTimePeriod}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new MinimalFlexTimePeriod;
    return MinimalFlexTimePeriod.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!MinimalFlexTimePeriod} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!MinimalFlexTimePeriod}
   */
  static deserializeBinaryFromReader(msg: MinimalFlexTimePeriod, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {number} */ (reader.readInt32());
        msg.setId(value1);
        break;
      case 2:
        var value2 = /** @type {string} */ (reader.readString());
        msg.setTitle(value2);
        break;
      case 3:
        var value3 = new common_date_pb.DateTime;
        reader.readMessage(value3,common_date_pb.DateTime.deserializeBinaryFromReader);
        msg.setDate(value3);
        break;
      case 4:
        var value4 = /** @type {string} */ (reader.readString());
        msg.setStartTime(value4);
        break;
      case 5:
        var value5 = /** @type {string} */ (reader.readString());
        msg.setEndTime(value5);
        break;
      case 6:
        var value6 = /** @type {boolean} */ (reader.readBool());
        msg.setEnableActivityManagement(value6);
        break;
      case 7:
        var value7 = /** @type {boolean} */ (reader.readBool());
        msg.setEnableStudentManagement(value7);
        break;
      case 8:
        var value8 = /** @type {boolean} */ (reader.readBool());
        msg.setEnableStudentSelfManagement(value8);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!MinimalFlexTimePeriod} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: MinimalFlexTimePeriod, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getId();
    if (f !== 0) {
      writer.writeInt32(
        1,
        f
      );
    }
    f = message.getTitle();
    if (f.length > 0) {
      writer.writeString(
        2,
        f
      );
    }
    f = message.getDate();
    if (f != null) {
      writer.writeMessage(
        3,
        f,
        common_date_pb.DateTime.serializeBinaryToWriter
      );
    }
    f = message.getStartTime();
    if (f.length > 0) {
      writer.writeString(
        4,
        f
      );
    }
    f = message.getEndTime();
    if (f.length > 0) {
      writer.writeString(
        5,
        f
      );
    }
    f = message.getEnableActivityManagement();
    if (f) {
      writer.writeBool(
        6,
        f
      );
    }
    f = message.getEnableStudentManagement();
    if (f) {
      writer.writeBool(
        7,
        f
      );
    }
    f = message.getEnableStudentSelfManagement();
    if (f) {
      writer.writeBool(
        8,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    MinimalFlexTimePeriod.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional int32 id = 1;
   * @return {number}
   */
  getId(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
  };


  /** @param {number} value */
  setId(value: number) {
    jspb.Message.setProto3IntField(this, 1, value);
  }


  /**
   * optional string title = 2;
   * @return {string}
   */
  getTitle(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
  };


  /** @param {string} value */
  setTitle(value: string) {
    jspb.Message.setProto3StringField(this, 2, value);
  }


  /**
   * optional minga.common.DateTime date = 3;
   * @return {?DateTime}
   */
  getDate(): common_date_pb.DateTime {
    return /** @type{?DateTime} */ (
      jspb.Message.getWrapperField(this, common_date_pb.DateTime, 3));
  }


  /** @param {?DateTime|undefined} value */
  setDate(value?: common_date_pb.DateTime) {
    jspb.Message.setWrapperField(this, 3, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearDate() {
    this.setDate(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasDate(): boolean {
    return jspb.Message.getField(this, 3) != null;
  }


  /**
   * optional string start_time = 4;
   * @return {string}
   */
  getStartTime(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
  };


  /** @param {string} value */
  setStartTime(value: string) {
    jspb.Message.setProto3StringField(this, 4, value);
  }


  /**
   * optional string end_time = 5;
   * @return {string}
   */
  getEndTime(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
  };


  /** @param {string} value */
  setEndTime(value: string) {
    jspb.Message.setProto3StringField(this, 5, value);
  }


  /**
   * optional bool enable_activity_management = 6;
   * @return {boolean}
   */
  getEnableActivityManagement(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
  };


  /** @param {boolean} value */
  setEnableActivityManagement(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 6, value);
  }


  /**
   * optional bool enable_student_management = 7;
   * @return {boolean}
   */
  getEnableStudentManagement(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
  };


  /** @param {boolean} value */
  setEnableStudentManagement(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 7, value);
  }


  /**
   * optional bool enable_student_self_management = 8;
   * @return {boolean}
   */
  getEnableStudentSelfManagement(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
  };


  /** @param {boolean} value */
  setEnableStudentSelfManagement(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 8, value);
  }


}
export namespace FlexTimeActivityCreatedByPerson {
  export interface AsObject {
    hash: string;
    name: string;
  }
}
export class FlexTimeActivityCreatedByPerson extends jspb.Message {
  static readonly displayName = "FlexTimeActivityCreatedByPerson";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): FlexTimeActivityCreatedByPerson.AsObject {
    return FlexTimeActivityCreatedByPerson.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!FlexTimeActivityCreatedByPerson} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: FlexTimeActivityCreatedByPerson) {
    var f: any;
    var obj: any = {
      hash: jspb.Message.getFieldWithDefault(msg, 1, ""),
      name: jspb.Message.getFieldWithDefault(msg, 2, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!FlexTimeActivityCreatedByPerson}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new FlexTimeActivityCreatedByPerson;
    return FlexTimeActivityCreatedByPerson.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!FlexTimeActivityCreatedByPerson} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!FlexTimeActivityCreatedByPerson}
   */
  static deserializeBinaryFromReader(msg: FlexTimeActivityCreatedByPerson, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setHash(value1);
        break;
      case 2:
        var value2 = /** @type {string} */ (reader.readString());
        msg.setName(value2);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!FlexTimeActivityCreatedByPerson} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: FlexTimeActivityCreatedByPerson, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getHash();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
    f = message.getName();
    if (f.length > 0) {
      writer.writeString(
        2,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    FlexTimeActivityCreatedByPerson.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string hash = 1;
   * @return {string}
   */
  getHash(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setHash(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


  /**
   * optional string name = 2;
   * @return {string}
   */
  getName(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
  };


  /** @param {string} value */
  setName(value: string) {
    jspb.Message.setProto3StringField(this, 2, value);
  }


}
export namespace FlexTimeActivityType {
  export interface AsObject {
    id: number;
    name: string;
  }
}
export class FlexTimeActivityType extends jspb.Message {
  static readonly displayName = "FlexTimeActivityType";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): FlexTimeActivityType.AsObject {
    return FlexTimeActivityType.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!FlexTimeActivityType} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: FlexTimeActivityType) {
    var f: any;
    var obj: any = {
      id: jspb.Message.getFieldWithDefault(msg, 1, 0),
      name: jspb.Message.getFieldWithDefault(msg, 2, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!FlexTimeActivityType}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new FlexTimeActivityType;
    return FlexTimeActivityType.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!FlexTimeActivityType} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!FlexTimeActivityType}
   */
  static deserializeBinaryFromReader(msg: FlexTimeActivityType, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {number} */ (reader.readInt32());
        msg.setId(value1);
        break;
      case 2:
        var value2 = /** @type {string} */ (reader.readString());
        msg.setName(value2);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!FlexTimeActivityType} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: FlexTimeActivityType, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getId();
    if (f !== 0) {
      writer.writeInt32(
        1,
        f
      );
    }
    f = message.getName();
    if (f.length > 0) {
      writer.writeString(
        2,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    FlexTimeActivityType.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional int32 id = 1;
   * @return {number}
   */
  getId(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
  };


  /** @param {number} value */
  setId(value: number) {
    jspb.Message.setProto3IntField(this, 1, value);
  }


  /**
   * optional string name = 2;
   * @return {string}
   */
  getName(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
  };


  /** @param {string} value */
  setName(value: string) {
    jspb.Message.setProto3StringField(this, 2, value);
  }


}
export namespace FlexTimeActivity {
  export interface AsObject {
    id: number;
    mingaId: number;
    name: string;
    registrationLimit: number;
    createdBy: number;
    bannerHash: string;
    imagePath: string;
    description: string;
    allowStudentToRegister: boolean;
    restrictionId: number;
    bannerImage?: image_image_pb.ImageInfo.AsObject;
    createdByPerson?: FlexTimeActivityCreatedByPerson.AsObject;
    restriction?: restriction_restriction_pb.Restriction.AsObject;
    location: string;
    type?: FlexTimeActivityType.AsObject;
  }
}
export class FlexTimeActivity extends jspb.Message {
  static readonly displayName = "FlexTimeActivity";
  /**
   * Oneof group definitions for this message. Each group defines the field
   * numbers belonging to that group. When of these fields' value is set, all
   * other fields in the group are cleared. During deserialization, if multiple
   * fields are encountered for a group, only the last value seen will be kept.
   * @private {!Array<!Array<number>>}
   * @const
   */
  static readonly oneofGroups_ = [[8,9]];


  /**
   * @return {FlexTimeActivity.AssetCase}
   */
  getAssetCase() {
    return /** @type {FlexTimeActivity.AssetCase} */(jspb.Message.computeOneofCase(this, FlexTimeActivity.oneofGroups_[0]));
  }

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, FlexTimeActivity.oneofGroups_);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): FlexTimeActivity.AsObject {
    return FlexTimeActivity.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!FlexTimeActivity} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: FlexTimeActivity) {
    var f: any;
    var obj: any = {
      id: jspb.Message.getFieldWithDefault(msg, 1, 0),
      mingaId: jspb.Message.getFieldWithDefault(msg, 2, 0),
      name: jspb.Message.getFieldWithDefault(msg, 3, ""),
      registrationLimit: jspb.Message.getFieldWithDefault(msg, 4, 0),
      createdBy: jspb.Message.getFieldWithDefault(msg, 5, 0),
      bannerHash: jspb.Message.getFieldWithDefault(msg, 8, ""),
      imagePath: jspb.Message.getFieldWithDefault(msg, 9, ""),
      description: jspb.Message.getFieldWithDefault(msg, 11, ""),
      allowStudentToRegister: jspb.Message.getBooleanFieldWithDefault(msg, 12, false),
      restrictionId: jspb.Message.getFieldWithDefault(msg, 13, 0),
      bannerImage: (f = msg.getBannerImage()) && image_image_pb.ImageInfo.toObject(includeInstance, f),
      createdByPerson: (f = msg.getCreatedByPerson()) && FlexTimeActivityCreatedByPerson.toObject(includeInstance, f),
      restriction: (f = msg.getRestriction()) && restriction_restriction_pb.Restriction.toObject(includeInstance, f),
      location: jspb.Message.getFieldWithDefault(msg, 17, ""),
      type: (f = msg.getType()) && FlexTimeActivityType.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!FlexTimeActivity}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new FlexTimeActivity;
    return FlexTimeActivity.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!FlexTimeActivity} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!FlexTimeActivity}
   */
  static deserializeBinaryFromReader(msg: FlexTimeActivity, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {number} */ (reader.readInt32());
        msg.setId(value1);
        break;
      case 2:
        var value2 = /** @type {number} */ (reader.readInt32());
        msg.setMingaId(value2);
        break;
      case 3:
        var value3 = /** @type {string} */ (reader.readString());
        msg.setName(value3);
        break;
      case 4:
        var value4 = /** @type {number} */ (reader.readInt32());
        msg.setRegistrationLimit(value4);
        break;
      case 5:
        var value5 = /** @type {number} */ (reader.readInt32());
        msg.setCreatedBy(value5);
        break;
      case 8:
        var value8 = /** @type {string} */ (reader.readString());
        msg.setBannerHash(value8);
        break;
      case 9:
        var value9 = /** @type {string} */ (reader.readString());
        msg.setImagePath(value9);
        break;
      case 11:
        var value11 = /** @type {string} */ (reader.readString());
        msg.setDescription(value11);
        break;
      case 12:
        var value12 = /** @type {boolean} */ (reader.readBool());
        msg.setAllowStudentToRegister(value12);
        break;
      case 13:
        var value13 = /** @type {number} */ (reader.readInt32());
        msg.setRestrictionId(value13);
        break;
      case 14:
        var value14 = new image_image_pb.ImageInfo;
        reader.readMessage(value14,image_image_pb.ImageInfo.deserializeBinaryFromReader);
        msg.setBannerImage(value14);
        break;
      case 15:
        var value15 = new FlexTimeActivityCreatedByPerson;
        reader.readMessage(value15,FlexTimeActivityCreatedByPerson.deserializeBinaryFromReader);
        msg.setCreatedByPerson(value15);
        break;
      case 16:
        var value16 = new restriction_restriction_pb.Restriction;
        reader.readMessage(value16,restriction_restriction_pb.Restriction.deserializeBinaryFromReader);
        msg.setRestriction(value16);
        break;
      case 17:
        var value17 = /** @type {string} */ (reader.readString());
        msg.setLocation(value17);
        break;
      case 18:
        var value18 = new FlexTimeActivityType;
        reader.readMessage(value18,FlexTimeActivityType.deserializeBinaryFromReader);
        msg.setType(value18);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!FlexTimeActivity} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: FlexTimeActivity, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getId();
    if (f !== 0) {
      writer.writeInt32(
        1,
        f
      );
    }
    f = message.getMingaId();
    if (f !== 0) {
      writer.writeInt32(
        2,
        f
      );
    }
    f = message.getName();
    if (f.length > 0) {
      writer.writeString(
        3,
        f
      );
    }
    f = message.getRegistrationLimit();
    if (f !== 0) {
      writer.writeInt32(
        4,
        f
      );
    }
    f = message.getCreatedBy();
    if (f !== 0) {
      writer.writeInt32(
        5,
        f
      );
    }
    f = /** @type {string} */ (jspb.Message.getField(message, 8));
    if (f != null) {
      writer.writeString(
        8,
        f
      );
    }
    f = /** @type {string} */ (jspb.Message.getField(message, 9));
    if (f != null) {
      writer.writeString(
        9,
        f
      );
    }
    f = message.getDescription();
    if (f.length > 0) {
      writer.writeString(
        11,
        f
      );
    }
    f = message.getAllowStudentToRegister();
    if (f) {
      writer.writeBool(
        12,
        f
      );
    }
    f = message.getRestrictionId();
    if (f !== 0) {
      writer.writeInt32(
        13,
        f
      );
    }
    f = message.getBannerImage();
    if (f != null) {
      writer.writeMessage(
        14,
        f,
        image_image_pb.ImageInfo.serializeBinaryToWriter
      );
    }
    f = message.getCreatedByPerson();
    if (f != null) {
      writer.writeMessage(
        15,
        f,
        FlexTimeActivityCreatedByPerson.serializeBinaryToWriter
      );
    }
    f = message.getRestriction();
    if (f != null) {
      writer.writeMessage(
        16,
        f,
        restriction_restriction_pb.Restriction.serializeBinaryToWriter
      );
    }
    f = message.getLocation();
    if (f.length > 0) {
      writer.writeString(
        17,
        f
      );
    }
    f = message.getType();
    if (f != null) {
      writer.writeMessage(
        18,
        f,
        FlexTimeActivityType.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    FlexTimeActivity.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional int32 id = 1;
   * @return {number}
   */
  getId(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
  };


  /** @param {number} value */
  setId(value: number) {
    jspb.Message.setProto3IntField(this, 1, value);
  }


  /**
   * optional int32 minga_id = 2;
   * @return {number}
   */
  getMingaId(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
  };


  /** @param {number} value */
  setMingaId(value: number) {
    jspb.Message.setProto3IntField(this, 2, value);
  }


  /**
   * optional string name = 3;
   * @return {string}
   */
  getName(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
  };


  /** @param {string} value */
  setName(value: string) {
    jspb.Message.setProto3StringField(this, 3, value);
  }


  /**
   * optional int32 registration_limit = 4;
   * @return {number}
   */
  getRegistrationLimit(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
  };


  /** @param {number} value */
  setRegistrationLimit(value: number) {
    jspb.Message.setProto3IntField(this, 4, value);
  }


  /**
   * optional int32 created_by = 5;
   * @return {number}
   */
  getCreatedBy(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
  };


  /** @param {number} value */
  setCreatedBy(value: number) {
    jspb.Message.setProto3IntField(this, 5, value);
  }


  /**
   * optional string banner_hash = 8;
   * @return {string}
   */
  getBannerHash(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
  };


  /** @param {string} value */
  setBannerHash(value: string) {
    jspb.Message.setOneofField(this, 8, FlexTimeActivity.oneofGroups_[0], value);
  }


  /**
   * Clears the field making it undefined.
   */
  clearBannerHash() {
    jspb.Message.setOneofField(this, 8, FlexTimeActivity.oneofGroups_[0], undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasBannerHash(): boolean {
    return jspb.Message.getField(this, 8) != null;
  }


  /**
   * optional string image_path = 9;
   * @return {string}
   */
  getImagePath(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
  };


  /** @param {string} value */
  setImagePath(value: string) {
    jspb.Message.setOneofField(this, 9, FlexTimeActivity.oneofGroups_[0], value);
  }


  /**
   * Clears the field making it undefined.
   */
  clearImagePath() {
    jspb.Message.setOneofField(this, 9, FlexTimeActivity.oneofGroups_[0], undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasImagePath(): boolean {
    return jspb.Message.getField(this, 9) != null;
  }


  /**
   * optional string description = 11;
   * @return {string}
   */
  getDescription(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
  };


  /** @param {string} value */
  setDescription(value: string) {
    jspb.Message.setProto3StringField(this, 11, value);
  }


  /**
   * optional bool allow_student_to_register = 12;
   * @return {boolean}
   */
  getAllowStudentToRegister(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 12, false));
  };


  /** @param {boolean} value */
  setAllowStudentToRegister(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 12, value);
  }


  /**
   * optional int32 restriction_id = 13;
   * @return {number}
   */
  getRestrictionId(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
  };


  /** @param {number} value */
  setRestrictionId(value: number) {
    jspb.Message.setProto3IntField(this, 13, value);
  }


  /**
   * optional ImageInfo banner_image = 14;
   * @return {?ImageInfo}
   */
  getBannerImage(): image_image_pb.ImageInfo {
    return /** @type{?ImageInfo} */ (
      jspb.Message.getWrapperField(this, image_image_pb.ImageInfo, 14));
  }


  /** @param {?ImageInfo|undefined} value */
  setBannerImage(value?: image_image_pb.ImageInfo) {
    jspb.Message.setWrapperField(this, 14, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearBannerImage() {
    this.setBannerImage(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasBannerImage(): boolean {
    return jspb.Message.getField(this, 14) != null;
  }


  /**
   * optional FlexTimeActivityCreatedByPerson created_by_person = 15;
   * @return {?FlexTimeActivityCreatedByPerson}
   */
  getCreatedByPerson(): FlexTimeActivityCreatedByPerson {
    return /** @type{?FlexTimeActivityCreatedByPerson} */ (
      jspb.Message.getWrapperField(this, FlexTimeActivityCreatedByPerson, 15));
  }


  /** @param {?FlexTimeActivityCreatedByPerson|undefined} value */
  setCreatedByPerson(value?: FlexTimeActivityCreatedByPerson) {
    jspb.Message.setWrapperField(this, 15, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearCreatedByPerson() {
    this.setCreatedByPerson(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasCreatedByPerson(): boolean {
    return jspb.Message.getField(this, 15) != null;
  }


  /**
   * optional restriction.Restriction restriction = 16;
   * @return {?Restriction}
   */
  getRestriction(): restriction_restriction_pb.Restriction {
    return /** @type{?Restriction} */ (
      jspb.Message.getWrapperField(this, restriction_restriction_pb.Restriction, 16));
  }


  /** @param {?Restriction|undefined} value */
  setRestriction(value?: restriction_restriction_pb.Restriction) {
    jspb.Message.setWrapperField(this, 16, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearRestriction() {
    this.setRestriction(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasRestriction(): boolean {
    return jspb.Message.getField(this, 16) != null;
  }


  /**
   * optional string location = 17;
   * @return {string}
   */
  getLocation(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
  };


  /** @param {string} value */
  setLocation(value: string) {
    jspb.Message.setProto3StringField(this, 17, value);
  }


  /**
   * optional FlexTimeActivityType type = 18;
   * @return {?FlexTimeActivityType}
   */
  getType(): FlexTimeActivityType {
    return /** @type{?FlexTimeActivityType} */ (
      jspb.Message.getWrapperField(this, FlexTimeActivityType, 18));
  }


  /** @param {?FlexTimeActivityType|undefined} value */
  setType(value?: FlexTimeActivityType) {
    jspb.Message.setWrapperField(this, 18, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearType() {
    this.setType(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasType(): boolean {
    return jspb.Message.getField(this, 18) != null;
  }


}
export namespace FlexTimeActivity {
export enum AssetCase {
  ASSET_NOT_SET = 0,
  BANNER_HASH = 8,
  IMAGE_PATH = 9,
}
} // namespace FlexTimeActivity
export namespace FlexTimeActivityInstance {
  export interface AsObject {
    id: number;
    mingaId: number;
    activityId: number;
    periodId: number;
    checkinReasonId: number;
    activity?: FlexTimeActivity.AsObject;
    period?: FlexTimePeriod.AsObject;
    createdBy: number;
    spaces: number;
    registered: number;
    absentees: number;
    checkinCount: number;
  }
}
export class FlexTimeActivityInstance extends jspb.Message {
  static readonly displayName = "FlexTimeActivityInstance";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): FlexTimeActivityInstance.AsObject {
    return FlexTimeActivityInstance.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!FlexTimeActivityInstance} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: FlexTimeActivityInstance) {
    var f: any;
    var obj: any = {
      id: jspb.Message.getFieldWithDefault(msg, 1, 0),
      mingaId: jspb.Message.getFieldWithDefault(msg, 2, 0),
      activityId: jspb.Message.getFieldWithDefault(msg, 3, 0),
      periodId: jspb.Message.getFieldWithDefault(msg, 4, 0),
      checkinReasonId: jspb.Message.getFieldWithDefault(msg, 5, 0),
      activity: (f = msg.getActivity()) && FlexTimeActivity.toObject(includeInstance, f),
      period: (f = msg.getPeriod()) && FlexTimePeriod.toObject(includeInstance, f),
      createdBy: jspb.Message.getFieldWithDefault(msg, 8, 0),
      spaces: jspb.Message.getFieldWithDefault(msg, 9, 0),
      registered: jspb.Message.getFieldWithDefault(msg, 10, 0),
      absentees: jspb.Message.getFieldWithDefault(msg, 11, 0),
      checkinCount: jspb.Message.getFieldWithDefault(msg, 12, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!FlexTimeActivityInstance}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new FlexTimeActivityInstance;
    return FlexTimeActivityInstance.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!FlexTimeActivityInstance} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!FlexTimeActivityInstance}
   */
  static deserializeBinaryFromReader(msg: FlexTimeActivityInstance, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {number} */ (reader.readInt32());
        msg.setId(value1);
        break;
      case 2:
        var value2 = /** @type {number} */ (reader.readInt32());
        msg.setMingaId(value2);
        break;
      case 3:
        var value3 = /** @type {number} */ (reader.readInt32());
        msg.setActivityId(value3);
        break;
      case 4:
        var value4 = /** @type {number} */ (reader.readInt32());
        msg.setPeriodId(value4);
        break;
      case 5:
        var value5 = /** @type {number} */ (reader.readInt32());
        msg.setCheckinReasonId(value5);
        break;
      case 6:
        var value6 = new FlexTimeActivity;
        reader.readMessage(value6,FlexTimeActivity.deserializeBinaryFromReader);
        msg.setActivity(value6);
        break;
      case 7:
        var value7 = new FlexTimePeriod;
        reader.readMessage(value7,FlexTimePeriod.deserializeBinaryFromReader);
        msg.setPeriod(value7);
        break;
      case 8:
        var value8 = /** @type {number} */ (reader.readInt32());
        msg.setCreatedBy(value8);
        break;
      case 9:
        var value9 = /** @type {number} */ (reader.readInt32());
        msg.setSpaces(value9);
        break;
      case 10:
        var value10 = /** @type {number} */ (reader.readInt32());
        msg.setRegistered(value10);
        break;
      case 11:
        var value11 = /** @type {number} */ (reader.readInt32());
        msg.setAbsentees(value11);
        break;
      case 12:
        var value12 = /** @type {number} */ (reader.readInt32());
        msg.setCheckinCount(value12);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!FlexTimeActivityInstance} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: FlexTimeActivityInstance, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getId();
    if (f !== 0) {
      writer.writeInt32(
        1,
        f
      );
    }
    f = message.getMingaId();
    if (f !== 0) {
      writer.writeInt32(
        2,
        f
      );
    }
    f = message.getActivityId();
    if (f !== 0) {
      writer.writeInt32(
        3,
        f
      );
    }
    f = message.getPeriodId();
    if (f !== 0) {
      writer.writeInt32(
        4,
        f
      );
    }
    f = message.getCheckinReasonId();
    if (f !== 0) {
      writer.writeInt32(
        5,
        f
      );
    }
    f = message.getActivity();
    if (f != null) {
      writer.writeMessage(
        6,
        f,
        FlexTimeActivity.serializeBinaryToWriter
      );
    }
    f = message.getPeriod();
    if (f != null) {
      writer.writeMessage(
        7,
        f,
        FlexTimePeriod.serializeBinaryToWriter
      );
    }
    f = message.getCreatedBy();
    if (f !== 0) {
      writer.writeInt32(
        8,
        f
      );
    }
    f = message.getSpaces();
    if (f !== 0) {
      writer.writeInt32(
        9,
        f
      );
    }
    f = message.getRegistered();
    if (f !== 0) {
      writer.writeInt32(
        10,
        f
      );
    }
    f = message.getAbsentees();
    if (f !== 0) {
      writer.writeInt32(
        11,
        f
      );
    }
    f = message.getCheckinCount();
    if (f !== 0) {
      writer.writeInt32(
        12,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    FlexTimeActivityInstance.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional int32 id = 1;
   * @return {number}
   */
  getId(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
  };


  /** @param {number} value */
  setId(value: number) {
    jspb.Message.setProto3IntField(this, 1, value);
  }


  /**
   * optional int32 minga_id = 2;
   * @return {number}
   */
  getMingaId(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
  };


  /** @param {number} value */
  setMingaId(value: number) {
    jspb.Message.setProto3IntField(this, 2, value);
  }


  /**
   * optional int32 activity_id = 3;
   * @return {number}
   */
  getActivityId(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
  };


  /** @param {number} value */
  setActivityId(value: number) {
    jspb.Message.setProto3IntField(this, 3, value);
  }


  /**
   * optional int32 period_id = 4;
   * @return {number}
   */
  getPeriodId(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
  };


  /** @param {number} value */
  setPeriodId(value: number) {
    jspb.Message.setProto3IntField(this, 4, value);
  }


  /**
   * optional int32 checkin_reason_id = 5;
   * @return {number}
   */
  getCheckinReasonId(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
  };


  /** @param {number} value */
  setCheckinReasonId(value: number) {
    jspb.Message.setProto3IntField(this, 5, value);
  }


  /**
   * optional FlexTimeActivity activity = 6;
   * @return {?FlexTimeActivity}
   */
  getActivity(): FlexTimeActivity {
    return /** @type{?FlexTimeActivity} */ (
      jspb.Message.getWrapperField(this, FlexTimeActivity, 6));
  }


  /** @param {?FlexTimeActivity|undefined} value */
  setActivity(value?: FlexTimeActivity) {
    jspb.Message.setWrapperField(this, 6, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearActivity() {
    this.setActivity(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasActivity(): boolean {
    return jspb.Message.getField(this, 6) != null;
  }


  /**
   * optional FlexTimePeriod period = 7;
   * @return {?FlexTimePeriod}
   */
  getPeriod(): FlexTimePeriod {
    return /** @type{?FlexTimePeriod} */ (
      jspb.Message.getWrapperField(this, FlexTimePeriod, 7));
  }


  /** @param {?FlexTimePeriod|undefined} value */
  setPeriod(value?: FlexTimePeriod) {
    jspb.Message.setWrapperField(this, 7, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearPeriod() {
    this.setPeriod(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasPeriod(): boolean {
    return jspb.Message.getField(this, 7) != null;
  }


  /**
   * optional int32 created_by = 8;
   * @return {number}
   */
  getCreatedBy(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
  };


  /** @param {number} value */
  setCreatedBy(value: number) {
    jspb.Message.setProto3IntField(this, 8, value);
  }


  /**
   * optional int32 spaces = 9;
   * @return {number}
   */
  getSpaces(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
  };


  /** @param {number} value */
  setSpaces(value: number) {
    jspb.Message.setProto3IntField(this, 9, value);
  }


  /**
   * optional int32 registered = 10;
   * @return {number}
   */
  getRegistered(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
  };


  /** @param {number} value */
  setRegistered(value: number) {
    jspb.Message.setProto3IntField(this, 10, value);
  }


  /**
   * optional int32 absentees = 11;
   * @return {number}
   */
  getAbsentees(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
  };


  /** @param {number} value */
  setAbsentees(value: number) {
    jspb.Message.setProto3IntField(this, 11, value);
  }


  /**
   * optional int32 checkin_count = 12;
   * @return {number}
   */
  getCheckinCount(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
  };


  /** @param {number} value */
  setCheckinCount(value: number) {
    jspb.Message.setProto3IntField(this, 12, value);
  }


}
export namespace ListFlexTimePeriodsRequest {
  export interface AsObject {
    startDate?: common_date_pb.DateTime.AsObject;
    endDate?: common_date_pb.DateTime.AsObject;
    fetchOnlyMyActivities: boolean;
    includeAbsentees: boolean;
  }
}
export class ListFlexTimePeriodsRequest extends jspb.Message {
  static readonly displayName = "ListFlexTimePeriodsRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): ListFlexTimePeriodsRequest.AsObject {
    return ListFlexTimePeriodsRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!ListFlexTimePeriodsRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: ListFlexTimePeriodsRequest) {
    var f: any;
    var obj: any = {
      startDate: (f = msg.getStartDate()) && common_date_pb.DateTime.toObject(includeInstance, f),
      endDate: (f = msg.getEndDate()) && common_date_pb.DateTime.toObject(includeInstance, f),
      fetchOnlyMyActivities: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
      includeAbsentees: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!ListFlexTimePeriodsRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new ListFlexTimePeriodsRequest;
    return ListFlexTimePeriodsRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!ListFlexTimePeriodsRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!ListFlexTimePeriodsRequest}
   */
  static deserializeBinaryFromReader(msg: ListFlexTimePeriodsRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new common_date_pb.DateTime;
        reader.readMessage(value1,common_date_pb.DateTime.deserializeBinaryFromReader);
        msg.setStartDate(value1);
        break;
      case 2:
        var value2 = new common_date_pb.DateTime;
        reader.readMessage(value2,common_date_pb.DateTime.deserializeBinaryFromReader);
        msg.setEndDate(value2);
        break;
      case 3:
        var value3 = /** @type {boolean} */ (reader.readBool());
        msg.setFetchOnlyMyActivities(value3);
        break;
      case 4:
        var value4 = /** @type {boolean} */ (reader.readBool());
        msg.setIncludeAbsentees(value4);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!ListFlexTimePeriodsRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: ListFlexTimePeriodsRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getStartDate();
    if (f != null) {
      writer.writeMessage(
        1,
        f,
        common_date_pb.DateTime.serializeBinaryToWriter
      );
    }
    f = message.getEndDate();
    if (f != null) {
      writer.writeMessage(
        2,
        f,
        common_date_pb.DateTime.serializeBinaryToWriter
      );
    }
    f = message.getFetchOnlyMyActivities();
    if (f) {
      writer.writeBool(
        3,
        f
      );
    }
    f = message.getIncludeAbsentees();
    if (f) {
      writer.writeBool(
        4,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    ListFlexTimePeriodsRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional minga.common.DateTime start_date = 1;
   * @return {?DateTime}
   */
  getStartDate(): common_date_pb.DateTime {
    return /** @type{?DateTime} */ (
      jspb.Message.getWrapperField(this, common_date_pb.DateTime, 1));
  }


  /** @param {?DateTime|undefined} value */
  setStartDate(value?: common_date_pb.DateTime) {
    jspb.Message.setWrapperField(this, 1, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearStartDate() {
    this.setStartDate(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasStartDate(): boolean {
    return jspb.Message.getField(this, 1) != null;
  }


  /**
   * optional minga.common.DateTime end_date = 2;
   * @return {?DateTime}
   */
  getEndDate(): common_date_pb.DateTime {
    return /** @type{?DateTime} */ (
      jspb.Message.getWrapperField(this, common_date_pb.DateTime, 2));
  }


  /** @param {?DateTime|undefined} value */
  setEndDate(value?: common_date_pb.DateTime) {
    jspb.Message.setWrapperField(this, 2, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearEndDate() {
    this.setEndDate(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasEndDate(): boolean {
    return jspb.Message.getField(this, 2) != null;
  }


  /**
   * optional bool fetch_only_my_activities = 3;
   * @return {boolean}
   */
  getFetchOnlyMyActivities(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
  };


  /** @param {boolean} value */
  setFetchOnlyMyActivities(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 3, value);
  }


  /**
   * optional bool include_absentees = 4;
   * @return {boolean}
   */
  getIncludeAbsentees(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
  };


  /** @param {boolean} value */
  setIncludeAbsentees(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 4, value);
  }


}
export namespace ListFlexTimePeriodsResponse {
  export interface AsObject {
    flexTimePeriodsList: FlexTimePeriod.AsObject[];
  }
}
export class ListFlexTimePeriodsResponse extends jspb.Message {
  static readonly displayName = "ListFlexTimePeriodsResponse";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [1];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, ListFlexTimePeriodsResponse.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): ListFlexTimePeriodsResponse.AsObject {
    return ListFlexTimePeriodsResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!ListFlexTimePeriodsResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: ListFlexTimePeriodsResponse) {
    var f: any;
    var obj: any = {
      flexTimePeriodsList: jspb.Message.toObjectList(msg.getFlexTimePeriodsList(),
      FlexTimePeriod.toObject, includeInstance)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!ListFlexTimePeriodsResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new ListFlexTimePeriodsResponse;
    return ListFlexTimePeriodsResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!ListFlexTimePeriodsResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!ListFlexTimePeriodsResponse}
   */
  static deserializeBinaryFromReader(msg: ListFlexTimePeriodsResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new FlexTimePeriod;
        reader.readMessage(value1,FlexTimePeriod.deserializeBinaryFromReader);
        msg.addFlexTimePeriods(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!ListFlexTimePeriodsResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: ListFlexTimePeriodsResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getFlexTimePeriodsList();
    if (f.length > 0) {
      writer.writeRepeatedMessage(
        1,
        f,
        FlexTimePeriod.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    ListFlexTimePeriodsResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * repeated FlexTimePeriod flex_time_periods = 1;
   * @return {!Array<!FlexTimePeriod>}
   */
  getFlexTimePeriodsList(): FlexTimePeriod[] {
    return /** @type{!Array<!FlexTimePeriod>} */ (
      jspb.Message.getRepeatedWrapperField(this, FlexTimePeriod, 1));
  }


  /** @param {!Array<!FlexTimePeriod>} value */
  setFlexTimePeriodsList(value?: FlexTimePeriod[]) {
    jspb.Message.setRepeatedWrapperField(this, 1, value);
  }


  /**
   * @param {!FlexTimePeriod=} opt_value
   * @param {number=} opt_index
   * @return {!FlexTimePeriod}
   */
  addFlexTimePeriods(opt_value?: FlexTimePeriod, opt_index?: number): FlexTimePeriod {
    return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, FlexTimePeriod, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearFlexTimePeriodsList() {
    this.setFlexTimePeriodsList([]);
  }


}
export namespace ListMinimalFlexTimePeriodsRequest {
  export interface AsObject {
    startDate?: common_date_pb.DateTime.AsObject;
    endDate?: common_date_pb.DateTime.AsObject;
  }
}
export class ListMinimalFlexTimePeriodsRequest extends jspb.Message {
  static readonly displayName = "ListMinimalFlexTimePeriodsRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): ListMinimalFlexTimePeriodsRequest.AsObject {
    return ListMinimalFlexTimePeriodsRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!ListMinimalFlexTimePeriodsRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: ListMinimalFlexTimePeriodsRequest) {
    var f: any;
    var obj: any = {
      startDate: (f = msg.getStartDate()) && common_date_pb.DateTime.toObject(includeInstance, f),
      endDate: (f = msg.getEndDate()) && common_date_pb.DateTime.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!ListMinimalFlexTimePeriodsRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new ListMinimalFlexTimePeriodsRequest;
    return ListMinimalFlexTimePeriodsRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!ListMinimalFlexTimePeriodsRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!ListMinimalFlexTimePeriodsRequest}
   */
  static deserializeBinaryFromReader(msg: ListMinimalFlexTimePeriodsRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new common_date_pb.DateTime;
        reader.readMessage(value1,common_date_pb.DateTime.deserializeBinaryFromReader);
        msg.setStartDate(value1);
        break;
      case 2:
        var value2 = new common_date_pb.DateTime;
        reader.readMessage(value2,common_date_pb.DateTime.deserializeBinaryFromReader);
        msg.setEndDate(value2);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!ListMinimalFlexTimePeriodsRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: ListMinimalFlexTimePeriodsRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getStartDate();
    if (f != null) {
      writer.writeMessage(
        1,
        f,
        common_date_pb.DateTime.serializeBinaryToWriter
      );
    }
    f = message.getEndDate();
    if (f != null) {
      writer.writeMessage(
        2,
        f,
        common_date_pb.DateTime.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    ListMinimalFlexTimePeriodsRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional minga.common.DateTime start_date = 1;
   * @return {?DateTime}
   */
  getStartDate(): common_date_pb.DateTime {
    return /** @type{?DateTime} */ (
      jspb.Message.getWrapperField(this, common_date_pb.DateTime, 1));
  }


  /** @param {?DateTime|undefined} value */
  setStartDate(value?: common_date_pb.DateTime) {
    jspb.Message.setWrapperField(this, 1, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearStartDate() {
    this.setStartDate(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasStartDate(): boolean {
    return jspb.Message.getField(this, 1) != null;
  }


  /**
   * optional minga.common.DateTime end_date = 2;
   * @return {?DateTime}
   */
  getEndDate(): common_date_pb.DateTime {
    return /** @type{?DateTime} */ (
      jspb.Message.getWrapperField(this, common_date_pb.DateTime, 2));
  }


  /** @param {?DateTime|undefined} value */
  setEndDate(value?: common_date_pb.DateTime) {
    jspb.Message.setWrapperField(this, 2, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearEndDate() {
    this.setEndDate(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasEndDate(): boolean {
    return jspb.Message.getField(this, 2) != null;
  }


}
export namespace ListMinimalFlexTimePeriodsResponse {
  export interface AsObject {
    flexTimePeriodsList: MinimalFlexTimePeriod.AsObject[];
  }
}
export class ListMinimalFlexTimePeriodsResponse extends jspb.Message {
  static readonly displayName = "ListMinimalFlexTimePeriodsResponse";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [1];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, ListMinimalFlexTimePeriodsResponse.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): ListMinimalFlexTimePeriodsResponse.AsObject {
    return ListMinimalFlexTimePeriodsResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!ListMinimalFlexTimePeriodsResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: ListMinimalFlexTimePeriodsResponse) {
    var f: any;
    var obj: any = {
      flexTimePeriodsList: jspb.Message.toObjectList(msg.getFlexTimePeriodsList(),
      MinimalFlexTimePeriod.toObject, includeInstance)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!ListMinimalFlexTimePeriodsResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new ListMinimalFlexTimePeriodsResponse;
    return ListMinimalFlexTimePeriodsResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!ListMinimalFlexTimePeriodsResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!ListMinimalFlexTimePeriodsResponse}
   */
  static deserializeBinaryFromReader(msg: ListMinimalFlexTimePeriodsResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new MinimalFlexTimePeriod;
        reader.readMessage(value1,MinimalFlexTimePeriod.deserializeBinaryFromReader);
        msg.addFlexTimePeriods(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!ListMinimalFlexTimePeriodsResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: ListMinimalFlexTimePeriodsResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getFlexTimePeriodsList();
    if (f.length > 0) {
      writer.writeRepeatedMessage(
        1,
        f,
        MinimalFlexTimePeriod.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    ListMinimalFlexTimePeriodsResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * repeated MinimalFlexTimePeriod flex_time_periods = 1;
   * @return {!Array<!MinimalFlexTimePeriod>}
   */
  getFlexTimePeriodsList(): MinimalFlexTimePeriod[] {
    return /** @type{!Array<!MinimalFlexTimePeriod>} */ (
      jspb.Message.getRepeatedWrapperField(this, MinimalFlexTimePeriod, 1));
  }


  /** @param {!Array<!MinimalFlexTimePeriod>} value */
  setFlexTimePeriodsList(value?: MinimalFlexTimePeriod[]) {
    jspb.Message.setRepeatedWrapperField(this, 1, value);
  }


  /**
   * @param {!MinimalFlexTimePeriod=} opt_value
   * @param {number=} opt_index
   * @return {!MinimalFlexTimePeriod}
   */
  addFlexTimePeriods(opt_value?: MinimalFlexTimePeriod, opt_index?: number): MinimalFlexTimePeriod {
    return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, MinimalFlexTimePeriod, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearFlexTimePeriodsList() {
    this.setFlexTimePeriodsList([]);
  }


}
export namespace UpsertFlexTimePeriodRequest {
  export interface AsObject {
    flexTimePeriod?: FlexTimePeriod.AsObject;
    cloneRegistered: boolean;
    cloneAssigned: boolean;
  }
}
export class UpsertFlexTimePeriodRequest extends jspb.Message {
  static readonly displayName = "UpsertFlexTimePeriodRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): UpsertFlexTimePeriodRequest.AsObject {
    return UpsertFlexTimePeriodRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!UpsertFlexTimePeriodRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: UpsertFlexTimePeriodRequest) {
    var f: any;
    var obj: any = {
      flexTimePeriod: (f = msg.getFlexTimePeriod()) && FlexTimePeriod.toObject(includeInstance, f),
      cloneRegistered: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
      cloneAssigned: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!UpsertFlexTimePeriodRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new UpsertFlexTimePeriodRequest;
    return UpsertFlexTimePeriodRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!UpsertFlexTimePeriodRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!UpsertFlexTimePeriodRequest}
   */
  static deserializeBinaryFromReader(msg: UpsertFlexTimePeriodRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new FlexTimePeriod;
        reader.readMessage(value1,FlexTimePeriod.deserializeBinaryFromReader);
        msg.setFlexTimePeriod(value1);
        break;
      case 2:
        var value2 = /** @type {boolean} */ (reader.readBool());
        msg.setCloneRegistered(value2);
        break;
      case 3:
        var value3 = /** @type {boolean} */ (reader.readBool());
        msg.setCloneAssigned(value3);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!UpsertFlexTimePeriodRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: UpsertFlexTimePeriodRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getFlexTimePeriod();
    if (f != null) {
      writer.writeMessage(
        1,
        f,
        FlexTimePeriod.serializeBinaryToWriter
      );
    }
    f = message.getCloneRegistered();
    if (f) {
      writer.writeBool(
        2,
        f
      );
    }
    f = message.getCloneAssigned();
    if (f) {
      writer.writeBool(
        3,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    UpsertFlexTimePeriodRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional FlexTimePeriod flex_time_period = 1;
   * @return {?FlexTimePeriod}
   */
  getFlexTimePeriod(): FlexTimePeriod {
    return /** @type{?FlexTimePeriod} */ (
      jspb.Message.getWrapperField(this, FlexTimePeriod, 1));
  }


  /** @param {?FlexTimePeriod|undefined} value */
  setFlexTimePeriod(value?: FlexTimePeriod) {
    jspb.Message.setWrapperField(this, 1, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearFlexTimePeriod() {
    this.setFlexTimePeriod(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasFlexTimePeriod(): boolean {
    return jspb.Message.getField(this, 1) != null;
  }


  /**
   * optional bool clone_registered = 2;
   * @return {boolean}
   */
  getCloneRegistered(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
  };


  /** @param {boolean} value */
  setCloneRegistered(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 2, value);
  }


  /**
   * optional bool clone_assigned = 3;
   * @return {boolean}
   */
  getCloneAssigned(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
  };


  /** @param {boolean} value */
  setCloneAssigned(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 3, value);
  }


}
export namespace GetFlexTimePeriodRequest {
  export interface AsObject {
    id: number;
  }
}
export class GetFlexTimePeriodRequest extends jspb.Message {
  static readonly displayName = "GetFlexTimePeriodRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): GetFlexTimePeriodRequest.AsObject {
    return GetFlexTimePeriodRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!GetFlexTimePeriodRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: GetFlexTimePeriodRequest) {
    var f: any;
    var obj: any = {
      id: jspb.Message.getFieldWithDefault(msg, 1, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!GetFlexTimePeriodRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new GetFlexTimePeriodRequest;
    return GetFlexTimePeriodRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!GetFlexTimePeriodRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!GetFlexTimePeriodRequest}
   */
  static deserializeBinaryFromReader(msg: GetFlexTimePeriodRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {number} */ (reader.readInt32());
        msg.setId(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!GetFlexTimePeriodRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: GetFlexTimePeriodRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getId();
    if (f !== 0) {
      writer.writeInt32(
        1,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    GetFlexTimePeriodRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional int32 id = 1;
   * @return {number}
   */
  getId(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
  };


  /** @param {number} value */
  setId(value: number) {
    jspb.Message.setProto3IntField(this, 1, value);
  }


}
export namespace DeleteFlexTimePeriodRequest {
  export interface AsObject {
    id: number;
  }
}
export class DeleteFlexTimePeriodRequest extends jspb.Message {
  static readonly displayName = "DeleteFlexTimePeriodRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): DeleteFlexTimePeriodRequest.AsObject {
    return DeleteFlexTimePeriodRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!DeleteFlexTimePeriodRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: DeleteFlexTimePeriodRequest) {
    var f: any;
    var obj: any = {
      id: jspb.Message.getFieldWithDefault(msg, 1, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!DeleteFlexTimePeriodRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new DeleteFlexTimePeriodRequest;
    return DeleteFlexTimePeriodRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!DeleteFlexTimePeriodRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!DeleteFlexTimePeriodRequest}
   */
  static deserializeBinaryFromReader(msg: DeleteFlexTimePeriodRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {number} */ (reader.readInt32());
        msg.setId(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!DeleteFlexTimePeriodRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: DeleteFlexTimePeriodRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getId();
    if (f !== 0) {
      writer.writeInt32(
        1,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    DeleteFlexTimePeriodRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional int32 id = 1;
   * @return {number}
   */
  getId(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
  };


  /** @param {number} value */
  setId(value: number) {
    jspb.Message.setProto3IntField(this, 1, value);
  }


}
export namespace DeleteFlexTimePeriodResponse {
  export interface AsObject {
  }
}
export class DeleteFlexTimePeriodResponse extends jspb.Message {
  static readonly displayName = "DeleteFlexTimePeriodResponse";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): DeleteFlexTimePeriodResponse.AsObject {
    return DeleteFlexTimePeriodResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!DeleteFlexTimePeriodResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: DeleteFlexTimePeriodResponse) {
    var f: any;
    var obj: any = {

    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!DeleteFlexTimePeriodResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new DeleteFlexTimePeriodResponse;
    return DeleteFlexTimePeriodResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!DeleteFlexTimePeriodResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!DeleteFlexTimePeriodResponse}
   */
  static deserializeBinaryFromReader(msg: DeleteFlexTimePeriodResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!DeleteFlexTimePeriodResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: DeleteFlexTimePeriodResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    DeleteFlexTimePeriodResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
}
export namespace ListFlexTimeActivitiesRequest {
  export interface AsObject {
    personHash: string;
    includeArchived: boolean;
  }
}
export class ListFlexTimeActivitiesRequest extends jspb.Message {
  static readonly displayName = "ListFlexTimeActivitiesRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): ListFlexTimeActivitiesRequest.AsObject {
    return ListFlexTimeActivitiesRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!ListFlexTimeActivitiesRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: ListFlexTimeActivitiesRequest) {
    var f: any;
    var obj: any = {
      personHash: jspb.Message.getFieldWithDefault(msg, 1, ""),
      includeArchived: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!ListFlexTimeActivitiesRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new ListFlexTimeActivitiesRequest;
    return ListFlexTimeActivitiesRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!ListFlexTimeActivitiesRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!ListFlexTimeActivitiesRequest}
   */
  static deserializeBinaryFromReader(msg: ListFlexTimeActivitiesRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setPersonHash(value1);
        break;
      case 2:
        var value2 = /** @type {boolean} */ (reader.readBool());
        msg.setIncludeArchived(value2);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!ListFlexTimeActivitiesRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: ListFlexTimeActivitiesRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getPersonHash();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
    f = message.getIncludeArchived();
    if (f) {
      writer.writeBool(
        2,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    ListFlexTimeActivitiesRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string person_hash = 1;
   * @return {string}
   */
  getPersonHash(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setPersonHash(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


  /**
   * optional bool include_archived = 2;
   * @return {boolean}
   */
  getIncludeArchived(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
  };


  /** @param {boolean} value */
  setIncludeArchived(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 2, value);
  }


}
export namespace GetFlexTimeActivityRequest {
  export interface AsObject {
    id: number;
  }
}
export class GetFlexTimeActivityRequest extends jspb.Message {
  static readonly displayName = "GetFlexTimeActivityRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): GetFlexTimeActivityRequest.AsObject {
    return GetFlexTimeActivityRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!GetFlexTimeActivityRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: GetFlexTimeActivityRequest) {
    var f: any;
    var obj: any = {
      id: jspb.Message.getFieldWithDefault(msg, 1, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!GetFlexTimeActivityRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new GetFlexTimeActivityRequest;
    return GetFlexTimeActivityRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!GetFlexTimeActivityRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!GetFlexTimeActivityRequest}
   */
  static deserializeBinaryFromReader(msg: GetFlexTimeActivityRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {number} */ (reader.readInt32());
        msg.setId(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!GetFlexTimeActivityRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: GetFlexTimeActivityRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getId();
    if (f !== 0) {
      writer.writeInt32(
        1,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    GetFlexTimeActivityRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional int32 id = 1;
   * @return {number}
   */
  getId(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
  };


  /** @param {number} value */
  setId(value: number) {
    jspb.Message.setProto3IntField(this, 1, value);
  }


}
export namespace DeleteFlexTimeActivityRequest {
  export interface AsObject {
    id: number;
  }
}
export class DeleteFlexTimeActivityRequest extends jspb.Message {
  static readonly displayName = "DeleteFlexTimeActivityRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): DeleteFlexTimeActivityRequest.AsObject {
    return DeleteFlexTimeActivityRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!DeleteFlexTimeActivityRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: DeleteFlexTimeActivityRequest) {
    var f: any;
    var obj: any = {
      id: jspb.Message.getFieldWithDefault(msg, 1, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!DeleteFlexTimeActivityRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new DeleteFlexTimeActivityRequest;
    return DeleteFlexTimeActivityRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!DeleteFlexTimeActivityRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!DeleteFlexTimeActivityRequest}
   */
  static deserializeBinaryFromReader(msg: DeleteFlexTimeActivityRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {number} */ (reader.readInt32());
        msg.setId(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!DeleteFlexTimeActivityRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: DeleteFlexTimeActivityRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getId();
    if (f !== 0) {
      writer.writeInt32(
        1,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    DeleteFlexTimeActivityRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional int32 id = 1;
   * @return {number}
   */
  getId(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
  };


  /** @param {number} value */
  setId(value: number) {
    jspb.Message.setProto3IntField(this, 1, value);
  }


}
export namespace DeleteFlexTimeActivityResponse {
  export interface AsObject {
  }
}
export class DeleteFlexTimeActivityResponse extends jspb.Message {
  static readonly displayName = "DeleteFlexTimeActivityResponse";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): DeleteFlexTimeActivityResponse.AsObject {
    return DeleteFlexTimeActivityResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!DeleteFlexTimeActivityResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: DeleteFlexTimeActivityResponse) {
    var f: any;
    var obj: any = {

    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!DeleteFlexTimeActivityResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new DeleteFlexTimeActivityResponse;
    return DeleteFlexTimeActivityResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!DeleteFlexTimeActivityResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!DeleteFlexTimeActivityResponse}
   */
  static deserializeBinaryFromReader(msg: DeleteFlexTimeActivityResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!DeleteFlexTimeActivityResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: DeleteFlexTimeActivityResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    DeleteFlexTimeActivityResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
}
export namespace ArchiveFlexTimeActivityRequest {
  export interface AsObject {
    id: number;
  }
}
export class ArchiveFlexTimeActivityRequest extends jspb.Message {
  static readonly displayName = "ArchiveFlexTimeActivityRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): ArchiveFlexTimeActivityRequest.AsObject {
    return ArchiveFlexTimeActivityRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!ArchiveFlexTimeActivityRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: ArchiveFlexTimeActivityRequest) {
    var f: any;
    var obj: any = {
      id: jspb.Message.getFieldWithDefault(msg, 1, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!ArchiveFlexTimeActivityRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new ArchiveFlexTimeActivityRequest;
    return ArchiveFlexTimeActivityRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!ArchiveFlexTimeActivityRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!ArchiveFlexTimeActivityRequest}
   */
  static deserializeBinaryFromReader(msg: ArchiveFlexTimeActivityRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {number} */ (reader.readInt32());
        msg.setId(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!ArchiveFlexTimeActivityRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: ArchiveFlexTimeActivityRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getId();
    if (f !== 0) {
      writer.writeInt32(
        1,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    ArchiveFlexTimeActivityRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional int32 id = 1;
   * @return {number}
   */
  getId(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
  };


  /** @param {number} value */
  setId(value: number) {
    jspb.Message.setProto3IntField(this, 1, value);
  }


}
export namespace ArchiveFlexTimeActivityResponse {
  export interface AsObject {
    flexTimeActivity?: FlexTimeActivity.AsObject;
  }
}
export class ArchiveFlexTimeActivityResponse extends jspb.Message {
  static readonly displayName = "ArchiveFlexTimeActivityResponse";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): ArchiveFlexTimeActivityResponse.AsObject {
    return ArchiveFlexTimeActivityResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!ArchiveFlexTimeActivityResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: ArchiveFlexTimeActivityResponse) {
    var f: any;
    var obj: any = {
      flexTimeActivity: (f = msg.getFlexTimeActivity()) && FlexTimeActivity.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!ArchiveFlexTimeActivityResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new ArchiveFlexTimeActivityResponse;
    return ArchiveFlexTimeActivityResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!ArchiveFlexTimeActivityResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!ArchiveFlexTimeActivityResponse}
   */
  static deserializeBinaryFromReader(msg: ArchiveFlexTimeActivityResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new FlexTimeActivity;
        reader.readMessage(value1,FlexTimeActivity.deserializeBinaryFromReader);
        msg.setFlexTimeActivity(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!ArchiveFlexTimeActivityResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: ArchiveFlexTimeActivityResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getFlexTimeActivity();
    if (f != null) {
      writer.writeMessage(
        1,
        f,
        FlexTimeActivity.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    ArchiveFlexTimeActivityResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional FlexTimeActivity flex_time_activity = 1;
   * @return {?FlexTimeActivity}
   */
  getFlexTimeActivity(): FlexTimeActivity {
    return /** @type{?FlexTimeActivity} */ (
      jspb.Message.getWrapperField(this, FlexTimeActivity, 1));
  }


  /** @param {?FlexTimeActivity|undefined} value */
  setFlexTimeActivity(value?: FlexTimeActivity) {
    jspb.Message.setWrapperField(this, 1, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearFlexTimeActivity() {
    this.setFlexTimeActivity(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasFlexTimeActivity(): boolean {
    return jspb.Message.getField(this, 1) != null;
  }


}
export namespace ListFlexTimeActivitiesResponse {
  export interface AsObject {
    flexTimeActivitiesList: FlexTimeActivity.AsObject[];
  }
}
export class ListFlexTimeActivitiesResponse extends jspb.Message {
  static readonly displayName = "ListFlexTimeActivitiesResponse";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [1];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, ListFlexTimeActivitiesResponse.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): ListFlexTimeActivitiesResponse.AsObject {
    return ListFlexTimeActivitiesResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!ListFlexTimeActivitiesResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: ListFlexTimeActivitiesResponse) {
    var f: any;
    var obj: any = {
      flexTimeActivitiesList: jspb.Message.toObjectList(msg.getFlexTimeActivitiesList(),
      FlexTimeActivity.toObject, includeInstance)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!ListFlexTimeActivitiesResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new ListFlexTimeActivitiesResponse;
    return ListFlexTimeActivitiesResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!ListFlexTimeActivitiesResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!ListFlexTimeActivitiesResponse}
   */
  static deserializeBinaryFromReader(msg: ListFlexTimeActivitiesResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new FlexTimeActivity;
        reader.readMessage(value1,FlexTimeActivity.deserializeBinaryFromReader);
        msg.addFlexTimeActivities(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!ListFlexTimeActivitiesResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: ListFlexTimeActivitiesResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getFlexTimeActivitiesList();
    if (f.length > 0) {
      writer.writeRepeatedMessage(
        1,
        f,
        FlexTimeActivity.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    ListFlexTimeActivitiesResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * repeated FlexTimeActivity flex_time_activities = 1;
   * @return {!Array<!FlexTimeActivity>}
   */
  getFlexTimeActivitiesList(): FlexTimeActivity[] {
    return /** @type{!Array<!FlexTimeActivity>} */ (
      jspb.Message.getRepeatedWrapperField(this, FlexTimeActivity, 1));
  }


  /** @param {!Array<!FlexTimeActivity>} value */
  setFlexTimeActivitiesList(value?: FlexTimeActivity[]) {
    jspb.Message.setRepeatedWrapperField(this, 1, value);
  }


  /**
   * @param {!FlexTimeActivity=} opt_value
   * @param {number=} opt_index
   * @return {!FlexTimeActivity}
   */
  addFlexTimeActivities(opt_value?: FlexTimeActivity, opt_index?: number): FlexTimeActivity {
    return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, FlexTimeActivity, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearFlexTimeActivitiesList() {
    this.setFlexTimeActivitiesList([]);
  }


}
export namespace UpsertFlexTimeActivityRequest {
  export interface AsObject {
    flexTimeActivity?: FlexTimeActivity.AsObject;
  }
}
export class UpsertFlexTimeActivityRequest extends jspb.Message {
  static readonly displayName = "UpsertFlexTimeActivityRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): UpsertFlexTimeActivityRequest.AsObject {
    return UpsertFlexTimeActivityRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!UpsertFlexTimeActivityRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: UpsertFlexTimeActivityRequest) {
    var f: any;
    var obj: any = {
      flexTimeActivity: (f = msg.getFlexTimeActivity()) && FlexTimeActivity.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!UpsertFlexTimeActivityRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new UpsertFlexTimeActivityRequest;
    return UpsertFlexTimeActivityRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!UpsertFlexTimeActivityRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!UpsertFlexTimeActivityRequest}
   */
  static deserializeBinaryFromReader(msg: UpsertFlexTimeActivityRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new FlexTimeActivity;
        reader.readMessage(value1,FlexTimeActivity.deserializeBinaryFromReader);
        msg.setFlexTimeActivity(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!UpsertFlexTimeActivityRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: UpsertFlexTimeActivityRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getFlexTimeActivity();
    if (f != null) {
      writer.writeMessage(
        1,
        f,
        FlexTimeActivity.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    UpsertFlexTimeActivityRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional FlexTimeActivity flex_time_activity = 1;
   * @return {?FlexTimeActivity}
   */
  getFlexTimeActivity(): FlexTimeActivity {
    return /** @type{?FlexTimeActivity} */ (
      jspb.Message.getWrapperField(this, FlexTimeActivity, 1));
  }


  /** @param {?FlexTimeActivity|undefined} value */
  setFlexTimeActivity(value?: FlexTimeActivity) {
    jspb.Message.setWrapperField(this, 1, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearFlexTimeActivity() {
    this.setFlexTimeActivity(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasFlexTimeActivity(): boolean {
    return jspb.Message.getField(this, 1) != null;
  }


}
export namespace ListFlexTimeActivityInstancesRequest {
  export interface AsObject {
    startDate?: common_date_pb.DateTime.AsObject;
    endDate?: common_date_pb.DateTime.AsObject;
    periodId: number;
    currentPeriod: boolean;
    personHashesList: string[];
    activityTemplateId: number;
  }
}
export class ListFlexTimeActivityInstancesRequest extends jspb.Message {
  static readonly displayName = "ListFlexTimeActivityInstancesRequest";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [5];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, ListFlexTimeActivityInstancesRequest.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): ListFlexTimeActivityInstancesRequest.AsObject {
    return ListFlexTimeActivityInstancesRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!ListFlexTimeActivityInstancesRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: ListFlexTimeActivityInstancesRequest) {
    var f: any;
    var obj: any = {
      startDate: (f = msg.getStartDate()) && common_date_pb.DateTime.toObject(includeInstance, f),
      endDate: (f = msg.getEndDate()) && common_date_pb.DateTime.toObject(includeInstance, f),
      periodId: jspb.Message.getFieldWithDefault(msg, 3, 0),
      currentPeriod: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
      personHashesList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f,
      activityTemplateId: jspb.Message.getFieldWithDefault(msg, 6, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!ListFlexTimeActivityInstancesRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new ListFlexTimeActivityInstancesRequest;
    return ListFlexTimeActivityInstancesRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!ListFlexTimeActivityInstancesRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!ListFlexTimeActivityInstancesRequest}
   */
  static deserializeBinaryFromReader(msg: ListFlexTimeActivityInstancesRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new common_date_pb.DateTime;
        reader.readMessage(value1,common_date_pb.DateTime.deserializeBinaryFromReader);
        msg.setStartDate(value1);
        break;
      case 2:
        var value2 = new common_date_pb.DateTime;
        reader.readMessage(value2,common_date_pb.DateTime.deserializeBinaryFromReader);
        msg.setEndDate(value2);
        break;
      case 3:
        var value3 = /** @type {number} */ (reader.readInt32());
        msg.setPeriodId(value3);
        break;
      case 4:
        var value4 = /** @type {boolean} */ (reader.readBool());
        msg.setCurrentPeriod(value4);
        break;
      case 5:
        var value5 = /** @type {string} */ (reader.readString());
        msg.addPersonHashes(value5);
        break;
      case 6:
        var value6 = /** @type {number} */ (reader.readInt32());
        msg.setActivityTemplateId(value6);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!ListFlexTimeActivityInstancesRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: ListFlexTimeActivityInstancesRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getStartDate();
    if (f != null) {
      writer.writeMessage(
        1,
        f,
        common_date_pb.DateTime.serializeBinaryToWriter
      );
    }
    f = message.getEndDate();
    if (f != null) {
      writer.writeMessage(
        2,
        f,
        common_date_pb.DateTime.serializeBinaryToWriter
      );
    }
    f = message.getPeriodId();
    if (f !== 0) {
      writer.writeInt32(
        3,
        f
      );
    }
    f = message.getCurrentPeriod();
    if (f) {
      writer.writeBool(
        4,
        f
      );
    }
    f = message.getPersonHashesList();
    if (f.length > 0) {
      writer.writeRepeatedString(
        5,
        f
      );
    }
    f = message.getActivityTemplateId();
    if (f !== 0) {
      writer.writeInt32(
        6,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    ListFlexTimeActivityInstancesRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional minga.common.DateTime start_date = 1;
   * @return {?DateTime}
   */
  getStartDate(): common_date_pb.DateTime {
    return /** @type{?DateTime} */ (
      jspb.Message.getWrapperField(this, common_date_pb.DateTime, 1));
  }


  /** @param {?DateTime|undefined} value */
  setStartDate(value?: common_date_pb.DateTime) {
    jspb.Message.setWrapperField(this, 1, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearStartDate() {
    this.setStartDate(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasStartDate(): boolean {
    return jspb.Message.getField(this, 1) != null;
  }


  /**
   * optional minga.common.DateTime end_date = 2;
   * @return {?DateTime}
   */
  getEndDate(): common_date_pb.DateTime {
    return /** @type{?DateTime} */ (
      jspb.Message.getWrapperField(this, common_date_pb.DateTime, 2));
  }


  /** @param {?DateTime|undefined} value */
  setEndDate(value?: common_date_pb.DateTime) {
    jspb.Message.setWrapperField(this, 2, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearEndDate() {
    this.setEndDate(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasEndDate(): boolean {
    return jspb.Message.getField(this, 2) != null;
  }


  /**
   * optional int32 period_id = 3;
   * @return {number}
   */
  getPeriodId(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
  };


  /** @param {number} value */
  setPeriodId(value: number) {
    jspb.Message.setProto3IntField(this, 3, value);
  }


  /**
   * optional bool current_period = 4;
   * @return {boolean}
   */
  getCurrentPeriod(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
  };


  /** @param {boolean} value */
  setCurrentPeriod(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 4, value);
  }


  /**
   * repeated string person_hashes = 5;
   * @return {!Array<string>}
   */
  getPersonHashesList(): string[] {
    return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 5));
  };


  /** @param {!Array<string>} value */
  setPersonHashesList(value: string[]) {
    jspb.Message.setField(this, 5, value || []);
  }


  /**
   * @param {string} value
   * @param {number=} opt_index
   */
  addPersonHashes(value: string, opt_index?: number): void {
    jspb.Message.addToRepeatedField(this, 5, value, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearPersonHashesList() {
    this.setPersonHashesList([]);
  }


  /**
   * optional int32 activity_template_id = 6;
   * @return {number}
   */
  getActivityTemplateId(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
  };


  /** @param {number} value */
  setActivityTemplateId(value: number) {
    jspb.Message.setProto3IntField(this, 6, value);
  }


}
export namespace ListFlexTimeActivityInstancesResponse {
  export interface AsObject {
    flexTimeActivityInstancesList: FlexTimeActivityInstance.AsObject[];
  }
}
export class ListFlexTimeActivityInstancesResponse extends jspb.Message {
  static readonly displayName = "ListFlexTimeActivityInstancesResponse";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [1];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, ListFlexTimeActivityInstancesResponse.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): ListFlexTimeActivityInstancesResponse.AsObject {
    return ListFlexTimeActivityInstancesResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!ListFlexTimeActivityInstancesResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: ListFlexTimeActivityInstancesResponse) {
    var f: any;
    var obj: any = {
      flexTimeActivityInstancesList: jspb.Message.toObjectList(msg.getFlexTimeActivityInstancesList(),
      FlexTimeActivityInstance.toObject, includeInstance)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!ListFlexTimeActivityInstancesResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new ListFlexTimeActivityInstancesResponse;
    return ListFlexTimeActivityInstancesResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!ListFlexTimeActivityInstancesResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!ListFlexTimeActivityInstancesResponse}
   */
  static deserializeBinaryFromReader(msg: ListFlexTimeActivityInstancesResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new FlexTimeActivityInstance;
        reader.readMessage(value1,FlexTimeActivityInstance.deserializeBinaryFromReader);
        msg.addFlexTimeActivityInstances(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!ListFlexTimeActivityInstancesResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: ListFlexTimeActivityInstancesResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getFlexTimeActivityInstancesList();
    if (f.length > 0) {
      writer.writeRepeatedMessage(
        1,
        f,
        FlexTimeActivityInstance.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    ListFlexTimeActivityInstancesResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * repeated FlexTimeActivityInstance flex_time_activity_instances = 1;
   * @return {!Array<!FlexTimeActivityInstance>}
   */
  getFlexTimeActivityInstancesList(): FlexTimeActivityInstance[] {
    return /** @type{!Array<!FlexTimeActivityInstance>} */ (
      jspb.Message.getRepeatedWrapperField(this, FlexTimeActivityInstance, 1));
  }


  /** @param {!Array<!FlexTimeActivityInstance>} value */
  setFlexTimeActivityInstancesList(value?: FlexTimeActivityInstance[]) {
    jspb.Message.setRepeatedWrapperField(this, 1, value);
  }


  /**
   * @param {!FlexTimeActivityInstance=} opt_value
   * @param {number=} opt_index
   * @return {!FlexTimeActivityInstance}
   */
  addFlexTimeActivityInstances(opt_value?: FlexTimeActivityInstance, opt_index?: number): FlexTimeActivityInstance {
    return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, FlexTimeActivityInstance, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearFlexTimeActivityInstancesList() {
    this.setFlexTimeActivityInstancesList([]);
  }


}
export namespace UpsertFlexTimeActivityInstanceRequest {
  export interface AsObject {
    flexTimeActivityInstancesList: FlexTimeActivityInstance.AsObject[];
  }
}
export class UpsertFlexTimeActivityInstanceRequest extends jspb.Message {
  static readonly displayName = "UpsertFlexTimeActivityInstanceRequest";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [1];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, UpsertFlexTimeActivityInstanceRequest.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): UpsertFlexTimeActivityInstanceRequest.AsObject {
    return UpsertFlexTimeActivityInstanceRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!UpsertFlexTimeActivityInstanceRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: UpsertFlexTimeActivityInstanceRequest) {
    var f: any;
    var obj: any = {
      flexTimeActivityInstancesList: jspb.Message.toObjectList(msg.getFlexTimeActivityInstancesList(),
      FlexTimeActivityInstance.toObject, includeInstance)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!UpsertFlexTimeActivityInstanceRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new UpsertFlexTimeActivityInstanceRequest;
    return UpsertFlexTimeActivityInstanceRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!UpsertFlexTimeActivityInstanceRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!UpsertFlexTimeActivityInstanceRequest}
   */
  static deserializeBinaryFromReader(msg: UpsertFlexTimeActivityInstanceRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new FlexTimeActivityInstance;
        reader.readMessage(value1,FlexTimeActivityInstance.deserializeBinaryFromReader);
        msg.addFlexTimeActivityInstances(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!UpsertFlexTimeActivityInstanceRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: UpsertFlexTimeActivityInstanceRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getFlexTimeActivityInstancesList();
    if (f.length > 0) {
      writer.writeRepeatedMessage(
        1,
        f,
        FlexTimeActivityInstance.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    UpsertFlexTimeActivityInstanceRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * repeated FlexTimeActivityInstance flex_time_activity_instances = 1;
   * @return {!Array<!FlexTimeActivityInstance>}
   */
  getFlexTimeActivityInstancesList(): FlexTimeActivityInstance[] {
    return /** @type{!Array<!FlexTimeActivityInstance>} */ (
      jspb.Message.getRepeatedWrapperField(this, FlexTimeActivityInstance, 1));
  }


  /** @param {!Array<!FlexTimeActivityInstance>} value */
  setFlexTimeActivityInstancesList(value?: FlexTimeActivityInstance[]) {
    jspb.Message.setRepeatedWrapperField(this, 1, value);
  }


  /**
   * @param {!FlexTimeActivityInstance=} opt_value
   * @param {number=} opt_index
   * @return {!FlexTimeActivityInstance}
   */
  addFlexTimeActivityInstances(opt_value?: FlexTimeActivityInstance, opt_index?: number): FlexTimeActivityInstance {
    return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, FlexTimeActivityInstance, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearFlexTimeActivityInstancesList() {
    this.setFlexTimeActivityInstancesList([]);
  }


}
export namespace UpsertFlexTimeActivityInstanceResponse {
  export interface AsObject {
    flexTimeActivityInstancesList: FlexTimeActivityInstance.AsObject[];
  }
}
export class UpsertFlexTimeActivityInstanceResponse extends jspb.Message {
  static readonly displayName = "UpsertFlexTimeActivityInstanceResponse";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [1];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, UpsertFlexTimeActivityInstanceResponse.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): UpsertFlexTimeActivityInstanceResponse.AsObject {
    return UpsertFlexTimeActivityInstanceResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!UpsertFlexTimeActivityInstanceResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: UpsertFlexTimeActivityInstanceResponse) {
    var f: any;
    var obj: any = {
      flexTimeActivityInstancesList: jspb.Message.toObjectList(msg.getFlexTimeActivityInstancesList(),
      FlexTimeActivityInstance.toObject, includeInstance)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!UpsertFlexTimeActivityInstanceResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new UpsertFlexTimeActivityInstanceResponse;
    return UpsertFlexTimeActivityInstanceResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!UpsertFlexTimeActivityInstanceResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!UpsertFlexTimeActivityInstanceResponse}
   */
  static deserializeBinaryFromReader(msg: UpsertFlexTimeActivityInstanceResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new FlexTimeActivityInstance;
        reader.readMessage(value1,FlexTimeActivityInstance.deserializeBinaryFromReader);
        msg.addFlexTimeActivityInstances(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!UpsertFlexTimeActivityInstanceResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: UpsertFlexTimeActivityInstanceResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getFlexTimeActivityInstancesList();
    if (f.length > 0) {
      writer.writeRepeatedMessage(
        1,
        f,
        FlexTimeActivityInstance.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    UpsertFlexTimeActivityInstanceResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * repeated FlexTimeActivityInstance flex_time_activity_instances = 1;
   * @return {!Array<!FlexTimeActivityInstance>}
   */
  getFlexTimeActivityInstancesList(): FlexTimeActivityInstance[] {
    return /** @type{!Array<!FlexTimeActivityInstance>} */ (
      jspb.Message.getRepeatedWrapperField(this, FlexTimeActivityInstance, 1));
  }


  /** @param {!Array<!FlexTimeActivityInstance>} value */
  setFlexTimeActivityInstancesList(value?: FlexTimeActivityInstance[]) {
    jspb.Message.setRepeatedWrapperField(this, 1, value);
  }


  /**
   * @param {!FlexTimeActivityInstance=} opt_value
   * @param {number=} opt_index
   * @return {!FlexTimeActivityInstance}
   */
  addFlexTimeActivityInstances(opt_value?: FlexTimeActivityInstance, opt_index?: number): FlexTimeActivityInstance {
    return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, FlexTimeActivityInstance, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearFlexTimeActivityInstancesList() {
    this.setFlexTimeActivityInstancesList([]);
  }


}
export namespace DeleteFlexTimeActivityInstanceRequest {
  export interface AsObject {
    id: number;
  }
}
export class DeleteFlexTimeActivityInstanceRequest extends jspb.Message {
  static readonly displayName = "DeleteFlexTimeActivityInstanceRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): DeleteFlexTimeActivityInstanceRequest.AsObject {
    return DeleteFlexTimeActivityInstanceRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!DeleteFlexTimeActivityInstanceRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: DeleteFlexTimeActivityInstanceRequest) {
    var f: any;
    var obj: any = {
      id: jspb.Message.getFieldWithDefault(msg, 1, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!DeleteFlexTimeActivityInstanceRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new DeleteFlexTimeActivityInstanceRequest;
    return DeleteFlexTimeActivityInstanceRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!DeleteFlexTimeActivityInstanceRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!DeleteFlexTimeActivityInstanceRequest}
   */
  static deserializeBinaryFromReader(msg: DeleteFlexTimeActivityInstanceRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {number} */ (reader.readInt32());
        msg.setId(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!DeleteFlexTimeActivityInstanceRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: DeleteFlexTimeActivityInstanceRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getId();
    if (f !== 0) {
      writer.writeInt32(
        1,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    DeleteFlexTimeActivityInstanceRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional int32 id = 1;
   * @return {number}
   */
  getId(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
  };


  /** @param {number} value */
  setId(value: number) {
    jspb.Message.setProto3IntField(this, 1, value);
  }


}
export namespace DeleteFlexTimeActivityInstanceResponse {
  export interface AsObject {
  }
}
export class DeleteFlexTimeActivityInstanceResponse extends jspb.Message {
  static readonly displayName = "DeleteFlexTimeActivityInstanceResponse";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): DeleteFlexTimeActivityInstanceResponse.AsObject {
    return DeleteFlexTimeActivityInstanceResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!DeleteFlexTimeActivityInstanceResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: DeleteFlexTimeActivityInstanceResponse) {
    var f: any;
    var obj: any = {

    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!DeleteFlexTimeActivityInstanceResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new DeleteFlexTimeActivityInstanceResponse;
    return DeleteFlexTimeActivityInstanceResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!DeleteFlexTimeActivityInstanceResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!DeleteFlexTimeActivityInstanceResponse}
   */
  static deserializeBinaryFromReader(msg: DeleteFlexTimeActivityInstanceResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!DeleteFlexTimeActivityInstanceResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: DeleteFlexTimeActivityInstanceResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    DeleteFlexTimeActivityInstanceResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
}
export namespace GetFlexTimeActivityTypesRequest {
  export interface AsObject {
  }
}
export class GetFlexTimeActivityTypesRequest extends jspb.Message {
  static readonly displayName = "GetFlexTimeActivityTypesRequest";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): GetFlexTimeActivityTypesRequest.AsObject {
    return GetFlexTimeActivityTypesRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!GetFlexTimeActivityTypesRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: GetFlexTimeActivityTypesRequest) {
    var f: any;
    var obj: any = {

    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!GetFlexTimeActivityTypesRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new GetFlexTimeActivityTypesRequest;
    return GetFlexTimeActivityTypesRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!GetFlexTimeActivityTypesRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!GetFlexTimeActivityTypesRequest}
   */
  static deserializeBinaryFromReader(msg: GetFlexTimeActivityTypesRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!GetFlexTimeActivityTypesRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: GetFlexTimeActivityTypesRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    GetFlexTimeActivityTypesRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
}
export namespace GetFlexTimeActivityTypesResponse {
  export interface AsObject {
    activityTypesList: misc_category_pb.Category.AsObject[];
  }
}
export class GetFlexTimeActivityTypesResponse extends jspb.Message {
  static readonly displayName = "GetFlexTimeActivityTypesResponse";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [1];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, GetFlexTimeActivityTypesResponse.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): GetFlexTimeActivityTypesResponse.AsObject {
    return GetFlexTimeActivityTypesResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!GetFlexTimeActivityTypesResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: GetFlexTimeActivityTypesResponse) {
    var f: any;
    var obj: any = {
      activityTypesList: jspb.Message.toObjectList(msg.getActivityTypesList(),
      misc_category_pb.Category.toObject, includeInstance)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!GetFlexTimeActivityTypesResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new GetFlexTimeActivityTypesResponse;
    return GetFlexTimeActivityTypesResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!GetFlexTimeActivityTypesResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!GetFlexTimeActivityTypesResponse}
   */
  static deserializeBinaryFromReader(msg: GetFlexTimeActivityTypesResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new misc_category_pb.Category;
        reader.readMessage(value1,misc_category_pb.Category.deserializeBinaryFromReader);
        msg.addActivityTypes(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!GetFlexTimeActivityTypesResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: GetFlexTimeActivityTypesResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getActivityTypesList();
    if (f.length > 0) {
      writer.writeRepeatedMessage(
        1,
        f,
        misc_category_pb.Category.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    GetFlexTimeActivityTypesResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * repeated minga.category.Category activity_types = 1;
   * @return {!Array<!Category>}
   */
  getActivityTypesList(): misc_category_pb.Category[] {
    return /** @type{!Array<!Category>} */ (
      jspb.Message.getRepeatedWrapperField(this, misc_category_pb.Category, 1));
  }


  /** @param {!Array<!Category>} value */
  setActivityTypesList(value?: misc_category_pb.Category[]) {
    jspb.Message.setRepeatedWrapperField(this, 1, value);
  }


  /**
   * @param {!Category=} opt_value
   * @param {number=} opt_index
   * @return {!Category}
   */
  addActivityTypes(opt_value?: misc_category_pb.Category, opt_index?: number): misc_category_pb.Category {
    return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, misc_category_pb.Category, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearActivityTypesList() {
    this.setActivityTypesList([]);
  }


}
export namespace Person {
  export interface AsObject {
    hash: string;
    name: string;
  }
}
export class Person extends jspb.Message {
  static readonly displayName = "Person";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): Person.AsObject {
    return Person.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!Person} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: Person) {
    var f: any;
    var obj: any = {
      hash: jspb.Message.getFieldWithDefault(msg, 1, ""),
      name: jspb.Message.getFieldWithDefault(msg, 2, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!Person}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new Person;
    return Person.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!Person} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!Person}
   */
  static deserializeBinaryFromReader(msg: Person, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setHash(value1);
        break;
      case 2:
        var value2 = /** @type {string} */ (reader.readString());
        msg.setName(value2);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!Person} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: Person, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getHash();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
    f = message.getName();
    if (f.length > 0) {
      writer.writeString(
        2,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    Person.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string hash = 1;
   * @return {string}
   */
  getHash(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setHash(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


  /**
   * optional string name = 2;
   * @return {string}
   */
  getName(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
  };


  /** @param {string} value */
  setName(value: string) {
    jspb.Message.setProto3StringField(this, 2, value);
  }


}
export namespace ExistingRegistration {
  export interface AsObject {
    activityName: string;
    activityInstanceId: number;
    person?: Person.AsObject;
    registeredBy?: Person.AsObject;
    activityTeacherName: string;
    cannotUnregister: boolean;
    activityLocation: string;
  }
}
export class ExistingRegistration extends jspb.Message {
  static readonly displayName = "ExistingRegistration";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): ExistingRegistration.AsObject {
    return ExistingRegistration.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!ExistingRegistration} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: ExistingRegistration) {
    var f: any;
    var obj: any = {
      activityName: jspb.Message.getFieldWithDefault(msg, 2, ""),
      activityInstanceId: jspb.Message.getFieldWithDefault(msg, 3, 0),
      person: (f = msg.getPerson()) && Person.toObject(includeInstance, f),
      registeredBy: (f = msg.getRegisteredBy()) && Person.toObject(includeInstance, f),
      activityTeacherName: jspb.Message.getFieldWithDefault(msg, 6, ""),
      cannotUnregister: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
      activityLocation: jspb.Message.getFieldWithDefault(msg, 8, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!ExistingRegistration}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new ExistingRegistration;
    return ExistingRegistration.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!ExistingRegistration} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!ExistingRegistration}
   */
  static deserializeBinaryFromReader(msg: ExistingRegistration, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 2:
        var value2 = /** @type {string} */ (reader.readString());
        msg.setActivityName(value2);
        break;
      case 3:
        var value3 = /** @type {number} */ (reader.readInt32());
        msg.setActivityInstanceId(value3);
        break;
      case 4:
        var value4 = new Person;
        reader.readMessage(value4,Person.deserializeBinaryFromReader);
        msg.setPerson(value4);
        break;
      case 5:
        var value5 = new Person;
        reader.readMessage(value5,Person.deserializeBinaryFromReader);
        msg.setRegisteredBy(value5);
        break;
      case 6:
        var value6 = /** @type {string} */ (reader.readString());
        msg.setActivityTeacherName(value6);
        break;
      case 7:
        var value7 = /** @type {boolean} */ (reader.readBool());
        msg.setCannotUnregister(value7);
        break;
      case 8:
        var value8 = /** @type {string} */ (reader.readString());
        msg.setActivityLocation(value8);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!ExistingRegistration} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: ExistingRegistration, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getActivityName();
    if (f.length > 0) {
      writer.writeString(
        2,
        f
      );
    }
    f = message.getActivityInstanceId();
    if (f !== 0) {
      writer.writeInt32(
        3,
        f
      );
    }
    f = message.getPerson();
    if (f != null) {
      writer.writeMessage(
        4,
        f,
        Person.serializeBinaryToWriter
      );
    }
    f = message.getRegisteredBy();
    if (f != null) {
      writer.writeMessage(
        5,
        f,
        Person.serializeBinaryToWriter
      );
    }
    f = message.getActivityTeacherName();
    if (f.length > 0) {
      writer.writeString(
        6,
        f
      );
    }
    f = message.getCannotUnregister();
    if (f) {
      writer.writeBool(
        7,
        f
      );
    }
    f = message.getActivityLocation();
    if (f.length > 0) {
      writer.writeString(
        8,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    ExistingRegistration.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string activity_name = 2;
   * @return {string}
   */
  getActivityName(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
  };


  /** @param {string} value */
  setActivityName(value: string) {
    jspb.Message.setProto3StringField(this, 2, value);
  }


  /**
   * optional int32 activity_instance_id = 3;
   * @return {number}
   */
  getActivityInstanceId(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
  };


  /** @param {number} value */
  setActivityInstanceId(value: number) {
    jspb.Message.setProto3IntField(this, 3, value);
  }


  /**
   * optional Person person = 4;
   * @return {?Person}
   */
  getPerson(): Person {
    return /** @type{?Person} */ (
      jspb.Message.getWrapperField(this, Person, 4));
  }


  /** @param {?Person|undefined} value */
  setPerson(value?: Person) {
    jspb.Message.setWrapperField(this, 4, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearPerson() {
    this.setPerson(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasPerson(): boolean {
    return jspb.Message.getField(this, 4) != null;
  }


  /**
   * optional Person registered_by = 5;
   * @return {?Person}
   */
  getRegisteredBy(): Person {
    return /** @type{?Person} */ (
      jspb.Message.getWrapperField(this, Person, 5));
  }


  /** @param {?Person|undefined} value */
  setRegisteredBy(value?: Person) {
    jspb.Message.setWrapperField(this, 5, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearRegisteredBy() {
    this.setRegisteredBy(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasRegisteredBy(): boolean {
    return jspb.Message.getField(this, 5) != null;
  }


  /**
   * optional string activity_teacher_name = 6;
   * @return {string}
   */
  getActivityTeacherName(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
  };


  /** @param {string} value */
  setActivityTeacherName(value: string) {
    jspb.Message.setProto3StringField(this, 6, value);
  }


  /**
   * optional bool cannot_unregister = 7;
   * @return {boolean}
   */
  getCannotUnregister(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
  };


  /** @param {boolean} value */
  setCannotUnregister(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 7, value);
  }


  /**
   * optional string activity_location = 8;
   * @return {string}
   */
  getActivityLocation(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
  };


  /** @param {string} value */
  setActivityLocation(value: string) {
    jspb.Message.setProto3StringField(this, 8, value);
  }


}
export namespace ValidateAssignRequest {
  export interface AsObject {
    periodId: number;
    personHashesList: string[];
  }
}
export class ValidateAssignRequest extends jspb.Message {
  static readonly displayName = "ValidateAssignRequest";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [2];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, ValidateAssignRequest.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): ValidateAssignRequest.AsObject {
    return ValidateAssignRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!ValidateAssignRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: ValidateAssignRequest) {
    var f: any;
    var obj: any = {
      periodId: jspb.Message.getFieldWithDefault(msg, 1, 0),
      personHashesList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!ValidateAssignRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new ValidateAssignRequest;
    return ValidateAssignRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!ValidateAssignRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!ValidateAssignRequest}
   */
  static deserializeBinaryFromReader(msg: ValidateAssignRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {number} */ (reader.readInt32());
        msg.setPeriodId(value1);
        break;
      case 2:
        var value2 = /** @type {string} */ (reader.readString());
        msg.addPersonHashes(value2);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!ValidateAssignRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: ValidateAssignRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getPeriodId();
    if (f !== 0) {
      writer.writeInt32(
        1,
        f
      );
    }
    f = message.getPersonHashesList();
    if (f.length > 0) {
      writer.writeRepeatedString(
        2,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    ValidateAssignRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional int32 period_id = 1;
   * @return {number}
   */
  getPeriodId(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
  };


  /** @param {number} value */
  setPeriodId(value: number) {
    jspb.Message.setProto3IntField(this, 1, value);
  }


  /**
   * repeated string person_hashes = 2;
   * @return {!Array<string>}
   */
  getPersonHashesList(): string[] {
    return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
  };


  /** @param {!Array<string>} value */
  setPersonHashesList(value: string[]) {
    jspb.Message.setField(this, 2, value || []);
  }


  /**
   * @param {string} value
   * @param {number=} opt_index
   */
  addPersonHashes(value: string, opt_index?: number): void {
    jspb.Message.addToRepeatedField(this, 2, value, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearPersonHashesList() {
    this.setPersonHashesList([]);
  }


}
export namespace ValidateAssignResponse {
  export interface AsObject {
    registrationList: ExistingRegistration.AsObject[];
  }
}
export class ValidateAssignResponse extends jspb.Message {
  static readonly displayName = "ValidateAssignResponse";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [1];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, ValidateAssignResponse.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): ValidateAssignResponse.AsObject {
    return ValidateAssignResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!ValidateAssignResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: ValidateAssignResponse) {
    var f: any;
    var obj: any = {
      registrationList: jspb.Message.toObjectList(msg.getRegistrationList(),
      ExistingRegistration.toObject, includeInstance)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!ValidateAssignResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new ValidateAssignResponse;
    return ValidateAssignResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!ValidateAssignResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!ValidateAssignResponse}
   */
  static deserializeBinaryFromReader(msg: ValidateAssignResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new ExistingRegistration;
        reader.readMessage(value1,ExistingRegistration.deserializeBinaryFromReader);
        msg.addRegistration(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!ValidateAssignResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: ValidateAssignResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getRegistrationList();
    if (f.length > 0) {
      writer.writeRepeatedMessage(
        1,
        f,
        ExistingRegistration.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    ValidateAssignResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * repeated ExistingRegistration registration = 1;
   * @return {!Array<!ExistingRegistration>}
   */
  getRegistrationList(): ExistingRegistration[] {
    return /** @type{!Array<!ExistingRegistration>} */ (
      jspb.Message.getRepeatedWrapperField(this, ExistingRegistration, 1));
  }


  /** @param {!Array<!ExistingRegistration>} value */
  setRegistrationList(value?: ExistingRegistration[]) {
    jspb.Message.setRepeatedWrapperField(this, 1, value);
  }


  /**
   * @param {!ExistingRegistration=} opt_value
   * @param {number=} opt_index
   * @return {!ExistingRegistration}
   */
  addRegistration(opt_value?: ExistingRegistration, opt_index?: number): ExistingRegistration {
    return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, ExistingRegistration, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearRegistrationList() {
    this.setRegistrationList([]);
  }


}
export namespace ArchiveFlexTimeRegistrationRequest {
  export interface AsObject {
    idsList: number[];
  }
}
export class ArchiveFlexTimeRegistrationRequest extends jspb.Message {
  static readonly displayName = "ArchiveFlexTimeRegistrationRequest";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [1];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, ArchiveFlexTimeRegistrationRequest.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): ArchiveFlexTimeRegistrationRequest.AsObject {
    return ArchiveFlexTimeRegistrationRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!ArchiveFlexTimeRegistrationRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: ArchiveFlexTimeRegistrationRequest) {
    var f: any;
    var obj: any = {
      idsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!ArchiveFlexTimeRegistrationRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new ArchiveFlexTimeRegistrationRequest;
    return ArchiveFlexTimeRegistrationRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!ArchiveFlexTimeRegistrationRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!ArchiveFlexTimeRegistrationRequest}
   */
  static deserializeBinaryFromReader(msg: ArchiveFlexTimeRegistrationRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {!Array<number>} */ (reader.readPackedInt32());
        msg.setIdsList(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!ArchiveFlexTimeRegistrationRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: ArchiveFlexTimeRegistrationRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getIdsList();
    if (f.length > 0) {
      writer.writePackedInt32(
        1,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    ArchiveFlexTimeRegistrationRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * repeated int32 ids = 1;
   * @return {!Array<number>}
   */
  getIdsList(): number[] {
    return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 1));
  };


  /** @param {!Array<number>} value */
  setIdsList(value: number[]) {
    jspb.Message.setField(this, 1, value || []);
  }


  /**
   * @param {number} value
   * @param {number=} opt_index
   */
  addIds(value: number, opt_index?: number): void {
    jspb.Message.addToRepeatedField(this, 1, value, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearIdsList() {
    this.setIdsList([]);
  }


}
export namespace ArchiveFlexTimeRegistrationResponse {
  export interface AsObject {
  }
}
export class ArchiveFlexTimeRegistrationResponse extends jspb.Message {
  static readonly displayName = "ArchiveFlexTimeRegistrationResponse";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): ArchiveFlexTimeRegistrationResponse.AsObject {
    return ArchiveFlexTimeRegistrationResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!ArchiveFlexTimeRegistrationResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: ArchiveFlexTimeRegistrationResponse) {
    var f: any;
    var obj: any = {

    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!ArchiveFlexTimeRegistrationResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new ArchiveFlexTimeRegistrationResponse;
    return ArchiveFlexTimeRegistrationResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!ArchiveFlexTimeRegistrationResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!ArchiveFlexTimeRegistrationResponse}
   */
  static deserializeBinaryFromReader(msg: ArchiveFlexTimeRegistrationResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!ArchiveFlexTimeRegistrationResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: ArchiveFlexTimeRegistrationResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    ArchiveFlexTimeRegistrationResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
}
