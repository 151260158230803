// GENERATED CODE -- DO NOT EDIT!

import {grpc} from '@improbable-eng/grpc-web';

import * as content_announcement_pb from '../content/announcement_pb';
import * as content_common_pb from '../content/common_pb';
import * as common_delta_pb from '../common/delta_pb';

export class Announcement {
  static readonly serviceName = "minga.content.Announcement";

  static readonly New = {
    methodName: "New",
    service: Announcement,
    requestStream: false,
    responseStream: false,
    requestType: content_announcement_pb.AnnouncementInfo,
    responseType: content_common_pb.ContentSaveResult,
  };

  static readonly Update = {
    methodName: "Update",
    service: Announcement,
    requestStream: false,
    responseStream: false,
    requestType: content_announcement_pb.DeltaAnnouncementInfo,
    responseType: content_common_pb.ContentSaveResult,
  };

  static readonly GetInfo = {
    methodName: "GetInfo",
    service: Announcement,
    requestStream: false,
    responseStream: false,
    requestType: content_common_pb.ContentInfoQuery,
    responseType: content_announcement_pb.AnnouncementInfo,
  };

}

export class AnnouncementClient {
  new() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  update() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  getInfo() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
}
