import { IPbisAutomationCounterResult } from 'libs/domain';
import { pbis_stats_pb } from 'libs/generated-grpc-ts';

import { IReportPersonMapper } from '../person';

export const toProto = (
  item: IPbisAutomationCounterResult,
): pbis_stats_pb.PbisAutomationStats => {
  const msg = new pbis_stats_pb.PbisAutomationStats();
  msg.setComplete(item.complete);
  msg.setIncomplete(item.incomplete);
  msg.setOverdue(item.overdue);
  msg.setCounter(item.counter);
  msg.setConsequence(item.consequence);
  msg.setAutomationGroupId(item.automationGroupId);
  msg.setAutomationGroup(item.automationGroup);
  msg.setPerson(IReportPersonMapper.toProto(item.person));
  return msg;
};

export const fromProto = (
  msg: pbis_stats_pb.PbisAutomationStats,
): IPbisAutomationCounterResult => ({
  complete: msg.getComplete(),
  incomplete: msg.getIncomplete(),
  overdue: msg.getOverdue(),
  counter: msg.getCounter(),
  consequence: msg.getConsequence(),
  automationGroupId: msg.getAutomationGroupId(),
  automationGroup: msg.getAutomationGroup(),
  person: IReportPersonMapper.fromProto(msg.getPerson()),
});
