import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  TrackByFunction,
  ViewChild,
} from '@angular/core';

import { gateway } from 'libs/generated-grpc-web';
import { ReplaySubject } from 'rxjs';

import { MgStreamScrollerComponent } from '@app/src/app/components/MgStreamScroller';
import { IMgStreamControl } from '@app/src/app/util/stream';

import { IOpenItemEvent } from '../../types';

@Component({
  selector: 'mg-home-gallery-feed',
  templateUrl: './home-gallery-feed.component.html',
  styleUrls: ['./home-gallery-feed.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HomeGalleryFeedComponent implements OnDestroy {
  // Children
  @ViewChild(MgStreamScrollerComponent, { static: true })
  streamScroller!: MgStreamScrollerComponent;

  // Clean up
  private readonly _destroyedSubject = new ReplaySubject<void>(1);

  // Inputs
  @Input() contentHash = '';
  @Input() groupHash = '';
  /** Search query */
  @Input() query = '';
  /** Forward to `<mg-fit-grid>` */
  @Input() maxAspectRatio: any;
  /** Forward to `<mg-fit-grid>` */
  @Input() minAspectRatio: any;

  // Outputs
  @Output()
  openItem: EventEmitter<IOpenItemEvent>;
  /** Forwarded from `<mg-stream-scroller>` */
  @Output()
  streamControlCreated: EventEmitter<IMgStreamControl<any>>;

  /** TrackBy */
  trackBy: TrackByFunction<gateway.gallery_pb.GalleryItem.AsObject>;

  // Computed getters

  get streamService() {
    return this._mingaGalleryProto;
  }

  get filter() {
    const filter: any = {};
    // only add those that are truthy, so that every fiter item is valid
    if (this.query) {
      filter.query = this.query;
    }
    if (this.contentHash) {
      filter.contentHash = this.contentHash;
    }
    if (this.groupHash) {
      filter.groupHash = this.groupHash;
    }
    return filter;
  }

  /** Component constructor */
  constructor(
    private _mingaGalleryProto: gateway.gallery_ng_grpc_pb.MingaGallery,
  ) {
    this.streamControlCreated = new EventEmitter<IMgStreamControl<any>>();
    this.openItem = new EventEmitter<IOpenItemEvent>();
    this.trackBy = (
      index: number,
      item: gateway.gallery_pb.GalleryItem.AsObject,
    ) => {
      return item.galleryPhotoUuid;
    };
  }

  ngOnDestroy(): void {
    this._destroyedSubject.next();
    this._destroyedSubject.complete();
  }

  public restartStream() {
    this.streamScroller.restartStream();
  }

  public getTileWidth(tile: gateway.gallery_pb.GalleryItem.AsObject): number {
    const imageInfo = tile.imageInfo;
    const sizeMap = imageInfo?.sizeMap || [];
    return sizeMap[0][1]?.width || 0;
  }

  public getTileHeight(tile: gateway.gallery_pb.GalleryItem.AsObject): number {
    const imageInfo = tile.imageInfo;
    const sizeMap = imageInfo?.sizeMap || [];
    return sizeMap[0][1]?.height || 0;
  }

  public _openItem(
    itemIndex: number,
    photoIndex: number,
    tile: gateway.gallery_pb.GalleryItem.AsObject,
  ) {
    this.openItem.emit({ itemIndex, photoIndex, tile });
  }
}
