import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { gateway } from 'libs/generated-grpc-web';
import { map, withLatestFrom } from 'rxjs/operators';

import { NotificationActions } from '../actions/notifications.actions';
import { NotificationsState } from '../selectors';

@Injectable()
export class NotificationEffects {
  constructor(
    private _actions$: Actions<NotificationActions.TypeUnion>,
    private _notificationManager: gateway.notification_ng_grpc_pb.NotificationManager,
    private _store: Store<any>,
  ) {}

  viewedNotifications$ = createEffect(() =>
    this._actions$.pipe(
      ofType(NotificationActions.TypeEnum.ViewedNotifications),
      withLatestFrom(this._store.pipe(select(NotificationsState.selectAll))),
      map(([action, notifications]) => {
        const notificationIds = notifications.map(item => item.id) as string[];
        const markViewedRequest =
          new gateway.notification_pb.MarkNotificationsViewedRequest();
        markViewedRequest.setNotificationIdList(notificationIds);
        this._notificationManager.markViewed(markViewedRequest);

        // remove the notifications from the store, because those should
        // only be unviewed notifications.
        return new NotificationActions.RemoveNotificationsAction(
          notificationIds,
        );
      }),
    ),
  );

  /**
   * Mark a notification as read.
   */

  markNotificationAsRead$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(NotificationActions.TypeEnum.MarkNotificationAsRead),
        map(
          (action: NotificationActions.MarkNotificationAsReadAction) =>
            action.payload,
        ),
        withLatestFrom(this._store.pipe(select(NotificationsState.selectAll))),
        map(([notification, notifications]) => {
          let notificationsToRemove: any[] = [];
          if (notification.id) {
            const readNotification = notifications.find(
              e => e.groupHash === notification.groupHash,
            );
            if (readNotification) {
              notification.groupHash = readNotification.groupHash;
            }
          }

          if (notification.groupHash) {
            // if there is a group hash, let's remove any notifications to do with
            // that group ie clicking one 'new content' notification for a group,
            // marks all new content for that group as read.
            notificationsToRemove = notifications.filter(
              e => e.groupHash === notification.groupHash,
            );
          }

          notificationsToRemove.push(notification);

          if (notificationsToRemove.length > 0) {
            // update the store to remove any notifications that were read.
            const notificationIds = notificationsToRemove.map(n => n.id);
            this.setNotificationAsRead(notificationIds);
            this._store.dispatch(
              new NotificationActions.RemoveNotificationsAction(
                notificationIds,
              ),
            );
          }
        }),
      ),
    { dispatch: false },
  );

  setNotificationAsRead(ids: string[]) {
    const markReadRequest =
      new gateway.notification_pb.MarkNotificationsReadRequest();
    markReadRequest.setNotificationIdList(ids);
    this._notificationManager.markRead(markReadRequest);
  }
}
