import { Injectable, NgZone } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { grpc } from '@improbable-eng/grpc-web';

import {
  LoadContentRequest,
  LoadContentResponse,
} from '../gateway/content_views_pb';

import {
  ContentViews as __ContentViews,
} from '../gateway/content_views_pb_service';

/**
  *
The naming convention of this service rpc calls are distinctly different from
the other services because this service has no crud.
  */
@Injectable({providedIn: 'root'})
export class ContentViews {

  constructor(private _ngZone: NgZone) {
  }

  loadContent(request: LoadContentRequest): Promise<LoadContentResponse>;
  loadContent(request: LoadContentRequest, metadata: grpc.Metadata): Promise<LoadContentResponse>;
  loadContent(request: LoadContentRequest, callback: (err: any|null, response: LoadContentResponse, metadata: grpc.Metadata) => void): void;
  loadContent(request: LoadContentRequest, metadata: grpc.Metadata, callback: (err: any|null, response: LoadContentResponse, metadata: grpc.Metadata) => void): void;

  loadContent(request: LoadContentRequest, arg1?: grpc.Metadata|((err: any|null, response: LoadContentResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: LoadContentResponse, metadata: grpc.Metadata) => void): Promise<LoadContentResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<LoadContentResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__ContentViews.loadContent, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

}

