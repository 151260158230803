export enum MingaRoleAvatar {
  SUPERADMIN = '/assets/avatars/superadmin.svg',
  DISTRICT_MANAGER = '/assets/avatars/district-manager.svg',
  OWNER = '/assets/avatars/owner.svg',
  MANAGER = '/assets/avatars/manager.svg',
  STAFF = '/assets/avatars/staff.svg',
  TEACHER = '/assets/avatars/teacher.svg',
  STUDENT = '/assets/avatars/student.svg',
  STUDENT_LEADER = '/assets/avatars/student-leader.svg',
  READ_ONLY = '/assets/avatars/read-only.svg',
  PARENT = '/assets/avatars/parent.svg',
}
