import * as custParse from 'dayjs/plugin/customParseFormat';
import { day } from 'libs/day';

day.extend(custParse);

export const formatTime = (
  timeStr: string,
  format: string = 'h:mm A',
): string => {
  const parsed = day(timeStr || '', ['HH:mm:ss', 'HH:mm']);
  return parsed.isValid() ? parsed.format(format) : '';
};
