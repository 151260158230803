import { ITeam } from 'libs/domain';
import { points_pb } from 'libs/generated-grpc-ts';

export namespace TeamMapper {
  export function toProto(team: ITeam): points_pb.Team {
    const msg = new points_pb.Team();
    if (team.imagePath) {
      msg.setTeamIcon(team.imagePath);
    }
    if (team.teamId) {
      msg.setTeamId(team.teamId);
    }
    if (team.name) {
      msg.setTeamName(team.name);
    }

    if (team.memberCount) {
      msg.setMemberCount(team.memberCount);
    }

    if (team.color) {
      msg.setTeamColor(team.color);
    }

    return msg;
  }

  export function fromProto(msg: points_pb.Team): ITeam {
    const teamId = msg.getTeamId();
    const imagePath = msg.getTeamIcon();
    const name = msg.getTeamName();
    const memberCount = msg.getMemberCount();
    const color = msg.getTeamColor();

    return { teamId, name, imagePath, memberCount, color };
  }
}
