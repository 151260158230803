import { date_pb } from 'libs/generated-grpc-ts';
import { challenge_response_pb } from 'libs/generated-grpc-ts';
import { PersonViewMinimal } from 'libs/generated-grpc-ts';
import { image_pb } from 'libs/generated-grpc-ts';

import { asObjectToMessage } from '../';
import { CommentListPresentationMapper } from '../content';

export namespace ChallengeResponsePresentationMapper {
  export function fromPresentationToChallengeResponseCardMessage(
    cardData: challenge_response_pb.ChallengeResponseCard.AsObject,
  ): challenge_response_pb.ChallengeResponseCard {
    const msg = new challenge_response_pb.ChallengeResponseCard();
    msg.setAuthorPersonView(
      asObjectToMessage(cardData.authorPersonView, new PersonViewMinimal()),
    );
    msg.setBackgroundBannerHash(cardData.backgroundBannerHash);
    msg.setBackgroundColor(cardData.backgroundColor);
    msg.setBody(cardData.body);
    msg.setCommentCount(cardData.commentCount);
    if (cardData.commentList) {
      const comments =
        CommentListPresentationMapper.fromPresentationToCommentContentMessage(
          cardData.commentList,
        );

      msg.setCommentList(comments);
    } else {
      msg.setCommentList([]);
    }

    msg.setCommentListPage(cardData.commentListPage);
    msg.setContentHash(cardData.contentHash);
    msg.setContextHash(cardData.contextHash);
    msg.setLikeCount(cardData.likeCount);
    msg.setOwnerContextHash(cardData.ownerContextHash);
    msg.setPrivacy(cardData.privacy);
    msg.setPublishDate(
      asObjectToMessage(cardData.publishDate, new date_pb.DateTime()),
    );
    msg.setTotalCommentCount(cardData.totalCommentCount);
    msg.setType(cardData.type);
    msg.setApproved(cardData.approved);
    const imgInfo = cardData?.featuredImage;
    if (imgInfo) {
      const imageInfo = new image_pb.ImageInfo();
      let sizeMap = imageInfo.getSizeMap();

      for (let [key, value] of imgInfo.sizeMap) {
        const imgSize = new image_pb.ImageSize();
        imgSize.setPath(value.path);
        imgSize.setWidth(value.width);
        imgSize.setHeight(value.height);

        sizeMap.set(key, imgSize);
      }

      msg.setFeaturedImage(imageInfo);
    }

    const bgImgInfo = cardData?.backgroundImage;
    if (bgImgInfo) {
      const imageInfo = new image_pb.ImageInfo();
      let sizeMap = imageInfo.getSizeMap();

      for (let [key, value] of bgImgInfo.sizeMap) {
        const imgSize = new image_pb.ImageSize();
        imgSize.setPath(value.path);
        imgSize.setWidth(value.width);
        imgSize.setHeight(value.height);

        sizeMap.set(key, imgSize);
      }
      msg.setBackgroundImage(imageInfo);
    }
    // msg.setVideo(cardData.video);

    return msg;
  }

  export function fromPresentationToChallengeResponseListItemMessage(
    cardData: challenge_response_pb.ChallengeResponseCard.AsObject,
  ): challenge_response_pb.ChallengeResponseListItem {
    const msg = new challenge_response_pb.ChallengeResponseListItem();
    msg.setAuthorPersonView(
      asObjectToMessage(cardData.authorPersonView, new PersonViewMinimal()),
    );
    msg.setBody(cardData.body);
    msg.setCommentCount(cardData.commentCount);

    msg.setContentHash(cardData.contentHash);
    msg.setContextHash(cardData.contextHash);
    msg.setLikeCount(cardData.likeCount);
    msg.setOwnerContextHash(cardData.ownerContextHash);
    msg.setPublishDate(
      asObjectToMessage(cardData.publishDate, new date_pb.DateTime()),
    );
    const imgInfo = cardData?.featuredImage;
    if (imgInfo) {
      const imageInfo = new image_pb.ImageInfo();
      let sizeMap = imageInfo.getSizeMap();

      for (let [key, value] of imgInfo.sizeMap) {
        const imgSize = new image_pb.ImageSize();
        imgSize.setPath(value.path);
        imgSize.setWidth(value.width);
        imgSize.setHeight(value.height);

        sizeMap.set(key, imgSize);
      }
      msg.setFeaturedImage(imageInfo);
    }
    msg.setApproved(cardData.approved);
    // msg.setVideo(cardData.video);

    return msg;
  }
}
