import { Injectable, NgZone } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { grpc } from '@improbable-eng/grpc-web';

import {
  ActivateRosteringMethodRequest,
  ActivateRosteringMethodResponse,
  AddMingaLinkRequest,
  AddMingaLinkResponse,
  ChangeCustomJoinCodeRequest,
  ChangeCustomJoinCodeResponse,
  CreateFilesRequest,
  CreateMingasRequest,
  CreateMingasResponse,
  CreateUserMingaJoinCodeRequest,
  CreateUserMingaJoinCodeResponse,
  DeactivateAllRosteringMethodsRequest,
  DeactivateAllRosteringMethodsResponse,
  DeleteMingaDistrictRequest,
  DeleteMingaDistrictResponse,
  DeleteMingasRequest,
  DeleteMingasResponse,
  DeleteSISRequest,
  DeleteSISResponse,
  ExportDistrictsChunk,
  ExportDistrictsRequest,
  GenerateMingaSFTPGoUserRequest,
  GenerateMingaSFTPGoUserResponse,
  GetActiveMingaSubscriptionRequest,
  GetActiveMingaSubscriptionResponse,
  GetMingaDistrictRequest,
  GetMingaDistrictResponse,
  GetMingaDistrictsRequest,
  GetMingaDistrictsResponse,
  GetMingaFeatureTogglesRequest,
  GetMingaFeatureTogglesResponse,
  GetMingaPartnersRequest,
  GetMingaPartnersResponse,
  GetMingaReportRequest,
  GetMingaReportResponse,
  GetMingaReportSummaryRequest,
  GetMingaReportSummaryResponse,
  GetMingaSettingsRequest,
  GetMingaSettingsResponse,
  GetMingaStatsForPersonRequest,
  GetMingaStatsForPersonResponse,
  GetPublicMingaCampInfoRequest,
  GetPublicMingaCampInfoResponse,
  GetSISErrorDetailsRequest,
  GetSISErrorDetailsResponse,
  GetSISRequest,
  GetSISResponse,
  GetSISSyncRequest,
  GetSISSyncResponse,
  GetSISUnifiedSyncRequest,
  GetSISUnifiedSyncResponse,
  GetStatsForStudentRequest,
  GetStatsForStudentResponse,
  MingaDashboardRequest,
  MingaDashboardRequest2,
  MingaDashboardResponse,
  MingaDashboardResponse2,
  MingaFilesRequest,
  MingaFilesResponse,
  PauseMingaRequest,
  PauseMingaResponse,
  ReadMingaSubscriptionsRequest,
  ReadMingaSubscriptionsResponse,
  ReadMingasRequest,
  ReadMingasResponse,
  ReadUserMingaGradesRequest,
  ReadUserMingaGradesResponse,
  ReadUserMingaJoinCodeRequest,
  ReadUserMingaJoinCodeResponse,
  ReadUserMingaRequest,
  ReadUserMingaResponse,
  RemoveFileRequest,
  RemoveMingaLinkRequest,
  RemoveMingaLinkResponse,
  RemoveMingaStudentIdImageRequest,
  RemoveMingaStudentIdImageResponse,
  ResetAutomationCountersRequest,
  ResetAutomationCountersResponse,
  ResetGroupsMembersRequest,
  ResetGroupsMembersResponse,
  ResetGroupsRequest,
  ResetGroupsResponse,
  ResetPointsRequest,
  ResetPointsResponse,
  ResetUserListsRequest,
  ResetUserListsResponse,
  SearchForMingaRequest,
  SearchForMingaResponse,
  TestSISRequest,
  TestSISResponse,
  TriggerSISSyncRequest,
  TriggerSISSyncResponse,
  UpdateMingaFeatureTogglesRequest,
  UpdateMingaFeatureTogglesResponse,
  UpdateMingaImageRequest,
  UpdateMingaImageResponse,
  UpdateMingaLinkRequest,
  UpdateMingaLinkResponse,
  UpdateMingaProfileRequest,
  UpdateMingaProfileResponse,
  UpdateMingaSettingRequest,
  UpdateMingaSettingResponse,
  UpdateMingaStudentIdImageRequest,
  UpdateMingaStudentIdImageResponse,
  UpdateMingasRequest,
  UpdateMingasResponse,
  UpdateSISRequest,
  UpdateSISResponse,
  UpdateUserMingaFeatureTogglesRequest,
  UpdateUserMingaFeatureTogglesResponse,
  UpdateUserMingaSettingsRequest,
  UpdateUserMingaSettingsResponse,
  UpsertMingaDistrictRequest,
  UpsertMingaDistrictResponse,
  UpsertMingaPartnerRequest,
  UpsertMingaPartnerResponse,
  UserMingaAccountInfoRequest,
  UserMingaAccountInfoResponse,
} from '../gateway/minga_pb';

import {
  MingaManager as __MingaManager,
} from '../gateway/minga_pb_service';

/**
  * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
  MingaManager service (all rpc calls available for Mingas)
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
  */
@Injectable({providedIn: 'root'})
export class MingaManager {

  constructor(private _ngZone: NgZone) {
  }

  resetGroups(request: ResetGroupsRequest): Promise<ResetGroupsResponse>;
  resetGroups(request: ResetGroupsRequest, metadata: grpc.Metadata): Promise<ResetGroupsResponse>;
  resetGroups(request: ResetGroupsRequest, callback: (err: any|null, response: ResetGroupsResponse, metadata: grpc.Metadata) => void): void;
  resetGroups(request: ResetGroupsRequest, metadata: grpc.Metadata, callback: (err: any|null, response: ResetGroupsResponse, metadata: grpc.Metadata) => void): void;

  resetGroups(request: ResetGroupsRequest, arg1?: grpc.Metadata|((err: any|null, response: ResetGroupsResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: ResetGroupsResponse, metadata: grpc.Metadata) => void): Promise<ResetGroupsResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<ResetGroupsResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__MingaManager.resetGroups, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  resetPoints(request: ResetPointsRequest): Promise<ResetPointsResponse>;
  resetPoints(request: ResetPointsRequest, metadata: grpc.Metadata): Promise<ResetPointsResponse>;
  resetPoints(request: ResetPointsRequest, callback: (err: any|null, response: ResetPointsResponse, metadata: grpc.Metadata) => void): void;
  resetPoints(request: ResetPointsRequest, metadata: grpc.Metadata, callback: (err: any|null, response: ResetPointsResponse, metadata: grpc.Metadata) => void): void;

  resetPoints(request: ResetPointsRequest, arg1?: grpc.Metadata|((err: any|null, response: ResetPointsResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: ResetPointsResponse, metadata: grpc.Metadata) => void): Promise<ResetPointsResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<ResetPointsResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__MingaManager.resetPoints, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  resetAutomationCounters(request: ResetAutomationCountersRequest): Promise<ResetAutomationCountersResponse>;
  resetAutomationCounters(request: ResetAutomationCountersRequest, metadata: grpc.Metadata): Promise<ResetAutomationCountersResponse>;
  resetAutomationCounters(request: ResetAutomationCountersRequest, callback: (err: any|null, response: ResetAutomationCountersResponse, metadata: grpc.Metadata) => void): void;
  resetAutomationCounters(request: ResetAutomationCountersRequest, metadata: grpc.Metadata, callback: (err: any|null, response: ResetAutomationCountersResponse, metadata: grpc.Metadata) => void): void;

  resetAutomationCounters(request: ResetAutomationCountersRequest, arg1?: grpc.Metadata|((err: any|null, response: ResetAutomationCountersResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: ResetAutomationCountersResponse, metadata: grpc.Metadata) => void): Promise<ResetAutomationCountersResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<ResetAutomationCountersResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__MingaManager.ResetAutomationCounters, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  resetGroupsMembers(request: ResetGroupsMembersRequest): Promise<ResetGroupsMembersResponse>;
  resetGroupsMembers(request: ResetGroupsMembersRequest, metadata: grpc.Metadata): Promise<ResetGroupsMembersResponse>;
  resetGroupsMembers(request: ResetGroupsMembersRequest, callback: (err: any|null, response: ResetGroupsMembersResponse, metadata: grpc.Metadata) => void): void;
  resetGroupsMembers(request: ResetGroupsMembersRequest, metadata: grpc.Metadata, callback: (err: any|null, response: ResetGroupsMembersResponse, metadata: grpc.Metadata) => void): void;

  resetGroupsMembers(request: ResetGroupsMembersRequest, arg1?: grpc.Metadata|((err: any|null, response: ResetGroupsMembersResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: ResetGroupsMembersResponse, metadata: grpc.Metadata) => void): Promise<ResetGroupsMembersResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<ResetGroupsMembersResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__MingaManager.resetGroupsMembers, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  resetUserLists(request: ResetUserListsRequest): Promise<ResetUserListsResponse>;
  resetUserLists(request: ResetUserListsRequest, metadata: grpc.Metadata): Promise<ResetUserListsResponse>;
  resetUserLists(request: ResetUserListsRequest, callback: (err: any|null, response: ResetUserListsResponse, metadata: grpc.Metadata) => void): void;
  resetUserLists(request: ResetUserListsRequest, metadata: grpc.Metadata, callback: (err: any|null, response: ResetUserListsResponse, metadata: grpc.Metadata) => void): void;

  resetUserLists(request: ResetUserListsRequest, arg1?: grpc.Metadata|((err: any|null, response: ResetUserListsResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: ResetUserListsResponse, metadata: grpc.Metadata) => void): Promise<ResetUserListsResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<ResetUserListsResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__MingaManager.resetUserLists, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  updateSIS(request: UpdateSISRequest): Promise<UpdateSISResponse>;
  updateSIS(request: UpdateSISRequest, metadata: grpc.Metadata): Promise<UpdateSISResponse>;
  updateSIS(request: UpdateSISRequest, callback: (err: any|null, response: UpdateSISResponse, metadata: grpc.Metadata) => void): void;
  updateSIS(request: UpdateSISRequest, metadata: grpc.Metadata, callback: (err: any|null, response: UpdateSISResponse, metadata: grpc.Metadata) => void): void;

  updateSIS(request: UpdateSISRequest, arg1?: grpc.Metadata|((err: any|null, response: UpdateSISResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: UpdateSISResponse, metadata: grpc.Metadata) => void): Promise<UpdateSISResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<UpdateSISResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__MingaManager.updateSIS, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  deleteSIS(request: DeleteSISRequest): Promise<DeleteSISResponse>;
  deleteSIS(request: DeleteSISRequest, metadata: grpc.Metadata): Promise<DeleteSISResponse>;
  deleteSIS(request: DeleteSISRequest, callback: (err: any|null, response: DeleteSISResponse, metadata: grpc.Metadata) => void): void;
  deleteSIS(request: DeleteSISRequest, metadata: grpc.Metadata, callback: (err: any|null, response: DeleteSISResponse, metadata: grpc.Metadata) => void): void;

  deleteSIS(request: DeleteSISRequest, arg1?: grpc.Metadata|((err: any|null, response: DeleteSISResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: DeleteSISResponse, metadata: grpc.Metadata) => void): Promise<DeleteSISResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<DeleteSISResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__MingaManager.deleteSIS, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  activateRosteringMethod(request: ActivateRosteringMethodRequest): Promise<ActivateRosteringMethodResponse>;
  activateRosteringMethod(request: ActivateRosteringMethodRequest, metadata: grpc.Metadata): Promise<ActivateRosteringMethodResponse>;
  activateRosteringMethod(request: ActivateRosteringMethodRequest, callback: (err: any|null, response: ActivateRosteringMethodResponse, metadata: grpc.Metadata) => void): void;
  activateRosteringMethod(request: ActivateRosteringMethodRequest, metadata: grpc.Metadata, callback: (err: any|null, response: ActivateRosteringMethodResponse, metadata: grpc.Metadata) => void): void;

  activateRosteringMethod(request: ActivateRosteringMethodRequest, arg1?: grpc.Metadata|((err: any|null, response: ActivateRosteringMethodResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: ActivateRosteringMethodResponse, metadata: grpc.Metadata) => void): Promise<ActivateRosteringMethodResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<ActivateRosteringMethodResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__MingaManager.activateRosteringMethod, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  deactivateAllRosteringMethods(request: DeactivateAllRosteringMethodsRequest): Promise<DeactivateAllRosteringMethodsResponse>;
  deactivateAllRosteringMethods(request: DeactivateAllRosteringMethodsRequest, metadata: grpc.Metadata): Promise<DeactivateAllRosteringMethodsResponse>;
  deactivateAllRosteringMethods(request: DeactivateAllRosteringMethodsRequest, callback: (err: any|null, response: DeactivateAllRosteringMethodsResponse, metadata: grpc.Metadata) => void): void;
  deactivateAllRosteringMethods(request: DeactivateAllRosteringMethodsRequest, metadata: grpc.Metadata, callback: (err: any|null, response: DeactivateAllRosteringMethodsResponse, metadata: grpc.Metadata) => void): void;

  deactivateAllRosteringMethods(request: DeactivateAllRosteringMethodsRequest, arg1?: grpc.Metadata|((err: any|null, response: DeactivateAllRosteringMethodsResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: DeactivateAllRosteringMethodsResponse, metadata: grpc.Metadata) => void): Promise<DeactivateAllRosteringMethodsResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<DeactivateAllRosteringMethodsResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__MingaManager.deactivateAllRosteringMethods, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  getSIS(request: GetSISRequest): Promise<GetSISResponse>;
  getSIS(request: GetSISRequest, metadata: grpc.Metadata): Promise<GetSISResponse>;
  getSIS(request: GetSISRequest, callback: (err: any|null, response: GetSISResponse, metadata: grpc.Metadata) => void): void;
  getSIS(request: GetSISRequest, metadata: grpc.Metadata, callback: (err: any|null, response: GetSISResponse, metadata: grpc.Metadata) => void): void;

  getSIS(request: GetSISRequest, arg1?: grpc.Metadata|((err: any|null, response: GetSISResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: GetSISResponse, metadata: grpc.Metadata) => void): Promise<GetSISResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<GetSISResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__MingaManager.getSIS, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  getSISErrorDetails(request: GetSISErrorDetailsRequest): Promise<GetSISErrorDetailsResponse>;
  getSISErrorDetails(request: GetSISErrorDetailsRequest, metadata: grpc.Metadata): Promise<GetSISErrorDetailsResponse>;
  getSISErrorDetails(request: GetSISErrorDetailsRequest, callback: (err: any|null, response: GetSISErrorDetailsResponse, metadata: grpc.Metadata) => void): void;
  getSISErrorDetails(request: GetSISErrorDetailsRequest, metadata: grpc.Metadata, callback: (err: any|null, response: GetSISErrorDetailsResponse, metadata: grpc.Metadata) => void): void;

  getSISErrorDetails(request: GetSISErrorDetailsRequest, arg1?: grpc.Metadata|((err: any|null, response: GetSISErrorDetailsResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: GetSISErrorDetailsResponse, metadata: grpc.Metadata) => void): Promise<GetSISErrorDetailsResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<GetSISErrorDetailsResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__MingaManager.getSISErrorDetails, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  testSISIntegration(request: TestSISRequest): Promise<TestSISResponse>;
  testSISIntegration(request: TestSISRequest, metadata: grpc.Metadata): Promise<TestSISResponse>;
  testSISIntegration(request: TestSISRequest, callback: (err: any|null, response: TestSISResponse, metadata: grpc.Metadata) => void): void;
  testSISIntegration(request: TestSISRequest, metadata: grpc.Metadata, callback: (err: any|null, response: TestSISResponse, metadata: grpc.Metadata) => void): void;

  testSISIntegration(request: TestSISRequest, arg1?: grpc.Metadata|((err: any|null, response: TestSISResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: TestSISResponse, metadata: grpc.Metadata) => void): Promise<TestSISResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<TestSISResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__MingaManager.TestSISIntegration, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  getSISSync(request: GetSISSyncRequest): Promise<GetSISSyncResponse>;
  getSISSync(request: GetSISSyncRequest, metadata: grpc.Metadata): Promise<GetSISSyncResponse>;
  getSISSync(request: GetSISSyncRequest, callback: (err: any|null, response: GetSISSyncResponse, metadata: grpc.Metadata) => void): void;
  getSISSync(request: GetSISSyncRequest, metadata: grpc.Metadata, callback: (err: any|null, response: GetSISSyncResponse, metadata: grpc.Metadata) => void): void;

  getSISSync(request: GetSISSyncRequest, arg1?: grpc.Metadata|((err: any|null, response: GetSISSyncResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: GetSISSyncResponse, metadata: grpc.Metadata) => void): Promise<GetSISSyncResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<GetSISSyncResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__MingaManager.getSISSync, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  getSISUnifiedSync(request: GetSISUnifiedSyncRequest): Promise<GetSISUnifiedSyncResponse>;
  getSISUnifiedSync(request: GetSISUnifiedSyncRequest, metadata: grpc.Metadata): Promise<GetSISUnifiedSyncResponse>;
  getSISUnifiedSync(request: GetSISUnifiedSyncRequest, callback: (err: any|null, response: GetSISUnifiedSyncResponse, metadata: grpc.Metadata) => void): void;
  getSISUnifiedSync(request: GetSISUnifiedSyncRequest, metadata: grpc.Metadata, callback: (err: any|null, response: GetSISUnifiedSyncResponse, metadata: grpc.Metadata) => void): void;

  getSISUnifiedSync(request: GetSISUnifiedSyncRequest, arg1?: grpc.Metadata|((err: any|null, response: GetSISUnifiedSyncResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: GetSISUnifiedSyncResponse, metadata: grpc.Metadata) => void): Promise<GetSISUnifiedSyncResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<GetSISUnifiedSyncResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__MingaManager.getSISUnifiedSync, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  triggerSISSync(request: TriggerSISSyncRequest): Promise<TriggerSISSyncResponse>;
  triggerSISSync(request: TriggerSISSyncRequest, metadata: grpc.Metadata): Promise<TriggerSISSyncResponse>;
  triggerSISSync(request: TriggerSISSyncRequest, callback: (err: any|null, response: TriggerSISSyncResponse, metadata: grpc.Metadata) => void): void;
  triggerSISSync(request: TriggerSISSyncRequest, metadata: grpc.Metadata, callback: (err: any|null, response: TriggerSISSyncResponse, metadata: grpc.Metadata) => void): void;

  triggerSISSync(request: TriggerSISSyncRequest, arg1?: grpc.Metadata|((err: any|null, response: TriggerSISSyncResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: TriggerSISSyncResponse, metadata: grpc.Metadata) => void): Promise<TriggerSISSyncResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<TriggerSISSyncResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__MingaManager.triggerSISSync, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  createMingas(request: CreateMingasRequest): Promise<CreateMingasResponse>;
  createMingas(request: CreateMingasRequest, metadata: grpc.Metadata): Promise<CreateMingasResponse>;
  createMingas(request: CreateMingasRequest, callback: (err: any|null, response: CreateMingasResponse, metadata: grpc.Metadata) => void): void;
  createMingas(request: CreateMingasRequest, metadata: grpc.Metadata, callback: (err: any|null, response: CreateMingasResponse, metadata: grpc.Metadata) => void): void;

  createMingas(request: CreateMingasRequest, arg1?: grpc.Metadata|((err: any|null, response: CreateMingasResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: CreateMingasResponse, metadata: grpc.Metadata) => void): Promise<CreateMingasResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<CreateMingasResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__MingaManager.createMingas, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  readMingas(request: ReadMingasRequest): Promise<ReadMingasResponse>;
  readMingas(request: ReadMingasRequest, metadata: grpc.Metadata): Promise<ReadMingasResponse>;
  readMingas(request: ReadMingasRequest, callback: (err: any|null, response: ReadMingasResponse, metadata: grpc.Metadata) => void): void;
  readMingas(request: ReadMingasRequest, metadata: grpc.Metadata, callback: (err: any|null, response: ReadMingasResponse, metadata: grpc.Metadata) => void): void;

  readMingas(request: ReadMingasRequest, arg1?: grpc.Metadata|((err: any|null, response: ReadMingasResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: ReadMingasResponse, metadata: grpc.Metadata) => void): Promise<ReadMingasResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<ReadMingasResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__MingaManager.readMingas, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  updateMingas(request: UpdateMingasRequest): Promise<UpdateMingasResponse>;
  updateMingas(request: UpdateMingasRequest, metadata: grpc.Metadata): Promise<UpdateMingasResponse>;
  updateMingas(request: UpdateMingasRequest, callback: (err: any|null, response: UpdateMingasResponse, metadata: grpc.Metadata) => void): void;
  updateMingas(request: UpdateMingasRequest, metadata: grpc.Metadata, callback: (err: any|null, response: UpdateMingasResponse, metadata: grpc.Metadata) => void): void;

  updateMingas(request: UpdateMingasRequest, arg1?: grpc.Metadata|((err: any|null, response: UpdateMingasResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: UpdateMingasResponse, metadata: grpc.Metadata) => void): Promise<UpdateMingasResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<UpdateMingasResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__MingaManager.updateMingas, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  deleteMingas(request: DeleteMingasRequest): Promise<DeleteMingasResponse>;
  deleteMingas(request: DeleteMingasRequest, metadata: grpc.Metadata): Promise<DeleteMingasResponse>;
  deleteMingas(request: DeleteMingasRequest, callback: (err: any|null, response: DeleteMingasResponse, metadata: grpc.Metadata) => void): void;
  deleteMingas(request: DeleteMingasRequest, metadata: grpc.Metadata, callback: (err: any|null, response: DeleteMingasResponse, metadata: grpc.Metadata) => void): void;

  deleteMingas(request: DeleteMingasRequest, arg1?: grpc.Metadata|((err: any|null, response: DeleteMingasResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: DeleteMingasResponse, metadata: grpc.Metadata) => void): Promise<DeleteMingasResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<DeleteMingasResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__MingaManager.deleteMingas, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  readUserMinga(request: ReadUserMingaRequest): Promise<ReadUserMingaResponse>;
  readUserMinga(request: ReadUserMingaRequest, metadata: grpc.Metadata): Promise<ReadUserMingaResponse>;
  readUserMinga(request: ReadUserMingaRequest, callback: (err: any|null, response: ReadUserMingaResponse, metadata: grpc.Metadata) => void): void;
  readUserMinga(request: ReadUserMingaRequest, metadata: grpc.Metadata, callback: (err: any|null, response: ReadUserMingaResponse, metadata: grpc.Metadata) => void): void;

  readUserMinga(request: ReadUserMingaRequest, arg1?: grpc.Metadata|((err: any|null, response: ReadUserMingaResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: ReadUserMingaResponse, metadata: grpc.Metadata) => void): Promise<ReadUserMingaResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<ReadUserMingaResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__MingaManager.readUserMinga, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  updateMingaProfile(request: UpdateMingaProfileRequest): Promise<UpdateMingaProfileResponse>;
  updateMingaProfile(request: UpdateMingaProfileRequest, metadata: grpc.Metadata): Promise<UpdateMingaProfileResponse>;
  updateMingaProfile(request: UpdateMingaProfileRequest, callback: (err: any|null, response: UpdateMingaProfileResponse, metadata: grpc.Metadata) => void): void;
  updateMingaProfile(request: UpdateMingaProfileRequest, metadata: grpc.Metadata, callback: (err: any|null, response: UpdateMingaProfileResponse, metadata: grpc.Metadata) => void): void;

  updateMingaProfile(request: UpdateMingaProfileRequest, arg1?: grpc.Metadata|((err: any|null, response: UpdateMingaProfileResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: UpdateMingaProfileResponse, metadata: grpc.Metadata) => void): Promise<UpdateMingaProfileResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<UpdateMingaProfileResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__MingaManager.updateMingaProfile, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  updateMingaImage(request: UpdateMingaImageRequest): Promise<UpdateMingaImageResponse>;
  updateMingaImage(request: UpdateMingaImageRequest, metadata: grpc.Metadata): Promise<UpdateMingaImageResponse>;
  updateMingaImage(request: UpdateMingaImageRequest, callback: (err: any|null, response: UpdateMingaImageResponse, metadata: grpc.Metadata) => void): void;
  updateMingaImage(request: UpdateMingaImageRequest, metadata: grpc.Metadata, callback: (err: any|null, response: UpdateMingaImageResponse, metadata: grpc.Metadata) => void): void;

  updateMingaImage(request: UpdateMingaImageRequest, arg1?: grpc.Metadata|((err: any|null, response: UpdateMingaImageResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: UpdateMingaImageResponse, metadata: grpc.Metadata) => void): Promise<UpdateMingaImageResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<UpdateMingaImageResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__MingaManager.updateMingaImage, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  addMingaLink(request: AddMingaLinkRequest): Promise<AddMingaLinkResponse>;
  addMingaLink(request: AddMingaLinkRequest, metadata: grpc.Metadata): Promise<AddMingaLinkResponse>;
  addMingaLink(request: AddMingaLinkRequest, callback: (err: any|null, response: AddMingaLinkResponse, metadata: grpc.Metadata) => void): void;
  addMingaLink(request: AddMingaLinkRequest, metadata: grpc.Metadata, callback: (err: any|null, response: AddMingaLinkResponse, metadata: grpc.Metadata) => void): void;

  addMingaLink(request: AddMingaLinkRequest, arg1?: grpc.Metadata|((err: any|null, response: AddMingaLinkResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: AddMingaLinkResponse, metadata: grpc.Metadata) => void): Promise<AddMingaLinkResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<AddMingaLinkResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__MingaManager.addMingaLink, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  updateMingaLink(request: UpdateMingaLinkRequest): Promise<UpdateMingaLinkResponse>;
  updateMingaLink(request: UpdateMingaLinkRequest, metadata: grpc.Metadata): Promise<UpdateMingaLinkResponse>;
  updateMingaLink(request: UpdateMingaLinkRequest, callback: (err: any|null, response: UpdateMingaLinkResponse, metadata: grpc.Metadata) => void): void;
  updateMingaLink(request: UpdateMingaLinkRequest, metadata: grpc.Metadata, callback: (err: any|null, response: UpdateMingaLinkResponse, metadata: grpc.Metadata) => void): void;

  updateMingaLink(request: UpdateMingaLinkRequest, arg1?: grpc.Metadata|((err: any|null, response: UpdateMingaLinkResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: UpdateMingaLinkResponse, metadata: grpc.Metadata) => void): Promise<UpdateMingaLinkResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<UpdateMingaLinkResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__MingaManager.updateMingaLink, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  removeMingaLink(request: RemoveMingaLinkRequest): Promise<RemoveMingaLinkResponse>;
  removeMingaLink(request: RemoveMingaLinkRequest, metadata: grpc.Metadata): Promise<RemoveMingaLinkResponse>;
  removeMingaLink(request: RemoveMingaLinkRequest, callback: (err: any|null, response: RemoveMingaLinkResponse, metadata: grpc.Metadata) => void): void;
  removeMingaLink(request: RemoveMingaLinkRequest, metadata: grpc.Metadata, callback: (err: any|null, response: RemoveMingaLinkResponse, metadata: grpc.Metadata) => void): void;

  removeMingaLink(request: RemoveMingaLinkRequest, arg1?: grpc.Metadata|((err: any|null, response: RemoveMingaLinkResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: RemoveMingaLinkResponse, metadata: grpc.Metadata) => void): Promise<RemoveMingaLinkResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<RemoveMingaLinkResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__MingaManager.removeMingaLink, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  pauseMinga(request: PauseMingaRequest): Promise<PauseMingaResponse>;
  pauseMinga(request: PauseMingaRequest, metadata: grpc.Metadata): Promise<PauseMingaResponse>;
  pauseMinga(request: PauseMingaRequest, callback: (err: any|null, response: PauseMingaResponse, metadata: grpc.Metadata) => void): void;
  pauseMinga(request: PauseMingaRequest, metadata: grpc.Metadata, callback: (err: any|null, response: PauseMingaResponse, metadata: grpc.Metadata) => void): void;

  pauseMinga(request: PauseMingaRequest, arg1?: grpc.Metadata|((err: any|null, response: PauseMingaResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: PauseMingaResponse, metadata: grpc.Metadata) => void): Promise<PauseMingaResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<PauseMingaResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__MingaManager.PauseMinga, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  getPublicMingaCampInfo(request: GetPublicMingaCampInfoRequest): Promise<GetPublicMingaCampInfoResponse>;
  getPublicMingaCampInfo(request: GetPublicMingaCampInfoRequest, metadata: grpc.Metadata): Promise<GetPublicMingaCampInfoResponse>;
  getPublicMingaCampInfo(request: GetPublicMingaCampInfoRequest, callback: (err: any|null, response: GetPublicMingaCampInfoResponse, metadata: grpc.Metadata) => void): void;
  getPublicMingaCampInfo(request: GetPublicMingaCampInfoRequest, metadata: grpc.Metadata, callback: (err: any|null, response: GetPublicMingaCampInfoResponse, metadata: grpc.Metadata) => void): void;

  getPublicMingaCampInfo(request: GetPublicMingaCampInfoRequest, arg1?: grpc.Metadata|((err: any|null, response: GetPublicMingaCampInfoResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: GetPublicMingaCampInfoResponse, metadata: grpc.Metadata) => void): Promise<GetPublicMingaCampInfoResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<GetPublicMingaCampInfoResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__MingaManager.GetPublicMingaCampInfo, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  updateUserMingaFeatureToggles(request: UpdateUserMingaFeatureTogglesRequest): Promise<UpdateUserMingaFeatureTogglesResponse>;
  updateUserMingaFeatureToggles(request: UpdateUserMingaFeatureTogglesRequest, metadata: grpc.Metadata): Promise<UpdateUserMingaFeatureTogglesResponse>;
  updateUserMingaFeatureToggles(request: UpdateUserMingaFeatureTogglesRequest, callback: (err: any|null, response: UpdateUserMingaFeatureTogglesResponse, metadata: grpc.Metadata) => void): void;
  updateUserMingaFeatureToggles(request: UpdateUserMingaFeatureTogglesRequest, metadata: grpc.Metadata, callback: (err: any|null, response: UpdateUserMingaFeatureTogglesResponse, metadata: grpc.Metadata) => void): void;

  updateUserMingaFeatureToggles(request: UpdateUserMingaFeatureTogglesRequest, arg1?: grpc.Metadata|((err: any|null, response: UpdateUserMingaFeatureTogglesResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: UpdateUserMingaFeatureTogglesResponse, metadata: grpc.Metadata) => void): Promise<UpdateUserMingaFeatureTogglesResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<UpdateUserMingaFeatureTogglesResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__MingaManager.UpdateUserMingaFeatureToggles, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  getMingaFeatureToggles(request: GetMingaFeatureTogglesRequest): Promise<GetMingaFeatureTogglesResponse>;
  getMingaFeatureToggles(request: GetMingaFeatureTogglesRequest, metadata: grpc.Metadata): Promise<GetMingaFeatureTogglesResponse>;
  getMingaFeatureToggles(request: GetMingaFeatureTogglesRequest, callback: (err: any|null, response: GetMingaFeatureTogglesResponse, metadata: grpc.Metadata) => void): void;
  getMingaFeatureToggles(request: GetMingaFeatureTogglesRequest, metadata: grpc.Metadata, callback: (err: any|null, response: GetMingaFeatureTogglesResponse, metadata: grpc.Metadata) => void): void;

  getMingaFeatureToggles(request: GetMingaFeatureTogglesRequest, arg1?: grpc.Metadata|((err: any|null, response: GetMingaFeatureTogglesResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: GetMingaFeatureTogglesResponse, metadata: grpc.Metadata) => void): Promise<GetMingaFeatureTogglesResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<GetMingaFeatureTogglesResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__MingaManager.GetMingaFeatureToggles, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  updateMingaFeatureToggles(request: UpdateMingaFeatureTogglesRequest): Promise<UpdateMingaFeatureTogglesResponse>;
  updateMingaFeatureToggles(request: UpdateMingaFeatureTogglesRequest, metadata: grpc.Metadata): Promise<UpdateMingaFeatureTogglesResponse>;
  updateMingaFeatureToggles(request: UpdateMingaFeatureTogglesRequest, callback: (err: any|null, response: UpdateMingaFeatureTogglesResponse, metadata: grpc.Metadata) => void): void;
  updateMingaFeatureToggles(request: UpdateMingaFeatureTogglesRequest, metadata: grpc.Metadata, callback: (err: any|null, response: UpdateMingaFeatureTogglesResponse, metadata: grpc.Metadata) => void): void;

  updateMingaFeatureToggles(request: UpdateMingaFeatureTogglesRequest, arg1?: grpc.Metadata|((err: any|null, response: UpdateMingaFeatureTogglesResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: UpdateMingaFeatureTogglesResponse, metadata: grpc.Metadata) => void): Promise<UpdateMingaFeatureTogglesResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<UpdateMingaFeatureTogglesResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__MingaManager.UpdateMingaFeatureToggles, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  updateMingaSetting(request: UpdateMingaSettingRequest): Promise<UpdateMingaSettingResponse>;
  updateMingaSetting(request: UpdateMingaSettingRequest, metadata: grpc.Metadata): Promise<UpdateMingaSettingResponse>;
  updateMingaSetting(request: UpdateMingaSettingRequest, callback: (err: any|null, response: UpdateMingaSettingResponse, metadata: grpc.Metadata) => void): void;
  updateMingaSetting(request: UpdateMingaSettingRequest, metadata: grpc.Metadata, callback: (err: any|null, response: UpdateMingaSettingResponse, metadata: grpc.Metadata) => void): void;

  updateMingaSetting(request: UpdateMingaSettingRequest, arg1?: grpc.Metadata|((err: any|null, response: UpdateMingaSettingResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: UpdateMingaSettingResponse, metadata: grpc.Metadata) => void): Promise<UpdateMingaSettingResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<UpdateMingaSettingResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__MingaManager.UpdateMingaSetting, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  getMingaSettings(request: GetMingaSettingsRequest): Promise<GetMingaSettingsResponse>;
  getMingaSettings(request: GetMingaSettingsRequest, metadata: grpc.Metadata): Promise<GetMingaSettingsResponse>;
  getMingaSettings(request: GetMingaSettingsRequest, callback: (err: any|null, response: GetMingaSettingsResponse, metadata: grpc.Metadata) => void): void;
  getMingaSettings(request: GetMingaSettingsRequest, metadata: grpc.Metadata, callback: (err: any|null, response: GetMingaSettingsResponse, metadata: grpc.Metadata) => void): void;

  getMingaSettings(request: GetMingaSettingsRequest, arg1?: grpc.Metadata|((err: any|null, response: GetMingaSettingsResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: GetMingaSettingsResponse, metadata: grpc.Metadata) => void): Promise<GetMingaSettingsResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<GetMingaSettingsResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__MingaManager.GetMingaSettings, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  updateMingaStudentIdImage(request: UpdateMingaStudentIdImageRequest): Promise<UpdateMingaStudentIdImageResponse>;
  updateMingaStudentIdImage(request: UpdateMingaStudentIdImageRequest, metadata: grpc.Metadata): Promise<UpdateMingaStudentIdImageResponse>;
  updateMingaStudentIdImage(request: UpdateMingaStudentIdImageRequest, callback: (err: any|null, response: UpdateMingaStudentIdImageResponse, metadata: grpc.Metadata) => void): void;
  updateMingaStudentIdImage(request: UpdateMingaStudentIdImageRequest, metadata: grpc.Metadata, callback: (err: any|null, response: UpdateMingaStudentIdImageResponse, metadata: grpc.Metadata) => void): void;

  updateMingaStudentIdImage(request: UpdateMingaStudentIdImageRequest, arg1?: grpc.Metadata|((err: any|null, response: UpdateMingaStudentIdImageResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: UpdateMingaStudentIdImageResponse, metadata: grpc.Metadata) => void): Promise<UpdateMingaStudentIdImageResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<UpdateMingaStudentIdImageResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__MingaManager.UpdateMingaStudentIdImage, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  removeMingaStudentIdImage(request: RemoveMingaStudentIdImageRequest): Promise<RemoveMingaStudentIdImageResponse>;
  removeMingaStudentIdImage(request: RemoveMingaStudentIdImageRequest, metadata: grpc.Metadata): Promise<RemoveMingaStudentIdImageResponse>;
  removeMingaStudentIdImage(request: RemoveMingaStudentIdImageRequest, callback: (err: any|null, response: RemoveMingaStudentIdImageResponse, metadata: grpc.Metadata) => void): void;
  removeMingaStudentIdImage(request: RemoveMingaStudentIdImageRequest, metadata: grpc.Metadata, callback: (err: any|null, response: RemoveMingaStudentIdImageResponse, metadata: grpc.Metadata) => void): void;

  removeMingaStudentIdImage(request: RemoveMingaStudentIdImageRequest, arg1?: grpc.Metadata|((err: any|null, response: RemoveMingaStudentIdImageResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: RemoveMingaStudentIdImageResponse, metadata: grpc.Metadata) => void): Promise<RemoveMingaStudentIdImageResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<RemoveMingaStudentIdImageResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__MingaManager.RemoveMingaStudentIdImage, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  addFiles(request: CreateFilesRequest): Promise<MingaFilesResponse>;
  addFiles(request: CreateFilesRequest, metadata: grpc.Metadata): Promise<MingaFilesResponse>;
  addFiles(request: CreateFilesRequest, callback: (err: any|null, response: MingaFilesResponse, metadata: grpc.Metadata) => void): void;
  addFiles(request: CreateFilesRequest, metadata: grpc.Metadata, callback: (err: any|null, response: MingaFilesResponse, metadata: grpc.Metadata) => void): void;

  addFiles(request: CreateFilesRequest, arg1?: grpc.Metadata|((err: any|null, response: MingaFilesResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: MingaFilesResponse, metadata: grpc.Metadata) => void): Promise<MingaFilesResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<MingaFilesResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__MingaManager.AddFiles, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  getFiles(request: MingaFilesRequest): Promise<MingaFilesResponse>;
  getFiles(request: MingaFilesRequest, metadata: grpc.Metadata): Promise<MingaFilesResponse>;
  getFiles(request: MingaFilesRequest, callback: (err: any|null, response: MingaFilesResponse, metadata: grpc.Metadata) => void): void;
  getFiles(request: MingaFilesRequest, metadata: grpc.Metadata, callback: (err: any|null, response: MingaFilesResponse, metadata: grpc.Metadata) => void): void;

  getFiles(request: MingaFilesRequest, arg1?: grpc.Metadata|((err: any|null, response: MingaFilesResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: MingaFilesResponse, metadata: grpc.Metadata) => void): Promise<MingaFilesResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<MingaFilesResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__MingaManager.GetFiles, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  removeFile(request: RemoveFileRequest): Promise<MingaFilesResponse>;
  removeFile(request: RemoveFileRequest, metadata: grpc.Metadata): Promise<MingaFilesResponse>;
  removeFile(request: RemoveFileRequest, callback: (err: any|null, response: MingaFilesResponse, metadata: grpc.Metadata) => void): void;
  removeFile(request: RemoveFileRequest, metadata: grpc.Metadata, callback: (err: any|null, response: MingaFilesResponse, metadata: grpc.Metadata) => void): void;

  removeFile(request: RemoveFileRequest, arg1?: grpc.Metadata|((err: any|null, response: MingaFilesResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: MingaFilesResponse, metadata: grpc.Metadata) => void): Promise<MingaFilesResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<MingaFilesResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__MingaManager.RemoveFile, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  getMingaDashboard2(request: MingaDashboardRequest2): Promise<MingaDashboardResponse2>;
  getMingaDashboard2(request: MingaDashboardRequest2, metadata: grpc.Metadata): Promise<MingaDashboardResponse2>;
  getMingaDashboard2(request: MingaDashboardRequest2, callback: (err: any|null, response: MingaDashboardResponse2, metadata: grpc.Metadata) => void): void;
  getMingaDashboard2(request: MingaDashboardRequest2, metadata: grpc.Metadata, callback: (err: any|null, response: MingaDashboardResponse2, metadata: grpc.Metadata) => void): void;

  getMingaDashboard2(request: MingaDashboardRequest2, arg1?: grpc.Metadata|((err: any|null, response: MingaDashboardResponse2, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: MingaDashboardResponse2, metadata: grpc.Metadata) => void): Promise<MingaDashboardResponse2>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<MingaDashboardResponse2>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__MingaManager.GetMingaDashboard2, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  getMingaReport(request: GetMingaReportRequest): Promise<GetMingaReportResponse>;
  getMingaReport(request: GetMingaReportRequest, metadata: grpc.Metadata): Promise<GetMingaReportResponse>;
  getMingaReport(request: GetMingaReportRequest, callback: (err: any|null, response: GetMingaReportResponse, metadata: grpc.Metadata) => void): void;
  getMingaReport(request: GetMingaReportRequest, metadata: grpc.Metadata, callback: (err: any|null, response: GetMingaReportResponse, metadata: grpc.Metadata) => void): void;

  getMingaReport(request: GetMingaReportRequest, arg1?: grpc.Metadata|((err: any|null, response: GetMingaReportResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: GetMingaReportResponse, metadata: grpc.Metadata) => void): Promise<GetMingaReportResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<GetMingaReportResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__MingaManager.GetMingaReport, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  getMingaReportSummary(request: GetMingaReportSummaryRequest): Promise<GetMingaReportSummaryResponse>;
  getMingaReportSummary(request: GetMingaReportSummaryRequest, metadata: grpc.Metadata): Promise<GetMingaReportSummaryResponse>;
  getMingaReportSummary(request: GetMingaReportSummaryRequest, callback: (err: any|null, response: GetMingaReportSummaryResponse, metadata: grpc.Metadata) => void): void;
  getMingaReportSummary(request: GetMingaReportSummaryRequest, metadata: grpc.Metadata, callback: (err: any|null, response: GetMingaReportSummaryResponse, metadata: grpc.Metadata) => void): void;

  getMingaReportSummary(request: GetMingaReportSummaryRequest, arg1?: grpc.Metadata|((err: any|null, response: GetMingaReportSummaryResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: GetMingaReportSummaryResponse, metadata: grpc.Metadata) => void): Promise<GetMingaReportSummaryResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<GetMingaReportSummaryResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__MingaManager.GetMingaReportSummary, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  getMingaDashboard(request: MingaDashboardRequest): Promise<MingaDashboardResponse>;
  getMingaDashboard(request: MingaDashboardRequest, metadata: grpc.Metadata): Promise<MingaDashboardResponse>;
  getMingaDashboard(request: MingaDashboardRequest, callback: (err: any|null, response: MingaDashboardResponse, metadata: grpc.Metadata) => void): void;
  getMingaDashboard(request: MingaDashboardRequest, metadata: grpc.Metadata, callback: (err: any|null, response: MingaDashboardResponse, metadata: grpc.Metadata) => void): void;

  getMingaDashboard(request: MingaDashboardRequest, arg1?: grpc.Metadata|((err: any|null, response: MingaDashboardResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: MingaDashboardResponse, metadata: grpc.Metadata) => void): Promise<MingaDashboardResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<MingaDashboardResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__MingaManager.GetMingaDashboard, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  getUserMingaAccountInfo(request: UserMingaAccountInfoRequest): Promise<UserMingaAccountInfoResponse>;
  getUserMingaAccountInfo(request: UserMingaAccountInfoRequest, metadata: grpc.Metadata): Promise<UserMingaAccountInfoResponse>;
  getUserMingaAccountInfo(request: UserMingaAccountInfoRequest, callback: (err: any|null, response: UserMingaAccountInfoResponse, metadata: grpc.Metadata) => void): void;
  getUserMingaAccountInfo(request: UserMingaAccountInfoRequest, metadata: grpc.Metadata, callback: (err: any|null, response: UserMingaAccountInfoResponse, metadata: grpc.Metadata) => void): void;

  getUserMingaAccountInfo(request: UserMingaAccountInfoRequest, arg1?: grpc.Metadata|((err: any|null, response: UserMingaAccountInfoResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: UserMingaAccountInfoResponse, metadata: grpc.Metadata) => void): Promise<UserMingaAccountInfoResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<UserMingaAccountInfoResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__MingaManager.GetUserMingaAccountInfo, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  searchForMinga(request: SearchForMingaRequest): Promise<SearchForMingaResponse>;
  searchForMinga(request: SearchForMingaRequest, metadata: grpc.Metadata): Promise<SearchForMingaResponse>;
  searchForMinga(request: SearchForMingaRequest, callback: (err: any|null, response: SearchForMingaResponse, metadata: grpc.Metadata) => void): void;
  searchForMinga(request: SearchForMingaRequest, metadata: grpc.Metadata, callback: (err: any|null, response: SearchForMingaResponse, metadata: grpc.Metadata) => void): void;

  searchForMinga(request: SearchForMingaRequest, arg1?: grpc.Metadata|((err: any|null, response: SearchForMingaResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: SearchForMingaResponse, metadata: grpc.Metadata) => void): Promise<SearchForMingaResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<SearchForMingaResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__MingaManager.SearchForMinga, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  updateUserMingaSettings(request: UpdateUserMingaSettingsRequest): Promise<UpdateUserMingaSettingsResponse>;
  updateUserMingaSettings(request: UpdateUserMingaSettingsRequest, metadata: grpc.Metadata): Promise<UpdateUserMingaSettingsResponse>;
  updateUserMingaSettings(request: UpdateUserMingaSettingsRequest, callback: (err: any|null, response: UpdateUserMingaSettingsResponse, metadata: grpc.Metadata) => void): void;
  updateUserMingaSettings(request: UpdateUserMingaSettingsRequest, metadata: grpc.Metadata, callback: (err: any|null, response: UpdateUserMingaSettingsResponse, metadata: grpc.Metadata) => void): void;

  updateUserMingaSettings(request: UpdateUserMingaSettingsRequest, arg1?: grpc.Metadata|((err: any|null, response: UpdateUserMingaSettingsResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: UpdateUserMingaSettingsResponse, metadata: grpc.Metadata) => void): Promise<UpdateUserMingaSettingsResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<UpdateUserMingaSettingsResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__MingaManager.UpdateUserMingaSettings, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  readUserMingaJoinCode(request: ReadUserMingaJoinCodeRequest): Promise<ReadUserMingaJoinCodeResponse>;
  readUserMingaJoinCode(request: ReadUserMingaJoinCodeRequest, metadata: grpc.Metadata): Promise<ReadUserMingaJoinCodeResponse>;
  readUserMingaJoinCode(request: ReadUserMingaJoinCodeRequest, callback: (err: any|null, response: ReadUserMingaJoinCodeResponse, metadata: grpc.Metadata) => void): void;
  readUserMingaJoinCode(request: ReadUserMingaJoinCodeRequest, metadata: grpc.Metadata, callback: (err: any|null, response: ReadUserMingaJoinCodeResponse, metadata: grpc.Metadata) => void): void;

  readUserMingaJoinCode(request: ReadUserMingaJoinCodeRequest, arg1?: grpc.Metadata|((err: any|null, response: ReadUserMingaJoinCodeResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: ReadUserMingaJoinCodeResponse, metadata: grpc.Metadata) => void): Promise<ReadUserMingaJoinCodeResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<ReadUserMingaJoinCodeResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__MingaManager.ReadUserMingaJoinCode, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  readUserMingaGrades(request: ReadUserMingaGradesRequest): Promise<ReadUserMingaGradesResponse>;
  readUserMingaGrades(request: ReadUserMingaGradesRequest, metadata: grpc.Metadata): Promise<ReadUserMingaGradesResponse>;
  readUserMingaGrades(request: ReadUserMingaGradesRequest, callback: (err: any|null, response: ReadUserMingaGradesResponse, metadata: grpc.Metadata) => void): void;
  readUserMingaGrades(request: ReadUserMingaGradesRequest, metadata: grpc.Metadata, callback: (err: any|null, response: ReadUserMingaGradesResponse, metadata: grpc.Metadata) => void): void;

  readUserMingaGrades(request: ReadUserMingaGradesRequest, arg1?: grpc.Metadata|((err: any|null, response: ReadUserMingaGradesResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: ReadUserMingaGradesResponse, metadata: grpc.Metadata) => void): Promise<ReadUserMingaGradesResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<ReadUserMingaGradesResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__MingaManager.ReadUserMingaGrades, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  createUserMingaJoinCode(request: CreateUserMingaJoinCodeRequest): Promise<CreateUserMingaJoinCodeResponse>;
  createUserMingaJoinCode(request: CreateUserMingaJoinCodeRequest, metadata: grpc.Metadata): Promise<CreateUserMingaJoinCodeResponse>;
  createUserMingaJoinCode(request: CreateUserMingaJoinCodeRequest, callback: (err: any|null, response: CreateUserMingaJoinCodeResponse, metadata: grpc.Metadata) => void): void;
  createUserMingaJoinCode(request: CreateUserMingaJoinCodeRequest, metadata: grpc.Metadata, callback: (err: any|null, response: CreateUserMingaJoinCodeResponse, metadata: grpc.Metadata) => void): void;

  createUserMingaJoinCode(request: CreateUserMingaJoinCodeRequest, arg1?: grpc.Metadata|((err: any|null, response: CreateUserMingaJoinCodeResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: CreateUserMingaJoinCodeResponse, metadata: grpc.Metadata) => void): Promise<CreateUserMingaJoinCodeResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<CreateUserMingaJoinCodeResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__MingaManager.CreateUserMingaJoinCode, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  changeCustomJoinCode(request: ChangeCustomJoinCodeRequest): Promise<ChangeCustomJoinCodeResponse>;
  changeCustomJoinCode(request: ChangeCustomJoinCodeRequest, metadata: grpc.Metadata): Promise<ChangeCustomJoinCodeResponse>;
  changeCustomJoinCode(request: ChangeCustomJoinCodeRequest, callback: (err: any|null, response: ChangeCustomJoinCodeResponse, metadata: grpc.Metadata) => void): void;
  changeCustomJoinCode(request: ChangeCustomJoinCodeRequest, metadata: grpc.Metadata, callback: (err: any|null, response: ChangeCustomJoinCodeResponse, metadata: grpc.Metadata) => void): void;

  changeCustomJoinCode(request: ChangeCustomJoinCodeRequest, arg1?: grpc.Metadata|((err: any|null, response: ChangeCustomJoinCodeResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: ChangeCustomJoinCodeResponse, metadata: grpc.Metadata) => void): Promise<ChangeCustomJoinCodeResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<ChangeCustomJoinCodeResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__MingaManager.ChangeCustomJoinCode, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  /**
    / Gets the list of available minga subscriptions
  */
  getMingaSubscriptions(request: ReadMingaSubscriptionsRequest): Promise<ReadMingaSubscriptionsResponse>;
  /**
    / Gets the list of available minga subscriptions
  */
  getMingaSubscriptions(request: ReadMingaSubscriptionsRequest, metadata: grpc.Metadata): Promise<ReadMingaSubscriptionsResponse>;
  /**
    / Gets the list of available minga subscriptions
  */
  getMingaSubscriptions(request: ReadMingaSubscriptionsRequest, callback: (err: any|null, response: ReadMingaSubscriptionsResponse, metadata: grpc.Metadata) => void): void;
  /**
    / Gets the list of available minga subscriptions
  */
  getMingaSubscriptions(request: ReadMingaSubscriptionsRequest, metadata: grpc.Metadata, callback: (err: any|null, response: ReadMingaSubscriptionsResponse, metadata: grpc.Metadata) => void): void;

  getMingaSubscriptions(request: ReadMingaSubscriptionsRequest, arg1?: grpc.Metadata|((err: any|null, response: ReadMingaSubscriptionsResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: ReadMingaSubscriptionsResponse, metadata: grpc.Metadata) => void): Promise<ReadMingaSubscriptionsResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<ReadMingaSubscriptionsResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__MingaManager.GetMingaSubscriptions, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  /**
    / Get active subscription for the current minga
  */
  getActiveMingaSubscription(request: GetActiveMingaSubscriptionRequest): Promise<GetActiveMingaSubscriptionResponse>;
  /**
    / Get active subscription for the current minga
  */
  getActiveMingaSubscription(request: GetActiveMingaSubscriptionRequest, metadata: grpc.Metadata): Promise<GetActiveMingaSubscriptionResponse>;
  /**
    / Get active subscription for the current minga
  */
  getActiveMingaSubscription(request: GetActiveMingaSubscriptionRequest, callback: (err: any|null, response: GetActiveMingaSubscriptionResponse, metadata: grpc.Metadata) => void): void;
  /**
    / Get active subscription for the current minga
  */
  getActiveMingaSubscription(request: GetActiveMingaSubscriptionRequest, metadata: grpc.Metadata, callback: (err: any|null, response: GetActiveMingaSubscriptionResponse, metadata: grpc.Metadata) => void): void;

  getActiveMingaSubscription(request: GetActiveMingaSubscriptionRequest, arg1?: grpc.Metadata|((err: any|null, response: GetActiveMingaSubscriptionResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: GetActiveMingaSubscriptionResponse, metadata: grpc.Metadata) => void): Promise<GetActiveMingaSubscriptionResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<GetActiveMingaSubscriptionResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__MingaManager.GetActiveMingaSubscription, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  getMingaPartners(request: GetMingaPartnersRequest): Promise<GetMingaPartnersResponse>;
  getMingaPartners(request: GetMingaPartnersRequest, metadata: grpc.Metadata): Promise<GetMingaPartnersResponse>;
  getMingaPartners(request: GetMingaPartnersRequest, callback: (err: any|null, response: GetMingaPartnersResponse, metadata: grpc.Metadata) => void): void;
  getMingaPartners(request: GetMingaPartnersRequest, metadata: grpc.Metadata, callback: (err: any|null, response: GetMingaPartnersResponse, metadata: grpc.Metadata) => void): void;

  getMingaPartners(request: GetMingaPartnersRequest, arg1?: grpc.Metadata|((err: any|null, response: GetMingaPartnersResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: GetMingaPartnersResponse, metadata: grpc.Metadata) => void): Promise<GetMingaPartnersResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<GetMingaPartnersResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__MingaManager.GetMingaPartners, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  upsertMingaPartner(request: UpsertMingaPartnerRequest): Promise<UpsertMingaPartnerResponse>;
  upsertMingaPartner(request: UpsertMingaPartnerRequest, metadata: grpc.Metadata): Promise<UpsertMingaPartnerResponse>;
  upsertMingaPartner(request: UpsertMingaPartnerRequest, callback: (err: any|null, response: UpsertMingaPartnerResponse, metadata: grpc.Metadata) => void): void;
  upsertMingaPartner(request: UpsertMingaPartnerRequest, metadata: grpc.Metadata, callback: (err: any|null, response: UpsertMingaPartnerResponse, metadata: grpc.Metadata) => void): void;

  upsertMingaPartner(request: UpsertMingaPartnerRequest, arg1?: grpc.Metadata|((err: any|null, response: UpsertMingaPartnerResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: UpsertMingaPartnerResponse, metadata: grpc.Metadata) => void): Promise<UpsertMingaPartnerResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<UpsertMingaPartnerResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__MingaManager.UpsertMingaPartner, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  getMingaDistricts(request: GetMingaDistrictsRequest): Promise<GetMingaDistrictsResponse>;
  getMingaDistricts(request: GetMingaDistrictsRequest, metadata: grpc.Metadata): Promise<GetMingaDistrictsResponse>;
  getMingaDistricts(request: GetMingaDistrictsRequest, callback: (err: any|null, response: GetMingaDistrictsResponse, metadata: grpc.Metadata) => void): void;
  getMingaDistricts(request: GetMingaDistrictsRequest, metadata: grpc.Metadata, callback: (err: any|null, response: GetMingaDistrictsResponse, metadata: grpc.Metadata) => void): void;

  getMingaDistricts(request: GetMingaDistrictsRequest, arg1?: grpc.Metadata|((err: any|null, response: GetMingaDistrictsResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: GetMingaDistrictsResponse, metadata: grpc.Metadata) => void): Promise<GetMingaDistrictsResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<GetMingaDistrictsResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__MingaManager.GetMingaDistricts, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  deleteMingaDistrict(request: DeleteMingaDistrictRequest): Promise<DeleteMingaDistrictResponse>;
  deleteMingaDistrict(request: DeleteMingaDistrictRequest, metadata: grpc.Metadata): Promise<DeleteMingaDistrictResponse>;
  deleteMingaDistrict(request: DeleteMingaDistrictRequest, callback: (err: any|null, response: DeleteMingaDistrictResponse, metadata: grpc.Metadata) => void): void;
  deleteMingaDistrict(request: DeleteMingaDistrictRequest, metadata: grpc.Metadata, callback: (err: any|null, response: DeleteMingaDistrictResponse, metadata: grpc.Metadata) => void): void;

  deleteMingaDistrict(request: DeleteMingaDistrictRequest, arg1?: grpc.Metadata|((err: any|null, response: DeleteMingaDistrictResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: DeleteMingaDistrictResponse, metadata: grpc.Metadata) => void): Promise<DeleteMingaDistrictResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<DeleteMingaDistrictResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__MingaManager.DeleteMingaDistrict, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  getMingaDistrict(request: GetMingaDistrictRequest): Promise<GetMingaDistrictResponse>;
  getMingaDistrict(request: GetMingaDistrictRequest, metadata: grpc.Metadata): Promise<GetMingaDistrictResponse>;
  getMingaDistrict(request: GetMingaDistrictRequest, callback: (err: any|null, response: GetMingaDistrictResponse, metadata: grpc.Metadata) => void): void;
  getMingaDistrict(request: GetMingaDistrictRequest, metadata: grpc.Metadata, callback: (err: any|null, response: GetMingaDistrictResponse, metadata: grpc.Metadata) => void): void;

  getMingaDistrict(request: GetMingaDistrictRequest, arg1?: grpc.Metadata|((err: any|null, response: GetMingaDistrictResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: GetMingaDistrictResponse, metadata: grpc.Metadata) => void): Promise<GetMingaDistrictResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<GetMingaDistrictResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__MingaManager.GetMingaDistrict, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  exportDistricts(request: ExportDistrictsRequest): {cancel():void;close():void}&Observable<ExportDistrictsChunk>;
  exportDistricts(request: ExportDistrictsRequest, metadata: grpc.Metadata): {cancel():void;close():void}&Observable<ExportDistrictsChunk>;
  exportDistricts(request: ExportDistrictsRequest, onMessage: (message?: ExportDistrictsChunk) => void,onError?: (err: any) => void,onEnd?: (code: number, msg: string|undefined, metadata: grpc.Metadata) => void): void;
  exportDistricts(request: ExportDistrictsRequest, metadata: grpc.Metadata, onMessage: (message?: ExportDistrictsChunk) => void,onError?: (err: any) => void,onEnd?: (code: number, msg: string|undefined, metadata: grpc.Metadata) => void): void;

  exportDistricts(request: ExportDistrictsRequest, arg1?: grpc.Metadata|((message?: ExportDistrictsChunk) => void), arg2?: ((message?: ExportDistrictsChunk) => void)|((err: any) => void), arg3?: ((err: any) => void)|((code: number, msg: string|undefined, metadata: grpc.Metadata) => void), arg4?: (code: number, msg: string|undefined, metadata: grpc.Metadata) => void): {cancel():void;close():void}&Observable<ExportDistrictsChunk>|void {
    let ret: any;
    let metadata: any;

    let onMessage: any;
    let onError: any;
    let onEnd: any;
    if(typeof arg1 === 'function') {
      onMessage = arg1;
      onError = arg2;
      onEnd = arg3;
    } else if(typeof arg2 === 'function') {
      metadata = arg1;
      onMessage = arg2;
      onError = arg3;
      onEnd = arg4;
    } else {
      metadata = arg1;
    }

    if(!onMessage) {
      let subject = new Subject<ExportDistrictsChunk>();
      ret = subject.asObservable();

      onMessage = (response: any) => {
        subject.next(response);
      };

      onError = (err: any) => {
        subject.error(err);
      };

      onEnd = (code: any, msg: any, metadata: any) => {
        subject.complete();
      };

    } else {
      if(!onError) {
        onError = (err: any) => console.error(err);
      }

      if(!onEnd) {
        onEnd = (code: any, msg: any, metadata: any) => {};
      }
    }

    let req = grpc.invoke(__MingaManager.ExportDistricts, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onMessage: response => this._ngZone.run(() => {
        onMessage(response);
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code == grpc.Code.OK) {
          onEnd(code, msg, metadata);
        } else {
          let err = new Error(code + ' ' + (msg||''));
          (<any>err).code = code;
          onError(err);
        }
      })
    });

    (<any>ret).close = () => {
      console.warn('[Angular Grpc] .close() is deprecated use .cancel() instead');
      return req.close();
    };
    (<any>ret).cancel = () => req.close();
    return ret;
  }

  upsertMingaDistrict(request: UpsertMingaDistrictRequest): Promise<UpsertMingaDistrictResponse>;
  upsertMingaDistrict(request: UpsertMingaDistrictRequest, metadata: grpc.Metadata): Promise<UpsertMingaDistrictResponse>;
  upsertMingaDistrict(request: UpsertMingaDistrictRequest, callback: (err: any|null, response: UpsertMingaDistrictResponse, metadata: grpc.Metadata) => void): void;
  upsertMingaDistrict(request: UpsertMingaDistrictRequest, metadata: grpc.Metadata, callback: (err: any|null, response: UpsertMingaDistrictResponse, metadata: grpc.Metadata) => void): void;

  upsertMingaDistrict(request: UpsertMingaDistrictRequest, arg1?: grpc.Metadata|((err: any|null, response: UpsertMingaDistrictResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: UpsertMingaDistrictResponse, metadata: grpc.Metadata) => void): Promise<UpsertMingaDistrictResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<UpsertMingaDistrictResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__MingaManager.UpsertMingaDistrict, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  generateMingaSFTPGoUser(request: GenerateMingaSFTPGoUserRequest): Promise<GenerateMingaSFTPGoUserResponse>;
  generateMingaSFTPGoUser(request: GenerateMingaSFTPGoUserRequest, metadata: grpc.Metadata): Promise<GenerateMingaSFTPGoUserResponse>;
  generateMingaSFTPGoUser(request: GenerateMingaSFTPGoUserRequest, callback: (err: any|null, response: GenerateMingaSFTPGoUserResponse, metadata: grpc.Metadata) => void): void;
  generateMingaSFTPGoUser(request: GenerateMingaSFTPGoUserRequest, metadata: grpc.Metadata, callback: (err: any|null, response: GenerateMingaSFTPGoUserResponse, metadata: grpc.Metadata) => void): void;

  generateMingaSFTPGoUser(request: GenerateMingaSFTPGoUserRequest, arg1?: grpc.Metadata|((err: any|null, response: GenerateMingaSFTPGoUserResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: GenerateMingaSFTPGoUserResponse, metadata: grpc.Metadata) => void): Promise<GenerateMingaSFTPGoUserResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<GenerateMingaSFTPGoUserResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__MingaManager.GenerateMingaSFTPGoUser, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  getMingaStatsForPerson(request: GetMingaStatsForPersonRequest): Promise<GetMingaStatsForPersonResponse>;
  getMingaStatsForPerson(request: GetMingaStatsForPersonRequest, metadata: grpc.Metadata): Promise<GetMingaStatsForPersonResponse>;
  getMingaStatsForPerson(request: GetMingaStatsForPersonRequest, callback: (err: any|null, response: GetMingaStatsForPersonResponse, metadata: grpc.Metadata) => void): void;
  getMingaStatsForPerson(request: GetMingaStatsForPersonRequest, metadata: grpc.Metadata, callback: (err: any|null, response: GetMingaStatsForPersonResponse, metadata: grpc.Metadata) => void): void;

  getMingaStatsForPerson(request: GetMingaStatsForPersonRequest, arg1?: grpc.Metadata|((err: any|null, response: GetMingaStatsForPersonResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: GetMingaStatsForPersonResponse, metadata: grpc.Metadata) => void): Promise<GetMingaStatsForPersonResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<GetMingaStatsForPersonResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__MingaManager.GetMingaStatsForPerson, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

  getStatsForStudent(request: GetStatsForStudentRequest): Promise<GetStatsForStudentResponse>;
  getStatsForStudent(request: GetStatsForStudentRequest, metadata: grpc.Metadata): Promise<GetStatsForStudentResponse>;
  getStatsForStudent(request: GetStatsForStudentRequest, callback: (err: any|null, response: GetStatsForStudentResponse, metadata: grpc.Metadata) => void): void;
  getStatsForStudent(request: GetStatsForStudentRequest, metadata: grpc.Metadata, callback: (err: any|null, response: GetStatsForStudentResponse, metadata: grpc.Metadata) => void): void;

  getStatsForStudent(request: GetStatsForStudentRequest, arg1?: grpc.Metadata|((err: any|null, response: GetStatsForStudentResponse, metadata: grpc.Metadata) => void), arg2?: (err: any|null, response: GetStatsForStudentResponse, metadata: grpc.Metadata) => void): Promise<GetStatsForStudentResponse>|void {
    let ret: any;
    let callback: any;
    let metadata: any;

    if(typeof arg1 === 'function') {
      callback = arg1;
    } else {
      metadata = arg1;
      callback = arg2;
    }

    if(!callback) {
      ret = new Promise<GetStatsForStudentResponse>((resolve, reject) => {
        callback = (err: any, response: any) => {
          if(err) reject(err);
          else resolve(response);
        };
      });
    }

    let responseMetadata: grpc.Metadata|null = null;

    let req = grpc.invoke(__MingaManager.GetStatsForStudent, {
      request: request,
      host: (<any>window).DEFAULT_ANGULAR_GRPC_HOST || 'https://' + location.hostname,
      metadata: metadata,
      onHeaders: headers => responseMetadata = headers,
      onMessage: response => this._ngZone.run(() => {
        callback(null, response, responseMetadata || new grpc.Metadata());
      }),
      onEnd: (code, msg, metadata) => this._ngZone.run(() => {
        if(code != grpc.Code.OK) {
          let err = new Error(msg);
          (<any>err).code = code;
          callback(err);
        }
      })
    });

    (<any>ret).cancel = () => req.close();
    return ret;
  }

}

