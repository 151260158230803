// source: report_person/report_person.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

import * as jspb from 'google-protobuf';
export namespace ReportPerson {
  export interface AsObject {
    id: number;
    name: string;
    email: string;
    grade: string;
    firstName: string;
    lastName: string;
    roleType: string;
    personHash: string;
    studentId: string;
  }
}
export class ReportPerson extends jspb.Message {
  static readonly displayName = "ReportPerson";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): ReportPerson.AsObject {
    return ReportPerson.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!ReportPerson} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: ReportPerson) {
    var f: any;
    var obj: any = {
      id: jspb.Message.getFieldWithDefault(msg, 1, 0),
      name: jspb.Message.getFieldWithDefault(msg, 2, ""),
      email: jspb.Message.getFieldWithDefault(msg, 3, ""),
      grade: jspb.Message.getFieldWithDefault(msg, 4, ""),
      firstName: jspb.Message.getFieldWithDefault(msg, 5, ""),
      lastName: jspb.Message.getFieldWithDefault(msg, 6, ""),
      roleType: jspb.Message.getFieldWithDefault(msg, 7, ""),
      personHash: jspb.Message.getFieldWithDefault(msg, 8, ""),
      studentId: jspb.Message.getFieldWithDefault(msg, 9, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!ReportPerson}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new ReportPerson;
    return ReportPerson.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!ReportPerson} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!ReportPerson}
   */
  static deserializeBinaryFromReader(msg: ReportPerson, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {number} */ (reader.readInt32());
        msg.setId(value1);
        break;
      case 2:
        var value2 = /** @type {string} */ (reader.readString());
        msg.setName(value2);
        break;
      case 3:
        var value3 = /** @type {string} */ (reader.readString());
        msg.setEmail(value3);
        break;
      case 4:
        var value4 = /** @type {string} */ (reader.readString());
        msg.setGrade(value4);
        break;
      case 5:
        var value5 = /** @type {string} */ (reader.readString());
        msg.setFirstName(value5);
        break;
      case 6:
        var value6 = /** @type {string} */ (reader.readString());
        msg.setLastName(value6);
        break;
      case 7:
        var value7 = /** @type {string} */ (reader.readString());
        msg.setRoleType(value7);
        break;
      case 8:
        var value8 = /** @type {string} */ (reader.readString());
        msg.setPersonHash(value8);
        break;
      case 9:
        var value9 = /** @type {string} */ (reader.readString());
        msg.setStudentId(value9);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!ReportPerson} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: ReportPerson, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getId();
    if (f !== 0) {
      writer.writeInt32(
        1,
        f
      );
    }
    f = message.getName();
    if (f.length > 0) {
      writer.writeString(
        2,
        f
      );
    }
    f = message.getEmail();
    if (f.length > 0) {
      writer.writeString(
        3,
        f
      );
    }
    f = message.getGrade();
    if (f.length > 0) {
      writer.writeString(
        4,
        f
      );
    }
    f = message.getFirstName();
    if (f.length > 0) {
      writer.writeString(
        5,
        f
      );
    }
    f = message.getLastName();
    if (f.length > 0) {
      writer.writeString(
        6,
        f
      );
    }
    f = message.getRoleType();
    if (f.length > 0) {
      writer.writeString(
        7,
        f
      );
    }
    f = message.getPersonHash();
    if (f.length > 0) {
      writer.writeString(
        8,
        f
      );
    }
    f = message.getStudentId();
    if (f.length > 0) {
      writer.writeString(
        9,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    ReportPerson.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional int32 id = 1;
   * @return {number}
   */
  getId(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
  };


  /** @param {number} value */
  setId(value: number) {
    jspb.Message.setProto3IntField(this, 1, value);
  }


  /**
   * optional string name = 2;
   * @return {string}
   */
  getName(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
  };


  /** @param {string} value */
  setName(value: string) {
    jspb.Message.setProto3StringField(this, 2, value);
  }


  /**
   * optional string email = 3;
   * @return {string}
   */
  getEmail(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
  };


  /** @param {string} value */
  setEmail(value: string) {
    jspb.Message.setProto3StringField(this, 3, value);
  }


  /**
   * optional string grade = 4;
   * @return {string}
   */
  getGrade(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
  };


  /** @param {string} value */
  setGrade(value: string) {
    jspb.Message.setProto3StringField(this, 4, value);
  }


  /**
   * optional string first_name = 5;
   * @return {string}
   */
  getFirstName(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
  };


  /** @param {string} value */
  setFirstName(value: string) {
    jspb.Message.setProto3StringField(this, 5, value);
  }


  /**
   * optional string last_name = 6;
   * @return {string}
   */
  getLastName(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
  };


  /** @param {string} value */
  setLastName(value: string) {
    jspb.Message.setProto3StringField(this, 6, value);
  }


  /**
   * optional string role_type = 7;
   * @return {string}
   */
  getRoleType(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
  };


  /** @param {string} value */
  setRoleType(value: string) {
    jspb.Message.setProto3StringField(this, 7, value);
  }


  /**
   * optional string person_hash = 8;
   * @return {string}
   */
  getPersonHash(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
  };


  /** @param {string} value */
  setPersonHash(value: string) {
    jspb.Message.setProto3StringField(this, 8, value);
  }


  /**
   * optional string student_id = 9;
   * @return {string}
   */
  getStudentId(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
  };


  /** @param {string} value */
  setStudentId(value: string) {
    jspb.Message.setProto3StringField(this, 9, value);
  }


}
