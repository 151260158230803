// source: flex_time/flex_time_activity.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

import * as jspb from 'google-protobuf';
import * as restriction_restriction_pb from '../restriction/restriction_pb';
export namespace ExistingRegistration {
  export interface AsObject {
    type?: RegistrationType;
    registeredBy: string;
    studentHash: string;
    studentName: string;
    activityTeacherName: string;
    activityName: string;
    activityLocation: string;
  }
}
export class ExistingRegistration extends jspb.Message {
  static readonly displayName = "ExistingRegistration";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): ExistingRegistration.AsObject {
    return ExistingRegistration.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!ExistingRegistration} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: ExistingRegistration) {
    var f: any;
    var obj: any = {
      type: jspb.Message.getFieldWithDefault(msg, 1, 0),
      registeredBy: jspb.Message.getFieldWithDefault(msg, 2, ""),
      studentHash: jspb.Message.getFieldWithDefault(msg, 3, ""),
      studentName: jspb.Message.getFieldWithDefault(msg, 4, ""),
      activityTeacherName: jspb.Message.getFieldWithDefault(msg, 5, ""),
      activityName: jspb.Message.getFieldWithDefault(msg, 6, ""),
      activityLocation: jspb.Message.getFieldWithDefault(msg, 7, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!ExistingRegistration}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new ExistingRegistration;
    return ExistingRegistration.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!ExistingRegistration} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!ExistingRegistration}
   */
  static deserializeBinaryFromReader(msg: ExistingRegistration, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {!RegistrationType} */ (reader.readEnum());
        msg.setType(value1);
        break;
      case 2:
        var value2 = /** @type {string} */ (reader.readString());
        msg.setRegisteredBy(value2);
        break;
      case 3:
        var value3 = /** @type {string} */ (reader.readString());
        msg.setStudentHash(value3);
        break;
      case 4:
        var value4 = /** @type {string} */ (reader.readString());
        msg.setStudentName(value4);
        break;
      case 5:
        var value5 = /** @type {string} */ (reader.readString());
        msg.setActivityTeacherName(value5);
        break;
      case 6:
        var value6 = /** @type {string} */ (reader.readString());
        msg.setActivityName(value6);
        break;
      case 7:
        var value7 = /** @type {string} */ (reader.readString());
        msg.setActivityLocation(value7);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!ExistingRegistration} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: ExistingRegistration, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getType();
    if (f !== 0.0) {
      writer.writeEnum(
        1,
        f
      );
    }
    f = message.getRegisteredBy();
    if (f.length > 0) {
      writer.writeString(
        2,
        f
      );
    }
    f = message.getStudentHash();
    if (f.length > 0) {
      writer.writeString(
        3,
        f
      );
    }
    f = message.getStudentName();
    if (f.length > 0) {
      writer.writeString(
        4,
        f
      );
    }
    f = message.getActivityTeacherName();
    if (f.length > 0) {
      writer.writeString(
        5,
        f
      );
    }
    f = message.getActivityName();
    if (f.length > 0) {
      writer.writeString(
        6,
        f
      );
    }
    f = message.getActivityLocation();
    if (f.length > 0) {
      writer.writeString(
        7,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    ExistingRegistration.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional RegistrationType type = 1;
   * @return {!RegistrationType}
   */
  getType(): RegistrationType {
    return /** @type {!RegistrationType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
  };


  /** @param {!RegistrationType} value */
  setType(value: RegistrationType) {
    jspb.Message.setProto3EnumField(this, 1, value);
  }


  /**
   * optional string registered_by = 2;
   * @return {string}
   */
  getRegisteredBy(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
  };


  /** @param {string} value */
  setRegisteredBy(value: string) {
    jspb.Message.setProto3StringField(this, 2, value);
  }


  /**
   * optional string student_hash = 3;
   * @return {string}
   */
  getStudentHash(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
  };


  /** @param {string} value */
  setStudentHash(value: string) {
    jspb.Message.setProto3StringField(this, 3, value);
  }


  /**
   * optional string student_name = 4;
   * @return {string}
   */
  getStudentName(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
  };


  /** @param {string} value */
  setStudentName(value: string) {
    jspb.Message.setProto3StringField(this, 4, value);
  }


  /**
   * optional string activity_teacher_name = 5;
   * @return {string}
   */
  getActivityTeacherName(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
  };


  /** @param {string} value */
  setActivityTeacherName(value: string) {
    jspb.Message.setProto3StringField(this, 5, value);
  }


  /**
   * optional string activity_name = 6;
   * @return {string}
   */
  getActivityName(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
  };


  /** @param {string} value */
  setActivityName(value: string) {
    jspb.Message.setProto3StringField(this, 6, value);
  }


  /**
   * optional string activity_location = 7;
   * @return {string}
   */
  getActivityLocation(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
  };


  /** @param {string} value */
  setActivityLocation(value: string) {
    jspb.Message.setProto3StringField(this, 7, value);
  }


}
export namespace ValidateAssignmentRequest {
  export interface AsObject {
    periodId: number;
    activityId: number;
    personHashesList: string[];
  }
}
export class ValidateAssignmentRequest extends jspb.Message {
  static readonly displayName = "ValidateAssignmentRequest";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [3];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, ValidateAssignmentRequest.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): ValidateAssignmentRequest.AsObject {
    return ValidateAssignmentRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!ValidateAssignmentRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: ValidateAssignmentRequest) {
    var f: any;
    var obj: any = {
      periodId: jspb.Message.getFieldWithDefault(msg, 1, 0),
      activityId: jspb.Message.getFieldWithDefault(msg, 2, 0),
      personHashesList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!ValidateAssignmentRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new ValidateAssignmentRequest;
    return ValidateAssignmentRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!ValidateAssignmentRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!ValidateAssignmentRequest}
   */
  static deserializeBinaryFromReader(msg: ValidateAssignmentRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {number} */ (reader.readInt32());
        msg.setPeriodId(value1);
        break;
      case 2:
        var value2 = /** @type {number} */ (reader.readInt32());
        msg.setActivityId(value2);
        break;
      case 3:
        var value3 = /** @type {string} */ (reader.readString());
        msg.addPersonHashes(value3);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!ValidateAssignmentRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: ValidateAssignmentRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getPeriodId();
    if (f !== 0) {
      writer.writeInt32(
        1,
        f
      );
    }
    f = message.getActivityId();
    if (f !== 0) {
      writer.writeInt32(
        2,
        f
      );
    }
    f = message.getPersonHashesList();
    if (f.length > 0) {
      writer.writeRepeatedString(
        3,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    ValidateAssignmentRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional int32 period_id = 1;
   * @return {number}
   */
  getPeriodId(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
  };


  /** @param {number} value */
  setPeriodId(value: number) {
    jspb.Message.setProto3IntField(this, 1, value);
  }


  /**
   * optional int32 activity_id = 2;
   * @return {number}
   */
  getActivityId(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
  };


  /** @param {number} value */
  setActivityId(value: number) {
    jspb.Message.setProto3IntField(this, 2, value);
  }


  /**
   * repeated string person_hashes = 3;
   * @return {!Array<string>}
   */
  getPersonHashesList(): string[] {
    return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
  };


  /** @param {!Array<string>} value */
  setPersonHashesList(value: string[]) {
    jspb.Message.setField(this, 3, value || []);
  }


  /**
   * @param {string} value
   * @param {number=} opt_index
   */
  addPersonHashes(value: string, opt_index?: number): void {
    jspb.Message.addToRepeatedField(this, 3, value, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearPersonHashesList() {
    this.setPersonHashesList([]);
  }


}
export namespace ValidateAssignmentResponse {
  export interface AsObject {
    successList: string[];
    existingRegistrationList: ExistingRegistration.AsObject[];
    restrictionErrorList: restriction_restriction_pb.RestrictionError.AsObject[];
  }
}
export class ValidateAssignmentResponse extends jspb.Message {
  static readonly displayName = "ValidateAssignmentResponse";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [1,2,3];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, ValidateAssignmentResponse.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): ValidateAssignmentResponse.AsObject {
    return ValidateAssignmentResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!ValidateAssignmentResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: ValidateAssignmentResponse) {
    var f: any;
    var obj: any = {
      successList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
      existingRegistrationList: jspb.Message.toObjectList(msg.getExistingRegistrationList(),
      ExistingRegistration.toObject, includeInstance),
      restrictionErrorList: jspb.Message.toObjectList(msg.getRestrictionErrorList(),
      restriction_restriction_pb.RestrictionError.toObject, includeInstance)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!ValidateAssignmentResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new ValidateAssignmentResponse;
    return ValidateAssignmentResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!ValidateAssignmentResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!ValidateAssignmentResponse}
   */
  static deserializeBinaryFromReader(msg: ValidateAssignmentResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.addSuccess(value1);
        break;
      case 2:
        var value2 = new ExistingRegistration;
        reader.readMessage(value2,ExistingRegistration.deserializeBinaryFromReader);
        msg.addExistingRegistration(value2);
        break;
      case 3:
        var value3 = new restriction_restriction_pb.RestrictionError;
        reader.readMessage(value3,restriction_restriction_pb.RestrictionError.deserializeBinaryFromReader);
        msg.addRestrictionError(value3);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!ValidateAssignmentResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: ValidateAssignmentResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getSuccessList();
    if (f.length > 0) {
      writer.writeRepeatedString(
        1,
        f
      );
    }
    f = message.getExistingRegistrationList();
    if (f.length > 0) {
      writer.writeRepeatedMessage(
        2,
        f,
        ExistingRegistration.serializeBinaryToWriter
      );
    }
    f = message.getRestrictionErrorList();
    if (f.length > 0) {
      writer.writeRepeatedMessage(
        3,
        f,
        restriction_restriction_pb.RestrictionError.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    ValidateAssignmentResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * repeated string success = 1;
   * @return {!Array<string>}
   */
  getSuccessList(): string[] {
    return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
  };


  /** @param {!Array<string>} value */
  setSuccessList(value: string[]) {
    jspb.Message.setField(this, 1, value || []);
  }


  /**
   * @param {string} value
   * @param {number=} opt_index
   */
  addSuccess(value: string, opt_index?: number): void {
    jspb.Message.addToRepeatedField(this, 1, value, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearSuccessList() {
    this.setSuccessList([]);
  }


  /**
   * repeated ExistingRegistration existing_registration = 2;
   * @return {!Array<!ExistingRegistration>}
   */
  getExistingRegistrationList(): ExistingRegistration[] {
    return /** @type{!Array<!ExistingRegistration>} */ (
      jspb.Message.getRepeatedWrapperField(this, ExistingRegistration, 2));
  }


  /** @param {!Array<!ExistingRegistration>} value */
  setExistingRegistrationList(value?: ExistingRegistration[]) {
    jspb.Message.setRepeatedWrapperField(this, 2, value);
  }


  /**
   * @param {!ExistingRegistration=} opt_value
   * @param {number=} opt_index
   * @return {!ExistingRegistration}
   */
  addExistingRegistration(opt_value?: ExistingRegistration, opt_index?: number): ExistingRegistration {
    return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, ExistingRegistration, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearExistingRegistrationList() {
    this.setExistingRegistrationList([]);
  }


  /**
   * repeated restriction.RestrictionError restriction_error = 3;
   * @return {!Array<!RestrictionError>}
   */
  getRestrictionErrorList(): restriction_restriction_pb.RestrictionError[] {
    return /** @type{!Array<!RestrictionError>} */ (
      jspb.Message.getRepeatedWrapperField(this, restriction_restriction_pb.RestrictionError, 3));
  }


  /** @param {!Array<!RestrictionError>} value */
  setRestrictionErrorList(value?: restriction_restriction_pb.RestrictionError[]) {
    jspb.Message.setRepeatedWrapperField(this, 3, value);
  }


  /**
   * @param {!RestrictionError=} opt_value
   * @param {number=} opt_index
   * @return {!RestrictionError}
   */
  addRestrictionError(opt_value?: restriction_restriction_pb.RestrictionError, opt_index?: number): restriction_restriction_pb.RestrictionError {
    return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, restriction_restriction_pb.RestrictionError, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearRestrictionErrorList() {
    this.setRestrictionErrorList([]);
  }


}
export enum RegistrationType {
  REGISTRATION = 0,
  ASSIGNMENT = 1,
}
