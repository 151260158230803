import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Route,
  Router,
  RouterStateSnapshot,
} from '@angular/router';

import { Observable, of } from 'rxjs';
import { map, mergeMap, take } from 'rxjs/operators';

import { AppConfigService } from '@app/src/app/minimal/services/AppConfig';
import { PermissionsService } from '@app/src/app/permissions';
import { MingaSettingsService } from '@app/src/app/store/Minga/services';

import { NavigationPermissionService } from '@shared/services/navigation/navigation-permission.service';
import { AccessRestrictionsValidator } from '@shared/utils/access-restrictions-validator';

@Injectable()
export class AccessRestrictionsGuard implements CanActivate {
  constructor(
    private _router: Router,
    private _settings: MingaSettingsService,
    private _permissions: PermissionsService,
    private _navPermissions: NavigationPermissionService,
    private _appConfig: AppConfigService,
  ) {}

  canActivate(
    _route: ActivatedRouteSnapshot,
    _state: RouterStateSnapshot,
  ): any {
    const validator = new AccessRestrictionsValidator(
      this._permissions,
      this._settings,
      this._navPermissions,
      this._appConfig,
    );

    return validator.observeAccess$(_route.data?.accessRestrictions).pipe(
      mergeMap(isAllowed => {
        if (isAllowed) {
          return of(true);
        } else {
          if (_route.data?.enableAutoRedirect) {
            const parentRoute = _route.parent.routeConfig;
            return this._redirectToFirstAccessibleSibling(
              _state.url,
              parentRoute,
              r => validator.observeAccess$(r),
            );
          } else {
            return validator
              .observeAccess$(_route.data?.accessRestrictions)
              .pipe(
                map(
                  canAccess => canAccess || this._router.createUrlTree([`/`]),
                ),
              );
          }
        }
      }),
    );
  }

  private async _redirectToFirstAccessibleSibling(
    url: string,
    parentRoute: Route,
    observeFn$: (...args: any) => Observable<boolean>,
  ) {
    const siblingRoutes = parentRoute.children.filter(
      child => !child.redirectTo,
    );
    let path: string;
    for (const sibling of siblingRoutes) {
      const canAccess = await observeFn$(sibling?.data?.accessRestrictions)
        .pipe(take(1))
        .toPromise();
      if (canAccess) {
        path = sibling.path;
        break;
      }
    }
    if (path === '') return true;
    if (path === undefined) return this._router.createUrlTree([`/`]);
    const urlSegments = url.split('/');
    const urlSegmentSize = urlSegments.length;
    const lastSegment = urlSegments[urlSegmentSize - 1];
    if (lastSegment === path) return true;
    if (urlSegmentSize > 2) urlSegments[urlSegmentSize - 1] = path;
    else urlSegments.push(path);
    return this._router.createUrlTree([urlSegments.join('/')]);
  }
}
