import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { gateway } from 'libs/generated-grpc-web';
import { IDesignedSvg } from 'libs/shared';
import { DesignedSvgMapper } from 'libs/shared-grpc';

import { BaseShortCardClass } from '../BaseShortCardClass';

export interface IMgPostShortCardElementProperties {}

@Component({
  selector: 'mg-post-short-card',
  templateUrl: './MgPostShortCard.element.html',
  styleUrls: ['./MgPostShortCard.element.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MgPostShortCardElement extends BaseShortCardClass {
  /** @internal */
  private _content: gateway.content_views_pb.ShortCardView.AsObject | null =
    null;
  /** @internal */
  private _designedSvg: IDesignedSvg | null = null;

  @Input()
  set content(content: gateway.content_views_pb.ShortCardView.AsObject | null) {
    this._content = content || null;
    this.setDesignedSvgFromContent(content);
  }

  get content(): gateway.content_views_pb.ShortCardView.AsObject | null {
    return this._content;
  }

  @Input()
  pinned: boolean = false;

  @Input()
  markKeywords: string = '';

  @Input()
  context: string = '';

  @Input()
  commentRoles: string[] | null = null;

  get designedSvg(): IDesignedSvg | null {
    return this._designedSvg;
  }

  private setDesignedSvgFromContent(
    content: gateway.content_views_pb.ShortCardView.AsObject | null,
  ) {
    if (content && content.designedSvg) {
      this._designedSvg = DesignedSvgMapper.asObjectToIDesignedSvg(
        content.designedSvg,
      );
    } else {
      this._designedSvg = null;
    }
  }
}
