import { NgModule } from '@angular/core';

import { MgPipesModule } from '@app/src/app/pipes';

import { MgNotificationBaseModule } from '../MgNotificationBase';
import { MgEventInviteNotificationElement } from './MgEventInviteNotification.element';

export { MgEventInviteNotificationElement };
@NgModule({
  imports: [
    // Minga dependencies
    MgNotificationBaseModule,
    MgPipesModule,

    // External dependencies
  ],
  declarations: [MgEventInviteNotificationElement],
  exports: [MgEventInviteNotificationElement],
})
export class MgEventInviteNotificationModule {}
