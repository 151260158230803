import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';

import { NgOption, NgSelectComponent } from '@ng-select/ng-select';
import { AddTagFn } from '@ng-select/ng-select/lib/ng-select.component';
import { uniqueId } from 'lodash';
import { BehaviorSubject, Observable, ReplaySubject, Subject } from 'rxjs';
import {
  distinctUntilChanged,
  map,
  skip,
  startWith,
  takeUntil,
} from 'rxjs/operators';

import { NgSelectModified } from '@shared/components/ng-select-modified';
import { SystemAlertSnackBarService } from '@shared/components/system-alert-snackbar';
import { MediaService } from '@shared/services/media';

import { FormSelectMessages } from '../../constants';
import {
  FormLabelBackground,
  FormSelectAppearance,
  FormSelectOption,
  FormSelectReturnTypes,
} from '../../types';
import { FormSheetSelectService } from '../form-sheet-select';

@Component({
  selector: 'mg-form-select',
  templateUrl: './form-select.component.html',
  styleUrls: ['./form-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormSelectComponent
  extends NgSelectModified
  implements OnDestroy, OnInit
{
  // Child components

  @ViewChild(NgSelectComponent, { static: false })
  ngSelect: NgSelectComponent;

  @ContentChild(TemplateRef)
  public selectContent: TemplateRef<any>;

  // Constants

  public readonly MESSAGES = FormSelectMessages;

  // General observables

  private readonly _destroyed$ = new ReplaySubject<void>(1);
  public readonly input$ = new Subject<string>();
  private readonly _isLoading$ = new BehaviorSubject<boolean>(false);
  public readonly isLoadingObs$ = this._isLoading$.asObservable();

  // Compact select

  private _extraCounter = 0;
  private _hiddenElements: Element[] = [];
  private _counterElement: Element;

  // Inputs

  @Input() name: string = uniqueId('mg-select');
  @Input() appearance: FormSelectAppearance;
  @Input() addTag: boolean | AddTagFn = false;
  @Input() addTagText = 'Add item';
  @Input() returnMode: FormSelectReturnTypes = 'value';
  @Input() searchable: boolean;
  @Input() isClearable: boolean;
  @Input() options: FormSelectOption[];
  @Input() closeOnSelect = true;
  @Input() hideSelected: boolean;
  @Input() isFullWidth = false;
  @Input() labelBackground: FormLabelBackground = 'white';
  @Input() notFoundText: string = FormSelectMessages.NO_RESULTS;
  @Input() set disabled(isDisabled: boolean) {
    if (isDisabled) {
      this.control.disable();
    } else {
      this.control.enable();
    }
    this._cdr.detectChanges();
  }
  @Input() appendTo: string;
  @Input() condensed: boolean;
  @Input() hint: string;
  /**
   * Unique id for things like analytics and testing to hook into
   * Important to note changing this could break either of those
   */
  @Input() id: string;
  @Input() enableMobileSheet = false;
  @Input() setFocus: Observable<any>;
  /**
   * Hides the error message that is displayed when the input is invalid
   */
  @Input() suppressErrorMessage = false;

  // Outputs

  @Output()
  public readonly selectionChanged = new EventEmitter<
    FormSelectOption | FormSelectOption[]
  >();
  @Output() public readonly blurEvent = new EventEmitter<void>();

  // Host bindings

  @HostBinding('class.add-mobile-bottomsheet')
  get hasCustomClass() {
    return this.enableMobileSheet;
  }

  public readonly showBottomSheetTrigger$ = this.media.isMobileView$.pipe(
    takeUntil(this._destroyed$),
    map(isMobileView => this.enableMobileSheet && isMobileView),
  );

  /** Select */
  private readonly _showClearSubject = new BehaviorSubject<boolean>(false);
  public readonly showClear$ = this._showClearSubject.asObservable();
  private _valueChanges$: Observable<any>;
  private _inputAttrs: Partial<HTMLInputElement> = {
    autocomplete: 'off',
    name: uniqueId('mg-select'),
  };
  public readonly inputAttrs = this._inputAttrs as Record<string, string>;

  get currentValue() {
    return this.value ?? this.control.value;
  }

  // Computed getters

  get searchInput$() {
    return this.input$;
  }

  get isLoading$() {
    return this._isLoading$;
  }

  get valueChanges$() {
    return this.control.valueChanges;
  }

  /** Component constructor */
  constructor(
    public media: MediaService,
    private _snackBar: SystemAlertSnackBarService,
    protected _fb: UntypedFormBuilder,
    private _renderer: Renderer2,
    private _cdr: ChangeDetectorRef,
    private _formSheetSelect: FormSheetSelectService,
  ) {
    super(_fb);
  }

  ngOnInit(): void {
    this._valueChanges$ = this.control.valueChanges.pipe(
      takeUntil(this._destroyed$),
      distinctUntilChanged(),
    );

    if (this.id) {
      this._inputAttrs['data-analytics'] = this.id;
      this._inputAttrs['data-test'] = this.id;
    }

    if (this.value) {
      if (Array.isArray(this.value) && !this.value.length) return;
      this._showClearSubject.next(true);
    }

    this._valueChanges$
      .pipe(
        startWith(false),
        skip(1),
        takeUntil(this._destroyed$),
        map(v => {
          return v && (Array.isArray(v) ? v[0] !== undefined : true);
        }),
      )
      .subscribe(v => {
        this._showClearSubject.next(v);
      });

    // we need to trigger change detection when the status changes
    // or the component wont update if the control is touched/dirty/etc
    // when manually marked from parent component
    this.control.statusChanges
      .pipe(takeUntil(this._destroyed$))
      .subscribe(() => {
        this._cdr.markForCheck();
      });
  }

  ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
    this._showClearSubject.complete();
  }

  public focus() {
    this.ngSelect.focus();
  }

  public open() {
    this.ngSelect.open();
  }

  public async openMobileBottomSheet() {
    const initialSelectionValue = this.control?.value ?? this.value;
    let initialSelectionOption: FormSelectOption<any>[];
    if (!this.multiple) {
      initialSelectionOption = this.options.filter(
        o => o.value === initialSelectionValue,
      );
    } else {
      initialSelectionOption = this.options.filter(o =>
        initialSelectionValue?.includes(o?.value),
      );
    }
    this._formSheetSelect
      .open({
        options: this.options,
        enableSearch: this.searchable,
        title: this.placeholder,
        multiple: this.multiple,
        initialSelection: initialSelectionOption,
      })
      .subscribe(response => {
        if (response?.type === 'submit') {
          let v;
          if (this.multiple) {
            v = response?.data?.selection?.map(option => option.value);
            this.handleModelChange(v);
          } else {
            v = response?.data?.selection[0]?.value ?? null;
            const selectedOption = this.options.find(
              option => option.value === v,
            );
            this.handleModelChange(selectedOption);
          }
          this.control.setValue(v);
          this._cdr.markForCheck();
        }
      });
  }

  public handleModelChange(event: FormSelectOption | FormSelectOption[]) {
    let changedResult = event;
    if (Array.isArray(changedResult)) {
      if (this.returnMode !== 'full') {
        changedResult = changedResult.map(option => option[this.returnMode]);
      }
    } else if (this.returnMode !== 'full' && !!event) {
      changedResult = changedResult[this.returnMode];
    }
    this.selectionChanged.emit(changedResult);

    // underlying ng-select has a bug where if it's searchable it wont blur on select
    // so we need to manually blur it
    if (this.searchable) {
      this.ngSelect.blur();
    }
  }

  public async setInitialValue<T>(val: T | T[]) {
    this.control.reset({
      selected: val,
    });
  }

  public async removeItem(item: any): Promise<void> {
    const val = [...this.control.value];
    val.splice(val.indexOf(item.value), 1);
    this.control.patchValue(val);
  }

  refreshSelectMenu() {
    this._cdr.markForCheck();
  }

  public resetFormSelect() {
    this.ngSelect.clearModel();
    this._resetOverflow();
  }

  /** Compact select internal api */
  public trackAddedItem() {
    if (!this.ngSelect) return;
    // ngSelect selection model requires a minor delay to update
    setTimeout(() => {
      if (this._extraCounter < 1) {
        const childElements =
          this.ngSelect.element.querySelectorAll('.ng-value');
        if (childElements.length < 1) return;

        const lastEl = childElements[childElements.length - 1];
        const secondLastEl =
          childElements.length < 2
            ? undefined
            : childElements[childElements.length - 2];

        // if wrapping occurs then hide the last 2 items to
        // create space for the counter element
        if (this._hasOverflowed(lastEl, secondLastEl)) {
          (lastEl as HTMLElement).style.display = 'none';
          if (secondLastEl) {
            (secondLastEl as HTMLElement).style.display = 'none';
          }
          this._hiddenElements = [lastEl, secondLastEl];
          this._extraCounter = 2;
          this._createAndInsertCounterElement();
        }
      } else {
        this._addItemToOverflow();
      }
      this.ngSelect.blur();
    }, 100);
  }

  public trackRemovedItem(removed: NgOption) {
    setTimeout(() => {
      if (this._extraCounter < 1) return;
      let hiddenElement: Element | undefined;
      try {
        hiddenElement = this._findRemovedElement(removed);
      } catch (e) {
        this._snackBar.open({
          message: 'Failed to find and remove item: ' + e,
          type: 'error',
        });
      }

      if (hiddenElement) {
        // the removed item was part of the overflow items
        // item no longer needs to be tracked
        const indexToRemove = this._hiddenElements.indexOf(hiddenElement);
        this._hiddenElements.splice(indexToRemove, 1);
      } else {
        // the removed items was not part of the overflow items
        // replace removed item chip with one of the hidden ones from overflow
        (this._hiddenElements[0] as HTMLElement).style.display = 'block';
        this._hiddenElements.shift();
      }
      this._extraCounter--;

      // if less then 2 overflow items then remove overflow chip element
      if (this._extraCounter < 2) {
        this._resetOverflow();
      } else {
        this._counterElement.innerHTML = `<span>${this._extraCounter} more</span>`;
      }
      this.ngSelect.blur();
    }, 100);
  }

  private _addItemToOverflow() {
    const childElements = this.ngSelect.element.querySelectorAll('.ng-value');
    const lastEl = childElements[childElements.length - 1];
    (lastEl as HTMLElement).style.display = 'none';
    this._hiddenElements.push(lastEl);
    this._extraCounter++;
    this._counterElement.innerHTML = `<span>${this._extraCounter} more</span>`;
  }

  private _createAndInsertCounterElement() {
    const valueContainer = this.ngSelect.element.querySelector(
      '.ng-value-container',
    );
    const inputContainer = this.ngSelect.element.querySelector('.ng-input');
    this._counterElement = this._renderer.createElement('div');
    this._counterElement.innerHTML = `<span>${this._extraCounter} more</span>`;
    this._renderer.addClass(this._counterElement, 'extra-counter');
    this._renderer.insertBefore(
      valueContainer,
      this._counterElement,
      inputContainer,
    );
  }

  private _hasOverflowed(lastEl: Element, secondLastEl: Element): boolean {
    const lastElOffset = (lastEl as HTMLElement).offsetLeft;
    const secondLastElOffset = secondLastEl
      ? (secondLastEl as HTMLElement).offsetLeft
      : lastElOffset - 1;
    return lastElOffset <= secondLastElOffset;
  }

  private _resetOverflow() {
    if (this._counterElement) this._counterElement.remove();
    this._counterElement = undefined;
    this._hiddenElements.forEach(hiddenChild => {
      (hiddenChild as HTMLElement).style.display = 'block';
    });
    this._hiddenElements = [];
    this._extraCounter = 0;
  }

  private _findRemovedElement(removed: NgOption): Element | undefined {
    return this._hiddenElements.find(hiddenChild => {
      const searchedElements = hiddenChild.getElementsByClassName('label');
      if (searchedElements.length < 1) {
        throw new Error('inner element does not exist');
      }

      return searchedElements[0].getAttribute('title') === removed.label;
    });
  }
}
