// routing through this file to add all the necessary extensions
// we need with dayjs
import * as day from 'dayjs';
import * as advancedFormat from 'dayjs/plugin/advancedFormat';
import * as custParse from 'dayjs/plugin/customParseFormat';
import * as dayOfYear from 'dayjs/plugin/dayOfYear';
import * as duration from 'dayjs/plugin/duration';
import * as isBetween from 'dayjs/plugin/isBetween';
import * as isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import * as isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import * as localeData from 'dayjs/plugin/localeData';
import * as minMax from 'dayjs/plugin/minMax';
import * as objectSupport from 'dayjs/plugin/objectSupport';
import * as relativeTime from 'dayjs/plugin/relativeTime';
import * as timezone from 'dayjs/plugin/timezone';
import * as utc from 'dayjs/plugin/utc';
import * as weekOfYear from 'dayjs/plugin/weekOfYear';

day.extend(relativeTime);
day.extend(utc);
day.extend(duration);
day.extend(isBetween);
day.extend(localeData);
day.extend(isSameOrBefore);
day.extend(isSameOrAfter);
day.extend(dayOfYear);
day.extend(timezone);
day.extend(weekOfYear);
day.extend(minMax);
day.extend(advancedFormat);
day.extend(objectSupport);
day.extend(custParse);

export { day };
