import { ColumnInfo } from 'libs/shared';

import { ReportDatasourceService } from '@app/src/app/components/manager-report/services/report-datasource.service';
import { ReportsService } from '@app/src/app/components/manager-report/services/report-service.service';

export class RtModalShellDataService extends ReportDatasourceService<any> {
  /** To allow action columns we need to cache the non-action columns
   * so we don't get double definition errors w MatTable.
   */
  private _columnsToReturn: ColumnInfo[];

  constructor(rs: ReportsService<any>, columnInfo: ColumnInfo[], data: any[]) {
    super(null, rs);
    this.setDisplayColumns(columnInfo);
    this._columnsToReturn = columnInfo;
    this.fetch = async () => ({ items: data, pageToken: 0 });
  }

  public getDisplayColumns() {
    return this._columnsToReturn;
  }

  async fetch() {
    return { items: [], pageToken: 0 };
  }
}
