import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { RouterModule } from '@angular/router';

import { StoreModule } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';

import { MgEmptyStateModule } from '@app/src/app/components/EmptyState';
import { TopOfFeedModule } from '@app/src/app/components/TopOfFeed';
import { MgPagedScrollerModule } from '@app/src/app/components/mg-paged-scroller/mg-paged-scroller.module';
import { MgContentFeedItemModule } from '@app/src/app/elements/MgContentFeedItem';
import { PermissionsModule } from '@app/src/app/permissions';
import { SearchModule } from '@app/src/app/search';
import { SearchContentModule } from '@app/src/app/search-content';

import { LayoutLegacyContentModule } from '@modules/layout/components/layout-legacy-content/layout-legacy-content.module';

import { TextModule } from '@shared/components/text';

import { HomeActionButtonModule } from '../home-action-button/home-action-button.module';
import { HomeFeedComponent } from './home-feed.component';

@NgModule({
  imports: [
    // Minga dependencies
    MgContentFeedItemModule,
    SearchContentModule,
    SearchModule,
    TopOfFeedModule,
    MgEmptyStateModule,
    PermissionsModule,
    MgPagedScrollerModule,
    LayoutLegacyContentModule,
    HomeActionButtonModule,
    TextModule,

    // External dependencies
    CommonModule,
    TranslateModule,
    StoreModule,
    RouterModule,
    FlexLayoutModule,
  ],
  declarations: [HomeFeedComponent],
  exports: [HomeFeedComponent],
})
export class HomeFeedModule {}
