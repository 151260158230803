//TS2742

export const contentStoreKeyname = 'content';

export interface IContentStoreState {
  contentEmailSending: boolean;
}

export namespace IContentStoreState {
  export const initialState: IContentStoreState = {
    contentEmailSending: false,
  };
}
