import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { gateway } from 'libs/generated-grpc-web';

/**
 * This notification uses a person's display name and with a title prefix
 * results in a notification (on the front-end) like the following:
 * --------------------------------------------
 * [titlePrefix] <span>[displayName]</span>
 * [body text]
 * --------------------------------------------
 *
 * This notification type is styled somewhere between a
 * NotificationWithDisplayPerson and NotificationWithDisplayBasic.
 *
 * Our current notifications remove any HTML on the client-side, to remove any
 * formatting used for push notes or in the title/body text in the content
 * itself that is used in a notification. This notification retains any
 * HTML passed in the titlePrefix and body text used in the notification. This
 * is to allow custom styling flexibility, as the content is created by us not
 * a user. However, as it is tied to the expected format above, this is not
 * ideal... @HACK
 * Therefore:
 * @TODO remove and replace this with an HTML notification, to allow for
 * more flexibility in notification layouts & designs.
 */
@Component({
  selector: 'mg-person-title-notification',
  templateUrl: './MgPersonTitleNotification.element.html',
  styleUrls: ['./MgPersonTitleNotification.element.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MgPersonTitleNotificationElement {
  @Input()
  notification?: gateway.notification_pb.Notification.AsObject;

  get notificationDisplay(): gateway.notification_pb.Notification.DisplayPersonTitle.AsObject | null {
    if (this.notification) {
      return this.notification.displayPersonTitle || null;
    }

    return null;
  }

  get titlePrefix(): string {
    if (this.notificationDisplay) {
      return this.notificationDisplay.titlePrefix || '';
    }

    return '';
  }

  get displayName(): string {
    if (this.notificationDisplay) {
      return this.notificationDisplay.displayName || '';
    }

    return '';
  }
}
