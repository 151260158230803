import { Injectable } from '@angular/core';

import {
  ModalOverlayRef,
  ModalOverlayService,
  ModalOverlayServiceConfig,
} from '../modal-overlay';
import { StudentIdUploaderDialogComponent } from './student-id-uploader-dialog.component';
import {
  IStudentIdUploaderDialogData,
  IStudentIdUploaderDialogResponse,
} from './types';

export type StudentIdUploaderDialogRef =
  ModalOverlayRef<StudentIdUploaderDialogComponent>;
export type StudentIdUploaderDialogConfig =
  ModalOverlayServiceConfig<IStudentIdUploaderDialogData>;

@Injectable({ providedIn: 'root' })
export class StudentIdUploaderDialogService {
  constructor(private _modalService: ModalOverlayService) {}

  open(
    config: StudentIdUploaderDialogConfig,
  ): ModalOverlayRef<
    IStudentIdUploaderDialogResponse,
    IStudentIdUploaderDialogData
  > {
    return this._modalService.open(StudentIdUploaderDialogComponent, config);
  }
}
