import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
  TrackByFunction,
  ViewChild,
} from '@angular/core';
import { MediaObserver } from '@angular/flex-layout';
import { UntypedFormControl } from '@angular/forms';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

import { FlexTimeActivity } from 'libs/domain';
import {
  BehaviorSubject,
  Observable,
  ReplaySubject,
  combineLatest,
} from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  map,
  shareReplay,
  startWith,
  takeUntil,
} from 'rxjs/operators';

import { AuthInfoService } from '@app/src/app/minimal/services/AuthInfo';
import { SentryService } from '@app/src/app/minimal/services/Sentry/Sentry.service';

import { SystemAlertSnackBarService } from '@shared/components/system-alert-snackbar';
import { FlexTimePermissionsService } from '@shared/services/flex-time/flex-time-permissions';

import { FtmActivityTemplatesMessages } from './ftm-activity-templates.constants';
import { FtmActivityTemplatesService } from './services';

@Component({
  selector: 'mg-ftm-activity-templates',
  templateUrl: './ftm-activity-templates.component.html',
  styleUrls: ['./ftm-activity-templates.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FtmActivityTemplatesComponent implements OnInit, OnDestroy {
  // TODO flex-act this will have to change
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  public displayedColumns: string[] = [
    'icon',
    'name',
    'createdBy',
    'location',
    'seats',
    'edit',
  ];

  /** General Observables */
  private _destroyedSubject = new ReplaySubject<void>(1);
  public readonly media$: Observable<any>;

  private _dataSource = new MatTableDataSource();

  // this indicator is responsible for initial load only
  private _isLoading = new BehaviorSubject(true);
  public isLoading$ = this._isLoading.asObservable();

  private _teacherHashSubject = new BehaviorSubject<string>('');
  public readonly teacherHash$ = this._teacherHashSubject.asObservable();

  public isFlexAdmin = this._flexPermissionService.isFlexTimeAdmin();

  public readonly searchControl = new UntypedFormControl('');
  private readonly _debouncedSearchValue$ =
    this.searchControl.valueChanges.pipe(
      takeUntil(this._destroyedSubject),
      startWith(''),
      debounceTime(300),
      shareReplay(1),
      distinctUntilChanged(),
      map(value => value.toLowerCase()),
    );

  public readonly activitiesDataSource$ = combineLatest([
    this.teacherHash$,
    this._ftmActivityTemplatesService.activities$,
    this._debouncedSearchValue$,
  ]).pipe(
    map(([teacherHash, activities, activityName]) => {
      const dataSource = this._dataSource;
      dataSource.data = activities;

      if (teacherHash) {
        dataSource.data = dataSource.data.filter(
          (activity: FlexTimeActivity) => {
            return activity.createdByPerson.hash === teacherHash;
          },
        );
      }

      if (activityName) {
        dataSource.data = dataSource.data.filter(
          (activity: FlexTimeActivity) => {
            return activity.name.toLowerCase().includes(activityName);
          },
        );
      }

      return dataSource;
    }),
  );

  public MESSAGES = FtmActivityTemplatesMessages;

  constructor(
    public mediaObserver: MediaObserver,
    private _ftmActivityTemplatesService: FtmActivityTemplatesService,
    private _systemAlertSnackBar: SystemAlertSnackBarService,
    private _sentry: SentryService,
    private _authInfoService: AuthInfoService,
    private _flexPermissionService: FlexTimePermissionsService,
  ) {
    this.media$ = this.mediaObserver.asObservable().pipe(
      takeUntil(this._destroyedSubject),
      map(change => change[0].mqAlias),
      distinctUntilChanged(),
    );
  }

  ngOnInit(): void {
    this._initialFetch();
  }

  ngOnDestroy(): void {
    this._destroyedSubject.next();
    this._destroyedSubject.complete();
    this._teacherHashSubject.complete();
    this._isLoading.complete();
  }

  public trackByFn(index, row: any): TrackByFunction<number> {
    return row.id || index;
  }

  public async openEditModal(activity) {
    await this._ftmActivityTemplatesService.openEditModal(activity);
    await this._initialFetch(false);
  }

  public onTeacherSelectChange(teacher?: any) {
    this._teacherHashSubject.next(teacher);
  }

  private async _initialFetch(showLoader = true) {
    try {
      this._isLoading.next(showLoader);
      const personHash = !this.isFlexAdmin
        ? this._authInfoService.authPersonHash
        : null;
      await this._ftmActivityTemplatesService.fetchAll(personHash);
    } catch (e) {
      this._systemAlertSnackBar.open({
        type: 'error',
        message: FtmActivityTemplatesMessages.SNACKBAR_FETCHING_ERROR,
      });
      this._sentry.captureMessageAsError(
        FtmActivityTemplatesMessages.SNACKBAR_FETCHING_ERROR,
        e,
      );
    } finally {
      this._isLoading.next(false);
    }
  }
}
