import {
  AfterContentInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { MatColumnDef } from '@angular/material/table';

import { ColumnInfo, TemplateColumnKeys } from 'libs/shared';

import { ReportDatasourceService } from '@app/src/app/components/manager-report/services/report-datasource.service';

import { MingaColorsType } from '@shared/constants';

import { ReportTableColumnTemplateComponent } from './rt-column-template.component';

@Component({
  selector: 'mg-summary-column',
  styles: ["[class*='w-'] {flex: initial; min-width: 0 !important }"],
  template: `<ng-container
    matColumnDef="{{ datasource.TEMPLATE_KEYS.SUMMARY }}">
    <mat-header-cell
      *matHeaderCellDef
      [mat-sort-header]="datasource.TEMPLATE_KEYS.SUMMARY"
      [disabled]="true"
      [ngClass]="summaryColumn.columnClasses">
    </mat-header-cell>
    <mat-cell
      *matCellDef="let item"
      class="button-container"
      [ngClass]="summaryColumn.columnClasses">
      <mg-btn
        variant="icon"
        iconSet="minga"
        icon="mg-flex-report"
        tooltip="Summary"
        (pressed)="navigateToSummary(item)">
      </mg-btn>
    </mat-cell>
  </ng-container>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: ReportTableColumnTemplateComponent,
      useExisting: ReportTableSummaryTemplateComponent,
    },
  ],
})
export class ReportTableSummaryTemplateComponent implements AfterContentInit {
  @ViewChild(MatColumnDef, { static: true }) columnDef: MatColumnDef;

  summaryColumn: ColumnInfo;

  @Input() datasource: ReportDatasourceService<any>;
  @Input() iconName = 'graph';
  @Input() colorTheme: MingaColorsType;

  @Output() viewSummary = new EventEmitter<any>();

  constructor() {}

  ngAfterContentInit(): void {
    this.summaryColumn = this.datasource
      .getTemplateColumns()
      .find(col => col.key === TemplateColumnKeys.SUMMARY);
  }

  navigateToSummary(item: any): void {
    this.datasource.viewSummary(item);
    this.viewSummary.emit(item);
  }
}
