// GENERATED CODE -- DO NOT EDIT!

import {grpc} from '@improbable-eng/grpc-web';

import * as studio_content_studio_image_library_pb from '../studio_content/studio_image_library_pb';
import * as image_image_pb from '../image/image_pb';
import * as common_stream_pb from '../common/stream_pb';

export class StudioContentImageLibrary {
  static readonly serviceName = "StudioContentImageLibrary";

  static readonly StreamImages = {
    methodName: "StreamImages",
    service: StudioContentImageLibrary,
    requestStream: false,
    responseStream: true,
    requestType: common_stream_pb.StreamID,
    responseType: studio_content_studio_image_library_pb.StudioContentImageLibraryStreamImagesResponse,
  };

  static readonly StreamImagesControl = {
    methodName: "StreamImagesControl",
    service: StudioContentImageLibrary,
    requestStream: false,
    responseStream: false,
    requestType: common_stream_pb.StreamControl,
    responseType: common_stream_pb.StreamControlResponse,
  };

}

export class StudioContentImageLibraryClient {
  streamImages() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  streamImagesControl() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
}
