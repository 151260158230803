import { Injectable } from '@angular/core';

import { ICalCalendar, ICalEvent } from 'ical-gen';
import { gateway } from 'libs/generated-grpc-web';

import { downloadjs } from '@app/src/app/util/downloadjs';

import { SystemAlertSnackBarService } from '@shared/components/system-alert-snackbar';

/**
 * Calendar service for Calendar-PhoneGap-Plugin
 * https://github.com/LeumitDev/Calendar-PhoneGap-Plugin
 */
@Injectable({ providedIn: 'root' })
export class ICalExportService {
  private _calendarPlugin: any;
  private readonly _isMobileDevice: boolean =
    window.MINGA_APP_ANDROID || window.MINGA_APP_IOS;

  constructor(private _systemAlertSnackBar: SystemAlertSnackBarService) {
    if (this._isMobileDevice) {
      const plugins = (<any>window).plugins;
      this._calendarPlugin = plugins.calendar;
    }
  }

  private _removeHTML(value: string) {
    const div = document.createElement('div');
    div.innerHTML = value;
    return div.textContent || '';
  }

  async addLongEventContentToCalendar(
    event: gateway.content_views_pb.LongEventCardView.AsObject,
    hash: string,
  ) {
    if (!this._isMobileDevice) {
      await this._addToCalendarWeb(event, hash);
    } else {
      await this._addToCalendarMobile(event);
    }
  }

  private async _addToCalendarMobile(
    event: gateway.content_views_pb.LongEventCardView.AsObject,
  ) {
    if (event?.startTimestamp) {
      const startDate = new Date(event.startTimestamp);
      const endDate = new Date(event.endTimestamp);
      const title = event.title;
      const location = event.location;
      const notes = this._removeHTML(event.fullBody);

      try {
        const calOptions = this._calendarPlugin.getCalendarOptions();
        // add ticket url for event url if applicable
        if (event.ticketUrl) {
          calOptions.url = event.ticketUrl;
        }
        const existingEvent = await new Promise((resolve, reject) => {
          this._calendarPlugin.findEventWithOptions(
            title,
            location,
            notes,
            startDate,
            endDate,
            calOptions,
            (message: any[]) => {
              const hasEvent = !!message && message.length;
              resolve(hasEvent);
            },
            (message: any) => {
              console.error(
                `[MobileCalendarService] addLongEventContentToCalendar() find event failed: `,
                message,
              );
              reject(false);
            },
          );
        });

        if (existingEvent) {
          this._systemAlertSnackBar.warning(
            `Event "${title}" already added to your calendar!`,
          );
          return;
        }

        this._calendarPlugin.createEventInteractivelyWithOptions(
          title,
          location,
          notes,
          startDate,
          endDate,
          calOptions,
          (message: any) => {
            // No message needed as the user will have chosen in their native
            // calendar app how to save the event
          },
          (message: any) => {
            console.error(
              `[MobileCalendarService] addLongEventContentToCalendar() failed: `,
              message,
            );
            this._systemAlertSnackBar.error(
              `An error occured, please try again.`,
            );
          },
        );
      } catch (err) {
        console.error(
          `[MobileCalendarService] addLongEventContentToCalendar() failed: `,
          err,
        );
        this._systemAlertSnackBar.error(`An error occured, please try again.`);
      }
    } else {
      this._systemAlertSnackBar.error(
        `Cannot add event to Calendar, missing start date & time.`,
      );
    }
  }

  private async _addToCalendarWeb(
    event: gateway.content_views_pb.LongEventCardView.AsObject,
    hash: string,
  ) {
    if (event?.startTimestamp) {
      const calendar = new ICalCalendar();
      const startDate = new Date(event.startTimestamp);
      const endDate = new Date(event.endTimestamp);
      const title = event.title;
      const notes = this._removeHTML(event.fullBody);

      try {
        const icalEvent = new ICalEvent({
          start: {
            date: startDate,
            zone: 'UTC',
          },
          end: {
            date: endDate,
            zone: 'UTC',
          },
          summary: title,
          description: notes,
          url: event.ticketUrl,
          stamp: startDate,
          sequence: 1,
          uid: hash,
        });

        calendar.addChild(icalEvent);
        const ics = await calendar.renderToString();
        downloadjs(ics, 'minga_event-' + hash + '.ics');
      } catch (e) {
        this._systemAlertSnackBar.error(`An error occured, please try again.`);
      }
    }
  }
}
