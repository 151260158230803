import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { MgButtonModule } from '@app/src/app/button';
import { MgImageModule } from '@app/src/app/components/MgImage';
import { MgContentLinkModule } from '@app/src/app/content-link';
import { MgAvatarModule } from '@app/src/app/elements/MgAvatar';
import { MgEllipsisModule } from '@app/src/app/elements/MgEllipsis';
import { MgLikeModule } from '@app/src/app/elements/MgLike';
import { MgPersonMinimalModule } from '@app/src/app/elements/MgPersonMinimal';
import { MgShortCardModule } from '@app/src/app/elements/MgShortCard';
import { MgeIconModule } from '@app/src/app/elements/MgeIcon';
import { MgPipesModule } from '@app/src/app/pipes';
import { UgcLinksHighlightPipeModule } from '@app/src/app/ugc-links/pipes/UgcLinksHighlight';

import { MgChallengeResponseShortCardElement } from './MgChallengeResponseShortCard.element';

@NgModule({
  imports: [
    // Minga dependencies
    MgShortCardModule,
    UgcLinksHighlightPipeModule,
    MgImageModule,
    MgPipesModule,
    MgeIconModule,
    MgButtonModule,
    MgPersonMinimalModule,
    MgAvatarModule,
    MgLikeModule,
    MgEllipsisModule,
    MgContentLinkModule,

    // External dependencies
    CommonModule,
    RouterModule,
  ],
  declarations: [MgChallengeResponseShortCardElement],
  exports: [MgChallengeResponseShortCardElement],
})
export class MgChallengeResponseShortCardModule {}
