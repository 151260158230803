// source: common/stream.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

import * as jspb from 'google-protobuf';
import * as common_legacy_pb from '../common/legacy_pb';
export namespace StreamID {
  export interface AsObject {
    id: number;
  }
}
export class StreamID extends jspb.Message {
  static readonly displayName = "StreamID";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): StreamID.AsObject {
    return StreamID.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!StreamID} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: StreamID) {
    var f: any;
    var obj: any = {
      id: jspb.Message.getFieldWithDefault(msg, 1, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!StreamID}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new StreamID;
    return StreamID.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!StreamID} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!StreamID}
   */
  static deserializeBinaryFromReader(msg: StreamID, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {number} */ (reader.readInt32());
        msg.setId(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!StreamID} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: StreamID, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getId();
    if (f !== 0) {
      writer.writeInt32(
        1,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    StreamID.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional int32 id = 1;
   * @return {number}
   */
  getId(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
  };


  /** @param {number} value */
  setId(value: number) {
    jspb.Message.setProto3IntField(this, 1, value);
  }


}
export namespace StreamItemMetadata {
  export interface AsObject {
    index: number;
    position?: StreamItemPosition;
    id: string;
    placeholder: boolean;
  }
}
export class StreamItemMetadata extends jspb.Message {
  static readonly displayName = "StreamItemMetadata";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): StreamItemMetadata.AsObject {
    return StreamItemMetadata.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!StreamItemMetadata} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: StreamItemMetadata) {
    var f: any;
    var obj: any = {
      index: jspb.Message.getFieldWithDefault(msg, 1, 0),
      position: jspb.Message.getFieldWithDefault(msg, 2, 0),
      id: jspb.Message.getFieldWithDefault(msg, 3, ""),
      placeholder: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!StreamItemMetadata}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new StreamItemMetadata;
    return StreamItemMetadata.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!StreamItemMetadata} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!StreamItemMetadata}
   */
  static deserializeBinaryFromReader(msg: StreamItemMetadata, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {number} */ (reader.readInt32());
        msg.setIndex(value1);
        break;
      case 2:
        var value2 = /** @type {!StreamItemPosition} */ (reader.readEnum());
        msg.setPosition(value2);
        break;
      case 3:
        var value3 = /** @type {string} */ (reader.readString());
        msg.setId(value3);
        break;
      case 4:
        var value4 = /** @type {boolean} */ (reader.readBool());
        msg.setPlaceholder(value4);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!StreamItemMetadata} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: StreamItemMetadata, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getIndex();
    if (f !== 0) {
      writer.writeInt32(
        1,
        f
      );
    }
    f = message.getPosition();
    if (f !== 0.0) {
      writer.writeEnum(
        2,
        f
      );
    }
    f = message.getId();
    if (f.length > 0) {
      writer.writeString(
        3,
        f
      );
    }
    f = message.getPlaceholder();
    if (f) {
      writer.writeBool(
        4,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    StreamItemMetadata.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional int32 index = 1;
   * @return {number}
   */
  getIndex(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
  };


  /** @param {number} value */
  setIndex(value: number) {
    jspb.Message.setProto3IntField(this, 1, value);
  }


  /**
   * optional StreamItemPosition position = 2;
   * @return {!StreamItemPosition}
   */
  getPosition(): StreamItemPosition {
    return /** @type {!StreamItemPosition} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
  };


  /** @param {!StreamItemPosition} value */
  setPosition(value: StreamItemPosition) {
    jspb.Message.setProto3EnumField(this, 2, value);
  }


  /**
   * optional string id = 3;
   * @return {string}
   */
  getId(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
  };


  /** @param {string} value */
  setId(value: string) {
    jspb.Message.setProto3StringField(this, 3, value);
  }


  /**
   * optional bool placeholder = 4;
   * @return {boolean}
   */
  getPlaceholder(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
  };


  /** @param {boolean} value */
  setPlaceholder(value: boolean) {
    jspb.Message.setProto3BooleanField(this, 4, value);
  }


}
export namespace StreamFilterOp {
  export interface AsObject {
    ltNow?: StreamFilterOp.LtNow.AsObject;
    lteNow?: StreamFilterOp.LteNow.AsObject;
    gtNow?: StreamFilterOp.GtNow.AsObject;
    gteNow?: StreamFilterOp.GteNow.AsObject;
    falsyOrLteNow?: StreamFilterOp.FalsyOrLteNow.AsObject;
    falsyOrLtNow?: StreamFilterOp.FalsyOrLtNow.AsObject;
    falsyOrGteNow?: StreamFilterOp.FalsyOrGteNow.AsObject;
    falsyOrGtNow?: StreamFilterOp.FalsyOrGtNow.AsObject;
    includes?: StreamFilterOp.Includes.AsObject;
    gteValue?: StreamFilterOp.GteValue.AsObject;
    gtValue?: StreamFilterOp.GtValue.AsObject;
    lteValue?: StreamFilterOp.LteValue.AsObject;
    ltValue?: StreamFilterOp.LtValue.AsObject;
    falsyOrGteValue?: StreamFilterOp.FalsyOrGteValue.AsObject;
    falsyOrGtValue?: StreamFilterOp.FalsyOrGtValue.AsObject;
    falsyOrLteValue?: StreamFilterOp.FalsyOrLteValue.AsObject;
    falsyOrLtValue?: StreamFilterOp.FalsyOrLtValue.AsObject;
    withinInclusiveValue?: StreamFilterOp.WithinInclusiveValue.AsObject;
  }
}
export class StreamFilterOp extends jspb.Message {
  static readonly displayName = "StreamFilterOp";
  /**
   * Oneof group definitions for this message. Each group defines the field
   * numbers belonging to that group. When of these fields' value is set, all
   * other fields in the group are cleared. During deserialization, if multiple
   * fields are encountered for a group, only the last value seen will be kept.
   * @private {!Array<!Array<number>>}
   * @const
   */
  static readonly oneofGroups_ = [[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18]];


  /**
   * @return {StreamFilterOp.OpCase}
   */
  getOpCase() {
    return /** @type {StreamFilterOp.OpCase} */(jspb.Message.computeOneofCase(this, StreamFilterOp.oneofGroups_[0]));
  }

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, StreamFilterOp.oneofGroups_);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): StreamFilterOp.AsObject {
    return StreamFilterOp.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!StreamFilterOp} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: StreamFilterOp) {
    var f: any;
    var obj: any = {
      ltNow: (f = msg.getLtNow()) && StreamFilterOp.LtNow.toObject(includeInstance, f),
      lteNow: (f = msg.getLteNow()) && StreamFilterOp.LteNow.toObject(includeInstance, f),
      gtNow: (f = msg.getGtNow()) && StreamFilterOp.GtNow.toObject(includeInstance, f),
      gteNow: (f = msg.getGteNow()) && StreamFilterOp.GteNow.toObject(includeInstance, f),
      falsyOrLteNow: (f = msg.getFalsyOrLteNow()) && StreamFilterOp.FalsyOrLteNow.toObject(includeInstance, f),
      falsyOrLtNow: (f = msg.getFalsyOrLtNow()) && StreamFilterOp.FalsyOrLtNow.toObject(includeInstance, f),
      falsyOrGteNow: (f = msg.getFalsyOrGteNow()) && StreamFilterOp.FalsyOrGteNow.toObject(includeInstance, f),
      falsyOrGtNow: (f = msg.getFalsyOrGtNow()) && StreamFilterOp.FalsyOrGtNow.toObject(includeInstance, f),
      includes: (f = msg.getIncludes()) && StreamFilterOp.Includes.toObject(includeInstance, f),
      gteValue: (f = msg.getGteValue()) && StreamFilterOp.GteValue.toObject(includeInstance, f),
      gtValue: (f = msg.getGtValue()) && StreamFilterOp.GtValue.toObject(includeInstance, f),
      lteValue: (f = msg.getLteValue()) && StreamFilterOp.LteValue.toObject(includeInstance, f),
      ltValue: (f = msg.getLtValue()) && StreamFilterOp.LtValue.toObject(includeInstance, f),
      falsyOrGteValue: (f = msg.getFalsyOrGteValue()) && StreamFilterOp.FalsyOrGteValue.toObject(includeInstance, f),
      falsyOrGtValue: (f = msg.getFalsyOrGtValue()) && StreamFilterOp.FalsyOrGtValue.toObject(includeInstance, f),
      falsyOrLteValue: (f = msg.getFalsyOrLteValue()) && StreamFilterOp.FalsyOrLteValue.toObject(includeInstance, f),
      falsyOrLtValue: (f = msg.getFalsyOrLtValue()) && StreamFilterOp.FalsyOrLtValue.toObject(includeInstance, f),
      withinInclusiveValue: (f = msg.getWithinInclusiveValue()) && StreamFilterOp.WithinInclusiveValue.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!StreamFilterOp}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new StreamFilterOp;
    return StreamFilterOp.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!StreamFilterOp} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!StreamFilterOp}
   */
  static deserializeBinaryFromReader(msg: StreamFilterOp, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new StreamFilterOp.LtNow;
        reader.readMessage(value1,StreamFilterOp.LtNow.deserializeBinaryFromReader);
        msg.setLtNow(value1);
        break;
      case 2:
        var value2 = new StreamFilterOp.LteNow;
        reader.readMessage(value2,StreamFilterOp.LteNow.deserializeBinaryFromReader);
        msg.setLteNow(value2);
        break;
      case 3:
        var value3 = new StreamFilterOp.GtNow;
        reader.readMessage(value3,StreamFilterOp.GtNow.deserializeBinaryFromReader);
        msg.setGtNow(value3);
        break;
      case 4:
        var value4 = new StreamFilterOp.GteNow;
        reader.readMessage(value4,StreamFilterOp.GteNow.deserializeBinaryFromReader);
        msg.setGteNow(value4);
        break;
      case 5:
        var value5 = new StreamFilterOp.FalsyOrLteNow;
        reader.readMessage(value5,StreamFilterOp.FalsyOrLteNow.deserializeBinaryFromReader);
        msg.setFalsyOrLteNow(value5);
        break;
      case 6:
        var value6 = new StreamFilterOp.FalsyOrLtNow;
        reader.readMessage(value6,StreamFilterOp.FalsyOrLtNow.deserializeBinaryFromReader);
        msg.setFalsyOrLtNow(value6);
        break;
      case 7:
        var value7 = new StreamFilterOp.FalsyOrGteNow;
        reader.readMessage(value7,StreamFilterOp.FalsyOrGteNow.deserializeBinaryFromReader);
        msg.setFalsyOrGteNow(value7);
        break;
      case 8:
        var value8 = new StreamFilterOp.FalsyOrGtNow;
        reader.readMessage(value8,StreamFilterOp.FalsyOrGtNow.deserializeBinaryFromReader);
        msg.setFalsyOrGtNow(value8);
        break;
      case 9:
        var value9 = new StreamFilterOp.Includes;
        reader.readMessage(value9,StreamFilterOp.Includes.deserializeBinaryFromReader);
        msg.setIncludes(value9);
        break;
      case 10:
        var value10 = new StreamFilterOp.GteValue;
        reader.readMessage(value10,StreamFilterOp.GteValue.deserializeBinaryFromReader);
        msg.setGteValue(value10);
        break;
      case 11:
        var value11 = new StreamFilterOp.GtValue;
        reader.readMessage(value11,StreamFilterOp.GtValue.deserializeBinaryFromReader);
        msg.setGtValue(value11);
        break;
      case 12:
        var value12 = new StreamFilterOp.LteValue;
        reader.readMessage(value12,StreamFilterOp.LteValue.deserializeBinaryFromReader);
        msg.setLteValue(value12);
        break;
      case 13:
        var value13 = new StreamFilterOp.LtValue;
        reader.readMessage(value13,StreamFilterOp.LtValue.deserializeBinaryFromReader);
        msg.setLtValue(value13);
        break;
      case 14:
        var value14 = new StreamFilterOp.FalsyOrGteValue;
        reader.readMessage(value14,StreamFilterOp.FalsyOrGteValue.deserializeBinaryFromReader);
        msg.setFalsyOrGteValue(value14);
        break;
      case 15:
        var value15 = new StreamFilterOp.FalsyOrGtValue;
        reader.readMessage(value15,StreamFilterOp.FalsyOrGtValue.deserializeBinaryFromReader);
        msg.setFalsyOrGtValue(value15);
        break;
      case 16:
        var value16 = new StreamFilterOp.FalsyOrLteValue;
        reader.readMessage(value16,StreamFilterOp.FalsyOrLteValue.deserializeBinaryFromReader);
        msg.setFalsyOrLteValue(value16);
        break;
      case 17:
        var value17 = new StreamFilterOp.FalsyOrLtValue;
        reader.readMessage(value17,StreamFilterOp.FalsyOrLtValue.deserializeBinaryFromReader);
        msg.setFalsyOrLtValue(value17);
        break;
      case 18:
        var value18 = new StreamFilterOp.WithinInclusiveValue;
        reader.readMessage(value18,StreamFilterOp.WithinInclusiveValue.deserializeBinaryFromReader);
        msg.setWithinInclusiveValue(value18);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!StreamFilterOp} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: StreamFilterOp, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getLtNow();
    if (f != null) {
      writer.writeMessage(
        1,
        f,
        StreamFilterOp.LtNow.serializeBinaryToWriter
      );
    }
    f = message.getLteNow();
    if (f != null) {
      writer.writeMessage(
        2,
        f,
        StreamFilterOp.LteNow.serializeBinaryToWriter
      );
    }
    f = message.getGtNow();
    if (f != null) {
      writer.writeMessage(
        3,
        f,
        StreamFilterOp.GtNow.serializeBinaryToWriter
      );
    }
    f = message.getGteNow();
    if (f != null) {
      writer.writeMessage(
        4,
        f,
        StreamFilterOp.GteNow.serializeBinaryToWriter
      );
    }
    f = message.getFalsyOrLteNow();
    if (f != null) {
      writer.writeMessage(
        5,
        f,
        StreamFilterOp.FalsyOrLteNow.serializeBinaryToWriter
      );
    }
    f = message.getFalsyOrLtNow();
    if (f != null) {
      writer.writeMessage(
        6,
        f,
        StreamFilterOp.FalsyOrLtNow.serializeBinaryToWriter
      );
    }
    f = message.getFalsyOrGteNow();
    if (f != null) {
      writer.writeMessage(
        7,
        f,
        StreamFilterOp.FalsyOrGteNow.serializeBinaryToWriter
      );
    }
    f = message.getFalsyOrGtNow();
    if (f != null) {
      writer.writeMessage(
        8,
        f,
        StreamFilterOp.FalsyOrGtNow.serializeBinaryToWriter
      );
    }
    f = message.getIncludes();
    if (f != null) {
      writer.writeMessage(
        9,
        f,
        StreamFilterOp.Includes.serializeBinaryToWriter
      );
    }
    f = message.getGteValue();
    if (f != null) {
      writer.writeMessage(
        10,
        f,
        StreamFilterOp.GteValue.serializeBinaryToWriter
      );
    }
    f = message.getGtValue();
    if (f != null) {
      writer.writeMessage(
        11,
        f,
        StreamFilterOp.GtValue.serializeBinaryToWriter
      );
    }
    f = message.getLteValue();
    if (f != null) {
      writer.writeMessage(
        12,
        f,
        StreamFilterOp.LteValue.serializeBinaryToWriter
      );
    }
    f = message.getLtValue();
    if (f != null) {
      writer.writeMessage(
        13,
        f,
        StreamFilterOp.LtValue.serializeBinaryToWriter
      );
    }
    f = message.getFalsyOrGteValue();
    if (f != null) {
      writer.writeMessage(
        14,
        f,
        StreamFilterOp.FalsyOrGteValue.serializeBinaryToWriter
      );
    }
    f = message.getFalsyOrGtValue();
    if (f != null) {
      writer.writeMessage(
        15,
        f,
        StreamFilterOp.FalsyOrGtValue.serializeBinaryToWriter
      );
    }
    f = message.getFalsyOrLteValue();
    if (f != null) {
      writer.writeMessage(
        16,
        f,
        StreamFilterOp.FalsyOrLteValue.serializeBinaryToWriter
      );
    }
    f = message.getFalsyOrLtValue();
    if (f != null) {
      writer.writeMessage(
        17,
        f,
        StreamFilterOp.FalsyOrLtValue.serializeBinaryToWriter
      );
    }
    f = message.getWithinInclusiveValue();
    if (f != null) {
      writer.writeMessage(
        18,
        f,
        StreamFilterOp.WithinInclusiveValue.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    StreamFilterOp.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional LtNow lt_now = 1;
   * @return {?StreamFilterOp.LtNow}
   */
  getLtNow(): StreamFilterOp.LtNow {
    return /** @type{?StreamFilterOp.LtNow} */ (
      jspb.Message.getWrapperField(this, StreamFilterOp.LtNow, 1));
  }


  /** @param {?StreamFilterOp.LtNow|undefined} value */
  setLtNow(value?: StreamFilterOp.LtNow) {
    jspb.Message.setOneofWrapperField(this, 1, StreamFilterOp.oneofGroups_[0], value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearLtNow() {
    this.setLtNow(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasLtNow(): boolean {
    return jspb.Message.getField(this, 1) != null;
  }


  /**
   * optional LteNow lte_now = 2;
   * @return {?StreamFilterOp.LteNow}
   */
  getLteNow(): StreamFilterOp.LteNow {
    return /** @type{?StreamFilterOp.LteNow} */ (
      jspb.Message.getWrapperField(this, StreamFilterOp.LteNow, 2));
  }


  /** @param {?StreamFilterOp.LteNow|undefined} value */
  setLteNow(value?: StreamFilterOp.LteNow) {
    jspb.Message.setOneofWrapperField(this, 2, StreamFilterOp.oneofGroups_[0], value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearLteNow() {
    this.setLteNow(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasLteNow(): boolean {
    return jspb.Message.getField(this, 2) != null;
  }


  /**
   * optional GtNow gt_now = 3;
   * @return {?StreamFilterOp.GtNow}
   */
  getGtNow(): StreamFilterOp.GtNow {
    return /** @type{?StreamFilterOp.GtNow} */ (
      jspb.Message.getWrapperField(this, StreamFilterOp.GtNow, 3));
  }


  /** @param {?StreamFilterOp.GtNow|undefined} value */
  setGtNow(value?: StreamFilterOp.GtNow) {
    jspb.Message.setOneofWrapperField(this, 3, StreamFilterOp.oneofGroups_[0], value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearGtNow() {
    this.setGtNow(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasGtNow(): boolean {
    return jspb.Message.getField(this, 3) != null;
  }


  /**
   * optional GteNow gte_now = 4;
   * @return {?StreamFilterOp.GteNow}
   */
  getGteNow(): StreamFilterOp.GteNow {
    return /** @type{?StreamFilterOp.GteNow} */ (
      jspb.Message.getWrapperField(this, StreamFilterOp.GteNow, 4));
  }


  /** @param {?StreamFilterOp.GteNow|undefined} value */
  setGteNow(value?: StreamFilterOp.GteNow) {
    jspb.Message.setOneofWrapperField(this, 4, StreamFilterOp.oneofGroups_[0], value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearGteNow() {
    this.setGteNow(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasGteNow(): boolean {
    return jspb.Message.getField(this, 4) != null;
  }


  /**
   * optional FalsyOrLteNow falsy_or_lte_now = 5;
   * @return {?StreamFilterOp.FalsyOrLteNow}
   */
  getFalsyOrLteNow(): StreamFilterOp.FalsyOrLteNow {
    return /** @type{?StreamFilterOp.FalsyOrLteNow} */ (
      jspb.Message.getWrapperField(this, StreamFilterOp.FalsyOrLteNow, 5));
  }


  /** @param {?StreamFilterOp.FalsyOrLteNow|undefined} value */
  setFalsyOrLteNow(value?: StreamFilterOp.FalsyOrLteNow) {
    jspb.Message.setOneofWrapperField(this, 5, StreamFilterOp.oneofGroups_[0], value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearFalsyOrLteNow() {
    this.setFalsyOrLteNow(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasFalsyOrLteNow(): boolean {
    return jspb.Message.getField(this, 5) != null;
  }


  /**
   * optional FalsyOrLtNow falsy_or_lt_now = 6;
   * @return {?StreamFilterOp.FalsyOrLtNow}
   */
  getFalsyOrLtNow(): StreamFilterOp.FalsyOrLtNow {
    return /** @type{?StreamFilterOp.FalsyOrLtNow} */ (
      jspb.Message.getWrapperField(this, StreamFilterOp.FalsyOrLtNow, 6));
  }


  /** @param {?StreamFilterOp.FalsyOrLtNow|undefined} value */
  setFalsyOrLtNow(value?: StreamFilterOp.FalsyOrLtNow) {
    jspb.Message.setOneofWrapperField(this, 6, StreamFilterOp.oneofGroups_[0], value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearFalsyOrLtNow() {
    this.setFalsyOrLtNow(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasFalsyOrLtNow(): boolean {
    return jspb.Message.getField(this, 6) != null;
  }


  /**
   * optional FalsyOrGteNow falsy_or_gte_now = 7;
   * @return {?StreamFilterOp.FalsyOrGteNow}
   */
  getFalsyOrGteNow(): StreamFilterOp.FalsyOrGteNow {
    return /** @type{?StreamFilterOp.FalsyOrGteNow} */ (
      jspb.Message.getWrapperField(this, StreamFilterOp.FalsyOrGteNow, 7));
  }


  /** @param {?StreamFilterOp.FalsyOrGteNow|undefined} value */
  setFalsyOrGteNow(value?: StreamFilterOp.FalsyOrGteNow) {
    jspb.Message.setOneofWrapperField(this, 7, StreamFilterOp.oneofGroups_[0], value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearFalsyOrGteNow() {
    this.setFalsyOrGteNow(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasFalsyOrGteNow(): boolean {
    return jspb.Message.getField(this, 7) != null;
  }


  /**
   * optional FalsyOrGtNow falsy_or_gt_now = 8;
   * @return {?StreamFilterOp.FalsyOrGtNow}
   */
  getFalsyOrGtNow(): StreamFilterOp.FalsyOrGtNow {
    return /** @type{?StreamFilterOp.FalsyOrGtNow} */ (
      jspb.Message.getWrapperField(this, StreamFilterOp.FalsyOrGtNow, 8));
  }


  /** @param {?StreamFilterOp.FalsyOrGtNow|undefined} value */
  setFalsyOrGtNow(value?: StreamFilterOp.FalsyOrGtNow) {
    jspb.Message.setOneofWrapperField(this, 8, StreamFilterOp.oneofGroups_[0], value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearFalsyOrGtNow() {
    this.setFalsyOrGtNow(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasFalsyOrGtNow(): boolean {
    return jspb.Message.getField(this, 8) != null;
  }


  /**
   * optional Includes includes = 9;
   * @return {?StreamFilterOp.Includes}
   */
  getIncludes(): StreamFilterOp.Includes {
    return /** @type{?StreamFilterOp.Includes} */ (
      jspb.Message.getWrapperField(this, StreamFilterOp.Includes, 9));
  }


  /** @param {?StreamFilterOp.Includes|undefined} value */
  setIncludes(value?: StreamFilterOp.Includes) {
    jspb.Message.setOneofWrapperField(this, 9, StreamFilterOp.oneofGroups_[0], value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearIncludes() {
    this.setIncludes(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasIncludes(): boolean {
    return jspb.Message.getField(this, 9) != null;
  }


  /**
   * optional GteValue gte_value = 10;
   * @return {?StreamFilterOp.GteValue}
   */
  getGteValue(): StreamFilterOp.GteValue {
    return /** @type{?StreamFilterOp.GteValue} */ (
      jspb.Message.getWrapperField(this, StreamFilterOp.GteValue, 10));
  }


  /** @param {?StreamFilterOp.GteValue|undefined} value */
  setGteValue(value?: StreamFilterOp.GteValue) {
    jspb.Message.setOneofWrapperField(this, 10, StreamFilterOp.oneofGroups_[0], value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearGteValue() {
    this.setGteValue(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasGteValue(): boolean {
    return jspb.Message.getField(this, 10) != null;
  }


  /**
   * optional GtValue gt_value = 11;
   * @return {?StreamFilterOp.GtValue}
   */
  getGtValue(): StreamFilterOp.GtValue {
    return /** @type{?StreamFilterOp.GtValue} */ (
      jspb.Message.getWrapperField(this, StreamFilterOp.GtValue, 11));
  }


  /** @param {?StreamFilterOp.GtValue|undefined} value */
  setGtValue(value?: StreamFilterOp.GtValue) {
    jspb.Message.setOneofWrapperField(this, 11, StreamFilterOp.oneofGroups_[0], value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearGtValue() {
    this.setGtValue(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasGtValue(): boolean {
    return jspb.Message.getField(this, 11) != null;
  }


  /**
   * optional LteValue lte_value = 12;
   * @return {?StreamFilterOp.LteValue}
   */
  getLteValue(): StreamFilterOp.LteValue {
    return /** @type{?StreamFilterOp.LteValue} */ (
      jspb.Message.getWrapperField(this, StreamFilterOp.LteValue, 12));
  }


  /** @param {?StreamFilterOp.LteValue|undefined} value */
  setLteValue(value?: StreamFilterOp.LteValue) {
    jspb.Message.setOneofWrapperField(this, 12, StreamFilterOp.oneofGroups_[0], value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearLteValue() {
    this.setLteValue(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasLteValue(): boolean {
    return jspb.Message.getField(this, 12) != null;
  }


  /**
   * optional LtValue lt_value = 13;
   * @return {?StreamFilterOp.LtValue}
   */
  getLtValue(): StreamFilterOp.LtValue {
    return /** @type{?StreamFilterOp.LtValue} */ (
      jspb.Message.getWrapperField(this, StreamFilterOp.LtValue, 13));
  }


  /** @param {?StreamFilterOp.LtValue|undefined} value */
  setLtValue(value?: StreamFilterOp.LtValue) {
    jspb.Message.setOneofWrapperField(this, 13, StreamFilterOp.oneofGroups_[0], value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearLtValue() {
    this.setLtValue(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasLtValue(): boolean {
    return jspb.Message.getField(this, 13) != null;
  }


  /**
   * optional FalsyOrGteValue falsy_or_gte_value = 14;
   * @return {?StreamFilterOp.FalsyOrGteValue}
   */
  getFalsyOrGteValue(): StreamFilterOp.FalsyOrGteValue {
    return /** @type{?StreamFilterOp.FalsyOrGteValue} */ (
      jspb.Message.getWrapperField(this, StreamFilterOp.FalsyOrGteValue, 14));
  }


  /** @param {?StreamFilterOp.FalsyOrGteValue|undefined} value */
  setFalsyOrGteValue(value?: StreamFilterOp.FalsyOrGteValue) {
    jspb.Message.setOneofWrapperField(this, 14, StreamFilterOp.oneofGroups_[0], value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearFalsyOrGteValue() {
    this.setFalsyOrGteValue(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasFalsyOrGteValue(): boolean {
    return jspb.Message.getField(this, 14) != null;
  }


  /**
   * optional FalsyOrGtValue falsy_or_gt_value = 15;
   * @return {?StreamFilterOp.FalsyOrGtValue}
   */
  getFalsyOrGtValue(): StreamFilterOp.FalsyOrGtValue {
    return /** @type{?StreamFilterOp.FalsyOrGtValue} */ (
      jspb.Message.getWrapperField(this, StreamFilterOp.FalsyOrGtValue, 15));
  }


  /** @param {?StreamFilterOp.FalsyOrGtValue|undefined} value */
  setFalsyOrGtValue(value?: StreamFilterOp.FalsyOrGtValue) {
    jspb.Message.setOneofWrapperField(this, 15, StreamFilterOp.oneofGroups_[0], value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearFalsyOrGtValue() {
    this.setFalsyOrGtValue(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasFalsyOrGtValue(): boolean {
    return jspb.Message.getField(this, 15) != null;
  }


  /**
   * optional FalsyOrLteValue falsy_or_lte_value = 16;
   * @return {?StreamFilterOp.FalsyOrLteValue}
   */
  getFalsyOrLteValue(): StreamFilterOp.FalsyOrLteValue {
    return /** @type{?StreamFilterOp.FalsyOrLteValue} */ (
      jspb.Message.getWrapperField(this, StreamFilterOp.FalsyOrLteValue, 16));
  }


  /** @param {?StreamFilterOp.FalsyOrLteValue|undefined} value */
  setFalsyOrLteValue(value?: StreamFilterOp.FalsyOrLteValue) {
    jspb.Message.setOneofWrapperField(this, 16, StreamFilterOp.oneofGroups_[0], value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearFalsyOrLteValue() {
    this.setFalsyOrLteValue(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasFalsyOrLteValue(): boolean {
    return jspb.Message.getField(this, 16) != null;
  }


  /**
   * optional FalsyOrLtValue falsy_or_lt_value = 17;
   * @return {?StreamFilterOp.FalsyOrLtValue}
   */
  getFalsyOrLtValue(): StreamFilterOp.FalsyOrLtValue {
    return /** @type{?StreamFilterOp.FalsyOrLtValue} */ (
      jspb.Message.getWrapperField(this, StreamFilterOp.FalsyOrLtValue, 17));
  }


  /** @param {?StreamFilterOp.FalsyOrLtValue|undefined} value */
  setFalsyOrLtValue(value?: StreamFilterOp.FalsyOrLtValue) {
    jspb.Message.setOneofWrapperField(this, 17, StreamFilterOp.oneofGroups_[0], value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearFalsyOrLtValue() {
    this.setFalsyOrLtValue(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasFalsyOrLtValue(): boolean {
    return jspb.Message.getField(this, 17) != null;
  }


  /**
   * optional WithinInclusiveValue within_inclusive_value = 18;
   * @return {?StreamFilterOp.WithinInclusiveValue}
   */
  getWithinInclusiveValue(): StreamFilterOp.WithinInclusiveValue {
    return /** @type{?StreamFilterOp.WithinInclusiveValue} */ (
      jspb.Message.getWrapperField(this, StreamFilterOp.WithinInclusiveValue, 18));
  }


  /** @param {?StreamFilterOp.WithinInclusiveValue|undefined} value */
  setWithinInclusiveValue(value?: StreamFilterOp.WithinInclusiveValue) {
    jspb.Message.setOneofWrapperField(this, 18, StreamFilterOp.oneofGroups_[0], value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearWithinInclusiveValue() {
    this.setWithinInclusiveValue(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasWithinInclusiveValue(): boolean {
    return jspb.Message.getField(this, 18) != null;
  }


}
export namespace StreamFilterOp {
export enum OpCase {
  OP_NOT_SET = 0,
  LT_NOW = 1,
  LTE_NOW = 2,
  GT_NOW = 3,
  GTE_NOW = 4,
  FALSY_OR_LTE_NOW = 5,
  FALSY_OR_LT_NOW = 6,
  FALSY_OR_GTE_NOW = 7,
  FALSY_OR_GT_NOW = 8,
  INCLUDES = 9,
  GTE_VALUE = 10,
  GT_VALUE = 11,
  LTE_VALUE = 12,
  LT_VALUE = 13,
  FALSY_OR_GTE_VALUE = 14,
  FALSY_OR_GT_VALUE = 15,
  FALSY_OR_LTE_VALUE = 16,
  FALSY_OR_LT_VALUE = 17,
  WITHIN_INCLUSIVE_VALUE = 18,
}
} // namespace StreamFilterOp
export namespace StreamFilterOp {
export namespace Value {
  export interface AsObject {
    doubleValue: number;
    floatValue: number;
    int32Value: number;
    uint32Value: number;
    boolValue: boolean;
    stringValue: string;
    uint64Value: number;
  }
}
export class Value extends jspb.Message {
  static readonly displayName = "StreamFilterOp.Value";
  /**
   * Oneof group definitions for this message. Each group defines the field
   * numbers belonging to that group. When of these fields' value is set, all
   * other fields in the group are cleared. During deserialization, if multiple
   * fields are encountered for a group, only the last value seen will be kept.
   * @private {!Array<!Array<number>>}
   * @const
   */
  static readonly oneofGroups_ = [[1,2,3,4,5,6,7]];


  /**
   * @return {StreamFilterOp.Value.ValueCase}
   */
  getValueCase() {
    return /** @type {StreamFilterOp.Value.ValueCase} */(jspb.Message.computeOneofCase(this, StreamFilterOp.Value.oneofGroups_[0]));
  }

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, StreamFilterOp.Value.oneofGroups_);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): Value.AsObject {
    return Value.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!Value} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: Value) {
    var f: any;
    var obj: any = {
      doubleValue: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
      floatValue: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
      int32Value: jspb.Message.getFieldWithDefault(msg, 3, 0),
      uint32Value: jspb.Message.getFieldWithDefault(msg, 4, 0),
      boolValue: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
      stringValue: jspb.Message.getFieldWithDefault(msg, 6, ""),
      uint64Value: jspb.Message.getFieldWithDefault(msg, 7, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!Value}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new Value;
    return Value.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!StreamFilterOp.Value} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!StreamFilterOp.Value}
   */
  static deserializeBinaryFromReader(msg: Value, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {number} */ (reader.readDouble());
        msg.setDoubleValue(value1);
        break;
      case 2:
        var value2 = /** @type {number} */ (reader.readFloat());
        msg.setFloatValue(value2);
        break;
      case 3:
        var value3 = /** @type {number} */ (reader.readInt32());
        msg.setInt32Value(value3);
        break;
      case 4:
        var value4 = /** @type {number} */ (reader.readUint32());
        msg.setUint32Value(value4);
        break;
      case 5:
        var value5 = /** @type {boolean} */ (reader.readBool());
        msg.setBoolValue(value5);
        break;
      case 6:
        var value6 = /** @type {string} */ (reader.readString());
        msg.setStringValue(value6);
        break;
      case 7:
        var value7 = /** @type {number} */ (reader.readUint64());
        msg.setUint64Value(value7);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!StreamFilterOp.Value} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: StreamFilterOp.Value, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = /** @type {number} */ (jspb.Message.getField(message, 1));
    if (f != null) {
      writer.writeDouble(
        1,
        f
      );
    }
    f = /** @type {number} */ (jspb.Message.getField(message, 2));
    if (f != null) {
      writer.writeFloat(
        2,
        f
      );
    }
    f = /** @type {number} */ (jspb.Message.getField(message, 3));
    if (f != null) {
      writer.writeInt32(
        3,
        f
      );
    }
    f = /** @type {number} */ (jspb.Message.getField(message, 4));
    if (f != null) {
      writer.writeUint32(
        4,
        f
      );
    }
    f = /** @type {boolean} */ (jspb.Message.getField(message, 5));
    if (f != null) {
      writer.writeBool(
        5,
        f
      );
    }
    f = /** @type {string} */ (jspb.Message.getField(message, 6));
    if (f != null) {
      writer.writeString(
        6,
        f
      );
    }
    f = /** @type {number} */ (jspb.Message.getField(message, 7));
    if (f != null) {
      writer.writeUint64(
        7,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    StreamFilterOp.Value.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional double double_value = 1;
   * @return {number}
   */
  getDoubleValue(): number {
    return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
  };


  /** @param {number} value */
  setDoubleValue(value: number) {
    jspb.Message.setOneofField(this, 1, StreamFilterOp.Value.oneofGroups_[0], value);
  }


  /**
   * Clears the field making it undefined.
   */
  clearDoubleValue() {
    jspb.Message.setOneofField(this, 1, StreamFilterOp.Value.oneofGroups_[0], undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasDoubleValue(): boolean {
    return jspb.Message.getField(this, 1) != null;
  }


  /**
   * optional float float_value = 2;
   * @return {number}
   */
  getFloatValue(): number {
    return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
  };


  /** @param {number} value */
  setFloatValue(value: number) {
    jspb.Message.setOneofField(this, 2, StreamFilterOp.Value.oneofGroups_[0], value);
  }


  /**
   * Clears the field making it undefined.
   */
  clearFloatValue() {
    jspb.Message.setOneofField(this, 2, StreamFilterOp.Value.oneofGroups_[0], undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasFloatValue(): boolean {
    return jspb.Message.getField(this, 2) != null;
  }


  /**
   * optional int32 int32_value = 3;
   * @return {number}
   */
  getInt32Value(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
  };


  /** @param {number} value */
  setInt32Value(value: number) {
    jspb.Message.setOneofField(this, 3, StreamFilterOp.Value.oneofGroups_[0], value);
  }


  /**
   * Clears the field making it undefined.
   */
  clearInt32Value() {
    jspb.Message.setOneofField(this, 3, StreamFilterOp.Value.oneofGroups_[0], undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasInt32Value(): boolean {
    return jspb.Message.getField(this, 3) != null;
  }


  /**
   * optional uint32 uint32_value = 4;
   * @return {number}
   */
  getUint32Value(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
  };


  /** @param {number} value */
  setUint32Value(value: number) {
    jspb.Message.setOneofField(this, 4, StreamFilterOp.Value.oneofGroups_[0], value);
  }


  /**
   * Clears the field making it undefined.
   */
  clearUint32Value() {
    jspb.Message.setOneofField(this, 4, StreamFilterOp.Value.oneofGroups_[0], undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasUint32Value(): boolean {
    return jspb.Message.getField(this, 4) != null;
  }


  /**
   * optional bool bool_value = 5;
   * @return {boolean}
   */
  getBoolValue(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
  };


  /** @param {boolean} value */
  setBoolValue(value: boolean) {
    jspb.Message.setOneofField(this, 5, StreamFilterOp.Value.oneofGroups_[0], value);
  }


  /**
   * Clears the field making it undefined.
   */
  clearBoolValue() {
    jspb.Message.setOneofField(this, 5, StreamFilterOp.Value.oneofGroups_[0], undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasBoolValue(): boolean {
    return jspb.Message.getField(this, 5) != null;
  }


  /**
   * optional string string_value = 6;
   * @return {string}
   */
  getStringValue(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
  };


  /** @param {string} value */
  setStringValue(value: string) {
    jspb.Message.setOneofField(this, 6, StreamFilterOp.Value.oneofGroups_[0], value);
  }


  /**
   * Clears the field making it undefined.
   */
  clearStringValue() {
    jspb.Message.setOneofField(this, 6, StreamFilterOp.Value.oneofGroups_[0], undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasStringValue(): boolean {
    return jspb.Message.getField(this, 6) != null;
  }


  /**
   * optional uint64 uint64_value = 7;
   * @return {number}
   */
  getUint64Value(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
  };


  /** @param {number} value */
  setUint64Value(value: number) {
    jspb.Message.setOneofField(this, 7, StreamFilterOp.Value.oneofGroups_[0], value);
  }


  /**
   * Clears the field making it undefined.
   */
  clearUint64Value() {
    jspb.Message.setOneofField(this, 7, StreamFilterOp.Value.oneofGroups_[0], undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasUint64Value(): boolean {
    return jspb.Message.getField(this, 7) != null;
  }


}
} // namespace StreamFilterOp
export namespace StreamFilterOp {
export namespace Value {
export enum ValueCase {
  VALUE_NOT_SET = 0,
  DOUBLE_VALUE = 1,
  FLOAT_VALUE = 2,
  INT32_VALUE = 3,
  UINT32_VALUE = 4,
  BOOL_VALUE = 5,
  STRING_VALUE = 6,
  UINT64_VALUE = 7,
}
} // namespace StreamFilterOp
} // namespace Value
export namespace StreamFilterOp {
export namespace LtNow {
  export interface AsObject {
  }
}
export class LtNow extends jspb.Message {
  static readonly displayName = "StreamFilterOp.LtNow";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): LtNow.AsObject {
    return LtNow.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!LtNow} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: LtNow) {
    var f: any;
    var obj: any = {

    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!LtNow}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new LtNow;
    return LtNow.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!StreamFilterOp.LtNow} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!StreamFilterOp.LtNow}
   */
  static deserializeBinaryFromReader(msg: LtNow, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!StreamFilterOp.LtNow} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: StreamFilterOp.LtNow, writer: jspb.BinaryWriter) {
    var f: any = undefined;
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    StreamFilterOp.LtNow.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
}
} // namespace StreamFilterOp
export namespace StreamFilterOp {
export namespace LteNow {
  export interface AsObject {
  }
}
export class LteNow extends jspb.Message {
  static readonly displayName = "StreamFilterOp.LteNow";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): LteNow.AsObject {
    return LteNow.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!LteNow} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: LteNow) {
    var f: any;
    var obj: any = {

    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!LteNow}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new LteNow;
    return LteNow.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!StreamFilterOp.LteNow} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!StreamFilterOp.LteNow}
   */
  static deserializeBinaryFromReader(msg: LteNow, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!StreamFilterOp.LteNow} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: StreamFilterOp.LteNow, writer: jspb.BinaryWriter) {
    var f: any = undefined;
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    StreamFilterOp.LteNow.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
}
} // namespace StreamFilterOp
export namespace StreamFilterOp {
export namespace GtNow {
  export interface AsObject {
  }
}
export class GtNow extends jspb.Message {
  static readonly displayName = "StreamFilterOp.GtNow";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): GtNow.AsObject {
    return GtNow.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!GtNow} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: GtNow) {
    var f: any;
    var obj: any = {

    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!GtNow}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new GtNow;
    return GtNow.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!StreamFilterOp.GtNow} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!StreamFilterOp.GtNow}
   */
  static deserializeBinaryFromReader(msg: GtNow, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!StreamFilterOp.GtNow} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: StreamFilterOp.GtNow, writer: jspb.BinaryWriter) {
    var f: any = undefined;
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    StreamFilterOp.GtNow.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
}
} // namespace StreamFilterOp
export namespace StreamFilterOp {
export namespace GteNow {
  export interface AsObject {
  }
}
export class GteNow extends jspb.Message {
  static readonly displayName = "StreamFilterOp.GteNow";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): GteNow.AsObject {
    return GteNow.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!GteNow} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: GteNow) {
    var f: any;
    var obj: any = {

    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!GteNow}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new GteNow;
    return GteNow.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!StreamFilterOp.GteNow} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!StreamFilterOp.GteNow}
   */
  static deserializeBinaryFromReader(msg: GteNow, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!StreamFilterOp.GteNow} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: StreamFilterOp.GteNow, writer: jspb.BinaryWriter) {
    var f: any = undefined;
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    StreamFilterOp.GteNow.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
}
} // namespace StreamFilterOp
export namespace StreamFilterOp {
export namespace FalsyOrLteNow {
  export interface AsObject {
  }
}
export class FalsyOrLteNow extends jspb.Message {
  static readonly displayName = "StreamFilterOp.FalsyOrLteNow";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): FalsyOrLteNow.AsObject {
    return FalsyOrLteNow.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!FalsyOrLteNow} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: FalsyOrLteNow) {
    var f: any;
    var obj: any = {

    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!FalsyOrLteNow}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new FalsyOrLteNow;
    return FalsyOrLteNow.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!StreamFilterOp.FalsyOrLteNow} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!StreamFilterOp.FalsyOrLteNow}
   */
  static deserializeBinaryFromReader(msg: FalsyOrLteNow, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!StreamFilterOp.FalsyOrLteNow} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: StreamFilterOp.FalsyOrLteNow, writer: jspb.BinaryWriter) {
    var f: any = undefined;
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    StreamFilterOp.FalsyOrLteNow.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
}
} // namespace StreamFilterOp
export namespace StreamFilterOp {
export namespace FalsyOrLtNow {
  export interface AsObject {
  }
}
export class FalsyOrLtNow extends jspb.Message {
  static readonly displayName = "StreamFilterOp.FalsyOrLtNow";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): FalsyOrLtNow.AsObject {
    return FalsyOrLtNow.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!FalsyOrLtNow} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: FalsyOrLtNow) {
    var f: any;
    var obj: any = {

    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!FalsyOrLtNow}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new FalsyOrLtNow;
    return FalsyOrLtNow.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!StreamFilterOp.FalsyOrLtNow} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!StreamFilterOp.FalsyOrLtNow}
   */
  static deserializeBinaryFromReader(msg: FalsyOrLtNow, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!StreamFilterOp.FalsyOrLtNow} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: StreamFilterOp.FalsyOrLtNow, writer: jspb.BinaryWriter) {
    var f: any = undefined;
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    StreamFilterOp.FalsyOrLtNow.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
}
} // namespace StreamFilterOp
export namespace StreamFilterOp {
export namespace FalsyOrGteNow {
  export interface AsObject {
  }
}
export class FalsyOrGteNow extends jspb.Message {
  static readonly displayName = "StreamFilterOp.FalsyOrGteNow";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): FalsyOrGteNow.AsObject {
    return FalsyOrGteNow.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!FalsyOrGteNow} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: FalsyOrGteNow) {
    var f: any;
    var obj: any = {

    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!FalsyOrGteNow}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new FalsyOrGteNow;
    return FalsyOrGteNow.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!StreamFilterOp.FalsyOrGteNow} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!StreamFilterOp.FalsyOrGteNow}
   */
  static deserializeBinaryFromReader(msg: FalsyOrGteNow, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!StreamFilterOp.FalsyOrGteNow} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: StreamFilterOp.FalsyOrGteNow, writer: jspb.BinaryWriter) {
    var f: any = undefined;
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    StreamFilterOp.FalsyOrGteNow.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
}
} // namespace StreamFilterOp
export namespace StreamFilterOp {
export namespace FalsyOrGtNow {
  export interface AsObject {
  }
}
export class FalsyOrGtNow extends jspb.Message {
  static readonly displayName = "StreamFilterOp.FalsyOrGtNow";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): FalsyOrGtNow.AsObject {
    return FalsyOrGtNow.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!FalsyOrGtNow} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: FalsyOrGtNow) {
    var f: any;
    var obj: any = {

    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!FalsyOrGtNow}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new FalsyOrGtNow;
    return FalsyOrGtNow.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!StreamFilterOp.FalsyOrGtNow} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!StreamFilterOp.FalsyOrGtNow}
   */
  static deserializeBinaryFromReader(msg: FalsyOrGtNow, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!StreamFilterOp.FalsyOrGtNow} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: StreamFilterOp.FalsyOrGtNow, writer: jspb.BinaryWriter) {
    var f: any = undefined;
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    StreamFilterOp.FalsyOrGtNow.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
}
} // namespace StreamFilterOp
export namespace StreamFilterOp {
export namespace Includes {
  export interface AsObject {
    valueList: StreamFilterOp.Value.AsObject[];
  }
}
export class Includes extends jspb.Message {
  static readonly displayName = "StreamFilterOp.Includes";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [1];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, StreamFilterOp.Includes.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): Includes.AsObject {
    return Includes.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!Includes} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: Includes) {
    var f: any;
    var obj: any = {
      valueList: jspb.Message.toObjectList(msg.getValueList(),
      StreamFilterOp.Value.toObject, includeInstance)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!Includes}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new Includes;
    return Includes.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!StreamFilterOp.Includes} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!StreamFilterOp.Includes}
   */
  static deserializeBinaryFromReader(msg: Includes, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new StreamFilterOp.Value;
        reader.readMessage(value1,StreamFilterOp.Value.deserializeBinaryFromReader);
        msg.addValue(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!StreamFilterOp.Includes} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: StreamFilterOp.Includes, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getValueList();
    if (f.length > 0) {
      writer.writeRepeatedMessage(
        1,
        f,
        StreamFilterOp.Value.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    StreamFilterOp.Includes.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * repeated Value value = 1;
   * @return {!Array<!StreamFilterOp.Value>}
   */
  getValueList(): StreamFilterOp.Value[] {
    return /** @type{!Array<!StreamFilterOp.Value>} */ (
      jspb.Message.getRepeatedWrapperField(this, StreamFilterOp.Value, 1));
  }


  /** @param {!Array<!StreamFilterOp.Value>} value */
  setValueList(value?: StreamFilterOp.Value[]) {
    jspb.Message.setRepeatedWrapperField(this, 1, value);
  }


  /**
   * @param {!StreamFilterOp.Value=} opt_value
   * @param {number=} opt_index
   * @return {!StreamFilterOp.Value}
   */
  addValue(opt_value?: StreamFilterOp.Value, opt_index?: number): StreamFilterOp.Value {
    return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, StreamFilterOp.Value, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearValueList() {
    this.setValueList([]);
  }


}
} // namespace StreamFilterOp
export namespace StreamFilterOp {
export namespace GteValue {
  export interface AsObject {
    value?: StreamFilterOp.Value.AsObject;
  }
}
export class GteValue extends jspb.Message {
  static readonly displayName = "StreamFilterOp.GteValue";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): GteValue.AsObject {
    return GteValue.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!GteValue} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: GteValue) {
    var f: any;
    var obj: any = {
      value: (f = msg.getValue()) && StreamFilterOp.Value.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!GteValue}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new GteValue;
    return GteValue.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!StreamFilterOp.GteValue} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!StreamFilterOp.GteValue}
   */
  static deserializeBinaryFromReader(msg: GteValue, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new StreamFilterOp.Value;
        reader.readMessage(value1,StreamFilterOp.Value.deserializeBinaryFromReader);
        msg.setValue(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!StreamFilterOp.GteValue} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: StreamFilterOp.GteValue, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getValue();
    if (f != null) {
      writer.writeMessage(
        1,
        f,
        StreamFilterOp.Value.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    StreamFilterOp.GteValue.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional Value value = 1;
   * @return {?StreamFilterOp.Value}
   */
  getValue(): StreamFilterOp.Value {
    return /** @type{?StreamFilterOp.Value} */ (
      jspb.Message.getWrapperField(this, StreamFilterOp.Value, 1));
  }


  /** @param {?StreamFilterOp.Value|undefined} value */
  setValue(value?: StreamFilterOp.Value) {
    jspb.Message.setWrapperField(this, 1, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearValue() {
    this.setValue(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasValue(): boolean {
    return jspb.Message.getField(this, 1) != null;
  }


}
} // namespace StreamFilterOp
export namespace StreamFilterOp {
export namespace GtValue {
  export interface AsObject {
    value?: StreamFilterOp.Value.AsObject;
  }
}
export class GtValue extends jspb.Message {
  static readonly displayName = "StreamFilterOp.GtValue";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): GtValue.AsObject {
    return GtValue.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!GtValue} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: GtValue) {
    var f: any;
    var obj: any = {
      value: (f = msg.getValue()) && StreamFilterOp.Value.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!GtValue}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new GtValue;
    return GtValue.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!StreamFilterOp.GtValue} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!StreamFilterOp.GtValue}
   */
  static deserializeBinaryFromReader(msg: GtValue, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new StreamFilterOp.Value;
        reader.readMessage(value1,StreamFilterOp.Value.deserializeBinaryFromReader);
        msg.setValue(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!StreamFilterOp.GtValue} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: StreamFilterOp.GtValue, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getValue();
    if (f != null) {
      writer.writeMessage(
        1,
        f,
        StreamFilterOp.Value.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    StreamFilterOp.GtValue.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional Value value = 1;
   * @return {?StreamFilterOp.Value}
   */
  getValue(): StreamFilterOp.Value {
    return /** @type{?StreamFilterOp.Value} */ (
      jspb.Message.getWrapperField(this, StreamFilterOp.Value, 1));
  }


  /** @param {?StreamFilterOp.Value|undefined} value */
  setValue(value?: StreamFilterOp.Value) {
    jspb.Message.setWrapperField(this, 1, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearValue() {
    this.setValue(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasValue(): boolean {
    return jspb.Message.getField(this, 1) != null;
  }


}
} // namespace StreamFilterOp
export namespace StreamFilterOp {
export namespace LteValue {
  export interface AsObject {
    value?: StreamFilterOp.Value.AsObject;
  }
}
export class LteValue extends jspb.Message {
  static readonly displayName = "StreamFilterOp.LteValue";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): LteValue.AsObject {
    return LteValue.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!LteValue} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: LteValue) {
    var f: any;
    var obj: any = {
      value: (f = msg.getValue()) && StreamFilterOp.Value.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!LteValue}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new LteValue;
    return LteValue.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!StreamFilterOp.LteValue} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!StreamFilterOp.LteValue}
   */
  static deserializeBinaryFromReader(msg: LteValue, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new StreamFilterOp.Value;
        reader.readMessage(value1,StreamFilterOp.Value.deserializeBinaryFromReader);
        msg.setValue(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!StreamFilterOp.LteValue} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: StreamFilterOp.LteValue, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getValue();
    if (f != null) {
      writer.writeMessage(
        1,
        f,
        StreamFilterOp.Value.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    StreamFilterOp.LteValue.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional Value value = 1;
   * @return {?StreamFilterOp.Value}
   */
  getValue(): StreamFilterOp.Value {
    return /** @type{?StreamFilterOp.Value} */ (
      jspb.Message.getWrapperField(this, StreamFilterOp.Value, 1));
  }


  /** @param {?StreamFilterOp.Value|undefined} value */
  setValue(value?: StreamFilterOp.Value) {
    jspb.Message.setWrapperField(this, 1, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearValue() {
    this.setValue(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasValue(): boolean {
    return jspb.Message.getField(this, 1) != null;
  }


}
} // namespace StreamFilterOp
export namespace StreamFilterOp {
export namespace LtValue {
  export interface AsObject {
    value?: StreamFilterOp.Value.AsObject;
  }
}
export class LtValue extends jspb.Message {
  static readonly displayName = "StreamFilterOp.LtValue";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): LtValue.AsObject {
    return LtValue.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!LtValue} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: LtValue) {
    var f: any;
    var obj: any = {
      value: (f = msg.getValue()) && StreamFilterOp.Value.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!LtValue}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new LtValue;
    return LtValue.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!StreamFilterOp.LtValue} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!StreamFilterOp.LtValue}
   */
  static deserializeBinaryFromReader(msg: LtValue, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new StreamFilterOp.Value;
        reader.readMessage(value1,StreamFilterOp.Value.deserializeBinaryFromReader);
        msg.setValue(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!StreamFilterOp.LtValue} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: StreamFilterOp.LtValue, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getValue();
    if (f != null) {
      writer.writeMessage(
        1,
        f,
        StreamFilterOp.Value.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    StreamFilterOp.LtValue.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional Value value = 1;
   * @return {?StreamFilterOp.Value}
   */
  getValue(): StreamFilterOp.Value {
    return /** @type{?StreamFilterOp.Value} */ (
      jspb.Message.getWrapperField(this, StreamFilterOp.Value, 1));
  }


  /** @param {?StreamFilterOp.Value|undefined} value */
  setValue(value?: StreamFilterOp.Value) {
    jspb.Message.setWrapperField(this, 1, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearValue() {
    this.setValue(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasValue(): boolean {
    return jspb.Message.getField(this, 1) != null;
  }


}
} // namespace StreamFilterOp
export namespace StreamFilterOp {
export namespace FalsyOrGteValue {
  export interface AsObject {
    value?: StreamFilterOp.Value.AsObject;
  }
}
export class FalsyOrGteValue extends jspb.Message {
  static readonly displayName = "StreamFilterOp.FalsyOrGteValue";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): FalsyOrGteValue.AsObject {
    return FalsyOrGteValue.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!FalsyOrGteValue} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: FalsyOrGteValue) {
    var f: any;
    var obj: any = {
      value: (f = msg.getValue()) && StreamFilterOp.Value.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!FalsyOrGteValue}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new FalsyOrGteValue;
    return FalsyOrGteValue.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!StreamFilterOp.FalsyOrGteValue} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!StreamFilterOp.FalsyOrGteValue}
   */
  static deserializeBinaryFromReader(msg: FalsyOrGteValue, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new StreamFilterOp.Value;
        reader.readMessage(value1,StreamFilterOp.Value.deserializeBinaryFromReader);
        msg.setValue(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!StreamFilterOp.FalsyOrGteValue} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: StreamFilterOp.FalsyOrGteValue, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getValue();
    if (f != null) {
      writer.writeMessage(
        1,
        f,
        StreamFilterOp.Value.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    StreamFilterOp.FalsyOrGteValue.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional Value value = 1;
   * @return {?StreamFilterOp.Value}
   */
  getValue(): StreamFilterOp.Value {
    return /** @type{?StreamFilterOp.Value} */ (
      jspb.Message.getWrapperField(this, StreamFilterOp.Value, 1));
  }


  /** @param {?StreamFilterOp.Value|undefined} value */
  setValue(value?: StreamFilterOp.Value) {
    jspb.Message.setWrapperField(this, 1, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearValue() {
    this.setValue(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasValue(): boolean {
    return jspb.Message.getField(this, 1) != null;
  }


}
} // namespace StreamFilterOp
export namespace StreamFilterOp {
export namespace FalsyOrGtValue {
  export interface AsObject {
    value?: StreamFilterOp.Value.AsObject;
  }
}
export class FalsyOrGtValue extends jspb.Message {
  static readonly displayName = "StreamFilterOp.FalsyOrGtValue";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): FalsyOrGtValue.AsObject {
    return FalsyOrGtValue.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!FalsyOrGtValue} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: FalsyOrGtValue) {
    var f: any;
    var obj: any = {
      value: (f = msg.getValue()) && StreamFilterOp.Value.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!FalsyOrGtValue}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new FalsyOrGtValue;
    return FalsyOrGtValue.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!StreamFilterOp.FalsyOrGtValue} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!StreamFilterOp.FalsyOrGtValue}
   */
  static deserializeBinaryFromReader(msg: FalsyOrGtValue, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new StreamFilterOp.Value;
        reader.readMessage(value1,StreamFilterOp.Value.deserializeBinaryFromReader);
        msg.setValue(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!StreamFilterOp.FalsyOrGtValue} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: StreamFilterOp.FalsyOrGtValue, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getValue();
    if (f != null) {
      writer.writeMessage(
        1,
        f,
        StreamFilterOp.Value.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    StreamFilterOp.FalsyOrGtValue.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional Value value = 1;
   * @return {?StreamFilterOp.Value}
   */
  getValue(): StreamFilterOp.Value {
    return /** @type{?StreamFilterOp.Value} */ (
      jspb.Message.getWrapperField(this, StreamFilterOp.Value, 1));
  }


  /** @param {?StreamFilterOp.Value|undefined} value */
  setValue(value?: StreamFilterOp.Value) {
    jspb.Message.setWrapperField(this, 1, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearValue() {
    this.setValue(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasValue(): boolean {
    return jspb.Message.getField(this, 1) != null;
  }


}
} // namespace StreamFilterOp
export namespace StreamFilterOp {
export namespace FalsyOrLteValue {
  export interface AsObject {
    value?: StreamFilterOp.Value.AsObject;
  }
}
export class FalsyOrLteValue extends jspb.Message {
  static readonly displayName = "StreamFilterOp.FalsyOrLteValue";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): FalsyOrLteValue.AsObject {
    return FalsyOrLteValue.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!FalsyOrLteValue} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: FalsyOrLteValue) {
    var f: any;
    var obj: any = {
      value: (f = msg.getValue()) && StreamFilterOp.Value.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!FalsyOrLteValue}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new FalsyOrLteValue;
    return FalsyOrLteValue.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!StreamFilterOp.FalsyOrLteValue} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!StreamFilterOp.FalsyOrLteValue}
   */
  static deserializeBinaryFromReader(msg: FalsyOrLteValue, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new StreamFilterOp.Value;
        reader.readMessage(value1,StreamFilterOp.Value.deserializeBinaryFromReader);
        msg.setValue(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!StreamFilterOp.FalsyOrLteValue} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: StreamFilterOp.FalsyOrLteValue, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getValue();
    if (f != null) {
      writer.writeMessage(
        1,
        f,
        StreamFilterOp.Value.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    StreamFilterOp.FalsyOrLteValue.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional Value value = 1;
   * @return {?StreamFilterOp.Value}
   */
  getValue(): StreamFilterOp.Value {
    return /** @type{?StreamFilterOp.Value} */ (
      jspb.Message.getWrapperField(this, StreamFilterOp.Value, 1));
  }


  /** @param {?StreamFilterOp.Value|undefined} value */
  setValue(value?: StreamFilterOp.Value) {
    jspb.Message.setWrapperField(this, 1, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearValue() {
    this.setValue(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasValue(): boolean {
    return jspb.Message.getField(this, 1) != null;
  }


}
} // namespace StreamFilterOp
export namespace StreamFilterOp {
export namespace FalsyOrLtValue {
  export interface AsObject {
    value?: StreamFilterOp.Value.AsObject;
  }
}
export class FalsyOrLtValue extends jspb.Message {
  static readonly displayName = "StreamFilterOp.FalsyOrLtValue";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): FalsyOrLtValue.AsObject {
    return FalsyOrLtValue.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!FalsyOrLtValue} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: FalsyOrLtValue) {
    var f: any;
    var obj: any = {
      value: (f = msg.getValue()) && StreamFilterOp.Value.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!FalsyOrLtValue}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new FalsyOrLtValue;
    return FalsyOrLtValue.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!StreamFilterOp.FalsyOrLtValue} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!StreamFilterOp.FalsyOrLtValue}
   */
  static deserializeBinaryFromReader(msg: FalsyOrLtValue, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new StreamFilterOp.Value;
        reader.readMessage(value1,StreamFilterOp.Value.deserializeBinaryFromReader);
        msg.setValue(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!StreamFilterOp.FalsyOrLtValue} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: StreamFilterOp.FalsyOrLtValue, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getValue();
    if (f != null) {
      writer.writeMessage(
        1,
        f,
        StreamFilterOp.Value.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    StreamFilterOp.FalsyOrLtValue.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional Value value = 1;
   * @return {?StreamFilterOp.Value}
   */
  getValue(): StreamFilterOp.Value {
    return /** @type{?StreamFilterOp.Value} */ (
      jspb.Message.getWrapperField(this, StreamFilterOp.Value, 1));
  }


  /** @param {?StreamFilterOp.Value|undefined} value */
  setValue(value?: StreamFilterOp.Value) {
    jspb.Message.setWrapperField(this, 1, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearValue() {
    this.setValue(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasValue(): boolean {
    return jspb.Message.getField(this, 1) != null;
  }


}
} // namespace StreamFilterOp
export namespace StreamFilterOp {
export namespace WithinInclusiveValue {
  export interface AsObject {
    startValue?: StreamFilterOp.Value.AsObject;
    endValue?: StreamFilterOp.Value.AsObject;
  }
}
export class WithinInclusiveValue extends jspb.Message {
  static readonly displayName = "StreamFilterOp.WithinInclusiveValue";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): WithinInclusiveValue.AsObject {
    return WithinInclusiveValue.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!WithinInclusiveValue} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: WithinInclusiveValue) {
    var f: any;
    var obj: any = {
      startValue: (f = msg.getStartValue()) && StreamFilterOp.Value.toObject(includeInstance, f),
      endValue: (f = msg.getEndValue()) && StreamFilterOp.Value.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!WithinInclusiveValue}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new WithinInclusiveValue;
    return WithinInclusiveValue.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!StreamFilterOp.WithinInclusiveValue} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!StreamFilterOp.WithinInclusiveValue}
   */
  static deserializeBinaryFromReader(msg: WithinInclusiveValue, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = new StreamFilterOp.Value;
        reader.readMessage(value1,StreamFilterOp.Value.deserializeBinaryFromReader);
        msg.setStartValue(value1);
        break;
      case 2:
        var value2 = new StreamFilterOp.Value;
        reader.readMessage(value2,StreamFilterOp.Value.deserializeBinaryFromReader);
        msg.setEndValue(value2);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!StreamFilterOp.WithinInclusiveValue} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: StreamFilterOp.WithinInclusiveValue, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getStartValue();
    if (f != null) {
      writer.writeMessage(
        1,
        f,
        StreamFilterOp.Value.serializeBinaryToWriter
      );
    }
    f = message.getEndValue();
    if (f != null) {
      writer.writeMessage(
        2,
        f,
        StreamFilterOp.Value.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    StreamFilterOp.WithinInclusiveValue.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional Value start_value = 1;
   * @return {?StreamFilterOp.Value}
   */
  getStartValue(): StreamFilterOp.Value {
    return /** @type{?StreamFilterOp.Value} */ (
      jspb.Message.getWrapperField(this, StreamFilterOp.Value, 1));
  }


  /** @param {?StreamFilterOp.Value|undefined} value */
  setStartValue(value?: StreamFilterOp.Value) {
    jspb.Message.setWrapperField(this, 1, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearStartValue() {
    this.setStartValue(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasStartValue(): boolean {
    return jspb.Message.getField(this, 1) != null;
  }


  /**
   * optional Value end_value = 2;
   * @return {?StreamFilterOp.Value}
   */
  getEndValue(): StreamFilterOp.Value {
    return /** @type{?StreamFilterOp.Value} */ (
      jspb.Message.getWrapperField(this, StreamFilterOp.Value, 2));
  }


  /** @param {?StreamFilterOp.Value|undefined} value */
  setEndValue(value?: StreamFilterOp.Value) {
    jspb.Message.setWrapperField(this, 2, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearEndValue() {
    this.setEndValue(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasEndValue(): boolean {
    return jspb.Message.getField(this, 2) != null;
  }


}
} // namespace StreamFilterOp
export namespace StreamFilterType {
  export interface AsObject {
    doubleValue: number;
    floatValue: number;
    int32Value: number;
    uint32Value: number;
    boolValue: boolean;
    stringValue: string;
    op?: StreamFilterOp.AsObject;
  }
}
export class StreamFilterType extends jspb.Message {
  static readonly displayName = "StreamFilterType";
  /**
   * Oneof group definitions for this message. Each group defines the field
   * numbers belonging to that group. When of these fields' value is set, all
   * other fields in the group are cleared. During deserialization, if multiple
   * fields are encountered for a group, only the last value seen will be kept.
   * @private {!Array<!Array<number>>}
   * @const
   */
  static readonly oneofGroups_ = [[1,2,3,4,5,6,7]];


  /**
   * @return {StreamFilterType.ValuesCase}
   */
  getValuesCase() {
    return /** @type {StreamFilterType.ValuesCase} */(jspb.Message.computeOneofCase(this, StreamFilterType.oneofGroups_[0]));
  }

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, StreamFilterType.oneofGroups_);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): StreamFilterType.AsObject {
    return StreamFilterType.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!StreamFilterType} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: StreamFilterType) {
    var f: any;
    var obj: any = {
      doubleValue: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
      floatValue: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
      int32Value: jspb.Message.getFieldWithDefault(msg, 3, 0),
      uint32Value: jspb.Message.getFieldWithDefault(msg, 4, 0),
      boolValue: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
      stringValue: jspb.Message.getFieldWithDefault(msg, 6, ""),
      op: (f = msg.getOp()) && StreamFilterOp.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!StreamFilterType}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new StreamFilterType;
    return StreamFilterType.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!StreamFilterType} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!StreamFilterType}
   */
  static deserializeBinaryFromReader(msg: StreamFilterType, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {number} */ (reader.readDouble());
        msg.setDoubleValue(value1);
        break;
      case 2:
        var value2 = /** @type {number} */ (reader.readFloat());
        msg.setFloatValue(value2);
        break;
      case 3:
        var value3 = /** @type {number} */ (reader.readInt32());
        msg.setInt32Value(value3);
        break;
      case 4:
        var value4 = /** @type {number} */ (reader.readUint32());
        msg.setUint32Value(value4);
        break;
      case 5:
        var value5 = /** @type {boolean} */ (reader.readBool());
        msg.setBoolValue(value5);
        break;
      case 6:
        var value6 = /** @type {string} */ (reader.readString());
        msg.setStringValue(value6);
        break;
      case 7:
        var value7 = new StreamFilterOp;
        reader.readMessage(value7,StreamFilterOp.deserializeBinaryFromReader);
        msg.setOp(value7);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!StreamFilterType} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: StreamFilterType, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = /** @type {number} */ (jspb.Message.getField(message, 1));
    if (f != null) {
      writer.writeDouble(
        1,
        f
      );
    }
    f = /** @type {number} */ (jspb.Message.getField(message, 2));
    if (f != null) {
      writer.writeFloat(
        2,
        f
      );
    }
    f = /** @type {number} */ (jspb.Message.getField(message, 3));
    if (f != null) {
      writer.writeInt32(
        3,
        f
      );
    }
    f = /** @type {number} */ (jspb.Message.getField(message, 4));
    if (f != null) {
      writer.writeUint32(
        4,
        f
      );
    }
    f = /** @type {boolean} */ (jspb.Message.getField(message, 5));
    if (f != null) {
      writer.writeBool(
        5,
        f
      );
    }
    f = /** @type {string} */ (jspb.Message.getField(message, 6));
    if (f != null) {
      writer.writeString(
        6,
        f
      );
    }
    f = message.getOp();
    if (f != null) {
      writer.writeMessage(
        7,
        f,
        StreamFilterOp.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    StreamFilterType.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional double double_value = 1;
   * @return {number}
   */
  getDoubleValue(): number {
    return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
  };


  /** @param {number} value */
  setDoubleValue(value: number) {
    jspb.Message.setOneofField(this, 1, StreamFilterType.oneofGroups_[0], value);
  }


  /**
   * Clears the field making it undefined.
   */
  clearDoubleValue() {
    jspb.Message.setOneofField(this, 1, StreamFilterType.oneofGroups_[0], undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasDoubleValue(): boolean {
    return jspb.Message.getField(this, 1) != null;
  }


  /**
   * optional float float_value = 2;
   * @return {number}
   */
  getFloatValue(): number {
    return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
  };


  /** @param {number} value */
  setFloatValue(value: number) {
    jspb.Message.setOneofField(this, 2, StreamFilterType.oneofGroups_[0], value);
  }


  /**
   * Clears the field making it undefined.
   */
  clearFloatValue() {
    jspb.Message.setOneofField(this, 2, StreamFilterType.oneofGroups_[0], undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasFloatValue(): boolean {
    return jspb.Message.getField(this, 2) != null;
  }


  /**
   * optional int32 int32_value = 3;
   * @return {number}
   */
  getInt32Value(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
  };


  /** @param {number} value */
  setInt32Value(value: number) {
    jspb.Message.setOneofField(this, 3, StreamFilterType.oneofGroups_[0], value);
  }


  /**
   * Clears the field making it undefined.
   */
  clearInt32Value() {
    jspb.Message.setOneofField(this, 3, StreamFilterType.oneofGroups_[0], undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasInt32Value(): boolean {
    return jspb.Message.getField(this, 3) != null;
  }


  /**
   * optional uint32 uint32_value = 4;
   * @return {number}
   */
  getUint32Value(): number {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
  };


  /** @param {number} value */
  setUint32Value(value: number) {
    jspb.Message.setOneofField(this, 4, StreamFilterType.oneofGroups_[0], value);
  }


  /**
   * Clears the field making it undefined.
   */
  clearUint32Value() {
    jspb.Message.setOneofField(this, 4, StreamFilterType.oneofGroups_[0], undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasUint32Value(): boolean {
    return jspb.Message.getField(this, 4) != null;
  }


  /**
   * optional bool bool_value = 5;
   * @return {boolean}
   */
  getBoolValue(): boolean {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
  };


  /** @param {boolean} value */
  setBoolValue(value: boolean) {
    jspb.Message.setOneofField(this, 5, StreamFilterType.oneofGroups_[0], value);
  }


  /**
   * Clears the field making it undefined.
   */
  clearBoolValue() {
    jspb.Message.setOneofField(this, 5, StreamFilterType.oneofGroups_[0], undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasBoolValue(): boolean {
    return jspb.Message.getField(this, 5) != null;
  }


  /**
   * optional string string_value = 6;
   * @return {string}
   */
  getStringValue(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
  };


  /** @param {string} value */
  setStringValue(value: string) {
    jspb.Message.setOneofField(this, 6, StreamFilterType.oneofGroups_[0], value);
  }


  /**
   * Clears the field making it undefined.
   */
  clearStringValue() {
    jspb.Message.setOneofField(this, 6, StreamFilterType.oneofGroups_[0], undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasStringValue(): boolean {
    return jspb.Message.getField(this, 6) != null;
  }


  /**
   * optional StreamFilterOp op = 7;
   * @return {?StreamFilterOp}
   */
  getOp(): StreamFilterOp {
    return /** @type{?StreamFilterOp} */ (
      jspb.Message.getWrapperField(this, StreamFilterOp, 7));
  }


  /** @param {?StreamFilterOp|undefined} value */
  setOp(value?: StreamFilterOp) {
    jspb.Message.setOneofWrapperField(this, 7, StreamFilterType.oneofGroups_[0], value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearOp() {
    this.setOp(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasOp(): boolean {
    return jspb.Message.getField(this, 7) != null;
  }


}
export namespace StreamFilterType {
export enum ValuesCase {
  VALUES_NOT_SET = 0,
  DOUBLE_VALUE = 1,
  FLOAT_VALUE = 2,
  INT32_VALUE = 3,
  UINT32_VALUE = 4,
  BOOL_VALUE = 5,
  STRING_VALUE = 6,
  OP = 7,
}
} // namespace StreamFilterType
export namespace StreamFilter {
  export interface AsObject {
    keyValuesMap: [string, StreamFilterType.AsObject][];
  }
}
export class StreamFilter extends jspb.Message {
  static readonly displayName = "StreamFilter";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): StreamFilter.AsObject {
    return StreamFilter.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!StreamFilter} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: StreamFilter) {
    var f: any;
    var obj: any = {
      keyValuesMap: (f = msg.getKeyValuesMap()) ? f.toObject(includeInstance, StreamFilterType.toObject) : []
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!StreamFilter}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new StreamFilter;
    return StreamFilter.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!StreamFilter} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!StreamFilter}
   */
  static deserializeBinaryFromReader(msg: StreamFilter, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = msg.getKeyValuesMap();
        reader.readMessage(value1, function(message, reader) {
          jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, StreamFilterType.deserializeBinaryFromReader, "");
           });
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!StreamFilter} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: StreamFilter, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getKeyValuesMap(true);
    if (f && f.getLength() > 0) {
      f.serializeBinary(1, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, StreamFilterType.serializeBinaryToWriter);
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    StreamFilter.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * map<string, StreamFilterType> key_values = 1;
   * @param {boolean=} opt_noLazyCreate Do not create the map if
   * empty, instead returning `undefined`
   * @return {!jspb.Map<string,!StreamFilterType>}
   */
  getKeyValuesMap(): jspb.Map<string,StreamFilterType>;
  getKeyValuesMap(noLazyCreate: true): jspb.Map<string,StreamFilterType>|undefined;
  getKeyValuesMap(noLazyCreate: false): jspb.Map<string,StreamFilterType>;
  getKeyValuesMap(opt_noLazyCreate: boolean = false): jspb.Map<string,StreamFilterType> {
    return /** @type {!jspb.Map<string,!StreamFilterType>} */ (
        jspb.Message.getMapField(this, 1, opt_noLazyCreate,
        StreamFilterType));
  }


  /**
   * Clears values from the map. The map will be non-null.
   */
  clearKeyValuesMap() {
    this.getKeyValuesMap().clear();
  }


}
export namespace StreamControl {
  export interface AsObject {
    action?: StreamAction;
    streamId?: StreamID.AsObject;
    streamFilter?: StreamFilter.AsObject;
  }
}
export class StreamControl extends jspb.Message {
  static readonly displayName = "StreamControl";
  /**
   * Oneof group definitions for this message. Each group defines the field
   * numbers belonging to that group. When of these fields' value is set, all
   * other fields in the group are cleared. During deserialization, if multiple
   * fields are encountered for a group, only the last value seen will be kept.
   * @private {!Array<!Array<number>>}
   * @const
   */
  static readonly oneofGroups_ = [[2,3]];


  /**
   * @return {StreamControl.InfoCase}
   */
  getInfoCase() {
    return /** @type {StreamControl.InfoCase} */(jspb.Message.computeOneofCase(this, StreamControl.oneofGroups_[0]));
  }

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, StreamControl.oneofGroups_);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): StreamControl.AsObject {
    return StreamControl.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!StreamControl} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: StreamControl) {
    var f: any;
    var obj: any = {
      action: jspb.Message.getFieldWithDefault(msg, 1, 0),
      streamId: (f = msg.getStreamId()) && StreamID.toObject(includeInstance, f),
      streamFilter: (f = msg.getStreamFilter()) && StreamFilter.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!StreamControl}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new StreamControl;
    return StreamControl.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!StreamControl} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!StreamControl}
   */
  static deserializeBinaryFromReader(msg: StreamControl, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {!StreamAction} */ (reader.readEnum());
        msg.setAction(value1);
        break;
      case 2:
        var value2 = new StreamID;
        reader.readMessage(value2,StreamID.deserializeBinaryFromReader);
        msg.setStreamId(value2);
        break;
      case 3:
        var value3 = new StreamFilter;
        reader.readMessage(value3,StreamFilter.deserializeBinaryFromReader);
        msg.setStreamFilter(value3);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!StreamControl} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: StreamControl, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getAction();
    if (f !== 0.0) {
      writer.writeEnum(
        1,
        f
      );
    }
    f = message.getStreamId();
    if (f != null) {
      writer.writeMessage(
        2,
        f,
        StreamID.serializeBinaryToWriter
      );
    }
    f = message.getStreamFilter();
    if (f != null) {
      writer.writeMessage(
        3,
        f,
        StreamFilter.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    StreamControl.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional StreamAction action = 1;
   * @return {!StreamAction}
   */
  getAction(): StreamAction {
    return /** @type {!StreamAction} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
  };


  /** @param {!StreamAction} value */
  setAction(value: StreamAction) {
    jspb.Message.setProto3EnumField(this, 1, value);
  }


  /**
   * optional StreamID stream_id = 2;
   * @return {?StreamID}
   */
  getStreamId(): StreamID {
    return /** @type{?StreamID} */ (
      jspb.Message.getWrapperField(this, StreamID, 2));
  }


  /** @param {?StreamID|undefined} value */
  setStreamId(value?: StreamID) {
    jspb.Message.setOneofWrapperField(this, 2, StreamControl.oneofGroups_[0], value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearStreamId() {
    this.setStreamId(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasStreamId(): boolean {
    return jspb.Message.getField(this, 2) != null;
  }


  /**
   * optional StreamFilter stream_filter = 3;
   * @return {?StreamFilter}
   */
  getStreamFilter(): StreamFilter {
    return /** @type{?StreamFilter} */ (
      jspb.Message.getWrapperField(this, StreamFilter, 3));
  }


  /** @param {?StreamFilter|undefined} value */
  setStreamFilter(value?: StreamFilter) {
    jspb.Message.setOneofWrapperField(this, 3, StreamControl.oneofGroups_[0], value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearStreamFilter() {
    this.setStreamFilter(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasStreamFilter(): boolean {
    return jspb.Message.getField(this, 3) != null;
  }


}
export namespace StreamControl {
export enum InfoCase {
  INFO_NOT_SET = 0,
  STREAM_ID = 2,
  STREAM_FILTER = 3,
}
} // namespace StreamControl
export namespace StreamControlResponse {
  export interface AsObject {
    status?: common_legacy_pb.StatusCode;
    streamStatus?: StreamStatus;
    streamId?: StreamID.AsObject;
  }
}
export class StreamControlResponse extends jspb.Message {
  static readonly displayName = "StreamControlResponse";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): StreamControlResponse.AsObject {
    return StreamControlResponse.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!StreamControlResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: StreamControlResponse) {
    var f: any;
    var obj: any = {
      status: jspb.Message.getFieldWithDefault(msg, 1, 0),
      streamStatus: jspb.Message.getFieldWithDefault(msg, 3, 0),
      streamId: (f = msg.getStreamId()) && StreamID.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!StreamControlResponse}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new StreamControlResponse;
    return StreamControlResponse.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!StreamControlResponse} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!StreamControlResponse}
   */
  static deserializeBinaryFromReader(msg: StreamControlResponse, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {!StatusCode} */ (reader.readEnum());
        msg.setStatus(value1);
        break;
      case 3:
        var value3 = /** @type {!StreamStatus} */ (reader.readEnum());
        msg.setStreamStatus(value3);
        break;
      case 2:
        var value2 = new StreamID;
        reader.readMessage(value2,StreamID.deserializeBinaryFromReader);
        msg.setStreamId(value2);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!StreamControlResponse} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: StreamControlResponse, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getStatus();
    if (f !== 0.0) {
      writer.writeEnum(
        1,
        f
      );
    }
    f = message.getStreamStatus();
    if (f !== 0.0) {
      writer.writeEnum(
        3,
        f
      );
    }
    f = message.getStreamId();
    if (f != null) {
      writer.writeMessage(
        2,
        f,
        StreamID.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    StreamControlResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional StatusCode status = 1;
   * @return {!StatusCode}
   */
  getStatus(): common_legacy_pb.StatusCode {
    return /** @type {!StatusCode} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
  };


  /** @param {!StatusCode} value */
  setStatus(value: common_legacy_pb.StatusCode) {
    jspb.Message.setProto3EnumField(this, 1, value);
  }


  /**
   * optional StreamStatus stream_status = 3;
   * @return {!StreamStatus}
   */
  getStreamStatus(): StreamStatus {
    return /** @type {!StreamStatus} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
  };


  /** @param {!StreamStatus} value */
  setStreamStatus(value: StreamStatus) {
    jspb.Message.setProto3EnumField(this, 3, value);
  }


  /**
   * optional StreamID stream_id = 2;
   * @return {?StreamID}
   */
  getStreamId(): StreamID {
    return /** @type{?StreamID} */ (
      jspb.Message.getWrapperField(this, StreamID, 2));
  }


  /** @param {?StreamID|undefined} value */
  setStreamId(value?: StreamID) {
    jspb.Message.setWrapperField(this, 2, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearStreamId() {
    this.setStreamId(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasStreamId(): boolean {
    return jspb.Message.getField(this, 2) != null;
  }


}
export enum StreamStatus {
  STREAM_OK = 0,
  STREAM_EXHAUSTED = 1,
  STREAM_EXHAUSTED_FRONT = 2,
  STREAM_EXHAUSTED_BACK = 3,
}
export enum StreamAction {
  NOOP = 0,
  SEEK_FRONT = 1,
  SEEK_BACK = 2,
  PAUSE = 3,
  RESUME = 4,
  CANCEL = 5,
  SEEK_ANY = 6,
  COUNT = 7,
  RESTART = 8,
}
export enum StreamItemPosition {
  NONE = 0,
  UPSERT = 1,
  PUSH_FRONT = 2,
  PUSH_BACK = 3,
}
