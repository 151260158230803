import { Injectable } from '@angular/core';

import { UpdateConsequences, IConsequence } from 'libs/domain';
import {
  pbis_ng_grpc_pb,
  pbis_pb as pbis_proto,
} from 'libs/generated-grpc-web';
import { ConsequenceMapper, UpdateConsequenceMapper } from 'libs/shared-grpc';

@Injectable({ providedIn: 'root' })
export class ConsequenceService {
  constructor(private _pbisManager: pbis_ng_grpc_pb.PbisManager) {}

  public async createConsequences(
    consequences: UpdateConsequences,
    listId?: number,
  ): Promise<IConsequence[]> {
    const request = new pbis_proto.CreateConsequenceRequest();

    request.setConsequences(UpdateConsequenceMapper.toProto(consequences));
    request.setListId(listId);

    const result = await this._pbisManager.createConsequences(request);
    return result
      .getConsequenceList()
      .map(msg => ConsequenceMapper.fromProto(msg));
  }
}
