import { Validators } from '@angular/forms';

import * as day from 'dayjs';
import { AutomationAutoResetFrequency } from 'libs/domain';

type Options<T> = Array<{
  label: string;
  value: T;
}>;

export enum EditAutomationGroupMessages {
  MODAL_TITLE_CREATE = 'Create Automation Group',
  MODAL_TITLE_EDIT = 'Edit Automation Group',

  INVALID_FORM_MESSAGE = 'Please complete the missing information(s)',
  BEHAVIORS_WARNING_MESSAGE = 'One or more selected behaviors are currently deactivated and can’t be used',
  FIELD_GROUP_NAME = 'Automation group name (eg. Tardy Policy)',
  FIELD_INTERNAL_DESC = 'Internal description (optional)',
  FIELD_BEHAVIOR = 'Behavior',
  FIELDSET_BEHAVIOR_TRIGGERING = 'Behavior triggering automation',
  FIELDSET_FREQUENCY_RESET = 'Reset counter automatically',
  FIELDSET_FREQUENCY_RESET_DESC = 'All students will be reset back to 0 for the behaviors triggering the automation',
  FIELD_AUTO_RESET_FREQUENCY = 'Frequency (option)',
  FIELD_AUTO_RESET_DAY = 'Day',
  FIELD_AUTO_RESET_TIME = 'Time',
  FIELD_AUTO_RESET_CUSTOM_DATES = 'Dates',
  FIELD_AUTO_RESET_DAILY_DESC = 'The counter will be reset at 12am every day',
  FIELD_AUTO_RESET_WEEKLY_DESC = 'The counter will be reset at 12am every Sunday',
  FIELD_AUTO_RESET_MONTHLY_DESC = 'The counter will be reset at 12am every first of the month',
  FIELDSET_MANUAL_COUNTER_RESET = 'Manual automation counter reset',
  FIELDSET_MANUAL_COUNTER_RESET_DESC = 'Reset the behavior(s) count triggering the automations back to 0 or update to a specific value',

  DELETE_CONFIRM_TITLE = 'Are you sure you want to delete this automation group?',
  DELETE_CONFIRM_DISCARD_TITLE = 'Are you sure you want to discard this this automation group?',
  DELETE_CONFIRM_DISCARD_DESC = 'You will lose any unsaved changes',
  DELETE_CONFIRM_CANCEL_BTN = 'Cancel',
  DELETE_CONFIRM_DELETE_BTN = 'Delete',
  DELETE_CONFIRM_DISCARD_BTN = 'Discard',
  MANUAL_COUNTER_RESET_BTN = 'Reset / update',
  MANUAL_COUNTER_ALL_STUDENT_BTN = 'All students',
  MANUAL_COUNTER_STUDENT_BTN = 'Select student(s)',
}

export enum EditAutomationFormFields {
  GROUP_NAME = 'name',
  INTERNAL_DESC = 'description',
  BEHAVIOR = 'triggers',
  ENABLE_AUTO_RESET = 'resetCounter',
  AUTO_RESET_FREQUENCY = 'autoResetFrequency',
  AUTO_RESET_TIME = 'autoResetTime',
  AUTOR_RESET_CUSTOM_DATES = 'autoResetCustomDates',
}

export const EDIT_AUTOMATIONS_FORMGROUP = {
  [EditAutomationFormFields.GROUP_NAME]: [null, [Validators.required]],
  [EditAutomationFormFields.INTERNAL_DESC]: [null],
  [EditAutomationFormFields.BEHAVIOR]: [null, [Validators.required]],
  [EditAutomationFormFields.ENABLE_AUTO_RESET]: [false, [Validators.required]],
  [EditAutomationFormFields.AUTO_RESET_FREQUENCY]: [
    AutomationAutoResetFrequency.CUSTOM,
    [Validators.required],
  ],
  [EditAutomationFormFields.AUTO_RESET_TIME]: [day().format('HH:mm')],
  [EditAutomationFormFields.AUTOR_RESET_CUSTOM_DATES]: [null],
};

export const FREQUENCY_OPTIONS: Options<AutomationAutoResetFrequency> = [
  { label: 'Daily', value: AutomationAutoResetFrequency.DAILY },
  { label: 'Weekly', value: AutomationAutoResetFrequency.WEEKLY },
  { label: 'Monthly', value: AutomationAutoResetFrequency.MONTHLY },
  { label: 'Custom', value: AutomationAutoResetFrequency.CUSTOM },
];
