import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';

import { MgEmptyStateModule } from '@app/src/app/components/EmptyState';
import { MgIconModule } from '@app/src/app/icon';
import { MgSpinnerModule } from '@app/src/app/spinner';

import { GenericButtonModule } from '@shared/components/button';
import { CountTileModule } from '@shared/components/count-tile';
import { FormModule } from '@shared/components/form';
import { GenericModule } from '@shared/components/generic';
import { TextModule } from '@shared/components/text';
import { TilesCarouselModule } from '@shared/components/tiles-carousel';

import { CheckinManagerDashboardComponent } from './checkin-manager-dashboard.component';

@NgModule({
  imports: [
    // Minga dependencies
    MgIconModule,
    TilesCarouselModule,
    MgSpinnerModule,
    MgEmptyStateModule,
    CountTileModule,
    GenericButtonModule,
    GenericModule,
    FormModule,
    TextModule,

    // External dependencies
    CommonModule,
    FlexLayoutModule,
  ],
  declarations: [CheckinManagerDashboardComponent],
  exports: [CheckinManagerDashboardComponent],
})
export class CheckinManagerDashboardModule {}
