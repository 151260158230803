import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { legacy_pb } from 'libs/generated-grpc-web';
import { ReplaySubject } from 'rxjs';

import { AppConfigService } from '@app/src/app/minimal/services/AppConfig';
import { AuthService } from '@app/src/app/minimal/services/Auth';
import { RootService } from '@app/src/app/minimal/services/RootService';

import { SystemAlertSnackBarService } from '@shared/components/system-alert-snackbar';

import { LandingService } from '../../services';

@Component({
  selector: 'mg-landing-reset',
  templateUrl: './landing-reset.component.html',
  styleUrls: ['./landing-reset.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LandingResetComponent implements OnInit, OnDestroy {
  // Clean up

  private readonly _destroyedSubject = new ReplaySubject<void>(1);

  // State
  error = '';

  /** Component constructor */
  constructor(
    public landingService: LandingService,
    private _route: ActivatedRoute,
    private _router: Router,
    private _systemAlertSnackBar: SystemAlertSnackBarService,
    private _rootService: RootService,
    private _authService: AuthService,
    private _appConfigService: AppConfigService,
  ) {}

  ngOnInit(): void {
    this._route.params.subscribe(async params => {
      const resetToken = params.resetToken || '';
      const promise = this._handleToken(resetToken).catch(() => {
        this.error = 'Unable to reset password. Try again later.';
      });

      await this._rootService.addLoadingPromise(promise);
    });
  }

  ngOnDestroy(): void {
    this._destroyedSubject.next();
    this._destroyedSubject.complete();
  }

  public clickExit() {
    this.landingService.passwordToken = '';
    if (window.history && window.history.length > 1) {
      history.back();
    } else {
      window.close();
    }
  }

  private async _handleToken(resetToken: string) {
    if (!resetToken) {
      this._systemAlertSnackBar.error('Empty reset token');
      return this._router.navigateByUrl('/landing');
    }
    this.landingService.passwordToken = resetToken;
    // make sure we have a country set so this request
    // goes to the right place.
    await this._appConfigService.ensureCountrySet();

    const response = await this._authService.resetPassword(resetToken);
    const status = response.getStatus();

    if (status === legacy_pb.StatusCode.OK) {
      this._systemAlertSnackBar.success('Password reset successfully');
      return this._router.navigate(['/landing', 'create', resetToken], {
        replaceUrl: true,
      });
    } else {
      this.error = 'Invalid or expired reset token.';
    }
  }
}
