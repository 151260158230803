import { Directionality } from '@angular/cdk/bidi';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  Optional,
  Output,
} from '@angular/core';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MatDateFormats,
} from '@angular/material/core';
import { MatCalendar, MatDatepickerIntl } from '@angular/material/datepicker';

import { Dayjs } from 'dayjs';
import {
  eventCategoryColor,
  EventCategoryEnum,
  eventCategoryPrettyText,
} from 'libs/shared';
import { Subscription } from 'rxjs';

import {
  ContentEvents,
  IContentCreateSuccessEvent,
  IContentEventCodeCheckInSuccessEvent,
  IContentEventGoingEvent,
} from '@app/src/app/minimal/services/ContentEvents';
import {
  CALENDAR_EVENT_FILTER,
  EventContentService,
  ICalendarSelectDay,
} from '@app/src/app/minimal/services/EventContent';

@Component({
  selector: 'mg-calendar',
  templateUrl: './Calendar.component.html',
  styleUrls: ['./Calendar.component.scss'],
})
export class Calendar<D>
  extends MatCalendar<D>
  implements OnDestroy, OnChanges
{
  dateAdapter: DateAdapter<D>;
  weekViewHideAll: boolean = true;
  subscribeNewDays: ICalendarSelectDay[] = [];
  filteringDays: ICalendarSelectDay[] = [];

  private _contentEventGoingSub?: Subscription;
  private _contentEventCreateSub?: Subscription;
  private _contentEventCheckInSub?: Subscription;
  private _contentEventCheckInSuccessSub?: Subscription;
  private _unFilterEventSub?: Subscription;
  private _dateFilter: CALENDAR_EVENT_FILTER = CALENDAR_EVENT_FILTER.ALL;
  private _todayMorning: Date;

  _weekView: boolean = true;
  @Input()
  get weekView(): boolean {
    return this._weekView;
  }
  set weekView(value: boolean) {
    this._weekView = value;
    // console.log('weekview is now set to : ' + value);
    this.weekViewChange.emit(value);
    this._animateWeekViewHiding();
  }
  @Output()
  weekViewChange: EventEmitter<any> = new EventEmitter();

  @Input()
  multiSelectDays: ICalendarSelectDay[] = [];

  @Input()
  get filter(): CALENDAR_EVENT_FILTER {
    return this._dateFilter;
  }
  set filter(value: CALENDAR_EVENT_FILTER) {
    this._dateFilter = value;
  }

  @Input()
  get activeViewDate(): D | null {
    return this.activeDate;
  }
  set activeViewDate(value: D | null) {
    this.activeDate = value || this.activeDate;
  }

  @Output()
  onDateClick: EventEmitter<Date> = new EventEmitter();

  constructor(
    _elementRef: ElementRef,
    _intl: MatDatepickerIntl,
    _ngZone: NgZone,
    private contentEvents: ContentEvents,
    private eventContent: EventContentService,
    @Optional() _dateAdapter: DateAdapter<D>,
    @Optional() @Inject(MAT_DATE_FORMATS) _dateFormats: MatDateFormats,
    _changeDetectorRef: ChangeDetectorRef,
    @Optional() _dir?: Directionality,
  ) {
    super(_intl, _dateAdapter, _dateFormats, _changeDetectorRef);

    this._contentEventGoingSub =
      this.contentEvents.onContentEventGoing.subscribe(ev => {
        this._onContentEvent(ev, null, null);
      });

    this._contentEventCreateSub =
      this.contentEvents.onPostCreateSuccess.subscribe(ev => {
        this._onContentEvent(null, ev, null);
      });

    this._contentEventCheckInSub =
      this.contentEvents.onContentEventCheckIn.subscribe(ev => {
        this._onContentEvent(ev, null, null);
      });

    this._contentEventCheckInSuccessSub =
      this.contentEvents.onContentEventCodeCheckInSuccess.subscribe(ev =>
        this._onContentEvent(null, null, ev),
      );

    this._unFilterEventSub = this.eventContent.onUnFilterEvents.subscribe(
      ev => {
        this.filteringDays = [];
      },
    );

    this._todayMorning = new Date();
    this._todayMorning.setHours(0);
    this._todayMorning.setMinutes(0);
    this._todayMorning.setSeconds(0);
    this._todayMorning.setMilliseconds(0);
  }

  ngOnDestroy() {
    this._contentEventGoingSub.unsubscribe();
    this._contentEventCreateSub.unsubscribe();
    if (this._unFilterEventSub) {
      this._unFilterEventSub.unsubscribe();
    }
  }

  get selectedDays() {
    return this.multiSelectDays.concat(this.subscribeNewDays);
  }

  private _onContentEvent(
    ev: IContentEventGoingEvent | null,
    evPost: IContentCreateSuccessEvent | null,
    evCodeCheckIn: IContentEventCodeCheckInSuccessEvent | null,
  ) {
    let newDay: ICalendarSelectDay = null;
    let dateTime = Date.now();

    if (ev && ev.date) {
      dateTime = ev.date.getTime();

      newDay = {
        date: ev.date,
        going: ev.going,
        past: Date.now() > dateTime,
        checkedIn: !!ev.checkedIn,
        endDate: ev.endDate || null,
        color: ev.color || eventCategoryColor(EventCategoryEnum.OTHER),
      };
    } else if (evPost && evPost.typeString === 'event' && evPost.date) {
      dateTime = evPost.date.getTime();
      newDay = {
        date: evPost.date,
        going: false,
        past: Date.now() > dateTime,
        endDate: evPost.endDate || null,
        color: evPost.color || eventCategoryColor(EventCategoryEnum.OTHER),
      };
    } else if (evCodeCheckIn && evCodeCheckIn.date) {
      dateTime = evCodeCheckIn.date.getTime();
      newDay = {
        date: evCodeCheckIn.date,
        going: true,
        checkedIn: true,
        past: Date.now() > dateTime,
        endDate: evCodeCheckIn.endDate || null,
        color: ev.color || eventCategoryColor(EventCategoryEnum.OTHER),
      };
    }

    if (newDay) {
      for (let i = 0; i < this.subscribeNewDays.length; ++i) {
        const day = this.subscribeNewDays[i];
        if (day.date.getTime() == dateTime) {
          this.subscribeNewDays[i] = newDay;
          return;
        }
      }
      this.subscribeNewDays.push(newDay);

      if (ev && !ev.going) {
        // leaving an event, that wasn't in the subscribeNewDays array
        // find the day and update it
        for (let i = 0; i < this.multiSelectDays.length; ++i) {
          const event = this.multiSelectDays[i];
          if (event.date.getTime() == dateTime && event.going) {
            this.multiSelectDays[i].going = false;
            return;
          }
        }
      }
    }
  }

  onSelectedChange(e: Dayjs) {
    const eventTimestamp = e.valueOf();

    // upcoming doesn't allow past
    if (
      this._dateFilter == CALENDAR_EVENT_FILTER.UPCOMING &&
      eventTimestamp < this._todayMorning.getTime()
    ) {
      return;
      // past doesn't allow upcoming
    } else if (
      this._dateFilter == CALENDAR_EVENT_FILTER.PAST &&
      eventTimestamp > this._todayMorning.getTime()
    ) {
      return;
    }

    const selectedDate: Date = e.toDate();

    this.onDateClick.emit(selectedDate);

    let emitParams = {
      date: selectedDate,
      going: false,
    };

    let filterDay: ICalendarSelectDay = {
      going: false,
      date: selectedDate,
      color: eventCategoryColor(EventCategoryEnum.OTHER),
    };

    for (let event of this.multiSelectDays) {
      if (event.date.getTime() == eventTimestamp) {
        emitParams.going = true;
        filterDay.going = true;
        break;
      }
    }

    // @TODO: support multiple days for filtering
    this.filteringDays = [filterDay];

    // Filter one day event, received on the event component with that stream
    this.eventContent.emitFilterEvent(emitParams);
  }

  getCalendarClasses() {
    let calandarClass = 'all';

    switch (this.filter) {
      case CALENDAR_EVENT_FILTER.GOING:
        calandarClass = 'going';
        break;
      case CALENDAR_EVENT_FILTER.PAST:
        calandarClass = 'past';
        break;
      case CALENDAR_EVENT_FILTER.UPCOMING:
        calandarClass = 'upcoming';
      default:
    }
    return calandarClass;
  }

  private _animateWeekViewHiding() {
    if (this.weekView) {
      setTimeout(() => {
        this.weekViewHideAll = true;
      }, 300);
    } else {
      this.weekViewHideAll = false;
    }
  }

  toggleExpansion() {
    this.weekView = !this.weekView;

    this._animateWeekViewHiding();
  }
}
