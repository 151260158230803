import {
  Notifications,
  NotificationsState,
} from '@app/src/app/store/Notifications/selectors';

import { NotificationActions } from '../actions/notifications.actions';

/**
 * Reducer for the notifications store.
 *
 * @param state
 * @param action
 */
export const notificationsReducer = (
  state: Notifications = NotificationsState.initialState,
  action: NotificationActions.TypeUnion,
): Notifications => {
  switch (action.type) {
    case NotificationActions.TypeEnum.LoadNotifications: {
      return NotificationsState.entityAdapter.setAll(action.payload, state);
    }
    case NotificationActions.TypeEnum.RemoveNotifications: {
      return NotificationsState.entityAdapter.removeMany(action.payload, state);
    }
    case NotificationActions.TypeEnum.AddNotification: {
      return NotificationsState.entityAdapter.addOne(action.payload, state);
    }
    default: {
      return state;
    }
  }
};
