import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'map' })
export class ArrayMapPipe implements PipeTransform {
  transform<T, R>(
    array: T[] | null,
    callback: (item: T, index: number, array: T[]) => R,
  ): R[] {
    if (!array || !Array.isArray(array) || typeof callback !== 'function') {
      return [];
    }

    return array.map(callback);
  }
}
