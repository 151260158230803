import { IMingaSubscription } from 'libs/domain';
import { gateway } from 'libs/generated-grpc-ts';
import { wrappers_pb } from 'libs/generated-grpc-ts';

export namespace MingaSubscriptionProtoMapper {
  export function toIMingaSubscription(
    msg: gateway.minga_pb.MingaSubscription,
  ) {
    const mingaSubscription: IMingaSubscription = {
      id: msg.getId(),
      planId: msg.getPlanId(),
      name: msg.getName(),
      planUrl: msg.getPlanUrl(),
      annualFee: msg.getAnnualFee(),
      premiumPrograms: msg.getPremiumPrograms(),
      premiumStudioTemplates: msg.getPremiumStudioTemplates(),
      mingaSize: msg.hasMingaSize() ? msg.getMingaSize().getValue() : null,
      groupSize: msg.hasGroupSize() ? msg.getGroupSize().getValue() : null,
      adminUserSize: msg.hasAdminUserSize()
        ? msg.getAdminUserSize().getValue()
        : null,
      pricedPerDefaultRole: msg.getPricedPerDefaultRole(),
      annualSmsMessageQuota: msg.getAnnualSmsMessageQuota(),
    };

    return mingaSubscription;
  }

  export function fromIMingaSubscription(obj: IMingaSubscription) {
    const message = new gateway.minga_pb.MingaSubscription();
    message.setPlanId(obj.planId);
    message.setName(obj.name);
    message.setMingaSizeDeprecated(obj.mingaSize || 0);

    // convert annual fee to a month fee if possible, for legacy purposes
    const annualValue = parseInt(obj.annualFee);
    if (annualValue && !isNaN(annualValue)) {
      message.setMonthlyFee(Math.round(annualValue / 12));
    }

    message.setPlanUrl(obj.planUrl);
    message.setAnnualFee(obj.annualFee);
    message.setId(obj.id);

    if (obj.mingaSize !== null) {
      const wrapper = new wrappers_pb.Int32Value();
      wrapper.setValue(obj.mingaSize);
      message.setMingaSize(wrapper);
    }

    if (obj.groupSize !== null) {
      const wrapper = new wrappers_pb.Int32Value();
      wrapper.setValue(obj.groupSize);
      message.setGroupSize(wrapper);
    }

    if (obj.adminUserSize !== null) {
      const wrapper = new wrappers_pb.Int32Value();
      wrapper.setValue(obj.adminUserSize);
      message.setAdminUserSize(wrapper);
    }

    message.setPremiumPrograms(obj.premiumPrograms);
    message.setPremiumStudioTemplates(obj.premiumStudioTemplates);
    message.setPricedPerDefaultRole(obj.pricedPerDefaultRole);

    return message;
  }
}
