import { MingaAppMainRoutes } from '@app/src/app/types';

import {
  LayoutAccessDeniedComponent,
  LayoutPageNotFoundComponent,
  LayoutServerErrorComponent,
} from '@modules/layout';

export const ERROR_ROUTES: MingaAppMainRoutes = [
  {
    path: 'access-denied',
    component: LayoutAccessDeniedComponent,
    data: {
      title: 'Error 403',
      description:
        'You do not have the required permissions to access this page.',
      navigationVisibility: false,
    },
  },
  // Not found page
  {
    path: 'not-found',
    component: LayoutPageNotFoundComponent,
    data: {
      title: 'Error 404',
      description: 'Page not found',
      navigationVisibility: false,
    },
  },
  // Server error page
  {
    path: 'server-error',
    component: LayoutServerErrorComponent,
    data: {
      title: 'Error 500',
      description: 'Server error',
      navigationVisibility: false,
    },
  },
  //Wildcard route
  {
    path: '**',
    pathMatch: 'full',
    redirectTo: 'not-found',
  },
];
