import { MingaStatus } from '@modules/minga-manager/constants';

import { FormSelectOption } from '@shared/components/form';

import { MmDashboardFilters } from '../types/mm-dashboard.types';

export enum MmDashboardFilter {
  SEARCH_QUERY = 'searchQuery',
  MODULE = 'module',
  DISTRICT = 'district',
  STATE = 'state',
  STATUS = 'status',
  PARTNER = 'partner',
  SUBSCRIPTION = 'subscription',
  PAUSED = 'paused',
  START_DATE = 'startDate',
  END_DATE = 'endDate',
  SUPPORT_TIER = 'supportTier',
}

export enum MmDashboardStatusFilterValues {
  ACTIVE = 'Active',
  PAUSED = 'Paused',
}

export const MM_DASHBOARD_FILTERS_INITIAL_STATE: MmDashboardFilters = {
  searchQuery: '',
  district: [],
  module: [],
  partner: [],
  state: [],
  status: [MingaStatus.LIVE],
  subscription: ['paid'],
  paused: ['false'],
  supportTier: [],
};

export const MM_DASHBOARD_FILTERS_INITIAL_STATE_NON_SA: MmDashboardFilters = {
  searchQuery: '',
  district: [],
  module: [],
  partner: [],
  state: [],
  status: [],
  subscription: [],
  paused: [],
  supportTier: [],
};

export const STATUS_FILTER_VALUES: FormSelectOption[] = [
  { label: MmDashboardStatusFilterValues.ACTIVE, value: 'false' },
  { label: MmDashboardStatusFilterValues.PAUSED, value: 'true' },
];
