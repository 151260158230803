// GENERATED CODE -- DO NOT EDIT!

import {grpc} from '@improbable-eng/grpc-web';

import * as gateway_comment_pb from '../gateway/comment_pb';
import * as common_legacy_pb from '../common/legacy_pb';
import * as gateway_person_view_pb from '../gateway/person_view_pb';
import * as gateway_mention_pb from '../gateway/mention_pb';
import * as gateway_moderation_pb from '../gateway/moderation_pb';

export class CommentManager {
  static readonly serviceName = "CommentManager";

  static readonly addComment = {
    methodName: "addComment",
    service: CommentManager,
    requestStream: false,
    responseStream: false,
    requestType: gateway_comment_pb.AddCommentRequest,
    responseType: gateway_comment_pb.AddCommentResponse,
  };

  static readonly fetchComments = {
    methodName: "fetchComments",
    service: CommentManager,
    requestStream: false,
    responseStream: false,
    requestType: gateway_comment_pb.FetchCommentsRequest,
    responseType: gateway_comment_pb.FetchCommentsResponse,
  };

}

export class CommentManagerClient {
  addComment() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
  fetchComments() {
    throw new Error('minga_protoc_gen_improbable_eng_grpc_web client methods are unimplemented');
  }
}
