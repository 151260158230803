// source: content/minga.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

import * as jspb from 'google-protobuf';
import * as common_delta_pb from '../common/delta_pb';
import * as common_date_pb from '../common/date_pb';
import * as gateway_moderation_pb from '../gateway/moderation_pb';
export namespace MingaAddProgram {
  export interface AsObject {
    programHash: string;
    programStartDate?: common_date_pb.Date.AsObject;
  }
}
export class MingaAddProgram extends jspb.Message {
  static readonly displayName = "MingaAddProgram";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): MingaAddProgram.AsObject {
    return MingaAddProgram.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!MingaAddProgram} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: MingaAddProgram) {
    var f: any;
    var obj: any = {
      programHash: jspb.Message.getFieldWithDefault(msg, 1, ""),
      programStartDate: (f = msg.getProgramStartDate()) && common_date_pb.Date.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!MingaAddProgram}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new MingaAddProgram;
    return MingaAddProgram.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!MingaAddProgram} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!MingaAddProgram}
   */
  static deserializeBinaryFromReader(msg: MingaAddProgram, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setProgramHash(value1);
        break;
      case 2:
        var value2 = new common_date_pb.Date;
        reader.readMessage(value2,common_date_pb.Date.deserializeBinaryFromReader);
        msg.setProgramStartDate(value2);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!MingaAddProgram} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: MingaAddProgram, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getProgramHash();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
    f = message.getProgramStartDate();
    if (f != null) {
      writer.writeMessage(
        2,
        f,
        common_date_pb.Date.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    MingaAddProgram.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string program_hash = 1;
   * @return {string}
   */
  getProgramHash(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setProgramHash(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


  /**
   * optional minga.common.Date program_start_date = 2;
   * @return {?Date}
   */
  getProgramStartDate(): common_date_pb.Date {
    return /** @type{?Date} */ (
      jspb.Message.getWrapperField(this, common_date_pb.Date, 2));
  }


  /** @param {?Date|undefined} value */
  setProgramStartDate(value?: common_date_pb.Date) {
    jspb.Message.setWrapperField(this, 2, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearProgramStartDate() {
    this.setProgramStartDate(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasProgramStartDate(): boolean {
    return jspb.Message.getField(this, 2) != null;
  }


}
export namespace MingaRemoveProgram {
  export interface AsObject {
    programContextHash: string;
  }
}
export class MingaRemoveProgram extends jspb.Message {
  static readonly displayName = "MingaRemoveProgram";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): MingaRemoveProgram.AsObject {
    return MingaRemoveProgram.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!MingaRemoveProgram} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: MingaRemoveProgram) {
    var f: any;
    var obj: any = {
      programContextHash: jspb.Message.getFieldWithDefault(msg, 1, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!MingaRemoveProgram}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new MingaRemoveProgram;
    return MingaRemoveProgram.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!MingaRemoveProgram} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!MingaRemoveProgram}
   */
  static deserializeBinaryFromReader(msg: MingaRemoveProgram, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setProgramContextHash(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!MingaRemoveProgram} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: MingaRemoveProgram, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getProgramContextHash();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    MingaRemoveProgram.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string program_context_hash = 1;
   * @return {string}
   */
  getProgramContextHash(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setProgramContextHash(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


}
export namespace MingaProgramResult {
  export interface AsObject {
    programContextHash: string;
  }
}
export class MingaProgramResult extends jspb.Message {
  static readonly displayName = "MingaProgramResult";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): MingaProgramResult.AsObject {
    return MingaProgramResult.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!MingaProgramResult} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: MingaProgramResult) {
    var f: any;
    var obj: any = {
      programContextHash: jspb.Message.getFieldWithDefault(msg, 1, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!MingaProgramResult}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new MingaProgramResult;
    return MingaProgramResult.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!MingaProgramResult} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!MingaProgramResult}
   */
  static deserializeBinaryFromReader(msg: MingaProgramResult, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setProgramContextHash(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!MingaProgramResult} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: MingaProgramResult, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getProgramContextHash();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    MingaProgramResult.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string program_context_hash = 1;
   * @return {string}
   */
  getProgramContextHash(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setProgramContextHash(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


}
export namespace MingaAddContent {
  export interface AsObject {
    contentHash: string;
    contentStartDateTime?: common_date_pb.DateTime.AsObject;
    contentEndDateTime?: common_date_pb.DateTime.AsObject;
    contentPinEndDateTime?: common_date_pb.DateTime.AsObject;
  }
}
export class MingaAddContent extends jspb.Message {
  static readonly displayName = "MingaAddContent";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): MingaAddContent.AsObject {
    return MingaAddContent.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!MingaAddContent} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: MingaAddContent) {
    var f: any;
    var obj: any = {
      contentHash: jspb.Message.getFieldWithDefault(msg, 1, ""),
      contentStartDateTime: (f = msg.getContentStartDateTime()) && common_date_pb.DateTime.toObject(includeInstance, f),
      contentEndDateTime: (f = msg.getContentEndDateTime()) && common_date_pb.DateTime.toObject(includeInstance, f),
      contentPinEndDateTime: (f = msg.getContentPinEndDateTime()) && common_date_pb.DateTime.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!MingaAddContent}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new MingaAddContent;
    return MingaAddContent.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!MingaAddContent} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!MingaAddContent}
   */
  static deserializeBinaryFromReader(msg: MingaAddContent, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setContentHash(value1);
        break;
      case 2:
        var value2 = new common_date_pb.DateTime;
        reader.readMessage(value2,common_date_pb.DateTime.deserializeBinaryFromReader);
        msg.setContentStartDateTime(value2);
        break;
      case 3:
        var value3 = new common_date_pb.DateTime;
        reader.readMessage(value3,common_date_pb.DateTime.deserializeBinaryFromReader);
        msg.setContentEndDateTime(value3);
        break;
      case 4:
        var value4 = new common_date_pb.DateTime;
        reader.readMessage(value4,common_date_pb.DateTime.deserializeBinaryFromReader);
        msg.setContentPinEndDateTime(value4);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!MingaAddContent} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: MingaAddContent, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getContentHash();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
    f = message.getContentStartDateTime();
    if (f != null) {
      writer.writeMessage(
        2,
        f,
        common_date_pb.DateTime.serializeBinaryToWriter
      );
    }
    f = message.getContentEndDateTime();
    if (f != null) {
      writer.writeMessage(
        3,
        f,
        common_date_pb.DateTime.serializeBinaryToWriter
      );
    }
    f = message.getContentPinEndDateTime();
    if (f != null) {
      writer.writeMessage(
        4,
        f,
        common_date_pb.DateTime.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    MingaAddContent.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string content_hash = 1;
   * @return {string}
   */
  getContentHash(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setContentHash(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


  /**
   * optional minga.common.DateTime content_start_date_time = 2;
   * @return {?DateTime}
   */
  getContentStartDateTime(): common_date_pb.DateTime {
    return /** @type{?DateTime} */ (
      jspb.Message.getWrapperField(this, common_date_pb.DateTime, 2));
  }


  /** @param {?DateTime|undefined} value */
  setContentStartDateTime(value?: common_date_pb.DateTime) {
    jspb.Message.setWrapperField(this, 2, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearContentStartDateTime() {
    this.setContentStartDateTime(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasContentStartDateTime(): boolean {
    return jspb.Message.getField(this, 2) != null;
  }


  /**
   * optional minga.common.DateTime content_end_date_time = 3;
   * @return {?DateTime}
   */
  getContentEndDateTime(): common_date_pb.DateTime {
    return /** @type{?DateTime} */ (
      jspb.Message.getWrapperField(this, common_date_pb.DateTime, 3));
  }


  /** @param {?DateTime|undefined} value */
  setContentEndDateTime(value?: common_date_pb.DateTime) {
    jspb.Message.setWrapperField(this, 3, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearContentEndDateTime() {
    this.setContentEndDateTime(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasContentEndDateTime(): boolean {
    return jspb.Message.getField(this, 3) != null;
  }


  /**
   * optional minga.common.DateTime content_pin_end_date_time = 4;
   * @return {?DateTime}
   */
  getContentPinEndDateTime(): common_date_pb.DateTime {
    return /** @type{?DateTime} */ (
      jspb.Message.getWrapperField(this, common_date_pb.DateTime, 4));
  }


  /** @param {?DateTime|undefined} value */
  setContentPinEndDateTime(value?: common_date_pb.DateTime) {
    jspb.Message.setWrapperField(this, 4, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearContentPinEndDateTime() {
    this.setContentPinEndDateTime(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasContentPinEndDateTime(): boolean {
    return jspb.Message.getField(this, 4) != null;
  }


}
export namespace MingaRemoveContent {
  export interface AsObject {
    contentContextHash: string;
  }
}
export class MingaRemoveContent extends jspb.Message {
  static readonly displayName = "MingaRemoveContent";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): MingaRemoveContent.AsObject {
    return MingaRemoveContent.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!MingaRemoveContent} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: MingaRemoveContent) {
    var f: any;
    var obj: any = {
      contentContextHash: jspb.Message.getFieldWithDefault(msg, 1, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!MingaRemoveContent}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new MingaRemoveContent;
    return MingaRemoveContent.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!MingaRemoveContent} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!MingaRemoveContent}
   */
  static deserializeBinaryFromReader(msg: MingaRemoveContent, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setContentContextHash(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!MingaRemoveContent} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: MingaRemoveContent, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getContentContextHash();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    MingaRemoveContent.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string content_context_hash = 1;
   * @return {string}
   */
  getContentContextHash(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setContentContextHash(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


}
export namespace MingaContentResult {
  export interface AsObject {
    contentContextHash: string;
    moderationResult?: gateway_moderation_pb.ModerationResult.AsObject;
  }
}
export class MingaContentResult extends jspb.Message {
  static readonly displayName = "MingaContentResult";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): MingaContentResult.AsObject {
    return MingaContentResult.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!MingaContentResult} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: MingaContentResult) {
    var f: any;
    var obj: any = {
      contentContextHash: jspb.Message.getFieldWithDefault(msg, 1, ""),
      moderationResult: (f = msg.getModerationResult()) && gateway_moderation_pb.ModerationResult.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!MingaContentResult}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new MingaContentResult;
    return MingaContentResult.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!MingaContentResult} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!MingaContentResult}
   */
  static deserializeBinaryFromReader(msg: MingaContentResult, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setContentContextHash(value1);
        break;
      case 3:
        var value3 = new gateway_moderation_pb.ModerationResult;
        reader.readMessage(value3,gateway_moderation_pb.ModerationResult.deserializeBinaryFromReader);
        msg.setModerationResult(value3);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!MingaContentResult} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: MingaContentResult, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getContentContextHash();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
    f = message.getModerationResult();
    if (f != null) {
      writer.writeMessage(
        3,
        f,
        gateway_moderation_pb.ModerationResult.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    MingaContentResult.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string content_context_hash = 1;
   * @return {string}
   */
  getContentContextHash(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setContentContextHash(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


  /**
   * optional minga.ModerationResult moderation_result = 3;
   * @return {?ModerationResult}
   */
  getModerationResult(): gateway_moderation_pb.ModerationResult {
    return /** @type{?ModerationResult} */ (
      jspb.Message.getWrapperField(this, gateway_moderation_pb.ModerationResult, 3));
  }


  /** @param {?ModerationResult|undefined} value */
  setModerationResult(value?: gateway_moderation_pb.ModerationResult) {
    jspb.Message.setWrapperField(this, 3, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearModerationResult() {
    this.setModerationResult(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasModerationResult(): boolean {
    return jspb.Message.getField(this, 3) != null;
  }


}
export namespace MingaMoveContent {
  export interface AsObject {
    contentContextHash: string;
    contentStartDateTime?: common_delta_pb.DateTimeDelta.AsObject;
    contentEndDateTime?: common_delta_pb.DateTimeDelta.AsObject;
    contentPinEndDateTime?: common_delta_pb.DateTimeDelta.AsObject;
  }
}
export class MingaMoveContent extends jspb.Message {
  static readonly displayName = "MingaMoveContent";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): MingaMoveContent.AsObject {
    return MingaMoveContent.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!MingaMoveContent} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: MingaMoveContent) {
    var f: any;
    var obj: any = {
      contentContextHash: jspb.Message.getFieldWithDefault(msg, 1, ""),
      contentStartDateTime: (f = msg.getContentStartDateTime()) && common_delta_pb.DateTimeDelta.toObject(includeInstance, f),
      contentEndDateTime: (f = msg.getContentEndDateTime()) && common_delta_pb.DateTimeDelta.toObject(includeInstance, f),
      contentPinEndDateTime: (f = msg.getContentPinEndDateTime()) && common_delta_pb.DateTimeDelta.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!MingaMoveContent}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new MingaMoveContent;
    return MingaMoveContent.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!MingaMoveContent} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!MingaMoveContent}
   */
  static deserializeBinaryFromReader(msg: MingaMoveContent, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setContentContextHash(value1);
        break;
      case 2:
        var value2 = new common_delta_pb.DateTimeDelta;
        reader.readMessage(value2,common_delta_pb.DateTimeDelta.deserializeBinaryFromReader);
        msg.setContentStartDateTime(value2);
        break;
      case 3:
        var value3 = new common_delta_pb.DateTimeDelta;
        reader.readMessage(value3,common_delta_pb.DateTimeDelta.deserializeBinaryFromReader);
        msg.setContentEndDateTime(value3);
        break;
      case 4:
        var value4 = new common_delta_pb.DateTimeDelta;
        reader.readMessage(value4,common_delta_pb.DateTimeDelta.deserializeBinaryFromReader);
        msg.setContentPinEndDateTime(value4);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!MingaMoveContent} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: MingaMoveContent, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getContentContextHash();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
    f = message.getContentStartDateTime();
    if (f != null) {
      writer.writeMessage(
        2,
        f,
        common_delta_pb.DateTimeDelta.serializeBinaryToWriter
      );
    }
    f = message.getContentEndDateTime();
    if (f != null) {
      writer.writeMessage(
        3,
        f,
        common_delta_pb.DateTimeDelta.serializeBinaryToWriter
      );
    }
    f = message.getContentPinEndDateTime();
    if (f != null) {
      writer.writeMessage(
        4,
        f,
        common_delta_pb.DateTimeDelta.serializeBinaryToWriter
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    MingaMoveContent.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string content_context_hash = 1;
   * @return {string}
   */
  getContentContextHash(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setContentContextHash(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


  /**
   * optional minga.common.DateTimeDelta content_start_date_time = 2;
   * @return {?DateTimeDelta}
   */
  getContentStartDateTime(): common_delta_pb.DateTimeDelta {
    return /** @type{?DateTimeDelta} */ (
      jspb.Message.getWrapperField(this, common_delta_pb.DateTimeDelta, 2));
  }


  /** @param {?DateTimeDelta|undefined} value */
  setContentStartDateTime(value?: common_delta_pb.DateTimeDelta) {
    jspb.Message.setWrapperField(this, 2, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearContentStartDateTime() {
    this.setContentStartDateTime(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasContentStartDateTime(): boolean {
    return jspb.Message.getField(this, 2) != null;
  }


  /**
   * optional minga.common.DateTimeDelta content_end_date_time = 3;
   * @return {?DateTimeDelta}
   */
  getContentEndDateTime(): common_delta_pb.DateTimeDelta {
    return /** @type{?DateTimeDelta} */ (
      jspb.Message.getWrapperField(this, common_delta_pb.DateTimeDelta, 3));
  }


  /** @param {?DateTimeDelta|undefined} value */
  setContentEndDateTime(value?: common_delta_pb.DateTimeDelta) {
    jspb.Message.setWrapperField(this, 3, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearContentEndDateTime() {
    this.setContentEndDateTime(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasContentEndDateTime(): boolean {
    return jspb.Message.getField(this, 3) != null;
  }


  /**
   * optional minga.common.DateTimeDelta content_pin_end_date_time = 4;
   * @return {?DateTimeDelta}
   */
  getContentPinEndDateTime(): common_delta_pb.DateTimeDelta {
    return /** @type{?DateTimeDelta} */ (
      jspb.Message.getWrapperField(this, common_delta_pb.DateTimeDelta, 4));
  }


  /** @param {?DateTimeDelta|undefined} value */
  setContentPinEndDateTime(value?: common_delta_pb.DateTimeDelta) {
    jspb.Message.setWrapperField(this, 4, value);
  }


  /**
   * Clears the message field making it undefined.
   */
  clearContentPinEndDateTime() {
    this.setContentPinEndDateTime(undefined);
  }


  /**
   * Returns whether this field is set.
   * @return {boolean}
   */
  hasContentPinEndDateTime(): boolean {
    return jspb.Message.getField(this, 4) != null;
  }


}
export namespace MingaUnpinContent {
  export interface AsObject {
    contentContextHash: string;
  }
}
export class MingaUnpinContent extends jspb.Message {
  static readonly displayName = "MingaUnpinContent";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): MingaUnpinContent.AsObject {
    return MingaUnpinContent.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!MingaUnpinContent} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: MingaUnpinContent) {
    var f: any;
    var obj: any = {
      contentContextHash: jspb.Message.getFieldWithDefault(msg, 1, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!MingaUnpinContent}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new MingaUnpinContent;
    return MingaUnpinContent.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!MingaUnpinContent} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!MingaUnpinContent}
   */
  static deserializeBinaryFromReader(msg: MingaUnpinContent, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setContentContextHash(value1);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!MingaUnpinContent} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: MingaUnpinContent, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getContentContextHash();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    MingaUnpinContent.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string content_context_hash = 1;
   * @return {string}
   */
  getContentContextHash(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setContentContextHash(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


}
export namespace MingaUnpinContentResult {
  export interface AsObject {
  }
}
export class MingaUnpinContentResult extends jspb.Message {
  static readonly displayName = "MingaUnpinContentResult";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): MingaUnpinContentResult.AsObject {
    return MingaUnpinContentResult.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!MingaUnpinContentResult} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: MingaUnpinContentResult) {
    var f: any;
    var obj: any = {

    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!MingaUnpinContentResult}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new MingaUnpinContentResult;
    return MingaUnpinContentResult.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!MingaUnpinContentResult} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!MingaUnpinContentResult}
   */
  static deserializeBinaryFromReader(msg: MingaUnpinContentResult, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!MingaUnpinContentResult} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: MingaUnpinContentResult, writer: jspb.BinaryWriter) {
    var f: any = undefined;
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    MingaUnpinContentResult.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
}
export namespace MingaSetCommentingRolesRequest {
  export interface AsObject {
    contentHash: string;
    allowedCommentingRoleTypesList: string[];
  }
}
export class MingaSetCommentingRolesRequest extends jspb.Message {
  static readonly displayName = "MingaSetCommentingRolesRequest";
  /**
   * List of repeated fields within this message type.
   * @private {!Array<number>}
   * @const
   */
  static readonly repeatedFields_ = [2];

  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, MingaSetCommentingRolesRequest.repeatedFields_, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): MingaSetCommentingRolesRequest.AsObject {
    return MingaSetCommentingRolesRequest.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!MingaSetCommentingRolesRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: MingaSetCommentingRolesRequest) {
    var f: any;
    var obj: any = {
      contentHash: jspb.Message.getFieldWithDefault(msg, 1, ""),
      allowedCommentingRoleTypesList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!MingaSetCommentingRolesRequest}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new MingaSetCommentingRolesRequest;
    return MingaSetCommentingRolesRequest.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!MingaSetCommentingRolesRequest} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!MingaSetCommentingRolesRequest}
   */
  static deserializeBinaryFromReader(msg: MingaSetCommentingRolesRequest, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      case 1:
        var value1 = /** @type {string} */ (reader.readString());
        msg.setContentHash(value1);
        break;
      case 2:
        var value2 = /** @type {string} */ (reader.readString());
        msg.addAllowedCommentingRoleTypes(value2);
        break;
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!MingaSetCommentingRolesRequest} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: MingaSetCommentingRolesRequest, writer: jspb.BinaryWriter) {
    var f: any = undefined;
    f = message.getContentHash();
    if (f.length > 0) {
      writer.writeString(
        1,
        f
      );
    }
    f = message.getAllowedCommentingRoleTypesList();
    if (f.length > 0) {
      writer.writeRepeatedString(
        2,
        f
      );
    }
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    MingaSetCommentingRolesRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
  /**
   * optional string content_hash = 1;
   * @return {string}
   */
  getContentHash(): string {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
  };


  /** @param {string} value */
  setContentHash(value: string) {
    jspb.Message.setProto3StringField(this, 1, value);
  }


  /**
   * repeated string allowed_commenting_role_types = 2;
   * @return {!Array<string>}
   */
  getAllowedCommentingRoleTypesList(): string[] {
    return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
  };


  /** @param {!Array<string>} value */
  setAllowedCommentingRoleTypesList(value: string[]) {
    jspb.Message.setField(this, 2, value || []);
  }


  /**
   * @param {string} value
   * @param {number=} opt_index
   */
  addAllowedCommentingRoleTypes(value: string, opt_index?: number): void {
    jspb.Message.addToRepeatedField(this, 2, value, opt_index);
  }


  /**
   * Clears the list making it empty but non-null.
   */
  clearAllowedCommentingRoleTypesList() {
    this.setAllowedCommentingRoleTypesList([]);
  }


}
export namespace MingaSetCommentingRolesResult {
  export interface AsObject {
  }
}
export class MingaSetCommentingRolesResult extends jspb.Message {
  static readonly displayName = "MingaSetCommentingRolesResult";
  /**
   * Generated by JsPbCodeGenerator.
   * @param {Array=} opt_data Optional initial data array, typically from a
   * server response, or constructed directly in Javascript. The array is used
   * in place and becomes part of the constructed object. It is not cloned.
   * If no data is provided, the constructed object will be empty, but still
   * valid.
   * @extends {jspb.Message}
   * @constructor
   */
  constructor(opt_data?: any) {
    super();
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
  }


  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  toObject(includeInstance?: boolean): MingaSetCommentingRolesResult.AsObject {
    return MingaSetCommentingRolesResult.toObject(includeInstance || false, this);
  }


  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!MingaSetCommentingRolesResult} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static toObject(includeInstance: boolean, msg: MingaSetCommentingRolesResult) {
    var f: any;
    var obj: any = {

    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  }


  /**
   * Deserializes binary data (in protobuf wire format).
   * @param {jspb.ByteSource} bytes The bytes to deserialize.
   * @return {!MingaSetCommentingRolesResult}
   */
  static deserializeBinary(bytes: jspb.ByteSource) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new MingaSetCommentingRolesResult;
    return MingaSetCommentingRolesResult.deserializeBinaryFromReader(msg, reader);
  }

  /**
   * Deserializes binary data (in protobuf wire format) from the
   * given reader into the given message object.
   * @param {!MingaSetCommentingRolesResult} msg The message object to deserialize into.
   * @param {!jspb.BinaryReader} reader The BinaryReader to use.
   * @return {!MingaSetCommentingRolesResult}
   */
  static deserializeBinaryFromReader(msg: MingaSetCommentingRolesResult, reader: jspb.BinaryReader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break;
      }
      var field = reader.getFieldNumber();
      switch (field) {
      default:
        reader.skipField();
        break;
      }
    }
    return msg;
  }


  /**
   * Serializes the given message to binary data (in protobuf wire
   * format), writing to the given BinaryWriter.
   * @param {!MingaSetCommentingRolesResult} message
   * @param {!jspb.BinaryWriter} writer
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  static serializeBinaryToWriter(message: MingaSetCommentingRolesResult, writer: jspb.BinaryWriter) {
    var f: any = undefined;
  }


  /**
   * Serializes the message to binary data (in protobuf wire format).
   * @return {!Uint8Array}
   */
  serializeBinary() {
    var writer = new jspb.BinaryWriter();
    MingaSetCommentingRolesResult.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }
}
